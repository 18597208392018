import { actions, assign, createMachine } from 'xstate';
import { conditionRequiresOperand, DataSetTextFilterCondition } from '@process-street/subgrade/process';

type Context<Column extends string, Condition extends string, Value> = {
  columnId: Column | undefined;
  condition: Condition | undefined;
  value: Value | undefined;
};

type Event<Column extends string, Condition extends string, Value> =
  | {
      type: 'SELECT_COLUMN';
      id: Column;
      condition?: Condition;
      value?: Value;
    }
  | {
      type: 'SELECT_CONDITION';
      condition: Condition;
      value?: Value;
    }
  | {
      type: 'UPDATE_VALUE';
      value: Value | undefined;
    }
  | {
      type: 'CLEAR_VALUE';
    }
  | {
      type: 'PERSIST';
    };

export const UPDATE_DEBOUNCE = 500;
// RTL tests seem to not like the 25ms delay, so we increase it to 100ms
export const FOCUS_DELAY = process.env.NODE_ENV === 'test' ? 100 : 25;

/** Since filters are a kind of composite input (made up of two dropdowns and a text field),
 * we use a state machine to manage the state of the filter.
 * Essentially, during the initial interaction with the filter, we don't want to persist as it
 * is pretty jarring to have the filter persist as you are interacting with it.
 */
export const makeColumnsFilterItemStateMachine = <
  Column extends string = string,
  Condition extends string = string,
  Value = string,
>({
  getIsValueConfigured = v => v !== undefined,
}: {
  getIsValueConfigured?: (value: Value | undefined) => boolean;
} = {}) =>
  createMachine(
    {
      predictableActionArguments: true,
      schema: {
        context: {} as Context<Column, Condition, Value>,
        events: {} as Event<Column, Condition, Value>,
      },
      tsTypes: {} as import('./columns-filter-item-machine.typegen').Typegen0,
      context: {
        columnId: undefined,
        condition: undefined,
        value: undefined,
      },
      initial: 'column',
      states: {
        column: {
          // Delay focus events to allow for other things like state based disabled states to update
          after: { FOCUS_DELAY: { actions: 'focusColumn' } },
          on: {
            SELECT_COLUMN: { target: 'condition', actions: 'selectColumn' },
          },
          always: [
            // jump states forward based on initial context
            {
              cond: 'isConfigured',
              target: 'configured',
            },
            {
              cond: 'isColumnSelected',
              target: 'condition',
            },
          ],
        },
        condition: {
          after: { FOCUS_DELAY: { actions: 'focusCondition' } },
          on: {
            SELECT_COLUMN: { target: 'condition', actions: 'selectColumn' },
            SELECT_CONDITION: [
              {
                cond: 'conditionRequiresNoOperand',
                target: 'configured',
                actions: ['clearValue', 'selectCondition', 'persist'],
              },
              { target: 'value', actions: 'selectCondition' },
            ],
          },
          always: {
            cond: 'isConditionSelected',
            target: 'value',
          },
        },
        value: {
          after: { FOCUS_DELAY: { actions: 'focusValue' } },
          on: {
            SELECT_COLUMN: { target: 'value', actions: 'selectColumn' },
            SELECT_CONDITION: [
              {
                cond: 'conditionRequiresNoOperand',
                target: 'configured',
                actions: ['clearValue', 'selectCondition', 'persist'],
              },
              { target: 'value', actions: 'selectCondition' },
            ],
            UPDATE_VALUE: [
              // Use debounced version on value since it's a text field
              {
                target: 'configured',
                cond: 'valueIsString',
                actions: ['updateValue', 'cancelPersist', 'debouncedPersist'],
              },
              { target: 'configured', actions: ['updateValue', 'cancelPersist', 'persist'] },
            ],
          },
        },
        /** once the filter is "configured", persist changes on any field changes */
        configured: {
          on: {
            SELECT_COLUMN: { actions: ['selectColumn', 'persist'] },
            SELECT_CONDITION: [
              {
                cond: 'conditionRequiresNoOperand',
                actions: ['clearValue', 'selectCondition', 'persist'],
              },
              {
                actions: ['selectCondition', 'persist'],
              },
            ],
            UPDATE_VALUE: [
              { cond: 'valueIsString', actions: ['updateValue', 'cancelPersist', 'debouncedPersist'] },
              { actions: ['updateValue', 'persist'] },
            ],
            PERSIST: { actions: 'persist' },
          },
        },
      },
    },
    {
      actions: {
        selectColumn: assign({
          columnId: (_, event) => event.id,
          condition: (ctx, event) => event.condition ?? ctx.condition,
          value: (ctx, event) => event.value ?? ctx.value,
        }),
        selectCondition: assign({
          condition: (_, event) => event.condition,
          value: (ctx, event) => event.value ?? ctx.value,
        }),
        updateValue: assign({ value: (_, event) => event.value }),
        clearValue: assign({ value: () => undefined }),
        cancelPersist: actions.cancel('persist'),
        debouncedPersist: actions.send('PERSIST', { id: 'persist', delay: UPDATE_DEBOUNCE }),
      },
      guards: {
        isConfigured: (ctx, _) =>
          ctx.columnId !== undefined && ctx.condition !== undefined && getIsValueConfigured(ctx.value),
        isColumnSelected: (ctx, _) => ctx.columnId !== undefined,
        isConditionSelected: (ctx, _) => ctx.columnId !== undefined && ctx.condition !== undefined,
        conditionRequiresNoOperand: (ctx, evt) =>
          !conditionRequiresOperand((evt.condition ?? ctx.condition) as DataSetTextFilterCondition),
        valueIsString: (_ctx, evt) => typeof evt.value === 'string',
      },
      delays: {
        FOCUS_DELAY,
      },
    },
  );
