import * as React from 'react';
import { Box } from 'components/design/next';
import { TableActions } from './table-actions';
import { TableContent } from './table-content';
import { TablePagination } from './table-pagination';

export const ScheduleView: React.FC<React.PropsWithChildren<unknown>> = () => {
  React.useEffect(() => {
    window.document.title = `Scheduled | Process Street`;
  }, []);

  return (
    <Box as="section" py="4" px="8" w="full" overflowY="auto">
      <TableActions />
      <Box overflowX="auto">
        <TableContent />
      </Box>
      <TablePagination />
    </Box>
  );
};
