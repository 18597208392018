import angular from 'angular';
import { queryClient } from 'components/react-root';
import { GetAllLinkedDataSetRowsQuery } from 'pages/reports/data-sets/query-builder';
import templateUrl from './checklist-select-field.component.html';
import './checklist-select-field.scss';

angular.module('frontStreetApp.directives').component('psChecklistSelectFormField', {
  bindings: {
    user: '<',
    widget: '<',
    editable: '<',
    formFieldValue: '<',
    onUpdateValue: '&',
    onInteractionEnd: '&',
    readOnly: '<',
  },
  templateUrl,
  controller($scope, $q) {
    const ctrl = this;

    // Options to be used on the linked dropdown;
    ctrl.linkedSavedViewOptions = [];
    ctrl.isLoadingLinkRows = false;

    ctrl.loadLinkRows = () => {
      // Don't fetch the data again if it's already available
      if (ctrl.linkedSavedViewOptions.length > 0) return;

      ctrl.isLoadingLinkRows = true;

      const params = {
        dataSetId: ctrl.widget.config.linkedDataSetId,
        linkId: ctrl.widget.config.linkId,
        savedViewId: ctrl.widget.config.linkedSavedViewId,
      };

      const deferred = $q.defer();

      const promise = queryClient
        .fetchQuery(GetAllLinkedDataSetRowsQuery.getKey(params), () => GetAllLinkedDataSetRowsQuery.queryFn(params))
        .then(rows => {
          deferred.resolve(
            rows.map(row => ({
              id: row.id,
              name: row.cells[ctrl.widget.config.linkedColumnId]?.value,
            })),
          );
        })
        .catch(e => {
          deferred.reject(e);
        });

      deferred.promise
        .then(options => {
          ctrl.linkedSavedViewOptions = options;
          ctrl.isLoadingLinkRows = false;
        })
        .catch(() => {
          ctrl.isLoadingLinkRows = false;
        });

      return promise;
    };

    ctrl.$onChanges = function (changes) {
      if (
        changes.formFieldValue &&
        changes.formFieldValue.currentValue &&
        changes.formFieldValue.currentValue.fieldValue
      ) {
        ctrl.fieldValue = angular.copy(changes.formFieldValue.currentValue.fieldValue);
        const itemExists = ctrl.widget.config.items.some(item => item.name === ctrl.fieldValue.value);
        if (!itemExists) {
          ctrl.bonusItemName = ctrl.fieldValue.value;
        }
      }

      if (changes.formFieldValue?.currentValue?.formFieldWidget) {
        // enrich the widget config with the new changes
        ctrl.widget.config = {
          ...ctrl.widget.config,
          ...changes.formFieldValue.currentValue.formFieldWidget.config,
        };
      }
    };

    ctrl.updateSelectField = function (widget, fieldValue) {
      return ctrl.onUpdateValue({
        widget,
        fieldValue,
      });
    };

    ctrl.onBlur = function (widget, fieldValue) {
      return ctrl.onInteractionEnd({
        widget,
        fieldValue,
      });
    };

    ctrl.shouldShowAudit = () => Boolean(ctrl.formFieldValue?.audit);

    ctrl.handleOnSelect = function () {
      ctrl.fieldValue = { value: ctrl.fieldValue.name, dataSetRowId: ctrl.fieldValue.id };

      return ctrl.onUpdateValue({
        widget: ctrl.widget,
        fieldValue: ctrl.fieldValue,
      });
    };

    ctrl.clearSelectedValue = function () {
      ctrl.fieldValue = { value: '' };

      return ctrl.onUpdateValue({
        widget: ctrl.widget,
        fieldValue: ctrl.fieldValue,
      });
    };

    ctrl.isReadOnly = function () {
      return !ctrl.fieldValue || !ctrl.editable;
    };

    ctrl.getMatchingOptions = function (search) {
      if (!search) return ctrl.linkedSavedViewOptions;

      const regexp = new RegExp(search, 'gi');

      return ctrl.linkedSavedViewOptions.filter(option => regexp.test(option.name));
    };
  },
});
