export const industries: readonly string[] = [
  'Accounting',
  'Education',
  'Engineering and Construction',
  'Finance',
  'Health',
  'Hospitality',
  'Human Resources',
  'IT',
  'Law',
  'Manufacturing',
  'Marketing',
  'Real Estate',
  'Retail',
  'Sales',
  'Software',
  'Transport and Logistics',
  'Other',
] as const;
