import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { PlanLevelPricingPanel } from './PlanLevelPricingPanel';
import { StripeLink } from 'components/billing/stripe-billing-portal-button/stripe-link';

export const BillingModule = angular
  .module('billing', ['ui.bootstrap', 'ngRedux'])
  .component(
    'psPlanLevelPricingPanel',
    reactToAngular(PlanLevelPricingPanel, [
      'currentPlan',
      'onSelect',
      'planInterval',
      'planMap',
      'discount',
      'organization',
    ]),
  )
  .component('psStripeLink', reactToAngular(StripeLink, ['organization'])).name;
