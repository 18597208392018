import { Muid } from '@process-street/subgrade/core';
import { Folder } from '@process-street/subgrade/process';
import { axiosService } from 'services/axios-service';

const makeGetAllFoldersByOrganizationId =
  ({ permission }: { permission?: 'folder_read' | 'template_create' } = {}) =>
  (organizationId: Muid): Promise<Folder[]> =>
    axiosService
      .getAxios()
      .get<Folder[]>(`/1/organizations/${organizationId}/folders`, {
        params: { ...(permission ? { permission } : undefined) },
      })
      .then(response => response.data);

export const getAllFoldersByOrganizationId = makeGetAllFoldersByOrganizationId();

export const getAllReadableFoldersByOrganizationId = makeGetAllFoldersByOrganizationId({ permission: 'folder_read' });

const getAllWriteableFoldersByOrganizationId = makeGetAllFoldersByOrganizationId({ permission: 'template_create' });

export const FolderPickerApi = {
  getAllFoldersByOrganizationId,
  getAllReadableFoldersByOrganizationId,
  getAllWriteableFoldersByOrganizationId,
};
