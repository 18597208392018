import { ButtonProps } from 'components/design/next';
import * as React from 'react';

export interface PaywallHook {
  (): {
    onClick?: () => void;
    isLoading: boolean;
  };
}

export const PaywallButton: React.FC<React.PropsWithChildren<{ useHook: PaywallHook }>> = ({
  children: child,
  useHook,
}) => {
  if (!React.isValidElement<Pick<ButtonProps, 'onClick' | 'isDisabled'> & { href?: string }>(child)) {
    throw new Error(`PaywallButton child must be a clickable element`);
  }
  const paywallOverrides = useHook();
  const onClick = paywallOverrides.onClick ?? child.props.onClick;

  // Allow links to have paywall. Remove the href in the event there is a paywall override.
  const href = paywallOverrides.onClick ? undefined : child.props.href;
  return React.cloneElement(child, { isDisabled: paywallOverrides.isLoading || child.props.isDisabled, onClick, href });
};
