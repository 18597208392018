import { OneOffTask } from '@process-street/subgrade/one-off-task';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  MenuItem,
  Text,
  useDisclosure,
} from 'components/design/next';
import { useNextThemeToast } from 'components/design/next/use-next-theme-toast';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { DeleteChecklistMutation } from 'features/checklist/query-builder';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { GetOneOffTasksByChecklistQuery } from 'features/one-off-tasks/query-builder';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useCanEditOneOfftask } from '../hooks';
import { useOneOffTaskDrawerStore } from '../one-off-task-drawer-store';
import { useInboxItemsDataSource } from 'pages/tasks/hooks/use-inbox-items-data-source';

export type DeleteTaskMenuItemProps = {
  task: OneOffTask;
};
export const DeleteTaskMenuItem: React.FC<React.PropsWithChildren<DeleteTaskMenuItemProps>> = ({ task }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onClose: onCloseDrawer } = useOneOffTaskDrawerStore();
  const toast = useNextThemeToast();
  const queryClient = useQueryClient();
  const canEdit = useCanEditOneOfftask(task);
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const { invalidate: invalidateInboxData } = useInboxItemsDataSource();

  const deleteTaskMutation = DeleteChecklistMutation.useMutation({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Task deleted',
      });

      queryClient.invalidateQueries(GetInboxItemsQuery.key);
      if (task.linkedChecklist) {
        queryClient.invalidateQueries(GetOneOffTasksByChecklistQuery.getKey({ checklistId: task.linkedChecklist.id }));
      }
      invalidateInboxData({ shouldPurge: true });
      onCloseDrawer();
    },
    onError: () => {
      toast({
        status: 'error',
        title: `We're having problems deleting this Task.`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const handleOnDelete = () => {
    deleteTaskMutation.mutate({ id: task.internalChecklistId });
    onClose();
  };
  return (
    <>
      <MenuItem
        color="red.500"
        icon={<Icon icon="trash-alt" size="4" variant="far" color="red.500" />}
        iconSpacing="2"
        fontSize="md"
        onClick={onOpen}
        isDisabled={!canEdit}
      >
        Delete task
      </MenuItem>
      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontWeight="bold">Delete this task?</AlertDialogHeader>
            <AlertDialogBody>
              <Text>
                Your task and its content will be deleted and <Text fontWeight="bold">can't be recovered.</Text>
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleOnDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
