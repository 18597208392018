import React, { useEffect } from 'react';

import { Box, Text, useToast } from 'components/design/next';
import { SIZE_TOO_LARGE, useUploadCoverIcon } from './use-upload-cover-icon';
import { Template } from '@process-street/subgrade/process';
import last from 'lodash/last';
import { UploadArea, UploadProgress } from 'features/upload/components';
import { getHighlightColor } from 'features/upload/components/utils';
import { DefaultErrorMessages } from 'components/utils/error-messages';

type UploadCoverIconProps = {
  templateId: Template['id'];
  uploadingMessage: string;
  onFinish: (templateId: Template['id']) => void;
  acceptMimeTypes?: string;
};
export const UploadTemplateCoverIcon: React.FC<React.PropsWithChildren<UploadCoverIconProps>> = ({
  templateId,
  uploadingMessage,
  onFinish,
  acceptMimeTypes,
  children,
}) => {
  const toast = useToast();

  const { dropzoneState, progress, uploadError } = useUploadCoverIcon({
    templateId,
    onFinish,
    accept: acceptMimeTypes,
  });

  useEffect(() => {
    if (uploadError) {
      toast({
        status: 'error',
        title: "We're having problems uploading the cover icon",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    }
  }, [toast, uploadError]);

  const fileTooLarge =
    dropzoneState.fileRejections.length > 0 && last(dropzoneState.fileRejections[0].errors)?.code === SIZE_TOO_LARGE;

  return (
    <div {...dropzoneState.getRootProps({ className: 'dropzone' })}>
      <Box p={4} borderRadius="md" cursor="pointer" bgColor={getHighlightColor(dropzoneState)}>
        {progress === undefined ? (
          <UploadArea dropzoneInputProps={dropzoneState.getInputProps()}>{children}</UploadArea>
        ) : (
          <UploadProgress progress={progress} message={uploadingMessage} />
        )}
        {fileTooLarge && (
          <Text color="red.500" align="center" fontWeight="medium">
            {last(dropzoneState.fileRejections[0].errors)?.message}
          </Text>
        )}
      </Box>
    </div>
  );
};
