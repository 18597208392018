import { Muid, MuidConverter, Option } from '@process-street/subgrade/core';
import {
  ChecklistColumn,
  ChecklistGridData,
  ChecklistGridSearchRequest,
  Clause,
  ColDescriptor,
  ColumnDef,
  ColumnDto,
  ColumnType,
  ConditionalFilterAssigneeListOperand,
  Filter,
  FilterType,
  FormFieldDataRow,
  isFormFieldValueColumnDef,
  TaskColumnDef,
} from '@process-street/subgrade/dashboard';
import { AxiosResponse } from 'axios';
import { GetDataRequest, TemplateDto } from 'components/dashboard/models/filters';
import {
  GetAvatarsRequest,
  GetAvatarsResponse,
  MembershipAvatarDto,
  OverdueTaskDto,
  RowValues,
  TaskDataRow,
} from 'components/dashboard/models/grid';
import { checklistColumns } from 'components/dashboard/models/columns';
import { SavedView, savedViewCurrentUserId } from 'components/dashboard/models/saved-views';
import { axiosService } from 'services/axios-service';
import { GridDataService } from './grid-data.service';
import { queryClient } from 'components/react-root';
import { ChecklistGridCountQuery } from 'features/checklists/query-builder';
import { GetTemplateSchemaQuery } from 'features/template/query-builder';
import { GetChecklistGridFormFieldDataQuery, PostReportsSearchQuery } from 'features/checklist-grid/query-builder';
import { GridHelper } from './grid-helper';
import { TemplateType } from '@process-street/subgrade/process';

export class GridDataServiceImpl implements GridDataService {
  public static getInstance() {
    return GridDataServiceImpl.instance;
  }

  private static instance = new GridDataServiceImpl();

  private predefinedColumns = checklistColumns.map(col => ({
    columnType: col.type as ColumnType,
    field: col.field as string,
    name: col.headerName as string,
  }));

  public getColumns(
    templateId: Option<Muid>,
    isFormFieldSortingEnabled?: boolean,
    isLabelColumnEnabled?: boolean,
  ): Promise<ColumnDto[]> {
    const predefinedColumns = this.predefinedColumns.filter(c =>
      isLabelColumnEnabled ? c : c.field !== ChecklistColumn.ChecklistLabelId,
    );

    if (templateId) {
      return GetTemplateSchemaQuery.queryFn({ templateId }).then(data => {
        const taskColumns = this.getTaskColumns(data.columns);
        const formFieldValueColumns = this.getFormFieldValueColumns(data.columns, isFormFieldSortingEnabled);

        return [...predefinedColumns, ...taskColumns, ...formFieldValueColumns];
      });
    } else {
      return Promise.resolve([...predefinedColumns]);
    }
  }

  public getTaskColumns(columns: ColumnDef[]): ColumnDto[] {
    return columns
      .filter(col => col.kind === ColumnType.Task && col.descriptor === ColDescriptor.Status)
      .map(col => {
        const colDef = col.data as TaskColumnDef;
        return {
          columnType: ColumnType.Task,
          field: `tasks.${colDef.groupId}:${ColDescriptor.Status}`,
          groupId: colDef.groupId,
          name: colDef.label,
        };
      })
      .filter(col => !col.name.endsWith(':'));
  }

  public getFormFieldValueColumns(columns: ColumnDef[], isFormFieldSortingEnabled?: boolean): ColumnDto[] {
    return columns
      .filter(isFormFieldValueColumnDef)
      .map(column => GridHelper.formFieldColumnDefToColumnDto(column, isFormFieldSortingEnabled));
  }

  public getData(request: GetDataRequest, organizationMembershipId: Muid): Promise<RowValues[]> {
    const searchCriteria = this.requestToSearchRequest(request, organizationMembershipId);
    const params: PostReportsSearchQuery.Params = { searchCriteria, organizationId: request.organizationId };

    return queryClient
      .fetchQuery(PostReportsSearchQuery.getKey(params), () => PostReportsSearchQuery.queryFn(params))
      .then(data => data.map(row => this.convertRow(row)));
  }

  public getAvatars(request: GetAvatarsRequest): Promise<MembershipAvatarDto[]> {
    return axiosService
      .getAxios()
      .post(`/1/organizations/${request.organizationId}/checklist-grid/avatars`, request)
      .then((response: AxiosResponse) => {
        const data = response.data as GetAvatarsResponse;
        return data.avatars;
      });
  }

  public getRowCount(request: GetDataRequest, organizationMembershipId: Muid): Promise<number> {
    // unpacking page size as it's not relevant for count
    // This allows react-query cache to work with the results component fetching its own data
    const { pageSize: _, ...searchRequest } = this.requestToSearchRequest(request, organizationMembershipId);

    return queryClient
      .fetchQuery(
        ChecklistGridCountQuery.getKey({ organizationId: request.organizationId, ...searchRequest }),
        ChecklistGridCountQuery.queryFn,
      )
      .then(({ total }) => total);
  }

  public requestToSearchRequest(request: GetDataRequest, organizationMembershipId: Muid): ChecklistGridSearchRequest {
    const searchRequest: ChecklistGridSearchRequest = {
      offsetId: request.offsetId,
      pageSize: request.pageSize,
      templateTypes: [TemplateType.Playbook],
    };

    if (request.sorting) {
      searchRequest.sortBy = request.sorting!.sortColumn;
      searchRequest.sortAsc = request.sorting.sortAsc;
      searchRequest.sortByWidgetGroupId = request.sorting.sortByWidgetGroupId;
    }

    if (request.filtering) {
      searchRequest.searchPattern = request.filtering.searchPattern;
      searchRequest.templateIds = request.filtering.selectedTemplates;

      if (request.filtering.conditionalFilter) {
        searchRequest.filter = conditionalFilterTreeToList(
          request.filtering.conditionalFilter,
          organizationMembershipId,
        );
      }
    }

    return searchRequest;
  }

  public conditionalFilterTreeToList = conditionalFilterTreeToList;

  public convertRow(row: ChecklistGridData): RowValues {
    const { status } = row;

    const progress = {
      completedTasks: row.progress.completedTasksCount,
      status,
      totalTasks: row.progress.totalTasksCount,
    };

    const rowValues = {
      checklistRevisionId: row.checklistRevisionId,
      id: row.id,
      [ChecklistColumn.LastActiveDate]: row.lastActiveDate,
      [ChecklistColumn.Assignees]: row.assignees,
      [ChecklistColumn.ChecklistCompletedDate]: row.checklistCompletedDate,
      [ChecklistColumn.ChecklistCompletedBy]: row.completedBy,
      [ChecklistColumn.ChecklistDueDate]: row.checklistDueDate,
      [ChecklistColumn.ChecklistName]: {
        checklistId: row.id,
        checklistName: row.checklistName,
      },
      [ChecklistColumn.TemplateName]: {
        templateId: row.templateId,
        templateName: row.templateName,
      },
      [ChecklistColumn.OverdueTasksCount]: {
        checklistId: row.id,
        count: row.overdueTasksCount,
      },
      [ChecklistColumn.TotalCommentsAndAttachmentsCount]: {
        checklistId: row.id,
        count: row.totalCommentsCount + row.totalAttachmentsCount,
      },
      [ChecklistColumn.CompletedTasksCount]: progress,
      [ChecklistColumn.ChecklistCreateDate]: row.checklistCreatedDate,
      [ChecklistColumn.ProgressStatus]: status,
      [ChecklistColumn.ChecklistLabelId]: {
        labelId: row.labelId,
        checklistId: row.id,
      },
      [ChecklistColumn.TemplateVersion]: row.templateVersion,
    };

    /*
    rowValues[ChecklistColumn.ChecklistLabelId] = {
      ...rowValues[ChecklistColumn.ChecklistLabelId],
      rowValues,
    };
*/

    return rowValues;
  }

  public getTemplatesByOrganizationId(organizationId: Muid): Promise<TemplateDto[]> {
    return axiosService
      .getAxios()
      .get(`/1/organizations/${organizationId}/checklist-grid/templates`)
      .then((response: AxiosResponse) => {
        return response.data.templates as TemplateDto[];
      });
  }

  public getTaskData(
    organizationId: Muid,
    templateId: Muid,
    checklistRevisionIds: Muid[],
    taskGroupIds: Muid[],
  ): Promise<TaskDataRow[]> {
    const taskDataRequest = { templateId, checklistRevisionIds, taskGroupIds };
    return axiosService
      .getAxios()
      .post(`/1/organizations/${organizationId}/checklist-grid/task-data`, taskDataRequest)
      .then((response: AxiosResponse) => {
        const results = response.data as TaskDataRow[];

        // convert uuids to muids
        results.forEach(row => {
          row.tasks.forEach(task => {
            task.id = MuidConverter.fromUuid(task.id);
            task.groupId = MuidConverter.fromUuid(task.groupId);
          });
        });

        return results;
      });
  }

  public getFormFieldValuesData(
    organizationId: Muid,
    templateId: Muid,
    checklistRevisionIds: Muid[],
    widgetGroupIds: Muid[],
  ): Promise<FormFieldDataRow[]> {
    return GetChecklistGridFormFieldDataQuery.queryFn({
      organizationId,
      templateId,
      checklistRevisionIds,
      widgetGroupIds,
    });
  }

  public getSavedViewsByOrganizationId(organizationId: Muid): Promise<SavedView[]> {
    return axiosService
      .getAxios()
      .get(`/1/organizations/${organizationId}/checklist-grid/saved-views`)
      .then((response: AxiosResponse) => {
        return response.data as SavedView[];
      });
  }

  public getOverdueTasksByChecklistId(checklistId: Muid): Promise<OverdueTaskDto[]> {
    return axiosService
      .getAxios()
      .get(`/1/checklists/${checklistId}/checklist-grid/overdue-tasks`)
      .then((response: AxiosResponse) => {
        return response.data as OverdueTaskDto[];
      });
  }

  public saveSavedView(view: SavedView): Promise<SavedView> {
    return axiosService
      .getAxios()
      .post(`/1/organizations/${view.organizationId}/checklist-grid/saved-views`, view)
      .then((response: AxiosResponse) => response.data as SavedView);
  }

  public deleteSavedView(organizationId: Muid, viewId: Muid): Promise<void> {
    return axiosService.getAxios().delete(`/1/organizations/${organizationId}/checklist-grid/saved-views/${viewId}`);
  }
}

export const gridDataService = GridDataServiceImpl.getInstance();

export function conditionalFilterTreeToList(
  conditionalFilter: Filter,
  organizationMembershipId: Muid,
  result: Filter[] = [],
): Filter[] {
  switch (conditionalFilter.filterType) {
    case FilterType.Clause:
      const clause = conditionalFilter as Clause;
      if (clause.operand.operandType === 'AssigneeListOperand') {
        const operand = clause.operand as ConditionalFilterAssigneeListOperand;
        const value = operand.value.map(id => (id === savedViewCurrentUserId ? organizationMembershipId : id));
        const updatedClause = {
          ...conditionalFilter,
          operand: {
            ...operand,
            value,
          },
        };
        result.push(updatedClause);
      } else {
        result.push(conditionalFilter);
      }
      break;
    default:
      const filter = { ...conditionalFilter, children: [] };
      result.push(filter);

      if (conditionalFilter.children) {
        conditionalFilter.children.forEach(child =>
          conditionalFilterTreeToList(child, organizationMembershipId, result),
        );
      }
      break;
  }

  return result;
}
