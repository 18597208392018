import { ChecklistError, Muid, Option, updateAuditMetadata } from '@process-street/subgrade/core';
import { Checklist, ChecklistStatus } from '@process-street/subgrade/process';
import { BaseChecklistSelector } from '@process-street/subgrade/redux/selector';
import { selectorWithCurrentUser } from '@process-street/subgrade/redux/selector/util/selector-with-current-user';
import { BaseReduxState } from '@process-street/subgrade/redux/types';
import { ChecklistUpdateValidator } from './checklist-update-validator';
import { OptimisticDiff, OptimisticResult, OptimisticService } from './model';
import { DynamicDueDateEngine } from './rules/dynamic-due-date';
import { OptimisticResultBuilder } from './util/optimistic-result-builder';

const updateDueDateById = (checklistId: Muid, checklistRevisionId: Option<Muid>, dueDate: number | null) =>
  selectorWithCurrentUser<BaseReduxState, Option<OptimisticResult | ChecklistError>>((currentUser, state) => {
    const updateError: Option<ChecklistError> = ChecklistUpdateValidator.validateUpdateDueDate(dueDate);
    if (updateError) {
      return updateError;
    }

    const originalChecklist = BaseChecklistSelector.getById(checklistId)(state);
    if (!originalChecklist) {
      return undefined;
    }

    const updatedChecklist: Checklist = {
      ...originalChecklist,
      audit: updateAuditMetadata(currentUser.id, originalChecklist),
      dueDate: dueDate ? dueDate : undefined,
    };

    const resultBuilder = new OptimisticResultBuilder(false).checklist.appendUpdateEvent(
      updatedChecklist,
      originalChecklist,
    );

    if (checklistRevisionId) {
      DynamicDueDateEngine.applyOnChecklistDueDateChange(updatedChecklist, checklistRevisionId, resultBuilder, state);
    }

    return resultBuilder.build();
  });

const updateNameById = (checklistId: Muid, name: string) =>
  selectorWithCurrentUser<BaseReduxState, Option<OptimisticResult>>((currentUser, state) => {
    const originalChecklist = BaseChecklistSelector.getById(checklistId)(state);
    if (!originalChecklist) {
      return undefined;
    }

    const updatedChecklist: OptimisticDiff<Checklist> = {
      audit: updateAuditMetadata(currentUser.id, originalChecklist),
      id: checklistId,
      name,
    };

    return new OptimisticResultBuilder(false).checklist.appendUpdateEvent(updatedChecklist, originalChecklist).build();
  });

const emulateStatusUpdate = (checklistId: Muid, status: ChecklistStatus) =>
  selectorWithCurrentUser<BaseReduxState, Option<OptimisticResult | ChecklistError>>((currentUser, state) => {
    const originalChecklist = BaseChecklistSelector.getById(checklistId)(state);
    if (!originalChecklist) {
      return undefined;
    }

    const updatedChecklist: OptimisticDiff<Checklist> = {
      audit: updateAuditMetadata(currentUser.id, originalChecklist),
      id: checklistId,
      status,
    };

    return new OptimisticResultBuilder(false).checklist.appendUpdateEvent(updatedChecklist, originalChecklist).build();
  });

const completeById = (checklistId: Muid, checklistRevisionId: Muid) => (state: BaseReduxState) => {
  const updateError: Option<ChecklistError> = ChecklistUpdateValidator.validateCompleteByChecklistRevisionId(
    checklistRevisionId,
    state,
  );
  if (updateError) {
    return updateError;
  }

  return emulateStatusUpdate(checklistId, ChecklistStatus.Completed)(state);
};
const archiveById = (checklistId: Muid) => emulateStatusUpdate(checklistId, ChecklistStatus.Archived);
const deleteById = (checklistId: Muid) => emulateStatusUpdate(checklistId, ChecklistStatus.Deleted);

export const OptimisticChecklist: OptimisticService<ChecklistError> = {
  archiveById,
  completeById,
  deleteById,
  updateDueDateById,
  updateNameById,
};
