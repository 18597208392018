import { DeserializeHtml } from '@udecode/plate-core';
import { MARK_COLOR } from 'pages/pages/_id/edit/page/plugins/ps-color';
import rgb2hex from 'rgb2hex';

export const backgroundDeserializer: DeserializeHtml = {
  isLeaf: true,
  getNode: el => ({
    type: MARK_COLOR,
    background: el.style.background.startsWith('rgb') ? rgb2hex(el.style.background).hex : undefined,
  }),
  rules: [{ validNodeName: 'SPAN' }],
};
