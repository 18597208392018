import * as React from 'react';
import { Button, Icon, Tooltip, ButtonProps, Link, forwardRef } from 'components/design/next';
import { match } from 'ts-pattern';
import { TriggerIcons, TriggerLabels } from '../choose-template-triggers/native-trigger-data';
import { NativeTemplateTrigger } from '@process-street/subgrade/process';
import { RunMultipleButton, ScheduleWorkflowButton, WorkflowRunLinkButton } from 'components/template-menu-buttons';
import { useInjector } from 'components/injection-provider';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { NgLink } from 'features/app/components/ng-link';
import { Muid } from '@process-street/subgrade/core';
import { useTriggersModalStore } from 'features/automations/components/workflow-automations/components/template/triggers-modal-store';
import { useMatch } from '@process-street/adapters/navigation';

export type NativeTriggerButtonProps = Partial<ButtonProps> & {
  shouldCollapseLabels: boolean;
  templateId?: Muid;
  trigger: NativeTemplateTrigger;
  editable?: boolean;
};

export const NativeTriggerButton = ({
  shouldCollapseLabels,
  trigger,
  templateId,
  editable = true,
  ...buttonProps
}: NativeTriggerButtonProps) => {
  const triggersModalStore = useTriggersModalStore();

  const label = TriggerLabels[trigger];
  const { $state } = useInjector('$state');
  const runUrlOptions = {
    to: $state.current,
    params: {
      ...$state.params,
      [AppModalQueryParam.Modal]: AppModalName.RunChecklist,
      [AppModalQueryParam.ModalTemplateId]: templateId,
    },
    options: { inherit: false },
  };

  const tooltipContent = editable ? label : 'Edit workflow to edit this trigger';

  return (
    <Tooltip label={tooltipContent} isDisabled={editable && !shouldCollapseLabels} shouldWrapChildren hasArrow>
      {match(trigger)
        .with('manual', () => (
          <TriggerButton
            {...buttonProps}
            as={NgLink}
            {...runUrlOptions}
            leftIcon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
            shouldCollapseLabel={shouldCollapseLabels}
            disabled={!editable}
            label={label}
          />
        ))
        .with('email', () => (
          <TriggerButton
            {...buttonProps}
            leftIcon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
            shouldCollapseLabel={shouldCollapseLabels}
            label={label}
            onClick={triggersModalStore.openEmailTab}
            disabled={!editable}
          />
        ))
        .with('runLink', () => (
          <WorkflowRunLinkButton>
            <TriggerButton
              {...buttonProps}
              leftIcon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
              shouldCollapseLabel={shouldCollapseLabels}
              label={label}
              onClick={triggersModalStore.openRunLinkTab}
              disabled={!editable}
            />
          </WorkflowRunLinkButton>
        ))
        .with('runMultiple', () => (
          <RunMultipleButton>
            <TriggerButton
              {...buttonProps}
              leftIcon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
              shouldCollapseLabel={shouldCollapseLabels}
              label={label}
              onClick={triggersModalStore.openRunLinkTab}
              disabled={!editable}
            />
          </RunMultipleButton>
        ))
        .with('schedule', () => (
          <ScheduleWorkflowButton>
            <TriggerButton
              {...buttonProps}
              leftIcon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
              shouldCollapseLabel={shouldCollapseLabels}
              label={label}
              disabled={!editable}
            />
          </ScheduleWorkflowButton>
        ))
        .with('api', () => (
          <TriggerButton
            {...buttonProps}
            as={Link}
            href={$state.href('organizationManage.tab', { tab: 'integrations' }, { inherit: false })}
            leftIcon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
            shouldCollapseLabel={shouldCollapseLabels}
            label={label}
            onClick={() => {}}
            disabled={!editable}
          />
        ))
        .otherwise(() => null)}
    </Tooltip>
  );
};

const TriggerButton = forwardRef<ButtonProps & { shouldCollapseLabel: boolean; label: string }, 'button'>(
  ({ shouldCollapseLabel, label, ...props }, ref) => {
    const isViewV2 = useMatch('templateViewV2');

    return (
      <Button
        {...{
          ref,
          variant: isViewV2 ? 'ghost' : 'outline',
          borderRadius: isViewV2 ? 'full' : undefined,
          bg: 'white',
          size: 'sm',
          fontWeight: 'normal',
          _hover: {
            textDecoration: 'none',
            bg: isViewV2 ? 'transparent' : 'gray.100',
          },
          ...(shouldCollapseLabel
            ? {
                'iconSpacing': '0',
                'aria-label': label,
                'px': '2',
              }
            : {
                children: label,
              }),
          ...props,
        }}
        _disabled={{
          opacity: 1,
          cursor: 'default',
        }}
      />
    );
  },
);
