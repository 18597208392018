import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { DatePicker, DatePickerProps } from '../date-picker';

export type OperandValueDateProps = DatePickerProps;

export const OperandValueDate: React.VFC<OperandValueDateProps> = props => {
  const currentUser = useSelector(SessionSelector.getCurrentUser);

  return <DatePicker {...props} timeZone={currentUser?.timeZone} />;
};
