import { Button, ButtonProps } from 'components/design/next';
import { Organization, toError } from '@process-street/subgrade/core';
import { useStripeBillingPortalSessionQuery } from 'components/billing/stripe-billing-portal-button/query';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-use';
import { getWindow } from 'components/utils/globals-access';
import * as React from 'react';

export type StripeBillingPortalButtonProps = Omit<ButtonProps, 'onClick'> & {
  onStripeError?: (error: Error) => Promise<void> | void;
  organization: Organization;
};

export const StripeBillingPortalButton: React.FC<React.PropsWithChildren<StripeBillingPortalButtonProps>> = ({
  children,
  organization,
  onStripeError,
  ...props
}) => {
  const { href } = useLocation();
  const {
    data: stripeData,
    error: queryError,
    isFetching,
    isError,
    isSuccess,
    refetch: fetchStripeBillingPortalSession,
  } = useStripeBillingPortalSessionQuery(organization, href!);

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (clicked) {
      if (!isFetching && isSuccess && stripeData) {
        try {
          // ensure we have a valid url before attempting to navigate
          new URL(stripeData.portalUrl);
          // if no error was thrown we're good
          getWindow().location.assign(stripeData.portalUrl);
        } catch (e) {
          // Invalid URL, edge case
          setClicked(false);
          onStripeError && onStripeError(toError(e));
        }
      } else if (isError && queryError) {
        setClicked(false);
        onStripeError && onStripeError(queryError);
      }
    }
  }, [clicked, isFetching, isSuccess, stripeData, isError, queryError, onStripeError]);

  const onClick = () => {
    fetchStripeBillingPortalSession();
    setClicked(true);
  };

  // As we may have more than one StripeBillingPortalButton on the screen at any one time, we disabled the button
  // if it is not the one that was clicked when the query is running (isFetching === true)
  return (
    <Button {...props} onClick={onClick} isLoading={clicked} isDisabled={!clicked && isFetching}>
      {children}
    </Button>
  );
};
