import * as React from 'react';
import { Button, Icon, ButtonProps, Text } from 'components/design/next';
import { useEditWorkflowPaywall } from 'components/paywalls/edit-workflow';
import { PaywallButton } from 'components/paywalls';
import { TemplateType } from '@process-street/subgrade/process';
import { TemplateTypeProvider } from 'utils/template/template-type-context';

type BtnVariant = 'solid' | 'ghost';

export type Props = {
  templateType: TemplateType;
  onClick?: () => void;
  variant?: BtnVariant;
  draftExists?: boolean;
  label?: string;
  children?: React.ReactElement;
};

const VariantStyles: Record<BtnVariant, ButtonProps> = {
  solid: {
    padding: '7px 12px',
    color: 'gray.600',
    bgColor: 'white',
    borderColor: 'gray.300',
    borderWidth: 'px',
    borderStyle: 'solid',
    borderRadius: 'base',
    mb: '5',
    position: 'relative',
    _hover: { bgColor: 'gray.100' },
  },
  ghost: {
    padding: '2',
    mb: '-px',
    color: '#555',
    // matching a.list-group-item:hover (angular bootstrap)
    _hover: { bgColor: '#f5f5f5' },
    borderRadius: 'none',
    // unfortunate nudging to get it to line up with other angular bootstrap anchor elements
    ml: '2px',
  },
};

export const EditTemplateButton = (props: Props) => {
  const { templateType } = props;
  const btn = React.useMemo(() => {
    if (props.children === undefined) {
      const { onClick, draftExists, variant, label = 'Edit' } = props;
      return (
        <Button
          {...VariantStyles[variant!]}
          onClick={onClick}
          variant="unstyled"
          leftIcon={<Icon icon="edit" variant="far" size="4" />}
          iconSpacing="2"
          display="flex"
          w="full"
          // unfortunate nudging to get it to line up with other angular bootstrap anchor elements
          justifyContent="flex-start"
          rightIcon={
            draftExists ? (
              <Icon
                icon="exclamation-circle"
                variant="fas"
                size="4"
                color="yellow.500"
                title="This workflow has unpublished changes."
              />
            ) : undefined
          }
        >
          {/* Push the right icon to the right */}
          <Text mr="auto">{label}</Text>
        </Button>
      );
    }

    if (React.isValidElement(props.children)) {
      return props.children;
    }

    return null;
  }, [props]);

  return (
    <TemplateTypeProvider templateType={templateType}>
      <PaywallButton useHook={useEditWorkflowPaywall}>{btn}</PaywallButton>
    </TemplateTypeProvider>
  );
};
