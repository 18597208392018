import * as React from 'react';

import { AutomationSelectorProvider } from './components/selector/context';
import { useLoadTaskTemplatesQuery } from './query';
import { AutomationsModal } from './components/modal/component';
import { TemplateRevision, TemplateType } from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { TemplateTypeProvider } from 'utils/template/template-type-context';

type AutomationsWrapperModalProps = React.PropsWithChildren<{
  templateRevision: TemplateRevision;
  templateType: TemplateType;
}>;

export const AutomationsModalWrapper = ({ templateRevision, templateType, children }: AutomationsWrapperModalProps) => {
  const templateRevisionId = templateRevision?.id;

  const { TaskTemplateActions } = useInjector('TaskTemplateActions');

  useLoadTaskTemplatesQuery({ templateRevisionId, taskTemplateActions: TaskTemplateActions });

  return (
    <AutomationSelectorProvider>
      <TemplateTypeProvider templateType={templateType}>
        <AutomationsModal />
        {children}
      </TemplateTypeProvider>
    </AutomationSelectorProvider>
  );
};
