import { ApprovalTaskGroupsMap } from '@process-street/subgrade/approval-rule/approval.types';
import { Muid, Option } from '@process-street/subgrade/core';
import { ChecklistRevision, Task, TaskStatus, TaskTemplate } from '@process-street/subgrade/process';
import { BaseApprovalSelector } from '@process-street/subgrade/redux/selector/approval.selectors';
import angular from 'angular';
import { ApprovalRulesActions } from 'components/approval-rules/store/approval-rules.actions';
import { ApprovalRuleSelector } from 'components/approval-rules/store/approval-rules.selectors';
import { ApprovalActions } from 'components/approvals/store/approval.actions';
import ngRedux from 'ng-redux';
import { TaskTemplateActions } from 'reducers/task-template/task-template.actions';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { TaskActionsType } from 'reducers/task/task.actions';
import { TaskSelector } from 'reducers/task/task.selectors';
import { ReduxAppState } from 'reducers/types';
import { connectController } from 'reducers/util';
import templateUrl from './container.component.html';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ToastService } from 'services/toast-service.interface';
import { TaskListEvent } from 'app/directives/task-list/task-list-event';

interface InternalState {
  approvalTask: Task;
  approvedTasks: Task[];
  awaitingTasks: Task[];
  rejectedTasks: Task[];
}

interface InternalActions {
  getAllApprovalRuleSubjectsByTemplateRevisionId(templateRevisionId: Muid): Promise<void>;
  getAllApprovalsByChecklistRevisionId(checklistRevisionId: Muid): Promise<void>;
  getAllTaskTemplatesByTemplateRevisionId(templateRevisionId: Muid): Promise<void>;
  getAllTasksByChecklistRevisionId(checklistRevisionId: Muid): Promise<void>;
}

export class ApprovalsContainerController {
  public checklistRevision: Option<ChecklistRevision>;
  public task: Option<Task>;
  public disabled: Option<boolean>;

  public state: Option<InternalState>;
  public actions: Option<InternalActions>;

  private taskTemplate: Option<TaskTemplate>;

  static $inject = [
    '$rootScope',
    '$ngRedux',
    '$timeout',
    'ApprovalActions',
    'ApprovalRulesActions',
    'TaskTemplateActions',
    'TaskActions',
    'ToastService',
  ];
  constructor(
    private $rootScope: angular.IScope,
    private $ngRedux: ngRedux.INgRedux,
    private $timeout: angular.ITimeoutService,
    private approvalActions: ApprovalActions,
    private approvalRulesActions: ApprovalRulesActions,
    private taskTemplateActions: TaskTemplateActions,
    private taskActions: TaskActionsType,
    private toastService: ToastService,
  ) {
    const mapStateToThis = () => (state: ReduxAppState) => {
      let approvalTask: Option<Task>;
      let approvalTaskGroupsMap: ApprovalTaskGroupsMap = {
        approvedTasks: [],
        awaitingTasks: [],
        notSubmittedTasks: [],
        rejectedTasks: [],
      };
      let approvalRulesCount = 0;
      let notPermittedSubjectTasksExist = false;

      if (this.task && this.checklistRevision) {
        const { templateRevision } = this.checklistRevision;
        approvalTask = TaskSelector.getById(this.task.id)(state);
        this.taskTemplate = TaskTemplateSelector.getById(this.task.taskTemplate!.id)(state);
        approvalRulesCount = ApprovalRuleSelector.getAllByTaskTemplateGroupIds(templateRevision.id, [
          this.taskTemplate!.group.id,
        ])(state).length;
        approvalTaskGroupsMap = BaseApprovalSelector.getApprovalTaskGroupsMap(
          this.checklistRevision.id,
          this.task.id,
          templateRevision.id,
          this.taskTemplate!.group.id,
        )(state);

        notPermittedSubjectTasksExist = BaseApprovalSelector.areNotPermittedTasksExist(
          this.checklistRevision.id,
          templateRevision.id,
          this.taskTemplate!.group.id,
        )(state);
      }

      return { ...approvalTaskGroupsMap, approvalTask, approvalRulesCount, notPermittedSubjectTasksExist };
    };

    const mapDispatchToThis = () => ({
      getAllApprovalRuleSubjectsByTemplateRevisionId: this.approvalRulesActions.getAllByTemplateRevisionId,
      getAllApprovalsByChecklistRevisionId: this.approvalActions.getAllByChecklistRevisionId,
      getAllTaskTemplatesByTemplateRevisionId: this.taskTemplateActions.getAllByTemplateRevisionId,
      getAllTasksByChecklistRevisionId: this.taskActions.getAllByChecklistRevisionId,
    });

    connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, this.shouldChange)(this);
  }

  public shouldChange(changes: {
    checklistRevision: angular.IChangesObject<ChecklistRevision>;
    task: angular.IChangesObject<Task>;
  }) {
    return (
      !!(changes.checklistRevision && changes.checklistRevision.currentValue) ||
      !!(changes.task && changes.task.currentValue)
    );
  }

  public $onChanges(changes: {
    checklistRevision: angular.IChangesObject<ChecklistRevision>;
    task: angular.IChangesObject<Task>;
    disabled: angular.IChangesObject<boolean>;
  }) {
    const { checklistRevision, disabled, task } = changes;
    if (checklistRevision && checklistRevision.currentValue) {
      this.checklistRevision = checklistRevision.currentValue;
    }
    if (task && task.currentValue) {
      this.task = task.currentValue;
    }
    if (disabled && disabled.currentValue) {
      this.disabled = disabled.currentValue;
    }

    if (this.shouldChange(changes)) {
      this.actions!.getAllApprovalRuleSubjectsByTemplateRevisionId(this.checklistRevision!.templateRevision.id).catch(
        () => {
          this.toastService.openToast({
            status: 'error',
            title: `We're having problems loading the approval subject tasks`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
      );
      this.actions!.getAllTaskTemplatesByTemplateRevisionId(this.checklistRevision!.templateRevision.id).catch(() => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems loading the tasks`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
    }
  }

  public completeIfNeeded() {
    // use timeout to make sure redux stuff runs first
    this.$timeout(() => {
      if (this.state!.approvalTask.status === TaskStatus.Completed || this.state!.awaitingTasks.length === 0) {
        // TODO
        // @ts-expect-error -- TODO
        this.onComplete();
        this.$rootScope.$broadcast(TaskListEvent.SELECT_TASK_BELOW_REQUEST, this.taskTemplate);
      }
    });
  }
}

export const ApprovalsContainer: angular.IComponentOptions = {
  bindings: {
    checklistRevision: '<',
    disabled: '<',
    onComplete: '&',
    onItemSelect: '&',
    task: '<',
  },
  controller: ApprovalsContainerController,
  templateUrl,
};
