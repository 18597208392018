import { AxiosError } from 'axios';
import { CreateOneOffTaskRequest, OneOffTask } from '@process-street/subgrade/one-off-task';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace CreateOneOffTaskMutation {
  export type Params = CreateOneOffTaskRequest;

  export type Response = OneOffTask;

  export const key = ['post', 'one-off-task'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/one-off-tasks`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, CreateOneOffTaskRequest> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
