import { Box } from 'components/design/next';
import { LogoSpinner } from 'components/common/LogoSpinner';
import { useInjector } from 'components/injection-provider';
import dayjs from 'dayjs';
import { useFeatureFlagStatus } from 'features/feature-flags/use-feature-flags';
import { useGetOrganizationDetails } from 'features/organization/query-builder';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

export const SignUpGuard: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { $state } = useInjector('$state');
  const sessionOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const sessionUser = useSelector(SessionSelector.getCurrentUser);
  const featureFlagsStatus = useFeatureFlagStatus();

  const [isRedirecting, setIsRedirecting] = React.useState(false);

  const isInTheSignUpFlow = $state.current.name?.startsWith('newCompleteSignUp') ?? false;

  const isAdmin = Boolean(sessionUser && sessionUser.id === sessionOrganization?.audit.createdBy.id);

  const organizationAgeInMinutes = sessionOrganization
    ? dayjs().diff(dayjs(sessionOrganization?.audit.createdDate), 'minute')
    : Infinity;
  const isANewOrganization = organizationAgeInMinutes <= MAX_ORGANIZATION_AGE_IN_MINUTES;

  const { isLoading } = useGetOrganizationDetails(
    { organizationId: sessionOrganization?.id },
    {
      onSuccess: data => {
        if (!sessionOrganization || data !== undefined) return;

        const userEmailName = sessionUser?.email.split('@')?.[0];

        if (isANewOrganization) {
          setIsRedirecting(true);
          $state.go('newCompleteSignUp.organization', {
            organizationId: sessionOrganization.id,
            // By default, the organization name is the email username (the string before the `@`), so we only pass the org name
            // param when the organization name is not the default, allowing the user to choose a new one.
            organizationName:
              userEmailName && sessionOrganization.name.startsWith(userEmailName)
                ? undefined
                : sessionOrganization?.name,
          });
        }
      },
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: isAdmin && isANewOrganization && !isInTheSignUpFlow,
      retry: false,
    },
  );

  // We show the loading spinner until we have all the data needed to determine whether or not the user
  // should be redirected to the organization sign up flow.
  if (isLoading || featureFlagsStatus.loading || !sessionOrganization || isRedirecting)
    return (
      <Box position="fixed" inset="0" bgColor="white" zIndex="2000" paddingTop="calc(80px + var(--ps-navbar-offset))">
        <LogoSpinner />
      </Box>
    );

  return <>{children}</>;
};

// Any organization older than this value will not be considered "new", so we will not redirect the user to the organization setup flow
const MAX_ORGANIZATION_AGE_IN_MINUTES = 5;
