import { axiosService } from 'services/axios-service';
import { User } from '@process-street/subgrade/core';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export type UpdateSettingsParams = { id: User['id']; key: string; value: unknown };
export type UpdateSettingsResult = void;
export const UpdateSettings = {
  key: ['user', 'update-settings'],
  mutationFn: (params: UpdateSettingsParams) =>
    axiosService
      .getAxios()
      .patch<UpdateSettingsParams, UpdateSettingsResult>(`/1/users/${params.id}/settings/${params.key}`, params),
};
export const useUpdateSettingsMutation = (
  options: UseMutationOptions<UpdateSettingsResult, AxiosError, UpdateSettingsParams> = {},
) => {
  return useMutation<UpdateSettingsResult, AxiosError, UpdateSettingsParams>(
    UpdateSettings.key,
    UpdateSettings.mutationFn,
    options,
  );
};
