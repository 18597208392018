import React, { useEffect, useState } from 'react';
import { Folder } from '@process-street/subgrade/process';
import { LibraryItemPermissionsIndicator } from 'components/permissions/library-item-permissions-indicator/component';
import { useFoldersPermissionsStatsQuery } from 'components/permissions/services/query';
import { PermissionsStats } from 'components/permissions/models/permissions-stats';
import { LinkProps, Spinner } from 'components/design/next';

export interface FolderPermissionsIndicatorProps {
  folderId: Folder['id'];
  organizationId: Folder['organization']['id'];
  target?: LinkProps['target'];
}

export const FolderPermissionsIndicator: React.FC<React.PropsWithChildren<FolderPermissionsIndicatorProps>> = ({
  folderId,
  organizationId,
  target,
}) => {
  const [stats, setStats] = useState<PermissionsStats>();
  const { data, isLoading } = useFoldersPermissionsStatsQuery(organizationId);

  useEffect(() => {
    if (data) {
      setStats(data[folderId]);
    }
  }, [data, folderId]);

  return isLoading && !stats ? (
    <Spinner />
  ) : stats ? (
    <LibraryItemPermissionsIndicator permissionsStats={stats} target={target} />
  ) : null;
};
