import { TaskTemplate, EmbedWidget, Template } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import {
  DeleteWidgetByHeaderIdMutation,
  DeleteWidgetByHeaderIdMutationResponse,
  UpdateFormFieldValueMutationResponse,
  UpdateWidgetMutation,
  UpdateWidgetMutationResponse,
  WidgetsByTemplateRevisionIdQuery,
} from 'features/widgets/query-builder';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { ActorRefFrom, assign, createMachine, send, sendParent } from 'xstate';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { WidgetEvent } from '../../../types';
import { SharedContext } from 'pages/forms/_id/shared';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { match } from 'ts-pattern';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { WidgetMachineHelpers } from '../../../helpers/machine';

type Context = {
  widget: EmbedWidget;
  template: Template;
  initialWidget?: EmbedWidget;
  key?: string;
  recentlyMovedFrom?: TaskTemplate;
  inputNode: HTMLElement | null;
  url?: string;
  isReadOnly?: boolean;
};

type Event =
  | WidgetEvent<EmbedWidget>
  | { type: 'CHANGE'; widget: EmbedWidget }
  | { type: 'FOCUS' }
  | { type: 'BLUR' }

  // Internal type
  | { type: 'done.invoke.updateWidget'; data: EmbedWidget };

export type EmbedContentMachine = ReturnType<typeof makeEmbedContentMachine>;
export type EmbedContentActorRef = ActorRefFrom<EmbedContentMachine>;

export const makeEmbedContentMachine = ({
  widget,
  template,
  sharedContext,
  isReadOnly = false,
}: {
  widget: EmbedWidget;
  template: Template;
  sharedContext: SharedContext;
  isReadOnly?: boolean;
}) => {
  const { queryClient, templateId } = sharedContext;

  const cacheSetter = WidgetsByTemplateRevisionIdQuery.makeCacheSetter({
    queryClient,
    templateRevisionId: (widget.header.taskTemplate as TaskTemplate).templateRevision.id,
  });

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  const id = WidgetMachineHelpers.getId(widget);
  return createMachine(
    {
      id,
      initial: 'idle',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
        services: {} as {
          updateFormFieldValue: {
            data: UpdateFormFieldValueMutationResponse;
          };
          deleteWidgetMutation: {
            data: DeleteWidgetByHeaderIdMutationResponse;
          };
          updateWidgetMutation: {
            data: UpdateWidgetMutationResponse;
          };
        },
      },
      tsTypes: {} as import('./embed-content-machine.typegen').Typegen0,
      context: {
        widget,
        template,
        initialWidget: widget,
        recentlyMovedFrom: undefined,
        isReadOnly,
      } as Context,
      type: 'parallel',
      states: {
        input: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                MOVE_UP: { actions: ['sendMoveUp'] },
                MOVE_DOWN: { actions: ['sendMoveDown'] },
                DUPLICATE: { actions: ['sendDuplicate'] },
                FOCUS: { target: 'active' },
                MOVE_TO_STEP: { actions: ['sendMoveToStep'] },
                MOVED_FROM_STEP: { actions: ['assignRecentlyMovedFrom'] },
                UPDATE_WIDGET_HEADER: { actions: ['assignHeader'] },
                SET_NODE: { actions: ['assignNode'] },
                SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
              },
            },
            active: {
              on: {
                BLUR: {
                  target: 'idle',
                },
                CHANGE: {
                  target: 'idle',
                  actions: ['assignWidget', 'sendUpdateWidgetMutationEvent'],
                },
              },
            },
            error: {
              on: {
                FOCUS: {
                  target: 'active',
                },
                CHANGE: {
                  target: 'idle',
                  actions: ['assignWidget', 'sendUpdateWidgetMutationEvent'],
                },
              },
            },
          },
        },
        mutation: {
          initial: 'idle',
          states: {
            idle: {
              id: 'idle',
              on: {
                DELETE_WIDGET: {
                  target: 'deleting',
                },
                UPDATE_WIDGET: {
                  target: 'updatingWidget',
                },
              },
            },
            deleting: {
              invoke: {
                src: 'deleteWidgetMutation',
                onDone: {
                  target: 'idle',
                },
                onError: {
                  target: 'idle',
                  actions: 'logError',
                },
              },
            },
            updatingWidget: {
              invoke: {
                src: 'updateWidgetMutation',
                onDone: {
                  target: 'idle',
                },
                onError: {
                  target: 'idle',
                  actions: 'logError',
                },
              },
            },
          },
        },
      },
    },
    {
      actions: {
        logError: makeErrorLoggerAction(id),
        assignWidget: assign({
          widget: (_, e) => e.widget,
        }),
        sendMoveUp: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'up',
        })),
        sendMoveDown: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'down',
        })),
        sendDuplicate: sendParent(ctx => ({
          type: 'DUPLICATE_WIDGET',
          widget: ctx.widget,
        })),
        sendUpdateWidgetMutationEvent: send('UPDATE_WIDGET'),
        sendMoveToStep: sendParent((ctx, e) => ({
          type: 'MOVE_WIDGET_TO_STEP',
          widget: ctx.widget,
          from: e.from,
          to: e.to,
        })),
        assignHeader: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET_HEADER' }, ({ header }) => ({
                ...ctx.widget,
                header: {
                  ...ctx.widget?.header,
                  ...{ ...(header as EmbedWidget['header']) },
                },
              }))
              .otherwise(() => ctx.widget),
        }),
        assignNode: assign({ inputNode: (_, evt) => evt.node }),
        scrollIntoView: ctx => {
          WidgetMachineHelpers.scrollToWidget(ctx.widget);
        },
        assignRecentlyMovedFrom: assign({
          recentlyMovedFrom: (_ctx, e) =>
            match(e)
              .with({ type: 'MOVED_FROM_STEP' }, ({ from }) => ({
                ...from,
              }))
              .otherwise(() => undefined),
        }),
      },
      services: {
        updateWidgetMutation: async (context, evt) => {
          const widget = evt.widget ?? (context.widget as EmbedWidget);
          return makeMutation(queryClient, {
            mutationKey: UpdateWidgetMutation.getKey(),
            mutationFn: () => UpdateWidgetMutation.mutationFn<EmbedWidget>(widget!),
            onSuccess: widget => {
              cacheSetter.update(widget);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },
        deleteWidgetMutation: async context => {
          return makeMutation(queryClient, {
            mutationKey: DeleteWidgetByHeaderIdMutation.getKey(),
            mutationFn: () => DeleteWidgetByHeaderIdMutation.mutationFn(context.widget!.header.id),
            onSuccess: () => {
              cacheSetter.delete(context.widget!);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: `We're having problems deleting the widget`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          }).execute();
        },
      },
    },
  );
};
