import { Muid } from '@process-street/subgrade/core';
import { Category } from '@process-street/subgrade/process/premade-template-model';

export interface CategoryOption {
  category: Category;
  label: string;
  value: Muid;
}

const transformCategoryToCategoryOption = (category: Category) => ({
  category,
  label: category.name,
  value: category.id,
});

const transformCategoriesToCategoryOptions = (categories: Category[]) =>
  categories.map(transformCategoryToCategoryOption) as CategoryOption[];

export const UserOptionTransformer = {
  transformCategoryToCategoryOption,
  transformCategoriesToCategoryOptions,
};
