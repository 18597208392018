import * as React from 'react';
import { Alert, AlertIcon, CloseButton, Text, useDisclosure } from 'components/design/next';

export const DataSetMobileAlert = () => {
  const disclosure = useDisclosure({ defaultIsOpen: true });

  if (!disclosure.isOpen) return null;

  return (
    <Alert display={{ base: 'block', md: 'none' }} status="warning" mb={4} mt={{ base: -5, md: 0 }} position="relative">
      <AlertIcon />
      <Text mt={2}>
        This feature requires more screen space and is currently not supported on smaller devices. For a complete
        experience, please try again on a tablet or desktop.
      </Text>
      <CloseButton
        alignSelf="flex-start"
        position="absolute"
        right={1}
        top={1}
        opacity={0.5}
        onClick={disclosure.onClose}
      />
    </Alert>
  );
};
