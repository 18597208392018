import * as React from 'react';
import { Alert, AlertDescription, AlertTitle, Button, HStack, useDisclosure, VStack } from 'components/design/next';
import { Organization, SubscriptionStatus } from '@process-street/subgrade/core';
import { match } from 'ts-pattern';
import { ContactSalesModal } from 'features/contact-sales/components/contact-sales/contact-sales-modal';
import { ContactSalesService } from 'services/interop/contact-sales-service';

export type StartupPlanAlertWrapperProps = {
  organization: Organization;
};

const now = () => Math.floor(new Date().getTime() / 1000);

export const StartupPlanAlertWrapper = ({ organization }: StartupPlanAlertWrapperProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const title = match(organization.subscription.status)
    .with(SubscriptionStatus.Active, () => `You are on a legacy plan.`)
    .with(SubscriptionStatus.Trialing, () => `You're currently on a free trial of Pro.`)
    .with(SubscriptionStatus.Canceled, () => `Your free trial of Pro has expired.`)
    .otherwise(() => ``);

  const handleContactSales = () => {
    ContactSalesService.updateIntercomVariables({ company: { id: organization.id, contact_sales_click_at: now() } });
    onOpen();
  };

  return (
    <Alert borderRadius="8px" status="info">
      <HStack justify={'space-between'} w="full">
        <VStack align="baseline">
          <AlertTitle>{title}</AlertTitle>
          <AlertDescription fontSize="sm">To purchase Process Street, please&nbsp;contact&nbsp;Sales.</AlertDescription>
        </VStack>
        <Button variant="primary" size="lg" onClick={handleContactSales}>
          Contact Sales
        </Button>
        {isOpen && <ContactSalesModal onClose={onClose} selectedOrganization={organization} utm="app_billing_tab" />}
      </HStack>
    </Alert>
  );
};
