import * as React from 'react';
import { useTaskTemplateListActorRef } from 'app/pages/workflows/_id/edit-v2/hooks/use-task-templates-list-actor';
import { Button, Icon } from 'components/design/next';
import { useSelector } from '@xstate/react';
import { TaskTemplateListActorSelectors } from '../../../task-template-list-machine';

export type BulkMoveTaskDirection = 'up' | 'down';
export type BulkMoveTaskButtonProps = {
  direction: BulkMoveTaskDirection;
};
export const BulkMoveTaskButton = ({ direction }: BulkMoveTaskButtonProps) => {
  const actor = useTaskTemplateListActorRef();

  const isFirstTaskSelected = useSelector(actor, TaskTemplateListActorSelectors.isFirstTaskSelected);
  const isLastTaskSelected = useSelector(actor, TaskTemplateListActorSelectors.isLastTaskSelected);

  const isDisabled = (direction === 'up' && isFirstTaskSelected) || (direction === 'down' && isLastTaskSelected);

  const handleOnMove = () => {
    actor.send({ type: 'BULK_MOVE', direction });
  };
  const buttonText = direction === 'up' ? 'Move up' : 'Move down';
  return (
    <Button
      isDisabled={isDisabled}
      w="163px"
      display="flex"
      color="gray.500"
      justifyContent="flex-start"
      variant="tertiary"
      iconSpacing="3"
      onClick={handleOnMove}
      leftIcon={<Icon icon={direction === 'up' ? 'arrow-up' : 'arrow-down'} variant="far" size="4" color="gray.500" />}
    >
      {buttonText}
    </Button>
  );
};
