import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { AddNewEmailModal } from 'components/user/profile/AddNewEmailModal';

export const ProfileModule = angular
  .module('profile', ['ngRedux'])
  .component(
    'psAddNewEmailModal',
    reactToAngular(AddNewEmailModal, ['visible', 'passwordRequired', 'onCancel', 'onAdd']),
  ).name;
