import { AuditMetadata, Muid, MuidConverter, MuidUtils, UserType } from '@process-street/subgrade/core';
import {
  ChecklistColumn,
  Clause,
  Condition,
  ConditionalFilterUtils,
  FilterType,
  ProgressStatus,
} from '@process-street/subgrade/dashboard';
import { generateAudit } from '@process-street/subgrade/test';
import { MemberItem } from 'components/common/MemberOption';
import { Filtering, Sorting } from 'components/dashboard/models/filters';
import { ColumnOrderMap, ColumnSizeMap } from 'components/dashboard/models/grid';
import { CustomIconName } from 'components/design/next/icon/icon-name';

export interface SavedView {
  accessLevel: SavedViewAccessLevel;
  audit: AuditMetadata;
  organizationMembershipIds?: Muid[];
  columnsConfig: ColumnsConfig;
  filters: Filtering;
  id: Muid;
  name: string;
  organizationId: Muid;
  predefined: boolean;
  saved?: boolean;
  sourceSavedViewId?: Muid;
  folderId?: SavedViewFolder['id'];
}

export interface SavedViewFolder {
  id: Muid;
  audit: AuditMetadata;
  name: string;
}

export interface ColumnsConfig {
  visibleIds?: string[];
  orderMap?: ColumnOrderMap;
  sizeMap?: ColumnSizeMap;
  sorting?: Sorting;
}

export enum SavedViewAccessLevel {
  IndividualsAndGroups = 'IndividualsAndGroups',
  Organization = 'Organization',
  Private = 'Private',
}

export const accessLevelToIconMap: { [key in SavedViewAccessLevel]: CustomIconName } = {
  [SavedViewAccessLevel.IndividualsAndGroups]: 'users',
  [SavedViewAccessLevel.Organization]: 'globe',
  [SavedViewAccessLevel.Private]: 'lock',
};

export const accessLevelToLabelMap: { [key in SavedViewAccessLevel]: string } = {
  [SavedViewAccessLevel.IndividualsAndGroups]: 'Individuals & Groups',
  [SavedViewAccessLevel.Organization]: 'Organization',
  [SavedViewAccessLevel.Private]: 'Private',
};

export const accessLevelToDescriptionMap: { [key in SavedViewAccessLevel]: string } = {
  [SavedViewAccessLevel.IndividualsAndGroups]: 'Visible to admins and any users or groups you invite',
  [SavedViewAccessLevel.Organization]: 'Visible to everyone in your organization',
  [SavedViewAccessLevel.Private]: 'Only visible to you',
};

const organizationId = MuidUtils.randomMuid();

const rootId = MuidUtils.randomMuid();
const myActiveChecklistsFiltering: Filtering = {
  conditionalFilter: {
    children: [
      {
        columnName: ChecklistColumn.ProgressStatus,
        condition: Condition.DoesNotContain,
        filterType: FilterType.Clause,
        id: MuidUtils.randomMuid(),
        operand: ConditionalFilterUtils.getProgressStatusListOperand([ProgressStatus.Completed]),
        parentId: rootId,
      } as Clause,
      /*
      {
        columnName: ChecklistColumn.Assignees,
        condition: Condition.Contains,
        filterType: FilterType.Clause,
        id: MuidUtils.randomMuid(),
        operand: getMuidListOperand([userId]),
        parentId: rootId,
      } as Clause,
*/
    ],
    filterType: FilterType.And,
    id: rootId,
  },
  searchPattern: '',
  selectedTemplates: [],
};

export const allNotArchivedChecklistsFiltering: Filtering = {
  conditionalFilter: {
    children: [
      {
        columnName: ChecklistColumn.ProgressStatus,
        condition: Condition.DoesNotContain,
        filterType: FilterType.Clause,
        id: MuidUtils.randomMuid(),
        operand: ConditionalFilterUtils.getProgressStatusListOperand([ProgressStatus.Archived]),
        parentId: rootId,
      } as Clause,
    ],
    filterType: FilterType.And,
    id: rootId,
  },
  searchPattern: '',
  selectedTemplates: [],
};

export const allActiveChecklistsFiltering: Filtering = {
  conditionalFilter: {
    children: [
      {
        columnName: ChecklistColumn.ProgressStatus,
        condition: Condition.DoesNotContain,
        filterType: FilterType.Clause,
        id: MuidUtils.randomMuid(),
        operand: ConditionalFilterUtils.getProgressStatusListOperand([
          ProgressStatus.Completed,
          ProgressStatus.Archived,
        ]),
        parentId: rootId,
      } as Clause,
    ],
    filterType: FilterType.And,
    id: rootId,
  },
  searchPattern: '',
  selectedTemplates: [],
};

const recentlyCompletedFiltering: Filtering = {
  conditionalFilter: {
    children: [
      {
        columnName: ChecklistColumn.ProgressStatus,
        condition: Condition.Contains,
        filterType: FilterType.Clause,
        id: MuidUtils.randomMuid(),
        operand: ConditionalFilterUtils.getProgressStatusListOperand([ProgressStatus.Completed]),
        parentId: rootId,
      } as Clause,
    ],
    filterType: FilterType.And,
    id: rootId,
  },
  searchPattern: '',
  selectedTemplates: [],
};

const oldestOverdueFiltering: Filtering = {
  conditionalFilter: {
    children: [
      {
        columnName: ChecklistColumn.ProgressStatus,
        condition: Condition.Contains,
        filterType: FilterType.Clause,
        id: MuidUtils.randomMuid(),
        operand: ConditionalFilterUtils.getProgressStatusListOperand([ProgressStatus.Overdue]),
        parentId: rootId,
      } as Clause,
    ],
    filterType: FilterType.And,
    id: rootId,
  },
  searchPattern: '',
  selectedTemplates: [],
};

export const predefinedViews: SavedView[] = [
  {
    accessLevel: SavedViewAccessLevel.Private,
    audit: generateAudit(),
    columnsConfig: {},
    filters: myActiveChecklistsFiltering,
    id: MuidUtils.randomMuid(),
    name: 'My Active Checklists',
    organizationId,
    organizationMembershipIds: [],
    predefined: true,
  },
  {
    accessLevel: SavedViewAccessLevel.Organization,
    audit: generateAudit(),
    columnsConfig: {},
    filters: allActiveChecklistsFiltering,
    id: MuidUtils.randomMuid(),
    name: 'All Active Checklists',
    organizationId,
    organizationMembershipIds: [],
    predefined: true,
  },
  {
    accessLevel: SavedViewAccessLevel.Organization,
    audit: generateAudit(),
    columnsConfig: {},
    filters: recentlyCompletedFiltering,
    id: MuidUtils.randomMuid(),
    name: 'Recently Completed',
    organizationId,
    organizationMembershipIds: [],
    predefined: true,
  },
  {
    accessLevel: SavedViewAccessLevel.Organization,
    audit: generateAudit(),
    columnsConfig: {},
    filters: oldestOverdueFiltering,
    id: MuidUtils.randomMuid(),
    name: 'Oldest Overdue',
    organizationId,
    organizationMembershipIds: [],
    predefined: true,
  },
];

const initialFiltering: Filtering = {
  conditionalFilter: allActiveChecklistsFiltering.conditionalFilter,
  searchPattern: '',
  selectedTemplates: [],
};

export const getNewSavedView = (
  partial: Partial<SavedView> = {},
  createdById: Muid = MuidUtils.randomMuid(),
): SavedView => ({
  accessLevel: SavedViewAccessLevel.Private,
  audit: generateAudit(createdById),
  columnsConfig: {},
  filters: initialFiltering,
  id: MuidUtils.randomMuid(),
  name: '',
  organizationId,
  organizationMembershipIds: [],
  predefined: false,
  saved: false,
  ...partial,
});

export const savedViewCurrentUserId: Muid = MuidConverter.fromUuid('00000000-0000-0000-CAFE-000000000000');
export const currentUserMemberItem: MemberItem = {
  email: '',
  id: savedViewCurrentUserId,
  initials: 'CU',
  name: 'Current User',
  organizationMembershipId: savedViewCurrentUserId,
  userType: UserType.Group,
};
