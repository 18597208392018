import { DerivedChecklistStatusStat, DerivedChecklistStatus as Status } from '@process-street/subgrade/dashboard';
import { Muid } from '@process-street/subgrade/core';
import { makeTooltipContext } from '../chart-tooltip';
import { BarStackBar, LegendStat } from '../common/types';

export type ChecklistStat = DerivedChecklistStatusStat & { userId: Muid };

export type ChecklistStatusBar = BarStackBar<ChecklistStat, Status>;

export type TooltipData = ChecklistStatusBar | LegendStat<Status> | { y: number };

const {
  tooltipReducer,
  makeInitialTooltipState,
  usableTooltipContext: usableContext,
} = makeTooltipContext<TooltipData>();

export { tooltipReducer, makeInitialTooltipState };

export const [useTooltipEffect, TooltipEffectContext] = usableContext;
