import * as React from 'react';
import { useFormEditorPageActorRef } from '../../form-editor-page-machine';
import { useInsertWidgetDragStatusStore } from '../insert-widget/insert-widget-drag-status-store';
import { useDropOnWidgetListItem } from './use-drop-on-widget-list-item';
import { Box, BoxProps } from '@chakra-ui/react';
import { Muid } from '@process-street/subgrade/core';
import { FormEditorPageActorSelectors } from '../../form-editor-page-machine/form-editor-page-machine-selectors';
import { TemplateType } from '@process-street/subgrade/process';
import { useSelector } from '@xstate/react';

const useElementsSizes = ({
  element,
  isForm,
  isOver,
}: {
  element: HTMLElement | null;
  isForm: boolean;
  isOver: boolean;
}) => {
  const siblingRect = element?.previousElementSibling?.getBoundingClientRect();
  const siblingWidth = siblingRect?.width;
  const [parentRect, setParentRect] = React.useState<DOMRect | null>(null);

  const containerLeftOffset = React.useMemo(() => {
    if (isForm || !element) return 0;

    const parentLeftOffset = parentRect?.left ?? 0;
    const taskContainerElement = document.getElementById('tasks-container');

    return parentLeftOffset - (taskContainerElement?.getBoundingClientRect()?.left ?? 0);
    //  We need to keep `isOver` in the dependency list so the hook updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForm, element, isOver, parentRect]);

  const containerPadding = 36;
  const indicatorLeftOffset = containerLeftOffset + containerPadding;

  React.useEffect(() => {
    requestAnimationFrame(() => {
      const parentRect = element?.parentElement?.getBoundingClientRect();
      setParentRect(parentRect ?? null);
    });
  }, [element]);

  return {
    indicatorLeftOffset,
    siblingWidth,
  };
};

export const TopDropzone = ({ taskTemplateId, ...props }: BoxProps & { taskTemplateId: Muid }) => {
  const actor = useFormEditorPageActorRef();
  const isForm = useSelector(actor, FormEditorPageActorSelectors.getTemplate)?.templateType === TemplateType.Form;
  const dragStatuses = useInsertWidgetDragStatusStore().statuses;
  const isDragging = React.useMemo(() => {
    return Object.values(dragStatuses).some(({ isDragging }) => isDragging);
  }, [dragStatuses]);

  const {
    dropResult: { isOver },
    ref: widgetDropRef,
  } = useDropOnWidgetListItem({ actor, area: 'top', taskTemplateId });

  const { indicatorLeftOffset, siblingWidth } = useElementsSizes({
    element: widgetDropRef.current,
    isForm,
    isOver,
  });

  return (
    <Box
      ref={widgetDropRef}
      zIndex={isDragging ? 'tooltip' : -1}
      pointerEvents={isDragging ? 'all' : 'none'}
      position="absolute"
      w="full"
      h={isForm ? '125px' : '200px'}
      left="0"
      top="0"
      display="flex"
      alignItems="flex-end"
      {...props}
    >
      {isOver && (
        <Box
          h="2"
          position="absolute"
          bg="brand.200"
          w="full"
          left={`${indicatorLeftOffset}px`}
          maxW={siblingWidth ? `${siblingWidth}px` : undefined}
        />
      )}
    </Box>
  );
};

export const BottomDropzone = ({ taskTemplateId, ...props }: BoxProps & { taskTemplateId: Muid }) => {
  const actor = useFormEditorPageActorRef();
  const isForm = useSelector(actor, FormEditorPageActorSelectors.getTemplate)?.templateType === TemplateType.Form;
  const dragStatuses = useInsertWidgetDragStatusStore().statuses;
  const isDragging = React.useMemo(() => {
    return Object.values(dragStatuses).some(({ isDragging }) => isDragging);
  }, [dragStatuses]);

  const {
    dropResult: { isOver },
    ref: widgetDropRef,
  } = useDropOnWidgetListItem({ actor, area: 'bottom', taskTemplateId });

  const { indicatorLeftOffset, siblingWidth } = useElementsSizes({
    element: widgetDropRef.current,
    isForm,
    isOver,
  });

  return (
    <Box
      ref={widgetDropRef}
      zIndex={isDragging ? 'tooltip' : -1}
      pointerEvents={isDragging ? 'all' : 'none'}
      position="absolute"
      w={'full'}
      h="80px"
      left="0"
      mt="-36px"
      display="flex"
      alignItems="flex-start"
      {...props}
    >
      {isOver && (
        <Box
          h="2"
          position="absolute"
          bg="brand.200"
          w="full"
          left={`${indicatorLeftOffset}px`}
          maxW={siblingWidth ? `${siblingWidth}px` : undefined}
        />
      )}
    </Box>
  );
};
