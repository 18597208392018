import * as React from 'react';
import { Button, Image, Link, Flex, Text } from 'components/design/next';
import { Organization } from '@process-street/subgrade/core';
import { SignUpHeader } from '../signup-header';
import { SignUpFooter } from '../signup-footer';

export type SignupSelectorProps = {
  onEmailSignUp: () => void;
  onGoogleSignUp: () => void;
  onSignIn: () => void;
  organization: Organization;
};

export const SignUpSelector: React.FC<React.PropsWithChildren<SignupSelectorProps>> = ({
  onEmailSignUp,
  onGoogleSignUp,
  onSignIn,
  organization,
}) => {
  return (
    <Flex alignItems="center" direction="column">
      <SignUpHeader organization={organization} />

      <Button variant="outline" colorScheme="gray" w="80" mt={10} onClick={onGoogleSignUp}>
        <Image src={require('app/images/providers/google-logo.svg')} boxSize="20px" mr="2" />
        <Text color="gray.600">Sign-up with Google</Text>
      </Button>

      <Text color="gray.500" mt={4}>
        or
      </Text>

      <Link mt={4} color="brand.500" onClick={onEmailSignUp}>
        Sign up with email
      </Link>

      <Text color="gray.500" mt={10}>
        Already have an account?{' '}
        <Link color="brand.500" onClick={onSignIn}>
          Sign in.
        </Link>
      </Text>

      <SignUpFooter />
    </Flex>
  );
};
