import * as React from 'react';
import { forwardRef, Text, TextProps } from 'components/design/next';

type NotEnoughDataProps = TextProps;

export const NotEnoughData = forwardRef<NotEnoughDataProps, 'p'>((props, ref) => {
  return (
    <Text ref={ref} color="gray.400" variant="-1" py="4" textAlign="center" {...props}>
      Not enough data yet
    </Text>
  );
});
