import { Muid } from '@process-street/subgrade/core';
import { Widget } from '@process-street/subgrade/process';
import { axiosService } from 'services/axios-service';
import { QueryClient, QueryObserver, QueryObserverOptions, useQuery as useRQ, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';

export namespace GetWidgetByTaskTemplateIdQuery {
  export type Params = { taskTemplateId?: Muid };

  export type Response = Widget[];

  export const key = ['task-templates', 'widgets'];
  export const getKey = (params: Params) => [...key, params.taskTemplateId];
  export const queryFn = ({ taskTemplateId }: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/task-templates/${taskTemplateId}/widgets`)
      .then(r => r.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ({
      queryKey: getKey(params),
      queryFn: () => queryFn(params),
      ...options,
      enabled: Boolean(params.taskTemplateId),
    });
  };

  export const makeQueryObserver = ({
    queryClient,
    options,
    ...params
  }: GetWidgetByTaskTemplateIdQuery.Params & {
    queryClient: QueryClient;
    options?: QueryObserverOptions<GetWidgetByTaskTemplateIdQuery.Response, AxiosError>;
  }) => {
    return new QueryObserver<GetWidgetByTaskTemplateIdQuery.Response, AxiosError>(queryClient, {
      ...options,
      queryKey: GetWidgetByTaskTemplateIdQuery.getKey(params),
      queryFn: () => GetWidgetByTaskTemplateIdQuery.queryFn(params),
      enabled: Boolean(params.taskTemplateId) && options?.enabled !== false,
    });
  };
}
