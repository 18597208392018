import { PlanInterval, PlanLevel, PlanLevelUtil } from '@process-street/subgrade/billing';
import {
  calculatePercentageDiscount,
  calculatePercentDiscountedCost,
  calculateRawAdditionalMemberCost,
  calculateRawPlanCost,
  isPlanFree,
  isPlanLevelFree,
} from '@process-street/subgrade/billing/plan-utils';
import { SubscriptionStatus } from '@process-street/subgrade/core';
import angular from 'angular';
import Encase from 'encase';
import { connectService } from 'reducers/util';
import { FreeTrialPlans } from 'services/plans';
import { isPlanIdLegacy, toDescriptor, findDescriptor } from 'utils/plans';

angular.module('frontStreetApp.services').service('PlanService', function ($filter, $ngRedux, PlanActions) {
  const self = this;

  connectService('PlanService', $ngRedux, null, PlanActions)(self);

  /**
   * Checks whether a plan id is a legacy plan.
   *
   * @param {string} planId
   * @returns {boolean}
   */
  self.isPlanIdLegacy = isPlanIdLegacy;

  /**
   * Finds a plan descriptor for a given plan id.
   *
   * @param {string} planId
   */
  self.findDescriptor = findDescriptor;

  /**
   * Returns non legacy plan descriptor by plan id or default plan descriptor
   * @param {string} planId
   * @returns {{}}
   */
  self.findDescriptorOrGetDefault = function (planId) {
    return self.findDescriptor(planId) ?? toDescriptor(FreeTrialPlans);
  };

  /**
   * Returns plan by id
   *
   * @param {string} id
   * @returns {Promise}
   */
  self.getById = function (id) {
    return self.actions.getById(id).then(({ payload: plan }) => plan);
  };

  /**
   * Returns all plans
   *
   * @param {boolean} flushCache
   * @returns {Promise}
   */
  self.getAll = flushCache => this.actions.getAll(flushCache).then(({ payload }) => payload);

  /**
   * @typedef {Object} PlanCost
   * @property {number} rawCost Plan cost including discount
   * @property {number} rawTotalCost rawCost * members
   * @property {number} cost Formatted rawCost
   * @property {number} totalCost Formatted rawTotalCost
   */

  self.calculateDiscount = calculatePercentageDiscount;

  self.calculateDiscountedCost = calculatePercentDiscountedCost;

  /**
   *
   * @param {Plan} plan
   * @param {number} quantity
   * @param {number} discount
   * @param {Organization} organization
   * @returns {PlanCost}
   */
  self.calculatePlanCost = function (plan, quantity, discount, organization) {
    const { planCost, totalPlanCost } = calculateRawPlanCost(
      plan,
      this.calculateBilledQuantity(plan, quantity, organization),
      discount,
    );

    const formattedPlanCost = $filter('number')(planCost, 2);
    const formattedTotalPlanCost = $filter('number')(totalPlanCost, 2);

    return {
      rawCost: planCost,
      rawTotalCost: totalPlanCost,
      cost: formattedPlanCost,
      totalCost: formattedTotalPlanCost,
    };
  };

  self.calculateBilledQuantity = function (plan, quantity, organization) {
    const actualMinQuantity = organization?.minQuantity ?? plan.minQuantity ?? 0;
    return Math.max(actualMinQuantity, quantity);
  };

  self.calculateAdditionalMemberCost = function (plan, discount, quantity) {
    const additionalCost = calculateRawAdditionalMemberCost(plan, discount, quantity);
    return $filter('number')(additionalCost, 2);
  };

  /**
   * Returns true if plan is free
   *
   * @param {Plan} plan
   * @returns {boolean}
   */
  self.isPlanFree = isPlanFree;

  /**
   * Returns true is plan level is free
   *
   * @param {PlanLevel} planLevel
   * @returns {boolean}
   */
  self.isPlanLevelFree = isPlanLevelFree;

  /**
   * Returns true is plan level is enterprise
   *
   * @param {PlanLevel} planLevel
   * @returns {boolean}
   */
  self.isPlanLevelEnterprise = function (planLevel) {
    return planLevel === PlanLevel.Enterprise;
  };

  /**
   *
   * @param {Plan} plan
   * @param {SubscriptionStatus} subscriptionStatus
   * @returns {boolean}
   */
  self.isPaidAccount = function (plan, subscriptionStatus) {
    return !(
      plan.level === PlanLevel.Free ||
      (subscriptionStatus === SubscriptionStatus.Trialing && plan.level != PlanLevel.Enterprise)
    );
  };

  /**
   * Returns title for plan level. E.g. BusinessPro => 'Business Pro'
   *
   * @param {PlanLevel} level
   * @returns {string}
   */
  self.getTitleForPlanLevel = level => Encase.separate(level).join(' ');

  /**
   * Checks whether one plan level ordered after another
   *
   * @param {PlanLevel} comparingLevel
   * @param {PlanLevel} toLevel
   * @returns {boolean}
   */
  self.isPlanLevelAfter = function (comparingLevel, toLevel) {
    return PlanLevelUtil.rank(comparingLevel) > PlanLevelUtil.rank(toLevel);
  };

  /**
   * Returns all plan levels
   *
   * @returns [PlanLevel]
   */
  self.getAllPlanLevels = function () {
    const planLevels = [];

    PlanLevelUtil.all().forEach(name => {
      planLevels.push(name);
    });

    return planLevels;
  };

  /**
   * Returns name of a plan
   *
   * @param {Plan} plan
   * @returns {string}
   */
  self.getDisplayName = function (plan) {
    return plan.active ? self.getTitleForPlanLevel(plan.level) : plan.name;
  };

  /**
   * Returns base word of a interval
   *
   * @param {PlanInterval} interval
   * @returns {string}
   */
  self.convertPlanIntervalToWord = function (interval) {
    return interval === PlanInterval.Monthly ? 'month' : 'year';
  };
});
