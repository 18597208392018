import { ComponentMultiStyleConfig, createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/popover.ts
const $arrowBg = cssVar('popper-arrow-bg');

const helpers = createMultiStyleConfigHelpers(['popper', 'content']);

export const Popover: Partial<ComponentMultiStyleConfig> = helpers.defineMultiStyleConfig({
  sizes: {
    auto: {
      popper: {
        width: 'auto',
      },
    },
    sm: {
      popper: {
        maxW: 'sm',
      },
    },
    md: {
      popper: {
        maxW: 'md',
      },
    },
    lg: {
      popper: {
        maxW: 'lg',
      },
    },
  },
  baseStyle: {
    content: {
      boxShadow: 'xl',
      zIndex: 'popover',
    },
  },
  variants: {
    'tooltip-dark': {
      content: {
        'bg': 'gray.700',
        'color': 'white',
        'borderRadius': 'lg',
        'zIndex': 'popover',
        [$arrowBg.variable]: 'colors.gray.700',
        '& a': {
          textDecor: 'underline',
          color: 'white',
          _hover: {
            textDecor: 'underline',
            color: 'brand.200',
          },
        },
      },
      arrow: {
        bgColor: 'gray.700',
      },
    },
  },
});
