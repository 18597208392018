import * as React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { MergeTagMode, MergeTagTarget } from '@process-street/subgrade/form';
import { MergeTagsServiceUtils } from 'services/merge-tags';
import { useResolvedMergeTagsByChecklistRevisionIdQuery } from 'features/merge-tags/query-builder';
import { useFeatureFlags } from 'features/feature-flags';

interface UseParseChecklistContentByTargetProps {
  checklistRevisionId?: Muid;
  taskId?: Muid;
  mergeTagTarget: MergeTagTarget;
}

export const useParseChecklistMergeTagsByTarget = ({
  mergeTagTarget,
  taskId,
  checklistRevisionId,
}: UseParseChecklistContentByTargetProps) => {
  const ffs = useFeatureFlags('longTextFieldMarkdown');

  const resolvedTagsQuery = useResolvedMergeTagsByChecklistRevisionIdQuery({
    checklistRevisionId,
    taskId,
    mergeTagTarget,
  });

  return React.useCallback(
    ({ content, mode }: { content: string; mode: MergeTagMode }) => {
      const resolvedTags = resolvedTagsQuery.data ?? [];
      if (!resolvedTags) {
        return content;
      }
      switch (mode) {
        case MergeTagMode.PLAINTEXT: {
          return MergeTagsServiceUtils.replaceResolvedTagsWithPlaintextValues(resolvedTags, content);
        }
        case MergeTagMode.HTML: {
          return MergeTagsServiceUtils.replaceResolvedTagsWithHtmlValues(resolvedTags, content, {
            isMarkdownEnabled: ffs.longTextFieldMarkdown,
          });
        }
        default:
          throw new Error(`unsupported mode: ${mode}`);
      }
    },
    [ffs.longTextFieldMarkdown, resolvedTagsQuery.data],
  );
};
