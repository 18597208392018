import angular from 'angular';
import OverlayScrollbars from 'overlayscrollbars';

angular.module('frontStreetApp.directives').directive('psScroller', () => ({
  transclude: true,
  priority: 1000,
  template: '<div class="nano"><div class="nano-content" data-ng-transclude></div></div>',
  link(scope, element) {
    const instance = OverlayScrollbars(element, {
      sizeAutoCapable: true,
      paddingAbsolute: true,
      scrollbars: {
        autoHide: 'leave',
        clickScrolling: true,
      },
    });

    scope.$on('$destroy', () => {
      instance.destroy();
    });
  },
}));
