import { Muid } from '@process-street/subgrade/core';
import { useInjector } from 'components/injection-provider';
import { GetAllRulesByTemplateRevisionIdQuery } from 'features/conditional-logic/query-builder';
import { TaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { WidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { EventName } from 'services/event-name';
import { useDebouncedCallback } from 'use-debounce';

type UseSyncTemplateEditor = { templateRevisionId: Muid; isModalOpen: boolean };

/* This hook listens to the template editor changes and invalidate the conditional logic queries
  so we have updated data in the CL editor */
export const useSyncTemplateEditor = ({ templateRevisionId, isModalOpen }: UseSyncTemplateEditor) => {
  const { $rootScope } = useInjector('$rootScope');
  const queryClient = useQueryClient();

  const invalidateWidgets = () =>
    queryClient.invalidateQueries(WidgetsByTemplateRevisionIdQuery.getKey(templateRevisionId));
  const debouncedInvalidateWidgets = useDebouncedCallback(invalidateWidgets, 500);

  const invalidateTaskTemplates = () =>
    queryClient.invalidateQueries(TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }));
  const debouncedInvalidateTaskTemplates = useDebouncedCallback(invalidateTaskTemplates, 500);

  const invalidateRules = () =>
    queryClient.invalidateQueries(GetAllRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId }));

  useEffect(() => {
    const unsubs = [
      $rootScope.$on(EventName.WIDGET_CREATE_OK, invalidateWidgets),
      $rootScope.$on(EventName.WIDGET_UPDATE_OK, debouncedInvalidateWidgets),
      $rootScope.$on(EventName.WIDGET_DELETE_OK, invalidateWidgets),
      $rootScope.$on(EventName.TASK_TEMPLATE_CREATE_OK, invalidateTaskTemplates),
      $rootScope.$on(EventName.TASK_TEMPLATE_UPDATE_OK, debouncedInvalidateTaskTemplates),
      $rootScope.$on(EventName.TASK_TEMPLATE_BULK_DELETE_OK, invalidateTaskTemplates),
    ];

    return () => unsubs.forEach(unsub => unsub());
    // eslint-disable-next-line react-hooks/exhaustive-deps -- subscribe once for $rootScope
  }, [$rootScope, debouncedInvalidateWidgets]);

  useEffect(() => {
    if (isModalOpen) invalidateRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- react to isModalOpen
  }, [isModalOpen]);
};
