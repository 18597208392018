import { Plan } from '../billing';
import { Muid, Ref, S3File } from './core-model';
import {
  Organization,
  OrganizationMembership,
  OrganizationMembershipRole,
  OrganizationMembershipStatus,
  OrganizationTheme,
} from './organization-model';

export interface AuditMetadata {
  createdDate: number;
  createdBy: Ref<User>;
  updatedDate: number;
  updatedBy: Ref<User>;
}

export enum IdentityProvider {
  ADFS = 'ADFS',
  ProcessStreet = 'ProcessStreet',
  Google = 'Google',
  WAAD = 'WAAD',
  SAMLP = 'SAMPL',
}

export interface UserProfile {
  username: string;
  avatarFile?: Ref<S3File>;
  avatarUrl?: string;
  telephoneNumber?: string;
  whatIDo?: string;
  timeZone: string;
}

export enum UserStatus {
  Created = 'Created',
  Active = 'Active',
  Deleted = 'Deleted',
}

export interface UserEmail {
  readonly id: Muid;
  audit: AuditMetadata;
  user: Ref<User>;
  email: string;
  emailVerified: boolean;
  emailVerificationCode: Muid;
  emailVerificationSentDate?: number;
}

export enum UserType {
  Standard = 'Standard',
  Anonymous = 'Anonymous',
  Api = 'Api',
  Group = 'Group',
  Sandbox = 'Sandbox',
}

export enum UserOrigin {
  FrontStreet = 'FrontStreet',
  Invitation = 'Invitation',
  PublicStreet = 'PublicStreet',
  SharedTemplate = 'SharedTemplate',
}

export enum UserOwnership {
  User = 'User',
  Organization = 'Organization',
}

export interface User extends UserProfile {
  readonly id: Muid;
  audit: AuditMetadata;
  primaryUserEmail: Ref<UserEmail>;
  email: string;
  emailVerified: boolean;
  emailVerificationCodeId: Muid;
  emailVerificationSentDate?: number;
  password?: string;
  source?: string;
  origin: UserOrigin;
  signUpProvider: IdentityProvider;
  status: UserStatus;
  developer: boolean;
  beta: boolean;
  userType: UserType;
  idpId?: string;
  ownership: UserOwnership;
}

export interface InviteeUser extends User {
  passwordIsSet: false;
  loginRequired: boolean;
  mustLoginFromDate: number;
}

export interface CreatedUser extends User {
  status: UserStatus.Created;
}

export function isCreatedUser(user: User): user is CreatedUser {
  return user.status === UserStatus.Created;
}

type DeviceType = 'Android' | 'iPhone' | 'Web';

export interface UserPushToken {
  readonly id: Muid;
  user: Ref<User>;
  deviceType: DeviceType;
  deviceToken: string;
  arn: string;
}

export interface UserSettings {
  readonly id: Muid;
  user: Ref<User>;
  settings: { [key: string]: string };
  selectedOrganizationId?: Muid;
}

export interface OrphanUserInfo {
  user: User;
  identityProvider: IdentityProvider;
}

export interface BaseUserInfo {
  user: User;
  identityProvider: IdentityProvider;
  userSettings: { organizationUseFrequencyMap?: Record<Muid, number>; [key: string]: unknown };
  /**
   * @deprecated **This field is NOT actually actually deprecated** but you need to be very careful about using it, so we want the strikethrough text effect to remind you.
   *
   * It actually represents the _last_ selected organization and is loaded at startup.
   * Since we want to support multiple organizations in different browsers/windows we can't rely on fetching this using, react-query for example,
   * because server side data might not necessarily the same as the one that the client has selected in different browsers.
   *
   * You probably need to use {@link SessionSelector.getSelectedOrganization}  as this is client-side state.
   */
  selectedOrganization: Organization;
  /**
   * @deprecated **This field is NOT actually actually deprecated** but you need to be very careful about using it, so we want the strikethrough text effect to remind you.
   *
   * It actually represents the plan of the _last_ selected organization and is loaded at startup.
   * Since we want to support multiple organizations in different browsers/windows we can't rely on fetching this using, react-query for example,
   * because server side data might not necessarily the same as the one that the client has selected in different browsers.
   *
   * You probably need to use {@link SessionSelector.getCurrentPlan} as this is client-side state.
   */
  selectedPlan: Plan;

  organizationSubdomainsMap: { [key: Organization['id']]: string };
}

/** User info object received upon login. */
export interface CompleteLoginUserInfo extends BaseUserInfo {
  organizationMembership: OrganizationMembership;
  elevioHash: string;
  intercomHash: string;
}

export interface UserInfo {
  user: User;
  organizationMembership: OrganizationMembership;
}

export interface CreatedUserInfo {
  elevioUserHash: string;
  intercomUserHash: string;
  user: CreatedUser;
}

export interface AnonymousUserInfo extends BaseUserInfo {
  token: string;
  profile: Profile;
}

export interface InvitationUserInfo {
  organization: Organization;
  inviteeUser: User;
  provider: IdentityProvider;
  accepted: boolean;
  id: Muid;
  audit: AuditMetadata;
  workflowRunName?: string;
  invitationSentDate: number;
  ipAddress: string;
  role: OrganizationMembershipRole;
  theme?: OrganizationTheme;
}

export interface SignUpResult {
  organization: Organization;
  user: User;
  organizationCreated: boolean;
}

export interface Profile {
  userId: Muid;
  email: string;
  username: string;
  provider: IdentityProvider;
  checklistId?: Muid;
  templateId?: Muid;
}

export interface Avatar {
  initials: string;
  title: string;
  url: string;
  unconfirmed: boolean;
}

export interface UserDetails {
  id: Muid;
  audit: AuditMetadata;
  userId: User['id'];
  organizationId: Organization['id'];
  referral?: string;
  department?: string;
  useCase?: string;
  hasScheduledDemo?: boolean;
}

export const USE_CASE_OPTIONS = [
  'Client Management',
  'Client Onboarding',
  'Compliance Standards (ISO)',
  'Employee Experience',
  'Employee Onboarding',
  'Financial Audits/Checks',
  'Financial Investment Management',
  'Property Management',
  'Quality Assurance',
  'Other',
] as const;

export type UserDetailUseCase = typeof USE_CASE_OPTIONS[number];

export const shouldShowUser = (
  user: User,
  organizationMembershipMap: Record<User['id'], OrganizationMembership>,
  isDeactivationEnabled: boolean,
) => {
  if (!isDeactivationEnabled) return true;

  return organizationMembershipMap?.[user.id]?.status === OrganizationMembershipStatus.Active;
};

export const getAvatarUrl = (user: User, size: number = 32) => {
  if (!user.avatarFile) return;

  return `https://aang.s3.amazonaws.com/${user.avatarFile.id}-${size}.jpg`;
};
