import angular from 'angular';
import { RoleAssignmentRules } from './api/role-assignment-rules.resource';
import { RoleAssignmentRulesActions } from './store/role-assignment-rules.actions';
import { RoleAssignmentRulesApi } from './api/role-assignment-rules.api';
import { RoleAssignmentRuleAvatarComponent } from './avatar/role-assignment-rule-avatar.component';
import { RoleAssignmentUpgradeComponent } from './upgrade/role-assignment-upgrade.component';

export const RoleAssignmentsModule = angular
  .module('roleBasedAssignments', [])
  .factory('RoleAssignmentRules', RoleAssignmentRules)
  .service('RoleAssignmentRulesApi', RoleAssignmentRulesApi)
  .factory('RoleAssignmentRulesActions', RoleAssignmentRulesActions)
  .component('psRoleAssignmentRuleAvatar', RoleAssignmentRuleAvatarComponent)
  .component('psRoleAssignmentUpgrade', RoleAssignmentUpgradeComponent).name;
