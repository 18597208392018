import angular from 'angular';
import { CookieService } from 'features/cookies/cookie-service';
import { dayjs as moment } from '@process-street/subgrade/util';
import { trace } from 'components/trace';

angular.module('frontStreetApp.services').service('GrowSumoService', function ($window) {
  const logger = trace({ name: 'GrowSumoService' });

  const self = this;

  self.signUp = function (organization) {
    if (!organization) {
      return;
    }

    $window.growsumo.data.customer_key = organization.id;
    $window.growsumo.data.name = organization.name;
    $window.growsumo.data.email = organization.email;
    $window.growsumo.createSignup();
  };

  /**
   * The name of the partner key cookie.
   * This was found in this article:
   * https://educate.growsumo.com/company-knowledge-base/integrations/integration-overview
   *
   * @type {string}
   */
  const PARTNER_KEY_COOKIE_KEY = 'growSumoPartnerKey';

  /**
   * Sets the partner key cookie if it does not exist.
   *
   * @param {string} partnerKey The GrowSumo partner key (ignored if undefined)
   */
  self.setPartnerKeyCookie = function (partnerKey) {
    if (!partnerKey) {
      return;
    }

    const cookie = CookieService.getSimpleCookie(PARTNER_KEY_COOKIE_KEY);
    if (cookie) {
      logger.info('GrowSumo partner key already set to "%s"', cookie);
    } else {
      logger.info('Setting GrowSumo partner key to "%s"', partnerKey);

      CookieService.setSimpleCookie(PARTNER_KEY_COOKIE_KEY, partnerKey, {
        expires: moment().add(90, 'days').toDate(),
      });
    }
  };
});
