import {
  Box,
  Flex,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
  Image,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Textarea,
  ModalFooter,
  HStack,
  Button,
  useToast,
} from 'components/design/next';
import { GenerateDatasetMutation } from 'app/features/ai-generation/query-builder';
import * as React from 'react';
import { DataSetNameField } from './dataset-name-field';
import { AxiosError } from 'axios';
import { HttpStatus } from '@process-street/subgrade/util';
import { DataSetOption } from './predefined-datasets-options';
import { AiGeneratedDataSetSettingsModalContext } from './context';
import { useQueryClient } from 'react-query';
import { GetAllDataSetsQuery, GetDataSetRowsQuery } from 'app/pages/reports/data-sets/query-builder';
import { useNavigateToDataSet } from '../../hooks';
import { DataSetColumnStateModel } from '@process-street/subgrade/process';

export type AiGeneratedDataSetSettingsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AiGeneratedDataSetSettingsModal = ({ isOpen, onClose }: AiGeneratedDataSetSettingsModalProps) => {
  const [mode, setMode] = React.useState<'configuring' | 'generating'>('configuring');
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState<string>('');
  const [isPredefinedDescription, setIsPredefinedDescription] = React.useState(false);
  const descriptionRef = React.useRef<HTMLTextAreaElement>(null);
  const [hasModerationError, setHasModerationError] = React.useState(false);
  const toast = useToast();
  const maxNameLength = 255;
  const queryClient = useQueryClient();
  const navigateToDataSet = useNavigateToDataSet();

  const generateDataSetMutation = GenerateDatasetMutation.useMutation({
    onSuccess: async dataSet => {
      await queryClient.invalidateQueries(GetAllDataSetsQuery.key);
      const columns = dataSet.columnDefs?.map(DataSetColumnStateModel.fromColumnDef);
      await queryClient.invalidateQueries(GetDataSetRowsQuery.getKey({ id: dataSet.id, columns }));
      toast({
        status: 'success',
        title: 'Data Set generated successfully',
        position: 'top',
      });
      navigateToDataSet(dataSet, onClose);
    },
    onError: (err: AxiosError) => {
      if (err.response?.status === HttpStatus.BAD_REQUEST) {
        setHasModerationError(true);
      } else {
        toast({
          status: 'error',
          title: 'ChatGPT is experiencing an issue',
          position: 'top',
        });
        onClose();
      }
    },
  });

  const handleInputChange = React.useCallback(
    (name: string) => {
      setName(name);
      setHasModerationError(false);

      if (isPredefinedDescription) {
        setDescription('');
      }
    },
    [isPredefinedDescription],
  );

  const handleNameSelect = (option: DataSetOption) => {
    setHasModerationError(false);
    setName(option.name);
    setDescription(option.description);
    descriptionRef.current?.focus();
  };

  const handleOnGenerateDataset = () => {
    setMode('generating');
    generateDataSetMutation.mutate({
      name,
      description,
    });
  };

  const contextValue = React.useMemo(
    () => ({
      name,
      description: description ?? '',
      onInputChange: handleInputChange,
    }),
    [name, description, handleInputChange],
  );

  const isLoading = generateDataSetMutation.isLoading;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent overflow="hidden">
        {mode === 'configuring' && (
          <AiGeneratedDataSetSettingsModalContext.Provider value={contextValue}>
            <ModalHeader position="relative" fontSize="base" fontWeight="bold" p="0">
              <Box
                position="absolute"
                top="0"
                left="0"
                h="full"
                w="full"
                bg="linear-gradient(103.79deg, #8D77D7 29.15%, #5238AF 92.35%)"
                opacity="0.9"
              />
              <VStack
                bgImg={require('app/images/create-workflow-modal/ai-modal-cover.png')}
                h="52"
                justifyContent="flex-start"
                alignItems="flex-start"
                px="5"
                py="4"
                spacing="0"
              >
                <Text fontSize="lg" color="white" zIndex="1">
                  Process AI
                </Text>

                <Flex zIndex="1" justifyContent="center" alignItems="center" w="full">
                  <Image
                    w="full"
                    maxW="60"
                    src={require('app/images/create-workflow-modal/ai-modal-cover-v2.png')}
                    alt="Process Pete"
                  />
                </Flex>
              </VStack>
            </ModalHeader>
            <ModalCloseButton color="white" />
            <form>
              <ModalBody p="0">
                <VStack spacing="6" p="6">
                  <DataSetNameField
                    maxLength={maxNameLength}
                    hasModerationError={hasModerationError}
                    description={description ?? ''}
                    value={name}
                    onSelect={handleNameSelect}
                    isLoading={isLoading}
                  />

                  <FormControl>
                    <FormLabel>Columns</FormLabel>
                    <Text mb="2" variant="-1" color="gray.500">
                      Type in the columns you'd like to have in this Data Set, separated by commas.
                    </Text>
                    <Textarea
                      w="full"
                      value={description}
                      ref={descriptionRef}
                      onChange={e => {
                        setDescription(e.target.value);
                        setIsPredefinedDescription(false);
                      }}
                      placeholder={'e.g., “Country name, capital, country code, and capital timezone.”'}
                      isRequired
                      isDisabled={generateDataSetMutation.isLoading}
                      maxLength={200}
                    />
                  </FormControl>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <HStack spacing="4">
                  <Button variant="unstyled" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    isDisabled={!name || isLoading || name.length > maxNameLength}
                    onClick={handleOnGenerateDataset}
                    type="submit"
                    loadingText="Generating..."
                    isLoading={generateDataSetMutation.isLoading}
                  >
                    Generate with AI
                  </Button>
                </HStack>
              </ModalFooter>
            </form>
          </AiGeneratedDataSetSettingsModalContext.Provider>
        )}
        {mode === 'generating' && (
          <ModalBody>
            <VStack spacing="8" py="4">
              <Image
                w="full"
                maxW="32"
                src={require('app/images/data-sets/sparkles-loop-yellow.gif')}
                alt="Generating Data Set"
              />
              <Text color="gray.700" fontWeight="medium">
                Generating Data Set...
              </Text>
            </VStack>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
