import { Muid } from '@process-street/subgrade/core';
import { ApiKey } from '@process-street/subgrade/api-keys';
import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetApiKeysQuery {
  export type Params = { organizationId?: Muid };

  export type Response = ApiKey[];

  export const key = ['organization', 'api-keys'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = ({ organizationId }: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/organizations/${organizationId}/api-keys`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      refetchOnWindowFocus: false,
      enabled: Boolean(params.organizationId) && options.enabled !== false,
    });
  };
}
