import { DerivedTaskStatus, DerivedChecklistStatus } from '@process-street/subgrade/dashboard';

export const TASK_STATUS_KEYS_COLORS: { status: DerivedTaskStatus; color: string }[] = [
  { status: 'notCompleted', color: 'gray.400' },
  { status: 'onTrack', color: 'blue.500' },
  { status: 'dueSoon', color: 'yellow.500' },
  { status: 'overdue', color: 'red.500' },
  { status: 'completed', color: 'green.500' },
];
export const TASK_STATUS_COLORS = TASK_STATUS_KEYS_COLORS.map(({ color }) => color);
export const TASK_STATUS_KEYS = TASK_STATUS_KEYS_COLORS.map(({ status }) => status);
export const TASK_STATUS_LABELS: Record<DerivedTaskStatus, string> = {
  completed: 'Completed',
  notCompleted: 'Not Started',
  dueSoon: 'Due Soon',
  onTrack: 'On Track',
  overdue: 'Overdue',
};

export const CHECKLIST_STATUS_KEYS_COLORS: { status: DerivedChecklistStatus; color: string }[] = [
  { status: 'onTrack', color: 'blue.500' },
  { status: 'dueSoon', color: 'yellow.500' },
  { status: 'overdue', color: 'red.500' },
  { status: 'completed', color: 'green.500' },
];
export const CHECKLIST_STATUS_COLORS = CHECKLIST_STATUS_KEYS_COLORS.map(({ color }) => color);
export const CHECKLIST_STATUS_KEYS = CHECKLIST_STATUS_KEYS_COLORS.map(({ status }) => status);
export const CHECKLIST_STATUS_LABELS: Record<DerivedChecklistStatus, string> = {
  completed: 'Completed',
  dueSoon: 'Due Soon',
  onTrack: 'On Track',
  overdue: 'Overdue',
};
