import { Widget } from '@process-street/subgrade/process';
import { deleteWidgetByHeaderId } from 'pages/pages/_id/edit/page/utils/widget.api';
import { useMutation } from 'react-query';

export type DeleteWidgetByHeaderIdMutationResponse = Widget;

export const DeleteWidgetByHeaderIdMutation = {
  key: ['widget', 'delete'],
  getKey() {
    return [...DeleteWidgetByHeaderIdMutation.key];
  },
  mutationFn: deleteWidgetByHeaderId,
};

export const useDeleteWidgetMutation = () => {
  return useMutation(DeleteWidgetByHeaderIdMutation.mutationFn, {
    mutationKey: DeleteWidgetByHeaderIdMutation.getKey(),
  });
};
