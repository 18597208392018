import angular from 'angular';

//https://stackoverflow.com/questions/13320015/how-to-write-a-debounce-service-in-angularjs

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

angular.module('frontStreetApp.services').factory(
  'debounce',
  ($timeout, $q) =>
    function (func, wait, immediate, waitForResponse) {
      let callInProgress;
      let timeout;
      let deferred = $q.defer();

      return function (...args) {
        const self = this;

        const resolveDebouncedFunction = function () {
          deferred.resolve(func.apply(self, args));
          deferred = $q.defer();
        };

        const runDebouncedFunction = function () {
          timeout = null;
          if (!immediate) {
            callInProgress = true;
            resolveDebouncedFunction();
          }
        };

        const checkAndRun = function () {
          const callNow = immediate && !timeout;
          if (timeout) {
            $timeout.cancel(timeout);
          }

          if (callInProgress && waitForResponse) {
            timeout = $timeout(checkAndRun);
          } else {
            timeout = $timeout(runDebouncedFunction, wait);
          }

          if (callNow) {
            resolveDebouncedFunction();
          }
        };

        checkAndRun();

        return deferred.promise.finally(() => {
          callInProgress = false;
        });
      };
    },
);
