import * as React from 'react';
import { MenuItem, Icon } from 'components/design/next';
import { useAutomationSelector } from 'app/pages/templates/_id/automation/components/selector/context';
import { SolutionTypeTag } from '@process-street/subgrade/automation';
import { TaskTemplate } from '@process-street/subgrade/process';
import { useTaskTemplateGroupId } from 'app/pages/templates/_id/automation/utils/use-task-template-group-id';

type AutomationMenuItemProps = {
  taskTemplate: TaskTemplate;
  switchSelectedTask: (taskTemplate: TaskTemplate) => void;
};

export const AutomationMenuItem = React.forwardRef<HTMLButtonElement, AutomationMenuItemProps>(
  ({ switchSelectedTask, taskTemplate }, ref) => {
    const [, send] = useAutomationSelector();
    const taskTemplateGroupId = useTaskTemplateGroupId();

    const handleClick = () => {
      if (taskTemplateGroupId !== taskTemplate.group.id) {
        setTimeout(() => {
          switchSelectedTask(taskTemplate);
        }, 300);
      }

      send({
        type: 'SOLUTION_TYPE_SELECTED',
        payload: { solutionTypeTag: SolutionTypeTag.WhenTaskCheckedThen, modalView: 'task' },
      });
    };

    return (
      <MenuItem ref={ref} fontSize="md" color="gray.600" icon={<Icon icon="bolt" size="3.5" />} onClick={handleClick}>
        Automate
      </MenuItem>
    );
  },
);
