import * as React from 'react';
import Pie, { PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { Box, Stat, StatLabel, StatNumber, useToken, Text } from 'components/design/next';
import {
  CHECKLIST_STATUS_COLORS as COLORS,
  CHECKLIST_STATUS_KEYS as KEYS,
  CHECKLIST_STATUS_LABELS as STATUS_LABELS,
} from '../common/constants';
import { DERIVED_CHECKLIST_STATUS, DerivedChecklistStatus as Status } from '@process-street/subgrade/dashboard';
import { ParentSize } from '@visx/responsive';
import { match, P } from 'ts-pattern';
import { AnalyticsMode, isSingleWorkflowResponse, useChecklistAnalyticsQuery } from 'features/checklists/query-builder';
import { useChecklistSearchCriteria } from 'pages/reports/hooks/use-checklist-search-criteria';
import { makeInitialTooltipState, TaskStat, TooltipEffectContext, tooltipReducer } from './tooltip-state';
import { usePopper } from 'react-popper';
import { ParentSizeRenderProps } from '../common/types';
import { ChartContext } from './chart-context';
import { Slice } from './slice';
import { CHECKLIST_STATUS_SUMMARY } from '../common/visualization-names';
import { EmptyDonut } from '../empty-donut';
import { ChartTooltip } from '../chart-tooltip';
import { ChartSpinner } from '../common/chart-spinner';
import { ChartAlert } from '../chart-alert';
import { makeBarStackHorizontalLegend } from '../bar-stack-horizontal';
import { LegendItem } from './legend-item';
import { useParentWidthValue } from '../common/use-parent-width-value';
import { useLegendHeight } from '../common/use-legend-height';

export interface ChecklistStatusSummaryChartProps {
  data: TaskStat[];
  parentSize: ParentSizeRenderProps;
  config?: Partial<{
    thickness?: number;
    hideLegend?: boolean;
    margin?: number;
  }>;
}

const Legend = makeBarStackHorizontalLegend<Status>();

const DEFAULT_MARGIN = 20;

export const ChecklistStatusSummaryChart: React.VFC<ChecklistStatusSummaryChartProps> = ({
  parentSize: { width: parentWidth, height: parentHeight },
  data,
  config,
}) => {
  const marginValue = config?.margin ?? DEFAULT_MARGIN;
  const margin = { top: marginValue, right: marginValue, bottom: marginValue, left: marginValue };

  const { legendHeight, legendRef } = useLegendHeight<HTMLDivElement>();

  const colorScale = scaleOrdinal<Status, string>({
    domain: KEYS,
    range: useToken('colors', COLORS),
  });

  const innerWidth = parentWidth - margin.left - margin.right;
  const innerHeight = parentHeight - margin.top - margin.bottom - legendHeight;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = useParentWidthValue(parentWidth, { md: 100, base: 50 }) ?? 50;

  // Tooltip/popper setup
  const [tooltipState, dispatch] = React.useReducer(tooltipReducer, makeInitialTooltipState());
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(tooltipState.referenceElement, popperElement, {
    placement: 'right',
  });

  const chartContext = React.useMemo(
    () => ({
      getKey: (d: PieArcDatum<TaskStat>) => d.data.status,
      getColor: (d: PieArcDatum<TaskStat>) => colorScale(d.data.status),
    }),
    [colorScale],
  );

  const totalChecklists = data.reduce((acc, curr) => acc + curr.total, 0);

  if (parentWidth < 10) return null;

  return (
    <ChartContext.Provider value={chartContext}>
      <TooltipEffectContext.Provider value={dispatch}>
        <Box aria-label={CHECKLIST_STATUS_SUMMARY}>
          <svg width={parentWidth} height={parentHeight - legendHeight}>
            <Group top={centerY + margin.top} left={centerX + margin.left}>
              <Pie<TaskStat>
                data={data}
                pieValue={stat => stat.total}
                outerRadius={radius}
                innerRadius={radius - (config?.thickness ?? donutThickness)}
                cornerRadius={3}
                padAngle={0.005}
              >
                {({ arcs, path }) => arcs.map(arc => <Slice key={chartContext.getKey(arc)} arc={arc} path={path} />)}
              </Pie>
            </Group>
          </svg>

          {!config?.hideLegend && (
            <Legend ref={legendRef} scale={colorScale} aria-label="checklist status summary legend">
              {label => <LegendItem key={label.datum} {...label} />}
            </Legend>
          )}

          {tooltipState.data && (
            <ChartTooltip ref={setPopperElement} style={styles.popper} {...attributes.popper}>
              {match(tooltipState.data)
                .with({ status: P.not(P.nullish) }, ({ total, status }) => (
                  <Stat aria-label="task status total">
                    <StatLabel>Total {STATUS_LABELS[status]}</StatLabel>
                    <StatNumber color={colorScale(status)}>
                      {total}{' '}
                      <Text as="span" variant="1" color="gray.300">
                        of {totalChecklists}
                      </Text>
                    </StatNumber>
                  </Stat>
                ))
                .otherwise(() => null)}
            </ChartTooltip>
          )}
        </Box>
      </TooltipEffectContext.Provider>
    </ChartContext.Provider>
  );
};

export type ChecklistStatusSummaryProps = Pick<ChecklistStatusSummaryChartProps, 'config'> & {
  mode?: AnalyticsMode;
};

export const ChecklistStatusSummary: React.FC<React.PropsWithChildren<ChecklistStatusSummaryProps>> = props => {
  const searchCriteria = useChecklistSearchCriteria();
  const { templateIds } = searchCriteria;

  const analyticsQuery = useChecklistAnalyticsQuery(
    { ...searchCriteria, mode: props.mode },
    {
      select: analytics =>
        isSingleWorkflowResponse(analytics)
          ? DERIVED_CHECKLIST_STATUS.map(status => ({ status, total: analytics.checklistStatusSummary[status] }))
          : [],
    },
  );
  return match(analyticsQuery)
    .with({ status: 'loading' }, () => <ChartSpinner />)
    .with({ status: 'error' }, ({ error }) => <ChartAlert statusCode={error.response?.status} />)
    .with({ status: 'success', data: P.when(values => values.every(({ total }) => total === 0)) }, () => (
      <EmptyDonut config={props.config} />
    ))
    .with({ status: 'success', data: P.not(P.nullish) }, ({ data }) => (
      <ParentSize>
        {parentSize => <ChecklistStatusSummaryChart {...{ parentSize, key: templateIds?.join(':'), data, ...props }} />}
      </ParentSize>
    ))
    .otherwise(() => null);
};
