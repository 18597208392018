import { SearchContext } from 'features/global-search/components/search-context';
import * as React from 'react';
import { SearchBox } from './components/search-box';
import { createUsableContext } from '@process-street/subgrade/util';
import { Muid } from '@process-street/subgrade/core';
import { CrossLinkCard, CrossLinkBody } from '../display/components';
import { Box, Portal } from 'components/design/next';
import { Hits } from 'features/global-search/components/hits';
import { Hit } from './components/hit';
import { useMachine, useSelector } from '@xstate/react';
import { crosslinkSearchMachine } from './machine';
import type { Send } from './machine';
import { SearchClient } from 'algoliasearch';
import { useTemplateTypeContext } from 'utils/template/template-type-context';
import { noop } from 'lodash';
import { GlobalSearchHits } from 'features/global-search/components/model';
import isTemplateHit = GlobalSearchHits.isTemplateHit;
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';

export type Props = {
  onSelect: (templateId: Muid) => void;
  searchClient?: SearchClient;
  onFocus?: () => void;
  isReadOnly?: boolean;
};
type Context = Props & {
  send: Send;
};

export const [useCrossLinkSearchContext, CrossLinkSearchContext] = createUsableContext<Context>({
  hookName: 'useCrossLinkSearchContext',
  providerName: 'CrossLinkSearchProvider',
});

export const CrossLinkCardSearch: React.FC<React.PropsWithChildren<Props>> = ({
  onSelect,
  searchClient,
  isReadOnly = false,
  onFocus = noop,
}) => {
  const [searchState, send] = useMachine(crosslinkSearchMachine);
  const onSelectOverride = React.useCallback(
    (...params: Parameters<Props['onSelect']>) => {
      send('SELECT');
      onSelect(...params);
    },
    [onSelect, send],
  );
  const actor = useFormEditorPageActorRef();
  const isLoading = useSelector(actor, FormEditorPageActorSelectors.isLoading);

  const context = React.useMemo<Context>(
    () => ({ onSelect: onSelectOverride, send, onFocus }),
    [onSelectOverride, send, onFocus],
  );
  const isOpen = searchState.matches('focused') || searchState.matches('blurring');
  const { templateType } = useTemplateTypeContext();

  const [position, setPosition] = React.useState({ top: 0, left: 0, width: 0, shouldShowAbove: false });

  const containerRef = React.useRef<HTMLDivElement>(null);

  const updatePosition = React.useCallback(() => {
    if (isOpen && containerRef.current && !isLoading) {
      const rect = containerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const dropdownHeight = Math.min(windowHeight * 0.6, 350); // Based on the box's max-height
      const spaceBelowElement = windowHeight - rect.bottom;
      const shouldShowAbove = spaceBelowElement < dropdownHeight;

      setPosition({
        // If showing above, position from the top of the container
        // Otherwise position from the bottom
        top: shouldShowAbove ? rect.top - 350 : rect.bottom + 8,
        left: rect.left,
        width: rect.width,
        shouldShowAbove,
      });
    }
  }, [isOpen, isLoading]);

  // Update position on mount, open state change, and window events
  React.useEffect(
    function recalculateResultsPosition() {
      updatePosition();

      if (isOpen) {
        window.addEventListener('scroll', updatePosition, true);
        window.addEventListener('resize', updatePosition);

        return () => {
          window.removeEventListener('scroll', updatePosition, true);
          window.removeEventListener('resize', updatePosition);
        };
      }
    },
    [isOpen, updatePosition],
  );

  return (
    <SearchContext {...{ isOpen, searchClient, templateType }}>
      <CrossLinkSearchContext.Provider value={context}>
        <Box position="relative" ref={containerRef}>
          <CrossLinkCard {...{ p: 0, border: 0 }}>
            <CrossLinkBody>
              <SearchBox
                inputProps={{
                  borderWidth: '1px!important',
                  borderStyle: 'solid',
                  borderLeft: 'inherit',
                  borderTop: 'inherit',
                  borderRight: 'inherit',
                  isDisabled: isReadOnly,
                }}
              />
            </CrossLinkBody>
          </CrossLinkCard>
          {isOpen && (
            <Portal>
              <ThemeProvider2024>
                <Box
                  bg="white"
                  position="fixed"
                  w="full"
                  zIndex="1"
                  top={`${position.top}px`}
                  left={`${position.left}px`}
                  width={`${position.width}px`}
                  borderRadius="base"
                  borderColor="gray.200"
                  borderWidth="px"
                  borderStyle="solid"
                  maxH="min(60vh, 350px)"
                  overflowY="auto"
                >
                  <Hits>
                    {hit =>
                      isTemplateHit(hit) && (
                        <Hit
                          hit={hit}
                          // since the hit doesn't know the list option is checking for mouse enter, we'll skip default hover styles
                          _hover={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
                        />
                      )
                    }
                  </Hits>
                </Box>
              </ThemeProvider2024>
            </Portal>
          )}
        </Box>
      </CrossLinkSearchContext.Provider>
    </SearchContext>
  );
};
