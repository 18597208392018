import * as React from 'react';
import { TableLoadingSkeleton } from '../../table-loading-skeleton';
import { DataSetTableV1Props } from './data-set-table-v1';

const DataSetTable = React.lazy(() =>
  import('./data-set-table-v1').then(({ DataSetTableV1 }) => ({ default: DataSetTableV1 })),
);

export const DataSetTableV1Wrapper: React.FC<React.PropsWithChildren<DataSetTableV1Props>> = props => {
  return (
    <React.Suspense fallback={<TableLoadingSkeleton />}>
      <DataSetTable {...props} />
    </React.Suspense>
  );
};
