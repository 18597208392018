import { Link } from '@process-street/adapters/navigation';
import { Template } from '@process-street/subgrade/process';
import { abbreviateForTitle, queryString } from '@process-street/subgrade/util';
import { Button, Icon } from 'components/design/next';
import * as React from 'react';

interface PreviewWorkflowProps {
  template: Template;
  button?: React.ReactElement;
}

export const PreviewWorkflow: React.FC<React.PropsWithChildren<PreviewWorkflowProps>> = ({ template, button }) => {
  const previewButton = button ?? (
    <Button
      as={Link}
      to={{
        pathname: 'templateView',
        search: queryString.stringify({ id: template.id, title: `${abbreviateForTitle(template.name)}` }),
      }}
      size="sm"
      variant="ghost"
      leftIcon={<Icon icon="eye" size="3" color="gray.600" />}
      aria-label={`Preview ${template.name}`}
      colorScheme="gray"
      color="gray.600"
      backgroundColor="white"
      borderWidth="thin"
      borderColor="gray.300"
      borderStyle="solid"
      fontWeight="normal"
      fontSize="xs"
      _hover={{
        textDecoration: 'unset',
        color: 'gray.600',
        backgroundColor: 'gray.100',
      }}
    >
      View
    </Button>
  );

  return React.cloneElement(previewButton, {
    as: Link,
    to: {
      pathname: 'templateView',
      search: queryString.stringify({ id: template.id, title: `${abbreviateForTitle(template.name)}` }),
    },
  });
};
