import angular from 'angular';

angular.module('frontStreetApp.services').service('SegmentService', function () {
  this.calculateSegmentParts = function (segments) {
    if (segments.length < 4) {
      return {
        visible: segments,
      };
    } else {
      const visible = segments.slice(segments.length - 2);
      visible.unshift(segments[0]);
      return {
        visible,
        hidden: segments.slice(0, segments.length - 2).reverse(),
      };
    }
  };
});
