import * as React from 'react';

import { BlvdSelect, BlvdSelectProps, GroupBase } from 'components/design/BlvdSelect';

export type OperandValueSelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = BlvdSelectProps<Option, IsMulti, Group>;

export function OperandValueSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: OperandValueSelectProps<Option, IsMulti, Group>) {
  return <BlvdSelect placeholder="Choose:" searchPlaceholder="Search options..." isSearchable {...props} />;
}
