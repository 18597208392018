import { AuditMetadata } from '@process-street/subgrade/core';
import { DataSetColumnDef, DataSetColumnType } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateDataSetColumnMutationParams = {
  dataSetId: string;
  columnId: string;
  position: number;
  columnType?: DataSetColumnType;
  name?: string;
};

type CreateDataSetColumnMutationResponse = DataSetColumnDef & { audit: AuditMetadata };

export const CreateDataSetColumnMutation = {
  mutationFn: (params: CreateDataSetColumnMutationParams) =>
    axiosService
      .getAxios()
      .post<CreateDataSetColumnMutationResponse>(`/1/data-sets/${params.dataSetId}/columns`, params)
      .then(res => res.data),
};

export const useCreateDataSetColumnMutation = (
  options: UseMutationOptions<CreateDataSetColumnMutationResponse, AxiosError, CreateDataSetColumnMutationParams> = {},
) => {
  return useMutation(CreateDataSetColumnMutation.mutationFn, options);
};
