import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { inboxStatsReducer } from './inbox-stats.reducer';
import { GROUP_STATS_GET } from '../group/group.actions';

/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "state" }]*/
const groupStatsReducer = handleActions(
  {
    [GROUP_STATS_GET]: (state, { payload }) => EntitiesReducerUtils.upsertAll({}, payload),
  },
  {},
);

export const statsReducer = combineReducers({
  inbox: inboxStatsReducer,
  group: groupStatsReducer,
});
