import * as React from 'react';
import {
  FormFieldCondition as FormFieldConditionModel,
  SimpleChecklistRuleDefinition,
} from '@process-street/subgrade/conditional-logic';
import { Box, Text, Flex } from 'components/design/next';
import { FormFieldCondition } from './form-field-condition';

export interface SimpleRuleProps {
  rule: SimpleChecklistRuleDefinition;
}

export const SimpleRule: React.FC<React.PropsWithChildren<SimpleRuleProps>> = ({ rule }) => {
  const condition: FormFieldConditionModel = {
    formFieldWidgetGroupId: rule.formFieldWidgetGroupId,
    operator: rule.operator,
    operandType: rule.operand.operandType,
    operandValue: { value: rule.operand.value },
  };
  return (
    <Flex display="flex" flexDirection="row">
      <Text as="span" fontWeight="bold" mr={2} width="32px">
        IF
      </Text>
      <Box>
        <FormFieldCondition condition={condition} />
      </Box>
    </Flex>
  );
};
