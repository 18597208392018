import EventEmitter from 'eventemitter3';

const eventEmitterInternal = new EventEmitter();

export namespace EventEmitterService {
  /** This argument is used to prevent infinite loops when emitting events. */
  const RELAYED_EVENT_ARG = '__RELAYED_EVENT__';
  export const ANGULAR_RELAY_EVENT = '__EventEmitterRelay__';

  /** Drop-in for $rootScope.$broadcast in React. Emits to eventEmitter and $rootScope. */
  export function broadcast(eventName: string, ...args: Array<unknown>) {
    if (args.includes(RELAYED_EVENT_ARG)) return;
    eventEmitterInternal.emit.call(eventEmitterInternal, eventName, ...args);
    // emit relay event that $rootScope can listen to without this file depending on Angular
    eventEmitterInternal.emit.call(
      eventEmitterInternal,
      ANGULAR_RELAY_EVENT,
      ...[eventName, ...args, RELAYED_EVENT_ARG],
    );
  }

  /* Emit event without relaying back to Angular. Used if an event originates from $rootScope.$broadcast. */
  export function broadcastFromAngular(eventName: string, ...args: unknown[]) {
    if (args.includes(RELAYED_EVENT_ARG)) return;
    eventEmitterInternal.emit.call(eventEmitterInternal, eventName, ...args);
  }

  /** Replacement for $rootScope.$on in React.
   * In contrast to $rootScope.$on, doesn't call listener with __event as 1st argument.
   * Make sure to return the result of this function to remove the listener when the component unmounts.
   * */
  export function on<T>(eventName: string, listener: (data: T) => void) {
    eventEmitterInternal.on.call(eventEmitterInternal, eventName, listener);
    return () => void eventEmitterInternal.removeListener.call(eventEmitterInternal, eventName, listener);
  }
}
