import { useInjector } from 'components/injection-provider';
import { useMicrosoftTeams } from 'features/microsoft-teams/use-microsoft-teams';

// Teams retrieves the same context value until a page reload.
// This global variable persists only until a full page reload (unlike sessionStorage)
// Lets the user navigate back to the inbox manually after viewing a WFR/task
// by only triggering the redirect once.
let redirected = false;

export function useMicrosoftTeamsInboxRedirect(): { isReady: boolean } {
  const { $state } = useInjector('$state');
  const { microsoftTeamsContext, isReady } = useMicrosoftTeams();
  const result = { isReady };

  if (redirected || !isReady) {
    return result;
  }

  const subEntityId = microsoftTeamsContext?.subEntityId || '';
  const taskRegex = /checklist-([^|]*)\|task-([^|]*)/;
  const checklistRegex = /checklist-([^|]*)/;

  if (taskRegex.test(subEntityId)) {
    const [, checklistId, taskId] = subEntityId.match(taskRegex) as RegExpMatchArray;
    redirected = true;
    $state.go('msTeamsChecklist.task', { id: checklistId, groupId: taskId });
  } else if (checklistRegex.test(subEntityId)) {
    const [, checklistId] = subEntityId.match(checklistRegex) as RegExpMatchArray;
    redirected = true;
    $state.go('msTeamsChecklist', { id: checklistId });
  }

  return result;
}

/**
 * For use in tests to reset state
 */
function resetRedirected() {
  redirected = false;
}

export default resetRedirected;
