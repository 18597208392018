import { isNotIdRef, Muid, MuidUtils } from '@process-street/subgrade/core';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useUserDeactivationHelpers } from 'features/user/use-user-deactivation-helpers';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { isAssignableUserOrGroupOm } from '@process-street/subgrade/util/membership-utils';
import { useGetChecklistAssignmentsQuery } from 'features/checklists/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useDeleteChecklistAssignmentMutation } from 'features/checklists/query-builder/delete-checklist-assignment-mutation';
import { DeleteTaskAssignmentMutation, UpdateTaskAssignmentMutation } from 'features/task/query-builder';
import { useCreateChecklistAssignmentMutation } from 'components/run-checklist/query-builder';
import * as React from 'react';
import { UserOption, UserOptionTransformer } from 'utils/user-option-transformer';
import { ChecklistAssignment, TaskAssignment } from '@process-street/subgrade/role-assignment';
import { useNextThemeToast } from 'components/design/next/use-next-theme-toast';
import { GetTaskAssignmentsByTaskQuery } from 'features/one-off-tasks/query-builder';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { useGetTasksAssignmentsByChecklistRevisionQuery } from 'features/checklist-revisions/query-builder/get-tasks-assignments-by-checklist-revision-query';

interface UseReassignModalParams {
  checklistId?: Muid;
  taskId?: Muid;
  shouldShowTasksCount?: boolean;
  onChange?: () => void;
}

export function useReassignModal({ checklistId, taskId, shouldShowTasksCount, onChange }: UseReassignModalParams) {
  const hasChecklistId = Boolean(checklistId);
  const hasTaskId = hasChecklistId && Boolean(taskId);

  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const deactivationHelpers = useUserDeactivationHelpers();

  const { data: users } = useGetAllOrganizationMembershipsQuery(
    {
      organizationId: selectedOrganizationId!,
    },
    {
      enabled: Boolean(selectedOrganizationId),
      select: oms => (oms ?? []).filter(isAssignableUserOrGroupOm).map(om => om.user),
    },
  );

  const toast = useNextThemeToast();

  const checklistAssignmentsQuery = useGetChecklistAssignmentsQuery(
    { checklistId: checklistId! },
    {
      enabled: hasChecklistId && !hasTaskId,
      refetchOnWindowFocus: false,
    },
  );

  const showRemoveErrorToast = () => {
    toast({
      status: 'error',
      title: `We're having problems removing the assignment from this ${hasTaskId ? 'task' : 'workflow run'}.`,
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
  };

  const deleteChecklistAssignmentMutation = useDeleteChecklistAssignmentMutation({
    onSuccess: () => {
      checklistAssignmentsQuery.refetch();
      onChange?.();
    },
    onError: showRemoveErrorToast,
  });

  const deleteTaskAssigmnentMutation = DeleteTaskAssignmentMutation.useMutation({
    onSuccess: () => {
      tasksAssignmentsQuery.refetch();
      onChange?.();
    },
    onError: showRemoveErrorToast,
  });

  const showAddErrorToast = () => {
    toast({
      status: 'error',
      title: `We're having problems assigning to this ${hasTaskId ? 'task' : 'workflow run'}.`,
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
  };

  const createChecklistAssignmentMutation = useCreateChecklistAssignmentMutation({
    onSuccess: () => {
      checklistAssignmentsQuery.refetch();
      onChange?.();
    },
    onError: showAddErrorToast,
  });

  const shouldFetchAllTaskAssignments = hasChecklistId && shouldShowTasksCount && !hasTaskId;

  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery(
    { checklistId },
    { enabled: shouldFetchAllTaskAssignments },
  );

  const allTasksAssignmentsQuery = useGetTasksAssignmentsByChecklistRevisionQuery(
    { checklistRevisionId: checklistRevisionQuery.data?.id ?? '' },
    {
      enabled: shouldFetchAllTaskAssignments && Boolean(checklistRevisionQuery.data?.id),
    },
  );

  const singleTaskAssignmentsQuery = GetTaskAssignmentsByTaskQuery.useQuery(
    { taskId },
    {
      enabled: hasTaskId && Boolean(taskId),
    },
  );

  const tasksAssignmentsQuery = hasTaskId ? singleTaskAssignmentsQuery : allTasksAssignmentsQuery;

  const updateTaskAssignmentMutation = UpdateTaskAssignmentMutation.useMutation({
    onSuccess: () => {
      tasksAssignmentsQuery.refetch();
      onChange?.();
    },
    onError: showAddErrorToast,
  });

  const taskOrChecklistQuery = hasTaskId ? tasksAssignmentsQuery : checklistAssignmentsQuery;

  const assignableUserOptions =
    React.useMemo(() => {
      const isLoaded = users && taskOrChecklistQuery.data;
      if (!isLoaded) {
        return;
      }
      const assignedUserIds = new Set(
        taskOrChecklistQuery.data
          ?.map(
            assignment => isNotIdRef(assignment.organizationMembership) && assignment.organizationMembership.user.id,
          )
          .filter(Boolean),
      );
      const assignedUsers = new Set(users.filter(user => assignedUserIds.has(user.id)));
      const assignableUsers = users.filter(user => !assignedUsers.has(user));
      const activeAssignableUsers = assignableUsers.filter(user => deactivationHelpers.isActive(user.id));
      return UserOptionTransformer.transformUsersToOptions(activeAssignableUsers);
    }, [deactivationHelpers, taskOrChecklistQuery.data, users]) ?? [];

  const handleOnRemoveChecklistAssignment = (assignment: ChecklistAssignment) => {
    deleteChecklistAssignmentMutation.mutate({ assignmentId: assignment.id });
  };

  const handleOnRemoveTaskAssignment = (assignment: TaskAssignment) => {
    if (isNotIdRef(assignment.organizationMembership) && isNotIdRef(assignment.organizationMembership.user)) {
      deleteTaskAssigmnentMutation.mutate({
        taskId: assignment.task.id,
        assignmentId: assignment.organizationMembership.user.email,
      });
    }
  };

  const handleOnAddAssignee = (userOption: UserOption) => {
    if (hasTaskId) {
      updateTaskAssignmentMutation.mutate({
        taskId: taskId!,
        email: userOption.user.email,
      });
    } else {
      createChecklistAssignmentMutation.mutate({
        assignmentId: MuidUtils.randomMuid(),
        checklistId: checklistId!,
        email: userOption.user.email,
      });
    }
  };
  return {
    checklistAssignmentsQuery,
    tasksAssignmentsQuery,
    taskOrChecklistQuery,
    assignableUserOptions,
    handleOnRemoveChecklistAssignment,
    handleOnRemoveTaskAssignment,
    handleOnAddAssignee,
  };
}
