import { ChecklistStatus } from '@process-street/subgrade/process';
import angular from 'angular';
import { connectController } from 'reducers/util';
import { canAccess, Feature } from 'services/features/features';
import { DateUtils, HttpStatus } from '@process-street/subgrade/util';
import { ChecklistMigrationModuleSelector } from 'components/migration/store/checklist-migration.selectors';
import { createSelector } from 'reselect';
import templateUrl from './rightbar.component.html';
import './rightbar.scss';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { AppModalQueryParam } from 'app/app.constants';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ArrayService } from 'services/array-service';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { EmailService } from 'services/email-service';
import ResponsiveBootstrapToolkit from 'responsive-toolkit';
import { DynamicDueDateLabelService } from 'components/dynamic-due-dates/services/dynamic-due-date-label.service';
import { DynamicDueDatesSelector } from 'components/dynamic-due-dates/store/dynamic-due-dates.selectors';
import { trace } from 'components/trace';
import { EventName } from 'services/event-name';
import { SatisMeterEvent } from 'services/interop/satis-meter-event';
import { TemplateRevisionSelectors } from 'reducers/template-revision/template-revision.selectors';
import { ChecklistEvent } from 'services/checklists/checklist-event';
import { queryClient } from 'components/react-root';
import { ResolvedMergeTagsByChecklistRevisionIdQuery } from 'features/merge-tags/query-builder';
import { GetChecklistQuery } from 'features/checklists/query-builder';
import { AnalyticsService } from 'components/analytics/analytics.service';
import { match, P } from 'ts-pattern';

angular.module('frontStreetApp.directives').component('psChecklistRightbar', {
  bindings: {
    user: '<',
    revision: '<',
    activeStep: '<',
    checklistRule: '<',
    taskTemplates: '<',
    widgetsMap: '<',
    onCompleteChecklist: '&',
  },
  templateUrl,
  controller(
    $ngRedux,
    $q,
    $scope,
    $state,
    $rootScope,
    ChecklistActions,
    ChecklistAssignmentService,
    ChecklistMigrationActions,
    ChecklistMigrationService,
    ChecklistRevisionService,
    ChecklistRightbarEvent,
    ChecklistService,
    ChecklistSubscriptions,
    FolderService,
    MessageBox,
    OrganizationMembershipService,
    OrganizationService,
    SecurityService,
    SessionService,
    TempDataService,
    ToastService,
    UserService,
    FeatureFlagService,
  ) {
    const logger = trace({ name: 'psChecklistRightbar' });
    logger.info('loading component');

    const ctrl = this;
    this.FeatureFlagService = FeatureFlagService;
    const connectToRedux = () => {
      const mapStateToCtrl = () =>
        createSelector(
          [
            ChecklistMigrationModuleSelector.getStatsByChecklistId(ctrl.revision.checklist.id),
            OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId,
            SessionSelector.getCurrentPlan,
            DynamicDueDatesSelector.getDateWidgetsByTemplateRevisionId(ctrl.revision.templateRevision.id),
            TemplateRevisionSelectors.getNewestByTemplateId(ctrl.revision.checklist.template.id),
          ],
          (checklistStats, organizationMembership, plan, dateWidgets, templateRevisions) => {
            const { migratable, migrating, migrationStatus } = checklistStats;

            const finishedTemplateRevision = templateRevisions[0];
            const migrationRequired = finishedTemplateRevision?.checklistMigrationStrategy !== 'Optional';

            return {
              migratable,
              migrating,
              migrationStatus,
              organizationMembership,
              plan,
              dateWidgets,
              migrationRequired,
            };
          },
        );

      const mapDispatchToCtrl = () => ({
        getChecklistById: ChecklistActions.getById,
        setStatsByChecklist: ChecklistMigrationActions.setStatsByChecklist,
      });

      const shouldChange = changes => changes.revision && changes.revision.currentValue;

      connectController($ngRedux, mapStateToCtrl, mapDispatchToCtrl, shouldChange)(ctrl);
    };

    ctrl.$onInit = function () {
      ctrl.sidebarHidden = !$state.params.comments;

      connectToRedux();
      ctrl.isPdfEmbellishmentEnabled = this.FeatureFlagService.getFeatureFlags().pdfEmbellishment;
      ctrl.isReactWorkflowEditorEnabled = this.FeatureFlagService.getFeatureFlags().reactWorkflowEditor;
      ctrl._initializeFeatures();
      ctrl._initializePermissions(ctrl.revision.checklist);

      ctrl._initializeAssignments(ctrl.revision.checklist);
      ctrl._initializeSubscription($state.params.id);

      ctrl._initializeUserOrganizationMembership(ctrl.user);
      ctrl._initializeIsUpdatingChecklistStatus();

      $scope.$on(ChecklistEvent.DELETE_OK, (__event, _checklist) => {
        ToastService.openToast({
          status: 'success',
          title: `Workflow run deleted`,
          description: 'You can restore the workflow run from the right bar.',
        });
      });

      $scope.$on(ChecklistEvent.DELETE_FAILED, () => {
        ToastService.openToast({
          status: 'error',
          title: `We're having problems deleting the workflow`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });

      const deregisterWatch = $scope.$watch('revision', revision => {
        if (revision) {
          deregisterWatch();
          $scope.revision.checklist.dueDate = revision.checklist.dueDate || null;
        }
      });

      if (ResponsiveBootstrapToolkit.is('xs')) {
        ctrl.hideSidebar();
      }

      $scope.$on('$stateChangeSuccess', (__event, toState) => {
        if (ctrl.migrationModalOptions && !['checklist', 'checklist.task'].includes(toState.name)) {
          ctrl.migrationModalOptions.interrupted = true; // stop polling
        }
      });
    };

    ctrl.$onChanges = function (changes) {
      if (changes.revision && changes.revision.currentValue) {
        ctrl.revision = changes.revision.currentValue;
      }
    };

    // Sidebar

    ctrl.hideSidebar = function (userClicked) {
      logger.info('hiding checklist sidebar');

      if (userClicked) {
        AnalyticsService.trackEvent('rightbar closed', { location: 'rightbar arrow' });
      }

      ctrl.sidebarHidden = true;
      SessionService.setChecklistEditorProperty('sidebarHidden', ctrl.sidebarHidden);

      $rootScope.$broadcast(ChecklistRightbarEvent.TOGGLED, ctrl.sidebarHidden);
    };

    ctrl.showSidebar = function () {
      logger.info('showing checklist sidebar');

      AnalyticsService.trackEvent('rightbar opened', { location: 'checklist' });

      ctrl.sidebarHidden = false;
      SessionService.setChecklistEditorProperty('sidebarHidden', ctrl.sidebarHidden);

      $rootScope.$broadcast(ChecklistRightbarEvent.TOGGLED, ctrl.sidebarHidden);
    };

    ctrl.isChecklistActionable = ChecklistService.isChecklistActionable;
    ctrl.isChecklistActive = ChecklistService.isChecklistActionable;
    ctrl.isChecklistArchived = ChecklistService.isChecklistArchived;
    ctrl.isChecklistCompleted = ChecklistService.isChecklistCompleted;
    // Disallows completing/archiving/deleting while another status update is in progress
    ctrl.isUpdatingChecklistStatus = false;

    // Features

    ctrl._initializeFeatures = function () {
      OrganizationService.getById(ctrl.revision.checklist.organization.id).then(
        organization => {
          const planId = organization && organization.subscription.plan.id;

          ctrl.completeStateAvailable = canAccess(Feature.COMPLETED_STATE, planId);
        },
        () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems loading the organization`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
      );
    };

    // Permissions

    ctrl.permissionMap = {};

    ctrl._initializePermissions = function (checklist) {
      $q.all({
        checklistUpdate: SecurityService.canUpdateChecklistByChecklist(checklist),
        checklistComplete: SecurityService.canCompleteChecklistByChecklist(checklist),
        checklistArchive: SecurityService.canArchiveChecklistByChecklist(checklist),
        checklistReactivate: SecurityService.canReactivateChecklistByChecklist(checklist),
        checklistAssignmentCreate: SecurityService.canCreateChecklistAssignmentByChecklist(checklist),
        checklistAssignmentDelete: SecurityService.canDeleteChecklistAssignmentByChecklist(checklist),
        checklistDelete: SecurityService.canDeleteChecklistByChecklist(checklist),
        templateUpdate: SecurityService.canUpdateTemplateByTemplate(checklist.template),
        checklistExport: SecurityService.canExportChecklistByChecklist(checklist),
        checklistShare: SecurityService.canShareChecklistByChecklist(checklist),
        canShare: ChecklistService.canShare(),
      }).then(permissionMap => {
        ctrl.permissionMap = permissionMap;
        ctrl.editWorkflowVisible = ctrl.permissionMap.templateUpdate;
        ctrl.canShare = permissionMap.canShare;
      });
    };

    ctrl.canUpdateDueDate = function (checklist) {
      return checklist && ChecklistService.isChecklistActionable(checklist) && ctrl.permissionMap.checklistUpdate;
    };

    ctrl.canAssignUsers = function (checklist) {
      return (
        !ctrl.currentUserIsFreeMember() &&
        checklist &&
        ChecklistService.isChecklistActionable(checklist) &&
        ctrl.permissionMap.checklistAssignmentCreate
      );
    };

    ctrl.canUnassignUsers = function (checklist) {
      return (
        !ctrl.currentUserIsFreeMember() &&
        checklist &&
        ChecklistService.isChecklistActionable(checklist) &&
        ctrl.permissionMap.checklistAssignmentDelete
      );
    };

    ctrl.completeChecklist = function (__checklist) {
      ctrl.onCompleteChecklist();
    };

    ctrl.reactivateChecklist = function (checklist) {
      ChecklistService.updateStatus(checklist, ChecklistStatus.Active);
    };

    ctrl.archiveChecklist = function (checklist) {
      ChecklistService.updateStatus(checklist, ChecklistStatus.Archived);
    };

    ctrl.unarchiveChecklist = function (checklist) {
      if (checklist.template.status === 'Archived') {
        const message =
          "You cannot unarchive a run while the workflow it's based on is archived.<br>" +
          "Please unarchive the workflow it's based on first and try again.";

        ToastService.openToast({
          status: 'warning',
          title: `We couldn't unarchive the workflow run`,
          description: message,
        });
      } else {
        ChecklistService.updateStatus(checklist, ChecklistStatus.Active);
      }
    };

    let deleteChecklistShown = false;
    ctrl.deleteChecklist = function (checklist) {
      if (deleteChecklistShown) {
        return;
      }

      deleteChecklistShown = true;
      MessageBox.confirm({
        title: 'Delete this workflow run?',
        message: 'Your workflow run will be deleted and irrecoverable!',
        okButton: {
          type: 'danger',
          text: 'Delete',
          action: doDeleteChecklist.bind(null, checklist),
        },
      }).result.finally(() => {
        deleteChecklistShown = false;
      });
    };

    function doDeleteChecklist(checklist) {
      ChecklistService.delete(checklist).then(() => {
        const previousState = TempDataService.getStates().find(
          st => st.state.name.startsWith('templateView') || st.state.name.startsWith('dashboard'),
        );

        if (previousState) {
          const {
            [AppModalQueryParam.Modal]: _,
            [AppModalQueryParam.ModalTemplateId]: __,
            ...filteredParams
          } = previousState.params;
          $state.go(previousState.state.name, filteredParams);
        } else {
          FolderService.getById(checklist.template.folder.id).then(folder => {
            FolderService.toSlugPathAsync(folder).then(path => {
              $state.go('dashboard.type', { type: 'folder', path });
            });
          });
        }
      });
    }

    ctrl.editTemplate = function () {
      const { template } = ctrl.revision.checklist;

      const key = `ai-generated-workflow-${template.id}`;
      if (localStorage.getItem(key) === 'true') {
        localStorage.removeItem(key);
        $rootScope.$broadcast(SatisMeterEvent.AiGeneratedTemplateFirstEdit);
      }

      const step = ctrl.activeStep;

      const { route, params } = match({ step, isReactWorkflowEditorEnabled: ctrl.isReactWorkflowEditorEnabled })
        .with({ step: P.not(P.nullish), isReactWorkflowEditorEnabled: true }, ({ step }) => ({
          route: 'templateV2.task',
          params: { id: template.id, groupId: step.id },
        }))
        .with({ step: P.not(P.nullish), isReactWorkflowEditorEnabled: false }, ({ step }) => ({
          route: 'template.task',
          params: { id: template.id, verb: 'edit', groupId: step.id },
        }))
        .otherwise(() => ({ route: 'template', params: { id: template.id, verb: 'edit' } }));

      $state.go(route, params);
    };

    ctrl.migrateChecklistRevision = checklistRevision => {
      ctrl.scheduleOneMigration(checklistRevision);
    };

    ctrl.scheduleOneMigration = checklistRevision => {
      ChecklistMigrationService.markAsMigrating(checklistRevision);

      ChecklistRevisionService.scheduleOneMigrationByChecklistRevisionId(checklistRevision.id).then(
        () => {
          ToastService.openToast({
            status: 'success',
            title: `The workflow run has been scheduled for an update and should be updated soon!`,
          });

          AnalyticsService.trackEvent('checklist updated to new template revision');

          ctrl.migrationModalOptions = {
            checklistRevision,
            interrupted: false,
          };

          ChecklistMigrationService.checkChecklistMigrationProgressAndRefreshOnFinish(ctrl.migrationModalOptions);
        },
        response => {
          ChecklistMigrationService.markAsMigratable(checklistRevision);

          if (response.status === HttpStatus.CONFLICT) {
            ChecklistMigrationService.refreshPageIfOnChecklist();
          } else {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems updating the workflow run`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          }
        },
      );
    };

    // Subscriptions
    ctrl.subscription = null;

    ctrl.subscribe = function (checklist) {
      if (ctrl.changingSubscription) {
        return;
      }

      ctrl.changingSubscription = true;

      ChecklistSubscriptions.create({
        checklistId: checklist.id,
        userId: ctrl.user.id,
      })
        .$promise.then(
          subscription => {
            AnalyticsService.trackEvent('checklist subscription created', {
              auto: false,
            });

            ToastService.openToast({
              status: 'success',
              title: `Subscription updated`,
              description: `You'll now get email notifications for this workflow run.`,
            });

            ctrl.subscription = subscription;

            return subscription;
          },
          response => {
            logger.error('failed to create subscription. Reason: %s', JSON.stringify(response));

            ToastService.openToast({
              status: 'error',
              title: `We're having problems updating the workflow subscription`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });

            return $q.reject(response);
          },
        )
        .finally(() => {
          ctrl.changingSubscription = false;
        });
    };

    ctrl.unsubscribe = function (subscription) {
      if (ctrl.changingSubscription) {
        return;
      }

      ctrl.changingSubscription = true;

      ChecklistSubscriptions.delete({
        id: subscription.id,
      })
        .$promise.then(
          response => {
            AnalyticsService.trackEvent('checklist subscription deleted');

            ToastService.openToast({
              status: 'success',
              title: `Subscription updated`,
              description: `You'll won't get email notifications for this workflow run any more.`,
            });

            ctrl.subscription = null;

            return response;
          },
          response => {
            logger.error('failed to delete subscription. Reason: %s', JSON.stringify(response));

            ToastService.openToast({
              status: 'error',
              title: `We're having problems updating the workflow subscription`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });

            return $q.reject(response);
          },
        )
        .finally(() => {
          ctrl.changingSubscription = false;
        });
    };

    ctrl._initializeSubscription = function (checklistId) {
      ctrl.changingSubscription = true;

      ChecklistSubscriptions.query({
        where: JSON.stringify({
          'checklistId': { _eq: checklistId },
          'organizationMembership.userId': { _eq: ctrl.user.id },
        }),
        include: 'organizationMembership',
      })
        .$promise.then(
          subscriptions => {
            if (subscriptions.length) {
              const [subscription] = subscriptions;
              ctrl.subscription = subscription;
            }

            return subscriptions;
          },
          response => {
            logger.error('failed to retrieve subscriptions. Reason: %s', JSON.stringify(response));

            ctrl.missingSubscription = true;

            return $q.reject(response);
          },
        )
        .finally(() => {
          ctrl.changingSubscription = false;
        });
    };

    // Due Date

    ctrl.saveDueDate = function (checklist, dueDate) {
      const originalChecklist = angular.copy(checklist);
      const originalDueDate = originalChecklist.dueDate;
      checklist.dueDate = dueDate;

      ChecklistService.updateDueDate(checklist, dueDate, originalDueDate).then(
        async () => {
          $rootScope.$broadcast(EventName.CHECKLIST_DUE_DATE_UPDATE_OK, checklist);
          await queryClient.invalidateQueries(GetChecklistQuery.getKey({ checklistId: checklist.id }));
          await queryClient.invalidateQueries(ResolvedMergeTagsByChecklistRevisionIdQuery.key);
        },
        response => {
          checklist.dueDate = originalDueDate;

          switch (response.status) {
            case HttpStatus.BAD_REQUEST:
              ToastService.openToast({
                status: 'warning',
                title: `We couldn't update the due date`,
                description: 'The due date must be in the future',
              });
              break;
            default:
              ToastService.openToast({
                status: 'error',
                title: `We're having problems updating the due date`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
          }
        },
      );
    };

    ctrl.removeDueDate = function (checklist) {
      const originalChecklist = angular.copy(checklist);
      const originalDueDate = originalChecklist.dueDate;
      delete checklist.dueDate;

      ChecklistService.updateDueDate(checklist, null, originalDueDate).catch(() => {
        checklist.dueDate = originalDueDate;
        ToastService.openToast({
          status: 'error',
          title: `We're having problems removing the due date`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
    };

    ctrl.shouldShowShareLink = function (checklist) {
      return checklist && checklist.shared;
    };

    ctrl.shouldShowEditTemplate = function () {
      return ctrl.currentUserIsFreeMember() || ctrl.editWorkflowVisible;
    };

    ctrl.generateShareableLink = function (checklist) {
      if (!checklist.shared) {
        return null;
      }

      return $state.href('checklist', { id: checklist.id }, { absolute: true });
    };

    ctrl.updateShareable = function () {
      if (angular.isDefined(ctrl.revision.checklist.shared)) {
        ChecklistService.canShare().then(canShare => {
          if (canShare) {
            const { shared } = ctrl.revision.checklist;

            ChecklistService.updateShareable(ctrl.revision.checklist.id, shared).then(
              () => {
                const successMessage = shared
                  ? 'Anyone with the link can complete this workflow run.'
                  : 'Only your organization can complete this workflow run.';

                ToastService.openToast({
                  status: 'success',
                  title: `Share link ${shared ? 'enabled' : 'disabled'}`,
                  description: successMessage,
                });
              },
              () => {
                ToastService.openToast({
                  status: 'warning',
                  title: `We couldn't ${shared ? 'enable' : 'disable'} the share link `,
                  description: 'Please ensure your email address is verified and try again.',
                });
                ctrl.revision.checklist.shared = !shared;
              },
            );
          }
        });
      }
    };

    // Assignments

    ctrl._initializeAssignments = function (checklist) {
      ctrl.assignees = [];

      ChecklistAssignmentService.getAllByChecklistId(checklist.id).then(assignments => {
        ctrl.assignees = assignments
          .filter(assignment => !isAnonymousUser(assignment.organizationMembership.user))
          .map(assignment => assignment.organizationMembership.user);
      });
    };

    ctrl.showAllAssignees = () => {
      ctrl.allAssigneesShown = true;
    };

    const ASSIGNEES_PER_LINE = 6;

    ctrl.getAssigneesLimit = () => {
      if (ctrl.allAssigneesShown || ctrl.assignees.length <= ASSIGNEES_PER_LINE) {
        return undefined;
      } else {
        return ASSIGNEES_PER_LINE - 1;
      }
    };

    ctrl.shouldShowShowAllAssigneesButton = () =>
      !ctrl.allAssigneesShown && ctrl.assignees && ctrl.assignees.length > ASSIGNEES_PER_LINE;

    ctrl.getExtraAssigneesCount = () => ctrl.assignees.length - (ASSIGNEES_PER_LINE - 1);

    ctrl.assignUsersHelpText = `Search for a person in your organization by name or email address, ' +
      'or enter an email address to invite a Guest (External).`;

    ctrl.assignUser = function (checklist, user) {
      if (!EmailService.isValidEmailAddress(user.email)) {
        ToastService.openToast({
          status: 'warning',
          title: `We couldn't assign the user`,
          description: 'The email address appears to be invalid.',
        });
        return;
      }

      ctrl.assignees.push(user);

      const name = UserService.getLabel(user);

      ChecklistAssignmentService.assignOrInvite(checklist, user, false /* autoAssign */).then(
        assignment => {
          // Remove the original user in case they were just a shell for invitation
          ArrayService.desplice(ctrl.assignees, user);
          ctrl.assignees.push(assignment.organizationMembership.user);

          ToastService.openToast({
            status: 'success',
            title: 'Assignments updated',
            description: `${name} has been assigned to the workflow run`,
          });
        },
        response => {
          switch (response.status) {
            case HttpStatus.CONFLICT:
              ToastService.openToast({
                status: 'warning',
                title: `We couldn't assign ${name} to the workflow run`,
                description: 'They are already assigned to the workflow run.',
              });
              break;
            case HttpStatus.BAD_REQUEST:
              ToastService.openToast({
                status: 'warning',
                title: `We couldn't assign ${name} to the workflow run`,
                description: DefaultErrorMessages.getUserInactiveDescription(name, response),
              });
              break;
            default:
              ToastService.openToast({
                status: 'error',
                title: `We're having problems assigning ${name} to the workflow run`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
              break;
          }

          ArrayService.desplice(ctrl.assignees, user);
        },
      );
    };

    ctrl.unassignUser = function (checklist, user) {
      ArrayService.desplice(ctrl.assignees, user);

      const name = UserService.getLabel(user);

      ChecklistAssignmentService.unassign(checklist, user).then(
        () => {
          ToastService.openToast({
            status: 'success',
            title: `<b>${name}</b> has been removed from this workflow run`,
          });
        },
        () => {
          ToastService.openToast({
            status: 'alert',
            title: `We having problems removing <b>${name}</b> to the workflow run`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });

          ctrl.assignees.push(user);
        },
      );
    };

    ctrl._initializeUserOrganizationMembership = function (user) {
      OrganizationMembershipService.getAllByOrganizationId(SecurityService.getSelectedOrganizationIdByUser(user)).then(
        memberships => {
          ctrl.userOrganizationMembership = memberships.find(m => m.user.id === user.id);
        },
      );
    };

    ctrl._initializeIsUpdatingChecklistStatus = () => {
      $scope.$on(ChecklistEvent.UPDATE_STARTED, () => {
        ctrl.isUpdatingChecklistStatus = true;
      });

      const finishUpdate = () => {
        ctrl.isUpdatingChecklistStatus = false;
      };
      $scope.$on(ChecklistEvent.UPDATE_FAILED, finishUpdate);
      $scope.$on(ChecklistEvent.UPDATE_OK, finishUpdate);
    };

    ctrl.currentUserIsFreeMember = function () {
      return ctrl.userOrganizationMembership?.role === OrganizationMembershipRole.FreeMember;
    };

    ctrl.isOverrideVisible = () => ctrl.checklistRule && !ctrl.revision.checklist.dueDateOverridden;

    ctrl.overrideDescription = () =>
      ctrl.checklistRule &&
      ctrl.taskTemplates &&
      ctrl.state.dateWidgets &&
      DynamicDueDateLabelService.getModalLabelByRule(ctrl.checklistRule, ctrl.taskTemplates, ctrl.state.dateWidgets);

    ctrl.getDueDate = () =>
      ctrl.revision.checklist.dueDate
        ? DateUtils.formatActivityTime({ date: ctrl.revision.checklist.dueDate, timeZone: ctrl.user.timeZone })
        : null;

    ctrl.getDueDateLabel = () =>
      ctrl.taskTemplates &&
      ctrl.state.dateWidgets &&
      DynamicDueDateLabelService.getChecklistDueDateLabel(
        ctrl.checklistRule,
        ctrl.revision.checklist,
        ctrl.taskTemplates,
        ctrl.state.dateWidgets,
        ctrl.user.timeZone,
      );

    $scope.$on(EventName.CHECKLIST_DYNAMIC_DUE_DATE_UPDATED, (__event, data) => {
      ctrl.revision = {
        ...ctrl.revision,
        checklist: {
          ...ctrl.revision.checklist,
          dueDate: data.dueDate,
        },
      };
    });
  },
});
