export enum TrayConfigPopupMessageType {
  ERROR = 'tray.configPopup.error',
  CANCEL = 'tray.configPopup.cancel',
  FINISH = 'tray.configPopup.finish',
  READY = 'tray.configPopup.ready',
  VALIDATE = 'tray.configPopup.validate',
  CLIENT_VALIDATION = 'tray.configPopup.client.validation',
}

const CUSTOM_DOMAIN = 'config.automation.process.st';
const AUTH_BASE_URL = 'auth.automation.process.st';

type ConfigWindowUrlProps = {
  solutionInstanceId: string;
  authorizationCode: string;
};

export const getConfigWindowUrl = ({ solutionInstanceId, authorizationCode }: ConfigWindowUrlProps): string => {
  return `https://${CUSTOM_DOMAIN}/external/solutions/processstreet/configure/${solutionInstanceId}?code=${authorizationCode}&authBaseUrl=${AUTH_BASE_URL}`;
};

export const ConfigWizardWindow = {
  getConfigWindowUrl,
};
