import angular from 'angular';
import { FocusById } from 'services/focus.interface';
import templateUrl from './reject-comment.component.html';
import './reject-comment.component.scss';

export class ApprovalSubjectTaskRejectCommentController {
  static $inject = ['focusById'];
  constructor(private focusById: FocusById) {
    this.focusById('comment');
  }
}

export const ApprovalSubjectTaskRejectComment: angular.IComponentOptions = {
  bindings: {
    onCancel: '&',
    onReject: '&',
  },
  controller: ApprovalSubjectTaskRejectCommentController,
  templateUrl,
};
