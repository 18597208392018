import * as React from 'react';
import './Spinner.scss';

interface SpinnerProps {
  spinnerClass?: string;
  spinnerText?: string;
}

export const Spinner: React.FunctionComponent<React.PropsWithChildren<SpinnerProps>> = ({
  spinnerClass = '',
  spinnerText,
}) => {
  return (
    <span className={`spinner-container ${spinnerClass}`}>
      <span className="ps-spinner" />
      {spinnerText ? <span className="spinner-text">{spinnerText}</span> : null}
    </span>
  );
};
