import * as React from 'react';

import {
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  VStack,
  useToast,
} from 'components/design/next';

import { useCopyToClipboard } from 'react-use';

interface ApiKeyFieldProps {
  apiKeyValue: string;
}

export const ApiKeyField: React.FC<React.PropsWithChildren<ApiKeyFieldProps>> = ({ apiKeyValue }) => {
  const toast = useToast();
  const [showKey, setShowKey] = React.useState(false);
  const handleClickView = () => setShowKey(!showKey);

  const [_, copyToClipboard] = useCopyToClipboard();

  const handleCopyClick = (apiKey: string) => {
    copyToClipboard(apiKey);
    toast({
      status: 'success',
      title: 'Copied API Key to clipboard!',
      variant: 'subtle',
    });
  };
  return (
    <FormControl as={VStack} alignItems="flex-start" w="full" spacing="0">
      <FormLabel color="gray.600" display={{ base: 'block', md: 'none' }}>
        API Key
      </FormLabel>
      <InputGroup w="full">
        <Input
          id={apiKeyValue}
          borderColor="gray.300"
          defaultValue={apiKeyValue}
          pr={1}
          type={showKey ? 'text' : 'password'}
          variant="filled"
          _disabled={{ bgColor: 'gray.100', opacity: 1 }}
          isDisabled
        />
        <InputRightAddon px="0" border="none">
          <IconButton
            aria-label="Copy API Key"
            backgroundColor="transparent"
            borderColor="gray.300"
            borderRadius={0}
            borderLeft="none"
            color="gray.600"
            onClick={() => handleCopyClick(apiKeyValue)}
            px="0"
            size="md"
            variant="outline"
            icon={<Icon icon="copy" size="4" color="gray.500" />}
            _hover={{ bgColor: 'gray.50' }}
          />
        </InputRightAddon>
        <InputRightAddon px="0" border="none">
          <IconButton
            aria-label="View API Key"
            backgroundColor="transparent"
            borderColor="gray.300"
            borderBottomLeftRadius="0"
            borderLeft="none"
            borderTopLeftRadius="0"
            color="gray.600"
            onClick={handleClickView}
            px="0"
            size="md"
            variant="outline"
            icon={<Icon icon="eye" size="4" color="gray.500" />}
            _hover={{ bgColor: 'gray.50' }}
          />
        </InputRightAddon>
      </InputGroup>
    </FormControl>
  );
};
