import angular from 'angular';
import { generateLocation, generateTo, getTabByName, getTabIndexByName } from 'services/tab-service';
import profileTemplate from './profile.component.html';
import notificationsTemplate from './notifications.component.html';
import groupsTemplate from './groups.component.html';
import activityTemplate from './activity.component.html';
import organizationTemplate from './organizations/organizations.component.html';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { isGroupUser, isStandardUser } from '@process-street/subgrade/util/user-type-utils';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'UserManageCtrl',
    function (
      $q,
      $scope,
      $state,
      $timeout,
      GroupService,
      OrganizationMembershipService,
      SessionService,
      ToastService,
      UserService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'UserManageCtrl' });
      logger.info('loading ctrl');

      const profileTab = {
        name: 'profile',
        heading: 'Profile',
        templateUrl: profileTemplate,
      };

      const notificationsTab = {
        name: 'notifications',
        heading: 'Notifications',
        templateUrl: notificationsTemplate,
      };

      const groupsTab = {
        name: 'groups',
        heading: 'Groups',
        templateUrl: groupsTemplate,
      };

      const activityTab = {
        name: 'activity',
        heading: 'Activity',
        templateUrl: activityTemplate,
      };

      const organizationsTab = {
        name: 'organizations',
        heading: 'Organizations',
        templateUrl: organizationTemplate,
      };

      ctrl.$onInit = () => {
        $scope.loading = true;
        initialize().then(
          () => {
            $scope.loading = false;
          },
          results => {
            if (isGroupUser(results.user)) {
              redirectToGroup();
            } else {
              redirectToDashboard();
            }
          },
        );
      };

      function initialize() {
        const sessionUser = UserService.getCurrentUser();
        const userId = $state.params.id === 'me' ? sessionUser.id : $state.params.id;

        return $q
          .all({
            user: UserService.getById(userId),
            organizationMemberships: OrganizationMembershipService.getAllByUserId(sessionUser.id),
          })
          .then(results => {
            $scope.user = results.user;

            const membershipExists = results.organizationMemberships.some(
              item => item.organization.id === SessionService.getSelectedOrganizationId(),
            );

            if (membershipExists) {
              $scope.organization = SessionService.getSelectedOrganization();
            }

            let promise;
            if (isStandardUser($scope.user)) {
              $scope.setUserStatus($scope.user.id);
              loadTabs();
              promise = $q.resolve(results);
            } else {
              promise = $q.reject(results);
            }

            return promise;
          });
      }

      $scope.$on(EventName.USER_UPDATED, (__event, user) => {
        $scope.user = user;
      });

      $scope.$on('$stateChangeSuccess', (__event, __toState, toParams) => {
        $scope.activeIndex = getTabIndexByName($scope.tabs, toParams.tab) || 0;
      });

      $scope.setUserStatus = function (userId) {
        if ($scope.organization) {
          OrganizationMembershipService.getByOrganizationIdAndUserId($scope.organization.id, userId).then(
            membership => {
              if (membership.admin) {
                $scope.status = 'Admin';
              } else {
                $scope.status = membership.guest ? 'Guest' : 'Member';
              }
            },
          );
        } else {
          $scope.status = 'Ghost';
        }
      };

      function initializeTabs(tabs) {
        tabs.forEach(tab => {
          tab.select = function () {
            this.selectedOnce = true;

            const to = generateTo($state.current.name);
            const location = generateLocation($state.current.name);
            if ($state.includes('userManage')) {
              $state.go(to, { tab: tab.name, index: null }, { location });
            }
          };
        });

        const selectedTab = getTabByName($scope.tabs, $state.params.tab) || tabs[0];
        selectedTab.select();
      }

      function loadTabs() {
        if ($scope.organization) {
          const sessionUser = UserService.getCurrentUser();
          OrganizationMembershipService.getByOrganizationIdAndUserId($scope.organization.id, sessionUser.id).then(
            membership => {
              if (sessionUser.id === $scope.user.id) {
                $scope.tabs = [profileTab, notificationsTab, groupsTab, activityTab, organizationsTab];
              } else if (membership.admin) {
                $scope.tabs = [groupsTab, activityTab];
              } else {
                $scope.tabs = [activityTab];
              }

              initializeTabs($scope.tabs);
              $scope.activeIndex = getActiveIndex();
            },
          );
        } else {
          $scope.tabs = [profileTab, organizationsTab];

          initializeTabs($scope.tabs);
          $scope.activeIndex = getActiveIndex();
        }
      }

      function getActiveIndex() {
        return getTabIndexByName($scope.tabs, $state.params.tab) || 0;
      }

      function redirectToDashboard() {
        ToastService.openToast({
          status: 'error',
          title: `We're having problems loading the user`,
          description: DefaultErrorMessages.notFoundErrorDescription,
        });

        $timeout(() => $state.go('dashboard', {}, { location: 'replace' }));
      }

      function redirectToGroup() {
        GroupService.getGroupByUserId($scope.user.id).then(
          ({ payload: groups }) => {
            $state.go('groupManage.tab', { id: groups[0].id, tab: 'members' }, { location: 'replace' });
          },
          () => redirectToDashboard(),
        );
      }
    },
  );
