import * as React from 'react';
import { Grid, GridItem, HStack, Spacer, Stack, useToast, VStack } from 'components/design/next';
import { useGetDataSetRowsQuery } from '../../query-builder/get-data-set-rows';
import {
  DataSet,
  DataSetColumnFilterForm,
  DataSetColumnState,
  DataSetColumnStateModel,
  SavedView,
  SavedViewFilterOperator,
} from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { match, P } from 'ts-pattern';
import { useDataSetFilters } from '../../store';
import { AgGridEvent, ColumnMovedEvent, ColumnResizedEvent, PostSortRowsParams } from '@ag-grid-community/core';
import _keyBy from 'lodash/keyBy';
import { useDataSetsGuard } from '../../hooks/use-data-sets-guard';
import { DataSetSidebar } from './data-set-sidebar';
import { DataSetTableWrapper } from 'pages/reports/data-sets/components/data-set-page/data-set-table/data-set-table-wrapper';
import { DataSetToolbar } from 'pages/reports/data-sets/components/data-set-page/data-set-toolbar';
import { DataSetFiltersBar } from 'pages/reports/data-sets/components/data-set-page/data-set-filters-bar';
import { GetAllDataSetsQuery, useUpdateSavedViewMutation } from 'pages/reports/data-sets/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { queryClient } from 'components/react-root';
import { useSelectedSavedView } from 'pages/reports/data-sets/components/data-set-page/selected-saved-view-id-store';
import { LinkedWorkflowsButton } from 'pages/reports/data-sets/components/linked-workflows-button';
import { ShareDataSetButton } from 'pages/reports/data-sets/components/share/share-data-set-button';
import { ExportToCsvButton } from 'pages/reports/data-sets/components/export';
import { DataSetAvatars } from 'pages/reports/data-sets/components/data-set-page/data-set-avatars';
import { DataSetUtils } from 'pages/reports/data-sets/components/data-set-page/data-set-utils';
import { DataSetPageSkeleton } from 'pages/reports/data-sets/components/data-set-page/data-set-table-v1/data-set-page-skeleton';
import { SavedViewTabs } from 'pages/data-sets/components/saved-view-tabs';
import { DataSetDrawer } from './data-set-drawer/data-set-drawer';
import { DataSetMobileAlert } from './data-set-mobile-alert';

type RowsLoadedRefKey = `${DataSet['id']}_${SavedView['id']}_${number}`;

export const DataSetPage = () => {
  const { $state } = useInjector('$state');
  useDataSetsGuard();
  const toast = useToast();

  const { isFetching, dataSetPageStore, selectedDataSet, selectedSavedView, dataSets } = useSelectedSavedView();
  const { dataSetId, savedViewId } = dataSetPageStore;

  const dataSetFilters = useDataSetFilters();
  const rowsLoadedRef = React.useRef<Set<RowsLoadedRefKey>>(new Set([]));
  const rowsRefKey: RowsLoadedRefKey = `${selectedDataSet?.id}_${selectedSavedView?.id}_${dataSetFilters.version}`;
  const isRowsLoaded = rowsLoadedRef.current.has(rowsRefKey);

  const setColumnsState = useDataSetFilters(React.useCallback(state => state.setColumnsState, []));

  const columnsWithFilters: DataSetColumnState[] = React.useMemo(() => {
    return DataSetColumnStateModel.combineColumnsWithFilters(dataSetFilters.columns, dataSetFilters.filters);
  }, [dataSetFilters.columns, dataSetFilters.filters]);

  React.useEffect(
    function resetStateOnDataSetOrSavedViewChange() {
      if (!selectedDataSet) {
        return;
      }
      const columns = selectedSavedView
        ? selectedSavedView.columns
        : selectedDataSet.columnDefs.map<DataSetColumnState>(DataSetColumnStateModel.fromColumnDef) ?? [];
      const filters: DataSetColumnFilterForm[] = DataSetColumnStateModel.getFilters(columns);
      columns && dataSetFilters.setColumnsState({ columns });
      filters && dataSetFilters.setFilters({ filters });
      dataSetFilters.setOperator(selectedSavedView?.config?.filterOperator ?? SavedViewFilterOperator.And);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only react to selected data set or saved view ID changes
    [selectedDataSet?.id, selectedSavedView?.id],
  );

  const dataSetRowsQuery = useGetDataSetRowsQuery(
    { id: dataSetId!, savedViewId, columns: columnsWithFilters, filterOperator: dataSetFilters.operator },
    {
      enabled: !isFetching && !isRowsLoaded && Boolean(dataSetId) && dataSetFilters.columns.length > 0,
      // Reduce the server load
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Infinity,
      onSuccess: () => {
        rowsLoadedRef.current.add(rowsRefKey);
      },
    },
  );

  const handleSortOrMove = React.useCallback(
    (event: AgGridEvent) => {
      const gridColumns = event.api.getAllGridColumns()?.filter(DataSetUtils.isDataColumn);
      if (!gridColumns) return;

      const columnsStateMap = _keyBy(dataSetFilters.columns, 'id');

      const columns = gridColumns
        .map(gridColumn => {
          const columnState: DataSetColumnState | undefined = columnsStateMap[gridColumn.getId()];
          return { gridColumn, columnState };
        })
        .filter(({ columnState }) => Boolean(columnState))
        .map(({ gridColumn, columnState }) => ({
          ...columnState,
          sort: gridColumn.getSort() ?? undefined,
          sortIndex: gridColumn.getSortIndex() ?? undefined,
        }));

      setColumnsState({ columns });
    },
    [dataSetFilters.columns, setColumnsState],
  );

  const handleColumnMove = React.useCallback(
    (event: ColumnMovedEvent) => {
      if (!event.finished) return;
      handleSortOrMove(event);
    },
    [handleSortOrMove],
  );

  const handleColumnResize = React.useCallback(
    (event: ColumnResizedEvent) => {
      if (!event.finished || !event.column || event.source !== 'uiColumnResized') return;
      const gridColumn = event.column;
      const columnState = dataSetFilters.columns.find(column => column.id === gridColumn.getColId());
      if (!columnState) return;

      const updatedColumnState = {
        ...columnState,
        width: gridColumn.getActualWidth(),
      };

      dataSetFilters.updateColumnState({ column: updatedColumnState });
    },
    [dataSetFilters],
  );

  React.useEffect(
    function updateUrlFromStore() {
      const { stateName, stateParams } = match({ dataSetId, savedViewId })
        .with({ dataSetId: P.not(P.nullish), savedViewId: P.not(P.nullish) }, ({ dataSetId, savedViewId }) => ({
          stateName: 'dataSets.savedView',
          stateParams: { dataSetId, savedViewId },
        }))
        .with({ dataSetId: P.not(P.nullish), savedViewId: P.nullish }, ({ dataSetId }) => ({
          stateName: 'dataSets.dataSet',
          stateParams: { dataSetId },
        }))
        .otherwise(() => ({
          stateName: 'dataSets',
          stateParams: {},
        }));

      $state.go(stateName, stateParams);
    },
    [$state, dataSetId, savedViewId],
  );

  const updateSavedViewMutation = useUpdateSavedViewMutation({
    onSuccess: updatedSavedView => {
      void queryClient.invalidateQueries(GetAllDataSetsQuery.key);

      toast({
        status: 'success',
        title: 'Saved view successfully updated',
      });

      dataSetPageStore.setSavedViewId(updatedSavedView.id);
      updatedSavedView.columns.forEach(column => dataSetFilters.updateColumnState({ column }));
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems updating the saved view",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const handleUpdateSavedView = React.useCallback(() => {
    if (selectedSavedView && selectedDataSet) {
      const config = dataSetFilters.operator
        ? {
            filterOperator: dataSetFilters.operator,
          }
        : undefined;
      updateSavedViewMutation.mutate({
        columns: DataSetColumnStateModel.combineColumnsWithFilters(dataSetFilters.columns, dataSetFilters.filters),
        name: selectedSavedView.name,
        dataSetId: selectedDataSet.id,
        savedViewId: selectedSavedView.id,
        config,
      });
    }
  }, [
    dataSetFilters.columns,
    dataSetFilters.filters,
    dataSetFilters.operator,
    selectedDataSet,
    selectedSavedView,
    updateSavedViewMutation,
  ]);

  const handleSavedViewCreated = React.useCallback(
    (sv: SavedView) => dataSetPageStore.setSavedViewId(sv.id),
    [dataSetPageStore],
  );

  const postSortRows = React.useCallback(function keepFullWidthRowAtTheBottom(params: PostSortRowsParams) {
    const rowNodes = params.nodes ?? [];
    const lastIndex = rowNodes.length;
    const lastRowNode = rowNodes[lastIndex - 1];

    // When the `+ New` row is at the top after a sort, we move it to the end
    if (lastRowNode && !DataSetUtils.isFullWidthRow({ rowNode: lastRowNode })) {
      const fullWidthRowIndex = rowNodes.findIndex(rowNode => DataSetUtils.isFullWidthRow({ rowNode }));

      if (fullWidthRowIndex >= 0) {
        rowNodes.splice(lastIndex, 0, rowNodes.splice(fullWidthRowIndex, 1)[0]);
      }
    }
  }, []);

  const hideSavedViewControls = Boolean(selectedSavedView && selectedDataSet?.canAccessDataSet === false);

  const [quickFilter, setQuickFilter] = React.useState('');

  return (
    <Grid gridTemplateColumns={{ base: '0px 1fr', lg: '270px 1fr' }}>
      <GridItem h="calc(100vh - 64px)">
        <DataSetDrawer>
          <DataSetSidebar />
        </DataSetDrawer>
      </GridItem>

      <GridItem>
        <VStack spacing="0" alignItems="flex-start" minH="calc(100vh - 64px)" zIndex={-1}>
          {dataSets.length === 0 && <DataSetPageSkeleton isLoading={isFetching} />}

          {selectedDataSet && (
            <VStack w="full" h="full" as="main" mt="8" px={{ base: '4', sm: '8' }} flex="1" alignItems="stretch">
              <DataSetMobileAlert />

              <DataSetToolbar
                key={selectedDataSet.id}
                dataSet={selectedDataSet}
                onUpdate={ds => dataSetPageStore.setDataSetId(ds.id)}
              />

              <SavedViewTabs />

              <Stack
                pt="4"
                pb="2"
                alignItems={{ base: 'flex-start', md: 'flex-end' }}
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '4', md: '1' }}
              >
                {selectedDataSet.columnDefs && (
                  <DataSetFiltersBar
                    allColumns={selectedDataSet.columnDefs}
                    onUpdateSavedView={handleUpdateSavedView}
                    isDisabled={hideSavedViewControls}
                    // Due to the cached `keepPreviousData` request, `isLoading` would remain false on filter change
                    isLoading={dataSetRowsQuery.isFetching}
                    onSavedViewCreated={handleSavedViewCreated}
                    onUpdateQuickFilter={setQuickFilter}
                  />
                )}
                <Spacer display={{ base: 'none', md: 'block' }} />

                <HStack spacing="3">
                  {selectedDataSet && selectedSavedView && (
                    <DataSetAvatars dataSetId={selectedDataSet.id} savedViewId={selectedSavedView.id} />
                  )}

                  {selectedDataSet && (
                    <ShareDataSetButton
                      dataSetId={selectedDataSet.id}
                      isDisabled={hideSavedViewControls}
                      savedViewId={selectedSavedView?.id}
                      size="sm"
                    />
                  )}

                  {selectedDataSet && (
                    <LinkedWorkflowsButton
                      dataSetId={selectedDataSet.id}
                      savedViewId={selectedSavedView?.id}
                      size="sm"
                      iconProps={{ size: '3' }}
                    />
                  )}

                  {selectedDataSet && selectedSavedView && (
                    <ExportToCsvButton dataSet={selectedDataSet} savedView={selectedSavedView} size="sm" />
                  )}
                </HStack>
              </Stack>

              <DataSetTableWrapper
                dataSetId={selectedDataSet.id}
                columnsState={dataSetFilters.columns}
                columns={selectedDataSet.columnDefs}
                rows={dataSetRowsQuery.data ?? []}
                onSortChanged={handleSortOrMove}
                onColumnMoved={handleColumnMove}
                onColumnResized={handleColumnResize}
                quickFilter={quickFilter}
                postSortRows={postSortRows}
              />
            </VStack>
          )}
        </VStack>
      </GridItem>
    </Grid>
  );
};
