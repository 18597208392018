import * as React from 'react';
import { useSelector } from '@xstate/react';
import { useFormEditorPageActorRef } from '../../form-editor-page-machine';
import { useStateParam } from 'hooks/use-state-param';
import { useLeakyLocation, useMatch, useNavigate } from '@process-street/adapters/navigation';
import { queryString } from '@process-street/subgrade/util';
import { isPickedPropertiesEqual } from 'app/pages/workflows/_id/edit-v2/helpers/is-picked-props-equal';
import { FormEditorPageActorSelectors } from '../../form-editor-page-machine/form-editor-page-machine-selectors';

export function useTaskTemplateGroupIdNavSync() {
  const isForm = Boolean(useMatch('form'));
  const isWorkflow = Boolean(useMatch('templateV2'));
  const isEditor = isForm || isWorkflow;
  const navigate = useNavigate();
  const location = useLeakyLocation();

  const actor = useFormEditorPageActorRef();
  const taskTemplateGroupId = useStateParam({ key: 'groupId' });
  const taskTemplateSelector = React.useMemo(
    () => FormEditorPageActorSelectors.getTaskTemplateByGroupId(taskTemplateGroupId ?? ''),
    [taskTemplateGroupId],
  );

  const taskTemplate = useSelector(actor, taskTemplateSelector, isPickedPropertiesEqual(['id']));
  const firstTaskTemplateGroupId = useSelector(actor, FormEditorPageActorSelectors.getFirstTaskTemplateGroupId);
  const groupIdParamIsValid = Boolean(taskTemplate);
  const isDeleting = useSelector(actor, FormEditorPageActorSelectors.isDeleting);

  React.useEffect(() => {
    const shouldGoToFirstTask =
      isEditor &&
      (taskTemplateGroupId ? !groupIdParamIsValid && firstTaskTemplateGroupId : firstTaskTemplateGroupId) &&
      !isDeleting; // Don't go to first task if we're deleting a task template.

    if (shouldGoToFirstTask) {
      navigate({
        pathname: isWorkflow ? 'templateV2.task' : 'form.step',
        search: queryString.stringify({
          ...queryString.parse(location.search),
          groupId: firstTaskTemplateGroupId,
        }),
      });
    }
  }, [
    actor,
    taskTemplateGroupId,
    firstTaskTemplateGroupId,
    groupIdParamIsValid,
    isDeleting,
    isForm,
    isWorkflow,
    isEditor,
    navigate,
    location.search,
  ]);
}
