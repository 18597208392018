import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import { AiTaskTemplateId, FormFieldKeysContext, NativeAutomation, Widget } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { WidgetServiceUtils } from 'services/widget-service.utils';
import { MergeTagMode, MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';

type WidgetAiTasksMap = Record<Widget['id'], AiTaskTemplateId[]>;

export function useInputWidgetAiTasksMap({ templateRevisionId }: { templateRevisionId?: Muid }): WidgetAiTasksMap {
  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevisionId);
  const taskTemplateAutomations = GetAllNativeAutomationsQuery.useGetTaskTemplateAutomationsMapQuery({
    templateRevisionId,
  });

  return React.useMemo(
    () =>
      widgetsQuery.data && taskTemplateAutomations.data
        ? makeWidgetAiTasksMap(widgetsQuery.data, taskTemplateAutomations.data)
        : {},
    [widgetsQuery.data, taskTemplateAutomations.data],
  );
}

export const makeWidgetAiTasksMap = (
  widgets: Widget[],
  automationsMap: Record<Muid, NativeAutomation[]>,
): WidgetAiTasksMap => {
  const automations = Object.entries(automationsMap);
  return automations.reduce((acc, [taskTemplateId, nativeAutomations]) => {
    nativeAutomations.forEach(nativeAutomation => {
      nativeAutomation.actions.forEach(action => {
        match(action)
          .with({ actionType: 'ExecuteAiPrompt' }, ({ config: { prompt } }) => {
            const keys = WidgetServiceUtils.getFormFieldWidgetKeyMap(widgets, FormFieldKeysContext.MERGE_TAG);
            const foundTags = MergeTagStringReplacementUtils.findTags(prompt ?? '', MergeTagMode.PLAINTEXT);
            foundTags.forEach(tag => {
              const widget = keys[tag.replace(/^form\./, '')];
              if (!widget) return;
              if (!acc[widget.id]) acc[widget.id] = [];
              acc[widget.id].push({ taskTemplateId, nativeAutomation });
            });
          })
          .otherwise(() => {});
      });
    });
    return acc;
  }, {} as WidgetAiTasksMap);
};
