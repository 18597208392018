import { env } from 'components/common/env';

export interface IAppConfig {
  url?: string;
  apiUrl?: string;
  environment?: string;
}

export const AppConfig: IAppConfig = {
  apiUrl: env.APP_API_URL,
  environment: env.APP_STAGE,
  url: env.APP_URL,
};
