/* tslint:disable */
import { Option, ProcessingError } from '@process-street/subgrade/core';
import {
  createAction,
  createRequestAction,
  createSuccessAction,
  createFailureAction,
} from '@process-street/subgrade/redux/action-creator';
import { BaseReduxState } from '@process-street/subgrade/redux/types';
import { ReduxAppState } from 'reducers/types';
import { Dispatch } from 'redux';
import { Selector } from 'reselect';
import { OptimisticResult } from '../optimistic';
import angular from 'angular';

// ts-lint:disable-next-line
type SimpleMetaCreator<M> = (...args: any[]) => M;
// ts-lint:disable-next-line
type SelectorMetaCreator<S, M> = (...args: any[]) => Selector<S, M>;

type OptimisticMetaCreator<S, M> = SimpleMetaCreator<M> | SelectorMetaCreator<S, M>;

// ts-lint:disable-next-line
type OptimisticPayloadCreator<T> = (...args: any[]) => angular.IPromise<T>;

// ts-lint:disable-next-line
function isFunction(value: any): boolean {
  return value && typeof value === 'function';
}

function isSelectorMetaCreator<S, M>(meta: M | Selector<S, M>): meta is Selector<S, M> {
  return isFunction(meta);
}

function createMeta<S, M>(metaCreator: OptimisticMetaCreator<S, M>, args: any[], state: S): M {
  const meta = metaCreator(...args);
  return isSelectorMetaCreator<S, M>(meta) ? meta(state) : meta;
}

/**
 *
 * @param type - string type of the action
 * @param payloadCreator - payload creator, that should return either Object or Promise
 * @param metaCreator - meta creator, if meta contains flushCache cache will be ignored and request will be made
 * @param optimisticSelector - selector for possible optimistic value
 * @return {function(...[*]): Function}
 */
export function optimisticAction<S extends ReduxAppState, T, M>(
  type: string,
  payloadCreator: OptimisticPayloadCreator<T>,
  metaCreator: OptimisticMetaCreator<S, M>,
  optimisticSelector: (...args: any[]) => Selector<BaseReduxState, Option<OptimisticResult> | ProcessingError>,
) {
  const actionCreator =
    (...args: any[]) =>
    (dispatch: Dispatch, getState: () => S) => {
      const state = getState();

      const generalMeta = metaCreator ? createMeta(metaCreator, args, state) : {};
      const optimistic = optimisticSelector(...args)(state);

      const meta =
        optimistic && (optimistic as ProcessingError).code === undefined ? { ...generalMeta, optimistic } : generalMeta;

      dispatch(createRequestAction(type, undefined, meta));

      const payload = payloadCreator(...args);

      return payload.then(
        (response: T) => {
          dispatch(createSuccessAction(type, response, meta));

          return dispatch(createAction(type, response, meta));
        },
        error => {
          dispatch(createFailureAction(type, error, meta));

          return Promise.reject(error);
        },
      );
    };

  actionCreator.toString = () => type;

  return actionCreator;
}
