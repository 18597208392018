import * as ReactIs from 'react-is';
import { Muid } from '@process-street/subgrade/core';
import { Folder } from '@process-street/subgrade/process';
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Spacer,
} from 'components/design/next';
import React, { useState } from 'react';
import { FolderPickerRoot } from './root';
import { createUsableContext } from '@process-street/subgrade/util';
import { useFoldersQuery } from 'pages/library/components/template-library/query';
import { findPrivateRootFolder, isPrivateFolder } from 'features/folder/lib';

type FolderPickType = 'within_library' | 'to_organization' | 'to_private';

export type PickButtonProps = ButtonProps & { pickType: 'within_library' | 'to_organization' | 'to_private' };

export type FolderPickerModalProps = {
  candidateType?: 'template' | 'folder';
  verb?: 'publish' | 'move' | 'add' | 'duplicate';
  initialFolderId: Muid;
  candidateId?: Muid;
  title: string;
  pickButton: React.FC<React.PropsWithChildren<PickButtonProps>>;
  onPick?: (folder: Folder) => void;
  isLoading?: boolean;
  loadingText?: string;
  organizationId?: Muid;
} & Omit<ModalProps, 'children'> & { children?: ModalProps['children'] | undefined };

export type Context = Pick<FolderPickerModalProps, 'initialFolderId' | 'candidateType' | 'candidateId' | 'verb'> & {
  selectedFolder?: Folder;
};
export const [useFolderPickerModalContext, FolderPickerModalContext] = createUsableContext<Context>({
  hookName: 'useFolderPickerModalContext',
  providerName: 'FolderPickerModalContext.Provider',
});

export const PickerAddonTop: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <>{children}</>;
};

export const PickerAddonBottom: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <>{children}</>;
};

export const PickerLabel: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <>{children}</>;
};

export const FolderPickerModal: React.FC<React.PropsWithChildren<FolderPickerModalProps>> = ({
  initialFolderId,
  candidateType,
  candidateId,
  title,
  pickButton: PickButton,
  onPick,
  isLoading,
  loadingText,
  organizationId,
  children,
  verb = 'add',
  ...modalProps
}) => {
  const [selectedFolder, setSelectedFolder] = useState<Folder | undefined>(undefined);
  const [writeable, setWritable] = useState(false);
  const [privateLibrarySelected, setPrivateLibrarySelected] = useState(false);

  const { data: folders = [] } = useFoldersQuery();
  const folder = folders.find(f => f.id === initialFolderId);
  const isInitialFolderPrivate = folder ? isPrivateFolder(folder, folders) : false;

  if (selectedFolder && organizationId && selectedFolder.organization.id !== organizationId) {
    setSelectedFolder(undefined);
  }

  const isPublishing = verb === 'publish';
  let pickType: FolderPickType = 'within_library';
  if (privateLibrarySelected && (isPublishing || !isInitialFolderPrivate)) {
    pickType = 'to_private';
  } else if (!privateLibrarySelected && (isPublishing || isInitialFolderPrivate)) {
    pickType = 'to_organization';
  }

  const handleSelect = (libraryIsPrivate: boolean) => (folder: Folder, writeable: boolean) => {
    setSelectedFolder(folder);
    setWritable(writeable);
    setPrivateLibrarySelected(libraryIsPrivate);
  };
  const handleSelectPrivate = React.useMemo(() => handleSelect(true), []);
  const handleSelectOrganization = React.useMemo(() => handleSelect(false), []);

  const handlePick = () => {
    if (selectedFolder) {
      onPick?.(selectedFolder);
    }
  };
  const handleClose = () => {
    modalProps.onClose?.();
  };

  const hasPrivateRootFolder = !!findPrivateRootFolder(folders);
  // Disallow moving/publishing from organization to private
  // When the publish modal appears the item has already been created in some folder
  const movingFromOrganization = (verb === 'move' || verb === 'publish') && !isInitialFolderPrivate;
  const shouldShowPrivateFolders = hasPrivateRootFolder && !movingFromOrganization;

  let addonTop: React.ReactNode = null;
  let addonBottom: React.ReactNode = null;
  let pickerLabel: React.ReactNode = null;
  // `extends infer` is a way to "assign" an inferred type to a variable
  type Child = typeof children extends infer c ? (c extends any[] ? c[number] : c) : typeof children;
  const inspectChild = (child: Child) => {
    if (!React.isValidElement(child)) {
      throw new Error('Children for FolderPickerModal must be valid elements');
    }

    if (child.type === PickerAddonTop) {
      if (addonTop) {
        throw new Error('You can only use one PickerAddonTop element');
      }
      addonTop = child;
    }

    if (child.type === PickerAddonBottom) {
      if (addonBottom) {
        throw new Error('You can only use one PickerAddonTop element');
      }
      addonBottom = child;
    }
    if (child.type === PickerLabel) {
      if (pickerLabel) {
        throw new Error('You can only use one PickerLabel element');
      }
      pickerLabel = child;
    }

    if (ReactIs.isFragment(child)) {
      React.Children.forEach(child.props.children, inspectChild);
    }
  };
  React.Children.forEach(children, inspectChild);

  return (
    <FolderPickerModalContext.Provider value={{ candidateId, candidateType, initialFolderId, verb, selectedFolder }}>
      <Modal
        {...modalProps}
        onClose={handleClose}
        onEsc={handleClose}
        onOverlayClick={handleClose}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent pt="0">
          <ModalHeader fontSize="lg">{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody py="3" px={0}>
            <Box px="6">{addonTop}</Box>

            {shouldShowPrivateFolders && (
              <>
                <FolderPickerRoot
                  onSelect={handleSelectPrivate}
                  organizationId={organizationId}
                  libraryIsPrivate={true}
                >
                  <Box px="6">{pickerLabel}</Box>
                </FolderPickerRoot>
                <Divider opacity={1} borderColor="gray.200" my={2} mx={6} w="auto" />
              </>
            )}

            <FolderPickerRoot
              onSelect={handleSelectOrganization}
              organizationId={organizationId}
              libraryIsPrivate={false}
            >
              {!shouldShowPrivateFolders && <Box px="6">{pickerLabel}</Box>}
            </FolderPickerRoot>

            <Box px="6">{addonBottom}</Box>
          </ModalBody>
          <ModalFooter borderTop="1px" borderColor="gray.200">
            <Spacer />
            <Button isDisabled={isLoading} variant="ghost" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <PickButton
              isLoading={isLoading}
              loadingText={loadingText}
              onClick={handlePick}
              isDisabled={!selectedFolder || !writeable}
              pickType={pickType}
            ></PickButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FolderPickerModalContext.Provider>
  );
};
