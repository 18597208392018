import { createCachedAction } from 'reducers/util';

export const FOLDER_PERMIT_PUT = 'folderPermit/PUT';
export const FOLDER_PERMIT_DELETE = 'folderPermit/DELETE';
export const FOLDER_PERMIT_GET_ALL_BY_ORGANIZATION_ID = 'folderPermit/GET_ALL_BY_ORGANIZATION_ID';

export const FolderPermitActions = FolderPermitApi => {
  'ngInject';

  const getAllByOrganizationId = createCachedAction(
    FOLDER_PERMIT_GET_ALL_BY_ORGANIZATION_ID,
    FolderPermitApi.getAllByOrganizationId,
  );

  return {
    getAllByOrganizationId,
  };
};
