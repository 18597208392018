import { FOLDER_DELETE_BY_ID, FOLDER_UPDATE } from 'reducers/folder/folder.actions';
import {
  GROUP_CREATE,
  GROUP_DELETE,
  GROUP_UPDATE,
  GROUP_USER_ADD,
  GROUP_USER_REMOVE,
} from 'reducers/group/group.actions';
import { composeReducerObjects } from 'reducers/util';

const analyticsEvent = (action, properties) => ({ action, properties });

const groupActionsReducerObject = {
  [GROUP_CREATE]: (
    state,
    {
      payload: {
        group: {
          id,
          user: { username: name },
        },
      },
    },
  ) =>
    analyticsEvent('group created', {
      'group id': id,
      'group name': name,
    }),
  [GROUP_UPDATE]: () => analyticsEvent('group updated'),
  [GROUP_DELETE]: (
    state,
    {
      meta: {
        id,
        element: {
          user: { username: name },
        },
      },
    },
  ) =>
    analyticsEvent('group deleted', {
      id,
      name,
    }),
  [GROUP_USER_ADD]: (state, { meta: { email } }) =>
    analyticsEvent('user added someone to group', {
      'someone email': email,
    }),
  [GROUP_USER_REMOVE]: (
    state,
    {
      meta: {
        membership: {
          user: { email },
        },
      },
    },
  ) =>
    analyticsEvent('user removed someone from group', {
      'someone email': email,
    }),
};

const folderActionReducerObject = {
  [FOLDER_UPDATE]: (__state, { payload: folder }) =>
    analyticsEvent('folder updated', {
      id: folder.id,
      name: folder.name,
    }),
  [FOLDER_DELETE_BY_ID]: (__state, { meta: { folderId } }) =>
    analyticsEvent('folder deleted', {
      id: folderId,
    }),
};

/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "state" }]*/
export const analyticsReducer = composeReducerObjects(folderActionReducerObject, groupActionsReducerObject);
