import { ICellRendererParams } from '@ag-grid-community/core';
import { ProgressValue } from 'components/dashboard/models/grid';
import { Progress } from 'components/design/Progress';
import { Progress as ChProgress } from '@chakra-ui/react';
import * as React from 'react';
import styles from './ProgressRenderer.module.scss';
import { GridHelper } from 'components/dashboard/services/grid-helper';

export interface ProgressRendererProps extends Partial<ICellRendererParams> {
  value?: ProgressValue;
}

const ProgressSkeleton = ({ rowIndex }: { rowIndex: number }) => {
  const values = [80, 40, 50, 90, 70];

  return (
    <ChProgress
      value={values[rowIndex % values.length]}
      colorScheme="gray"
      w="200px"
      h="2"
      bgColor="gray.100"
      opacity="0.3"
      borderRadius="xs"
    />
  );
};

export const ProgressRenderer: React.FC<React.PropsWithChildren<ProgressRendererProps>> = ({
  value,
  rowIndex,
  data,
}) => {
  if (!value && GridHelper.isEmptyStateRow(data)) return <ProgressSkeleton rowIndex={rowIndex ?? 0} />;

  if (!value) {
    return <div />;
  }

  const percentage = value.totalTasks > 0 ? Math.round((value.completedTasks * 100) / value.totalTasks) : 0;

  return (
    <div className={styles.progressRenderer}>
      <Progress value={percentage} status={value.status} w="full" />
      <span className={styles.percentageLabel}>
        {value.completedTasks}/{value.totalTasks}
      </span>
    </div>
  );
};
