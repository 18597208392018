import { isNotIdRef, UserType } from '@process-street/subgrade/core';
import { ChecklistAssignment } from '@process-street/subgrade/role-assignment';
import { Avatar, AvatarSize } from 'components/common/Avatar';
import { Box, HStack, Icon, List, ListItem, ListProps, Skeleton, SkeletonCircle, Text } from 'components/design/next';
import { useUserDeactivationHelpers } from 'features/user/use-user-deactivation-helpers';
import * as React from 'react';
import { RemoveAssigneeButton } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers/AssigneesRenderer/reassign-modal/components/remove-assignee-button';

export type AssignmentsListProps = {
  assignments: ChecklistAssignment[];
  onRemoveAssignment: (user: ChecklistAssignment) => void;
};

export const AssignmentsList: React.FC<React.PropsWithChildren<ListProps & AssignmentsListProps>> = ({
  assignments,
  onRemoveAssignment,
  ...props
}) => {
  const deactivationHelpers = useUserDeactivationHelpers();

  return (
    <List w="full" {...props}>
      {assignments.map(
        assignment =>
          isNotIdRef(assignment.organizationMembership) &&
          isNotIdRef(assignment.organizationMembership.user) && (
            <ListItem
              key={assignment.organizationMembership.user.id}
              py="2"
              opacity={deactivationHelpers.isActive(assignment.organizationMembership.user.id) ? 1 : 0.6}
            >
              <HStack justifyContent="space-between">
                {deactivationHelpers.withTooltip(
                  assignment.organizationMembership.user.id,
                  <HStack>
                    <Box w="6" mr="1">
                      <Avatar
                        user={assignment.organizationMembership.user}
                        size={AvatarSize.Small}
                        showUnconfirmedBadge={true}
                      />
                    </Box>

                    <Box maxW={78}>
                      <Text variant="-1" fontWeight="medium" color="gray.600" noOfLines={1}>
                        {assignment.organizationMembership.user.username}
                      </Text>

                      <Text variant="-2" color="gray.500" noOfLines={1}>
                        {assignment.organizationMembership.user.userType === UserType.Group
                          ? 'Group'
                          : assignment.organizationMembership.user.email}
                      </Text>
                    </Box>
                  </HStack>,
                )}
                <RemoveAssigneeButton onClick={() => onRemoveAssignment(assignment)} />
              </HStack>
            </ListItem>
          ),
      )}
    </List>
  );
};

export const AssignmentItemSkeleton: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <ListItem py="2">
      <HStack justifyContent="space-between">
        <HStack>
          <SkeletonCircle size="6" />
          <Box>
            <Skeleton h="3">
              <Text>username</Text>
            </Skeleton>

            <Skeleton h="2" mt="1">
              <Text>user@mail.com</Text>
            </Skeleton>
          </Box>
        </HStack>
        <Skeleton h="6">
          <Icon variant="far" icon="user-times" size="6" />
        </Skeleton>
      </HStack>
    </ListItem>
  );
};
