import angular from 'angular';

angular.module('frontStreetApp.dao').service('DaoCacheService', function ($cacheFactory, $q) {
  const self = this;

  const cache = $cacheFactory('dao', { capacity: 100 });
  const permissionsCache = $cacheFactory('permissions', { capacity: 100 });
  const inboxItemsCache = $cacheFactory('inbox-items-dao', { capacity: 100 });

  self.clearAll = function () {
    self.getDaoCache().removeAll();
    self.getPermissionsCache().removeAll();
  };

  self.getDaoCache = function () {
    return cache;
  };

  self.getPermissionsCache = function () {
    return permissionsCache;
  };

  self.getInboxItemsCache = function () {
    return inboxItemsCache;
  };

  self.generateK = function (prefix) {
    return function (...args) {
      return [prefix].concat([].slice.apply(args)).join(':');
    };
  };

  self.makePromise = function (promiseMap, cacheKey, cacheValue, flushCache, f) {
    let promise;

    if (angular.isDefined(cacheValue) && !flushCache) {
      promise = $q.resolve(cacheValue);
    } else if (promiseMap[cacheKey]) {
      promise = promiseMap[cacheKey];
    } else {
      promise = f().finally(() => {
        delete promiseMap[cacheKey];
      });
      promiseMap[cacheKey] = promise;
    }

    return promise;
  };
});
