import * as React from 'react';

/**
 * A utility hook that other utility hooks/components can use to memoize functions.
 * This allows consumers to skip `React.useCallback`, moving the responsibility to the
 * utility to the call site of the function.
 * For more context: https://epicreact.dev/how-react-uses-closures-to-avoid-bugs/
 */
export function useFunctionRef<F extends { (...args: any[]): any } | undefined>(fn: F) {
  const fnRef = React.useRef(fn);
  React.useLayoutEffect(() => {
    fnRef.current = fn;
  });
  return fnRef;
}
