import { useMatch } from '@process-street/adapters/navigation';
import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import {
  TaskTemplatePermissionLevel,
  TaskTemplatePermissionLevels,
  TaskTemplatePermit,
  UpdateTaskTemplatePermit,
} from '@process-street/subgrade/permission';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from 'components/design/next';
import {
  GetTaskTemplatePermitsQuery,
  UpdateAllTaskTemplatesPermitsMutation,
} from 'features/template-revisions/query-builder';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { match } from 'ts-pattern';

export interface MembershipPermissionSelectorProps {
  membership: OrganizationMembershipWithUser;
  permits?: TaskTemplatePermit[];
  isPredefinedGroup: boolean;
  taskTemplates: TaskTemplate[];
  templateRevisionId: TemplateRevision['id'];
}

export const MembershipPermissionSelector: React.FC<React.PropsWithChildren<MembershipPermissionSelectorProps>> = ({
  membership,
  permits,
  isPredefinedGroup,
  taskTemplates,
  templateRevisionId,
}) => {
  const isEditorV2 = useMatch('templateV2');
  const { user } = membership;
  const queryClient = useQueryClient();

  const currentLevel = React.useMemo(() => {
    if (permits) {
      // Assume permits are provided filtered by membership and task template
      const hasTaskReadAccess = permits.some(permit => permit.taskRead);
      const hasTaskUpdateAccess = permits.some(permit => permit.taskUpdate);
      const hasAnyPermit = permits.length > 0;

      return match({ hasTaskReadAccess, hasTaskUpdateAccess, hasAnyPermit, isPredefinedGroup })
        .with({ hasTaskReadAccess: true, hasTaskUpdateAccess: true }, () => TaskTemplatePermissionLevel.ViewAndComplete)
        .with({ hasAnyPermit: false, isPredefinedGroup: true }, () => TaskTemplatePermissionLevel.ViewAndComplete)
        .with({ hasTaskReadAccess: true }, () => TaskTemplatePermissionLevel.ViewOnly)
        .with({ hasTaskReadAccess: false }, () => TaskTemplatePermissionLevel.NoAccess)
        .otherwise(() => undefined);
    }

    return undefined;
  }, [permits, isPredefinedGroup]);

  const { mutate } = UpdateAllTaskTemplatesPermitsMutation.useMutation({
    onSuccess: _ => {
      queryClient.invalidateQueries(GetTaskTemplatePermitsQuery.getKey({ templateRevisionId }));
    },
  });

  const handleChange = (level: TaskTemplatePermissionLevel) => {
    const taskRead =
      level === TaskTemplatePermissionLevel.ViewAndComplete || level === TaskTemplatePermissionLevel.ViewOnly;
    const taskUpdate = level === TaskTemplatePermissionLevel.ViewAndComplete;

    const permits: Array<UpdateTaskTemplatePermit> = taskTemplates.map(taskTemplate => ({
      taskRead,
      taskUpdate,
      organizationMembershipId: membership.id,
      taskTemplateId: taskTemplate.id,
      templateRevisionId,
    }));

    mutate({
      templateRevisionId,
      permits,
    });
  };

  const levels = isPredefinedGroup
    ? TaskTemplatePermissionLevels
    : TaskTemplatePermissionLevels.filter(level => level !== TaskTemplatePermissionLevel.NoAccess);

  return (
    <Menu autoSelect={false} isLazy={true}>
      {currentLevel && (
        <>
          <MenuButton
            as={Button}
            aria-label={user.username}
            variant="unstyled"
            rightIcon={<Icon icon="angle-down" size="3" variant="far" />}
            size="sm"
            color="gray.600"
            textAlign="right"
            display="inline-flex"
            alignItems="center"
            fontSize={isEditorV2 ? 'md' : 'sm'}
          >
            {currentLevel}
          </MenuButton>

          <MenuList color="gray.600" zIndex="dropdown">
            {levels.map(level => (
              <MenuItem onClick={() => handleChange(level)} key={level} data-item-selected={currentLevel === level}>
                {level}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
