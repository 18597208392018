import { ELEMENT_PS_SOFT_BREAK } from 'pages/pages/_id/edit/page/plugins';
import { createSendRichEmailPluginFactory } from '../../send-rich-email-plate-types';
import { onKeyDownSoftBreak } from './onKeyDownSoftBreak';
import { SoftBreakPlugin } from './types';

/**
 * Insert soft break following configurable rules.
 * Each rule specifies a hotkey and query options.
 */
export const createSoftBreakPlugin = createSendRichEmailPluginFactory<SoftBreakPlugin>({
  key: ELEMENT_PS_SOFT_BREAK,
  handlers: {
    onKeyDown: onKeyDownSoftBreak,
  },
  isElement: true,
  isInline: true,
  options: {
    rules: [{ hotkey: 'shift+enter' }],
  },
});
