import { Checklist, ChecklistMigrationStatus, ChecklistStatus } from '@process-street/subgrade/process';

export const isChecklistActionable = (checklist?: Checklist) => {
  if (!checklist) {
    return undefined;
  }

  const { migrationStatus, status } = checklist;
  const migrating = migrationStatus !== ChecklistMigrationStatus.Inactive;
  const active = status === ChecklistStatus.Active;

  return active && !migrating;
};
