import angular from 'angular';
import { htmlEscaped } from '@process-street/subgrade/util';
import templateUrl from './select-template.component.html';

angular.module('frontStreetApp.directives').directive('psSelectTemplate', () => ({
  scope: {
    templates: '=psSelectTemplate',
    maxSuggestions: '=selectTemplateMaxSuggestions',
  },
  restrict: 'A',
  templateUrl,
  link(scope) {
    scope.$watch('templates', templates => {
      scope.getTemplates = function (pattern) {
        const lowerPattern = pattern.toLowerCase();
        if (lowerPattern) {
          return templates
            .filter(template => template.name.toLowerCase().includes(lowerPattern))
            .slice(0, scope.maxSuggestions);
        } else {
          return templates;
        }
      };

      scope.getTemplateName = template => htmlEscaped`${template.name}`;
    });
  },
}));
