import { Approval } from '@process-street/subgrade/approval-rule/approval.model';
import { Muid } from '@process-street/subgrade/core';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { EntityMap, LookupMap, RequestStage } from '@process-street/subgrade/redux/types';
import {
  APPROVAL_GET_ALL_BY_CHECKLIST_REVISION,
  APPROVAL_UPSERT_ALL,
  APPROVAL_UPSERT_ALL_BY_TASK_ID,
} from 'components/approvals/store/approval.actions';
import { withOptimisticLookupsReducer } from 'reducers/optimistic';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { ReducerObject } from 'reducers/util/types';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

interface ApprovalActionType {
  payload: Approval[];
  meta: { checklistRevisionId: Muid };
}

const approvalEntitiesActionHandlers: ReducerObject<EntityMap<Approval>> = {
  [APPROVAL_GET_ALL_BY_CHECKLIST_REVISION + RequestStage.Success]: (
    state: EntityMap<Approval>,
    { payload: approvals }: ApprovalActionType,
  ) => EntitiesReducerUtils.upsertAll(state, approvals),
  [APPROVAL_UPSERT_ALL + RequestStage.Success]: (
    state: EntityMap<Approval>,
    { payload: approvals }: ApprovalActionType,
  ) => EntitiesReducerUtils.upsertAll(state, approvals),
  [APPROVAL_UPSERT_ALL_BY_TASK_ID + RequestStage.Success]: (
    state: EntityMap<Approval>,
    { payload: approvals }: ApprovalActionType,
  ) => EntitiesReducerUtils.upsertAll(state, approvals),
};

export const approvalReducer = handleActions(approvalEntitiesActionHandlers, {});

const approvalByChecklistRevisionIdActionHandlers: ReducerObject<LookupMap> = {
  [APPROVAL_GET_ALL_BY_CHECKLIST_REVISION + RequestStage.Success]: (
    state: LookupMap,
    { payload: approvals, meta: { checklistRevisionId } }: ApprovalActionType,
  ) => LookupsReducerUtils.replaceAllUsingSelectorFunctions(state, approvals, () => checklistRevisionId),
  [APPROVAL_UPSERT_ALL]: (
    state: LookupMap,
    { payload: approvals, meta: { checklistRevisionId } }: ApprovalActionType,
  ) => LookupsReducerUtils.upsertAllUsingSelectorFunctions(state, approvals, () => checklistRevisionId),
  [APPROVAL_UPSERT_ALL_BY_TASK_ID]: (
    state: LookupMap,
    { payload: approvals, meta: { checklistRevisionId } }: ApprovalActionType,
  ) => LookupsReducerUtils.upsertAllUsingSelectorFunctions(state, approvals, () => checklistRevisionId),
};

export const approvalByChecklistRevisionIdReducer = withOptimisticLookupsReducer<Approval>(
  optimisticResult => optimisticResult.approvalEvents,
  (_approval: Approval, action) => action.meta.checklistRevisionId,
  handleActionsOnSuccess(approvalByChecklistRevisionIdActionHandlers, {}),
  APPROVAL_UPSERT_ALL,
);

export const approvalLookupsReducer = combineReducers({
  byChecklistRevisionId: approvalByChecklistRevisionIdReducer,
});
