import { FieldType } from '@process-street/subgrade/process';
import { HStack, Icon, Text } from 'components/design/next';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import * as React from 'react';

export type AiTaskWidgetSelectItemProps = {
  label: string;
  fieldType?: FieldType;
};

type ExcludedFieldTypes = FieldType.Members | FieldType.MultiChoice | FieldType.MultiSelect | FieldType.File;

const ICONS: Record<Exclude<FieldType, ExcludedFieldTypes>, CustomIconName> = {
  Date: 'calendar-alt',
  Email: 'envelope',
  Hidden: 'eye-slash',
  Number: 'tally',
  Select: 'square-chevron-down',
  SendRichEmail: 'envelope',
  Snippet: 'brackets-curly',
  Table: 'table',
  Text: 'font',
  Textarea: 'paragraph',
  Url: 'globe',
};

export const AiTaskWidgetSelectItem: React.FC<React.PropsWithChildren<AiTaskWidgetSelectItemProps>> = ({
  label,
  fieldType,
}) => {
  return (
    <HStack spacing="2">
      {fieldType && fieldType in ICONS ? (
        <Icon icon={ICONS[fieldType as keyof typeof ICONS]} color="gray.500" size="4" />
      ) : null}
      <Text>{label}</Text>
    </HStack>
  );
};
