import { match } from 'ts-pattern';

export function getTooltipLabel<Option>({
  options,
  getOptionLabel,
  visibleValuesCount,
  visibleLimitReached,
}: {
  options: readonly Option[];
  getOptionLabel: (option: Option) => string;
  visibleValuesCount: number;
  visibleLimitReached: boolean;
}) {
  const [head, ...tail] = options.slice(visibleValuesCount);
  if (!head) return '';

  const tailLabel = match(tail.length)
    .with(0, () => '')
    .with(1, () => ` and ${getOptionLabel(tail[0])}`)
    .otherwise(() => ` and ${tail.length} others`);

  const tooltipLabel = visibleLimitReached ? `${getOptionLabel(head)}${tailLabel}` : '';
  return tooltipLabel;
}
