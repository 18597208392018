import * as React from 'react';
import { Button, Icon } from 'components/design/next';
import { PaywallButton } from 'components/paywalls';
import { useRunWorkflowPaywall } from 'components/paywalls/run-workflow';
import { NgLink } from 'features/app/components/ng-link';

export type Props = {
  isVisible: boolean;
  // ng props
  inline?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  label?: string;
};

export const RunWorkflowButton: React.FC<React.PropsWithChildren<Props>> = ({
  isVisible,
  // react props
  children,
  // ng props
  onClick,
  label = 'Run',
  isLoading,
  inline = false,
}) => {
  const btn = React.useMemo(() => {
    if (children === undefined) {
      return (
        <Button
          loadingText="Starting..."
          isLoading={isLoading}
          padding="7px 12px"
          color="white"
          bgColor="brand.500"
          borderRadius="base"
          mb={inline ? '0' : '5'}
          position="relative"
          _hover={{ bgColor: 'brand.600' }}
          onClick={onClick}
          isDisabled={isLoading}
          variant="unstyled"
          leftIcon={<Icon icon="play" variant="far" size="4" />}
          iconSpacing="2"
          display="flex"
          w="full"
          // unfortunate nudging to get it to line up with other angular bootstrap anchor elements
          justifyContent="flex-start"
          fontWeight="bold"
        >
          {label}
        </Button>
      );
    }

    if (React.isValidElement(children)) {
      if (!isButtonOrLinkLike(children)) {
        throw new Error('RunWorkflowButton child must have an onClick or href to intercept');
      }
      return children;
    }

    return null;
  }, [children, isLoading, inline, onClick, label]);

  if (!isVisible) return null;

  return <PaywallButton useHook={useRunWorkflowPaywall}>{btn}</PaywallButton>;
};

function isButtonOrLinkLike(element: React.ReactElement): boolean {
  const { props } = element;
  const isButtonLike = props.onClick;
  const isLinkLike = props.href;
  const isNgLinkLinke = element.type === NgLink || props.as === NgLink;
  return isButtonLike || isLinkLike || isNgLinkLinke;
}
