import * as React from 'react';
import {
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from 'components/design/next';
import { PublicTemplate } from '@process-street/subgrade/process';
import { generateEmbed, generateShareLinks } from 'features/template/share-utils';
import { useCopyToClipboard } from 'react-use';
import { AnalyticsService } from 'components/analytics/analytics.service';

export type Props = Omit<ModalProps, 'children'> & { template: PublicTemplate };

export const PublicShareModal: React.FC<React.PropsWithChildren<Props>> = ({ template, ...modalProps }) => {
  const { name } = template;

  const embed = generateEmbed(template);
  const shareLink = generateShareLinks(template);

  const shareWith = (method: string) => () => {
    AnalyticsService.trackEvent('template shared', {
      method,
    });
  };

  const [{ value: shareLinkCopied }, copyShareLinkToClipboard] = useCopyToClipboard();
  const [{ value: embedCopied }, copyEmbedToClipboard] = useCopyToClipboard();
  const copyShareLinkToClipboardRef = React.useRef<HTMLButtonElement>(null);

  return (
    <Modal {...modalProps} initialFocusRef={copyShareLinkToClipboardRef} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Share Workflow: {name}</ModalHeader>
        <ModalBody pb="4">
          <Text>
            This workflow is <b>public</b> and can be seen by anyone.
          </Text>

          <FormControl id="public-url">
            <FormLabel mt="5">Share</FormLabel>
            <InputGroup>
              <Input type="text" value={template.publicUrl} onFocus={shareWith('Link')} isReadOnly={true} />
              <InputRightElement>
                <IconButton
                  ref={copyShareLinkToClipboardRef}
                  variant="ghost"
                  aria-label="copy link"
                  icon={
                    <Icon
                      icon={shareLinkCopied ? 'clipboard-check' : 'clipboard'}
                      size="4"
                      variant="far"
                      color={shareLinkCopied ? 'green.500' : 'inherit'}
                    />
                  }
                  onClick={() => copyShareLinkToClipboard(template.publicUrl)}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <HStack spacing="2" w="full" my="6">
            <Link href={shareLink.facebook} title="Facebook">
              <IconButton
                onClick={shareWith('Facebook')}
                icon={<Icon variant="fab" icon="facebook-f" size="6" />}
                aria-label="Facebook"
              />
            </Link>

            <Link href={shareLink.twitter} title="Twitter">
              <IconButton
                onClick={shareWith('Twitter')}
                icon={<Icon variant="fab" icon="twitter" size="6" />}
                aria-label="Twitter"
              />
            </Link>

            <Link href={shareLink.linkedIn} title="LinkedIn">
              <IconButton
                onClick={shareWith('LinkedIn')}
                icon={<Icon variant="fab" icon="linkedin-in" size="6" />}
                aria-label="LinkedIn"
              />
            </Link>

            <Link href={shareLink.email} target="_blank" rel="noopener noreferrer" title="Email">
              <IconButton
                onClick={shareWith('Email')}
                icon={<Icon variant="far" icon="envelope" size="4" />}
                aria-label="Email"
              />
            </Link>
          </HStack>

          <FormControl id="embed">
            <FormLabel>Embed</FormLabel>
            <InputGroup>
              <Input type="text" value={embed} onFocus={shareWith('Embed')} isReadOnly={true} />

              <InputRightElement>
                <IconButton
                  variant="ghost"
                  aria-label="copy link"
                  icon={
                    <Icon
                      icon={embedCopied ? 'clipboard-check' : 'clipboard'}
                      size="4"
                      variant="far"
                      color={embedCopied ? 'green.500' : 'inherit'}
                    />
                  }
                  onClick={() => copyEmbedToClipboard(embed)}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
