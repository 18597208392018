import React from 'react';
import {
  FormSolutionTypeTagDescriptionMap,
  isNativeAutomationWithLink,
  isTemplateSolutionInstance,
  SolutionTypeTag,
  SolutionTypeTagDescriptionMap,
} from '@process-street/subgrade/automation';
import { AutomationType } from '../type';
import { AutomationList } from '../list';
import { Box, Spinner, VStack } from 'components/design/next';
import { useSolutionTypeTagInstances } from './use-solution-type-tag-instances';
import { useAutomationSelector } from './context';
import { useTaskTemplate } from '../../use-task-template';
import { useTaskTemplateGroupId } from '../../utils/use-task-template-group-id';
import { useTemplateId } from '../../utils/use-template-id';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { match } from 'ts-pattern';
import { TemplateType } from '@process-street/subgrade/process';
import { useSelectedOrganization } from 'hooks/use-selected-organization';

export const AutomationsSelector: React.VFC = () => {
  const templateId = useTemplateId();
  const selectedOrganizationId = useSelectedOrganization();
  const { instances } = useSolutionTypeTagInstances({ templateId });
  const templateQuery = useGetTemplateQuery({ templateId });
  const [state] = useAutomationSelector();
  const { modalView } = state.context;
  const taskTemplateGroupId = useTaskTemplateGroupId();
  const taskTemplate = useTaskTemplate();

  if (!selectedOrganizationId) {
    return null;
  }

  if (templateQuery.status === 'loading') {
    return <Spinner />;
  }

  const visibleInstanceTypes = match({ template: templateQuery.data, modalView })
    .with({ template: { templateType: TemplateType.Form } }, () => ({
      [SolutionTypeTag.WhenChecklistCompletedThen]: instances[SolutionTypeTag.WhenChecklistCompletedThen],
    }))
    .with({ modalView: 'task' }, () => ({
      [SolutionTypeTag.WhenTaskCheckedThen]: instances[SolutionTypeTag.WhenTaskCheckedThen],
    }))
    .otherwise(() => instances);

  return (
    <VStack overflowY="auto" height="100%" width="100%" align="stretch" spacing="4" py="8">
      {Object.entries(visibleInstanceTypes).map(([instanceType, instances]) => {
        const filteredInstances =
          instanceType === SolutionTypeTag.WhenTaskCheckedThen
            ? instances.filter(
                i =>
                  (isTemplateSolutionInstance(i) && i.taskTemplateGroupId === taskTemplateGroupId) ||
                  (isNativeAutomationWithLink(i) && i.link.taskTemplateId === taskTemplate?.id),
              )
            : instances;
        const descriptionMap =
          templateQuery.data?.templateType === TemplateType.Playbook
            ? SolutionTypeTagDescriptionMap
            : FormSolutionTypeTagDescriptionMap;
        const instanceTypeTitle =
          modalView === 'task'
            ? `When ${taskTemplate?.name ?? 'the task'} is checked ...`
            : descriptionMap[instanceType as SolutionTypeTag];

        return (
          <VStack align="stretch" key={instanceType} spacing="2">
            <Box>
              {/* Single item when creating a new task automation */}
              <AutomationType
                title={instanceTypeTitle}
                type={instanceType as SolutionTypeTag}
                selected={state.matches('creating') && state.context.solutionTypeTag === instanceType}
              />
            </Box>
            {filteredInstances.length > 0 && (
              <Box>
                <AutomationList automationInstances={filteredInstances} />
              </Box>
            )}
          </VStack>
        );
      })}
    </VStack>
  );
};
