import { DataSetRow } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { Muid } from '@process-street/subgrade/core';

export type CreateDataSetRowMutationParams = {
  dataSetId: string;
  rowId: string;
  cells?: Record<Muid, string>;
};

type CreateDataSetMutationResponse = DataSetRow;

export const CreateDataSetRowMutation = {
  mutationFn: (params: CreateDataSetRowMutationParams) =>
    axiosService
      .getAxios()
      .post<CreateDataSetMutationResponse>(`/1/data-sets/${params.dataSetId}/rows`, params)
      .then(res => res.data),
};

export const useCreateDataSetRowMutation = (
  options: UseMutationOptions<CreateDataSetMutationResponse, AxiosError, CreateDataSetRowMutationParams> = {},
) => {
  return useMutation(CreateDataSetRowMutation.mutationFn, options);
};
