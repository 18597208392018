import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { API, UpdateTemplateForm } from 'pages/pages/_id/edit/page/api';
import { Muid } from '@process-street/subgrade/core';

export type UpdateTemplateMutationGenerics = {
  data: Template;
  variables: UpdateTemplateForm;
  error: AxiosError;
};
export const UpdateTemplateMutation = {
  key: ['update', 'templates'],
  getKey(templateId: Muid) {
    return ['update', 'templates', templateId];
  },
  mutationFn: API.updateTemplate,
};

export type DeleteTemplateMutationGenerics = {
  data: void;
  variables: { templateId: Template['id'] };
  error: AxiosError;
};
export const DeleteTemplateMutation = {
  key: ['delete', 'templates'],
  getKey(templateId: Muid) {
    return ['delete', 'templates', templateId];
  },
  mutationFn: API.deleteTemplate,
};
