import {
  FOLDER_DELETE_BY_ID,
  FOLDER_GET_ALL_BY_ORGANIZATION_ID,
  FOLDER_GET_BY_ID,
  FOLDER_SET,
  FOLDER_UPDATE,
} from 'reducers/folder/folder.actions';
import { combineActions } from 'redux-actions';
import { appendOnPutReducerObject, referencesNormalizer } from 'reducers/entities/entities.utils';
import { FOLDER_PERMIT_PUT } from 'reducers/folder-permit/folder-permit.actions';
import { composeReducerObjects } from 'reducers/util';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';

const normalizeFolder = referencesNormalizer(['organization', 'parentFolder', 'deletedBy']);

const FOLDER_SINGLE_ACTIONS = combineActions(FOLDER_GET_BY_ID, FOLDER_UPDATE, FOLDER_SET);

const folderApiReducerObject = {
  [FOLDER_SINGLE_ACTIONS]: (state, { payload: folder }) => {
    const nextState = { ...state };
    nextState[folder.id] = normalizeFolder(folder);
    if (folder.parentFolder && Object.keys(folder.parentFolder).length !== 1) {
      nextState[folder.parentFolder.id] = normalizeFolder(folder.parentFolder);
    }
    return nextState;
  },
  [FOLDER_GET_ALL_BY_ORGANIZATION_ID]: (state, { payload: folders }) => {
    const nextState = { ...state };
    folders.forEach(folder => {
      nextState[folder.id] = normalizeFolder(folder);
      if (folder.parentFolder && Object.keys(folder.parentFolder).length !== 1) {
        nextState[folder.parentFolder.id] = normalizeFolder(folder.parentFolder);
      }
    });
    return nextState;
  },
  [FOLDER_DELETE_BY_ID]: (state, { meta: { folderId } }) => EntitiesReducerUtils.deleteById(state, folderId),
};

export const folderEntitiesReducer = composeReducerObjects(
  folderApiReducerObject,
  appendOnPutReducerObject(FOLDER_PERMIT_PUT, 'folder', normalizeFolder),
);
