import { entitiesSelector } from 'reducers/entities/entities.selectors';
import { lookupsSelector } from 'reducers/lookups/lookups.selectors';
import { createSelector } from 'reselect';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { Entities } from 'reducers/entities/entities';

const entityMap = state => entitiesSelector(state)[Entities.CHECKLIST_TASK_ASSIGNMENT];

const lookups = state => lookupsSelector(state)[Entities.CHECKLIST_TASK_ASSIGNMENT];

const lookupByTaskId = state => lookups(state).byTaskId;

const getAllIdsByTaskId = taskId => createSelector(lookupByTaskId, byTaskIdMap => byTaskIdMap[taskId] || []);

const getAllByTaskIdWithOrganizationMembershipAndUser = taskId =>
  createSelector(
    [getAllIdsByTaskId(taskId), entityMap, OrganizationMembershipSelector.getAllWithUserBySelectedOrganizationId],
    (assignmentIds, entitiesMap, organizationMemberships) =>
      assignmentIds.reduce((assignments, assignmentId) => {
        const assignment = entitiesMap[assignmentId];

        if (assignment) {
          const assignmentMembership = organizationMemberships.find(
            om => om.id === assignment.organizationMembership.id,
          );

          if (assignmentMembership && assignmentMembership.user) {
            assignments.push({ ...assignment, organizationMembership: assignmentMembership });
          }
        }

        return assignments;
      }, []),
  );

export const ChecklistTaskAssignmentSelector = {
  getAllByTaskIdWithOrganizationMembershipAndUser,
};
