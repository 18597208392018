import { Muid } from '@process-street/subgrade/core';
import { Widget } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type AiGenerationWidgetssMutationParams = {
  taskTemplateId: Muid;
};

type AiGenerationWidgetssMutationResult = Widget[];

export const AiGenerationWidgetsMutation = {
  key: ['ai-generation', 'widgets'],
  mutationFn: (params: AiGenerationWidgetssMutationParams) =>
    axiosService
      .getAxios()
      .post<AiGenerationWidgetssMutationResult>('/1/ai-generation/widgets', params)
      .then(r => r.data),
};

export const useAiGenerationWidgetsMutation = (
  options: UseMutationOptions<AiGenerationWidgetssMutationResult, AxiosError, AiGenerationWidgetssMutationParams> = {},
) => {
  return useMutation(AiGenerationWidgetsMutation.mutationFn, {
    mutationKey: AiGenerationWidgetsMutation.key,
    ...options,
  });
};
