import * as React from 'react';
import { CreateTemplateSource } from 'services/template-service.interface';
import { Button, ButtonProps, HStack, Icon, Skeleton, Text, useDisclosure, VStack } from 'components/design/next';
import { useFeatureFlag } from 'features/feature-flags';
import { useNewBlankWorkflowButton } from 'features/new-buttons/use-new-blank-workflow-button';
import { PaywallButton } from 'components/paywalls';
import { useCreateTemplatePaywall } from 'components/paywalls/create-template';
import { useNewWorkflowFromTemplateButton } from 'features/new-buttons/use-new-workflow-from-template-button';
import { useNewBlankFormButton } from 'features/new-buttons/use-new-blank-form-button';
import { useNewBlankPageButton } from 'features/new-buttons/use-new-blank-page-button';
import { useCurrentOrPrivateRootFolderQuery } from 'features/new-menu/query';
import { NewFolderModal } from 'features/new-menu/new-folder-modal';
import { FolderPickerModal, PickButtonProps } from 'components/folder-picker/modal';
import { NewWorkflowModal } from 'features/new-menu/new-workflow-modal';
import { useOneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { CreateDataSetModal } from 'pages/data-sets/components/create-data-set-modal';
import { useCanViewDataSets } from 'app/pages/reports/data-sets/hooks/use-can-view-data-sets';
import { AiGeneratedDataSetSettingsModal } from 'app/pages/data-sets/components/ai-generated-dataset-settings-modal';

const PickButton: React.FC<React.PropsWithChildren<PickButtonProps>> = ({ pickType, ...props }) => (
  <Button {...props}>
    Create
    {pickType === 'to_private' ? ' to Private' : null}
    {pickType === 'to_organization' ? ' to Organization' : null}
  </Button>
);

export const CreateActions = ({ source }: { source: CreateTemplateSource }) => {
  const organizationId = useSelectedOrganization()?.id;

  const { buttonProps: newBlankWorkflowButtonProps, folderPickerProps: newBlankWorkflowFolderPickerProps } =
    useNewBlankWorkflowButton({ source, allowSelectFolder: true });
  const { folderPickerProps: newWorkflowFromTemplateFolderPickerProps } = useNewWorkflowFromTemplateButton({
    source,
    allowSelectFolder: true,
  });
  const { buttonProps: newBlankFormButtonProps, folderPickerProps: newBlankFormFolderPickerProps } =
    useNewBlankFormButton({ source, allowSelectFolder: true });
  const { buttonProps: newBlankPageButtonProps, folderPickerProps: newBlankPageFolderPickerProps } =
    useNewBlankPageButton({ source, allowSelectFolder: true });

  const { data: currentFolder, isLoading: currentFolderLoading } = useCurrentOrPrivateRootFolderQuery();
  const newFolderDisclosure = useDisclosure();
  const addFolderIsLoading = currentFolderLoading;
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');

  const folderPickerProps = [
    newBlankWorkflowFolderPickerProps,
    newWorkflowFromTemplateFolderPickerProps,
    newBlankFormFolderPickerProps,
    newBlankPageFolderPickerProps,
  ].find(props => props.isOpen);

  const newWorkflowDisclosure = useDisclosure();
  const createDataSetDisclosure = useDisclosure();
  const generateDataSetWithAiDisclosure = useDisclosure();
  const { createProjectTask } = useOneOffTaskDrawerStore();
  const userCanViewDataSets = useCanViewDataSets();

  return (
    <>
      <VStack px="6" pb="2" spacing="0" bg="transparent" w="full" alignItems="flex-start" shouldWrapChildren={false}>
        <Text fontWeight="normal" color="gray.500" fontSize="sm">
          New...
        </Text>
        <VStack pt="1" alignItems="flex-start" w="full" spacing="0" shouldWrapChildren={false}>
          {newBlankWorkflowButtonProps.isLoading ? (
            <CreateActionItemSkeleton text="Workflow" />
          ) : (
            <>
              <PaywallButton useHook={useCreateTemplatePaywall}>
                <CreateActionItemButton aria-label="Create Workflow" onClick={newWorkflowDisclosure.onOpen}>
                  <HStack justifyContent="space-between">
                    <HStack spacing="2">
                      <Icon
                        position="relative"
                        variant="fas"
                        icon="workflow"
                        color="indigo.500"
                        size="4"
                        _groupHover={{ color: 'indigo.600' }}
                      />
                      <Text fontSize="sm" _groupHover={{ color: 'brand.500' }}>
                        Workflow
                      </Text>
                    </HStack>
                    <Icon icon="plus" variant="far" size="3" color="gray.600" _groupHover={{ color: 'brand.500' }} />
                  </HStack>
                </CreateActionItemButton>
              </PaywallButton>
            </>
          )}

          {newBlankFormButtonProps.isLoading ? (
            <CreateActionItemSkeleton text="Form" />
          ) : (
            <PaywallButton useHook={useCreateTemplatePaywall}>
              {/* Always enable button to show paywall for internal guests */}
              <CreateActionItemButton aria-label="Create Form" {...newBlankFormButtonProps} isDisabled={false}>
                <HStack justifyContent="space-between">
                  <HStack spacing="2">
                    <Icon
                      position="relative"
                      variant="fas"
                      icon="ballot"
                      color="orange.500"
                      size="4"
                      _groupHover={{ color: 'orange.600' }}
                    />
                    <Text fontSize="sm" _groupHover={{ color: 'brand.500' }}>
                      Form
                    </Text>
                  </HStack>
                  <Icon icon="plus" variant="far" size="3" color="gray.600" _groupHover={{ color: 'brand.500' }} />
                </HStack>
              </CreateActionItemButton>
            </PaywallButton>
          )}

          {isDataSetsV2Enabled && userCanViewDataSets && (
            <CreateActionItemButton
              aria-label="Create Data Set"
              onClick={createDataSetDisclosure.onOpen}
              isDisabled={false}
            >
              <HStack justifyContent="space-between">
                <HStack spacing="2">
                  <Icon position="relative" variant="fas" icon="database" color="yellow.500" size="4" />
                  <Text fontSize="sm" _groupHover={{ color: 'brand.500' }}>
                    Data Set
                  </Text>
                </HStack>
                <Icon icon="plus" variant="far" size="3" color="gray.600" _groupHover={{ color: 'brand.500' }} />
              </HStack>
            </CreateActionItemButton>
          )}

          {newBlankPageButtonProps.isLoading ? (
            <CreateActionItemSkeleton text="Page" />
          ) : (
            // Page will be auto-created in private folder
            <CreateActionItemButton aria-label="Create Page" {...newBlankPageButtonProps} isDisabled={false}>
              <HStack justifyContent="space-between">
                <HStack spacing="2">
                  <Icon
                    position="relative"
                    variant="fas"
                    icon="file-alt"
                    color="teal.500"
                    _groupHover={{ color: 'teal.600' }}
                    size="4"
                  />
                  <Text fontSize="sm" _groupHover={{ color: 'brand.500' }}>
                    Page
                  </Text>
                </HStack>
                <Icon icon="plus" variant="far" size="3" color="gray.600" _groupHover={{ color: 'brand.500' }} />
              </HStack>
            </CreateActionItemButton>
          )}

          <CreateActionItemButton aria-label="Create Task" onClick={createProjectTask}>
            <HStack justifyContent="space-between">
              <HStack spacing="2">
                <Icon
                  variant="fad"
                  icon="circle-check"
                  primaryLayer={{
                    opacity: 1,
                    color: 'teal.500',
                  }}
                  secondaryLayer={{
                    opacity: 0.4,
                    color: 'teal.500',
                  }}
                  _groupHover={{ color: 'teal.600' }}
                  size="4"
                />
                <Text fontSize="sm" _groupHover={{ color: 'brand.500' }}>
                  Task
                </Text>
              </HStack>
              <Icon icon="plus" variant="far" size="3" color="gray.600" _groupHover={{ color: 'brand.500' }} />
            </HStack>
          </CreateActionItemButton>

          {addFolderIsLoading ? (
            <CreateActionItemSkeleton text="Folder" />
          ) : (
            <CreateActionItemButton
              aria-label="Create Folder"
              onClick={newFolderDisclosure.onOpen}
              isLoading={addFolderIsLoading}
            >
              <HStack justifyContent="space-between">
                <HStack spacing="2">
                  <Icon
                    position="relative"
                    variant="fas"
                    icon="folder"
                    color="gray.500"
                    _groupHover={{ color: 'gray.600' }}
                    size="4"
                  />
                  <Text fontSize="sm" _groupHover={{ color: 'brand.500' }}>
                    Folder
                  </Text>
                </HStack>
                <Icon icon="plus" variant="far" size="3" color="gray.600" _groupHover={{ color: 'brand.500' }} />
              </HStack>
            </CreateActionItemButton>
          )}
        </VStack>
      </VStack>
      {currentFolder ? (
        <NewFolderModal
          selectedOrganizationId={currentFolder.organization.id}
          currentFolderId={currentFolder.id}
          {...newFolderDisclosure}
        />
      ) : null}
      {folderPickerProps && (
        <FolderPickerModal
          {...folderPickerProps}
          verb="add"
          organizationId={organizationId}
          pickButton={PickButton}
        ></FolderPickerModal>
      )}
      {newWorkflowDisclosure.isOpen && <NewWorkflowModal {...newWorkflowDisclosure} />}
      {createDataSetDisclosure.isOpen && (
        <CreateDataSetModal
          {...createDataSetDisclosure}
          onGenerateWithAi={() => {
            createDataSetDisclosure.onClose();
            generateDataSetWithAiDisclosure.onOpen();
          }}
        />
      )}
      {generateDataSetWithAiDisclosure.isOpen && (
        <AiGeneratedDataSetSettingsModal {...generateDataSetWithAiDisclosure} />
      )}
    </>
  );
};

const CreateActionItemButton: React.FC<React.PropsWithChildren<ButtonProps>> = ({ children, ...props }) => {
  return (
    // data-group attribute enables group hover styles
    <Button data-group w="full" minH="7" variant="unstyled" borderRadius="0" {...props}>
      {children}
    </Button>
  );
};

const CreateActionItemSkeleton: React.FC<React.PropsWithChildren<{ text: string }>> = ({ text }) => {
  return (
    <HStack justifyContent="space-between" minH="7" w="full">
      <HStack spacing="3">
        <Skeleton h="4">
          <Icon variant="far" icon="workflow" size="4" />
        </Skeleton>
        <Skeleton h="4">
          <Text fontSize="sm">{text}</Text>
        </Skeleton>
      </HStack>
      <Skeleton h="3">
        <Icon variant="far" icon="plus" size="3" />
      </Skeleton>
    </HStack>
  );
};
