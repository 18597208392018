import { queryClient } from 'components/react-root';
import { GetOrganizationStats } from 'features/organization/query-builder';

export class OrganizationApi {
  constructor(Organizations, $q) {
    'ngInject';

    this.Organizations = Organizations;
    this.$q = $q;
  }

  create = (name, timeZone) => this.Organizations.create({ name, timeZone }).$promise;

  getById = id => this.Organizations.getById({ id }).$promise;

  /**
   * Gets discount by organization id
   * @return {Promise} with discount information
   */
  getDiscountByOrganizationId = id => this.Organizations.getDiscountByOrganizationId({ id }).$promise;

  updateById = (organization, info) =>
    this.Organizations.updateById({
      id: organization.id,
      name: info.name,
      industry: info.industry,
    }).$promise;

  updateGrowSumoPartnerKeyById = (organizationId, partnerKey) =>
    this.Organizations.updateGrowSumoPartnerKeyById({
      organizationId,
      partnerKey,
    }).$promise;

  updateDefaultSsoOrganizationMembershipLevelById = (organizationId, organizationMembershipLevel) =>
    this.Organizations.updateDefaultSsoOrganizationMembershipLevelById({
      organizationId,
      organizationMembershipLevel,
    }).$promise;

  /**
   * Gets card by organization id
   */
  getCardById = organizationId => this.Organizations.getCardById({ id: organizationId }).$promise;

  /**
   * Sets a new card by organization id using given card token
   */
  setCardById = (id, cardToken) => this.Organizations.setCardById({ id, cardToken }).$promise;

  /**
   * Set new coupon by organization id
   *
   * @return {Promise} empty
   */
  setCouponById = (id, coupon) => this.Organizations.setCouponById({ id, coupon }).$promise;

  /**
   * Repairs stripe by organization id
   *
   * @return {Promise} with organization that may or may not be updated
   */
  repairById = id => this.Organizations.repairById({ id }).$promise;

  /**
   * Updates subscription plan by organization id
   *
   * @return {Promise} with updated organization
   */
  updateSubscriptionPlanById = (id, planId) => this.Organizations.updateSubscriptionPlanById({ id, planId }).$promise;

  /**
   * Cancels subscription by organization id with feedback
   *
   * @return {Promise} with updated organization
   */
  cancelSubscriptionById = (id, cancellationForm) =>
    this.Organizations.cancelSubscriptionById({ id, ...cancellationForm }).$promise;

  /**
   * Starts trial on a paid plan by organization id
   *
   * @return {Promise} with updated organization
   */
  startSubscriptionTrialById = (id, paidPlanId) =>
    this.Organizations.startSubscriptionTrialById({ id, planId: paidPlanId }).$promise;

  /**
   * Ends trial by organization id
   */
  endSubscriptionTrialById = id => this.Organizations.endSubscriptionTrialById({ id }).$promise;

  /**
   * Gets all api keys by organization id
   */
  getAllApiKeysById = id => this.Organizations.getAllApiKeysById({ id }).$promise;

  /**
   * Creates an api key by organization id
   *
   * @return {Promise} with the new key
   */
  createApiKeyById = (id, label) => this.Organizations.createApiKeyById({ id, label }).$promise;

  /**
   * Updates API key by organization id and key id
   *
   * @return {Promise} empty
   */
  updateApiKeyByOrganizationIdAndApiKeyId = (orgId, apiKeyId, changes) =>
    this.Organizations.updateApiKeyByOrganizationIdAndApiKeyId({ id: orgId, apiKeyId, changes }).$promise;

  /**
   * Deletes API key by organization id and key id
   * @return {Promise} empty
   */
  deleteApiKeyByOrganizationIdAndApiKeyId = (orgId, apiKeyId) =>
    this.Organizations.deleteApiKeyByOrganizationIdAndApiKeyId({ id: orgId, apiKeyId }).$promise;

  /**
   * Gets organization stats by organization id
   */
  getOrganizationStatsById = organizationId => {
    return this.$q((resolve, reject) =>
      queryClient
        .fetchQuery({
          queryKey: GetOrganizationStats.getKey(organizationId),
          queryFn: () => GetOrganizationStats.queryFn(organizationId),
          staleTime: 1000 * 60 * 5, // 5 Minutes
        })
        .then(response =>
          resolve({
            ...response,
            id: organizationId,
          }),
        )
        .catch(reject),
    );
  };

  /**
   * Locks organization to SSO
   */
  lockToSsoByOrganizationId = organizationId =>
    this.Organizations.lockToSsoByOrganizationId({ id: organizationId }).$promise;

  getAllUsersWithNotMatchingEmailDomainById = organizationId =>
    this.Organizations.getAllUsersWithNotMatchingEmailDomainById({
      id: organizationId,
    }).$promise;

  getAllUsersWithMatchingEmailDomainAndMultipleEmailsById = organizationId =>
    this.Organizations.getAllUsersWithMatchingEmailDomainAndMultipleEmailsById({
      id: organizationId,
    }).$promise;

  getAllUsersWithMatchingEmailDomainAndMultipleOrganizationsById = organizationId =>
    this.Organizations.getAllUsersWithMatchingEmailDomainAndMultipleOrganizationsById({
      id: organizationId,
    }).$promise;

  setEmail = (organizationId, email) => this.Organizations.setEmail({ id: organizationId, email }).$promise;
}
