import * as React from 'react';
import {
  Button,
  ButtonProps,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure,
  useToast,
} from 'components/design/next';
import { DatasetCreateFromCsvModal } from '../data-set-creation-modal';
import { GetAllDataSetsQuery, useCreateDataSetMutation } from 'pages/reports/data-sets/query-builder';
import { useQueryClient } from 'react-query';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { DataSet } from '@process-street/subgrade/process';

export type CreateDataSetButtonProps = ButtonProps & {
  mode?: 'icon' | 'text';
  onCreateSuccess?: (dataSet: DataSet) => void;
};

export const CreateDataSetButton: React.FC<React.PropsWithChildren<CreateDataSetButtonProps>> = ({
  onCreateSuccess,
  mode = 'text',
  ...buttonProps
}) => {
  const toast = useToast();
  const importFromCsvDisclosure = useDisclosure();
  const queryClient = useQueryClient();

  const createDataSetMutation = useCreateDataSetMutation({
    onSuccess: async dataSet => {
      await queryClient.invalidateQueries(GetAllDataSetsQuery.key);

      toast({
        status: 'success',
        title: 'Data set created',
      });

      if (onCreateSuccess) {
        onCreateSuccess(dataSet);
      }
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems creating the data set",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  return (
    <>
      <DatasetCreateFromCsvModal {...importFromCsvDisclosure} onCreateSuccess={onCreateSuccess} />
      <Menu>
        {mode == 'text' ? (
          <MenuButton
            as={Button}
            bgColor="gray.200"
            _hover={{ bgColor: 'gray.300' }}
            _active={{ bgColor: 'gray.300' }}
            color="blackAlpha.800"
            iconSpacing="2"
            leftIcon={<Icon size="4" icon="plus" variant="far" />}
            {...buttonProps}
          >
            {buttonProps.children ?? 'Data Set'}
          </MenuButton>
        ) : (
          <MenuButton
            as={IconButton}
            variant="ghost"
            aria-label="Create Data Set"
            color="blackAlpha.800"
            icon={<Icon size="4" icon="plus" variant="far" />}
            {...buttonProps}
          />
        )}
        <Portal>
          <MenuList w="20" zIndex="popover">
            <MenuItem
              onClick={() => createDataSetMutation.mutate({})}
              icon={<Icon size="4" icon="pencil" variant="far" color="gray.500" />}
            >
              Blank Data Set
            </MenuItem>
            <MenuItem
              onClick={() => importFromCsvDisclosure.onOpen()}
              icon={<Icon size="4" icon="arrow-up-to-line" variant="far" color="gray.500" />}
            >
              Import Data Set
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </>
  );
};
