import { AuditMetadata } from '@process-street/subgrade/core';
import { Comment, Task } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateCommentMutationParams = {
  audit: Partial<AuditMetadata>;
  content: Comment['content'];
  taskId: Task['id'];
  method: Comment['method'];
};

export type CreateCommentMutationResponse = Comment;

export const CreateCommentMutation = {
  key: ['post', 'comment'],
  mutationFn: (params: CreateCommentMutationParams) =>
    axiosService
      .getAxios()
      .post<CreateCommentMutationResponse>(`/1/comments`, params)
      .then(res => res.data),
};

export const useCreateCommentMutation = (
  options: UseMutationOptions<CreateCommentMutationResponse, AxiosError, CreateCommentMutationParams> = {},
) => {
  return useMutation(CreateCommentMutation.mutationFn, options);
};
