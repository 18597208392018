export const DueDateRuleDefinitionApi = function (ResourceFactory) {
  'ngInject';

  return ResourceFactory.create({
    getAllByTemplateRevisionId: 'GET[] /1/template-revisions/:templateRevisionId/due-date-rules',
    getAllFilteredByChecklistRevisionId: 'GET[] /1/checklist-revisions/:checklistRevisionId/due-date-rules',
    createOrUpdateByTemplateRevisionId: 'PATCH[] /1/template-revisions/:templateRevisionId/due-date-rules',
    deleteAllByTemplateRevisionIdAndIds: 'POST[] /1/template-revisions/:templateRevisionId/due-date-rules/delete',
  });
};
