import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { RawParams, StateDeclaration } from '@uirouter/angularjs';
import { useFunctionRef } from './use-function-ref';
import angular from 'angular';

export function useOnStateChangeSuccess(
  cb: (data: {
    event: angular.IAngularEvent;
    toState: StateDeclaration;
    toParams: RawParams;
    fromState: StateDeclaration;
    fromParams: RawParams;
  }) => void,
  deps: any[] = [],
) {
  const { $rootScope } = useInjector('$rootScope');

  const cbRef = useFunctionRef(cb);

  React.useEffect(() => {
    const unsubscribe = $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) =>
      cbRef.current({ event, toState, toParams, fromState, fromParams }),
    );
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
