import * as React from 'react';
import { Box, FormControl, FormLabel, InputProps, VStack } from 'components/design/next';
import { BlvdSelect, BlvdSelectHelpers, BlvdSelectProps } from 'components/design/BlvdSelect';
import { SelectFormFieldValue, SelectFormFieldWidget } from '@process-street/subgrade/process';
import { ChecklistFormFieldWidgetProps } from 'components/widgets/form-field/common/types';
import { WidgetProvider } from '../../hooks/use-widget-context';
import { useGetAllLinkedDataSetRowsQuery } from 'pages/reports/data-sets/query-builder';
import { Option } from 'space-monad';
import { FormFieldAudit } from 'components/widgets/form-field/common/FormFieldAudit';
import { ChecklistSelectFormFieldWidgetHelper } from 'pages/runs/_id/components/checklist-select-form-field-widget/helper';

export interface ChecklistSelectFormFieldWidgetProps
  extends Omit<InputProps, 'isInvalid'>,
    ChecklistFormFieldWidgetProps<SelectFormFieldWidget, SelectFormFieldValue> {
  // Added this just to trigger updates when changing a field value from my work
  fieldValue: unknown;
}

type OptionType = {
  label: string;
  value: string;
};

export const ChecklistSelectFormFieldWidget: React.FC<
  React.PropsWithChildren<ChecklistSelectFormFieldWidgetProps>
> = props => {
  const { widget, formFieldValue, isInvalid, readOnly, onUpdateValue, onInteractionEnd } = props;
  const isDisabled = readOnly || !props.editable;
  const { linkedDataSetId, linkId, linkedSavedViewId, linkedColumnId } = widget.config;
  const isDataSetLinkedDropdown =
    Boolean(linkedDataSetId) && Boolean(linkId) && Boolean(linkedSavedViewId) && Boolean(linkedColumnId);

  const dataSetRowsQuery = useGetAllLinkedDataSetRowsQuery(
    {
      dataSetId: linkedDataSetId!,
      linkId: linkId!,
      savedViewId: linkedSavedViewId!,
    },
    {
      enabled: isDataSetLinkedDropdown,
    },
  );

  const stringValue = Option(formFieldValue)
    .map(ffv => ffv.fieldValue.dataSetRowId || ffv.fieldValue.value)
    .map(String)
    .getOrElse('');

  const [options, optionsMap]: [OptionType[], Record<string, OptionType>] = React.useMemo(() => {
    if (isDataSetLinkedDropdown) {
      return ChecklistSelectFormFieldWidgetHelper.getLinkedOptions(
        dataSetRowsQuery.data ?? [],
        linkedColumnId!,
        formFieldValue,
      );
    } else {
      return ChecklistSelectFormFieldWidgetHelper.getConfigOptions(widget.config.items, stringValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
  }, [widget.config.items, dataSetRowsQuery.data, linkedColumnId, isDataSetLinkedDropdown]);

  const onChange = (option: OptionType) => {
    if (isDataSetLinkedDropdown) {
      onUpdateValue(widget, {
        value: option.label,
        dataSetRowId: option.value,
      });
      // Called for legacy reasons and to be consistent with the other form fields.
      onInteractionEnd(widget, {
        value: option.label,
        dataSetRowId: option.value,
      });
    } else {
      // We store the name/label of the item in the form field value for standard dropdowns. It's not ideal as
      // it can't handle duplicates, but it's been like that forever.
      onUpdateValue(widget, {
        value: option.label,
      });
      // Called for legacy reasons and to be consistent with the other form fields.
      onInteractionEnd(widget, {
        value: option.label,
      });
    }
  };

  const handleOnChange: BlvdSelectProps<OptionType>['onChange'] = value => {
    if (BlvdSelectHelpers.isOptionType<OptionType>(value)) {
      onChange(value);
    } else {
      onUpdateValue(widget, {
        value: '',
      });
    }
  };

  return (
    <WidgetProvider widget={widget}>
      <FormControl as={VStack} alignItems="stretch" isRequired={widget.required} isInvalid={isInvalid}>
        <FormLabel htmlFor={`form-field-widget-${widget.id}`} fontWeight="normal">
          {widget.label || 'Untitled Dropdown'}
        </FormLabel>

        <Box maxW="175" w="full">
          <BlvdSelect
            inputId={`form-field-widget-${widget.id}`}
            placeholder="Select"
            isClearable
            isSearchable
            value={optionsMap[stringValue]}
            onChange={handleOnChange}
            options={options}
            isLoading={dataSetRowsQuery.isLoading}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            getOptionLabel={ChecklistSelectFormFieldWidgetHelper.getOptionLabel(formFieldValue)}
            isOptionDisabled={ChecklistSelectFormFieldWidgetHelper.isOptionOrphaned}
            styles={{
              control: styles => {
                return {
                  ...styles,
                  backgroundColor: isDisabled ? 'var(--ps-colors-gray-100)' : 'inherit',
                };
              },
            }}
          />
        </Box>

        {formFieldValue?.audit && <FormFieldAudit fontSize="xs" audit={formFieldValue.audit} />}
      </FormControl>
    </WidgetProvider>
  );
};
