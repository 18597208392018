import { Link } from '@process-street/adapters/navigation';
import { isNotIdRef } from '@process-street/subgrade/core';
import { abbreviateForTitle } from '@process-street/subgrade/util';
import qs from 'qs';
import * as React from 'react';
import { Flex, HStack, Icon, Tooltip, Text } from 'components/design/next';
import { TasksTableUtils } from '../tasks-table/tasks-table-utils';
import { useIsTextTruncated } from 'app/hooks/use-is-text-truncated';
import { match, P } from 'ts-pattern';
import { InboxItemUtils } from '@process-street/subgrade/inbox';
import { AppModalName } from 'app/app.constants';

export type WorkflowRunRendererProps = {
  value: string | undefined;
  data: TasksTableUtils.TasksTableItem;
};

export const WorkflowRunRenderer = ({ value, data }: WorkflowRunRendererProps) => {
  const textRef = React.useRef<HTMLParagraphElement>(null);
  const { isTextTruncated } = useIsTextTruncated({ textRef });

  if (!value || !TasksTableUtils.isInboxItemRow(data)) return null;

  const checklistName = isNotIdRef(data.checklist) ? data.checklist.name : undefined;

  const content = (
    <Text ref={textRef} noOfLines={1}>
      {value}
    </Text>
  );

  const to = match(data)
    .when(InboxItemUtils.isOneOffTask, data => {
      return {
        pathname: 'checklist',
        search: qs.stringify({
          id: data.checklist.id,
          title: `${abbreviateForTitle(checklistName)}-`,
          modal: AppModalName.OneOffTask,
          modalTaskId: data.task.id,
        }),
      };
    })
    .with({ task: { taskTemplate: { group: { id: P.string } } } }, data => {
      return {
        pathname: 'checklist.task',
        search: qs.stringify({
          id: data.checklist.id,
          title: `${abbreviateForTitle(checklistName)}-`,
          groupId: data.task.taskTemplate.group.id,
        }),
      };
    })
    .otherwise(() => ({
      pathname: 'checklist',
      search: qs.stringify({
        id: data.checklist.id,
        title: `${abbreviateForTitle(checklistName)}-`,
      }),
    }));

  return (
    <Flex h="full" alignItems="center" role="group" px={2}>
      <Link
        to={to}
        target="_blank"
        color="gray.700"
        w="full"
        _groupHover={{ color: 'brand.500', textDecoration: 'unset' }}
      >
        <HStack justifyContent="space-between">
          {isTextTruncated ? (
            <Tooltip label={value} hasArrow>
              {content}
            </Tooltip>
          ) : (
            content
          )}

          <Icon
            icon="arrow-up-right-from-square"
            size="3"
            color="brand.500"
            _groupHover={{ display: 'block' }}
            display="none"
          />
        </HStack>
      </Link>
    </Flex>
  );
};
