import { SignUpGuard } from 'features/sign-up/components/guard';
import * as React from 'react';
import { Library } from '.';

export const LibraryWrapper = () => {
  return (
    <SignUpGuard>
      <Library />
    </SignUpGuard>
  );
};
