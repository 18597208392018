import angular from 'angular';

angular.module('frontStreetApp.directives').directive(
  'psUpdate',
  ($parse, $timeout) =>
    function postLink(scope, element, attributes) {
      const fn = $parse(attributes.psUpdate);
      let timeout;
      element.on('input', () => {
        $timeout.cancel(timeout);
        timeout = $timeout(() => {
          fn(scope);
        }, 500);
      });
    },
);
