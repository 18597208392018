import { Period } from '@process-street/subgrade/core';
import {
  DateFormFieldConstraintsSourceType,
  DateFormFieldWidget,
  FormFieldWidget,
} from '@process-street/subgrade/process';
import {
  Button,
  ButtonProps,
  Icon,
  Popover,
  PopoverContent,
  PopoverHeader,
  useDisclosure,
  Text,
  PopoverCloseButton,
  PopoverTrigger,
  PopoverBody,
  VStack,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  Select,
  PopoverFooter,
} from 'components/design/next';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import * as React from 'react';
import { match, P } from 'ts-pattern';
import { ConstraintDirection } from '../use-date-settings-constraints';
import { ConstraintSourceOption, ConstraintSourceSelector } from './constraint-source-selector';

export type DateFieldAddConstraintItem = {
  constraintDirection: ConstraintDirection;
  sourceType: DateFormFieldConstraintsSourceType;
  specificDate?: number;
  formFieldWidgetGroupId?: string;
  offset: Period;
};

export type AddConstraintButtonProps = {
  currentWidget: DateFormFieldWidget;
  dateWidgets: FormFieldWidget[];
  existingConstraintDirection?: ConstraintDirection;
  onAddConstraint: (values: DateFieldAddConstraintItem) => void;
  forceRerender?: () => void;
} & ButtonProps;

export const AddConstraintButton: React.FC<React.PropsWithChildren<AddConstraintButtonProps>> = ({
  currentWidget,
  dateWidgets,
  existingConstraintDirection,
  onAddConstraint,
  forceRerender,
  ...props
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const [item, setItem] = React.useState<DateFieldAddConstraintItem>({
    constraintDirection: existingConstraintDirection === 'after' ? 'before' : 'after',
    sourceType: DateFormFieldConstraintsSourceType.FormFieldValue,
    offset: { years: 0, months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 },
  });

  // We don't show offset when selecting specific date
  const [isSpecificDate, setIsSpecificDate] = React.useState(false);

  const taskTemplatesQuery = useTaskTemplatesByTemplateRevisionIdQuery({
    templateRevisionId: currentWidget.templateRevision.id,
  });

  const handleOnClose = () => {
    onClose();
    forceRerender?.();
  };

  const handleOnSubmit = () => {
    onAddConstraint(item);
    handleOnClose();
  };

  const handleOnRemove = () => {
    handleOnClose();
  };

  const handleSelectSourceOption = async (option: ConstraintSourceOption) => {
    match(option.type)
      // If source is widget, value has target widget group.
      .with(DateFormFieldConstraintsSourceType.FormFieldValue, () => {
        setItem(prev => ({
          ...prev,
          sourceType: option.type,
          formFieldWidgetGroupId: typeof option.value === 'string' ? option.value : undefined,
          specificDate: undefined,
        }));
      })
      .with(DateFormFieldConstraintsSourceType.SpecificDate, async () => {
        setItem(prev => ({
          ...prev,
          sourceType: option.type,
          specificDate: typeof option.value === 'number' ? option.value : undefined,
          formFieldWidgetGroupId: undefined,
        }));
      })
      .with(
        P.union(
          DateFormFieldConstraintsSourceType.TaskDueDate,
          DateFormFieldConstraintsSourceType.ChecklistStartDate,
          DateFormFieldConstraintsSourceType.ChecklistDueDate,
        ),
        () => {
          setItem(prev => ({
            ...prev,
            sourceType: option.type,
            specificDate: undefined,
            formFieldWidgetGroupId: undefined,
          }));
        },
      )
      .exhaustive();
  };

  const isValid = React.useMemo(
    () =>
      match(item)
        .with(
          { sourceType: DateFormFieldConstraintsSourceType.SpecificDate, specificDate: P.not(P.nullish) },
          () => true,
        )
        .with(
          { sourceType: DateFormFieldConstraintsSourceType.FormFieldValue, formFieldWidgetGroupId: P.not(P.nullish) },
          () => true,
        )
        .with(
          {
            sourceType: P.union(
              DateFormFieldConstraintsSourceType.TaskDueDate,
              DateFormFieldConstraintsSourceType.ChecklistStartDate,
              DateFormFieldConstraintsSourceType.ChecklistDueDate,
            ),
          },
          () => true,
        )
        .otherwise(() => false),
    [item],
  );

  return (
    <Popover placement="bottom-start" isOpen={isOpen} onOpen={onOpen} onClose={handleOnClose}>
      <PopoverTrigger>
        <Button
          variant="outline"
          color="gray.600"
          borderColor="gray.300"
          borderWidth="1px"
          fontWeight="normal"
          leftIcon={<Icon icon="plus" variant="far" size="3" />}
          {...props}
        >
          Add rule
        </Button>
      </PopoverTrigger>
      <PopoverContent py="3" w="sm" zIndex="popover" textAlign="left">
        <PopoverHeader p={0} mb={3} mx={3}>
          <Text variant="-1u" color="gray.400" textAlign="center">
            Date validation
          </Text>
          <PopoverCloseButton size="sm" color="gray.500" />
        </PopoverHeader>

        <PopoverBody>
          <VStack alignItems="flex-start" spacing="4">
            {isSpecificDate ? (
              <HStack w="full" justifyContent="space-between">
                <Text color="gray.600" fontWeight="medium">
                  Date must be:
                </Text>
                <Select
                  maxW="143px"
                  data-testid="direction-selector"
                  value={item.constraintDirection}
                  isDisabled={Boolean(existingConstraintDirection)}
                  fontSize="sm"
                  onChange={e =>
                    setItem(prev => ({
                      ...prev,
                      constraintDirection: e.target.value as ConstraintDirection,
                    }))
                  }
                >
                  <option value="after">After</option>
                  <option value="before">Before</option>
                </Select>
              </HStack>
            ) : (
              <>
                <Text color="gray.600" fontWeight="medium">
                  Date must be:
                </Text>

                <HStack spacing="4" alignItems="flex-start">
                  <VStack alignItems="flex-start">
                    <NumberInput min={0} value={item.offset.months}>
                      <NumberInputField color={item.offset.months > 0 ? 'default' : 'gray.300'} />
                      <NumberInputStepper>
                        <NumberIncrementStepper
                          aria-label="month increment"
                          // using onClick instead of onChange since in forms editor the onChange is not triggered
                          // I think is because formiks overrides
                          onClick={() =>
                            setItem(prev => ({
                              ...prev,
                              offset: {
                                ...prev.offset,
                                months: prev.offset.months + 1,
                              },
                            }))
                          }
                        />
                        <NumberDecrementStepper
                          aria-label="decrement increment"
                          onClick={() => {
                            if (item.offset.months > 0)
                              setItem(prev => ({
                                ...prev,
                                offset: {
                                  ...prev.offset,
                                  months: prev.offset.months - 1,
                                },
                              }));
                          }}
                        />
                      </NumberInputStepper>
                    </NumberInput>
                    <Text variant="-1" pl="2">
                      months
                    </Text>
                  </VStack>

                  <VStack alignItems="flex-start">
                    <NumberInput min={0} value={item.offset.days}>
                      <NumberInputField color={item.offset.days > 0 ? 'default' : 'gray.300'} />
                      <NumberInputStepper>
                        <NumberIncrementStepper
                          aria-label="day increment"
                          onClick={() => {
                            setItem(prev => ({
                              ...prev,
                              offset: {
                                ...prev.offset,
                                days: prev.offset.days + 1,
                              },
                            }));
                          }}
                        />
                        <NumberDecrementStepper
                          aria-label="day decrement"
                          onClick={() => {
                            if (item.offset.days > 0) {
                              setItem(prev => ({
                                ...prev,
                                offset: {
                                  ...prev.offset,
                                  days: prev.offset.days - 1,
                                },
                              }));
                            }
                          }}
                        />
                      </NumberInputStepper>
                    </NumberInput>
                    <Text variant="-1" pl="2">
                      days
                    </Text>
                  </VStack>
                  <FormControl>
                    <Select
                      data-testid="direction-selector"
                      value={item.constraintDirection}
                      isDisabled={Boolean(existingConstraintDirection)}
                      fontSize="sm"
                      onChange={e =>
                        setItem(prev => ({
                          ...prev,
                          constraintDirection: e.target.value as ConstraintDirection,
                        }))
                      }
                    >
                      <option value="after">After</option>
                      <option value="before">Before</option>
                    </Select>
                  </FormControl>
                </HStack>
              </>
            )}

            <HStack w="full">
              <ConstraintSourceSelector
                dateWidgets={dateWidgets}
                taskTemplates={taskTemplatesQuery.data ?? []}
                onSelect={handleSelectSourceOption}
                onSelectSpecificDate={() => setIsSpecificDate(true)}
              />
            </HStack>
          </VStack>
        </PopoverBody>
        <PopoverFooter>
          <HStack w="full" justifyContent="space-between" pt="2">
            <Button onClick={handleOnSubmit} type="submit" w="40%" variant="primary" flex="0 50%" isDisabled={!isValid}>
              Save
            </Button>
            <Button
              w="40%"
              variant="danger"
              flex="0 50%"
              colorScheme="red"
              onClick={handleOnRemove}
              isDisabled={!isValid}
            >
              Remove
            </Button>
          </HStack>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
