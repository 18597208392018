import angular from 'angular';
import { bindActionCreatorsToActions } from 'reducers/util';
import { HttpStatus } from '@process-street/subgrade/util';
import { FieldType, WidgetUtils, WidgetValidation } from '@process-street/subgrade/process';
import { FileUploadConstants } from '../file-upload/file-upload-constants';
import templateUrl from './checklist-form-field-widget.component.html';
import { FormFieldErrorCodes } from '@process-street/subgrade/core';
import { queryClient } from 'components/react-root';
import { ResolvedMergeTagsByChecklistRevisionIdQuery } from 'features/merge-tags/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { StringService } from 'services/string-service';
import { Key } from 'services/key';
import { match, P } from 'ts-pattern';
import {
  GetFormFieldValuesByChecklistRevisionIdQuery,
  UpdateFormFieldValueMutation,
} from 'features/widgets/query-builder';
import { PromiseQueueKeyGenerator } from 'app/services/promise-queue/promise-queue-key-generator-pure';
import { FormFieldEvent } from 'services/form-field-event';

angular.module('frontStreetApp.directives').component('psChecklistFormFieldWidget', {
  bindings: {
    user: '<',
    widget: '<',
    editable: '<',
    revision: '<',
    formFieldValue: '<',
    onUpdateProgress: '&',
    invalidFormFieldMap: '<',
    taskId: '<',
    readOnly: '<',
  },
  templateUrl,
  controller(
    $ngRedux,
    $rootScope,
    $timeout,
    $q,
    $window,
    FileUploadService,
    FeatureFlagService,
    FormFieldService,
    FormFieldValueActions,
    FormFieldValueService,
    MessageBox,
    PromiseQueueDescGenerator,
    PromiseQueueService,
    RuleEvent,
    SessionService,
    ToastService,
    ValueUpdater,
  ) {
    const ctrl = this;

    const unsubscribe = $ngRedux.connect(null, bindActionCreatorsToActions(FormFieldValueActions))(ctrl);

    ctrl.$onInit = function () {
      ctrl.updateFailed = false;
      ctrl.deleteFailed = false;
      ctrl.errorMessage = '';
      if (ctrl.widget?.fieldType === FieldType.File) {
        ctrl.errorMessage = ctrl.getFileFormFieldWidgetErrorMessage(
          ctrl.widget.constraints,
          ctrl.formFieldValue?.fieldValue.name,
        );
      }
    };

    ctrl.$onDestroy = function () {
      if (unsubscribe) {
        unsubscribe();
      }
    };

    let valueUpdater;

    ctrl.$onChanges = function (changes) {
      if (changes.revision && changes.revision.currentValue) {
        const queueKey = PromiseQueueKeyGenerator.generateByChecklistId(ctrl.revision.checklist.id);
        const delay = FormFieldService.getUpdateDelay(ctrl.widget);
        valueUpdater = new ValueUpdater(
          queueKey,
          map => ctrl.doUpdateFormFieldValue(map.widget, map.newValue, map.originalValue),
          delay,
        );
      }

      if (changes.widget?.currentValue) {
        ctrl.widget = changes.widget.currentValue;
      }
    };

    ctrl.setUpdateFailed = function () {
      ctrl.updateFailed = true;
    };

    ctrl.unsetUpdateFailed = function () {
      if (ctrl.updateFailed) {
        ctrl.updateFailed = false;
        ctrl.errorMessage = '';
      }
    };

    ctrl.setDeleteFailed = function () {
      ctrl.deleteFailed = true;
    };

    ctrl.unsetDeleteFailed = function () {
      if (ctrl.deleteFailed) {
        ctrl.deleteFailed = false;
      }
    };

    ctrl.getWidgetLabel = function (widget) {
      return StringService.abbreviate(widget.label || widget.key);
    };

    let updateTimeout;

    /**
     * This event is used to drive logic on the frontend (conditional logic, merge tags etc)
     * The backend is updated separately via ValueUpdate service.
     *
     * We only need to debounce for widgets which contain text inputs to avoid bogging down the browser.
     */
    ctrl.broadcastValueUpdatedEvent = function (widget, formFieldValue) {
      if (
        [FieldType.Email, FieldType.Number, FieldType.Text, FieldType.Textarea, FieldType.Url].includes(
          widget?.fieldType,
        )
      ) {
        $timeout.cancel(updateTimeout);
        updateTimeout = $timeout(() => {
          $rootScope.$broadcast(FormFieldEvent.FORM_FIELD_VALUE_UPDATED, formFieldValue);
        }, 500);
      } else {
        $rootScope.$broadcast(FormFieldEvent.FORM_FIELD_VALUE_UPDATED, formFieldValue);
      }
    };

    ctrl.onInteractionEnd = (widget, fieldValue) => {
      const formFieldValue = angular.copy(ctrl.formFieldValue);
      formFieldValue.fieldValue = angular.copy(fieldValue);

      $rootScope.$broadcast(FormFieldEvent.FORM_FIELD_INTERACTION_ENDED, widget, formFieldValue);
    };

    ctrl._getUpdatedAuditInfo = function () {
      // Setting audit information
      const audit = ctrl.formFieldValue.audit || {};
      const now = Date.now();
      audit.updatedBy = { id: ctrl.user.id };
      audit.updatedDate = now;

      if (!audit.createdBy) {
        audit.createdBy = { id: ctrl.user.id };
      }
      if (!audit.createdDate) {
        audit.createdDate = now;
      }
      return audit;
    };

    ctrl.updateFormFieldValue = function (widget, newFieldValue, shouldSkipDebounce = false) {
      const originalValue = angular.copy(ctrl.formFieldValue);
      ctrl.formFieldValue.fieldValue = angular.copy(newFieldValue);

      const [failedWidget] = FormFieldValueService.getFailedFormFieldWidgets({
        taskWidgets: [widget],
        formFieldValueMap: { [widget.header.id]: ctrl.formFieldValue },
      });
      ctrl.formFieldValue.audit = angular.copy(ctrl._getUpdatedAuditInfo());

      // Don't attempt persistence with a bad value
      // Also, if the currently updated widget's value is invalid, don't consider it as an update event
      // To e.g. prevent eagerly showing tasks by CL even though
      // the value was invalid and not persisted
      if (failedWidget) {
        return;
      }

      ctrl.broadcastValueUpdatedEvent(widget, ctrl.formFieldValue);

      $rootScope.$broadcast(FormFieldEvent.FORM_FIELD_VALUE_UPDATE_STARTED, ctrl.formFieldValue, originalValue);

      if (shouldSkipDebounce) {
        return ctrl.doUpdateFormFieldValue(widget, newFieldValue, originalValue);
      }

      const queueDesc = PromiseQueueDescGenerator.generateUpdateByFormField(widget, newFieldValue);
      const value = {
        widget,
        newValue: ctrl.formFieldValue,
        originalValue,
      };
      const updatedValuePromise = valueUpdater.updateValue(value, queueDesc);
      ctrl.actions.setFormFieldValueUpdatePending();
      return updatedValuePromise;
    };

    ctrl.doUpdateFormFieldValue = function (widget, newFormFieldValue, originalFormFieldValue) {
      return FormFieldValueService.updateFormFieldValue(
        newFormFieldValue,
        originalFormFieldValue,
        ctrl.revision.id,
        widget.id,
        ctrl.revision.checklist.id,
      ).then(
        result => {
          ctrl.formFieldValue.id = result.formFieldValue.id;

          ctrl.unsetUpdateFailed();

          $rootScope.$broadcast(RuleEvent.CHECKLIST_TASKS_STATE_UPDATED, result.tasks);

          return result.formFieldValue;
        },
        response => {
          // We don't want to show the error coming from the backend for field validation.
          // We have client-side validation for showing errors
          // Keeping this out of the switch statement for explicitness
          if (
            response.status === HttpStatus.BAD_REQUEST &&
            response.data.code === FormFieldErrorCodes.InvalidFormFieldValues
          ) {
            return $q.reject(response);
          }

          ctrl.setUpdateFailed();

          if (FormFieldService.isRevertible(widget)) {
            ctrl.formFieldValue = originalFormFieldValue;
          }

          match(response)
            .with(
              { status: HttpStatus.CONFLICT },
              { status: HttpStatus.NOT_FOUND, data: P.when(d => d.message?.includes?.('task not found')) },
              () => {
                MessageBox.confirm({
                  title: 'Workflow Run Updated',
                  message:
                    "We couldn't update the value because the workflow run has been updated. " +
                    'Click refresh to get the new version!',
                  okButton: {
                    type: 'success',
                    text: 'Refresh',
                    action: () => $window.location.reload(),
                  },
                });
              },
            )
            .otherwise(() => {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems updating the form field ${ctrl.getWidgetLabel(widget)}`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            });

          return $q.reject(response);
        },
      );
    };

    ctrl.deleteFormFieldValue = function (widget) {
      const originalFormFieldValue = angular.copy(ctrl.formFieldValue);
      angular.copy({}, ctrl.formFieldValue.fieldValue);

      // TODO: We should get rid of this event.
      // But bunch of things still replying on this one
      $rootScope.$broadcast(FormFieldEvent.FORM_FIELD_VALUE_UPDATED, ctrl.formFieldValue);

      const queueDesc = PromiseQueueDescGenerator.generateDeleteByFormField(
        ctrl.widget,
        originalFormFieldValue.fieldValue,
      );
      const queueKey = PromiseQueueKeyGenerator.generateByChecklistId(ctrl.revision.checklist.id);
      return PromiseQueueService.enqueue(
        queueKey,
        () => ctrl.doDeleteFormFieldValue(widget, originalFormFieldValue),
        queueDesc,
      );
    };

    ctrl.doDeleteFormFieldValue = (widget, originalFormFieldValue) =>
      this.actions.deleteFormFieldValue(ctrl.revision.id, widget.id).then(
        async ({ payload: response }) => {
          ctrl.unsetUpdateFailed();
          ctrl.unsetDeleteFailed();
          await queryClient.invalidateQueries(
            GetFormFieldValuesByChecklistRevisionIdQuery.getKey({ checklistRevisionId: ctrl.revision.id }),
          );
          await queryClient.invalidateQueries(ResolvedMergeTagsByChecklistRevisionIdQuery.key);

          return response;
        },
        response => {
          ctrl.setDeleteFailed();
          ctrl.formFieldValue = originalFormFieldValue;

          switch (response.status) {
            case HttpStatus.CONFLICT:
              ToastService.openToast({
                status: 'warning',
                title: `We're having problems deleting the form field value ${ctrl.getWidgetLabel(widget)}`,
                description: 'The workflow run has likely been updated, please refresh and try again.',
              });
              break;
            default:
              ToastService.openToast({
                status: 'error',
                title: `We're having problems deleting the form field ${ctrl.getWidgetLabel(widget)}`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
          }

          return response;
        },
      );

    ctrl.fileFieldUpload = {
      add(__event, data) {
        // Disable autoUpload so we can get the promise from submit
        data.autoUpload = false;

        $timeout(() => {
          data.process(function () {
            return this.process(data);
          });
        });
      },
      processDone(__event, data) {
        const [{ name: originalName, type: mimeType }] = data.files;

        FileUploadService.submitFormFieldWidgetUpload(
          data,
          ctrl.widget,
          ctrl.revision.id,
          ctrl.widget.id,
          originalName,
          mimeType,
        ).catch(response => {
          ctrl.fail(null, data, response);
        });
      },
      processFail(__event, data) {
        const [{ error }] = data.files;
        switch (error) {
          case FileUploadConstants.Error.FILE_TOO_LARGE: {
            const prettyMaxFileSize = StringService.getPrettySize(data.maxFileSize);
            ToastService.openToast({
              status: 'warning',
              title: `We're having problems uploading that file`,
              description: `The file must be smaller than ${prettyMaxFileSize}.`,
            });
            break;
          }
          default:
            ToastService.openToast({
              status: 'error',
              title: `We're having problems uploading that file`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
        }
      },
      done(__event, data) {
        ctrl.unsetUpdateFailed();

        FileUploadService.finishUploadForFormFieldWidget(data)
          .then(async ffvResult => {
            ctrl.actions.uploadedFormFieldFile(ffvResult);

            // Set value
            const fieldValue = ffvResult && ffvResult.formFieldValue && ffvResult.formFieldValue.fieldValue;
            ctrl.formFieldValue.fieldValue = angular.copy(fieldValue);
            // Copy to trigger $onChange in file-field component
            ctrl.formFieldValue = angular.copy(ctrl.formFieldValue);

            // File field is not updated like other formFields so get current date and ctrl.user for Audit
            ctrl.formFieldValue.audit = angular.copy(ctrl._getUpdatedAuditInfo());

            UpdateFormFieldValueMutation.updateFormFieldValuesOnSuccess(queryClient)({
              formFieldValue: ffvResult.formFieldValue,
            });
            // TODO: We should get rid of this event.
            // But bunch of things still replying on this one
            $rootScope.$broadcast(FormFieldEvent.FORM_FIELD_VALUE_UPDATED, ctrl.formFieldValue);

            FileUploadService.finishUpload(ctrl.widget);
          })
          .catch(() => {
            ctrl.fail(null, data);
          });
      },
      fail: ctrl.fail,
    };

    ctrl.fail = (__event, data, response) => {
      // For quick UI responsiveness
      angular.copy({}, ctrl.formFieldValue.fieldValue);
      ctrl.setUpdateFailed();

      if (
        response.status === HttpStatus.BAD_REQUEST &&
        response.data.code === FormFieldErrorCodes.InvalidFormFieldValues
      ) {
        ctrl.errorMessage = ctrl.getFileFormFieldWidgetErrorMessage(
          ctrl.widget.constraints,
          data.originalFiles[0]?.name,
        );
      } else {
        switch (data.errorThrown) {
          case 'Conflict':
            ToastService.openToast({
              status: 'warning',
              title: `We're having problems uploading the file`,
              description: 'The workflow run has likely been updated, please refresh and try again.',
            });
            break;
          default:
            ToastService.openToast({
              status: 'error',
              title: `We're having problems uploading the file`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
        }
      }

      // Clean up
      FileUploadService.finishUpload(ctrl.widget);
    };

    ctrl.stopEventPropagation = function (event) {
      if (event.keyCode === Key.LEFT_ARROW || event.keyCode === Key.RIGHT_ARROW) {
        event.stopPropagation();
      }
    };

    ctrl.getFileFormFieldWidgetErrorMessage = (constraints, value) => {
      if (WidgetValidation.getFileFormFieldWidgetErrorType(constraints, value)) {
        return `Only ${constraints?.extensions?.join(', ')} can be uploaded here.`;
      }
      return '';
    };

    ctrl.isEmpty = () => {
      return WidgetUtils.isEmpty(ctrl.widget, ctrl.formFieldValue);
    };

    ctrl.isInvalid = () => {
      return (
        ctrl.updateFailed ||
        ctrl.deleteFailed ||
        (ctrl.widget.required && ctrl.invalidFormFieldMap?.[ctrl.widget.id] && ctrl.isEmpty())
      );
    };

    ctrl.featureFlags = FeatureFlagService.getFeatureFlags();
  },
});
