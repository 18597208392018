import angular from 'angular';
import { TaskPermissionTemplateButtonComponent } from 'components/task-permission/template-button/task-permission-template-button.component';
import { TaskPermissionSelectorComponent } from 'components/task-permission/selector/task-permission-selector.component';
import { TaskPermissionRuleActions } from 'components/task-permission/store/task-permission-rule.actions';
import { TaskTemplatePermitActions } from 'components/task-permission/store/task-template-permit.actions';
import { TaskPermissionUpgradeModal } from 'components/task-permission/modal/task-permission-upgrade-modal.component';
import { TaskPermissionPopUpComponent } from 'components/task-permission/pop-up/task-permission-pop-up.component';
import { TaskPermissionRuleApi } from 'components/task-permission/store/task-permission-rule.api';
import { TaskTemplatePermitApi } from 'components/task-permission/store/task-template-permit.api';
import { TaskPermissionRuleComponent } from 'components/task-permission/rule/task-permission-rule.component';
import { TaskPermissionRuleService } from 'components/task-permission/service/task-permission-rule.service';
import { TaskTemplatePermitService } from 'components/task-permission/service/task-template-permit.service';

export const TaskPermissionModule = angular
  .module('taskPermissionModule', [])
  .component('psTaskPermissionTemplateButton', TaskPermissionTemplateButtonComponent)
  .component('psTaskPermissionSelector', TaskPermissionSelectorComponent)
  .component('psTaskPermissionUpgradeModal', TaskPermissionUpgradeModal)
  .component('psTaskPermissionPopUp', TaskPermissionPopUpComponent)
  .component('psTaskPermissionRule', TaskPermissionRuleComponent)
  .factory('TaskPermissionRuleActions', TaskPermissionRuleActions)
  .factory('TaskPermissionRuleApi', TaskPermissionRuleApi)
  .factory('TaskTemplatePermitActions', TaskTemplatePermitActions)
  .factory('TaskTemplatePermitApi', TaskTemplatePermitApi)
  .service('TaskPermissionRuleService', TaskPermissionRuleService)
  .service('TaskTemplatePermitService', TaskTemplatePermitService).name;
