import { createSelector, Selector } from 'reselect';
import { Muid, Option, OrganizationMembership, OrganizationMembershipWithUser, User } from '../../core';
import { safeEntityMapToArrayByIdsWith } from '../safe-entity-map-to-array-by-ids';
import { EntityMap, LightReduxState, ObjectMap } from '../types';
import { BaseGroupMembershipSelector } from './group-membership.selectors';
import { BaseUserSelector } from './user.selectors';

const getById =
  (organizationMembershipId: Muid): Selector<LightReduxState, Option<OrganizationMembership>> =>
  (state: LightReduxState) =>
    state.entities.organizationMemberships[organizationMembershipId];

const getEntityMap: Selector<LightReduxState, EntityMap<OrganizationMembership>> = (state: LightReduxState) =>
  state.entities.organizationMemberships;

const organizationMembershipByUserIdMap: Selector<LightReduxState, ObjectMap<OrganizationMembership>> = createSelector(
  getEntityMap,
  organizationMembership =>
    Object.values(organizationMembership).reduce(
      (organizationMembershipByUserId: ObjectMap<OrganizationMembership>, membership) =>
        Object.assign(organizationMembershipByUserId, { [membership.user.id]: membership }),
      {},
    ),
);

const getByUserId =
  (userId: Muid): Selector<LightReduxState, Option<OrganizationMembership>> =>
  (state: LightReduxState) =>
    organizationMembershipByUserIdMap(state)[userId];

const getWithUserById =
  (membershipId: Muid): Selector<LightReduxState, Option<OrganizationMembershipWithUser>> =>
  (state: LightReduxState) =>
    getAllWithUserForActiveOrganization(state)[membershipId];

const getAllById =
  (membershipIds: Muid[]) =>
  (state: LightReduxState): OrganizationMembership[] =>
    safeEntityMapToArrayByIdsWith(state.entities.organizationMemberships, membershipIds);

const getAllForActiveOrganization = (state: LightReduxState): OrganizationMembership[] =>
  Object.values(state.entities.organizationMemberships);

const getAllWithUserForActiveOrganization: Selector<
  LightReduxState,
  EntityMap<OrganizationMembershipWithUser>
> = createSelector(getEntityMap, BaseUserSelector.getEntityMap, (organizationMembershipMap, userMap) =>
  Object.values(organizationMembershipMap).reduce((agg: EntityMap<OrganizationMembershipWithUser>, om) => {
    const user = userMap[om.user.id];
    if (user !== undefined) {
      agg[om.id] = { ...om, user };
    }
    return agg;
  }, {}),
);

const getAllByGroupId =
  (groupId: Muid) =>
  (state: LightReduxState): OrganizationMembership[] => {
    const groupMemberships = BaseGroupMembershipSelector.getAllGroupMembershipsByGroupId(groupId)(state);
    const membershipIds = groupMemberships.map(groupMembership => groupMembership.organizationMembership.id);
    return getAllById(membershipIds)(state);
  };

const getAllWithUserByGroupId =
  (groupId: Muid) =>
  (state: LightReduxState): OrganizationMembershipWithUser[] => {
    const groupMemberships = BaseGroupMembershipSelector.getAllGroupMembershipsByGroupId(groupId)(state);
    const membershipIds = groupMemberships.map(groupMembership => groupMembership.organizationMembership.id);
    return safeEntityMapToArrayByIdsWith(getAllWithUserForActiveOrganization(state), membershipIds);
  };

const getUserByOrganizationMembershipId =
  (membershipId: Muid) =>
  (state: LightReduxState): Option<User> => {
    const membership = getById(membershipId)(state);
    return membership ? BaseUserSelector.getById(membership.user.id)(state) : undefined;
  };

const getAllUsersByOrganizationMembershipIds =
  (membershipIds: Muid[]) =>
  (state: LightReduxState): User[] => {
    const memberships = getAllById(membershipIds)(state);
    const userIds = memberships.map(membership => membership.user.id);
    return BaseUserSelector.getAllById(userIds)(state);
  };

export const BaseOrganizationMembershipSelector = {
  getAllById,
  getAllForActiveOrganization,
  getAllByGroupId,
  getAllWithUserByGroupId,
  getAllWithUserForActiveOrganization,
  getAllUsersByOrganizationMembershipIds,
  getById,
  getWithUserById,
  getByUserId,
  getEntityMap,
  getUserByOrganizationMembershipId,
};
