import angular from 'angular';
import { Verb } from 'services/activities/verb';
import { TemplateConstants } from 'services/template-constants';
import { ObjectType } from 'services/activities/object-type';
import { HttpStatus } from '@process-street/subgrade/util';
import { queryClient } from 'components/react-root';
import { GetConsolidatedTemplatePermissions } from 'features/permissions/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { trace } from 'components/trace';
import { TemplateType } from '@process-street/subgrade/process';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'TemplateManageActivityCtrl',
    function (
      $scope,
      $state,
      ActivityService,
      OrganizationMembershipService,
      SecurityService,
      SessionService,
      ToastService,
      TemplateActivityService,
      TemplateRevisionService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'TemplateManageActivityCtrl' });
      logger.info('loading ctrl');

      let userIsAdmin;
      let userHasTemplateUpdate;
      ctrl.$onInit = () => {
        const user = SessionService.getUser();
        const selectedOrganizationId = SecurityService.getSelectedOrganizationIdByUser(user);
        OrganizationMembershipService.isAdminByUserIdAndOrganizationId(user.id, selectedOrganizationId).then(
          currentUserIsAdmin => {
            userIsAdmin = currentUserIsAdmin;
            $scope.loadOlderActivities();
          },
        );
        const templateId = $state.params.id;
        queryClient
          .fetchQuery(GetConsolidatedTemplatePermissions.getKey(templateId), () =>
            GetConsolidatedTemplatePermissions.queryFn(templateId),
          )
          .then(data => {
            userHasTemplateUpdate = data.permissionMap.templateUpdate;
          });
      };

      const PAGE_SIZE = 20;

      $scope.loadOlderActivities = function () {
        $scope.loadingSentences = true;

        const oldSentences = $scope.sentences || [];
        const offsetId = oldSentences[oldSentences.length - 1]?.activity?.id;

        TemplateActivityService.getActivitiesByIdLimitAndOffsetId($state.params.id, PAGE_SIZE, offsetId)
          .then(result => {
            $scope.hiddenTotal = result.hiddenTotal;

            $scope.sentences = $scope.sentences || [];

            if (result.data.length) {
              const newSentences = result.data.map(TemplateActivityService.interpretSimple).filter(a => a);
              $scope.sentences = $scope.sentences.concat(newSentences);
              $scope.moreSentences = result.data.length === PAGE_SIZE;
            } else {
              $scope.moreSentences = false;
            }
          })
          .finally(() => {
            $scope.loadingSentences = false;
          });
      };

      $scope.isUndeletable = function (activity) {
        if (activity.objectType === ObjectType.TEMPLATE_REVISION) {
          const activityIsUpdated = activity.verb === Verb.UPDATED;
          const templateIsActive = activity.object.template.status === TemplateConstants.STATUS.ACTIVE;
          return userHasTemplateUpdate && activityIsUpdated && templateIsActive;
        } else {
          return userIsAdmin && ActivityService.isUndeletable(activity);
        }
      };

      $scope.undelete = function (activity) {
        const isWorkflow = activity.object.template.templateType === TemplateType.Playbook;
        if (activity.objectType === ObjectType.TEMPLATE_REVISION) {
          TemplateRevisionService.create(activity.object.template.id, activity.object.id)
            .then(() => {
              ToastService.openToast({
                status: 'success',
                title: `${isWorkflow ? 'Workflow' : 'Page'} version restored`,
              });

              $state.go('template', { id: activity.object.template.id });
            })
            .catch(response => {
              if (response.status === HttpStatus.CONFLICT) {
                ToastService.openToast({
                  status: 'warning',
                  title: `You cannot restore a ${isWorkflow ? 'workflow' : 'page'} version if there is a draft`,
                  description: `Please publish or discard the current ${
                    isWorkflow ? 'workflow' : 'page'
                  } draft and try again.`,
                });
              } else {
                ToastService.openToast({
                  status: 'error',
                  title: `We're having problems restoring the ${isWorkflow ? 'workflow' : 'page'} version`,
                  description: DefaultErrorMessages.unexpectedErrorDescription,
                });
              }
            });
        } else {
          ActivityService.undelete(activity);
        }
      };
    },
  );
