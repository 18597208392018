import { Muid } from '@process-street/subgrade/core';
import { axiosService } from 'services/axios-service';
import { DataSetMergeTag, DataSetMergeTagWithValue } from '@process-street/subgrade/process';

export type GetDataSetMergeTagsByChecklistRevisionParams = { checklistRevisionId?: Muid };

export type GetDataSetMergeTagsByChecklistRevisionResult = DataSetMergeTagWithValue[];

export const GetDataSetMergeTagsByChecklistRevisionQuery = {
  key: ['checklist-revisions', 'data-set-merge-tags'],
  getKey: (params: GetDataSetMergeTagsByChecklistRevisionParams) => [
    ...GetDataSetMergeTagsByChecklistRevisionQuery.key,
    params,
  ],
  queryFn: ({ checklistRevisionId }: GetDataSetMergeTagsByChecklistRevisionParams) =>
    axiosService
      .getAxios()
      .get<GetDataSetMergeTagsByChecklistRevisionResult>(
        `/1/checklist-revisions/${checklistRevisionId}/data-set-merge-tags`,
      )
      .then(r => r.data),
};

export type GetDataSetMergeTagsByTemplateRevisionParams = { templateRevisionId: Muid };

export type GetDataSetMergeTagsByTemplateRevisionResult = DataSetMergeTag[];

export const GetDataSetMergeTagsByTemplateRevisionQuery = {
  key: ['template-revisions', 'data-set-merge-tags'],
  getKey: (params: GetDataSetMergeTagsByTemplateRevisionParams) => [
    ...GetDataSetMergeTagsByTemplateRevisionQuery.key,
    params,
  ],
  queryFn: ({ templateRevisionId }: GetDataSetMergeTagsByTemplateRevisionParams) =>
    axiosService
      .getAxios()
      .get<GetDataSetMergeTagsByTemplateRevisionResult>(
        `/1/template-revisions/${templateRevisionId}/data-set-merge-tags`,
      )
      .then(r => r.data),
};
