import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { TaskPermissionsPicker } from './task-permissions-picker';

export const TaskPermissionsPickerModule = angular
  .module('psTaskPermissionPickerModule', [])
  .component(
    'psTaskPermissionPicker',
    reactToAngular(TaskPermissionsPicker, ['taskTemplates', 'templateRevisionId', 'isDisabled']),
  ).name;
