import * as React from 'react';
import { Muid, OrganizationMembershipRole } from '@process-street/subgrade/core';
import { Icon, IconButton, Tooltip, useDisclosure, useToast } from 'components/design/next';
import {
  TaskTemplatesByTemplateRevisionIdQuery,
  UpdateTaskTemplateMutation,
} from 'features/task-templates/query-builder';
import { useInjector } from 'components/injection-provider';
import { useSelectedOrganization } from 'app/hooks/use-selected-organization';
import { useGetCurrentUserInfoQuery } from 'app/features/user/query-builder';
import { canAccess, Feature } from 'app/services/features/features';
import { StopTaskModal } from 'app/pages/workflows/_id/edit-v2/components/task-list/components/bulk-actions-drawer/bulk-stop-task/stop-task-modal';
import { AnalyticsService } from 'app/components/analytics/analytics.service';
import { AnalyticsConstants } from '@process-street/subgrade/analytics';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { StateFrom } from 'xstate';
import { Option } from 'space-monad';
import { useSelector } from '@xstate/react';
import { match } from 'ts-pattern';
import _isEqual from 'lodash/isEqual';
import { useSharedContext } from 'app/pages/forms/_id/shared';
import { TaskTemplate } from '@process-street/subgrade/process';
import { isFunction } from 'lodash';
import { FormEditorPageMachine } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-types';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';

export interface StopTaskButtonProps {
  taskTemplateGroupId: Muid;
  isEditable?: boolean;
}

export const StopTaskButton: React.FC<React.PropsWithChildren<StopTaskButtonProps>> = ({
  taskTemplateGroupId,
  isEditable = true,
}) => {
  const { SessionService, UserSettingsService } = useInjector('$rootScope', 'SessionService', 'UserSettingsService');
  const toast = useToast();
  const actor = useFormEditorPageActorRef();
  const { queryClient } = useSharedContext();

  const taskTemplateSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return Option(taskTemplateGroupId)
        .map(groupId => FormEditorPageActorSelectors.getTaskTemplateByGroupId(groupId)(state))
        .get();
    },
    [taskTemplateGroupId],
  );

  const taskTemplate = useSelector(actor, taskTemplateSelector, _isEqual);

  const hasStop = taskTemplate?.stop ?? false;
  const organization = useSelectedOrganization();
  const currentUserInfoQuery = useGetCurrentUserInfoQuery();
  const disclosure = useDisclosure();

  const isFeatureAvailable = React.useMemo(() => {
    if (organization?.subscription.plan.id) {
      const planId = organization.subscription.plan.id;
      return canAccess(Feature.STOP_TASK, planId);
    }
  }, [organization?.subscription.plan.id]);

  const isAdmin = React.useMemo(() => {
    if (currentUserInfoQuery.data) {
      return currentUserInfoQuery.data?.organizationMembership.role === OrganizationMembershipRole.Admin;
    }
    return false;
  }, [currentUserInfoQuery.data]);

  const addStopTaskModalSeen = SessionService.getTemplateEditorProperty('addStopTaskModalSeen');

  const shouldShowUpgradeModal = React.useMemo(() => {
    return !hasStop && (!isFeatureAvailable || !addStopTaskModalSeen);
  }, [hasStop, addStopTaskModalSeen, isFeatureAvailable]);

  const templateRevisionId = taskTemplate?.templateRevision.id;

  const updateTemplateMutation = UpdateTaskTemplateMutation.useMutation({
    onMutate: () => {
      if (templateRevisionId) {
        const previousTaskTemplates = queryClient.getQueryData<Array<TaskTemplate>>(
          TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
        );
        queryClient.setQueryData<Array<TaskTemplate>>(
          TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
          current => {
            if (!current) return [];

            const updatedTaskTemplate = { ...taskTemplate, stop: !hasStop };

            return current.map(tt => {
              if (tt.id === taskTemplate.id) {
                return updatedTaskTemplate;
              }
              return tt;
            });
          },
        );
        return () => {
          queryClient.setQueryData<Array<TaskTemplate>>(
            TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
            previousTaskTemplates ?? [],
          );
        };
      }
      return () => {};
    },
    onSuccess: () => {
      AnalyticsService.trackEvent(AnalyticsConstants.Event.STOP_TASK_ADDED_TO_TASK, { amount: 'one' });
      toast({
        status: 'success',
        title: 'Task updated',
        variant: 'subtle',
      });
    },
    onError: (_err, _vars, context) => {
      if (isFunction(context)) {
        // Revert the optimistic update in case of error
        context();
      }
      toast({
        status: 'error',
        title: 'We are having problems updating the Task',
      });
    },
  });

  const handleToggleStop = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();

      if (shouldShowUpgradeModal) {
        SessionService.setTemplateEditorProperty('addStopTaskModalSeen', true /* value */);
        disclosure.onOpen();
      } else {
        updateTemplateMutation.mutate({
          taskTemplateId: taskTemplate?.id ?? '',
          taskTemplate: { stop: !hasStop, name: taskTemplate?.name ?? '' },
        });
      }
    },
    [
      taskTemplate?.name,
      taskTemplate?.id,
      hasStop,
      SessionService,
      shouldShowUpgradeModal,
      disclosure,
      updateTemplateMutation,
    ],
  );

  const handleOnAddStopFromModal = React.useCallback(() => {
    updateTemplateMutation.mutate({
      taskTemplateId: taskTemplate?.id ?? '',
      taskTemplate: { stop: !hasStop, name: taskTemplate?.name },
    });
    UserSettingsService.updateTemplateEditor(SessionService.getTemplateEditorProperties());
    disclosure.onClose();
  }, [
    taskTemplate?.id,
    hasStop,
    taskTemplate?.name,
    updateTemplateMutation,
    SessionService,
    UserSettingsService,
    disclosure,
  ]);

  const tooltipLabel = match({ hasStop, isEditable })
    .with({ hasStop: true, isEditable: true }, () => 'Remove stop')
    .with({ hasStop: false, isEditable: true }, () => 'Add stop')
    .with({ hasStop: true, isEditable: false }, () => 'Has stop')
    .with({ hasStop: false, isEditable: false }, () => 'Has no stop')
    .otherwise(() => '');

  if (!isEditable && !hasStop) return null;

  return (
    <>
      <Tooltip shouldWrapChildren hasArrow label={tooltipLabel}>
        <IconButton
          aria-label="Add Stop"
          icon={
            <>
              {hasStop && <Icon icon="slash" size="4" color="purple.500" variant="far" sx={{ position: 'absolute' }} />}
              <Icon icon="hand" size="4" color={hasStop ? 'purple.500' : 'gray.400'} variant="far" />
            </>
          }
          onClick={isEditable ? handleToggleStop : () => {}}
          variant={isEditable ? 'outline' : 'ghost'}
          _hover={isEditable ? {} : { bgColor: 'transparent', cursor: 'default' }}
          borderColor="gray.300"
          borderWidth="px"
          size="sm"
        />
      </Tooltip>
      {disclosure.isOpen && (
        <StopTaskModal
          disclosure={disclosure}
          isAdmin={isAdmin}
          isFeatureAvailable={isFeatureAvailable}
          onAddStop={handleOnAddStopFromModal}
        />
      )}
    </>
  );
};
