import { FieldType, isFormFieldWidget, TaskTemplate, Template, Widget } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';
import { GetTaskAssignmentRulesByTemplateRevisionIdQuery } from 'app/features/task-assignment-rules/query-builder';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'app/features/template/query-builder';
import { DeleteWidgetByHeaderIdMutation, WidgetsByTemplateRevisionIdQuery } from 'app/features/widgets/query-builder';
import { ToastServiceImpl } from 'app/services/toast-service.impl';
import { makeMutation } from 'app/utils/query-builder/make-mutation';
import { QueryClient } from 'react-query';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';

interface MakeDeleteWidgetMutationOptions {
  widget: Widget;
  queryClient: QueryClient;
  templateId: Template['id'];
}

const TASK_ASSIGNMENT_RULES_FIELD_TYPES = [FieldType.Members, FieldType.Email];

export function makeDeleteWidgetMutation({ widget, queryClient, templateId }: MakeDeleteWidgetMutationOptions) {
  const templateRevisionId = isFormFieldWidget(widget)
    ? widget.templateRevision?.id
    : (widget.header.taskTemplate as TaskTemplate).templateRevision.id;

  const cacheSetter = WidgetsByTemplateRevisionIdQuery.makeCacheSetter({
    queryClient,
    templateRevisionId,
  });

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  const taskAssignmentRulesCacheSetter = GetTaskAssignmentRulesByTemplateRevisionIdQuery.makeCacheSetter({
    queryClient,
    templateRevisionId,
  });

  return makeMutation(queryClient, {
    mutationKey: DeleteWidgetByHeaderIdMutation.getKey(),
    mutationFn: () => DeleteWidgetByHeaderIdMutation.mutationFn(widget.header.id),
    onSuccess: () => {
      cacheSetter.delete(widget);
      templateRevisionCacheSetter.updateDraftLastUpdatedDate();
      if (isFormFieldWidget(widget) && TASK_ASSIGNMENT_RULES_FIELD_TYPES.includes(widget.fieldType)) {
        taskAssignmentRulesCacheSetter.deleteWidget(widget);
      }
      void queryClient.invalidateQueries(GetAllNativeAutomationsQuery.getKey({ templateRevisionId }));
    },
    onError: () => {
      ToastServiceImpl.openToast({
        status: 'error',
        title: `We're having problems deleting the widget`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  }).execute();
}
