import { TrayAutomationAppDetail, UnsafeTrayAutomationAppName } from './tray-automation-apps';

type OtherTag = 'ps_visible';
export interface TraySolution {
  id: string;
  title: string;
  description: string;
  tags: Array<SolutionTag | OtherTag | SolutionTypeTag>;
  solutionTypeTag?: SolutionTypeTag;
  automationApp: UnsafeTrayAutomationAppName;
}
export function isTraySolution(solution: unknown): solution is TraySolution {
  return typeof solution === 'object' && (solution as any).solutionTypeTag !== undefined;
}

export enum GenericSolutionTag {
  /**
   * @deprecated
   */
  Incoming = 'ps_incoming', // incoming solution - 'create checklist when'
  /**
   * @deprecated
   */
  Outgoing = 'ps_outgoing', // outgoing solution - 'When a checklist is complete then'
  AutomationApp = 'ps_provider', // solution provider prefix
}

export type SolutionTag = TrayAutomationAppDetail['tag'] | GenericSolutionTag;

export enum SolutionTypeTag {
  CreateChecklistWhen = 'ps_create_checklist_when',
  WhenTaskCheckedThen = 'ps_when_task_checked_then',
  WhenChecklistCompletedThen = 'ps_when_checklist_completed_then',
}

export const SolutionTypeTagDescriptionMap = {
  [SolutionTypeTag.CreateChecklistWhen]: 'Run a workflow when…',
  [SolutionTypeTag.WhenTaskCheckedThen]: 'When a task is checked then…',
  [SolutionTypeTag.WhenChecklistCompletedThen]: 'When a workflow run is complete then…',
};

export const FormSolutionTypeTagDescriptionMap = {
  [SolutionTypeTag.CreateChecklistWhen]: 'Create a form response when…',
  [SolutionTypeTag.WhenTaskCheckedThen]: 'When a page is completed then…',
  [SolutionTypeTag.WhenChecklistCompletedThen]: 'When a form is submitted...',
};

export const DataSetSolutionTypeTagDescription = {
  [SolutionTypeTag.CreateChecklistWhen]: 'Run a workflow when...',
  [SolutionTypeTag.WhenChecklistCompletedThen]: 'Update a Data Set when...',
  [SolutionTypeTag.WhenTaskCheckedThen]: 'When a task is checked then...',
};
