import React from 'react';
import { ConditionalLogicModal } from 'features/conditional-logic/components/modal';
import { TopBarActorRef, TopBarActorSelectors } from './top-bar-machine';
import { useSelector } from '@xstate/react';
import { useUiDisclosure } from '../ui-context';

export const FormConditionalLogicModalWrapper: React.FC<React.PropsWithChildren<{ actor: TopBarActorRef }>> = ({
  actor,
}) => {
  const template = useSelector(actor, TopBarActorSelectors.getTemplate);
  const templateRevision = useSelector(actor, TopBarActorSelectors.getTemplateRevision);

  const conditionalLogicDisclosure = useUiDisclosure('conditionalLogic');

  return templateRevision ? (
    <ConditionalLogicModal
      template={template}
      templateRevisionId={templateRevision.id}
      {...conditionalLogicDisclosure}
    />
  ) : null;
};
