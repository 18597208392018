import * as React from 'react';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { useToken } from 'components/design/next';
import { useBarStackHorizontalHeights } from './use-bar-stack-horizontal-heights';
import { StackKey } from '@visx/shape/lib/types';
import { useResponsiveMargin } from './use-responsive-margin';
import { ParentSizeRenderProps } from '../common/types';
import { getStringWidth } from '@visx/text';
import { useLegendHeight } from '../common/use-legend-height';

export function useBarStackHorizontal<Key extends StackKey, Datum extends Record<Key, any>>({
  data,
  keys,
  yDomain,
  yTickLabelDomain = yDomain,
  colors,
  parentSize: { width: parentWidth, height: parentHeight },
}: {
  data: Datum[];
  colors: string[];
  parentSize: ParentSizeRenderProps;
  keys: Key[];
  yDomain: (datum: Datum) => string;
  yTickLabelDomain?: (datum: Datum) => string;
}) {
  const { legendHeight, legendRef } = useLegendHeight<HTMLDivElement>();

  const fontSizeXs = useToken('fontSizes', 'xs');
  const yAxisTickMaxWidth =
    getStringWidth(data.map(yTickLabelDomain).sort((a, b) => b.length - a.length)[0] ?? '', {
      fontSize: fontSizeXs,
    }) ?? 0;
  const margin = useResponsiveMargin({ yAxisTickMaxWidth });

  const { chartHeight, chartOverflowHeight, xAxisHeight } = useBarStackHorizontalHeights({
    legendHeight,
    parentHeight,
    rowCount: data.length,
    margin,
  });

  // bounds
  const xMax = parentWidth - margin.left - margin.right;
  const yMax = chartHeight - margin.top - margin.bottom;

  const keyTotals = data.map(datum => {
    return keys.reduce((acc, status) => {
      acc += Number(datum[status]);
      return acc;
    }, 0);
  });

  // scales
  const xScale = scaleLinear<number>({
    domain: [0, Math.max(...keyTotals)],
    range: [0, xMax],
    round: true,
  });

  const yScale = scaleBand<string>({
    domain: data.map(yDomain),
    ...(data.length <= 5 ? { paddingOuter: 0.5 } : {}),
    paddingInner: Math.max(0.5, 0.9 - 0.1 * data.length),
    range: [0, yMax],
  });

  const colorScale = scaleOrdinal<Key, string>({
    domain: keys,
    range: useToken('colors', colors),
  });

  const invertYCoord = React.useCallback(
    (y: number) => {
      // step is the distance from the start of one row to the start of the next row, so bar height + padding
      const eachBandWidth = yScale.step();
      // round the y coord divided by band width to get the row index
      const index = Math.floor(y / eachBandWidth);
      const val = yScale.domain()[index];
      return val;
    },
    [yScale],
  );

  const value = React.useMemo(() => {
    return {
      colorScale,
      xScale,
      yScale,
      legendHeight,
      legendRef,
      xMax,
      yMax,
      chartOverflowHeight,
      xAxisHeight,
      chartHeight,
      margin,
      invertYCoord,
    };
  }, [
    colorScale,
    xScale,
    yScale,
    legendHeight,
    legendRef,
    xMax,
    yMax,
    chartOverflowHeight,
    xAxisHeight,
    chartHeight,
    margin,
    invertYCoord,
  ]);

  return value;
}
