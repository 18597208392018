import angular from 'angular';
import './redeem.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';

angular
  .module('frontStreetApp.controllers')
  .controller('RedeemCtrl', function ($scope, $state, OrganizationService, SessionService, ToastService) {
    const ctrl = this;

    ctrl.$onInit = () => {
      $scope.organization = SessionService.getSelectedOrganization() || { name: '???' };

      // If the coupon is set via the URL, auto-enter it
      $scope.coupon = $state.params.coupon;
    };

    $scope.setCoupon = function (coupon) {
      $scope.submitting = true;

      OrganizationService.setCouponById($scope.organization.id, coupon).then(
        () => {
          ToastService.openToast({
            status: 'success',
            title: `Coupon applied`,
          });

          $state.go('organizationManage.tab', { tab: 'billing' });
        },
        () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having applying the coupon`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });

          $scope.submitting = false;
        },
      );
    };
  });
