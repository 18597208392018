import * as React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { MultiSelectFieldValue } from '@process-street/subgrade/process';
import { getCursorLocation, HoverLocation } from 'hooks/get-cursor-location';
import { match } from 'ts-pattern';

export enum ItemTypes {
  Subtask = 'Subtask',
}

export type UseDropOnSubtaskListItemValue = {
  before?: MultiSelectFieldValue;
  after?: MultiSelectFieldValue;
  item: MultiSelectFieldValue;
};

export function useDropOnSubtaskListItem({
  itemValue,
  onDrop,
}: {
  itemValue: MultiSelectFieldValue;
  onDrop: (v: UseDropOnSubtaskListItemValue) => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);

  const [hoverLocation, setHoverLocation] = React.useState<HoverLocation>(null);

  const [dropResult, drop] = useDrop(
    () => ({
      accept: [ItemTypes.Subtask],
      drop: (item: MultiSelectFieldValue, monitor) => {
        if (!ref.current) return;

        const locationRelativeToWidget = match(getCursorLocation({ monitor, element: ref.current }))
          .with('top', () => ({ before: itemValue, item }))
          .with('bottom', () => ({ after: itemValue, item }))
          .otherwise(() => ({ item }));

        onDrop(locationRelativeToWidget);
      },
      hover(_item, monitor) {
        if (!ref.current) return;
        setHoverLocation(getCursorLocation({ monitor, element: ref.current }));
      },
      collect: monitor => ({ isOver: monitor.isOver() }),
    }),
    [onDrop],
  );

  const [{ isDragging }, drag] = useDrag(
    {
      type: ItemTypes.Subtask,
      item: () => itemValue,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [itemValue],
  );

  React.useEffect(() => {
    drag(drop(ref));
  }, [drop, drag]);

  return React.useMemo(
    () => ({
      dropResult,
      ref,
      hoverLocation,
      isDragging,
    }),
    [dropResult, hoverLocation, isDragging],
  );
}
