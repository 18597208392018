import * as React from 'react';
import { Input } from 'components/design/next';

interface TaskNameInputProps {
  initialValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onFocus?: (field: string) => void;
}

export const TaskNameInput: React.FC<React.PropsWithChildren<TaskNameInputProps>> = React.memo(
  ({ initialValue, onChange, onBlur, onFocus }) => {
    const [name, setName] = React.useState(initialValue);
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
      onChange(e);
    };
    return (
      <Input
        bgColor="white"
        name="name"
        placeholder="Task name"
        value={name}
        onBlur={onBlur}
        onChange={handleNameChange}
        autoFocus={true}
        onFocus={() => onFocus?.('name')}
      />
    );
  },
);
