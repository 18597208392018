import { SystemStyleObject } from '@chakra-ui/system';
import merge from 'lodash/merge';

const commonAgGridStyleProps: SystemStyleObject = {
  '.ag-header-container': {
    bgColor: 'brand.100',
  },
  '.ag-header-cell': {
    fontWeight: '500',
    color: 'gray.700',
    fontSize: 'sm',
    px: '2',
  },
  '.ag-row': {
    '&:hover': {
      bgColor: 'gray.100',
    },
  },
  '.ag-cell': {
    'color': 'gray.600',
    'lineHeight': 'base',

    '&.focus-visible': {
      outlineColor: 'brand.400',
      outlineWidth: 0.5,
      outlineStyle: 'auto',
      outlineOffset: 0,
    },
  },
  '.ag-header-viewport .ag-header-cell .column-name, .ag-cell': {
    fontSize: 'sm',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.100',
    borderCollapse: 'collapse',
  },
  '.ag-cell, .ag-header-cell .column-name p': {
    p: '6px',
  },
  '.ag-header-viewport .ag-header-cell.focus-visible': {
    outline: 'none',
  },
  '.ag-header-viewport .ag-header-cell .column-name': {
    bgColor: 'brand.100',
    marginTop: '0',
    minHeight: '9',
  },
  '.ag-header-viewport .ag-header-cell.focus-visible .column-name': {
    outlineColor: 'brand.400',
    outlineWidth: 0.5,
    outlineStyle: 'auto',
    outlineOffset: 0,
  },
  '.ag-header-viewport .ag-header-cell .column-name input': {
    outlineColor: 'brand.400',
    width: '100%',
    height: '26px',
    borderRadius: '0',
    marginLeft: '4px',
  },
  '.ag-cell-inline-editing': {
    'padding': 0,
    'bgColor': 'white',

    '&:focus-within': {
      outlineColor: 'brand.500',
      outlineWidth: 0.5,
      outlineStyle: 'auto',
      outlineOffset: 0,
    },
  },
  '.row-context-menu-cell.ag-cell.ag-cell-focus': {
    outline: 'none',
  },
  '.row-context-menu-cell': {
    border: 'none !important',
    backgroundColor: 'white',
  },
  '.ag-header-cell-sortable': {
    cursor: 'auto',
  },
  '.ag-row:not(.ag-row-first) .ag-cell': {
    borderTop: '0',
  },
  '.ag-cell:not([aria-colindex="1"])': {
    borderLeft: '0',
  },
  // Center checkbox in header column
  ['.ag-pinned-left-header .ag-header-row .ag-header-cell .ag-header-cell-comp-wrapper,' +
  '.ag-pinned-left-cols-container .ag-row .ag-cell']: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // Custom checkbox style
  '.ag-selection-checkbox': {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',

    '&.ag-invisible': {
      display: 'none',
    },

    '.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper': {
      'w': 5,
      'h': 5,
      'border': '1px solid',
      'borderColor': 'gray.400',
      'borderRadius': 'sm',
      'cursor': 'pointer',

      'input': {
        appearance: 'none',
        opacity: 0,
        cursor: 'pointer',
        w: 'full',
        h: 'full',
      },

      '&.ag-checked': {
        'bgColor': 'brand.500',
        'borderColor': 'brand.500',
        'opacity': 1,

        '&::before': {
          content: '""',
          position: 'absolute',
          w: 'full',
          h: 'full',
          bg: `url(${require('app/images/reports/check-icon.svg')})`,
          bgPosition: 'center',
          bgRepeat: 'no-repeat',
        },
      },
      '&.ag-disabled': {
        bgColor: 'gray.300',
      },
    },
  },

  // fixes selected cells shifting away by 1px
  '--ag-range-selection-border-color': 'transparent',
  '--ag-range-selection-border-style': 'solid',
  '.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within': {
    borderLeft: 'none',
  },
};
export const withCommonAgGridStyleProps = (styleProps: SystemStyleObject) =>
  merge({}, commonAgGridStyleProps, styleProps);
