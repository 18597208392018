import { axiosService } from 'services/axios-service';
import { AxiosResponse } from 'axios';
import { Muid } from '@process-street/subgrade/core';
import { OrganizationMembershipUsageStats } from 'pages/organizations/manage/users/models/organization-membership-usage-stats';

const getOrganizationMembershipUsageStats = (
  organizationMembershipId: Muid,
): Promise<OrganizationMembershipUsageStats> => {
  return axiosService
    .getAxios()
    .get(`/1/organization-memberships/${organizationMembershipId}/usage-stats`, {})
    .then((response: AxiosResponse) => {
      return response.data as OrganizationMembershipUsageStats;
    });
};

export const UsageStatsApi = {
  getOrganizationMembershipUsageStats,
};
