import { Icon, IconButton, Tooltip, useDisclosure } from 'components/design/next';
import { DataSet, SavedView } from '@process-street/subgrade/process';
import React from 'react';
import { ShareDataSetModal } from './share-data-set-modal';
import { ShareSavedViewModal } from './share-saved-view-modal';

export type ShareDataSetButtonProps = {
  dataSetId: DataSet['id'];
  isDisabled: boolean;
  savedViewId?: SavedView['id'];
};

export const ShareDataSetButtonV1: React.FC<React.PropsWithChildren<ShareDataSetButtonProps>> = ({
  dataSetId,
  isDisabled,
  savedViewId,
}) => {
  const shareDataSetDisclosure = useDisclosure();
  const shareSavedViewDisclosure = useDisclosure();
  const toolTipLabel = isDisabled
    ? `You don't have access to edit this saved view. Please reach out to the owner or an admin.`
    : savedViewId
    ? 'Share Saved View'
    : 'Share Data Set';

  return (
    <>
      <Tooltip hasArrow shouldWrapChildren label={toolTipLabel}>
        <IconButton
          isDisabled={isDisabled}
          onFocus={e => e.preventDefault()}
          variant="ghost"
          colorScheme="gray"
          aria-label="Share Data Set"
          borderRadius="4px"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.300"
          icon={<Icon color="gray.500" icon="share-alt" size="4" />}
          onClick={savedViewId ? shareSavedViewDisclosure.onOpen : shareDataSetDisclosure.onOpen}
        />
      </Tooltip>
      <ShareDataSetModal isLoading={false} dataSetId={dataSetId} {...shareDataSetDisclosure} />
      {savedViewId && (
        <ShareSavedViewModal
          isLoading={false}
          dataSetId={dataSetId}
          savedViewId={savedViewId}
          {...shareSavedViewDisclosure}
        />
      )}
    </>
  );
};
