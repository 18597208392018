import angular from 'angular';
import { reactToAngular } from 'angulareact';
import { DueDateTaskIndicator } from './due-date-task-indicator';

export const DueDateTaskIndicatorModule = angular
  .module('dueDateTaskIndicatorModule', [])
  .component(
    'psDueDateTaskIndicator',
    reactToAngular(DueDateTaskIndicator, [
      'templateRevisionId',
      'taskTemplate',
      'checklistRevisionId',
      'isTaskSelected',
      'task',
    ]),
  ).name;
