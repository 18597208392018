import { Box, Flex, Skeleton, SkeletonText, useToast, VStack } from 'components/design/next';
import React from 'react';
import { FormResponseBody } from './components/form-response-body';
import { makeFormResponsePageMachine } from './form-response-page-machine';
import { useMachine } from '@xstate/react';
import { useFormResponseSharedContext } from './hooks/use-form-response-shared-context';
import { useStateParam } from 'hooks/use-state-param';
import { useInjector } from 'components/injection-provider';
import { AuthInternalErrors, DefaultErrorMessages } from 'components/utils/error-messages';
import usePromise from 'react-use/lib/usePromise';
import { useMount } from 'react-use';

export const FormResponsePage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const formResponseId = useStateParam({ key: 'id' })!;
  const sharedContext = useFormResponseSharedContext();
  const [state] = useMachine(() => makeFormResponsePageMachine({ formResponseId, sharedContext }));

  const mounted = usePromise();
  const toast = useToast();
  const { AnonymousAuthService } = useInjector('AnonymousAuthService');

  useMount(() => {
    mounted(AnonymousAuthService.checklistViewAnonymousAuth(formResponseId)).catch(error => {
      if (error.message === AuthInternalErrors.generalError) {
        toast({
          status: 'warning',
          title: 'Login to complete form',
          description: 'This form requires you to be logged in to complete it.',
        });
      } else {
        toast({
          status: 'error',
          title: "We're having problems loading the form",
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      }
    });
  });

  return state.matches('loading') ? (
    <FormResponseBodySkeleton />
  ) : state.matches('ready') ? (
    <FormResponseBody actor={state.context.formResponseActor!} />
  ) : null;
};

const FormResponseBodySkeleton: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex
    h="100vh"
    bgColor="brand.100"
    justifyContent="center"
    overflowY="auto"
    pb={{ base: '0', lg: '12' }}
    pt={{ base: '32', lg: 0 }}
    data-testid="skeleton-container"
  >
    <Box
      w={{ base: 'full', lg: '3xl' }}
      h="fit-content"
      mt={{ base: '0', lg: '8' }}
      bgColor="white"
      borderRadius={{ base: '0', lg: 'lg' }}
      p="8"
    >
      <SkeletonText pl="1" mt="4" w="60%" noOfLines={3} skeletonHeight="3" />
      <VStack alignItems="start" spacing="8" mt="4" px="1">
        <VStack alignItems="start">
          <Skeleton fontSize="xs">First Name</Skeleton>
          <Skeleton fontSize="md">Alfred</Skeleton>
        </VStack>
        <VStack alignItems="start">
          <Skeleton fontSize="xs">Email</Skeleton>
          <Skeleton fontSize="md">alfred@process.st</Skeleton>
        </VStack>
        <Skeleton w="75%" h="20"></Skeleton>
      </VStack>
    </Box>
  </Flex>
);
