import React, { useEffect, useState } from 'react';
import { useToast } from 'components/design/next';
import { CreateWorkflow } from 'features/create-organization/components/create-workflow-cta/component';
import { NewOrganizationModal } from 'features/create-organization/components/new-organization-modal';

interface CreatePlaybookToastProps {
  onToastClose?: () => void;
  isOpen: boolean;
}

export const CreateWorkflowToast: React.FunctionComponent<React.PropsWithChildren<CreatePlaybookToastProps>> = ({
  onToastClose,
  isOpen,
}) => {
  const toast = useToast();
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen && !toastIsOpen) {
      setToastIsOpen(true);

      toast({
        position: 'bottom-right',
        duration: null,
        render: ({ onClose }) => {
          const handleClose = () => {
            onClose();
            setToastIsOpen(false);
            onToastClose?.();
          };

          return (
            <CreateWorkflow
              onClose={handleClose}
              onOpenNewOrganizationModal={() => {
                handleClose();
                setModalIsOpen(true);
              }}
            />
          );
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only when isOpen prop changes
  }, [isOpen]);

  return <NewOrganizationModal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} />;
};
