import { DataSetPermit } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type DeleteDataSetPermissionMutationParams = {
  dataSetId: string;
  permitId: string;
};

export type DeleteDataSetPermissionMutationResponse = DataSetPermit;

export const DeleteDataSetPermitMutation = {
  mutationFn: (params: DeleteDataSetPermissionMutationParams) =>
    axiosService
      .getAxios()
      .delete<DeleteDataSetPermissionMutationResponse>(`/1/data-sets/${params.dataSetId}/permits/${params.permitId}`)
      .then(res => res.data),
};

export const useDeleteDataSetPermitMutation = (
  options: UseMutationOptions<
    DeleteDataSetPermissionMutationResponse,
    AxiosError,
    DeleteDataSetPermissionMutationParams
  >,
) => {
  return useMutation(DeleteDataSetPermitMutation.mutationFn, options);
};
