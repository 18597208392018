import { EquatableSet } from 'services/equatable-set.pure';
import { FutureChecklistRow } from './query';
import create from 'zustand';
import { combine } from 'zustand/middleware';

const INITIAL_STATE = {
  searchQuery: '',
  offset: 0,
  hasMoreResults: true,
  rows: new EquatableSet<FutureChecklistRow>('id'),
  day: '',
};
const useScheduledViewStore = create(
  combine(INITIAL_STATE, set => ({
    setSearchQuery: (searchQuery: string) => set({ searchQuery }),
    showMore: () => set(state => ({ offset: state.offset + 1 })),
    setHasMoreResults: (hasMoreResults: boolean) => set({ hasMoreResults }),
    setRows: ({ reset, newRows }: { reset: boolean; newRows: FutureChecklistRow[] }) => {
      set(current => {
        if (reset) {
          const draft = new EquatableSet<FutureChecklistRow>('id');
          draft.addAll(newRows);
          return { rows: draft };
        }

        const draft = current.rows.clone();
        draft.addAll(newRows);
        return { rows: draft };
      });
    },
    setDay: (day: string) => set({ day }),
  })),
);

const resetStore = () => useScheduledViewStore.setState(INITIAL_STATE);

export { useScheduledViewStore, resetStore };
