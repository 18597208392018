import { useBreakpoint, useToken } from '@chakra-ui/react';

const PX_IN_EM = 16;

export const useBreakpointPx = () => {
  const screenSize = useBreakpoint() ?? 'lg';
  // get breakpoint (in em)
  const token = useToken('breakpoints', screenSize);
  const breakpointPx = parseInt(token) * PX_IN_EM;
  return breakpointPx;
};
