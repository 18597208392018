import * as React from 'react';
import { Group } from '@visx/group';
import { Box } from 'components/design/next';
import { Margin } from '@visx/xychart';
import { BarStackHorizontalMarginContext } from './bar-stack-horizontal-margin-context';

export type BarStackHorizontalScrollableProps = {
  barStackHorizontal: React.ReactElement;
  axisLeft: React.ReactElement;
  axisBottom: React.ReactElement;
  chartOverflowHeight: number;
  chartHeight: number;
  parentWidth: number;
  xAxisHeight: number;
  margin: Margin;
};

export const BarStackHorizontalScrollable: React.VFC<BarStackHorizontalScrollableProps> = ({
  barStackHorizontal,
  axisLeft,
  axisBottom,
  chartHeight,
  chartOverflowHeight,
  parentWidth,
  xAxisHeight,
  margin,
}) => {
  const marginContext = React.useMemo<Margin>(() => {
    return {
      top: margin.top,
      bottom: margin.bottom,
      left: margin.left,
      right: margin.right,
    };
    // unpack and repack for easier dep comparison
  }, [margin.bottom, margin.right, margin.left, margin.top]);

  return (
    <BarStackHorizontalMarginContext.Provider value={marginContext}>
      <Box height={chartOverflowHeight} overflowY="auto">
        <Box as="svg" width={parentWidth} height={chartHeight} display="block">
          <Group left={margin.left} top={margin.top}>
            {barStackHorizontal}
            {axisLeft}
          </Group>
        </Box>
      </Box>

      {/* separate svg so the x-axis is fixed and the y-axis can scroll */}
      <svg height={xAxisHeight} width={parentWidth}>
        <Group left={margin.left}>{axisBottom}</Group>
      </svg>
    </BarStackHorizontalMarginContext.Provider>
  );
};
