import { RuleConstants } from '@process-street/subgrade/conditional-logic/rule-constants';
import { ArrayService } from 'services/array-service';
import { trace } from 'components/trace';
import { FormFieldValueExtractor } from '../services/form-field-value-extractor';
import { OperandTypeResolver } from './operand-type-resolver';
import {
  MultiChoiceFormFieldValue,
  MultiChoiceFormFieldWidget,
  SimpleFieldValue,
} from '@process-street/subgrade/process';
import { ConditionEvaluator } from '@process-street/subgrade/conditional-logic';

const logger = trace({ name: 'DoesNotContainCondition' });

export const DoesNotContainCondition: ConditionEvaluator = {
  evaluate: (formFieldValue, formFieldWidget, operand) => {
    if (!formFieldValue || !formFieldWidget || !operand) return false;

    const operandType = OperandTypeResolver.resolve(formFieldWidget);

    let evaluatedAsTrue = false;

    switch (operandType) {
      case RuleConstants.OperandType.STRING:
        const fieldValue = (formFieldValue?.fieldValue as SimpleFieldValue)?.value;
        evaluatedAsTrue = !fieldValue || !fieldValue.toString().includes(operand.toString());
        break;
      case RuleConstants.OperandType.STRING_LIST: {
        const selectedItemNames = FormFieldValueExtractor.extractSelectedItemNamesFromMultiChoice(
          formFieldValue as MultiChoiceFormFieldValue,
          formFieldWidget as MultiChoiceFormFieldWidget,
        );
        evaluatedAsTrue =
          selectedItemNames.length === 0 || !operand || !ArrayService.includes(selectedItemNames, operand.toString());
        break;
      }
      default:
        logger.error('unsupported operand type: %s', operandType);
    }

    return evaluatedAsTrue;
  },
};
