import {
  mailto,
  RichEmailWidgetAttachmentWithS3File,
  SendRichEmailFormFieldConfig,
  SendRichEmailFormFieldWidget,
} from '@process-street/subgrade/process';
import { Option } from '@process-street/subgrade/core';
import { EditorUtils } from 'features/widgets/components/send-rich-email/template/editor/editor-utils';
import { nl2br, StringUtils } from '@process-street/subgrade/util';

export const EMPTY_HTML_STRINGS = [
  /^\s*$/,
  /^<p>\s*<\/p>$/,
  /^<html><head><\/head><body><p>\s*<\/p><\/body><\/html>$/,
  /^<html><head><\/head><body>\s*<\/body><\/html>$/,
];

export function bodyIsEmpty(body = ''): boolean {
  const string = EditorUtils.withoutStyleString(body);
  return EMPTY_HTML_STRINGS.some(regExp => regExp.test(string));
}

export function getEmailBody<
  Config extends Pick<
    SendRichEmailFormFieldWidget['config'],
    'editor' | 'richEditorBody' | 'rawHTMLBody' | 'plainTextBody'
  >,
>(widgetConfig: Config): string {
  switch (widgetConfig.editor) {
    case 'RichEditor':
      return widgetConfig.richEditorBody ?? '';
    case 'RawHTMLEditor':
      return widgetConfig.rawHTMLBody ?? '';
    case 'PlainTextEditor':
      return widgetConfig.plainTextBody ?? '';
  }
}

/**
 * This makes sure the body is always in HTML format.
 */
export function normalizeBodyToHtml(body: string, emailFormat: SendRichEmailFormFieldConfig['emailFormat']): string {
  if (emailFormat === 'PlainText') {
    return nl2br(body);
  } else {
    return body;
  }
}

export function isSendEmailDisabled({
  to,
  subject,
  body,
}: {
  to: Option<string[]>;
  subject: Option<string>;
  body?: string;
}): boolean {
  const toIsEmpty = to ? to.length === 0 : true;
  return toIsEmpty || bodyIsEmpty(body) || StringUtils.isBlank(subject);
}

export function isSendTestEmailDisabled<
  Config extends Pick<
    SendRichEmailFormFieldWidget['config'],
    'editor' | 'subject' | 'richEditorBody' | 'rawHTMLBody' | 'plainTextBody' | 'to'
  >,
>(widgetConfig: Config): boolean {
  const body = getEmailBody(widgetConfig);
  const { subject } = widgetConfig;
  return bodyIsEmpty(body) || StringUtils.isBlank(subject);
}

export function getMailToLink(widgetConfig: SendRichEmailFormFieldWidget['config']): string {
  return mailto({
    to: widgetConfig.to,
    cc: widgetConfig.cc,
    bcc: widgetConfig.bcc,
    subject: widgetConfig.subject,
    body: widgetConfig.plainTextBody,
  });
}

export const calculateTotalSizeOfAttachments = (attachments: RichEmailWidgetAttachmentWithS3File[]) =>
  attachments.map(a => a.s3File.size ?? 0).reduce((prev, curr) => prev + curr, 0);
