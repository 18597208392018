import angular from 'angular';
import { env } from 'components/common/env';
import Muid from 'node-muid';
import { HttpStatus } from '@process-street/subgrade/util';
import { PlanInterval, PlanLevel } from '@process-street/subgrade/billing';
import { ConfettiTheme } from 'directives/confetti/confetti-theme';
import { CookieService } from 'features/cookies/cookie-service';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { LocalStorageService } from 'features/storage/local-storage-service';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'DeveloperConsoleToolsCtrl',
    function (
      $ngRedux,
      $rootScope,
      $sce,
      $scope,
      $state,
      $timeout,
      $window,
      appBootService,
      AnonAuthService,
      Auth0Service,
      Authentication,
      auth,
      Diagnostics,
      OrganizationService,
      OrganizationMembershipService,
      PlanService,
      ScheduleTools,
      SessionService,
      TempDataService,
      UserService,
      ToastService,
    ) {
      'ngInject';
      const ctrl = this;
      const logger = trace({ name: 'DeveloperConsoleToolsCtrl' });
      logger.info('loading ctrl');

      ctrl.$onInit = () => {
        initializeScheduleTools();
        const selectedOrganizationId = SessionService.getSelectedOrganizationId();

        $scope.user = SessionService.getUser();
        $scope.token = SessionService.getToken();

        OrganizationMembershipService.getByOrganizationIdAndUserId(selectedOrganizationId, $scope.user.id).then(om => {
          $scope.userOm = om;
        });

        OrganizationService.getById(selectedOrganizationId).then(
          organization => {
            $scope.organization = organization;

            PlanService.getById($scope.organization.subscription.plan.id).then(plan => {
              $scope.plan = plan;
            });
          },
          _response => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading the organization`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          },
        );

        // SatisMeter

        $scope.surveyForced = TempDataService.isSatisMeterSurveyForced();

        // App

        $scope.environment = env.APP_STAGE;
        $scope.version = LocalStorageService.getItem('version');

        // Muid Converter

        $scope.uuid = '';
        $scope.muid = '';

        $scope.$watch('uuid', value => {
          if (value && value.length === 36) {
            $scope.muid = Muid.fromUuid(value);
          }
        });

        $scope.$watch('muid', value => {
          if (value && value.length === 22) {
            $scope.uuid = Muid.toUuid(value);
          }
        });
      };

      // Template Import/Export

      $scope.updateExportTemplateUrl = () => {
        const url = `${env.APP_API_URL}/1/templates/${$scope.exportTemplateId}/export`;
        $scope.templateExportUrl = $sce.trustAsResourceUrl(url);
      };

      $scope.exportTemplate = () => {
        const form = angular.element('#templateExportForm');
        const token = SessionService.getToken();
        CookieService.setTemporaryTokenCookie(token);
        form.submit();
      };

      $scope.importTemplate = (event, data) => {
        data.url = `${env.APP_API_URL}/1/templates/import`;
        data.headers = SessionService.getAuthHeaders();

        return new Promise((resolve, reject) => {
          data.submit().then(resolve, reject);
        }).then(
          result => {
            $state.go('templateView', { id: result.id });
          },
          _response => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems importing the workflow`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          },
        );
      };

      // Confetti

      $scope.selectedConfettiTheme = null;

      $scope.confettiThemes = [ConfettiTheme.ProcessStreet, ConfettiTheme.Halloween, ConfettiTheme.Christmas];

      // Organization

      $scope.getStripeLink = organization => {
        const envIsProd = env.APP_STAGE === 'prod';
        return (
          organization &&
          (envIsProd
            ? 'https://dashboard.stripe.com/customers/' + organization.stripeId
            : 'https://dashboard.stripe.com/test/customers/' + organization.stripeId)
        );
      };

      $scope.repairStripe = function (organization) {
        OrganizationService.repairStripeByOrganization(organization).then(
          repairedOrganization => {
            if (repairedOrganization.stripeId === organization.stripeId) {
              ToastService.openToast({
                status: 'success',
                title: `Stripe is fine.  No need to repair.`,
              });
            } else {
              ToastService.openToast({
                status: 'success',
                title: `Stripe repaired`,
              });

              $rootScope.$broadcast(EventName.ORGANIZATION_UPDATED, repairedOrganization);

              $scope.organization = repairedOrganization;
            }
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems repairing Stripe`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          },
        );
      };

      // Subscription

      $scope.startSubscriptionTrial = function (organization) {
        const planDescriptor = PlanService.findDescriptor($scope.plan.id);
        if (planDescriptor) {
          const planId = planDescriptor[PlanLevel.Standard][PlanInterval.Monthly];

          OrganizationService.startSubscriptionTrialByOrganizationId(organization.id, planId).then(
            () => {
              ToastService.openToast({
                status: 'success',
                title: `Trial started.  Reloading...`,
              });
            },
            () => {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems starting the trial`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          );
        } else {
          ToastService.openToast({
            status: 'warning',
            title: `We couldn't start the trial`,
            description: 'You can only start trials for non-legacy plans.',
          });
        }
      };

      $scope.endSubscriptionTrial = function (organization) {
        OrganizationService.endSubscriptionTrialByOrganizationId(organization.id).then(
          () => {
            ToastService.openToast({
              status: 'success',
              title: `Trial started.  Reloading...`,
              description: 'Wait 10-30 seconds for Stripe to notice.',
            });
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems ending the trial`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          },
        );
      };

      // Masquerade

      $scope.masquerade = function (email) {
        Authentication.masquerade({ email }).$promise.then(
          result => {
            ToastService.openToast({
              status: 'success',
              title: 'Masquerade successful. You can change subdomains for 5 minutes. Enjoy the ball! Reloading...',
            });

            auth.logout();
            $timeout(() => {
              CookieService.setTemporaryTokenCookie(result.token);
              auth.storeToken(result.token);

              appBootService.setupSessionAndBootMasqueraded().then(() => {
                $timeout(() => $window.location.reload(true));
              });
            });
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having masquerading as that user`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          },
        );
      };

      const AnonymousMasqueradeMessage =
        'Note: This sort of masquerading should not normally be possible. This is for testing purposes only.';

      $scope.masqueradeAsAnonymousByTemplateId = async function (templateId) {
        $window.alert(AnonymousMasqueradeMessage);
        try {
          const result = await AnonAuthService.authenticateAnonymously('Template', templateId);
          auth.storeToken(result.token);
          $timeout(() => $window.location.reload(true));

          ToastService.openToast({
            status: 'success',
            title: `Masquerade complete.  Reloading....`,
          });
        } catch (err) {
          if (err instanceof Error) {
            // This means it was an error unrelated to the authentication
            throw err;
          }
          ToastService.openToast({
            status: 'error',
            title: `We're having problems masquerading`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        }
      };

      $scope.masqueradeAsAnonymousByChecklistId = async function (checklistId) {
        $window.alert(AnonymousMasqueradeMessage);
        try {
          const result = await AnonAuthService.authenticateAnonymously('Checklist', checklistId);
          auth.storeToken(result.token);
          $timeout(() => $window.location.reload(true));
          ToastService.openToast({
            status: 'success',
            title: `Masquerade complete.  Reloading....`,
          });
        } catch (err) {
          if (err instanceof Error) {
            // This means it was an error unrelated to the authentication
            throw err;
          }
          ToastService.openToast({
            status: 'error',
            title: `We're having problems masquerading`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        }
      };

      // User

      $scope.toggleSurveyForced = function () {
        $scope.surveyForced = !$scope.surveyForced;
        TempDataService.setSatisMeterSurveyForced($scope.surveyForced);
      };

      $scope.testSocketTimeout = function () {
        $scope.testInProgress = true;

        const startTime = Date.now();
        Diagnostics.testSocketTimeout({ duration: 120 })
          .$promise.then(
            () => {
              ToastService.openToast({
                status: 'error',
                title: `We're having testing the socket timeout`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
            response => {
              const endTime = Date.now();
              const totalTime = endTime - startTime;

              switch (response.status) {
                case HttpStatus.INTERNAL_SERVER_ERROR:
                  ToastService.openToast({
                    status: 'success',
                    title: `Passed socket timeout test (${totalTime}ms).`,
                  });
                  break;

                default:
                  ToastService.openToast({
                    status: 'error',
                    title: 'Failed socket timeout test.',
                    description: DefaultErrorMessages.unexpectedErrorDescription,
                  });
              }
            },
          )
          .finally(() => {
            $scope.testInProgress = false;
          });
      };

      $scope.testException = () => {
        $scope.testInProgress = true;
        Diagnostics.testException().$promise.finally(() => {
          $scope.testInProgress = false;
        });
      };

      $scope.testLoggerError = () => {
        logger.error('error emitted with argument replaced "%s"', 'replaced');
        $scope.testInProgress = true;
        Diagnostics.testLoggerError().$promise.finally(() => {
          $scope.testInProgress = false;
        });
      };

      $scope.enableAutoTokenRenewal = function () {
        Auth0Service.scheduleTokenRenewal();
        ToastService.openToast({
          status: 'success',
          title: `Token renewal enabled`,
        });
      };

      $scope.disableAutoTokenRenewal = function () {
        Auth0Service.unscheduleTokenRenewal();
        ToastService.openToast({
          status: 'success',
          title: `Token renewal disabled`,
        });
      };

      // Scheduler

      function initializeScheduleTools() {
        ScheduleTools.count().$promise.then(result => {
          $scope.scheduledCount = result.count;
        });

        ScheduleTools.countPotential().$promise.then(result => {
          $scope.potentialScheduledCount = result.count;
        });
      }

      function pollScheduledChecklists() {
        $scope.schedulingChecklists = true;

        $timeout(() => {
          logger.info('polling scheduled checklists');

          ScheduleTools.count().$promise.then(
            result => {
              if (result.count !== $scope.scheduledCount) {
                $scope.scheduledCount = result.count;
                pollScheduledChecklists();
              } else {
                $scope.schedulingChecklists = false;
              }
            },
            () => {
              $scope.schedulingChecklists = false;
            },
          );
        }, 5000);
      }

      $scope.scheduleChecklists = function () {
        ScheduleTools.schedule().$promise.then(
          () => {
            pollScheduledChecklists();

            ToastService.openToast({
              status: 'success',
              title: `Workflows scheduled`,
            });
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems scheduling the workflows`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          },
        );
      };

      $scope.muidToUuid = function (muid) {
        return muid ? Muid.toUuid(muid) : '';
      };
    },
  );
