import { isPlanFree, Plan } from '../billing';
import { Option } from '../core/option';
import { EmailWidget } from './widget-model';

export interface MailtoSpec {
  to?: string | string[];
  cc?: string | string[];
  bcc?: string | string[];
  subject?: string;
  body?: string;
}

export interface EmailHeader {
  name: string;
  value: string;
}

export const toEmailLine = (emails?: string | string[]): Option<string> => {
  if (emails) {
    if (Array.isArray(emails)) {
      return emails
        .map(email => email.trim())
        .filter(email => email !== '')
        .join(',');
    }
    return emails.trim();
  }
  return undefined;
};

export const mailtoBodyByPlan = (body?: string, plan?: Plan): Option<string> => {
  if (plan && isPlanFree(plan)) {
    return `${body ?? ''} \n\nSent by Process.st`;
  }
  return body;
};

export const mailtoByWidgetAndPlan = (widget: EmailWidget, currentPlan: Option<Plan>): string => {
  const mailtoSpec = {
    to: widget.recipient,
    cc: widget.cc,
    bcc: widget.bcc,
    subject: widget.subject,
    body: mailtoBodyByPlan(widget.body, currentPlan),
  };

  return mailto(mailtoSpec);
};

export const mailto = (spec: MailtoSpec): string => {
  let url = 'mailto:';

  const to = toEmailLine(spec.to);
  if (to) {
    url += encodeURIComponent(to);
  }

  const headers: EmailHeader[] = [];

  const cc = toEmailLine(spec.cc);
  if (cc) {
    headers.push({
      name: 'cc',
      value: encodeURIComponent(cc),
    });
  }

  const bcc = toEmailLine(spec.bcc);
  if (bcc) {
    headers.push({
      name: 'bcc',
      value: encodeURIComponent(bcc),
    });
  }

  if (spec.subject) {
    headers.push({
      name: 'subject',
      value: encodeURIComponent(spec.subject),
    });
  }

  if (spec.body) {
    headers.push({
      name: 'body',
      value: encodeURIComponent(spec.body),
    });
  }

  if (headers.length) {
    url += `?${headers.map((header: EmailHeader) => `${header.name}=${header.value}`).join('&')}`;
  }

  return url;
};
