import { forwardRef, omitThemingProps, ThemingProps, useStyleConfig, HTMLChakraProps } from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import * as React from 'react';
import { Box } from 'components/design/next';
import { RenderElementProps } from 'slate-react';

export interface EditorVoidProps extends HTMLChakraProps<'div'>, ThemingProps, Pick<RenderElementProps, 'attributes'> {}

export const EditorVoid = forwardRef<EditorVoidProps, 'div'>((props, ref) => {
  const { className, ...rest } = omitThemingProps(props);
  const styles = useStyleConfig('EditorVoid', props);
  const _className = cx('chakra-editor-void', className);

  return <Box sx={styles} ref={ref} className={_className} {...rest} />;
});
