import angular from 'angular';
import templateUrl from './doodads.component.html';
import './doodads.scss';

angular.module('frontStreetApp.directives').component('psDoodads', {
  bindings: {
    user: '<',
    checklistRevision: '<',
    activeStepId: '<',
    activeChecklistTask: '<',
    doodadsContainerClass: '<',
    isSandboxMode: '<',
  },
  templateUrl,
  controller($q, DoodadsService) {
    const ctrl = this;

    ctrl.initialized = false;
    ctrl.doodadsMap = {};

    ctrl.$onInit = function () {
      ctrl._initializeDoodadsAndActivities(ctrl.checklistRevision).then(() => (ctrl.initialized = true));
    };

    ctrl.$onChanges = function (changes) {
      if (changes.checklistRevision && changes.checklistRevision.previousValue.id) {
        ctrl.initialized = false;
        ctrl._initializeDoodadsAndActivities(ctrl.checklistRevision).then(() => {
          ctrl.initialized = true;
        });
      }
    };

    ctrl._initializeDoodadsAndActivities = function (revision) {
      ctrl.doodadsMap = {};
      const taskActivitiesRequest = DoodadsService.retrieveTaskActivities(revision).then(siloDoodads);

      const formFieldActivitiesRequest = DoodadsService.retrieveFormFieldValueActivities(revision).then(siloDoodads);

      return $q.all({
        taskActivities: taskActivitiesRequest,
        formFieldActivities: formFieldActivitiesRequest,
      });
    };

    function siloDoodads(doodads) {
      const { doodadsMap } = ctrl;

      doodads.forEach(doodad => {
        const stepId = toStepId(doodad.task);

        doodadsMap[stepId] = doodadsMap[stepId] || [];
        doodadsMap[stepId].push(doodad);
      });

      return doodads;
    }

    function toStepId(task) {
      return task.taskTemplate.group.id;
    }
  },
});
