import { Margin } from '@visx/xychart';
import { useBreakpointValue } from 'components/design/next';

const LEFT_BASE = 48;
const LEFT_MD = 248;
const RIGHT_BASE = 48;
const RIGHT_MD = 120;
const MY_BASE = 20;
const MY_MD = 20;

export function useResponsiveMargin({ yAxisTickMaxWidth }: { yAxisTickMaxWidth: number }): Margin {
  const margin = useBreakpointValue<Margin>({
    base: { left: LEFT_BASE, right: RIGHT_BASE / 2, bottom: 0, top: MY_BASE },
    md: { left: Math.min(LEFT_MD, yAxisTickMaxWidth + 16), right: RIGHT_MD, bottom: 0, top: MY_MD },
  }) ?? { left: Math.min(LEFT_MD, yAxisTickMaxWidth + 16), right: RIGHT_MD, bottom: 0, top: MY_MD };
  return margin;
}
