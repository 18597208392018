import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { CommentAttachment } from './CommentAttachment';
import { CommentItem } from './CommentItem';

export const CommentsModule = angular
  .module('comments', [])
  .component(
    'psCommentAttachment',
    reactToAngular(CommentAttachment, [
      'attachment',
      'deletable',
      'onDeleteAttachment',
      'onCancelUpload',
      'createdByUser',
      'userLink',
    ]),
  )
  .component(
    'psCommentItem',
    reactToAngular(CommentItem, [
      'comment',
      'commentContent',
      'commentAuthor',
      'commentCreatedDate',
      'userActivityUrl',
      'embeddedVideos',
      'onDelete',
      'onAnswer',
      'disabled',
      'deletable',
      'answerable',
    ]),
  ).name;
