import * as React from 'react';
import { useTemplateMenuContext, useTemplateMenuDisclosureContext } from '../template-menu';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { ButtonWithUpgradeTooltip } from 'components/button-with-upgrade-tooltip';

export type AddToPublicLibraryButtonProps = {
  children: (props: {
    isPublic: boolean;
  }) => React.ReactElement<{ onClick: React.MouseEventHandler<HTMLButtonElement>; isLoading: boolean }> | null;
};

export const AddToPublicLibraryButton: React.FC<AddToPublicLibraryButtonProps> = React.memo(({ children }) => {
  const { templateId } = useTemplateMenuContext();
  const { addToPublicModalDisclosure: disclosure, publicShareModalDisclosure } = useTemplateMenuDisclosureContext();

  const templateQuery = useGetTemplateQuery({ templateId }, { enabled: Boolean(templateId) });
  const isPublic = !!templateQuery.data?.public;

  if (typeof children !== 'function') {
    throw new Error('`AddToPublicLibraryButton ` must be a render prop function');
  }
  const result = children({ isPublic });
  if (result === null || !React.isValidElement(result) || Array.isArray(result)) {
    throw new Error('The render prop function child must return a single clickable element  or a render prop function');
  }

  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId, { enabled: !!templateId });

  const isPrivate = useIsPrivateTemplate(templateId);
  const canPublicize = permissionsQuery.data?.permissionMap.templateShare && !isPrivate;

  const btn = React.cloneElement(result, {
    onClick: () => {
      if (isPublic) {
        publicShareModalDisclosure.onToggle();
        return;
      }
      disclosure.onToggle();
    },
  });

  const isFree = useIsCurrentUserFreeMember();
  if (isFree) return <ButtonWithUpgradeTooltip>{result}</ButtonWithUpgradeTooltip>;

  if (!canPublicize) return null;

  return <>{btn}</>;
});
