import { FieldType, TaskStatus, TemplateType, tmplTypeNameLower } from '@process-street/subgrade/process';
import { DateUtils, escapeHtml as esc, unescapeHtml as unesc } from '@process-street/subgrade/util';
import angular from 'angular';
import { ObjectType } from 'services/activities/object-type';
import { Verb } from 'services/activities/verb';
import { match } from 'ts-pattern';
import { sprintf } from 'sprintf-js';
import { StringService } from 'services/string-service';
import { trace } from 'components/trace';

angular.module('frontStreetApp.services').service('UserActivityService', function ($q, Activities, ActivityService) {
  const logger = trace({ name: 'UserActivityService' });

  const self = this;

  const abbr = StringService.abbreviate;
  const { valuateData } = ActivityService;
  const spf = sprintf;

  function interpretTag(activity) {
    let sentence = ActivityService.interpret(activity);
    switch (activity.verb) {
      case Verb.CREATED:
        sentence.verb.value = 'created';
        sentence.objects.push({ value: spf('tag "%s"', esc(activity.data.name)) });
        break;
      case Verb.UPDATED:
        sentence.verb.value = 'renamed';
        sentence.objects.push({
          value: spf('tag "%s" to "%s"', esc(activity.data.oldName), esc(activity.data.newName)),
        });
        break;
      case Verb.DELETED:
        sentence.verb.value = 'deleted';
        sentence.objects.push({ value: spf('tag "%s"', esc(activity.data.name)) });
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }
    return sentence;
  }

  function interpretTagMembership(activity) {
    let sentence = ActivityService.interpret(activity);
    switch (activity.verb) {
      case Verb.CREATED:
        sentence.verb.value = 'added';
        sentence.objects.push({
          value: 'a <a>workflow</a>',
          tooltip: activity.data.template.name,
          type: ObjectType.TEMPLATE,
          id: activity.data.template.id,
        });
        sentence.objects.push({ value: spf('to tag "%s"', esc(activity.data.tag.name)) });
        break;
      case Verb.DELETED:
        sentence.verb.value = 'removed';
        sentence.objects.push({
          value: 'a <a>workflow</a>',
          tooltip: activity.data.template.name,
          type: ObjectType.TEMPLATE,
          id: activity.data.template.id,
        });
        sentence.objects.push({ value: spf('from tag "%s"', esc(activity.data.tag.name)) });
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }
    return sentence;
  }

  function interpretFolder(activity) {
    let sentence = ActivityService.interpret(activity);
    const base = {
      type: activity.objectType,
      id: activity.object.id,
    };
    const name = esc(abbr(activity.data.name));
    switch (activity.verb) {
      case Verb.CREATED:
        sentence.verb.value = 'created';
        sentence.objects.push({ value: spf('folder "%s"', esc(activity.data.name)) });
        break;
      case Verb.UPDATED:
        sentence.verb.value = 'renamed';
        sentence.objects.push({
          value: spf('folder "%s" to "%s"', esc(activity.data.oldName), esc(activity.data.newName)),
        });
        break;
      case Verb.DELETED:
        sentence.verb.value = 'deleted';
        sentence.objects.push({ value: spf('folder "%s"', esc(activity.data.name)) });
        break;
      case Verb.ATTACHED:
        sentence.verb.value = 'added';
        sentence.objects.push(angular.extend({}, base, { value: spf('template to folder "%s"', name) }));
        break;
      case Verb.DETACHED:
        sentence.verb.value = 'removed';
        sentence.objects.push(angular.extend({}, base, { value: spf('template from folder "%s"', name) }));
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }
    return sentence;
  }

  function interpretTemplate(activity) {
    let sentence = ActivityService.interpret(activity);
    const template = activity.object;
    const templateTypeName = tmplTypeNameLower(template);
    sentence.contextObjects.push(generateContextObject(template));

    const base = {
      value: `a <a>${templateTypeName}</a>`,
      tooltip: activity.object.name,
      type: activity.objectType,
      id: activity.object.id,
    };
    switch (activity.verb) {
      case Verb.CREATED:
        sentence.verb.value = 'created';
        sentence.objects.push(angular.extend({}, base));
        break;
      case Verb.DELETED:
        sentence.verb.value = 'deleted';
        sentence.objects.push(angular.extend({}, base));
        break;
      case Verb.RESTORED:
        sentence.verb.value = 'restored';
        sentence.objects.push(angular.extend({}, base));
        break;
      case Verb.UPDATED:
        switch (activity.data.status) {
          case 'Active':
            sentence.verb.value = 'unarchived';
            sentence.objects.push(angular.extend({}, base));
            break;
          case 'Archived':
            sentence.verb.value = 'archived';
            sentence.objects.push(angular.extend({}, base));
            break;
          case 'Deleted':
            sentence.verb.value = 'deleted';
            sentence.objects.push(angular.extend({}, base));
            break;
          default:
            if (activity.data.public) {
              sentence.verb.value = 'made';
              sentence.objects.push({
                value: 'a <a>workflow</a> public',
                tooltip: activity.object.name,
                type: ObjectType.TEMPLATE,
                id: activity.object.id,
              });
            } else if (activity.data.newFolder) {
              sentence.verb.value = 'added';
              sentence.objects.push({
                value: 'a <a>workflow</a>',
                tooltip: activity.object.name,
                type: ObjectType.TEMPLATE,
                id: activity.object.id,
              });
              sentence.objects.push({ value: spf('to folder "%s"', esc(activity.data.newFolder.name)) });
            } else if (activity.data.oldFolder) {
              sentence.verb.value = 'removed';
              sentence.objects.push({
                value: 'a <a>workflow</a>',
                tooltip: activity.object.name,
                type: ObjectType.TEMPLATE,
                id: activity.object.id,
              });
              sentence.objects.push({ value: spf('from folder "%s"', esc(activity.data.oldFolder.name)) });
            } else {
              sentence.verb.value = 'changed';
              sentence.objects.push(angular.extend({}, base, { value: 'a <a>workflow</a>' }));
              sentence.objects.push(valuateData(activity.data));
            }
        }
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }

    return sentence;
  }

  function interpretTemplateRevision(activity) {
    let sentence = ActivityService.interpret(activity);
    const { template } = activity.object;
    const templateTypeName = tmplTypeNameLower(template).toLowerCase();
    sentence.contextObjects.push(generateContextObject(template));

    const base = {
      value: `a <a>${templateTypeName}</a>`,
      tooltip: activity.object.template.name,
      type: activity.objectType,
      id: activity.object.id,
    };
    switch (activity.verb) {
      case Verb.CREATED:
        sentence.verb.value = 'started editing';
        sentence.objects.push(angular.extend({}, base));
        break;
      case Verb.UPDATED:
        switch (activity.data.status) {
          case 'Finished':
            sentence.verb.value = 'published changes to';
            sentence.objects.push(angular.extend({}, base));
            break;
          case 'Deleted':
            sentence.verb.value = 'discarded changes to';
            sentence.objects.push(angular.extend({}, base));
            break;
          default:
            logger.error('unrecognized template revision status: %s', activity.data.status);
            sentence = undefined;
        }
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }
    return sentence;
  }

  function interpretChecklistAssignment(activity) {
    let sentence = ActivityService.interpret(activity);

    const template = {
      id: activity.data?.checklist?.template?.id,
      name: activity.data?.checklist?.template?.name || 'Some template',
    };
    sentence.contextObjects.push(generateContextObject(template));

    const checklistId = activity.data?.checklist?.id;
    const checklistName = activity.data?.checklist?.name || 'Some checklist';
    const userId = activity.data?.user?.id;
    const username = activity.data?.user?.username || 'Someone';

    const base = {
      value: spf('<a>%s</a>', esc(abbr(username))),
      tooltip: username,
      type: ObjectType.USER,
      id: userId,
    };
    switch (activity.verb) {
      case Verb.CREATED:
        sentence.verb.value = 'assigned';
        sentence.objects.push(angular.extend({}, base));
        sentence.objects.push({
          value: spf('to a <a>workflow run</a> "%s"', esc(abbr(checklistName))),
          tooltip: checklistName,
          type: ObjectType.CHECKLIST,
          id: checklistId,
        });
        break;
      case Verb.DELETED:
        sentence.verb.value = 'unassigned';
        sentence.objects.push(angular.extend({}, base));
        sentence.objects.push({
          value: spf('from a <a>workflow run</a> "%s"', esc(abbr(checklistName))),
          tooltip: checklistName,
          type: ObjectType.CHECKLIST,
          id: checklistId,
        });
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }
    return sentence;
  }

  function interpretChecklist(activity) {
    let sentence = ActivityService.interpret(activity);
    const { template } = activity.object;
    sentence.contextObjects.push(generateContextObject(template));

    const base = {
      value: spf('a <a>workflow run</a> "%s"', esc(abbr(activity.object.name))),
      tooltip: activity.object.name,
      type: activity.objectType,
      id: activity.object.id,
    };
    switch (activity.verb) {
      case Verb.CREATED:
        sentence.verb.value = 'started';
        sentence.objects.push(angular.extend({}, base));
        break;
      case Verb.DELETED:
        sentence.verb.value = 'deleted';
        sentence.objects.push(angular.extend({}, base));
        break;
      case Verb.RESTORED:
        sentence.verb.value = 'restored';
        sentence.objects.push(angular.extend({}, base));
        break;
      case Verb.UPDATED:
        switch (activity.data.status) {
          case 'Active':
            sentence.verb.value = 'unarchived';
            sentence.objects.push(angular.extend({}, base));
            break;
          case 'Archived':
            sentence.verb.value = 'archived';
            sentence.objects.push(angular.extend({}, base));
            break;
          case 'Deleted':
            sentence.verb.value = 'deleted';
            sentence.objects.push(angular.extend({}, base));
            break;
          default:
            sentence.verb.value = 'changed';
            sentence.objects.push(angular.extend({}, base, { value: 'a <a>workflow run</a>' }));
            sentence.objects.push(valuateData(activity.data));
        }
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }
    return sentence;
  }

  function interpretChecklistRevision(activity) {
    let sentence = ActivityService.interpret(activity);
    const { template } = activity.object.checklist;
    sentence.contextObjects.push(generateContextObject(template));

    const base = {
      value: spf('a <a>workflow run</a> "%s"', esc(abbr(activity.object.checklist.name))),
      tooltip: activity.object.checklist.name,
      type: ObjectType.CHECKLIST,
      id: activity.object.checklist.id,
    };
    switch (activity.verb) {
      case Verb.UPDATED:
        switch (activity.data.status) {
          case 'Migrated':
            sentence.verb.value = 'updated';
            sentence.objects.push(angular.extend({}, base, { value: `${base.value} to the latest version` }));
            break;
          default:
            sentence.verb.value = 'changed';
            sentence.objects.push(angular.extend({}, base, { value: 'a <a>workflow run</a>' }));
            sentence.objects.push(valuateData(activity.data));
        }
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }
    return sentence;
  }

  function interpretTasks(activity) {
    let sentence = ActivityService.interpret(activity);
    const { template } = activity.object.checklist;
    sentence.contextObjects.push(generateContextObject(template));

    const base = {
      value: spf('in <a>workflow run</a> "%s"', esc(abbr(activity.object.checklist.name))),
      tooltip: activity.object.checklist.name,
      type: activity.objectType,
      id: activity.object.id,
    };
    switch (activity.verb) {
      case Verb.UPDATED:
        switch (activity.data.status) {
          case TaskStatus.Completed:
            sentence.verb.value = 'checked all';
            sentence.objects.push(angular.extend({}, base));
            break;
          case TaskStatus.NotCompleted:
            sentence.verb.value = 'unchecked all';
            sentence.objects.push(angular.extend({}, base));
            break;
          default:
            logger.error('unrecognized task status: %s', activity.data.status);
            sentence = undefined;
        }
        break;
      case Verb.APPROVED:
        sentence.verb.value = 'approved';
        sentence.objects.push(
          angular.extend(base, {
            value: spf('a <a>task</a> "%s"', esc(abbr(activity.object.taskTemplate.name))),
          }),
        );
        break;
      case Verb.REJECTED:
        sentence.verb.value = 'rejected';
        sentence.objects.push(
          angular.extend(base, {
            value: spf('a <a>task</a> "%s"', esc(abbr(activity.object.taskTemplate.name))),
          }),
        );
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }
    return sentence;
  }

  function interpretTask(activity) {
    const checklist = activity.object.checklistRevision?.checklist;
    const templateId = activity.data.templateId ?? checklist.template.id;
    const templateName = activity.data.templateName ?? checklist.template.name;
    const checklistName = activity.data.checklistName ?? checklist.name;
    const taskTemplateName = activity.data.taskTemplateName ?? activity.object.taskTemplate.name;

    const isTask = activity.data.templateType === TemplateType.Task;
    const taskName = isTask ? checklistName : taskTemplateName;

    let sentence = ActivityService.interpret(activity);
    if (!isTask) {
      sentence.contextObjects.push(generateContextObject({ id: templateId, name: templateName }));
    }

    const base = {
      tooltip: taskTemplateName,
      type: activity.objectType,
      id: activity.object.id,
    };
    match(activity)
      .with({ verb: Verb.UPDATED, data: { activityType: 'UpdateDueDate' } }, () => {
        const dueDate = activity.data.dueDate && DateUtils.formatActivityTime({ date: activity.data.dueDate });
        sentence.verb.value = dueDate ? `set the due date to ${dueDate}` : 'removed the due date';

        sentence.objects.push(
          angular.extend({}, base, {
            value: spf('in <a>task</a> "%s"', esc(abbr(taskName))),
          }),
        );

        if (!isTask) {
          sentence.objects.push(
            angular.extend({}, base, {
              value: spf('in <a>workflow run</a> "%s"', esc(abbr(checklistName))),
              tooltip: checklistName,
            }),
          );
        }
      })
      .with({ verb: Verb.UPDATED }, () => {
        switch (activity.data.status) {
          case TaskStatus.Completed:
            sentence.verb.value = 'checked';
            sentence.objects.push(
              angular.extend({}, base, {
                value: spf('a <a>task</a> "%s"', esc(abbr(taskName))),
              }),
            );
            if (!isTask) {
              sentence.objects.push(
                angular.extend({}, base, {
                  value: spf('in <a>workflow run</a> "%s"', esc(abbr(checklistName))),
                  tooltip: checklistName,
                }),
              );
            }
            break;
          case TaskStatus.NotCompleted:
            sentence.verb.value = 'unchecked';
            sentence.objects.push(
              angular.extend(base, {
                value: spf('a <a>task</a> "%s"', esc(abbr(taskTemplateName))),
              }),
            );
            sentence.objects.push(
              angular.extend({}, base, {
                value: spf('in <a>workflow run</a> "%s"', esc(abbr(checklistName))),
                tooltip: checklistName,
              }),
            );
            break;
          default:
            logger.error('unrecognized task status: %s', activity.data.status);
            sentence = undefined;
        }
      })
      .with({ verb: Verb.APPROVED }, () => {
        sentence.verb.value = 'approved';
        sentence.objects.push(
          angular.extend(base, {
            value: spf('a <a>task</a> "%s"', esc(abbr(taskTemplateName))),
            tooltip: taskTemplateName,
          }),
        );
        sentence.objects.push(
          angular.extend({}, base, {
            value: spf('in <a>workflow run</a> "%s"', esc(abbr(checklistName))),
            tooltip: checklistName,
          }),
        );
      })
      .with({ verb: Verb.REJECTED }, () => {
        sentence.verb.value = 'rejected';
        sentence.objects.push(
          angular.extend(base, {
            value: spf('a <a>task</a> "%s"', esc(abbr(taskTemplateName))),
            tooltip: taskTemplateName,
          }),
        );
        sentence.objects.push(
          angular.extend({}, base, {
            value: spf('in <a>workflow run</a> "%s"', esc(abbr(checklistName))),
            tooltip: checklistName,
          }),
        );
        if (activity.data?.comment) {
          sentence.objects.push({
            value: spf('saying "%s"', esc(abbr(unesc(activity.data.comment)))),
            tooltip: activity.data.comment,
          });
        }
      })
      .otherwise(() => {
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
      });
    return sentence;
  }

  function interpretComment(activity, extended) {
    const checklist = activity.object.task?.checklistRevision?.checklist;
    const templateId = activity.data.templateId ?? checklist.template.id;
    const templateName = activity.data.templateName ?? checklist.template.name;
    const checklistName = activity.data.checklistName ?? checklist.name;
    const taskTemplateName = activity.data.taskTemplateName ?? activity.object.task.taskTemplate.name;

    const isTask = activity.data.templateType === TemplateType.Task;
    const taskName = isTask ? checklistName : taskTemplateName;

    let sentence = ActivityService.interpret(activity);
    if (!isTask) {
      sentence.contextObjects.push(generateContextObject({ id: templateId, name: templateName }));
    }

    const base = {
      type: activity.objectType,
      id: activity.object.id,
    };
    switch (activity.verb) {
      case Verb.CREATED:
        sentence.verb.value = 'posted';
        sentence.objects.push(angular.extend({}, base, { value: 'a <a>comment</a>' }));
        sentence.objects.push(
          angular.extend({}, base, {
            value: spf('to <a>task</a> "%s"', esc(abbr(taskName))),
            tooltip: taskName,
          }),
        );
        if (!isTask) {
          sentence.objects.push(
            angular.extend({}, base, {
              value: spf('in <a>workflow run</a> "%s"', esc(abbr(checklistName))),
              tooltip: checklistName,
            }),
          );
        }
        if (extended) {
          sentence.quote = activity.data.content;
        } else {
          sentence.objects.push({
            value: spf('saying "%s"', esc(abbr(unesc(activity.data.content)))),
            tooltip: activity.data.content,
          });
        }
        break;
      case Verb.UPDATED:
        sentence.verb.value = 'deleted';
        sentence.objects.push(
          angular.extend({}, base, {
            value: spf('a comment from <a>task</a> "%s"', esc(abbr(taskName))),
            tooltip: taskName,
          }),
        );
        if (!isTask) {
          sentence.objects.push(
            angular.extend({}, base, {
              value: spf('in <a>workflow run</a> "%s"', esc(abbr(checklistName))),
              tooltip: checklistName,
            }),
          );
        }
        sentence.objects.push({
          value: spf('saying "%s"', esc(abbr(unesc(activity.data.content)))),
          tooltip: activity.data.content,
        });
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }
    return sentence;
  }

  function interpretAttachment(activity, extended) {
    const checklist = activity.object.task?.checklistRevision?.checklist;
    const templateId = activity.data.templateId ?? checklist.template.id;
    const templateName = activity.data.templateName ?? checklist.template.name;
    const checklistName = activity.data.checklistName ?? checklist.name;
    const taskTemplateName = activity.data.taskTemplateName ?? activity.object.task.taskTemplate.name;
    const originalName = activity.data.fileOriginalName ?? activity.object.file.originalName;

    let sentence = ActivityService.interpret(activity);
    sentence.contextObjects.push(generateContextObject({ id: templateId, name: templateName }));

    const base = {
      type: activity.objectType,
      id: activity.object.id,
    };
    switch (activity.verb) {
      case Verb.CREATED:
        sentence.verb.value = 'attached';
        sentence.objects.push(
          angular.extend({}, base, {
            value: spf('a file to <a>task</a> "%s"', esc(abbr(taskTemplateName))),
            tooltip: taskTemplateName,
          }),
        );
        sentence.objects.push(
          angular.extend({}, base, {
            value: spf('in <a>workflow run</a> "%s"', esc(abbr(checklistName))),
            tooltip: checklistName,
          }),
        );
        sentence.objects.push({
          value: spf('named "%s"', esc(abbr(unesc(originalName)))),
          tooltip: originalName,
        });
        if (extended) {
          sentence.file = activity.object.file;
        }
        break;
      case Verb.UPDATED:
        sentence.verb.value = 'deleted';
        sentence.objects.push({
          value: spf('an attachment from <a>task</a> "%s"', esc(abbr(taskTemplateName))),
          tooltip: taskTemplateName,
        });
        sentence.objects.push(
          angular.extend({}, base, {
            value: spf('in <a>workflow run</a> "%s"', esc(abbr(checklistName))),
            tooltip: checklistName,
          }),
        );
        sentence.objects.push({
          value: spf('named "%s"', esc(abbr(unesc(originalName)))),
          tooltip: originalName,
        });
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }
    return sentence;
  }

  function interpretPermit(activity) {
    let sentence = ActivityService.interpret(activity);
    const { user, level, template } = activity.data;
    sentence.contextObjects.push(generateContextObject(template));

    let base;
    if (template) {
      base = {
        value: 'a <a>workflow</a>',
        tooltip: template.name,
        type: ObjectType.TEMPLATE,
        id: template.id,
      };
    }

    switch (activity.verb) {
      case Verb.CREATED:
        sentence.verb.value = 'added';
        sentence.objects.push({
          value: spf('<a>%s</a> to', esc(abbr(user.username))),
          tooltip: user.username,
          type: ObjectType.USER,
          id: user.id,
        });
        sentence.objects.push(angular.extend({}, base));
        sentence.objects.push({
          value: spf('with access level <b>%s</b>', level || '???'),
        });
        break;
      case Verb.UPDATED:
        sentence.verb.value = 'set access level of';
        sentence.objects.push({
          value: spf('<a>%s</a> on', esc(abbr(user.username))),
          tooltip: user.username,
          type: ObjectType.USER,
          id: user.id,
        });
        sentence.objects.push(angular.extend({}, base));
        sentence.objects.push({
          value: spf('to <b>%s</b>', level || '???'),
        });
        break;
      case Verb.DELETED:
        sentence.verb.value = 'removed';
        sentence.objects.push({
          value: spf('<a>%s</a> from', esc(abbr(user.username))),
          tooltip: user.username,
          type: ObjectType.USER,
          id: user.id,
        });
        sentence.objects.push(angular.extend({}, base));
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }

    return sentence;
  }

  function interpretFormFieldValue(activity) {
    return match(activity.data.fieldType)
      .with(FieldType.SendRichEmail, () => interpretSendRichEmailFormFieldValue(activity))
      .with(FieldType.MultiSelect, () => interpretMultiSelectFormFieldValue(activity))
      .otherwise(() => {
        throw new Error(`Field Type type [${activity.data.fieldType}] is not supported`);
      });
  }

  function interpretMultiSelectFormFieldValue(activity) {
    const itemsUpdated = activity?.data?.itemsUpdated;
    if (!itemsUpdated) {
      logger.error('form field activity does not have an itemsUpdated object');
      return undefined;
    }

    const { taskTemplate, checklist, template } = activity.data;

    let sentence = ActivityService.interpret(activity);
    sentence.contextObjects.push(generateContextObject(template));

    const base = {
      type: 'ChecklistTask',
      id: `${checklist.id}+${taskTemplate.group.id}`,
    };

    function generateObject(item, niceStatus, conjoined) {
      return angular.extend({}, base, {
        tooltip: item.name,
        value: spf('<a>subtask</a> "%s" as %s%s', esc(abbr(item.name)), niceStatus, conjoined ? ',' : ''),
      });
    }

    switch (activity.verb) {
      case Verb.UPDATED:
        sentence.verb.value = 'marked';

        // If there are no items updated, then make the sentence undefined to skip it
        if (!itemsUpdated.length) {
          sentence = undefined;
        }

        itemsUpdated.forEach((item, i) => {
          // If the sentence has been set to undefined, skip the rest of the iterations
          if (!sentence) {
            return;
          }

          const itemIsLast = itemsUpdated.length - 1 === i;

          switch (item.newData.status) {
            case TaskStatus.Completed:
              sentence.objects.push(generateObject(item, 'completed', !itemIsLast));

              break;

            case TaskStatus.NotCompleted:
              sentence.objects.push(generateObject(item, 'not completed', !itemIsLast));

              break;

            default:
              logger.error('unrecognized task status: %s', item.newData.status);
              sentence = undefined;
          }
        });

        if (sentence) {
          sentence.objects.push(
            angular.extend({}, base, {
              value: spf('in <a>task</a> "%s"', esc(abbr(taskTemplate.name))),
            }),
          );
          sentence.objects.push(
            angular.extend({}, base, {
              value: spf('of <a>workflow run</a> "%s"', esc(abbr(checklist.name))),
              tooltip: checklist.name,
            }),
          );
        }

        break;

      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }

    return sentence;
  }

  function interpretSendRichEmailFormFieldValue(activity) {
    const emailWasSent = activity?.data?.sentEmail;
    if (!emailWasSent) {
      logger.error('form field activity does not have an semtEmail object');
      return undefined;
    }

    const { checklistId, taskTemplate, subject } = activity.data;

    const sentence = ActivityService.interpret(activity);

    const quotedSubject = subject ? ` "${esc(abbr(subject))}" ` : ' ';

    const sentenceObject = {
      type: 'ChecklistTask',
      id: `${checklistId}+${taskTemplate.group.id}`,
      tooltip: subject,
      value: spf('<a>an email</a>%sin <a>task</a> "%s"', quotedSubject, esc(abbr(taskTemplate.name))),
    };

    sentence.verb.value = 'sent';
    sentence.objects.push(sentenceObject);

    return sentence;
  }

  function interpretOrganizationMembership(activity) {
    let sentence = ActivityService.interpret(activity);

    const base = {
      value: `<a>${esc(activity.data.user.username)}</a>`,
      tooltip: activity.data.user.username,
      type: ObjectType.USER,
      id: activity.data.user.id,
    };

    const organization = {
      value: `<a>${esc(activity.data.organization.name)}</a>`,
      tooltip: activity.data.organization.name,
      type: ObjectType.ORGANIZATION_MEMBERSHIP,
      id: activity.organization.id,
    };

    switch (activity.verb) {
      case Verb.CREATED:
        sentence.verb.value = 'added';
        base.value += ' to';
        sentence.objects.push(angular.extend({}, base));
        sentence.objects.push(angular.extend({}, organization));
        break;
      case Verb.UPDATED:
        sentence.verb.value = 'set the access level of';
        sentence.objects.push(angular.extend({}, base));
        sentence.objects.push({
          value: `to ${activity.data.updatedLevel} in`,
        });
        sentence.objects.push(angular.extend({}, organization));
        break;
      case Verb.DELETED:
        sentence.verb.value = 'removed';
        base.value += ' from';
        sentence.objects.push(angular.extend({}, base));
        sentence.objects.push(angular.extend({}, organization));
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }

    return sentence;
  }

  function generateContextObject({ id, name }) {
    return {
      value: spf('<a>%s</a>', esc(abbr(name, 30))),
      tooltip: name,
      type: ObjectType.TEMPLATE,
      id,
    };
  }

  function interpretChecklistTaskAssignment(activity) {
    let sentence = ActivityService.interpret(activity);
    const { data } = activity;
    const isTask = data.template?.templateType === TemplateType.Task;
    const taskName = isTask ? data.checklist.name : data.taskTemplate.name;

    switch (activity.verb) {
      case Verb.CREATED:
        sentence.verb.value = 'assigned';
        sentence.objects.push({
          value: spf('<a>task</a> "%s"', esc(abbr(taskName))),
          tooltip: taskName,
          type: 'Task',
          id: data.task.id,
        });
        sentence.objects.push({
          value: spf('to <a>%s</a>', esc(abbr(data.assignee.username))),
          tooltip: data.assignee.username,
          type: 'User',
          id: data.assignee.id,
        });
        if (!isTask) {
          sentence.objects.push({
            value: spf('in <a>workflow run</a> "%s"', esc(abbr(data.checklist.name))),
            tooltip: data.checklist.name,
            type: 'Checklist',
            id: data.checklist.id,
          });
        }
        break;
      case Verb.DELETED:
        sentence.verb.value = 'unassigned';
        sentence.objects.push({
          value: spf('<a>%s</a>', esc(abbr(data.assignee.username))),
          tooltip: data.assignee.username,
          type: 'User',
          id: data.assignee.id,
        });
        sentence.objects.push({
          value: spf('from <a>task</a> "%s"', esc(abbr(taskName))),
          tooltip: taskName,
          type: 'Task',
          id: data.task.id,
        });
        if (!isTask) {
          sentence.objects.push({
            value: spf('in <a>workflow run</a> "%s"', esc(abbr(data.checklist.name))),
            tooltip: data.checklist.name,
            type: 'Checklist',
            id: data.checklist.id,
          });
        }
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }
    return sentence;
  }

  function interpretManyChecklistTaskAssignments(activity) {
    let sentence = ActivityService.interpret(activity);
    const { data } = activity;

    const assignmentsTooltip = activity.data.assignments
      .map(a => spf('%s to "%s"', a.assignee.username, abbr(a.taskTemplate.name)))
      .join(', ');

    switch (activity.verb) {
      case Verb.CREATED:
        sentence.verb.value = 'assigned';
        sentence.objects.push({
          value: 'one or more tasks',
          tooltip: assignmentsTooltip,
        });
        sentence.objects.push({
          value: spf('in <a>workflow run</a> "%s"', esc(abbr(data.checklist.name))),
          tooltip: data.checklist.name,
          type: 'Checklist',
          id: data.checklist.id,
        });
        // This might be the creator, so the assignee won't exist
        if (data.assignee) {
          sentence.objects.push({
            value: spf('to <a>%s</a>', esc(abbr(data.assignee.username))),
            tooltip: data.assignee.username,
            type: 'User',
            id: data.assignee.id,
          });
        }
        break;
      default:
        logger.error('unrecognized verb: %s', activity.verb);
        sentence = undefined;
    }
    return sentence;
  }

  function interpret(activity, extended) {
    let sentence;
    try {
      switch (activity.objectType) {
        case ObjectType.TAG:
          sentence = interpretTag(activity);
          break;
        case ObjectType.TAG_MEMBERSHIP:
          sentence = interpretTagMembership(activity);
          break;
        case ObjectType.FOLDER:
          sentence = interpretFolder(activity);
          break;
        case ObjectType.TEMPLATE:
          sentence = interpretTemplate(activity);
          break;
        case ObjectType.TEMPLATE_REVISION:
          sentence = interpretTemplateRevision(activity);
          break;
        case ObjectType.CHECKLIST_ASSIGNMENT:
          sentence = interpretChecklistAssignment(activity);
          break;
        case ObjectType.CHECKLIST:
          sentence = interpretChecklist(activity);
          break;
        case ObjectType.CHECKLIST_REVISION:
          sentence = interpretChecklistRevision(activity);
          break;
        case ObjectType.TASKS:
          sentence = interpretTasks(activity);
          break;
        case ObjectType.TASK:
          sentence = interpretTask(activity);
          break;
        case ObjectType.COMMENT:
          sentence = interpretComment(activity, extended);
          break;
        case ObjectType.ATTACHMENT:
          sentence = interpretAttachment(activity, extended);
          break;
        case ObjectType.TEMPLATE_PERMIT:
          sentence = interpretPermit(activity);
          break;
        case ObjectType.FORM_FIELD_VALUE:
          sentence = interpretFormFieldValue(activity);
          break;
        case ObjectType.ORGANIZATION_MEMBERSHIP:
          sentence = interpretOrganizationMembership(activity);
          break;
        case ObjectType.CHECKLIST_TASK_ASSIGNMENT:
          sentence = interpretChecklistTaskAssignment(activity);
          break;
        case ObjectType.MANY_CHECKLIST_TASK_ASSIGNMENTS:
          sentence = interpretManyChecklistTaskAssignments(activity);
          break;
        default:
          logger.error('unrecognized object type: %s', activity.objectType);
      }
    } catch (e) {
      logger.error('an error occurred interpreting the activity data', e);
    }
    return sentence;
  }

  self.interpretSimple = function (activity) {
    return interpret(activity, false);
  };

  self.interpretExtended = function (activity) {
    return interpret(activity, true);
  };

  self.getActivitiesByIdOrganizationIdLimitAndOffsetId = function (userId, organizationId, limit, offsetId) {
    return Activities.getAll({
      type: 'user',
      userId,
      organizationId,
      limit,
      offsetId,
    }).$promise.then(
      result => {
        logger.info('succeeded to query %d user activity(s)', result.data.length);
        return result;
      },
      response => {
        logger.error('failed to query user activities. Reason: %s', JSON.stringify(response));
        return $q.reject(response);
      },
    );
  };
});
