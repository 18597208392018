import { IncomingWebhookChecklistConfigKey } from '@process-street/subgrade/automation';
import { Muid } from '@process-street/subgrade/core';
import { DataSetColumnDef, FormFieldWidget } from '@process-street/subgrade/process';
import { SelectorHelper } from 'directives/rules/template/task-templates-selector/selector-helper';
import { getReadablePath } from './get-readable-path';

export type SelectOption = { label: string; value: string } & (
  | { type: 'checklist' }
  | {
      type: 'formField';
      taskTemplateId: Muid;
      templateRevisionId: Muid;
      value: FormFieldWidget['header']['group']['id'];
    }
  | { type: 'path'; isInvalid: boolean }
  | { type: 'columnDefinition' }
);
export type PathOption = Extract<SelectOption, { type: 'path' }>;
export type ChecklistOrFormFieldOrColumnDefinitionOption = Extract<
  SelectOption,
  { type: 'checklist' } | { type: 'formField' } | { type: 'columnDefinition' }
>;
export type KeyColumnOption = { label: string; value: string };

export function pathToPathOption(
  path: string,
  { isInvalid = false }: Partial<{ isInvalid: boolean }> = {},
): PathOption {
  const label = getReadablePath(path);
  return { value: path, label, type: 'path', isInvalid };
}

const CHECKLIST_PROPERTY_LABEL_MAP: Record<IncomingWebhookChecklistConfigKey, string> = {
  DueDate: 'Workflow Run due date',
  Assignees: 'Workflow Run assignees',
  Name: 'Workflow Run name',
  Shared: 'Workflow Run shared state',
};

export function checklistPropertyToChecklistOption(
  property: IncomingWebhookChecklistConfigKey,
): ChecklistOrFormFieldOrColumnDefinitionOption {
  return { value: property, label: CHECKLIST_PROPERTY_LABEL_MAP[property], type: 'checklist' };
}

export function formFieldWidgetToFormFieldOption(
  formField: FormFieldWidget,
): ChecklistOrFormFieldOrColumnDefinitionOption {
  return {
    value: formField.header.group.id,
    label: SelectorHelper.getLabelForWidget(formField),
    type: 'formField',
    taskTemplateId: formField.header.taskTemplate.id,
    templateRevisionId: formField.templateRevision.id,
  };
}

export function dataSetColumnToOption(
  columnDefinition: DataSetColumnDef,
): ChecklistOrFormFieldOrColumnDefinitionOption {
  return {
    value: columnDefinition.id,
    label: columnDefinition.name,
    type: 'columnDefinition',
  };
}
