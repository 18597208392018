import angular from 'angular';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.services')
  .service(
    'SyncService',
    function (
      $ngRedux,
      $q,
      $timeout,
      ChecklistAssignmentService,
      GroupMembershipService,
      SessionService,
      UserSettingsService,
      UserService,
    ) {
      const logger = trace({ name: 'SyncService' });

      const self = this;

      // Pull

      self.pullGroupMemberships = function (userId) {
        return GroupMembershipService.queryGroupMembershipByUser(userId).then(({ payload }) => payload);
      };

      self.pullFolderChecklistAssignments = function (folderId, checklistStatus, templateStatus) {
        return ChecklistAssignmentService.getAllByOrganizationId({
          organizationId: SessionService.getSelectedOrganizationId(),
          folderId,
          templateStatus,
          checklistStatus,
        }).then(
          assignments => {
            logger.info('succeeded to retrieve %d folder checklist assignment(s)', assignments.length);
            return assignments;
          },
          response => {
            logger.error('failed to retrieve folder checklist assignment(s). Reason: %s', JSON.stringify(response));
            return $q.reject(response);
          },
        );
      };

      self.pullChecklistAssignments = function (checklistId, checklistStatus) {
        return ChecklistAssignmentService.getAllByOrganizationId({
          organizationId: SessionService.getSelectedOrganizationId(),
          checklistId,
          checklistStatus,
        }).then(
          assignments => {
            logger.info('succeeded to retrieve %d checklist assignment(s)', assignments.length);
            return assignments;
          },
          response => {
            logger.error('failed to retrieve checklist assignment(s). Reason: %s', JSON.stringify(response));
            return $q.reject(response);
          },
        );
      };

      self.pullUserSettings = function () {
        const sessionUser = UserService.getCurrentUser();
        return UserService.getSettingsById(sessionUser.id);
      };

      // Push

      self.pushOrganizationUseFrequencyMap = function () {
        logger.info('pushing organization use frequency update');

        UserSettingsService.updateOrganizationUseFrequencyMap(SessionService.getOrganizationUseFrequencyMap());
      };

      let previousDashboardPropertiesPushOffset = Date.now();
      let previousDashboardPropertiesPushTimeout = null;

      self.pushDashboardProperties = function () {
        if (previousDashboardPropertiesPushTimeout) {
          logger.info('canceling dashboard properties update push');
          $timeout.cancel(previousDashboardPropertiesPushTimeout);
        }

        const now = Date.now();
        const delay = 5000 - Math.min(now - previousDashboardPropertiesPushOffset, 5000);
        previousDashboardPropertiesPushTimeout = $timeout(() => {
          logger.info('pushing dashboard properties update');

          previousDashboardPropertiesPushOffset = Date.now();
          previousDashboardPropertiesPushTimeout = null;
        }, delay);
      };

      let previousInboxPropertiesPushOffset = Date.now();
      let previousInboxPropertiesPushTimeout = null;

      self.pushInboxProperties = function () {
        if (previousInboxPropertiesPushTimeout) {
          logger.info('canceling dashboard properties update push');
          $timeout.cancel(previousInboxPropertiesPushTimeout);
        }

        const now = Date.now();
        const delay = 5000 - Math.min(now - previousInboxPropertiesPushOffset, 5000);
        previousInboxPropertiesPushTimeout = $timeout(() => {
          logger.info('pushing inbox properties update');

          previousDashboardPropertiesPushOffset = Date.now();
          previousInboxPropertiesPushOffset = null;

          UserSettingsService.updateInbox(SessionService.getInboxProperties());
        }, delay);
      };
    },
  );
