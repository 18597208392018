import * as React from 'react';
import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from 'components/design/next';

export type DeleteConfirmationModalProps = {
  isOpen: boolean;
  title: React.ReactNode;
  content: React.ReactNode;
  isLoading: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export const DeleteConfirmationModal: React.FC<React.PropsWithChildren<DeleteConfirmationModalProps>> = ({
  isOpen,
  isLoading,
  title,
  content,
  onClose,
  onDelete,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent mt="20">
        <ModalHeader border="0" borderBottomWidth="1px" borderStyle="solid" borderColor="gray.200">
          <HStack spacing="2">
            <Icon icon="cog" size="4" color="gray.400" />

            <Text size="xl" fontWeight="700" color="gray.700">
              {title}
            </Text>
          </HStack>
        </ModalHeader>

        <ModalBody pt="6" pb="4">
          {content}
        </ModalBody>

        <ModalFooter as={HStack} spacing={2}>
          <Button isDisabled={isLoading} onClick={onClose} variant="solid" colorScheme="gray">
            Cancel
          </Button>

          <Button isLoading={isLoading} loadingText="Deleting..." variant="solid" colorScheme="red" onClick={onDelete}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
