import { Muid } from '@process-street/subgrade/core';
import { TaskTemplate, TaskTemplatesDeleteResponse, Widget } from '@process-street/subgrade/process';
import {
  DeleteTaskTemplatesMutation,
  TaskTemplatesByTemplateRevisionIdQuery,
  TaskTemplatesByTemplateRevisionIdQueryResponse,
  UpdateTaskTemplateMutation,
} from 'features/task-templates/query-builder';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { createMachine, sendParent } from 'xstate';
import { SharedContext } from '../../shared';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { DeleteWidgetMutation, WidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { GetAllNativeAutomationsQuery } from 'app/features/native-automations/query-builder';

type ForwardableEvent =
  | { type: 'CONFIRM_DELETE_TASK_TEMPLATE'; taskTemplateId: Muid }
  | { type: 'CANCEL_DELETE_TASK_TEMPLATE'; taskTemplateId: Muid };
export type { ForwardableEvent as DeleteTaskTemplateMachineForwardableEvent };

type ParentEvent =
  | ForwardableEvent
  | { type: 'PROMPT_TASK_TEMPLATE_DELETE' }
  | { type: 'DELETING_TASK_TEMPLATE'; taskTemplateId: Muid };
export type { ParentEvent as DeleteTaskTemplateMachineParentEvent };

type Event = ForwardableEvent | { type: 'done.invoke.deleteTaskTemplate'; data: Array<TaskTemplatesDeleteResponse> };

interface Context {}

export const makeDeleteTaskTemplateMachine = ({
  taskTemplateId,
  templateRevisionId,
  sharedContext,
  widgets,
}: {
  sharedContext: SharedContext;
  taskTemplateId: Muid;
  templateRevisionId: Muid;
  widgets: Widget[];
}) => {
  const { queryClient, templateId } = sharedContext;

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  const id = 'delete-task-template';

  return createMachine(
    {
      id,
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      tsTypes: {} as import('./delete-task-template-machine.typegen').Typegen0,

      initial: 'checkWidgets',
      states: {
        checkWidgets: {
          always: [
            { cond: 'hasOnlyOneTaskAndHasNoWidgets', target: 'cleanup' },
            { cond: 'taskHasNoWidgets', target: 'deleting' },
            { target: 'prompt' },
          ],
        },
        checkTasks: {
          always: [{ cond: 'hasOnlyOneTask', target: 'cleanup' }, { target: 'checkWidgets' }],
        },
        prompt: {
          entry: ['sendParentPrompt'],
          on: {
            CONFIRM_DELETE_TASK_TEMPLATE: [{ cond: 'hasOnlyOneTask', target: 'cleanup' }, { target: 'deleting' }],
            CANCEL_DELETE_TASK_TEMPLATE: 'done',
          },
        },
        cleanup: {
          invoke: [
            {
              id: 'cleanupTaskTemplate',
              src: 'cleanupTaskTemplateMutation',
              onDone: { target: 'done' },
              onError: { target: 'done', actions: 'logError' },
            },
          ],
        },
        deleting: {
          entry: ['sendParentDeleting'],
          invoke: [
            {
              id: 'deleteTaskTemplate',
              src: 'deleteTaskTemplateMutation',
              onDone: { target: 'done' },
              onError: { target: 'done', actions: 'logError' },
            },
          ],
        },
        done: { type: 'final', data: () => taskTemplateId },
      },
    },
    {
      actions: {
        logError: makeErrorLoggerAction(id),
        sendParentPrompt: sendParent('PROMPT_TASK_TEMPLATE_DELETE'),
        sendParentDeleting: sendParent(() => ({ type: 'DELETING_TASK_TEMPLATE', taskTemplateId })),
      },
      services: {
        cleanupTaskTemplateMutation: async (_ctx, _evt) => {
          // if is the only step, delete all widgets and clear step name
          const taskTemplates =
            queryClient.getQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
              TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
            ) ?? [];
          const [taskTemplate] = taskTemplates;

          const widgetHeaderIds = widgets
            .filter(widget => widget.header.taskTemplate.id === taskTemplate.id)
            .map(widget => widget.header.id);

          const deleteWidgetsPromise = widgetHeaderIds.map(headerId => DeleteWidgetMutation.mutationFn({ headerId }));
          const updateTaskNamePromise = UpdateTaskTemplateMutation.mutationFn({
            taskTemplateId: taskTemplate.id,
            taskTemplate: { name: undefined },
          });

          const promises = [...deleteWidgetsPromise, updateTaskNamePromise];

          try {
            await Promise.all(promises);
            await Promise.all([
              queryClient.invalidateQueries(WidgetsByTemplateRevisionIdQuery.getKey(templateRevisionId)),
              queryClient.invalidateQueries(TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId })),
            ]);
            queryClient.setQueryData<Array<TaskTemplate>>(
              TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId: taskTemplate.templateRevision.id }),
              () => {
                return [{ ...taskTemplate, name: undefined }];
              },
            );
          } catch (e) {
            ToastServiceImpl.openToast({
              status: 'error',
              title: "We're having problems deleting that step",
            });
            queryClient.setQueryData<Array<TaskTemplate>>(
              TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId: taskTemplate.templateRevision.id }),
              () => {
                return [taskTemplate];
              },
            );
          }
        },
        deleteTaskTemplateMutation: async (_ctx, _evt) => {
          return makeMutation(queryClient, {
            mutationFn: () =>
              DeleteTaskTemplatesMutation.mutationFn({
                taskTemplatesIds: [taskTemplateId],
              }),
            onMutate: () => {
              const tasksBefore = queryClient.getQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
              );

              queryClient.setQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
                current => {
                  return current?.filter(taskTemplate => taskTemplate.id !== taskTemplateId) || [];
                },
              );

              return { tasksBefore };
            },
            onSuccess: () => {
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
              queryClient.invalidateQueries(GetAllNativeAutomationsQuery.getKey({ templateRevisionId }));
            },
            onError: (_err, _vars, mutationCtx) => {
              queryClient.setQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
                () => mutationCtx?.tasksBefore ?? [],
              );
              ToastServiceImpl.openToast({
                status: 'error',
                title: "We're having problems deleting that step",
              });
            },
          }).execute();
        },
      },
      guards: {
        taskHasNoWidgets: () => widgets.length === 0,
        hasOnlyOneTask: () => {
          const taskTemplates =
            queryClient.getQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
              TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
            ) ?? [];

          return taskTemplates.length === 1;
        },
        hasOnlyOneTaskAndHasNoWidgets: () => {
          const taskTemplates =
            queryClient.getQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
              TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
            ) ?? [];
          const hasOnlyOneTask = taskTemplates.length === 1;
          const hasWidgets = widgets.length > 0;

          return hasOnlyOneTask && !hasWidgets;
        },
      },
    },
  );
};
