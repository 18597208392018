import { FeatureFlags } from '../feature-flag-constants';

export const SET_FEATURE_FLAGS = 'featureFlags/SET_FEATURE_FLAGS';

export interface SetFeatureFlagsAction {
  type: 'featureFlags/SET_FEATURE_FLAGS';
  payload: FeatureFlags;
}

const setFlags = (flags: FeatureFlags) => ({
  type: SET_FEATURE_FLAGS,
  payload: flags,
});
setFlags.type = SET_FEATURE_FLAGS;

export const FeatureFlagAction = {
  setFlags,
};
