import { CommentInboxItem } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateCommentStatusMutationParams = {
  id: CommentInboxItem['id'];
  status: CommentInboxItem['status'];
};

export type UpdateCommentStatusMutationResponse = CommentInboxItem;

export const UpdateCommentStatusMutation = {
  key: ['update', 'comment', 'status'],
  mutationFn: (params: UpdateCommentStatusMutationParams) =>
    axiosService
      .getAxios()
      .put<UpdateCommentStatusMutationResponse>(`/1/comment-inbox/comments/${params.id}`, params)
      .then(res => res.data),
};

export const useUpdateCommentStatusMutation = (
  options: UseMutationOptions<UpdateCommentStatusMutationResponse, AxiosError, UpdateCommentStatusMutationParams> = {},
) => {
  return useMutation(UpdateCommentStatusMutation.mutationFn, options);
};
