import * as React from 'react';
import {
  Badge,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ImportWorkflowModal } from 'pages/templates/_id/components/import-workflow-modal';
import { FolderPickerModal } from 'components/folder-picker/modal';
import { CreateWorkflowOption, PickButton } from 'features/new-menu/new-workflow-modal';
import { useNewBlankWorkflowButton } from 'features/new-buttons/use-new-blank-workflow-button';
import { CreateTemplateSource } from 'services/template-service.interface';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { useNgStateModalControls } from 'components/ng-state-modal-controls';
import { AppModalName } from 'app/app.constants';
import { useGetUserDetailsQuery } from 'features/user/query-builder/user-details';
import { useCurrentUser } from 'hooks/use-current-user';
import { AiLocalStorage } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/ai-local-storage';
import { useGetOrganizationDetails } from 'features/organization/query-builder';

export const FirstWorkflowModal: React.FC = () => {
  const { isOpen, onClose } = useNgStateModalControls(AppModalName.SignUpFirstWorkflow);
  const organizationId = useSelectedOrganization()?.id;
  const userId = useCurrentUser()?.id;

  const organizationDetailsQuery = useGetOrganizationDetails(
    { organizationId },
    { enabled: isOpen && Boolean(organizationId) },
  );
  const userDetailsQuery = useGetUserDetailsQuery(userId ?? '', { enabled: isOpen && Boolean(userId) });

  const importModalDisclosure = useDisclosure();
  const onImportClick = () => {
    importModalDisclosure.onOpen();
    onClose();
  };

  const {
    buttonProps: newAiGeneratedWorkflowButtonProps,
    buttonAction: newAiGeneratedWorkflowButtonAction,
    folderPickerProps: newAiGeneratedWorkflowFolderPickerProps,
  } = useNewBlankWorkflowButton({
    source: CreateTemplateSource.MAIN_NAV_BAR,
    isAiGenerated: true,
    allowSelectFolder: true,
  });

  const handleAiGeneration: React.MouseEventHandler<HTMLButtonElement> = event => {
    const userDetails = userDetailsQuery.data;
    if (userDetails) {
      AiLocalStorage.setInitialConfigFromSignUpDetails(organizationDetailsQuery.data, userDetails);
    }

    newAiGeneratedWorkflowButtonProps.onClick?.(event);
  };

  const folderPickerProps = [newAiGeneratedWorkflowFolderPickerProps].find(props => props.isOpen);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="18px" color="gray.700" py="6" px="8">
            Create your first Workflow
          </ModalHeader>

          <ModalCloseButton right="7" top="6" />
          <ModalBody mx={8} pt="6" px={{ base: '9', xs: '4', md: '9' }} pb="10">
            <VStack spacing="9">
              <Box>
                <Text>Create, track, automate, and complete tasks to streamline processes and improve efficiency.</Text>
              </Box>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                flexWrap={{ base: 'wrap', md: 'nowrap' }}
                alignItems={{ base: 'center', sm: 'stretch' }}
                justifyContent="center"
                gap={{ base: '6', sm: '2', md: '3' }}
                spacing="0"
              >
                <CreateWorkflowOption
                  title={
                    <>
                      Generate with AI
                      <Badge ml="2" colorScheme="purple" color="purple.600" bgColor="purple.100">
                        Beta
                      </Badge>
                    </>
                  }
                  description="Use Process AI to generate a custom workflow for your use case."
                  imageSrc={require('app/images/create-workflow-modal/generate-with-ai-icon.png')}
                  {...newAiGeneratedWorkflowButtonProps}
                  onClick={handleAiGeneration}
                  isDisabled={false}
                  stackProps={{
                    maxW: 'unset',
                  }}
                />

                <CreateWorkflowOption
                  title={
                    <>
                      Import Document
                      <Badge ml="2" colorScheme="purple" color="purple.600" bgColor="purple.100">
                        Beta
                      </Badge>
                    </>
                  }
                  description="Use Process AI to generate a custom workflow based on your document."
                  imageSrc={require('app/images/create-workflow-modal/file-import.png')}
                  onClick={onImportClick}
                  stackProps={{
                    maxW: 'unset',
                  }}
                />
              </Stack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {importModalDisclosure.isOpen && (
        <ImportWorkflowModal
          isOpen={importModalDisclosure.isOpen}
          onClose={importModalDisclosure.onClose}
          onFinish={newAiGeneratedWorkflowButtonAction}
        />
      )}
      {folderPickerProps?.isOpen && (
        <FolderPickerModal {...folderPickerProps} verb="add" organizationId={organizationId} pickButton={PickButton} />
      )}
    </>
  );
};
