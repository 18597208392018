import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { NodeStatus } from 'features/conditional-logic/utils/conditional-logic-utils';
import { match, P } from 'ts-pattern';

const getVisibilityIconsState = (params: {
  nodeStatus: NodeStatus | null;
  selectedRule: ChecklistRuleDefinition | null;
  ruleAffectingWidget: ChecklistRuleDefinition | null;
}) => {
  return match(params)
    .with(
      { nodeStatus: P.not(P.nullish), selectedRule: P.not(P.nullish) },
      ({ nodeStatus, selectedRule, ruleAffectingWidget }) => {
        const isShownByPreviousRule = ruleAffectingWidget ? nodeStatus.isShownBy.has(ruleAffectingWidget.id) : false;
        const isShownBySelectedRule = nodeStatus.isShownBy.has(selectedRule.id);

        const isHiddenByPreviousRule = ruleAffectingWidget ? nodeStatus.isHiddenBy.has(ruleAffectingWidget.id) : false;
        const isHiddenBySelectedRule = nodeStatus.isHiddenBy.has(selectedRule.id);

        const showShownIcon = isShownBySelectedRule || isShownByPreviousRule;
        const showHiddenIcon = isHiddenBySelectedRule || isHiddenByPreviousRule;
        const showTriggerIcon = nodeStatus.isTriggerAt.has(selectedRule.id);
        const showHiddenByDefaultIcon = !showShownIcon && !showHiddenIcon && nodeStatus.isHiddenByDefault;

        return { showShownIcon, showHiddenIcon, showTriggerIcon, showHiddenByDefaultIcon };
      },
    )
    .otherwise(() => ({
      showShownIcon: false,
      showHiddenIcon: false,
      showTriggerIcon: false,
      showHiddenByDefaultIcon: false,
    }));
};

export const ConditionalLogicSideMenuHelpers = {
  getVisibilityIconsState,
};
