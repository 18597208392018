import { getBlockAbove, isSelectionAtBlockStart, isSelectionAtBlockEnd } from '@udecode/slate-utils';
import { PSEditor } from '../utils/ps-editor';
import { createPagesPluginFactory, PagesWidgetElement, PagesWithOverride } from '../pages-plate-types';
import { setNodes } from '@udecode/slate';

export const insertBreakBasedOnCursor: PagesWithOverride = editor => {
  const { insertBreak } = editor;

  editor.insertBreak = () => {
    const previousNode = getBlockAbove<PagesWidgetElement>(editor);
    const cursorAtStart = isSelectionAtBlockStart(editor);
    const cursorAtEnd = isSelectionAtBlockEnd(editor);

    insertBreak();
    PSEditor.selectNextEmptyWidget(editor);

    if (!previousNode) return;
    const [previousBlock, previousPath] = previousNode;

    // Only make leave the new node as the default paragraph type if at the end of the line
    // Otherwise, we want to keep the current type
    if (!cursorAtEnd) {
      setNodes(editor, { type: previousBlock.type });
    } else {
      setNodes(editor, { type: 'p' });
    }

    // Treat a break from the beginning of the line as moving the block down,
    // making the previous block a paragraph
    if (cursorAtStart) {
      setNodes(editor, { type: 'p' }, { at: previousPath });
    }
  };

  return editor;
};

export const createPSInsertBreak = createPagesPluginFactory({
  key: 'PSInsertBreak',
  withOverrides: insertBreakBasedOnCursor,
});
