import { Comment, Task, Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetCommentsQueryParams = {
  templateId: Template['id'];
  taskId: Task['id'];
};

export type GetCommentsQueryResponse = Comment[];

export const GetCommentsQuery = {
  key: ['comments'],
  getKey: (params: GetCommentsQueryParams): QueryKey => [...GetCommentsQuery.key, params],
  queryFn: (params: GetCommentsQueryParams) =>
    axiosService
      .getAxios()
      .get<GetCommentsQueryResponse>(`/1/comments`, {
        params: {
          where: {
            'templateId': { _eq: params.templateId },
            'task.id': { _eq: params.taskId },
            'status': { _eq: 'Active' },
          },
          include: 'createdBy',
        },
      })
      .then(res => res.data),
};

export const useGetCommentsQuery = <Select = GetCommentsQueryResponse>(
  params: GetCommentsQueryParams,
  options: UseQueryOptions<GetCommentsQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetCommentsQuery.getKey(params), () => GetCommentsQuery.queryFn(params), options);
};
