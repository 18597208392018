import { MuidUtils, newAuditMetadata } from '@process-street/subgrade/core';
import { DataSetTriggerType, TemplateTriggerType, Webhook } from '@process-street/subgrade/process';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { EntityMap } from '@process-street/subgrade/redux/types';
import {
  AddEmptyWebhookActionType,
  DeleteWebhookSuccessActionType,
  UpdateWebhookSuccessActionType,
  WEBHOOKS_ADD_EMPTY_WEBHOOK,
  WEBHOOKS_DELETE_SUCCESS,
  WEBHOOKS_FETCH_SUCCESS,
  WEBHOOKS_UPDATE_SUCCESS,
  WebhooksFetchSuccessActionType,
} from 'components/organization-manage/webhooks/store/webhooks.actions';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';

const actionHandlers = {
  [WEBHOOKS_FETCH_SUCCESS]: (state: EntityMap<Webhook>, { payload: webhooks }: WebhooksFetchSuccessActionType) =>
    EntitiesReducerUtils.upsertAll(state, webhooks),
  [WEBHOOKS_ADD_EMPTY_WEBHOOK]: (
    state: EntityMap<Webhook>,
    { payload: { userId, webhookType } }: AddEmptyWebhookActionType,
  ) => {
    const entity: Webhook = {
      audit: newAuditMetadata(userId),
      existsOnServer: false,
      id: MuidUtils.randomMuid(),
      triggers:
        webhookType === 'Workflow' || webhookType === 'Form'
          ? [TemplateTriggerType.ChecklistCreated]
          : [DataSetTriggerType.DataSetRowCreated],
      url: '',
      status: 'Disabled',
      integrationType: 'Standard',
      webhookType,
    };
    return EntitiesReducerUtils.upsert(state, entity);
  },

  [WEBHOOKS_UPDATE_SUCCESS]: (state: EntityMap<Webhook>, { payload: webhook }: UpdateWebhookSuccessActionType) =>
    EntitiesReducerUtils.upsert(state, webhook),
  [WEBHOOKS_DELETE_SUCCESS]: (state: EntityMap<Webhook>, { payload: id }: DeleteWebhookSuccessActionType) =>
    EntitiesReducerUtils.deleteById(state, id),
};

export const webhooksEntitiesReducer = handleActionsOnSuccess(actionHandlers, {});
