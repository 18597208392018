import { createCachedAction } from 'reducers/util';

export const TEMPLATE_PERMIT_PUT = 'templatePermit/PUT';
export const TEMPLATE_PERMIT_DELETE = 'templatePermit/DELETE';
export const TEMPLATE_PERMIT_GET_ALL_BY_ORGANIZATION_ID = 'templatePermit/GET_ALL_BY_ORGANIZATION_ID';

export const TemplatePermitActions = TemplatePermitApi => {
  'ngInject';

  const getAllByOrganizationId = createCachedAction(
    TEMPLATE_PERMIT_GET_ALL_BY_ORGANIZATION_ID,
    TemplatePermitApi.getAllByOrganizationId,
  );

  return {
    getAllByOrganizationId,
  };
};
