import { createAction } from 'redux-actions';

// rules
export const DYNAMIC_DUE_DATE_GET_ALL_BY_TEMPLATE_REVISION = 'dynamicDueDate/GET_ALL_BY_TEMPLATE_REVISION';
export const DYNAMIC_DUE_DATE_GET_ALL_FILTERED_BY_CHECKLIST_REVISION =
  'dynamicDueDate/GET_ALL_FILTERED_BY_CHECKLIST_REVISION';
export const DYNAMIC_DUE_DATE_REMOVE_ALL_BY_IDS = 'dynamicDueDate/REMOVE_ALL_BY_IDS';
export const DYNAMIC_DUE_DATE_UPSERT_ALL = 'dynamicDueDate/DYNAMIC_DUE_DATE_UPSERT_ALL';
export const DYNAMIC_DUE_DATE_DELETE_ALL_BY_IDS = 'dynamicDueDate/DELETE_ALL_BY_IDS';

export const DynamicDueDateActions = DueDateRuleDefinitionDao => {
  'ngInject';

  const removeForTaskTemplateByIds = createAction(DYNAMIC_DUE_DATE_REMOVE_ALL_BY_IDS, (taskTemplateId, ids) => ({
    taskTemplateId,
    ids,
  }));

  const upsertAll = createAction(DYNAMIC_DUE_DATE_UPSERT_ALL, (templateRevisionId, rules) =>
    DueDateRuleDefinitionDao.createOrUpdateByTemplateRevisionId(templateRevisionId, rules),
  );
  const deleteAllByIds = createAction(DYNAMIC_DUE_DATE_DELETE_ALL_BY_IDS, (templateRevisionId, ids) =>
    DueDateRuleDefinitionDao.deleteAllByTemplateRevisionIdAndIds(templateRevisionId, ids),
  );

  const getAllByTemplateRevisionId = createAction(
    DYNAMIC_DUE_DATE_GET_ALL_BY_TEMPLATE_REVISION,
    (templateRevisionId, { flushCache } = { flushCache: false }) =>
      DueDateRuleDefinitionDao.getAllByTemplateRevisionId(templateRevisionId, flushCache),
  );

  const getAllFilteredByChecklistRevisionId = createAction(
    DYNAMIC_DUE_DATE_GET_ALL_FILTERED_BY_CHECKLIST_REVISION,
    checklistRevisionId => DueDateRuleDefinitionDao.getAllFilteredByChecklistRevisionId(checklistRevisionId),
    checklistRevisionId => ({ checklistRevisionId }),
  );

  return {
    deleteAllByIds,
    getAllByTemplateRevisionId,
    getAllFilteredByChecklistRevisionId,
    removeForTaskTemplateByIds,
    upsertAll,
  };
};
