import * as React from 'react';
import { match, P } from 'ts-pattern';

import { Grid, GridItem, Button, VStack, Text, Box } from 'components/design/next';

import { useInboxItems } from './use-inbox-items';

import { Title } from './title';
import { Item } from './item';
import { MSTeamsTopbar } from 'features/microsoft-teams/components/topbar';
import { useMicrosoftTeamsInboxRedirect } from 'features/microsoft-teams/inbox/use-microsoft-teams-inbox-redirect';
import { IntercomService } from 'services/interop/intercom-service';

export const MicrosoftTeamsInbox: React.FC<React.PropsWithChildren<{}>> = () => {
  const { isReady } = useMicrosoftTeamsInboxRedirect();

  // Disable Intercom Messenger
  IntercomService.update({
    hide_default_launcher: true,
  });

  const query = useInboxItems();

  if (!isReady) {
    return null;
  }

  return (
    <Box bg="brand.50" minH="100vh">
      <MSTeamsTopbar borderBottomWidth={0.5} borderBottomColor="gray.200" borderBottomStyle="solid" />
      <Grid
        pt="6"
        pb="6"
        templateColumns={{ base: '1fr 100vw 1fr', md: '1fr min(var(--ps-space-4xl), 100vw) 1fr' }}
        rowGap="7"
      >
        <GridItem colStart={2} px={['4', '4', '6', 0]}>
          <Title />
        </GridItem>
        <GridItem colStart={2} px={['4', '4', '6', 0]}>
          <VStack as="ul" pl="0" spacing="2">
            {match(query)
              .with({ status: 'loading' }, () => <div>Loading...</div>)
              .with(
                {
                  status: 'success',
                  data: {
                    pages: P.when(data => data.flat().length === 0),
                  },
                },
                () => (
                  <VStack>
                    <Text fontSize="xl" fontWeight="bold">
                      That's all
                    </Text>
                    <Text> ☕️ Enjoy your day</Text>{' '}
                  </VStack>
                ),
              )
              .with({ status: 'success' }, ({ data: { pages } }) => {
                return pages.flat().map((item, index) => <Item key={index} {...{ item }} />);
              })
              .otherwise(() => null)}
          </VStack>
        </GridItem>
        {query.hasNextPage && (
          <GridItem colStart={2} justifySelf="end">
            <Button onClick={() => query.fetchNextPage()} isDisabled={!query.hasNextPage || query.isFetchingNextPage}>
              Show more
            </Button>
          </GridItem>
        )}
      </Grid>
    </Box>
  );
};
