import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import {
  QueryClient,
  QueryKey,
  QueryObserver,
  QueryObserverOptions,
  useQuery as useRQ,
  UseQueryOptions,
} from 'react-query';
import { axiosService } from 'services/axios-service';
import { Approval } from '@process-street/subgrade/approval-rule';

export namespace GetApprovalsByChecklistRevisionIdQuery {
  export type Params = { checklistRevisionId?: Muid };

  export type Response = Approval[];

  export const key = ['checklist-approvals'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/checklist-revision/${params.checklistRevisionId}/approvals`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.checklistRevisionId) && options.enabled !== false,
    });
  };

  export const makeObserver = ({
    queryClient,
    options,
    ...params
  }: Params & {
    queryClient: QueryClient;
    options: QueryObserverOptions<Response, AxiosError>;
  }) => {
    return new QueryObserver<Response, AxiosError>(queryClient, {
      queryKey: getKey(params),
      queryFn: () => queryFn(params),
      enabled: Boolean(params.checklistRevisionId) && options.enabled !== false,
      ...options,
    });
  };
}
