import { isPublicTemplate, Template, TemplateStatus, TemplateType } from '@process-street/subgrade/process';
import {
  Box,
  Button,
  Icon,
  IconButton,
  MenuButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  useBoolean,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from 'components/design/next';
import { PublicLibraryIndicator } from 'components/permissions/public-library-indicator/component';
import { TemplatePermissionsIndicator } from 'components/permissions/template-permissions-indicator/component';
import { useFeatureFlag } from 'features/feature-flags';
import * as React from 'react';
import { match, P } from 'ts-pattern';
import {
  IconLinkContainer,
  ReportsLink,
  RightButton,
  Row,
  RowItem,
  RowLink,
  RowPermissionsIndicator,
  RowProps,
  WorkflowRunsLinkText,
} from '../common-ui';
import { PublicShareModal } from './public-share-modal';
import { useChecklistCountByTemplate } from './use-checklist-count-by-template';
import { TemplateMenu, TemplateMenuItem } from 'app/features/template/components/template-menu/template-menu';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import { RunWorkflowButton } from 'components/focus-bar/workflow/run-workflow-button';
import { EditButton } from 'pages/pages/_id/edit/page/components/edit-button';
import { TemplateShareButton } from 'directives/template-share/template-share-button';
import { useGetAllConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { LibraryCoverIcon } from 'features/cover-icon/components/library';
import { abbreviateForTitle } from '@process-street/subgrade/util';
import { MobileWorkflowActions, WorkflowActions } from './workflow-actions';
import { SelectionCheckbox } from '../selection-checkbox/selection-checkbox';
import { TemplateRowDragWrapper } from './drag-wrapper';
import { RequestFormEditPermission } from 'pages/forms/_id/shared/request-form-edit-permission';
import { FormActions, MobileFormActions } from './form-actions';
import { Muid } from '@process-street/subgrade/core';
import { MobileActions } from '../mobile-actions';
import { MobilePageActions } from './page-actions';

export type Props = {
  template: Template;
  toggleItemDisclosure: (id: Muid) => void;
  itemDisclosureById: Record<Muid, boolean>;
  isPagesEditorV2Enabled: boolean;
} & RowProps;

const TEMPLATE_LABELS = {
  [TemplateType.Page]: 'Page',
  [TemplateType.Form]: 'Form',
  [TemplateType.Playbook]: 'Workflow',
  [TemplateType.Task]: 'task', // added just for type safety
  [TemplateType.WorkflowSandbox]: 'sandbox', // also added just for type safety
} as const;

export const TemplateRow: React.FC<React.PropsWithChildren<Props>> = ({
  template,
  toggleItemDisclosure,
  itemDisclosureById,
  isPagesEditorV2Enabled,
  ...props
}) => {
  const [isHover, setIsHover] = useBoolean(false);
  const [isMenuOpen, setIsMenuOpen] = useBoolean(false);
  const isNewTemplateLibraryRowAffordancesEnabled = useFeatureFlag('newTemplateLibraryRowAffordances');
  const isSelectionEnabled = useFeatureFlag('deleteArchiveMultipleTemplatesFromLibrary');
  const isDragAndDropEnabled = useFeatureFlag('dragAndDropLibrary');
  const isWorkflowDashboardEnabled = useFeatureFlag('workflowDashboard');
  const isExpanded = itemDisclosureById[template.id] ?? false;

  // TEMPLATE INFO
  const { status, name, templateType, id } = template;
  const isArchived = status === TemplateStatus.Archived;

  // CHECKLIST COUNT
  const { count: checklistCount, isLoading } = useChecklistCountByTemplate(template);
  // TODO fix permissions indicator for private items, then re-enable
  const isPrivate = useIsPrivateTemplate(template.id);

  const templateLabel = TEMPLATE_LABELS[templateType];

  // ACTIONS
  const publicShareDisclosure = useDisclosure();
  const showPublicShareModal = () => {
    publicShareDisclosure.onOpen();
  };

  const { data: { permissionMap } = {} } = useGetAllConsolidatedTemplatePermissionsQuery({
    select: allTemplatePermits => allTemplatePermits.find(fp => fp.templateId === template.id),
  });

  const sharingIsEnabled =
    !!permissionMap &&
    (permissionMap.templatePermitsManage ||
      permissionMap.templateShareLevelUpdate ||
      permissionMap.templateShareLinkUpdate);

  const handleOnCloseMenu = () => {
    setIsMenuOpen.off();
  };

  const isViewOnlyForm = templateType === TemplateType.Form && !permissionMap?.templateUpdate;

  const isMobile = useBreakpointValue({ base: true, md: false });
  const shouldDisplay = !isMobile && isHover;

  const templateMenuItems = (
    <>
      {match(templateType)
        .with(TemplateType.Playbook, () => (
          <RunWorkflowButton templateId={template.id}>
            <TemplateMenuItem w="full" icon={<Icon icon="play" variant="far" size="4" />}>
              Run Workflow
            </TemplateMenuItem>
          </RunWorkflowButton>
        ))
        .with(TemplateType.Form, () => null)
        .with(TemplateType.Page, () => null)
        .with(TemplateType.Task, () => null)
        .with(TemplateType.WorkflowSandbox, () => null)
        .exhaustive()}

      <EditButton templateId={template.id}>
        <TemplateMenuItem />
      </EditButton>

      {!isPrivate && (
        <TemplateShareButton templateId={template.id} isDisabled={!sharingIsEnabled}>
          <TemplateMenuItem icon={<Icon icon="share" variant="far" size="4" />}>Share</TemplateMenuItem>
        </TemplateShareButton>
      )}
    </>
  );

  const mobileTemplateMenu = (
    <TemplateMenu
      mode="view"
      templateId={template.id}
      isLazy={true}
      menuListProps={{ maxH: '54' }}
      onClose={handleOnCloseMenu}
      menuButton={
        <MenuButton
          as={MobileActions.Item}
          leftIcon={<Icon icon="ellipsis-h" size="4" />}
          aria-label={`Open ${templateLabel.toLowerCase()} menu`}
          aria-haspopup="true"
        >
          View options
        </MenuButton>
      }
    >
      {templateMenuItems}
    </TemplateMenu>
  );

  return (
    <TemplateRowDragWrapper key={template.id} template={template}>
      <VStack spacing="0" w="full" overflow="hidden">
        <Row
          onMouseEnter={() => setIsHover.on()}
          onMouseLeave={() => setIsHover.off()}
          {...(isDragAndDropEnabled ? { px: undefined, pr: { base: 2, md: 4 }, pl: 1 } : {})}
          {...props}
          bgGradient={
            isArchived
              ? 'repeating-linear-gradient(45deg, gray.50, gray.50 10px, gray.100 10px, gray.100 20px)'
              : undefined
          }
          role="group"
        >
          {isSelectionEnabled && (
            <Box mr="4">
              <SelectionCheckbox item={template} />
            </Box>
          )}

          {match(templateType)
            .with(TemplateType.Playbook, () => (
              <RowLink
                to={isWorkflowDashboardEnabled ? 'templateDashboard' : 'templateView'}
                params={{ id, title: `${abbreviateForTitle(template?.name)}-` }}
                options={{ inherit: false }}
                icon={<LibraryCoverIcon templateId={id} templateType={templateType} flexShrink={0} flexGrow={0} />}
                _hover={{
                  color: 'brand.500',
                  svg: {
                    color: 'indigo.600',
                  },
                }}
                onClick={isMobile ? () => toggleItemDisclosure(template.id) : undefined}
              >
                {name}
              </RowLink>
            ))
            .with(TemplateType.Form, () =>
              match(isViewOnlyForm)
                .with(true, () => (
                  <RowItem
                    icon={
                      <LibraryCoverIcon
                        templateId={id}
                        templateType={templateType}
                        flexShrink={0}
                        flexGrow={0}
                        iconProps={{ color: 'orange.200' }}
                      />
                    }
                    color="gray.300"
                    _hover={{
                      color: 'gray.300',
                      cursor: 'not-allowed',
                      svg: {
                        color: 'orange.200',
                      },
                    }}
                  >
                    <Popover trigger="hover" variant="tooltip-dark">
                      <PopoverTrigger>
                        <span>{name}</span>
                      </PopoverTrigger>
                      <PopoverContent w="auto">
                        <PopoverArrow />
                        <PopoverBody>{template && <RequestFormEditPermission template={template} />}</PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </RowItem>
                ))
                .with(false, () => (
                  <RowLink
                    to="form"
                    params={{ id, title: `${abbreviateForTitle(template?.name)}-` }}
                    options={{ inherit: false }}
                    icon={<LibraryCoverIcon templateId={id} templateType={templateType} flexShrink={0} flexGrow={0} />}
                    _hover={{
                      svg: {
                        color: 'orange.600',
                      },
                    }}
                    onClick={isMobile ? () => toggleItemDisclosure(template.id) : undefined}
                  >
                    {name}
                  </RowLink>
                ))
                .exhaustive(),
            )
            .with(TemplateType.Page, () => (
              <RowLink
                to={isPagesEditorV2Enabled ? 'pageViewV2' : 'pageView'}
                params={{ id, title: `${abbreviateForTitle(template?.name)}-` }}
                options={{ inherit: false }}
                icon={<LibraryCoverIcon templateId={id} templateType={templateType} flexShrink={0} flexGrow={0} />}
                _hover={{
                  color: 'brand.500',
                  svg: {
                    color: 'teal.600',
                  },
                }}
                onClick={isMobile ? () => toggleItemDisclosure(template.id) : undefined}
              >
                {name}
              </RowLink>
            ))
            .otherwise(() => null)}

          {shouldDisplay || isMenuOpen ? (
            <RightButton
              onClick={() => setIsMenuOpen.on()}
              w={{ base: '10', md: 'auto' }}
              pr={{ base: undefined, md: '8' }}
              pl={{ base: undefined, md: '4' }}
            >
              <>
                {templateType === TemplateType.Playbook && isNewTemplateLibraryRowAffordancesEnabled && (
                  <WorkflowActions template={template} />
                )}

                {templateType === TemplateType.Form && <FormActions template={template} />}

                <TemplateMenu
                  mode="view"
                  templateId={template.id}
                  isLazy={true}
                  menuListProps={{ maxH: '54' }}
                  onClose={handleOnCloseMenu}
                  menuButton={
                    <MenuButton
                      as={IconButton}
                      icon={<Icon icon="ellipsis-h" variant="far" size="3" />}
                      aria-label={`Open ${templateLabel.toLowerCase()} menu`}
                      aria-haspopup="true"
                      variant="ghost"
                      size="sm"
                      color="gray.600"
                      backgroundColor="white"
                      borderWidth="thin"
                      borderColor={{ base: 'transparent', md: 'gray.300' }}
                      borderStyle="solid"
                      _hover={{
                        bgColor: 'gray.100',
                      }}
                      _expanded={{
                        bgColor: 'gray.200',
                      }}
                      _active={{
                        bgColor: 'gray.200',
                      }}
                    />
                  }
                >
                  {templateMenuItems}
                </TemplateMenu>
              </>
            </RightButton>
          ) : (
            <Box />
          )}

          {match({ template })
            .with({ template: P.when(isPublicTemplate) }, ({ template }) => (
              <>
                <Button
                  display={['none', null, 'inline-flex']}
                  variant="ghost"
                  title={`This ${templateLabel.toLowerCase()} is public`}
                  onClick={showPublicShareModal}
                  mx="4"
                >
                  <PublicLibraryIndicator />
                </Button>
                <PublicShareModal {...publicShareDisclosure} template={template} />
              </>
            ))

            .otherwise(() => null)}

          {!isPrivate && (
            <RowPermissionsIndicator>
              <TemplatePermissionsIndicator
                templateId={template.id}
                folderId={template.folder.id}
                organizationId={template.organization.id}
                isViewOnlyForm={isViewOnlyForm}
              />
            </RowPermissionsIndicator>
          )}

          {match({ templateType, isLoading })
            .with({ isLoading: true }, () => (
              <>
                <IconLinkContainer w={10}>
                  <Skeleton h="6" width="8" />
                </IconLinkContainer>
                <IconLinkContainer>
                  <Skeleton h="6" width="8" />
                </IconLinkContainer>
              </>
            ))
            .with({ templateType: TemplateType.Playbook }, () => (
              <>
                <IconLinkContainer w={10}>
                  <ReportsLink
                    to="reports"
                    params={{ templateId: id, tab: 'analytics' }}
                    options={{ inherit: false }}
                    title="Click to view the analytics for this workflow."
                    icon={<Icon icon="chart-bar" variant="far" size="4" />}
                  />
                </IconLinkContainer>
                <IconLinkContainer aria-label="workflow run count">
                  <ReportsLink
                    to="reports"
                    params={{ templateId: id, tab: 'table' }}
                    options={{ inherit: false }}
                    title="Click to view the workflow runs for this workflow."
                    icon={<Icon icon="th-list" variant="far" size="4" />}
                  >
                    <WorkflowRunsLinkText>{checklistCount}</WorkflowRunsLinkText>
                  </ReportsLink>
                </IconLinkContainer>
              </>
            ))
            .with({ templateType: TemplateType.Form }, () => (
              <>
                <IconLinkContainer w={10}></IconLinkContainer>
                <IconLinkContainer aria-label="form response count">
                  <ReportsLink
                    to="formResponses"
                    params={{ id: template?.id, title: template?.name }}
                    title="Click to view the responses for this form."
                    icon={<Icon icon="th-list" variant="far" size="4" />}
                  >
                    <WorkflowRunsLinkText>{checklistCount}</WorkflowRunsLinkText>
                  </ReportsLink>
                </IconLinkContainer>
              </>
            ))
            .with({ templateType: P.union(TemplateType.Page, TemplateType.Form) }, () => (
              <>
                <IconLinkContainer w={10}></IconLinkContainer>
                <IconLinkContainer></IconLinkContainer>
              </>
            ))
            .otherwise(() => (
              <IconLinkContainer></IconLinkContainer>
            ))}

          <IconButton
            aria-label={`${isExpanded ? 'Collapse' : 'Expand'} ${template.name} folder`}
            display={{ base: 'block', md: 'none' }}
            icon={<Icon icon={isExpanded ? 'caret-up' : 'caret-down'} variant="fas" color="gray.500" size="4" />}
            variant="unstyled"
            maxW="8"
            onClick={() => toggleItemDisclosure(template.id)}
          />
        </Row>

        {isExpanded && templateType === TemplateType.Playbook && (
          <MobileWorkflowActions template={template}>{mobileTemplateMenu}</MobileWorkflowActions>
        )}

        {isExpanded && templateType === TemplateType.Form && (
          <MobileFormActions template={template}>{mobileTemplateMenu}</MobileFormActions>
        )}

        {isExpanded && templateType === TemplateType.Page && (
          <MobilePageActions template={template}>{mobileTemplateMenu}</MobilePageActions>
        )}
      </VStack>
    </TemplateRowDragWrapper>
  );
};
