import {
  PaywallAdminBodyDescription,
  PaywallAdminHeaderIcon,
  PaywallAdminIconUserPlus,
  PaywallBodyAdminHeading,
  PaywallBodyNoIcon,
  PaywallButtonGroup,
  PaywallCloseButton,
  PaywallDivider,
  PaywallFeatures,
  PaywallFooter,
  PaywallHeaderWithIcon,
  PaywallModal,
  PaywallPrimaryActionLinkButton,
  PaywallSecondaryActionButton,
} from 'components/paywalls/common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { usePaywall } from '../paywall-provider';
import { useFeatureSet } from 'components/paywalls/use-feature-set';
import { IntercomService } from 'services/interop/intercom-service';
import { useRoleNames } from 'hooks/use-role-names';
import { AnalyticsService } from 'components/analytics/analytics.service';

export const BulkInvitePaywall: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setPaywall } = usePaywall();
  const { usersLimit } = useFeatureSet();
  const { name: orgName } = useSelector(SessionSelector.getSelectedOrganization) ?? {};
  const roleNames = useRoleNames();

  const contactSales = React.useCallback(() => {
    AnalyticsService.trackEvent('contact us link clicked', { location: 'use checklist' });
    IntercomService.show();
  }, []);

  const closePaywall = React.useCallback(() => {
    setPaywall(null);
  }, [setPaywall]);

  return (
    <PaywallModal>
      <PaywallHeaderWithIcon bgColor="brand.100">
        <PaywallCloseButton onClick={closePaywall} />

        <PaywallAdminHeaderIcon>
          <PaywallAdminIconUserPlus />
        </PaywallAdminHeaderIcon>
      </PaywallHeaderWithIcon>

      <PaywallBodyNoIcon>
        <PaywallBodyAdminHeading>
          {orgName} has reached {usersLimit} member limit
        </PaywallBodyAdminHeading>
        <PaywallAdminBodyDescription>
          Add more {roleNames.FullMember.plural} by upgrading {orgName} to Pro.
        </PaywallAdminBodyDescription>

        <PaywallFeatures />
      </PaywallBodyNoIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallButtonGroup>
          <PaywallSecondaryActionButton onClick={contactSales} className="intercom-bot-contact-sales">
            Contact Sales
          </PaywallSecondaryActionButton>

          <PaywallPrimaryActionLinkButton href="/organizations/manage/billing">
            Upgrade Now
          </PaywallPrimaryActionLinkButton>
        </PaywallButtonGroup>
      </PaywallFooter>
    </PaywallModal>
  );
};
