import angular, { IController, IFormController, IRootScopeService, IScope } from 'angular';
import { HttpStatus } from '@process-street/subgrade/util';
import { AnalyticsConstants } from '@process-street/subgrade/analytics';
import { AuthenticationError, MSTeamsUtils } from 'features/microsoft-teams/utils';
import { EmailService } from 'services/email-service';
import { trace } from 'components/trace';
import { LocalStorageService } from 'features/storage/local-storage-service';
import { IStateService } from 'angular-ui-router';
import { SignUpInfo, SignUpService } from 'services/sign-up-service.interface';
import { AppBootService } from 'services/boot/app-boot-service';
import { Auth0Service } from 'services/auth0-service.interface';
import { FeatureFlagService } from 'services/features/feature-flags/feature-flag-service';
import { FocusById } from 'services/focus.interface';
import { SessionService } from 'services/session-service.interface';
import { SignUpStateService } from 'services/sign-up-state-service';
import { queryClient } from 'components/react-root';
import { GetPublicTemplateQuery } from 'features/template/query-builder/get-public-template-query';
import { Template } from '@process-street/subgrade/process';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { SignUpErrorCode } from 'services/sign-up-error-code';

angular.module('frontStreetApp.controllers').controller(
  'SignUpCtrl',
  function (
    this: IController,
    $rootScope: IRootScopeService,
    $scope: IScope & {
      info: SignUpInfo;
      msTeamsApp: boolean;
      loading: boolean;
      submitting: boolean;
      [s: string]: any;
    },
    $state: IStateService,
    appBootService: AppBootService,
    Auth0Service: Auth0Service,
    FeatureFlagService: FeatureFlagService,
    focusById: FocusById,
    SessionService: SessionService,
    SignUpService: SignUpService,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias -- old ctrl
    const ctrl = this;
    const logger = trace({ name: 'SignUpCtrl' });
    logger.info('loading ctrl');

    $scope.loading = false;
    $scope.msTeamsApp = MSTeamsUtils.isEmbeddedInMsTeams();

    ctrl.$onInit = async () => {
      $scope.info = {
        email: $state.params.email,
      };

      if ($scope.msTeamsApp) {
        await FeatureFlagService.initializeForAnonymousMicrosoftTeamsUser();
      } else {
        FeatureFlagService.initializeForUser();
      }

      SignUpStateService.saveSource($state.params.source);
      SignUpStateService.saveCoupon($state.params.coupon);
      SignUpStateService.savePlanId($state.params.planId);
      SignUpStateService.saveTemplateIds($state.params);

      const { templateId, pageId } = SignUpStateService.getTemplateIds();
      const publicTemplateId = templateId ?? pageId;
      if (publicTemplateId) {
        const data = await queryClient.fetchQuery<Template>({
          queryFn: () => GetPublicTemplateQuery.queryFn({ templateId: publicTemplateId }),
          queryKey: GetPublicTemplateQuery.getKey({ templateId: publicTemplateId }),
        });
        $scope.templateName = data?.name;
      }

      $scope._autoSignUpIfPossible();
    };

    $scope.signUp = function (form: IFormController, info: SignUpInfo) {
      if (form.$valid) {
        if ($state.params.url) {
          LocalStorageService.setItem('url', $state.params.url);
        }

        $scope._doSignUp(info.email);
      } else {
        const messages: string[] = [];

        if (form.email.$error.required) {
          messages.push('You must enter an email address.');
        }
        if (form.email.$error.email) {
          messages.push('You must enter a valid email address.');
        }

        const description = messages.join('<br>');
        ToastServiceImpl.openToast({
          status: 'error',
          title: description,
        });
      }
    };

    $scope._doSignUp = function (email: string) {
      $scope.submitting = true;
      const source = SignUpStateService.getSource() ?? '';

      return SignUpService.signUp(email, source).then(
        user => {
          $state.go('newCompleteSignUp.user', {
            email: user.email,
            planId: $state.params.planId,
            coupon: $state.params.coupon,
          });
        },
        response => {
          $scope.submitting = false;

          if (response.status === HttpStatus.CONFLICT) {
            switch (response.data.code) {
              case SignUpErrorCode.DUPLICATE:
                $state.go('login');
                break;
              case SignUpErrorCode.LOCKED:
                $state.go('loginSSO');
                break;
            }
          }
        },
      );
    };

    $scope.signUpUsingIDP = (connection: string) => async () => {
      if ($state.params.url) {
        LocalStorageService.setItem('url', $state.params.url);
      }

      if ($scope.msTeamsApp) {
        const teamsSdk = await import('@microsoft/teams-js');

        teamsSdk.initialize();
        teamsSdk.getContext(context => {
          // We can't call the authentication popup if we are already inside the authentication popup context.
          // This case happens in the Teams mobile app.
          if (context.frameContext === teamsSdk.FrameContexts.authentication) {
            Auth0Service.loginUsingIDP(connection);
          } else {
            $scope.loading = true;
            $scope.$apply();

            Auth0Service.loginUsingIDPPopup(connection)
              .then(token => {
                $scope._bootAppWithToken(token);
              })
              .catch((e: AuthenticationError) => {
                logger.error('Sign-up via Teams SDK failed.', e);
                const errorMessage = MSTeamsUtils.getAuthErrorMessage(e);

                $scope.loading = false;
                ToastServiceImpl.openToast({
                  status: 'error',
                  title: errorMessage,
                });
                $scope.$apply();
              });
          }
        });
      } else {
        Auth0Service.loginUsingIDP(connection);
      }
    };

    $scope.signUpUsingMicrosoft = $scope.signUpUsingIDP('microsoft-login');
    $scope.signUpUsingGoogle = $scope.signUpUsingIDP('google-oauth2');

    $scope._autoSignUpIfPossible = function () {
      const { email } = $state.params;
      const emailOk = EmailService.isValidEmailAddress(email);
      if (emailOk) {
        $scope._doSignUp(email);
      } else {
        focusById('email');
      }
    };

    $scope._bootAppWithToken = function (token: string) {
      appBootService.setupSessionAndBootUsingToken(token).then(() => {
        $rootScope.$broadcast(
          AnalyticsConstants.Event.USER_LOGGED_IN,
          SessionService.getUser(),
          SessionService.getProfile(),
        );
        Auth0Service.redirectToStoredUrl();
      });
    };

    $scope.expandEmailAddress = EmailService.expandEmailAddress;
  },
);
