import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace DeleteDataSetMutation {
  export type Params = {
    dataSetId: Muid;
  };

  export type Response = void;

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .delete(`/1/data-sets/${params.dataSetId}`)
      .then(r => r.data);

  export const useMutation = (options?: UseMutationOptions<Response, AxiosError, Params>) => {
    return useRQMutation(mutationFn, options);
  };
}
