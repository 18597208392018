import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { PermissionsSelectorWrapper } from './permissions-selector/wrapper';
import { PermissionsDescriptionWrapper } from 'components/permissions/permissions-description/wrapper';
import { FolderPermissionsIndicatorWrapper } from 'components/permissions/folder-permissions-indicator/wrapper';

export const PermissionsModule = angular
  .module('permissionsModule', [])
  .component(
    'psPermissionsSelector',
    reactToAngular(PermissionsSelectorWrapper, [
      'isDisabled',
      'accessLevels',
      'selectedAccessLevel',
      'onChange',
      'membership',
      'onLevelChange',
    ]),
  )
  .component('psPermissionsDescription', reactToAngular(PermissionsDescriptionWrapper, ['memberships']))
  .component(
    'psFolderPermissionsIndicator',
    reactToAngular(FolderPermissionsIndicatorWrapper, ['organizationId', 'folderId']),
  ).name;
