import { Template, TemplateRevision } from '@process-street/subgrade/process';
import * as yup from 'yup';

export type TemplateSettingsSchema = Pick<
  Template,
  'name' | 'description' | 'checklistCommentsEnabled' | 'checklistShareLinkEnabled' | 'checklistRequestApprovalEnabled'
> &
  Pick<TemplateRevision, 'defaultChecklistName'>;

export enum TemplateSettingsNextPath {
  Default = 'Default',
  AddTasks = 'AddTasks',
}

const WORFKLOW_NAME_LENGTH_MAX = 255;
const WORFKLOW_DESCRIPTION_LENGTH_MAX = 255;
const DEFAULT_RUN_NAME_MAX = 500;
const TASK_TEMPLATE_NAME_LENGTH_MAX = 255;

export const templateSettingsSchema = yup.object().shape<TemplateSettingsSchema>({
  name: yup
    .string()
    .max(WORFKLOW_NAME_LENGTH_MAX, `Name must be at most ${WORFKLOW_NAME_LENGTH_MAX} characters.`)
    .trim()
    .required('Name is required.'),
  description: yup
    .string()
    .max(WORFKLOW_DESCRIPTION_LENGTH_MAX, `Description must be at most ${WORFKLOW_DESCRIPTION_LENGTH_MAX} characters.`)
    .trim(),
  defaultChecklistName: yup
    .string()
    .max(DEFAULT_RUN_NAME_MAX, `Run name must be at most ${DEFAULT_RUN_NAME_MAX} characters.`)
    .trim(),
  checklistCommentsEnabled: yup.boolean(),
  checklistShareLinkEnabled: yup.boolean(),
  checklistRequestApprovalEnabled: yup.boolean(),
});

export type BulkAddTasksSchema = {
  tasks?: string;
};

export const bulkAddTasksSchema = yup.object().shape<BulkAddTasksSchema>({
  tasks: yup
    .string()
    .test(
      'taskNameLength',
      `Each task name must have a maximum length of ${TASK_TEMPLATE_NAME_LENGTH_MAX} characters.`,
      tasks => (tasks ?? '').split(/\n/).every(line => line.length < TASK_TEMPLATE_NAME_LENGTH_MAX),
    ),
});
