import * as React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { useFormikContext } from 'formik';
import { TemplateSettingsSchema } from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/schema';
import { useMergeTaggableInput } from 'hooks/use-merge-taggable-input';
import { InputGroup } from 'components/design/next';
import { Input, InputRightElement } from 'components/design/next';
import { MergeTagsMenu, MergeTagsMenuButton } from 'features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';

export const DefaultChecklistNameInput: React.FC<React.PropsWithChildren<{ templateRevisionId: Muid }>> = ({
  templateRevisionId,
}) => {
  const { values, handleBlur, handleChange, setFieldValue } = useFormikContext<TemplateSettingsSchema>();
  const { ref: defaultChecklistNameRef, insertMergeTag: insertVariable } = useMergeTaggableInput({
    get: () => values.defaultChecklistName ?? '',
    set: v => setFieldValue('defaultChecklistName', v),
  });

  return (
    <InputGroup>
      <Input
        name="defaultChecklistName"
        aria-label="Default workflow run name"
        placeholder="{{ current_user.name }} - {{ current_date.full }}"
        value={values.defaultChecklistName}
        onBlur={handleBlur}
        onChange={handleChange}
        ref={defaultChecklistNameRef}
      />
      <InputRightElement _focusWithin={{ zIndex: 3 }}>
        <MergeTagsMenu
          placement="bottom"
          {...{
            templateRevisionId,
            onSelect: (tag, _, fallback) => insertVariable(tag, fallback),
            mergeTagTarget: MergeTagsConstants.Target.CHECKLIST,
            menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
          }}
        />
      </InputRightElement>
    </InputGroup>
  );
};
