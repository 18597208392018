import * as React from 'react';
import { S3File } from '@process-street/subgrade/core';
import { Button, Icon, Link, Text, Flex } from 'components/design/next';

export const DownloadElement: React.FC<React.PropsWithChildren<S3File>> = props => {
  const file = props;

  return (
    <Flex justifyContent="space-between" align="center">
      <Flex align="center">
        <Icon size="4" icon="paperclip" color="gray.600" />
        <Text ml="2" textTransform="uppercase" fontWeight="bold">
          {file.extension}
        </Text>
        <Text ml="4" fontWeight="medium">
          {file.originalName}
        </Text>
      </Flex>
      <Button
        data-hide-on-print
        ml="4"
        as={Link}
        href={file.url}
        target="_blank"
        rel="noopener noreferrer"
        variant="primary"
        download
        _hover={{ color: 'white', textDecoration: 'none', backgroundColor: 'brand.600' }}
        flexShrink={0}
      >
        <Icon size="4" icon="arrow-to-bottom" mr="2" />
        Download
      </Button>
    </Flex>
  );
};
