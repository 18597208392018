import { VideoWidgetService } from '@process-street/subgrade/process';
import { AspectRatio, Box } from 'components/design/next';
import * as React from 'react';
import { EmbedInfo } from '../../';

const Iframe: React.FC<React.PropsWithChildren<{ src: string }>> = ({ src }) => {
  return (
    <iframe
      title="video embed"
      src={src}
      frameBorder="0"
      allowFullScreen={true}
      sandbox="allow-scripts allow-forms allow-popups allow-same-origin allow-presentation"
    />
  );
};

const WistiaEmbed: React.FC<React.PropsWithChildren<{ serviceCode: string }>> = ({ serviceCode }) => {
  return <Box title="video embed" className={`wistia_embed wistia_async_${serviceCode}`} />;
};

const Embed: React.FC<React.PropsWithChildren<{ embed: EmbedInfo }>> = ({ embed }) => {
  switch (embed.service) {
    case VideoWidgetService.YouTube:
      return <Iframe src={`https://www.youtube.com/embed/${embed.serviceCode}?modestbranding=1&showinfo=0`} />;
    case VideoWidgetService.Vimeo:
      return <Iframe src={`https://player.vimeo.com/video/${embed.serviceCode}`} />;
    case VideoWidgetService.Loom:
      return <Iframe src={`https://www.loom.com/embed/${embed.serviceCode}`} />;
    case VideoWidgetService.Wistia:
      return <WistiaEmbed serviceCode={embed.serviceCode} />;
    default:
      return <Box>Unknown service: {embed.service}</Box>;
  }
};

export const VideoUI: React.FC<React.PropsWithChildren<{ embed: EmbedInfo }>> = ({ embed }) => {
  return React.useMemo(
    () => (
      <AspectRatio ratio={16 / 9} width="100%" borderRadius="lg" overflow="hidden">
        <Embed embed={embed} />
      </AspectRatio>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- embed object is recreated on every rerender
    [embed.service, embed.serviceCode],
  );
};
