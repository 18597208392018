import React from 'react';
import { Button, HStack, Icon, IconButton } from 'components/design/next';
import { FormResponsePageProviders } from 'pages/checklists/_id/providers';
import { Task } from '@process-street/subgrade/process';
import { CreateAllApprovalsMutation } from 'features/approvals/query-builder/create-all-approvals-mutation';
import { MuidUtils } from '@process-street/subgrade/core';
import { ApprovalStatus } from '@process-street/subgrade/approval-rule';

export type ApprovalButtonsProps = {
  subjectTask: Task;
  approvalTaskId: Task['id'];
};

export const ApprovalButtons: React.FC<ApprovalButtonsProps> = ({ subjectTask, approvalTaskId }) => {
  const formResponseActor = FormResponsePageProviders.FormResponseActorRef.useActorRef();

  const handleCreateApproval = (status: ApprovalStatus) => {
    const approval: CreateAllApprovalsMutation.ApprovalDto = {
      id: MuidUtils.randomMuid(),
      subjectTaskId: subjectTask.id,
      approvalTaskId,
      organizationId: subjectTask.organization.id,
      status,
    };
    formResponseActor.send({ type: 'CREATE_APPROVALS', approvals: [approval] });
  };

  return (
    <HStack gap={0} width="full" justifyContent="flex-end">
      <Button
        variant="outline"
        colorScheme="red"
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
        width="100px"
        onClick={() => handleCreateApproval(ApprovalStatus.Rejected)}
      >
        Reject
      </Button>
      <IconButton
        aria-label={'Approve'}
        colorScheme="red"
        variant="outline"
        borderLeft="none"
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
      >
        <Icon icon="comment" size="4" />
      </IconButton>

      <Button
        colorScheme="blue"
        marginLeft={4}
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
        width="100px"
        onClick={() => handleCreateApproval(ApprovalStatus.Approved)}
      >
        Approve
      </Button>
      <IconButton
        aria-label={'Approve'}
        colorScheme="blue"
        borderLeft="2px solid"
        borderColor="white"
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
      >
        <Icon icon="comment" size="4" />
      </IconButton>
    </HStack>
  );
};
