import React from 'react';
import { Template } from '@process-street/subgrade/process';
import { Edit } from './edit';
import { View } from './view';

export type EditProps = { templateId: Template['id']; editable: boolean };

export const TemplateMedia = ({ templateId, editable }: EditProps) => {
  return editable ? <Edit templateId={templateId} /> : <View templateId={templateId} />;
};
