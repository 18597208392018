import { isNotIdRef, OrganizationMembershipRole, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { StringUtils } from '@process-street/subgrade/util';

export const PAGE_SIZE = 50;
export const PAGE_BTNS_TO_SHOW = 5;

export type ShownUserType = 'All Users' | 'Admins' | 'Members' | 'Guests';

type Action =
  | { type: 'searchChanged'; value: string; memberships: OrganizationMembershipWithUser[] }
  | { type: 'userTypeChanged'; userType: ShownUserType; memberships: OrganizationMembershipWithUser[] }
  | { type: 'loadMemberships'; memberships: OrganizationMembershipWithUser[] }
  | { type: 'previousPage' }
  | { type: 'nextPage' }
  | { type: 'setPage'; page: number };

export type State = {
  filteredMemberships: OrganizationMembershipWithUser[];
  usernameOrEmail: string;
  shownUserType: ShownUserType;
  page: number;
};

const canShowMembership = (membership: OrganizationMembershipWithUser, userType: ShownUserType): boolean => {
  switch (userType) {
    case 'Admins':
      return membership.role === OrganizationMembershipRole.Admin;
    case 'Guests':
      return [OrganizationMembershipRole.Guest, OrganizationMembershipRole.FreeMember].includes(membership.role);
    case 'Members':
      return membership.role === OrganizationMembershipRole.FullMember;
    default:
      return true;
  }
};

const containsUsernameOrEmail = (filter: string, membership: OrganizationMembershipWithUser) => {
  if (filter === '') {
    return true;
  }
  const { user } = membership;
  if (isNotIdRef(user)) {
    const usernameContainsFilter = StringUtils.containsIgnoreCase(user.username, filter);
    const emailContainsFilter = StringUtils.containsIgnoreCase(user.email, filter);
    return usernameContainsFilter || emailContainsFilter;
  }
  return false;
};

export function getLastPage<Item>({ length }: Item[]) {
  return Math.ceil(length / PAGE_SIZE);
}

export const getMinMax = (page: number) => {
  const min = (page - 1) * PAGE_SIZE;
  const max = min + PAGE_SIZE;
  return [min, max];
};

export function getPageSlice<Item>({ page, items }: { page: number; items: Item[] }) {
  return items.slice(...getMinMax(page));
}

export const getPageButtonRange = ({ page, lastPage }: { page: number; lastPage: number }) => {
  return Array.from({ length: PAGE_BTNS_TO_SHOW }, (_, i) => {
    const it = ((i + 1) % 6) + PAGE_BTNS_TO_SHOW * Math.floor((page - 1) / PAGE_BTNS_TO_SHOW);
    return it > lastPage ? undefined : it;
  });
};

export const reducer = (state: State, action: Action): State => {
  const filterMemberships = ({
    memberships,
    userType = state.shownUserType,
    usernameOrEmail = state.usernameOrEmail,
  }: {
    memberships: OrganizationMembershipWithUser[];
    userType?: ShownUserType;
    usernameOrEmail?: string;
  }) => {
    return memberships.filter(
      membership =>
        canShowMembership(membership, userType) &&
        containsUsernameOrEmail(usernameOrEmail.trim().toLowerCase(), membership),
    );
  };

  switch (action.type) {
    case 'searchChanged': {
      return {
        ...state,
        usernameOrEmail: action.value,
        filteredMemberships: filterMemberships({ memberships: action.memberships, usernameOrEmail: action.value }),
        page: 1,
      };
    }

    case 'userTypeChanged': {
      return {
        ...state,
        shownUserType: action.userType,
        filteredMemberships: filterMemberships({ memberships: action.memberships, userType: action.userType }),
        page: 1,
      };
    }

    case 'loadMemberships': {
      return {
        ...state,
        filteredMemberships: filterMemberships({ memberships: action.memberships }),
        page: 1,
      };
    }

    case 'previousPage': {
      return {
        ...state,
        page: Math.max(1, state.page - 1),
      };
    }

    case 'nextPage': {
      const lastPage = getLastPage(state.filteredMemberships);
      return {
        ...state,
        page: Math.min(lastPage, state.page + 1),
      };
    }

    case 'setPage': {
      return {
        ...state,
        page: action.page,
      };
    }

    default:
      return state;
  }
};
