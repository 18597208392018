import { useDisclosure, useOutsideClick } from '@chakra-ui/react';
import { TinyMCEEditor } from 'app/features/rich-text';
import { useRef, useEffect, useCallback } from 'react';

export type UseToolbarDisclosureParams = {
  editor?: TinyMCEEditor;
  popoverContentRef: React.MutableRefObject<HTMLDivElement | null>;
  mergeTagsMenuContentRef: React.MutableRefObject<HTMLDivElement | null>;
};

/*
 * Custom hook to manage the disclosure of the text content widget toolbar component.
 * It uses Chakra UI's `useOutsideClick` to close the toolbar when clicking outside of specified areas:
 * - If the click was inside the editor content area.
 * - If the click was inside the toolbar popover.
 * - If the click was inside the merge tags menu.
 * - If the click was inside the component's root element.
 */
export const useToolbarDisclosure = ({
  editor,
  popoverContentRef,
  mergeTagsMenuContentRef,
}: UseToolbarDisclosureParams) => {
  const toolbarDisclosure = useDisclosure();
  const ghostElementRef = useRef<HTMLDivElement | null>(null);

  const editorRef = useRef<TinyMCEEditor | undefined>(editor);

  useEffect(() => {
    editorRef.current = editor;
  }, [editor]);

  const handler = useCallback(
    (e: Event) => {
      const target = e.target as HTMLElement;
      if (target) {
        const clickedToolbar = popoverContentRef.current?.contains(target) ?? false;
        const clickedMergeTagsMenuContent = mergeTagsMenuContentRef.current?.contains(target) ?? false;
        const clickedEditorContent = editorRef.current?.contentAreaContainer?.contains(target) ?? false;

        if (clickedToolbar || clickedMergeTagsMenuContent || clickedEditorContent) {
          toolbarDisclosure.onOpen();
          return;
        }
      }
      toolbarDisclosure.onClose();
    },
    [popoverContentRef, mergeTagsMenuContentRef, toolbarDisclosure],
  );

  useOutsideClick({
    ref: ghostElementRef,
    handler,
  });

  return toolbarDisclosure;
};
