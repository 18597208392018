import {
  GROUP_CREATE,
  GROUP_DELETE,
  GROUP_GET_BY_ID,
  GROUP_GET_BY_USER,
  GROUP_QUERY,
} from 'reducers/group/group.actions';
import { composeReducerObjects } from 'reducers/util';
import { appendOnPutReducerObject, referencesNormalizer } from 'reducers/entities/entities.utils';
import { GROUP_MEMBERSHIP_PUT } from 'reducers/group-membership/group-membership.actions';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';

const normalizeGroup = referencesNormalizer(['organization', 'user']);

/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "state" }]*/
const groupsReducerObject = {
  [GROUP_QUERY]: (state, { payload }) => EntitiesReducerUtils.upsertAll({}, payload, normalizeGroup),
  [GROUP_GET_BY_ID]: (state, { payload: groups }) => EntitiesReducerUtils.upsertAll(state, groups, normalizeGroup),
  [GROUP_CREATE]: (state, { payload: { group } }) => EntitiesReducerUtils.upsert(state, group, normalizeGroup),
  [GROUP_GET_BY_USER]: (state, { payload }) => EntitiesReducerUtils.upsertAll({}, payload, normalizeGroup),
  [GROUP_DELETE]: (state, { meta: { id } }) => {
    const newState = Object.assign({}, state);
    delete newState[id];
    return newState;
  },
};

export const groupEntitiesReducer = composeReducerObjects(
  groupsReducerObject,
  appendOnPutReducerObject(GROUP_MEMBERSHIP_PUT, 'group', normalizeGroup),
);
