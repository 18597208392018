import { ButtonGroup, ButtonGroupProps } from 'components/design/next';
import React from 'react';

export const TriggersStack: React.FC<React.PropsWithChildren<Partial<ButtonGroupProps>>> = props => {
  return (
    <ButtonGroup
      h="13"
      alignItems="center"
      maxW="full"
      bg="brand.100"
      borderRadius="xl"
      w="auto"
      justifyContent="center"
      py="2"
      px="3"
      spacing="3"
      {...props}
    />
  );
};
