import { Plan } from '@process-street/subgrade/billing';
import { Option, Organization } from '@process-street/subgrade/core';
import { isT5KStartupPlan } from 'services/plans';

export const MIN_ORGANIZATION_CREATED_DAYS_FOR_SHARE = 7;
export const DAY_IN_MILIS = 24 * 60 * 60 * 1000;

/**
 * Determines if an organization is safe to share.
 * Returns true if organization is in T5K Startup plan and has at least 7-days age or
 * organization is in other plan.
 *
 * @returns {boolean}
 */
export function getIsOrganizationSafeToShare(organization: Option<Organization>, plan: Option<Plan>): boolean {
  const organiationCreatedDateMilis = organization?.audit.createdDate;

  if (!organiationCreatedDateMilis || !plan) {
    return false;
  }

  if (!isT5KStartupPlan(plan.id)) {
    // Only for our new trailists T5K plans, not enterprise (exactly as BE checks).
    return true;
  }

  const minimumDate = new Date(new Date().getTime() - MIN_ORGANIZATION_CREATED_DAYS_FOR_SHARE * DAY_IN_MILIS);

  return organiationCreatedDateMilis < minimumDate.getTime();
}
