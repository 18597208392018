import * as React from 'react';
import { GetFormSharesQuery } from 'features/forms/query-builder/get-form-shares-query';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Text } from 'components/design/next';
import { SharedItem } from 'pages/forms/_id/edit/components/send-link-via-email-modal/shared-item';

export type SharedListProps = {
  formShares: GetFormSharesQuery.FormShareWithResponse[];
};

export const SharedList: React.FC<React.PropsWithChildren<SharedListProps>> = ({ formShares }) => {
  return formShares.length > 0 ? (
    <Accordion w="full" allowToggle defaultIndex={0}>
      <AccordionItem marginBottom={2}>
        <AccordionButton w="auto" padding={0}>
          <Text variant="-2u" fontWeight="bold" color="gray.400">
            Shared
          </Text>
          <AccordionIcon fontSize="md" color="gray.400" />
        </AccordionButton>

        <AccordionPanel pl="1">
          {formShares.map(formShare => (
            <SharedItem formShare={formShare} key={formShare.formShare.id} />
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : (
    <></>
  );
};
