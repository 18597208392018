import { combineReducers } from 'redux';
import { handleActionsOnSuccess } from 'reducers/util';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { TASK_TEMPLATE_DELETE_ALL } from 'reducers/task-template/task-template.actions';

import { WIDGET_DELETE_BY_HEADER_ID } from 'components/widgets/store/widget.actions';
import {
  TASK_PERMISSION_RULE_DELETE_ALL_BY_IDS,
  TASK_PERMISSION_RULE_DELETE_BY_ID,
  TASK_PERMISSION_RULE_GET_ALL_BY_TEMPLATE_REVISION,
  TASK_PERMISSION_RULE_UPSERT,
  TASK_PERMISSION_RULE_UPSERT_ALL,
} from './task-permission-rule.actions';

export const taskPermissionRuleReducer = handleActionsOnSuccess(
  {
    [TASK_PERMISSION_RULE_GET_ALL_BY_TEMPLATE_REVISION]: (state, { payload }) =>
      EntitiesReducerUtils.upsertAll(state, payload),
    [TASK_PERMISSION_RULE_UPSERT]: (state, { payload }) => EntitiesReducerUtils.upsert(state, payload),
    [TASK_PERMISSION_RULE_UPSERT_ALL]: (state, { payload }) => EntitiesReducerUtils.upsertAll(state, payload),
    [TASK_PERMISSION_RULE_DELETE_BY_ID]: (state, { payload }) => EntitiesReducerUtils.delete(state, payload),
    [TASK_PERMISSION_RULE_DELETE_ALL_BY_IDS]: (state, { payload }) => EntitiesReducerUtils.deleteAll(state, payload),
    [TASK_TEMPLATE_DELETE_ALL]: (state, { meta: { relatedPermissionRules } }) =>
      EntitiesReducerUtils.deleteAll(state, relatedPermissionRules),
    [WIDGET_DELETE_BY_HEADER_ID]: (state, { meta: { relatedTaskPermissionRules } }) => {
      if (relatedTaskPermissionRules.length === 0) {
        return state;
      }
      return EntitiesReducerUtils.deleteAll(state, relatedTaskPermissionRules);
    },
  },
  {},
);

const taskPermissionRuleLookupByTaskTemplateGroupIdReducer = handleActionsOnSuccess(
  {
    [TASK_PERMISSION_RULE_GET_ALL_BY_TEMPLATE_REVISION]: (state, { payload: rules }) =>
      LookupsReducerUtils.replaceAll(state, rules, 'targetTaskTemplateGroup.id', 'id'),
    [TASK_PERMISSION_RULE_UPSERT]: (state, { payload: rule }) =>
      LookupsReducerUtils.upsert(state, rule, 'targetTaskTemplateGroup.id', 'id'),
    [TASK_PERMISSION_RULE_UPSERT_ALL]: (state, { payload: rules }) =>
      LookupsReducerUtils.upsertAll(state, rules, 'targetTaskTemplateGroup.id', 'id'),
    [TASK_PERMISSION_RULE_DELETE_BY_ID]: (state, { payload: rule }) =>
      LookupsReducerUtils.delete(state, rule, 'targetTaskTemplateGroup.id', 'id'),
    [TASK_PERMISSION_RULE_DELETE_ALL_BY_IDS]: (state, { payload: rules }) =>
      LookupsReducerUtils.deleteAll(state, rules, 'targetTaskTemplateGroup.id', 'id'),
    [TASK_TEMPLATE_DELETE_ALL]: (state, { meta: { taskTemplates } }) =>
      LookupsReducerUtils.deleteAllKeysByIds(
        state,
        taskTemplates.map(tt => tt.group.id),
      ),
    [WIDGET_DELETE_BY_HEADER_ID]: (state, { meta: { relatedTaskPermissionRules } }) => {
      if (relatedTaskPermissionRules.length === 0) {
        return state;
      }
      return LookupsReducerUtils.deleteAll(state, relatedTaskPermissionRules, 'targetTaskTemplateGroup.id', 'id');
    },
  },
  {},
);

const taskPermissionRuleLookupByTemplateRevisionIdReducer = handleActionsOnSuccess(
  {
    [TASK_PERMISSION_RULE_GET_ALL_BY_TEMPLATE_REVISION]: (state, { payload: rules }) =>
      LookupsReducerUtils.replaceAll(state, rules, 'templateRevision.id', 'id'),
    [TASK_PERMISSION_RULE_UPSERT]: (state, { payload: rule }) =>
      LookupsReducerUtils.upsert(state, rule, 'templateRevision.id', 'id'),
    [TASK_PERMISSION_RULE_UPSERT_ALL]: (state, { payload: rules }) =>
      LookupsReducerUtils.upsertAll(state, rules, 'templateRevision.id', 'id'),
    [TASK_PERMISSION_RULE_DELETE_BY_ID]: (state, { payload: rule }) =>
      LookupsReducerUtils.delete(state, rule, 'templateRevision.id', 'id'),
    [TASK_PERMISSION_RULE_DELETE_ALL_BY_IDS]: (state, { payload: rules }) =>
      LookupsReducerUtils.deleteAll(state, rules, 'templateRevision.id', 'id'),
    [TASK_TEMPLATE_DELETE_ALL]: (state, { meta: { relatedPermissionRules } }) =>
      LookupsReducerUtils.deleteAll(state, relatedPermissionRules, 'templateRevision.id', 'id'),
    [WIDGET_DELETE_BY_HEADER_ID]: (state, { meta: { templateRevisionId, relatedTaskPermissionRules } }) => {
      if (relatedTaskPermissionRules.length === 0) {
        return state;
      }

      return LookupsReducerUtils.deleteAllUsingSelectorFunctions(
        state,
        relatedTaskPermissionRules,
        () => templateRevisionId,
      );
    },
  },
  {},
);

export const taskPermissionRuleLookupsReducer = combineReducers({
  byTaskTemplateGroupId: taskPermissionRuleLookupByTaskTemplateGroupIdReducer,
  byTemplateRevisionId: taskPermissionRuleLookupByTemplateRevisionIdReducer,
});
