import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { UseInfiniteQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { useCurrentUser } from 'hooks/use-current-user';

export const useInboxItems = (
  params?: Partial<GetInboxItemsQuery.Params>,
  options?: UseInfiniteQueryOptions<GetInboxItemsQuery.Response, AxiosError>,
) => {
  const selectedOrganization = useSelectedOrganization();
  const user = useCurrentUser();
  const organizationId = params?.organizationId || selectedOrganization?.id;

  return GetInboxItemsQuery.useQuery(
    {
      userIds: [user?.id!],
      ...params,
      organizationId: organizationId!,
    },
    {
      keepPreviousData: true,
      ...options,
      enabled: Boolean(user?.id) && Boolean(organizationId) && options?.enabled !== false,
    },
  );
};
