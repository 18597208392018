import { PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { createUsableContext } from '@process-street/subgrade/util';
import { DerivedTaskStatus as Status } from '@process-street/subgrade/dashboard';
import { TaskStat } from './tooltip-state';

export type TChartContext = {
  getKey: (d: PieArcDatum<TaskStat>) => Status;
  getColor: (d: PieArcDatum<TaskStat>) => string;
};
export const [useChartContext, ChartContext] = createUsableContext<TChartContext>({
  hookName: 'useChartContext',
  providerName: 'ChartContext.Provider',
});
