import { CoverIcon, Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type AiGenerationCoverIconMutationParams = {
  templateRevisionId: Muid;
  name: string;
  retryCount: number;
};

type AiGenerationCoverIconMutationResult = CoverIcon;

export const AiGenerationCoverIconMutation = {
  key: ['ai-generation', 'emoji'],
  mutationFn: (params: AiGenerationCoverIconMutationParams) =>
    axiosService
      .getAxios()
      .post<AiGenerationCoverIconMutationResult>('/1/ai-generation/cover-icon', params)
      .then(r => r.data),
};

export const useAiGenerationCoverIconMutation = (
  options: UseMutationOptions<
    AiGenerationCoverIconMutationResult,
    AxiosError,
    AiGenerationCoverIconMutationParams
  > = {},
) => {
  return useMutation(AiGenerationCoverIconMutation.mutationFn, {
    mutationKey: AiGenerationCoverIconMutation.key,
    ...options,
  });
};
