import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { InitialGroupOrderComparatorParams } from '@ag-grid-community/core';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AgGridReactProps } from '@ag-grid-community/react';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { getRowId } from 'pages/tasks/helpers';
import React from 'react';
import { match, P } from 'ts-pattern';
import { TasksTableCellRenderer } from '../cell-renderer';
import { UNATTACHED_TASKS_GROUP } from './column-defs';
import { TasksTableUtils } from './tasks-table-utils';

const commonTableProps: Partial<AgGridReactProps<TasksTableUtils.TasksTableItem>> = {
  animateRows: false,
  getRowId: node => getRowId(node.data),
  infiniteInitialRowCount: 5,
  isFullWidthRow: params => TasksTableUtils.isFullWidthRow(params.rowNode.data),
  maxConcurrentDatasourceRequests: 1,
  suppressRowClickSelection: true,
};

export const useTableProps = ({ isMobile, hasMoreItems }: { isMobile: boolean; hasMoreItems: boolean }) => {
  const getNonGroupedTableProps = React.useCallback(
    (): Partial<AgGridReactProps<TasksTableUtils.TasksTableItem>> & { key: string } => ({
      ...commonTableProps,
      // The key is essential for triggering a table update when the screensize changes,
      // ensuring proper adjustment of row heights for mobile or desktop layouts.
      // Changing row model type requires a rerender of the grid, we trigger that through the key
      key: `ag-grid-${isMobile ? 'mobile' : 'desktop'}`,
      fullWidthCellRenderer: TasksTableCellRenderer.NewMenuRowRenderer,
      rowHeight: isMobile ? TasksTableUtils.ROW_HEIGHT_MOBILE_PX : TasksTableUtils.ROW_HEIGHT_DESKTOP_MY_WORK_GA_PX,
      headerHeight: TasksTableUtils.ROW_HEIGHT_DESKTOP_MY_WORK_GA_PX,
      modules: [InfiniteRowModelModule],
      rowModelType: 'infinite',
      rowSelection: 'multiple',
      cacheBlockSize: TasksTableUtils.TASKS_TABLE_PAGE_SIZE,
    }),
    [isMobile],
  );

  const getGroupedByDueDateTableProps = React.useCallback(
    ({
      hash,
    }: {
      hash?: TasksTableUtils.TasksTableHash;
    }): Partial<AgGridReactProps<TasksTableUtils.TasksTableItem>> & { key: string } => ({
      ...commonTableProps,
      key: `ag-grid-${isMobile ? 'mobile' : 'desktop'}due-date-grouped`,
      fullWidthCellRenderer: TasksTableCellRenderer.LoadMoreRowRenderer,
      getRowHeight: params => {
        if (params.node.group) {
          return TasksTableUtils.GROUP_ROW_HEIGHT_PX;
        }
        return isMobile ? TasksTableUtils.ROW_HEIGHT_MOBILE_PX : TasksTableUtils.ROW_HEIGHT_DESKTOP_MY_WORK_GA_PX;
      },
      groupAllowUnbalanced: true, // "load more row"
      groupDefaultExpanded: -1, // expand all groupings
      groupDisplayType: 'groupRows',
      groupRowRenderer: TasksTableCellRenderer.DueDateGroupRowRenderer,
      groupRowRendererParams: {
        hasMoreItems,
      },
      headerHeight: TasksTableUtils.ROW_HEIGHT_DESKTOP_MY_WORK_GA_PX,
      initialGroupOrderComparator: compareDueDateGroupOrder,
      isGroupOpenByDefault: ({ key }) => {
        return (
          match([hash, key])
            // Close "Overdue" when looking at "Upcoming"
            .with(
              [TasksTableUtils.TasksTableHash.Upcoming, TasksTableUtils.TasksTableDueDateGroup.Overdue.toString()],
              () => false,
            )
            // Close everything except "No Due Date" when looking at "No Due Date"
            .with(
              [
                TasksTableUtils.TasksTableHash.NoDueDate,
                P.not(TasksTableUtils.TasksTableDueDateGroup.NoDueDate.toString()),
              ],
              () => false,
            )
            .otherwise(() => true)
        );
      },
      maxConcurrentDatasourceRequests: 1,
      modules: [ClientSideRowModelModule, RowGroupingModule],
      rowModelType: 'clientSide',
      rowSelection: 'multiple',
      suppressGroupRowsSticky: true,
    }),
    [hasMoreItems, isMobile],
  );

  const getGroupedByWorkflowRunTableProps = React.useCallback(
    (): Partial<AgGridReactProps<TasksTableUtils.TasksTableItem>> & { key: string } => ({
      ...commonTableProps,
      key: `ag-grid-${isMobile ? 'mobile' : 'desktop'}workflow-run-grouped`,
      fullWidthCellRenderer: TasksTableCellRenderer.WorkflowRunGroupingFullRowRenderer,
      getRowHeight: params => {
        if (params.node.group) {
          // +5 to have space to render divider after group title
          return TasksTableUtils.GROUP_ROW_HEIGHT_PX + 5;
        }

        return isMobile ? TasksTableUtils.ROW_HEIGHT_MOBILE_PX : TasksTableUtils.ROW_HEIGHT_DESKTOP_MY_WORK_GA_PX;
      },
      groupAllowUnbalanced: true, // "load more row"
      groupDefaultExpanded: -1, // expand all groupings
      groupDisplayType: 'groupRows',
      groupRowRenderer: TasksTableCellRenderer.WorkflowRunGroupRowRenderer,
      groupRowRendererParams: (params: any) => {
        const firstChildWithChecklist = params.node.childrenAfterGroup.find(
          (child: any) => child?.data?.checklist !== undefined,
        );
        const firstChildWithTemplate = params.node.childrenAfterGroup.find(
          (child: any) => child?.data?.template !== undefined,
        );

        const checklist = firstChildWithChecklist ? firstChildWithChecklist.data.checklist : null;
        const template = firstChildWithTemplate ? firstChildWithTemplate.data.template : null;

        return {
          ...params,
          checklist,
          template,
          hasMoreItems,
        };
      },
      headerHeight: TasksTableUtils.ROW_HEIGHT_DESKTOP_MY_WORK_GA_PX,
      initialGroupOrderComparator: compareWorkflowRunGroupOrder,
      modules: [ClientSideRowModelModule, RowGroupingModule],
      rowModelType: 'clientSide',
      rowSelection: 'multiple',
      suppressGroupRowsSticky: true,
    }),
    [hasMoreItems, isMobile],
  );

  return {
    getNonGroupedTableProps,
    getGroupedByDueDateTableProps,
    getGroupedByWorkflowRunTableProps,
  };
};

function compareDueDateGroupOrder(params: InitialGroupOrderComparatorParams<TasksTableUtils.TasksTableItem>) {
  if (TasksTableUtils.isFullWidthRow(params.nodeA.data)) {
    // "Load more" button goes last, it's "greater than" everything
    return 1;
  } else if (TasksTableUtils.isFullWidthRow(params.nodeB.data)) {
    return -1;
  }
  return (params.nodeA.key ?? '') > (params.nodeB.key ?? '') ? 1 : -1;
}

function compareWorkflowRunGroupOrder(params: InitialGroupOrderComparatorParams<TasksTableUtils.TasksTableItem>) {
  const { nodeA, nodeB } = params;
  const keyA = nodeA.key ?? '';
  const keyB = nodeB.key ?? '';

  // Check for full-width rows ("Load more" button) which should always go last
  if (TasksTableUtils.isFullWidthRow(nodeA.data) || TasksTableUtils.isFullWidthRow(nodeB.data)) {
    return TasksTableUtils.isFullWidthRow(nodeA.data) ? 1 : -1;
  }

  // Determine if keys are the UNATTACHED_TASKS_GROUP
  const isUnattachedA = keyA === UNATTACHED_TASKS_GROUP;
  const isUnattachedB = keyB === UNATTACHED_TASKS_GROUP;

  // Prioritize unattached groups to go last unless both are unattached
  if (isUnattachedA || isUnattachedB) {
    return isUnattachedA ? 1 : -1;
  }

  // Compare based on group names for all other rows
  return keyA > keyB ? 1 : -1;
}
