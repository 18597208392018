import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { UserUtils } from '@process-street/subgrade/util';
import { UserValue } from 'components/dashboard/models/grid';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { createSelector } from 'reselect';

export const getUserValueMapSelector = createSelector(
  OrganizationMembershipSelector.getAllAssignableBySelectedOrganizationId,
  allMemberships => {
    return allMemberships.reduce((userValueMap, membership: OrganizationMembershipWithUser) => {
      userValueMap[membership.id] = {
        id: membership.user.id,
        organizationMembershipId: membership.id,
        avatarUrl: UserUtils.extractAvatarUrl(membership.user, 64),
        initials: UserUtils.extractInitials(membership.user),
      };
      return userValueMap;
    }, {} as { [index: string]: UserValue });
  },
);

export const GridSelectors = {
  getUserValueMapSelector,
};
