import { FieldType, DueDateRuleOffsetDirection, DueDateRuleSourceType } from '@process-street/subgrade/process';
import { trace } from 'components/trace';

export class DueDateRuleDefinitionValidationService {
  constructor(TaskTemplateService, util) {
    'ngInject';

    this.logger = trace({ name: 'DueDateRuleDefinitionValidationService' });
    this.TaskTemplateService = TaskTemplateService;
    this.util = util;

    this.taskTemplateComparer = util.getOrderableComparer();
    this._initOffsetDirectionAvailabilityMap();
  }

  /**
   * Throws excpetion in case of validation error
   * @param rule
   * @param taskTemplates
   * @param formFieldWidgets
   */
  validate(rule, taskTemplates, formFieldWidgets) {
    this.validateRuleObjectStructure(rule);
    this.validateOffsetDirection(rule);

    if (
      rule.targetTaskTemplateGroup &&
      !this.doesTaskTemplateExistByGroupId(taskTemplates, rule.targetTaskTemplateGroup.id)
    ) {
      throw new ReferenceError('target task is not one of the existing tasks');
    }

    switch (rule.sourceType) {
      case DueDateRuleSourceType.FormFieldValue:
        this._validateForFormFieldValueCase(rule, formFieldWidgets);
        return;
      case DueDateRuleSourceType.TaskCompletedDate:
        this._validateForTaskCompletedDateCase(rule, taskTemplates);
        return;
      case DueDateRuleSourceType.PreviousTaskCompletedDate:
        this._validateForPreviousTaskCompletedDateCase(rule, taskTemplates);
        return;
      case DueDateRuleSourceType.TaskDueDate:
        this._validateForTaskDueDateCase(rule, taskTemplates);
        return;
      case DueDateRuleSourceType.ChecklistStartDate:
        return;
      case DueDateRuleSourceType.ChecklistDueDate:
        return;
      default:
        throw new Error(`unsupported source type ${rule.sourceType} for the rule`);
    }
  }

  _validateRuleValue(value, valueName, rule) {
    if (!value) {
      throw new ReferenceError(`rule error - ${valueName} not defined (ruleId=${rule.id})`);
    }
  }

  _validateForFormFieldValueCase(rule, formFieldWidgets) {
    const formFieldWidgetGroupId = rule.formFieldWidgetGroup && rule.formFieldWidgetGroup.id;

    this._validateRuleValue(formFieldWidgetGroupId, 'formFieldWidgetGroupId', rule);

    const matchingFormFieldWidget = formFieldWidgets.find(
      ffw => ffw.header && ffw.header.group && ffw.header.group.id === formFieldWidgetGroupId,
    );

    if (!matchingFormFieldWidget) {
      throw new ReferenceError(`form field widget not found (ruleId=${rule.id},
             formFieldWidgetGroupId=${formFieldWidgetGroupId})`);
    }

    if (matchingFormFieldWidget.fieldType !== FieldType.Date) {
      throw new ReferenceError(`form field widget has wrong type: ${matchingFormFieldWidget.fieldType}
            (ruleId=${rule.id})`);
    }
  }

  _validateForTaskCompletedDateCase(rule, taskTemplates) {
    const taskTemplateGroupId = rule.taskTemplateGroup && rule.taskTemplateGroup.id;

    this._validateRuleValue(taskTemplateGroupId, 'taskTemplateGroupId', rule);

    const found = taskTemplates.some(tt => tt.group && tt.group.id === taskTemplateGroupId);
    if (!found) {
      throw new ReferenceError(`task template not found (ruleId=${rule.id},
            taskTemplateGroupId=${taskTemplateGroupId})`);
    }
  }

  _validateForPreviousTaskCompletedDateCase(rule, taskTemplates) {
    const targetTaskTemplateGroupId = rule.targetTaskTemplateGroup && rule.targetTaskTemplateGroup.id;

    this._validateRuleValue(targetTaskTemplateGroupId, 'targetTaskTemplateGroupId', rule);

    const previousTaskTemplate =
      rule.targetTaskTemplateGroup && this.findPreviousTaskTemplate(taskTemplates, rule.targetTaskTemplateGroup.id);

    if (!previousTaskTemplate) {
      throw new ReferenceError(`There is no previous task. Choose another date. (ruleId=${rule.id})`);
    }
  }

  findPreviousTaskTemplate(taskTemplates, targetTaskTemplateGroupId) {
    const sortedTaskTemplatesExcludingHeading = taskTemplates
      .filter(tt => !this.TaskTemplateService.isHeading(tt))
      .sort(this.taskTemplateComparer);

    const allTaskTemplateGroupIds = sortedTaskTemplatesExcludingHeading.map(tt => tt.group.id);

    const targetTaskTemplateIndex = allTaskTemplateGroupIds.indexOf(targetTaskTemplateGroupId);

    if (targetTaskTemplateIndex > 0) {
      return sortedTaskTemplatesExcludingHeading[targetTaskTemplateIndex - 1];
    } else {
      return undefined;
    }
  }

  _validateForTaskDueDateCase(rule, taskTemplates) {
    const taskTemplateGroupId = rule.taskTemplateGroup && rule.taskTemplateGroup.id;

    this._validateRuleValue(taskTemplateGroupId, 'taskTemplateGroupId', rule);

    const found = taskTemplates.some(tt => tt.group && tt.group.id === taskTemplateGroupId);
    if (!found) {
      throw new ReferenceError(`task template not found (ruleId=${rule.id})`);
    }
  }

  _initOffsetDirectionAvailabilityMap() {
    const OffsetDirectionAvailabilityMap = {};

    OffsetDirectionAvailabilityMap[DueDateRuleSourceType.FormFieldValue] = [
      DueDateRuleOffsetDirection.Before,
      DueDateRuleOffsetDirection.After,
    ];
    OffsetDirectionAvailabilityMap[DueDateRuleSourceType.TaskCompletedDate] = [DueDateRuleOffsetDirection.After];
    OffsetDirectionAvailabilityMap[DueDateRuleSourceType.PreviousTaskCompletedDate] = [
      DueDateRuleOffsetDirection.After,
    ];
    OffsetDirectionAvailabilityMap[DueDateRuleSourceType.TaskDueDate] = [
      DueDateRuleOffsetDirection.Before,
      DueDateRuleOffsetDirection.After,
    ];
    OffsetDirectionAvailabilityMap[DueDateRuleSourceType.ChecklistStartDate] = [DueDateRuleOffsetDirection.After];
    OffsetDirectionAvailabilityMap[DueDateRuleSourceType.ChecklistDueDate] = [DueDateRuleOffsetDirection.Before];

    this.OffsetDirectionAvailabilityMap = OffsetDirectionAvailabilityMap;
  }

  validateOffsetDirection(rule) {
    if (
      !this.OffsetDirectionAvailabilityMap[rule.sourceType] ||
      !this.OffsetDirectionAvailabilityMap[rule.sourceType].includes(rule.offsetDirection)
    ) {
      throw new ReferenceError(`offsetDirection is invalid (ruleId=${rule.id},
            offsetDirection=${rule.offsetDirection})`);
    }
  }

  doesTaskTemplateExistByGroupId(taskTemplates, taskTemplateGroupId) {
    return taskTemplates.findIndex(tt => tt.group.id === taskTemplateGroupId) !== -1;
  }

  validateRuleObjectStructure(rule) {
    this.logger.info('validating due date rule ', rule);

    if (!rule) {
      throw new ReferenceError('due date rule must be defined');
    }

    if (!rule.sourceType) {
      throw new ReferenceError('source type must be defined');
    }

    const allowedSourceTypes = Object.keys(DueDateRuleSourceType).map(key => DueDateRuleSourceType[key]);
    if (!allowedSourceTypes.includes(rule.sourceType)) {
      throw new ReferenceError(`source type [${rule.sourceType}] is not valid`);
    }

    const allowedOffsetDirections = [DueDateRuleOffsetDirection.Before, DueDateRuleOffsetDirection.After];
    if (!rule.offsetDirection) {
      throw new ReferenceError('offsetDirection is not defined');
    } else if (!allowedOffsetDirections.includes(rule.offsetDirection)) {
      throw new ReferenceError(`offsetDirection [${rule.offsetDirection}] is invalid`);
    }
  }
}
