import { Muid } from '@process-street/subgrade/core';
import {
  FormFieldValue,
  FormFieldValueUpdateRequest,
  FormFieldValueUpdateResult,
} from '@process-street/subgrade/process';
import { AngularResource } from 'reducers/angular-types';
import angular from 'angular';

interface FormFieldValueResource {
  getAllByChecklistRevisionId(request: { id: Muid }): AngularResource<FormFieldValue[]>;
  updateFormFieldValue(request: FormFieldValueUpdateRequest): AngularResource<FormFieldValueUpdateResult>;
  deleteFormFieldValue(request: { id: Muid; widgetId: Muid }): AngularResource<FormFieldValueUpdateResult>;
}

export interface FormFieldValueApi {
  getAllByChecklistRevisionId(id: Muid): angular.IPromise<FormFieldValue[]>;
  updateFormFieldValue(
    fieldValue: FormFieldValue,
    id: Muid,
    widgetId: Muid,
  ): angular.IPromise<FormFieldValueUpdateResult>;
  deleteFormFieldValue(id: Muid, widgetId: Muid): angular.IPromise<FormFieldValueUpdateResult>;
}

// TODO
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error -- TODO
export const FormFieldValueApiImpl = function (resourceFactory): FormFieldValueApi {
  const formFieldValueResource: FormFieldValueResource = resourceFactory.create({
    deleteFormFieldValue: 'DELETE /1/checklist-revisions/:id/widgets/:widgetId/form-field-value',
    getAllByChecklistRevisionId: 'GET[] /1/checklist-revisions/:id/form-field-values',
    updateFormFieldValue: 'PUT /1/checklist-revisions/:id/widgets/:widgetId/form-field-value',
  });

  const getAllByChecklistRevisionId = (revisionId: Muid) =>
    formFieldValueResource.getAllByChecklistRevisionId({ id: revisionId }).$promise;

  const updateFormFieldValue = (fieldValue: FormFieldValue, checklistRevisionId: Muid, widgetId: Muid) => {
    const request: FormFieldValueUpdateRequest = Object.assign({ id: checklistRevisionId, widgetId }, fieldValue);
    return formFieldValueResource.updateFormFieldValue(request).$promise;
  };

  const deleteFormFieldValue = (revisionId: Muid, widgetId: Muid) =>
    formFieldValueResource.deleteFormFieldValue({ widgetId, id: revisionId }).$promise;

  return {
    deleteFormFieldValue,
    getAllByChecklistRevisionId,
    updateFormFieldValue,
  };
};
