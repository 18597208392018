import { Box, Center, useRadio, UseRadioProps } from 'components/design/next';
import * as React from 'react';
import { ActiveIndicator } from 'pages/organizations/manage/billing/components/duration-switcher/ActiveIndicator';

export const RadioButton = (props: UseRadioProps & { children?: React.ReactNode }) => {
  const { getInputProps, getRadioProps, getLabelProps, state } = useRadio(props);
  return (
    <Box as="label" pos="relative" {...getLabelProps()} mb={0}>
      <input {...getInputProps()} />
      <Center
        {...getRadioProps()}
        cursor="pointer"
        pos="relative"
        zIndex={1}
        h="12"
        px="8"
        textAlign="center"
        transition="all 0.2s"
        minW="8rem"
        fontWeight="medium"
        color="gray.600"
        _checked={{
          color: 'brand.600',
          fontWeight: 'bold',
        }}
      >
        {props.children}
      </Center>
      {state.isChecked && <ActiveIndicator bg="white" layoutId="highlight" transition={{ duration: '0.1' }} />}
    </Box>
  );
};
