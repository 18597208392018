import { getTagLabelsFromDataSetMergeTags, getTagsFromKeys } from './get-tags-from-keys';
import { EMPTY_MERGE_TAG_CLASS, injectMergeTagLabels } from './inject-merge-tag-labels';
import { getAllTextNodesAsSpans } from './get-all-text-nodes-as-spans';
import { replaceInTextSpansInPlace } from './replace-in-text-spans-in-place';
import { replaceResolvedTagsWithHtmlValues } from './replace-resolved-tags-with-html-values';
import { replaceResolvedTagsWithPlaintextValues } from './replace-resolved-tags-with-plaintext-values';
import { generatePatternByKey } from './generate-pattern-by-key';
import { applyFilter } from './apply-filter';
import { buildResolvedTags } from './build-resolved-tags';

export const MergeTagsServiceUtils = {
  EMPTY_MERGE_TAG_CLASS,
  buildResolvedTags,
  getAllTextNodesAsSpans,
  getTagsFromKeys,
  getTagLabelsFromDataSetMergeTags,
  injectMergeTagLabels,
  replaceInTextSpansInPlace,
  generatePatternByKey,
  applyFilter,
  replaceResolvedTagsWithHtmlValues,
  replaceResolvedTagsWithPlaintextValues,
};

export type { Tags } from './get-tags-from-keys';
