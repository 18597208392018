import { NumberFormFieldWidget } from '@process-street/subgrade/process';
import * as yup from 'yup';
import { SettingsSchemaValidators } from 'components/widgets/form-field/settings/common/settings-schema-validators';
import { Option } from 'space-monad';

export type NumberSettingsSchema = NumberFormFieldWidget['constraints'] &
  NumberFormFieldWidget['config'] & {
    hasVariables: boolean;
  };

const { min: minDigits, max: maxDigits } = SettingsSchemaValidators.minMax({
  minConst: 0,
  maxConst: Number.MAX_VALUE,
  maxKey: 'maxDigits',
});
const { min: minValue, max: maxValue } = SettingsSchemaValidators.minMax({
  minConst: Number.MIN_SAFE_INTEGER,
  maxConst: Number.MAX_VALUE,
  maxKey: 'maxValue',
});

export const numberSettingsSchema = yup.object<NumberSettingsSchema>({
  allowNegative: yup.boolean(),
  minDigits,
  maxDigits,
  minValue,
  maxValue,
  placeholder: SettingsSchemaValidators.placeholder(60),
  // this is a transient/hidden value used to drive a dynamic validation
  // inspiration: https://stackoverflow.com/questions/65613573/yup-how-to-validate-field-only-when-it-exists
  hasVariables: yup.boolean(),
  defaultValue: yup.string().when('hasVariables', {
    is: true,
    otherwise: (schema: yup.StringSchema) =>
      schema
        .test(
          'isNumber',
          'Default value must be a number or contain variables.',
          value => isEmpty(value) || !Number.isNaN(toNumber(value)),
        )
        .when('minValue', (min: number | undefined, schema: yup.StringSchema) =>
          typeof min === 'number'
            ? schema.test(
                'minValue',
                `Default value must be at least ${min}.`,
                value => isEmpty(value) || toNumber(value) >= min,
              )
            : schema,
        )
        .when('maxValue', (max: number | undefined, schema: yup.StringSchema) =>
          typeof max === 'number'
            ? schema.test(
                'maxValue',
                `Default value must be less than ${max}.`,
                value => isEmpty(value) || toNumber(value) <= max,
              )
            : schema,
        )
        .when('minDigits', (minDigits: number | undefined, schema: yup.StringSchema) =>
          typeof minDigits === 'number'
            ? schema
                .transform((v: string) => v.replace(/[.-]/, ''))
                .min(minDigits, `Default value must be at least ${minDigits} digits.`)
            : schema,
        )
        .when('maxDigits', (maxDigits: number | undefined, schema: yup.StringSchema) =>
          typeof maxDigits === 'number'
            ? schema
                .transform((v: string) => v.split('.')?.[0].replace(/-/, ''))
                .max(maxDigits, `Default value must be at most ${maxDigits} digits.`)
            : schema,
        )
        .when('decimalPlaces', {
          is: d => !d,
          then: (schema: yup.StringSchema) =>
            schema.test(
              'decimalPlacesNotAllowed',
              'Decimal places are not permitted for default field.',
              value => isEmpty(value) || getDecimalsCount(value) === 0,
            ),
        })
        .when('decimalPlaces', {
          is: d => d === 1,
          then: (schema: yup.StringSchema) =>
            schema.test(
              'singleDecimalPlace',
              '1 decimal place required for default field.',
              value => isEmpty(value) || getDecimalsCount(value) === 1,
            ),
        })
        .when('decimalPlaces', (decimalPlaces = 0, schema: yup.StringSchema) =>
          decimalPlaces > 1
            ? schema.test(
                'multipleDecimalPlaces',
                `${decimalPlaces} decimal places required for default field.`,
                value => isEmpty(value) || getDecimalsCount(value) === decimalPlaces,
              )
            : schema,
        )
        .when('allowNegative', (allowNegative: boolean, schema: yup.StringSchema) =>
          !allowNegative
            ? schema.test(
                'allowNegative',
                'Negative numbers are not permitted in default field.',
                value => isEmpty(value) || toNumber(value) >= 0,
              )
            : schema,
        ),
  }),
  decimalPlaces: yup
    .number()
    .transform(n => (!n ? 0 : n))
    .min(0)
    .max(3),
});

function toNumber(value?: string | null): number {
  return Number.parseFloat(value ?? '');
}

function getDecimalsCount(value?: string | null): number {
  return Option(value?.split('.')[1])
    .map(d => d.length)
    .getOrElse(0);
}

function isEmpty(value?: string | null): boolean {
  return (value ?? '') === '';
}
