const Error = {
  MAX_NUMBER_OF_FILES_EXCEEDED: 'Maximum number of files exceeded',
  FILE_TYPE_NOT_ALLOWED: 'File type not allowed',
  FILE_TOO_LARGE: 'File is too large',
  FILE_TOO_SMALL: 'File is too small',
};

export const FileUploadConstants = {
  Error,
  Abort: 'abort',
};
