import {
  TEMPLATE_PERMIT_DELETE,
  TEMPLATE_PERMIT_GET_ALL_BY_ORGANIZATION_ID,
  TEMPLATE_PERMIT_PUT,
} from 'reducers/template-permit/template-permit.actions';
import { entitiesCollectionReducerObject, referencesNormalizer } from 'reducers/entities/entities.utils';
import { composeReducerObjects } from 'reducers/util';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import {
  CREATE_TEMPLATE_PERMIT,
  DELETE_TEMPLATE_PERMIT,
  UPDATE_TEMPLATE_PERMIT,
  TEMPLATE_PERMITS_GET_ALL_BY_TEMPLATE_ID,
  TEMPLATE_PERMITS_GET_EXISTING_AND_NEW_BY_TEMPLATE_ID,
} from 'components/template/membership/store/template-membership.actions';
import { toSuccess } from 'reducers/util/handlers';

const normalizeTemplatePermit = referencesNormalizer(['organization', 'organizationMembership', 'template']);

export const templatePermitEntitiesReducer = composeReducerObjects(
  entitiesCollectionReducerObject(TEMPLATE_PERMIT_PUT, TEMPLATE_PERMIT_DELETE, normalizeTemplatePermit),

  {
    [TEMPLATE_PERMIT_GET_ALL_BY_ORGANIZATION_ID]: (state, { payload: templatePermits }) =>
      EntitiesReducerUtils.upsertAll(state, templatePermits, normalizeTemplatePermit),

    [toSuccess(TEMPLATE_PERMITS_GET_ALL_BY_TEMPLATE_ID)]: (state, { payload: paginatedResponse }) =>
      EntitiesReducerUtils.upsertAll(state, paginatedResponse.response.data, normalizeTemplatePermit),
    [toSuccess(TEMPLATE_PERMITS_GET_EXISTING_AND_NEW_BY_TEMPLATE_ID)]: (state, { payload: paginatedResponse }) =>
      EntitiesReducerUtils.upsertAll(state, paginatedResponse.response.data, normalizeTemplatePermit),
    [toSuccess(DELETE_TEMPLATE_PERMIT)]: (state, { payload }) =>
      EntitiesReducerUtils.deleteById(state, payload.permitId),
    [toSuccess(CREATE_TEMPLATE_PERMIT)]: (state, { payload }) =>
      EntitiesReducerUtils.upsert(state, payload.permit, normalizeTemplatePermit),
    [toSuccess(UPDATE_TEMPLATE_PERMIT)]: (state, { payload }) =>
      EntitiesReducerUtils.upsert(state, payload.permit, normalizeTemplatePermit),
  },
);
