import angular from 'angular';

angular
  .module('frontStreetApp.controllers')
  .controller('EmailVerificationCtrl', function ($scope, $state, $stateParams, UserService) {
    if ($stateParams.codeId) {
      UserService.validateEmailVerification($stateParams.codeId)
        .then(() => {
          $scope.outcome = 'success';
        })
        .catch(() => {
          $scope.outcome = 'failure';
        });
    } else {
      $scope.outcome = 'failure';
    }

    $scope.redirect = function () {
      $state.go('reports');
    };
  });
