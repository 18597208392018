import { CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select';
import { useToken } from '@chakra-ui/react';

/**
 * For each component:
 * 1. Start with base styles received from react-select
 * 2. Add BlvdSelect overrides
 * 3. Pass these on to the component override in `styles` prop received by BlvdSelect (if exists)
 *
 * Note: BlvdSelect.scss always takes precedence, we should gradually move styles over to here.
 */
export function useBlvdSelectStyles<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(overrides: StylesConfig<Option, IsMulti, Group> = {}): StylesConfig<Option, IsMulti, Group> {
  const [gray100, gray300, gray400, gray500, gray600, brand500] = useToken('colors', [
    'gray.100',
    'gray.300',
    'gray.400',
    'gray.500',
    'gray.600',
    'brand.500',
  ]);
  const [size2, size3, size7] = useToken('sizes', ['2', '3', '7']);
  const [fontMd] = useToken('fontSizes', ['md']);

  return {
    ...overrides,
    placeholder: (s, state) => {
      const styles: CSSObjectWithLabel = {
        ...s,
        color: gray600,
        whiteSpace: 'nowrap',
        fontSize: fontMd,
      };
      return overrides.placeholder ? overrides.placeholder(styles, state) : styles;
    },
    control: (s, state) => {
      const styles: CSSObjectWithLabel = {
        ...s,
        'borderColor': state.isFocused ? brand500 : gray300,
        'boxShadow': state.isFocused ? `0 0 0 1px ${brand500}` : 'none',
        ':hover': {
          borderColor: state.isFocused ? brand500 : gray400,
        },
        'p': {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      };
      return overrides.control ? overrides.control(styles, state) : styles;
    },
    dropdownIndicator: (s, state) => {
      const styles: CSSObjectWithLabel = {
        ...s,
        'padding': `11px ${size3}`,
        'color': gray500,
        ':hover': {
          color: gray600,
        },
      };
      return overrides.dropdownIndicator ? overrides.dropdownIndicator(styles, state) : styles;
    },
    valueContainer: (s, state) => {
      const styles: CSSObjectWithLabel = {
        ...s,
        justifyContent: 'flex-start',
        padding: `0 ${size2}`,
      };
      return overrides.valueContainer ? overrides.valueContainer(styles, state) : styles;
    },
    // accommodate to predefined item height if menu list is virtualized
    option: (s, state) => {
      const styles: CSSObjectWithLabel = { ...s, height: '100%' };
      return overrides.option ? overrides.option(styles, state) : styles;
    },
    multiValue: (s, state) => {
      const styles: CSSObjectWithLabel = {
        ...s,
        backgroundColor: gray100,
        height: size7,
        marginTop: 0,
      };
      return overrides.multiValue ? overrides.multiValue(styles, state) : styles;
    },
    multiValueLabel: (s, state) => {
      const styles: CSSObjectWithLabel = {
        ...s,
        alignItems: 'center',
        color: gray600,
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: '150px',
        overflow: 'hidden',
        padding: '0 4px',
        fontSize: fontMd,
      };
      return overrides.multiValueLabel ? overrides.multiValueLabel(styles, state) : styles;
    },
  };
}
