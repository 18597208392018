import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { SignInWithProviderButton } from './components/sign-in-with-provider-button';

export const LoginModule = angular
  .module('psLogin', [])
  .component(
    'psSignInWithProviderButton',
    reactToAngular(SignInWithProviderButton, ['showPopupIcon', 'onClick', 'providerName', 'children']),
  ).name;
