import * as React from 'react';
import { HStack, StackProps } from 'components/design/next';

export const ShellContentActions: React.FC<React.PropsWithChildren<StackProps>> = ({ children, ...props }) => {
  return (
    <HStack bottom="0" position="sticky" pb="2" bg="white" spacing="4" w="full" justifyContent="flex-end" {...props}>
      {children}
    </HStack>
  );
};
