import * as React from 'react';
import { FormControl, Icon, Link, Switch, Text, Tooltip, useToast } from 'components/design/next';
import { TemplateRevision } from '@process-street/subgrade/process';
import { UpdateHasProcessStreetBrandingMutation } from 'features/template-revisions/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { PlanLevel } from '@process-street/subgrade/billing';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { useQueryClient } from 'react-query';
import { useInjector } from 'components/injection-provider';

export type BrandingSwitchProps = {
  templateRevision: TemplateRevision;
};

export const BrandingSwitch: React.FC<React.PropsWithChildren<BrandingSwitchProps>> = ({ templateRevision }) => {
  const [hasProcessStreetBranding, setProcessStreetBranding] = React.useState(
    templateRevision.hasProcessStreetBranding,
  );
  const queryClient = useQueryClient();
  const toast = useToast();
  const toastRef = React.useRef<ReturnType<typeof toast>>();
  const { $state } = useInjector('$state');

  const updateProcessStreetBrandingMutation = UpdateHasProcessStreetBrandingMutation.useMutation({
    onSuccess: revision => {
      setProcessStreetBranding(revision.hasProcessStreetBranding);
      templateRevisionCacheSetter.updateDraftLastUpdatedDate();
    },
    onError: () => {
      toast({
        status: 'error',
        title: `We're having problems updating branding.`,
        description: 'Please try again in a moment or contact support.',
      });
      setProcessStreetBranding(templateRevision.hasProcessStreetBranding);
    },
  });

  const selectedPlan = useSelector(SessionSelector.getCurrentPlan);
  const switchEnabled = !!selectedPlan && [PlanLevel.Professional, PlanLevel.Enterprise].includes(selectedPlan.level);

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId: templateRevision.template.id,
  });

  const href = $state.href('organizationManage.tab', { tab: 'billing' });

  const updateProcessStreetBranding = () => {
    if (!switchEnabled) {
      toastRef.current = toast({
        status: 'warning',
        title: (
          <Text>
            <Link
              {...{
                href,
                textDecor: 'underline',
                onClick: () => toastRef.current && toast.close(toastRef.current),
              }}
            >
              Upgrade
            </Link>{' '}
            your plan to remove Process Street branding and replace it with your own logo.
            <br />
            Available on these plans: Pro, Enterprise.
          </Text>
        ),
      });
      return;
    }

    const updatedProcessStreetBranding = !hasProcessStreetBranding;
    setProcessStreetBranding(updatedProcessStreetBranding);

    updateProcessStreetBrandingMutation.mutateAsync({
      templateRevisionId: templateRevision.id,
      hasProcessStreetBranding: updatedProcessStreetBranding,
    });
  };

  return (
    <FormControl pt="4">
      <Switch
        size="lg"
        spacing="2"
        display="flex"
        alignItems="center"
        isChecked={hasProcessStreetBranding}
        onChange={updateProcessStreetBranding}
      >
        <Text variant="1" fontWeight="normal" color="gray.500" as="span">
          Process Street branding
        </Text>
        {!switchEnabled && (
          <Tooltip
            label={
              'Upgrade your plan to remove Process Street branding and replace it with your own logo.\n' +
              'Available on these plans: Pro, Enterprise.'
            }
            shouldWrapChildren
            hasArrow
          >
            <Icon icon="info-circle" size="4" variant="far" color="gray.400" ml="2" />
          </Tooltip>
        )}
      </Switch>
    </FormControl>
  );
};
