import { Muid } from '@process-street/subgrade/core';
import { queryClient } from 'components/react-root';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { ChecklistRevisionApi } from 'reducers/checklist-revision/checklist-revision.api';
import { createCachedAction } from 'reducers/util/index';

export const CHECKLIST_REVISION_QUERY = 'checklist/revision/QUERY';
export const CHECKLIST_REVISION_COUNTS = 'checklist/revision/COUNTS';
export const CHECKLIST_REVISION_SCHEDULE_ONE_MIGRATION = 'checklist/revision/SCHEDULE_ONE_MIGRATION';
export const CHECKLIST_REVISION_MIGRATE_ONE_WITH_PRIORITY = 'checklist/revision/MIGRATE_ONE_WITH_PRIORITY';
export const CHECKLIST_REVISION_GET = 'checklist/revision/GET';
export const CHECKLIST_REVISION_GET_ACTIVE_BY_CHECKLIST = 'checklist/revision/GET_ACTIVE_BY_CHECKLIST';

export class ChecklistRevisionActions {
  public query = createCachedAction(CHECKLIST_REVISION_QUERY, (params: { where: string; include: string }) =>
    this.checklistRevisionApi.query(params),
  );

  public counts = createCachedAction(CHECKLIST_REVISION_COUNTS, () => this.checklistRevisionApi.counts());

  public scheduleOneMigrationByChecklistRevisionId = createCachedAction(
    CHECKLIST_REVISION_SCHEDULE_ONE_MIGRATION,
    (id: Muid) => this.checklistRevisionApi.scheduleOneMigrationByChecklistRevisionId(id),
  );

  public migrateOneWithPriorityByChecklistRevisionId = createCachedAction(
    CHECKLIST_REVISION_MIGRATE_ONE_WITH_PRIORITY,
    (id: Muid) => this.checklistRevisionApi.migrateOneWithPriorityByChecklistRevisionId(id),
  );

  public get = createCachedAction(CHECKLIST_REVISION_GET, (id: Muid) => this.checklistRevisionApi.get(id));

  public getActiveByChecklistId = createCachedAction(CHECKLIST_REVISION_GET_ACTIVE_BY_CHECKLIST, (id: Muid) =>
    queryClient.fetchQuery({
      queryKey: GetActiveChecklistRevisionByChecklistIdQuery.getKey({ checklistId: id }),
      queryFn: () => GetActiveChecklistRevisionByChecklistIdQuery.queryFn({ checklistId: id }),
      staleTime: 1000, // Not having fresh the active checklist revision causes bugs, for example in the migration modal.
    }),
  );

  static $inject = ['ChecklistRevisionApi'];

  constructor(private checklistRevisionApi: ChecklistRevisionApi) {}
}
