import { Task } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UploadUrlChecklistEmailAttachmentMutationParams = {
  taskId: Task['id'];
  fileName: string;
  mimeType: string;
};

export type UploadUrlChecklistEmailAttachmentMutationResponse = { url: string; key: string };

export const UploadUrlChecklistEmailAttachmentMutation = {
  key: ['email-attachments', 'checklist-upload-url'],
  mutationFn: (params: UploadUrlChecklistEmailAttachmentMutationParams) =>
    axiosService
      .getAxios()
      .post<UploadUrlChecklistEmailAttachmentMutationResponse>(`/1/email-attachments/checklist-upload-url`, params)
      .then(res => res.data),
};

export const useUploadUrlChecklistEmailAttachmentMutation = (
  options: UseMutationOptions<
    UploadUrlChecklistEmailAttachmentMutationResponse,
    AxiosError,
    UploadUrlChecklistEmailAttachmentMutationParams
  > = {},
) => {
  return useMutation(UploadUrlChecklistEmailAttachmentMutation.mutationFn, options);
};
