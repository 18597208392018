import { HttpStatus } from '@process-street/subgrade/util';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useToast } from 'components/design/next';

function showWorkflowViewErrorToast(responseStatus: number, toast: ReturnType<typeof useToast>) {
  switch (responseStatus) {
    case HttpStatus.FORBIDDEN:
      toast({
        status: 'warning',
        title: DefaultErrorMessages.permissionErrorTitle('workflow'),
        description: DefaultErrorMessages.permissionErrorDescription('workflow'),
      });
      break;
    case HttpStatus.NOT_FOUND:
      toast({
        status: 'warning',
        title: `We couldn't find the workflow`,
        description: DefaultErrorMessages.notFoundErrorDescription,
      });
      break;
    default:
      toast({
        status: 'error',
        title: `We're having problems loading the workflow`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
  }
}

export const TemplateViewUtils = {
  showWorkflowViewErrorToast,
};
