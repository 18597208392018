import { useInjector } from 'components/injection-provider';
import * as React from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Box, Button, Text, useDisclosure, useToast } from 'components/design/next';
import { useAsync, usePromise } from 'react-use';
import { PlanInterval, PlanLevel } from '@process-street/subgrade/billing';
import { Option, SubscriptionCancellationForm } from '@process-street/subgrade/core';
import {
  BillingDetails,
  IntervalToPlanCostInfoMap,
  PlanActionType,
  PlanCostQuantity,
} from 'pages/organizations/manage/billing/models';
import { PlanBillingSummaryMode } from 'pages/organizations/manage/billing/components/billing-summary/component';
import { IntercomService } from 'services/interop/intercom-service';
import { HttpStatus } from '@process-street/subgrade/util';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { PlanLevelSelectorState } from 'directives/billing/plan-level-selector/plan-level-selector-service.interface';
import { BillingProps, BillingTabProps } from 'pages/organizations/manage/billing/components/billing-tab/component';
import { AnalyticsService } from 'components/analytics/analytics.service';

export const useBillingProps = (props: BillingTabProps): BillingProps => {
  const { currentPlan, discount } = props;

  // hooks
  const { BillingTabService } = useInjector('BillingTabService');

  const { $stateParams } = useInjector('$stateParams');

  React.useEffect(() => {
    if ($stateParams.modal) {
      plansDisclosure.onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$stateParams.modal]);

  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const plansDisclosure = useDisclosure();
  const cancelSubscriptionDisclosure = useDisclosure();
  const mounted = usePromise();

  // state
  const [selectedInterval, setSelectedInterval] = React.useState(PlanInterval.Yearly);
  const [selectedPlanLevel, setSelectedPlanLevel] = React.useState(currentPlan.level);
  const [country, setCountry] = React.useState<Option<string>>();
  const [zip, setZip] = React.useState<Option<string>>();
  const [costMap, setCostMap] = React.useState<Option<IntervalToPlanCostInfoMap>>();
  const [submitting, setSubmitting] = React.useState(false);
  const [billingMode, setBillingMode] = React.useState<Option<PlanBillingSummaryMode>>();
  const [organization, setOrganization] = React.useState(props.organization);
  const [planCostQuantity, setPlanCostQuantity] = React.useState<Option<PlanCostQuantity>>();

  // consts
  const { subscription } = organization;
  const { quantity } = subscription;

  const subscriptionIsCancelable = BillingTabService.subscriptionIsCancelable(organization, currentPlan);
  const shouldShowOnlyYearlyBillingCycle = BillingTabService.shouldShowOnlyYearlyBillingCycle(
    currentPlan,
    subscription,
    discount,
  );

  const planMapState = useAsync(
    () => BillingTabService.getFreemiumAndFreeTrialPlansAndFeatureSetsByPlan(currentPlan),
    [BillingTabService, currentPlan],
  );
  const cardInfoState = useAsync(
    () => BillingTabService.getCard(organization.id),
    [BillingTabService, organization.id],
  );

  // cost map
  React.useEffect(() => {
    const selectedPlan = getPlanForLevel(selectedPlanLevel);
    if (selectedPlan && planMapState.value) {
      const minQuantity = organization.minQuantity ?? selectedPlan.minQuantity ?? 0;
      const costQuantity = Math.max(minQuantity, quantity);
      setPlanCostQuantity({ minQuantity, quantity: costQuantity });

      const map = BillingTabService.getPlanCostDetails(
        planMapState.value,
        selectedPlanLevel,
        costQuantity,
        discount,
        organization,
      );
      setCostMap(map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore getPlanForLevel
  }, [BillingTabService, planMapState, selectedPlanLevel, quantity, discount]);

  // invitation resend in error toast
  const { $state } = useInjector('$state');
  const onResendVerification = () => {
    toast.closeAll();
    $state.go('userManage', { id: 'me' });
  };

  const planSubscriptionDetailsState = useAsync(
    () => BillingTabService.getPlanSubscriptionDetails(currentPlan, organization),
    [BillingTabService, currentPlan, organization],
  );

  const contactUs = () => {
    AnalyticsService.trackEvent('contact us link clicked', { location: 'billing page' });
    IntercomService.show();
  };

  const updatePlan = () => {
    updatePlanForLevel(selectedPlanLevel);
  };

  const updatePlanForLevel = (level: PlanLevel) => {
    const selectedPlan = getPlanForLevel(level);
    if (!selectedPlan) {
      return;
    }

    const updateCard = !cardInfoState.value && selectedPlan.level !== PlanLevel.Free;
    const cardElement = elements?.getElement(CardElement) ?? undefined;

    if (!stripe || (updateCard && !cardElement)) {
      return;
    }

    if (updateCard && !country) {
      toast({
        status: 'warning',
        title: 'There appears to be a problem validating your details',
        description: 'Please select a country.',
        variant: 'subtle',
      });
      return;
    }

    if (updateCard && !zip) {
      toast({
        status: 'warning',
        title: 'There appears to be a problem validating your details',
        description: 'Please fill in the Zip/Postal Code.',
        variant: 'subtle',
      });
      return;
    }

    const billingDetails: BillingDetails = {
      addressCountry: country,
      addressZip: zip,
    };

    setSubmitting(true);

    mounted(
      BillingTabService.updateSubscription(stripe, cardElement, billingDetails, currentPlan, selectedPlan, organization)
        .then(() => {
          toast({
            title: 'Subscription updated',

            status: 'success',

            variant: 'subtle',
          });
        })
        .catch(error => {
          if (error.status === HttpStatus.FORBIDDEN && error.data.code === 'EmailVerificationRequired') {
            toast({
              status: 'error',
              title: 'Email address not verified',
              description: (
                <Box>
                  <Text>
                    Before subscribing, please check your email inbox for the verification email and confirm your email
                    address.
                  </Text>
                  <Button textDecoration="underline" colorScheme="brand" variant="link" onClick={onResendVerification}>
                    Resend
                  </Button>
                </Box>
              ),
              variant: 'subtle',
            });
          } else {
            toast({
              status: 'error',
              title: "We're having problems updating the subscription",
              description: DefaultErrorMessages.unexpectedErrorDescription,

              variant: 'subtle',
            });
          }
        })
        .finally(() => {
          setSubmitting(false);
        }),
    );
  };

  const getPlanForLevel = (level: PlanLevel) => {
    const intervalToPlan = planMapState.value && planMapState.value[level];
    return intervalToPlan && intervalToPlan[selectedInterval];
  };

  const cancelSubscription = (cancellationForm: SubscriptionCancellationForm) => {
    setSubmitting(true);

    BillingTabService.cancelSubscription(currentPlan, organization.id, cancellationForm)
      .then(updatedOrg => {
        setOrganization(updatedOrg);
      })
      .catch(() => {
        toast({
          title: "We're having problems cancelling the subscription",
          description: DefaultErrorMessages.unexpectedErrorDescription,

          status: 'error',

          variant: 'subtle',
        });

        IntercomService.show();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handlePlanSelect = (level: PlanLevel, selectorState: PlanLevelSelectorState): boolean => {
    plansDisclosure.onClose();

    if (selectorState === PlanLevelSelectorState.CONTACT_US) {
      BillingTabService.contactUs();
      return false;
    } else if (BillingTabService.isFreeAndShouldCancelSubscription(currentPlan, level, organization)) {
      cancelSubscriptionDisclosure.onOpen();
      return false;
    } else if (selectorState !== PlanLevelSelectorState.CURRENT_PLAN) {
      setSelectedPlanLevel(level);

      if (level === PlanLevel.Free) {
        updatePlanForLevel(level);
      } else {
        setBillingMode('planUpgrade');
      }
      return true;
    }
    return false;
  };

  const openPlanSelector = () => {
    setBillingMode(undefined); // hide billing summary
    plansDisclosure.onOpen();
  };
  const handleChangeBillingCycle = () => {
    setBillingMode('intervalChange');
  };

  const handleStripeError = React.useCallback(() => {
    toast({
      status: 'error',
      title: `We're having problems updating your details`,
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
  }, [toast]);

  const handleEmailUpdate = (email: string) => {
    BillingTabService.updateEmail(organization.id, email)
      .then(org => {
        setOrganization(org);

        toast({
          status: 'success',
          title: 'Billing email successfully updated',

          variant: 'subtle',
        });
      })
      .catch(() => {
        toast({
          status: 'error',
          title: "We're having problems updating your billing email",
          description: DefaultErrorMessages.unexpectedErrorDescription,

          variant: 'subtle',
        });
      });
  };

  const billingDetailsVisible = true;

  const handlePlanAction = (action: PlanActionType) => {
    switch (action) {
      case 'contact':
        contactUs();
        break;

      case 'selectPlan':
        openPlanSelector();
        break;

      case 'renewSubscription':
        setSubmitting(true);
        BillingTabService.renewSubscription(organization, currentPlan)
          .then(() => {
            toast({
              title: 'Subscription renewed',

              status: 'success',
              variant: 'subtle',
            });
          })
          .finally(() => {
            setSubmitting(false);
          });
        break;
    }
  };
  return {
    billingDetailsVisible,
    billingMode,
    cancelSubscription,
    cancelSubscriptionDisclosure,
    cardInfoState,
    contactUs,
    costMap,
    currentPlan,
    discount,
    handleEmailUpdate,
    handleChangeBillingCycle,
    handlePlanAction,
    handlePlanSelect,
    handleStripeError,
    openPlanSelector,
    organization,
    planCostQuantity,
    planMapState,
    planSubscriptionDetailsState,
    plansDisclosure,
    quantity,
    selectedInterval,
    selectedPlanLevel,
    setCountry,
    setSelectedInterval,
    setZip,
    shouldShowOnlyYearlyBillingCycle,
    submitting,
    subscription,
    subscriptionIsCancelable,
    updatePlan,
  };
};
