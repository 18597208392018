import * as React from 'react';
import { isForm, isPage, isWorkflow, TemplateType } from '@process-street/subgrade/process';
import { createUsableContext } from '@process-street/subgrade/util';

type Context = {
  templateType: TemplateType;
  isPage: boolean;
  isForm: boolean;
  isWorkflow: boolean;
};

export const [useTemplateTypeContext, TemplateTypeContext] = createUsableContext<Context>({
  hookName: 'useTemplateTypeContext',
  providerName: 'TemplateTypeContext.Provider',
});

export const TemplateTypeProvider: React.FC<React.PropsWithChildren<{ templateType: TemplateType }>> = ({
  templateType,
  children,
}) => {
  const value = React.useMemo<Context>(() => {
    return {
      templateType,
      isPage: isPage(templateType),
      isWorkflow: isWorkflow(templateType),
      isForm: isForm(templateType),
    };
  }, [templateType]);
  return <TemplateTypeContext.Provider value={value}>{children}</TemplateTypeContext.Provider>;
};
