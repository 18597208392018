import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { ExtendedComponentsConfig } from 'components/design/BlvdSelect/types';
import { UserOption } from 'utils/user-option-transformer';
import { Box, HStack, Icon, Text } from 'components/design/next';
import { OverflowingUsersIndicator } from 'components/run-checklist/components/RunChecklist/components/OverflowingUsersIndicator';
import * as React from 'react';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { UserOptionItem } from '../components/user-option-item';

export type AssigneesSearchBoxProps = {
  userOptions: UserOption[];
  onAddSelectedOption: (userOption: UserOption) => void;
  placeholder: string;
};

export const AssigneesSearchBox: React.FC<React.PropsWithChildren<AssigneesSearchBoxProps>> = ({
  userOptions,
  placeholder,
  onAddSelectedOption,
}) => {
  const selectComponents: ExtendedComponentsConfig<UserOption> = {
    Option: UserOptionItem,
    OverflowingValuesIndicator: OverflowingUsersIndicator,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  const handleChange = (value: OnChangeValue<UserOption, false>, actionMeta: ActionMeta<UserOption>) => {
    if (actionMeta.action === 'select-option' && BlvdSelectHelpers.isOptionType<UserOption>(value)) {
      onAddSelectedOption(value);
    }
  };

  return (
    <Box maxWidth="100" width="full">
      <Select
        styles={{
          option: (styles, { isFocused }) => {
            return {
              ...styles,
              backgroundColor: isFocused ? 'var(--ps-colors-brand-100)' : 'var(--ps-white)',
            };
          },
        }}
        components={selectComponents}
        isSearchable={true}
        options={userOptions}
        fixedSize={true}
        menuControls={false}
        placeholder={<Placeholder>{placeholder}</Placeholder>}
        value={[]}
        onChange={handleChange}
      />
    </Box>
  );
};

const Placeholder: React.FC<React.PropsWithChildren<unknown>> = props => (
  <HStack color="gray.400" spacing="2">
    <Icon variant="far" size="4" icon="search" />
    <Text fontSize="sm" fontWeight="normal">
      {props.children}
    </Text>
  </HStack>
);
