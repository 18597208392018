import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { Muid, Organization, User, UserType } from '@process-street/subgrade/core';
import { Plan } from '@process-street/subgrade/billing';
import {
  FeatureFlagKey,
  FeatureFlags,
  LaunchDarklyPublicKey,
} from 'services/features/feature-flags/feature-flag-constants';
import angular from 'angular';
import ngRedux from 'ng-redux';
import { FeatureFlagAction } from 'services/features/feature-flags/store/feature-flags.actions';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { StringUtils } from '@process-street/subgrade/util';
import { NEW_STALE_TIME_ENABLED_KEY, queryClient } from 'components/react-root';
import { LDSingleKindContext } from 'launchdarkly-react-client-sdk';
import { FeatureFlagsUtils } from './feature-flag-service.pure';

export interface PSLaunchDarklyUser extends LDSingleKindContext {
  kind: 'user';
  key: string;
  name: string;
  email?: string;
  beta?: boolean;
  developer?: boolean;
  selectedOrganizationId?: Muid;
  selectedOrganizationName?: string;
  selectedOrganizationCreatedDate?: string;
  selectedPlanId?: Muid;
}

export interface FeatureFlagService {
  initializeForUser(user?: User, selectedOrganization?: Organization, selectedPlan?: Plan): LDClient;

  initializeForAnonymousMicrosoftTeamsUser(): Promise<void>;

  getFeatureFlags(): FeatureFlags;

  getFeatureFlagsAsync(): Promise<FeatureFlags>;
}

export class FeatureFlagServiceImpl implements FeatureFlagService {
  public static $inject = ['$ngRedux'];

  private client: LDClient | undefined = undefined;
  private initializeResolver!: () => void;
  /* Promise that resolves when feature flags have been saved to Redux. */
  private initializedWaiter!: Promise<void>;

  constructor(private $ngRedux: ngRedux.INgRedux) {
    this.resetInitializedWaiter();
  }

  public async getFeatureFlagsAsync(): Promise<FeatureFlags> {
    await this.initializedWaiter;
    return this.getFeatureFlags();
  }

  public initializeForUser(user?: User, selectedOrganization?: Organization, selectedPlan?: Plan): LDClient {
    const ldUser: PSLaunchDarklyUser =
      user?.userType === UserType.Standard
        ? FeatureFlagsUtils.generateLaunchDarklyUser(user, selectedOrganization, selectedPlan)
        : FeatureFlagsUtils.generateLaunchDarklyAnonymousUser();

    this.resetInitializedWaiter();
    if (this.client === undefined) {
      return this.initialize(ldUser);
    } else {
      this.client?.identify(ldUser).then(flags => {
        this.updateFeatureFlagsInRedux(flags);
      });
      return this.client;
    }
  }

  public async initializeForAnonymousMicrosoftTeamsUser() {
    const teamsSdk = await import('@microsoft/teams-js');

    teamsSdk.initialize();
    teamsSdk.getContext(context => {
      const email = context.loginHint;

      if (email) {
        const ldUser: PSLaunchDarklyUser = {
          kind: 'user',
          key: email,
          name: email,
          email,
        };

        this.initialize(ldUser);
      } else {
        console.error('failed to initialize launch darkly for Microsoft teams: user email not available.');
      }
    });
  }

  /** Makes getFeatureFlagsAsync wait until feature flags have been refetched. */
  private resetInitializedWaiter() {
    this.initializedWaiter = new Promise<void>(res => (this.initializeResolver = res));
  }

  private initialize(ldUser: PSLaunchDarklyUser): LDClient {
    this.client = initialize(LaunchDarklyPublicKey, ldUser);

    this.client.on('initialized', () => {
      const flags = this.client ? this.client.allFlags() : {};
      if (flags) {
        this.updateFeatureFlagsInRedux(flags);
      }
    });

    return this.client;
  }

  public getFeatureFlags(): FeatureFlags {
    const state = this.$ngRedux.getState();
    return FeatureFlagSelector.getFeatureFlags(state);
  }

  public updateFeatureFlagsInRedux(flags: LDFlagSet) {
    this.setNewStaleTimeEnabled(flags[FeatureFlagKey.TuneReactQueryCaching]);
    this.$ngRedux.dispatch(
      FeatureFlagAction.setFlags(
        Object.entries(FeatureFlagKey).reduce(
          (acc, [key, launchDarklyKey]) => {
            const stateKey = StringUtils.uncapitalize(key as keyof typeof FeatureFlagKey);
            acc[stateKey] = flags[launchDarklyKey];
            return acc;
          },
          { isInitialized: true } as FeatureFlags,
        ),
      ),
    );
    this.initializeResolver();
  }

  // Remove this when flag TuneReactQueryCaching is removed.
  private setNewStaleTimeEnabled(enabled = false): void {
    const staleTime = enabled ? 1000 * 60 : 1000; // 60 seconds || 1 second.
    queryClient.setDefaultOptions({
      queries: {
        staleTime,
      },
    });
    localStorage.setItem(NEW_STALE_TIME_ENABLED_KEY, JSON.stringify(enabled));
  }
}

angular.module('frontStreetApp.services').service('FeatureFlagService', FeatureFlagServiceImpl);
