import { Muid, Option } from '@process-street/subgrade/core';
import { Folder } from '@process-street/subgrade/process';
import { EntityMap } from '@process-street/subgrade/redux/types';
import { createSelector, Selector } from 'reselect';
import { ReduxAppState } from '../types';
import { SessionSelector } from 'reducers/session/session.selectors';

const isActive = (folder: Folder): boolean => !folder.deletedBy;

const isSameOrganization = (organizationId: Muid, folder: Folder): boolean =>
  folder && folder.organization.id === organizationId;

const isChildFolder = (parentFolderId: Muid, folder: Option<Folder>): boolean => {
  if (!folder) {
    return false;
  }
  if (!folder.parentFolder) {
    return false;
  }
  return folder.parentFolder.id === parentFolderId;
};

const isOrphan = (folder: Folder, folders: Folder[]): boolean => {
  if (!folder.parentFolder) {
    return false;
  }
  const parentFolder = folders.find(f => f.id === folder.parentFolder?.id);
  return !parentFolder;
};

const getEntityMap = (state: ReduxAppState): EntityMap<Folder> => state.entities.folders;

const getAllOrphansByOrganizationId =
  (organizationId: Muid) =>
  (state: ReduxAppState): Folder[] => {
    return Object.values(FolderSelector.getEntityMap(state)).filter(
      (folder, _, folders) => isSameOrganization(organizationId, folder) && isOrphan(folder, folders),
    );
  };

const getAllByCurrentOrganization = (state: ReduxAppState) => {
  const orgId = SessionSelector.getSelectedOrganizationId(state);
  return Object.values(FolderSelector.getEntityMap(state)).filter(f => f.organization?.id === orgId);
};

const getById =
  (folderId: Muid) =>
  (state: ReduxAppState): Folder | undefined =>
    FolderSelector.getEntityMap(state)[folderId];

export const isActiveChildFolder = (parentFolderId: Muid) => (folder: Folder) =>
  isActive(folder) && isChildFolder(parentFolderId, folder);

const getAllChildrenByFolderId =
  (parentFolderId: Muid) =>
  (state: ReduxAppState): Folder[] =>
    Object.values(FolderSelector.getEntityMap(state)).filter(isActiveChildFolder(parentFolderId));

const getParentsIdsRecursively = (folderMap: EntityMap<Folder>, folder: Folder): Muid[] => {
  const parent = folder?.parentFolder?.id && folderMap[folder.parentFolder.id];
  if (parent) {
    return getParentsIdsRecursively(folderMap, parent).concat([parent.id]);
  }

  return [];
};

const getAncestryIdsByFolderId = (folderId: Muid, includeSelf = false): Selector<ReduxAppState, Muid[]> =>
  createSelector(getEntityMap, folderMap => {
    const folder = folderMap[folderId];

    const parentsIds = getParentsIdsRecursively(folderMap, folder);

    if (includeSelf) {
      parentsIds.push(folderId);
    }

    return parentsIds;
  });

export const isActiveChildOrOrphanFolder =
  (parentFolderId: Muid) =>
  (folder: Folder, _i = 0, folders: Folder[] = []) =>
    isActive(folder) && (isChildFolder(parentFolderId, folder) || isOrphan(folder, folders));

const getAllChildrenAndOrphansByFolderId =
  (parentFolderId: Muid) =>
  (state: ReduxAppState): Folder[] =>
    Object.values(getEntityMap(state)).filter(isActiveChildOrOrphanFolder(parentFolderId));

export const FolderSelector = {
  getAllChildrenAndOrphansByFolderId,
  getAllChildrenByFolderId,
  getAllOrphansByOrganizationId,
  getAllByCurrentOrganization,
  getById,
  getEntityMap,
  getAncestryIdsByFolderId,
};
