import * as React from 'react';
import { Modal, ModalContent, ModalOverlay, ModalProps } from 'components/design/next';
import { WorkflowSetupStep } from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/workflow-setup-step';
import { OnboardingSettingsKey } from 'components/user/user-constants';
import { Skeleton } from 'components/design/next';
import { useActor } from '@xstate5/react';
import { InitialSetupStep } from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/initial-setup-step';
import {
  TemplateSettingsActions,
  templateSettingsMachine,
} from 'pages/templates/_id/components/template-settings-modal/template-settings-machine';
import { useOnboardingSettings } from 'pages/templates/_id/components/template-settings-modal/use-onboarding-settings';
import { AddTasksStep } from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/add-tasks-step';
import { useTemplateSettingsModalContext } from './template-settings-modal-context';
import { PreselectTriggers } from './onboarding-steps/preselect-triggers';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { TemplateGalleryHelper } from 'components/template-gallery/components/TemplateGallery/template-gallery-helpers';
import { Option } from 'space-monad';
import { TemplateSettingsContext } from './context';
import { useInjector } from 'components/injection-provider';
import { useFeatureFlag } from 'app/features/feature-flags';

export type TemplateSettingsModalProps = Partial<ModalProps>;

export const TemplateSettingsModal: React.FC<React.PropsWithChildren<TemplateSettingsModalProps>> = props => {
  const { templateId, variant = 'setupScreen', ...modalContext } = useTemplateSettingsModalContext();
  const isReactWorkflowEditorEnabled = useFeatureFlag('reactWorkflowEditor');

  const isSetupScreen = variant === 'setupScreen' || variant === 'dashboardSetupScreen';
  const { onboardingSettingsQuery, updateOnboardingSettings } = useOnboardingSettings();

  const { $state } = useInjector('$state');

  const [snapshot, send] = useActor(
    templateSettingsMachine.provide({
      actions: {
        [TemplateSettingsActions.ON_FINISHED]: ({ context }) => {
          if (context.needsToReload) {
            $state.reload();
          }
        },
        [TemplateSettingsActions.MUTATE_FIRST_WORKFLOW_SETTING]: () =>
          updateOnboardingSettings({ [OnboardingSettingsKey.FIRST_WORKFLOW_CREATED]: true }),
      },
    }),
  );

  const modalSize =
    snapshot.matches('addTasks') ||
    snapshot.matches('preselectTrigger') ||
    (snapshot.matches('finished') && !isSetupScreen)
      ? '3xl'
      : 'xl';

  React.useEffect(() => {
    if (!isSetupScreen && onboardingSettingsQuery.data?.[OnboardingSettingsKey.FIRST_WORKFLOW_CREATED]) {
      send({ type: 'NEXT' });
    }
  }, [onboardingSettingsQuery.data, isSetupScreen, send]);

  const templateQuery = useGetTemplateQuery({ templateId });
  const isFromTemplateGallery = Option(templateQuery.data)
    .map(TemplateGalleryHelper.isCopiedFromTemplateGallery)
    .getOrElse(false);

  React.useEffect(() => {
    send({ type: 'SET_CONTEXT', context: { isFromTemplateGallery } });
  }, [isFromTemplateGallery, send]);

  const onWizardNext = () => send({ type: 'NEXT' });

  const setupOnNext = () => {
    send({ type: 'GO_TO_TRIGGERS' });
  };

  return (
    <Modal
      {...(isSetupScreen
        ? modalContext
        : {
            isOpen: !snapshot.matches('finished'),
            onClose: () => send({ type: 'FINISH' }),
          })}
      {...props}
      size={modalSize}
      scrollBehavior={isReactWorkflowEditorEnabled ? 'inside' : 'outside'}
    >
      <ModalOverlay />
      <ModalContent
        // override with exact width for triggers dialog
        width={snapshot.matches('preselectTrigger') ? '690px' : undefined}
        // for modal overflow to work properly, ModalBody & ModalFooter have to be direct children of ModalContent
        // the current structure of modal components inside providers/forms won't give us
        // a sticky footer or good overflow behavior
        // this allows the entire modal to scroll on small screens
        containerProps={{ overflowY: 'scroll' }}
        maxH="inherit"
      >
        <Skeleton isLoaded={!onboardingSettingsQuery.isLoading}>
          <TemplateSettingsContext.Provider value={{ send }}>
            {isSetupScreen ? (
              <WorkflowSetupStep {...{ onNext: modalContext.onClose, onClose: modalContext.onClose }} />
            ) : (
              <>
                {snapshot.matches('createFirstWorkflow') && <InitialSetupStep onNext={onWizardNext} />}
                {snapshot.matches('workflowSetup') && (
                  <WorkflowSetupStep {...{ onNext: setupOnNext, onClose: modalContext.onClose }} />
                )}
                {snapshot.matches('preselectTrigger') && <PreselectTriggers onNext={onWizardNext} />}
                {snapshot.matches('addTasks') && <AddTasksStep onNext={onWizardNext} />}
              </>
            )}
          </TemplateSettingsContext.Provider>
        </Skeleton>
      </ModalContent>
    </Modal>
  );
};
