import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import get from 'lodash/get';
import { toArray } from 'reducers/util';
import { isModelRef } from '@process-street/subgrade/core';

export * from './reference-normalizer';
export * from './safe-entity-map-to-array-by-ids';

export const entitiesCollectionReducerObject = (putActionName, deleteActionName, normalize) => ({
  [putActionName]: (state, { payload }) => {
    const normalizedPayload = normalize ? payload.map(el => normalize(el)) : payload;
    return EntitiesReducerUtils.upsertAll(state, normalizedPayload);
  },
  [deleteActionName]: (state, { payload }) => {
    const id = typeof payload === 'string' ? payload : payload.id;
    return EntitiesReducerUtils.deleteById(state, id);
  },
});

/**
 * With our structure we often load referenced entities in related ones.
 * Like user can be loaded if we request for OrganizationMembership in a form of IdRef or ModelRef.
 * In order to keep entities structure accurate and relevant we need to populate those related entities into
 * entities in Redux.
 *
 * This is a utility function, that on specific PUT event extracts related entities and adds them to parent collection.
 *
 * For example if we are talking about OrganizationMembership, the relevant action would be
 * ORGANIZATION_MEMBERSHIP_PUT and relevant objSelector would be 'user'
 *
 * @param putActionName - put action name
 * @param objSelector - obj selector command
 * @return reducer, which when putActionName triggered selects relevant updates for current entity.
 */
export const appendOnPutReducerObject = (putActionName, objSelector, normalize) => ({
  [putActionName]: (state, { payload }) => {
    const appended = toArray(payload).reduce((agg, obj) => {
      const el = get(obj, objSelector);
      const elementShouldBeAppended = isModelRef(el) && el.id && el !== state[el.id];
      if (elementShouldBeAppended) {
        const newEl = { ...el, ...state[el.id] };
        agg[el.id] = normalize(newEl);
      }
      return agg;
    }, {});

    if (Object.keys(appended).length > 0) {
      return { ...state, ...appended };
    } else {
      return state;
    }
  },
});

export const appendOnSingleElementPutReducerObject = (singlePutActionName, objSelector, normalize) => ({
  [singlePutActionName]: (state, { payload: obj }) => {
    const el = get(obj, objSelector);
    const elementShouldBeAppended = el && Object.keys(el).length !== 1 && el.id && el !== state[el.id];
    if (elementShouldBeAppended) {
      const newEl = { ...el, ...state[el.id] };
      return { ...state, [el.id]: normalize(newEl) };
    } else {
      return state;
    }
  },
});

/**
 * This reducer is here to ensure cascade removal of related entities on parent removal.
 *
 * For example:
 *  If you remove template, you also need to remove all checklists related to this template from Redux.
 *
 * This is emulation of old functionality, that was part of old DB structure
 *
 * @param deleteActionType - delete action type
 * @param objIdSelector - path to id inside of child collection,
 *      (for checklist it would be checklist.template.id, which would match template.id of removed template)
 * @param elSelector - id selector, which defaults to entity id
 * @returns {Function} a reducer, that will execute cascade cleanup of a child collection, on parent element removal
 */
export const cascadeCleanOnDeleteReducerObject = (deleteActionType, objIdSelector, elSelector) => ({
  [deleteActionType]: (state, { payload, meta }) => {
    const idToRemove = elSelector ? get(meta.element, elSelector) : payload;

    if (objIdSelector) {
      const filteredValues = Object.values(state).filter(obj => {
        const id = get(obj, objIdSelector);
        return idToRemove !== id;
      });

      return EntitiesReducerUtils.upsertAll({}, filteredValues);
    } else {
      return EntitiesReducerUtils.deleteById(state, idToRemove);
    }
  },
});

/**
 * Change object generator for large collections
 *
 * @param originalCollection
 * @param currentById - current value in a form of normalized object
 * @return {{updated: Array, removed: Array, added: Array}}
 *  updated - all entities, that were updated during the change
 *  removed - all entities, that were removed during the change
 *  added - all entities, that were added during the change
 */
export const entitiesChangeFrom = (originalCollection, currentById) => {
  const updated = [];
  const removed = [];

  originalCollection.forEach(originalValue => {
    if (currentById[originalValue.id] === undefined) {
      removed.push(originalValue);
    } else if (currentById[originalValue.id] !== originalValue) {
      updated.push(currentById[originalValue.id]);
    }
    delete currentById[originalValue.id];
  });
  const added = Object.values(currentById);

  return {
    updated,
    removed,
    added,
  };
};
