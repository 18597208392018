import { Template, TemplateType } from '@process-street/subgrade/process';
import { abbreviateForTitle } from '@process-street/subgrade/util';
import { AppModalQueryParam } from 'app/app.constants';
import { useInjector } from 'components/injection-provider';
import { useCopyToClipboard } from 'react-use';

type UseCopyShareLinkProps = {
  template: Template;
};

export const useCopyShareLink = ({ template }: UseCopyShareLinkProps) => {
  const { $state } = useInjector('$state');
  const [_, copyToClipboard] = useCopyToClipboard();

  const isPage = template.templateType === TemplateType.Page;
  const title = abbreviateForTitle(template.name);
  const state = isPage ? 'pageView' : 'templateView';

  const url = $state.href(
    state,
    {
      id: template.id,
      title: title && `${title}-`,
      [AppModalQueryParam.Modal]: undefined,
    },
    { absolute: true, inherit: false },
  );

  return {
    url,
    copyToClipboard: () => copyToClipboard(url),
  };
};
