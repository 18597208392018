import { Option, User } from '@process-street/subgrade/core';
import { FieldType, FormFieldWidget } from '@process-street/subgrade/process';
import angular from 'angular';
import { SessionService } from 'services/session-service.interface';
import templateUrl from './widget-renderer.component.html';
import './widget-renderer.component.scss';

export class ApprovalSubjectTaskWidgetRendererController {
  public user: Option<User>;

  public untitledLabelMap: Record<FieldType, string | undefined> = {
    [FieldType.Date]: 'Untitled Date',
    [FieldType.Email]: 'Untitled Email Address',
    [FieldType.File]: 'Untitled File Upload',
    [FieldType.Hidden]: undefined,
    [FieldType.Members]: 'Untitled Members',
    [FieldType.MultiChoice]: 'Untitled Multi Choice',
    [FieldType.MultiSelect]: 'Subtasks',
    [FieldType.Number]: 'Untitled Number',
    [FieldType.Select]: 'Untitled Dropdown',
    [FieldType.SendRichEmail]: 'Untitled Send Email',
    [FieldType.Snippet]: 'Untitled Snippet',
    [FieldType.Table]: 'Untitled Table',
    [FieldType.Text]: 'Untitled Short Text',
    [FieldType.Textarea]: 'Untitled Long Text',
    [FieldType.Url]: 'Untitled Website',
  };

  static $inject = ['SessionService'];
  constructor(private sessionService: SessionService) {
    this.user = this.sessionService.getUser();
  }

  public getLabel(widget: FormFieldWidget) {
    let { label } = widget;
    if (!label) {
      label = this.untitledLabelMap[widget.fieldType];
    }
    return `${label}:`;
  }

  public shouldShowLabel(widget: FormFieldWidget) {
    return widget.fieldType !== FieldType.MultiSelect;
  }
}

export const ApprovalSubjectTaskWidgetRenderer: angular.IComponentOptions = {
  bindings: {
    formFieldValue: '<',
    widget: '<',
  },
  controller: ApprovalSubjectTaskWidgetRendererController,
  templateUrl,
};
