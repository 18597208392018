import { UrlFormFieldWidget, TEXT_MAX_LENGTH, URL_REGEXP } from '@process-street/subgrade/process';
import * as yup from 'yup';

export type UrlSettingsSchema = UrlFormFieldWidget['constraints'] &
  UrlFormFieldWidget['config'] & {
    hasVariables: boolean;
  };

export const urlSettingsSchema = yup.object<UrlSettingsSchema>({
  // this is a transient/hidden value used to drive a dynamic validation
  // inspiration: https://stackoverflow.com/questions/65613573/yup-how-to-validate-field-only-when-it-exists
  hasVariables: yup.boolean(),
  defaultValue: yup
    .string()
    .min(0)
    .max(TEXT_MAX_LENGTH)
    .when('hasVariables', {
      is: true,
      then: (schema: yup.StringSchema) => schema,
      otherwise: (schema: yup.StringSchema) => {
        return schema.matches(URL_REGEXP, { message: 'Invalid URL', excludeEmptyString: true });
      },
    }),
});
