import { ColDef } from '@ag-grid-community/core';
import {
  ChecklistColumn,
  Clause,
  Condition,
  ConditionalFilterOperand,
  ConditionalFilterUtils,
  conditionNameMap,
  FilterType,
} from '@process-street/subgrade/dashboard';
import React, { useEffect, useState } from 'react';
import styles from './FilterRow.module.scss';

import { Muid } from '@process-street/subgrade/core';
import { FilterTypeCell } from '../FilterTypeCell';
import { OperandCell } from '../OperandCell';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { Box, IconButton, Tooltip, Icon } from 'components/design/next';

interface ConditionRowProps {
  columns: ColDef[];
  filter: Clause;
  parentFilterType: FilterType;
  first: boolean;
  onChange: (filter: Clause) => void;
  onDelete: (id: Muid) => void;
  onSwitchFilterType: (switchedType: FilterType) => void;
}

type ColumnOptionType = {
  value: ChecklistColumn;
  label: string;
};

type ConditionOptionType = {
  value: Condition;
  label: string;
};

const getConditionItems = (column: ChecklistColumn) =>
  ConditionalFilterUtils.getConditionsForChecklistColumn(column).map(key => ({
    value: key,
    label: conditionNameMap[key],
  }));

export const FilterRow: React.FunctionComponent<React.PropsWithChildren<ConditionRowProps>> = ({
  first,
  filter,
  parentFilterType,
  columns,
  onDelete,
  onChange,
  onSwitchFilterType,
}) => {
  const [storedFilter, setStoredFilter] = useState<Clause>(filter);

  useEffect(() => {
    setStoredFilter(filter);
  }, [filter]);

  const handleColumnSelect = ({ value: column }: ColumnOptionType) => {
    const { operand, condition } = ConditionalFilterUtils.getClauseConditionAndOperand({
      column,
      condition: storedFilter.condition,
    });

    const updatedFilter = { ...storedFilter, columnName: column, operand, condition };

    setStoredFilter(updatedFilter);
    onChange(updatedFilter);
  };

  const handleConditionSelect = ({ value: condition }: ConditionOptionType) => {
    const { operand } = ConditionalFilterUtils.getClauseConditionAndOperand({
      column: storedFilter.columnName,
      condition,
    });

    const updatedFilter = { ...storedFilter, operand, condition };

    setStoredFilter(updatedFilter);
    onChange(updatedFilter);
  };

  const handleDelete = () => {
    onDelete(filter.id);
  };

  const handleOperandValueChange = (operand: ConditionalFilterOperand) => {
    const updatedFilter = { ...storedFilter, operand };

    setStoredFilter(updatedFilter);
    onChange(updatedFilter);
  };

  const switchFilterType = () => {
    const switchedType = parentFilterType === FilterType.And ? FilterType.Or : FilterType.And;
    onSwitchFilterType(switchedType);
  };

  return (
    <div className={styles.filterRow}>
      <Tooltip label="Remove filter">
        <IconButton
          aria-label="Remove filter"
          data-testid="remove-filter"
          size="sm"
          variant="tertiary"
          icon={<Icon icon="times" size="3.5" color="gray.500" />}
          onClick={handleDelete}
        />
      </Tooltip>

      <FilterTypeCell first={first} filterType={parentFilterType} onClick={switchFilterType} />

      <Box className={styles.filterFields}>
        <BlvdSelect
          className={styles.columnDropdown}
          id="FilterRow_ColumnDropdown"
          options={columns.map(column => ({ value: column.field!, label: column.headerName! }))}
          styles={{
            menu: ({ width, ...css }) => ({ ...css, minWidth: width }),
          }}
          value={{
            value: storedFilter.columnName! as string,
            label: columns.find(c => c.field === storedFilter.columnName)!.headerName,
          }}
          onChange={value => {
            if (value && BlvdSelectHelpers.isOptionType<ColumnOptionType>(value)) {
              handleColumnSelect(value);
            }
          }}
        />

        <BlvdSelect
          className={styles.columnDropdown}
          id="FilterRow_ConditionDropdown"
          options={getConditionItems(storedFilter.columnName)}
          value={{ value: storedFilter.condition, label: conditionNameMap[storedFilter.condition] }}
          styles={{
            menu: ({ width, ...css }) => ({ ...css, minWidth: width }),
          }}
          onChange={value => {
            if (value && BlvdSelectHelpers.isOptionType<ConditionOptionType>(value)) {
              handleConditionSelect(value);
            }
          }}
        />

        <div className={styles.operand}>
          <OperandCell operand={storedFilter.operand} onChange={handleOperandValueChange} />
        </div>
      </Box>
    </div>
  );
};
