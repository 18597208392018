import * as React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  chakra,
  Flex,
  forwardRef,
  HStack,
  Text,
  Tooltip,
  useTheme,
  VStack,
} from 'components/design/next';
import { AutomationAppIcon } from 'features/automations/components/automation-app-icon';
import { AutomationAppName, SolutionTypeTag, TemplateSolutionInstance } from '@process-street/subgrade/automation';
import { match } from 'ts-pattern';

export interface AutomationListItemProps {
  description?: string;
  status?: 'on' | 'off';
  selected?: boolean;
  automationApp?: AutomationAppName;
  onClick: React.MouseEventHandler;
  templateSolutionInstanceTypeTag: TemplateSolutionInstance['solutionTypeTag'];
  taskName?: string;
}

type ItemButtonProps = Pick<AutomationListItemProps, 'status' | 'selected' | 'description' | 'taskName'> & ButtonProps;

const ItemButton = forwardRef(
  ({ selected, status, description, taskName, children, ...props }: ItemButtonProps, ref) => {
    const theme = useTheme();
    return (
      <Button
        as={Box}
        w="100%"
        ref={ref}
        borderRadius="lg"
        data-child="tint"
        _active={{ color: 'white', backgroundColor: 'brand.500' }}
        backgroundColor="white"
        isActive={selected}
        height="auto"
        variant="outline"
        colorScheme="gray"
        textAlign="left"
        aria-label="automation item description"
        {...props}
      >
        <Flex justify="space-between" py="4" px="1" w="100%">
          <Box maxWidth={`calc(100% - ${theme.sizes[6]})`} flexGrow={1}>
            {children}
          </Box>

          <Box w="4">
            <Text variant="1" fontWeight="normal" casing="capitalize">
              {status}
            </Text>
          </Box>
        </Flex>
      </Button>
    );
  },
);
export const AutomationListItem: React.FC<React.PropsWithChildren<AutomationListItemProps>> = ({
  automationApp,
  templateSolutionInstanceTypeTag,
  onClick,
  description,
  status,
  selected,
  taskName,
}) => {
  return (
    <VStack
      spacing="2"
      p="0"
      as="button"
      ref={(node: HTMLDivElement) => {
        if (selected && node) node.scrollIntoView();
      }}
      bg="transparent"
      w="100%"
      align="flex-start"
      onClick={onClick}
      sx={{
        '[data-child=move]': {
          transition: 'transform .125s ease-in-out',
          willChange: 'transition',
        },
        '&:focus [data-child=move]': {
          transform: 'translateX(var(--ps-sizes-2))',
        },
        '[data-child=tint]': {
          transition: 'background-color .2s ease-in-out, color .2s ease-in-out',
          willChange: 'background-color, color',
        },
        '&:focus [data-child=tint]': {
          bg: 'brand.500',
          color: 'white',
        },
      }}
    >
      <Box ml="9" data-child="move" />
      <HStack w="100%" align="center" justify="center" flexGrow={1}>
        <Box h="100%" w="8">
          {!!automationApp && <AutomationAppIcon w="6" automationApp={automationApp} />}
        </Box>
        {match(templateSolutionInstanceTypeTag)
          .with(SolutionTypeTag.WhenTaskCheckedThen, () => {
            const label = (
              <>
                When <chakra.span fontStyle="italic">{taskName}</chakra.span> is checked {description}
              </>
            );
            return (
              <Tooltip label={label} hasArrow placement="bottom">
                <ItemButton {...{ description, taskName, status, selected }}>
                  <Text as="h3" variant="1" noOfLines={1} fontWeight="normal">
                    {label}
                  </Text>
                </ItemButton>
              </Tooltip>
            );
          })
          .otherwise(() => {
            return (
              <ItemButton {...{ description, status, selected }}>
                <Text as="h3" variant="1" noOfLines={1} fontWeight="normal">
                  ... {description}
                </Text>
              </ItemButton>
            );
          })}
      </HStack>
    </VStack>
  );
};
