import { ChecklistRevision, Template, Comment } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetCommentsByChecklistRevisionIdQueryParams = {
  checklistRevisionId: ChecklistRevision['id'];
  templateId: Template['id'];
};

export type GetCommentsByChecklistRevisionIdQueryResponse = Comment[];

export const GetCommentsByChecklistRevisionIdQuery = {
  key: ['comments', 'checklist-revision'],
  getKey: (params: GetCommentsByChecklistRevisionIdQueryParams): QueryKey => [
    ...GetCommentsByChecklistRevisionIdQuery.key,
    params,
  ],
  queryFn: (params: GetCommentsByChecklistRevisionIdQueryParams) =>
    axiosService
      .getAxios()
      .get<GetCommentsByChecklistRevisionIdQueryResponse>(
        `/1/comments/by-checklist-revision/${params.checklistRevisionId}`,
        {
          params: {
            where: {
              'task.taskTemplate.templateRevision.templateId': { _eq: params.templateId },
              'task.checklistRevisionId': { _eq: params.checklistRevisionId },
              'status': { _eq: 'Active' },
            },
            include: 'createdBy,task.taskTemplate.templateRevision',
          },
        },
      )
      .then(res => res.data),
};

export const useGetCommentsByChecklistRevisionIdQuery = <Select = GetCommentsByChecklistRevisionIdQueryResponse>(
  params: GetCommentsByChecklistRevisionIdQueryParams,
  options: UseQueryOptions<GetCommentsByChecklistRevisionIdQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetCommentsByChecklistRevisionIdQuery.getKey(params),
    () => GetCommentsByChecklistRevisionIdQuery.queryFn(params),
    options,
  );
};
