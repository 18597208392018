import {
  CrossLinkWidget,
  EmbedWidget,
  FileWidget,
  ImageWidget,
  TableWidget,
  TextWidget,
  VideoWidget,
  Widget,
  WidgetType,
} from '@process-street/subgrade/process';
import { escapeHtml } from '@process-street/subgrade/util';
import { isElement, isText } from '@udecode/slate';
import { jsx } from 'slate-hyperscript';
import {
  ELEMENT_PS_FILE,
  ELEMENT_PS_IMAGE,
  ELEMENT_PS_TABLE,
  ELEMENT_PS_VIDEO,
} from 'pages/pages/_id/edit/page/plugins';
import { ELEMENT_PS_EMBED } from '../plugins/ps-embed';
import { match } from 'ts-pattern';
import rgb2hex from 'rgb2hex';
import { ELEMENT_PS_CROSS_LINK } from '../plugins/ps-cross-link';
import { PagesWidgetElement, PagesRichText, PagesElement, PagesDescendant } from '../pages-plate-types';

export const wrapWithTag = (tagName: string, children?: unknown, attributes = ''): string => {
  return `<${`${tagName} ${attributes}`.trim()}>${children ?? ''}</${tagName}>`;
};

export const getHTMLString = (widget: TextWidget | TableWidget): string => {
  return widget.content ?? wrapWithTag('p');
};

const getTextStyles = (child: ChildNode): Partial<PagesRichText> => {
  const el = child as HTMLElement;
  return {
    ...(el.style.borderBottom === '1px solid'
      ? {
          underline: true,
        }
      : {}),
    ...(el.style.textDecoration === 'line-through'
      ? {
          strikethrough: true,
        }
      : {}),
    // HACK there is _some_ kind of bug where sometimes the colors all get converted to rgb values.
    // Haven't been able to reproduce consistently, but usually see it happen if you manipulate multiple words with color and toggle the color menu buttons a lot.
    ...(el.style.color ? { color: rgb2hex(el.style.color).hex } : {}),
    ...(el.style.backgroundColor ? { background: rgb2hex(el.style.backgroundColor).hex } : {}),
  };
};

const parser = new DOMParser();

export function deserializeHtml(htmlString: string) {
  const { body } = (() => {
    const doc = parser.parseFromString(htmlString, 'text/html');

    // to support legacy task_templates.text values, we need to support html strings with root siblings
    if (doc.body.childNodes.length > 1) {
      return parser.parseFromString(wrapWithTag('fragment', htmlString), 'text/html');
    }
    return doc;
  })();

  return deserialize(body);
}

export const widgetToSlateElement = (widget: Widget): PagesWidgetElement => {
  return match<Widget, PagesWidgetElement>(widget)
    .with({ header: { type: WidgetType.Text } }, w => {
      const [element] = deserializeHtml(getHTMLString(w));
      return { ...element, widget: w };
    })

    .with({ header: { type: WidgetType.Table } }, w => {
      const [element] = deserializeHtml(getHTMLString(w));
      return { widget: w, type: ELEMENT_PS_TABLE, children: [element] };
    })

    .with({ header: { type: WidgetType.File } }, w => {
      return { widget: w, type: ELEMENT_PS_FILE, children: [{ text: '' }] } as PagesWidgetElement<FileWidget>;
    })
    .with({ header: { type: WidgetType.Image } }, w => {
      return { widget: w, type: ELEMENT_PS_IMAGE, children: [{ text: '' }] } as PagesWidgetElement<ImageWidget>;
    })
    .with({ header: { type: WidgetType.Video } }, w => {
      return { widget: w, type: ELEMENT_PS_VIDEO, children: [{ text: '' }] } as PagesWidgetElement<VideoWidget>;
    })
    .with({ header: { type: WidgetType.Embed } }, w => {
      return { widget: w, type: ELEMENT_PS_EMBED, children: [{ text: '' }] } as PagesWidgetElement<EmbedWidget>;
    })
    .with({ header: { type: WidgetType.CrossLink } }, w => {
      return {
        widget: w,
        type: ELEMENT_PS_CROSS_LINK,
        children: [{ text: '' }],
      } as PagesWidgetElement<CrossLinkWidget>;
    })
    .otherwise(() => {
      return { widget, type: 'p', children: [{ text: '' }] };
    });
};

const jsxElement = (opts: Partial<PagesElement>, children: RecursiveReturn[]) => jsx('element', opts, children);

/** This is a fallback normalization effort to prevent browsers (e.g., mobile safari on iOS) from converting plain text to links, which leads to invalid structure
 * e.g.,
 * ```html
 * <!-- Original from DB -->
 * <strong>
 *   4929281774
 * </strong>
 *
 * <!-- After iOS Safari parses-->
 * <strong>
 *   <a href="tel:4929281774">4929281774</a>
 * </strong>
 * ```
 */
export const stripElements = (child: RecursiveReturn): RecursiveReturn | RecursiveReturn[] => {
  if (isElement(child)) {
    return child.children.flatMap(stripElements);
  }
  return child;
};

const jsxText = (child: ChildNode, children: RecursiveReturn[], options: Partial<PagesRichText> = {}) => {
  return jsx('text', { ...getTextStyles(child), ...options } as PagesRichText, children.flatMap(stripElements));
};

// eslint-disable-next-line no-control-regex
const ReturnStringsPattern = new RegExp('[\x0d\x0a]+', 'gm');

// these overload types allow us to get a different return type for the initial document.body input compared to the recursive inputs
type RecursiveReturn = string | null | ReturnType<typeof jsx>;

export function deserialize(el: HTMLElement): [PagesWidgetElement];
export function deserialize(el: ChildNode): RecursiveReturn;

// For reference: https://docs.slatejs.org/concepts/09-serializing#deserializing
export function deserialize(el: HTMLElement | ChildNode): [PagesWidgetElement] | RecursiveReturn {
  if (el.nodeType === Node.TEXT_NODE) {
    if (ReturnStringsPattern.test(el.textContent || '')) {
      return el.textContent?.trim().replace(ReturnStringsPattern, '') === '' ? null : el.textContent;
    }
    return el.textContent;
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const children = Array.from(el.childNodes)
    .map(deserialize)
    .filter(c => c != null);

  // TODO normalize
  if (children.length === 0) {
    children.push({ text: '' });
  }

  return match(el.nodeName)
    .with('BODY', () => jsx('fragment', {}, children))
    .with('P', () => jsxElement({ type: 'p' as const }, children))
    .with('BR', () => jsxElement({ type: 'break' }, children))
    .with('FRAGMENT', () => jsxElement({ type: 'fragment' }, children))
    .with('STRONG', () => jsxText(el, children, { bold: true }))
    .with('EM', () => jsxText(el, children, { italic: true }))
    .with('SPAN', () => jsxText(el, children))
    .with('H1', () => jsxElement({ type: 'h1' }, children))
    .with('H2', () => jsxElement({ type: 'h2' }, children))
    .with('H3', () => jsxElement({ type: 'h3' }, children))
    .with('UL', () => jsxElement({ type: 'ul' }, children))
    .with('TABLE', () => jsxElement({ type: 'table' }, children))
    .with('THEAD', () => jsxElement({ type: 'thead' }, children))
    .with('TBODY', () => jsxElement({ type: 'tbody' }, children))
    .with('TR', () => jsxElement({ type: 'tr' }, children))
    .with('TH', () => jsxElement({ type: 'th' }, children))
    .with('TD', () => jsxElement({ type: 'td' }, children))
    .with('LI', () => {
      const li = el as HTMLLIElement;
      const depthAttr = li.attributes.getNamedItem('data-slate-depth')?.value ?? '1';
      const parsedDepth = parseInt(depthAttr, 10);
      return jsxElement({ type: 'li', depth: parsedDepth }, children);
    })
    .with('A', () => {
      const link = el as HTMLAnchorElement;
      // This is needed because if it's a merge tag (i.e. has the form {{ task.URL }}),
      // the DOMParser will escape it and prefix with the base URL
      const rawHref = link.getAttributeNode('href')?.value;
      return jsxElement({ type: 'a', href: rawHref, target: link.target, rel: link.rel as 'nofollow' }, children);
    })
    .otherwise(() => el.textContent);
}

const Formatters: Array<[(n: PagesRichText) => boolean, (s: string, node: PagesRichText) => string]> = [
  [n => Boolean(n.bold), s => wrapWithTag('strong', s)],
  [n => Boolean(n.italic), s => wrapWithTag('em', s)],
  [n => Boolean(n.underline), s => wrapWithTag('span', s, 'style="border-bottom: 1px solid;"')],
  [n => Boolean(n.strikethrough), s => wrapWithTag('span', s, 'style="text-decoration: line-through;"')],
  [
    n => Boolean(n.background) || Boolean(n.color),
    (s, n) => {
      const backgroundColor = n.background ? `background-color: ${safeRgb2Hex(n.background)};` : '';
      const color = n.color ? `color: ${safeRgb2Hex(n.color)};` : '';
      const properties = [backgroundColor, color].filter(prop => Boolean(prop)).join(' ');

      return wrapWithTag('span', s, `style="${properties}"`);
    },
  ],
];

const safeRgb2Hex = (color: string): string => {
  try {
    return rgb2hex(color).hex;
  } catch {
    console.warn(`Unable to parse color [${color}] to hex.  Defaulting to black.`);
    return '#000000';
  }
};

export const serialize = (node: PagesDescendant): string => {
  if (isText(node)) {
    return Formatters.filter(([predicate]) => predicate(node)).reduce(
      (text, [, formatter]) => formatter(text, node),
      escapeHtml(node.text),
    );
  }

  const children = node.children.map(serialize).join('');

  return (
    match(node)
      .with({ type: 'p' }, _node => wrapWithTag('p', children))
      .with({ type: 'table' }, _node => wrapWithTag('table', children))
      .with({ type: 'tbody' }, _node => wrapWithTag('tbody', children))
      .with({ type: 'thead' }, _node => wrapWithTag('thead', children))
      .with({ type: 'th' }, _node => wrapWithTag('th', children))
      .with({ type: 'tr' }, _node => wrapWithTag('tr', children))
      .with({ type: 'td' }, _node => wrapWithTag('td', children))
      .with({ type: 'break' }, _node => '<br />')
      .with({ type: 'fragment' }, _node => children)
      .with({ type: 'h1' }, _node => wrapWithTag(`h1`, children))
      .with({ type: 'h2' }, _node => wrapWithTag(`h2`, children))
      .with({ type: 'h3' }, _node => wrapWithTag(`h3`, children))
      .with({ type: 'ul' }, _node => wrapWithTag(`ul`, children))
      // TODO: maybe look a_bove this (2 levels) for a UL, and if there is one, then wrap it in a `ul`.
      .with({ type: 'li' }, node => wrapWithTag(`li`, children, `data-slate-depth="${node.depth}"`))
      .with({ type: 'a' }, node =>
        wrapWithTag(`a`, children, `href="${node.href}" target="${node.target}" rel="${node.rel}"`),
      )
      .otherwise(() => children)
  );
};
