import { Plan } from '@process-street/subgrade/billing';
import { MindsharePlanId, WholesalePlanId, GodPlanId, AllPlanDescriptors, PlanIdMap } from 'services/plans';
import { Descriptor } from 'pages/organizations/manage/billing/models';

// These are plans that we don't want to show the legacy warning for
const SPECIAL_PLAN_IDS = [GodPlanId, MindsharePlanId, WholesalePlanId];

export const toDescriptor = (lookup: PlanIdMap = {}): Descriptor => {
  return Object.entries(lookup).reduce((acc, [id, { track, level, interval }]) => {
    if (!acc[track]) acc[track] = {};
    if (!acc[track]![level]) acc[track]![level] = {};
    acc[track]![level]![interval] = id;
    return acc;
  }, {} as Descriptor);
};

export const findDescriptor = (planId: Plan['id']): Descriptor | undefined => {
  const descriptor = AllPlanDescriptors.find(descriptor => Boolean(descriptor[planId]));
  return descriptor && toDescriptor(descriptor);
};

export const isPlanIdLegacy = (planId: Plan['id']) => {
  const descriptorFound = findDescriptor(planId);
  const planIdIsSpecial = SPECIAL_PLAN_IDS.includes(planId);
  return !descriptorFound && !planIdIsSpecial;
};

export * from './get-is-paid-account';
