import React from 'react';
import { CsvLastRun } from 'components/run-from-csv/model/CsvLastRun';
import { Box } from 'components/design/next';
import { DateUtils } from '@process-street/subgrade/util';

export interface LastRunInfoProps {
  lastRun: CsvLastRun;
}

export const LastRunInfo: React.FunctionComponent<React.PropsWithChildren<LastRunInfoProps>> = ({ lastRun }) => {
  const date = DateUtils.formatDateFromNow(lastRun.createdDate);

  return (
    <Box>
      <Box>
        Filename "{lastRun.fileName}" uploaded {date}
      </Box>
      {lastRun.processedCount} of {lastRun.recordCount} lines have been processed, with{' '}
      {lastRun.processedCount - lastRun.errorCount} successful and {lastRun.errorCount} errors.
      <br />
      <br />
      {lastRun.errors.length > 0 && (
        <Box>
          <Box>
            <b>Errors:</b>
          </Box>
          {lastRun.errors.map(error => (
            <Box key={error.lineNo}>
              Line {error.lineNo} - {error.error}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
