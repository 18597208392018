import { Muid } from '@process-street/subgrade/core';
import { captureException, captureMessage, configureScope, Severity, User, withScope } from '@sentry/browser';
import { SentryStateContext } from './sentry-state-context';
import { CaptureContext } from '@sentry/types';

const EmptyTag = 'none';

class SentryServiceImpl {
  public setUserContext(context: User) {
    configureScope(scope => {
      scope.setUser(context);
    });
  }

  public setOrganizationId(organizationId: Muid): void {
    configureScope(scope => {
      scope.setTag('organizationId', organizationId);
    });
  }

  public setStateContext(context: SentryStateContext): void {
    configureScope(scope => {
      scope.setTag('templateId', context.templateId || EmptyTag);
      scope.setTag('checklistId', context.checklistId || EmptyTag);
      scope.setTag('taskTemplateGroupId', context.taskTemplateGroupId || EmptyTag);
    });
  }

  public clearContext(): void {
    configureScope(scope => {
      scope.clear();
    });
  }

  public captureMessage(message: string, severity: Severity = Severity.Error): void {
    captureMessage(message, severity);
  }

  public captureMessageWithMeta(
    message: string,
    meta: { [key: string]: string | Record<string, unknown> } = {},
    severity: Severity = Severity.Error,
  ): void {
    withScope(scope => {
      Object.keys(meta).forEach((key: string) => {
        scope.setExtra(key, meta[key]);
      });
      captureMessage(message, severity);
    });
  }

  public captureException(exception: any, captureContext?: CaptureContext) {
    captureException(exception, captureContext);
  }
}

export const SentryService = new SentryServiceImpl();
