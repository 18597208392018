import { Plan, PlanInterval, PlanLevel } from '../../billing';
import { PlanFeatureSet } from '../../billing/plan-model';
import { MuidUtils } from '../../core';
import {
  TEST_BUSINESS_PLAN,
  TEST_BUSINESS_PRO_PLAN,
  TEST_LEGACY_PLAN,
  TEST_FREEMIUM_FREE_PLAN,
  TEST_FREE_TRIAL_FREE_PLAN,
  TEST_FREEMIUM_PRO_PLAN,
  TEST_FREE_TRIAL_ENTERPRISE_T5K_STARTUP_PLAN,
  TEST_FREE_TRIAL_PRO_T5K_STARTUP_PLAN,
} from '../plans';
import { generateAudit } from './audit-generator';

const testProcessStreetAdmin = 'gejt4aeMNWhvlsbQ9e5I3A';

export const businessPlanFeatureSet: PlanFeatureSet = {
  id: 'oVBsFAwtzNxgCHyYkDJOJg',
  name: 'Unlimited',
  softCap: false,
};

export const FremiumPlanFeatureSet: PlanFeatureSet = {
  name: '5/5/5 Free',
  usersLimit: 5,
  activeChecklistsLimit: 5,
  activeTemplatesLimit: 5,
  checklistRunsLimit: 5,
  softCap: false,
  id: '',
};

export const Fremium111PlanFeatureSet: PlanFeatureSet = {
  name: '1/1/1 Free',
  usersLimit: 1,
  activeChecklistsLimit: 1,
  activeTemplatesLimit: 1,
  checklistRunsLimit: 1,
  softCap: false,
  id: '',
};

const businessPlan: Plan = {
  id: TEST_BUSINESS_PLAN,
  audit: generateAudit(testProcessStreetAdmin),
  featureSet: businessPlanFeatureSet,
  name: 'Small',
  level: PlanLevel.Business,
  interval: PlanInterval.Monthly,
  cost: 30,
  maxUsers: 10,
  active: false,
  minQuantity: 0,
};

const legacyFeatureSet: PlanFeatureSet = {
  id: 'oVBsFAwtzNxgCHyYkDJOJg',
  name: 'Unlimited',
  softCap: false,
};

const legacyPlan: Plan = {
  id: TEST_LEGACY_PLAN,
  audit: generateAudit(testProcessStreetAdmin),
  featureSet: legacyFeatureSet,
  name: 'Friends & Family',
  level: PlanLevel.Free,
  interval: PlanInterval.Monthly,
  cost: 0,
  maxUsers: 3,
  active: false,
  minQuantity: 0,
};

const businessProFeatureSet: PlanFeatureSet = {
  id: 'oVBsFAwtzNxgCHyYkDJOJg',
  name: 'Unlimited',
  softCap: false,
};

const businessProPla: Plan = {
  id: TEST_BUSINESS_PRO_PLAN,
  audit: generateAudit(testProcessStreetAdmin),
  featureSet: businessProFeatureSet,
  name: 'BP3 Enterprise (Yearly)',
  level: PlanLevel.Enterprise,
  interval: PlanInterval.Yearly,
  cost: 1000,
  active: true,
  minQuantity: 0,
};

const freemiumFreePlan: Plan = {
  id: TEST_FREEMIUM_FREE_PLAN,
  audit: generateAudit(testProcessStreetAdmin),
  featureSet: businessProFeatureSet,
  name: 'Freemium Free (Yearly)',
  level: PlanLevel.Free,
  interval: PlanInterval.Yearly,
  cost: 1000,
  active: true,
  minQuantity: 0,
};

const freemiumProPlan: Plan = {
  id: TEST_FREEMIUM_PRO_PLAN,
  audit: generateAudit(testProcessStreetAdmin),
  featureSet: businessProFeatureSet,
  name: 'Freemium Pro (Yearly)',
  level: PlanLevel.Professional,
  interval: PlanInterval.Yearly,
  cost: 1000,
  active: true,
  minQuantity: 0,
};

const freeTrialFreePlan: Plan = {
  id: TEST_FREE_TRIAL_FREE_PLAN,
  audit: generateAudit(testProcessStreetAdmin),
  featureSet: businessProFeatureSet,
  name: 'Free Trial Free (Yearly)',
  level: PlanLevel.Free,
  interval: PlanInterval.Yearly,
  cost: 1000,
  active: true,
  minQuantity: 5,
};

const freeTrialEnterpriseT5KStartupPlan: Plan = {
  id: TEST_FREE_TRIAL_ENTERPRISE_T5K_STARTUP_PLAN,
  audit: generateAudit(testProcessStreetAdmin),
  featureSet: businessProFeatureSet,
  name: 'T5K PS Startup (Monthly)',
  level: PlanLevel.Enterprise,
  interval: PlanInterval.Monthly,
  cost: 100,
  active: true,
  minQuantity: 5,
};

const freeTrialProT5KStartupPlan: Plan = {
  id: TEST_FREE_TRIAL_PRO_T5K_STARTUP_PLAN,
  audit: generateAudit(testProcessStreetAdmin),
  featureSet: businessProFeatureSet,
  name: 'T5K PS Startup (Monthly)',
  level: PlanLevel.Professional,
  interval: PlanInterval.Monthly,
  cost: 100,
  active: true,
  minQuantity: 5,
};

export const generateTestPlans = () => ({
  [TEST_BUSINESS_PLAN]: businessPlan,
  [TEST_BUSINESS_PRO_PLAN]: businessProPla,
  [TEST_LEGACY_PLAN]: legacyPlan,
  [TEST_FREEMIUM_FREE_PLAN]: freemiumFreePlan,
  [TEST_FREE_TRIAL_FREE_PLAN]: freeTrialFreePlan,
  [TEST_FREE_TRIAL_ENTERPRISE_T5K_STARTUP_PLAN]: freeTrialEnterpriseT5KStartupPlan,
  [TEST_FREE_TRIAL_PRO_T5K_STARTUP_PLAN]: freeTrialProT5KStartupPlan,
  [TEST_FREEMIUM_PRO_PLAN]: freemiumProPlan,
});

export const generatePlan = (plan?: Partial<Plan>): Plan => ({
  id: MuidUtils.randomMuid(),
  audit: generateAudit(),
  featureSet: businessPlanFeatureSet,
  name: 'Generated plan',
  level: PlanLevel.Business,
  interval: PlanInterval.Monthly,
  cost: 100,
  active: true,
  minQuantity: 0,
  ...plan,
});
