import * as React from 'react';
import { FileWidget, ImageWidget } from '@process-street/subgrade/process/widget-model';
import { PagesWidgetElement, usePagesEditorRef, PagesRenderElementProps } from '../../pages-plate-types';
import { ImageUI } from './components/image-ui';
import { PSEditor } from 'pages/pages/_id/edit/page/utils/ps-editor';
import { UploadElement } from 'pages/pages/_id/edit/page/components/upload';
import { Icon } from 'components/design/next';
import { EditorVoid } from '../elements/void';
import { useIsEditable } from 'features/rich-text';

export const _ImageElement: React.FC<
  React.PropsWithChildren<PagesRenderElementProps<PagesWidgetElement<ImageWidget>>>
> = ({ attributes, element, children }) => {
  const { widget } = element;
  const editor = usePagesEditorRef();
  const editable = useIsEditable();

  const handleOnUploadFinish = React.useCallback(
    (updatedWidget: ImageWidget | FileWidget) => {
      // Mutation Slate state through node's widget change
      PSEditor.setWidgetElement(editor, updatedWidget as ImageWidget);
    },
    [editor],
  );

  return (
    <EditorVoid attributes={attributes} contentEditable={false}>
      {widget.file === undefined && editable && (
        <UploadElement
          data-hide-on-print
          widget={widget}
          onFinish={handleOnUploadFinish}
          uploadingMessage="Uploading image..."
          acceptMimeTypes="image/*"
        >
          <Icon icon="image" size="4" color="gray.600" mr="2" />
          Upload Image
        </UploadElement>
      )}
      {widget.file !== undefined && <ImageUI {...widget} editable={editable} />}
      {children}
    </EditorVoid>
  );
};

export const ImageElement = React.memo(_ImageElement, (a, b) => {
  // TODO when we support resizing, we should check for that here as well
  return a.element.widget.file?.id === b.element.widget.file?.id;
});
