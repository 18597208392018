import angular from 'angular';
import { generateLocation, generateTo, getTabByName, getTabIndexByName } from 'services/tab-service';
import propertiesTemplate from './properties.component.html';
import membersTemplate from './members.component.html';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'FolderManageCtrl',
    function (
      $scope,
      $state,
      $timeout,
      DataService,
      FolderService,
      SessionService,
      Subject,
      UserService,
      ToastService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'FolderManageCtrl' });
      logger.info('loading ctrl');

      const propertiesTab = {
        name: 'properties',
        heading: 'Properties',
        templateUrl: propertiesTemplate,
      };

      const permissionsTab = {
        name: 'permissions',
        heading: 'Permissions',
        templateUrl: membersTemplate,
      };

      ctrl.$onInit = () => {
        initializePermissions();
        initializeFolder();

        if (!$scope.folder) {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems loading the folder`,
            description: DefaultErrorMessages.notFoundErrorDescription,
          });
          $timeout(() => {
            $state.go('dashboard');
          });
        } else {
          const canUpdateFolder = $scope.subject.canUpdateFolder($scope.folder.id);
          const isPrivateFolder = FolderService.isPrivateFolder($scope.folder);

          $scope.tabs = [canUpdateFolder ? [propertiesTab] : [], !isPrivateFolder ? [permissionsTab] : []].flat();
          $scope.activeIndex = getTabIndexByName($scope.tabs, $state.params.tab) || 0;

          $timeout(() => {
            initializeTabs($scope.tabs);
          });
        }
      };

      $scope.getFolderPath = function (folder) {
        return FolderService.toSlugPath(folder);
      };

      // Permissions
      function initializePermissions() {
        const sessionUser = UserService.getCurrentUser();
        const selectedOrganizationId = SessionService.getSelectedOrganizationId();
        $scope.subject = new Subject(sessionUser.id, selectedOrganizationId);
      }

      $scope.$on('$stateChangeSuccess', (__event, __toState, toParams) => {
        $scope.activeIndex = getTabIndexByName($scope.tabs, toParams.tab) || 0;
      });

      // Initialization
      function initializeTabs(tabs) {
        tabs.forEach(tab => {
          tab.select = function () {
            this.selectedOnce = true;

            const to = generateTo($state.current.name);
            const location = generateLocation($state.current.name);
            if ($state.includes('folderManage')) {
              $state.go(to, { tab: tab.name, index: null }, { location });
            }
          };
        });

        const selectedTab = getTabByName($scope.tabs, $state.params.tab) || tabs[0];
        selectedTab.select();
      }

      function initializeFolder() {
        $scope.folder = DataService.getCollection('folders').get($state.params.id);

        $scope.$on(EventName.FOLDER_UPDATED, (__event, folder) => {
          $scope.folder = folder;
        });
      }
    },
  );
