import { Muid } from '@process-street/subgrade/core';
import { Checklist, ChecklistMigrationStatus, ChecklistRevision } from '@process-street/subgrade/process';
import { StateService } from '@uirouter/angularjs';
import angular, { IDeferred } from 'angular';
import { ChecklistMigrationModalOptions } from 'components/migration/checklist/checklist-migration-modal-options';
import { ChecklistMigrationService } from 'components/migration/services/checklist-migration-service';
import {
  ChecklistMigrationActions,
  ChecklistMigrationActionsSetStatsByChecklistType,
} from 'components/migration/store/checklist-migration.actions';
import ngRedux from 'ng-redux';
import { ChecklistActions, ChecklistActionsGetByIdType } from 'reducers/checklist/checklist.actions.interface';
import { connectService } from 'reducers/util';
import { Action } from 'redux-actions';
import { IModalInstanceService, MessageBox } from 'services/message-box.interface';

const SINGLE_CHECKLIST_MIGRATION_POLL_DELAY = 2000;

export interface Actions {
  getChecklistById: ChecklistActionsGetByIdType;
  setStatsByChecklist: ChecklistMigrationActionsSetStatsByChecklistType;
}

export class ChecklistMigrationServiceImpl implements ChecklistMigrationService {
  static $inject = [
    '$ngRedux',
    '$q',
    '$state',
    '$timeout',
    'ChecklistActions',
    'MessageBox',
    'ChecklistMigrationActions',
  ];
  public actions!: Actions;

  public checklistMigrationService: any;
  private $uibChecklistMigrationModal!: IModalInstanceService;

  constructor(
    private $ngRedux: ngRedux.INgRedux,
    private $q: angular.IQService,
    private $state: StateService,
    private $timeout: angular.ITimeoutService,
    checklistActions: ChecklistActions,
    private messageBox: MessageBox,
    checklistMigrationActions: ChecklistMigrationActions,
  ) {
    const mapDispatchToThis = {
      getChecklistById: checklistActions.getById,
      setStatsByChecklist: checklistMigrationActions.setStatsByChecklist,
    };
    connectService('ChecklistMigrationService', this.$ngRedux, null, mapDispatchToThis)(this);
  }

  public showChecklistMigrationModal(checklistRevision: ChecklistRevision): IDeferred<boolean> {
    const options: ChecklistMigrationModalOptions = {
      checklistRevision,
      interrupted: false,
    };

    this.doShowChecklistMigrationModal(options);

    const deferred: IDeferred<boolean> = this.$q.defer();
    this.checkChecklistMigrationProgress(options, deferred);

    deferred.promise.then(interrupted => {
      if (this.$uibChecklistMigrationModal) {
        this.$uibChecklistMigrationModal.close();
        if (!interrupted) {
          this.refreshPageIfOnChecklist();
        }
      }
    });

    return deferred;
  }

  /**
   * Refresh if user on workflow page
   */
  public refreshPageIfOnChecklist(): void {
    if (this.$state.includes('checklist')) {
      this.$state.go(this.$state.current, {}, { reload: true });
    }
  }

  public checkChecklistMigrationProgressAndRefreshOnFinish(options: ChecklistMigrationModalOptions): void {
    const deferred: IDeferred<boolean> = this.$q.defer();
    this.checkChecklistMigrationProgress(options, deferred);
    deferred.promise.then(interrupted => {
      if (!interrupted) {
        this.refreshPageIfOnChecklist();
      }
    });
  }

  public checkChecklistMigrationProgress(options: ChecklistMigrationModalOptions, deferred: IDeferred<boolean>): void {
    const checklistId: Muid = options.checklistRevision.checklist.id;
    this.actions.getChecklistById(checklistId, true /* flushCache */).then((result: Action<Checklist>) => {
      const { migrationStatus } = result.payload;
      this.actions.setStatsByChecklist(checklistId, migrationStatus, false /* migratable */);

      const migrating = migrationStatus !== ChecklistMigrationStatus.Inactive;
      if (options.interrupted) {
        deferred.resolve(true /* interrupted */);
      } else if (migrating) {
        this.$timeout(() => {
          this.checkChecklistMigrationProgress(options, deferred);
        }, SINGLE_CHECKLIST_MIGRATION_POLL_DELAY);
      } else {
        deferred.resolve(false /* interrupted */);
      }
    });
  }

  public markAsMigrating(checklistRevision: ChecklistRevision): void {
    this.actions.setStatsByChecklist(
      checklistRevision.checklist.id,
      ChecklistMigrationStatus.Scheduled,
      false /* migratable */,
    );
  }

  public markAsMigratable(checklistRevision: ChecklistRevision): void {
    this.actions.setStatsByChecklist(
      checklistRevision.checklist.id,
      ChecklistMigrationStatus.Inactive,
      true /* migratable */,
    );
  }

  private doShowChecklistMigrationModal(options: ChecklistMigrationModalOptions): void {
    this.$uibChecklistMigrationModal = this.messageBox.customComponent({
      component: 'psChecklistMigrationModal',
      options,
    });
  }
}
