import angular from 'angular';
import templateUrl from './template-file-field.component.html';
import './template-file-field.scss';
import { FormFieldEvent } from 'services/form-field-event';

angular.module('frontStreetApp.directives').component('psTemplateFileFormField', {
  bindings: {
    widget: '<',
    editable: '<',
    onUpdateWidget: '&',
  },
  templateUrl,
  controller($rootScope) {
    const ctrl = this;

    // Viewable

    ctrl.clickDisabledButton = function () {
      $rootScope.$broadcast(FormFieldEvent.DISABLED_FORM_FIELD_CLICKED);
    };

    // Editable

    ctrl.updateFormFieldWidget = function () {
      ctrl.onUpdateWidget({ widget: ctrl.widget });
    };
  },
});
