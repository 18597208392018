import angular from 'angular';
import { MultiSelectItemValueStatus } from '@process-street/subgrade/process';
import { UrlUtils } from 'components/utils/url-utils';
import templateUrl from './checklist-multi-select-field.component.html';
import { trace } from 'components/trace';
import { queryClient } from 'components/react-root';
import { QueryObserver } from 'react-query';
import { ResolvedMergeTagsByChecklistRevisionIdQuery } from 'features/merge-tags/query-builder';
import { MergeTagMode, MergeTagTarget } from '@process-street/subgrade/form';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { FormFieldEvent } from 'services/form-field-event';

angular.module('frontStreetApp.directives').component('psChecklistMultiSelectFormField', {
  bindings: {
    user: '<',
    checklistRevision: '<',
    widget: '<',
    editable: '<',
    formFieldValue: '<',
    onUpdateValue: '&',
    readOnly: '<',
    taskId: '<',
  },
  templateUrl,
  controller($filter, $rootScope, $q) {
    const ctrl = this;
    this.$q = $q;
    const logger = trace({ name: 'MultiSelectFieldCtrl' });

    ctrl.$onInit = function () {
      subscribeToMergeTagsQuery();
    };

    ctrl.$onDestroy = function () {
      ctrl.unsubscribeFromMergeTagsQuery();
    };

    function subscribeToMergeTagsQuery() {
      const observer = new QueryObserver(queryClient, {
        queryKey: ResolvedMergeTagsByChecklistRevisionIdQuery.getKey({
          checklistRevisionId: ctrl.checklistRevision.id,
          mergeTagTarget: MergeTagTarget.GENERAL,
          includeLegacyTags: false,
          taskId: ctrl.taskId,
        }),
        queryFn: ResolvedMergeTagsByChecklistRevisionIdQuery.queryFn(queryClient),
        staleTime: Infinity,
        refetchOnReconnect: 'always',
        refetchOnWindowFocus: 'always',
        refetchOnMount: 'always',
      });

      ctrl.unsubscribeFromMergeTagsQuery = observer.subscribe(result => {
        ctrl.tags = result.data ?? [];
      });
    }

    ctrl.$onChanges = function (changes) {
      if (
        changes.formFieldValue &&
        changes.formFieldValue.currentValue &&
        changes.formFieldValue.currentValue.fieldValue
      ) {
        const fieldValue = angular.copy(changes.formFieldValue.currentValue.fieldValue);
        ctrl.fieldValue = ctrl.generateValueMap(ctrl.widget.config.items, fieldValue);
      }
    };

    ctrl.updateFormFieldValue = function (itemValues) {
      return ctrl.onUpdateValue({
        widget: ctrl.widget,
        fieldValue: {
          itemValues,
        },
      });
    };

    ctrl.generateValueMap = function (items, fieldValue) {
      const valueMap = {};

      (items || []).forEach(item => {
        valueMap[item.id] = {
          id: item.id,
          status: MultiSelectItemValueStatus.NotCompleted,
        };
      });

      (fieldValue.itemValues || []).forEach(itemValue => {
        valueMap[itemValue.id] = itemValue;
      });

      return valueMap;
    };

    ctrl.toggleItemStatus = function (event, widget, item, checklistRevision) {
      if (checklistRevision && ctrl.editable && !ctrl.readOnly) {
        const itemValue = ctrl.fieldValue[item.id];

        switch (itemValue.status) {
          case MultiSelectItemValueStatus.Completed:
            itemValue.status = MultiSelectItemValueStatus.NotCompleted;
            break;
          case MultiSelectItemValueStatus.NotCompleted:
            itemValue.status = MultiSelectItemValueStatus.Completed;
            break;
          default:
            logger.error('unexpected status: %s', itemValue.status);
        }

        $rootScope.$broadcast(FormFieldEvent.SUB_CHECKLIST_ITEM_STATUS_UPDATED, item, itemValue, {
          taskTemplate: widget.header.taskTemplate,
          checklist: ctrl.checklistRevision.checklist,
          template: ctrl.checklistRevision.checklist.template,
        });

        event.stopPropagation();

        updateMultiSelectValue(widget);
      }
    };

    function updateMultiSelectValue(widget) {
      const itemValues = [];
      widget.config.items.forEach(item => {
        if (ctrl.fieldValue[item.id]) {
          itemValues.push(ctrl.fieldValue[item.id]);
        }
      });

      ctrl.updateFormFieldValue(itemValues);
    }

    ctrl.setTargetAttribute = function (url) {
      return {
        target: UrlUtils.isAppUrl(url) ? '_self' : '_blank',
      };
    };

    ctrl.shouldShowAudit = () => Boolean(ctrl.formFieldValue?.audit);

    ctrl.parseMergeTags = content => {
      const value =
        content === undefined
          ? ''
          : MergeTagStringReplacementUtils.replaceResolvedTagsValues(ctrl.tags ?? [], content, MergeTagMode.PLAINTEXT);

      if (value.includes('Variable value is missing')) return value;

      return $filter('psLinky')(value, null, ctrl.setTargetAttribute);
    };
  },
});
