import { PlanLevel, PlanTrack } from '@process-street/subgrade/billing';
import { PlanFeatureList } from 'components/billing/PlanFeatureList';
import { getPaidPlansFeaturesMap } from 'components/billing/plans/features';
import { PlanSelectButton } from 'components/billing/PlanSelectButton';
import { PlanTopPanel } from 'components/billing/PlanTopPanel';
import { useFeatureFlags } from 'features/feature-flags';
import React from 'react';
import { usePlanTrack } from 'services/use-plan-track';
import { PlanLevelSelectorState } from '../../../directives/billing/plan-level-selector/plan-level-selector-service.interface';
import './PlanLevelPricingItem.scss';

interface PlanLevelPricingItemProps {
  level: PlanLevel;
  cost: string;
  selectorState: PlanLevelSelectorState;
  onSelect: (level: PlanLevel) => void;
}

const getSelectedClass = (level: PlanLevel, selected: boolean) =>
  selected ? `selected-level-${level.toString().toLowerCase()}` : '';

export const PlanLevelPricingItem: React.FunctionComponent<React.PropsWithChildren<PlanLevelPricingItemProps>> = ({
  level,
  selectorState,
  cost,
  onSelect,
}) => {
  const track = usePlanTrack();
  const onFreemium = track === PlanTrack.Freemium;
  const selected = selectorState === PlanLevelSelectorState.SELECTED_PLAN;
  const selectedClass = getSelectedClass(level, selected);
  const featureFlags = useFeatureFlags();

  if (!track) {
    return null;
  }

  const noLegacyTrack = onFreemium ? PlanTrack.Freemium : PlanTrack.FreeTrial;

  return (
    <div className={`${onFreemium ? 'col-sm-6' : 'col-sm-4'} plan-level-pricing-item ${selectedClass}`}>
      <PlanTopPanel level={level} selected={selected} cost={cost} />
      <PlanSelectButton level={level} selectorState={selectorState} onSelect={onSelect} />
      <PlanFeatureList featureMap={getPaidPlansFeaturesMap(featureFlags, noLegacyTrack)[level]} />
    </div>
  );
};
