import angular from 'angular';
import { canAccess, Feature } from 'services/features/features';
import stopTaskTemplate from './stop-task.component.html';
import addStopTaskModalTemplate from '../../../stop-task-button/add-stop-task/add-stop-task.component.html';
import './stop-task.scss';

angular.module('frontStreetApp.directives').component('psTaskTemplatesStopTask', {
  bindings: {
    organization: '<',
    user: '<',
    onlyAdd: '<',
    onAdd: '&',
    onRemove: '&',
  },
  templateUrl: stopTaskTemplate,
  controller($q, MessageBox, OrganizationMembershipService, SessionService) {
    const ctrl = this;

    ctrl.$onInit = function () {
      // This is here to allow unit test to run
    };

    ctrl.$onChanges = function (changes) {
      if (changes.organization) {
        calculateFeatureIsAvailable();
        calculateUserIsAdmin();
      }
      if (changes.user) {
        calculateUserIsAdmin();
      }
    };

    function calculateFeatureIsAvailable() {
      if (ctrl.organization) {
        const planId = ctrl.organization.subscription.plan.id;
        ctrl.featureIsAvailable = canAccess(Feature.STOP_TASK, planId);
      } else {
        ctrl.featureIsAvailable = false;
      }
    }

    function calculateUserIsAdmin() {
      if (ctrl.organization && ctrl.user) {
        OrganizationMembershipService.isAdminByUserIdAndOrganizationId(ctrl.user.id, ctrl.organization.id).then(
          userIsAdmin => {
            ctrl.userIsAdmin = userIsAdmin;
          },
          () => {
            ctrl.userIsAdmin = false;
          },
        );
      } else {
        ctrl.userIsAdmin = false;
      }
    }

    ctrl.canUpgrade = function () {
      return !ctrl.featureIsAvailable && ctrl.userIsAdmin;
    };

    ctrl.add = function () {
      if (!SessionService.getTemplateEditorProperty('addStopTaskModalSeen') || ctrl.canUpgrade()) {
        SessionService.setTemplateEditorProperty('addStopTaskModalSeen', true /* value */);

        MessageBox.custom({
          templateUrl: addStopTaskModalTemplate,
          controller: 'AddStopTaskCtrl',
          options: {
            addStop: () => {
              ctrl.onAdd();
              return $q.resolve();
            },
            featureIsAvailable: ctrl.featureIsAvailable,
            userIsAdmin: ctrl.userIsAdmin,
          },
        });
      } else {
        ctrl.onAdd();
      }
    };

    ctrl.remove = function () {
      ctrl.onRemove();
    };
  },
});
