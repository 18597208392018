import angular from 'angular';
import templateUrl from './checklist-header.component.html';
import './checklist-header.scss';

angular.module('frontStreetApp.directives').component('psChecklistHeader', {
  bindings: {
    actionable: '<',
    title: '<',
    onUpdate: '&',
    toggleCompletedTasksVisibility: '<',
    hideCompletedTasks: '<',
  },
  templateUrl,
  controller(FeatureFlagService, ToastService) {
    const ctrl = this;

    ctrl.isMergeTagImprovementsEnabled = FeatureFlagService.getFeatureFlags().mergeTagImprovements;

    ctrl.$onInit = () => {
      const el = document.querySelector('.steps-header');
      const observer = new IntersectionObserver(
        ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
        { threshold: [1] },
      );

      observer.observe(el);
    };

    ctrl.handleTitleBlur = function (title) {
      if (!title || !title.length) {
        ToastService.openToast({
          status: 'warning',
          title: `Don't forget to name your workflow run!`,
        });
      }
    };

    ctrl.updateTitle = function (title) {
      if (title) {
        ctrl.onUpdate({ title });
      }
    };
  },
});
