import { ConditionalLogicUtils, NodeStatus, NormalizedData } from '../../utils/conditional-logic-utils';
import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { Muid } from '@process-street/subgrade/core';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import { produce } from 'immer';
import create from 'zustand';

export type ConditionalLogicModalAction =
  | { type: 'SET_SELECTED_WIDGET'; payload: Widget | null; shouldAutoCreateNewRule?: boolean }
  | { type: 'SET_SELECTED_TASK'; payload: TaskTemplate | null }
  | { type: 'SET_SELECTED_RULE'; payload: ChecklistRuleDefinition | null }
  | { type: 'SET_NODES_STATUS'; payload: Record<Muid, NodeStatus> }
  | { type: 'SET_NORMALIZED_DATA'; payload: NormalizedData }
  | { type: 'SET_SCROLL_TO_FIRST_RULE_WITH_ERROR'; payload: boolean }
  | { type: 'RESET_SELECTED_VALUES' }
  | { type: 'CLEAR_AUTO_CREATE_NEW_RULE' };

export type ConditionalLogicModalState = {
  selectedTask: TaskTemplate | null;
  selectedWidget: Widget | null;
  selectedRule: ChecklistRuleDefinition | null;
  shouldAutoCreateNewRule?: boolean;
  scrollToFirstRuleWithError: boolean;
  nodesStatus: Record<Muid, NodeStatus>;
  normalizedData: NormalizedData;
};

export type ConditionalLogicModalStore = ConditionalLogicModalState & {
  dispatch: (action: ConditionalLogicModalAction) => void;
};

export const modalReducer = (
  state: ConditionalLogicModalState,
  action: ConditionalLogicModalAction,
): ConditionalLogicModalState => {
  return match(action)
    .with({ type: 'CLEAR_AUTO_CREATE_NEW_RULE' }, () =>
      produce(state, draft => {
        draft.shouldAutoCreateNewRule = false;
      }),
    )
    .with({ type: 'SET_SELECTED_WIDGET' }, ({ payload, shouldAutoCreateNewRule }) =>
      produce(state, draft => {
        if (!payload) {
          draft.selectedWidget = null;
          draft.selectedRule = null;
          draft.shouldAutoCreateNewRule = false;
        } else {
          draft.selectedRule =
            state.normalizedData.rules.byFormFieldWidgetGroupId[payload.header.group.id]?.[0] ?? null;
          draft.selectedWidget = payload;
          draft.selectedTask = null;
          draft.shouldAutoCreateNewRule = shouldAutoCreateNewRule ?? false;
        }
      }),
    )
    .with({ type: 'SET_SELECTED_TASK' }, ({ payload }) =>
      produce(state, draft => {
        draft.selectedTask = payload;
      }),
    )
    .with({ type: 'SET_SELECTED_RULE' }, ({ payload }) =>
      produce(state, draft => {
        if (state.selectedWidget) {
          draft.selectedRule = payload;
        }
      }),
    )
    .with({ type: 'SET_NODES_STATUS' }, ({ payload }) =>
      produce(state, draft => {
        draft.nodesStatus = payload;
      }),
    )
    .with({ type: 'SET_NORMALIZED_DATA' }, ({ payload }) =>
      produce(state, draft => {
        draft.normalizedData = payload;
      }),
    )
    .with({ type: 'RESET_SELECTED_VALUES' }, () =>
      produce(state, draft => {
        draft.selectedWidget = null;
        draft.selectedRule = null;
        draft.selectedTask = null;
      }),
    )
    .with({ type: 'SET_SCROLL_TO_FIRST_RULE_WITH_ERROR' }, ({ payload }) =>
      produce(state, draft => {
        if (payload) {
          draft.selectedWidget = null;
          draft.selectedRule = null;
          draft.selectedTask = null;
        }

        draft.scrollToFirstRuleWithError = payload;
      }),
    )
    .otherwise(() => state);
};

export const useConditionalLogicModalStore = create<ConditionalLogicModalStore>(set => {
  return {
    selectedTask: null,
    selectedWidget: null,
    shouldAutoCreateNewRule: false,
    selectedRule: null,
    nodesStatus: {},
    scrollToFirstRuleWithError: false,
    normalizedData: ConditionalLogicUtils.getNormalizedData([], [], []),
    dispatch: action => set(state => modalReducer(state, action)),
  };
});
