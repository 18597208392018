import * as React from 'react';
import { Modal, ModalOverlay } from 'components/design/next';
import { UIMachineSelectors, useUIActorRef } from 'pages/forms/_id/shared';
import { useSelector } from '@xstate/react';

export const EditorModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { uiActorRef } = useUIActorRef();
  const modal = useSelector(uiActorRef, UIMachineSelectors.getModal);
  const modalProps = useSelector(uiActorRef, UIMachineSelectors.getModalProps);
  const overlayProps = useSelector(uiActorRef, UIMachineSelectors.getOverlayProps);

  return (
    <Modal isOpen={Boolean(modal)} onClose={() => uiActorRef.send({ type: 'CLOSE_MODAL' })} size="xl" {...modalProps}>
      <ModalOverlay {...overlayProps} />
      {modal}
    </Modal>
  );
};
