import { TypeOfEnumValue } from '@process-street/subgrade/core';
import { TextFormFieldWidget } from '../widget-model';

function textFormFieldCIF<
  Code extends string,
  Config extends Record<
    string,
    { code: Code; isInvalid: (constraints: NonNullable<TextFormFieldWidget['constraints']>, value: string) => boolean }
  >,
>(config: Config): Config {
  return config;
}

export const TextFormFieldValidation = textFormFieldCIF({
  notInBetween: {
    code: 'not-in-between',
    isInvalid: (constraints, value) =>
      !!constraints?.min &&
      !!constraints?.max &&
      !(value.length >= constraints?.min && value.length <= constraints.max),
  },
  maxChars: {
    code: 'max-chars',
    isInvalid: (constraints, value) => !!constraints.max && constraints.max < value.length,
  },
  minChars: {
    code: 'min-chars',
    isInvalid: (constraints, value) => !!constraints.min && constraints.min > value.length,
  },
});

export const getTextFormFieldWidgetErrorType = (
  constraints: TextFormFieldWidget['constraints'],
  value: string = '',
) => {
  if (value === '' || !constraints) return;
  return (
    Object.values(TextFormFieldValidation).find(config => {
      return config.isInvalid(constraints, value);
    })?.code ?? undefined
  );
};

export type TextFormFieldValidationCode = TypeOfEnumValue<typeof TextFormFieldValidation>['code'];
