import * as React from 'react';
import { Box, BoxProps, MotionWrapper } from 'components/design/next';
import { useChartBoxContext } from './context';

export const ChartBoxOverlay: React.FC<React.PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  const { setIsExpanded } = useChartBoxContext();

  return (
    <MotionWrapper initial={{ opacity: 0 }} animate={{ opacity: 0.5 }} exit={{ opacity: 0 }}>
      <Box
        role="button"
        onClick={setIsExpanded.off}
        aria-label="close chart dialog"
        position="absolute"
        width="100vw"
        h="100vh"
        top="0"
        left="0"
        zIndex="overlay"
        bg="gray.700"
        {...props}
      />
    </MotionWrapper>
  );
};
