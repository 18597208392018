import * as React from 'react';
import { Tooltip, Button, TooltipProps, ButtonProps } from 'components/design/next';

export const ToolbarButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return (
    <Button variant="ghost" _active={{ bgColor: 'gray.600' }} _hover={{ bgColor: 'gray.600' }} {...props} ref={ref} />
  );
});

export const ToolbarTooltip: React.FC<React.PropsWithChildren<TooltipProps>> = React.memo(props => {
  return <Tooltip placement="top" shouldWrapChildren={true} hasArrow={true} {...props} />;
});
