import { WidgetHeader, WidgetLike } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type WidgetContentAction =
  | 'Generate'
  | 'MakeShorter'
  | 'MakeLonger'
  | 'ChangeToneFormal'
  | 'ChangeToneCasual'
  | 'ChangeToneFriendly'
  | 'ContinueWriting';

export type GenerateWidgetContentQueryParams = {
  headerId: WidgetHeader['id'];
  action: WidgetContentAction;
  content?: string;
  selection?: string;
};

type GenerateWidgetContentQueryResponse = WidgetLike;

const key = ['ai-generation', 'widget-content'];
export const GenerateWidgetContentMutation = {
  key,
  getKey: (params: GenerateWidgetContentQueryParams) => [...key, params],
  mutationFn: (params: GenerateWidgetContentQueryParams) =>
    axiosService
      .getAxios()
      .post<GenerateWidgetContentQueryResponse>(`/1/ai-generation/widget-content`, params)
      .then(res => res.data),
};

export const useGenerateWidgetContentMutation = (
  options: UseMutationOptions<GenerateWidgetContentQueryResponse, AxiosError, GenerateWidgetContentQueryParams> = {},
) => {
  return useMutation(GenerateWidgetContentMutation.mutationFn, options);
};
