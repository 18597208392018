import * as React from 'react';
import { Widget } from '@process-street/subgrade/process';
import { createUsableContext } from '@process-street/subgrade/util';
import { useFunctionRef } from 'hooks/use-function-ref';
import { useInjector } from 'components/injection-provider';
import { EventName } from 'services/event-name';

type Context<W extends Widget> = {
  widget: W;
  onUpdate: (widget: W) => Promise<W>;
};

export const [_useWidgetSettingsContext, WidgetSettingsContext] = createUsableContext<Context<Widget>>({
  hookName: 'useWidgetSettingsContext',
  providerName: 'WidgetSettingsProvider',
});

//
export const useWidgetSettingsContext = <W extends Widget>() => {
  return _useWidgetSettingsContext() as unknown as Context<W>;
};

export const WidgetSettingsProvider = ({
  widget,
  onUpdate,
  children,
}: React.PropsWithChildren<{
  widget: Widget;
  onUpdate: (widget: Widget) => Promise<Widget>;
}>) => {
  const { $rootScope } = useInjector('$rootScope');
  const onUpdateRef = useFunctionRef(onUpdate);
  const value = React.useMemo(
    () =>
      ({
        widget,
        onUpdate: (updatedWidget: Widget) => {
          onUpdateRef.current(updatedWidget)?.then(persistedWidget => {
            $rootScope.$broadcast(EventName.WIDGET_UPDATE_SETTINGS, persistedWidget);
            return persistedWidget;
          });
        },
      } as Context<Widget>),
    [widget, onUpdateRef, $rootScope],
  );
  return <WidgetSettingsContext.Provider value={value}>{children}</WidgetSettingsContext.Provider>;
};
