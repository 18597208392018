import { useFormEditorPageActorRef } from '../../form-editor-page-machine';
import { useSelector } from '@xstate/react';
import { useStateParam } from 'hooks/use-state-param';
import { FormEditorPageActorSelectors } from '../../form-editor-page-machine/form-editor-page-machine-selectors';

export const useStepHeader = () => {
  const actor = useFormEditorPageActorRef();
  const taskTemplateGroupId = useStateParam({ key: 'groupId' });

  const taskTemplates = useSelector(actor, FormEditorPageActorSelectors.getTaskTemplates);
  const widgets = useSelector(actor, FormEditorPageActorSelectors.getWidgets);

  const index = taskTemplates.findIndex(t => t.group.id === taskTemplateGroupId);
  const taskTemplate = taskTemplates[index];

  const onNameChange = (name: string) => {
    actor.send({ type: 'UPDATE_TASK_TEMPLATE', taskTemplateId: taskTemplate.id, taskTemplate: { name } });
  };

  const onMove = (toIndex: number) => {
    actor.send({
      type: 'MOVE_TASK_TEMPLATE',
      taskTemplate,
      direction: toIndex > index ? 'after' : 'before',
      toIndex,
    });
  };

  const onDuplicate = () => {
    actor.send({ type: 'DUPLICATE_TASK_TEMPLATE', taskTemplateId: taskTemplate.id });
  };

  const onDelete = () => {
    actor.send({ type: 'DELETE_TASK_TEMPLATE', taskTemplateId: taskTemplate.id });
  };

  return {
    onNameChange,
    index,
    isEnabled: Boolean(index > -1),
    taskTemplates,
    widgets,
    onMove,
    onDelete,
    onDuplicate,
  };
};
