/**
 * The cache namespaces used by the DAOs.
 * Make sure that they don't clash!
 * @readonly
 * @memberOf DaoCacheConstants
 * @enum {string}
 */
const Namespace = {
  CHECKLIST: 'cl',
  CHECKLIST_ASSIGNMENT: 'cla',
  CHECKLIST_REVISION: 'clr',
  DUE_DATE_RULE: 'ddr',
  FOLDER: 'fl',
  FORM_FIELD_VALUE: 'ffv',
  INBOX_ITEM: 'ii',
  ORGANIZATION: 'o',
  ORGANIZATION_DOMAINS: 'od',
  ORGANIZATION_STATS: 'ostats',
  ORGANIZATION_MEMBERSHIP: 'om',
  PERMISSIONS: 'p',
  PLAN: 'pln',
  PLAN_FEATURE_SET: 'plnftrset',
  TASK: 'task',
  TEMPLATE: 'tmpl',
  TEMPLATE_REVISION: 'tmplr',
  USER: 'u',
  RULE: 'r',
  WIDGET: 'w',
};

export const DaoCacheConstants = {
  Namespace,
};
