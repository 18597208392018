import angular from 'angular';
import { DefaultErrorMessages } from 'components/utils/error-messages';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'DeveloperConsoleOrganizationsCtrl',
    function ($scope, $timeout, SessionService, OrganizationService, ToastService) {
      const ctrl = this;
      ctrl.$onInit = () => {
        $scope.lookupInfo = { organizationId: SessionService.getSelectedOrganizationId() };
      };

      $scope.lookup = function (organizationId) {
        $scope.submitting = true;

        OrganizationService.getById(organizationId, { flushCache: true })
          .then(
            organization => {
              $scope.targetOrganization = organization;
            },
            () => {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems loading the organization`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          )
          .finally(() => {
            $scope.submitting = false;
          });
      };

      $scope.runSsoVerification = function (organization) {
        $scope.verificationOrganization = null;

        $timeout(() => {
          $scope.verificationOrganization = organization;
        });
      };
    },
  );
