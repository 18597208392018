import * as React from 'react';
import { ModalProps } from '@chakra-ui/modal';
import { mailto } from '@process-street/subgrade/process';
import { Text, useDisclosure } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import {
  PaywallAdminBodyDescription,
  PaywallAdminHeaderIcon,
  PaywallAdminIconStars,
  PaywallBodyAdminHeading,
  PaywallBodyNoIcon,
  PaywallButtonGroup,
  PaywallCloseButton,
  PaywallDivider,
  PaywallFeatures,
  PaywallFeaturesList,
  PaywallFeaturesListItem,
  PaywallFooter,
  PaywallHeaderWithIcon,
  PaywallLink,
  PaywallModal,
  PaywallPrimaryActionLinkButton,
  PaywallSecondaryActionButton,
} from 'components/paywalls/common';
import { useOrganizationUsageStats } from 'components/paywalls/trial-expired/query';
import { useDismissTrialExpiredPaywall } from 'components/paywalls/trial-expired/use-dismiss-trial-expired-paywall';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useAdminEmails } from '../use-admin-emails';
import { usePaywall } from '../paywall-provider';
import { ContactSalesModal } from 'features/contact-sales/components/contact-sales/contact-sales-modal';
import { IntercomService } from 'services/interop/intercom-service';
import { TrialBlockService, UpdateTrialBlockPaywallVariablesProps } from 'services/interop/trial-block-service';
import { useRoleNames } from 'hooks/use-role-names';
import { AnalyticsService } from 'components/analytics/analytics.service';

export type Props = Partial<ModalProps>;

const TrialExpiredWithoutOverages: React.FC<React.PropsWithChildren<Props>> = ({ ...props }) => {
  const { dismissPaywall } = useDismissTrialExpiredPaywall();

  const { $state } = useInjector('$state');
  const contactSales = React.useCallback(() => {
    AnalyticsService.trackEvent('contact us link clicked', { location: 'trial expiry paywall' });
    IntercomService.show();
  }, []);

  const handleUpgradeNow = () => {
    dismissPaywall();
    $state.go('organizationManage.tab', { tab: 'billing', trialExpired: true });
  };

  return (
    <PaywallModal {...props}>
      <PaywallHeaderWithIcon bgColor="brand.100">
        <PaywallAdminHeaderIcon>
          <PaywallAdminIconStars />
        </PaywallAdminHeaderIcon>
      </PaywallHeaderWithIcon>

      <PaywallBodyNoIcon>
        <PaywallBodyAdminHeading>Your Pro trial is over</PaywallBodyAdminHeading>

        <PaywallAdminBodyDescription>
          You've finished your trial of Process Street's Pro plan.
        </PaywallAdminBodyDescription>
        <PaywallAdminBodyDescription>Upgrade today to keep the momentum rolling.</PaywallAdminBodyDescription>

        <PaywallFeatures />
      </PaywallBodyNoIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallButtonGroup>
          <PaywallSecondaryActionButton onClick={contactSales} className="intercom-bot-contact-sales">
            Contact Sales
          </PaywallSecondaryActionButton>
          <PaywallPrimaryActionLinkButton onClick={handleUpgradeNow} role="link">
            Upgrade Now
          </PaywallPrimaryActionLinkButton>
        </PaywallButtonGroup>
      </PaywallFooter>
    </PaywallModal>
  );
};

const TrialExpiredWithOverages: React.FC<React.PropsWithChildren<Props>> = ({ ...props }) => {
  const { dismissPaywall } = useDismissTrialExpiredPaywall();
  const roleNames = useRoleNames();

  const { $state } = useInjector('$state');
  const contactSales = React.useCallback(() => {
    AnalyticsService.trackEvent('contact us link clicked', { location: 'trial expiry paywall' });
    IntercomService.show();
  }, []);

  const { data: organizationUsageStats } = useOrganizationUsageStats();

  const handleUpgradeNow = () => {
    dismissPaywall();
    $state.go('organizationManage.tab', { tab: 'billing', trialExpired: true });
  };

  return (
    <PaywallModal {...props}>
      <PaywallHeaderWithIcon bgColor="brand.100">
        <PaywallAdminHeaderIcon>
          <PaywallAdminIconStars />
        </PaywallAdminHeaderIcon>
      </PaywallHeaderWithIcon>

      <PaywallBodyNoIcon>
        <PaywallBodyAdminHeading>Your Pro trial is over</PaywallBodyAdminHeading>

        <PaywallAdminBodyDescription>
          You've finished your trial of Process Street's Pro plan.
        </PaywallAdminBodyDescription>
        <PaywallAdminBodyDescription>Upgrade today to keep the momentum rolling.</PaywallAdminBodyDescription>

        <PaywallFeaturesList>
          <PaywallFeaturesListItem>
            Keep all{' '}
            <Text fontWeight="bold" color="brand.500" as="span">
              {organizationUsageStats?.fullMembersCount}
            </Text>{' '}
            {roleNames.FullMember.plural}
          </PaywallFeaturesListItem>
          <PaywallFeaturesListItem>Create unlimited workflows</PaywallFeaturesListItem>
          <PaywallFeaturesListItem>Run unlimited workflows</PaywallFeaturesListItem>
        </PaywallFeaturesList>
      </PaywallBodyNoIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallButtonGroup>
          <PaywallSecondaryActionButton onClick={contactSales} className="intercom-bot-contact-sales">
            Contact Sales
          </PaywallSecondaryActionButton>
          <PaywallPrimaryActionLinkButton onClick={handleUpgradeNow} role="link">
            Upgrade Now
          </PaywallPrimaryActionLinkButton>
        </PaywallButtonGroup>
      </PaywallFooter>
    </PaywallModal>
  );
};

const now = () => Math.floor(new Date().getTime() / 1000);

const TrialExpiredBlockAdmin: React.FC<React.PropsWithChildren<Props>> = ({ ...props }) => {
  const { $state } = useInjector('$state');
  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!selectedOrganization) return null;

  const trackTrialBlock = (properties: UpdateTrialBlockPaywallVariablesProps) => {
    TrialBlockService.updateIntercomPaywallClickedVariables(properties);
  };

  const handleStartupPlanAdmin = () => {
    trackTrialBlock({ company: { id: selectedOrganization.id, trial_block_paywall_startup_plan_clicked_at: now() } });
    $state.go(
      'organizationManage.tab',
      { tab: 'billing', trialExpired: true, showTrialExpiredPaywall: false },
      { inherit: false },
    );
  };

  const handleContactSales = () => {
    trackTrialBlock({ company: { id: selectedOrganization.id, trial_block_paywall_contact_sales_clicked_at: now() } });
    onOpen();
  };

  const handleSeePlanDetails = () => {
    trackTrialBlock({
      company: { id: selectedOrganization.id, trial_block_paywall_see_plan_details_clicked_at: now() },
    });
  };

  return (
    <>
      <PaywallModal {...props}>
        <PaywallHeaderWithIcon bgColor="brand.100">
          <PaywallAdminHeaderIcon>
            <PaywallAdminIconStars />
          </PaywallAdminHeaderIcon>
        </PaywallHeaderWithIcon>

        <PaywallBodyNoIcon>
          <PaywallBodyAdminHeading>Your Pro trial is over</PaywallBodyAdminHeading>

          <PaywallAdminBodyDescription>
            You've finished your trial of Process Street's Pro plan.
          </PaywallAdminBodyDescription>
          <PaywallAdminBodyDescription>Upgrade today to keep the momentum rolling.</PaywallAdminBodyDescription>

          <PaywallFeaturesList>
            <PaywallFeaturesListItem>Unlimited workflows</PaywallFeaturesListItem>
            <PaywallFeaturesListItem>Unlimited data sets</PaywallFeaturesListItem>
            <PaywallFeaturesListItem>Unlimited automations</PaywallFeaturesListItem>
            <PaywallFeaturesListItem>Unlimited pages</PaywallFeaturesListItem>
          </PaywallFeaturesList>
        </PaywallBodyNoIcon>

        <PaywallDivider />

        <PaywallFooter>
          <PaywallButtonGroup>
            <PaywallSecondaryActionButton onClick={handleStartupPlanAdmin} fontWeight="medium">
              Startup plan
            </PaywallSecondaryActionButton>
            <PaywallPrimaryActionLinkButton onClick={handleContactSales}>Contact sales</PaywallPrimaryActionLinkButton>
          </PaywallButtonGroup>
          <PaywallLink
            href="https://www.process.st/pricing/"
            mt="4"
            fontSize="small"
            textDecoration="underline"
            isExternal={true}
            onClick={handleSeePlanDetails}
          >
            See plan details
          </PaywallLink>
        </PaywallFooter>
      </PaywallModal>
      {isOpen && (
        <ContactSalesModal
          onClose={onClose}
          selectedOrganization={selectedOrganization}
          utm="app_trial_block_paywall"
        />
      )}
    </>
  );
};

const TrialExpiredBlockNonAdmin: React.FC<React.PropsWithChildren<Props>> = ({ ...props }) => {
  const { setPaywall } = usePaywall();
  const orgId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const [adminEmail, ...cc] = useAdminEmails();

  const closePaywall = React.useCallback(() => {
    setPaywall(null);
  }, [setPaywall]);

  const trackTrialBlock = (properties: UpdateTrialBlockPaywallVariablesProps) => {
    TrialBlockService.updateIntercomPaywallClickedVariables(properties);
  };

  const handleContactAdmin = () => {
    trackTrialBlock({ company: { id: orgId, trial_block_paywall_contact_admin_clicked_at: now() } });
  };

  const href = mailto({
    to: adminEmail,
    subject: `Seriously, we need Process Street!`,
    body: `Our trial of Process Street just ended :( \n\rI can already see how Process Street could be transformative for our business in all the ways that we were hoping. So far it’s awesome and we were only just getting started with some of the more advanced features like conditional logic and automations.\n\rPlus, check out what Alex from Salesforce said about it -\r“Process Street has allowed us to become very innovative and agile, not only across teams but across the globe. This facilitation of collaboration and alignment has been a huge win for us and our customers at Salesforce"\n\rHere is the email address so that you can get the ball rolling. Please let me know if there is anything you need from me that’ll help move things along quickly :)\n\rContact sales@process.st`,
    cc,
  });

  return (
    <PaywallModal {...props}>
      <PaywallHeaderWithIcon bgColor="brand.100">
        <PaywallCloseButton onClick={closePaywall} />
        <PaywallAdminHeaderIcon>
          <PaywallAdminIconStars />
        </PaywallAdminHeaderIcon>
      </PaywallHeaderWithIcon>

      <PaywallBodyNoIcon>
        <PaywallBodyAdminHeading>Your Pro trial is over</PaywallBodyAdminHeading>

        <PaywallAdminBodyDescription>
          You've finished your trial of Process Street's Pro plan.
        </PaywallAdminBodyDescription>
        <PaywallAdminBodyDescription>Upgrade today to keep the momentum rolling.</PaywallAdminBodyDescription>

        <PaywallFeaturesList>
          <PaywallFeaturesListItem>Unlimited workflows</PaywallFeaturesListItem>
          <PaywallFeaturesListItem>Unlimited data sets</PaywallFeaturesListItem>
          <PaywallFeaturesListItem>Unlimited automations</PaywallFeaturesListItem>
          <PaywallFeaturesListItem>Unlimited pages</PaywallFeaturesListItem>
        </PaywallFeaturesList>
      </PaywallBodyNoIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallButtonGroup>
          <PaywallPrimaryActionLinkButton onClick={handleContactAdmin} href={href} target="_blank">
            Contact Admin
          </PaywallPrimaryActionLinkButton>
        </PaywallButtonGroup>
      </PaywallFooter>
    </PaywallModal>
  );
};

export enum TrialExpiredPaywallType {
  NoOverages = 'NoOverages',
  Overages = 'Overages',
  BlockAdmin = 'BlockAdmin',
  BlockNonAdmin = 'BlockNonAdmin',
}

export const UIs = {
  [TrialExpiredPaywallType.NoOverages]: TrialExpiredWithoutOverages,
  [TrialExpiredPaywallType.Overages]: TrialExpiredWithOverages,
  [TrialExpiredPaywallType.BlockAdmin]: TrialExpiredBlockAdmin,
  [TrialExpiredPaywallType.BlockNonAdmin]: TrialExpiredBlockNonAdmin,
};
