import angular from 'angular';
import templateUrl from './template-textarea-field.component.html';
import './template-textarea-field.scss';

angular.module('frontStreetApp.directives').component('psTemplateTextareaFormField', {
  bindings: {
    widget: '<',
    editable: '<',
    onUpdateWidget: '&',
  },
  templateUrl,
  controller() {
    const ctrl = this;

    ctrl.updateFormFieldWidget = function () {
      ctrl.onUpdateWidget({ widget: ctrl.widget });
    };
  },
});
