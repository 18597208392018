import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Divider,
  Spacer,
  Text,
  useToast,
} from 'components/design/next';
import { GetAllTemplatesQuery, useDeleteTemplate, useGetTemplateQuery } from 'features/template/query-builder';
import { TemplateStatus, TemplateType, tmplTypeName, tmplTypeNameLower } from '@process-street/subgrade/process';
import { useRedirectToFolderView } from './use-redirect-to-folder-view';
import { useQueryClient } from 'react-query';
import { match } from 'ts-pattern';
import { DeleteTemplateMutation } from 'features/template/mutation-builder';
import { AnalyticsService } from 'components/analytics/analytics.service';
import { useTemplateMenuContext, useTemplateMenuDisclosureContext } from '../template-menu';

export const DeleteTemplateAlert = () => {
  const { templateId, view } = useTemplateMenuContext();
  const { deleteTemplateAlertDisclosure: disclosure } = useTemplateMenuDisclosureContext();
  const { isOpen, onClose } = disclosure;

  const { data: template } = useGetTemplateQuery({ templateId });
  const toast = useToast();

  const queryClient = useQueryClient();
  const deleteTemplateMutation = useDeleteTemplate({
    mutationKey: DeleteTemplateMutation.getKey(templateId),
    onSuccess: async () => {
      AnalyticsService.trackEvent('template deleted', {});

      toast({
        status: 'success',
        title: template ? `${tmplTypeName(template)} deleted` : 'Deleted',
      });

      await queryClient.invalidateQueries(
        GetAllTemplatesQuery.getKey({
          organizationId: template?.organization.id,
          templateStatus: TemplateStatus.Active,
        }),
      );

      switch (view) {
        case 'show': {
          redirectToFolderView();
          return;
        }
        default: {
          onClose();
        }
      }
    },
  });

  const { redirectToFolderView } = useRedirectToFolderView({ templateId });

  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const whatWillBeDeleted = match(template?.templateType)
    .with(undefined, () => '')
    .with(TemplateType.Playbook, () => 'workflow and all of its runs')
    .with(TemplateType.Form, () => 'form and all of its responses')
    .with(TemplateType.Page, () => 'page')
    .run();

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onClose}
      onOverlayClick={onClose}
      scrollBehavior="inside"
      size="xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader py="4" px="8" fontSize="lg">
          Delete this {tmplTypeNameLower(template)}?
        </AlertDialogHeader>
        <AlertDialogCloseButton />

        <AlertDialogBody pt="4" px="8" pb="8">
          <Text>
            Your {whatWillBeDeleted} will be deleted and{' '}
            <Text as="span" fontWeight="700">
              can’t be recovered
            </Text>
            .
          </Text>
        </AlertDialogBody>

        <Divider />

        <AlertDialogFooter py="6" px="8">
          <Spacer />
          <ButtonGroup>
            <Button ref={cancelRef} variant="ghost" onClick={onClose} colorScheme="gray">
              Cancel
            </Button>
            <Button
              isLoading={deleteTemplateMutation.isLoading || deleteTemplateMutation.isSuccess}
              isDisabled={deleteTemplateMutation.isLoading}
              variant="danger"
              onClick={() => deleteTemplateMutation.mutate({ templateId })}
            >
              Delete
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
