import * as React from 'react';
import { Box, Button, useDisclosure, Center, Text, VStack, useToast } from 'components/design/next';
import { DesignItemButton } from './design-item-button';
import { CDNImage } from 'app/components/cdn-image';
import { DeleteBackgroundImageMutation, GetBackgroundImageQuery } from 'app/features/template/query-builder';
import { match, P } from 'ts-pattern';
import { AddFileModal } from './add-file-modal';
import { UploadBackgroundImage } from 'app/features/background-image/components/upload-background-image';
import { Template } from '@process-street/subgrade/process';
import { useQueryClient } from 'react-query';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';

export const AddBackgroundButton = ({ templateId }: { templateId: Template['id'] }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const backgroundImageQuery = GetBackgroundImageQuery.useQuery({ templateId });

  const addBackgroundDisclosure = useDisclosure();
  const onUploadSuccess = async (templateId: Template['id']) => {
    await queryClient.invalidateQueries(GetBackgroundImageQuery.getKey({ templateId }));
    addBackgroundDisclosure.onClose();
  };

  const deleteBackgroundMutation = DeleteBackgroundImageMutation.useMutation({
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries(GetBackgroundImageQuery.getKey({ templateId: variables.templateId }));
      toast({
        title: 'Background removed',
        status: 'success',
      });
    },
    onError: () => {
      toast({
        title: 'We are having trouble removing this background',
        description: DefaultErrorMessages.unexpectedErrorDescription,
        status: 'error',
      });
    },
  });

  const handleRemoveBackground = () => deleteBackgroundMutation.mutate({ templateId });

  return (
    <>
      <DesignItemButton
        title="Background"
        onClick={addBackgroundDisclosure.onOpen}
        handleRemove={handleRemoveBackground}
        isLoading={deleteBackgroundMutation.isLoading}
      >
        {match(backgroundImageQuery)
          .with({ status: 'success', data: P.not(undefined) }, ({ data: backgroundImage }) => (
            <Box w="full" h="171px" borderRadius="base" borderColor="gray.300" borderWidth="1px" borderStyle="solid">
              <Center h="full" position="relative">
                <CDNImage
                  alt={backgroundImage.s3File.originalName}
                  s3File={backgroundImage.s3File}
                  {...{
                    transformation: {
                      width: 'auto',
                      height: 171,
                    },
                    maxHeight: '171px',
                  }}
                />
              </Center>
            </Box>
          ))
          .otherwise(() => null)}
      </DesignItemButton>
      <AddFileModal
        isOpen={addBackgroundDisclosure.isOpen}
        onClose={addBackgroundDisclosure.onClose}
        title="Add background"
      >
        <UploadBackgroundImage
          templateId={templateId}
          uploadingMessage="Uploading image..."
          acceptMimeTypes="image/*"
          onFinish={onUploadSuccess}
        >
          <VStack spacing={3}>
            <Button>Choose image</Button>
            <VStack>
              <Text variant="-1" color="gray.500" fontWeight="normal">
                Images wider than 1920px work best
              </Text>
            </VStack>
          </VStack>
        </UploadBackgroundImage>
      </AddFileModal>
    </>
  );
};
