import * as React from 'react';
import { match } from 'ts-pattern';
import {
  List,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerProps,
  Portal,
  Text,
  useDisclosure,
  useOutsideClick,
  HStack,
  Icon,
} from 'components/design/next';
import { Checklist } from '@process-street/subgrade/process';

import { useGetAllActivitiesByTypeQuery } from 'features/activities/query-builder';
import { Activity, ActivityObjectType, AttachmentActivity, CommentActivity } from '@process-street/subgrade/activity';
import { getActiveAttachmentsFromActivityLog, getActiveCommentsFromActivityLog } from 'features/comments/utils';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { OverviewAttachmentItem, OverviewCommentItem } from '../../common';
import { IconButton } from '@chakra-ui/react';
import { TOP_BAR_HEIGHT_CSS_VAR } from 'app/pages/forms/_id/shared';
import { WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT } from 'app/components/focus-bar/workflow/focus-bar-hstack';

export type TemplateCommentsOverviewDrawerProps = {
  templateId: Checklist['id'];
  children: React.ReactElement;
  popoverProps?: Partial<DrawerProps>;
};

export const TemplateCommentsOverviewDrawer: React.FC<React.PropsWithChildren<TemplateCommentsOverviewDrawerProps>> = ({
  templateId,
  popoverProps,
  children,
}) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const disclosure = useDisclosure();
  const { data: currentUser } = useGetCurrentUserInfoQuery({
    select: data => data.user,
  });

  const commentsQuery = useGetAllActivitiesByTypeQuery<Activity[]>(
    {
      type: 'template',
      templateId,
      limit: 100,
      objectType: ActivityObjectType.Comment,
    },
    {
      enabled: Boolean(templateId),
      select: ({ data }) => getActiveCommentsFromActivityLog(data as CommentActivity[]),
    },
  );

  const attachmentsQuery = useGetAllActivitiesByTypeQuery<Activity[]>(
    {
      type: 'template',
      templateId,
      limit: 100,
      objectType: ActivityObjectType.Attachment,
    },
    {
      enabled: Boolean(templateId),
      select: ({ data }) => getActiveAttachmentsFromActivityLog(data as AttachmentActivity[]),
    },
  );

  const allItems = match([commentsQuery, attachmentsQuery])
    .with(
      [{ status: 'success' }, { status: 'success' }],
      ([{ data: comments }, { data: attachments }]) =>
        comments?.concat(attachments ?? []).sort((a, b) => b.date - a.date) ?? [],
    )
    .otherwise(() => []);

  const count = allItems?.length;

  const trigger = React.cloneElement(children, {
    isDisabled: !count,
    onClick: disclosure.onOpen,
  });

  useOutsideClick({
    ref: contentRef,
    handler: disclosure.onClose,
  });

  return (
    <>
      {trigger}
      <Drawer
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        placement="right"
        autoFocus={false}
        trapFocus={false}
        returnFocusOnClose={false}
        {...popoverProps}
      >
        <Portal>
          <DrawerContent
            ref={contentRef}
            h={`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR} - var(--ps-sizes-${WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT}))`}
            top={`calc(${TOP_BAR_HEIGHT_CSS_VAR} + var(--ps-sizes-${WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT})) !important`}
            maxW={{ base: 'full', md: '354px' }}
            bottom={0}
            bgColor="white"
            borderLeftWidth="thin"
            borderLeftColor="gray.100"
            borderLeftStyle="solid"
            boxShadow="0px 4px 6px -1px #0000001A"
          >
            <DrawerHeader as={HStack} px="6" pt="6" fontWeight="bold" spacing={2} position="relative">
              <Icon icon="comment" size="5" color="gray.400" />
              <Text color="gray.500">
                Run Comments <Text as="span" color="gray.500" fontWeight="normal">{`(${count})`}</Text>
              </Text>

              <IconButton
                variant="ghost"
                icon={<Icon icon="times" size="4" color="gray.500" />}
                size="sm"
                colorScheme="gray"
                aria-label="Close comments drawer"
                onClick={disclosure.onClose}
                position="absolute"
                right="1"
              />
            </DrawerHeader>
            <DrawerBody px="6" pb="2">
              <List spacing="4" w="full">
                {currentUser &&
                  allItems.map(activity => {
                    if ('attachmentId' in activity.data) {
                      return (
                        <OverviewAttachmentItem
                          {...{ key: activity.id, activity, currentUserId: currentUser.id, type: 'template' }}
                        />
                      );
                    }
                    if ('commentId' in activity.data) {
                      return (
                        <OverviewCommentItem
                          {...{
                            key: activity.id,
                            activity,
                            currentUserId: currentUser.id,
                            type: 'template',
                          }}
                        />
                      );
                    }
                    return null;
                  })}
              </List>
            </DrawerBody>
          </DrawerContent>
        </Portal>
      </Drawer>
    </>
  );
};
