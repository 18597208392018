import { Muid, OrganizationMembership, User } from '@process-street/subgrade/core';
import { FormFieldValueWithWidget } from '@process-street/subgrade/process';
import { TaskAssignment, TaskAssignmentRule } from '@process-street/subgrade/role-assignment';
import { getLabelForWidget } from 'app/directives/rules/template/task-templates-selector/selector-helper';
import { None, Option } from 'space-monad';

export interface RoleOption {
  user: User;
  name: string;
  assignmentType: AssignmentType;
  formFieldWidgetId: Muid;
}

export enum AssignmentType {
  Email = 'Email',
  Members = 'Members',
}

export const getOptionsFromAssignmentRules = ({
  formFieldValues,
  assignmentRulesByFormFieldWidgetGroupIdMap,
  taskAssignments,
  assignmentType,
}: {
  formFieldValues: FormFieldValueWithWidget[];
  assignmentRulesByFormFieldWidgetGroupIdMap: Map<string, TaskAssignmentRule>;
  taskAssignments: TaskAssignment[];
  assignmentType: AssignmentType;
}) => {
  const ffvOptions = formFieldValues
    .map(ffv => {
      const rule = assignmentRulesByFormFieldWidgetGroupIdMap.get(ffv.formFieldWidget.header.group.id);
      if (!rule) {
        return Option(null);
      }
      const assignment = taskAssignments.find(ta => ta.sourceTaskAssignmentRule?.id === rule?.id);
      if (assignment) {
        return {
          name: getLabelForWidget(ffv.formFieldWidget),
          user: (assignment?.organizationMembership as OrganizationMembership)?.user,
          assignmentType,
          formFieldWidgetId: ffv.formFieldWidget.id,
        } as RoleOption;
      }
      return Option(null);
    })

    .filter(option => (option as Option<null>) !== None);
  return ffvOptions as RoleOption[];
};

export const RoleOptionTransformer = {
  getOptionsFromAssignmentRules,
};
