import * as React from 'react';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { useSelector } from 'react-redux';
import { env } from 'components/common/env';
import { SessionSelector } from 'reducers/session/session.selectors';
import { createUsableContext } from '@process-street/subgrade/util';
import { useGlobalSearchApiKeyQuery } from '../query-builder';
import { TemplateType } from '@process-street/subgrade/process';

// env is undefined in storybook and causes this to break
// adding || process.env to the env assignment breaks deploys
const ALGOLIA_APP_ID = env?.ALGOLIA_APP_ID ?? '';

export enum AlgoliaIndices {
  Templates = 'templates',
  SavedViews = 'saved_views',
}

export type SearchContextProps = {
  initSearchStatus?: SearchStatus;
  initSearchQuery?: string;
  /** If not provided, both template types will be included */
  templateType?: TemplateType;
  isOpen?: boolean;
  searchClient?: SearchClient;
};

export const SearchContext: React.FC<React.PropsWithChildren<SearchContextProps>> = ({
  children,
  initSearchStatus,
  initSearchQuery,
  templateType,
  isOpen = true,
  searchClient: searchClientOverride = null,
}) => {
  const id = useSelector(SessionSelector.getSelectedOrganizationId);

  const [searchClient, setSearchClient] = React.useState<SearchClient | null>(searchClientOverride);
  const { refetch: refetchApiKey } = useGlobalSearchApiKeyQuery(
    { organizationId: id! },
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      enabled: !!id,
      onSuccess: key => {
        setSearchClient(algoliasearch(ALGOLIA_APP_ID, key));
      },
    },
  );

  // Refetch the API key on subsequent opens to make sure permissions are up to date
  // The first fetch happens on mount to have results immediately
  const hasBeenOpenedOnce = React.useRef(false);
  React.useEffect(() => {
    if (isOpen) {
      if (hasBeenOpenedOnce.current) {
        refetchApiKey();
      } else {
        hasBeenOpenedOnce.current = true;
      }
    }
  }, [refetchApiKey, isOpen]);

  return searchClient ? (
    // @ts-expect-error using the wrong @types/react-dom version
    <InstantSearch searchClient={searchClient} indexName={AlgoliaIndices.Templates}>
      {/* HACK: Configure is undefined for some reason in storybooks, this allows it to render */}
      {Configure && <Configure {...(templateType ? { filters: `templateType:${templateType}` } : {})} />}
      <SearchStatusProvider initSearchStatus={initSearchStatus} initSearchQuery={initSearchQuery}>
        {children}
      </SearchStatusProvider>
    </InstantSearch>
  ) : null;
};

export type Context = {
  status: 'selecting' | 'selected';
  select: () => void;
  reset: () => void;
  query: string;
  setQuery: (value: string) => void;
};

export const [useSearchStatus, SearchStatusContext] = createUsableContext<Context>({
  hookName: 'useSearchStatus',
  providerName: 'SearchStatusProvider',
});

export type SearchStatus = 'selecting' | 'selected';

export const SearchStatusProvider: React.FC<React.PropsWithChildren<Omit<SearchContextProps, 'isOpen'>>> = ({
  children,
  initSearchStatus = 'selecting',
  initSearchQuery = '',
}) => {
  const [query, setQuery] = React.useState(initSearchQuery);
  const [status, setStatus] = React.useState<SearchStatus>(initSearchStatus);

  const select = React.useCallback(() => {
    setStatus('selected');
  }, []);

  const reset = React.useCallback(() => {
    setStatus('selecting');
  }, []);

  const value = React.useMemo(
    () => ({
      query,
      reset,
      select,
      setQuery,
      status,
    }),
    [query, reset, select, status],
  );
  return <SearchStatusContext.Provider value={value}>{children}</SearchStatusContext.Provider>;
};
