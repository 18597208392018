import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from 'components/design/next';
import { useGetTemplateQuery, usePublicizeTemplateMutation } from 'app/features/template/query-builder';
import { PublicShareModal } from 'app/pages/library/components/template-library/template-row/public-share-modal';
import * as React from 'react';
import { match, P } from 'ts-pattern';
import { useTemplateMenuContext, useTemplateMenuDisclosureContext } from '../template-menu';

export const AddToPublicLibraryModal = () => {
  const { templateId } = useTemplateMenuContext();
  const { addToPublicModalDisclosure: disclosure, publicShareModalDisclosure } = useTemplateMenuDisclosureContext();

  const templateQuery = useGetTemplateQuery({ templateId });

  const isPublic = !!templateQuery.data?.public;

  const toast = useToast();

  const publicizeMutation = usePublicizeTemplateMutation({
    onSuccess: () => {
      templateQuery.refetch();
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Could not add workflow to the public library',
        description: 'Please verify your email address and try again.',
      });
    },
  });

  const publicize = () => {
    publicizeMutation.mutate({ templateId });
  };

  // One time effect to open the public share after the publicizing completes
  const publicShareOpenedOnSuccess = React.useRef(false);
  React.useEffect(() => {
    if (publicizeMutation.isSuccess && isPublic && !publicShareOpenedOnSuccess.current) {
      publicShareOpenedOnSuccess.current = true;
      publicShareModalDisclosure.onOpen();
    }
  }, [isPublic, publicShareModalDisclosure, publicizeMutation.isSuccess]);

  return (
    <>
      {match(templateQuery)
        .with({ data: { public: true, publicUrl: P.not(undefined), publicId: P.not(undefined) } }, ({ data }) => (
          <PublicShareModal {...publicShareModalDisclosure} template={data} />
        ))
        .with({ data: { public: false } }, () => (
          <Modal {...disclosure} size="xl">
            <ModalOverlay />
            <ModalCloseButton />
            <ModalContent>
              <ModalHeader pt="8" pb="4">
                <Text variant="2" noOfLines={1}>
                  Publish to public library?
                </Text>
              </ModalHeader>
              <ModalBody pt="4" pb="8">
                <Text variant="1">
                  This workflow will be published to the public library.{' '}
                  <Text as="b" fontWeight="medium">
                    It will be accessible to the general public and indexed by search engines.
                  </Text>
                </Text>
                <Text variant="1" mt="4">
                  To reverse this action, contact support.
                </Text>
              </ModalBody>
              <ModalFooter borderTop="1px" borderColor="gray.200">
                <ButtonGroup>
                  <Button variant="ghost" onClick={disclosure.onClose}>
                    Cancel
                  </Button>
                  <Button onClick={publicize} isLoading={publicizeMutation.isLoading}>
                    Publish to Public Library
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        ))
        .otherwise(() => null)}
    </>
  );
};
