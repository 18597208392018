import { useSolutionsByTemplateIdQuery } from 'features/automations/query-builder';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useTemplateId } from '../../automation/utils/use-template-id';
import partition from 'lodash/partition';
import { toEntityMap } from '@process-street/subgrade/redux';
import {
  isNativeTemplateTrigger,
  NativeTemplateTrigger,
  TraySolutionIdTrigger,
  TraySolutionTriggerUtils,
} from '@process-street/subgrade/process';
import { Option } from 'space-monad';
import {
  AutomationInstance,
  isIncomingWebhookInstance,
  isTemplateSolutionInstance,
  TrayAutomationAppName,
  UnsafeTrayAutomationAppName,
  withTrayPrefix,
} from '@process-street/subgrade/automation';
import { useSolutionTypeTagInstances } from '../../automation/components/selector/use-solution-type-tag-instances';
import { match } from 'ts-pattern';

type Mapping = {
  configuredInstances: AutomationInstance[];
  preferredNativeTriggers: NativeTemplateTrigger[];
  preferredTrayTriggerAndAppNames: [TraySolutionIdTrigger, TrayAutomationAppName][];
};
type Trigger = keyof Mapping;

export function useSelectedTemplateTriggers<T extends Trigger>(...triggerTypesProp: T[]): Pick<Mapping, T> {
  const templateId = useTemplateId();
  const triggerTypes: T[] | Trigger[] =
    triggerTypesProp.length > 0
      ? triggerTypesProp
      : (['configuredInstances', 'preferredNativeTriggers', 'preferredTrayTriggerAndAppNames'] as Trigger[]);

  const solutionsMapQuery = useSolutionsByTemplateIdQuery(
    { templateId },
    {
      select: toEntityMap,
      enabled: triggerTypes.includes('preferredTrayTriggerAndAppNames'),
    },
  );

  const templateQuery = useGetTemplateQuery(
    { templateId },
    {
      enabled:
        triggerTypes.includes('preferredNativeTriggers') && triggerTypes.includes('preferredTrayTriggerAndAppNames'),
    },
  );
  const preferredTriggers = templateQuery.data?.preferredTriggers ?? [];

  const [preferredNativeTriggers, preferredTraySolutionTriggers] = partition(preferredTriggers, trigger =>
    isNativeTemplateTrigger(trigger),
  ) as [NativeTemplateTrigger[], TraySolutionIdTrigger[]];
  const preferredTraySolutionsAndAppNames = preferredTraySolutionTriggers
    .map(trigger => {
      const solutionId = TraySolutionTriggerUtils.triggerToId(trigger);
      const automationApp = Option<UnsafeTrayAutomationAppName>(solutionsMapQuery.data?.[solutionId]?.automationApp)
        .map(withTrayPrefix)
        .get();
      return [trigger, automationApp];
    })
    .filter((tuple): tuple is [TraySolutionIdTrigger, TrayAutomationAppName] => Boolean(tuple[1]));

  const { instances } = useSolutionTypeTagInstances(
    { templateId },
    { enabled: triggerTypes.includes('configuredInstances') },
  );

  const configuredInstances = instances.ps_create_checklist_when.filter(i =>
    match(i)
      .when(isTemplateSolutionInstance, () => true)
      .when(isIncomingWebhookInstance, i => i.status !== 'Deleted')
      .otherwise(() => false),
  );

  return triggerTypes.reduce((acc, triggerType) => {
    match(triggerType)
      .with('configuredInstances', () => (acc.configuredInstances = configuredInstances))
      .with('preferredNativeTriggers', () => (acc.preferredNativeTriggers = preferredNativeTriggers))
      .with(
        'preferredTrayTriggerAndAppNames',
        () => (acc.preferredTrayTriggerAndAppNames = preferredTraySolutionsAndAppNames),
      )
      .exhaustive();
    return acc;
  }, {} as Mapping);
}
