import templateUrl from './task-permission-rule.component.html';
import './task-permission-rule.scss';

export const TaskPermissionRuleComponent = {
  bindings: {
    rule: '<',
    widgets: '<',
    widgetsLoaded: '<',
    deleteVisible: '<',
    onClick: '&',
    onDelete: '&',
  },
  templateUrl,
  controller: class {
    constructor(TaskPermissionRuleService) {
      'ngInject';

      this.TaskPermissionRuleService = TaskPermissionRuleService;
    }

    getRuleDescription() {
      const { rule, widgets, widgetsLoaded } = this;
      return this.TaskPermissionRuleService.getRuleDescription(rule, widgets, widgetsLoaded);
    }

    getRuleTitle() {
      const { rule, widgets, widgetsLoaded } = this;
      return this.TaskPermissionRuleService.getRuleTitle(rule, widgets, widgetsLoaded);
    }
  },
};
