import { AuthConnection, Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetOrganizationAuthConnectionsParams = {
  organizationId?: Muid;
};

export const GetOrganizationAuthConnectionsQuery = {
  key: ['organizations', 'auth-connections'],
  getKey: (params: GetOrganizationAuthConnectionsParams): QueryKey => [
    ...GetOrganizationAuthConnectionsQuery.key,
    params,
  ],
  queryFn: (params: GetOrganizationAuthConnectionsParams) =>
    axiosService
      .getAxios()
      .get(`/1/organizations/${params.organizationId}/auth-connections`)
      .then(r => r.data),
};

export const useGetOrganizationAuthConnectionsQuery = (
  params: GetOrganizationAuthConnectionsParams,
  options?: UseQueryOptions<AuthConnection[], AxiosError>,
) => {
  return useQuery(
    GetOrganizationAuthConnectionsQuery.getKey(params),
    () => GetOrganizationAuthConnectionsQuery.queryFn(params),
    {
      ...options,
      enabled: Boolean(params.organizationId) && options?.enabled !== false,
    },
  );
};
