import angular from 'angular';
import { WidgetConstants } from '@process-street/subgrade/process/widget-constants';
import { escapeHtml } from '@process-street/subgrade/util';
import { FileUploadConstants } from '../file-upload/file-upload-constants';
import templateUrl from './template-image-widget.component.html';
import './template-image-widget.scss';
import { StringService } from 'services/string-service';
import { EventName } from 'services/event-name';

angular.module('frontStreetApp.directives').component('psTemplateImageWidget', {
  bindings: {
    widget: '<',
    disabled: '<',
    onUpdate: '&',
  },
  templateUrl,
  controller($rootScope, $scope, $timeout, $ngRedux, FileUploadService) {
    const ctrl = this;

    ctrl.$onInit = function () {
      ctrl.imageMimeTypes = WidgetConstants.IMAGE_MIME_TYPES;
      ctrl.safeCaption = escapeHtml(ctrl.widget.caption);
    };

    ctrl.add = function (__event, data) {
      // Disable autoUpload so we can get the promise from submit
      data.autoUpload = false;

      $timeout(() => {
        data.process(function () {
          return this.process(data);
        });
      });
    };

    ctrl.done = function (__event, data) {
      FileUploadService.finishUpload(ctrl.widget);

      FileUploadService.finishUploadForTemplateWidget(data)
        .then(widget => {
          ctrl.widget.file = widget.file;
        })
        .catch(() => {
          ctrl.fail(null, data);
        });
    };

    ctrl.fail = function (__event, _data) {
      $rootScope.$broadcast(EventName.WIDGET_UPLOAD_FAILED, ctrl.widget);

      // Clean up

      delete ctrl.widget.file;
      delete ctrl.widget.description;

      FileUploadService.finishUpload(ctrl.widget);

      delete ctrl.uploadProgress;
    };

    ctrl.progress = function (__event, data) {
      ctrl.uploadProgress = parseInt((data.loaded / data.total) * 100, 10);
    };

    ctrl.processDone = function (__event, data) {
      const { header } = ctrl.widget;
      const { name: originalName, type: mimeType } = data.files[0];

      FileUploadService.submitTemplateWidgetUpload(data, ctrl.widget, header.id, originalName, mimeType)
        .then(() => {
          ctrl.uploadProgress = 0;
        })
        .catch(() => {
          ctrl.fail(null, data);
        });
    };

    ctrl.processFail = function (__event, data) {
      let message;
      const { error } = data.files[0];
      switch (error) {
        case FileUploadConstants.Error.FILE_TOO_LARGE: {
          const prettyMaxFileSize = StringService.getPrettySize(data.maxFileSize);
          message = `Sorry, the image must be smaller than ${prettyMaxFileSize}.`;
          break;
        }
        case FileUploadConstants.Error.FILE_TYPE_NOT_ALLOWED:
          message = 'Sorry, you can only upload GIF, JPG and PNG images.';
          break;
        default:
          message = 'Sorry, we were unable to upload your image.';
      }

      $rootScope.$broadcast(EventName.WIDGET_UPLOAD_INVALID_FILE_TYPE, message);

      if (ctrl.widget) {
        delete ctrl.widget.file;
      }
    };

    ctrl.cancelEvent = function (event) {
      event.preventDefault();
    };

    ctrl.updateWidget = function () {
      ctrl.onUpdate({ widget: ctrl.widget });
      ctrl.safeCaption = escapeHtml(ctrl.widget.caption);
    };

    // For drops

    $scope.$on(EventName.WIDGET_DROP_ADD, (__event, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.add(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_DONE, (__event, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.done(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_FAIL, (__event, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.fail(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_PROCESS_DONE, (__event, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.processDone(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_PROCESS_FAIL, (__event, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.processFail(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_PROGRESS, (__event, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.progress(null, data);
      }
    });
  },
});
