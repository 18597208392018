import { Muid, OrganizationMembership, OrganizationMembershipRole } from '@process-street/subgrade/core';
import { ReduxAppState } from 'reducers/types';
import { createCachedAction } from 'reducers/util';
import { Action, Dispatch } from 'redux';
import { OrganizationMembershipSelector } from './organization-membership.selectors';
import { ThunkAction } from 'redux-thunk';

export const ORGANIZATION_MEMBERSHIP_GET_ALL_BY_ORG_ID = 'organizationMembership/GET_ALL_BY_ORGANIZATION_ID';
export const ORGANIZATION_MEMBERSHIP_GET_ALL_BY_USER_ID = 'organizationMembership/GET_ALL_BY_USER_ID';

export const ORGANIZATION_MEMBERSHIP_SET_LEVEL = 'organizationMembership/SET_LEVEL';
export const ORGANIZATION_MEMBERSHIP_DELETE_BY_ORGANIZATION_ID_AND_USER_ID =
  'organizationMembership/DELETE_BY_ORGANIZATION_ID_AND_USER_ID';

interface OrganizationMembershipApi {
  getAllOrgMembershipByOrgId(organizationId: Muid): Promise<OrganizationMembership[]>;

  /**
   * Gets all organization memberships that available for a given user id for all organization
   */
  getAllByUserId(userId: Muid): Promise<OrganizationMembership[]>;

  setLevel(membershipId: Muid, newLevel: OrganizationMembershipRole): Promise<OrganizationMembership>;

  deleteByOrganizationIdAndUserId(organizationId: Muid, userId: Muid): Promise<void>;
}

export interface IOrganizationMembershipActions {
  getAllByUserId: (userId: Muid, flushCache?: boolean) => void;
  getAllOrgMembershipByOrgId(
    organizationId: Muid,
    flushCache?: boolean,
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;
  setLevel: (membershipId: Muid, newLevel: OrganizationMembershipRole) => void;
}

export const OrganizationMembershipActions = (OrganizationMembershipApi: OrganizationMembershipApi) => {
  'ngInject';

  const getAllOrgMembershipByOrgId = createCachedAction(
    ORGANIZATION_MEMBERSHIP_GET_ALL_BY_ORG_ID,
    OrganizationMembershipApi.getAllOrgMembershipByOrgId,
    (organizationId: Muid) => ({ organizationId, flushCache: true }),
  );

  /**
   * Gets all organization memberships that available for a given user id for all organization
   */
  const getAllByUserId = createCachedAction<
    ReduxAppState,
    OrganizationMembership[],
    { userId: Muid; flushCache?: boolean }
  >(
    ORGANIZATION_MEMBERSHIP_GET_ALL_BY_USER_ID,
    OrganizationMembershipApi.getAllByUserId,
    (userId: Muid, flushCache?: boolean) => ({ userId, flushCache }),
    (state: ReduxAppState, userId: Muid) => OrganizationMembershipSelector.isLoadedByUserId(userId)(state),
    OrganizationMembershipSelector.getAllByUserId,
  );

  const setLevel = createCachedAction(ORGANIZATION_MEMBERSHIP_SET_LEVEL, OrganizationMembershipApi.setLevel);

  const doDeleteByOrganizationIdAndUserId = createCachedAction(
    ORGANIZATION_MEMBERSHIP_DELETE_BY_ORGANIZATION_ID_AND_USER_ID,
    OrganizationMembershipApi.deleteByOrganizationIdAndUserId,
    (organizationId, userId, membership) => ({ organizationId, userId, membership }),
  );

  const deleteByOrganizationIdAndUserId =
    (organizationId: Muid, userId: Muid) => (dispatch: Dispatch, getState: () => ReduxAppState) => {
      const membership = OrganizationMembershipSelector.getByOrganizationIdAndUserId(
        organizationId,
        userId,
      )(getState());

      // @ts-expect-error -- TODO
      return dispatch(doDeleteByOrganizationIdAndUserId(organizationId, userId, membership));
    };

  return {
    deleteByOrganizationIdAndUserId,
    getAllByUserId,
    getAllOrgMembershipByOrgId,
    setLevel,
  };
};
