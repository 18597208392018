import { OrganizationMembershipService } from 'services/organization-membership-service.interface';
import { isNotIdRef, OrganizationMembership, OrganizationMembershipRole, User } from '@process-street/subgrade/core';
import { htmlEscaped } from '@process-street/subgrade/util';
import { isPromoting, RolesMap } from 'pages/organizations/manage/users/components/role-selector/model';
import { responseUtilsService } from 'components/utils/response-utils.service';
import { HttpStatus } from '@process-street/subgrade/util';
import { ToastService } from 'services/toast-service.interface';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export interface OrganizationMembershipRoleService {
  switchRole(options: {
    organizationMembership: OrganizationMembership;
    role: OrganizationMembershipRole;
    legacy: boolean;
    freemiumTrack?: boolean;
  }): Promise<void>;
}

export class OrganizationMembershipRoleServiceImpl implements OrganizationMembershipRoleService {
  public static $inject = ['OrganizationMembershipService', 'ToastService'];

  constructor(
    private readonly organizationMembershipService: OrganizationMembershipService,
    private readonly toastService: ToastService,
  ) {}

  switchRole({
    organizationMembership,
    role,
    legacy,
    freemiumTrack = false,
  }: {
    organizationMembership: OrganizationMembership;
    role: OrganizationMembershipRole;
    legacy: boolean;
    freemiumTrack?: boolean;
  }): Promise<void> {
    const currentRole = organizationMembership.role;
    const promoting = isPromoting(currentRole, role);

    const title = legacy ? RolesMap[role].legacyTitle : RolesMap[role].title;
    const roleName = title;

    const userName = (organizationMembership.user as User).username;
    const verbDone = promoting ? 'promoted' : 'demoted';
    const fullMembersTitle = 'Members';

    return this.organizationMembershipService
      .setLevel(organizationMembership.id, role)
      .then(() => {
        this.toastService.openToast({
          status: 'success',
          title: `${userName} ${verbDone} to ${roleName}`,
        });
      })
      .catch(response => {
        if (responseUtilsService.isUsersLimitReached(response)) {
          const message = responseUtilsService.getUsersLimitReachedMessage(response);
          this.toastService.openToast({
            status: 'warning',
            title: `We couldn't update the role for the user`,
            description: message,
          });
        } else if (response.status === HttpStatus.PAYMENT_REQUIRED && freemiumTrack) {
          const orgName = isNotIdRef(organizationMembership.organization)
            ? organizationMembership.organization.name
            : null;
          const message = htmlEscaped`Your organization is at the max that comes with your free plan.<br/>Add more ${fullMembersTitle} by upgrading ${
            orgName ? `${orgName} ` : ''
          }to Pro.`;

          this.toastService.openToast({
            status: 'warning',
            title: `We couldn't update the role for the user`,
            description: message,
          });
        } else if (response.data && response.data.code === 'PS.OrganizationMembership.NonOrganizationDomainEmail') {
          this.toastService.openToast({
            status: 'warning',
            title: `We couldn't update the role for the user`,
            description: `The user is not part of your SSO domain.`,
          });
        } else {
          this.toastService.openToast({
            status: 'warning',
            title: `We're having problems updating the role for this user`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        }

        return Promise.reject(response);
      });
  }
}
