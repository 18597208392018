import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace RetryNativeAutomationMutation {
  export type Params = { nativeAutomationExecutionId?: Muid };

  export type Response = {};

  export const key = ['native-automation-executions', 'retry'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/native-automation-executions/${params.nativeAutomationExecutionId}/retry`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
