import {
  isFormFieldWidget,
  isMultiSelectFormFieldWidget,
  MultiSelectFormFieldWidget,
  Widget,
} from '@process-street/subgrade/process';

export namespace OneOffTaskUtils {
  export const ONE_OFF_TASK_SUBTASK_KEY = 'Subtasks';

  export const isSubtasksFormFieldWidget = (w: Widget): w is MultiSelectFormFieldWidget =>
    isFormFieldWidget(w) && isMultiSelectFormFieldWidget(w) && w.key === ONE_OFF_TASK_SUBTASK_KEY;
}
