import { trace } from 'components/trace';
import { ObjectUtilsService as IObjectUtilsService } from 'components/utils/object-utils.service.interface';
import { KEY_PREFIX as REDUX_PERSIST_STORE_KEY_PREFIX } from 'redux-persist/lib/constants';
import { AuthService } from 'services/auth.interface';
import { SecurityService as ISecurityService } from 'services/security/security-service.interface';
import { getEnv } from 'components/common/env';

export const SessionSyncListener = (
  $window: Window,
  auth: AuthService,
  ObjectUtilsService: IObjectUtilsService,
  SecurityService: ISecurityService,
) => {
  'ngInject';
  const logger = trace({ name: 'SessionSyncListener' });

  $window.addEventListener('storage', (event: StorageEvent) => {
    // https://www.w3.org/TR/webstorage/#the-storage-event
    if (event.key === null || !event.key.startsWith(REDUX_PERSIST_STORE_KEY_PREFIX)) {
      return;
    }

    const oldSession = ObjectUtilsService.readSession(event.oldValue);
    const newSession = ObjectUtilsService.readSession(event.newValue);

    const tokenChanged = oldSession.token !== newSession.token;

    if (tokenChanged) {
      const userIsLoggingIn = oldSession.token === null && !auth.isLoggedIn();
      const userIsLoggingOut = newSession.token === null && auth.isLoggedIn();

      // Check for /auth0-callback is due to issue in Safari PS-6605
      if (userIsLoggingIn && !$window.location.href.startsWith(getEnv().AUTH0_REDIRECT_URI)) {
        logger.info('user is logging in from another window');
        $window.location.reload();
      } else if (userIsLoggingOut) {
        logger.info('user is logging out in another window, we need to refresh to keep sync');
        $window.location.reload();
      }
    } else if (
      typeof oldSession.selectedOrganizationId === 'string' &&
      typeof newSession.selectedOrganizationId === 'string' &&
      oldSession.selectedOrganizationId !== newSession.selectedOrganizationId &&
      !SecurityService.isAuthenticatedAnonymously()
    ) {
      logger.info(
        'organization changed from "%s" to "%s"',
        oldSession.selectedOrganizationId,
        newSession.selectedOrganizationId,
      );
    }
  });
};
