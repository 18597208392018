import * as React from 'react';
import { Icon, MenuItem, MenuItemProps } from 'components/design/next';
import { useWidgetActorRef } from '../../../shared/widget-context';

export const DeleteMenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = props => {
  const { widgetActorRef } = useWidgetActorRef();
  return (
    <MenuItem
      color="red.500"
      iconSpacing="2"
      aria-label="Delete"
      icon={<Icon icon="trash-can" size="4" />}
      onClick={() => {
        widgetActorRef.send({ type: 'DELETE_WIDGET' });
      }}
      {...props}
    >
      Delete
    </MenuItem>
  );
};
