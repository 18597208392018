import { StripeBillingPortalButton } from 'components/billing/stripe-billing-portal-button/component';
import { Text, useToast } from 'components/design/next';
import * as React from 'react';
import { Organization } from '@process-street/subgrade/core';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export type StripeLinkProps = {
  organization: Organization;
};

export const StripeLink: React.FC<StripeLinkProps> = ({ organization }) => {
  const toast = useToast();

  const handleStripeError = React.useCallback(() => {
    toast({
      status: 'error',
      title: `We're having problems fetching your billing details`,
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
  }, [toast]);

  return (
    <StripeBillingPortalButton organization={organization} onStripeError={handleStripeError} variant="unstyled">
      <Text as="span" color="brand.500">
        Manage subscription & view payment history
      </Text>
    </StripeBillingPortalButton>
  );
};
