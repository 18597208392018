import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { AiGenerateTextWidgetContentMenu } from '.';

export const AiGenerateTextWidgetContentMenuModule = angular
  .module(
    'aiGenerateTextWidgetContentMenu',
    [], // dependencies
  )
  .component(
    'psAiGenerateTextWidgetContentMenu',
    reactToAngular(
      AiGenerateTextWidgetContentMenu,
      ['widget', 'onContentGenerated', 'editor', 'onGenerationStatusChange'], // props
    ),
  ).name;
