import { Muid, User, UserType } from '@process-street/subgrade/core';
import { UserUtils } from '@process-street/subgrade/util';

export interface UserOption {
  user: User;
  label: string;
  value: Muid;
  avatarUrl?: string;
  avatarInitials: string;
  emailVisible: boolean;
}

const transformUsersToOptions = (users: User[]) => users.map(user => transformUserToOption(user));

const transformUserToOption = (user: User) =>
  ({
    avatarUrl: UserUtils.extractAvatarUrl(user, 64),
    avatarInitials: UserUtils.extractInitials(user),
    emailVisible: user.userType === UserType.Standard,
    label: user.username,
    value: user.id,
    user,
  } as UserOption);

export const UserOptionTransformer = {
  transformUsersToOptions,
  transformUserToOption,
};
