import isObject from 'lodash/isObject';
import { AuditMetadata, Muid, Organization, Ref, User } from '../core';

export interface Folder {
  readonly id: Muid;
  audit: AuditMetadata;
  deletedDate?: number;
  deletedBy?: Ref<User>;
  organization: Ref<Organization>;
  parentFolder?: Ref<Folder>;
  name: string;
  slug: string;
  folderType: FolderType;
  ownerUserId?: Muid;
}

export interface FolderWithPath extends Folder {
  path: string[];
}

export enum FolderType {
  OrganizationRoot = 'OrganizationRoot',
  PrivateRoot = 'PrivateRoot',
  Standard = 'Standard',
}

export const isFolder = (maybeFolder: unknown): maybeFolder is Folder => {
  if (!isObject(maybeFolder)) return false;

  return 'folderType' in maybeFolder;
};
