import { GetInboxItemsQuery } from 'app/features/microsoft-teams/query-builder';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

export const useInfiniteQueryObserver = (inboxQueryParams: GetInboxItemsQuery.Params, onChange: () => void) => {
  const queryClient = useQueryClient();

  useEffect(
    function refreshGridOnInboxItemsChange() {
      const unsubscribe = queryClient.getQueryCache().subscribe(event => {
        if (!event) return;

        const { query, type } = event;
        if (type === 'queryUpdated' && query.queryKey[0] === 'inbox-items' && query.state.status === 'success') {
          onChange();
        }
      });

      return () => {
        unsubscribe();
      };
    },
    // Ignore `onChange` because it's not memoized and shouldn't cause grid updates.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inboxQueryParams, queryClient],
  );
};
