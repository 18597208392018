import {
  ConditionalFilterDateTimeRangeOperand,
  ConditionalFilterOperandType,
} from '@process-street/subgrade/dashboard';
import React, { useEffect, useRef, useState } from 'react';
import styles from './DateRangeOperandEditor.module.scss';
import { BlvdDatePicker, FocusableDatePickerInput, formatMMDDYYYY } from 'components/design/BlvdDatePicker';

export interface DateRangeOperandEditorProps {
  operand: ConditionalFilterDateTimeRangeOperand;
  onChange: (operand: ConditionalFilterDateTimeRangeOperand) => void;
}

interface DateRangeOperand {
  operandType: ConditionalFilterOperandType.DateTimeRange;
  value: {
    from?: Date;
    to?: Date;
  };
}

const toDateOperand = (operand: ConditionalFilterDateTimeRangeOperand): DateRangeOperand => ({
  operandType: ConditionalFilterOperandType.DateTimeRange,
  value: {
    from: operand.value.from !== undefined ? new Date(operand.value.from) : undefined,
    to: operand.value.to !== undefined ? new Date(operand.value.to) : undefined,
  },
});

const toOperand = (operand: DateRangeOperand): ConditionalFilterDateTimeRangeOperand => ({
  operandType: ConditionalFilterOperandType.DateTimeRange,
  value: {
    from: operand.value.from && operand.value.from.getTime(),
    to: operand.value.to && operand.value.to.getTime(),
  },
});

export const DateRangeOperandEditor: React.FC<React.PropsWithChildren<DateRangeOperandEditorProps>> = ({
  operand,
  onChange,
}) => {
  const [storedOperand, setStoredOperand] = useState<DateRangeOperand>(toDateOperand(operand));
  useEffect(() => {
    setStoredOperand(toDateOperand(operand));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- operand value change only
  }, [operand.value]);

  const toInput = useRef<FocusableDatePickerInput>();

  const handleChangeFrom = (day?: Date) => {
    if (!day) return;
    const from = day;
    const { to } = storedOperand.value;

    const updatedOperand = {
      ...storedOperand,
      value: {
        from,
        to,
      },
    };
    setStoredOperand(updatedOperand);
    onChange(toOperand(updatedOperand));
    if (toInput.current) {
      toInput.current.focus();
    }
  };

  const handleChangeTo = (day?: Date) => {
    if (!day) return;
    const updatedOperand = {
      ...storedOperand,
      value: {
        ...storedOperand.value,
        to: day,
      },
    };
    setStoredOperand(updatedOperand);
    onChange(toOperand(updatedOperand));
  };

  const disabledDaysFrom = storedOperand.value.to !== undefined ? { after: storedOperand.value.to } : undefined;
  const disabledDaysTo = storedOperand.value.from !== undefined ? { before: storedOperand.value.from } : undefined;

  return (
    <div className={styles.dualDateContainer}>
      <BlvdDatePicker
        value={storedOperand.value.from}
        onDayChange={handleChangeFrom}
        rangeTo={storedOperand.value.to}
        formatTriggerLabel={formatMMDDYYYY}
        triggerProps={{ leftIcon: undefined, iconSpacing: 0, width: 'full' }}
        dayPickerProps={{
          disabled: disabledDaysFrom,
        }}
      />
      <div className={styles.andConnector}>and</div>
      <BlvdDatePicker
        ref={el => (toInput.current = el || undefined)}
        value={storedOperand.value.to}
        onDayChange={handleChangeTo}
        rangeFrom={storedOperand.value.from}
        formatTriggerLabel={formatMMDDYYYY}
        triggerProps={{ leftIcon: undefined, iconSpacing: 0, width: 'full' }}
        dayPickerProps={{
          disabled: disabledDaysTo,
        }}
      />
    </div>
  );
};
