import { MuidUtils } from '@process-street/subgrade/core';
import { Box, Icon, MotionWrapper } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import * as React from 'react';
import { useMicrosoftTeams } from '../use-microsoft-teams';
import { MSTeamsUtils } from '../utils';
import { MicrosoftTeamsIdentityProviderLoadingAnimation, ssoProviders } from './identity-provider-loading-animation';

export const MicrosoftTeamsLoginRedirect = () => {
  const { Auth0Service, $stateParams } = useInjector('Auth0Service', '$stateParams');
  const { microsoftTeams, isReady } = useMicrosoftTeams();

  const provider = $stateParams.provider ?? 'unknown';
  const providerName = ssoProviders[provider];
  const text = `Hold on! We are redirecting you to ${provider === 'sso' ? 'your SSO provider' : providerName}`;

  React.useEffect(() => {
    if (!microsoftTeams || !isReady) return;

    microsoftTeams.initialize();
    microsoftTeams.getContext(() => {
      const state = MuidUtils.randomMuid();
      const redirectTo = Auth0Service.getIDPLoginUrl({ state, connection: provider });

      MSTeamsUtils.setAuthState(state);

      // Give some time to play the animation
      setTimeout(() => window.location.assign(redirectTo), 1000);
    });
  }, [microsoftTeams, isReady, Auth0Service, provider]);

  return (
    <MicrosoftTeamsIdentityProviderLoadingAnimation
      provider={provider}
      text={text}
      icon={
        <MotionWrapper
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            ease: 'easeInOut',
            delay: 0.5,
            duration: 0.5,
          }}
        >
          <MotionWrapper
            animate={{ rotate: [0, 360] }}
            transition={{
              ease: 'easeInOut',
              delay: 3,
              duration: 1,
              repeat: Infinity,
              repeatDelay: 3,
            }}
          >
            <Box mb="-8" mr="0.5">
              <Icon icon="arrow-right-arrow-left" size="6" />
            </Box>
          </MotionWrapper>
        </MotionWrapper>
      }
    />
  );
};
