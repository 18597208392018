import {
  selectedOrganizationAllMembersMembershipSelector,
  selectedOrganizationAllGuestsMembershipSelector,
  selectedOrganizationAllAnonymousMembershipSelector,
} from 'reducers/group/group.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { connectController } from 'reducers/util';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import { TaskTemplatePermitsSelector } from '../store/task-template-permit.selector';
import { TaskPermissionRuleSelector } from '../store/task-permission-rule.selector';
import { UserUtils } from '@process-street/subgrade/util';
import templateUrl from './task-permission-pop-up.component.html';
import './task-permission-pop-up.scss';

export const TaskPermissionPopUpComponent = {
  bindings: {
    user: '<',
    taskTemplate: '<',
    taskTemplates: '<',
    disabled: '<',
  },
  templateUrl,
  controller: class {
    constructor($ngRedux, TaskPermissionRuleActions, TaskPermissionRuleService, TaskTemplatePermitService) {
      'ngInject';

      this.TaskPermissionRuleService = TaskPermissionRuleService;
      this.TaskTemplatePermitService = TaskTemplatePermitService;

      const mapStateToThis = () => state => {
        const selectedOrganizationId = SessionSelector.getSelectedOrganizationId(state);

        const allMembersMembership = selectedOrganizationAllMembersMembershipSelector(state);
        const allGuestsMembership = selectedOrganizationAllGuestsMembershipSelector(state);
        const allAnonymousMembership = selectedOrganizationAllAnonymousMembershipSelector(state);

        const isBulk = (this.taskTemplates && !!this.taskTemplates.length) || false;
        const taskTemplateId = this.taskTemplate && this.taskTemplate.id;
        let taskTemplateIds = [];
        if (isBulk) {
          taskTemplateIds = this.taskTemplates.map(tt => tt.id);
        } else if (taskTemplateId) {
          taskTemplateIds = [taskTemplateId];
        }

        const taskTemplatePermitsSystemGroups =
          TaskTemplatePermitsSelector.getAllByTaskTemplateIdsOnlySystemGroups(taskTemplateIds)(state);

        const taskTemplatePermitsNoSystemGroups = TaskTemplatePermitsSelector.getAllByTaskTemplateIdsNoSystemGroups(
          taskTemplateIds,
        )(state).filter(permit => permit.taskRead);

        const taskTemplateGroupId = this.taskTemplate && this.taskTemplate.group.id;
        let taskTemplateGroupIds = [];
        if (isBulk) {
          taskTemplateGroupIds = this.taskTemplates.map(tt => tt.group.id);
        } else if (taskTemplateGroupId) {
          taskTemplateGroupIds = [taskTemplateGroupId];
        }

        const allTaskPermissionRules = taskTemplateGroupIds
          ? TaskPermissionRuleSelector.getAllByTaskTemplateGroupIds(taskTemplateGroupIds)(state)
          : [];

        const taskPermissionRules = TaskPermissionRuleService.extractUniqueTypedRules(allTaskPermissionRules);

        let templateRevisionId = this.taskTemplate && this.taskTemplate.templateRevision.id;
        if (isBulk) {
          templateRevisionId = this.taskTemplates[0] && this.taskTemplates[0].templateRevision.id;
        }

        const emailAndMembersFieldWidgets = templateRevisionId
          ? WidgetSelector.getAllEmailAndMembersWidgetsByTemplateRevisionId(templateRevisionId)(state)
          : [];

        const widgetsLoaded = templateRevisionId
          ? WidgetSelector.getLoadedStatusByTemplateRevisionId(templateRevisionId)(state)
          : false;

        const permissionMemberships = TaskTemplatePermitService.getMembershipsConcatenation(
          taskTemplatePermitsNoSystemGroups,
        );

        return {
          allAnonymousMembership,
          allGuestsMembership,
          allMembersMembership,
          allTaskPermissionRules,
          emailAndMembersFieldWidgets,
          isBulk,
          permissionMemberships,
          selectedOrganizationId,
          taskPermissionRules,
          taskTemplatePermitsNoSystemGroups,
          taskTemplatePermitsSystemGroups,
          widgetsLoaded,
        };
      };

      const mapDispatchToThis = () => ({
        deleteRuleById: TaskPermissionRuleActions.deleteById,
        deleteAllRulesByIds: TaskPermissionRuleActions.deleteAllByIds,
      });

      const shouldChange = change => change.taskTemplate || change.taskTemplates;

      connectController($ngRedux, mapStateToThis, mapDispatchToThis, shouldChange)(this);

      this.onSwitch = this.onSwitch.bind(this);
    }

    getPermitByMembership(organizationMembership) {
      const { taskTemplatePermitsSystemGroups, isBulk } = this.state;
      const filteredPermits = taskTemplatePermitsSystemGroups.filter(
        permit => permit.organizationMembership.id === organizationMembership.id,
      );

      if (isBulk) {
        const len = this.taskTemplates.length;
        if (len > filteredPermits.length) {
          return true; // has default permits so at least one is set
        } else {
          return filteredPermits.reduce((res, permit) => res || permit.taskRead, false);
        }
      } else {
        return filteredPermits.reduce((res, permit) => res && permit.taskRead, true);
      }
    }

    getSwitchTooltip(organizationMembership) {
      const { taskTemplatePermitsSystemGroups, isBulk } = this.state;
      const filteredPermits = taskTemplatePermitsSystemGroups.filter(
        permit => permit.organizationMembership.id === organizationMembership.id,
      );

      if (isBulk) {
        const len = this.taskTemplates.length;
        const disabledCount = filteredPermits.filter(permit => !permit.taskRead).length;

        if (disabledCount < len) {
          return `Enabled for ${len - disabledCount} of ${len} selected tasks`;
        } else {
          return 'Disabled for all selected tasks';
        }
      } else {
        const enabled = filteredPermits.reduce((res, permit) => res && permit.taskRead, true);
        return enabled ? 'Permission enabled' : 'Permission disabled';
      }
    }

    getPredefinedGroups() {
      const { allMembersMembership, allGuestsMembership, allAnonymousMembership } = this.state;

      if (allMembersMembership && allGuestsMembership && allAnonymousMembership) {
        allAnonymousMembership._allAnonymous = true;

        return [allMembersMembership, allGuestsMembership, allAnonymousMembership];
      } else {
        return [];
      }
    }

    onSwitch(event) {
      const { isBulk } = this.state;
      const { taskTemplate, taskTemplates } = this;

      const permitted = event.target.checked;
      // NOTE data-id must be passed to ps-switch
      const membership = this.getPredefinedGroups().find(({ id }) => id === event.target.id);
      this.TaskTemplatePermitService.upsertPermit(membership, isBulk, taskTemplate, taskTemplates, permitted);
    }

    removePermit(membership) {
      const { isBulk } = this.state;
      const { taskTemplate, taskTemplates } = this;
      const permitted = false;

      this.TaskTemplatePermitService.upsertPermit(membership, isBulk, taskTemplate, taskTemplates, permitted);
    }

    removeRule(rule) {
      const { isBulk } = this.state;
      if (isBulk) {
        const { allTaskPermissionRules } = this.state;
        const templateRevisionId = this.taskTemplates[0].templateRevision.id;

        const filteredRules = allTaskPermissionRules.filter(r =>
          this.TaskPermissionRuleService.areRulesSameType(r, rule),
        );
        this.actions.deleteAllRulesByIds(
          templateRevisionId,
          filteredRules.map(r => r.id),
        );
      } else {
        const templateRevisionId = this.taskTemplate.templateRevision.id;
        this.actions.deleteRuleById(templateRevisionId, rule.id);
      }
    }

    getGroupLabel(user) {
      return UserUtils.getLabel(user, false);
    }
  },
};
