import { TinyMCEEditor } from 'app/features/rich-text';
import { KeyStrings } from 'app/services/key';
import { useCallback, useMemo, useRef } from 'react';
import { WidgetActor } from '../../../form-editor-page-machine/form-editor-page-machine-types';
import { ClientRectObject } from '@popperjs/core';

// @ts-expect-error WidgetActor type is too complex
const navigate = (e: KeyboardEvent, actor: WidgetActor, caretOffset: number) => {
  if (e.key === KeyStrings.ARROW_UP) {
    actor.send({ type: 'FOCUS_PREVIOUS_WIDGET', caretOffset });
  } else if (e.key === KeyStrings.ARROW_DOWN) {
    actor.send({ type: 'FOCUS_NEXT_WIDGET', caretOffset });
  }
};

function getCaretAbsolutePosition(editor: TinyMCEEditor) {
  const { selection } = editor;

  if (!selection || !selection.getRng) return null;

  const range = selection.getRng(); // Get the caret range
  const rect = range.getBoundingClientRect(); // Bounding rectangle of the caret

  // Calculate absolute position
  const absolutePosition = {
    left: rect.left + window.scrollX, // Horizontal position in the document
    top: rect.top + window.scrollY, // Vertical position in the document
    width: rect.width,
    height: rect.height,
  };

  return absolutePosition;
}

const getCaretOffset = (editor: TinyMCEEditor) => editor.selection?.getRng?.().startOffset;

export const useTextContentKeyboardNavigation = (editor: TinyMCEEditor | undefined, actor: WidgetActor) => {
  const lastCaretClientBoundingRect = useRef<Pick<ClientRectObject, 'top'> | null>(
    editor
      ? getCaretAbsolutePosition(editor)
      : {
          top: 0,
        },
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent, editor: TinyMCEEditor) => {
      const caretOffset = getCaretOffset(editor);
      requestAnimationFrame(() => {
        const currentCaretClientBoundingRect = getCaretAbsolutePosition(editor);

        if (e.key === KeyStrings.ARROW_UP || e.key === KeyStrings.ARROW_DOWN) {
          // That means the cursor hasn't moved, so it reached an edge.
          if (
            lastCaretClientBoundingRect.current &&
            lastCaretClientBoundingRect.current?.top === currentCaretClientBoundingRect?.top
          ) {
            navigate(e, actor, caretOffset);
          }
        } else if (e.key === KeyStrings.BACKSPACE && editor.getContent({ format: 'text' }).length === 0) {
          actor.send({ type: 'FOCUS_PREVIOUS_WIDGET' });
          actor.send({ type: 'DELETE_WIDGET' });
        }

        lastCaretClientBoundingRect.current = currentCaretClientBoundingRect;
      });
    },
    [actor],
  );

  const handleFocus = useCallback((editor: TinyMCEEditor) => {
    requestAnimationFrame(() => {
      lastCaretClientBoundingRect.current = getCaretAbsolutePosition(editor);
    });
  }, []);

  return useMemo(
    () => ({
      onKeyDown: handleKeyDown,
      onFocus: handleFocus,
    }),
    [handleKeyDown, handleFocus],
  );
};
