import { Muid, Option } from '@process-street/subgrade/core';
import { Checklist, ChecklistStats } from '@process-street/subgrade/process';
import { BaseChecklistSelector } from '@process-street/subgrade/redux/selector/checklist.selectors';
import { TemplateSelector } from 'reducers/template/template.selectors';
import { ReduxAppState } from 'reducers/types';
import { createSelector, Selector } from 'reselect';

export const getWithTemplateById = (checklistId: Muid) => (state: ReduxAppState) => {
  const checklist = BaseChecklistSelector.getById(checklistId)(state);
  if (checklist) {
    const template = TemplateSelector.getById(checklist.template.id)(state);
    return { ...checklist, template };
  }

  return checklist;
};

export const getAllIdsByTemplateId =
  (templateId: Muid): Selector<ReduxAppState, Muid[]> =>
  (state: ReduxAppState) =>
    state.checklist.byTemplate[templateId] || [];

export const getAllByTemplateId = (templateId: Muid): Selector<ReduxAppState, Checklist[]> =>
  createSelector(
    [ChecklistSelector.getAllIdsByTemplateId(templateId), BaseChecklistSelector.getEntityMap],
    (checklistsByTemplateIds, entityMap) => checklistsByTemplateIds.map(checklistId => entityMap[checklistId]),
  );

export const getStatsByChecklistId =
  (checklistId: Muid): Selector<ReduxAppState, Option<ChecklistStats>> =>
  (state: ReduxAppState) =>
    state.checklist.stats[checklistId];

export const ChecklistSelector = {
  getAllByTemplateId,
  getAllIdsByTemplateId,
  getById: BaseChecklistSelector.getById,
  getEntityMap: BaseChecklistSelector.getEntityMap,
  getForCurrentChecklistId: BaseChecklistSelector.getForCurrentChecklistId,
  getStatsByChecklistId,
  getWithTemplateById,
};
