import { TimeBasedCondition } from '@process-street/subgrade/conditional-logic';
import { Checklist } from '@process-street/subgrade/process';
import dayjs from 'dayjs';

interface TimeBasedConditionEvaluatorType {
  evaluate(checklist: Checklist, timeBasedCondition: TimeBasedCondition): boolean;
}

export const TimeBasedConditionEvaluator: TimeBasedConditionEvaluatorType = {
  evaluate: (checklist, condition) => {
    const period = condition.operand.value;
    if (!period) return false;

    const date = dayjs(checklist.audit.createdDate);

    return date.add(dayjs.duration(period)).isBefore(Date.now());
  },
};
