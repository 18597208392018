import * as React from 'react';
import { Box, BoxProps, MotionWrapper, Portal } from 'components/design/next';
import { ChartBoxContext } from './context';
import { useChartBox } from './use-chart-box';
import { ChartBoxOverlay } from './chart-box-overlay';
import useKey from 'react-use/lib/useKey';
import { AnimatePresence, MotionProps } from 'framer-motion';
import { DIALOG_ROLE } from './constants';
import { ChartBoxTitle } from './chart-box-title';
import { ChartBoxHeader } from './chart-box-header';

export interface ChartBoxProps extends BoxProps {}

const escKeyPressed = (e: KeyboardEvent) => {
  return e.key === 'Escape';
};

const commonBoxProps: BoxProps = {
  p: '6',
  borderRadius: 'xl',
  borderColor: 'gray.200',
  borderWidth: 'px',
  borderStyle: 'solid',
  bg: 'white',
};

const commonMotionProps: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const ChartBox: React.FC<React.PropsWithChildren<ChartBoxProps>> = ({ children, ...props }) => {
  let chartName: string = '';

  const findChartTitle = (child: typeof children) => {
    if (!React.isValidElement(child)) return;
    if (child.type === ChartBoxTitle && typeof child.props.children === 'string') {
      chartName = child.props.children;
    }
    if (child.type === ChartBoxHeader) {
      React.Children.forEach(child.props.children, findChartTitle);
    }
  };
  React.Children.forEach(children, findChartTitle);
  if (!chartName) {
    throw new Error('Expected ChartBoxTitle children to be a string');
  }

  const chartBox = useChartBox({ chartName });
  const { isExpanded, setIsExpanded } = chartBox;

  useKey(escKeyPressed, setIsExpanded.off);

  return (
    <ChartBoxContext.Provider value={chartBox}>
      <AnimatePresence>
        {isExpanded && (
          <Portal>
            <ChartBoxOverlay />
            <MotionWrapper {...commonMotionProps}>
              <Box
                {...{
                  ...commonBoxProps,
                  ...props,
                  role: DIALOG_ROLE,
                  position: 'absolute',
                  zIndex: 'modal',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '95vw',
                  height: { base: '90vh', md: '80vh' },
                }}
              >
                {children}
              </Box>
            </MotionWrapper>
          </Portal>
        )}
        {!isExpanded && (
          <MotionWrapper {...commonMotionProps}>
            <Box
              {...{
                position: 'relative',
                ...props,
              }}
            >
              {children}
            </Box>
          </MotionWrapper>
        )}
      </AnimatePresence>
    </ChartBoxContext.Provider>
  );
};
