import angular from 'angular';
import marked from 'marked';
import { UrlUtils } from 'components/utils/url-utils';

angular.module('frontStreetApp.services').factory('marked', () => {
  marked.Lexer.rules.gfm.heading = { exec: angular.noop };
  marked.Lexer.rules.gfm.lheading = { exec: angular.noop };

  // eslint-disable-next-line max-len
  marked.InlineLexer.rules.breaks.url =
    /^((((?:http|https):(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.:!/\\w]*))*)/;

  const renderer = new marked.Renderer();
  renderer.link = function (href, title, text) {
    const prefixedHref = href.indexOf('http') !== 0 && !href.startsWith('mailto:') ? `http://${href}` : href;

    let out = `<a href="${prefixedHref}"`;
    if (title) {
      out += ` title="${title}"`;
    }

    if (!UrlUtils.isAppUrl(prefixedHref)) {
      out += ' target="_blank" rel="noopener noreferrer"';
    }

    out += `>${text}</a>`;
    return out;
  };

  marked.setOptions({
    renderer,
    gfm: true,
    tables: false,
    breaks: true,
    sanitize: true,
    mangle: false,
  });

  return marked;
});
