import angular from 'angular';
import { EventName } from 'services/event-name';
import { AuthEvent } from 'services/auth-event/AuthEvent';

angular
  .module('frontStreetApp.services')
  .service('ProfitwellListener', function ($rootScope, OrganizationService, ProfitwellService, SessionService) {
    this.start = (organizationId, userId) => {
      if (organizationId && userId) {
        Promise.all([
          OrganizationService.getById(organizationId),
          OrganizationService.getAllOrganizationMemberships(userId),
        ]).then(([organization, memberships]) => {
          const { email } = organization;
          const currentMembership = memberships.find(
            om => om.organization.id === organizationId && om.user.id === userId,
          );
          const role = currentMembership?.role;

          ProfitwellService.start(email, role);
        });
      }
    };

    this.listen = () => {
      $rootScope.$on(EventName.SELECTED_ORGANIZATION_UPDATED, (__event, organizationId) => {
        const user = SessionService.getUser();

        this.start(organizationId, user?.id);
      });

      $rootScope.$on(AuthEvent.USER_LOGGED_OUT, () => {
        ProfitwellService.stop();
      });
    };
  });
