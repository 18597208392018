import { Muid } from '@process-street/subgrade/core';
import { FormFieldValueWithWidget } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { QueryClient, QueryObserver, QueryObserverOptions, useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetFormFieldValuesByChecklistRevisionIdQueryParams = { checklistRevisionId?: Muid };

export type GetFormFieldValuesByChecklistRevisionIdQueryResponse = FormFieldValueWithWidget[];

export const GetFormFieldValuesByChecklistRevisionIdQuery = {
  key: ['checklist-revisions', 'form-field-values'],
  getKey: (params: GetFormFieldValuesByChecklistRevisionIdQueryParams): QueryKey => [
    ...GetFormFieldValuesByChecklistRevisionIdQuery.key,
    params,
  ],
  queryFn: ({ checklistRevisionId }: GetFormFieldValuesByChecklistRevisionIdQueryParams) =>
    axiosService
      .getAxios()
      .get<GetFormFieldValuesByChecklistRevisionIdQueryResponse>(
        `/1/checklist-revisions/${checklistRevisionId}/form-field-values`,
      )
      .then(res => res.data),
};

export const useGetFormFieldValuesByChecklistRevisionIdQuery = <
  Select = GetFormFieldValuesByChecklistRevisionIdQueryResponse,
>(
  params: GetFormFieldValuesByChecklistRevisionIdQueryParams,
  options: UseQueryOptions<GetFormFieldValuesByChecklistRevisionIdQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetFormFieldValuesByChecklistRevisionIdQuery.getKey(params),
    () => GetFormFieldValuesByChecklistRevisionIdQuery.queryFn(params),
    {
      ...options,
      enabled: Boolean(params.checklistRevisionId) && options.enabled !== false,
    },
  );
};

export const makeGetFormFieldValuesByChecklistRevisionIdQueryObserver = ({
  queryClient,
  options,
  ...params
}: GetFormFieldValuesByChecklistRevisionIdQueryParams & {
  queryClient: QueryClient;
  options: QueryObserverOptions<GetFormFieldValuesByChecklistRevisionIdQueryResponse, AxiosError>;
}) => {
  return new QueryObserver<GetFormFieldValuesByChecklistRevisionIdQueryResponse, AxiosError>(queryClient, {
    queryKey: GetFormFieldValuesByChecklistRevisionIdQuery.getKey(params),
    queryFn: () => GetFormFieldValuesByChecklistRevisionIdQuery.queryFn(params),
    enabled: Boolean(params.checklistRevisionId) && options.enabled !== false,
    ...options,
  });
};
