import { isPrivateRootFolder } from 'features/folder/lib';
import { Option } from 'space-monad';
import { match, P } from 'ts-pattern';
import { useFoldersQuery } from './query';
import always from 'lodash/fp/always';
import { isActiveChildFolder, isActiveChildOrOrphanFolder } from 'reducers/folder/folder.selectors';
import {
  getAllByFolderAndTemplateStatusesPredicate,
  getAllOrphansAndFolderByFolderAndTemplateStatusesPredicate,
} from 'reducers/template/template.selectors';
import { GetTemplateQuery, useGetAllTemplatesQuery } from 'features/template/query-builder';
import { Template, TemplateStatus } from '@process-street/subgrade/process';
import { useGetTagMembershipsQuery } from 'features/tags/query-builder';
import { isModelRef } from '@process-street/subgrade/core';
import { useLibraryContext } from 'pages/library/context';
import { TemplateStatusesMap } from 'pages/library/constants';
import { useFoldersPathQuery } from 'hooks/use-folders-path-query';
import { useDispatch, useSelector } from 'react-redux';
import { FOLDER_GET_ALL_BY_ORGANIZATION_ID } from 'reducers/folder/folder.actions';
import { useQueryClient } from 'react-query';
import { SessionSelector } from 'reducers/session/session.selectors';
import {
  useGetAllConsolidatedFolderPermissionsQuery,
  useGetAllConsolidatedTemplatePermissionsQuery,
} from 'features/permissions/query-builder';
import { useWriteableFoldersQuery } from 'features/folder/query-builder';
import { useFeatureFlag } from 'features/feature-flags';

export function useFolderViewRows() {
  const queryClient = useQueryClient();
  const { templateStatusView, view } = useLibraryContext();
  const statuses = TemplateStatusesMap.get(templateStatusView) ?? [];
  const dispatch = useDispatch();
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const isDragAndDropEnabled = useFeatureFlag('dragAndDropLibrary');

  const foldersQuery = useFoldersQuery({
    onSuccess: folders => {
      // PremadeTemplateService depends on DataService folders which depends on redux :(
      // TODO refactor folder service to not depend on redux
      dispatch({ type: FOLDER_GET_ALL_BY_ORGANIZATION_ID, payload: folders });
    },
  });
  const foldersPathQuery = useFoldersPathQuery();
  const foldersPath = foldersPathQuery.data ?? [];
  const [rootFolder] = foldersPath;
  foldersPath.reverse(); // in place
  const folderOpt = Option(foldersPath[0]);
  // Having a bit of pedantic fun here with `always`.
  // `always(always(false))` means always return a function that always returns false :)
  const folderPredicate = folderOpt.fold(always(always(false)), folder =>
    match(folder)
      .with({ parentFolder: undefined }, f => isActiveChildOrOrphanFolder(f.id))
      .with(P.not(undefined), f => isActiveChildFolder(f.id))
      .otherwise(always(always(false))),
  );
  const visibleFolders = foldersQuery.data?.filter(folderPredicate).sort((a, b) => a.name.localeCompare(b.name)) ?? [];

  const templatePredicate = folderOpt.fold(always(always(false)), folder =>
    match(folder)
      .with({ parentFolder: undefined }, folder =>
        getAllOrphansAndFolderByFolderAndTemplateStatusesPredicate({
          folderId: folder.id,
          folderIds: foldersQuery.data?.map(f => f.id) ?? [],
          organizationId: folder.organization.id,
          statuses,
        }),
      )
      .with(P.not(undefined), f =>
        getAllByFolderAndTemplateStatusesPredicate({
          folderId: f.id,
          statuses,
          organizationId: f.organization.id,
        }),
      )
      .otherwise(always(always(false))),
  );

  const templatesQuery = useGetAllTemplatesQuery(
    {
      organizationId: organizationId!,
      templateStatus: templateStatusView === 'active' ? TemplateStatus.Active : TemplateStatus.Archived,
    },
    {
      enabled: Boolean(organizationId),
      staleTime: GetTemplateQuery.staleTime,
      select: templates =>
        templates.filter(tmpl =>
          templateStatusView === 'active'
            ? tmpl.status === TemplateStatus.Active
            : tmpl.status === TemplateStatus.Archived,
        ),
      onSuccess: templates => {
        templates.forEach(template => {
          queryClient.setQueryData(GetTemplateQuery.getKey({ templateId: template.id }), template);
        });
      },
    },
  );

  const folderPermissionsQuery = useGetAllConsolidatedFolderPermissionsQuery({
    enabled: isDragAndDropEnabled,
  });

  const templatePermissionsQuery = useGetAllConsolidatedTemplatePermissionsQuery({
    enabled: isDragAndDropEnabled,
  });

  const writeableFoldersQuery = useWriteableFoldersQuery(organizationId ?? '', {
    enabled: isDragAndDropEnabled && Boolean(organizationId),
  });

  const visibleTemplates =
    templatesQuery.data?.filter(templatePredicate).sort((a, b) => a.name.localeCompare(b.name)) ?? [];

  const rows = [...visibleFolders, ...visibleTemplates];

  const foldersLoading =
    foldersQuery.isIdle ||
    foldersQuery.isLoading ||
    folderPermissionsQuery.isLoading ||
    writeableFoldersQuery.isLoading;
  const templatesLoading =
    foldersLoading || templatesQuery.isIdle || templatesQuery.isLoading || templatePermissionsQuery.isLoading;
  const isLoading = foldersLoading || templatesLoading || view !== 'folder';
  const isEmpty = view !== 'scheduled' && !isLoading && rows.length === 0;
  const isPrivateLibrary = isPrivateRootFolder(rootFolder);

  return {
    rows,
    isEmpty,
    isLoading,
    isPrivateLibrary,
  };
}

export function useTagViewRows() {
  const { templateStatusView, view, path: tag } = useLibraryContext();
  const queryClient = useQueryClient();
  const tagMembershipsQuery = useGetTagMembershipsQuery(
    {},
    {
      select: tagMemberships => {
        return (
          tagMemberships
            .filter(tm => {
              return (isModelRef(tm.tag) ? tm.tag.name === tag : false) && isModelRef(tm.template)
                ? templateStatusView === 'active'
                  ? tm.template.status === TemplateStatus.Active
                  : true
                : false;
            })
            // we can be sure based on `isModelRef(tm.template)  ? ... : false` from above
            .map(tm => tm.template as Template)
            .sort(({ name: a }, { name: b }) => a.localeCompare(b)) ?? []
        );
      },
      onSuccess: (templates: Template[]) => {
        templates.forEach(template => {
          queryClient.setQueryData(GetTemplateQuery.getKey({ templateId: template.id }), template);
        });
      },
    },
  );

  const isLoading = tagMembershipsQuery.isLoading || view !== 'tag';
  const isEmpty = !isLoading && tagMembershipsQuery.data?.length === 0;

  return {
    rows: tagMembershipsQuery.data ?? [],
    isEmpty,
    isLoading,
  };
}
