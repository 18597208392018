import { TaskAssignment } from '@process-street/subgrade/role-assignment';
import { Box, HStack, List, ListItem, ListProps, Text } from 'components/design/next';
import * as React from 'react';
import groupBy from 'lodash/groupBy';
import { isNotIdRef, User, UserType } from '@process-street/subgrade/core';
import { Avatar, AvatarSize } from 'components/common/Avatar';
import { useUserDeactivationHelpers } from 'features/user/use-user-deactivation-helpers';
import { RemoveAssigneeButton } from '../components/remove-assignee-button';

export type TasksAssignmentsListsProps = {
  tasksAssignments: TaskAssignment[];
  /**
   * If present, the component displays individual assignments with a remove button.
   * If missing, the component groups task assignments.
   * */
  onRemove?: (assignment: TaskAssignment) => void;
};

export interface UserWithTasksCount {
  user: User;
  tasksCount: number;
  assignment?: TaskAssignment;
}

export const TasksAssignmentsLists: React.FC<React.PropsWithChildren<ListProps & TasksAssignmentsListsProps>> = ({
  tasksAssignments,
  onRemove,
  ...props
}) => {
  const [usersWithTasks, setUsersWithTasks] = React.useState<UserWithTasksCount[]>([]);
  const deactivationHelpers = useUserDeactivationHelpers();

  React.useEffect(
    function groupAssignmentsByUsers() {
      const groupedTaskAssignments = groupBy(tasksAssignments, x => x.organizationMembership.id);

      const users: UserWithTasksCount[] = [];
      Object.entries(groupedTaskAssignments).forEach(([_, userAssignments]) => {
        const firstAssignment = userAssignments[0];
        if (
          firstAssignment &&
          isNotIdRef(firstAssignment.organizationMembership) &&
          isNotIdRef(firstAssignment.organizationMembership.user)
        )
          users.push({
            user: { ...firstAssignment.organizationMembership.user },
            tasksCount: userAssignments.length,
            assignment: userAssignments.length === 1 ? firstAssignment : undefined,
          });
      });
      setUsersWithTasks(users);
    },
    [tasksAssignments, onRemove],
  );

  return (
    <List w="full" {...props}>
      {usersWithTasks.map(userWithTasks => (
        <ListItem
          key={userWithTasks.user.id}
          py="2"
          opacity={deactivationHelpers.isActive(userWithTasks.user.id) ? 1 : 0.6}
        >
          <HStack justifyContent="space-between">
            {deactivationHelpers.withTooltip(
              userWithTasks.user.id,
              <HStack>
                <Box w="6" mr="1">
                  <Avatar user={userWithTasks.user} size={AvatarSize.Small} showUnconfirmedBadge />
                </Box>

                <Box maxW={78}>
                  <Text variant="-1" fontWeight="medium" color="gray.600" noOfLines={1}>
                    {userWithTasks.user.username}
                  </Text>

                  <Text variant="-2" color="gray.500" noOfLines={1}>
                    {userWithTasks.user.userType === UserType.Group ? 'Group' : userWithTasks.user.email}
                  </Text>
                </Box>
              </HStack>,
            )}
            {!onRemove && (
              <Text fontWeight="medium" fontSize="sm" color="gray.400">
                {userWithTasks.tasksCount} Task{userWithTasks.tasksCount > 1 ? 's' : ''}
              </Text>
            )}
            {onRemove && userWithTasks.assignment && (
              <RemoveAssigneeButton onClick={() => userWithTasks.assignment && onRemove(userWithTasks.assignment)} />
            )}
          </HStack>
        </ListItem>
      ))}
    </List>
  );
};
