import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';
import { DropzoneState, useDropzone } from 'react-dropzone';
import {
  FormFieldValue,
  FormFieldWidget,
  RichEmailWidgetAttachmentWithS3File,
  Template,
} from '@process-street/subgrade/process';
import { useUploadToS3Mutation } from 'features/upload/query-builder';
import { useUploadUrlChecklistEmailAttachmentMutation } from '../query-builder/upload-url-checklist-email-attachment-mutation';
import { useFinishEmailAttachmentChecklistUploadMutation } from '../query-builder/finish-email-attachment-checklist-upload-mutation';

export const SIZE_TOO_LARGE = 'size-too-large';
const MAX_FILE_SIZE_MB = 25;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

type UploadAttachmentReturn = {
  dropzoneState: DropzoneState;
  uploadError: AxiosError | null;
};

type UploadChecklistEmailAttachmentProps = {
  taskId: Template['id'];
  widgetId: FormFieldWidget['id'];
  formFieldValueId?: FormFieldValue['id'];
  onFinish: (attachment: RichEmailWidgetAttachmentWithS3File) => void;
  onCreateFormFieldValue: () => Promise<FormFieldValue>;
  setIsUploading: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  setProgress: (progress: number | undefined) => void;
  totalSize: number;
};

export const useUploadChecklistEmailAttachment = ({
  taskId,
  widgetId,
  formFieldValueId,
  onFinish,
  onCreateFormFieldValue,
  setIsUploading,
  setProgress,
  totalSize,
}: UploadChecklistEmailAttachmentProps): UploadAttachmentReturn => {
  const uploadUrlMutation = useUploadUrlChecklistEmailAttachmentMutation();
  const uploadToS3Mutation = useUploadToS3Mutation();

  const finishUploadAttachmentMutation = useFinishEmailAttachmentChecklistUploadMutation({
    onSuccess: data => {
      onFinish(data);
    },
    onError: () => {},
  });

  const uploadError = uploadUrlMutation.error ?? uploadToS3Mutation.error ?? finishUploadAttachmentMutation.error;

  useEffect(() => {
    if (uploadError) {
      setProgress(undefined);
      setIsUploading.off();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query error
  }, [uploadError]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;

      const [file] = acceptedFiles;

      const reader = new FileReader();

      // If doesn't exist, create form field value while starting upload
      const formFieldValueIdPromise = formFieldValueId
        ? Promise.resolve(formFieldValueId)
        : onCreateFormFieldValue().then(ffv => ffv.id);

      const { url, key } = await uploadUrlMutation.mutateAsync(
        {
          taskId,
          fileName: file.name,
          mimeType: file.type,
        },
        {
          onSuccess: () => {
            setIsUploading.on();
            setProgress(1);

            reader.readAsArrayBuffer(file);
          },
        },
      );

      reader.onload = async () => {
        if (reader.readyState === 2 && reader.result !== null) {
          uploadToS3Mutation.mutateAsync(
            {
              file,
              url,
              data: reader.result,
              onProgress: (progress: number) => setProgress(progress),
            },
            {
              onSuccess: async () => {
                await finishUploadAttachmentMutation.mutateAsync({
                  taskId,
                  widgetId,
                  formFieldValueId: await formFieldValueIdPromise,
                  contentType: file.type,
                  originalFilename: file.name,
                  key,
                });
              },
            },
          );
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
    [taskId, widgetId, formFieldValueId],
  );

  const dropzoneState = useDropzone({
    onDrop,
    validator: file => {
      if (file.size > MAX_FILE_SIZE - totalSize) {
        return {
          code: SIZE_TOO_LARGE,
          message: `Uploaded files must not exceed ${MAX_FILE_SIZE_MB} MB in total.`,
        };
      }
      return null;
    },
  });

  return {
    dropzoneState,
    uploadError,
  };
};
