export enum Entities {
  APPROVAL_RULE_SUBJECT = 'approvalRuleSubject',
  APPROVAL = 'approval',
  CHECKLIST = 'checklists',
  CHECKLIST_ASSIGNMENT = 'checklistAssignments',
  CHECKLIST_PERMIT = 'checklistPermits',
  CHECKLIST_TASK_ASSIGNMENT = 'checklistTaskAssignments',
  DYNAMIC_DUE_DATE = 'dynamicDueDate',
  FOLDER = 'folders',
  FOLDER_PERMIT = 'folderPermits',
  GROUP = 'groups',
  GROUP_MEMBERSHIP = 'groupMemberships',
  ORGANIZATION = 'organizations',
  ORGANIZATION_DOMAIN = 'organizationDomain',
  ORGANIZATION_MEMBERSHIP = 'organizationMemberships',
  PLAN = 'plans',
  PLAN_FEATURE_SET = 'planFeatureSet',
  ROLE_ASSIGNMENT_RULE = 'roleAssignmentRules',
  TASK = 'task',
  TASK_PERMISSION_RULE = 'taskPermissionRules',
  TASK_STATS = 'taskStats',
  TASK_TEMPLATE = 'taskTemplates',
  TASK_TEMPLATE_ASSIGNMENT = 'templateTaskAssignments',
  TASK_TEMPLATE_PERMIT = 'taskTemplatePermits',
  TEMPLATE = 'templates',
  TEMPLATE_PERMIT = 'templatePermits',
  TEMPLATE_REVISION = 'templateRevision',
  USER = 'users',
  WIDGET = 'widgets',
}
