import angular from 'angular';

angular.module('frontStreetApp.services').factory('RulesApi', function (ResourceFactory) {
  return ResourceFactory.create({
    getAllByTemplateRevisionId: 'GET /1/template-revisions/:templateRevisionId/rule-definitions',
    getAllByChecklistRevisionId: 'GET /1/checklist-revisions/:checklistRevisionId/rule-definitions',
    createOrUpdateByTemplateRevisionId: 'POST /1/template-revisions/:templateRevisionId/rule-definitions',
    deltaUpdate: 'POST /1/template-revisions/:templateRevisionId/rule-definitions/delta',
  });
});
