import { Organization, OrganizationDetails } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { HttpStatus } from '@process-street/subgrade/util';

export type GetOrganizationDetailsParams = {
  organizationId?: Organization['id'];
};

export type GetOrganizationDetailsResult = OrganizationDetails | undefined;

export const GetOrganizationDetails = {
  key: ['organization', 'details'],
  getKey: (params: GetOrganizationDetailsParams): QueryKey => [...GetOrganizationDetails.key, params.organizationId],
  queryFn: (params: GetOrganizationDetailsParams) =>
    axiosService
      .getAxios()
      .get<GetOrganizationDetailsResult>(`/1/organizations/${params.organizationId}/details`, {
        validateStatus: status => {
          return status === HttpStatus.OK || status === HttpStatus.NOT_FOUND;
        },
      })
      .then(res => {
        if (res.status === HttpStatus.NOT_FOUND) {
          return undefined;
        } else {
          return res.data;
        }
      }),
};

export const useGetOrganizationDetails = (
  params: GetOrganizationDetailsParams,
  options: UseQueryOptions<GetOrganizationDetailsResult, AxiosError> = {},
) => {
  return useQuery(GetOrganizationDetails.getKey(params), () => GetOrganizationDetails.queryFn(params), {
    ...options,
    enabled: Boolean(params.organizationId) && options.enabled !== false,
  });
};
