import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ClearIndicatorProps, GroupBase } from 'react-select';

export const ClearIndicator = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: ClearIndicatorProps<Option, IsMulti, Group>,
) => {
  const { innerProps } = props;
  return (
    <div {...innerProps} className={'blvd-select__indicator'} aria-label="clear selection">
      <FontAwesomeIcon icon={faTimes} />
    </div>
  );
};
