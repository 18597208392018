import { createSelector, Selector } from 'reselect';
import { Muid, Option } from '../../core';
import { DueDateRuleDefinition } from '../../process';
import { safeEntityMapToArrayByIdsWith } from '../safe-entity-map-to-array-by-ids';
import { BaseReduxState, EntityMap, LookupMap } from '../types';

const getById =
  (id: Muid): Selector<BaseReduxState, Option<DueDateRuleDefinition>> =>
  (state: BaseReduxState) =>
    state.entities.dynamicDueDate[id];

const getEntityMap: Selector<BaseReduxState, EntityMap<DueDateRuleDefinition>> = (state: BaseReduxState) =>
  state.entities.dynamicDueDate;

const getLookupsByTemplateRevisionId: Selector<BaseReduxState, LookupMap> = (state: BaseReduxState) =>
  state.lookups.dynamicDueDate.byTemplateRevisionId;

const getByTemplateRevisionId = (templateRevisionId: Muid): Selector<BaseReduxState, DueDateRuleDefinition[]> =>
  createSelector(getLookupsByTemplateRevisionId, getEntityMap, (lookupMap, entityMap) =>
    safeEntityMapToArrayByIdsWith(entityMap, lookupMap[templateRevisionId] ?? []),
  );

export const BaseDueDateRuleDefinitionSelector = {
  getById,
  getEntityMap,
  getByTemplateRevisionId,
};
