import { ChecklistColumn } from './columns';
import { ProgressStatus } from './conditional-filters';
import { GridSortOrder, InboxGridData } from './grid';
import { ProgressStatusUtils } from './progress-status-utils';

const filterByName = (query: string, items: InboxGridData[]): InboxGridData[] => {
  const normalizedQuery = query.trim().toLowerCase();
  if (normalizedQuery === '') {
    return items;
  }

  return items.filter(
    item =>
      (!!item.checklistName && item.checklistName.toLowerCase().includes(normalizedQuery)) ||
      item.templateName.toLowerCase().includes(normalizedQuery) ||
      (!!item.nextTask && item.nextTask.name && item.nextTask.name.toLowerCase().includes(normalizedQuery)),
  );
};

type InboxGridDataComparison = (a: InboxGridData, b: InboxGridData) => number;

const ProgressStatusOrder: { [key in ProgressStatus]: number } = {
  [ProgressStatus.Overdue]: 1,
  [ProgressStatus.DueSoon]: 2,
  [ProgressStatus.OnTrack]: 3,
  [ProgressStatus.Completed]: 4,
  [ProgressStatus.Archived]: 5,
};

const ChecklistColumnToSort: { [key in ChecklistColumn]: InboxGridDataComparison } = {
  Selection: () => 0,
  Assignees: () => 0,
  ChecklistCompletedBy: () => 0,
  ChecklistLabelId: () => 0,
  ChecklistCompletedDate: (a, b) => (a.checklistCompletedDate || Date.now()) - (b.checklistCompletedDate || Date.now()),
  ChecklistCreateDate: (a, b) => a.checklistCreatedDate - b.checklistCreatedDate,
  ChecklistDueDate: (a, b) => {
    if (a.checklistDueDate && b.checklistDueDate) {
      return a.checklistDueDate - b.checklistDueDate;
    }
    if (a.checklistDueDate) {
      return -1;
    }
    if (b.checklistDueDate) {
      return 1;
    }
    return 0;
  },
  ChecklistName: (a, b) => (a.checklistName ?? '').localeCompare(b.checklistName ?? ''),
  TemplateName: (a, b) => (a.templateName ?? '').localeCompare(b.templateName ?? ''),
  CompletedTasksCount: (a, b) => a.progress.completedTasksCount - b.progress.completedTasksCount,
  LastActiveDate: (a, b) => a.lastActiveDate - b.lastActiveDate,
  OverdueTasksCount: (a, b) => a.overdueTasksCount - b.overdueTasksCount,
  TotalCommentsAndAttachmentsCount: () => 0,
  ProgressStatus: (a, b) =>
    ProgressStatusOrder[ProgressStatusUtils.getByInboxGridData(a)] -
    ProgressStatusOrder[ProgressStatusUtils.getByInboxGridData(b)],
  TemplateVersion: (a, b) => a.templateVersion - b.templateVersion,
};

const sortByOrder = (order: GridSortOrder, items: InboxGridData[]): InboxGridData[] => {
  items.sort(ChecklistColumnToSort[order.sortBy]);
  if (!order.sortAsc) {
    items.reverse();
  }
  return items;
};

export const InboxGridDataUtils = {
  filterByName,
  sortByOrder,
};
