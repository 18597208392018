import {
  DueDateRuleSourceType,
  DueDateRuleOffsetDirection,
  TaskTemplate,
  FormFieldWidget,
  DueDateRuleDefinition,
  Checklist,
} from '@process-street/subgrade/process';
import { Muid, Option } from '@process-street/subgrade/core';
import { match } from 'ts-pattern';
import { DateUtils } from '@process-street/subgrade/util';

function getLabelByRule(rule: DueDateRuleDefinition, taskList: TaskTemplate[], dateFieldList: FormFieldWidget[]) {
  const { sourceType } = rule;

  switch (sourceType) {
    case DueDateRuleSourceType.ChecklistDueDate:
      return 'workflow run due date';
    case DueDateRuleSourceType.ChecklistStartDate:
      return 'workflow run start date';
    case DueDateRuleSourceType.PreviousTaskCompletedDate:
      return 'previous task checked';
    case DueDateRuleSourceType.TaskCompletedDate: {
      const task = rule.taskTemplateGroup && getTaskByGroupId(taskList, rule.taskTemplateGroup.id);
      const name = task ? task.name : 'unknown';
      return `task '${name}' completed date`;
    }
    case DueDateRuleSourceType.TaskDueDate: {
      const task = rule.taskTemplateGroup && getTaskByGroupId(taskList, rule.taskTemplateGroup?.id);
      const name = task ? task.name : 'unknown';
      return `task '${name}' due date`;
    }
    case DueDateRuleSourceType.FormFieldValue: {
      const dateWidget =
        rule.formFieldWidgetGroup && getDateWidgetByGroupId(dateFieldList, rule.formFieldWidgetGroup.id);
      const name = dateWidget ? dateWidget.label : 'unknown';
      return `form value '${name}'`;
    }
    default:
      return sourceType;
  }
}

function getTaskByGroupId(taskList: TaskTemplate[], groupId: Muid) {
  return taskList.find(task => task.group.id === groupId);
}

function getDateWidgetByGroupId(dateWidgets: FormFieldWidget[], groupId: Muid) {
  return dateWidgets.find(widget => widget.header.group.id === groupId);
}

function getOffsetLabel(value: number, singularLabel: string, pluralLabel: string) {
  if (value === 1) {
    return `${value} ${singularLabel}`;
  } else if (value > 1) {
    return `${value} ${pluralLabel}`;
  } else {
    return '';
  }
}

function getFullLabelByRule(
  rule: Option<DueDateRuleDefinition>,
  taskTemplates: TaskTemplate[],
  dateWidgets: FormFieldWidget[],
) {
  if (rule) {
    let label = 'Due ';
    const offset = rule.dueOffset;

    label += getOffsetLabel(offset.years, 'year ', 'years ');
    label += getOffsetLabel(offset.months, 'month ', 'months ');
    label += getOffsetLabel(offset.days, 'day ', 'days ');
    label += getOffsetLabel(offset.hours, 'hr ', 'hrs ');
    label += getOffsetLabel(offset.minutes, 'min ', 'mins ');

    if (rule.offsetDirection === DueDateRuleOffsetDirection.After) {
      label += 'after ';
    } else {
      label += 'before ';
    }

    label += getLabelByRule(rule, taskTemplates, dateWidgets);

    return label;
  } else {
    return 'Due date';
  }
}

function getModalLabelByRule(
  rule: Option<DueDateRuleDefinition>,
  taskTemplates: TaskTemplate[],
  dateWidgets: FormFieldWidget[],
) {
  if (rule) {
    let label = '';
    const offset = rule.dueOffset;

    label += getOffsetLabel(offset.years, 'year ', 'years ');
    label += getOffsetLabel(offset.months, 'month ', 'months ');
    label += getOffsetLabel(offset.days, 'day ', 'days ');
    label += getOffsetLabel(offset.hours, 'hour ', 'hours ');
    label += getOffsetLabel(offset.minutes, 'minute ', 'minutes ');

    if (rule.offsetDirection === DueDateRuleOffsetDirection.After) {
      label += 'after ';
    } else {
      label += 'before ';
    }

    label += getLabelByRule(rule, taskTemplates, dateWidgets);

    return label;
  } else {
    return '';
  }
}

function getChecklistDueDateLabel(
  checklistRule: Option<DueDateRuleDefinition>,
  checklist: Checklist,
  taskTemplates: TaskTemplate[],
  dateWidgets: FormFieldWidget[],
  timeZone: string,
): string {
  const hasRule = Boolean(checklistRule);
  const hasDueDate = Boolean(checklist.dueDate);
  const isDueDateOverridden = checklist.dueDateOverridden;

  return match({ hasRule, hasDueDate, isDueDateOverridden })
    .with({ hasRule: true, hasDueDate: false, isDueDateOverridden: false }, () =>
      getModalLabelByRule(checklistRule, taskTemplates, dateWidgets),
    )
    .with({ hasRule: true, hasDueDate: true, isDueDateOverridden: false }, () =>
      DateUtils.formatActivityTime({ date: checklist.dueDate!, timeZone }),
    )
    .with({ hasRule: false }, () =>
      checklist.dueDate ? DateUtils.formatActivityTime({ date: checklist.dueDate, timeZone }) : 'Set due date',
    )
    .with({ hasRule: true, isDueDateOverridden: true }, () =>
      checklist.dueDate ? DateUtils.formatActivityTime({ date: checklist.dueDate, timeZone }) : 'Set due date',
    )
    .otherwise(() => '');
}

export const DynamicDueDateLabelService = {
  getChecklistDueDateLabel,
  getDateWidgetByGroupId,
  getFullLabelByRule,
  getLabelByRule,
  getModalLabelByRule,
  getOffsetLabel,
  getTaskByGroupId,
};
