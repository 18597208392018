import mime from 'mime';

function getMimeType(fileName: string): string {
  const mimeTypeFromName = mime.getType(fileName);
  return mimeTypeFromName ? mimeTypeFromName : 'application/octet-stream';
}

export const MimeUtils = {
  getMimeType,
};
