import {
  OrderTreeUtils,
  TaskTemplateTaskType,
  TaskTemplateUpdateResponseStatus,
  WidgetType,
} from '@process-street/subgrade/process';
import { ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';
import angular from 'angular';
import { isFailedBulkTemplateTaskAssignmentResponse } from 'components/template-task-assignment/store/template-task-assignment.reducer';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { ApprovalRuleSelector } from 'components/approval-rules/store/approval-rules.selectors';
import templateUrl from './task-template-list.component.html';
import './task-template-list.scss';
import { TemplateConstants } from 'services/template-constants';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import groupBy from 'lodash/groupBy';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ArrayService } from 'services/array-service';
import ResponsiveBootstrapToolkit from 'responsive-toolkit';
import { SentryService } from 'components/sentry/sentry.service';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { AiGeneratorAnimationService } from 'services/ai-generator-animation-service';
import { ablyService } from 'app/pusher/ably.service';
import { AblyEvent } from 'app/pusher/ably-event';
import { createDuplicateTaskTemplatePlaceholder } from './create-duplicate-task-template-placeholder';
import { queryClient } from 'components/react-root';
import { TaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { MuidUtils } from '@process-street/subgrade/core';
import { TaskListEvent } from 'directives/task-list/task-list-event';

angular.module('frontStreetApp.directives').component('psTaskTemplateList', {
  bindings: {
    editable: '<',

    templateRevision: '<',
    singleTaskTemplateGroupId: '<',
    widgetsMap: '<',
    userCanManageTasks: '<',

    onTaskTemplatesLoaded: '&',
    onSelectTaskTemplate: '&',
    onSingleTaskTemplateSet: '&',
    onTaskTemplateNameChanged: '&',

    onInitWidgets: '&',
    onCleanUpWidgets: '&',
    onGetDuplicatedWidgets: '&',

    onToggleWidgetsVisibility: '&',
    onSetWidgetsVisibility: '&',
  },
  templateUrl,
  controller(
    $anchorScroll,
    $animate,
    $ngRedux,
    $rootScope,
    $scope,
    $timeout,
    $q,
    $window,
    DynamicDueDateActions,
    focusById,
    MessageBox,
    OrderTreeBulkUpdater,
    RuleEvent,
    RuleService,
    SessionService,
    TaskTemplateListMenuEvent,
    TaskListService,
    TaskPermissionRuleActions,
    TaskTemplateActions,
    TaskTemplateService,
    TaskTemplateListService,
    TaskTemplatePermitActions,
    util,
    WidgetActions,
    WidgetService,
    FeatureFlagService,
    ToastService,
  ) {
    const logger = trace({ name: 'psTaskTemplateList' });
    logger.info('loading ctrl');

    $scope.taskGenerationStatuses = {};
    $scope.aiGenerationStatus = 'idle';
    $scope.taskCreationQueue = [];

    const ctrl = this;
    const orderTreesBulkUpdater = new OrderTreeBulkUpdater(TaskTemplateService.updateOrderTrees, {
      deferPredicate: TaskTemplateListService.checkIfAllTaskTemplatesCreated,
      onSuccess: TaskTemplateListService.onOrderTreesBulkUpdateSuccess,
      onFailure: TaskTemplateListService.onOrderTreesBulkUpdateFailure,
    });

    ctrl.mapStateToThis = (templateRevisionId, activeTaskTemplateGroupId) =>
      createSelector(
        [
          ApprovalRuleSelector.getAllByTaskTemplateGroupIds(templateRevisionId, [activeTaskTemplateGroupId]),
          WidgetSelector.getAllByTemplateRevisionId(templateRevisionId),
        ],
        (approvalSubjectRules, widgets) => {
          return {
            approvalSubjectTaskTemplateGroupIds: approvalSubjectRules.map(asr => asr.subjectTaskTemplateGroupId),
            widgetsByTaskTemplateId: groupBy(widgets, w => w.header.taskTemplate.id),
          };
        },
      );

    const bindActionsToCtrl = {
      removeDddRulesForTaskTemplateByIds: DynamicDueDateActions.removeForTaskTemplateByIds,
      getAllDddRulesByTemplateRevisionId: DynamicDueDateActions.getAllByTemplateRevisionId,
      getAllWidgetsByTemplateRevisionId: WidgetActions.getAllByTemplateRevisionId,
      getAllTaskTemplatesByTemplateRevisionId: TaskTemplateActions.getAllByTemplateRevisionId,
      getAllTaskTemplatePermitsByTemplateRevisionId: TaskTemplatePermitActions.getAllByTemplateRevisionId,
      getAllTaskPermissionRulesByTemplateRevisionId: TaskPermissionRuleActions.getAllByTemplateRevisionId,
    };

    const mapDispatchToProps = dispatch => ({
      actions: bindActionCreators(bindActionsToCtrl, dispatch),
    });

    ctrl.connectToRedux = () => {
      if (ctrl.unsubscribe) {
        ctrl.unsubscribe();
      }
      const stateRetrievable = !!ctrl.templateRevision && !!ctrl.singleTaskTemplateGroupId;
      ctrl.unsubscribe = $ngRedux.connect(
        stateRetrievable ? ctrl.mapStateToThis(ctrl.templateRevision.id, ctrl.singleTaskTemplateGroupId) : null,
        mapDispatchToProps,
      )(ctrl);
    };

    ctrl.$onInit = () => {
      ctrl.listenerUnsubscribers = [
        $rootScope.$on(TaskListEvent.AI_GENERATED_TASK_TEMPLATES, () => {
          $scope.aiGenerationStatus = 'loading';
          initialize({ isAiTaskGenerationSuccess: true });
        }),
        $rootScope.$on(TaskListEvent.AI_WIDGET_GENERATION_FOR_TASK_STARTED, (_, taskTemplate) => {
          $scope.taskGenerationStatuses[taskTemplate.group.id] = 'animating';
          animateTaskSwitch(taskTemplate);
        }),
        $rootScope.$on(TaskListEvent.AI_WIDGET_GENERATION_FOR_TASK_ANIMATION_DONE, (_, taskTemplate) => {
          $scope.taskGenerationStatuses[taskTemplate.group.id] = 'done';
          animateTaskSwitch(taskTemplate);
        }),
      ];
    };

    ctrl.$onDestroy = () => {
      ctrl.unsubscribe();

      ctrl.listenerUnsubscribers?.forEach(unsub => unsub());
      ctrl.unsubscribeFromTaskTemplatesUpdates?.();
    };

    ctrl.$onChanges = function (changes) {
      const { singleTaskTemplateGroupId, templateRevision } = changes;
      ctrl.connectToRedux();
      if (
        singleTaskTemplateGroupId &&
        singleTaskTemplateGroupId.currentValue &&
        ctrl.taskTemplates &&
        !templateRevision
      ) {
        ctrl.singleTaskTemplateGroupId = singleTaskTemplateGroupId.currentValue;
        setSingleTaskTemplateByGroupId(ctrl.singleTaskTemplateGroupId);
        resolveInitialTaskTemplate();
      } else if (templateRevision && templateRevision.currentValue) {
        initialize();
        subscribeToTaskTemplatesUpdates(templateRevision.currentValue);
      }
    };

    ctrl.selectedTaskTemplates = [];
    ctrl.selectedTaskTemplateMap = {};
    ctrl.taskTemplateMap = {};
    ctrl.assigneesMap = {};

    ctrl.taskTemplateInViewMap = {};

    ctrl.isItemRenderable = taskTemplate =>
      ctrl.taskTemplateInViewMap[taskTemplate?.group.id] || ctrl.isSingleSelected(taskTemplate);

    ctrl.animateTasksCreationPromise = null;

    async function initialize(options = { isAiTaskGenerationSuccess: false, isTaskTemplatesUpdated: false }) {
      // Before we do anything, let's wait for the tasks creation to finish if it was started
      if (ctrl.animateTasksCreationPromise) {
        logger.info('Waiting for tasks creation animation to finish before initializing...');
        await ctrl.animateTasksCreationPromise;
        logger.info('Tasks creation animation finished, initializing...');
      }

      initializeSortable();

      $q.all({
        taskTemplates: TaskTemplateService.getTaskTemplates(ctrl.templateRevision.id, {
          flushCache: options.isAiTaskGenerationSuccess || options.isTaskTemplatesUpdated,
        }),
        assignments: TaskTemplateListService.initializeTemplateAssignments(ctrl.templateRevision.id, ctrl.assigneesMap),
        rules: RuleService.getAllByTemplateRevisionId(ctrl.templateRevision.id),
      }).then(({ taskTemplates, rules }) => {
        ctrl.taskTemplates = taskTemplates;

        ctrl.taskTemplateHasAssociatedRule = ConditionalLogicCommonUtils.makeTaskTemplateHasAssociatedRule(rules);

        ctrl.actions
          .getAllWidgetsByTemplateRevisionId(ctrl.templateRevision.id, {
            flushCache: options.isAiTaskGenerationSuccess,
          })
          .then(actionResult => actionResult.payload)
          .then(widgets => {
            if (options.isAiTaskGenerationSuccess) {
              $rootScope.$broadcast(TaskListEvent.AI_WIDGET_GENERATION_FOR_TASK_DONE, widgets);
            }
          });

        // task permits/permission rules
        ctrl.actions.getAllTaskTemplatePermitsByTemplateRevisionId(ctrl.templateRevision.id);
        ctrl.actions.getAllTaskPermissionRulesByTemplateRevisionId(ctrl.templateRevision.id);

        ctrl.taskTemplateMap = TaskTemplateListService.getTaskTemplateMap(ctrl.taskTemplates);
        TaskTemplateListService.initializeAssigneesMap(ctrl.taskTemplates, ctrl.assigneesMap);

        ctrl.taskTemplates.forEach(taskTemplate => {
          ctrl.onInitWidgets({ taskTemplateGroupId: taskTemplate.group.id });
        });

        resolveInitialTaskTemplate();

        if (options.isAiTaskGenerationSuccess) {
          ctrl.animateTasksCreationPromise = animateTasksCreation(taskTemplates);
        }

        // Giving a bit of time to render, otherwise list items can jump a bit
        $timeout(() => {
          ctrl.onTaskTemplatesLoaded({ taskTemplates: ctrl.taskTemplates });
        });
      });

      ctrl.actions.getAllDddRulesByTemplateRevisionId(ctrl.templateRevision.id, {
        flushCache: options.isAiTaskGenerationSuccess || options.isTaskTemplatesUpdated,
      });
    }

    ctrl.taskHasConditionalLogic = taskTemplate => {
      const widgets = ctrl.widgetsByTaskTemplateId?.[taskTemplate.id] ?? [];
      return ctrl.taskTemplateHasAssociatedRule({ taskTemplate, widgets });
    };

    function resolveInitialTaskTemplate() {
      // Initial task should be resolved after assignments are loaded
      const templateId = ctrl.templateRevision.template.id;
      const initialTaskTemplate = TaskTemplateListService.getInitialSelected(
        ctrl.singleTaskTemplateGroupId,
        templateId,
        ctrl.taskTemplates,
      );

      if (!initialTaskTemplate) {
        hideWidgets();
      } else {
        ctrl.selectTaskTemplate(SELECT_TYPE.SINGLE, initialTaskTemplate);

        // This is necessary because the $stateChangeSuccess event will already have fired,
        // but since there were no $scope.taskTemplate, it couldn't task it
        setSingleTaskTemplateByGroupId(initialTaskTemplate.group.id);
      }
    }

    function initializeSortable() {
      ctrl.taskTemplateSortableOptions = {
        start(event) {
          if (event.ctrlKey || event.metaKey || event.shiftKey) {
            ctrl.taskTemplateSortableOptions.disabled = true;
          } else {
            ctrl.taskTemplateDragging = true;
          }
        },
        stop() {
          ctrl.taskTemplateDragging = false;
        },
        update(__event, ui) {
          const indexes = [ui.item.sortable.index];
          const newIndexes = [ui.item.sortable.dropindex];

          orderTreesBulkUpdater.moveAt(indexes, newIndexes, ctrl.taskTemplates).then(updatedTaskTemplates => {
            // Update react query cached data after moving tasks.
            queryClient.setQueryData(
              TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId: ctrl.templateRevision.id }),
              cachedTaskTemplates => {
                const taskTemplates = cachedTaskTemplates?.map(cachedTaskTemplate => {
                  const updatedTask = updatedTaskTemplates.find(t => t.id === cachedTaskTemplate.id);
                  return updatedTask ? updatedTask.taskTemplate : cachedTaskTemplate;
                });
                return taskTemplates.sort((a, b) => OrderTreeUtils.compare(a.orderTree, b.orderTree));
              },
            );
          });

          $timeout(() => {
            // Refreshing position details of single selected task
            // the timeout used to make it work with ui-sortable
            fireOnSingleTaskTemplateSetCallback();
          });
        },
        disabled: !ctrl.editable,
        handle: 'ps-task-template-list-item > .task-list-item > .step-number-container',
        revert: true,
        scrollSensitivity: 70,
        tolerance: 'pointer',
        axis: 'y',
        opacity: 0.8,
      };

      ctrl.sortingDisabled = true;
      ctrl.disableUISortable = function (disabled) {
        ctrl.sortingDisabled = !!disabled;
        ctrl.taskTemplateSortableOptions.disabled = !!disabled;
      };

      ctrl.mouseMove = function (event) {
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
          ctrl.disableUISortable(true /* disabled */);
        } else {
          ctrl.refreshUISortableDisabledStatus();
        }
      };

      ctrl.refreshUISortableDisabledStatus = function () {
        ctrl.disableUISortable(!ctrl.isItemsEditable() /* disabled */);
      };
    }

    const showMultiSelectMenu = function () {
      ctrl.multiSelection = true;
      ctrl.refreshUISortableDisabledStatus();

      // Getting due offset if it's the same
      let [{ dueOffset }] = ctrl.selectedTaskTemplates;
      const allHaveSameDueOffset = ctrl.selectedTaskTemplates.every(tt =>
        TaskTemplateService.checkIfDueOffsetEqual(tt.dueOffset, dueOffset),
      );
      dueOffset = (allHaveSameDueOffset && dueOffset) || undefined;
      // Getting whether all task templates doesn't have stop
      const onlyAdd = ctrl.selectedTaskTemplates.every(taskTemplate => taskTemplate.stop !== true);

      $rootScope.$broadcast(
        TaskListEvent.SHOW_MULTI_SELECT_MENU,
        ctrl.selectedTaskTemplates.length,
        dueOffset,
        onlyAdd,
        ctrl.selectedTaskTemplates,
        ctrl.templateRevision,
      );
    };

    const hideMultiSelectMenu = function () {
      const [loneTaskTemplate] = ctrl.selectedTaskTemplates;
      ctrl.onSelectTaskTemplate({ taskTemplateGroupId: loneTaskTemplate.group.id, replace: true });
      ctrl.multiSelection = false;
      ctrl.refreshUISortableDisabledStatus();
      $rootScope.$broadcast(TaskListEvent.HIDE_MULTI_SELECT_MENU);
    };

    const SELECT_TYPE = {
      SINGLE: 'single',
      CTRL: 'ctrl',
      SHIFT: 'shift',
    };

    /**
     * Selects the task template if the event has not been prevented.
     *
     * @param event
     * @param taskTemplate
     */
    ctrl.clickTaskTemplate = function (event, taskTemplate) {
      if (!event.originalEvent.fromAssignmentClick) {
        event.stopPropagation();
        ctrl.disableUISortable(true);
        let selectType = SELECT_TYPE.SINGLE;
        if (ctrl.editable) {
          if (event.ctrlKey || event.metaKey) {
            selectType = SELECT_TYPE.CTRL;
          } else if (event.shiftKey) {
            $window.document.getSelection().removeAllRanges();
            selectType = SELECT_TYPE.SHIFT;
          }
        }
        ctrl.selectTaskTemplate(selectType, taskTemplate);
      }
    };

    ctrl.isTaskTemplateSelected = function (taskTemplate) {
      return TaskTemplateListService.isTaskTemplateSelected(taskTemplate, ctrl.selectedTaskTemplateMap);
    };

    ctrl.addTaskTemplateToSelected = function (taskTemplate) {
      return TaskTemplateListService.addTaskTemplateToSelected(
        taskTemplate,
        ctrl.selectedTaskTemplates,
        ctrl.selectedTaskTemplateMap,
      );
    };

    ctrl.removeTaskTemplateFromSelected = function (taskTemplate) {
      return TaskTemplateListService.removeTaskTemplateFromSelected(
        taskTemplate,
        ctrl.selectedTaskTemplates,
        ctrl.selectedTaskTemplateMap,
      );
    };

    ctrl.selectTaskTemplate = function (selectType, taskTemplate) {
      // don't select multiple on small screen. https://processstreet.atlassian.net/browse/PS-5027
      let normalizedSelectType = selectType;
      if (ResponsiveBootstrapToolkit.is('<=sm')) {
        normalizedSelectType = SELECT_TYPE.SINGLE;
      }

      if (!taskTemplate) {
        return; // Ignore, if already selected
      }
      if (ctrl.taskTemplateDragging) {
        return; // Ignore, if dragging
      }

      switch (normalizedSelectType) {
        case SELECT_TYPE.SINGLE:
          ctrl.selectedTaskTemplateMap = {};
          ctrl.selectedTaskTemplates = [];
          ctrl.addTaskTemplateToSelected(taskTemplate);
          break;
        case SELECT_TYPE.CTRL:
          if (ctrl.isTaskTemplateSelected(taskTemplate) && ctrl.selectedTaskTemplates.length !== 1) {
            ctrl.removeTaskTemplateFromSelected(taskTemplate);
          } else {
            ctrl.addTaskTemplateToSelected(taskTemplate);
          }
          break;
        case SELECT_TYPE.SHIFT: {
          const index = ctrl.taskTemplates.indexOf(taskTemplate);
          const lastSelectedTaskTemplate = ctrl.selectedTaskTemplates[ctrl.selectedTaskTemplates.length - 1];
          // If no last index present, use index
          const lastSelectedIndex = ctrl.taskTemplates.indexOf(lastSelectedTaskTemplate);
          let fromIndex = !angular.isUndefined(lastSelectedIndex) ? lastSelectedIndex : index;
          let toIndex = index;
          if (lastSelectedIndex > index) {
            fromIndex = index;
            toIndex = lastSelectedIndex;
          }
          // From and to indexes inclusive, to make sure that both sides are marked as selected
          for (let i = fromIndex; i <= toIndex; i += 1) {
            ctrl.addTaskTemplateToSelected(ctrl.taskTemplates[i]);
          }
          break;
        }
        default:
          logger.info('Unexpected select type');
      }

      if (ctrl.selectedTaskTemplates.length === 1) {
        hideMultiSelectMenu();
      } else {
        showMultiSelectMenu();
      }
    };

    // Handle key events

    const KEY_UP = 38;
    const KEY_DOWN = 40;

    ctrl.handleTaskTemplateKeydown = function (event, taskTemplate) {
      // This doesn't work well on mobile
      if (util.isMobile()) {
        return;
      }

      switch (event.keyCode) {
        case KEY_UP:
          selectTaskTemplateAbove(taskTemplate);
          hideMultiSelectMenu();
          event.preventDefault();
          break;
        case KEY_DOWN:
          selectTaskTemplateBelow(taskTemplate);
          hideMultiSelectMenu();
          event.preventDefault();
          break;
        default: // We don't care about other keys
      }
    };

    /**
     * Makes a task template (taskTemplate) above <b>taskTemplate</b> to be selected.
     * Looks for a taskTemplate that is not in <b>_deleting</b> state.
     *
     * @param taskTemplate will be used as a lower bound and it cannot be selected
     * @returns {boolean} <b>true</b> if a task above was selected (exists and not in deleting state),
     *                    <b>false</b> - otherwise
     */
    function selectTaskTemplateAbove(taskTemplate) {
      const taskTemplateToSelect = TaskListService.getTaskTemplateAbove(taskTemplate, ctrl.taskTemplates);

      if (taskTemplateToSelect.group.id !== taskTemplate.group.id) {
        ctrl.selectTaskTemplate(SELECT_TYPE.SINGLE, taskTemplateToSelect);
        return true;
      } else {
        return false;
      }
    }

    /**
     * Makes a task template (taskTemplate) below <b>taskTemplate</b> to be selected.
     * Looks for a taskTemplate that is not in <b>_deleting</b> state.
     *
     * @param taskTemplate will be used as an upper bound and it cannot be selected
     * @returns {boolean} <b>true</b> if a task below was selected (exists and not in deleting state),
     *                    <b>false</b> - otherwise
     */
    function selectTaskTemplateBelow(taskTemplate) {
      const taskTemplateToSelect = TaskListService.getTaskTemplateBelow(taskTemplate, ctrl.taskTemplates);

      if (taskTemplateToSelect.group.id !== taskTemplate.group.id) {
        ctrl.selectTaskTemplate(SELECT_TYPE.SINGLE, taskTemplateToSelect);
        return true;
      } else {
        return false;
      }
    }

    ctrl.getTaskTemplateClasses = function (taskTemplate) {
      const selected = ctrl.isTaskTemplateSelected(taskTemplate);
      const approvalSubject =
        ctrl.approvalSubjectTaskTemplateGroupIds &&
        ctrl.approvalSubjectTaskTemplateGroupIds.includes(taskTemplate.group.id);

      return TaskTemplateListService.getTaskTemplateClasses(
        taskTemplate,
        selected,
        approvalSubject,
        $scope.taskGenerationStatuses?.[taskTemplate.group?.id] === 'animating',
      );
    };

    ctrl.isHeading = TaskTemplateService.isHeading;

    ctrl.getSingleSelected = function () {
      return ctrl.selectedTaskTemplates.length === 1 ? ctrl.selectedTaskTemplates[0] : undefined;
    };

    ctrl.isSingleSelected = function (taskTemplate) {
      return TaskTemplateService.hasSameGroupId(taskTemplate, ctrl.getSingleSelected());
    };

    ctrl.isItemsEditable = function () {
      return ctrl.editable && !ctrl.multiSelection;
    };

    function fireOnSingleTaskTemplateSetCallback() {
      const singleTaskTemplate = ctrl.getSingleSelected();
      if (singleTaskTemplate) {
        ctrl.onSingleTaskTemplateSet({
          taskTemplate: singleTaskTemplate,
          assignees: ctrl.assigneesMap[singleTaskTemplate.id],
          canMoveUp: TaskTemplateListService.canMoveTaskTemplateUp(singleTaskTemplate, ctrl.taskTemplates),
          canMoveDown: TaskTemplateListService.canMoveTaskTemplateDown(singleTaskTemplate, ctrl.taskTemplates),
        });
      }
    }

    function setSingleTaskTemplateByGroupId(taskTemplateGroupId) {
      const singleTaskTemplate = ctrl.taskTemplates && ctrl.taskTemplateMap[taskTemplateGroupId];

      if (singleTaskTemplate) {
        if (!ctrl.widgetsVisible || ResponsiveBootstrapToolkit.is('<=sm')) {
          showWidgets();
        }

        // We auto-focus on the template name if it hasn't been changed from the default
        const nameIsDefault = ctrl.templateRevision.template.name === TemplateConstants.BLANK_WORKFLOW_NAME;
        const shouldFocusOnTemplateName = nameIsDefault && !ctrl.taskTemplateFocused && ctrl.editable;
        if (!shouldFocusOnTemplateName) {
          TaskTemplateListService.setTaskTemplateInputFocus(singleTaskTemplate);
        }

        TaskTemplateListService.rememberSingleSelectedTaskTemplate(ctrl.templateRevision, singleTaskTemplate);

        // Go to top
        $anchorScroll('widgets-top');

        // Refreshing position details of single task
        fireOnSingleTaskTemplateSetCallback();
      } else {
        logger.error("There's no task template with id: %s", taskTemplateGroupId);
      }
    }

    ctrl.taskTemplateNameChanged = function (taskTemplate, name) {
      ctrl.onTaskTemplateNameChanged({ taskTemplate, name });
    };

    ctrl.createTaskTemplateAt = function ({ index, name, taskType = TaskTemplateTaskType.Standard }) {
      const newTaskTemplate = TaskTemplateListService.generateTaskTemplate(
        name,
        ctrl.templateRevision.id,
        taskType,
        index /* atIndex */,
        ctrl.taskTemplates,
      );

      ctrl._createTaskTemplateAt(index, newTaskTemplate);
    };

    ctrl._createTaskTemplateAt = function (index, newTaskTemplate) {
      hideMultiSelectMenu();

      ctrl.selectTaskTemplate(SELECT_TYPE.SINGLE, newTaskTemplate);

      ctrl.onInitWidgets({ taskTemplateGroupId: newTaskTemplate.group.id });

      return $q((resolve, reject) =>
        TaskTemplateListService.createTaskTemplate(
          newTaskTemplate,
          ctrl.taskTemplates,
          ctrl.taskTemplateMap,
          ctrl.assigneesMap,
        )
          .then(taskTemplate => ctrl._createApprovalNote(taskTemplate).then(() => $q.resolve(taskTemplate)))
          .catch(() => {
            ctrl.selectTaskTemplate(SELECT_TYPE.SINGLE, ctrl.taskTemplates[index]);
            ctrl.onCleanUpWidgets({ taskTemplateGroupId: newTaskTemplate.group.id });
            reject();
          }),
      );
    };

    ctrl._createApprovalNote = taskTemplate => {
      if (
        !FeatureFlagService.getFeatureFlags().approvalNotes ||
        taskTemplate.taskType !== TaskTemplateTaskType.Approval
      )
        return $q.resolve();

      const widget = {
        header: {
          id: MuidUtils.randomMuid(),
          taskTemplate,
          type: WidgetType.Text,
          orderTree: '1', // first and only widget in the Approval task
        },
      };
      return WidgetService.create(widget, { taskTemplate });
    };

    let focusTaskTemplateTimeout;
    ctrl.focusedTaskTemplate = function (taskTemplate) {
      ctrl.focalTaskTemplate = taskTemplate;
      ctrl.taskTemplateFocused = true;
      $timeout.cancel(focusTaskTemplateTimeout);

      focusTaskTemplateTimeout = $timeout(() => {
        // If multi select mode on, the focus should be disabled
        if (
          !ctrl.multiSelection &&
          taskTemplate &&
          ctrl.focalTaskTemplate &&
          taskTemplate.group.id === ctrl.focalTaskTemplate.group.id
        ) {
          delete ctrl.focalTaskTemplate;
          ctrl.selectTaskTemplate(SELECT_TYPE.SINGLE, taskTemplate);
        }
      }, 200);
    };

    ctrl.updateTaskTemplate = function (taskTemplate) {
      if (ctrl.taskTemplates.indexOf(taskTemplate) !== -1) {
        TaskTemplateListService.updateTaskTemplate(taskTemplate).then(
          () => {
            taskTemplate._updateFailed = false;
          },
          response => {
            taskTemplate._updateFailed = true;

            // TODO how to roll back? Before it was 'taskTemplate.name = task.name;'

            TaskTemplateService.sortTaskTemplates(ctrl.taskTemplates);

            ToastService.openToast({
              status: 'error',
              title: `We're having problems updating the task`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });

            SentryService.captureMessageWithMeta(response.statusText, {
              url: response.config.url,
              message: response.data.message,
              status: response.status,
              method: response.config.method,
              taskTemplate,
            });
          },
        );
      }
    };

    ctrl.deleteTaskTemplate = function (taskTemplate) {
      deleteTaskTemplates([taskTemplate]);
    };

    let deleteTaskTemplateShown = false;
    const deleteTaskTemplates = function (taskTemplatesToDelete = []) {
      const taskTemplatesToDeleteIsEmpty = taskTemplatesToDelete.length === 0;
      const allTaskTemplatesWillBeDeleted =
        taskTemplatesToDelete.length >= ctrl.taskTemplates.filter(tt => !tt._deleting).length;

      if (deleteTaskTemplateShown || taskTemplatesToDeleteIsEmpty || allTaskTemplatesWillBeDeleted) {
        return;
      }

      const needsConfirmation = taskTemplatesToDelete.some(tt => {
        const widgets = ctrl.widgetsMap[tt.group.id] || [];
        return (tt.name && tt.name.length) || widgets.length;
      });
      if (needsConfirmation) {
        deleteTaskTemplateShown = true;
        MessageBox.confirm({
          title: 'Delete selected tasks?',
          message: 'These tasks and all of their content will be deleted and irrecoverable!',
          okButton: {
            type: 'danger',
            text: 'Delete',
            action: startDeleteTaskTemplates.bind(
              null,
              ctrl.taskTemplates,
              taskTemplatesToDelete,
              ctrl.taskTemplateMap,
            ),
          },
        }).result.finally(() => {
          deleteTaskTemplateShown = false;
        });
      } else {
        startDeleteTaskTemplates(ctrl.taskTemplates, taskTemplatesToDelete, ctrl.taskTemplateMap);
      }
    };

    function startDeleteTaskTemplates(taskTemplates, taskTemplatesToDelete, taskTemplateMap) {
      taskTemplatesToDelete.forEach(taskTemplate => {
        taskTemplate._deleting = true;
      });

      if (!selectTaskTemplateAbove(taskTemplatesToDelete[0])) {
        // If there is no task above, select the one below
        selectTaskTemplateBelow(taskTemplatesToDelete[taskTemplatesToDelete.length - 1]);
      }

      startDeleteAfterAllTaskTemplatesCreated(taskTemplates, taskTemplatesToDelete, taskTemplateMap);
    }

    function startDeleteAfterAllTaskTemplatesCreated(taskTemplates, taskTemplatesToDelete, taskTemplateMap) {
      const taskTemplateInCreation = taskTemplatesToDelete.find(tt => tt._creating === true);

      if (taskTemplateInCreation) {
        // If a task template in creation is found, then we wait until it's created
        // and running this check continuously to make sure
        // that there no more task templates in creation

        const deferred = $q.defer();
        taskTemplateInCreation._onCreated = function () {
          util.pipe(
            deferred,
            startDeleteAfterAllTaskTemplatesCreated(taskTemplates, taskTemplatesToDelete, taskTemplateMap),
          );
        };
        return deferred.promise;
      } else {
        return doDeleteTaskTemplates(taskTemplates, taskTemplatesToDelete, taskTemplateMap);
      }
    }

    function doDeleteTaskTemplates(taskTemplates, taskTemplatesToDelete, taskTemplateMap) {
      return TaskTemplateService.deleteAll(taskTemplatesToDelete).then(
        bulkUpdateResponses => {
          hideMultiSelectMenu();

          let atLeastOneFailed = false;
          bulkUpdateResponses.forEach(res => {
            if (res.taskTemplate) {
              const taskTemplate = taskTemplateMap[res.taskTemplate.group.id];
              if (res.response === TaskTemplateUpdateResponseStatus.Ok) {
                ArrayService.desplice(taskTemplates, taskTemplate);
                delete taskTemplateMap[taskTemplate.group.id];
                ctrl.onCleanUpWidgets({ taskTemplateGroupId: taskTemplate.group.id });
              } else {
                atLeastOneFailed = true;
                taskTemplate._deleteFailed = true;
              }
            }
          });

          if (atLeastOneFailed) {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems deleting the tasks`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          } else {
            hideMultiSelectMenu();
          }

          // If there is no tasks left
          if (!taskTemplates || taskTemplates.length === 0) {
            setSingleTaskTemplateByGroupId(null);
            hideWidgets();
          }

          return bulkUpdateResponses;
        },
        () => {
          taskTemplatesToDelete.forEach(taskTemplate => {
            delete taskTemplateMap[taskTemplate.group.id]._deleting;
          });
        },
      );
    }

    function duplicateTaskTemplate(sourceTaskTemplate, taskTemplates, taskTemplateMap) {
      hideMultiSelectMenu();

      const index = sourceTaskTemplate ? taskTemplates.indexOf(sourceTaskTemplate) : taskTemplates.length - 1;

      const placeholderTaskTemplate = createDuplicateTaskTemplatePlaceholder({
        sourceTaskTemplate,
        taskTemplates,
        index,
      });

      ctrl.selectTaskTemplate(SELECT_TYPE.SINGLE, placeholderTaskTemplate);

      ctrl.onInitWidgets({ taskTemplateGroupId: placeholderTaskTemplate.group.id });

      $rootScope.$broadcast(TaskListEvent.DUPLICATE_WIDGETS_STARTED);

      return TaskTemplateListService.duplicateTaskTemplate(
        sourceTaskTemplate,
        placeholderTaskTemplate,
        taskTemplates,
        taskTemplateMap,
        ctrl.assigneesMap,
      )
        .then(
          duplicatedTaskTemplate => {
            ctrl.onGetDuplicatedWidgets({ duplicatedTaskTemplate, newTaskTemplate: placeholderTaskTemplate });
          },
          response => {
            ctrl.selectTaskTemplate(SELECT_TYPE.SINGLE, taskTemplates[index]);

            ctrl.onCleanUpWidgets({ taskTemplateGroupId: placeholderTaskTemplate.group.id });

            return $q.reject(response);
          },
        )
        .finally(() => {
          $rootScope.$broadcast(TaskListEvent.DUPLICATE_WIDGETS_FINISHED);
        });
    }

    // Widgets

    ctrl.toggleWidgetsVisibility = function () {
      // preventing toggle if multiple are selected. https://processstreet.atlassian.net/browse/PS-5027
      if (ctrl.selectedTaskTemplates <= 1) {
        ctrl.onToggleWidgetsVisibility();
      }
    };

    function showWidgets(instantly) {
      ctrl.onSetWidgetsVisibility({ visible: true, instantly });
      ctrl.widgetsVisible = true;
    }

    function hideWidgets() {
      ctrl.onSetWidgetsVisibility({ visible: false });
      ctrl.widgetsVisible = false;
    }

    // Events

    // task events
    $scope.$on(EventName.TASK_TEMPLATE_UPDATE_OK, (__event, taskTemplate, deletedRulesIds) => {
      // TODO check this might be handled natively by reducer
      ctrl.actions.removeDddRulesForTaskTemplateByIds(taskTemplate.id, deletedRulesIds);
    });

    $scope.$on(TaskTemplateListMenuEvent.CREATE_REQUEST_FROM_MENU, (__event, taskTemplate, { name, taskType } = {}) => {
      const index = taskTemplate ? ctrl.taskTemplates.indexOf(taskTemplate) : ctrl.taskTemplates.length - 1;
      ctrl.createTaskTemplateAt({ index, name, taskType });
    });

    $scope.$on(TaskTemplateListMenuEvent.DELETE_REQUEST_FROM_MENU, (__event, taskTemplate) => {
      ctrl.deleteTaskTemplate(taskTemplate);
    });

    $scope.$on(TaskListEvent.BULK_DELETE_REQUEST_FROM_MENU, () => {
      deleteTaskTemplates(ctrl.selectedTaskTemplates);
    });

    $scope.$on(TaskTemplateListMenuEvent.DUPLICATE_REQUEST_FROM_MENU, (__event, taskTemplate) => {
      duplicateTaskTemplate(taskTemplate, ctrl.taskTemplates, ctrl.taskTemplateMap);
    });

    $scope.$on(TaskTemplateListMenuEvent.MOVE_UP_REQUEST_FROM_MENU, (__event, taskTemplate) => {
      TaskTemplateListService.moveAllTaskTemplatesUp([taskTemplate], ctrl.taskTemplates, orderTreesBulkUpdater);
      // Refreshing position details of single selected task
      fireOnSingleTaskTemplateSetCallback();
    });

    $scope.$on(TaskTemplateListMenuEvent.MOVE_DOWN_REQUEST_FROM_MENU, (__event, taskTemplate) => {
      TaskTemplateListService.moveAllTaskTemplatesDown([taskTemplate], ctrl.taskTemplates, orderTreesBulkUpdater);
      // Refreshing position details of single selected task
      fireOnSingleTaskTemplateSetCallback();
    });

    $scope.$on(TaskListEvent.SELECT_TASK_BELOW_REQUEST, (__event, taskTemplate) => {
      selectTaskTemplateBelow(taskTemplate);
    });

    $scope.$on(TaskListEvent.GETTING_DUPLICATED_WIDGETS_FINISHED, (__event, newTaskTemplate) => {
      delete newTaskTemplate._duplicating;
    });

    $scope.$on(TaskListEvent.BULK_MOVE_UP_REQUEST_FROM_MENU, () => {
      TaskTemplateListService.moveAllTaskTemplatesUp(
        ctrl.selectedTaskTemplates,
        ctrl.taskTemplates,
        orderTreesBulkUpdater,
      );
      // Refreshing position details of single selected task
      fireOnSingleTaskTemplateSetCallback();
    });

    $scope.$on(TaskListEvent.BULK_MOVE_DOWN_REQUEST_FROM_MENU, () => {
      TaskTemplateListService.moveAllTaskTemplatesDown(
        ctrl.selectedTaskTemplates,
        ctrl.taskTemplates,
        orderTreesBulkUpdater,
      );
      // Refreshing position details of single selected task
      fireOnSingleTaskTemplateSetCallback();
    });

    $scope.$on(TaskListEvent.BULK_SET_DUE_OFFSET, (__event, dueOffset) => {
      TaskTemplateListService.updateAllDueOffset(dueOffset, ctrl.selectedTaskTemplates, ctrl.taskTemplateMap);
    });

    $scope.$on(TaskListEvent.BULK_REMOVE_DUE_OFFSET, () => {
      TaskTemplateListService.updateAllDueOffset(
        null /* dueOffset */,
        ctrl.selectedTaskTemplates,
        ctrl.taskTemplateMap,
      );
    });

    $scope.$on(TaskListEvent.BULK_ADD_STOP, () => {
      TaskTemplateListService.updateAllStop(true /*stop*/, ctrl.selectedTaskTemplates, ctrl.taskTemplateMap).then(
        () => {
          // Updates stop add / remove buttons
          showMultiSelectMenu();
        },
        () => {
          // Do nothing
        },
      );
    });

    $scope.$on(TaskListEvent.BULK_REMOVE_STOP, () => {
      TaskTemplateListService.updateAllStop(false /*stop*/, ctrl.selectedTaskTemplates, ctrl.taskTemplateMap).then(
        () => {
          // Updates stop add / remove buttons
          showMultiSelectMenu();
        },
        () => {
          // Do nothing
        },
      );
    });

    $scope.$on(EventName.TASK_TEMPLATE_BULK_ASSIGN_STARTED, () => {
      ctrl.selectedTaskTemplates.forEach(taskTemplate => {
        delete taskTemplate._updateFailed;
      });
    });

    $scope.$on(EventName.TASK_TEMPLATE_BULK_UNASSIGN_STARTED, () => {
      ctrl.selectedTaskTemplates.forEach(taskTemplate => {
        delete taskTemplate._updateFailed;
      });
    });

    $scope.$on(EventName.TASK_TEMPLATE_BULK_ASSIGN_OK, (__event, __taskTemplates, response) => {
      response.forEach(res => {
        if (isFailedBulkTemplateTaskAssignmentResponse(res.response)) {
          const taskTemplate = ctrl.selectedTaskTemplates.find(tt => tt.id === res.taskTemplate.id);

          if (taskTemplate) {
            taskTemplate._updateFailed = true;
          }
        }
      });
    });

    $scope.$on(EventName.TASK_TEMPLATE_BULK_UNASSIGN_OK, (__event, __taskTemplates, response) => {
      response.forEach(res => {
        if (isFailedBulkTemplateTaskAssignmentResponse(res.response)) {
          const taskTemplate = ctrl.selectedTaskTemplates.find(tt => tt.id === res.taskTemplate.id);

          if (taskTemplate) {
            taskTemplate._updateFailed = true;
          }
        }
      });
    });

    $scope.$on(TaskListEvent.BULK_CLEAR_SELECTION, () => {
      ctrl.selectTaskTemplate(SELECT_TYPE.SINGLE, ctrl.selectedTaskTemplates[0]);
    });

    $scope.$on(RuleEvent.RULES_UPDATE_OK, (__event, __templateRevisionId, ruleDefinitions, updatedTaskTemplates) => {
      const updatedTaskTemplateHiddenByDefaultMap = updatedTaskTemplates.reduce((map, taskTemplate) => {
        map[taskTemplate.id] = taskTemplate.hiddenByDefault;
        return map;
      }, {});

      ctrl.taskTemplates.forEach(tt => {
        tt.hiddenByDefault = !!updatedTaskTemplateHiddenByDefaultMap[tt.id];
      });

      ctrl.taskTemplateHasAssociatedRule =
        ConditionalLogicCommonUtils.makeTaskTemplateHasAssociatedRule(ruleDefinitions);
    });

    $scope.$on(EventName.TASK_TEMPLATE_CREATE_STARTED, (__event, taskTemplate) => {
      focusById(`step-${taskTemplate.group.id}`);
    });

    async function animateTasksCreation(taskTemplates) {
      ctrl.taskTemplates = [];

      const runAnimation = AiGeneratorAnimationService.createTaskTemplatesAnimator(taskTemplates, {
        timeout: $timeout,
        update: (taskTemplate, index) => {
          ctrl.taskTemplates[index] = taskTemplate;
          // ignore approval tasks during generation
          if (taskTemplate.taskType === TaskTemplateTaskType.Approval) {
            $scope.taskGenerationStatuses[taskTemplate.group.id] = 'done';
          }
        },
      });

      await runAnimation();
    }

    function animateTaskSwitch(taskTemplate) {
      const currentTask = ctrl.getSingleSelected();
      const currentTaskGroupId = currentTask?.group.id;
      const isCurrentTask = taskTemplate.group.id === currentTaskGroupId;
      const isCurrentTaskAnimationDone = $scope.taskGenerationStatuses[currentTaskGroupId] === 'done';

      if (!isCurrentTask && !isCurrentTaskAnimationDone) return;

      const taskGenerationStatusesValues = Object.values($scope.taskGenerationStatuses);
      const areAllAnimationsDone =
        taskGenerationStatusesValues.length === ctrl.taskTemplates.length &&
        taskGenerationStatusesValues.every(value => value === 'done');

      // Switch back to the first task once all the animations are completed
      if (areAllAnimationsDone && $scope.aiGenerationStatus === 'loading') {
        $scope.aiGenerationStatus = 'done';

        $timeout(() => {
          ctrl.selectTaskTemplate(SELECT_TYPE.SINGLE, ctrl.taskTemplates[0]);
        }, 500);

        return;
      }

      const taskWithAnimationPending = ctrl.taskTemplates.find(tt => {
        return $scope.taskGenerationStatuses?.[tt.group.id] === 'animating';
      });

      if (!taskWithAnimationPending) return;

      $timeout(() => {
        // Select the next task that is animating
        ctrl.selectTaskTemplate(SELECT_TYPE.SINGLE, taskWithAnimationPending);

        $timeout(() => {
          const scroller = document.querySelector('.widgets-scroller');

          // scroll to the bottom of the page after switching to the task
          scroller?.scrollBy({ top: scroller.clientHeight, behavior: 'smooth' });
        }, 300);
      });
    }

    function subscribeToTaskTemplatesUpdates(templateRevision) {
      const channelName = ablyService.getChannelNameForTemplateRevision(templateRevision.id);
      const channel = ablyService.getChannel(channelName);

      const taskTemplatesUpdatedListener = () => {
        logger.info(`message from ${AblyEvent.EventType.TaskTemplatesUpdated}`);

        initialize({ isTaskTemplatesUpdated: true });
      };

      logger.info(`subscribing to ${AblyEvent.EventType.TaskTemplatesUpdated}`);
      channel.subscribe(AblyEvent.EventType.TaskTemplatesUpdated, taskTemplatesUpdatedListener);

      ctrl.unsubscribeFromTaskTemplatesUpdates = () => {
        logger.info(`unsubscribing from ${AblyEvent.EventType.TaskTemplatesUpdated}`);

        channel.unsubscribe(AblyEvent.EventType.TaskTemplatesUpdated);
      };
    }
  },
});
