import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { ButtonWithUpgradeTooltip } from 'components/button-with-upgrade-tooltip';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { Option } from 'space-monad';
import { useTemplateMenuContext } from '../template-menu';
import { ButtonProps } from 'components/design/next';

export type ExportCsvButtonProps = { children: React.ReactElement<ButtonProps> };

export const ExportCsvButton: React.FC<ExportCsvButtonProps> = React.memo(({ children }) => {
  const { CsvExportModalService } = useInjector('CsvExportModalService');
  const { templateId } = useTemplateMenuContext();
  const templateQuery = useGetTemplateQuery({ templateId }, { enabled: Boolean(templateId) });

  const exportCsv = React.useCallback(() => {
    if (templateQuery.data) {
      CsvExportModalService.exportToCsvModal(templateQuery.data);
    }
  }, [CsvExportModalService, templateQuery.data]);

  if (!React.isValidElement(children) || Array.isArray(children)) {
    throw new Error('ExportCsvButton child must be a single clickable element');
  }

  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId, { enabled: !!templateId });

  const isFree = useIsCurrentUserFreeMember();
  if (isFree) return <ButtonWithUpgradeTooltip>{children}</ButtonWithUpgradeTooltip>;

  const hasPermission = Option(permissionsQuery.data)
    .map(({ permissionMap: pm }) => (pm.checklistCreate || pm.checklistSchedule) && pm.checklistExport)
    .getOrElse(false);

  if (!hasPermission) return null;

  const btn = React.cloneElement(children, { onClick: exportCsv });

  return btn;
});
