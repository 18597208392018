import * as React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Spinner,
  Stack,
} from 'components/design/next';
import { Organization } from '@process-street/subgrade/core';
import { SignUpHeader } from 'pages/guest-signup/components/signup-header';
import { SignUpFooter } from '../signup-footer';
import { Field, FieldProps, Form, Formik } from 'formik';
import { registrationSchema } from './registration.schema';
import { SignUpInfo } from 'pages/guest-signup/model/model';
import { FormikHelpers } from 'formik/dist/types';

export type SignUpFormProps = {
  organization: Organization;
  email: string;
  onSubmit: (info: SignUpInfo) => Promise<unknown>;
};

type FormValues = {
  fullName: string;
  email: string;
  password: string;
  subscribe: boolean;
};

export const SignUpForm: React.FC<SignUpFormProps> = ({ organization, email, onSubmit }) => {
  const [formErrors] = React.useState<string[]>([]);

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const info = {
      email,
      organizationName: organization.name,
      password: values.password,
      subscribe: values.subscribe,
      username: values.fullName,
    };

    onSubmit(info).catch(() => {
      setSubmitting(false);
    });
  };

  return (
    <Flex alignItems="center" direction="column">
      <SignUpHeader organization={organization} />
      <Formik
        initialValues={{ fullName: '', email, password: '', subscribe: false }}
        initialErrors={[]}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={registrationSchema}
        onSubmit={handleSubmit}
      >
        {props => (
          <Form>
            <Stack w="496px" spacing={4} mt={10}>
              <Field name="email">
                {({ field }: FieldProps<string, FormValues>) => (
                  <FormControl isReadOnly>
                    <FormLabel>Your email</FormLabel>
                    <Input type="text" id="email" isDisabled={true} variant="outline" {...field} />
                    <FormHelperText>We'll never share your email.</FormHelperText>
                  </FormControl>
                )}
              </Field>

              <Field name="fullName">
                {({ field, form }: FieldProps<string, FormValues>) => (
                  <FormControl isRequired isInvalid={!!form.errors.fullName && form.touched.fullName}>
                    <FormLabel>Full Name</FormLabel>
                    <Input type="text" variant="outline" id="fullName" autoFocus={true} {...field} />
                    <FormErrorMessage>{form.errors.fullName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="password">
                {({ field, form }: FieldProps<string, FormValues>) => (
                  <FormControl isRequired isInvalid={!!form.errors.password && form.touched.password}>
                    <FormLabel>Choose a Password</FormLabel>
                    <Input type="password" id="password" variant="outline" {...field} />
                    {!form.errors.password && (
                      <FormHelperText>
                        Password must be at least 8 characters long, contain one number, one uppercase character, one
                        lowercase character and one special character.
                      </FormHelperText>
                    )}
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <SignUpFooter fontSize="sm" />

              <Field name="subscribe">
                {({ field }: FieldProps<string, FormValues>) => (
                  <FormControl>
                    <Checkbox id="subscribe" {...field}>
                      Subscribe to Process Street Blog (recommended)
                    </Checkbox>
                  </FormControl>
                )}
              </Field>

              <Box>
                {formErrors.map((error, index) => (
                  <Alert
                    key={index}
                    status="error"
                    color="gray.500"
                    backgroundColor="red.100"
                    borderRadius="base"
                    border="1px solid"
                    borderColor="red.300"
                  >
                    <AlertIcon />
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                ))}
              </Box>

              <Box textAlign="center">
                <Button
                  w={250}
                  mt={10}
                  isLoading={props.isSubmitting}
                  type="submit"
                  spinner={<Spinner sx={{ color: 'white' }} />}
                >
                  Create Account and Login
                </Button>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};
