import React from 'react';
import { VStack } from 'components/design/next';
import { ChecklistStatusByUser } from 'pages/reports/components/checklist-status-by-user';
import {
  ChartBox,
  ChartBoxChart,
  ChartBoxHeader,
  ChartBoxProps,
  ChartBoxTitle,
  ChartBoxTitleInfo,
  LearnMore,
} from 'pages/reports/components/chart-box';
import { ChecklistTaskStatusByUser } from 'pages/reports/components/checklist-task-status-by-user';
import { ChecklistTaskStatusByTask } from 'pages/reports/components/checklist-task-status-by-task';
import { ChecklistCountOverTime } from 'pages/reports/components/checklist-count-over-time';
import { ChecklistAvgCompletionMinutesOverTime } from 'pages/reports/components/checklist-avg-completion-minutes-over-time';
import { Stack } from '@chakra-ui/react';

const chartBoxProps: ChartBoxProps = {
  bgColor: 'transparent',
  border: 0,
  w: 'full',
  p: 0,
};

export const Analytics = () => {
  return (
    <VStack w="full" spacing={12}>
      <ChartBox {...chartBoxProps}>
        <ChartBoxHeader>
          <ChartBoxTitle>Workflow Run Status by Assigned User</ChartBoxTitle>
          <ChartBoxTitleInfo>
            Based on workflow due date and workflow run assignee. <LearnMore />
          </ChartBoxTitleInfo>
        </ChartBoxHeader>

        <ChartBoxChart>
          <ChecklistStatusByUser />
        </ChartBoxChart>
      </ChartBox>

      <ChartBox {...chartBoxProps}>
        <ChartBoxHeader>
          <ChartBoxTitle>Task Status by Assigned User</ChartBoxTitle>
          <ChartBoxTitleInfo>
            Task status based on due date and task assignee. <LearnMore />
          </ChartBoxTitleInfo>
        </ChartBoxHeader>

        <ChartBoxChart>
          <ChecklistTaskStatusByUser />
        </ChartBoxChart>
      </ChartBox>

      <ChartBox {...chartBoxProps}>
        <ChartBoxHeader>
          <ChartBoxTitle>Task Completion &amp; Status</ChartBoxTitle>
          <ChartBoxTitleInfo>
            Based on visible tasks and status based on due date. <LearnMore />
          </ChartBoxTitleInfo>
        </ChartBoxHeader>

        <ChartBoxChart h={{ base: '3xs', md: 'lg' }}>
          <ChecklistTaskStatusByTask />
        </ChartBoxChart>
      </ChartBox>

      <Stack w="full" flexDirection={{ base: 'column', xl: 'row' }} gap={{ base: 12, xl: 7 }}>
        <ChartBox {...chartBoxProps}>
          <ChartBoxHeader>
            <ChartBoxTitle>Workflow Run Counts</ChartBoxTitle>
            <ChartBoxTitleInfo>
              Count of workflow run completion over time. <LearnMore />
            </ChartBoxTitleInfo>
          </ChartBoxHeader>

          <ChartBoxChart>
            <ChecklistCountOverTime />
          </ChartBoxChart>
        </ChartBox>

        <ChartBox {...chartBoxProps}>
          <ChartBoxHeader>
            <ChartBoxTitle>Workflow Run Average Completion Time</ChartBoxTitle>
            <ChartBoxTitleInfo>
              Average time it takes from run creation to run completion over time. <LearnMore />
            </ChartBoxTitleInfo>
          </ChartBoxHeader>

          <ChartBoxChart>
            <ChecklistAvgCompletionMinutesOverTime />
          </ChartBoxChart>
        </ChartBox>
      </Stack>
    </VStack>
  );
};
