import { ActorRef } from 'xstate';

/**
 * Inspired by: https://github.com/statelyai/xstate/discussions/3826#discussioncomment-4958246
 * The state._sessionid approach didn't work due to using `predictableActionArguments` which is recommended.
 * It works fine to just register using the result of calling `spawn` in the machines and skip the _sessionid.
 */
export function makeReceptionist<Recipients extends string>() {
  const recipients = {} as Record<Recipients, ActorRef<any, any>>;

  function set(name: Recipients, actorRef: ActorRef<any, any>) {
    recipients[name] = actorRef;
  }

  return {
    get<Ref extends ActorRef<any, any>>(name: Recipients): Ref {
      return recipients[name] as Ref;
    },
    getAll() {
      return recipients;
    },
    set,
    register({ name, actorRef }: { name: Recipients; actorRef: ActorRef<any, any> }) {
      set(name, actorRef);
    },
    remove(name: Recipients) {
      delete recipients[name];
    },
    removeAll() {
      Object.keys(recipients).forEach(name => {
        delete recipients[name as Recipients];
      });
    },
  };
}
