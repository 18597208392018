import { INVITATION_CREATE } from 'reducers/invitation/invitation.actions';
import { composeReducerObjects, handleActionsOnSuccess } from 'reducers/util';
import { combineReducers } from 'redux';
import { combineActions } from 'redux-actions';
import {
  TASK_ASSIGNMENT_GET_ALL_BY_CHECKLIST_REVISION,
  TASK_ASSIGNMENT_INVITE,
} from 'components/checklist-task-assignment/store/checklist-task-assignment.actions';
import {
  ORGANIZATION_MEMBERSHIP_DELETE_BY_ORGANIZATION_ID_AND_USER_ID,
  ORGANIZATION_MEMBERSHIP_GET_ALL_BY_ORG_ID,
  ORGANIZATION_MEMBERSHIP_GET_ALL_BY_USER_ID,
  ORGANIZATION_MEMBERSHIP_SET_LEVEL,
} from 'reducers/organization-membership/organization-membership.actions';
import { GROUP_CREATE, GROUP_DELETE } from 'reducers/group/group.actions';
import {
  GROUP_MEMBERSHIP_PUT,
  GROUP_MEMBERSHIP_QUERY,
  GROUP_MEMBERSHIP_QUERY_BY_GROUP,
} from 'reducers/group-membership/group-membership.actions';
import {
  appendOnPutReducerObject,
  cascadeCleanOnDeleteReducerObject,
  referencesNormalizer,
} from 'reducers/entities/entities.utils';
import { CHECKLIST_PERMIT_PUT } from 'reducers/checklist-permit/checklist-permit.actions';
import { TEMPLATE_PERMIT_PUT } from 'reducers/template-permit/template-permit.actions';
import { FOLDER_PERMIT_PUT } from 'reducers/folder-permit/folder-permit.actions';
import { ORGANIZATION_CREATE } from 'reducers/organization/organization.actions';
import {
  CHECKLIST_ASSIGNMENT_ARRAY_ACTIONS,
  CHECKLIST_ASSIGNMENT_ASSIGN_USER,
} from 'reducers/checklist-assignment/checklist-assignment.actions';
import {
  FORM_FIELD_VALUE_SET,
  FORM_FIELD_VALUE_UPDATE,
} from 'components/form-field-value/store/form-field-value.actions';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { SESSION_SET_ORGANIZATION_MEMBERSHIPS } from 'reducers/session/session.actions';

export const normalizeOrganizationMembership = referencesNormalizer(['organization', 'user']);

const organizationMembershipReducerObject = {
  [combineActions(GROUP_CREATE, TASK_ASSIGNMENT_INVITE, INVITATION_CREATE, ORGANIZATION_CREATE)]: (
    state,
    { payload: { organizationMembership } },
  ) => EntitiesReducerUtils.upsert(state, organizationMembership, normalizeOrganizationMembership),
  [combineActions(ORGANIZATION_MEMBERSHIP_GET_ALL_BY_ORG_ID, ORGANIZATION_MEMBERSHIP_GET_ALL_BY_USER_ID)]: (
    state,
    { payload },
  ) => EntitiesReducerUtils.upsertAll(state, payload, normalizeOrganizationMembership),
  [TASK_ASSIGNMENT_GET_ALL_BY_CHECKLIST_REVISION]: (state, { payload }) => {
    const memberships = payload.map(({ organizationMembership }) => organizationMembership);
    return EntitiesReducerUtils.upsertAll(state, memberships, normalizeOrganizationMembership);
  },
  [combineActions(GROUP_MEMBERSHIP_QUERY, GROUP_MEMBERSHIP_QUERY_BY_GROUP)]: (state, { payload: groups }) => {
    const memberships = groups.map(({ organizationMembership }) => organizationMembership);
    return EntitiesReducerUtils.upsertAll(state, memberships, normalizeOrganizationMembership);
  },
  [ORGANIZATION_MEMBERSHIP_SET_LEVEL]: (state, { payload: membership }) =>
    EntitiesReducerUtils.upsert(state, membership, normalizeOrganizationMembership),
  [ORGANIZATION_MEMBERSHIP_DELETE_BY_ORGANIZATION_ID_AND_USER_ID]: (state, { meta: { membership } }) =>
    membership ? EntitiesReducerUtils.deleteById(state, membership.id) : state,
  [combineActions(FORM_FIELD_VALUE_UPDATE, FORM_FIELD_VALUE_SET)]: (
    state,
    {
      payload: {
        checklistTaskAssignments: { created: assignments },
      },
    },
  ) =>
    EntitiesReducerUtils.upsertAll(
      state,
      assignments.map(a => a.organizationMembership),
      normalizeOrganizationMembership,
    ),

  [SESSION_SET_ORGANIZATION_MEMBERSHIPS]: (state, { payload: memberships, meta: { selectedOrganizationId } = {} }) => {
    if (!selectedOrganizationId) return state;

    return EntitiesReducerUtils.upsertAll(
      state,
      memberships.filter(({ organization }) => organization.id === selectedOrganizationId),
      normalizeOrganizationMembership,
    );
  },
};

export const organizationMembershipEntitiesReducer = composeReducerObjects(
  organizationMembershipReducerObject,
  appendOnPutReducerObject(CHECKLIST_PERMIT_PUT, 'organizationMembership', normalizeOrganizationMembership),
  appendOnPutReducerObject(GROUP_MEMBERSHIP_PUT, 'organizationMembership', normalizeOrganizationMembership),
  appendOnPutReducerObject(FOLDER_PERMIT_PUT, 'organizationMembership', normalizeOrganizationMembership),
  appendOnPutReducerObject(TEMPLATE_PERMIT_PUT, 'organizationMembership', normalizeOrganizationMembership),
  appendOnPutReducerObject(
    CHECKLIST_ASSIGNMENT_ARRAY_ACTIONS,
    'organizationMembership',
    normalizeOrganizationMembership,
  ),
  appendOnPutReducerObject(CHECKLIST_ASSIGNMENT_ASSIGN_USER, 'organizationMembership', normalizeOrganizationMembership),

  cascadeCleanOnDeleteReducerObject(GROUP_DELETE, 'user.id', 'user.id'),
);

const organizationMembershipByUserIdReducer = handleActionsOnSuccess(
  {
    [combineActions(ORGANIZATION_MEMBERSHIP_GET_ALL_BY_ORG_ID, ORGANIZATION_MEMBERSHIP_GET_ALL_BY_USER_ID)]: (
      state,
      { payload: memberships },
    ) => LookupsReducerUtils.upsertAllUsingSelectorFunctions(state, memberships, m => m.user.id),
    [combineActions(GROUP_CREATE, TASK_ASSIGNMENT_INVITE, INVITATION_CREATE, ORGANIZATION_CREATE)]: (
      state,
      { payload: { organizationMembership } },
    ) => LookupsReducerUtils.upsertUsingSelectorFunctions(state, organizationMembership, m => m.user.id),
    [combineActions(GROUP_MEMBERSHIP_QUERY, GROUP_MEMBERSHIP_QUERY_BY_GROUP)]: (state, { payload: groupMemberships }) =>
      LookupsReducerUtils.upsertAllUsingSelectorFunctions(
        state,
        groupMemberships,
        gm => gm.organizationMembership.user.id,
        gm => gm.organizationMembership.id,
      ),
    [ORGANIZATION_MEMBERSHIP_DELETE_BY_ORGANIZATION_ID_AND_USER_ID]: (state, { meta: { userId } }) =>
      EntitiesReducerUtils.deleteById(state, userId),
    [combineActions(FORM_FIELD_VALUE_UPDATE, FORM_FIELD_VALUE_SET)]: (
      state,
      {
        payload: {
          checklistTaskAssignments: { created: assignments },
        },
      },
    ) =>
      LookupsReducerUtils.upsertAll(state, assignments, 'organizationMembership.user.id', 'organizationMembership.id'),
    [TASK_ASSIGNMENT_GET_ALL_BY_CHECKLIST_REVISION]: (state, { payload: assignments }) =>
      LookupsReducerUtils.upsertAll(state, assignments, 'organizationMembership.user.id', 'organizationMembership.id'),
  },
  {},
);

export const organizationMembershipReducer = combineReducers({
  byUserId: organizationMembershipByUserIdReducer,
});
