export const ChecklistTaskAssignments = ResourceFactory => {
  'ngInject';

  return ResourceFactory.create({
    getAllByChecklistRevisionId: 'GET[] /1/checklist-revisions/:checklistRevisionId/tasks/assignments',
    assign: 'PUT /1/tasks/:taskId/assignments/:email',
    unassign: 'DELETE /1/tasks/:taskId/assignments/:email',
    invite: 'POST /1/tasks/:taskId/assignments/:email/invite',
  });
};
