import React from 'react';
import { Alert, AlertIcon, CloseButton, Link, Spacer, TextProps, useDisclosure } from 'components/design/next';
import { useOnboardingSettings } from 'pages/templates/_id/components/template-settings-modal/use-onboarding-settings';
import { OnboardingSettingsKey } from 'components/user/user-constants';

const SETTING_KEY = OnboardingSettingsKey.SCHEDULE_ALERT_DISMISSED;

export const ScheduleAlert: React.FC<React.PropsWithChildren<{ textProps?: Partial<TextProps> }>> = ({ textProps }) => {
  const { onboardingSettingsQuery, updateOnboardingSettings } = useOnboardingSettings();

  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false });

  React.useEffect(() => {
    if (onboardingSettingsQuery.data) {
      const alertDismissed = onboardingSettingsQuery.data[SETTING_KEY] ?? false;
      if (!alertDismissed) {
        onOpen();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
  }, [onboardingSettingsQuery.data]);

  const handleClose = () => {
    updateOnboardingSettings({
      [SETTING_KEY]: true,
    });

    onClose();
  };

  return isOpen ? (
    <Alert status="info" padding={2} color="gray.700" alignItems="center!important" {...textProps}>
      <AlertIcon />
      You can&nbsp;
      <Link
        textDecoration="underline"
        isExternal
        fontWeight="medium"
        href="https://www.process.st/help/docs/scheduled-workflow-runs/"
      >
        automatically schedule
      </Link>
      &nbsp;your Workflows.
      <Spacer />
      <CloseButton height="auto" onClick={handleClose} />
    </Alert>
  ) : (
    <></>
  );
};
