import classNames from 'classnames';
import { Icon, IconSize, IconType } from 'components/design/Icon';
import React from 'react';
import styles from './BlvdButton.module.scss';

export enum ButtonSize {
  Regular = 'Regular',
  Small = 'Small',
}

export enum ButtonType {
  Action = 'Action',
  Brand = 'Brand',
  Danger = 'Danger',
  Secondary = 'Secondary',
  Warning = 'Warning',
}

export enum ButtonStyle {
  Outline = 'Outline',
  Primary = 'Primary',
  Transparent = 'Transparent',
  Ash = 'Ash',
  White = 'White',
  Reset = 'Reset',
}

export interface BlvdButtonProps {
  className?: string;
  disabled?: boolean;
  icon?: IconType;
  isLeft?: boolean;
  onClick?: () => void;
  size?: ButtonSize;
  style?: ButtonStyle;
  type?: ButtonType;
  useIconMargin?: boolean;
  loading?: boolean;
  htmlType?: 'submit' | 'reset' | 'button';
}

export const BlvdButton: React.FunctionComponent<React.PropsWithChildren<BlvdButtonProps>> = ({
  children,
  className,
  disabled,
  icon,
  isLeft = false,
  onClick,
  size = ButtonSize.Regular,
  style = ButtonStyle.Primary,
  type = ButtonType.Action,
  useIconMargin = true,
  loading = false,
  htmlType = 'button',
}) => {
  const classes = classNames({
    [className || 'forget-it']: !!className,
    [styles.disabled]: disabled,
    [styles.blvdButton]: true,
    [styles.regular]: size === ButtonSize.Regular,
    [styles.regularLeft]: size === ButtonSize.Regular && isLeft,
    [styles.small]: size === ButtonSize.Small,
    [styles.semanticButton]: style === ButtonStyle.Reset,
    [styles.actionPrimary]: style === ButtonStyle.Primary && type === ButtonType.Action,
    [styles.actionOutline]: style === ButtonStyle.Outline && type === ButtonType.Action,
    [styles.actionTransparent]: style === ButtonStyle.Transparent && type === ButtonType.Action,
    [styles.brandPrimary]: style === ButtonStyle.Primary && type === ButtonType.Brand,
    [styles.brandOutline]: style === ButtonStyle.Outline && type === ButtonType.Brand,
    [styles.brandTransparent]: style === ButtonStyle.Transparent && type === ButtonType.Brand,
    [styles.warningPrimary]: style === ButtonStyle.Primary && type === ButtonType.Warning,
    [styles.warningOutline]: style === ButtonStyle.Outline && type === ButtonType.Warning,
    [styles.warningTransparent]: style === ButtonStyle.Transparent && type === ButtonType.Warning,
    [styles.dangerPrimary]: style === ButtonStyle.Primary && type === ButtonType.Danger,
    [styles.dangerOutline]: style === ButtonStyle.Outline && type === ButtonType.Danger,
    [styles.dangerTransparent]: style === ButtonStyle.Transparent && type === ButtonType.Danger,
    [styles.secondaryAsh]: style === ButtonStyle.Ash && type === ButtonType.Secondary,
    [styles.secondaryWhite]: style === ButtonStyle.White && type === ButtonType.Secondary,
    [styles.secondaryTransparent]: style === ButtonStyle.Transparent && type === ButtonType.Secondary,
  });

  const iconClasses = classNames({
    [styles.icon]: useIconMargin,
    [styles.hideOnLoading]: loading,
  });

  const textClasses = classNames({
    [styles.hideOnLoading]: loading,
  });

  const iconSize = size === ButtonSize.Regular ? IconSize.Normal : IconSize.Small;

  return (
    <button type={htmlType} disabled={disabled || loading} className={classes} onClick={onClick}>
      {!!icon && (
        <span className={iconClasses}>
          <Icon iconType={icon} size={iconSize} />
        </span>
      )}
      <span className={textClasses}>{children}</span>
      {!!loading && (
        <div className={styles.loadingIconContainer}>
          <Icon iconType={IconType.Spinner} size={iconSize} spin={loading} />
        </div>
      )}
    </button>
  );
};
