import { updateOrderTrees } from 'pages/pages/_id/edit/page/utils/widget.api';
import { useMutation } from 'react-query';

export const UpdateWidgetOrderTreesMutation = {
  key: ['widget', 'order-trees', 'update'],
  getKey() {
    return [...UpdateWidgetOrderTreesMutation.key];
  },
  mutationFn: updateOrderTrees,
};

export const useUpdateWidgetOrderTreesMutation = () => {
  return useMutation(UpdateWidgetOrderTreesMutation.mutationFn, {
    mutationKey: UpdateWidgetOrderTreesMutation.getKey(),
  });
};
