import angular from 'angular';
import { getEnv } from 'components/common/env';

angular.module('frontStreetApp.services').factory('FacebookService', $window => {
  function boot() {
    $window.fbq('init', getEnv().FACEBOOK_PIXEL_ID);

    // The PageView tracking is disabled (commented out) to get rid of the Facebook pixel errors
    // This is a temporary fix, the proper fix will be decided in the future

    // This is normally done in pixel initialization snippet
    // $window.fbq('track', 'PageView');
  }

  function trackViewContent() {
    $window.fbq('track', 'ViewContent');
  }

  function trackCompleteRegistration() {
    $window.fbq('track', 'CompleteRegistration');
  }

  function trackMakePurchase(value, currency) {
    const val = value ? value.toString() : '0.00';
    const curr = currency || 'USD';

    $window.fbq('track', 'Purchase', { value: val, currency: curr });
  }

  return {
    boot,
    trackViewContent,
    trackCompleteRegistration,
    trackMakePurchase,
  };
});
