import { Muid } from '@process-street/subgrade/core';
import { safeEntityMapToArrayByIdsWith } from '@process-street/subgrade/redux/safe-entity-map-to-array-by-ids';
import { EntityMap } from '@process-street/subgrade/redux/types';

interface PossiblyDeleted {
  _deleted?: boolean;
}

/**
 * Extracts list of entities by their ids from the entity map
 *
 * @param entityMap
 * @param ids
 * @return object[]
 */
export function safeEntityMapToArrayByIds<T>(entityMap: EntityMap<T>, ids: Muid[]): T[] {
  return safeEntityMapToArrayByIdsWith(entityMap, ids, entity =>
    (entity as PossiblyDeleted)._deleted ? undefined : entity,
  );
}
