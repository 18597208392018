import { axiosService } from 'services/axios-service';
import { User } from '@process-street/subgrade/core';
import { QueryClient, QueryObserver, QueryObserverOptions, useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';

const getCurrentUser = () =>
  axiosService
    .getAxios()
    .get<User>('/1/users/me')
    .then(res => res.data);

export const GetCurrentUser = {
  key: ['current-user'],
  getKey: () => [...GetCurrentUser.key],
  queryFn: getCurrentUser,
};

export type GetCurrentUserResponse = User;

export const useGetCurrentUserQuery = <Select = GetCurrentUserResponse>(
  options: UseQueryOptions<GetCurrentUserResponse, AxiosError, Select> = {},
) => {
  return useQuery<GetCurrentUserResponse, AxiosError, Select>(GetCurrentUser.getKey(), GetCurrentUser.queryFn, {
    ...options,
    staleTime: Infinity, // The current use does not change after login.
  });
};

export const makeGetCurrentUserQueryObserver = ({
  queryClient,
  options,
}: {
  queryClient: QueryClient;
  options: QueryObserverOptions<GetCurrentUserResponse, AxiosError>;
}) => {
  return new QueryObserver<GetCurrentUserResponse, AxiosError>(queryClient, {
    queryKey: GetCurrentUser.getKey(),
    queryFn: () => GetCurrentUser.queryFn(),
    ...options,
  });
};
