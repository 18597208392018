import * as React from 'react';
import {
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  MotionWrapper,
  Portal,
  StackProps,
  VStack,
} from 'components/design/next';
import { TaskTemplate, TaskTemplateTaskType } from '@process-street/subgrade/process';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { AnimatePresence, MotionProps } from 'framer-motion';
import { useStateParam } from 'app/hooks/use-state-param';
import { CustomIconName } from 'app/components/design/next/icon/icon-name';
import { SolutionTypeTag } from '@process-street/subgrade/automation';
import { useAutomationSelector } from 'app/pages/templates/_id/automation/components/selector/context';

export type TaskListNewButtonProps = {
  taskTemplates: TaskTemplate[];
  onAddTask: (at?: number, taskTemplate?: Partial<TaskTemplate>) => void;
} & StackProps;

export const TaskListNewButton = React.forwardRef<HTMLDivElement, TaskListNewButtonProps>(
  ({ onAddTask, taskTemplates, ...props }, ref) => {
    const groupId = useStateParam({ key: 'groupId' });

    const getAt = () => {
      return groupId ? taskTemplates.findIndex(taskTemplate => taskTemplate.group.id === groupId) : undefined;
    };

    const handleAddStandardTask = () => {
      onAddTask(getAt(), {});
    };

    const handleAddHeadingTask = () => {
      onAddTask(getAt(), { name: 'Heading:' });
    };

    const handleAddApprovalTask = () => {
      onAddTask(getAt(), { name: 'Approval:', taskType: TaskTemplateTaskType.Approval });
    };

    const handleAddAiTask = () => {
      onAddTask(getAt(), { name: 'AI Task:', taskType: TaskTemplateTaskType.AI });
    };

    const [_, send] = useAutomationSelector();
    const handleOpenAutomationsModal = () => {
      send({
        type: 'SOLUTION_TYPE_SELECTED',
        payload: { solutionTypeTag: SolutionTypeTag.WhenChecklistCompletedThen },
      });
    };

    return (
      <VStack
        w="full"
        alignItems="center"
        justifyContent="center"
        spacing={0}
        position="relative"
        {...props}
        ref={ref}
        height="48px"
      >
        <Divider h={4} orientation="vertical" />

        <Menu placement="bottom">
          {({ isOpen }) => (
            <>
              <MenuButton
                as={IconButton}
                ml={'1px'}
                aria-label="Add new item to sidebar"
                variant="outline"
                icon={<Icon icon="plus" size="4" color="gray.600" />}
                borderWidth="thin"
                colorScheme="gray"
                size="sm"
                borderColor="gray.300"
                bgColor="white"
              />

              <Portal>
                <MenuList borderColor="gray.200" zIndex="modal">
                  <ThemeProvider2024>
                    <AnimatePresence>
                      {isOpen && (
                        <>
                          <NewButtonMenuItem index={0} iconName="plus-square" onClick={handleAddStandardTask}>
                            Task
                          </NewButtonMenuItem>

                          <NewButtonMenuItem index={1} iconName="sparkles" onClick={handleAddAiTask}>
                            AI Task
                          </NewButtonMenuItem>

                          <NewButtonMenuItem index={2} iconName="heading" onClick={handleAddHeadingTask}>
                            Heading
                          </NewButtonMenuItem>

                          <NewButtonMenuItem index={3} iconName="thumbs-up" onClick={handleAddApprovalTask}>
                            Approval
                          </NewButtonMenuItem>

                          <NewButtonMenuItem index={4} iconName="bolt" onClick={handleOpenAutomationsModal}>
                            Automations
                          </NewButtonMenuItem>
                        </>
                      )}
                    </AnimatePresence>
                  </ThemeProvider2024>
                </MenuList>
              </Portal>
            </>
          )}
        </Menu>
      </VStack>
    );
  },
);

const getMenuItemMotionProps = (index: number): MotionProps => {
  return {
    initial: { opacity: 0, y: -5 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -5 },
    transition: { delay: 0.05 * index, ease: 'easeInOut' },
  };
};

const NewButtonMenuItem = ({
  index,
  iconName,
  ...rest
}: { index: number; iconName: CustomIconName } & MenuItemProps) => {
  return (
    <MotionWrapper {...getMenuItemMotionProps(index)}>
      <MenuItem icon={<Icon icon={iconName} size="4" color="gray.600" />} fontSize="md" color="gray.600" {...rest} />
    </MotionWrapper>
  );
};
