import { AnonymousUserInfo, CompleteLoginUserInfo, OrphanUserInfo } from '@process-street/subgrade/core';
import { StateService } from '@uirouter/angularjs';
import angular from 'angular';
import { SentryService } from 'components/sentry/sentry.service';
import { Trace, trace } from 'components/trace';
import { AuthService } from 'services/auth.interface';
import { Auth0Service } from 'services/auth0-service.interface';
import { HttpStatus } from '@process-street/subgrade/util';
import { ElevioService } from 'services/interop/elevio-service.interface';
import { GoNativeService } from 'features/go-native/go-native-service';
import { GtmService } from 'features/google/gtm-service';
import { SessionService } from 'services/session-service.interface';
import { UserService } from 'services/user-service.interface';
import { FeatureFlagService } from 'services/features/feature-flags/feature-flag-service';
import { CookieService } from 'features/cookies/cookie-service';
import { SubdomainRedirectService } from 'services/subdomains/subdomain-redirect-service';
import { queryClient } from 'components/react-root';
import { AnonAuthService } from 'services/anon-auth-service.interface';
import { IntercomService } from 'services/interop/intercom-service';
import { OrganizationService } from 'services/organization-service.interface';
import { isStandardUser } from '@process-street/subgrade/util/user-type-utils';
import { isAxiosError } from '@process-street/subgrade/api';
import { WatchdogItemType, WatchdogService } from 'services/boot/watchdog-service';
import { HubSpotService } from 'features/hub-spot/hub-spot-service';
import { MasqueradeService } from 'features/masquerade/masquerade-service';

export interface AppBootService {
  setupSessionAndBootUsingCookie(): Promise<void>;

  setupSessionAndBootUsingToken(token: string): Promise<void>;

  setupSessionAndBootForAnonymousUser(anonymousUser: AnonymousUserInfo): void;

  setupSessionAndBootLoggedIn(): Promise<void>;

  setupSessionAndBootMasqueraded(): Promise<void>;

  cleanUpSessionAndShutdown(): Promise<void>;
}

export class AppBootServiceImpl implements AppBootService {
  public static $inject = [
    '$state',
    'auth',
    'AnonAuthService',
    'Auth0Service',
    'ElevioService',
    'FeatureFlagService',
    'OrganizationService',
    'SessionService',
    'subdomainRedirectService',
    'UserService',
  ];
  private logger: Trace;

  constructor(
    private $state: StateService,
    private auth: AuthService,
    private anonAuthService: AnonAuthService,
    private auth0Service: Auth0Service,
    private elevioService: ElevioService,
    private featureFlagService: FeatureFlagService,
    private organizationService: OrganizationService,
    private sessionService: SessionService,
    private subdomainRedirectService: SubdomainRedirectService,
    private userService: UserService,
  ) {
    this.logger = trace({ name: 'AppBootService' });
  }

  public async setupSessionAndBootUsingCookie() {
    this.logger.info('Setting up session and booting via cookie.');
    WatchdogService.set(WatchdogItemType.BOOT);
    const token = CookieService.getTokenCookie();
    this.auth.storeToken(token);
    await this.setupSessionAndBoot();
  }

  public async setupSessionAndBootUsingToken(token: string) {
    this.logger.info('Setting up session and booting via token.');
    WatchdogService.set(WatchdogItemType.BOOT);
    this.auth.storeToken(token);
    this.sessionService.setToken(token);
    await this.setupSessionAndBoot();
  }

  public async setupSessionAndBootForAnonymousUser(anonymousUser: AnonymousUserInfo) {
    this.logger.info('Setting up session and booting for anonymous user.');
    WatchdogService.set(WatchdogItemType.BOOT);

    this.sessionService.setSelectedOrganizationId(anonymousUser.selectedOrganization.id);
    CookieService.setUserIdCookie('anonymous');

    const organizationId = this.anonAuthService.getOrganizationId();
    const anonymousToken = this.anonAuthService.getAnonymousToken();
    if (!anonymousToken || !organizationId) {
      throw new Error('At session boot, we should be anonymously authenticated. Cannot find token or organization id.');
    }
    this.sessionService.setSelectedOrganizationId(organizationId);
    this.sessionService.setToken(anonymousToken);
    this.sessionService.setOrganizationSubdomainsMap(anonymousUser.organizationSubdomainsMap);
    // invalidate React queries after anonymous authentication
    await queryClient.resetQueries();
    // Fetch user info e.g. plan for anonymous user
    await this.getCompleteLoginUserInfo();
    WatchdogService.clear();

    this.subdomainRedirectService.setupSubdomainRedirectOnBoot({
      isLoggedIn: true,
      isMasquerading: false,
    });

    this.logger.info('Successfully authenticated anonymous user.');
  }

  public async setupSessionAndBootLoggedIn() {
    this.logger.info('Setting up session and booting for logged in user.');

    return this.setupSessionAndBoot({ isLoggedIn: true });
  }

  public async setupSessionAndBootMasqueraded() {
    this.logger.info('Setting up session and booting for masqueraded user.');

    MasqueradeService.trackStart();
    return this.setupSessionAndBoot({ isLoggedIn: true, isMasquerading: true });
  }

  private async setupSessionAndBoot({ isLoggedIn = false, isMasquerading = false } = {}) {
    this.logger.info('Will trigger token renew and then bootstrap application');
    try {
      WatchdogService.set(WatchdogItemType.TOKEN_RENEW);
      const token = await this.auth0Service.renewTokenIfNeeded();
      this.auth.storeToken(token);

      const persistedSelectedOrganization = this.organizationService.getLastSelectedOrganization();
      const userInfo = await this.getCompleteLoginUserInfo();

      WatchdogService.set(WatchdogItemType.SET_SELECTED_ORGANIZATION);
      if (userInfo && isStandardUser(userInfo.user)) {
        const selectedOrganization = persistedSelectedOrganization ?? userInfo.selectedOrganization;

        this.sessionService.setUserInfo({
          ...userInfo,
          selectedOrganization,
        });

        sessionStorage.setItem('selectedOrganizationId', selectedOrganization?.id);

        this.setupServices(userInfo);
      }
      this.logger.info('Successfully bootstrapped standard user.');

      this.subdomainRedirectService.setupSubdomainRedirectOnBoot({ isLoggedIn, isMasquerading });
      WatchdogService.clear();
    } catch (e) {
      if (e instanceof Error) {
        this.logger.error(e);
      } else {
        this.logger.error(JSON.stringify(e));
      }
      WatchdogService.clear();
      this.auth.clearSession();
    }
  }

  public async cleanUpSessionAndShutdown() {
    MasqueradeService.trackStop();
    GoNativeService.shutdown();
    this.elevioService.logoutUser();
    IntercomService.shutdown();
    SentryService.clearContext();
    this.auth.clearSession();
    this.auth0Service.unscheduleTokenRenewal();
    this.auth.logout();
    this.auth0Service.logout();
  }

  public setupServices(userInfo: CompleteLoginUserInfo) {
    this.logger.info('Setting up services.');

    const { user, selectedOrganization, selectedPlan, organizationMembership, intercomHash, elevioHash } = userInfo;
    GoNativeService.setup(user.id);
    SentryService.setUserContext({ email: user.email, id: user.id, username: user.username });
    SentryService.setOrganizationId(selectedOrganization.id);
    this.elevioService.changeUser(user, elevioHash);
    IntercomService.boot(user, intercomHash, selectedOrganization.id);
    HubSpotService.identify(user.email, user.id);

    const fields = {
      userId: user.id,
      userCreatedDate: new Date(user.audit.createdDate).toISOString(),
      userUsername: user.username,
      userEmail: user.email,
      organizationId: selectedOrganization.id,
      organizationName: selectedOrganization.name,
      organizationMembershipRole: organizationMembership.role,
      planId: selectedPlan.id,
      planLevel: selectedPlan.level,
      planName: selectedPlan.name,
    };
    GtmService.pushEvent({ event: 'identify', fields });
    GtmService.saveSignUpDateInStorage({
      userId: user.id,
      userCreatedDate: user.audit.createdDate,
      organizationCreatedById: selectedOrganization.audit.createdBy.id,
    });
    void GtmService.pushUserLoggedInSecondTimeEventIfNeeded({ event: 'user.loggedInSecondTime', fields });

    this.featureFlagService.initializeForUser(user, selectedOrganization, selectedPlan);
  }

  public async getCompleteLoginUserInfo(): Promise<CompleteLoginUserInfo | void> {
    try {
      WatchdogService.set(WatchdogItemType.COMPLETE_LOGIN);
      // Await the promise to catch the error
      return await this.userService.completeLogin();
    } catch (e) {
      if (!isAxiosError(e) || !e.response) throw e;
      WatchdogService.set(WatchdogItemType.COMPLETE_LOGIN_ERROR);
      const { response } = e;
      // When an SSO user is signing in using sso credentials for the first time.
      if (response.status === HttpStatus.NOT_FOUND) {
        this.logger.info('User info endpoint returned NOT_FOUND.  Creating new user via SSO signup flow.');
        WatchdogService.set(WatchdogItemType.SIGNUP_SSO);
        const userInfo = await this.signUpSSOUser();
        return userInfo;
      } else if (response.status === HttpStatus.CONFLICT) {
        this.logger.info('User info endpoint returned CONFLICT.  Triggering orphan user flow.');
        const data = response.data as OrphanUserInfo;
        this.setupSessionAndBootForOrphanUser(data);
        WatchdogService.clear();
        await this.$state.go('userManage.tab', { id: 'me', tab: 'organizations' });
      } else {
        this.logger.error(`User info endpoint return ${response.status}.  Logging out.`);
        WatchdogService.clear();
        await this.$state.go('logout');
      }
    }
  }

  private async signUpSSOUser(): Promise<CompleteLoginUserInfo> {
    const signUpResult = await this.auth.signUp();
    const userInfo = await this.userService.completeLogin();
    this.auth.broadcastCreatedEventsBySignUpResult(signUpResult);
    return userInfo;
  }

  private setupSessionAndBootForOrphanUser(userInfo: OrphanUserInfo) {
    this.sessionService.setOrphanUserInfo(userInfo);
  }
}

angular.module('frontStreetApp.services').service('appBootService', AppBootServiceImpl);
