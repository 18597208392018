import * as React from 'react';
import { HStack, Skeleton, Text, useBreakpointValue } from 'components/design/next';
import { ChecklistGridCountQuery } from 'features/checklists/query-builder/checklist-grid-count';
import { useChecklistSearchCriteria } from 'pages/reports/hooks/use-checklist-search-criteria';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { match, P } from 'ts-pattern';

interface ChecklistDashboardResultsProps {}

const formatNumber = (n: number) => String(n).replace(/(.)(?=(\d{3})+$)/g, '$1,');

export const ChecklistDashboardResults: React.VFC<ChecklistDashboardResultsProps> = () => {
  const searchCriteria = useChecklistSearchCriteria();
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const countQuery = ChecklistGridCountQuery.useQuery(
    { organizationId, ...searchCriteria },
    { enabled: Boolean(organizationId) },
  );
  const count = countQuery.data?.total;

  const label = match(count)
    .with(0, () => 'No results')
    .with(1, () => '1 result')
    .with(P.number, c => `${formatNumber(c)} results`)
    .otherwise(() => '');

  return (
    <HStack spacing={{ base: '0', xl: '4' }} flexWrap="wrap" mb="4">
      <Text as="h2" color="gray.700" display={{ base: 'none', xl: 'inline-block' }} variant="2" fontWeight="medium">
        Workflow Runs
      </Text>
      <Skeleton isLoaded={!countQuery.isLoading}>
        <Text as="span" color="gray.500" variant={useBreakpointValue({ base: '-2', sm: '-1' })}>
          {label}
        </Text>
      </Skeleton>
    </HStack>
  );
};
