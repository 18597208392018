import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';

type Context = {
  shouldShowConfirmation: boolean;
  setShouldShowConfirmation: (shouldShow: boolean) => void;
  confirmationContent: React.ReactNode;
  setConfirmationContent: (content: React.ReactNode) => void;
  onConfirm: (confirmCallback: () => void) => void;
  hasAcceptedConfirmation?: boolean;
  setHasAcceptedConfirmation: (hasAccepted: boolean) => void;
};

export const [useConfirmationContext, ConfirmationContext] = createUsableContext<Context>({
  hookName: 'useConfirmationContext',
  providerName: 'ConfirmationContext',
});

export const ConfirmationContextProvider: React.FunctionComponent<React.PropsWithChildren<Context>> = ({
  children,
  shouldShowConfirmation,
  setShouldShowConfirmation,
  confirmationContent,
  setConfirmationContent,
  onConfirm,
  hasAcceptedConfirmation,
  setHasAcceptedConfirmation,
}) => {
  const value = React.useMemo(
    () => ({
      shouldShowConfirmation,
      setShouldShowConfirmation,
      confirmationContent,
      setConfirmationContent,
      onConfirm,
      hasAcceptedConfirmation,
      setHasAcceptedConfirmation,
    }),
    [
      shouldShowConfirmation,
      setShouldShowConfirmation,
      confirmationContent,
      setConfirmationContent,
      onConfirm,
      hasAcceptedConfirmation,
      setHasAcceptedConfirmation,
    ],
  );

  return <ConfirmationContext.Provider value={value}>{children}</ConfirmationContext.Provider>;
};
