import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { useCanRunWorkflows } from 'hooks/use-can-run-workflows';
import { useOrganizationMembershipRole } from '../use-organization-membership-role';

export const useShouldBlockRunWorkflow = (): {
  shouldBlock: boolean;
  role: OrganizationMembershipRole | undefined;
} => {
  const role = useOrganizationMembershipRole();
  const canRunWorkflows = useCanRunWorkflows();

  return { shouldBlock: !canRunWorkflows, role };
};
