import { ConditionalFilterSingleDateOperand } from '@process-street/subgrade/dashboard';
import React from 'react';
import { BlvdDatePicker, formatMMDDYYYY } from 'components/design/BlvdDatePicker';

export interface SingleDateOperandEditorProps {
  operand: ConditionalFilterSingleDateOperand;
  onChange: (operand: ConditionalFilterSingleDateOperand) => void;
}

export const SingleDateOperandEditor: React.FunctionComponent<
  React.PropsWithChildren<SingleDateOperandEditorProps>
> = ({ operand, onChange }) => {
  const date = new Date(operand.value);
  const handleChange = (day?: Date) => {
    day && onChange({ ...operand, value: day.getTime() });
  };

  return (
    <BlvdDatePicker
      value={date}
      onDayChange={handleChange}
      formatTriggerLabel={formatMMDDYYYY}
      triggerProps={{ leftIcon: undefined, iconSpacing: 0, width: 'full' }}
    />
  );
};
