import { Muid, Option } from '@process-street/subgrade/core';
import { StateService } from '@uirouter/core';
import { GridDataService } from 'components/dashboard/services/grid-data.service';
import { gridDataService } from 'components/dashboard/services/grid-data.service.impl';
import { ChecklistDashboardActions } from 'components/dashboard/store/checklist-dashboard.actions';
import React from 'react';
import { IOrganizationMembershipActions } from 'reducers/organization-membership/organization-membership.actions';
import { SessionService } from 'services/session-service.interface';
import { testContext } from './react-test-context';
import { getEnv } from 'components/common/env';

export interface ReactContextType {
  actions: {
    checklistDashboardActions: ChecklistDashboardActions;
    organizationMembershipActions: IOrganizationMembershipActions;
  };
  env: {
    mrProcesspantsOrganizationId: Option<Muid>;
  };
  services: {
    $state: StateService;
    gridDataService: GridDataService;
    sessionService: SessionService;
  };
}

export const getRealContext = (
  $state: StateService,
  organizationMembershipActions: IOrganizationMembershipActions,
  sessionService: SessionService,
): ReactContextType => ({
  actions: {
    checklistDashboardActions: new ChecklistDashboardActions(gridDataService),
    organizationMembershipActions,
  },
  env: {
    mrProcesspantsOrganizationId: getEnv().APP_PROCESSPANTS_ORG_ID,
  },
  services: {
    $state,
    gridDataService,
    sessionService,
  },
});

// using test context by default because real needs some injections
export const AngularDependenciesContext = React.createContext<ReactContextType>(testContext);
