import { Muid, Organization, OrganizationMembershipRole } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateOrganizationDefaultSsoMembershipLevelParams = {
  organizationId: Muid;
  level: OrganizationMembershipRole;
};

export const UpdateOrganizationDefaultSsoMembershipLevelMutation = {
  key: ['organizations', 'default-sso-organization-membership-level'],
  getKey: (params: UpdateOrganizationDefaultSsoMembershipLevelParams) => [
    ...UpdateOrganizationDefaultSsoMembershipLevelMutation.key,
    params,
  ],
  queryFn: (params: UpdateOrganizationDefaultSsoMembershipLevelParams) =>
    axiosService
      .getAxios()
      .put<Organization>(`/1/organizations/${params.organizationId}/default-sso-organization-membership-level`, params)
      .then(r => r.data),
};

export const useUpdateOrganizationDefaultSsoMembershipLevelMutation = (
  options?: UseMutationOptions<Organization, AxiosError, UpdateOrganizationDefaultSsoMembershipLevelParams>,
) => {
  return useMutation(
    UpdateOrganizationDefaultSsoMembershipLevelMutation.key,
    UpdateOrganizationDefaultSsoMembershipLevelMutation.queryFn,
    options,
  );
};
