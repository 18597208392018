import { InboxItem, InboxItemType, InboxItemUtils } from '@process-street/subgrade/inbox';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { OpUnitType } from 'dayjs';
import { dayjs } from '@process-street/subgrade/util/dayjs';
import { isModelRef, isNotIdRef, Muid, MuidUtils } from '@process-street/subgrade/core';
import { Checklist, ChecklistStatus, TaskStatus } from '@process-street/subgrade/process';

export namespace TasksTableUtils {
  export const TASKS_TABLE_NON_DATA_ROW_COUNT = 1; // New button row
  export const ROW_HEIGHT_DESKTOP_MY_WORK_GA_PX = 40;
  export const ROW_HEIGHT_MOBILE_PX = 48;
  export const GROUP_ROW_HEIGHT_PX = 56;
  export const CHECKBOX_COL_WIDTH_PX = 40;
  export const TASKS_TABLE_PAGE_SIZE = 50;

  export enum FULL_ROW_BUTTON_TYPE {
    LOAD_MORE_BUTTON,
    ADD_NEW_INFINITE_SCROLL, // used to +New in infinite scroll
    ADD_NEW_ATTACHED_TASK, // used to +New task in grouped by wfr name
    ADD_NEW_UNATTACHED_TASK,
  }

  export const LOAD_MORE_BUTTON_ROW: FullWidthRow = {
    isFullWidth: true,
    type: FULL_ROW_BUTTON_TYPE.LOAD_MORE_BUTTON,
  };

  export const ADD_NEW_INFINITE_SCROLL_BUTTON_ROW: FullWidthRow = {
    isFullWidth: true,
    type: FULL_ROW_BUTTON_TYPE.ADD_NEW_INFINITE_SCROLL,
  };

  /** A full width row, that can be either a +New button or a "load more" button. */
  interface FullWidthRow {
    isFullWidth: true;
    type: FULL_ROW_BUTTON_TYPE;
  }

  interface LoadingRow {
    isLoading: true;
    id: Muid;
  }

  export interface NewTaskRow extends FullWidthRow {
    checklist?: Checklist;
    id: Muid;
  }

  export const createLoadingRow = (): LoadingRow => ({ isLoading: true, id: MuidUtils.randomMuid() });

  export const createNewTaskRow = (checklist?: Checklist): NewTaskRow => ({
    checklist,
    isFullWidth: true,
    type: checklist
      ? TasksTableUtils.FULL_ROW_BUTTON_TYPE.ADD_NEW_ATTACHED_TASK
      : TasksTableUtils.FULL_ROW_BUTTON_TYPE.ADD_NEW_UNATTACHED_TASK,
    id: MuidUtils.randomMuid(),
  });

  export function isFullWidthRow(row?: TasksTableItem): row is FullWidthRow {
    return !!row && 'isFullWidth' in row && row.isFullWidth;
  }

  export function isLoadingRow(row?: TasksTableItem): row is LoadingRow {
    return !!row && 'isLoading' in row && row.isLoading;
  }

  export function isInboxItemRow(row?: TasksTableItem): row is InboxItem {
    return !!row && !isFullWidthRow(row) && !isLoadingRow(row);
  }

  export function isNewAttachedTaskRow(row?: TasksTableItem): row is NewTaskRow {
    return (
      !!row &&
      'isFullWidth' in row &&
      row.isFullWidth &&
      row.type === TasksTableUtils.FULL_ROW_BUTTON_TYPE.ADD_NEW_ATTACHED_TASK
    );
  }

  export function isNewUnattachedTaskRow(row?: TasksTableItem): row is NewTaskRow {
    return (
      !!row &&
      'isFullWidth' in row &&
      row.isFullWidth &&
      row.type === TasksTableUtils.FULL_ROW_BUTTON_TYPE.ADD_NEW_UNATTACHED_TASK
    );
  }

  export function isNewTaskRow(row?: TasksTableItem): row is NewTaskRow {
    return isNewAttachedTaskRow(row) || isNewUnattachedTaskRow(row);
  }

  export function isInboxItemCompleted(row?: TasksTableItem): boolean {
    if (!isInboxItemRow(row)) return false;

    if (InboxItemUtils.isChecklist(row)) {
      return isNotIdRef(row.checklist) && row.checklist.status === ChecklistStatus.Completed;
    }

    if (InboxItemUtils.isTask(row)) {
      return row.task.status === TaskStatus.Completed;
    }

    return false;
  }

  export function isReadOnly(row?: TasksTableItem): boolean {
    if (!isInboxItemRow(row)) return false;

    const isWorkflowTask = row.itemType == InboxItemType.StandardTask || row.itemType == InboxItemType.ApprovalTask;
    return isWorkflowTask && isModelRef(row.checklist) && row.checklist.status === ChecklistStatus.Completed;
  }

  export type TasksTableItem = FullWidthRow | LoadingRow | InboxItem | NewTaskRow;

  export enum TasksTableGroupBy {
    Ungrouped = 'Ungrouped',
    DueDate = 'DueDate',
    WorkflowRun = 'WorkflowRun',
  }

  export type TasksTableFilters = GetInboxItemsQuery.Params & {
    groupBy?: TasksTableGroupBy;
  };

  /* Numbering allows ordering groups in Inbox table */
  export enum TasksTableDueDateGroup {
    Overdue,
    Today,
    ThisWeek,
    ThisMonth,
    Later,
    NoDueDate,
  }

  export enum TasksTableHash {
    Upcoming = 'upcoming',
    NoDueDate = 'noDueDate',
  }

  export function getDueDateGroup(dueDate?: number): TasksTableDueDateGroup {
    const dueDateDayJs = dayjs(dueDate);
    const isBefore = (unit?: OpUnitType) => dueDateDayJs.isBefore(unit ? dayjs().endOf(unit) : dayjs());
    if (!dueDate) {
      return TasksTableDueDateGroup.NoDueDate;
    } else if (isBefore()) {
      return TasksTableDueDateGroup.Overdue;
    } else if (isBefore('day')) {
      return TasksTableDueDateGroup.Today;
    } else if (isBefore('week')) {
      return TasksTableDueDateGroup.ThisWeek;
    } else if (isBefore('month')) {
      return TasksTableDueDateGroup.ThisMonth;
    } else {
      return TasksTableDueDateGroup.Later;
    }
  }
}
