import { useEffect } from 'react';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-context';
import pLimit from 'p-limit';

export const usePasteFile = () => {
  const actor = useFormEditorPageActorRef();

  useEffect(
    function listenToPasteEvent() {
      const handlePaste = (event: ClipboardEvent) => {
        const clipboardItems = event.clipboardData?.items ?? [];

        const limit = pLimit(1);

        Array.from(clipboardItems).forEach(item => {
          if (item.kind === 'file') {
            const file = item.getAsFile();
            if (file) {
              void limit(
                () =>
                  new Promise(resolve => {
                    actor.send({ type: 'PASTE_FILE', file });

                    setTimeout(() => {
                      resolve({});
                    }, 500);
                  }),
              );
            }
          }
        });
      };

      window.addEventListener('paste', handlePaste);

      return function cleanUp() {
        window.removeEventListener('paste', handlePaste);
      };
    },
    [actor],
  );
};
