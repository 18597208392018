import * as React from 'react';
import { HexColorPicker } from 'react-colorful';
import {
  Wrap,
  WrapItem,
  Box,
  VStack,
  Input,
  Button,
  useToken,
  IconButton,
  InputGroup,
  Icon,
  InputLeftAddon,
  InputRightElement,
} from 'components/design/next';
import { useDebounce } from 'react-use';
import { noop } from '@process-street/subgrade/util';

const colors = [
  '#7fdbff',
  '#0074d9',
  '#01ff70',
  '#001f3f',
  '#39cccc',
  '#3d9970',
  '#2ecc40',
  '#ff4136',
  '#85144b',
  '#ff851b',
  '#b10dc9',
  '#ffdc00',
  '#f012be',
  '#ffffff',
  '#111111',
  '#dddddd',
];

type Props = {
  color?: string;
  onChange: (color?: string) => void;
  onFocusInput?: () => void;
};

export const ColorPicker: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({ color, onFocusInput = noop, onChange }) => {
    const [val, setVal] = React.useState((color ?? '').replace('#', ''));
    const [baseRadius] = useToken('radii', ['md']);

    const updateColor = React.useCallback((color: string) => {
      // The API doesn't expect the Hex code to contain uppercase characters
      setVal(color.replace('#', '').toLowerCase());
    }, []);

    useDebounce(
      () => {
        const formattedVal = !!val && val !== '' && val.indexOf('#') !== 0 ? `#${val}` : val;
        onChange(formattedVal);
      },
      200,
      [val],
    );

    return (
      <VStack
        sx={{
          '&>.react-colorful': {
            'width': '100% !important',
            '&>.react-colorful__saturation': {
              borderTopRadius: baseRadius,
            },
            '&>.react-colorful__last-control': {
              borderBottomRadius: baseRadius,
            },
          },
        }}
        width="full"
      >
        <Box width="full">
          <InputGroup>
            <InputLeftAddon
              background="transparent"
              color="white"
              borderColor="currentColor"
              border="1px solid currentColor"
              borderRightWidth="0"
            >
              <Icon icon="hashtag" size="4" />
            </InputLeftAddon>
            <Input
              variant="outline"
              width="full"
              placeholder="hex code"
              value={val}
              onFocus={() => onFocusInput()}
              onBlur={(evt: React.ChangeEvent<HTMLInputElement>) => {
                updateColor(evt.target.value);
              }}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                updateColor(evt.target.value);
              }}
            />
            <InputRightElement color="currentColor">
              <Box>
                <IconButton
                  variant="unstyled"
                  color="gray.100"
                  aria-label="Clear Color"
                  icon={<Icon variant="far" icon="tint-slash" size="5" />}
                  onClick={() => setVal('')}
                />
              </Box>
            </InputRightElement>
          </InputGroup>
        </Box>
        <HexColorPicker
          color={val}
          onChange={(selectedColor: string) => {
            if (!/NaN/.test(selectedColor)) {
              updateColor(selectedColor);
            }
          }}
        />
        <Wrap spacing="1" justify="center">
          {colors.map(c => {
            return (
              <WrapItem width="8" height="8" key={c}>
                <Button
                  variant="unstyled"
                  onClick={() => {
                    updateColor(c);
                  }}
                  key={c}
                  backgroundColor={c}
                  display="block"
                  width="full"
                  height="full"
                  aria-label={c}
                />
              </WrapItem>
            );
          })}
        </Wrap>
      </VStack>
    );
  },
);
