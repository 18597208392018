import angular from 'angular';

angular
  .module('frontStreetApp.controllers')
  .controller('RulesUpgradeModalCtrl', function ($scope, $uibModalInstance, modalOptions) {
    $scope.canUpgrade = modalOptions.canUpgrade;
    $scope.rulesFeatureIsAvailable = modalOptions.rulesFeatureIsAvailable;
    $scope.openRulesManager = modalOptions.openRulesManager;

    $scope.close = function () {
      $uibModalInstance.close();
    };

    $scope.cancel = function () {
      $scope.close();
    };

    $scope.upgrade = function () {
      $scope.close();
    };

    $scope.openRulesManagerModal = function () {
      $scope.openRulesManager();
      $scope.close();
    };
  });
