import { connectService } from 'reducers/util';
import { TaskSelector } from 'reducers/task/task.selectors';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { ChecklistEvent } from 'services/checklists/checklist-event';
import { FormFieldEvent } from 'services/form-field-event';

export class DueDateUpdateListener {
  constructor($ngRedux, TaskActions) {
    'ngInject';

    this.logger = trace({ name: 'DueDateUpdateListener' });

    const mapStateToThis = state => ({
      taskMap: TaskSelector.getEntityMap(state),
      taskTemplateMap: TaskTemplateSelector.getEntityMap(state),
    });

    connectService('DueDateUpdateListener', $ngRedux, mapStateToThis, TaskActions)(this);
  }

  listen($scope) {
    $scope.$on(EventName.TASK_STATUS_UPDATE_OK, (__event, __data, dueDateTaskStates) => {
      this.compareChanges(dueDateTaskStates, false, EventName.TASK_STATUS_UPDATE_OK);
    });

    $scope.$on(
      FormFieldEvent.FORM_FIELD_VALUE_UPDATE_OK,
      (__event, __formFieldValue, __originalFormFieldValue, __checklistRevisionId, dueDateTaskStates) => {
        this.compareChanges(dueDateTaskStates, true, FormFieldEvent.FORM_FIELD_VALUE_UPDATE_OK);
      },
    );

    $scope.$on(ChecklistEvent.UPDATE_DUE_DATE_OK, (__event, dueDateTaskStates) => {
      this.compareChanges(dueDateTaskStates, true, ChecklistEvent.UPDATE_DUE_DATE_OK);
    });
  }

  _dateToLong(newDueDate) {
    if (newDueDate && newDueDate.getTime instanceof Function) {
      return newDueDate.getTime();
    } else {
      return newDueDate;
    }
  }

  compareChanges(dueDateTaskStates, exactCompare, event) {
    dueDateTaskStates.forEach(item => {
      const task = this.state.taskMap[item.id];
      if (task) {
        const taskDueDate = task.dueDate ? this._dateToLong(task.dueDate) : null;
        const itemDueDate = item.dueDate ? item.dueDate : null;

        const diff = Math.abs(taskDueDate - itemDueDate);
        if (diff > 0) {
          this.actions.updateInternal({ ...task, dueDate: itemDueDate });
        }

        if (diff > 0 && (exactCompare || diff > 10000)) {
          const taskTemplate = this.state.taskTemplateMap[task.taskTemplate.id];

          this.logger.warn(
            'Due date difference found event=%s, diff=%dms, taskId=%s taskName=%s',
            JSON.stringify(event),
            diff,
            task.id,
            taskTemplate.name,
          );
        }
      }
    });
  }
}
