import {
  ChecklistRuleDefinition,
  ChecklistRuleDefinitionFormFieldOperator,
  ChecklistRuleDefinitionLogicalOperator,
  ChecklistRuleDefinitionOperandType,
  ChecklistRuleDefinitionOperator,
  Condition,
  FormFieldCondition,
  isConditionLogical,
  isRuleLogical,
  LogicalChecklistRuleDefinition,
  LogicalCondition,
  SimpleChecklistRuleDefinition,
  TaskCondition,
  TimeBasedCondition,
} from '@process-street/subgrade/conditional-logic';
import { Muid, MuidUtils } from '@process-street/subgrade/core';
import * as React from 'react';
import { ConditionDisplayProps } from 'features/conditional-logic/components/rule-definition/condition';

export function useLogicalRule(
  rule: ChecklistRuleDefinition,
  onChange: (rule: Partial<ChecklistRuleDefinition>) => void,
) {
  const logicalRule = transformToLogicalRule(rule);
  const [topCondition, setTopCondition] = React.useState(
    () => addConditionIds(logicalRule.operand.data) as LogicalConditionWithId,
  );

  const onConditionChange = (id: Muid, data: FormFieldCondition | TaskCondition | TimeBasedCondition) => {
    const updatedCondition = updateById(topCondition, { id, ...data }) as LogicalConditionWithId;
    setTopCondition(updatedCondition);
    onChange(getRule(updatedCondition, logicalRule));
  };

  const onConditionListChange = (updatedCondition: LogicalConditionWithId) => {
    setTopCondition(updatedCondition as LogicalConditionWithId);
    onChange(getRule(updatedCondition, logicalRule));
  };

  return {
    conditions: getConditionsArray(topCondition, onConditionListChange),
    onConditionChange,
  };
}

interface LogicalConditionWithId extends LogicalCondition {
  id: Muid;
  conditions: ConditionWithId[];
}

interface FormFieldConditionWithId extends FormFieldCondition {
  id: Muid;
}

interface TaskConditionWithId extends TaskCondition {
  id: Muid;
}

interface TimeBasedConditionWithId extends TimeBasedCondition {
  id: Muid;
}

type ConditionWithId =
  | LogicalConditionWithId
  | FormFieldConditionWithId
  | TaskConditionWithId
  | TimeBasedConditionWithId;

/** Transform single-condition "A" rule to logical condition "OR(AND(A))" */
function transformToLogicalRule(rule: ChecklistRuleDefinition): LogicalChecklistRuleDefinition {
  if (isRuleLogical(rule)) {
    return rule;
  }

  const condition = getConditionFromSimpleRule(rule);
  return {
    ...rule,
    operator: ChecklistRuleDefinitionOperator.LogicalOr,
    operand: {
      operandType: ChecklistRuleDefinitionOperandType.Logical,
      data: {
        operator: ChecklistRuleDefinitionLogicalOperator.LogicalOr,
        conditions: [
          {
            operator: ChecklistRuleDefinitionLogicalOperator.LogicalAnd,
            conditions: [condition],
          },
        ],
      },
    },
  };
}

function getConditionFromSimpleRule(rule: SimpleChecklistRuleDefinition): FormFieldCondition {
  return {
    formFieldWidgetGroupId: rule.formFieldWidgetGroupId,
    operator: rule.operator,
    operandType: rule.operand.operandType,
    operandValue: { value: rule.operand.value },
  };
}

/** Add IDs to condition nodes so that React can render from an array. */
function addConditionIds(condition: Condition): ConditionWithId {
  return isConditionLogical(condition)
    ? {
        ...condition,
        id: MuidUtils.randomMuid(),
        conditions: condition.conditions.map(addConditionIds),
      }
    : {
        ...condition,
        id: MuidUtils.randomMuid(),
      };
}

function stripConditionIds(condition: ConditionWithId): Condition {
  if (isConditionLogical(condition)) {
    const { id, ...rest } = condition;
    return {
      ...rest,
      conditions: condition.conditions.map(stripConditionIds),
    };
  } else {
    const { id, ...rest } = condition;
    return rest;
  }
}

export type ConditionWithDisplayProps = (FormFieldConditionWithId | TaskConditionWithId | TimeBasedConditionWithId) &
  ConditionDisplayProps;

/** Extract an array of conditions from a condition tree. */
function getConditionsArray(
  topCondition: LogicalConditionWithId,
  onChange?: (topCondition: LogicalConditionWithId) => void,
): ConditionWithDisplayProps[] {
  const getInternal = (c: ConditionWithId, operator?: ChecklistRuleDefinitionOperator): ConditionWithDisplayProps[] => {
    if (isConditionLogical(c)) {
      // display higher-level OR operator for first AND condition
      // this is because we don't flatten but a single item is always represented as "OR(AND(A))"
      const conditions = c.conditions.flatMap((cond, idx) => getInternal(cond, idx === 0 ? operator : c.operator));
      if (c.operator === ChecklistRuleDefinitionLogicalOperator.LogicalAnd) {
        // last condition in an 'AND' group gets an 'AND' button
        const lastCondition = conditions[conditions.length - 1];
        if (lastCondition) {
          lastCondition.onAddAnd = () => {
            const result = addAndById(topCondition, lastCondition.id);
            onChange?.(result);
          };
        }
      }
      return conditions;
    } else {
      const displayTypes: Record<any, ConditionDisplayProps['displayType']> = {
        [ChecklistRuleDefinitionOperator.LogicalOr]: 'or',
        [ChecklistRuleDefinitionOperator.LogicalAnd]: 'and',
      };
      // make shallow copy of form field condition to not mutate topCondition
      const result = {
        ...c,
        onDelete: () => {
          const result = deleteById(topCondition, c.id);
          onChange?.(result);
        },
      };
      return [operator ? { ...result, displayType: displayTypes[operator] } : { ...result }];
    }
  };

  const result: ConditionWithDisplayProps[] = getInternal(topCondition);
  const withCallbacks = result.map((c, idx) => {
    // first condition is always 'IF'
    if (idx === 0) {
      c.displayType = 'if';
    }
    // last condition always has 'OR' button
    if (idx === result.length - 1) {
      c.onAddOr = () => {
        const result = addOr(topCondition);
        onChange?.(result);
      };
    }
    // each condition has a 'delete' button
    c.onDelete = () => {
      const result = deleteById(topCondition, c.id);
      onChange?.(result);
    };
    return c;
  });
  return withCallbacks;
}

function updateById(
  current: ConditionWithId,
  updated: FormFieldConditionWithId | TaskConditionWithId | TimeBasedConditionWithId,
): ConditionWithId {
  if (isConditionLogical(current)) {
    return {
      ...current,
      conditions: current.conditions.map(c => updateById(c, updated)),
    };
  } else if (current.id === updated.id) {
    return updated;
  } else {
    return current;
  }
}

export function getConditionCount(rule: ChecklistRuleDefinition): { conditions: number; buttonRows: number } {
  const logicalRule = transformToLogicalRule(rule);
  const getInternal = (c: Condition): number => {
    if (isConditionLogical(c)) {
      return c.conditions.reduce((sum, cond) => sum + getInternal(cond), 0);
    } else {
      return 1;
    }
  };
  // heuristic shortcut instead of recursion
  // with current design, each OR condition operand has 1 button row
  // (each is AND, and the last is AND, OR)
  const buttonRows = logicalRule.operand.data.conditions.length;
  const conditions = getInternal(logicalRule.operand.data);
  return {
    conditions,
    buttonRows,
  };
}

function deleteById(topCondition: LogicalConditionWithId, id: Muid): LogicalConditionWithId {
  const deleteByIdInternal = (current: ConditionWithId): ConditionWithId => {
    if (isConditionLogical(current)) {
      const index = current.conditions.findIndex(c => c.id === id);
      if (index !== -1) {
        const newConditions = [...current.conditions.slice(0, index), ...current.conditions.slice(index + 1)];
        return { ...current, conditions: newConditions };
      }
      return { ...current, conditions: current.conditions.map(deleteByIdInternal) };
    }
    return current;
  };

  const result = deleteByIdInternal(topCondition) as LogicalConditionWithId;
  // filter out empty AND items
  result.conditions = result.conditions.filter(c => {
    const needsRemoval =
      isConditionLogical(c) &&
      c.operator === ChecklistRuleDefinitionLogicalOperator.LogicalAnd &&
      c.conditions.length === 0;
    return !needsRemoval;
  });
  return result;
}

function getNewFormFieldCondition() {
  return {
    id: MuidUtils.randomMuid(),
    operator: ChecklistRuleDefinitionFormFieldOperator.Is,
    operandType: ChecklistRuleDefinitionOperandType.String,
    operandValue: { value: null },
  };
}

function addOr(topCondition: LogicalConditionWithId): LogicalConditionWithId {
  if (!isConditionLogical(topCondition)) {
    throw new Error('addOr should be called for logical conditions only.');
  }
  if (topCondition.operator !== ChecklistRuleDefinitionLogicalOperator.LogicalOr) {
    throw new Error('Top level condition must be OR.');
  }
  const newAndCondition: LogicalConditionWithId = {
    id: MuidUtils.randomMuid(),
    operator: ChecklistRuleDefinitionLogicalOperator.LogicalAnd,
    conditions: [getNewFormFieldCondition()],
  };
  return {
    ...topCondition,
    conditions: [...topCondition.conditions, newAndCondition],
  };
}

/** In the AND operator where condition by ID is present,
 * add another AND condition. */
function addAndById(topCondition: LogicalConditionWithId, id: Muid): LogicalConditionWithId {
  const addAndInternal = (current: ConditionWithId): ConditionWithId => {
    if (!isConditionLogical(current)) {
      return current;
    }
    if (
      current.operator === ChecklistRuleDefinitionLogicalOperator.LogicalAnd &&
      current.conditions.some((c: ConditionWithId) => c.id === id)
    ) {
      return {
        ...current,
        conditions: [...current.conditions, getNewFormFieldCondition()],
      };
    } else {
      return { ...current, conditions: current.conditions.map(addAndInternal) };
    }
  };
  return addAndInternal(topCondition) as LogicalConditionWithId;
}

/** Strip IDs in conditions and return logical rule */
function getRule(
  topCondition: LogicalConditionWithId,
  rule: LogicalChecklistRuleDefinition,
): LogicalChecklistRuleDefinition {
  return {
    ...rule,
    operand: {
      ...rule.operand,
      data: stripConditionIds(topCondition) as LogicalCondition,
    },
  };
}
