import { Approval, ApprovalStatus } from '@process-street/subgrade/approval-rule/approval.model';
import { Muid } from '@process-street/subgrade/core';
import { OptimisticApproval } from 'reducers/optimistic/optimistic-approval';
import { ReduxAppState } from 'reducers/types';
import { createCachedAction, optimisticAction } from 'reducers/util';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

// Rules
export const APPROVAL_GET_ALL_BY_CHECKLIST_REVISION = 'frontStreet/approvals/GET_ALL_BY_CHECKLIST_REVISION';
export const APPROVAL_UPSERT_ALL = 'frontStreet/approvals/UPSERT_ALL';
export const APPROVAL_UPSERT_ALL_BY_TASK_ID = 'frontStreet/approvals/UPSERT_ALL_BY_TASK_ID';

export interface ApprovalActions {
  getAllByChecklistRevisionId(checklistRevisionId: Muid): Promise<void>;
  upsertAll(checklistRevisionId: Muid, approvals: Approval[]): Promise<void>;
  upsertAllByTaskId(
    checklistRevisionId: Muid,
    taskId: Muid,
    approvalCommand: ApprovalStatus,
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;
}

// TODO
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error -- TODO
export const ApprovalActionsImpl = ApprovalsApi => {
  'ngInject';

  const getAllByChecklistRevisionId = createCachedAction(
    APPROVAL_GET_ALL_BY_CHECKLIST_REVISION,
    ApprovalsApi.getAllByChecklistRevisionId,
    (checklistRevisionId: Muid) => ({ checklistRevisionId }),
    (state: ReduxAppState, checklistRevisionId: Muid) =>
      Boolean(state.lookups.approval.byChecklistRevisionId[checklistRevisionId]),
  );

  const upsertAll = optimisticAction(
    APPROVAL_UPSERT_ALL,
    ApprovalsApi.upsertAll,
    (checklistRevisionId: Muid, approvals: Approval[], approvalTaskId: Muid) => ({
      approvalTaskId,
      approvals,
      checklistRevisionId,
    }),
    OptimisticApproval.upsertAll,
  );

  const upsertAllByTaskId = createCachedAction(
    APPROVAL_UPSERT_ALL_BY_TASK_ID,
    ApprovalsApi.upsertAllByTask,
    (checklistRevisionId: Muid, taskId: Muid, approvalCommand: ApprovalStatus) => ({
      approvalCommand,
      checklistRevisionId,
      taskId,
    }),
  );

  return {
    getAllByChecklistRevisionId,
    upsertAll,
    upsertAllByTaskId,
  };
};
