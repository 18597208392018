import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { GlobalSearchApi } from 'services/global-search-api';

type GlobalSearchApiKeyParams = { organizationId: Muid };
type GlobalSearchApiKeyResult = string;

const GlobalSearchApiKeyQuery = {
  key: 'global-search-api-key',
  getKey: (params: GlobalSearchApiKeyParams): QueryKey => [GlobalSearchApiKeyQuery.key, params],
  queryFn: (params: GlobalSearchApiKeyParams) => GlobalSearchApi.getApiKey(params.organizationId).then(r => r.apiKey),
};

export const useGlobalSearchApiKeyQuery = (
  params: GlobalSearchApiKeyParams,
  options: UseQueryOptions<GlobalSearchApiKeyResult, AxiosError> = {},
) => {
  return useQuery(GlobalSearchApiKeyQuery.getKey(params), () => GlobalSearchApiKeyQuery.queryFn(params), options);
};
