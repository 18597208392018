import { Muid } from '../core';
import { OrderTree } from '../process';

export const DERIVED_CHECKLIST_STATUS = ['completed', 'onTrack', 'dueSoon', 'overdue'] as const;
export type DerivedChecklistStatus = typeof DERIVED_CHECKLIST_STATUS[number];

export type DerivedChecklistStatusStat = {
  templateId: Muid;
  name: string;
  avgCompletionTimeMinutes?: number;
} & Record<DerivedChecklistStatus, number>;

export type DerivedChecklistStatusSummary = {
  avgCompletionTimeMinutes?: number;
} & Record<DerivedChecklistStatus, number>;

export const DERIVED_TASK_STATUSES = ['completed', 'notCompleted', 'onTrack', 'dueSoon', 'overdue'] as const;
export type DerivedTaskStatus = typeof DERIVED_TASK_STATUSES[number];

export type DerivedTaskStatsSummary = Record<DerivedTaskStatus, number>;

export type DerivedTaskStatByTask = { name?: string; orderTree: OrderTree; groupId: Muid } & DerivedTaskStatsSummary;

export type DerivedTaskStatByUser = { userId: Muid } & DerivedTaskStatsSummary;

export type ChecklistTimeSeries = {
  date: string;
  /** Average completion time in minutes at this datetime */
  avgCompletionTimeMinutes?: number;
  /** Total workflow runs at this datetime */
  runCount?: number;
};
