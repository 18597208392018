import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { TaskStatus } from '@process-street/subgrade/process';
import { Option, User } from '@process-street/subgrade/core';

const getRequiredNotCompletedTasks = (tasks: OneOffTask[]): OneOffTask[] =>
  tasks.filter(task => task.required && task.status === TaskStatus.NotCompleted);

const findFirstRequiredNotCompletedUserTask = (tasks: OneOffTask[], userId: User['id']): Option<OneOffTask> =>
  tasks.find(
    task =>
      task.required && task.status === TaskStatus.NotCompleted && task.assignments.map(a => a.user.id).includes(userId),
  );

export const OneOffTaskHelper = {
  getRequiredNotCompletedTasks,
  findFirstRequiredNotCompletedUserTask,
};
