import angular from 'angular';
import buttonTemplate from './import-template-button.component.html';

angular.module('frontStreetApp.directives').component('psImportTemplateButton', {
  bindings: {
    template: '<',
  },
  templateUrl: buttonTemplate,
  controller($state) {
    const ctrl = this;

    ctrl.importTemplate = function (template) {
      $state.go('templateAdd', { templateId: template.id });
    };
  },
});
