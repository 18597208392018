import angular from 'angular';

angular.module('frontStreetApp.services').factory('Users', function (ResourceFactory) {
  return ResourceFactory.create({
    requestPasswordReset: 'POST /1/users/request-password-reset',
    resetPassword: 'POST /1/users/reset-password/:passwordResetId',
    updateUsingToken: 'PATCH /1/users/token/:token',
    validateEmailVerification: 'POST /1/users/validate-email-verification/:codeId',
    sendEmailVerification: 'POST /1/users/:id/send-email-verification/:email',
    update: 'PATCH /1/users/:id',
    getByToken: 'GET /1/users/me',
    getById: 'GET /1/users/:id',
    getAllEmailsById: 'GET[] /1/users/:id/emails',
    createUserEmail: 'PUT /1/users/:id/emails/:email',
    deleteUserEmail: 'DELETE /1/users/:id/emails/:email',
    setPrimaryEmail: 'POST /1/users/:id/emails/:email/set-primary',
    createAvatarFile: 'POST /1/users/:id/avatar-file',
    getOrganizations: 'GET[] /1/users/:id/organizations',
    getSettings: 'GET /1/users/:id/settings',
    completeLogin: 'POST /1/authentication/complete-login',
    getUserLinkableDomains: 'GET[] /1/users/me/organizations/:organizationId/linkable-domains',
    updateSettings: 'PATCH /1/users/:id/settings/:key',
  });
});
