import * as React from 'react';
import { Flex, HStack, Text } from 'components/design/next';
import { Identifiable } from '@process-street/subgrade/core';
import { SelectionBarActionsContext } from './selection-bar-actions-context';

export type SelectionBarActionsProps<T extends Identifiable = Identifiable> = {
  selectedItems: T[];
};

export const SelectionBarActions = <T extends Identifiable = Identifiable>({
  selectedItems,
  children,
}: React.PropsWithChildren<SelectionBarActionsProps<T>>) => {
  const providerValue = React.useMemo(
    () => ({
      selectedItems,
    }),
    [selectedItems],
  );

  if (!selectedItems.length) return null;

  return (
    <SelectionBarActionsContext.Provider value={providerValue}>
      <Flex position="fixed" bottom="12" zIndex="docked" left="50%" w="auto" transform="translateX(-50%)">
        <HStack spacing="0" overflow="hidden" pl="5" borderRadius="full" bgColor="gray.700" color="white">
          <Text textAlign="center" pr="4">
            {selectedItems.length} selected
          </Text>

          {children}
        </HStack>
      </Flex>
    </SelectionBarActionsContext.Provider>
  );
};
