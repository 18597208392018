import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateShareableLinkMutationParams = {
  templateId: Template['id'];
  shared: boolean;
};

export type UpdateShareableLinkMutationResponse = Template;

export const UpdateShareableLinkMutation = {
  key: ['template', 'shareable-link'],
  mutationFn: (params: UpdateShareableLinkMutationParams) =>
    axiosService
      .getAxios()
      .put(`/1/templates/${params.templateId}/shareableRunLink`, params)
      .then(res => res.data),
};

export const useUpdateShareableLinkMutation = (
  options: UseMutationOptions<UpdateShareableLinkMutationResponse, AxiosError, UpdateShareableLinkMutationParams> = {},
) => {
  return useMutation(UpdateShareableLinkMutation.mutationFn, options);
};
