import angular from 'angular';
import templateUrl from './required-option.component.html';
import './required-option.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';

angular.module('frontStreetApp.directives').component('psRequiredOption', {
  bindings: {
    formFieldWidget: '<',
  },
  templateUrl,
  controller(SessionService, WidgetService, ToastService) {
    const ctrl = this;

    ctrl.toggleRequired = function (formFieldWidget) {
      formFieldWidget.required = !formFieldWidget.required;

      WidgetService.update(formFieldWidget).catch(() => {
        ToastService.openToast({
          status: 'error',
          title: `We're having problems updating the form field `,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
    };
  },
});
