import angular from 'angular';
import { ChecklistActions } from 'reducers/checklist/checklist.actions';
import { ChecklistApi } from 'reducers/checklist/checklist.api';
import { ChecklistAssignmentActions } from 'reducers/checklist-assignment/checklist-assignment.actions';
import { ChecklistAssignmentApi } from 'reducers/checklist-assignment/checklist-assignment.api';
import { ChecklistPermitActions } from 'reducers/checklist-permit/checklist-permit.actions';
import { ChecklistPermitApi } from 'reducers/checklist-permit/checklist-permit.api';
import { ChecklistRevisionActions } from 'reducers/checklist-revision/checklist-revision.actions';
import { ChecklistRevisionApiImpl } from 'reducers/checklist-revision/checklist-revision.api';
import { FolderActions } from 'reducers/folder/folder.actions';
import { FolderApi } from 'reducers/folder/folder.api';
import { FolderPermitActions } from 'reducers/folder-permit/folder-permit.actions';
import { FolderPermitApi } from 'reducers/folder-permit/folder-permit.api';
import { GroupMembershipActions } from 'reducers/group-membership/group-membership.actions';
import { GroupMembershipApi } from 'reducers/group-membership/group-membership.api';
import { InvitationActions } from 'reducers/invitation/invitation.actions';
import { InvitationApi } from 'reducers/invitation/invitation.api';
import { InboxActions } from 'reducers/inbox/inbox.actions';
import { InboxApi } from 'reducers/inbox/inbox.api';
import { OrganizationApi } from 'reducers/organization/organization.api';
import { OrganizationMembershipActions } from 'reducers/organization-membership/organization-membership.actions';
import { OrganizationMembershipApi } from 'reducers/organization-membership/organization-membership.api';
import { PlanActions } from 'reducers/plan/plan.actions';
import { PlanApi } from 'reducers/plan/plan.api';
import { ScopeEventAdapter } from 'reducers/scope-event/scope-event.adapter';
import { TaskActions } from 'reducers/task/task.actions';
import { TaskApi } from 'reducers/task/task.api';
import { TemplateActions } from 'reducers/template/template.actions';
import { TaskTemplateActionsImpl } from 'reducers/task-template/task-template.actions';
import { TemplateApi } from 'reducers/template/template.api';
import { TemplatePermitActions } from 'reducers/template-permit/template-permit.actions';
import { TemplatePermitApi } from 'reducers/template-permit/template-permit.api';
import { TemplateRevisionActions } from 'reducers/template-revision/template-revision.actions';
import { TemplateRevisionApi } from 'reducers/template-revision/template-revision.api';
import { UserActions } from 'reducers/user/user.actions';
import { UserApi } from 'reducers/user/user.api';
import { DynamicDueDateActions } from 'components/dynamic-due-dates/store/dynamic-due-dates.actions';
import { OrganizationActions } from 'reducers/organization/organization.actions';
import { PingApiImpl } from 'reducers/core/ping.api';
import { OrganizationDomainActions } from './organization-domain/organization-domain.actions';
import { OrganizationDomainApi } from './organization-domain/organization-domain.api';
import { PlanFeatureSetActions } from './plan-feature-set/plan-feature-set.actions';
import { PlanFeatureSetApi } from './plan-feature-set/plan-feature-set.api';
import { TaskTemplateApiImpl } from './task-template/task-template.api';
import { TaskTemplates } from './task-template/task-templates-resource';
import { AuthenticationApiImpl } from './auth/authentication.api';
import { AuthenticationActionsImpl } from './auth/authentication.actions';
import { TaskStatsActionsImpl } from './task-stats/task-stats.actions';

export const ReducersModule = angular
  .module('app.reducers', ['frontStreetApp.services'])
  .service('AuthenticationApi', AuthenticationApiImpl)
  .service('AuthenticationActions', AuthenticationActionsImpl)
  .service('ChecklistApi', ChecklistApi)
  .factory('ChecklistActions', ChecklistActions)
  .service('ChecklistRevisionApi', ChecklistRevisionApiImpl)
  .service('ChecklistRevisionActions', ChecklistRevisionActions)
  .service('ChecklistAssignmentApi', ChecklistAssignmentApi)
  .factory('ChecklistAssignmentActions', ChecklistAssignmentActions)
  .service('ChecklistPermitApi', ChecklistPermitApi)
  .factory('ChecklistPermitActions', ChecklistPermitActions)
  .factory('DynamicDueDateActions', DynamicDueDateActions)
  .service('FolderApi', FolderApi)
  .factory('FolderActions', FolderActions)
  .service('FolderPermitApi', FolderPermitApi)
  .factory('FolderPermitActions', FolderPermitActions)
  .service('GroupMembershipApi', GroupMembershipApi)
  .factory('GroupMembershipActions', GroupMembershipActions)
  .service('InboxApi', InboxApi)
  .factory('InboxActions', InboxActions)
  .service('InvitationApi', InvitationApi)
  .factory('InvitationActions', InvitationActions)
  .service('PlanApi', PlanApi)
  .factory('PlanActions', PlanActions)
  .service('PlanFeatureSetApi', PlanFeatureSetApi)
  .factory('PlanFeatureSetActions', PlanFeatureSetActions)
  .service('OrganizationApi', OrganizationApi)
  .factory('OrganizationActions', OrganizationActions)
  .service('OrganizationDomainApi', OrganizationDomainApi)
  .factory('OrganizationDomainActions', OrganizationDomainActions)
  .service('OrganizationMembershipApi', OrganizationMembershipApi)
  .factory('OrganizationMembershipActions', OrganizationMembershipActions)
  .service('PingApi', PingApiImpl)
  .service('TaskApi', TaskApi)
  .factory('TaskActions', TaskActions)
  .factory('TaskStatsActions', TaskStatsActionsImpl)
  .service('TemplateApi', TemplateApi)
  .factory('TemplateActions', TemplateActions)
  .factory('TaskTemplates', TaskTemplates)
  .service('TaskTemplateApi', TaskTemplateApiImpl)
  .factory('TaskTemplateActions', TaskTemplateActionsImpl)
  .service('TemplatePermitApi', TemplatePermitApi)
  .factory('TemplatePermitActions', TemplatePermitActions)
  .service('TemplateRevisionApi', TemplateRevisionApi)
  .factory('TemplateRevisionActions', TemplateRevisionActions)
  .service('UserApi', UserApi)
  .factory('UserActions', UserActions)
  .run(ScopeEventAdapter).name;
