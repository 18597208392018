import angular from 'angular';
import { DefaultErrorMessages } from 'components/utils/error-messages';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'InboxCtrl',
    function (
      $q,
      $scope,
      $state,
      OrganizationService,
      SecurityService,
      SessionService,
      UserSettingsService,
      ToastService,
    ) {
      const ctrl = this;
      $scope.initialized = false;

      ctrl.$onInit = () => {
        SessionService.setDefaultHomepage('inbox');

        if (!initUser()) {
          return;
        }

        initOrganization($scope.user);

        $state.go('myWork');
      };

      /**
       * Initializes user and redirects to login if user is not fully authenticated
       */
      function initUser() {
        if (SecurityService.isAuthenticatedFully()) {
          $scope.user = SessionService.getUser();

          return true;
        } else {
          $state.go('login', { url: $state.href('myWork') });

          return false;
        }
      }

      function initOrganization(user) {
        return OrganizationService.getById(SecurityService.getSelectedOrganizationIdByUser(user)).then(
          organization => {
            $scope.organization = organization;

            $scope.initialized = true;
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading the inbox`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
            $state.go('dashboard');

            return $q.reject();
          },
        );
      }

      $scope.$on('$destroy', () => {
        // Save the table view properties
        if (!$state.is('logout')) {
          UserSettingsService.updateDefaultHomepage(SessionService.getDefaultHomepage());
        }
      });
    },
  );
