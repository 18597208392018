import { Widget } from '@process-street/subgrade/process';
import create from 'zustand';

type State = {
  outputWidgetsClickedDuringAiTask: Set<Widget['id']>;
};

type Effects = {
  addOutputWidgetClickedDuringAiTask: (widgetId: Widget['id']) => void;
  removeOutputWidgetClickedDuringAiTask: (widgetId: Widget['id']) => void;
};

type Store = State & Effects;

export const useWorkflowRunStore = create<Store>(set => ({
  outputWidgetsClickedDuringAiTask: new Set(),
  addOutputWidgetClickedDuringAiTask: widgetId =>
    set(state => {
      const newSet = new Set(state.outputWidgetsClickedDuringAiTask);
      newSet.add(widgetId);
      return { outputWidgetsClickedDuringAiTask: newSet };
    }),
  removeOutputWidgetClickedDuringAiTask: widgetId =>
    set(state => {
      const newSet = new Set(state.outputWidgetsClickedDuringAiTask);
      newSet.delete(widgetId);
      return { outputWidgetsClickedDuringAiTask: newSet };
    }),
}));
