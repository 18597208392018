import { useInjector } from 'components/injection-provider';
import {
  isPublishDraftMutation,
  PublishDraftMutation,
} from 'features/template-revisions/query-builder/publish-draft-mutation';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import {
  TEMPLATE_REVISION_DELETE_BY_ID,
  TEMPLATE_REVISION_FINISH_BY_ID,
} from 'reducers/template-revision/template-revision.actions';
import { makeMutationListener } from 'services/react-query';
import { DiscardDraftMutation, isDiscardDraftMutation } from 'features/template/query-builder';

const KEYS = [PublishDraftMutation.key, DiscardDraftMutation.key];
/**
 * This keeps track of react query mutations from the focus bar and updates the redux store
 */
export function useReactQueryReduxSync() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mutationCache = queryClient.getMutationCache();
  const { $stateParams } = useInjector('$stateParams');

  const { id: templateId } = $stateParams;

  React.useEffect(() => {
    const unsubscribe = mutationCache.subscribe(
      makeMutationListener({
        keys: KEYS,
        onSuccess: mutation => {
          if (!mutation.options.mutationKey) return;

          if (isDiscardDraftMutation(mutation)) {
            const templateRevision = mutation.state.data;
            dispatch({ type: TEMPLATE_REVISION_DELETE_BY_ID, payload: templateRevision });
          }

          if (isPublishDraftMutation(mutation)) {
            const templateRevision = mutation.state.data;
            dispatch({ type: TEMPLATE_REVISION_FINISH_BY_ID, payload: templateRevision });
          }
        },
      }),
    );

    return () => {
      unsubscribe();
    };
  }, [dispatch, mutationCache, templateId]);
}
