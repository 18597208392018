import uiRouter, { StateProvider, UrlRouterProvider } from '@uirouter/angularjs';
import angular from 'angular';
import { OrganizationSpecificSso } from 'components/sso/angular/organization-specific-sso.component';
import { OrganizationSpecificSsoPage } from 'components/sso/OrganizationSpecificSsoPage/OrganizationSpecificSsoPage';
import { reactToAngular } from 'components/react-root';

export const SsoModule = angular
  .module('sso', [uiRouter])
  .component('psOrganizationSpecificSso', OrganizationSpecificSso)
  .component(
    'psOrganizationSpecificSsoPage',
    reactToAngular(OrganizationSpecificSsoPage, ['onLogin', 'onLoginWithoutSso']),
  )
  // Routes
  .config(($stateProvider: StateProvider, $urlRouterProvider: UrlRouterProvider) => {
    'ngInject';

    $stateProvider.state('organizationSpecificSso', {
      component: 'psOrganizationSpecificSso',
      data: {
        external: true,
        internal: false,
      },
      url: '/login/sso/:connectionName?url',
    });
    $urlRouterProvider.otherwise('/');
  }).name;
