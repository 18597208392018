import { selectedOrganizationMembershipWithUsersNoSystemGroupsSelector } from 'reducers/group/group.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { connectController } from 'reducers/util';
import { TaskTemplatePermitsSelector } from 'components/task-permission/store/task-template-permit.selector';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import { TaskPermissionRuleSelector } from 'components/task-permission/store/task-permission-rule.selector';
import { getMembershipsSuggestions } from '@process-street/subgrade/util/membership-utils';
import Muid from 'node-muid';
import { uuid } from 'services/uuid';
import templateUrl from './task-permission-selector.component.html';
import './task-permission-selector.scss';
import { isOrganizationMembershipActive } from '@process-street/subgrade/core';

export const TaskPermissionSelectorComponent = {
  bindings: {
    user: '<',
    taskTemplate: '<',
    taskTemplates: '<',
    disabled: '<',
  },
  templateUrl,
  controller: class {
    featureIsAvailable = true;

    MAX_SUGGESTIONS = 5;

    constructor(
      $ngRedux,
      FeatureGateService,
      TaskPermissionRuleActions,
      TaskPermissionRuleService,
      TaskTemplatePermitService,
    ) {
      'ngInject';

      this.FeatureGateService = FeatureGateService;
      this.TaskPermissionRuleService = TaskPermissionRuleService;
      this.TaskTemplatePermitService = TaskTemplatePermitService;

      const mapStateToThis = () => state => {
        const selectedOrganizationId = SessionSelector.getSelectedOrganizationId(state);

        const isBulk = (this.taskTemplates && !!this.taskTemplates.length) || false;
        const taskTemplateId = this.taskTemplate && this.taskTemplate.id;
        let taskTemplateIds = [];
        if (isBulk) {
          taskTemplateIds = this.taskTemplates.map(tt => tt.id);
        } else if (taskTemplateId) {
          taskTemplateIds = [taskTemplateId];
        }

        const taskTemplatePermits = TaskTemplatePermitsSelector.getAllByTaskTemplateIdsNoSystemGroups(taskTemplateIds)(
          state,
        ).filter(permit => permit.taskRead);

        const organizationMemberships = selectedOrganizationMembershipWithUsersNoSystemGroupsSelector(state).filter(
          om => isOrganizationMembershipActive(om),
        );

        const templateRevisionId = isBulk
          ? this.taskTemplates[0].templateRevision.id
          : this.taskTemplate && this.taskTemplate.templateRevision.id;
        const emailAndMembersFieldWidgets = templateRevisionId
          ? WidgetSelector.getAllEmailAndMembersWidgetsByTemplateRevisionId(templateRevisionId)(state)
          : [];

        const widgetsLoaded = templateRevisionId
          ? WidgetSelector.getLoadedStatusByTemplateRevisionId(templateRevisionId)(state)
          : false;

        const possibleRulesMap = templateRevisionId
          ? this._generatePossibleRulesMap(
              emailAndMembersFieldWidgets,
              selectedOrganizationId,
              templateRevisionId,
              isBulk,
            )
          : {};

        const taskTemplateGroupId = this.taskTemplate && this.taskTemplate.group.id;
        let taskTemplateGroupIds = [];
        if (isBulk) {
          taskTemplateGroupIds = this.taskTemplates.map(tt => tt.group.id);
        } else if (taskTemplateGroupId) {
          taskTemplateGroupIds = [taskTemplateGroupId];
        }

        const taskPermissionRules = taskTemplateGroupIds
          ? TaskPermissionRuleSelector.getAllByTaskTemplateGroupIds(taskTemplateGroupIds)(state)
          : [];

        const assignedMemberships = TaskTemplatePermitService.getMembershipsIntersection(
          taskTemplatePermits,
          taskTemplateIds.length,
        );

        const assignedMembershipIds = assignedMemberships.map(om => om.id);

        return {
          assignedMembershipIds,
          emailAndMembersFieldWidgets,
          isBulk,
          organizationMemberships,
          possibleRulesMap,
          selectedOrganizationId,
          taskPermissionRules,
          taskTemplatePermits,
          widgetsLoaded,
        };
      };

      const mapDispatchToThis = () => ({
        upsertRule: TaskPermissionRuleActions.upsert,
        upsertAllRules: TaskPermissionRuleActions.upsertAll,
      });

      const shouldChange = change => change.taskTemplate || change.taskTemplates;

      connectController($ngRedux, mapStateToThis, mapDispatchToThis, shouldChange)(this);
    }

    $onChanges(changes) {
      if (changes.taskTemplate) {
        this.clearSearch();
      }
    }

    getSuggestions(partial) {
      const { assignedMembershipIds, organizationMemberships } = this.state;

      return getMembershipsSuggestions(organizationMemberships, assignedMembershipIds, partial).slice(
        0,
        this.MAX_SUGGESTIONS,
      );
    }

    assign(membership) {
      const { isBulk } = this.state;
      const { taskTemplate, taskTemplates } = this;
      const permitted = true;

      this.TaskTemplatePermitService.upsertPermit(membership, isBulk, taskTemplate, taskTemplates, permitted);
    }

    _generatePossibleRulesMap(emailAndMembersFieldWidgets, selectedOrganizationId, templateRevisionId, isBulk) {
      const rulesMap = {};
      if (isBulk) {
        this.taskTemplates.forEach(tt => {
          rulesMap[tt.group.id] = this.TaskPermissionRuleService.generatePossibleRulesForTaskTemplate(
            emailAndMembersFieldWidgets,
            selectedOrganizationId,
            templateRevisionId,
            tt,
          );
        });
      } else {
        rulesMap[this.taskTemplate.group.id] = this.TaskPermissionRuleService.generatePossibleRulesForTaskTemplate(
          emailAndMembersFieldWidgets,
          selectedOrganizationId,
          templateRevisionId,
          this.taskTemplate,
        );
      }

      return rulesMap;
    }

    _getSelectedTaskTemplatesPossibleRules() {
      if (this.state.isBulk) {
        let rules = [];
        this.taskTemplates.forEach(tt => {
          rules = rules.concat(this.state.possibleRulesMap[tt.group.id] || []);
        });
        return rules;
      } else {
        return this.state.possibleRulesMap[this.taskTemplate.group.id] || [];
      }
    }

    _getRuleDescription(rule) {
      return this.TaskPermissionRuleService.getRuleDescription(
        rule,
        this.state.emailAndMembersFieldWidgets,
        this.state.widgetsLoaded,
      );
    }

    _getRuleTitle(rule) {
      return this.TaskPermissionRuleService.getRuleTitle(
        rule,
        this.state.emailAndMembersFieldWidgets,
        this.state.widgetsLoaded,
      );
    }

    _isRuleMatchingTrimmedPartial = (rule, trimmedPartial) =>
      this._getRuleTitle(rule).toLowerCase().includes(trimmedPartial) ||
      this._getRuleDescription(rule).toLowerCase().includes(trimmedPartial);

    getRulesSuggestions(partial) {
      const trimmedPartial = (partial || '').trim().toLowerCase();

      const existingRules = this.state.taskPermissionRules;
      const allPossibleRules = this._getSelectedTaskTemplatesPossibleRules();
      const allMatchingPossibleRules = allPossibleRules.filter(
        rule =>
          this.TaskPermissionRuleService.isRuleAvailable(existingRules, rule) &&
          this._isRuleMatchingTrimmedPartial(rule, trimmedPartial),
      );

      return this.TaskPermissionRuleService.extractUniqueTypedRules(allMatchingPossibleRules).slice(
        0,
        this.MAX_SUGGESTIONS,
      );
    }

    createRule(rule) {
      const { isBulk, taskPermissionRules } = this.state;

      if (isBulk) {
        const rules = this.taskTemplates
          .map(tt => ({
            ...rule,
            targetTaskTemplateGroup: { id: tt.group.id },
            id: Muid.fromUuid(uuid()),
          }))
          .filter(
            newRule =>
              !taskPermissionRules.find(existingRule =>
                this.TaskPermissionRuleService.areRulesEqual(newRule, existingRule),
              ),
          );

        this.actions.upsertAllRules(this.taskTemplates[0].templateRevision.id, { rules });
      } else {
        this.actions.upsertRule(this.taskTemplate.templateRevision.id, rule);
      }
    }

    clearSearch() {
      this.search = '';
    }

    isRolesTabEnabled = () => this.FeatureGateService.isRoleBasedTaskPermissionsFeatureEnabled(this.user);
  },
};
