import { Box, FormControl, HStack, Switch, Text, Tooltip } from 'components/design/next';
import React, { useEffect, useState } from 'react';
import {
  AutomationInstance,
  AutomationInstanceUtils,
  isDataSetIncomingWebhook,
  isIncomingWebhookInstance,
  isNativeAutomationWithLink,
  isTemplateIncomingWebhook,
  isTemplateSolutionInstance,
} from '@process-street/subgrade/automation';
import { useUpdateTemplateSolutionInstanceMutation } from 'features/automations/query-builder/update-template-solution-instance-mutation';
import { useQueryClient } from 'react-query';
import {
  GetAllIncomingWebhooksByDataSetIdQuery,
  GetAllIncomingWebhooksByTemplateIdQuery,
  GetAllIncomingWebhooksByTemplateIdQueryResponse,
  SolutionInstancesByTemplateIdQuery,
  SolutionInstancesByTemplateIdQueryResponse,
  useUpdateIncomingWebhookMutation,
} from 'features/automations/query-builder';
import { match } from 'ts-pattern';
import {
  GetAllNativeAutomationsQuery,
  UpdateNativeAutomationStatusMutation,
} from 'features/native-automations/query-builder';

export interface AutomationToggleProps {
  automationInstance?: AutomationInstance;
}

enum HelperText {
  Configured = 'Activate automation with this toggle.',
  Activated = 'To edit or delete, toggle automation to “off”.',
  New = 'Automation can not be activated until configured.',
}

function getHelperText({ active, editable }: { active: boolean; editable: boolean }): string {
  if (!editable) {
    return HelperText.New;
  }
  if (active) {
    return HelperText.Activated;
  }
  if (!active) {
    return HelperText.Configured;
  }
  return '';
}

export const AutomationToggle: React.FunctionComponent<React.PropsWithChildren<AutomationToggleProps>> = ({
  automationInstance,
}) => {
  const isEditable = match(automationInstance)
    .when(isTemplateSolutionInstance, ({ configured }) => configured)
    .when(isIncomingWebhookInstance, () => true)
    .when(isNativeAutomationWithLink, () => true)
    .otherwise(() => false);

  const instanceIsEnabled = automationInstance && AutomationInstanceUtils.isEnabled(automationInstance);
  const [isActive, setIsActive] = useState(Boolean(instanceIsEnabled));
  useEffect(() => {
    setIsActive(Boolean(instanceIsEnabled));
  }, [instanceIsEnabled]);

  const queryClient = useQueryClient();
  const updateTSIMutation = useUpdateTemplateSolutionInstanceMutation({
    onSuccess: tsi => {
      if (!tsi) return;

      queryClient.setQueryData<SolutionInstancesByTemplateIdQueryResponse>(
        SolutionInstancesByTemplateIdQuery.getKey({ templateId: tsi.templateId }),
        instances => instances?.map(i => (i.id === tsi.id ? tsi : i)) ?? [tsi],
      );
    },
  });

  const updateWebhookMutation = useUpdateIncomingWebhookMutation({
    onSuccess: webhook => {
      if (!webhook) return;

      match(webhook)
        .when(isTemplateIncomingWebhook, webhook => {
          queryClient.setQueryData<GetAllIncomingWebhooksByTemplateIdQueryResponse>(
            GetAllIncomingWebhooksByTemplateIdQuery.getKey({ templateId: webhook.templateId }),
            current => current?.map(w => (w.id === webhook.id ? webhook : w)) ?? [webhook],
          );
        })
        .when(isDataSetIncomingWebhook, webhook => {
          queryClient.setQueryData<GetAllIncomingWebhooksByTemplateIdQueryResponse>(
            GetAllIncomingWebhooksByDataSetIdQuery.getKey({ dataSetId: webhook.dataSetId }),
            current => current?.map(w => (w.id === webhook.id ? webhook : w)) ?? [webhook],
          );
        })
        .otherwise(() => {});
    },
  });

  const updateNativeAutomationStatusMutation = UpdateNativeAutomationStatusMutation.useMutation({
    onSuccess: () => queryClient.invalidateQueries(GetAllNativeAutomationsQuery.getKey()),
  });

  const toggle = () => {
    if (!automationInstance) return;
    match(automationInstance)
      .when(isTemplateSolutionInstance, tsi => {
        updateTSIMutation.mutate({
          templateId: tsi.templateId,
          solutionInstanceId: tsi.solutionInstanceId,
          enabled: !tsi.enabled,
          configured: tsi.configured,
        });
      })
      .when(isIncomingWebhookInstance, wi => {
        updateWebhookMutation.mutate({
          ...wi,
          status: wi.status === 'Active' ? 'Disabled' : 'Active',
        });
      })
      .when(isNativeAutomationWithLink, ({ automation }) => {
        updateNativeAutomationStatusMutation.mutate({
          nativeAutomationId: automation.id,
          status: automation.status === 'Active' ? 'Disabled' : 'Active',
        });
      })
      .run();
  };

  const handleOnSwitch = () => {
    setIsActive(!isActive);
    toggle();
  };

  return (
    <Box>
      <FormControl as={HStack}>
        <Box>
          <Tooltip
            shouldWrapChildren={true}
            hasArrow={true}
            label={getHelperText({ active: isActive, editable: isEditable })}
            placement="bottom"
          >
            <Switch
              isChecked={isActive}
              onChange={handleOnSwitch}
              isDisabled={!isEditable}
              size="lg"
              name="enabled"
              mb={0}
            />
          </Tooltip>
        </Box>
        <Box>
          <Text fontWeight="normal">{isActive ? 'On' : 'Off'}</Text>
        </Box>
      </FormControl>
    </Box>
  );
};
