import React from 'react';
import styles from '../FormFieldValueRenderer.module.scss';

interface UrlFieldRendererProps {
  url?: string;
  external?: boolean;
}

export const UrlFieldRenderer: React.FunctionComponent<React.PropsWithChildren<UrlFieldRendererProps>> = ({
  url,
  external,
}) => {
  if (!url) {
    return <div />;
  }

  const target = external ? '_blank' : '_self';
  return (
    <a href={`${url}`} target={target} title={`${url}`} rel={external ? 'noopener noreferrer' : ''}>
      <div className={styles.trimmedLabel}>{url}</div>
    </a>
  );
};
