import { WidgetUtils } from '@process-street/subgrade/process';
import angular from 'angular';
import templateUrl from './required-indicator.component.html';
import './required-indicator.scss';

angular.module('frontStreetApp.directives').component('psRequiredIndicator', {
  bindings: {
    formFieldWidget: '<',
    formFieldValue: '<',
    invalidFormFieldMap: '<',
  },
  transclude: true,
  templateUrl,
  controller() {
    const ctrl = this;

    /**
     * TEMP hack to differentiate requirement failures from constraint failures.
     * The current `invalidFormFieldsMap` doesn't differentiate between these two
     * so when the map is set based on constraint failures, the requirement message is shown
     * even if there is a value present
     */
    ctrl.isEmpty = () => {
      return WidgetUtils.isEmpty(ctrl.formFieldWidget, ctrl.formFieldValue);
    };
  },
});
