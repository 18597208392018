import * as React from 'react';
import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { IHeaderParams, RowNode } from '@ag-grid-community/core';
import { getRowId } from 'pages/tasks/helpers';
import { useTasksRowSelectionProvider } from 'pages/tasks/row-selection-provider';
import { TasksTableUtils } from '../tasks-table/tasks-table-utils';

export type SelectionHeaderRendererProps = Partial<IHeaderParams> & {
  checkboxProps?: CheckboxProps;
  nonDataRowCount?: number;
};

export const SelectionHeaderRenderer: React.FC<React.PropsWithChildren<SelectionHeaderRendererProps>> = ({
  api,
  checkboxProps,
  nonDataRowCount = TasksTableUtils.TASKS_TABLE_NON_DATA_ROW_COUNT,
}) => {
  const [selectedNodes] = useTasksRowSelectionProvider();

  const selectedNodesCount = selectedNodes.length;
  const allNodesCount = api?.getDisplayedRowCount() ?? 0;
  const isAllSelected = selectedNodesCount > 0 && selectedNodesCount === allNodesCount - nonDataRowCount;
  const isIndeterminate = selectedNodesCount > 0 && !isAllSelected;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const nodesToRedraw: RowNode[] = [];

    const selectAll = () => {
      api?.forEachNode(node => {
        const row = node.data;
        if (TasksTableUtils.isInboxItemCompleted(row) || TasksTableUtils.isReadOnly(row)) return;

        const id = getRowId(row);
        if (!id) return;

        node.setSelected(true);
        nodesToRedraw.push({ ...node, id } as RowNode);
      });
    };

    const unselectAll = () => {
      selectedNodes.forEach(node => {
        if (!node.data) return;

        const id = getRowId(node.data);
        if (!id) return;

        node.setSelected(false);
        nodesToRedraw.push({ ...node, id } as RowNode);
      });
    };

    if (isIndeterminate) {
      unselectAll();
    } else if (e.target.checked) {
      selectAll();
    } else {
      unselectAll();
    }

    api?.redrawRows({ rowNodes: nodesToRedraw });
  };

  return (
    <Checkbox
      isChecked={isAllSelected}
      isIndeterminate={isIndeterminate}
      colorScheme={isIndeterminate ? 'gray' : 'brand'}
      onChange={handleChange}
      size="lg"
      pl={2}
      sx={{
        '.chakra-checkbox__control': {
          borderWidth: 'thin',
        },
      }}
      {...checkboxProps}
    />
  );
};
