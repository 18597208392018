import { S3File } from '@process-street/subgrade/core';
import { DownloadLink } from 'components/common/preview/DownloadLink';
import React, { SyntheticEvent, useMemo } from 'react';

import commonStyles from '../Preview.module.scss';
import styles from './FilePreview.module.scss';

export interface FilePreviewProps {
  file: S3File;
  caption?: string;
}

export const FilePreview: React.FunctionComponent<React.PropsWithChildren<FilePreviewProps>> = ({ file, caption }) => {
  const trimmedCaption = useMemo(() => (caption ? caption!.trim() : ''), [caption]);

  const clickHandler = (_: SyntheticEvent) => {
    window.open(file.url, '_blank');
  };

  return (
    <figure className={commonStyles.figure}>
      <span title="File Extension Icon" onClick={clickHandler} className={styles.thumbnail}>
        {file.extension ? file.extension : '?'}
      </span>
      <DownloadLink url={file.url} />
      {trimmedCaption && <figcaption className={commonStyles.caption}>{trimmedCaption}</figcaption>}
    </figure>
  );
};
