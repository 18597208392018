import angular from 'angular';
import { DefaultErrorMessages } from 'components/utils/error-messages';

angular.module('frontStreetApp.controllers').controller('TaskCtrl', function ($state, ToastService, TaskService) {
  const ctrl = this;

  ctrl.$onInit = () => {
    const { id } = $state.params;
    if (id) {
      TaskService.getById(id).then(
        task => {
          $state.go('checklist.task', {
            id: task.checklistRevision.checklist.id,
            groupId: task.taskTemplate.group.id,
          });
        },
        () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems loading the task`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });

          $state.go('dashboard');
        },
      );
    } else {
      $state.go('dashboard');
    }
  };
});
