import { Option } from '@process-street/subgrade/core';
import angular from 'angular';
import { templateMigrationStatsService } from 'components/migration/services/template-migration-stats-service';
import { TemplateMigrationSelectors } from 'components/migration/store/template-migration.selector';
import ngRedux from 'ng-redux';
import { ReduxAppState } from 'reducers/types';
import { connectController } from 'reducers/util';
import { TemplateMigrationModalOptions } from './template-migration-modal-options';
import templateUrl from './template-migration-modal.component.html';
import './template-migration-modal.scss';
import { AnalyticsService } from 'components/analytics/analytics.service';

interface InternalState {
  formattedTotal: string;
  percentage: number;
}

export class TemplateMigrationModalController {
  public static $inject = ['$ngRedux'];

  public options: Option<TemplateMigrationModalOptions>;
  private readonly state: InternalState = {
    formattedTotal: '0',
    percentage: templateMigrationStatsService.InitialPercentage,
  };

  constructor(private $ngRedux: ngRedux.INgRedux) {
    const mapStateToThis =
      () =>
      (state: ReduxAppState): InternalState => {
        if (this.options) {
          const templateMigrationState = TemplateMigrationSelectors.templateMigrationState(this.options.template!.id)(
            state,
          );

          let { countMigrated, countTotal } = this.options;
          if (
            templateMigrationState &&
            templateMigrationState.stats &&
            this.options.templateRevisionId === templateMigrationState.templateRevisionId
          ) {
            countMigrated = templateMigrationState.stats.countMigrated || 0;
            countTotal = templateMigrationState.stats.countTotal;
            const percentage = templateMigrationStatsService.getMigrationPercentage(countMigrated, countTotal);
            const formattedTotal = countTotal.toLocaleString();
            return {
              formattedTotal,
              percentage,
            };
          }
        }
        return this.state;
      };
    connectController(this.$ngRedux, mapStateToThis, null)(this);
  }

  public $onChanges = (changes: {
    resolve: angular.IChangesObject<Option<{ modalOptions: TemplateMigrationModalOptions }>>;
  }) => {
    if (changes.resolve) {
      const { currentValue } = changes.resolve;
      this.options = currentValue && currentValue.modalOptions;
    }
  };

  public onButtonClick = (): void => {
    if (this.options) {
      const { template, templateRevisionId } = this.options;

      AnalyticsService.trackEvent('Migration Modal Clicked', {
        'Template Id': template.id,
        'Template Name': template.name,
        'Template Revision Id': templateRevisionId,
      });
    }
    // @ts-expect-error -- -- This is ignored as close is added with bindings
    this.close();
  };
}

export const TemplateMigrationModalComponent: angular.IComponentOptions = {
  bindings: {
    close: '&',
    resolve: '<',
  },
  controller: TemplateMigrationModalController,
  templateUrl,
};
