import { Box, Flex, FlexProps, useColorModeValue as mode, useRadioGroup } from 'components/design/next';
import * as React from 'react';
import { RadioButton } from './RadioButton';
import { PlanInterval } from '@process-street/subgrade/billing';

type Props = {
  planInterval: PlanInterval;
  onIntervalChange: (value: PlanInterval) => void;
} & FlexProps;

export const DurationSwitcher = ({ planInterval, onIntervalChange, ...restProps }: Props) => {
  const { getRadioProps, getRootProps } = useRadioGroup({
    value: planInterval,
    onChange: onIntervalChange,
  });

  return (
    <Box pos="relative">
      <Flex
        display="inline-flex"
        align="center"
        bg={mode('gray.200', 'gray.700')}
        rounded="full"
        {...getRootProps(restProps)}
      >
        <RadioButton {...getRadioProps({ value: PlanInterval.Yearly })}>Yearly (Save 17%!)</RadioButton>
        <RadioButton {...getRadioProps({ value: PlanInterval.Monthly })}>Monthly</RadioButton>
      </Flex>
    </Box>
  );
};
