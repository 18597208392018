import { useMutation, UseMutationOptions } from 'react-query';
import { DeleteTemplateMutation, DeleteTemplateMutationGenerics } from 'features/template/mutation-builder';
import { Muid } from '@process-street/subgrade/core';

export const useDeleteTemplate = (
  options: UseMutationOptions<
    DeleteTemplateMutationGenerics['data'],
    DeleteTemplateMutationGenerics['error'],
    DeleteTemplateMutationGenerics['variables']
  > = {},
) => {
  return useMutation(({ templateId }) => DeleteTemplateMutation.mutationFn(templateId), {
    ...options,
  });
};

type UseDeleteTemplatesParams = {
  templateIds: Muid[];
};

export const useDeleteTemplatesMutation = (
  options: UseMutationOptions<
    DeleteTemplateMutationGenerics['data'][],
    DeleteTemplateMutationGenerics['error'],
    UseDeleteTemplatesParams
  > = {},
) => {
  return useMutation(
    ({ templateIds }) => Promise.all(templateIds.map(templateId => DeleteTemplateMutation.mutationFn(templateId))),
    {
      ...options,
    },
  );
};
