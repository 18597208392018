import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateChecklistDueDateMutation {
  export type Params = {
    id: Muid;
    dueDate: number | null;
  };

  export type Response = void;

  export const key = ['put', 'checklists', 'due'];

  export const getKey = (params: Params) => [...key, params];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/checklists/${params.id}/due`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
