import { DropTargetMonitor } from 'react-dnd';

export type HoverLocation = 'top' | 'bottom' | null;

export function getCursorLocation<Target>({
  monitor,
  element,
}: {
  monitor: DropTargetMonitor<Target>;
  element: HTMLElement;
}): HoverLocation {
  // Determine rectangle on screen
  const hoverBoundingRect = element.getBoundingClientRect();

  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

  // Determine mouse position
  const clientOffset = monitor.getClientOffset();
  if (!clientOffset) return null;

  // Get pixels to the top
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;
  return hoverClientY > hoverMiddleY ? 'bottom' : 'top';
}
