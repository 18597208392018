import * as React from 'react';
import {
  forwardRef,
  InputGroup,
  InputGroupProps,
  Grid,
  GridProps,
  GridItem,
  GridItemProps,
  Text,
} from 'components/design/next';

export const EMAIL_PLACEHOLDER = 'Email, e.g. alfred@wayne-enterprises.com';

export const EmailMetaData = forwardRef<GridProps, 'div'>((props, ref) => {
  return (
    <Grid
      ref={ref}
      templateAreas={`
        "to toVal"
        "cc ccVal"
        "bcc bccVal"
        "subject subjectVal"
      `}
      templateColumns="var(--ps-sizes-18) 1fr"
      gridGap="4"
      {...props}
    />
  );
});

export type RowProps = { row: 'to' | 'cc' | 'bcc' | 'subject' | 'body' };

export const EmailMetaDataRowKey = forwardRef<GridItemProps & RowProps, 'div'>(({ children, row, ...props }, ref) => {
  return (
    <GridItem ref={ref} {...props} area={row} justifySelf="end">
      <Text as="span" fontWeight="medium" color="gray.600">
        {children}
      </Text>
    </GridItem>
  );
});

export const EmailMetaDataRowValue = forwardRef<GridItemProps & RowProps, 'div'>(({ children, row, ...props }, ref) => {
  return (
    <GridItem ref={ref} {...props} area={`${row}Val`}>
      <Text as="span" color="gray.600">
        {children}
      </Text>
    </GridItem>
  );
});

export const EmailMetaDataInputGroup = forwardRef<InputGroupProps, 'div'>((props, ref) => {
  // zIndex to make sure merge tag menu renders over other merge tag menu buttons
  return <InputGroup ref={ref} _focusWithin={{ zIndex: 4 }} {...props} />;
});
