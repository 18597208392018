import React from 'react';
import { Progress } from 'components/design/next';

// components
import { ImagePreview } from 'components/common/preview/ImagePreview';

// models
import { UploadAttachment } from '../CommentAttachment.interface';

import styles from './CommentAttachmentContent.module.scss';

export interface CommentAttachmentContentProps {
  attachment: UploadAttachment;
}

export const CommentAttachmentContent: React.FunctionComponent<
  React.PropsWithChildren<CommentAttachmentContentProps>
> = ({ attachment }) => {
  const isImage =
    attachment && attachment.file && attachment.file.mimeType && attachment.file.mimeType.startsWith('image/');
  const uploadInProgress = attachment && attachment._uploadProgress !== undefined && attachment._uploadProgress >= 0;

  return (
    <div className={styles.commentAttachmentContent}>
      <span>Attachment:</span>
      <a
        target="_blank"
        className={styles.commentAttachmentImageLink}
        href={attachment.file.url}
        rel="noopener noreferrer"
      >
        {attachment.file.originalName}
      </a>

      {isImage && (
        <div className={styles.commentAttachmentContentImageContainer}>
          <ImagePreview src={attachment.file.url} alt={attachment.file.originalName} />
        </div>
      )}
      {uploadInProgress && (
        <Progress value={attachment._uploadProgress} aria-label={`${attachment._uploadProgress}%`} my="2" />
      )}
    </div>
  );
};
