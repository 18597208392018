import { ChecklistConstants, TaskConstants } from '../process';
import { CommentCreatedActivityResponse } from './activity.model';
import { MentionDto } from './mention.model';

const fromCommentCreatedActivity = (activity: CommentCreatedActivityResponse): MentionDto => ({
  id: activity.id,
  content: activity.data.content!,
  createdBy: activity.subject.id,
  createdDate: activity.date,
  taskId: activity.data.taskId,
  taskName: activity.data.taskTemplateName ?? TaskConstants.DefaultTaskName,
  checklistId: activity.data.checklistId,
  checklistName: activity.data.checklistName ?? ChecklistConstants.DefaultChecklistName,
});

export const MentionDtoUtils = {
  fromCommentCreatedActivity,
};
