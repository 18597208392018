import angular from 'angular';
import { urlService } from 'services/url-service';
import { EmailService } from 'services/email-service';

angular.module('frontStreetApp.directives').directive('input', () => ({
  require: '?ngModel',
  link(__scope, __element, attributes, ngModel) {
    if (ngModel && attributes.type === 'email') {
      ngModel.$validators.email = function (value) {
        return ngModel.$isEmpty(value) || EmailService.isValidEmailAddress(value);
      };
    }

    if (ngModel && attributes.type === 'url') {
      ngModel.$validators.url = function (value) {
        return ngModel.$isEmpty(value) || urlService.isValidHttpUrl(value);
      };
    }
  },
}));
