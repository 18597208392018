import { ICellRendererParams } from '@ag-grid-community/core';
import classNames from 'classnames';
import { TemplateValue } from 'components/dashboard/models/grid';
import * as React from 'react';
import styles from './TemplateRenderer.module.scss';
import { useUserCanViewTemplates } from 'components/dashboard/hooks/use-user-can-view-templates';

interface TemplateRendererProps extends Partial<ICellRendererParams> {
  value?: TemplateValue;
}

export const TemplateRenderer: React.FC<React.PropsWithChildren<TemplateRendererProps>> = ({ value }) => {
  const userCanViewTemplates = useUserCanViewTemplates();

  if (!value) {
    return <div />;
  }

  const templateLink = `/workflows/${value.templateId}/dashboard`;

  return (
    <div className={styles.templateRenderer}>
      {userCanViewTemplates && (
        <a href={templateLink} className={classNames('truncate-text', styles.templateName)}>
          {value.templateName}
        </a>
      )}
      {!userCanViewTemplates && value.templateName}
    </div>
  );
};
