export const TemplateRevisionApi = function (TemplateRevisions) {
  'ngInject';

  const getAllTemplateRevisionsByTemplateId = templateId =>
    TemplateRevisions.query({
      where: JSON.stringify({ templateId: { _eq: templateId } }),
      orderBy: '-revision',
    }).$promise;

  const getAllDraftsByFolderIds = folderIds =>
    TemplateRevisions.query({
      where: JSON.stringify({
        'template.folderId': { _in: folderIds },
        'template.status': { _eq: 'Active' },
        'status': { _eq: 'Draft' },
      }),
      include: 'template',
    }).$promise;

  const getAllNewestByTemplateId = templateId =>
    TemplateRevisions.getAllNewestByTemplateId({ id: templateId }).$promise;

  const create = (templateId, baseTemplateRevisionId) =>
    TemplateRevisions.create({ templateId, baseTemplateRevisionId }).$promise;

  const getById = templateRevisionId => TemplateRevisions.getById({ id: templateRevisionId }).$promise;

  const finishById = templateRevisionId => TemplateRevisions.finishById({ id: templateRevisionId }).$promise;

  const deleteById = templateRevisionId => TemplateRevisions.deleteById({ id: templateRevisionId }).$promise;

  return {
    create,
    deleteById,
    finishById,
    getAllTemplateRevisionsByTemplateId,
    getById,
    getAllDraftsByFolderIds,
    getAllNewestByTemplateId,
  };
};
