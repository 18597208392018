import * as React from 'react';

import { DateUtils } from '@process-street/subgrade/util';
import { Badge, BadgeProps } from 'components/design/next';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { dayjs as moment } from '@process-street/subgrade/util';

const ARIA_LABELS = {
  overdue: 'Item is overdue',
  today: 'Item is due today',
  future: 'Item is due in the future',
};
export type DueDateBadgeProps = { date: number } & BadgeProps;

export const DueDateBadge = ({ date, ...props }: DueDateBadgeProps) => {
  const { data: timeZone, isLoading } = useGetCurrentUserInfoQuery({
    select: data => data.user.timeZone,
  });
  const tz: string = isLoading ? moment.tz.guess() : timeZone ?? '';

  const dueMoment = moment(date);
  const now = moment(Date.now());

  const isOverdue = dueMoment.isBefore(now);
  const isToday = dueMoment.diff(now, 'h') <= 24;

  const variant = isOverdue ? 'danger' : isToday ? 'warning' : 'primary';
  const status = isOverdue ? 'overdue' : isToday ? 'today' : 'future';

  return (
    <Badge aria-label={ARIA_LABELS[status]} py="1" px="2" variant={variant} {...props}>
      {DateUtils.formatDateToMonthDay(date, { showYearIfPast: true, timeZone: tz })}
    </Badge>
  );
};
