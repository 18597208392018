import templateUrl from './checkout-business-plan.component.html';
import { AnalyticsService } from 'components/analytics/analytics.service';

export const CheckoutBusinessPlanComponent = {
  bindings: {
    reason: '@',
    reload: '@',
  },
  templateUrl,
  controller: class CheckoutBusinessPlanComponent {
    constructor($state) {
      'ngInject';

      this.$state = $state;
    }

    redirectToBusinessPlan = () => {
      AnalyticsService.trackEvent('check out the business plan clicked');
      this.$state.go('organizationManage.tab', { tab: 'billing' }, { reload: this.reload === 'true' });
    };
  },
};
