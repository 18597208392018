import { fieldChecklistColumnNameMap, useFormResponsesStore } from 'pages/forms/_id/responses/store';
import { useStateParam } from 'hooks/use-state-param';
import { ChecklistGridExportFormBaseFields } from 'components/dashboard/components/checklist/ChecklistGridExportForm';
import { TemplateType } from '@process-street/subgrade/process';
import { ChecklistExportButton } from 'components/dashboard/components/checklist/ChecklistDashboardFilters/filters';
import * as React from 'react';
import { GridHelper } from 'components/dashboard/services/grid-helper';

export const FormResponsesExportButton = () => {
  const store = useFormResponsesStore();
  const formId = useStateParam({ key: 'id' });

  const visibleColumns = store.visibleColumnFields
    // remove "link", "status" etc. columns
    .filter(v => {
      return v.startsWith('formField') || v in fieldChecklistColumnNameMap;
    })
    // transform to backend-compatible column names
    .map(v => (fieldChecklistColumnNameMap as any)[v] ?? v);

  const exportForm: ChecklistGridExportFormBaseFields = {
    conditionalFilter: store.conditionalFilter && [store.conditionalFilter],
    orderMap: GridHelper.getOrderMap(visibleColumns),
    selectedTemplates: [formId!],
    sorting: store.sorting && {
      sortAsc: store.sorting.sortAsc,
      sortColumn: store.sorting.sortBy,
    },
    templateType: TemplateType.Form,
    visibleColumns,
  };

  return <ChecklistExportButton exportForm={exportForm} iconOnly={true} />;
};
