import { Selector } from 'reselect';
import { Muid, Option } from '../../core';
import { ChecklistRevision } from '../../process';
import { BaseReduxState, EntityMap } from '../types';

const getById =
  (revisionId: Muid): Selector<BaseReduxState, Option<ChecklistRevision>> =>
  (state: BaseReduxState) =>
    state.entities.checklistRevision[revisionId];

const getEntityMap: Selector<BaseReduxState, EntityMap<ChecklistRevision>> = (state: BaseReduxState) =>
  state.entities.checklistRevision;

export const BaseChecklistRevisionSelector = {
  getById,
  getEntityMap,
};
