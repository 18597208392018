import { createSelector } from 'reselect';
import { entitiesSelector } from 'reducers/entities/entities.selectors';
import { Entities } from 'reducers/entities/entities';
import { lookupsSelector } from 'reducers/lookups/lookups.selectors';
import { safeEntityMapToArrayByIds } from 'reducers/entities/entities.utils';

const entityMap = state => entitiesSelector(state)[Entities.TASK_TEMPLATE_ASSIGNMENT] || {};
const lookups = state => lookupsSelector(state)[Entities.TASK_TEMPLATE_ASSIGNMENT] || {};

const lookupByTemplateRevisionId = state => lookups(state).byTemplateRevisionId || {};

const getIdsByTemplateRevisionId = templateRevisionId =>
  createSelector(lookupByTemplateRevisionId, lookupMap => lookupMap[templateRevisionId] || []);

const getAllByTemplateRevisionId = templateRevisionId =>
  createSelector([entityMap, getIdsByTemplateRevisionId(templateRevisionId)], (entitiesMap, assignmentIds) =>
    safeEntityMapToArrayByIds(entitiesMap, assignmentIds),
  );

const getAllByTemplateRevisionIdAndTaskTemplateId = (templateRevisionId, taskTemplateId) =>
  createSelector(getAllByTemplateRevisionId(templateRevisionId), assignments =>
    assignments.filter(a => a.taskTemplate.id === taskTemplateId),
  );

const getAllByTemplateRevisionIdAndTaskTemplateIds = (templateRevisionId, taskTemplateIds) =>
  createSelector(getAllByTemplateRevisionId(templateRevisionId), assignments =>
    assignments.filter(a => taskTemplateIds.includes(a.taskTemplate.id)),
  );

const getTaskTemplateIdAssignmentsMapByTemplateRevisionId = templateRevisionId =>
  createSelector([entityMap, getIdsByTemplateRevisionId(templateRevisionId)], (entitiesMap, assignmentIds) =>
    assignmentIds.reduce((agg, assignmentId) => {
      const assignment = entitiesMap[assignmentId];

      if (!assignment || assignment._deleted) {
        return agg;
      }

      if (!agg[assignment.taskTemplate.id]) {
        agg[assignment.taskTemplate.id] = [];
      }
      agg[assignment.taskTemplate.id].push(assignment);

      return agg;
    }, {}),
  );

export const TemplateTaskAssignmentSelector = {
  getAllByTemplateRevisionId,
  getAllByTemplateRevisionIdAndTaskTemplateId,
  getAllByTemplateRevisionIdAndTaskTemplateIds,
  getTaskTemplateIdAssignmentsMapByTemplateRevisionId,
};
