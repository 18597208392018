import * as React from 'react';
import { ExtendedComponentsConfig } from 'components/design/BlvdSelect/types';
import { OverflowingUsersIndicator } from 'components/run-checklist/components/RunChecklist/components/OverflowingUsersIndicator';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { Box, BoxProps, HStack, Icon, Text, useToken } from 'components/design/next';
import { components, ActionMeta, createFilter, OnChangeValue, InputActionMeta } from 'react-select';
import { Muid } from '@process-street/subgrade/core';
import { UserOrRuleOption } from './transformer';
import { OptionItem } from './option-item';
import { UserOption } from 'utils/user-option-transformer';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

export interface AddNewPermissionSearchBoxProps {
  options: { label: string; options: UserOrRuleOption[] }[];
  handleAddPermit: (userId: Muid) => void;
  handleAddRule: (ruleId: Muid) => void;
}

export const SHOW_MORE_USER_VALUE = 'show-more-items';

export const AddNewPermissionSearchBox: React.FC<
  React.PropsWithChildren<AddNewPermissionSearchBoxProps & BoxProps>
> = ({ options, handleAddPermit, handleAddRule, ...props }) => {
  const [brand100, white] = useToken('colors', ['brand.100', 'white']);
  const [isShowingMore, setIsShowingMore] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  // HACK react-select runs filterOption when the options change (which is fair)
  // this guard prevents isShowingMore from getting automatically flipped off after `Show More` is clicked
  const didJustClickShowMore = React.useRef(false);

  const handleChange = (value: OnChangeValue<UserOrRuleOption, false>, actionMeta: ActionMeta<UserOrRuleOption>) => {
    if (actionMeta.action === 'select-option' && BlvdSelectHelpers.isOptionType<UserOrRuleOption>(value)) {
      if (value.type === 'User' && value.value !== SHOW_MORE_USER_VALUE) {
        handleAddPermit(value.user.user.id);
        setTimeout(() => {
          setIsOpen(false);
        }, 0);
      } else if (value.type === 'User' && value.value === SHOW_MORE_USER_VALUE) {
        // Fake user option to show the Show more button when needed.
        setIsShowingMore(true);
        // Now in the JUST_CLICKED_SHOW_MORE state
        didJustClickShowMore.current = true;
      } else if (value.type === 'Rule') {
        handleAddRule(value.value);
        setTimeout(() => {
          setIsOpen(false);
        }, 0);
      }
    }
  };

  const newOptions = React.useMemo(() => {
    if (isShowingMore) {
      return options;
    }
    const userOptions = options.find(option => option.label === 'Users')?.options ?? [];

    if (userOptions.length > 3) {
      const firstThreeUsers = options.find(option => option.label === 'Users')?.options.slice(0, 3) ?? [];
      firstThreeUsers.push({
        label: 'Users',
        value: SHOW_MORE_USER_VALUE,
        type: 'User',
        user: {} as UserOption,
      });

      const rules = options.find(option => option.label === 'Roles')?.options ?? [];

      return [
        { label: 'Users', options: firstThreeUsers },
        { label: 'Roles', options: rules },
      ];
    }

    return options;
  }, [options, isShowingMore]);

  const handleSearchChange = (filter: string, _meta: InputActionMeta) => {
    // Once a user types we are no longer in the JUST_CLICKED_SHOW_MORE state
    if (filter !== '' && isShowingMore && didJustClickShowMore.current) {
      didJustClickShowMore.current = false;
    }
    if (filter.trim() === '' && isShowingMore && !didJustClickShowMore.current) {
      setIsShowingMore(false);
    } else if (filter.trim() !== '' && !isShowingMore) {
      setIsShowingMore(true);
    }
  };

  const filterOption = (option: FilterOptionOption<UserOrRuleOption>, filter: string) => {
    const optionsFilter = createFilter({
      ignoreCase: true,
      matchFrom: 'any',
    });

    return optionsFilter(option, filter);
  };

  const selectComponents: ExtendedComponentsConfig<UserOrRuleOption> = {
    Option: OptionItem,
    ValueContainer: CustomValueContainer,
    OverflowingValuesIndicator: OverflowingUsersIndicator,
  };
  return (
    <Box
      {...props}
      sx={{
        '.blvd-select__dummy-input': {
          height: '0',
        },
      }}
    >
      <BlvdSelect
        styles={{
          option: (styles, { isFocused }) => {
            return {
              ...styles,
              backgroundColor: isFocused ? brand100 : white,
            };
          },
        }}
        components={selectComponents}
        options={newOptions}
        menuControls={false}
        searchPlaceholder={'Search user, group or role'}
        value={[]}
        onChange={handleChange}
        menuIsOpen={isOpen}
        filterOption={filterOption}
        closeMenuOnSelect={false}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        fixedSize
        isSearchable
        onInputChange={handleSearchChange}
      />
    </Box>
  );
};

const Placeholder: React.FC<React.PropsWithChildren<unknown>> = () => (
  <HStack color="gray.600" w="65">
    <Icon variant="far" size="4" icon="user-plus" />
    <Text variant="1">Add new permission</Text>
  </HStack>
);

const CustomValueContainer: React.FC<React.PropsWithChildren<any>> = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <Placeholder />
      {React.Children.map(children, child => (child && child.key !== 'placeholder' ? child : null))}
    </components.ValueContainer>
  );
};
