import angular from 'angular';
import { trace } from 'components/trace';
import { TemplateActivityUtils } from './template-activity-service-utils';

angular
  .module('frontStreetApp.services')
  .service(
    'TemplateActivityService',
    function (Activities, ActivityService, SessionService, TemplateService, util, $q) {
      const self = this;
      const currentUser = SessionService.getUser();

      const logger = trace({ name: 'TemplateActivityService' });

      self.interpretSimple = function (activity) {
        return TemplateActivityUtils.interpret(activity, {
          extended: false,
          timeZone: currentUser?.timeZone,
        });
      };

      self.interpretExtended = function (activity) {
        return TemplateActivityUtils.interpret(activity, { extended: true, timeZone: currentUser?.timeZone });
      };

      self.getActivitiesByIdLimitAndOffsetId = function (templateId, limit, offsetId) {
        return Activities.getAll({
          type: 'template',
          templateId,
          offsetId,
          limit,
        }).$promise.then(
          result => {
            logger.info('succeeded to query %d template activity(s)', result.data.length);
            return result;
          },
          response => {
            logger.error('failed to query template activities. Reason: %s', JSON.stringify(response));
            return $q.reject(response);
          },
        );
      };
    },
  );
