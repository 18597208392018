import { Muid } from '@process-street/subgrade/core';
import { ChecklistWidget } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { QueryClient, QueryObserver, QueryObserverOptions, useQuery, UseQueryOptions, QueryKey } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetAllChecklistWidgetsQueryParams = {
  checklistRevisionId?: Muid;
};

export type GetAllChecklistWidgetsQueryResponse = ChecklistWidget[];

const key = ['checklist-revisions', 'checklist-widgets'];
export const GetAllChecklistWidgetsQuery = {
  key,
  getKey: (params: GetAllChecklistWidgetsQueryParams): QueryKey => [...key, params],
  queryFn: ({ checklistRevisionId }: GetAllChecklistWidgetsQueryParams) =>
    axiosService
      .getAxios()
      .get<GetAllChecklistWidgetsQueryResponse>(`/1/checklist-revisions/${checklistRevisionId}/checklist-widgets`)
      .then(res => res.data),
};

export const useGetAllChecklistWidgetsQuery = <Select = GetAllChecklistWidgetsQueryResponse>(
  params: GetAllChecklistWidgetsQueryParams,
  options: UseQueryOptions<GetAllChecklistWidgetsQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetAllChecklistWidgetsQuery.getKey(params),
    () => GetAllChecklistWidgetsQuery.queryFn(params),
    options,
  );
};

export const makeGetAllChecklistWidgetsQueryObserver = ({
  queryClient,
  options,
  ...params
}: GetAllChecklistWidgetsQueryParams & {
  queryClient: QueryClient;
  options: QueryObserverOptions<GetAllChecklistWidgetsQueryResponse, AxiosError>;
}) => {
  return new QueryObserver<GetAllChecklistWidgetsQueryResponse, AxiosError>(queryClient, {
    queryKey: GetAllChecklistWidgetsQuery.getKey(params),
    queryFn: () => GetAllChecklistWidgetsQuery.queryFn(params),
    ...options,
  });
};
