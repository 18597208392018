import { DynamicAssignment, DynamicAssignmentType } from 'pages/templates/_id/models/assignments';
import * as React from 'react';
import { Box, Flex, Icon, Text, ListItem, Tooltip, HStack, ThemeTypings, TextProps } from 'components/design/next';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { useMatch } from '@process-street/adapters/navigation';

type IconMap = {
  [key in DynamicAssignmentType]: {
    icon: IconName;
    color: ThemeTypings['colors'];
    disabledColor: ThemeTypings['colors'];
  };
};
const iconMap: IconMap = {
  emailField: {
    icon: 'envelope',
    color: 'brand.500',
    disabledColor: 'brand.300',
  },
  membersField: {
    icon: 'users',
    color: 'green.500',
    disabledColor: 'green.300',
  },
  workflowRunner: {
    icon: 'play',
    color: 'indigo.500',
    disabledColor: 'indigo.300',
  },
};

export const DynamicAssignmentItem = ({
  item,
  onClick,
  titleProps,
  descriptionProps,
}: {
  item: DynamicAssignment;
  onClick: (assignment: DynamicAssignment) => void;
  titleProps?: Partial<TextProps>;
  descriptionProps?: Partial<TextProps>;
}) => {
  const isEditorV2 = Boolean(useMatch('templateV2'));
  const { icon, color, disabledColor } = iconMap[item.assignmentType];

  const { disabled } = item;

  const handleClick = () => {
    if (!disabled) {
      onClick(item);
    }
  };

  return (
    <ListItem
      role="button"
      px={4}
      py={2}
      _hover={{
        backgroundColor: disabled ? 'white' : 'gray.100',
      }}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      onClick={handleClick}
    >
      <Tooltip label={item.tooltip}>
        <HStack>
          <Flex
            backgroundColor={disabled ? disabledColor : color}
            color="white"
            borderRadius="100%"
            minWidth={6}
            minHeight={6}
            h={6}
            w={6}
            alignItems="center"
            justifyContent="center"
          >
            <Icon icon={icon} variant="fas" size="3" />
          </Flex>

          <Box pl={1} maxW={78}>
            <Text
              variant="-1"
              fontWeight="md"
              fontSize={isEditorV2 ? 'xs' : 'inherit'}
              color={disabled ? 'gray.400' : 'gray.600'}
              noOfLines={1}
              {...titleProps}
            >
              {item.title}
            </Text>
            <Text variant="-2" color={disabled ? 'gray.400' : 'gray.500'} {...descriptionProps}>
              {item.description}
            </Text>
          </Box>
        </HStack>
      </Tooltip>
    </ListItem>
  );
};
