import { createSelector, Selector } from 'reselect';
import { Plan } from '../../billing';
import { Muid, Option, Organization, User } from '../../core';
import { EntityMap, LightReduxState } from '../types';
import { BaseOrganizationSelector } from './organization.selectors';
import { BasePlanSelector } from './plan.selectors';

const getCurrentUser: Selector<LightReduxState, Option<User>> = (state: LightReduxState): Option<User> =>
  state.session.user ? state.session.user : undefined;

const getCurrentUserId: Selector<LightReduxState, Option<Muid>> = (state: LightReduxState): Option<Muid> => {
  const currentUser = getCurrentUser(state);
  return currentUser ? currentUser.id : undefined;
};

const getSelectedOrganizationId: Selector<LightReduxState, Option<Muid>> = (state: LightReduxState): Option<Muid> =>
  state.session.selectedOrganizationId ? state.session.selectedOrganizationId : undefined;

const getSelectedOrganization: Selector<LightReduxState, Option<Organization>> = createSelector(
  [getSelectedOrganizationId, BaseOrganizationSelector.getEntityMap],
  (selectedOrganizationId: Option<Muid>, organizations: EntityMap<Organization>) =>
    selectedOrganizationId ? organizations[selectedOrganizationId] : undefined,
);

const getCurrentPlan: Selector<LightReduxState, Option<Plan>> = createSelector<
  LightReduxState,
  Option<Organization>,
  EntityMap<Plan>,
  Option<Plan>
>([getSelectedOrganization, BasePlanSelector.getEntityMap], (org: Option<Organization>, plans: EntityMap<Plan>) =>
  org ? plans[org.subscription.plan.id] : undefined,
);

export const BaseSessionSelector = {
  getCurrentUser,
  getCurrentUserId,
  getCurrentPlan,
  getSelectedOrganization,
  getSelectedOrganizationId,
};
