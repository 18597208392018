import { isValidFullUrl } from '../process';

const IFRAME_URL_REGEX = /<iframe.*src="(.*?)"/i;

function isUrlHttps(url: string): boolean {
  return url.substr(0, 8) === 'https://';
}

function isUrlValid(url: string): boolean {
  return isUrlHttps(url) && isValidFullUrl(url);
}

function extractUrl(url: string): string {
  const matches = IFRAME_URL_REGEX.exec(url);
  return matches ? matches[1] : url;
}

function extractDomain(url: string): string {
  return new URL(url).hostname;
}

export const EmbedUtils = {
  isUrlHttps,
  isUrlValid,
  extractUrl,
  extractDomain,
};
