import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';
import { getAvatar } from 'components/common/Avatar';
import {
  Avatar,
  AvatarBadge,
  HStack,
  IconButton,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
} from 'components/design/next';
import * as React from 'react';
import { match, P } from 'ts-pattern';

export type AssigneeListButton = {
  assignedMemberships: OrganizationMembershipWithUser[];
};

export const AssigneeListButton: React.FC<React.PropsWithChildren<AssigneeListButton>> = ({ assignedMemberships }) => {
  const totalAssigned = assignedMemberships.length;

  const [lastUser] = [...assignedMemberships]
    .slice()
    .sort((a, b) => a.user.username.toLowerCase().localeCompare(b.user.username.toLowerCase()));

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="Assignees"
          icon={
            <Avatar
              size="xs"
              bg="gray.300"
              {...match(lastUser?.user)
                .with({ avatarUrl: P.string }, { avatarFile: { id: P.string } }, { username: P.string }, user => {
                  const avatar = getAvatar(user);
                  return {
                    src: avatar.url,
                    name: user.username,
                  };
                })
                .otherwise(() => ({}))}
            >
              {totalAssigned > 1 && (
                <AvatarBadge fontSize="8px" h="1em" minW="1em" px="0.5" bg="gray.400" border="none">
                  {totalAssigned}
                </AvatarBadge>
              )}
            </Avatar>
          }
          {...{
            iconSpacing: '0',
            variant: 'ghost',
            size: 'sm',
            borderWidth: 'px',
            borderColor: 'gray.300',
            px: '6px',
            justifyContent: 'center',
          }}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader>
          <Text color="gray.700" fontWeight="medium">
            Assignees
          </Text>

          <PopoverCloseButton color="gray.500" />
        </PopoverHeader>
        <PopoverArrow />
        <PopoverBody pb="4">
          <VStack as={List} alignItems="flex-start" w="full" mb="0" spacing="4" p="0">
            {assignedMemberships.map(assignee => (
              <AssignmentItem organizationMembership={assignee} key={assignee.id} />
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

type AssignmentItemProps = {
  organizationMembership: OrganizationMembershipWithUser;
};

const AssignmentItem: React.FC<React.PropsWithChildren<AssignmentItemProps>> = ({ organizationMembership }) => {
  const { user } = organizationMembership;
  const avatar = getAvatar(organizationMembership.user);

  return (
    <HStack as={ListItem} w="full" p="0">
      <Avatar src={avatar.url} name={user.username} />
      <VStack alignItems="start" w="full">
        <Text fontWeight="medium" fontSize="md">
          {user.username}
        </Text>
        <Text fontSize="sm" color="gray.400">
          {isGroupUser(user) ? 'Group' : user.email}
        </Text>
      </VStack>
    </HStack>
  );
};
