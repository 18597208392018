import { IntercomService } from 'services/interop/intercom-service';
import { HubSpotService } from 'features/hub-spot/hub-spot-service';

type Value = string | number | boolean | undefined;

function trackEvent(action: string, properties: Record<string, Value> = {}) {
  let propertiesMap: Record<string, Value> = {};
  if (Array.isArray(properties)) {
    for (let i = 0; i < properties.length; i += 2) {
      propertiesMap[properties[i]] = properties[i + 1];
    }
  } else {
    propertiesMap = properties;
  }
  IntercomService.trackEvent(action, propertiesMap);
  HubSpotService.trackCustomBehavioralEvent(action, convertValuesToString(propertiesMap));
}

function convertValuesToString(properties: Record<string, Value>): Record<string, string> {
  const convertedProperties: Record<string, string> = {};
  for (const [key, value] of Object.entries(properties)) {
    if (value !== undefined) {
      convertedProperties[key] = String(value);
    }
  }
  return convertedProperties;
}

export const AnalyticsService = {
  trackEvent,
};
export type IAnalyticsService = typeof AnalyticsService;
