import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, QueryKey } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetUnreadCommentsCountQueryResponse = { count: number };

export const GetUnreadCommentsCountQuery = {
  key: ['comment-inbox', 'unread-count'],
  getKey: (): QueryKey => [...GetUnreadCommentsCountQuery.key],
  queryFn: () =>
    axiosService
      .getAxios()
      .get<GetUnreadCommentsCountQueryResponse>(`/1/comment-inbox/comments/unread-count`)
      .then(res => res.data),
};

export const useGetUnreadCommentsCountQuery = <Select = GetUnreadCommentsCountQueryResponse>(
  options: UseQueryOptions<GetUnreadCommentsCountQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetUnreadCommentsCountQuery.getKey(), () => GetUnreadCommentsCountQuery.queryFn(), options);
};
