import { Identifiable, Muid, Option } from '@process-street/subgrade/core';
import { LookupMap, RequestStage } from '@process-street/subgrade/redux/types';
import cloneDeep from 'lodash.clonedeep';
import { AnyAction, Reducer } from 'redux';
import {
  OptimisticCreateEvent,
  OptimisticDeleteEvent,
  OptimisticEvent,
  OptimisticEventType,
  OptimisticUpdate,
} from '../model';

type OptimisticLookupRelevantEvent<T extends Identifiable> = OptimisticCreateEvent<T> | OptimisticDeleteEvent<T>;

export function withOptimisticLookupsReducer<T extends Identifiable>(
  entitiesSelector: (actions: OptimisticUpdate) => Array<OptimisticEvent<T>>,
  keySelector: (fiedlValue: T, action: AnyAction) => Muid,
  reducer: Reducer<LookupMap, AnyAction>,
  ...supportedActions: string[]
): Reducer<LookupMap, AnyAction> {
  if (supportedActions.length === 0) {
    throw new Error('Specify supported core action types');
  }
  const supportedActionSet = new Set(
    supportedActions
      .map(coreType => coreType + RequestStage.Request)
      .concat(supportedActions.map(coreType => coreType + RequestStage.Success))
      .concat(supportedActions.map(coreType => coreType + RequestStage.Failure)),
  );
  return (state: Option<LookupMap> = {}, action: AnyAction) => {
    if (supportedActionSet.has(action.type)) {
      if (action.stage === RequestStage.Success && !action.meta.optimistic.rollbackOnSuccess) {
        return reducer(state, action);
      }
      const update: OptimisticUpdate =
        action.stage === RequestStage.Request ? action.meta.optimistic.commit : action.meta.optimistic.rollback;

      const relevantEvents: Array<OptimisticLookupRelevantEvent<T>> = (entitiesSelector(update) || []).filter(
        (event): event is OptimisticCreateEvent<T> | OptimisticDeleteEvent<T> =>
          event.eventType !== OptimisticEventType.Update,
      );

      if (relevantEvents.length === 0) {
        return reducer(state, action);
      }

      const updatedState = relevantEvents.reduce(
        (agg: LookupMap, event: OptimisticCreateEvent<T> | OptimisticDeleteEvent<T>) => {
          const { id } = event.entity;
          const key = keySelector(event.entity, action);
          if (agg[key]) {
            if (event.eventType === OptimisticEventType.Create) {
              agg[key].push(id);
            } else if (event.eventType === OptimisticEventType.Delete) {
              const elIndex = agg[key].indexOf(id);
              if (elIndex > -1) {
                agg[key].splice(elIndex, 1);
              }
            }
          } else if (event.eventType === OptimisticEventType.Create) {
            agg[key] = [id];
          }
          return agg;
        },
        cloneDeep(state),
      );

      return reducer(updatedState, action);
    }
    return reducer(state, action);
  };
}
