import { MergeTagsConstants } from '@process-street/subgrade/form';
import { escapeHtml } from '@process-street/subgrade/util';
import { generatePatternByKey } from './generate-pattern-by-key';

export function replaceInTextSpansInPlace(
  textSpans: HTMLSpanElement[],
  key: string,
  replacement: string = escapeHtml(key),
) {
  textSpans.forEach(textSpan => {
    const pattern = generatePatternByKey(MergeTagsConstants.Mode.HTML, key);
    textSpan.innerHTML = textSpan.innerHTML.replace(pattern, replacement);
  });
}
