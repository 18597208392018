import { TinyMCEEditor } from 'app/features/rich-text';

export namespace TextContentMachineHelpers {
  /**
   * Recursively finds the first text node within the given node.
   * @param {Node} node - The node to search within.
   * @returns {Node | null} - The first text node found, or null if no text node is found.
   */
  const findFirstTextNode = (node: Node): Node | null => {
    if (!node) return null;

    // Check if the current node is a text node
    if (node.nodeType === Node.TEXT_NODE) {
      return node;
    }

    // Recursively check the child nodes
    for (const child of node.childNodes) {
      const textNode = findFirstTextNode(child);
      if (textNode) return textNode; // Return the first found text node
    }

    return null; // No text node found
  };

  /**
   * Recursively finds the last text node within the given node.
   * @param {Node} node - The node to search within.
   * @returns {Node | null} - The last text node found, or null if no text node is found.
   */
  const findLastTextNode = (node: Node): Node | null => {
    if (!node) return null;

    // If the current node is a text node, return it
    if (node.nodeType === Node.TEXT_NODE) {
      return node;
    }

    // Traverse child nodes in reverse order to find the last text node
    for (let i = node.childNodes.length - 1; i >= 0; i--) {
      const child = node.childNodes[i];
      const textNode = findLastTextNode(child);
      if (textNode) return textNode; // Return the first found text node (from the end)
    }

    return null; // No text node found
  };

  /**
   * Places the caret at the start of the content within the TinyMCE editor.
   * Optionally, a caret position within the first text node can be specified.
   */
  export const placeCaretAtStart = (editor: TinyMCEEditor, caretPosition?: number) => {
    if (!editor) return;

    const body = editor.getBody(); // Get the editor's content body
    const firstNode = body?.firstChild; // Get the first child of the body

    if (!firstNode) {
      console.error('firstNode not found');
      editor.focus();
      return;
    }

    const textNode = findFirstTextNode(firstNode);

    if (!textNode) {
      console.error('textNode is not found.');
      editor.focus();
      return;
    }

    editor.focus();

    const caretOffset =
      caretPosition && textNode.textContent ? Math.min(caretPosition, textNode.textContent.length - 1) : 0;
    // Create and configure a Range object
    const range = editor.getDoc().createRange();
    range.setStart(textNode, caretOffset); // Set the start of the range at the specified offset
    range.collapse(true); // Collapse the range to a single point (the caret position)

    const selection = editor.selection.getSel(); // Get the current selection object
    selection?.removeAllRanges(); // Remove all existing ranges
    selection?.addRange(range); // Add the new range, placing the caret
  };

  /**
   * Places the caret at the end of the content within the TinyMCE editor.
   * Optionally, caret position within the last text node.
   * @param {TinyMCEEditor} editor - The TinyMCE editor instance.
   * @param {number} [caretPosition] - Optional caret offset within the last text node.
   */
  export const placeCaretAtEnd = (editor: TinyMCEEditor, caretPosition?: number) => {
    if (!editor) return;

    const body = editor.getBody();
    const lastNode = body?.lastChild;

    if (!lastNode) {
      console.warn('Editor content is empty');
      editor.focus();
      return;
    }

    const textNode = findLastTextNode(lastNode);

    if (!textNode) {
      console.warn('Editor content is empty');
      editor.focus();
      return;
    }

    // Move the caret to the end
    editor.focus();

    const caretOffset =
      caretPosition && textNode.textContent ? Math.min(caretPosition, textNode.textContent.length - 1) : 0;
    // Create and configure a Range object
    const range = editor.getDoc().createRange();
    range.setStart(textNode, caretOffset); // Set the start of the range at the specified offset
    range.collapse(true); // Collapse the range to a single point (the caret position)

    const selection = editor.selection.getSel(); // Get the current selection object
    selection?.removeAllRanges(); // Remove all existing ranges
    selection?.addRange(range); // Add the new range, placing the caret
  };
}
