import TurndownService from 'turndown';
import MarkdownIt from 'markdown-it';
import emoji from 'markdown-it-emoji';
import mila from 'markdown-it-link-attributes';
import { escapeHtml } from 'markdown-it/lib/common/utils';

const turndownService = new TurndownService({
  headingStyle: 'setext',
  codeBlockStyle: 'fenced',
});
turndownService.addRule('strikethrough', {
  filter: ['del', 's'],
  replacement: content => '~~' + content + '~~',
});
turndownService.addRule('preformatted', {
  filter: 'pre',
  replacement: content => '```\n' + content + '\n```',
});

const markdownParser = new MarkdownIt({
  html: false,
  breaks: true,
  linkify: true,
  xhtmlOut: true,
  highlight: str => {
    // skip default <code> tag inside <pre> as TinyMCE doesn't use it in preformatted paragraphs
    const endingNewineRemoved = str.replace(/\n$/m, '');
    return `<pre>${escapeHtml(endingNewineRemoved)}</pre>`;
  },
})
  .use(emoji)
  .use(mila, {
    attrs: {
      target: '_blank',
    },
  });

export const TextAreaRichEditorHelper = {
  html2markdown: (content: string) => turndownService.turndown(content),
  markdown2html: (md: string) => markdownParser.render(md),
};
