import * as React from 'react';
import { Box, HStack, Skeleton, SkeletonCircle, VStack } from 'components/design/next';

const SkeletonItem = () => (
  <HStack
    w="full"
    spacing="3"
    borderBottomWidth="thin"
    borderBottomColor="gray.200"
    borderBottomStyle="solid"
    py="3"
    _last={{
      borderBottom: 'none',
    }}
    px="5"
    alignItems="flex-start"
    justifyContent="flex-start"
  >
    <SkeletonCircle flexBasis="8" w="8" h="8" minW="8" />

    <VStack w="full" alignItems="flex-start" justifyContent="center">
      <Skeleton h="3" w="full" maxW="100" />
      <Skeleton h="2" w="full" maxW="60" />
    </VStack>
  </HStack>
);

export const LoadingState = () => {
  return (
    <Box w="full" data-testid="loading-state">
      {Array.from({ length: 5 }).map((_, i) => (
        <SkeletonItem key={i} />
      ))}
    </Box>
  );
};
