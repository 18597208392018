import { FormFieldWidget, TaskTemplate } from '@process-street/subgrade/process';
import { useInputWidgetAiTasksMap } from 'features/native-automations/hooks/use-input-widget-ai-tasks-map';
import { useOutputWidgetAiTasksMap } from 'features/native-automations/hooks/use-output-widget-ai-tasks-map';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';

interface Result {
  inputsTo: TaskTemplate[];
  outputsFrom: TaskTemplate[];
}

export function useAssociatedAiTaskTemplates({ widget }: { widget: FormFieldWidget }): Result {
  // Race condition with optimistic widgets and tmpl rev id
  const templateRevisionId = widget.templateRevision?.id;
  const outputFromAiTasks = useOutputWidgetAiTasksMap({ templateRevisionId })[widget.id];
  const outputTaskTemplateIds = outputFromAiTasks?.map(aiTaskTemplate => aiTaskTemplate.taskTemplateId) ?? [];

  const inputWidgetAiTasksMap = useInputWidgetAiTasksMap({ templateRevisionId });
  const inputToAiTasks = inputWidgetAiTasksMap[widget.id] ?? [];
  const inputTaskTemplateIds = inputToAiTasks?.map(aiTaskTemplate => aiTaskTemplate.taskTemplateId) ?? [];

  const taskTemplateQuery = useTaskTemplatesByTemplateRevisionIdQuery(
    { templateRevisionId },
    {
      enabled: inputTaskTemplateIds.length > 0 || outputTaskTemplateIds.length > 0,
      select: taskTemplates =>
        taskTemplates.reduce(
          (acc, taskTemplate) => {
            if (inputTaskTemplateIds.includes(taskTemplate.id)) {
              acc.inputsTo.push(taskTemplate);
            }
            if (outputTaskTemplateIds.includes(taskTemplate.id)) {
              acc.outputsFrom.push(taskTemplate);
            }
            return acc;
          },
          { inputsTo: [], outputsFrom: [] } as Result,
        ),
    },
  );
  return taskTemplateQuery.data ?? { inputsTo: [], outputsFrom: [] };
}
