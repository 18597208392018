import { isPublicTemplate } from '@process-street/subgrade/process';
import angular from 'angular';
import { generateEmbed, generateShareLinks } from './share-utils';
import { trace } from 'components/trace';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'TemplateShareCtrl',
    function ($scope, MessageBox, modalOptions, SessionService, TemplateService, ToastService) {
      const ctrl = this;
      const logger = trace({ name: 'TemplateShareCtrl' });
      logger.info('loading ctrl');

      ctrl.$onInit = () => {
        $scope.template = modalOptions.template;

        if (modalOptions.steps) {
          $scope.allTaskNamesAreBlank = areAllTaskNamesBlank(modalOptions.steps);
        }

        if (isPublicTemplate($scope.template)) {
          $scope.embed = generateEmbed($scope.template);
          $scope.shareLink = generateShareLinks($scope.template);
        }
      };

      function areAllTaskNamesBlank(steps) {
        return steps.every(step => !step.name);
      }

      $scope.publicize = function (template) {
        if ($scope.allTaskNamesAreBlank) {
          ToastService.openToast({
            status: 'warning',
            title: `We couldn't add the workflow to the public library`,
            description: 'Please ensure the task names are not blank.',
          });
          return;
        }

        function publicize() {
          $scope.publicizing = true;

          TemplateService.updatePublic(template.id, true)
            .then(
              updatedTemplate => {
                logger.info('succeeded to make template public');

                AnalyticsService.trackEvent('template set to public', {
                  'template id': updatedTemplate.id,
                  'template name': updatedTemplate.name,
                });

                $scope.template = updatedTemplate;

                generateEmbed(updatedTemplate);
                generateShareLinks(updatedTemplate);
              },
              response => {
                logger.error('failed to make template public. Reason: %s', JSON.stringify(response));
                ToastService.openToast({
                  status: 'warning',
                  title: `We couldn't add the workflow to the public library`,
                  description: 'Please ensure your email address is verified and try again.',
                });
                $scope.publicizing = false;
              },
            )
            .finally(() => {
              $scope.publicizing = false;
            });
        }

        MessageBox.confirm({
          title: `Publish Workflow: ${template.name}`,
          message:
            '' +
            'This template will be published to the public library. ' +
            '<b>It will be accessible to the general public and indexed by search engines.</b><br>' +
            '<br>' +
            'This action cannot be reversed.',
          okButton: {
            text: 'Publish to Public Library',
            action: publicize,
          },
          cancelButton: {
            text: 'Cancel',
          },
        });
      };

      $scope.shareWith = function (method) {
        AnalyticsService.trackEvent('template shared', {
          method,
        });
      };
    },
  );
