import * as React from 'react';
import { AspectRatio, Box, Image, Text, VStack } from '@chakra-ui/react';
import { Icon } from 'components/design/next';
import {
  ImageContentWidgetMachineActorRef,
  ImageContentWidgetMachineSelectors as Selectors,
} from './image-content-widget-machine';
import { useSelector } from '@xstate/react';
import { RulesEngineTargetSelectors } from '../../form-response-body/rules-engine-machine';

export type ImageContentWidgetProps = {
  actor: ImageContentWidgetMachineActorRef;
};

export const ImageContentWidget: React.FC<React.PropsWithChildren<ImageContentWidgetProps>> = ({ actor }) => {
  const widget = useSelector(actor, Selectors.getWidget);
  const isHiddenByRule = useSelector(actor, RulesEngineTargetSelectors.getIsHiddenByRule);

  if (isHiddenByRule) return null;

  if (!widget.file) {
    return (
      <AspectRatio ratio={16 / 9}>
        <Box
          display="flex"
          cursor="pointer"
          position="relative"
          bgColor="brand.100"
          alignItems="center"
          justifyContent="center"
          p={10}
          w="full"
          maxW="175"
        >
          <Icon color="white" variant="fas" icon="mountain-sun" size="70%" />
        </Box>
      </AspectRatio>
    );
  }
  return (
    <VStack w="full">
      <Image mb="4" src={widget.file.url} alt={widget.caption} maxW="full" h="auto" />;
      {widget.caption && (
        <Text fontStyle="italic" color="gray.500">
          {widget.caption}
        </Text>
      )}
    </VStack>
  );
};
