import { ICellRendererParams } from '@ag-grid-community/core';
import { Box, Tooltip } from '@chakra-ui/react';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import { GridHelper } from 'components/dashboard/services/grid-helper';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

interface MonthDayRendererProps extends Partial<ICellRendererParams> {
  value?: Date;
}

const MonthDaySkeleton = ({ rowIndex }: { rowIndex: number }) => {
  const sizes = ['10', '14', '8', '20', '6'];

  return <Box w={sizes[rowIndex % sizes.length]} h="3" bgColor="gray.100" borderRadius="xs" />;
};

export const MonthDayRenderer: React.FC<React.PropsWithChildren<MonthDayRendererProps>> = ({
  value,
  colDef,
  rowIndex,
  data,
}) => {
  const currentUser = useSelector(SessionSelector.getCurrentUser);

  if (!value && GridHelper.isEmptyStateRow(data)) return <MonthDaySkeleton rowIndex={rowIndex ?? 0} />;

  if (!value || !currentUser) {
    return <div />;
  }

  const cell = DateUtils.formatDateToMonthDay(value, { showYearIfPast: true, timeZone: currentUser.timeZone });
  const tooltip = DateUtils.formatDateTime(value, DateFormat.Default, currentUser.timeZone);
  return (
    <Tooltip label={tooltip} id={`checklist-${rowIndex}-${colDef?.colId ?? 'unknown-month-day-column'}`} hasArrow>
      <div className="truncate-text">{cell}</div>
    </Tooltip>
  );
};
