import { RawParams, StateDeclaration } from '@uirouter/core';
import { AppState } from 'app/app.states.typegen';
import { useInjector } from 'components/injection-provider';
import * as React from 'react';
import { match } from 'ts-pattern';
import { useOnStateChangeSuccess } from './use-on-state-change-success';

// TODO move to adapters
export function useStateParam<Value extends string>({
  key,
  defaultValue,
  stateName,
}: {
  key: string;
  defaultValue?: Value;
  stateName?: AppState;
}): Value | undefined {
  const getValue = React.useCallback(
    (state?: StateDeclaration, params?: RawParams) => {
      return match(stateName)
        .with(undefined, state?.name as AppState, () => params?.[key] as Value | undefined)
        .otherwise(() => undefined);
    },
    [key, stateName],
  );

  const { $stateParams, $state } = useInjector('$stateParams', '$state');
  const [value, setValue] = React.useState<Value | undefined>(
    () => getValue($state.current, $stateParams) ?? defaultValue,
  );

  useOnStateChangeSuccess(
    ({ toParams, toState }) => {
      setValue(getValue(toState, toParams));
    },
    [key],
  );

  return value;
}
