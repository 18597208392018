import { Selector } from 'reselect';
import { Muid, Option } from '../../core';
import { Template } from '../../process';
import { EntityMap, LightReduxState } from '../types';

const getById =
  (templateId: Muid): Selector<LightReduxState, Option<Template>> =>
  (state: LightReduxState): Option<Template> =>
    getEntityMap(state)[templateId];

const getEntityMap: Selector<LightReduxState, EntityMap<Template>> = (state: LightReduxState): EntityMap<Template> =>
  state.entities.templates;

export const BaseTemplateSelector = {
  getById,
  getEntityMap,
};
