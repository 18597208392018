import { AxiosError } from 'axios';
import { axiosService } from 'services/axios-service';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { CompleteLoginUserInfo } from '@process-street/subgrade/core';

export namespace CompleteLoginMutation {
  export type Response = CompleteLoginUserInfo;

  export const key = ['complete-login'];

  export const mutationFn = () =>
    axiosService
      .getAxios()
      .post<Response>(`/1/authentication/complete-login`)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
