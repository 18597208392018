import { Approval, ApprovalStatus } from '@process-street/subgrade/approval-rule/approval.model';
import { Muid, MuidUtils, User } from '@process-street/subgrade/core';
import { Task, TaskStatus, TaskTemplate, TaskTemplateTaskType } from '@process-street/subgrade/process';
import { BaseApprovalSelector } from '@process-street/subgrade/redux/selector';
import ngRedux from 'ng-redux';

interface ApprovalService {
  generateApproval(
    approvalTask: Task,
    subjectTask: Task,
    status: ApprovalStatus,
    organizationId: Muid,
    user: User,
    comment?: string,
  ): Approval;

  isApprovalTask(taskTemplate: TaskTemplate): boolean;

  resolveUpdatedApproval(
    selectedOrganizationId: Muid,
    approvals: Approval[],
    approvalTask: Task,
    subjectTask: Task,
    status: ApprovalStatus,
    user: User,
    comment?: string,
  ): Approval;
}

class ApprovalServiceImpl implements ApprovalService {
  public static getInstance() {
    return ApprovalServiceImpl.instance;
  }

  private static instance = new ApprovalServiceImpl();

  public generateApproval(
    approvalTask: Task,
    subjectTask: Task,
    status: ApprovalStatus,
    organizationId: Muid,
    user: User,
    comment?: string,
  ): Approval {
    return {
      approvalTaskId: approvalTask.id,
      audit: {
        createdBy: { id: user.id },
        createdDate: Date.now(),
        updatedBy: { id: user.id },
        updatedDate: Date.now(),
      },
      comment: comment || '',
      id: MuidUtils.randomMuid(),
      organizationId,
      reviewedById: user.id,
      status,
      subjectTaskId: subjectTask.id,
    };
  }

  public isApprovalTask(taskTemplate: TaskTemplate): boolean {
    return taskTemplate && taskTemplate.taskType === TaskTemplateTaskType.Approval;
  }

  public resolveUpdatedApproval(
    selectedOrganizationId: Muid,
    approvals: Approval[],
    approvalTask: Task,
    subjectTask: Task,
    status: ApprovalStatus,
    user: User,
    comment?: string,
  ): Approval {
    const existingApproval = approvals.find(
      a => a.approvalTaskId === approvalTask.id && a.subjectTaskId === subjectTask.id,
    );

    if (existingApproval) {
      return {
        ...existingApproval,
        comment: comment || '',
        status,
      };
    } else {
      return this.generateApproval(approvalTask, subjectTask, status, selectedOrganizationId, user, comment);
    }
  }

  public getOptmisticTaskStatus({
    task,
    approvals,
    $ngRedux,
  }: {
    task: Task;
    approvals: Approval[];
    $ngRedux: ngRedux.INgRedux;
  }) {
    const anyRejected = approvals.some(a => a.status === ApprovalStatus.Rejected);

    if (anyRejected) {
      return TaskStatus.NotCompleted;
    }

    const reduxState = $ngRedux.getState();
    const approvalTaskGroupsMap = BaseApprovalSelector.getApprovalTaskGroupsMapByApprovalTaskId(task.id)(reduxState);

    if (approvalTaskGroupsMap) {
      const anyNotSubmitted = approvalTaskGroupsMap.notSubmittedTasks.length > 0;
      if (anyNotSubmitted) {
        return TaskStatus.NotCompleted;
      }

      // If we had any rejected approval we would finish earlier
      const approvedSubjectTaskIds = approvals.map(approval => approval.subjectTaskId);
      const anyAwaiting = approvalTaskGroupsMap.awaitingTasks.some(task => !approvedSubjectTaskIds.includes(task.id));
      const anyNotPermitted = BaseApprovalSelector.areNotPermittedTasksExistByApprovalTaskId(task.id)(reduxState);

      return anyAwaiting || anyNotPermitted ? TaskStatus.NotCompleted : TaskStatus.Completed;
    } else {
      return TaskStatus.NotCompleted;
    }
  }
}

export const approvalService = ApprovalServiceImpl.getInstance();
