import { match, P } from 'ts-pattern';
import { useCopyToClipboard } from 'react-use';

import { abbreviateForTitle } from '@process-street/subgrade/util';
import { Template } from '@process-street/subgrade/process';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useInjector } from 'components/injection-provider';
import { AppModalQueryParam } from 'app/app.constants';

type Props = {
  templateId: Template['id'];
  selectedVariables?: string[] | null;
};
export const useCopyRunLink = ({ templateId, selectedVariables }: Props) => {
  const { $state } = useInjector('$state');
  const query = useGetTemplateQuery({ templateId });

  const title = match(query)
    .with({ status: 'success', data: P.not(P.nullish) }, ({ data }) => abbreviateForTitle(data.name))
    .otherwise(() => '');

  const params = new URLSearchParams();
  if (Array.isArray(selectedVariables)) {
    selectedVariables.forEach(variable => params.append(variable, 'XXX'));
  }

  const queryParams = params.toString() ? `?${params.toString()}` : '';

  const path = $state.href(
    'checklistRun',
    {
      templateId,
      title: title && `${title}-`,
      [AppModalQueryParam.Modal]: undefined,
    },
    { absolute: true, inherit: false },
  );

  const url = new URL(`${path}${queryParams}`).toString();

  const [_, copyToClipboard] = useCopyToClipboard();

  return {
    url,
    hasQueryParams: Boolean(queryParams),
    copyToClipboard: () => copyToClipboard(url),
  };
};
