import { Groups } from 'reducers/group/group.resource';

export const GroupApi = {
  query: () => Groups.query({ include: 'user' }),

  getGroupStatsByOrganizationId: organizationId => Groups.getGroupStatsByOrganizationId({ organizationId }),

  getGroupByUserId: userId => {
    const userQuery = {
      where: JSON.stringify({ userId: { _eq: userId } }),
    };
    return Groups.query(userQuery);
  },

  getGroupById: groupId => {
    const groupQuery = {
      where: JSON.stringify({ id: { _eq: groupId } }),
      include: 'user',
    };
    return Groups.query(groupQuery);
  },

  createGroup: (organizationId, name) =>
    Groups.createGroup({
      organizationId,
      name,
    }),

  updateGroup: (group, info) => Groups.updateGroup({ id: group.id, name: info.name }),

  updateGroupAvatar: (group, data) => {
    const formData = new FormData();
    formData.append('file', data.files[0]);
    formData.id = group.id;
    return Groups.updateGroupAvatar(formData).then(avatarFile => ({ group, avatarFile }));
  },

  deleteGroup: group => Groups.deleteGroup({ id: group.id }).then(() => group.id),

  addUserToGroup: (group, email) => Groups.addUserToGroup({ id: group.id, email }),

  removeUserFromGroup: (group, membership) =>
    Groups.removeUserFromGroup({
      id: group.id,
      email: membership.user.email,
    }),
};
