import { createSelector, Selector } from 'reselect';
import { Muid, Option } from '../../core';
import { Checklist } from '../../process';
import { BaseReduxState, EntityMap } from '../types';
import { BaseNavigationSelector } from './navigation.selectors';

const getEntityMap: Selector<BaseReduxState, EntityMap<Checklist>> = (state: BaseReduxState) =>
  state.entities.checklists;

const getById =
  (checklistId: Muid): Selector<BaseReduxState, Option<Checklist>> =>
  (state: BaseReduxState) =>
    state.entities.checklists[checklistId];

const getForCurrentChecklistId: Selector<BaseReduxState, Option<Checklist>> = createSelector(
  [BaseNavigationSelector.getCurrentChecklistId, getEntityMap],
  (currentChecklistId: Option<Muid>, entityMap: EntityMap<Checklist>) => {
    if (currentChecklistId) {
      return entityMap[currentChecklistId];
    } else {
      return undefined;
    }
  },
);

export const BaseChecklistSelector = {
  getById,
  getEntityMap,
  getForCurrentChecklistId,
};
