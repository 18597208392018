import * as React from 'react';
import { useState } from 'react';
import { Muid, User } from '@process-street/subgrade/core';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { OrganizationMembershipUtils } from '@process-street/subgrade/core/organization-membership-utils';
import { GridHelper } from 'components/dashboard/services/grid-helper';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { MemberItem } from 'components/common/MemberOption';
import { components, GroupBase, SelectComponentsConfig } from 'react-select';
import { AvatarGroup, AvatarProps, HStack, Text } from 'components/design/next';
import { useUserDeactivationHelpers } from 'features/user/use-user-deactivation-helpers';
import { useInboxDropdownStyles } from 'pages/tasks/components/filter-bar/use-inbox-dropdown-styles';
import sortBy from 'lodash/sortBy';
import { ChakraAvatar } from 'components/design/next/chakra-avatar';
import { DropdownIndicator } from 'pages/tasks/components/filter-bar/selector-common-components';
import { BlvdMembersPicker, BlvdMembersPickerComponents } from 'app/components/design/BlvdMembersPicker';

export interface AssigneeSelectorProps {
  assigneeUserIds?: User['id'][];
  onChange?: (values: User['id'][]) => void;
}

const MAX_AVATARS = 4;
const AVATAR_MARGIN = -2;
const PLACEHOLDER = 'All organization';

export const AssigneeSelector: React.FC<React.PropsWithChildren<AssigneeSelectorProps>> = ({
  assigneeUserIds = [],
  onChange,
}) => {
  const userInfoQuery = useGetCurrentUserInfoQuery();
  const assignableMemberItemsQuery = useGetAllOrganizationMembershipsQuery(
    { organizationId: userInfoQuery.data?.organizationMembership.organization.id },
    {
      select: memberships =>
        memberships
          .filter(OrganizationMembershipUtils.isAssignable)
          .map(GridHelper.convertOrganizationMembershipWithUserToMemberItem),
      enabled: userInfoQuery.isSuccess,
    },
  );

  const memberItems = React.useMemo(() => assignableMemberItemsQuery.data ?? [], [assignableMemberItemsQuery.data]);

  // rearrange member items, when menu is closed
  const [menuCloseCount, setMenuCloseCount] = useState(0);
  const rearrangedMemberItems = React.useMemo(
    () => sortMembers(memberItems, assigneeUserIds, userInfoQuery.data?.user.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we reorder when close count changes
    [menuCloseCount, userInfoQuery.data, memberItems],
  );

  const selectedOmIds = assigneeUserIds
    .map(userId => memberItems.find(member => member.id === userId))
    .filter((m): m is MemberItem => Boolean(m))
    .map(member => member.organizationMembershipId);

  const styles = useInboxDropdownStyles<MemberItem, false>();

  React.useEffect(
    function ensureAssigneeUserIdsPresence() {
      if (!assignableMemberItemsQuery.isSuccess || !userInfoQuery.isSuccess) return;

      const isAnyAssignableMemberNotFound =
        memberItems.filter(member => assigneeUserIds.includes(member.id)).length !== assigneeUserIds.length;

      if (isAnyAssignableMemberNotFound) {
        onChange?.([userInfoQuery.data.user.id]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      assignableMemberItemsQuery.isSuccess,
      assigneeUserIds,
      memberItems,
      userInfoQuery.data?.user?.id,
      userInfoQuery.isSuccess,
    ],
  );

  return (
    <BlvdMembersPicker
      items={rearrangedMemberItems}
      id={selectedOmIds[0]}
      value={rearrangedMemberItems.find(item => item.organizationMembershipId === selectedOmIds[0])}
      onSelect={member => onChange?.([member.id])}
      onMenuClose={() => setMenuCloseCount(menuCloseCount + 1)}
      blvdSelectProps={{
        placeholder: PLACEHOLDER,
        styles,
        components: COMPONENTS,
        fixedSize: false,
        shouldKeepInputOnSelect: true,
      }}
    />
  );
};

const COMPONENTS: SelectComponentsConfig<MemberItem, false, GroupBase<MemberItem>> = {
  Option: BlvdMembersPickerComponents.Option,
  SingleValue: props => {
    const values = props.getValue();
    const isMultipleSelected = values.length > 1;
    // if multiple selected, rely on sorted AvatarGroup inside ValueContainer
    return isMultipleSelected ? null : (
      <components.SingleValue {...props}>
        <MemberItemAvatar memberItem={props.data} />
      </components.SingleValue>
    );
  },
  DropdownIndicator,
  ValueContainer: ({ children, ...props }) => {
    const values = props.getValue();

    const isSearching = props.selectProps.inputValue.length > 0;

    function getMultiContents() {
      // Sort avatars by name so that their order is stable
      const sortedValues = sortMembersByName(Array.from(values));
      return (
        <>
          <AvatarGroup size="xs" max={MAX_AVATARS} spacing={AVATAR_MARGIN}>
            {sortedValues.map((value, index) => {
              return <MemberItemAvatar memberItem={value} key={index} isMultipleSelected />;
            })}
          </AvatarGroup>
        </>
      );
    }

    return (
      <components.ValueContainer {...props}>
        {/* Force rendering placeholder while user is searching */}
        {!values.length && props.selectProps.inputValue && (
          <components.Placeholder {...props} innerProps={props.innerProps!} isFocused={false}>
            {PLACEHOLDER}
          </components.Placeholder>
        )}
        {/* Force rendering the selected OM while user is searching */}
        {isSearching && values.length === 1 ? <MemberItemAvatar memberItem={values[0]} /> : children}
        {values.length > 1 && getMultiContents()}
      </components.ValueContainer>
    );
  },
};

interface MemberItemAvatarProps {
  memberItem: MemberItem;
  isMultipleSelected?: boolean;
}

function MemberItemAvatar({ memberItem, isMultipleSelected = false, ...rest }: MemberItemAvatarProps & AvatarProps) {
  const deactivationHelpers = useUserDeactivationHelpers();
  const { name, avatarUrl, organizationMembershipId, id } = memberItem;
  const isActive = deactivationHelpers.isActive(organizationMembershipId);
  const avatar = (
    <ChakraAvatar size="xs" userId={id} src={avatarUrl} name={name} opacity={isActive ? 1 : 0.6} {...rest} />
  );

  return isMultipleSelected ? (
    avatar
  ) : (
    <HStack {...rest} flexShrink={1} w="100%">
      {avatar}
      <Text whiteSpace="nowrap" textOverflow="ellipsis">
        {name}
      </Text>
    </HStack>
  );
}

/**
 * Move current user, then the rest of selected items to top.
 * Sort members by username.
 */
function sortMembers(memberItems: MemberItem[], selected: Muid[], currentUserId?: Muid) {
  const currentUserIndex = memberItems.findIndex(m => m.id === currentUserId);
  const currentUserItem = memberItems[currentUserIndex];

  const withoutCurrentUserItem = currentUserItem
    ? [...memberItems.slice(0, currentUserIndex), ...memberItems.slice(currentUserIndex + 1)]
    : memberItems;

  const sorted = sortBy(sortMembersByName(withoutCurrentUserItem), item => !selected.includes(item.id));

  return currentUserItem ? [currentUserItem, ...sorted] : sorted;
}

function sortMembersByName(members: MemberItem[]) {
  return members.sort((a, b) => a.name.localeCompare(b.name));
}
