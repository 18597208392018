import { Box, Button, ButtonGroup, HStack, Input, Link, Spacer, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { Muid } from '@process-street/subgrade/core';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { Icon } from 'components/design/next';
import { CreateSavedViewButton } from 'pages/reports/data-sets/components/create-saved-view-button';
import * as React from 'react';
import { useDataSetNativeAutomationEditorForm } from './use-data-set-native-automation-editor-form';
import { Helpers } from 'pages/templates/_id/automation/components/editor/data-set-native-automation-editor/helpers';
import { useFeatureFlag } from 'app/features/feature-flags';

export type DataSetNativeAutomationEditorProps = {
  nativeAutomationId?: Muid;
};

export const DataSetNativeAutomationEditor: React.FC<React.PropsWithChildren<DataSetNativeAutomationEditorProps>> = ({
  nativeAutomationId,
}) => {
  const {
    dataSet,
    hasUnsavedChanges,
    isLoading,
    nativeAutomation,
    savedViewsOptions,
    columnsOptions,
    conditionOptions,
    triggerOptions,
    fields,
    templatesOptions,
    handleDiscardChanges,
    handleSubmit,
  } = useDataSetNativeAutomationEditorForm({ nativeAutomationId });

  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');

  const savedViewModalDisclosure = useDisclosure();
  const savedViewMenuDisclosure = useDisclosure();

  const handleSavedViewMenuClose = () => {
    // don't close the menu while the modal is open
    if (savedViewModalDisclosure.isOpen) return;

    savedViewMenuDisclosure.onClose();
  };

  const isDataSetRowChangedTrigger = fields.trigger.value?.value === 'DataSetRowChanged';

  const saveEnabled =
    hasUnsavedChanges &&
    fields.trigger.value &&
    fields.template.value &&
    fields.savedView.value &&
    (isDataSetRowChangedTrigger ? Helpers.isConditionValid(fields) : true);

  const canCreateSavedView = Boolean(dataSet?.canAccessDataSet);

  return (
    <VStack w="full" spacing="8" minH="670px" alignItems="flex-start">
      <VStack w="full" alignItems="flex-start">
        <Text variant="-2u" color="gray.500">
          Choose trigger
        </Text>

        <Box w="full">
          <BlvdSelect
            options={triggerOptions}
            value={fields.trigger.value}
            placeholder="Select action"
            onChange={fields.trigger.handler}
          />

          {isDataSetRowChangedTrigger && (
            <Text mt="1" variant="-1" color="gray.500" fontStyle="italic">
              This trigger applies only for records created or updated after setting up the trigger.
              <br />
              {/* eslint-disable-next-line no-irregular-whitespace */}
              It’ll only create one Workflow Run per Data Set record.
            </Text>
          )}
        </Box>
      </VStack>

      {!isDataSetsV2Enabled && fields.trigger.value && (
        <VStack w="full" alignItems="flex-start">
          <HStack spacing="2">
            <Icon icon="database" size="3" color="gray.500" />

            <Text variant="-2u" color="gray.500">
              Saved View
            </Text>
          </HStack>

          <Box w="full">
            <BlvdSelect
              value={fields.savedView.value}
              options={savedViewsOptions}
              placeholder="Select Data Set saved view"
              onChange={fields.savedView.handler}
              isSearchable
              menuIsOpen={savedViewMenuDisclosure.isOpen}
              onMenuOpen={savedViewMenuDisclosure.onOpen}
              onMenuClose={handleSavedViewMenuClose}
              components={{
                NoOptionsMessage: () => {
                  return (
                    <VStack maxW="80" mx="auto" py="13" spacing="4">
                      <Icon
                        icon="database"
                        variant="fad"
                        size="16"
                        primaryLayer={{ color: 'gray.300' }}
                        secondaryLayer={{ color: 'gray.400' }}
                      />

                      <VStack>
                        <Text color="gray.600" textAlign="center">
                          This Data Set doesn’t contain any Saved View, why don’t you{' '}
                          {dataSet ? (
                            <CreateSavedViewButton
                              dataSet={dataSet}
                              isDisabled={!canCreateSavedView}
                              buttonProps={{
                                variant: 'link',
                                leftIcon: undefined,
                                fontWeight: 'normal',
                                color: 'gray.600',
                                fontSize: 'md',
                                textDecoration: 'underline',
                              }}
                              modalProps={savedViewModalDisclosure}
                            >
                              create one
                            </CreateSavedViewButton>
                          ) : (
                            'create one'
                          )}
                          ?
                        </Text>

                        <Text color="gray.500" textAlign="center">
                          Learn more{' '}
                          <Link
                            href="https://www.process.st/help/docs/data-sets/#create-a-saved-view"
                            target="_blank"
                            rel="noreferrer"
                            color="gray.500"
                            textDecoration="underline"
                            _hover={{ color: 'gray.600', textDecoration: 'underline' }}
                          >
                            here
                          </Link>
                        </Text>
                      </VStack>
                    </VStack>
                  );
                },
              }}
            />
          </Box>
        </VStack>
      )}

      {fields.trigger.value && fields.savedView.value && (
        <VStack w="full" alignItems="flex-start">
          <HStack spacing="2">
            <Icon icon="workflow" size="3" color="gray.500" />

            <Text variant="-2u" color="gray.500">
              Workflow
            </Text>
          </HStack>

          <Box w="full">
            <BlvdSelect
              value={fields.template.value}
              options={templatesOptions}
              placeholder="Select Template"
              onChange={fields.template.handler}
              isSearchable
            />
          </Box>
        </VStack>
      )}

      {fields.template.value && isDataSetRowChangedTrigger && (
        <VStack w="full" spacing="4">
          <VStack w="full" alignItems="flex-start">
            <Text variant="-2u" color="gray.500">
              Condition
            </Text>
            <Box w="full">
              <BlvdSelect
                value={fields.operandA.value}
                options={columnsOptions}
                onChange={fields.operandA.handler}
                placeholder="Select Data Set field"
              />
            </Box>
          </VStack>

          <HStack w="full" spacing="4">
            <Box w="full" maxW="48">
              <BlvdSelect
                options={conditionOptions}
                value={fields.conditionType.value}
                onChange={fields.conditionType.handler}
                placeholder="Condition"
              />
            </Box>

            {!fields.conditionType.value || !Helpers.isSingleOperandCondition(fields.conditionType.value.value) ? (
              <Input
                w="full"
                value={fields.operandB.value ?? ''}
                onChange={fields.operandB.handler}
                placeholder="Enter value"
              />
            ) : (
              <Spacer minW="50%" />
            )}
          </HStack>
        </VStack>
      )}

      <HStack w="full" justifyContent="flex-end" pt="8">
        <ButtonGroup spacing="4">
          {nativeAutomation && (
            <Button
              variant="secondary"
              fontWeight="normal"
              onClick={handleDiscardChanges}
              isDisabled={!hasUnsavedChanges}
              isLoading={isLoading}
            >
              Cancel
            </Button>
          )}

          <Button onClick={handleSubmit} variant="primary" isDisabled={!saveEnabled} isLoading={isLoading}>
            Save
          </Button>
        </ButtonGroup>
      </HStack>
    </VStack>
  );
};
