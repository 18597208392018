import * as React from 'react';
import { NumberFormFieldWidget as Widget } from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { FormFieldEvent } from 'services/form-field-event';
import {
  Box,
  FormControl,
  Input,
  FormLabel,
  VStack,
  InputLeftAddon,
  InputRightAddon,
  HStack,
  Text,
} from 'components/design/next';
import { NumberFieldInputGroup } from '../common';
import { useDebouncedCallback } from 'use-debounce';
import { EditDefaultValueButton } from 'pages/templates/_id/components/default-value-field';

type Props = {
  widget: Widget;
  isEditable: boolean;
  onUpdate: (options: { widget: Widget }) => void;
};

const DEFAULT_PLACEHOLDER = 'Some numbers will be typed here...';
const DEBOUNCE_MS = 500;

export const TemplateNumberFormField: React.FC<React.PropsWithChildren<Props>> = ({ isEditable, widget, onUpdate }) => {
  const { $rootScope } = useInjector('$rootScope');

  const [label, setLabel] = React.useState(widget.label ?? '');
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(
    () => {
      const isFocused = inputRef.current === document.activeElement;

      // Don't sync when the input is focused
      if (isFocused) return;

      // Sync the inner label state whenever the label from the widget passed
      // as prop changes
      if (widget.label && widget.label !== label) {
        setLabel(widget.label);
      }
    },
    // Removing the `label` to avoid infinite loops.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [widget.label],
  );

  const debouncedUpdate = useDebouncedCallback((value: string) => {
    onUpdate({ widget: { ...widget, label: value } });
  }, DEBOUNCE_MS);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setLabel(value);
    debouncedUpdate(value);
  };

  const onClickDisabled = () => {
    $rootScope.$broadcast(FormFieldEvent.DISABLED_FORM_FIELD_CLICKED);
  };
  const placeholder =
    widget.config.defaultValue || (widget.config.placeholder ?? '').replace(/^$/, DEFAULT_PLACEHOLDER);

  const input = (
    <NumberFieldInputGroup w="full">
      {/* HStack spacing not working well with flex-direction nor order props on parent/child respectively */}
      {widget.config.unit && widget.config.unitLocation !== 'suffix' ? (
        // cursor set because of angular drag and drop global styles
        <InputLeftAddon cursor="auto">{widget.config.unit}</InputLeftAddon>
      ) : null}

      <Input
        placeholder={placeholder}
        isDisabled
        sx={{ background: 'gray.100', color: 'gray.400', opacity: '1 !important' }}
      />

      {/* cursor set because of angular drag and drop global styles */}
      <InputRightAddon px={0} cursor="auto" as={HStack} spacing={0}>
        {widget.config.unit && widget.config.unitLocation === 'suffix' ? (
          <Text px={4}>{widget.config.unit}</Text>
        ) : null}
        {isEditable ? <EditDefaultValueButton widget={widget} /> : null}
      </InputRightAddon>
    </NumberFieldInputGroup>
  );

  return (
    <Box>
      {isEditable ? (
        <VStack as="form" alignItems="flex-start" spacing="4">
          <FormControl>
            <Input
              ref={inputRef}
              variant="outline"
              type="text"
              placeholder="Type label here"
              value={label}
              maxLength={255}
              // Angular app uses a `focusById()` utility at various points (e.g., adding a new widget)
              data-focus-id={`widget-${widget.header.id}`}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>{input}</FormControl>
        </VStack>
      ) : (
        <Box as="form">
          <FormControl isRequired={widget.required}>
            <FormLabel color="gray.400">{widget.label || 'Untitled Number'}</FormLabel>
            <Box onClick={onClickDisabled}>{input}</Box>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};
