import { FormControl, FormLabel, Icon, Text, Tooltip } from 'components/design/next';
import { BlvdSelect } from 'components/design/BlvdSelect';
import React from 'react';
import { GetSavedViewFoldersQuery } from 'features/checklist-grid/query-builder';
import { Muid, OrganizationMembership } from '@process-street/subgrade/core';
import { SavedView } from 'components/dashboard/models/saved-views';
import { useToken } from '@chakra-ui/react';
import sortBy from 'lodash/sortBy';

export interface SavedViewFolderDropdownProps {
  editedView: SavedView;
  handleFolderChange: (folderId?: Muid) => void;
  organizationMembership: OrganizationMembership;
}

interface FolderOption {
  value: Muid | undefined;
  label: string;
}

export const SavedViewFolderDropdown = ({
  editedView,
  handleFolderChange,
  organizationMembership,
}: SavedViewFolderDropdownProps) => {
  const savedViewFoldersQuery = GetSavedViewFoldersQuery.useQuery({
    organizationId: organizationMembership.organization.id,
  });
  const folders = React.useMemo(() => sortBy(savedViewFoldersQuery.data, 'name'), [savedViewFoldersQuery.data]);
  const noFolderOption: FolderOption = { value: undefined, label: 'No folder' };
  const folderOptions: FolderOption[] = [noFolderOption].concat(
    folders.map(folder => ({
      value: folder.id,
      label: folder.name,
    })),
  );

  const folderSelectValue: FolderOption = editedView.folderId
    ? ({
        value: editedView.folderId,
        label: folders.find(f => f.id === editedView.folderId)?.name,
      } as FolderOption)
    : noFolderOption;

  const gray200 = useToken('colors', 'gray.200');

  return (
    <FormControl>
      <FormLabel>
        Folder
        <Tooltip
          label={<Text fontSize="xs">Folders won’t modify saved view permissions.</Text>}
          hasArrow
          shouldWrapChildren
        >
          <Icon ml={1} size="4" icon="info-circle" variant="far" color="gray.500" />
        </Tooltip>
      </FormLabel>
      <BlvdSelect<FolderOption>
        options={folderOptions}
        value={folderSelectValue}
        onChange={item => item && handleFolderChange(item.value)}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? undefined : gray200,
          }),
        }}
      />
    </FormControl>
  );
};
