import { Muid, OrganizationTheme } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateOrganizationThemeParams = {
  organizationId: Muid;
  brandColor: string | null;
  emailCustomizationEnabled: boolean;
  emailPrimaryColor: string | null;
  emailBackgroundColor: string | null;
  emailFooter: string;
  emailHeaderLogoFileId: string | null;
};

type CreateOrganizationThemeResponse = OrganizationTheme;

export const CreateOrganizationTheme = {
  key: ['organization', 'theme'],
  mutationFn: (params: CreateOrganizationThemeParams) =>
    axiosService
      .getAxios()
      .post<CreateOrganizationThemeResponse>(`/1/organizations/${params.organizationId}/theme`, {
        brandColor: params.brandColor,
        emailCustomizationEnabled: params.emailCustomizationEnabled,
        emailPrimaryColor: params.emailPrimaryColor,
        emailBackgroundColor: params.emailBackgroundColor,
        emailFooter: params.emailFooter,
        emailHeaderLogoFileId: params.emailHeaderLogoFileId,
      })
      .then(r => r.data),
};

export const useCreateOrganizationTheme = (
  options?: UseMutationOptions<CreateOrganizationThemeResponse, AxiosError, CreateOrganizationThemeParams>,
) => {
  return useMutation(CreateOrganizationTheme.key, CreateOrganizationTheme.mutationFn, options);
};
