import { FilterType } from '@process-street/subgrade/dashboard';
import React from 'react';
import { Button, Text, VStack } from 'components/design/next';

export interface FilterTypeCellProps {
  first: boolean;
  filterType: FilterType;
  onClick: () => void;
}

export const FilterTypeCell: React.FunctionComponent<React.PropsWithChildren<FilterTypeCellProps>> = ({
  first,
  filterType,
  onClick,
}) => {
  const label = filterType === FilterType.And ? 'and' : 'or';

  return (
    <VStack minW={15} mx={1}>
      {first ? (
        <Text variant="-1u" color="gray.500" fontWeight="normal">
          Show
        </Text>
      ) : (
        <Button
          variant="tertiary"
          size="sm"
          color="gray.500"
          textTransform="uppercase"
          flexShrink="0"
          onClick={onClick}
        >
          {label}
        </Button>
      )}
    </VStack>
  );
};
