import { PlanLevel, PlanLevelUtil, PlanTrackPaidPlans, PlanTrack } from '@process-street/subgrade/billing';
import { FeatureFlagKey, FeatureFlags } from 'services/features/feature-flags/feature-flag-constants';

export type Feature =
  | 'UnlimitedChecklists'
  | 'UnlimitedTemplates'
  | 'DynamicDueDates'
  | 'EnforcedOrder'
  | 'ConditionalLogic'
  | 'RoleAssignments'
  | 'Approvals'
  | 'SingleSignOn'
  | 'Support'
  | 'TaskPermissions'
  | 'Onboarding'
  | 'NativeAutomations'
  | 'PremiumNativeAutomations'
  | 'CustomNativeAutomations'
  | 'AutomationRunsPerMonth';

export type PlanFeatureDescription = { mobile: string; desktop: string };

function featureHasMinimumPlan(planFeatureValue: PlanFeatureValue): planFeatureValue is MinimumPlanFeature {
  return (planFeatureValue as MinimumPlanFeature).minimumPlan !== undefined;
}

type MinimumPlanFeature = { minimumPlan: PlanLevel };
export type PlanFeatureValue = MinimumPlanFeature | { [level in PlanLevel]?: PlanFeatureDescription };

export interface FeatureConfig {
  description: string;
  name: string;
  order: number;
  planFeatureValue: PlanFeatureValue;
  multiline?: boolean;
  featureFlag?: Uncapitalize<keyof typeof FeatureFlagKey>;
}

const AutomationsLabel = 'Automation Runs / Month';

export const Features: Record<Feature, FeatureConfig> = {
  UnlimitedChecklists: {
    description: 'Workflow runs are the single-use running instances of a workflow',
    name: 'Unlimited Workflow Runs',
    order: 1,
    planFeatureValue: { minimumPlan: PlanLevel.Basic },
  },
  UnlimitedTemplates: {
    description: 'A workflow is a set of instructions that lay out exactly how a process should be completed.',
    name: 'Unlimited Workflows',
    order: 2,
    planFeatureValue: { minimumPlan: PlanLevel.Basic },
  },
  DynamicDueDates: {
    description: 'Set due dates dynamically based on tasks being checked.',
    name: 'Dynamic Due Dates',
    order: 3,
    planFeatureValue: { minimumPlan: PlanLevel.Standard },
  },
  EnforcedOrder: {
    description: 'Enforce task order and automatically notify the next person.',
    name: 'Enforced Order',
    order: 4,
    planFeatureValue: { minimumPlan: PlanLevel.Standard },
  },
  ConditionalLogic: {
    description: 'Conditional logic allows your users to follow different paths based on variations in your process.',
    name: 'Conditional Logic',
    order: 5,
    planFeatureValue: { minimumPlan: PlanLevel.Standard },
  },
  RoleAssignments: {
    description: 'Dynamically assign tasks to different roles on your team.',
    name: 'Role Assignments',
    order: 6,
    planFeatureValue: { minimumPlan: PlanLevel.Standard },
  },
  TaskPermissions: {
    description:
      'Task Permissions allow you to hide specific tasks from different users to make your workflows more private.',
    order: 7,
    planFeatureValue: { minimumPlan: PlanLevel.Standard },
    name: 'Task Permissions',
  },
  Approvals: {
    description: 'Approve or reject other tasks for better process control',
    name: 'Approvals',
    order: 8,
    planFeatureValue: { minimumPlan: PlanLevel.Standard },
  },
  SingleSignOn: {
    description:
      'Single Sign-On allows your users to sign-in with Active Directory or any SAML-based identity provider.',
    order: 9,
    name: 'Single Sign-On',
    multiline: true,
    planFeatureValue: { minimumPlan: PlanLevel.Enterprise },
  },
  NativeAutomations: {
    description: 'Automate workflows in Google Sheets, Process Street, and Slack',
    name: 'Automations',
    order: 10,
    planFeatureValue: { minimumPlan: PlanLevel.Basic },
  },
  PremiumNativeAutomations: {
    description: 'Automate workflows in DocuSign, Jira, and Salesforce',
    name: 'Premium Automations',
    order: 11,
    planFeatureValue: { minimumPlan: PlanLevel.Standard },
  },
  CustomNativeAutomations: {
    description: "We'll build a custom automation for your team",
    name: 'Custom Automations',
    order: 12,
    planFeatureValue: { minimumPlan: PlanLevel.Enterprise },
  },
  AutomationRunsPerMonth: {
    description: 'Number of times automations can execute or run per month',
    name: 'Automation Runs Per Month',
    order: 13,
    planFeatureValue: {
      [PlanLevel.Basic]: {
        desktop: '2000',
        mobile: `2000 ${AutomationsLabel}`,
      },
      [PlanLevel.Standard]: {
        desktop: '5000',
        mobile: `5000 ${AutomationsLabel}`,
      },
      [PlanLevel.Professional]: {
        desktop: '2000',
        mobile: `2000 ${AutomationsLabel}`,
      },
      [PlanLevel.Enterprise]: {
        desktop: 'Custom',
        mobile: `Custom # ${AutomationsLabel}`,
      },
    },
    multiline: true,
  },
  Support: {
    description: 'Provided level of support',
    multiline: true,
    name: 'Support',
    order: 14,
    planFeatureValue: {
      [PlanLevel.Basic]: {
        desktop: 'Email & Chat',
        mobile: 'Email & Chat Support',
      },
      [PlanLevel.Standard]: {
        desktop: 'Email & Chat',
        mobile: 'Email & Chat Support',
      },
      [PlanLevel.Professional]: {
        desktop: 'Email & Chat',
        mobile: 'Email & Chat Support',
      },
      [PlanLevel.Enterprise]: {
        desktop: 'Dedicated Success Manager',
        mobile: 'Dedicated Success Manager',
      },
    },
  },
  Onboarding: {
    description: 'Provided level of onboarding help',
    multiline: true,
    name: 'Onboarding',
    order: 15,
    planFeatureValue: {
      [PlanLevel.Basic]: {
        desktop: 'Self-Guided',
        mobile: 'Self-Guided Onboarding',
      },
      [PlanLevel.Standard]: {
        desktop: 'Training Consultation',
        mobile: 'Training Consultation',
      },
      [PlanLevel.Professional]: {
        desktop: 'Training Consultation',
        mobile: 'Training Consultation',
      },
      [PlanLevel.Enterprise]: {
        desktop: 'White Glove',
        mobile: 'White Glove Onboarding',
      },
    },
  },
};

const FeatureList: Array<[Feature, FeatureConfig]> = Object.entries(Features) as Array<[Feature, FeatureConfig]>;

const OrderedFeatureList = FeatureList.slice().sort(([, a], [, b]) => {
  return a.order - b.order;
});

export type PlanFeatureMap = Record<Feature, boolean | PlanFeatureDescription>;

export type PlansFeaturesMap = Record<PlanLevel, PlanFeatureMap>;

export const getPaidPlansFeaturesMap = (featureFlags: FeatureFlags, planTrack: PlanTrack) =>
  OrderedFeatureList.reduce((acc, [feature, config]) => {
    const { planFeatureValue, featureFlag } = config;

    if (featureFlag && !featureFlags[featureFlag]) return acc;

    PlanTrackPaidPlans[planTrack].forEach(billingPlan => {
      if (!acc[billingPlan]) {
        acc[billingPlan] = {} as PlanFeatureMap;
      }

      const value = featureHasMinimumPlan(planFeatureValue)
        ? PlanLevelUtil.rank(billingPlan) >= PlanLevelUtil.rank(planFeatureValue.minimumPlan)
        : planFeatureValue[billingPlan];

      acc[billingPlan][feature] = value ?? false;
    });

    return acc;
  }, {} as PlansFeaturesMap);

export const filterFeaturesByFeatureFlags = (featureFlags: FeatureFlags): Array<[Feature, FeatureConfig]> => {
  return OrderedFeatureList.filter(([, config]) => {
    return config.featureFlag ? featureFlags[config.featureFlag] : true;
  });
};
