import { FormControl, FormLabel, Grid, GridItem, Skeleton } from 'components/design/next';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { useGetOrganizationQuery } from 'features/organization/query-builder';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { isUndefined } from '@process-street/subgrade/core';
import { SimpleOption } from 'components/design/BlvdSelect/types';
import { OnChangeValue } from 'react-select';

export type IndustryFieldProps = {
  onSave: (params: { industry?: string }) => void;
};

export const IndustryField: React.FC<React.PropsWithChildren<IndustryFieldProps>> = ({ onSave }) => {
  const currentOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const [industry, setIndustry] = React.useState<string>();

  const organizationQuery = useGetOrganizationQuery({ organizationId: currentOrganization?.id });

  React.useEffect(() => {
    if (isUndefined(industry) && organizationQuery.data) {
      setIndustry(organizationQuery.data.industry ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
  }, [organizationQuery.data]);

  const handleChange = (option: OnChangeValue<SimpleOption, false>) => {
    if (BlvdSelectHelpers.isOptionType<SimpleOption>(option)) {
      setIndustry(option.value);
      onSave({
        industry: option.value,
      });
    }
  };

  return (
    <FormControl as={Grid} templateColumns={{ base: '1fr', md: '220px 1fr' }}>
      <GridItem display="flex" alignItems="center">
        <FormLabel htmlFor="industry" color="gray.600" as="label">
          Industry
        </FormLabel>
      </GridItem>
      <GridItem
        sx={{
          '.blvd-select .blvd-select__control .blvd-select__value-container': { pl: 4 },
        }}
      >
        <Skeleton w="full" isLoaded={!organizationQuery.isLoading}>
          <BlvdSelect
            id="industry"
            name="industry"
            value={industry ? { label: industry, value: industry } : null}
            options={INDUSTRIES.map(industry => ({ label: industry, value: industry }))}
            onChange={handleChange}
          />
        </Skeleton>
      </GridItem>
    </FormControl>
  );
};

const INDUSTRIES = [
  'Accounting',
  'Education',
  'Engineering and Construction',
  'Finance',
  'Health',
  'Hospitality',
  'Human Resources',
  'IT',
  'Law',
  'Manufacturing',
  'Marketing',
  'Real Estate',
  'Retail',
  'Sales',
  'Software',
  'Transport and Logistics',
  'Other',
];
