import * as React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
  Button,
} from 'components/design/next';

export type PreventLostWorkModalProps = {
  onDiscard: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export const PreventLostWorkModal: React.FC<React.PropsWithChildren<PreventLostWorkModalProps>> = ({
  onDiscard,
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent containerProps={{ alignItems: 'center' }} mt={0}>
        <ModalHeader>
          <Text color="gray.700" fontSize="lg" fontWeight="semibold">
            Discard changes
          </Text>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text color="gray.600">Are you sure you want to discard all your changes?</Text>
        </ModalBody>

        <ModalFooter>
          <Button onClick={() => onClose()} variant="ghost" colorScheme="gray">
            Cancel
          </Button>
          <Button
            onClick={onDiscard}
            bgColor="red.500"
            _hover={{ bgColor: 'red.600' }}
            _active={{ bgColor: 'red.600' }}
          >
            Discard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
