import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { canAccess, Feature } from 'services/features/features';
import { Option } from 'space-monad';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { ButtonWithUpgradeTooltip } from 'components/button-with-upgrade-tooltip';
import { Template } from '@process-street/subgrade/process';

export type WorkflowRunLinkButtonProps = React.PropsWithChildren<{ templateId?: Template['id'] }>;

export const WorkflowRunLinkButton: React.FC<WorkflowRunLinkButtonProps> = React.memo(({ templateId, children }) => {
  const { $stateParams } = useInjector('$stateParams');
  const id = templateId ?? $stateParams.id;

  if (!React.isValidElement(children) || Array.isArray(children)) {
    throw new Error('WorkflowRunLinkButton child must be a single clickable element');
  }

  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(id, { enabled: Boolean(id) });
  const plan = useSelector(SessionSelector.getCurrentPlan);

  const isFree = useIsCurrentUserFreeMember();
  if (isFree) return <ButtonWithUpgradeTooltip>{children}</ButtonWithUpgradeTooltip>;

  const checklistRunLinkAvailable = canAccess(Feature.CHECKLIST_RUN_LINK, plan?.id);
  const hasPermission = Option(permissionsQuery.data)
    .map(
      ({ permissionMap: pm }) => pm.templatePermitsManage || pm.templateShareLevelUpdate || pm.templateShareLinkUpdate,
    )
    .getOrElse(false);

  if (!(checklistRunLinkAvailable && hasPermission)) return null;

  const btn = React.cloneElement(children);

  return btn;
});
