import angular from 'angular';

angular.module('frontStreetApp.controllers').controller('UserUnsubscribeCtrl', function ($scope, $state, UserService) {
  $scope.unsubscribing = false;

  if (!$state.params.token) {
    $scope.outcome = 'failure';
  }

  $scope.unsubscribe = function () {
    $scope.unsubscribing = true;

    UserService.updateUsingToken($state.params.token)
      .then(
        () => {
          $scope.outcome = 'success';
        },
        () => {
          $scope.outcome = 'failure';
        },
      )
      .finally(() => {
        $scope.unsubscribing = false;
      });
  };
});
