import * as React from 'react';
import { AutomationLogStatus, ExecutionStatus } from '@process-street/subgrade/automation';
import {
  Box,
  Button,
  Fade,
  Flex,
  MotionWrapper,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SlideFade,
  Text,
  useToast,
} from 'components/design/next';
import { AnimatePresence } from 'framer-motion';
import { LogItem } from './log-item';
import {
  AutomationStatus,
  AutomationStatusIndicatorIconComponent,
} from 'pages/checklists/_id/components/automations-status-indicator/components/indicator-icon/component';
import { Muid } from '@process-street/subgrade/core';
import { useInjector } from 'components/injection-provider';
import { useOnStateChangeSuccess } from 'hooks/use-on-state-change-success';
import { useGetAllExecutionStatusesByChecklistIdQuery } from 'features/automations/query-builder/get-all-execution-statuses-by-checklist-id-query';

export type AutomationsStatusIndicatorComponentProps = {
  status: AutomationStatus;
  data?: ExecutionStatus[];
  onRefresh?: () => void;
};

export const AutomationsStatusIndicatorDisclosureId = 'Automations Status Indicator';

export const AutomationsStatusIndicatorComponent: React.FC<
  React.PropsWithChildren<AutomationsStatusIndicatorComponentProps>
> = ({ data, status }) => {
  const resultsCount = data?.length ?? 0;

  return (
    <Popover placement="bottom-end" size="lg">
      <PopoverTrigger>
        <Button aria-label="toggle run log" variant="unstyled" w="fit-content">
          <AutomationStatusIndicatorIconComponent status={status} />
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent zIndex="popover">
          <PopoverArrow />
          <PopoverCloseButton
            padding="0"
            textTransform="none"
            borderStyle="none"
            outline="initial"
            margin="0"
            marginTop="2.5"
            cursor="pointer"
            backgroundColor="transparent"
            fontSize="xs"
            color="gray.400"
          />
          <PopoverBody>
            <Box>
              <Flex
                borderBottomWidth="px"
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
                width="100%"
                p={1}
                alignItems="center"
              >
                <AutomationStatusIndicatorIconComponent status={status} />
                <Flex as={Fade} in={true} unmountOnExit={true} width="100%">
                  <Flex pl="1" alignItems="center" flexGrow={1}>
                    <Text color="gray.500" variant="1">
                      Automation Status
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex maxH="50vh" overflowY="auto" as={SlideFade} unmountOnExit={true} in={true} width="100%">
                {resultsCount > 0 ? (
                  <Box width="100%">
                    <AnimatePresence>
                      {data?.map((executionLog: ExecutionStatus) => (
                        <MotionWrapper
                          layout
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          key={executionLog.id}
                        >
                          <Box _even={{ bg: 'gray.50' }}>
                            <LogItem log={executionLog} />
                          </Box>
                        </MotionWrapper>
                      ))}
                    </AnimatePresence>
                  </Box>
                ) : (
                  <MotionWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <Box key="no logs" _even={{ bg: 'gray.50' }} paddingX="4" paddingY="2">
                      <Text>No automations have run on this workflow run.</Text>
                    </Box>
                  </MotionWrapper>
                )}
              </Flex>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export type AutomationsStatusIndicatorProps = {
  checklistId: Muid;
  checklistOpenedAt: number;
};

export const AutomationsStatusIndicator: React.FC<React.PropsWithChildren<AutomationsStatusIndicatorProps>> = ({
  checklistId,
  checklistOpenedAt,
}) => {
  const { refetch, data } = useGetAllExecutionStatusesByChecklistIdQuery({ checklistId }, { refetchInterval: 2000 });

  const { $state } = useInjector('$state');
  const toast = useToast();
  const toastIdRef = React.useRef<string | number>();

  const updateFields = React.useCallback(() => {
    $state.reload();

    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }, [$state, toast]);

  useOnStateChangeSuccess(() => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  });

  const showRefreshToast = React.useCallback(
    () =>
      (toastIdRef.current = toast({
        status: 'success',
        title: 'Automation Complete',
        description: (
          <Box pt="1">
            <Button variant="unstyled" onClick={updateFields}>
              <Text textDecoration="underline">Refresh this page to see update</Text>
            </Button>
          </Box>
        ),
      })),
    [toast, updateFields],
  );

  const [state, setState] = React.useState(AutomationStatus.Idle);

  const updateStateByLogStatus = React.useCallback(
    (status: AutomationLogStatus | undefined) => {
      switch (status) {
        case AutomationLogStatus.Processing:
        case undefined:
          setState(AutomationStatus.Processing);
          break;
        case AutomationLogStatus.Success:
          setState(AutomationStatus.Succeeded);
          showRefreshToast();
          break;
        case AutomationLogStatus.Failure:
          setState(AutomationStatus.Failed);
          break;
        default:
          throw new Error(`Unknown status ${status}`);
      }
    },
    [showRefreshToast],
  );

  const resetStateAfterTimeoutByLogStatus = (status: AutomationLogStatus | undefined, timeout: number) => {
    switch (status) {
      case AutomationLogStatus.Success:
      case AutomationLogStatus.Failure:
        const timer = setTimeout(() => {
          setState(AutomationStatus.Idle);
        }, timeout);
        return () => clearTimeout(timer);
    }
  };

  React.useEffect(() => {
    if (data?.length !== undefined && data.length > 0) {
      const [lastExecution] = data;
      if (lastExecution.eventDate > checklistOpenedAt) {
        updateStateByLogStatus(lastExecution.status);
        resetStateAfterTimeoutByLogStatus(lastExecution.status, 4000);
      }
    } else {
      setState(AutomationStatus.Idle);
    }
  }, [data, checklistOpenedAt, updateStateByLogStatus]);

  const onRefresh = () => {
    refetch();
  };

  return <AutomationsStatusIndicatorComponent data={data} onRefresh={onRefresh} status={state} />;
};
