import angular from 'angular';

angular.module('frontStreetApp.services').factory('Folders', function (ResourceFactory) {
  return ResourceFactory.create({
    getAllByOrganizationId: 'GET[] /1/organizations/:organizationId/folders',
    getById: 'GET /1/folders/:id',
    create: 'POST /1/folders',
    createAt: 'PUT /1/folders/:id',
    update: 'PATCH /1/folders/:id',
    move: 'PUT /1/folders/:id/move/:folderId',
    deleteById: 'DELETE /1/folders/:id',
  });
});
