import { Muid, Option } from '@process-street/subgrade/core';
import { Webhook } from '@process-street/subgrade/process';
import * as React from 'react';
import { WebhookItem } from '../WebhookItem';

interface WebhooksListProps {
  webhooks: Option<Webhook[]>;
  onWebhookUpdate: (webhook: Webhook) => void;
  onWebhookDelete: (id: Muid) => void;
}

export class WebhookList extends React.PureComponent<WebhooksListProps> {
  public render() {
    if (this.props.webhooks === undefined) {
      return (
        <div className="text-center">
          <div className="spinner-container">
            <div className="ps-spinner" />
          </div>
        </div>
      );
    }

    const integrationWebhooks = this.props.webhooks.filter(w => !w.traySolutionInstanceId);
    return integrationWebhooks.map(webhook => (
      <WebhookItem
        key={webhook.id}
        webhook={webhook}
        onWebhookUpdate={this.props.onWebhookUpdate}
        onWebhookDelete={this.props.onWebhookDelete}
      />
    ));
  }
}
