import { Approval, ApprovalStatus, ApprovalWithChecklistRevisionId } from '../../approval-rule/approval.model';
import { MuidUtils } from '../../core';
import { generateAudit } from './audit-generator';

export const generateApproval = (approval?: Partial<Approval>): ApprovalWithChecklistRevisionId => ({
  id: MuidUtils.randomMuid(),
  audit: generateAudit(),
  organizationId: MuidUtils.randomMuid(),
  approvalTaskId: MuidUtils.randomMuid(),
  subjectTaskId: MuidUtils.randomMuid(),
  status: ApprovalStatus.Approved,
  reviewedById: MuidUtils.randomMuid(),
  comment: '',
  checklistRevisionId: MuidUtils.randomMuid(),
  ...approval,
});
