import * as React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Icon,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from 'components/design/next';
import { useAdminEmails } from 'components/paywalls/use-admin-emails';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { mailto } from '@process-street/subgrade/process';
import { Muid } from '@process-street/subgrade/core';
import { useInjector } from 'components/injection-provider';
import messageTemplate from 'directives/template-share/modal/template-share-modal.component.html';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { useRoleNames } from 'hooks/use-role-names';

export type Props = {
  templateId: Muid;
  isVisible?: boolean;
  isDisabled: boolean;
  modalOptions?: {
    hideTabs?: boolean;
    tab?: 'runlink' | 'sharelink';
  };
  children: React.ReactElement<ButtonProps>;
};

export const TemplateShareButton: React.FC<React.PropsWithChildren<Props>> = ({
  templateId,
  isVisible = true,
  isDisabled,
  children,
  modalOptions,
}) => {
  const currentUser = useSelector(SessionSelector.getCurrentUser);
  const [adminEmail, ...bcc] = useAdminEmails();
  const { MessageBox } = useInjector('MessageBox');
  const { data: template } = useGetTemplateQuery({ templateId });
  const { data: templateRevision } = useNewestTemplateRevisionQuery({ templateId });
  const roleNames = useRoleNames();

  const onClick = React.useCallback(() => {
    MessageBox.custom({
      animation: false,
      templateUrl: messageTemplate,
      controller: 'TemplateShareModal',
      size: 'lg',
      options: {
        template,
        templateRevision,
        user: currentUser,
        tab: undefined,
        ...modalOptions,
      },
    });
  }, [MessageBox, currentUser, template, templateRevision, modalOptions]);

  const href = mailto({
    to: adminEmail,
    subject: `Someone on your team is requesting an upgrade on your Process Street plan`,
    body: `A ${roleNames.FreeMember.single} on your Process Street account${
      currentUser?.username ? `, ${currentUser.username}, ` : ''
    }would like you to upgrade them to a ${roleNames.FullMember.single}. ${
      roleNames.FullMember.plural
    } can create and run workflows.\n\nUpgrade here: https://app.process.st/organizations/manage/billing`,
    bcc,
  });
  const btn = React.useMemo(
    () =>
      React.isValidElement(children) ? (
        React.cloneElement(children, {
          onClick,
          isDisabled,
        })
      ) : (
        <Button
          // matching bootstrap padding
          padding="7px 12px"
          color="gray.600"
          bgColor="white"
          borderColor="gray.300"
          borderWidth="px"
          borderStyle="solid"
          borderRadius="base"
          w="full"
          mb="5"
          position="relative"
          onClick={onClick}
          justifyContent="flex-start"
          display="flex"
          isDisabled={isDisabled}
          variant="unstyled"
          leftIcon={<Icon icon="share-alt" variant="far" size="4" />}
          iconSpacing="2"
          _hover={{ bgColor: 'gray.100' }}
        >
          Share
        </Button>
      ),
    [onClick, isDisabled, children],
  );

  if (isDisabled)
    // HACK: disabled buttons cause tricky behavior with popovers because it affects the cursor events property
    // Here we render an empty div over the button when it is disabled.
    return (
      <Box position="relative">
        {btn}

        <Popover trigger="hover" size="auto" variant="tooltip-dark">
          <PopoverTrigger>
            <Box w="full" top="0" left="0" h="full" position="absolute" />
          </PopoverTrigger>
          <PopoverContent w="auto">
            <PopoverArrow />
            <PopoverBody>
              <Link href={href} isExternal>
                Contact your admin
              </Link>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    );

  if (!isVisible) return null;

  return btn;
};
