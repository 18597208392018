import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { Icon, IconButton, Menu, MenuButton, MenuList } from 'components/design/next';
import * as React from 'react';
import { DeleteTaskMenuItem } from './delete-task-menu-item';

export type TopbarMenuProps = {
  task: OneOffTask;
};

export const TopbarMenu: React.FC<React.PropsWithChildren<TopbarMenuProps>> = ({ task }) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        data-testid="topbar-menu"
        icon={<Icon size="4" variant="far" icon="ellipsis-h" />}
        size="sm"
        aria-label="actions"
        variant="outline"
        borderWidth="px"
        borderColor="gray.300"
        color="gray.500"
      />
      <MenuList>
        <DeleteTaskMenuItem task={task} />
      </MenuList>
    </Menu>
  );
};
