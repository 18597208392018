import {
  DueDateRuleOffsetDirection,
  DueDateRuleSourceType,
  DueDateRuleType,
  TaskTemplateGroup,
  WidgetGroup,
} from '@process-street/subgrade/process';
import { Period, Ref } from '@process-street/subgrade/core';

export type PartialDueDateRule = {
  targetTaskTemplateGroup?: Ref<TaskTemplateGroup>;
  sourceType?: DueDateRuleSourceType;
  formFieldWidgetGroup?: WidgetGroup;
  taskTemplateGroup?: TaskTemplateGroup;
  offsetDirection: DueDateRuleOffsetDirection;
  dueOffset: Period;
  workdaysOnly: boolean;
  ruleType: DueDateRuleType;
};

export const getInitialPartialRule = (ruleType: DueDateRuleType): PartialDueDateRule => ({
  offsetDirection: DueDateRuleOffsetDirection.After,
  dueOffset: {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  },
  workdaysOnly: true,
  ruleType,
});
