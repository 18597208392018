import * as React from 'react';
import { Box, Flex, useColorModeValue as mode, useDisclosure } from 'components/design/next';
import { DurationSwitcher } from '../duration-switcher';
import { PricingCard } from '../pricing-card';
import { PlanCostQuantity, PlanMap, PlanStates } from 'pages/organizations/manage/billing/models/models';
import { FreePricingCard } from 'pages/organizations/manage/billing/components/pricing-card/FreePricingCard';
import {
  calculatePercentDiscountedCost,
  Plan,
  PlanFeatureSet,
  PlanInterval,
  PlanLevel,
  PlanLevelUtil,
} from '@process-street/subgrade/billing';
import { Option, Organization, OrganizationDiscount } from '@process-street/subgrade/core';
import { useInjector } from 'components/injection-provider';
import { PlanLevelSelectorState } from 'directives/billing/plan-level-selector/plan-level-selector-service.interface';

export type PlanSelectorProps = {
  currentPlan: Plan;
  discount: OrganizationDiscount;
  intervalSelectorVisible: boolean;
  onIntervalChange: (value: PlanInterval) => void;
  onSelect: (plan: PlanLevel, selectorState: PlanLevelSelectorState) => boolean;
  organization: Organization;
  planCostQuantity: PlanCostQuantity;
  planMap: PlanMap;
  selectedInterval: PlanInterval;
};

export const PlanSelector: React.FC<React.PropsWithChildren<PlanSelectorProps>> = ({
  currentPlan,
  discount,
  intervalSelectorVisible,
  onIntervalChange,
  onSelect,
  organization,
  planCostQuantity,
  planMap,
  selectedInterval,
}) => {
  const { PlanLevelSelectorService } = useInjector('PlanLevelSelectorService');
  const { isOpen: detailsExpanded, onToggle: toggleDetails } = useDisclosure();
  const [selectedPlanLevel, setSelectedPlanLevel] = React.useState<Option<PlanLevel>>();
  const { $state, OrganizationService } = useInjector('$state', 'OrganizationService');

  const planStates = React.useMemo(() => {
    const ps: PlanStates = {};

    PlanLevelUtil.all().forEach(planLevel => {
      const selected = selectedPlanLevel === planLevel;

      ps[planLevel] = PlanLevelSelectorService.getPlanLevelState(organization, currentPlan, planLevel, selected);
    });
    return ps;
  }, [selectedPlanLevel, PlanLevelSelectorService, organization, currentPlan]);

  const getFormattedCostPerMonth = (level: PlanLevel) => {
    const baseCost = planMap[level]?.[selectedInterval]?.cost;

    if (!baseCost) {
      return '0.00';
    }

    let cost = calculatePercentDiscountedCost(baseCost, discount);

    if (selectedInterval === PlanInterval.Yearly) {
      cost /= 12;
    }
    return cost ? PlanLevelSelectorService.formatCostAsString(cost) : '0.00';
  };

  const handleSelect = (level: PlanLevel) => {
    const selectorState = planStates[level]!;

    const changed = onSelect(level, selectorState);
    if (changed) {
      setSelectedPlanLevel(level);
    }
  };

  const proCost = `$${getFormattedCostPerMonth(PlanLevel.Professional)}`;
  const proPlanMinQuantity = planMap[PlanLevel.Professional]?.[selectedInterval]?.minQuantity ?? 0;

  const featureSetFree = planMap[PlanLevel.Free]?.[selectedInterval]?.featureSet as PlanFeatureSet;
  const { trialExpired } = $state.params;

  const isTrialing = OrganizationService.isTrialing(organization.subscription);

  const freePlanVisible =
    !isTrialing && (planStates[PlanLevel.Free] !== PlanLevelSelectorState.CURRENT_PLAN || trialExpired);

  const proCostQuantity = {
    minQuantity: organization.minQuantity ?? proPlanMinQuantity,
    quantity: planCostQuantity.quantity,
  };

  return (
    <Box as="section">
      <Box maxW={{ base: '560px', lg: 'unset' }} mx="auto" px={{ base: '6', md: '0' }}>
        {intervalSelectorVisible && (
          <Flex direction="column" align={{ base: 'flex-start', md: 'center' }} maxW="2xl" mx="auto">
            <DurationSwitcher planInterval={selectedInterval} onIntervalChange={onIntervalChange} />
          </Flex>
        )}

        <Flex
          direction={{ base: 'column', lg: 'row' }}
          maxW={{ base: '560px', lg: 'unset' }}
          mx="auto"
          mt={intervalSelectorVisible ? 10 : 0}
          bg={mode('white', 'gray.700')}
          rounded="xl"
        >
          <PricingCard
            colorScheme="brand"
            detailsExpanded={detailsExpanded}
            flex="1"
            level={PlanLevel.Professional}
            onPlanSelect={handleSelect}
            onToggleDetails={toggleDetails}
            planCostQuantity={proCostQuantity}
            planStates={planStates}
            price={proCost}
            selected={selectedPlanLevel === PlanLevel.Professional}
          />
          <Box
            w={{ base: 'unset', lg: '1px' }}
            minH="0"
            h={{ base: '1px', lg: 'unset' }}
            bg={mode('gray.100', 'gray.600')}
          />
          <PricingCard
            colorScheme="purple"
            detailsExpanded={detailsExpanded}
            flex="1"
            level={PlanLevel.Enterprise}
            onPlanSelect={handleSelect}
            onToggleDetails={toggleDetails}
            planCostQuantity={planCostQuantity}
            planStates={planStates}
            price="Custom"
            selected={selectedPlanLevel === PlanLevel.Enterprise}
          />
        </Flex>

        {freePlanVisible && (
          <FreePricingCard
            flex="1"
            planStates={planStates}
            level={PlanLevel.Free}
            onPlanSelect={handleSelect}
            selected={selectedPlanLevel === PlanLevel.Free}
            featureSet={featureSetFree}
          />
        )}
      </Box>
    </Box>
  );
};
