import angular from 'angular';
import { groupWithUserSelector } from 'reducers/group/group.selectors';
import { htmlEscaped } from '@process-street/subgrade/util';
import { PERMISSION_STATS_KEY } from 'components/permissions/services/query';
import { queryClient } from 'components/react-root';
import { EventName } from 'services/event-name';
import { GroupActions } from 'reducers/group/group.actions';

class GroupManagePropertiesCtrl {
  constructor($scope, $state, $ngRedux, MessageBox, ToastService) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.MessageBox = MessageBox;
    this.ToastService = ToastService;

    // Profile Photo Upload

    $scope.acceptTypes = /(\.|\/)(gif|jpe?g|png)$/i;
    $scope.maxFileSize = 2 * 1024 * 1024;

    // Initialization

    const mapStateToThis = state => {
      const group = groupWithUserSelector(state, $state.params.id);
      return group ? { group, info: { name: group.user.username } } : {};
    };

    const mapDispatchToThis = {
      updateGroupAvatar: GroupActions.updateGroupAvatar,
      deleteGroup: GroupActions.deleteGroup,
      updateGroup: GroupActions.updateGroup,
    };

    this.unsubscribe = $ngRedux.connect(mapStateToThis, mapDispatchToThis)($scope);
  }

  $onDestroy = () => {
    this.unsubscribe();
  };

  verifyGroupDelete = group => {
    this.MessageBox.confirm({
      title: 'Delete this group?',
      message: htmlEscaped`Are you sure you want to delete the group <b>${group.user.username}</b>?`,
      okButton: {
        type: 'danger',
        text: 'Delete',
        action: () => {
          this.$scope.deleting = true;

          const promise = this.$scope
            .deleteGroup(group)
            .then(() => {
              // invalidate all permissions stats queries because we don't know what the group had access to
              queryClient.invalidateQueries(PERMISSION_STATS_KEY);
            })
            .catch(() => {
              this.$scope.deleting = false;
            });
          this.$state.go('loadAndGo', {
            promise,
            onSuccess: () => ({ to: 'organizationManage.tab', params: { tab: 'groups' } }),
          });
        },
      },
    });
  };

  update = (group, info) => {
    this.$scope.updating = true;
    this.$scope
      .updateGroup(group, info)
      .then(() => {
        this.$scope.form.$setPristine();
        this.$scope.$emit(EventName.GROUP_UPDATED, group);
      })
      .finally(() => {
        this.$scope.updating = false;
      });
  };

  updateAvatarStart = (__event, data) => {
    this.$scope.updateGroupAvatar(this.$scope.group, data).finally(() => {
      this.$scope.fileUploading = false;
    });
  };

  updateAvatarProcessFail = (__event, data) => {
    this.$scope.fileUploading = false;
    if (data.files[0].error === 'File is too large') {
      this.ToastService.openToast({
        status: 'warning',
        title: `The group photo must be less than 2 Mb`,
      });
    } else {
      this.ToastService.openToast({
        status: 'warning',
        title: `The group photo must be a GIF, JPG or PNG`,
      });
    }
  };
}

angular.module('frontStreetApp.controllers').controller('GroupManagePropertiesCtrl', GroupManagePropertiesCtrl);
