import { dayjs as moment } from '@process-street/subgrade/util';
import angular from 'angular';
import { approvalService } from 'components/approvals/approval-service';
import { loadingToast } from 'components/design/next/toast/loading';
import { usePrintStore } from 'components/react-root/print-store';
import { PrintImageService } from 'features/print/print-image-service';
import { connectController } from 'reducers/util';
import './print-checklist.scss';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'ChecklistPrintCtrl',
    function (
      $ngRedux,
      $q,
      $scope,
      $state,
      $timeout,
      $window,
      ApprovalActions,
      ApprovalRulesActions,
      ChecklistAssignmentService,
      ChecklistRevisionService,
      ChecklistTaskAssignmentService,
      DoodadsService,
      FormFieldValueService,
      OrganizationMembershipService,
      OrganizationService,
      PrintService,
      TaskService,
      TaskTemplateService,
      ToastService,
      UserService,
      WidgetActions,
      WidgetService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'PrintChecklistCtrl' });

      const mapDispatchToThis = () => ({
        getAllApprovalsByChecklistRevisionId: ApprovalActions.getAllByChecklistRevisionId,
        getAllApprovalRuleSubjectsByTemplateRevisionId: ApprovalRulesActions.getAllByTemplateRevisionId,
      });

      ctrl.$onInit = () => {
        connectController($ngRedux, null, mapDispatchToThis)(ctrl);
        usePrintStore.setState({ isPrintPage: true });

        $scope.formFieldValueMap = {};

        $scope.user = UserService.getCurrentUser();

        const checklistId = $state.params.id;
        const request = ChecklistRevisionService.getActiveByChecklistId(checklistId)
          .then(async revision => {
            $scope.checklistRevision = revision;

            ctrl.actions.getAllApprovalsByChecklistRevisionId(revision.id);
            ctrl.actions.getAllApprovalRuleSubjectsByTemplateRevisionId(revision.templateRevision.id);
            $window.document.title = `${$scope.checklistRevision.checklist.name} | Process Street`;

            const { templateRevision } = $scope.checklistRevision;

            return $q
              .all({
                checklistAssignments: ChecklistAssignmentService.getAllByChecklistId(
                  $scope.checklistRevision.checklist.id,
                ),
                tasks: TaskService.getAllByChecklistRevisionId($scope.checklistRevision.id),
                widgets: WidgetService.getAllByTemplateRevisionId(templateRevision.id),
                formFieldValues: FormFieldValueService.getAllByChecklistRevisionId($scope.checklistRevision.id),
                comments: DoodadsService.retrieveComments(revision),
                attachments: DoodadsService.retrieveAttachments(revision),
                taskActivities: DoodadsService.retrieveTaskActivities(revision),
                formFieldValueActivities: DoodadsService.retrieveFormFieldValueActivities(revision),
                taskAssignments: ChecklistTaskAssignmentService.getAllByChecklistRevisionId(
                  $scope.checklistRevision.id,
                ),
                organizationMemberships: OrganizationMembershipService.getAllByOrganizationId(
                  $scope.checklistRevision.organization.id,
                ),
                organization: OrganizationService.getById($scope.checklistRevision.organization.id),
                checklistWidgets: WidgetService.getAllChecklistWidgetsByChecklistRevisionId(
                  $scope.checklistRevision.id,
                ),
              })
              .then(
                responses => {
                  // Filter out hidden tasks
                  $scope.tasks = responses.tasks.filter(t => !t.hidden);
                  $scope.tasks.forEach(task => {
                    task.taskTemplate.name = task.taskTemplate.name || TaskTemplateService.DEFAULT_NAME;
                  });
                  // Create a Set of widgets ids that are hidden.
                  const hiddenWidgetIds = new Set(
                    responses.checklistWidgets.filter(item => item.hidden).map(item => item.groupId),
                  );

                  // Filter out hidden widgets. We still need the current data structure to generate the Print view
                  const visibleWidgets = responses.widgets.filter(item => !hiddenWidgetIds.has(item.header.group.id));

                  $scope.widgetsMap = PrintService.generateWidgetsMap(visibleWidgets);
                  $scope.formFieldValueMap = PrintService.generateFormFieldValueMap(
                    responses.formFieldValues,
                    $scope.widgetsMap,
                  );

                  $scope.doodadsMap = PrintService.generateDoodadsMap(
                    responses.comments,
                    responses.attachments,
                    responses.taskActivities,
                    responses.formFieldValueActivities,
                  );

                  $scope.taskAssignmentsMap = PrintService.generateTaskAssignmentsMap(responses.taskAssignments);

                  const usersMap = {};
                  responses.organizationMemberships.forEach(membership => {
                    const { user } = membership;
                    usersMap[user.id] = user;
                  });

                  $scope.assignments = responses.checklistAssignments.map(assignment => {
                    assignment.user = usersMap[assignment.organizationMembership.user.id];
                    return assignment;
                  });

                  // Generating checklist and task details
                  $scope.checklistCreatedInfo = PrintService.generateChecklistCreatedInfo(
                    $scope.checklistRevision.checklist,
                    usersMap,
                  );
                  $scope.checklistStatusInfo = PrintService.generateChecklistStatusInfo(
                    $scope.checklistRevision.checklist,
                    usersMap,
                  );

                  $scope.taskInfoMap = PrintService.generateTaskInfoMap(responses.tasks);
                  $scope.taskProgressMap = PrintService.generateTaskProgressMap(responses.formFieldValues);

                  $scope.totalTaskCount = ctrl.countTotalTasks($scope.tasks);
                  $scope.completedTaskCount = ctrl.countCompletedTasks($scope.tasks);

                  $scope.organization = responses.organization;
                },
                () => {
                  // Do nothing
                },
              );
          })
          .catch(error => {
            logger.log(error);
            $scope.failed = true;

            ToastService.openToast({
              title: 'Something went wrong',
              description: "We weren't able to generate the print preview. Please try again later.",
              status: 'error',

              onCloseComplete: () => {
                $window.close();
              },
            });

            return $q.reject();
          });

        ctrl.initializeSpinner(request);
      };

      ctrl.$onDestroy = function () {
        usePrintStore.setState({ isPrintPage: false });
      };

      ctrl.countTotalTasks = function (tasks) {
        return tasks.reduce((total, t) => {
          const i = isCountable(t) ? 1 : 0;
          return total + i;
        }, 0);
      };

      ctrl.countCompletedTasks = function (tasks) {
        return tasks.reduce((total, t) => {
          const i = isCountable(t) && t.status === 'Completed' ? 1 : 0;
          return total + i;
        }, 0);
      };

      function isCountable(t) {
        return !t.hidden && !TaskTemplateService.isHeading(t.taskTemplate);
      }

      ctrl.initializeSpinner = function (request) {
        const toastId = ToastService.openToast({
          duration: null,
          render: loadingToast({
            title: 'Please wait...',
            description: 'Generating print preview.',
          }),
          onCloseComplete: () => {
            $timeout(() => {
              $window.print();
            }, 1000);
          },
        });

        onPageLoad(() => {
          request.then(
            () => {
              PrintImageService.whenAllImagesLoaded(() => {
                ToastService.closeToast(toastId);
              });
            },
            () => {
              ToastService.closeToast(toastId);
            },
          );
        });
      };

      $scope.isHeading = TaskTemplateService.isHeading;

      $scope.formatInTimeZone = function (timestamp) {
        const tz = UserService.getCurrentUser()?.timeZone;
        const mnt = tz ? moment(timestamp).tz(tz) : moment(timestamp);
        return mnt.format('ddd MMM D YYYY [at] h:mmA z');
      };

      function onPageLoad(callback) {
        // We need to check this first because the load event might have already finished
        if ($window.document.readyState === 'complete') {
          callback();
        } else {
          $window.addEventListener('load', callback);
        }
      }

      $scope.isApprovalTask = task => approvalService.isApprovalTask(task.taskTemplate);
    },
  );
