import * as React from 'react';
import { FormControl, FormErrorMessage, FormLabel, Input, useToast, VStack } from 'components/design/next';
import { useDebouncedCallback } from 'use-debounce';
import { UpdateApiKeyMutation } from 'features/api-keys/query-builder';
import { useSelectedOrganization } from 'hooks/use-selected-organization';

interface ApiKeysListProps {
  apiKeyLabel: string;
  id: string;
}

const DEBOUNCE_MS = 500;

export const ApiLabelField: React.FC<React.PropsWithChildren<ApiKeysListProps>> = ({ apiKeyLabel, id }) => {
  const toast = useToast();
  const organization = useSelectedOrganization();

  const [apiLabel, setApiLabel] = React.useState<string>(() => apiKeyLabel);

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApiLabel(e.target.value);
    debouncedMutateLabel();
  };

  const handleBlur = () => debouncedMutateLabel.flush();

  const labelError = React.useMemo(() => {
    if (apiLabel.length === 0) {
      toast({
        status: 'warning',
        title: 'Please ensure the API key has a valid label.',
      });
      return;
    }
    if (apiLabel.length > 256) {
      toast({
        status: 'warning',
        title: 'The API Key Label can not have more than 256 characters.',
      });
    }
    return null;
  }, [apiLabel.length, toast]);

  const updateApiKeyMutation = UpdateApiKeyMutation.useMutation({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'API Key Label updated',
        variant: 'subtle',
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'We are having problems updating the API Key Label',
      });
    },
  });

  const debouncedMutateLabel = useDebouncedCallback(() => {
    updateApiKeyMutation.mutate({ id: organization?.id, changes: { label: apiLabel }, apiKeyId: id });
  }, DEBOUNCE_MS);

  return (
    <FormControl maxW={{ md: '300px' }}>
      <FormLabel color="gray.600" display={{ base: 'block', md: 'none' }}>
        Label
      </FormLabel>
      <VStack>
        <Input
          id={id}
          isInvalid={Boolean(labelError)}
          marginTop={0}
          onBlur={handleBlur}
          onChange={handleLabelChange}
          placeholder="API Key Label"
          type="text"
          value={apiLabel}
          required
        />
        <FormErrorMessage>{labelError}</FormErrorMessage>
      </VStack>
    </FormControl>
  );
};
