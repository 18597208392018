import { VStack, Text } from 'components/design/next';
import { LogoSpinner } from 'components/common/LogoSpinner';
import * as React from 'react';

export type MicrosoftTeamsLoginLoadingStateProps = {
  children?: React.ReactNode;
};

export const MicrosoftTeamsLoginLoadingState = ({ children }: MicrosoftTeamsLoginLoadingStateProps) => {
  return (
    <VStack py="10">
      <LogoSpinner text="" />
      <Text align="center">{children ?? 'The login process will continue in a pop-up window.'}</Text>
    </VStack>
  );
};
