import * as React from 'react';
import { Divider, Grid, GridItem, Link, Text, VStack } from 'components/design/next';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { GetApiKeysQuery } from 'features/api-keys/query-builder';
import { ApiKeysList } from './api-keys-list';
import { CreateApiButton } from './create-api-button';

export const APIKeys: React.FC<React.PropsWithChildren<unknown>> = () => {
  const organization = useSelectedOrganization();
  const getApiKeysQuery = GetApiKeysQuery.useQuery({ organizationId: organization?.id });
  return (
    <VStack spacing="6" alignItems="flex-start" w="full">
      <Text as="h5" color="gray.500" variant="-2u">
        API
      </Text>
      <Divider />
      <Grid templateColumns={{ base: '1fr', md: '480px 1fr' }} w="full" gap="4">
        <GridItem>
          You can use the Process Street API to integrate with over 1000 services like Salesforce, Trello and Asana via
          our&nbsp;
          <Link
            href="https://zapier.com/apps/process-street/integrations"
            color="brand.500"
            _hover={{ color: 'brand.600' }}
            isExternal
          >
            Zapier
          </Link>
          &nbsp;app, our&nbsp;
          <Link
            href="https://www.make.com/en/integrations/process-street"
            color="brand.500"
            _hover={{ color: 'brand.600' }}
            isExternal
          >
            Make
          </Link>
          &nbsp;or&nbsp;
          <Link
            href="https://powerautomate.microsoft.com/en-us/connectors/details/shared_processstreet/process-street/"
            color="brand.500"
            _hover={{ color: 'brand.600' }}
            isExternal
          >
            Power Automate
          </Link>
          &nbsp;connectors.
        </GridItem>
        <GridItem display="flex" justifyContent={{ base: 'center', md: 'flex-end' }}>
          <CreateApiButton currentApiKeyCount={getApiKeysQuery?.data?.length} />
        </GridItem>
      </Grid>
      {getApiKeysQuery.data && <ApiKeysList apiKeys={getApiKeysQuery.data} />}
    </VStack>
  );
};
