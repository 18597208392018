import { OverdueTaskDto } from 'components/dashboard/models/grid';
import { SavedView } from 'components/dashboard/models/saved-views';
import { ChecklistDashboardModuleState, PropertyMap, ReduxAppState } from 'reducers/types';
import { Selector } from 'reselect';

const getModuleState: Selector<ReduxAppState, ChecklistDashboardModuleState> = (
  state: ReduxAppState,
): ChecklistDashboardModuleState => state.modules.checklistDashboard;

const getSavedViews: Selector<ReduxAppState, SavedView[]> = (state: ReduxAppState) => getModuleState(state).savedViews;

const savedViewsLoaded: Selector<ReduxAppState, boolean> = (state: ReduxAppState) =>
  getModuleState(state).savedViewsLoaded;

const getOverdueTasksLoaded: Selector<ReduxAppState, PropertyMap<boolean>> = (state: ReduxAppState) =>
  getModuleState(state).overdueTasksLoaded;

const getOverdueTasks: Selector<ReduxAppState, PropertyMap<OverdueTaskDto[]>> = (state: ReduxAppState) =>
  getModuleState(state).overdueTasks;

export const ChecklistDashboardSelectors = {
  getOverdueTasks,
  getOverdueTasksLoaded,
  getSavedViews,
  savedViewsLoaded,
};
