import { ELEMENT_TBODY, ELEMENT_THEAD } from './ps-table';
import { ELEMENT_TABLE, ELEMENT_TD, ELEMENT_TH, ELEMENT_TR } from '@udecode/plate-table';
import { ELEMENT_DEFAULT } from '@udecode/plate-core';
import { isElement, insertNodes, removeNodes, moveNodes, getNodeDescendants } from '@udecode/slate';
import { findNodePath } from '@udecode/slate-react';
import {
  PagesEditor,
  PagesNode,
  PagesNodeEntry,
  PagesTableBodyElement,
  PagesTableCellElement,
  PagesTableElement,
  PagesTableHeadElement,
  PagesTableHeaderElement,
  PagesTableRowElement,
} from '../../pages-plate-types';
import { Path } from 'slate';

const DEFAULT_COLUMN_COUNT = 2;

function createEmptyTable(): PagesTableElement {
  return {
    type: ELEMENT_TABLE,
    children: [createThead(), createTbody()],
  };
}

function createTbody(): PagesTableBodyElement {
  return {
    type: ELEMENT_TBODY,
    children: [
      getEmptyRowNode({ header: false, colCount: DEFAULT_COLUMN_COUNT }),
      getEmptyRowNode({ header: false, colCount: DEFAULT_COLUMN_COUNT }),
    ],
  };
}

function createThead(): PagesTableHeadElement {
  return {
    type: ELEMENT_THEAD,
    children: [getEmptyRowNode({ header: true, colCount: DEFAULT_COLUMN_COUNT })],
  };
}

function getEmptyRowNode({ header, colCount }: { colCount: number; header: boolean }): PagesTableRowElement {
  return {
    type: ELEMENT_TR,
    children: Array(colCount)
      .fill(colCount)
      .map(() => getEmptyCellNode({ header })) as PagesTableCellElement[] | PagesTableHeaderElement[],
  };
}

function getEmptyCellNode({ header }: { header: boolean }): PagesTableCellElement | PagesTableHeaderElement {
  const text = { text: '' };
  header && Object.assign(text, { bold: true });
  return {
    type: header ? ELEMENT_TH : ELEMENT_TD,
    children: [
      {
        type: ELEMENT_DEFAULT,
        children: [text],
      },
    ],
  };
}

function insertRowAfter(editor: PagesEditor, tableNode: PagesNode, row?: number) {
  const { rows, colCount } = getRows(editor, tableNode);
  const rowCount = rows.length;
  const selectedRow = row ?? rowCount - 1;

  if (selectedRow < 0 || selectedRow >= rowCount) {
    throw new Error('Illegal row number');
  }
  const newRowNode = getEmptyRowNode({
    header: false,
    colCount,
  });

  // we don't insert another header row, but insert before the first tbody row
  const rowPath = selectedRow === 0 ? rows[1][1] : Path.next(rows[selectedRow][1]);
  insertNodes(editor, newRowNode, { at: rowPath });
}

function insertColumnAfter(editor: PagesEditor, tableNode: PagesNode, column?: number) {
  const { rows, colCount } = getRows(editor, tableNode);
  const selectedColumn = Math.min((column ?? colCount) + 1, colCount);

  if (selectedColumn < -1 || selectedColumn > colCount) {
    throw new Error('Illegal column number');
  }

  rows.forEach(([, rowPath], idx) => {
    const isHeader = idx === 0;
    const newCellNode = getEmptyCellNode({ header: isHeader });
    const newColumnPath = rowPath.concat(selectedColumn);

    insertNodes(editor, newCellNode, { at: newColumnPath });
  });
}

const appendRow = (editor: PagesEditor, tableNode: PagesNode) => insertRowAfter(editor, tableNode);

const appendColumn = (editor: PagesEditor, tableNode: PagesNode) => insertColumnAfter(editor, tableNode);

const insertRowBefore = (editor: PagesEditor, tableNode: PagesNode, row: number) =>
  insertRowAfter(editor, tableNode, row - 1);

const insertColumnBefore = (editor: PagesEditor, tableNode: PagesNode, column: number) =>
  insertColumnAfter(editor, tableNode, column - 1);

function removeRow(editor: PagesEditor, tableNode: PagesNode, row: number) {
  if (row === 0) {
    throw new Error('Cannot remove header row');
  }
  const { rows } = getRows(editor, tableNode);

  if (row <= 0 || row >= rows.length) {
    throw new Error('Illegal row number');
  }

  if (row === 1 && rows.length === 2) {
    throw new Error('Cannot remove the last remaining body row');
  }

  const [, rowPath] = rows[row];
  removeNodes(editor, { at: rowPath });
}

function removeColumn(editor: PagesEditor, tableNode: PagesNode, column: number) {
  const { rows, colCount } = getRows(editor, tableNode);

  if (column < 0 || column >= colCount) {
    throw new Error('Illegal column number');
  }

  rows.forEach(([, rowPath]) => {
    const columnPath = rowPath.concat(column);
    removeNodes(editor, { at: columnPath });
  });
}

function moveRowUp(editor: PagesEditor, tableNode: PagesNode, row: number) {
  if (row <= 1) {
    throw new Error('Unable to move up header row or first body row up');
  }
  const { rows } = getRows(editor, tableNode);

  if (row < 0 || row >= rows.length) {
    throw new Error('Illegal row number');
  }

  const [, rowPath] = rows[row];
  moveNodes(editor, { at: rowPath, to: Path.previous(rowPath) });
}

function moveRowDown(editor: PagesEditor, tableNode: PagesNode, row: number) {
  if (row === 0) {
    throw new Error('Unable to move down header row');
  }
  const { rows } = getRows(editor, tableNode);

  if (row === rows.length - 1) {
    throw new Error('Unable to move down last row');
  }
  if (row < 0 || row > rows.length) {
    throw new Error('Illegal row number');
  }

  const [, rowPath] = rows[row];
  moveNodes(editor, { at: rowPath, to: Path.next(rowPath) });
}

function moveColumnLeft(editor: PagesEditor, tableNode: PagesNode, column: number) {
  const { rows, colCount } = getRows(editor, tableNode);

  if (column === 0) {
    throw new Error('Cannot move first column to the left');
  }
  if (column < 0 || column >= colCount) {
    throw new Error('Illegal column number');
  }

  rows.forEach(([, rowPath]) => {
    const columnPath = rowPath.concat(column);

    moveNodes(editor, { at: columnPath, to: Path.previous(columnPath) });
  });
}

function moveColumnRight(editor: PagesEditor, tableNode: PagesNode, column: number) {
  const { rows, colCount } = getRows(editor, tableNode);

  if (column === colCount - 1) {
    throw new Error('Cannot move last column to the right');
  }
  if (column < 0 || column >= colCount) {
    throw new Error('Illegal column number');
  }

  rows.forEach(([, rowPath]) => {
    const columnPath = rowPath.concat(column);

    moveNodes(editor, { at: columnPath, to: Path.next(columnPath) });
  });
}

function getTableSize(tableNode: PagesNode) {
  const rows = [...getNodeDescendants(tableNode)].filter(n => isElement(n[0]) && n[0].type === ELEMENT_TR);
  const [[firstRowElem]] = rows;
  const colCount = isElement(firstRowElem) ? firstRowElem.children.length : 0;

  return { rowCount: rows.length, colCount };
}

/**
 * Get row entries for a table in node entry format, relative to the editor root.
 * Invariant: first row is the header row.
 */
function getRows(editor: PagesEditor, tableNode: PagesNode) {
  const tablePath = findNodePath(editor, tableNode);
  if (!tablePath) {
    throw new Error('Table node not found in editor');
  }
  const rows = [...getNodeDescendants(tableNode)]
    .filter(n => isElement(n[0]) && n[0].type === ELEMENT_TR)
    .map(([node, path]) => [node, tablePath.concat(path)] as PagesNodeEntry);
  const [[firstRowElem]] = rows;
  const colCount = isElement(firstRowElem) ? firstRowElem.children.length : 0;
  return { rows, colCount };
}

export const TableWidgetOperations = {
  createEmptyTable,
  insertRowBefore,
  insertRowAfter,
  insertColumnBefore,
  insertColumnAfter,
  moveColumnLeft,
  moveColumnRight,
  moveRowUp,
  moveRowDown,
  removeColumn,
  removeRow,
  appendRow,
  appendColumn,
  getTableSize,
};
