import React from 'react';
import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Button } from 'components/design/next';
import { CommentsValue } from 'components/dashboard/models/grid';
import { useInjector } from 'components/injection-provider';

interface CommentsAndAttachmentsRendererProps extends Partial<ICellRendererParams> {
  value?: CommentsValue;
}

const Cell = ({ count }: { count: number }) => {
  return (
    <>
      <FontAwesomeIcon icon={faComment} />
      <span className="ml-h">{count}</span>
    </>
  );
};
export const CommentsAndAttachmentsRenderer: React.FC<React.PropsWithChildren<CommentsAndAttachmentsRendererProps>> = ({
  value,
}) => {
  const { $state } = useInjector('$state');

  if (!value) return <div />;

  const { count, checklistId } = value;

  if (!count || count === 0) {
    return <div />;
  }

  return (
    <Button
      variant="unstyled"
      aria-label="go to workflow run comments"
      onClick={() =>
        $state.go('checklist', {
          id: checklistId,
          comments: true,
        })
      }
    >
      <Cell {...{ count }} />
    </Button>
  );
};
