import { Folder } from '@process-street/subgrade/process';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { AxiosError } from 'axios';

export type MoveFolderParams = { candidateId: Folder['id']; toFolderId: Folder['id'] };

export type MoveFolderResult = Folder;

export const MoveFolder = {
  mutationFn: ({ toFolderId, candidateId }: MoveFolderParams): Promise<MoveFolderResult> =>
    axiosService
      .getAxios()
      .put<MoveFolderResult>(`/1/folders/${candidateId}/move/${toFolderId}`)
      .then(res => res.data),
};

export const useMoveFolderMutation = (
  options: UseMutationOptions<MoveFolderResult, AxiosError, MoveFolderParams> = {},
) => {
  return useMutation<MoveFolderResult, AxiosError, MoveFolderParams>(MoveFolder.mutationFn, options);
};

type MoveFoldersParams = {
  candidateIds: Folder['id'][];
  toFolderId: Folder['id'];
};

export const useMoveFoldersMutation = (
  options: UseMutationOptions<MoveFolderResult[], AxiosError, MoveFoldersParams> = {},
) => {
  return useMutation<MoveFolderResult[], AxiosError, MoveFoldersParams>(
    params =>
      Promise.all(
        params.candidateIds.map(candidateId => MoveFolder.mutationFn({ candidateId, toFolderId: params.toFolderId })),
      ),
    options,
  );
};
