import * as React from 'react';
import { Box, HStack, BoxProps, Tooltip } from 'components/design/next';
import { usePrintStore } from 'components/react-root/print-store';
import { AiTaskAuditHelperText, CommonAuditProps, ProcessPeteCircle, ProcessPeteIcon } from './common';

export interface EmptyAiTaskAuditProps extends BoxProps, CommonAuditProps {}

export const EmptyAiTaskAudit: React.FC<React.PropsWithChildren<EmptyAiTaskAuditProps>> = ({
  aiTaskTemplate: _,
  ...props
}) => {
  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;

  return (
    <Box {...props}>
      <HStack>
        <Tooltip label="This form field is an output of an AI task" shouldWrapChildren hasArrow>
          <ProcessPeteCircle>
            <ProcessPeteIcon />
          </ProcessPeteCircle>
        </Tooltip>

        <AiTaskAuditHelperText color={isPrintMode ? 'gray.200' : 'gray.400'}>
          AI Task has not started yet.
        </AiTaskAuditHelperText>
      </HStack>
    </Box>
  );
};
