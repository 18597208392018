import * as React from 'react';
import { useState } from 'react';
import * as AgGridReactModule from '@ag-grid-community/react';
import {
  BodyScrollEvent,
  CellMouseOverEvent,
  GridReadyEvent,
  IRowNode,
  SelectionChangedEvent,
} from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid-no-native-widgets.css';
import { useInboxItemsDataSource } from 'pages/tasks/hooks/use-inbox-items-data-source';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { useTasksFilters } from 'pages/tasks/tasks-filters-provider';
import { getRowId } from 'pages/tasks/helpers';
import { IdentifiableTaskRow, useTasksRowSelectionProvider } from 'pages/tasks/row-selection-provider';
import { SelectionBarWrapper } from '../selection-bar/selection-bar';
import { isNotIdRef, Muid } from '@process-street/subgrade/core';
import { useDeepCompareEffect, useUnmount } from 'react-use';
import { ReassignModal } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers/AssigneesRenderer/reassign-modal';
import { Box, useBreakpointValue, useToast } from 'components/design/next';
import { useInboxItems } from 'pages/microsoft-teams/inbox/use-inbox-items';
import { useQueryClient } from 'react-query';
import { withCommonAgGridStyleProps } from 'components/design/ag-grid';
import { getInboxItemDisplayProps } from 'pages/tasks/components/cell-renderer/name-renderer';
import { InboxItem } from '@process-street/subgrade/inbox';
import { scrollLoadMoreButtonIntoView } from '../cell-renderer/load-more-row-renderer';
import {
  allColumnDefs,
  groupedByDueDateColumnDefs,
  groupedByWorkflowRunColumnDefs,
  isNotAttachedOneOffTask,
} from './column-defs';
import { match, P } from 'ts-pattern';
import { useTableFocusManager } from 'pages/tasks/hooks/use-table-focus-manager';
import { usePurgeTasksTable } from 'pages/tasks/hooks/use-purge-tasks-table';
import { TasksTableUtils } from 'pages/tasks/components/tasks-table/tasks-table-utils';
import { useLeakyLocation } from 'app/adapters/navigation';
import { useTableProps } from './use-table-props';
import { Checklist } from '@process-street/subgrade/process';
import { TasksTableEmptyState } from './tasks-table-empty-state';
import { useInboxItemsGridActions, useSetInboxItemsGridApi } from '../../use-inbox-items-grid-context-store';
import { useGridContext } from '../cell-renderer/use-grid-context';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';
import { useInfiniteQueryObserver } from './use-infinite-query-observer';
import { UpdateInboxItemsStatusMutation } from 'app/features/inbox/query-builder/update-status-mutation';
import { useCurrentUser } from 'hooks/use-current-user';

const getTableStyles = (hasSelectedRows?: boolean) =>
  withCommonAgGridStyleProps({
    '& .ag-cell': {
      'p': 0,

      // checkbox cell
      '&[col-id=checkbox]': {
        'borderColor': { lg: 'transparent' },
        'borderRightColor': { base: 'transparent', lg: 'transparent' },
        'borderLeftColor': 'transparent',
        'px': 1.5,
        'py': 2,
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        '&.selection-disabled': {
          pointerEvents: 'none',
        },
      },

      '&:nth-of-type(2)': {
        borderLeftColor: { lg: 'transparent' },
      },

      '&:last-of-type': {
        borderRightColor: 'transparent',
      },
      'borderRightColor': 'transparent',
    },

    '& .ag-header-cell': {
      pl: 0,
    },

    '& .ag-header-cell-comp-wrapper': {
      '.chakra-checkbox': {
        margin: '0 auto',
      },
    },

    // Dash under first row
    '& .ag-header-container::after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      // Dash does not includes checkbox column + 8px to suplement padding
      left: `${TasksTableUtils.CHECKBOX_COL_WIDTH_PX + 8}px`,
      right: '0',
      height: '1px',
      backgroundColor: 'gray.200',
    },

    '& .ag-header-cell-label': {
      color: 'gray.500',
    },

    '& .ag-header-container': {
      backgroundColor: 'transparent',
      position: 'relative',
    },

    '& .ag-header .ag-pinned-left-header': {
      backgroundColor: 'transparent',
    },

    '& .ag-selection-checkbox .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper': {
      borderColor: 'gray.200',
    },

    // disable group hover background
    '& .ag-row-level-0.ag-row:hover': {
      backgroundColor: 'transparent',
    },

    '.ag-header-row': {
      '.ag-header-cell:first-of-type': {
        opacity: { lg: hasSelectedRows ? 1 : 0 },
      },

      '&:hover  .ag-header-cell:first-of-type': {
        opacity: { lg: 1 },
      },
    },

    '& .ag-row-focus:not(.ag-full-width-row)': {
      'backgroundColor': 'transparent',

      '.ag-cell': {
        'backgroundColor': 'brand.100',

        '&:first-of-type': {
          // checkbox cell can't be highlighted
          backgroundColor: { lg: 'transparent' },
        },
      },
    },

    '& .ag-row': {
      '&:not(.ag-full-width-row) .ag-cell[col-id=checkbox]': {
        opacity: { lg: hasSelectedRows ? 1 : 0 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      '&:hover': {
        'backgroundColor': 'transparent',

        '.ag-cell': {
          'backgroundColor': 'gray.50',
          '&:first-of-type': {
            opacity: { lg: 1 },
            // checkbox cell can't be highlighted
            backgroundColor: { lg: 'transparent' },
          },
        },
      },
    },
  });

export type TasksTableProps = {};

export const TasksTable = () => {
  const gridRef = React.useRef<AgGridReactModule.AgGridReact>(null);
  const { groupBy, ...inboxQueryParams } = useTasksFilters();
  const user = useCurrentUser();

  const isGroupedByDueDate = groupBy === TasksTableUtils.TasksTableGroupBy.DueDate;
  const isGroupedByWorkflowRun = groupBy === TasksTableUtils.TasksTableGroupBy.WorkflowRun;
  const isGrouped = isGroupedByDueDate || isGroupedByWorkflowRun;

  const queryClient = useQueryClient();
  const [selectedRows, setSelectedRows] = useTasksRowSelectionProvider();
  const isMobile = useBreakpointValue({ base: true, lg: false }) ?? false;
  const focusManager = useTableFocusManager(gridRef.current?.api);
  const gridContextActions = useInboxItemsGridActions();
  const setGridApi = useSetInboxItemsGridApi();
  const gridContext = useGridContext();
  const toast = useToast();

  // Infinite data source used when rows are not grouped
  const { dataSource, invalidate: invalidateInboxQuery } = useInboxItemsDataSource();

  const handleGridReady = React.useCallback(
    (event: GridReadyEvent<TasksTableUtils.TasksTableItem>) => {
      if (!isGrouped) {
        event.api.setGridOption('datasource', dataSource);
      }

      setGridApi(event.api);
    },
    [dataSource, isGrouped, setGridApi],
  );

  useDeepCompareEffect(
    function reloadGridDataSource() {
      // When not in grouped mode, React Query handles fetching
      if (!isGrouped) {
        gridRef.current?.api?.setGridOption('datasource', dataSource);
      }
    },
    [inboxQueryParams, isGrouped, dataSource],
  );

  // Regular query used in case rows are grouped
  // Infinite data model is not compatible with row grouping
  const sortBy = React.useMemo(() => {
    if (isGroupedByWorkflowRun) return GetInboxItemsQuery.SortBy.ChecklistName;
    if (isGroupedByDueDate) return GetInboxItemsQuery.SortBy.AssignmentDueDate;

    return inboxQueryParams.sortBy;
  }, [inboxQueryParams.sortBy, isGroupedByDueDate, isGroupedByWorkflowRun]);

  const inboxItemsInfiniteQuery = useInboxItems(
    { ...inboxQueryParams, sortBy, pageSize: TasksTableUtils.TASKS_TABLE_PAGE_SIZE },
    {
      enabled: isGrouped,
      onError: () => {
        toast({
          status: 'error',
          title: `We're having problems loading your tasks`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      },
      notifyOnChangeProps: ['data', 'status'],
    },
  );

  const inboxItemsQueryData = React.useMemo(
    () =>
      match({ status: inboxItemsInfiniteQuery.status, data: inboxItemsInfiniteQuery.data })
        .with({ status: 'loading' }, () => Array.from({ length: 5 }, () => TasksTableUtils.createLoadingRow()))
        .with({ data: P.not(P.nullish) }, ({ data }) => (data?.pages.flat() ?? []).sort(compareInboxItemsByName))
        .otherwise(() => []),
    [inboxItemsInfiniteQuery.status, inboxItemsInfiniteQuery.data],
  );

  const shouldShowLoadMoreButton = inboxItemsInfiniteQuery.hasNextPage ?? false;

  const inboxItems = React.useMemo(() => {
    const items = shouldShowLoadMoreButton
      ? [...inboxItemsQueryData, TasksTableUtils.LOAD_MORE_BUTTON_ROW]
      : inboxItemsQueryData;

    if (!isGroupedByWorkflowRun) {
      return items;
    } else {
      // When grouped by workflow run, we need to add rows to add tasks to each group
      const checklists = items.reduce((acc, item) => {
        if (TasksTableUtils.isInboxItemRow(item)) {
          if (isNotAttachedOneOffTask(item)) {
            return acc;
          } else {
            const checklistIds = new Set(acc.map(a => a.id));
            if (!checklistIds.has(item.checklist.id) && isNotIdRef(item.checklist)) {
              acc.push(item.checklist);
            }
          }
        }

        return acc;
      }, [] as Checklist[]);

      const newAttachedTasksRows: TasksTableUtils.NewTaskRow[] = checklists.map(checklist =>
        TasksTableUtils.createNewTaskRow(checklist),
      );

      // +New unattached task row (checklist is undefined)
      const newUnattachedTaskRow: TasksTableUtils.NewTaskRow = TasksTableUtils.createNewTaskRow();

      const newTaskRows: TasksTableUtils.NewTaskRow[] = [...newAttachedTasksRows, newUnattachedTaskRow];

      return items.concat(newTaskRows);
    }
  }, [shouldShowLoadMoreButton, inboxItemsQueryData, isGroupedByWorkflowRun]);

  const handleSelectionChanged = React.useCallback(
    ({ api }: SelectionChangedEvent<TasksTableUtils.TasksTableItem>) => {
      const nodes = api?.getSelectedNodes() ?? [];
      const identifiableNodes = nodes
        .map(node => {
          if (!TasksTableUtils.isInboxItemRow(node.data)) return null;

          node.id = getRowId(node.data);

          return node as IdentifiableTaskRow;
        })
        .filter((item): item is IdentifiableTaskRow => Boolean(item));

      setSelectedRows(identifiableNodes);
    },
    [setSelectedRows],
  );

  const refreshGrid = React.useCallback(() => {
    if (!isGrouped) {
      gridRef.current?.api?.refreshInfiniteCache();
    } else {
      const groupNodes: IRowNode[] = [];
      gridRef.current?.api?.forEachNode(node => {
        if (node.group) groupNodes.push(node);
      });
      // refresh group cells for group counts update
      if (groupNodes.length) gridRef.current?.api.refreshCells({ rowNodes: groupNodes });
    }
  }, [isGrouped]);

  const tasksTablePurgeStore = usePurgeTasksTable();
  React.useEffect(
    function purgeTasksTableOnTaskChange() {
      // Remove after AG Grid 30, this forces a full refresh
      if (!isGrouped && tasksTablePurgeStore.shouldPurge) {
        gridRef.current?.api?.purgeInfiniteCache();
        tasksTablePurgeStore.setShouldPurge(false);
      }
    },
    [isGrouped, tasksTablePurgeStore, tasksTablePurgeStore.shouldPurge],
  );

  const handleAssigneeChange = React.useCallback(async () => {
    await invalidateInboxQuery();
  }, [invalidateInboxQuery]);

  const unselectNodes = (nodeIdsToUnselect: Muid[]) => {
    const nodes = gridRef.current?.api?.getSelectedNodes() ?? [];

    nodes.forEach(node => {
      if (nodeIdsToUnselect.some(nodeId => nodeId === node.id)) {
        node.setSelected(false);
      }
    });
  };

  const unselectAllNodes = () => {
    const selectedNodes = gridRef.current?.api?.getSelectedNodes() ?? [];

    selectedNodes.forEach(node => {
      node.setSelected(false);
    });
  };

  // We allow AG Grid to manage fetching from data source and display empty state based on that.
  const [isEmpty, setIsEmpty] = useState(false);
  const isCurrentUser = inboxQueryParams.userIds.length === 1 && inboxQueryParams.userIds[0] === user?.id;
  const filtersAreEmpty =
    !inboxQueryParams.search &&
    !inboxQueryParams.itemsType &&
    (!inboxQueryParams.templateIds || inboxQueryParams.templateIds.length === 0);
  const emptyStateMode = isCurrentUser && filtersAreEmpty ? 'allClear' : 'noResults';

  const handleModelUpdated = React.useCallback(() => {
    if (isGrouped) {
      const hasItems = inboxItems.some(TasksTableUtils.isInboxItemRow);
      setIsEmpty(!hasItems);
    } else {
      const displayedRowCount = gridRef.current?.api?.getDisplayedRowCount();
      setIsEmpty(displayedRowCount === TasksTableUtils.TASKS_TABLE_NON_DATA_ROW_COUNT);
    }

    focusManager.onModelUpdated();
  }, [focusManager, inboxItems, isGrouped]);

  const location = useLeakyLocation();
  const { getNonGroupedTableProps, getGroupedByDueDateTableProps, getGroupedByWorkflowRunTableProps } = useTableProps({
    isMobile,
    hasMoreItems: shouldShowLoadMoreButton,
  });
  const tableProps = React.useMemo(() => {
    return match(groupBy)
      .with(TasksTableUtils.TasksTableGroupBy.DueDate, () =>
        getGroupedByDueDateTableProps({
          hash: location.hash as TasksTableUtils.TasksTableHash,
        }),
      )
      .with(TasksTableUtils.TasksTableGroupBy.WorkflowRun, () => getGroupedByWorkflowRunTableProps())
      .otherwise(() => getNonGroupedTableProps());
  }, [
    groupBy,
    location.hash,
    getGroupedByDueDateTableProps,
    getGroupedByWorkflowRunTableProps,
    getNonGroupedTableProps,
  ]);

  const columnDefs = React.useMemo(
    () =>
      match(groupBy)
        .with(TasksTableUtils.TasksTableGroupBy.DueDate, () => groupedByDueDateColumnDefs)
        .with(TasksTableUtils.TasksTableGroupBy.WorkflowRun, () => groupedByWorkflowRunColumnDefs)
        .otherwise(() => allColumnDefs),
    [groupBy],
  );

  const handleCellMouseOver = React.useCallback(
    (event: CellMouseOverEvent) => {
      if (event.data) {
        gridContextActions.setHoveredRowId(getRowId(event.data));
        gridContextActions.setHoveredColumnId(event.column.getColId());
      }
    },
    [gridContextActions],
  );

  const handleBodyScroll = React.useCallback(
    (event: BodyScrollEvent) => {
      if (event.direction === 'vertical') {
        gridContextActions.setHoveredRowId(null);
        gridContextActions.setHoveredColumnId(null);
      }
    },
    [gridContextActions],
  );

  const fullWidthCellRendererParams = React.useMemo(
    () => ({
      onLoadMore: async () => {
        await inboxItemsInfiniteQuery.fetchNextPage();
        scrollLoadMoreButtonIntoView();
      },
    }),
    // The ESLint wants that we pass the entire `inboxItemsInfiniteQuery`, but this object reference is untable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inboxItemsInfiniteQuery.fetchNextPage],
  );

  useInfiniteQueryObserver(inboxQueryParams, refreshGrid);

  useUnmount(() => {
    gridContextActions.deselectRow();
  });

  const handleOnSuccessUpdateItemStatus = React.useCallback(
    async (response: UpdateInboxItemsStatusMutation.Response) => {
      const completedTasks = response.filter(item => item.response === 'Ok');
      unselectNodes(completedTasks.map(task => task.todo.id));
      await GetInboxItemsQuery.invalidate(queryClient);
      gridRef.current?.api.clearFocusedCell();

      if (completedTasks.length > 0) {
        refreshGrid();
      }
    },
    [queryClient, refreshGrid],
  );

  return (
    <>
      <Box
        h="full"
        // Even if empty, keep AG Grid in DOM to fetch inbox if filters change
        display={isEmpty ? 'none' : 'auto'}
        transform={{ lg: `translateX(-${TasksTableUtils.CHECKBOX_COL_WIDTH_PX}px)` }}
        w={{ base: 'full', lg: `calc(100% + ${TasksTableUtils.CHECKBOX_COL_WIDTH_PX}px)` }}
        sx={getTableStyles(selectedRows.length > 0)}
      >
        <AgGridReactModule.AgGridReact<TasksTableUtils.TasksTableItem>
          ref={gridRef}
          columnDefs={columnDefs}
          context={gridContext}
          fullWidthCellRendererParams={fullWidthCellRendererParams}
          embedFullWidthRows
          rowData={isGrouped ? inboxItems : undefined}
          onGridReady={handleGridReady}
          onModelUpdated={handleModelUpdated}
          onSelectionChanged={handleSelectionChanged}
          onBodyScroll={handleBodyScroll}
          onCellMouseOver={handleCellMouseOver}
          {...tableProps}
        />

        <SelectionBarWrapper
          onSuccessUpdateItemsStatus={handleOnSuccessUpdateItemStatus}
          onSuccessBulkSnooze={unselectAllNodes}
          onSuccessBulkDeleteSnoozed={unselectAllNodes}
        />
        <ReassignModal onChange={handleAssigneeChange} />
      </Box>
      {isEmpty && <TasksTableEmptyState mode={emptyStateMode} />}
    </>
  );
};

function compareInboxItemsByName(a: InboxItem, b: InboxItem) {
  const nameA = getInboxItemDisplayProps(a).name;
  const nameB = getInboxItemDisplayProps(b).name;
  if (nameA === nameB) return 0;
  return (nameA ?? '') > (nameB ?? '') ? 1 : -1;
}
