import { Muid } from '@process-street/subgrade/core';
import { DataSetColumnState, SavedView, SavedViewConfig } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type UpdateSavedViewParams = {
  dataSetId: Muid;
  savedViewId: Muid;
  name: string;
  columns: DataSetColumnState[];
  config?: SavedViewConfig;
};

type UpdateSavedViewResponse = SavedView;

export const UpdateSavedViewMutation = {
  mutationFn: (params: UpdateSavedViewParams) => {
    const { dataSetId, savedViewId, ...rest } = params;
    return axiosService
      .getAxios()
      .put<UpdateSavedViewResponse>(`/1/data-sets/${dataSetId}/saved-views/${savedViewId}`, {
        id: savedViewId,
        ...rest,
      })
      .then(res => res.data);
  },
};

export const useUpdateSavedViewMutation = (
  options: UseMutationOptions<UpdateSavedViewResponse, AxiosError, UpdateSavedViewParams> = {},
) => {
  return useMutation(UpdateSavedViewMutation.mutationFn, options);
};
