import angular from 'angular';
import { connectService } from 'reducers/util';
import { HttpStatus } from '@process-street/subgrade/util';

angular
  .module('frontStreetApp.services')
  .service('ChecklistTaskAssignmentService', function ($ngRedux, $q, MessageBox, util, ChecklistTaskAssignmentActions) {
    const self = this;

    connectService('ChecklistTaskAssignmentService', $ngRedux, null, ChecklistTaskAssignmentActions)(self);

    /**
     * Get all checklist task assignments for a given checklist revision id.
     *
     * @param checklistRevisionId
     * @returns {*}
     */
    self.getAllByChecklistRevisionId = function (checklistRevisionId) {
      return self.actions.getAllByChecklistRevisionId(checklistRevisionId).then(({ payload }) => payload);
    };

    /**
     * Converts a list of assignments into a map between task ids and users.
     *
     * @param assignments
     * @returns {{}}
     */
    self.toAssigneesMap = function (assignments) {
      const assigneesMap = {};
      assignments.forEach(assignment => {
        assigneesMap[assignment.task.id] = assigneesMap[assignment.task.id] || [];
        assigneesMap[assignment.task.id].push(assignment.organizationMembership.user);
      });
      return assigneesMap;
    };

    /**
     * Assigns the user to the task.
     *
     * @param taskId
     * @param email
     * @returns {*}
     */
    self.assign = function (taskId, email) {
      return self.actions.assign(taskId, email).then(({ payload }) => payload);
    };

    /**
     * Assigns the user to the task unless they're not in the organization, in which case it will invite them.
     *
     * @param taskId
     * @param email
     */
    self.assignOrInvite = function (taskId, email) {
      return self.assign(taskId, email).catch(response => {
        if (response.status === HttpStatus.NOT_FOUND) {
          return self.invite(taskId, email);
        } else {
          return $q.reject(response);
        }
      });
    };

    self.invite = function (taskId, email) {
      return self.actions.invite(taskId, email).then(({ payload: assignment }) => assignment);
    };

    /**
     * Unassigns the user from the task.
     *
     * @param taskId
     * @param email
     * @returns {*}
     */
    self.unassign = function (taskId, email) {
      return this.actions.unassign(taskId, email).then(({ payload }) => payload);
    };
  });
