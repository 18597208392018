import * as React from 'react';
import { Box, Button, useDisclosure, Center, Text, VStack, useToast } from 'components/design/next';
import { Template } from '@process-street/subgrade/process';
import { DesignItemButton } from './design-item-button';
import { CDNImage } from 'app/components/cdn-image';
import { match, P } from 'ts-pattern';
import { AddFileModal } from './add-file-modal';
import { useQueryClient } from 'react-query';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';
import { CoverImageByTemplateIdQuery, DeleteCoverImageMutation } from 'app/features/cover-image/query-builder';
import { UploadTemplateCoverImage } from 'app/features/cover-image/components/common/upload';

export const AddCoverButton = ({ templateId }: { templateId: Template['id'] }) => {
  const addCoverDisclosure = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();
  const coverImageQuery = CoverImageByTemplateIdQuery.useQuery({ templateId });

  const deleteCoverMutation = DeleteCoverImageMutation.useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(CoverImageByTemplateIdQuery.getKey({ templateId }));
      toast({
        title: 'Cover image removed',
        status: 'success',
      });
    },
    onError: () => {
      toast({
        title: 'We are having trouble removing this cover',
        description: DefaultErrorMessages.unexpectedErrorDescription,
        status: 'error',
      });
    },
  });

  const handleRemoveCover = () => deleteCoverMutation.mutate({ templateId });

  const onUploadSuccess = async (templateId: Template['id']) => {
    await queryClient.invalidateQueries(CoverImageByTemplateIdQuery.getKey({ templateId }));
    addCoverDisclosure.onClose();
  };

  return (
    <>
      <DesignItemButton
        title="Notification cover"
        onClick={addCoverDisclosure.onOpen}
        handleRemove={handleRemoveCover}
        isLoading={deleteCoverMutation.isLoading}
      >
        {match(coverImageQuery)
          .with({ status: 'success', data: P.not(undefined) }, ({ data: coverImage }) => (
            <Box w="full" h="171px" borderRadius="base" borderColor="gray.300" borderWidth="1px" borderStyle="solid">
              <Center h="full" position="relative">
                <CDNImage
                  alt={coverImage.s3File.originalName}
                  s3File={coverImage.s3File}
                  {...{
                    transformation: {
                      width: 'auto',
                      height: 171,
                    },
                    maxHeight: '171px',
                  }}
                />
              </Center>
            </Box>
          ))
          .otherwise(() => null)}
      </DesignItemButton>
      <AddFileModal isOpen={addCoverDisclosure.isOpen} onClose={addCoverDisclosure.onClose} title="Add cover">
        <UploadTemplateCoverImage
          templateId={templateId}
          uploadingMessage="Uploading image..."
          acceptMimeTypes="image/*"
          onFinish={onUploadSuccess}
        >
          <VStack spacing={3}>
            <Button>Choose image</Button>
            <VStack>
              <Text align="center" variant="-1" color="gray.500" fontWeight="normal">
                Cover image will appear as a banner at the top of workflows, runs and emails.
              </Text>
              <Text variant="-1" color="gray.500" fontWeight="normal">
                Images wider than 700px work best
              </Text>
            </VStack>
          </VStack>
        </UploadTemplateCoverImage>
      </AddFileModal>
    </>
  );
};
