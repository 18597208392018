import { useGetOrganizationQuery } from 'features/organization/query-builder';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';

export const useTimezone = ({ fallback }: { fallback?: string } = {}) => {
  const userInfoQuery = useGetCurrentUserInfoQuery();
  const currentOrganizationQuery = useGetOrganizationQuery({
    organizationId: userInfoQuery.data?.organizationMembership.organization.id,
  });

  return userInfoQuery.data?.user.timeZone ?? currentOrganizationQuery.data?.timeZone ?? fallback;
};
