import { Template } from '../process/template-model';
import { AuditMetadata, Muid, Organization, OrganizationMembershipWithUser, Period, Ref } from '../core';

export interface FutureChecklist {
  id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  template: Ref<Template>;
  name: string;
  startDate: number;
  timeZone: string;
  nextRunDate: number;
  previousRunDate: number;
  duePeriod?: Period;
}

export interface FutureChecklistJob {
  id: Muid;
  organization: Ref<Organization>;
  futureChecklist: Ref<FutureChecklist>;
  dayInterval: number | null;
  weekInterval: number | null;
  weekDay: number | null;
  monthInterval: number | null;
  monthBy: FutureChecklistMonthBy | null;
  yearInterval: number | null;
}

export interface FutureChecklistAssignment {
  id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  futureChecklist: Ref<FutureChecklist>;
  organizationMembership: OrganizationMembershipWithUser;
}

export enum FutureChecklistFrequency {
  YEARLY = 'Yearly',
  MONTHLY = 'Monthly',
  WEEKLY = 'Weekly',
  DAILY = 'Daily',
  ONCE = '',
}

export enum FutureChecklistMonthBy {
  DAY_OF_MONTH = 'DayOfMonth',
  DAY_OF_WEEK = 'DayOfWeek',
}
