import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { Integrations } from '.';

export const IntegrationsModule = angular
  .module(
    'integrations',
    [], // dependencies
  )
  .component(
    'psIntegrations',
    reactToAngular(
      Integrations,
      [], // props
    ),
  ).name;
