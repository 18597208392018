import * as React from 'react';
import { HStack, IconButton } from '@chakra-ui/react';
import { useDrag } from 'react-dnd';
import { Icon, useToast } from 'components/design/next';
import { Template } from '@process-street/subgrade/process';
import { useFeatureFlag } from 'features/feature-flags';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';

type TemplateRowDragWrapperProps = {
  template: Template;
};

export const TemplateRowDragWrapper: React.FC<React.PropsWithChildren<TemplateRowDragWrapperProps>> = ({
  template,
  children,
}) => {
  const isGuest = useSelector(SessionSelector.isUserGuestOfSelectedOrganization);
  const isPrivate = useIsPrivateTemplate(template.id);

  const isDragAndDropEnabled = useFeatureFlag('dragAndDropLibrary');
  const toast = useToast();
  const [{ opacity }, drag, preview] = useDrag(() => ({
    type: 'template',
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging(),
    }),
    item: template,
    end: (_, monitor) => {
      const didDrop = monitor.didDrop();
      const targetId = monitor.getTargetIds();

      if (targetId.length > 0 && !didDrop) {
        toast({
          status: 'warning',
          title: 'You don’t have permission to move to this folder',
        });
      }
    },
  }));

  if (!isDragAndDropEnabled || (isGuest && !isPrivate)) return <>{children}</>;

  return (
    <HStack role="group" position="relative" ref={preview} opacity={opacity}>
      <IconButton
        size="sm"
        opacity="0"
        variant="ghost"
        colorScheme="gray"
        left="0"
        aria-label="handle"
        color="gray.300"
        icon={<Icon variant="fas" size="4" icon="grip-vertical" />}
        ref={drag}
        _groupHover={{ opacity: '1' }}
        _hover={{ bgColor: 'transparent', cursor: 'grab' }}
        _active={{ bgColor: 'transparent', cursor: 'grabbing' }}
        display={{ base: 'none', md: 'flex' }}
      />

      {children}
    </HStack>
  );
};
