import { ExportCsvOption } from 'components/dashboard/models/filters';
import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Radio, RadioGroup, Text } from 'components/design/next';
import { ModalContent, ModalOverlay, Stack } from '@chakra-ui/react';

interface ExportModalProps {
  isOpen: boolean;
  onExport: (option: ExportCsvOption) => void;
  onClose: () => void;
}

export const ExportModal: React.FunctionComponent<React.PropsWithChildren<ExportModalProps>> = ({
  isOpen,
  onExport,
  onClose,
}) => {
  const [selected, setSelected] = useState<ExportCsvOption>(ExportCsvOption.ExportVisible);

  const handleExportClick = () => {
    onExport(selected);
    onClose();
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontWeight="semibold" fontSize="lg" color="gray.700">
              Export to CSV
            </Text>
          </ModalHeader>
          <ModalBody>
            <RadioGroup name="exportCsvOption" onChange={v => setSelected(v as ExportCsvOption)} value={selected}>
              <Stack>
                <Radio value={ExportCsvOption.ExportVisible}>Export only visible columns</Radio>
                <Radio value={ExportCsvOption.ExportAll}>Export all columns</Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} variant="ghost" fontWeight="normal">
              Cancel
            </Button>
            <Button ml={2} variant="primary" onClick={handleExportClick}>
              Export CSV
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
