import { MuidUtils } from '@process-street/subgrade/core';
import {
  TaskPermissionRule,
  TaskTemplatePermissionLevel,
  TaskTemplatePermissionLevels,
} from '@process-street/subgrade/permission';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from 'components/design/next';
import {
  GetTaskTemplateRulesQuery,
  UpdateAllTaskTemplatesRulesMutation,
} from 'features/template-revisions/query-builder';
import * as React from 'react';
import { useQueryClient } from 'react-query';

export interface RulePermissionSelectorProps {
  rule: TaskPermissionRule;
  taskTemplates: TaskTemplate[];
  templateRevisionId: TemplateRevision['id'];
}

export const RulePermissionSelector: React.FC<React.PropsWithChildren<RulePermissionSelectorProps>> = ({
  rule,
  taskTemplates,
  templateRevisionId,
}) => {
  const queryClient = useQueryClient();

  const currentLevel = rule.taskUpdate
    ? TaskTemplatePermissionLevel.ViewAndComplete
    : TaskTemplatePermissionLevel.ViewOnly;

  const updateAllTaskTemplatesRulesMutation = UpdateAllTaskTemplatesRulesMutation.useMutation({
    onSuccess: _ => {
      queryClient.invalidateQueries(GetTaskTemplateRulesQuery.getKey({ templateRevisionId }));
    },
  });

  const handleChange = (level: TaskTemplatePermissionLevel) => {
    const taskRead =
      level === TaskTemplatePermissionLevel.ViewAndComplete || level === TaskTemplatePermissionLevel.ViewOnly;
    const taskUpdate = level === TaskTemplatePermissionLevel.ViewAndComplete;

    const rules: Array<TaskPermissionRule> = taskTemplates.map(taskTemplate => {
      const isExistingRule = rule.targetTaskTemplateGroup.id === taskTemplate.group.id;
      if (isExistingRule) {
        return {
          ...rule,
          taskRead,
          taskUpdate,
        };
      } else {
        return {
          ...rule,
          id: MuidUtils.randomMuid(),
          targetTaskTemplateGroup: taskTemplate.group,
          taskRead,
          taskUpdate,
        };
      }
    });

    updateAllTaskTemplatesRulesMutation.mutate({
      templateRevisionId,
      rules,
    });
  };

  const levels = TaskTemplatePermissionLevels.filter(level => level !== TaskTemplatePermissionLevel.NoAccess);

  return (
    <Menu autoSelect={false} isLazy={true}>
      {currentLevel && (
        <>
          <MenuButton
            as={Button}
            variant="unstyled"
            rightIcon={<Icon icon="angle-down" size="3" variant="far" />}
            size="sm"
            color="gray.600"
            textAlign="right"
            display="inline-flex"
            alignItems="center"
            fontSize="sm"
            data-testid="rule-level-selector-menu"
          >
            {currentLevel}
          </MenuButton>

          <MenuList color="gray.600" zIndex="dropdown">
            {levels.map(level => (
              <MenuItem onClick={() => handleChange(level)} key={level} data-item-selected={currentLevel === level}>
                {level}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
