import { useSelector } from '@xstate/react';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useMemo } from 'react';
import _groupBy from 'lodash/groupBy';
import _keyBy from 'lodash/keyBy';
import _isEqual from 'lodash/isEqual';
import { ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';
import { useTaskTemplateListActorRef } from '../../hooks/use-task-templates-list-actor';
import { TaskTemplateListActorSelectors } from './task-template-list-machine';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';
import { TaskTemplate, TaskTemplateTaskType } from '@process-street/subgrade/process';

export namespace TaskListHooks {
  export const useTaskAssignmentRulesMap = () => {
    const editorActor = useFormEditorPageActorRef();

    const taskAssignmentRules = useSelector(editorActor, FormEditorPageActorSelectors.getTaskAssignmentRules, _isEqual);
    return useMemo(
      () => _groupBy(taskAssignmentRules, assignment => assignment.targetTaskTemplateGroup.id),
      [taskAssignmentRules],
    );
  };

  export const useTaskAssignmentsMap = () => {
    const editorActor = useFormEditorPageActorRef();

    const taskAssignments = useSelector(editorActor, FormEditorPageActorSelectors.getTaskAssignments, _isEqual);
    return useMemo(() => _groupBy(taskAssignments, assignment => assignment.taskTemplate.id), [taskAssignments]);
  };

  export const useOrganizationMembershipsMap = () => {
    const editorActor = useFormEditorPageActorRef();

    const oms = useSelector(editorActor, FormEditorPageActorSelectors.getOrganizationMemberships, _isEqual);
    return useMemo(() => _keyBy(oms, om => om.id), [oms]);
  };

  export const useDueDateRulesMap = () => {
    const editorActor = useFormEditorPageActorRef();

    const dueDateRules = useSelector(editorActor, FormEditorPageActorSelectors.getDueDateRules, _isEqual);
    return useMemo(
      () => _keyBy(dueDateRules, ruleDefinition => ruleDefinition.targetTaskTemplateGroup?.id ?? ''),
      [dueDateRules],
    );
  };

  export const useIsTaskTemplateAssociatedWithRules = () => {
    const editorActor = useFormEditorPageActorRef();

    const checklistRules = useSelector(editorActor, FormEditorPageActorSelectors.getRules, _isEqual);
    return useMemo(
      () => ConditionalLogicCommonUtils.makeTaskTemplateHasAssociatedRule(checklistRules),
      [checklistRules],
    );
  };

  export const useTaskTemplatesSelectionMap = () => {
    const taskTemplateListActor = useTaskTemplateListActorRef();
    const selectedTaskTemplates = useSelector(
      taskTemplateListActor,
      TaskTemplateListActorSelectors.getSelectedTaskTemplates,
    );

    return useMemo(() => Object.fromEntries(selectedTaskTemplates.map(tt => [tt.id, true])), [selectedTaskTemplates]);
  };

  export const useTaskTemplatesDisplayNumberMap = (taskTemplates: TaskTemplate[]) =>
    useMemo(
      () =>
        Object.fromEntries(
          taskTemplates
            .filter(tt => tt.taskType !== TaskTemplateTaskType.AI)
            .map((taskTemplate, index) => [taskTemplate.id, index + 1]),
        ),
      [taskTemplates],
    );
}
