import { Muid, Organization, Period, Ref } from '../core';
import { TaskTemplateGroup, TemplateRevision } from './template-model';
import { WidgetGroup } from './widget-model';

export enum DueDateRuleSourceType {
  FormFieldValue = 'FormFieldValue',
  TaskCompletedDate = 'TaskCompletedDate',
  PreviousTaskCompletedDate = 'PreviousTaskCompletedDate',
  TaskDueDate = 'TaskDueDate', // Not implemented
  ChecklistStartDate = 'ChecklistStartDate',
  ChecklistDueDate = 'ChecklistDueDate',
}

export enum DueDateRuleOffsetDirection {
  Before = 'Before',
  After = 'After',
}

export const DueDateRuleChecklistType = 'Checklist';
export const DueDateRuleTaskType = 'Task';
export type DueDateRuleType = 'Checklist' | 'Task';

export interface DueDateRuleDefinition {
  id: Muid;
  organization: Ref<Organization>;
  templateRevision: Ref<TemplateRevision>;
  targetTaskTemplateGroup?: Ref<TaskTemplateGroup>;
  sourceType: DueDateRuleSourceType;
  formFieldWidgetGroup?: WidgetGroup;
  taskTemplateGroup?: TaskTemplateGroup;
  offsetDirection: DueDateRuleOffsetDirection;
  dueOffset: Period;
  workdaysOnly: boolean;
  ruleType: DueDateRuleType;
}
