import { AvatarSize, avatarSizeMap } from 'components/common/Avatar';
import { Avatar as AvatarModel, Organization, User } from '@process-street/subgrade/core';
import isString from 'lodash/isString';
import { isAnonymousUser, isStandardUser } from '@process-street/subgrade/util/user-type-utils';

/**
 * The default URL for the Anonymous user.
 * @type {string}
 */
const ANONYMOUS_URL = 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&f=y&s=';

export const MAX_BADGE_DISPLAY_VALUE = 99;

export const ANONYMOUS_USER_INITIALS = '?';

type UserWithOrganization = User & Organization;

const getName = (user: UserWithOrganization): string => user?.username || user?.name;

const getSizeNumber = (size?: AvatarSize) => (size && avatarSizeMap[size] ? avatarSizeMap[size] : 64);

export const extractInitials = (value = '') => {
  let initials;
  const words = (value || '?').split(/[\s._-]+/);
  if (words.length > 1) {
    initials = words
      .map(word => (word.length ? word.charAt(0).toUpperCase() : '?'))
      .slice(0, 2)
      .join('');
  } else {
    initials = words[0].slice(0, 2);
  }
  return initials;
};

export const getAvatarUrl = (user?: User, avatarSize?: AvatarSize) => {
  const size = getSizeNumber(avatarSize);
  if (!user) return;
  if (isAnonymousUser(user)) {
    return ANONYMOUS_URL + size;
  } else if (user.avatarFile) {
    const fileId = user.avatarFile.id;
    return `https://aang.s3.amazonaws.com/${fileId}-${size}.jpg`;
  } else if (user.avatarUrl) {
    return user.avatarUrl;
  }
  return;
};

export const getAvatar = (user?: User | string, avatarSize?: AvatarSize): AvatarModel => {
  const avatar = {} as AvatarModel;
  if (isString(user)) {
    avatar.initials = extractInitials(user);
    avatar.title = user;
  } else if (user) {
    const avatarUrl = getAvatarUrl(user, avatarSize);
    if (avatarUrl) {
      avatar.url = avatarUrl;
    } else {
      avatar.initials = extractInitials(getName(user as UserWithOrganization));
    }
    avatar.title = getName(user as UserWithOrganization);
    avatar.unconfirmed = isStandardUser(user) && !user.emailVerified;
  } else {
    avatar.initials = '?';
  }
  return avatar;
};

export const getAvatarAttributes = (url?: string, initials?: string, user?: User): AvatarModel => {
  const avatar = getAvatar(user);
  return {
    ...avatar,
    initials: initials || avatar.initials,
    url: url || avatar.url,
  };
};

export const getBadgeValue = (value: number): string | number =>
  value > MAX_BADGE_DISPLAY_VALUE ? `${MAX_BADGE_DISPLAY_VALUE}+` : value;
