export type EqualityPredicate<T> = (a: T, b: T) => boolean;

export type Spec<T> = (T extends object ? keyof T : string) | EqualityPredicate<T>;

export class EquatableSet<T extends Record<string, any>> {
  private _equals: EqualityPredicate<T>;
  private _spec?: Spec<T>;
  private _items: T[];

  constructor(spec?: Spec<T>) {
    if (typeof spec === 'function') {
      this._equals = spec;
      this._spec = spec;
    } else if (typeof spec === 'string') {
      const key = spec;
      this._spec = spec;
      this._equals = (a: T, b: T) => {
        return a && b && a[key] === b[key];
      };
    } else {
      this._equals = function (a, b) {
        return a === b;
      };
    }

    this._items = [];
  }

  add(item: T) {
    const index = this._items.findIndex(i => this._equals(i, item));
    if (index >= 0) {
      this._items.splice(index, 1, item);
    } else {
      this._items.push(item);
    }
    return this;
  }

  addAll(items: T[]) {
    items.forEach(item => {
      this.add(item);
    });

    return this;
  }

  remove(item: T) {
    this._items = this._items.filter(i => !this._equals(i, item));
    return this;
  }

  removeAll(items: T[]) {
    items.forEach(item => {
      this.remove(item);
    });

    return this;
  }

  contains(item: T) {
    return this._items.some(i => this._equals(i, item));
  }

  filter(f: (item: T) => boolean) {
    return new EquatableSet<T>(this._spec).addAll(this._items.filter(f));
  }

  find(predicate: (item: T) => boolean) {
    return this._items.find(predicate);
  }

  toArray() {
    return this._items.slice();
  }

  clone() {
    return new EquatableSet<T>(this._spec).addAll(this._items);
  }
}
