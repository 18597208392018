const merge = (newState = {}, savedState) => {
  Object.keys(savedState).forEach(key => {
    const savedValue = savedState[key];
    const newValue = newState[key];
    // if inboundState is just an empty string ignore it
    if (isPlainEnoughObject(savedValue)) {
      // if object is plain enough shallow merge the new values (hence "Level2")
      newState[key] = { ...newValue, ...savedValue };
    } else if (isEmptyString(newValue)) {
      // otherwise hard set
      newState[key] = savedValue;
    }
  });
  return newState;
};

export function stateMerger(savedState, originalState, reducedState) {
  const newState = { ...reducedState };

  // only rehydrate if inboundState exists and is an object
  if (savedState && typeof savedState === 'object') {
    Object.keys(savedState).forEach(key => {
      // ignore _persist data
      if (key === '_persist') {
        return;
      }
      // if reducer modifies substate, skip auto rehydration
      if (originalState[key] !== reducedState[key]) {
        return;
      }
      if (isPlainEnoughObject(reducedState[key])) {
        // if object is plain enough shallow merge the new values (hence "Level2")
        newState[key] = merge({ ...newState[key] }, { ...savedState[key] });
        return;
      }
      // otherwise hard set
      newState[key] = savedState[key];
    });
  }

  return newState;
}

function isPlainEnoughObject(o) {
  return o !== null && !Array.isArray(o) && typeof o === 'object';
}

function isEmptyString(o) {
  return typeof o === 'string' && o.length === 0;
}
