import * as React from 'react';
import { Text, TextProps } from 'components/design/next';
import { useTemplateId } from '../../utils/use-template-id';
import { useGetTemplateQuery } from 'features/template/query-builder';

type TemplateTitleProps = TextProps;

export const TemplateInfo: React.FC<React.PropsWithChildren<TemplateTitleProps>> = props => {
  const templateId = useTemplateId();
  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;

  if (!template?.name) {
    return null;
  }

  return (
    <Text as="h2" variant="2" fontWeight="medium" noOfLines={1} {...props}>
      Automations for {template.name}
    </Text>
  );
};
