import { Muid } from '@process-street/subgrade/core';
import { AxiosResponse } from 'axios';
import { axiosService } from 'services/axios-service';

type Result = { apiKey: string };
const getApiKey = async (organizationId: Muid): Promise<Result> => {
  return axiosService
    .getAxios()
    .get(`/1/organizations/${organizationId}/algolia/api-key`)
    .then((response: AxiosResponse<Result>) => response.data);
};

export const GlobalSearchApi = {
  getApiKey,
};
