import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Checkbox,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from 'components/design/next';
import { Icon, IconProps } from 'components/design/next';
import { TextInputField } from '../components/text-input-field';
import { Form, Formik } from 'formik';
import * as React from 'react';
import {
  createAccountSchema,
  validatePasswordLowercase,
  validatePasswordNoWhitespace,
  validatePasswordNumbers,
  validatePasswordSize,
  validatePasswordSpecialChar,
  validatePasswordUppercase,
} from './form.schema';
import { getError, YupTestFunction } from '../helpers';
import { Title } from '../components/title';
import { match, P } from 'ts-pattern';
import { PasswordInputField } from '../components/password-input-field';
import { ShellContentActions } from '../components/shell-content-actions';

export type FormValues = {
  username: string;
  password: string;
  subscribe: boolean;
};

export type CreateAccountFormProps = {
  onSubmit: (values: FormValues) => void;
  isLoading?: boolean;
  error?: string;
};

const initialValues: FormValues = {
  username: '',
  password: '',
  subscribe: false,
};

export const CreateAccountForm: React.VFC<CreateAccountFormProps> = ({ error, onSubmit, isLoading = false }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => onSubmit(values)}
      validationSchema={createAccountSchema}
      validateOnBlur
      validateOnChange
    >
      {({ handleBlur, handleChange, handleSubmit, initialValues, values, errors, touched, submitCount }) => (
        <Box w="full" sx={{ '&>form': { width: 'full' } }}>
          <Form onSubmit={handleSubmit}>
            <VStack m="0 auto" spacing="8" alignItems="flex-start" maxWidth="xl">
              <Title>Create your account</Title>

              {error && (
                <Alert status="error">
                  <AlertIcon />
                  {error}
                </Alert>
              )}

              <TextInputField
                error={getError('username', submitCount, errors, touched)}
                label="Full Name"
                name="username"
                defaultValue={initialValues.username}
                onChange={handleChange}
                onBlur={handleBlur}
                autoFocus={true}
                isRequired
              />

              <Box w="full">
                <PasswordInputField
                  label="Password"
                  name="password"
                  error={getError('password', submitCount, errors, touched)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isRequired
                />
              </Box>

              <Grid
                templateColumns={{ base: '1fr', sm: '1fr 1fr' }}
                w="full"
                paddingLeft="0"
                color="gray.600"
                fontSize="small"
                lineHeight="tall"
              >
                {passwordRequirements.map(([label, validate]) => {
                  const isTouched = touched.password ?? false;
                  const isValid = validate(values.password);

                  const iconProps = match<boolean[], IconProps>([isTouched, isValid])
                    .with([P.boolean, true], () => ({
                      icon: 'check',
                      color: 'green',
                    }))
                    .with([true, false], () => ({ icon: 'times', color: 'red' }))
                    .otherwise(() => ({
                      icon: 'circle',
                      color: 'gray.600',
                      size: '1',
                      variant: 'fas',
                    }));

                  return (
                    <GridItem spacing="2" as={HStack} key={label}>
                      <Center w="4" h="4">
                        <Icon size="4" variant="far" {...iconProps} />
                      </Center>
                      <Text fontSize="small">{label}</Text>
                    </GridItem>
                  );
                })}
              </Grid>

              <Checkbox
                name="subscribe"
                spacing="2"
                defaultChecked={initialValues.subscribe}
                sx={{
                  '&>.chakra-checkbox__control': {
                    borderRadius: 'base',
                    borderColor: 'gray.400',
                    borderWidth: '1px',
                    width: 5,
                    height: 5,
                  },
                }}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Text fontSize="sm">
                  Subscribe to <strong>Process Street Blog</strong> (recommended)
                </Text>
              </Checkbox>

              <ShellContentActions>
                <Button
                  px="5"
                  height="12"
                  iconSpacing="2"
                  type="submit"
                  leftIcon={<Icon size="4" variant="far" icon="check" />}
                  isLoading={isLoading}
                >
                  Next
                </Button>
              </ShellContentActions>
            </VStack>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

const passwordRequirements: [string, YupTestFunction][] = [
  ['8 characters minimum', validatePasswordSize],
  ['One number', validatePasswordNumbers],
  ['One lowercase character', validatePasswordLowercase],
  ['One special character', validatePasswordSpecialChar],
  ['One uppercase character', validatePasswordUppercase],
  ['No whitespace', validatePasswordNoWhitespace],
];
