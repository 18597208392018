import * as React from 'react';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect';
import { OnChangeValue } from 'react-select';
import { useCustomNotificationConfigContext } from '../../custom-notification-config-provider';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { UserType } from '@process-street/subgrade/core';
import { useCustomNotificationsStore } from '../../custom-notifications-store';
import { FieldType, FormFieldWidget, isFormFieldWidget, NativeAutomation } from '@process-street/subgrade/process';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { useTemplateRevision } from '../../template-revision-context';
import {
  DEFAULT_ROLES,
  getBaseGroups,
  getRecipientToOption,
  Option,
  OptionGroup,
  OptionLabel,
  optionToRecipient,
  userToOption,
  widgetToOption,
} from './recipient-select-utils';

export type { Option };

export const useRecipientSelect = () => {
  const updateRecipients = useCustomNotificationsStore(state => state.updateRecipients);

  const { config } = useCustomNotificationConfigContext();
  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const usersQuery = useGetAllOrganizationMembershipsQuery({ organizationId: selectedOrganizationId });
  const usersMap = React.useMemo(() => new Map(usersQuery.data?.map(({ user }) => [user.id, user])), [usersQuery.data]);

  const templateRevision = useTemplateRevision();
  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevision.id, {
    select: widgets =>
      widgets.filter(
        (w): w is FormFieldWidget => isFormFieldWidget(w) && [FieldType.Members, FieldType.Email].includes(w.fieldType),
      ),
  });
  const widgetsByKey = React.useMemo(
    () => new Map(widgetsQuery.data?.map(widget => [widget.key, widget])),
    [widgetsQuery.data],
  );

  const isLoading = !usersQuery.data || !widgetsQuery.data;

  const recipientToOption = React.useCallback(
    (recipient: NativeAutomation.SendEmailActionRecipient): Option => {
      return getRecipientToOption({ usersMap, widgetsByKey })(recipient);
    },
    [usersMap, widgetsByKey],
  );

  const groupedOptions = React.useMemo<OptionGroup[]>(() => {
    if (isLoading) return [];

    const users: Option[] = usersQuery.data
      .filter(({ user }) => user.userType === UserType.Standard)
      .map(({ user }) => userToOption(user));
    const widgets: Option[] = widgetsQuery.data.map(widget => widgetToOption(widget));
    const roles: Option[] = DEFAULT_ROLES;
    return [
      { label: OptionLabel.Dynamic, options: [...roles, ...widgets] },
      { label: OptionLabel.Users, options: users },
    ];
  }, [isLoading, usersQuery.data, widgetsQuery.data]);

  const derivedSortedOptions = React.useMemo(() => {
    if (groupedOptions.length === 0) return [];

    const selectedSet = new Set(config.recipients?.map(recipient => recipientToOption(recipient).key) ?? []);
    return groupedOptions
      .flatMap(group => group.options)
      .reduce((acc, a) => {
        if (selectedSet.has(a.key)) {
          acc[0].options.push(a);
        } else if (a.type === 'role' || a.type === 'formField') {
          acc[1].options.push(a);
        } else if (a.type === 'user') {
          acc[2].options.push(a);
        }

        return acc;
      }, getBaseGroups());
  }, [config.recipients, groupedOptions, recipientToOption]);

  const [sortedOptions, setSortedOptions] = React.useState<OptionGroup[]>(derivedSortedOptions);
  if (sortedOptions.flatMap(g => g.options).length === 0 && widgetsQuery.isSuccess && usersQuery.isSuccess) {
    setSortedOptions(derivedSortedOptions);
  }

  const optionsMap = React.useMemo(
    () => new Map(groupedOptions.flatMap(group => group.options).map(option => [option.key, option])),
    [groupedOptions],
  );

  const values = React.useMemo<Option[]>(
    () =>
      isLoading
        ? []
        : config.recipients
            ?.map(recipient => optionsMap.get(recipientToOption(recipient).key))
            .filter((o): o is Option => o !== undefined) ?? [],
    [config.recipients, isLoading, optionsMap, recipientToOption],
  );

  const onMenuClose = () => {
    setSortedOptions(derivedSortedOptions);
  };

  const onChange = (newOptions: OnChangeValue<Option, true>) => {
    const safeOptions = BlvdSelectHelpers.isOptionsType<Option>(newOptions) ? newOptions : [];
    updateRecipients(config, safeOptions.map(optionToRecipient));
  };

  return {
    isLoading,
    onMenuClose,
    sortedOptions,
    values,
    onChange,
  };
};
