import { GroupBase, MultiValueRemoveProps } from 'react-select';
import { Box, Icon, IconButton } from 'components/design/next';
import React from 'react';

export const UserSelectedItemRemove = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MultiValueRemoveProps<Option, IsMulti, Group>,
) => {
  const { className: _, ...p } = props.innerProps;

  return (
    <Box paddingRight={2}>
      {/* @ts-expect-error button div ref clash */}
      <IconButton
        aria-label="Remove User"
        icon={<Icon variant="far" icon="xmark" size="4" color="gray.400" _hover={{ color: 'gray.500' }} />}
        variant="unstyled"
        {...p}
      />
    </Box>
  );
};
