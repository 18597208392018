import * as React from 'react';
import { Formik, Form } from 'formik';
import { HiddenFormFieldWidget, TEXT_MAX_LENGTH } from '@process-street/subgrade/process';
import {
  ModalBody,
  ModalFooter,
  Text,
  ButtonGroup,
  Button,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  useModalContext,
} from 'components/design/next';
import { HiddenSettingsSchema, hiddenSettingsSchema } from './hidden-settings-schema';
import { DefaultValueField } from '../common/default-value-field';
import { useWidgetSettingsContext } from '../widget-settings-context';

export const HiddenSettings = () => {
  const { widget, onUpdate } = useWidgetSettingsContext<HiddenFormFieldWidget>();
  const { onClose } = useModalContext();

  const initialValues: HiddenSettingsSchema = {
    defaultValue: widget.config.defaultValue ?? '',
  };

  const handleSubmit = (values: HiddenSettingsSchema) => {
    onUpdate({ ...widget, config: values });
    onClose();
  };

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader p={8}>
        <Text variant="2">{widget.label || 'Untitled hidden field'}</Text>
      </ModalHeader>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={hiddenSettingsSchema}>
        {({ isValid, dirty }) => (
          <Form>
            <ModalBody px={9} py={2}>
              <DefaultValueField maxLength={TEXT_MAX_LENGTH} />
            </ModalBody>
            <ModalFooter p={6}>
              <ButtonGroup>
                <Button aria-label="cancel changes" variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button aria-label="set constraints" isDisabled={!(isValid && dirty)} type="submit">
                  Apply
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};
