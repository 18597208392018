import { Button, ListItem, ButtonGroup, Text } from 'components/design/next';
import { DataSet, SavedView } from '@process-street/subgrade/process';
import * as React from 'react';
import { match } from 'ts-pattern';
import { UpdateSavedViewButton } from '../update-saved-view-button';

export type DataSetSavedViewsSidebarMenuItemProps = {
  dataSet: DataSet | null;
  savedView: SavedView;
  isSelected?: boolean;
  onSelect: (savedView: SavedView) => void;
};

export const DataSetSavedViewsSidebarMenuItem: React.FC<DataSetSavedViewsSidebarMenuItemProps> = ({
  dataSet,
  savedView,
  isSelected,
  onSelect,
}) => {
  const { canUpdate } = savedView;

  const buttonStyles = match({ isSelected })
    .with({ isSelected: true }, () => ({
      fontWeight: '500',
      color: 'brand.500',
    }))
    .otherwise(() => ({
      bgColor: 'transparent',
      fontWeight: '400',
      color: 'gray.600',
    }));

  return (
    <ListItem aria-selected={isSelected}>
      <ButtonGroup
        isAttached
        _hover={{ bgColor: 'brand.100' }}
        bgColor={isSelected ? 'brand.100' : 'transparent'}
        borderRadius="full"
        display="flex"
        role="group"
      >
        <Button
          onClick={() => onSelect(savedView)}
          w="full"
          borderRadius="full"
          variant="ghost"
          justifyContent="flex-start"
          _groupHover={{
            color: 'brand.500',
            bgColor: 'transparent',
          }}
          {...buttonStyles}
        >
          <Text noOfLines={1}>{savedView.name}</Text>
        </Button>

        {dataSet && canUpdate && <UpdateSavedViewButton dataSet={dataSet} savedView={savedView} />}
      </ButtonGroup>
    </ListItem>
  );
};
