import * as React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { ToInput } from 'features/widgets/components/send-rich-email/template/edit/to-input';
import { Field, Label } from 'features/widgets/components/send-rich-email/common/common';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { ButtonGroup, useCheckbox, Box, Text, InputRightElement } from 'components/design/next';
import { useFunctionRef } from 'hooks/use-function-ref';
import { SendRichEmailFormFieldWidget } from '@process-street/subgrade/process';
import { useWidgetUpdateOverrideListener } from 'hooks/use-widget-update-override-listener';
import { useEmailField, COMMA } from './use-email-field';

export type EmailFieldValues = Pick<SendRichEmailFormFieldWidget['config'], 'to' | 'cc' | 'bcc' | 'subject'>;

export type EmailFieldsProps = {
  fieldValues: EmailFieldValues;
  templateRevisionId: Muid;
  onChange: (values: EmailFieldValues) => void;
  widget: SendRichEmailFormFieldWidget;
};

export const EmailFields: React.FC<React.PropsWithChildren<EmailFieldsProps>> = ({
  fieldValues,
  templateRevisionId,
  onChange,
  widget,
}) => {
  const onChangeRef = useFunctionRef(onChange);

  const makeOnChange = React.useCallback(
    (key: keyof EmailFieldValues) => (value: string[]) => {
      onChangeRef.current({ ...fieldValues, [key]: value });
    },
    [fieldValues, onChangeRef],
  );

  const { setValue: setTo, ...toProps } = useEmailField({ initialValue: fieldValues.to, onChange: makeOnChange('to') });

  const { setValue: setCc, ...ccProps } = useEmailField({ initialValue: fieldValues.cc, onChange: makeOnChange('cc') });
  const ccCheckbox = useCheckbox({ defaultChecked: Boolean(ccProps.value) });

  const { setValue: setBcc, ...bccProps } = useEmailField({
    initialValue: fieldValues.bcc,
    onChange: makeOnChange('bcc'),
  });
  const bccCheckbox = useCheckbox({ defaultChecked: Boolean(bccProps.value) });

  const [subject, setSubject] = React.useState(fieldValues.subject ?? '');
  const handleSubjectChange = React.useCallback(
    (value: string) => {
      setSubject(value);
      onChangeRef.current({ ...fieldValues, subject: value });
    },
    [fieldValues, onChangeRef],
  );

  const updateValues = React.useCallback(
    (fieldValues: EmailFieldValues) => {
      setTo(fieldValues.to?.join(COMMA) ?? '');
      setCc(fieldValues.cc?.join(COMMA) ?? '');
      setBcc(fieldValues.bcc?.join(COMMA) ?? '');
      setSubject(fieldValues.subject ?? '');
    },
    [setBcc, setCc, setTo],
  );

  useWidgetUpdateOverrideListener(widget, updated => updateValues(updated.config));

  React.useEffect(
    function syncPropChanges() {
      updateValues(fieldValues);
    },
    [fieldValues, updateValues],
  );

  return (
    <>
      <Field>
        <Label>To</Label>
        <ToInput
          templateRevisionId={templateRevisionId}
          {...toProps}
          aria-label="to emails"
          pr="25"
          inputRightElement={({ mergeTagsMenu }) => (
            <InputRightElement w="25" _focusWithin={{ zIndex: 3 }}>
              <ButtonGroup alignItems="center">
                <TextCheckbox checkbox={ccCheckbox}>CC</TextCheckbox>
                <TextCheckbox checkbox={bccCheckbox}>BCC</TextCheckbox>
                {mergeTagsMenu}
              </ButtonGroup>
            </InputRightElement>
          )}
        />
      </Field>

      {ccCheckbox.state.isChecked ? (
        <Field>
          <Label>CC</Label>
          <ToInput templateRevisionId={templateRevisionId} {...ccProps} aria-label="cc emails" />
        </Field>
      ) : null}

      {bccCheckbox.state.isChecked ? (
        <Field>
          <Label>BCC</Label>
          <ToInput templateRevisionId={templateRevisionId} {...bccProps} aria-label="bcc emails" />
        </Field>
      ) : null}

      <Field>
        <Label>Subject</Label>
        <ToInput
          templateRevisionId={templateRevisionId}
          value={subject}
          onChange={handleSubjectChange}
          placeholder="Subject"
          mergeTagTarget={MergeTagsConstants.Target.GENERAL}
        />
      </Field>
    </>
  );
};

const TextCheckbox: React.FC<React.PropsWithChildren<{ checkbox: ReturnType<typeof useCheckbox> }>> = ({
  checkbox,
  children,
}) => {
  return (
    <Box
      {...{
        ...checkbox.getLabelProps(),
        cursor: 'pointer',
        as: 'label',
        display: 'flex',
        mb: '0',
        sx: {
          '.focus-visible + [data-focus]': {
            boxShadow: 'outline',
            borderRadius: 'md',
            zIndex: 1,
          },
        },
      }}
    >
      <input {...checkbox.getInputProps()} />
      <Text
        {...{
          ...checkbox.getCheckboxProps(),
          fontWeight: 'medium',
          ...(checkbox.state.isChecked ? { color: 'gray.300' } : { color: 'brand.500' }),
        }}
      >
        {children}
      </Text>
    </Box>
  );
};
