import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { MutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { useMutation as useRQMutation } from 'react-query';
import { SnoozedAssignment } from '@process-street/subgrade/inbox';

export namespace DeleteSnoozeAssignmentMutation {
  export type Params = {
    assignmentId: Muid;
  };

  export const key = ['delete', 'assignment', 'snooze'];

  export const mutationFn = ({ assignmentId }: Params) =>
    axiosService
      .getAxios()
      .delete<SnoozedAssignment>(`/1/assignments/${assignmentId}/snoozed`)
      .then(res => res.data);

  export const useMutation = (options: MutationOptions<SnoozedAssignment, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
