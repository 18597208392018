import { FieldType } from '@process-street/subgrade/process';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';
import { FormFieldValueSchemaTests } from '../common/validation/form-field-value-schema-tests';

export const snippetSettingsSchema = yup.object({
  config: yup
    .object({
      value: yup.string().max(4000, `The value must be less than 4000 characters`).notRequired(),
    })
    .required(),
  constraints: yup.object({}),
}) as ObjectSchema<WidgetSettings<FieldType.Snippet>>;

type ValidationSchemaProps = {
  required: boolean;
};

export const makeSnippetValidationSchema = ({ required }: ValidationSchemaProps) => {
  return yup.string().test(FormFieldValueSchemaTests.required(required));
};
