import { referencesNormalizer } from 'reducers/entities/entities.utils';
import { composeReducerObjects, handleActionsOnSuccess } from 'reducers/util';
import { TEMPLATE_DELETE_BY_ID } from 'reducers/template/template.actions';
import {
  TEMPLATE_REVISION_CREATE,
  TEMPLATE_REVISION_DELETE_BY_ID,
  TEMPLATE_REVISION_FINISH_BY_ID,
  TEMPLATE_REVISION_GET_ALL_BY_TEMPLATE_ID,
  TEMPLATE_REVISION_GET_ALL_NEWEST_BY_TEMPLATE_ID,
  TEMPLATE_REVISION_MULTIPLE_RESPONSE_ACTIONS,
  TEMPLATE_REVISION_SINGLE_RESPONSE_ACTIONS,
} from 'reducers/template-revision/template-revision.actions';
import { combineReducers } from 'redux';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';

//TODO this is partial reducer for TemplateRevision, there was no goal to move it to Entities yet
// It's

const normalizeTemplateRevision = referencesNormalizer(['template', 'organization']);

export const templateRevisionEntitiesReducer = composeReducerObjects(
  {
    [TEMPLATE_REVISION_SINGLE_RESPONSE_ACTIONS]: (state, { payload: templateRevision }) =>
      EntitiesReducerUtils.upsert(state, templateRevision, normalizeTemplateRevision),
    [TEMPLATE_REVISION_MULTIPLE_RESPONSE_ACTIONS]: (state, { payload: templateRevisions }) =>
      EntitiesReducerUtils.upsertAll(state, templateRevisions, normalizeTemplateRevision),
    [TEMPLATE_REVISION_DELETE_BY_ID]: (state, { payload: deletedTemplateRevision }) =>
      EntitiesReducerUtils.deleteById(state, deletedTemplateRevision.id),
  },
  {
    [TEMPLATE_DELETE_BY_ID]: (state, { meta: { templateId } }) => {
      const filteredValues = Object.values(state).filter(
        revision => revision && revision.template && revision.template.id !== templateId,
      );
      return EntitiesReducerUtils.upsertAll({}, filteredValues);
    },
  },
);

const byTemplateIdReducer = handleActionsOnSuccess(
  {
    [TEMPLATE_REVISION_GET_ALL_BY_TEMPLATE_ID]: (state, { payload: templateRevisions, meta: { templateId } }) =>
      LookupsReducerUtils.replaceAllUsingSelectorFunctions(state, templateRevisions, () => templateId),
    [TEMPLATE_REVISION_DELETE_BY_ID]: (state, { payload: deletedTemplateRevision }) => {
      if (deletedTemplateRevision) {
        return LookupsReducerUtils.deleteUsingSelectorFunctions(state, deletedTemplateRevision, rev => rev.template.id);
      } else {
        return state;
      }
    },
    [TEMPLATE_DELETE_BY_ID]: (state, { meta: { templateId } }) => EntitiesReducerUtils.deleteById(state, templateId),
  },
  {},
);

const newestByTemplateIdReducer = handleActionsOnSuccess(
  {
    [TEMPLATE_REVISION_GET_ALL_NEWEST_BY_TEMPLATE_ID]: (state, { payload: templateRevisions, meta: { templateId } }) =>
      LookupsReducerUtils.upsertAllUsingSelectorFunctions(state, templateRevisions, () => templateId),
    [TEMPLATE_REVISION_FINISH_BY_ID]: (
      state,
      {
        payload: {
          template: { id: templateId },
        },
      },
    ) => EntitiesReducerUtils.deleteById(state, templateId),
    [TEMPLATE_REVISION_DELETE_BY_ID]: (
      state,
      {
        payload: {
          template: { id: templateId },
        },
      },
    ) => EntitiesReducerUtils.deleteById(state, templateId),
    [TEMPLATE_REVISION_CREATE]: (
      state,
      {
        payload: {
          template: { id: templateId },
        },
      },
    ) => EntitiesReducerUtils.deleteById(state, templateId),
  },
  {},
);

export const templateRevisionReducer = combineReducers({
  byTemplateId: byTemplateIdReducer,
  newestByTemplateId: newestByTemplateIdReducer,
});
