import { RunButton } from 'features/new-buttons/run-button/run-button';
import { Button, Icon, Text } from 'components/design/next';
import * as React from 'react';
import { CreateChecklistSource } from 'services/checklist-service.interface';

export const BootstrapRunButton: React.FC<React.PropsWithChildren<{ source: CreateChecklistSource }>> = ({
  source,
}) => (
  <RunButton source={source}>
    <Button
      // match bootstrap navbar btn padding
      my="11px"
      aria-label="run"
      leftIcon={<Icon icon="play" variant="far" size="4" />}
      iconSpacing={[0, 0, '2']}
    >
      <Text display={['none', null, 'inline-block']}>Run</Text>
    </Button>
  </RunButton>
);
