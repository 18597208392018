import { DerivedChecklistStatus as Status } from '@process-street/subgrade/dashboard';
import { makeTooltipContext } from '../chart-tooltip';

export type TaskStat = { status: Status; total: number };

export type TooltipData = TaskStat;

const {
  tooltipReducer,
  makeInitialTooltipState,
  usableTooltipContext: usableContext,
} = makeTooltipContext<TooltipData>();

export { tooltipReducer, makeInitialTooltipState };

export const [useTooltipEffect, TooltipEffectContext] = usableContext;
