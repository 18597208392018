import { GroupMembershipWithUser, Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery as useRQQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetUserGroupMembershipsQuery {
  export type Params = { userId: Muid };

  export type Response = GroupMembershipWithUser[];

  export const key = ['group', 'membership', 'user'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/group-memberships/by-user/${params.userId}`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQQuery(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.userId) && options.enabled !== false,
    });
  };
}
