import { FormFieldWidget, RichEmailWidgetAttachmentWithS3File, Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type FinishEmailAttachmentTemplateUploadMutationParams = {
  templateId: Template['id'];
  widgetId: FormFieldWidget['id'];
  key: string;
  originalFilename: string;
  contentType: string;
};

export const FinishEmailAttachmentTemplateUploadMutation = {
  key: ['email-attachments', 'finish-template-upload'],
  mutationFn: (params: FinishEmailAttachmentTemplateUploadMutationParams) =>
    axiosService
      .getAxios()
      .post<RichEmailWidgetAttachmentWithS3File>(`/1/email-attachments/finish-template-upload`, params)
      .then(res => res.data),
};

export const useFinishEmailAttachmentTemplateUploadMutation = (
  options: UseMutationOptions<
    RichEmailWidgetAttachmentWithS3File,
    AxiosError,
    FinishEmailAttachmentTemplateUploadMutationParams
  > = {},
) => {
  return useMutation(FinishEmailAttachmentTemplateUploadMutation.mutationFn, options);
};
