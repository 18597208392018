import { Muid } from '../core';
import { FormFieldValue, FormFieldWidget, OrderTree, Task, TaskStatus } from '../process';
import { ConditionEvaluator, TaskConditionEvaluator, TimeBasedCondition } from './model';

// After many failed attempts to get the types from `model` to work, I gave up and just copied the types here.
// I'm pretty sure the types in `model` are wrong and/or inconsistent, but I don't have time to fix them right now.
export namespace Runnable {
  export interface ChecklistRuleDefinitionBase {
    id: Muid;
    organizationId: Muid;
    orderTree: OrderTree;
    /** For non-logical rules, the single widget participating in the rule condition. */
    formFieldWidgetGroupId?: Muid;
    /** Rule action - hide (true), show (false) */
    hidden: boolean;
    /** Rule action - task template group IDs */
    taskTemplateGroupIds: Muid[];
    /** Rule action - widget group IDs */
    widgetGroupIds: Muid[];
    description?: string;
  }

  export interface LogicalChecklistRuleDefinition extends ChecklistRuleDefinitionBase {
    operand: LogicalOperand;
    operator: ChecklistRuleDefinitionLogicalOperator;
  }

  /**
   * Precalculated rules have a source form field widget that is hidden due to task permissions when running a workflow.
   * The rules editor will never have this type.
   */
  export interface FormFieldChecklistRuleDefinition<
    Operator extends ChecklistRuleDefinitionFormFieldOperator = ChecklistRuleDefinitionFormFieldOperator,
  > extends ChecklistRuleDefinitionBase {
    formFieldWidgetGroupId: Muid;
    operand: Operator extends ChecklistRuleDefinitionFormFieldOperator.Precalculated
      ? PrecalculatedOperand
      : FormFieldOperand;
    operator: Operator;
  }

  export interface TaskChecklistRuleDefinition<
    Operator extends ChecklistRuleDefinitionTaskOperator = ChecklistRuleDefinitionTaskOperator,
  > extends ChecklistRuleDefinitionBase {
    taskTemplateGroupId: Muid;
    operand: Operator extends ChecklistRuleDefinitionTaskOperator.TaskPrecalculated
      ? PrecalculatedOperand
      : TaskOperand;
    operator: Operator;
  }

  export enum ChecklistRuleDefinitionFormFieldOperator {
    Is = 'Is',
    IsNot = 'IsNot',
    IsGreaterThan = 'IsGreaterThan',
    IsLessThan = 'IsLessThan',
    StartsWith = 'StartsWith',
    EndsWith = 'EndsWith',
    Contains = 'Contains',
    DoesNotContain = 'DoesNotContain',
    HasNoValue = 'HasNoValue',
    HasAnyValue = 'HasAnyValue',
    Precalculated = 'Precalculated',
  }

  export enum ChecklistRuleDefinitionTaskOperator {
    TaskStatusIs = 'TaskStatusIs',
    TaskPrecalculated = 'TaskPrecalculated',
  }

  export enum ChecklistRuleDefinitionLogicalOperator {
    LogicalOr = 'LogicalOr',
    LogicalAnd = 'LogicalAnd',
    /** Client side only for coercing to task visibility rules */
    Logical = 'Logical',
  }

  export enum ChecklistRuleDefinitionOperandType {
    String = 'String',
    StringList = 'StringList',
    DateTime = 'DateTime',
    Logical = 'Logical',
    Precalculated = 'Precalculated',
    TaskPrecalculated = 'TaskPrecalculated',
    Period = 'Period',
  }

  // Only 'number' for date fields, string otherwise
  export type FormFieldOperandValue = string | number;

  /** Old single-condition operand type. */
  export interface FormFieldOperand {
    operandType: ChecklistRuleDefinitionOperandType;
    value: FormFieldOperandValue | null;
  }

  export type TaskOperandValue = TaskStatus;

  export interface TaskOperand {
    operandType: ChecklistRuleDefinitionOperandType;
    value: TaskOperandValue | null;
  }

  export interface PrecalculatedOperand {
    operandType: ChecklistRuleDefinitionOperandType.Precalculated;
    value: boolean;
  }

  export interface LogicalOperand {
    data: LogicalCondition;
    operandType: ChecklistRuleDefinitionOperandType.Logical;
  }

  export type ChecklistRuleDefinition =
    | FormFieldChecklistRuleDefinition
    | TaskChecklistRuleDefinition
    | LogicalChecklistRuleDefinition;

  export type ChecklistRuleDefinitionOperand = ChecklistRuleDefinition['operand'];
  export type ChecklistRuleDefinitionOperator = ChecklistRuleDefinition['operator'];

  export interface LogicalCondition {
    operator: ChecklistRuleDefinitionLogicalOperator;
    conditions: Condition[];
  }

  export type FormFieldCondition<
    Operator extends ChecklistRuleDefinitionFormFieldOperator = ChecklistRuleDefinitionFormFieldOperator,
  > = {
    formFieldWidgetGroupId: Muid;
    operator: Operator;
  } & (Operator extends ChecklistRuleDefinitionFormFieldOperator.Precalculated
    ? {
        operandType: ChecklistRuleDefinitionOperandType.Precalculated;
        operandValue: { value: boolean };
      }
    : {
        operandType: Exclude<ChecklistRuleDefinitionOperandType, ChecklistRuleDefinitionOperandType.Precalculated>;
        operandValue: { value: FormFieldOperandValue | null };
      });

  export type TaskCondition<
    Operator extends ChecklistRuleDefinitionTaskOperator = ChecklistRuleDefinitionTaskOperator,
  > = {
    taskTemplateGroupId: Muid;
    operator: Operator;
  } & (Operator extends ChecklistRuleDefinitionTaskOperator.TaskPrecalculated
    ? {
        operand: {
          operandType: ChecklistRuleDefinitionOperandType.TaskPrecalculated;
          value: boolean;
        };
      }
    : {
        operand: {
          operandType: Exclude<
            ChecklistRuleDefinitionOperandType,
            ChecklistRuleDefinitionOperandType.TaskPrecalculated
          >;
          value: TaskOperandValue | null;
        };
      });

  export type Condition = LogicalCondition | FormFieldCondition | TaskCondition | TimeBasedCondition;

  type TaskVisibilityRuleBase = {
    targetTaskTemplateGroupIds: Muid[];
    targetWidgetGroupIds: Muid[];
    hidden: boolean;
    formFieldValue?: FormFieldValue;
  };

  export type TaskVisibilityRule<Operator extends ChecklistRuleDefinitionOperator = ChecklistRuleDefinitionOperator> =
    Operator extends ChecklistRuleDefinitionLogicalOperator.Logical
      ? TaskVisibilityRuleBase & { operand: boolean }
      : Operator extends ChecklistRuleDefinitionFormFieldOperator.Precalculated
      ? TaskVisibilityRuleBase & { formFieldWidget?: FormFieldWidget; operand: boolean }
      : Operator extends ChecklistRuleDefinitionTaskOperator.TaskPrecalculated
      ? TaskVisibilityRuleBase & { task: Task; operand: boolean }
      : Operator extends ChecklistRuleDefinitionTaskOperator.TaskStatusIs
      ? TaskVisibilityRuleBase & { task: Task; condition: TaskConditionEvaluator; operand: TaskOperandValue | null }
      : TaskVisibilityRuleBase & {
          condition: ConditionEvaluator;
          formFieldWidget: FormFieldWidget;
          operand: FormFieldOperandValue | null;
        };

  export interface LogicalCondition {
    operator: ChecklistRuleDefinitionLogicalOperator;
    conditions: Condition[];
  }

  export function isTaskVisibilityRuleForTask(
    rule: Runnable.TaskVisibilityRule,
  ): rule is Runnable.TaskVisibilityRule<Runnable.ChecklistRuleDefinitionTaskOperator.TaskStatusIs> {
    return 'task' in rule && 'condition' in rule;
  }

  export function isTaskVisibilityRuleForFormField(
    rule: Runnable.TaskVisibilityRule,
  ): rule is Runnable.TaskVisibilityRule<
    Exclude<
      Runnable.ChecklistRuleDefinitionFormFieldOperator,
      Runnable.ChecklistRuleDefinitionFormFieldOperator.Precalculated
    >
  > {
    return 'formFieldWidget' in rule && 'condition' in rule;
  }

  export function isOperandLogical(operand: ChecklistRuleDefinitionOperand): operand is LogicalOperand {
    return 'data' in operand;
  }

  export function isRuleLogical(rule: ChecklistRuleDefinition): rule is LogicalChecklistRuleDefinition {
    return isOperandLogical(rule.operand);
  }

  export function isPrecalculatedRule(
    rule: ChecklistRuleDefinition,
  ): rule is FormFieldChecklistRuleDefinition<ChecklistRuleDefinitionFormFieldOperator.Precalculated> {
    return rule.operator === ChecklistRuleDefinitionFormFieldOperator.Precalculated;
  }

  export function isTaskRule(rule: ChecklistRuleDefinition): rule is TaskChecklistRuleDefinition {
    return TASK_OPERATORS.includes(rule.operator as typeof TASK_OPERATORS[number]);
  }

  export function isTaskPrecalculatedRule(
    rule: ChecklistRuleDefinition,
  ): rule is TaskChecklistRuleDefinition<ChecklistRuleDefinitionTaskOperator.TaskPrecalculated> {
    return rule.operator === ChecklistRuleDefinitionTaskOperator.TaskPrecalculated;
  }

  export function isTaskNotPrecalculatedRule(
    rule: ChecklistRuleDefinition,
  ): rule is TaskChecklistRuleDefinition<
    Exclude<ChecklistRuleDefinitionTaskOperator, ChecklistRuleDefinitionTaskOperator.TaskPrecalculated>
  > {
    return rule.operator === ChecklistRuleDefinitionTaskOperator.TaskStatusIs;
  }

  export function isFormFieldRule(
    rule: ChecklistRuleDefinition,
  ): rule is FormFieldChecklistRuleDefinition<
    Exclude<ChecklistRuleDefinitionFormFieldOperator, ChecklistRuleDefinitionFormFieldOperator.Precalculated>
  > {
    return (
      FORM_FIELD_OPERATORS.includes(rule.operator as typeof FORM_FIELD_OPERATORS[number]) && !isPrecalculatedRule(rule)
    );
  }

  export function isFormFieldOrPrecalculatedRule(
    rule: ChecklistRuleDefinition,
  ): rule is FormFieldChecklistRuleDefinition {
    return isFormFieldRule(rule) || isPrecalculatedRule(rule);
  }

  export function isTaskOrPrecalculatedRule(rule: ChecklistRuleDefinition): rule is TaskChecklistRuleDefinition {
    return isTaskRule(rule) || isTaskPrecalculatedRule(rule);
  }

  export function isConditionLogical(condition: Condition): condition is LogicalCondition {
    return 'conditions' in condition;
  }

  export function isConditionLogicalOr(condition: Condition): condition is LogicalCondition {
    return isConditionLogical(condition) && condition.operator === ChecklistRuleDefinitionLogicalOperator.LogicalOr;
  }

  export function isConditionLogicalAnd(condition: Condition): condition is LogicalCondition {
    return isConditionLogical(condition) && condition.operator === ChecklistRuleDefinitionLogicalOperator.LogicalAnd;
  }

  export function isConditionPrecalculated(
    condition: Condition,
  ): condition is FormFieldCondition<ChecklistRuleDefinitionFormFieldOperator.Precalculated> {
    return condition.operator === ChecklistRuleDefinitionFormFieldOperator.Precalculated;
  }

  export function isTimeBasedCondition(condition: Condition): condition is TimeBasedCondition {
    return 'dateType' in condition;
  }

  const FORM_FIELD_OPERATORS = Object.values(ChecklistRuleDefinitionFormFieldOperator);

  const TASK_OPERATORS = Object.values(ChecklistRuleDefinitionTaskOperator);

  export function isFormFieldCondition(condition: Condition): condition is FormFieldCondition {
    return FORM_FIELD_OPERATORS.includes(condition.operator as typeof FORM_FIELD_OPERATORS[number]);
  }

  export function isTaskCondition(condition: Condition): condition is TaskCondition {
    return TASK_OPERATORS.includes(condition.operator as typeof TASK_OPERATORS[number]);
  }

  export function isPrecalculated(
    condition: Condition,
  ): condition is FormFieldCondition<ChecklistRuleDefinitionFormFieldOperator.Precalculated> {
    return condition.operator === ChecklistRuleDefinitionFormFieldOperator.Precalculated;
  }
  export function isNotPrecalculated(
    condition: Condition,
  ): condition is FormFieldCondition<
    Exclude<ChecklistRuleDefinitionFormFieldOperator, ChecklistRuleDefinitionFormFieldOperator.Precalculated>
  > {
    return FORM_FIELD_OPERATORS.filter(op => op !== ChecklistRuleDefinitionFormFieldOperator.Precalculated).includes(
      condition.operator as Exclude<
        ChecklistRuleDefinitionFormFieldOperator,
        ChecklistRuleDefinitionFormFieldOperator.Precalculated
      >,
    );
  }

  export function isTaskNotPrecalculated(
    condition: Condition,
  ): condition is TaskCondition<
    Exclude<ChecklistRuleDefinitionTaskOperator, ChecklistRuleDefinitionTaskOperator.TaskPrecalculated>
  > {
    return TASK_OPERATORS.filter(op => op !== ChecklistRuleDefinitionTaskOperator.TaskPrecalculated).includes(
      condition.operator as ChecklistRuleDefinitionTaskOperator.TaskStatusIs,
    );
  }

  export function isTaskConditionPrecalculated(
    condition: Condition,
  ): condition is TaskCondition<ChecklistRuleDefinitionTaskOperator.TaskPrecalculated> {
    return condition.operator === ChecklistRuleDefinitionTaskOperator.TaskPrecalculated;
  }
}
