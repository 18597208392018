import { Muid } from '@process-street/subgrade/core';
import { InboxItem } from '@process-street/subgrade/inbox';
import { axiosService } from 'services/axios-service';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export namespace UpdateInboxItemsStatusMutation {
  export type Todo = { id: Muid; todoType: InboxItem['itemType'] };

  export type Params = {
    newStatus: 'Completed' | 'NotCompleted';
    todos: Todo[];
  };

  export type Response = { response: 'Ok' | 'Conflict'; todo: Todo }[];

  export const key = ['put', 'todos', 'status'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/todos/status`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
