import { dayjs as moment } from '@process-street/subgrade/util';

const generateSnoozeTargets = () => {
  return {
    'Later today': moment().add(2, 'h').second(0).millisecond(0),
    'Tomorrow': moment().startOf('day').add(1, 'd').hour(8),
    'Later this week': moment().startOf('day').add(2, 'd').hour(8),
    'Next week': moment().startOf('day').isoWeekday(1).hour(8).add(1, 'w'),
  };
};

export const SnoozeService = {
  generateSnoozeTargets,
};
