import { Task } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UploadUrlAttachmentMutationParams = {
  taskId: Task['id'];
  fileName: string;
  mimeType: string;
};

export type UploadUrlAttachmentMutationResponse = { url: string; key: string };

export const UploadUrlAttachmentMutation = {
  key: ['attachment', 'presigned-url'],
  mutationFn: (params: UploadUrlAttachmentMutationParams) =>
    axiosService
      .getAxios()
      .post<UploadUrlAttachmentMutationResponse>(`/1/attachments/upload-url`, params)
      .then(res => res.data),
};

export const useUploadUrlAttachmentMutation = (
  options: UseMutationOptions<UploadUrlAttachmentMutationResponse, AxiosError, UploadUrlAttachmentMutationParams> = {},
) => {
  return useMutation(UploadUrlAttachmentMutation.mutationFn, options);
};
