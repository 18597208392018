import { ICellRendererParams } from '@ag-grid-community/core';
import { Button, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import { OverdueTasksModal } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/modals/OverdueTasksModal/OverdueTasksModal';
import { OverdueTasksValue } from 'components/dashboard/models/grid';
import { BlvdButton, ButtonSize, ButtonStyle, ButtonType } from 'components/design/BlvdButton';
import { useFeatureFlag } from 'features/feature-flags';
import * as React from 'react';
import styles from './OverdueTasksRenderer.module.scss';

export interface OverdueTasksRendererProps extends Partial<ICellRendererParams> {
  value?: OverdueTasksValue;
}

export const OverdueTasksRenderer: React.FC<React.PropsWithChildren<OverdueTasksRendererProps>> = ({ value }) => {
  const isNewContextMenuEnabled = useFeatureFlag('reactifiedTaskTemplateContextMenu');
  if (!value || !value.count) {
    return <div />;
  }

  const { count, checklistId } = value;
  let label;
  if (count === 1) {
    label = '1 Task';
  } else {
    label = count < 100 ? `${count} Tasks` : '99+ Tasks';
  }

  return (
    <div className={styles.overdueTasksRenderer}>
      {isNewContextMenuEnabled ? (
        <Popover>
          <PopoverTrigger>
            <Button
              colorScheme="red"
              variant="outline"
              size="small"
              fontSize="xs"
              color="red.500"
              borderColor="red.500"
              py="1"
              px="3"
              borderWidth="thin"
              _hover={{
                bgColor: 'red.500',
                color: 'white',
              }}
            >
              {label}
            </Button>
          </PopoverTrigger>

          <Portal>
            <PopoverContent>
              <PopoverBody p="0">
                <OverdueTasksModal checklistId={checklistId} totalCount={count} />
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      ) : (
        <Popover>
          <PopoverTrigger>
            <BlvdButton style={ButtonStyle.Outline} type={ButtonType.Danger} size={ButtonSize.Small}>
              {label}
            </BlvdButton>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <OverdueTasksModal checklistId={checklistId} totalCount={count} />
            </PopoverContent>
          </Portal>
        </Popover>
      )}
    </div>
  );
};
