import { Button } from 'components/design/next';
import * as React from 'react';

interface LoadMoreRowRendererProps {
  onLoadMore: () => void;
}

const TASKS_TABLE_LOAD_MORE_BUTTON_ID = 'tasks-table-load-more-button';

export function scrollLoadMoreButtonIntoView() {
  document.getElementById(TASKS_TABLE_LOAD_MORE_BUTTON_ID)?.scrollIntoView();
}

export const LoadMoreRowRenderer = (props: LoadMoreRowRendererProps) => {
  return (
    <Button
      id={TASKS_TABLE_LOAD_MORE_BUTTON_ID}
      w="full"
      onClick={props.onLoadMore}
      h={9}
      mt={0}
      pl={2}
      borderRadius="none"
      fontWeight="medium"
      colorScheme="gray"
      color="gray.400"
      backgroundColor="inherit"
      _hover={{
        backgroundColor: 'gray.100',
      }}
      _active={{
        backgroundColor: 'gray.50',
      }}
    >
      Load more
    </Button>
  );
};
