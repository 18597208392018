import * as React from 'react';
import { useNavigate } from '@process-street/adapters/navigation';
import { Muid } from '@process-street/subgrade/core';
import { queryString } from '@process-street/subgrade/util';
import { Button, ButtonProps, Icon } from 'components/design/next';
import { useUiDisclosure } from 'app/pages/forms/_id/shared';

export type ImportTemplateButtonProps = React.PropsWithChildren<{
  templateId: Muid;
  children?: React.ReactElement<ButtonProps>;
}>;

export const ImportTemplateButton = ({ templateId, children }: ImportTemplateButtonProps) => {
  const navigate = useNavigate();
  const disclosure = useUiDisclosure('taskList');

  const handleClick = React.useCallback(() => {
    disclosure.onClose();
    navigate({ pathname: 'templateAdd', search: queryString.stringify({ templateId }) });
  }, [disclosure, navigate, templateId]);

  const btn = React.useMemo(
    () =>
      React.isValidElement(children) ? (
        React.cloneElement(children, {
          onClick: (evt: React.MouseEvent) => {
            handleClick();
            children.props?.onClick?.(evt);
          },
        })
      ) : (
        <Button
          w="full"
          minW="max-content"
          variant="outline"
          colorScheme="gray"
          leftIcon={<Icon icon="arrow-to-bottom" variant="far" size="4" color="gray.500" />}
          iconSpacing="2"
          onClick={handleClick}
          borderColor="gray.300"
          borderWidth="thin"
          color="gray.600"
        >
          Import Workflow
        </Button>
      ),
    [children, handleClick],
  );

  return btn;
};
