import { Option } from '../core';
import { OrderTree } from './template-model';

const ALPHABET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

function toNumber(str: Option<string>): number {
  if (str === undefined) {
    return 0;
  }
  return ALPHABET.indexOf(str.substr(-1)) + (str.length - 1) * ALPHABET.length;
}

function toString(n: number): string {
  return ALPHABET.substr(-1).repeat(n / ALPHABET.length) + ALPHABET.charAt(n % ALPHABET.length);
}

function compare(a: OrderTree, b: OrderTree): number {
  const parts1 = a.split('.');
  const parts2 = b.split('.');
  const min = Math.min(parts1.length, parts2.length);
  for (let i = 0; i < min; i += 1) {
    const p1 = toNumber(parts1[i]);
    const p2 = toNumber(parts2[i]);
    if (p1 !== p2) {
      return p1 - p2;
    }
  }
  return parts1.length - parts2.length;
}

export const OrderTreeUtils = {
  compare,
  toNumber,
  toString,
};
