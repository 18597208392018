import * as React from 'react';
import {
  Box,
  useControllableState,
  FormControl,
  FormLabel,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Icon,
  InputGroup,
  Fade,
  useDisclosure,
  Wrap,
  WrapItem,
  InputRightElement,
  InputProps,
  Spinner,
} from 'components/design/next';
import { useTagManager } from './use-tag-manager';
import { AnimatePresence, motion } from 'framer-motion';
import { Muid } from '@process-street/subgrade/core';

const ORGANIZATION_TAGS = 'all-organization-tags';

export type TagManagerProps = Pick<InputProps, 'autoFocus'> & { templateId: Muid };

const MotionWrapItem = motion(WrapItem);

export const TagManager: React.FC<React.PropsWithChildren<TagManagerProps>> = ({ autoFocus, templateId }) => {
  const tagInput = React.useRef<HTMLInputElement>(null);
  const successMessage = useDisclosure({
    id: 'TagManagerSuccess',
  });
  const [tagInputValue, setTagInputValue] = useControllableState<string>({ defaultValue: '' });
  const onTagAdded = React.useCallback(() => {
    successMessage.onOpen();
    setTimeout(() => {
      successMessage.onClose();
    }, 1000);
  }, [successMessage]);

  const { createTag, removeTag, templateTags, organizationTags } = useTagManager({
    onTagAdded,
    templateId,
  });

  return (
    <Box>
      <FormControl id="tags">
        <FormLabel>Tags</FormLabel>
        <InputGroup>
          <Input
            autoFocus={autoFocus}
            ref={tagInput}
            autoComplete="off"
            list={ORGANIZATION_TAGS}
            variant="outline"
            value={tagInputValue}
            onChange={evt => {
              setTagInputValue(evt.target.value);
            }}
            onKeyPress={evt => {
              if (evt.key === 'Enter' && tagInputValue.trim() !== '') {
                createTag(tagInputValue.trim());
                setTagInputValue('');
              }
            }}
            type="text"
          />
          <datalist data-testid="autocomplete" id={ORGANIZATION_TAGS}>
            {organizationTags.data?.map(tag => (
              <option key={tag.id} value={tag.name} aria-label={tag.name} />
            ))}
          </datalist>
          <InputRightElement>
            <Fade in={successMessage.isOpen}>
              <Icon color="green.500" icon="check-circle" size="4" />
            </Fade>
          </InputRightElement>
        </InputGroup>
        {templateTags.isLoading ? (
          <Box pt="4">
            <Spinner size="lg" />
          </Box>
        ) : (
          <Wrap pt="4" spacing={2}>
            <AnimatePresence initial={false}>
              {templateTags.data?.map(tag => (
                <MotionWrapItem
                  layout
                  transition={{ type: 'spring', duration: 0.4 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  key={tag.id}
                >
                  <Tag size="lg" variant="outline" colorScheme="brand">
                    <TagLabel>{tag.name}</TagLabel>
                    <TagCloseButton onClick={() => removeTag(tag)} />
                  </Tag>
                </MotionWrapItem>
              ))}
            </AnimatePresence>
          </Wrap>
        )}
      </FormControl>
    </Box>
  );
};
