import * as React from 'react';
import { Text, TextProps } from 'components/design/next';

export const Title: React.FC<React.PropsWithChildren<TextProps>> = ({ children, ...props }) => {
  return (
    <Text
      w="full"
      fontSize="xl"
      fontWeight="medium"
      as="h1"
      color="gray.700"
      textAlign="center"
      maxWidth="xl"
      {...props}
    >
      {children}
    </Text>
  );
};
