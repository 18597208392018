import * as React from 'react';
import {
  Flex,
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Textarea,
} from 'components/design/next';
import { FieldType, FormFieldWidget } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import { EditDefaultValueButton } from './edit-default-value-button';
import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';
import { useInjector } from 'components/injection-provider';
import { FormFieldEvent } from 'services/form-field-event';
import { trace } from 'components/trace';

export interface DefaultValueFieldProps {
  widget: FormFieldWidget;
}

const logger = trace({ name: 'DefaultValueField (Template)' });

export const DefaultValueField: React.FC<React.PropsWithChildren<DefaultValueFieldProps>> = ({ widget }) => {
  const { $rootScope } = useInjector('$rootScope');
  const isEditable = useWorkflowState() === 'edit';

  const derivedPlaceholder = match(widget)
    .with({ config: { defaultValue: P.when(Boolean) } }, ({ config: { defaultValue } }) => String(defaultValue))
    .with({ config: { placeholder: P.when(Boolean) } }, ({ config: { placeholder } }) => String(placeholder))
    .with({ fieldType: FieldType.Email }, () => 'e.g., ned@stark')
    .with({ fieldType: FieldType.Text }, () => 'Short text will be typed here...')
    .with({ fieldType: FieldType.Textarea }, () => 'Long text will be typed here...')
    .with({ fieldType: FieldType.Number }, () => 'e.g., 42')
    .with({ fieldType: FieldType.Url }, () => 'e.g., https://www.process.st')
    .with({ fieldType: FieldType.Hidden }, () => 'Hidden text will be stored here...')
    .otherwise(() => '');

  if (!derivedPlaceholder) {
    logger.error('No placeholder or default value found for widget', widget);
  }

  const Field = match(widget)
    .with({ fieldType: FieldType.Textarea }, () => Textarea)
    .otherwise(() => Input);

  const onClickDisabled = () => {
    $rootScope.$broadcast(FormFieldEvent.DISABLED_FORM_FIELD_CLICKED);
  };

  return (
    <Flex position="relative" w="full">
      {isEditable ? null : (
        <Box
          cursor="not-allowed"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex="1"
          role="button"
          aria-label="disabled field hint"
          onClick={onClickDisabled}
        />
      )}

      <InputGroup>
        {match(widget)
          .with({ fieldType: P.union(FieldType.Email, FieldType.Url) }, ({ fieldType }) => (
            <InputLeftAddon>
              <Icon
                icon={match<typeof fieldType, CustomIconName>(fieldType)
                  .with(FieldType.Email, () => 'envelope')
                  .with(FieldType.Url, () => 'globe')
                  .exhaustive()}
                variant="far"
                size="4"
                color="gray.400"
              />
            </InputLeftAddon>
          ))
          .otherwise(() => null)}

        <Field
          isDisabled
          bg="gray.100"
          opacity="1 !important"
          color="gray.400"
          value={derivedPlaceholder}
          resize="none"
        />
        {isEditable ? (
          <InputRightElement>
            <EditDefaultValueButton widget={widget} />
          </InputRightElement>
        ) : null}
      </InputGroup>
    </Flex>
  );
};
