import { GrouppedObjectMap, ObjectMap } from '../redux/types';
import { Task } from './checklist-model';
import { TaskTemplate } from './template-model';
import { FormFieldWidget } from './widget-model';
import { Muid } from '../core';

/**
 * Converts array to a map using key extractor function
 * @param items
 * @param key
 */
export const toMapByKeyFunc = <T>(items: T[], key: (i: T) => Muid): ObjectMap<T> =>
  items.reduce((agg: ObjectMap<T>, item: T) => {
    agg[key(item)] = item;
    return agg;
  }, {});

/**
 * Converts array to a groupped map using key extractor function
 * @param items
 * @param key
 */
export const toGrouppedMapByKeyFunc = <T>(items: T[], key: (i: T) => Muid): GrouppedObjectMap<T> =>
  items.reduce((agg: GrouppedObjectMap<T>, item: T) => {
    const id = key(item);
    agg[id] = agg[id] === undefined ? [] : agg[id];
    agg[id].push(item);
    return agg;
  }, {});

/**
 * Converts taskTemplate list to ObjectMap with
 * - key - taskTemplate.group.id
 * - value - taskTemplate
 */
export function toTaskTemplateMapByGroupId(taskTemplates: TaskTemplate[]): ObjectMap<TaskTemplate> {
  return toMapByKeyFunc(taskTemplates, taskTemplate => taskTemplate.group.id);
}

/**
 * Converts FormFieldWidgets list to ObjectMap with
 * - key - widget.header.group.id
 * - value - formFieldWidget
 */
export function toFormFieldWidgetMapByHeaderGroupId(widgets: FormFieldWidget[]): ObjectMap<FormFieldWidget> {
  return toMapByKeyFunc(widgets, widget => widget.header.group.id);
}

/**
 * Converts Task list to ObjectMap with
 * - key - taskTemplate id
 * - value - task
 */
export function toTaskMapByTaskTemplateId(tasks: Task[]): ObjectMap<Task> {
  return toMapByKeyFunc(tasks, task => task.taskTemplate.id);
}

/**
 * Builds form field widgets map by task template group id
 * @param formFieldWidgets
 */
const toFormFieldWidgetMapByTaskTemplateGroupId = (
  formFieldWidgets: FormFieldWidget[],
): GrouppedObjectMap<FormFieldWidget> =>
  toGrouppedMapByKeyFunc(
    formFieldWidgets,
    formFieldWidget => (formFieldWidget.header.taskTemplate as TaskTemplate).group.id,
  );

export const MapUtils = {
  toFormFieldWidgetMapByTaskTemplateGroupId,
};
