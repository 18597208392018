import { UserOption } from 'utils/user-option-transformer';
import { isValidEmail } from '@process-street/subgrade/process';
import { Box, Button, Flex, Text } from 'components/design/next';
import React from 'react';
import { NoticeProps } from 'react-select';

export const InviteUsers =
  (onInvite: (email: string) => void, guestRoleTitle: string) => (props: NoticeProps<UserOption, true>) => {
    const email = props.selectProps.inputValue;
    const handleClick = () => {
      if (email) {
        onInvite(email);
      }
    };
    const emailIsValid = isValidEmail(email);

    return (
      <Flex alignItems="center" justifyContent="space-between" marginX={2} marginY={3}>
        <Box maxW={66}>
          <Text variant="-1" fontWeight="medium" color="gray.600" noOfLines={1}>
            {email}
          </Text>
          <Text variant="-2" color="gray.500">
            This user will be invited as a {guestRoleTitle}.
          </Text>
        </Box>
        <Button variant="solid" colorScheme="gray" isDisabled={!emailIsValid} onClick={handleClick} w="auto!important">
          Assign
        </Button>
      </Flex>
    );
  };
