import { Muid } from '@process-street/subgrade/core';
import { useGetAllTaskTemplatesAssignmentsByTemplateRevisionIdQuery } from 'features/task-template-assignment/query-builder';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';

export const useIsAssigned = ({
  organizationMembershipId,
  templateId,
}: {
  templateId?: Muid;
  organizationMembershipId: Muid;
}): boolean => {
  const templateRevisionQuery = useNewestTemplateRevisionQuery({ templateId }, { enabled: !!templateId });
  const templateRevisionId = templateRevisionQuery.data?.id;
  const templateTasksQuery = useGetAllTaskTemplatesAssignmentsByTemplateRevisionIdQuery(
    { templateRevisionId: templateRevisionId! },
    { enabled: !!templateRevisionId },
  );

  const assigned =
    templateTasksQuery.data?.some(a => a.organizationMembership.id === organizationMembershipId) ?? false;

  return assigned;
};
