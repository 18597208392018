export enum Key {
  CHECKLIST_METHOD = 'method',
  ORGANIZATION_ID = 'organization id',
  ORGANIZATION_NAME = 'organization name',
  PLAN_ID = 'plan id',
  PLAN_NAME = 'plan name',
  PLAN_LEVEL = 'plan level',
  PLAN_INTERVAL = 'plan interval',
  PLAN_COST = 'plan cost',
  CURRENT_PLAN_ID = 'current plan id',
  CURRENT_PLAN_NAME = 'current plan name',
  CURRENT_PLAN_LEVEL = 'current plan level',
  CURRENT_PLAN_INTERVAL = 'current plan interval',
  CURRENT_PLAN_COST = 'current plan cost',
  SELECTED_ORGANIZATION_ID = 'selected organization id',
  SELECTED_ORGANIZATION_INDUSTRY = 'selected organization industry',
  SELECTED_ORGANIZATION_NAME = 'selected organization name',
  SELECTED_ORGANIZATION_STRIPE_ID = 'selected organization stripe id',
  TEMPLATE_ID = 'template id',
  TEMPLATE_NAME = 'template name',
  USER_EMAIL = 'user email',
  USER_ID = 'user id',
  USER_LEVEL = 'user level',
  USER_ORIGIN = 'origin',
  USER_SOURCE = 'source',
  USER_SUBSCRIBED_TO_BLOG = 'subscribed to blog',
  USER_WHAT_I_DO = 'what i do',
  SUBSCRIPTION_END_DATE = 'subscription end date',
  SUBSCRIPTION_START_DATE = 'subscription start date',
  SUBSCRIPTION_STATUS = 'subscription status',
  SUBSCRIPTION_QUANTITY = 'subscription quantity',
  CURRENT_PLAN_COST_PER_MONTH = 'approx cost per month',
  CURRENT_PLAN_COST_TOTAL = 'approx cost total',
  WIDGET_LEGACY_TYPE = 'type',
  WIDGET_ID = 'widget id',
  WIDGET_TYPE = 'widget type',
  WIDGET_FIELD_TYPE = 'field type',
  TASK_ID = 'task id',
  TASK_NAME = 'task name',
}

export enum Event {
  CHECKLIST_ARCHIVED = 'checklist archived',
  CHECKLIST_ASSIGNED = 'checklist assigned',
  CHECKLIST_COMPLETED = 'checklist completed',
  CHECKLIST_CREATED = 'checklist created',
  CHECKLIST_DELETED = 'checklist deleted',
  CHECKLIST_REACTIVATED = 'checklist reactivated',
  CHECKLIST_UNARCHIVED = 'checklist unarchived',
  CHECKLIST_VIEWED = 'checklist viewed (app)',
  CHECKLISTS_EXPORTED_AS_CSV = 'checklists exported as csv',
  STOP_TASK_ADDED_TO_TASK = 'stop task(s) added',
  SUB_CHECKLIST_ITEM_STATUS_UPDATED = 'sub-checklist item checked/unchecked',
  SUPPORT_LINK_CLICKED = 'support link clicked',
  TASK_LIST_STOPPED_TASKS_SHOWN = 'task list stopped tasks shown',
  TASK_LIST_STOPPED_TASKS_HIDDEN = 'task list stopped tasks hidden',
  TASK_TEMPLATE_CREATED = 'task template created',
  TASKS_CHECKED_UNCHECKED = 'tasks checked/unchecked',
  TEMPLATE_CREATED = 'template created',
  TEMPLATE_REVISION_DRAFT_CREATED = 'template revision draft created',
  TEMPLATE_VIEWED = 'template viewed',
  USER_LOGGED_IN = 'user logged in',
  USER_SIGNED_UP_STEP_1 = 'user signed up - step 1 completed',
  USER_SIGNED_UP_STEP_2 = 'user signed up - step 2 completed',
  WIDGET_CREATED = 'widget created',
}

export const AnalyticsConstants = {
  Key,
  Event,
};
