const Namespace = {
  CHECKLIST: 'checklist',
  TAGS_PARSING: 'tags parsing',
};

const ChecklistAction = {
  UPDATE_STATUS: 'update status',
};

const TaskAction = {
  UPDATE_STATUS: 'update status',
};

const FormFieldAction = {
  UPDATE: 'value update',
  DELETE: 'value delete',
};

export const PromiseQueueConstants = {
  ChecklistAction,
  FormFieldAction,
  Namespace,
  TaskAction,
};
