import { TemplateRevisionWithTemplate } from '@process-street/subgrade/process';
import { createUsableContext } from '@process-street/subgrade/util';
import React from 'react';

export const [useTemplateRevision, TemplateRevisionContext] = createUsableContext<TemplateRevisionWithTemplate>({
  hookName: 'useTemplateRevision',
  providerName: 'TemplateRevisionProvider',
});

export const TemplateRevisionProvider: React.FC<
  React.PropsWithChildren<{ templateRevision: TemplateRevisionWithTemplate }>
> = ({ templateRevision, children }) => {
  const value = React.useMemo(() => templateRevision, [templateRevision]);
  return <TemplateRevisionContext.Provider value={value}>{children}</TemplateRevisionContext.Provider>;
};
