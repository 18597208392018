import { Muid } from '@process-street/subgrade/core';
import { DataSetRow } from '@process-street/subgrade/process';
import { axiosService } from 'services/axios-service';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';

export type GetAllLinkedDataSetRowsParams = {
  dataSetId: Muid;
  savedViewId: Muid;
  linkId: Muid;
};

export type GetAllLinkRowsResponse = DataSetRow[];

export const GetAllLinkedDataSetRowsQuery = {
  key: ['data-sets', 'saved-views', 'link', 'rows'],
  getKey: (params: GetAllLinkedDataSetRowsParams): QueryKey => [...GetAllLinkedDataSetRowsQuery.key, params],
  queryFn: (params: GetAllLinkedDataSetRowsParams) =>
    axiosService
      .getAxios()
      .get<GetAllLinkRowsResponse>(
        `/1/data-sets/${params.dataSetId}/saved-views/${params.savedViewId}/links/${params.linkId}/rows`,
      )
      .then(res => res.data),
};

export const useGetAllLinkedDataSetRowsQuery = <Select = GetAllLinkRowsResponse>(
  params: GetAllLinkedDataSetRowsParams,
  options?: UseQueryOptions<GetAllLinkRowsResponse, AxiosError, Select>,
) => {
  return useQuery(
    GetAllLinkedDataSetRowsQuery.getKey(params),
    () => GetAllLinkedDataSetRowsQuery.queryFn(params),
    options,
  );
};
