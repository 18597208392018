import { FormResponseActorRefProvider, useFormResponseActorRef } from './form-response-actor-provider';
import { FormResponsePageActorRefProvider, useFormResponsePageActorRef } from './form-response-page-actor-provider';

export const FormResponsePageProviders = {
  FormResponsePageActorRef: {
    Provider: FormResponsePageActorRefProvider,
    useActorRef: useFormResponsePageActorRef,
  },
  FormResponseActorRef: {
    Provider: FormResponseActorRefProvider,
    useActorRef: useFormResponseActorRef,
  },
};
