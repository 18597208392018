import React, { useEffect } from 'react';

import { useToast } from 'components/design/next';
import {
  FormFieldValue,
  FormFieldWidget,
  RichEmailWidgetAttachmentWithS3File,
  Task,
} from '@process-street/subgrade/process';
import { useUploadChecklistEmailAttachment } from './use-upload-checklist-email-attachment';
import { UploadButton } from 'features/widgets/components/send-rich-email/email_attachment/index';
import { useShowFileUploadErrorToast } from 'features/widgets/components/send-rich-email/email_attachment/use-show-file-upload-error-toast';
import { DefaultErrorMessages } from 'components/utils/error-messages';

type UploadChecklistEmailAttachmentProps = {
  taskId: Task['id'];
  widgetId: FormFieldWidget['id'];
  formFieldValueId?: FormFieldValue['id'];
  /** Empty state form field value creator if form field value ID doesn't exist when uploading. */
  onCreateFormFieldValue: () => Promise<FormFieldValue>;
  onFinish: (attachment: RichEmailWidgetAttachmentWithS3File) => void;
  setIsUploading: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  progress: number | undefined;
  setProgress: (progress: number | undefined) => void;
  totalSize: number;
};
export const UploadChecklistEmailAttachment: React.FC<React.PropsWithChildren<UploadChecklistEmailAttachmentProps>> = ({
  taskId,
  widgetId,
  formFieldValueId,
  onCreateFormFieldValue,
  onFinish,
  setIsUploading,
  setProgress,
  progress,
  totalSize,
}) => {
  const toast = useToast();

  const { dropzoneState, uploadError } = useUploadChecklistEmailAttachment({
    taskId,
    widgetId,
    formFieldValueId,
    setIsUploading,
    onCreateFormFieldValue,
    onFinish,
    setProgress,
    totalSize,
  });

  useEffect(() => {
    if (uploadError) {
      toast({
        status: 'error',
        title: "We're having problems uploading the attachment",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    }
  }, [uploadError, toast]);

  useShowFileUploadErrorToast(dropzoneState);

  return (
    <div {...dropzoneState.getRootProps()}>
      {progress === undefined && (
        <UploadButton aria-label="add attachment" dropzoneInputProps={dropzoneState.getInputProps()} />
      )}
    </div>
  );
};
