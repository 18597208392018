enum Dimension {
  USER_ID = '&uid',
  USER_EMAIL = 'dimension1',
  ORGANIZATION_ID = 'dimension2',
  ORGANIZATION_NAME = 'dimension3',
}

export const GoogleAnalyticsConstants = {
  Dimension,
};
