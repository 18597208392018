import * as React from 'react';
import { MenuButton, MenuButtonProps, IconButtonProps, IconButton, Icon } from 'components/design/next';
import { match } from 'ts-pattern';

export type VerticalPosition = 'top' | 'center' | 'bottom';
export type HorizontalPosition = 'left' | 'center' | 'right';
export type Position = `${VerticalPosition}-${HorizontalPosition}`;

export interface MergeTagsMenuButtonProps
  extends Partial<Omit<MenuButtonProps, 'aria-label'>>,
    Partial<IconButtonProps> {
  /** For basic control from angular */
  ngProps?: {
    buttonPosition?: Position;
    buttonSize?: IconButtonProps['size'];
  };
}

export const MergeTagsMenuButton: React.FC<React.PropsWithChildren<MergeTagsMenuButtonProps>> = ({
  ngProps = {},
  ...rest
}) => {
  return (
    <MenuButton
      {...{
        'color': 'gray.500',
        'variant': 'ghost',
        'aria-label': 'Select a variable',
        'as': IconButton,
        'icon': <Icon icon="wand-magic-sparkles" size="4" variant="far" />,

        // Exposing the unideal "props" prop because angular can't transclude (compose children)
        ...(ngProps.buttonSize ? { size: ngProps.buttonSize } : {}),
        ...(ngProps.buttonPosition ? { position: 'absolute' } : {}),
        ...match(ngProps.buttonPosition?.split('-')[0] as VerticalPosition)
          .with('top', () => ({ top: 1 }))
          .with('center', () => ({ top: '50%', transform: 'translateY(-50%)' }))
          .with('bottom', () => ({ bottom: 1 }))
          .otherwise(() => null),
        ...match(ngProps.buttonPosition?.split('-')[1] as HorizontalPosition)
          .with('left', () => ({ left: 1 }))
          .with('center', () => ({ left: '50%', transform: 'translateX(-50%)' }))
          .with('right', () => ({ right: 1 }))
          .otherwise(() => null),
        // End ngProps stuff

        ...rest,
      }}
    />
  );
};
