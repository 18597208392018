import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { MutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { useMutation as useRQMutation } from 'react-query';
import { SnoozedAssignment } from '@process-street/subgrade/inbox';

export namespace SnoozeAssignmentMutation {
  export type Params = {
    assignmentId: Muid;
    untilDate: number;
  };

  export const key = ['assignment', 'snooze'];

  export const mutationFn = ({ assignmentId, ...payload }: Params) =>
    axiosService
      .getAxios()
      .put(`/1/assignments/${assignmentId}/snoozed`, payload)
      .then(res => res.data);

  export const useMutation = (options: MutationOptions<SnoozedAssignment, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
