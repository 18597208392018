import * as React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from 'components/design/next';
import { useGetAllGroupsQuery } from 'features/group/query-builder/get-all-groups';
import { GroupListItem } from 'pages/organizations/manage/groups/components/group-list-item';
import { useGroupMemberships } from 'features/group-memberships/query-builder';
import { GroupType, OrganizationMembershipWithUser, User } from '@process-street/subgrade/core';
import { AddGroupModal } from 'pages/organizations/manage/groups/components/add-group-modal';
import { useDispatch, useSelector } from 'react-redux';
import { restrictedSelector } from 'reducers/group/group.selectors';
import sortBy from 'lodash/sortBy';
import pluralize from 'pluralize';
import { useCreateGroupMutation } from 'features/group/query-builder';
import { SessionSelector } from 'reducers/session/session.selectors';
import { GROUP_CREATE } from 'reducers/group/group.actions';

export const GroupsPage: React.VFC = () => {
  const groupsQuery = useGetAllGroupsQuery({ include: 'user' });
  const groupMemberships = useGroupMemberships();
  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const dispatch = useDispatch();

  const createGroupMutation = useCreateGroupMutation({
    onSuccess: response => {
      const { username } = response.group.user as User;
      dispatch({
        type: GROUP_CREATE,
        meta: {
          name: username,
          organizationId: selectedOrganizationId,
        },
        payload: response,
      });

      groupsQuery.refetch();
    },
  });

  const groups = sortBy(
    groupsQuery.data?.filter(group => group.groupType === GroupType.Standard),
    [g => (g.user as User).username],
  );

  const addGroupDisclosure = useDisclosure();
  const isRestricted = useSelector(restrictedSelector);

  const handleNewGroupClick = () => {
    addGroupDisclosure.onOpen();
  };

  const handleGroupCreate = (name: string) => {
    if (selectedOrganizationId) {
      createGroupMutation.mutate({
        organizationId: selectedOrganizationId,
        name,
      });
    }
  };

  return isRestricted ? (
    <Alert status="warning">
      <AlertIcon />
      <AlertDescription>Your current plan does not allow to use this feature.</AlertDescription>
    </Alert>
  ) : (
    <>
      <Stack direction={['column', 'row']} spacing="24px">
        <Text>
          A user group is a way to refer to a group of users at once, allowing you to assign permissions in bulk.
        </Text>
        <Button
          variant="primary"
          minWidth="140px"
          onClick={handleNewGroupClick}
          leftIcon={<Icon variant="far" icon="plus" size="4" />}
        >
          New Group
        </Button>
      </Stack>
      <HStack
        borderBottom="2px solid"
        borderBottomColor="gray.200"
        marginTop={5}
        marginBottom={2}
        paddingBottom={2}
        letterSpacing="1px"
      >
        <Text color="gray.400" variant="-2u" fontWeight="bold" w="340px" marginLeft={10}>
          Name
        </Text>
        <Text color="gray.400" variant="-2u" fontWeight="bold">
          Members
        </Text>
      </HStack>
      {groups &&
        groupMemberships.data &&
        groups.map(group => (
          <GroupListItem
            key={group.id}
            group={group}
            groupMembers={groupMemberships.data
              .filter(gm => gm.group.id === group.id)
              .map(gm => gm.organizationMembership as OrganizationMembershipWithUser)}
          />
        ))}

      {groups && (
        <Text color="gray.400">
          This organization has{' '}
          <Text as="span" fontWeight="bold">
            {groups.length}
          </Text>{' '}
          {pluralize('group', groups.length)}
        </Text>
      )}

      <AddGroupModal
        isOpen={addGroupDisclosure.isOpen}
        onClose={addGroupDisclosure.onClose}
        onCancel={addGroupDisclosure.onClose}
        onGroupCreate={handleGroupCreate}
      />
    </>
  );
};
