import { createUsableContext } from '@process-street/subgrade/util';
import {
  HStack,
  Circle,
  Image,
  TextProps,
  Text,
  StackProps,
  forwardRef,
  TextareaProps,
  Textarea,
} from 'components/design/next';
import React from 'react';
import { match } from 'ts-pattern';

const [useProcessPeteTextareaHeaderContext, ProcessPeteTextareaHeaderContext] = createUsableContext<{
  status: 'error' | 'info';
  isDisabled: boolean;
}>({
  hookName: 'useProcessPeteTextareaHeaderContext',
  providerName: 'ProcessPeteTextareaHeaderProvider',
});

export const ProcessPeteTextareaHeader: React.FC<
  React.PropsWithChildren<StackProps & { status?: 'error' | 'info'; isDisabled?: boolean }>
> = ({ children, isDisabled = false, status = 'info', ...props }) => {
  return (
    <ProcessPeteTextareaHeaderContext.Provider value={{ status, isDisabled }}>
      <HStack
        py="2"
        px="3"
        border="1px solid"
        borderRadius="base"
        borderBottom="none"
        borderBottomRadius="0"
        spacing="4"
        {...match(status)
          .with('error', () => ({
            bg: 'red.50',
            borderColor: 'red.300',
          }))
          .otherwise(() => ({
            bg: 'gray.50',
            borderColor: 'gray.300',
          }))}
        {...(isDisabled ? { opacity: 0.4 } : {})}
        {...props}
      >
        {children}
      </HStack>
    </ProcessPeteTextareaHeaderContext.Provider>
  );
};

export const ProcessPeteTextareaHeaderIcon: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { status } = useProcessPeteTextareaHeaderContext();
  return (
    <Circle size="8" bg="white">
      {match(status)
        .with('error', () => <Image w="5" src={require('app/images/process-pete-red.png')} alt="process-pete" />)
        .otherwise(() => (
          <Image w="5" src={require('app/images/process-pete-gray.png')} alt="process-pete" />
        ))}
    </Circle>
  );
};

export const ProcessPeteTextareaHeaderTitle: React.FC<React.PropsWithChildren<TextProps>> = props => {
  const { status } = useProcessPeteTextareaHeaderContext();
  return (
    <Text
      variant="-1"
      {...match(status)
        .with('error', () => ({
          color: 'red.500',
        }))
        .otherwise(() => ({
          color: 'gray.500',
        }))}
      fontWeight="medium"
      {...props}
    />
  );
};

export const ProcessPeteTextarea = forwardRef<TextareaProps, 'textarea'>((props, ref) => {
  return (
    <Textarea ref={ref} placeholder="Type your own prompt" borderTopRadius="0" borderColor="gray.300" {...props} />
  );
});
ProcessPeteTextarea.displayName = 'ProcessPeteTextarea';
