import { Template } from '@process-street/subgrade/process';
import { Icon, IconButton } from 'components/design/next';
import React from 'react';
import { useShareFormButton } from '../hooks/use-share-form-button';

export interface ShareButtonProps {
  template: Template;
}

export const ShareButton: React.FC<React.PropsWithChildren<ShareButtonProps>> = ({ template }) => {
  const { onShowShareTemplate } = useShareFormButton(template);
  return (
    <IconButton
      aria-label="Share"
      variant="tertiary"
      icon={<Icon icon="share" variant="far" size="4" color="gray.500" />}
      onClick={onShowShareTemplate}
    />
  );
};
