import {
  DueDateRuleOffsetDirection,
  DueDateRuleSourceType,
  FormFieldWidget,
  TaskTemplate,
} from '@process-street/subgrade/process';
import { Option } from '@process-street/subgrade/core';
import { IconType } from 'components/design/Icon';
import { DueDateHelper } from 'features/dynamic-due-dates/services/due-date-helper';
import { match } from 'ts-pattern';
import { PartialDueDateRule } from 'features/dynamic-due-dates/models/models';

export type RuleItem = {
  id: DueDateRuleSourceType | string;
  type: DueDateRuleSourceType;
  label: string;
  icon: IconType;
};

const ChecklistStartDateItem: RuleItem = {
  id: DueDateRuleSourceType.ChecklistStartDate,
  type: DueDateRuleSourceType.ChecklistStartDate,
  label: 'workflow run start date',
  icon: IconType.CalendarAlt,
};

const PopularAfterItemsForFirstTask: RuleItem[] = [ChecklistStartDateItem];

const PopularAfterItems: RuleItem[] = [
  ChecklistStartDateItem,
  {
    id: DueDateRuleSourceType.PreviousTaskCompletedDate,
    type: DueDateRuleSourceType.PreviousTaskCompletedDate,
    label: 'previous task is checked',
    icon: IconType.CheckmarkSquare,
  },
];

const PopularBeforeItems: RuleItem[] = [
  {
    id: DueDateRuleSourceType.ChecklistDueDate,
    type: DueDateRuleSourceType.ChecklistDueDate,
    label: 'workflow run due date',
    icon: IconType.CalendarAlt,
  },
];

function getPopularItems(
  rule: PartialDueDateRule,
  selectedTaskTemplate: Option<TaskTemplate>,
  taskTemplates: TaskTemplate[],
) {
  return match({ offsetDirection: rule.offsetDirection, ruleType: rule.ruleType })
    .with({ offsetDirection: DueDateRuleOffsetDirection.Before, ruleType: 'Checklist' }, () => [])
    .with({ offsetDirection: DueDateRuleOffsetDirection.Before, ruleType: 'Task' }, () => {
      return PopularBeforeItems;
    })
    .with(
      { offsetDirection: DueDateRuleOffsetDirection.After, ruleType: 'Checklist' },
      () => PopularAfterItemsForFirstTask,
    )
    .with({ offsetDirection: DueDateRuleOffsetDirection.After, ruleType: 'Task' }, () => {
      const hasPreviousTask =
        selectedTaskTemplate &&
        taskTemplates.find(tt => DueDateHelper.isTaskBeforeExcludeHeading(tt, selectedTaskTemplate.orderTree));

      return hasPreviousTask ? PopularAfterItems : PopularAfterItemsForFirstTask;
    })
    .otherwise(value => {
      throw new Error(`Unhandled match case [${JSON.stringify(value)}]`);
    });
}

export type RuleOption = {
  label: string;
  value: string;
  type: DueDateRuleSourceType;
  icon?: IconType;
  suffix?: JSX.Element;
  upgradeRequired?: boolean;
};

const convertPopularItem = (item: RuleItem): RuleOption => ({
  label: item.label,
  value: item.id,
  icon: item.icon,
  type: item.type,
});

const convertDateWidget = (widget: FormFieldWidget): RuleOption => ({
  label: widget.label || widget.key,
  value: widget.header.group.id,
  icon: IconType.CalendarAlt,
  type: DueDateRuleSourceType.FormFieldValue,
});

const convertTaskTemplate = (tt: TaskTemplate): RuleOption => ({
  label: tt.name || '<unnamed task>',
  value: tt.group.id,
  icon: IconType.CheckmarkSquare,
  type: DueDateRuleSourceType.TaskCompletedDate,
});

const getTaskItems = (
  offsetDirection: DueDateRuleOffsetDirection,
  taskTemplates: TaskTemplate[],
  selectedTaskTemplate: Option<TaskTemplate>,
  selectedTaskTemplates: TaskTemplate[],
): RuleOption[] =>
  match(offsetDirection)
    .with(DueDateRuleOffsetDirection.After, () => {
      const firstOrderTree = DueDateHelper.getFirstOrderTree(selectedTaskTemplate, selectedTaskTemplates);
      return taskTemplates
        .filter(t => DueDateHelper.isTaskBeforeExcludeHeading(t, firstOrderTree))
        .map(RuleItems.convertTaskTemplate);
    })
    .otherwise(() => []);

export const RuleItems = {
  PopularAfterItems,
  PopularAfterItemsForFirstTask,
  PopularBeforeItems,
  getPopularItems,
  convertPopularItem,
  convertDateWidget,
  convertTaskTemplate,
  getTaskItems,
};
