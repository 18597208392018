export interface Env {
  AG_GRID_LICENSE_KEY?: string;
  APP_API_URL: string;
  APP_API_PATH: string;
  APP_WEBHOOK_URL?: string;
  APP_API_VERSION: string;
  APP_BLANK_WORKFLOW_ID: string;
  APP_BLANK_FORM_ID: string;
  APP_BLANK_PAGE_ID: string;
  APP_PROCESSPANTS_ORG_ID: string;
  APP_STAGE: 'dev' | 'test' | 'prod';
  APP_URL: string;
  APP_DEFAULT_SUBDOMAIN: string;
  APP_WEB_URL: string;
  AUTH0_APP_ID: string;
  AUTH0_AUDIENCE: string;
  AUTH0_DATABASE_CONNECTION_NAME: string;
  AUTH0_DOMAIN: string;
  AUTH0_LOGOUT_URI: string;
  AUTH0_REDIRECT_PATH: string;
  AUTH0_REDIRECT_URI: string;
  FACEBOOK_PIXEL_ID: string;
  INTERCOM_APP_ID: string;
  LAUNCHDARKLY_KEY: string;
  ALGOLIA_APP_ID: string;
  SATIS_WRITE_KEY: string;
  SENTRY_CONFIG: string;
  SENTRY_DSN: string;
  STRIPE_PUB_KEY: string;
  WISTIA_UPLOAD_URL: string;
  WISTIA_API_PASSWORD: string;
  WISTIA_PROJECT_ID: string;
  ABLY_API_KEY: string;
  IMAGEKIT_URL_ENDPOINT: string;
  GTM_CONTAINER_ID: string;
}

declare const window: Window & { env: Env };

const baseEnv = window.env ?? process.env;
const subdomainApiUrl = `${window.location.origin}${baseEnv.APP_API_PATH}`;
const subdomainAuth0RedirectUrl = `${window.location.origin}${baseEnv.AUTH0_REDIRECT_PATH}`;
const subdomainAppUrl = window.location.origin;

export const env = Object.assign({}, baseEnv, {
  APP_URL: baseEnv.APP_URL ?? subdomainAppUrl,
  APP_API_URL: baseEnv.APP_API_URL ?? subdomainApiUrl,
  AUTH0_REDIRECT_URI: baseEnv.AUTH0_REDIRECT_URI ?? subdomainAuth0RedirectUrl,
} as Partial<Env>);

export const getEnv: () => Env = () => env;
