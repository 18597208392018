import * as React from 'react';
import { useToken, Box } from 'components/design/next';
import { Axis, LineSeries, XYChart } from '@visx/xychart';
import { ParentSizeRenderProps } from '../common/types';
import { ParentSize } from '@visx/responsive';
import { curveCardinal as curve } from '@visx/curve';
import { getRandomInt } from '@process-street/subgrade/core';
import { NotEnoughData } from '../common/not-enough-data';
import { useChartBoxContext } from '../chart-box/context';
import { useEffectOnce } from 'react-use';

export interface EmptyLineGraphChartProps {
  parentSize: ParentSizeRenderProps;
  data: { id: string; value: number }[];
}

export const EmptyLineGraphChart: React.FC<React.PropsWithChildren<EmptyLineGraphChartProps>> = ({
  parentSize: { height: parentHeight, width: parentWidth },
  data,
}) => {
  const stroke = useToken('colors', 'gray.300');
  const [labelHeight, setLabelHeight] = React.useState(0);

  return (
    <Box aria-label="empty line graph">
      <XYChart
        xScale={{ type: 'band' }}
        yScale={{ type: 'linear', round: true }}
        height={parentHeight - labelHeight}
        width={parentWidth}
        margin={{ left: 50, right: 50, bottom: 0, top: 50 }}
      >
        <LineSeries
          stroke={stroke}
          dataKey="Empty line chart"
          data={data}
          xAccessor={d => d.id}
          yAccessor={y => y.value}
          curve={curve}
          max={Math.max(...data.map(d => d.value))}
        />
        <Axis
          key="y-axis"
          label=""
          orientation="left"
          numTicks={10}
          tickFormat={() => ''}
          stroke={stroke}
          hideTicks
          strokeWidth="1"
        />
        <Axis key="x-axis" orientation="bottom" strokeWidth="1" tickFormat={() => ''} stroke={stroke} hideTicks />
      </XYChart>

      <NotEnoughData
        ref={node => {
          setLabelHeight(node?.offsetHeight ?? 0);
        }}
      />
    </Box>
  );
};

export interface EmptyLineGraphProps {
  count?: number;
}

export const EmptyLineGraph: React.FC<React.PropsWithChildren<EmptyLineGraphProps>> = ({ count = 20 }) => {
  const { setIsEmpty } = useChartBoxContext();
  useEffectOnce(() => {
    setIsEmpty.on();
    return () => {
      setIsEmpty.off();
    };
  });

  const data = Array.from({ length: count }, (_, i) => ({
    id: String(i),
    value: getRandomInt({ min: 1, max: 100 }),
  }));

  return <ParentSize>{parentSize => <EmptyLineGraphChart {...{ parentSize, data }} />}</ParentSize>;
};
