import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { PrintOneOffTasks } from '.';
import { PrintOneOffTasksSummary } from './print-one-off-tasks-summary';

export const PrintOneOffTasksModule = angular
  .module(
    'printOneOffTasks',
    [], // dependencies
  )
  .component('psPrintOneOffTasks', reactToAngular(PrintOneOffTasks, ['checklist']))
  .component('psPrintOneOffTasksSummary', reactToAngular(PrintOneOffTasksSummary, ['checklist'])).name;
