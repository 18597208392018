import { Muid, OrganizationTheme } from '@process-street/subgrade/core';
import { HttpStatus } from '@process-street/subgrade/util';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, QueryKey } from 'react-query';
import { axiosService } from 'services/axios-service';

type GetOrganizationThemeParams = {
  organizationId?: Muid;
};

type GetOrganizationThemeResponse = OrganizationTheme;

export const GetOrganizationTheme = {
  key: ['organization', 'theme'],
  getKey: (params: GetOrganizationThemeParams): QueryKey => [...GetOrganizationTheme.key, params],
  queryFn: (params: GetOrganizationThemeParams) =>
    axiosService
      .getAxios()
      .get(`/1/organizations/${params.organizationId}/theme`, {
        validateStatus: status => {
          return status === HttpStatus.OK || status === HttpStatus.NOT_FOUND || status === HttpStatus.PAYMENT_REQUIRED;
        },
      })
      .then(res => {
        if (res.status === HttpStatus.NOT_FOUND) {
          return undefined;
        } else {
          return res.data;
        }
      }),
};

export const useGetOrganizationTheme = <T = GetOrganizationThemeResponse>(
  params: GetOrganizationThemeParams,
  options?: UseQueryOptions<GetOrganizationThemeResponse, AxiosError, T>,
) => {
  return useQuery(GetOrganizationTheme.getKey(params), () => GetOrganizationTheme.queryFn(params), {
    refetchOnWindowFocus: false,
    retry: (failureCount, error) =>
      error.response?.status !== HttpStatus.NOT_FOUND &&
      error.response?.status !== HttpStatus.PAYMENT_REQUIRED &&
      failureCount < 2,
    ...options,
    enabled: Boolean(params.organizationId) && options?.enabled !== false,
  });
};
