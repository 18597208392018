import * as yup from 'yup';
import { Checklist, MultiSelectFieldValue, MultiSelectItemValueStatus } from '@process-street/subgrade/process';
import dayjs from 'dayjs';

export type CreateOneOffTaskSettingsSchema = {
  name: string;
  description: string;
  subtasks?: MultiSelectFieldValue[];
  assigneeEmails: string[];
  required: boolean;
  dueDate?: number;
  linkedChecklistId?: Checklist['id'];
  wfrLinkEnabled: boolean;
};

export const ONE_OFF_TASK_NAME_MAX_LENGTH = 255;

export function getCreateOneOffTaskSettingsSchema({ timeZone }: { timeZone?: string }) {
  const minDate = dayjs().tz(timeZone).toDate().getTime();
  return yup.object().shape<CreateOneOffTaskSettingsSchema>({
    name: yup
      .string()
      .max(ONE_OFF_TASK_NAME_MAX_LENGTH, `Name must be at most ${ONE_OFF_TASK_NAME_MAX_LENGTH} characters.`)
      .trim()
      .required('Name is required.'),
    description: yup.string(),
    dueDate: yup.number().min(minDate, 'The time needs to be set ahead of the current time').optional(),
    assigneeEmails: yup.array().of(yup.string().required()).required(),
    linkedChecklistId: yup.string().when('wfrLinkEnabled', {
      is: true,
      then: yup.string().required(),
    }),
    wfrLinkEnabled: yup.boolean(),
    required: yup.boolean(),
    subtasks: yup
      .array()
      .of(
        yup
          .object()
          .shape<MultiSelectFieldValue>({
            itemType: yup.string(),
            id: yup.string().required(),
            name: yup.string().trim().required("Subtask can't be empty"),
            status: yup.string().oneOf(Object.values(MultiSelectItemValueStatus)).required(),
          })
          .required(),
      )
      .optional() as yup.Schema<MultiSelectFieldValue[] | undefined>,
  });
}
