import { Widget } from '@process-street/subgrade/process';
import { createUsableContext } from '@process-street/subgrade/util';
import React from 'react';
import { ActorRef } from 'xstate';
import { WidgetEvent } from 'pages/forms/_id/edit/types';

type TWidgetActorContext<W extends Widget> = {
  widgetActorRef: ActorRef<WidgetEvent<W>>;
};
export const [_useWidgetActorContext, WidgetActorContext] = createUsableContext<TWidgetActorContext<Widget>>({
  hookName: 'useWidgetActorContext',
  providerName: 'WidgetActorProvider',
});

export function useWidgetActorRef<W extends Widget>() {
  return _useWidgetActorContext() as TWidgetActorContext<W>;
}

export function WidgetActorProvider<W extends Widget>({
  children,
  ...context
}: React.PropsWithChildren<TWidgetActorContext<W>>) {
  // Have to do some `as` casting here because the `send` function is typed as `ActorRef<WidgetMenuEvent<W>>`.
  // What matters is consumers will pass the generic to the hook so it should be typed well.
  const value = React.useMemo(
    () => ({ widgetActorRef: context.widgetActorRef as ActorRef<WidgetEvent> }),
    [context.widgetActorRef],
  );
  return <WidgetActorContext.Provider value={value}>{children}</WidgetActorContext.Provider>;
}
