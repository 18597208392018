import { ConfigWizardWindow } from 'pages/templates/_id/automation/lib';
import { Muid, toError } from '@process-street/subgrade/core';
import { AutomationApi } from 'features/automations/services';

type GetConfigWizard = {
  templateId: Muid;
  solutionInstanceId: string;
  onConfigFinish?: (solutionInstanceId: string, configured: boolean) => void;
  onConfigError?: (message: string) => void;
};

const getConfigWizard = async ({ templateId, solutionInstanceId }: GetConfigWizard): Promise<string> => {
  try {
    const { authorizationCode } = await AutomationApi.authorizeConfig(templateId, solutionInstanceId);
    return ConfigWizardWindow.getConfigWindowUrl({
      solutionInstanceId,
      authorizationCode,
    });
  } catch (err) {
    throw toError(err);
  }
};

export const AutomationUtils = {
  getConfigWizard,
};
