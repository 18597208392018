import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { handleActions as originalHandleActions } from 'redux-actions';

export interface ActionHandlers<T> {
  [key: string]: (state: Draft<T>, action: any) => void | T;
}

/**
 * Transforms actionHandlers into optimistic actionHandlers, meaning once that only handle
 * Success cases and ignore errors
 */
function immerReducerMap<T>(actionHandlers: ActionHandlers<T>): ActionHandlers<T> {
  return Object.keys(actionHandlers).reduce(
    (newReducer, key) => ({
      ...newReducer,
      [key]: produce((draft, action) => {
        return actionHandlers[key](draft, action);
      }),
    }),
    {},
  );
}

/**
 * Wrapper for handleActions, that handles only optimistic cases
 */
export function handleActions<T>(handlers: ActionHandlers<T>, initialState: Partial<T>): Reducer<T> {
  // TODO
  // @ts-expect-error -- this is due to complexity of defining reducer map
  return originalHandleActions<T>(immerReducerMap(handlers), initialState);
}
