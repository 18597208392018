import { Muid } from '@process-street/subgrade/core';
import { DataSetCellValue, DataSetColumnType, DataSetRow } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type UpdateCellValueParams = {
  dataSetId: Muid;
  rowId: Muid;
  columnId: Muid;
  columnType: DataSetColumnType;
  value: DataSetCellValue['value'];
};

type UpdateCellValueResponse = DataSetRow;

export const UpdateCellValueMutation = {
  mutationFn: (params: UpdateCellValueParams) =>
    axiosService
      .getAxios()
      .put(`/1/data-sets/${params.dataSetId}/rows/${params.rowId}/columns/${params.columnId}`, {
        value: params.value,
        columnType: params.columnType,
      })
      .then(r => r.data),
};

export const useUpdateCellValueMutation = (
  options: UseMutationOptions<UpdateCellValueResponse, AxiosError, UpdateCellValueParams> = {},
) => {
  return useMutation(UpdateCellValueMutation.mutationFn, options);
};
