import { MemberItem } from 'components/common/MemberOption';
import React from 'react';
import { BlvdSelect, BlvdSelectProps, BlvdSelectHelpers } from '../BlvdSelect';
import { components, GroupBase, SelectComponentsConfig } from 'react-select';
import { HStack, Text, VStack } from 'components/design/next';
import { Avatar } from 'components/common/Avatar';
import { UserType } from '@process-street/subgrade/core';

export interface BlvdMembersPickerProps {
  id: string;
  value?: MemberItem;
  items: MemberItem[];
  onSelect: (value: MemberItem) => void;
  onMenuClose?: () => void;
  blvdSelectProps?: BlvdSelectProps<MemberItem, false>;
}

type Option = MemberItem;

export const BlvdMembersPicker: React.FunctionComponent<React.PropsWithChildren<BlvdMembersPickerProps>> = ({
  id,
  items,
  value: outerValue,
  onSelect,
  onMenuClose,
  blvdSelectProps,
}) => {
  const filterOption: BlvdSelectProps<Option>['filterOption'] = (option, pattern) => {
    const { email, name } = option.data as Option;
    return email.toLowerCase().includes(pattern.toLowerCase()) || name.toLowerCase().includes(pattern.toLowerCase());
  };
  const value: Option[] = outerValue ? [outerValue] : [];

  return (
    <BlvdSelect
      id={id}
      filterOption={filterOption}
      isSearchable
      getOptionValue={option => option.id}
      placeholder="Add new permission"
      options={items}
      value={value}
      closeMenuOnSelect
      components={BlvdMembersPickerComponents}
      onChange={item => {
        if (BlvdSelectHelpers.isOptionType<Option>(item)) {
          onSelect(item);
        }
      }}
      onMenuClose={() => {
        onMenuClose?.();
      }}
      {...blvdSelectProps}
    />
  );
};

export const BlvdMembersPickerComponents: SelectComponentsConfig<Option, false, GroupBase<Option>> = {
  Option: ({ children: _, ...props }) => {
    const { name, email, avatarUrl, userType, initials, id } = props.data as Option;

    const isEmailVisible = userType === UserType.Standard;

    return (
      <components.Option {...props}>
        <HStack spacing="2">
          <HStack>
            <Avatar url={avatarUrl} initials={initials} userId={id} />
            <VStack alignItems="flex-start" spacing="0">
              <Text color="gray.600">{name}</Text>
              {isEmailVisible ? (
                <Text variant="-2" color="gray.500">
                  {email}
                </Text>
              ) : null}
            </VStack>
          </HStack>
        </HStack>
      </components.Option>
    );
  },
};
