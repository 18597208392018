export const TaskTemplates = ResourceFactory => {
  'ngInject';

  return ResourceFactory.create({
    query: 'GET[] /1/task-templates',
    deleteAllByIds: 'POST[] /1/task-templates/delete',
    updateAllOrderTrees: 'PUT[] /1/task-templates/order-tree',
    updateAllDueOffset: 'PUT[] /1/task-templates/due',
    updateAllStop: 'PUT[] /1/task-templates/stop',
    create: 'PUT /1/task-templates/:id',
    update: 'PATCH /1/task-templates/:id',
    duplicate: 'PUT /1/task-templates/:id/duplicate/:duplicateId',
    updateDueOffset: 'PUT /1/task-templates/:id/due',
    getAllByChecklistRevisionId: 'GET[] /1/checklist-revisions/:id/task-templates',
  });
};
