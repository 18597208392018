import React, { useEffect } from 'react';

import { useToast } from 'components/design/next';
import { FormFieldWidget, RichEmailWidgetAttachmentWithS3File, Template } from '@process-street/subgrade/process';
import { useUploadTemplateEmailAttachment } from 'features/widgets/components/send-rich-email/email_attachment/use-upload-template-email-attachment';
import { UploadButton } from 'features/widgets/components/send-rich-email/email_attachment/index';
import { useShowFileUploadErrorToast } from 'features/widgets/components/send-rich-email/email_attachment/use-show-file-upload-error-toast';
import { DefaultErrorMessages } from 'components/utils/error-messages';

type UploadTemplateEmailAttachmentProps = {
  templateId: Template['id'];
  widgetId: FormFieldWidget['id'];
  onFinish: (attachment: RichEmailWidgetAttachmentWithS3File) => void;
  setIsUploading: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  progress: number | undefined;
  setProgress: (progress: number | undefined) => void;
  totalSize: number;
};
export const UploadTemplateEmailAttachment: React.FC<React.PropsWithChildren<UploadTemplateEmailAttachmentProps>> = ({
  templateId,
  widgetId,
  onFinish,
  setIsUploading,
  setProgress,
  progress,
  totalSize,
}) => {
  const toast = useToast();

  const { dropzoneState, uploadError } = useUploadTemplateEmailAttachment({
    templateId,
    widgetId,
    setIsUploading,
    onFinish,
    setProgress,
    totalSize,
  });

  useEffect(() => {
    if (uploadError) {
      toast({
        status: 'error',
        title: "We're having problems uploading the attachment",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    }
  }, [uploadError, toast]);

  useShowFileUploadErrorToast(dropzoneState);

  return (
    <div {...dropzoneState.getRootProps()}>
      {progress === undefined && (
        <UploadButton aria-label="add attachment" dropzoneInputProps={dropzoneState.getInputProps()} />
      )}
    </div>
  );
};
