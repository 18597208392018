import React from 'react';
import { useToast } from 'components/design/next';
import { captureException as captureSentryException } from '@sentry/browser';
import { ErrorBoundary as ReactErrorBoundary, ErrorBoundaryPropsWithFallback } from 'react-error-boundary';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export type Props = Partial<ErrorBoundaryPropsWithFallback> & {
  recover: (error: Error) => void;
};

export const ErrorBoundary: React.FC<React.PropsWithChildren<Props>> = ({ recover, fallback, ...props }) => {
  const toast = useToast();

  const onError = (error: Error) => {
    captureSentryException({ ...error, message: `Pages editor critical error: ${error.message}` });
    toast({
      status: 'error',
      title: "We're having problems loading the page",
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
    recover(error);
  };

  return <ReactErrorBoundary onError={onError} fallback={fallback ?? <></>} {...props} />;
};
