import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Text,
  HStack,
  useToast,
} from 'components/design/next';
import { SelectFormFieldWidget, Widget } from '@process-street/subgrade/process';
import { Icon } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { MergeTagsByTemplateRevisionIdQuery } from 'features/merge-tags/query-builder';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useDeleteDataSetLinkMutation } from '../../query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { getWidgetModalsMachineService, Selectors } from 'features/widgets/widget-modals-machine';
import { useSelector } from '@xstate/react';
import { noop } from 'lodash';

export type DisconnectDataSetConfirmationModalProps = {
  onUpdate?: (widget: Widget) => void;
};

const actor = getWidgetModalsMachineService();
export const DisconnectDataSetConfirmationModal: React.FC<
  React.PropsWithChildren<DisconnectDataSetConfirmationModalProps>
> = ({ onUpdate = noop }) => {
  const onClose = () => {
    actor.send({ type: 'CLOSE_UNLINK_DATA_SET' });
  };
  const widget = useSelector(actor, Selectors.getWidget) as SelectFormFieldWidget;
  const isOpen = useSelector(actor, Selectors.getIsOpen('unlinkDataSet'));

  const toast = useToast();
  const queryClient = useQueryClient();
  const { WidgetService } = useInjector('WidgetService');

  const deleteDataSetLinkMutation = useDeleteDataSetLinkMutation({
    onSuccess: async () => {
      await MergeTagsByTemplateRevisionIdQuery.invalidate(queryClient, widget.templateRevision.id);
      const updatedWidget = { ...widget, config: { items: widget.config.items } };
      await WidgetService.update(updatedWidget);
      onUpdate(updatedWidget);
      onClose();

      toast({
        status: 'success',
        title: 'Data set disconnected',
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems disconnecting the data set",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const handleConfirm = () => {
    if (!widget.config.linkedSavedViewId || !widget.config.linkedDataSetId || !widget.config.linkId) {
      throw new Error("Widget's config is missing data set link information");
    }

    deleteDataSetLinkMutation.mutate({
      savedViewId: widget.config.linkedSavedViewId,
      dataSetId: widget.config.linkedDataSetId,
      linkId: widget.config.linkId,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <HStack spacing="2">
            <Icon color="gray.400" icon="database" size="4" />

            <Text color="gray.700" fontSize="lg">
              Disconnect Data Set
            </Text>
          </HStack>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text>
            If you disconnect, you will need to replace the related data set variables in the workflow. Are you sure?
          </Text>
        </ModalBody>

        <ModalFooter as={HStack} spacing="2">
          <Button isDisabled={deleteDataSetLinkMutation.isLoading} onClick={onClose} variant="solid" colorScheme="gray">
            Cancel
          </Button>

          <Button
            isLoading={deleteDataSetLinkMutation.isLoading}
            variant="solid"
            colorScheme="brand"
            onClick={handleConfirm}
            loadingText="Disconnecting..."
          >
            Disconnect
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
