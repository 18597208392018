import { ClientRectObject } from '@popperjs/core';
import { TinyMCEEditor } from 'app/features/rich-text';
import { useEffect, useState } from 'react';
import { EditorEvent } from 'tinymce';

export type UseToolbarAnchorPositionParams = {
  editor?: TinyMCEEditor;
};

/*
 * Custom hook to calculate and track the toolbar's anchor position based on
 * the bounding rectangle of the current editor's active node.
 */
export const useToolbarAnchorPosition = ({ editor }: UseToolbarAnchorPositionParams) => {
  const [clientRect, setClientRect] = useState<ClientRectObject | null>(null);

  useEffect(
    function calculateActiveAndDisabledCommands() {
      if (!editor) return;

      const handleNodeChange = ({ parents }: EditorEvent<{ parents: Array<Node> }>) => {
        try {
          if (!parents?.length) return;

          const lastParent = parents[0];

          const rect = (lastParent as HTMLElement)?.getBoundingClientRect();

          if (rect) {
            setClientRect(rect);
          }
        } catch (e) {
          console.error(e);
        }
      };

      editor.on('NodeChange', handleNodeChange);
      return () => {
        editor.off('NodeChange', handleNodeChange);
      };
    },
    [editor],
  );

  return clientRect;
};
