import { AutomationAppName, SolutionTypeTag } from '@process-street/subgrade/automation';
import { VStack, Text, HStack, Icon, Box, StackProps, Button } from 'components/design/next';
import { AutomationAppIcon } from 'features/automations/components/automation-app-icon';
import { useAutomationsModalEvents } from 'features/automations/components/workflow-automations/components/common/context';
import React from 'react';

export interface AutomationsDiscoverabilityProps {}

export const AutomationsDiscoverability: React.FC<React.PropsWithChildren<AutomationsDiscoverabilityProps>> = () => {
  return (
    <VStack alignItems="flex-start" w="full">
      <HStack color="gray.400">
        <Icon icon="bolt" variant="far" size="4" />
        <Text variant="-2u">Connect your apps</Text>
      </HStack>
      <Text variant="-1" color="gray.500">
        Send data to your favorite tools
      </Text>
      <AutomationsOptions pt="3" />
    </VStack>
  );
};

const AutomationsOptions: React.FC<React.PropsWithChildren<StackProps>> = props => {
  const { openSolutionTypeTag } = useAutomationsModalEvents();

  const handleOpenAutomationsModal = () => {
    openSolutionTypeTag(SolutionTypeTag.WhenChecklistCompletedThen);
  };

  return (
    <VStack w="full" spacing="4" {...props}>
      <HStack
        justifyContent="center"
        py="2"
        w="full"
        borderRadius="4"
        bgColor="gray.100"
        _hover={{ cursor: 'pointer' }}
        onClick={handleOpenAutomationsModal}
        aria-label="automation process street"
      >
        <AutomationAppIcon h="5" automationApp="tray:ProcessStreet" />
        <Text fontSize="sm" fontWeight="semibold">
          Start Workflow Run
        </Text>
      </HStack>
      <HStack w="full" spacing="2" justifyContent="space-between">
        <AutomationOption automationApp="tray:GoogleSheets" handleOnClick={handleOpenAutomationsModal} />
        <AutomationOption automationApp="tray:Slack" handleOnClick={handleOpenAutomationsModal} />
        <AutomationOption automationApp="tray:Jira" handleOnClick={handleOpenAutomationsModal} />
        <AutomationOption automationApp="tray:Salesforce" handleOnClick={handleOpenAutomationsModal} />
      </HStack>
    </VStack>
  );
};

type AutomationOptionProps = { automationApp: AutomationAppName; handleOnClick: () => void };
const AutomationOption: React.FC<React.PropsWithChildren<AutomationOptionProps>> = ({
  automationApp,
  handleOnClick,
}) => {
  return (
    <Box
      p="2"
      as={Button}
      variant="unstyled"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="8"
      onClick={handleOnClick}
      _hover={{ bgColor: 'brand.100' }}
    >
      <AutomationAppIcon h="5" automationApp={automationApp} />
    </Box>
  );
};
