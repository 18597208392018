import { FolderPermitResolver } from '@process-street/subgrade/permission/folder-permit-resolver';
import { TemplatePermitResolver } from '@process-street/subgrade/permission/template-permit-resolver';
import { WorkflowRunPermitResolver } from '@process-street/subgrade/permission/workflow-run-permit-resolver';

const permissionsLookup = {
  ...FolderPermitResolver.folderPermitLookup,
  ...TemplatePermitResolver.workflowTemplatePermitLookup,
  ...WorkflowRunPermitResolver.workflowRunPermitLookup,
};

export const PermitResolver = {
  permissionsLookup,
};
