import * as React from 'react';
import { MenuItem, MenuItemProps, Switch, useBoolean } from 'components/design/next';
import { FormFieldWidget } from '@process-street/subgrade/process';
import { useWidgetActorRef } from '../../../shared/widget-context';

export const RequiredMenuItem: React.FC<React.PropsWithChildren<MenuItemProps & { widget: FormFieldWidget }>> = ({
  widget,
  ...props
}) => {
  const { widgetActorRef } = useWidgetActorRef<FormFieldWidget>();
  const [isChecked, setIsChecked] = useBoolean(widget.required);

  return (
    <MenuItem
      iconSpacing="2"
      aria-label="Required toggle"
      icon={
        <Switch
          isChecked={isChecked}
          size="md"
          // let the menu item handle pointer events
          pointerEvents="none"
        />
      }
      closeOnSelect={false}
      onClick={() => {
        widgetActorRef.send({ type: 'UPDATE_WIDGET', widget: { ...widget, required: !isChecked } });
        setIsChecked.toggle();
      }}
      {...props}
    >
      Required
    </MenuItem>
  );
};
