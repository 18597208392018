import { Button, Icon, Image } from 'components/design/next';
import { Auth0ConnectionName, Auth0Constants } from '@process-street/subgrade/auth/auth0-constants';
import * as React from 'react';

export type SignInWithProviderButtonProps = {
  showPopupIcon?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  providerName: Auth0ConnectionName;
  children?: string;
};

const connectionIcons: Record<Auth0ConnectionName, string> = {
  [Auth0ConnectionName.Google]: require('app/images/providers/google-logo.svg'),
  [Auth0ConnectionName.Microsoft]: require('app/images/providers/microsoft-logo.svg'),
};

export const SignInWithProviderButton: React.FC<React.PropsWithChildren<SignInWithProviderButtonProps>> = ({
  showPopupIcon,
  onClick,
  isDisabled,
  providerName,
  children,
}) => {
  const icon = connectionIcons[providerName];

  return (
    <Button
      w="full"
      variant="ghost"
      iconSpacing="2"
      leftIcon={icon ? <Image src={icon} alt={Auth0Constants.ConnectionLabels[providerName]} h="5" /> : undefined}
      rightIcon={showPopupIcon ? <Icon icon="external-link" variant="far" size="4" /> : undefined}
      border="1px"
      borderColor="gray.300"
      color="black"
      h="12"
      fontWeight="normal"
      onClick={onClick}
      isDisabled={isDisabled}
    >
      {children ?? `Sign-in with ${Auth0Constants.ConnectionLabels[providerName]}`}
    </Button>
  );
};
