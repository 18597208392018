import { Button, HStack, Icon, useDisclosure } from 'components/design/next';
import { useTaskTemplateListActorRef } from 'app/pages/workflows/_id/edit-v2/hooks/use-task-templates-list-actor';
import * as React from 'react';
import { DeleteTaskConfirmationModal } from './delete-task-confirmation-modal';
import { useSelector } from '@xstate/react';
import { TaskTemplateListActorSelectors } from '../../../task-template-list-machine';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';

export const BulkDeleteTaskButton = () => {
  const actor = useTaskTemplateListActorRef();
  const editorActor = useFormEditorPageActorRef();

  const widgetsByTaskTemplateId = useSelector(editorActor, FormEditorPageActorSelectors.getWidgetsByTaskTemplateId);
  const selectedTasks = useSelector(actor, TaskTemplateListActorSelectors.getSelectedTaskTemplates);

  const bulkDeleteDisclosure = useDisclosure();
  const handleOnDelete = () => {
    const needsConfirmation = selectedTasks.some(st => {
      const widgets = widgetsByTaskTemplateId[st.id] || [];
      return widgets.length > 0;
    });
    if (needsConfirmation) {
      bulkDeleteDisclosure.onOpen();
    } else {
      confirmBulkDelete();
    }
  };
  const confirmBulkDelete = () => actor.send({ type: 'BULK_DELETE_TASKS' });
  return (
    <>
      <HStack w="full">
        <Button
          w="full"
          display="flex"
          color="gray.500"
          justifyContent="flex-start"
          variant="tertiary"
          iconSpacing="3"
          onClick={handleOnDelete}
          leftIcon={<Icon icon="trash" variant="far" size="4" color="gray.500" />}
        >
          Delete
        </Button>
      </HStack>
      {bulkDeleteDisclosure.isOpen && (
        <DeleteTaskConfirmationModal onClose={bulkDeleteDisclosure.onClose} handleDeleteTasks={confirmBulkDelete} />
      )}
    </>
  );
};
