import angular from 'angular';
import { trace } from 'components/trace';

angular.module('frontStreetApp.controllers').controller('LogoutCtrl', function (appBootService) {
  const ctrl = this;
  const logger = trace({ name: 'LogoutCtrl' });
  logger.info('loading ctrl');

  ctrl.$onInit = () => {
    logger.info('logging out');
    appBootService.cleanUpSessionAndShutdown();
  };
});
