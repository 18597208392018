import { ICellRendererParams } from '@ag-grid-community/core';
import { Muid } from '@process-street/subgrade/core';
import { Box, Icon, Link } from 'components/design/next';
import * as React from 'react';

interface LinkRendererProps extends Partial<Partial<ICellRendererParams>> {
  value?: Muid;
}

export const LinkRenderer: React.FC<React.PropsWithChildren<LinkRendererProps>> = ({ value }) => {
  if (!value) {
    return <div />;
  }

  return (
    <Box textAlign="center">
      <Link href={`/responses/${value}`} isExternal aria-label="view response">
        <Icon icon="external-link" size="4" />
      </Link>
    </Box>
  );
};
