import * as React from 'react';
import { TemplateSettingsSchema } from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/schema';
import { HStack, Icon, Switch, Text, Tooltip } from 'components/design/next';
import { useMatch } from '@process-street/adapters/navigation';

type ChecklistCommentsProps = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: TemplateSettingsSchema;
};

const ARROW_SIZE = 10;

export const ChecklistComments: React.FC<React.PropsWithChildren<ChecklistCommentsProps>> = ({
  handleChange,
  values,
}) => {
  const isEditorV2 = useMatch('templateV2');
  return (
    <HStack>
      <Switch
        name="checklistCommentsEnabled"
        isChecked={values.checklistCommentsEnabled}
        onChange={handleChange}
        size={isEditorV2 ? 'md' : 'lg'}
        spacing="2"
        display="flex"
        alignItems="center"
      >
        <Text fontWeight="normal" color="gray.600" as="span">
          Workflow Run Comments
        </Text>
      </Switch>
      <Tooltip label="Enable comments on all workflow runs" hasArrow arrowSize={ARROW_SIZE} shouldWrapChildren>
        <Icon icon="circle-info" variant="far" size="4" color="gray.500" />
      </Tooltip>
    </HStack>
  );
};
