import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type DeleteOrganizationDomainParams = {
  organizationId?: string;
  domain: string;
};

export const DeleteOrganizationDomainMutation = {
  key: ['organization', 'domains'],
  mutationFn: (params: DeleteOrganizationDomainParams) =>
    axiosService
      .getAxios()
      .delete(`/1/organizations/${params.organizationId}/domains/${params.domain}`)
      .then(r => r.data),
};

export const useDeleteOrganizationDomainMutation = (
  options?: UseMutationOptions<unknown, AxiosError, DeleteOrganizationDomainParams>,
) => {
  return useMutation(params => DeleteOrganizationDomainMutation.mutationFn(params), options);
};
