import angular from 'angular';
import { isBrowserSafari, getOrderableComparer, toOrderTrees } from './util-pure';

angular.module('frontStreetApp.services').service('util', function ($q, $timeout, $window) {
  // TODO
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  // Mobile

  self.isMobile = function () {
    const iOS = $window.navigator.userAgent.match('iPhone OS') || $window.navigator.userAgent.match('iPad');
    const android = $window.navigator.userAgent.match('Android');
    return iOS || android;
  };

  // Promises

  /**
   * Pipe the promise outcome into the given deferred.
   * Thus, if the promise is resolved, then so will the deferred.
   * Conversely, if the promise is rejected, then so will the deferred.
   *
   * @param deferred
   * @param promise
   */
  self.pipe = function (deferred, promise) {
    promise.then(
      value => {
        deferred.resolve(value);
      },
      reason => {
        deferred.reject(reason);
      },
    );
  };

  // Orderable
  Object.assign(self, { toOrderTrees, getOrderableComparer });

  // Function functions

  self.functionize = function (value) {
    return angular.isFunction(value)
      ? value
      : function () {
          return value;
        };
  };

  /**
   * Counting occurrences of a substring in a string.
   * <b>Note:</b> 1. it doesn't taking into account overlapping
   *              2. might be not the best solution for counting singe char in the string
   *
   * @param string a full string
   * @param substring to be counted in full string
   * @returns {number}
   */
  self.countOccurrences = function (string, substring) {
    let count = 0;

    if (substring.length > 0) {
      let pos = string.indexOf(substring);
      while (pos > -1) {
        count += 1;
        pos = string.indexOf(substring, pos + substring.length);
      }
    }

    return count;
  };

  const COPY_NUM_TPL = '#n#';

  /**
   * Generates copy name with ordinal number from original name by looking at other names (copies).
   * Also truncates name with length over specified limit.
   *
   * @param postfixTpl template with COPY_NUM_TPL ('#n#') for ordinal number e.g. ' (Copy #n#)' -> ' (Copy 2)'
   * @param names an array of strings to be checked for other copies
   * @param originalName is name of original item
   * @param limit is max string length e.g. 255
   * @returns {string} Resulting name of a copy
   */
  self.generateCopyName = function (postfixTpl, names, originalName, limit) {
    if (postfixTpl.indexOf(COPY_NUM_TPL) < 0) {
      throw new Error('Template should have ordinal number tag');
    }

    const [tpl] = new RegExp(`[\\s_]*${COPY_NUM_TPL}`, 'g').exec(postfixTpl);

    // Acquiring last ordinal number of copies
    const [postfixStart] = postfixTpl.split(tpl);
    const regex = postfixTpl.replace('(', '\\(').replace(')', '\\)').replace(COPY_NUM_TPL, '(\\d+)');

    let lastOrdinalNumber = 0;

    const postfixLevelEquals = function (a, b) {
      // Strings have same number of postfix elements in the name
      return self.countOccurrences(a, postfixStart) === self.countOccurrences(b, postfixStart);
    };

    for (let i = 0; i < names.length; i += 1) {
      const name = names[i];
      const postfixPos = name.lastIndexOf(postfixStart);
      if (postfixPos > 0) {
        const substring = name.substring(0, postfixPos);

        // Ensuring that we count truncated copies, handle copy of copies and truncated names
        if (
          originalName.startsWith(substring) &&
          (postfixLevelEquals(originalName, substring) || (limit && originalName.length === limit))
        ) {
          const match = new RegExp(regex, 'g').exec(name.substring(postfixPos, name.length));
          if (match && match.length > 1) {
            lastOrdinalNumber = Math.max(match[1], lastOrdinalNumber);
          } else if (lastOrdinalNumber === 0) {
            lastOrdinalNumber = 1;
          }
        }
      }
    }

    // Generating postfix

    let replace = COPY_NUM_TPL;
    let replaceTo = lastOrdinalNumber + 1;
    // Case for first copy that doesn't have ordinal number e.g.'(Copy)'
    if (lastOrdinalNumber === 0 && postfixTpl.indexOf(tpl) > -1) {
      replace = tpl;
      replaceTo = '';
    }
    const postfix = postfixTpl.replace(replace, replaceTo);

    // Truncating by limit
    let newLength = originalName.length;
    if (limit) {
      newLength = Math.min(originalName.length, limit - postfix.length);

      if (newLength < 0) {
        throw new Error('Limit should be bigger than postfix template');
      }
    }

    return originalName.substring(0, newLength) + postfix;
  };

  self.isBrowserSafari = function () {
    return isBrowserSafari($window);
  };
});
