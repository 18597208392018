import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule/approval-rule-subject.model';
import { Muid } from '@process-street/subgrade/core';
import { TaskTemplatePermit } from '@process-street/subgrade/permission';
import { TaskPermissionRule } from '@process-street/subgrade/permission/permission-model';
import {
  DueDateRuleDefinition,
  OrderTreeUtils,
  TaskTemplate,
  TaskTemplateGroup,
  TaskTemplateOrderModel,
  TaskTemplateUpdateResponseStatus,
  Widget,
  WidgetGroup,
} from '@process-street/subgrade/process';
import { TaskAssignment, TaskAssignmentRule } from '@process-street/subgrade/role-assignment';
import { ApprovalRuleSelector } from 'components/approval-rules/store/approval-rules.selectors';
import { DynamicDueDatesSelector } from 'components/dynamic-due-dates/store/dynamic-due-dates.selectors';
import { RoleAssignmentRuleSelector } from 'components/role-assignments/store/role-assignment-rules.selector';
import { TaskPermissionRuleSelector } from 'components/task-permission/store/task-permission-rule.selector';
import { TaskTemplatePermitsSelector } from 'components/task-permission/store/task-template-permit.selector';
import { TemplateTaskAssignmentSelector } from 'components/template-task-assignment/store/template-task-assignment.selector';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import { TaskTemplateApi } from 'reducers/task-template/task-template.api';

import { createSuccessAction } from '@process-street/subgrade/redux';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { ReduxAppState } from 'reducers/types';
import { createCachedAction } from 'reducers/util';
import { Action, Dispatch } from 'redux';
import { ActionMeta } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';

export const TASK_TEMPLATE_GET_ALL_BY_TEMPLATE_REVISION_ID =
  'templateRevision/taskTemplate/GET_ALL_BY_TEMPLATE_REVISION_ID';
export const TASK_TEMPLATE_GET_ALL_BY_CHECKLIST_REVISION_ID =
  'templateRevision/taskTemplate/GET_ALL_BY_CHECKLIST_REVISION_ID';
export const TASK_TEMPLATE_UPDATE = 'templateRevision/taskTemplate/UPDATE';
export const TASK_TEMPLATE_CREATE = 'templateRevision/taskTemplate/CREATE';
export const TASK_TEMPLATE_DELETE_ALL = 'templateRevision/taskTemplate/DELETE_ALL';
export const TASK_TEMPLATE_GET_FIRST_BY_TEMPLATE_ID = 'templateRevision/taskTemplate/RETRIEVE_FIRST';
export const TASK_TEMPLATE_GET_PREMADE_BY_TEMPLATE_ID = 'templateRevision/taskTemplate/GET_PREMADE_BY_TEMPLATE_ID';
export const TASK_TEMPLATE_UPDATE_DUE_OFFSET = 'templateRevision/taskTemplate/UPDATE_DUE_OFFSET';
export const TASK_TEMPLATE_UPDATE_ALL_DUE_OFFSET = 'templateRevision/taskTemplate/UPDATE_ALL_DUE_OFFSET';
export const TASK_TEMPLATE_UPDATE_ALL_ORDER_TREES = 'templateRevision/taskTemplate/UPDATE_ALL_ORDER_TREES';
export const TASK_TEMPLATE_UPDATE_ALL_STOP = 'templateRevision/taskTemplate/UPDATE_ALL_STOP';
export const TASK_TEMPLATE_DUPLICATE = 'templateRevision/taskTemplate/DUPLICATE';
export const TASK_TEMPLATE_UPDATE_ALL_HIDDEN_BY_DEFAULT_BY_TEMPLATE_ID =
  'templateRevision/taskTemplate/UPDATE_ALL_HIDDEN_BY_DEFAULT_BY_TEMPLATE_ID';

export interface TaskTemplateUpdateAllOrderTreesActionMeta {
  approvalRulesToDelete: ApprovalRuleSubject[];
  templateRevisionId: Muid;
}

export interface TaskTemplateDeleteAllActionMeta {
  relatedApprovalRules: ApprovalRuleSubject[];
  dddRules: DueDateRuleDefinition[];
  taskTemplates: TaskTemplate[];
  templateRevisionId: Muid;
  relatedAssignments: TaskAssignment[];
  relatedPermissions: TaskTemplatePermit[];
  relatedPermissionRules: TaskPermissionRule[];
  relatedRoleAssignmentRules: TaskAssignmentRule[];
  relatedWidgets: Widget[];
}

export interface HiddenByDefaultModel {
  hiddenByDefault: boolean;
  taskTemplateId: Muid;
}

export type TaskTemplateDeleteAllAction = ActionMeta<TaskTemplate[], TaskTemplateDeleteAllActionMeta>;
export type TaskTemplateUpdateAllHiddenByDefaultByTemplateIdAction = ActionMeta<
  Record<string, unknown>,
  { templateRevisionId: Muid; hiddenByDefaultModels: HiddenByDefaultModel[] }
>;

export type TaskTemplateGetAllByChecklistRevisionIdAction = ActionMeta<TaskTemplate[], { checklistRevisionId: Muid }>;

export interface TaskTemplateActions {
  create(): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  deleteAllByIds(taskTemplateIds: Muid[]): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  duplicate(): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  getAllByChecklistRevisionId(
    checklistRevisionId: Muid,
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  getAllByTemplateRevisionId(
    templateRevisionId: Muid,
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  getFirstByTemplateId(templateId: Muid): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  update(): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  updateAllDueOffset(): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  updateAllOrderTrees(): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  updateAllStop(): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  updateDueOffset(): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  updateAllHiddenByDefaultByTemplateRevisionId(
    templateRevisionId: Muid,
    hiddenByDefaultModels: HiddenByDefaultModel[],
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;
}

export const TaskTemplateActionsImpl = (TaskTemplateApi: TaskTemplateApi) => {
  'ngInject';

  const create = createCachedAction(TASK_TEMPLATE_CREATE, TaskTemplateApi.create);

  const getAllByTemplateRevisionId = createCachedAction(
    TASK_TEMPLATE_GET_ALL_BY_TEMPLATE_REVISION_ID,
    TaskTemplateApi.getAllByTemplateRevisionId,
    (templateRevisionId: Muid, flushCache: boolean = false) => ({ templateRevisionId, flushCache }),
    (state: ReduxAppState, templateRevisionId: Muid): boolean =>
      TaskTemplateSelector.getLoadedStatusByTemplateRevisionId(templateRevisionId)(state),
    // @ts-expect-error -- TODO
    TaskTemplateSelector.getAllByTemplateRevisionId,
  );

  const getAllByChecklistRevisionId = createCachedAction(
    TASK_TEMPLATE_GET_ALL_BY_CHECKLIST_REVISION_ID,
    TaskTemplateApi.getAllByChecklistRevisionId,
    (checklistRevisionId: Muid) => ({ checklistRevisionId }),
  );

  const getFirstByTemplateId = createCachedAction(
    TASK_TEMPLATE_GET_FIRST_BY_TEMPLATE_ID,
    TaskTemplateApi.getFirstByTemplateId,
    (templateId: Muid) => ({ templateId }),
  );

  const updateAllHiddenByDefaultByTemplateRevisionId = (
    templateRevisionId: Muid,
    hiddenByDefaultModels: HiddenByDefaultModel[],
  ) =>
    createSuccessAction(
      TASK_TEMPLATE_UPDATE_ALL_HIDDEN_BY_DEFAULT_BY_TEMPLATE_ID,
      {},
      { templateRevisionId, hiddenByDefaultModels },
    );

  const update = createCachedAction(TASK_TEMPLATE_UPDATE, TaskTemplateApi.update);

  const updateDueOffset = createCachedAction(TASK_TEMPLATE_UPDATE_DUE_OFFSET, TaskTemplateApi.updateDueOffset);

  const updateAllDueOffset = createCachedAction(
    TASK_TEMPLATE_UPDATE_ALL_DUE_OFFSET,
    TaskTemplateApi.updateAllDueOffset,
  );

  const taskTemplateUpdateAllOrderTreesMetaCreator =
    (orderModels: TaskTemplateOrderModel[], templateRevisionId: Muid) =>
    (state: ReduxAppState): TaskTemplateUpdateAllOrderTreesActionMeta => {
      const orderModelMap: { [id: string]: TaskTemplateOrderModel } = orderModels.reduce(
        (agg: { [id: string]: TaskTemplateOrderModel }, model: TaskTemplateOrderModel) => {
          agg[model.taskTemplateGroupId] = model;
          return agg;
        },
        {},
      );
      const allApprovalRules = ApprovalRuleSelector.getAllByTemplateRevisionId(templateRevisionId)(state);
      const approvalRulesToDelete: ApprovalRuleSubject[] = allApprovalRules.filter(ar => {
        // find the matching model for approval and subject models
        const approvalOrderModel = orderModelMap[ar.approvalTaskTemplateGroupId];
        const subjectOrderModel = orderModelMap[ar.subjectTaskTemplateGroupId];

        if (approvalOrderModel && subjectOrderModel) {
          // if the new approval task place is before the subject, we need to get rid of it
          return OrderTreeUtils.compare(approvalOrderModel.orderTree, subjectOrderModel.orderTree) < 0;
        } else {
          // this approval rule was not affected
          return false;
        }
      });

      return { approvalRulesToDelete, templateRevisionId };
    };

  const updateAllOrderTrees = createCachedAction(
    TASK_TEMPLATE_UPDATE_ALL_ORDER_TREES,
    TaskTemplateApi.updateAllOrderTrees,
    // @ts-expect-error -- TODO
    taskTemplateUpdateAllOrderTreesMetaCreator,
  );

  const updateAllStop = createCachedAction(TASK_TEMPLATE_UPDATE_ALL_STOP, TaskTemplateApi.updateAllStop);

  const deleteAll = createCachedAction(
    TASK_TEMPLATE_DELETE_ALL,
    // async to trigger /REQUEST/, /SUCCESS, and /FAILURE actions
    async (taskTemplates: TaskTemplate[]) => taskTemplates,
    // @ts-expect-error -- TODO
    (taskTemplates: TaskTemplate[]) =>
      (state: ReduxAppState): TaskTemplateDeleteAllActionMeta => {
        const relatedWidgets: Widget[] = taskTemplates.reduce((agg: Widget[], taskTemplate) => {
          const widgets = WidgetSelector.getAllByTaskTemplateId(taskTemplate.id)(state);
          agg.push(...widgets);
          return agg;
        }, []);

        const isRelatedRule = (rule: {
          targetTaskTemplateGroup: TaskTemplateGroup;
          sourceFormFieldWidgetGroup?: WidgetGroup;
        }) => {
          const { targetTaskTemplateGroup, sourceFormFieldWidgetGroup } = rule;
          // Rule is targeting this task
          if (taskTemplates.some(task => task.group.id === targetTaskTemplateGroup.id)) {
            return true;
          }
          // Rule is based on widgets in this task
          if (sourceFormFieldWidgetGroup) {
            return relatedWidgets.some(widget => widget.header.group.id === sourceFormFieldWidgetGroup.id);
          }
          return false;
        };

        const templateRevisionId = taskTemplates[0].templateRevision.id;
        const relatedAssignments = TemplateTaskAssignmentSelector.getAllByTemplateRevisionIdAndTaskTemplateIds(
          templateRevisionId,
          taskTemplates.map((tt: TaskTemplate) => tt.id),
        )(state);

        const relatedApprovalRules: ApprovalRuleSubject[] = ApprovalRuleSelector.getAllByTaskTemplateGroupIds(
          templateRevisionId,
          taskTemplates.map((taskTemplate: TaskTemplate) => taskTemplate.group.id),
        )(state);

        const allAssignmentRules: TaskAssignmentRule[] =
          RoleAssignmentRuleSelector.getAllByTemplateRevisionId(templateRevisionId)(state);
        const relatedRoleAssignmentRules = allAssignmentRules.filter(rule => isRelatedRule(rule));

        const allDDDRules: DueDateRuleDefinition[] =
          DynamicDueDatesSelector.getAllByTemplateRevisionId(templateRevisionId)(state);
        const dddRules = allDDDRules.filter(
          ({ taskTemplateGroup, targetTaskTemplateGroup }) =>
            (taskTemplateGroup && taskTemplates.some(task => task.group.id === taskTemplateGroup.id)) ||
            (targetTaskTemplateGroup && taskTemplates.some(task => task.group.id === targetTaskTemplateGroup.id)),
        );

        const relatedPermissions: TaskTemplatePermit[] = TaskTemplatePermitsSelector.getAllByTaskTemplateIds(
          taskTemplates.map(tt => tt.id),
        )(state);

        const allPermissionRules: TaskPermissionRule[] =
          TaskPermissionRuleSelector.getAllByTemplateRevisionId(templateRevisionId)(state);
        const relatedPermissionRules = allPermissionRules.filter(rule => isRelatedRule(rule));

        return {
          dddRules,
          relatedApprovalRules,
          relatedAssignments,
          relatedPermissionRules,
          relatedPermissions,
          relatedRoleAssignmentRules,
          relatedWidgets,
          taskTemplates,
          templateRevisionId,
        };
      },
  );

  const deleteAllByIds = (taskTemplateIds: Muid[]) => (dispatch: Dispatch) => {
    return TaskTemplateApi.deleteAllByIds(taskTemplateIds).then(result => {
      const removedTemplates = result
        .filter(res => res.response === TaskTemplateUpdateResponseStatus.Ok)
        .map(res => res.taskTemplate)
        .filter((taskTemplate): taskTemplate is TaskTemplate => !!taskTemplate);

      if (removedTemplates.length > 0) {
        // TODO
        // TODO
        // @ts-expect-error -- TODO
        dispatch(deleteAll(removedTemplates));
      }

      return result;
    });
  };

  const duplicate = createCachedAction(TASK_TEMPLATE_DUPLICATE, TaskTemplateApi.duplicate);

  return {
    create,
    deleteAllByIds,
    duplicate,
    getAllByChecklistRevisionId,
    getAllByTemplateRevisionId,
    getFirstByTemplateId,
    update,
    updateAllDueOffset,
    updateAllHiddenByDefaultByTemplateRevisionId,
    updateAllOrderTrees,
    updateAllStop,
    updateDueOffset,
  };
};
