import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { RequestStage } from '@process-street/subgrade/redux/types';
import { concatInDistinctArray } from 'reducers/util';
import { TemplateTaskAssignmentConstants } from 'services/template-task-assignment-constants';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { TASK_TEMPLATE_DELETE_ALL } from 'reducers/task-template/task-template.actions';
import {
  TT_ASSIGNMENT_CREATE,
  TT_ASSIGNMENT_CREATE_ALL,
  TT_ASSIGNMENT_DELETE,
  TT_ASSIGNMENT_DELETE_ALL,
  TT_ASSIGNMENT_GET_ALL_BY_TMPL_REV_ID,
} from './template-task-assignment.actions';

export const isFailedBulkTemplateTaskAssignmentResponse = response =>
  response !== TemplateTaskAssignmentConstants.BulkResponse.OK &&
  response !== TemplateTaskAssignmentConstants.BulkResponse.NOTHING_TO_UPDATE;

export const templateTaskAssignmentEntitiesReducer = handleActions(
  {
    [TT_ASSIGNMENT_GET_ALL_BY_TMPL_REV_ID + RequestStage.Success]: (state, { payload: assignments }) =>
      EntitiesReducerUtils.upsertAll(state, assignments),
    [TT_ASSIGNMENT_CREATE + RequestStage.Success]: (state, { payload: assignment }) =>
      EntitiesReducerUtils.upsert(state, assignment),

    [TT_ASSIGNMENT_CREATE_ALL + RequestStage.Success]: (state, { payload: assignmentsResponse }) => {
      const assignments = [];
      assignmentsResponse.forEach(res => {
        if (!isFailedBulkTemplateTaskAssignmentResponse(res.response)) {
          assignments.push(res.taskTemplateAssignment);
        }
      });

      return EntitiesReducerUtils.upsertAll(state, assignments);
    },
    [TT_ASSIGNMENT_DELETE + RequestStage.Success]: (state, { meta: { assignmentId } }) =>
      EntitiesReducerUtils.delete(state, { id: assignmentId }),
    [TT_ASSIGNMENT_DELETE_ALL + RequestStage.Success]: (state, { payload: unassignmentsResponse }) => {
      const removedAssignments = [];
      unassignmentsResponse.forEach(res => {
        if (!isFailedBulkTemplateTaskAssignmentResponse(res.response)) {
          removedAssignments.push(res.taskTemplateAssignment);
        }
      });

      return EntitiesReducerUtils.deleteAll(state, removedAssignments);
    },
    [TASK_TEMPLATE_DELETE_ALL]: (state, { meta: { relatedAssignments } }) =>
      EntitiesReducerUtils.deleteAll(state, relatedAssignments),
  },
  {},
);

const templateTaskAssignmentByTemplateRevisionIdLookupReducer = handleActions(
  {
    [TT_ASSIGNMENT_GET_ALL_BY_TMPL_REV_ID + RequestStage.Success]: (
      state,
      { payload: assignments, meta: { templateRevisionId } },
    ) => LookupsReducerUtils.replaceAllUsingSelectorFunctions(state, assignments, () => templateRevisionId),
    [TT_ASSIGNMENT_CREATE + RequestStage.Success]: (state, { payload: assignment, meta: { templateRevisionId } }) =>
      LookupsReducerUtils.upsertUsingSelectorFunctions(state, assignment, () => templateRevisionId),
    [TT_ASSIGNMENT_CREATE_ALL + RequestStage.Success]: (
      state,
      { payload: assignmentsResponse, meta: { templateRevisionId } },
    ) => {
      let templateRevisionIdsCopy = state[templateRevisionId] ? [...state[templateRevisionId]] : [];

      assignmentsResponse.forEach(res => {
        if (!isFailedBulkTemplateTaskAssignmentResponse(res.response)) {
          const assignment = res.taskTemplateAssignment;
          templateRevisionIdsCopy = concatInDistinctArray(templateRevisionIdsCopy, assignment.id);
        }
      });

      return { ...state, [templateRevisionId]: templateRevisionIdsCopy };
    },
    [TT_ASSIGNMENT_DELETE + RequestStage.Success]: (state, { meta: { templateRevisionId, assignmentId } }) =>
      LookupsReducerUtils.deleteAllByIds(state, templateRevisionId, [assignmentId]),
    [TT_ASSIGNMENT_DELETE_ALL + RequestStage.Success]: (
      state,
      { payload: unassignmentsResponse, meta: { templateRevisionId } },
    ) => {
      const removedAssignmentIds = [];
      unassignmentsResponse.forEach(res => {
        if (!isFailedBulkTemplateTaskAssignmentResponse(res.response)) {
          removedAssignmentIds.push(res.taskTemplateAssignment.id);
        }
      });
      const newAssignmentIds = (state[templateRevisionId] || [])
        .slice()
        .filter(id => !removedAssignmentIds.includes(id));
      return {
        ...state,
        [templateRevisionId]: newAssignmentIds,
      };
    },
    [TASK_TEMPLATE_DELETE_ALL]: (state, { payload: taskTemplates, meta: { relatedAssignments } }) => {
      if (relatedAssignments.length === 0) {
        return state;
      }

      // here we must assume that this action is performed under the same template revision
      const templateRevisionId = taskTemplates[0].templateRevision.id;
      const relatedAssignmentIds = relatedAssignments.map(a => a.id);
      const newAssignmentIds = (state[templateRevisionId] || [])
        .slice()
        .filter(id => !relatedAssignmentIds.includes(id));
      return {
        ...state,
        [templateRevisionId]: newAssignmentIds,
      };
    },
  },
  {},
);

export const templateTaskAssignmentLookupReducer = combineReducers({
  byTemplateRevisionId: templateTaskAssignmentByTemplateRevisionIdLookupReducer,
});
