import { OrganizationDiscountSelector } from 'reducers/organization/organization-discount.selector';
import { createSelector } from 'reselect';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { connectController } from 'reducers/util';
import { PlanInterval, PlanLevel } from '@process-street/subgrade/billing';
import { isPlanFree } from '@process-street/subgrade/billing/plan-utils';
import { isBillableUser } from '@process-street/subgrade/util/membership-utils';
import { SubscriptionStatus } from '@process-street/subgrade/core';
import templateUrl from './additional-cost-message.component.html';

export const AdditionalCostComponent = {
  templateUrl,
  controller: class {
    constructor($ngRedux, OrganizationActions) {
      'ngInject';

      const mapStateToCtrl = () =>
        createSelector(
          [
            SessionSelector.getSelectedOrganization,
            SessionSelector.getCurrentPlan,
            OrganizationDiscountSelector.getCurrentOrganizationAdditionalMemberCost,
            OrganizationMembershipSelector.getAllWithUserBySelectedOrganizationId,
          ],
          (organization, currentPlan, additionalCost, memberships) => {
            const currentSubIsPremiumAndTrialing =
              !isPlanFree(currentPlan) &&
              organization.subscription &&
              organization.subscription.status === SubscriptionStatus.Trialing;

            const shouldShow =
              additionalCost > 0.1 && !currentSubIsPremiumAndTrialing && currentPlan.level !== PlanLevel.Enterprise;

            const activeMembershipsCount = memberships.filter(isBillableUser).length;

            const minQuantity = organization.minQuantity ?? currentPlan.minQuantity ?? 0;

            const availableMembers = minQuantity - activeMembershipsCount;
            const shouldShowAdditionalCost = activeMembershipsCount >= minQuantity;

            return {
              selectedOrganizationId: organization.id,
              shouldShow,
              shouldShowAdditionalCost,
              availableMembers,
              plan: currentPlan,
              interval: currentPlan.interval === PlanInterval.Monthly ? 'month' : 'year',
              additionalCost,
            };
          },
        );

      const mapDispatchToCtrl = () => ({
        getDiscountByOrganizationId: OrganizationActions.getDiscountByOrganizationId,
      });

      connectController($ngRedux, mapStateToCtrl, mapDispatchToCtrl)(this);
    }

    $onInit() {
      this.actions.getDiscountByOrganizationId(this.state.selectedOrganizationId);
    }
  },
};
