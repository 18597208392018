import { Template, TemplateRevision, TemplateRevisionStatus } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import {
  QueryClient,
  QueryKey,
  QueryObserver,
  QueryObserverOptions,
  QueryObserverResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { axiosService } from 'services/axios-service';
import {
  GetAllTemplateRevisionQueryResponse,
  GetAllTemplateRevisionsQuery,
} from 'features/template-revisions/query-builder/get-all-template-revisions';

export type GetNewestTemplateRevisionsByTemplateIdQueryParams = { templateId?: Template['id'] };

export type GetNewestTemplateRevisionsByTemplateIdQueryResponse = TemplateRevision[];

export const GetNewestTemplateRevisionsByTemplateIdQuery = {
  key: ['template-revisions', 'newest'],
  getKey: (params: GetNewestTemplateRevisionsByTemplateIdQueryParams): QueryKey => [
    ...GetNewestTemplateRevisionsByTemplateIdQuery.key,
    params,
  ],
  queryFn: (params: GetNewestTemplateRevisionsByTemplateIdQueryParams) =>
    axiosService
      .getAxios()
      .get<GetNewestTemplateRevisionsByTemplateIdQueryResponse>(
        `/1/templates/${params.templateId}/template-revisions/newest`,
      )
      .then(res => res.data),
  select: {
    where:
      ({ status }: { status: TemplateRevisionStatus }) =>
      (result: GetNewestTemplateRevisionsByTemplateIdQueryResponse) => {
        switch (status) {
          case TemplateRevisionStatus.Draft:
            return result[1];
          default:
            return result[0];
        }
      },
  },
  makeCacheSetter: ({ queryClient, templateId }: { queryClient: QueryClient; templateId: Template['id'] }) => {
    return {
      updateDraftLastUpdatedDate: () => {
        queryClient.setQueryData<GetNewestTemplateRevisionsByTemplateIdQueryResponse>(
          GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId }),
          current => {
            if (current && current[1]) {
              const [finished, draft] = current;
              const updatedDraft = {
                ...draft,
                lastActiveDate: Date.now(),
              };
              return [finished, updatedDraft];
            } else {
              return [];
            }
          },
        );
      },
    };
  },
};

const getInitialOptions = ({
  queryClient,
  params,
}: {
  queryClient: QueryClient;
  params: GetNewestTemplateRevisionsByTemplateIdQueryParams;
}) => {
  return {
    initialData: () => {
      const cachedData = queryClient.getQueryData<GetAllTemplateRevisionQueryResponse>(
        GetAllTemplateRevisionsQuery.getKey(),
      );

      const finishedRevision = cachedData?.find(
        tr => tr.template.id === params.templateId && tr.status === TemplateRevisionStatus.Finished,
      );
      const draftRevision = cachedData?.find(
        tr => tr.template.id === params.templateId && tr.status === TemplateRevisionStatus.Draft,
      );

      if (finishedRevision) {
        return [finishedRevision];
        // eslint-disable-next-line no-dupe-else-if -- not willing to fix this but this looks dead
      } else if (finishedRevision && draftRevision) {
        return [finishedRevision, draftRevision];
      }
    },
    initialDataUpdatedAt: () => {
      return queryClient.getQueryState(GetAllTemplateRevisionsQuery.getKey())?.dataUpdatedAt;
    },
  };
};

export const useGetNewestTemplateRevisionsByTemplateIdQuery = <
  Select = GetNewestTemplateRevisionsByTemplateIdQueryResponse,
>(
  params: GetNewestTemplateRevisionsByTemplateIdQueryParams,
  options: UseQueryOptions<GetNewestTemplateRevisionsByTemplateIdQueryResponse, AxiosError, Select> = {},
) => {
  const queryClient = useQueryClient();

  return useQuery(
    GetNewestTemplateRevisionsByTemplateIdQuery.getKey(params),
    () => GetNewestTemplateRevisionsByTemplateIdQuery.queryFn(params),
    {
      ...getInitialOptions({ queryClient, params }),
      ...options,
    },
  );
};

export const makeGetNewestTemplateRevisionsByTemplateIdQueryObserver = ({
  queryClient,
  options,
  ...params
}: GetNewestTemplateRevisionsByTemplateIdQueryParams & {
  queryClient: QueryClient;
  options?: QueryObserverOptions<GetNewestTemplateRevisionsByTemplateIdQueryResponse, AxiosError>;
}) => {
  return new QueryObserver<GetNewestTemplateRevisionsByTemplateIdQueryResponse, AxiosError>(queryClient, {
    queryKey: GetNewestTemplateRevisionsByTemplateIdQuery.getKey(params),
    queryFn: () => GetNewestTemplateRevisionsByTemplateIdQuery.queryFn(params),
    enabled: Boolean(params.templateId) && options?.enabled !== false,
    ...options,
  });
};

export type GetNewestTemplateRevisionsByTemplateIdQueryObserverResult = QueryObserverResult<
  GetNewestTemplateRevisionsByTemplateIdQueryResponse,
  AxiosError
>;
