import { getAboveNode } from '@udecode/plate-common';
import { match } from 'ts-pattern';
import { createPagesPluginFactory, PagesElementType, PagesWithOverride } from '../pages-plate-types';

export const ELEMENT_PS_IMAGE: PagesElementType = 'ps-image-widget';

const onDeleteBackward: PagesWithOverride = editor => {
  const { deleteBackward } = editor;
  editor.deleteBackward = unit => {
    if (editor.selection) {
      const blockAbove = getAboveNode(editor);
      if (blockAbove) {
        const [block] = blockAbove;
        const result = match(block)
          .with({ type: 'p', children: [{ text: '' }] }, () => {
            deleteBackward('block');
            return editor;
          })
          .otherwise(() => {});

        if (result) return result;
      }
    }
    deleteBackward(unit);
  };
  return editor;
};

export const createPSImage = createPagesPluginFactory({
  key: ELEMENT_PS_IMAGE,
  isElement: true,
  withOverrides: onDeleteBackward,
});
