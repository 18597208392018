import { Muid } from '@process-street/subgrade/core';
import { TaskAssignmentRule } from '@process-street/subgrade/role-assignment';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateTaskAssignmentRuleMutationParams = {
  templateRevisionId: Muid;
} & Omit<TaskAssignmentRule, 'id'>; // note that id is not honored by the back-end

export type CreateTaskAssignmentRuleMutationResponse = TaskAssignmentRule;

export const CreateTaskAssignmentRuleMutation = {
  key: ['post', 'template-revisions', 'task-assignment-rules'],
  getKey: (params: Pick<CreateTaskAssignmentRuleMutationParams, 'templateRevisionId'>) => [
    ...CreateTaskAssignmentRuleMutation.key,
    params.templateRevisionId,
  ],
  mutationFn: ({ templateRevisionId, ...params }: CreateTaskAssignmentRuleMutationParams) =>
    axiosService
      .getAxios()
      .post<CreateTaskAssignmentRuleMutationResponse>(
        `/1/template-revisions/${templateRevisionId}/task-assignment-rules`,
        params,
      )
      .then(res => res.data),
};

export const useCreateTaskAssignmentRuleMutation = (
  options: UseMutationOptions<
    CreateTaskAssignmentRuleMutationResponse,
    AxiosError,
    CreateTaskAssignmentRuleMutationParams
  > = {},
) => {
  return useMutation(CreateTaskAssignmentRuleMutation.mutationFn, options);
};
