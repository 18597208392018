import { createCachedAction, optimisticAction, toFailure, toRequest, toSuccess } from 'reducers/util';
import { createAction } from 'redux-actions';
import { ChecklistRevisionSelector } from 'reducers/checklist-revision/checklist-revision.selectors';
import { OptimisticTask } from '../optimistic';
import { Action, combineReducers } from 'redux';
import { getStatusReducer } from 'reducers/statuses/statuses-utils';
import { ReduxAppState } from 'reducers/types';
import { ThunkDispatch } from 'redux-thunk';
import { Task } from '@process-street/subgrade/process';
import { queryClient } from 'app/components/react-root';
import { GetAllTasksByChecklistRevisionIdQuery } from 'app/features/task/query-builder';

export const TASK_GET_ALL_BY_CHECKLIST_REVISION_ID = 'task/GET_ALL_BY_CHECKLIST_REVISION_ID';
export const TASK_COUNT_ALL_BY_ORGANIZATION_ID = 'task/COUNT_ALL_BY_ORGANIZATION_ID';
export const TASK_GET = 'task/GET';
export const TASK_UPDATE_INTERNAL = 'task/UPDATE_INTERNAL';
export const TASK_UPDATE_STATUS = 'task/STATUS_UPDATE';
export const TASK_UPDATE_DUE_DATE = 'task/UPDATE_DUE_DATE';

// TODO Following 4 actions are never used inside the code consider removing them from Frontend & Backend
export const TASK_UPDATE_STATUSES = 'task/UPDATE_STATUSES';
export const TASK_ASSIGN_TO_TASK = 'task/ASSIGN_TO_TASK';
export const TASK_UNASSIGN_FROM_TASK = 'task/UNASSIGN_FROM_TASK';
export const TASK_GET_ASSIGNMENTS = 'task/GET_ASSIGNMENTS';

export const TaskActions = (TaskApi: any) => {
  'ngInject';

  const doGetAllByChecklistRevisionId = createCachedAction(
    TASK_GET_ALL_BY_CHECKLIST_REVISION_ID,
    (__checklistId, checklistRevisionId) => TaskApi.getAllByChecklistRevisionId(checklistRevisionId),
    (checklistId, checklistRevisionId, flushCache) => ({ checklistId, checklistRevisionId, flushCache }),
  );

  const getAllByChecklistRevisionId =
    (checklistRevisionId: string, flushCache?: boolean) =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, Action>, getState: () => ReduxAppState) => {
      const checklistRevision = ChecklistRevisionSelector.getById(checklistRevisionId)(getState());
      const checklistId = checklistRevision ? checklistRevision.checklist.id : undefined;
      if (flushCache) {
        queryClient.invalidateQueries(
          GetAllTasksByChecklistRevisionIdQuery.getKey({
            checklistRevisionId,
          }),
        );
      }
      return dispatch(doGetAllByChecklistRevisionId(checklistId, checklistRevisionId, flushCache));
    };

  const countAllByOrganizationId = createCachedAction(
    TASK_COUNT_ALL_BY_ORGANIZATION_ID,
    TaskApi.countAllByOrganizationId,
  );

  const get = createCachedAction(TASK_GET, TaskApi.get);

  const updateDueDate = optimisticAction(
    TASK_UPDATE_DUE_DATE,
    TaskApi.updateDueDate,
    (taskId, dueDate, dueDateOverridden) => ({ taskId, dueDate, dueDateOverridden }),
    OptimisticTask.updateDueDate,
  );

  const updateInternal = createAction(TASK_UPDATE_INTERNAL, (task: Task) => ({ task }));

  const updateTaskStatusRequest = createAction(
    toRequest(TASK_UPDATE_STATUS),
    () => {},
    (task: Task) => ({
      taskId: task.id,
    }),
  );
  const updateTaskStatusSuccess = createAction(
    toSuccess(TASK_UPDATE_STATUS),
    () => {},
    (task: Task) => ({
      taskId: task.id,
    }),
  );
  const updateTaskStatusFailure = createAction(
    toFailure(TASK_UPDATE_STATUS),
    () => {},
    (task: Task) => ({
      taskId: task.id,
    }),
  );

  const assignToTask = createCachedAction(TASK_ASSIGN_TO_TASK, TaskApi.assignToTask);

  const unassignFromTask = createCachedAction(TASK_UNASSIGN_FROM_TASK, TaskApi.unassignFromTask);

  const getAssignment = createCachedAction(TASK_GET_ASSIGNMENTS, TaskApi.getAssignment);

  return {
    assignToTask,
    countAllByOrganizationId,
    get,
    getAllByChecklistRevisionId,
    getAssignment,
    unassignFromTask,
    updateDueDate,
    updateInternal,
    updateTaskStatusRequest,
    updateTaskStatusSuccess,
    updateTaskStatusFailure,
  };
};

export type TaskActionsType = ReturnType<typeof TaskActions>;

export const taskStatusUpdatesReducer = combineReducers({
  byTaskId: getStatusReducer(TASK_UPDATE_STATUS, 'taskId'),
});
