import { Muid, Option, Period } from '../core';
import { FormFieldValue, FormFieldWidget, OrderTree, Task, TaskStatus } from '../process';

export enum ChecklistRuleDefinitionFormFieldOperator {
  Is = 'Is',
  IsNot = 'IsNot',
  IsGreaterThan = 'IsGreaterThan',
  IsLessThan = 'IsLessThan',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
  Contains = 'Contains',
  DoesNotContain = 'DoesNotContain',
  HasNoValue = 'HasNoValue',
  HasAnyValue = 'HasAnyValue',
  IsEmpty = 'IsEmpty',
  IsNotEmpty = 'IsNotEmpty',
  TaskStatusIs = 'TaskStatusIs',
  Precalculated = 'Precalculated',
  TaskPrecalculated = 'TaskPrecalculated',
  PeriodAfter = 'PeriodAfter',
}

export enum ChecklistRuleDefinitionLogicalOperator {
  LogicalOr = 'LogicalOr',
  LogicalAnd = 'LogicalAnd',
}

export const ChecklistRuleDefinitionOperator = Object.assign(
  {},
  ChecklistRuleDefinitionFormFieldOperator,
  ChecklistRuleDefinitionLogicalOperator,
);
export type ChecklistRuleDefinitionOperator =
  | ChecklistRuleDefinitionFormFieldOperator
  | ChecklistRuleDefinitionLogicalOperator;

export enum ChecklistRuleDefinitionOperandType {
  String = 'String',
  StringList = 'StringList',
  DateTime = 'DateTime',
  Logical = 'Logical',
  Precalculated = 'Precalculated',
  Period = 'Period',
}

export interface FormFieldCondition {
  formFieldWidgetGroupId?: Muid;
  operator: ChecklistRuleDefinitionOperator;
  operandType: ChecklistRuleDefinitionOperandType;
  operandValue: { value: FormFieldOperandValue | null };
}

export interface TaskCondition {
  taskTemplateGroupId?: Muid;
  operator: ChecklistRuleDefinitionOperator;
  operand: TaskOperand;
}

export interface LogicalCondition {
  operator: ChecklistRuleDefinitionLogicalOperator;
  conditions: Condition[];
}

export interface TimeBasedCondition {
  operator: ChecklistRuleDefinitionOperator;
  operand: TimeBasedOperand;
  dateType: 'ChecklistStartDate';
}

/**
 * Can also be PrecalculatedCondition when running a workflow.
 * WFR rules engine is still in JS so far, these types are applicable for
 * the rules editor, where conditions are never Precalculated .
 */
export type Condition = FormFieldCondition | TaskCondition | LogicalCondition | TimeBasedCondition;

export interface LogicalOperand {
  data: LogicalCondition;
  operandType: ChecklistRuleDefinitionOperandType.Logical;
}

// Only 'number' for date fields, string otherwise
export type FormFieldOperandValue = string | number;

/** Old single-condition operand type. */
export interface FormFieldOperand {
  operandType: ChecklistRuleDefinitionOperandType;
  value: FormFieldOperandValue | null;
}

export type TaskOperandValue = TaskStatus;

export interface TaskOperand {
  operandType: ChecklistRuleDefinitionOperandType;
  value: TaskOperandValue | null;
}

export interface TimeBasedOperand {
  operandType: ChecklistRuleDefinitionOperandType.Period;
  value: Partial<Period> | null;
}

export type Operand = FormFieldOperand | LogicalOperand;

export interface BaseChecklistRuleDefinition {
  id: Muid;
  organizationId: Muid;
  orderTree: OrderTree;
  formFieldWidgetGroupId?: Muid;
  taskTemplateGroupId?: Muid;
  operator: ChecklistRuleDefinitionOperator;
  operand: Operand;
  /** Rule action - hide (true), show (false) */
  hidden: boolean;
  /** Rule action - task template group IDs */
  taskTemplateGroupIds: Muid[];
  /** Rule action - widget group IDs */
  widgetGroupIds: Muid[];
  description?: string;
}

export interface LogicalChecklistRuleDefinition extends BaseChecklistRuleDefinition {
  operator: ChecklistRuleDefinitionLogicalOperator.LogicalOr;
  operand: LogicalOperand;
}

/**
 * @deprecated Non-logical (simple) rule definitions are deprecated.
 * All new rules created are logical. Existing rules can be "simple", until the user
 * edits and saves them.
 */
export interface SimpleChecklistRuleDefinition extends BaseChecklistRuleDefinition {
  formFieldWidgetGroupId: Muid;
  operand: FormFieldOperand;
}

export type ChecklistRuleDefinition = SimpleChecklistRuleDefinition | LogicalChecklistRuleDefinition;

export function isOperandLogical(operand: Operand): operand is LogicalOperand {
  return 'data' in operand;
}

export function isRuleLogical(rule: ChecklistRuleDefinition): rule is LogicalChecklistRuleDefinition {
  return Boolean(rule.operand) && isOperandLogical(rule.operand);
}

export function isConditionLogical(condition: Condition): condition is LogicalCondition {
  return 'conditions' in condition;
}

export interface TaskTemplateHiddenByDefault {
  taskTemplateId: Muid;
  hiddenByDefault: boolean;
}

export interface ConditionalRules {
  id?: Muid;
  definitions: ChecklistRuleDefinition[];
  hiddenByDefaults: TaskTemplateHiddenByDefault[];
}

export type ConditionEvaluatorOperand = FormFieldOperandValue | null | undefined;

export interface ConditionEvaluator {
  evaluate: (
    formFieldValue: Option<FormFieldValue>,
    formFieldWidget: FormFieldWidget,
    operand?: ConditionEvaluatorOperand,
  ) => boolean;
}

export interface TaskConditionEvaluator {
  evaluate: (task: Task, operand?: TaskOperandValue | null) => boolean;
}
