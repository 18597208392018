import * as React from 'react';
import { Flex, FlexProps, Icon, IconProps } from 'components/design/next';

export interface CustomNotificationIconProps extends Partial<FlexProps> {
  clockProps?: Partial<IconProps>;
  bellProps?: Partial<IconProps>;
}

const defaultBellProps: Partial<IconProps> = {
  variant: 'far',
  size: '4',
};

const defaultClockProps: Partial<IconProps> = {
  variant: 'far',
  size: '3',
};

export const CustomNotificationIcon: React.FC<React.PropsWithChildren<CustomNotificationIconProps>> = ({
  clockProps = {},
  bellProps = {},
  ...props
}) => {
  const { bg = 'inherit' } = props;

  return (
    <Flex className="ps-custom-notification-icon" display="inline-flex" bg={bg} justifyContent="center" {...props}>
      <Icon icon="bell" {...{ ...defaultBellProps, ...bellProps }} />
      <Icon
        icon="clock"
        alignSelf="flex-end"
        marginLeft="-25%"
        bgColor={bg}
        borderRadius="full"
        {...{ ...defaultClockProps, ...clockProps }}
      />
    </Flex>
  );
};
