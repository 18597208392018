import { Template } from '@process-street/subgrade/process';
import { QueryKey } from 'react-query';
import { TraySolution } from '@process-street/subgrade/automation';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type SolutionsByTemplateIdQueryParams = { templateId?: Template['id'] };

export type SolutionsByTemplateIdQueryResponse = TraySolution[];

export const SolutionsByTemplateIdQuery = {
  key: ['solutions'],
  getKey: (params: SolutionsByTemplateIdQueryParams): QueryKey => [...SolutionsByTemplateIdQuery.key, params],
  queryFn: ({ templateId }: SolutionsByTemplateIdQueryParams) =>
    axiosService
      .getAxios()
      .get(`/1/templates/${templateId}/automations/solutions`)
      .then(res => res.data),
};

export function useSolutionsByTemplateIdQuery<Select = SolutionsByTemplateIdQueryResponse>(
  params: SolutionsByTemplateIdQueryParams,
  options: UseQueryOptions<SolutionsByTemplateIdQueryResponse, AxiosError, Select> = {},
) {
  return useQuery(SolutionsByTemplateIdQuery.getKey(params), () => SolutionsByTemplateIdQuery.queryFn(params), {
    ...options,
    enabled: Boolean(params.templateId) && options.enabled !== false,
  });
}
