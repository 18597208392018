import { createSelector, Selector } from 'reselect';
import { Muid, Option } from '../../core';
import { TemplateRevision, TemplateRevisionStatus } from '../../process';
import { EntityMap, LightReduxState, ObjectMap } from '../types';
import { BaseTemplateSelector } from './template.selectors';

const getById =
  (revisionId: Muid): Selector<LightReduxState, Option<TemplateRevision>> =>
  (state: LightReduxState): Option<TemplateRevision> =>
    getEntityMap(state)[revisionId];

const getEntityMap: Selector<LightReduxState, EntityMap<TemplateRevision>> = (
  state: LightReduxState,
): EntityMap<TemplateRevision> => state.entities.templateRevision;

const templateRevisionByTemplateId = createSelector(getEntityMap, templateRevisionMap =>
  Object.values(templateRevisionMap).reduce((agg, templateRevision) => {
    if (templateRevision.status === TemplateRevisionStatus.Finished) {
      agg[templateRevision.template.id] = templateRevision;
    }
    return agg;
  }, {} as ObjectMap<TemplateRevision>),
);

const getFinishedByTemplateId =
  (templateId: Muid) =>
  (state: LightReduxState): Option<TemplateRevision> =>
    templateRevisionByTemplateId(state)[templateId];

const getWithTemplateById =
  (revisionId: Muid): Selector<LightReduxState, Option<TemplateRevision>> =>
  (state: LightReduxState): Option<TemplateRevision> => {
    const templateRevision = getById(revisionId)(state);
    if (templateRevision) {
      const template = BaseTemplateSelector.getById(templateRevision.template.id)(state);
      if (template) {
        return { ...templateRevision, template };
      }
    }
    return undefined;
  };

export const BaseTemplateRevisionSelector = {
  getById,
  getEntityMap,
  getWithTemplateById,
  getFinishedByTemplateId,
};
