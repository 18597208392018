import { combineActions } from 'redux-actions';
import { createCachedAction } from 'reducers/util';

export const CHECKLIST_ASSIGNMENT_GET_ALL_BY_CHECKLIST_ID = 'checklistAssignment/GET_ALL_BY_CHECKLIST_ID';
export const CHECKLIST_ASSIGNMENT_GET_ALL_BY_ORGANIZATION_ID = 'checklistAssignment/GET_ALL_BY_ORGANIZATION_ID';
export const CHECKLIST_ASSIGNMENT_SEARCH_ALL_ACTIVE_BY_TEMPLATE_ID =
  'checklistAssignment/SEARCH_ALL_ACTIVE_BY_TEMPLATE_ID';
export const CHECKLIST_ASSIGNMENT_ASSIGN_USER = 'checklistAssignment/ASSIGN_USER';
export const CHECKLIST_ASSIGNMENT_UNASSIGN_USER = 'checklistAssignment/UNASSIGN_USER';

export const CHECKLIST_ASSIGNMENT_ARRAY_ACTIONS = combineActions(
  CHECKLIST_ASSIGNMENT_GET_ALL_BY_CHECKLIST_ID,
  CHECKLIST_ASSIGNMENT_GET_ALL_BY_ORGANIZATION_ID,
  CHECKLIST_ASSIGNMENT_SEARCH_ALL_ACTIVE_BY_TEMPLATE_ID,
);

export const ChecklistAssignmentActions = ChecklistAssignmentApi => {
  'ngInject';

  const assignUser = createCachedAction(CHECKLIST_ASSIGNMENT_ASSIGN_USER, ChecklistAssignmentApi.assignUser);

  const getAllByChecklistId = createCachedAction(
    CHECKLIST_ASSIGNMENT_GET_ALL_BY_CHECKLIST_ID,
    ChecklistAssignmentApi.getAllByChecklistId,
    (checklistId, flushCache) => ({ checklistId, flushCache }),
  );

  const getAllByOrganizationId = createCachedAction(
    CHECKLIST_ASSIGNMENT_GET_ALL_BY_ORGANIZATION_ID,
    ChecklistAssignmentApi.getAllByOrganizationId,
  );

  const unassignUser = createCachedAction(CHECKLIST_ASSIGNMENT_UNASSIGN_USER, ChecklistAssignmentApi.unassignUser);

  return {
    assignUser,
    getAllByChecklistId,
    getAllByOrganizationId,
    unassignUser,
  };
};
