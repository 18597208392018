export const waitForElement = <T extends HTMLElement>(selector: string, timeout = 100): Promise<T> => {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    // Function to check if the element is in the DOM
    const checkForElement = () => {
      const element = document.querySelector(selector);

      // If the element is found, resolve the promise
      if (element) {
        resolve(element as T);
      } else {
        // Check if the timeout has been reached
        if (Date.now() - startTime >= timeout) {
          reject(new Error(`Element with selector "${selector}" not found within ${timeout}ms`));
        } else {
          // If not, try again after a delay
          requestAnimationFrame(checkForElement);
        }
      }
    };

    // Start checking for the element
    checkForElement();
  });
};
