import * as React from 'react';

import {
  Avatar,
  AvatarBadge,
  Box,
  Center,
  Divider,
  HStack,
  Icon,
  IconButton,
  Link,
  ListItemProps,
  Stack,
  Text,
  Tooltip,
  useBoolean,
  VStack,
} from 'components/design/next';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import { CommentInboxItem, CommentInboxItemStatus } from '@process-street/subgrade/process';

import { useInjector } from 'components/injection-provider';

import { ContentText, HoverableListItem } from '../../common';
import { useUpdateCommentStatusMutation } from 'features/comments/query-builder/update-comment-status-mutation';
import { GetInboxCommentsQuery, GetUnreadCommentsCountQuery } from 'features/comments/query-builder';
import { ReplyCommentPopover } from '../reply-comment-popover';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { useQueryClient } from 'react-query';
import { useBreakpointValue } from 'components/design/next';
import { MergeTagHighlighter } from 'features/merge-tags/components/merge-tag-highlighter';
import { match } from 'ts-pattern';
import { useOneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';

export type TaskCommentItemProps = {
  commentInboxItem: CommentInboxItem;
} & ListItemProps;

export const TaskCommentItem: React.FC<TaskCommentItemProps> = ({ commentInboxItem, ...props }) => {
  const queryClient = useQueryClient();

  const { $state } = useInjector('$state');
  const [isCommenting, setIsCommenting] = useBoolean();

  const userInfoQuery = useGetCurrentUserInfoQuery();

  const {
    createdByUsername,
    createdByAvatarUrl,
    createdDate,
    taskTemplateName,
    taskTemplateGroupId,
    checklistId,
    checklistName,
    canAccessResource,
    commentContent,
    id,
    status,
    commentId,
    itemType,
    taskId,
  } = commentInboxItem;

  const taskHref = $state.href('checklist.task.comment', {
    id: checklistId,
    groupId: taskTemplateGroupId,
    commentId,
  });

  const workflowRunHref = $state.href('checklist', { id: checklistId });

  const updateCommentStatusMutation = useUpdateCommentStatusMutation({
    onSuccess: result => {
      queryClient.setQueryData(GetInboxCommentsQuery.getKey({}), (old = []) =>
        (old as CommentInboxItem[]).map(inboxComment =>
          inboxComment.id === result.id ? { ...inboxComment, status: result.status } : inboxComment,
        ),
      );
      queryClient.invalidateQueries(GetUnreadCommentsCountQuery.getKey());
    },
    retry: 3,
  });

  const markCommentAsRead = () =>
    status === CommentInboxItemStatus.unread &&
    updateCommentStatusMutation.mutate({ id, status: CommentInboxItemStatus.read });

  const isMobile = useBreakpointValue({ base: true, md: false });
  const { viewTaskById } = useOneOffTaskDrawerStore();
  const openOneOffTask = () => {
    markCommentAsRead();
    viewTaskById({ taskId });
  };

  return (
    <HoverableListItem key={id} {...props}>
      {({ hovered }) => (
        <Tooltip
          hasArrow
          label="You don't have access to this document, it's been deleted or the comments have been disabled. Please reach out to the owner or an admin."
          isDisabled={canAccessResource}
        >
          <HStack spacing="6" alignItems="stretch" width="full">
            <Avatar name={createdByUsername} src={createdByAvatarUrl} size="xs" border="none">
              {status === CommentInboxItemStatus.unread && <AvatarBadge boxSize="2.5" bg="red.500" top="-1" />}
            </Avatar>
            <Box alignItems="flex-start" minW="0" width="full" flex="1">
              <Stack direction={['column', 'row']}>
                <VStack flex="1" alignItems="flex-start" spacing={0.5} width={['full', '70']}>
                  <HStack justifyContent="space-between" alignItems="flex-start" width="full">
                    <Text aria-label="comment description" color="gray.600">
                      <Text aria-label="comment sender" as="span" fontWeight="bold">
                        {createdByUsername}{' '}
                      </Text>{' '}
                      mentioned you on task{' '}
                      {match({ canAccessResource, itemType })
                        .with({ canAccessResource: false }, () => (
                          <Text color="gray.500" fontSize="medium" noOfLines={1} as="span">
                            {taskTemplateName}
                          </Text>
                        ))
                        .with({ itemType: 'ProjectTask' }, () => (
                          <Link
                            aria-label="task link"
                            variant="noline"
                            color="brand.500"
                            fontWeight="medium"
                            noOfLines={1}
                            onClick={openOneOffTask}
                          >
                            {taskTemplateName}
                          </Link>
                        ))
                        .otherwise(() => (
                          <Link
                            aria-label="task link"
                            href={taskHref}
                            variant="noline"
                            color="brand.500"
                            fontWeight="medium"
                            noOfLines={1}
                            onClick={markCommentAsRead}
                          >
                            {taskTemplateName}
                          </Link>
                        ))}
                    </Text>
                  </HStack>
                  <HStack minW="0" flex="1" mt="0" width="full">
                    <Text
                      aria-label="comment date"
                      variant="-1"
                      color="gray.500"
                      maxWidth={{ base: '50%', md: '100%' }}
                      noOfLines={1}
                    >
                      {DateUtils.formatDateTime(
                        createdDate,
                        DateUtils.isOverYear(createdDate)
                          ? DateFormat.DateMonthDayYearAtTime
                          : DateFormat.DateMonthDayAtTime,
                        userInfoQuery.data?.user.timeZone,
                      )}
                    </Text>

                    {itemType !== 'ProjectTask' && (
                      <Center height="3">
                        <Divider borderColor="gray.500" borderLeftWidth="0.5" orientation="vertical" />
                      </Center>
                    )}

                    {match({ canAccessResource, itemType })
                      .with({ canAccessResource: false }, () => (
                        <Text
                          color="gray.500"
                          fontSize="sm"
                          maxWidth={{ base: '50%', md: '100%' }}
                          noOfLines={1}
                          as="span"
                        >
                          <Icon size="1em" variant="far" icon="workflow-run" />{' '}
                          <MergeTagHighlighter content={checklistName ?? ''} />
                        </Text>
                      ))
                      .with({ itemType: 'ProjectTask' }, () => null)
                      .otherwise(() => (
                        <Link
                          aria-label="workflow run link"
                          href={workflowRunHref}
                          variant="noline"
                          color="gray.500"
                          fontSize="sm"
                          maxWidth={{ base: '50%', md: '100%' }}
                          noOfLines={1}
                        >
                          <Icon size="1em" variant="far" icon="workflow-run" mt="-2px" />{' '}
                          <MergeTagHighlighter content={checklistName ?? ''} />
                        </Link>
                      ))}
                  </HStack>
                </VStack>
                <VStack justifyContent="center" alignItems={'end'} width={['full', '30']}>
                  {(hovered || isCommenting || isMobile) && (
                    <HStack>
                      {canAccessResource && (
                        <ReplyCommentPopover
                          {...{
                            checklistId: commentInboxItem.checklistId,
                            templateId: commentInboxItem.templateId,
                            taskId: commentInboxItem.taskId,
                            onOpen: () => {
                              markCommentAsRead();
                              setIsCommenting.on();
                            },
                            onClose: setIsCommenting.off,
                          }}
                        />
                      )}
                      <Tooltip label="Mark as read" hasArrow>
                        <IconButton
                          aria-label="mark as read"
                          variant="outline"
                          size="sm"
                          borderWidth="px"
                          bg="white"
                          color="gray.700"
                          borderColor="gray.300"
                          icon={<Icon size="4" variant="far" icon="check" color="gray.700" />}
                          onClick={markCommentAsRead}
                          disabled={status === CommentInboxItemStatus.read}
                        />
                      </Tooltip>
                    </HStack>
                  )}
                </VStack>
              </Stack>
              <ContentText
                {...{
                  content: commentContent,
                  checklistId,
                  minW: '0',
                  flex: '1',
                  mt: '2',
                  fontWeight: status === CommentInboxItemStatus.read ? 'normal' : '600',
                }}
              />
            </Box>
          </HStack>
        </Tooltip>
      )}
    </HoverableListItem>
  );
};
