import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { DefaultableFormFieldValue, DefaultableFormFieldWidget, WidgetUtils } from '@process-street/subgrade/process';
import { useFeatureFlag } from 'features/feature-flags';
import { match, P } from 'ts-pattern';
import { useMaskedValue, useSyncedValue, validatedInputMachine } from 'utils/widget/validated-input-machine';
import { InterpreterFrom } from 'xstate';

export type Args = {
  send: InterpreterFrom<typeof validatedInputMachine>['send'];
  widget: DefaultableFormFieldWidget;
  formFieldValue?: DefaultableFormFieldValue;
};

export function useSyncedAndMaskedFormFieldValues({ widget, formFieldValue, send }: Args) {
  const isMergeTagImprovementsEnabled = useFeatureFlag('mergeTagImprovements');
  const hasDefaultValue = WidgetUtils.hasDefaultValue({ formFieldValue, widget });

  const stringValue = match(formFieldValue)
    .with({ fieldValue: { value: P.not(P.nullish) } }, ffv => String(ffv.fieldValue.value))
    .otherwise(() => '');

  // When we send an empty default form field value to the backend to update,
  // don't sync that back to the widget.
  const isSyncDisabled = hasDefaultValue && !stringValue;
  const enabled = !isSyncDisabled;
  useSyncedValue({ value: stringValue, enabled, send });

  // don't mask value if feature is disabled or if field has been edited by user
  const fieldEdited = formFieldValue?.fieldValue?.hasDefaultValue === false;
  const maskingEnabled = hasDefaultValue && !fieldEdited;
  const maskedValue = isMergeTagImprovementsEnabled
    ? MergeTagStringReplacementUtils.replaceUnknownTagsValues((widget.config.defaultValue as string) ?? '')
    : (widget.config.defaultValue as string) ?? '';

  useMaskedValue({ send, maskedValue, value: stringValue, enabled: maskingEnabled });
}
