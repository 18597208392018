import { isModelRef, Muid } from '@process-street/subgrade/core';
import {
  TaskTemplate,
  TaskTemplateUpdateResponse,
  TaskTemplateUpdateResponseStatus,
  TaskTemplateUpdateResult,
  TaskWithTaskTemplate,
  Widget,
} from '@process-street/subgrade/process';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { EntityMap, LookupMap, ObjectMap } from '@process-street/subgrade/redux/types';
import { FORM_FIELD_VALUE_GET_ALL_BY_CHECKLIST_REVISION } from 'components/form-field-value/store/form-field-value.actions';
import { FormFieldValueGetByChecklistRevisionIdAction } from 'components/form-field-value/store/types';
import {
  WIDGET_COPY,
  WIDGET_CREATE_AT,
  WIDGET_CREATE_FILE,
  WIDGET_GET_ALL_BY_TASK_TEMPLATE_ID,
  WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID,
  WIDGET_SYNC,
  WIDGET_UPDATE,
} from 'components/widgets/store/widget.actions';
import { referencesNormalizer } from 'reducers/entities/reference-normalizer';
import { getStatusReducer } from 'reducers/statuses/statuses-utils';
import {
  TASK_TEMPLATE_UPDATE_ALL_HIDDEN_BY_DEFAULT_BY_TEMPLATE_ID,
  TaskTemplateGetAllByChecklistRevisionIdAction,
  TaskTemplateUpdateAllHiddenByDefaultByTemplateIdAction,
} from 'reducers/task-template/task-template.actions';
import { TASK_GET_ALL_BY_CHECKLIST_REVISION_ID } from 'reducers/task/task.actions';
import { TaskTemplateLookups, TaskTemplateStatuses } from 'reducers/types';
import { toSuccess } from 'reducers/util';
import { composeReducerObjects } from 'reducers/util/compose-reducer-object';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { ReducerObject } from 'reducers/util/types';
import { combineReducers, Reducer } from 'redux';
import { Action, ActionMeta, combineActions } from 'redux-actions';
import {
  TASK_TEMPLATE_CREATE,
  TASK_TEMPLATE_DELETE_ALL,
  TASK_TEMPLATE_DUPLICATE,
  TASK_TEMPLATE_GET_ALL_BY_CHECKLIST_REVISION_ID,
  TASK_TEMPLATE_GET_ALL_BY_TEMPLATE_REVISION_ID,
  TASK_TEMPLATE_GET_FIRST_BY_TEMPLATE_ID,
  TASK_TEMPLATE_GET_PREMADE_BY_TEMPLATE_ID,
  TASK_TEMPLATE_UPDATE,
  TASK_TEMPLATE_UPDATE_ALL_DUE_OFFSET,
  TASK_TEMPLATE_UPDATE_ALL_ORDER_TREES,
  TASK_TEMPLATE_UPDATE_ALL_STOP,
  TASK_TEMPLATE_UPDATE_DUE_OFFSET,
  TaskTemplateDeleteAllAction,
} from './task-template.actions';

// TODO move this to task.actions, when it's in TS
export type TaskGetAllByChecklistRevisionId = ActionMeta<TaskWithTaskTemplate[], { checklistRevisionId: Muid }>;

const normalizeTaskTemplate = referencesNormalizer<TaskTemplate>(['organization', 'templateRevision']);

const taskTemplateWidgetReducer: ReducerObject<EntityMap<TaskTemplate>> = {
  // TODO
  // @ts-expect-error -- TODO
  [combineActions(WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID, WIDGET_GET_ALL_BY_TASK_TEMPLATE_ID)]: (
    state: EntityMap<TaskTemplate>,
    action: Action<Widget[]>,
  ) => {
    const { payload: widgets } = action;

    const taskTemplates: ObjectMap<TaskTemplate> = widgets.reduce((agg: ObjectMap<TaskTemplate>, widget: Widget) => {
      const { taskTemplate } = widget.header;
      if (isModelRef(taskTemplate) && !agg[taskTemplate.id]) {
        agg[taskTemplate.id] = taskTemplate;
      }
      return agg;
    }, {});

    return EntitiesReducerUtils.upsertAll(state, Object.values(taskTemplates), normalizeTaskTemplate);
  },
  // TODO
  // @ts-expect-error -- TODO
  [combineActions(WIDGET_CREATE_AT, WIDGET_COPY, WIDGET_CREATE_FILE, WIDGET_UPDATE, WIDGET_SYNC)]: (
    state: EntityMap<TaskTemplate>,
    action: Action<Widget>,
  ) => {
    const { payload: widget } = action;
    const { taskTemplate } = widget.header;
    if (isModelRef(taskTemplate)) {
      return EntitiesReducerUtils.upsert(state, taskTemplate, normalizeTaskTemplate);
    }
    return state;
  },
  [FORM_FIELD_VALUE_GET_ALL_BY_CHECKLIST_REVISION]: (
    state: EntityMap<TaskTemplate>,
    action: FormFieldValueGetByChecklistRevisionIdAction,
  ) => {
    const taskTemplates: TaskTemplate[] = action.payload
      .map(fieldValue => fieldValue.formFieldWidget)
      .filter(isModelRef)
      .map(widget => widget.header.taskTemplate)
      .filter(isModelRef);
    return EntitiesReducerUtils.upsertAll(state, taskTemplates, normalizeTaskTemplate);
  },
  [TASK_GET_ALL_BY_CHECKLIST_REVISION_ID]: (state: EntityMap<TaskTemplate>, action: TaskGetAllByChecklistRevisionId) =>
    EntitiesReducerUtils.upsertAll(
      state,
      action.payload.map(task => task.taskTemplate),
      normalizeTaskTemplate,
    ),
};

const taskTemplateActionReducer = {
  // TODO
  // @ts-expect-error -- TODO
  [combineActions(
    TASK_TEMPLATE_GET_ALL_BY_TEMPLATE_REVISION_ID,
    TASK_TEMPLATE_GET_ALL_BY_CHECKLIST_REVISION_ID,
    TASK_TEMPLATE_GET_PREMADE_BY_TEMPLATE_ID,
  )]: (state: EntityMap<TaskTemplate>, action: Action<TaskTemplate[]>) =>
    EntitiesReducerUtils.upsertAll(state, action.payload, normalizeTaskTemplate),
  // TODO
  // @ts-expect-error -- TODO
  [combineActions(
    TASK_TEMPLATE_UPDATE_ALL_DUE_OFFSET,
    TASK_TEMPLATE_UPDATE_ALL_STOP,
    TASK_TEMPLATE_UPDATE_ALL_ORDER_TREES,
  )]: (state: EntityMap<TaskTemplate>, action: Action<TaskTemplateUpdateResponse[]>) => {
    const updated = action.payload
      .filter(response => response.response === TaskTemplateUpdateResponseStatus.Ok)
      .map(response => response.taskTemplate)
      .filter((taskTemplate): taskTemplate is TaskTemplate => !!taskTemplate);
    return EntitiesReducerUtils.upsertAll(state, updated, normalizeTaskTemplate);
  },
  // TODO
  // @ts-expect-error -- TODO
  [combineActions(
    TASK_TEMPLATE_CREATE,
    TASK_TEMPLATE_GET_FIRST_BY_TEMPLATE_ID,
    TASK_TEMPLATE_UPDATE_DUE_OFFSET,
    TASK_TEMPLATE_DUPLICATE,
  )]: (state: EntityMap<TaskTemplate>, action: Action<TaskTemplate>) =>
    EntitiesReducerUtils.upsert(state, action.payload, normalizeTaskTemplate),
  [TASK_TEMPLATE_UPDATE]: (state: EntityMap<TaskTemplate>, action: Action<TaskTemplateUpdateResult>) =>
    EntitiesReducerUtils.upsert(state, action.payload.taskTemplate, normalizeTaskTemplate),
  [TASK_TEMPLATE_DELETE_ALL]: (state: EntityMap<TaskTemplate>, action: TaskTemplateDeleteAllAction) =>
    EntitiesReducerUtils.deleteAll(state, action.payload),
  [toSuccess(TASK_TEMPLATE_UPDATE_ALL_HIDDEN_BY_DEFAULT_BY_TEMPLATE_ID)]: (
    state: EntityMap<TaskTemplate>,
    action: TaskTemplateUpdateAllHiddenByDefaultByTemplateIdAction,
  ) => {
    const ttByRevision = Object.values(state).filter(tt => tt.templateRevision.id === action.meta.templateRevisionId);
    const updatedTaskTemplates = ttByRevision.map(tt => {
      const hiddenByDefaultModel = action.meta.hiddenByDefaultModels.find(model => model.taskTemplateId === tt.id);
      return hiddenByDefaultModel ? { ...tt, hiddenByDefault: hiddenByDefaultModel.hiddenByDefault } : tt;
    });

    return EntitiesReducerUtils.upsertAll(state, updatedTaskTemplates);
  },
};

export const taskTemplateEntitiesReducer = composeReducerObjects<EntityMap<TaskTemplate>>(
  taskTemplateActionReducer,
  taskTemplateWidgetReducer,
);

const taskTemplateLookupByTemplateRevisionIdReducer: Reducer<LookupMap> = handleActionsOnSuccess<LookupMap>(
  {
    // @ts-expect-error -- TODO
    [combineActions(
      TASK_TEMPLATE_GET_ALL_BY_TEMPLATE_REVISION_ID,
      TASK_TEMPLATE_GET_ALL_BY_CHECKLIST_REVISION_ID,
      TASK_TEMPLATE_GET_PREMADE_BY_TEMPLATE_ID,
    )]: (state: LookupMap, action: Action<TaskTemplate[]>) =>
      LookupsReducerUtils.replaceAll(state, action.payload, 'templateRevision.id', 'id'),
    [TASK_GET_ALL_BY_CHECKLIST_REVISION_ID]: (state: LookupMap, action: TaskGetAllByChecklistRevisionId) =>
      LookupsReducerUtils.replaceAllUsingSelectorFunctions<TaskTemplate>(
        state,
        action.payload.map(task => task.taskTemplate),
        taskTemplate => taskTemplate.templateRevision.id,
      ),
    // @ts-expect-error -- TODO
    [combineActions(TASK_TEMPLATE_CREATE, TASK_TEMPLATE_DUPLICATE)]: (state: LookupMap, action: Action<TaskTemplate>) =>
      LookupsReducerUtils.upsert(state, action.payload, 'templateRevision.id', 'id'),
    [TASK_TEMPLATE_DELETE_ALL]: (state: LookupMap, action: Action<TaskTemplate[]>) =>
      LookupsReducerUtils.deleteAllUsingSelectorFunctions(
        state,
        action.payload,
        (taskTemplate: TaskTemplate) => taskTemplate.templateRevision.id,
      ),
  },
  {},
);

const taskTemplateLookupByChecklistRevisionIdReducer: Reducer<LookupMap> = handleActionsOnSuccess<LookupMap>(
  {
    [TASK_TEMPLATE_GET_ALL_BY_CHECKLIST_REVISION_ID]: (
      state: LookupMap,
      action: TaskTemplateGetAllByChecklistRevisionIdAction,
    ) =>
      LookupsReducerUtils.replaceAllUsingSelectorFunctions(
        state,
        action.payload,
        () => action.meta.checklistRevisionId,
      ),
    [TASK_GET_ALL_BY_CHECKLIST_REVISION_ID]: (state: LookupMap, action: TaskGetAllByChecklistRevisionId) =>
      LookupsReducerUtils.replaceAllUsingSelectorFunctions(
        state,
        action.payload.map(task => task.taskTemplate),
        () => action.meta.checklistRevisionId,
      ),
  },
  {},
);

export const taskTemplateLookupsReducer: Reducer<TaskTemplateLookups> = combineReducers<TaskTemplateLookups>({
  byChecklistRevisionId: taskTemplateLookupByChecklistRevisionIdReducer,
  byTemplateRevisionId: taskTemplateLookupByTemplateRevisionIdReducer,
});

export const taskTemplateStatusesReducer = combineReducers<TaskTemplateStatuses>({
  byTemplateRevisionId: getStatusReducer(TASK_TEMPLATE_GET_ALL_BY_TEMPLATE_REVISION_ID, 'templateRevisionId'),
});
