import { referencesNormalizer } from 'reducers/entities/entities.utils';
import {
  ORGANIZATION_DOMAIN_CREATE_BY_ORGANIZATION_ID_AND_NAME,
  ORGANIZATION_DOMAIN_GET_ALL_BY_ORGANIZATION_ID,
  ORGANIZATION_DOMAIN_DELETE_BY_ID_AND_NAME,
} from 'reducers/organization-domain/organization-domain.actions';
import { handleActionsOnSuccess } from '../util';

export const normalizeOrganizationDomain = referencesNormalizer(['organization']);

export const organizationDomainEntitiesReducer = handleActionsOnSuccess(
  {
    [ORGANIZATION_DOMAIN_GET_ALL_BY_ORGANIZATION_ID]: (state, { payload: domains, meta: { organizationId } }) => ({
      ...state,
      [organizationId]: domains.map(normalizeOrganizationDomain),
    }),
    [ORGANIZATION_DOMAIN_CREATE_BY_ORGANIZATION_ID_AND_NAME]: (
      state,
      { payload: domain, meta: { organizationId } },
    ) => ({ ...state, [organizationId]: (state[organizationId] || []).concat(normalizeOrganizationDomain(domain)) }),
    [ORGANIZATION_DOMAIN_DELETE_BY_ID_AND_NAME]: (state, { meta: { organizationId, name } }) => {
      if (state[organizationId]) {
        const organizationDomains = state[organizationId].filter(domain => domain.name !== name);
        return { ...state, [organizationId]: organizationDomains };
      }
      return state;
    },
  },
  {},
);
