import { ChecklistError, Muid, Option, ProcessingErrorFactory } from '@process-street/subgrade/core';
import { BaseTaskStatsSelector } from '@process-street/subgrade/redux/selector';
import { BaseReduxState } from '@process-street/subgrade/redux/types';

const validateCompleteByChecklistRevisionId = (
  checklistRevisionId: Option<Muid>,
  state: BaseReduxState,
): Option<ChecklistError> => {
  if (!checklistRevisionId) {
    return undefined;
  }
  const taskStats = BaseTaskStatsSelector.getAllByChecklistRevisionId(checklistRevisionId)(state);
  const taskWithInvalidFields = taskStats.find(stats => stats.invalidFieldsCount > 0);
  if (taskWithInvalidFields) {
    return ProcessingErrorFactory.checklist.fieldsRequired(taskWithInvalidFields.invalidFieldsCount);
  }

  return undefined;
};

const validateUpdateDueDate = (dueDate: number | null): Option<ChecklistError> => {
  if (dueDate && dueDate < Date.now()) {
    return ProcessingErrorFactory.checklist.dueDateInPast();
  }
  return undefined;
};

export const ChecklistUpdateValidator = {
  validateCompleteByChecklistRevisionId,
  validateUpdateDueDate,
};
