import { Muid } from '@process-street/subgrade/core';
import { TaskPermissionRule } from '@process-street/subgrade/permission';
import {
  ChecklistWidget,
  DueDateRuleDefinition,
  Widget,
  WidgetHeader,
  WidgetUpdateOrderTreesRequest,
  WidgetUpdateOrderTreesResponse,
} from '@process-street/subgrade/process';
import { createSuccessAction } from '@process-street/subgrade/redux';
import { ObjectMap } from '@process-street/subgrade/redux/types';
import { TaskAssignmentRule } from '@process-street/subgrade/role-assignment';
import { DynamicDueDatesSelector } from 'components/dynamic-due-dates/store/dynamic-due-dates.selectors';
import { RoleAssignmentRuleSelector } from 'components/role-assignments/store/role-assignment-rules.selector';
import { TaskPermissionRuleSelector } from 'components/task-permission/store/task-permission-rule.selector';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { ReduxAppState } from 'reducers/types';
import { createCachedAction } from 'reducers/util';
import { Action } from 'redux';
import { ActionMeta, createAction } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

export const WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID = 'widget/GET_ALL_BY_TEMPLATE_REVISION_ID';
export const WIDGET_GET_ALL_BY_CHECKLIST_REVISION_ID = 'widget/GET_ALL_BY_CHECKLIST_REVISION_ID';
export const WIDGET_GET_ALL_BY_TASK_TEMPLATE_ID = 'widget/GET_ALL_BY_TASK_TEMPLATE_ID';
export const WIDGET_CREATE_AT = 'widget/CREATE_AT';
export const WIDGET_CREATE_FILE = 'widget/CREATE_FILE';
export const WIDGET_COPY = 'widget/COPY';
export const WIDGET_MOVE = 'widget/MOVE';
export const WIDGET_UPDATE = 'widget/UPDATE';
export const WIDGET_SYNC = 'widget/SYNC';
export const WIDGET_UPDATE_ALL_ORDER_TREES = 'widget/UPDATE_ALL_ORDER_TREES';
export const WIDGET_DELETE_BY_HEADER_ID = 'widget/DELETE_BY_HEADER_ID';
export const WIDGET_UPDATE_ALL_HIDDEN_BY_DEFAULT = 'widget/UPDATE_ALL_HIDDEN_BY_DEFAULT';
export const CHECKLIST_WIDGET_GET_ALL_BY_CHECKLIST_REVISION_ID = 'checklistWidget/GET_ALL_BY_CHECKLIST_REVISION_ID';
export const CHECKLIST_WIDGET_UPDATE_ALL_INTERNAL = 'checklistWidget/UPDATE_ALL_INTERNAL';

export interface WidgetHiddenByDefault {
  hiddenByDefault: boolean;
  widgetHeaderId: Muid;
  widgetId: Muid;
  widgetGroupId: Muid;
}

export type WidgetUpdateAllHiddenByDefault = ActionMeta<
  Record<string, unknown>,
  { templateRevisionId: Muid; hiddenByDefaultModels: WidgetHiddenByDefault[] }
>;

export interface WidgetApi {
  getAllByChecklistRevisionId(checklistRevisionId: Muid): Promise<Widget[]>;

  getAllChecklistWidgetsByChecklistRevisionId(checklistRevisionId: Muid): Promise<ChecklistWidget[]>;

  getAllByTemplateRevisionId(templateRevisionId: Muid): Promise<Widget[]>;

  getAllByTaskTemplateId(taskTemplateId: Muid): Promise<Widget[]>;

  createAt(headerId: Muid, url: string): Promise<Widget>;

  createFile(headerId: Muid, url: string): Promise<Widget>;

  copy(): Promise<Widget>;

  update(): Promise<Widget>;

  updateAllOrderTrees(): Promise<WidgetUpdateOrderTreesResponse[]>;

  deleteByHeaderId(headerId: Muid): Promise<void>;

  updateAllHiddenByDefault(
    templateRevisionId: Muid,
    hiddenByDefaultModels: WidgetHiddenByDefault[],
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;
}

export type WidgetGetAllByTemplateRevisionIdAction = ActionMeta<Widget[], { templateRevisionId: Muid }>;
export type WidgetGetAllByChecklistRevisionIdAction = ActionMeta<Widget[], { checklistRevisionId: Muid }>;
export type WidgetUpdateOrderTreesAction = ActionMeta<WidgetUpdateOrderTreesResponse[], { widgets: ObjectMap<Widget> }>;
export type ChecklistWidgetGetAllByChecklistRevisionIdAction = ActionMeta<
  ChecklistWidget[],
  { checklistRevisionId: Muid }
>;

interface WidgetDeleteByHeaderIdMeta {
  relatedRoleAssignmentRules: TaskAssignmentRule[];
  widget: Widget;
  templateRevisionId: Muid;
  dddRules: DueDateRuleDefinition[];
}

export type WidgetDeleteByHeaderIdAction = ActionMeta<WidgetHeader, WidgetDeleteByHeaderIdMeta>;

type WidgetMoveMeta = {
  sourceTaskTemplateId: Muid;
};

export type WidgetMoveAction = ActionMeta<Widget, WidgetMoveMeta>;

export interface WidgetActions {
  getAllByTemplateRevisionId(templateRevisionId: Muid): Promise<void>;
  getAllByChecklistRevisionId(checklistRevisionId: Muid): Promise<void>;
  getAllChecklistWidgetsByChecklistRevisionId(
    checklistRevisionId: Muid,
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, WidgetDeleteByHeaderIdAction>;
  cacheWidgets(widgets: Widget[]): Action<Widget>;

  sync(widget: Widget): void;
}

export const WidgetActionsImpl = (WidgetsApi: WidgetApi) => {
  'ngInject';

  const getAllByChecklistRevisionId = createCachedAction(
    WIDGET_GET_ALL_BY_CHECKLIST_REVISION_ID,
    WidgetsApi.getAllByChecklistRevisionId,
    (checklistRevisionId: Muid) => ({ checklistRevisionId }),
  );

  const getAllChecklistWidgetsByChecklistRevisionId = createCachedAction(
    CHECKLIST_WIDGET_GET_ALL_BY_CHECKLIST_REVISION_ID,
    WidgetsApi.getAllChecklistWidgetsByChecklistRevisionId,
    (checklistRevisionId: Muid) => ({ checklistRevisionId }),
  );

  /* Save the widgets to redux store */
  const cacheWidgets = (widgets: Widget[]) => ({
    type: WIDGET_GET_ALL_BY_TASK_TEMPLATE_ID,
    payload: widgets,
  });

  const getAllByTemplateRevisionId = createCachedAction(
    WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID,
    WidgetsApi.getAllByTemplateRevisionId,
    (templateRevisionId: Muid, flushCache: boolean) => ({ templateRevisionId, flushCache }),
    (state: ReduxAppState, templateRevisionId: Muid): boolean =>
      WidgetSelector.getLoadedStatusByTemplateRevisionId(templateRevisionId)(state),
    // @ts-expect-error -- TODO
    WidgetSelector.getAllWithTaskTemplateByTemplateRevisionId,
  );

  const getAllByTaskTemplateId = createCachedAction(
    WIDGET_GET_ALL_BY_TASK_TEMPLATE_ID,
    WidgetsApi.getAllByTaskTemplateId,
  );

  const createAt = createCachedAction(WIDGET_CREATE_AT, WidgetsApi.createAt);

  const createFile = createCachedAction(WIDGET_CREATE_FILE, WidgetsApi.createFile);

  const copy = createCachedAction(WIDGET_COPY, WidgetsApi.copy);

  const update = createCachedAction(WIDGET_UPDATE, WidgetsApi.update);

  const updateAllChecklistWidgetsInternal = createAction(
    CHECKLIST_WIDGET_UPDATE_ALL_INTERNAL,
    (checklistWidgets: ChecklistWidget[]) => ({ checklistWidgets }),
  );

  const sync = createAction(WIDGET_SYNC);
  const move = createAction(
    WIDGET_MOVE,
    (widget: Widget, _sourceTaskTemplateId: Muid) => widget,
    (_widget: Widget, sourceTaskTemplateId: Muid) => ({ sourceTaskTemplateId }),
  );

  const doUpdateAllOrderTrees = createCachedAction(
    WIDGET_UPDATE_ALL_ORDER_TREES,
    WidgetsApi.updateAllOrderTrees,
    (_request: WidgetUpdateOrderTreesRequest, widgets: ObjectMap<Widget>) => ({ widgets }),
  );

  const updateAllOrderTrees =
    (request: WidgetUpdateOrderTreesRequest) =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, Action>, getState: () => ReduxAppState) => {
      const state = getState();

      const widgets: ObjectMap<Widget> = request.orderModels.reduce((agg: ObjectMap<Widget>, req) => {
        const widget = WidgetSelector.getByHeaderId(req.widgetHeaderId)(state);
        if (widget) {
          agg[widget.header.id] = widget;
        }
        return agg;
      }, {});

      return dispatch(doUpdateAllOrderTrees(request, widgets));
    };

  const doDeleteById = createCachedAction(
    WIDGET_DELETE_BY_HEADER_ID,
    WidgetsApi.deleteByHeaderId,
    (
      _headerId: Muid,
      widget: Widget,
      templateRevisionId: Muid,
      dddRules: DueDateRuleDefinition[],
      relatedRoleAssignmentRules: TaskAssignmentRule[],
      relatedTaskPermissionRules: TaskPermissionRule[],
    ) => ({
      dddRules,
      relatedRoleAssignmentRules,
      relatedTaskPermissionRules,
      templateRevisionId,
      widget,
    }),
  );

  const deleteByHeaderId =
    (headerId: Muid) =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, Action>, getState: () => ReduxAppState) => {
      const state = getState();

      const widget = WidgetSelector.getByHeaderId(headerId)(state);
      const taskTemplate = widget ? TaskTemplateSelector.getById(widget.header.taskTemplate.id)(state) : undefined;
      const templateRevisionId = taskTemplate ? taskTemplate.templateRevision.id : undefined;
      const dddRules = DynamicDueDatesSelector.getAllByTemplateRevisionIdAndWidget(templateRevisionId, widget)(state);
      const relatedRoleAssignmentRules = widget
        ? RoleAssignmentRuleSelector.getAllBySourceFormFieldWidgetGroupId(
            templateRevisionId,
            widget.header.group.id,
          )(state)
        : [];

      const relatedTaskPermissionRules = widget
        ? TaskPermissionRuleSelector.getAllBySourceFormFieldWidgetGroupId(
            templateRevisionId,
            widget.header.group.id,
          )(state)
        : [];

      return dispatch(
        doDeleteById(
          headerId,
          widget,
          templateRevisionId,
          dddRules,
          relatedRoleAssignmentRules,
          relatedTaskPermissionRules,
        ),
      );
    };

  const updateAllHiddenByDefault = (templateRevisionId: Muid, hiddenByDefaultModels: WidgetHiddenByDefault[]) =>
    createSuccessAction(WIDGET_UPDATE_ALL_HIDDEN_BY_DEFAULT, {}, { templateRevisionId, hiddenByDefaultModels });

  return {
    updateAllChecklistWidgetsInternal,
    copy,
    createAt,
    createFile,
    deleteByHeaderId,
    getAllByChecklistRevisionId,
    getAllChecklistWidgetsByChecklistRevisionId,
    getAllByTaskTemplateId,
    getAllByTemplateRevisionId,
    sync,
    move,
    update,
    updateAllHiddenByDefault,
    updateAllOrderTrees,
    cacheWidgets,
  };
};
