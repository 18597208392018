import angular from 'angular';
import { DateUtils } from '@process-street/subgrade/util';
import templateUrl from './activity.component.html';
import './activity.scss';
import { HtmlServiceUtils } from 'services/html-service.utils';

angular.module('frontStreetApp.directives').directive('psActivity', ($state, SessionService) => ({
  scope: {
    sentence: '=psActivity',
    hideAvatar: '=',
    undeletable: '=',
    onUndelete: '&',
  },
  transclude: true,
  restrict: 'A',
  templateUrl,
  link: function postLink(scope) {
    const currentUser = SessionService.getUser();

    if (scope.sentence.quote) {
      scope.sentence.quote = HtmlServiceUtils.autolinkUrls(scope.sentence.quote);
    }

    scope.redirectObject = function (event, type, id) {
      if (event.target.tagName.toLowerCase() === 'a') {
        $state.go('objectRedirect', { type, id });
      }
    };

    scope.undeleted = false;
    scope.undelete = function () {
      scope.onUndelete({ activity: scope.sentence.activity });
      scope.undeleted = true;
    };

    scope.formatSentenceDate = function (sentence) {
      return DateUtils.formatActivityWithTimezone({
        date: sentence.date,
        timeZone: currentUser?.timeZone,
      });
    };

    scope.username =
      scope.sentence.subject.userType === 'Anonymous' ? 'Guest (Anonymous)' : scope.sentence.subject.value;
  },
}));
