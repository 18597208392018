import * as React from 'react';
import { ButtonProps } from 'components/design/next';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useGetConsolidatedFolderPermissionsQuery } from 'features/permissions/query-builder';
import { useTemplateMenuContext, useTemplateMenuDisclosureContext } from '../template-menu';

import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { ButtonWithUpgradeTooltip } from 'components/button-with-upgrade-tooltip';

export interface DuplicateTemplateButtonProps {
  children: React.ReactElement<ButtonProps>;
}

export const DuplicateTemplateButton: React.FC<DuplicateTemplateButtonProps> = React.memo(({ children }) => {
  const { duplicateTemplateModalDisclosure: disclosure } = useTemplateMenuDisclosureContext();
  const { templateId } = useTemplateMenuContext();

  const { data: template } = useGetTemplateQuery({ templateId });
  const initialFolderId = template?.folder.id ?? '';

  const { data: { permissionMap } = {} } = useGetConsolidatedFolderPermissionsQuery(initialFolderId!, {
    enabled: !!initialFolderId,
  });

  const isFree = useIsCurrentUserFreeMember();

  if (!React.isValidElement(children) || Array.isArray(children)) {
    throw new Error('DuplicateTemplateButton child must be a single clickable element');
  }

  const canCopy = permissionMap?.templateCreate;

  if (isFree) return <ButtonWithUpgradeTooltip>{children}</ButtonWithUpgradeTooltip>;

  // UI
  const btn = React.cloneElement(children, {
    onClick: () => {
      disclosure.onToggle();
    },
  });

  return canCopy ? <>{btn}</> : null;
});
