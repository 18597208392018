import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './LogoSpinner.module.scss';

import psLogo from '../../../images/logo-spinner.gif';

export interface LogoSpinnerProps {
  text?: string;
}

export const defaultText = 'Loading...';
const defaultAlt = 'Loading...';

export const LogoSpinner: React.FunctionComponent<React.PropsWithChildren<LogoSpinnerProps>> = ({
  text = defaultText,
}) => {
  const [showLogo, setShowLogo] = useState(false);

  const logoClasses = classNames({
    [styles.logoSpinnerImage]: true,
    [styles.logoSpinnerImageLoaded]: showLogo,
  });

  useEffect(() => {
    setShowLogo(true);
  }, []);

  return (
    <div className={styles.logoSpinner}>
      <img src={psLogo} alt={defaultAlt} className={logoClasses} />
      <span className={styles.logoSpinnerText}>{text}</span>
    </div>
  );
};
