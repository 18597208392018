import angular from 'angular';
import { groupWithUserSelector } from 'reducers/group/group.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { getTabIndexByName, initializeTabs } from 'services/tab-service';
import { DataServiceConstants } from 'reducers/data-service/data-service-constants';
import activityTemplate from './activity.component.html';
import billingTemplate from './billing.component.html';
import groupsTemplate from './groups.component.html';
import settingsTemplateV2 from './settings/settings.component.html';
import usersTemplate from './users/users.component.html';
import integrationsTemplate from './integrations.component.html';
import notificationsTemplate from './notifications/notifications.component.html';
import './_manage.scss';
import { UserType } from '@process-street/subgrade/core';
import { trace } from 'components/trace';
import { GroupActions } from 'reducers/group/group.actions';
import { PlanLevel } from '@process-street/subgrade/billing';

const INTEGRATIONS_TAB = {
  name: 'integrations',
  heading: 'Integrations',
  templateUrl: integrationsTemplate,
};

const NOTIFICATIONS_TAB = {
  name: 'notifications',
  heading: 'Notifications',
  templateUrl: notificationsTemplate,
};

// keep this as a factory in case we need conditional tabs (e.g., new feature flag)
const getPossibleTabs = () => {
  const POSSIBLE_TABS = [
    {
      name: 'users',
      heading: 'Members&nbsp;&amp;&nbsp;Guests',
      templateUrl: usersTemplate,
    },
    {
      name: 'groups',
      heading: 'Groups',
      templateUrl: groupsTemplate,
    },
    {
      name: 'settings',
      heading: 'Settings',
      templateUrl: settingsTemplateV2,
    },
    {
      name: 'activity',
      heading: 'Activity',
      templateUrl: activityTemplate,
    },
    {
      name: 'billing',
      heading: 'Billing',
      templateUrl: billingTemplate,
    },
  ];

  return POSSIBLE_TABS;
};

class OrganizationManageCtrl {
  constructor(
    $scope,
    $state,
    $timeout,
    $ngRedux,
    DataService,
    PlanActions,
    OrganizationActions,
    OrganizationMembershipActions,
    OrganizationMembershipService,
    SessionService,
    Subject,
    UserService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$timeout = $timeout;
    this.DataService = DataService;
    this.OrganizationMembershipService = OrganizationMembershipService;
    this.Subject = Subject;
    this.UserService = UserService;
    this.SessionService = SessionService;

    const logger = trace({ name: 'OrganizationManageCtrl' });
    logger.info('loading ctrl');

    const mapStateToScope = state => {
      const organizationId = SessionSelector.getSelectedOrganizationId(state);
      const organization = SessionSelector.getSelectedOrganization(state);
      const group = groupWithUserSelector(state, $state.params.id);
      const currentUserId = SessionSelector.getCurrentUserId(state);

      return {
        organizationId,
        organization,
        group,
        currentUserId,
      };
    };

    const mapDispatchToScope = {
      getGroupById: GroupActions.getGroupById,
      getPlan: PlanActions.getById,
      getAllOrgMembershipByOrgId: OrganizationMembershipActions.getAllOrgMembershipByOrgId,
      getOrganizationById: OrganizationActions.getById,
    };

    this.unsubscribe = $ngRedux.connect(mapStateToScope, mapDispatchToScope)(this.$scope);

    this.unsubscribeStateListener = $scope.$on('$stateChangeSuccess', (__event, __toState, toParams) => {
      $scope.activeIndex = getTabIndexByName($scope.tabs, toParams.tab) || 0;
    });
  }

  launch = () => {
    // This is still might be needed for the Billing tab
    const subject = new this.Subject(this.$scope.currentUserId, this.$scope.organizationId);

    const userIsAdmin = subject.isAdmin();
    const userIsGuest = subject.isGuest();

    this.$scope.userIsGuest = subject.isGuest();

    if (userIsAdmin) {
      // create a copy because we dont want to override the constant
      const tabsCopy = getPossibleTabs();
      tabsCopy.splice(3, 0, INTEGRATIONS_TAB);

      if (this.$scope.planLevel === PlanLevel.Enterprise) {
        tabsCopy.splice(3, 0, NOTIFICATIONS_TAB);
      }

      this.$scope.tabs = tabsCopy;
      this.$scope.activeIndex = getTabIndexByName(this.$scope.tabs, this.$state.params.tab) || 0;

      initializeTabs(this.$scope.tabs, this.$state, this.$scope, state => state.includes('organizationManage'));
    } else if (!userIsAdmin && !userIsGuest) {
      this.$scope.tabs = getPossibleTabs().slice(0, 1);
      this.$scope.activeIndex = getTabIndexByName(this.$scope.tabs, this.$state.params.tab) || 0;

      initializeTabs(this.$scope.tabs, this.$state, this.$scope, state => state.includes('organizationManage'));
    } else {
      const query = {
        'organization.id': this.$scope.organizationId,
        'user.userType': UserType.Standard,
        'admin': true,
      };

      const adminMembership = this.DataService.getCollection(DataServiceConstants.ORGANIZATION_MEMBERSHIPS).find(
        query,
        ['user'],
      );

      this.$scope.adminUser = (adminMembership || {}).user;
    }
  };

  $onInit = () => {
    const loadUser = this.$scope.getAllOrgMembershipByOrgId(this.$scope.organizationId);

    const loadOrgAndPlan = this.$scope
      .getOrganizationById(this.$scope.organizationId, { flushCache: true })
      .then(() => {
        const planId = this.$scope.organization.subscription.plan.id;
        return this.$scope.getPlan(planId);
      });

    Promise.all([loadUser, loadOrgAndPlan]).then(([_, orgAndPlan]) => {
      this.$scope.planLevel = orgAndPlan.payload.level;
      this.launch();
    });
  };

  $onDestroy = () => {
    this.unsubscribe();
    this.unsubscribeStateListener();
  };
}

angular.module('frontStreetApp.controllers').controller('OrganizationManageCtrl', OrganizationManageCtrl);
