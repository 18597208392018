import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule/approval-rule-subject.model';
import { Muid, Option } from '@process-street/subgrade/core';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import angular from 'angular';
import { ApprovalRulesActions } from 'components/approval-rules/store/approval-rules.actions';
import { ApprovalRuleSelector } from 'components/approval-rules/store/approval-rules.selectors';
import { FeatureGateService } from 'components/utils/feature-gate.service';
import ngRedux from 'ng-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { TaskTemplateActions } from 'reducers/task-template/task-template.actions';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { ReduxAppState } from 'reducers/types';
import { connectController } from 'reducers/util';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SessionService } from 'services/session-service.interface';
import templateUrl from './view-container.component.html';
import './view-container.component.scss';

interface InternalState {
  approvalRuleSubjects: ApprovalRuleSubject[];
  approvalRuleSubjectsLoaded: boolean;
  subjectTaskTemplates: TaskTemplate[];
}

interface TaskTemplateChanges {
  currentValue: TaskTemplate;
  previousValue: TaskTemplate;
}

interface TemplateRevisionChanges {
  currentValue: TemplateRevision;
  previousValue: TemplateRevision;
}

interface InternalActions {
  getAllTaskTemplatesByTemplateRevisionId(
    templateRevisionId: Muid,
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;
  getAllApprovalRuleSubjectsByTemplateRevisionId(
    templateRevisionId: Muid,
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;
  deleteAllApprovalRuleSubjects(
    templateRevisionId: Muid,
    ruleIds: Muid[],
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;
}

export class ApprovalRulesViewContainerController {
  public state: Option<InternalState>;
  public actions: Option<InternalActions>;

  public templateRevision: Option<TemplateRevision>;
  public taskTemplate: Option<TaskTemplate>;

  static $inject = ['$ngRedux', 'TaskTemplateActions', 'ApprovalRulesActions', 'FeatureGateService', 'SessionService'];

  constructor(
    private $ngRedux: ngRedux.INgRedux,
    private taskTemplateActions: TaskTemplateActions,
    private approvalRulesActions: ApprovalRulesActions,
    private featureGateService: FeatureGateService,
    private sessionService: SessionService,
  ) {
    const mapStateToThis = () => (state: ReduxAppState) => {
      const selectedOrganization = SessionSelector.getSelectedOrganization(state);
      const user = this.sessionService.getUser();
      const planId = selectedOrganization && selectedOrganization.subscription.plan.id;
      const approvalsFeatureIsAvailable =
        user && planId && this.featureGateService.isApprovalsFeatureAvailable(user, planId);

      const taskTemplates = this.templateRevision
        ? TaskTemplateSelector.getAllByTemplateRevisionId(this.templateRevision!.id)(state)
        : [];

      const approvalRuleSubjectsLoaded = this.templateRevision
        ? ApprovalRuleSelector.getLoadedStatusByTemplateRevisionId(this.templateRevision!.id)(state)
        : false;

      const allApprovalRuleSubjects = this.templateRevision
        ? ApprovalRuleSelector.getAllByTemplateRevisionId(this.templateRevision!.id)(state)
        : [];

      const approvalRuleSubjects = allApprovalRuleSubjects.filter(ars => {
        return ars.approvalTaskTemplateGroupId === this.taskTemplate!.group.id;
      });

      const selectedTaskTemplateGroupIds = this.getSubjectTaskTemplateGroupIds(
        approvalRuleSubjects,
        this.taskTemplate!,
      );

      const subjectTaskTemplates = taskTemplates.filter(tt => {
        return selectedTaskTemplateGroupIds.indexOf(tt.group.id) > -1;
      });

      return {
        approvalRuleSubjects,
        approvalRuleSubjectsLoaded,
        approvalsFeatureIsAvailable,
        subjectTaskTemplates,
      };
    };

    const mapDispatchToThis = () => ({
      deleteAllApprovalRuleSubjects: this.approvalRulesActions.deleteAll,
      getAllApprovalRuleSubjectsByTemplateRevisionId: this.approvalRulesActions.getAllByTemplateRevisionId,
      getAllTaskTemplatesByTemplateRevisionId: this.taskTemplateActions.getAllByTemplateRevisionId,
    });

    connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, this.shouldChange)(this);
  }

  public $onChanges(change: { taskTemplate?: TaskTemplateChanges; templateRevision?: TemplateRevisionChanges }) {
    const { taskTemplate, templateRevision } = change;
    if (taskTemplate && taskTemplate.currentValue) {
      this.taskTemplate = taskTemplate.currentValue;
    }
    if (templateRevision && templateRevision.currentValue) {
      this.templateRevision = templateRevision.currentValue;
    }

    if (this.shouldChange(change)) {
      this.actions!.getAllTaskTemplatesByTemplateRevisionId(this.templateRevision!.id);
      this.actions!.getAllApprovalRuleSubjectsByTemplateRevisionId(this.templateRevision!.id);
    }
  }

  public shouldChange(change: { taskTemplate?: TaskTemplateChanges; templateRevision?: TemplateRevisionChanges }) {
    return (
      !!(change.templateRevision && change.templateRevision.currentValue) ||
      !!(change.taskTemplate && change.taskTemplate.currentValue)
    );
  }

  public nonEmpty() {
    return this.state!.subjectTaskTemplates && this.state!.subjectTaskTemplates.length > 0;
  }

  public removeSubjectTaskTemplates(taskTemplate: TaskTemplate) {
    const rule = this.state!.approvalRuleSubjects.find(ars => ars.subjectTaskTemplateGroupId === taskTemplate.group.id);

    if (rule) {
      this.actions!.deleteAllApprovalRuleSubjects(this.templateRevision!.id, [rule.id]);
    }
  }

  private getSubjectTaskTemplateGroupIds(
    approvalRuleSubjects: ApprovalRuleSubject[],
    approvalTaskTemplate: TaskTemplate,
  ) {
    return approvalRuleSubjects
      .filter(s => s.approvalTaskTemplateGroupId === approvalTaskTemplate.group.id)
      .reduce((acc: Muid[], approvalRuleSubject) => {
        acc.push(approvalRuleSubject.subjectTaskTemplateGroupId);
        return acc;
      }, []);
  }
}

export const ApprovalRulesViewContainerComponent: angular.IComponentOptions = {
  bindings: {
    disabled: '<',
    onSubjectTaskSelect: '&',
    onToEditMode: '&',
    taskTemplate: '<',
    templateRevision: '<',
  },
  controller: ApprovalRulesViewContainerController,
  templateUrl,
};
