import get from 'lodash/get';
import { Identifiable, Muid } from '../core';
import { concatInDistinctArray, idSelector, toLookupMap, toReferenceMap } from './reducer-utils';
import { LookupMap, ReferenceMap, SelectorFunction } from './types';

/**
 * Appends references to lookup state using selector functions
 * @param state
 * @param entities
 * @param selectKey
 * @param selectId
 */
function upsertAllUsingSelectorFunctions<T extends Identifiable>(
  state: LookupMap,
  entities: T[],
  selectKey: SelectorFunction<T>,
  selectId: SelectorFunction<T> = idSelector,
): LookupMap {
  const entityRefs = toLookupMap(entities, selectKey, selectId);
  return mergeLookupMap(state, entityRefs);
}

/**
 * Appends references to lookup state using selector functions
 * @param state
 * @param entities
 * @param selectKey
 * @param selectId
 */
function upsertReferencesUsingSelectorFunctions<T extends Identifiable>(
  state: ReferenceMap,
  entities: T[],
  selectKey: SelectorFunction<T>,
  selectId: SelectorFunction<T> = idSelector,
): ReferenceMap {
  const entityRefs = toReferenceMap(entities, selectKey, selectId);
  return mergeReferenceMap(state, entityRefs);
}

/**
 * Replaces references with new ones using selector functions
 * @param state
 * @param entities
 * @param selectKey
 * @param selectId
 */
function replaceAllUsingSelectorFunctions<T extends Identifiable>(
  state: LookupMap,
  entities: T[],
  selectKey: SelectorFunction<T>,
  selectId: SelectorFunction<T> = idSelector,
): LookupMap {
  const entityRefs = toLookupMap(entities, selectKey, selectId);
  return replaceLookupMap(state, entityRefs);
}

/**
 * Upserts references to lookup state using paths to access nested properties
 * @param state
 * @param entities
 * @param keyPath
 * @param idPath
 */
function upsertAll<T extends Identifiable>(
  state: LookupMap,
  entities: T[],
  keyPath: string,
  idPath: string,
): LookupMap {
  const selectKey = (entity: T) => get(entity, keyPath);
  const selectId = (entity: T) => get(entity, idPath);

  return upsertAllUsingSelectorFunctions(state, entities, selectKey, selectId);
}

/**
 * Replaces references with new ones using paths to access nested properties
 * @param state
 * @param entities
 * @param keyPath
 * @param idPath
 */
function replaceAll<T extends Identifiable>(
  state: LookupMap,
  entities: T[],
  keyPath: string,
  idPath: string,
): LookupMap {
  const selectKey = (entity: T) => get(entity, keyPath);
  const selectId = (entity: T) => get(entity, idPath);

  return replaceAllUsingSelectorFunctions(state, entities, selectKey, selectId);
}

function replaceKey<T extends Identifiable>(state: LookupMap, key: Muid, entities: T[]): LookupMap {
  return { ...state, [key]: entities.map(entity => entity.id) };
}

/**
 * Replaces lookup entity root state
 * @param entities
 * @param selectId
 */
function replaceRootUsingSelectorFunctions<T extends Identifiable>(
  entities: T[],
  selectId: SelectorFunction<T> = idSelector,
): Muid[] {
  return entities.reduce((agg: Muid[], entity: T) => {
    const id = selectId(entity);

    if (id && !agg.includes(id)) {
      agg.push(id);
    }

    return agg;
  }, []);
}

/**
 * Upserts reference to lookups state
 * @param state
 * @param entity
 * @param keyPath
 * @param idPath
 */
function upsert<T extends Identifiable>(state: LookupMap, entity: T, keyPath: string, idPath: string): LookupMap {
  const selectKey = (e: T) => get(e, keyPath);
  const selectId = (e: T) => get(e, idPath);

  return upsertUsingSelectorFunctions(state, entity, selectKey, selectId);
}

/**
 * Upserts value to LookupMap by key
 */
function upsertByKeyAndValue(state: LookupMap, key: Muid, value: Muid): LookupMap {
  const keyState = state[key] ?? [];
  if (keyState.indexOf(value) === -1) {
    return {
      ...state,
      [key]: keyState.concat(value),
    };
  }
  return state;
}

/**
 * Upserts reference to lookups state using selector functions
 * @param state
 * @param entity
 * @param selectKey
 * @param selectId
 */
function upsertUsingSelectorFunctions<T extends Identifiable>(
  state: LookupMap,
  entity: T,
  selectKey: SelectorFunction<T>,
  selectId: SelectorFunction<T> = idSelector,
): LookupMap {
  const key = selectKey(entity);
  const value = selectId(entity);
  const agg = { ...state };

  if (key && value) {
    agg[key] = agg[key] ?? [];
    if (agg[key].indexOf(value) === -1) {
      agg[key] = [...agg[key], value]; // creation of new object improves changes detection
    }
  }

  return { ...agg };
}

/**
 * Deletes reference from lookup state using selector functions
 * @param state
 * @param entity
 * @param keyPath
 * @param idPath
 */
function deleteUsingSelectorFunctions<T extends Identifiable>(
  state: LookupMap,
  entity: T,
  selectKey: SelectorFunction<T>,
  selectId: SelectorFunction<T> = idSelector,
): LookupMap {
  const key = selectKey(entity);
  const value = selectId(entity);
  const agg = { ...state };

  if (key && value && agg[key] !== undefined) {
    agg[key] = agg[key].filter(id => id !== value);
  }

  return { ...agg };
}

/**
 * Deletes single reference from lookupMap by key & value
 */
function deleteByKeyAndValue(state: LookupMap, key: Muid, value: Muid): LookupMap {
  if (state[key] === undefined) {
    return state;
  }
  const nextKeyState = state[key].filter(id => id !== value);
  if (nextKeyState.length === state[key].length) {
    return state;
  }

  const nextState = { ...state };
  if (nextKeyState.length === 0) {
    delete nextState[key];
  } else {
    nextState[key] = nextKeyState;
  }

  return nextState;
}

/**
 * Deletes reference from lookup state
 * @param state
 * @param entity
 * @param keyPath
 * @param idPath
 */
function deleteOne<T extends Identifiable>(state: LookupMap, entity: T, keyPath: string, idPath: string): LookupMap {
  const selectKey = (e: T) => get(e, keyPath);
  const selectId = (e: T) => get(e, idPath);

  return deleteUsingSelectorFunctions(state, entity, selectKey, selectId);
}

/**
 * Deletes all references for given entities from lookup state
 * @param state
 * @param entities
 * @param keyPath
 * @param idPath
 */
function deleteAll<T extends Identifiable>(
  state: LookupMap,
  entities: T[],
  keyPath: string,
  idPath: string,
): LookupMap {
  const selectKey = (e: T) => get(e, keyPath);
  const selectId = (e: T) => get(e, idPath);

  return deleteAllUsingSelectorFunctions(state, entities, selectKey, selectId);
}

/**
 * Deletes all references for given entities from lookup state using selector functions
 * @param state
 * @param entities
 * @param keyPath
 * @param idPath
 */
function deleteAllUsingSelectorFunctions<T extends Identifiable>(
  state: LookupMap,
  entities: T[],
  selectKey: SelectorFunction<T>,
  selectId: SelectorFunction<T> = idSelector,
): LookupMap {
  const entityRefs = entities.reduce(
    (agg, entity) => {
      const key = selectKey(entity);
      const value = selectId(entity);

      if (key && value && agg[key] !== undefined) {
        agg[key] = agg[key].filter(id => id !== value);
      }

      return agg;
    },
    { ...state },
  );

  return entityRefs;
}

/**
 * Deletes all references from lookup state by given ids
 * @param state
 * @param key
 * @param ids
 */
function deleteAllByIds(state: LookupMap, key: Muid, ids: Muid[]): LookupMap {
  const agg = { ...state };
  if (agg[key] !== undefined) {
    agg[key] = agg[key].filter(id => !ids.includes(id));
  }
  return agg;
}

/**
 * Remove all keys by id
 * @param {LookupMap} state
 * @param {Muid[]} keys
 * @return {LookupMap}
 */
function deleteAllKeysByIds(state: LookupMap, keys: Muid[]): LookupMap {
  const nextState = { ...state };
  keys.forEach(id => delete nextState[id]);
  return nextState;
}

/**
 * Merges lookup map to lookup state
 * @param state
 * @param lookupMap
 */
function mergeLookupMap(state: LookupMap, lookupMap: LookupMap) {
  return Object.keys(lookupMap).reduce(
    (agg, id) => {
      if (agg[id] !== undefined) {
        agg[id] = concatInDistinctArray(agg[id], lookupMap[id]);
      } else {
        agg[id] = lookupMap[id];
      }
      return agg;
    },
    { ...state },
  );
}

/**
 * Merges reference map to reference state
 * @param state
 * @param referenceMap
 */
function mergeReferenceMap(state: ReferenceMap, referenceMap: ReferenceMap) {
  return Object.keys(referenceMap).reduce(
    (agg, id) => {
      agg[id] = referenceMap[id];
      return agg;
    },
    { ...state },
  );
}

/**
 * Replaces entries of lookup state with those from given lookup map
 * @param state
 * @param lookupMap
 */
function replaceLookupMap(state: LookupMap, lookupMap: LookupMap) {
  return Object.keys(lookupMap).reduce(
    (agg, id) => {
      agg[id] = lookupMap[id];
      return agg;
    },
    { ...state },
  );
}

export const LookupsReducerUtils = {
  delete: deleteOne,
  deleteAll,
  deleteAllByIds,
  deleteAllUsingSelectorFunctions,
  deleteUsingSelectorFunctions,
  deleteAllKeysByIds,
  deleteByKeyAndValue,
  mergeLookupMap,
  replaceAll,
  replaceAllUsingSelectorFunctions,
  replaceKey,
  replaceLookupMap,
  replaceRootUsingSelectorFunctions,
  upsert,
  upsertAll,
  upsertAllUsingSelectorFunctions,
  upsertByKeyAndValue,
  upsertUsingSelectorFunctions,
  upsertReferencesUsingSelectorFunctions,
};
