export const RoleAssignmentRules = ResourceFactory => {
  'ngInject';

  return ResourceFactory.create({
    getAllByTemplateRevisionId: 'GET[] /1/template-revisions/:templateRevisionId/task-assignment-rules',
    create: 'POST /1/template-revisions/:templateRevisionId/task-assignment-rules',
    createAll: 'POST[] /1/template-revisions/:templateRevisionId/task-assignment-rules/all',
    delete: 'DELETE /1/template-revisions/:templateRevisionId/task-assignment-rules/:ruleId',
    deleteAll: 'POST[] /1/template-revisions/:templateRevisionId/task-assignment-rules/delete',
  });
};
