import { ChakraTheme } from '@chakra-ui/react';
import { OrganizationTheme } from '@process-street/subgrade/core';
import { HttpStatus } from '@process-street/subgrade/util';
import { useInjector } from 'components/injection-provider';
import { useGetOrganizationTheme } from 'features/brand-customization/query-builder/get-organization-theme';
import { useGetInvitationQuery } from 'features/invitations/query-builder';
import { useStateParam } from 'hooks/use-state-param';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { getBrandTheme, theme as psTheme } from './theme';

const useInvitationBrandColor = () => {
  const invitationId = useStateParam({ key: 'id', stateName: 'completeSignUp.invitation' });
  const invitationQuery = useGetInvitationQuery({ invitationId }, { staleTime: 1000 * 60 });
  return invitationQuery.data?.theme?.brandColor;
};

export const useBrandTheme = (baseTheme: ChakraTheme = psTheme) => {
  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const organizationId = selectedOrganization?.id;
  const { ElevioService } = useInjector('ElevioService');

  const organizationThemeQuery = useGetOrganizationTheme(
    { organizationId },
    {
      initialData: getPersistedTheme(),
      staleTime: Infinity,
      retry: (failureCount, error) => error.response?.status !== HttpStatus.NOT_FOUND && failureCount < 2,
      onSuccess: theme => {
        persistTheme(theme);
      },
    },
  );

  const invitationBrandColor = useInvitationBrandColor();

  const theme = useMemo(() => {
    if (!organizationThemeQuery.isSuccess && !invitationBrandColor) return baseTheme;

    const brandColor =
      invitationBrandColor ??
      organizationThemeQuery.data?.brandColor ??
      (typeof baseTheme.colors.blue === 'object' ? baseTheme.colors.blue[500] : undefined);

    if (brandColor && typeof brandColor === 'string') {
      const colorHex = brandColor[0] === '#' ? brandColor : `#${brandColor}`;
      try {
        // Set the support widget's color
        ElevioService.setMainColor?.(colorHex);
      } catch (e) {
        console.error('Failed to set elevio main color.', e);
      }

      return getBrandTheme(baseTheme, colorHex);
    }

    return baseTheme;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organizationThemeQuery?.data?.brandColor,
    organizationThemeQuery.isError,
    organizationId,
    ElevioService,
    invitationBrandColor,
  ]);

  return theme;
};

const persistTheme = (theme: OrganizationTheme) => {
  localStorage.setItem(BRAND_THEME_KEY, JSON.stringify(theme));
  sessionStorage.setItem(BRAND_THEME_KEY, JSON.stringify(theme));
};

const getPersistedTheme = () => {
  const theme = sessionStorage.getItem(BRAND_THEME_KEY) ?? localStorage.getItem(BRAND_THEME_KEY);

  if (!theme) return undefined;

  try {
    const parsedTheme = JSON.parse(theme);

    return parsedTheme;
  } catch {
    return undefined;
  }
};

export const deletePersistedTheme = () => {
  localStorage.removeItem(BRAND_THEME_KEY);
  sessionStorage.removeItem(BRAND_THEME_KEY);

  return;
};

export const BRAND_THEME_KEY = 'brandTheme';
