import * as React from 'react';
import { VStack, Link, Button, Text, Input, Box } from 'components/design/next';
import { Icon } from 'components/design/next';

export type ImportDataSetProps = {
  ref: React.RefObject<{ clear: () => void }>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  isLoading?: boolean;
};

export const ImportDataSet = React.forwardRef(({ onChange, isLoading }: ImportDataSetProps, ref) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useImperativeHandle(ref, () => ({
    clear: () => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
  }));

  return (
    <VStack w="full" spacing="4">
      <Box>
        <Button
          isLoading={isLoading}
          loadingText="Uploading file"
          onClick={() => inputRef?.current?.click()}
          iconSpacing="2"
          leftIcon={<Icon size="4" icon="upload" />}
          variant="solid"
          colorScheme="brand"
        >
          Upload file
        </Button>

        <Input
          ref={inputRef}
          onChange={onChange}
          type="file"
          hidden
          name="file"
          w="0"
          h="0"
          visibility="hidden"
          accept=".csv"
          data-testid="file-input"
        />
      </Box>

      <Text textAlign="center" color="gray.600" fontSize="sm">
        The CSV file should be properly formatted.
        <br />
        <Link color="brand.500" href={CSV_EXAMPLE_FILE_URL} target="blank" download>
          Download example CSV file.
        </Link>
      </Text>
    </VStack>
  );
});

const CSV_EXAMPLE_FILE_URL = '/api/1/data-sets/create-from-csv-sample';
