import { Muid, Option, S3File } from '@process-street/subgrade/core';
import {
  Widget,
  CrossLinkWidget,
  EmbedWidget,
  FileWidget,
  ImageWidget,
  TableWidget,
  TextWidget,
  VideoWidget,
  WidgetHeader,
  WidgetType,
} from '@process-street/subgrade/process';
import { isSelectionAtBlockEnd, isSelectionAtBlockStart } from '@udecode/slate-utils';
import isEqual from 'lodash/isEqual';
import { ELEMENT_PS_UNORDERED_LIST_ITEM } from 'pages/pages/_id/edit/page/components';
import {
  ELEMENT_PS_FILE,
  ELEMENT_PS_FRAGMENT,
  ELEMENT_PS_IMAGE,
  ELEMENT_PS_TABLE,
} from 'pages/pages/_id/edit/page/plugins';
import { widgetToSlateElement } from 'pages/pages/_id/edit/page/utils/serialization';
import { Location, Path } from 'slate';
import { HistoryEditor } from 'slate-history';
import { getAboveNode, getNextNode, insertNodes, isElement, select, setNodes } from '@udecode/slate';
import {
  PagesNodeEntry,
  PagesWidgetElement,
  PagesEditor,
  PagesElement,
  PagesListItemElement,
  PagesElementType,
} from '../pages-plate-types';
import { ELEMENT_PS_CROSS_LINK } from '../plugins/ps-cross-link';
import { ELEMENT_PS_EMBED } from '../plugins/ps-embed';
import { PersistentEditor } from '../plugins/ps-persistence/persistent-editor';
import { TableWidgetOperations } from '../plugins/ps-table/ps-table-utils';
import { ELEMENT_PS_VIDEO } from '../plugins/ps-video';

export type WidgetWithS3File = FileWidget | ImageWidget | VideoWidget;

export const PSEditor = {
  getWidgetNodeEntryByHeaderId: (editor: PagesEditor, widgetHeaderId: WidgetHeader['id']): Option<PagesNodeEntry> => {
    let entry: Option<PagesNodeEntry>;
    for (let index = 0; index < editor.children.length; index++) {
      const node = editor.children[index];
      if (PSEditor.isWidgetElementByHeaderId(node, widgetHeaderId)) {
        entry = [node, [index]];
        break;
      }
    }
    return entry;
  },
  getWidgetElement: (editor: PagesEditor, options?: { at?: Location }) => {
    const at = options?.at || editor.selection;

    if (at) {
      return getAboveNode<PagesWidgetElement>(editor, {
        at,
        mode: 'highest',
        match: PSEditor.isWidgetElement,
      });
    } else {
      return undefined;
    }
  },
  setWidgetElement: (editor: PagesEditor, widget: Widget): void => {
    const [, at] = PSEditor.getWidgetNodeEntryByHeaderId(editor, widget.header.id) ?? [];

    PersistentEditor.withoutPersisting(editor, () => {
      setNodes(
        editor,
        { widget },
        {
          match: node => PSEditor.isWidgetElementByHeaderId(node, widget.header.id),
          mode: 'highest',
          at,
        },
      );
    });
  },
  isFullWidgetElement: (element: PagesWidgetElement): boolean => {
    if (PSEditor.isTextWidgetElement(element)) {
      const { children } = widgetToSlateElement(element.widget);
      return (
        children.length === element.children.length &&
        children.every((child, idx) => isEqual(child, element.children[idx]))
      );
    }
    return true;
  },
  isWidgetHeadersMatch: (header1: WidgetHeader, header2: WidgetHeader): boolean => {
    return header1.id === header2.id && header1.orderTree === header2.orderTree;
  },
  isHistoryUndeleteOperation: (editor: PagesEditor, widget: Widget): boolean => {
    return (
      HistoryEditor.isHistoryEditor(editor) &&
      ((editor.history.undos.length > 0 &&
        editor.history.undos
          .slice(-1)[0]
          .operations.some(
            op =>
              op.type === 'remove_node' &&
              PSEditor.isWidgetElement(op.node) &&
              PSEditor.isWidgetHeadersMatch(op.node.widget.header, widget.header),
          )) ||
        (editor.history.redos.length > 0 &&
          editor.history.redos
            .slice(-1)[0]
            .operations.some(
              op =>
                op.type === 'insert_node' &&
                PSEditor.isWidgetElement(op.node) &&
                PSEditor.isWidgetHeadersMatch(op.node.widget.header, widget.header),
            )))
    );
  },
  isWidget: (obj: any): obj is Widget => {
    return obj && 'id' in obj && 'header' in obj;
  },
  isWidgetElement: (node: unknown): node is PagesWidgetElement => {
    return isElement(node) && 'widget' in node;
  },
  isWidgetElementByHeaderId: (node: unknown, headerId: Muid): node is PagesWidgetElement => {
    return PSEditor.isWidgetElement(node) && node.widget.header.id === headerId;
  },
  isTextWidgetElement: (node: unknown): node is PagesWidgetElement<TextWidget> => {
    return PSEditor.isWidgetElement(node) && node.widget.header.type === WidgetType.Text;
  },
  isFileWidgetElement: (node: unknown): node is PagesWidgetElement<FileWidget> => {
    return PSEditor.isWidgetElement(node) && node.widget.header.type === WidgetType.File;
  },
  isImageWidgetElement: (node: unknown): node is PagesWidgetElement<ImageWidget> => {
    return PSEditor.isWidgetElement(node) && node.widget.header.type === WidgetType.Image;
  },
  isVideoWidgetElement: (node: unknown): node is PagesWidgetElement<VideoWidget> => {
    return PSEditor.isWidgetElement(node) && node.widget.header.type === WidgetType.Video;
  },
  isEmbedWidgetElement: (node: unknown): node is PagesWidgetElement<EmbedWidget> => {
    return PSEditor.isWidgetElement(node) && node.widget.header.type === WidgetType.Embed;
  },
  isCrossLinkWidgetElement: (node: unknown): node is PagesWidgetElement<CrossLinkWidget> => {
    return PSEditor.isWidgetElement(node) && node.widget.header.type === WidgetType.CrossLink;
  },
  isTableWidgetElement: (node: unknown): node is PagesWidgetElement<TableWidget> => {
    return PSEditor.isWidgetElement(node) && node.widget.header.type === WidgetType.Table;
  },
  isTypedElement: (node: unknown): node is PagesElement => {
    return isElement(node) && node.type !== ELEMENT_PS_FRAGMENT;
  },
  isListItemElement: (node: unknown): node is PagesListItemElement => {
    return isElement(node) && node.type === ELEMENT_PS_UNORDERED_LIST_ITEM;
  },
  isCrossLinkWidgetType: (type: string) => type === ELEMENT_PS_CROSS_LINK,
  isTableWidgetType: (type: string) => type === ELEMENT_PS_TABLE,
  isMediaElementType: (type: string) => {
    return [ELEMENT_PS_FILE, ELEMENT_PS_IMAGE, ELEMENT_PS_VIDEO, ELEMENT_PS_EMBED].includes(type as PagesElementType);
  },
  setFileToWidgetElement: (
    element: PagesWidgetElement & { widget: WidgetWithS3File },
    file?: S3File,
  ): PagesWidgetElement & { widget: WidgetWithS3File } => {
    return {
      ...element,
      widget: {
        ...element.widget,
        file,
      },
    };
  },
  rootPath: (path: Path) => path.slice(0, 1),
  isRootPath: (path: Path) => path.length === 1,
  insertUnorderedListItemNode: (
    editor: PagesEditor,
    options: Partial<Pick<PagesListItemElement, 'depth'>> & { at?: Location },
  ) =>
    insertNodes(
      editor,
      [{ type: ELEMENT_PS_UNORDERED_LIST_ITEM, depth: options.depth || 1, children: [{ text: '' }] }],
      options,
    ),
  insertTextNode: (editor: PagesEditor, options: { at?: Location } = {}) =>
    insertNodes(editor, [{ type: 'p', children: [{ text: '' }] }], options),
  insertImageNode: (editor: PagesEditor, options: { at?: Location }) =>
    insertNodes(editor, [{ type: ELEMENT_PS_IMAGE, children: [{ text: '' }] }], options),
  insertFileNode: (editor: PagesEditor, options: { at?: Location }) =>
    insertNodes(editor, [{ type: ELEMENT_PS_FILE, children: [{ text: '' }] }], options),
  insertVideoNode: (editor: PagesEditor, options: { at?: Location }) =>
    insertNodes(editor, [{ type: ELEMENT_PS_VIDEO, children: [{ text: '' }] }], options),
  insertEmbedNode: (editor: PagesEditor, options: { at?: Location }) =>
    insertNodes(editor, [{ type: ELEMENT_PS_EMBED, children: [{ text: '' }] }], options),
  insertCrossLinkNode: (editor: PagesEditor, options: { at?: Location }) =>
    insertNodes(editor, [{ type: ELEMENT_PS_CROSS_LINK, children: [{ text: '' }] }], options),
  insertTableNode: (editor: PagesEditor, options: { at?: Location }) =>
    insertNodes(
      editor,
      [
        {
          type: ELEMENT_PS_TABLE,
          children: [TableWidgetOperations.createEmptyTable()],
        },
      ],
      options,
    ),

  /*
   * Sometimes the break happens and the selection is moved to the empty text node after an inline element (e.g., link)
   */
  selectNextEmptyWidget: (editor: PagesEditor) => {
    if (!isSelectionAtBlockStart(editor) && isSelectionAtBlockEnd(editor)) {
      const [, nextPath] = getNextNode(editor, { match: n => PSEditor.isWidgetElement(n) }) ?? [];
      if (nextPath) {
        select(editor, nextPath);
      }
    }
  },
};
