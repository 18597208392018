import * as React from 'react';
import { Box, Button, Flex, Icon, Text, BoxProps, useDisclosure, Spinner } from 'components/design/next';
import { isValidEmail } from '@process-street/subgrade/process';
import { useInviteQuery } from './use-invite-query';
import { OrganizationMembership, User } from '@process-street/subgrade/core';
import { InviteFullMemberPaywall } from 'components/paywalls/invite-full-member/invite-full-member-paywall';
import { useOnPlanTrack } from 'services/use-plan-track';
import { PlanTrack } from '@process-street/subgrade/billing';
import { HttpStatus } from '@process-street/subgrade/util';
import { useRoleNames } from 'hooks/use-role-names';

type InviteProps = {
  email: string;
  onInvite: (email: string) => Promise<void>;
  allMembers: OrganizationMembership[];
} & BoxProps;

type Mode = 'invite' | 'confirm' | 'error' | 'loading';

export const Invite: React.FC<React.PropsWithChildren<InviteProps>> = ({
  allMembers,
  email,
  onInvite,
  ...boxProps
}) => {
  const [mode, setMode] = React.useState<Mode>('invite');
  const { showInviteNote } = useInviteQuery();
  const paywall = useDisclosure();
  const onFreemium = useOnPlanTrack(PlanTrack.Freemium);
  const roleNames = useRoleNames();

  React.useEffect(() => {
    setMode('invite');
  }, [email]);

  const emailIsValid = isValidEmail(email);
  const existingUser =
    emailIsValid && allMembers.find(member => (member.user as User).email.toLowerCase() === email.toLowerCase());

  const invite = () => {
    setMode('loading');
    onInvite(email)
      .then(() => {
        setMode('confirm');
      })
      .catch(response => {
        if (response.status === HttpStatus.PAYMENT_REQUIRED && onFreemium) {
          paywall.onOpen();
        }
        setMode('error');
      });
  };

  return (
    <Box {...boxProps}>
      {mode === 'invite' && existingUser && (
        <Text variant="-2" color="gray.500">
          This user is already assigned.
        </Text>
      )}

      {(mode === 'invite' || mode === 'loading') && !existingUser && (
        <Flex alignItems="center" justifyContent="space-between">
          <Box maxW={60}>
            <Text variant="-1" fontWeight="medium" color="gray.600" noOfLines={1}>
              {email}
            </Text>

            {showInviteNote && (
              <Text variant="-2" color="gray.500">
                This user will be invited as a paid {roleNames.FullMember.single}.
              </Text>
            )}
          </Box>
          {mode === 'loading' ? (
            <Spinner />
          ) : (
            <Button variant="solid" colorScheme="gray" isDisabled={!emailIsValid} onClick={invite} w="auto!important">
              Invite
            </Button>
          )}
        </Flex>
      )}

      {mode === 'confirm' && (
        <Flex>
          <Text variant="-1" color="gray.500">
            <Text as="span" fontWeight="medium" variant="-1" color="gray.500">
              {email}
            </Text>
            &nbsp;will be assigned and invited as a {roleNames.FullMember.single}.
          </Text>
          &nbsp;
          <Icon alignSelf="center" size="6" icon="check" variant="far" color="green.500" />
        </Flex>
      )}

      {mode === 'error' && (
        <Flex>
          <Text variant="-1" color="gray.500">
            There was an error inviting&nbsp;
            <Text as="span" fontWeight="medium" variant="-1" color="gray.500">
              {email}
            </Text>
            .
          </Text>
          &nbsp;
          <Icon alignSelf="center" size="6" icon="xmark" variant="far" color="red.500" />
        </Flex>
      )}

      <InviteFullMemberPaywall isOpen={paywall.isOpen} onClose={paywall.onClose} invitee={email} />
    </Box>
  );
};
