import * as React from 'react';
import { Text, TextProps, Square, SquareProps, HStack, forwardRef, StackProps } from 'components/design/next';

export type BarStackHorizontalLegendItemProps = StackProps;

export const BarStackHorizontalLegendItem = forwardRef<BarStackHorizontalLegendItemProps, 'div'>((props, ref) => {
  return <HStack {...{ ref, ...props }} />;
});

export type BarStackHorizontalLegendItemSquareProps = SquareProps;

export const BarStackHorizontalLegendItemSquare: React.FC<
  React.PropsWithChildren<BarStackHorizontalLegendItemSquareProps>
> = props => {
  return <Square size="4" {...props} />;
};

export type BarStackHorizontalLegendItemTextProps = TextProps;

export const BarStackHorizontalLegendItemText: React.FC<
  React.PropsWithChildren<BarStackHorizontalLegendItemTextProps>
> = props => {
  return <Text variant="-1" color="gray.500" {...props} />;
};
