import * as React from 'react';
import { SkeletonText, Text, Tooltip, TextProps } from 'components/design/next';
import { match, P } from 'ts-pattern';
import { DateUtils, DateFormat } from '@process-street/subgrade/util';
import { useUserByIdQuery } from 'features/user/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { SendRichEmailFormFieldValue } from '@process-street/subgrade/process';

export type SendRichEmailTimestampProps = SendRichEmailFormFieldValue['fieldValue'] & {
  showIfNotSent?: boolean;
} & TextProps;

export const SendRichEmailTimestamp: React.FC<React.PropsWithChildren<SendRichEmailTimestampProps>> = ({
  lastSentByUserId,
  lastSentDate,
  showIfNotSent = true,
  ...props
}) => {
  const timeZone = useSelector(SessionSelector.getCurrentUser)?.timeZone;

  const lastSentByUserQuery = useUserByIdQuery({ id: lastSentByUserId! }, { enabled: Boolean(lastSentByUserId) });

  return match({ ...lastSentByUserQuery, lastSentDate, showIfNotSent })
    .with({ status: 'loading' }, () => <SkeletonText noOfLines={1} />)
    .with({ status: 'success', lastSentDate: P.not(P.nullish) }, ({ data: { username }, lastSentDate }) => (
      <Tooltip label={DateUtils.formatDateTime(lastSentDate, DateFormat.DateMonthDayYearAtTime, timeZone)} hasArrow>
        <Text variant="-1" fontWeight="500" display="inline-block" color="gray.500" noOfLines={1} {...props}>
          Sent by {username} {DateUtils.formatDateFromNow(lastSentDate, timeZone)}
        </Text>
      </Tooltip>
    ))
    .with({ status: 'success' }, ({ data: { username } }) => (
      <Text variant="-1" fontWeight="500" color="gray.500" noOfLines={1}>
        Sent by {username}
      </Text>
    ))
    .with({ showIfNotSent: true }, () => (
      <Text variant="-1" fontWeight="500" color="gray.500" noOfLines={1}>
        (Not sent)
      </Text>
    ))
    .otherwise(() => null);
};
