import { Option } from '@process-street/subgrade/core';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import angular from 'angular';
import templateUrl from './list.component.html';
import './list.component.scss';

interface SubjectTaskTemplatesChanges {
  currentValue: TaskTemplate[];
  previousValue: TaskTemplate[];
}

interface TemplateRevisionChanges {
  currentValue: TemplateRevision;
  previousValue: TemplateRevision;
}

export class ApprovalRuleSubjectTasksListController {
  public templateRevision: Option<TemplateRevision>;
  public subjectTaskTemplates: Option<TaskTemplate[]>;

  public $onChanges(change: {
    subjectTaskTemplates?: SubjectTaskTemplatesChanges;
    templateRevision?: TemplateRevisionChanges;
  }) {
    const { subjectTaskTemplates, templateRevision } = change;
    if (subjectTaskTemplates && subjectTaskTemplates.currentValue) {
      this.subjectTaskTemplates = subjectTaskTemplates.currentValue;
    }
    if (templateRevision && templateRevision.currentValue) {
      this.templateRevision = templateRevision.currentValue;
    }
  }
}

export const ApprovalRuleSubjectTasksListComponent: angular.IComponentOptions = {
  bindings: {
    disabled: '<',
    onRemove: '&',
    onSelect: '&',
    subjectTaskTemplates: '<',
    templateRevision: '<',
  },
  controller: ApprovalRuleSubjectTasksListController,
  templateUrl,
};
