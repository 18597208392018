import * as React from 'react';
import { Button, ButtonProps, useDisclosure } from 'components/design/next';
import { ContactSalesModal } from './contact-sales-modal';
import { useSelectedOrganization } from 'hooks/use-selected-organization';

export const ContactSales: React.FC<React.PropsWithChildren<ButtonProps>> = ({ ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const selectedOrganization = useSelectedOrganization();

  if (!selectedOrganization) return null;

  return (
    <>
      <Button onClick={onOpen} {...props}>
        Contact sales
      </Button>
      {isOpen && <ContactSalesModal onClose={onClose} selectedOrganization={selectedOrganization} utm="app_topbar" />}
    </>
  );
};
