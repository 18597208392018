import * as React from 'react';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { featureFlagsStatusSelector } from 'reducers/statuses/statuses.selectors';
import { Statuses } from 'reducers/types';
import { FeatureFlags } from 'services/features/feature-flags/feature-flag-constants';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { Option } from 'space-monad';

type FeatureFlagKey = keyof FeatureFlags;

function filterFeatureFlags<Key extends FeatureFlagKey>(
  keys: Key[],
  flags: FeatureFlags,
): typeof keys['length'] extends 0 ? FeatureFlags : Pick<FeatureFlags, Key> {
  return keys.length === 0
    ? flags
    : keys.reduce((featuresAcc, key) => {
        featuresAcc[key] = flags[key];
        return featuresAcc;
      }, {} as Pick<FeatureFlags, Key>);
}

export function getFeatureFlagPreview<Key extends FeatureFlagKey>(): Pick<FeatureFlags, Key> {
  const qp = new URLSearchParams(window.location.search);
  const previewFlags = Option(qp.get('ff'));
  const flags = previewFlags.fold<Key[], Key[]>(
    () => [],
    ff => JSON.parse(ff),
  );
  return flags.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {} as Pick<FeatureFlags, Key>);
}

/**
 * Takes N feature name arguments as strings and returns an object just those keys.
 * By passing no arguments, you get all the feature flag keys. For using a single feature you can also use @link `useFeatureFlag`
 *
 * @example
 * ```tsx
 * const { pages } = useFeatureFlags("pages")
 * ```
 *
 * @example
 * ```tsx
 * const { pages, freemium } = useFeatureFlags("pages", "freemium")
 * ```
 *
 * @example
 * ```tsx
 * const featureFlags = useFeatureFlags()
 * ```
 */
export function useFeatureFlags(): FeatureFlags;
export function useFeatureFlags<Key extends FeatureFlagKey>(...features: Key[]): Pick<FeatureFlags, Key>;
export function useFeatureFlags<Key extends FeatureFlagKey>(
  ...features: Key[]
): typeof features['length'] extends 0 ? FeatureFlags : Pick<FeatureFlags, Key> {
  type NarrowedFlags = typeof features['length'] extends 0 ? FeatureFlags : Pick<FeatureFlags, Key>;
  const allFeatureFlags = useSelector(FeatureFlagSelector.getFeatureFlags);

  const [featureFlags, setFeatureFlags] = React.useState<NarrowedFlags>(() =>
    filterFeatureFlags(features, allFeatureFlags),
  );

  useDeepCompareEffect(() => {
    setFeatureFlags(filterFeatureFlags(features, allFeatureFlags));
  }, [features, allFeatureFlags]);

  return featureFlags;
}

/**
 * Convenience function for when you just need one feature flag. Composes {@link useFeatureFlags}
 */
export function useFeatureFlag<Key extends FeatureFlagKey>(key: Key) {
  const { [key]: value } = useFeatureFlags(key);
  return value;
}

export function useFeatureFlagStatus(): Statuses['featureFlags'] {
  return useSelector(featureFlagsStatusSelector);
}

/** Returns true only if feature flags have been fetched from LaunchDarkly. */
export function useIsFeatureFlagInitialized() {
  return useSelector(FeatureFlagSelector.getFeatureFlags).isInitialized;
}
