import * as React from 'react';
import { Box, Center, Divider, Link, ModalBody, Text } from 'components/design/next';
import { Button, ButtonGroup, Icon, ModalCloseButton, ModalFooter } from 'components/design/next';

const GUIDE_URL = 'https://www.process.st/help/docs/getting-started/';
export const InitialSetupStep: React.FC<
  React.PropsWithChildren<{
    onNext: () => void;
  }>
> = ({ onNext }) => {
  return (
    <>
      <ModalCloseButton />
      <Center borderTopRadius="2xl" w="100%" h={55} bgColor="brand.100" mb={10}>
        <Icon size="25" variant="fas" icon="wand-magic-sparkles" color="brand.500" />
      </Center>
      <ModalBody>
        <Box mb={5}>
          <Text align="center" as="h2" variant="3" fontWeight="bold" mb={4}>
            Let’s create your first workflow
          </Text>
          <Text align="center">
            Use Process Street to make your team
            <br /> processes fun, fast and faultless.
            <br />
            You can find more information in our{' '}
            <Link color="gray.500" href={GUIDE_URL}>
              Getting Started guide.
            </Link>
          </Text>
        </Box>
      </ModalBody>
      <Divider />
      <ModalFooter>
        <ButtonGroup>
          <Button variant="primary" type="submit" onClick={onNext}>
            Next
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};
