import { CHECKLIST_UNDELETE, CHECKLIST_UPDATE_STATUS } from 'reducers/checklist/checklist.actions';
import { TEMPLATE_UNDELETE } from 'reducers/template/template.actions';
import { FOLDER_UPDATE } from 'reducers/folder/folder.actions';
import { composeReducerObjects, handleActionsOnSuccess } from 'reducers/util';
import { combineReducers } from 'redux';
import { ChecklistStatus } from '@process-street/subgrade/process';
import { htmlEscaped } from '@process-street/subgrade/util';
import {
  TT_ASSIGNMENT_CREATE,
  TT_ASSIGNMENT_CREATE_ALL,
  TT_ASSIGNMENT_DELETE,
  TT_ASSIGNMENT_DELETE_ALL,
  TT_ASSIGNMENT_GET_ALL_BY_TMPL_REV_ID,
} from 'components/template-task-assignment/store/template-task-assignment.actions';
import { isFailedBulkTemplateTaskAssignmentResponse } from 'components/template-task-assignment/store/template-task-assignment.reducer';
import { HttpStatus } from '@process-street/subgrade/util';
import {
  RA_RULE_CREATE,
  RA_RULE_CREATE_ALL,
  RA_RULE_DELETE,
  RA_RULE_DELETE_ALL,
} from 'components/role-assignments/store/role-assignment-rules.actions';
import {
  TASK_ASSIGNMENT_ASSIGN,
  TASK_ASSIGNMENT_INVITE,
  TASK_ASSIGNMENT_UNASSIGN,
} from 'components/checklist-task-assignment/store/checklist-task-assignment.actions';
import { ChecklistTaskAssignmentConstants } from '@process-street/subgrade/process/checklist-task-assignment-constants';
import {
  DANGER_NOTICE_TYPE,
  NOTICE_CLOSE,
  NOTICE_SHOW,
  CRITICAL_NOTICE_CLOSE,
  CRITICAL_NOTICE_SHOW,
  SUCCESS_NOTICE_TYPE,
  WARNING_NOTICE_TYPE,
} from './flash.actions';
import {
  GROUP_CREATE,
  GROUP_DELETE,
  GROUP_GET_BY_ID,
  GROUP_UPDATE,
  GROUP_UPDATE_AVATAR,
  GROUP_USER_ADD,
  GROUP_USER_REMOVE,
} from '../group/group.actions';
import { ToastServiceImpl } from 'services/toast-service.impl';

const NOTIFIER_INITIAL_STATE = { shown: false };

export const SUCCESS_DEFAULT_DURATION = 3000;
// Danger notice should never disappear
export const DANGER_DEFAULT_DURATION = -1;
export const WARNING_DEFAULT_DURATION = 5000;

/**
 * This is a bridge between our old Redux based toast architecture (aka flash/notifier) and our new Chakra toasts.
 * Our legacy approach launched toasts via actions.  This forwards those actions to the
 * ToastService to launch a Chakra toast.
 */
const forwardEventToToastService = (type, message) => {
  ToastServiceImpl.openToast({
    status: type === DANGER_NOTICE_TYPE ? 'error' : type,
    title: message,
  });
};

const onSuccessOrFailure = (success, failure) => (state, action) =>
  onSuccess(success)(onFailure(failure)(state, action), action);

const onSuccess = success => (state, action) => {
  if (!action.error) {
    const successMessage = typeof success === 'string' ? success : success(state, action);
    forwardEventToToastService(SUCCESS_NOTICE_TYPE, successMessage);
  }
  return state;
};

const onFailure = failure => (state, action) => {
  if (action.error) {
    const failureMessage = typeof failure === 'string' ? failure : failure(state, action);
    forwardEventToToastService(DANGER_NOTICE_TYPE, failureMessage);
  }
  return state;
};

const templateActionsNoticeReducerObject = {
  [TEMPLATE_UNDELETE]: onSuccessOrFailure('Template recovered.', 'Failed to recover template.'),
};

const checklistActionsNoticeReducerObject = {
  [CHECKLIST_UNDELETE]: onSuccessOrFailure('Checklist recovered.', 'Failed to recover checklist.'),
  [CHECKLIST_UPDATE_STATUS]: onSuccessOrFailure(
    (__state, action) => (action.meta.status === ChecklistStatus.Archived ? 'Archived checklist.' : undefined),
    (__state, action) =>
      action.meta.status === ChecklistStatus.Archived
        ? 'We were unable to archived your checklist. Please try again later.'
        : undefined,
  ),
};

const taskAssignmentActionsNoticeReducerObject = {
  [TT_ASSIGNMENT_GET_ALL_BY_TMPL_REV_ID]: onFailure('Failed to get assignments'),
  [TT_ASSIGNMENT_CREATE]: onSuccessOrFailure(
    (__state, { meta: { assigneeLabel } }) => htmlEscaped`Assigned <b>${assigneeLabel}</b> to this task.`,
    (__state, { meta: { assigneeLabel } }) => htmlEscaped`Failed to assign <b>${assigneeLabel}</b> to this task.`,
  ),
  [TT_ASSIGNMENT_CREATE_ALL]: onSuccessOrFailure(
    (__state, { payload: assignmentsResponse, meta: { assigneeLabel } }) => {
      let atLeastOneFailed = false;
      assignmentsResponse.forEach(res => {
        if (isFailedBulkTemplateTaskAssignmentResponse(res.response)) {
          atLeastOneFailed = true;
        }
      });

      if (atLeastOneFailed) {
        return (
          htmlEscaped`Sorry, we couldn\'t assign <b>${assigneeLabel}</b> to some tasks. ` +
          'They have been highlighted.'
        );
      } else {
        return htmlEscaped`Assigned <b>${assigneeLabel}</b> to selected tasks.`;
      }
    },
    (__state, { meta: { assigneeLabel } }) =>
      htmlEscaped`Sorry, we couldn\'t assign <b>${assigneeLabel}</b> to selected tasks. ` +
      'They have been highlighted.',
  ),
  [TT_ASSIGNMENT_DELETE]: onSuccessOrFailure(
    (__state, { meta: { assigneeLabel } }) => htmlEscaped`Unassigned <b>${assigneeLabel}</b> from this task.`,
    (__state, { meta: { assigneeLabel } }) => htmlEscaped`Failed to unassign <b>${assigneeLabel}</b> from this task.`,
  ),
  [TT_ASSIGNMENT_DELETE_ALL]: onSuccessOrFailure(
    (__state, { payload: assignmentsResponse, meta: { assigneeLabel } }) => {
      let atLeastOneFailed = false;
      assignmentsResponse.forEach(res => {
        if (isFailedBulkTemplateTaskAssignmentResponse(res.response)) {
          atLeastOneFailed = true;
        }
      });

      if (atLeastOneFailed) {
        return (
          htmlEscaped`Sorry, we couldn\'t unassign <b>${assigneeLabel}</b> from some tasks. ` +
          'They have been highlighted.'
        );
      } else {
        return htmlEscaped`Unassigned <b>${assigneeLabel}</b> from selected tasks.`;
      }
    },
    (__state, { meta: { assigneeLabel } }) =>
      htmlEscaped`Sorry, we couldn\'t unassign <b>${assigneeLabel}</b> from selected tasks. ` +
      'They have been highlighted.',
  ),
};

const roleAssignmentRulesActionsNoticeReducerObject = {
  [RA_RULE_CREATE]: onSuccessOrFailure('Assignment rule created.', 'Failed to create assignment rule.'),
  [RA_RULE_CREATE_ALL]: onSuccessOrFailure('Assignment rules created.', 'Failed to create assignment rules.'),
  [RA_RULE_DELETE]: onSuccessOrFailure('Assignment rule deleted.', 'Failed to delete assignment rule.'),
  [RA_RULE_DELETE_ALL]: onSuccessOrFailure('Assignment rules deleted.', 'Failed to delete assignment rules.'),
};

const checklistTaskAssignmentActionsNoticeReducerObject = {
  [TASK_ASSIGNMENT_INVITE]: onSuccessOrFailure(
    (__state, { meta: { userLabel } }) => htmlEscaped`Assigned <b>${userLabel}</b> to this task.`,
    (__state, { meta: { userLabel } }) => htmlEscaped`Failed to assign <b>${userLabel}</b> to this task.`,
  ),
  [TASK_ASSIGNMENT_UNASSIGN]: onSuccessOrFailure(
    (__state, { meta: { userLabel } }) => htmlEscaped`Unassigned <b>${userLabel}</b> from this task.`,
    (__state, { meta: { userLabel } }) => htmlEscaped`Failed to unassign <b>${userLabel}</b> from this task.`,
  ),
  [TASK_ASSIGNMENT_ASSIGN]: (__state, { error, meta: { userLabel }, payload: { status, data } }) => {
    if (error) {
      if (status === HttpStatus.CONFLICT) {
        const { CreateResultCode } = ChecklistTaskAssignmentConstants;
        if (data.code === CreateResultCode.DUPLICATE_ASSIGNMENT) {
          forwardEventToToastService(
            WARNING_NOTICE_TYPE,
            htmlEscaped`<b>${userLabel}</b> is already assigned to this task.`,
          );
        } else {
          forwardEventToToastService(
            DANGER_NOTICE_TYPE,
            "We couldn't assign to the task because the workflow run has been updated.<br>" +
              'Please refresh and try again.',
          );
        }
      } else if (status === HttpStatus.BAD_REQUEST) {
        forwardEventToToastService(WARNING_NOTICE_TYPE, 'You can only assign organization members to tasks.');
      } else if (status === HttpStatus.FORBIDDEN) {
        forwardEventToToastService(WARNING_NOTICE_TYPE, "You can't assign that user to this task.");
      } else {
        forwardEventToToastService(DANGER_NOTICE_TYPE, htmlEscaped`Failed to assign <b>${userLabel}</b> to this task.`);
      }
    } else {
      forwardEventToToastService(SUCCESS_NOTICE_TYPE, htmlEscaped`Assigned <b>${userLabel}</b> to this task.`);
    }

    return __state;
  },
};

const groupActionsNoticeReducerObject = {
  [GROUP_CREATE]: onSuccessOrFailure(
    'Group created.',
    (__state, { meta: { name } }) => htmlEscaped`Egad! Failed to create group <b>${name}</b>.`,
  ),
  [GROUP_DELETE]: onSuccessOrFailure('Group deleted.', 'Failed to delete this group.'),
  [GROUP_UPDATE]: onSuccessOrFailure('Properties updated.', 'Failed to update properties.'),
  [GROUP_UPDATE_AVATAR]: onSuccessOrFailure('Group photo has been updated.', 'Failed to update the group photo'),
  [GROUP_USER_ADD]: onSuccessOrFailure(
    (__state, { meta: { email } }) => htmlEscaped`Added <b>${email}</b> to group.`,
    (__state, { meta: { email }, payload: { status } }) => {
      if (status === 400) {
        return 'Guests cannot be added to groups.';
      } else {
        return htmlEscaped`Failed to add <b>${email}</b> to group.`;
      }
    },
  ),
  [GROUP_GET_BY_ID]: onFailure('That group was not found.'),
  [GROUP_USER_REMOVE]: onSuccessOrFailure(
    (
      __state,
      {
        meta: {
          membership: {
            user: { username },
          },
        },
      },
    ) => htmlEscaped`Removed <b>${username}</b> from group.`,
    (
      __state,
      {
        meta: {
          membership: {
            user: { username },
          },
        },
      },
    ) => htmlEscaped`Failed to remove <b>${username}</b> from group.`,
  ),
};

const flashNoticeReducerObject = {
  [NOTICE_SHOW]: (__state, { payload: notice }) => {
    forwardEventToToastService(notice.type, notice.message, notice.duration, notice.button);
    return __state;
  },
  [NOTICE_CLOSE]: () => NOTIFIER_INITIAL_STATE,
};

const flashCriticalNoticeReducer = handleActionsOnSuccess(
  {
    [CRITICAL_NOTICE_SHOW]: (__state, { payload: notice }) => {
      forwardEventToToastService(notice.type, notice.message, notice.duration, notice.button);
      return __state;
    },
    [CRITICAL_NOTICE_CLOSE]: () => NOTIFIER_INITIAL_STATE,
  },
  NOTIFIER_INITIAL_STATE,
);

const folderActionNoticeReducer = {
  [FOLDER_UPDATE]: onSuccess('Properties updated.'),
};

export const flashReducer = combineReducers({
  notice: composeReducerObjects(
    checklistTaskAssignmentActionsNoticeReducerObject,
    folderActionNoticeReducer,
    flashNoticeReducerObject,
    groupActionsNoticeReducerObject,
    taskAssignmentActionsNoticeReducerObject,
    roleAssignmentRulesActionsNoticeReducerObject,
    checklistActionsNoticeReducerObject,
    templateActionsNoticeReducerObject,
  ),
  criticalNotice: flashCriticalNoticeReducer,
});
