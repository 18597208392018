import * as React from 'react';
import { RenderElementProps } from 'slate-react';

export const Break: React.FC<React.PropsWithChildren<RenderElementProps>> = ({ attributes, children }) => {
  return (
    <span {...attributes}>
      <br />
      {/* Slate needs this empty child to be there */}
      {children}
    </span>
  );
};
