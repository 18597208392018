import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Divider, HStack, Icon, Image, ListItem, Text, UnorderedList, VStack } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { useToast } from 'components/design/next';
import { useConnectMicrosoftTeamsMutation } from 'features/microsoft-teams/query-builder';
import { useMicrosoftTeams } from 'features/microsoft-teams/use-microsoft-teams';
import { uuid } from 'services/uuid';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { trace } from 'components/trace';
import { IntercomService } from 'services/interop/intercom-service';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export const MicrosoftTeamsConnect: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { $state } = useInjector('$state');

  const logger = trace({ name: 'MicrosoftTeamsConnect' });
  const toast = useToast();
  const { isEmbeddedInMsTeams, isReady: isTeamsSdkReady, microsoftTeams } = useMicrosoftTeams();

  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const [conversationId, setConversationId] = useState<string>();
  const [verificationCode] = useState(uuid());

  const connectMicrosoftTeamsMutation = useConnectMicrosoftTeamsMutation();

  const isReady = isTeamsSdkReady && selectedOrganization?.id && conversationId;

  useEffect(() => {
    //Disable Intercom
    IntercomService.update({
      hide_default_launcher: true,
    });
    const { teamsConversationId } = $state.params;

    if (teamsConversationId) {
      setConversationId(teamsConversationId);
    } else {
      toast({
        status: 'error',
        title: "We're having problems connecting to Microsoft Teams",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });

      logger.error('Microsoft teams connect launched with invalid parameters.', $state.params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$state]);

  const handleCancel = () => {
    window.close();
  };

  const handleAuthorize = () => {
    if (isReady) {
      connectMicrosoftTeamsMutation
        .mutateAsync({
          teamsConversationId: conversationId!,
          teamsVerificationCode: verificationCode,
        })
        .then(() => {
          microsoftTeams!.authentication.notifySuccess(verificationCode);
        });
    } else {
      toast({
        status: 'error',
        title: "We're having problems connecting to Microsoft Teams",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });

      logger.error("Can't connect to MS Teams.  Either we're not in Teams or the SDK isn't ready.", {
        isEmbeddedInMsTeams,
        isTeamsSdkReady,
      });
    }
  };

  return (
    <VStack w="full" h="100vh" flex="1">
      <VStack w="md" h="100%" justifyContent="center" flex="1" spacing="4">
        <HStack w="full" alignItems="center" justifyContent="center" spacing="8">
          <Image src={require('app/images/ms-teams-icon.svg')} height="12" alt="Microsoft Teams icon" />
          <Icon icon="exchange" variant="far" size="6" color="gray.300" />
          <Image src={require('app/images/ps-icon-blue.svg')} height="12" alt="Process Street icon" />
        </HStack>
        <Text variant="2" lineHeight="30px" fontWeight="medium" px="10" textAlign="center">
          Connect Microsoft Teams to your Process Street account
        </Text>
        <Text variant="-1">This app has been installed and now you need to authorize it.</Text>
        <Divider />
        <Text fontWeight="medium">What will Process Street be able to do?</Text>
        <UnorderedList pl="6">
          <ListItem fontSize="sm" lineHeight="18px">
            Send private notifications to Microsoft Teams from the {selectedOrganization?.name} organization.
          </ListItem>
        </UnorderedList>
        <HStack w="full" alignItems="center" justifyContent="center" spacing="6">
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleAuthorize} isDisabled={!isReady} isLoading={!microsoftTeams} loadingText="Loading">
            Authorize
          </Button>
        </HStack>
      </VStack>
    </VStack>
  );
};
