import * as React from 'react';
import { AspectRatio, Center, Icon, VStack } from 'components/design/next';

export const EmbedWidgetPlaceholder: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <AspectRatio bgColor="brand.100" ratio={16 / 9} borderRadius="8px">
      <Center>
        <VStack>
          <Icon icon="browser" variant="fas" size="160px" color="white" />
          {children}
        </VStack>
      </Center>
    </AspectRatio>
  );
};
