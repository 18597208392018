import { Muid, OrganizationUsageStats } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { axiosService } from 'services/axios-service';

export type CanUseResponse = (OrganizationUsageStats & { canUse: false }) | { canUse: true };

const canUseChecklist = async (checklistId: Muid): Promise<CanUseResponse> => {
  return axiosService
    .getAxios()
    .get(`/1/checklists/${checklistId}/can-use`)
    .then(
      () => ({ canUse: true }),
      (error: AxiosError) => {
        if (error.response && error.response.status === 402) {
          return { ...error.response.data, canUse: false };
        } else {
          return { canUse: true };
        }
      },
    );
};

export const API = {
  canUseChecklist,
} as const;
