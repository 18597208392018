import * as React from 'react';
import {
  Box,
  BoxProps,
  forwardRef,
  Grid,
  GridProps,
  GridItem,
  GridItemProps,
  Text,
  HStack,
  Tag,
  TagLabel,
  Spinner,
  Center,
  FormControlProps,
  FormControl,
  FormLabelProps,
  FormLabel,
  TabProps,
  Tab,
  useToken,
} from 'components/design/next';
import DOMPurify from 'dompurify';
import { usePrintStore } from 'components/react-root/print-store';
import { useFeatureFlag } from 'features/feature-flags';

export const EMAIL_PLACEHOLDER = 'Email, e.g. alfred@wayne-enterprises.com';

export type EmailMetaDataProps = GridProps & {
  ccVisible: boolean;
  bccVisible: boolean;
};

export const EmailMetaData = forwardRef<EmailMetaDataProps, 'div'>(({ ccVisible, bccVisible, ...props }, ref) => {
  const templateAreas = `
        "to toVal"
        ${ccVisible ? '"cc ccVal"' : ''}
        ${bccVisible ? '"bcc bccVal"' : ''}
        "subject subjectVal"
        "body bodyVal"
      `;

  return (
    <Grid ref={ref} templateAreas={templateAreas} templateColumns="var(--ps-sizes-18) 1fr" gridGap="4" {...props} />
  );
});

export type RowProps = { row: 'to' | 'cc' | 'bcc' | 'subject' | 'body' };

export const EmailMetaDataRowKey = forwardRef<GridItemProps & RowProps, 'div'>(({ children, row, ...props }, ref) => {
  return (
    <GridItem ref={ref} {...props} area={row} justifySelf="end">
      <Text as="span" fontWeight="medium" color="gray.600">
        {children}
      </Text>
    </GridItem>
  );
});

export const EmailMetaDataRowValue = forwardRef<GridItemProps & RowProps, 'div'>(({ row, ...props }, ref) => {
  return <GridItem ref={ref} area={`${row}Val`} display="flex" alignItems="center" {...props} />;
});

export const EmailMetaDataTags: React.FC<React.PropsWithChildren<{ children?: string[] }>> = ({ children }) => {
  return (
    <HStack>
      {children?.map(child => (
        <Tag key={child}>
          <TagLabel>{child}</TagLabel>
        </Tag>
      ))}
    </HStack>
  );
};

export const sanitizeEmailBody = (html: string) => {
  return DOMPurify.sanitize(html, { WHOLE_DOCUMENT: true });
};

export const sanitizeEmailSubject = (html: string) => {
  return DOMPurify.sanitize(html);
};

export const sanitizeEmailRecipientsField = (html: string) => {
  return DOMPurify.sanitize(html);
};

export const EmailBodyIframe = forwardRef<BoxProps, 'iframe'>((props, ref) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [brand100] = useToken('colors', ['brand.100']);
  const [fontSm] = useToken('fontSizes', ['sm']);
  const { isPrintPage } = usePrintStore();
  const isPdfEmbellishmentEnabled = useFeatureFlag('pdfEmbellishment');

  return (
    <>
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : null}
      <Box
        {...{
          ref,
          visibility: isLoading ? 'hidden' : 'visible',
          title: 'Email Body',
          as: 'iframe',
          w: 'full',
          h: 0,
          maxH: 'lg',
          border: 'none',
          sandbox: 'allow-same-origin',
          onLoad: e => {
            const iframe = e.target as HTMLIFrameElement;
            const root = iframe.contentDocument?.querySelector(':root') as HTMLElement;
            if (iframe.contentWindow) {
              if (isPrintPage && isPdfEmbellishmentEnabled) {
                // Print view is much narrower than the regular view, therefore the size needs adjustment that cannot be styled outside
                iframe.style.height = `${iframe.contentWindow.document.documentElement.offsetHeight + 72}px`;
                root.style.fontSize = fontSm;
              } else {
                iframe.style.height = `${iframe.contentWindow.document.documentElement.offsetHeight}px`;
              }

              // Set the --ps-colors-brand-100 CSS variable within the iframe of the email body.
              // This adjustment resolves the background styling for the specified variable.
              root?.style?.setProperty('--ps-colors-brand-100', brand100);

              if (iframe.contentDocument) {
                iframe.contentDocument.body.addEventListener('click', e => {
                  const target = e.target as HTMLElement;

                  const linkElement = (
                    target.nodeName === 'A' ? target : target.closest('a')
                  ) as HTMLLinkElement | null;

                  if (!linkElement) return;

                  e.preventDefault();
                  e.stopPropagation();

                  const linkTarget = linkElement.getAttribute('target');
                  if (linkTarget === '_blank') {
                    window.open(linkElement.href, linkTarget === '_blank' ? linkTarget : undefined);
                  } else {
                    window.location.href = linkElement.href;
                  }
                });
              }
            }
            setIsLoading(false);
          },
          ...props,
        }}
      />
    </>
  );
});

export const Field = forwardRef<FormControlProps, 'div'>((props, ref) => {
  return <FormControl {...{ ref, display: 'flex', alignItems: 'center', ...props }} />;
});

export const Label = forwardRef<FormLabelProps, 'div'>((props, ref) => {
  return <FormLabel {...{ ref, mb: 0, textAlign: 'right', minW: 16, ...props }} />;
});

export const BodyEditorTab = forwardRef<TabProps, 'div'>((props, ref) => {
  return (
    <Tab
      {...{
        ref,
        color: 'gray.500',
        _selected: { bg: 'white', color: 'gray.700', borderColor: 'gray.300', borderBottomColor: 'white' },
        ...props,
      }}
    >
      <HStack spacing="2">{props.children}</HStack>
    </Tab>
  );
});
