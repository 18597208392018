import angular from 'angular';
import { ChecklistTaskAssignments } from './api/checklist-task-assignments.resource';
import { ChecklistTaskAssignmentApi } from './api/checklist-task-assignment.api';
import { ChecklistTaskAssignmentActions } from './store/checklist-task-assignment.actions';
import { ChecklistTaskAssignmentsComponent } from './assignments/checklist-task-assignments.component';
import { AddChecklistTaskAssignmentComponent } from './add-assignment/add-checklist-task-assignment.component';
import { ChecklistTaskAssignerComponent } from './assigner/checklist-task-assigner.component';
import { ChecklistTaskAssignerMyWorkComponent } from './assigner/checklist-task-assigner-my-work.component';

export const ChecklistTaskAssignmentModule = angular
  .module('checklistTaskAssignerModule', [])
  .factory('ChecklistTaskAssignments', ChecklistTaskAssignments)
  .service('ChecklistTaskAssignmentApi', ChecklistTaskAssignmentApi)
  .factory('ChecklistTaskAssignmentActions', ChecklistTaskAssignmentActions)
  .component('psChecklistTaskAssignments', ChecklistTaskAssignmentsComponent)
  .component('psAddChecklistTaskAssignment', AddChecklistTaskAssignmentComponent)
  .component('psChecklistTaskAssigner', ChecklistTaskAssignerComponent)
  .component('psChecklistTaskAssignerMyWork', ChecklistTaskAssignerMyWorkComponent).name;
