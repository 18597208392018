import React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';
import { CustomNotificationUtils } from './custom-notification-utils';

type Context = {
  config: Partial<CustomNotificationUtils.Config>;
};

export const [useCustomNotificationConfigContext, CustomNotificationConfigContext] = createUsableContext<Context>({
  hookName: 'useCustomNotificationConfigContext',
  providerName: 'CustomNotificationConfigProvider',
});

export const CustomNotificationConfigProvider: React.FC<React.PropsWithChildren<Context>> = ({ config, children }) => {
  const value = React.useMemo<Context>(() => ({ config }), [config]);
  return <CustomNotificationConfigContext.Provider value={value}>{children}</CustomNotificationConfigContext.Provider>;
};
