import { Button, Grid, GridItem, Icon, Text, Tooltip, useToast } from 'components/design/next';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useGetOrganizationTheme } from 'features/brand-customization/query-builder/get-organization-theme';
import { SendTestEmailBrandMutation } from 'features/organization/query-builder';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SUCCESS_DEFAULT_DURATION } from 'reducers/flash/flash.reducer';
import { SessionSelector } from 'reducers/session/session.selectors';

export const SendTestEmail: React.FC<React.PropsWithChildren<unknown>> = () => {
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const currentUserInfo = useSelector(SessionSelector.getCurrentUser);
  const currentUserEmail = currentUserInfo?.email ?? '';

  const toast = useToast();

  const organizationThemeQuery = useGetOrganizationTheme({ organizationId });

  const sendTestEmailMutation = SendTestEmailBrandMutation.useMutation({
    onSuccess: () => {
      toast({
        title: (
          <Text fontWeight="normal">
            Email sent to to{' '}
            <Text as="span" fontWeight="bold">
              {currentUserEmail}
            </Text>
            .
          </Text>
        ),
        status: 'success',
        duration: SUCCESS_DEFAULT_DURATION,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems sending the email",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const handleClick = () => sendTestEmailMutation.mutate({ organizationId });

  // data could be null because theme is not create on org creation.
  const isDisabled =
    !organizationThemeQuery?.data ||
    (organizationThemeQuery.data &&
      !organizationThemeQuery.data.emailPrimaryColor &&
      !organizationThemeQuery.data.emailBackgroundColor &&
      !organizationThemeQuery.data.emailHeaderLogoFileUrl);

  return (
    <Grid w="full" mt="8" rowGap="6" templateColumns={{ base: '1fr', md: '220px 1fr' }}>
      <GridItem></GridItem>
      <GridItem>
        <Tooltip
          isDisabled={!isDisabled}
          w="100%"
          label={`You need to set up the email branding.`}
          hasArrow
          shouldWrapChildren
        >
          <Tooltip shouldWrapChildren hasArrow label={`Test email will be sent to ${currentUserEmail}`}>
            <Button
              isDisabled={isDisabled}
              variant="tertiary"
              leftIcon={<Icon icon="paper-plane" size="4" variant="far" />}
              onClick={handleClick}
            >
              Send Test
            </Button>
          </Tooltip>
        </Tooltip>
      </GridItem>
    </Grid>
  );
};
