import * as Mutations from 'features/widgets/query-builder';
import * as React from 'react';
import { withOverrides } from 'pages/pages/_id/edit/page/plugins/ps-persistence/ps-persistence';
import { trace } from 'components/trace';
import { createPagesPluginFactory } from '../../pages-plate-types';

const logger = trace({ name: 'useWithPSPersistence' });

export const useCreatePSPersistence = () => {
  const { mutateAsync: create } = Mutations.useCreateWidgetMutation();
  const { mutateAsync: copy } = Mutations.useCopyWidgetMutation();
  const { mutateAsync: update } = Mutations.useUpdateWidgetMutation();
  const { mutateAsync: deleteWidget } = Mutations.useDeleteWidgetMutation();
  const { mutateAsync: undelete } = Mutations.useUndeleteWidgetMutation();
  const { mutateAsync: updateOrderTrees } = Mutations.useUpdateWidgetOrderTreesMutation();

  return React.useMemo(() => {
    const props = { create, copy, update, deleteWidget, undelete, updateOrderTrees, logger };

    return createPagesPluginFactory({
      key: 'PSPersistence',
      withOverrides: withOverrides(props, logger),
    });
  }, [create, copy, deleteWidget, undelete, update, updateOrderTrees]);
};
