import * as React from 'react';
import {
  Text,
  TextProps,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
  PopoverContent,
  Link,
} from 'components/design/next';

export const ChartBoxTitleInfo: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Popover trigger="hover" placement="right" variant="tooltip-dark">
      <PopoverTrigger>
        <span>
          <Icon icon="info-circle" size="4" variant="far" color="gray.600" />
        </span>
      </PopoverTrigger>

      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Text variant="-1">{children}</Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const LearnMore: React.FC<React.PropsWithChildren<TextProps>> = props => {
  return (
    <Text as="span" variant="inherit" {...props}>
      Learn more{' '}
      <Link isExternal href="https://www.process.st/help/docs/analytics/">
        here
      </Link>
      .
    </Text>
  );
};
