import { ELEMENT_PS_IMAGE } from 'pages/pages/_id/edit/page/plugins/ps-image';
import { ELEMENT_PS_FILE } from 'pages/pages/_id/edit/page/plugins/ps-file';
import { ELEMENT_PS_VIDEO } from 'pages/pages/_id/edit/page/plugins/ps-video';
import { ELEMENT_PS_SOFT_BREAK } from 'pages/pages/_id/edit/page/plugins/ps-soft-break';
import { ELEMENT_PS_CROSS_LINK } from './ps-cross-link';
import { ELEMENT_PS_EMBED } from './ps-embed';
import { createPagesPluginFactory, PagesElement } from '../pages-plate-types';

const VoidElements: PagesElement['type'][] = [
  ELEMENT_PS_SOFT_BREAK,
  ELEMENT_PS_IMAGE,
  ELEMENT_PS_FILE,
  ELEMENT_PS_VIDEO,
  ELEMENT_PS_CROSS_LINK,
  ELEMENT_PS_EMBED,
];

export const createPSVoid = createPagesPluginFactory({
  key: 'PSVoid',
  withOverrides: editor => {
    const { isVoid } = editor;

    return Object.assign(editor, {
      isVoid: (element: PagesElement) => {
        return VoidElements.includes(element.type) ? true : isVoid(element);
      },
    });
  },
});
