import * as React from 'react';

export function useLegendHeight<Legend extends HTMLElement>() {
  const [legendHeight, setLegendHeight] = React.useState(0);
  const ref = React.useRef<Legend | null>(null);
  const legendRef = React.useCallback((node: Legend | null) => {
    setLegendHeight(node?.clientHeight ?? 0);
    ref.current = node;
  }, []);

  React.useEffect(() => {
    const handler = () => {
      ref.current && setLegendHeight(ref.current.clientHeight);
    };

    // invoke on mount
    handler();

    // listen for changes
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return React.useMemo(() => {
    return {
      legendHeight,
      legendRef,
      setLegendHeight,
    };
  }, [legendHeight, legendRef]);
}
