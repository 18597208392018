import { Muid } from '@process-street/subgrade/core';
import { AngularDependenciesContext } from 'components/common/context/angular-dependencies-context';
import { Spinner } from 'components/common/spinner/Spinner';
import { OverdueTaskDto } from 'components/dashboard/models/grid';
import { ChecklistDashboardSelectors } from 'components/dashboard/store/checklist-dashboard.selectors';
import { BlvdButton, ButtonStyle } from 'components/design/BlvdButton';
import React, { useContext, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { PropertyMap, ReduxAppState } from 'reducers/types';
import { createSelector } from 'reselect';
import { OverdueTaskItem } from './OverdueTaskItem';
import styles from './OverdueTasksModal.module.scss';

export interface OverdueTasksModalProps extends React.PropsWithChildren<PropsFromState> {
  checklistId: Muid;
  overdueTasks: PropertyMap<OverdueTaskDto[]>;
  overdueTasksLoaded: PropertyMap<boolean>;
  totalCount: number;
}

const overdueLimit = 3;

export const PureOverdueTasksModal: React.FunctionComponent<OverdueTasksModalProps> = ({
  checklistId,
  overdueTasks,
  overdueTasksLoaded,
  totalCount,
}) => {
  const dispatch = useDispatch();
  const context = useContext(AngularDependenciesContext);
  const {
    actions: { checklistDashboardActions },
  } = context;

  // load tasks
  useEffect(() => {
    const loaded = overdueTasksLoaded[checklistId] || false;
    if (!loaded) {
      dispatch(checklistDashboardActions.getOverdueTasksByChecklistId(checklistId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- checklist ID change
  }, [checklistId]);

  const leftCount = Math.max(totalCount - overdueLimit, 0);
  const leftText = totalCount > 99 ? '99+' : `${leftCount}`;
  const checklistLink = `/checklists/${checklistId}`;
  const loadedForChecklist = overdueTasksLoaded[checklistId] || false;
  const overdueTasksForChecklist = overdueTasks[checklistId] || [];
  return (
    <div>
      <div className={styles.title}>Overdue tasks</div>
      <div className={styles.content}>
        {!loadedForChecklist && <Spinner />}

        {overdueTasksForChecklist.map(task => (
          <OverdueTaskItem key={task.taskId} overdueTask={task} />
        ))}

        {leftCount > 0 && <p className={styles.leftCount}>Plus {leftText} more...</p>}
      </div>
      <div className={styles.footer}>
        <a href={checklistLink}>
          <BlvdButton style={ButtonStyle.Outline} className={styles.button}>
            Open Workflow Run
          </BlvdButton>
        </a>
      </div>
    </div>
  );
};

interface PropsFromState {
  overdueTasks: PropertyMap<OverdueTaskDto[]>;
  overdueTasksLoaded: PropertyMap<boolean>;
}

const mapStateToProps = createSelector(
  [ChecklistDashboardSelectors.getOverdueTasks, ChecklistDashboardSelectors.getOverdueTasksLoaded],
  (overdueTasks, overdueTasksLoaded) => ({
    overdueTasks,
    overdueTasksLoaded,
  }),
);

export const OverdueTasksModal = connect<
  PropsFromState,
  Record<string, unknown>,
  Record<string, unknown>,
  ReduxAppState
>(mapStateToProps)(PureOverdueTasksModal);
