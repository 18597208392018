import * as React from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from 'components/design/next';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { Muid, Option } from '@process-street/subgrade/core';
import { useSelector } from 'react-redux';
import { useSelector as useXStateSelector } from '@xstate/react';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { TaskConstants, TaskTemplate, Widget } from '@process-street/subgrade/process';
import { ActionMeta, OnChangeValue } from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { getWidgetModalsMachineService, Selectors } from 'features/widgets/widget-modals-machine';

export interface MoveWidgetModalProps {
  templateRevisionId: Muid;
  currentTaskTemplateId: Muid;
  onMove: (widget: Widget, targetTaskTemplate: TaskTemplate) => void;
}

type TaskTemplateOption = {
  value: Muid;
  label: string;
  taskTemplate: TaskTemplate;
};

const actor = getWidgetModalsMachineService();

export const MoveWidgetModal: React.FC<React.PropsWithChildren<MoveWidgetModalProps>> = ({
  templateRevisionId,
  currentTaskTemplateId,
  onMove,
}) => {
  const onClose = () => {
    actor.send({ type: 'CLOSE_MOVE' });
  };
  const widget = useXStateSelector(actor, Selectors.getWidget);
  const isOpen = useXStateSelector(actor, Selectors.getIsOpen('move'));

  const [taskTemplate, selectTaskTemplate] = React.useState<Option<TaskTemplate>>();

  const taskTemplates = useSelector(TaskTemplateSelector.getAllByTemplateRevisionId(templateRevisionId))
    .map((taskTemplate, index) => ({ taskTemplate, index }))
    .filter(({ taskTemplate }) => taskTemplate.id !== currentTaskTemplateId);

  const handleMove = () => {
    if (taskTemplate && widget) {
      onMove(widget, taskTemplate);
      onClose();
    }
  };

  const options: TaskTemplateOption[] = taskTemplates.map(({ taskTemplate, index }) => ({
    value: taskTemplate.id,
    label: `[${index + 1}] ${taskTemplate.name ?? TaskConstants.DefaultTaskName}`,
    taskTemplate,
  }));

  const handleTaskTemplateSelect = (
    value: OnChangeValue<TaskTemplateOption, false>,
    actionMeta: ActionMeta<TaskTemplateOption>,
  ) => {
    if (actionMeta.action === 'select-option') {
      if (BlvdSelectHelpers.isOptionType<TaskTemplateOption>(value)) {
        selectTaskTemplate(value.taskTemplate);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader p={8}>
          <Text variant="2">Move</Text>
        </ModalHeader>
        <ModalBody px={9} py={2}>
          <VStack alignItems="stretch">
            <Text color="gray.600">Data, conditional logic and variables will move along with the field.</Text>
            <Text variant="1" fontWeight="medium" color="gray.700" paddingTop={4}>
              Move to
            </Text>
            <BlvdSelect
              onChange={handleTaskTemplateSelect}
              options={options}
              isSearchable={true}
              placeholder="Select task"
            />
          </VStack>
        </ModalBody>
        <ModalFooter p={6}>
          <ButtonGroup>
            <Button aria-label="cancel" variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button aria-label="move widget" isDisabled={!taskTemplate} onClick={handleMove}>
              Move
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
