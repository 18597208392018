import * as React from 'react';
import {
  Box,
  BoxProps,
  Icon,
  IconButton,
  MotionWrapper,
  useBreakpointValue,
  useDisclosure,
} from 'app/components/design/next';
import { TaskList } from '../task-list';
import { AnimatePresence } from 'framer-motion';

const SLIDE_DURATION_SECOND = 0.5;

export const TaskListSection = React.memo((props: BoxProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false }) ?? true;
  const disclosure = useDisclosure({ defaultIsOpen: isDesktop });

  React.useEffect(
    function toggleDisclosureBasedOnScreenSize() {
      if (isDesktop) {
        disclosure.onOpen();
      } else {
        disclosure.onClose();
      }
    },
    // We don't want this effect to run when the disclosure changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDesktop],
  );

  return (
    <>
      {!disclosure.isOpen && (
        // list opener is outside the container so that it doesn't take space up in there
        <IconButton
          variant="tertiary"
          borderRightRadius={0}
          icon={<Icon icon="table-list" variant="far" size="4" />}
          aria-label="open task list menu"
          position="fixed"
          mt={1}
          left={-1}
          onClick={disclosure.onOpen}
          zIndex={1}
        />
      )}

      <Box
        as="aside"
        overflowY="auto" // scroll separately from the main content
        position={isDesktop ? 'relative' : 'fixed'}
        w="full"
        borderLeftWidth="thin"
        borderLeftColor="gray.100"
        borderLeftStyle="solid"
        bgColor={isDesktop ? 'rgba(255, 255, 255, 0.84)' : 'white'}
        transform={disclosure.isOpen ? 'translateX(0)' : 'translateX(-100vw)'}
        transition={`transform ${SLIDE_DURATION_SECOND}s ease-in-out`}
        zIndex={isDesktop ? undefined : 'sticky'}
        boxShadow="0px 1px 2px 0px #0000000D"
        {...props}
      >
        <IconButton
          aria-label="Toggle sidebar visibility"
          icon={<Icon icon="times" size="4" color="gray.600" />}
          h={9}
          w={9}
          variant="ghost"
          onClick={disclosure.onClose}
          color="gray.600"
          position="absolute"
          top={2}
          right={3}
          _hover={{ bgColor: 'gray.200' }}
          borderRadius="md"
          display={isDesktop ? 'none' : 'block'}
          zIndex={3}
        />
        <AnimatePresence>
          {disclosure.isOpen && (
            <MotionWrapper animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <TaskList onSelect={() => !isDesktop && disclosure.onClose()} />
            </MotionWrapper>
          )}
        </AnimatePresence>
      </Box>
    </>
  );
});
