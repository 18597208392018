import { Muid, Option } from '../core';
import { EntityMap } from './types';

/**
 * Extracts list of entities by their ids from the entity map,
 * with extension function that populates additional data.
 *
 * @param entityMap
 * @param ids
 * @param enhancer - function that adds missing parts to the entity, or returns undefined if it can't.
 * @return object[]
 */
export function safeEntityMapToArrayByIdsWith<T>(
  entityMap: EntityMap<T>,
  ids: Muid[],
  enhancer: (entity: T) => Option<T> = entity => entity,
): T[] {
  return ids.reduce((agg: T[], id) => {
    const entity = entityMap[id] !== undefined ? enhancer(entityMap[id]) : undefined;
    if (entity) {
      agg.push(entity);
    }
    return agg;
  }, []);
}
