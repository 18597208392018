import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UploadUrlCoverIconMutationParams = {
  templateId: Template['id'];
  fileName: string;
  mimeType: string;
};

export type UploadUrlCoverIconMutationResponse = { url: string; key: string };

export const UploadUrlCoverIconMutation = {
  key: ['icon', 'presigned-url'],
  mutationFn: (params: UploadUrlCoverIconMutationParams) =>
    axiosService
      .getAxios()
      .post(`/1/templates/${params.templateId}/cover-icon/upload-url`, params)
      .then(res => res.data),
};

export const useUploadUrlCoverIconMutation = (
  options: UseMutationOptions<UploadUrlCoverIconMutationResponse, AxiosError, UploadUrlCoverIconMutationParams> = {},
) => {
  return useMutation(UploadUrlCoverIconMutation.mutationFn, options);
};
