import { TemplateRevision } from '@process-street/subgrade/process';
import _isEqual from 'lodash/isEqual';

export const isTemplateRevisionEqual = (a: TemplateRevision | undefined, b: TemplateRevision | undefined) => {
  if (a && b) {
    const { lastActiveDate: _aLastActiveDate, ...aWithoutLastActiveDate } = a;
    const { lastActiveDate: _bLastActiveDate, ...bWithoutLastActiveDate } = b;

    return _isEqual(aWithoutLastActiveDate, bWithoutLastActiveDate);
  }

  return _isEqual(a, b);
};
