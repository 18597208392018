import {
  ChecklistRuleDefinition,
  ChecklistRuleDefinitionLogicalOperator,
  ChecklistRuleDefinitionOperandType,
  ChecklistRuleDefinitionOperator,
  FormFieldCondition,
  FormFieldOperand,
  isConditionLogical,
  isRuleLogical,
  LogicalCondition,
  RuleConstants,
  TaskCondition,
  TaskVisibility,
  TimeBasedCondition,
} from '@process-street/subgrade/conditional-logic';
import { FieldType, FormFieldWidget, TaskTemplate, Widget } from '@process-street/subgrade/process';
import { ConditionalLogicUtils } from 'features/conditional-logic/utils/conditional-logic-utils';
import { useEffect, useRef } from 'react';
import { match, P } from 'ts-pattern';
import { TimeSource } from 'directives/rules/template/task-templates-selector/selector-helper';

type UseRuleDefinitionValidationParams = {
  isScrolling?: boolean;
  initialValue?: boolean;
  ruleDeps: GetChecklistRuleDefinitionActionChangeParams;
};

export const useRuleDefinitionValidation = ({
  isScrolling,
  ruleDeps,
  initialValue = true,
}: UseRuleDefinitionValidationParams) => {
  const isValidRef = useRef(initialValue);

  useEffect(() => {
    // Don't validate while the virtualized list is scrolling
    if (isScrolling) return;

    const ruleDefinition = getChecklistRuleDefinitionActionChange(ruleDeps);

    isValidRef.current = validateRuleDefinition(ruleDefinition);
  }, [isScrolling, ruleDeps]);

  return isValidRef.current;
};

export type RuleConditionParams = {
  operator: ChecklistRuleDefinitionOperator;
  operandValue: { value: FormFieldOperand['value'] } | null;
  selectedWidget: FormFieldWidget | null;
  selectedTask: TaskTemplate | null;
  selectedTimeSource: TimeSource | null;
};

type RuleActionParams = {
  effect: TaskVisibility | null;
  selectedWidgets: Widget[];
  selectedTasks: TaskTemplate[];
};

type RuleParams = {
  rule: ChecklistRuleDefinition;
};

export const getFormFieldCondition = ({
  operator,
  operandValue,
  selectedWidget,
  selectedTask,
  selectedTimeSource,
}: RuleConditionParams): FormFieldCondition | TaskCondition | TimeBasedCondition => {
  const isOperandValueRequired = operator ? ConditionalLogicUtils.isOperandValueRequired(operator) : true;
  const operandType = match({ selectedWidget, selectedTask, selectedTimeSource })
    .with({ selectedWidget: P.not(P.nullish) }, ({ selectedWidget }) => {
      return resolveOperandTypeByWidget(selectedWidget) ?? ChecklistRuleDefinitionOperandType.String;
    })
    .with({ selectedTask: P.not(P.nullish) }, () => {
      return ChecklistRuleDefinitionOperandType.String;
    })
    .with({ selectedTimeSource: P.not(P.nullish) }, () => {
      return ChecklistRuleDefinitionOperandType.Period;
    })
    .otherwise(() => ChecklistRuleDefinitionOperandType.String);

  if (selectedTask) {
    return {
      taskTemplateGroupId: selectedTask.group.id,
      operand: {
        operandType,
        value: operandValue?.value,
      },
      operator,
    } as TaskCondition;
  }

  if (selectedTimeSource) {
    return {
      operand: {
        operandType,
        value: operandValue?.value,
      },
      operator,
      dateType: TimeSource.ChecklistStartDate,
    } as TimeBasedCondition;
  }

  return {
    operandValue: isOperandValueRequired && operandValue?.value ? operandValue : { value: null },
    operandType,
    operator,
    formFieldWidgetGroupId: selectedWidget?.header.group.id,
  };
};

export type GetChecklistRuleDefinitionActionChangeParams = RuleActionParams & RuleParams;

export const getChecklistRuleDefinitionActionChange = ({
  rule,
  selectedWidgets,
  selectedTasks,
  effect,
}: GetChecklistRuleDefinitionActionChangeParams): ChecklistRuleDefinition => {
  return {
    ...rule,
    hidden: effect === RuleConstants.TaskVisibility.HIDE,
    widgetGroupIds: selectedWidgets.map(w => w.header.group.id),
    taskTemplateGroupIds: selectedTasks.map(tt => tt.group.id),
  };
};

const resolveOperandTypeByWidget = (formFieldWidget: FormFieldWidget) => {
  const stringWidgetTypes = new Set([
    FieldType.Text,
    FieldType.Textarea,
    FieldType.Hidden,
    FieldType.Select,
    FieldType.MultiChoice,
    FieldType.Email,
    FieldType.Url,
    FieldType.Number,
  ]);
  const dateTimeWidgetTypes = new Set([FieldType.Date, FieldType.SendRichEmail]);

  return match(formFieldWidget.fieldType)
    .when(
      widgetType => dateTimeWidgetTypes.has(widgetType),
      () => ChecklistRuleDefinitionOperandType.DateTime,
    )
    .when(
      widgetType => stringWidgetTypes.has(widgetType),
      () => ChecklistRuleDefinitionOperandType.String,
    )
    .otherwise(widgetType => {
      console.error('not supported form field widget of type %s', widgetType);

      return undefined;
    });
};

// Also handled by yup in schema.ts when saving, but this is used for performance reasons
export const validateRuleDefinition = (rule: ChecklistRuleDefinition) => {
  const { orderTree, formFieldWidgetGroupId, operator, operand, widgetGroupIds, taskTemplateGroupIds, hidden } = rule;
  const isLogical = isRuleLogical(rule);

  // Required fields
  if (
    !orderTree ||
    !operator ||
    !operand ||
    !widgetGroupIds ||
    !taskTemplateGroupIds ||
    typeof hidden !== 'boolean' ||
    (!isLogical && !rule.operand.operandType)
  ) {
    return false;
  }

  // Operator is not valid
  if (!new Set(Object.values(ChecklistRuleDefinitionOperator)).has(operator)) return false;

  // At least one widget or one task template should be selected
  if (widgetGroupIds.length === 0 && taskTemplateGroupIds.length === 0) return false;

  if (isLogical) {
    // Logical rule validity
    // Top-level OR must have ANDs which must have form field conditions
    if (
      rule.operand.data.operator !== ChecklistRuleDefinitionLogicalOperator.LogicalOr ||
      !rule.operand.data.conditions.every(
        v => isConditionLogical(v) && v.operator === ChecklistRuleDefinitionLogicalOperator.LogicalAnd,
      ) ||
      !validateRuleCondition(rule.operand.data)
    ) {
      return false;
    }
  } else {
    if (!formFieldWidgetGroupId) return false;

    if (ConditionalLogicUtils.isOperandValueRequired(operator) && !rule.operand?.value) {
      return false;
    }
  }

  return true;
};

function validateRuleCondition(condition: LogicalCondition) {
  const conditions: FormFieldCondition[] = condition.conditions.flatMap(
    v => (v as LogicalCondition).conditions as FormFieldCondition[],
  );

  return conditions.every(
    c =>
      (c.operandType === ChecklistRuleDefinitionOperandType.Period || Boolean(c.formFieldWidgetGroupId)) &&
      Boolean(c.operator) &&
      (ConditionalLogicUtils.isOperandValueRequired(c.operator)
        ? Boolean(c.operandValue?.value)
        : c.operandValue?.value === null),
  );
}
