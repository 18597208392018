import { FlashActions } from 'reducers/flash/flash.actions';
import { groupNamesSelector, groupWithUserSelector } from 'reducers/group/group.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { createCachedAction } from 'reducers/util';
import { createAction } from 'redux-actions';
import { GroupApi } from 'reducers/group/group.api';

export const GROUP_PUT = 'groups/GET';
export const GROUP_DELETE = 'groups/DELETE';

export const GROUP_STATS_GET = 'groups/STATS_GET';
export const GROUP_QUERY = 'groups/QUERY';
export const GROUP_GET_BY_USER = 'groups/GET_BY_USER';
export const GROUP_GET_BY_ID = 'groups/GET_BY_ID';
export const GROUP_CREATE = 'groups/CREATE';
export const GROUP_UPDATE = 'groups/UPDATE';
export const GROUP_UPDATE_AVATAR = 'groups/UPDATE_AVATAR';
export const GROUP_USER_ADD = 'groups/ADD_USER';
export const GROUP_USER_REMOVE = 'groups/REMOVE_USER';

const queryGroups = createCachedAction(GROUP_QUERY, GroupApi.query);

const getGroupStatsByOrganizationId = createCachedAction(
  GROUP_STATS_GET,
  GroupApi.getGroupStatsByOrganizationId,
  organizationId => ({ organizationId }),
);

// TODO this might be a candidate for deprecation, used only to get group id, should have a better strategy here
// Maybe reuse groupId in userId definition
const getGroupByUserId = createCachedAction(GROUP_GET_BY_USER, GroupApi.getGroupByUserId);

const getGroupById = createCachedAction(
  GROUP_GET_BY_ID,
  GroupApi.getGroupById,
  groupId => ({ groupId }),
  (state, groupId) => {
    const group = groupWithUserSelector(state, groupId);
    return group !== undefined;
  },
);

const doCreateGroup = createCachedAction(GROUP_CREATE, GroupApi.createGroup, (organizationId, name) => ({
  name,
  organizationId,
}));

const createGroup = name => (dispatch, getState) => {
  const state = getState();

  const groupNames = groupNamesSelector(state);
  const exists = groupNames.some(username => username.toLowerCase() === name.toLowerCase());

  if (exists) {
    const errorMessage = 'A group with that name already exists.';
    dispatch(FlashActions.showWarningNotice(errorMessage));
    return Promise.reject(errorMessage);
  } else {
    const organizationId = SessionSelector.getSelectedOrganizationId(state);
    return dispatch(doCreateGroup(organizationId, name));
  }
};

const updateGroup = createCachedAction(GROUP_UPDATE, GroupApi.updateGroup);

// WARNING don't change to createCachedAction, it can't handle data file
const updateGroupAvatar = createAction(GROUP_UPDATE_AVATAR, GroupApi.updateGroupAvatar);

const deleteGroup = createCachedAction(GROUP_DELETE, GroupApi.deleteGroup, group => ({
  id: group.id,
  element: group,
}));

const addUserToGroup = createCachedAction(GROUP_USER_ADD, GroupApi.addUserToGroup, (group, email) => ({
  group,
  email,
}));

const removeUserFromGroup = createCachedAction(
  GROUP_USER_REMOVE,
  GroupApi.removeUserFromGroup,
  (group, membership) => ({ group, membership }),
);

export const GroupActions = {
  getGroupStatsByOrganizationId,
  queryGroups,
  getGroupById,
  getGroupByUserId,
  createGroup,
  updateGroup,
  updateGroupAvatar,
  deleteGroup,
  addUserToGroup,
  removeUserFromGroup,
};
