import { Button, Text, Tooltip } from 'components/design/next';
import { DataSetColumnDef, DataSetColumnState } from '@process-street/subgrade/process';
import { Icon } from 'components/design/next';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import * as React from 'react';
import { match } from 'ts-pattern';
import { useDataSetFilters } from '../../store';

type DataSetSortButtonProps = {
  columnState: DataSetColumnState;
  columnDef: DataSetColumnDef;
};

export const DataSetSortButton: React.FC<React.PropsWithChildren<DataSetSortButtonProps>> = ({
  columnState,
  columnDef,
}) => {
  const dataSetFilters = useDataSetFilters();

  const sortIcon = match<typeof columnState.sort, CustomIconName | null>(columnState.sort)
    .with('asc', () => 'arrow-up')
    .with('desc', () => 'arrow-down')
    .otherwise(() => null);

  const sortLabel = match(columnState.sort)
    .with('asc', () => 'ascending')
    .with('desc', () => 'descending')
    .otherwise(() => '');

  const handleRemoveSort = () => {
    dataSetFilters.setColumnsState({
      columns: dataSetFilters.columns.map(column => {
        if (column.id !== columnState.id) return column;

        return {
          ...column,
          // nulls are required to update the grid
          sort: null,
          sortIndex: null,
        };
      }),
    });
  };

  return (
    <Tooltip
      label={
        <Text fontSize="xs" color="white">
          <strong>{columnDef.name}</strong> sort {sortLabel}
        </Text>
      }
      hasArrow
    >
      <Button
        variant="ghost"
        color={'brand.500'}
        fontSize="md"
        fontWeight={500}
        leftIcon={sortIcon ? <Icon icon={sortIcon} size="4" color="inherit" /> : undefined}
        rightIcon={
          <Icon onClick={handleRemoveSort} transition="200ms ease transform" icon="times" size="3" color="gray.500" />
        }
        borderWidth="1px"
        borderColor={'brand.500'}
        borderStyle="solid"
        borderRadius="full"
        bgColor={'brand.100'}
        px="2"
        _hover={{
          borderColor: 'brand.500',
          bgColor: 'brand.100',
        }}
      >
        {columnDef.name}
      </Button>
    </Tooltip>
  );
};
