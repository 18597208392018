import { getPluginType } from '@udecode/plate-core';
import { getAboveNode } from '@udecode/plate-common';
import { splitNodes, isElement } from '@udecode/slate';
import {
  createTablePlugin,
  withDeleteTable,
  withGetFragmentTable,
  withInsertFragmentTable,
  withInsertTextTable,
  withSelectionTable,
  withSetFragmentDataTable,
} from '@udecode/plate-table';
import {
  createPagesPluginFactory,
  createPagesPlugins,
  PagesDOMHandlers,
  PagesEditor,
  PagesNode,
  PagesValue,
} from '../../pages-plate-types';
import { withPSNormalizeTable } from './with-ps-normalize-table';

export const ELEMENT_PS_TABLE = 'ps-table-widget';
export const ELEMENT_TBODY = 'tbody';
export const ELEMENT_THEAD = 'thead';

const onPaste: PagesDOMHandlers['onPaste'] = editor => event => {
  // walk through each paste handler until the default is prevented
  [restrictPlainText, pasteWholeTable].forEach(fn => {
    if (!event.isDefaultPrevented()) {
      fn();
    }
  });

  function restrictPlainText() {
    // Restrict pasting plain text
    const tableWidgetAbove = getAboveNode(editor, { match: { type: getPluginType(editor, ELEMENT_PS_TABLE) } });
    if (tableWidgetAbove) {
      event.stopPropagation();
      event.preventDefault();
      const plainTextMime = 'text/plain';
      const data = event.clipboardData.getData(plainTextMime);
      editor.insertText(data);
    }
  }

  function pasteWholeTable() {
    const { clipboardData: data } = event;
    const fragment =
      data.getData('application/x-slate-fragment') ??
      // This method is not public for some reason
      // https://github.com/ianstormtaylor/slate/blob/7bfb61c76977cd3ddfc6d4e0f73270a673538f76/packages/slate-react/src/utils/dom.ts#L241
      data.getData('text/html').match(/data-slate-fragment="(.+?)"/m)?.[1];

    if (fragment) {
      const decoded = decodeURIComponent(window.atob(fragment));
      const parsed = JSON.parse(decoded) as PagesNode[];
      if (parsed.length === 1) {
        const [element] = parsed;
        if (isElement(element) && element.type === 'ps-table-widget') {
          // Now we know the only clipboard data is a single table widget
          event.preventDefault();

          // https://github.com/ianstormtaylor/slate/blob/main/packages/slate-react/src/plugin/with-react.ts#L220
          // couldn't find access to `insertTextData` on the editor object
          const text = data.getData('text/plain');
          if (text) {
            const lines = text.split(/\r\n|\r|\n/);
            let split = false;

            for (const line of lines) {
              if (split) {
                splitNodes(editor, { always: true });
              }
              editor.insertText(line);
              split = true;
            }
          }
        }
      }
    }
  }
};

const createPSTable = createPagesPluginFactory({
  key: ELEMENT_PS_TABLE,
  isElement: true,
  handlers: { onPaste },
});

const createPSTbody = createPagesPluginFactory({
  key: ELEMENT_TBODY,
  isElement: true,
});

const createPSThead = createPagesPluginFactory({
  key: ELEMENT_THEAD,
  isElement: true,
});

export const createTableWidgetPlugins = (options?: any) =>
  createPagesPlugins([
    createTablePlugin({
      ...options,
      withOverrides: (editor, plugin) => {
        // Copies withNormalizeTable.ts from @udecode/plate-table
        editor = withPSNormalizeTable(editor, plugin);
        editor = withDeleteTable<PagesValue>(editor);
        editor = withGetFragmentTable<PagesValue>(editor);
        editor = withInsertFragmentTable<PagesValue, PagesEditor>(editor, plugin as any);
        editor = withInsertTextTable<PagesValue>(editor, plugin as any);
        editor = withSelectionTable<PagesValue>(editor);
        editor = withSetFragmentDataTable<PagesValue>(editor);
        return editor;
      },
    }),
    createPSTable(),
    createPSThead(),
    createPSTbody(),
  ]);
