import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { FirstWorkflowModal } from '.';
import { ImportWorkflowModalWrapper } from 'pages/sign-up/first-workflow-modal/import-workflow-modal-wrapper';

export const FirstWorkflowModalModule = angular
  .module(
    'firstWorkflowModal',
    [], // dependencies
  )
  .component('psFirstWorkflowModal', reactToAngular(FirstWorkflowModal))
  .component('psImportWorkflowModal', reactToAngular(ImportWorkflowModalWrapper)).name;
