import { FieldValueJson, SimpleFieldValue } from '@process-street/subgrade/process';
import angular from 'angular';
import templateUrl from './url-renderer.component.html';
import { HtmlServiceUtils } from 'services/html-service.utils';

export class ApprovalSubjectTaskWidgetUrlRendererController {
  public getValue(fieldValue: FieldValueJson) {
    return HtmlServiceUtils.autolinkUrls((fieldValue && ((fieldValue as SimpleFieldValue).value as string)) || '');
  }
}

export const ApprovalSubjectTaskWidgetUrlRenderer: angular.IComponentOptions = {
  bindings: {
    formFieldValue: '<',
  },
  controller: ApprovalSubjectTaskWidgetUrlRendererController,
  templateUrl,
};
