import angular from 'angular';
import { FileUploadConstants } from '../file-upload/file-upload-constants';
import templateUrl from './template-file-widget.component.html';
import './template-file-widget.scss';
import './template-file-widget-preview.scss';
import prettyBytes from 'pretty-bytes';
import { StringService } from 'services/string-service';
import { EventName } from 'services/event-name';

angular.module('frontStreetApp.directives').component('psTemplateFileWidget', {
  bindings: {
    widget: '<',
    disabled: '<',
    onUpdate: '&',
  },
  templateUrl,
  controller($rootScope, $scope, $timeout, FileUploadService, focusById) {
    const ctrl = this;

    ctrl.add = function (__event, data) {
      // Disable autoUpload so we can get the promise from submit
      data.autoUpload = false;

      $timeout(() => {
        data.process(function () {
          return this.process(data);
        });
      });
    };

    ctrl.done = function (__event, data) {
      FileUploadService.finishUpload(ctrl.widget);

      FileUploadService.finishUploadForTemplateWidget(data)
        .then(widget => {
          ctrl.widget.file = widget.file;
        })
        .catch(() => {
          ctrl.fail(null, data);
        });
    };

    ctrl.fail = function (__event) {
      $rootScope.$broadcast(EventName.WIDGET_UPLOAD_FAILED, ctrl.widget);

      // Clean up

      delete ctrl.widget.file;
      delete ctrl.widget.description;

      FileUploadService.finishUpload(ctrl.widget);

      delete ctrl.widget._uploadProgress;
      ctrl.uploadProgress = -1;
    };

    ctrl.progress = function (__event, data) {
      ctrl.uploadProgress = parseInt((data.loaded / data.total) * 100, 10);
    };

    ctrl.processDone = function (__event, data) {
      const { header } = ctrl.widget;
      const [{ name: originalName, type: mimeType }] = data.files;

      FileUploadService.submitTemplateWidgetUpload(data, ctrl.widget, header.id, originalName, mimeType).catch(() => {
        ctrl.fail(null, data);
      });

      const [file] = data.files;

      if (ctrl.widget.header.type === 'File') {
        ctrl.widget.description = file.name;
      }

      ctrl.uploadProgress = 0;
    };

    ctrl.processFail = function (__event, data) {
      let message;
      const [{ error }] = data.files;
      switch (error) {
        case FileUploadConstants.Error.FILE_TOO_LARGE: {
          const prettyMaxFileSize = StringService.getPrettySize(data.maxFileSize);
          message = `Sorry, the file must be smaller than ${prettyMaxFileSize}.`;
          break;
        }
        default:
          message = 'Sorry, we were unable to upload your file.';
      }

      $rootScope.$broadcast(EventName.WIDGET_UPLOAD_INVALID_FILE_TYPE, message);

      if (ctrl.widget) {
        delete ctrl.widget.file;
      }
    };

    ctrl.cancelEvent = function (event) {
      event.preventDefault();
    };

    ctrl.updateWidget = function () {
      ctrl.onUpdate({ widget: ctrl.widget });
    };

    ctrl.getPrettySize = function () {
      return prettyBytes(ctrl.widget.file?.size ?? 0);
    };

    ctrl.isEditing = false;
    ctrl.setIsEditing = function (isEditing) {
      ctrl.isEditing = isEditing;
      if (ctrl.isEditing) {
        focusById('file.description', { selectionStart: () => 0 });
      }
    };

    // For drops

    $scope.$on(EventName.WIDGET_DROP_ADD, (__event, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.add(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_DONE, (__event, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.done(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_FAIL, (__event, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.fail(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_PROCESS_DONE, (__event, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.processDone(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_PROCESS_FAIL, (__event, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.processFail(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_PROGRESS, (__event, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.progress(null, data);
      }
    });
  },
});
