import { SessionSelector } from 'reducers/session/session.selectors';
import { CookieService } from 'features/cookies/cookie-service';
import { createSelector } from 'reselect';
import { store } from 'features/redux/store';
import { bindActionCreators } from 'redux';
import { SessionActions } from 'reducers/session/session.actions';

const stateSelector = createSelector(
  [
    SessionSelector.getSelectedOrganization,
    SessionSelector.getCurrentPlan,
    SessionSelector.getSession,
    SessionSelector.isCanceled,
    SessionSelector.getUserInfo,
  ],
  (selectedOrganization, currentPlan, session, inCanceledState, userInfo) => ({
    selectedOrganization,
    currentPlan,
    session,
    inCanceledState,
    userInfo,
  }),
);
const getState = () => stateSelector(store.getState());

const actions = bindActionCreators(SessionActions, store.dispatch);

// Default homepage

const getDefaultHomepage = () => getState().session.defaultHomepage;

const setDefaultHomepage = homepage => actions.setDefaultHomepage(homepage);

// Auth Headers

const getAuthHeaders = () => ({
  ...(getState().session.token ? { Authorization: `Bearer ${getState().session.token}` } : {}),
  ...(getState().session.selectedOrganizationId
    ? { 'X-Process-Street-Organization-Id': getState().session.selectedOrganizationId }
    : {}),
});

// Token

const getToken = () => getState().session.token;

const setToken = token => {
  CookieService.setFilesAuthTokenCookie(token);
  return actions.setToken(token);
};

const deleteToken = () => {
  CookieService.removeFilesAuthTokenCookie();
  return actions.deleteToken();
};

// Profile
// We don't want to return null, hence we explicitly return undefined
const getProfile = () => (getState().session.profile ? getState().session.profile : undefined);

const setProfile = profile => actions.setProfile(profile);

// Inbox

const getInboxProperties = () => getState().session.inbox;

const getInboxProperty = prop => getState().session.inbox[prop];

const setInboxProperty = (property, value) => actions.setInboxProperty(property, value);

// Template Editor

const getTemplateEditorProperties = () => getState().session.templateEditor;

const getTemplateEditorProperty = property => getState().session.templateEditor[property];

const setTemplateEditorProperty = (property, value) => actions.setTemplateEditorProperty(property, value);

const deleteTemplateEditorProperty = property => actions.deleteTemplateEditorProperty(property);

// Checklist Editor

const getChecklistEditorProperties = () => getState().session.checklistEditor;

const getChecklistEditorProperty = property => getState().session.checklistEditor[property];

const setChecklistEditorProperty = (property, value) => actions.setChecklistEditorProperty(property, value);

const deleteChecklistEditorProperty = property => actions.deleteChecklistEditorProperty(property);

// Checklist Stats

const getChecklistStatsProperties = () => getState().session.checklistStats;

const getChecklistStatsProperty = prop => getState().session.checklistStats[prop];

const setChecklistStatsProperty = (property, value) => actions.setChecklistStatsProperty(property, value);

// Organizations

const getSelectedOrganization = () => getState().selectedOrganization;

const getSelectedOrganizationId = () => getState().session.selectedOrganizationId;

const setSelectedOrganizationId = organizationId => actions.setSelectedOrganizationId(organizationId);

const setOrganizationSubdomainsMap = subdomains => actions.setOrganizationSubdomainsMap(subdomains);

const getOrganizationUseFrequencyMap = () => getState().session.organizationUseFrequencyMap;

const getOrganizationMemberships = () => getState().session.organizationMemberships;

const incrementOrganizationUseFrequency = organizationId => actions.incrementOrganizationUseFrequency(organizationId);

const isCanceled = () => getState().inCanceledState;

const getCurrentPlan = () => getState().currentPlan;

// User
// we dont want to return null, hence we explicitly return undefined
const getUser = () => (getState().session.user ? getState().session.user : undefined);

const setUser = user => actions.setUser(user);

const setOnboardingProperty = (key, value) => actions.setOnboardingProperty(key, value);

// UserInfo
const getUserInfo = () => getState().userInfo;

const setUserInfo = userInfo => {
  const { user, selectedOrganization, userSettings, elevioHash, intercomHash, organizationSubdomainsMap } = userInfo;
  SessionService.importSettings(userSettings);
  SessionService.setUser(user);
  SessionService.setSelectedOrganizationId(selectedOrganization.id);
  SessionService.setOrganizationSubdomainsMap(organizationSubdomainsMap);
  const profile = generateProfile(user, userInfo.identityProvider);
  SessionService.setProfile(profile);
  SessionService.setElevioHash(elevioHash);
  SessionService.setIntercomHash(intercomHash);
};

const setOrphanUserInfo = orphanUserInfo => {
  const profile = generateProfile(orphanUserInfo.user, orphanUserInfo.identityProvider);
  SessionService.setProfile(profile);
  SessionService.setUser(orphanUserInfo.user);
};

const generateProfile = (user, identityProvider) => {
  return {
    email: user.email,
    provider: identityProvider,
    userId: user.id,
    username: user.username,
  };
};

// Hash

const setElevioHash = hash => actions.setElevioHash(hash);

const setIntercomHash = hash => actions.setIntercomHash(hash);

// Miscellaneous

const importSettings = settings => actions.importSettings(settings);

const clear = () => actions.clear();

export const SessionService = {
  getDefaultHomepage,
  setDefaultHomepage,
  getAuthHeaders,
  getToken,
  setToken,
  deleteToken,
  getProfile,
  setProfile,
  getInboxProperties,
  getInboxProperty,
  setInboxProperty,
  getTemplateEditorProperties,
  getTemplateEditorProperty,
  setTemplateEditorProperty,
  deleteTemplateEditorProperty,
  getChecklistEditorProperties,
  getChecklistEditorProperty,
  setChecklistEditorProperty,
  deleteChecklistEditorProperty,
  getChecklistStatsProperties,
  getChecklistStatsProperty,
  setChecklistStatsProperty,
  getSelectedOrganization,
  getSelectedOrganizationId,
  setSelectedOrganizationId,
  setOrganizationSubdomainsMap,
  getOrganizationUseFrequencyMap,
  getOrganizationMemberships,
  incrementOrganizationUseFrequency,
  isCanceled,
  getCurrentPlan,
  getUser,
  setUser,
  setOnboardingProperty,
  getUserInfo,
  setUserInfo,
  setOrphanUserInfo,
  setElevioHash,
  setIntercomHash,
  importSettings,
  clear,
};
