import * as React from 'react';
import { Circle, Square, Icon, SquareProps } from 'components/design/next';
import {
  trayAutomationAppIsPremium,
  AutomationAppName,
  isTrayAutomationAppName,
} from '@process-street/subgrade/automation';
import { AutomationAppIcon } from 'features/automations/components/automation-app-icon';
import { match } from 'ts-pattern';
import { usePlanTrack } from 'services/use-plan-track';

export interface AutomationAppSquareProps {
  automationApp: AutomationAppName;
  hasWarning?: boolean;
  outerSquareProps?: SquareProps;
  innerSquareProps?: SquareProps;
}

export const AutomationAppSquare: React.FC<React.PropsWithChildren<AutomationAppSquareProps>> = ({
  automationApp,
  hasWarning,
  innerSquareProps,
  outerSquareProps,
}) => {
  const track = usePlanTrack();
  const isPremium = match(automationApp)
    .when(isTrayAutomationAppName, a => track && trayAutomationAppIsPremium(a, track))
    .otherwise(() => false);
  const matcher = match({ isPremium, hasWarning });

  return (
    <Square
      {...{
        mx: 'auto',
        borderRadius: 'xl',
        bg: 'brand.50',
        p: '4',
        size: '21',
        ...outerSquareProps,
        ...matcher
          .with({ hasWarning: true }, () => ({
            bg: 'yellow.100',
            borderColor: 'gray.100',
            borderWidth: 'px',
            borderStyle: 'solid',
          }))
          .with({ isPremium: true }, () => ({}))
          .otherwise(() => ({})),
      }}
    >
      <Square
        {...{
          position: 'relative',
          bg: 'white',
          p: '2',
          size: '12',
          borderRadius: 'lg',
          borderWidth: 'px',
          borderColor: 'brand.100',
          borderStyle: 'solid',
          ...matcher
            .with({ hasWarning: true }, () => ({ borderColor: 'yellow.300' }))
            .with({ isPremium: true }, () => ({}))
            .otherwise(() => ({})),
          ...innerSquareProps,
        }}
      >
        <AutomationAppIcon w="8" automationApp={automationApp} />

        {matcher
          .with({ hasWarning: true }, () => (
            <IconCircle bg="yellow.300">
              <Icon icon="triangle-exclamation" variant="far" color="white" size="3" />
            </IconCircle>
          ))
          .with({ isPremium: true }, () => (
            <IconCircle bg="brand.50" borderWidth="px" borderStyle="solid" borderColor="gray.200">
              <Icon icon="star" variant="far" color="brand.500" size="3" />
            </IconCircle>
          ))
          .otherwise(() => null)}
      </Square>
    </Square>
  );
};

const IconCircle: React.FC<React.PropsWithChildren<SquareProps>> = props => {
  return (
    <Circle
      {...{
        ...props,
        size: '5',
        position: 'absolute',
        top: '0',
        right: '0',
        transform: 'translate(40%, -40%)',
      }}
    />
  );
};
