import { Muid } from '@process-street/subgrade/core';
import { TaskAssignmentRule } from '@process-street/subgrade/role-assignment';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateAllTaskAssignmentRulesMutationParams = {
  templateRevisionId: Muid;
  rules: TaskAssignmentRule[];
};

export type CreateAllTaskAssignmentRulesMutationResponse = TaskAssignmentRule[];

export const CreateAllTaskAssignmentRulesMutation = {
  key: ['post', 'template-revisions', 'task-assignment-rules', 'all'],
  getKey: (params: Pick<CreateAllTaskAssignmentRulesMutationParams, 'templateRevisionId'>) => [
    ...CreateAllTaskAssignmentRulesMutation.key,
    params.templateRevisionId,
  ],
  mutationFn: ({ templateRevisionId, ...params }: CreateAllTaskAssignmentRulesMutationParams) =>
    axiosService
      .getAxios()
      .post<CreateAllTaskAssignmentRulesMutationResponse>(
        `/1/template-revisions/${templateRevisionId}/task-assignment-rules/all`,
        params,
      )
      .then(res => res.data),
};

export const useCreateAllTaskAssignmentRulesMutation = (
  options: UseMutationOptions<
    CreateAllTaskAssignmentRulesMutationResponse,
    AxiosError,
    CreateAllTaskAssignmentRulesMutationParams
  > = {},
) => {
  return useMutation(CreateAllTaskAssignmentRulesMutation.mutationFn, options);
};
