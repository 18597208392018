import * as React from 'react';
import {
  Box,
  Flex,
  Button,
  Divider,
  Icon,
  Text,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  HStack,
  useBreakpointValue,
  VStack,
  ButtonGroupProps,
  StackProps,
  MenuButtonProps,
} from 'components/design/next';
import { useTemplateId } from '../../automation/utils/use-template-id';
import {
  AutomationInstanceUtils,
  getAutomationAppNameLabel,
  getPrefixedAutomationAppName,
  getTrayAutomationAppDetail,
  isAutomationInstance,
  isIncomingWebhookInstance,
  isTemplateSolutionInstance,
  withTrayPrefix,
} from '@process-street/subgrade/automation';
import { match } from 'ts-pattern';
import { AutomationAppIcon } from 'features/automations/components/automation-app-icon';
import { TriggerIcons, TriggerLabels } from '../choose-template-triggers/native-trigger-data';
import { TraySolutionTriggerUtils, isNativeTemplateTrigger } from '@process-street/subgrade/process';
import { RunMultipleButton, ScheduleWorkflowButton, WorkflowRunLinkButton } from 'components/template-menu-buttons';
import { useInjector } from 'components/injection-provider';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { NgLink } from 'features/app/components/ng-link';
import { DisplayableTriggerUtils } from './displayable-trigger';
import { useSelectedTemplateTriggers } from './use-selected-template-triggers';
import { NativeTriggerButton } from './native-trigger-button';
import { useTriggersModalStore } from 'features/automations/components/workflow-automations/components/template/triggers-modal-store';
import { AutomationInstanceBadge } from './automation-instance-badge';
import { TriggersStack } from './common';
import { useFeatureFlag } from 'app/features/feature-flags';

export type SelectedTemplateTriggersViewProps = {
  stackProps?: Partial<ButtonGroupProps>;
  automationItemProps?: Partial<StackProps>;
  menuButtonProps?: Partial<MenuButtonProps>;
};

export const SelectedTemplateTriggersView: React.FC<React.PropsWithChildren<SelectedTemplateTriggersViewProps>> = ({
  stackProps,
  automationItemProps,
  menuButtonProps,
}) => {
  const isReactWorkflowEditorEnabled = useFeatureFlag('reactWorkflowEditor');
  const templateId = useTemplateId();
  const { configuredInstances, preferredNativeTriggers } = useSelectedTemplateTriggers(
    'configuredInstances',
    'preferredNativeTriggers',
  );
  const maxIconButtons = useBreakpointValue({ base: 5, sm: 9, lg: 6, xl: 9 }) ?? 6;
  const collapseIconThreshold = useBreakpointValue([4, null, null, 3, 4]) ?? 3;

  const combinedTriggers = [...preferredNativeTriggers, ...configuredInstances];

  const filteredTriggers = combinedTriggers.filter(i =>
    match(i)
      .when(isNativeTemplateTrigger, () => true)
      .when(TraySolutionTriggerUtils.isTraySolutionIdTrigger, () => false)
      .when(DisplayableTriggerUtils.isTemplateSolutionInstance, i => i.configured && i.enabled)
      .when(DisplayableTriggerUtils.isIncomingWebhookInstance, i => i.status === 'Active')
      .otherwise(() => false),
  );

  const shown = filteredTriggers.slice(0, maxIconButtons);
  const hidden = filteredTriggers.slice(maxIconButtons);

  const shouldCollapseLabels = filteredTriggers.length > collapseIconThreshold;

  const triggersModalStore = useTriggersModalStore();

  const { $state } = useInjector('$state');

  if (filteredTriggers.length === 0) return <Box w="full" mt="7" />;

  const runUrlOptions = {
    to: $state.current,
    params: {
      ...$state.params,
      [AppModalQueryParam.Modal]: AppModalName.RunChecklist,
      [AppModalQueryParam.ModalTemplateId]: templateId,
    },
    options: { inherit: false },
  };

  return (
    <Flex direction="column" w="full" alignItems="center" mt="7">
      <TriggersStack {...stackProps}>
        {shown.map(trigger =>
          match(trigger)
            .when(isNativeTemplateTrigger, trigger => (
              <NativeTriggerButton
                key={trigger}
                {...{
                  trigger,
                  shouldCollapseLabels,
                  templateId,
                }}
                editable={false}
                {...{ fontSize: isReactWorkflowEditorEnabled ? 'md' : 'inherit' }}
              />
            ))
            .when(isAutomationInstance, i => {
              const appName = match(i)
                .when(isTemplateSolutionInstance, i => {
                  const { label } = getTrayAutomationAppDetail(withTrayPrefix(i.automationApp));
                  const { description } = i;
                  return [label, description].filter(Boolean).join(': when ');
                })
                .when(isIncomingWebhookInstance, i => i.name ?? i.automationApp)
                .otherwise(() => 'Automation');

              const tooltip = `Edit workflow to edit "${appName}" trigger`;

              const id = AutomationInstanceUtils.getInstanceId(i);

              return (
                <Tooltip key={id} label={tooltip} hasArrow shouldWrapChildren>
                  <AutomationInstanceBadge automationInstance={i} shouldCollapseLabels={shouldCollapseLabels} />
                </Tooltip>
              );
            })
            .otherwise(() => null),
        )}
        {hidden.length > 0 ? (
          <Popover>
            <PopoverTrigger>
              <Button
                variant="outline"
                colorScheme="gray"
                bg="white"
                size="sm"
                px="2"
                iconSpacing="0"
                leftIcon={<Icon icon="plus" variant="far" size="2" />}
                {...menuButtonProps}
              >
                <Text variant="-2">{hidden.length}</Text>
              </Button>
            </PopoverTrigger>
            <PopoverContent w="auto">
              <PopoverArrow />

              <PopoverBody>
                <VStack alignItems="flex-start">
                  {hidden.map(trigger =>
                    match(trigger)
                      .when(isNativeTemplateTrigger, trigger => {
                        const label = TriggerLabels[trigger];
                        return match(trigger)
                          .with('manual', () => (
                            <Button
                              key={trigger}
                              as={NgLink}
                              {...runUrlOptions}
                              leftIcon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
                              children={label}
                              _hover={{ textDecoration: 'none' }}
                            />
                          ))
                          .with('email', () => (
                            <Button
                              key={trigger}
                              leftIcon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
                              children={label}
                              onClick={triggersModalStore.openEmailTab}
                            />
                          ))
                          .with('runLink', () => (
                            <WorkflowRunLinkButton>
                              <Button
                                key={trigger}
                                leftIcon={
                                  <Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />
                                }
                                children={label}
                                onClick={triggersModalStore.openRunLinkTab}
                              />
                            </WorkflowRunLinkButton>
                          ))
                          .with('runMultiple', () => (
                            <RunMultipleButton>
                              <Button
                                key={trigger}
                                leftIcon={
                                  <Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />
                                }
                                children={label}
                              />
                            </RunMultipleButton>
                          ))
                          .with('schedule', () => (
                            <ScheduleWorkflowButton>
                              <Button
                                key={trigger}
                                leftIcon={
                                  <Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />
                                }
                                children={label}
                              />
                            </ScheduleWorkflowButton>
                          ))
                          .with('api', () => (
                            <Button
                              key={trigger}
                              as={NgLink}
                              to={'organizationManage.tab'}
                              params={{ tab: 'integrations' }}
                              options={{ inherit: false }}
                              leftIcon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
                              children={label}
                            />
                          ))
                          .otherwise(() => null);
                      })
                      .when(isAutomationInstance, i => {
                        const safeAppName = getPrefixedAutomationAppName(i);
                        const label = getAutomationAppNameLabel(safeAppName);
                        const tooltip = 'Edit workflow to edit trigger';

                        return (
                          <Tooltip label={tooltip} key={AutomationInstanceUtils.getInstanceId(i)} hasArrow>
                            <HStack cursor="default" noOfLines={1} flex="0 1 auto" {...automationItemProps}>
                              <AutomationAppIcon automationApp={safeAppName} w="4" />
                              <Text as="span" noOfLines={1} variant="-1" minW="0">
                                {label}
                              </Text>
                            </HStack>
                          </Tooltip>
                        );
                      })
                      .otherwise(() => null),
                  )}
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : null}
      </TriggersStack>

      <Box height="6">
        <Divider orientation="vertical" borderStyle="solid" borderColor="gray.300" />
      </Box>
    </Flex>
  );
};
