import { ColDef, IRowNode } from '@ag-grid-community/core';
import { TasksTableCellRenderer } from '../cell-renderer';
import '@ag-grid-community/styles/ag-grid-no-native-widgets.css';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { InboxItem, InboxItemType, InboxItemUtils } from '@process-street/subgrade/inbox';
import { TasksTableUtils } from './tasks-table-utils';
import { isNotIdRef } from '@process-street/subgrade/core';
import { match, P } from 'ts-pattern';
import { OrderTreeUtils } from '@process-street/subgrade/process';

export function dueDateComparator<Value = unknown>(
  _valueA: Value,
  _valueB: Value,
  rowNodeA: Pick<IRowNode<TasksTableUtils.TasksTableItem>, 'data'>,
  rowNodeB: Pick<IRowNode<TasksTableUtils.TasksTableItem>, 'data'>,
  isDescending: boolean,
) {
  const sortDirection = isDescending ? -1 : 1;

  if (TasksTableUtils.isFullWidthRow(rowNodeA.data)) return 1;
  if (TasksTableUtils.isFullWidthRow(rowNodeB.data)) return -1;

  if (TasksTableUtils.isInboxItemRow(rowNodeA.data) && TasksTableUtils.isInboxItemRow(rowNodeB.data)) {
    const dueDateA = InboxItemUtils.getDueDate(rowNodeA.data) ?? 0;
    const dueDateB = InboxItemUtils.getDueDate(rowNodeB.data) ?? 0;
    const checklistA: string = rowNodeA.data.checklist.id;
    const checklistB: string = rowNodeB.data.checklist.id;

    const orderTreeA: string | undefined = match(rowNodeA.data)
      .with({ task: { taskTemplate: { orderTree: P.string } } }, item => item.task.taskTemplate.orderTree)
      .otherwise(() => undefined);

    const orderTreeB: string | undefined = match(rowNodeB.data)
      .with({ task: { taskTemplate: { orderTree: P.string } } }, item => item.task.taskTemplate.orderTree)
      .otherwise(() => undefined);

    // 1st sort criteria - sort by due date
    if (dueDateA < dueDateB) return -1 * sortDirection;
    if (dueDateA > dueDateB) return 1 * sortDirection;

    // 2nd sort criteria - it will group the inbox items by checklist
    if (checklistA < checklistB) return -1;
    if (checklistA > checklistB) return 1;

    // 3rd sort criteria - it will put the one off tasks after the tasks if they are in the same checklist
    if (rowNodeA.data.itemType === InboxItemType.OneOffTask && rowNodeB.data.itemType === InboxItemType.OneOffTask) {
      if (rowNodeA.data.task.audit.createdDate < rowNodeB.data.task.audit.createdDate) return -1 * sortDirection;
      if (rowNodeA.data.task.audit.createdDate > rowNodeB.data.task.audit.createdDate) return 1 * sortDirection;
    }

    if (rowNodeA.data.itemType === InboxItemType.OneOffTask) return 1;
    if (rowNodeB.data.itemType === InboxItemType.OneOffTask) return -1;

    // 4th sort criteria - when tasks are in the same checklist, we sort by order tree so tasks are presented in the same order they are in the checklist
    return OrderTreeUtils.compare(orderTreeA ?? '', orderTreeB ?? '');
  }

  return 0;
}

export function workflowRunGroupComparator<Value = unknown>(
  _valueA: Value,
  _valueB: Value,
  rowNodeA: Pick<IRowNode<TasksTableUtils.TasksTableItem>, 'data'>,
  rowNodeB: Pick<IRowNode<TasksTableUtils.TasksTableItem>, 'data'>,
) {
  if (TasksTableUtils.isFullWidthRow(rowNodeA.data)) return 1;
  if (TasksTableUtils.isFullWidthRow(rowNodeB.data)) return -1;

  if (TasksTableUtils.isInboxItemRow(rowNodeA.data) && TasksTableUtils.isInboxItemRow(rowNodeB.data)) {
    const orderTreeA: string | undefined = match(rowNodeA.data)
      .with({ task: { taskTemplate: { orderTree: P.string } } }, item => item.task.taskTemplate.orderTree)
      .otherwise(() => undefined);

    const orderTreeB: string | undefined = match(rowNodeB.data)
      .with({ task: { taskTemplate: { orderTree: P.string } } }, item => item.task.taskTemplate.orderTree)
      .otherwise(() => undefined);

    // 1st sort criteria - it will put the one off tasks after the tasks if they are in the same checklist
    if (rowNodeA.data.itemType === InboxItemType.OneOffTask && rowNodeB.data.itemType === InboxItemType.OneOffTask) {
      if (rowNodeA.data.task.audit.createdDate < rowNodeB.data.task.audit.createdDate) return -1;
      if (rowNodeA.data.task.audit.createdDate > rowNodeB.data.task.audit.createdDate) return 1;
    }

    if (rowNodeA.data.itemType === InboxItemType.OneOffTask) return 1;
    if (rowNodeB.data.itemType === InboxItemType.OneOffTask) return -1;

    // 2nd sort criteria - sort by order tree so tasks are presented in the same order they are in the checklist
    return OrderTreeUtils.compare(orderTreeA ?? '', orderTreeB ?? '');
  }

  return 0;
}

const checkboxColumnDef: ColDef<TasksTableUtils.TasksTableItem> = {
  colId: 'checkbox',
  cellRenderer: () => null,
  lockPosition: true,
  sortable: false,
  width: 40,
  headerComponent: TasksTableCellRenderer.SelectionHeaderRenderer,
  checkboxSelection: true,
  cellClass: params => {
    if (TasksTableUtils.isInboxItemCompleted(params.data) || TasksTableUtils.isReadOnly(params.data)) {
      return 'selection-disabled';
    }
    return '';
  },
};

const nameColumnDef: ColDef<TasksTableUtils.TasksTableItem> = {
  colId: GetInboxItemsQuery.SortBy.TaskTemplateName,
  headerName: 'Name',
  valueGetter: params => {
    if (!TasksTableUtils.isInboxItemRow(params.data)) {
      return;
    }
    return InboxItemUtils.getName(params.data);
  },
  headerComponent: TasksTableCellRenderer.HeaderRenderer,
  minWidth: 490,
  cellRenderer: TasksTableCellRenderer.NameRenderer,
  flex: 1,
  comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
    // Custom comparator to handle "new task rows"
    const isANewTask = TasksTableUtils.isNewTaskRow(nodeA.data);
    const isBNewTask = TasksTableUtils.isNewTaskRow(nodeB.data);

    // Handle new task row comparison explicitly
    if (isANewTask || isBNewTask) {
      return isDescending ? -1 : 1; // New task rows go to the bottom on asc, top on desc
    }

    // Use natural order
    return valueA?.localeCompare(valueB);
  },
};

const dueDateColumnDef: ColDef<TasksTableUtils.TasksTableItem> = {
  colId: GetInboxItemsQuery.SortBy.AssignmentDueDate,
  headerName: 'Due',
  headerComponent: TasksTableCellRenderer.HeaderRenderer,
  valueGetter: params => {
    // @ts-expect-error -- TODO
    if (TasksTableUtils.isNewTaskRow(params.data) && params.column.sort === 'asc') {
      return Number.MAX_SAFE_INTEGER;
    }
    if (!TasksTableUtils.isInboxItemRow(params.data)) {
      return;
    }
    return InboxItemUtils.getDueDate(params.data);
  },
  width: 90,
  cellRenderer: TasksTableCellRenderer.DueDateRenderer,
  comparator: dueDateComparator,
};

const checklistNameColumnDef: ColDef<TasksTableUtils.TasksTableItem> = {
  colId: GetInboxItemsQuery.SortBy.ChecklistName,
  field: 'checklist.name',
  headerName: 'Workflow Run',
  headerComponent: TasksTableCellRenderer.HeaderRenderer,
  headerComponentParams: {
    icon: 'play',
  },
  cellRenderer: TasksTableCellRenderer.WorkflowRunRenderer,
  minWidth: 290,
  flex: 1,
};

const templateNameColumnDef: ColDef<TasksTableUtils.TasksTableItem> = {
  colId: GetInboxItemsQuery.SortBy.TemplateName,
  headerName: 'Workflow',
  field: 'template.name',
  headerComponent: TasksTableCellRenderer.HeaderRenderer,
  headerComponentParams: {
    icon: 'workflow',
  },
  minWidth: 260,
  cellRenderer: TasksTableCellRenderer.WorkflowRenderer,
};

const assigneesColumnDef: ColDef<TasksTableUtils.TasksTableItem> = {
  colId: 'assignees',
  headerName: 'Assignees',
  headerComponent: TasksTableCellRenderer.HeaderRenderer,
  width: 100,
  sortable: false,
  cellRenderer: TasksTableCellRenderer.AssigneeRenderer,
};

const commentsColumnDef: ColDef<TasksTableUtils.TasksTableItem> = {
  colId: 'comments',
  headerName: 'Comments',
  headerComponent: TasksTableCellRenderer.HeaderRenderer,
  field: 'totalDoodads',
  width: 100,
  sortable: false,
  cellRenderer: TasksTableCellRenderer.CommentsRenderer,
};

export const allColumnDefs: ColDef<TasksTableUtils.TasksTableItem>[] = [
  checkboxColumnDef,
  nameColumnDef,
  dueDateColumnDef,
  checklistNameColumnDef,
  templateNameColumnDef,
  assigneesColumnDef,
  commentsColumnDef,
];

export const groupedByDueDateColumnDefs: ColDef<TasksTableUtils.TasksTableItem>[] = allColumnDefs.concat([
  {
    colId: 'dueDateGroup',
    rowGroup: true,
    hide: true,
    sort: 'asc',
    comparator: dueDateComparator,
    valueGetter: params => {
      if (!TasksTableUtils.isInboxItemRow(params.data)) {
        return;
      }
      return TasksTableUtils.getDueDateGroup(InboxItemUtils.getDueDate(params.data));
    },
  },
]);

export const isNotAttachedOneOffTask = (data: InboxItem): boolean =>
  data.itemType === InboxItemType.OneOffTask && isNotIdRef(data.checklist) && !data.checklist.name;

export const UNATTACHED_TASKS_GROUP = 'Unattached tasks';

export const groupedByWorkflowRunColumnDefs: ColDef<TasksTableUtils.TasksTableItem>[] = [
  checkboxColumnDef,
  nameColumnDef,
  dueDateColumnDef,
  assigneesColumnDef,
  { ...commentsColumnDef, minWidth: 570 }, // takes space of workflow + template, since they are not shown in this grouping.
  {
    colId: 'workflowRunGroup',
    rowGroup: true,
    sort: 'asc',
    hide: true,
    comparator: workflowRunGroupComparator,
    valueGetter: params => {
      if (!TasksTableUtils.isInboxItemRow(params.data) && !TasksTableUtils.isNewTaskRow(params.data)) {
        return;
      }
      if (TasksTableUtils.isInboxItemRow(params.data) && isNotAttachedOneOffTask(params.data)) {
        return UNATTACHED_TASKS_GROUP;
      }

      if (TasksTableUtils.isNewUnattachedTaskRow(params.data)) {
        return UNATTACHED_TASKS_GROUP;
      }

      return isNotIdRef(params.data.checklist) ? `${params.data.checklist.name}${params.data.checklist.id}` : '';
    },
  },
];
