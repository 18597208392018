import { Tooltip, Text, Box, BoxProps } from 'components/design/next';
import React from 'react';
import { useAudit } from './use-audit';
import { AuditMetadata } from '@process-street/subgrade/core';
import { useFeatureFlag } from 'features/feature-flags';

export interface FormFieldAuditProps extends BoxProps {
  audit: AuditMetadata;
}

export const FormFieldAudit: React.FC<React.PropsWithChildren<FormFieldAuditProps>> = ({ audit, ...props }) => {
  const { formattedDate, formattedPrintDate, formattedDateWithTimezone, isLoading, isPrintMode, username } = useAudit({
    audit,
  });
  const isPdfEmbellishmentEnabled = useFeatureFlag('pdfEmbellishment');

  if (isLoading) return <></>;

  return (
    <Box {...props}>
      <Tooltip label={formattedDate} hasArrow>
        <Text as="span" color={isPrintMode ? 'gray.200' : 'gray.400'} fontWeight="bold" variant="-2">
          Updated by{' '}
          <Text as="span" variant="inherit">
            {username}{' '}
          </Text>
          {isPrintMode ? (
            <Text as="span" variant="inherit">
              {isPdfEmbellishmentEnabled ? formattedDateWithTimezone : formattedDate}
            </Text>
          ) : (
            <Text as="span" variant="inherit">
              {formattedPrintDate}
            </Text>
          )}
        </Text>
      </Tooltip>
    </Box>
  );
};
