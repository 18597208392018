import React, { useEffect, useState } from 'react';
import { Template } from '@process-street/subgrade/process';
import { LibraryItemPermissionsIndicator } from 'components/permissions/library-item-permissions-indicator/component';
import { PermissionsStats } from 'components/permissions/models/permissions-stats';
import { useTemplatesPermissionsStatsQuery } from 'components/permissions/services/query';
import { Spinner } from 'components/design/next';

export interface TemplatePermissionsIndicatorProps {
  templateId: Template['id'];
  folderId: Template['folder']['id'];
  organizationId: Template['folder']['id'];
  isViewOnlyForm?: boolean;
}

export const TemplatePermissionsIndicator: React.FC<React.PropsWithChildren<TemplatePermissionsIndicatorProps>> =
  React.memo(({ templateId, folderId, organizationId, isViewOnlyForm = false }) => {
    const [stats, setStats] = useState<PermissionsStats>();
    const { data, isLoading } = useTemplatesPermissionsStatsQuery(organizationId, folderId);

    useEffect(() => {
      if (data) {
        setStats(data[templateId]);
      }
    }, [data, templateId]);

    return isLoading && !stats ? (
      <Spinner />
    ) : stats ? (
      <LibraryItemPermissionsIndicator permissionsStats={stats} isViewOnlyForm={isViewOnlyForm} />
    ) : null;
  });
