import React from 'react';
import { Box } from 'components/design/next';
import { RichTextEditor } from 'features/rich-text';
import { noop } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import { useUnmount } from 'react-use';

interface TableEditorProps {
  widgetId: string;
  value?: string;
  onChange: (content: string) => void;
  onFocus?: () => void;
}

const DEBOUNCE_MS = 500;

const ALIGNMENT_TOOLBAR_ITEMS = ['alignleft', 'aligncenter', 'alignright', 'alignjustify'];
const LIST_TOOLBAR_ITEMS = ['bullist', 'numlist'];
const TEXT_STYLE_TOOLBAR_ITEMS = ['bold', 'underline', 'italic', 'strikethrough'];
const LINK_ITEMS = ['link'];

export const TableEditor: React.FC<React.PropsWithChildren<TableEditorProps>> = React.memo(
  ({ value, onChange, widgetId, onFocus = noop }) => {
    const [editorValue, setEditorValue] = React.useState<string | undefined>(() => value);
    const debouncedOnChange = useDebouncedCallback(onChange, DEBOUNCE_MS);
    useUnmount(() => {
      debouncedOnChange.flush();
    });
    return (
      <>
        <Box
          id={`editor-bar-container-${widgetId}`}
          w="full"
          sx={{
            '.tox-editor-header': {
              border: 'none !important',
              pb: 2,
            },
            '.tox .tox-menu': {
              pt: 4,
            },
            '.tox-tinymce-inline': {
              zIndex: 'var(--ps-zIndices-base)',
            },
          }}
        />
        <RichTextEditor
          editorProps={{
            value: editorValue,
            onEditorChange: value => {
              setEditorValue(value);
              debouncedOnChange(value);
            },
            onBlur: () => debouncedOnChange(editorValue ?? ''),
            onFocus,
          }}
          init={{
            convert_newlines_to_brs: false,
            placeholder: 'Use the toolbar to create a table.',
            fixed_toolbar_container: `#editor-bar-container-${widgetId}`,
            link_default_target: '_blank',
            toolbar_persist: true,
            toolbar: [TEXT_STYLE_TOOLBAR_ITEMS, ALIGNMENT_TOOLBAR_ITEMS, LINK_ITEMS, LIST_TOOLBAR_ITEMS]
              .map(section => section.join(' '))
              .join(' '),
          }}
          editorWrapperProps={{
            'aria-label': 'table editor container',
            'sx': {
              'px': 4,
              'borderWidth': '1px',
              'borderColor': 'gray.200',
              'borderRadius': '4px',
              'borderStyle': 'solid',
              'fontSize': 'md',
              'minHeight': '112px',
              // Overrides mce's default styles. This way, the user has control of the styles.
              '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before': {
                fontStyle: 'normal',
                pl: 1,
                fontSize: 'md',
                color: 'gray.400',
              },
              '.mce-item-table': {
                width: '100%',
              },
              '.mce-item-table p': {
                margin: 0,
              },
              '.mce-item-table th': {
                backgroundColor: 'brand.50',
                textTransform: 'none',
                fontWeight: 'normal',
                fontSize: 'md',
                padding: 2,
                minWidth: 30,
                color: 'gray.700',
              },
              '.mce-item-table td': {
                padding: 2,
              },
            },
          }}
        />
      </>
    );
  },
);
