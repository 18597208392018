import angular from 'angular';

angular
  .module('frontStreetApp.controllers')
  .controller('MessageBoxConfirmCtrl', function ($scope, $uibModalInstance, modalOptions, focusById) {
    $scope.title = modalOptions.title;
    $scope.message = modalOptions.message;
    $scope.okButton = angular.extend({ text: 'OK', action: angular.noop }, modalOptions.okButton || {});
    $scope.cancelButton = angular.extend({ text: 'Cancel', action: angular.noop }, modalOptions.cancelButton || {});
    focusById('cancelButton');

    $scope.ok = function () {
      $uibModalInstance.close($scope.okButton.action());
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss($scope.cancelButton.action());
    };
  });
