import React from 'react';
import { AvatarSize } from 'components/common/Avatar/AvatarSize';
import { Box, Icon } from 'components/design/next';
import { match } from 'ts-pattern';
import { useAvatarClass } from 'components/common/Avatar/use-avatar-class';
import { BoxProps } from '@chakra-ui/react';

/** @deprecated use ChakraAvatar instead */
export const DeactivatedAvatar: React.FC<
  React.PropsWithChildren<
    {
      size: AvatarSize;
    } & BoxProps
  >
> = props => {
  const { size, ...rest } = props;

  const { avatarClass } = useAvatarClass(size);

  return (
    <Box
      className={`avatar ${avatarClass}`}
      role="figure"
      borderStyle="dashed"
      borderColor="gray.300"
      borderWidth="thin"
      borderRadius="full"
      padding={match(size)
        .with(AvatarSize.ExtraSmall, () => 0.5)
        .with(AvatarSize.Small, () => 1)
        .with(AvatarSize.Normal, () => 2)
        .with(AvatarSize.Medium, () => 2.5)
        .with(AvatarSize.Large, () => 3)
        .otherwise(() => 1)}
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
      aria-label="Deactivated user avatar"
      flexShrink={0}
      {...rest}
    >
      <Icon icon="user" color="gray.300" />
    </Box>
  );
};
