import { Muid } from '@process-street/subgrade/core';
import { ChecklistMigrationModuleState } from 'components/migration/store/migration.types';
import { ReduxAppState } from 'reducers/types';
import { createSelector } from 'reselect';

const moduleState = (state: ReduxAppState): ChecklistMigrationModuleState => state.modules.checklistMigration;

const getStatsByChecklistId = (checklistId: Muid) =>
  createSelector(moduleState, (state: ChecklistMigrationModuleState) => state.byChecklist[checklistId] || {});

export const ChecklistMigrationModuleSelector = {
  getStatsByChecklistId,
};
