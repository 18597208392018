import angular from 'angular';

angular.module('frontStreetApp.services').factory('TemplateTaskAssignments', function (ResourceFactory) {
  return ResourceFactory.create({
    getAllByTemplateRevisionId: 'GET[] /1/template-revisions/:templateRevisionId/task-templates/assignments',
    assign: 'PUT /1/task-templates/:taskTemplateId/assignments/:email',
    unassign: 'DELETE /1/task-templates/:taskTemplateId/assignments/:email',
    assignAll: 'PUT[] /1/task-templates/assign',
    unassignAll: 'PUT[] /1/task-templates/unassign',
  });
});
