import * as yup from 'yup';
import { FormFieldValueSchemaTests } from 'pages/forms/_id/edit/components/form-fields/common/validation/form-field-value-schema-tests';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';
import { FormFieldWidgetSettingsSchemas } from '../common/validation/form-field-widget-settings-schema';
import { FieldType, FileFieldValue, FileFormFieldWidget } from '@process-street/subgrade/process';

type ValidationSchemaProps = {
  required: boolean;
};

export const fileSettingsSchema: yup.ObjectSchema<WidgetSettings<FieldType.File>> = yup
  .object({
    config: yup
      .object<FileFormFieldWidget['config']>({
        ...FormFieldWidgetSettingsSchemas.placeholder(60),
      })
      .required(),
    constraints: yup.object<FileFormFieldWidget['constraints']>({}).required(),
  })
  .required();

export const makeFileValidationSchema = ({ required }: ValidationSchemaProps) => {
  return yup.mixed<FileFieldValue>().test(FormFieldValueSchemaTests.required(required));
};
