import { OrganizationMembershipRole, Option } from '@process-street/subgrade/core';
import { Plan } from '@process-street/subgrade/billing';
import { isT5KPlan } from 'services/plans';

export interface RoleInfo {
  title: string;
  legacyTitle: string;
  description: string;
  t5kDescription: string;
  index: number;
}

export const RolesMap: Record<OrganizationMembershipRole, RoleInfo> = {
  [OrganizationMembershipRole.Admin]: {
    title: 'Admin',
    legacyTitle: 'Admin',
    description: 'Unlimited permissions - full control over all organization settings and billing details.',
    t5kDescription: 'Unlimited permissions - full control over all organization settings and billing details.',
    index: 1,
  },
  [OrganizationMembershipRole.FullMember]: {
    title: 'Member',
    legacyTitle: 'Member',
    description: 'Create, edit, and run workflows. Create and edit pages.',
    t5kDescription: 'Create, edit, and run workflows. Create and edit pages.',
    index: 2,
  },
  [OrganizationMembershipRole.FreeMember]: {
    title: 'Guest (Internal)',
    legacyTitle: 'Member',
    description: 'Create and edit pages.',
    t5kDescription: 'View and run workflows. Create and edit pages.',
    index: 3,
  },
  [OrganizationMembershipRole.Guest]: {
    title: 'Guest (External)',
    legacyTitle: 'Guest',
    description: 'Limited access - can only see active workflows they’re assigned to.',
    t5kDescription: 'Limited access - can only see active workflows they’re assigned to.',
    index: 4,
  },
};

const RolesWithoutGuest = [
  OrganizationMembershipRole.Admin,
  OrganizationMembershipRole.FullMember,
  OrganizationMembershipRole.FreeMember,
];

export const Roles = [
  OrganizationMembershipRole.Admin,
  OrganizationMembershipRole.FullMember,
  OrganizationMembershipRole.FreeMember,
  OrganizationMembershipRole.Guest,
];

export const isPromoting = (fromRole: OrganizationMembershipRole, toRole: OrganizationMembershipRole) =>
  RolesMap[fromRole].index > RolesMap[toRole].index;

export const sameOrLessPrivilegeRoles = (
  rolesToFilterFrom: OrganizationMembershipRole[] = Roles,
  currentUserRole: OrganizationMembershipRole | undefined,
) =>
  currentUserRole
    ? rolesToFilterFrom.filter(role => RolesMap[role].index >= RolesMap[currentUserRole].index)
    : rolesToFilterFrom;

export const SourcesToRolesMap = {
  All: Roles,
  Folders: RolesWithoutGuest,
  Groups: [OrganizationMembershipRole.Admin, OrganizationMembershipRole.FullMember],
  Pages: RolesWithoutGuest,
  Workflows: RolesWithoutGuest,
};

export const getRoleDescription = (role: OrganizationMembershipRole, plan: Option<Plan>): string => {
  if (plan && isT5KPlan(plan.id)) {
    return RolesMap[role].t5kDescription;
  }
  return RolesMap[role].description;
};
