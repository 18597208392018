import * as React from 'react';
import groupBy from 'lodash/groupBy';
import { useAiTaskTemplateFormContext } from '../../ai-task-template-form-context';
import {
  ExecuteAiPrompt,
  FieldType,
  getFormFieldWidgetLabel,
  FormFieldWidget,
  Widget,
} from '@process-street/subgrade/process';
import { Muid } from '@process-street/subgrade/core';
import { useFunctionRef } from 'hooks/use-function-ref';
import { indexOf } from 'lodash';

export type AiTaskWidgetOption = { label: string; value: Muid; fieldType?: FieldType };

export interface UseAiTaskWidgetSelectArgs {
  value?: Muid | string;
  filter?: (widget: Widget) => widget is FormFieldWidget;
}

export const useAiTaskWidgetSelect = ({
  value: valueProp,
  filter = ExecuteAiPrompt.isOutputWidget,
}: UseAiTaskWidgetSelectArgs) => {
  const { widgets, taskTemplates } = useAiTaskTemplateFormContext();
  const filterRef = useFunctionRef(filter);
  const formFieldWidgets = React.useMemo(() => widgets.filter(filterRef.current), [filterRef, widgets]);

  const value = React.useMemo(() => {
    if (!valueProp) return null;
    const widget = formFieldWidgets.find(w => w.id === valueProp);
    return widget
      ? { label: getFormFieldWidgetLabel(widget), value: widget.id, fieldType: widget.fieldType }
      : { label: valueProp, value: valueProp };
  }, [formFieldWidgets, valueProp]);

  const taskWidgetOptions = React.useMemo(() => {
    const grouped = groupBy(formFieldWidgets, widget => widget.header.taskTemplate.id);
    return taskTemplates.map(taskTemplate => ({
      label: `${indexOf(taskTemplates, taskTemplate) + 1}: ${taskTemplate.name ?? 'Untitled'}`,
      options: (grouped[taskTemplate.id] ?? []).map(w => ({
        label: getFormFieldWidgetLabel(w),
        value: w.id,
        fieldType: w.fieldType,
      })),
    }));
  }, [formFieldWidgets, taskTemplates]);

  return {
    value,
    taskWidgetOptions,
  };
};
