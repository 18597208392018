import * as React from 'react';
import { components, SingleValueProps } from 'react-select';
import { AiTaskWidgetSelectItem } from './ai-task-widget-select-item';
import { AiTaskWidgetOption } from './use-ai-task-widget-select';

export type AiTaskWidgetSelectValueProps = SingleValueProps<AiTaskWidgetOption>;

export const AiTaskWidgetSelectValue: React.FC<AiTaskWidgetSelectValueProps> = props => {
  return (
    <components.SingleValue {...props}>
      <AiTaskWidgetSelectItem label={props.data.label} fieldType={props.data.fieldType} />
    </components.SingleValue>
  );
};
