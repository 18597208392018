import angular from 'angular';
import { DueDateListeners } from './listeners/due-date-listeners.module';
import { DueDateRuleDefinitionApiModule } from './api/due-date-rule-definition-api.module';
import { DueDateRuleDefinitionDaoModule } from './dao/due-date-rule-definition-dao.module';
import { DynamicDueDateServices } from './services/dynamic-due-date-services.module';
import { ChecklistButtonComponent } from './checklist-button/checklist-button.component';
import { DropDownSelectorComponent } from './drop-down-selector/drop-down-selector.component';
import { TemplateButtonComponent } from './template-button/template-button.component';
import { TemplateMultiselectComponent } from './template-multiselect/template-multiselect.component';
import { TemplatePopupComponent } from './template-popup/template-popup.component';
import { UpgradeModalComponent } from './upgrade-modal/upgrade-modal.component';

export const DynamicDueDatesModule = angular
  .module('dynamicDueDatesModule', [
    DueDateListeners,
    DueDateRuleDefinitionApiModule,
    DueDateRuleDefinitionDaoModule,
    DynamicDueDateServices,
  ])
  .component('psDynamicDueDateChecklistButton', ChecklistButtonComponent)
  .component('psDynamicDueDateDropDownSelector', DropDownSelectorComponent)
  .component('psDynamicDueDateTemplateButton', TemplateButtonComponent)
  .component('psDynamicDueDateTemplateMultiselect', TemplateMultiselectComponent)
  .component('psDynamicDueDateTemplatePopup', TemplatePopupComponent)
  .component('psDynamicDueDateUpgradeModal', UpgradeModalComponent).name;
