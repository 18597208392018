import { Muid } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UndeleteTemplateMutationParams = {
  templateId: Muid;
};

export type UndeleteTemplateMutationResponse = Template;

export const UndeleteTemplateMutation = {
  key: ['template', 'undelete'],
  mutationFn: ({ templateId }: UndeleteTemplateMutationParams) =>
    axiosService
      .getAxios()
      .put(`/1/templates/${templateId}/undelete`, { templateId })
      .then(r => r.data),
};

export const useUndeleteTemplateMutation = (
  options: UseMutationOptions<UndeleteTemplateMutationResponse, AxiosError, UndeleteTemplateMutationParams> = {},
) =>
  useMutation(UndeleteTemplateMutation.mutationFn, {
    mutationKey: UndeleteTemplateMutation.key,
    ...options,
  });
