import { ColDef } from '@ag-grid-community/core';
import {
  ChecklistColumn,
  ChecklistGridData,
  ColumnDto,
  ColumnType,
  FormFieldValueColumnField,
} from '@process-street/subgrade/dashboard';
import {
  CompletedByRenderer,
  MonthDayRenderer,
  StatusRenderer,
} from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers';
import { LinkRenderer } from '../components/form-responses-table/link-renderer';

export type PredefinedFormResponsesField =
  | keyof Pick<
      ChecklistGridData,
      'status' | 'checklistCreatedDate' | 'checklistCompletedDate' | 'lastActiveDate' | 'completedBy'
    >;

export const SELECTION_COLUMN_NAME = 'selection';
export const LINK_COLUMN_NAME = 'link';

export interface ChecklistGridDataWithPredefined extends ChecklistGridData {
  [SELECTION_COLUMN_NAME]: string;
  [LINK_COLUMN_NAME]: string;
}

export interface FormResponsesColDefWithPredefined extends ColDef<ChecklistGridDataWithPredefined> {
  field: PredefinedFormResponsesField | typeof SELECTION_COLUMN_NAME | typeof LINK_COLUMN_NAME;
}

export const PREDEFINED_COLUMN_DEFS = [
  {
    field: SELECTION_COLUMN_NAME,
    cellRenderer: () => null,
    headerComponent: 'SelectionHeaderRenderer',
    lockPosition: true,
    pinned: 'left',
    sortable: false,
    width: 40,
  },
  {
    field: LINK_COLUMN_NAME,
    headerName: 'Link',
    cellRenderer: LinkRenderer,
    valueGetter: d => d.data?.id,
    width: 48,
    type: ColumnType.Checklist,
  },
  {
    field: 'checklistCreatedDate',
    headerName: 'Created',
    cellRenderer: MonthDayRenderer,
    width: 100,
    type: ColumnType.Checklist,
  },
  { field: 'status', headerName: 'Status', cellRenderer: StatusRenderer, type: ColumnType.Checklist },
  {
    field: 'lastActiveDate',
    headerName: 'Last Active',
    cellRenderer: MonthDayRenderer,
    width: 108,
    type: ColumnType.Checklist,
  },
  {
    field: 'checklistCompletedDate',
    headerName: 'Completed',
    cellRenderer: MonthDayRenderer,
    width: 108,
    type: ColumnType.Checklist,
  },
  {
    field: 'completedBy',
    headerName: 'Submitted by',
    cellRenderer: CompletedByRenderer,
    width: 140,
    type: ColumnType.Checklist,
    sortable: false,
  },
] satisfies FormResponsesColDefWithPredefined[];

interface FormResponsesFormFieldColDef extends ColDef<ChecklistGridData> {
  field: FormFieldValueColumnField;
}

export type FormResponsesColDef = typeof PREDEFINED_COLUMN_DEFS[number] | FormResponsesFormFieldColDef;
export type FormResponsesColDto = ColumnDto<PredefinedFormResponsesField> | ColumnDto<FormFieldValueColumnField>;

/** ChecklistColumn is required to interact with the back-end, but it forces unnecessary
 * complexity for ag-grid. This map allows us to convert between the two types.
 */
export const fieldChecklistColumnNameMap = {
  checklistCompletedDate: ChecklistColumn.ChecklistCompletedDate,
  checklistCreatedDate: ChecklistColumn.ChecklistCreateDate,
  completedBy: ChecklistColumn.ChecklistCompletedBy,
  lastActiveDate: ChecklistColumn.LastActiveDate,
  status: ChecklistColumn.ProgressStatus,
} satisfies Record<PredefinedFormResponsesField, ChecklistColumn>;

export type FormResponseChecklistColumn = typeof fieldChecklistColumnNameMap[keyof typeof fieldChecklistColumnNameMap];

type Invert<Obj extends Record<string, string>> = { [K in keyof Obj as Obj[K]]: K };

export const checklistColumnNameFieldMap = Object.entries(fieldChecklistColumnNameMap).reduce(
  (acc, [key, value]) => ({ ...acc, [value]: key }),
  {} as Invert<typeof fieldChecklistColumnNameMap>,
);

const FILTERABLE_CHECKLIST_COLUMNS = [
  ChecklistColumn.ChecklistCompletedDate,
  ChecklistColumn.ChecklistCreateDate,
  ChecklistColumn.LastActiveDate,
  ChecklistColumn.ProgressStatus,
] as const;

export type FilterableFormResponseChecklistColumn = typeof FILTERABLE_CHECKLIST_COLUMNS[number];

export type FilterableFormResponsesField = typeof checklistColumnNameFieldMap[FilterableFormResponseChecklistColumn];
export type FilterableFormResponsesColDef = Extract<FormResponsesColDef, { field: FilterableFormResponsesField }>;

function isFilterableFormResponsesColDef(colDef: FormResponsesColDef): colDef is FilterableFormResponsesColDef {
  if (colDef.field.startsWith('formFields')) return false;
  const column =
    fieldChecklistColumnNameMap[colDef.field as Exclude<PredefinedFormResponsesField, FormFieldValueColumnField>];
  return Boolean(FILTERABLE_CHECKLIST_COLUMNS.find(c => c === column));
}

export const FormResponsesUtils = {
  isFilterableColDef: isFilterableFormResponsesColDef,
};
