import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { RolesMap } from 'pages/organizations/manage/users/components/role-selector/model';

export interface RoleNameInfo {
  single: string;
  plural: string;
}

export const useRoleNames = (): Record<OrganizationMembershipRole, RoleNameInfo> => {
  const admin = RolesMap[OrganizationMembershipRole.Admin];
  const fullMember = RolesMap[OrganizationMembershipRole.FullMember];
  const freeMember = RolesMap[OrganizationMembershipRole.FreeMember];
  const guest = RolesMap[OrganizationMembershipRole.Guest];

  return {
    [OrganizationMembershipRole.Admin]: {
      single: admin.title,
      plural: `${admin.title}s`,
    },
    [OrganizationMembershipRole.FullMember]: {
      single: fullMember.title,
      plural: `${fullMember.title}s`,
    },
    [OrganizationMembershipRole.FreeMember]: {
      single: freeMember.title,
      plural: `Guests (Internal)`,
    },
    [OrganizationMembershipRole.Guest]: {
      single: guest.title,
      plural: `Guests (External)`,
    },
  };
};
