export const Verb = {
  CREATED: 'Created',
  COPIED: 'Copied',
  UPDATED: 'Updated',
  DELETED: 'Deleted',
  ATTACHED: 'Attached',
  DETACHED: 'Detached',
  RESTORED: 'Restored',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};
