import { ActivityObjectType, UserActivity } from '@process-street/subgrade/activity';
import { InterpretedActivity, InterpreterContext } from 'services/activities/interpreters/InterpreterContext';
import { Option } from '@process-street/subgrade/core';
import { Verb } from 'services/activities/verb';
import { sprintf as spf } from 'sprintf-js';
import { escapeHtml as esc } from '@process-street/subgrade/util';

function interpret(activity: UserActivity, ctx: InterpreterContext) {
  let sentence: Option<InterpretedActivity> = ctx.interpret(activity);
  const { data } = activity;

  switch (activity.verb) {
    case Verb.UPDATED:
      sentence.verb.value = 'updated';

      sentence.objects.push({
        value: spf('<a>%s</a>', esc(ctx.abbr(data.username))),
        type: ActivityObjectType.User,
        id: activity.object.id,
      });

      if (data.updatedUsername) {
        sentence.objects.push({
          value: spf('Username was updated to <a>%s</a>', esc(ctx.abbr(data.updatedUsername))),
          type: ActivityObjectType.User,
          id: activity.object.id,
        });
      }
      if (data.updatedEmail) {
        sentence.objects.push({
          value: spf('Email was updated to <a>%s</a>', esc(ctx.abbr(data.updatedEmail))),
          type: ActivityObjectType.User,
          id: activity.object.id,
        });
      }
      if (data.updatedRole) {
        sentence.objects.push({
          value: spf('Role was updated to <a>%s</a>', esc(ctx.abbr(data.updatedRole))),
          type: ActivityObjectType.User,
          id: activity.object.id,
        });
      }

      break;
    default:
      ctx.logger.error('unrecognized verb: %s', activity.verb);
      sentence = undefined;
  }
  return sentence;
}

export const UserActivityInterpreter = {
  interpret,
};
