import * as React from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { DateUtils } from '@process-street/subgrade/util';
import { useGetCurrentUserQuery } from 'features/user/query-builder';
import { User } from '@process-street/subgrade/core';
import { TaskStatus } from '@process-street/subgrade/process';

type PrintOneOffTaskAuditProps = {
  task: OneOffTask;
  usersMap: Record<string, User>;
};

export const PrintOneOffTaskAudit = ({ task, usersMap }: PrintOneOffTaskAuditProps) => {
  const currentUserQuery = useGetCurrentUserQuery();

  const updatedByUser = usersMap[task.audit.updatedBy?.id];
  if (task.status !== TaskStatus.Completed || !updatedByUser) return null;

  return (
    <VStack w="full" spacing={0} alignItems="flex-end">
      <Text as="span" fontSize="10px" mb={0}>
        by <strong>{updatedByUser.username}</strong>
      </Text>
      <Text as="span" fontSize="10px" mb={0}>
        {DateUtils.formatActivityWithTimezone({
          date: task.audit.updatedDate,
          timeZone: currentUserQuery.data?.timeZone,
        })}
      </Text>
    </VStack>
  );
};
