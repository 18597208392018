import angular from 'angular';
import { features } from 'services/features/features';
import { PermitConstants, PermitType } from '@process-street/subgrade/permission/permit-constants';
import { FolderMembershipActions } from 'components/folder/membership/store/folder-membership.actions';
import { connectService } from 'reducers/util';
import { IdentityProvider, OrganizationMembershipRole } from '@process-street/subgrade/core';
import { createSelector } from 'reselect';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { Rules } from 'components/invitation/invitation-widget/rules';
import { HttpStatus } from '@process-street/subgrade/util';
import { queryClient } from 'components/react-root';
import { GetFolderPermits } from 'features/permits/query-builder';
import { SourcesToRolesMap } from 'pages/organizations/manage/users/components/role-selector/model';
import { PERMISSION_STATS_KEY } from 'components/permissions/services/query';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ArrayService } from 'services/array-service';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'FolderManageMembersCtrl',
    function (
      $ngRedux,
      $scope,
      $state,
      $q,
      $timeout,
      DataService,
      InvitationService,
      OrganizationMembershipService,
      PermitService,
      ResponseUtilsService,
      SessionService,
      UserService,
      UserSuggester,
      ToastService,
    ) {
      const ctrl = this;

      let unsubscribe = () => {
        /* do nothing */
      };

      const mapStateToThis = createSelector(
        [
          FeatureFlagSelector.getFeatureFlags,
          SessionSelector.getCurrentPlan,
          OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId,
        ],
        (featureFlags, plan, organizationMembership) => ({
          featureFlags,
          plan,
          organizationMembership,
        }),
      );

      $scope.connectToRedux = () => {
        unsubscribe = connectService(
          'FolderManageMembersCtrl',
          $ngRedux,
          mapStateToThis,
          FolderMembershipActions,
        )($scope);
      };

      $scope.$on('$destroy', () => {
        unsubscribe();
      });

      ctrl.$onInit = () => {
        // WARNING!!!
        // There is a secret dependency here on $scope.subject!
        $scope.connectToRedux();

        $scope.paywallIsOpen = false;
        $scope.invitee = '';
        $scope.isFreemium = features.isFreemiumTrack($scope.state.plan.id);
        $scope.suggestionsAreLoading = false;

        $scope.inviteConfig = (() => {
          switch ($scope.state.organizationMembership?.role) {
            case OrganizationMembershipRole.FreeMember:
              return Rules.freeMember;

            default:
              return Rules.matchInviterOrFreeMember;
          }
        })();

        $scope.availableRoles = SourcesToRolesMap.Folders;

        $scope.accessLevels = PermitConstants.FolderFullMemberAccessLevels;
        $scope.guestAccessLevels = PermitConstants.FolderLegacyGuestAccessLevels;
        $scope.freeAccessLevels = PermitConstants.FolderFreeMemberAccessLevels;

        initializeFolder();
        initializeSuggestions();
        initializeMemberships();
      };

      function initializeFolder() {
        $scope.folder = DataService.getCollection('folders').get($state.params.id);
      }

      // Invitations suggestions
      let userSuggester;
      $scope.suggestedUsers = [];

      function initializeSuggestions() {
        userSuggester = new UserSuggester([IdentityProvider.ProcessStreet]);
      }

      const initializeSuggestedUsers = () => {
        const excludeUsers = $scope.memberships?.map(membership => membership.user) ?? [];

        $scope.suggestionsAreLoading = true;
        userSuggester.suggest(undefined, excludeUsers).then(users => {
          $timeout(() => {
            $scope.suggestedUsers = users;
            $scope.suggestionsAreLoading = false;
          });
        });
      };

      $scope.selectSuggestion = function (suggestion) {
        $scope.add(suggestion.email);
      };

      // Add
      $scope.invite = ({ email, role = OrganizationMembershipRole.FullMember }) => {
        $scope.add(email, role);
      };

      $scope.closePaywall = () => {
        $scope.paywallIsOpen = false;
        $scope.invitee = '';
      };

      $scope.add = function (email, role) {
        $scope.adding = true;

        const selectedOrganizationId = SessionService.getSelectedOrganizationId();
        const membership = DataService.getCollection('organizationMemberships').find(
          mbsp => {
            return (
              mbsp.organization.id === selectedOrganizationId && mbsp.user.email?.toLowerCase() === email.toLowerCase()
            );
          },
          ['user'],
        );
        $scope.invitee = membership?.user?.username ?? email;

        const inviteeInFolder = ArrayService.includes($scope.memberships, membership, 'id');
        if (inviteeInFolder) {
          ToastService.openToast({
            status: 'warning',
            title: `We couldn't invite ${email} to the folder`,
            description: 'They are already a member of the folder.',
          });

          $scope.adding = false;

          return;
        }

        let request;

        if (membership) {
          $scope.memberships.push(membership);
          request = PermitService.db.create(PermitType.FOLDER, $scope.folder, membership).then(
            permit => {
              AnalyticsService.trackEvent('user added someone to folder', {
                'someone email': membership.user.email,
              });

              $scope.actions.createFolderPermit($scope.folder.id, permit);

              queryClient.invalidateQueries(GetFolderPermits.key);

              return permit;
            },
            response => {
              ArrayService.desplice($scope.memberships, membership);

              return $q.reject(response);
            },
          );
        } else {
          const resource = { type: 'Folder', id: $scope.folder.id };

          request = InvitationService.invite(email, resource, { role }).then(response => {
            $scope.actions.getFolderPermits($scope.folder.id, $scope.folder.organization.id);
            AnalyticsService.trackEvent('user added someone to folder', {
              'someone email': email,
            });

            ToastService.openToast({
              status: 'success',
              title: `Invitation sent to ${email}`,
            });

            $scope.memberships.push(response.organizationMembership);
            queryClient.invalidateQueries(GetFolderPermits.key);
            return response;
          });
        }

        request
          .then(response => {
            // Reset form
            initializeMemberships();

            return response;
          }, $scope._handleAddErrorResponse(email))
          .finally(() => {
            $scope.adding = false;
          });
      };

      $scope._handleAddErrorResponse = email => response => {
        if (ResponseUtilsService.isUsersLimitReached(response)) {
          const message = ResponseUtilsService.getUsersLimitReachedMessage(response);
          ToastService.openToast({
            status: 'warning',
            title: `We couldn't invite the user to the folder`,
            description: message,
          });
          initializeMemberships(); // we added user but as guest only
        } else if (response.status === HttpStatus.PAYMENT_REQUIRED && $scope.isFreemium) {
          $scope.paywallIsOpen = true;
        } else {
          const status = response.status === HttpStatus.BAD_REQUEST ? 'warning' : 'error';
          ToastService.openToast({
            status,
            title: `We're having problems adding ${email} to the folder`,
            description: DefaultErrorMessages.getUserInactiveDescription(email, response),
          });
        }
        return $q.reject(response);
      };

      function initializeMemberships() {
        // invalidate all folder & template permission stats due to recursion
        // this is called when any folder permission is added, removed etc.
        queryClient.invalidateQueries(PERMISSION_STATS_KEY);
        PermitService.getAllAndInherited(PermitType.FOLDER, $state.params.id).then(
          result => {
            $scope.permits = result.permits;
            $scope.memberships = PermitService.initializeMemberships($scope.permits);

            const foldersCollection = DataService.getCollection('folders');

            $scope.inheritedPermits = result.inheritedPermits.filter(permit => {
              // Filter out folders we don't have access to and the Home folder
              const folder = foldersCollection.get(permit.folder.id);
              return folder && folder.parentFolder;
            });
            $scope.inheritedPermits.forEach(permit => {
              const folder = foldersCollection.get(permit.folder.id);
              permit._resource = folder;
              permit._resourceName = folder ? folder.name : '???';
              permit._level = PermitService.getLevel(permit);
            });
            initializeSuggestedUsers();
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading the users for the folder`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          },
        );
      }

      $scope.onRemove = () => {
        initializeMemberships();
      };

      // Permissions
      $scope.canAddMembers = (subject, folder) => {
        return subject?.canUpdateFolder(folder.id);
      };
    },
  );
