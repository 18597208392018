import { connectController } from 'reducers/util';
import { SessionSelector } from 'reducers/session/session.selectors';
import templateUrl from './checklist-task-assigner-my-work.component.html';
import { ChecklistTaskAssignmentSelector } from '../store/checklist-task-assignment.selectors';
import './checklist-task-assigner-my-work.scss';

// checklist-task-assigner.js is used from Inbox and from WFR. Duplicating this to use from My Work.
export const ChecklistTaskAssignerMyWorkComponent = {
  bindings: {
    task: '<',
    disabled: '<',
  },
  templateUrl,
  controller: class ChecklistTaskAssignerMyWorkComponent {
    helpText =
      'Search for a person in your organization by name or email address, ' +
      'or enter an email address to invite a guest.';

    constructor($ngRedux, ChecklistTaskAssignmentActions, OrganizationMembershipActions) {
      'ngInject';

      this.$ngRedux = $ngRedux;
      this.ChecklistTaskAssignmentActions = ChecklistTaskAssignmentActions;
      this.OrganizationMembershipActions = OrganizationMembershipActions;
    }

    $onInit() {
      const mapStateToThis = () => state => {
        const selectedOrganizationId = SessionSelector.getSelectedOrganizationId(state);
        const assignments = ChecklistTaskAssignmentSelector.getAllByTaskIdWithOrganizationMembershipAndUser(
          this.task.id,
        )(state);
        const users = assignments.map(assignment => assignment.organizationMembership.user);

        return { selectedOrganizationId, assignments, users };
      };

      const mapDispatchToThis = () => ({
        getAllOrgMembershipByOrgId: this.OrganizationMembershipActions.getAllOrgMembershipByOrgId,
        getAllAssignmentsByChecklistRevisionId: this.ChecklistTaskAssignmentActions.getAllByChecklistRevisionId,
      });

      connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, this.shouldChange)(this);

      this.loadAssignments();
    }

    $onChanges(changes) {
      const needToReload =
        changes.task &&
        changes.task.currentValue &&
        changes.task.previousValue &&
        changes.task.previousValue.checklistRevision &&
        changes.task.currentValue.checklistRevision &&
        changes.task.previousValue.checklistRevision.id !== changes.task.currentValue.checklistRevision.id;
      if (needToReload) {
        this.loadAssignments();
      }
    }

    shouldChange = changes => changes.task && changes.task.currentValue;

    loadAssignments = () => {
      this.actions.getAllOrgMembershipByOrgId(this.state.selectedOrganizationId);
      this.actions.getAllAssignmentsByChecklistRevisionId(this.task.checklistRevision.id);
    };

    registerTaskAssignmentCtrl(taskAssignmentCtrl) {
      this.taskAssignmentCtrl = taskAssignmentCtrl;
    }

    onShowAddChecklistTaskAssignment() {
      if (this.taskAssignmentCtrl) {
        this.taskAssignmentCtrl.onShow();
      }
    }
  },
};
