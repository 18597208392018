import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { FutureChecklistNameInput } from 'features/future-checklist/components/future-checklist-name-input/index';

export const FutureChecklistNameInputModule = angular
  .module('FutureChecklistNameInput', [])
  .component(
    'psFutureChecklistNameInput',
    reactToAngular(FutureChecklistNameInput, ['templateId', 'onChange', 'value']),
  ).name;
