export const ChecklistPermitApi = function (Permits) {
  'ngInject';

  const getAllByOrganizationId = organizationId =>
    Permits.getAllByOrganizationId({
      type: 'checklist',
      organizationId,
    }).$promise;

  return {
    getAllByOrganizationId,
  };
};
