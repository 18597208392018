import angular from 'angular';
import { DynamicDueDateService } from './dynamic-due-date.service';
import { TaskDueDateService } from './task-due-date-service';
import { DueDateRuleDefinitionValidationService } from './due-date-rule-definition-validation-service';
import { TaskDueDateListenerService } from './task-due-date-listener-service';

export const DynamicDueDateServices = angular
  .module('dynamicDueDateServices', [])
  .service('DueDateRuleDefinitionValidationService', DueDateRuleDefinitionValidationService)
  .service('DynamicDueDateService', DynamicDueDateService)
  .service('TaskDueDateListenerService', TaskDueDateListenerService)
  .service('TaskDueDateService', TaskDueDateService).name;
