import { Muid } from '@process-street/subgrade/core';
import { Template, TemplateRevision, TemplateRevisionStatus, Widget } from '@process-street/subgrade/process';
import { useToast } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { UpdateTemplateMutation, UpdateTemplateMutationGenerics } from 'features/template/mutation-builder';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import debounce from 'lodash/debounce';
import { API, UpdateTemplateForm } from 'pages/pages/_id/edit/page/api';
import * as React from 'react';
import { QueryStatus, useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { HttpStatus } from '@process-street/subgrade/util';
import { templateReducer } from './utils/template-reducer';
import {
  GetNewestTemplateRevisionsByTemplateIdQuery,
  GetNewestTemplateRevisionsByTemplateIdQueryResponse,
  PublishDraftMutation,
} from 'features/template-revisions/query-builder';
import { GetTemplateQuery, useGetTemplateQuery } from 'features/template/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { match } from 'ts-pattern';
import { AxiosError } from 'axios';

export const WidgetsQueryKey = 'edit-page-widgets';

export const UpdateDebounce = 500;

export function useNewestTemplateRevisionQuery(
  {
    templateId,
    editable,
  }: {
    templateId: Muid | undefined;
    editable?: boolean;
  },
  options: UseQueryOptions<
    GetNewestTemplateRevisionsByTemplateIdQueryResponse,
    Error,
    TemplateRevision | undefined
  > = {},
) {
  return useQuery<GetNewestTemplateRevisionsByTemplateIdQueryResponse, Error, TemplateRevision | undefined>(
    GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId: templateId! }),
    () => GetNewestTemplateRevisionsByTemplateIdQuery.queryFn({ templateId: templateId! }),
    {
      ...options,
      enabled: Boolean(templateId) && (options.enabled ?? true),
      select: GetNewestTemplateRevisionsByTemplateIdQuery.select.where({
        status: editable ? TemplateRevisionStatus.Draft : TemplateRevisionStatus.Finished,
      }),
    },
  );
}

type PageState = 'loading' | 'editing' | 'publishing' | 'viewing';

export function usePageQuery(
  templateId: Muid,
  editable: boolean,
): {
  pageState: PageState;
  isLoading: boolean;
  widgetsStatus: QueryStatus;
  template?: Template;
  createDraftTmplRevision: () => void;
  updateTemplate: (form: UpdateTemplateForm) => void;
  templateRevision?: TemplateRevision;
  widgets: Widget[];
} {
  const queryClient = useQueryClient();
  const { $state } = useInjector('$state');
  const toast = useToast();

  const templateQuery = useGetTemplateQuery(
    { templateId },
    {
      onError: error => {
        toast.closeAll();
        switch (error.response?.status) {
          case HttpStatus.NOT_FOUND: {
            toast({
              status: 'error',
              title: "We're having problems loading the page",
              description: 'The page is no longer available.',
            });
            $state.go('dashboard');
            break;
          }
          case HttpStatus.FORBIDDEN: {
            toast({
              status: 'warning',
              title: `You don't have permission to view that page`,
              description: 'Please reach out to the page owner or an admin.',
            });
            $state.go('dashboard');
            break;
          }
          default: {
            toast({
              status: 'error',
              title: "We're having problems loading the page",
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
            $state.go('dashboard');
            break;
          }
        }
      },
      onSuccess: freshTemplate => {
        setTemplateState({ type: 'REFRESH', template: freshTemplate, mutationStatus: updateTemplateMutation.status });
      },
    },
  );

  // we're ejecting from react-query state here to allow seamless page name editing
  // we don't want the users interactions to be blocked by network latency
  // in this case, the staleness of the data should be relative to the user's form input
  const [{ template }, setTemplateState] = React.useReducer(templateReducer, {
    template: templateQuery.data,
    status: 'fresh',
  });

  React.useEffect(() => {
    if (templateQuery.data?.name === template?.name) {
      setTemplateState({ type: 'SET_STATUS', status: 'fresh' });
    }
  }, [templateQuery.data?.name, template?.name]);

  const updateTemplateMutation = useMutation<
    UpdateTemplateMutationGenerics['data'],
    UpdateTemplateMutationGenerics['error'],
    UpdateTemplateMutationGenerics['variables']
  >(request => UpdateTemplateMutation.mutationFn(template!.id, request), {
    mutationKey: UpdateTemplateMutation.key,
    onMutate: () => {
      // Cancel any outstanding queries
      queryClient.cancelQueries(GetTemplateQuery.getKey({ templateId }));
    },
    onSuccess: () => {
      queryClient.invalidateQueries(GetTemplateQuery.getKey({ templateId }));
    },
  });
  const updateTemplateDebounced = React.useRef(debounce(updateTemplateMutation.mutate, UpdateDebounce)).current;

  const updateTemplate = React.useCallback(
    (form: UpdateTemplateForm) => {
      setTemplateState({ type: 'UPDATE', form });
      updateTemplateDebounced(form);
    },
    [updateTemplateDebounced],
  );

  const createDraftTmplRevisionMutation: UseMutationResult<TemplateRevision, AxiosError, { templateId: Muid }> =
    useMutation(({ templateId }) => API.createDraft(templateId), {
      onSuccess: draftTmplRevision => {
        const queryKey = GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId });
        const data = queryClient.getQueryData<TemplateRevision[]>(queryKey);

        if (data) {
          // We have the finished template revision so can add the draft template revision
          queryClient.setQueryData(queryKey, [...data, draftTmplRevision]);
        } else {
          // We're missing the finished template revision so need to reload both
          queryClient.invalidateQueries(queryKey);
        }
      },
      onError: e => {
        switch (e.response?.status) {
          case HttpStatus.CONFLICT: {
            // If it's a conflict, it means someone else made a draft, so we just need to invalidate the query to fix it
            const queryKey = GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId });
            queryClient.invalidateQueries(queryKey);
            break;
          }
          default: {
            // Otherwise, it could be a fatal error, so we need to throw them back to the dashboard with an error
            toast({
              status: 'error',
              title: "We're having problems loading the page",
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
            $state.go('dashboard');
            break;
          }
        }
      },
    });

  const createDraftTmplRevision = React.useCallback(() => {
    if (createDraftTmplRevisionMutation.isIdle) {
      createDraftTmplRevisionMutation.mutate({ templateId });
    }
  }, [createDraftTmplRevisionMutation, templateId]);

  const templateRevisionQuery = useNewestTemplateRevisionQuery({ templateId, editable });

  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevisionQuery.data?.id ?? '', {
    enabled: !templateRevisionQuery.isLoading && Boolean(templateRevisionQuery.data?.id),
    refetchOnWindowFocus: false,
    staleTime: 1000, // Getting new widgets after publishing.
  });

  const publishMutation = queryClient.getMutationCache().find({ mutationKey: PublishDraftMutation.key });
  const pageStatePattern = {
    templateQueryStatus: templateQuery.status,
    templateRevisionQueryStatus: templateRevisionQuery.status,
    widgetsQueryStatus: widgetsQuery.status,
    publishMutationStatus: publishMutation?.state.status,
  };
  const pageState = match<typeof pageStatePattern, PageState>(pageStatePattern)
    .with(
      { templateQueryStatus: 'loading' },
      { templateRevisionQueryStatus: 'loading' },
      { widgetsQueryStatus: 'loading' },
      () => 'loading',
    )
    .with({ publishMutationStatus: 'loading' }, () => 'publishing')
    .otherwise(() => (editable ? 'editing' : 'viewing'));

  return React.useMemo(
    () => ({
      isLoading: pageState === 'loading',
      pageState,
      widgetsStatus: widgetsQuery.status,
      templateRevision: templateRevisionQuery.data,
      createDraftTmplRevision,
      widgets: widgetsQuery.data ?? [],
      template,
      updateTemplate,
    }),
    [
      pageState,
      widgetsQuery.status,
      widgetsQuery.data,
      templateRevisionQuery.data,
      createDraftTmplRevision,
      template,
      updateTemplate,
    ],
  );
}
