import { ExecuteAiPrompt, NativeAutomation } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';

export function isNativeAutomationPristine(nativeAutomation: NativeAutomation): boolean {
  return nativeAutomation.actions.every(action => {
    return match(action)
      .with({ actionType: 'ExecuteAiPrompt' }, action => {
        return match(action.config)
          .with({ promptType: 'Custom' }, ({ prompt }) => {
            return prompt.trim() === '';
          })
          .with(
            { promptType: P.union('Blog', 'SocialMedia', 'Newsletter', 'Summarize', 'Sentiment', 'Theme') },
            ({ promptType, systemPrompt, prompt }) => {
              const defaultConfig = ExecuteAiPrompt.PROMPT_CONFIGS_BY_TYPE[promptType];
              const trimmed = prompt.trim();
              const promptHasChanged = trimmed !== defaultConfig.prompt;

              const trimmedSystemPrompt = systemPrompt.trim();
              const systemPromptHasChanged = trimmedSystemPrompt !== defaultConfig.systemPrompt;

              return !(promptHasChanged || systemPromptHasChanged);
            },
          )
          .with({ promptType: 'Parse' }, ({ promptInputs, prompt, systemPrompt, promptType }) => {
            const defaultConfig = ExecuteAiPrompt.PROMPT_CONFIGS_BY_TYPE[promptType];

            const hasInputs = Object.values(promptInputs).some(v => Boolean(v.trim()));

            const trimmed = prompt.trim();
            const promptHasChanged = trimmed !== defaultConfig.prompt;

            const trimmedSystemPrompt = systemPrompt.trim();
            const systemPromptHasChanged = trimmedSystemPrompt !== defaultConfig.systemPrompt;

            return !(promptHasChanged || systemPromptHasChanged || hasInputs);
          })
          .otherwise(() => true);
      })
      .with({ actionType: 'UpdateFormFields' }, ({ config }) => {
        return Object.values(config.mapping).every(v => !v.trim());
      })
      .otherwise(() => true);
  });
}
