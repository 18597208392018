import { Muid } from '@process-street/subgrade/core';
import { DataSetRow, DataSetColumnState, SavedViewFilterOperator } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { QueryClient, useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import produce from 'immer';

export type GetDataSetRowsParams = {
  id: Muid;
  columns?: DataSetColumnState[];
  savedViewId?: Muid;
  filterOperator?: SavedViewFilterOperator;
};

export type GetDataSetRowsResponse = DataSetRow[];

// Keys that don't affect the rows query
const UI_ONLY_STATE_KEYS: (keyof Pick<DataSetColumnState, 'pinned' | 'width'>)[] = ['pinned', 'width'];

export const GetDataSetRowsQuery = {
  key: ['data-sets', 'rows'] as const,
  queryFn: (params: GetDataSetRowsParams) =>
    axiosService
      .getAxios()
      .put<GetDataSetRowsResponse>(`/1/data-sets/${params.id}/rows`, params)
      .then(res => res.data),
  // There are cases with this query where we don't want to refetch if the params change
  // E.g., appending a column
  getKey: ({ columns, ...params }: GetDataSetRowsParams) => {
    const filteredColumns = columns
      // cache the query based on meaninful column state
      ?.map(c =>
        _omitBy(c, (value, key) => {
          const keyIsUIOnly = UI_ONLY_STATE_KEYS.includes(key as typeof UI_ONLY_STATE_KEYS[number]);
          // omit undefined properties so an undefined sort is the same as no sort at all
          const valueIsUndefined = _isUndefined(value);
          return keyIsUIOnly || valueIsUndefined;
        }),
      );

    return [
      ...GetDataSetRowsQuery.key,
      {
        ...params,
        columns: filteredColumns,
      },
    ] as [...typeof GetDataSetRowsQuery.key, GetDataSetRowsParams];
  },
};

export const useGetDataSetRowsQuery = (
  params: GetDataSetRowsParams,
  options: UseQueryOptions<GetDataSetRowsResponse, AxiosError, GetDataSetRowsResponse> = {},
) => {
  return useQuery(GetDataSetRowsQuery.getKey(params) as QueryKey, () => GetDataSetRowsQuery.queryFn(params), options);
};

type GetDataSetRowsQueryKey = ReturnType<typeof GetDataSetRowsQuery.getKey>;

export const getDataSetRowsQueryKeysByDataSetId = ({
  dataSetId,
  queryClient,
}: {
  dataSetId: Muid;
  queryClient: QueryClient;
}): GetDataSetRowsQueryKey[] => {
  return queryClient
    .getQueryCache()
    .findAll(GetDataSetRowsQuery.key, {
      predicate: query => {
        const [, , params] = query.queryKey as ReturnType<typeof GetDataSetRowsQuery.getKey>;
        const { id } = params;
        return id === dataSetId;
      },
    })
    .map(q => q.queryKey as GetDataSetRowsQueryKey);
};

export function removeRowFromDataSetRowsCache({
  queryClient,
  rowId,
  dataSetId,
}: {
  rowId: Muid;
  dataSetId: Muid;
  queryClient: QueryClient;
}) {
  getDataSetRowsQueryKeysByDataSetId({ dataSetId, queryClient }).forEach(queryKey => {
    queryClient.setQueryData<GetDataSetRowsResponse | undefined>(queryKey, current => {
      if (!current) return current;
      return current.filter(r => rowId !== r.id);
    });
  });
}

export function appendRowToDataSetRowsCache({
  queryClient,
  row,
  dataSetId,
}: {
  row: DataSetRow;
  dataSetId: Muid;
  queryClient: QueryClient;
}) {
  getDataSetRowsQueryKeysByDataSetId({ dataSetId, queryClient }).forEach(queryKey => {
    queryClient.setQueryData<GetDataSetRowsResponse | undefined>(queryKey, current => {
      const sortState = queryKey[2].columns?.find(c => c.sort);
      if (!current) return current;
      if (sortState?.sort === 'desc') {
        return [row].concat(current);
      } else {
        return current.concat(row);
      }
    });
  });
}

export function addColumnToDataSetRowsCache({
  queryClient,
  columnId,
  index,
  dataSetId,
}: {
  columnId: Muid;
  index: number;
  dataSetId: Muid;
  queryClient: QueryClient;
}) {
  getDataSetRowsQueryKeysByDataSetId({ dataSetId, queryClient }).forEach(queryKey => {
    const currentData = queryClient.getQueryData<GetDataSetRowsResponse | undefined>(queryKey);

    const newKey = produce(queryKey, draft => {
      draft[2].columns?.splice(index, 0, { id: columnId, filters: [], hide: false });
    });

    const newData = currentData?.map(row => {
      return { ...row, cells: { ...row.cells, [columnId]: { value: '' } } };
    });

    queryClient.setQueryData<GetDataSetRowsResponse | undefined>(newKey, newData);
  });
}

export function removeColumnFromDataSetRowsCache({
  queryClient,
  columnId,
  dataSetId,
}: {
  columnId: Muid;
  dataSetId: Muid;
  queryClient: QueryClient;
}) {
  getDataSetRowsQueryKeysByDataSetId({ dataSetId, queryClient }).forEach(key => {
    const columnHasSort = key[2].columns?.find(c => c.id === columnId && Boolean(c.sort));
    // refetch the rows if there is a sort instead of setting query data
    if (columnHasSort) return;

    const current = queryClient.getQueryData<GetDataSetRowsResponse | undefined>(key);
    if (!current) return;
    const modifiedKey = produce(key, draft => {
      draft[2].columns = draft[2].columns?.filter(c => c.id !== columnId);
    });

    const modifiedData = current?.map(row => {
      const { [columnId]: _, ...cells } = row.cells;
      return { ...row, cells };
    });

    queryClient.setQueryData<GetDataSetRowsResponse | undefined>(modifiedKey, modifiedData);
  });
}

export function updateCellValueInRowsCache({
  queryClient,
  columnId,
  dataSetId,
  rowId,
  value,
}: {
  columnId: Muid;
  dataSetId: Muid;
  rowId: Muid;
  value: string;
  queryClient: QueryClient;
}) {
  getDataSetRowsQueryKeysByDataSetId({ dataSetId, queryClient }).forEach(key => {
    queryClient.setQueryData<GetDataSetRowsResponse | undefined>(key, current => {
      if (!current) return current;
      return current.map(row => {
        if (row.id === rowId) {
          return { ...row, cells: { ...row.cells, [columnId]: { value } } };
        }
        return row;
      });
    });
  });
}
