import { PlanLevel } from '@process-street/subgrade/billing';
import React from 'react';
import { PlanLevelSelectorState } from '../../../directives/billing/plan-level-selector/plan-level-selector-service.interface';
import './PlanSelectButton.scss';

interface PlanSelectButtonProps {
  level: PlanLevel;
  selectorState: PlanLevelSelectorState;
  onSelect: (level: PlanLevel) => void;
}

const getButtonClass = (level: PlanLevel, selected: boolean) =>
  selected ? `plan-select-button__selected-level-${level.toLowerCase()}` : '';

const handleSelect = (level: PlanLevel, onSelect: (level: PlanLevel) => void) => () => onSelect(level);

export const PlanSelectButton: React.FunctionComponent<React.PropsWithChildren<PlanSelectButtonProps>> = ({
  level,
  selectorState,
  onSelect,
}) => {
  const selected = selectorState === PlanLevelSelectorState.SELECTED_PLAN;
  const current = selectorState === PlanLevelSelectorState.CURRENT_PLAN;

  return (
    <div className="plan-select-button">
      <button
        type="button"
        className={getButtonClass(level, selected)}
        disabled={current}
        onClick={handleSelect(level, onSelect)}
      >
        {selectorState}
      </button>
    </div>
  );
};
