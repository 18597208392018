import { ChecklistMode } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useCreateChecklistMutation } from 'features/checklists/query-builder/create-checklist-mutation';
import { useToast } from 'components/design/next';
import { useNavigate } from '@process-street/adapters/navigation';
import qs from 'qs';

export const SANDBOX_PREVIEWER_PARAMS = 'sandboxPreviewerParams';
export const SANDBOX_TEMPLATE_NAME_PREFIX = '[Preview]';

export const useCreateSandboxAndRedirect = ({
  templateName,
  templateId,
  openInNewTab = false,
}: {
  templateName: string | undefined;
  templateId?: string | undefined;
  openInNewTab?: boolean;
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const createChecklistMutation = useCreateChecklistMutation({
    onSuccess: checklist => {
      toast({
        status: 'success',
        title: 'Preview created!',
      });
      // Open Sandbox in a new tab
      if (openInNewTab) {
        window.open(`/preview/runs/${checklist.id}`, '_blank', 'noopener noreferrer')?.focus();
        sessionStorage.removeItem(SANDBOX_PREVIEWER_PARAMS);
      } else {
        navigate({ pathname: 'sandboxChecklist', search: qs.stringify({ id: checklist.id }) }, {});
      }
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems running this workflow",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const runChecklistAndRedirect = () => {
    if (!templateId || !templateName) {
      return;
    }

    toast({
      status: 'info',
      title: 'Please wait while we generate your preview for this Workflow...',
    });

    createChecklistMutation.mutate({
      templateId,
      name: `${SANDBOX_TEMPLATE_NAME_PREFIX} ${templateName}`,
      mode: ChecklistMode.Sandbox,
    });
  };
  return runChecklistAndRedirect;
};
