import { IconName } from '@fortawesome/fontawesome-common-types';
import { Box, BoxProps, HStack, Icon, Text, Tooltip } from 'components/design/next';
import React from 'react';
import { useFolderPickerModalContext } from './modal';

export type FolderPickerItemProps = {
  name: string;
  writeable: boolean;
  description?: string;
  icon?: IconName;
} & BoxProps;

export const FolderPickerItem: React.FC<React.PropsWithChildren<FolderPickerItemProps>> = ({
  name,
  description,
  writeable,
  icon = 'folder',
  ...props
}) => {
  const { verb } = useFolderPickerModalContext();

  return (
    <HStack py="4" textAlign="left" minWidth="0" {...props}>
      <Icon icon={icon} color="gray.500" variant="fas" size="4" mr="2" />
      <Box w="full">
        <Text noOfLines={1}>{name}</Text>
        <Text color="gray.500" variant="-2" fontWeight="initial">
          {description}
        </Text>
      </Box>
      {!writeable ? (
        <Tooltip
          label={`You don't have permission to ${verb} to this folder`}
          hasArrow={true}
          fontSize="xs"
          placement="top"
        >
          <Text as="span">
            <Icon variant="fas" icon="lock" color="gray.300" size="4" mx="2" />
          </Text>
        </Tooltip>
      ) : null}
    </HStack>
  );
};
