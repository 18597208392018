import angular from 'angular';
import { loadingToast } from 'components/design/next/toast/loading';
import { PrintImageService } from 'features/print/print-image-service';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'TemplatePrintCtrl',
    function (
      $q,
      $scope,
      $state,
      $timeout,
      $window,
      OrganizationService,
      TaskTemplateService,
      TemplateRevisionService,
      ToastService,
      WidgetService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'PrintChecklistCtrl' });

      ctrl.$onInit = () => {
        const templateId = $state.params.id;
        const request = TemplateRevisionService.getAllNewestByTemplateId(templateId)
          .then(revisions => {
            [$scope.templateRevision] = revisions;

            $window.document.title = `${$scope.templateRevision.template.name} | Process Street`;

            return $q
              .all({
                taskTemplates: TaskTemplateService.getAllByTemplateRevisionId($scope.templateRevision.id),
                widgets: WidgetService.getAllByTemplateRevisionId($scope.templateRevision.id),
                organization: OrganizationService.getById($scope.templateRevision.organization.id),
              })
              .then(responses => {
                $scope.taskTemplates = responses.taskTemplates;
                $scope.taskTemplates.forEach(taskTemplate => {
                  taskTemplate.name = taskTemplate.name || TaskTemplateService.DEFAULT_NAME;
                });

                $scope.widgetsMap = ctrl.generateWidgetsMap(responses.widgets);

                $scope.organization = responses.organization;
              });
          })
          .catch(error => {
            $scope.failed = true;
            logger.log(error);
            ToastService.openToast({
              title: 'Rats!',
              description: "We weren't able to generate the print preview. Please try again later.",
              status: 'error',

              onCloseComplete: () => {
                $window.close();
              },
            });

            return $q.reject();
          });

        ctrl.initializeSpinner(request);
      };

      ctrl.generateWidgetsMap = function (widgets) {
        const widgetsMap = {};

        widgets.forEach(widget => {
          if (!WidgetService.isEmpty(widget)) {
            const groupId = widget.header.taskTemplate.group.id;
            widgetsMap[groupId] = widgetsMap[groupId] || [];
            widgetsMap[groupId].push(widget);
          }
        });

        angular.forEach(widgetsMap, WidgetService.sortWidgets);

        return widgetsMap;
      };

      ctrl.initializeSpinner = function (request) {
        const toastId = ToastService.openToast({
          duration: null,
          render: loadingToast({
            title: 'Please wait...',
            description: 'Generating print preview.',
          }),
          onCloseComplete: () => {
            $timeout(() => {
              $window.print();
            }, 1000);
          },
        });

        onPageLoad(() => {
          request.then(
            () => {
              PrintImageService.whenAllImagesLoaded(() => {
                ToastService.closeToast(toastId);
              });
            },
            () => {
              ToastService.closeToast(toastId);
            },
          );
        });
      };

      $scope.isHeading = TaskTemplateService.isHeading;

      $scope.isApproval = TaskTemplateService.isApproval;

      function onPageLoad(callback) {
        // We need to check this first because the load event might have already finished
        if ($window.document.readyState === 'complete') {
          callback();
        } else {
          $window.addEventListener('load', callback);
        }
      }
    },
  );
