import { FieldType, TaskStatus } from '@process-street/subgrade/process';
import { DateUtils, escapeHtml as esc, unescapeHtml as unesc } from '@process-street/subgrade/util';
import angular from 'angular';
import { ObjectType } from 'services/activities/object-type';
import { Verb } from 'services/activities/verb';
import { match } from 'ts-pattern';
import { sprintf } from 'sprintf-js';
import { StringService } from 'services/string-service';
import { trace } from 'components/trace';
import { FormFieldStringService } from 'features/activities/services/form-field-string-service';

angular
  .module('frontStreetApp.services')
  .service('ChecklistActivityService', function ($q, Activities, ActivityService) {
    const self = this;

    const logger = trace({ name: 'ChecklistActivityService' });

    const abbr = StringService.abbreviate;
    const { valuateData } = ActivityService;
    const spf = sprintf;

    function interpretChecklistAssignment(activity) {
      let sentence = ActivityService.interpret(activity);
      switch (activity.verb) {
        case Verb.CREATED: {
          sentence.verb.value = 'assigned';
          const userId = activity.data?.user?.id;
          const username = activity.data?.user?.username || 'Someone';
          sentence.objects.push({
            value: spf('<a>%s</a>', esc(abbr(username))),
            tooltip: username,
            type: 'User',
            id: userId,
          });
          sentence.objects.push({ value: 'to workflow run' });
          break;
        }
        case Verb.DELETED: {
          sentence.verb.value = 'unassigned';
          const userId = activity.data?.user?.id;
          const username = activity.data?.user?.username || 'Someone';
          sentence.objects.push({
            value: spf('<a>%s</a>', esc(abbr(username))),
            tooltip: username,
            type: 'User',
            id: userId,
          });
          sentence.objects.push({ value: 'from workflow run' });
          break;
        }
        default:
          logger.error('unrecognized verb: %s', activity.verb);
          sentence = undefined;
      }
      return sentence;
    }

    function interpretChecklist(activity) {
      let sentence = ActivityService.interpret(activity);
      switch (activity.verb) {
        case Verb.CREATED:
          sentence.verb.value = 'created';
          sentence.objects.push({ value: 'workflow run' });
          break;
        case Verb.DELETED:
          sentence.verb.value = 'deleted';
          sentence.objects.push({ value: 'workflow run' });
          break;
        case Verb.RESTORED:
          sentence.verb.value = 'restored';
          sentence.objects.push({ value: 'workflow run' });
          break;
        case Verb.UPDATED:
          switch (activity.data.status) {
            case 'Active': {
              let verbValue = 'unarchived';
              if (activity.data.previousStatus && activity.data.previousStatus === 'Completed') {
                verbValue = 'reactivated';
              }
              sentence.verb.value = verbValue;
              sentence.objects.push({ value: 'workflow run' });
              break;
            }
            case 'Archived':
              sentence.verb.value = 'archived';
              sentence.objects.push({ value: 'workflow run' });
              break;
            case 'Completed':
              sentence.verb.value = 'completed';
              sentence.objects.push({ value: 'workflow run' });
              break;
            case 'Deleted':
              sentence.verb.value = 'deleted';
              sentence.objects.push({ value: 'workflow run' });
              break;
            default:
              sentence.verb.value = 'changed';
              sentence.objects.push({ value: 'workflow run' });
              sentence.objects.push(valuateData(activity.data));
          }
          break;
        default:
          logger.error('unrecognized verb: %s', activity.verb);
          sentence = undefined;
      }
      return sentence;
    }

    function interpretChecklistRevision(activity) {
      let sentence = ActivityService.interpret(activity);
      switch (activity.verb) {
        case Verb.UPDATED:
          switch (activity.data.status) {
            case 'Migrated':
              sentence.verb.value = 'updated';
              sentence.objects.push({ value: 'checklist to latest version' });
              break;
            default:
              sentence.verb.value = 'changed';
              sentence.objects.push({ value: 'workflow run' });
              sentence.objects.push(valuateData(activity.data));
          }
          break;
        default:
          logger.error('unrecognized verb: %s', activity.verb);
          sentence = undefined;
      }
      return sentence;
    }

    function interpretTasks(activity) {
      let sentence = ActivityService.interpret(activity);
      switch (activity.verb) {
        case Verb.UPDATED:
          switch (activity.data.status) {
            case TaskStatus.Completed:
              sentence.verb.value = 'checked all';
              sentence.objects.push({ value: 'in workflow run' });
              break;
            case TaskStatus.NotCompleted:
              sentence.verb.value = 'unchecked all';
              sentence.objects.push({ value: 'in workflow run' });
              break;
            default:
              logger.error('unrecognized task status: %s', activity.data.status);
              sentence = undefined;
          }
          break;
        default:
          logger.error('unrecognized verb: %s', activity.verb);
          sentence = undefined;
      }
      return sentence;
    }

    function interpretTask(activity) {
      let sentence = ActivityService.interpret(activity);
      const taskTemplateName = activity.data.taskTemplateName ?? activity.object.taskTemplate.name;
      const base = {
        tooltip: taskTemplateName,
        type: activity.objectType,
        id: activity.object.id,
      };
      match(activity)
        .with({ verb: Verb.UPDATED, data: { activityType: 'UpdateDueDate' } }, () => {
          const dueDate = activity.data.dueDate && DateUtils.formatActivityTime({ date: activity.data.dueDate });
          sentence.verb.value = dueDate ? `set the due date to ${dueDate}` : 'removed the due date';
          sentence.objects.push(
            angular.extend(base, {
              value: spf('in <a>task</a> "%s"', esc(abbr(taskTemplateName))),
            }),
          );
        })
        .with({ verb: Verb.UPDATED }, () => {
          switch (activity.data.status) {
            case TaskStatus.Completed:
              sentence.verb.value = 'checked';
              sentence.objects.push(
                angular.extend(base, {
                  value: spf('a <a>task</a> "%s"', esc(abbr(taskTemplateName))),
                }),
              );
              break;
            case TaskStatus.NotCompleted:
              sentence.verb.value = 'unchecked';
              sentence.objects.push(
                angular.extend(base, {
                  value: spf('a <a>task</a> "%s"', esc(abbr(taskTemplateName))),
                }),
              );
              break;
            default:
              logger.error('unrecognized task status: %s', activity.data.status);
              sentence = undefined;
          }
        })
        .with({ verb: Verb.APPROVED }, () => {
          sentence.verb.value = 'approved';
          sentence.objects.push(
            angular.extend(base, {
              value: spf('a <a>task</a> "%s"', esc(abbr(taskTemplateName))),
            }),
          );
        })
        .with({ verb: Verb.REJECTED }, () => {
          sentence.verb.value = 'rejected';
          sentence.objects.push(
            angular.extend(base, {
              value: spf('a <a>task</a> "%s"', esc(abbr(taskTemplateName))),
            }),
          );
          if (activity.data?.comment) {
            sentence.objects.push({
              value: spf('saying "%s"', esc(abbr(unesc(activity.data.comment)))),
              tooltip: activity.data.comment,
            });
          }
        })
        .otherwise(() => {
          logger.error('unrecognized verb: %s', activity.verb);
          sentence = undefined;
        });

      return sentence;
    }

    function interpretComment(activity, extended) {
      const taskTemplateName = activity.data.taskTemplateName ?? activity.object.task.taskTemplate.name;

      let sentence = ActivityService.interpret(activity);
      const base = {
        type: activity.objectType,
        id: activity.object.id,
      };
      switch (activity.verb) {
        case Verb.CREATED:
          sentence.verb.value = 'posted';
          sentence.objects.push(angular.extend({}, base, { value: 'a <a>comment</a>' }));
          sentence.objects.push(
            angular.extend({}, base, {
              value: spf('to <a>task</a> "%s"', esc(abbr(taskTemplateName))),
              tooltip: taskTemplateName,
            }),
          );
          if (extended) {
            sentence.quote = activity.data.content;
          } else {
            sentence.objects.push({
              value: spf('saying "%s"', esc(abbr(unesc(activity.data.content)))),
              tooltip: activity.data.content,
            });
          }
          break;
        case Verb.UPDATED:
          sentence.verb.value = 'deleted';
          sentence.objects.push(
            angular.extend({}, base, {
              value: spf('a comment from <a>task</a> "%s"', esc(abbr(taskTemplateName))),
              tooltip: taskTemplateName,
            }),
          );
          sentence.objects.push({
            value: spf('saying "%s"', esc(abbr(unesc(activity.data.content)))),
            tooltip: activity.data.content,
          });
          break;
        default:
          logger.error('unrecognized verb: %s', activity.verb);
          sentence = undefined;
      }
      return sentence;
    }

    function interpretAttachment(activity, extended) {
      const taskTemplateName = activity.data.taskTemplateName ?? activity.object.task.taskTemplate.name;
      const originalName = activity.data.fileOriginalName ?? activity.object.file.originalName;

      let sentence = ActivityService.interpret(activity);
      const base = {
        type: activity.objectType,
        id: activity.object.id,
      };
      switch (activity.verb) {
        case Verb.CREATED:
          sentence.verb.value = 'attached';
          sentence.objects.push(
            angular.extend({}, base, {
              value: spf('a file to <a>task</a> "%s"', esc(abbr(taskTemplateName))),
              tooltip: taskTemplateName,
            }),
          );
          sentence.objects.push({
            value: spf('named "%s"', esc(abbr(unesc(originalName)))),
            tooltip: originalName,
          });
          if (extended) {
            sentence.file = activity.object.file;
          }
          break;
        case Verb.UPDATED:
          sentence.verb.value = 'deleted';
          sentence.objects.push({
            value: spf('an attachment from <a>task</a> "%s"', esc(abbr(taskTemplateName))),
            tooltip: taskTemplateName,
          });
          sentence.objects.push({
            value: spf('named "%s"', esc(abbr(unesc(originalName)))),
            tooltip: originalName,
          });
          break;
        default:
          logger.error('unrecognized verb: %s', activity.verb);
          sentence = undefined;
      }
      return sentence;
    }

    function interpretFormFieldValue(activity) {
      const fieldType = activity.data.fieldType ?? activity.data.formFieldWidgetFieldType;
      return (
        match(fieldType)
          // Regular form fields
          .with(FieldType.Date, () => interpretSimpleFormFieldValue(activity))
          .with(FieldType.Email, () => interpretSimpleFormFieldValue(activity))
          .with(FieldType.File, () => interpretSimpleFormFieldValue(activity))
          .with(FieldType.Hidden, () => interpretSimpleFormFieldValue(activity))
          .with(FieldType.Members, () => interpretSimpleFormFieldValue(activity))
          .with(FieldType.MultiChoice, () => interpretSimpleFormFieldValue(activity))
          .with(FieldType.Number, () => interpretSimpleFormFieldValue(activity))
          .with(FieldType.Select, () => interpretSimpleFormFieldValue(activity))
          .with(FieldType.Table, () => interpretSimpleFormFieldValue(activity))
          .with(FieldType.Text, () => interpretSimpleFormFieldValue(activity))
          .with(FieldType.Textarea, () => interpretSimpleFormFieldValue(activity))
          .with(FieldType.Url, () => interpretSimpleFormFieldValue(activity))
          // Content-like form fields
          .with(FieldType.MultiSelect, () => interpretMultiSelectFormFieldValue(activity))
          .with(FieldType.SendRichEmail, () => interpretSendRichEmailFormFieldValue(activity))
          .otherwise(() => {
            throw new Error(`Field Type [${fieldType}] is not supported.`);
          })
      );
    }

    function interpretMultiSelectFormFieldValue(activity) {
      const itemsUpdated = activity?.data?.itemsUpdated;
      if (!itemsUpdated) {
        logger.error('form field activity does not have an itemsUpdated object');
        return undefined;
      }

      const { checklist, taskTemplate } = activity.data;

      let sentence = ActivityService.interpret(activity);

      const base = {
        type: 'ChecklistTask',
        id: `${checklist.id}+${taskTemplate.group.id}`,
      };

      function generateObject(item, niceStatus, conjoined) {
        return angular.extend({}, base, {
          tooltip: item.name,
          value: spf('<a>subtask</a> "%s" as %s%s', esc(abbr(item.name)), niceStatus, conjoined ? ',' : ''),
        });
      }

      switch (activity.verb) {
        case Verb.UPDATED:
          if (itemsUpdated.length > 0) {
            sentence.verb.value = 'marked';
            itemsUpdated.forEach((item, i) => {
              // If the sentence has been set to undefined, skip the rest of the iterations
              if (!sentence) {
                return;
              }
              const itemIsLast = itemsUpdated.length - 1 === i;
              switch (item.newData.status) {
                case TaskStatus.Completed:
                  sentence.objects.push(generateObject(item, 'completed', !itemIsLast));
                  break;

                case TaskStatus.NotCompleted:
                  sentence.objects.push(generateObject(item, 'not completed', !itemIsLast));
                  break;

                default:
                  logger.error('unrecognized task status: %s', item.newData.status);
                  sentence = undefined;
              }
            });
            sentence.objects.push(
              angular.extend({}, base, {
                value: spf('in <a>task</a> "%s"', esc(abbr(taskTemplate.name))),
              }),
            );
          } else {
            sentence = undefined;
          }
          break;

        default:
          logger.error('unrecognized verb: %s', activity.verb);
          sentence = undefined;
      }

      return sentence;
    }

    function interpretSendRichEmailFormFieldValue(activity) {
      const { checklistId, taskTemplate, subject } = activity.data;

      const sentence = ActivityService.interpret(activity);
      sentence.verb.value = 'sent';

      const sentenceObject = {
        type: 'ChecklistTask',
        id: `${checklistId}+${taskTemplate.group.id}`,
        tooltip: subject,
        value: spf(
          '<a>an email</a> "%s" in <a>task</a> "%s"',
          esc(abbr(subject ?? '<no subject>')),
          esc(abbr(taskTemplate.name)),
        ),
      };
      sentence.objects.push(sentenceObject);

      return sentence;
    }

    function interpretSimpleFormFieldValue(activity) {
      const {
        checklistId,
        taskTemplateGroupId,
        taskTemplateName,
        formFieldWidgetFieldType,
        formFieldWidgetConfig,
        formFieldWidgetLabel,
        fieldValueContext,
        fieldValue,
      } = activity.data;

      const sentence = ActivityService.interpret(activity);

      const value = match(formFieldWidgetFieldType)
        .with(FieldType.Date, () => FormFieldStringService.convertDateToString(fieldValue, fieldValueContext))
        .with(FieldType.File, () => FormFieldStringService.convertFileToString(fieldValue))
        .with(FieldType.Members, () => FormFieldStringService.convertMembersToString(fieldValueContext))
        .with(FieldType.MultiChoice, () =>
          FormFieldStringService.convertMultiChoiceToString(fieldValue, formFieldWidgetConfig),
        )
        .with(FieldType.Table, () => FormFieldStringService.convertTableToString(fieldValue, formFieldWidgetConfig))
        .otherwise(() => fieldValue.value);

      switch (activity.verb) {
        case Verb.UPDATED:
          sentence.verb.value = 'set';
          sentence.objects.push({
            type: 'ChecklistTask',
            id: `${checklistId}+${taskTemplateGroupId}`,
            tooltip: value,
            value: spf(
              'the value of <a>form field</a> %s to "%s" in <a>task</a> "%s"',
              esc(abbr(formFieldWidgetLabel)),
              esc(abbr(value)),
              esc(abbr(taskTemplateName)),
            ),
          });
          break;
        case Verb.DELETED:
          sentence.verb.value = 'deleted';
          sentence.objects.push({
            type: 'ChecklistTask',
            id: `${checklistId}+${taskTemplateGroupId}`,
            tooltip: value,
            value: spf(
              'the value of <a>form field</a> %s in <a>task</a> "%s"',
              esc(abbr(formFieldWidgetLabel)),
              esc(abbr(value)),
              esc(abbr(taskTemplateName)),
            ),
          });
          break;
      }

      return sentence;
    }

    function interpretChecklistTaskAssignment(activity) {
      let sentence = ActivityService.interpret(activity);
      const { data } = activity;

      switch (activity.verb) {
        case Verb.CREATED:
          sentence.verb.value = 'assigned';
          sentence.objects.push({
            value: spf('<a>task</a> "%s"', esc(abbr(data.taskTemplate.name))),
            tooltip: data.taskTemplate.name,
            type: 'Task',
            id: data.task.id,
          });
          sentence.objects.push({
            value: spf('to <a>%s</a>', esc(abbr(data.assignee.username))),
            tooltip: data.assignee.username,
            type: 'User',
            id: data.assignee.id,
          });
          break;
        case Verb.DELETED:
          sentence.verb.value = 'unassigned';
          sentence.objects.push({
            value: spf('<a>%s</a>', esc(abbr(data.assignee.username))),
            tooltip: data.assignee.username,
            type: 'User',
            id: data.assignee.id,
          });
          sentence.objects.push({
            value: spf('from <a>task</a> "%s"', esc(abbr(data.taskTemplate.name))),
            tooltip: data.taskTemplate.name,
            type: 'Task',
            id: data.task.id,
          });
          break;
        default:
          logger.error('unrecognized verb: %s', activity.verb);
          sentence = undefined;
      }
      return sentence;
    }

    function interpretManyChecklistTaskAssignments(activity) {
      let sentence = ActivityService.interpret(activity);

      const tooltip = activity.data.assignments
        .map(a => spf('%s to "%s"', a.assignee.username, abbr(a.taskTemplate.name)))
        .join(', ');

      switch (activity.verb) {
        case Verb.CREATED:
          sentence.verb.value = 'assigned';
          sentence.objects.push({
            value: 'one or more tasks',
            tooltip,
          });
          break;
        default:
          logger.error('unrecognized verb: %s', activity.verb);
          sentence = undefined;
      }
      return sentence;
    }

    function interpret(activity, extended) {
      let sentence;
      try {
        switch (activity.objectType) {
          case ObjectType.CHECKLIST_ASSIGNMENT:
            sentence = interpretChecklistAssignment(activity);
            break;
          case ObjectType.CHECKLIST:
            sentence = interpretChecklist(activity);
            break;
          case ObjectType.CHECKLIST_REVISION:
            sentence = interpretChecklistRevision(activity);
            break;
          case ObjectType.TASKS:
            sentence = interpretTasks(activity);
            break;
          case ObjectType.TASK:
            sentence = interpretTask(activity);
            break;
          case ObjectType.COMMENT:
            sentence = interpretComment(activity, extended);
            break;
          case ObjectType.ATTACHMENT:
            sentence = interpretAttachment(activity, extended);
            break;
          case ObjectType.FORM_FIELD_VALUE:
            sentence = interpretFormFieldValue(activity);
            break;
          case ObjectType.CHECKLIST_TASK_ASSIGNMENT:
            sentence = interpretChecklistTaskAssignment(activity);
            break;
          case ObjectType.MANY_CHECKLIST_TASK_ASSIGNMENTS:
            sentence = interpretManyChecklistTaskAssignments(activity);
            break;
          default:
            logger.error('unrecognized object type: %s', activity.objectType);
        }
      } catch (e) {
        logger.error('an error occurred interpreting the activity data', e);
      }
      return sentence;
    }

    self.interpretSimple = function (activity) {
      return interpret(activity, false);
    };

    self.interpretExtended = function (activity) {
      return interpret(activity, true);
    };

    self.getActivitiesByIdLimitAndOffsetId = function (checklistId, limit, offsetId) {
      return Activities.getAll({
        type: 'checklist',
        checklistId,
        limit,
        offsetId,
      }).$promise.then(
        result => {
          logger.info('succeeded to query %d checklist activity(s)', result.data.length);
          return result;
        },
        response => {
          logger.error('failed to query checklist activities. Reason: %s', JSON.stringify(response));
          return $q.reject(response);
        },
      );
    };
  });
