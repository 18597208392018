import { PlanInterval, PlanTrack } from '@process-street/subgrade/billing';
import {
  isOrganizationMembershipActive,
  OrganizationMembership,
  OrganizationMembershipRole,
  SubscriptionStatus,
  User,
} from '@process-street/subgrade/core';
import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
  VStack,
  AlertDialogCloseButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
  ButtonGroup,
} from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { useInvalidateCanUseOrganization } from 'components/paywalls/trial-expired/query';
import { useRoleButtonWidth } from 'hooks/use-role-button-width';
import { DowngradeDialog } from 'pages/organizations/manage/users/components/downgrade-dialog';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { useOnPlanTrack } from 'services/use-plan-track';
import { getRoleDescription, isPromoting, Roles, RolesMap } from './model';
import { selector } from './selector';

export type RoleSelectorProps = {
  organizationMembership: OrganizationMembership;
};

enum Status {
  Idle,
  Updating,
  Demote,
  Promote,
}

type RoleTitleProps = {
  isSelected: boolean;
};
const RoleTitle: React.FC<React.PropsWithChildren<RoleTitleProps>> = ({ isSelected, children }) => {
  if (isSelected) {
    return (
      <HStack justify="space-between" alignItems="center">
        <Text fontWeight="medium">{children}</Text>
        <Icon size="4" icon="check" variant="far" />
      </HStack>
    );
  }

  return <Text fontWeight="medium">{children}</Text>;
};

export const RoleSelector: React.FC<React.PropsWithChildren<RoleSelectorProps>> = ({ organizationMembership }) => {
  const { OrganizationMembershipRoleService } = useInjector('OrganizationMembershipRoleService');
  const { invalidateCanUseOrganization } = useInvalidateCanUseOrganization();

  const menuDisclosure = useDisclosure();
  const downgradeDisclosure = useDisclosure();
  const [selectedRole, setSelectedRole] = useState<OrganizationMembershipRole>(organizationMembership.role);
  const [status, setStatus] = useState(Status.Idle);
  const [targetRole, setTargetRole] = useState<OrganizationMembershipRole>(organizationMembership.role);
  const state = useSelector(selector);
  const freemiumTrack = useOnPlanTrack(PlanTrack.Freemium);
  const roleButtonWidth = useRoleButtonWidth(selectedRole);
  const isTrialing = state.organization?.subscription.status === SubscriptionStatus.Trialing;

  const isCurrentUser = state.currentOrganizationMembership?.id === organizationMembership.id;
  const userName = (organizationMembership.user as User).username;
  const selectedRoleTitle = RolesMap[selectedRole].title;
  const targetRoleTitle = RolesMap[targetRole].title;

  useEffect(() => {
    setSelectedRole(organizationMembership.role);
  }, [organizationMembership.role]);

  const [, switchRole] = useAsyncFn(
    async (role: OrganizationMembershipRole) => {
      setStatus(Status.Updating);

      OrganizationMembershipRoleService.switchRole({
        organizationMembership,
        role,
        legacy: false,
        freemiumTrack,
      })
        .then(() => {
          invalidateCanUseOrganization();
          setSelectedRole(role);
        })
        .finally(() => {
          setStatus(Status.Idle);
        });
    },
    [OrganizationMembershipRoleService, organizationMembership, freemiumTrack, invalidateCanUseOrganization],
  );

  const handleDialogConfirm = () => {
    switchRole(targetRole);
    downgradeDisclosure.onClose();
  };

  const switchToTargetRole = React.useCallback(() => {
    if (targetRole) {
      switchRole(targetRole);
    }
  }, [switchRole, targetRole]);

  const handleChange = (role: OrganizationMembershipRole) => () => {
    const promoting = isPromoting(selectedRole, role);
    setTargetRole(role);
    switch (role) {
      case OrganizationMembershipRole.Guest: {
        setStatus(Status.Demote);
        return;
      }
      case OrganizationMembershipRole.FreeMember: {
        if (!promoting) {
          downgradeDisclosure.onOpen();
          return;
        }
        break;
      }
      case OrganizationMembershipRole.Admin:
      case OrganizationMembershipRole.FullMember: {
        if (
          state.shouldShowAdditionalCost &&
          !isTrialing &&
          [OrganizationMembershipRole.FreeMember, OrganizationMembershipRole.Guest].includes(selectedRole)
        ) {
          setStatus(Status.Promote);
          return;
        }
      }
    }
    switchRole(role);
  };

  const closeAlert = React.useCallback(() => {
    setStatus(Status.Idle);
  }, []);

  const selectorDisabled = !state.userIsAdmin || isCurrentUser;
  const cancelDemoteRef = React.useRef<HTMLButtonElement>(null);
  const cancelPromoteRef = React.useRef<HTMLButtonElement>(null);
  const planLevel = state.currentPlan?.level;
  const interval = state.currentPlan?.interval === PlanInterval.Monthly ? 'month' : 'year';

  return (
    <>
      <DowngradeDialog
        organizationMembershipId={organizationMembership.id}
        username={userName}
        isOpen={downgradeDisclosure.isOpen}
        onConfirm={handleDialogConfirm}
        onClose={downgradeDisclosure.onClose}
      />
      {status === Status.Updating && <Spinner sx={{ color: 'brand.600' }} />}
      {status === Status.Idle && selectorDisabled && <Text color="gray.500">{selectedRoleTitle}</Text>}
      {status === Status.Idle && !selectorDisabled && (
        <Menu autoSelect={false} isLazy={true} {...menuDisclosure}>
          <MenuButton
            as={Button}
            variant="unstyled"
            rightIcon={<Icon icon="angle-down" size="4" variant="far" />}
            title={selectedRoleTitle}
            size="sm"
            color="gray.600"
            _focus={{ boxShadow: 'none' }}
            w={roleButtonWidth}
            textAlign="right"
            display="inline-flex"
            alignItems="center"
            isDisabled={!isOrganizationMembershipActive(organizationMembership)}
          >
            {selectedRoleTitle}
          </MenuButton>

          {menuDisclosure.isOpen ? (
            <MenuList color="gray.600" right={0} width="295px" zIndex={'dropdown'}>
              {Roles.map(role => (
                <MenuItem
                  onClick={handleChange(role)}
                  key={role}
                  data-item-selected={role === selectedRole}
                  isDisabled={role === selectedRole}
                >
                  <HStack w="full" spacing="2">
                    <VStack w="full" align="left" spacing="0">
                      <RoleTitle isSelected={role === selectedRole}>{RolesMap[role].title}</RoleTitle>
                      <Text fontSize="xs" color="gray.500" data-item-description={true}>
                        {getRoleDescription(role, state.currentPlan)}
                      </Text>
                    </VStack>
                  </HStack>
                </MenuItem>
              ))}
            </MenuList>
          ) : null}
        </Menu>
      )}

      {(() => {
        if (!targetRole) return;
        switch (status) {
          case Status.Demote: {
            return (
              <AlertDialog isOpen={true} leastDestructiveRef={cancelDemoteRef} onClose={closeAlert}>
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg">
                      Demote {selectedRoleTitle} to {targetRoleTitle}?
                    </AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                      If you demote{' '}
                      <Text as="span" fontWeight="bold">
                        {userName}
                      </Text>{' '}
                      to {targetRoleTitle}, they will be removed from all groups, and they will not be able to see the
                      workflow runs of other users.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <ButtonGroup>
                        <Button variant="ghost" onClick={closeAlert} ref={cancelDemoteRef}>
                          Cancel
                        </Button>
                        <Button variant="danger" onClick={() => switchRole(OrganizationMembershipRole.Guest)}>
                          Demote to {targetRoleTitle}
                        </Button>
                      </ButtonGroup>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            );
          }

          case Status.Promote: {
            return (
              <AlertDialog isOpen={true} leastDestructiveRef={cancelPromoteRef} onClose={closeAlert}>
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg">
                      Promote {selectedRoleTitle} to {targetRoleTitle}?
                    </AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                      <Text>{planLevel} pricing is per member.</Text>
                      <Text>
                        Promoting a {selectedRoleTitle} will increase the price by ${state.additionalMemberCost} per{' '}
                        {interval}.
                      </Text>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <ButtonGroup>
                        <Button variant="ghost" onClick={closeAlert} ref={cancelPromoteRef}>
                          Cancel
                        </Button>
                        <Button onClick={switchToTargetRole}>Promote to {targetRoleTitle}</Button>
                      </ButtonGroup>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            );
          }
        }
      })()}
    </>
  );
};
