import * as React from 'react';
import { Box, Checkbox, Text, Tooltip } from 'components/design/next';
import { useTemplateLibrarySelectionSlice } from '../selection-store';
import {
  useGetAllConsolidatedFolderPermissionsQuery,
  useGetAllConsolidatedTemplatePermissionsQuery,
} from 'features/permissions/query-builder';
import { Folder, Template, TemplateType } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { capitalize } from 'lodash';
import { FolderPermissionMap, TemplatePermissionMap } from '@process-street/subgrade/core';

type SelectionCheckboxProps = {
  item: Template | Folder;
};

export const SelectionCheckbox: React.FC<React.PropsWithChildren<SelectionCheckboxProps>> = ({ item }) => {
  const templatePermissions = useGetAllConsolidatedTemplatePermissionsQuery({
    select: allTemplatePermits => allTemplatePermits.find(fp => fp.templateId === item.id),
  });

  const folderPermissions = useGetAllConsolidatedFolderPermissionsQuery({
    enabled: 'folderType' in item,
    select: allFolderPermits => allFolderPermits.find(fp => fp.folderId === item.id),
  });

  const permissionMap: Partial<FolderPermissionMap | TemplatePermissionMap> =
    templatePermissions.data?.permissionMap ?? folderPermissions.data?.permissionMap ?? {};

  const { select, deselect, isSelected, selectedIds } = useTemplateLibrarySelectionSlice();

  const archiveOrDeleteIsEnabled = match({ permissionMap, item })
    .with(
      {
        item: { folderType: P.not(P.nullish) },
        permissionMap: P.union({ folderDelete: true }, { folderUpdate: true }),
      },
      () => true,
    )
    .with(
      {
        item: { templateType: P.not(P.nullish) },
        permissionMap: P.union({ templateUpdate: true }, { templateDelete: true }, { templateArchive: true }),
      },
      () => true,
    )
    .with(
      {
        // Checkling P.nullish works for Workflows and Forms because both use template atoms for permits. Pages has their owns.
        // Could add this permits to the previous UNION but this is explicit and more readable.
        item: { templateType: TemplateType.Page },
        permissionMap: P.union({ pageUpdate: true }, { pageDelete: true }),
      },
      () => true,
    )
    .otherwise(() => false);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      select(item);
    } else {
      deselect(item.id);
    }
  };

  const itemType = match(item)
    .with({ templateType: TemplateType.Playbook }, () => 'Workflow')
    .with({ templateType: P.not(P.nullish) }, ({ templateType }) => capitalize(templateType))
    .otherwise(() => 'Folder');

  return (
    <Tooltip
      isDisabled={archiveOrDeleteIsEnabled}
      label={
        <Text fontSize="xs">
          You don’t have permission to
          <br /> archive or delete this {itemType}.
        </Text>
      }
      textAlign="center"
      hasArrow
    >
      <Box>
        <Checkbox
          isDisabled={!archiveOrDeleteIsEnabled}
          onChange={handleCheck}
          isChecked={isSelected(item.id)}
          size="lg"
          borderWidth="thin"
          opacity={selectedIds.size > 0 ? 1 : 0}
          sx={{
            '.chakra-checkbox__control': {
              borderColor: 'gray.300',
              borderWidth: 'thin',
            },
          }}
          _disabled={{
            '.chakra-checkbox__control': {
              bgColor: 'gray.200',
              borderColor: 'gray.300',
            },
          }}
          _groupHover={{
            opacity: 1,
          }}
        />
      </Box>
    </Tooltip>
  );
};
