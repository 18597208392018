import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateOrganizationDetailsParams = {
  organizationId: string;
  employeeCount?: string;
};

export type CreateOrganizationDetailsResult = void;

export const CreateOrganizationDetails = {
  key: ['organization', 'details', 'create'],
  mutationFn: ({ organizationId, ...body }: CreateOrganizationDetailsParams) =>
    axiosService
      .getAxios()
      .post<CreateOrganizationDetailsResult>(`/1/organizations/${organizationId}/details`, body)
      .then(r => r.data),
};

export const useCreateOrganizationDetailsMutation = (
  options: UseMutationOptions<CreateOrganizationDetailsResult, AxiosError, CreateOrganizationDetailsParams> = {},
) => {
  return useMutation<CreateOrganizationDetailsResult, AxiosError, CreateOrganizationDetailsParams>(
    CreateOrganizationDetails.mutationFn,
    options,
  );
};
