import * as React from 'react';
import { Template } from '@process-street/subgrade/process';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Icon,
  Text,
  VStack,
} from 'components/design/next';
import { RunMultipleButton, ScheduleWorkflowButton } from 'components/template-menu-buttons';
import { WorkflowRunLink } from './workflow-run-link';
import { RunViaEmailButton } from 'pages/templates/_id/dashboard/components/sidebar/more-running-options/run-via-email-button';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { usePaywall } from 'components/paywalls';
import { FreeMember } from 'components/paywalls/edit-workflow/ui';
import { DelayedSpinner } from 'components/design/next/delayed-spinner';

export interface MoreRunningOptionsProps {
  templateId?: Template['id'];
}

export const MoreRunningOptions = ({ templateId }: MoreRunningOptionsProps) => {
  const { data, isLoading } = useGetConsolidatedTemplatePermissionsQuery(templateId, { enabled: Boolean(templateId) });
  const canSchedule = data?.permissionMap.checklistSchedule;
  const canRun = data?.permissionMap.checklistCreate;
  const { setPaywall } = usePaywall();

  const scheduleButton = (
    <Button
      variant="tertiary"
      size="sm"
      leftIcon={<Icon icon="clock" size="3" variant="far" />}
      fontSize="xs"
      onClick={canSchedule ? undefined : () => setPaywall(<FreeMember />)}
    >
      Schedule runs
    </Button>
  );

  const runMultipleButton = (
    <Button
      variant="tertiary"
      size="sm"
      leftIcon={<Icon icon="forward" size="3" variant="far" />}
      fontSize="xs"
      onClick={canRun ? undefined : () => setPaywall(<FreeMember />)}
    >
      Run multiple
    </Button>
  );

  return (
    <Accordion allowToggle defaultIndex={0} w="100%">
      <AccordionItem>
        <AccordionButton _hover={{ backgroundColor: 'unset' }} p={0}>
          <HStack spacing={2}>
            <Icon icon="play" variant="far" size="3" />
            <Text fontWeight="bold" fontSize="sm" color="gray.600">
              More running options
            </Text>
            <AccordionIcon h={5} w={5} ml={3} />
          </HStack>
        </AccordionButton>
        <AccordionPanel px="0" pt={4} pb={2}>
          <VStack spacing={4} alignItems="flex-start">
            <Box>
              <Text mb={2} color="gray.500" fontSize="sm">
                Create a new run by clicking a link
              </Text>
              <DelayedSpinner isLoaded={Boolean(templateId)} isCentered={true}>
                <WorkflowRunLink templateId={templateId!} />
              </DelayedSpinner>
            </Box>
            <Box>
              <Text mb={2} color="gray.500" fontSize="sm">
                Schedule runs to start on a certain date
              </Text>
              <DelayedSpinner isLoaded={Boolean(templateId) && !isLoading} isCentered={true}>
                {canSchedule ? (
                  <ScheduleWorkflowButton templateId={templateId} mode="view">
                    {scheduleButton}
                  </ScheduleWorkflowButton>
                ) : (
                  scheduleButton
                )}
              </DelayedSpinner>
            </Box>
            <Box>
              <Text mb={2} color="gray.500" fontSize="sm">
                Send an email to create a new run
              </Text>
              <DelayedSpinner isLoaded={Boolean(templateId)} isCentered={true}>
                <RunViaEmailButton templateId={templateId!} />
              </DelayedSpinner>
            </Box>
            <Box>
              <Text mb={2} color="gray.500" fontSize="sm">
                Create multiple runs at once
              </Text>
              <DelayedSpinner isLoaded={Boolean(templateId) && !isLoading} isCentered={true}>
                {canRun ? (
                  <RunMultipleButton templateId={templateId}>{runMultipleButton}</RunMultipleButton>
                ) : (
                  runMultipleButton
                )}
              </DelayedSpinner>
            </Box>
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
