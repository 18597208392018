import { Input, Icon, InputLeftElement, InputGroup } from 'components/design/next';
import * as React from 'react';
import { DueDateRuleDefinition, FormFieldWidget, TaskTemplate, Widget } from '@process-street/subgrade/process';
import { DynamicDueDateLabelService } from 'components/dynamic-due-dates/services/dynamic-due-date-label.service';
import { useSelector } from 'react-redux';
import { ReduxAppState } from 'reducers/types';
import { DynamicDueDatesSelector } from 'components/dynamic-due-dates/store/dynamic-due-dates.selectors';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';

export type RunChecklistDueLabelProps = {
  checklistRule: DueDateRuleDefinition;
};

export const RunChecklistDueLabel: React.FC<React.PropsWithChildren<RunChecklistDueLabelProps>> = ({
  checklistRule,
}) => {
  const dateWidgets: FormFieldWidget[] = useSelector<ReduxAppState, Widget[]>(
    DynamicDueDatesSelector.getDateWidgetsByTemplateRevisionId(checklistRule.templateRevision.id),
  ) as FormFieldWidget[];
  const taskTemplates = useSelector<ReduxAppState, TaskTemplate[]>(
    TaskTemplateSelector.getAllByTemplateRevisionId(checklistRule.templateRevision.id),
  );

  const label = DynamicDueDateLabelService.getFullLabelByRule(checklistRule, taskTemplates, dateWidgets);
  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        children={<Icon icon="clock" variant="far" size="4" color={'gray.600'} />}
      />
      <Input
        {...{
          backgroundColor: 'gray.200',
          borderColor: 'gray.300',
          color: 'gray.500',
          opacity: '1!important',
          disabled: true,
        }}
        value={label}
      />
    </InputGroup>
  );
};
