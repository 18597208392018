import * as React from 'react';
import { TemplateType } from '@process-street/subgrade/process';
import { useGetFormResponsesSearchQuery } from 'features/forms/query-builder';
import { useNavigate } from 'app/adapters/navigation';
import { queryString } from '@process-street/subgrade/util';
import { useSelectedOrganization } from 'hooks/use-selected-organization';

export const StartHere: React.FC = () => {
  const navigate = useNavigate();
  const organization = useSelectedOrganization();

  const runsQuery = useGetFormResponsesSearchQuery(
    {
      organizationId: organization?.id,
      searchCriteria: {
        templateTypes: [TemplateType.Playbook],
        pageSize: 5,
      },
    },
    { enabled: !!organization },
  );

  React.useEffect(
    function redirectToStartHereWFR() {
      if (!runsQuery.data) return;

      const startHereRecord = runsQuery.data.find(w => w.templateName === 'Start Here');
      if (startHereRecord) {
        navigate({
          pathname: 'checklist',
          search: queryString.stringify({
            id: startHereRecord.id,
          }),
        });
      }
    },
    [navigate, runsQuery.data],
  );

  return null;
};
