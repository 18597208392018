import { ConditionalFilterOperand, ConditionalFilterOperandType } from '@process-street/subgrade/dashboard';
import React from 'react';
import { match } from 'ts-pattern';
import { AssigneeListOperandEditor } from '../AssigneeListOperandEditor';
import {
  ChecklistStatusListOperandEditor,
  ChecklistStatusListOperandEditorProps,
} from '../ChecklistStatusListOperandEditor';
import { DateRangeOperandEditor } from '../DateRangeOperandEditor';
import { IntegerOperandEditor } from '../IntegerOperandEditor';
import { SingleDateOperandEditor } from '../SingleDateOperandEditor';
import { StringOperandEditor } from '../StringOperandEditor';
import { ChecklistLabelListOperandEditor } from 'components/dashboard/components/checklist/ChecklistDashboardFilters/filters/ChecklistDashboardConditionalFilter/ChecklistLabelListOperandEditor/ChecklistLabelListOperandEditor';

export interface OperandCellProps {
  operand: ConditionalFilterOperand;
  onChange: (operand: ConditionalFilterOperand) => void;
  components?: Partial<{
    ChecklistStatus: React.FunctionComponent<React.PropsWithChildren<ChecklistStatusListOperandEditorProps>>;
  }>;
}

export const OperandCell: React.FunctionComponent<React.PropsWithChildren<OperandCellProps>> = ({
  operand,
  onChange,
  components,
}) => {
  return match(operand)
    .with({ operandType: ConditionalFilterOperandType.String }, operand => (
      <StringOperandEditor onChange={onChange} operand={operand} />
    ))
    .with({ operandType: ConditionalFilterOperandType.Integer }, operand => (
      <IntegerOperandEditor operand={operand} onChange={onChange} />
    ))
    .with({ operandType: ConditionalFilterOperandType.DateTime }, operand => (
      <SingleDateOperandEditor onChange={onChange} operand={operand} />
    ))
    .with({ operandType: ConditionalFilterOperandType.ChecklistStatusList }, operand => {
      const C = components?.ChecklistStatus ?? ChecklistStatusListOperandEditor;
      return <C onChange={onChange} operand={operand} />;
    })
    .with({ operandType: ConditionalFilterOperandType.AssigneeListOperand }, operand => (
      <AssigneeListOperandEditor onChange={onChange} operand={operand} />
    ))
    .with({ operandType: ConditionalFilterOperandType.DateTimeRange }, operand => (
      <DateRangeOperandEditor onChange={onChange} operand={operand} />
    ))
    .with({ operandType: ConditionalFilterOperandType.ChecklistLabelList }, operand => (
      <ChecklistLabelListOperandEditor onChange={onChange} operand={operand} />
    ))
    .otherwise(operand => <div>Unknown operand: {operand.operandType}</div>);
};
