import * as React from 'react';
import { HStack, Icon, VStack, Text, Center, Divider } from 'components/design/next';
import { isNotIdRef } from '@process-street/subgrade/core';
import { InboxItemType, TaskInboxItem } from '@process-street/subgrade/inbox';

import { useInjector } from 'components/injection-provider';

import { DueDateBadge } from './due-date-badge';
import { Assignees } from './assignees';
import { useInboxItemDetailsModal } from 'app/pages/tasks/hooks/use-inbox-item-details-modal';
import { getInboxItemDisplayProps } from 'app/pages/tasks/components/cell-renderer/name-renderer';
import {
  oneOffTaskDrawerStore,
  useCloseOneOffTaskDrawer,
  useViewTaskById,
} from 'app/features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { InboxItemDetailsEvents } from 'app/directives/inbox/item/details/inbox-item-details.events';

const MASKED_ITEM_TEXT = "You don't have permission to see this task";
const DEFAULT_TASK_NAME = '<unnamed task>';

const resolveTaskName = (item: TaskInboxItem) => {
  if (item.masked) {
    return MASKED_ITEM_TEXT;
  }
  return isNotIdRef(item.task.taskTemplate) ? item.task.taskTemplate.name : DEFAULT_TASK_NAME;
};

export const TaskItem = ({ item }: { item: TaskInboxItem }) => {
  const { $rootScope } = useInjector('$state', '$rootScope');
  const inboxItemDetailsModal = useInboxItemDetailsModal();
  const viewTaskById = useViewTaskById();
  const closeTaskDrawer = useCloseOneOffTaskDrawer();

  const isOneOffTask = item.itemType === InboxItemType.OneOffTask;

  const checklist = isNotIdRef(item.checklist) ? item.checklist : null;
  const templateName = isNotIdRef(item.template) ? item.template.name : '';
  const task = isNotIdRef(item.task) ? item.task : undefined;
  const taskName = resolveTaskName(item);

  const handleClick = async () => {
    if (isOneOffTask) {
      $rootScope.$broadcast(InboxItemDetailsEvents.INBOX_ITEM_DETAILS_CLOSED);

      // When the drawer is open, we will close and then open the new item
      // so it matches the behavior of the angular drawer.
      if (oneOffTaskDrawerStore.getState().isOpen) {
        closeTaskDrawer();

        setTimeout(() => {
          viewTaskById({ taskId: item.task.id });
        }, 300);
      } else {
        viewTaskById({ taskId: item.task.id });
      }
    } else {
      closeTaskDrawer();
      inboxItemDetailsModal.onOpen(item);
    }
  };

  const vStackProps = {
    onClick: handleClick,
    cursor: 'pointer',
  };

  const { icon } = getInboxItemDisplayProps(item);

  return (
    <>
      <Icon
        icon={icon}
        variant="fad"
        primaryLayer={
          isOneOffTask
            ? {
                opacity: 1,
                color: 'teal.500',
              }
            : { color: 'purple.500' }
        }
        secondaryLayer={
          isOneOffTask
            ? {
                opacity: 0.4,
                color: 'teal.500',
              }
            : { color: 'purple.300' }
        }
        size="5"
        aria-label="task"
      />

      <VStack {...vStackProps} align="left" minW="0" width="full" flex="1" spacing="0">
        <Text
          aria-label="workflow run title"
          color="gray.700"
          fontWeight="medium"
          fontSize={{ base: 'sm', lg: 'md' }}
          noOfLines={1}
        >
          {taskName}
        </Text>
        {checklist?.name && (
          <HStack spacing="1.5" minW="0" flex="1">
            <Text aria-label="workflow title" color="gray.600" fontWeight="regular" fontSize="xs" noOfLines={1}>
              {checklist?.name}
            </Text>
            <Center height={{ base: '2', md: '3' }}>
              <Divider borderColor="gray.500" borderLeftWidth="0.5" orientation="vertical" />
            </Center>
            <Text aria-label="workflow title" color="gray.600" fontWeight="regular" fontSize="xs" noOfLines={1}>
              {templateName}
            </Text>
          </HStack>
        )}
      </VStack>
      <HStack>
        {task?.dueDate && <DueDateBadge date={task?.dueDate} />}
        {item.assignees && <Assignees assignees={item.assignees} />}
      </HStack>
    </>
  );
};
