import React from 'react';
import { chakra, HStack, Icon, Link, Text, TextProps } from 'components/design/next';

export const CommentHelpBlock: React.FC<React.PropsWithChildren<TextProps>> = ({ ...props }) => (
  <HStack className="hidden-xs" width="full" alignItems="center" textColor="gray.400">
    <Text noOfLines={1} {...{ ...props, fontSize: props.fontSize ?? 'sm' }}>
      <chakra.b>**bold**</chakra.b>
      <chakra.i ml="1">_italic_</chakra.i>
      <chakra.span ml="1">* bullet</chakra.span>
      <chakra.span ml="1">&gt;quote</chakra.span>
      <chakra.span ml="1">:emoji:</chakra.span>
    </Text>
    <Link
      href="http://www.process.st/help/docs/format-comments/"
      tabIndex={-1}
      target="_blank"
      rel="noopener noreferrer"
      ml="1"
    >
      <Icon variant="far" icon="info-circle" color="brand.500" size="0.9em" />
    </Link>
  </HStack>
);
