import { Muid, UserType } from '@process-street/subgrade/core';
import classNames from 'classnames';
import { Avatar } from 'components/common/Avatar';
import { Icon, IconType } from 'components/design/Icon';
import React from 'react';
import styles from './MemberOption.module.scss';
import { MemberItem } from './member-item';

export interface OptionParams<T> {
  item: T;
}

export interface MemberOptionProps extends OptionParams<MemberItem> {
  deletable?: boolean;
  item: MemberItem;
  onDelete?: (id: Muid) => void;
  selectable?: boolean;
}

export const MemberOption: React.FunctionComponent<React.PropsWithChildren<MemberOptionProps>> = ({
  deletable,
  onDelete,
  selectable,
  item: { avatarUrl, email, initials, name, organizationMembershipId, userType },
}) => {
  const classes = classNames({
    [styles.membersItem]: true,
    [styles.selectable]: selectable !== false,
  });

  const handleDelete = () => {
    if (onDelete) {
      onDelete(organizationMembershipId);
    }
  };

  const emailVisible = userType === UserType.Standard;

  return (
    <div className={classes}>
      <div className={styles.memberInfo}>
        <Avatar url={avatarUrl} initials={initials} />
        <div className={styles.labels}>
          <span className={styles.userName}>{name}</span>
          {emailVisible && <span className={styles.email}>{email}</span>}
        </div>
      </div>
      {deletable === true && (
        <span className={styles.deleteIcon} aria-label="Remove user" onClick={handleDelete}>
          <Icon iconType={IconType.RemoveUser} />
        </span>
      )}
    </div>
  );
};
