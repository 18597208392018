import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';
import { UIActorRef } from './ui-machine';

type Context = { uiActorRef: UIActorRef };

export const [useUIActorRef, UIActorContext] = createUsableContext<Context>({
  hookName: 'useUIContext',
  providerName: 'UIContextProvider',
});

export const UIActorProvider: React.FC<React.PropsWithChildren<Context>> = ({ children, ...context }) => {
  return <UIActorContext.Provider value={context}>{children}</UIActorContext.Provider>;
};
