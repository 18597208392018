import { DeserializeHtml } from '@udecode/plate-core';
import { ELEMENT_LINK } from '@udecode/plate-link';

export const linkDeserializer: DeserializeHtml = {
  getNode: el => ({
    type: ELEMENT_LINK,
    href: el.getAttribute('href'),
  }),
  rules: [{ validNodeName: 'A' }],
};
