export const TaskListEvent = {
  // Multi selection menu
  // TODO Move all these to a TaskTemplateListEvent file

  SHOW_MULTI_SELECT_MENU: 'show multi select menu',
  HIDE_MULTI_SELECT_MENU: 'hide multi select menu',
  HIDE_POP_BOX: 'hide pop box',

  BULK_DELETE_REQUEST_FROM_MENU: 'bulk delete request from menu',
  BULK_MOVE_UP_REQUEST_FROM_MENU: 'bulk move up request from menu',
  BULK_MOVE_DOWN_REQUEST_FROM_MENU: 'bulk move down request from menu',

  BULK_CLEAR_SELECTION: 'bulk remove selection',

  BULK_SET_DUE_OFFSET: 'bulk set due offset',
  BULK_REMOVE_DUE_OFFSET: 'bulk remove due offset',

  BULK_ASSIGN_USER: 'bulk assign user',
  BULK_UNASSIGN_USER: 'bulk unassign user',

  BULK_ADD_STOP: 'bulk add stop',
  BULK_REMOVE_STOP: 'bulk remove stop',

  // Template
  // TODO Move all these to a TaskTemplateListEvent file

  DUPLICATE_WIDGETS_STARTED: 'duplicate widgets started',
  DUPLICATE_WIDGETS_FINISHED: 'duplicate widgets finished',
  GETTING_DUPLICATED_WIDGETS_FINISHED: 'getting duplicated widgets finished',
  SELECT_TASK_BELOW_REQUEST: 'select task below request',
  UPDATE_TASK_TEMPLATE_NAME: 'update task template name',

  // Checklist

  TOGGLE_TASK_STATUS_REQUEST: 'toggle task status request',
  AI_TASK_COMPLETED: 'ai task completed',
  TASK_PROGRESS_UPDATED: 'task progress updated',
  TASK_COMMENTS_COUNT_UPDATED: 'task comments count updated',
  TASK_APPROVAL_STATUS_CHANGED: 'task status updated',
  SUBJECT_TASK_REJECTED: 'subject task rejected',
  STOPPED_TASKS_COUNT_UPDATED: 'stopped tasks count updated',
  STOPPED_TASKS_SHOWN_TOGGLED: 'stopped tasks shown toggled',

  AI_GENERATED_TASK_TEMPLATES: 'ai generated task templates',
  AI_WIDGET_GENERATION_FOR_TASK_STARTED: 'ai widget generation for task started',
  AI_WIDGET_GENERATION_FOR_TASK_DONE: 'ai widget generation for task done',
  AI_WIDGET_GENERATION_FOR_TASK_ANIMATION_DONE: 'ai widget generation for task animation done',
} as const;
