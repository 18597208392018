import { Template } from '@process-street/subgrade/process';
import { Link, Text } from 'components/design/next';
import React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { mailto as buildMailToLink } from '@process-street/subgrade/process';
import { LocationService } from '@process-street/subgrade/util';
import { useAdminEmails } from 'components/paywalls/use-admin-emails';

export interface RequestFormEditPermission {
  template: Template;
}

export const RequestFormEditPermission: React.FC<React.PropsWithChildren<RequestFormEditPermission>> = ({
  template,
}) => {
  const currentUser = useSelector(SessionSelector.getCurrentUser);
  const [adminEmail, ...bcc] = useAdminEmails();

  const formUrl = LocationService.editFormHref(template.id, template.name);

  const mailto = buildMailToLink({
    to: adminEmail,
    subject: `Someone on your team wants to edit a Form`,
    body: `${
      currentUser?.username ? currentUser.username : ''
    } would like to edit a form. Update permissions here: ${formUrl}`,
    bcc,
  });

  return (
    <Text variant="-2">
      You don't have permission to edit this form. <Link href={mailto}>Request access.</Link>
    </Text>
  );
};
