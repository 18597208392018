import { createUsableContext } from '@process-street/subgrade/util';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { Editor } from 'slate';
import { PagesEditor, usePagesEditorState } from './pages-plate-types';

type Context = {
  lastSelection: Editor['selection'];
  setLastSelection: (selection: Editor['selection']) => void;
};

export const [useSelectionContext, SelectionContext] = createUsableContext<Context>({
  hookName: 'useSelectionContext',
  providerName: 'SelectionContext',
});

const useLastSelection = (
  editor: PagesEditor,
  nullable: boolean,
): [Editor['selection'], (s: Editor['selection']) => void] => {
  const [selection, setSelection] = React.useState(editor.selection);
  const setLastSelection = React.useCallback(
    (newSelection: Editor['selection']) => {
      if (nullable !== true && !newSelection) return;
      if (isEqual(selection, newSelection)) return;
      setSelection(newSelection);
    },
    [nullable, selection],
  );
  React.useEffect(() => setLastSelection(editor.selection), [editor.selection, setLastSelection]);
  return React.useMemo(() => [selection, setLastSelection], [selection, setLastSelection]);
};

export const SelectionProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const editor = usePagesEditorState();
  const [lastSelection, setLastSelection] = useLastSelection(editor, false);

  const value: Context = React.useMemo(() => ({ lastSelection, setLastSelection }), [lastSelection, setLastSelection]);

  return <SelectionContext.Provider value={value}>{children}</SelectionContext.Provider>;
};
