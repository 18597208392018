import { PlanService } from 'services/plan-service.interface';
import {
  Muid,
  Option,
  Organization,
  OrganizationDiscount,
  OrganizationUsageStats,
  Subscription,
  SubscriptionCancellationForm,
  SubscriptionStatus,
} from '@process-street/subgrade/core';
import {
  BillingDetails,
  CardInfo,
  Descriptor,
  FeatureSetMap,
  IntervalToMuid,
  IntervalToPlanCostInfoMap,
  LevelToIntervalToMuid,
  LimitInfo,
  PlanAction,
  PlanActions,
  PlanMap,
  PlanSubscriptionDetails,
} from 'pages/organizations/manage/billing/models';
import {
  getDiscountValue,
  Plan,
  PlanFeatureSet,
  PlanInterval,
  PlanIntervalUtil,
  PlanLevel,
  PlanTrack,
  SubscriptionState,
} from '@process-street/subgrade/billing';
import { PlanFeatureSetService } from 'services/plan-feature-set-service.interface';
import { OrganizationService } from 'services/organization-service.interface';
import { HttpStatus } from '@process-street/subgrade/util';
import { PlanLevelSelectorService } from 'directives/billing/plan-level-selector/plan-level-selector-service.interface';
import { CreateTokenCardData, Stripe, StripeCardElement } from '@stripe/stripe-js';
import { BillingDetailsService } from 'directives/billing/billing-details/billing-details-service.interface';
import { BillingService } from 'directives/billing/billing-service.interface';
import { SessionService } from 'services/session-service.interface';
import { API } from 'components/paywalls/trial-expired/api';
import { getFreemiumAndFreeTrialPlansByCurrentPlan, PlanIdMap } from 'services/plans';
import { IntercomService } from 'services/interop/intercom-service';
import { AnalyticsService } from 'components/analytics/analytics.service';

export class BillingTabService {
  static $inject = [
    'BillingDetailsService',
    'BillingService',
    'OrganizationService',
    'PlanFeatureSetService',
    'PlanLevelSelectorService',
    'PlanService',
    'SessionService',
  ];

  constructor(
    private readonly billingDetailsService: BillingDetailsService,
    private readonly billingService: BillingService,
    private readonly organizationService: OrganizationService,
    private readonly planFeatureSetService: PlanFeatureSetService,
    private readonly planLevelSelectorService: PlanLevelSelectorService,
    private readonly planService: PlanService,
    private readonly sessionService: SessionService,
  ) {}

  //We have migrated this method in a previous refactor.
  // you can use the one in utils/plans
  public toDescriptor(planIdMap: PlanIdMap): Descriptor {
    const acc: Descriptor = {};

    Object.entries(planIdMap).forEach(([id, { track, level, interval }]) => {
      const levelToIntervalToMuid = acc[track] ?? {};
      const intervalToMuid = levelToIntervalToMuid[level] ?? {};

      intervalToMuid[interval] = id;
      levelToIntervalToMuid[level] = intervalToMuid;
      acc[track] = levelToIntervalToMuid;
    });

    return acc;
  }

  public getFreemiumAndFreeTrialPlansDescriptorByPlan(plan: Plan): Descriptor {
    return this.toDescriptor(getFreemiumAndFreeTrialPlansByCurrentPlan(plan));
  }

  public getFreemiumAndFreeTrialPlansAndFeatureSetsByPlan(plan: Plan): Promise<PlanMap> {
    const freemiumAndFreeTrialPlansDescriptor = this.getFreemiumAndFreeTrialPlansDescriptorByPlan(plan);

    const planMap: PlanMap = {};
    const featureSetMap: FeatureSetMap = {};
    const promises: Promise<void>[] = [];

    Object.entries(freemiumAndFreeTrialPlansDescriptor).forEach(([track, levelsIntervalsIds]) => {
      Object.entries(levelsIntervalsIds as LevelToIntervalToMuid).forEach(([level, intervalsIds]) => {
        const planLevel = level as PlanLevel;
        planMap[planLevel] = {};
        featureSetMap[level as PlanLevel] = {};

        Object.entries(intervalsIds as IntervalToMuid).forEach(([interval, __id]) => {
          const planInterval = interval as PlanInterval;
          const planId = freemiumAndFreeTrialPlansDescriptor[track as PlanTrack]![planLevel]![planInterval];

          const promise = this.planService.getById(planId!).then(plan => {
            planMap[planLevel]![planInterval] = plan;
            this.planFeatureSetService.getById(plan.featureSet.id).then(featureSet => {
              featureSetMap[planLevel]![planInterval] = featureSet;
            });
          });
          promises.push(promise);
        });
      });
    });

    return Promise.all(promises).then(() => planMap);
  }

  public getCard(organizationId: Muid): Promise<CardInfo | undefined> {
    return this.organizationService.getCardByOrganizationId(organizationId).catch(e => {
      if (e.status === HttpStatus.NOT_FOUND) {
        return undefined;
      } else {
        throw e;
      }
    });
  }

  public getPlanCostDetails(
    planMap: PlanMap,
    planLevel: PlanLevel,
    quantity: number,
    discount: OrganizationDiscount,
    organization: Organization,
  ) {
    const levelPlans = planMap[planLevel];

    const costDetailsPerPeriodMap: IntervalToPlanCostInfoMap = {};

    PlanIntervalUtil.all().forEach(interval => {
      const plan = levelPlans && levelPlans[interval];

      if (plan) {
        costDetailsPerPeriodMap[interval] = this.planLevelSelectorService.getPlanCostDetailsDescription(
          plan,
          interval,
          quantity,
          discount,
          organization,
        );
      }
    });

    return costDetailsPerPeriodMap;
  }

  public async setCard(
    stripe: Stripe,
    cardElement: StripeCardElement,
    billingDetails: BillingDetails,
    organization: Organization,
  ) {
    const { token, error } = await this.createToken(stripe, cardElement, billingDetails);

    if (token) {
      return this.billingDetailsService._setOrganizationCard(organization, token.id);
    } else {
      return Promise.reject(error);
    }
  }

  private createToken(stripe: Stripe, cardElement: StripeCardElement, billingDetails: BillingDetails) {
    const data: CreateTokenCardData = {
      name: billingDetails.name,
      address_line1: billingDetails.addressLine1,
      address_line2: billingDetails.addressLine2,
      address_city: billingDetails.addressCity,
      address_state: billingDetails.addressState,
      address_country: billingDetails.addressCountry,
      address_zip: billingDetails.addressZip,
    };

    return stripe.createToken(cardElement, data);
  }

  public async updateSubscription(
    stripe: Stripe,
    cardElement: Option<StripeCardElement>,
    billingDetails: BillingDetails,
    currentPlan: Plan | undefined,
    upgradedPlan: Plan,
    organization: Organization,
  ): Promise<Organization> {
    if (cardElement) {
      await this.setCard(stripe, cardElement, billingDetails, organization);
    }

    const user = this.sessionService.getUser();
    if (user) {
      return this.billingService.upgradeSubscription(billingDetails, organization, currentPlan, upgradedPlan, user);
    } else {
      return Promise.reject({
        message: 'User not logged in.',
      });
    }
  }

  public contactUs() {
    AnalyticsService.trackEvent('contact us link clicked', { location: 'billing page' });
    IntercomService.show();
  }

  public isFreeAndShouldCancelSubscription(
    currentPlan: Plan | undefined,
    planLevel: PlanLevel,
    organization: Organization,
  ) {
    const canceled = this.organizationService.isCanceled(organization.subscription);
    const selectedPlanIsFree = this.planService.isPlanLevelFree(planLevel);

    if (!currentPlan) {
      return selectedPlanIsFree && !canceled; // legacy level
    }

    const expired = this.billingService.isSubscriptionExpired(organization.subscription, currentPlan);
    const trialing = this.organizationService.isTrialing(organization.subscription);
    const legacy = this.planService.isPlanIdLegacy(currentPlan.id);

    const organizationPlanIsFree = this.planService.isPlanFree(currentPlan);

    // The logic combinations of the the second part of the boolean expression below:
    //     legacy +     expired +     free => false
    //     legacy +     expired + not free => false
    //     legacy + not expired +     free => false
    //     legacy + not expired + not free => true
    // not legacy +     expired +     free => false
    // not legacy +     expired + not free => true
    // not legacy + not expired +     free => false
    // not legacy + not expired + not free => true
    // Simplified here http://www.wolframalpha.com/input/?
    // i=(A+AND+NOT+B+AND+NOT+C)+OR+((NOT+A+AND+B+AND+NOT+C)+OR+(NOT+A+AND+NOT+B+AND+NOT+C))
    return !canceled && selectedPlanIsFree && !trialing && !(legacy && expired) && !organizationPlanIsFree;
  }

  public async getPlanSubscriptionDetails(plan: Plan, organization: Organization): Promise<PlanSubscriptionDetails> {
    const { subscription } = organization;

    const [featureSet, organizationStats, organizationUsageStats] = await Promise.all([
      this.planFeatureSetService.getById(plan.featureSet.id),
      this.organizationService.getOrganizationStatsById(organization.id),
      API.getOrganizationUsageStats(organization.id),
    ]);

    const subscriptionState = this.billingService.getSubscriptionState(
      plan,
      featureSet,
      subscription,
      organizationStats,
    );

    const limits = {
      checklistsRuns: this.billingService.getChecklistRunsLimitDetails(featureSet, organizationStats),
      activeChecklists: this.billingService.getActiveChecklistsLimitDetails(featureSet, organizationStats),
      activeTemplates: this.billingService.getActiveTemplatesLimitDetails(featureSet, organizationStats),
      fullMembers: this.getFullMembersLimitDetails(featureSet, organizationUsageStats),
    };

    return {
      subscriptionState,
      limits,
    };
  }

  public getFullMembersLimitDetails(
    planFeatureSet: PlanFeatureSet,
    organizationStats: OrganizationUsageStats,
  ): LimitInfo {
    const usage = organizationStats.fullMembersCount;
    const limit = planFeatureSet.usersLimit;

    return {
      usage,
      limit,
      limitIsReached: limit ? usage > limit : false,
    };
  }

  public getPlanAction(
    plan: Plan,
    organization: Organization,
    subscriptionState: SubscriptionState,
    newPlanSelected: boolean,
  ): PlanAction {
    const daysLeft = this.billingService.getTrialDaysRemaining(organization.subscription);
    const trial = subscriptionState === SubscriptionState.TRIALING && daysLeft > 0;
    const trialExpired = subscriptionState === SubscriptionState.TRIALING && daysLeft <= 0;
    const canceled = subscriptionState === SubscriptionState.CANCEL_AT_PERIOD_END;

    if (trial) {
      return PlanActions.changePlan;
    } else if (trialExpired) {
      return newPlanSelected ? PlanActions.changePlan : PlanActions.selectPlan;
    } else if (canceled) {
      return newPlanSelected ? PlanActions.changePlan : PlanActions.renewSubscription;
    }

    switch (plan.level) {
      case PlanLevel.Free:
        return newPlanSelected ? PlanActions.changePlan : PlanActions.upgradePlan;

      case PlanLevel.Professional:
        return PlanActions.changePlan;

      case PlanLevel.Enterprise:
        return PlanActions.contactUs;

      default:
        return PlanActions.changePlan;
    }
  }

  public shouldShowOnlyYearlyBillingCycle(
    plan: Plan,
    subscription: Subscription,
    discount: Option<OrganizationDiscount>,
  ): boolean {
    const yearly = plan.interval === PlanInterval.Yearly;
    const paidPlan = plan.level !== PlanLevel.Free;
    const canceled = subscription.status === SubscriptionStatus.Canceled;
    const discountValue = getDiscountValue(discount);
    const canceledAndNotDiscounted = canceled && !discountValue;

    return !canceledAndNotDiscounted && paidPlan && yearly;
  }

  public updateEmail(organizationId: Muid, email: string): Promise<Organization> {
    return this.organizationService.setEmail(organizationId, email);
  }

  public getSubscriptionStatus(subscription: Subscription, subscriptionState: SubscriptionState): string {
    switch (subscriptionState) {
      case SubscriptionState.CANCEL_AT_PERIOD_END:
        return 'Canceled';
      case SubscriptionState.ACTIVE:
        return 'Active';
      case SubscriptionState.TRIALING: {
        const trialDaysRemaining = this.billingService.getTrialDaysRemaining(subscription);
        return `Trialing (${trialDaysRemaining} ${trialDaysRemaining > 1 ? 'days' : 'day'} left)`;
      }
      case SubscriptionState.EXPIRED:
        return 'Expired';
      case SubscriptionState.EXCEEDED:
        return 'Limit Exceeded';
      case SubscriptionState.PAST_DUE:
        return 'Past Due';
      default:
        return '';
    }
  }

  public cancelSubscription(
    currentPlan: Plan,
    organizationId: Muid,
    cancellationForm: SubscriptionCancellationForm,
  ): Promise<Organization> {
    return this.organizationService
      .cancelSubscriptionByOrganizationId(organizationId, cancellationForm)
      .then(updatedOrganization => {
        AnalyticsService.trackEvent('subscription canceled', {
          'plan id': currentPlan.id,
          'plan name': currentPlan.name,
          'plan interval': currentPlan.interval,
          'plan level': currentPlan.level,
          'cancellation reason': cancellationForm.reason,
          'alternative solution': cancellationForm.alternativeSolution,
          'additional cancellation details': cancellationForm.additionalDetails,
        });

        return updatedOrganization;
      });
  }

  public subscriptionIsCancelable(organization: Organization, plan: Plan): boolean {
    const enterprise = plan.level === PlanLevel.Enterprise;

    return !enterprise && this.billingService.subscriptionIsCancelable(organization, plan);
  }

  public renewSubscription(organization: Organization, plan: Plan): Promise<void> {
    return this.billingService.renewSubscription(organization, plan);
  }
}
