import * as React from 'react';
import { StackProps, VStack } from 'components/design/next';

export const TabContainer: React.FC<React.PropsWithChildren<StackProps>> = ({ children, ...props }) => {
  return (
    <VStack
      minH={`calc(90vh - ${MODAL_HEADER_HEIGHT + TAB_HEIGHT + PADDING_TOP + FOOTER_HEIGHT}px)`}
      spacing={4}
      alignItems="flex-start"
      {...props}
    >
      {children}
    </VStack>
  );
};

const MODAL_HEADER_HEIGHT = 81;
const TAB_HEIGHT = 65;
const PADDING_TOP = 16;
const FOOTER_HEIGHT = 96;
