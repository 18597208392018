import * as React from 'react';
import { CreateTemplateSource } from 'services/template-service.interface';
import { useCurrentOrPrivateRootFolderQuery } from 'features/new-menu/query';
import { useInjector } from 'components/injection-provider';
import { useGetConsolidatedFolderPermissionsQuery } from 'features/permissions/query-builder';
import { AiLocalStorage } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/ai-local-storage';

export const AiGeneratePage = () => {
  const { data: currentFolder } = useCurrentOrPrivateRootFolderQuery();
  const { PremadeTemplateService, $stateParams, $state } = useInjector(
    'PremadeTemplateService',
    '$stateParams',
    '$state',
  );

  const query = useGetConsolidatedFolderPermissionsQuery(currentFolder?.id ?? '', {
    enabled: !!currentFolder,
  });

  React.useEffect(
    function handleQuerySuccess() {
      if (query.status !== 'success' || !query.data) return;

      const { permissionMap, folderId } = query.data;
      const isAllowedToCreateWorkflow = Boolean(permissionMap?.templateCreate);
      const { name, description, retryCount } = $stateParams;

      if (name || description || retryCount) {
        AiLocalStorage.setInitialConfig({
          name,
          description,
          retryCount,
          autoStart: true,
        });
      }

      if (!isAllowedToCreateWorkflow) {
        // redirect to the library
        $state.go('dashboard');

        return;
      }

      PremadeTemplateService.createBlankWorkflowInFolderAndRedirect({
        folderId,
        source: CreateTemplateSource.AI_STATIC_URL,
        isAiGenerated: true,
      });
    },
    // We want this effect to run only when the query status changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.status],
  );

  return null;
};
