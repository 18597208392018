import { TableFormFieldConfig } from '@process-street/subgrade/process/configs/table-form-field-config';
import { ColDef } from '@ag-grid-community/core';

export function useAgGridColDefs<TData>(
  columnDefs: TableFormFieldConfig.ColumnDef[],
  overrides: Partial<ColDef<TData>> = {},
): ColDef<TData>[] {
  return columnDefs.map(
    def =>
      ({
        colId: def.id,
        field: `cells.${def.id}.value`,
        headerName: def.name,
        ...overrides,
      } as ColDef<TData>),
  );
}
