import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { SlackApi } from '../../services/slack.api';
import { SlackAuthorizeSpinner, SpinnerState } from '../SlackAuthorizeSpinner';
import styles from './SlackConnect.module.scss';
import { useMount } from 'react-use';

interface SlackConnectProps {
  slackTeamId?: string;
  slackUserId?: string;
  enterpriseId?: string;
  hmac?: string;
}

export const SlackConnect: React.FC<React.PropsWithChildren<SlackConnectProps>> = ({
  slackTeamId,
  slackUserId,
  enterpriseId,
  hmac,
}) => {
  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const [spinnerState, setSpinnerState] = useState(SpinnerState.Authorizing);
  useMount(() => {
    let mounted = true;
    if (selectedOrganization?.id && slackTeamId && slackUserId && hmac) {
      SlackApi.connect(selectedOrganization.id, slackTeamId, slackUserId, enterpriseId, hmac).then(
        () => {
          if (mounted) {
            setSpinnerState(SpinnerState.Success);
          }
        },
        () => {
          if (mounted) {
            setSpinnerState(SpinnerState.Failure);
          }
        },
      );
    } else {
      setSpinnerState(SpinnerState.Failure);
    }
    return () => {
      mounted = false;
    };
  });

  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.spinnerContainerItem}>
        <SlackAuthorizeSpinner state={spinnerState} />
      </div>
    </div>
  );
};
