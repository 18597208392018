import { Option } from '@process-street/subgrade/core';
import { ActivityObjectType, CommentActivity } from '@process-street/subgrade/activity';
import { InterpretedActivity, InterpreterContext } from './InterpreterContext';
import { sprintf as spf } from 'sprintf-js';
import { Verb } from 'services/activities/verb';
import { escapeHtml as esc, unescapeHtml as unesc } from '@process-street/subgrade/util';
import {
  Checklist,
  ChecklistRevision,
  Comment,
  Task,
  TaskTemplate,
  Template,
  TemplateType,
} from '@process-street/subgrade/process';

const interpret = (activity: CommentActivity, ctx: InterpreterContext, extended: boolean = false) => {
  const task = (activity.object as Comment).task as Task;
  const taskTemplate = task?.taskTemplate as TaskTemplate;
  const checklist = (task?.checklistRevision as ChecklistRevision)?.checklist as Checklist;
  const template = checklist?.template as Template;

  const { data } = activity;

  const templateId = data.templateId ?? checklist?.template.id;
  const templateName = data.templateName ?? template?.name;
  const checklistName = data.checklistName ?? checklist?.name ?? 'unknown';
  const taskTemplateName = data.taskTemplateName ?? taskTemplate?.name ?? 'unknown';

  const isTask = data.templateType === TemplateType.Task;
  const taskName = isTask ? checklistName : taskTemplateName;

  let sentence: Option<InterpretedActivity> = ctx.interpret(activity);
  if (!isTask) {
    sentence.contextObjects.push({
      value: spf('<a>%s</a>', esc(ctx.abbr(templateName, 30))),
      tooltip: templateName,
      type: ActivityObjectType.Template,
      id: templateId,
    });
  }

  const base = {
    type: activity.objectType,
    id: activity.object.id,
  };
  switch (activity.verb) {
    case Verb.CREATED:
      sentence.verb.value = 'posted';
      sentence.objects.push({ ...base, value: 'a <a>comment</a>' });
      sentence.objects.push({
        ...base,
        value: spf('to <a>task</a> "%s"', esc(ctx.abbr(taskName))),
        tooltip: taskName,
      });
      if (!isTask) {
        sentence.objects.push({
          ...base,
          value: spf('in <a>workflow run</a> "%s"', esc(ctx.abbr(checklistName))),
          tooltip: checklistName,
        });
      }

      if (extended) {
        sentence.quote = activity.data.content;
      } else {
        sentence.objects.push({
          value: spf('saying "%s"', esc(ctx.abbr(unesc(activity.data.content)))),
          tooltip: activity.data.content,
        });
      }
      break;
    case Verb.UPDATED:
      sentence.verb.value = 'deleted';
      sentence.objects.push({
        ...base,
        value: spf('a comment from <a>task</a> "%s"', esc(ctx.abbr(taskName))),
        tooltip: taskName,
      });
      if (!isTask) {
        sentence.objects.push({
          ...base,
          value: spf('in <a>workflow run</a> "%s"', esc(ctx.abbr(checklistName))),
          tooltip: checklistName,
        });
      }

      sentence.objects.push({
        value: spf('saying "%s"', esc(ctx.abbr(unesc(activity.data.content)))),
        tooltip: activity.data.content,
      });
      break;
    default:
      ctx.logger.error('unrecognized verb: %s', activity.verb);
      sentence = undefined;
  }
  return sentence;
};

export const CommentActivityInterpreter = {
  interpret,
};
