import { NameRenderer } from './name-renderer';
import { WorkflowRunRenderer } from './workflow-run-renderer';
import { DueDateRenderer } from './due-date-renderer';
import { WorkflowRenderer } from './workflow-renderer';
import { AssigneeRenderer } from './assignee-renderer';
import { CommentsRenderer } from './comments-renderer';
import { HeaderRenderer } from './header-renderer';
import { SelectionHeaderRenderer } from './selection-header-renderer';
import { NewMenuRowRenderer } from './new-menu-row-renderer';
import { DueDateGroupRowRenderer } from './due-date-group-row-renderer';
import { LoadMoreRowRenderer } from 'pages/tasks/components/cell-renderer/load-more-row-renderer';
import { WorkflowRunGroupRowRenderer } from './workflow-run-group-row-renderer';
import { NewTaskRowRenderer } from './new-task-row-renderer';
import { WorkflowRunGroupingFullRowRenderer } from './workflow-run-grouping-full-row-renderer';

export const TasksTableCellRenderer = {
  AssigneeRenderer,
  CommentsRenderer,
  DueDateGroupRowRenderer,
  DueDateRenderer,
  HeaderRenderer,
  LoadMoreRowRenderer,
  NameRenderer,
  NewMenuRowRenderer,
  NewTaskRowRenderer,
  SelectionHeaderRenderer,
  WorkflowRenderer,
  WorkflowRunGroupRowRenderer,
  WorkflowRunGroupingFullRowRenderer,
  WorkflowRunRenderer,
};
