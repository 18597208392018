import { Plan } from '@process-street/subgrade/billing';
import { calculateRawAdditionalMemberCost } from '@process-street/subgrade/billing/plan-utils';
import { Muid, Organization, OrganizationDiscount } from '@process-street/subgrade/core';
import { createSelector, Selector } from 'reselect';
import { SessionSelector } from '../session/session.selectors';
import { OrganizationDiscountMap, ReduxAppState } from '../types';

const getDiscountMap: Selector<ReduxAppState, OrganizationDiscountMap> = (
  state: ReduxAppState,
): OrganizationDiscountMap => state.organization.discount;

const getDiscountByOrganizationId =
  (organizationId: Muid): Selector<ReduxAppState, OrganizationDiscount | undefined> =>
  (state: ReduxAppState): OrganizationDiscount | undefined =>
    organizationId ? getDiscountMap(state)[organizationId] : undefined;

const getCurrentOrganizationAdditionalMemberCost: Selector<ReduxAppState, number> = createSelector(
  [SessionSelector.getCurrentPlan, getDiscountMap, SessionSelector.getSelectedOrganization],
  (currentPlan: Plan | undefined, discounts: OrganizationDiscountMap, organization: Organization | undefined) => {
    if (!organization || !currentPlan) {
      return 0;
    }
    const {
      id: organizationId,
      subscription: { quantity },
    } = organization;
    const discount = discounts[organizationId];
    const additionalMemberCost = calculateRawAdditionalMemberCost(currentPlan, discount, quantity);
    return additionalMemberCost;
  },
);

export const OrganizationDiscountSelector = {
  getCurrentOrganizationAdditionalMemberCost,
  getDiscountByOrganizationId,
};
