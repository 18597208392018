import { Widget } from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import React from 'react';
import { EventName } from 'services/event-name';
import { useFunctionRef } from './use-function-ref';

export function useWidgetUpdateOverrideListener<W extends Widget>(widget: W, handler: (w: W) => void) {
  const { $rootScope } = useInjector('$rootScope');
  const handlerRef = useFunctionRef(handler);

  React.useEffect(() => {
    return $rootScope.$on(EventName.WIDGET_UPDATE_OVERRIDE, (_event, updatedWidget) => {
      if (updatedWidget.header.group.id === widget.header.group?.id) {
        handlerRef.current(updatedWidget as W);
      }
    });
    // Optimistic widget from angular might not have group 🙄
  }, [$rootScope, handlerRef, widget.header.group?.id]);
}
