import * as React from 'react';
import { SignupFlow } from './component';
import { Organization } from '@process-street/subgrade/core';
import { SignUpInfo } from 'pages/guest-signup/model/model';

export type GuestSignupWrapperProps = {
  organization: Organization;
  email: string;
  onSubmit: (info: SignUpInfo) => Promise<unknown>;
};

export const GuestSignupWrapper: React.FC<React.PropsWithChildren<GuestSignupWrapperProps>> = ({
  organization,
  onSubmit,
  email,
}) => {
  return <SignupFlow organization={organization} onSubmit={onSubmit} email={email} />;
};
