import { TemplateType } from '@process-street/subgrade/process';
import angular from 'angular';
import { match } from 'ts-pattern';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'ObjectRedirectCtrl',
    function (
      $ngRedux,
      $state,
      $timeout,
      Attachments,
      Comments,
      ChecklistService,
      ChecklistRevisionActions,
      SessionService,
      TemplateRevisionService,
      TemplateService,
      Tasks,
      ToastService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'ObjectRedirectCtrl' });
      logger.info('loading ctrl');

      ctrl.$onInit = () => {
        $timeout(redirect);
      };

      function redirect() {
        const andReplace = { location: 'replace' };
        function resourceNotFound(resourceName) {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems loading the ${resourceName}`,
            description: 'The workflow run has likely been updated, please refresh and try again.',
          });

          $state.go('dashboard', {}, andReplace);
        }

        switch ($state.params.type) {
          case 'User':
            $state.go('userManage', { id: $state.params.id }, andReplace);
            break;

          case 'Template': {
            TemplateService.getById($state.params.id).then(template => {
              const route = match(template.templateType)
                .with(TemplateType.Form, () => 'form')
                .with(TemplateType.Page, () => 'pageView')
                .with(TemplateType.Playbook, () => 'templateView')
                .run();

              $state.go(route, { id: $state.params.id }, andReplace);
            });
            break;
          }

          case 'TemplateRevision':
            retrieveTemplateRevision($state.params.id).then(revision => {
              const route = match(revision.template.templateType)
                .with(TemplateType.Form, () => 'form')
                .with(TemplateType.Page, () => 'pageView')
                .with(TemplateType.Playbook, () => 'templateView')
                .run();
              $state.go(route, { id: revision.template.id }, andReplace);
            }, resourceNotFound.bind(null, 'template revision'));

            break;

          case 'Checklist':
            ChecklistService.getById($state.params.id).then(checklist => {
              TemplateService.getById(checklist.template.id).then(template => {
                const route = match(template.templateType)
                  .with(TemplateType.Form, () => 'formResponse')
                  .with(TemplateType.Playbook, () => 'checklist')
                  .run();

                $state.go(route, { id: $state.params.id }, andReplace);
              });
            });
            break;

          case 'Group':
            $state.go('groupManage', { id: $state.params.id }, andReplace);
            break;

          case 'ChecklistTask': {
            const parts = $state.params.id.split('+');

            $state.go('checklist.task', { id: parts[0], groupId: parts[1] }, andReplace);
            break;
          }
          case 'ChecklistRevision':
            retrieveChecklistRevision($state.params.id).then(revision => {
              $state.go('checklist', { id: revision.checklist.id }, andReplace);
            }, resourceNotFound.bind(null, 'checklist revision'));

            break;

          case 'Task':
            retrieveTask($state.params.id).then(task => {
              $state.go(
                'checklist.task',
                {
                  id: task.checklistRevision.checklist.id,
                  groupId: task.taskTemplate.group.id,
                },
                andReplace,
              );
            }, resourceNotFound.bind(null, 'task'));

            break;

          case 'Comment':
            retrieveComment($state.params.id).then(comment => {
              $state.go(
                'checklist.task',
                {
                  id: comment.task.checklistRevision.checklist.id,
                  groupId: comment.task.taskTemplate.group.id,
                },
                andReplace,
              );
            }, resourceNotFound.bind(null, 'comment'));

            break;

          case 'Attachment':
            retrieveAttachment($state.params.id).then(attachment => {
              $state.go(
                'checklist.task',
                {
                  id: attachment.task.checklistRevision.checklist.id,
                  groupId: attachment.task.taskTemplate.group.id,
                },
                andReplace,
              );
            }, resourceNotFound.bind(null, 'attachment'));

            break;

          default:
            $state.go('dashboard', {}, andReplace);
        }
      }

      function retrieveTemplateRevision(id) {
        return TemplateRevisionService.getById(id);
      }

      function retrieveChecklistRevision(id) {
        const checklistRevisionPromise = $ngRedux.dispatch(ChecklistRevisionActions.get(id));
        return checklistRevisionPromise;
      }

      function retrieveTask(id) {
        return Tasks.get({
          id,
        }).$promise;
      }

      function retrieveComment(id) {
        return Comments.get({
          id,
        }).$promise;
      }

      function retrieveAttachment(id) {
        return Attachments.get({
          id,
        }).$promise;
      }
    },
  );
