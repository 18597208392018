import { User } from '@process-street/subgrade/core';
import { FileWidget } from '@process-street/subgrade/process';
import { FilePreview } from 'components/common/preview/FilePreview';
import { ImagePreview } from 'components/common/preview/ImagePreview';
import React from 'react';

export interface ChecklistFileWidgetProps {
  user: User;
  widget: FileWidget;
}

export const ChecklistFileWidget: React.FunctionComponent<React.PropsWithChildren<ChecklistFileWidgetProps>> = (
  props: ChecklistFileWidgetProps,
) => {
  const { file } = props.widget;

  if (file) {
    return (
      <>
        {file.mimeType.includes('image') && (
          <ImagePreview
            src={file.url}
            caption={props.widget.description || file.originalName}
            thumbnail={true}
            download={true}
          />
        )}

        {!file.mimeType.includes('image') && (
          <FilePreview file={file} caption={props.widget.description || file.originalName} />
        )}
      </>
    );
  } else {
    return <div />;
  }
};
