import * as React from 'react';
import { Checklist, ChecklistStatus } from '@process-street/subgrade/process';
import { Box, Divider, HStack, Icon, IconButton, List, Spacer, Text, Tooltip, VStack } from 'components/design/next';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { useOneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { OneOffTaskListItem } from './one-off-task-list-item';

export type OneOffTaskListProps = {
  checklist: Checklist;
  requiredTaskIds: OneOffTask['id'][];
  tasks: OneOffTask[];
};

export const OneOffTaskList: React.FC<React.PropsWithChildren<OneOffTaskListProps>> = ({
  checklist,
  requiredTaskIds,
  tasks,
}) => {
  const { createAttachedTask } = useOneOffTaskDrawerStore();

  const attachNewTask = () =>
    createAttachedTask({
      preselectedTemplateId: checklist.template.id,
      preselectedChecklistId: checklist.id,
    });

  return (
    <Box width="full" display="flex" flexDirection="column">
      <VStack
        spacing={4}
        marginLeft={8}
        marginTop={4}
        borderColor="gray.200"
        borderWidth="1px"
        borderStyle="solid"
        borderRadius="10px"
        backgroundColor="white"
        paddingX={3}
        paddingY={3}
      >
        <HStack width="full">
          <Text variant="-1u" color="gray.400">
            Tasks attached ({tasks.length})
          </Text>
          <Tooltip
            label={<Text fontSize="xs">Create a task attached to this workflow run</Text>}
            hasArrow
            placement="top"
            shouldWrapChildren
          >
            <Icon size="4" icon="info-circle" variant="far" color="gray.500" />
          </Tooltip>
          <Spacer />
          <IconButton
            aria-label="create task"
            variant="tertiary"
            color="gray.600"
            size="sm"
            mr={1}
            icon={<Icon icon="plus" variant="far" size="4" />}
            onClick={attachNewTask}
            isDisabled={checklist.status !== ChecklistStatus.Active}
          />
        </HStack>

        {tasks.length > 0 && (
          <List spacing={1} width="full">
            {tasks.map((task, index) => (
              <React.Fragment key={task.id}>
                <OneOffTaskListItem key={task.id} checklist={checklist} task={task} requiredTaskIds={requiredTaskIds} />
                {index === tasks.length - 1 ? null : (
                  <Box pr={2}>
                    <Divider />
                  </Box>
                )}
              </React.Fragment>
            ))}
          </List>
        )}
      </VStack>
    </Box>
  );
};
