import angular from 'angular';
import { reactToAngular } from 'angulareact';
import { ChecklistNameInput } from './checklist-name-input';

export const ChecklistNameInputModule = angular
  .module('workflowNameInputModule', [])
  .component(
    'psChecklistNameInput',
    reactToAngular(ChecklistNameInput, ['value', 'onChange', 'onBlur', 'isDisabled']),
  ).name;
