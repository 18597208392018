import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputRightElement,
  Text,
} from 'components/design/next';
import { useMergeTaggableInput } from 'hooks/use-merge-taggable-input';
import { MergeTagsMenu, MergeTagsMenuButton } from 'features/merge-tags/components/merge-tags-menu';
import { useTemplateId } from 'pages/templates/_id/automation/utils/use-template-id';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { WidgetUtils } from '@process-street/subgrade/process';
import { formikMetaToFormControlProps } from '../fields/utils';
import { TextSettingsSchema } from 'components/widgets/form-field/settings/text-settings/text-settings-schema';
import { Input, InputProps } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { EventName } from 'services/event-name';
import { useWidgetSettingsContext } from '../widget-settings-context';

export const DefaultValueField: React.FC<React.PropsWithChildren<InputProps>> = props => {
  const [{ onChange: _, ...field }, meta] = useField<string | undefined>('defaultValue');
  const context = useFormikContext<TextSettingsSchema>();
  const {
    widget: { id: widgetId },
  } = useWidgetSettingsContext();

  const { ref: defaultValueRef, insertMergeTag: insertVariable } = useMergeTaggableInput<HTMLInputElement>({
    get: () => field.value ?? '',
    set: value => {
      context.setValues({ ...context.values, defaultValue: value, hasVariables: WidgetUtils.hasVariables(value) });
    },
  });
  const templateId = useTemplateId();
  const templateRevisionQuery = useNewestTemplateRevisionQuery({ templateId, editable: true });
  const templateRevisionId = templateRevisionQuery.data?.id;
  const currentValueHasVariables = WidgetUtils.hasVariables(field.value);
  const { $rootScope } = useInjector('$rootScope');
  React.useEffect(() => {
    return $rootScope.$on(EventName.DEFAULT_VALUE_SETTINGS_CLICK, (_event, widget) => {
      if (widget.id === widgetId) {
        defaultValueRef.current?.focus();
      }
    });
  }, [$rootScope, defaultValueRef, widgetId]);

  return (
    <FormControl {...formikMetaToFormControlProps(meta)}>
      <FormLabel htmlFor="defaultValue">
        <Text color="gray.700">Default answer</Text>
      </FormLabel>

      <InputGroup>
        <Input
          {...field}
          value={field.value ?? ''}
          id="defaultValue"
          ref={defaultValueRef}
          aria-label="optional default answer"
          placeholder="Type default answer here"
          variant="outline"
          {...props}
          // custom onChange here to support a dynamic yup schema
          onChange={e => {
            const { value } = e.target;
            context.setValues({
              ...context.values,
              defaultValue: value,
              hasVariables: WidgetUtils.hasVariables(value),
            });
            props.onChange?.(e);
          }}
        />
        {templateRevisionId && (
          <InputRightElement _focusWithin={{ zIndex: 3 }}>
            <MergeTagsMenu
              {...{
                templateRevisionId,
                onSelect: (key, _fieldId, fallback) => insertVariable(key, fallback),
                mergeTagTarget: MergeTagsConstants.Target.GENERAL,
                menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>

      {currentValueHasVariables ? (
        <FormHelperText>Validation disabled due to {'{{ variables }}'}</FormHelperText>
      ) : null}
      {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};
