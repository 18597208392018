import angular from 'angular';
import { SessionSelector } from 'reducers/session/session.selectors';
import { createSelector } from 'reselect';
import templateUrl from './topbar.component.html';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';

angular.module('frontStreetApp.directives').component('psTopbar', {
  templateUrl,
  controller($ngRedux, $scope, OrganizationService, ToastService) {
    const ctrl = this;

    const mapStateToCtrl = createSelector([SessionSelector.getCurrentUser], user => {
      const userIsAnonymous = !user || isAnonymousUser(user);

      return {
        user,
        userIsAnonymous,
      };
    });
    ctrl.storeUnsubscribe = $ngRedux.connect(mapStateToCtrl)(ctrl);

    ctrl.$onInit = function () {
      if (!ctrl.user) {
        return;
      }

      showResendNoticeIfNeeded();
    };

    ctrl.$onDestroy = function () {
      if (ctrl.storeUnsubscribe) {
        ctrl.storeUnsubscribe();
      }
    };

    function wasResendNotificationSentRecently() {
      const tenMinutes = 10 * 60 * 1000;
      const now = Date.now();

      if (ctrl.user.emailVerificationSentDate) {
        return now - ctrl.user.emailVerificationSentDate > tenMinutes;
      } else {
        return true;
      }
    }

    // Resend verification email
    // TODO port to React
    function showResendNoticeIfNeeded() {
      if (!ctrl.userIsAnonymous && !ctrl.user.emailVerified) {
        const ONE_DAY_MS = 24 * 60 * 60 * 1000;
        const createdDatePlusOneDay = ctrl.user.audit.createdDate + ONE_DAY_MS;

        if (Date.now() > createdDatePlusOneDay && wasResendNotificationSentRecently()) {
          ToastService.openToast({
            status: 'warning',
            title: 'Email address is not verified',
            description:
              'To receive email notifications for assignments and due dates, please verify your email address in your profile.',
          });
        }
      }
    }

    ctrl.switchOrganization = function (organizationId) {
      OrganizationService.switchById(organizationId, true /* redirectToHome */);
    };
  },
});
