/**
 * Task Status
 * @readonly
 * @memberof TaskConstants
 * @enum {string}
 */
enum Mode {
  INBOX = 'Inbox',
  CHECKLIST_EDITOR = 'ChecklistEditor',
}

export const TaskListConstants = {
  Mode,
};
