import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { InboxItemHeader } from '.';

export const InboxItemHeaderModule = angular
  .module('InboxItemHeaderModule', [])
  .component(
    'psInboxItemHeader',
    reactToAngular(InboxItemHeader, [
      'item',
      'isChecklistTaskSelected',
      'resolveItemTitle',
      'selectedTaskItem',
      'returnToChecklist',
      'resolveTaskItemSubtitle',
    ]),
  ).name;
