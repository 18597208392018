import { ProgressStatus } from '@process-street/subgrade/dashboard';
import React from 'react';
import styles from './BlvdPill.module.scss';

export enum PillStyle {
  Solid = 'Solid',
  Light = 'Light',
  Pastel = 'Pastel',
}

export enum PillSize {
  Small = 'Small',
  Large = 'Large',
}

const statusClasses = {
  [ProgressStatus.Archived]: styles.archived,
  [ProgressStatus.Completed]: styles.completed,
  [ProgressStatus.Overdue]: styles.overdue,
  [ProgressStatus.DueSoon]: styles.dueSoon,
  [ProgressStatus.OnTrack]: styles.onTrack,
};

const styleClasses = {
  [PillStyle.Solid]: 'solid',
  [PillStyle.Light]: 'light',
  [PillStyle.Pastel]: 'pastel',
};

const sizeClasses = {
  [PillSize.Small]: styles.pillSmall,
  [PillSize.Large]: styles.pillLarge,
};

const labels = {
  [ProgressStatus.Archived]: 'Archived',
  [ProgressStatus.Completed]: 'Completed',
  [ProgressStatus.Overdue]: 'Overdue',
  [ProgressStatus.DueSoon]: 'Due Soon',
  [ProgressStatus.OnTrack]: 'On-Track',
};

interface BlvdPillProps {
  status: ProgressStatus;
  size: PillSize;
  pillStyle: PillStyle;
}

export const BlvdPill: React.FunctionComponent<React.PropsWithChildren<BlvdPillProps>> = ({
  status,
  size,
  pillStyle,
}) => (
  <div className={`${sizeClasses[size]} ${statusClasses[status]} ${styleClasses[pillStyle]}`}>{labels[status]}</div>
);
