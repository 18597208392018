import * as React from 'react';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import {
  Text,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  PopoverBody,
  PopoverHeader,
  PopoverCloseButton,
} from 'components/design/next';
import { TaskAssignmentRuleWithWidget, UserWithAssignmentId } from './use-task-assignments';
import { TaskAssignments, TaskAssignmentRule, TaskAssignmentUser } from '../task-assignments';
import { useMatch } from '@process-street/adapters/navigation';

export type ViewAssigneesMenuProps = React.PropsWithChildren<{
  taskTemplate: TaskTemplate;
  templateRevision: TemplateRevision;
  assignees: UserWithAssignmentId[];
  rules: TaskAssignmentRuleWithWidget[];
  isEditable?: boolean;
}>;

export const ViewAssigneesMenu = ({
  taskTemplate,
  templateRevision,
  assignees,
  rules,
  isEditable,
  children,
}: ViewAssigneesMenuProps) => {
  const isEditorV2 = Boolean(useMatch('templateV2')) || Boolean(useMatch('templateViewV2'));
  return (
    <Popover isLazy>
      <PopoverTrigger>{children}</PopoverTrigger>

      <Portal>
        <PopoverContent>
          <PopoverHeader>
            <Text variant="-1" color="gray.700" fontWeight="medium">
              Assignees
            </Text>

            <PopoverCloseButton
              padding="0"
              textTransform="none"
              borderStyle="none"
              outline="initial"
              margin="0"
              marginTop={1}
              cursor="pointer"
              backgroundColor="transparent"
              fontSize="xs"
              fontWeight="md"
              color="gray.500"
              w="24px!important"
            />
          </PopoverHeader>
          <PopoverArrow />
          <PopoverBody pb="4">
            <TaskAssignments>
              {assignees.map(assignee => (
                <TaskAssignmentUser
                  key={assignee.id}
                  user={assignee}
                  taskTemplatesIds={[taskTemplate.id]}
                  templateRevisionId={templateRevision.id}
                  isReadOnly={!isEditable}
                  isEditorV2={isEditorV2}
                />
              ))}
              {rules.map(rule => (
                <TaskAssignmentRule
                  key={rule.id}
                  rule={rule}
                  templateRevisionId={templateRevision.id}
                  isReadOnly={!isEditable}
                  isEditorV2={isEditorV2}
                />
              ))}
            </TaskAssignments>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
