import angular from 'angular';
import { htmlEscaped } from '@process-street/subgrade/util';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'TagManagePropertiesCtrl',
    function ($scope, $state, MessageBox, SessionService, Tags, TagService, ToastService) {
      const ctrl = this;
      const logger = trace({ name: 'TagManagePropertiesCtrl' });
      logger.info('loading ctrl');

      ctrl.$onInit = () => {
        initialize();
      };

      $scope.update = function (tag, info) {
        $scope.updating = true;

        Tags.update({
          id: tag.id,
          name: info.name,
        })
          .$promise.then(
            () => {
              AnalyticsService.trackEvent('tag updated');

              ToastService.openToast({
                status: 'success',
                title: `Tag updated`,
              });

              $scope.form.$setPristine();
              tag.name = info.name;
              $scope.form.$setPristine();
              $scope.$emit(EventName.TAG_UPDATED, tag);
            },
            () => {
              logger.error('failed to update tag');

              ToastService.openToast({
                status: 'error',
                title: `We're having problems updating the tag`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          )
          .finally(() => {
            $scope.updating = false;
          });
      };

      $scope.delete = function (tag) {
        MessageBox.confirm({
          title: 'Delete this tag?',
          message: htmlEscaped`Are you sure you want to delete the tag <b>${tag.name}</b>?`,
          okButton: {
            type: 'danger',
            text: 'Delete',
            action: doDelete.bind(null, tag),
          },
        });
      };

      function doDelete(tag) {
        $scope.deleting = true;

        Tags.delete({ id: tag.id }).$promise.then(
          () => {
            AnalyticsService.trackEvent('tag deleted');

            ToastService.openToast({
              status: 'success',
              title: `Tag deleted`,
            });

            $state.go('dashboard');
          },
          () => {
            logger.error('failed to delete tag');

            ToastService.openToast({
              status: 'error',
              title: `We're having problems deleting the tag`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });

            $scope.deleting = false;
          },
        );
      }

      // Initialization

      function initialize() {
        TagService.getById($state.params.id).then(tag => {
          $scope.tag = tag;

          initializeForm($scope.tag);
        });
      }

      function initializeForm(tag) {
        $scope.info = {
          name: tag.name,
        };
      }
    },
  );
