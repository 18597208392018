import { match } from 'ts-pattern';
import create from 'zustand';

type Tab = 'email' | 'run-link';
interface TriggersModalState {
  tab: Tab;
  isOpen: boolean;
}

interface TriggersModalEffects {
  openEmailTab: () => void;
  openRunLinkTab: () => void;
  changeTab: (tab: Tab) => void;
  onClose: () => void;
}

type TriggersModalStore = TriggersModalState & TriggersModalEffects;

export const useTriggersModalStore = create<TriggersModalStore>(set => ({
  tab: 'run-link',
  isOpen: false,
  openEmailTab: () => set({ tab: 'email', isOpen: true }),
  openRunLinkTab: () => set({ tab: 'run-link', isOpen: true }),
  changeTab: (tab: Tab) => set({ tab }),
  onClose: () => set({ isOpen: false }),
}));

export const TriggersModalStoreUtls = {
  tabToIndex: (tab: Tab): number =>
    match(tab)
      .with('email', () => 1)
      .with('run-link', () => 0)
      .exhaustive(),
  indexToTab: (index: number): Tab =>
    match<number, Tab>(index)
      .with(0, () => 'run-link')
      .with(1, () => 'email')
      .otherwise(() => 'run-link'),
};
