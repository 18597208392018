import { PremadeTemplateOverview } from '@process-street/subgrade/process/premade-template-model';
import styles from 'components/template-gallery/components/TemplateGalleryCards/TemplateGalleryCards.module.scss';
import { TemplateGalleryCard } from 'components/template-gallery/components/TemplateGalleryCard';
import React from 'react';

type TemplateGalleryCardsProps = {
  templates: PremadeTemplateOverview[];
  onSelect: (templateOverview: PremadeTemplateOverview) => void;
};

export const TemplateGalleryCards: React.FC<React.PropsWithChildren<TemplateGalleryCardsProps>> = ({
  templates,
  onSelect,
}) => {
  return (
    <div className={styles.container}>
      {templates.map(template => (
        <div className={styles.singleCardContainer} key={template.templateId}>
          <TemplateGalleryCard premadeTemplateOverview={template} onSelect={onSelect} />
        </div>
      ))}
    </div>
  );
};
