// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../images/task-template/conditional-upgrade-img.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"add-rules-modal\"> <div class=\"modal-header\"> <button type=\"button\" data-ng-click=\"$close()\" class=\"close\">&times;</button> <h4 class=\"modal-title text-center\"> <span ng-if=\"!canUpgrade\">Conditional Logic</span> <span ng-if=\"canUpgrade\" class=\"upgrade-title\"> <span>More flexible workflows with Conditional Logic</span> <span class=\"badge badge-success ml-1\">Standard</span> </span> </h4> </div> <div class=\"modal-body text-center\"> <div> <div class=\"row\"> <div class=\"col-xs-12 explanation-box\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </div> <p> Processes don't always follow one path. Conditions allow<br/> you to show and hide certain tasks based on form fields. </p> </div> </div> <div class=\"upgrade-btn-box\"> <button class=\"btn btn-primary text-center\" ng-if=\"rulesFeatureIsAvailable\" data-ng-click=\"openRulesManagerModal()\"> Ok, Add Logic! </button> <a class=\"btn btn-primary\" ng-if=\"!rulesFeatureIsAvailable && canUpgrade\" data-ui-sref=\"organizationManage.tab({ tab: 'billing' })\" data-ng-click=\"close()\" data-ui-sref-opts=\"{ reload: true }\"> Upgrade to Standard </a> <p ng-if=\"!rulesFeatureIsAvailable && !canUpgrade\"><br/>Contact an administrator to upgrade your plan.</p> </div> <div class=\"upgrade-btn-box\"> <a href=\"http://www.process.st/help/docs/conditional-logic/\" target=\"_blank\" rel=\"noopener noreferrer\"> Learn more &raquo; </a> </div> </div> </div> ";
// Exports
var _module_exports = code;;
var path = '/directives/rules/template/conditionals-button/upgrade-modal/upgrade-modal.component.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;