import { IncomingWebhook, IncomingWebhookOptimistic } from '@process-street/subgrade/automation';
import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetAllIncomingWebhooksByDataSetIdQueryParams = {
  dataSetId?: Muid;
};

export type GetAllIncomingWebhooksByDataSetIdQueryResponse = (IncomingWebhook | IncomingWebhookOptimistic)[];

export const GetAllIncomingWebhooksByDataSetIdQuery = {
  key: ['data-sets', 'incoming-webhooks'],
  getKey: (params: GetAllIncomingWebhooksByDataSetIdQueryParams): QueryKey => [
    ...GetAllIncomingWebhooksByDataSetIdQuery.key,
    params,
  ],
  queryFn: (params: GetAllIncomingWebhooksByDataSetIdQueryParams) =>
    axiosService
      .getAxios()
      .get<GetAllIncomingWebhooksByDataSetIdQueryResponse>(`/1/data-sets/${params.dataSetId}/incoming-webhooks`)
      .then(res => res.data),
};

export const useGetAllIncomingWebhooksByDataSetIdQuery = <Select = GetAllIncomingWebhooksByDataSetIdQueryResponse>(
  params: GetAllIncomingWebhooksByDataSetIdQueryParams,
  options: UseQueryOptions<GetAllIncomingWebhooksByDataSetIdQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetAllIncomingWebhooksByDataSetIdQuery.getKey(params),
    () => GetAllIncomingWebhooksByDataSetIdQuery.queryFn(params),
    {
      ...options,
      enabled: Boolean(params.dataSetId) && options.enabled !== false,
    },
  );
};
