import React from 'react';
import { useInjector } from 'components/injection-provider';
import { useCreateTemplatePaywall } from 'components/paywalls/create-template';
import { EventName } from 'services/event-name';
import { PaywallHook } from 'components/paywalls/paywall-button';

type PaywallEventDrivenProps = {
  event: EventName['TEMPLATE_SHOW_PAYWALL'];
};

const Hooks: Record<PaywallEventDrivenProps['event'], PaywallHook> = {
  [EventName.TEMPLATE_SHOW_PAYWALL]: useCreateTemplatePaywall,
};

export const PaywallEventDriven: React.FunctionComponent<React.PropsWithChildren<PaywallEventDrivenProps>> = ({
  event,
}) => {
  const useHook = Hooks[event];
  if (!useHook) {
    throw new Error(`Paywall not configured for ${event}`);
  }

  const { onClick } = useHook();
  const { $rootScope } = useInjector('$rootScope');

  React.useEffect(() => {
    if (onClick) {
      const unsubscribe = $rootScope.$on(event, onClick);
      return () => {
        unsubscribe();
      };
    }
  }, [onClick, event, $rootScope]);

  return null;
};
