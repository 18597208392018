import { DateFormFieldActor, DateFormFieldWidget, makeDateFormFieldMachine } from './date-form-field';
import { EmailFormFieldWidget, EmailFormFieldActor, makeEmailFormFieldMachine } from './email-form-field';
import { FileFormFieldWidget, FileFormFieldActor, makeFileFormFieldMachine } from './file-form-field';
import { HiddenFormFieldActor, makeHiddenFormFieldMachine } from './hidden-form-field';
import { HiddenFormFieldWidget } from './hidden-form-field/hidden-form-field';
import { makeMembersFormFieldMachine, MembersFormFieldActor, MembersFormFieldWidget } from './members-form-field';
import { makeNumberFormFieldMachine, NumberFormFieldActor, NumberFormFieldWidget } from './number-form-field';
import { SelectFormFieldWidget, SelectFormFieldActor, makeSelectFormFieldMachine } from './select-form-field';
import { SnippetFormFieldWidget, makeSnippetFormFieldMachine, SnippetFormFieldActor } from './snippet-form-field';
import { TableFormFieldWidget, TableFormFieldActor, makeTableFormFieldMachine } from './table-form-field';
import { TextFormFieldWidget, TextFormFieldActor, makeTextFormFieldMachine } from './text-form-field';
import { TextareaFormFieldWidget, TextareaFormFieldActor, makeTextareaFormFieldMachine } from './textarea-form-field';
import { UrlFormFieldWidget, UrlFormFieldActor, makeUrlFormFieldMachine } from './url-form-field';

export const FormFields = {
  Url: UrlFormFieldWidget,
  Email: EmailFormFieldWidget,
  Text: TextFormFieldWidget,
  Textarea: TextareaFormFieldWidget,
  File: FileFormFieldWidget,
  Select: SelectFormFieldWidget,
  Date: DateFormFieldWidget,
  Number: NumberFormFieldWidget,
  Table: TableFormFieldWidget,
  Hidden: HiddenFormFieldWidget,
  Snippet: SnippetFormFieldWidget,
  Members: MembersFormFieldWidget,
};

export type FormFieldActor = {
  Date: DateFormFieldActor;
  Email: EmailFormFieldActor;
  File: FileFormFieldActor;
  Hidden: HiddenFormFieldActor;
  Members: MembersFormFieldActor;
  Number: NumberFormFieldActor;
  Select: SelectFormFieldActor;
  Snippet: SnippetFormFieldActor;
  Table: TableFormFieldActor;
  Text: TextFormFieldActor;
  Textarea: TextareaFormFieldActor;
  Url: UrlFormFieldActor;
};

export const FormFieldMachineFactories = {
  Url: makeUrlFormFieldMachine,
  Email: makeEmailFormFieldMachine,
  Text: makeTextFormFieldMachine,
  Textarea: makeTextareaFormFieldMachine,
  File: makeFileFormFieldMachine,
  Select: makeSelectFormFieldMachine,
  Date: makeDateFormFieldMachine,
  Number: makeNumberFormFieldMachine,
  Table: makeTableFormFieldMachine,
  Hidden: makeHiddenFormFieldMachine,
  Snippet: makeSnippetFormFieldMachine,
  Members: makeMembersFormFieldMachine,
};
