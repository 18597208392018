import * as React from 'react';
import { Box, HStack, BoxProps, Tooltip } from 'components/design/next';
import { usePrintStore } from 'components/react-root/print-store';
import { AiTaskAuditHelperText, CommonAuditProps, ProcessPeteCircle, ProcessPeteIcon } from './common';
import { useWorkflowRunStore } from '../../workflow-run-store';
import { match } from 'ts-pattern';
import { useUnmount } from 'react-use';
import { useWidgetContext } from '../../hooks/use-widget-context';

export interface RunningAiTaskAuditProps extends BoxProps, CommonAuditProps {}

export const RunningAiTaskAudit: React.FC<React.PropsWithChildren<RunningAiTaskAuditProps>> = ({
  aiTaskTemplate: _,
  ...props
}) => {
  const { widget } = useWidgetContext();
  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;
  const hasBeenClicked = useWorkflowRunStore(state => {
    return state.outputWidgetsClickedDuringAiTask.has(widget.id);
  });
  const removeWasClicked = useWorkflowRunStore(state => state.removeOutputWidgetClickedDuringAiTask);
  useUnmount(() => {
    removeWasClicked(widget.id);
  });

  return (
    <Box {...props}>
      <HStack>
        <Tooltip label="This form field is an output of an AI task" shouldWrapChildren hasArrow>
          <ProcessPeteCircle bg={`${hasBeenClicked ? 'yellow' : 'gray'}.100`}>
            <ProcessPeteIcon color={hasBeenClicked ? 'yellow.600' : undefined} />
          </ProcessPeteCircle>
        </Tooltip>

        <AiTaskAuditHelperText
          color={match({ isPrintMode, hasBeenClicked })
            .with({ isPrintMode: true }, () => 'gray.200')
            .with({ hasBeenClicked: true }, () => 'yellow.600')
            .otherwise(() => 'gray.400')}
        >
          AI task is running&hellip;
        </AiTaskAuditHelperText>
      </HStack>
    </Box>
  );
};
