import { BlobWithName } from 'components/file-upload/types';

const IMAGE_JPEG = 'image/jpeg';

export const getImageOrientation = (file: BlobWithName): Promise<number> => {
  const reader = new FileReader();

  return new Promise(resolve => {
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (!event || !event.target || !event.target.result) {
        // not orientation defined
        return resolve(-1);
      }

      const view: DataView = new DataView(event.target.result as ArrayBuffer);

      if (view.getUint16(0, false) !== 0xffd8) {
        // Check valid jpeg (look for jpeg headers)
        return resolve(-2);
      }

      const length = view.byteLength;
      let offset = 2;

      /* eslint-disable no-bitwise */
      while (offset < length) {
        const marker = view.getUint16(offset, false);
        offset += 2;

        if (marker === 0xffe1) {
          // look for exif header
          // ascii hex values of the string 'Exif'
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return resolve(-1); // No Exif data, might be XMP data instead
          }
          // Now determine the endianness of the tags by checking the
          // 8 byte TIFF header (check the byte alignment)
          const little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);

          const tags = view.getUint16(offset, little);
          offset += 2;

          // Now sloppily search for the orientation tag id
          for (let i = 0; i < tags; i += 1) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              return resolve(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          // check end section marker
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return resolve(-1); // not orientation defined
    };

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  });
};

export const resetImageOrientation = (file: Blob, orientation: number, compression?: number): Promise<BlobWithName> => {
  const reader = new window.FileReader();

  return new Promise(resolve => {
    reader.onload = event => {
      const img = new window.Image();
      img.onload = () => {
        const { width, height } = img;
        const canvas = window.document.createElement('canvas');
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

        // set proper canvas dimensions before transform & export
        if (orientation > 4 && orientation < 9) {
          canvas.width = height;
          canvas.height = width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }

        // transform context before drawing image
        switch (orientation) {
          case 2: // horizontal flip
            ctx.transform(-1, 0, 0, 1, width, 0);
            break;
          case 3: // 180 deg rotate left
            ctx.transform(-1, 0, 0, -1, width, height);
            break;
          case 4: // vertical flip
            ctx.transform(1, 0, 0, -1, 0, height);
            break;
          case 5: // vertical flip + 90 deg rotate right
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6: // 90 deg rotate right
            ctx.transform(0, 1, -1, 0, height, 0);
            break;
          case 7: // horizontal flip + 90 deg rotate right
            ctx.transform(0, -1, -1, 0, height, width);
            break;
          case 8: // 90 deg rotate left
            ctx.transform(0, -1, 1, 0, 0, width);
            break;
          default:
            break;
        }

        ctx.drawImage(img, 0, 0);

        const canvasCompression = typeof compression !== 'undefined' ? compression : 0.5;
        const dataURI = canvas.toDataURL(IMAGE_JPEG, canvasCompression);
        const binary = window.atob(dataURI.split(',')[1]);
        const imageByteArray = [];
        for (let i = 0; i < binary.length; i += 1) {
          imageByteArray.push(binary.charCodeAt(i));
        }

        resolve(new window.Blob([new Uint8Array(imageByteArray)], { type: IMAGE_JPEG }) as BlobWithName);
      };

      if (event.target && event.target.result) {
        img.src = event.target.result as string;
      }
    };

    reader.readAsDataURL(file);
  });
};

export const adjustImageOrientation = async (file: BlobWithName) => {
  if (file && file.type === IMAGE_JPEG) {
    const orientation = await getImageOrientation(file);
    const blob = await resetImageOrientation(file, orientation);
    blob.name = file.name;
    return blob;
  } else {
    return file;
  }
};
