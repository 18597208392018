import angular from 'angular';
import { match } from 'ts-pattern';
import buttonTemplate from './stop-task-button.component.html';
import './stop-task-button.scss';

angular.module('frontStreetApp.directives').component('psStopTaskButton', {
  bindings: {
    taskTemplate: '<',
    organization: '<',
    user: '<',
    disabled: '<',
  },
  templateUrl: buttonTemplate,
  controller(StopTaskButtonService) {
    const ctrl = this;

    ctrl.addStop = function () {
      StopTaskButtonService.addStop({
        taskTemplate: ctrl.taskTemplate,
        organization: ctrl.organization,
        user: ctrl.user,
      });
    };

    ctrl.getTooltipLabel = () => {
      return match({
        disabled: ctrl.disabled,
        stop: ctrl.taskTemplate.stop,
      })
        .with({ disabled: true, stop: true }, () => 'Stop task')
        .with({ stop: true }, () => 'Remove stop')
        .with({ stop: false }, () => 'Add stop')
        .otherwise(() => '');
    };
  },
});
