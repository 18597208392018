import { PingResponse } from '@process-street/subgrade/core/ping-response';
import { PingResource } from 'app/resources/ping-resource.interfaces';
import angular from 'angular';

export interface PingApi {
  ping(): angular.IPromise<PingResponse>;
}

export class PingApiImpl implements PingApi {
  public static $inject = ['Ping'];

  constructor(private pingResource: PingResource) {}

  public ping = (): angular.IPromise<PingResponse> => this.pingResource.ping().$promise;
}
