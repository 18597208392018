import * as React from 'react';
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Text,
  Tooltip,
} from 'components/design/next';
import { ToolbarButton, ToolbarTooltip } from '../common';
import { useLinkButton, TargetBlank, TargetSelf } from './use-link-button';

export const LinkButton: React.VFC = React.memo(() => {
  const {
    href,
    setHref,
    target,
    setTarget,
    linkActive,
    isOpen,
    onClose,
    onOpen,
    onMouseDown,
    removeLink,
    submit,
    linkEntry,
    restoreSelection,
    setForcedState,
    submitAttempted,
  } = useLinkButton();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const urlValidationMessage = inputRef.current?.validationMessage ?? '';
  const isInvalid = !!urlValidationMessage;
  const showValidationMessage = submitAttempted && isInvalid;

  return (
    <Popover
      isLazy={true}
      isOpen={isOpen}
      initialFocusRef={inputRef}
      onClose={() => {
        restoreSelection();
        onClose();
        setForcedState('none');
      }}
      onOpen={onOpen}
    >
      <ToolbarTooltip label="Link">
        {/* Box needed to make tooltip work on popover trigger */}
        <Box>
          <PopoverTrigger>
            <ToolbarButton
              aria-label={linkActive ? 'Link applied to selection' : 'Link not applied to selection'}
              color={linkActive ? 'brand.300' : 'white'}
              rightIcon={<Icon icon="caret-down" variant="fas" size="3" />}
              onMouseDown={onMouseDown}
            >
              <Icon icon="link" size="4" />
            </ToolbarButton>
          </PopoverTrigger>
        </Box>
      </ToolbarTooltip>

      <PopoverContent w="80">
        <PopoverArrow />
        <PopoverBody>
          <Input
            mb="2"
            placeholder="Enter URL"
            type="url"
            ref={inputRef}
            variant="outline"
            value={href}
            onChange={e => setHref(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                submit({ validationMessage: urlValidationMessage });
              }
            }}
          />

          {href ? (
            <>
              <Switch
                id="link-target"
                isChecked={target === TargetBlank}
                onChange={() => setTarget(target === TargetBlank ? TargetSelf : TargetBlank)}
                size="lg"
                spacing="2"
                display="flex"
                alignItems="center"
              >
                <Text variant="-1" fontWeight="normal" color="gray.500" as="span">
                  Open in new window
                </Text>
              </Switch>

              <Divider mt="4" mb="2" />

              <HStack justifyContent="space-between">
                <Button variant="ghost" onClick={removeLink} disabled={!linkEntry} size="xs" color="black">
                  Remove link
                </Button>

                <Tooltip
                  label={urlValidationMessage}
                  isDisabled={!isInvalid}
                  {...(showValidationMessage ? { isOpen: true } : {})}
                  shouldWrapChildren={true}
                  variant="error"
                >
                  <Button
                    variant="primary"
                    onClick={() => submit({ validationMessage: urlValidationMessage })}
                    size="sm"
                    isDisabled={isInvalid}
                  >
                    Save
                  </Button>
                </Tooltip>
              </HStack>
            </>
          ) : null}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});
