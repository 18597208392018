import { createUsableContext } from '@process-street/subgrade/util';
import { Node, NodeInfoMap } from 'directives/rules/template/task-templates-selector/selector-helper';

export type TaskTemplatesSelectorContextValue = {
  nodeInfoMap: NodeInfoMap;
  searchTerm: string;
  onFold: (node: Node) => void;
};

export const [useTaskTemplatesSelectorContext, TaskTemplatesSelectorContext] =
  createUsableContext<TaskTemplatesSelectorContextValue>({
    hookName: 'useTaskTemplatesSelectorContext',
    providerName: 'TaskTemplatesSelectorProvider',
  });
