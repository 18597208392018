import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { canAccess, Feature } from 'services/features/features';

export const useUserCanViewTemplates = (): boolean => {
  const userIsGuest = useSelector(SessionSelector.isUserGuestOfSelectedOrganization);
  const plan = useSelector(SessionSelector.getCurrentPlan);
  const guestCanRunChecklist = canAccess(Feature.GUESTS_CAN_CREATE_CHECKLISTS, plan?.id);

  return !userIsGuest || guestCanRunChecklist;
};
