import * as React from 'react';
import { Center, Spinner } from 'components/design/next';
import { PlanSelectorModal } from 'pages/organizations/manage/billing/components/plan-selector';
import { Plan, PlanInterval, PlanLevel } from '@process-street/subgrade/billing';
import { PlanLevelSelectorState } from 'directives/billing/plan-level-selector/plan-level-selector-service.interface';
import {
  Option,
  Organization,
  OrganizationDiscount,
  Subscription,
  SubscriptionCancellationForm,
} from '@process-street/subgrade/core';
import {
  CardInfo,
  IntervalToPlanCostInfoMap,
  PlanActionType,
  PlanCostQuantity,
  PlanMap,
  PlanSubscriptionDetails,
} from '../../models';
import { getEnv } from 'components/common/env';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { PlanDetails } from 'pages/organizations/manage/billing/components/plan-details';
import { PlanAlertWrapper } from 'pages/organizations/manage/billing/components/plan-alert/wrapper';
import {
  PlanBillingSummary,
  PlanBillingSummaryMode,
} from 'pages/organizations/manage/billing/components/billing-summary/component';
import { PlanBillingDetails } from 'pages/organizations/manage/billing/components/billing-details/component';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { useBillingProps } from 'pages/organizations/manage/billing/components/billing-tab/use-billing-props';

export type BillingTabProps = {
  currentPlan: Plan;
  discount: OrganizationDiscount;
  organization: Organization;
};

export type BillingProps = {
  billingDetailsVisible: boolean;
  billingMode: Option<PlanBillingSummaryMode>;
  cancelSubscription: (cancellationForm: SubscriptionCancellationForm) => void;
  cancelSubscriptionDisclosure: any;
  cardInfoState: AsyncState<CardInfo | undefined>;
  contactUs: () => void;
  costMap: Option<IntervalToPlanCostInfoMap>;
  currentPlan: Plan;
  discount: OrganizationDiscount;
  handleChangeBillingCycle: () => void;
  handleEmailUpdate: (email: string) => void;
  handlePlanAction: (action: PlanActionType) => void;
  handlePlanSelect: (level: PlanLevel, selectorState: PlanLevelSelectorState) => boolean;
  handleStripeError: () => void;
  openPlanSelector: () => void;
  organization: Organization;
  planCostQuantity: Option<PlanCostQuantity>;
  planMapState: AsyncState<PlanMap>;
  planSubscriptionDetailsState: AsyncState<PlanSubscriptionDetails>;
  plansDisclosure: any;
  quantity: number;
  selectedInterval: PlanInterval;
  selectedPlanLevel: PlanLevel;
  setCountry: React.Dispatch<React.SetStateAction<Option<string>>>;
  setSelectedInterval: React.Dispatch<React.SetStateAction<PlanInterval>>;
  setZip: React.Dispatch<React.SetStateAction<Option<string>>>;
  shouldShowOnlyYearlyBillingCycle: boolean;
  submitting: boolean;
  subscription: Subscription;
  subscriptionIsCancelable: boolean;
  updatePlan: () => void;
};

export const BillingTab: React.FC<React.PropsWithChildren<BillingTabProps>> = props => {
  const {
    billingDetailsVisible,
    billingMode,
    cardInfoState,
    contactUs,
    costMap,
    currentPlan,
    discount,
    handlePlanAction,
    handlePlanSelect,
    handleStripeError,
    openPlanSelector,
    organization,
    planCostQuantity,
    planMapState,
    planSubscriptionDetailsState,
    plansDisclosure,
    quantity,
    selectedInterval,
    selectedPlanLevel,
    setCountry,
    setSelectedInterval,
    setZip,
    shouldShowOnlyYearlyBillingCycle,
    submitting,
    subscription,
    updatePlan,
  } = useBillingProps(props);

  return (
    <>
      {submitting && (
        <Center position="absolute" top="50%" right="50%" zIndex="tooltip">
          <Spinner size="xl" color="brand.500" />
        </Center>
      )}

      {planSubscriptionDetailsState.value && (
        <PlanAlertWrapper
          discount={discount}
          onContactClick={contactUs}
          onUpgradeClick={openPlanSelector}
          plan={currentPlan}
          planSubscriptionDetails={planSubscriptionDetailsState.value}
          quantity={quantity}
          subscription={subscription}
          organization={organization}
          onStripeError={handleStripeError}
        />
      )}

      {planSubscriptionDetailsState.value && (
        <PlanDetails
          plan={currentPlan}
          organization={organization}
          planSubscriptionDetails={planSubscriptionDetailsState.value}
          onAction={handlePlanAction}
          newPlanSelected={!!billingMode}
        />
      )}

      {planMapState.value && planCostQuantity && (
        <PlanSelectorModal
          currentPlan={currentPlan}
          discount={discount}
          intervalSelectorVisible={!shouldShowOnlyYearlyBillingCycle}
          isOpen={plansDisclosure.isOpen}
          onClose={plansDisclosure.onClose}
          onIntervalChange={setSelectedInterval}
          onSelect={handlePlanSelect}
          organization={organization}
          planCostQuantity={planCostQuantity}
          planMap={planMapState.value}
          selectedInterval={selectedInterval}
        />
      )}

      {costMap && billingDetailsVisible && !!planSubscriptionDetailsState.value && (
        <PlanBillingDetails organization={organization} />
      )}

      {!!billingMode && !!planCostQuantity && (
        <PlanBillingSummary
          cardDefined={!!cardInfoState.value}
          costMap={costMap}
          currentPlan={currentPlan}
          mode={billingMode}
          onCountryChange={setCountry}
          onIntervalChange={setSelectedInterval}
          onSubmit={updatePlan}
          onZipChange={setZip}
          planCostQuantity={planCostQuantity}
          selectedInterval={selectedInterval}
          selectedPlanLevel={selectedPlanLevel}
          submitting={submitting}
        />
      )}
    </>
  );
};

const stripeKey = getEnv().STRIPE_PUB_KEY;
const stripePromise = loadStripe(stripeKey);

export const BillingTabStripeWrapper = (props: BillingTabProps) => (
  // @ts-expect-error library type
  <Elements stripe={stripePromise}>
    <BillingTab {...props} />
  </Elements>
);

const PlanBillingSummaryElement = (props: BillingTabProps) => {
  const {
    cardInfoState,
    costMap,
    currentPlan,
    setCountry,
    setSelectedInterval,
    updatePlan,
    setZip,
    planCostQuantity,
    selectedInterval,
    selectedPlanLevel,
    submitting,
  } = useBillingProps(props);
  return (
    <>
      {!!planCostQuantity && (
        <PlanBillingSummary
          cardDefined={!!cardInfoState.value}
          costMap={costMap}
          currentPlan={currentPlan}
          mode={'planUpgrade'}
          onCountryChange={setCountry}
          onIntervalChange={setSelectedInterval}
          onSubmit={updatePlan}
          onZipChange={setZip}
          planCostQuantity={planCostQuantity}
          selectedInterval={selectedInterval}
          selectedPlanLevel={selectedPlanLevel}
          submitting={submitting}
        />
      )}
    </>
  );
};

export const PlanBillingSummaryWrapper = (props: BillingTabProps) => {
  return (
    // @ts-expect-error library typing is wrong
    <Elements stripe={stripePromise}>
      <PlanBillingSummaryElement {...props} />
    </Elements>
  );
};
