import {
  ChecklistWidget,
  CONFIGURABLE_CONFIGS,
  CrossLinkWidget,
  DateFieldValue,
  DateFormFieldWidget,
  DefaultableFormFieldValue,
  DefaultableFormFieldWidget,
  EmailFormFieldWidget,
  EmailWidget,
  EmbedWidget,
  FieldType,
  FieldValueJson,
  FileFieldValue,
  FileFormFieldWidget,
  FileWidget,
  FormFieldConfig,
  FormFieldValue,
  FormFieldValueWithWidget,
  FormFieldWidget,
  HiddenFormFieldWidget,
  ImageWidget,
  MembersFieldValue,
  MultiChoiceFormFieldWidget,
  MultiChoiceItemValueStatus,
  MultiOptionFieldValue,
  MultiSelectFormFieldWidget,
  MultiSelectItemValueStatus,
  NumberFormFieldWidget,
  SelectFormFieldConfig,
  SelectFormFieldWidget,
  SendRichEmailFieldValue,
  SendRichEmailFormFieldWidget,
  SimpleFieldValue,
  SnippetFormFieldWidget,
  TextareaFormFieldWidget,
  TextFormFieldWidget,
  TextWidget,
  UrlFormFieldWidget,
  VideoWidget,
  Widget,
  WidgetType,
} from './widget-model';
import { GrouppedObjectMap } from '../redux';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import { TableFormFieldUtils } from '@process-street/subgrade/process/widget-utils/table-form-field-utils';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';

export const isSelectFormFieldConfig = (config: FormFieldConfig): config is SelectFormFieldConfig =>
  Array.isArray((config as SelectFormFieldConfig).items);

export function isMultiChoiceFormFieldWidget(widget: FormFieldWidget): widget is MultiChoiceFormFieldWidget {
  return widget.fieldType === FieldType.MultiChoice;
}

export function isMultiSelectFormFieldWidget(widget: FormFieldWidget): widget is MultiSelectFormFieldWidget {
  return widget.fieldType === FieldType.MultiSelect;
}

export const isCrossLinkWidget = (widget: Widget): widget is CrossLinkWidget =>
  widget.header.type === WidgetType.CrossLink;

export const isTextWidget = (widget: Widget): widget is TextWidget => widget.header.type === WidgetType.Text;

export const isVideoWidget = (widget: Widget): widget is VideoWidget => widget.header.type === WidgetType.Video;

export const isFileWidget = (widget: Widget): widget is FileWidget => widget.header.type === WidgetType.File;

export const isImageWidget = (widget: Widget): widget is ImageWidget => widget.header.type === WidgetType.Image;

export const isEmailWidget = (widget: Widget): widget is EmailWidget => widget.header.type === WidgetType.Email;

export const isEmbedWidget = (widget: Widget): widget is EmbedWidget => widget.header.type === WidgetType.Embed;

export const isFormFieldWidget = (widget: Widget): widget is FormFieldWidget =>
  widget.header.type === WidgetType.FormField;

export const isTextFormFieldWidget = (widget: Widget): widget is TextFormFieldWidget =>
  isFormFieldWidget(widget) && widget.fieldType === FieldType.Text;

export const isTextareaFormFieldWidget = (widget: Widget): widget is TextareaFormFieldWidget =>
  isFormFieldWidget(widget) && widget.fieldType === FieldType.Textarea;

export const isNumberFormFieldWidget = (widget: Widget): widget is NumberFormFieldWidget =>
  isFormFieldWidget(widget) && widget.fieldType === FieldType.Number;

export const isFileFormFieldWidget = (widget: Widget): widget is FileFormFieldWidget =>
  isFormFieldWidget(widget) && widget.fieldType === FieldType.File;

export const isDateFormFieldWidget = (widget: Widget): widget is DateFormFieldWidget =>
  isFormFieldWidget(widget) && widget.fieldType === FieldType.Date;

export const isSelectFormFieldWidget = (widget: Widget): widget is SelectFormFieldWidget =>
  isFormFieldWidget(widget) && widget.fieldType === FieldType.Select;

export const isHiddenFormFieldWidget = (widget: Widget): widget is HiddenFormFieldWidget =>
  isFormFieldWidget(widget) && widget.fieldType === FieldType.Hidden;

export const isUrlFormFieldWidget = (widget: Widget): widget is UrlFormFieldWidget =>
  isFormFieldWidget(widget) && widget.fieldType === FieldType.Url;

export const isEmailFormFieldWidget = (widget: Widget): widget is EmailFormFieldWidget =>
  isFormFieldWidget(widget) && widget.fieldType === FieldType.Email;

export const isSnippetFormFieldWidget = (widget: Widget): widget is SnippetFormFieldWidget =>
  isFormFieldWidget(widget) && widget.fieldType === FieldType.Snippet;

export const isSendRichEmailFormFieldWidget = (widget: Widget): widget is SendRichEmailFormFieldWidget =>
  isFormFieldWidget(widget) && widget.fieldType === FieldType.SendRichEmail;

export const isEmpty = (formFieldWidget: FormFieldWidget, formFieldValue?: FormFieldValue) => {
  if (!formFieldValue) {
    return true;
  }
  return isFormFieldValueEmpty(formFieldWidget, formFieldValue.fieldValue);
};

export const isFormFieldValueEmpty = (formFieldWidget: FormFieldWidget, fieldValue?: FieldValueJson): boolean => {
  if (!fieldValue) {
    return true;
  }

  switch (formFieldWidget.fieldType) {
    case FieldType.File:
      return Object.keys(fieldValue).length === 0;

    case FieldType.Members:
      return !isMemberFieldValue(fieldValue) || !fieldValue.organizationMembershipIds?.length;

    case FieldType.MultiChoice: {
      if (isMultiOptionFieldValue(fieldValue) && fieldValue.itemValues.length > 0) {
        const hasEveryFieldSelected = fieldValue.itemValues.every(
          item => item.status === MultiChoiceItemValueStatus.NotSelected,
        );
        return hasEveryFieldSelected;
      }
      return true;
    }

    case FieldType.MultiSelect: {
      if (
        isMultiSelectFormFieldWidget(formFieldWidget) &&
        isMultiOptionFieldValue(fieldValue) &&
        fieldValue.itemValues.length > 0
      ) {
        const allItemsCompleted = formFieldWidget.config.items.every(item => {
          const value = fieldValue.itemValues.find(val => val.id === item.id);
          return !!value && value.status === MultiSelectItemValueStatus.Completed;
        });

        return !allItemsCompleted;
      }
      return true;
    }

    case FieldType.SendRichEmail: {
      if (isSendRichEmailFieldValue(fieldValue)) {
        return !(fieldValue.lastSentByUserId && fieldValue.lastSentDate);
      }
      return true;
    }

    case FieldType.Table: {
      if (isTableFieldValue(fieldValue)) {
        return fieldValue.rows.every(TableFormFieldUtils.isRowEmpty);
      }
      return true;
    }

    case FieldType.Date:
    case FieldType.Email:
    case FieldType.Number:
    case FieldType.Select:
    case FieldType.Text:
    case FieldType.Textarea:
    case FieldType.Url:
      return !isSimpleFieldValue(fieldValue);

    default:
      return false;
  }
};

export const isFileFieldValue = (value?: FieldValueJson): value is FileFieldValue =>
  !!value && !!(value as FileFieldValue).url;

export const isSimpleFieldValue = (value?: FieldValueJson): value is SimpleFieldValue & { value: string } =>
  !!value && !!(value as SimpleFieldValue).value;

export const isDateFieldValue = (value?: FieldValueJson): value is DateFieldValue =>
  !!value && typeof (value as DateFieldValue).value === 'number';

export const isMultiOptionFieldValue = (value?: FieldValueJson): value is MultiOptionFieldValue =>
  Array.isArray((value as MultiOptionFieldValue)?.itemValues);

export const isMemberFieldValue = (value?: FieldValueJson): value is MembersFieldValue =>
  Array.isArray((value as MembersFieldValue).organizationMembershipIds);

export const isSendRichEmailFieldValue = (value?: FieldValueJson): value is SendRichEmailFieldValue =>
  !!value && 'lastSentByUserId' in value && 'lastSentDate' in value;

export const isTableFieldValue = (value?: FieldValueJson): value is TableFieldValue.FieldValue =>
  Array.isArray((value as TableFieldValue.FieldValue).rows);

const filterVisibleWidgets = <T extends Widget>(widgets: T[], checklistWidgets: ChecklistWidget[]): T[] =>
  widgets.filter(widget => {
    const checklistWidget = checklistWidgets.find(cw => cw.groupId === widget.header.group.id);
    return !checklistWidget || !checklistWidget.hidden;
  });

const filterVisibleWidgetsMap = <T extends Widget>(
  widgetsMap: GrouppedObjectMap<T>,
  checklistWidgets: ChecklistWidget[],
): GrouppedObjectMap<T> => {
  const result: GrouppedObjectMap<T> = {};

  Object.keys(widgetsMap).forEach(id => {
    const widgets = widgetsMap[id];
    const filteredWidgets = filterVisibleWidgets(widgets, checklistWidgets);
    result[id] = filteredWidgets;
  });

  return result;
};

/** Determine if any of the configs are configurable in the settings modal */
const hasConfigurableConfig = (widget: FormFieldWidget) => {
  return (
    widget.config &&
    new Set(Object.keys(widget.config).filter(config => CONFIGURABLE_CONFIGS.has(config as any))).size > 0
  );
};

function hasVariables(value = '') {
  const curlyBracesRegex = /\{\{.*?}}/g;
  const squareBracesRegex = /\[.*?]/g;
  const chevronsRegex = MergeTagStringReplacementUtils.CHEVRONS_REGEXP;

  return curlyBracesRegex.test(value) || squareBracesRegex.test(value) || chevronsRegex.test(value);
}

/** A form field value has the default value if it's fieldValue.hasDefaultValue is true
 * _or_ if there is no field value and the widget has a default value configured */
function hasValidDefaultValue(args: {
  formFieldValue?: DefaultableFormFieldValue;
  widget?: DefaultableFormFieldWidget;
  defaultValue?: string | undefined;
}): boolean {
  const defaultValueConfigured = Boolean(args.defaultValue || args.widget?.config.defaultValue);
  return defaultValueConfigured && args.formFieldValue?.fieldValue?.hasDefaultValue === true;
}

/**
 * A subset of having a default value, is having an invalid default value.
 * A form field value is invalid if there is a default value configured for the widget
 * and either the form field value object or its value field is empty or empty string.
 */
function hasInvalidDefaultValue(args: {
  formFieldValue?: DefaultableFormFieldValue;
  widget?: DefaultableFormFieldWidget;
  defaultValue?: string | undefined;
}): boolean {
  const defaultValueConfigured = Boolean(args.defaultValue || args.widget?.config.defaultValue);
  const fieldValue = args.formFieldValue?.fieldValue;
  return defaultValueConfigured && !fieldValue?.value && fieldValue?.hasDefaultValue !== false;
}

function hasDefaultValue(args: {
  formFieldValue?: DefaultableFormFieldValue;
  widget?: DefaultableFormFieldWidget;
  defaultValue?: string | undefined;
}): boolean {
  return hasValidDefaultValue(args) || hasInvalidDefaultValue(args);
}

function filterMembershipWidgetsOnly(formFieldValue: FormFieldValueWithWidget) {
  return formFieldValue.formFieldWidget.fieldType === FieldType.Members;
}

function filterEmailWidgetsOnly(formFieldValue: FormFieldValueWithWidget) {
  return formFieldValue.formFieldWidget.fieldType === FieldType.Email;
}

export const WidgetUtils = {
  filterEmailWidgetsOnly,
  filterMembershipWidgetsOnly,
  filterVisibleWidgets,
  filterVisibleWidgetsMap,
  hasConfigurableConfig,
  isEmpty,
  isFormFieldValueEmpty,
  isFormFieldWidget,
  hasVariables,
  hasDefaultValue,
  hasInvalidDefaultValue,
};
