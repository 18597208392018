import { EmailFormFieldWidget, FieldType, TEXT_MAX_LENGTH } from '@process-street/subgrade/process';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';
import { FormFieldValueSchemaTests } from '../common/validation/form-field-value-schema-tests';
import { FormFieldWidgetSettingsSchemas } from '../common/validation/form-field-widget-settings-schema';

type Restriction = EmailFormFieldWidget['constraints']['restriction'];
const DOMAIN_PATTERN = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;

export const emailSettingsSchema: ObjectSchema<WidgetSettings<FieldType.Email>> = yup
  .object({
    restriction: yup
      .string()
      .oneOf(['Allow', 'Block'] as Restriction[])
      .optional(),
    domains: yup
      .array()
      .of(
        yup
          .string()
          .matches(DOMAIN_PATTERN, domain => `Invalid domain: ${domain.value}`)
          .required(),
      )
      .optional(),
    config: yup
      .object<EmailFormFieldWidget['config']>({
        ...FormFieldWidgetSettingsSchemas.placeholder(60),
        defaultValue: yup
          .string()
          .min(0)
          .max(TEXT_MAX_LENGTH)
          .when('$hasVariables', {
            is: true,
            otherwise: (schema: yup.StringSchema) => {
              return schema
                .email('Default value must be a valid email')
                .when('$constraints', (constraints: EmailFormFieldWidget['constraints'], schema: yup.StringSchema) => {
                  return schema
                    .test(FormFieldValueSchemaTests.emailAllowDomains(constraints?.restriction, constraints?.domains))
                    .test(FormFieldValueSchemaTests.emailBlockDomains(constraints?.restriction, constraints?.domains));
                });
            },
          })

          .optional(),
      })
      .required(),
    constraints: yup
      .object<EmailFormFieldWidget['constraints']>({
        restriction: yup
          .string()
          .oneOf(['Allow', 'Block'] as Restriction[])
          .optional(),
        domains: yup
          .array()
          .of(
            yup
              .string()
              .matches(DOMAIN_PATTERN, domain => `Invalid domain: ${domain.value}`)
              .required(),
          )
          .optional(),
      })
      .required(),
  })
  .required();

type ValidationSchemaProps = {
  required: boolean;
  constraints: EmailFormFieldWidget['constraints'];
};

export const makeEmailValidationSchema = ({ required, constraints }: ValidationSchemaProps) => {
  const { restriction, domains } = constraints;

  return yup
    .string()
    .test(FormFieldValueSchemaTests.required(required))
    .test(FormFieldValueSchemaTests.email())
    .test(FormFieldValueSchemaTests.emailAllowDomains(restriction, domains))
    .test(FormFieldValueSchemaTests.emailBlockDomains(restriction, domains));
};
