import { Muid, Option, User } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { getAvatar } from 'components/common/Avatar';
import { Avatar, AvatarGroup, Button, HStack, Spinner } from 'components/design/next';
import { FolderMembershipActions } from 'components/folder/membership/store/folder-membership.actions';
import { useInjector } from 'components/injection-provider';
import { TemplatePermissionsIndicator } from 'components/permissions/template-permissions-indicator/component';
import { TemplateMembershipActions } from 'components/template/membership/store/template-membership.actions';
import { TemplatePermitsSelector } from 'components/template/membership/store/template-membership.selector';
import * as React from 'react';
import { connect } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { TemplateSelector } from 'reducers/template/template.selectors';
import { ReduxAppState, Status } from 'reducers/types';
import { UserSelector } from 'reducers/user/user.selectors';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { PaginatedMemberships } from '../../models';
import messageTemplate from 'directives/template-share/modal/template-share-modal.component.html';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { GroupActions } from 'reducers/group/group.actions';
import { useMount } from 'react-use';

interface StateToProps {
  currentUser: User;
  selectedOrganizationId: Option<Muid>;
  paginatedPermits: PaginatedMemberships;
  status: Option<Status>;
  template: Option<Template>;
}

interface DispatchFromProps {
  getFolderPermits: (folderId: Muid, organizationId: Muid) => void;
  getTemplatePermits: (templateId: Muid, limit: number, startingAfter?: Option<Muid>) => void;
  getAllOrgMembershipByOrgId: (organizationId: Muid) => void;
  getGroups: () => void;
}

export interface PureTemplateMemberListProps extends StateToProps, DispatchFromProps {}

const PAGE_SIZE = 10;
const RELOAD_SIZE = 7;

export const PureTemplateMemberList: React.FunctionComponent<React.PropsWithChildren<PureTemplateMemberListProps>> = ({
  getAllOrgMembershipByOrgId,
  currentUser,
  getFolderPermits,
  getGroups,
  getTemplatePermits,
  paginatedPermits,
  selectedOrganizationId,
  status,
  template,
}) => {
  React.useEffect(() => {
    if (!template?.id) return;

    if (selectedOrganizationId) {
      getFolderPermits(template.folder.id, selectedOrganizationId);
    }
    getTemplatePermits(template.id, PAGE_SIZE);
  }, [getFolderPermits, getTemplatePermits, selectedOrganizationId, template?.folder.id, template?.id]);

  React.useEffect(() => {
    if (!template?.id) return;
    if (
      paginatedPermits.hasMore &&
      paginatedPermits.loadedTemplatePermitsCount < RELOAD_SIZE &&
      (!status || !status.loading)
    ) {
      getTemplatePermits(template.id, PAGE_SIZE);
    }
  }, [getTemplatePermits, paginatedPermits.hasMore, paginatedPermits.loadedTemplatePermitsCount, status, template?.id]);

  // load memberships
  React.useEffect(() => {
    if (selectedOrganizationId) {
      getAllOrgMembershipByOrgId(selectedOrganizationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- org ID change
  }, [selectedOrganizationId]);

  // load groups
  useMount(() => {
    getGroups();
  });

  const isLoading = paginatedPermits.memberships.map(m => m.user).some(u => u === undefined);

  const { MessageBox } = useInjector('MessageBox');

  const { data: templateRevision } = useNewestTemplateRevisionQuery(
    { templateId: template?.id },
    { enabled: Boolean(template?.id) },
  );
  const showTemplateSharingModal = () => {
    MessageBox.custom({
      animation: false,
      templateUrl: messageTemplate,
      controller: 'TemplateShareModal',
      size: 'lg',
      options: { template, templateRevision, user: currentUser },
    });
  };

  if (!template) return null;

  return paginatedPermits && paginatedPermits.totalCount > 0 ? (
    isLoading ? (
      <Spinner />
    ) : (
      <>
        <HStack display={{ base: 'none', md: 'flex' }} my="4" color="gray.500">
          <TemplatePermissionsIndicator
            folderId={template.folder.id}
            templateId={template.id}
            organizationId={template.organization.id}
          />

          <AvatarGroup
            size="md"
            max={5}
            role="button"
            onClick={showTemplateSharingModal}
            _hover={{ cursor: 'pointer' }}
          >
            {paginatedPermits.memberships.map(member => {
              const avatar = getAvatar(member.user);

              return member && member.permit ? (
                <Avatar
                  key={member.permit.id}
                  showBorder={true}
                  name={avatar.title}
                  title={avatar.title}
                  src={avatar.url}
                  getInitials={() => avatar.initials}
                />
              ) : null;
            })}
            {/* Activate the +N feature with the difference of total count and displayed */}
            {Array.from({ length: paginatedPermits.totalCount - paginatedPermits.memberships.length }, (_, i) => (
              <Avatar key={i} />
            ))}
          </AvatarGroup>
        </HStack>

        <Button
          display={{ md: 'none' }}
          onClick={showTemplateSharingModal}
          w={8}
          h={8}
          p={0}
          minW={8}
          color="white"
          bgColor="gray.400"
          justifyContent="center"
          alignItems="center"
          borderRadius="full"
          fontWeight="normal"
        >
          {paginatedPermits.totalCount}
        </Button>
      </>
    )
  ) : null;
};

const mapStateToProps = (state: ReduxAppState, props: TemplateMemberListProps): StateToProps => {
  let paginatedMemberships: PaginatedMemberships;
  const template = TemplateSelector.getById(props.templateId)(state);

  if (template) {
    paginatedMemberships = TemplatePermitsSelector.templateAggregatedPermitsSelector(
      props.templateId,
      template.folder.id,
    )(state);
  } else {
    paginatedMemberships = {
      hasMore: false,
      loadedTemplatePermitsCount: 0,
      memberships: [],
      totalCount: 0,
    };
  }
  const currentUserId = SessionSelector.getCurrentUserId(state);
  const selectedOrganizationId = SessionSelector.getSelectedOrganizationId(state);
  const currentUser: User = UserSelector.getEntityMap(state)[currentUserId!];
  const status = template ? TemplatePermitsSelector.getStatusByTemplateId(props.templateId)(state) : undefined;

  return {
    currentUser,
    paginatedPermits: paginatedMemberships,
    selectedOrganizationId,
    status,
    template,
  };
};

interface TemplateMemberListProps {
  templateId: Muid;
}

export const TemplateMemberList: React.FC<React.PropsWithChildren<TemplateMemberListProps>> = (
  props: TemplateMemberListProps,
) => {
  const { OrganizationMembershipActions } = useInjector('OrganizationMembershipActions');

  const mapDispatchToProps = (
    dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>,
  ): DispatchFromProps => ({
    getFolderPermits: (templateId: Muid, organizationId: Muid) =>
      dispatch(FolderMembershipActions.getFolderPermits(templateId, organizationId)),
    getTemplatePermits: (templateId: Muid, limit: number) =>
      dispatch(TemplateMembershipActions.getTemplatePermits(templateId, limit)),
    getAllOrgMembershipByOrgId: (organizationId: Muid) =>
      dispatch(OrganizationMembershipActions.getAllOrgMembershipByOrgId(organizationId)),
    getGroups: () => dispatch(GroupActions.queryGroups()),
  });

  const InternalMemberList = React.useMemo(
    () =>
      connect<StateToProps, DispatchFromProps, TemplateMemberListProps, ReduxAppState>(
        mapStateToProps,
        mapDispatchToProps,
      )(PureTemplateMemberList),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore mapDispatchToProps
    [],
  );

  return <InternalMemberList {...props} />;
};
