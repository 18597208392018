import { Muid } from '@process-street/subgrade/core';
import axios, { CancelTokenSource } from 'axios';
import { StringService } from 'services/string-service';

import {
  Attachment,
  FormFieldValueUpdateResult,
  GetUploadUrlForAttachmentResponse,
  GetUploadUrlForFormFieldWidgetResponse,
  GetUploadUrlForTemplateWidgetResponse,
  Widget,
} from '@process-street/subgrade/process';
import { WidgetConstants } from '@process-street/subgrade/process/widget-constants';
import angular from 'angular';
import { adjustImageOrientation } from 'components/file-upload/utils/fileUpload';
import { FileUploadApi } from 'components/file-upload/services/file-upload.api';
import { FileUploadActions, FileUploadActionsSetStatsType } from 'components/file-upload/store/file-upload.actions';
import { FileUploadStats } from 'components/file-upload/store/file-upload.types';
import { Trace, trace } from 'components/trace';
import { WidgetActions } from 'components/widgets/store/widget.actions';
import ngRedux from 'ng-redux';
import { connectService } from 'reducers/util';
import {
  BlobWithName,
  File,
  RequestActions,
  SubmitData,
  UploadedItemType,
  UploadsMap,
  UploadWork,
  XhrMarker,
} from '../types';
import { FileUploadService } from './file-upload.service';
import { HttpStatus } from '@process-street/subgrade/util';

interface Actions {
  setStats: FileUploadActionsSetStatsType;
  syncWidget(widget: Widget): void;
}

export class FileUploadServiceImpl implements FileUploadService {
  public static $inject = ['$ngRedux', '$timeout', 'FileUploadActions', 'FileUploadApi', 'WidgetActions'];

  // TODO
  // @ts-expect-error -- TODO
  public actions: Actions;

  private readonly logger: Trace;
  private uploadsMap: UploadsMap = {};

  constructor(
    private readonly $ngRedux: ngRedux.INgRedux,
    private readonly $timeout: angular.ITimeoutService,
    private readonly fileUploadActions: FileUploadActions,
    private readonly fileUploadApi: FileUploadApi,
    private readonly widgetActions: WidgetActions,
  ) {
    this.$timeout = $timeout;
    this.logger = trace({ name: 'FileUploadService' });

    const mapDispatchToThis = {
      setStats: this.fileUploadActions.setStats,
      syncWidget: this.widgetActions.sync,
    };

    connectService('FileUploadService', this.$ngRedux, null, mapDispatchToThis)(this);
  }

  public getUploadMap(): UploadsMap {
    return this.uploadsMap;
  }

  public setUploadMap(uploadsMap: UploadsMap): void {
    this.uploadsMap = uploadsMap;
  }

  public isVideoFileType(fileName: string): boolean {
    const fileNameParts = fileName.split('.');
    const { length } = fileNameParts;
    const ext = length > 1 ? fileNameParts[length - 1].toLowerCase() : false;

    return WidgetConstants.VIDEO_MIME_TYPES.test(`.${ext}`);
  }

  public isValidFileSize(file: File): boolean {
    let valid = true;
    const fileTypeIsVideo = this.isVideoFileType(file.name);
    if (fileTypeIsVideo && file.size > WidgetConstants.VIDEO_MAX_FILE_SIZE) {
      valid = false;
    } else if (!fileTypeIsVideo && file.size > WidgetConstants.MAX_FILE_SIZE) {
      valid = false;
    }

    return valid;
  }

  public getFileSizeLimitMessage(file: File): string {
    let prettyFileSizeLimit;
    const videoFileType = this.isVideoFileType(file.name);
    if (videoFileType && file.size > WidgetConstants.VIDEO_MAX_FILE_SIZE) {
      prettyFileSizeLimit = StringService.getPrettySize(WidgetConstants.VIDEO_MAX_FILE_SIZE);
    } else if (!videoFileType && file.size > WidgetConstants.MAX_FILE_SIZE) {
      prettyFileSizeLimit = StringService.getPrettySize(WidgetConstants.MAX_FILE_SIZE);
    }

    let message = '';
    if (prettyFileSizeLimit) {
      message = `Sorry, the attachment must be smaller than ${prettyFileSizeLimit}.`;
    }

    return message;
  }

  public abortUpload(item: XhrMarker): void {
    const cancelRequest = this.getCancelRequestFn(item);
    if (cancelRequest) {
      this.$timeout(cancelRequest);

      const work = this.uploadsMap[item.id];
      if (work) {
        this.logger.info(`aborting upload of ${work.type} ${work.id}`);
        delete this.uploadsMap[item.id];
      }
    }
  }

  public abortTemplateUploads(): void {
    this.getTemplateUploads()
      .filter((work: UploadWork) => work.type === UploadedItemType.Widget)
      .forEach((work: UploadWork) => {
        this.abortUpload(work);
      });
  }

  public abortChecklistUploads(): void {
    this.getChecklistUploads().forEach((work: UploadWork) => {
      this.abortUpload(work);
    });
  }

  public submitUpload(data: SubmitData, itemToMark: XhrMarker, itemType: UploadedItemType): void {
    // Start the upload
    const xhr = data.submit();
    itemToMark._jqXHR = xhr;

    const work: UploadWork = {
      _jqXHR: xhr,
      id: itemToMark.id,
      type: itemType,
    };

    this.logger.info(`${itemType} submitted for upload, id: [${itemToMark.id}], url: ${data.url}`);
    this.uploadsMap[work.id] = work;
  }

  public hasAnyInProgress(): boolean {
    return Object.values(this.uploadsMap).length > 0;
  }

  public hasChecklistUploads(): boolean {
    return this.getChecklistUploads().length > 0;
  }

  public hasTemplateUploads(): boolean {
    return this.getTemplateUploads().length > 0;
  }

  public finishUpload(item: XhrMarker): void {
    delete item._jqXHR;

    const work = this.uploadsMap[item.id];
    if (work) {
      this.logger.info(`finishing upload of ${work.type} ${work.id}`);
      delete this.uploadsMap[item.id];
    }

    this.clearStats(item);
  }

  public setUploadStats(stats: FileUploadStats): void {
    this.actions.setStats(stats);
  }

  public finishUploadForAttachment(data: SubmitData): angular.IPromise<Attachment> {
    const { mimeType, key, originalName, taskId } = data.meta!;

    return this.fileUploadApi.finishUploadForAttachment(taskId!, key, originalName, mimeType);
  }

  public finishUploadForFormFieldWidget(data: SubmitData): angular.IPromise<FormFieldValueUpdateResult> {
    const { mimeType, key, originalName, checklistRevisionId, widgetId } = data.meta!;

    return this.fileUploadApi.finishUploadForFormFieldWidget(
      checklistRevisionId!,
      widgetId!,
      key,
      originalName,
      mimeType,
    );
  }

  public finishUploadForTemplateWidget(data: SubmitData): angular.IPromise<Widget> {
    const { mimeType, key, originalName, headerId } = data.meta!;

    return this.fileUploadApi.finishUploadForTemplateWidget(headerId!, key, originalName, mimeType).then(widget => {
      this.actions.syncWidget(widget);
      return widget;
    });
  }

  public submitFormFieldWidgetUpload(
    data: SubmitData,
    itemToMark: XhrMarker,
    checklistRevisionId: Muid,
    widgetId: Muid,
    fileName: string,
    mimeType: string,
  ): angular.IPromise<void> {
    this.setStatsAsUploading(itemToMark);

    return this.fileUploadApi
      .getUploadUrlForFormFieldWidget(checklistRevisionId, widgetId, fileName, mimeType)
      .then((response: GetUploadUrlForFormFieldWidgetResponse) => {
        data.url = response.url;
        data.type = 'PUT';
        data.meta = {
          checklistRevisionId,
          key: response.key,
          mimeType,
          originalName: fileName,
          widgetId,
        };

        this.submitUpload(data, itemToMark, UploadedItemType.FormFieldValue);
      });
  }

  public submitTemplateWidgetUpload(
    data: SubmitData,
    itemToMark: XhrMarker,
    headerId: Muid,
    fileName: string,
    mimeType: string,
  ): angular.IPromise<void> {
    this.setStatsAsUploading(itemToMark);

    return this.fileUploadApi
      .getUploadUrlForTemplateWidget(headerId, fileName, mimeType)
      .then((response: GetUploadUrlForTemplateWidgetResponse) => {
        data.url = response.url;
        data.type = 'PUT';
        data.meta = {
          headerId,
          key: response.key,
          mimeType,
          originalName: fileName,
        };

        this.submitUpload(data, itemToMark, UploadedItemType.Widget);
      });
  }

  public async submitAttachmentUpload(
    file: BlobWithName,
    itemToMark: XhrMarker,
    taskId: Muid,
    itemType: UploadedItemType,
    onProgress: (event: ProgressEvent, item: XhrMarker) => void,
  ): Promise<XhrMarker> {
    const { name: fileName, type: mimeType } = file;
    this.setStatsAsUploading(itemToMark);

    const response: GetUploadUrlForAttachmentResponse = await this.fileUploadApi.getUploadUrlForAttachment(
      taskId,
      fileName,
      mimeType,
    );

    const requestSource: CancelTokenSource = axios.CancelToken.source();
    const work: UploadWork = {
      // fake jqXHR until we can remove it (when other components don't use the jquery plugin)
      _jqXHR: {} as JqueryFileUploadConvenienceObject,
      id: itemToMark.id,
      request: requestSource,
      type: itemType,
    };

    this.addToUploadMap(work, itemType, response.url);

    const options = {
      cancelToken: requestSource.token,
      headers: {
        'Content-Disposition': `attachment; filename="${fileName}"`,
        'Content-Type': mimeType || 'application/octet-stream',
      },
      onUploadProgress: (data: ProgressEvent) => {
        onProgress(data, itemToMark);
      },
    };

    const adjustedFile = await adjustImageOrientation(file);
    await axios.put(response.url, adjustedFile, options);

    this.finishUpload(itemToMark);

    const updatedItem = await this.fileUploadApi.finishUploadForAttachment(taskId!, response.key, fileName, mimeType);

    // convert to unkown before to avoid having _jqXHR attribute.
    return updatedItem as unknown as XhrMarker;
  }

  public getRejectedFileErrorMessage(file: File) {
    let message;
    if (!this.isValidFileSize(file)) {
      const prettyMaxFileSize = StringService.getPrettySize(WidgetConstants.MAX_FILE_SIZE);
      message = `Sorry, the attachment must be smaller than ${prettyMaxFileSize}.`;
    } else {
      message = 'Sorry, we were unable to upload your attachment.';
    }
    return message;
  }

  /**
   * Response.message comes only for 'abort' case - when user is cancelling upload
   * In case of backend error - response comes with response.data.message
   * @param response
   */
  public getUploadErrorMessage(response: { status?: number; message?: string; data?: { message?: string } }) {
    if (response.status === HttpStatus.BAD_REQUEST && response.data && response.data.message) {
      return response.data.message;
    } else if (response.status === HttpStatus.CONFLICT) {
      return "We couldn't upload the attachment because the workflow has been updated.<br>Please refresh and try again.";
    } else if (response.message === 'abort') {
      // No message for this case
    } else {
      return "Sorry, we couldn't upload that file for some reason. Please try again later.";
    }
  }

  private getChecklistUploads(): UploadWork[] {
    return Object.values(this.uploadsMap).filter((work: UploadWork) =>
      [UploadedItemType.FormFieldValue, UploadedItemType.Attachment].includes(work.type),
    );
  }

  private getTemplateUploads(): UploadWork[] {
    return Object.values(this.uploadsMap).filter((work: UploadWork) => work.type === UploadedItemType.Widget);
  }

  private addToUploadMap(work: UploadWork, itemType: UploadedItemType, url: string) {
    this.uploadsMap[work.id] = work;
    this.logger.info(`${itemType} submitted for upload, id: [${work.id}], url: ${url}`);
  }

  /**
   * gets abort/cancel request function as a void fn.
   */
  private getCancelRequestFn(item: XhrMarker) {
    if (item._jqXHR?.abort) {
      const request = item._jqXHR;
      return () => {
        request.abort();
      };
    }

    if (this.uploadsMap[item.id] && this.uploadsMap[item.id].request) {
      const { request } = this.uploadsMap[item.id];
      return () => {
        !!request && request.cancel(RequestActions.Abort);
      };
    }
  }

  private setStatsAsUploading(item: XhrMarker) {
    const stats = {
      id: item.id,
      progress: 0,
      uploading: true,
    };
    this.setUploadStats(stats);
  }

  private clearStats(item: XhrMarker) {
    const stats = {
      id: item.id,
      progress: 0,
      uploading: false,
    };
    this.setUploadStats(stats);
  }
}
