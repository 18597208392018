import * as React from 'react';
import { Input, InputGroup, InputLeftElement, Icon, InputGroupProps } from 'components/design/next';

export type DataSetQuickFilterProps = {
  onChange: (value: string) => void;
  inputGroupProps?: Partial<InputGroupProps>;
};

export const DataSetQuickFilter = ({ onChange, inputGroupProps }: DataSetQuickFilterProps) => {
  return (
    <InputGroup w="auto" {...inputGroupProps}>
      <InputLeftElement>
        <Icon variant="far" size="3.5" color="gray.500" icon="magnifying-glass" />
      </InputLeftElement>
      <Input placeholder="Search" bgColor="white" onChange={e => onChange(e.target.value)} maxW={70} />
    </InputGroup>
  );
};
