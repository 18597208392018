import { Muid } from '@process-street/subgrade/core';
import { axiosService } from 'services/axios-service';

export namespace DeleteWidgetMutation {
  export type Params = { headerId: Muid };

  export const mutationFn = async ({ headerId }: Params): Promise<void> => {
    return axiosService
      .getAxios()
      .delete(`/1/widgets/${headerId}`)
      .then(res => res.data);
  };
}
