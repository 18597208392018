import { CoverIcon } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type AddEmojiCoverIconMutationParams = {
  templateId: Template['id'];
  emoji: string;
};

export type AddEmojiCoverIconMutationResponse = CoverIcon;

export const AddEmojiCoverIconMutation = {
  key: ['cover-icon', 'emoji'],
  mutationFn: (params: AddEmojiCoverIconMutationParams) =>
    axiosService
      .getAxios()
      .post<AddEmojiCoverIconMutationResponse>(`/1/templates/${params.templateId}/cover-icon/emoji`, params)
      .then(res => res.data),
};

export const useAddEmojiCoverIconMutation = (
  options: UseMutationOptions<AddEmojiCoverIconMutationResponse, AxiosError, AddEmojiCoverIconMutationParams> = {},
) => {
  return useMutation(AddEmojiCoverIconMutation.mutationFn, { mutationKey: AddEmojiCoverIconMutation.key, ...options });
};
