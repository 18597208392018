import React from 'react';

import { useToast } from 'components/design/next';

import { Checklist, Comment, Task, Template } from '@process-street/subgrade/process';
import { isNotIdRef } from '@process-street/subgrade/core';

import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { NewCommentBox } from './new-comment-box';
import { GetCommentsByChecklistRevisionIdQuery, useUpdateCommentMutation } from 'features/comments/query-builder';
import { useQueryClient } from 'react-query';
import { DefaultErrorMessages } from 'components/utils/error-messages';

type EditCommentProps = {
  comment: Comment;
  checklistId: Checklist['id'];
  templateId: Template['id'];
  setIsEditing: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
};

export const EditComment: React.FC<React.PropsWithChildren<EditCommentProps>> = ({
  comment,
  checklistId,
  templateId,
  setIsEditing,
}) => {
  const queryClient = useQueryClient();
  const { audit, content, id } = comment;
  const toast = useToast();
  const task = isNotIdRef(comment.task) ? comment.task : ({} as Task);

  const { data: currentUser } = useGetCurrentUserInfoQuery({
    select: data => data.user,
  });

  const updateCommentMutation = useUpdateCommentMutation({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Comment updated',
      });

      setIsEditing.off();

      queryClient.invalidateQueries(
        GetCommentsByChecklistRevisionIdQuery.getKey({ checklistRevisionId: task.checklistRevision.id, templateId }),
      );
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems deleting the comment",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });
  const handleEditComment = (content: Comment['content']) => {
    updateCommentMutation.mutate({
      commentId: id,
      audit: { ...audit, updatedDate: new Date().getTime() },
      content,
    });
    setIsEditing.off();
  };

  return (
    <NewCommentBox
      {...{ onSendComment: handleEditComment, checklistId, content, currentUser, onCancel: setIsEditing.off }}
    />
  );
};
