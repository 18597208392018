import { Muid, OrganizationMembershipStatus, UserType } from '@process-street/subgrade/core';
import { Checkbox, HStack, Text, Tooltip, VStack } from 'components/design/next';
import { MemberItem } from 'components/common/MemberOption';
import deepEqual from 'deep-equal';
import { useUserDeactivationHelpers } from 'features/user/use-user-deactivation-helpers';
import React, { useEffect, useState } from 'react';
import { components, GroupBase, SelectComponentsConfig } from 'react-select';
import { BlvdSelect, BlvdSelectProps } from '../BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect';
import { BlvdMembersMultiselectProvider, useBlvdMembersMultiselectContext } from './blvd-members-multiselect-context';
import { ThemeName, useThemeName } from 'components/design/next/theme-name-provider';
import { ChakraAvatar } from 'components/design/next/chakra-avatar';

export interface BlvdMembersMultiselectProps {
  id?: string;
  items: MemberItem[];
  selectedOmIds: Muid[];
  onMenuClose: (selectedItems: MemberItem[]) => void;
  onChange?: (selectedItems: MemberItem[]) => void;
  blvdSelectProps?: BlvdSelectProps<MemberItem, true>;
}

type Option = MemberItem;

const getOptions = (items: MemberItem[], selectedOmIds: string[]) =>
  items.filter(item => selectedOmIds.includes(item.organizationMembershipId));

export const BlvdMembersMultiselect: React.FunctionComponent<React.PropsWithChildren<BlvdMembersMultiselectProps>> = ({
  id,
  items: options,
  selectedOmIds,
  onMenuClose,
  blvdSelectProps,
  onChange,
}) => {
  const [value, setValue] = useState<Option[]>(getOptions(options, selectedOmIds));
  const changeValue = (value: Option[]) => {
    setValue(value);
    onChange?.(value);
  };
  const deactivationHelpers = useUserDeactivationHelpers();

  const getOrganizationMembershipStatus = React.useCallback(
    (memberId: Muid) => {
      if (!deactivationHelpers.isFeatureEnabled) return undefined;

      return deactivationHelpers.getUserOrganizationMembership(memberId)?.status;
    },
    [deactivationHelpers],
  );

  useEffect(() => {
    const newOptions = getOptions(options, selectedOmIds);
    if (!deepEqual(newOptions, value)) {
      setValue(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only act on options and not value change
  }, [options, selectedOmIds]);

  const filterOption: BlvdSelectProps<Option>['filterOption'] = (option, pattern) => {
    const { name, email } = option.data as Option;
    return name.toLowerCase().includes(pattern.toLowerCase()) || email.toLowerCase().includes(pattern.toLowerCase());
  };

  return (
    <BlvdMembersMultiselectProvider getOrganizationMembershipStatus={getOrganizationMembershipStatus}>
      <BlvdSelect
        isMulti
        isSearchable
        menuControls
        fixedSize
        id={id}
        options={options}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        filterOption={filterOption}
        value={value}
        onMenuClose={() => onMenuClose(value)}
        components={BlvdMembersMultiselectComponents}
        onChange={newOptions => {
          if (BlvdSelectHelpers.isOptionsType<Option>(newOptions)) {
            changeValue(newOptions as Option[]);
          } else {
            changeValue([]);
          }
        }}
        {...blvdSelectProps}
      />
    </BlvdMembersMultiselectProvider>
  );
};

export const BlvdMembersMultiselectComponents: SelectComponentsConfig<Option, true, GroupBase<Option>> = {
  Option: ({ children: _, ...props }) => {
    const { name, email, avatarUrl, organizationMembershipId, userType, id } = props.data;

    const isEmailVisible = userType === UserType.Standard;
    const { getOrganizationMembershipStatus } = useBlvdMembersMultiselectContext();
    const membershipStatus = getOrganizationMembershipStatus?.(organizationMembershipId);
    const isActive = membershipStatus ? membershipStatus === OrganizationMembershipStatus.Active : true;

    const is2024Theme = useThemeName() === ThemeName.TwentyFour;

    const ui = (
      <components.Option {...props}>
        <HStack spacing="2" opacity={isActive ? 1 : 0.6} w="100%" overflow="hidden">
          {/* Disable default pointer events to prevent menu closing  */}
          <Checkbox size={is2024Theme ? 'md' : 'lg'} isChecked={props.isSelected} pointerEvents="none" />
          <ChakraAvatar size="sm" url={avatarUrl} name={name} userId={id} />
          <VStack alignItems="flex-start" spacing="0" w="100%" overflow="hidden">
            <Text color="gray.600" whiteSpace="nowrap" textOverflow="ellipsis">
              {name}
            </Text>
            {isEmailVisible ? (
              <Text fontSize="12px" color="gray.500" whiteSpace="nowrap" textOverflow="ellipsis">
                {email}
              </Text>
            ) : null}
          </VStack>
        </HStack>
      </components.Option>
    );
    return isActive ? (
      ui
    ) : (
      <Tooltip label="User deactivated" placement="top" hasArrow>
        {ui}
      </Tooltip>
    );
  },
  MultiValue: ({ children: _, ...props }) => {
    const deactivationHelpers = useUserDeactivationHelpers();
    const { name, avatarUrl, organizationMembershipId, id } = props.data as Option;
    const isActive = deactivationHelpers.isActive(organizationMembershipId);
    return (
      <HStack {...props.innerProps} _notFirst={{ ml: 1 }} flexShrink={0} maxW="none" opacity={isActive ? 1 : 0.6}>
        <ChakraAvatar size="xs" url={avatarUrl} name={name} userId={id} />
        <Text color="gray.600">{name}</Text>
      </HStack>
    );
  },
};
