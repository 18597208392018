import * as React from 'react';
import { useReorderAutoScroll } from '../hooks/use-reorder-auto-scroll';

type Context = {
  widgetAutoScroll: ReturnType<typeof useReorderAutoScroll>;
  /** Not applicable for Forms */
  taskAutoScroll?: ReturnType<typeof useReorderAutoScroll>;
};

/** Declared as optional context - may not exist in e.g. View mode */
export const ReorderAutoScrollContext = React.createContext<Context | null>(null);
