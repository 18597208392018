import * as React from 'react';
import { Label } from '@process-street/subgrade/process';
import { Link, Text, Tag, TagCloseButton, LayoutProps, Tooltip } from 'components/design/next';

export type LabelItemProps = {
  label: Label;
  onClick?: (label: Label) => void;
  onDelete?: (label: Label) => void;
  removeProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  maxW?: LayoutProps['maxW'];
};

export const LabelItem: React.FC<React.PropsWithChildren<LabelItemProps>> = ({
  label,
  onClick,
  onDelete,
  removeProps,
  maxW,
}) => (
  <Link variant="unstyled" onClick={onClick ? () => onClick(label) : undefined} display="contents">
    <Tag backgroundColor={'#' + label.color} variant="solid" size="sm" borderRadius="md" px={2} maxW={maxW}>
      <Tooltip label={label.name}>
        <Text
          fontSize="xs"
          fontWeight="medium"
          color="gray.600"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          maxW="calc(100%)"
        >
          {label.name}
        </Text>
      </Tooltip>
      {/* @ts-expect-error -- conflict between react-select expecting a div element and the chakra component being a button */}
      {onDelete && <TagCloseButton {...removeProps} color="black" onClick={() => onDelete(label)} />}
    </Tag>
  </Link>
);
