import { Muid } from '@process-street/subgrade/core';
import create from 'zustand';

interface ColumnHeaderState {
  hoveredColumnId: Muid | null;
  editingColumnId: Muid | null;
  newColumnId: Muid | null;
}

interface ColumnHeaderEffects {
  setHoveredColumnId: (rowId: Muid | null) => void;
  setEditingColumnId: (rowId: Muid | null) => void;
  setNewColumnId: (rowId: Muid | null) => void;
}

type ColumnHeaderStore = ColumnHeaderState & ColumnHeaderEffects;

export const useColumnHeaderStore = create<ColumnHeaderStore>(set => ({
  editingColumnId: null,
  hoveredColumnId: null,
  newColumnId: null,
  setHoveredColumnId: (columnId: Muid | null) => set({ hoveredColumnId: columnId }),
  setEditingColumnId: (editingColumnId: Muid | null) => set({ editingColumnId }),
  setNewColumnId: (newColumnId: Muid | null) => set({ newColumnId }),
}));

export function getColumnHeaderPropsById(store: ColumnHeaderStore, columnId: Muid) {
  return {
    isNew: store.newColumnId === columnId,
    isEditing: store.editingColumnId === columnId,
    isHovered: store.hoveredColumnId === columnId,
    setIsEditing: (is: boolean) => store.setEditingColumnId(is ? columnId : null),
    setIsNew: (is: boolean) => store.setNewColumnId(is ? columnId : null),
    setIsHovered: (is: boolean) => store.setHoveredColumnId(is ? columnId : null),
  };
}
