import { PlanLevel, PlanTrack } from '@process-street/subgrade/billing';
import { useRoleNames } from 'hooks/use-role-names';
import React from 'react';
import { useOnPlanTrack } from 'services/use-plan-track';
import './PlanTopPanel.scss';

interface PlanTopPanelProps {
  level: PlanLevel;
  selected: boolean;
  cost: string;
}

const CustomLabels: { [level in PlanLevel]?: string } = {
  [PlanLevel.Professional]: 'Pro',
};
const getLevelLabel = (level: PlanLevel): string => {
  return CustomLabels[level] ?? level.toString();
};

const getClass = (level: PlanLevel) => `plan-top-panel plan-top-panel__level-${level.toLowerCase()}`;

const Cost: React.FC<React.PropsWithChildren<{ level: PlanLevel; cost: string }>> = ({ cost, level }) => {
  if (level === PlanLevel.Enterprise) {
    return <div className="cost cost-custom">Custom</div>;
  } else if (level === PlanLevel.Free) {
    return (
      <div className="cost">
        <span className="integer-part">$0</span>
      </div>
    );
  } else {
    const [costUnit, costSubUnit] = cost.split('.');

    return (
      <div className="cost">
        <span className="integer-part">${costUnit}</span>
        <span className="decimal-part">.{costSubUnit}</span>
      </div>
    );
  }
};

export const PlanTopPanel: React.FunctionComponent<React.PropsWithChildren<PlanTopPanelProps>> = props => {
  const onFreemium = useOnPlanTrack(PlanTrack.Freemium);
  const roleNames = useRoleNames();

  return (
    <div className={getClass(props.level)}>
      <div className="plan-top-panel__level">{getLevelLabel(props.level)}</div>

      <Cost level={props.level} cost={props.cost} />

      <div className="per">
        Per {onFreemium ? `${roleNames.FullMember.single}` : 'User'}
        <br />
        Per Month
      </div>
    </div>
  );
};
