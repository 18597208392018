import { FormFieldValue, FormFieldWidget } from '@process-street/subgrade/process';
import { FormResponseMachineReceptionist } from 'pages/responses/_id/form-response-machine-receptionist';
import { RulesEngineMachineActorRef } from './rules-engine-machine';

export function sendRulesActorFormFieldValueUpdate<Widget extends FormFieldWidget, Value extends FormFieldValue>({
  formFieldValue,
  formFieldWidget,
}: {
  formFieldWidget: Widget;
  formFieldValue: Value;
}) {
  const actor = FormResponseMachineReceptionist.get<RulesEngineMachineActorRef>('rules-engine-actor');
  actor?.send({ type: 'FORM_FIELD_VALUE_UPDATE', formFieldValue, formFieldWidget });
}
