import { Option, User } from '@process-street/subgrade/core';
import { BaseUserSelector } from '@process-street/subgrade/redux/selector/user.selectors';
import { ReduxAppState } from 'reducers/types';

const getAll = (state: ReduxAppState): User[] => Object.values(UserSelector.getEntityMap(state));

const getByPrimaryEmail =
  (email: string) =>
  (state: ReduxAppState): Option<User> =>
    UserSelector.getAll(state).find(user => user.email.toLowerCase() === email.toLowerCase());

export const UserSelector = {
  getAll,
  getById: BaseUserSelector.getById,
  getByPrimaryEmail,
  getEntityMap: BaseUserSelector.getEntityMap,
};
