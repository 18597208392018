import angular from 'angular';
import { uuid } from 'services/uuid';
import Muid from 'node-muid';
import { createSelector } from 'reselect';
import { groupsSelector } from 'reducers/group/group.selectors';
import { FieldType, WidgetType } from '@process-street/subgrade/process';
import { GroupType } from '@process-street/subgrade/core';
import { FormFieldDefaultLabel, NON_REVERTIBLE_FIELD_TYPES } from './form-field-service-constants';
import { FeatureFlagSelector } from './features/feature-flags/store/feature-flags.selectors';
import { GroupActions } from 'reducers/group/group.actions';

export class FormFieldService {
  constructor($ngRedux, util, WidgetService) {
    'ngInject';

    this.util = util;
    this.WidgetService = WidgetService;

    this.generateArrayOfFieldTypesWithUpdateDelay();

    this.store = $ngRedux;

    const mapStateToThis = createSelector(
      [groupsSelector, FeatureFlagSelector.getFeatureFlags],
      (groups, featureFlags) => ({ groups, ffs: featureFlags }),
    );

    const mapDispatchToScope = {
      queryGroups: GroupActions.queryGroups,
    };

    this.unsubscribe = this.store.connect(mapStateToThis, mapDispatchToScope)(this);

    this.queryGroups();
  }

  /**
   * Create a email form field widget.
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @returns {widget}
   */
  createEmailField(headerId, taskTemplate, orderTree, key) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.Email,
      key,
      label: FormFieldDefaultLabel.Email,
      config: {},
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  /**
   * Create a URL form field widget.
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @returns {widget}
   */
  createUrlField(headerId, taskTemplate, orderTree, key) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.Url,
      key,
      label: FormFieldDefaultLabel.Url,
      config: {},
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  /**
   * Create a text form field widget.
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @returns {widget}
   */
  createTextField(headerId, taskTemplate, orderTree, key) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.Text,
      key,
      label: null,
      config: {},
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  /**
   * Create a number form field widget.
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @returns {widget}
   */
  createNumberField(headerId, taskTemplate, orderTree, key) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.Number,
      key,
      label: null,
      config: {},
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  /**
   * Create a textarea form field widget.
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @returns {widget}
   */
  createTextareaField(headerId, taskTemplate, orderTree, key) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.Textarea,
      key,
      label: null,
      config: {},
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  /**
   * Create a file form field widget.
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @returns {widget}
   */
  createFileField(headerId, taskTemplate, orderTree, key) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.File,
      key,
      label: null,
      config: {},
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  /**
   * Create a date form field widget.
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @returns {widget}
   */
  createDateField(headerId, taskTemplate, orderTree, key) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.Date,
      key,
      label: FormFieldDefaultLabel.Date,
      config: {},
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  /**
   * Create a hidden form field widget.
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @returns {widget}
   */
  createHiddenField(headerId, taskTemplate, orderTree, key) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.Hidden,
      key,
      label: null,
      config: {},
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  /**
   * Create a table form field widget.
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @returns {widget}
   */
  createTableField(headerId, taskTemplate, orderTree, key) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.Table,
      key,
      label: FormFieldDefaultLabel.Table,
      config: {
        columnDefs: [
          { id: Muid.fromUuid(uuid()), name: 'First Name', columnType: 'Text' },
          { id: Muid.fromUuid(uuid()), name: 'Last Name', columnType: 'Text' },
        ],
      },
      required: false,
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  /**
   * Create a select form field widget.
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @param items
   * @returns {widget}
   */
  createSelectField(headerId, taskTemplate, orderTree, key, items) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.Select,
      key,
      label: null,
      config: {
        items,
      },
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  /**
   * Create SendRichEmail form field widget.
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @returns {widget}
   */
  createSendRichEmailField(headerId, taskTemplate, orderTree, key) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.SendRichEmail,
      key,
      label: null,
      config: {
        editor: 'RichEditor',
        emailFormat: 'RichTextOrHtml',
        to: [],
        cc: [],
        bcc: [],
        subject: '',
        richEditorBody: '<p></p>',
        rawHTMLBody: '',
        editAllowed: true,
      },
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  createMembersField(headerId, taskTemplate, orderTree, key) {
    const allMembersGroup =
      angular.isObject(this.groups) &&
      Object.values(this.groups).find(({ groupType }) => groupType === GroupType.AllMembers);

    if (!allMembersGroup) {
      throw new Error('could not find AllMembers group');
    }

    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.Members,
      key,
      label: FormFieldDefaultLabel.Members,
      config: {
        groupId: allMembersGroup.id,
      },
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  /**
   * Create a multi-choice form field widget.
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @param items
   * @returns {widget}
   */
  createMultiChoiceField(headerId, taskTemplate, orderTree, key, items) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.MultiChoice,
      key,
      label: null,
      config: {
        items,
      },
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  /**
   * Create a multi-select form field widget (sub-checklist).
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @param items
   * @returns {widget}
   */
  createMultiSelectField(headerId, taskTemplate, orderTree, key, items) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.MultiSelect,
      key,
      label: null,
      config: {
        items,
      },
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  /**
   * Create a snippet form field widget.
   *
   * @param headerId
   * @param taskTemplate
   * @param orderTree
   * @param key
   * @returns {widget}
   */
  createSnippetField(headerId, taskTemplate, orderTree, key) {
    const widget = {
      id: Muid.fromUuid(uuid()),
      header: {
        id: headerId,
        taskTemplate,
        type: WidgetType.FormField,
        orderTree,
      },
      fieldType: FieldType.Snippet,
      key,
      label: null,
      config: {},
    };

    return this.WidgetService.create(widget, {
      taskTemplate,
    });
  }

  generateKeyFromLabel(label) {
    return (
      label &&
      label
        .trim()
        .replace(/\s+/g, '_') // Replace all whitespace with underscores, otherwise, who cares?
        .replace(/[{]{2,}/g, '{')
        .replace(/[}]{2,}/g, '}')
    ); // replace double brackets
  }

  /**
   * Returns list of mapped values from a field from widgets.
   *
   * @param field name of widget's field, use a constant e.g. KEY_FIELD for 'key'
   * @param widgetsMap list of widgets
   * @param excludeWidget a widget to be excluded from the resulting list
   * @returns {Array} values of the mapped field
   */
  toFieldValuesList(field, widgetsMap, excludeWidget) {
    const fieldValues = [];

    angular.forEach(widgetsMap, widgets => {
      widgets.forEach(widget => {
        if (widget[field] && (!excludeWidget || widget.id !== excludeWidget.id)) {
          fieldValues.push(widget[field]);
        }
      });
    });

    return fieldValues;
  }

  static FIELD_KEY = 'key';

  toKeys(widgetsMap, excludeWidget) {
    return this.toFieldValuesList(FormFieldService.FIELD_KEY, widgetsMap, excludeWidget);
  }

  static FIELD_LABEL = 'label';

  toLabels(widgetsMap, excludeWidget) {
    return this.toFieldValuesList(FormFieldService.FIELD_LABEL, widgetsMap, excludeWidget);
  }

  generateUniqueKey(keys, key) {
    const ordinals = [];

    // Find all keys that have the given prefix
    keys.forEach(k => {
      if (k === key) {
        ordinals.push(1);
      } else if (k.startsWith(`${key}_`)) {
        // If a key has this key as a prefix, determine its ordinal and add it a list
        const ordinal = +k.substring(key.length + 1);
        if (!isNaN(ordinal)) {
          ordinals.push(ordinal);
        }
      }
    });

    // Sort numbers in descending order
    const sortedOrdinals = ordinals.sort((a, b) => b - a);

    // This will get one more than the greatest ordinal
    return ordinals.length ? `${key}_${sortedOrdinals[0] + 1}` : key;
  }

  static MAX_LABEL_LENGTH = 255;

  generateLabelForCopy(labels, originalLabel) {
    return this.util.generateCopyName(
      ' (Copy #n#)' /*postfixTpl*/,
      labels,
      originalLabel,
      FormFieldService.MAX_LABEL_LENGTH,
    );
  }

  static MAX_KEY_LENGTH = 255;

  generateKeyForCopy(keys, originalKey) {
    return this.util.generateCopyName('_(Copy_#n#)' /*postfixTpl*/, keys, originalKey, FormFieldService.MAX_KEY_LENGTH);
  }

  isRevertible(widget) {
    return (
      widget.header.type === WidgetType.FormField &&
      widget.fieldType &&
      !NON_REVERTIBLE_FIELD_TYPES.has(widget.fieldType)
    );
  }

  generateArrayOfFieldTypesWithUpdateDelay() {
    this.FIELD_TYPES_WITH_UPDATE_DELAY = [
      FieldType.Text,
      FieldType.Textarea,
      FieldType.Email,
      FieldType.Url,
      FieldType.MultiSelect,
      FieldType.MultiChoice,
      FieldType.Members,
    ];
  }

  getUpdateDelay(widget) {
    return this.FIELD_TYPES_WITH_UPDATE_DELAY.includes(widget.fieldType) ? 500 : 0;
  }
}

angular.module('frontStreetApp.services').service('FormFieldService', FormFieldService);
