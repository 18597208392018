import { ExecutionStatus } from '@process-street/subgrade/automation';
import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetAllExecutionStatusesByChecklistIdQueryParams = {
  checklistId?: Muid;
};

export type GetAllExecutionStatusesByChecklistIdQueryResponse = ExecutionStatus[];

export const GetAllExecutionStatusesByChecklistIdQuery = {
  key: ['checklists', 'automations', 'executions'],
  getKey: (params: GetAllExecutionStatusesByChecklistIdQueryParams): QueryKey => [
    ...GetAllExecutionStatusesByChecklistIdQuery.key,
    params,
  ],
  queryFn: ({ checklistId }: GetAllExecutionStatusesByChecklistIdQueryParams) =>
    axiosService
      .getAxios()
      .get<GetAllExecutionStatusesByChecklistIdQueryResponse>(`/1/checklists/${checklistId}/automations/executions`)
      .then(res => res.data),
};

export const useGetAllExecutionStatusesByChecklistIdQuery = <
  Select = GetAllExecutionStatusesByChecklistIdQueryResponse,
>(
  params: GetAllExecutionStatusesByChecklistIdQueryParams,
  options: UseQueryOptions<GetAllExecutionStatusesByChecklistIdQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetAllExecutionStatusesByChecklistIdQuery.getKey(params),
    () => GetAllExecutionStatusesByChecklistIdQuery.queryFn(params),
    {
      ...options,
      enabled: Boolean(params.checklistId) && options.enabled !== false,
    },
  );
};
