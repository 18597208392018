export const HANDLE_PROPS = {
  'data-is-drag-handle': true,
};

const isTargetDragHandle = (target: HTMLElement) => {
  const isDragHandle =
    target.dataset.isDragHandle || (target.closest('[data-is-drag-handle]') as HTMLElement)?.dataset.isDragHandle;
  return isDragHandle === 'true';
};

export const ReorderUtils = {
  isTargetDragHandle,
  HANDLE_PROPS,
};
