import {} from '@process-street/subgrade/core';
import { FinishUploadForCreateOneOffTaskRequest } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace FinishUploadAttachmentMutation {
  export type Params = FinishUploadForCreateOneOffTaskRequest;

  export type Response = {};

  export const key = ['one-off-task', 'finish-upload'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/one-off-task/attachments/finish-upload`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
