import { DateFieldValue } from '../process';
import { dayjs as moment } from '@process-street/subgrade/util';
import { DateFormat, DateUtils } from './date-utils';
import { DateContext, DateContextUtils } from '@process-street/subgrade/core/date-context';

const formatDateFieldValueToShortMonth = (dateFieldValue: DateFieldValue, dateContext: DateContext) => {
  const timestamp = dateFieldValue.value as number;
  const organizationTimeZone = DateContextUtils.getOrganizationTimeZone(dateContext);
  return dateFieldValue.timeHidden
    ? moment.tz(timestamp, organizationTimeZone).format(DateFormat.DateShortMonthWithComma)
    : moment.tz(timestamp, dateContext.userTimeZone).format(DateFormat.DateTimeShortMonth);
};

const formatDateFieldValueToLongMonth = (dateFieldValue: DateFieldValue, dateContext: DateContext) => {
  const timestamp = dateFieldValue.value as number;
  const organizationTimeZone = DateContextUtils.getOrganizationTimeZone(dateContext);
  return dateFieldValue.timeHidden
    ? moment.tz(timestamp, organizationTimeZone).format(DateFormat.DateLongMonth)
    : moment.tz(timestamp, dateContext.userTimeZone).format(DateFormat.DateTimeFullMonth);
};

const formatDateFieldValueToLongWeekdayShortMonth = (dateFieldValue: DateFieldValue, dateContext: DateContext) => {
  const timestamp = dateFieldValue.value as number;
  const organizationTimeZone = DateContextUtils.getOrganizationTimeZone(dateContext);
  return dateFieldValue.timeHidden
    ? DateUtils.formatDateTime(timestamp, DateFormat.DateLongWeekdayShortMonth, organizationTimeZone)
    : DateUtils.formatDateTime(timestamp, DateFormat.DateTimeLongWeekdayShortMonthWithZone, dateContext.userTimeZone);
};

const formatDateFieldValueToShortMonthNoSeconds = (dateFieldValue: DateFieldValue, dateContext: DateContext) => {
  const timestamp = dateFieldValue.value as number;
  const organizationTimeZone = DateContextUtils.getOrganizationTimeZone(dateContext);
  return dateFieldValue.timeHidden
    ? DateUtils.formatDateTime(timestamp, DateFormat.DateShortMonth, organizationTimeZone)
    : DateUtils.formatDateTime(timestamp, DateFormat.DateTimeShortMonthNoSeconds, dateContext.userTimeZone);
};

export const DateFieldUtils = {
  formatDateFieldValueToLongMonth,
  formatDateFieldValueToLongWeekdayShortMonth,
  formatDateFieldValueToShortMonth,
  formatDateFieldValueToShortMonthNoSeconds,
};
