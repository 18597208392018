import { useInjector } from 'components/injection-provider';
import { trace } from 'components/trace';
import * as React from 'react';
import { onlineManager, useQueryClient } from 'react-query';
import { useDebounce } from 'react-use';
import { makeMutationListener } from 'services/react-query';
import { MUTATION_KEYS } from '../../mutation-keys';

export const DRAFT_STATUSES = ['saving', 'saved', 'offline', 'error'] as const;
export type DraftStatus = typeof DRAFT_STATUSES[number];

const CONFIRM_LEAVE_MESSAGE = 'You have unpublished changes. Discard changes and leave this page?';
export const useDraftStatus = () => {
  const { $rootScope } = useInjector('$rootScope');
  const logger = trace({ name: 'useDraftStatus' });
  const queryClient = useQueryClient();
  const mutationCache = queryClient.getMutationCache();
  const state = React.useState<DraftStatus>('saved');
  const [status, setStatus] = state;

  React.useEffect(() => {
    const unsubscribe = mutationCache.subscribe(
      makeMutationListener({
        keys: MUTATION_KEYS,
        onSuccess: () => {
          setStatus('saving');
        },
        onError: e => {
          setStatus('error');
          logger.error('unable to save changes to page', e);
        },
      }),
    );
    return () => {
      unsubscribe();
    };
  }, [logger, mutationCache, setStatus]);

  useDebounce(
    () => {
      if (status === 'saving') {
        setStatus('saved');
      }
    },
    1000,
    [status],
  );

  React.useEffect(() => {
    const unsubscribe = onlineManager.subscribe(() => {
      setStatus(onlineManager.isOnline() ? 'saved' : 'offline');
    });
    return () => {
      unsubscribe();
    };
  }, [setStatus]);

  React.useEffect(() => {
    if (status === 'error') {
      const alertUser = (e: WindowEventMap['beforeunload']) => {
        e.returnValue = CONFIRM_LEAVE_MESSAGE;
        return CONFIRM_LEAVE_MESSAGE;
      };
      const unsubscribe = $rootScope.$on('$locationChangeStart', e => {
        const answer = window.confirm(CONFIRM_LEAVE_MESSAGE);
        if (!answer) {
          e.preventDefault();
        }
      });
      window.addEventListener('beforeunload', alertUser);
      return () => {
        unsubscribe();
        window.removeEventListener('beforeunload', alertUser);
      };
    }
  }, [$rootScope, status]);

  return state;
};
