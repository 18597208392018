import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { GetSettings, useGetOnboardingSettingsQuery, useUpdateSettingsMutation } from 'features/user/query-builder';
import { useQueryClient } from 'react-query';
import { OnboardingSettings, SettingsKey, UserSettings } from 'components/user/user-constants';

/**
 * Gets/sets keys under the onboarding settings key.
 * */
export function useOnboardingSettings() {
  const currentUser = useSelector(SessionSelector.getCurrentUser);
  if (!currentUser) {
    throw new Error('User should be logged in before useOnboardingSettings is used.');
  }

  const getSettingsQueryKey = GetSettings.getKey({ id: currentUser.id });
  const onboardingSettingsQuery = useGetOnboardingSettingsQuery({ id: currentUser.id });
  const queryClient = useQueryClient();
  const settingsMutation = useUpdateSettingsMutation({
    onMutate: async () => {
      // wait for settings query to finish before mutating
      await queryClient.fetchQuery(getSettingsQueryKey);
    },
  });

  const updateOnboardingSettings = async (settings: OnboardingSettings) => {
    // wait for settings query to finish before mutating
    const currentSettings = await queryClient.fetchQuery<UserSettings>(getSettingsQueryKey);
    const currentOnboardingSettings = currentSettings?.[SettingsKey.ONBOARDING] ?? {};
    const updatedOnboardingSettings = {
      ...currentOnboardingSettings,
      ...settings,
    };
    return settingsMutation.mutateAsync(
      {
        id: currentUser.id,
        key: SettingsKey.ONBOARDING,
        value: updatedOnboardingSettings,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(getSettingsQueryKey);
        },
      },
    );
  };

  return {
    onboardingSettingsQuery,
    updateOnboardingSettings,
  };
}
