import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { Template } from '@process-street/subgrade/process';
import { ButtonProps } from 'components/design/next';

export type RunMultipleButtonProps = {
  templateId?: Template['id'];
  children: React.ReactElement<ButtonProps>;
};

export const RunMultipleButton: React.FC<RunMultipleButtonProps> = React.memo(({ children, templateId }) => {
  const { $state, $stateParams } = useInjector('$state', '$stateParams');
  const id = templateId ?? $stateParams.id;

  const go = React.useCallback(() => {
    $state.go(`checklistAddMultiple`, { templateId: id });
  }, [$state, id]);

  if (!React.isValidElement(children) || Array.isArray(children)) {
    throw new Error('RunMultipleButton child must be a single clickable element');
  }

  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(id, { enabled: Boolean(id) });
  if (!permissionsQuery.data?.permissionMap.checklistCreate) return null;

  const btn = React.cloneElement(children, { onClick: go });

  return btn;
});
