import angular from 'angular';
import { FeatureGateServiceImpl } from './feature-gate.service.impl';
import { ObjectUtilsService } from './object-utils.service';
import { ResponseUtilsService } from './response-utils.service';

export const UtilsModule = angular
  .module('utils', [])
  .service('FeatureGateService', FeatureGateServiceImpl)
  .service('ObjectUtilsService', ObjectUtilsService)
  .service('ResponseUtilsService', ResponseUtilsService).name;
