import { createCachedAction } from 'reducers/util/index';

export const TASK_TEMPLATE_PERMIT_GET_ALL_BY_TEMPLATE_REVISION =
  'templateRevision/taskTemplatePermit/GET_ALL_BY_TEMPLATE_REVISION';
export const TASK_TEMPLATE_PERMIT_UPSERT = 'templateRevision/taskTemplatePermit/UPSERT';
export const TASK_TEMPLATE_PERMIT_UPSERT_ALL = 'templateRevision/taskTemplatePermit/UPSERT_ALL';

export const TaskTemplatePermitActions = TaskTemplatePermitApi => {
  'ngInject';

  const getAllByTemplateRevisionId = createCachedAction(
    TASK_TEMPLATE_PERMIT_GET_ALL_BY_TEMPLATE_REVISION,
    TaskTemplatePermitApi.getAllByTemplateRevisionId,
    templateRevisionId => ({ templateRevisionId }),
  );

  const upsert = createCachedAction(
    TASK_TEMPLATE_PERMIT_UPSERT,
    TaskTemplatePermitApi.upsert,
    (templateRevisionId, createRequest) => ({ templateRevisionId, createRequest }),
  );

  const upsertAll = createCachedAction(
    TASK_TEMPLATE_PERMIT_UPSERT_ALL,
    TaskTemplatePermitApi.upsertAll,
    (templateRevisionId, createRequest) => ({ templateRevisionId, createRequest }),
  );

  return {
    getAllByTemplateRevisionId,
    upsert,
    upsertAll,
  };
};
