import { Muid } from '@process-street/subgrade/core/core-model';

export const UserSettings = {
  Paywalls: {
    key: 'paywalls',
    defaults: {
      displayTrialExpiredPaywallForOrgIds: [],
    } as PaywallSettings,
  },
};

export type PaywallSettings = {
  displayTrialExpiredPaywallForOrgIds: Muid[];
};
