import * as React from 'react';
import { ChecklistTaskListComponents as Components } from './components';
import { FormResponsePageMachineHooks } from 'app/pages/responses/_id/form-response-page-hooks';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';
import { FormResponsePageProviders } from '../../providers';
import { Task, TaskStatus, TaskTemplateTaskType, TaskUtils } from '@process-street/subgrade/process';
import { OneOffTaskList } from 'pages/checklists/_id/components/task-list/components/one-off-task-list';
import { TaskMachineHooks } from 'pages/responses/_id/components/task/task-machine-hooks';
import { CoverImage } from './components/top-controls/cover-image';

export const TaskList = ({ onSelect }: { onSelect?: (task: Task) => void }) => {
  const formResponseActor = FormResponsePageProviders.FormResponseActorRef.useActorRef();

  const checklist = FormResponsePageMachineHooks.useChecklist();
  const oneOffTasks = FormResponsePageMachineHooks.useOneOffTasks();
  const tasks = FormResponsePageMachineHooks.useTasks();

  const currentTaskActor = FormResponseMachineHooks.useCurrentTaskActorRef();
  const currentTask = TaskMachineHooks.useTask(currentTaskActor);

  const shouldHideCompletedTasks = FormResponseMachineHooks.useShouldHideCompletedTasks();
  const shouldHideStoppedTasks = FormResponseMachineHooks.useShouldHideStoppedTasks();

  const taskNumberById = React.useMemo(
    () =>
      Object.fromEntries(
        tasks
          ?.filter(t => t.taskTemplate.taskType !== TaskTemplateTaskType.AI)
          .map((task, index) => [task.id, index + 1]) ?? [],
      ),
    [tasks],
  );

  const [tasksUntilNearestStop, tasksAfterNearestStop] = React.useMemo(() => {
    const safeTasks = tasks ?? [];
    const nearestStopIndex = safeTasks.findIndex(task => TaskUtils.isStopped(task));

    if (nearestStopIndex < 0)
      return [
        // There are not stopped tasks, so we return all of them
        safeTasks,
        // Return a empty list of hidden tasks
        [],
      ];

    const untilStop = safeTasks.slice(0, nearestStopIndex);
    const afterStop = safeTasks.slice(nearestStopIndex, safeTasks.length);

    return [untilStop, afterStop];
  }, [tasks]);

  const displayedTasks = React.useMemo(() => {
    const safeTasks = shouldHideStoppedTasks ? tasksUntilNearestStop : tasks ?? [];

    return safeTasks.filter(task => !(shouldHideCompletedTasks && TaskUtils.isCompleted(task)));
  }, [shouldHideStoppedTasks, tasksUntilNearestStop, tasks, shouldHideCompletedTasks]);

  const handleSelectTask = React.useCallback(
    (task: Task) => {
      formResponseActor.send({ type: 'SELECT_TASK', taskId: task.id });
      onSelect?.(task);
    },
    [formResponseActor, onSelect],
  );

  const handleTaskStatusChange = React.useCallback(
    (task: Task) => {
      if (task.status === TaskStatus.Completed) {
        formResponseActor.send({ type: 'UNCOMPLETE_TASK', taskId: task.id });
      } else {
        formResponseActor.send({ type: 'COMPLETE_TASK', taskId: task.id });
      }
    },
    [formResponseActor],
  );

  const templateId = checklist?.template.id;

  return (
    <>
      {templateId && <CoverImage templateId={templateId} />}
      <Components.Container>
        {checklist && <Components.TopControls checklist={checklist} />}

        <Components.TasksWrapper>
          {displayedTasks?.map((task, index, array) => {
            // grab original task number, before hiding completed tasks
            const stepNumber = taskNumberById[task.id] ?? 0;
            return (
              <Components.Item
                key={task.id}
                task={task}
                stepNumber={stepNumber}
                isFirst={index === 0}
                isLast={array.length - 1 === index}
                isSelected={task.id === currentTask?.id}
                onSelect={handleSelectTask}
                onTaskStatusChange={handleTaskStatusChange}
                selectedTask={currentTask}
              />
            );
          })}

          <Components.ToggleStoppedTasks hiddenTasksCount={tasksAfterNearestStop.length} />
        </Components.TasksWrapper>

        {/* requiredTaskIds were passed on failed WFR completion to indicate what completion was blocked by these tasks */}
        {checklist && oneOffTasks && <OneOffTaskList tasks={oneOffTasks} checklist={checklist} requiredTaskIds={[]} />}
      </Components.Container>
    </>
  );
};
