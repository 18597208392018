import React from 'react';
import { getReachedPlanLevelLimitsAsMessages } from 'utils/billing';
import { useGetOrganizationStatsQuery } from 'features/organization/query-builder';
import { getWarningMessage, upgradeButtonVisible } from './utils';
import { PlanFeatureSet, PlanLevel } from '@process-street/subgrade/billing';

import { Button, HStack, Link, Tooltip } from 'components/design/next';
import { isTrialing } from 'utils/organization';
import { ContactSales } from 'features/contact-sales/components/contact-sales';
import { useHref, useNavigate } from '@process-street/adapters/navigation';
import { queryString } from '@process-street/subgrade/util';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { isAdmin } from '@process-street/subgrade/core';
import { usePlan } from 'services/use-plan-track';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';

export const UpgradeButton = () => {
  const navigate = useNavigate();
  const billingTabHref = useHref({
    pathname: 'organizationManage.tab',
    search: queryString.stringify({ tab: 'billing' }),
  });

  const { data: userInfo } = useGetCurrentUserInfoQuery();
  const selectedOrganization = useSelectedOrganization();
  const selectedPlan = usePlan();

  const showRequestDemoButton = isTrialing(selectedOrganization?.subscription);

  const organizationStatsQuery = useGetOrganizationStatsQuery(selectedOrganization?.id!, {
    enabled: Boolean(selectedOrganization?.id),
  });

  const limits = getReachedPlanLevelLimitsAsMessages({
    planFeatureSet: selectedPlan?.featureSet as PlanFeatureSet,
    organizationStats: organizationStatsQuery.data,
  });

  if (!userInfo) return null;

  const topPanelMessage = getWarningMessage({
    organization: selectedOrganization,
    plan: selectedPlan,
    limits,
    userInfo,
  });

  const topPanelMessageTooltip = topPanelMessage.replace(/(<\/?strong>)/g, '');

  const isUpgradeButtonVisible = !isAdmin(userInfo.organizationMembership.role)
    ? false
    : upgradeButtonVisible({
        selectedOrganizationPlan: selectedPlan,
        selectedOrganization,
        limits,
      });

  if (!topPanelMessage.length) {
    return null;
  }
  return (
    <>
      <HStack
        color="blue.500"
        backgroundColor="blue.100"
        borderRadius="base"
        pl="4"
        pr={showRequestDemoButton ? '0' : '4'}
        minH="10"
      >
        <Tooltip label={topPanelMessageTooltip}>
          <Link
            verticalAlign={showRequestDemoButton ? 'baseline' : '-webkit-baseline-middle'}
            variant="noline"
            _hover={{
              color: 'brand.500',
            }}
            maxW="100"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            href={billingTabHref}
            dangerouslySetInnerHTML={{ __html: topPanelMessage }}
          ></Link>
        </Tooltip>
        {showRequestDemoButton && (
          <ContactSales
            textDecoration="underline"
            fontWeight="medium"
            _focus={{ color: 'brand.500' }}
            variant="ghost"
          />
        )}
      </HStack>

      {isUpgradeButtonVisible && (
        <Button
          onClick={() => {
            navigate(
              { pathname: 'organizationManage.tab', search: queryString.stringify({ tab: 'billing', modal: true }) },
              { replace: true },
            );
          }}
          variant="tertiary"
          size="md"
        >
          {isTrialing(selectedOrganization?.subscription)
            ? 'Subscribe'
            : selectedPlan?.level === PlanLevel.Free
            ? 'Upgrade'
            : 'Subscribe'}
        </Button>
      )}
    </>
  );
};
