import * as React from 'react';
import {
  VStack,
  Text,
  HStack,
  Button,
  Icon,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Wrap,
  WrapItem,
  IconButton,
  ButtonProps,
  FormLabel,
} from 'components/design/next';
import {
  SolutionTypeTag,
  getAutomationAppNameLabel,
  AutomationAppName,
  withTrayPrefix,
  withIncomingWebhookPrefix,
  getPrefixedAutomationAppName,
} from '@process-street/subgrade/automation';
import { useSolutionsByTemplateIdQuery } from 'features/automations/query-builder';
import { UserDetailUseCase, USE_CASE_OPTIONS } from '@process-street/subgrade/core';
import { FeaturedIncomingWebhooks } from 'features/automations/featured-incoming-webhook-apps';
import { useGetUserDetailsQuery } from 'features/user/query-builder/user-details';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useTemplateId } from '../../utils/use-template-id';
import { AutomationAppSquare } from 'pages/templates/_id/components/automation-app-square';
import { noop } from '@process-street/subgrade/util';
import { useAutomationAppButton } from '../app-selector/automation-app-button/use-automation-app-button';
import { useAutomationSelector } from '../selector/context';
import { useGetAutomationInstanceQuery } from '../../utils/use-get-automation-instance';
import { Option } from 'space-monad';
import { sortAutomationAppNames } from 'pages/templates/_id/components/choose-template-triggers';

export const AppSelectorV2: React.VFC = () => {
  const templateId = useTemplateId();
  const [state] = useAutomationSelector();
  const instanceQuery = useGetAutomationInstanceQuery({
    automationInstanceId: state.context.automationInstanceId,
    automationInstanceType: state.context.automationType,
  });
  const selectedAppName = Option(instanceQuery.data).fold(
    () => state.context.automationAppName,
    instance => getPrefixedAutomationAppName(instance),
  );

  const solutionsQuery = useSolutionsByTemplateIdQuery({ templateId });

  const trayTriggers =
    solutionsQuery.data
      ?.filter(solution => solution.solutionTypeTag === SolutionTypeTag.CreateChecklistWhen)
      .map(solution => withTrayPrefix(solution.automationApp)) ?? [];

  const webhookTriggers = FeaturedIncomingWebhooks.enabledAppNames.map(withIncomingWebhookPrefix);

  const allTriggersAppNames = Array.from(new Set([...trayTriggers, ...webhookTriggers]));

  const userId = useSelector(SessionSelector.getCurrentUserId);
  const userDetailsQuery = useGetUserDetailsQuery(userId!, {
    // can 404
    retry: 0,
  });
  const userUseCase = userDetailsQuery.data?.useCase as UserDetailUseCase | undefined;

  const [useCase, setUseCase] = React.useState<UserDetailUseCase | ''>('');

  const sortedTriggers = sortAutomationAppNames({
    automationAppNames: allTriggersAppNames,
    matchesUserUseCase: app => {
      return FeaturedIncomingWebhooks.getAutomationAppNameMatchesUseCase({
        automationAppName: app,
        useCase: userUseCase,
      });
    },
    matchesUseCaseFilter: app => {
      return (
        useCase === '' ||
        FeaturedIncomingWebhooks.getAutomationAppNameMatchesUseCase({ automationAppName: app, useCase })
      );
    },
  });

  const [appQuery, setAppQuery] = React.useState('');

  const visibleApps: AutomationAppName[] = selectedAppName
    ? [selectedAppName]
    : sortedTriggers.filter(automationApp => {
        const label = getAutomationAppNameLabel(automationApp);
        const labelMatch = label.toLowerCase().includes(appQuery.toLowerCase());
        return labelMatch;
      });

  const defaultButton = useAutomationAppButton({ automationApp: withIncomingWebhookPrefix('Webhook') });

  return (
    <VStack alignItems="stretch" maxH="338px" w="516px" spacing="4">
      <FormLabel marginBottom="0">
        <Text fontWeight="bold">1. Choose an app</Text>
      </FormLabel>

      {!selectedAppName && sortedTriggers.length > 8 ? (
        <HStack spacing="4" justifyContent="space-between">
          <InputGroup w="55%">
            <InputLeftElement>
              <Icon icon="search" size="4" variant="far" />
            </InputLeftElement>
            <Input placeholder="Search apps" value={appQuery} onChange={e => setAppQuery(e.target.value)} />
            {appQuery.length > 0 ? (
              <InputRightElement>
                <IconButton
                  variant="ghost"
                  size="xs"
                  aria-label="clear search"
                  icon={<Icon icon="close" size="4" variant="far" />}
                  onClick={() => setAppQuery('')}
                />
              </InputRightElement>
            ) : null}
          </InputGroup>

          <Select
            w="45%"
            placeholder="All Departments"
            value={useCase}
            onChange={e => setUseCase(e.target.value as typeof USE_CASE_OPTIONS[number])}
          >
            {USE_CASE_OPTIONS.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </HStack>
      ) : null}

      {visibleApps.length === 0 && appQuery.length > 0 ? (
        <VStack>
          <AutomationAppSquare automationApp={withIncomingWebhookPrefix('Webhook')} />
          <VStack>
            <Text variant="-1">
              Looks like{' '}
              <Text as="b" variant="inherit">
                {appQuery}
              </Text>{' '}
              is not available.
            </Text>
            <Text variant="-2">
              Why don&apos;t you try our custom{' '}
              <Button
                fontSize="xs"
                fontWeight="normal"
                variant="link"
                colorScheme="brand"
                onClick={() => defaultButton.setAutomationApp(withIncomingWebhookPrefix('Webhook'))}
              >
                Webhook
              </Button>
              ?
            </Text>
          </VStack>
        </VStack>
      ) : null}

      <Wrap spacing="6" flex="1" overflowY="auto" overflowX="hidden">
        {visibleApps.map(automationApp => {
          return (
            <WrapItem key={automationApp}>
              <AutomationButton automationApp={automationApp} />
            </WrapItem>
          );
        })}
      </Wrap>
    </VStack>
  );
};

const AutomationButton: React.VFC<{ automationApp: AutomationAppName }> = ({ automationApp }) => {
  const label = getAutomationAppNameLabel(automationApp);

  const { hasAccess, isDisabled, setAutomationApp } = useAutomationAppButton({
    automationApp,
  });

  const hasAccessProps: ButtonProps = hasAccess
    ? { onClick: () => setAutomationApp(automationApp) }
    : {
        onClick: noop,
        _hover: { cursor: 'default' },
        bgColor: 'gray.200',
      };

  return (
    <Button
      variant="unstyled"
      maxW="21"
      _hover={
        isDisabled
          ? {}
          : {
              transform: 'scale(1.05)',
              transition: 'transform 0.2s',
            }
      }
      isDisabled={isDisabled}
      aria-disabled={isDisabled || !hasAccess}
      {...hasAccessProps}
    >
      <VStack>
        <AutomationAppSquare automationApp={automationApp} />
        <Text noOfLines={2} whiteSpace="normal" variant="-1" mt="2" fontWeight="medium">
          {label}
        </Text>
      </VStack>
    </Button>
  );
};
