import { FieldValue } from '@process-street/subgrade/process';
import React from 'react';
import styles from '../FormFieldValueRenderer.module.scss';

interface EmailFieldRendererProps {
  value: FieldValue;
}

export const EmailFieldRenderer: React.FunctionComponent<React.PropsWithChildren<EmailFieldRendererProps>> = ({
  value,
}) => {
  if (!value) {
    return <div />;
  }

  return (
    <a href={`mailto:${value}`} title={`${value}`}>
      <div className={styles.trimmedLabel}>{value}</div>
    </a>
  );
};
