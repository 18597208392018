import React from 'react';
import { Center, Icon, Text, VStack, StackProps } from 'components/design/next';
import { captureException as captureSentryException } from '@sentry/browser';
import { ErrorBoundary as ReactErrorBoundary, ErrorBoundaryPropsWithFallback } from 'react-error-boundary';

export type Props = Partial<Omit<ErrorBoundaryPropsWithFallback, 'fallback'>> & {
  recover?: (error: Error) => void;
  wrapperProps?: StackProps;
};

export const ErrorBoundary: React.FC<React.PropsWithChildren<Props>> = ({ recover, wrapperProps, ...props }) => {
  const onError = (error: Error) => {
    captureSentryException({ ...error, message: `Critical error: ${error.message}` });
    recover?.(error);
  };

  const fallback = (
    <Center>
      <VStack w="full" alignItems="center" spacing="4" py="10" {...wrapperProps}>
        <Icon
          icon="hexagon-xmark"
          variant="fad"
          primaryLayer={{ color: 'red.600' }}
          secondaryLayer={{ color: 'red.600' }}
          size="10"
        />

        <VStack w="full" alignItems="center" spacing="0">
          <Text color="gray.600">Oops! Something went wrong.</Text>
          <Text fontSize="sm" color="gray.500">
            It seems we've encountered an error.
          </Text>
        </VStack>
      </VStack>
    </Center>
  );

  return <ReactErrorBoundary onError={onError} fallback={fallback} {...props} />;
};

export const withErrorBoundary = <T extends {} = {}>(Component: React.FC<React.PropsWithChildren<T>>) => {
  const WithErrorBoundary: React.FC<React.PropsWithChildren<T>> = props => {
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    );
  };

  WithErrorBoundary.displayName = `withErrorBoundary(${Component.displayName})`;

  return WithErrorBoundary;
};
