import { FieldTypePreservingOmit } from '@process-street/subgrade/core';
import { NativeAutomation } from '@process-street/subgrade/process';
import { BlvdSelect, BlvdSelectProps } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import * as React from 'react';
import { MathOperatorOption } from './math-operator-option';

export type TMathOperatorOption = { value: NativeAutomation.Operator; label: string };

const operatorOptions: TMathOperatorOption[] = [
  {
    value: 'Add',
    label: 'Add',
  },
  {
    value: 'Subtract',
    label: 'Subtract',
  },
  {
    value: 'Multiply',
    label: 'Multiply',
  },
  {
    value: 'Divide',
    label: 'Divide',
  },
];

interface MathOperatorSelectProps
  extends FieldTypePreservingOmit<BlvdSelectProps<TMathOperatorOption>, 'value' | 'onChange'> {
  value?: NativeAutomation.Operator;
  onChange: (value: TMathOperatorOption) => void;
}

export const MathOperatorSelect: React.FC<React.PropsWithChildren<MathOperatorSelectProps>> = ({
  value: valueProp,
  onChange,
  ...props
}) => {
  const value = React.useMemo(() => {
    const opt = valueProp ? operatorOptions.find(x => x.value === valueProp) : null;
    return opt ?? null;
  }, [valueProp]);

  return (
    <BlvdSelect
      isSearchable={false}
      options={operatorOptions}
      value={value}
      components={{
        Option: MathOperatorOption,
      }}
      onChange={option => {
        if (BlvdSelectHelpers.isOptionType<{ value: string }>(option)) {
          onChange(option);
        }
      }}
      {...props}
    />
  );
};
