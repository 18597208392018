import { FieldType, FormFieldWidget } from '@process-street/subgrade/process';
import { RuleConstants } from '@process-street/subgrade/conditional-logic/rule-constants';
import { trace } from 'components/trace';

const logger = trace({ name: 'OperandTypeResolver' });

function resolve<T extends Pick<FormFieldWidget, 'fieldType'>>(formFieldWidget: T) {
  let operandType;

  const widgetType = formFieldWidget?.fieldType;
  switch (widgetType) {
    case FieldType.Date:
    case FieldType.SendRichEmail:
      operandType = RuleConstants.OperandType.DATE_TIME;
      break;
    case FieldType.Text:
    case FieldType.Textarea:
    case FieldType.Hidden:
    case FieldType.Select:
    case FieldType.Email:
    case FieldType.Url:
    case FieldType.Number:
    case FieldType.Members:
      operandType = RuleConstants.OperandType.STRING;
      break;
    case FieldType.MultiChoice:
      operandType = RuleConstants.OperandType.STRING_LIST;
      break;
    default:
      logger.error('not supported form field widget of type %s', widgetType);
  }

  return operandType;
}

export const OperandTypeResolver = {
  resolve,
};
