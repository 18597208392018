export const TemplateApi = function (Templates) {
  'ngInject';

  /**
   * Copies template by template id to target folder id with target template name
   *
   * @param templateId
   * @param targetFolderId
   * @param targetTemplateName
   *
   * @return {Promise}
   */
  const copy = (templateId, targetFolderId, targetTemplateName) =>
    Templates.copy({ id: templateId, folderId: targetFolderId, name: targetTemplateName }).$promise;

  const create = (organizationId, templateId, folderId, name) =>
    Templates.create({
      organizationId,
      templateId,
      folderId,
      name,
    }).$promise;

  /**
   * Deletes a template by id
   *
   * @param id
   */
  const deleteById = id => Templates.deleteById({ id }).$promise;

  const getAllByOrganizationIdAndStatus = (organizationId, templateStatus) =>
    Templates.getAllByOrganizationIdAndStatus({
      organizationId,
      templateStatus,
    }).$promise;

  const getAllByOrganizationIdAndStatusForSchedule = (organizationId, templateStatus) =>
    Templates.getAllByOrganizationIdAndStatus({
      organizationId,
      templateStatus,
      schedule: true,
      templateType: 'Playbook',
    }).$promise;

  /**
   * Gets a single template by id
   *
   * @param id
   * @return {Promise}
   */
  const getById = id => Templates.getById({ id }).$promise;

  const getPublicById = id => Templates.getPublic({ id }).$promise;

  const undelete = templateId => Templates.undelete({ id: templateId }).$promise;

  const update = updateTemplateRequest => Templates.update(updateTemplateRequest).$promise;

  const updatePublic = (templateId, publicStatus) =>
    Templates.updatePublic({
      id: templateId,
      public: publicStatus,
    }).$promise;

  const updateShareableRunLink = (templateId, shared) =>
    Templates.updateShareableRunLink({
      id: templateId,
      shared,
    }).$promise;

  /**
   * Updates share level of a template
   *
   * @param id
   * @param level
   *
   * @return {Promise}
   */
  const updateShareLevelById = (id, level) => Templates.updateShareLevelById({ id, level }).$promise;

  const updateStatus = (templateId, status) =>
    Templates.updateStatus({
      id: templateId,
      status,
    }).$promise;

  return {
    copy,
    create,
    deleteById,
    getAllByOrganizationIdAndStatus,
    getAllByOrganizationIdAndStatusForSchedule,
    getById,
    getPublicById,
    undelete,
    update,
    updatePublic,
    updateShareableRunLink,
    updateShareLevelById,
    updateStatus,
  };
};
