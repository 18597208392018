import { handleActionsOnSuccess } from 'reducers/util';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { PLAN_FEATURE_SET_GET_BY_ID } from './plan-feature-set.actions';

export const planFeatureSetEntitiesReducer = handleActionsOnSuccess(
  {
    [PLAN_FEATURE_SET_GET_BY_ID]: (state, { payload: featureSet }) => EntitiesReducerUtils.upsert(state, featureSet),
  },
  {},
);
