import * as React from 'react';
import { FocusBar as PageFocusBar } from 'pages/pages/_id/edit/page/components/focus-bar';
import { FocusBar as WorkflowFocusBar } from './workflow';
import { TemplateType } from '@process-street/subgrade/process';
import { WorkflowFocusBarWrapper } from './workflow/workflow-focus-bar-wrapper';
import { match } from 'ts-pattern';

export type Props = {
  templateType: TemplateType;
};

export const FocusBar: React.FC<React.PropsWithChildren<Props>> = ({ templateType }) => {
  return match(templateType)
    .with(TemplateType.Page, () => <PageFocusBar />)
    .with(TemplateType.Playbook, () => (
      <WorkflowFocusBarWrapper>
        <WorkflowFocusBar />
      </WorkflowFocusBarWrapper>
    ))
    .otherwise(() => null);
};
