import { Muid } from '@process-street/subgrade/core';
import { Checklist } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UndeleteChecklistMutationParams = {
  checklistId: Muid;
};

export type UndeleteChecklistMutationResponse = Checklist;

export const UndeleteChecklistMutation = {
  key: ['checklist', 'undelete'],
  mutationFn: ({ checklistId }: UndeleteChecklistMutationParams) =>
    axiosService
      .getAxios()
      .put(`/1/checklists/${checklistId}/undelete`, { checklistId })
      .then(r => r.data),
};

export const useUndeleteChecklistMutation = (
  options: UseMutationOptions<UndeleteChecklistMutationResponse, AxiosError, UndeleteChecklistMutationParams> = {},
) =>
  useMutation(UndeleteChecklistMutation.mutationFn, {
    mutationKey: UndeleteChecklistMutation.key,
    ...options,
  });
