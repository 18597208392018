import * as React from 'react';
import { Icon, IconButton } from 'components/design/next';
import { useTemplateSettingsModalContext } from 'pages/templates/_id/components/template-settings-modal/template-settings-modal-context';
import { usePaywall } from 'components/paywalls';
import { FreeMember } from 'components/paywalls/edit-workflow/ui';

export type WorkflowSetupButtonProps = {
  hasUpdatePermission: boolean;
};

export const WorkflowSetupButton = ({ hasUpdatePermission }: WorkflowSetupButtonProps) => {
  const { openSetup } = useTemplateSettingsModalContext();
  const { setPaywall } = usePaywall();

  const handleOpenSetupClick = () => {
    if (hasUpdatePermission) {
      return openSetup();
    } else {
      setPaywall(<FreeMember />);
    }
  };

  return (
    <IconButton
      aria-label="Workflow setup"
      icon={<Icon icon="cog" variant="far" size="4" color="gray.700" />}
      variant="secondary"
      onClick={handleOpenSetupClick}
      backgroundColor="gray.200"
      _hover={{ backgroundColor: 'gray.300' }}
    />
  );
};
