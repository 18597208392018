import { isModelRef } from '@process-street/subgrade/core';
import {
  FieldType,
  FormFieldWidget,
  Widget,
  WidgetHeaderOfType,
  WidgetType,
  WidgetUpdateOrderTreesResponse,
  WidgetUpdateResponseStatus,
} from '@process-street/subgrade/process';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { EntityMap, LookupMap } from '@process-street/subgrade/redux/types';
import { FORM_FIELD_VALUE_GET_ALL_BY_CHECKLIST_REVISION } from 'components/form-field-value/store/form-field-value.actions';
import { FormFieldValueGetByChecklistRevisionIdAction } from 'components/form-field-value/store/types';
import {
  WIDGET_COPY,
  WIDGET_CREATE_AT,
  WIDGET_CREATE_FILE,
  WIDGET_DELETE_BY_HEADER_ID,
  WIDGET_GET_ALL_BY_CHECKLIST_REVISION_ID,
  WIDGET_GET_ALL_BY_TASK_TEMPLATE_ID,
  WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID,
  WIDGET_MOVE,
  WIDGET_SYNC,
  WIDGET_UPDATE,
  WIDGET_UPDATE_ALL_HIDDEN_BY_DEFAULT,
  WIDGET_UPDATE_ALL_ORDER_TREES,
  WidgetDeleteByHeaderIdAction,
  WidgetGetAllByChecklistRevisionIdAction,
  WidgetMoveAction,
  WidgetUpdateAllHiddenByDefault,
  WidgetUpdateOrderTreesAction,
} from 'components/widgets/store/widget.actions';
import { referencesNormalizer } from 'reducers/entities/reference-normalizer';
import { getStatusReducer } from 'reducers/statuses/statuses-utils';
import { TASK_TEMPLATE_DELETE_ALL, TaskTemplateDeleteAllAction } from 'reducers/task-template/task-template.actions';
import { WidgetLookups, WidgetStatuses } from 'reducers/types';
import { toSuccess } from 'reducers/util';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { combineReducers, Reducer } from 'redux';
import { Action, combineActions } from 'redux-actions';
import { match, P } from 'ts-pattern';

const normalizeWidgetHeader = <T extends WidgetType>(widgetHeader: WidgetHeaderOfType<T>) => {
  return referencesNormalizer<WidgetHeaderOfType<T>>(['organization', 'taskTemplate'])(widgetHeader);
};

const normalizeWidget = <_Widget extends Widget>(widget: _Widget): _Widget =>
  ({
    ...widget,
    header: normalizeWidgetHeader(widget.header),
  } as _Widget);

const normalizeFormFieldWidget =
  (existingEntityMap: EntityMap<Widget>) =>
  (widget: Widget): Widget => {
    const existingWidget = existingEntityMap[widget.id];
    const richWidget = match({ existingWidget, widget })
      .with(
        {
          existingWidget: { fieldType: FieldType.Select, config: { linkId: P.string } },
          widget: { fieldType: FieldType.Select },
        },
        // Enrich the widget config because the response from `form-field-values` endpoint does not contains the
        // dropdown's link information.
        ({ existingWidget, widget }) => ({
          ...widget,
          config: {
            ...existingWidget.config,
            ...widget.config,
          },
        }),
      )
      .otherwise(() => widget);

    return {
      ...richWidget,
      header: normalizeWidgetHeader(widget.header),
    } as Widget;
  };

export const widgetEntitiesReducer = handleActionsOnSuccess(
  {
    // @ts-expect-error -- TODO
    [combineActions(
      WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID,
      WIDGET_GET_ALL_BY_TASK_TEMPLATE_ID,
      WIDGET_GET_ALL_BY_CHECKLIST_REVISION_ID,
    )]: (state: EntityMap<Widget>, action: Action<Widget[]>) =>
      EntitiesReducerUtils.upsertAll(state, action.payload, normalizeWidget),
    // @ts-expect-error -- TODO
    [combineActions(WIDGET_CREATE_AT, WIDGET_CREATE_FILE, WIDGET_COPY, WIDGET_UPDATE, WIDGET_SYNC, WIDGET_MOVE)]: (
      state: EntityMap<Widget>,
      action: Action<Widget>,
    ) => EntitiesReducerUtils.upsert(state, action.payload, normalizeWidget),
    [WIDGET_UPDATE_ALL_ORDER_TREES]: (state: EntityMap<Widget>, action: WidgetUpdateOrderTreesAction) => {
      const {
        payload: responses,
        meta: { widgets: widgetMap },
      } = action;
      const updatedWidgets: Widget[] = responses.reduce(
        (widgets: Widget[], response: WidgetUpdateOrderTreesResponse) => {
          if (
            response.response === WidgetUpdateResponseStatus.Ok &&
            response.widgetHeader &&
            widgetMap[response.widgetHeader.id]
          ) {
            widgets.push({
              ...widgetMap[response.widgetHeader.id],
              header: response.widgetHeader,
            } as Widget);
          }
          return widgets;
        },
        [],
      );

      return EntitiesReducerUtils.upsertAll(state, updatedWidgets, normalizeWidget);
    },
    [WIDGET_DELETE_BY_HEADER_ID]: (state: EntityMap<Widget>, action: WidgetDeleteByHeaderIdAction) =>
      EntitiesReducerUtils.delete(state, action.meta.widget),
    [TASK_TEMPLATE_DELETE_ALL]: (state: EntityMap<Widget>, action: TaskTemplateDeleteAllAction) =>
      EntitiesReducerUtils.deleteAll(state, action.meta.relatedWidgets),
    [FORM_FIELD_VALUE_GET_ALL_BY_CHECKLIST_REVISION]: (
      state: EntityMap<Widget>,
      action: FormFieldValueGetByChecklistRevisionIdAction,
    ) => {
      const widgets: FormFieldWidget[] = action.payload
        .map(fieldValue => fieldValue.formFieldWidget)
        .filter(isModelRef);
      return EntitiesReducerUtils.upsertAll(state, widgets, normalizeFormFieldWidget(state));
    },
    [toSuccess(WIDGET_UPDATE_ALL_HIDDEN_BY_DEFAULT)]: (
      state: EntityMap<Widget>,
      action: WidgetUpdateAllHiddenByDefault,
    ) => {
      const updatedWidgets: Widget[] = [];
      const models = action.meta.hiddenByDefaultModels;

      models.forEach(model => {
        const widget = state[model.widgetId];
        if (widget) {
          updatedWidgets.push({
            ...widget,
            header: {
              ...widget.header,
              hiddenByDefault: model.hiddenByDefault,
            },
          } as Widget);
        }
      });

      return EntitiesReducerUtils.upsertAll(state, updatedWidgets);
    },
  },
  {},
);

const widgetByTaskTemplateIdReducer: Reducer<LookupMap> = handleActionsOnSuccess(
  {
    // @ts-expect-error -- TODO
    [combineActions(
      WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID,
      WIDGET_GET_ALL_BY_CHECKLIST_REVISION_ID,
      WIDGET_GET_ALL_BY_TASK_TEMPLATE_ID,
    )]: (state: LookupMap, action: Action<Widget[]>) =>
      LookupsReducerUtils.upsertAllUsingSelectorFunctions(
        state,
        action.payload,
        widget => widget.header.taskTemplate.id,
      ),
    // @ts-expect-error -- TODO
    [combineActions(WIDGET_CREATE_AT, WIDGET_CREATE_FILE, WIDGET_COPY, WIDGET_UPDATE, WIDGET_SYNC)]: (
      state: LookupMap,
      action: Action<Widget>,
    ) =>
      LookupsReducerUtils.upsertAllUsingSelectorFunctions(
        state,
        [action.payload],
        widget => widget.header.taskTemplate.id,
      ),
    [WIDGET_DELETE_BY_HEADER_ID]: (state: LookupMap, action: WidgetDeleteByHeaderIdAction) =>
      LookupsReducerUtils.deleteUsingSelectorFunctions(
        state,
        action.meta.widget,
        widget => widget.header.taskTemplate.id,
      ),
    [WIDGET_MOVE]: (state: LookupMap, action: WidgetMoveAction) => {
      const removedState = LookupsReducerUtils.deleteUsingSelectorFunctions(
        state,
        action.payload,
        () => action.meta.sourceTaskTemplateId,
      );
      return LookupsReducerUtils.upsertAllUsingSelectorFunctions(
        removedState,
        [action.payload],
        widget => widget.header.taskTemplate.id,
      );
    },
    [TASK_TEMPLATE_DELETE_ALL]: (state: LookupMap, action: TaskTemplateDeleteAllAction) =>
      LookupsReducerUtils.deleteAllKeysByIds(
        state,
        action.meta.taskTemplates.map(taskTemplate => taskTemplate.id),
      ),
  },
  {},
);

const widgetByChecklistRevisionIdReducer: Reducer<LookupMap> = handleActionsOnSuccess(
  {
    [WIDGET_GET_ALL_BY_CHECKLIST_REVISION_ID]: (state: LookupMap, action: WidgetGetAllByChecklistRevisionIdAction) =>
      LookupsReducerUtils.replaceAllUsingSelectorFunctions(
        state,
        action.payload,
        () => action.meta.checklistRevisionId,
      ),
  },
  {},
);

export const widgetLookupsReducer = combineReducers<WidgetLookups>({
  byChecklistRevisionId: widgetByChecklistRevisionIdReducer,
  byTaskTemplateId: widgetByTaskTemplateIdReducer,
});

export const widgetStatusesReducer = combineReducers<WidgetStatuses>({
  byTemplateRevisionId: getStatusReducer(WIDGET_GET_ALL_BY_TEMPLATE_REVISION_ID, 'templateRevisionId'),
});
