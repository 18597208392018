import { createSelector, Selector } from 'reselect';
import { ApprovalRuleSubject } from '../../approval-rule/approval-rule-subject.model';
import { Muid, Option } from '../../core';
import { safeEntityMapToArrayByIdsWith } from '../safe-entity-map-to-array-by-ids';
import { BaseApprovalRuleSubjectLookupsState, BaseReduxState, EntityMap, LookupMap } from '../types';
import { BaseTaskTemplateSelector } from './task-template.selectors';
import { BaseTaskSelector } from './task.selectors';

const getEntityMap: Selector<BaseReduxState, EntityMap<ApprovalRuleSubject>> = (state: BaseReduxState) =>
  state.entities.approvalRuleSubject;
const getLookups: Selector<BaseReduxState, BaseApprovalRuleSubjectLookupsState> = (state: BaseReduxState) =>
  state.lookups.approvalRuleSubject;

const getById =
  (id: Muid): Selector<BaseReduxState, Option<ApprovalRuleSubject>> =>
  (state: BaseReduxState) =>
    getEntityMap(state)[id];

const getLookupMapByTemplateRevisionId: Selector<BaseReduxState, LookupMap> = (state: BaseReduxState) =>
  getLookups(state).byTemplateRevisionId;

const getIdsByTemplateRevisionId = (templateRevisionId: Muid): Selector<BaseReduxState, Muid[]> =>
  createSelector(getLookupMapByTemplateRevisionId, (lookupMap: LookupMap) => lookupMap[templateRevisionId] ?? []);

const getAllByTemplateRevisionId = (templateRevisionId: Muid): Selector<BaseReduxState, ApprovalRuleSubject[]> =>
  createSelector(
    [getEntityMap, getIdsByTemplateRevisionId(templateRevisionId)],
    (entitiesMap, ruleIds): ApprovalRuleSubject[] => safeEntityMapToArrayByIdsWith(entitiesMap, ruleIds),
  );

const getAllByTaskTemplateGroupId = (
  templateRevisionId: Muid,
  taskTemplateGroupId: Muid,
): Selector<BaseReduxState, ApprovalRuleSubject[]> =>
  createSelector(getAllByTemplateRevisionId(templateRevisionId), allRules =>
    allRules.filter(approvalRule => approvalRule.approvalTaskTemplateGroupId === taskTemplateGroupId),
  );

const getAllByTaskTemplateGroupIds = (
  templateRevisionId: Muid,
  taskTemplateGroupIds: Muid[],
): Selector<BaseReduxState, ApprovalRuleSubject[]> =>
  createSelector(getAllByTemplateRevisionId(templateRevisionId), allTemplateRevisionRules =>
    allTemplateRevisionRules.filter(r => taskTemplateGroupIds.includes(r.approvalTaskTemplateGroupId)),
  );

const getAllByApprovalTaskId =
  (approvalTaskId: Muid): Selector<BaseReduxState, ApprovalRuleSubject[]> =>
  (state: BaseReduxState) => {
    const approvalTask = BaseTaskSelector.getById(approvalTaskId)(state);
    if (!approvalTask) {
      return [];
    }

    const approvlaTaskTemplate = BaseTaskTemplateSelector.getById(approvalTask.taskTemplate.id)(state);
    if (!approvlaTaskTemplate) {
      return [];
    }

    return getAllByTaskTemplateGroupId(approvlaTaskTemplate.templateRevision.id, approvlaTaskTemplate.group.id)(state);
  };

export const BaseApprovalRuleSelector = {
  getAllByTaskTemplateGroupId,
  getAllByTaskTemplateGroupIds,
  getAllByTemplateRevisionId,
  getAllByApprovalTaskId,
  getById,
};
