import { ModalProps } from '@chakra-ui/modal';
import { PaywallSettings, UserSettings } from '@process-street/subgrade/core/user-settings-model';
import { usePaywall } from 'components/paywalls';
import { useMutateUserSettings, useUserSettings } from 'components/paywalls/trial-expired/query';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

export type Props = Partial<ModalProps>;

export const useDismissTrialExpiredPaywall = () => {
  const { setPaywall } = usePaywall();

  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);

  const { data: paywallSettings, isLoading } = useUserSettings<PaywallSettings>(
    UserSettings.Paywalls.key,
    UserSettings.Paywalls.defaults,
  );

  const mutateUserSettings = useMutateUserSettings<PaywallSettings>();

  const dismissPaywall = () => {
    if (
      selectedOrganization &&
      paywallSettings &&
      paywallSettings.displayTrialExpiredPaywallForOrgIds.includes(selectedOrganization.id)
    ) {
      return mutateUserSettings
        .mutateAsync({
          key: UserSettings.Paywalls.key,
          value: {
            ...paywallSettings,
            displayTrialExpiredPaywallForOrgIds: paywallSettings.displayTrialExpiredPaywallForOrgIds.filter(
              orgId => orgId !== selectedOrganization.id,
            ),
          },
        })
        .then(() => {
          setPaywall(null);
        });
    }
  };

  return { dismissPaywall, isLoading };
};
