import * as React from 'react';
import { HStack, Text, Icon, Alert, Tooltip, Flex } from 'components/design/next';
import { DraftStatus, useDraftStatus } from './use-draft-status';
import { useGetFocusBarColor } from '../focus-bar/use-get-focus-bar-color';

export type UIProps = { status: DraftStatus };

export const DraftStatusIndicatorUI: React.FC<React.PropsWithChildren<UIProps>> = ({ status }) => {
  const getFocusBarColor = useGetFocusBarColor();

  return (
    <HStack
      spacing="2"
      color={getFocusBarColor({ light: 'gray.600', dark: 'white' })}
      display={['none', null, 'inline-flex']}
    >
      {(() => {
        switch (status) {
          case 'saving':
            return (
              <>
                <Icon
                  icon="sync-alt"
                  variant="far"
                  color={getFocusBarColor({ light: 'gray.600', dark: 'white' })}
                  size="3"
                  animation="spin"
                />
                <Text variant="-1">Saving…</Text>
              </>
            );
          case 'saved':
            return <Text variant="-1">Draft auto-saved</Text>;
          case 'offline':
            return (
              <Alert status="error" py="0.5" px="1">
                <Tooltip label="You are offline. Changes to the page will not be saved.">
                  <Flex>
                    <Icon icon="wifi-slash" variant="fas" color="red.500" size="3" mr="1" />
                  </Flex>
                </Tooltip>
                <Text color="gray.600" variant="-1" fontWeight="medium">
                  Offline
                </Text>
              </Alert>
            );
          case 'error':
            return (
              <Alert status="error" py="0.5" px="1">
                <Icon icon="exclamation-circle" variant="far" color="red.500" size="3" mr="1" />
                <Text color="gray.600" variant="-1" fontWeight="medium">
                  Error saving changes
                </Text>
              </Alert>
            );
        }
      })()}
    </HStack>
  );
};

export const DraftStatusIndicator: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [status] = useDraftStatus();
  return <DraftStatusIndicatorUI status={status} />;
};
