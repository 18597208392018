import * as React from 'react';
import { Alert, AlertIcon, AlertProps, AlertTitle, CloseButton, Link, Text } from 'components/design/next';
import { NgLink } from 'features/app/components/ng-link';
import { mailto as buildMailTo } from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useGetNewestTemplateRevisionsByTemplateIdQuery, useGetTemplateQuery } from 'features/template/query-builder';
import { useStateParam } from 'hooks/use-state-param';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { match } from 'ts-pattern';
import { useGetIsPaidAccount } from 'utils/plans/get-is-paid-account/use-get-is-paid-account';
import { useAdminEmails } from 'components/paywalls/use-admin-emails';

export interface UpgradePlanAlertProps extends Partial<AlertProps> {
  mode?: 'flash' | 'sticky';
}

export const RECENTLY_CREATED_WINDOW_MS = 1000 * 30; // 30 seconds

export const UpgradePlanAlert: React.FC<React.PropsWithChildren<UpgradePlanAlertProps>> = ({
  mode = 'sticky',
  ...props
}) => {
  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const isPaidAccount = useGetIsPaidAccount();

  const templateId = useStateParam({ key: 'id' });
  const templateCreatedDate = useGetTemplateQuery({ templateId }).data?.audit.createdDate ?? 0;
  const revisionCreatedDate =
    useGetNewestTemplateRevisionsByTemplateIdQuery({ templateId }).data?.[0].audit.createdDate ?? 0;
  const now = Date.now();

  const isFirstRevision = revisionCreatedDate - templateCreatedDate < RECENTLY_CREATED_WINDOW_MS;
  const revisionWasJustCreated = now - revisionCreatedDate < RECENTLY_CREATED_WINDOW_MS;

  const shouldShow = match({ isPaidAccount, mode })
    .with({ isPaidAccount: true }, () => false)
    .with({ mode: 'flash' }, () => isFirstRevision && revisionWasJustCreated)
    .otherwise(() => true);

  const [hasClosed, setHasClosed] = React.useState(false);
  const close = () => setHasClosed(true);

  const memberships = useSelector(SessionSelector.getAllOrganizationMembershipsOfCurrentUser);
  const userOm = memberships.find(m => m.organization.id === selectedOrganization?.id);
  const isAdmin = userOm?.role === OrganizationMembershipRole.Admin;

  return shouldShow && !hasClosed ? (
    <Alert status="info" {...props}>
      <AlertIcon boxSize="4" />

      <AlertTitle>{isAdmin ? <Admin /> : <NonAdmin />}</AlertTitle>

      {mode === 'flash' ? <CloseButton color="gray.500" size="sm" onClick={() => close()} /> : null}
    </Alert>
  ) : null;
};

const Admin = () => {
  return (
    <Text variant="-1" color="blue.600">
      To share this form with people outside your organization,{' '}
      <NgLink
        textDecoration="underline"
        color="inherit"
        to="organizationManage.tab"
        params={{ tab: 'billing' }}
        isExternal
      >
        upgrade
      </NgLink>{' '}
      your account.
    </Text>
  );
};

const NonAdmin = () => {
  const user = useSelector(SessionSelector.getCurrentUser);
  const org = useSelector(SessionSelector.getSelectedOrganization);
  const [adminEmail, ...bcc] = useAdminEmails();
  const { $state } = useInjector('$state');
  const mailto = buildMailTo({
    to: adminEmail,
    subject: `Someone on your team wants to share a form publicly`,
    body: `${user?.username} would like to share a form with people outside your organization.
    To share this form with people outside your organization, upgrade your account.
    Upgrade here: ${$state.href('organizationManage.tab', { tab: 'billing' })}`,
    bcc,
  });

  return (
    <Text variant="-1" color="blue.600">
      To share this form with people outside your organization,{' '}
      <Link textDecoration="underline" color="inherit" href={mailto} isExternal>
        contact your {org?.name} admin
      </Link>{' '}
    </Text>
  );
};
