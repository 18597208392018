import * as React from 'react';
import { User } from '@process-street/subgrade/core';
import { Avatar, AvatarProps } from 'components/design/next';
import { getAvatar } from 'app/components/common/Avatar';
import constant from 'lodash/constant';

export type TaskListItemAssigneeIndicatorAvatarProps = AvatarProps & { user: User };

export const TaskListItemAssigneeIndicatorAvatar = React.forwardRef(
  ({ user, ...props }: TaskListItemAssigneeIndicatorAvatarProps, ref) => {
    const { url, initials, title } = getAvatar(user);

    return (
      <Avatar
        ref={ref}
        aria-label={title}
        size="sm"
        name={title}
        getInitials={constant(initials)}
        src={url}
        borderWidth="thin"
        borderStyle="solid"
        borderColor="white"
        {...props}
      />
    );
  },
);
