import { match } from 'ts-pattern';
import { TaskWithTaskTemplate, FormFieldWidget, getFormFieldWidgetLabel } from '../process';

/* This is error codes related to Task changes */
export enum TaskErrorCodes {
  /* Error code when Checklist is inactive */
  ChecklistInactive = 'PS.Task.ChecklistInactive',
  /* Error code when Checklist Revision is inactive */

  /* Error code when updated status is not one of Completed / NonCompleted */
  UnsupportedStatus = 'PS.Task.UnsupportedStatus',
  /* Calling user is not allowed to update Task Status */
  UpdateForbidden = 'PS.Task.UpdateForbidden',
  /* When before Completing this task need to close all the previous tasks */
  NeedToCompletePrevious = 'PS.Task.NeedToCompletePrevious',
  /* When task has invalid form fields */
  InvalidFormFields = 'PS.Task.InvalidFormFields',
  /* When updating Task requires prior StopTask completion */
  StopTaskRequired = 'PS.Task.StopTaskRequired',
  /* When provided due date is invalid */
  InvalidDueDate = 'PS.Task.InvalidDueDate',
  /* User provided dueDate manually, and it can't be overriden */
  NothingToUpdate = 'PS.Task.NothingToUpdate',
}

/* This are error codes related to Checklist changes */
export enum ChecklistErrorCodes {
  /* Status used for update is not supported by Checklist */
  UnsupportedStatus = 'PS.Checklist.UnsupportedStatus',
  /* Limit on Checklist creation reached */
  ChecklistLimitReached = 'PS.Checklist.ChecklistLimitReached',
  /* When provided due date is invalid */
  InvalidDueDate = 'PS.Checklist.InvalidDueDate',
  /* When Checklist has invalid form fields */
  InvalidFormFields = 'PS.Checklist.InvalidFormFields',
  /* When Checklist has invalid form fields that the user can't see due to permissions */
  InvalidFormFieldsNotPermitted = 'PS.Checklist.InvalidFormFieldsNotPermitted',
  /* When Checklist has Stop Tasks preventing an action */
  InvalidStopTasks = 'PS.Checklist.InvalidStopTasks',
  /* When updating Checklist requires prior StopTask completion */
  StopTaskRequired = 'PS.Checklist.StopTaskRequired',
  /* When checklist does not have next task */
  NoTaskToShow = 'PS.Checklist.NoTaskToShow',
  /* When removing checklist field failed */
  FailedToRemoveFormField = 'PS.Checklist.FailedToRemoveFormField',
  /* When could not create approval */
  CouldNotCreateApproval = 'PS.Checklist.CouldNotCreateApproval',
}

export enum FolderErrorCodes {
  /* Error code when deleting a Folder that has items inside (other folders or templates) */
  FolderContainsChildrenError = 'PS.Folder.FolderContainsChildrenError',
}

export enum GeneralErrorCodes {
  InternalError = 'PS.General.InternalError',
  ActiveChecklistRevisionMissing = 'PS.General.DataMissing',
  UserDoesNotHaveOrganizationId = 'PS.General.UserDoesNotHaveOrganizationId',
  AssignmentMissing = 'PS.AssignmentMissing',
  BrokenOptimisticUpdate = 'PS.BrokenOptimisticUpdate',
  NoActionSpecified = 'PS.NoActionSpecified',
}

export enum FormFieldErrorCodes {
  InvalidFormField = 'PS.FormField.InvalidFormField',
  // https://github.com/process-street/process-street/blob/master/app/services/widgets/FormFieldValueErrorCodes.scala#L8
  InvalidFormFieldValues = 'PS.FormFieldValues.Invalid',
}

/* Union of all error codes, that can */
export type ProcessStreetErrorCodes = TaskErrorCodes | ChecklistErrorCodes | GeneralErrorCodes | FormFieldErrorCodes;

/* Universal error returned either from Server or from OptimisticService */
export class ProcessingError extends Error {
  public readonly code: ProcessStreetErrorCodes;

  constructor(code: ProcessStreetErrorCodes, message?: string) {
    super(message || `Processing Error ${code}`);
    this.code = code;
  }
}

/* Error returned on Task processing */
export class TaskError extends ProcessingError {
  constructor(code: TaskErrorCodes, message?: string) {
    super(code, message);
  }
}

/* Error returned on Checklist processing */
export class ChecklistError extends ProcessingError {
  constructor(code: ChecklistErrorCodes, message?: string) {
    super(code, message);
  }
}

const createTaskError = (code: TaskErrorCodes, message?: string): TaskError => new TaskError(code, message);

const createChecklistError = (code: ChecklistErrorCodes, message?: string): ChecklistError =>
  new ChecklistError(code, message);

const invalid = (widget: FormFieldWidget): ProcessingError => {
  const label = getFormFieldWidgetLabel(widget);
  const message = `Oops! ${label} ${widget.fieldType.toLowerCase()} format is invalid.`;
  return new ProcessingError(FormFieldErrorCodes.InvalidFormField, message);
};

const formField = {
  invalid,
};

const internalError = () => new ProcessingError(GeneralErrorCodes.InternalError);

const activeChecklistRevisionMissing = () => new ProcessingError(GeneralErrorCodes.ActiveChecklistRevisionMissing);

const assignmentMissing = () => new ProcessingError(GeneralErrorCodes.AssignmentMissing);

const application = {
  internalError,
  activeChecklistRevisionMissing,
  assignmentMissing,
};

const taskNeedsToBeCompleted = (pendingTasks: TaskWithTaskTemplate[]) => {
  if (pendingTasks.length === 1) {
    return task.taskNeedsToBeCompleted(pendingTasks[0]);
  } else {
    return createChecklistError(
      ChecklistErrorCodes.InvalidStopTasks,
      `Oops! ${pendingTasks.length} tasks still need to be completed.`,
    );
  }
};

const fieldsRequiredOrConstraintsFailed = ({
  requiredCount = 0,
  failedCount = 0,
}: {
  requiredCount?: number;
  failedCount?: number;
}) => {
  const message = makeRequiredOrFailedMessage({ requiredCount, failedCount });
  return createChecklistError(ChecklistErrorCodes.InvalidFormFields, message);
};
const fieldsRequired = (requiredCount: number) => fieldsRequiredOrConstraintsFailed({ requiredCount });

const dueDateInPast = () =>
  createChecklistError(ChecklistErrorCodes.InvalidDueDate, `Oops! Checklist due date should be in future.`);

const couldNotCreateApproval = () => createChecklistError(ChecklistErrorCodes.CouldNotCreateApproval);

const checklist = {
  taskNeedsToBeCompleted,
  fieldsRequired,
  fieldsRequiredOrConstraintsFailed,
  dueDateInPast,
  couldNotCreateApproval,
  invalidNotPermitted: () => {
    const message = `Oops, some fields still need to be completed by someone else before you can continue with this workflow run.`;
    return createChecklistError(ChecklistErrorCodes.InvalidFormFieldsNotPermitted, message);
  },
};

const makeRequiredOrFailedMessage = ({
  requiredCount = 0,
  failedCount = 0,
}: {
  requiredCount?: number;
  failedCount?: number;
}) => {
  const errors = [
    match(requiredCount)
      .with(0, () => undefined)
      .with(1, () => '1 form field still needs to be completed')
      .otherwise(() => `${requiredCount} form fields still need to be completed`),
    match(failedCount)
      .with(0, () => undefined)
      .with(1, () => '1 form field is invalid')
      .otherwise(() => `${failedCount} form fields are invalid`),
  ]
    .filter(Boolean)
    .join(' and ');
  return `Oops! ${errors}.`;
};

const task = {
  taskNeedsToBeCompleted: (pendingTask?: TaskWithTaskTemplate) => {
    const taskName = pendingTask?.taskTemplate.name ? `"${pendingTask.taskTemplate.name}"` : 'task';
    return createChecklistError(ChecklistErrorCodes.InvalidStopTasks, `Oops! ${taskName} still needs to be completed.`);
  },
  fieldsRequired: (requiredCount: number) => task.fieldsRequiredOrConstraintsFailed({ requiredCount }),
  fieldsRequiredOrConstraintsFailed: ({
    requiredCount,
    failedCount = 0,
  }: {
    requiredCount: number;
    failedCount?: number;
  }) => {
    const message = makeRequiredOrFailedMessage({ requiredCount, failedCount });
    return createTaskError(TaskErrorCodes.InvalidFormFields, message);
  },
  dueDateInPast: () => createTaskError(TaskErrorCodes.InvalidDueDate, `Oops! Task due date should be in future.`),
};

export const ProcessingErrorFactory = {
  application,
  formField,
  task,
  checklist,
};

const ClientErrorCodes = new Set<ProcessStreetErrorCodes>([
  TaskErrorCodes.InvalidDueDate,
  TaskErrorCodes.InvalidFormFields,
  ChecklistErrorCodes.InvalidFormFields,
  ChecklistErrorCodes.InvalidFormFieldsNotPermitted,
  ChecklistErrorCodes.InvalidDueDate,
  ChecklistErrorCodes.InvalidStopTasks,
  FormFieldErrorCodes.InvalidFormField,
  FormFieldErrorCodes.InvalidFormFieldValues,
]);

const isProcessingError = (error: unknown): error is ProcessingError =>
  error instanceof Error && Boolean((error as ProcessingError).code);

const isClientError = (error?: Error): error is ProcessingError =>
  !!error && isProcessingError(error) && ClientErrorCodes.has(error.code);

const isApplicationError = (error?: Error): boolean => !!error && !isClientError(error);

function isChecklistError(error: unknown): error is ChecklistError {
  return !!error && Object.values(ChecklistErrorCodes).includes((error as any).code);
}

export function toError(error: unknown): Error {
  return error instanceof Error ? error : new Error('Unknown error occurred');
}

function toProcessingError(error: unknown): ProcessingError {
  return isProcessingError(error) ? error : internalError();
}

export const ProcessingErrorUtils = {
  isProcessingError,
  isClientError,
  isApplicationError,
  isChecklistError,
  makeRequiredOrFailedMessage,
  toProcessingError,
};
