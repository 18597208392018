import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { GetWidgetByTaskTemplateIdQuery } from 'app/features/widgets/query-builder';
import { AiGeneratorAnimationService } from 'app/services/ai-generator-animation-service';
import { makeErrorLoggerAction } from 'app/utils/machines';
import {
  makeQueryMachine,
  QueryActor,
  QueryActorSelectors,
  RefetchOnMountOnly,
  SystemUpdateEvent,
} from 'app/utils/query-builder';
import { ActorRefFrom, assign, createMachine, sendParent, spawn } from 'xstate';
import { SharedContext } from '../../../shared';

type Context = {
  sharedContext: SharedContext;
  taskTemplate: TaskTemplate;
  // queries
  widgetsQuery?: QueryActor<GetWidgetByTaskTemplateIdQuery.Response>;
};

type Event =
  | SystemUpdateEvent<{ id: 'widgets-query'; data: GetWidgetByTaskTemplateIdQuery.Response }>
  | { type: 'ANIMATE' }
  | { type: 'INTERNAL_UPDATE_WIDGET'; widget: Widget }
  | { type: 'WIDGETS_ANIMATION_FINISH'; taskTemplate: TaskTemplate };

export type WidgetGenerationMachine = ReturnType<typeof makeWidgetGenerationMachine>;
export type WidgetGenerationMachineActor = ActorRefFrom<WidgetGenerationMachine>;

export const makeWidgetGenerationMachine = ({
  sharedContext,
  taskTemplate,
}: {
  sharedContext: SharedContext;
  taskTemplate: TaskTemplate;
}) => {
  const { queryClient } = sharedContext;

  const id = `widget-generation:${taskTemplate.group.id}`;
  return createMachine(
    {
      id,
      initial: 'idle',
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      tsTypes: {} as import('./widget-generation-machine.typegen').Typegen0,
      context: () =>
        ({
          sharedContext,
          taskTemplate,
          widgetsQuery: undefined,
        } as Context),
      states: {
        idle: {
          on: {
            ANIMATE: { target: 'loading' },
          },
        },
        loading: {
          entry: ['assignWidgetsQuery'],
          on: {
            'xstate.update': [{ cond: 'widgetsReady', target: 'animating' }],
          },
        },
        animating: {
          invoke: [
            {
              id: 'animateWidgets',
              src: 'animateWidgetsService',
              onDone: { target: 'done' },
              onError: { target: 'done', actions: 'logError' },
            },
          ],
          on: {
            INTERNAL_UPDATE_WIDGET: { actions: 'sendParentUpdateWidget' },
          },
        },
        done: {
          type: 'final',
          entry: 'sendParentAnimationFinish',
        },
      },
    },
    {
      actions: {
        logError: makeErrorLoggerAction(id),
        sendParentUpdateWidget: sendParent((ctx, evt) => ({
          type: 'UPDATE_WIDGET',
          taskTemplateGroupId: ctx.taskTemplate.group.id,
          widget: evt.widget,
        })),
        sendParentAnimationFinish: sendParent(ctx => ({
          type: 'WIDGETS_ANIMATION_FINISH',
          taskTemplate: ctx.taskTemplate,
        })),
        assignWidgetsQuery: assign({
          widgetsQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: GetWidgetByTaskTemplateIdQuery.makeQueryObserver({
                  queryClient,
                  options: { ...RefetchOnMountOnly, keepPreviousData: true },
                  taskTemplateId: ctx.taskTemplate.id,
                }),
              }),
              { sync: true, name: `widgets-query` },
            );
          },
        }),
      },
      services: {
        animateWidgetsService: ctx => async send => {
          const widgets = QueryActorSelectors.getQueryData(ctx.widgetsQuery) ?? [];

          if (!widgets.length) return;

          const animator = AiGeneratorAnimationService.createWidgetsAnimator(
            { [ctx.taskTemplate.group.id]: widgets },
            {
              timeout: ((callback: (args: void) => void, ms: number) => {
                return setTimeout(callback, ms);
              }) as typeof setTimeout,
              addMissingItems: true,
              update: (widget, _) => {
                send({ type: 'INTERNAL_UPDATE_WIDGET', widget });
              },
            },
          );

          await animator();
        },
      },
      guards: {
        widgetsReady: (_ctx, evt) => QueryActorSelectors.isUpdateEventSuccess(evt, 'widgets-query'),
      },
    },
  );
};
