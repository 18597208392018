import * as yup from 'yup';

export const passwordValidationMsg =
  'Password must be at least 8 characters long, contain one number, one uppercase character, one lowercase character and one special character.';

export const registrationSchema = yup.object().shape({
  fullName: yup.string().trim().required('Required field'),
  password: yup
    .string()
    .trim()
    .required('Required field')
    .min(8, passwordValidationMsg)
    .test('Contains 1 number', passwordValidationMsg, value => /[0-9]/.test(value!))
    .test('Contains 1 lowercase character', passwordValidationMsg, value => /[a-z]/.test(value!))
    .test('Contains 1 uppercase character', passwordValidationMsg, value => /[A-Z]/.test(value!))
    .test('Contains 1 special character', passwordValidationMsg, value => /[^A-Za-z0-9]/.test(value!)),
});
