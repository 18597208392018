import angular from 'angular';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.services')
  .service('DoodadsService', function ($q, Activities, Attachments, ChecklistActivityService, Comments) {
    const self = this;

    const logger = trace({ name: 'DoodadsService' });

    self.retrieveComments = function (revision) {
      return Comments.getByChecklistRevision({
        checklistRevisionId: revision.id,
        where: JSON.stringify({
          'task.taskTemplate.templateRevision.templateId': {
            _eq: revision.checklist.template.id,
          },
          'task.checklistRevisionId': { _eq: revision.id },
          'status': { _eq: 'Active' },
        }),
        include: 'createdBy,task.taskTemplate.templateRevision',
      }).$promise.then(
        templateComments => {
          templateComments.forEach(comment => {
            comment._type = 'comment';
          });

          return templateComments;
        },
        response => {
          logger.error('failed to get comments. Reason: %s', JSON.stringify(response));
          return $q.reject(response);
        },
      );
    };

    self.retrieveAttachments = function (revision) {
      return Attachments.query({
        where: JSON.stringify({
          'task.taskTemplate.templateRevision.templateId': {
            _eq: revision.checklist.template.id,
          },
          'task.checklistRevisionId': { _eq: revision.id },
          'status': { _eq: 'Active' },
        }),
        include: 'createdBy,task.taskTemplate.templateRevision,file',
      }).$promise.then(
        templateAttachments => {
          templateAttachments.forEach(attachment => {
            attachment._type = 'attachment';
          });

          return templateAttachments;
        },
        response => {
          logger.error('failed to get attachments. Reason: %s', JSON.stringify(response));
          return $q.reject(response);
        },
      );
    };

    self.retrieveTaskActivities = function (revision) {
      return Activities.getAll({
        type: 'checklist',
        limit: 100,
        checklistId: revision.checklist.id,
        objectType: 'Task',
      }).$promise.then(result =>
        result.data
          .map(activity => {
            const task = {
              taskTemplate: {
                group: { id: activity.data.taskTemplateGroupId ?? activity.object.taskTemplate.group.id },
              },
            };
            const sentence = ChecklistActivityService.interpretSimple(activity);

            return {
              _type: 'activity',
              id: activity.id,
              audit: {
                createdBy: activity.subject,
                createdDate: activity.date,
              },
              task,
              sentence,
            };
          })
          .filter(a => !!a.sentence),
      );
    };

    self.retrieveFormFieldValueActivities = function (revision) {
      return Activities.getAll({
        type: 'checklist',
        limit: 1000,
        checklistId: revision.checklist.id,
        objectType: 'FormFieldValue',
      }).$promise.then(result =>
        result.data
          // We only want to use MultiSelect widgets with itemsUpdated
          .filter(activity => activity.data.itemsUpdated?.length)
          .map(activity => {
            const task = { taskTemplate: activity.data.taskTemplate };
            const sentence = ChecklistActivityService.interpretSimple(activity);

            // Remove the last object, as it's the one saying "in Task X"
            sentence.objects.splice(sentence.objects.length - 1, 1);

            return {
              _type: 'activity',
              id: activity.id,
              audit: {
                createdBy: activity.subject,
                createdDate: activity.date,
              },
              task,
              sentence,
            };
          }),
      );
    };
  });
