import { checklistAssignmentReducer } from 'reducers/checklist-assignment/checklist-assignment.reducer';
import { DATA_SERVICE_CLEAR } from 'reducers/data-service/data-service.actions';
import { organizationMembershipReducer } from 'reducers/organization-membership/organization-membership.reducer';
import { SESSION_SET_SELECTED_ORGANIZATION_ID } from 'reducers/session/session.actions';
import { SessionSelector } from 'reducers/session/session.selectors';
import { templateRevisionReducer } from 'reducers/template-revision/template-revision.reducer';
import { combineReducers } from 'redux';
import { analyticsReducer } from 'components/analytics/analytics.state';
import { checklistReducer } from 'reducers/checklist/checklist.reducer';
import { inboxReducer } from 'reducers/inbox/inbox.reducer';
import { scopeEventReducer } from 'reducers/scope-event/scope-event.reducer';
import { organizationReducer } from 'reducers/organization/organization.reducer';
import { lookupsReducer } from './lookups/lookups.reducer';
import { dataServiceMetaReducer } from './data-service/data-service.reducer';
import { entitiesReducer } from './entities/entities.reducer';
import { flashReducer } from './flash/flash.reducer';
import { sessionReducer } from './session/session.reducer';
import { statusesReducer } from './statuses/statuses.reducer';
import { statsReducer } from './stats/stats.reducer';
import { taskReducer } from './task/task.reducer';
import { modulesReducer } from './modules/modules.reducer';
import { RESET_STATE } from 'features/redux/store';

const reducer = combineReducers({
  analytics: analyticsReducer,
  checklist: checklistReducer,
  checklistAssignment: checklistAssignmentReducer,
  dataService: dataServiceMetaReducer,
  entities: entitiesReducer,
  flash: flashReducer,
  inbox: inboxReducer,
  lookups: lookupsReducer,
  modules: modulesReducer,
  task: taskReducer,
  templateRevision: templateRevisionReducer,
  organization: organizationReducer,
  organizationMembership: organizationMembershipReducer,
  scopeEvent: scopeEventReducer,
  session: sessionReducer,
  stats: statsReducer,
  statuses: statusesReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === SESSION_SET_SELECTED_ORGANIZATION_ID || action.type === DATA_SERVICE_CLEAR) {
    if (SessionSelector.getSelectedOrganizationId(state) !== action.payload || action.type === DATA_SERVICE_CLEAR) {
      const cleanState = {
        // TODO remove when no longer needed - this is here for compatibility with existing references
        entities: {
          organizations: state.entities.organizations,
          plans: state.entities.plans,
        },
        modules: {
          featureFlag: state.modules.featureFlag,
        },
        session: state.session,
        statuses: {
          featureFlags: state.statuses.featureFlags,
        },
      };
      return reducer(cleanState, action);
    } else {
      return state;
    }
  } else if (action.type === RESET_STATE) {
    return reducer({ session: {} }, action);
  } else {
    return reducer(state, action);
  }
};
