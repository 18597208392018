import { Muid } from '@process-street/subgrade/core';
import { TemplateSchema } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetTemplateSchemaQueryParams = {
  templateId?: Muid;
};

export type GetTemplateSchemaQueryResponse = TemplateSchema;

const key = ['templates', 'schema'];
export const GetTemplateSchemaQuery = {
  key,
  getKey: (params: GetTemplateSchemaQueryParams): QueryKey => [...key, params],
  queryFn: ({ templateId }: GetTemplateSchemaQueryParams) =>
    axiosService
      .getAxios()
      .get<GetTemplateSchemaQueryResponse>(`/1/templates/${templateId}/schema`)
      .then(res => res.data),
};

export const useGetTemplateSchemaQuery = <Select = GetTemplateSchemaQueryResponse>(
  params: GetTemplateSchemaQueryParams,
  options: UseQueryOptions<GetTemplateSchemaQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetTemplateSchemaQuery.getKey(params), () => GetTemplateSchemaQuery.queryFn(params), options);
};
