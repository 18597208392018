import * as React from 'react';
import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from 'components/design/next';

export type DeleteConfirmationModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
};

export const DeleteConfirmationModal: React.FC<React.PropsWithChildren<DeleteConfirmationModalProps>> = ({
  onConfirm,
  onCancel,
  isOpen,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">Remove this file?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>If you remove this file, it will be deleted and irrecoverable.</Text>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" colorScheme="gray" mr={3} onClick={onCancel}>
            Keep file
          </Button>

          <Button variant="solid" colorScheme="red" onClick={onConfirm}>
            Remove file
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
