import angular from 'angular';

angular.module('frontStreetApp.services').factory('ChecklistSubscriptions', function (ResourceFactory) {
  return ResourceFactory.create({
    query: 'GET[] /1/checklist-subscriptions',
    counts: 'GET /1/checklist-subscriptions/counts',
    create: 'POST /1/checklist-subscriptions',
    get: 'GET /1/checklist-subscriptions/:id',
    update: 'PATCH /1/checklist-subscriptions/:id',
    delete: 'DELETE /1/checklist-subscriptions/:id',
  });
});
