import { Box, Button, HStack, Icon, Text, useToast } from 'components/design/next';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { GetOrganizationTheme } from 'features/brand-customization/query-builder/get-organization-theme';
import { UploadArea, UploadProgress } from 'features/upload/components';
import { getHighlightColor } from 'features/upload/components/utils';
import { last } from 'lodash';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { SIZE_TOO_LARGE, useUploadEmailHeaderLogo } from './use-upload-email-header-logo';

export const EmailBrandHeaderLogoUploader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const queryClient = useQueryClient();
  const toast = useToast();

  const { dropzoneState, progress, uploadError } = useUploadEmailHeaderLogo({
    organizationId,
    onFinish: () => queryClient.invalidateQueries(GetOrganizationTheme.getKey({ organizationId })),
    accept: 'image/*',
  });

  React.useEffect(() => {
    if (uploadError) {
      toast({
        status: 'error',
        title: "We're having problems uploading the email header logo.",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    }
  }, [toast, uploadError]);

  const fileTooLarge =
    dropzoneState.fileRejections.length > 0 && last(dropzoneState.fileRejections[0].errors)?.code === SIZE_TOO_LARGE;

  return (
    <div {...dropzoneState.getRootProps({ className: 'dropzone' })}>
      <Box
        data-testid="header-logo-file-input"
        borderRadius="md"
        cursor="pointer"
        bgColor={getHighlightColor(dropzoneState)}
      >
        {progress === undefined ? (
          <UploadArea boxProps={{ p: '0' }} dropzoneInputProps={dropzoneState.getInputProps()}>
            <HStack spacing="2">
              <Button
                variant="outline"
                colorScheme="gray"
                borderWidth="1px"
                color="gray.600"
                fontWeight="normal"
                px="4"
                leftIcon={<Icon icon="camera" size="4" />}
                iconSpacing="2"
              >
                Upload
              </Button>
            </HStack>
          </UploadArea>
        ) : (
          <UploadProgress progress={progress} message="Uploading..." />
        )}
        {fileTooLarge && (
          <Text color="red.500" align="center" fontWeight="medium">
            {last(dropzoneState.fileRejections[0].errors)?.message}
          </Text>
        )}
      </Box>
    </div>
  );
};
