import { filesize } from 'filesize';
import * as React from 'react';
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useToast,
  VStack,
} from 'components/design/next';
import { AI_IMPORT_WORKFLOW_S3_ID } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/wrapper';
import { useUploadWorkflowDoc } from 'directives/ui/topbar/components/import-workflow/use-upload-workflow-doc';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { Progress } from 'components/design/next/';
import { useFeatureFlag } from 'features/feature-flags';
import { Stack } from '@chakra-ui/react';

export interface ImportWorkflowModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFinish: (id: string) => void;
}

export const ImportWorkflowModal: React.FC<React.PropsWithChildren<ImportWorkflowModalProps>> = ({
  isOpen,
  onClose,
  onFinish,
}) => {
  const [progress, setProgress] = React.useState<number | undefined>();
  const [file, setFile] = React.useState<File | undefined>();
  const [fileId, setFileId] = React.useState<string | undefined>();
  const toast = useToast();

  const onFileUploadFinish = (id: string) => {
    setFileId(id);
  };

  const onRemoveUploadedFile = () => {
    setFile(undefined);
    setFileId(undefined);
  };

  const onGenerate = () => {
    if (!fileId) {
      throw new Error('File ID should be present when generating imported workflow.');
    }
    // passing s3 id through local storage into new WF
    localStorage.setItem(AI_IMPORT_WORKFLOW_S3_ID, fileId);
    onClose();
    onFinish(fileId);
  };

  const isImageImportEnabled = useFeatureFlag('gpt4oDocumentImport');

  const { dropzoneState, uploadError, acceptedImportTypes } = useUploadWorkflowDoc({
    setProgress,
    setFile,
    onFileUploadFinish,
    isImageImportEnabled,
  });

  React.useEffect(() => {
    if (uploadError) {
      toast({
        status: 'error',
        title: "We're having problems uploading the file",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    }
  }, [toast, uploadError]);

  const shouldShowProgress = progress !== undefined && progress < 100;
  const errorMessage = dropzoneState.fileRejections[0]?.errors?.[0]?.message;

  const isSubmitEnabled = fileId && progress === 100;

  const fileSize = file ? filesize(file.size) : null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="18px" color="gray.700" py="6" px="8">
          Import document
        </ModalHeader>

        <ModalCloseButton right="7" top="6" />
        <ModalBody py="6">
          <Stack spacing="2" px={{ base: '9', xs: '4', md: '9' }}>
            <Box
              border="1px"
              borderStyle="dashed"
              borderColor={dropzoneState.isDragActive ? 'purple.500' : 'gray.300'}
              backgroundColor={dropzoneState.isDragActive ? 'purple.100' : 'inherit'}
              w="100%"
              borderRadius="4px"
              pt="8"
              pb="6"
              {...dropzoneState.getRootProps()}
            >
              <VStack>
                <Icon icon="cloud-arrow-up" variant="fal" color="purple.500" size="20" />
                <Text mt="4" fontWeight="500">
                  Drag & drop
                </Text>
                <Text>
                  or{' '}
                  <Link textDecoration="underline" color="blue.500">
                    Choose file
                  </Link>{' '}
                  to upload
                </Text>
              </VStack>
            </Box>
            {errorMessage && (
              <Text variant="-1" color="red.500">
                {errorMessage}
              </Text>
            )}
            <Text variant="-1" color="gray.400">
              {isImageImportEnabled
                ? 'Supported formats: PDF, CSV, DOCX, PNG, JPG, HTML & TXT.'
                : 'Supported formats: PDF, CSV, DOCX, HTML & TXT.'}
              <br />
              {isImageImportEnabled ? 'File size limit: 25MB, 20MB for images' : 'File size limit: 25MB'}
            </Text>
            <input aria-label="file-upload" {...dropzoneState.getInputProps()} accept={acceptedImportTypes.join(',')} />
            {file && (
              <Box
                w="100%"
                borderStyle="solid"
                borderColor="gray.100"
                borderWidth="1px"
                borderRadius="4px"
                px="3"
                py="2"
                style={{ marginTop: 'var(--ps-space-6)' }}
              >
                <HStack w="100%">
                  <Text variant="-1" fontWeight="500">
                    {file.name}
                  </Text>
                  <Spacer />
                  <IconButton
                    variant="ghost"
                    aria-label="close"
                    icon={<Icon icon="close" variant="far" size="4" color="gray.500" />}
                    onClick={onRemoveUploadedFile}
                    size="xs"
                  />
                </HStack>
                <Text variant="-2" color="gray.400">
                  {React.isValidElement(fileSize) ? fileSize : null}
                </Text>
                {shouldShowProgress && <Progress value={progress} borderRadius="base" mt="3" />}
              </Box>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter pt="0">
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" ml="4" onClick={onGenerate} isDisabled={!isSubmitEnabled}>
            Generate Workflow
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
