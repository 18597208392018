import { Muid, orderTreeService } from '@process-street/subgrade/core';
import {
  ChecklistGridSearchRequest,
  ChecklistTimeSeries,
  DerivedChecklistStatusStat,
  DerivedChecklistStatusSummary,
  DerivedTaskStatByTask,
  DerivedTaskStatByUser,
  DerivedTaskStatsSummary,
} from '@process-street/subgrade/dashboard';
import { AxiosError } from 'axios';
import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type AnalyticsMode = 'Limited' | 'Full';

export interface ChecklistAnalyticsQueryParams extends ChecklistGridSearchRequest {
  mode?: AnalyticsMode;
}

export type SingleWorkflowResponse = {
  taskStatusStatsByTaskGroupId: Record<Muid, DerivedTaskStatByTask>;
  taskStatusStatsByAssigneeUserId: Record<Muid, DerivedTaskStatByUser>;
  taskStatusSummary: DerivedTaskStatsSummary;
  checklistStatusSummary: DerivedChecklistStatusSummary;
  checklistStatusStatsByAssigneeUserId: Record<Muid, DerivedChecklistStatusStat>;
  checklistStatsTimeSeries: ChecklistTimeSeries[];
};

export type MultipleWorkflowsResponse = {
  checklistStatusStatsByTemplateId: Record<Muid, DerivedChecklistStatusStat>;
  checklistStatusStatsByAssigneeUserId: Record<Muid, DerivedChecklistStatusStat>;
};

export type ChecklistAnalyticsQueryResponse = SingleWorkflowResponse | MultipleWorkflowsResponse;

export function isMultipleWorkflowsResponse(
  response: ChecklistAnalyticsQueryResponse,
): response is MultipleWorkflowsResponse {
  return (response as MultipleWorkflowsResponse).checklistStatusStatsByTemplateId !== undefined;
}

export function isSingleWorkflowResponse(
  response: ChecklistAnalyticsQueryResponse,
): response is SingleWorkflowResponse {
  return (response as SingleWorkflowResponse).taskStatusSummary !== undefined;
}

const keyFactory = {
  key: [{ static: 'checklist-analytics' }],
  getKey: (params: ChecklistAnalyticsQueryParams) => [{ ...keyFactory.key[0], ...params }],
};
type QueryKey = ReturnType<typeof keyFactory['getKey']>;

export const ChecklistAnalyticsQuery = {
  ...keyFactory,
  queryFn: ({ queryKey: [{ static: _, ...body }] }: QueryFunctionContext<QueryKey>) =>
    axiosService
      .getAxios()
      .post<ChecklistAnalyticsQueryResponse>(`/1/checklist-analytics`, body)
      .then(res => res.data),
  sortByOrderTree<T extends DerivedTaskStatByTask>(a: T, b: T) {
    return orderTreeService.compare(a.orderTree, b.orderTree);
  },
};

export const useChecklistAnalyticsQuery = <Select = ChecklistAnalyticsQueryResponse>(
  params: ChecklistAnalyticsQueryParams,
  options: UseQueryOptions<ChecklistAnalyticsQueryResponse, AxiosError, Select, QueryKey> = {},
) => {
  return useQuery(ChecklistAnalyticsQuery.getKey(params), ChecklistAnalyticsQuery.queryFn, {
    staleTime: 1000 * 60,
    ...options,
  });
};
