import * as React from 'react';
import {
  AutomationAppName,
  AutomationInstance,
  isIncomingWebhookAppName,
  isIncomingWebhookInstance,
  isTemplateSolutionInstance,
  isTrayAutomationAppName,
  TrayAutomationAppName,
  withIncomingWebhookPrefix,
  withTrayPrefix,
} from '@process-street/subgrade/automation';
import { TraySolutionTriggerUtils, TraySolutionIdTrigger } from '@process-street/subgrade/process';
import { toEntityMap } from '@process-street/subgrade/redux';
import {
  useGetAllIncomingWebhooksByTemplateIdQuery,
  useSolutionInstancesByTemplateIdQuery,
  useSolutionsByTemplateIdQuery,
} from 'features/automations/query-builder';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useTemplateId } from '../../automation/utils/use-template-id';
import { match } from 'ts-pattern';

/** This compiles preferred triggers and automation instances for lookup utils to determine if app buttons should have a warning */
export function useUnconfiguredInstances() {
  const templateId = useTemplateId();
  const unconfiguredTemplateSolutionInstancesQuery = useSolutionInstancesByTemplateIdQuery(
    { templateId },
    { select: tsis => tsis.filter(tsi => !tsi.configured) },
  );
  const templateSolutionInstancesById = React.useMemo(() => {
    const tsis = unconfiguredTemplateSolutionInstancesQuery.data ?? [];
    return toEntityMap(tsis);
  }, [unconfiguredTemplateSolutionInstancesQuery.data]);

  const solutionsQuery = useSolutionsByTemplateIdQuery({ templateId }, { select: toEntityMap });
  const unconfiguredTraySolutionsByAppNameQuery = useGetTemplateQuery(
    { templateId },
    {
      enabled: Boolean(solutionsQuery.isSuccess),
      select: template =>
        template.preferredTriggers.filter(TraySolutionTriggerUtils.isTraySolutionIdTrigger).reduce((acc, trigger) => {
          const id = TraySolutionTriggerUtils.triggerToId(trigger);
          const solution = solutionsQuery.data?.[id];
          if (!solution) return acc;

          const safeName = withTrayPrefix(solution.automationApp);
          if (!acc[safeName]) {
            acc[safeName] = trigger;
          }
          return acc;
        }, {} as Partial<Record<TrayAutomationAppName, TraySolutionIdTrigger>>),
    },
  );
  const traySolutionsByAppName = React.useMemo(() => {
    return unconfiguredTraySolutionsByAppNameQuery.data ?? {};
  }, [unconfiguredTraySolutionsByAppNameQuery.data]);

  const unconfiguredWebhooksQuery = useGetAllIncomingWebhooksByTemplateIdQuery(
    { templateId },
    { select: webhooks => webhooks.filter(w => w.status !== 'Deleted' && !w.name) },
  );
  const incomingWebhooksById = React.useMemo(() => {
    const webhooks = unconfiguredWebhooksQuery.data ?? [];
    return toEntityMap(webhooks);
  }, [unconfiguredWebhooksQuery.data]);

  const getInstanceFromApp = React.useCallback(
    (automationAppname: AutomationAppName) => {
      return match(automationAppname)
        .when(isTrayAutomationAppName, name => {
          return (
            unconfiguredTemplateSolutionInstancesQuery.data?.find(tsi => withTrayPrefix(tsi.automationApp) === name) ||
            traySolutionsByAppName[name]
          );
        })
        .when(isIncomingWebhookAppName, name => {
          return unconfiguredWebhooksQuery.data?.find(
            webhook => withIncomingWebhookPrefix(webhook.automationApp) === name,
          );
        })
        .otherwise(() => undefined);
    },
    [traySolutionsByAppName, unconfiguredTemplateSolutionInstancesQuery.data, unconfiguredWebhooksQuery.data],
  );

  const instanceIsNotConfigured = React.useCallback(
    (automationInstance: AutomationInstance) => {
      return match(automationInstance)
        .when(isTemplateSolutionInstance, tsi => Boolean(templateSolutionInstancesById[tsi.id]))
        .when(isIncomingWebhookInstance, webhook => Boolean(incomingWebhooksById[webhook.id]))
        .otherwise(() => false);
    },
    [incomingWebhooksById, templateSolutionInstancesById],
  );

  return {
    templateSolutionInstancesById,
    incomingWebhooksById,
    traySolutionsByAppName,
    instanceIsNotConfigured,
    getInstanceFromApp,
  };
}
