import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { customSpace } from '../space';

function getSize(size: keyof typeof customSpace) {
  const themeSize = customSpace[size] ?? size;
  return {
    container: {
      width: size,
      height: size,
      fontSize: `calc(${themeSize} / 2.5)`,
    },
    excessLabel: {
      width: size,
      height: size,
      fontSize: `calc(${themeSize} / 2.5)`,
      fontWeight: 'normal',
    },
    label: {
      fontSize: `calc(${themeSize} / 2.5)`,
      lineHeight: themeSize,
    },
  };
}

const sizes = {
  '2xs': getSize('4'),
  'xs': getSize('6'),
  'sm': getSize('8'),
  'md': getSize('9'),
  'lg': getSize('16'),
  'xl': getSize('24'),
  '2xl': getSize('32'),
  'full': getSize('full'),
};

export const Avatar: Partial<ComponentMultiStyleConfig> = {
  sizes,
  parts: ['container'],
  baseStyle: {
    container: {
      bg: 'gray.400',
      color: 'white',
      borderStyle: 'solid',
      borderWidth: 'thin',
    },
    excessLabel: {
      bg: 'gray.400',
      color: 'white',
      borderStyle: 'solid',
      borderWidth: 'thin',
      borderColor: 'white',
    },
  },
};
