import { Checklist } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateChecklistStatusMutation {
  export type Params = { checklistId: Checklist['id']; status: Checklist['status'] };

  export type Response = {};

  export const key = ['post', 'checklist', 'complete'];

  export const mutationFn = (params: Params): Promise<Response> =>
    axiosService
      .getAxios()
      .put<Response>(`/1/checklists/${params.checklistId}/status/${params.status}`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
