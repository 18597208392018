import * as React from 'react';
import { Alert, AlertIcon, Show, VStack, Divider, Text, IconButton, Icon, Input, Box } from 'components/design/next';
import { AiTaskTemplateFormProvider, useAiTaskTemplateForm } from './ai-task-template-form-context';
import { Skeleton } from 'components/design/next';
import { match, P } from 'ts-pattern';
import { BasicAiTaskPromptForm, ParseAiTaskForm } from './components';
import { AiTask } from '@process-street/subgrade/process';
import { AiTaskPromptTypeSelect, AiTaskPromptTypeSelectOption } from '../ai-task-prompt-type-select';
import { SelectInstance } from 'react-select';
import { ChangePromptTypeAlertDialog } from './change-prompt-type-alert-dialog';
import { MathAiTaskForm } from './components/math-ai-task-form';
import { noop } from 'lodash';

export interface AiTaskTemplateFormProps {
  onShowTasks?: () => void;
  onEditTaskTemplateName?: (newName: string) => void;
  showHeader?: boolean;
}

export const AiTaskTemplateForm: React.FC<React.PropsWithChildren<AiTaskTemplateFormProps>> = ({
  onShowTasks = noop,
  onEditTaskTemplateName = noop,
  showHeader = true,
}) => {
  const context = useAiTaskTemplateForm({ onEditTaskTemplateName });
  const { aiTaskTemplate, promptType, handlePromptTypeChange, isDisabled, isPristine } = context;
  const { taskTemplate } = aiTaskTemplate ?? {};
  const [promptChangingWarning, setPromptChangingWarning] = React.useState<'idle' | 'shown' | 'accepted'>('idle');

  const selectRef = React.useRef<SelectInstance<AiTaskPromptTypeSelectOption>>(null);

  return (
    <VStack
      key={aiTaskTemplate?.taskTemplate.group.id}
      spacing="5"
      alignItems="flex-start"
      py="8"
      maxW="640px" // match angular :(
      mx="auto"
      px="2"
      position="relative"
    >
      {showHeader && (
        <>
          <Show below="lg">
            <IconButton
              variant="outline"
              colorScheme="gray"
              aria-label="show tasks"
              icon={<Icon icon="th-list" variant="far" size="4" color="gray.500" />}
              position="absolute"
              top="1"
              left="1"
              onClick={() => onShowTasks()}
            />
          </Show>

          <Text variant="2" fontWeight="medium" fontSize="18">
            {taskTemplate?.name}
          </Text>
          <Divider />
        </>
      )}

      <Box position="relative" w="full">
        {isDisabled ? null : (
          <AiTaskPromptTypeSelect
            selectRef={selectRef}
            value={promptType}
            onChange={type => {
              setPromptChangingWarning('idle');
              handlePromptTypeChange(type);
            }}
          />
        )}

        {match({ isPristine, promptChangingWarning })
          .with({ isPristine: false, promptChangingWarning: 'idle' }, () => (
            <Box
              position="absolute"
              top="0"
              left="0"
              w="full"
              h="full"
              role="button"
              aria-label="change prompt type"
              onClick={() => setPromptChangingWarning('shown')}
            />
          ))
          .otherwise(() => null)}

        <ChangePromptTypeAlertDialog
          isOpen={promptChangingWarning === 'shown'}
          onClose={() => setPromptChangingWarning('idle')}
          onContinue={() => {
            setPromptChangingWarning('accepted');
            selectRef.current?.openMenu?.('first');
          }}
        />
      </Box>

      {match(context)
        .with({ isLoading: false, aiTaskTemplate: P.not(undefined), templateRevision: P.not(undefined) }, value => {
          const promptConfig = AiTask.getPromptConfig(value.aiTaskTemplate!.nativeAutomation);
          return (
            <AiTaskTemplateFormProvider {...value}>
              {match(promptConfig)
                .with(
                  {
                    promptType: P.union(
                      'Custom',
                      'Blog',
                      'SocialMedia',
                      'Newsletter',
                      'Sentiment',
                      'Summarize',
                      'Theme',
                      'Translate',
                    ),
                  },
                  // use key to force rerender and autofocus
                  config => <BasicAiTaskPromptForm key={config.promptType} promptConfig={config} />,
                )
                .with({ promptType: 'Parse' }, () => <ParseAiTaskForm />)
                .with({ promptType: 'Math' }, () => <MathAiTaskForm />)
                .with({ promptType: P.not(undefined) }, ({ promptType }) => (
                  <Alert status="error">
                    <AlertIcon /> AI Task prompt type not supported: {promptType}
                  </Alert>
                ))
                .otherwise(() => null)}
            </AiTaskTemplateFormProvider>
          );
        })
        .otherwise(() => (
          <VStack w="full" alignItems="flex-start">
            <Skeleton w="full">
              <Input />
            </Skeleton>
            <Skeleton w="full">
              <Input h="34" />
            </Skeleton>
          </VStack>
        ))}
    </VStack>
  );
};
