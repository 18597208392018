import { match, P } from 'ts-pattern';
import * as React from 'react';
import { CoverImageByTemplateIdQuery } from 'features/cover-image/query-builder';
import { useBreakpointPx } from 'components/design/next';
import { useCDNImage } from 'components/cdn-image/use-cdn-image';
import { Muid } from '@process-street/subgrade/core';

type UseBackgroundCdnImageOptions = {
  templateId?: Muid;
};

export const useBackgroundCdnImage = ({ templateId }: UseBackgroundCdnImageOptions) => {
  const coverImageQuery = CoverImageByTemplateIdQuery.useQuery({ templateId });
  const breakpointPx = useBreakpointPx();

  const cdnImage = useCDNImage({
    s3File: coverImageQuery.data?.s3File,
    transformation: { width: breakpointPx },
  });
  const previousCdnImageRef = React.useRef(cdnImage);

  previousCdnImageRef.current = match({ previous: previousCdnImageRef.current, current: cdnImage })
    // deleted image
    .with({ previous: { src: P.not('') }, current: { src: '' } }, ({ current }) => current)
    //  use the image from the previous breakpoint until the current's breakpoint image is being loaded
    .with({ previous: { anySrcLoaded: true }, current: { status: P.not('loaded') } }, ({ previous }) => previous)
    // Always use the current's breakpoint image when the previous hasn't loaded
    .with({ previous: { anySrcLoaded: false } }, ({ current }) => current)
    // Always use the current's breakpoint image it's already loaded
    .with({ current: { status: 'loaded' } }, ({ current }) => current)
    .otherwise(({ previous }) => previous);

  return previousCdnImageRef.current;
};
