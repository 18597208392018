import { Box, HStack, Text } from 'components/design/next';
import { Node, NodeType, SelectorHelper } from 'directives/rules/template/task-templates-selector/selector-helper';
import * as React from 'react';
import { Widget } from '@process-street/subgrade/process';

type MultiValueLabelProps = {
  data: Node & { value: string; level: number };
};

export const MultiValueLabel: React.FC<React.PropsWithChildren<MultiValueLabelProps>> = props => {
  const iconName = props.data.type === NodeType.Widget && SelectorHelper.getIconForWidget(props.data.ref as Widget);

  return (
    <HStack spacing="2">
      {iconName ? (
        <Box
          as="i"
          display={{ base: 'none', sm: 'inline-block' }}
          className={`fa ${iconName} far fw`}
          color="gray.500"
        />
      ) : null}
      <Text
        as="span"
        display="inline-flex"
        alignItems="center"
        maxWidth="150px"
        fontWeight="semibold"
        fontSize="sm"
        noOfLines={1}
      >
        {props.children}
      </Text>
    </HStack>
  );
};
