import { CoverIcon } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type FinishUploadCoverIconMutationParams = {
  templateId: Template['id'];
  key: string;
  originalFilename: string;
  contentType: string;
};

export type FinishUploadCoverIconMutationResponse = CoverIcon;

export const FinishUploadCoverIconMutation = {
  key: ['cover-icon', 'finish-upload'],
  mutationFn: (params: FinishUploadCoverIconMutationParams) =>
    axiosService
      .getAxios()
      .post<FinishUploadCoverIconMutationResponse>(`/1/templates/${params.templateId}/cover-icon/finish-upload`, params)
      .then(res => res.data),
};

export const useFinishUploadCoverIconMutation = (
  options: UseMutationOptions<
    FinishUploadCoverIconMutationResponse,
    AxiosError,
    FinishUploadCoverIconMutationParams
  > = {},
) => {
  return useMutation(FinishUploadCoverIconMutation.mutationFn, {
    mutationKey: FinishUploadCoverIconMutation.key,
    ...options,
  });
};
