import { User, UserType } from '@process-street/subgrade/core';
import { Box, BoxProps, HStack, Text } from 'components/design/next';
import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { useDebounce } from 'react-use';
import { Key } from 'services/key';
import './CommentEditor.scss';
import { ChakraAvatar } from 'components/design/next/chakra-avatar';
import Fuse from 'fuse.js';

export type CommentEditorProps = {
  mentionableUsers?: User[];
  user: User;
  activeStepId?: string;
  content: string;
  onUpdate: (text: string) => void;
  maxSuggestions?: number;
} & BoxProps;

export const DEBOUNCE_DELAY = 500;

const REPLACER = '{{#%#}}';

export const handleKeyUp: KeyboardEventHandler<HTMLDivElement> = event => {
  if ([Key.LEFT_ARROW, Key.RIGHT_ARROW].includes(event.keyCode)) {
    event.stopPropagation();
  }
};

interface Emoji {
  shortname: string;
  emoji: string;
}

const FUZZY_THRESHOLD = 0.4;
const MAX_USER_SUGGESTIONS = 8;
const MAX_EMOJI_SUGGESTIONS = 10;

export const CommentEditor: React.FC<React.PropsWithChildren<CommentEditorProps>> = ({
  onUpdate,
  mentionableUsers = [],
  content,
  activeStepId,
  maxSuggestions,
  ...props
}) => {
  const [emojis, setEmojis] = useState<Array<Emoji>>([]);

  useEffect(() => {
    // no good way to test this in the UI so avoiding the async setState so the tests don't have a leak
    if (process.env.NODE_ENV !== 'test') {
      import(/* webpackPrefetch: true */ './emojis.json').then(data => setEmojis(Array.from(data)));
    }
  }, []);

  const [text, setText] = React.useState(content);

  React.useEffect(() => {
    setText(content);
  }, [activeStepId, content]);

  const fuse = React.useMemo(
    () =>
      new Fuse(mentionableUsers, {
        keys: ['username', 'email'],
        threshold: FUZZY_THRESHOLD,
      }),
    [mentionableUsers],
  );

  const getSuggestions = React.useCallback(
    (search: string) =>
      fuse
        .search(search)
        .map(result => result.item)
        .map(user => ({
          display: `@${user.username}`,
          id: user.id,
        }))
        .slice(0, maxSuggestions ?? MAX_USER_SUGGESTIONS),
    [fuse, maxSuggestions],
  );

  useDebounce(
    () => {
      onUpdate(text);
    },
    DEBOUNCE_DELAY,
    [text],
  );

  const handleChangeText = React.useCallback((event: { target: { value: string } }) => {
    const value = event.target.value.replace(REPLACER, '');
    setText(value);
  }, []);

  const renderSuggestion = React.useCallback(
    (suggestion: SuggestionDataItem) => {
      const user = mentionableUsers.find(u => u.id === suggestion.id);
      if (!user) return null;
      // Currently, only standard users are mentionable, but let's make sure
      // not to expose internal email addresses
      const email = user.userType === UserType.Standard ? user.email : null;
      return (
        <HStack spacing={0}>
          <ChakraAvatar size="sm" user={user} />
          <Text px={2}>
            {user.username}
            {email && ` (${email})`}
          </Text>
        </HStack>
      );
    },
    [mentionableUsers],
  );

  const renderEmojiSuggestion = React.useCallback((suggestion: SuggestionDataItem) => {
    return (
      <>
        <span>{suggestion.id} </span>
        <span>{suggestion.display}</span>
      </>
    );
  }, []);

  const queryEmojis = (query: string): SuggestionDataItem[] | undefined => {
    if (query.length === 0) return;

    return emojis
      .filter(emoji => emoji.shortname.indexOf(query.toLowerCase()) > -1)
      .slice(0, maxSuggestions ?? MAX_EMOJI_SUGGESTIONS)
      .map(({ emoji, shortname }) => ({ id: emoji, display: shortname }));
  };

  return (
    <Box onKeyUp={handleKeyUp} tabIndex={1} outline="none" mb="4" {...props}>
      <MentionsInput
        className="mentions"
        value={text}
        onChange={handleChangeText}
        placeholder="Write a comment&hellip; Type @ to mention other users."
        onClick={e => e.stopPropagation()}
        style={{ minHeight: '70px' }}
      >
        {/* User mentions */}
        <Mention
          appendSpaceOnAdd={true}
          trigger="@"
          markup={`__display__${REPLACER}`}
          data={getSuggestions}
          renderSuggestion={renderSuggestion}
        />
        {/* Emoji mentions */}
        <Mention
          trigger=":"
          markup="__id__"
          regex={/($a)/}
          data={queryEmojis}
          renderSuggestion={renderEmojiSuggestion}
        />
      </MentionsInput>
    </Box>
  );
};
