import * as React from 'react';
import { TemplateInfo } from '../template-info';
import { AutomationDetailsContainer } from '../details-container';
import { AutomationsSelector } from '../selector';
import {
  Grid,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useBreakpointValue,
} from 'components/design/next';
import { useAutomationSelector } from '../selector/context';
import { useRootScopeStateMachineEvents } from '../../use-root-scope-state-machine-events';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { useFeatureFlag } from 'app/features/feature-flags';

export type AutomationsModalProps = Partial<ModalProps>;

export const AutomationsModal: React.VFC<AutomationsModalProps> = ({ ...modalProps }) => {
  useRootScopeStateMachineEvents();

  const [state, send] = useAutomationSelector();

  const size = useBreakpointValue({ base: 'lg', xl: '6xl' });

  const handleClose = () => {
    send('LEAVE');
  };

  const isReactWFEditorEnabled = useFeatureFlag('reactWorkflowEditor');

  const modalContent = (
    <ModalContent>
      <ModalHeader
        as={HStack}
        justifyContent="space-between"
        pt="8"
        px="8"
        borderBottomColor="gray.100"
        borderBottomStyle="solid"
      >
        <TemplateInfo variant="2" fontWeight="bold" color="gray.700" />
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody p="0" minH="80vh">
        <Grid templateColumns={{ base: '1fr', xl: 'repeat(4, 1fr)' }} templateRows="1fr" h="80vh">
          <GridItem
            colSpan={{ base: 4, xl: 1 }}
            width={{ base: '100%', xl: '100' }}
            h={{ base: '50vh', xl: 'full' }}
            px="6"
            bg="gray.50"
            as="nav"
            aria-label="Automations"
            overflowY="hidden"
            borderBottomLeftRadius="2xl"
          >
            <AutomationsSelector />
          </GridItem>
          <GridItem
            colSpan={3}
            h={{ base: '50vh', xl: 'full' }}
            px="5"
            overflow="scroll"
            bgColor="white"
            position="relative"
            borderBottomRightRadius="2xl"
          >
            <AutomationDetailsContainer />
          </GridItem>
        </Grid>
      </ModalBody>
    </ModalContent>
  );

  return (
    <Modal
      size={size}
      isOpen={!state.matches('hidden')}
      onClose={handleClose}
      {...modalProps}
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      {isReactWFEditorEnabled ? <ThemeProvider2024>{modalContent}</ThemeProvider2024> : <>{modalContent}</>}
    </Modal>
  );
};
