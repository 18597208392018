import { useSelector } from 'react-redux';
import { BaseSessionSelector } from '@process-street/subgrade/redux/selector';
import { PlanFeatureSet } from '@process-street/subgrade/billing';

const DefaultLimit = 5;

export const useFeatureSet = () => {
  const plan = useSelector(BaseSessionSelector.getCurrentPlan);
  const featureSet = plan?.featureSet as PlanFeatureSet;

  return {
    featureSet,
    usersLimit: featureSet?.usersLimit ?? DefaultLimit,
    activeChecklistsLimit: featureSet?.activeChecklistsLimit ?? DefaultLimit,
    activeTemplatesLimit: featureSet?.activeTemplatesLimit ?? DefaultLimit,
  };
};
