import { useInjector } from 'components/injection-provider';
import React from 'react';
import { useQueryClient } from 'react-query';
import { FormResponseMachineSharedContext } from '../types';

export function useFormResponseSharedContext(): FormResponseMachineSharedContext {
  const queryClient = useQueryClient();
  const { $state } = useInjector('$state');

  return React.useMemo(() => ({ queryClient, $state }), [$state, queryClient]);
}
