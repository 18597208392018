import * as React from 'react';
import { GridItem, HStack, Skeleton, VStack } from 'components/design/next';

const skeletonProps = { startColor: 'gray.200', endColor: 'gray.50' };

const MenuItemSkeleton = () => (
  <HStack w="full" spacing="2">
    <Skeleton {...skeletonProps} w="4" h="4" />
    <Skeleton {...skeletonProps} w="full" h="10" />
  </HStack>
);

export const EmptySideMenu = () => (
  <GridItem w="full" display={{ base: 'none', lg: 'flex' }} bgColor="brand.50" as="aside">
    <VStack w="full">
      <HStack spacing="4" py="3" px="6" w="full" bgColor="brand.100">
        <Skeleton startColor="gray.200" endColor="gray.50" h="10" w="full" />
        <Skeleton startColor="gray.200" endColor="gray.50" h="10" w="10" />
      </HStack>

      <VStack w="full" alignItems="stretch" spacing="4" pt="6" pl="10" pr="6">
        <MenuItemSkeleton />
        <MenuItemSkeleton />
        <MenuItemSkeleton />
        <MenuItemSkeleton />
        <MenuItemSkeleton />
      </VStack>
    </VStack>
  </GridItem>
);
