import * as React from 'react';
import { Icon, IconButton, Link, Divider, HStack, Center, VStack } from 'components/design/next';
import { ChecklistInboxItem, TaskInboxItem } from '@process-street/subgrade/inbox';
import { InboxCoverIcon } from 'features/cover-icon/components/inbox';
import { isModelRef, isNotIdRef, toModelRef } from '@process-street/subgrade/core';
import { MergeTagHighlighter } from 'features/merge-tags/components/merge-tag-highlighter';
import { useGetChecklistQuery } from 'app/features/checklists/query-builder';
import { useListenToWorkflowRunUpdated } from 'app/pages/inbox/hooks/use-listen-to-checklist-run-update';
import { useInjector } from 'app/components/injection-provider';

type ChecklistHeaderProps = {
  item: ChecklistInboxItem;
  isChecklistTaskSelected: () => boolean;
  resolveItemTitle: () => string;
  selectedTaskItem: TaskInboxItem;
  returnToChecklist: () => void;
};

export const ChecklistHeader = ({
  item,
  isChecklistTaskSelected,
  selectedTaskItem,
  resolveItemTitle,
  returnToChecklist,
}: ChecklistHeaderProps) => {
  const { $state } = useInjector('$state');
  const title = resolveItemTitle();
  const checklistTaskSelected = isChecklistTaskSelected();

  const groupId =
    selectedTaskItem && isNotIdRef(selectedTaskItem.task.taskTemplate)
      ? selectedTaskItem.task.taskTemplate.group.id
      : '';
  const selectedTaskItemHref = $state.href('checklist.task', {
    id: selectedTaskItem?.checklist.id,
    groupId,
  });

  const templateName = isNotIdRef(item.template) ? item.template.name : '';

  const checklistHref = $state.href('checklist', { id: item.checklist.id });
  const templateHref = $state.href('templateDashboard', { id: item.template.id });

  const { data: checklistName } = useGetChecklistQuery(
    {
      checklistId: item.checklist.id,
    },
    {
      select: checklist => {
        if (!checklist.name) return isModelRef(item.checklist) ? item.checklist.name : '';

        return checklist.name;
      },
      initialData: toModelRef(item.checklist),
    },
  );

  useListenToWorkflowRunUpdated(item.checklist.id, { shouldUpdateInboxQuery: true });

  return (
    <HStack spacing={{ base: '1.5', sm: '3', lg: '4' }}>
      {checklistTaskSelected ? (
        <>
          <IconButton
            color="gray.500"
            aria-label="return to task list"
            variant="unstyled"
            icon={<Icon size="4" variant="far" icon="arrow-left" />}
            onClick={returnToChecklist}
          />
          <Center height={{ base: '10', lg: '12' }}>
            <Divider orientation="vertical" />
          </Center>
          <InboxCoverIcon templateId={item.template.id} />
          <VStack align="left" minW="0" width="full" flex="1" spacing="0">
            <Link
              href={selectedTaskItemHref}
              variant="noline"
              color="gray.700"
              fontWeight="bold"
              fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
              noOfLines={1}
            >
              {title}
            </Link>
            <HStack spacing="1.5" minW="0" flex="1">
              <Link
                aria-label="workflow run title"
                href={checklistHref}
                variant="noline"
                color="gray.500"
                fontSize="sm"
                maxWidth="45%"
                noOfLines={1}
              >
                <MergeTagHighlighter content={checklistName ?? ''} />
              </Link>
              <Center height={{ base: '2', md: '3' }}>
                <Divider borderColor="gray.500" borderLeftWidth="0.5" orientation="vertical" />
              </Center>
              <Link
                aria-label="workflow title"
                href={templateHref}
                variant="noline"
                color="gray.500"
                fontSize="sm"
                maxWidth="45%"
                noOfLines={1}
              >
                {templateName}
              </Link>
            </HStack>
          </VStack>
        </>
      ) : (
        <>
          <InboxCoverIcon templateId={item.template.id} />
          <VStack align="left" minW="0" width="full" flex="1" spacing="0">
            <Link
              aria-label="workflow run title"
              href={checklistHref}
              variant="noline"
              color="gray.700"
              fontWeight="bold"
              fontSize={{ base: 'md', lg: 'lg' }}
              noOfLines={1}
            >
              <MergeTagHighlighter content={title} />
            </Link>
            <Link
              aria-label="workflow title"
              href={templateHref}
              variant="noline"
              color="gray.600"
              fontWeight="regular"
              fontSize={{ base: 'sm', lg: 'md' }}
              maxWidth="90%"
              noOfLines={1}
            >
              {templateName}
            </Link>
          </VStack>
        </>
      )}
    </HStack>
  );
};
