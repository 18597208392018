import * as yup from 'yup';
import { FieldType, NumberFormFieldWidget } from '@process-street/subgrade/process';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';
import { FormFieldValueSchemaTests } from '../common/validation/form-field-value-schema-tests';
import { FormFieldWidgetSettingsSchemas } from '../common/validation/form-field-widget-settings-schema';
import { Option } from 'space-monad';

type ValidationSchemaProps = {
  required: boolean;
  constraints?: NumberFormFieldWidget['constraints'];
};

export const numberSettingsSchema: yup.ObjectSchema<WidgetSettings<FieldType.Number>> = yup
  .object({
    hasVariables: yup.boolean(),
    config: yup
      .object<NumberFormFieldWidget['config']>({
        ...FormFieldWidgetSettingsSchemas.placeholder(60),
        unit: yup.string().optional(),
        unitLocation: yup.string().oneOf(['prefix', 'suffix']).optional(),
        defaultValue: yup
          .string()
          .optional()
          .when('$hasVariables', {
            is: true,
            otherwise: (schema: yup.StringSchema) =>
              schema
                .test(
                  'isNumber',
                  'Default value must be a number or contain variables.',
                  value => isEmpty(value) || !Number.isNaN(toNumber(value)),
                )
                .when('$constraints', (constraints: NumberFormFieldWidget['constraints'], schema: yup.StringSchema) =>
                  applyConstraints(schema, constraints),
                ),
          }),
      })
      .required(),

    constraints: yup
      .object<NumberFormFieldWidget['constraints']>({
        maxDigits: FormFieldWidgetSettingsSchemas.maxDigits,
        minDigits: FormFieldWidgetSettingsSchemas.minDigits,
        maxValue: FormFieldWidgetSettingsSchemas.maxValue,
        minValue: FormFieldWidgetSettingsSchemas.minValue,
        allowNegative: yup.boolean(),
        decimalPlaces: yup.number().optional().min(1).max(4),
      })
      .required(),
  })
  .required();

export const makeNumberValidationSchema = ({ required, constraints = {} }: ValidationSchemaProps) => {
  return yup
    .string()
    .matches(/^-?(?!0\d+)\d+\.?\d*$/, 'Invalid number')
    .test(FormFieldValueSchemaTests.required(required))
    .test(FormFieldValueSchemaTests.valueMin(constraints.minValue))
    .test(FormFieldValueSchemaTests.valueMax(constraints.maxValue))
    .test(FormFieldValueSchemaTests.lengthMax(constraints.maxDigits, { unit: 'digits' }))
    .test(FormFieldValueSchemaTests.lengthMin(constraints.minDigits, { unit: 'digits' }))
    .test(FormFieldValueSchemaTests.decimalPlaces(constraints.decimalPlaces))
    .test(FormFieldValueSchemaTests.allowNegative(constraints.allowNegative));
};

const applyConstraints = (schema: yup.StringSchema, constraints: NumberFormFieldWidget['constraints']) => {
  return schema
    .test(
      'minValue',
      `Default value must be at least ${constraints.minValue}.`,
      value => constraints.minValue === undefined || isEmpty(value) || toNumber(value) >= constraints.minValue,
    )
    .test(
      'maxValue',
      `Default value must be less than ${constraints.maxValue}.`,
      value => constraints.maxValue === undefined || isEmpty(value) || toNumber(value) <= constraints.maxValue,
    )
    .test(
      'minDigits',
      `Default value must be at least ${constraints.minDigits} digits.`,
      value =>
        constraints.minDigits === undefined ||
        isEmpty(value) ||
        (value ?? '').replace(/[.-]/, '').length >= constraints.minDigits,
    )
    .test(
      'maxDigits',
      `Default value must be at most ${constraints.maxDigits} digits.`,
      value =>
        constraints.maxDigits === undefined ||
        isEmpty(value) ||
        (value ?? '').split('.')?.[0].replace(/-/, '').length <= constraints.maxDigits,
    )
    .test('decimalPlacesNotAllowed', 'Decimal places are not permitted for default field.', value =>
      (constraints.decimalPlaces && constraints.decimalPlaces === 0) || !constraints.decimalPlaces
        ? isEmpty(value) || getDecimalsCount(value) === 0
        : true,
    )
    .test('singleDecimalPlace', '1 decimal place required for default field.', value =>
      constraints.decimalPlaces === 1 ? isEmpty(value) || getDecimalsCount(value) === 1 : true,
    )
    .test(
      'multipleDecimalPlaces',
      `${constraints.decimalPlaces} decimal places required for default field.`,
      value =>
        constraints.decimalPlaces === undefined ||
        constraints.decimalPlaces <= 1 ||
        isEmpty(value) ||
        getDecimalsCount(value) === constraints.decimalPlaces,
    )
    .test(
      'allowNegative',
      'Negative numbers are not permitted in default field.',
      value => constraints.allowNegative || isEmpty(value) || toNumber(value) >= 0,
    );
};

function toNumber(value?: string | null): number {
  return Number.parseFloat(value ?? '');
}

function getDecimalsCount(value?: string | null): number {
  return Option(value?.split('.')[1])
    .map(d => d.length)
    .getOrElse(0);
}

function isEmpty(value?: string | null): boolean {
  return (value ?? '') === '';
}
