import * as React from 'react';
import {
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Skeleton,
  VStack,
  Icon,
  Flex,
  Box,
  Text,
} from 'components/design/next';
import { useScheduleStoreAndQuery, FutureChecklistRow } from './query';
import { useTable, Column } from 'react-table';
import { TemplateCell, AssignmentsCell, StartDateCell, NameCell, DueDateCell } from './cells';
import { toDayGroupId } from './utils';
import { useInjector } from 'components/injection-provider';

export const TableContent: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { $state } = useInjector('$state');

  const {
    store: { rows, day },
    isLoading,
  } = useScheduleStoreAndQuery();

  const columns = React.useMemo<Column<FutureChecklistRow>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: NameCell,
      },
      {
        Header: 'Workflow',
        accessor: 'template',
        Cell: TemplateCell,
      },
      {
        Header: 'Assignees',
        accessor: 'assignments',
        Cell: AssignmentsCell,
      },
      {
        Header: 'Start Date',
        accessor: 'nextRunDate',
        Cell: StartDateCell,
      },
      {
        Header: 'Due Date',
        accessor: 'dueDate',
        Cell: DueDateCell,
      },
    ],
    [],
  );

  const data = React.useMemo(() => {
    if (!day) {
      return rows.toArray();
    }
    return rows.toArray().filter(row => toDayGroupId(row.nextRunDate) === day);
  }, [day, rows]);

  const table = useTable({
    columns,
    data,
  });

  return (
    <>
      <Table my="8" borderWidth="1px" fontSize="sm" {...table.getTableProps()}>
        <Thead bg="gray.50">
          {table.headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th {...column.getHeaderProps()} whiteSpace="nowrap" scope="col">
                  {column.render('Header') as React.ReactNode}
                </Th>
              ))}
              <Th />
            </Tr>
          ))}
        </Thead>

        <Tbody {...table.getTableBodyProps()}>
          {isLoading
            ? Array.from({ length: 3 }, (_, i) => (
                <Tr key={i}>
                  {Array.from({ length: columns.length }, (_, i) => (
                    <Td key={i}>
                      <Flex h="8" alignItems="center">
                        <Box>
                          <Skeleton>Filler text</Skeleton>
                        </Box>
                      </Flex>
                    </Td>
                  ))}
                </Tr>
              ))
            : table.rows.map(row => {
                table.prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} minH="9">
                    {row.cells.map(cell => {
                      return (
                        <Td whiteSpace="nowrap" {...cell.getCellProps()}>
                          <Flex alignItems="center" h="8">
                            {cell.render('Cell') as React.ReactNode}
                          </Flex>
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
        </Tbody>
      </Table>

      {table.rows.length === 0 && !isLoading ? (
        <VStack spacing="6" mt="26">
          <Icon
            icon="calendar-plus"
            variant="fad"
            size="16"
            primaryLayer={{ color: 'gray.400' }}
            secondaryLayer={{ color: 'gray.500' }}
          />
          <Box textAlign="center">
            <Text variant="3" color="gray.700" fontWeight="bold" mb="4">
              There's nothing here yet.
            </Text>

            <Button
              as="a"
              variant="solid"
              colorScheme="brand"
              href={$state.href('futureChecklistAdd')}
              _hover={{ bgColor: 'brand.600', color: 'white' }}
            >
              Schedule workflow
            </Button>
          </Box>
        </VStack>
      ) : null}
    </>
  );
};
