import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type DeleteSavedViewParams = {
  dataSetId: Muid;
  savedViewId: Muid;
};

type DeleteSavedViewResponse = unknown;

export const DeleteSavedViewMutation = {
  mutationFn: async (params: DeleteSavedViewParams) => {
    return axiosService
      .getAxios()
      .delete(`/1/data-sets/${params.dataSetId}/saved-views/${params.savedViewId}`)
      .then(r => r.data);
  },
};

export const useDeleteSavedViewMutation = (
  options: UseMutationOptions<DeleteSavedViewResponse, AxiosError, DeleteSavedViewParams> = {},
) => {
  return useMutation(DeleteSavedViewMutation.mutationFn, options);
};
