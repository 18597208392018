import { AccessLevel } from '@process-street/subgrade/permission/permit-constants';
import { ChecklistPermit } from '@process-street/subgrade/permission/permission-model';

export interface WorkflowRunPermitDelta {
  checklistRead: boolean;
  checklistUpdate: boolean;
  checklistDelete: boolean;
  doodadCreate: boolean;
  doodadRead: boolean;
  doodadUpdate: boolean;
  doodadDelete: boolean;
}

const emptyWorkflowRunPermit: WorkflowRunPermitDelta = {
  checklistRead: false,
  checklistUpdate: false,
  checklistDelete: false,
  doodadCreate: false,
  doodadRead: false,
  doodadUpdate: false,
  doodadDelete: false,
};

const runPermissions: WorkflowRunPermitDelta = {
  ...emptyWorkflowRunPermit,
  checklistRead: true,
  checklistUpdate: true,
  doodadCreate: true,
  doodadRead: true,
};

const getKeys = (permissions: WorkflowRunPermitDelta) =>
  Object.keys(permissions) as Array<keyof WorkflowRunPermitDelta>;

const workflowRunPermitLookup: { [index: string]: WorkflowRunPermitDelta } = {
  [AccessLevel.WORKFLOW_RUN_RUN]: runPermissions,
};

const workflowRunAccessLevels = [AccessLevel.WORKFLOW_RUN_RUN];

const hasWorkflowRunAccessLevel = (permit: ChecklistPermit, level: AccessLevel) => {
  const permissions = workflowRunPermitLookup[level];
  const hasLevel = permissions && getKeys(permissions).every(key => permissions[key] === permit[key]);
  return hasLevel;
};

const findWorkflowRunAccessLevel = (permit: ChecklistPermit) =>
  workflowRunAccessLevels.find(level => hasWorkflowRunAccessLevel(permit, level));

export const WorkflowRunPermitResolver = {
  hasWorkflowRunAccessLevel,
  findWorkflowRunAccessLevel,
  workflowRunPermitLookup,
};
