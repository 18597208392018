import * as React from 'react';
import { ChecklistInboxItem, InboxItemUtils, TaskInboxItem } from '@process-street/subgrade/inbox';
import { ChecklistHeader } from './components/checklist';
import { TaskHeader } from './components/task';

export type InboxItemHeaderProps = {
  item: ChecklistInboxItem | TaskInboxItem;
  isChecklistTaskSelected: () => boolean;
  resolveItemTitle: () => string;
  resolveTaskItemSubtitle: () => string;
  selectedTaskItem: TaskInboxItem;
  returnToChecklist: () => void;
};

export const InboxItemHeader: React.FC<React.PropsWithChildren<InboxItemHeaderProps>> = ({
  item,
  selectedTaskItem,
  isChecklistTaskSelected,
  resolveItemTitle,
  returnToChecklist,
  resolveTaskItemSubtitle,
}) => {
  if (InboxItemUtils.isChecklist(item))
    return (
      <ChecklistHeader {...{ item, isChecklistTaskSelected, resolveItemTitle, selectedTaskItem, returnToChecklist }} />
    );
  if (InboxItemUtils.isTask(item))
    return (
      <TaskHeader {...{ item, isChecklistTaskSelected, resolveItemTitle, selectedTaskItem, resolveTaskItemSubtitle }} />
    );

  return null;
};
