import * as React from 'react';
import { useState } from 'react';
import { Container, VStack } from '@chakra-ui/react';
import { TasksTable } from './components/tasks-table';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { useCurrentUser } from 'hooks/use-current-user';
import { TasksFiltersContext } from './tasks-filters-provider';
import { ThemeProvider2024 } from 'components/design/next/theme-provider-2024';
import { IdentifiableTaskRow, TasksRowSelectionContext } from './row-selection-provider';
import { TOP_BAR_HEIGHT_CSS_VAR } from 'pages/forms/_id/shared';
import { useMount, useUnmount } from 'react-use';
import { useInjector } from 'components/injection-provider';
import { InboxItemDetailsEvents } from 'directives/inbox/item/details/inbox-item-details.events';
import { FilterBar } from 'pages/tasks/components/filter-bar';
import { GetOneOffTaskQuery } from 'features/one-off-tasks/query-builder';
import { useViewTask } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { AppModalQueryParam } from 'app/app.constants';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { useQueryClient } from 'react-query';
import { TasksTableUtils } from './components/tasks-table/tasks-table-utils';
import { useStateParams } from 'app/adapters/navigation';
import { useGridSettingsPersistence } from './hooks/use-grid-settings-persistence';
import { getMyWorkInitialFilters, parseMyWorkFiltersSearchParams } from './helpers';
import { useMyWorkBackNavigation } from './hooks/use-my-work-back-navigation';
import deepEqual from 'deep-equal';

export const TasksPage = () => {
  const organization = useSelectedOrganization();
  const user = useCurrentUser();
  const viewTask = useViewTask();
  const { $rootScope } = useInjector('$rootScope');
  const queryClient = useQueryClient();

  const isLoaded = Boolean(organization) && Boolean(user);

  const stateParams = useStateParams();

  const preselectedOneOffTaskQuery = GetOneOffTaskQuery.useQuery(
    { id: stateParams[AppModalQueryParam.ModalTaskId] as string },
    {
      enabled: Boolean(stateParams[AppModalQueryParam.Modal]) && Boolean(stateParams[AppModalQueryParam.ModalTaskId]),
    },
  );

  const initialFilters = React.useMemo(
    () =>
      getMyWorkInitialFilters({
        organization,
        user,
      }),
    [organization, user],
  );

  const [filters, setFilters] = useState<TasksTableUtils.TasksTableFilters>(initialFilters);

  useGridSettingsPersistence({
    isLoaded,
    filters,
  });

  const templateIdParam = stateParams?.templateId;
  useMount(function preselectTemplateFromState() {
    if (filters.templateIds && filters.templateIds.length > 0) return;

    if (typeof templateIdParam === 'string') {
      setFilters(currentFilters => ({ ...currentFilters, templateIds: [templateIdParam] }));
    }
  });

  React.useEffect(
    function openTaskIfPreselected() {
      if (preselectedOneOffTaskQuery.data) {
        viewTask({ task: preselectedOneOffTaskQuery.data });
      }
    },
    [preselectedOneOffTaskQuery.data, viewTask],
  );

  React.useEffect(
    function setCurrentOrganizationId() {
      if (organization) {
        setFilters(currentFilters => ({ ...currentFilters, organizationId: organization.id }));
      }
    },
    [organization],
  );

  React.useEffect(
    function setCurrentUserId() {
      if (user) {
        setFilters(currentFilters => {
          // Don't override userIds from URL.
          if (currentFilters.userIds.length) return currentFilters;

          return { ...currentFilters, userIds: [user.id] };
        });
      }
    },
    [user],
  );

  const rowSelectionValue = useState<IdentifiableTaskRow[]>([]);

  useUnmount(() => {
    $rootScope.$broadcast(InboxItemDetailsEvents.INBOX_ITEM_DETAILS_CLOSED);
  });

  useMount(async function loadFreshInboxItems() {
    await GetInboxItemsQuery.invalidate(queryClient);
  });

  const handleNavigationChange = React.useCallback(
    (nextURL: URL) => {
      if (nextURL.search && nextURL.pathname === '/work') {
        const newFilters = {
          ...filters,
          ...parseMyWorkFiltersSearchParams(nextURL.search),
        };

        const hasFiltersChanged = !deepEqual(newFilters, filters);
        if (hasFiltersChanged) {
          setFilters(newFilters);
        }
      }
    },
    [filters],
  );

  useMyWorkBackNavigation({
    onChange: handleNavigationChange,
  });

  if (!isLoaded) {
    return null;
  }

  return (
    <ThemeProvider2024>
      <TasksFiltersContext.Provider value={filters}>
        <TasksRowSelectionContext.Provider value={rowSelectionValue}>
          <VStack
            w="full"
            alignItems="stretch"
            as={Container}
            maxW="1440px"
            py={{ base: 4, lg: 6 }}
            px={{ base: 2, lg: 10 }}
            h={`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR})`}
          >
            <FilterBar onChange={setFilters} filters={filters} />

            {Boolean(filters.organizationId) && <TasksTable />}
          </VStack>
        </TasksRowSelectionContext.Provider>
      </TasksFiltersContext.Provider>
    </ThemeProvider2024>
  );
};
