import { StringUtils } from '@process-street/subgrade/util';
import jsonpath from 'jsonpath';

type ParsedPath = {
  expression: { value: string | number };
};
function isParsedPath(obj: any): obj is ParsedPath {
  return obj?.expression?.value != null;
}

export function getParsedPath(path: string): string[] {
  return [...jsonpath.parse(path).values()].slice(1).map(getValue);
}

export function getReadablePath(path: string): string {
  return getParsedPath(path).map(formatPath).join(' / ');
}

const getValue = (path: any): string => {
  if (isParsedPath(path)) {
    return String(path.expression.value);
  }

  return '(invalid path)';
};

export function formatPath(path: string): string {
  return StringUtils.toTitleCase(
    String(path)
      // Look for long acronyms and filter out the last letter
      .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
      // Look for lower-case letters followed by upper-case letters
      .replace(/([a-z\d])([A-Z])/g, '$1 $2')
      // Look for lower-case letters followed by numbers
      .replace(/([a-zA-Z])(\d)/g, '$1 $2')
      // replace lodash and dash with space
      .replace(/[-_]/g, ' ')
      // Remove any white space left around the word

      .trim(),
  );
}
