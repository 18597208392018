import * as React from 'react';
import { LegendOrdinal } from '@visx/legend';
import { LegendOrdinalProps } from '@visx/legend/lib/legends/Ordinal';
import { forwardRef, StackProps, Stack } from 'components/design/next';
import { ScaleOrdinal } from 'd3-scale';

type Label<Key extends string> = Parameters<
  NonNullable<LegendOrdinalProps<ScaleOrdinal<Key, string>>['children']>
>[0][number];

export function makeBarStackHorizontalLegend<Key extends string>() {
  return forwardRef<
    Omit<StackProps, 'scale' | 'children'> & {
      scale: ScaleOrdinal<Key, string>;
      children: (label: Label<Key>) => React.ReactNode;
    },
    'div'
  >(({ children, scale, ...props }, ref) => {
    return (
      <LegendOrdinal<ScaleOrdinal<Key, string>> direction="row" scale={scale}>
        {labels => (
          <Stack
            ref={ref}
            pt="6"
            pb="0"
            px="4"
            justifyContent="center"
            gap={{ base: '2', md: '5' }}
            wrap="wrap"
            direction="row"
            {...props}
          >
            {labels.map(children)}
          </Stack>
        )}
      </LegendOrdinal>
    );
  });
}
