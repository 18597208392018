import * as React from 'react';
import { forwardRef } from 'components/design/next';
import { StackKey } from '@visx/shape/lib/types';
import { BarStackBar } from '../common/types';

export function makeBarStackHorizontalRect<Datum, Key extends StackKey = StackKey>() {
  return forwardRef<React.SVGProps<SVGRectElement> & { bar: BarStackBar<Datum, Key> }, 'rect'>(
    ({ bar, ...props }, ref) => {
      return <rect ref={ref} x={bar.x} y={bar.y} width={bar.width} height={bar.height} fill={bar.color} {...props} />;
    },
  );
}
