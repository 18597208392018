import angular from 'angular';
import { connectService } from 'reducers/util';
import { IntercomService } from 'services/interop/intercom-service';
import { trace } from 'components/trace';
import jwtDecode from 'jwt-decode';

// TODO this service is old and should be removed. See comments below.

angular
  .module('frontStreetApp.services')
  .service('AnonAuthService', function ($ngRedux, $q, AuthenticationActions, DaoCacheService) {
    const logger = trace({ name: 'AnonAuthService' });

    const self = this;
    connectService('AnonAuthService', $ngRedux, null, {
      authenticateAnonymous: AuthenticationActions.authenticateAnonymous,
    })(self);

    let token = null;
    let user = null;
    let organizationId = null;
    let userIsLoggedIn = false;

    function AnonAuthService() {
      void 0;
    }

    // can be moved to AnonymousAuthService
    AnonAuthService.authenticateAnonymously = function (resourceType, resourceId) {
      return self.actions
        .authenticateAnonymous({
          id: resourceId,
          type: resourceType,
        })
        .then(
          ({ payload }) => {
            logger.info('succeeded to authenticate anonymous user');

            AnonAuthService.setupData(payload, false /* loggedIn */);

            return payload;
          },
          response => {
            logger.error('failed to authenticate anonymous user. Reason: %s', JSON.stringify(response));

            return $q.reject(response);
          },
        );
    };

    // The below could be probably replaced by using SessionService
    // we can look at user type, org ID, token in session.
    AnonAuthService.isAuthenticatedAnonymously = function () {
      return !!token;
    };

    AnonAuthService.isLoggedIn = function () {
      return !!userIsLoggedIn;
    };

    AnonAuthService.setupData = function (data, loggedIn) {
      IntercomService.update({ hide_default_launcher: true });
      ({ token, user } = data);
      organizationId = data.selectedOrganization.id;
      userIsLoggedIn = loggedIn;
    };

    AnonAuthService.clearAnonymousAuthentication = function () {
      token = null;
      user = null;
      organizationId = null;
      userIsLoggedIn = false;
      DaoCacheService.clearAll();
    };

    AnonAuthService.getAnonymousToken = function () {
      if (!this.isAuthenticatedAnonymously()) {
        return null;
      }

      return token;
    };

    AnonAuthService.getOrganizationId = function () {
      if (!this.isAuthenticatedAnonymously()) {
        return null;
      }

      return organizationId;
    };

    AnonAuthService.getAnonymousUser = function () {
      if (!this.isAuthenticatedAnonymously()) {
        return null;
      }

      return user;
    };

    // unused
    AnonAuthService.getChecklistId = function () {
      if (!token) {
        return null;
      }

      const deserializedToken = jwtDecode(token);

      return deserializedToken.checklistId;
    };

    // unused
    AnonAuthService.getTemplateId = function () {
      if (!token) {
        return null;
      }

      const deserializedToken = jwtDecode(token);

      return deserializedToken.templateId;
    };

    return AnonAuthService;
  });
