import { forwardRef, omitThemingProps, ThemingProps, useStyleConfig, HTMLChakraProps } from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import * as React from 'react';
import { Text } from 'components/design/next';
import { HeaderElementProps } from '../../header';

export interface EditorHeadingProps extends HTMLChakraProps<'h1'>, ThemingProps {
  variant: '1' | '2' | '3';
}

export const EditorHeading = forwardRef<EditorHeadingProps, 'h1'>((props, ref) => {
  const { className, ...rest } = omitThemingProps(props);
  const styles = useStyleConfig('EditorHeading', props);
  const _className = cx(`chakra-editor-heading-${props.variant}`, className);
  const as = React.useMemo(() => {
    return `h${parseInt(props.variant, 10) + 1}` as HeaderElementProps['element']['type'];
  }, [props.variant]);

  return <Text as={as} sx={styles} display="block" ref={ref} className={_className} {...rest} />;
});
