import * as React from 'react';
import { FormControl, FormLabel, Grid, GridItem } from 'components/design/next';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { timeZones } from 'utils/timezones';
import { SaveOrganizationParams } from 'features/organization/manage/settings/settings';
import { OnChangeValue } from 'react-select';
import { SimpleOption } from 'components/design/BlvdSelect/types';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useGetOrganizationQuery } from 'features/organization/query-builder';

export type TimeZoneFieldProps = {
  onSave: (params: SaveOrganizationParams) => void;
};

export const TimeZoneField: React.FC<React.PropsWithChildren<TimeZoneFieldProps>> = ({ onSave }) => {
  const options = timeZones.map(option => ({ value: option, label: option }));
  const currentOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const [timeZone, setTimeZone] = React.useState<SimpleOption | null>(null);
  const organizationQuery = useGetOrganizationQuery({ organizationId: currentOrganization?.id });

  React.useEffect(() => {
    const tz = organizationQuery.data?.timeZone;
    if (tz) {
      setTimeZone({ value: tz, label: tz });
    }
  }, [organizationQuery.data]);

  const handleChange = (option: OnChangeValue<SimpleOption, false>) => {
    if (BlvdSelectHelpers.isOptionType<SimpleOption>(option)) {
      setTimeZone(option);

      onSave({
        timeZone: option.value,
      });
    }
  };

  return (
    <FormControl as={Grid} templateColumns={{ base: '1fr', md: '220px 1fr' }}>
      <GridItem display="flex" alignItems="center">
        <FormLabel htmlFor="timezone" color="gray.600" as="label">
          Timezone
        </FormLabel>
      </GridItem>
      <GridItem
        sx={{
          '.blvd-select .blvd-select__control .blvd-select__value-container': { pl: 4 },
        }}
      >
        <BlvdSelect
          id="timezone"
          name="timezone"
          data-testid="timezone"
          options={options}
          onChange={handleChange}
          value={timeZone}
          isSearchable
        />
      </GridItem>
    </FormControl>
  );
};
