import { Muid } from '@process-street/subgrade/core';
import {} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace FinishUploadEmailHeaderLogoMutation {
  export type Params = {
    organizationId: Muid;
    key: string;
    originalFilename: string;
    contentType: string;
  };

  export type Response = {};

  export const key = ['theme', 'email-logo', 'finish-upload'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/organizations/${params.organizationId}/theme/email-header-logo/finish-upload`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
