import angular from 'angular';
import { HttpStatus } from '@process-street/subgrade/util';
import { connectService } from 'reducers/util';
import { trace } from 'components/trace';
import { ChecklistAssignmentEvent } from 'services/assignments/checklist-assignment-event';

angular
  .module('frontStreetApp.services')
  .service(
    'ChecklistAssignmentService',
    function ($ngRedux, $q, $rootScope, ChecklistAssignmentActions, InvitationService) {
      const self = this;
      connectService('ChecklistAssignmentService', $ngRedux, null, ChecklistAssignmentActions)(self);

      const logger = trace({ name: 'ChecklistAssignmentService' });

      self.assignOrInvite = function (checklist, user, autoAssign) {
        return self.assign(checklist, user, autoAssign).catch(response => {
          if (response.status === HttpStatus.NOT_FOUND) {
            return self.invite(checklist, user);
          } else {
            return $q.reject(response);
          }
        });
      };

      self.assign = function (checklist, user, autoAssign) {
        return self.actions.assignUser(checklist.id, user.email, autoAssign).then(
          ({ payload: assignment }) => {
            logger.info('succeeded to assign "%s" to checklist', user.email);

            $rootScope.$broadcast(ChecklistAssignmentEvent.CREATE_OK, { checklist, user });

            return assignment;
          },
          response => {
            logger.info('failed to assign "%s" to checklist (error code: %s)', user.email, response.status);
            return $q.reject(response);
          },
        );
      };

      self.invite = function (checklist, user) {
        const resource = { type: 'Checklist', id: checklist.id };
        const options = {
          username: user.username,
          avatarUrl: user.avatarUrl,
          provider: user.provider,
        };

        return InvitationService.invite(user.email, resource, options).then(() =>
          self
            .getAllByChecklistId(checklist.id, true)
            .then(as => as.find(a => a.organizationMembership.user.email === user.email)),
        );
      };

      self.unassign = (checklist, user) =>
        self.actions.unassignUser(checklist.id, user.email).then(({ payload: assignment }) => {
          $rootScope.$broadcast(ChecklistAssignmentEvent.DELETE_OK, { checklist, user });
          return assignment;
        });

      self.getAllByChecklistId = (checklistId, flushCache) =>
        self.actions.getAllByChecklistId(checklistId, flushCache).then(({ payload: assignments }) => assignments);

      self.getAllByOrganizationId = query =>
        self.actions.getAllByOrganizationId(query).then(({ payload: assignments }) => assignments);
    },
  );
