import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { ReducerObject } from 'reducers/util/types';
import { Reducer } from 'redux';

export function composeReducerObjects<S>(...reducers: Array<ReducerObject<S>>): Reducer<S> {
  const allReducers = reducers.reduce((agg, reducer) => ({ ...agg, ...reducer }), {});
  // TODO
  // @ts-expect-error -- TODO
  return handleActionsOnSuccess<S>(allReducers, {});
}
