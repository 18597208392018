import {
  FOLDER_PERMIT_DELETE,
  FOLDER_PERMIT_GET_ALL_BY_ORGANIZATION_ID,
  FOLDER_PERMIT_PUT,
} from 'reducers/folder-permit/folder-permit.actions';
import { entitiesCollectionReducerObject, referencesNormalizer } from 'reducers/entities/entities.utils';
import { composeReducerObjects } from 'reducers/util';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import {
  CREATE_FOLDER_PERMIT,
  DELETE_FOLDER_PERMIT,
  FOLDER_PERMITS_GET_ALL_BY_FOLDER_AND_ORGANIZATION_ID,
  UPDATE_FOLDER_PERMIT,
} from 'components/folder/membership/store/folder-membership.actions';
import { toSuccess } from 'reducers/util/handlers';

const normalizeFolderPermit = referencesNormalizer(['organization', 'organizationMembership', 'folder']);

export const folderPermitEntitiesReducer = composeReducerObjects(
  entitiesCollectionReducerObject(FOLDER_PERMIT_PUT, FOLDER_PERMIT_DELETE, normalizeFolderPermit),

  {
    [FOLDER_PERMIT_GET_ALL_BY_ORGANIZATION_ID]: (state, { payload: folderPermits }) =>
      EntitiesReducerUtils.upsertAll(state, folderPermits),
    [toSuccess(FOLDER_PERMITS_GET_ALL_BY_FOLDER_AND_ORGANIZATION_ID)]: (state, { payload }) =>
      EntitiesReducerUtils.upsertAll(state, payload.response, normalizeFolderPermit),
    [toSuccess(DELETE_FOLDER_PERMIT)]: (state, { payload }) => EntitiesReducerUtils.deleteById(state, payload.permitId),
    [toSuccess(CREATE_FOLDER_PERMIT)]: (state, { payload }) =>
      EntitiesReducerUtils.upsert(state, payload.permit, normalizeFolderPermit),
    [toSuccess(UPDATE_FOLDER_PERMIT)]: (state, { payload }) =>
      EntitiesReducerUtils.upsert(state, payload.permit, normalizeFolderPermit),
  },
);
