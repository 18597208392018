import { InboxItemUtils } from '@process-street/subgrade/inbox';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import get from 'lodash/get';
import {
  CHECKLIST_ASSIGNMENT_ARRAY_ACTIONS,
  CHECKLIST_ASSIGNMENT_ASSIGN_USER,
  CHECKLIST_ASSIGNMENT_UNASSIGN_USER,
} from 'reducers/checklist-assignment/checklist-assignment.actions';
import { CHECKLIST_DELETE, CHECKLIST_DELETE_BY_ID } from 'reducers/checklist/checklist.actions';
import { cascadeCleanOnDeleteReducerObject } from 'reducers/entities/entities.utils';
import { INBOX_GET_ALL_BY_ORGANIZATION_ID } from 'reducers/inbox/inbox.actions';
import { composeReducerObjects, concatInDistinctArray, handleActionsOnSuccess } from 'reducers/util';
import { combineReducers } from 'redux';

import { normalizeChecklistAssignment } from './checklist-assignment.selectors';

const checklistAssignmentApiEntitiesReducerObject = {
  [CHECKLIST_ASSIGNMENT_ARRAY_ACTIONS]: (state, { payload: assignments }) =>
    EntitiesReducerUtils.upsertAll(state, assignments, normalizeChecklistAssignment),
  [CHECKLIST_ASSIGNMENT_ASSIGN_USER]: (state, { payload: assignment }) =>
    EntitiesReducerUtils.upsert(state, assignment, normalizeChecklistAssignment),
  [CHECKLIST_ASSIGNMENT_UNASSIGN_USER]: (state, { payload: { id } }) => EntitiesReducerUtils.deleteById(state, id),
};

const checklistAssignmentOnChecklistDeleteReducerObject = {
  [CHECKLIST_DELETE_BY_ID]: (state, { meta: { assignments } }) =>
    EntitiesReducerUtils.deleteAllByIds(
      state,
      assignments.map(({ id }) => id),
    ),
};

export const checklistAssignmentEntitiesReducer = composeReducerObjects(
  checklistAssignmentApiEntitiesReducerObject,
  checklistAssignmentOnChecklistDeleteReducerObject,

  cascadeCleanOnDeleteReducerObject(CHECKLIST_DELETE, 'checklist.id'),
);

// ORGANIZATION_MEMBERSHIP_DELETE && FOLDER_DELETE will make state inconsistent
export const checklistAssignmentByChecklistReducer = handleActionsOnSuccess(
  {
    [CHECKLIST_ASSIGNMENT_ASSIGN_USER]: (state, { payload: assignment }) =>
      LookupsReducerUtils.upsertUsingSelectorFunctions(state, assignment, a => a.checklist.id),
    [CHECKLIST_ASSIGNMENT_UNASSIGN_USER]: (state, { payload: assignment }) =>
      LookupsReducerUtils.deleteUsingSelectorFunctions(state, assignment, a => a.checklist.id),
    [CHECKLIST_ASSIGNMENT_ARRAY_ACTIONS]: (state, { payload: assignments }) =>
      LookupsReducerUtils.upsertAll(state, assignments, 'checklist.id', 'id'),
    [CHECKLIST_DELETE_BY_ID]: (state, { meta: { checklistId } }) => EntitiesReducerUtils.deleteById(state, checklistId),
  },
  {},
);

const checklistAssignmentByUserReducer = handleActionsOnSuccess(
  {
    [INBOX_GET_ALL_BY_ORGANIZATION_ID]: (state, { payload: items }) => {
      const checklists = InboxItemUtils.getChecklists(items);
      const checklistToUser = checklists.reduce((agg, checklistItem) => {
        const checklistId = checklistItem.checklist.id;
        const assignees = checklistItem.assignees.map(user => user.id);
        agg[checklistId] = assignees;
        return agg;
      }, {});
      return LookupsReducerUtils.mergeLookupMap(state, checklistToUser);
    },
    [CHECKLIST_ASSIGNMENT_ASSIGN_USER]: (state, { payload: assignment }) => {
      const checklistId = assignment.checklist.id;
      const userId = get(assignment, 'organizationMembership.user.id');
      if (userId) {
        const newState = { ...state, [checklistId]: concatInDistinctArray(state[userId], assignment.id) };
        return newState;
      } else {
        return state;
      }
    },
    [CHECKLIST_ASSIGNMENT_UNASSIGN_USER]: (state, { payload: assignment }) => {
      const newState = { ...state };
      const userId = get(assignment, 'organizationMembership.user.id');
      if (newState[assignment.checklist.id]) {
        newState[assignment.checklist.id] = newState[assignment.checklist.id].filter(id => id !== userId);
        if (newState[assignment.checklist.id].length === 0) {
          delete newState[assignment.checklist.id];
        }
      }
      return newState;
    },
    [CHECKLIST_ASSIGNMENT_ARRAY_ACTIONS]: (state, { payload: assignments }) =>
      LookupsReducerUtils.upsertAll(state, assignments, 'checklist.id', 'organizationMembership.user.id'),
    [CHECKLIST_DELETE_BY_ID]: (state, { meta: { checklistId } }) => EntitiesReducerUtils.deleteById(state, checklistId),
  },
  {},
);

const checklistAssignmentByOrganizationMembershipReducer = handleActionsOnSuccess(
  {
    [CHECKLIST_ASSIGNMENT_ASSIGN_USER]: (state, { payload: assignment }) =>
      LookupsReducerUtils.upsertUsingSelectorFunctions(state, assignment, a => a.organizationMembership.id),
    [CHECKLIST_ASSIGNMENT_UNASSIGN_USER]: (state, { payload: assignment }) =>
      LookupsReducerUtils.deleteUsingSelectorFunctions(state, assignment, a => a.organizationMembership.id),
    [CHECKLIST_ASSIGNMENT_ARRAY_ACTIONS]: (state, { payload: assignments }) =>
      LookupsReducerUtils.upsertAll(state, assignments, 'organizationMembership.id', 'id'),
    [CHECKLIST_DELETE_BY_ID]: (state, { meta: { assignments } }) =>
      LookupsReducerUtils.deleteAll(state, assignments, 'organizationMembership.id', 'id'),
  },
  {},
);

export const checklistAssignmentReducer = combineReducers({
  byChecklist: checklistAssignmentByChecklistReducer,
  byUser: checklistAssignmentByUserReducer,
  byOrganizationMembership: checklistAssignmentByOrganizationMembershipReducer,
});
