import React from 'react';
import { match, P } from 'ts-pattern';
import { UseQueryResult } from 'react-query';
import { CoverImageByTemplateIdQuery } from 'features/cover-image/query-builder';
import { GetCoverIconByTemplateId } from 'features/cover-icon/query-builder';
import { Template } from '@process-street/subgrade/process';
import { Box } from 'components/design/next';
import { TemplateCoverImage } from 'features/cover-image/components/template';
import { TemplateCoverIcon } from 'features/cover-icon/components/template';
import { CoverIcon, CoverImage } from '@process-street/subgrade/core';

export type ViewProps = { templateId: Template['id'] };

export const View = ({ templateId }: ViewProps) => {
  const coverIconQuery = GetCoverIconByTemplateId.useQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
    },
  );
  const coverImageQuery = CoverImageByTemplateIdQuery.useQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
    },
  );

  return (
    match<[UseQueryResult<CoverIcon | undefined>, UseQueryResult<CoverImage | undefined>]>([
      coverIconQuery,
      coverImageQuery,
    ])
      //   icon / no cover image
      .with([{ status: 'success' }, { status: 'success', data: P.nullish }], ([{ data: coverIcon }, _]) => (
        <TemplateCoverIcon icon={coverIcon} templateId={templateId} ml="3" pl="2" mb="-6" height="14" width="14" />
      ))
      // no icon / cover image
      .with([{ status: 'success', data: P.nullish }, { status: 'success' }], ([_, { data: coverImage }]) => (
        <TemplateCoverImage coverImage={coverImage} templateId={templateId} />
      ))
      // // icon / cover image
      .with([{ status: 'success' }, { status: 'success' }], ([{ data: coverIcon }, { data: coverImage }]) => (
        <Box mb="-7" borderColor="transparent">
          <TemplateCoverImage coverImage={coverImage} mb={0} maxWidth="100%" mr="0" templateId={templateId} />
          <TemplateCoverIcon icon={coverIcon} mt="-8" templateId={templateId} />
        </Box>
      ))
      .otherwise(() => <Box borderColor="transparent"></Box>)
  );
};
