import { Box, Divider } from 'components/design/next';
import * as React from 'react';
import { Organization } from '@process-street/subgrade/core';
import { StripeLink } from 'components/billing/stripe-billing-portal-button/stripe-link';

export type BillingDetailsProps = {
  organization: Organization;
};

export const PlanBillingDetails: React.FC<React.PropsWithChildren<BillingDetailsProps>> = ({ organization }) => {
  return (
    <Box>
      <Divider opacity={1} borderColor="gray.200" my={8} />
      <StripeLink organization={organization} />
    </Box>
  );
};
