import * as React from 'react';
import { VStack } from 'components/design/next';
import { InboxCommentsList } from 'features/comments/components/inbox';
import { Container } from '@chakra-ui/react';
import { ThemeProvider2024 } from 'components/design/next/theme-provider-2024';

export interface CommentsPageProps {}

export const CommentsPage: React.FC<React.PropsWithChildren<CommentsPageProps>> = () => {
  return (
    <ThemeProvider2024>
      <VStack maxW="4xl" w="full" alignItems="stretch" as={Container} pt={{ base: 4, md: 6 }} p={4}>
        <InboxCommentsList p={0} m={0} />
      </VStack>
    </ThemeProvider2024>
  );
};
