import { Folder, Template, TemplateStatus, TextWidget, Widget } from '@process-street/subgrade/process';
import { GetAllTemplatesQuery, useDeleteTemplate } from 'features/template/query-builder';
import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { TEMPLATE_DELETE_BY_ID } from 'reducers/template/template.actions';
import { makeMutationListener } from 'services/react-query';
import { useIsEditable } from 'features/rich-text';
import { DEFAULT_PAGE_NAME } from './constants';
import { usePageQuery } from './query';
import { PublishDraftMutation } from 'features/template-revisions/query-builder';
import { DeleteTemplateMutation } from 'features/template/mutation-builder';
import { PagesWidgetElement, usePagesPlateEditorState } from './pages-plate-types';
import { AnalyticsService } from 'components/analytics/analytics.service';

const KEYS = [PublishDraftMutation.key];

/**
 * This hook tells whether a page draft can-be automatically deleted,
 * and returns a delete mutation.
 * Doesn't have any side effects.
 * @param templateId
 * @returns shouldAutoDelete: page is empty and is not being published
 * @returns deleteTemplate: mutation that deletes the empty page
 */
export function usePageDraftShouldAutoDelete(templateId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const editable = useIsEditable();
  const editor = usePagesPlateEditorState();
  const widgets = editor?.children ?? [];

  const { isLoading: pageQueryIsLoading, template, templateRevision } = usePageQuery(templateId, editable);

  const [published, setPublished] = useState(false);

  const templateQueryKey =
    template &&
    GetAllTemplatesQuery.getKey({
      organizationId: template.organization.id,
      templateStatus: TemplateStatus.Active,
    });
  const deleteTemplate = useDeleteTemplate({
    mutationKey: DeleteTemplateMutation.getKey(templateId),
    onMutate: ({ templateId: deletedTemplateId }) => {
      if (!templateQueryKey) {
        return;
      }
      const templates = queryClient.getQueryData<Folder[]>(templateQueryKey);
      const filtered = templates?.filter(t => t.id !== deletedTemplateId);
      queryClient.setQueryData(templateQueryKey, filtered);
    },
    onSuccess: () => {
      AnalyticsService.trackEvent('empty page auto-deleted', {});
      dispatch({ type: TEMPLATE_DELETE_BY_ID, meta: { templateId } });
      templateQueryKey && queryClient.invalidateQueries(templateQueryKey);
    },
  });

  const mutationCache = queryClient.getMutationCache();
  React.useEffect(() => {
    const setPublishedTrue = () => setPublished(true);
    const unsubscribe = mutationCache.subscribe(
      makeMutationListener({
        keys: KEYS,
        onSuccess: setPublishedTrue,
        onLoading: setPublishedTrue,
      }),
    );
    return () => {
      unsubscribe();
    };
  });

  const shouldAutoDelete =
    !published && !pageQueryIsLoading && isEmptyPage(template, widgets) && templateRevision?.revision === 1 && editable;

  return useMemo(
    () => ({
      shouldAutoDelete,
      deleteTemplate,
    }),
    [shouldAutoDelete, deleteTemplate],
  );
}

function isEmptyPage(template: Template | undefined, widgets: PagesWidgetElement<Widget>[]) {
  return (
    template?.name === DEFAULT_PAGE_NAME &&
    widgets &&
    (widgets.length === 0 || (widgets.length === 1 && (widgets[0].widget as TextWidget)?.content === '<p></p>'))
  );
}
