import { BLVD_SELECT_MAGIC_GRID_AREA } from 'components/design/BlvdSelect';
import { Box, Text } from 'components/design/next';
import * as React from 'react';
import { SingleValueProps } from 'react-select';
import { DataSetDropdownOptionType } from './data-sets-dropdown';

export const DataSetDropdownSingleValue: React.FC<
  React.PropsWithChildren<SingleValueProps<DataSetDropdownOptionType, false>>
> = ({ data }) => {
  return (
    <Text
      color="gray.600"
      maxW="calc(100% - 10px)"
      minW="max-content"
      noOfLines={1}
      gridArea={BLVD_SELECT_MAGIC_GRID_AREA}
    >
      <Box data-testid="dropdown-data-set-name" as="span" fontWeight={data.type === 'DataSet' ? '600' : 'normal'}>
        {data.dataSet.name}
      </Box>

      {data.type === 'DataSet' && data.dataSet.savedViews.length > 0 && (
        <Box ml="1" as="span" color="gray.600">
          ({data.dataSet.savedViews.length})
        </Box>
      )}

      {data.type === 'SavedView' && (
        <>
          {' / '}
          <Box data-testid="dropdown-saved-view-name" as="span" color="gray.600" fontWeight="600">
            {data.label}
          </Box>
        </>
      )}
    </Text>
  );
};
