import angular from 'angular';
import './image-spinner.scss';

angular.module('frontStreetApp.directives').directive('psImageSpinner', () => ({
  restrict: 'A',
  link(__scope, element) {
    const display = element.css('display');
    element.hide();
    const spinner = angular.element(
      '<span class="image-spinner-area"><span class="image-spinner-dark-bg"></span></span>',
    );
    spinner.insertAfter(element);
    element.on('load', () => {
      spinner.remove();
      element.css({
        display,
      });
    });
  },
}));
