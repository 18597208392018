import { SessionSelector } from 'reducers/session/session.selectors';
import { selectedOrganizationMembershipWithUsersNoSystemGroupsSelector } from 'reducers/group/group.selectors';
import { TemplateTaskAssignmentSelector } from 'components/template-task-assignment/store/template-task-assignment.selector';
import { connectController } from 'reducers/util';
import { RoleAssignmentRuleSelector } from 'components/role-assignments/store/role-assignment-rules.selector';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import uniqWith from 'lodash/uniqWith';
import { TaskAssignmentRuleUtils } from '@process-street/subgrade/role-assignment';
import templateUrl from './template-task-assignments.component.html';
import { queryClient } from 'components/react-root';
import { GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery } from 'features/task-template-assignment/query-builder';
import { GetTaskAssignmentRulesByTemplateRevisionIdQuery } from 'features/task-assignment-rules/query-builder';

export const TemplateTaskAssignmentsComponent = {
  bindings: {
    user: '<',
    bulk: '<',
    avatarSize: '<',
    templateRevisionId: '<',
    taskTemplate: '<',
    taskTemplates: '<',
    unassignable: '<',
    setHasAssignments: '&',
    showMultiple: '<',
  },
  templateUrl,
  controller: class TemplateTaskAssignmentsComponent {
    constructor(
      $ngRedux,
      TemplateTaskAssignmentActions,
      OrganizationMembershipActions,
      RoleAssignmentRulesActions,
      UserService,
      WidgetActions,
    ) {
      'ngInject';

      this.$ngRedux = $ngRedux;
      this.UserService = UserService;
      this.TemplateTaskAssignmentActions = TemplateTaskAssignmentActions;
      this.OrganizationMembershipActions = OrganizationMembershipActions;
      this.RoleAssignmentRulesActions = RoleAssignmentRulesActions;
      this.UserService = UserService;
      this.WidgetActions = WidgetActions;
    }

    $onInit() {
      const mapStateToThis = () => state => {
        const selectedOrganizationId = SessionSelector.getSelectedOrganizationId(state);
        const organizationMemberships = selectedOrganizationMembershipWithUsersNoSystemGroupsSelector(state);
        const allTaskAssignments = TemplateTaskAssignmentSelector.getTaskTemplateIdAssignmentsMapByTemplateRevisionId(
          this.templateRevisionId,
        )(state);

        const assignees = this.resolveAssignees(allTaskAssignments, organizationMemberships);

        const allAssignmentRules = RoleAssignmentRuleSelector.getAllByTemplateRevisionId(this.templateRevisionId)(
          state,
        );

        const assignmentRules = this.resolveAssignmentRules(allAssignmentRules);

        const uniqueRules = this._extractUniqueTypedRules(assignmentRules);

        const emailAndMembersFieldWidgets = WidgetSelector.getAllEmailAndMembersWidgetsByTemplateRevisionId(
          this.templateRevisionId,
        )(state);

        const hasAssignments = assignees.length > 0 || assignmentRules.length > 0;
        this.setHasAssignments({ hasAssignments });

        return {
          selectedOrganizationId,
          organizationMemberships,
          allTaskAssignments,
          assignees,
          assignmentRules,
          emailAndMembersFieldWidgets,
          uniqueRules,
        };
      };

      const mapDispatchToThis = () => ({
        getAllTemplateTaskAssignments: this.TemplateTaskAssignmentActions.getAllByTemplateRevisionId,
        deleteTemplateTaskAssignment: this.TemplateTaskAssignmentActions.deleteTemplateTaskAssignment,
        deleteAllTemplateTaskAssignments: this.TemplateTaskAssignmentActions.deleteAllTemplateTaskAssignments,
        getAllOrgMembershipByOrgId: this.OrganizationMembershipActions.getAllOrgMembershipByOrgId,
        geAllRulesByTemplateRevisionId: this.RoleAssignmentRulesActions.getAllByTemplateRevisionId,
        deleteRule: this.RoleAssignmentRulesActions.deleteRule,
        deleteAllRules: this.RoleAssignmentRulesActions.deleteAll,
        getAllWidgetsByTemplateRevisionId: this.WidgetActions.getAllByTemplateRevisionId,
      });

      connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, this.shouldChange)(this);

      this.actions.getAllOrgMembershipByOrgId(this.state.selectedOrganizationId);
      this.actions.getAllTemplateTaskAssignments(this.templateRevisionId);
      this.actions.getAllWidgetsByTemplateRevisionId(this.templateRevisionId);
      this.actions.geAllRulesByTemplateRevisionId(this.templateRevisionId);
    }

    shouldChange = changes => {
      if (!this.bulk) {
        return changes.taskTemplate && changes.taskTemplate.currentValue;
      } else {
        return changes.taskTemplates && changes.taskTemplates.currentValue;
      }
    };

    resolveAssignees = (allTaskAssignments, organizationMemberships) => {
      if (!this.bulk && this.taskTemplate) {
        const taskAssignments = allTaskAssignments[this.taskTemplate.id] || [];
        const assignedMembershipIds = taskAssignments.map(assignment => assignment.organizationMembership.id);

        return organizationMemberships.filter(om => assignedMembershipIds.includes(om.id)).map(om => om.user);
      } else if (this.taskTemplates) {
        return this._getAssigneesForAllSelectedTaskTemplates(organizationMemberships, allTaskAssignments);
      } else {
        return [];
      }
    };

    resolveAssignmentRules = allAssignmentRules => {
      if (!this.bulk && this.taskTemplate) {
        return allAssignmentRules.filter(r => r.targetTaskTemplateGroup.id === this.taskTemplate.group.id);
      } else if (this.taskTemplates) {
        const groupIds = this.taskTemplates.map(tt => tt.group.id);

        return allAssignmentRules.filter(r => groupIds.includes(r.targetTaskTemplateGroup.id));
      } else {
        return [];
      }
    };

    _extractUniqueTypedRules = rules => uniqWith(rules, this._areRulesSameType);

    _areRulesSameType = TaskAssignmentRuleUtils.areRulesSameType;

    _getAssigneesForAllSelectedTaskTemplates(organizationMemberships, taskAssignmentsMap) {
      const organizationMembershipMap = organizationMemberships.reduce((map, om) => {
        map[om.id] = om;

        return map;
      }, {});

      let assignees = this.taskTemplates.reduce((assigneesList, taskTemplate) => {
        const templateTaskAssignments = taskAssignmentsMap[taskTemplate.id] || [];

        templateTaskAssignments.forEach(tta => {
          const membership = organizationMembershipMap[tta.organizationMembership.id];

          if (membership) {
            assigneesList.push(membership.user);
          }
        });

        return assigneesList;
      }, []);

      // We need only unique users
      assignees = assignees.filter((user, i, users) => users.indexOf(user) === i);
      return assignees;
    }

    unassignUser = user => {
      if (!this.bulk) {
        const userOrganizationMembership = this.state.organizationMemberships.find(om => om.user.id === user.id);
        const taskAssignments = this.state.allTaskAssignments[this.taskTemplate.id] || [];
        const assignmentToRemove = taskAssignments.find(
          ta => ta.organizationMembership.id === userOrganizationMembership.id,
        );

        if (assignmentToRemove) {
          this.actions
            .deleteTemplateTaskAssignment(
              this.taskTemplate.id,
              user.email,
              this.taskTemplate.templateRevision.id,
              assignmentToRemove.id,
              this.UserService.getLabel(user),
            )
            .then(() => {
              queryClient.setQueryData(
                GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery.getKey({
                  templateRevisionId: this.templateRevisionId,
                }),
                current => current?.filter(ta => ta.id !== assignmentToRemove.id) ?? [],
              );
            });
        }
      } else {
        this.actions
          .deleteAllTemplateTaskAssignments(
            this.templateRevisionId,
            this.taskTemplates,
            user,
            this.UserService.getLabel(user),
          )
          .then(() => {
            // invalidate because we don't have the bulk response to set query data
            queryClient.invalidateQueries(
              GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery.getKey({
                templateRevisionId: this.templateRevisionId,
              }),
            );
          });
      }
    };

    removeRule = typedRule => {
      if (!this.bulk) {
        // in a single case, type rule is the actual rule
        this.actions.deleteRule(this.templateRevisionId, typedRule.id).then(() => {
          queryClient.setQueryData(
            GetTaskAssignmentRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId: this.templateRevisionId }),
            current => current?.filter(r => r.id !== typedRule.id) ?? [],
          );
        });
      } else {
        const ruleIds = this.state.assignmentRules.filter(r => this._areRulesSameType(r, typedRule)).map(r => r.id);
        this.actions.deleteAllRules(this.templateRevisionId, ruleIds).then(() => {
          // invalidate because we don't have the bulk response to set query data
          queryClient.invalidateQueries(
            GetTaskAssignmentRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId: this.templateRevisionId }),
          );
        });
      }
    };
  },
};
