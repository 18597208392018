import { FormFieldWidget, TaskTemplate, MembersFormFieldWidget, Template } from '@process-street/subgrade/process';
import { UpdateWidgetMutation, WidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { ActorRefFrom, assign, createMachine, send, sendParent, spawn } from 'xstate';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { FormFieldLabelActor, makeFormFieldLabelMachine } from '../common/form-field-label';
import { match } from 'ts-pattern';
import { WidgetEvent } from '../../../types';
import { SharedContext } from 'pages/forms/_id/shared';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { makeDeleteWidgetMutation } from '../common/mutations/make-delete-widget-mutation';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { WidgetMachineHelpers } from '../../../helpers/machine';

type Context = {
  widget: MembersFormFieldWidget;
  initialWidget: MembersFormFieldWidget;
  template: Template;
  labelActor: FormFieldLabelActor<MembersFormFieldWidget>;
  recentlyMovedFrom?: TaskTemplate;
  inputNode: HTMLElement | null;
  isReadOnly?: boolean;
};

type Event =
  | WidgetEvent<MembersFormFieldWidget>
  | { type: 'AUTO_FOCUS' }
  // Internal type
  | { type: 'done.invoke.updateWidgetMutation'; data: MembersFormFieldWidget };

export type MembersFormFieldMachine = ReturnType<typeof makeMembersFormFieldMachine>;
export type MembersFormFieldActor = ActorRefFrom<MembersFormFieldMachine>;

export const makeMembersFormFieldMachine = ({
  widget,
  template,
  sharedContext,
  isReadOnly = false,
}: {
  widget: MembersFormFieldWidget;
  template: Template;
  sharedContext: SharedContext;
  isReadOnly?: boolean;
}) => {
  const { queryClient, templateId } = sharedContext;
  const id = WidgetMachineHelpers.getId(widget);

  const cacheSetter = WidgetsByTemplateRevisionIdQuery.makeCacheSetter({
    queryClient,
    templateRevisionId: (widget as FormFieldWidget).templateRevision?.id,
  });

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  return createMachine(
    {
      id,
      initial: isReadOnly ? 'viewing' : 'idle',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      tsTypes: {} as import('./members-form-field-machine.typegen').Typegen0,
      context: () =>
        ({
          widget,
          initialWidget: widget,
          template,
          labelActor: spawn(makeFormFieldLabelMachine<MembersFormFieldWidget>({ widget, queryClient })),
          recentlyMovedFrom: undefined,
          inputNode: null,
          isReadOnly,
        } as Context),
      states: {
        idle: {
          on: {
            DELETE_WIDGET: { target: 'deleting' },
            MOVE_DOWN: { actions: ['sendMoveDown'] },
            MOVE_UP: { actions: ['sendMoveUp'] },
            DUPLICATE: { actions: ['sendDuplicate'] },
            MOVE_TO_STEP: { actions: ['sendMoveToStep'] },
            MOVED_FROM_STEP: { actions: ['assignRecentlyMovedFrom'] },
            UPDATE_WIDGET_HEADER: { actions: ['assignHeader'] },
            SET_WIDGET_LABEL: { actions: ['assignLabel'] },
            SET_NODE: { actions: ['assignNode'] },
            SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
          },
        },
        viewing: {},
        editing: {},
        saving: {
          invoke: [
            {
              id: 'updateWidgetMutation',
              src: 'updateWidgetMutation',
              onDone: {
                target: 'idle',
                actions: ['assignWidget', 'sendUpdateMergeTags', 'assignInitialWidget', 'sendUpdateDone'],
              },
              onError: {
                target: 'error',
                actions: ['logError', 'resetWidget', 'sendUpdateError'],
              },
            },
          ],
        },
        deleting: {
          invoke: [
            {
              id: 'deleteWidget',
              src: 'deleteWidget',
              onDone: { target: 'deleted' },
              onError: { target: 'idle', actions: 'logError' },
            },
          ],
        },
        deleted: {
          type: 'final',
        },
        error: {},
      },
      on: {
        AUTO_FOCUS: { actions: ['sendAutoFocus'] },
        UPDATE_WIDGET: { target: 'saving', actions: ['assignWidget'] },
      },
    },
    {
      actions: {
        logError: makeErrorLoggerAction(id),
        assignWidget: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET' }, ({ widget }) => widget)
              .with({ type: 'done.invoke.updateWidgetMutation' }, ({ data }) => data)
              .otherwise(() => ctx.widget),
        }),
        assignInitialWidget: assign({
          initialWidget: (_, e) => e.data,
        }),
        sendUpdateMergeTags: sendParent(ctx => ({
          type: 'UPDATE_MERGE_TAGS_REFERENCES',
          widget: ctx.widget,
          oldKey: ctx.initialWidget.key,
        })),
        resetWidget: assign({
          widget: context => context.initialWidget,
        }),
        sendMoveUp: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'up',
        })),
        sendMoveDown: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'down',
        })),
        sendDuplicate: sendParent(ctx => ({
          type: 'DUPLICATE_WIDGET',
          widget: ctx.widget,
        })),
        sendAutoFocus: send({ type: 'AUTO_FOCUS' }, { to: ctx => ctx.labelActor }),
        sendUpdateDone: send(ctx => ({ type: 'UPDATE_DONE', data: ctx.widget }), { to: ctx => ctx.labelActor }),
        sendUpdateError: send({ type: 'UPDATE_ERROR' }, { to: ctx => ctx.labelActor }),
        sendMoveToStep: sendParent((ctx, e) => ({
          type: 'MOVE_WIDGET_TO_STEP',
          widget: ctx.widget,
          from: e.from,
          to: e.to,
        })),
        assignHeader: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET_HEADER' }, ({ header }) => ({
                ...ctx.widget,
                header: {
                  ...ctx.widget.header,
                  ...{ ...(header as MembersFormFieldWidget['header']) },
                },
              }))
              .otherwise(() => ctx.widget),
        }),
        assignLabel: send((_ctx, e) => ({ type: 'CHANGE', value: e.label }), { to: ctx => ctx.labelActor }),
        assignNode: assign({ inputNode: (_, evt) => evt.node }),
        scrollIntoView: ctx => {
          WidgetMachineHelpers.scrollToWidget(ctx.widget);
        },
        assignRecentlyMovedFrom: assign({
          recentlyMovedFrom: (_ctx, e) =>
            match(e)
              .with({ type: 'MOVED_FROM_STEP' }, ({ from }) => ({
                ...from,
              }))
              .otherwise(() => undefined),
        }),
      },
      services: {
        updateWidgetMutation: async (context, evt) => {
          const widget = match(evt)
            .with({ type: 'UPDATE_WIDGET' }, ({ widget }) => widget)
            .otherwise(() => context.widget);
          return makeMutation(queryClient, {
            mutationKey: UpdateWidgetMutation.getKey(),
            mutationFn: () => UpdateWidgetMutation.mutationFn<MembersFormFieldWidget>(widget),
            onSuccess: widget => {
              cacheSetter.update(widget);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },
        deleteWidget: ({ widget }) =>
          makeDeleteWidgetMutation({
            widget,
            queryClient,
            templateId,
          }),
      },
    },
  );
};
