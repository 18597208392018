export const FolderApi = function (Folders) {
  'ngInject';

  const getById = folderId => Folders.getById({ id: folderId }).$promise;

  const getAllByOrganizationId = (organizationId, permission) =>
    Folders.getAllByOrganizationId({ organizationId, permission }).$promise;

  const update = (id, name) => Folders.update({ id, name }).$promise;

  const deleteById = id => Folders.deleteById({ id }).$promise;

  return {
    deleteById,
    getById,
    getAllByOrganizationId,
    update,
  };
};
