import { useToast } from 'components/design/next';
import * as React from 'react';
import { AblyEvent } from 'app/pusher/ably-event';
import { ablyService } from 'app/pusher/ably.service';
import { Muid } from '@process-street/subgrade/core';
import { AiGeneratingWorkflowErrorToast } from './ai-generation-workflow-error-toast';
import { useAiGenerationSlice } from './store';
import { useInjector } from 'components/injection-provider';
import { trace } from 'components/trace';
import { useDeleteTemplate } from 'features/template/query-builder';
import { useTemplateId } from '../../automation/utils/use-template-id';

const FORM_FIELD_GENERATION_TIMEOUT = 50000;

export const useSubscribeToAbly = ({
  templateRevisionId,
  tasksCount,
  templateName,
  onError,
}: {
  templateRevisionId: Muid;
  templateName: string;
  tasksCount: number;
  openModal: () => void;
  onError: () => void;
}) => {
  const toast = useToast();
  const templateId = useTemplateId();
  const { $state } = useInjector('$state');
  const stopGeneration = useAiGenerationSlice(state => state.stopGeneration);
  const taskTemplateUpdatedCountRef = React.useRef(0);
  const timeoutIdRef = React.useRef<NodeJS.Timeout | null>(null);
  const logger = trace({ name: 'useSubscribeToAbly' });
  const deleteTemplateMutation = useDeleteTemplate();

  const handleRetry = React.useCallback(() => {
    $state.go('templateAiGenerate', { name: templateName });
    if (templateId) deleteTemplateMutation.mutate({ templateId });
  }, [$state, templateName, templateId, deleteTemplateMutation]);

  const showErrorToast = React.useCallback(() => {
    toast({
      render: () => {
        return <AiGeneratingWorkflowErrorToast onRetry={handleRetry} />;
      },
    });
  }, [toast, handleRetry]);

  // Subscribes to `AiGenerationError` message
  React.useEffect(() => {
    const channelName = ablyService.getChannelNameForTemplateRevision(templateRevisionId);
    const channel = ablyService.getChannel(channelName);

    const errorListener = (message: { name: string }) => {
      if (message.name !== AblyEvent.EventType.AiGenerationError) return;

      stopGeneration();
      showErrorToast();
      onError();

      logger.error('Got error from Ably', message.name, message);
    };

    channel.subscribe(AblyEvent.EventType.AiGenerationError, errorListener);

    return () => {
      channel.unsubscribe(AblyEvent.EventType.AiGenerationError, errorListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore onError prop change
  }, [showErrorToast, templateRevisionId, stopGeneration]);

  // Indentify timeouts between task generation
  React.useEffect(() => {
    const channelName = ablyService.getChannelNameForTemplateRevision(templateRevisionId);
    const channel = ablyService.getChannel(channelName);

    const listener = () => {
      taskTemplateUpdatedCountRef.current = taskTemplateUpdatedCountRef.current + 1;
      const remaining = tasksCount - taskTemplateUpdatedCountRef.current;

      logger.info(`${taskTemplateUpdatedCountRef.current} out of ${tasksCount} tasks were loaded.`);

      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }

      if (remaining > 0) {
        timeoutIdRef.current = setTimeout(() => {
          if (taskTemplateUpdatedCountRef.current < tasksCount) {
            logger.info(
              `No form fields were received in the last ${
                FORM_FIELD_GENERATION_TIMEOUT / 1000
              }s. The generation will be aborted.`,
            );

            stopGeneration();
            showErrorToast();
            onError();
          }
        }, FORM_FIELD_GENERATION_TIMEOUT);
      }
      // No need to set the timeout once all the tasks are finished
      else {
        logger.info('All the form fields where generated.');
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
          timeoutIdRef.current = null;
        }

        return;
      }
    };

    channel.subscribe(AblyEvent.EventType.TaskTemplateUpdated, listener);

    return () => {
      channel.unsubscribe(AblyEvent.EventType.TaskTemplateUpdated, listener);
      logger.info('UNSUBSCRIBING');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore onError prop change
  }, [templateRevisionId, tasksCount]);
};
