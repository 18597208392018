import * as React from 'react';

export const useMoveElevioLauncher = (isOpen: boolean) => {
  React.useEffect(
    function addClassNameToLauncherElement() {
      if (isOpen) {
        const launcherElement = document.querySelector('._elevio_launcher');
        if (launcherElement) {
          // Add class name to override styles of the launcher element
          launcherElement.classList.add('_elev_io__chakra-drawer--open');
        }
      }

      return () => {
        const launcherElement = document.querySelector('._elevio_launcher');
        if (launcherElement) {
          launcherElement.classList.remove('_elev_io__chakra-drawer--open');
        }
      };
    },
    [isOpen],
  );
};
