import * as React from 'react';
import { VStack, HStack, Icon, IconButton, Circle, Text, Divider, StackProps, Tooltip } from 'components/design/next';
import { useFormEditorPageActorRef } from '../../form-editor-page-machine';
import { useSelector } from '@xstate/react';
import { NgLink } from 'features/app/components/ng-link';
import { useStateParam } from 'hooks/use-state-param';
import { TaskTemplate, TemplateType } from '@process-street/subgrade/process';
import { DeleteTaskTemplateAlertDialog } from './delete-task-template-alert-dialog';
import { getStepName } from '../../helpers/steps';
import _isEqual from 'lodash/isEqual';
import { FormEditorPageActorSelectors } from '../../form-editor-page-machine/form-editor-page-machine-selectors';

export interface FormEditorBottomBarProps extends StackProps {}

export const FormEditorBottomBar: React.FC<React.PropsWithChildren<FormEditorBottomBarProps>> = props => {
  const groupId = useStateParam({ key: 'groupId' });

  const actor = useFormEditorPageActorRef();
  const taskTemplates = useSelector(actor, FormEditorPageActorSelectors.getTaskTemplates);
  const promptingDeleteTaskTemplateActor = useSelector(
    actor,
    FormEditorPageActorSelectors.getPromptingDeleteTaskTemplateActor,
    _isEqual,
  );

  const lastTaskIndex = taskTemplates.length - 1;

  const addStep = () => {
    actor.send('CREATE_TASK_TEMPLATE');
  };

  return (
    <>
      <VStack w="full" {...props}>
        <HStack
          spacing="0"
          overflowX="auto"
          h="full"
          px="4"
          maxW={{ base: 'full', lg: '788px' }}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="20"
          bg="brand.50"
        >
          {taskTemplates.map((taskTemplate, index) => (
            <TaskStepButton
              taskTemplate={taskTemplate}
              key={taskTemplate.id}
              isSelected={groupId === taskTemplate.group.id}
              isLast={index === lastTaskIndex}
              stepNumber={index + 1}
            />
          ))}

          <HStack spacing="0">
            {taskTemplates.length > 0 ? (
              <Divider orientation="horizontal" w="6" borderWidth="1px" borderColor={'gray.300'} borderStyle="dashed" />
            ) : null}
            <IconButton
              minW="7"
              h="7"
              w="7"
              variant="solid"
              colorScheme="gray"
              aria-label="add step"
              icon={<Icon icon="plus" size="4" />}
              onClick={addStep}
            />
          </HStack>
        </HStack>
      </VStack>

      {promptingDeleteTaskTemplateActor ? (
        <DeleteTaskTemplateAlertDialog
          templateType={TemplateType.Form}
          onCancel={() => promptingDeleteTaskTemplateActor?.send('CANCEL_DELETE_TASK_TEMPLATE')}
          onConfirm={() => promptingDeleteTaskTemplateActor?.send('CONFIRM_DELETE_TASK_TEMPLATE')}
        />
      ) : null}
    </>
  );
};

const TaskStepButton: React.FC<
  React.PropsWithChildren<{
    taskTemplate: TaskTemplate;
    isSelected: boolean;
    isLast: boolean;
    stepNumber: number;
  }>
> = ({ taskTemplate, isSelected, isLast, stepNumber }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (isSelected) {
      ref.current?.scrollIntoView();
    }
  }, [isSelected]);

  const stepName = getStepName({ taskTemplate, number: stepNumber });

  return (
    <HStack spacing="0" ref={ref}>
      <NgLink
        aria-label={stepName}
        to="form.step"
        params={{ groupId: taskTemplate.group.id }}
        key={taskTemplate.id}
        _hover={{}}
      >
        <Tooltip label={stepName} hasArrow placement="top">
          <Circle
            size="7"
            py="1"
            position="relative"
            role="group"
            {...(isSelected
              ? { bgColor: 'brand.500', color: 'white', _hover: { cursor: 'default' } }
              : {
                  bgColor: 'white',
                  border: '1px solid',
                  borderColor: 'gray.300',
                  color: 'gray.500',
                  _hover: { bgColor: 'gray.50' },
                })}
          >
            <Text variant="-1" fontWeight="medium">
              {stepNumber}
            </Text>
          </Circle>
        </Tooltip>
      </NgLink>
      {isLast ? null : <Divider w="6" orientation="horizontal" borderWidth="1px" borderColor={'gray.300'} />}
    </HStack>
  );
};
