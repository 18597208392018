import * as React from 'react';
import {
  IncomingWebhookChecklistConfigKey,
  IncomingWebhookChecklistConfigKeys,
  SolutionTypeTag,
  withIncomingWebhookPrefix,
} from '@process-street/subgrade/automation';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Spacer,
  Square,
  Text,
  Tooltip,
  useToast,
  VStack,
} from 'components/design/next';
import { useCopyToClipboard } from 'react-use';
import {
  GetAllIncomingWebhooksByDataSetIdQuery,
  GetAllIncomingWebhooksByDataSetIdQueryResponse,
  GetAllIncomingWebhooksByTemplateIdQuery,
  GetAllIncomingWebhooksByTemplateIdQueryResponse,
  useGetAllIncomingWebhooksByDataSetIdQuery,
  useGetAllIncomingWebhooksByTemplateIdQuery,
  useUpdateIncomingWebhookMutation,
} from 'features/automations/query-builder';
import { Muid } from '@process-street/subgrade/core';
import { useQueryClient } from 'react-query';
import { getEnv } from 'components/common/env';
import { useGetIncomingWebhookJsonPathsQuery } from 'features/automations/query-builder/get-incoming-webhook-json-paths-query';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { FieldType, FormFieldWidget, isFormFieldWidget } from '@process-street/subgrade/process';
import { useGetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template-revisions/query-builder';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { ActionMeta, GroupBase, OnChangeValue, SelectComponentsConfig } from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import {
  makeIncomingWebhookEditorMachine,
  State as IncomingWebhookEditorState,
} from './incoming-webhook-editor-machine';
import {
  getUseIncomingWebhookForm,
  isChecklistMapping,
  isDataSetMapping,
  isFormFieldMapping,
  Mapping,
} from './form-store';
import { useMachine } from '@xstate/react';
import { PathOption } from './components/path-option';
import {
  ChecklistOrFormFieldOrColumnDefinitionOption as TChecklistOrFormFieldOption,
  ChecklistOrFormFieldOrColumnDefinitionOption,
  checklistPropertyToChecklistOption,
  dataSetColumnToOption,
  formFieldWidgetToFormFieldOption,
  KeyColumnOption,
  PathOption as TPathOption,
  pathToPathOption,
} from './utils';
import { match, P } from 'ts-pattern';
import { Option } from 'space-monad';
import { ChecklistOrFormFieldOption } from './components/checklist-or-form-field-option';
import { PathSingleValue } from './components/path-single-value';
import { useTemplateId } from '../../../utils/use-template-id';
import { AutomationAppSquare } from 'pages/templates/_id/components/automation-app-square';
import { FeaturedIncomingWebhooks } from 'features/automations/featured-incoming-webhook-apps';
import jsonpath from 'jsonpath';
import { useAutomationSelector } from '../../selector/context';
import { useDelayedTriggerTest } from './use-delayed-trigger-test';
import { useDataSetId } from '../../../utils/use-data-set-id';
import { trace } from 'components/trace';
import { GetAllDataSetsQuery } from 'pages/reports/data-sets/query-builder';
import { useWebhookType } from './use-webhook-type';
import { NoOptionsMessage } from './components/no-options-message';

export type IncomingWebhookEditorProps = {
  webhookId: Muid;
  initialState?: IncomingWebhookEditorState['value'];
};

const logger = trace({ name: 'IncomingWebhookEditor' });

export const IncomingWebhookEditor: React.FC<IncomingWebhookEditorProps> = ({
  webhookId,
  initialState = 'initial',
}) => {
  const templateId = useTemplateId();
  const dataSetId = useDataSetId();
  const toast = useToast();
  const [machineValue, automationMachineSend] = useAutomationSelector();

  const { hasUnsavedChanges } = machineValue.context;

  const webhookType = useWebhookType();

  const machine = React.useRef<ReturnType<typeof makeIncomingWebhookEditorMachine>>();
  if (!machine.current) {
    machine.current = makeIncomingWebhookEditorMachine({ initial: initialState });
  }
  const [webhookMachineState, webhookMachineSend] = useMachine(machine.current);

  React.useEffect(() => {
    automationMachineSend({
      type: 'AUTOMATION_SELECTED',
      payload: {
        id: webhookId,
        automationType: 'webhook',
        solutionTypeTag: webhookType === 'DataSet' ? SolutionTypeTag.WhenChecklistCompletedThen : undefined,
      },
    });
  }, [webhookId, automationMachineSend, webhookType]);

  const webhookURL = match(getEnv())
    .with(
      { APP_WEBHOOK_URL: P.string },
      ({ APP_WEBHOOK_URL }) => `${APP_WEBHOOK_URL}/incoming-webhooks/enqueue/${webhookId}`,
    )
    .otherwise(({ APP_API_URL }) => `${APP_API_URL}/1/incoming-webhooks/${webhookId}`);

  const [{ value: copiedWebhookURL }, copyWebhookURLToClipboard] = useCopyToClipboard();
  const urlWasCopied = Boolean(copiedWebhookURL);

  const templateWebhookQuery = useGetAllIncomingWebhooksByTemplateIdQuery(
    { templateId: templateId ?? '' },
    {
      enabled: Boolean(templateId && webhookId),
      select: webhooks => webhooks.find(webhook => webhook.id === webhookId),
    },
  );
  const dataSetWebhookQuery = useGetAllIncomingWebhooksByDataSetIdQuery(
    { dataSetId: dataSetId ?? '' },
    {
      enabled: Boolean(dataSetId && webhookId),
      select: webhooks => webhooks.find(webhook => webhook.id === webhookId),
    },
  );
  const dataSetQuery = GetAllDataSetsQuery.useQuery({
    select: dataSet => dataSet.find(dataSet => dataSet.id === dataSetId),
    enabled: Boolean(dataSetId),
  });

  const webhook = templateWebhookQuery.data ?? dataSetWebhookQuery.data;

  // Strategy used for per-component zustand stores
  // https://github.com/pmndrs/zustand/issues/128
  // https://github.com/pmndrs/zustand/issues/101#issuecomment-673452082
  const [useForm] = React.useState(() => getUseIncomingWebhookForm());
  const form = useForm();

  React.useEffect(() => {
    if (!form.isHydrated && webhook) {
      form.hydrate(webhook);
    }
  });

  const queryClient = useQueryClient();

  const updateWebhookMutation = useUpdateIncomingWebhookMutation({
    onSuccess: webhook => {
      automationMachineSend({ type: 'SET_HAS_UNSAVED_CHANGES', hasUnsavedChanges: false });

      toast({
        title: 'Webhook saved',
        status: 'success',
      });

      match(webhook)
        .with({ webhookType: 'DataSet' }, webhook => {
          queryClient.setQueryData<GetAllIncomingWebhooksByDataSetIdQueryResponse>(
            GetAllIncomingWebhooksByDataSetIdQuery.getKey({ dataSetId: webhook.dataSetId }),
            current => current?.map(w => (w.id === webhook.id ? webhook : w)) ?? [webhook],
          );
        })
        .with({ webhookType: 'Workflow' }, webhook => {
          queryClient.setQueryData<GetAllIncomingWebhooksByTemplateIdQueryResponse>(
            GetAllIncomingWebhooksByTemplateIdQuery.getKey({ templateId: webhook.templateId }),
            current => current?.map(w => (w.id === webhook.id ? webhook : w)) ?? [webhook],
          );
        })
        .otherwise(() => {});
    },
    onError: error => {
      toast({
        title: `We're having problems saving the webhook`,
        status: 'error',
        description: match(error.response?.data)
          .with({ message: 'Key column contains duplicated values' }, data => data.message)
          .otherwise(() => undefined),
      });
    },
  });

  const handleWebhookNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    useForm.setState({ name: e.target.value });
    automationMachineSend({ type: 'SET_HAS_UNSAVED_CHANGES', hasUnsavedChanges: true });
  };

  const pathsQuery = useGetIncomingWebhookJsonPathsQuery(
    { webhookId },
    {
      enabled: false,
      onSettled: result => {
        webhookMachineSend({ type: 'TEST_RECEIVED', result });
      },
    },
  );

  if (webhookMachineState.matches('initial')) {
    pathsQuery.refetch();
  }

  const templateRevisionQuery = useGetNewestTemplateRevisionsByTemplateIdQuery(
    { templateId: templateId ?? '' },
    { enabled: Boolean(templateId) },
  );
  const templateRevisionId = templateRevisionQuery.data?.[0].id;
  const formFieldWidgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevisionId ?? '', {
    enabled: Boolean(templateRevisionId),
  });
  const formFields =
    formFieldWidgetsQuery.data?.filter((widget): widget is FormFieldWidget => {
      return (
        isFormFieldWidget(widget) &&
        ![FieldType.SendRichEmail, FieldType.File, FieldType.Snippet].includes(widget.fieldType)
      );
    }) ?? [];

  const handleSave = () => {
    match({ dataSetId, templateId })
      .with({ dataSetId: P.not(P.nullish) }, ({ dataSetId }) => {
        updateWebhookMutation.mutate({
          dataSetId,
          id: webhookId,
          name: form.name,
          config: {
            dataSetColumns: Object.fromEntries(
              form.mappings
                .filter(isDataSetMapping)
                .filter(cm => Boolean(cm.id && cm.property && cm.path))
                .map(cm => [cm.property, cm.path!]),
            ),
            dataSetKeyColumn: form.keyColumn,
          },
          webhookType: 'DataSet',
        });
      })
      .with({ templateId: P.string }, ({ templateId }) => {
        updateWebhookMutation.mutate({
          templateId,
          id: webhookId,
          name: form.name,
          config: {
            checklistProperties: Object.fromEntries(
              form.mappings
                .filter(isChecklistMapping)
                .filter(cm => Boolean(cm.id && cm.property && cm.path))
                .map(cm => [cm.property, cm.path]),
            ),
            fields: Object.fromEntries(
              form.mappings
                .filter(isFormFieldMapping)
                .filter(ffm => Boolean(ffm.id && ffm.formFieldWidgetHeaderGroupId && ffm.path))
                .map(ffm => [ffm.formFieldWidgetHeaderGroupId, ffm.path]),
            ),
          },
          webhookType: 'Workflow',
        });
      })
      .otherwise(() => {
        logger.info('`templateId` and `dataSetId` are missing.');
      });
  };

  const featuredEvents = match(webhook)
    .with({ automationApp: P.not('Webhook'), webhookType: P.not('DataSet') }, webhook =>
      FeaturedIncomingWebhooks.eventsByApp[withIncomingWebhookPrefix(webhook.automationApp)].filter(
        e => e.event !== '' && e.samplePayload,
      ),
    )
    .otherwise(() => []);

  const [eventName, setEventName] = React.useState<string>('');

  const shouldShowFeaturedEvents = (pathsQuery.data?.paths.length ?? 0) === 0 && featuredEvents.length > 0;

  const samplePayload = match({ data: pathsQuery.data, eventName, webhook })
    .with({ data: P.not(P.nullish) }, ({ data }) => data.payload)
    .with(
      { eventName: P.not(''), webhook: P.not(P.nullish) },
      ({ eventName, webhook }) =>
        FeaturedIncomingWebhooks.eventsByApp[withIncomingWebhookPrefix(webhook.automationApp)].find(
          evt => evt.event === eventName,
        )?.samplePayload,
    )
    .otherwise(() => undefined);

  const payloadPathOptions = (
    pathsQuery.data?.paths ??
    (samplePayload ? jsonpath.paths(samplePayload ?? {}, '$..*').map(paths => jsonpath.stringify(paths)) : [])
  ).map(path => pathToPathOption(path));

  const checklistPropertyOptions = IncomingWebhookChecklistConfigKeys.filter(
    // remove properties that have already been mapped
    key => !form.mappings.some(mapping => mapping.type === 'checklist' && mapping.property === key),
  ).map(checklistPropertyToChecklistOption);

  const unmappedFormFields = formFields.filter(
    formField =>
      !form.mappings.some(
        mapping => mapping.type === 'formField' && mapping.formFieldWidgetHeaderGroupId === formField.header.group.id,
      ),
  );

  const formFieldWidgetOptions = unmappedFormFields
    .filter(formField => formField.fieldType !== FieldType.MultiSelect)
    .map(formFieldWidgetToFormFieldOption);

  const subTaskOptions = unmappedFormFields
    .filter(formField => formField.fieldType === FieldType.MultiSelect)
    .map(formFieldWidgetToFormFieldOption);

  const dataSetColumnOptions = (dataSetQuery.data?.columnDefs ?? []).map(dataSetColumnToOption);

  const mappingOptions: ReadonlyArray<GroupBase<TChecklistOrFormFieldOption>> = match(dataSetId)
    .with(P.string, () => [{ label: 'Columns', options: dataSetColumnOptions }])
    .otherwise(() => [
      {
        label: 'Workflow Run Metadata',
        options: checklistPropertyOptions,
      },
      {
        label: 'Form Fields',
        options: formFieldWidgetOptions,
      },
      {
        label: 'Sub Tasks',
        options: subTaskOptions,
      },
    ]);

  const availableKeyColumns: KeyColumnOption[] = dataSetColumnOptions
    .filter(column =>
      form.mappings.find(mapping =>
        match(mapping)
          .with({ type: 'dataSet' }, ({ property }) => property === column.value)
          .otherwise(() => false),
      ),
    )
    .map(column => ({
      label: column.label,
      value: column.value,
    }));

  const selectedKeyColumn: KeyColumnOption | undefined = availableKeyColumns.find(km => km.value === form.keyColumn);

  const events = Option(webhook)
    .map(w => FeaturedIncomingWebhooks.eventsByApp[withIncomingWebhookPrefix(w.automationApp)])
    .getOrElse([]);
  const event = Option(events.find(event => event.event === eventName)).getOrElse(events[0]);
  const docsUrl = event?.docsUrl;

  const handleDiscardChanges = () => {
    if (webhook) {
      form.hydrate(webhook);
      automationMachineSend({ type: 'SET_HAS_UNSAVED_CHANGES', hasUnsavedChanges: false });
    }
  };

  const { isLoading: delayedTestIsOn, handleTest: handleRefetch } = useDelayedTriggerTest(pathsQuery);

  const webhookUrlInputRef = React.useRef<HTMLInputElement>(null);
  const isUncopied = webhookMachineState.matches('uncopied');
  const hasBeenSelectedRef = React.useRef(false);
  React.useEffect(() => {
    const node = webhookUrlInputRef.current;
    if (isUncopied && node && !hasBeenSelectedRef.current) {
      node.select();
      hasBeenSelectedRef.current = true;
    }
  }, [isUncopied]);

  return (
    <VStack align="stretch" spacing="8" position="relative">
      <VStack alignItems="flex-start" spacing="6">
        {webhook?.webhookType !== 'DataSet' && webhookMachineState.matches('uncopied') && webhook?.automationApp ? (
          <AutomationAppSquare
            automationApp={withIncomingWebhookPrefix(webhook.automationApp)}
            outerSquareProps={{
              bgColor: 'brand.200',
            }}
          />
        ) : null}

        {!webhookMachineState.matches('editing') && (
          <VStack alignItems="flex-start" spacing="2" w="full">
            <HStack>
              <Text variant="-2u" color="gray.500">
                setup webhook
              </Text>
              <Popover trigger="hover" variant="tooltip-dark">
                <PopoverTrigger>
                  <span>
                    <Icon icon="info-circle" variant="far" size="4" />
                  </span>
                </PopoverTrigger>
                <PopoverContent w="auto">
                  <PopoverArrow />
                  <PopoverBody>
                    <Text>
                      Learn more{' '}
                      <Link href="https://www.process.st/help/docs/webhooks/" isExternal>
                        here
                      </Link>
                      .
                    </Text>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
            {webhook?.webhookType !== 'DataSet' && (
              <Text variant="1">
                You&apos;ll need to configure your{' '}
                {webhook && webhook.automationApp !== 'Webhook' ? `${webhook.automationApp} account` : 'application'}{' '}
                with this Process Street webhook URL.{' '}
                {docsUrl ? (
                  <>
                    You can find more information{' '}
                    <Link textDecor="underline" href={docsUrl} isExternal>
                      here
                    </Link>
                    .
                  </>
                ) : null}
              </Text>
            )}

            {webhook?.webhookType === 'DataSet' && (
              <Text variant="1">
                You’ll need to configure your application with this Process Street webhook URL.
                {docsUrl ? (
                  <>
                    You can find more information{' '}
                    <Link textDecor="underline" href={docsUrl} isExternal>
                      here
                    </Link>
                    .
                  </>
                ) : null}
              </Text>
            )}
          </VStack>
        )}

        <VStack alignItems="flex-start" spacing="4" w="full">
          {webhookType !== 'DataSet' && (
            <FormControl isRequired>
              <FormLabel>Webhook Name</FormLabel>
              <Input
                placeholder="…specify action and app name here"
                value={form.name}
                onChange={handleWebhookNameChange}
              />
            </FormControl>
          )}

          <FormControl>
            <FormLabel>Your Webhook URL</FormLabel>
            <InputGroup
              role="button"
              _hover={{ cursor: 'pointer', bg: 'brand.50' }}
              onClick={() => {
                webhookMachineSend({ type: 'COPIED', hasFeaturedEvents: featuredEvents.length > 0 });
                copyWebhookURLToClipboard(webhookURL);
              }}
            >
              <InputLeftAddon>
                <Icon icon="link" variant="far" size="4" />
              </InputLeftAddon>
              <Input
                isReadOnly
                pointerEvents="none"
                value={webhookURL}
                pr={urlWasCopied ? '22' : '15'}
                ref={webhookUrlInputRef}
              />
              <InputRightElement width={urlWasCopied ? '22' : '15'}>
                <Button
                  size="xs"
                  variant="outline"
                  colorScheme="gray"
                  fontWeight="normal"
                  rightIcon={urlWasCopied ? <Icon icon="check" color="green.500" size="3" variant="far" /> : undefined}
                >
                  {urlWasCopied ? 'Copied!' : 'Copy'}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </VStack>
      </VStack>

      {webhookMachineState.matches('listeningForTest') ? (
        <VStack spacing="3">
          <Square position="relative" size="20">
            <Icon
              position="absolute"
              bottom="0"
              left="0"
              icon="message-captions"
              variant="fad"
              size="10"
              primaryLayer={{ opacity: 1, color: 'brand.500' }}
              secondaryLayer={{ opacity: 1, color: 'brand.200' }}
            />
            <Icon
              position="absolute"
              top="0"
              right="0"
              transform="scaleX(-1)"
              icon="message-captions"
              variant="fad"
              size="10"
              secondaryLayer={{ opacity: 1, color: 'brand.500' }}
              primaryLayer={{ opacity: 1, color: 'brand.200' }}
            />
          </Square>

          <Text variant="1" fontWeight="bold" color="gray.600">
            We&apos;re listening!
          </Text>

          <Text variant="-1" color="gray.600">
            Have your application send a request to the webhook URL, and then test your trigger.
          </Text>

          <ButtonGroup>
            <Button
              variant="secondary"
              onClick={handleRefetch}
              isLoading={pathsQuery.isLoading || delayedTestIsOn}
              loadingText="Testing…"
            >
              Test trigger
            </Button>
            <Button
              variant="ghost"
              fontWeight="normal"
              colorScheme="brand"
              onClick={() => webhookMachineSend('TEST_SKIPPED')}
            >
              Skip
            </Button>
          </ButtonGroup>
        </VStack>
      ) : null}

      {webhookMachineState.matches('testError') || webhookMachineState.matches('editing.testError') ? (
        <VStack spacing="3">
          <Icon
            variant="fad"
            icon="exclamation-circle"
            size="10"
            primaryLayer={{ opacity: 1, color: 'red.500' }}
            secondaryLayer={{ opacity: 1, color: 'red.200' }}
          />
          <Text variant="1" fontWeight="bold" color="gray.600">
            There was an error
          </Text>

          <Text variant="-1" color="gray.600">
            Create a new request and test your trigger again.
          </Text>

          <ButtonGroup>
            <Button
              variant="secondary"
              onClick={handleRefetch}
              isLoading={pathsQuery.isLoading || delayedTestIsOn}
              loadingText="Testing…"
            >
              Test trigger
            </Button>

            <Button
              variant="ghost"
              fontWeight="normal"
              colorScheme="brand"
              onClick={() => webhookMachineSend('TEST_SKIPPED')}
            >
              Skip
            </Button>
          </ButtonGroup>
        </VStack>
      ) : null}

      {shouldShowFeaturedEvents &&
      (webhookMachineState.matches('selectingFeaturedEvent') || webhookMachineState.matches('editing')) ? (
        <FormControl>
          <FormLabel>Select event</FormLabel>
          <Select
            placeholder="Webhook Event"
            value={eventName}
            onChange={e => {
              setEventName(e.target.value);
              webhookMachineSend({ type: 'FEATURED_EVENT_SELECTED' });
            }}
          >
            {webhook &&
              featuredEvents.map(event => (
                <option value={event.event} key={event.event}>
                  {event.event}
                </option>
              ))}
          </Select>
        </FormControl>
      ) : null}

      {webhookMachineState.matches('editing.initial') ? (
        <Accordion py="5" px="4" bgColor="gray.50" allowToggle>
          <AccordionItem>
            <HStack justifyContent="space-between">
              {samplePayload ? (
                <AccordionButton w="auto">
                  <Text variant="-2u">See payload</Text>
                  <AccordionIcon fontSize="md" />
                </AccordionButton>
              ) : (
                <Text variant="-2u" color="gray.600">
                  Don&apos;t see your field?
                </Text>
              )}

              <Button
                variant="outline"
                colorScheme="gray"
                fontWeight="normal"
                size="sm"
                onClick={handleRefetch}
                isLoading={pathsQuery.isLoading || delayedTestIsOn}
              >
                Test trigger
              </Button>
            </HStack>
            <AccordionPanel>
              <Box as="pre" whiteSpace="pre-wrap" wordBreak="break-word">
                {JSON.stringify(samplePayload ?? {}, null, 2)}
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ) : null}

      {webhookMachineState.matches('editing') ? (
        <>
          <FormControl>
            <VStack alignItems="flex-start" w="full" spacing="4">
              <FormLabel>
                <Text variant="-2u" color="gray.500">
                  field mapping
                </Text>
              </FormLabel>

              <Box w="full">
                <HStack w="full" justifyContent="space-between" mb="2">
                  <Text variant="-1" fontWeight="medium" w="50%">
                    Webhook
                  </Text>
                  <Box w="4" />
                  <Box w="50%">
                    {webhookType === 'Workflow' ? (
                      <Text variant="-1" fontWeight="medium">
                        Process Street
                      </Text>
                    ) : (
                      <HStack alignItems="center">
                        <Icon icon="database" size="3" color="gray.400" />
                        <Text variant="-1" fontWeight="medium">
                          Data Set
                        </Text>
                      </HStack>
                    )}
                  </Box>
                  <Box w="10" />
                </HStack>

                <VStack spacing="2">
                  {form.mappings.map(mapping => (
                    <HStack key={mapping.id} w="full">
                      <Box w="full">
                        <BlvdSelect<TPathOption>
                          isSearchable
                          value={
                            mapping.path
                              ? pathToPathOption(mapping.path, {
                                  isInvalid: pathsQuery.data ? !pathsQuery.data.paths.includes(mapping.path) : false,
                                })
                              : undefined
                          }
                          options={payloadPathOptions}
                          onChange={(value: OnChangeValue<TPathOption, false>, actionMeta: ActionMeta<TPathOption>) => {
                            if (actionMeta.action === 'select-option') {
                              if (BlvdSelectHelpers.isOptionType<TPathOption>(value)) {
                                form.updateMapping({ ...mapping, path: value.value });
                                automationMachineSend({ type: 'SET_HAS_UNSAVED_CHANGES', hasUnsavedChanges: true });
                              }
                            }
                          }}
                          placeholder="Select field"
                          components={PayloadSelectComponents}
                        />
                      </Box>

                      <Icon icon="arrow-right" size="4" variant="far" />

                      <Box w="full">
                        <BlvdSelect
                          isSearchable
                          placeholder={webhookType === 'DataSet' ? 'Select column' : 'Select'}
                          isDisabled={!mapping.path}
                          value={match<Mapping, ChecklistOrFormFieldOrColumnDefinitionOption | undefined>(mapping)
                            .with({ type: 'checklist', property: P.not(P.nullish) }, m =>
                              checklistPropertyToChecklistOption(m.property),
                            )
                            .with({ type: 'formField' }, m =>
                              Option(formFields.find(ff => ff.header.group.id === m.formFieldWidgetHeaderGroupId)).fold(
                                () => undefined,
                                formFieldWidgetToFormFieldOption,
                              ),
                            )
                            .with({ type: 'dataSet' }, m =>
                              Option(
                                dataSetQuery.data?.columnDefs?.find(columnDef => columnDef.id === m.property),
                              ).fold(() => undefined, dataSetColumnToOption),
                            )
                            .otherwise(() => undefined)}
                          options={mappingOptions}
                          components={ChecklistSelectComponents}
                          onChange={option => {
                            if (BlvdSelectHelpers.isOptionType<TChecklistOrFormFieldOption>(option)) {
                              const { id, path } = mapping;
                              match(option)
                                .with({ type: 'checklist' }, cm => {
                                  form.updateMapping({
                                    id,
                                    path,
                                    type: 'checklist',
                                    property: cm.value as IncomingWebhookChecklistConfigKey,
                                  });
                                })
                                .with({ type: 'formField' }, fm => {
                                  form.updateMapping({
                                    id,
                                    path,
                                    type: 'formField',
                                    formFieldWidgetHeaderGroupId: fm.value,
                                  });
                                })
                                .with({ type: 'columnDefinition' }, cd => {
                                  form.updateMapping({
                                    id,
                                    path,
                                    type: 'dataSet',
                                    property: cd.value,
                                  });
                                })
                                .run();
                              automationMachineSend({ type: 'SET_HAS_UNSAVED_CHANGES', hasUnsavedChanges: true });
                            }
                          }}
                        />
                      </Box>

                      <IconButton
                        variant="ghost"
                        icon={<Icon icon="close" size="4" variant="far" />}
                        aria-label="Remove mapping"
                        onClick={() => {
                          form.deleteMapping(mapping.id);
                          automationMachineSend({ type: 'SET_HAS_UNSAVED_CHANGES', hasUnsavedChanges: true });
                        }}
                      />
                    </HStack>
                  ))}
                </VStack>
              </Box>

              <Button
                mt="4"
                variant="tertiary"
                size="sm"
                onClick={form.addNewMapping}
                leftIcon={<Icon icon="plus" variant="far" size="4" />}
              >
                Add field
              </Button>
            </VStack>

            {webhookType === 'DataSet' && (
              <HStack w="full" justifyContent="flex-start" spacing="2" py="9">
                <Text id="key-column-label" w="40" color="gray.600" fontSize="sm" fontWeight="semibold">
                  Key mapping
                  <Tooltip
                    label={
                      <Text fontSize="xs">
                        If a Data Set record exists with the same column value, it will be <strong>updated</strong>{' '}
                        instead of creating a new record.
                      </Text>
                    }
                    hasArrow
                  >
                    <Box as="span">
                      <Icon icon="info-circle" color="gray.400" ml="1" size="4" mb="-0.5" />
                    </Box>
                  </Tooltip>
                </Text>

                <Box w="full">
                  <BlvdSelect
                    options={availableKeyColumns}
                    placeholder="Select field e.g. Employee ID (optional)"
                    aria-labelledby="key-column-label"
                    value={selectedKeyColumn ?? null}
                    isDisabled={!availableKeyColumns.length}
                    onChange={value => {
                      if (BlvdSelectHelpers.isOptionType<KeyColumnOption>(value)) {
                        form.updateKeyColumn(value.value);
                        automationMachineSend({ type: 'SET_HAS_UNSAVED_CHANGES', hasUnsavedChanges: true });
                      }
                    }}
                  />
                </Box>

                {form.keyColumn ? (
                  <IconButton
                    variant="ghost"
                    icon={<Icon icon="close" size="4" variant="far" />}
                    aria-label="Remove key mapping"
                    onClick={() => {
                      form.deleteKeyColumn();
                      automationMachineSend({ type: 'SET_HAS_UNSAVED_CHANGES', hasUnsavedChanges: true });
                    }}
                  />
                ) : (
                  <Spacer minW="9" />
                )}
              </HStack>
            )}
          </FormControl>
        </>
      ) : null}

      <Box
        {...{
          w: 'full',
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'sticky',
          bottom: '0',
          py: '3',
          px: '2',
          bg: 'white',
        }}
      >
        <ButtonGroup spacing="4">
          <Button
            variant="secondary"
            onClick={handleDiscardChanges}
            fontWeight="normal"
            isDisabled={!hasUnsavedChanges}
          >
            Discard changes
          </Button>

          <Button
            isDisabled={(!form.name && webhookType !== 'DataSet') || !hasUnsavedChanges}
            onClick={handleSave}
            isLoading={updateWebhookMutation.isLoading}
            variant="primary"
          >
            Save
          </Button>
        </ButtonGroup>
      </Box>
    </VStack>
  );
};

const PayloadSelectComponents: SelectComponentsConfig<TPathOption, false, GroupBase<TPathOption>> = {
  Option: PathOption,
  SingleValue: PathSingleValue,
};

const ChecklistSelectComponents: SelectComponentsConfig<
  TChecklistOrFormFieldOption,
  false,
  GroupBase<TChecklistOrFormFieldOption>
> = {
  Option: ChecklistOrFormFieldOption,
  NoOptionsMessage,
};
