import { Draft } from 'immer';
import { Identifiable, isModelRef, Muid } from '../core';
import { EntityMap, EntityNormalizer, LookupMap, ReferenceMap, SelectorFunction } from './types';

export const identity = <T extends Identifiable>(e: T) => e;
export const idSelector = <T extends Identifiable>(e: T) => e.id;

/**
 * Converts array of entities into entity map using entity ids
 * @param arr
 * @param normalize
 */
export function toEntityMap<T extends Identifiable>(arr: T[], normalize: EntityNormalizer<T> = identity): EntityMap<T> {
  return arr.reduce((agg: EntityMap<T>, entity: T) => {
    if (isModelRef(entity)) {
      agg[entity.id] = normalize(entity);
    }
    return agg;
  }, {});
}

/**
 * Converts array of entities into entity map using id selector
 * @param arr
 * @param normalize
 */
export function toEntityMapUsingIdSelector<T extends Identifiable>(
  arr: T[],
  selectId: SelectorFunction<T> = idSelector,
  normalize: EntityNormalizer<T> = identity,
): EntityMap<T> {
  return arr.reduce((agg: EntityMap<T>, entity: T) => {
    if (isModelRef(entity)) {
      const id = selectId(entity);
      agg[id] = normalize(entity);
    }
    return agg;
  }, {});
}

/**
 * Concatenates array and element or two arrays into an array with distinct values
 * @param arr
 * @param el
 */
export function concatInDistinctArray<T>(arr: T[] | null = [], el: T | ConcatArray<T>): T[] {
  const nonNullArr = arr === null ? [] : arr;
  return nonNullArr.concat(el).filter((v, i, a) => a.indexOf(v) === i);
}

/**
 * Converts array of entities into lookup map using selector functions
 * @param entities
 * @param selectKey
 * @param selectId
 */
export function toLookupMap<T extends Identifiable>(
  entities: T[],
  selectKey: SelectorFunction<T>,
  selectId: SelectorFunction<T> = idSelector,
): LookupMap {
  const map = entities.reduce((agg: { [id: string]: Set<Muid> }, entity: T) => {
    const key = selectKey(entity);
    const id = selectId(entity);

    if (key && id) {
      agg[key] = agg[key] ?? new Set();
      agg[key].add(id);
    }

    return agg;
  }, {});

  return Object.keys(map).reduce((agg: Draft<LookupMap>, key: string) => {
    agg[key] = Array.from(map[key]);
    return agg;
  }, {});
}

/**
 * Converts array of entities into lookup map using selector functions
 * @param entities
 * @param selectKey
 * @param selectId
 */
export function toReferenceMap<T extends Identifiable>(
  entities: T[],
  selectKey: SelectorFunction<T>,
  selectId: SelectorFunction<T> = idSelector,
): ReferenceMap {
  return entities.reduce((agg: ReferenceMap, entity: T) => {
    const key = selectKey(entity);
    const id = selectId(entity);
    agg[key] = id;
    return agg;
  }, {});
}
