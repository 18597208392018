import angular from 'angular';

import { EntitiesSelectors } from 'reducers/entities/entities.selectors';
import { connectService } from 'reducers/util';
import { DataServiceConstants } from 'reducers/data-service/data-service-constants';

class GroupMembershipService {
  constructor($ngRedux, GroupMembershipActions) {
    'ngInject';

    this.selector = new EntitiesSelectors(DataServiceConstants.GROUP_MEMBERSHIPS, $ngRedux);

    connectService('GroupMembershipService', $ngRedux, null, GroupMembershipActions)(this);
  }

  queryGroupMembership = () => this.actions.queryGroupMembership();

  // TODO Delete this, not used anywhere
  queryGroupMembershipByGroup = groupId => this.actions.queryGroupMembershipByGroup(groupId);

  queryGroupMembershipByUser = userId => this.actions.queryGroupMembershipByUser(userId);

  getAllByOrganizationMembershipUserId = userId => this.actions.getAllByOrganizationMembershipUserId(userId);

  removeFromAllGroups = membership => this.actions.removeFromAllGroups(membership);

  getAll = includes => this.selector.calculateFilter(() => true, includes);

  filter = (filter, includes) => this.selector.calculateFilter(filter, includes);
}

angular.module('frontStreetApp.services').service('GroupMembershipService', GroupMembershipService);
