import {
  Alert,
  AlertIcon,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from 'components/design/next';
import { DataSet } from '@process-street/subgrade/process';
import uniqBy from 'lodash/uniqBy';
import pluralize from 'pluralize';
import * as React from 'react';
import { useGetDataSetLinksQuery } from '../../query-builder';
import { DataSetFormModal } from '../data-set-form-modal';
import { DeleteConfirmationModal } from '../delete-confirmation-modal';
import { useFeatureFlag } from 'features/feature-flags';
import { useEditDataSet } from 'pages/reports/data-sets/components/data-set-page/use-edit-data-set';
import { NoAccessTooltip } from 'pages/reports/data-sets/components/data-set-page/no-access-tooltip';

export type UpdateDataSetButtonProps = {
  dataSet: DataSet;
  isSelected?: boolean;
  isDisabled: boolean;
  onSuccess?: (dataSet: DataSet) => void;
};

export const UpdateDataSetButton: React.FC<React.PropsWithChildren<UpdateDataSetButtonProps>> = ({
  dataSet,
  isSelected,
  isDisabled,
  onSuccess,
}) => {
  const modalDisclosure = useDisclosure();
  const deleteDisclosure = useDisclosure();
  const menuDisclosure = useDisclosure();
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');

  const { handleSubmit, handleDelete, updateDataSetMutation, deleteDataSetMutation } = useEditDataSet(dataSet.id);

  const dataSetLinks = useGetDataSetLinksQuery(
    {
      dataSetId: dataSet.id,
    },
    { enabled: deleteDisclosure.isOpen },
  );

  const linkedTemplatesCount = uniqBy(dataSetLinks.data, 'templateId').length || 0;

  return (
    <>
      {isDataSetsV2Enabled ? (
        <NoAccessTooltip
          isDisabled={!isDisabled}
          contactAdmin={isDisabled}
          label="You don’t have access to edit this Data Set."
          dataSet={dataSet}
        >
          <Menu {...menuDisclosure}>
            <MenuButton
              as={IconButton}
              isDisabled={isDisabled}
              variant="ghost"
              colorScheme="gray"
              aria-label="Edit Data Set"
              size="sm"
              isRound
              icon={<Icon color="gray.400" icon="cog" size="3" />}
              opacity={isSelected || menuDisclosure.isOpen ? 1 : 0}
              _groupHover={{ opacity: 1, position: 'relative' }}
              sx={{ position: 'absolute', right: 0, top: 0 }}
            />
            <Portal>
              <MenuList zIndex="popover">
                <MenuItem onClick={modalDisclosure.onOpen} icon={<Icon variant="far" icon="edit" size="4" />}>
                  Rename Data Set
                </MenuItem>
                <MenuItem
                  onClick={deleteDisclosure.onOpen}
                  color="red.500"
                  icon={<Icon variant="far" icon="trash-alt" size="4" />}
                >
                  Delete Data Set
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </NoAccessTooltip>
      ) : (
        <Tooltip
          hasArrow
          shouldWrapChildren
          label="You don't have access to edit this saved view. Please reach out to the owner or an admin."
          isDisabled={!isDisabled}
        >
          <IconButton
            isDisabled={isDisabled}
            variant="ghost"
            colorScheme="gray"
            aria-label="Edit Data Set"
            borderRadius="4px"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.300"
            icon={<Icon color="gray.500" icon="cog" size="4" />}
            onClick={modalDisclosure.onOpen}
          />
        </Tooltip>
      )}

      <DataSetFormModal
        initialValues={dataSet}
        isOpen={modalDisclosure.isOpen}
        saveStatus={updateDataSetMutation.status}
        deleteStatus={deleteDataSetMutation.status}
        onClose={modalDisclosure.onClose}
        onSubmit={dataSetDelta =>
          handleSubmit(dataSetDelta, {
            onSuccess: dataSet => {
              modalDisclosure.onClose();
              onSuccess?.(dataSet);
            },
          })
        }
        onDelete={
          isDataSetsV2Enabled
            ? undefined
            : () => {
                modalDisclosure.onClose();
                deleteDisclosure.onOpen();
              }
        }
      />

      <DeleteConfirmationModal
        {...deleteDisclosure}
        title="Delete Data Set"
        content={
          <>
            <VStack alignItems="stretch" spacing="5">
              {linkedTemplatesCount > 0 && (
                <Alert fontWeight="700" color="gray.700" status="warning">
                  <AlertIcon />
                  This data set is connected to {pluralize('workflow', linkedTemplatesCount, true)}.
                </Alert>
              )}
              <Text>
                Your Data Set will be deleted and <strong>can't be recovered</strong>.
              </Text>
            </VStack>
          </>
        }
        isLoading={deleteDataSetMutation.isLoading}
        onDelete={() => handleDelete({ onSuccess: deleteDisclosure.onClose })}
      />
    </>
  );
};
