import { isPlanFree, Plan, PlanFeatureSet } from '@process-street/subgrade/billing';
import { OrganizationStats, Subscription, SubscriptionStatus } from '@process-street/subgrade/core';
import { dayjs as moment } from '@process-street/subgrade/util';

export const isSubscribed = (subscription?: Subscription): boolean => {
  if (!subscription) return false;
  return subscription.status === SubscriptionStatus.Trialing || subscription.status === SubscriptionStatus.Active;
};

export const isSubscriptionExpired = ({ subscription, plan }: { subscription: Subscription; plan: Plan }): boolean => {
  if (!subscription || !plan) return true;
  return !isSubscribed(subscription) && !isPlanFree(plan);
};

const getActiveTemplatesLimitDetails = function ({
  planFeatureSet,
  organizationStats,
}: {
  planFeatureSet: PlanFeatureSet;
  organizationStats: OrganizationStats;
}) {
  const usage = organizationStats.templatesActiveCount;
  const limit = planFeatureSet.activeTemplatesLimit;

  return {
    usage,
    limit,
    limitIsReached: limit ? usage > limit : false,
  };
};

const getChecklistRunsLimitDetails = function ({
  planFeatureSet,
  organizationStats,
}: {
  planFeatureSet: PlanFeatureSet;
  organizationStats: OrganizationStats;
}) {
  const usage = organizationStats.checklistsRunsCountCurrentPeriod;
  const limit = planFeatureSet.checklistRunsLimit;

  return {
    usage,
    limit,
    limitIsReached: limit ? usage > limit : false,
  };
};

const getActiveChecklistsLimitDetails = function ({
  planFeatureSet,
  organizationStats,
}: {
  planFeatureSet: PlanFeatureSet;
  organizationStats: OrganizationStats;
}) {
  const usage = organizationStats.checklistsActiveCount;
  const limit = planFeatureSet.activeChecklistsLimit;

  return {
    usage,
    limit,
    limitIsReached: limit ? usage > limit : false,
  };
};

export const getReachedPlanLevelLimitsAsMessages = function ({
  planFeatureSet,
  organizationStats,
}: {
  planFeatureSet?: PlanFeatureSet;
  organizationStats?: OrganizationStats;
}) {
  const limits = [];

  if (!planFeatureSet || !organizationStats) return [];

  if (getActiveTemplatesLimitDetails({ planFeatureSet, organizationStats }).limitIsReached) {
    limits.push('active workflows');
  }
  if (getActiveChecklistsLimitDetails({ planFeatureSet, organizationStats }).limitIsReached) {
    limits.push('active workflow runs');
  }
  if (getChecklistRunsLimitDetails({ planFeatureSet, organizationStats }).limitIsReached) {
    limits.push('workflow runs');
  }

  return limits;
};

export const getTrialDaysRemaining = (subscription: Subscription): number => {
  const nowMoment = moment(Date.now());
  const trialEndMoment = moment(subscription.trialEndDate);

  return Math.max(Math.round(trialEndMoment.diff(nowMoment, 'days', true)), 0);
};
