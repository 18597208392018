import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { WidgetConditionalLogicButton } from '.';

export const WidgetConditionalLogicButtonModule = angular
  .module('widgetConditionalLogicButton', [])
  .component(
    'psWidgetConditionalLogicButton',
    reactToAngular(WidgetConditionalLogicButton, ['templateRevision', 'widget', 'widgetHasConstraintValidation']),
  ).name;
