import { ActorRefFrom, assign, createMachine } from 'xstate';
import { sendParent } from 'xstate/lib/actions';

type Event = { type: 'UPLOAD_STARTED' } | { type: 'UPDATE_PROGRESS'; progress: number } | { type: 'UPLOAD_FINISHED' };

type Context = {
  progress: number;
};

export const makeVideoUploadMachine = () => {
  return createMachine(
    {
      initial: 'idle',
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      context: {
        progress: 0,
      },
      states: {
        idle: {
          on: {
            UPLOAD_STARTED: 'uploading',
          },
        },
        uploading: {
          entry: ['sendVideoUploadingToParent'],
          on: {
            UPLOAD_FINISHED: {
              target: 'idle',
              actions: ['resetProgress'],
            },
            UPDATE_PROGRESS: {
              actions: ['assignProgress'],
            },
          },
        },
      },
    },
    {
      actions: {
        assignProgress: assign({
          progress: (ctx, evt) => {
            if (evt.type !== 'UPDATE_PROGRESS') return ctx.progress;

            return evt.progress;
          },
        }),
        resetProgress: assign({
          progress: 0,
        }),
        sendVideoUploadingToParent: sendParent({
          type: 'VIDEO_UPLOADING',
        }),
      },
    },
  );
};

export type VideoUploadMachine = ReturnType<typeof makeVideoUploadMachine>;
export type VideoUploadActorRef = ActorRefFrom<VideoUploadMachine>;
