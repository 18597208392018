import {
  ChecklistStatus,
  ConflictResultType,
  DueDateState,
  FormFieldWidget,
  Task,
  TaskStatus,
} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { Muid } from '@process-street/subgrade/core';

export namespace UpdateTaskStatusMutation {
  export type Params = {
    taskId: Muid;
    status: TaskStatus;
  };

  export type Response = {
    task: Task;
    checklistStatus: ChecklistStatus;
    dueDateTaskStates: DueDateState[];
    conflictType?: ConflictResultType;
    invalidFormFields: FormFieldWidget[];
  };

  export const key = ['update', 'checklist-revisions', 'tasks'];

  export const mutationFn = async (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/tasks/${params.taskId}/status/${params.status}`)
      .then(r => r.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(UpdateTaskStatusMutation.key, UpdateTaskStatusMutation.mutationFn, options);
  };
}
