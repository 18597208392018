import {
  getBlockAbove,
  getParentNode,
  getPluginOptions,
  getPluginType,
  isElement,
  isText,
  setNodes,
  TElement,
  unwrapNodes,
  wrapNodeChildren,
} from '@udecode/plate-common';
import { ELEMENT_TABLE, ELEMENT_TD, ELEMENT_TR, getCellTypes, TablePlugin } from '@udecode/plate-table';
import { PagesTableElement, PagesWithOverride } from '../../pages-plate-types';

/* Copies withNormalizeTable.ts from @udecode/plate-table.
 * Their version doesn't follow correct HTML table format and normalizes incorrectly.
 */
export const withPSNormalizeTable: PagesWithOverride = editor => {
  const { normalizeNode } = editor;

  const { initialTableWidth } = getPluginOptions<TablePlugin>(editor as any, ELEMENT_TABLE);

  editor.normalizeNode = ([node, path]) => {
    if (isElement(node)) {
      if (node.type === getPluginType(editor, ELEMENT_TABLE)) {
        const tableEntry = getBlockAbove(editor, {
          at: path,
          match: { type: getPluginType(editor, ELEMENT_TABLE) },
        });

        if (tableEntry) {
          unwrapNodes(editor, {
            at: path,
          });
          return;
        }

        if (initialTableWidth) {
          const tableNode = node as PagesTableElement;
          const colCount = (tableNode.children[0]?.children as TElement[] | undefined)?.length;
          if (colCount) {
            const colSizes: number[] = [];

            if (!tableNode.colSizes) {
              for (let i = 0; i < colCount; i++) {
                colSizes.push(initialTableWidth / colCount);
              }
            } else if (tableNode.colSizes.some(size => !size)) {
              tableNode.colSizes.forEach(colSize => {
                colSizes.push(colSize || initialTableWidth / colCount);
              });
            }

            if (colSizes.length > 0) {
              setNodes<PagesTableElement>(editor, { colSizes }, { at: path });
              return;
            }
          }
        }
      }

      if (node.type === getPluginType(editor, ELEMENT_TR)) {
        const parentEntry = getParentNode(editor, path);

        // This is where we deviate from Plate. It expects TR to be a child of Table which is wrong.
        if (parentEntry?.[0].type === getPluginType(editor, ELEMENT_TD)) {
          unwrapNodes(editor, {
            at: path,
          });
          return;
        }
      }

      if (getCellTypes(editor).includes(node.type)) {
        const { children } = node;

        const parentEntry = getParentNode(editor, path);

        if (parentEntry?.[0].type !== getPluginType(editor, ELEMENT_TR)) {
          unwrapNodes(editor, {
            at: path,
          });
          return;
        }

        if (isText(children[0])) {
          wrapNodeChildren<TElement>(editor, editor.blockFactory({}, path), {
            at: path,
          });

          return;
        }
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};
