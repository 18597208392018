import angular from 'angular';
import { canCreateOrganization, isOwnershipUser } from 'utils/security';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';

angular
  .module('frontStreetApp.services')
  .service('SecurityService', function (AnonAuthService, auth, PermissionsDao, Permissions, SessionService) {
    const self = this;

    /**
     * Determines if current user is fully authenticated.
     *
     * @return boolean
     */
    self.isAuthenticatedFully = function () {
      return auth.isLoggedIn();
    };

    /**
     * Determines if current user is anonymously authenticated.
     */
    self.isAuthenticatedAnonymously = function () {
      return AnonAuthService.isAuthenticatedAnonymously();
    };

    self.whoCanReadTemplateByTemplateId = function (templateId) {
      return Permissions.whoCanReadTemplate({ templateId }).$promise;
    };

    self.whoCanReadPageByPageId = pageId => Permissions.whoCanReadPage({ pageId }).$promise;

    self.whoCanReadChecklistByChecklistId = function (checklistId) {
      return Permissions.whoCanReadChecklist({ checklistId }).$promise;
    };

    self.whoCanCreateChecklistByTemplateId = function (templateId) {
      return Permissions.whoCanCreateChecklist({ templateId }).$promise;
    };

    /**
     * Gets the correct organization id based on the user and current situation known in scope
     *
     * @param user
     * @return The organization id, or undefined if there is none
     */
    self.getSelectedOrganizationIdByUser = function (user) {
      let organizationId;

      if (user && self.isAuthenticatedFully() && !isAnonymousUser(user)) {
        organizationId = SessionService.getSelectedOrganizationId();
      } else if (user && self.isAuthenticatedAnonymously() && isAnonymousUser(user)) {
        organizationId = AnonAuthService.getOrganizationId();
      }

      return organizationId;
    };

    /**
     * Gets the correct authentication token id based on the user and current situation known in scope.
     *
     * @param user
     * @return The token or undefined if there is no token
     */
    self.getAuthenticationTokenByUser = function (user) {
      let token;

      if (user && self.isAuthenticatedFully() && !isAnonymousUser(user)) {
        token = SessionService.getToken();
      } else if (user && self.isAuthenticatedAnonymously() && isAnonymousUser(user)) {
        token = AnonAuthService.getAnonymousToken();
      }

      return token;
    };

    // PERMISSIONS

    // Global

    self.getConsolidatedGlobalPermissionsByOrganizationId = function (organizationId) {
      return PermissionsDao.getConsolidatedGlobalByOrganizationId(organizationId);
    };

    self.canCreateTagsByTemplate = function (template) {
      return self
        .getConsolidatedGlobalPermissionsByOrganizationId(template.organization.id)
        .then(permission => permission.permissionMap.tagCreate);
    };

    self.canUpdateOrganizationMembershipLevelByTemplate = function (template) {
      return self
        .getConsolidatedGlobalPermissionsByOrganizationId(template.organization.id)
        .then(permission => permission.permissionMap.organizationMembershipLevelUpdate);
    };

    self.canInviteMembersByOrganizationId = function (organizationId) {
      return self
        .getConsolidatedGlobalPermissionsByOrganizationId(organizationId)
        .then(permission => permission.permissionMap.membersInvite);
    };

    // Checklist

    self.getConsolidatedPermissionsByChecklist = function (checklist) {
      return PermissionsDao.getConsolidatedByChecklistId(checklist.id);
    };

    self.getAllConsolidatedByChecklistIds = function (checklistIds) {
      return PermissionsDao.getAllConsolidatedByChecklistIds(checklistIds);
    };

    self.canUpdateChecklistByChecklist = function (checklist) {
      return self
        .getConsolidatedPermissionsByChecklist(checklist)
        .then(permission => permission.permissionMap.checklistUpdate);
    };

    self.canDeleteChecklistByChecklist = function (checklist) {
      return self
        .getConsolidatedPermissionsByChecklist(checklist)
        .then(permission => permission.permissionMap.checklistDelete);
    };

    self.canReadChecklistByChecklist = function (checklist) {
      return self
        .getConsolidatedPermissionsByChecklist(checklist)
        .then(permission => permission.permissionMap.checklistRead);
    };

    self.canDeleteDoodadByChecklist = function (checklist) {
      return self
        .getConsolidatedPermissionsByChecklist(checklist)
        .then(permission => permission.permissionMap.doodadDelete);
    };

    self.canManageTaskByChecklist = function (checklist) {
      return self
        .getConsolidatedPermissionsByChecklist(checklist)
        .then(permission => permission.permissionMap.taskManage);
    };

    self.canCompleteChecklistByChecklist = function (checklist) {
      return self
        .getConsolidatedPermissionsByChecklist(checklist)
        .then(permission => permission.permissionMap.checklistComplete);
    };

    self.canReactivateChecklistByChecklist = function (checklist) {
      return self
        .getConsolidatedPermissionsByChecklist(checklist)
        .then(permission => permission.permissionMap.checklistReactivate);
    };

    self.canArchiveChecklistByChecklist = function (checklist) {
      return self
        .getConsolidatedPermissionsByChecklist(checklist)
        .then(permission => permission.permissionMap.checklistArchive);
    };

    self.canCreateChecklistAssignmentByChecklist = function (checklist) {
      return self
        .getConsolidatedPermissionsByChecklist(checklist)
        .then(permission => permission.permissionMap.checklistAssignmentCreate);
    };

    self.canDeleteChecklistAssignmentByChecklist = function (checklist) {
      return self
        .getConsolidatedPermissionsByChecklist(checklist)
        .then(permission => permission.permissionMap.checklistAssignmentDelete);
    };

    self.canExportChecklistByChecklist = function (checklist) {
      return self
        .getConsolidatedPermissionsByChecklist(checklist)
        .then(permission => permission.permissionMap.checklistExport);
    };

    self.canShareChecklistByChecklist = function (checklist) {
      return self
        .getConsolidatedPermissionsByChecklist(checklist)
        .then(permission => permission.permissionMap.checklistShare);
    };

    // Folder

    self.getConsolidatedPermissionsByFolder = function (folder) {
      return PermissionsDao.getConsolidatedByFolderId(folder.id);
    };

    self.canCreateTemplateByFolder = function (folder) {
      return self
        .getConsolidatedPermissionsByFolder(folder)
        .then(permission => permission.permissionMap.templateCreate);
    };

    // Template

    self.getConsolidatedPermissionsByTemplate = function (template) {
      return PermissionsDao.getConsolidatedByTemplateId(template.id);
    };

    self.canCreateChecklistByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.checklistCreate);
    };

    self.canExportChecklistByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.checklistExport);
    };

    self.canScheduleChecklistByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.checklistSchedule);
    };

    self.canReadTemplateByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.templateRead);
    };

    self.canUpdateTemplateByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.templateUpdate);
    };

    self.canDeleteTemplateByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.templateDelete);
    };

    self.canSubscribeToTemplateByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.templateSubscribe);
    };

    self.canArchiveTemplateByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.templateArchive);
    };

    self.canManageTagsByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.templateTagsManage);
    };

    self.canManageTemplatePermitsByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.templatePermitsManage);
    };

    self.canShareTemplateByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.templateShare);
    };

    self.canUpdateTemplateShareLevelByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.templateShareLevelUpdate);
    };

    self.canUpdateTemplateShareLinkByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.templateShareLinkUpdate);
    };

    self.canUpdatePageByTemplate = function (template) {
      return self
        .getConsolidatedPermissionsByTemplate(template)
        .then(permission => permission.permissionMap.pageUpdate);
    };

    self.canCreateAdditionalEmail = function (user) {
      return isOwnershipUser(user);
    };

    self.canChangePrimaryEmail = function (user) {
      return isOwnershipUser(user);
    };

    self.canCreateOrganization = canCreateOrganization;

    self.canChangePassword = function (user) {
      return isOwnershipUser(user);
    };
  });
