import {
  EmailFormFieldWidget,
  MembersFormFieldWidget,
  TaskTemplate,
  TemplateRevision,
  TemplateTaskAssignment,
} from '@process-street/subgrade/process';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useGetAllGroupsQuery } from 'features/group/query-builder/get-all-groups';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { useGetAllTaskTemplatesAssignmentsByTemplateRevisionIdQuery } from 'features/task-template-assignment/query-builder';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { TaskAssignmentRule, TaskAssignmentRuleUtils } from '@process-street/subgrade/role-assignment';
import uniqWith from 'lodash/uniqWith';
import {
  Group,
  GroupType,
  Muid,
  OrganizationMembership,
  OrganizationMembershipWithUser,
  User,
} from '@process-street/subgrade/core';
import { GetTaskAssignmentRulesByTemplateRevisionIdQuery } from 'features/task-assignment-rules/query-builder';
import { UseAssignmentsQuerySelectors } from '../assignment-picker/use-assignments-query';
import { Option } from 'space-monad';
import React from 'react';

type EmailOrMembers = EmailFormFieldWidget | MembersFormFieldWidget;
export type UserWithAssignmentId = User & { assignmentId: Muid; organizationMembershipId: Muid };
export type TaskAssignmentRuleWithWidget = TaskAssignmentRule & { widget?: EmailOrMembers };

type UseTaskAssignmentsArgs = {
  templateRevision: TemplateRevision;
  taskTemplate: TaskTemplate;
};

type UseTaskAssignmentsResult = {
  uniqueRules: TaskAssignmentRuleWithWidget[];
  assignees: UserWithAssignmentId[];
};

export const useTaskAssignments = ({
  templateRevision,
  taskTemplate,
}: UseTaskAssignmentsArgs): UseTaskAssignmentsResult => {
  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const groupsQuery = useGetAllGroupsQuery({ include: 'user' });

  const taskAssignmentsQuery = useGetAllTaskTemplatesAssignmentsByTemplateRevisionIdQuery({
    templateRevisionId: templateRevision.id,
  });
  const organizationMembershipAssignmentMap = React.useMemo(() => {
    return Selectors.getOrganizationMembershipAssignmentMap({
      taskTemplateId: taskTemplate.id,
      assignments: taskAssignmentsQuery.data ?? [],
    });
  }, [taskAssignmentsQuery.data, taskTemplate.id]);

  const organizationMembershipsQuery = useGetAllOrganizationMembershipsQuery({
    organizationId: selectedOrganizationId,
  });
  const assignees = React.useMemo(() => {
    return Selectors.getValidMembershipUsers({
      groups: groupsQuery.data ?? [],
      organizationMembershipAssignmentMap,
      organizationMemberships: organizationMembershipsQuery.data ?? [],
    });
  }, [groupsQuery.data, organizationMembershipAssignmentMap, organizationMembershipsQuery.data]);

  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevision.id, {
    select: widgets => widgets.filter(UseAssignmentsQuerySelectors.isEmailOrMembersFormFieldWidget),
  });

  const assignmentRulesQuery = GetTaskAssignmentRulesByTemplateRevisionIdQuery.useQuery({
    templateRevisionId: templateRevision.id,
  });
  const assignmentRules = React.useMemo(() => {
    return Selectors.getTaskRulesWithWidget({
      taskTemplateGroupId: taskTemplate.group.id,
      widgets: widgetsQuery.data ?? [],
      rules: assignmentRulesQuery.data ?? [],
    });
  }, [assignmentRulesQuery.data, taskTemplate.group.id, widgetsQuery.data]);

  const uniqueRules = uniqWith(assignmentRules, TaskAssignmentRuleUtils.areRulesSameType);

  return { uniqueRules, assignees };
};

const Selectors = {
  getValidMembershipUsers: ({
    groups,
    organizationMembershipAssignmentMap,
    organizationMemberships,
  }: {
    groups: Group[];
    organizationMembershipAssignmentMap: Record<OrganizationMembership['id'], TemplateTaskAssignment['id']>;
    organizationMemberships: OrganizationMembershipWithUser[];
  }) => {
    const systemGroups = groups.filter(group => group.groupType !== GroupType.Standard);
    const systemGroupUserIds = new Set(systemGroups.map(group => group.user.id));
    return organizationMemberships
      .filter(om => !systemGroupUserIds.has(om.user.id) && organizationMembershipAssignmentMap[om.id])
      .map(om => ({
        ...om.user,
        organizationMembershipId: om.id,
        assignmentId: organizationMembershipAssignmentMap[om.id],
      }));
  },

  getOrganizationMembershipAssignmentMap: ({
    taskTemplateId,
    assignments,
  }: {
    taskTemplateId: Muid;
    assignments: TemplateTaskAssignment[];
  }) => {
    return assignments
      .filter(assignment => assignment.taskTemplate.id === taskTemplateId)
      .reduce((acc, assignment) => {
        acc[assignment.organizationMembership.id] = assignment.id;
        return acc;
      }, {} as Record<string, string>);
  },

  getTaskRulesWithWidget: ({
    taskTemplateGroupId,
    widgets,
    rules,
  }: {
    taskTemplateGroupId: Muid;
    widgets: EmailOrMembers[];
    rules: TaskAssignmentRule[];
  }) => {
    return rules
      .filter(rule => rule.targetTaskTemplateGroup?.id === taskTemplateGroupId)
      .map(rule => ({
        ...rule,
        ...Option(widgets.find(widget => widget.header.group.id === rule.sourceFormFieldWidgetGroup?.id))
          .map<{ widget?: typeof widgets[number] }>(widget => ({ widget }))
          .getOrElse({}),
      }));
  },
};

export { Selectors as UseTaskAssignmentsSelectors };
