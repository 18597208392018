import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { LibraryView } from '../context';
import { useStateParam } from 'hooks/use-state-param';

const FOLDER: LibraryView = 'folder';
const TAG: LibraryView = 'tag';

export function useUpdateRoute() {
  const { $state } = useInjector('$state');

  const path =
    useStateParam({ key: 'path' })
      ?.replace(/(?:%2F|%252F)/g, '/')
      ?.replace(/%20/, ' ') ?? '';
  const { current } = $state;

  React.useEffect(() => {
    if ($state.is('dashboard')) {
      $state.go(
        'dashboard.type',
        { type: FOLDER, path: 'home' },
        {
          location: $state.is('dashboard') ? 'replace' : true,
        },
      );
    }

    if ($state.is('dashboard.type', { type: FOLDER }) && !$state.is('dashboard.type', { type: FOLDER, path })) {
      $state.go(
        'dashboard.type',
        { type: FOLDER, path },
        {
          location: $state.is('dashboard') ? 'replace' : true,
        },
      );
    }

    if ($state.is('dashboard.type', { type: TAG }) && !$state.is('dashboard.type', { type: TAG, path })) {
      $state.go('dashboard.type', { type: TAG, path }, { location: $state.is('dashboard') ? 'replace' : true });
    }
  }, [path, $state, current]);
}
