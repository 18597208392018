import { AutomationAppName, stripAutomationAppNamePrefix } from '@process-street/subgrade/automation';
import orderBy from 'lodash/orderBy';
import always from 'lodash/constant';

export function sortAutomationAppNames({
  automationAppNames,
  hasUnconfiguredInstance = always(false),
  matchesUserUseCase = always(false),
  matchesUseCaseFilter = always(false),
}: {
  automationAppNames: AutomationAppName[];
  hasUnconfiguredInstance?(automationAppName: AutomationAppName): boolean;
  matchesUserUseCase?(automationAppName: AutomationAppName): boolean;
  matchesUseCaseFilter?(automationAppName: AutomationAppName): boolean;
}) {
  const sortedTriggers = orderBy(
    automationAppNames,
    [
      app => (hasUnconfiguredInstance(app) ? 0 : 1),
      app => (matchesUserUseCase(app) ? 0 : 1),
      app => (matchesUseCaseFilter(app) ? 0 : 1),
      stripAutomationAppNamePrefix, // <- alphabetical
    ],
    ['asc', 'asc', 'asc', 'asc'],
  );

  return sortedTriggers;
}
