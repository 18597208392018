import { Badge, Center, HStack } from 'components/design/next';
import { TaskTemplate, Template, TemplateRevision } from '@process-street/subgrade/process';
import {
  SolutionInstancesTooltip,
  TaskAutomationInstanceItem,
} from 'app/features/automations/components/task-automation-indicator';
import { useSolutionTypeTagInstances } from 'app/pages/templates/_id/automation/components/selector/use-solution-type-tag-instances';
import * as React from 'react';
import { TaskActionsAutomationsButton } from '../task-actions-automations-button';
import { AutomationInstanceUtils } from '@process-street/subgrade/automation';

export type TaskActionsAutomationIndicatorProps = {
  taskTemplate: TaskTemplate;
  templateRevision: TemplateRevision;
  templateId: Template['id'];
  isEditable?: boolean;
};

export const TaskActionsAutomationIndicator = ({
  taskTemplate,
  templateRevision,
  templateId,
  isEditable = true,
}: TaskActionsAutomationIndicatorProps) => {
  const {
    instances: { ps_when_task_checked_then },
  } = useSolutionTypeTagInstances({ templateId });

  const activeTaskAutomations = ps_when_task_checked_then.filter(i =>
    AutomationInstanceUtils.isEnabledTaskInstance(i, taskTemplate),
  );

  const automationsToShow = activeTaskAutomations?.slice(0, 2);

  const hiddenTaskSolutionInstancesCount = activeTaskAutomations.length - automationsToShow.length;

  if (!automationsToShow.length) return null;

  return (
    <HStack
      spacing="0"
      maxH="8"
      border={isEditable ? '1px solid' : 'none'}
      borderColor="gray.300"
      borderRadius="var(--ps-radii-base)"
    >
      {isEditable && (
        <TaskActionsAutomationsButton
          isEditable={isEditable}
          iconButtonProps={{ border: 'none', _hover: { bg: 'transparent' } }}
        />
      )}
      <SolutionInstancesTooltip
        templateRevision={templateRevision}
        taskTemplate={taskTemplate}
        notVisibleCount={activeTaskAutomations.length}
      >
        <HStack spacing={0} pr="1">
          {automationsToShow.map((automationInstance, index, array) => {
            return (
              <Center
                key={AutomationInstanceUtils.getInstanceId(automationInstance)}
                borderRadius="full"
                bgColor="white"
                borderWidth="thin"
                borderColor="gray.200"
                borderStyle="solid"
                minW="6"
                minH="6"
                _notFirst={{ ml: -3 }}
                zIndex={array.length - index - 1}
              >
                <TaskAutomationInstanceItem
                  templateRevision={templateRevision}
                  taskTemplate={taskTemplate}
                  isDisabled={false}
                  editable={isEditable}
                  isLast={false}
                  isSelected={false}
                  notVisibleCount={0}
                  automationInstance={automationInstance}
                  isTooltipDisabled
                  w="6"
                  h="6"
                />
              </Center>
            );
          })}
          {hiddenTaskSolutionInstancesCount > 0 && (
            <Badge
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="full"
              bg="gray.400"
              color="white"
              h="4"
              w="4"
              mt="2"
              ml="-2"
              zIndex="2"
            >
              {hiddenTaskSolutionInstancesCount}
            </Badge>
          )}
        </HStack>
      </SolutionInstancesTooltip>
    </HStack>
  );
};
