import { createSelector } from 'reselect';
import { SessionSelector } from 'reducers/session/session.selectors';
import { connectController } from 'reducers/util';
import templateUrl from './expiration-warning.component.html';

export const ExpirationWarningComponent = {
  templateUrl,
  controller: class OrganizationManageExpirationWarningComponent {
    constructor($ngRedux, BillingService) {
      'ngInject';

      const mapStateToCtrl = createSelector(
        [SessionSelector.getSelectedOrganization, SessionSelector.getCurrentPlan],
        (organization, plan) => {
          const subscriptionIsExpired = BillingService.isSubscriptionExpired(organization.subscription, plan);
          return {
            subscriptionIsExpired,
          };
        },
      );

      connectController($ngRedux, () => mapStateToCtrl)(this);
    }
  },
};
