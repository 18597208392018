import { ButtonGroup, Divider, Text, VStack } from '@chakra-ui/react';
import { NativeAutomation, NativeAutomationWithLink, TaskTemplate, Template } from '@process-street/subgrade/process';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from 'components/design/next';
import {
  CreateNativeAutomationMutation,
  GetAllNativeAutomationsQuery,
  UpdateNativeAutomationActionsMutation,
} from 'features/native-automations/query-builder';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import { useAutomationSelector } from '../../components/selector/context';
import { FormFieldSelector } from './form-field-selector';
import { TaskSelector } from './task-selector';

export interface PublishTaskToPageConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
  templateId: Template['id'];
  taskTemplateId: TaskTemplate['id'];
  configuredAutomation?: NativeAutomationWithLink;
}

type ActionConfig = Extract<NativeAutomation.Action, { actionType: 'PublishTaskToPage' }>['config'];

const ERROR_MESSAGE = 'Field is required.';
const validationSchema = yup.object<ActionConfig>({
  widgetGroupId: yup.string().required(ERROR_MESSAGE),
  taskTemplateGroupId: yup.string().required(ERROR_MESSAGE),
});

export const PublishTaskToPageConfigModal: React.FC<PublishTaskToPageConfigModalProps> = ({
  isOpen,
  onClose,
  templateId,
  taskTemplateId,
  configuredAutomation,
}) => {
  const action = configuredAutomation?.automation.actions[0];
  // Narrow the action config type
  if ((configuredAutomation && !action) || (action && action?.actionType !== 'PublishTaskToPage'))
    throw new Error(
      `Native automation [${configuredAutomation?.automation.id}] must be a Publish Task to Page automation.`,
    );
  const config = action?.config;

  const queryClient = useQueryClient();
  const [, send] = useAutomationSelector();
  const handleUpsertSuccess = (automation: NativeAutomation) => {
    send({
      type: 'AUTOMATION_CONFIGURED',
      payload: {
        automationType: 'native',
        id: automation.id,
      },
    });
    return queryClient.invalidateQueries(GetAllNativeAutomationsQuery.getKey());
  };
  const createNativeAutomationMutation = CreateNativeAutomationMutation.useMutation({
    onSuccess: result => handleUpsertSuccess(result.automation),
  });
  const updateNativeAutomationActionsMutation = UpdateNativeAutomationActionsMutation.useMutation({
    onSuccess: handleUpsertSuccess,
  });

  const doCreate = (configForm: ActionConfig) => {
    return createNativeAutomationMutation.mutateAsync({
      automationType: 'WorkflowPageAutomation',
      linkedEntity: {
        taskTemplateId,
      },
      trigger: {
        triggerType: 'TaskCompleted',
        config: {
          taskTemplateId,
        },
      },
      actions: [
        {
          actionType: 'PublishTaskToPage',
          key: 'action1',
          config: configForm,
        },
      ],
    });
  };

  const doUpdate = (configForm: ActionConfig) => {
    if (!configuredAutomation) throw new Error('Automation should exist');
    return updateNativeAutomationActionsMutation.mutateAsync({
      nativeAutomationId: configuredAutomation.automation.id,
      actions: [
        {
          actionType: 'PublishTaskToPage',
          key: 'action1',
          config: configForm,
        },
      ],
    });
  };

  const handleSubmit = async (values: ActionConfig) => {
    if (configuredAutomation) {
      await doUpdate(values);
    } else {
      await doCreate(values);
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader px={6}>
          <Text fontWeight="bold" fontSize="lg" as="h2">
            Choose Page
          </Text>
        </ModalHeader>
        <Divider />
        <Formik<ActionConfig>
          initialValues={{
            taskTemplateGroupId: config?.taskTemplateGroupId ?? '',
            widgetGroupId: config?.widgetGroupId ?? '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <ModalBody p={6}>
                <VStack gap={6}>
                  <Text>Choose the field with the ID of the Page that should be updated</Text>
                  <FormFieldSelector templateId={templateId} />
                  <TaskSelector templateId={templateId} />
                </VStack>
              </ModalBody>
              <ModalFooter p={6}>
                <ButtonGroup spacing={2}>
                  <Button onClick={onClose} variant="secondary" isDisabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary" isLoading={isSubmitting} isDisabled={!isValid}>
                    Next
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
