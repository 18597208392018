import { QueryActorSelectors } from 'app/utils/query-builder';
import {
  FormEditorPageMachineCreateWidgetEvent,
  FormPageEditorMachineContext,
  FormPageEditorMachineEvent,
} from '../form-editor-page-machine-types';
import { MuidUtils, User } from '@process-street/subgrade/core';
import { FormEditorPageMachineHelpers } from '../form-editor-page-machine-helpers';
import { Widget, WidgetType } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import {
  generateFileWidget,
  generateImageWidget,
  generateVideoWidget,
  generateWidgetHeader,
} from '@process-street/subgrade/test';
import { CreateWidgetMutation, WidgetsByTemplateRevisionIdQuery } from 'app/features/widgets/query-builder';
import { makeMutation } from 'app/utils/query-builder/make-mutation';
import { AnalyticsConstants } from '@process-street/subgrade/analytics';
import { spawn } from 'xstate';
import { fromPromise } from 'xstate/lib/behaviors';
import { AnalyticsService } from 'app/components/analytics/analytics.service';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'app/features/template/query-builder';
import { ALL_MEMBERS_GROUP_USERNAME } from '@process-street/subgrade/util/membership-utils';
import { getOrderableComparer } from 'app/services/util-pure';
import { spawnWidgetMachine } from '../helpers/spawn-widget-machine';

const { Key } = AnalyticsConstants;

export const createWidgetFromClipboard = (
  context: FormPageEditorMachineContext,
  event: Extract<FormPageEditorMachineEvent, { type: 'PASTE_FILE' }>,
) => {
  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient: context.sharedContext.queryClient,
    templateId: context.sharedContext.templateId,
  });

  const taskTemplates = QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery)?.all ?? [];
  const taskTemplateGroupId = context.sharedContext.$state.params.groupId;
  const taskTemplate = taskTemplates.find(tt => tt.group.id === taskTemplateGroupId) ?? taskTemplates[0];
  const allWidgets = QueryActorSelectors.getQueryData(context.widgetsByTemplateRevisionIdQuery)?.all ?? [];
  const defaultGroupId =
    QueryActorSelectors.getQueryData(context.groupsQuery)?.find(
      g => (g.user as User).username === ALL_MEMBERS_GROUP_USERNAME,
    )?.id ?? MuidUtils.randomMuid();

  if (!taskTemplate) {
    throw new Error('task template is missing');
  }

  const template = QueryActorSelectors.getQueryData(context.templateQuery);

  if (!template) {
    throw new Error('template is missing');
  }

  const createWidgetEvent: FormEditorPageMachineCreateWidgetEvent = {
    type: 'CREATE_WIDGET',
    payload: match(event.file.type)
      .when(
        fileType => fileType.startsWith('image/'),
        () => ({ widgetType: WidgetType.Image }),
      )
      .when(
        fileType => fileType.startsWith('video/'),
        () => ({ widgetType: WidgetType.Video }),
      )
      .otherwise(() => ({ widgetType: WidgetType.File })),
  };
  const widgetRequest = FormEditorPageMachineHelpers.makeWidgetRequest({
    event: createWidgetEvent,
    taskTemplateId: taskTemplate.id,
    allWidgets,
    defaultGroupId,
    templateType: template.templateType,
  });

  const optimisticWidget = match(createWidgetEvent.payload)
    .with({ widgetType: P.not(P.nullish) }, ({ widgetType }) => {
      const header = {
        id: widgetRequest.headerId,
        orderTree: widgetRequest.orderTree,
        taskTemplate,
      };

      return match(widgetType)
        .with(WidgetType.File, widgetType =>
          generateFileWidget({
            header: generateWidgetHeader(widgetType, header),
            file: undefined,
            description: undefined,
          }),
        )
        .with(WidgetType.Image, widgetType =>
          generateImageWidget({
            header: generateWidgetHeader(widgetType, header),
            caption: undefined,
            file: undefined,
          }),
        )
        .with(WidgetType.Video, widgetType =>
          generateVideoWidget({
            header: generateWidgetHeader(widgetType, header),
            description: undefined,
            file: undefined,
            service: undefined,
            serviceCode: undefined,
          }),
        )
        .otherwise(() => undefined);
    })
    .otherwise(() => undefined);

  const mutate = async () => {
    const queryKey = WidgetsByTemplateRevisionIdQuery.getKey(
      FormEditorPageMachineHelpers.getTemplateRevision(context)?.id,
    );

    const widget = await makeMutation(context.sharedContext.queryClient, {
      mutationFn: () => CreateWidgetMutation.mutationFn(widgetRequest),
      mutationKey: CreateWidgetMutation.getKey(),
      onMutate: () => {
        const rollbackData = context.sharedContext.queryClient.getQueryData(queryKey);

        if (optimisticWidget) {
          context.sharedContext.queryClient.setQueryData<Widget[]>(queryKey, current => {
            if (!current) return [optimisticWidget];

            if (!optimisticWidget.header.orderTree) return [...current, optimisticWidget];

            return [...current, optimisticWidget].sort(widgetComparer);
          });
        }

        return rollbackData;
      },
      onSuccess: newWidget => {
        const props = {
          [Key.WIDGET_LEGACY_TYPE]: `${newWidget.header.type}Widget`, // Legacy property
          [Key.WIDGET_ID]: newWidget.id,
          [Key.WIDGET_TYPE]: newWidget.header.type,
          // @ts-expect-error -- TODO
          [Key.WIDGET_FIELD_TYPE]: newWidget.fieldType,
          [Key.TASK_ID]: taskTemplate.id,
          [Key.TASK_NAME]: taskTemplate.name,
        };
        AnalyticsService.trackEvent(AnalyticsConstants.Event.WIDGET_CREATED, props);
        context.sharedContext.queryClient.setQueryData<Widget[]>(queryKey, current => {
          return (
            current?.map(w => {
              if (w.header.id !== widgetRequest.headerId) return w;
              return newWidget;
            }) ?? []
          );
        });

        templateRevisionCacheSetter.updateDraftLastUpdatedDate();
      },
      onError: (_error, _variables, rollbackData) => {
        context.sharedContext.queryClient.setQueryData(queryKey, rollbackData);
      },
    }).execute();

    return widget;
  };

  const actor = spawn(fromPromise(mutate), { name: 'createWidgetMutation' });
  const widgetActor = optimisticWidget
    ? spawnWidgetMachine(optimisticWidget, template, context.sharedContext, context.isReadOnly)
    : null;

  actor.subscribe(state => {
    if (state.status === 'fulfilled') {
      widgetActor?.send({ type: 'FOCUS' });
      if (event.file.type.startsWith('video')) {
        widgetActor?.send({ type: 'UPLOAD', file: event.file });
      } else {
        widgetActor?.send({ type: 'CHANGE', value: event.file });
      }
    }
  });

  if (widgetActor) {
    widgetActor.send({ type: 'AUTO_FOCUS' });

    setTimeout(() => {
      widgetActor.send({ type: 'SCROLL_INTO_VIEW' });
    }, 50);
  }

  return {
    ...context,
    createWidgetMutationActorRefMap: {
      ...context.createWidgetMutationActorRefMap,
      [widgetRequest.headerId]: actor,
    },
    widgetActorMap: {
      ...context.widgetActorMap,
      ...(widgetActor ? { [widgetRequest.headerId]: widgetActor } : {}),
    },
  };
};

const widgetComparer = getOrderableComparer(<W extends { header: { orderTree?: string } }>(widget: W) => widget.header);
