import { Muid, Option } from '@process-street/subgrade/core';
import { MergeTagTarget } from '@process-street/subgrade/form';
import { ResolvedTag } from '@process-street/subgrade/merge-tags';
import { WidgetConstants } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { QueryClient, QueryFunctionContext, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { MergeTagsServiceUtils } from 'services/merge-tags';
import { MergeTagsByChecklistRevisionIdQuery } from './merge-tags-by-checklist-revision-id';

export type ResolvedMergeTagsByChecklistRevisionIdQueryParams = {
  checklistRevisionId?: Muid;
  taskId: Option<Muid>;
  mergeTagTarget: MergeTagTarget;
};

export type ResolvedMergeTagsByChecklistRevisionIdQueryResponse = ResolvedTag[];

const keyFactory = {
  key: [{ static: ['merge-tags', 'checklist-revision-id', 'resolved'] }],
  getKey: (params: ResolvedMergeTagsByChecklistRevisionIdQueryParams) => [{ ...keyFactory.key[0], ...params }],
};
type QueryKey = ReturnType<typeof keyFactory['getKey']>;

export const ResolvedMergeTagsByChecklistRevisionIdQuery = {
  ...keyFactory,
  queryFn:
    (queryClient: QueryClient) =>
    async ({
      queryKey: [{ checklistRevisionId, mergeTagTarget, taskId }],
    }: QueryFunctionContext<QueryKey>): Promise<ResolvedMergeTagsByChecklistRevisionIdQueryResponse> => {
      const context = WidgetConstants.FormFieldKeysContext.MERGE_TAG;
      const tags = await queryClient.fetchQuery(
        MergeTagsByChecklistRevisionIdQuery.getKey({ checklistRevisionId, context, mergeTagTarget }),
        MergeTagsByChecklistRevisionIdQuery.queryFn(queryClient),
        { staleTime: Infinity },
      );
      if (!checklistRevisionId) return [];
      return MergeTagsServiceUtils.buildResolvedTags({
        tags,
        taskId,
        queryClient,
        checklistRevisionId,
      });
    },
};

export const useResolvedMergeTagsByChecklistRevisionIdQuery = <
  Select = ResolvedMergeTagsByChecklistRevisionIdQueryResponse,
>(
  params: Omit<ResolvedMergeTagsByChecklistRevisionIdQueryParams, 'queryClient'>,
  options: UseQueryOptions<ResolvedMergeTagsByChecklistRevisionIdQueryResponse, AxiosError, Select, QueryKey> = {},
) => {
  const queryClient = useQueryClient();
  return useQuery(
    ResolvedMergeTagsByChecklistRevisionIdQuery.getKey(params),
    ResolvedMergeTagsByChecklistRevisionIdQuery.queryFn(queryClient),
    {
      ...options,
      enabled: Boolean(params.checklistRevisionId) && options.enabled !== false,
    },
  );
};
