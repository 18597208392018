import React from 'react';
import { SolutionTypeTag } from '@process-street/subgrade/automation';
import { Text, Button, Icon } from 'components/design/next';
import * as AutomationSelectorMachine from 'pages/templates/_id/automation/components/selector/context';

export interface AutomationTypeProps {
  title: string;
  type: SolutionTypeTag;
  selected?: boolean;
  rightIcon?: React.ReactElement;
}

export const AutomationType: React.FC<React.PropsWithChildren<AutomationTypeProps>> = ({
  selected,
  title,
  type,
  rightIcon,
}) => {
  const [, send] = AutomationSelectorMachine.useAutomationSelector();

  const handleNewAutomationClick = () => {
    send({ type: 'SOLUTION_TYPE_SELECTED', payload: { solutionTypeTag: type } });
  };
  return (
    <Button
      ref={node => {
        if (selected) node?.scrollIntoView();
      }}
      onClick={handleNewAutomationClick}
      variant={selected ? 'primary' : 'tertiary'}
      size="xl"
      w="full"
      textAlign="left"
      fontWeight="normal"
      justifyContent="space-between"
      rightIcon={rightIcon ?? <Icon color={selected ? 'white' : 'brand.500'} size="5" icon="plus" variant="far" />}
    >
      <Text noOfLines={1}>{title}</Text>
    </Button>
  );
};
