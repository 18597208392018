import * as React from 'react';
import {
  Box,
  Container,
  HStack,
  Icon,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Text,
  useToast,
  VStack,
} from 'components/design/next';
import { Sidebar } from './components/sidebar';
import { FocusBar } from 'components/focus-bar';
import { TemplateType } from '@process-street/subgrade/process';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { PieCharts } from './components/pie-charts';
import { useDispatch } from 'react-redux';
import { toSuccess } from 'reducers/util';
import { TEMPLATE_GET_BY_ID } from 'reducers/template/template.actions';
import { Summary } from './components/summary';
import { Reports } from './components/reports';
import { BackgroundImage } from './components/background-image';
import { useInjector } from 'components/injection-provider';
import { Activity } from './components/activity';
import { SectionWrapper } from './components/section-wrapper';
import { GetFeatureFlagsQuery } from 'features/feature-flags/query-builder/feature-flags';
import { TOP_BAR_HEIGHT_TOKEN } from 'pages/forms/_id/shared';
import {
  activeChecklistsSavedViewFilter,
  allChecklistsSavedViewFilter,
  ChecklistSavedViewConfigProvider,
} from './dashboard-provider';
import { TemplateViewUtils } from 'features/template/view-utils';
import { ProgressStatus } from '@process-street/subgrade/dashboard';
import { useSwitchOrganizationIfNeeded } from 'hooks/use-switch-organization-if-needed';

export type DashboardProps = {};

const tabProps: Partial<TabProps> = {
  bgColor: 'white',
  borderBottomColor: 'gray.300',
  borderBottomStyle: 'solid',
  borderBottomWidth: 0.5,
  color: 'gray.500',
  fontWeight: 'medium',
  marginBottom: 0,
  _selected: {
    borderBottomColor: 'brand.500',
    color: 'brand.500',
  },
};

export const Dashboard: React.FC<React.PropsWithChildren<DashboardProps>> = () => {
  const { $stateParams, $state } = useInjector('$stateParams', '$state');
  const { id: templateId } = $stateParams;
  const [activeProgressStatusFilter, setActiveProgressStatusFilter] = React.useState<ProgressStatus | null>(null);

  if (!templateId) throw new Error('templateId is missing');

  const dispatch = useDispatch();
  const templateQuery = useGetTemplateQuery({ templateId }, { retry: 1 });

  useSwitchOrganizationIfNeeded({ organizationId: templateQuery?.data?.organization?.id });

  React.useEffect(() => {
    const template = templateQuery.data;
    if (template) {
      if (template.templateType === TemplateType.Task) {
        $state.go('dashboard');
        return;
      }

      dispatch({
        type: toSuccess(TEMPLATE_GET_BY_ID),
        payload: templateQuery.data,
        meta: { templateId },
      });
    }
  }, [$state, dispatch, templateId, templateQuery.data]);

  React.useEffect(() => {
    if (templateQuery?.data?.name) {
      // circumvent Angular data bind in app.component.html
      setTimeout(() => (document.title = `${templateQuery?.data?.name} | Process Street`), 0);
    }
  }, [templateQuery.data]);

  const toast = useToast();

  React.useEffect(() => {
    if (templateQuery.error) {
      const responseStatus = templateQuery.error.response?.status;
      responseStatus && TemplateViewUtils.showWorkflowViewErrorToast(responseStatus, toast);
      $state.go('dashboard');
    }
  }, [$state, templateId, templateQuery.error, toast]);

  const featureFlagsQuery = GetFeatureFlagsQuery.useQuery({
    select: ff => ff.workflowDashboard,
  });
  const isTemplateDashboardEnabled = featureFlagsQuery.data ?? false;

  React.useEffect(() => {
    if (!featureFlagsQuery.isLoading && !isTemplateDashboardEnabled) {
      // Redirect the user to the old `templateView` in case the feature flag is not enabled.
      $state.go('templateView', { id: templateId, redirectedFromDashboard: true });
    }
  }, [featureFlagsQuery, $state, templateId, isTemplateDashboardEnabled]);

  if (featureFlagsQuery.isLoading || !isTemplateDashboardEnabled) return null;

  return (
    <VStack w="full" spacing={0}>
      <FocusBar key="dashboard-focus-bar" templateType={TemplateType.Playbook} />

      <HStack w="full" bgColor="white" m={0} alignItems="flex-start" spacing="0">
        <Box position="relative" w="100%" backgroundColor="gray.50">
          <BackgroundImage templateId={templateId} />

          <Container
            as={VStack}
            spacing={{ base: 6, lg: 8 }}
            maxW="container.xl"
            pt={{ base: TOP_BAR_HEIGHT_TOKEN + 4, sm: TOP_BAR_HEIGHT_TOKEN + 8 }}
            px={{ base: 3, sm: 8 }}
            zIndex={2}
          >
            <SimpleGrid w="full" templateColumns={{ base: '1fr', lg: '2fr 1fr' }} gap={6}>
              <ChecklistSavedViewConfigProvider templateId={templateId} filter={activeChecklistsSavedViewFilter}>
                <PieCharts />
              </ChecklistSavedViewConfigProvider>

              <ChecklistSavedViewConfigProvider templateId={templateId} filter={allChecklistsSavedViewFilter}>
                <Summary
                  onSelectProgressStatus={setActiveProgressStatusFilter}
                  activeProgressStatus={activeProgressStatusFilter}
                />
              </ChecklistSavedViewConfigProvider>
            </SimpleGrid>

            <VStack w="full" zIndex={2}>
              <Tabs w="full" variant="enclosed" isLazy>
                <TabList as={HStack} spacing={0.5} border="none">
                  <Tab {...tabProps} as={HStack} spacing={2}>
                    <Icon icon="play" size="4" />
                    <Text>Workflow runs</Text>
                  </Tab>
                  <Tab {...tabProps} as={HStack} spacing={2}>
                    <Icon icon="clock-rotate-left" size="4" />
                    <Text>Activity</Text>
                  </Tab>
                </TabList>
                <VStack
                  as={SectionWrapper}
                  zIndex={2}
                  p="4"
                  alignItems="flex-start"
                  w="full"
                  borderTopLeftRadius="0"
                  mb="8"
                >
                  <TabPanels>
                    <TabPanel p="0">
                      <Reports
                        templateId={templateId}
                        activeProgressStatus={activeProgressStatusFilter}
                        onResetFilters={() => setActiveProgressStatusFilter(null)}
                      />
                    </TabPanel>
                    <TabPanel p="0">
                      <Activity templateId={templateId} />
                    </TabPanel>
                  </TabPanels>
                </VStack>
              </Tabs>
            </VStack>
          </Container>
        </Box>

        <ChecklistSavedViewConfigProvider templateId={templateId} filter={allChecklistsSavedViewFilter}>
          <Sidebar template={templateQuery.data} />
        </ChecklistSavedViewConfigProvider>
      </HStack>
    </VStack>
  );
};
