import { VideoWidgetService } from '../process';

const YOU_TUBE_REGEX = /(?:youtube\.com|youtu\.be)(?:\/.*)?(?:\/|v=)([A-Za-z0-9-_]{11})/;
const VIMEO_REGEX = /vimeo\.com.*\/(\d+)/;
const WISTIA_REGEX = /wistia\.(?:net|com)\/.*?\/([A-Za-z0-9]{10})/;
const LOOM_REGEX = /loom\.com\/(?:share|embed)\/(\w+)/;

export function parseEmbedServiceValue(embedValue: string) {
  const trimmedInput = embedValue && embedValue.trim();
  const youTubeMatches = YOU_TUBE_REGEX.exec(trimmedInput);
  if (youTubeMatches !== null) {
    return {
      service: VideoWidgetService.YouTube,
      serviceCode: youTubeMatches[1],
    };
  }
  const vimeoMatches = VIMEO_REGEX.exec(trimmedInput);
  if (vimeoMatches !== null) {
    return {
      service: VideoWidgetService.Vimeo,
      serviceCode: vimeoMatches[1],
    };
  }
  const wistiaMatches = WISTIA_REGEX.exec(trimmedInput);
  if (wistiaMatches !== null) {
    return {
      service: VideoWidgetService.Wistia,
      serviceCode: wistiaMatches[1],
    };
  }
  const loomMatches = LOOM_REGEX.exec(trimmedInput);
  if (loomMatches !== null) {
    return {
      service: VideoWidgetService.Loom,
      serviceCode: loomMatches[1],
    };
  }
  return null;
}
