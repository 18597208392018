import { TemplateMigrationModuleState } from 'app/reducers/types';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { Action } from 'redux-actions';
import {
  GetMigrationStatsSuccessActionPayload,
  MigrationSetTotalActionPayload,
  MigrationSuccessActionPayload,
  TEMPLATE_MIGRATION_GET_MIGRATION_STATS_SUCCESS,
  TEMPLATE_MIGRATION_SET_TOTAL,
  TEMPLATE_MIGRATION_SUCCESS,
  TEMPLATE_MIGRATION_UPDATE_POLLER_STATUS,
  UpdatePollingStatusPayload,
} from './template-migration.actions';

const setMigrationTotalCount = (
  state: TemplateMigrationModuleState | undefined,
  { payload }: Action<MigrationSetTotalActionPayload | MigrationSuccessActionPayload>,
) => {
  const nextState: TemplateMigrationModuleState = { ...state };
  const { templateId } = payload;
  nextState[templateId] = {
    ...nextState[templateId],
    stats: {
      countTotal: payload.countTotal,
    },
    templateRevisionId: payload.templateRevisionId,
  };
  return nextState;
};
export const templateMigrationReducers = handleActionsOnSuccess(
  {
    [TEMPLATE_MIGRATION_GET_MIGRATION_STATS_SUCCESS]: (
      state: TemplateMigrationModuleState | undefined,
      { payload }: Action<GetMigrationStatsSuccessActionPayload>,
    ): TemplateMigrationModuleState => {
      const nextState: TemplateMigrationModuleState = { ...state };
      const { templateId } = payload;
      const newStats = {
        countMigrated: payload.stats.countMigrated,
        countMigrating: payload.stats.countMigrating,
        countScheduled: payload.stats.countScheduled,
        countTotal: nextState[templateId].stats!.countTotal,
      };

      if (nextState[templateId]) {
        nextState[templateId] = {
          ...nextState[templateId],
          stats: newStats,
        };
      }
      return nextState;
    },

    [TEMPLATE_MIGRATION_SET_TOTAL]: setMigrationTotalCount,

    [TEMPLATE_MIGRATION_SUCCESS]: setMigrationTotalCount,

    [TEMPLATE_MIGRATION_UPDATE_POLLER_STATUS]: (
      state: TemplateMigrationModuleState | undefined,
      { payload }: Action<UpdatePollingStatusPayload>,
    ) => {
      const nextState: TemplateMigrationModuleState = { ...state };
      const { templateId } = payload;
      nextState[templateId] = {
        ...nextState[templateId],
        pollingStatus: payload.pollingStatus,
        templateRevisionId: payload.templateRevisionId,
      };
      return nextState;
    },
  },
  {},
);
