import * as React from 'react';
import { Formik, Form, useField, useFormikContext } from 'formik';
import {
  Text,
  Box,
  HStack,
  VStack,
  Checkbox,
  FormControl,
  CheckboxProps,
  FormLabel,
  Input,
  Select,
  Radio,
  RadioGroup,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Button,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Tooltip,
  useModalContext,
} from 'components/design/next';
import {
  NumberFormFieldConfig,
  NumberFormFieldConstraints,
  NumberFormFieldWidget,
  WidgetUtils,
} from '@process-street/subgrade/process';

import { NumberField, Placeholder } from '../fields';
import {
  NumberSettingsSchema,
  numberSettingsSchema,
} from 'components/widgets/form-field/settings/number-settings/number-settings-schema';
import { DefaultValueField } from 'components/widgets/form-field/settings/common/default-value-field';
import { match, P } from 'ts-pattern';
import { useFunctionRef } from 'hooks/use-function-ref';
import { useWidgetSettingsContext } from '../widget-settings-context';
import { DigitsInfoPopover, ValueInfoPopover } from './info-popovers';

type ConstraintsKey = keyof NumberFormFieldWidget['constraints'];
type ConfigKey = keyof NumberFormFieldWidget['config'];

// For these fields, empty strings should be deleted
const EMPTY_STRING_TO_UNDEFINED_KEYS: ConfigKey[] = ['placeholder', 'unit'];
// For these fields, `false` should be deleted
const FALSE_TO_UNDEFINED_KEYS: ConstraintsKey[] = ['allowNegative'];

/**
 * Removes undefined or false values from the form object. Allows a user to reset the constraints to {}.
 */
const sanitizeData = (values: NumberSettingsSchema): NumberSettingsSchema => {
  EMPTY_STRING_TO_UNDEFINED_KEYS.forEach(key => {
    if (values[key] === '') {
      delete values[key];
    }
  });
  FALSE_TO_UNDEFINED_KEYS.forEach(key => {
    if (values[key] === false) {
      delete values[key];
    }
  });

  if (!values.unit && values.unitLocation) {
    delete values.unitLocation;
  }

  Object.entries(values)
    .filter(([_key, value]) => value === undefined || Number.isNaN(value) || value === '')
    .forEach(([key]) => {
      delete values[key as keyof typeof values];
    });

  return values;
};

export const NumberSettings = () => {
  const { widget, onUpdate } = useWidgetSettingsContext<NumberFormFieldWidget>();
  const { onClose } = useModalContext();

  const initialValues: NumberSettingsSchema = {
    ...widget.config,
    ...widget.constraints,
    hasVariables: WidgetUtils.hasVariables(widget.config.defaultValue),
  };

  const handleSubmit = (values: NumberSettingsSchema) => {
    const { placeholder, unit, unitLocation, defaultValue, hasVariables: _, ...rest } = sanitizeData(values);
    const config: NumberFormFieldConfig = {
      placeholder,
      unit,
      unitLocation,
      defaultValue,
    };
    const constraints: NumberFormFieldConstraints = rest;
    onUpdate({ ...widget, config, constraints });
    onClose();
  };

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader p={8}>
        <Text variant="2">{widget.label || 'Untitled number field'}</Text>
      </ModalHeader>

      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={numberSettingsSchema}>
        {({ isValid, dirty, values, getFieldProps }) => (
          <Form>
            <ModalBody px={9} py={2}>
              <VStack alignItems="flex-start" spacing="6">
                <VStack as="fieldset" alignItems="flex-start" spacing="1.5">
                  <HStack>
                    <Text as="legend" variant="1" fontWeight="medium" color="gray.700" borderBottom="none">
                      Value
                    </Text>

                    <Box position="relative">
                      <ValueInfoPopover />
                    </Box>
                  </HStack>
                  <HStack spacing="6">
                    <NumberField
                      {...getFieldProps('minValue')}
                      aria-label="set min value"
                      max={values.maxValue || undefined}
                    >
                      Min
                    </NumberField>
                    <NumberField
                      {...getFieldProps('maxValue')}
                      aria-label="set max value"
                      min={values.minValue || undefined}
                    >
                      Max
                    </NumberField>
                  </HStack>
                </VStack>

                <VStack as="fieldset" alignItems="flex-start" spacing="1.5">
                  {/* Border bottom applied by global scss */}
                  <HStack>
                    <Text as="legend" variant="1" fontWeight="medium" color="gray.700" borderBottom="none">
                      Digits
                    </Text>
                    <Box position="relative">
                      <DigitsInfoPopover />
                    </Box>
                  </HStack>

                  <HStack spacing="6">
                    <NumberField
                      {...getFieldProps('minDigits')}
                      aria-label="set min digits"
                      min={1}
                      max={values.maxDigits}
                    >
                      Min
                    </NumberField>
                    <NumberField
                      {...getFieldProps('maxDigits')}
                      aria-label="set max digits"
                      min={values.minDigits ?? 1}
                    >
                      Max
                    </NumberField>
                  </HStack>
                </VStack>

                <DecimalPlacesSelectField />

                <AllowNegativeNumbersCheckbox />

                <UnitField />

                <Placeholder name="placeholder" />

                <DefaultValueField />
              </VStack>
            </ModalBody>
            <ModalFooter p={6}>
              <ButtonGroup>
                <Button aria-label="cancel changes" variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button aria-label="set constraints" isDisabled={!(isValid && dirty)} type="submit">
                  Apply
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};

const CheckboxField: React.FC<React.PropsWithChildren<{ name: string } & CheckboxProps>> = ({
  name,
  children,
  ...props
}) => {
  const [field, meta] = useField(name);

  return (
    <FormControl>
      <Checkbox {...field} id={field.name} {...props} isInvalid={!!meta.error && meta.touched} isChecked={field.value}>
        <Text color="gray.600" fontWeight="normal">
          {children}
        </Text>
      </Checkbox>
    </FormControl>
  );
};

const UnitField: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [field, meta] = useField('unit' as ConfigKey);

  return (
    <FormControl>
      <FormLabel htmlFor="unit">
        <Text color="gray.700">Unit</Text>
      </FormLabel>
      <Input
        {...field}
        value={field.value ?? ''}
        id="unit"
        maxLength={3}
        aria-label="unit"
        placeholder="%, $, €, £, °F, °C, 📞,..."
        variant="outline"
        isInvalid={meta.touched && !!meta.error}
      />
      {Boolean(field.value) && <UnitLocationField />}
    </FormControl>
  );
};

const AllowNegativeNumbersCheckbox: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [field, _meta, { setValue }] = useField('allowNegative' as ConfigKey);
  const setValueRef = useFunctionRef(setValue);

  const { values } = useFormikContext<NumberSettingsSchema>();
  const { minValue, maxValue } = values ?? {};

  const derivedConstraint = React.useMemo(() => {
    return match({ minValue, maxValue })
      .with({ minValue: P.when(min => min < 0) }, { maxValue: P.when(max => max < 0) }, () => {
        return 'allow' as const;
      })
      .with({ minValue: P.when(min => typeof min === 'number' && min >= 0) }, () => {
        return 'disallow' as const;
      })
      .otherwise(() => undefined);
  }, [maxValue, minValue]);

  React.useEffect(() => {
    match(derivedConstraint)
      .with('allow', () => {
        setValueRef.current(true);
      })
      .otherwise(() => {
        setValueRef.current(false);
      });
  }, [derivedConstraint, setValueRef]);

  const isDisabled = derivedConstraint !== undefined;

  return (
    <Tooltip shouldWrapChildren label="Max or min value determines this" hasArrow>
      <CheckboxField {...field} aria-label="allow negative numbers" isDisabled={isDisabled}>
        Allow negative numbers
      </CheckboxField>
    </Tooltip>
  );
};

const UnitLocationField: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [field, meta, { setValue }] = useField('unitLocation' as ConfigKey);
  const {
    values: { unit },
  } = useFormikContext<NumberSettingsSchema>();

  React.useEffect(() => {
    if (unit !== undefined && unit !== '' && !field.value) {
      setValue('prefix');
    }
  }, [field.value, setValue, unit]);

  return (
    <FormControl pt={6} isInvalid={meta.touched && !!meta.error}>
      <RadioGroup id="unitLocation" defaultValue="prefix" aria-label="unit location" {...field}>
        <HStack spacing="4">
          {/* Needed because its inheriting a label style from _form.scss */}
          <Radio mb={0} onChange={field.onChange} value="prefix">
            Prefix
          </Radio>
          <Radio onChange={field.onChange} value="suffix">
            Suffix
          </Radio>
        </HStack>
      </RadioGroup>
    </FormControl>
  );
};

export const DecimalPlacesSelectField: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [field, meta, helper] = useField('decimalPlaces' as ConstraintsKey);
  const { setValue } = helper;

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(parseInt(e.target.value));
  };
  return (
    <FormControl>
      <FormLabel htmlFor="decimalPlaces">
        <Text variant="1" fontWeight="medium" color="gray.700">
          Decimal places
        </Text>
      </FormLabel>
      <Select
        id="decimalPlaces"
        {...field}
        value={Number.isNaN(field.value) ? '' : field.value}
        w="full"
        isInvalid={meta.touched && !!meta.error}
        onChange={onChange}
      >
        <option value="">Decimals not allowed</option>
        <option value="1">1 decimal</option>
        <option value="2">2 decimals</option>
        <option value="3">3 decimals</option>
      </Select>
    </FormControl>
  );
};
