import { Activity } from '@process-street/subgrade/activity';
import { htmlEscaped, escapeHtml, DateUtils } from '@process-street/subgrade/util';
import Encase from 'encase';
import { StringService } from 'services/string-service';
import { match, P } from 'ts-pattern';
import { InterpretedActivity } from './interpreters/InterpreterContext';

export const valuateData = (data: Activity['data'], timeZone: string | undefined) => {
  const keys = Object.keys(data);
  const object: any = {};
  if (keys.length) {
    const [key] = keys;
    let value: string = data[key as keyof typeof data];
    const formattedK = Encase.separate(keys[0]).join(' ').toLowerCase();
    if (value !== null && (formattedK === 'date' || formattedK.endsWith('date'))) {
      value = DateUtils.formatActivityTime({
        date: parseFloat(value),
        timeZone,
      });
    }
    const formattedV = escapeHtml(StringService.abbreviate(value));
    object.value =
      value === null ? htmlEscaped`<b>${formattedK}</b> to none` : htmlEscaped`<b>${formattedK}</b> to "${formattedV}"`;
    object.tooltip = value;
  }
  return object;
};

export const interpret = (activity: Activity): InterpretedActivity => {
  const subjectName = match(activity)
    .with({ data: { subjectName: P.string } }, v => v.data.subjectName)
    .with({ subject: { username: P.string } }, v => v.subject.username)
    .otherwise(() => '');

  return {
    activity,
    subject: { ...activity.subject, value: subjectName },
    verb: { value: '' },
    objects: [],
    contextObjects: [],
    date: activity.date,
  };
};
