import get from 'lodash/get';

export const entitiesSelector = state => state.entities;

const joinWithIncluded = (element, includedCollection, allEntities) => {
  const parts = includedCollection.split('.');

  parts.reduce((agg, field) => {
    const collectionName = `${field}s`;
    if (agg[field]) {
      const { id } = agg[field];
      const included = allEntities[collectionName][id];
      if (included) {
        agg[field] = { ...included };
        return agg[field];
      } else {
        return agg;
      }
    } else {
      return agg;
    }
  }, element);
};

const join = (model, includes = [], allEntities) =>
  includes.reduce(
    (agg, includedCollection) => {
      joinWithIncluded(agg, includedCollection, allEntities);
      return agg;
    },
    { ...model },
  );

const includeAndApply = (f, predicate, includes = [], store, collection) => {
  const allEntities = entitiesSelector(store.getState());
  const all = Object.values(allEntities[collection]);

  if (all.length === 0) {
    return f.call(all, () => true);
  } else if (typeof predicate === 'function') {
    const allWithRef = includes.length > 0 ? all.map(model => join(model, includes, allEntities)) : all;

    return f.call(allWithRef, predicate);
  } else {
    const predicateF = val => Object.keys(predicate).every(key => get(val, key) === predicate[key]);

    const includesRequiredKeys = Object.keys(predicate).every(key => {
      const keyValue = get(all[0], key);
      return keyValue !== undefined;
    });
    if (includesRequiredKeys) {
      const results = f.call(all, predicateF);
      if (results && Array.isArray(results)) {
        return results.map(el => join(el, includes, allEntities));
      } else if (results) {
        return join(results, includes, allEntities);
      } else {
        return null;
      }
    } else {
      const allWithRef = includes.length > 0 ? all.map(model => join(model, includes, allEntities)) : all;
      return f.call(allWithRef, predicateF);
    }
  }
};

export class EntitiesSelectors {
  constructor(collection, store) {
    this.collection = collection;
    this.store = store;
  }

  calculateGet = (id, includes = []) => {
    const state = entitiesSelector(this.store.getState());
    const model = state[this.collection][id];

    if (!model || includes.length === 0) {
      return model;
    } else {
      return join(model, includes, state);
    }
  };

  calculateFind = (predicate, includes = []) =>
    includeAndApply(Array.prototype.find, predicate, includes, this.store, this.collection);

  calculateFilter = (predicate, includes) =>
    includeAndApply(Array.prototype.filter, predicate, includes, this.store, this.collection);

  calculateSome = (predicate, includes = []) => !!this.calculateFind(predicate, includes);
}
