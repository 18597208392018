import { Avatar, Option } from '@process-street/subgrade/core';
import { FormFieldValue, MembersFieldValue } from '@process-street/subgrade/process';
import { ObjectMap } from '@process-street/subgrade/redux/types';
import angular from 'angular';
import { AvatarService } from 'components/avatars/services/avatar-service.interface';
import ngRedux from 'ng-redux';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { connectController } from 'reducers/util';
import { createSelector } from 'reselect';
import templateUrl from './members-renderer.component.html';
import './members-renderer.component.scss';

export class ApprovalSubjectTaskWidgetMembersRendererController {
  public formFieldValue: Option<FormFieldValue>;

  static $inject = ['$ngRedux', 'AvatarService'];
  constructor(private $ngRedux: ngRedux.INgRedux, private avatarService: AvatarService) {
    const mapStateToThis = () =>
      createSelector(
        [OrganizationMembershipSelector.getAllWithUserBySelectedOrganizationId],
        organizationMemberships => {
          const memberships = this.formFieldValue
            ? organizationMemberships.filter(om =>
                (this.formFieldValue!.fieldValue as MembersFieldValue).organizationMembershipIds?.includes(om.id),
              )
            : [];

          const avatarsMap = memberships.reduce((map: ObjectMap<Avatar>, om) => {
            map[om.id] = this.avatarService.getAvatar(om && om.user);
            return map;
          }, {});

          return {
            avatarsMap,
            memberships,
          };
        },
      );

    const mapDispatchToThis = () => ({});

    connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, null)(this);
  }

  public $onChanges(changes: { formFieldValue: angular.IChangesObject<FormFieldValue> }) {
    const { formFieldValue } = changes;
    if (formFieldValue && formFieldValue.currentValue) {
      this.formFieldValue = formFieldValue.currentValue;
    }
  }
}

export const ApprovalSubjectTaskWidgetMembersRenderer: angular.IComponentOptions = {
  bindings: {
    formFieldValue: '<',
  },
  controller: ApprovalSubjectTaskWidgetMembersRendererController,
  templateUrl,
};
