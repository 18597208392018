import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  AlertIcon,
  Button,
  Text,
  VStack,
} from 'components/design/next';
import * as React from 'react';

export type UpdateSavedViewFiltersDialogProps = {
  linkedWorkflowsCount: number;
  onUpdateView: () => void;
} & Omit<AlertDialogProps, 'children' | 'leastDestructiveRef'>;

export const UpdateSavedViewFiltersDialog = ({
  linkedWorkflowsCount,
  isOpen,
  onClose,
  onUpdateView,
  ...props
}: UpdateSavedViewFiltersDialogProps) => {
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} {...props} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogCloseButton pt="3" />
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Update View
          </AlertDialogHeader>
          <AlertDialogBody>
            <VStack alignItems="stretch" spacing="5">
              <Alert fontWeight="700" color="gray.700" status="warning">
                <AlertIcon />
                This view is connected to {linkedWorkflowsCount} workflows
              </Alert>
              <Text>
                Your view will be updated and <strong>can&apos;t be recovered.</strong>
              </Text>
            </VStack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant="secondary" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              ml="3"
              onClick={() => {
                onUpdateView();
                onClose();
              }}
            >
              Update View
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
