import { HStack, StackProps } from 'components/design/next';
import * as React from 'react';
import { useGetFocusBarColor } from './use-get-focus-bar-color';
import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';
import { useMatch } from '@process-street/adapters/navigation';

export const WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT = 18;
export const WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT_V2 = 16;

export const WORKFLOW_DASHBOARD_FOCUS_BAR_HEIGHT = 16;

export const FOCUS_BAR_BORDER_WIDTH = 1;

export const FocusBarHStack: React.FC<React.PropsWithChildren<StackProps>> = props => {
  const getFocusBarColor = useGetFocusBarColor();
  const workflowState = useWorkflowState();
  const isDashboard = workflowState === 'dashboard';
  const isEditorV2 = Boolean(useMatch('templateV2')) || Boolean(useMatch('templateViewV2'));
  const isViewV2 = Boolean(useMatch('templateViewV2'));
  return (
    <HStack
      borderBottomStyle="solid"
      borderBottomWidth={isViewV2 ? `${FOCUS_BAR_BORDER_WIDTH}px` : 0}
      borderBottomColor="gray.200"
      data-hide-on-print
      h={
        isDashboard
          ? WORKFLOW_DASHBOARD_FOCUS_BAR_HEIGHT
          : isEditorV2
          ? WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT_V2
          : isViewV2
          ? WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT_V2 + FOCUS_BAR_BORDER_WIDTH
          : WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT
      }
      w="full"
      py="2"
      px={['4', null, '3']}
      position="fixed"
      background={getFocusBarColor({ light: 'gray.50', dark: 'gray.700', dashboard: 'white' })}
      zIndex="docked"
      justify="space-between"
      spacing="4"
      {...props}
    />
  );
};
