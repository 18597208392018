import { Node } from 'directives/rules/template/task-templates-selector/selector-helper';
import { Dispatch, useReducer } from 'react';
import range from 'lodash/range';
import { useConditionalLogicModalStore } from '../modal/store';

// The number of accordion items before we start looping the task's form fields.
// It's 2 because we render the "previous rule tasks" and the "selected form field's task"
// before the affected tasks/form fields.
const ACCORDION_ITEMS_COUNT_BEFORE_FORM_FIELDS = 2;

export type UseConditionalLogicAccordionOptions = {
  taskNodes: Node[];
};

export type UseConditionalLogicAccordionReturn = {
  state: AccordionState;
  dispatch: Dispatch<AccordionAction>;
  collapse: () => void;
  expand: () => void;
  set: (indexes: Set<number>) => void;
  toggle: (index: number) => void;
};

export const useConditionalLogicAccordion = ({
  taskNodes,
}: UseConditionalLogicAccordionOptions): UseConditionalLogicAccordionReturn => {
  const { selectedWidget } = useConditionalLogicModalStore();

  const [accordionState, dispatchAccordionState] = useReducer(accordionStateReducer, {
    // Adding +2 because we might have aditional accordion items based on the selected widget
    allItems: new Set(range(taskNodes.length + ACCORDION_ITEMS_COUNT_BEFORE_FORM_FIELDS)),
    current: new Set(selectedWidget ? range(taskNodes.length + ACCORDION_ITEMS_COUNT_BEFORE_FORM_FIELDS) : []),
  } as AccordionState);

  return {
    state: accordionState,
    dispatch: dispatchAccordionState,
    collapse: () => dispatchAccordionState({ type: 'collapse' }),
    expand: () => dispatchAccordionState({ type: 'expand' }),
    set: indexes => dispatchAccordionState({ type: 'set', indexes }),
    toggle: index => dispatchAccordionState({ type: 'toggle', index }),
  };
};

export type AccordionState = { allItems: Set<number>; current: Set<number> };

type AccordionAction =
  | { type: 'expand' }
  | { type: 'collapse' }
  | { type: 'set'; indexes: Set<number> }
  | { type: 'toggle'; index: number };

const accordionStateReducer = (state: AccordionState, action: AccordionAction) => {
  switch (action.type) {
    case 'collapse':
      return { ...state, current: new Set([]) };
    case 'set':
      return { ...state, current: action.indexes ?? state.allItems };
    case 'toggle': {
      const newCurrent = new Set(state.current);

      if (state.current.has(action.index)) {
        newCurrent.delete(action.index);
      } else {
        newCurrent.add(action.index);
      }

      return { ...state, current: newCurrent };
    }

    case 'expand':
    default:
      return { ...state, current: state.allItems };
  }
};
