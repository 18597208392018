import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  AlertDialogContent,
  ButtonGroup,
  Button,
  Text,
} from 'components/design/next';
import { useAutomationSelector } from './context';
import { useUnsavedChangesBrowserAlert } from './use-unsaved-changes-browser-alert';

export const UnsavedChangesAlertDialog: React.VFC = () => {
  const [state, send] = useAutomationSelector();
  const isOpen = state.matches('editing.unsavedChanges');
  const leastDestructiveRef = React.useRef<HTMLButtonElement>(null);
  const handleClose = () => {
    send('STAY');
  };

  const handleLeave = () => {
    send([{ type: 'SET_HAS_UNSAVED_CHANGES', hasUnsavedChanges: false }, state.event]);
  };

  useUnsavedChangesBrowserAlert();

  return (
    <AlertDialog {...{ isOpen, leastDestructiveRef, onClose: handleClose }}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          <Text variant="1">You have unsaved changes</Text>
        </AlertDialogHeader>
        <AlertDialogCloseButton />

        <AlertDialogBody>Are you sure you want to leave?</AlertDialogBody>
        <AlertDialogFooter>
          <ButtonGroup>
            <Button variant="tertiary" onClick={handleClose} ref={leastDestructiveRef}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleLeave}>
              Leave
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
