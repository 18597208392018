import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Divider,
  Spacer,
  Text,
  useToast,
} from 'components/design/next';
import { useQueryClient } from 'react-query';
import {
  GetAllTemplatesQuery,
  GetTemplateQuery,
  UpdateTemplateStatusMutation,
  useGetTemplateQuery,
  useUpdateTemplateStatusMutation,
} from 'features/template/query-builder';
import { useTemplateMenuContext, useTemplateMenuDisclosureContext } from '../template-menu';
import { TemplateType, tmplTypeName, tmplTypeNameLower } from '@process-street/subgrade/process';
import {
  GetNewestTemplateRevisionsByTemplateIdQuery,
  GetNewestTemplateRevisionsByTemplateIdQueryResponse,
} from 'features/template-revisions/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { match } from 'ts-pattern';
import { AnalyticsService } from 'components/analytics/analytics.service';

export const ArchiveTemplateAlert = () => {
  const { templateId } = useTemplateMenuContext();
  const { archiveTemplateAlertDisclosure: disclosure } = useTemplateMenuDisclosureContext();
  const { isOpen, onClose } = disclosure;

  const { data: template } = useGetTemplateQuery({ templateId }, { enabled: Boolean(templateId) });
  const toast = useToast();

  const queryClient = useQueryClient();

  const archiveTemplateMutation = useUpdateTemplateStatusMutation({
    mutationKey: UpdateTemplateStatusMutation.getKey({ templateId, status: 'Archived' }),
    onSuccess: res => {
      AnalyticsService.trackEvent('template archived', {});
      void queryClient.invalidateQueries(GetAllTemplatesQuery.getKey({ organizationId: res.organization.id }));
      queryClient.setQueryData(GetTemplateQuery.getKey({ templateId }), res);
      queryClient.setQueryData<GetNewestTemplateRevisionsByTemplateIdQueryResponse | undefined>(
        GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId }),
        revisions => {
          return revisions?.map(revision => {
            return { ...revision, template };
          }) as GetNewestTemplateRevisionsByTemplateIdQueryResponse | undefined;
        },
      );
      onClose();
      toast({
        status: 'success',
        title: `${tmplTypeName(template)} archived`,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: `We're having problems archiving the ${tmplTypeNameLower(template)}`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const whatWillBeArchived = match(template?.templateType)
    .with(undefined, () => '')
    .with(TemplateType.Playbook, () => 'workflow and all of its runs')
    .with(TemplateType.Form, () => 'form and all of its responses')
    .with(TemplateType.Page, () => 'page')
    .run();

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onClose}
      onOverlayClick={onClose}
      scrollBehavior="inside"
      size="xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader py="4" px="8" fontSize="lg">
          Archive this {tmplTypeNameLower(template)}?
        </AlertDialogHeader>
        <AlertDialogCloseButton />

        <AlertDialogBody pt="4" px="8" pb="8">
          <Text>Your {whatWillBeArchived} will be archived.</Text>
        </AlertDialogBody>

        <Divider />

        <AlertDialogFooter py="6" px="8">
          <Spacer />
          <ButtonGroup>
            <Button ref={cancelRef} variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={archiveTemplateMutation.isLoading}
              isDisabled={archiveTemplateMutation.isLoading}
              variant="primary"
              onClick={() => archiveTemplateMutation.mutate({ templateId, status: 'Archived' })}
            >
              Archive
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
