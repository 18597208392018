import * as React from 'react';
import { VideoWidget, VideoWidgetService } from '@process-street/subgrade/process';
import { PagesRenderElementProps, PagesWidgetElement, usePagesEditorRef } from '../../pages-plate-types';
import { HStack, Icon, Input, InputGroup, InputLeftElement, Text } from 'components/design/next';
import { EditorVoid } from '../elements/void';
import { useIsEditable } from 'features/rich-text';
import { useDebouncedCallback } from 'use-debounce';
import { useMutation } from 'react-query';
import * as WidgetApi from 'pages/pages/_id/edit/page/utils/widget.api';
import { VideoUI } from './components/video-ui';
import { parseEmbedServiceValue } from '@process-street/subgrade/util/embed-service-code-parser';
import { PSEditor } from '../../utils/ps-editor';
import { UploadVideoElement } from 'pages/pages/_id/edit/page/components/upload';

export type EmbedInfo = {
  service: VideoWidgetService;
  serviceCode: string;
};

export const VideoElement: React.FC<
  React.PropsWithChildren<PagesRenderElementProps<PagesWidgetElement<VideoWidget>>>
> = ({ attributes, element, children }) => {
  const { widget } = element;
  const editor = usePagesEditorRef();
  const editable = useIsEditable();

  const updateWidgetMutation = useMutation((updatedWidget: VideoWidget) => WidgetApi.updateWidget(updatedWidget));

  const updatedWidgetDebounced = useDebouncedCallback(async (embed: EmbedInfo) => {
    const updatedWidget = await updateWidgetMutation.mutateAsync({
      ...widget,
      service: embed.service,
      serviceCode: embed.serviceCode,
    });

    PSEditor.setWidgetElement(editor, updatedWidget);
  }, 500);

  const [errorMessage, setErrorMessage] = React.useState('');
  const [uploadStarted, setUploadStarted] = React.useState(false);
  const handleChangeText = React.useCallback(
    (event: { target: { value: string } }) => {
      const { value } = event.target;

      setErrorMessage('');
      const parsed = parseEmbedServiceValue(value);
      if (parsed) {
        updatedWidgetDebounced(parsed);
      } else if (value !== '') {
        setErrorMessage('Video could not be embedded. Please try again.');
      }
    },
    [updatedWidgetDebounced],
  );

  const handleOnUploadFinish = React.useCallback(
    (updatedWidget: VideoWidget) => {
      // Mutation Slate state through node's widget change
      const embed = {
        service: updatedWidget.service,
        serviceCode: updatedWidget.serviceCode,
      } as EmbedInfo;

      updatedWidgetDebounced(embed);
    },
    [updatedWidgetDebounced],
  );

  return (
    <EditorVoid attributes={attributes} contentEditable={false}>
      {editable && !widget.service && (
        <HStack bg={errorMessage ? 'red.100' : 'gray.100'} p={4} borderRadius="md" spacing={3}>
          {!uploadStarted && (
            <HStack w="70%">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon size="4" icon="video" variant="far" color="gray.600" />}
                />
                <Input
                  variant="outline"
                  placeholder="Paste video URL or embed code here"
                  px={3}
                  py={4}
                  bg="white"
                  onChange={handleChangeText}
                />
              </InputGroup>
            </HStack>
          )}
          <UploadVideoElement
            data-hide-on-print
            widget={widget}
            onFinish={handleOnUploadFinish}
            onUploadStarted={() => setUploadStarted(true)}
            uploadingMessage="Uploading video..."
            acceptMimeTypes="video/*"
          >
            <HStack>
              <Icon icon="video" size="4" color="gray.700" mr="2" />
              <Text color="gray.700">Upload Video</Text>
            </HStack>
          </UploadVideoElement>
        </HStack>
      )}
      {widget.service && widget.serviceCode && (
        <VideoUI embed={{ service: widget.service, serviceCode: widget.serviceCode }} />
      )}
      {errorMessage ? <Text color="red.500">{errorMessage}</Text> : undefined}
      {children}
    </EditorVoid>
  );
};
