import { MergeTagsConstants } from '@process-street/subgrade/form/merge-tags-constants';
import { mailto } from '@process-street/subgrade/process';
import { safeEscapeHtml } from '@process-street/subgrade/util';
import angular from 'angular';
import templateUrl from './checklist-email-widget.component.html';
import { EventName } from 'services/event-name';
import { ChecklistEvent } from 'services/checklists/checklist-event';
import { PromiseQueueKeyGenerator } from 'app/services/promise-queue/promise-queue-key-generator-pure';
import { FormFieldEvent } from 'services/form-field-event';

angular.module('frontStreetApp.directives').component('psChecklistEmailWidget', {
  bindings: {
    user: '<',
    widget: '<',
    templateRevision: '<',
    checklistRevision: '<',
    task: '<',
    formFieldValueMap: '<',
    organization: '<',
    readOnly: '<',
  },
  templateUrl,
  controller(
    $scope,
    $q,
    $window,
    MergeTagsService,
    PromiseQueueDescGenerator,
    PromiseQueueService,
    util,
    WidgetService,
  ) {
    const ctrl = this;

    ctrl.Target = MergeTagsConstants.Target;

    ctrl.$onInit = function () {
      $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_UPDATED, () => {
        ctrl.parseWidgetContent(ctrl.widget);
      });

      $scope.$on(ChecklistEvent.UPDATE_OK, () => {
        ctrl.parseWidgetContent(ctrl.widget);
      });

      $scope.$on(EventName.TASK_DUE_DATE_UPDATE_OK, () => {
        ctrl.parseWidgetContent(ctrl.widget);
      });
    };

    ctrl.$onChanges = function (changes) {
      if (changes.widget) {
        ctrl.widget = changes.widget.currentValue;

        // This is to make sure the user can see something while the merge tags are replaced
        if (!ctrl.parsedWidget) {
          // only for the first time
          ctrl.parsedWidget = angular.copy(ctrl.widget);
        }
      }

      if (changes.task) {
        ctrl.task = changes.task.currentValue;
      }

      if (changes.formFieldValueMap) {
        ctrl.formFieldValueMap = changes.formFieldValueMap.currentValue;
      }

      if (changes.organization) {
        ctrl.organization = changes.organization.currentValue;
      }

      ctrl.parseWidgetContent(ctrl.widget);
    };

    /**
     * Opens mailto url in a new tab, fixes issue for Firefox which opens the link in the same window
     */
    ctrl.openMailTo = function () {
      if (ctrl.readOnly) return;
      // Fixing issue with blocked popup window for Safari
      if (util.isBrowserSafari()) {
        $window.location.href = ctrl.mailUrl;
      } else {
        $window.open(ctrl.mailUrl);
      }
    };

    /**
     * Parses widget's content within the context of checklist view
     *
     * @param widget
     */
    ctrl.parseWidgetContent = widget => {
      if (!widget) {
        return;
      }

      const recipient = MergeTagsService.parseChecklistContent(
        MergeTagsConstants.Mode.HTML,
        MergeTagsConstants.Target.EMAIL,
        safeEscapeHtml(widget.recipient),
        ctrl.templateRevision,
        ctrl.checklistRevision,
        ctrl.task,
        ctrl.formFieldValueMap,
        ctrl.user,
      );
      const cc = MergeTagsService.parseChecklistContent(
        MergeTagsConstants.Mode.HTML,
        MergeTagsConstants.Target.EMAIL,
        safeEscapeHtml(widget.cc),
        ctrl.templateRevision,
        ctrl.checklistRevision,
        ctrl.task,
        ctrl.formFieldValueMap,
        ctrl.user,
      );
      const bcc = MergeTagsService.parseChecklistContent(
        MergeTagsConstants.Mode.HTML,
        MergeTagsConstants.Target.EMAIL,
        safeEscapeHtml(widget.bcc),
        ctrl.templateRevision,
        ctrl.checklistRevision,
        ctrl.task,
        ctrl.formFieldValueMap,
        ctrl.user,
      );
      const subject = MergeTagsService.parseChecklistContent(
        MergeTagsConstants.Mode.HTML,
        MergeTagsConstants.Target.GENERAL,
        safeEscapeHtml(widget.subject),
        ctrl.templateRevision,
        ctrl.checklistRevision,
        ctrl.task,
        ctrl.formFieldValueMap,
        ctrl.user,
      );
      const body = MergeTagsService.parseChecklistContent(
        MergeTagsConstants.Mode.HTML,
        MergeTagsConstants.Target.GENERAL,
        safeEscapeHtml(widget.body),
        ctrl.templateRevision,
        ctrl.checklistRevision,
        ctrl.task,
        ctrl.formFieldValueMap,
        ctrl.user,
      );

      const queueDesc = PromiseQueueDescGenerator.generateForMergeTagsParsingByWidgetId(widget.id);
      const queueKey = PromiseQueueKeyGenerator.generateForMergeTagsParsingByWidgetId(widget.id);

      PromiseQueueService.enqueue(
        queueKey,
        () =>
          $q
            .all({
              recipient,
              cc,
              bcc,
              subject,
              body,
            })
            .then(response => {
              ctrl.parsedWidget.recipient = response.recipient;
              ctrl.parsedWidget.cc = response.cc;
              ctrl.parsedWidget.bcc = response.bcc;
              ctrl.parsedWidget.subject = response.subject;
              ctrl.parsedWidget.body = response.body;

              generateMailto(widget);
            }),
        queueDesc,
      );
    };

    function generateMailto(widget) {
      const recipient = MergeTagsService.parseChecklistContent(
        MergeTagsConstants.Mode.PLAINTEXT,
        MergeTagsConstants.Target.EMAIL,
        widget.recipient,
        ctrl.templateRevision,
        ctrl.checklistRevision,
        ctrl.task,
        ctrl.formFieldValueMap,
        ctrl.user,
      );
      const cc = MergeTagsService.parseChecklistContent(
        MergeTagsConstants.Mode.PLAINTEXT,
        MergeTagsConstants.Target.EMAIL,
        widget.cc,
        ctrl.templateRevision,
        ctrl.checklistRevision,
        ctrl.task,
        ctrl.formFieldValueMap,
        ctrl.user,
      );
      const bcc = MergeTagsService.parseChecklistContent(
        MergeTagsConstants.Mode.PLAINTEXT,
        MergeTagsConstants.Target.EMAIL,
        widget.bcc,
        ctrl.templateRevision,
        ctrl.checklistRevision,
        ctrl.task,
        ctrl.formFieldValueMap,
        ctrl.user,
      );
      const subject = MergeTagsService.parseChecklistContent(
        MergeTagsConstants.Mode.PLAINTEXT,
        MergeTagsConstants.Target.GENERAL,
        widget.subject,
        ctrl.templateRevision,
        ctrl.checklistRevision,
        ctrl.task,
        ctrl.formFieldValueMap,
        ctrl.user,
      );
      const body = MergeTagsService.parseChecklistContent(
        MergeTagsConstants.Mode.PLAINTEXT,
        MergeTagsConstants.Target.GENERAL,
        widget.body,
        ctrl.templateRevision,
        ctrl.checklistRevision,
        ctrl.task,
        ctrl.formFieldValueMap,
        ctrl.user,
      );

      $q.all({
        recipient,
        cc,
        bcc,
        subject,
        body,
      }).then(response => {
        ctrl.mailUrl = mailto({
          to: response.recipient,
          cc: response.cc,
          bcc: response.bcc,
          subject: response.subject,
          body: response.body,
        });

        WidgetService.generateEmailBody(ctrl.organization, response.body).then(generatedBody => {
          ctrl.mailUrl = mailto({
            to: response.recipient,
            cc: response.cc,
            bcc: response.bcc,
            subject: response.subject,
            body: generatedBody,
          });
        });
      });
    }
  },
});
