import { useState, useEffect, useCallback } from 'react';

import { useCopyToClipboard } from 'react-use';

import { useInjector } from 'components/injection-provider';

import type { Template } from '@process-street/subgrade/process';
import { TemplateType } from '@process-street/subgrade/process';
import { abbreviateForTitle } from '@process-street/subgrade/util';

import { useGetTemplateQuery } from 'features/template/query-builder';
import { AppModalQueryParam } from 'app/app.constants';

const stateNames = {
  [TemplateType.Page]: 'pageView',
  // We share the respond link for forms.
  [TemplateType.Form]: 'addFormResponse',
  [TemplateType.Playbook]: 'templateView',
  [TemplateType.Task]: 'inbox', // added just for type safety
  [TemplateType.WorkflowSandbox]: 'sandbox', // also added just for type safety
} as const;

export const useCopyTemplateLink = (
  templateId: Template['id'],
): [string, React.MouseEventHandler<HTMLButtonElement>] => {
  const { $state } = useInjector('$state');
  const { data: template } = useGetTemplateQuery({ templateId });

  const templateType = template?.templateType ?? TemplateType.Page;
  const stateName = stateNames[templateType];
  const title = abbreviateForTitle(template?.name);

  const [{ value: clipboardValue }, copyToClipboard] = useCopyToClipboard();
  const [copiedValue, setCopiedValue] = useState('');

  // using our own clipboard value state so we can reset it
  useEffect(() => {
    if (clipboardValue) {
      setCopiedValue(clipboardValue);
    }
  }, [clipboardValue]);

  const url = $state.href(
    stateName,
    {
      id: templateId,
      title: title?.concat('-'),
      [AppModalQueryParam.Modal]: undefined,
    },
    { absolute: true, inherit: false },
  );

  const handleCopyLink: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    copyToClipboard(url);
    setCopiedValue(url);
  }, [copyToClipboard, url]);

  useEffect(() => {
    return () => {
      setCopiedValue('');
    };
  }, []);

  return [copiedValue, handleCopyLink];
};
