import React, { useEffect, useRef, useState } from 'react';
import { Avatar } from 'components/common/Avatar';
import {
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  List,
  ListItem,
  Skeleton,
  SkeletonCircle,
  Square,
  Text,
  useDisclosure,
} from 'components/design/next';
import { InvitationRoleSelector } from '../invitation-role-selector/component';
import { BulkInviteModal } from 'components/invitation/invitation-widget/bulk-invite-modal';
import { useCombobox } from 'downshift';
import { OrganizationMembershipRole, shouldShowUser, User } from '@process-street/subgrade/core';
import { UserUtils } from '@process-street/subgrade/util';
import { isValidEmail } from '@process-street/subgrade/process';
import { InviteButtonConfig, InviteButtonConfigs, InviteConfig, Rules } from './rules';
import { useSelector } from 'react-redux';
import { match, P } from 'ts-pattern';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { GroupSelector } from 'reducers/group/group.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useFeatureFlag } from 'features/feature-flags';

export interface InvitationWidgetProps {
  isDisabled: boolean;
  users: User[];
  onInvite: (options: { email: string; role: OrganizationMembershipRole }) => void;
  onSelectSuggestion: (user: User) => void;
  config?: InviteConfig;
  isBulkEnabled?: boolean;
  availableRoles: OrganizationMembershipRole[];
  placeholder?: string;
  suggestionsAreLoading: boolean;
  roleSelectorEnabled: boolean;
  inviteButtonConfig: InviteButtonConfig;
}

const FieldHeight = 12;
export const MaxSuggestions = 5;

export const InvitationWidget: React.FC<React.PropsWithChildren<InvitationWidgetProps>> = ({
  isDisabled,
  onInvite,
  users,
  onSelectSuggestion,
  config = Rules.fullMemberOrGuest,
  isBulkEnabled,
  availableRoles,
  placeholder = 'Enter name, group, or email address',
  suggestionsAreLoading,
  inviteButtonConfig = InviteButtonConfigs.INVITE,
  roleSelectorEnabled = true,
}) => {
  const buttonWidth = inviteButtonConfig.width;
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const isUserDeactivationEnabled = useFeatureFlag('deactivatingUsers');

  const inputRef = useRef<HTMLInputElement>();

  const organizationMembership = useSelector(
    OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId,
  );

  const organizationMembershipsSelector = React.useMemo(
    () => OrganizationMembershipSelector.getAllByOrganizationIdMapByUserIdMemoized(organizationId ?? ''),
    [organizationId],
  );
  const organizationMembershipsByUserIdMap = useSelector(organizationMembershipsSelector);

  const hiddenUserIds = useSelector(GroupSelector.getHiddenSystemGroupsUserIds);
  const usersToShow = users.filter(u => {
    const isHidden = hiddenUserIds.includes(u.id);

    return !isHidden && shouldShowUser(u, organizationMembershipsByUserIdMap, isUserDeactivationEnabled);
  });

  const [inputItems, setInputItems] = useState<User[]>([]);

  const [email, setEmail] = useState('');
  const inviteEnabled = !isDisabled && isValidEmail(email);

  const { checkboxWidth = 0, defaultRole } = config;
  const inputPaddingRight = checkboxWidth + buttonWidth;

  useEffect(() => {
    setInputItems(usersToShow.slice(0, MaxSuggestions));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- usersToShow changes on every render
  }, [users]);

  const {
    isOpen: isBulkInviteModalOpen,
    onOpen: bulkInviteModalOnOpen,
    onClose: bulkInviteModalOnClose,
  } = useDisclosure();

  const getUserRole = () => {
    return match<[string, OrganizationMembershipRole | undefined], OrganizationMembershipRole>([
      defaultRole,
      organizationMembership?.role,
    ])
      .with(['match-inviter', OrganizationMembershipRole.Admin], () => OrganizationMembershipRole.FullMember)
      .with(['match-inviter', P.any], () => organizationMembership?.role ?? OrganizationMembershipRole.FreeMember)
      .otherwise(() => defaultRole as OrganizationMembershipRole);
  };

  const [selectedRole, setSelectedRole] = useState<OrganizationMembershipRole>(getUserRole());

  const handleInvite = () => {
    onInvite({ email, role: selectedRole });
    resetInviteForm();
  };

  const { isOpen, getLabelProps, getMenuProps, getItemProps, getInputProps, getComboboxProps, openMenu, reset } =
    useCombobox<User>({
      items: inputItems,
      onInputValueChange: ({ inputValue }) => {
        setInputItems(UserUtils.filterUsersByEmailOrUsername(usersToShow, inputValue ?? '').slice(0, MaxSuggestions));
        setEmail(inputValue ?? '');
      },
      onSelectedItemChange: ({ selectedItem }) => {
        setEmail('');
        if (selectedItem) {
          onSelectSuggestion(selectedItem);
        }
        reset();
      },
    });

  const resetInviteForm = () => {
    setEmail('');
    reset();
  };

  return (
    <>
      <HStack mb="2" justify="space-between">
        <HStack spacing="2">
          <Icon variant="far" icon="envelope" size="4" />
          <Text variant="1" fontWeight="medium" color="gray.600" {...getLabelProps()}>
            Invite Users
          </Text>
        </HStack>
        {isBulkEnabled && (
          <Text as={Link} variant="1" color="brand.500" onClick={bulkInviteModalOnOpen}>
            <Icon icon="upload" size="4" mr="1" />
            Bulk invite
          </Text>
        )}
      </HStack>

      <InputGroup h={FieldHeight} {...getComboboxProps()}>
        <Input
          placeholder={placeholder}
          h={FieldHeight}
          pr={inputPaddingRight}
          ref={inputRef}
          value={email}
          {...getInputProps({
            onKeyDown: event => {
              if (event.key === 'Enter' && inviteEnabled) {
                handleInvite();
              }
            },
            onFocus: () => {
              if (!isOpen) {
                openMenu();
              }
            },
          })}
        />
      </InputGroup>

      <List
        display={isOpen && (inputItems.length > 0 || suggestionsAreLoading) ? 'block' : 'none'}
        borderColor="gray.300"
        backgroundColor="white"
        zIndex="dropdown"
        borderWidth="px"
        borderStyle="solid"
        boxShadow="base"
        py="1"
        px="2"
        my="1"
        rounded="md"
        pos="absolute"
        {...getMenuProps()}
      >
        {suggestionsAreLoading
          ? [...Array(4)].map((_, i) => (
              <HStack key={i} pt="2" pb={1}>
                <SkeletonCircle size="8" />
                <Skeleton h="6" w={60} />
              </HStack>
            ))
          : inputItems.map((user, index) => (
              <ListItem
                _selected={{
                  backgroundColor: 'brand.500',
                  color: 'white',
                }}
                key={user.id}
                h="10"
                rounded="md"
                px="2"
                py="1"
                cursor="pointer"
                {...getItemProps({ item: user, index })}
              >
                <HStack>
                  <Square size="8">
                    <Avatar user={user} />
                  </Square>
                  <Text as="span">{UserUtils.getLabel(user)}</Text>
                </HStack>
              </ListItem>
            ))}
      </List>

      <InputGroup h={FieldHeight} mt="4">
        <InputRightElement w="82" h={FieldHeight} pl="2">
          <Text color="gray.400" fontSize="md" px="2">
            Invite as:
          </Text>
          <InvitationRoleSelector
            availableRoles={availableRoles}
            selectedRole={selectedRole}
            handleUserRoleSelected={setSelectedRole}
            isDisabled={!roleSelectorEnabled && !inviteEnabled}
          />
          <Button
            iconSpacing="2"
            leftIcon={<Icon icon="paper-plane" variant="far" size="4" />}
            w={buttonWidth}
            h="full"
            isDisabled={!inviteEnabled}
            onClick={handleInvite}
            variant="primary"
            ml="4"
          >
            {inviteButtonConfig.text}
          </Button>
        </InputRightElement>
      </InputGroup>

      <BulkInviteModal isOpen={isBulkInviteModalOpen} onClose={bulkInviteModalOnClose} />
    </>
  );
};
