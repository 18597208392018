import * as React from 'react';
import { Box, HStack, BoxProps, Tooltip, Link } from 'components/design/next';
import { usePrintStore } from 'components/react-root/print-store';
import { AiTaskAuditHelperText, CommonAuditProps, ProcessPeteCircle, ProcessPeteIcon } from './common';
import { useHandleRetryFailedNativeAutomation } from '../../hooks/use-handle-retry-failed-native-automation';
import { match, P } from 'ts-pattern';
import { useChecklistRevisionNativeAutomationStatus } from 'pages/runs/_id/hooks/use-checklist-revision-native-automation-status';

export interface FailedAiTaskAuditProps extends BoxProps, CommonAuditProps {
  errorMessage?: string;
}

export const FailedAiTaskAudit: React.FC<React.PropsWithChildren<FailedAiTaskAuditProps>> = ({
  aiTaskTemplate,
  errorMessage,
  ...props
}) => {
  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;

  const { latestExecutionId } = useChecklistRevisionNativeAutomationStatus({
    nativeAutomation: aiTaskTemplate.nativeAutomation,
  });
  const handleRetry = useHandleRetryFailedNativeAutomation({ nativeAutomationExecutionId: latestExecutionId });

  const message = match([errorMessage])
    .with([P.when(msg => msg && msg.includes('context_length_exceeded'))], () => (
      <>AI task content length exceeded, please contact the workflow creator to reduce the generated length.</>
    ))
    .otherwise(() => (
      <>
        An error occurred executing the AI task. Fill manually or{' '}
        <Link color={isPrintMode ? 'red.200' : 'red.500'} onClick={handleRetry} textDecor="underline">
          retry
        </Link>
        .
      </>
    ));

  return (
    <Box {...props}>
      <HStack>
        <Tooltip label="This form field is an output of an AI task" shouldWrapChildren hasArrow>
          <ProcessPeteCircle>
            <ProcessPeteIcon />
          </ProcessPeteCircle>
        </Tooltip>

        <AiTaskAuditHelperText color={isPrintMode ? 'red.200' : 'red.500'}>{message}</AiTaskAuditHelperText>
      </HStack>
    </Box>
  );
};
