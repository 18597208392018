import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UploadUrlWorkflowSourceMutation {
  export type Params = {
    fileName: string;
    mimeType: string;
  };

  export type Response = { url: string; key: string };

  export const key = ['ai-generation', 'import-template', 'upload-url'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/ai-generation/import-template/upload-url`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(UploadUrlWorkflowSourceMutation.mutationFn, { ...options, mutationKey: key });
  };
}
