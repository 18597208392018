import {
  EMAIL_BRAND_FOOTER_DEFAULT,
  Muid,
  MuidUtils,
  Organization,
  OrganizationMembershipRole,
  OrganizationTheme,
  Ref,
  SubscriptionStatus,
} from '../../core';
import { TEST_BUSINESS_PRO_PLAN } from '../plans';
import { generateAudit } from './audit-generator';
import { testOrganizationId } from './generator-constants';
import { generateSubscription } from './subscription-generator';

export const generateOrganization = (organization?: Partial<Organization>): Organization => ({
  id: testOrganizationId,
  audit: generateAudit(),
  stripeId: 'cus_DULho46NcyD1TO',
  subscription: generateSubscription({
    plan: {
      id: TEST_BUSINESS_PRO_PLAN,
    },
    currentPeriodStartDate: Date.now(),
    currentPeriodEndDate: Date.now(),
    cancelAtPeriodEnd: false,
    quantity: 6,
    status: SubscriptionStatus.Active,
  }),
  minQuantity: undefined,
  cardDefined: true,
  name: 'Some Organization',
  email: 'some@process.st',
  industry: 'Human Resources',
  settings: {},
  growSumoPartnerKey: 'growTest',
  defaultSsoOrganizationMembershipLevel: OrganizationMembershipRole.Guest,
  enhancedFileSecurity: false,
  ...organization,
});

export const generateOrganizationRef = (organizationId: Muid = testOrganizationId): Ref<Organization> => ({
  id: organizationId,
});

export function generateRef<T>(): Ref<T> {
  return { id: MuidUtils.randomMuid() };
}

export const generateOrganizationTheme = (
  theme: Partial<OrganizationTheme> = { organizationId: testOrganizationId },
): OrganizationTheme => {
  return {
    organizationId: testOrganizationId,
    brandColor: '#5500ff',
    id: MuidUtils.randomMuid(),
    audit: generateAudit(),
    emailCustomizationEnabled: false,
    emailPrimaryColor: '#ec6f6f',
    emailBackgroundColor: '#366d4e',
    emailFooter: EMAIL_BRAND_FOOTER_DEFAULT,
    emailHeaderLogoFileUrl: null,
    emailHeaderLogoFileId: MuidUtils.randomMuid(),
    ...theme,
  };
};
