import angular from 'angular';
import { dayjs as moment } from '@process-street/subgrade/util';
import { sprintf } from 'sprintf-js';
import { FutureChecklistFrequency, FutureChecklistMonthBy } from '@process-street/subgrade/process';
import { FutureChecklistUtils } from './future-checklist-service.utils';

angular.module('frontStreetApp.services').service('FutureChecklistService', function ($state, TempDataService) {
  const self = this;

  self.Frequency = FutureChecklistFrequency;
  self.MonthBy = FutureChecklistMonthBy;

  self.renderName = function (nameFormat, dateMoment) {
    return FutureChecklistUtils.renderName({ name: nameFormat, dateMoment });
  };

  self.generateJobs = function (info) {
    let jobs;

    switch (info.frequency) {
      case self.Frequency.YEARLY:
        jobs = generateYearlyJobs(info);

        break;
      case self.Frequency.MONTHLY:
        jobs = generateMonthlyJobs(info);

        break;
      case self.Frequency.WEEKLY:
        jobs = generateWeeklyJobs(info);

        break;
      case self.Frequency.DAILY:
        jobs = generateDailyJobs(info);

        break;
      default:
        // Do nothing, this is for the "Nope" option
        jobs = [];
    }

    return jobs;
  };

  function generateYearlyJobs(info) {
    return [
      {
        yearInterval: info.repeat.yearInterval,
      },
    ];
  }

  function generateMonthlyJobs(info) {
    return [
      {
        monthInterval: info.repeat.monthInterval,
        monthBy: info.repeat.monthBy,
      },
    ];
  }

  function generateWeeklyJobs(info) {
    const jobs = [];

    angular.forEach(info.repeat.weekDays, (checked, weekDay) => {
      if (checked) {
        jobs.push({
          weekInterval: info.repeat.weekInterval,
          weekDay: +weekDay,
        });
      }
    });

    return jobs;
  }

  function generateDailyJobs(info) {
    return [
      {
        dayInterval: info.repeat.dayInterval,
      },
    ];
  }

  self.generateSummary = function (startDate, jobs) {
    const startMoment = moment(startDate);
    let summary;

    if (jobs && jobs.length && !jobs[0].weekInterval) {
      const [firstJob] = jobs;

      if (firstJob.yearInterval) {
        summary = sprintf('Every %d year(s) on %s', firstJob.yearInterval, startMoment.format('MMM D'));
      } else if (firstJob.monthInterval && firstJob.monthBy === self.MonthBy.DAY_OF_MONTH) {
        summary = sprintf('Every %d month(s) on day %d', firstJob.monthInterval, startMoment.date());
      } else if (firstJob.monthInterval && firstJob.monthBy === self.MonthBy.DAY_OF_WEEK) {
        const weekOfMonth = getWeekOfMonth(startMoment);
        const startMomentIsLastWeek =
          weekOfMonth >= 4 && startMoment.clone().add(7, 'days').month() !== startMoment.month();
        const adjective = startMomentIsLastWeek ? 'last' : getOrdinal(weekOfMonth);
        summary = sprintf(
          'Every %d month(s) on the %s %s',
          firstJob.monthInterval,
          adjective,
          moment().day(startMoment.day()).format('dddd'),
        );
      } else if (firstJob.dayInterval) {
        summary = sprintf('Every %d day(s)', firstJob.dayInterval);
      } else {
        summary = undefined;
      }
    } else if (jobs && jobs.length) {
      const weekDayNames = jobs.map(job => moment().day(job.weekDay).format('dddd'));

      summary = sprintf('Every %d week(s) on %s', jobs[0].weekInterval, weekDayNames.join(', '));
    } else {
      summary = `Once on ${startMoment.format('ddd MMM D YYYY')}`;
    }

    return summary;
  };

  function getOrdinal(n) {
    if (n > 3 && n < 21) {
      return `${n}th`;
    }
    const suffix = n % 10;
    switch (suffix) {
      case 1:
        return `${n}st`;
      case 2:
        return `${n}nd`;
      case 3:
        return `${n}rd`;
      default:
        return `${n}th`;
    }
  }

  self.calculateDueDate = function (startDate, duePeriod) {
    return FutureChecklistUtils.calculateDueDate({ startDate, duePeriod });
  };

  self.calculateDuePeriod = function (startDate, dueDate) {
    let period;
    if (startDate && dueDate) {
      const startMoment = moment(startDate);
      let dueMoment = moment(dueDate);

      const years = dueMoment.diff(startMoment, 'years');
      dueMoment = dueMoment.subtract(years, 'years');

      const months = dueMoment.diff(startMoment, 'months');
      dueMoment = dueMoment.subtract(months, 'months');

      const days = dueMoment.diff(startMoment, 'days');
      dueMoment = dueMoment.subtract(days, 'days');

      const hours = dueMoment.diff(startMoment, 'hours');
      dueMoment = dueMoment.subtract(hours, 'hours');

      const minutes = dueMoment.diff(startMoment, 'minutes');
      dueMoment = dueMoment.subtract(minutes, 'minutes');

      period = {
        years,
        months,
        days,
        hours,
        minutes,
      };
    }
    return period;
  };

  function getWeekOfMonth(someMoment) {
    return Math.ceil(someMoment.date() / 7);
  }

  self.isPositivePeriod = function (period) {
    return (
      period &&
      (period.years > 0 ||
        period.months > 0 ||
        period.days > 0 ||
        period.hours > 0 ||
        period.minutes > 0 ||
        period.seconds > 0)
    );
  };

  self.returnToWorkflow = function () {
    const { state, params } = TempDataService.getPreviousState();

    // only go back to the previous page if we are coming from the workflow page
    if (state.name?.startsWith('template')) {
      $state.go(state.name, params);
    } else {
      $state.go('dashboard.type', { type: 'scheduled' });
    }
  };
});
