import { FileFieldValue } from '@process-street/subgrade/process';
import React from 'react';
import styles from '../FormFieldValueRenderer.module.scss';

interface FileFieldRendererProps {
  value?: FileFieldValue;
}

export const FileFieldRenderer: React.FunctionComponent<React.PropsWithChildren<FileFieldRendererProps>> = ({
  value,
}) => {
  if (!value) {
    return <div />;
  }

  return (
    <a href={value.url} title={value.name} target="_blank" rel="noopener noreferrer">
      <div className={styles.trimmedLabel}>{value.name}</div>
    </a>
  );
};
