import * as React from 'react';
import { ScaleInput } from '@visx/scale';
import { useChecklistAnalyticsQuery } from 'features/checklists/query-builder';
import { FormattedLabel } from '@visx/legend/lib/types';
import { ScaleOrdinal } from 'd3-scale';
import { DerivedChecklistStatus as Status } from '@process-street/subgrade/dashboard';
import { useTooltipEffect } from './tooltip-state';
import { useChecklistSearchCriteria } from 'pages/reports/hooks/use-checklist-search-criteria';
import {
  BarStackHorizontalLegendItem,
  BarStackHorizontalLegendItemSquare,
  BarStackHorizontalLegendItemText,
} from '../bar-stack-horizontal';
import { CHECKLIST_STATUS_LABELS as STATUS_LABELS } from '../common/constants';

type Scale = ScaleOrdinal<Status, string>;

export const LegendItem: React.VFC<FormattedLabel<ScaleInput<Scale>, ReturnType<Scale>>> = ({ ...label }) => {
  const { datum: status } = label;

  const searchCriteria = useChecklistSearchCriteria();
  const statusTotalQuery = useChecklistAnalyticsQuery(searchCriteria, {
    select: analytics =>
      Object.values(analytics.checklistStatusStatsByAssigneeUserId).reduce((acc, el) => acc + el[status], 0),
  });
  const total = statusTotalQuery.data ?? 0;
  const ref = React.useRef<HTMLDivElement>(null);
  const dispatch = useTooltipEffect();

  return (
    <BarStackHorizontalLegendItem
      {...{
        ref,
        'onMouseLeave': () => {
          dispatch({ type: 'hide' });
        },
        'aria-label': `${status} total for workflow`,
        'onMouseMove': () => {
          if (!ref.current) return;
          dispatch({
            type: 'show',
            payload: {
              referenceElement: ref.current,
              data: { status, total },
            },
          });
        },
      }}
    >
      <BarStackHorizontalLegendItemSquare bgColor={label.value} />
      <BarStackHorizontalLegendItemText>{STATUS_LABELS[label.datum]}</BarStackHorizontalLegendItemText>
    </BarStackHorizontalLegendItem>
  );
};
