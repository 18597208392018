const getPrettySize = function (bytes: number): string {
  // Adapted from: https://stackoverflow.com/q/10420352/62571
  if (bytes === 0) {
    return '0.0  B';
  } else {
    const e = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / 1024 ** e).toFixed(1)} ${' KMGTP'.charAt(e)}B`;
  }
};

const abbreviate = function (value?: string, maxLength?: number) {
  const defaultedValue = value || '';
  const defaultedMaxLength = maxLength || 20;

  return defaultedValue.length > defaultedMaxLength
    ? `${defaultedValue.substr(0, defaultedMaxLength)}...`
    : defaultedValue;
};

export const StringService = {
  getPrettySize,
  abbreviate,
};
