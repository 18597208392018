// The leading space matters!
const XML_PATTERN = new RegExp(' xmlns=".*?"', 'g');

const DIV_OPEN = '<div>';
const DIV_CLOSE = '</div>';

/** Serialize back to html and remove the namespace of div that was created by XMLSerializer */
export function divToHtmlString(el: HTMLDivElement) {
  return new XMLSerializer()
    .serializeToString(el)
    .replace(XML_PATTERN, '')
    .slice(DIV_OPEN.length, DIV_CLOSE.length * -1);
}
