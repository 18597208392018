import * as React from 'react';
import { MenuItemProps } from 'components/design/next';
import { FieldType, FormFieldWidget, FormFieldWidgetOfType } from '@process-street/subgrade/process';
import { useWidgetActorRef } from 'pages/forms/_id/shared/widget-context';
import { useUIActorRef } from 'pages/forms/_id/shared';
import { ObjectSchema } from 'yup';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';
import { SettingsInlineContent } from './settings-inline-content';

export type InlineSettingsProps<Type extends FieldType> = React.PropsWithChildren<
  MenuItemProps & {
    widget: FormFieldWidgetOfType<Type>;
    schema: ObjectSchema<WidgetSettings<Type>>;
  }
>;

export const InlineSettings = React.memo(
  <Type extends FieldType>({ widget, schema, children }: InlineSettingsProps<Type>) => {
    const { widgetActorRef } = useWidgetActorRef<FormFieldWidget>();
    const { uiActorRef } = useUIActorRef();

    const handleUpdate = React.useCallback(
      (widget: FormFieldWidget) => widgetActorRef.send({ type: 'UPDATE_WIDGET', widget }),
      [widgetActorRef],
    );
    const handleClose = React.useCallback(() => uiActorRef.send({ type: 'CLOSE_MODAL' }), [uiActorRef]);

    return (
      <SettingsInlineContent widget={widget} schema={schema} onUpdate={handleUpdate} onClose={handleClose}>
        {children}
      </SettingsInlineContent>
    );
  },
) as <Type extends FieldType>(props: InlineSettingsProps<Type>) => JSX.Element;
