import { Muid } from '@process-street/subgrade/core';
import { ChecklistGridSearchRequest } from '@process-street/subgrade/dashboard';
import { AxiosError } from 'axios';
import { QueryFunctionContext, useQuery as useRQQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace ChecklistGridCountQuery {
  export type Params = Omit<ChecklistGridSearchRequest, 'pageSize'> & {
    organizationId?: Muid;
  };

  export type Response = { total: number };

  export const key = [{ static: 'checklist-grid-count' }];
  export const getKey = (params: Params) => [{ ...key[0], ...params }];
  export type QueryKey = ReturnType<typeof getKey>;

  export const queryFn = ({ queryKey: [{ static: _, organizationId, ...params }] }: QueryFunctionContext<QueryKey>) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/organizations/${organizationId}/checklist-grid/count`, params)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select, QueryKey> = {},
  ) => {
    return useRQQuery(getKey(params), queryFn, {
      ...options,
      enabled: Boolean(params.organizationId) && options.enabled !== false,
      staleTime: 1000 * 60,
    });
  };
}
