import angular from 'angular';
import { SessionSelector } from 'reducers/session/session.selectors';
import { connectController } from 'reducers/util';
import { OrganizationSelector } from '../../reducers/organization/organization.selectors';
import templateUrl from './switch-organization.component.html';
import { queryClient } from 'components/react-root';
import { deletePersistedTheme } from 'components/design/next/use-brand-theme';
import { SessionActions } from 'reducers/session/session.actions';

export const SwitchOrganizationComponent = {
  templateUrl,
  controller: class SwitchOrganizationComponent {
    constructor(
      $httpParamSerializer,
      $location,
      $ngRedux,
      $q,
      $state,
      $stateParams,
      $window,
      FeatureFlagService,
      UserSettingsService,
      SyncService,
      PlanActions,
    ) {
      'ngInject';

      this.store = $ngRedux;
      this.$q = $q;
      this.$state = $state;
      this.$ngRedux = $ngRedux;
      this.$stateParams = $stateParams;
      this.$window = $window;
      this.$location = $location;
      this.$httpParamSerializer = $httpParamSerializer;
      this.UserSettingsService = UserSettingsService;
      this.FeatureFlagService = FeatureFlagService;
      this.SyncService = SyncService;

      const mapStateToThis = () => state => ({
        user: SessionSelector.getCurrentUser(state),
        plan: SessionSelector.getCurrentPlan(state),
        organization: OrganizationSelector.getById($stateParams.organizationId)(state) || {},
        homeState: SessionSelector.getSession(state).defaultHomepage || 'dashboard',
      });

      connectController($ngRedux, mapStateToThis, () => ({
        ...SessionActions,
        getPlanById: PlanActions.getById,
      }))(this);
    }

    $onInit() {
      if (this.$stateParams.organizationId) {
        this.switchOrganization(this.$stateParams.organizationId).then(() => {
          const { stateName, stateParams, searchParams, redirectToHome } = this.$stateParams.current || {};
          this.SyncService.pushOrganizationUseFrequencyMap();

          // Clear all cached data to ensure all the data is valid for the new organization.
          queryClient.clear();

          this.redirectByStateNameAndStateParams(stateName, stateParams, searchParams, redirectToHome);

          // Remove theme from local storage so we don't use the previously selected organization's theme.
          deletePersistedTheme();
        });
      } else {
        // This will prevent the user from getting stuck here
        this.$state.go('dashboard');
      }
    }

    switchOrganization = organizationId => {
      return this.UserSettingsService.updateSelectedOrganizationId(organizationId).then(() =>
        this.$q.all([
          this.actions.incrementOrganizationUseFrequency(organizationId),
          this.actions.getPlanById(this.state.organization.subscription.plan.id),
          this.actions.setSelectedOrganizationId(organizationId),
        ]),
      );
    };

    redirectByStateNameAndStateParams = (stateName, stateParams, searchParams, redirectToHome) => {
      const stateIsChecklistRun =
        stateName && stateName.startsWith('checklistRun') && !angular.equals(searchParams, {});
      if (stateIsChecklistRun) {
        let url = this.$state.href(stateName, stateParams);
        url += `?${this.$httpParamSerializer(searchParams)}`;

        this.$location.url(url);
        this.$window.location.reload();
      } else {
        let redirectParams;
        if (redirectToHome || stateName === undefined) {
          redirectParams = { to: this.state.homeState, params: {} };
        } else {
          redirectParams = { to: stateName, params: stateParams };
        }

        this.$state.go(redirectParams.to, redirectParams.params, { reload: true });
      }
    };
  },
};
