import * as React from 'react';

export function useIsOverflowing(ui: React.ReactElement): [React.ReactElement, boolean] {
  const ref = React.useRef<HTMLElement>(null);
  const clone = React.cloneElement(ui, { ref });
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  React.useEffect(() => {
    if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) {
      setIsOverflowing(true);
    }
  }, []);

  return [clone, isOverflowing];
}
