import * as React from 'react';
import { Link, Tooltip } from 'components/design/next';
import { RenderElementProps } from 'slate-react';
import { useIsEditable } from 'features/rich-text';
import { useOS } from 'services/os-util';
import { useMetaPressed } from './use-meta-pressed';
import { useTimeoutFn } from 'react-use';
import { PagesLinkElement } from '../../pages-plate-types';

export type LinkElementProps = {
  element: PagesLinkElement;
  attributes: RenderElementProps['attributes'];
  children: RenderElementProps['children'];
};

export const HideDelay = 2000;

export const LinkElement: React.FC<React.PropsWithChildren<LinkElementProps>> = ({
  attributes,
  children,
  element,
}): JSX.Element => {
  const editable = useIsEditable();
  const metaPressed = useMetaPressed();

  const [isOpen, setIsOpen] = React.useState(false);
  const [, , reset] = useTimeoutFn(() => {
    setIsOpen(false);
  }, HideDelay);
  React.useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const { metaSymbol } = useOS();

  return editable ? (
    <Tooltip label={`${metaSymbol} + click to open ${element.href}`} isDisabled={!editable} isOpen={isOpen}>
      <Link
        {...attributes}
        href={element.href}
        target={element.target}
        rel={element.rel}
        color="brand.500"
        _hover={{
          ...(metaPressed
            ? { textDecoration: 'underline', cursor: 'pointer' }
            : { textDecoration: 'none', cursor: 'default' }),
          color: 'brand.500',
        }}
        // since it is within a content-editable div, we have to monkey patch click behavior.
        onMouseDown={() => {
          if (metaPressed) {
            window.open(element.href, element.target);
            return;
          }
          setIsOpen(true);
        }}
      >
        {children}
      </Link>
    </Tooltip>
  ) : (
    <Link {...attributes} href={element.href} target={element.target} color="brand.500">
      {children}
    </Link>
  );
};
