import { ToastServiceImpl } from 'services/toast-service.impl';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { Text, VStack, Button, Divider, HStack, Box, Portal } from 'components/design/next';
import React from 'react';
import { AxiosError } from 'axios';
import { HttpStatus } from '@process-street/subgrade/util';

const showFormFieldUpdateGeneralErrorToast = (title?: string, description?: string) => {
  ToastServiceImpl.openToast({
    status: 'error',
    title: title ?? "We're having problems updating the form field",
    description: description ?? DefaultErrorMessages.unexpectedErrorDescription,
  });
};

function showFormFieldUpdateConflictModal() {
  const onRefresh = () => window.location.reload();
  ToastServiceImpl.openToast({
    duration: null,
    render: () => (
      <Portal>
        <Box zIndex="toast" position="fixed" top="0" left="0" w="full" h="full" bgColor="blackAlpha.400" />
        <HStack top="0" mt="16" position="fixed" zIndex="toast" w="full" justifyContent="center">
          <VStack
            zIndex="toast"
            bgColor="white"
            borderRadius="lg"
            px={{ base: '2', md: '6' }}
            py="4"
            spacing="4"
            shadow="md"
            w={{ base: 'full', md: '514px' }}
            justifyContent="space-between"
            mt="16"
          >
            <Text as="h2" variant="2" fontWeight="bold" alignSelf="flex-start">
              Form Updated
            </Text>
            <Text>
              We couldn't update the value because the form has been updated. Click refresh to get the new version!
            </Text>

            <Divider />
            <HStack justifyContent="flex-end" w="100%">
              <Button variant="primary" onClick={onRefresh} ml={2}>
                Refresh
              </Button>
            </HStack>
          </VStack>
        </HStack>
      </Portal>
    ),
  });
}

function showFormFieldUpdateErrorToast(error: AxiosError) {
  const checklistNotFound = error?.isAxiosError && error?.response?.status === HttpStatus.NOT_FOUND;
  const shouldShowConflictModal = error?.isAxiosError && error?.response?.status === HttpStatus.CONFLICT;
  if (shouldShowConflictModal) {
    showFormFieldUpdateConflictModal();
  } else {
    if (checklistNotFound) {
      showFormFieldUpdateGeneralErrorToast(
        'This form has been removed',
        'The form field you are trying to respond to does not exist anymore.',
      );
    } else {
      showFormFieldUpdateGeneralErrorToast();
    }
  }
}

export const FormResponseErrorToasts = {
  showFormFieldUpdateErrorToast,
};
