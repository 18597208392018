// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../images/task-template/stop-task-upgrade.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"add-stop-task\"> <div class=\"modal-header\"> <button type=\"button\" data-ng-click=\"$close()\" class=\"close\">&times;</button> <h4 class=\"modal-title text-center\" ng-if=\"!options.taskTemplate.stop\"> <span ng-if=\"featureIsAvailable\">Add Stop Task</span> <span ng-if=\"!featureIsAvailable\" class=\"upgrade-title\"> <span>Upgrade to use Stop Tasks</span> <span class=\"badge badge-success ml-1\">Standard</span> </span> </h4> </div> <div class=\"modal-body text-center\"> <div> <div class=\"row\"> <div class=\"col-xs-12 explanation-box\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </div> </div> </div> <p class=\"upgrade-description\"> Stop a workflow from continuing until a task is completed.<br/> Automatically notify the next person when its their turn to start working. </p> <div class=\"upgrade-btn-box\"> <button class=\"btn btn-primary text-center\" data-ng-if=\"featureIsAvailable\" data-ng-click=\"addStop()\"> Ok, Add a stop! </button> <div data-ng-if=\"!featureIsAvailable\"> <a class=\"btn btn-primary\" data-ng-if=\"userIsAdmin\" data-ng-click=\"$close()\" data-ui-sref=\"organizationManage.tab({ tab: 'billing' })\" data-ui-sref-opts=\"{ reload: true }\"> Upgrade to Standard </a> <p ng-if=\"!userIsAdmin\">Contact an administrator to upgrade your plan.</p> </div> </div> <div class=\"upgrade-btn-box\"> <a href=\"https://process.st/help/stop-task\" target=\"_blank\" rel=\"noopener noreferrer\">Learn more &raquo;</a> </div> </div> </div> ";
// Exports
var _module_exports = code;;
var path = '/directives/stop-task-button/add-stop-task/add-stop-task.component.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;