export const ChecklistTaskAssignmentApi = function (ChecklistTaskAssignments) {
  'ngInject';

  const getAllByChecklistRevisionId = checklistRevisionId =>
    ChecklistTaskAssignments.getAllByChecklistRevisionId({ checklistRevisionId }).$promise;

  const assign = (taskId, email) => ChecklistTaskAssignments.assign({ taskId, email }).$promise;

  const unassign = (taskId, email) => ChecklistTaskAssignments.unassign({ taskId, email }).$promise;

  const invite = (taskId, email) => ChecklistTaskAssignments.invite({ taskId, email }).$promise;

  return {
    getAllByChecklistRevisionId,
    assign,
    unassign,
    invite,
  };
};
