import * as React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { Tooltip, useDisclosure } from 'components/design/next';
import { Button, Icon } from 'components/design/next';
import { CoverImageUploadModal } from 'features/cover-image/components/common/modal';
import { useHover } from 'react-use';
import { CoverImageByTemplateIdQuery, DeleteCoverImageMutation } from 'features/cover-image/query-builder';
import { commonSetupButtonProps } from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/add-icon-button';

const LABEL = `Cover image will appear as a banner at the top of workflows, runs and emails.
Images wider than 700px work best.`;

export const AddCoverButton: React.FC<React.PropsWithChildren<{ templateId: Muid }>> = ({ templateId }) => {
  const coverImageQuery = CoverImageByTemplateIdQuery.useQuery({ templateId });

  const hasCoverImage = coverImageQuery.isSuccess && !!coverImageQuery.data;

  return hasCoverImage ? <RemoveButton templateId={templateId} /> : <AddButton templateId={templateId} />;
};

const AddButton: React.FC<React.PropsWithChildren<{ templateId: Muid }>> = ({ templateId }) => {
  const disclosure = useDisclosure();
  return (
    <>
      <Tooltip hasArrow label={LABEL}>
        <Button
          {...commonSetupButtonProps}
          leftIcon={<Icon icon="image-landscape" variant="far" size="4" color="gray.600" />}
          rightIcon={<Icon icon="circle-info" variant="far" size="4" color="gray.500" />}
          onClick={() => disclosure.onOpen()}
        >
          Add Cover
        </Button>
      </Tooltip>
      <CoverImageUploadModal {...disclosure} templateId={templateId} onFinish={disclosure.onClose} />
    </>
  );
};

const RemoveButton: React.FC<React.PropsWithChildren<{ templateId: Muid }>> = ({ templateId }) => {
  const removeCoverImageMutation = DeleteCoverImageMutation.useMutationWithToast();
  const handleRemoveCoverImage = () => {
    removeCoverImageMutation.mutate({ templateId });
  };

  const [hoverable] = useHover((hovered: boolean) =>
    hovered ? (
      <Button
        {...commonSetupButtonProps}
        color="red.600"
        borderColor="red.600"
        leftIcon={<Icon icon="trash" variant="far" size="4" color="red.600" />}
        onClick={handleRemoveCoverImage}
      >
        Delete Cover
      </Button>
    ) : (
      <Button
        {...commonSetupButtonProps}
        color="green.600"
        borderColor="green.600"
        leftIcon={<Icon icon="check" variant="far" size="4" color="green.600" />}
      >
        Cover added
      </Button>
    ),
  );

  return hoverable;
};
