import * as React from 'react';
import { Box, Progress } from 'components/design/next';
import { FormResponseActor } from 'app/pages/responses/_id/components/form-response-body/form-response-machine';
import { useSelector } from '@xstate/react';
import { ChecklistUtils } from '@process-street/subgrade/process';
import { FormResponseMachineChecklistSelectors } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-selectors';
import { StatusBar } from './status-bar';
import { TOP_BAR_HEIGHT_TOKEN } from 'app/pages/forms/_id/shared';

export type ChecklistTopBarProps = {
  actor: FormResponseActor;
};

export const ChecklistTopBar: React.FC<ChecklistTopBarProps> = ({ actor }) => {
  const checklist = useSelector(actor, FormResponseMachineChecklistSelectors.getChecklist);
  const progress = useSelector(actor, FormResponseMachineChecklistSelectors.getCompletionPercentage);

  return (
    <Box position="sticky" top={TOP_BAR_HEIGHT_TOKEN} zIndex="banner">
      {ChecklistUtils.isArchived(checklist) || ChecklistUtils.isCompleted(checklist) ? (
        <StatusBar checklist={checklist} />
      ) : (
        <Progress w="full" size="sm" value={progress} isAnimated={true} aria-label="workflow run progress bar" />
      )}
    </Box>
  );
};
