import * as React from 'react';
import { Template, TemplateStatus, TemplateType } from '@process-street/subgrade/process';
import { useGetAllTemplatesQuery } from 'features/template/query-builder';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { TemplatesFilter } from 'components/dashboard/components/checklist/ChecklistDashboardFilters/filters/ChecklistDashboardTemplatesFilter/templates-filter';
import { useInboxDropdownStyles } from 'pages/tasks/components/filter-bar/use-inbox-dropdown-styles';
import { FilteredItem } from 'components/dashboard/components/checklist/ChecklistDashboardFilters/filters/ChecklistDashboardTemplatesFilter/use-selected-template-ids-state';
import { components, GroupBase, SelectComponentsConfig } from 'react-select';
import { HStack, Icon, Text, useBreakpointValue } from 'components/design/next';
import { match } from 'ts-pattern';
import sortBy from 'lodash/sortBy';
import { DropdownIndicator } from 'pages/tasks/components/filter-bar/selector-common-components';

export interface WorkflowSelectorProps {
  templateIds?: Template['id'][];
  onChange?: (ids: Template['id'][]) => void;
}

export const WorkflowSelector: React.FC<React.PropsWithChildren<WorkflowSelectorProps>> = ({
  templateIds = [],
  onChange = () => {},
}) => {
  const organization = useSelectedOrganization();

  const templatesQuery = useGetAllTemplatesQuery(
    {
      organizationId: organization?.id!,
      templateStatus: TemplateStatus.Active,
      templateType: TemplateType.Playbook,
    },
    {
      enabled: Boolean(organization?.id),
    },
  );
  const templates = React.useMemo(() => templatesQuery.data ?? [], [templatesQuery.data]);

  const filteredItems: FilteredItem[] = React.useMemo(() => {
    return sortBy(
      templates.map(template => ({ value: template.id, label: template.name })),
      'label',
    );
  }, [templates]);

  const styles = useInboxDropdownStyles<FilteredItem>();

  const menuWidth = useBreakpointValue({
    base: 'calc(100vw - 16px)',
    md: 'min(300px, 100vw)',
  });

  React.useEffect(
    function ensureTemplateIdsPresence() {
      if (!templatesQuery.isSuccess) return;

      const isAnyTemplateNotFound =
        templates.filter(template => templateIds.includes(template.id)).length !== templateIds.length;

      // When a template is not found, that means the user has no access to this template.
      // This can happen when the `templateIds` value comes from the URL, and the user has no access to some of these ids.
      // When this happens, we reset the templateIds.
      if (isAnyTemplateNotFound) {
        onChange([]);
      }
    },
    // We don't want to run this effect when onChange changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templateIds, templates, templatesQuery.isSuccess],
  );

  return (
    <TemplatesFilter
      onSelectionChange={onChange}
      filteredItems={filteredItems}
      selectedTemplateIds={templateIds}
      blvdSelectProps={{
        styles: {
          ...styles,
          menu: s => ({
            ...s,
            width: 'max-content',
            maxWidth: '260px',
            minWidth: menuWidth,
            p: {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: '100%',
            },
          }),
        },
        components: COMPONENTS,
        shouldKeepInputOnSelect: true,
        maxOptionsBeforeVirtualization: 10,
      }}
    />
  );
};

const COMPONENTS: SelectComponentsConfig<FilteredItem, true, GroupBase<FilteredItem>> = {
  DropdownIndicator,
  MultiValue: () => null,
  ValueContainer: ({ children, ...props }) => {
    const values = props.getValue();
    const selectedValuesCount = values.length;

    const contents = match(selectedValuesCount)
      // Force rendering placeholder while user is searching
      .with(0, () => {
        return (
          !values.length &&
          props.selectProps.inputValue && (
            <components.Placeholder {...props} innerProps={props.innerProps!} isFocused={false}>
              All workflows
            </components.Placeholder>
          )
        );
      })
      .with(1, () => <WorkflowPlaceholder label={values[0].label}></WorkflowPlaceholder>)
      .otherwise(() => <WorkflowPlaceholder label={`${values.length} workflows`} />);

    return (
      <components.ValueContainer {...props}>
        {contents}
        {children}
      </components.ValueContainer>
    );
  },
};
const WorkflowPlaceholder = (props: { label: string }) => (
  <HStack spacing={2} width="100%">
    <Icon color="gray.500" variant="far" icon="workflow" size="4" />
    <Text color="gray.600">{props.label}</Text>
  </HStack>
);
