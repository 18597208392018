import { OrganizationMembership, OrganizationMembershipStatus } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateOrganizationMembershipStatusMutation {
  export type Params = { id: OrganizationMembership['id']; status: OrganizationMembershipStatus };

  export type Response = OrganizationMembership;

  export const key = ['organization-membership', 'status'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/organization-memberships/${params.id}/status/${params.status}`)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
