import { useBrandTheme } from 'components/design/next/use-brand-theme';
import * as React from 'react';
import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';

/** Display toast with Next theme. Prevents leaking 2024 theme through toasts. */
export const useNextThemeToast = () => {
  const theme = useBrandTheme();

  const chakraToast = React.useMemo(() => {
    return createStandaloneToast({
      theme,
    }).toast;
  }, [theme]);

  // Refresh if root toast changes (related to custom brand)
  return React.useMemo(
    () =>
      Object.assign(
        (props?: UseToastOptions): ReturnType<typeof chakraToast> =>
          chakraToast({
            position: 'top',
            isClosable: true,
            duration: 5000,
            variant: 'subtle',
            ...props,
          }),
        chakraToast, // Object.assign leaves out the "call" part
      ),
    [chakraToast],
  );
};
