import * as React from 'react';
import { Box, Button, Center, Icon, List, Text, VStack } from 'components/design/next';
import { TaskCommentItem } from './task-comment-item';
import {
  GetInboxCommentsQuery,
  GetUnreadCommentsCountQuery,
  useGetInboxCommentsQuery,
  useGetUnreadCommentsCountQuery,
  useMarkAllCommentsReadMutation,
} from 'features/comments/query-builder';
import { match, P } from 'ts-pattern';
import { CommentSkeleton } from './common';
import { CommentInboxItem, CommentInboxItemStatus } from '@process-street/subgrade/process';
import { useQueryClient } from 'react-query';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { StackProps } from '@chakra-ui/react';
import { useNextThemeToast } from 'components/design/next/use-next-theme-toast';

export const InboxCommentsList = (props: StackProps) => {
  const queryClient = useQueryClient();
  const commentsQuery = useGetInboxCommentsQuery({});
  const toast = useNextThemeToast();

  const unreadCommentsQuery = useGetUnreadCommentsCountQuery();

  const mutation = useMarkAllCommentsReadMutation({
    onSuccess: () => {
      queryClient.setQueryData(GetInboxCommentsQuery.getKey({}), (old = []) => {
        return (old as CommentInboxItem[]).map(inboxComment =>
          inboxComment.status === CommentInboxItemStatus.unread
            ? { ...inboxComment, status: CommentInboxItemStatus.read }
            : inboxComment,
        );
      });
      queryClient.invalidateQueries(GetUnreadCommentsCountQuery.getKey());
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems marking the comments as read",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const isLoading = commentsQuery.isLoading || unreadCommentsQuery.isLoading;

  return (
    <VStack alignItems="flex-start" pt="6" w="full" spacing="4" pb="10" {...props}>
      {match({ isLoading, comments: commentsQuery.data, unreadCount: unreadCommentsQuery.data })
        .with(
          {
            isLoading: false,
            comments: P.when(data => data.length > 0),
            unreadCount: P.when(data => data.count > 0),
          },
          () => (
            <Button
              placeSelf="flex-end"
              variant="link"
              color="gray.500"
              fontWeight="normal"
              width="min-content"
              _hover={{ color: 'brand.500', textDecor: 'underline' }}
              onClick={() => mutation.mutate()}
            >
              Mark all as read
            </Button>
          ),
        )
        .with(
          {
            isLoading: false,
            comments: P.when(data => data.length > 0),
            unreadCount: P.when(data => data.count === 0),
          },
          () => (
            <Button placeSelf="flex-end" variant="unstyled" color="gray.500" width="min-content" isDisabled={true}>
              Mark all as read
            </Button>
          ),
        )
        .otherwise(() => (
          <Box h="6"></Box>
        ))}
      <List w="full">
        {match(commentsQuery)
          .with({ status: 'loading' }, () => Array.from({ length: 6 }).map((_, i) => <CommentSkeleton key={i} />))
          .with({ status: 'error' }, () =>
            toast({
              status: 'error',
              title: "We're having problems loading the comments",
              description: 'Please try and reload the page. If the problem persists, contact support.',
            }),
          )
          .with(
            {
              status: 'success',
              data: P.when(data => data.length === 0),
            },
            () => (
              <VStack mt="25">
                <Icon color="gray.400" variant="far" icon="inbox" size="20" mb="5" />
                <Text color="gray.400" fontSize="xl" fontWeight="400">
                  All clear!
                </Text>
                <Text color="gray.400" fontSize="lg">
                  New comments will be shown here.
                </Text>{' '}
              </VStack>
            ),
          )
          .with({ status: 'success' }, ({ data }) => (
            <>
              {data.map((commentInboxItem, index, array) => {
                const previous = array[index - 1];

                const previousIsUnread = previous?.status === CommentInboxItemStatus.unread;

                return (
                  <TaskCommentItem
                    key={index}
                    {...{
                      commentInboxItem,
                      borderColor: commentInboxItem.status === CommentInboxItemStatus.read ? 'white' : 'brand.300',
                      borderTopWidth: previousIsUnread ? '0px' : '1px',
                    }}
                  />
                );
              })}
              {data.length === 50 && (
                <Center h="13" color="gray.500">
                  <Text>
                    That's everything for now. Only the 50 most recent comments are included in the comments inbox.
                  </Text>
                </Center>
              )}
            </>
          ))
          .otherwise(() => null)}
      </List>
    </VStack>
  );
};
