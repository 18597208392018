import { WidgetSelector } from 'components/widgets/store/widget.selector';
import { connectController } from 'reducers/util';
import { TaskAssignmentRuleSourceType } from '@process-street/subgrade/role-assignment';
import { FieldType } from '@process-street/subgrade/process';
import templateUrl from './role-assignment-rule-avatar.component.html';
import './role-assignment-rule-avatar.scss';

export const RoleAssignmentRuleAvatarComponent = {
  bindings: {
    rule: '<',
    badge: '<',
  },
  templateUrl,
  controller: class RoleAssignmentRuleAvatarComponent {
    constructor($ngRedux, WidgetActions) {
      'ngInject';

      this.$ngRedux = $ngRedux;
      this.WidgetActions = WidgetActions;
    }

    $onInit() {
      const mapStateToThis = () => state => {
        const emailAndMembersFieldWidgets = WidgetSelector.getAllEmailAndMembersWidgetsByTemplateRevisionId(
          this.rule.templateRevision.id,
        )(state);

        return { emailAndMembersFieldWidgets };
      };

      const mapDispatchToThis = () => ({
        getAllWidgetsByTemplateRevisionId: this.WidgetActions.getAllByTemplateRevisionId,
      });

      connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, this.shouldChange)(this);

      this.actions.getAllWidgetsByTemplateRevisionId(this.rule.templateRevision.id);
    }

    shouldChange = changes => changes.rule && changes.rule.currentValue;

    resolveWidgetByGroupId = groupId => this.state.emailAndMembersFieldWidgets.find(w => w.header.group.id === groupId);

    resolveIconClass() {
      let iconClass = '';

      if (this.rule.sourceType === TaskAssignmentRuleSourceType.ChecklistInitiator) {
        iconClass = 'fa-user';
      } else if (this.rule.sourceType === TaskAssignmentRuleSourceType.FormField) {
        const widget = this.resolveWidgetByGroupId(this.rule.sourceFormFieldWidgetGroup.id);

        if (widget && widget.fieldType === FieldType.Email) {
          iconClass = 'fa-envelope';
        } else if (widget && widget.fieldType === FieldType.Members) {
          iconClass = 'fa-users';
        }
      }

      return iconClass;
    }
  },
};
