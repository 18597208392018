import React from 'react';
import { useQueryClient } from 'react-query';
import { QueryBuilder } from './query-builder';

export function useLazyQuery<Builder extends QueryBuilder>(queryBuilder: Builder) {
  const queryClient = useQueryClient();

  return React.useCallback(
    function lazyQuery(...params: Parameters<Builder['queryFn']>): Promise<ReturnType<Builder['queryFn']>> {
      return queryClient.fetchQuery({
        queryKey: queryBuilder.getKey(...params),
        queryFn: () => queryBuilder.queryFn(...params),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- queryBuilder is module scope and queryClient is stable reference
    [],
  );
}
