import { SESSION_SET_ORGANIZATION_MEMBERSHIPS } from 'reducers/session/session.actions';
import { USER_COMPLETE_LOGIN } from 'reducers/user/user.actions';
import { combineReducers } from 'redux';
import { combineActions } from 'redux-actions';
import { handleActionsOnSuccess, composeReducerObjects } from 'reducers/util';
import {
  ORGANIZATION_GET_BY_ID,
  ORGANIZATION_GET_DISCOUNT,
  ORGANIZATION_SUBSCRIPTION_CANCEL_BY_ID,
  ORGANIZATION_CREATE,
  ORGANIZATION_SET_EMAIL,
  ORGANIZATION_REPAIR_BY_ID,
  ORGANIZATION_SUBSCRIPTION_START_TRIAL_BY_ID,
  ORGANIZATION_UPDATE_BY_ID,
  ORGANIZATION_UPDATE_GROW_SUMO_PARTNER_KEY_BY_ID,
  ORGANIZATION_SUBSCRIPTION_UPDATE_PLAN_BY_ID,
  ORGANIZATION_CARD_SET_BY_ID,
} from 'reducers/organization/organization.actions';

import { GROUP_PUT } from 'reducers/group/group.actions';
import { appendOnPutReducerObject } from 'reducers/entities/entities.utils';
import { GROUP_MEMBERSHIP_PUT } from 'reducers/group-membership/group-membership.actions';
import { TEMPLATE_PERMIT_PUT } from 'reducers/template-permit/template-permit.actions';
import { CHECKLIST_PERMIT_PUT } from 'reducers/checklist-permit/checklist-permit.actions';
import { FOLDER_PERMIT_PUT } from 'reducers/folder-permit/folder-permit.actions';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';

const normalizeOrganization = organization => organization;

const organizationActionsReducerObject = {
  [combineActions(
    ORGANIZATION_GET_BY_ID,
    ORGANIZATION_UPDATE_GROW_SUMO_PARTNER_KEY_BY_ID,
    ORGANIZATION_UPDATE_BY_ID,
    ORGANIZATION_REPAIR_BY_ID,
    ORGANIZATION_SUBSCRIPTION_START_TRIAL_BY_ID,
    ORGANIZATION_SUBSCRIPTION_UPDATE_PLAN_BY_ID,
    ORGANIZATION_SUBSCRIPTION_CANCEL_BY_ID,
    ORGANIZATION_SET_EMAIL,
  )]: (state, { payload: org }) => ({
    ...state,
    [org.id]: normalizeOrganization(org),
  }),
  [ORGANIZATION_CREATE]: (state, { payload: { organization } }) =>
    EntitiesReducerUtils.upsert(state, organization, normalizeOrganization),
  [ORGANIZATION_CARD_SET_BY_ID]: (state, { meta: { organizationId } }) => {
    if (state[organizationId]) {
      const updatedOrg = { ...state[organizationId], cardDefined: true };
      return { ...state, [organizationId]: updatedOrg };
    } else {
      return state;
    }
  },
  [SESSION_SET_ORGANIZATION_MEMBERSHIPS]: (state, { payload: memberships }) =>
    EntitiesReducerUtils.upsertAll(
      state,
      memberships.map(({ organization }) => organization),
    ),
  [USER_COMPLETE_LOGIN]: (state, { payload: { selectedOrganization } }) =>
    EntitiesReducerUtils.upsert(state, selectedOrganization),
};

export const organizationEntitiesReducer = composeReducerObjects(
  // OrganizationGet reducer
  organizationActionsReducerObject,

  // Load organization from possible references
  appendOnPutReducerObject(GROUP_MEMBERSHIP_PUT, 'organization', normalizeOrganization),
  appendOnPutReducerObject(GROUP_PUT, 'organization', normalizeOrganization),
  appendOnPutReducerObject(FOLDER_PERMIT_PUT, 'organization', normalizeOrganization),
  appendOnPutReducerObject(TEMPLATE_PERMIT_PUT, 'organization', normalizeOrganization),
  appendOnPutReducerObject(CHECKLIST_PERMIT_PUT, 'organization', normalizeOrganization),
);

const organizationDiscountReducer = handleActionsOnSuccess(
  {
    [ORGANIZATION_GET_DISCOUNT]: (state, { payload: discount, meta: { organizationId } }) => ({
      ...state,
      [organizationId]: discount,
    }),
  },
  {},
);

export const organizationReducer = combineReducers({
  discount: organizationDiscountReducer,
});
