import {
  SelectFormFieldConfig,
  MultiChoiceItemValueStatus,
  MultiOptionFieldValue,
} from '@process-street/subgrade/process';
import { FormFieldDto } from '@process-street/subgrade/dashboard';
import React from 'react';
import styles from '../FormFieldValueRenderer.module.scss';

interface MultiChoiceFieldRendererProps {
  value?: FormFieldDto;
}

const getMultichoiceFieldValue = (formFieldValue: FormFieldDto) => {
  const { config, fieldValue } = formFieldValue;

  const itemMap = new Map();
  (config as SelectFormFieldConfig).items.forEach(item => {
    itemMap.set(item.id, item.name);
  });

  let value = '';
  (fieldValue as MultiOptionFieldValue).itemValues
    .filter(itemValue => itemValue.status === MultiChoiceItemValueStatus.Selected)
    .forEach(itemValue => {
      const name = itemMap.get(itemValue.id) || 'Unnamed Item';
      if (value.length > 0) {
        value += ', ';
      }
      value += name;
    });

  return value;
};

export const MultiChoiceFieldRenderer: React.FunctionComponent<
  React.PropsWithChildren<MultiChoiceFieldRendererProps>
> = ({ value }) => {
  if (!value) {
    return <div />;
  }

  const fieldValue = getMultichoiceFieldValue(value);

  return (
    <div className={styles.trimmedLabel} title={fieldValue}>
      {fieldValue}
    </div>
  );
};
