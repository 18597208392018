import { FieldType, WidgetType } from '@process-street/subgrade/process';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import { NewContentWidgetItem, NewFormFieldWidgetItem } from '../../types';

export interface FormFieldOption extends NewFormFieldWidgetItem {
  icon: CustomIconName;
  label: string;
}

export const FORM_FIELDS: FormFieldOption[] = [
  {
    icon: 'rectangle-wide',
    fieldType: FieldType.Text,
    label: 'Short Text',
  },
  {
    icon: 'rectangle',
    label: 'Long Text',
    fieldType: FieldType.Textarea,
  },
  {
    icon: 'envelope',
    fieldType: FieldType.Email,
    label: 'Email',
  },
  {
    icon: 'globe',
    fieldType: FieldType.Url,
    label: 'Website',
  },
  {
    icon: 'file-upload',
    fieldType: FieldType.File,
    label: 'File Upload',
  },
  {
    icon: 'calendar-days',
    fieldType: FieldType.Date,
    label: 'Date',
  },
  {
    icon: 'tally',
    fieldType: FieldType.Number,
    label: 'Numbers',
  },
  {
    icon: 'square-chevron-down',
    fieldType: FieldType.Select,
    label: 'Dropdown',
  },
  {
    icon: 'list',
    fieldType: FieldType.MultiChoice,
    label: 'Multi Choice',
  },
  {
    icon: 'table',
    fieldType: FieldType.Table,
    label: 'Table',
  },
];

export const WF_EDITOR_FORM_FIELDS: FormFieldOption[] = [
  {
    icon: 'rectangle-wide',
    fieldType: FieldType.Text,
    label: 'Short Text',
  },
  {
    icon: 'rectangle',
    label: 'Long Text',
    fieldType: FieldType.Textarea,
  },
  {
    icon: 'envelope',
    fieldType: FieldType.Email,
    label: 'Email',
  },
  {
    icon: 'globe',
    fieldType: FieldType.Url,
    label: 'Website',
  },
  {
    icon: 'file-upload',
    fieldType: FieldType.File,
    label: 'File Upload',
  },
  {
    icon: 'calendar-days',
    fieldType: FieldType.Date,
    label: 'Date',
  },
  {
    icon: 'tally',
    fieldType: FieldType.Number,
    label: 'Numbers',
  },
  {
    icon: 'square-chevron-down',
    fieldType: FieldType.Select,
    label: 'Dropdown',
  },
  {
    icon: 'list',
    fieldType: FieldType.MultiChoice,
    label: 'Multi Choice',
  },
  {
    icon: 'users',
    fieldType: FieldType.Members,
    label: 'Members',
  },
  {
    icon: 'table',
    fieldType: FieldType.Table,
    label: 'Table',
  },
  {
    icon: 'brackets-curly',
    fieldType: FieldType.Snippet,
    label: 'Snippet',
  },
  {
    icon: 'eye-slash',
    fieldType: FieldType.Hidden,
    label: 'Hidden',
  },
];

export interface ContentFieldOption extends NewContentWidgetItem {
  icon: CustomIconName;
  label: string;
  fieldType?: FieldType;
}

export const CONTENT_FIELDS: ContentFieldOption[] = [
  {
    icon: 'text',
    widgetType: WidgetType.Text,
    label: 'Text',
  },
  {
    icon: 'image',
    widgetType: WidgetType.Image,
    label: 'Image',
  },
  {
    icon: 'file',
    widgetType: WidgetType.File,
    label: 'File',
  },
];

export const WF_EDITOR_CONTENT_FIELDS: ContentFieldOption[] = [
  {
    icon: 'text',
    widgetType: WidgetType.Text,
    label: 'Text',
  },
  {
    icon: 'image',
    widgetType: WidgetType.Image,
    label: 'Image',
  },
  {
    icon: 'file',
    widgetType: WidgetType.File,
    label: 'File',
  },
  {
    icon: 'video',
    widgetType: WidgetType.Video,
    label: 'Video',
  },
  {
    icon: 'file-alt',
    widgetType: WidgetType.CrossLink,
    label: 'Page',
  },
  {
    icon: 'envelope',
    widgetType: WidgetType.FormField,
    fieldType: FieldType.SendRichEmail,
    label: 'Send Email',
  },
  {
    icon: 'list',
    widgetType: WidgetType.FormField,
    fieldType: FieldType.MultiSelect,
    label: 'Subtasks',
  },
  {
    icon: 'code',
    widgetType: WidgetType.Embed,
    label: 'Embed',
  },
];

export const PAGES_EDITOR_CONTENT_FIELDS: ContentFieldOption[] = [
  {
    icon: 'text',
    widgetType: WidgetType.Text,
    label: 'Text',
  },
  {
    icon: 'image',
    widgetType: WidgetType.Image,
    label: 'Image',
  },
  {
    icon: 'video',
    widgetType: WidgetType.Video,
    label: 'Video',
  },
  {
    icon: 'file',
    widgetType: WidgetType.File,
    label: 'File',
  },
  {
    icon: 'table',
    widgetType: WidgetType.Table,
    label: 'Table',
  },
  {
    icon: 'code',
    widgetType: WidgetType.Embed,
    label: 'Embed',
  },
  {
    icon: 'workflow',
    widgetType: WidgetType.CrossLink,
    label: 'Workflows',
  },
];
