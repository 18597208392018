import { match, P } from 'ts-pattern';
import { useDataSetId } from '../../../utils/use-data-set-id';
import { useTemplateId } from '../../../utils/use-template-id';

export const useWebhookType = () => {
  const templateId = useTemplateId();
  const dataSetId = useDataSetId();

  return match({ templateId, dataSetId })
    .with({ dataSetId: P.string }, () => 'DataSet' as const)
    .otherwise(() => 'Workflow' as const);
};
