import { Muid } from '@process-street/subgrade/core';
import { DataSetLinkWithNames } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type GetDataSetLinksParams = {
  dataSetId: Muid;
};

type GetDataSetLinksResponse = DataSetLinkWithNames[];

export const GetDataSetLinksQuery = {
  key: ['data-sets', 'links'],
  getKey: (params: GetDataSetLinksParams): QueryKey => [...GetDataSetLinksQuery.key, params],
  queryFn: (params: GetDataSetLinksParams) =>
    axiosService
      .getAxios()
      .get(`/1/data-sets/${params.dataSetId}/links`)
      .then(r => r.data),
};

export const useGetDataSetLinksQuery = (
  params: GetDataSetLinksParams,
  options?: UseQueryOptions<GetDataSetLinksResponse, AxiosError>,
) => {
  return useQuery(GetDataSetLinksQuery.getKey(params), () => GetDataSetLinksQuery.queryFn(params), options);
};
