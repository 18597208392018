import * as React from 'react';
import { Button, Icon, Menu, MenuButton, MenuGroup, MenuList, Portal, Text } from 'components/design/next';
import { SandboxRefreshButton } from 'features/template/components/sandbox-refresh-button';
import { ChecklistRevision, Template } from '@process-street/subgrade/process';
import { RestartSandboxButton } from '../restart-sandbox-button';
import { RedirectNotificationsToggle } from '../redirect-notifications-toggle';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';

export type MobileMeatballMenuProps = {
  checklistRevisionId: ChecklistRevision['id'];
  templateId?: Template['id'];
  templateName: Template['name'];
};

export const MobileMeatballMenu = ({ checklistRevisionId, templateId, templateName }: MobileMeatballMenuProps) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="outline"
        p="0"
        borderColor="white"
        colorScheme="gray"
        _hover={{ bgColor: 'gray.200' }}
        _active={{ bgColor: 'gray.200' }}
        _focus={{ bgColor: 'gray.200' }}
      >
        <Icon icon="ellipsis-h" size="4" />
        <Text as="span" srOnly>
          Open sandbox menu
        </Text>
      </MenuButton>
      <Portal>
        <ThemeProvider2024>
          <MenuList>
            <RedirectNotificationsToggle isMobile />
            <MenuGroup title="Sandbox">
              <RestartSandboxButton templateId={templateId} templateName={templateName} isMobile />
              <SandboxRefreshButton checklistRevisionId={checklistRevisionId} isMobile />
            </MenuGroup>
          </MenuList>
        </ThemeProvider2024>
      </Portal>
    </Menu>
  );
};
