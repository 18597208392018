import { bindStateToStateProperty } from './bind-state-to-state-property';
import { bindActionCreatorsToActions } from './bind-action-creators-to-actions';

/**
 * Simple wrap for $ngRedux that automatically follows notation and adds onDestroy & onChange calls to the component.
 */
export const connectController = (store, mapStateToThis, mapDispatchToThis, shouldChange) => controller => {
  let unsubscribe;
  const subscribe = () => {
    if (unsubscribe) {
      unsubscribe();
    }

    const targetMapStateToThis = mapStateToThis ? bindStateToStateProperty(mapStateToThis()) : null;
    const targetMapDispatchToThis = mapDispatchToThis ? bindActionCreatorsToActions(mapDispatchToThis()) : null;

    unsubscribe = store.connect(targetMapStateToThis, targetMapDispatchToThis)(controller);
  };

  if (controller.$onInit) {
    subscribe();
  } else {
    controller.$onInit = () => {
      subscribe();
    };
  }

  if (shouldChange) {
    const oldOnChange = controller.$onChanges && controller.$onChanges.bind(controller);
    controller.$onChanges = changes => {
      if (shouldChange(changes)) {
        subscribe();
      }

      if (oldOnChange) {
        oldOnChange(changes);
      }
    };
  }

  const oldOnDestroy = controller.$onDestroy && controller.$onDestroy.bind(controller);
  controller.$onDestroy = () => {
    if (unsubscribe) {
      unsubscribe();
    }

    if (oldOnDestroy) {
      oldOnDestroy();
    }
  };
};
