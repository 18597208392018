import * as React from 'react';
import { Button, Box, Icon, Stack, Text, IconButton } from 'components/design/next';
import {
  Clause,
  Condition,
  ConditionalFilterOperand,
  ConditionalFilterOperandType,
  ConditionalFilterUtils,
  conditionNameMap,
  FilterType,
} from '@process-street/subgrade/dashboard';
import { match } from 'ts-pattern';
import { OperandCell } from 'components/dashboard/components/checklist/ChecklistDashboardFilters/filters/ChecklistDashboardConditionalFilter/OperandCell';
import {
  FormResponseStatusListOperandEditor,
  FORM_RESPONSE_STATUS_LIST_OPERAND_EDITOR_ID,
} from './form-response-status-list-operand-editor';
import {
  useFormResponsesStore,
  fieldChecklistColumnNameMap,
  FormResponseChecklistColumn,
  FormResponsesUtils,
} from '../../../store';
import { makeColumnsFilterItemStateMachine } from 'pages/reports/data-sets/components/columns-filter/columns-filter-item-machine';
import { useMachine } from '@xstate/react';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { SelectInstance } from 'react-select';
import { getChecklistColumnHeaderName } from 'components/dashboard/models/columns';

export interface FormResponsesConditionalFilterClauseProps {
  clause: Clause;
  isFirst: boolean;
  filterType: FilterType;
  onFilterDelete(id: string): void;
  onFilterSwitchType(filterType: FilterType): void;
  onChange(clause: Clause): void;
}

type ColumnOptionType = {
  value: FormResponseChecklistColumn;
  label: string;
};

type ConditionOptionType = {
  value: Condition;
  label: string;
};

export const FormResponsesConditionalFilterClause: React.FC<
  React.PropsWithChildren<FormResponsesConditionalFilterClauseProps>
> = ({ clause, onFilterDelete, onFilterSwitchType, filterType, isFirst, onChange }) => {
  const { predefinedColDefs: colDefs } = useFormResponsesStore();
  const columnRef = React.useRef<SelectInstance<ColumnOptionType>>(null);
  const conditionRef = React.useRef<SelectInstance<ConditionOptionType>>(null);
  const valueRef = React.useRef<HTMLInputElement>(null);

  const [state, send] = useMachine(
    () =>
      makeColumnsFilterItemStateMachine<FormResponseChecklistColumn, Condition, ConditionalFilterOperand>({
        getIsValueConfigured,
      }),
    {
      context: {
        columnId: clause.columnName as FormResponseChecklistColumn,
        condition: clause.condition,
        value: clause.operand,
      },
      actions: {
        persist: (ctx, _e) => {
          if (ctx.columnId && ctx.condition && ctx.value) {
            onChange({ ...clause, columnName: ctx.columnId, condition: ctx.condition, operand: ctx.value });
          }
        },
        focusColumn: (_c, _e) => {
          columnRef.current?.focus?.();
        },
        focusCondition: (_c, _e) => {
          conditionRef.current?.focus?.();
        },
        focusValue: (_c, _e) => {
          // HACK: the OperandCell is really tough to work with in terms of something like ref.current.focus()
          const selector = match(state.context.value)
            .with(
              { operandType: ConditionalFilterOperandType.ChecklistStatusList },
              () => '[data-multiselect-toggle="true"]',
            )
            .otherwise(() => 'input');
          const input = valueRef.current?.querySelector(selector) as HTMLInputElement;
          input?.click();
        },
      },
    },
  );

  const handleColumnChange = (columnOption: ColumnOptionType) => {
    const column = columnOption.value;
    const { context } = state;
    const { condition, operand } = ConditionalFilterUtils.getClauseConditionAndOperand({
      column,
      condition: context.condition,
      operand: context.value,
    });
    send({ type: 'SELECT_COLUMN', id: column, condition, value: operand });
  };

  const handleConditionChange = (conditionOption: ConditionOptionType) => {
    const condition = conditionOption.value;
    const { context } = state;
    const { operand } = ConditionalFilterUtils.getClauseConditionAndOperand({
      column: context.columnId!,
      condition: conditionOption.value,
      operand: context.value,
    });
    send({ type: 'SELECT_CONDITION', condition, value: operand });
  };

  const handleValueChange = (value: ConditionalFilterOperand) => {
    send({ type: 'UPDATE_VALUE', value });
  };

  return (
    <Stack direction={{ base: 'column', md: 'row' }} key={clause.id} w="full">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <IconButton
          aria-label="remove filter"
          icon={<Icon icon="xmark" size="3" />}
          size="xs"
          variant="ghost"
          onClick={() => onFilterDelete(clause.id)}
        />
        <Box w="12" display="flex" justifyContent={{ base: 'flex-end', md: 'flex-start' }}>
          {isFirst ? (
            <Text variant="-2u">show</Text>
          ) : (
            <Button
              variant="ghost"
              size="xs"
              px="0"
              onClick={() => onFilterSwitchType(filterType === FilterType.And ? FilterType.Or : FilterType.And)}
            >
              <Text variant="-2u">
                {match({ filterType })
                  .with({ filterType: FilterType.And }, () => 'and')
                  .otherwise(() => 'or')}
              </Text>
            </Button>
          )}
        </Box>
      </Stack>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        sx={{
          '& > .blvd-select': { w: { base: 'full', md: '36' } },
          [`& #${FORM_RESPONSE_STATUS_LIST_OPERAND_EDITOR_ID}`]: { width: { base: 'full', md: '58' } },
        }}
      >
        <BlvdSelect
          aria-label="Column filter"
          options={colDefs.filter(FormResponsesUtils.isFilterableColDef).map(column => ({
            value: fieldChecklistColumnNameMap[column.field],
            label: getChecklistColumnHeaderName(fieldChecklistColumnNameMap[column.field]),
          }))}
          placeholder="Column"
          value={
            state.context.columnId
              ? {
                  value: state.context.columnId,
                  label: getChecklistColumnHeaderName(state.context.columnId),
                }
              : undefined
          }
          selectRef={columnRef}
          openMenuOnFocus={state.matches('column')}
          onChange={value => {
            if (value && BlvdSelectHelpers.isOptionType<ColumnOptionType>(value)) {
              handleColumnChange(value);
            }
          }}
        />

        <BlvdSelect
          aria-label="Column condition"
          options={getConditionItems(state.context.columnId!)}
          value={
            state.context.condition
              ? { value: state.context.condition, label: conditionNameMap[state.context.condition] }
              : undefined
          }
          selectRef={conditionRef}
          placeholder="Condition"
          openMenuOnFocus={state.matches('condition')}
          onChange={value => {
            if (value && BlvdSelectHelpers.isOptionType<ConditionOptionType>(value)) {
              handleConditionChange(value);
            }
          }}
        />

        <Box ref={valueRef}>
          <OperandCell
            components={{
              ChecklistStatus: FormResponseStatusListOperandEditor,
            }}
            operand={state.context.value ?? clause.operand}
            onChange={handleValueChange}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

const getConditionItems = (column: FormResponseChecklistColumn) =>
  ConditionalFilterUtils.getConditionsForChecklistColumn(column).map(key => ({
    value: key,
    label: conditionNameMap[key],
  }));

function getIsValueConfigured(operand?: ConditionalFilterOperand) {
  const isConfigured = match(operand)
    .with({ operandType: ConditionalFilterOperandType.ChecklistStatusList }, ({ value }) => value.length > 0)
    .otherwise(() => false);
  return isConfigured;
}
