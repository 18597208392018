import { Muid, Option, ProcessingErrorFactory, TaskError } from '@process-street/subgrade/core';
import { Task, TaskStatus } from '@process-street/subgrade/process';
import {
  BaseTaskSelector,
  BaseTaskStatsSelector,
  BaseTaskTemplateSelector,
} from '@process-street/subgrade/redux/selector/';
import { BaseReduxState } from '@process-street/subgrade/redux/types';

const hasInvalidFields = (taskId: Muid, state: BaseReduxState): boolean => {
  const taskStats = BaseTaskStatsSelector.getByTaskId(taskId)(state);
  return !!taskStats && taskStats.invalidFieldsCount > 0;
};

const isStopTask = (task: Task, state: BaseReduxState): boolean => {
  const taskTemplate = BaseTaskTemplateSelector.getById(task.taskTemplate.id)(state);
  return !!taskTemplate && taskTemplate.stop;
};

// TODO: Switch to TaskService.validateIfTaskStatusUpdatable
const isCompletableTask = (taskId: Muid, state: BaseReduxState): boolean => {
  // Step 1. Checking if there is task stats and any invalid fiedls
  if (hasInvalidFields(taskId, state)) {
    return false;
  }
  const task = BaseTaskSelector.getById(taskId)(state);
  if (!task) {
    return true;
  }
  // Step 2. Checking if this is a stop task
  if (!isStopTask(task, state)) {
    return true;
  }
  // Step 3. Check all prior tasks for being completable
  const tasks = BaseTaskSelector.getAllByChecklistRevisionId(task.checklistRevision.id)(state);
  const taskIndex = tasks.findIndex(t => t.id === taskId);
  if (taskIndex < 1) {
    return true;
  }
  return tasks.every((t, i) => {
    if (i >= taskIndex || t.hidden) {
      return true;
    }
    if (hasInvalidFields(t.id, state)) {
      return false;
    }
    if (isStopTask(t, state)) {
      return t.status === TaskStatus.Completed;
    }
    return true;
  });
};

export const validateUpdateTaskStatus = (
  taskId: Muid,
  status: TaskStatus,
  state: BaseReduxState,
): Option<TaskError> => {
  // Step 1. We currently validate only Task completion, and ignore the rest
  if (status === TaskStatus.Completed && !isCompletableTask(taskId, state)) {
    return ProcessingErrorFactory.application.internalError();
  }

  return undefined;
};

export const validateUpdateDueDate = (dueDate: number | null): Option<TaskError> => {
  if (dueDate && dueDate < Date.now()) {
    return ProcessingErrorFactory.task.dueDateInPast();
  }

  return undefined;
};

export const TaskUpdateValidator = {
  validateUpdateDueDate,
  validateUpdateTaskStatus,
};
