import { OrganizationMembership, OrganizationMembershipWithUser } from './organization-model';
import { isStandardUserOrNonSystemGroupOm } from '@process-street/subgrade/util/membership-utils';

export namespace OrganizationMembershipUtils {
  export const isAdmin = (membership: OrganizationMembership) => membership.admin;

  export const isMember = (membership: OrganizationMembership) => !membership.admin && !membership.guest;

  export const isGuest = (membership: OrganizationMembership) => membership.guest;

  export const isAssignable = (membership: OrganizationMembershipWithUser) =>
    isStandardUserOrNonSystemGroupOm(membership);
}
