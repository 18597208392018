import { Muid } from '@process-street/subgrade/core';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { LookupMap } from '@process-street/subgrade/redux/types';
import {
  CREATE_FOLDER_PERMIT,
  CreateOrUpdateFolderPermitActionType,
  DELETE_FOLDER_PERMIT,
  DeleteFolderPermitActionType,
  FOLDER_PERMITS_GET_ALL_BY_FOLDER_AND_ORGANIZATION_ID,
  GetFolderPermitsActionType,
} from 'components/folder/membership/store/folder-membership.actions';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { toSuccess } from 'reducers/util/handlers';
import { combineReducers } from 'redux';

export const folderPermitActionHandler = {
  [toSuccess(FOLDER_PERMITS_GET_ALL_BY_FOLDER_AND_ORGANIZATION_ID)]: (
    state: LookupMap,
    { payload }: GetFolderPermitsActionType,
  ): LookupMap => {
    const newState = LookupsReducerUtils.upsertAllUsingSelectorFunctions(
      state,
      payload.response,
      () => payload.folderId,
    );
    return newState;
  },
  [toSuccess(CREATE_FOLDER_PERMIT)]: (
    state: LookupMap,
    { payload }: CreateOrUpdateFolderPermitActionType,
  ): LookupMap => {
    const existingPermits: Muid[] = state[payload.folderId];
    if (existingPermits) {
      const newPermits = [payload.permit.id, ...existingPermits];
      return {
        ...state,
        [payload.folderId]: newPermits,
      };
    } else {
      return {
        ...state,
        [payload.folderId]: [payload.permit.id],
      };
    }
  },

  [toSuccess(DELETE_FOLDER_PERMIT)]: (state: LookupMap, { payload }: DeleteFolderPermitActionType): LookupMap => {
    const existingPermits: Muid[] = state[payload.folderId];
    if (existingPermits) {
      const newPermits = existingPermits.reduce((agg: Muid[], permitId: Muid) => {
        if (permitId !== payload.permitId) {
          agg.push(permitId);
        }
        return agg;
      }, []);
      return {
        ...state,
        [payload.folderId]: newPermits,
      };
    } else {
      return state;
    }
  },
};

export const folderPermitsLookupsReducer = combineReducers({
  byFolderId: handleActionsOnSuccess(folderPermitActionHandler, {}),
});
