import * as React from 'react';
import { FileFormFieldWidget } from '@process-street/subgrade/process';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useModalContext,
  VStack,
} from 'components/design/next';
import { Form, Formik, useField } from 'formik';
import { Placeholder } from '../fields';
import { useWidgetSettingsContext } from '../widget-settings-context';

type FileFormFieldSettings = Pick<FileFormFieldWidget, 'constraints' | 'config'>;

const ExtensionsInput = () => {
  const [field, meta, helpers] = useField<string[] | undefined>('constraints.extensions');
  const { setValue } = helpers;

  const setExtensions = (value: string) => {
    const extensions = value.trim() !== '' ? splitString(value) : undefined;

    setValue(extensions);
  };

  const splitString = (value: string) => {
    return value.split(',').map(ext => {
      const t = ext.trim();
      const e = t.length === 0 || t.startsWith('.') ? t : '.' + t;
      return e;
    });
  };

  const extensions = (field.value ?? []).join(',');
  return (
    <FormControl>
      <FormLabel variant="1" fontWeight="medium" color="gray.600" mb={1.5}>
        Allowed file types
      </FormLabel>

      <Input
        {...field}
        id={field.name}
        onChange={e => setExtensions(e.target.value)}
        isInvalid={!!meta.error && meta.touched}
        value={extensions}
        name="constraints.extensions"
        aria-label="set allowed extensions"
        placeholder="Comma separated extensions ie. pdf, doc"
        variant="outline"
      />
    </FormControl>
  );
};

/**
 * Removes undefined or false values from the constraints object. Allows a user to reset the constraints to {}.
 */
const sanitiseData = ({ config, constraints }: FileFormFieldSettings): FileFormFieldSettings => {
  return {
    config,
    constraints: {
      ...(Boolean(constraints?.extensions) && { extensions: constraints?.extensions }),
    },
  };
};

export const FileSettings = () => {
  const { widget, onUpdate } = useWidgetSettingsContext<FileFormFieldWidget>();
  const { onClose } = useModalContext();

  const handleSubmit = (values: FileFormFieldSettings) => {
    // We have to pass the whole widget because update is done in Angular.
    const updatedData = {
      ...widget,
      ...sanitiseData(values),
    };

    onUpdate(updatedData);
    onClose();
  };

  const initialValues = {
    constraints: { ...widget.constraints },
    config: { placeholder: widget.config.placeholder || '' },
  };

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader p={8}>
        <Text variant="2">{widget.label || 'Untitled file upload field'}</Text>
      </ModalHeader>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isValid, dirty }) => (
          <Form>
            <ModalBody px={9} py={2}>
              <VStack spacing="6">
                <ExtensionsInput />
                <Placeholder />
              </VStack>
            </ModalBody>

            <ModalFooter p={6}>
              <ButtonGroup>
                <Button aria-label="cancel changes" variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button aria-label="set constraints" isDisabled={!(isValid && dirty)} type="submit">
                  Apply
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};
