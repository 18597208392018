import * as React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from 'components/design/next';
import { DatasetModalOption } from 'pages/data-sets/components/create-data-set-modal/dataset-modal-option';
import { getWidgetModalsMachineService, Selectors } from 'features/widgets/widget-modals-machine';
import { useSelector } from '@xstate/react';
import { SelectFormFieldWidget } from '@process-street/subgrade/process';

export type ConnectDataSetModalProps = {};

const actor = getWidgetModalsMachineService();

export const ConnectDataSetModal: React.FC<ConnectDataSetModalProps> = () => {
  const widget = useSelector(actor, Selectors.getWidget<SelectFormFieldWidget>);
  const isOpen = useSelector(actor, Selectors.getIsOpen('connectDataSet'));

  const close = () => {
    actor.send({ type: 'CLOSE_CONNECT_DATA_SET' });
  };
  const openLinkDataSetModal = () => {
    close();
    actor.send({ type: 'OPEN_LINK_DATA_SET', widget });
  };
  const onImportFromCsv = () => {
    close();
    actor.send({ type: 'OPEN_IMPORT_DATA_SET', widget });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={close} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="18px" color="gray.700" py="6" px="8">
            Connect Data Set
          </ModalHeader>

          <ModalCloseButton right="7" top="6" />
          <ModalBody mx={8} pt="6" px={{ base: '9', xs: '4', md: '9' }} pb="10">
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
              alignItems={{ base: 'center', sm: 'stretch' }}
              justifyContent="center"
              gap={{ base: '6', sm: '2', md: '3' }}
              spacing="0"
            >
              <DatasetModalOption
                title="Select Data Set"
                description="Choose an existing Data Set."
                imageSrc={require('app/images/data-sets/database.png')}
                onClick={openLinkDataSetModal}
                stackProps={{
                  maxW: 'unset',
                }}
              />
              <DatasetModalOption
                title="Import Data Set"
                description="Create a Data Set from a CSV file."
                imageSrc={require('app/images/data-sets/import-item.png')}
                onClick={onImportFromCsv}
                stackProps={{
                  maxW: 'unset',
                }}
              />
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
