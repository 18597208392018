import * as React from 'react';
import { useActor } from '@xstate/react';
import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  VStack,
  VisuallyHidden,
} from '@chakra-ui/react';
import { Icon } from 'components/design/next';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { FileContentActor } from './file-content-machine';
import { match, P } from 'ts-pattern';
import { useDropzone } from 'react-dropzone';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { StringUtils } from '@process-street/subgrade/util';
import {
  DownloadFileIconButton,
  FileUpload,
  FileUploadLeftElement,
  FileUploadRightElement,
  FileUploadRightElementInfo,
  FileUploadSizeLabel,
} from 'features/files';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { isPage } from '@process-street/subgrade/process';

export type FileContentProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: FileContentActor;
};

export const FileContent: React.FC<React.PropsWithChildren<FileContentProps>> = ({ actor, isFirst, isLast }) => {
  const [current, send] = useActor(actor);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const ref = React.useRef<HTMLDivElement | null>(null);
  const { widget, template, recentlyMovedFrom, isReadOnly } = current.context;

  const isUploadDisabled = Boolean(current.context.widget?.file || current.context.file) || isReadOnly;

  const dropzoneState = useDropzone({
    onDragEnter: () => send({ type: 'FOCUS' }),
    onDragLeave: () => send({ type: 'BLUR' }),
    onDrop: files => send({ type: 'CHANGE', value: files[0] }),
    maxFiles: 1,
    disabled: isUploadDisabled,
  });

  const description = React.useMemo(() => {
    const { description, file: widgetFile } = current.context.widget ?? {};
    const { name: fileName } = current.context.file ?? {};
    return StringUtils.getNonEmpty(description, widgetFile?.originalName, fileName);
  }, [current.context.file, current.context.widget]);

  const size = React.useMemo(
    () =>
      match({ file: current.context.file, widget: current.context.widget })
        .with({ widget: { file: { size: P.number } } }, ({ widget }) => widget.file.size)
        .with({ file: { size: P.number } }, ({ file: { size } }) => size)
        .otherwise(() => undefined),
    [current.context.file, current.context.widget],
  );

  const handleEditFileName: React.MouseEventHandler<HTMLElement> = e => {
    e.stopPropagation();
    descriptionRef.current?.focus();
    send({ type: 'TOGGLE_DESCRIPTION_EDIT' });
  };

  const descriptionRef = React.useRef<HTMLInputElement>(null);

  // Don't show empty file widget in view mode
  if (isReadOnly && !current.context.widget?.file) return null;

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack
          position="relative"
          w="full"
          alignItems="flex-start"
          ref={node => {
            ref.current = node;
            if (node && !current.context.inputNode) {
              send({ type: 'SET_NODE', node });
            }
          }}
          scrollMarginBottom={17}
        >
          {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          <Box className="file-container" {...dropzoneState.getRootProps()} position="relative">
            <FileUpload
              {...(isUploadDisabled
                ? {}
                : {
                    'aria-label': 'upload file',
                    'cursor': 'pointer',
                    'role': 'button',
                    'onClick': () => {
                      send('FOCUS');
                      inputRef.current?.click();
                    },
                  })}
              borderRadius="base"
              borderWidth={dropzoneState.isDragActive ? 'thin' : '0'}
              borderStyle={dropzoneState.isDragActive ? 'dashed' : 'solid'}
              borderColor={dropzoneState.isDragActive ? 'brand.400' : 'gray.200'}
            >
              <FileUploadLeftElement />

              <FileUploadRightElement>
                {match(description)
                  .with(P.intersection(P.string, P.not('')), description => (
                    <>
                      <FileUploadRightElementInfo>
                        <InputGroup
                          sx={{
                            '.chakra-input': { pe: '0' },
                            '_hover': {
                              '.chakra-input': { pe: isReadOnly ? 0 : '10' },
                              '.chakra-input__right-element': { display: 'flex' },
                            },
                          }}
                        >
                          <Input
                            ref={descriptionRef}
                            {...(current.matches('description.idle')
                              ? { isReadOnly: true, fontWeight: 'medium', textOverflow: 'ellipsis' }
                              : {
                                  fontWeight: '400',
                                })}
                            isReadOnly={isReadOnly}
                            value={description}
                            h="auto"
                            px="0"
                            border="none"
                            color="gray.600"
                            _focus={{ boxShadow: 'none' }}
                            onDoubleClick={handleEditFileName}
                            onClick={e => e.stopPropagation()}
                            onBlur={() => send({ type: 'DESCRIPTION_BLUR' })}
                            onChange={e => send({ type: 'DESCRIPTION_CHANGE', value: e.target.value })}
                            onKeyDown={e => send({ type: 'DESCRIPTION_KEY_DOWN', event: e })}
                          />
                          {!isReadOnly && current.matches('description.idle') ? (
                            <InputRightElement h="auto" display="none">
                              <IconButton
                                variant="ghost"
                                colorScheme="gray"
                                size="xs"
                                aria-label="edit file name"
                                onClick={handleEditFileName}
                                icon={<Icon icon="edit" size="3" />}
                              />
                            </InputRightElement>
                          ) : null}
                        </InputGroup>
                        <FileUploadSizeLabel>{size}</FileUploadSizeLabel>
                      </FileUploadRightElementInfo>

                      <DownloadFileIconButton href={current.context.widget?.file?.url ?? ''} />
                    </>
                  ))
                  .otherwise(() => (
                    <>
                      <Text color="gray.600" fontWeight="medium">
                        Upload file
                      </Text>

                      <Icon icon="arrow-up-to-line" size="4" color="gray.600" />
                    </>
                  ))}
              </FileUploadRightElement>

              {current.matches('mutation.uploading') && (
                <VStack
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  top="0"
                  left="0"
                  bottom="0"
                  right="0"
                  backgroundColor="blackAlpha.500"
                >
                  <Spinner color="white" />
                  <Text color="white">Uploading file</Text>
                </VStack>
              )}

              {dropzoneState.isDragActive && (
                <VStack
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  top="0"
                  left="0"
                  bottom="0"
                  right="0"
                  backgroundColor="blackAlpha.500"
                >
                  <Text color="white">Drop the file here to upload</Text>
                </VStack>
              )}

              <VisuallyHidden sx={{ '& > input': { display: 'block !important' } }}>
                <input
                  ref={inputRef}
                  type="file"
                  data-testid="file-input"
                  onChange={e => {
                    const [file] = e.target.files ?? [];

                    if (file) {
                      send({ type: 'CHANGE', value: file });
                    } else {
                      send('BLUR');
                    }
                  }}
                  {...dropzoneState.getInputProps()}
                />
              </VisuallyHidden>
            </FileUpload>
          </Box>
          {!isReadOnly && (
            <>
              <WidgetListItemDragIcon />
              <FormsWidgetMenu>
                <FormsWidgetMenuItems.Duplicate />
                {widget && !isPage(template) && <FormsWidgetMenuItems.MoveToStep widget={widget} />}
                <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                <FormsWidgetMenuItems.Delete />
              </FormsWidgetMenu>
            </>
          )}
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
