import { User, UserType } from '@process-street/subgrade/core';
import {
  ALL_ADMINS_GROUP_USERNAME,
  ALL_ANONYMOUS_GROUP_USERNAME,
  ALL_FREE_MEMBERS_GROUP_USERNAME,
  ALL_GUESTS_GROUP_USERNAME,
  ALL_MEMBERS_GROUP_USERNAME,
} from './membership-utils';

export const isStandardUser = (user: User) => user.userType === UserType.Standard;

export const isAnonymousUser = (user: User) => user.userType === UserType.Anonymous;

export const isGroupUser = (user: User) => user.userType === UserType.Group;

export const isApiUser = (user: User) => user.userType === UserType.Api;

export const isStandardGroupUser = (user: User) => isGroupUser(user) && !isSystemGroupUser(user);

export const isSystemGroupUser = (user: User) =>
  isGroupUser(user) &&
  [
    ALL_MEMBERS_GROUP_USERNAME,
    ALL_GUESTS_GROUP_USERNAME,
    ALL_ANONYMOUS_GROUP_USERNAME,
    ALL_ADMINS_GROUP_USERNAME,
    ALL_FREE_MEMBERS_GROUP_USERNAME,
  ].includes(user.username);

export const isStandardUserOrGroup = (user: User) => isStandardUser(user) || isGroupUser(user);

export const isStandardUserOrStandardGroup = (user: User) => isStandardUser(user) || isStandardGroupUser(user);
