import { Muid, Option } from '@process-street/subgrade/core';
import { Task, TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { safeEntityMapToArrayByIdsWith } from '@process-street/subgrade/redux/safe-entity-map-to-array-by-ids';
import { BaseTaskSelector } from '@process-street/subgrade/redux/selector/task.selectors';
import { EntityMap, ObjectMap } from '@process-street/subgrade/redux/types';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { ReduxAppState } from 'reducers/types';
import { createSelector, Selector } from 'reselect';

const getTaskIdsByChecklistId =
  (checklistId: Muid): Selector<ReduxAppState, Muid[]> =>
  (state: ReduxAppState) =>
    state.task.byChecklist[checklistId] || [];

/**
 * Returns task map by task.id
 * @param checklistId
 */
const getTaskMapByChecklistId = (checklistId: Muid): Selector<ReduxAppState, EntityMap<TaskWithTaskTemplate>> =>
  createSelector(
    [getTaskIdsByChecklistId(checklistId), BaseTaskSelector.getEntityMap, TaskTemplateSelector.getEntityMap],
    (taskIds, taskMap, taskTemplateMap) =>
      taskIds.reduce((agg: EntityMap<TaskWithTaskTemplate>, taskId: Muid) => {
        const task = taskMap[taskId];
        if (task) {
          const taskTemplate = taskTemplateMap[task.taskTemplate.id];
          if (taskTemplate) {
            agg[task.id] = { ...task, taskTemplate };
          }
        }
        return agg;
      }, {}),
  );

const getTaskWithTaskTemplateById = (taskId: Muid): Selector<ReduxAppState, Option<TaskWithTaskTemplate>> =>
  createSelector(
    [BaseTaskSelector.getById(taskId), TaskTemplateSelector.getEntityMap],
    (taskEntity, taskTemplateMap) => {
      if (taskEntity) {
        const taskTemplate = taskTemplateMap[taskEntity.taskTemplate.id];
        if (taskTemplate) {
          return { ...taskEntity, taskTemplate };
        }
      }
    },
  );

const groupTasksByTemplateRevisionGroupId = (tasks: TaskWithTaskTemplate[]): EntityMap<TaskWithTaskTemplate> =>
  tasks.reduce((agg: ObjectMap<TaskWithTaskTemplate>, task) => {
    agg[task.taskTemplate.group.id] = task;
    return agg;
  }, {});

/**
 * Returns task map by task.taskTemplate.group.id
 * @param checklistId
 */
const getTaskMapByChecklistIdGroupedByTaskTemplateGroupId = (
  checklistId: Muid,
): Selector<ReduxAppState, EntityMap<TaskWithTaskTemplate>> =>
  createSelector([getAllWithTaskTemplateByChecklistId(checklistId)], groupTasksByTemplateRevisionGroupId);

const getTaskMapWithTasksByChecklistIdGroupedByTaskTemplateGroupId = (
  checklistId: Muid,
): Selector<ReduxAppState, { taskMap: EntityMap<TaskWithTaskTemplate>; tasks: TaskWithTaskTemplate[] }> =>
  createSelector([getAllWithTaskTemplateByChecklistId(checklistId)], tasks => ({
    tasks,
    taskMap: groupTasksByTemplateRevisionGroupId(tasks),
  }));

const getAllWithTaskTemplateByChecklistId = (checklistId: Muid): Selector<ReduxAppState, TaskWithTaskTemplate[]> =>
  createSelector(
    [BaseTaskSelector.getEntityMap, getTaskIdsByChecklistId(checklistId), TaskTemplateSelector.getEntityMap],
    (entityMap, taskIds, taskTemplateMap) => {
      const tasks = safeEntityMapToArrayByIdsWith<Task>(
        entityMap,
        taskIds,
        (task: Task): Option<TaskWithTaskTemplate> => {
          const taskTemplate = taskTemplateMap[task.taskTemplate.id];
          if (taskTemplate) {
            return { ...task, taskTemplate };
          }
          return undefined;
        },
      );

      return tasks as TaskWithTaskTemplate[];
    },
  );

const getAllUserAssignmentsByTaskId =
  (taskId: Muid): Selector<ReduxAppState, Muid[]> =>
  (state: ReduxAppState) => {
    const userAssignments = state.task.toAssignees[taskId];
    return userAssignments || [];
  };

const anyTaskUpdateInProgress = (state: ReduxAppState) =>
  Object.values(state.statuses.taskUpdates.byTaskId).some(item => item.loading);

export const TaskSelector = {
  getAllByChecklistId: getAllWithTaskTemplateByChecklistId,
  getAllUserAssignmentsByTaskId,
  getAllWithTaskTemplateByChecklistId,
  getById: BaseTaskSelector.getById,
  getEntityMap: BaseTaskSelector.getEntityMap,
  getTaskMapByChecklistId,
  getTaskMapByChecklistIdGroupedByTaskTemplateGroupId,
  getTaskMapWithTasksByChecklistIdGroupedByTaskTemplateGroupId,
  getTaskWithTaskTemplateById,
  anyTaskUpdateInProgress,
};
