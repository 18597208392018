import { Box, HStack, SimpleGrid, Text, Tooltip, VStack } from '@chakra-ui/react';
import { isSingleWorkflowResponse, useChecklistAnalyticsQuery } from 'features/checklists/query-builder';
import * as React from 'react';
import { match, P } from 'ts-pattern';
import { dayjs } from '@process-street/subgrade/util';
import { useChecklistSearchCriteria } from 'pages/reports/hooks/use-checklist-search-criteria';
import { SectionWrapper } from '../section-wrapper';
import { ProgressStatus } from '@process-street/subgrade/dashboard';
import { Icon } from 'components/design/next';

export type SummaryProps = {
  activeProgressStatus: ProgressStatus | null;
  onSelectProgressStatus: (progressStatus: ProgressStatus | null) => void;
};

export const EMPTY_SYMBOL = '-';

export const Summary = ({ activeProgressStatus, onSelectProgressStatus }: SummaryProps) => {
  const searchCriteria = useChecklistSearchCriteria();
  const checklistAnalyticsQuery = useChecklistAnalyticsQuery(
    { ...searchCriteria, mode: 'Limited' },
    {
      select: analytics => {
        if (!isSingleWorkflowResponse(analytics)) {
          throw new Error('Expecting single workflow response.');
        }
        return analytics.checklistStatusSummary;
      },
    },
  );

  const handleSelectProgressStatus = (progressStatus: ProgressStatus) => {
    onSelectProgressStatus(progressStatus === activeProgressStatus ? null : progressStatus);
  };

  const { data } = checklistAnalyticsQuery;
  const avgTime = match(data?.avgCompletionTimeMinutes)
    .with(P.number, number => {
      const duration = dayjs.duration(number, 'minutes');
      const pattern = { hours: duration.hours(), minutes: duration.minutes() };

      return match(pattern)
        .with({ hours: 0, minutes: P.number }, ({ minutes }) => `${minutes}'`)
        .with({ hours: P.number, minutes: P.number }, ({ hours, minutes }) => `${hours}h ${minutes}'`)
        .otherwise(() => null);
    })
    .otherwise(() => null);

  return (
    <VStack as={SectionWrapper} spacing="6" minH="282px" alignItems="center" p="5" py="6">
      <Text fontWeight="500" color="gray.600">
        Workflow runs summary
      </Text>

      <SimpleGrid w="full" rowGap={4} columnGap={2} columns={2}>
        <Stat
          onClick={() => handleSelectProgressStatus(ProgressStatus.DueSoon)}
          isActive={activeProgressStatus === ProgressStatus.DueSoon}
          value={data?.dueSoon ?? EMPTY_SYMBOL}
          label="Due soon"
        />
        <Stat
          onClick={() => handleSelectProgressStatus(ProgressStatus.OnTrack)}
          isActive={activeProgressStatus === ProgressStatus.OnTrack}
          value={data?.onTrack ?? EMPTY_SYMBOL}
          label="On track"
        />
        <Stat
          onClick={() => handleSelectProgressStatus(ProgressStatus.Overdue)}
          isActive={activeProgressStatus === ProgressStatus.Overdue}
          value={data?.overdue ?? EMPTY_SYMBOL}
          label="Overdue"
        />
        <Stat value={avgTime ?? EMPTY_SYMBOL} label="Average time" tooltip="Average time to completion" />
      </SimpleGrid>
    </VStack>
  );
};

const Stat: React.FC<
  React.PropsWithChildren<{
    value: React.ReactNode;
    label: React.ReactNode;
    onClick?: () => void;
    isActive?: boolean;
    tooltip?: string;
  }>
> = ({ isActive, value, label, tooltip, onClick }) => {
  return (
    <VStack
      padding={3}
      onClick={onClick}
      bgColor={isActive ? 'brand.100' : undefined}
      borderRadius="12px"
      cursor={onClick ? 'pointer' : undefined}
    >
      <Text fontSize="xl" fontWeight="bold" color="gray.600">
        {value}
      </Text>

      <HStack>
        <Text fontSize="sm" color="gray.500">
          {label}
        </Text>

        {tooltip && (
          <Tooltip label={tooltip}>
            <Box>
              <Icon icon="info-circle" size="3" color="gray.500" />
            </Box>
          </Tooltip>
        )}
      </HStack>
    </VStack>
  );
};
