import * as React from 'react';
import { Link, Text, TextProps } from 'components/design/next';

type SignUpFooterProps = {
  fontSize?: TextProps['fontSize'];
};

export const SignUpFooter: React.FC<React.PropsWithChildren<SignUpFooterProps>> = ({ fontSize }) => (
  <Text color="gray.500" fontSize={fontSize ?? 'xs'} mt={4}>
    By signing up, you agree to the{' '}
    <Link color="brand.500" href="https://www.process.st/privacy" target="_blank">
      Privacy Policy
    </Link>{' '}
    and{' '}
    <Link color="brand.500" href="https://www.process.st/terms" target="_blank">
      Terms of Service
    </Link>
    .
  </Text>
);
