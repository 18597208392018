import angular from 'angular';
import { AxiosResourceFactory } from 'app/resources/factory/axios-resource-factory';
import { QCompat } from 'app/resources/factory/qcompat';

export type QResource = (params: Record<string, unknown>) => { $promise: angular.IPromise<unknown> };

export type QResources<T> = Record<keyof T, QResource>;

export type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type Action = {
  method: Method;
  url: string;
  params?: string[];
  withCredentials?: boolean;
};

export type Routes = Record<string, string | Action>;

export interface ResourceFactory {
  create<T extends Routes>(routes: T): QResources<T>;
}

angular.module('frontStreetApp.services').service('ResourceFactory', function (qCompat: QCompat): ResourceFactory {
  return {
    create<T extends Routes>(routes: T): QResources<T> {
      return qCompat(AxiosResourceFactory.create(routes));
    },
  };
});
