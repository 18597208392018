export const Widgets = ResourceFactory => {
  'ngInject';

  return ResourceFactory.create({
    getAllByChecklistRevisionId: 'GET[] /1/checklist-revisions/:id/widgets',
    getAllByTemplateRevisionId: 'GET[] /1/template-revisions/:id/widgets',
    getAllChecklistWidgetsByChecklistRevisionId: 'GET[] /1/checklist-revisions/:id/checklist-widgets',
    createAt: 'PUT /1/widgets/:headerId',
    createFile: 'POST /1/widgets/:headerId',
    copy: 'POST /1/widgets/:srcHeaderId/copy/:dstHeaderId',
    update: 'PATCH /1/widgets/:headerId',
    deleteByHeaderId: 'DELETE /1/widgets/:headerId',
    getAllByTaskTemplateId: 'GET[] /1/task-templates/:taskTemplateId/widgets',
    updateAllOrderTrees: 'PUT[] /1/widgets/order-tree',
  });
};
