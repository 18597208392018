import * as React from 'react';
import { BlvdSelect, BlvdSelectHelpers, BlvdSelectProps } from 'components/design/BlvdSelect';
import { getOffsetDirectionEntities, Timing, Entity, EntityType, getNewTimingFromEntity } from './rule-trees';
import { FieldTypePreservingOmit } from '@process-street/subgrade/core';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { useTemplateRevision } from '../../template-revision-context';
import { match } from 'ts-pattern';
import { TaskTemplate } from '@process-street/subgrade/process';
import { SelectComponentsConfig, components, GroupBase } from 'react-select';
import { HStack, Text, Box, Divider } from 'components/design/next';

export type EntitySelectProps = FieldTypePreservingOmit<
  BlvdSelectProps<EntityOption, false, GroupBase<EntityOption>>,
  'onChange'
> & {
  timing: Timing;
  onChange: React.Dispatch<React.SetStateAction<Timing>>;
};

export type EntityOption = { entity: Entity; label: string; value: string } & (
  | { type: 'WorkflowRun'; label: string }
  | {
      type: 'Task';
      taskTemplate: TaskTemplate;
      index: number;
    }
);

export const EntitySelect: React.FC<React.PropsWithChildren<EntitySelectProps>> = ({ timing, onChange, ...props }) => {
  const { id: templateRevisionId } = useTemplateRevision();
  const taskTemplatesQuery = useTaskTemplatesByTemplateRevisionIdQuery({ templateRevisionId });
  const taskTemplateIds = React.useMemo(
    () => taskTemplatesQuery.data?.map(({ id }) => id) ?? [],
    [taskTemplatesQuery.data],
  );
  const entityToOption = React.useMemo(
    () => makeEntityToOption({ taskTemplates: taskTemplatesQuery.data ?? [] }),
    [taskTemplatesQuery.data],
  );
  const entityToValue = React.useCallback(
    (entity?: Entity): EntityOption | null => (entity ? entityToOption(entity) : null),
    [entityToOption],
  );

  const options = React.useMemo(() => {
    // TODO for adding widgets, I'd do something like this:
    // options.flatmap(option => option.type === "Task" ? [option, ...widgetsByTaskTemplateId[option.taskTemplate.id]] : [option])
    // Then just cuse a custom renderer for `Option` to indent widgets
    return getOffsetDirectionEntities({ timing, templateRevisionId, taskTemplateIds }).map(entityToOption);
  }, [entityToOption, taskTemplateIds, templateRevisionId, timing]);

  return (
    <BlvdSelect
      isSearchable
      value={entityToValue(timing.entity)}
      options={options}
      placeholder="Select"
      components={COMPONENTS}
      getOptionValue={option => option.value}
      onChange={option => {
        if (BlvdSelectHelpers.isOptionType<EntityOption>(option)) {
          onChange(t => getNewTimingFromEntity(t, option.entity));
        } else {
          onChange(t => ({ ...t, entity: undefined, property: undefined }));
        }
      }}
      {...props}
    />
  );
};

const ENTITY_LABELS: { [k in EntityType]?: string } = {
  WorkflowRun: 'Workflow Run',
};

const makeEntityToOption = ({ taskTemplates }: { taskTemplates: TaskTemplate[] }) => {
  const taskTemplatesWithIndexMap = new Map(taskTemplates.map((t, i) => [t.id, { ...t, index: i + 1 }]));

  return (entity: Entity): EntityOption =>
    match<Entity, EntityOption>(entity)
      .with({ type: 'Task' }, e => {
        const { taskTemplateId } = e;
        const taskTemplate = taskTemplatesWithIndexMap.get(taskTemplateId);
        if (!taskTemplate) {
          throw new Error(`TaskTemplate with id ${taskTemplateId} not found`);
        }
        return {
          value: taskTemplateId,
          entity: e,
          label: taskTemplate.name ?? 'Unnamed Task',
          type: 'Task',
          taskTemplate,
          index: taskTemplatesWithIndexMap.get(taskTemplateId)?.index ?? 0,
        };
      })
      .with({ type: 'WorkflowRun' }, e => ({
        value: e.type,
        label: ENTITY_LABELS[e.type] ?? e.type,
        entity: e,
        type: 'WorkflowRun',
      }))
      .run();
};

const COMPONENTS: SelectComponentsConfig<EntityOption, false, GroupBase<EntityOption>> = {
  Option: ({ children: _, ...props }) => {
    const option = props.data as EntityOption;
    return (
      <>
        <Box as={components.Option<EntityOption, false, GroupBase<EntityOption>>} {...props}>
          {match(option)
            .with({ type: 'WorkflowRun' }, () => 'Workflow Run')
            .with({ type: 'Task' }, ({ index, label }) => (
              <HStack spacing="2">
                <Text variant="-2" fontWeight="medium" color="gray.500">
                  {index}
                </Text>
                <Text variant="1">{label}</Text>
              </HStack>
            ))

            .otherwise(() => null)}
        </Box>
        {/**
         * NOTE this divider might have to change over time. Currently it separates Workflow Run from Tasks,
         * but in the future there could be other non task entities that might come after Workflow Run.
         */}
        {option.type === 'WorkflowRun' ? <Divider /> : null}
      </>
    );
  },
};
