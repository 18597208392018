import { FieldType, HiddenFormFieldWidget, TEXTAREA_MAX_LENGTH } from '@process-street/subgrade/process';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';
import { FormFieldValueSchemaTests } from '../common/validation/form-field-value-schema-tests';
import { FormFieldWidgetSettingsSchemas } from '../common/validation/form-field-widget-settings-schema';

export const hiddenSettingsSchema: ObjectSchema<WidgetSettings<FieldType.Hidden>> = yup
  .object({
    hasVariables: yup.boolean(),
    config: yup
      .object<HiddenFormFieldWidget['config']>({
        ...FormFieldWidgetSettingsSchemas.defaultValue(TEXTAREA_MAX_LENGTH),
      })
      .required(),
  })
  .required();

type ValidationSchemaProps = {
  required: boolean;
};

export const makeHiddenValidationSchema = ({ required }: ValidationSchemaProps) => {
  return yup.string().test(FormFieldValueSchemaTests.required(required));
};
