import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { entitiesSelector } from 'reducers/entities/entities.selectors';
import { UserSelector } from 'reducers/user/user.selectors';

export const groupMembershipSelector = state => entitiesSelector(state).groupMemberships;

export const groupMembershipByGroupSelector = (state, groupId) => {
  const memberships = Object.values(groupMembershipSelector(state))
    .filter(mem => mem.group.id === groupId)
    .map(groupMem => {
      const orgMemId = groupMem.organizationMembership.id;
      const orgMem = OrganizationMembershipSelector.getById(orgMemId)(state);

      const userId = orgMem.user.id;
      const user = UserSelector.getById(userId)(state);

      const orgMemWithUser = { ...orgMem, user };
      return { ...groupMem, organizationMembership: orgMemWithUser };
    });

  return memberships;
};
