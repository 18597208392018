import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
} from 'components/design/next';
import { SendRichEmailFormFieldConfig } from '@process-street/subgrade/process';

export type SwitchModeAlertProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  targetEmailFormat: SendRichEmailFormFieldConfig['emailFormat'];
};

export const SwitchModeAlert: React.FC<React.PropsWithChildren<SwitchModeAlertProps>> = ({
  isOpen,
  onClose,
  targetEmailFormat,
  onConfirm,
}) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const header = targetEmailFormat === 'RichTextOrHtml' ? 'Switch to Rich text?' : 'Switch to Plain text?';
  const stayIn = targetEmailFormat === 'RichTextOrHtml' ? 'Stay in Plain text Editor' : 'Stay in Rich text Editor';

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {header}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>
              All content will be <b>discarded</b> if you change to
            </Text>
            {targetEmailFormat === 'RichTextOrHtml' && <Text>the rich text editor.</Text>}
            {targetEmailFormat === 'PlainText' && <Text>the plain text editor.</Text>}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} variant="ghost" onClick={onClose}>
              {stayIn}
            </Button>
            <Button variant="danger" onClick={onConfirm} ml={3}>
              Switch
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
