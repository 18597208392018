import { Muid } from '@process-street/subgrade/core';
import { MemberOption, MemberItem } from 'components/common/MemberOption';
import { Tooltip, FormControl, FormLabel, Text, Icon, Box, HStack } from 'components/design/next';
import { BlvdMembersPicker } from 'components/design/BlvdMembersPicker';
import React from 'react';

export interface SavedViewPermissionSelectorProps {
  allMembers: MemberItem[];
  organizationMembershipIds: Muid[];
  onAdd: (value: MemberItem) => void;
  onRemove: (value: Muid) => void;
}

export const SavedViewPermissionSelector: React.FunctionComponent<
  React.PropsWithChildren<SavedViewPermissionSelectorProps>
> = ({ allMembers, organizationMembershipIds, onAdd, onRemove }) => {
  const assignedMembers = allMembers.filter(member =>
    organizationMembershipIds.includes(member.organizationMembershipId),
  );
  const availableMembers = allMembers.filter(
    member => !organizationMembershipIds.includes(member.organizationMembershipId),
  );

  return (
    <FormControl>
      <FormLabel>
        <HStack>
          <Text>Who has access?</Text>
          <Tooltip label="Administrators can always see non-private saved views" shouldWrapChildren hasArrow>
            <Icon icon="info-circle" variant="far" size="4" color="brand.500" />
          </Tooltip>
        </HStack>
      </FormLabel>

      <Box
        sx={{
          '& .dropdown-menu': {
            mt: '1',
            w: 'full',
          },
          '& .open [data-multiselect-toggle=true]': {
            bgColor: 'gray.100',
          },
        }}
      >
        <BlvdMembersPicker id="SavedViewPermissionSelectorMembersPicker" onSelect={onAdd} items={availableMembers} />
      </Box>

      <Box overflowY="auto" maxH="50">
        {assignedMembers.map(member => (
          <MemberOption
            key={member.organizationMembershipId}
            item={member}
            selectable={false}
            deletable={true}
            onDelete={onRemove}
          />
        ))}
      </Box>
    </FormControl>
  );
};
