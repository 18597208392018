// Adapted from:
// http://stackoverflow.com/questions/14692640/angularjs-directive-to-replace-text

import { UrlUtils } from 'components/utils/url-utils';
import { EmailService } from './email-service';

// http://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
const URL_REGEX = /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#/%?=~_|!:,.;](?!&nbsp;))*[-A-Z0-9+&@#/%=~_|])/gi;

function autolinkUrls(text?: string) {
  if (text) {
    let linkedText = '';
    let index = 0;
    let match;
    while ((match = URL_REGEX.exec(text)) !== null) {
      const [url] = match;
      const http = url.indexOf('http') === 0;
      const mailto = url.indexOf('mailto') === 0;

      let link;
      if (!http && !mailto) {
        link = (EmailService.isValidEmailAddress(url) ? 'mailto:' : 'http://') + url;
      } else {
        link = url;
      }
      linkedText += text.substring(index, match.index);
      if (UrlUtils.isAppUrl(link)) {
        linkedText += `<a href="${link}" rel="nofollow">${url}</a>`;
      } else {
        linkedText += `<a href="${link}" rel="nofollow" target="_blank" rel="noopener noreferrer">${url}</a>`;
      }

      index = URL_REGEX.lastIndex;
    }
    linkedText += text.substring(index);
    return linkedText;
  } else {
    return undefined;
  }
}

export const HtmlServiceUtils = {
  autolinkUrls,
};
