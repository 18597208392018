import * as React from 'react';
import { components, OnChangeValue } from 'react-select';
import { Box, HStack, Text, Tooltip } from 'components/design/next';
import { DataSetColumnDef } from '@process-street/subgrade/process';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { Icon } from 'components/design/next';
import { BlvdSelect, BLVD_SELECT_MAGIC_GRID_AREA } from 'components/design/BlvdSelect';
import { match } from 'ts-pattern';

export type ColumnOptionType = {
  label: string;
  value: string;
  column: DataSetColumnDef;
};

export type DataSetColumnsVisibilityFilterProps = {
  selected: ColumnOptionType[];
  columns: DataSetColumnDef[];
  onChange: (columns: OnChangeValue<ColumnOptionType, true>) => void;
  isDisabled: boolean;
};

export const columnDefToOptionType = (def: DataSetColumnDef): ColumnOptionType => ({
  label: def.name,
  value: def.id,
  column: def,
});

export const DataSetColumnsVisibilityFilterV1: React.FC<
  React.PropsWithChildren<DataSetColumnsVisibilityFilterProps>
> = ({ columns, selected, onChange, isDisabled }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const options: ColumnOptionType[] = columns.map(column => ({
    label: column.name,
    value: column.id,
    column,
  }));

  const handleChange = (selectedValue: OnChangeValue<ColumnOptionType, true>) => {
    onChange(selectedValue);
  };

  const hasHiddenColumns = selected.length < columns.length;
  const shouldHighlightButton = isOpen || hasHiddenColumns;
  const filterColor = isDisabled ? 'gray.300' : shouldHighlightButton ? 'brand.500' : 'gray.500';

  return (
    <Box
      sx={{
        '.blvd-select': {
          'display': 'inline-block',

          '.blvd-select__menu': {
            minW: '250px',
          },

          '.blvd-select__control': {
            'boxShadow': 'none',
            'borderColor': isOpen || hasHiddenColumns ? 'brand.500' : 'transparent',
            'bgColor': isOpen || hasHiddenColumns ? 'brand.100' : 'transparent',
            'borderRadius': 'full',
            'fontWeight': isOpen || hasHiddenColumns ? '500' : 'normal',
            'minH': 10,

            '&:hover': {
              cursor: 'pointer',
              borderColor: 'brand.500',
              bgColor: 'brand.100',
            },
          },

          '.blvd-select__option': {
            '&.blvd-select__option--is-focused, &:hover': {
              bgColor: 'brand.100',
              color: 'brand.500',
            },
          },
        },
      }}
    >
      <Tooltip
        label="You don't have access to edit this saved view. Please reach out to the owner or an admin."
        hasArrow={true}
        placement="bottom"
        fontSize="xs"
        isDisabled={!isDisabled}
        shouldWrapChildren
      >
        <BlvdSelect
          isDisabled={isDisabled}
          value={selected}
          onChange={handleChange}
          options={options}
          onMenuOpen={() => setIsOpen(true)}
          onMenuClose={() => setIsOpen(false)}
          components={{
            MultiValue: Empty,
            IndicatorsContainer: Empty,
            Placeholder: Empty,
            ValueContainer: props => {
              const selectedColumnsCount = BlvdSelectHelpers.isOptionsType(selected) ? selected.length : columns.length;

              return (
                <components.ValueContainer {...props}>
                  <HStack display="inline-flex" gridArea={BLVD_SELECT_MAGIC_GRID_AREA}>
                    <Icon icon="eye-slash" size="4" color={filterColor} />
                    <Text color={filterColor} fontSize="sm">
                      {match(selectedColumnsCount)
                        .with(0, () => 'Fields')
                        .with(1, () => '1 Field')
                        .otherwise(l => `${l} Fields`)}
                    </Text>
                    <Icon icon="chevron-down" size="3" color={filterColor} />
                  </HStack>

                  {props.children}
                </components.ValueContainer>
              );
            },
          }}
          menuControls
          fixedSize
          isClearable
          isMulti
          isSearchable
        />
      </Tooltip>
    </Box>
  );
};

const Empty: React.FC<React.PropsWithChildren<unknown>> = () => null;
