import * as React from 'react';
import { components, OptionProps } from 'react-select';
import { AiTaskWidgetSelectItem } from './ai-task-widget-select-item';
import { AiTaskWidgetOption } from './use-ai-task-widget-select';

export type AiTaskWidgetSelectOptionProps = OptionProps<AiTaskWidgetOption, false>;

export const AiTaskWidgetSelectOption: React.FC<AiTaskWidgetSelectOptionProps> = props => {
  return (
    <components.Option {...props}>
      <AiTaskWidgetSelectItem label={props.data.label} fieldType={props.data.fieldType} />
    </components.Option>
  );
};
