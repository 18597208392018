import { Muid } from '@process-street/subgrade/core';
import { MergeTagMode, MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { FieldType, FormFieldWidget, TaskTemplateTaskType, WidgetType } from '@process-street/subgrade/process';
import { trace } from 'components/trace';
import { GetDueDateRulesByTemplateRevisionIdQuery } from 'features/dynamic-due-dates/query-builder';
import { useFeatureFlag } from 'features/feature-flags';
import { GetTaskAssignmentRulesByTemplateRevisionIdQuery } from 'features/task-assignment-rules/query-builder';
import { GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery } from 'features/task-template-assignment/query-builder';
import { TaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { WidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { match } from 'ts-pattern';

type UseGetAiGenerationWorkflowSummaryStatsParams = {
  templateRevisionId: Muid;
};

export const useGetAiGenerationWorkflowSummaryStats = ({
  templateRevisionId,
}: UseGetAiGenerationWorkflowSummaryStatsParams) => {
  const logger = trace({ name: 'useGetAiGenerationWorkflowSummaryStats' });
  const queryClient = useQueryClient();
  const newStaleTimeEnabled = useFeatureFlag('tuneReactQueryCaching');

  const getStats = useCallback(async () => {
    try {
      // Invalidate queries for stats if staleTime is 60s to make sure we display the correct information in the summary modal.
      if (newStaleTimeEnabled) {
        await Promise.all([
          queryClient.invalidateQueries(WidgetsByTemplateRevisionIdQuery.getKey(templateRevisionId)),
          queryClient.invalidateQueries(
            GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
          ),
          queryClient.invalidateQueries(GetTaskAssignmentRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId })),
          queryClient.invalidateQueries(GetDueDateRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId })),
        ]);
      }

      const widgetsPromise = queryClient.fetchQuery({
        queryKey: WidgetsByTemplateRevisionIdQuery.getKey(templateRevisionId),
        queryFn: () => WidgetsByTemplateRevisionIdQuery.queryFn(templateRevisionId),
      });

      const tasksPromise = queryClient.fetchQuery({
        queryKey: TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
        queryFn: () => TaskTemplatesByTemplateRevisionIdQuery.queryFn({ templateRevisionId }),
      });

      const taskTemplateAssignmentPromise = queryClient.fetchQuery({
        queryKey: GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
        queryFn: () => GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery.queryFn({ templateRevisionId }),
      });

      const taskAssignmentRulesPromise = queryClient.fetchQuery({
        queryKey: GetTaskAssignmentRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
        queryFn: () => GetTaskAssignmentRulesByTemplateRevisionIdQuery.queryFn({ templateRevisionId }),
      });

      const dynamicDueDatesPromise = queryClient.fetchQuery({
        queryKey: GetDueDateRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
        queryFn: () => GetDueDateRulesByTemplateRevisionIdQuery.queryFn({ templateRevisionId }),
      });

      const results = await Promise.all([
        tasksPromise,
        widgetsPromise,
        taskTemplateAssignmentPromise,
        taskAssignmentRulesPromise,
        dynamicDueDatesPromise,
      ]);

      const [tasks, widgets, taskAssignments, taskAssignmentRules, dueDateRules] = results;

      const stopTasks = tasks.filter(task => task.stop);
      const approvals = tasks.filter(task => task.taskType === TaskTemplateTaskType.Approval);
      const formFields = widgets.filter(widget => widget.header.type === WidgetType.FormField) as FormFieldWidget[];
      const mergeTagsCount = formFields
        .filter(ff => ff.fieldType === FieldType.SendRichEmail)
        .reduce((mergeTagsCount, formField) => {
          const foundTags: string[] = match(formField)
            .with({ fieldType: FieldType.SendRichEmail }, ff =>
              MergeTagStringReplacementUtils.findTags(ff.config.richEditorBody ?? '', MergeTagMode.HTML),
            )
            .otherwise(() => []);

          return foundTags.length + mergeTagsCount;
        }, 0);

      // Get only tasks what has assignments
      const tasksWithAssignments = tasks.filter(task => {
        const hasTaskAssignment = taskAssignments.find(ta => ta.taskTemplate.id === task.id);
        const hasTaskAssignmentRule = taskAssignmentRules.find(tar => tar.targetTaskTemplateGroup.id === task.group.id);

        return hasTaskAssignment || hasTaskAssignmentRule;
      });

      return {
        tasksCount: tasks.length,
        approvalsCount: approvals.length,
        stopTasksCount: stopTasks.length,
        formFieldsCount: formFields.length,
        assigneesCount: tasksWithAssignments.length,
        variablesCount: mergeTagsCount,
        dynamicDueDatesCount: dueDateRules.length,
      };
    } catch (e) {
      logger.error('Failed to fetch stats', e);

      return undefined;
    }
  }, [logger, newStaleTimeEnabled, queryClient, templateRevisionId]);

  return getStats;
};
