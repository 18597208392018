import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { Text } from 'components/design/next';
import { UploadAttachmentComment } from 'features/comments/components/common/attachment/upload-attachment';
import {
  SIZE_TOO_LARGE,
  useUploadAttachmentComment,
} from 'features/comments/components/common/attachment/use-upload-attachment-comment';
import * as React from 'react';
import last from 'lodash/last';
import { UploadProgress } from 'features/upload/components';
import { AttachmentType } from '@process-street/subgrade/process';

export type AddAttachmentButtonProps = {
  task: OneOffTask;
  onAddAttachment?: () => void;
};

export const AddAttachmentButton: React.FC<React.PropsWithChildren<AddAttachmentButtonProps>> = ({
  task,
  onAddAttachment,
}) => {
  const [uploadingCount, setUploadingCount] = React.useState(0);
  const [progress, setProgress] = React.useState<number | undefined>(undefined);

  const onFinishUploadAttachment = () => {
    setUploadingCount(0);
    setProgress(undefined);
    onAddAttachment?.();
  };

  const { dropzoneState, uploadError: _uploadError } = useUploadAttachmentComment({
    taskId: task.id,
    checklistId: task.internalChecklistId,
    setUploadingCount,
    onFinish: () => onFinishUploadAttachment(),
    setProgress,
    isDropDisabled: false,
    shouldBroadcastEvents: false,
    attachmentType: AttachmentType.Task,
  });

  const fileTooLarge =
    dropzoneState.fileRejections.length > 0 && last(dropzoneState.fileRejections[0].errors)?.code === SIZE_TOO_LARGE;

  return (
    <>
      {uploadingCount > 0 ? (
        <UploadProgress progress={progress as number} message="Uploading attachments..." />
      ) : (
        <>
          <UploadAttachmentComment
            taskId={task.id}
            checklistId={task.internalChecklistId}
            setUploadingCount={setUploadingCount}
            onFinish={onFinishUploadAttachment}
            progress={progress}
            setProgress={setProgress}
            disabled={false}
            rightText="Add attachment"
            shouldBroadcastEvents={false}
            attachmentType={AttachmentType.Task}
          />
          {fileTooLarge && (
            <Text color="red.500" align="center" fontWeight="medium">
              {last(dropzoneState.fileRejections[0].errors)?.message}
            </Text>
          )}
        </>
      )}
    </>
  );
};
