import { Muid, Option } from '@process-street/subgrade/core';
import {
  Checklist,
  DueDateRuleOffsetDirection,
  DueDateRuleSourceType,
  FormFieldValue,
  isSimpleFieldValue,
  Task,
  TaskStatus,
  toTaskMapByTaskTemplateId,
} from '@process-street/subgrade/process';
import {
  BaseChecklistRevisionSelector,
  BaseTaskSelector,
  BaseTaskStatsSelector,
  BaseTaskTemplateSelector,
} from '@process-street/subgrade/redux/selector';
import { BaseReduxState } from '@process-street/subgrade/redux/types';
import { dayjs as moment } from '@process-street/subgrade/util';
import { OptimisticResultBuilder } from '../..';
import { BaseDynamicDueDateRuleSelector, DynamicDueDateRule } from './dynamic-due-date-rule.selector';
import { DateContext, DateContextUtils } from '@process-street/subgrade/core/date-context';

function calculateRelativeDate(
  dueDateRule: DynamicDueDateRule,
  dateValue: number | string | undefined,
  dateContext?: DateContext,
): Option<moment.Dayjs> {
  const timeZone = DateContextUtils.getOrganizationTimeZone(dateContext);
  const relativeDateMoment = dateValue ? moment(dateValue, 'x').tz(timeZone) : undefined;
  if (!relativeDateMoment || !relativeDateMoment.isValid()) {
    return undefined;
  }
  const offset = Object.assign(
    {
      days: 0,
      hours: 0,
      minutes: 0,
      months: 0,
    },
    dueDateRule.dueOffset,
  );

  switch (dueDateRule.offsetDirection) {
    case DueDateRuleOffsetDirection.Before:
      return adjustDateForWorkdays(dueDateRule, relativeDateMoment.subtract(offset));
    case DueDateRuleOffsetDirection.After:
    default:
      return adjustDateForWorkdays(dueDateRule, relativeDateMoment.add(offset));
  }
}

function adjustDateForWorkdays(dueDateRule: DynamicDueDateRule, date: moment.Dayjs): moment.Dayjs {
  if (dueDateRule.workdaysOnly) {
    switch (dueDateRule.offsetDirection) {
      case DueDateRuleOffsetDirection.Before:
        switch (date.isoWeekday()) {
          case 7:
            return date.subtract(2, 'd'); // Sun
          case 6:
            return date.subtract(1, 'd'); // Sat
          default:
            return date;
        }
      case DueDateRuleOffsetDirection.After:
      default:
        switch (date.isoWeekday()) {
          case 7:
            return date.add(1, 'd'); // Sun
          case 6:
            return date.add(2, 'd'); // Sat
          default:
            return date;
        }
    }
  } else {
    return date;
  }
}

function updateDueDate(
  targetTask: Option<Task>,
  newDueDate: Option<moment.Dayjs>,
  resultBuilder: OptimisticResultBuilder,
): void {
  if (targetTask && !targetTask.dueDateOverridden) {
    const dueDate = newDueDate ? newDueDate.valueOf() : undefined;
    if (targetTask.dueDate !== dueDate) {
      resultBuilder.task.appendUpdateEvent(
        { id: targetTask.id, dueDate: dueDate ? dueDate.valueOf() : undefined },
        targetTask,
      );
    }
  }
}

function applyRules(
  rules: DynamicDueDateRule[],
  dateValue: string | number | undefined,
  tasks: Task[],
  resultBuilder: OptimisticResultBuilder,
) {
  const taskByTaskTemplateIdMap = toTaskMapByTaskTemplateId(tasks);

  rules.forEach((rule: DynamicDueDateRule) => {
    const targetTask = rule.targetTaskTemplateId ? taskByTaskTemplateIdMap[rule.targetTaskTemplateId] : undefined;
    const newDueDate = calculateRelativeDate(rule, dateValue);
    updateDueDate(targetTask, newDueDate, resultBuilder);
  });
}

/* Extract rules relate to FormFieldValue */
function getRulesForFormFieldValue(
  formFieldValue: FormFieldValue,
  state: BaseReduxState,
): Option<DynamicDueDateRule[]> {
  const checklistRevision = BaseChecklistRevisionSelector.getById(formFieldValue.checklistRevision.id)(state);
  if (!checklistRevision) {
    return undefined;
  }

  const rulesByFormFieldWidgetIdMap = BaseDynamicDueDateRuleSelector.getByTemplateRevisionIdGroupedByFormFieldWidgetId(
    checklistRevision.templateRevision.id,
  )(state);
  return rulesByFormFieldWidgetIdMap[formFieldValue.formFieldWidget.id];
}

/**
 * Updates all tasks' due dates as a result of form field update
 */
function applyOnFormFieldValueUpdate(
  formFieldValue: FormFieldValue,
  resultBuilder: OptimisticResultBuilder,
  state: BaseReduxState,
) {
  const relatedRules = getRulesForFormFieldValue(formFieldValue, state);
  if (!relatedRules) {
    return;
  }

  const tasks = BaseTaskSelector.getAllByChecklistRevisionId(formFieldValue.checklistRevision.id)(state);

  const dateValue: string | number | undefined =
    formFieldValue.fieldValue && isSimpleFieldValue(formFieldValue.fieldValue)
      ? formFieldValue.fieldValue.value
      : undefined;

  applyRules(relatedRules, dateValue, tasks, resultBuilder);
}

/**
 * Updates all tasks' due dates as a result of form field delete
 */
function applyOnFormFieldValueDelete(
  formFieldValue: FormFieldValue,
  resultBuilder: OptimisticResultBuilder,
  state: BaseReduxState,
) {
  const relatedRules = getRulesForFormFieldValue(formFieldValue, state);
  if (!relatedRules) {
    return;
  }

  const tasks = BaseTaskSelector.getAllByChecklistRevisionId(formFieldValue.checklistRevision.id)(state);

  applyRules(relatedRules, undefined, tasks, resultBuilder);
}

function applyTaskCompletedDateRules(updatedTask: Task, resultBuilder: OptimisticResultBuilder, state: BaseReduxState) {
  const taskTemplate = BaseTaskTemplateSelector.getById(updatedTask.taskTemplate.id)(state);
  if (!taskTemplate) {
    return;
  }

  const rulesByTaskTemplatesIdMap = BaseDynamicDueDateRuleSelector.getByTemplateRevisionIdGroupedByTaskTemplateId(
    taskTemplate.templateRevision.id,
  )(state);
  const relatedRules = rulesByTaskTemplatesIdMap[taskTemplate.id];

  if (!relatedRules) {
    return;
  }

  const tasks = BaseTaskSelector.getAllByChecklistRevisionId(updatedTask.checklistRevision.id)(state);

  const dateValue = updatedTask.status === TaskStatus.Completed ? updatedTask.completedDate : undefined;
  applyRules(
    relatedRules.filter(rule => rule.sourceType === DueDateRuleSourceType.TaskCompletedDate),
    dateValue,
    tasks,
    resultBuilder,
  );
}

function applyPreviousTaskCompletedDateRules(
  updatedTask: Task,
  resultBuilder: OptimisticResultBuilder,
  state: BaseReduxState,
) {
  const checklistRevision = BaseChecklistRevisionSelector.getById(updatedTask.checklistRevision.id)(state);
  if (!checklistRevision) {
    return;
  }

  const orderedTaskStats = BaseTaskStatsSelector.getAllOrderedWithOrderTreeByChecklistId(
    checklistRevision.checklist.id,
  )(state);
  const nextTaskId = orderedTaskStats.findIndex(task => task.taskId === updatedTask.id) + 1;
  if (nextTaskId === orderedTaskStats.length || nextTaskId === 0) {
    return;
  }
  const nextTask = BaseTaskSelector.getById(orderedTaskStats[nextTaskId].taskId)(state);
  if (!nextTask) {
    return;
  }

  const templateRevisionId = checklistRevision.templateRevision.id;
  // prettier-ignore
  const rulesByTargetTaskTemplatesIdMap = BaseDynamicDueDateRuleSelector
    .getByTemplateRevisionIdWithPreviousTaskCompleteGroupedByTargetTaskTemplateId(templateRevisionId)(state);
  const relatedRules = rulesByTargetTaskTemplatesIdMap[nextTask.taskTemplate.id];

  if (!relatedRules) {
    return;
  }

  const tasks = BaseTaskSelector.getAllByChecklistRevisionId(updatedTask.checklistRevision.id)(state);

  const dateValue = updatedTask.status === TaskStatus.Completed ? updatedTask.completedDate : undefined;
  applyRules(relatedRules, dateValue, tasks, resultBuilder);
}

/**
 * Updates due dates of all related tasks which are affected as a result of task completion
 *
 * This function DOES NOT modify passed in tasks, instead updates redux state
 *
 * @param rules
 * @param tasks
 * @param updatedTask
 * @param originalTask
 *
 * @returns All the tasks which may or may not be updated
 */
function applyOnTaskStatusChange(
  updatedTask: Task,
  resultBuilder: OptimisticResultBuilder,
  state: BaseReduxState,
): void {
  applyTaskCompletedDateRules(updatedTask, resultBuilder, state);
  applyPreviousTaskCompletedDateRules(updatedTask, resultBuilder, state);
}

/**
 * Updates due dates of all affected tasks as a result of task due date change
 *
 * This function DOES NOT modify passed in tasks, instead updates redux state
 *
 * @param rules
 * @param tasks
 * @param updatedTask
 * @param originalTask
 *
 * @returns All the tasks which may or may not be updated
 */
function applyOnTaskDueDateChange(updatedTask: Task, resultBuilder: OptimisticResultBuilder, state: BaseReduxState) {
  const taskTemplate = BaseTaskTemplateSelector.getById(updatedTask.taskTemplate.id)(state);
  if (!taskTemplate) {
    return;
  }

  const rulesByTaskTemplatesIdMap = BaseDynamicDueDateRuleSelector.getByTemplateRevisionIdGroupedByTaskTemplateId(
    taskTemplate.templateRevision.id,
  )(state);
  const relatedRules = rulesByTaskTemplatesIdMap[updatedTask.taskTemplate.id];

  if (!relatedRules) {
    return;
  }

  const tasks = BaseTaskSelector.getAllByChecklistRevisionId(updatedTask.checklistRevision.id)(state);

  applyRules(
    relatedRules.filter(rule => rule.sourceType === DueDateRuleSourceType.TaskDueDate),
    updatedTask.dueDate,
    tasks,
    resultBuilder,
  );
}

/**
 * Updates due dates of all affected tasks as a result of checklist due date update
 *
 * This function DOES NOT modify passed in tasks, instead updates redux state
 *
 * @param rules
 * @param tasks
 * @param checklist
 *
 * @returns All the tasks which may or may not be updated
 */
function applyOnChecklistDueDateChange(
  checklist: Checklist,
  checklistRevisionId: Muid,
  resultBuilder: OptimisticResultBuilder,
  state: BaseReduxState,
) {
  const checklistRevision = BaseChecklistRevisionSelector.getById(checklistRevisionId)(state);
  if (!checklistRevision) {
    return;
  }

  const relatedRules = BaseDynamicDueDateRuleSelector.getByTemplateRevisionIdWithChecklistDueDate(
    checklistRevision.templateRevision.id,
  )(state);

  if (relatedRules.length === 0) {
    return;
  }

  const tasks = BaseTaskSelector.getAllByChecklistRevisionId(checklistRevision.id)(state);

  applyRules(relatedRules, checklist.dueDate, tasks, resultBuilder);
}

export const DynamicDueDateEngine = {
  adjustDateForWorkdays,
  applyOnChecklistDueDateChange,
  applyOnFormFieldValueDelete,
  applyOnFormFieldValueUpdate,
  applyOnTaskDueDateChange,
  applyOnTaskStatusChange,
  calculateRelativeDate,
};
