import * as React from 'react';
import { ReactTestRenderer } from 'react-test-renderer';

export function mapWithIndex<T>(len: number, mapper: (index: number) => T): T[] {
  return [...Array(len)].map((_, index) => mapper(index));
}

export function mapWithNaturalIndex<T>(len: number, mapper: (index: number) => T): T[] {
  return mapWithIndex(len, index => mapper(index + 1));
}

export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const findByElementTypeAndClass = (tree: ReactTestRenderer, elementType: React.ElementType, className: string) =>
  tree.root.find(el => el.type === elementType && el.props.className && el.props.className.indexOf(className) > -1);

// some hacky business to interfere with react ref setting
// https://dev.to/tmikeschu/testing-element-dimensions-without-the-browser-5532
export function setMockRefElement<T>(node: T): void {
  const origUseRef = React.useRef;
  const mockRef = {
    get current() {
      // jest dom elements have no width,
      // so mocking a browser situation
      return node;
    },
    // we need a setter here because it gets called when you
    // pass a ref to <component ref={ref} />
    set current(_value) {
      // tslint pleaser
    },
  };

  jest.spyOn(React, 'useRef').mockImplementation(((...args) => {
    // Typically the initial value is only null for component refs.
    if (args[0] === null) {
      return mockRef;
    }
    return origUseRef(...args);
  }) as typeof origUseRef);
}
