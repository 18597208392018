import * as React from 'react';
import { match } from 'ts-pattern';
import { components, OptionProps } from 'react-select';
import { DataSetDropdownOptionType } from './data-sets-dropdown';
import { Box, HStack, Icon, Text, Tooltip } from 'components/design/next';

export const DataSetDropdownOption: React.FC<
  React.PropsWithChildren<OptionProps<DataSetDropdownOptionType, false>>
> = props => {
  const { data } = props;

  const isDataSet = data.type === 'DataSet';
  const isDisabled = isDataSet && !data.dataSet.canAccessDataSet;
  const hasSavedViews = data.dataSet.savedViews.length > 0;

  const textColor = match({ isSelected: props.isSelected, type: data.type, isDisabled })
    .with({ isDisabled: true }, () => 'gray.400')
    .with({ isSelected: true }, () => 'brand.500')
    .with({ type: 'DataSet' }, () => 'gray.600')
    .otherwise(() => 'gray.500');

  const iconColor = isDisabled ? 'gray.300' : 'gray.500';

  return (
    <Box className="blvd-select__option__wrapper" title={props.label}>
      <components.Option {...props}>
        <Tooltip
          label="You don't have access to this data set.  Please reach out to the owner or an admin."
          hasArrow={true}
          placement="right"
          fontSize="xs"
          isDisabled={!isDisabled}
        >
          <HStack
            w="full"
            spacing="2"
            pl={data.type === 'SavedView' ? '8' : '0'}
            alignItems="flex-start"
            alignContent="middle"
            aria-disabled={isDisabled}
          >
            <Text color={textColor}>
              {isDataSet && <Icon icon="database" size="4" color={iconColor} display="inline" mr="1" />}
              {data.label}
            </Text>

            {isDataSet && hasSavedViews && <Text color="gray.400">({data.dataSet.savedViews.length})</Text>}
          </HStack>
        </Tooltip>
      </components.Option>
    </Box>
  );
};
