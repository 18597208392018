import { QueryActorSelectors } from 'app/utils/query-builder';
import {
  FormEditorPageMachineCreateWidgetEvent,
  FormPageEditorMachineContext,
  TaskTemplatesLookupMap,
  WidgetsLookupMap,
} from './form-editor-page-machine-types';
import {
  FieldType,
  MembersFormFieldConfig,
  OrderTree,
  SelectFormFieldConfig,
  SendRichEmailFormFieldConfig,
  TaskTemplate,
  TemplateType,
  TextareaFormFieldConfig,
  Widget,
  WidgetType,
} from '@process-street/subgrade/process';
import { OptimisticTaskTemplate } from 'app/features/task-templates/query-builder';
import { match, P } from 'ts-pattern';
import { FormFieldHelpers } from '../helpers/form-field';
import { Muid, MuidUtils, orderTreeService } from '@process-street/subgrade/core';
import { TableFormFieldConfig } from '@process-street/subgrade/process/configs/table-form-field-config';
import { NewFormFieldWidgetItem } from '../types';
import { DEFAULT_TABLE_CONTENT } from '../components/content/table-content-widget';
import { FormFieldDefaultKey } from 'app/services/form-field-service-constants';
import groupBy from 'lodash/groupBy';

export namespace FormEditorPageMachineHelpers {
  export function getTemplate(context: FormPageEditorMachineContext) {
    return QueryActorSelectors.getQueryData(context.templateQuery);
  }

  export function getTemplateRevisions(context: FormPageEditorMachineContext) {
    return QueryActorSelectors.getQueryData(context.templateRevisionsQuery);
  }

  export function getDraftTemplateRevision(context: FormPageEditorMachineContext) {
    return getTemplateRevisions(context)?.[1];
  }

  export function getPublishedTemplateRevision(context: FormPageEditorMachineContext) {
    return getTemplateRevisions(context)?.[0];
  }

  export function getTemplateRevision(context: FormPageEditorMachineContext) {
    return context.isReadOnly ? getPublishedTemplateRevision(context) : getDraftTemplateRevision(context);
  }

  export function getTaskTemplates(context: FormPageEditorMachineContext) {
    const taskTemplatesLookup =
      QueryActorSelectors.getQueryData(context.taskTemplatesByTemplateRevisionIdQuery) ?? emptyTaskTemplatesLookupMap;

    if (!taskTemplatesLookup.all.length) return context.publishedTaskTemplates ?? [];
    return taskTemplatesLookup.all ?? [];
  }

  export const emptyWidgetsLookup: WidgetsLookupMap = {
    byId: {},
    byGroupId: {},
    byTaskTemplateId: {},
    byHeaderId: {},
    all: [],
  };

  export const emptyTaskTemplatesLookupMap: TaskTemplatesLookupMap = {
    byId: {},
    byGroupId: {},
    all: [],
  };

  export function createWidgetsLookup(widgets: Array<Widget>) {
    return widgets.reduce<WidgetsLookupMap>(
      (lookup, widget) => {
        lookup.byId[widget.id] = widget;
        lookup.byGroupId[widget.header.group.id] = widget.id;
        lookup.byHeaderId[widget.header.id] = widget;

        const existingWidgetsForTaskTemplateId = lookup.byTaskTemplateId[widget.header.taskTemplate.id] ?? [];

        lookup.byTaskTemplateId[widget.header.taskTemplate.id] = [...existingWidgetsForTaskTemplateId, widget.id];

        return lookup;
      },
      {
        byId: {},
        byGroupId: {},
        byTaskTemplateId: {},
        byHeaderId: {},
        all: widgets,
      },
    );
  }

  export function createTaskTemplatesLookup(taskTemplates: Array<TaskTemplate>) {
    return taskTemplates.reduce<TaskTemplatesLookupMap>(
      (lookup, taskTemplate) => {
        lookup.byId[taskTemplate.id] = taskTemplate;
        lookup.byGroupId[taskTemplate.group.id] = taskTemplate.id;

        return lookup;
      },
      {
        byId: {},
        byGroupId: {},
        all: taskTemplates,
      },
    );
  }

  export function getOrderTreesRelativeToWidget({
    orderTrees,
    ...event
  }: FormEditorPageMachineCreateWidgetEvent & {
    orderTrees: OrderTree[];
  }): OrderTree[] {
    return match(event)
      .with(
        { after: P.not(P.nullish) },
        ({
          after: {
            header: { orderTree },
          },
        }) => orderTreeService.after(orderTrees, orderTree!),
      )
      .with(
        { before: P.not(P.nullish) },
        ({
          before: {
            header: { orderTree },
          },
        }) => orderTreeService.before(orderTrees, orderTree!),
      )
      .otherwise(() => orderTreeService.before(orderTrees, null));
  }

  export function makeWidgetRequest({
    event,
    allWidgets,
    taskTemplateId,
    defaultGroupId,
    templateType,
  }: {
    event: FormEditorPageMachineCreateWidgetEvent;
    allWidgets: Widget[];
    taskTemplateId: Muid;
    defaultGroupId: Muid;
    templateType: TemplateType;
  }) {
    const widgetsByTaskTemplate = groupBy(allWidgets, w => w.header.taskTemplate.id);
    const taskTemplateWidgets = widgetsByTaskTemplate[taskTemplateId] ?? [];
    const orderTrees = taskTemplateWidgets.map(w => w.header.orderTree!);
    const [orderTree] = getOrderTreesRelativeToWidget({ ...event, orderTrees });

    const keys = FormFieldHelpers.toKeys(allWidgets);
    const config = match(event.payload)
      .with(
        { fieldType: P.union(FieldType.Select, FieldType.MultiChoice) },
        (): SelectFormFieldConfig => ({
          items: [
            { id: MuidUtils.randomMuid(), name: '' },
            { id: MuidUtils.randomMuid(), name: '' },
            { id: MuidUtils.randomMuid(), name: '' },
          ],
        }),
      )
      .with(
        { fieldType: P.union(FieldType.Table) },
        (): TableFormFieldConfig.Config => ({
          columnDefs: [
            { id: MuidUtils.randomMuid(), name: 'Name', columnType: 'Text' },
            { id: MuidUtils.randomMuid(), name: 'ID', columnType: 'Text' },
          ],
        }),
      )
      .with(
        { fieldType: P.union(FieldType.Members) },
        (): MembersFormFieldConfig => ({
          groupId: defaultGroupId,
        }),
      )
      .with(
        { fieldType: P.union(FieldType.Textarea) },
        (): TextareaFormFieldConfig => ({
          format:
            (event.payload as NewFormFieldWidgetItem).featureFlags?.longTextFieldMarkdown &&
            templateType === TemplateType.Playbook
              ? 'RichText'
              : 'PlainText',
        }),
      )
      .with(
        { fieldType: P.union(FieldType.SendRichEmail) },
        (): SendRichEmailFormFieldConfig => ({
          editor: 'RichEditor',
          emailFormat: 'RichTextOrHtml',
          to: [],
          cc: [],
          bcc: [],
          subject: '',
          plainTextBody: '',
          richEditorBody: '<p></p>',
          rawHTMLBody: '',
          editAllowed: true,
        }),
      )
      .with(
        { fieldType: P.union(FieldType.MultiSelect) },
        (): SelectFormFieldConfig => ({
          items: [
            { id: MuidUtils.randomMuid(), name: '' },
            { id: MuidUtils.randomMuid(), name: '' },
            { id: MuidUtils.randomMuid(), name: '' },
          ],
        }),
      )
      .with({ fieldType: P.not(P.nullish) }, () => ({}))
      .otherwise(() => undefined);
    const widgetAttributes = match(event.payload)
      .with({ widgetType: WidgetType.Table }, () => ({
        content: DEFAULT_TABLE_CONTENT,
      }))
      .otherwise(() => ({}));
    const widgetRequest = {
      headerId: MuidUtils.randomMuid(),
      taskTemplateId,
      orderTree,
      config,
      ...('fieldType' in event.payload
        ? {
            type: WidgetType.FormField,
            key: FormFieldHelpers.generateUniqueKey(keys, FormFieldDefaultKey[event.payload.fieldType]),
            label: match(event.payload)
              .with({ fieldType: FieldType.Text }, () => 'Short Text')
              .with({ fieldType: FieldType.Textarea }, () => 'Long Text')
              .with({ fieldType: FieldType.Email }, () => 'Email')
              .with({ fieldType: FieldType.Url }, () => 'Website')
              .with({ fieldType: FieldType.Select }, () => 'Dropdown')
              .with({ fieldType: FieldType.MultiChoice }, () => 'Multi Choice')
              .with({ fieldType: FieldType.File }, () => 'File')
              .with({ fieldType: FieldType.Date }, () => 'Date')
              .with({ fieldType: FieldType.Number }, () => 'Number')
              .with({ fieldType: FieldType.Table }, () => 'Table')
              .with({ fieldType: FieldType.Hidden }, () => 'Hidden')
              .with({ fieldType: FieldType.Snippet }, () => 'Snippet')
              .with({ fieldType: FieldType.Members }, () => 'Members')
              .otherwise(() => undefined),
            ...event.payload,
          }
        : { type: event.payload.widgetType, ...widgetAttributes }),
    };
    return widgetRequest;
  }

  export function insertNewWidget({
    event,
    widgets,
    newWidget,
  }: {
    event: FormEditorPageMachineCreateWidgetEvent;
    widgets: Widget[];
    newWidget: Widget;
  }) {
    return match(event)
      .with(
        { after: P.not(P.nullish) },
        ({
          after: {
            header: { id },
          },
        }) => {
          const index = widgets.findIndex(w => w.header.id === id);
          return [...widgets.slice(0, index + 1), newWidget, ...widgets.slice(index + 1)];
        },
      )
      .with(
        { before: P.not(P.nullish) },
        ({
          before: {
            header: { id },
          },
        }) => {
          const index = widgets.findIndex(w => w.header.id === id);
          return [...widgets.slice(0, index), newWidget, ...widgets.slice(index)];
        },
      )
      .otherwise(() => [...widgets, newWidget]);
  }

  export function appendTaskTemplate(
    current: Array<TaskTemplate | OptimisticTaskTemplate>,
    newTaskTemplate: TaskTemplate | OptimisticTaskTemplate,
    at?: number,
  ) {
    if (!current || current.length === 0) return [newTaskTemplate];
    if (at === undefined) return [...current, newTaskTemplate];

    return current.flatMap((taskTemplate, index) => {
      if (at === index) return [taskTemplate, newTaskTemplate];

      return taskTemplate;
    });
  }
}
