import * as React from 'react';
import { RunWorkflowButton as WithPaywall } from 'components/run-workflow-button';
import { Button, Icon } from 'components/design/next';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { useInjector } from 'components/injection-provider';
import { Template } from '@process-street/subgrade/process';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { ButtonProps } from 'react-day-picker';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { isT5KPlan } from 'services/plans';

export const RunWorkflowButton: React.FC<
  React.PropsWithChildren<{
    isDisabled?: boolean;
    templateId?: Template['id'];
    onClick?: React.MouseEventHandler;
    children?: React.ReactElement<ButtonProps>;
  }>
> = ({ isDisabled, children, templateId: overriddenTemplateId, onClick }) => {
  const { $state } = useInjector('$state');
  const plan = useSelector(SessionSelector.getCurrentPlan);

  const {
    params: { id: templateId },
  } = $state;
  const actualTemplateId = overriddenTemplateId || templateId;
  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(actualTemplateId, {
    enabled: Boolean(actualTemplateId),
  });
  const isFree = useIsCurrentUserFreeMember();
  const isFreeT5K = isFree && isT5KPlan(plan?.id ?? '');
  // show to free members of non T5K plans so they get the paywall
  // if is free member from T5K plan, we only show if has permissionMap.checklistCreate
  const isVisible = !!permissionsQuery.data?.permissionMap.checklistCreate || (isFreeT5K ? false : isFree);

  const handleClick = (evt: React.MouseEvent) => {
    onClick?.(evt);
    $state.go(
      $state.current,
      {
        ...$state.params,
        [AppModalQueryParam.Modal]: AppModalName.RunChecklist,
        [AppModalQueryParam.ModalTemplateId]: actualTemplateId,
      },
      { inherit: false },
    );
  };

  const btn = React.useMemo(
    () =>
      React.isValidElement(children) ? (
        React.cloneElement(children, {
          isDisabled,
          onClick: (evt: React.MouseEvent) => {
            handleClick(evt);
            children.props?.onClick?.(evt);
          },
        })
      ) : (
        <Button
          w="full"
          isDisabled={isDisabled}
          variant="secondary"
          leftIcon={<Icon icon="play" variant="far" size="4" />}
          iconSpacing="2"
          onClick={handleClick}
        >
          Run Workflow
        </Button>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
    [children, isDisabled, onClick, actualTemplateId],
  );

  return <WithPaywall isVisible={isVisible}>{btn}</WithPaywall>;
};
