import { IRowNode } from '@ag-grid-community/core';
import { combine } from 'zustand/middleware';
import create from 'zustand';
import { RowValues } from 'components/dashboard/models/grid';

type ChecklistScreenState = {
  selectedNodes: IRowNode<RowValues>[];
};

const INITIAL_STATE: ChecklistScreenState = {
  selectedNodes: [],
};

export const useChecklistScreenSlice = create(
  combine(INITIAL_STATE, set => {
    const setSelectedNodes = (nodes: IRowNode<RowValues>[]) =>
      set({
        selectedNodes: nodes,
      });

    return {
      setSelectedNodes,
    };
  }),
);
