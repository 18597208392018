import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { SavedViewFolder } from 'components/dashboard/models/saved-views';
import { Nullable } from '@udecode/plate-core';

export namespace DeleteSavedViewFolderMutation {
  export type Params = {
    organizationId: Muid;
    id: Muid;
  };

  export type Response = Nullable<SavedViewFolder>;

  export const key = ['delete', 'saved-view-folders'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .delete<Response>(`/1/organizations/${params.organizationId}/checklist-grid/saved-view-folders/${params.id}`)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
