import * as React from 'react';
import { AxisLeft } from '@visx/axis';
import { SharedAxisProps, AxisScale } from '@visx/axis/lib/types';
import { useToken } from 'components/design/next';

export const BarStackHorizontalAxisLeft = <Scale extends AxisScale>(props: SharedAxisProps<Scale>) => {
  const [gray500] = useToken('colors', ['gray.500']);
  const fontSizeXs = useToken('fontSizes', 'xs');

  return (
    <AxisLeft
      hideAxisLine
      hideTicks
      stroke={gray500}
      tickStroke={gray500}
      tickLabelProps={() => ({
        fill: gray500,
        fontSize: fontSizeXs,
        textAnchor: 'end',
        dy: '0.33em',
      })}
      {...props}
    />
  );
};
