import { AxiosError } from 'axios';
import { FormikErrors, FormikTouched } from 'formik';
import { Option } from 'space-monad';
import { match, P } from 'ts-pattern';

export const getError = <T>(
  fieldName: keyof T,
  submitCount: number,
  errors: FormikErrors<T>,
  touched: FormikTouched<T>,
) => {
  const error = errors[fieldName];
  const isTouched = touched[fieldName];

  return error && (isTouched || submitCount > 0) ? error : undefined;
};

export type YupTestFunction = (value: string | null | undefined) => boolean;

export const createTestFunction =
  (predicate: (value: string) => boolean, fallback: boolean = false): YupTestFunction =>
  value =>
    Option(value).map(predicate).getOrElse(fallback);

export const hasError = <T>(
  fieldName: keyof T,
  submitCount: number,
  errors: FormikErrors<T>,
  touched: FormikTouched<T>,
) => Boolean(getError(fieldName, submitCount, errors, touched));

export const getSignUpErrorMessage = <T>(error: AxiosError<T> | null) =>
  match(error?.response?.status)
    .with(400, () => 'Unfortunately, the data you have informed us is invalid. Please double-check it and try again.')
    .with(P.number, () => 'An internal error occurred, please try again later.')
    .otherwise(() => undefined);
