// TODO see about reusing pages
import { AutoformatPlugin, AutoformatRule } from '@udecode/plate-autoformat';
import {
  DOMHandlers,
  createPlateEditor,
  CreatePlateEditorOptions,
  createPluginFactory,
  createPlugins,
  createTEditor,
  Decorate,
  DecorateEntry,
  DOMHandler,
  EDescendant,
  EElement,
  EElementEntry,
  EElementOrText,
  EMarks,
  ENode,
  ENodeEntry,
  EText,
  ETextEntry,
  getTEditor,
  InjectComponent,
  InjectProps,
  KeyboardHandler,
  NoInfer,
  OnChange,
  OverrideByKey,
  PlateEditor,
  PlateId,
  PlatePlugin,
  PlatePluginComponent,
  PlatePluginInsertData,
  PlatePluginProps,
  PlateProps,
  PluginOptions,
  SerializeHtml,
  TNodeEntry,
  TReactEditor,
  useEditorRef,
  useEditorState,
  usePlateActions,
  usePlateEditorRef,
  usePlateEditorState,
  usePlateSelectors,
  usePlateStates,
  WithOverride,
  TInsertNodeOperation,
  TInsertTextOperation,
  TMergeNodeOperation,
  TMoveNodeOperation,
  TRemoveNodeOperation,
  TRemoveTextOperation,
  TSetNodeOperation,
  TSplitNodeOperation,
  TOperation,
  PlateRenderLeafProps,
} from '@udecode/plate-common';
import { Plate, TEditableProps, TRenderElementProps } from '@udecode/plate-core';
import {
  PagesBlockElement,
  PagesFragmentElement,
  PagesH1Element,
  PagesH2Element,
  PagesH3Element,
  PagesInlineChildren,
  PagesInlineDescendant,
  PagesInlineElement,
  PagesLinkElement,
  PagesParagraphElement,
  PagesRichText,
  PagesRichTextProperty,
  PagesSoftBreakElement,
} from 'pages/pages/_id/edit/page/pages-plate-types';

/**
 * Block props
 */

export type SendRichEmailBlockElement = PagesBlockElement;

export type SendRichEmailSoftBreakElement = PagesSoftBreakElement;

export type SendRichEmailFragmentElement = PagesFragmentElement;

export type SendRichEmailLinkElement = PagesLinkElement;

export interface SendRichEmailRichText extends PagesRichText {
  decoration?: 'mergeTag';
}
export type SendRichEmailRichTextProperty = PagesRichTextProperty;

/**
 * Inline Elements
 */

export type SendRichEmailInlineElement = PagesInlineElement;
export type SendRichEmailInlineDescendant = PagesInlineDescendant;
export type SendRichEmailInlineChildren = PagesInlineChildren;

/**
 * Blocks
 */

export type SendRichEmailParagraphElement = PagesParagraphElement;

export type SendRichEmailH1Element = PagesH1Element;
export type SendRichEmailH2Element = PagesH2Element;
export type SendRichEmailH3Element = PagesH3Element;

export type SendRichEmailBlock = Exclude<SendRichEmailElement, SendRichEmailInlineElement>;
export type SendRichEmailBlockEntry = TNodeEntry<SendRichEmailBlock>;

export type SendRichEmailRootBlock =
  | SendRichEmailParagraphElement
  | SendRichEmailH1Element
  | SendRichEmailH2Element
  | SendRichEmailH3Element
  | SendRichEmailFragmentElement
  | SendRichEmailLinkElement;

export type SendRichEmailValue = SendRichEmailRootBlock[];

/**
 * Editor types
 */

export type SendRichEmailEditor = PlateEditor<SendRichEmailValue>;
export type SendRichEmailReactEditor = TReactEditor<SendRichEmailValue>;
export type SendRichEmailNode = ENode<SendRichEmailValue>;
export type SendRichEmailNodeEntry = ENodeEntry<SendRichEmailValue>;
export type SendRichEmailElement = EElement<SendRichEmailValue>;
export type SendRichEmailElementEntry = EElementEntry<SendRichEmailValue>;
export type SendRichEmailText = EText<SendRichEmailValue>;
export type SendRichEmailTextEntry = ETextEntry<SendRichEmailValue>;
export type SendRichEmailElementOrText = EElementOrText<SendRichEmailValue>;
export type SendRichEmailDescendant = EDescendant<SendRichEmailValue>;
export type SendRichEmailMarks = EMarks<SendRichEmailValue>;
export type SendRichEmailMark = keyof SendRichEmailMarks;
export type SendRichEmailElementType = SendRichEmailRootBlock['type'];

/**
 * Plate types
 */

export type SendRichEmailDecorate<P = PluginOptions> = Decorate<P, SendRichEmailValue, SendRichEmailEditor>;
export type SendRichEmailDecorateEntry = DecorateEntry<SendRichEmailValue>;
export type SendRichEmailDOMHandler<P = PluginOptions> = DOMHandler<P, SendRichEmailValue, SendRichEmailEditor>;
export type SendRichEmailInjectComponent = InjectComponent<SendRichEmailValue>;
export type SendRichEmailInjectProps = InjectProps<SendRichEmailValue>;
export type SendRichEmailKeyboardHandler<P = PluginOptions> = KeyboardHandler<
  P,
  SendRichEmailValue,
  SendRichEmailEditor
>;
export type SendRichEmailOnChange<P = PluginOptions> = OnChange<P, SendRichEmailValue, SendRichEmailEditor>;
export type SendRichEmailOverrideByKey = OverrideByKey<SendRichEmailValue, SendRichEmailEditor>;
export type SendRichEmailPlatePlugin<P = PluginOptions> = PlatePlugin<P, SendRichEmailValue, SendRichEmailEditor>;
export type SendRichEmailPlatePluginInsertData = PlatePluginInsertData<SendRichEmailValue>;
export type SendRichEmailPlatePluginProps = PlatePluginProps<SendRichEmailValue>;
export type SendRichEmailPlateProps = PlateProps<SendRichEmailValue, SendRichEmailEditor>;
export type SendRichEmailSerializeHtml = SerializeHtml<SendRichEmailValue>;
export type SendRichEmailWithOverride<P = PluginOptions> = WithOverride<P, SendRichEmailValue, SendRichEmailEditor>;
export type SendRichEmailInsertNodeOperation = TInsertNodeOperation<SendRichEmailDescendant>;
export type SendRichEmailInsertTextOperation = TInsertTextOperation;
export type SendRichEmailMergeNodeOperation = TMergeNodeOperation;
export type SendRichEmailRemoveNodeOperation = TRemoveNodeOperation<SendRichEmailDescendant>;
export type SendRichEmailRemoveTextOperation = TRemoveTextOperation;
export type SendRichEmailSetNodeOperation = TSetNodeOperation;
export type SendRichEmailSplitNodeOperation = TSplitNodeOperation;
export type SendRichEmailMoveNodeOperation = TMoveNodeOperation;
export type SendRichEmailOperation = TOperation;
export type SendRichEmailDOMHandlers<P = PluginOptions> = DOMHandlers<P, SendRichEmailValue, SendRichEmailEditor>;

/**
 * Plate store, Slate context
 */

export const getSendRichEmailEditor = (editor: SendRichEmailEditor) =>
  getTEditor<SendRichEmailValue, SendRichEmailEditor>(editor);
export const useSendRichEmailEditorRef = () => useEditorRef<SendRichEmailValue, SendRichEmailEditor>();
export const useSendRichEmailEditorState = () => useEditorState<SendRichEmailValue, SendRichEmailEditor>();
export const useSendRichEmailPlateEditorRef = (id?: PlateId) =>
  usePlateEditorRef<SendRichEmailValue, SendRichEmailEditor>(id);
export const useSendRichEmailPlateEditorState = (id?: PlateId) =>
  usePlateEditorState<SendRichEmailValue, SendRichEmailEditor>(id);
export const useSendRichEmailPlateSelectors = (id?: PlateId) =>
  usePlateSelectors<SendRichEmailValue, SendRichEmailEditor>(id);
export const useSendRichEmailPlateActions = (id?: PlateId) =>
  usePlateActions<SendRichEmailValue, SendRichEmailEditor>(id);
export const useSendRichEmailPlateStates = (id?: PlateId) =>
  usePlateStates<SendRichEmailValue, SendRichEmailEditor>(id);

/**
 * Utils
 */
export const createSendRichEmailEditor = () => createTEditor() as SendRichEmailEditor;
export const createSendRichEmailPlateEditor = (
  options: CreatePlateEditorOptions<SendRichEmailValue, SendRichEmailEditor> = {},
) => createPlateEditor<SendRichEmailValue, SendRichEmailEditor>(options);
export const createSendRichEmailPluginFactory = <P = PluginOptions>(
  defaultPlugin: PlatePlugin<NoInfer<P>, SendRichEmailValue, SendRichEmailEditor>,
) => createPluginFactory(defaultPlugin);
export const createSendRichEmailPlugins = (
  plugins: SendRichEmailPlatePlugin[],
  options?: {
    components?: Record<string, PlatePluginComponent>;
    overrideByKey?: SendRichEmailOverrideByKey;
  },
) => createPlugins<SendRichEmailValue, SendRichEmailEditor>(plugins, options);

export type SendRichEmailAutoformatRule = AutoformatRule<SendRichEmailValue, SendRichEmailEditor>;
export type SendRichEmailAutoformatPlugin = AutoformatPlugin<SendRichEmailValue, SendRichEmailEditor>;

export type SendRichEmailRenderElementProps<Element extends SendRichEmailElement = SendRichEmailElement> =
  TRenderElementProps<SendRichEmailValue, Element>;
export const SendRichEmailPlate = Plate<SendRichEmailValue, SendRichEmailEditor>;
export type SendRichEmailRenderLeafProps = PlateRenderLeafProps<SendRichEmailValue, SendRichEmailRichText>;
export type SendRichEmailEditableProps = TEditableProps<SendRichEmailValue>;
