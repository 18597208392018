import {} from '@process-street/subgrade/core';
import { ChecklistRevision } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace RefreshSandboxMutation {
  export type Params = {
    checklistRevisionId: ChecklistRevision['id'];
  };

  export type Response = {};

  export const key = ['post', 'checklist-revisions', 'refresh-sandbox'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/checklist-revisions/${params.checklistRevisionId}/refresh-sandbox`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
