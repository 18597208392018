import {
  AutomationAppName,
  canUseTrayAutomationApp,
  isTrayAutomationAppName,
  trayAutomationAppIsPremium,
} from '@process-street/subgrade/automation';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { usePlanTrack } from 'services/use-plan-track';
import { match } from 'ts-pattern';

export function useAutomationAppQuery(automationApp: AutomationAppName) {
  const [status, setStatus] = React.useState<'loading' | 'ready'>('loading');

  const plan = useSelector(SessionSelector.getCurrentPlan);
  const track = usePlanTrack();

  React.useEffect(() => {
    if (plan?.level && track && status === 'loading') {
      setStatus('ready');
    }
  }, [plan?.level, status, track]);

  const { enabled, automationIsPremium } = match(automationApp)
    .when(isTrayAutomationAppName, automationApp => ({
      enabled:
        plan?.level && track && canUseTrayAutomationApp({ automationApp, planLevel: plan.level, planTrack: track }),
      automationIsPremium: track && trayAutomationAppIsPremium(automationApp, track),
    }))
    .otherwise(() => ({
      enabled: true,
      automationIsPremium: false,
    }));

  return {
    status,
    data: {
      enabled,
      automationIsPremium,
    },
  };
}
