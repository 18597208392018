import { toggleNodeType } from '@udecode/plate-core';
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading';
import { someNode } from '@udecode/slate';
import { Icon, MenuList, useDisclosure } from 'components/design/next';
import * as React from 'react';
import { PagesH1Element, PagesH2Element, PagesH3Element, usePagesEditorState } from '../../pages-plate-types';
import { useSelectionContext } from '../../selection-context';
import { ToolbarMenu, ToolbarMenuButton, ToolbarMenuItem } from '../balloon-toolbar/menu';
import { ToolbarButton, ToolbarTooltip } from '../common';
import { EditorHeading, EditorHeadingProps } from '../elements/heading';

type HeaderElement = PagesH1Element | PagesH2Element | PagesH3Element;

export const HeaderMenuItem: React.FC<React.PropsWithChildren<{ type: HeaderElement['type'] }>> = ({
  type,
  children,
}) => {
  const { lastSelection } = useSelectionContext();
  const editor = usePagesEditorState();
  const active = someNode(editor, { match: { type }, at: lastSelection ?? undefined });
  const variant = type.slice(1) as EditorHeadingProps['variant'];

  return (
    <ToolbarMenuItem
      {...(active ? { bgColor: 'brand.500', color: 'white' } : {})}
      onClick={e => {
        e.preventDefault();
        toggleNodeType(editor, { activeType: type });
      }}
    >
      <EditorHeading variant={variant}>{children}</EditorHeading>
    </ToolbarMenuItem>
  );
};

export const HeadersButton: React.FC<React.PropsWithChildren<unknown>> = React.memo(() => {
  const editor = usePagesEditorState();
  const headerActive = [ELEMENT_H1, ELEMENT_H2, ELEMENT_H3].some(t => someNode(editor, { match: { type: t } }));
  const disclosure = useDisclosure();

  return (
    <ToolbarMenu autoSelect={false} {...disclosure}>
      <ToolbarTooltip label="Header">
        <ToolbarMenuButton
          aria-label={headerActive ? 'Header text applied to selection' : 'Header text not applied to selection'}
          as={ToolbarButton}
          color={headerActive ? 'brand.300' : 'white'}
          rightIcon={<Icon icon="caret-down" size="3" variant="fas" />}
        >
          <Icon size="4" icon="heading" />
        </ToolbarMenuButton>
      </ToolbarTooltip>

      <MenuList>
        <HeaderMenuItem type={ELEMENT_H1}>Header 1</HeaderMenuItem>
        <HeaderMenuItem type={ELEMENT_H2}>Header 2</HeaderMenuItem>
        <HeaderMenuItem type={ELEMENT_H3}>Header 3</HeaderMenuItem>
      </MenuList>
    </ToolbarMenu>
  );
});
