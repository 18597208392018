import { Plan } from '@process-street/subgrade/billing';
import { Muid } from '@process-street/subgrade/core';

import { AxiosError } from 'axios';
import { QueryClient, QueryObserver, QueryObserverOptions, useQuery, UseQueryOptions, QueryKey } from 'react-query';
import { axiosService } from 'services/axios-service';

export type PlanQueryParams = { planId?: Muid };

export type PlanQueryResponse = Plan;

export const PlanQuery = {
  key: ['...'],
  getKey: (params: PlanQueryParams): QueryKey => [...PlanQuery.key, params],
  queryFn: ({ planId }: PlanQueryParams) =>
    axiosService
      .getAxios()
      .get<PlanQueryResponse>(`/1/plans/${planId}`)
      .then(res => res.data),
};

export const usePlanQuery = <Select = PlanQueryResponse>(
  params: PlanQueryParams,
  options: UseQueryOptions<PlanQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(PlanQuery.getKey(params), () => PlanQuery.queryFn(params), {
    ...options,
    enabled: Boolean(params.planId) && options.enabled !== false,
  });
};

export const makeGetPlanQueryObserver = ({
  queryClient,
  options,
  ...params
}: PlanQueryParams & {
  queryClient: QueryClient;
  options?: QueryObserverOptions<PlanQueryResponse, AxiosError>;
}) => {
  return new QueryObserver<PlanQueryResponse, AxiosError>(queryClient, {
    queryKey: PlanQuery.getKey(params),
    queryFn: () => PlanQuery.queryFn(params),
    enabled: Boolean(params.planId) && options?.enabled !== false,
    ...options,
  });
};
