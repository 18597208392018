import React from 'react';
import {
  NativeAutomation,
  NumberFormFieldValue,
  NumberFormFieldWidget,
  TEXTAREA_MAX_LENGTH,
  WidgetUtils,
  WidgetValidation,
} from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import { ChecklistFormFieldWidgetProps } from 'components/widgets/form-field/common/types';
import { InputProps } from 'components/design/next';
import { Option } from 'space-monad';
import { useMachine } from '@xstate/react';
import { validatedInputMachine } from 'utils/widget/validated-input-machine';
import { formFieldKeyUpHandler } from 'components/widgets/form-field/form-field-key-up-handler';
import { assign } from 'xstate';
import { useSyncedAndMaskedFormFieldValues } from 'components/widgets/form-field/common/use-synced-and-masked-form-field-values';
import { useAiTaskOutputWidgetStatus } from '../../hooks/use-ai-task-output-widget-status';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { useFeatureFlag } from 'features/feature-flags';

export interface Props
  extends Pick<
    ChecklistFormFieldWidgetProps<NumberFormFieldWidget, NumberFormFieldValue>,
    'widget' | 'editable' | 'formFieldValue' | 'onUpdateValue' | 'onInteractionEnd' | 'readOnly'
  > {}

const DEFAULT_PLACEHOLDER = 'Numbers will be typed here...';

export function useChecklistNumberFormField({
  widget,
  editable,
  formFieldValue,
  onUpdateValue,
  onInteractionEnd,
  readOnly,
}: Props): {
  inputProps: Partial<InputProps>;
  hasValidationErrors: boolean;
  errorMessages: string[];
  setValue: (value: string) => void;
  handleReset: () => void;
  aiAutomationStatus: NativeAutomation.EventStatus | undefined;
} {
  const isMergeTagImprovementsEnabled = useFeatureFlag('mergeTagImprovements');
  const { status: aiAutomationStatus } = useAiTaskOutputWidgetStatus(widget.id);
  const isReadOnly = !editable;
  const { constraints } = widget;

  const stringValue = Option(formFieldValue)
    .map(ffv => ffv.fieldValue.value)
    .map(String)
    .getOrElse('');
  const initiallyValid = WidgetValidation.getNumberFormFieldWidgetErrorTypes(constraints, stringValue).size === 0;
  const widgetDefaultValue = Option<string>(widget.config?.defaultValue)
    .map(defaultValue =>
      isMergeTagImprovementsEnabled
        ? MergeTagStringReplacementUtils.replaceUnknownTagsValues(defaultValue)
        : defaultValue,
    )
    .get();

  const [state, send] = useMachine(validatedInputMachine, {
    context: { value: stringValue, isValid: initiallyValid, shouldShowError: !initiallyValid },
    actions: {
      validate: assign({
        isValid: ({ value }, _event) => {
          const errors = WidgetValidation.getNumberFormFieldWidgetErrorTypes(constraints, value);
          return errors.size === 0;
        },
      }),
      onInteractionEnd: (ctx, _event) => {
        onInteractionEnd(widget, { value: ctx.value });
      },
      onReset: () => {
        onUpdateValue(widget, {
          value: WidgetUtils.hasVariables(widgetDefaultValue) ? '' : widgetDefaultValue,
          hasDefaultValue: true,
        });
      },
      onChange: (ctx, _event) => {
        onUpdateValue(widget, {
          value: ctx.value,
          hasDefaultValue: ctx.value === widgetDefaultValue,
        });
      },
    },
  });

  useSyncedAndMaskedFormFieldValues({ send, widget, formFieldValue });

  const errorTypes = state.context.shouldShowError
    ? WidgetValidation.getNumberFormFieldWidgetErrorTypes(constraints, state.context.value)
    : new Set();

  const errorMessages = [...errorTypes].map(errorType =>
    match(errorType)
      .with('min-digits', () => `Minimum ${widget.constraints?.minDigits} digits`)
      .with('max-digits', () => `Maximum ${widget.constraints?.maxDigits} digits`)
      .with('min-value', () => `Minimum ${widget.constraints?.minValue}`)
      .with('max-value', () => `Maximum ${widget.constraints?.maxValue}`)
      .with('decimal-places', () =>
        match(widget.constraints?.decimalPlaces)
          .with(undefined, () => 'Decimal places are not permitted')
          .with(1, () => '1 decimal place required')
          .otherwise(count => `${count} decimal places required`),
      )
      .with('no-negative', () => 'Negative numbers are not permitted')
      .with('is-number', () => 'Value must be a number')
      .otherwise(() => ''),
  );

  const setValue = (newValue: string) => {
    send({ type: 'CHANGE', value: newValue });
  };

  const placeholder = (widget.config.placeholder ?? '').replace(/^$/, DEFAULT_PLACEHOLDER);
  const hasValidationErrors = errorTypes.size > 0;

  const onBlur = () => {
    send({ type: 'BLUR' });
  };

  const onFocus = () => {
    send({ type: 'FOCUS' });
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setValue(e.target.value);
  };

  const handleReset = () => {
    send({ type: 'RESET', value: widgetDefaultValue ?? '' });
  };

  return {
    hasValidationErrors,
    errorMessages,
    setValue,
    handleReset,
    inputProps: {
      isReadOnly,
      value: state.context.value,
      onChange,
      onBlur,
      maxLength: TEXTAREA_MAX_LENGTH,
      onFocus,
      placeholder,
      overflowY: 'hidden',
      type: 'text',
      inputMode: 'numeric',
      onKeyUp: formFieldKeyUpHandler,
      isDisabled: !editable || aiAutomationStatus === 'AutomationRunning' || readOnly,
    },
    aiAutomationStatus,
  };
}
