import * as React from 'react';
import { Box, Grid, Icon, Skeleton, SkeletonProps, StackProps, VStack } from 'components/design/next';
import random from 'lodash/random';
import range from 'lodash/range';
import { CreateDataSetButton } from '../../create-data-set-button';

const TableItem: React.FC<React.PropsWithChildren<SkeletonProps>> = props => (
  <Skeleton
    h="4"
    bgColor="gray.50"
    borderRadius="8px"
    w="100%"
    maxW="100%"
    startColor="gray.50"
    endColor="gray.100"
    {...props}
  />
);

const TableColumn: React.FC<React.PropsWithChildren<StackProps>> = props => (
  <VStack
    alignItems="flex-start"
    spacing="6"
    border="0"
    pr="4"
    borderRightWidth="1px"
    borderStyle="solid"
    borderColor="gray.100"
    _notFirst={{ pl: '8' }}
    {...props}
  />
);

const ROWS_LENGTH = 8;
const COLUMNS_LENGTH = 5;

const getRandomWidths = () => {
  return range(0, ROWS_LENGTH).map(() => random(4, 10, false) * 10);
};

const columnsWidths = range(0, COLUMNS_LENGTH).map(() => getRandomWidths());

export type DataSetPageSkeletonProps = {
  isLoading?: boolean;
};

export const DataSetPageSkeleton: React.FC<React.PropsWithChildren<DataSetPageSkeletonProps>> = ({
  isLoading = false,
}) => {
  return (
    <Box px="10" mt="8" width="full">
      <Box h="4" bgColor="gray.100" borderRadius="8px" width="320px" mb="9" />
      <Box h="6" bgColor="gray.100" borderRadius="8px" width="full" mb="7" />

      <Box display="flex" alignItems="center" justifyContent="center" position="relative">
        <Grid w="full" templateColumns={{ sm: '2fr 1fr 1fr', lg: '2fr 1fr 2fr 2fr 1fr' }}>
          {columnsWidths.map((widths, index) => (
            <TableColumn key={index}>
              {widths.map((w, i) => (
                <TableItem
                  key={i}
                  w={`${w}%`}
                  isLoaded={!isLoading}
                  display={{
                    sm: index > 2 ? 'none' : 'block',
                    lg: 'block',
                  }}
                />
              ))}
            </TableColumn>
          ))}
        </Grid>
        {!isLoading && (
          <CreateDataSetButton
            variant="solid"
            colorScheme="brand"
            h="12"
            bgColor={undefined}
            color={undefined}
            leftIcon={<Icon size="5" color="inherit" icon="plus" />}
            _active={undefined}
            _hover={undefined}
            fontWeight="700"
            position="absolute"
          >
            Create your first Data Set
          </CreateDataSetButton>
        )}
      </Box>
    </Box>
  );
};
