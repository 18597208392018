import { DownloadLink } from 'components/common/preview/DownloadLink';
import React, { useCallback, useMemo, useState } from 'react';
import Img from 'react-image';
import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css';

import { Spinner } from '../../spinner/Spinner';

import commonStyles from '../Preview.module.scss';
import styles from './ImagePreview.module.scss';

export interface ImagePreviewProps {
  src: string;
  alt?: string;
  caption?: string;
  thumbnail?: boolean;
  download?: boolean;
}

export const ImagePreview: React.FunctionComponent<React.PropsWithChildren<ImagePreviewProps>> = ({
  src,
  alt,
  caption,
  thumbnail = false,
  download = false,
}) => {
  const trimmedCaption = useMemo(() => (caption ? caption!.trim() : ''), [caption]);

  const [modalPreviewOpen, setModalPreviewOpen] = useState<boolean>(false);

  const handleOpenModal = useCallback(() => setModalPreviewOpen(true), [setModalPreviewOpen]);
  const handleCloseModal = useCallback(() => setModalPreviewOpen(false), [setModalPreviewOpen]);

  return (
    <>
      <figure className={commonStyles.figure}>
        <Img
          src={src}
          alt={alt}
          decode={false}
          className={thumbnail ? styles.thumbnail : styles.fullsize}
          onClick={handleOpenModal}
          loader={<Spinner />}
        />
        {download && <DownloadLink url={src} />}
        {trimmedCaption && <figcaption className={commonStyles.caption}>{trimmedCaption}</figcaption>}
      </figure>
      {modalPreviewOpen && (
        <Lightbox
          mainSrc={src}
          imageCaption={trimmedCaption || alt}
          enableZoom={true}
          closeLabel={'Close'}
          reactModalStyle={{ overlay: { zIndex: 1055 } }}
          onCloseRequest={handleCloseModal}
        />
      )}
    </>
  );
};
