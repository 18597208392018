import * as React from 'react';
import { Icon, MenuButton, MenuButtonProps, ComponentWithAs } from 'components/design/next';
import { ToolbarButton } from '../../common';
import { useToolbarMenuButtonOnMouseDown } from './use-toolbar-menu-button-on-mouse-down';

// Easier to type this as a separate component and export the memoized one.
const _ToolbarMenuButton: ComponentWithAs<'button', MenuButtonProps> = ({ onMouseDown, ...props }) => {
  const onMouseDownOverride = useToolbarMenuButtonOnMouseDown(onMouseDown);

  return (
    <MenuButton
      onMouseDown={onMouseDownOverride}
      as={ToolbarButton}
      rightIcon={<Icon icon="caret-down" size="3" variant="fas" />}
      {...props}
    />
  );
};

export const ToolbarMenuButton = React.memo(_ToolbarMenuButton);
