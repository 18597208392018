import { IModalInstanceService } from 'services/message-box.interface';
import './approval-modal.component.scss';

export class ApprovalSubjectTaskModalController {
  static $inject = ['$uibModalInstance'];
  constructor(public $uibModalInstance: IModalInstanceService) {}

  public close(comment?: Record<string, unknown>) {
    this.$uibModalInstance.close(comment);
  }
}
