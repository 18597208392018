import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { OrganizationNotificationConfig } from '@process-street/subgrade/core/organization-notifications/organization-notification-config';

export namespace GetOrganizationNotificationConfigQuery {
  export type Params = { organizationId: Muid };

  export type Response = OrganizationNotificationConfig;

  export const key = ['organization', 'notification', 'config'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/organizations/${params.organizationId}/notification-config`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.organizationId) && options.enabled !== false,
    });
  };
}
