import { DueDateRuleSourceType } from '@process-street/subgrade/process';
import { SessionSelector } from 'reducers/session/session.selectors';
import { canAccess, Feature } from 'services/features/features';
import { connectController } from 'reducers/util';
import { StringUtils } from '@process-street/subgrade/util';
import templateUrl from './drop-down-selector.component.html';
import './drop-down-selector.scss';
import { trace } from 'components/trace';
import { DynamicDueDateLabelService } from 'components/dynamic-due-dates/services/dynamic-due-date-label.service';

const ChecklistStartDateItem = {
  id: 'ChecklistStartDate',
  type: 'ChecklistStartDate',
  label: 'workflow run start date',
};

export const PopularAfterItemsFirstTask = [ChecklistStartDateItem];

export const PopularAfterItems = [
  ChecklistStartDateItem,
  {
    id: 'PreviousTaskCompletedDate',
    type: 'PreviousTaskCompletedDate',
    label: 'previous task is checked',
  },
];

export const PopularBeforeItems = [
  {
    id: 'ChecklistDueDate',
    type: 'ChecklistDueDate',
    label: 'workflow run due date',
  },
];

export const DropDownSelectorComponent = {
  bindings: {
    dateOption: '<', // before/after
    onDateSelected: '&', // selection callback
    taskRule: '<',
    selectedTaskTemplate: '<',
    selectedTaskTemplates: '<',
    templateRevision: '<',
    taskTemplates: '<',
    dateWidgets: '<',
    widgetsLoaded: '<',
  },
  templateUrl,
  controller: class {
    constructor($ngRedux, $scope, $uibModal, OrderTreeService, TaskTemplateService) {
      'ngInject';

      this.logger = trace({ name: 'DropDownSelectorComponent' });
      this.$scope = $scope;
      this.$uibModal = $uibModal;

      this.DynamicDueDateLabelService = DynamicDueDateLabelService;
      this.OrderTreeService = OrderTreeService;
      this.TaskTemplateService = TaskTemplateService;

      this.formFieldSearch = '';
      this.formFieldSelectorOpen = false;
      this.selectedFormFieldWidget = null;
      this.selectedFormFieldType = null;

      // search
      this.dateSearch = [];
      this.popularSearch = [];
      this.taskSearch = [];

      $scope.$watch(
        () => this.formFieldSearch,
        newValue => this.searchChanged(newValue),
      );

      const mapStateToThis = () => state => {
        const selectedOrganization = SessionSelector.getSelectedOrganization(state);
        if (!selectedOrganization) {
          return {};
        }

        const planId = selectedOrganization.subscription.plan.id;
        const dynamicDueDatesFeatureIsAvailable = canAccess(Feature.DYNAMIC_DUE_DATES, planId);

        return {
          dynamicDueDatesFeatureIsAvailable,
        };
      };

      connectController($ngRedux, mapStateToThis, null)(this);
    }

    $onChanges(changes) {
      if (changes.dateOption && changes.dateOption.currentValue) {
        this.clearSelection();
        this.initPopularItems();
        this.updatePopularSearch();
        this.updateTaskSearch();
      }

      if (changes.selectedTaskTemplate && changes.selectedTaskTemplate.currentValue) {
        this.clearSelection();
        this.initPopularItems();
        this.updateTaskSearch();
        this.onRuleSet();
      }

      if (changes.selectedTaskTemplates && changes.selectedTaskTemplates.currentValue) {
        this.updateTaskSearch();
      }

      if (changes.taskRule) {
        this.onRuleSet();
      }

      if (changes.taskTemplates && changes.taskTemplates.currentValue) {
        this.updateTaskSearch();
        this.updateSelectedLabel();
      }

      if (changes.dateWidgets && changes.dateWidgets.currentValue) {
        this.updateDateSearch();
        this.validateDateWidgetSelection();
        this.updateSelectedLabel();
      }
    }

    onRuleSet() {
      const rule = this.taskRule;
      if (rule) {
        this.selectedFormFieldType = rule.sourceType;
        this.selectedFormFieldWidget = {
          id: rule.formFieldWidgetGroup && rule.formFieldWidgetGroup.id,
          type: rule.sourceType,
        };
        this.updateSelectedLabel();
      } else {
        this.selectedFormFieldWidget = null;
        this.selectedFormFieldType = null;
      }
    }

    initPopularItems() {
      const { dateOption, selectedTaskTemplate, taskTemplates } = this;

      if (!dateOption) {
        this.popularItems = PopularAfterItems;
      } else if (dateOption === 'before') {
        this.popularItems = PopularBeforeItems;
      } else {
        // after
        const prevTask =
          selectedTaskTemplate &&
          taskTemplates &&
          taskTemplates.find(tt => {
            const taskIsHeading = this.TaskTemplateService.isHeading(tt);
            const taskIsBefore = this.OrderTreeService.compare(tt.orderTree, selectedTaskTemplate.orderTree) < 0;

            return taskIsBefore && !taskIsHeading;
          });

        this.popularItems = prevTask ? PopularAfterItems : PopularAfterItemsFirstTask;
      }

      this.updatePopularSearch();
    }

    updateSelectedLabel() {
      if (this.taskRule && this.selectedFormFieldWidget) {
        this.selectedFormFieldWidget.label = this.DynamicDueDateLabelService.getLabelByRule(
          this.taskRule,
          this.taskTemplates,
          this.dateWidgets,
        );
      }
    }

    clearSelection() {
      this.selectedFormFieldWidget = null;
      this.selectedFormFieldType = null;
    }

    searchChanged(newValue) {
      this.logger.info('formFieldSearch', newValue);

      this.updatePopularSearch();
      this.updateDateSearch();
      this.updateTaskSearch();
    }

    validateDateWidgetSelection() {
      if (this.selectedFormFieldType === 'FormFieldValue' && this.widgetsLoaded) {
        const found = this.dateWidgets.find(widget => widget.header.group.id === this.selectedFormFieldWidget.id);

        if (!found) {
          this.logger.warn('selected date widget not found, label: %s', this.selectedFormFieldWidget.label);
          this.clearSelection();
        }
      }
    }

    widgetFilter(widget) {
      return StringUtils.containsIgnoreCase(widget.label, this.formFieldSearch);
    }

    taskFilter(task) {
      return StringUtils.containsIgnoreCase(task.name, this.formFieldSearch);
    }

    updatePopularSearch() {
      this.popularSearch = this.popularItems.filter(w => this.widgetFilter(w));
    }

    updateDateSearch() {
      this.dateSearch = this.dateWidgets.filter(w => this.widgetFilter(w));
    }

    templateFilter(taskTemplate, currentOrderTree) {
      const isHeading = this.TaskTemplateService.isHeading(taskTemplate);
      const isBefore = this.OrderTreeService.compare(taskTemplate.orderTree, currentOrderTree) < 0;
      return !isHeading && isBefore;
    }

    _getFirstOrderTree() {
      const { selectedTaskTemplate, selectedTaskTemplates } = this;

      if (selectedTaskTemplate) {
        return selectedTaskTemplate.orderTree;
      } else if (selectedTaskTemplates) {
        let firstOrderTree = null;

        selectedTaskTemplates.forEach(taskTemplate => {
          const { orderTree } = taskTemplate;

          if (!firstOrderTree || this.OrderTreeService.compare(orderTree, firstOrderTree) < 0) {
            firstOrderTree = orderTree;
          }
        });

        return firstOrderTree;
      } else {
        return '';
      }
    }

    updateTaskSearch() {
      if (this.dateOption === 'after') {
        const firstOrderTree = this._getFirstOrderTree();
        const templates = this.taskTemplates.filter(t => this.templateFilter(t, firstOrderTree));
        this.taskSearch = templates.filter(t => this.taskFilter(t));
      } else {
        this.taskSearch = [];
      }
    }

    getFormFieldIconClass(type) {
      const dateTypes = [
        DueDateRuleSourceType.FormFieldValue,
        DueDateRuleSourceType.ChecklistStartDate,
        DueDateRuleSourceType.ChecklistDueDate,
      ];
      const taskTypes = [
        DueDateRuleSourceType.TaskCompletedDate,
        DueDateRuleSourceType.TaskDueDate,
        DueDateRuleSourceType.PreviousTaskCompletedDate,
      ];

      return {
        'far fa-calendar-alt fa-fw': dateTypes.includes(type),
        'far fa-check-square fa-fw': taskTypes.includes(type),
      };
    }

    resolveWidgetLabel(widget) {
      return widget.label || widget.name || widget.key;
    }

    setSelectedFormFieldWidget(widget, type) {
      this.logger.info(`setSelectedFormFieldWidget ${type} ${widget}`);

      let { id } = widget;
      if (widget.header && widget.header.group) {
        ({ id } = widget.header.group);
      } else if (widget.group) {
        ({ id } = widget.group);
      }

      this.selectedFormFieldWidget = Object.assign({}, widget, { id });
      this.selectedFormFieldType = type;
      this.formFieldSelectorOpen = false;
      this.onDateSelected({
        rule: {
          sourceType: type,
          widget,
        },
      });
    }

    openUpgradeModal() {
      this.$uibModal.open({
        component: 'ps-dynamic-due-date-upgrade-modal',
        animation: true,
        backdrop: 'static',
        windowClass: 'due-upgrade-modal',
        resolve: {},
      });
    }

    clearSearch() {
      this.formFieldSearch = '';
    }
  },
};
