import { Template } from '@process-street/subgrade/process';
import { Icon, MenuItem } from 'components/design/next';
import * as React from 'react';
import { useShareFormButton } from '../hooks/use-share-form-button';

export type ShareMenuItemProps = {
  template: Template;
};

export const ShareMenuItem = ({ template }: ShareMenuItemProps) => {
  const { onShowShareTemplate } = useShareFormButton(template);

  return (
    <MenuItem icon={<Icon icon="share" variant="far" size="4" color="gray.500" />} onClick={onShowShareTemplate}>
      Share
    </MenuItem>
  );
};
