import {} from '@process-street/subgrade/core';
import { TaskPermissionRule } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateTaskTemplateRulesMutation {
  export type Params = TaskPermissionRule;

  export type Response = TaskPermissionRule;

  export const key = ['template-revision', 'rules'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/template-revisions/${params.templateRevision.id}/task-permission-rules`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
