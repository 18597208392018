import { queryClient } from 'components/react-root';
import { GetAllTasksByChecklistRevisionIdQuery } from 'features/task/query-builder';

export const TaskApi = function (Tasks) {
  'ngInject';

  const getAllByChecklistRevisionId = checklistRevisionId =>
    queryClient.fetchQuery(GetAllTasksByChecklistRevisionIdQuery.getKey({ checklistRevisionId }), () =>
      GetAllTasksByChecklistRevisionIdQuery.queryFn({ checklistRevisionId }),
    );

  const countAllByOrganizationId = id => Tasks.countAllByOrganizationId({ id }).$promise;

  const get = id => Tasks.get({ id }).$promise;

  const updateDueDate = (id, dueDate, dueDateOverridden) =>
    Tasks.updateDueDate({ id, dueDate, dueDateOverridden }).$promise;

  const updateStatus = (id, status) => Tasks.updateStatus({ id, status }).$promise;

  const assignToTask = (id, email) => Tasks.assignToTask({ id, email }).$promise;

  const unassignFromTask = (id, organizationMembershipId) =>
    Tasks.unassignFromTask({ id, organizationMembershipId }).$promise;

  const getAssignment = (id, organizationMembershipId) =>
    Tasks.getAssignment({ id, organizationMembershipId }).$promise;

  return {
    get,
    getAllByChecklistRevisionId,
    countAllByOrganizationId,
    updateDueDate,
    updateStatus,
    assignToTask,
    unassignFromTask,
    getAssignment,
  };
};
