import * as React from 'react';
import { Task } from 'pages/responses/_id/components/task';
import { Box, Center, VStack } from 'components/design/next';
import { TaskFooter } from '../task-footer/task-footer';
import { TaskActor } from 'app/pages/responses/_id/components/task/task-machine';
import { useSelector } from '@xstate/react';
import { TaskMachineSelectors } from 'app/pages/responses/_id/components/task/task-machine-selectors';
import { match } from 'ts-pattern';
import { TaskTemplateTaskType } from '@process-street/subgrade/process';
import { ApprovalTask } from 'pages/responses/_id/components/approval-task';
import { FormResponseActor } from 'pages/responses/_id/components/form-response-body/form-response-machine';
import { FormResponsePageMachineHooks } from 'pages/responses/_id/form-response-page-hooks';
import { TaskApprovalInfo } from 'pages/checklists/_id/components/task-section/task-approval-info';
import { ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { TaskHeader } from '../task-header';

type TaskSectionProps = {
  currentTaskActor: TaskActor;
  formResponseActor: FormResponseActor;
};

export const TaskSection = ({ currentTaskActor, formResponseActor }: TaskSectionProps) => {
  const task = useSelector(currentTaskActor, TaskMachineSelectors.getTask);
  const subjectIdToApprovalMap = FormResponsePageMachineHooks.useSubjectIdToApprovalMap();
  const approval = subjectIdToApprovalMap.get(task.id);
  const isApproved = approval?.status === ApprovalStatus.Approved;

  return (
    <Center as="main" w="full" pb="8">
      <Box
        bgColor="white"
        borderColor="gray.200"
        borderStyle="solid"
        borderWidth="1px"
        borderRadius={{ base: '0', lg: 'lg' }}
        py={9}
        px={{ base: '8', lg: '9' }}
        w="full"
        maxW={{ base: 'full', lg: '788px' }}
      >
        <VStack spacing="6" w="full" h="auto" mt={6}>
          <TaskHeader currentTaskActor={currentTaskActor} />
        </VStack>

        {approval && <TaskApprovalInfo approval={approval} />}

        <VStack spacing={5} pt={6}>
          {match(task.taskTemplate.taskType)
            .with(TaskTemplateTaskType.Approval, () => (
              <ApprovalTask taskMachine={currentTaskActor} formResponseActor={formResponseActor} />
            ))
            .otherwise(() => (
              <Task taskMachine={currentTaskActor} />
            ))}
        </VStack>

        {!isApproved && <TaskFooter />}
      </Box>
    </Center>
  );
};
