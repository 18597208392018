import { Muid } from '@process-street/subgrade/core';
import { TemplateShareLevel, TemplateType } from '@process-street/subgrade/process';
import { ButtonGroup, Icon, Text } from 'components/design/next';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useGetTemplateReadPermissionsQuery } from 'features/template/query-builder';
import React from 'react';
import { HttpStatus } from '@process-street/subgrade/util';
import { match, P } from 'ts-pattern';
import {
  CrossLinkBody,
  CrossLinkCard,
  CrossLinkLeftElement,
  CrossLinkRightElement,
  RunButton,
  RunButtonSkeleton,
  TemplateIcon,
  TemplateIconSkeleton,
  TemplateInfo,
  TemplateInfoSkeleton,
  TemplateNotAllowed,
  ViewButton,
} from './components';
import { useTemplateTypeContext } from 'utils/template/template-type-context';

interface CrossLinkCardDisplayProps {
  templateId: Muid;
}

export const CrossLinkCardLoading: React.FC<React.PropsWithChildren<unknown>> = () => (
  <CrossLinkCard>
    <CrossLinkLeftElement>
      <TemplateIconSkeleton />
    </CrossLinkLeftElement>

    <CrossLinkBody>
      <TemplateInfoSkeleton />
    </CrossLinkBody>

    <CrossLinkRightElement>
      <RunButtonSkeleton />
    </CrossLinkRightElement>
  </CrossLinkCard>
);

const CrossLinkCardDisplayDefault: React.FC<React.PropsWithChildren<CrossLinkCardDisplayProps>> = ({ templateId }) => {
  const { isWorkflow } = useTemplateTypeContext();
  return (
    <CrossLinkCard>
      <CrossLinkLeftElement>
        <TemplateIcon templateId={templateId} />
      </CrossLinkLeftElement>

      <CrossLinkBody>
        <TemplateInfo templateId={templateId} />
      </CrossLinkBody>

      <CrossLinkRightElement>
        <ButtonGroup>
          <ViewButton templateId={templateId} />
          {isWorkflow && <RunButton templateId={templateId} />}
        </ButtonGroup>
      </CrossLinkRightElement>
    </CrossLinkCard>
  );
};

const CrossLinkCardDisplayError: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { isPage } = useTemplateTypeContext();
  const useWhiteIcon = isPage;
  return (
    <CrossLinkCard>
      <CrossLinkLeftElement>
        <Icon size="4" icon="lock" variant="far" color={useWhiteIcon ? 'white' : undefined} />
      </CrossLinkLeftElement>
      <CrossLinkBody>
        <TemplateNotAllowed />
      </CrossLinkBody>
    </CrossLinkCard>
  );
};

const CrossLinkCardDeleted: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { isPage } = useTemplateTypeContext();
  const useWhiteIcon = isPage;
  return (
    <CrossLinkCard>
      <CrossLinkLeftElement>
        <Icon size="4" icon="trash-alt" variant="far" color={useWhiteIcon ? 'white' : undefined} />
      </CrossLinkLeftElement>
      <CrossLinkBody>
        <Text variant="1" color="gray.600">
          {children}
        </Text>
      </CrossLinkBody>
    </CrossLinkCard>
  );
};

export const CrossLinkCardDisplay: React.FC<React.PropsWithChildren<CrossLinkCardDisplayProps>> = ({ templateId }) => {
  // A 404 for this one means the template has been deleted
  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId, { retry: false });

  // A 404 for this one means the template has been deleted OR the user just doesn't have permission
  const templateReadPermissionsQuery = useGetTemplateReadPermissionsQuery(
    { templateId },
    {
      // no retry for 404s (no anonymous access)
      retry: (failureCount, error) => error.response?.status !== HttpStatus.NOT_FOUND && failureCount < 2,
    },
  );

  const isLoading = permissionsQuery.isLoading || templateReadPermissionsQuery.isLoading;
  const permissionsErrorCode = permissionsQuery.error?.response?.status;
  const { templateType } = useTemplateTypeContext();

  return match({
    isLoading,
    permissions: permissionsQuery.data,
    templateReadPermissions: templateReadPermissionsQuery.data,
    templateType,
    permissionsErrorCode,
  })
    .with({ isLoading: true }, () => <CrossLinkCardLoading />)
    .with({ permissionsErrorCode: HttpStatus.NOT_FOUND, templateType: TemplateType.Page }, () => (
      <CrossLinkCardDeleted>The linked page has been deleted.</CrossLinkCardDeleted>
    ))
    .with({ permissionsErrorCode: HttpStatus.NOT_FOUND, templateType: TemplateType.Playbook }, () => (
      <CrossLinkCardDeleted>The linked workflow has been deleted.</CrossLinkCardDeleted>
    ))
    .with(
      {
        permissions: { permissionMap: { pageRead: true } },
        templateType: TemplateType.Page,
      },
      {
        permissions: { permissionMap: { templateRead: true } },
        templateType: TemplateType.Playbook,
      },
      {
        templateReadPermissions: {
          template: {
            shareLevel: P.not(TemplateShareLevel.None),
          },
        },
      },
      () => <CrossLinkCardDisplayDefault templateId={templateId} />,
    )
    .otherwise(() => <CrossLinkCardDisplayError />);
};
