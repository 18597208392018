import { ConditionalFilterProgressStatusOperand, ProgressStatus } from '@process-street/subgrade/dashboard';
import { ChecklistStatusMultiselect } from 'components/common/checklist-status-multiselect';
import React from 'react';

export interface FormResponseStatusListOperandEditorProps {
  operand: ConditionalFilterProgressStatusOperand;
  onChange: (operand: ConditionalFilterProgressStatusOperand) => void;
}

export const FORM_RESPONSE_STATUS_LIST_OPERAND_EDITOR_ID = 'FormResponseStatusListOperandMultiselect';

export const FormResponseStatusListOperandEditor: React.FC<
  React.PropsWithChildren<FormResponseStatusListOperandEditorProps>
> = ({ operand, onChange }) => {
  const handleSelect = (statuses: ProgressStatus[]) => {
    onChange({ ...operand, value: statuses });
  };

  return (
    <ChecklistStatusMultiselect
      id={FORM_RESPONSE_STATUS_LIST_OPERAND_EDITOR_ID}
      statuses={operand.value}
      onDone={handleSelect}
      extractStatuses={[ProgressStatus.Completed, ProgressStatus.OnTrack]}
    />
  );
};
