import { QueryKey } from 'react-query';
import { AxiosError } from 'axios';
import { dayjs as moment } from '@process-street/subgrade/util';

import { Organization, User } from '@process-street/subgrade/core';
import { InboxItemType, InboxStats } from '@process-street/subgrade/inbox';

import { axiosService } from 'services/axios-service';
import { useQuery, UseQueryOptions } from 'react-query';
import { Template } from '@process-street/subgrade/process';

type GetInboxStatsByOrganizationProps = {
  organizationId: Organization['id'];
  userId: User['id'];
  itemsType?: InboxItemType;
  templateId?: Template['id'];
  includeOverdue?: boolean;
};
const getInboxStatsByOrganization = ({
  organizationId,
  userId,
  itemsType,
  templateId,
  includeOverdue,
}: GetInboxStatsByOrganizationProps): Promise<InboxStats> => {
  const dateTo = moment().endOf('day').format('x');
  const dateFrom = moment().endOf('day').format('x');

  return axiosService
    .getAxios()
    .get(`/1/organizations/${organizationId}/inbox-stats`, {
      params: {
        dateFrom,
        dateTo,
        userId,
        itemsType,
        templateId,
        includeOverdue,
      },
    })
    .then(res => res.data);
};

export const GetInboxStatsByOrganization = {
  key: ['inbox', 'stats', 'get'],
  getKey: (params: GetInboxStatsByOrganizationProps): QueryKey => [...GetInboxStatsByOrganization.key, params],
  queryFn: (params: GetInboxStatsByOrganizationProps) => getInboxStatsByOrganization(params),
};

type GetInboxStatsByOrganizationResult = InboxStats;
export const useGetInboxStatsByOrganizationQuery = <Select = GetInboxStatsByOrganizationResult>(
  params: GetInboxStatsByOrganizationProps,
  options: UseQueryOptions<InboxStats, AxiosError, Select> = {},
) => {
  return useQuery(GetInboxStatsByOrganization.getKey(params), () => GetInboxStatsByOrganization.queryFn(params), {
    staleTime: 1000 * 60 * 10, // 10 Minutes
    ...options,
  });
};
