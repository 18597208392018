import angular from 'angular';

angular.module('frontStreetApp.services').service('TagMembershipService', function (TagMemberships) {
  const self = this;

  /**
   * Attaches a tag with `tagName` the the template identified by `templateId`.
   *
   * @param templateId
   * @param tagName
   */
  self.create = function (templateId, tagName) {
    return TagMemberships.attachTag({
      templateId,
      tagName,
    }).$promise;
  };

  /**
   * Attaches a tag with `tagName` the the template identified by `templateId`.
   *
   * @param templateId
   * @param tagName
   */
  self.deleteByTemplateIdAndTagName = function (templateId, tagName) {
    return TagMemberships.detachTag({
      templateId,
      tagName,
    }).$promise;
  };

  self.getAll = function () {
    return TagMemberships.query().$promise;
  };

  /**
   * Retrieves all tag memberships for a given template id.
   * This will also include the tag relation.
   *
   * @param templateId
   */
  self.getAllByTemplateId = function (templateId) {
    return TagMemberships.query({
      templateId,
    }).$promise;
  };

  /**
   * Counts all active templates per tag in a given `organizationId`.
   *
   * @param organizationId
   */
  self.countAllByOrganizationId = function (organizationId) {
    return TagMemberships.counts({
      organizationId,
      templateStatus: 'Active',
    }).$promise;
  };
});
