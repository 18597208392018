import {
  AutomationInstance,
  AutomationInstanceUtils,
  getPrefixedAutomationAppName,
  isIncomingWebhookInstance,
  isTemplateSolutionInstance,
} from '@process-street/subgrade/automation';
import React from 'react';
import { Box, BoxProps, HStack, Icon, IconProps } from 'components/design/next';
import { AutomationAppIcon } from 'features/automations/components/automation-app-icon';
import { match, P } from 'ts-pattern';

type Props = {
  automationInstance: AutomationInstance;
};

export const DashboardAutomationInstanceBadge = ({ automationInstance }: Props) => {
  const label = AutomationInstanceUtils.getLabel(automationInstance);
  const safeAppName = getPrefixedAutomationAppName(automationInstance);

  const isPsToPsAutomation =
    isTemplateSolutionInstance(automationInstance) && automationInstance.automationApp === 'ProcessStreet';
  const isPsToPsWebhook =
    isIncomingWebhookInstance(automationInstance) && automationInstance.automationApp === 'Process Street';

  const extraIconProps =
    (isPsToPsAutomation || isPsToPsWebhook) &&
    match<AutomationInstance, Pick<IconProps, 'icon' | 'color'> | null>(automationInstance)
      .with({ description: P.when(d => d.includes('workflow')) }, () => ({ icon: 'workflow', color: 'indigo.500' }))
      .with({ description: P.when(d => d.includes('data set')) }, () => ({ icon: 'database', color: 'yellow.500' }))
      .when(
        () => isPsToPsWebhook,
        () => ({ icon: 'webhook', color: 'blue.500' }),
      )
      .otherwise(() => null);

  const boxProps: BoxProps = {
    bg: 'white',
    borderColor: 'gray.200',
    borderStyle: 'solid',
    borderWidth: 'thin',
    cursor: 'default',
    fontWeight: 'normal',
    justifyContent: 'center',
  };

  return (
    <Box position="relative">
      <HStack {...boxProps} borderRadius="8px" aria-label={label} h={12} p={2.5} spacing="0" w={12}>
        <AutomationAppIcon automationApp={safeAppName} />
      </HStack>
      {extraIconProps && (
        <HStack {...boxProps} borderRadius="full" h={5} left={9} position="absolute" top={-2} w={5}>
          <Icon size="11px" {...extraIconProps} />
        </HStack>
      )}
    </Box>
  );
};
