import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { useTemplateMenuContext } from '../template-menu';
import { ButtonProps } from 'components/design/next';

export interface WorkflowRunsButtonProps {
  children: React.ReactElement<ButtonProps>;
}

export const WorkflowRunsButton: React.FC<React.PropsWithChildren<WorkflowRunsButtonProps>> = React.memo(
  ({ children }) => {
    const { $state } = useInjector('$state');
    const { templateId } = useTemplateMenuContext();

    const go = React.useCallback(() => {
      $state.go(`reports`, { templateId });
    }, [$state, templateId]);

    if (!React.isValidElement(children) || Array.isArray(children)) {
      throw new Error('WorkflowRunsButton child must be a single clickable element');
    }

    const btn = React.cloneElement(children, { onClick: go });

    return btn;
  },
);
