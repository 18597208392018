import angular from 'angular';
import { DataServiceConstants } from 'reducers/data-service/data-service-constants';
import { connectService } from 'reducers/util';
import { htmlEscaped } from '@process-street/subgrade/util';
import { HttpStatus } from '@process-street/subgrade/util';
import { TemplateConstants } from 'services/template-constants';
import { Method } from 'services/template-service.interface';
import { SessionSelector } from 'reducers/session/session.selectors';
import { features } from 'services/features/features';
import { queryClient } from 'components/react-root';
import { GetAllTemplatesQuery } from 'features/template/query-builder';
import { GetTemplatesPermissionStatsByOrganization } from 'components/permissions/services/query';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { match } from 'ts-pattern';
import { TemplateType, tmplTypeName, tmplTypeNameLower } from '@process-street/subgrade/process';
import { trace } from 'components/trace';
import { AnalyticsService } from 'components/analytics/analytics.service';
import { Event } from '@process-street/subgrade/analytics';

angular
  .module('frontStreetApp.services')
  .service(
    'TemplateService',
    function (
      $q,
      $ngRedux,
      $rootScope,
      $state,
      $timeout,
      DataService,
      PlanService,
      SessionService,
      Subject,
      TaskTemplateActions,
      TemplateActions,
      UserService,
      ToastService,
    ) {
      const allActions = {
        ...TemplateActions,
        getFirstTaskTemplateByTemplateId: TaskTemplateActions.getFirstByTemplateId,
      };

      connectService('TemplateService', $ngRedux, null, allActions)(this);

      const logger = trace({ name: 'TemplateService' });

      const self = this;

      self.ShareLevel = {
        NONE: 'None',
        VIEW: 'View',
        RUN: 'Run',
      };

      self.create = function (premadeTemplate, folderId, name, creationMethod, source, organizationId) {
        return self._create(
          premadeTemplate.id,
          folderId,
          name || premadeTemplate.name,
          creationMethod,
          source,
          organizationId,
        );
      };

      self.createByPremadeId = function (premadeTemplateId, folderId, name, creationMethod, source, organizationId) {
        return self._create(premadeTemplateId, folderId, name, creationMethod, source, organizationId);
      };

      self._create = function (premadeTemplateId, folderId, name, creationMethod, source, organizationId) {
        const selectedOrganizationId = SessionService.getSelectedOrganizationId();

        return this.actions.create(organizationId || selectedOrganizationId, premadeTemplateId, folderId, name).then(
          ({ payload: template }) => {
            queryClient.invalidateQueries(
              GetTemplatesPermissionStatsByOrganization.getKey({
                organizationId: selectedOrganizationId,
                parentFolderId: folderId,
              }),
            );
            AnalyticsService.trackEvent(Event.TEMPLATE_CREATED, {
              'pre-made id': premadeTemplateId,
              'pre-made name': name,
              'template id': template.id,
              'template name': template.name,
              'method': creationMethod,
              'location': source,
            });

            return retrieveFirstTaskTemplate(template.id).then(
              firstTaskTemplate => {
                SessionService.setTemplateEditorProperty(
                  `template:${template.id}:activeStep`,
                  firstTaskTemplate.group.id,
                );

                return template;
              },
              () => template,
            );
          },
          response => {
            if (response.status === HttpStatus.PAYMENT_REQUIRED) {
              self.showLimitReachedMessageAndRedirect();
            } else {
              switch (response.status) {
                case HttpStatus.FORBIDDEN:
                  ToastService.openToast({
                    status: 'warning',
                    title: `We couldn't create the workflow`,
                    description: DefaultErrorMessages.permissionErrorDescription('folder'),
                  });
                  break;
                default:
                  ToastService.openToast({
                    status: 'error',
                    title: `We're having problems creating the workflow`,
                    description: DefaultErrorMessages.unexpectedErrorDescription,
                  });
              }
            }

            return $q.reject(response);
          },
        );
      };

      self.get = templateId => this.actions.getById(templateId).then(({ payload }) => payload);

      self.updateShareLevel = (templateId, shareLevel) =>
        this.actions.updateShareLevelById(templateId, shareLevel).then(({ payload }) => payload);

      self.updateShareableRunLink = (templateId, shared) =>
        this.actions.updateShareableRunLink(templateId, shared).then(({ payload }) => payload);

      self.copy = function (template, targetFolderId) {
        const name = `${template.name} (Copy)`;

        return this.actions.copy(template.id, targetFolderId, name).then(({ payload: copiedTemplate }) => {
          queryClient.invalidateQueries(GetTemplatesPermissionStatsByOrganization.key);
          AnalyticsService.trackEvent(Event.TEMPLATE_CREATED, {
            'pre-made id': template.id,
            'pre-made name': template.name,
            'template id': copiedTemplate.id,
            'template name': copiedTemplate.name,
            'method': Method.COPY,
          });

          return copiedTemplate;
        });
      };

      const retrieveFirstTaskTemplate = templateId =>
        this.actions
          .getFirstTaskTemplateByTemplateId(templateId)
          .then(({ payload: taskTemplate }) => (!taskTemplate ? Promise.reject() : taskTemplate));

      self.update = function (template, changes) {
        const updateTemplateRequest = {
          id: template.id,
          name: coalesce(changes.name, template.name),
          description: coalesce(changes.description, template.description),
        };

        return this.actions.update(updateTemplateRequest).then(({ payload }) => payload);
      };

      self.updatePublic = (templateId, publicStatus) =>
        this.actions.updatePublic(templateId, publicStatus).then(({ payload }) => payload);

      /**
       * Returns the first argument that is not undefined.
       *
       * @returns {*}
       */
      function coalesce(...args) {
        const len = args.length;
        for (let i = 0; i < len; i += 1) {
          if (args[i] !== undefined) {
            return args[i];
          }
        }
        return undefined;
      }

      this.updateStatus = function (template, newStatus) {
        const originalStatus = template.status;
        template.status = newStatus;

        return this.actions.updateStatus(template.id, newStatus).then(
          ({ payload: response }) => {
            logger.info('succeeded to update template status');

            let message;
            if (template.status === TemplateConstants.STATUS.ACTIVE) {
              AnalyticsService.trackEvent('template unarchived');
              message = `${tmplTypeName(template)} unarchived.`;
            } else {
              AnalyticsService.trackEvent('template archived');
              match(template.templateType)
                .with(TemplateType.Page, () => {
                  message = 'The page has been archived.';
                })
                .with(TemplateType.Form, () => {
                  message = 'The form has been archived.';
                })
                .with(TemplateType.Playbook, () => {
                  message = 'The workflow and its runs have been archived.';
                })
                .otherwise(() => {
                  message = 'The template has been archived.';
                });
            }

            ToastService.openToast({
              status: 'success',
              title: message,
            });

            return response;
          },
          errorResponse => {
            logger.error('failed to update template status');

            if (errorResponse.status === HttpStatus.PAYMENT_REQUIRED) {
              if (self.isFreemiumTrack()) {
                $rootScope.$broadcast(EventName.TEMPLATE_SHOW_PAYWALL);
              } else {
                self.showLimitReachedMessageAndRedirect();
              }
            } else {
              const verb = template.status === TemplateConstants.STATUS.ACTIVE ? 'unachiving' : 'archiving';
              const resource = tmplTypeNameLower(template);

              ToastService.openToast({
                status: 'error',
                title: `We're having problems ${verb} the ${resource} version`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            }

            template.status = originalStatus;

            return $q.reject(errorResponse);
          },
        );
      };

      self.isFreemiumTrack = () => {
        const plan = SessionSelector.getCurrentPlan($ngRedux.getState());

        return plan ? features.isFreemiumTrack(plan.id) : false;
      };

      self.delete = function (template) {
        return this.actions.deleteById(template.id).then(
          ({ payload: response }) => {
            AnalyticsService.trackEvent('template deleted');

            $rootScope.$broadcast(EventName.TEMPLATE_DELETE_OK, template);
            queryClient.invalidateQueries(GetAllTemplatesQuery.getKey({ organizationId: template.organization.id }));

            return response;
          },
          errorResponse => {
            $rootScope.$broadcast(EventName.TEMPLATE_DELETE_FAILED);

            return $q.reject(errorResponse);
          },
        );
      };

      self.undelete = function (id, status) {
        return this.actions.undelete(id).then(
          ({ payload: template }) => {
            if (status !== TemplateConstants.STATUS.ACTIVE) {
              return this.actions.updateStatus(template.id, status).then(({ payload: response }) => {
                template.status = response.status;

                $rootScope.$broadcast(EventName.TEMPLATE_UNDELETE_OK);

                return template;
              });
            } else {
              $rootScope.$broadcast(EventName.TEMPLATE_UNDELETE_OK);

              return template;
            }
          },
          response => {
            if (response.status === HttpStatus.PAYMENT_REQUIRED && self.isFreemiumTrack()) {
              $rootScope.$broadcast(EventName.TEMPLATE_SHOW_PAYWALL);
              return Promise.reject(response);
            } else {
              $rootScope.$broadcast(EventName.TEMPLATE_UNDELETE_FAILED);
            }
          },
        );
      };

      self.getLimitReachedMessage = function () {
        const selectedOrganization = SessionService.getSelectedOrganization();
        return PlanService.getById(selectedOrganization.subscription.plan.id).then(currentPlan => {
          const planTitle = PlanService.getTitleForPlanLevel(currentPlan.level);
          let message = '';
          if (PlanService.isPlanIdLegacy(currentPlan.id)) {
            message =
              'Free accounts have a limit of 5 active workflow runs.<br>' +
              'To add more, either upgrade to the <b>Basic plan</b> or archive some workflow runs.';
          } else {
            const limit = currentPlan.featureSet.activeTemplatesLimit;
            message =
              `${planTitle} plan has a limit of ${limit} active workflows.<br>` +
              'To add more, either upgrade your plan or archive some workflows.';
          }
          return message;
        });
      };

      /**
       * Shows an upgrade message and, if the user is an admin, redirects to billing page.
       */
      self.showLimitReachedMessageAndRedirect = function () {
        const sessionUser = UserService.getCurrentUser();
        const selectedOrganizationId = SessionService.getSelectedOrganizationId();
        const subject = new Subject(sessionUser.id, selectedOrganizationId);
        if (subject.admin) {
          $state.go('organizationManage.tab', { tab: 'billing' });
        }

        // This is necessary because if we're switching between loader and non-loader pages the message is lost
        // Adding this timeout makes it fire after the route has changed
        $timeout(() => {
          self.getLimitReachedMessage().then(message => {
            ToastService.openToast({
              status: 'warning',
              title: `We couldn't create the workflow`,
              description: message,
            });
          });
        });
      };

      self.getOrphanTemplates = (organizationId, statuses) =>
        DataService.getCollection(DataServiceConstants.TEMPLATES).getOrphanTemplates(organizationId, statuses);

      self.showPaidTemplateMessage = function (template) {
        const message = htmlEscaped`The workflow <b>${template.name}</b> will now count against your total,
                    now that it has been changed.`;

        ToastService.openToast({
          status: 'success',
          title: message,
        });
      };

      /**
       * Gets all templates with a given status.
       *
       * @param organizationId
       * @param status
       */
      self.getAllByOrganizationIdAndStatus = (organizationId, status) =>
        this.actions.getAllByOrganizationIdAndStatus(organizationId, status).then(({ payload }) => payload);

      self.getAllByOrganizationIdAndStatusForSchedule = (organizationId, status) =>
        this.actions
          .getAllByOrganizationIdAndStatusForSchedule(organizationId, status)
          .then(({ payload }) => payload.sort((a, b) => a.name.localeCompare(b.name)));

      self.getById = id => this.actions.getById(id).then(({ payload }) => payload);

      self.isPageByTemplateType = type => type === TemplateType.Page;
      self.isFormByTemplateType = type => type === TemplateType.Form;
      self.isTaskByTemplateType = type => type === TemplateType.Task;
    },
  );
