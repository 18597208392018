import { Runnable } from '@process-street/subgrade/conditional-logic';
import { TaskStatus } from '@process-street/subgrade/process';
import {
  ChecklistRuleDefinitionFormFieldOperator,
  ChecklistRuleDefinitionLogicalOperator,
  ChecklistRuleDefinitionOperandType,
  ChecklistRuleDefinitionOperator,
  FormFieldCondition,
  LogicalChecklistRuleDefinition,
  SimpleChecklistRuleDefinition,
} from '../../conditional-logic/model';
import { MuidUtils } from '../../core';

export const generateChecklistRuleDefinition = (
  ruleDefinition?: Partial<SimpleChecklistRuleDefinition>,
): SimpleChecklistRuleDefinition => ({
  id: MuidUtils.randomMuid(),
  orderTree: '1',
  organizationId: MuidUtils.randomMuid(),
  formFieldWidgetGroupId: MuidUtils.randomMuid(),
  operator: ChecklistRuleDefinitionOperator.Is,
  operand: {
    operandType: ChecklistRuleDefinitionOperandType.String,
    value: 'some',
  },
  taskTemplateGroupIds: [],
  hidden: false,
  widgetGroupIds: [],
  ...ruleDefinition,
});

export const generateRunnableFormFieldChecklistRuleDefinition = (
  ruleDefinition?: Partial<Runnable.FormFieldChecklistRuleDefinition>,
): Runnable.FormFieldChecklistRuleDefinition => ({
  id: MuidUtils.randomMuid(),
  orderTree: '1',
  organizationId: MuidUtils.randomMuid(),
  formFieldWidgetGroupId: MuidUtils.randomMuid(),
  operator: Runnable.ChecklistRuleDefinitionFormFieldOperator.Is,
  operand: {
    operandType: ChecklistRuleDefinitionOperandType.String,
    value: 'some',
  },
  taskTemplateGroupIds: [],
  hidden: false,
  widgetGroupIds: [],
  ...ruleDefinition,
});

export const generateRunnableTaskChecklistRuleDefinition = (
  ruleDefinition?: Partial<Runnable.TaskChecklistRuleDefinition>,
): Runnable.TaskChecklistRuleDefinition => ({
  id: MuidUtils.randomMuid(),
  orderTree: '1',
  organizationId: MuidUtils.randomMuid(),
  taskTemplateGroupId: MuidUtils.randomMuid(),
  operator: Runnable.ChecklistRuleDefinitionTaskOperator.TaskStatusIs,
  operand: {
    operandType: ChecklistRuleDefinitionOperandType.String,
    value: TaskStatus.Completed,
  },
  taskTemplateGroupIds: [],
  hidden: false,
  widgetGroupIds: [],
  ...ruleDefinition,
});

const generateFormFieldCondition: () => FormFieldCondition = () => ({
  operator: ChecklistRuleDefinitionFormFieldOperator.Is,
  operandType: ChecklistRuleDefinitionOperandType.String,
  operandValue: { value: 'something' },
  formFieldWidgetGroupId: MuidUtils.randomMuid(),
});

export const generateLogicalChecklistRuleDefinition = (
  ruleDefinition?: Partial<LogicalChecklistRuleDefinition>,
): LogicalChecklistRuleDefinition => ({
  id: MuidUtils.randomMuid(),
  orderTree: '1',
  organizationId: MuidUtils.randomMuid(),
  operator: ChecklistRuleDefinitionOperator.LogicalOr,
  operand: {
    operandType: ChecklistRuleDefinitionOperandType.Logical,
    data: {
      operator: ChecklistRuleDefinitionLogicalOperator.LogicalOr,
      conditions: [
        {
          operator: ChecklistRuleDefinitionLogicalOperator.LogicalAnd,
          conditions: [generateFormFieldCondition(), generateFormFieldCondition()],
        },
        {
          operator: ChecklistRuleDefinitionLogicalOperator.LogicalAnd,
          conditions: [generateFormFieldCondition()],
        },
      ],
    },
  },
  taskTemplateGroupIds: [],
  hidden: false,
  widgetGroupIds: [],
  ...ruleDefinition,
});
