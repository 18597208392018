import { OrganizationMembershipRole, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { PermitConstants } from '@process-street/subgrade/permission';
import { Folder, Template, TemplateType } from '@process-street/subgrade/process';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { canAccess, Feature } from 'services/features/features';
import { isT5KPlan } from 'services/plans';
import { match, P } from 'ts-pattern';

const WORKFLOW_PATTERN = { folder: P.not(P.nullish), templateType: TemplateType.Playbook };
const PAGE_PATTERN = { folder: P.not(P.nullish), templateType: TemplateType.Page };
const FORM_PATTERN = { folder: P.not(P.nullish), templateType: TemplateType.Form };

export const useAccessLevels = ({
  membership,
  record,
}: {
  membership: OrganizationMembershipWithUser;
  record?: Template | Folder;
}) => {
  const currentPlan = useSelector(SessionSelector.getCurrentPlan);
  const currentPlanId = currentPlan?.id;
  const newPermissionLevelsEnabled = currentPlanId && isT5KPlan(currentPlanId);

  if (!record) return [];

  return match(membership.role)
    .with(OrganizationMembershipRole.Guest, () => {
      const isLegacy = currentPlanId && canAccess(Feature.GUESTS_CAN_CREATE_CHECKLISTS, currentPlanId);
      return match({ ...record, isLegacy })
        .with({ ...WORKFLOW_PATTERN, isLegacy: true }, () => PermitConstants.WorkflowLegacyGuestAccessLevels)
        .with({ ...WORKFLOW_PATTERN, isLegacy: false }, () => PermitConstants.WorkflowGuestAccessLevels)
        .with(PAGE_PATTERN, () => PermitConstants.PageGuestAccessLevels)
        .with({ ...FORM_PATTERN }, () => PermitConstants.FormGuestAccessLevels)
        .with({ isLegacy: true }, () => PermitConstants.FolderLegacyGuestAccessLevels)
        .otherwise(() => PermitConstants.FolderGuestAccessLevels);
    })
    .with(OrganizationMembershipRole.FreeMember, () => {
      const canFreeMembersRunChecklists = isT5KPlan(currentPlanId ?? '');
      return match({ ...record, canFreeMembersRunChecklists })
        .with(
          { ...WORKFLOW_PATTERN, canFreeMembersRunChecklists: true },
          () => PermitConstants.WorkflowFreeMemberWithRunAccessLevels,
        )
        .with(
          { ...WORKFLOW_PATTERN, canFreeMembersRunChecklists: false },
          () => PermitConstants.WorkflowFreeMemberAccessLevels,
        )
        .with(PAGE_PATTERN, () => PermitConstants.PageFreeMemberAccessLevels)
        .with({ ...FORM_PATTERN }, () => PermitConstants.FormFreeMemberAccessLevels)
        .with({ canFreeMembersRunChecklists: true }, () => PermitConstants.FolderFreeMemberWithRunAccessLevels)
        .otherwise(() => PermitConstants.FolderFreeMemberAccessLevels);
    })
    .otherwise(() =>
      match(record)
        .with(WORKFLOW_PATTERN, () =>
          newPermissionLevelsEnabled
            ? PermitConstants.WorkflowFullMemberAccessLevelsV2
            : PermitConstants.WorkflowFullMemberAccessLevels,
        )
        .with(FORM_PATTERN, () => PermitConstants.FormFullMemberAccessLevels)
        .with(PAGE_PATTERN, () => PermitConstants.PageFullMemberAccessLevels)
        .otherwise(() =>
          newPermissionLevelsEnabled
            ? PermitConstants.FolderFullMemberAccessLevelsV2
            : PermitConstants.FolderFullMemberAccessLevels,
        ),
    );
};
