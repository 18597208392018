import { ProcessingError } from '../core';
import { FailureAction, RequestAction, RequestStage, SuccessAction } from './types';

export function createAction<P, M>(type: string, payload?: P, meta?: M) {
  return {
    type,
    payload,
    meta,
  };
}

export function createRequestAction<P, M>(coreType: string, payload?: P, meta?: M): RequestAction<P, M> {
  return {
    type: coreType + RequestStage.Request,
    payload,
    meta,
    stage: RequestStage.Request,
  };
}

export function createSuccessAction<P, M>(coreType: string, payload: P, meta?: M): SuccessAction<P, M> {
  return {
    type: coreType + RequestStage.Success,
    payload,
    meta,
    stage: RequestStage.Success,
  };
}

export function createFailureAction<P extends Error, M>(
  coreType: string,
  payload?: P | ProcessingError,
  meta?: M,
): FailureAction<M> {
  return {
    type: coreType + RequestStage.Failure,
    payload,
    meta,
    stage: RequestStage.Failure,
  };
}
