import { createUsableContext } from '@process-street/subgrade/util';

export enum ThemeName {
  TwentyFour = 'theme-2024',
  Next = 'next',
}

export const [useThemeName, ThemeNameContext] = createUsableContext<ThemeName>({
  hookName: 'useThemeName',
  providerName: 'ThemeNameContext',
});
