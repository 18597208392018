import { Muid } from '@process-street/subgrade/core';
import { safeEntityMapToArrayByIds } from 'reducers/entities/safe-entity-map-to-array-by-ids';
import { ReduxAppState } from 'reducers/types';
import { createSelector } from 'reselect';

const getEntityMap = (state: ReduxAppState) => state.entities.authConnection;
const getLookupMap = (state: ReduxAppState) => state.lookups.authConnection;

const getAllIdsByOrganizationId = (organizationId: Muid) =>
  createSelector(getLookupMap, lookupMap => lookupMap.byOrganizationId[organizationId] || []);

const getAllByOrganizationId = (organizationId: Muid) =>
  createSelector([getEntityMap, getAllIdsByOrganizationId(organizationId)], (entityMap, ids) =>
    safeEntityMapToArrayByIds(entityMap, ids),
  );

export const AuthConnectionSelector = {
  getAllByOrganizationId,
};
