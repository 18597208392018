import { PlanCostQuantity, PlanInfo } from 'pages/organizations/manage/billing/models/models';
import { PlanFeatureSet } from '@process-street/subgrade/billing';
import React from 'react';

export type PlanDescriptionProps = {
  featureSet?: PlanFeatureSet;
};

export type PlanDescriptionComponent = React.FC<React.PropsWithChildren<PlanDescriptionProps>>;

export const FreePlanDescription: PlanDescriptionComponent = ({ featureSet }) =>
  featureSet?.usersLimit === 1 ? (
    <>Limited to 1 workflow, workflow run, and member</>
  ) : (
    <>
      Limited to {featureSet?.activeTemplatesLimit} workflows, {featureSet?.activeChecklistsLimit} workflow runs, and{' '}
      {featureSet?.usersLimit} members
    </>
  );

export const ProPlanDescription: PlanDescriptionComponent = () => (
  <>Transform your team’s knowledge into actionable workflows.</>
);

export const EnterprisePlanDescription: PlanDescriptionComponent = () => (
  <>Your secure and scalable operational knowledge repository.</>
);

export type DurationProps = {
  planCostQuantity: PlanCostQuantity;
};

export type DurationComponent = React.FC<React.PropsWithChildren<DurationProps>>;

export const ProPlanDuration: React.FC<React.PropsWithChildren<DurationProps>> = ({ planCostQuantity }) => {
  const { minQuantity } = planCostQuantity;

  if (minQuantity > 1) {
    return <>per user per month (min. {minQuantity})</>;
  } else {
    return <>per user per month</>;
  }
};

export const EnterprisePlanDuration: DurationComponent = () => <>talk to us!</>;

export const FreePlanDuration: DurationComponent = () => null;

export const ProPlanInfo: PlanInfo = {
  name: 'Pro',
  description: ProPlanDescription,
  duration: ProPlanDuration,
  featuresHeader: 'What you get:',
  features: [
    'Unlimited workflows',
    'Unlimited workflow runs',
    'Unlimited free team members',
    '2000 automation runs / mo.',
  ],
  planDetails: [
    {
      header: 'Workflows',
      items: [
        'Forms & form fields',
        'Task assignments',
        'Approvals',
        'Enforce task order',
        'Dynamic due dates',
        'Task permissions',
        'Role assignments',
        'Conditional logic',
        'Workflow run links',
        'Recurring scheduled workflows',
      ],
    },
    {
      header: 'Sharing',
      items: ['External guest access', 'Public/anonymous workflows'],
    },
    {
      header: 'Admin & security',
      items: ['User groups', 'Google SSO'],
    },
  ],
};

export const EnterprisePlanInfo: PlanInfo = {
  name: 'Enterprise',
  description: EnterprisePlanDescription,
  duration: EnterprisePlanDuration,
  featuresHeader: 'Everything in Pro, plus:',
  features: ['API access', 'Custom reporting', 'Single sign-on', 'Custom automation runs / mo.'],
  planDetails: [
    {
      header: 'Integrations',
      items: ['Custom automations'],
    },
    {
      header: 'Reporting & api access',
      items: ['Reporting API and ETL'],
    },
    {
      header: 'Admin & security',
      items: ['Auto user provisioning', 'Invoice-based billing', 'Controlled release'],
    },
    {
      header: 'Training & support',
      items: ['Workflow setup assistance', 'Dedicated success manager', 'Fully managed workflows', 'SLA for support'],
    },
  ],
};

export const FreePlanInfo: PlanInfo = {
  name: 'Free',
  description: FreePlanDescription,
  duration: FreePlanDuration,
  featuresHeader: '',
  features: [],
};
