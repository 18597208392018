import { PagesEditor } from '../../pages-plate-types';

export const PERSISTING = new WeakMap<PagesEditor, boolean | undefined>();

export const PersistentEditor = {
  /**
   * Get the persisting flag's current value.
   */
  isPersisting(editor: PagesEditor): boolean | undefined {
    return PERSISTING.get(editor) === undefined || PERSISTING.get(editor) === true;
  },

  /**
   * Apply a series of changes inside a synchronous `fn`, without persisting any of
   * the operations to the backend.
   */
  withoutPersisting(editor: PagesEditor, fn: () => void): void {
    const prev = PersistentEditor.isPersisting(editor);
    PERSISTING.set(editor, false);
    fn();
    PERSISTING.set(editor, prev);
  },
};
