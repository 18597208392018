import * as React from 'react';
import { Box, Flex, Input, Select, Text, useToast } from 'components/design/next';
import { CardElement, useElements } from '@stripe/react-stripe-js';
import { countries } from 'countries-list';

export type CreditCardProps = {
  onCountryChange: (value: string) => void;
  onZipChange: (value: string) => void;
};

export const CreditCard: React.FC<React.PropsWithChildren<CreditCardProps>> = ({ onCountryChange, onZipChange }) => {
  const elements = useElements();
  const toast = useToast();

  React.useEffect(() => {
    const cardElement = elements?.getElement(CardElement);

    if (cardElement) {
      cardElement.on('change', event => {
        if (event.error) {
          toast({
            status: 'warning',
            title: "We couldn't update your card details",
            description: event.error.message,

            variant: 'subtle',
          });
        }
      });
    }
  }, [elements, toast]);

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onCountryChange(event.target.value);
  };

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onZipChange(event.target.value);
  };

  return (
    <Box>
      <Flex direction="row">
        <Box mr={2}>
          <Text fontWeight="medium" color="gray.600" mb={2}>
            Credit Card
          </Text>
          <Box w="440px" border="1px solid" borderColor="gray.300" borderRadius={4} paddingX={4} paddingY={3}>
            <CardElement options={{ hidePostalCode: true }} />
          </Box>
        </Box>
      </Flex>

      <Flex direction="row" mt={4}>
        <Box w={68}>
          <Text fontWeight="medium" color="gray.600" mb={2}>
            Country
          </Text>
          <Select placeholder="Select country" onChange={handleCountryChange}>
            {Object.values(countries).map(country => (
              <option key={country.name}>{country.name}</option>
            ))}
          </Select>
        </Box>
        <Box ml={2}>
          <Text fontWeight="medium" color="gray.600" mb={2}>
            Zip/Postal Code
          </Text>
          <Input variant="outline" width={40} onChange={handleZipChange} />
        </Box>
      </Flex>
    </Box>
  );
};
