import * as React from 'react';
import {
  ChecklistRuleDefinitionFormFieldOperator,
  TimeBasedCondition as TimeBasedConditionModel,
} from '@process-street/subgrade/conditional-logic';
import { TimeSourceLabels } from 'app/directives/rules/template/task-templates-selector/selector-helper';
import { Icon, HStack, Text } from 'app/components/design/next';
import { Period } from '@process-street/subgrade/core';

export interface TimeBasedConditionProps {
  condition: TimeBasedConditionModel;
  isTitle?: boolean;
}

export const TimeBasedCondition: React.FC<TimeBasedConditionProps> = ({ condition, isTitle = false }) => {
  const sourceLabel = TimeSourceLabels[condition.dateType];
  const isPast = condition.operator === ChecklistRuleDefinitionFormFieldOperator.PeriodAfter;
  const periodIndicator = isPast ? 'was' : 'is';
  const value = Period.format(condition.operand.value || {});
  const timeFromNow = isPast ? 'ago' : 'from now';
  return (
    <HStack>
      <Icon icon="play" size={isTitle ? '4' : '3'} />
      <Text as="span" fontSize={isTitle ? 'lg' : 'md'}>
        {sourceLabel}&nbsp;
        {!isTitle && (
          <>
            <Text as="span" fontWeight="bold">
              {periodIndicator}
            </Text>
            &nbsp;
            {value} {timeFromNow}
          </>
        )}
      </Text>
    </HStack>
  );
};
