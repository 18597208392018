import angular from 'angular';
import { AnalyticsConstants } from '@process-street/subgrade/analytics/analytics-constants';
import { MSTeamsUtils } from 'features/microsoft-teams/utils';
import { match, P } from 'ts-pattern';
import jwtDecode from 'jwt-decode';
import { trace } from 'components/trace';
import { LocalStorageService } from 'features/storage/local-storage-service';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'Auth0CallbackCtrl',
    function ($location, $rootScope, $scope, $state, $window, appBootService, Auth0Service, SessionService) {
      const ctrl = this;
      const logger = trace({ name: 'Auth0CallbackCtrl' });

      ctrl.$onInit = () => {
        $scope.isTeamsPopUp = ctrl._isMsTeamsPopup();

        $scope.loginProvider = null;

        if ($state.params.url) {
          LocalStorageService.setItem('url', $state.params.url);
        }

        if ($state.params.signedUp) {
          LocalStorageService.setItem('signedUp', $state.params.signedUp);
        }

        const storedUrl = LocalStorageService.getItem('url');

        const [microsoftTeamsConnectPathWithoutHashParams] = $state.href('microsoftTeamsConnect').split('#');
        const isConnectingViaChat = storedUrl?.startsWith(microsoftTeamsConnectPathWithoutHashParams) ?? false;

        if ($scope.isTeamsPopUp && !isConnectingViaChat) {
          logger.info('Auth0 callback executing MS Teams auth flow.');
          ctrl._completeMSTeamsAuthentication();
        } else {
          logger.info('Auth0 callback executing Standard auth flow.');

          $scope.isSigningUp =
            storedUrl?.startsWith(
              $state.href('newCompleteSignUp.organization', { organizationId: '' }, { inherit: false }),
            ) ?? false;

          // Handle the authentication result in the hash
          Auth0Service.parseAuth0Hash().then(token => {
            if (token) {
              appBootService.setupSessionAndBootUsingToken(token).then(() => {
                $rootScope.$broadcast(
                  AnalyticsConstants.Event.USER_LOGGED_IN,
                  SessionService.getUser(),
                  SessionService.getProfile(),
                );
                Auth0Service.redirectToStoredUrl();
              });
            }
          });
        }
      };

      ctrl._completeMSTeamsAuthentication = async () => {
        return import('@microsoft/teams-js').then(teamsSdk => {
          const hashParams = getHashParameters($location.hash());

          teamsSdk.initialize();

          match(hashParams)
            .with({ error: P.string }, () => teamsSdk.authentication.notifyFailure(hashParams.error))
            .with({ access_token: P.string }, () => {
              // Get the stored state parameter and compare with incoming state
              const expectedState = MSTeamsUtils.getAuthState();
              try {
                const decoded = jwtDecode(hashParams.access_token);

                $scope.loginProvider = decoded?.sub?.split('|')?.[0] ?? null;
                $scope.showLoginSuccess = true;
              } catch (e) {
                logger.error('Failed to decode the access token from the MS Teams auth flow.', e.message);
              }

              if (expectedState !== hashParams['state'])
                return teamsSdk.authentication.notifyFailure(MSTeamsUtils.AuthenticationError.StateDoesNotMatch);

              // Wait for the animation to complete
              setTimeout(() => teamsSdk.authentication.notifySuccess(hashParams.access_token), 3000);
            })
            .otherwise(() =>
              // Unexpected condition: hash does not contain error or access_token parameter
              teamsSdk.authentication.notifyFailure(MSTeamsUtils.AuthenticationError.UnexpectedFailure),
            );
        });
      };

      ctrl._isMsTeamsPopup = () => {
        return $window.opener && $window.opener !== $window && (ctrl._isMsTeamsUserAgent() || ctrl._isMsTeamsOpener());
      };

      /**
       * Returns true if the window/app is being served inside the MS Teams app.
       */
      ctrl._isMsTeamsUserAgent = () => {
        return $window.navigator.userAgent.includes('Teams');
      };

      /**
       * Returns true if the window/app was opened by one our MS Teams routes.
       */
      ctrl._isMsTeamsOpener = () => {
        // Checking the opener may fail if it's a different origin (eg. launched in the Teams app) so we default to false
        try {
          return $window.opener.location.href.includes('microsoft-teams');
        } catch {
          return false;
        }
      };
    },
  );

// Parse hash parameters into key-value pairs
const getHashParameters = hash =>
  hash.split('&').reduce((params, pairString) => {
    try {
      const [key, value] = pairString.split('=');
      params[key] = decodeURIComponent(value);
    } catch (e) {
      console.error('failed to decode hash param:', pairString);
    }

    return params;
  }, {});
