import angular from 'angular';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { Trace, trace } from 'components/trace';
import { env } from 'components/common/env';

export class ProfitWellService {
  public static $inject = ['$window'];

  private logger: Trace;

  constructor(private readonly $window: angular.IWindowService) {
    this.logger = trace({ name: 'ProfitWellService' });
  }

  public isProd = () => env.APP_STAGE === 'prod';
  private isRunnable = () => this.$window.profitwell && this.isProd();

  /**
   * Sets the ProfitWell user email.
   * Keep in mind that this the Stripe email, which would be the organization email for Process Street.
   * Set it to `null` to unset it.
   *
   * @param stage
   * @param email
   * @param role
   * @param logger
   */
  public start = (email: string, role: OrganizationMembershipRole) => {
    if (!this.isRunnable()) {
      this.logger.info(`skipping setting user email to "${email}" because not prod`);
    } else if (role !== OrganizationMembershipRole.Admin) {
      this.logger.info(`skipping setting user email to "${email}" because of role ${role}`);
    } else {
      this.logger.info(`setting user email to "${email}"`);
      this.$window.profitwell?.('user_email', email);
    }
  };

  public stop = () => {
    if (this.isRunnable()) {
      this.logger.info('stopping');
      this.$window.profitwell?.('user_email', null);
    } else {
      this.logger.info('skipping stopping because not prod');
    }
  };
}

angular.module('frontStreetApp.services').service('ProfitwellService', ProfitWellService);
