import * as yup from 'yup';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import { TableFormFieldUtils } from '@process-street/subgrade/process/widget-utils/table-form-field-utils';
import { ObjectSchema } from 'yup';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';
import { FieldType, TEXTAREA_MAX_LENGTH } from '@process-street/subgrade/process';
import { FormFieldWidgetSettingsSchemas } from 'pages/forms/_id/edit/components/form-fields/common/validation/form-field-widget-settings-schema';
import { TableFormFieldConfig } from '@process-street/subgrade/process/configs/table-form-field-config';

export const tableFormSettingsSchema: ObjectSchema<WidgetSettings<FieldType.Table>> = yup
  .object({
    config: yup
      .object<TableFormFieldConfig.Config>({
        columnDefs: yup.array<TableFormFieldConfig.ColumnDef>().required(),
        ...FormFieldWidgetSettingsSchemas.defaultValue(TEXTAREA_MAX_LENGTH),
      })
      .required() as yup.Schema<TableFormFieldConfig.Config>,
  })
  .required();

type ValidationSchemaProps = {
  required: boolean;
};

export const makeTableValidationSchema = ({ required }: ValidationSchemaProps) => {
  return required
    ? yup
        .array<TableFieldValue.Row>()
        .min(1, 'The table must contain at least 1 row.')
        .test(
          'At least 1 non-empty row',
          'The table must contain at least 1 non-empty row.',
          rows => rows?.some(row => !TableFormFieldUtils.isRowEmpty(row)) ?? false,
        )
    : yup.array<TableFieldValue.Row>();
};
