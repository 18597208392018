import angular from 'angular';
import { htmlEscaped } from '@process-street/subgrade/util';
import {
  IdentityProvider,
  isOrganizationMembershipActive,
  OrganizationMembershipRole,
} from '@process-street/subgrade/core';
import { ArrayService } from 'services/array-service';
import templateUrl from './add-assignments.component.html';
import './add-assignments.scss';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';
import { EmailService } from 'services/email-service';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { connectController } from 'reducers/util';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { TaskListEvent } from 'directives/task-list/task-list-event';

angular
  .module('frontStreetApp.directives')
  .controller(
    'AddAssignmentsBoxCtrl',
    function ($ngRedux, $scope, focusById, UserSuggester, OrganizationMembershipService) {
      const ctrl = this;
      ctrl.$onInit = () => {
        initializeSuggestions();

        const mapStateToThis = () => state => {
          const organization = SessionSelector.getSelectedOrganization(state);
          const om = OrganizationMembershipSelector.getAllByOrganizationId(organization.id)(state);
          const isUserDeactivationEnabled = FeatureFlagSelector.getFeatureFlags(state).deactivatingUsers;

          const organizationMembershipMapByUserId = Object.fromEntries(om.map(om => [om.user.id, om]));

          return {
            organizationMembershipsMap: organizationMembershipMapByUserId,
            organization,
            isUserDeactivationEnabled,
          };
        };

        connectController($ngRedux, mapStateToThis)(ctrl);

        if (ctrl.organization?.id) {
          OrganizationMembershipService.getAllByOrganizationId(ctrl.organization.id);
        }

        $scope.$on('init', (__event, values) => {
          $scope.helpText = values.helpText;
          $scope.disableGuests = values.disableGuests;
          $scope.disableGroups = values.disableGroups;
          $scope.disableFreeMembers = values.disableFreeMembers;
          $scope.users = values.users;
        });

        $scope.$on('changes', (__event, changes) => {
          if (changes.disableGuests) {
            $scope.disableGuests = changes.disableGuests.currentValue;
          }

          if (changes.disableGroups) {
            $scope.disableGroups = changes.disableGroups.currentValue;
          }

          if (changes.users) {
            $scope.users = changes.users.currentValue;
          }
        });

        $scope.$on('show', () => {
          focusById('user.email');
        });

        $scope.$on('hide', () => {
          $scope.user = { email: '' };
        });
      };

      function initializeSuggestions() {
        function filter(membership) {
          // This is a bit hackish, but seems unlikely to be a problem in practice

          if (['All Members', 'All Free Members', 'All Organization'].includes(membership.user.username)) {
            return false;
          }

          if ($scope.disableGuests && membership.guest) {
            return false;
          }

          if ($scope.disableGroups && isGroupUser(membership.user)) {
            return false;
          }

          if ($scope.disableFreeMembers && membership.role === OrganizationMembershipRole.FreeMember) {
            return false;
          }

          return !ArrayService.includes($scope.users, membership.user, 'id');
        }

        $scope.userSuggester = new UserSuggester([IdentityProvider.ProcessStreet], filter);
      }

      const MAX_SUGGESTIONS = 5;

      $scope.getSuggestions = function (partial) {
        const suggestions = $scope.userSuggester.suggest(partial.toLowerCase()).then(users =>
          users.slice(0, MAX_SUGGESTIONS).filter(user => {
            const organizationMembership = ctrl.state.organizationMembershipsMap?.[user.id];

            if (!organizationMembership || !ctrl.state.isUserDeactivationEnabled) return true;

            return isOrganizationMembershipActive(organizationMembership);
          }),
        );

        return suggestions;
      };

      $scope.selectUser = function (event, user) {
        // If this is not here, then the box will dismiss after being clicked
        event.stopPropagation();

        $scope.user = { email: '' };
        return $scope.$emit('assign', user);
      };

      $scope.getUserLabel = function (model) {
        let label;
        if (model && isGroupUser(model)) {
          label = model.username;
        } else if (angular.isObject(model)) {
          label = `${model.username} (${model.email})`;
        } else {
          label = model;
        }

        return htmlEscaped`${label}`;
      };

      $scope.expand = function (email) {
        $scope.user.email = EmailService.expandEmailAddress(email);
      };
    },
  )
  .component('psAddAssignments', {
    transclude: true,
    bindings: {
      users: '<',
      title: '<',
      helpText: '<',
      disableGuests: '<',
      disableGroups: '<',
      disableFreeMembers: '<',
      disabled: '<',
      onAssign: '&',
    },
    templateUrl,
    controller($scope) {
      const ctrl = this;

      // This is used to control the box visibility of ps-pop-box
      ctrl.box = { visible: false };

      // This component communicates with the controller in the pop box via events in the scope

      ctrl.init = function () {
        $scope.$broadcast('init', {
          users: ctrl.users,
          disableGuests: ctrl.disableGuests,
          disableGroups: ctrl.disableGroups,
          disableFreeMembers: ctrl.disableFreeMembers,
          helpText: ctrl.helpText,
        });
      };

      ctrl.show = function () {
        $scope.$broadcast('show');
        ctrl.box.visible = true;
      };

      ctrl.hide = function () {
        $scope.$broadcast('hide');
        ctrl.box.visible = false;
      };

      ctrl.$onChanges = function (changes) {
        if (changes.title) {
          ctrl.title = changes.title.currentValue || 'Assign users';
        }

        if (changes.disableFreeMembers && !changes.disableFreeMembers.isFirstChange()) {
          $scope.$broadcast('init', {
            users: ctrl.users,
            disableGuests: ctrl.disableGuests,
            disableGroups: ctrl.disableGroups,
            disableFreeMembers: changes.disableFreeMembers.currentValue,
            helpText: ctrl.helpText,
          });
        }

        $scope.$broadcast('changes', changes);
      };

      // Events

      $scope.$on('assign', (event, user) => {
        // Don't let the event escape the component
        event.stopPropagation();

        ctrl.onAssign({ user });
      });

      $scope.$on('$stateChangeStart', () => {
        ctrl.box.visible = false;
      });

      $scope.$on(TaskListEvent.SHOW_MULTI_SELECT_MENU, () => {
        ctrl.box.visible = false;
      });

      $scope.$on(TaskListEvent.HIDE_MULTI_SELECT_MENU, () => {
        ctrl.box.visible = false;
      });
    },
  });
