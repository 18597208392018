import angular from 'angular';
import { StripeConstants } from '@process-street/subgrade/billing/stripe-constants';
import { trace } from 'components/trace';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.services')
  .service('BillingDetailsService', function ($q, OrganizationService, StripeElements) {
    const logger = trace({ name: 'BillingDetailsService' });

    const self = this;

    self.setCard = function (cardElement, billingDetails, organization) {
      return self._createStripeToken(cardElement, billingDetails).then(tokenId =>
        self._setOrganizationCard(organization, tokenId).catch(response => {
          const message = self._convertSetCardErrorCodeToMessage(response.data.code);
          return $q.reject(message);
        }),
      );
    };

    self._createStripeToken = function (cardElement, billingDetails) {
      const tokenData = {
        name: billingDetails.name,
        address_line1: billingDetails.addressLine1,
        address_line2: billingDetails.addressLine2,
        address_city: billingDetails.addressCity,
        address_state: billingDetails.addressState,
        address_country: billingDetails.addressCountry,
        address_zip: billingDetails.addressZip,
      };

      const deferred = $q.defer();

      StripeElements.createToken(cardElement, tokenData).then(result => {
        if (result.error) {
          deferred.reject(result.error.message);
        } else {
          deferred.resolve(result.token.id);
        }
      });

      return deferred.promise;
    };

    self._convertSetCardErrorCodeToMessage = function (errorCode) {
      let message;
      switch (errorCode) {
        case StripeConstants.ErrorCode.CARD_DECLINED:
          message = 'Your credit card was declined.';
          break;
        case StripeConstants.ErrorCode.INCORRECT_CVC:
          message = "Your credit card's CVC is incorrect.";
          break;
        case StripeConstants.ErrorCode.EXPIRED_CARD:
          message = 'Your credit card is expired.';
          break;
        case StripeConstants.ErrorCode.PROCESSING_ERROR:
          message = 'An error occurred while processing your credit card.<br>Please try again later.';
          break;
        case 'EmailVerificationRequired':
          message = 'You need to verify your email address before you can add a credit card.';
          break;
        default:
          message = 'An error occurred while saving your credit card.<br>Please try again later.';
      }
      return message;
    };

    self._setOrganizationCard = function (organization, cardToken) {
      return OrganizationService.setCardById(organization.id, cardToken).then(
        card => {
          logger.info('succeeded to set card');

          if (organization.cardDefined) {
            AnalyticsService.trackEvent('card updated');
          } else {
            AnalyticsService.trackEvent('card added');
          }

          organization.cardDefined = true;

          return card;
        },
        response => {
          logger.info('failed to set card');

          AnalyticsService.trackEvent('card update failed');

          return $q.reject(response);
        },
      );
    };
  });
