import { Muid } from '@process-street/subgrade/core';
import { axiosService } from 'services/axios-service';
import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions } from 'react-query';
import { IncomingWebhookLog } from '@process-street/subgrade/automation';

export namespace GetIncomingWebhookLogsQuery {
  export type Params = { webhookId: Muid };

  export type Response = IncomingWebhookLog[];

  export const key = ['incoming-webhook', 'logs'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/incoming-webhooks/${params.webhookId}/logs`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.webhookId),
    });
  };
}
