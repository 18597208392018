import { Muid } from '@process-street/subgrade/core';
import * as React from 'react';
import { useCurrentUser } from './use-current-user';
import { useInjector } from 'components/injection-provider';

export function useSwitchOrganizationIfNeeded({ organizationId }: { organizationId?: Muid }): void {
  const user = useCurrentUser();

  const { SecurityService, OrganizationService } = useInjector('SecurityService', 'OrganizationService');

  React.useEffect(
    function switchOrganizationIfNeeded() {
      if (organizationId && user && organizationId !== SecurityService.getSelectedOrganizationIdByUser(user)) {
        OrganizationService.switchById(organizationId, false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- No need to run when SecurityService or OrganizationService changes
    [organizationId, user],
  );
}
