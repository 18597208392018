import angular from 'angular';
import { TemplateStatus } from '@process-street/subgrade/process';
import { SearchFutureChecklistsByOrganizationId } from 'features/future-checklist/query-builder';
import { queryClient } from 'components/react-root';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { trace } from 'components/trace';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'FutureChecklistAddCtrl',
    function (
      $scope,
      $state,
      FutureChecklists,
      FutureChecklistService,
      MessageBox,
      SessionService,
      ToastService,
      TemplateService,
      UserService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'FutureChecklistAddCtrl' });
      logger.info('loading ctrl');

      ctrl.$onInit = () => {
        $scope.info = $scope.info || {};

        const selectedOrganizationId = SessionService.getSelectedOrganizationId();
        TemplateService.getAllByOrganizationIdAndStatusForSchedule(selectedOrganizationId, TemplateStatus.Active).then(
          templates => {
            $scope.resourcesLoaded = true;

            $scope.templates = templates;

            const template =
              $state.params.templateId && $scope.templates.find(tmpl => tmpl.id === $state.params.templateId);

            const sessionUser = UserService.getCurrentUser();

            $scope.info = $scope.info || {};

            angular.extend($scope.info, {
              template,
              timeZone: sessionUser.timeZone,
            });
          },
        );
      };

      $scope.validate = function (info) {
        const duePeriod = info.duePeriod || FutureChecklistService.calculateDuePeriod(info.startDate, info.dueDate);
        return info.template && info.startDate && (!duePeriod || FutureChecklistService.isPositivePeriod(duePeriod));
      };

      $scope.createFutureChecklist = function (info) {
        if ($scope.creating) {
          return;
        }

        $scope.creating = true;

        // If there's no due period defined, then convert from the due date
        const duePeriod = info.duePeriod || FutureChecklistService.calculateDuePeriod(info.startDate, info.dueDate);

        const jobs = FutureChecklistService.generateJobs(info);

        FutureChecklists.create({
          templateId: info.template.id,
          name: info.name,
          startDate: info.startDate,
          timeZone: info.timeZone,
          duePeriod,
          jobs,
          emails: info.assignees.map(assignee => assignee.email),
        }).$promise.then(
          () => {
            queryClient.invalidateQueries(SearchFutureChecklistsByOrganizationId.key);

            AnalyticsService.trackEvent('scheduled checklist created', {
              'template id': info.template.id,
              'template name': info.template.name,
              'future checklist name': info.name,
            });

            ToastService.openToast({
              status: 'success',
              title: `Workflow has been scheduled`,
            });

            FutureChecklistService.returnToWorkflow();
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems scheduling the workflow`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });

            $scope.creating = false;
          },
        );
      };

      let confirmCancelFutureChecklistShown = false;
      $scope.confirmCancelFutureChecklist = function (form) {
        if (form.$dirty) {
          if (confirmCancelFutureChecklistShown) {
            return;
          }

          confirmCancelFutureChecklistShown = true;
          MessageBox.confirm({
            title: 'Cancel scheduled workflow changes?',
            message: 'All edits to this scheduled workflow will be discarded and irrecoverable!',
            okButton: {
              type: 'danger',
              text: 'Ok',
              action: cancelFutureChecklist,
            },
          }).result.finally(() => {
            confirmCancelFutureChecklistShown = false;
          });
        } else {
          cancelFutureChecklist();
        }
      };

      function cancelFutureChecklist() {
        FutureChecklistService.returnToWorkflow();
      }
    },
  );
