import angular from 'angular';

angular
  .module('frontStreetApp.controllers')
  .controller('MessageBoxPromptCtrl', function ($scope, $uibModalInstance, modalOptions, focusById) {
    $scope.title = modalOptions.title;
    $scope.message = modalOptions.message;
    $scope.pattern = modalOptions.pattern;

    $scope.result = {
      value: modalOptions.defaultValue,
    };

    $uibModalInstance.opened.then(() => {
      focusById('result.value', {
        selectionStart() {
          return 0;
        },
      });
    });

    $scope.okButton = angular.extend({ text: 'OK', action: angular.noop }, modalOptions.okButton || {});
    $scope.cancelButton = angular.extend({ text: 'Cancel', action: angular.noop }, modalOptions.cancelButton || {});

    $scope.ok = function (result) {
      if (result) {
        $uibModalInstance.close($scope.okButton.action(result));
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss($scope.cancelButton.action());
    };
  });
