import { MergeTagsConstants } from '@process-street/subgrade/form/merge-tags-constants';
import { mailto } from '@process-street/subgrade/process';
import { escapeHtml } from '@process-street/subgrade/util';
import angular from 'angular';
import { createMergeTag } from 'hooks/use-insert-merge-tag-callback';
import templateUrl from './template-email-widget.component.html';
import './template-email-widget.scss';

angular.module('frontStreetApp.directives').component('psTemplateEmailWidget', {
  bindings: {
    widget: '<',
    templateRevision: '<',
    mergeTagsEnabled: '<',
    organization: '<',
    disabled: '<',
    onUpdate: '&',
  },
  templateUrl,
  controller($q, $window, MergeTagsService, TextInsertService, util, WidgetService) {
    const ctrl = this;

    ctrl.Target = MergeTagsConstants.Target;

    ctrl.$onChanges = function (changes) {
      if (changes.widget) {
        ctrl.widget = changes.widget.currentValue;
        ctrl.parsedWidget = angular.copy(ctrl.widget);

        parseWidgetContent(ctrl.widget);
      }
    };

    ctrl.updateWidget = function () {
      ctrl.onUpdate({ widget: ctrl.widget });
      generateMailto(ctrl.widget);
    };

    ctrl.insertMergeTag = function (key, fieldId, fallback) {
      const [field] = angular.element(`[id="${fieldId}"]`);

      TextInsertService.insert(field, createMergeTag(key, fallback));
    };

    /**
     * Opens mailto url in a new tab, fixes issue for Firefox which opens the link in the same window
     */
    ctrl.openMailTo = function () {
      // Fixing issue with blocked popup window for Safari
      if (util.isBrowserSafari()) {
        $window.location.href = ctrl.mailUrl;
      } else {
        $window.open(ctrl.mailUrl);
      }
    };

    /**
     * Parses widget's content in the context of template view
     *
     * @param widget
     */
    function parseWidgetContent(widget) {
      const recipientProcess = MergeTagsService.parseTemplateContent(
        escapeHtml(widget.recipient),
        ctrl.templateRevision,
        MergeTagsConstants.Target.EMAIL,
      );
      const ccProcess = MergeTagsService.parseTemplateContent(
        escapeHtml(widget.cc),
        ctrl.templateRevision,
        MergeTagsConstants.Target.EMAIL,
      );
      const bccProcess = MergeTagsService.parseTemplateContent(
        escapeHtml(widget.bcc),
        ctrl.templateRevision,
        MergeTagsConstants.Target.EMAIL,
      );
      const subjectProcess = MergeTagsService.parseTemplateContent(
        escapeHtml(widget.subject),
        ctrl.templateRevision,
        MergeTagsConstants.Target.GENERAL,
      );
      const bodyProcess = MergeTagsService.parseTemplateContent(
        escapeHtml(widget.body),
        ctrl.templateRevision,
        MergeTagsConstants.Target.GENERAL,
      );

      $q.all({
        recipient: recipientProcess,
        cc: ccProcess,
        bcc: bccProcess,
        subject: subjectProcess,
        body: bodyProcess,
      }).then(response => {
        ctrl.parsedWidget.recipient = response.recipient;
        ctrl.parsedWidget.cc = response.cc;
        ctrl.parsedWidget.bcc = response.bcc;
        ctrl.parsedWidget.subject = response.subject;
        ctrl.parsedWidget.body = response.body;
      });

      generateMailto(widget);
    }

    function generateMailto(widget) {
      ctrl.mailUrl = mailto({
        to: widget.recipient,
        cc: widget.cc,
        bcc: widget.bcc,
        subject: widget.subject,
        body: widget.body,
      });

      WidgetService.generateEmailBody(ctrl.organization, widget.body).then(generatedBody => {
        ctrl.mailUrl = mailto({
          to: widget.recipient,
          cc: widget.cc,
          bcc: widget.bcc,
          subject: widget.subject,
          body: generatedBody,
        });
      });
    }
  },
});
