import { Organization, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { TaskPermissionRule, TaskTemplatePermit } from '@process-street/subgrade/permission';
import {
  FieldType,
  FormFieldWidget,
  isFormFieldWidget,
  TaskTemplate,
  TemplateRevision,
} from '@process-street/subgrade/process';
import { StringUtils, UserUtils } from '@process-street/subgrade/util';
import {
  isSystemGroupUserOm,
  isTaskPermitDisplayableSystemGroupUserOm,
  sortByVisibility,
} from '@process-street/subgrade/util/membership-utils';
import { useInjector } from 'components/injection-provider';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { GetTaskTemplatePermitsQuery } from 'features/template-revisions/query-builder';
import { GetTaskTemplateRulesQuery } from 'features/template-revisions/query-builder/get-task-template-rules-query';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { isEqual } from 'lodash';
import _groupBy from 'lodash/groupBy';
import React from 'react';
import { TaskPermissionRuleService } from '../../service/task-permission-rule.service';

const DEFAULT_SYSTEM_PERMIT_COUNT = 4;

export function useGetPermissionPickerLabel({
  taskTemplates,
  templateRevisionId,
  organizationId,
}: {
  taskTemplates: TaskTemplate[];
  templateRevisionId: TemplateRevision['id'];
  organizationId: Organization['id'];
}): {
  label?: string;
  /** Has default system permits, no direct permits or task permissions set. */
  hasDefaultPermits: boolean;
} {
  const { TaskPermissionRuleService } = useInjector('TaskPermissionRuleService');

  const rulesQuery = GetTaskTemplateRulesQuery.useQuery({ templateRevisionId });

  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevisionId, {
    select: widgets =>
      widgets.filter(
        (widget): widget is FormFieldWidget =>
          isFormFieldWidget(widget) && (widget.fieldType === FieldType.Email || widget.fieldType === FieldType.Members),
      ),
  });

  const allOrganizationMembershipsQuery = useGetAllOrganizationMembershipsQuery({
    organizationId,
  });

  const taskTemplatesIdsSet = new Set(taskTemplates.map(t => t.id));

  const { data: permits } = GetTaskTemplatePermitsQuery.useQuery(
    {
      templateRevisionId,
    },
    {
      select: data => data.permits.filter(permit => taskTemplatesIdsSet.has(permit.taskTemplate.id)),
    },
  );

  const permitsAreEqual = React.useMemo(() => {
    const firstPermitSet = new Set(permits?.filter(p => p.taskRead).map(p => p.organizationMembership.id) || []);
    const firstRuleSet = new Set(
      rulesQuery.data
        ?.filter(
          rule => rule.taskRead && taskTemplates[0] && rule.targetTaskTemplateGroup.id === taskTemplates[0].group.id,
        )
        .map(rule => (rule.sourceFormFieldWidgetGroup ? rule.sourceFormFieldWidgetGroup.id : rule.sourceType)),
    );
    return taskTemplates.every(taskTemplate => {
      const taskTemplatePermitSet = new Set(
        permits
          ?.filter(permit => permit.taskTemplate.id === taskTemplate.id && permit.taskRead)
          .map(p => p.organizationMembership.id) || [],
      );
      const taskTemplateRuleSet = new Set(
        rulesQuery.data
          ?.filter(rule => rule.taskRead && rule.targetTaskTemplateGroup.id === taskTemplate.group.id)
          .map(rule => (rule.sourceFormFieldWidgetGroup ? rule.sourceFormFieldWidgetGroup.id : rule.sourceType)),
      );
      return isEqual(firstPermitSet, taskTemplatePermitSet) && isEqual(firstRuleSet, taskTemplateRuleSet);
    });
  }, [permits, rulesQuery.data, taskTemplates]);

  const allSystemGroupUsers = React.useMemo(() => {
    if (allOrganizationMembershipsQuery.data) {
      return allOrganizationMembershipsQuery.data.filter(isSystemGroupUserOm);
    }
    return [];
  }, [allOrganizationMembershipsQuery.data]);

  const permitSet = React.useMemo(() => {
    if (permits) {
      return new Set(permits.filter(permit => permit.taskRead).map(permit => permit.organizationMembership.id));
    }
  }, [permits]);

  const visibleSystemGroupUsers = React.useMemo(() => {
    return allSystemGroupUsers.filter(isTaskPermitDisplayableSystemGroupUserOm).sort(sortByVisibility);
  }, [allSystemGroupUsers]);

  const visibleSystemGroupUsersWithPermits = React.useMemo(() => {
    if (permitSet) {
      return visibleSystemGroupUsers.filter(user => permitSet.has(user.id));
    }
    return [];
  }, [permitSet, visibleSystemGroupUsers]);

  const nonPredefinedGroupMembershipsWithPermits = React.useMemo(() => {
    if (permits && allSystemGroupUsers && allOrganizationMembershipsQuery.data) {
      const allGroupUserIdsSet = new Set(allSystemGroupUsers.map(user => user.id));
      const nonGroupMemberships = allOrganizationMembershipsQuery.data.filter(
        membership => !allGroupUserIdsSet.has(membership.id),
      );
      const filteredPermits = permits.filter(permit => permit.taskRead || permit.taskUpdate);
      const nonGroupMembershipsWithPermitsIdsSet = new Set(
        filteredPermits.map(permit => permit.organizationMembership.id),
      );
      const membershipsWithPermissions = nonGroupMemberships.filter(membership =>
        nonGroupMembershipsWithPermitsIdsSet.has(membership.id),
      );

      return membershipsWithPermissions;
    }
    return [];
  }, [permits, allSystemGroupUsers, allOrganizationMembershipsQuery.data]);

  const predefinedGroupMembershipsWithPermits = React.useMemo(() => {
    if (permits && visibleSystemGroupUsersWithPermits) {
      const membershipsWithPermissions = visibleSystemGroupUsersWithPermits.filter(membership => {
        const filteredPermits = permits.filter(permit => permit.organizationMembership.id === membership.id);
        return filteredPermits.every(permit => permit.taskRead);
      });

      return membershipsWithPermissions;
    }
    return [];
  }, [permits, visibleSystemGroupUsersWithPermits]);

  const membershipNames = React.useMemo(
    () => nonPredefinedGroupMembershipsWithPermits.map(x => x.user.username),
    [nonPredefinedGroupMembershipsWithPermits],
  );

  const taskPermissionRulesByTaskTemplateGroupId = React.useMemo(() => {
    if (rulesQuery.data) {
      return _groupBy(rulesQuery.data, rule => rule.targetTaskTemplateGroup.id);
    }
    return {};
  }, [rulesQuery.data]);

  const activePredefinedGroups = React.useMemo(
    () => predefinedGroupMembershipsWithPermits.map(x => UserUtils.getLabel(x.user, false)),
    [predefinedGroupMembershipsWithPermits],
  );

  return React.useMemo(() => {
    const shouldShowLabel = permitsAreEqual && taskTemplates?.length > 0;

    if (taskTemplates?.length === 1 || shouldShowLabel) {
      const [taskTemplate] = taskTemplates;

      const { uniqueRules, hasDefaultPermits } = getTaskTemplatePermitsData({
        permits: permits ?? [],
        allSystemGroupUsers,
        taskPermissionRulesByTaskTemplateGroupId,
        TaskPermissionRuleService,
        taskTemplateGroupId: taskTemplate.group.id,
      });

      const widgets = widgetsQuery.data ?? [];
      const ruleUsernames = uniqueRules?.map(rule => TaskPermissionRuleService.getRuleTitleLike(rule, widgets)) ?? [];

      const usernames = [...membershipNames, ...ruleUsernames, ...activePredefinedGroups];
      const predefinedGroupNames = visibleSystemGroupUsers.map(x => UserUtils.getLabel(x.user, false));

      const label = hasDefaultPermits
        ? [
            StringUtils.getSentenceList({
              items: predefinedGroupNames,
              prefix: 'Visible to ',
              max: 3,
            }),
            '(default)',
          ].join(' ')
        : StringUtils.getSentenceList({
            items: usernames,
            prefix: 'Visible to ',
            max: 3,
          });

      return {
        label,
        hasDefaultPermits,
      };
    } else {
      // Different permits among taskTemplates
      const hasDefaultPermits = permits
        ? taskTemplates.every(taskTemplate => {
            const taskPermits = permits.filter(permit => permit.taskTemplate.id === taskTemplate.id);
            return getTaskTemplatePermitsData({
              permits: taskPermits,
              allSystemGroupUsers,
              taskPermissionRulesByTaskTemplateGroupId,
              TaskPermissionRuleService,
              taskTemplateGroupId: taskTemplate.group.id,
            }).hasDefaultPermits;
          })
        : false;

      return {
        label: undefined,
        hasDefaultPermits,
      };
    }
  }, [
    taskTemplates,
    permitsAreEqual,
    permits,
    allSystemGroupUsers,
    widgetsQuery.data,
    taskPermissionRulesByTaskTemplateGroupId,
    TaskPermissionRuleService,
    activePredefinedGroups,
    membershipNames,
    visibleSystemGroupUsers,
  ]);
}

function getTaskTemplatePermitsData({
  permits,
  allSystemGroupUsers,
  taskPermissionRulesByTaskTemplateGroupId,
  TaskPermissionRuleService,
  taskTemplateGroupId,
}: {
  permits: TaskTemplatePermit[];
  allSystemGroupUsers: OrganizationMembershipWithUser[];
  taskPermissionRulesByTaskTemplateGroupId: Record<string, TaskPermissionRule[]>;
  TaskPermissionRuleService: TaskPermissionRuleService;
  taskTemplateGroupId: TaskTemplate['group']['id'];
}) {
  const allGroupUserIdsSet = new Set(allSystemGroupUsers.map(user => user.id));

  const systemGroupPermits = permits.filter(permit => allGroupUserIdsSet.has(permit.organizationMembership.id));

  const nonSystemGroupPermits = permits.filter(permit => !allGroupUserIdsSet.has(permit.organizationMembership.id));

  const systemPermitsCount = systemGroupPermits.length
    ? // default system permits allow "View and complete" i.e. read and update
      systemGroupPermits.filter(permit => permit.taskRead && permit.taskUpdate).length
    : DEFAULT_SYSTEM_PERMIT_COUNT;

  const nonSystemPermitsCount = nonSystemGroupPermits.filter(permit => permit.taskRead).length;

  const totalSystemPermitsCount = systemGroupPermits.length;
  const hasDefaultSystemPermits =
    (systemPermitsCount === DEFAULT_SYSTEM_PERMIT_COUNT && totalSystemPermitsCount === 0) ||
    systemPermitsCount === totalSystemPermitsCount;

  const taskPermissionRules = taskPermissionRulesByTaskTemplateGroupId[taskTemplateGroupId];
  const uniqueRules = TaskPermissionRuleService.extractUniqueTypedRules(taskPermissionRules);
  const hasTaskPermissionRules = uniqueRules?.length > 0;
  const hasDirectPermits = nonSystemPermitsCount !== 0;
  const hasDefaultPermits = hasDefaultSystemPermits && !hasDirectPermits && !hasTaskPermissionRules;

  return {
    hasDefaultPermits,
    uniqueRules,
  };
}
