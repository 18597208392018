import angular from 'angular';

angular.module('frontStreetApp.services').factory('TagMemberships', function (ResourceFactory) {
  return ResourceFactory.create({
    attachTag: 'PUT /1/templates/:templateId/tags/:tagName',
    detachTag: 'DELETE /1/templates/:templateId/tags/:tagName',
    query: 'GET[] /1/tag-memberships',
    counts: 'GET /1/tag-memberships/counts',
  });
});
