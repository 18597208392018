import * as React from 'react';
import { MenuItem } from '@chakra-ui/react';
import { Icon } from 'components/design/next';
import { useUIActorRef } from '../ui-context';

export const DesignMenuItem: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { uiActorRef } = useUIActorRef();
  return (
    <MenuItem
      icon={<Icon icon="cog" variant="far" size="4" color="gray.500" />}
      onClick={() => uiActorRef.send({ type: 'OPEN_DISCLOSURE', name: 'design' })}
    >
      Settings
    </MenuItem>
  );
};
