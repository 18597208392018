import * as React from 'react';
import { DesignProvider } from 'components/design/next';
import { toResult } from '@process-street/subgrade/util';
import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from 'react-query';
import { PaywallProvider } from 'components/paywalls';
import { useInjector } from 'components/injection-provider';
import { useTokenExpirationInterceptor } from './use-token-expiration-interceptor';
import { captureException } from '@sentry/browser';
import { setLogger } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { isAxiosError } from '@process-street/subgrade/api';
import { useEvent } from 'react-use';
import { usePrintStore } from './print-store';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { BackButtonProvider } from 'components/back-button-provider';
import { DrawerContainer } from './drawer-container';

const DevToolsMenu = React.lazy(() =>
  import('features/developer/components/dev-tools-menu').then(({ DevToolsMenu }) => ({
    default: DevToolsMenu,
  })),
);

setLogger({
  // eslint-disable-next-line no-console
  log: args => console.log(args),
  warn: args => console.warn(args),
  error: args => captureException(args),
});

export const queryClient = new QueryClient();

export const NEW_STALE_TIME_ENABLED_KEY = 'new-stale-time-enabled';
const newStaleTimeEnabled = toResult(() => JSON.parse(localStorage.getItem(NEW_STALE_TIME_ENABLED_KEY) ?? '')).fold(
  () => false,
  storedValue => storedValue,
);

queryClient.setDefaultOptions({
  queries: {
    staleTime: newStaleTimeEnabled ? 1000 * 60 : 1000,
  },
  mutations: {
    onError: (error, variables, context) => {
      if (error instanceof Error || isAxiosError(error)) {
        captureException(error, {
          contexts: {
            ...(variables && typeof variables === 'object' ? { variables: variables as Record<string, unknown> } : {}),
            ...(context && typeof context === 'object' ? { context: context as Record<string, unknown> } : {}),
          },
        });
      }
    },
  },
});

export type ReactRootProps = { queryClient?: QueryClient; resetCSS?: boolean };

const ReduxProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { $ngRedux } = useInjector('$ngRedux');
  return <Provider store={$ngRedux}>{children}</Provider>;
};

const AuthProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  useTokenExpirationInterceptor();
  return <>{children}</>;
};

export const ReactRoot: React.FC<React.PropsWithChildren<ReactRootProps>> = ({
  children,
  queryClient: queryClientOverride = queryClient,
  resetCSS = false,
}) => {
  useEvent('beforeprint', () => {
    usePrintStore.setState({ isPrintView: true });
  });
  useEvent('afterprint', () => {
    usePrintStore.setState({ isPrintView: false });
  });
  return (
    <QueryClientProvider client={queryClientOverride}>
      <ReduxProvider>
        <AuthProvider>
          <DesignProvider resetCSS={resetCSS}>
            <PaywallProvider>
              <BackButtonProvider>{children}</BackButtonProvider>
            </PaywallProvider>
            <MaybeDevTools />
            <DrawerContainer />
          </DesignProvider>
        </AuthProvider>
      </ReduxProvider>
      {process.env.NODE_ENV === 'development' ? <ReactQueryDevtools initialIsOpen={false} /> : null}
    </QueryClientProvider>
  );
};

const MaybeDevTools = () => {
  const isDev = process.env.NODE_ENV === 'development';
  const isTest = process.env.NODE_ENV === 'test';
  const isProd = process.env.NODE_ENV === 'production';

  // only fetch the developer bool if we're in production
  const userInfoQuery = useGetCurrentUserInfoQuery({ enabled: isProd, staleTime: Infinity });
  const userIsDeveloper = userInfoQuery.data?.user.developer;

  const shouldShowDevTools = !isTest && (isDev || userIsDeveloper);

  return <React.Suspense fallback={null}>{shouldShowDevTools ? <DevToolsMenu /> : null}</React.Suspense>;
};

export { reactToAngular } from 'angulareact';
