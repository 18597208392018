import { DerivedTaskStatus } from '@process-street/subgrade/dashboard';
import * as React from 'react';
import { makeBarStackHorizontalRect } from '../bar-stack-horizontal';
import { TaskStatusBar, useTooltipEffect, TaskStat } from './tooltip-state';

const BarStackHorizontalRect = makeBarStackHorizontalRect<TaskStat, DerivedTaskStatus>();

export const Rect: React.VFC<{ bar: TaskStatusBar }> = ({ bar }) => {
  const dispatch = useTooltipEffect();
  const ref = React.useRef<SVGRectElement>(null);
  const {
    bar: {
      data: { userId },
    },
    key,
  } = bar;

  return (
    <BarStackHorizontalRect
      aria-label={`${userId} ${key}`}
      ref={ref}
      bar={bar}
      onMouseLeave={() => {
        dispatch({ type: 'hide' });
      }}
      onMouseMove={() => {
        if (!ref.current) return;
        dispatch({
          type: 'show',
          payload: {
            data: bar,
            referenceElement: ref.current,
          },
        });
      }}
    />
  );
};
