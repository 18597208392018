import { Plan, PlanTrack } from '@process-street/subgrade/billing';
import { features } from './features/features';
import { Option } from '@process-street/subgrade/core';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { usePlanQuery } from 'features/plans/query-builder';

export function useOnPlanTrack(track: PlanTrack): boolean {
  const current = usePlanTrack();
  return current === track;
}

export function usePlan(): Option<Plan> {
  const organization = useSelectedOrganization();

  const planId = organization?.subscription?.plan?.id;
  const { data: plan } = usePlanQuery({ planId }, { enabled: Boolean(planId) });

  return plan;
}

export function usePlanTrack(): Option<PlanTrack> {
  const plan = usePlan();

  return features.getPlanTrack(plan?.id);
}
