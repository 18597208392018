import { IncomingWebhook, IncomingWebhookUpdateForm } from '@process-street/subgrade/automation';
import { Muid, Option } from '@process-street/subgrade/core';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateIncomingWebhookMutationParams = ({ templateId: Muid } | { dataSetId: Muid }) &
  IncomingWebhookUpdateForm;

export type UpdateIncomingWebhookMutationResponse = Option<IncomingWebhook>;

export const UpdateIncomingWebhookMutation = {
  key: ['update-incoming-webhook'],
  mutationFn: (params: UpdateIncomingWebhookMutationParams) =>
    axiosService
      .getAxios()
      .patch<UpdateIncomingWebhookMutationResponse>(`/1/incoming-webhooks/${params.id}`, params)
      .then(res => res.data),
};

export const useUpdateIncomingWebhookMutation = (
  options: UseMutationOptions<
    UpdateIncomingWebhookMutationResponse,
    AxiosError,
    UpdateIncomingWebhookMutationParams
  > = {},
) => {
  return useMutation(UpdateIncomingWebhookMutation.mutationFn, {
    mutationKey: UpdateIncomingWebhookMutation.key,
    ...options,
  });
};
