import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type MarkAllCommentsReadMutationResponse = {};

export const MarkAllCommentsReadMutation = {
  key: ['put', 'comments', 'all-as-read'],
  mutationFn: () =>
    axiosService
      .getAxios()
      .put<MarkAllCommentsReadMutationResponse>(`/1/comment-inbox/comments/mark-all-as-read`)
      .then(res => res.data),
};

export const useMarkAllCommentsReadMutation = (
  options: UseMutationOptions<MarkAllCommentsReadMutationResponse, AxiosError> = {},
) => {
  return useMutation(MarkAllCommentsReadMutation.mutationFn, options);
};
