import * as React from 'react';
import { Icon, IconButton, IconButtonProps } from 'components/design/next';
import { useChartBoxContext } from './context';

export const ChartBoxExpandButton: React.FC<React.PropsWithChildren<Omit<IconButtonProps, 'aria-label'>>> = props => {
  const { setIsExpanded, isExpanded, isEmpty } = useChartBoxContext();
  if (isEmpty) return null;

  return (
    <IconButton
      variant="ghost"
      size="sm"
      color="gray.500"
      onClick={setIsExpanded.toggle}
      icon={<Icon icon={isExpanded ? 'compress-alt' : 'expand-alt'} size="4" variant="far" />}
      aria-label={isExpanded ? 'collapse chart' : 'expand chart'}
      {...props}
    />
  );
};
