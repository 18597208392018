import * as React from 'react';
import { Box, Button, HStack, Text } from 'components/design/next';
import { OptionProps } from 'react-select';
import { NodeType } from 'directives/rules/template/task-templates-selector/selector-helper';
import { Icon } from 'components/design/next';
import { useTaskTemplatesSelectorContext } from './context';
import { MultiOption } from './multi-option';
import { OptionType } from './types';
import { useTemplateTypeContext } from 'utils/template/template-type-context';

export const Option: React.FC<React.PropsWithChildren<OptionProps<OptionType, true>>> = props => {
  const { nodeInfoMap, onFold } = useTaskTemplatesSelectorContext();

  const { id } = props.data;
  const nodeInfo = nodeInfoMap[id];
  const isFolded = nodeInfo?.folded ?? false;
  const isSelected = nodeInfo?.selected ?? false;
  const hasChildren = Boolean(props.data.widgets);
  const isHeading = props.data.type === NodeType.Heading;
  const isWidget = props.data.type === NodeType.Widget;
  const { isForm } = useTemplateTypeContext();
  const templateTypePaddingOffset = isForm ? 8 : 0;

  return (
    <Box
      w="full"
      pl={(props.data.level + 1) * 4 - templateTypePaddingOffset}
      bgColor={props.isFocused ? 'brand.100' : undefined}
      fontWeight={isHeading ? 'semibold' : undefined}
      onMouseMove={props.innerProps.onMouseMove}
      onMouseOver={props.innerProps.onMouseOver}
    >
      <HStack position="relative" w="full">
        {!isForm && (
          <Text
            position="absolute"
            left="-7"
            fontWeight={isSelected ? 'semibold' : 'normal'}
            fontSize="xs"
            color="gray.400"
            textAlign="right"
            minW="8"
            visibility={isWidget ? 'hidden' : 'visible'}
          >
            {props.data?.position ?? 0}
          </Text>
        )}

        <MultiOption {...props} />

        {hasChildren && (
          <Button
            size="xs"
            variant="ghost"
            onClick={() => onFold(props.data)}
            ml={-2}
            aria-label={`${isFolded ? 'Expand' : 'Collapse'} ${props.data.name}`}
            _hover={{ bgColor: 'brand.300' }}
          >
            <Icon icon={isFolded ? 'chevron-right' : 'chevron-down'} size="3" />
          </Button>
        )}
      </HStack>
    </Box>
  );
};
