import {
  FormFieldValue,
  FormFieldWidget,
  RichEmailWidgetAttachmentWithS3File,
  Template,
} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type FinishEmailAttachmentChecklistUploadMutationParams = {
  taskId: Template['id'];
  widgetId: FormFieldWidget['id'];
  formFieldValueId: FormFieldValue['id'];
  key: string;
  originalFilename: string;
  contentType: string;
};

export const FinishEmailAttachmentChecklistUploadMutation = {
  key: ['email-attachments', 'finish-checklist-upload'],
  mutationFn: (params: FinishEmailAttachmentChecklistUploadMutationParams) =>
    axiosService
      .getAxios()
      .post<RichEmailWidgetAttachmentWithS3File>(`/1/email-attachments/finish-checklist-upload`, params)
      .then(res => res.data),
};

export const useFinishEmailAttachmentChecklistUploadMutation = (
  options: UseMutationOptions<
    RichEmailWidgetAttachmentWithS3File,
    AxiosError,
    FinishEmailAttachmentChecklistUploadMutationParams
  > = {},
) => {
  return useMutation(FinishEmailAttachmentChecklistUploadMutation.mutationFn, options);
};
