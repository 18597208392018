import { match, P } from 'ts-pattern';
import { useRef, useCallback, useEffect } from 'react';
import { SideMenuItemProps } from '../side-menu-item';
import { UseConditionalLogicAccordionReturn } from './use-accordion-handlers';
import { VariableSizeList } from 'react-window';
import { useConditionalLogicModalStore } from '../modal/store';

type UseVirtualizedRowsSizesParams = {
  accordion: UseConditionalLogicAccordionReturn;
  rowsProps: SideMenuItemProps[];
};

export const useVirtualizedRowsSizes = ({ accordion, rowsProps }: UseVirtualizedRowsSizesParams) => {
  const listRef = useRef<VariableSizeList>(null);
  const sizeMap = useRef<Record<number, number>>({});
  const { selectedWidget, selectedTask } = useConditionalLogicModalStore();

  const getSize = (index: number) => sizeMap.current?.[index] ?? SIDE_MENU_ITEM_HEIGHT;

  const updateRowSize = useCallback(
    (index: number) => {
      const expandedIndexes = accordion.state.current;
      const sideMenuItemProps = rowsProps[index];

      if (sideMenuItemProps) {
        const isExpanded = expandedIndexes.has(index);

        const childrenLength = match(sideMenuItemProps)
          .with({ showOnlyTrigger: true }, () => 1)
          .with({ widgetsNodes: P.array(P.not(P.nullish)) }, props => props.widgetsNodes.length)
          .with({ node: P.not(P.nullish) }, props => props.node.widgets?.length ?? 0)
          .otherwise(() => 0);

        // Calculates the side menu item based on the amount of sub items (widgets)
        // that the task has when it's expanded.
        let height = isExpanded
          ? SIDE_MENU_ITEM_HEIGHT +
            ACCORDION_CONTENT_PADDING_BOTTOM +
            SIDE_MENU_SUB_ITEM_HEIGHT * childrenLength +
            SIDE_MENU_SUB_ITEM_GUTTER * childrenLength
          : SIDE_MENU_ITEM_HEIGHT;

        if (sideMenuItemProps.showArrow) {
          height += SIDE_MENU_ARROW_HEIGHT;
        }

        sizeMap.current = { ...sizeMap.current, [index]: height };
      }
    },
    [accordion.state, rowsProps],
  );

  // Updates the virtualized list rows sizes
  useEffect(
    () => {
      accordion.state.allItems.forEach(index => {
        updateRowSize(index);
      });

      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    },
    // The heights must be recalculates whenever the accordion, selected widget, or selcted task changes.
    [accordion.state, updateRowSize, selectedWidget, selectedTask],
  );

  return {
    updateRowSize,
    listRef,
    getSize,
  };
};

// The height in px of a single menu item
const SIDE_MENU_ITEM_HEIGHT = 36;
// The height in px of a menu sub item height
const SIDE_MENU_SUB_ITEM_HEIGHT = 36;
// The space in pixels between each sub menu item
const SIDE_MENU_SUB_ITEM_GUTTER = 12;
// The padding at the end of the accordion content when it's expanded
const ACCORDION_CONTENT_PADDING_BOTTOM = 20;

const SIDE_MENU_ARROW_HEIGHT = 36;
