import * as React from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  IconButton,
  Text,
  useDisclosure,
  useToast,
} from 'components/design/next';
import { Muid } from '@process-street/subgrade/core';
import { DeleteApiKeyMutation, GetApiKeysQuery } from 'features/api-keys/query-builder';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { queryClient } from 'components/react-root';

interface DeleteApiButtonProps {
  apiKey: Muid;
  apiKeyLabel: string;
}

export const DeleteApiButton: React.FC<React.PropsWithChildren<DeleteApiButtonProps>> = ({ apiKey, apiKeyLabel }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const organization = useSelectedOrganization();

  const deleteApiKeyMutation = DeleteApiKeyMutation.useMutation({
    onSuccess: () => {
      toast({
        title: 'API Key deleted',
        status: 'success',
        variant: 'subtle',
      });
      queryClient.invalidateQueries(GetApiKeysQuery.getKey({ organizationId: organization?.id }));
    },
    onError: () => {
      toast({
        title: "We're having problems deleting the API Key",
        status: 'error',
      });
    },
  });

  const handleDeleteApiKey = () => {
    onClose();
    deleteApiKeyMutation.mutate({ organizationId: organization?.id, apiKeyId: apiKey });
  };

  return (
    <>
      <IconButton
        aria-label="Delete API Key"
        backgroundColor="transparent"
        borderColor="gray.300"
        color="gray.600"
        icon={<Icon icon="trash" size="4" color="gray.500" />}
        mt={{ base: 8, md: 0 }}
        onClick={onOpen}
        size="md"
        variant="ghost"
        _hover={{ bgColor: 'gray.100' }}
      />
      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete this API key?
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>
                If you delete the API key called <Text as="b">{apiKeyLabel}</Text> then all integrations using that key
                will no longer be able to access your organization data. Are you sure you want to do that?
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} variant="ghost" onClick={onClose}>
                No
              </Button>
              <Button variant="danger" onClick={handleDeleteApiKey} ml={3}>
                Yep, cut 'em off
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
