import { IncomingWebhook, IncomingWebhookOptimistic } from '@process-street/subgrade/automation';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateIncomingWebhookMutationParams = IncomingWebhookOptimistic;

export type CreateIncomingWebhookMutationResponse = IncomingWebhook;

export const CreateIncomingWebhookMutation = {
  key: ['post', 'incoming-webhooks'],
  mutationFn: (params: CreateIncomingWebhookMutationParams) =>
    axiosService
      .getAxios()
      .post<CreateIncomingWebhookMutationResponse>(`/1/incoming-webhooks`, params)
      .then(res => res.data),
};

export const useCreateIncomingWebhookMutation = (
  options: UseMutationOptions<
    CreateIncomingWebhookMutationResponse,
    AxiosError,
    CreateIncomingWebhookMutationParams
  > = {},
) => {
  return useMutation(CreateIncomingWebhookMutation.mutationFn, {
    mutationKey: CreateIncomingWebhookMutation.key,
    ...options,
  });
};
