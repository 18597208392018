import { Muid } from '@process-street/subgrade/core';
import { Folder } from '@process-street/subgrade/process';
import React from 'react';
import { useFolderPickerRootContext } from './root';
import { FolderPickerTreeNode } from './tree-node';

export interface FolderPickerTreeProps {
  rootId: Muid;
  extraFolders?: Folder[];
  onBranchSelect?: () => void;
}
export const FolderPickerTree: React.FC<React.PropsWithChildren<FolderPickerTreeProps>> = ({
  rootId,
  extraFolders,
  onBranchSelect,
}) => {
  const { folders } = useFolderPickerRootContext();
  return (
    <>
      {folders
        .filter(f => f.parentFolder?.id === rootId)
        .concat(extraFolders ?? [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(f => (
          <FolderPickerTreeNode key={f.id} folder={f} onBranchSelect={onBranchSelect} />
        ))}
    </>
  );
};
