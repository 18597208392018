import { PlanTrack } from '@process-street/subgrade/billing';
import Muid from 'node-muid';
import Papa from 'papaparse';
import planGroupFeatureSetsCsv from './PlanGroupFeatureSets.csv';
import planPlanGroupsCsv from './PlanPlanGroups.csv';

export const FeatureLimit = {
  ACTIVE_TEMPLATE: 'ActiveTemplate',
  ACTIVE_CHECKLIST: 'ActiveChecklist',
  CHECKLIST_RUN: 'ChecklistRun',
};

export const Feature = {
  GROUPS: 'Groups',
  FULL_PERMISSIONS: 'FullPermissions',
  FULL_AUDIT_TRAIL: 'FullAuditTrail',
  NESTED_FOLDERS: 'NestedFolders',
  COMPLETED_STATE: 'CompletedState',
  CHECKLIST_RUN_LINK: 'ChecklistRunLink',
  TASK_ASSIGNMENT: 'TaskAssignment',
  TEMPLATE_SHARING: 'TemplateSharing',
  MERGE_TAGS: 'MergeTags',
  CHECKLIST_EXPORT: 'ChecklistExport',
  WEBHOOKS: 'Webhooks',
  GUESTS_CAN_CREATE_CHECKLISTS: 'GuestsCanCreateChecklists',
  BRANDING: 'Branding',
  WORKFLOW_RULES: 'WorkflowRules',
  STOP_TASK: 'StopTask',
  DYNAMIC_DUE_DATES: 'DynamicDueDates',
  ROLE_ASSIGNMENTS: 'RoleAssignments',
  TASK_PERMISSIONS: 'TaskPermissions',
  APPROVALS: 'Approvals',
  BRAND_COLOR: 'BrandColor',
};

class PlanGroup {
  constructor(name, allFeatures) {
    this.name = name;
    this.allFeatures = allFeatures;
    this.features = Object.keys(allFeatures).filter(key => allFeatures[key] === true);
  }
}

const featureMatrix = new Map();
Papa.parse(planGroupFeatureSetsCsv.trim(), { header: true }).data.forEach(featureSet => {
  featureMatrix.set(
    featureSet['Plan Group'],
    new PlanGroup(featureSet['Plan Group'], {
      // These are ones we will enable for everyone
      [Feature.GROUPS]: true,
      [Feature.FULL_PERMISSIONS]: true,
      [Feature.FULL_AUDIT_TRAIL]: true,
      [Feature.NESTED_FOLDERS]: true,
      [Feature.COMPLETED_STATE]: true,
      [Feature.CHECKLIST_RUN_LINK]: true,
      [Feature.TASK_ASSIGNMENT]: true,
      [Feature.TEMPLATE_SHARING]: true,
      [Feature.MERGE_TAGS]: true,
      [Feature.CHECKLIST_EXPORT]: true,
      [Feature.WEBHOOKS]: true,
      // These are the ones from the CSV
      [Feature.GUESTS_CAN_CREATE_CHECKLISTS]: featureSet['GuestsCanCreateChecklists'] === 'TRUE',
      [Feature.BRANDING]: featureSet['CustomBranding'] === 'TRUE',
      [Feature.WORKFLOW_RULES]: featureSet['ConditionalLogic'] === 'TRUE',
      [Feature.STOP_TASK]: featureSet['StopTasks'] === 'TRUE',
      [Feature.DYNAMIC_DUE_DATES]: featureSet['DynamicDueDates'] === 'TRUE',
      [Feature.ROLE_ASSIGNMENTS]: featureSet['RoleAssignments'] === 'TRUE',
      [Feature.TASK_PERMISSIONS]: featureSet['TaskPermissions'] === 'TRUE',
      [Feature.APPROVALS]: featureSet['Approvals'] === 'TRUE',
      [Feature.BRAND_COLOR]: featureSet['BrandColor'] === 'TRUE',
    }),
  );
});

const planGroupMatrix = new Map();
const planTrackMap = new Map();
Papa.parse(planPlanGroupsCsv.trim(), { header: true }).data.forEach(planPlanGroup => {
  const featureMap = featureMatrix.get(planPlanGroup['Plan Group']);
  const planId = Muid.fromUuid(planPlanGroup['Plan ID']);

  planGroupMatrix.set(planId, featureMap);
  planTrackMap.set(planId, planPlanGroup['Plan Track']);
});

export const getPlanGroup = planId => planGroupMatrix.get(planId);
const getPlanTrack = planId => planTrackMap.get(planId);

export const canAccess = (feature, planId) => {
  const planGroup = getPlanGroup(planId);
  return planGroup ? planGroup.allFeatures[feature] : false;
};

export const getFeatures = planId => {
  const planGroup = getPlanGroup(planId);
  return planGroup ? planGroup.features : [];
};

const isFreemiumTrack = planId => getPlanTrack(planId) === PlanTrack.Freemium;

const isFreeTrialTrack = planId => getPlanTrack(planId) === PlanTrack.FreeTrial;

// extra export added to make sure it can be spy with jasmine. Maybe after
// migrating to jest can be removed.
export const features = {
  canAccess,
  Feature,
  getPlanTrack,
  isFreemiumTrack,
  isFreeTrialTrack,
};
