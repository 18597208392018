import { Widget } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { updateWidget } from 'pages/pages/_id/edit/page/utils/widget.api';
import { useMutation, UseMutationOptions } from 'react-query';

export type UpdateWidgetMutationResponse = Widget;

export const UpdateWidgetMutation = {
  key: ['widget', 'update'],
  getKey() {
    return [...UpdateWidgetMutation.key];
  },
  mutationFn: updateWidget,
};

export const useUpdateWidgetMutation = <W extends Widget>(options?: UseMutationOptions<W, AxiosError, W>) => {
  return useMutation(UpdateWidgetMutation.mutationFn<W>, {
    mutationKey: UpdateWidgetMutation.getKey(),
    ...options,
  });
};
