import { toSuccess } from 'reducers/util/handlers';
import { Reducer } from 'redux';
import { Action, handleActions } from 'redux-actions';
import { ReducerObject, StrictReducer } from './types';

interface OptionalError {
  error?: boolean;
}

type ActionWithError<T extends OptionalError> = Action<T>;

/**
 * A transformer function which returns a reducer which only acts on success and ignores error.
 * This function should only be used when we createActions from `redux-actions` as
 * @param reducer
 */
export function onSuccess<S, T extends OptionalError>(reducer: StrictReducer<S, T>): StrictReducer<S, T> {
  return (state: S, action: ActionWithError<T>) => (action.error ? state : reducer(state, action));
}

/**
 * Transforms actionHandlers into optimistic actionHandlers, meaning once that only handle
 * Success cases and ignore errors
 */
function optimisticReducerMap<S>(actionHandlers: ReducerObject<S>): ReducerObject<S> {
  return Object.keys(actionHandlers).reduce(
    (newReducer, key) => ({
      ...newReducer,
      [key]: onSuccess(actionHandlers[key]),
    }),
    {},
  );
}

function successReducerMap<S>(actionHandlers: ReducerObject<S>): ReducerObject<S> {
  return Object.keys(actionHandlers).reduce(
    (newReducer, key) => ({
      ...newReducer,
      [toSuccess(key)]: actionHandlers[key],
    }),
    {},
  );
}

/**
 * Wrapper for handleActions, that handles only optimistic cases
 */
export function handleActionsOnSuccess<S>(actionHandlers: ReducerObject<S>, initialState: S): Reducer<S> {
  return handleActions(optimisticReducerMap(actionHandlers), initialState) as Reducer<S>;
}

/**
 * Wrapper for handleActions, that handles only Success actions
 */
export function handleSuccessActions<S>(actionHandlers: ReducerObject<S>, initialState: S): Reducer<S> {
  return handleActions(successReducerMap(actionHandlers), initialState) as Reducer<S>;
}
