import { useDisclosure, useToast, Text, Link } from 'components/design/next';
import { Template, TemplateType, tmplTypeName } from '@process-street/subgrade/process';
import {
  useTemplateMenuContext,
  useTemplateMenuDisclosureContext,
} from 'app/features/template/components/template-menu/template-menu';
import { GetAllTemplatesQuery, GetTemplateQuery, useGetTemplateQuery } from 'app/features/template/query-builder';
import React from 'react';
import { MoveToFolderModal } from './move-to-folder-modal';
import { TEMPLATE_SET } from 'app/reducers/template/template.actions';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useInjector } from 'components/injection-provider';
import { useFoldersQuery } from 'app/pages/library/components/template-library/query';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';
import { match, P } from 'ts-pattern';
import { getAncestorFolders, toSlugPath } from 'app/features/folder/lib';
import { GetAllFoldersByOrganizationId } from 'app/features/folder/query-builder';
import { GetTemplatesPermissionStatsByOrganization } from '../permissions/services/query';

export const MoveToFolderModalWrapper = () => {
  const { templateId, view } = useTemplateMenuContext();
  const { moveTemplateModalDisclosure } = useTemplateMenuDisclosureContext();

  const templateQuery = useGetTemplateQuery({
    templateId,
  });

  const onTemplateMoved = handleTemplateMoved({
    view,
    disclosure: moveTemplateModalDisclosure,
    template: templateQuery.data,
  });

  return (
    <>
      {templateQuery.data?.folder.id && (
        <MoveToFolderModal
          candidateId={templateId}
          folderId={templateQuery.data?.folder.id}
          isOpen={moveTemplateModalDisclosure.isOpen}
          onTemplateMove={onTemplateMoved}
          onClose={moveTemplateModalDisclosure.onClose}
        />
      )}
    </>
  );
};

const handleTemplateMoved = ({
  view,
  disclosure,
  template,
}: {
  view: 'index' | 'show';
  disclosure: ReturnType<typeof useDisclosure>;
  template?: Template;
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { $state } = useInjector('$state');

  const toast = useToast();
  const toastRef = React.useRef<ReturnType<typeof toast>>();

  const { data: folders = [] } = useFoldersQuery();
  const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(template?.id ?? '', {
    enabled: Boolean(template?.id),
  });
  const canMoveTemplate = match({ template, permissionMap })
    .with(
      {
        template: { templateType: P.union(TemplateType.Playbook, TemplateType.Form) },
        permissionMap: { templateUpdate: true },
      },
      () => true,
    )
    .with(
      {
        template: { templateType: TemplateType.Page },
        permissionMap: { pageUpdate: true },
      },
      () => true,
    )
    .otherwise(() => false);

  return React.useCallback(
    (template?: Template) => {
      if (canMoveTemplate) {
        if (template) {
          dispatch({ type: TEMPLATE_SET, payload: template });
          const {
            id: templateId,
            organization: { id: organizationId },
            folder: { id: parentFolderId },
          } = template;

          queryClient.invalidateQueries(GetTemplateQuery.getKey({ templateId }));
          queryClient.invalidateQueries(GetAllFoldersByOrganizationId.getKey(organizationId));
          queryClient.invalidateQueries(
            GetTemplatesPermissionStatsByOrganization.getKey({ organizationId, parentFolderId }),
          );

          if (view === 'index') {
            const folder = folders.find(f => f.id === template.folder.id);
            if (!folder) {
              throw new Error('Folder not found');
            }
            const ancestors = getAncestorFolders(folder, folders);
            queryClient.invalidateQueries(GetAllTemplatesQuery.getKey({ organizationId }));
            const href = $state.href('dashboard.type', { type: 'folder', path: toSlugPath(folder, ancestors) });
            toastRef.current = toast({
              status: 'success',
              title: (
                <Text>
                  {tmplTypeName(template)} moved to{' '}
                  <Link
                    {...{
                      href,
                      textDecor: 'underline',
                      _hover: { cursor: 'pointer', color: 'white', textDecor: 'underline' },
                      onClick: () => toastRef.current && toast.close(toastRef.current),
                    }}
                  >
                    {folder.name}
                  </Link>
                </Text>
              ),
            });
          } else {
            toast({
              status: 'success',
              title: `${tmplTypeName(template)} moved`,
            });
          }
        } else {
          queryClient.invalidateQueries(GetTemplatesPermissionStatsByOrganization.key);
        }
        disclosure.onClose();
      }
    },
    [canMoveTemplate, disclosure, dispatch, queryClient, view, folders, $state, toast],
  );
};
