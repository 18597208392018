import {
  DataSetMergeTag,
  DataSetMergeTagWithValue,
  FieldType,
  FormFieldWidget,
} from '@process-street/subgrade/process';
import { MergeTagsConstants, MergeTagTarget } from '@process-street/subgrade/form';
import { FieldTypeToMergeTagTargetMap, StandardMergeTag, StandardMergeTagLegacy } from './constants';

export type NonLegacyTags<T extends MergeTagTarget> = typeof StandardMergeTag[T] & Record<string, string>;

export type Tags<
  Target extends MergeTagTarget = MergeTagTarget,
  UseLegacy extends boolean | undefined = true,
> = (UseLegacy extends false
  ? typeof StandardMergeTag[Target]
  : typeof StandardMergeTagLegacy[Target] & typeof StandardMergeTag[Target]) &
  Record<string, string>;

type GetTagsOptions<UseLegacy extends boolean | undefined> = {
  includeLegacyTags: UseLegacy;
  includeInitialTags: boolean;
  isTableFormFieldEnabled: boolean;
};

export function getTagsFromKeys<Target extends MergeTagTarget, UseLegacy extends boolean | undefined>(
  keys: Record<FormFieldWidget['key'], FormFieldWidget>,
  target: Target,
  { includeLegacyTags, includeInitialTags, isTableFormFieldEnabled }: GetTagsOptions<UseLegacy>,
): Tags<Target, UseLegacy> {
  const initTags = includeInitialTags
    ? ({
        ...StandardMergeTag[target],
        ...(includeLegacyTags && StandardMergeTagLegacy[target]),
      } as Tags<Target, UseLegacy>)
    : ({} as Tags<Target, UseLegacy>);

  return Object.entries(keys).reduce((tags, [key, widget]) => {
    const isMemberNameField =
      widget.fieldType === FieldType.Members && target === MergeTagsConstants.Target.EMAIL && key.endsWith('.name');

    const isTargetField = FieldTypeToMergeTagTargetMap[target][widget.fieldType];
    const isIncludingTableWidget = isTableFormFieldEnabled || widget.fieldType !== FieldType.Table;

    if (isTargetField && !isMemberNameField && isIncludingTableWidget) {
      const namespacedKey = `form.${key}` as const;
      // Not sure why but I have to cast this type to assign a new string
      (tags as Record<string, string>)[namespacedKey] = widget.label ?? '';
    }
    return tags;
  }, initTags);
}

export function getTagLabelsFromDataSetMergeTags(dataSetMergeTags: DataSetMergeTag[]) {
  return dataSetMergeTags.reduce((acc, tag) => {
    acc[tag.key] = tag.label;
    return acc;
  }, {} as Record<string, string>);
}

export function getTagValuesFromDataSetMergeTags(dataSetMergeTags: DataSetMergeTagWithValue[]) {
  return dataSetMergeTags.reduce((acc, tag) => {
    acc[tag.key] = tag.value;
    return acc;
  }, {} as Record<string, string>);
}
