import { MenuSearchHeader } from 'components/design/BlvdSelect/components/MenuSearchHeader';
import { MenuControlsFooter } from 'components/design/BlvdSelect/components/multi/MenuControlsFooter';
import React from 'react';
import { components, GroupBase, MenuProps } from 'react-select';

export const Menu = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: MenuProps<Option, IsMulti, Group>,
) => {
  const { children, selectProps } = props;

  return (
    <components.Menu {...props}>
      <>
        {selectProps.isSearchable && (
          <>
            <MenuSearchHeader key={'menu-search-header'} {...props} />
            <hr className={'blvd-select__menu__separator'} />
          </>
        )}
        {children}
        {selectProps.menuControls && (
          <>
            <hr className={'blvd-select__menu__separator'} />
            <MenuControlsFooter key={'menu-controls-footer'} {...props} />
          </>
        )}
      </>
    </components.Menu>
  );
};
