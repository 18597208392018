export const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/i;

export const isValidEmail = (email?: string): boolean => email !== undefined && EMAIL_REGEXP.test(email);

const EMAIL_DOMAIN_REGEXP = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;

export const isValidEmailDomain = (domain?: string): boolean =>
  domain !== undefined && EMAIL_DOMAIN_REGEXP.test(domain);

// Adapted from: https://gist.github.com/dperini/729294
export const URL_REGEXP = new RegExp(
  '^' +
    // protocol identifier
    '(?:https?://)?' +
    // user:pass authentication
    '(?:\\S+(?::\\S*)?@)?' +
    '(?:' +
    // IP address exclusion
    // private & local networks
    '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
    '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
    '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broacast addresses
    // (first & last IP address of each class)
    '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
    '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
    '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
    '|' +
    // host name
    '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
    // domain name
    '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
    // TLD identifier
    '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
    // TLD may end with dot
    '\\.?' +
    ')' +
    // port number
    '(?::\\d{2,5})?' +
    // resource path
    '(?:[/?#]\\S*)?' +
    '$',
  'i',
);

export const isValidUrl = (url: string): boolean => url !== undefined && URL_REGEXP.test(url);

export const isPartialUrl = (url: string): boolean =>
  url === '' ? false : /(http|https):\/\//i.test(url) || isValidUrl(url);

export const ABSOLUTE_URL_REGEX = new RegExp(
  'https?:\\/\\/(www\\.)?' + '[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,24}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
);

export const isValidFullUrl = (value: string) => isValidAbsoluteUrl(value) && isValidUrl(value);

// makes sure that http/https is also present in the url. Copied from - https://stackoverflow.com/a/3809435/440432
export const isValidAbsoluteUrl = (url: string): boolean => url !== undefined && ABSOLUTE_URL_REGEX.test(url);
