import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { LoadAndGoComponent } from './load-and-go.component';

export const LoadAndGoModule = angular
  .module('loadAndGo', [uiRouter])
  .component('psLoadAndGo', LoadAndGoComponent)
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $stateProvider.state('loadAndGo', {
      parent: 'appLight',
      url: '/load-and-go',
      component: 'psLoadAndGo',
      data: {
        internal: true,
        skipLoader: true,
      },
      params: {
        promise: null,
        onSuccess: null,
        onFailure: null,
      },
    });
    $urlRouterProvider.otherwise('/');
  }).name;
