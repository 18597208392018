import * as React from 'react';
import {
  TrayAutomationAppName,
  TraySolution,
  SolutionTypeTag,
  isIncomingWebhookInstance,
  isTemplateSolutionInstance,
  withTrayPrefix,
} from '@process-street/subgrade/automation';
import { Template } from '@process-street/subgrade/process';
import { trace } from 'components/trace';
import { match } from 'ts-pattern';
import { useSolutionsByTemplateIdQuery } from 'features/automations/query-builder';
import { useInjector } from 'components/injection-provider';
import { useAutomationSelector } from '../selector/context';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useGetAutomationInstanceQuery } from '../../utils/use-get-automation-instance';

export type AutomationEditorResult = {
  data: {
    availableAutomationApps: Set<TrayAutomationAppName>;
    solutions?: TraySolution[];
    template?: Template;
  };
};

export function useAutomationEditor(): AutomationEditorResult {
  const { $stateParams } = useInjector('$stateParams');
  const templateId: string | undefined = $stateParams.id;

  const [state] = useAutomationSelector();
  const automationInstanceQuery = useGetAutomationInstanceQuery({
    automationInstanceId: state.context.automationInstanceId,
    automationInstanceType: state.context.automationType,
  });
  const automationInstance = automationInstanceQuery.data;

  const logger = trace({ name: 'useAutomationCreation' });

  const solutionsQuery = useSolutionsByTemplateIdQuery(
    { templateId: templateId ?? '' },
    { enabled: Boolean(templateId) },
  );
  const solutions = React.useMemo(() => solutionsQuery.data ?? [], [solutionsQuery.data]);

  const templateQuery = useGetTemplateQuery({ templateId: templateId ?? '' }, { enabled: Boolean(templateId) });
  const template = templateQuery.data;

  const solutionTypeTag = match(automationInstance)
    .when(isTemplateSolutionInstance, ({ solutionTypeTag: tag }) => tag)
    .when(isIncomingWebhookInstance, () => SolutionTypeTag.CreateChecklistWhen)
    .otherwise(() => state.context.solutionTypeTag);

  const availableAutomationApps = React.useMemo(() => {
    const relatedSolutions = solutions.filter(s => s.solutionTypeTag === solutionTypeTag);
    return new Set(
      relatedSolutions
        .filter(solution => {
          if (!solution.automationApp) {
            logger.error(`No automationApp field set for ${solution.title} (${solution.id})`);
            return false;
          }
          return true;
        })
        .map(s => withTrayPrefix(s.automationApp))
        .sort((a, b) => (a > b ? 1 : -1)),
    );
  }, [solutions, solutionTypeTag, logger]);

  return {
    data: {
      availableAutomationApps,
      solutions,
      template,
    },
  };
}

export function getMatchingTemplateSolutionInstances({
  solutions,
  solutionTypeTag,
  automationApp,
}: {
  automationApp: TrayAutomationAppName;
  solutions: TraySolution[];
  solutionTypeTag: SolutionTypeTag;
}) {
  return solutions.filter(
    s => s.solutionTypeTag === solutionTypeTag && withTrayPrefix(s.automationApp) === automationApp,
  );
}
