import { Muid } from '@process-street/subgrade/core';
import { FieldValueJson, FormFieldValue, FormFieldValueUpdateResult } from '@process-street/subgrade/process';
import { TaskPermitUtils } from '@process-street/subgrade/redux/selector/util/task-permit-utils';
import { StateService } from '@uirouter/angularjs';
import { FormFieldValueApi } from 'components/form-field-value/store/form-field-value.api';
import { FormFieldValueSelector } from 'components/form-field-value/store/form-field-value.selectors';
import { queryClient } from 'components/react-root';
import { UpdateFormFieldValueMutation } from 'features/widgets/query-builder';
import { ReduxAppState } from 'reducers/types';
import { createCachedAction } from 'reducers/util';
import { Dispatch } from 'redux';
import { Action, createAction } from 'redux-actions';

export const FORM_FIELD_VALUE_GET_ALL_BY_CHECKLIST_REVISION = 'formFieldValue/GET_ALL_BY_CHECKLIST_REVISION';
export const FORM_FIELD_VALUE_UPDATE = 'formFieldValue/UPDATE';
export const FORM_FIELD_VALUE_UPDATE_PENDING = 'formFieldValue/UPDATE_PENDING';
export const FORM_FIELD_VALUE_UPLOADED_FILE = 'formFieldValue/UPLOADED_FILE';
export const FORM_FIELD_VALUE_DELETE = 'formFieldValue/DELETE';
export const FORM_FIELD_VALUE_SET = 'formFieldValue/SET';

export interface FormFieldValueActions {
  getAllByChecklistRevisionId(checklistRevisionId: Muid): Promise<void>;
}

export const FormFieldValueActionsImpl = ($state: StateService, api: FormFieldValueApi) => {
  'ngInject';

  const getAllByChecklistRevisionId = createCachedAction(
    FORM_FIELD_VALUE_GET_ALL_BY_CHECKLIST_REVISION,
    api.getAllByChecklistRevisionId,
    (checklistRevisionId: Muid, flushCache: boolean) => ({ checklistRevisionId, flushCache }),
    (state: ReduxAppState, checklistRevisionId: Muid) =>
      FormFieldValueSelector.getAllByChecklistRevisionId(checklistRevisionId)(state).length > 0,
    // @ts-expect-error -- TODO
    FormFieldValueSelector.getAllWithFormFieldWithWidgetByChecklistRevisionId,
  );

  const deleteFormFieldValue = createCachedAction(
    FORM_FIELD_VALUE_DELETE,
    api.deleteFormFieldValue,
    (revisionId: Muid, widgetId: Muid) => (state: ReduxAppState) => {
      const allFieldValues = FormFieldValueSelector.getAllByChecklistRevisionId(revisionId)(state);
      const fieldValue = allFieldValues.find((f: FormFieldValue) => f.formFieldWidget.id === widgetId);

      return { revisionId, widgetId, fieldValue };
    },
  );

  const syncDeletedFormFieldValue = createCachedAction(
    FORM_FIELD_VALUE_DELETE,
    // skip DELETE form field value API call
    v => Promise.resolve(v),
    (revisionId: Muid, widgetId: Muid) => (state: ReduxAppState) => {
      const allFieldValues = FormFieldValueSelector.getAllByChecklistRevisionId(revisionId)(state);
      const fieldValue = allFieldValues.find((f: FormFieldValue) => f.formFieldWidget.id === widgetId);

      return { revisionId, widgetId, fieldValue };
    },
  );

  const updateFormFieldValueMutation = (
    fieldValue: FieldValueJson,
    checklistRevisionId: Muid,
    widgetId: Muid,
    _checklistId: Muid,
  ) => {
    return queryClient.executeMutation({
      mutationKey: UpdateFormFieldValueMutation.key,
      mutationFn: () => UpdateFormFieldValueMutation.mutationFn({ checklistRevisionId, widgetId, ...fieldValue }),
      onSuccess: UpdateFormFieldValueMutation.updateFormFieldValuesOnSuccess(queryClient),
      onError: UpdateFormFieldValueMutation.updateFormFieldValueOnError,
    });
  };

  const doUpdateFormFieldValue = createCachedAction(
    FORM_FIELD_VALUE_UPDATE,
    updateFormFieldValueMutation,
    (_fieldValue: FieldValueJson, checklistRevisionId: Muid, _widgetId: Muid, checklistId: Muid) => ({
      checklistId,
      checklistRevisionId,
    }),
  );

  const updateFormFieldValue =
    (fieldValue: FieldValueJson, checklistRevisionId: Muid, widgetId: Muid, checklistId: Muid) =>
    (dispatch: Dispatch, getState: () => ReduxAppState) =>
      // @ts-expect-error -- TODO
      dispatch(doUpdateFormFieldValue(fieldValue, checklistRevisionId, widgetId, checklistId)).then(
        (result: Action<FormFieldValueUpdateResult>) => {
          if (TaskPermitUtils.needReloadChecklist(result.payload, getState())) {
            $state.reload();
          }

          return result;
        },
      );

  const uploadedFormFieldFile = createAction(
    FORM_FIELD_VALUE_UPLOADED_FILE,
    (result: FormFieldValueUpdateResult) => result,
  );

  const setFormFieldValue = createAction(
    FORM_FIELD_VALUE_SET,
    (
      formFieldValue: FormFieldValue,
      checklistTaskAssignments: FormFieldValueUpdateResult['checklistTaskAssignments'],
    ) => ({
      formFieldValue,
      checklistTaskAssignments,
    }),
  );

  const setFormFieldValueUpdatePending = createAction(FORM_FIELD_VALUE_UPDATE_PENDING);

  return {
    deleteFormFieldValue,
    syncDeletedFormFieldValue,
    getAllByChecklistRevisionId,
    setFormFieldValue,
    updateFormFieldValue,
    uploadedFormFieldFile,
    setFormFieldValueUpdatePending,
  };
};
