import { GridApi } from '@ag-grid-community/core';
import { InboxItem, InboxItemUtils } from '@process-street/subgrade/inbox';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { useCallback, useMemo } from 'react';
import { TasksTableUtils } from '../components/tasks-table/tasks-table-utils';
import { useFocusedItemStore } from './use-focused-item-store';

export const useTableFocusManager = (api?: GridApi<TasksTableUtils.TasksTableItem>) => {
  const { item, actions } = useFocusedItemStore();

  const onModelUpdated = useCallback(() => {
    if (!api) return;

    if (item) {
      const itemId = InboxItemUtils.getId(item);

      if (!itemId) {
        return;
      }

      const row = api?.getRowNode(itemId);

      if (row) {
        const activeTagName = document.activeElement?.tagName;
        // Don't focus on the grid when an input is focused
        if (activeTagName === 'INPUT' || activeTagName === 'TEXTAREA') {
          return;
        }

        api?.setFocusedCell(row.rowIndex!, GetInboxItemsQuery.SortBy.ChecklistName);
      }
    }
  }, [api, item]);

  const blur = useCallback(() => {
    api?.clearFocusedCell();
    actions.reset();
  }, [api, actions]);

  const focus = useCallback(
    (item: InboxItem) => {
      actions.setItem({ item });
    },
    [actions],
  );

  return useMemo(
    () => ({
      onModelUpdated,
      blur,
      focus,
      reset: actions.reset,
      setItem: actions.setItem,
    }),
    [onModelUpdated, blur, focus, actions],
  );
};
