import { ActionMeta, BlvdSelect, BlvdSelectHelpers, OnChangeValue } from 'components/design/BlvdSelect';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from 'components/design/next';
import { useUiDisclosure } from 'pages/forms/_id/shared';
import * as React from 'react';
import { useSelector } from '@xstate/react';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { TaskConstants, TaskTemplate } from '@process-street/subgrade/process';
import { Muid, Option } from '@process-street/subgrade/core';
import { useFormEditorPageActorRef } from '../../../form-editor-page-machine';
import { useMoveToStepStore } from './move-to-step-store';
import { useMatch } from '@process-street/adapters/navigation';
import { FormEditorPageActorSelectors } from '../../../form-editor-page-machine/form-editor-page-machine-selectors';

type TaskTemplateOption = {
  value: Muid;
  label: string;
  taskTemplate: TaskTemplate;
};

export const MoveToStepModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const editorActor = useFormEditorPageActorRef();
  const { isOpen, onClose } = useUiDisclosure('moveToStep');

  const { selectedWidget, selectedWidgetActorRef } = useMoveToStepStore();

  const templateRevision = useSelector(editorActor, FormEditorPageActorSelectors.getTemplateRevision);
  const currentTaskTemplateId = selectedWidget?.header.taskTemplate.id;
  const [toTaskTemplate, setToTaskTemplate] = React.useState<Option<TaskTemplate>>();
  const isEditorV2 = useMatch('templateV2');

  const taskTemplatesQuery = useTaskTemplatesByTemplateRevisionIdQuery({ templateRevisionId: templateRevision?.id });
  const indexedTaskTemplates = React.useMemo(() => {
    if (taskTemplatesQuery.data) {
      return taskTemplatesQuery.data
        .map((taskTemplate, index) => ({ taskTemplate, index }))
        .filter(({ taskTemplate }) => taskTemplate.id !== currentTaskTemplateId);
    } else {
      return [];
    }
  }, [taskTemplatesQuery.data, currentTaskTemplateId]);

  const options: TaskTemplateOption[] = indexedTaskTemplates.map(({ taskTemplate, index }) => ({
    value: taskTemplate.id,
    label: `[${index + 1}] ${taskTemplate.name ?? TaskConstants.DefaultStepName}`,
    taskTemplate,
  }));

  const fromTaskTemplate = React.useMemo(() => {
    if (selectedWidget && taskTemplatesQuery.data) {
      return taskTemplatesQuery.data.find(taskTemplate => taskTemplate.id === selectedWidget.header.taskTemplate.id);
    }
  }, [selectedWidget, taskTemplatesQuery.data]);

  const handleTaskTemplateSelect = (
    value: OnChangeValue<TaskTemplateOption, false>,
    actionMeta: ActionMeta<TaskTemplateOption>,
  ) => {
    if (actionMeta.action === 'select-option') {
      if (BlvdSelectHelpers.isOptionType<TaskTemplateOption>(value)) {
        setToTaskTemplate(value.taskTemplate);
      }
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody px="9" py="2">
          <VStack alignItems="stretch">
            <Text variant="1" fontWeight="medium" color="gray.700" pt="4">
              Move to
            </Text>
            <BlvdSelect
              onChange={handleTaskTemplateSelect}
              options={options}
              isSearchable={true}
              placeholder={isEditorV2 ? 'Select task' : 'Select step'}
            />
          </VStack>
        </ModalBody>
        <ModalFooter p="6">
          <ButtonGroup>
            <Button aria-label="cancel" variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              aria-label="move widget"
              isDisabled={!toTaskTemplate}
              onClick={() => {
                if (selectedWidget && toTaskTemplate && fromTaskTemplate) {
                  selectedWidgetActorRef?.send({
                    type: 'MOVE_TO_STEP',
                    widget: selectedWidget,
                    from: fromTaskTemplate,
                    to: toTaskTemplate,
                  });
                }
                onClose();
              }}
            >
              Move
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
