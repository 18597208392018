import * as React from 'react';

import { chakra, VStack, TextProps } from 'components/design/next';

import { Checklist } from '@process-street/subgrade/process';

import { match } from 'ts-pattern';
import escapeRegExp from 'lodash/escapeRegExp';

import ReactHtmlParser, { convertNodeToElement, processNodes, Transform } from 'react-html-parser';
import MarkdownIt from 'markdown-it';
import emoji from 'markdown-it-emoji';
import mila from 'markdown-it-link-attributes';
import { useGetAllUsernames } from 'features/comments/use-get-all-usernames';

export type ContentTextProps = {
  content: string;
  checklistId?: Checklist['id'];
} & TextProps;

const tagUsersInContent = ({ usernames, content }: { usernames: string[]; content: string }): string => {
  // Sort usernames by length in descending order
  const sortedUsernames = [...usernames].sort((a, b) => b.length - a.length);

  return sortedUsernames.reduce((acc, username) => {
    const atUsername = `@${username}`;
    const atUsernameHtml = `<span data-test-id="mention" style="color: var(--ps-colors-gray-700); font-weight: 500;">${atUsername}</span>`;
    const match = new RegExp(`(@${escapeRegExp(username)})`, 'g');

    return acc.replace(match, atUsernameHtml);
  }, content);
};

const transform: Transform = (node, index: number): React.ReactElement | void | null => {
  return match(node)
    .with({ type: 'tag', name: 'p' }, () => (
      <chakra.p w="full" m="0" key={index}>
        {processNodes(node.children, transform)}
      </chakra.p>
    ))
    .with({ type: 'tag', name: 'strong' }, () => (
      <chakra.span as="span" fontWeight="bold" key={index}>
        {processNodes(node.children, transform)}
      </chakra.span>
    ))
    .otherwise(() => convertNodeToElement(node, index, transform));
};

const commentParser = new MarkdownIt({
  html: false,
  breaks: true,
  linkify: true,
  xhtmlOut: true,
})
  .use(emoji)
  .use(mila, {
    attrs: {
      target: '_blank',
    },
  });

export const ContentText: React.FC<React.PropsWithChildren<ContentTextProps>> = ({
  content,
  checklistId,
  ...props
}) => {
  const { usernames } = useGetAllUsernames();

  // Markdown To HTML
  const html = commentParser.render(content);

  // HTML To HTML With Mentions
  const htmlWithMentions = tagUsersInContent({ usernames, content: html });

  // HTML to React Elements
  const reactElements = ReactHtmlParser(htmlWithMentions, {
    decodeEntities: true,
    transform,
  });

  return (
    <VStack color="gray.600" width="full" alignItems="flex-start" space="4" fontSize="md" {...props}>
      {reactElements.map((el, index) => (
        <React.Fragment key={index}>{el}</React.Fragment>
      ))}
    </VStack>
  );
};
