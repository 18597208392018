import * as React from 'react';
import { Icon, Link, Text, Tooltip } from 'components/design/next';

interface SandboxInfoTooltipProps {
  label: string;
  link: string;
  iconColor?: string;
}

export const SandboxInfoTooltip: React.FC<React.PropsWithChildren<SandboxInfoTooltipProps>> = ({
  label,
  link,
  iconColor = 'white',
}) => {
  return (
    <Tooltip
      label={
        <Text fontSize="sm">
          {label}&nbsp;Learn more&nbsp;
          <Link textDecoration="underline" href={link} isExternal>
            here
          </Link>
          .
        </Text>
      }
      placement="bottom"
      pointerEvents="all"
      shouldWrapChildren
      hasArrow
    >
      <Icon icon="info-circle" variant="far" color={iconColor} size="4" />
    </Tooltip>
  );
};
