import { Checklist } from '@process-street/subgrade/process';
import { ChecklistAssignment } from '@process-street/subgrade/role-assignment';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetChecklistAssignmentsQueryParams = { checklistId: Checklist['id'] };

export type GetChecklistAssignmentsQueryResponse = ChecklistAssignment[];

export const GetChecklistAssignmentsQuery = {
  key: ['checklists', 'assignments'],
  getKey: (params: GetChecklistAssignmentsQueryParams): QueryKey => [...GetChecklistAssignmentsQuery.key, params],
  queryFn: (params: GetChecklistAssignmentsQueryParams) =>
    axiosService
      .getAxios()
      .get<GetChecklistAssignmentsQueryResponse>(`/1/checklists/${params.checklistId}/assignments`)
      .then(res => res.data),
};

export const useGetChecklistAssignmentsQuery = <Select = GetChecklistAssignmentsQueryResponse>(
  params: GetChecklistAssignmentsQueryParams,
  options: UseQueryOptions<GetChecklistAssignmentsQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetChecklistAssignmentsQuery.getKey(params),
    () => GetChecklistAssignmentsQuery.queryFn(params),
    options,
  );
};
