import * as React from 'react';
import { ChecklistStatus, Folder, isWorkflow, TemplateStatus } from '@process-street/subgrade/process';
import { toEntityMap } from '@process-street/subgrade/redux';
import { useGetChecklistsByOrganizationIdQuery } from 'features/checklists/query-builder';
import { useGetAllTemplatesQuery } from 'features/template/query-builder';

export const useChecklistCountByFolder = (folder: Folder) => {
  const { id: folderId } = folder;
  const { data: checklists = [], isLoading: checklistsAreLoading } = useGetChecklistsByOrganizationIdQuery({
    organizationId: folder.organization.id,
    templateStatus: TemplateStatus.Active,
    checklistStatus: ChecklistStatus.Active,
  });
  const { data: templates = [], isLoading: templatesAreLoading } = useGetAllTemplatesQuery({
    organizationId: folder.organization.id,
    templateStatus: TemplateStatus.Active,
  });

  const templateMap = toEntityMap(templates);

  const count = React.useMemo(() => {
    return checklists.reduce((acc, checklist) => {
      const template = templateMap[checklist.template.id];
      const checklistFolderId = template?.folder?.id;
      const isInFolder = folderId === checklistFolderId;
      return isInFolder && isWorkflow(template) ? acc + 1 : acc;
    }, 0);
  }, [checklists, folderId, templateMap]);

  return {
    isLoading: templatesAreLoading || checklistsAreLoading,
    count,
  };
};
