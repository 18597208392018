import {
  isDateFormFieldWidget,
  isNumberFormFieldWidget,
  isUrlFormFieldWidget,
  isValidUrl,
  WidgetValidation,
} from '@process-street/subgrade/process';
import angular from 'angular';
import { connectService } from 'reducers/util';
import { match, P } from 'ts-pattern';
import { FormFieldEvent } from 'services/form-field-event';

angular
  .module('frontStreetApp.services')
  .service(
    'FormFieldValueService',
    function ($ngRedux, $q, $rootScope, FeatureFlagService, FormFieldValueActions, FormFieldValueEvent) {
      const self = this;

      connectService('FormFieldValueService', $ngRedux, null, FormFieldValueActions)(self);

      self.getAllByChecklistRevisionId = (checklistRevisionId, flushCache) =>
        this.actions.getAllByChecklistRevisionId(checklistRevisionId, flushCache).then(action => action.payload);

      self.updateFormFieldValue = function (
        formFieldValue,
        originalFormFieldValue,
        checklistRevisionId,
        widgetId,
        checklistId,
      ) {
        $rootScope.$broadcast(
          FormFieldEvent.FORM_FIELD_VALUE_UPDATE_STARTED,
          formFieldValue,
          originalFormFieldValue,
          checklistRevisionId,
        );

        return self.actions
          .updateFormFieldValue(formFieldValue.fieldValue, checklistRevisionId, widgetId, checklistId)
          .then(
            ({ payload: result }) => {
              // Add id if new field value
              if (!formFieldValue.id) {
                formFieldValue.id = result.formFieldValue.id;
              }
              $rootScope.$broadcast(
                FormFieldEvent.FORM_FIELD_VALUE_UPDATE_OK,
                formFieldValue,
                originalFormFieldValue,
                checklistRevisionId,
                result.dueDateTaskStates,
              );

              return result;
            },
            error => {
              $rootScope.$broadcast(
                FormFieldEvent.FORM_FIELD_VALUE_UPDATE_FAILED,
                formFieldValue,
                originalFormFieldValue,
                checklistRevisionId,
              );

              return $q.reject(error);
            },
          )
          .finally(() => {
            $rootScope.$broadcast(
              FormFieldEvent.FORM_FIELD_VALUE_UPDATE_FINISHED,
              formFieldValue,
              originalFormFieldValue,
              checklistRevisionId,
            );
          });
      };

      self.initializeFormFieldValueMap = function (
        formFieldValueMap,
        formFieldValues,
        widgetsGroupedByStep,
        widgetsById = {},
      ) {
        formFieldValues.forEach(formFieldValue => {
          const enrichedConfig = widgetsById[formFieldValue.formFieldWidget.id]?.config;

          if (enrichedConfig) formFieldValue.formFieldWidget.config = enrichedConfig;

          formFieldValueMap[formFieldValue.formFieldWidget.header.id] = formFieldValue;
        });

        angular.forEach(widgetsGroupedByStep, widgets => {
          widgets.forEach(widget => {
            if (widget.header.type === 'FormField') {
              if (angular.isUndefined(formFieldValueMap[widget.header.id])) {
                formFieldValueMap[widget.header.id] = {
                  fieldValue: {},
                  formFieldWidget: widget,
                };
              }
            }
          });
        });
      };

      /**
       * Gets a list of form fields that doesn't meet constraints for a given task
       *
       * @param taskWidgets widgets for a task template group id
       * @param formFieldValueMap Map of form field values with widget header id as a key
       *
       * @returns A list of form field widgets for which the value considered invalid
       */
      self.getFailedFormFieldWidgets = ({ taskWidgets: widgets, formFieldValueMap }) => {
        // Guard for tasks without widgets
        if (!widgets) {
          return [];
        }
        return widgets.filter(widget => {
          // Let's wait on finding a more elegant pattern for different widget types
          if (isNumberFormFieldWidget(widget)) {
            const { fieldValue } = formFieldValueMap[widget.header.id] ?? {};
            const widgetValue = match({ fieldValue })
              .with({ fieldValue: { value: P.string } }, ({ fieldValue: { value } }) => value)
              .otherwise(() => '');

            const errorTypes = WidgetValidation.getNumberFormFieldWidgetErrorTypes(widget.constraints, widgetValue);
            return errorTypes.size > 0;
          }
          if (isDateFormFieldWidget(widget)) {
            const { fieldValue } = formFieldValueMap[widget.header.id] ?? {};
            const widgetValue = match({ fieldValue })
              .with({ fieldValue: { value: P.number } }, ({ fieldValue: { value } }) => value)
              .otherwise(() => null);

            const errorType = WidgetValidation.getDateFormFieldWidgetErrorType(widget.constraints, widgetValue);

            return Boolean(errorType);
          }

          if (isUrlFormFieldWidget(widget)) {
            const { fieldValue: { value = '' } = {} } = formFieldValueMap[widget.header.id] ?? {};
            return value !== '' && !isValidUrl(value);
          }

          if (WidgetValidation.hasConstraintValidation(widget)) {
            const { fieldValue } = formFieldValueMap[widget.header.id] ?? {};
            return WidgetValidation.hasFailedConstraints(widget, fieldValue);
          }

          return false;
        });
      };

      self.getAllTasksFailedConstraintsMap = (taskTemplates, formFieldWidgetsMap, formFieldValueMap, taskMap) => {
        const failedConstraintsMap = {};

        taskTemplates.forEach(taskTemplate => {
          if (!(taskMap[taskTemplate.group.id] && taskMap[taskTemplate.group.id].hidden)) {
            const taskWidgets = formFieldWidgetsMap[taskTemplate.group.id];
            const invalidWidgets = self.getFailedFormFieldWidgets({ taskWidgets, formFieldValueMap });
            if (invalidWidgets.length > 0) {
              failedConstraintsMap[taskTemplate.group.id] = invalidWidgets;
            }
          }
        });

        return failedConstraintsMap;
      };

      self.broadcastTaskHasFailedConstraintsFormFields = (taskTemplate, invalidFormFields = []) => {
        const data = {
          taskTemplate,
          invalidFormFields,
        };

        $rootScope.$broadcast(FormFieldValueEvent.TASK_HAS_FAILED_CONSTRAINTS_FORM_FIELDS, data);
      };

      self.broadcastChecklistHasFailedConstraintsFormFields = function ({
        invalidFormFields = [],
        ignoreRequiredFields = false,
      }) {
        $rootScope.$broadcast(FormFieldValueEvent.CHECKLIST_HAS_FAILED_CONSTRAINTS_FORM_FIELDS, {
          invalidFormFields,
          ignoreRequiredFields,
        });
      };
    },
  );
