import { EmailMergeTags, Muid } from '@process-street/subgrade/core';
import { axiosService } from 'services/axios-service';
import { AxiosError } from 'axios';
import { HttpStatus } from '@process-street/subgrade/util';

export type GetEmailMergeTagsParams = { checklistId: Muid };

export type GetEmailMergeTagsResult = EmailMergeTags | undefined;

export const GetEmailMergeTagsQuery = {
  key: ['checklists', 'email-merge-tags'],
  getKey: (params: GetEmailMergeTagsParams) => [...GetEmailMergeTagsQuery.key, params],
  queryFn: ({ checklistId }: GetEmailMergeTagsParams) =>
    axiosService
      .getAxios()
      .get<GetEmailMergeTagsResult>(`/1/checklists/${checklistId}/email-merge-tags`)
      .then(r => r.data)
      .catch((e: AxiosError) => {
        if (e.response?.status === HttpStatus.NOT_FOUND) {
          return undefined;
        } else {
          throw e;
        }
      }),
};
