import { FieldType, MembersFormFieldConfig } from '@process-street/subgrade/process';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';

export const membersSettingsSchema: ObjectSchema<WidgetSettings<FieldType.Members>> = yup
  .object({
    config: yup
      .object<MembersFormFieldConfig>({
        groupId: yup.string().required(),
      })
      .required() as yup.Schema<MembersFormFieldConfig>,
  })
  .required();
