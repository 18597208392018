import angular from 'angular';

angular.module('frontStreetApp.directives').directive('psSelectAllOnFocus', () => ({
  link(__scope, element) {
    let ignoreNextMouseup = false;
    element
      .on('mouseup', event => {
        if (ignoreNextMouseup) {
          event.preventDefault();
          ignoreNextMouseup = false;
        }
      })
      .on('focus', () => {
        element.select();
        ignoreNextMouseup = true;
      });
  },
}));
