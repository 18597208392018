import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type SendTestEmailMutationParams = {
  widgetHeaderId: Muid;
};

export type SendTestEmailMutationResponse = void;

export const SendTestEmailMutation = {
  key: ['widgets', 'send-test-email'],
  mutationFn: ({ widgetHeaderId, ...params }: SendTestEmailMutationParams) =>
    axiosService
      .getAxios()
      .post(`/1/widgets/${widgetHeaderId}/send-test`, params)
      .then(res => res.data),
};

export const useSendTestEmailMutation = (
  options: UseMutationOptions<SendTestEmailMutationResponse, AxiosError, SendTestEmailMutationParams> = {},
) => {
  return useMutation(SendTestEmailMutation.mutationFn, options);
};
