import React from 'react';

import { CoverImage } from '@process-street/subgrade/core';
import { CDNImage } from 'components/cdn-image';
import { Box, BoxProps } from 'components/design/next';
import { UseCDNImageProps } from 'app/components/cdn-image/use-cdn-image';

export type ChecklistCoverImageProps = { coverImage: CoverImage; cdnImageProps?: Partial<UseCDNImageProps> } & BoxProps;

export const ChecklistCoverImage: React.FC<React.PropsWithChildren<ChecklistCoverImageProps>> = ({
  coverImage,
  cdnImageProps,
  ...props
}) => {
  return (
    <Box data-testid="cover-image-container" height="150px" mx="-5" mb={8} position="relative" {...props}>
      <CDNImage
        alt={coverImage?.s3File.originalName}
        s3File={coverImage.s3File}
        transformation={{
          height: 150,
          width: 676, //max displayed width
        }}
        height="150px"
        {...cdnImageProps}
      />
    </Box>
  );
};
