import * as React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from 'components/design/next';
import {
  isAdmin,
  isCreatedUser,
  isOrganizationMembershipActive,
  OrganizationMembershipStatus,
  OrganizationMembershipWithUser,
} from '@process-street/subgrade/core';
import {
  OrganizationMembershipsQuery,
  UpdateOrganizationMembershipStatusMutation,
} from 'features/organization-memberships/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useInjector } from 'components/injection-provider';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';

export type DeactivateUserMenuProps = {
  organizationMembership: OrganizationMembershipWithUser;
};

export const DeactivateUserMenu: React.FC<React.PropsWithChildren<DeactivateUserMenuProps>> = ({
  organizationMembership,
}) => {
  const modalDisclosure = useDisclosure();
  const toast = useToast();
  const userName = organizationMembership.user.username;
  const queryClient = useQueryClient();
  const { OrganizationMembershipActions } = useInjector('OrganizationMembershipActions');
  const dispatch = useDispatch();
  const menuDisclosure = useDisclosure();

  const currentStatus = organizationMembership.status;
  const isActive = isOrganizationMembershipActive(organizationMembership);
  const otherStatus = isActive ? OrganizationMembershipStatus.Inactive : OrganizationMembershipStatus.Active;

  const currentUserInfoQuery = useGetCurrentUserInfoQuery();
  const isMemberCurrentUser = currentUserInfoQuery.data?.organizationMembership.id === organizationMembership.id;
  const isCurrentUserAdmin = currentUserInfoQuery.data && isAdmin(currentUserInfoQuery.data.organizationMembership);

  const isDeactivationEnabled = isCurrentUserAdmin && currentUserInfoQuery.data && !isMemberCurrentUser;

  const isPendingUser = isCreatedUser(organizationMembership.user);

  const updateOrganizationMembershipStatusMutation = UpdateOrganizationMembershipStatusMutation.useMutation({
    onSuccess: async () => {
      const organizationId = organizationMembership.organization.id;
      toast({
        title: (
          <Box>
            <strong>{userName}</strong> was successfully <strong>{isActive ? 'deactivated' : 'reactivated'}</strong>.
          </Box>
        ),
        status: 'success',
      });
      modalDisclosure.onClose();

      await queryClient.invalidateQueries(OrganizationMembershipsQuery.getKey({ organizationId }));
      dispatch(OrganizationMembershipActions.getAllOrgMembershipByOrgId(organizationId));
    },
    onError: () => {
      toast({
        title: `We're having problems ${isActive ? 'deactivating' : 'reactivating'} the user`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
        status: 'error',
      });
    },
  });
  const isMutationLoading = updateOrganizationMembershipStatusMutation.isLoading;

  const handleToggleStatus = () => {
    updateOrganizationMembershipStatusMutation.mutate({
      id: organizationMembership.id,
      status: otherStatus,
    });
  };

  const modalAction = isActive ? 'Deactivate' : 'Reactivate';
  const helpLink = 'https://www.process.st/help/docs/deactivating-users/';
  const deactivateContent = (
    <Text>
      If you deactivate {userName}, the work they previously completed will be retained for reporting purposes, but they
      will no longer be able to access your organization. Learn more{' '}
      <Link href={helpLink} target="blank" textDecoration="underline">
        here
      </Link>
      .
    </Text>
  );
  const reactivateContent = (
    <Text>
      Restore any previous worfklow run assignments, task assignments and permissions for this user (unless they have
      already been re-assigned).
    </Text>
  );

  if (isPendingUser) {
    return (
      <Text color="gray.500" fontStyle="italic" mr={5}>
        Pending
      </Text>
    );
  }

  if (!isDeactivationEnabled) {
    return (
      <Text color="gray.500" mr={5}>
        {currentStatus}
      </Text>
    );
  }

  if (isMutationLoading) {
    return <Spinner sx={{ color: 'brand.600' }} />;
  }

  return (
    <>
      <Menu autoSelect={false} isLazy={true} {...menuDisclosure}>
        <MenuButton
          as={Button}
          variant="unstyled"
          rightIcon={<Icon icon="angle-down" size="4" variant="far" />}
          title={currentStatus}
          size="sm"
          color="gray.600"
          _focus={{ boxShadow: 'none' }}
          textAlign="right"
          display="inline-flex"
          alignItems="center"
        >
          {currentStatus}
        </MenuButton>

        {menuDisclosure.isOpen ? (
          <MenuList color="gray.600" right={0} zIndex={'dropdown'}>
            {Object.values(OrganizationMembershipStatus).map(status => {
              return (
                <MenuItem
                  onClick={modalDisclosure.onOpen}
                  key={status}
                  data-item-selected={status === currentStatus}
                  disabled={isMutationLoading}
                >
                  {status}
                </MenuItem>
              );
            })}
          </MenuList>
        ) : null}
      </Menu>

      <Modal {...modalDisclosure}>
        <ModalOverlay />
        <ModalContent maxWidth="2xl">
          <ModalHeader>
            <Text as="h3" fontSize="lg" variant="1" fontWeight="bold">
              {modalAction} {userName}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{isActive ? deactivateContent : reactivateContent}</ModalBody>
          <ModalFooter>
            <ButtonGroup spacing="3">
              <Button
                isDisabled={isMutationLoading}
                variant="solid"
                colorScheme="gray"
                onClick={modalDisclosure.onClose}
              >
                Cancel
              </Button>

              <Button
                isLoading={isMutationLoading}
                variant={isActive ? 'danger' : 'primary'}
                onClick={handleToggleStatus}
                loadingText={isActive ? 'Deactivating' : 'Reactivating'}
              >
                {modalAction}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
