import { GetOneOffTasksByChecklistQuery } from 'features/one-off-tasks/query-builder';
import { Checklist } from '@process-street/subgrade/process';
import { QueryClient } from 'react-query';
import { OneOffTask } from '@process-street/subgrade/one-off-task';

function fetchOneOffTasksForChecklist(queryClient: QueryClient, checklistId: Checklist['id']): Promise<OneOffTask[]> {
  return queryClient.fetchQuery({
    queryKey: GetOneOffTasksByChecklistQuery.getKey({ checklistId }),
    queryFn: () => GetOneOffTasksByChecklistQuery.queryFn({ checklistId }),
  });
}

function subscribeToOneOffTasksForChecklist(
  queryClient: QueryClient,
  checklistId: Checklist['id'],
  callback: () => void,
): () => void {
  return queryClient.getQueryCache().subscribe(event => {
    if (!event) return;

    const oneOffQueryKey = GetOneOffTasksByChecklistQuery.getKey({ checklistId }) as Array<string>;

    const queryMatches = event.query.queryKey.join(',') === oneOffQueryKey.join(',');

    if (event.type === 'queryUpdated' && queryMatches && event.query.state.status === 'success') {
      callback();
    }
  });
}

export const OneOffTaskAngularHelper = {
  fetchOneOffTasksForChecklist,
  subscribeToOneOffTasksForChecklist,
};
