import { Flex, HStack, Text, UnorderedList, VStack, StackProps } from 'components/design/next';
import { Muid } from '@process-street/subgrade/core';
import { SavedView } from '@process-street/subgrade/process';
import * as React from 'react';
import { useSelectedDataSetContext, useSelectedSavedViewContext } from '../../context';
import { CreateSavedViewButton } from '../create-saved-view-button';
import { DataSetSavedViewsSidebarMenuItem } from './menu-item';
import { MenuItemSkeleton } from './menu-item-skeleton';

export type DataSetSavedViewsSidebarProps = Omit<StackProps, 'onChange'> & {
  savedViews: SavedView[];
  dataSetId?: Muid;
  isLoading?: boolean;
  onChange?: (savedView: SavedView) => void;
};

export const DataSetSavedViewsSidebar: React.FC<React.PropsWithChildren<DataSetSavedViewsSidebarProps>> = ({
  savedViews,
  dataSetId,
  isLoading,
  onChange,
  ...props
}) => {
  const [selectedSavedView] = useSelectedSavedViewContext();
  const [selectedDataSet] = useSelectedDataSetContext();

  const savedViewId = selectedSavedView?.id;
  const canCreateSavedView = Boolean(selectedDataSet?.canAccessDataSet);

  return (
    <VStack w="full" spacing="2" as="aside" bgColor="gray.50" p="4" maxW="270px" aria-busy={isLoading} {...props}>
      <HStack w="full" pl="4" pr="1" justifyContent="space-between">
        <Text fontWeight="700" variant="-2u" color="gray.500">
          Data Set saved views
        </Text>

        {selectedDataSet && (
          <CreateSavedViewButton dataSet={selectedDataSet} isDisabled={!canCreateSavedView} variant="icon" />
        )}
      </HStack>

      <UnorderedList w="full" listStyleType="none" ml="0">
        {!isLoading &&
          savedViews.map(savedView => (
            <DataSetSavedViewsSidebarMenuItem
              dataSet={selectedDataSet}
              onSelect={savedView => onChange?.(savedView)}
              isSelected={savedView.id === savedViewId}
              key={savedView.id}
              savedView={savedView}
            />
          ))}

        {(savedViews.length === 0 || isLoading) && (
          <Flex justifyContent="center" position="relative">
            <VStack opacity="0.7" spacing={2} w="full">
              <MenuItemSkeleton isLoaded={!isLoading} />
              <MenuItemSkeleton isLoaded={!isLoading} />
              <MenuItemSkeleton isLoaded={!isLoading} />
              <MenuItemSkeleton isLoaded={!isLoading} />
            </VStack>
          </Flex>
        )}
      </UnorderedList>
    </VStack>
  );
};
