import { Muid, MuidUtils } from '@process-street/subgrade/core';
import { InboxItemUtils } from '@process-street/subgrade/inbox';
import { TasksTableUtils } from '../components/tasks-table/tasks-table-utils';

const NEW_BUTTON_ID = MuidUtils.randomMuid();

export function getRowId(item: TasksTableUtils.TasksTableItem): Muid {
  if (TasksTableUtils.isLoadingRow(item)) return item.id;

  if (TasksTableUtils.isNewTaskRow(item)) return item.id;

  if (!TasksTableUtils.isInboxItemRow(item)) {
    return NEW_BUTTON_ID;
  }

  return InboxItemUtils.getUniqueId(item)!;
}
