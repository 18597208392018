import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { ConsolidatedTaskPermit } from '@process-street/subgrade/process';

export namespace GetConsolidatedTaskPermitsByTaskIdsQuery {
  export type Params = { taskIds: Muid[] };

  export type Response = ConsolidatedTaskPermit[];

  export const key = ['task', 'permissions', 'consolidated'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) => {
    const taskIds = params.taskIds.join(',');
    return axiosService
      .getAxios()
      .get<Response>(`/1/tasks/permissions/consolidated`, {
        params: {
          taskIds,
        },
      })
      .then(res => res.data);
  };

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: params.taskIds.length > 0 && options.enabled !== false,
    });
  };
}
