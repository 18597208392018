import { HStack, VStack, Button } from '@chakra-ui/react';
import { TemplateActivity } from '@process-street/subgrade/activity';
import { Muid } from '@process-street/subgrade/core';
import { useGetAllActivitiesByTypeInfiniteQuery } from 'features/activities/query-builder';
import { ActivitySentence } from 'pages/organizations/manage/activity/sentence';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { InterpretedActivity } from 'services/activities/interpreters/InterpreterContext';
import { TemplateActivityUtils } from 'services/activities/template-activity-service-utils';

export type ActivityProps = {
  templateId: Muid;
};

export const Activity = ({ templateId }: ActivityProps) => {
  const currentUser = useSelector(SessionSelector.getCurrentUser);
  const activityQuery = useGetAllActivitiesByTypeInfiniteQuery(
    {
      type: 'template',
      templateId,
      limit: 20,
    },
    {
      keepPreviousData: true,
      getNextPageParam: lastPage => ({
        offsetId: lastPage.data[lastPage.data.length - 1].id,
        templateId,
        limit: 20,
        type: 'template',
      }),
    },
  );

  const pages = activityQuery.data?.pages ?? [];
  const lastPage = pages[pages.length - 1];

  // Aggregate all sentences from each loaded page.
  const allSentences =
    activityQuery.data?.pages.flatMap(page =>
      page.data.map(activity =>
        TemplateActivityUtils.interpret(activity as TemplateActivity, {
          timeZone: currentUser?.timeZone,
          extended: false,
        }),
      ),
    ) ?? [];

  return (
    <VStack w="full" spacing="0">
      {allSentences
        .filter((sentence): sentence is InterpretedActivity => Boolean(sentence))
        .map(sentence => (
          <ActivitySentence key={sentence.activity.id} sentence={sentence} />
        ))}

      {allSentences.length < lastPage?.total && (
        <HStack w="full" justifyContent="center">
          <Button mt="4" onClick={() => activityQuery.fetchNextPage()} variant="link">
            Load more
          </Button>
        </HStack>
      )}
    </VStack>
  );
};
