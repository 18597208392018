import { Muid } from '@process-street/subgrade/core';
import { isHeading, Task, TaskStatus, TaskTemplate } from '@process-street/subgrade/process';
import { BaseTaskSelector, BaseTaskTemplateSelector } from '@process-street/subgrade/redux/selector';
import { BaseReduxState, ObjectMap } from '@process-street/subgrade/redux/types';
import { createSelector, Selector } from 'reselect';
import { OptimisticResultBuilder } from '../..';

export interface StopTaskEngine {
  applyChangesOnTaskUpdate(updatedTask: Task, resultBuilder: OptimisticResultBuilder, state: BaseReduxState): void;
}

class StopTaskEngineImpl implements StopTaskEngine {
  private taskTemplates: TaskTemplate[];

  constructor(taskTemplates: TaskTemplate[]) {
    this.taskTemplates = taskTemplates;
  }

  public getTasksByTaskTemplateId = (checklistRevisionId: Muid, state: BaseReduxState) => {
    const tasks = BaseTaskSelector.getAllByChecklistRevisionId(checklistRevisionId)(state);
    const taskByTaskTemplateId: ObjectMap<Task> = tasks.reduce((agg: ObjectMap<Task>, task) => {
      agg[task.taskTemplate.id] = task;
      return agg;
    }, {});
    return taskByTaskTemplateId;
  };

  public getFirstStopIndex = (taskByTaskTemplateId: ObjectMap<Task>) => {
    const firstStopIndex = this.taskTemplates.findIndex(taskTemplate => {
      if (!(taskTemplate.stop && !isHeading(taskTemplate))) {
        return false;
      }

      const relatedTask = taskByTaskTemplateId[taskTemplate.id];
      return relatedTask && !relatedTask.hidden && relatedTask.status === TaskStatus.NotCompleted;
    });
    return firstStopIndex;
  };

  public applyChangesOnTaskUpdate = (
    updatedTask: Task,
    resultBuilder: OptimisticResultBuilder,
    state: BaseReduxState,
  ): void => {
    const relevantTaskTemplate = BaseTaskTemplateSelector.getById(updatedTask.taskTemplate.id)(state);
    if (!relevantTaskTemplate || !relevantTaskTemplate.stop) {
      return;
    }

    const taskByTaskTemplateId = this.getTasksByTaskTemplateId(updatedTask.checklistRevision.id, state);
    taskByTaskTemplateId[updatedTask.taskTemplate.id] = updatedTask;
    const firstStopIndex = this.getFirstStopIndex(taskByTaskTemplateId);

    // Mark all task as non stoped before the first task and stoped after firstStopIndex
    this.taskTemplates.forEach((taskTemplate, i) => {
      const task = taskByTaskTemplateId[taskTemplate.id];
      const taskStopped = firstStopIndex !== -1 && i >= firstStopIndex;
      if (!task.hidden && task.stopped !== taskStopped) {
        const stoppedTask = { id: task.id, stopped: taskStopped };
        resultBuilder.task.appendUpdateEvent(stoppedTask, task);
      }
    });
  };
}

const getByTemplateRevisionId = (templateRevisionId: Muid): Selector<BaseReduxState, StopTaskEngine> =>
  createSelector(BaseTaskTemplateSelector.getAllByTemplateRevisionId(templateRevisionId), taskTemplates => {
    return new StopTaskEngineImpl(taskTemplates);
  });

export const StopTaskEngineFactory = {
  getByTemplateRevisionId,
};
