import { TaskConstants, TaskTemplate } from '@process-street/subgrade/process';
import { Box, Icon, Tooltip } from 'components/design/next';
import * as React from 'react';

export interface ContentFieldRecentlyMovedIndicatorProps {
  from: TaskTemplate;
}

export const ContentFieldRecentlyMovedIndicator: React.FC<
  React.PropsWithChildren<ContentFieldRecentlyMovedIndicatorProps>
> = ({ from }) => {
  return (
    <Tooltip label={`Content block moved from ${from.name ?? TaskConstants.DefaultStepName}`}>
      <Box position="absolute" color="brand.500" top="-3" left="-2">
        <Icon icon="circle" variant="fas" size="2" />
      </Box>
    </Tooltip>
  );
};
