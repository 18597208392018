import { queryClient } from 'components/react-root';
import { GetInvitationQuery } from 'features/invitations/query-builder';

export class InvitationApi {
  constructor(Invitations, $q) {
    'ngInject';

    this.Invitations = Invitations;
    this.$q = $q;
  }

  create = (email, resource, opts) =>
    this.Invitations.create({
      email,
      resource,
      username: opts.username,
      avatarUrl: opts.avatarUrl,
      provider: opts.provider,
      role: opts.role,
    }).$promise;

  getById = id =>
    this.$q((resolve, reject) =>
      queryClient
        .fetchQuery({
          queryKey: GetInvitationQuery.getKey({ invitationId: id }),
          queryFn: () => GetInvitationQuery.queryFn({ invitationId: id }),
        })
        .then(resolve)
        .catch(reject),
    );

  resend = (userId, organizationId) => this.Invitations.resend({ userId, organizationId }).$promise;
}
