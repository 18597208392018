import { Organization } from '@process-street/subgrade/core';
import { useGetOrganizationQuery } from 'features/organization/query-builder';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';

export const useSelectedOrganization = (): Organization | undefined => {
  const userInfoQuery = useGetCurrentUserInfoQuery();

  const { data: selectedOrganization } = useGetOrganizationQuery({
    organizationId: userInfoQuery.data?.organizationMembership?.organization.id,
  });

  return selectedOrganization;
};
