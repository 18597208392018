import angular from 'angular';
import templateUrl from './edit-container.component.html';
import './edit-container.component.scss';

export const ApprovalRulesEditContainerComponent: angular.IComponentOptions = {
  bindings: {
    onToViewMode: '&',
    taskTemplate: '<',
    templateRevision: '<',
  },
  controller: class ApprovalRulesEditContainerController {},
  templateUrl,
};
