import { createCachedAction } from 'reducers/util';

export const INBOX_GET_ALL_BY_ORGANIZATION_ID = 'inbox/GET_ALL_BY_ORGANIZATION_ID';
export const INBOX_GET_STATS_BY_ORGANIZATION = 'inbox/GET_STATS_BY_ORGANIZATION';

export const InboxActions = InboxApi => {
  'ngInject';

  const getAllByOrganizationId = createCachedAction(
    INBOX_GET_ALL_BY_ORGANIZATION_ID,
    InboxApi.getAllByOrganizationId,
    (organizationId, filters, offset) => ({ organizationId, filters, offset }),
  );

  const getInboxStatsByOrganization = createCachedAction(
    INBOX_GET_STATS_BY_ORGANIZATION,
    InboxApi.getInboxStatsByOrganization,
    (organizationId, userId, itemsType) => ({ organizationId, userId, itemsType, flushCache: true }),
  );

  return {
    getAllByOrganizationId,
    getInboxStatsByOrganization,
  };
};
