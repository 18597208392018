import { TaskTemplate } from '@process-street/subgrade/process';
import { AutomationInstance, AutomationInstanceUtils } from '@process-street/subgrade/automation';
import { Text } from 'components/design/next';
import * as React from 'react';

interface TaskSolutionInstanceLabelProps {
  automationInstance: AutomationInstance;
  taskTemplate?: TaskTemplate;
}

export function TaskAutomationInstanceLabel({ automationInstance, taskTemplate }: TaskSolutionInstanceLabelProps) {
  return (
    <Text textAlign="center" variant="-2">
      When{' '}
      <Text as="span" fontWeight="bold" variant="-2">
        {taskTemplate?.name || 'a task'}
      </Text>{' '}
      is checked {AutomationInstanceUtils.getLabel(automationInstance)}
    </Text>
  );
}
