import { StateService } from '@uirouter/angularjs';
import { State } from 'services/temp-data-service.interface';

export const RELEVANT_MIGRATION_STATES = ['templateView', 'checklist', 'dashboard'];

export const redirectToRelevantState = ({
  $state,
  returnState,
  isReactWorkflowEditorEnabled = false,
}: {
  $state: StateService;
  returnState?: State;
  isReactWorkflowEditorEnabled?: boolean;
}) => {
  if (returnState && stateStartsWith(returnState)('checklist')) {
    $state.go(returnState.state.name, returnState.params);
  } else {
    // PS-5421 - check if the user is on template edit task page before redirecting as he might have
    // already navigated away.
    if (($state.includes('template') && $state.params.id) || isReactWorkflowEditorEnabled) {
      $state.go('templateDashboard', { id: $state.params.id });
    }
  }
};

const stateStartsWith =
  (state: State) =>
  (str = '') => {
    return state.state.name.startsWith(str);
  };

export const getFirstStateMatching = (states: State[], ...filters: string[]) => {
  return states.find(stateInfo => filters.some(stateStartsWith(stateInfo)));
};
