import * as React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Icon,
  IconButton,
  IconButtonProps,
  IconProps,
  Link,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  VStack,
} from 'components/design/next';
import { useGetDataSetLinksQuery } from 'pages/reports/data-sets/query-builder/get-data-set-links';
import { useGetSavedViewLinksQuery } from 'pages/reports/data-sets/query-builder/get-saved-view-links';
import {
  DataSet,
  DataSetLinkWithNames,
  SavedView,
  TemplateStatus,
  TemplateType,
} from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { useGetAllTemplatesQuery } from 'features/template/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import groupBy from 'lodash/groupBy';
import _keyBy from 'lodash/keyBy';
import pluralize from 'pluralize';
import { useDataSetViewMode, ViewMode } from 'pages/reports/data-sets/hooks/use-data-set-view-mode';

export type LinkedWorkflowsButtonProps = Partial<IconButtonProps> & {
  dataSetId: DataSet['id'];
  savedViewId?: SavedView['id'];
  iconProps?: Partial<IconProps>;
};

interface DataSetLinkWithNamesWithPermission extends DataSetLinkWithNames {
  isPermitted: boolean;
}

export const LinkedWorkflowsButton: React.FC<React.PropsWithChildren<LinkedWorkflowsButtonProps>> = ({
  dataSetId,
  savedViewId,
  iconProps,
  ...buttonProps
}) => {
  const viewMode = useDataSetViewMode();

  const dataSetLinks = useGetDataSetLinksQuery(
    { dataSetId },
    {
      enabled: viewMode === ViewMode.DataSet,
    },
  );

  const savedViewLinks = useGetSavedViewLinksQuery(
    { dataSetId, savedViewId },
    {
      enabled: viewMode === ViewMode.SavedView,
    },
  );

  const allLinks = savedViewId
    ? [...(savedViewLinks.data ?? [])]
    : [...(dataSetLinks.data ?? []), ...(savedViewLinks.data ?? [])];

  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const templatesQuery = useGetAllTemplatesQuery(
    {
      organizationId: organizationId!,
      templateType: TemplateType.Playbook,
      templateStatus: TemplateStatus.Active,
    },
    { enabled: Boolean(organizationId) },
  );

  const allowedTemplateTypes = [TemplateType.Playbook];
  const allFilteredLinks = allLinks.filter(link => allowedTemplateTypes.includes(link.templateType));

  const templateIds = React.useMemo(() => (templatesQuery.data ?? []).map(t => t.id), [templatesQuery.data]);

  const isLoading = dataSetLinks.isLoading || savedViewLinks.isLoading || templatesQuery.isLoading;
  const hasLinkTemplates = allFilteredLinks.length > 0;

  const linksWithPermissions: DataSetLinkWithNamesWithPermission[] = (allFilteredLinks ?? []).map(link => ({
    isPermitted: templateIds.includes(link.templateId),
    ...link,
  }));

  return !isLoading && hasLinkTemplates ? (
    <ConnectedButton links={linksWithPermissions} iconProps={iconProps} {...buttonProps} />
  ) : (
    <DisconnectedButton isLoading={isLoading} iconProps={iconProps} {...buttonProps} />
  );
};

const buttonStyles: Partial<ButtonProps> = {
  variant: 'ghost',
  borderRadius: '4px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'gray.300',
};

function ConnectedButton({
  links,
  iconProps,
  ...props
}: Partial<IconButtonProps> & { links: DataSetLinkWithNamesWithPermission[]; iconProps?: Partial<IconProps> }) {
  const viewMode = useDataSetViewMode();

  const linksByTemplateId = groupBy(links, l => l.templateId);
  const linkedTemplatesCount = Object.keys(linksByTemplateId).length;

  const label = (
    <>
      {linkedTemplatesCount} <strong>{pluralize('Workflow', linkedTemplatesCount)}</strong> connected to this{' '}
      {viewMode === ViewMode.DataSet ? 'data set' : 'saved view'}
    </>
  );

  const { $state } = useInjector('$state');

  const handleGoto = (id: DataSetLinkWithNames['templateId']) => {
    $state.go('templateView', { id });
  };

  return (
    <Menu>
      <Tooltip hasArrow label={label}>
        <Box w="fit-content">
          <MenuButton
            as={Button}
            aria-label="Connected Workflows"
            color="purple.500"
            {...buttonStyles}
            {...props}
            leftIcon={<Icon variant="fas" icon="workflow" size="4" {...iconProps} />}
          >
            {linkedTemplatesCount}
          </MenuButton>
        </Box>
      </Tooltip>
      <MenuList zIndex={10}>
        <MenuGroup>
          <Text variant="-2u" color="gray.500" py={2} px={4}>
            Workflow connections
          </Text>
          <VStack w="full" alignItems="flex-start">
            {Object.entries(linksByTemplateId).map(([_, links]) => (
              <Tooltip
                isDisabled={links[0].isPermitted}
                label="You don't have permission to view this workflow."
                key={links[0].templateId}
              >
                <MenuItem
                  isDisabled={!links[0].templateId || !links[0].isPermitted}
                  onClick={() => handleGoto(links[0].templateId)}
                  icon={<Icon color="purple.500" variant="fas" icon="workflow" size="4" />}
                  w="full"
                >
                  {links[0].templateName} ({links.length})
                </MenuItem>
              </Tooltip>
            ))}
          </VStack>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}

function DisconnectedButton({
  isLoading,
  iconProps,
  ...props
}: Partial<IconButtonProps> & { isLoading: boolean; iconProps?: Partial<IconProps> }) {
  const viewMode = useDataSetViewMode();
  const helpDocsUrl = 'https://www.process.st/help/docs/data-sets/';

  const label = (
    <>
      No <strong>Workflows</strong> connected to this {viewMode === ViewMode.DataSet ? 'data set' : 'saved view'}
    </>
  );

  return (
    <Menu>
      <Tooltip isDisabled={isLoading} hasArrow label={label}>
        <Box w="fit-content">
          <MenuButton
            onFocus={e => e.preventDefault()}
            as={IconButton}
            disabled={isLoading}
            aria-label={isLoading ? 'Loading' : 'No Connected Workflows'}
            color="gray.500"
            {...buttonStyles}
            {...props}
            icon={<Icon variant="fas" icon="workflow" size="4" {...iconProps} />}
          />
        </Box>
      </Tooltip>
      <MenuList w={75} p={4} zIndex={10}>
        <Text variant="-2u" color="gray.500">
          Workflow connections (0)
        </Text>
        <VStack mt={4}>
          <Icon
            variant="fad"
            icon="plug"
            primaryLayer={{ color: 'brand.300', opacity: 1 }}
            secondaryLayer={{ color: 'brand.300', opacity: 0.4 }}
            size="15"
          />
        </VStack>
        <Text color="gray.600" mt={5}>
          Did you know you can connect Data Sets to <strong>Workflow</strong> form fields?
        </Text>
        <Text color="gray.500" mt={1}>
          Learn more{' '}
          <Link href={helpDocsUrl} target="_blank" textDecoration="underline">
            here
          </Link>
          .
        </Text>
      </MenuList>
    </Menu>
  );
}
