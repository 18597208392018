import * as React from 'react';
import { DataSet, SavedView, SavedViewType } from '@process-street/subgrade/process';
import { Tab, Text, Tooltip } from 'components/design/next';
import { UpdateSavedViewButton } from 'pages/reports/data-sets/components/update-saved-view-button';

type SavedViewTabItemProps = {
  selectedDataSet: DataSet;
  savedView: SavedView;
  onClick: () => void;
  isSelected: boolean;
};

export const SavedViewTabItem: React.FC<SavedViewTabItemProps> = ({
  selectedDataSet,
  savedView,
  onClick,
  isSelected,
}) => (
  <Tab onClick={onClick} paddingX={2}>
    <Tooltip label={savedView.name}>
      <Text maxW="160px" textAlign="left" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
        {savedView.name}
      </Text>
    </Tooltip>

    {isSelected && savedView.savedViewType === SavedViewType.Standard && (
      <UpdateSavedViewButton dataSet={selectedDataSet} savedView={savedView} />
    )}
  </Tab>
);
