import * as React from 'react';
import { match } from 'ts-pattern';
import { DateFormFieldWidget, FieldType, Widget } from '@process-street/subgrade/process';
import { TextSettings } from './text-settings';
import { TextareaSettings } from './textarea-settings';
import { NumberSettings } from './number-settings';
import { FileSettings } from './file-settings';
import { EmailSettings } from './email-settings';
import { DateSettings } from './date-settings';
import { SendRichEmailSettings } from './send-rich-email-settings';
import { UrlSettings } from './url-settings';
import { HiddenSettings } from './hidden-settings';
import { useSelector } from '@xstate/react';
import { getWidgetModalsMachineService, Selectors } from 'features/widgets/widget-modals-machine';
import { Modal, ModalOverlay } from 'components/design/next';
import { useWidgetSettingsContext, WidgetSettingsProvider } from './widget-settings-context';
import { useFeatureFlag } from 'features/feature-flags';
import { DateSettingsWithDynamicParametersModalContent } from './date-settings/date-settings-with-dynamic-parameters';

type FormFieldSettingsProps = {
  onUpdate: (widget: Widget) => Promise<Widget>;
};

const actor = getWidgetModalsMachineService();

export const FormFieldSettings: React.FC<React.PropsWithChildren<FormFieldSettingsProps>> = ({ onUpdate }) => {
  const isCustomDateValidationWithDynamicParametersEnabled = useFeatureFlag(
    'customDateValidationWithDynamicParameters',
  );
  const onClose = () => {
    actor.send({ type: 'CLOSE_SETTINGS' });
  };
  const isOpen = useSelector(actor, Selectors.getIsOpen('settings'));
  const widget = useSelector(actor, Selectors.getWidget);

  if (!widget) return null;

  return (
    <WidgetSettingsProvider onUpdate={onUpdate} widget={widget}>
      <Modal isOpen={isOpen} size="xl" onClose={onClose}>
        <ModalOverlay />

        {match(widget)
          .with({ fieldType: FieldType.Text }, () => <TextSettings />)
          .with({ fieldType: FieldType.Textarea }, () => <TextareaSettings />)
          .with({ fieldType: FieldType.Number }, () => <NumberSettings />)
          .with({ fieldType: FieldType.File }, () => <FileSettings />)
          .with({ fieldType: FieldType.Email }, () => <EmailSettings />)
          .with({ fieldType: FieldType.Date }, () =>
            isCustomDateValidationWithDynamicParametersEnabled ? <DateSettingsContent /> : <DateSettings />,
          )
          .with({ fieldType: FieldType.Url }, () => <UrlSettings />)
          .with({ fieldType: FieldType.Hidden }, () => <HiddenSettings />)
          .with({ fieldType: FieldType.SendRichEmail }, () => <SendRichEmailSettings />)
          .otherwise(() => null)}
      </Modal>
    </WidgetSettingsProvider>
  );
};

const DateSettingsContent = () => {
  const { widget, onUpdate } = useWidgetSettingsContext<DateFormFieldWidget>();

  return <DateSettingsWithDynamicParametersModalContent widget={widget} onUpdate={onUpdate} />;
};
