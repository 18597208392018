import { dayjs as moment } from '@process-street/subgrade/util';

export enum ConfettiTheme {
  ProcessStreet = 'ProcessStreet',
  Halloween = 'Halloween',
  Christmas = 'Christmas',
}

export const ConfettiThemeColorsMap = {
  [ConfettiTheme.ProcessStreet]: ['#2c98e3', '#Fc5e77', '#3fcea3'],
  [ConfettiTheme.Halloween]: ['#ff9a00', '#000000', '#c900ff'],
  [ConfettiTheme.Christmas]: ['#ce0d0d', '#0ca90c', '#ffd700'],
};

export function determineThemeByDate(date: Date) {
  const thisWeek = moment(date).week();
  const halloweenWeek = moment(date)
    .month(10 - 1)
    .date(31)
    .week();
  const christmasWeek = moment(date)
    .month(12 - 1)
    .date(25)
    .week();
  if (thisWeek === halloweenWeek) {
    return ConfettiTheme.Halloween;
  } else if (thisWeek === christmasWeek) {
    return ConfettiTheme.Christmas;
  } else {
    return ConfettiTheme.ProcessStreet;
  }
}
