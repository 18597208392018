import * as React from 'react';
import { FormEvent } from 'react';
import {
  Alert,
  AlertIcon,
  Button,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
} from 'components/design/next';
import { QueryObserverResult } from 'react-query';
import { SavedView } from '@process-street/subgrade/process';
import { MuidUtils } from '@process-street/subgrade/core';
import { DeleteSavedViewButton } from '../update-saved-view-button/delete-saved-view-button';
import { CreateSavedViewParams } from '../../query-builder';
import { useFeatureFlag } from 'features/feature-flags';

export type OnSubmitCreateSavedViewParams = Pick<CreateSavedViewParams, 'name' | 'columns'> & {
  id: SavedView['id'];
};

export type SavedViewFormModalProps = {
  isOpen: boolean;
  initialValues?: Partial<SavedView>;
  status: QueryObserverResult['status'];
  deleteStatus?: QueryObserverResult['status'];
  submitButtonText?: string;
  submitButtonLoadingText?: string;
  title?: string;
  onSubmit?: (value: OnSubmitCreateSavedViewParams) => void;
  onClose: () => void;
  onDelete?: () => void;
  hasLinkedTemplates?: boolean;
};

export const SavedViewFormModal: React.FC<React.PropsWithChildren<SavedViewFormModalProps>> = ({
  isOpen,
  initialValues,
  submitButtonText = 'Create',
  submitButtonLoadingText = 'Creating...',
  title = 'New Saved View',
  onClose,
  onSubmit,
  onDelete,
  status,
  deleteStatus,
  hasLinkedTemplates = false,
}) => {
  const [value, setValue] = React.useState<string>(initialValues?.name ?? '');
  const isSaving = status === 'loading';
  const isDeleting = deleteStatus === 'loading';
  const isLoading = isSaving || isDeleting;
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const payload: OnSubmitCreateSavedViewParams = {
      id: initialValues?.id ?? MuidUtils.randomMuid(),
      name: value,
      columns: initialValues?.columns ?? [],
    };

    onSubmit?.(payload);
    setValue('');
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={isLoading ? () => {} : handleClose}>
      <ModalOverlay />
      <ModalContent maxW="lg" mt="20" as="form">
        <ModalHeader border="0" borderBottomWidth="1px" borderStyle="solid" borderColor="gray.200">
          <HStack spacing="2">
            <Icon icon="cog" size="4" color="gray.400" />

            <Text size="xl" color="gray.700">
              {title}
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />

        <ModalBody pt="6" pb="4">
          <VStack alignItems="stretch" spacing="5">
            {!isDataSetsV2Enabled && hasLinkedTemplates && (
              <Alert fontWeight="700" color="gray.700" status="error">
                <AlertIcon />
                This Saved View is being used in a workflow.
              </Alert>
            )}
            <VStack alignItems="flex-start">
              <Text fontSize="sm" as="label" htmlFor="name">
                Saved View name
              </Text>
              <Input
                isDisabled={isLoading}
                value={value}
                w="full"
                id="name"
                name="name"
                onChange={e => setValue(e.target.value)}
                placeholder="Type name here"
                color="gray.600"
                _placeholder={{ color: 'gray.400' }}
                autoFocus
              />
            </VStack>
          </VStack>
        </ModalBody>

        <ModalFooter pb="6" spacing="0" justifyContent="space-between" as={HStack}>
          {onDelete ? (
            <DeleteSavedViewButton isDisabled={isSaving} status={deleteStatus} onDelete={onDelete} />
          ) : (
            <Spacer />
          )}

          <HStack spacing="0">
            <Button
              variant="solid"
              colorScheme="gray"
              mr={3}
              onClick={handleClose}
              isDisabled={isLoading}
              fontWeight="500"
            >
              Cancel
            </Button>
            <Button
              isLoading={isSaving}
              isDisabled={isLoading || !value || value === initialValues?.name}
              onClick={handleSubmit}
              type="submit"
              colorScheme="brand"
              variant="solid"
              loadingText={submitButtonLoadingText}
              fontWeight="500"
            >
              {submitButtonText}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
