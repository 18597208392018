import angular from 'angular';
import { ElevioUtils } from 'services/interop/elevio-utils';

// remove this Angular service and use ElevioUtils
angular.module('frontStreetApp.services').factory('ElevioService', $window => {
  function changeUser(user, userHash) {
    const elevioUser = {
      email: user.email,
      user_hash: userHash,
      traits: ElevioUtils.toElevioTraits(user),
    };
    if ($window._elev.changeUser) {
      // If Elevio is already loaded, use the change user method
      delete $window._elev.user;
      $window._elev.changeUser(elevioUser);
    } else {
      // Otherwise, set the user property so it'll grab it once loaded
      $window._elev.user = elevioUser;
    }
  }

  function logoutUser() {
    if ($window._elev.logoutUser) {
      $window._elev.logoutUser();
    } else {
      // If there is a user queued to login, delete it
      delete $window._elev.user;
    }
  }

  function openElevioArticles() {
    $window._elev.openModule('articles');
  }

  function setMainColor(color) {
    $window._elev.setSettings?.({ main_color: color });
  }

  return {
    changeUser,
    logoutUser,
    openElevioArticles,
    setMainColor,
  };
});
