import { AuditMetadata, Muid, Ref } from '../core';

export interface PlanFeatureSet {
  readonly id: Muid;
  name: string;
  usersLimit?: number;
  activeChecklistsLimit?: number;
  activeTemplatesLimit?: number;
  checklistRunsLimit?: number;
  softCap: boolean;
}

export enum PlanInterval {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export enum PlanTrack {
  Freemium = 'Freemium',
  FreeTrial = 'FreeTrial',
  Legacy = 'Legacy',
}

const OrderedPlanIntervals: PlanInterval[] = [PlanInterval.Monthly, PlanInterval.Yearly];
export const PlanIntervalUtil = {
  all(): PlanInterval[] {
    return OrderedPlanIntervals;
  },
};

export enum PlanLevel {
  Free = 'Free',
  Business = 'Business',
  BusinessPro = 'BusinessPro',
  Enterprise = 'Enterprise',
  Basic = 'Basic',
  Standard = 'Standard',
  Professional = 'Professional',
}

const OrderedPlanLevels: PlanLevel[] = [
  PlanLevel.Free,
  PlanLevel.Basic,
  PlanLevel.Business,
  PlanLevel.Standard,
  PlanLevel.BusinessPro,
  PlanLevel.Professional,
  PlanLevel.Enterprise,
];

const CurrentPlanLevels: PlanLevel[] = [
  PlanLevel.Free,
  PlanLevel.Basic,
  PlanLevel.Standard,
  PlanLevel.Professional,
  PlanLevel.Enterprise,
];

const CurrentPlanTracks: PlanTrack[] = Object.values(PlanTrack);

export const PlanLevelUtil = {
  rank(level: PlanLevel): number {
    return OrderedPlanLevels.indexOf(level);
  },

  all(): PlanLevel[] {
    return CurrentPlanLevels;
  },
};

export const PlanTrackUtil = {
  all(): PlanTrack[] {
    return CurrentPlanTracks;
  },
};

export interface Plan {
  readonly id: Muid;
  audit: AuditMetadata;
  featureSet: Ref<PlanFeatureSet>;
  name: string;
  level: PlanLevel;
  interval: PlanInterval;
  cost: number;
  maxUsers?: number;
  active: boolean;
  minQuantity: number;
}

export const PlanTrackPaidPlans: Record<PlanTrack, PlanLevel[]> = {
  [PlanTrack.FreeTrial]: [PlanLevel.Basic, PlanLevel.Standard, PlanLevel.Professional, PlanLevel.Enterprise],
  [PlanTrack.Legacy]: [],
  [PlanTrack.Freemium]: [PlanLevel.Professional, PlanLevel.Enterprise],
};
