import { Selector } from 'reselect';
import { Muid, Option } from '../../core';
import { OrderTreeUtils, TaskStats } from '../../process';
import { safeEntityMapToArrayByIdsWith } from '../safe-entity-map-to-array-by-ids';
import { BaseReduxState, BaseTaskStatsLookups, EntityMap, ImmutableLookupMap } from '../types';
import { SelectorFactories } from './factories/selector-factories';
import { BaseNavigationSelector } from './navigation.selectors';

const getEntityMap: Selector<BaseReduxState, EntityMap<TaskStats>> = (state: BaseReduxState) =>
  state.entities.taskStats;

const getLookups = (state: BaseReduxState): BaseTaskStatsLookups => state.lookups.taskStats;

const getLookupByChecklistId = (state: BaseReduxState): Readonly<ImmutableLookupMap> => getLookups(state).byChecklistId;

const getLookupByChecklistRevisionId = (state: BaseReduxState): Readonly<ImmutableLookupMap> =>
  getLookups(state).byChecklistRevisionId;

const getAllForCurrentChecklistId = SelectorFactories.getEntitiesArrayByLookupId(
  BaseNavigationSelector.getCurrentChecklistId,
  getEntityMap,
  getLookupByChecklistId,
);

const getAllForCurrentChecklistRevisionId = SelectorFactories.getEntitiesArrayByLookupId(
  BaseNavigationSelector.getCurrentChecklistRevisionId,
  getEntityMap,
  getLookupByChecklistRevisionId,
);

const getEntityMapForCurrentChecklistId = SelectorFactories.getEntitiesMapByLookupId(
  BaseNavigationSelector.getCurrentChecklistId,
  getEntityMap,
  getLookupByChecklistId,
);

const getEntityMapForCurrentChecklistRevisionId = SelectorFactories.getEntitiesMapByLookupId(
  BaseNavigationSelector.getCurrentChecklistRevisionId,
  getEntityMap,
  getLookupByChecklistRevisionId,
);

const getByTaskId =
  (taskId: Muid): Selector<BaseReduxState, Option<TaskStats>> =>
  (state: BaseReduxState) =>
    state.entities.taskStats[taskId];

const getAllByChecklistRevisionId =
  (checklistRevisionId: Muid): Selector<BaseReduxState, TaskStats[]> =>
  (state: BaseReduxState) =>
    safeEntityMapToArrayByIdsWith(
      state.entities.taskStats,
      getLookupByChecklistRevisionId(state)[checklistRevisionId] ?? [],
    );

const getAllByChecklistId =
  (checklistId: Muid): Selector<BaseReduxState, TaskStats[]> =>
  (state: BaseReduxState) =>
    safeEntityMapToArrayByIdsWith(state.entities.taskStats, state.lookups.taskStats.byChecklistId[checklistId] ?? []);

const getAllOrderedWithOrderTreeByChecklistId =
  (checklistId: Muid): Selector<BaseReduxState, TaskStats[]> =>
  (state: BaseReduxState) => {
    const taskStats = getAllByChecklistId(checklistId)(state);
    return taskStats.sort((a: TaskStats, b: TaskStats) => OrderTreeUtils.compare(a.orderTree, b.orderTree));
  };

export const BaseTaskStatsSelector = {
  getAllByChecklistId,
  getAllByChecklistRevisionId,
  getAllOrderedWithOrderTreeByChecklistId,
  getAllForCurrentChecklistId,
  getAllForCurrentChecklistRevisionId,
  getByTaskId,
  getEntityMap,
  getLookupByChecklistId,
  getLookupByChecklistRevisionId,
  getEntityMapForCurrentChecklistId,
  getEntityMapForCurrentChecklistRevisionId,
};
