import * as React from 'react';
import { Box, Text, Center, Image, Input, VStack, Spinner } from 'components/design/next';
import { ImageWidget } from '@process-street/subgrade/process';
import { useMutation } from 'react-query';
import * as WidgetApi from 'pages/pages/_id/edit/page/utils/widget.api';
import { useDebouncedCallback } from 'use-debounce';

export const ImageUI: React.FC<React.PropsWithChildren<ImageWidget & { editable?: boolean }>> = ({
  editable,
  ...props
}) => {
  const widget = props;

  const [caption, setCaption] = React.useState(widget.caption ?? '');

  const { mutate } = useMutation((updatedWidget: ImageWidget) => WidgetApi.updateWidget(updatedWidget));

  const updatedWidgetDebounced = useDebouncedCallback(caption => {
    mutate({
      ...widget,
      caption,
    });
  }, 500);

  const handleOnCaptionChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setCaption(event.target.value);
    updatedWidgetDebounced(event.target.value);
  };

  return (
    <VStack align="flex-start">
      {!!widget.file && (
        <Center w="full">
          <Image
            src={widget.file?.url}
            alt={widget.caption}
            borderRadius="lg"
            fallback={
              <Box p="20">
                <Spinner size="xl" />
              </Box>
            }
          />
        </Center>
      )}
      {editable ? (
        <Input
          variant="outline"
          placeholder="Enter a caption (optional)"
          value={caption}
          px={3}
          py={4}
          bg="white"
          onChange={handleOnCaptionChange}
        />
      ) : (
        <Center w="full">
          <Text variant="-1">{caption}</Text>
        </Center>
      )}
    </VStack>
  );
};
