import {
  IncomingWebhook,
  IncomingWebhookChecklistConfigKey,
  IncomingWebhookOptimistic,
} from '@process-street/subgrade/automation';
import { Muid, MuidUtils } from '@process-street/subgrade/core';
import { match, P } from 'ts-pattern';
import create from 'zustand';

export type ChecklistMapping = {
  property: IncomingWebhookChecklistConfigKey | undefined;
  path: string | undefined;
  id: Muid;
  type: 'checklist';
};

export type FormFieldMapping = {
  formFieldWidgetHeaderGroupId: Muid | undefined;
  path: string | undefined;
  id: Muid;
  type: 'formField';
};

export type DataSetMapping = {
  property: Muid;
  path: string | undefined;
  id: Muid;
  type: 'dataSet';
};

export type NewMapping = { id: Muid; path: string | undefined; type: 'new' };

export function isFormFieldMapping(mapping: Mapping): mapping is FormFieldMapping {
  return mapping.type === 'formField';
}
export function isChecklistMapping(mapping: Mapping): mapping is ChecklistMapping {
  return mapping.type === 'checklist';
}

export function isDataSetMapping(mapping: Mapping): mapping is DataSetMapping {
  return mapping.type === 'dataSet';
}

export type Mapping = NewMapping | ChecklistMapping | FormFieldMapping | DataSetMapping;

export type IncomingWebhookForm = Pick<IncomingWebhook, 'name'> & {
  mappings: Mapping[];
  keyColumn?: string;
};

export type IncomingWebhookFormActions = {
  hydrate: (incomingWebhook: IncomingWebhook | IncomingWebhookOptimistic) => void;
  addNewMapping: () => void;
  updateMapping: (value: Mapping) => void;
  deleteMapping: (id: Muid) => void;
  updateKeyColumn: (value: string | undefined) => void;
  deleteKeyColumn: () => void;
};

export type IncomingWebhookFormStore = IncomingWebhookForm & IncomingWebhookFormActions & { isHydrated: boolean };

export const EMPTY_FORM: IncomingWebhookForm = {
  name: '',
  mappings: [],
};

export const getUseIncomingWebhookForm = () =>
  create<IncomingWebhookFormStore>(set => {
    const addNewMapping = () => {
      set(current => ({
        mappings: [...current.mappings, { id: MuidUtils.randomMuid(), path: undefined, type: 'new' }],
      }));
    };

    const updateMapping = (value: Mapping) => {
      set(current => {
        const newMappings = current.mappings.map(mapping => (mapping.id === value.id ? value : mapping));
        const mappingForKey = current.keyColumn
          ? newMappings.find(mapping => mapping.type === 'dataSet' && mapping.property === current.keyColumn)
          : undefined;
        const keyColumn = match(mappingForKey)
          .with({ type: 'dataSet' }, mapping => mapping.property)
          .otherwise(() => undefined);

        return {
          mappings: newMappings,
          keyColumn,
        };
      });
    };
    const deleteMapping = (id: Muid) => {
      set(current => {
        const mapping = current.mappings.find(mapping => mapping.id === id);
        const newKeyColumn =
          mapping?.type === 'dataSet' && mapping?.property === current.keyColumn ? undefined : current.keyColumn;

        return { mappings: current.mappings.filter(mapping => mapping.id !== id), keyColumn: newKeyColumn };
      });
    };

    const updateKeyColumn = (value: string | undefined) => {
      set(() => ({ keyColumn: value }));
    };

    const deleteKeyColumn = () => {
      set(() => ({ keyColumn: undefined }));
    };

    const hydrate = (incomingWebhook: IncomingWebhook | IncomingWebhookOptimistic) => {
      const checklistMappings = match(incomingWebhook)
        .with({ config: { checklistProperties: P.not(P.nullish) } }, webhook =>
          (
            Object.entries(webhook.config.checklistProperties ?? {}) as [IncomingWebhookChecklistConfigKey, string][]
          ).map(
            ([property, path]): ChecklistMapping => ({ property, path, id: MuidUtils.randomMuid(), type: 'checklist' }),
          ),
        )
        .otherwise(() => []);

      const formFieldMappings = match(incomingWebhook)
        .with({ config: { fields: P.not(P.nullish) } }, ({ config }) =>
          (Object.entries(config.fields ?? {}) as [Muid, string][]).map(
            ([formFieldWidgetHeaderGroupId, path]): FormFieldMapping => ({
              formFieldWidgetHeaderGroupId,
              path,
              id: MuidUtils.randomMuid(),
              type: 'formField',
            }),
          ),
        )
        .otherwise(() => []);

      const dataSetMappings = match(incomingWebhook)
        .with({ config: { dataSetColumns: P.not(P.nullish) } }, ({ config }) =>
          Object.entries(config.dataSetColumns).map(
            ([property, path]): DataSetMapping => ({
              property,
              path,
              id: MuidUtils.randomMuid(),
              type: 'dataSet',
            }),
          ),
        )
        .otherwise(() => []);

      const mappings: Mapping[] = [...checklistMappings, ...formFieldMappings, ...dataSetMappings];

      // start a new one if none exist
      const safeMappings: Mapping[] =
        mappings.length > 0 ? mappings : [{ type: 'new', id: MuidUtils.randomMuid(), path: undefined }];

      const keyColumn = match(incomingWebhook.config)
        .with({ dataSetKeyColumn: P.string }, config => config.dataSetKeyColumn)
        .otherwise(() => undefined);

      set(current => ({
        ...current,
        mappings: safeMappings,
        name: incomingWebhook.name,
        keyColumn,
        isHydrated: true,
      }));
    };

    return {
      ...EMPTY_FORM,
      isHydrated: false,
      addNewMapping,
      updateMapping,
      deleteMapping,
      updateKeyColumn,
      deleteKeyColumn,
      hydrate,
    };
  });
