import { OrderTree, TaskTemplate, TemplateRevision, WidgetHeader } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type MoveWidgetMutationParams = {
  headerId: WidgetHeader['id'];
  templateRevisionId: TemplateRevision['id'];
  targetTaskTemplateId: TaskTemplate['id'];
  targetWidgetOrderTree: OrderTree;
};

export const MoveWidgetMutation = {
  key: ['post', 'widgets', 'move'],
  mutationFn: ({ headerId, ...params }: MoveWidgetMutationParams) =>
    axiosService
      .getAxios()
      .post<WidgetHeader>(`/1/widgets/${headerId}/move`, params)
      .then(res => res.data),
};

export const useMoveWidgetMutation = (
  options: UseMutationOptions<WidgetHeader, AxiosError, MoveWidgetMutationParams> = {},
) => {
  return useMutation(MoveWidgetMutation.mutationFn, options);
};
