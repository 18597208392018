import angular from 'angular';

angular.module('frontStreetApp.services').service('MobileAppService', function ($window, $location) {
  const self = this;

  self.isIOSDevice = function () {
    const deviceTypes = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];
    return $window.navigator.platform ? deviceTypes.includes($window.navigator.platform) : false;
  };

  self.redirect = function () {
    if (self.isIOSDevice()) {
      const path = $location.path().replace(/^\//, '').replace(/\/$/, '');
      $window.location.href = 'app.process.st://processst/' + path;
    }
  };
});
