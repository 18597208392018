import { SolutionTypeTag } from '@process-street/subgrade/automation';
import { Condition, ProgressStatus } from '@process-street/subgrade/dashboard';
import { AppState } from 'app/app.states.typegen';
import { Spinner, BreadcrumbLink, Text, BreadcrumbLinkProps, forwardRef } from 'components/design/next';
import { useAutomationsModalEvents } from 'features/automations/components/workflow-automations/components/common/context';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { useTemplateRevision } from 'pages/templates/_id/components/custom-notifications-modal/template-revision-context';
import React from 'react';
import { useUIActorRef } from '../ui-context';
import { useFormResponseCountQuery } from '../use-form-responses-count-query';

type Step = {
  key: 'build' | 'logic' | 'automate' | 'responses';
  stateName?: AppState;
  label: string;
  render?: (children: React.ReactNode) => React.ReactElement;
  breadcrumbLink?: typeof FormsBreadcrumbLink;
};

export const AutomateStep: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { openSolutionTypeTag } = useAutomationsModalEvents();

  return React.cloneElement(children as React.ReactElement, {
    onClick: () => openSolutionTypeTag(SolutionTypeTag.WhenChecklistCompletedThen),
  });
};

export const ConditionalLogicStep: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { uiActorRef } = useUIActorRef();
  const templateRevision = useTemplateRevision();
  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevision.id);

  const isDisabled = widgetsQuery.data && widgetsQuery.data.length === 0;

  return React.cloneElement(children as React.ReactElement, {
    onClick: () => uiActorRef.send({ type: 'OPEN_DISCLOSURE', name: 'conditionalLogic' }),
    isDisabled,
  });
};

export const FormsBreadcrumbLink = forwardRef<BreadcrumbLinkProps, 'a'>((props, ref) => (
  <BreadcrumbLink
    ref={ref}
    textTransform="uppercase"
    fontWeight="500"
    fontSize="sm"
    color="gray.500"
    letterSpacing="0.1em"
    _hover={{ fontWeight: '500' }}
    {...props}
  />
));

export const ResponsesBreadcrumbLink = forwardRef<BreadcrumbLinkProps, 'a'>((props, ref) => {
  const countQuery = useFormResponseCountQuery({
    condition: Condition.DoesNotContain,
    progressStatuses: [ProgressStatus.Archived],
  });
  const isLinkDisabled = (countQuery.data?.total ?? 0) === 0;
  return (
    <FormsBreadcrumbLink
      {...props}
      ref={ref}
      {...(isLinkDisabled
        ? {
            onClick: e => e.preventDefault(),
            cursor: 'not-allowed',
            pointerEvents: 'none',
            color: 'gray.400',
          }
        : {})}
    >
      <ResponsesLabel />
    </FormsBreadcrumbLink>
  );
});

export const ResponsesLabel = () => {
  const countQuery = useFormResponseCountQuery();
  return (
    <Text variant="inherit">
      Responses ({countQuery.isSuccess ? countQuery.data.total : <Spinner as="span" size="xs" />})
    </Text>
  );
};

export const STEPS: Step[] = [
  { key: 'build', stateName: 'form', label: 'Build' },
  { key: 'logic', label: 'Logic', render: children => <ConditionalLogicStep>{children}</ConditionalLogicStep> },
  {
    key: 'automate',
    label: 'Automate',
    render: children => <AutomateStep>{children}</AutomateStep>,
  },
  { key: 'responses', stateName: 'formResponses', breadcrumbLink: ResponsesBreadcrumbLink, label: '' },
];
