import { ICellRendererParams } from '@ag-grid-community/core';
import { DateUtils } from '@process-street/subgrade/util';
import { Tooltip } from 'components/design/next';
import React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

interface DateFieldRendererProps extends Partial<ICellRendererParams> {
  value?: number;
}

export const DateFieldRenderer: React.FunctionComponent<React.PropsWithChildren<DateFieldRendererProps>> = ({
  value,
  colDef,
  rowIndex,
}) => {
  const currentUser = useSelector(SessionSelector.getCurrentUser);

  if (!value || !currentUser) {
    return <div />;
  }

  const date = new Date(value);
  const tooltip = DateUtils.formatDateTimeToLongMonth(date, currentUser.timeZone);
  const text = DateUtils.formatDateToShortMonth(date, currentUser.timeZone);

  return (
    <Tooltip label={tooltip} id={`checklist-${rowIndex}-${colDef?.colId ?? 'unknown-date0field-column'}`} hasArrow>
      <div className="truncate-text">{text}</div>
    </Tooltip>
  );
};
