import * as React from 'react';
import { Skeleton, Link } from 'components/design/next';
import { match, P } from 'ts-pattern';
import { useGetAllTemplatesQuery } from 'features/template/query-builder';
import { CellRenderer } from './common';
import { Muid } from '@process-street/subgrade/core';
import { useInjector } from 'components/injection-provider';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { TemplateStatus, TemplateType } from '@process-street/subgrade/process';

export const TemplateCell: CellRenderer<'template'> = ({
  cell: {
    value: { id },
  },
}) => {
  const orgId = useSelector(SessionSelector.getSelectedOrganizationId);

  const templateForScheduleQuery = useGetAllTemplatesQuery(
    {
      organizationId: orgId!,
      templateStatus: TemplateStatus.Active,
      schedule: true,
      templateType: TemplateType.Playbook,
    },
    {
      enabled: Boolean(orgId),
      select: templates => templates.find(t => t.id === id),
    },
  );

  return match(templateForScheduleQuery)
    .with({ status: 'loading' }, () => (
      <Skeleton>
        <Link>Loading</Link>
      </Skeleton>
    ))
    .with({ status: 'success', data: P.nullish }, () => <Link cursor="not-allowed">(hidden)</Link>)
    .with({ status: 'success', data: P.not(P.nullish) }, ({ data: { name } }) => (
      <TemplateLink id={id}>{name}</TemplateLink>
    ))
    .otherwise(() => null);
};

const TemplateLink: React.FC<React.PropsWithChildren<{ id: Muid }>> = ({ id, children }) => {
  const { $state } = useInjector('$state');
  return (
    <Link href={$state.href('templateDashboard', { id })} display="block">
      {children}
    </Link>
  );
};
