import { MergeTagsConstants } from '@process-street/subgrade/form';
import { generatePatternByKey } from './generate-pattern-by-key';
import { applyFilter } from './apply-filter';
import { ResolvedTag } from '@process-street/subgrade/merge-tags';

export function replaceResolvedTagsWithPlaintextValues(resolvedTags: Array<ResolvedTag>, content: string): string {
  let value = content;

  resolvedTags.forEach(tag => {
    const pattern = generatePatternByKey(MergeTagsConstants.Mode.PLAINTEXT, tag.key);
    value = value.replace(
      pattern,
      (__match, __key, filter, param) =>
        applyFilter({ filter, param, key: tag.key, value: tag.replacement ?? '' }) ?? '',
    );
  });
  return value;
}
