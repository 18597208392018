import { SubscriptionStatus } from '../core';

const isCancelled = (status: SubscriptionStatus): boolean =>
  status !== SubscriptionStatus.Active &&
  status !== SubscriptionStatus.Trialing &&
  status !== SubscriptionStatus.PastDue;

export const SubscriptionUtils = {
  isCancelled,
};
