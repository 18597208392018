import angular from 'angular';
import { ArrayService } from 'services/array-service';

angular
  .module('frontStreetApp.services')
  .factory('OrderTreeBulkUpdater', ($timeout, $q, debounce, OrderTreeService, util) => {
    /**
     * @typedef {Object} OrderTreeBulkUpdaterOptions
     * @property {function} onSuccess should accept bulkUpdateResponses {Array},
     *                                movedItems {Array}, originalOrderTreeMap {map}, items {Array}
     * @property {function} onFailure should accept bulkUpdateResponses {Array},
     *                                movedItems {Array}, originalOrderTreeMap {map} , items {Array}
     * @property {function} [deferPredicate] should accept parameter items {Array} and return true or false,
     *                      the updater will wait until it's true
     * @property {function} [getOrderTree] should accept item and return orderTree
     * @property {function} [setOrderTree] should accept orderTree and item, and set the orderTree to the item
     */

    /**
     * Constructor of OrderTreeBulkUpdater
     *
     * @param {function} update should accept itemsToUpdate {Array}, newOrderTreeMap {map} and return promise
     * @param {OrderTreeBulkUpdaterOptions} options Consists of deferPredicate, onSuccess and onFailure
     * @constructor
     */
    function OrderTreeBulkUpdater(update, options) {
      const self = this;

      self.update = update;
      self.options = options;

      self.options.deferPredicate =
        self.options.deferPredicate ||
        function () {
          return true;
        };
      self.options.onSuccess =
        self.options.onSuccess ||
        function () {
          void 0;
        };
      self.options.onFailure =
        self.options.onFailure ||
        function () {
          void 0;
        };
      self.options.setOrderTree =
        self.options.setOrderTree ||
        function (orderTree, item) {
          item.orderTree = orderTree;
        };
      self.options.getOrderTree =
        self.options.getOrderTree ||
        function (item) {
          return item.orderTree;
        };

      self.debouncedUpdate = undefined;
      self.deferPredicateTimeout = undefined;
      self.originalOrderTreeMap = undefined;
    }

    OrderTreeBulkUpdater.prototype.toOrderTrees = function (items) {
      const self = this;
      return items.map(item => self.options.getOrderTree(item));
    };

    /**
     * Moves all items to new positions
     *
     * @param indexes Indexes of task templates that are going to move
     * @param newIndexes New indexes of the task templates
     * @param items List of all task templates
     */
    OrderTreeBulkUpdater.prototype.moveAt = function (indexes, newIndexes, items) {
      const self = this;

      // Saving original order tree for detecting changes and for rolling back in case of update failure
      if (!self.originalOrderTreeMap) {
        self.originalOrderTreeMap = items.reduce((map, item) => {
          map[item.id] = self.options.getOrderTree(item);
          return map;
        }, {});
      }

      // Reordering task templates with new indexes in a new array, otherwise it conflicts with ui-sortable
      const itemsOrdered = items.slice();
      indexes.forEach((index, i) => {
        ArrayService.move(itemsOrdered, index, newIndexes[i]);
      });

      // Reassigning order trees
      const orderTreesSorted = self.toOrderTrees(itemsOrdered).sort(OrderTreeService.compare);
      itemsOrdered.forEach((item, i) => {
        self.options.setOrderTree(orderTreesSorted[i], item);
      });

      return self.updateWithDebounce(items);
    };

    OrderTreeBulkUpdater.prototype.updateWithDebounce = function (items) {
      const self = this;
      const { deferPredicate } = self.options;

      $timeout.cancel(self.deferPredicateTimeout);
      if (!deferPredicate(items)) {
        const deferred = $q.defer();
        self.deferPredicateTimeout = $timeout(() => {
          util.pipe(deferred, self.updateWithDebounce(items));
        }, 500);

        return deferred.promise;
      } else {
        if (!self.debouncedUpdate) {
          self.debouncedUpdate = debounce(
            self.doUpdate,
            1000 /* wait */,
            false /* immediate */,
            true /* waitForResponse */,
          );
        }

        return self.debouncedUpdate(items);
      }
    };

    OrderTreeBulkUpdater.prototype.doUpdate = function (items) {
      const self = this;

      const orderTreesSorted = self.toOrderTrees(items).sort(OrderTreeService.compare);

      // Detecting moved task templates and saving new order trees map for later use as original order tree map
      const itemsToMove = [];
      const editedOrderTreeMap = {};
      items.forEach((item, i) => {
        if (self.originalOrderTreeMap[item.id] !== orderTreesSorted[i]) {
          itemsToMove.push(items[i]);
        }
        editedOrderTreeMap[item.id] = orderTreesSorted[i];
        self.options.setOrderTree(orderTreesSorted[i], item);
      });

      return self
        .update(itemsToMove, editedOrderTreeMap)
        .then(
          bulkUpdateResponses => {
            self.options.onSuccess(bulkUpdateResponses, itemsToMove, self.originalOrderTreeMap, items);
            return bulkUpdateResponses;
          },
          response => {
            self.options.onFailure(response, itemsToMove, self.originalOrderTreeMap, items);
            self.rollBackOrderTrees(items, self.originalOrderTreeMap);
            return $q.reject(response);
          },
        )
        .finally(() => {
          // This ensures that original order tree map is in last saved state
          angular.copy(editedOrderTreeMap, self.originalOrderTreeMap);
        });
    };

    OrderTreeBulkUpdater.prototype.rollBackOrderTrees = function (items, originalOrderTreeMap) {
      const self = this;

      if (originalOrderTreeMap.length > 0) {
        items.forEach(item => {
          self.options.setOrderTree(originalOrderTreeMap[item.id], item);
        });
      }
    };

    return OrderTreeBulkUpdater;
  });
