import { Box, useToken } from '@chakra-ui/react';
import { Muid } from '@process-street/subgrade/core';
import { CDNImage } from 'components/cdn-image';
import { useBreakpointPx } from 'components/design/next';
import * as React from 'react';
import { match, P } from 'ts-pattern';
import { GetBackgroundImageQuery } from 'app/features/template/query-builder';

export type BackgroundImageProps = {
  templateId: Muid;
  /** Offset from screen top in CSS tokens. Will also decrease image height respectively. */
  topOffset?: string | number;
};

export const BackgroundImage = ({ templateId, topOffset = 0 }: BackgroundImageProps) => {
  const breakpointPx = useBreakpointPx();
  const coverImageQuery = GetBackgroundImageQuery.useQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
      select: data => data?.s3File,
    },
  );

  const topOffsetCss = useToken('sizes', topOffset);

  return (
    <Box position="sticky" top={topOffset}>
      {match(coverImageQuery)
        .with({ status: 'success', data: P.not(P.nullish) }, ({ data }) => (
          <Box w="full" overflow="hidden" position="absolute">
            <CDNImage
              alt={data.originalName}
              s3File={data}
              transformation={{
                width: breakpointPx,
              }}
              opacity="0.7"
              h={`calc(100vh - ${topOffsetCss})`}
            />

            <Box position="absolute" top="0" w="full" h="full" bgGradient="linear(transparent 50%, gray.50)" />
          </Box>
        ))
        .otherwise(() => null)}
    </Box>
  );
};
