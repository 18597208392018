import { Template, TemplateRevisionStatus, TemplateRevisionWithTemplate } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQueries, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';
import { axiosService } from 'services/axios-service';
import {
  GetAllTemplateRevisionQueryResponse,
  GetAllTemplateRevisionsQuery,
} from 'features/template-revisions/query-builder/get-all-template-revisions';

export type GetNewestTemplateRevisionsByTemplateIdQueryParams = { templateId?: Template['id'] };
export type GetNewestTemplateRevisionsByTemplateIdsQueryParams = { templateIds: Template['id'][] };

export type GetNewestTemplateRevisionsByTemplateIdQueryResponse = [
  TemplateRevisionWithTemplate,
  TemplateRevisionWithTemplate | undefined,
];

export const GetNewestTemplateRevisionsByTemplateIdQuery = {
  key: ['template-revisions', 'newest'],
  getKey: (params: GetNewestTemplateRevisionsByTemplateIdQueryParams) => [
    ...GetNewestTemplateRevisionsByTemplateIdQuery.key,
    params,
  ],
  queryFn: (params: GetNewestTemplateRevisionsByTemplateIdQueryParams) =>
    axiosService
      .getAxios()
      .get<GetNewestTemplateRevisionsByTemplateIdQueryResponse>(
        `/1/templates/${params.templateId}/template-revisions/newest`,
      )
      .then(res => res.data),
  select: {
    where:
      ({ status }: { status: TemplateRevisionStatus }) =>
      (result: GetNewestTemplateRevisionsByTemplateIdQueryResponse) => {
        switch (status) {
          case TemplateRevisionStatus.Draft:
            return result[1];
          default:
            return result[0];
        }
      },
  },
};

export const useGetNewestTemplateRevisionsByTemplateIdQuery = <
  Select = GetNewestTemplateRevisionsByTemplateIdQueryResponse,
>(
  params: GetNewestTemplateRevisionsByTemplateIdQueryParams,
  options: UseQueryOptions<GetNewestTemplateRevisionsByTemplateIdQueryResponse, AxiosError, Select> = {},
) => {
  return useGetNewestTemplateRevisionsByTemplateIdsQuery<Select>(
    { templateIds: [params.templateId ?? ''] },
    {
      enabled: Boolean(params.templateId) && options.enabled !== false,
      ...options,
    },
  )[0] as UseQueryResult<Select, AxiosError>;
};

export const useGetNewestTemplateRevisionsByTemplateIdsQuery: <
  Select = GetNewestTemplateRevisionsByTemplateIdQueryResponse,
>(
  params: GetNewestTemplateRevisionsByTemplateIdsQueryParams,
  options?: UseQueryOptions<GetNewestTemplateRevisionsByTemplateIdQueryResponse, AxiosError, Select>,
) => UseQueryResult<GetNewestTemplateRevisionsByTemplateIdQueryResponse>[] = (params, options = {}) => {
  const queryClient = useQueryClient();

  return useQueries(
    params.templateIds.map(templateId => ({
      queryKey: GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId }),
      queryFn: () => GetNewestTemplateRevisionsByTemplateIdQuery.queryFn({ templateId }),
      initialData: () => {
        const cachedData = queryClient.getQueryData<GetAllTemplateRevisionQueryResponse>(
          GetAllTemplateRevisionsQuery.getKey(),
        );

        const finishedRevision = cachedData?.find(
          tr => tr.template.id === templateId && tr.status === TemplateRevisionStatus.Finished,
        );
        const draftRevision = cachedData?.find(
          tr => tr.template.id === templateId && tr.status === TemplateRevisionStatus.Draft,
        );

        if (finishedRevision) {
          return [finishedRevision];
          // eslint-disable-next-line no-dupe-else-if -- not willing to fix this but this looks dead
        } else if (finishedRevision && draftRevision) {
          return [finishedRevision, draftRevision];
        }
      },
      initialDataUpdatedAt: () => {
        return queryClient.getQueryState(GetAllTemplateRevisionsQuery.getKey())?.dataUpdatedAt;
      },
      ...(options as any),
    })),
  ) as UseQueryResult<GetNewestTemplateRevisionsByTemplateIdQueryResponse>[];
};
