import { StateFrom } from 'xstate';
import { TaskMachine } from './task-machine';

type State = StateFrom<TaskMachine>;

export namespace TaskMachineSelectors {
  export const getIsCompleted = (state: State) => state.matches('task.complete');
  export const getWidgetActors = (state: State) => Object.values(state.context.widgetActorsMap);
  export const getTask = (state: State) => state.context.task;
  export const getApprovals = (state: State) => state.context.approvals;
}
