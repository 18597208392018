import { Muid } from '../../core';
import { ExecuteAiPrompt, NativeAutomation } from '.';
import { TaskTemplate } from '../template-model';

export namespace AiTask {
  export function getPromptConfig(nativeAutomation?: NativeAutomation): ExecuteAiPrompt.PromptConfig | undefined {
    const actions = nativeAutomation?.actions ?? [];
    const promptConfig = actions.find(NativeAutomation.isExecuteAiPromptAction)?.config;
    // HACK while math is not chat gpt based
    const mathConfig = actions.some(NativeAutomation.isMathFormulaAction)
      ? ExecuteAiPrompt.PROMPT_CONFIGS_BY_TYPE['Math']
      : undefined;
    return promptConfig ?? mathConfig;
  }

  export function isPromptOfType<T extends ExecuteAiPrompt.PromptType>(promptType: T) {
    return (
      promptConfig?: NativeAutomation.GetActionByType<'ExecuteAiPrompt'>['config'],
    ): promptConfig is ExecuteAiPrompt.GetPromptConfig<T> => {
      return promptConfig?.promptType === promptType;
    };
  }
}

/** This is basically a client side type to represent the joins table task_template_automation_links */
export interface AiTaskTemplateId {
  taskTemplateId: Muid;
  nativeAutomation: NativeAutomation;
}

export interface AiTaskTemplate {
  taskTemplate: TaskTemplate;
  nativeAutomation: NativeAutomation;
}
