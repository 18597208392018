import dayjs from 'dayjs';
import { sprintf } from 'sprintf-js';
import { SentryService } from 'components/sentry/sentry.service';

export type LogArguments = any[];

export type ILogCall = (...args: LogArguments) => void;

export interface Trace {
  debug: ILogCall;
  error: ILogCall;
  info: ILogCall;
  log: ILogCall;
  warn: ILogCall;
}

class TraceImpl implements Trace {
  public debug: ILogCall;
  public error: ILogCall;
  public info: ILogCall;
  public log: ILogCall;
  public warn: ILogCall;

  private readonly name: string;

  /* eslint-disable no-console */
  constructor(private readonly spec: { name: string }) {
    this.name = this.spec.name || '???';

    this.debug = this.wrap(console.debug);
    this.info = this.wrap(console.info);
    this.log = this.wrap(console.log);
    this.warn = this.wrap(console.warn);
    this.error = this.wrapError(console.error);
  }
  /* eslint-enable no-console */

  private formatArgs(args: LogArguments) {
    const date = dayjs().format('YYYY-MM-DD HH:mm:ss,SSS');
    const as = Array.prototype.slice.call(args, 0);
    return [`${date} [${this.name}] - ${as[0]}`].concat(as.slice(1));
  }

  private formatArgsWithoutDate(args: LogArguments) {
    const as = Array.prototype.slice.call(args, 0);
    return [`[${this.name}] - ${as[0]}`].concat(as.slice(1));
  }

  private wrap(delegate: ILogCall): ILogCall {
    return (...args: LogArguments) => {
      const formattedArgs = this.formatArgs(args);
      delegate(...formattedArgs);
    };
  }

  private wrapError(delegate: ILogCall): ILogCall {
    return (...args: LogArguments) => {
      const formattedArgs = this.formatArgs(args);
      delegate(...formattedArgs);

      const formattedArgsWithoutDate = this.formatArgsWithoutDate(args);
      let message;
      try {
        message = sprintf(formattedArgsWithoutDate[0], ...formattedArgsWithoutDate.slice(1));
      } catch {
        [message] = formattedArgsWithoutDate;
      }
      SentryService.captureMessage(message);
    };
  }
}

export const trace = (spec: { name: string }): Trace => new TraceImpl(spec);
