import * as React from 'react';
import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuList,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { Icon } from 'components/design/next';
import { useFeatureFlag } from 'app/features/feature-flags';

export type FormsWidgetMenuProps = {
  buttonProps?: Partial<IconButtonProps & MenuButtonProps>;
  disclosure?: ReturnType<typeof useDisclosure>;
};

export const FormsWidgetMenu = ({
  children,
  disclosure,
  buttonProps,
}: React.PropsWithChildren<FormsWidgetMenuProps>) => {
  const isReactWFEditorEnabled = useFeatureFlag('reactWorkflowEditor');
  return (
    <Menu {...disclosure} isLazy>
      {({ isOpen }) => (
        <>
          <MenuButton
            position="absolute"
            top="50%"
            transform="translateY(-50%)"
            right="-10"
            as={IconButton}
            opacity={{ base: '1', sm: disclosure?.isOpen ? 1 : 0 }}
            _groupHover={{ opacity: 1 }}
            variant="ghost"
            color="gray.400"
            _hover={{ color: 'gray.600' }}
            _active={{ color: 'gray.600' }}
            aria-label="Widget menu"
            icon={<Icon icon="ellipsis-h" size="4" />}
            size={{ base: 'sm', lg: 'md' }}
            {...buttonProps}
          />

          {isOpen && (
            <Portal>
              {isReactWFEditorEnabled ? (
                <MenuList
                  zIndex="popover"
                  sx={{
                    '.chakra-menu__menuitem': {
                      fontSize: isReactWFEditorEnabled ? 'sm' : 'md',
                    },
                  }}
                >
                  {children}
                </MenuList>
              ) : (
                <MenuList zIndex="popover">{children}</MenuList>
              )}
            </Portal>
          )}
        </>
      )}
    </Menu>
  );
};
