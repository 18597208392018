import * as React from 'react';
import { usePaywall } from '../paywall-provider';
import { PaywallHook } from '../paywall-button';
import { useShouldBlockCreateTemplate } from './use-should-block-create-template';
import { UIs } from './ui';

export const useCreateTemplatePaywall: PaywallHook = () => {
  const { setPaywall } = usePaywall();
  const { shouldBlock, isLoading, role } = useShouldBlockCreateTemplate();

  const onClick = React.useCallback(() => {
    const paywall = role ? React.createElement(UIs[role]) : null;
    setPaywall(paywall);
    return () => {
      setPaywall(null);
    };
  }, [role, setPaywall]);

  return { onClick: shouldBlock ? onClick : undefined, isLoading };
};
