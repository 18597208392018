import React, { memo } from 'react';
import { Box } from 'components/design/next';
import { RichTextEditor } from 'features/rich-text';

interface DescriptionEditorProps {
  value: string;
  onChange: (content: string) => void;
}

const headerSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 512" width="24px" height="24px">
<path d="M254 52.8C249.3 40.3 237.3 32 224 32s-25.3 8.3-30 20.8L57.8 416H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32h-1.8l18-48H303.8l18 48H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H390.2L254 52.8zM279.8 304H168.2L224 155.1 279.8 304z"/>
</svg>`;

const DescriptionEditor: React.FC<React.PropsWithChildren<DescriptionEditorProps>> = memo(({ value, onChange }) => {
  return (
    <>
      <Box
        id="editor-bar-container"
        w="full"
        sx={{
          '.tox-editor-header': {
            border: 'none !important',
            pb: 2,
          },
          '.tox .tox-menu': {
            pt: 4,
          },
          '.tox-tinymce-inline': {
            zIndex: 'var(--ps-zIndices-base)',
          },
        }}
      />
      <RichTextEditor
        editorProps={{
          value,
          onEditorChange: onChange,
        }}
        init={{
          placeholder: 'What is this task about?',
          fixed_toolbar_container: '#editor-bar-container',
          link_default_target: '_blank',
          toolbar_persist: true,
          toolbar: [
            ['undo', 'redo'],
            ['headings'],
            ['bold', 'italic', 'underline'],
            ['link'],
            ['bullist', 'numlist'],
            ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
            ['forecolor', 'backcolor'],
          ]
            .map(section => section.join(' '))
            .join(' '),
          setup: editor => {
            if (editor?.ui) {
              editor.ui.registry.addIcon('headers', headerSvg);
              editor.ui.registry.addMenuButton('headings', {
                icon: 'headers',
                fetch: callback => {
                  const paragraph = {
                    type: 'togglemenuitem',
                    text: 'Paragraph',
                    onAction: () => editor.execCommand('mceToggleFormat', false, 'p'),
                  };
                  const items = ['h1', 'h2', 'h3', 'h4'].map(heading => ({
                    type: 'togglemenuitem',
                    text: heading.toUpperCase().replaceAll('H', 'Heading '),
                    onAction: () => editor.execCommand('mceToggleFormat', false, heading),
                  }));
                  //  @ts-expect-error -- The tinymce package does not include an export to the type NestedMenuItemContents.
                  callback([paragraph, ...items]);
                },
              });
            }
          },
        }}
        editorWrapperProps={{
          sx: {
            'px': 4,
            'borderWidth': '1px',
            'borderColor': 'gray.200',
            'borderRadius': '4px',
            'borderStyle': 'solid',
            'fontSize': 'md',
            'minHeight': '112px',
            '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before': {
              fontStyle: 'normal',
              pl: 1,
              fontSize: 'md',
              color: 'gray.400',
            },
          },
        }}
      />
    </>
  );
});

export default DescriptionEditor;
