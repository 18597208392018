import * as React from 'react';
import { useState } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { Column } from '@ag-grid-community/core';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import { Icon } from 'components/design/next';
import { match } from 'ts-pattern';

export type HeaderRendererProps = {
  column: Column;
  displayName: string;
  icon?: CustomIconName;
  enableSorting?: boolean;
  progressSort: (multiSort?: boolean) => void;
};

export const HeaderRenderer = ({ displayName, icon, enableSorting, progressSort, column }: HeaderRendererProps) => {
  const [sorting, setSorting] = useState(column.getSort());

  const onSortChange = React.useCallback(() => {
    setSorting(column.getSort());
  }, [column, setSorting]);

  React.useEffect(
    function setupSortingListener() {
      column.addEventListener('sortChanged', onSortChange);
      return () => {
        column.removeEventListener('sortChanged', onSortChange);
      };
    },
    [column, onSortChange],
  );

  return (
    <HStack
      w="full"
      h="full"
      pl="2"
      color="gray.500"
      aria-label={displayName}
      onClick={() => enableSorting && progressSort(false)}
      _hover={{ cursor: 'pointer', bgColor: 'gray.50' }}
    >
      {icon ? <Icon icon={icon} size="3" color="gray.500" /> : undefined}

      <Text fontWeight="normal">{displayName}</Text>

      {enableSorting &&
        match(sorting)
          .with('desc', () => <Icon variant="far" size="3" icon="sort-down" />)
          .with('asc', () => <Icon variant="far" size="3" icon="sort-up" />)
          .otherwise(() => <Icon variant="far" size="3" icon="sort" />)}
    </HStack>
  );
};
