import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { withErrorBoundary } from 'components/error-boundary';
import { RequestAccess } from '.';

export const RequestAccessModule = angular
  .module(
    'requestAccess',
    [], // dependencies
  )
  .component(
    'psRequestAccessPage',
    reactToAngular(
      withErrorBoundary(RequestAccess),
      [], // props
    ),
  ).name;
