import { createCachedAction } from 'reducers/util';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { htmlEscaped } from '@process-street/subgrade/util';
import { FlashActions } from 'reducers/flash/flash.actions';
import { TemplateTaskAssignmentSelector } from './template-task-assignment.selector';

export const TT_ASSIGNMENT_GET_ALL_BY_TMPL_REV_ID = 'taskTemplateAssignment/GET_BY_TMPL_REV_ID';
export const TT_ASSIGNMENT_CREATE = 'taskTemplateAssignment/CREATE';
export const TT_ASSIGNMENT_CREATE_ALL = 'taskTemplateAssignment/CREATE_ALL';
export const TT_ASSIGNMENT_DELETE = 'taskTemplateAssignment/DELETE';
export const TT_ASSIGNMENT_DELETE_ALL = 'taskTemplateAssignment/DELETE_ALL';

export const TemplateTaskAssignmentActions = (TemplateTaskAssignmentService, TaskTemplateService) => {
  'ngInject';

  const getAllByTemplateRevisionId = createCachedAction(
    TT_ASSIGNMENT_GET_ALL_BY_TMPL_REV_ID,
    TemplateTaskAssignmentService.getAllByTemplateRevisionId,
    templateRevisionId => ({ templateRevisionId }),
    (state, templateRevisionId) =>
      TemplateTaskAssignmentSelector.getAllByTemplateRevisionId(templateRevisionId)(state).length > 0,
  );

  const createTemplateTaskAssignment = createCachedAction(
    TT_ASSIGNMENT_CREATE,
    TemplateTaskAssignmentService.assign,
    (taskTemplateId, email, templateRevisionId, assigneeLabel) => ({
      taskTemplateId,
      email,
      templateRevisionId,
      assigneeLabel,
    }),
  );

  const createAllTemplateTaskAssignments =
    (templateRevisionId, taskTemplates, assignee, assigneeLabel) => (dispatch, getState) => {
      const state = getState();

      // get all assignments for this template revision
      const templateTaskAssignments =
        TemplateTaskAssignmentSelector.getTaskTemplateIdAssignmentsMapByTemplateRevisionId(templateRevisionId)(state);
      const assigneeOrganizationMembership =
        OrganizationMembershipSelector.getWithUserBySelectedOrganizationIdAndUserId(assignee.id)(state);

      // deal only with the ones that don't already have this assignment (and not headings... just in case)
      const taskTemplatesForAssignment = taskTemplates.filter(
        tt =>
          !TaskTemplateService.isHeading(tt) &&
          (!templateTaskAssignments[tt.id] ||
            templateTaskAssignments[tt.id].findIndex(
              a => a.organizationMembership.id === assigneeOrganizationMembership.id,
            ) === -1),
      );

      if (taskTemplatesForAssignment.length === 0) {
        const message = htmlEscaped`<b>${assigneeLabel}</b> already added to selected tasks.`;

        return dispatch(FlashActions.showSuccessNotice(message));
      }

      return dispatch(
        doCreateAllTemplateTaskAssignments(
          taskTemplatesForAssignment,
          assignee.email,
          templateRevisionId,
          assigneeLabel,
        ),
      );
    };

  const doCreateAllTemplateTaskAssignments = createCachedAction(
    TT_ASSIGNMENT_CREATE_ALL,
    TemplateTaskAssignmentService.assignAll,
    (taskTemplatesForAssignment, email, templateRevisionId, assigneeLabel) => ({
      taskTemplatesForAssignment,
      email,
      templateRevisionId,
      assigneeLabel,
    }),
  );

  const deleteTemplateTaskAssignment = createCachedAction(
    TT_ASSIGNMENT_DELETE,
    TemplateTaskAssignmentService.unassign,
    (taskTemplateId, email, templateRevisionId, assignmentId, assigneeLabel) => ({
      taskTemplateId,
      email,
      templateRevisionId,
      assignmentId,
      assigneeLabel,
    }),
  );

  const deleteAllTemplateTaskAssignments =
    (templateRevisionId, taskTemplates, assignee, assigneeLabel) => (dispatch, getState) => {
      const state = getState();

      // get all assignments for this template revision
      const templateTaskAssignments =
        TemplateTaskAssignmentSelector.getAllByTemplateRevisionId(templateRevisionId)(state);
      const assigneeOrganizationMembership =
        OrganizationMembershipSelector.getWithUserBySelectedOrganizationIdAndUserId(assignee.id)(state);

      // deal only with the ones that have this assignment (and not headings... just in case)
      const taskTemplatesForUnassignment = taskTemplates.filter(
        tt =>
          !TaskTemplateService.isHeading(tt) &&
          (!templateTaskAssignments[tt.id] ||
            templateTaskAssignments[tt.id].findIndex(
              a => a.organizationMembership.id === assigneeOrganizationMembership.id,
            ) !== -1),
      );

      if (taskTemplatesForUnassignment.length === 0) {
        const message = htmlEscaped`<b>${assigneeLabel}</b> already added to selected tasks.`;

        return dispatch(FlashActions.showSuccessNotice(message));
      }

      return dispatch(
        doDeleteAllTemplateTaskAssignments(
          taskTemplatesForUnassignment,
          assignee.email,
          templateRevisionId,
          assigneeLabel,
        ),
      );
    };

  const doDeleteAllTemplateTaskAssignments = createCachedAction(
    TT_ASSIGNMENT_DELETE_ALL,
    TemplateTaskAssignmentService.unassignAll, //(taskTemplates, user.email)
    (taskTemplates, email, templateRevisionId, assigneeLabel) => ({
      taskTemplates,
      email,
      templateRevisionId,
      assigneeLabel,
    }),
  );

  return {
    getAllByTemplateRevisionId,
    createTemplateTaskAssignment,
    createAllTemplateTaskAssignments,
    deleteTemplateTaskAssignment,
    deleteAllTemplateTaskAssignments,
  };
};
