import angular from 'angular';
import { connectService } from '../reducers/util';

angular.module('frontStreetApp.services').service('PlanFeatureSetService', function ($ngRedux, PlanFeatureSetActions) {
  const self = this;

  connectService('PlanFeatureSetService', $ngRedux, null, PlanFeatureSetActions)(this);

  /**
   * @typedef {Object} PlanFeatureSet
   * @property {string} id
   * @property {number} checklistRunsLimit
   * @property {number} activeTemplatesLimit
   * @property {number} activeChecklistsLimit
   */

  /**
   * Returns all plan feature set by id
   * @param {string} id
   * @returns PlanFeatureSet
   */
  self.getById = function (id) {
    return this.actions.getById(id, false /*flushCache*/).then(({ payload }) => payload);
  };
});
