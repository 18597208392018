import { Widget } from '@process-street/subgrade/process';
import kebabCase from 'lodash/kebabCase';
import { match, P } from 'ts-pattern';

const getId = (widget: Widget) => {
  return match(widget)
    .with({ fieldType: P.not(P.nullish) }, ({ fieldType }) => `${kebabCase(fieldType)}-form-field:${widget.id}`)
    .otherwise(widget => `${kebabCase(widget.header.type)}-content-widget:${widget.id}`);
};

/**
 * Finds the closest scrollable parent of an element.
 *
 * Useful for determining where to apply scroll logic in nested layouts,
 * modals, or custom UI components.
 *
 * @param element - The element to start the search from.
 * @returns The closest scrollable parent, or `document.body` if none is found.
 */
function getScrollableParent(element: HTMLElement): HTMLElement {
  if (!element) return document.body;

  let currentElement: HTMLElement = element;

  while (currentElement !== document.body) {
    // Check if the element is scrollable
    const { overflowY } = window.getComputedStyle(currentElement);

    if ((overflowY === 'auto' || overflowY === 'scroll') && currentElement.scrollHeight > currentElement.clientHeight) {
      return currentElement;
    }

    if (currentElement.parentElement) {
      // Move to the parent element
      currentElement = currentElement.parentElement;
    }
  }

  return document.body; // Default to the body if no scrollable parent is found
}

/**
 * Scrolls to an element if it is not fully visible within its scrollable parent.
 *
 * Ensures elements remain in view when working with nested scrollable containers
 * or dynamic layouts.
 *
 * @param element - The element to check and scroll into view if needed.
 */
function scrollToElementIfNotVisible(element: HTMLElement): void {
  if (!element) return;

  // Find the scrollable parent
  const scrollableParent = getScrollableParent(element);

  // Get the element's bounding rectangle relative to the viewport
  const elementRect = element.getBoundingClientRect();
  const parentRect = scrollableParent.getBoundingClientRect();

  // Check if the element is fully visible within the scrollable parent
  const isVisible = elementRect.top >= parentRect.top && elementRect.bottom <= parentRect.bottom;

  // Scroll to the element if it's not fully visible
  if (!isVisible) {
    const offset = 32;
    const parentScrollTop = scrollableParent.scrollTop; // Current scroll position of the parent
    const parentTop = scrollableParent.getBoundingClientRect().top; // Top edge of the parent relative to the viewport
    const elementTop = element.getBoundingClientRect().top; // Top edge of the element relative to the viewport

    // Calculate the new scroll position
    const newScrollTop = parentScrollTop + (elementTop - parentTop) - offset;

    // Smoothly scroll the parent to the new position
    scrollableParent.scrollTo({
      top: newScrollTop,
      behavior: 'smooth',
    });
  }
}

const scrollToWidget = (widget: Widget) => {
  // Get the DOM node associated with the widget
  const node = document.getElementById(WidgetMachineHelpers.getId(widget));
  if (node) {
    scrollToElementIfNotVisible(node);
  }
};

export const WidgetMachineHelpers = {
  getId,
  scrollToWidget,
  getScrollableParent,
};
