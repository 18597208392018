import { MuidUtils, S3File } from '@process-street/subgrade/core';
import { generateAudit } from '@process-street/subgrade/test';
import { CDNImage } from 'components/cdn-image';
import { Box, Flex, HStack, Icon, IconButton, List, VStack } from 'components/design/next';
import { HoverableListItem } from 'features/comments/components/common';
import { AttachmentFile } from 'features/comments/components/common/attachment/attachment-file';
import * as React from 'react';
import { AttachmentToFinishUpload } from './use-create-one-off-task-upload-attachments';

export type OneOffTaskAttachmentListProps = {
  attachments: AttachmentToFinishUpload[];
  isEnhancedFileSecurityEnabled: boolean;
  onRemoveAttachment: (key: string) => void;
};

export const OneOffTaskAttachmentList: React.FC<React.PropsWithChildren<OneOffTaskAttachmentListProps>> = ({
  attachments,
  isEnhancedFileSecurityEnabled,
  onRemoveAttachment,
}) => {
  return (
    <List w="full">
      {attachments.map(attachment => {
        const file = {
          id: MuidUtils.randomMuid(),
          audit: generateAudit(),
          name: attachment.originalFilename,
          mimeType: attachment.contentType,
          url: attachment.url,
          key: attachment.key,
          extension: attachment.fileExtension,
          originalName: attachment.originalFilename,
          size: attachment.size,
        } as S3File;
        return (
          <HoverableListItem
            id={attachment.key}
            key={attachment.key}
            border="none"
            w="full"
            px="1"
            sx={{
              background: 'white',
              transition: 'background 1s',
              transitionDelay: '0.2s',
            }}
          >
            {({ hovered }) => (
              <HStack alignItems="flex-start" spacing="3" width="full" justifyContent="space-between">
                <VStack alignItems="flex-start" width="full">
                  <Flex justifyContent="flex-end" width="full" h="8">
                    {hovered && (
                      <IconButton
                        bg="white"
                        color="gray.600"
                        variant="outline"
                        colorScheme="gray"
                        size="sm"
                        borderWidth="px"
                        icon={<Icon size="4" variant="far" icon="trash" />}
                        aria-label="delete"
                        onClick={() => onRemoveAttachment(attachment.key)}
                      />
                    )}
                  </Flex>
                  {attachment.contentType.startsWith('image/') ? (
                    <Box position="relative">
                      <Box position="relative" width="full">
                        <CDNImage
                          {...{
                            isDisabled: isEnhancedFileSecurityEnabled,
                            s3File: file,
                            alt: attachment.originalFilename,
                            transformation: {
                              width: 'auto',
                              height: 300,
                            },
                            maxHeight: '300px',
                          }}
                        ></CDNImage>
                      </Box>
                    </Box>
                  ) : (
                    <AttachmentFile {...{ file, canDownload: false }} />
                  )}
                </VStack>
              </HStack>
            )}
          </HoverableListItem>
        );
      })}
    </List>
  );
};
