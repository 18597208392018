import {
  AutomationInstance,
  SolutionTypeTag,
  TemplateSolutionInstance,
  TrayOrNativeAutomationInstance,
} from '@process-street/subgrade/automation';
import { Template, TemplateRevision } from '@process-street/subgrade/process';
import {
  useGetAllIncomingWebhooksByTemplateIdQuery,
  useSolutionInstancesByTemplateIdQuery,
  useSolutionsByTemplateIdQuery,
} from 'features/automations/query-builder';
import * as React from 'react';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';

type GroupedSolutions = {
  [SolutionTypeTag.CreateChecklistWhen]: AutomationInstance[];
  [SolutionTypeTag.WhenTaskCheckedThen]: TrayOrNativeAutomationInstance[];
  [SolutionTypeTag.WhenChecklistCompletedThen]: TemplateSolutionInstance[];
};

interface Params {
  templateId?: Template['id'];
  /** If not provided, will use the latest draft template revision. */
  templateRevisionId?: TemplateRevision['id'];
}

export function useSolutionTypeTagInstances(
  { templateId, templateRevisionId }: Params,
  { enabled }: { enabled: boolean } = { enabled: true },
): { instances: GroupedSolutions; isLoading: boolean } {
  const solutionsQuery = useSolutionsByTemplateIdQuery({ templateId }, { enabled });
  const solutions = React.useMemo(() => solutionsQuery.data ?? [], [solutionsQuery.data]);

  const templateSolutionInstancesQuery = useSolutionInstancesByTemplateIdQuery({ templateId }, { enabled });
  const templateSolutionInstances = React.useMemo(
    () => templateSolutionInstancesQuery.data ?? [],
    [templateSolutionInstancesQuery.data],
  );

  const incomingWebhooksQuery = useGetAllIncomingWebhooksByTemplateIdQuery(
    { templateId },
    {
      enabled,
      select: webhooks => webhooks.filter(({ status }) => status !== 'Deleted'),
    },
  );

  const isLoading =
    incomingWebhooksQuery.isLoading || templateSolutionInstancesQuery.isLoading || solutionsQuery.isLoading;

  const incomingWebhooks = React.useMemo(() => incomingWebhooksQuery.data ?? [], [incomingWebhooksQuery.data]);

  const templateRevisionQuery = useNewestTemplateRevisionQuery(
    { templateId, editable: true },
    { enabled: !templateRevisionId && enabled },
  );
  const workflowPageAutomationsQuery = GetAllNativeAutomationsQuery.useQuery(
    { templateRevisionId: templateRevisionId ?? templateRevisionQuery.data?.id },
    { enabled, select: as => as.filter(a => a.automation.automationType === 'WorkflowPageAutomation') },
  );

  const instances = React.useMemo<GroupedSolutions>(() => {
    const createChecklistWhenSolutionIds = solutions
      .filter(solution => solution.solutionTypeTag === SolutionTypeTag.CreateChecklistWhen)
      .map(s => s.id);
    const whenTaskCheckedSolutionIds = solutions
      .filter(solution => solution.solutionTypeTag === SolutionTypeTag.WhenTaskCheckedThen)
      .map(s => s.id);
    const whenChecklistCompletedThenSolutionIds = solutions
      .filter(solution => solution.solutionTypeTag === SolutionTypeTag.WhenChecklistCompletedThen)
      .map(s => s.id);

    const createChecklistWhenInstances: AutomationInstance[] = [
      ...templateSolutionInstances.filter(
        tsi =>
          createChecklistWhenSolutionIds.includes(tsi.solutionId) ||
          tsi.solutionTypeTag === SolutionTypeTag.CreateChecklistWhen,
      ),
      ...incomingWebhooks,
    ];

    const whenTaskCheckedInstances: TrayOrNativeAutomationInstance[] = [
      ...templateSolutionInstances.filter(
        tsi =>
          whenTaskCheckedSolutionIds.includes(tsi.solutionId) ||
          tsi.solutionTypeTag === SolutionTypeTag.WhenTaskCheckedThen,
      ),
      ...(workflowPageAutomationsQuery.data ?? []),
    ];

    const whenChecklistCompletedThenSolutionInstances: TemplateSolutionInstance[] = templateSolutionInstances.filter(
      tsi =>
        whenChecklistCompletedThenSolutionIds.includes(tsi.solutionId) ||
        tsi.solutionTypeTag === SolutionTypeTag.WhenChecklistCompletedThen,
    );

    return {
      [SolutionTypeTag.CreateChecklistWhen]: createChecklistWhenInstances,
      [SolutionTypeTag.WhenTaskCheckedThen]: whenTaskCheckedInstances,
      [SolutionTypeTag.WhenChecklistCompletedThen]: whenChecklistCompletedThenSolutionInstances,
    };
  }, [incomingWebhooks, solutions, templateSolutionInstances, workflowPageAutomationsQuery.data]);
  return { instances, isLoading };
}
