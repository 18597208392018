import * as React from 'react';
import { Checkbox, Divider, Flex, HStack, List, ListItem, Text, VStack } from 'components/design/next';
import { usePrintRulesContext, WidgetAttributes } from '../../../context';
import { getGroupedTasksAndWidgets } from '../utils';
import { TaskTemplatePrintAttributes } from '../print-template-rules';
import { WidgetInfo } from './widget-info';

export const HiddenByDefaultSection: React.FC = () => {
  const { taskGroupIds, widgetGroupIds } = usePrintRulesContext();
  const { groupedTasksWidgets, hiddenByDefaultCount } = getGroupedTasksAndWidgets(taskGroupIds, widgetGroupIds);
  const itemsToShow = groupedTasksWidgets.filter(shouldShowGroup);
  return (
    <VStack spacing={4} w="full">
      <Flex justifyContent="space-between" w="full">
        <Text>Hidden by default</Text>
        <Text>{hiddenByDefaultCount}</Text>
      </Flex>
      <Divider />
      <VStack spacing={2} justifyContent="flex-start" alignItems="flex-start" w="full">
        {itemsToShow.map(taskGroup => (
          <VStack
            key={`task-${taskGroup.task.id}`}
            spacing={2}
            justifyContent="flex-start"
            alignItems="flex-start"
            w="full"
          >
            <HStack spacing={2}>
              <Text as="span" color="gray.400" fontWeight="bold">
                {taskGroup.task.taskOrder}
              </Text>
              <Checkbox defaultChecked={taskGroup.task.hiddenByDefault} isReadOnly />
              <Text>{taskGroup.task.name ?? 'unnamed task'}</Text>
            </HStack>
            <Flex gap={2} alignItems="flex-start" flexDir="column" ml="var(--ps-space-10) !important">
              <List>
                {taskGroup.widgets.map(({ widget, icon, label }) => (
                  <ListItem as={HStack} spacing={2} key={`widget-${widget.id}`}>
                    <WidgetInfo icon={icon} isChecked={widget.header.hiddenByDefault} label={label} />
                  </ListItem>
                ))}
              </List>
            </Flex>
          </VStack>
        ))}
      </VStack>
    </VStack>
  );
};

const shouldShowGroup = (groupedTaskWidgets: { task: TaskTemplatePrintAttributes; widgets: WidgetAttributes[] }) => {
  const taskIsHiddenByDefault = groupedTaskWidgets.task.hiddenByDefault;
  if (taskIsHiddenByDefault) return true;

  const hiddenWidgets = groupedTaskWidgets.widgets.filter(({ widget }) => widget.header.hiddenByDefault);
  if (hiddenWidgets.length === 0) return false;

  return true;
};
