import React from 'react';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';

import { AuditMetadata, User } from '@process-street/subgrade/core';
import { getUserProfilePicUrl } from 'features/comments/utils';

import { Box, HStack, StackProps, Text, TooltipWithTouch } from 'components/design/next';
import { Avatar, AvatarSize } from 'components/common/Avatar';
import { useUserDeactivationHelpers } from 'features/user/use-user-deactivation-helpers';

import { dayjs as moment } from '@process-street/subgrade/util';
import { usePrintStore } from 'components/react-root/print-store';
import { useTimezone } from 'app/hooks/use-timezone';

type AuditInfoStackProps = {
  createdBy: User;
  createdDate: AuditMetadata['createdDate'];
} & StackProps;

export const AuditInfoStack: React.FC<React.PropsWithChildren<AuditInfoStackProps>> = ({
  createdBy,
  createdDate,
  ...props
}) => {
  const deactivationHelpers = useUserDeactivationHelpers();
  const timezone = useTimezone({ fallback: moment.tz.guess() });
  const relativeDate = DateUtils.formatDateFromNow(createdDate, timezone);
  const formattedDate = DateUtils.formatDateTime(
    createdDate,
    DateFormat.DateTimeLongWeekdayShortMonthWithZone,
    timezone,
  );
  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;

  return (
    <HStack alignItems="center" spacing="3" opacity={deactivationHelpers.isActive(createdBy.id) ? 1 : 0.6} {...props}>
      {deactivationHelpers.withTooltip(
        createdBy.id,
        <Box>
          <Avatar
            user={createdBy}
            userId={createdBy?.id}
            url={getUserProfilePicUrl(createdBy)}
            size={AvatarSize.Small}
          />
        </Box>,
      )}

      <HStack alignItems="baseline">
        {deactivationHelpers.withTooltip(
          createdBy.id,
          <Text aria-label="comment sender" variant="-1" color="gray.600" fontWeight="medium">
            {createdBy?.username}{' '}
          </Text>,
        )}
        {isPrintMode ? (
          <Text aria-label="comment date" variant="-2" color="gray.500" noOfLines={1}>
            {formattedDate}
          </Text>
        ) : (
          <TooltipWithTouch label={formattedDate} hasArrow={true} placement="top" fontSize="xs">
            <Text
              display="inline-block"
              color="gray.500"
              cursor="pointer"
              fontSize="xs"
              borderBottomWidth="px"
              borderBottomColor="gray.400"
              borderBottomStyle="dotted"
            >
              {relativeDate}
            </Text>
          </TooltipWithTouch>
        )}
      </HStack>
    </HStack>
  );
};
