import { ChecklistRevision, Template, Attachment } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetAttachmentsQueryParams = {
  checklistRevisionId: ChecklistRevision['id'];
  templateId: Template['id'];
};

export type GetAttachmentsQueryResponse = Attachment[];

export const GetAttachmentsQuery = {
  key: ['attachments', 'checklist-revision'],
  getKey: (params: GetAttachmentsQueryParams): QueryKey => [...GetAttachmentsQuery.key, params],
  queryFn: (params: GetAttachmentsQueryParams) =>
    axiosService
      .getAxios()
      .get<GetAttachmentsQueryResponse>(`/1/attachments`, {
        params: {
          where: {
            'task.taskTemplate.templateRevision.templateId': { _eq: params.templateId },
            'task.checklistRevisionId': { _eq: params.checklistRevisionId },
            'status': { _eq: 'Active' },
          },
          include: 'createdBy,task.taskTemplate.templateRevision,file',
        },
      })
      .then(res => res.data),
};

export const useGetAttachmentsQuery = <Select = GetAttachmentsQueryResponse>(
  params: GetAttachmentsQueryParams,
  options: UseQueryOptions<GetAttachmentsQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetAttachmentsQuery.getKey(params), () => GetAttachmentsQuery.queryFn(params), options);
};
