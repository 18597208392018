import { OrganizationMembership } from '@process-street/subgrade/core';
import { axiosService } from 'app/services/axios-service';
import { AxiosError } from 'axios';
import { QueryClient, useQuery as useRQQuery, UseQueryOptions, QueryKey } from 'react-query';

export namespace GetSnoozeAssignmentCountQuery {
  export type Params = {};

  export type Response = {
    count: number;
    organizationMembershipId: OrganizationMembership['id'];
  };

  export const key = ['assignments', 'snoozed', 'count'];

  export const getKey = (): QueryKey => key;

  export const queryFn = (_params: Params) =>
    axiosService
      .getAxios()
      .get<Response>('/1/assignments/snoozed/count')
      .then(res => res.data);

  export const useQuery = <Select = Response>(options: UseQueryOptions<Response, AxiosError, Select> = {}) => {
    return useRQQuery(getKey(), () => queryFn({}), options);
  };

  export const incrementCount = (queryClient: QueryClient, amount: number) => {
    const originalValue = queryClient.getQueryData(GetSnoozeAssignmentCountQuery.key);

    queryClient.setQueryData<Response>(getKey(), previous => ({
      organizationMembershipId: '',
      ...previous,
      count: (previous?.count ?? 0) + amount,
    }));

    return () => {
      queryClient.setQueryData(getKey(), originalValue);
    };
  };

  export const invalidate = (queryClient: QueryClient) => {
    return queryClient.invalidateQueries(getKey());
  };
}
