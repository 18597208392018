import { Muid } from '@process-street/subgrade/core';
import create from 'zustand';

type State = {
  updatingWidgetGroupIds: Set<Muid>;
};

type Actions = {
  addUpdatingWidgetGroupIds: (widgetGroupIds: Muid[]) => void;
  addUpdatingWidgetGroupId: (widgetGroupId: Muid) => void;
  removeUpdatingWidgetGroupId: (widgetGroupId: Muid) => void;
  clearUpdatingWidgetGroupIds: () => void;
};

type Store = State & Actions;

export const useTemplateWidgetsStore = create<Store>(set => {
  return {
    updatingWidgetGroupIds: new Set(),
    addUpdatingWidgetGroupIds: (widgetGroupIds: Muid[]) =>
      set(state => {
        const newUpdatingWidgetGroupIds = new Set([...state.updatingWidgetGroupIds, ...widgetGroupIds]);
        return { updatingWidgetGroupIds: newUpdatingWidgetGroupIds };
      }),
    addUpdatingWidgetGroupId: (widgetGroupId: Muid) =>
      set(state => {
        const newUpdatingWidgetGroupIds = new Set([...state.updatingWidgetGroupIds, widgetGroupId]);
        return { updatingWidgetGroupIds: newUpdatingWidgetGroupIds };
      }),
    removeUpdatingWidgetGroupId: (widgetGroupId: Muid) =>
      set(state => {
        const newUpdatingWidgetGroupIds = new Set([...state.updatingWidgetGroupIds]);
        newUpdatingWidgetGroupIds.delete(widgetGroupId);
        return { updatingWidgetGroupIds: newUpdatingWidgetGroupIds };
      }),
    clearUpdatingWidgetGroupIds: () => set({ updatingWidgetGroupIds: new Set() }),
  };
});
