import { Button, Icon } from 'components/design/next';
import { useOneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import * as React from 'react';
import { TasksTableUtils } from '../tasks-table/tasks-table-utils';

export type NewTaskRowRendererProps = {
  data?: TasksTableUtils.NewTaskRow;
};

export const NewTaskRowRenderer = (props: NewTaskRowRendererProps) => {
  const { createAttachedTask, createProjectTask } = useOneOffTaskDrawerStore();

  const checklist = props?.data?.checklist;

  const handleOnClick = () => {
    if (checklist) {
      createAttachedTask({
        preselectedChecklistId: checklist.id,
        preselectedTemplateId: checklist.template.id,
      });
    } else {
      createProjectTask();
    }
  };

  return (
    <Button
      display="flex"
      justifyContent="flex-start"
      w="full"
      h="9"
      mt="0"
      mb="2"
      pl="12" // width of checkbox column
      onClick={handleOnClick}
      borderRadius="none"
      fontWeight="medium"
      colorScheme="gray"
      color="gray.400"
      backgroundColor="inherit"
      _hover={{
        backgroundColor: 'gray.100',
      }}
      _active={{
        backgroundColor: 'gray.50',
      }}
      leftIcon={<Icon icon="plus" size="3" />}
      iconSpacing="2"
    >
      New
    </Button>
  );
};
