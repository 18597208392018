import angular from 'angular';
import { connectService } from 'reducers/util';

angular
  .module('frontStreetApp.services')
  .service('ChecklistRevisionService', function ($ngRedux, ChecklistRevisionActions) {
    const self = this;

    connectService('ChecklistRevisionService', $ngRedux, null, ChecklistRevisionActions)(self);

    /**
     * Gets active checklist revision by checklist id
     *
     * @param checklistId
     * @return {Promise}
     */
    self.getActiveByChecklistId = function (checklistId) {
      return self.actions.getActiveByChecklistId(checklistId).then(action => action.payload);
    };

    /**
     * Schedules checklist for migration
     * @param checklistRevisionId
     * @returns {Promise}
     */
    self.scheduleOneMigrationByChecklistRevisionId = function (checklistRevisionId) {
      return self.actions.scheduleOneMigrationByChecklistRevisionId(checklistRevisionId);
    };

    /**
     * Cancels scheduled migration (if possible) and migrates one synchronously
     * @param checklistRevisionId
     * @returns {Promise}
     */
    self.migrateOneWithPriorityByChecklistRevisionId = function (checklistRevisionId) {
      return self.actions.migrateOneWithPriorityByChecklistRevisionId(checklistRevisionId);
    };
  });
