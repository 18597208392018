import { TemplateRevision, TemplateRevisionStatus } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { CreateTemplateRevisionMutation, PublishDraftMutation } from 'features/template-revisions/query-builder';
import {
  GetNewestTemplateRevisionsByTemplateIdQuery,
  GetNewestTemplateRevisionsByTemplateIdQueryResponse,
  GetTemplateQueryResponse,
  makeGetNewestTemplateRevisionsByTemplateIdQueryObserver,
  makeGetTemplateQueryObserver,
} from 'features/template/query-builder';
import { ToastServiceImpl } from 'services/toast-service.impl';
import {
  makeQueryMachine,
  QueryActor,
  QueryActorSelectors,
  RefetchOnMountOnly,
  SystemUpdateEvent,
} from 'utils/query-builder';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { ActorRefFrom, assign, createMachine, sendParent, spawn, StateFrom } from 'xstate';
import { SharedContext } from '../use-shared-context';
import { ScheduleAllMigrationsByTemplateRevisionIdMutation } from 'features/checklist-revisions/query-builder';
import { makeErrorLoggerAction } from 'app/utils/machines';

interface MakeTopBarMachineArgs {
  sharedContext: SharedContext;
}

type Context = {
  // queries
  templateQuery?: QueryActor<GetTemplateQueryResponse>;
  templateRevisionsQuery?: QueryActor<GetNewestTemplateRevisionsByTemplateIdQueryResponse>;
};

type ParentEvent = { type: 'PUBLISH' | 'NEW_DRAFT_CREATED' | 'PUBLISH_SUCCESS' };
export type { ParentEvent as TopBarParentEvent };

type Event =
  | { type: 'PUBLISH' }
  | SystemUpdateEvent<
      | { id: 'template-query'; data: GetTemplateQueryResponse }
      | { id: 'template-revisions-query'; data: GetNewestTemplateRevisionsByTemplateIdQueryResponse }
    >;

const id = 'top-bar';

export const makeTopBarMachine = ({ sharedContext }: MakeTopBarMachineArgs) => {
  const { queryClient, templateId } = sharedContext;

  return createMachine(
    {
      id,
      predictableActionArguments: true,
      schema: {
        context: {} as Context,
        events: {} as Event,
      },
      tsTypes: {} as import('./top-bar-machine.typegen').Typegen0,
      initial: 'loading',
      context: {},
      states: {
        loading: {
          entry: ['assignTemplateQuery', 'assignTemplateRevisionsQuery'],
          on: {
            'xstate.update': { cond: 'areTemplatesReady', target: 'idle' },
          },
        },
        idle: {
          on: { PUBLISH: { target: 'publishing', actions: ['forwardToParent'] } },
        },
        publishing: {
          initial: 'publish',
          states: {
            publish: {
              invoke: [
                {
                  id: 'publishTemplate',
                  src: 'publishAndMigrateTemplate',
                  onDone: {
                    target: 'createNextDraft',
                    actions: ['sendParentPublishSuccess'],
                  },
                  onError: {
                    actions: ['logError', 'openPublishErrorToast'],
                  },
                },
              ],
            },
            createNextDraft: {
              invoke: [
                {
                  id: 'createNextDraftRevision',
                  src: 'createDraftRevisionMutation',
                  onDone: {
                    target: 'done',
                    actions: ['sendParentNewDraftCreated'],
                  },
                  onError: {
                    actions: ['logError', 'openPublishErrorToast'],
                  },
                },
              ],
            },
            done: { type: 'final' },
          },
          onDone: 'idle',
        },
      },
    },
    {
      actions: {
        assignTemplateQuery: assign({
          templateQuery: (_ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetTemplateQueryObserver({ templateId, queryClient, options: RefetchOnMountOnly }),
              }),
              { sync: true, name: 'template-query' },
            );
          },
        }),
        assignTemplateRevisionsQuery: assign({
          templateRevisionsQuery: (_ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetNewestTemplateRevisionsByTemplateIdQueryObserver({
                  templateId,
                  queryClient,
                  options: RefetchOnMountOnly,
                }),
              }),
              { sync: true, name: 'template-revisions-query' },
            );
          },
        }),

        openPublishErrorToast: () => {
          ToastServiceImpl.openToast({
            status: 'error',
            title: `We're having problems publishing the form`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },

        forwardToParent: sendParent((_ctx, evt) => evt),
        sendParentPublishSuccess: sendParent('PUBLISH_SUCCESS'),
        sendParentNewDraftCreated: sendParent('NEW_DRAFT_CREATED'),
        logError: makeErrorLoggerAction(id),
      },
      services: {
        publishAndMigrateTemplate: (ctx, _evt) => {
          const templateRevisionId = getDraftTemplateRevision(ctx)!.id;
          const publishMutation = makeMutation(queryClient, {
            mutationKey: PublishDraftMutation.key,
            mutationFn: () => PublishDraftMutation.mutationFn(templateRevisionId),
            onSuccess: data => {
              queryClient.setQueryData(GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId }), () => [
                data,
              ]);
            },
          });
          const scheduleMigrationsMutation = makeMutation(queryClient, {
            mutationKey: ScheduleAllMigrationsByTemplateRevisionIdMutation.key,
            mutationFn: () => ScheduleAllMigrationsByTemplateRevisionIdMutation.mutationFn({ templateRevisionId }),
          });
          return publishMutation.execute().then(() => scheduleMigrationsMutation.execute());
        },
        createDraftRevisionMutation: async context => {
          const draftRevision = QueryActorSelectors.getQueryData(context.templateRevisionsQuery)?.find(
            revision => revision.status === TemplateRevisionStatus.Draft,
          );

          if (draftRevision) return draftRevision;

          return makeMutation<TemplateRevision>(queryClient, {
            mutationFn: () => CreateTemplateRevisionMutation.mutationFn({ templateId }),
            onSuccess: data => {
              queryClient.setQueryData<GetNewestTemplateRevisionsByTemplateIdQueryResponse>(
                GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId }),
                current => [current![0], data],
              );
            },
          }).execute();
        },
      },
      guards: {
        areTemplatesReady: (ctx, _evt) => {
          return (
            QueryActorSelectors.isQueryActorSuccess(ctx.templateQuery) &&
            QueryActorSelectors.isQueryActorSuccess(ctx.templateRevisionsQuery)
          );
        },
      },
    },
  );
};

export type TopBarMachine = ReturnType<typeof makeTopBarMachine>;
export type TopBarActorRef = ActorRefFrom<TopBarMachine>;

type State = StateFrom<TopBarMachine>;

function getDraftTemplateRevision(context: Context) {
  return QueryActorSelectors.getQueryData(context.templateRevisionsQuery)?.[1];
}

function getPublishedTemplateRevision(context: Context) {
  return QueryActorSelectors.getQueryData(context.templateRevisionsQuery)?.[0];
}

function getTemplate(state: State) {
  return QueryActorSelectors.getQueryData(state.context.templateQuery);
}

function getTemplateRevision(state: State) {
  return getDraftTemplateRevision(state.context) ?? getPublishedTemplateRevision(state.context);
}

function getIsPublishing(state: State) {
  return state.matches('publishing');
}

export const TopBarActorSelectors = {
  getTemplate,
  getTemplateRevision,
  getIsPublishing,
};
