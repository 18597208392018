import * as React from 'react';

import { ListItem, ListItemProps } from 'components/design/next';

import { useHover } from 'react-use';

type CommentListItemProps = {
  children: (props: { hovered: boolean }) => React.ReactNode;
} & Omit<ListItemProps, 'children'>;

export const HoverableListItem = ({ children, ...props }: CommentListItemProps) => {
  const [hoverable] = useHover((hovered: boolean) => (
    <ListItem
      display="flex"
      border="1px"
      borderColor="gray.200"
      borderRadius="sm"
      px={{ base: 4, lg: 6 }}
      py={{ base: 1, lg: 2 }}
      bg="white"
      width="full"
      height="min-content"
      mt="0"
      minW="0"
      flex="1"
      alignItems="flex-start"
      {...props}
    >
      {children({ hovered })}
    </ListItem>
  ));
  return hoverable;
};
