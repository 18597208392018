import { Checkbox, CheckboxProps } from 'components/design/next';
import * as React from 'react';
import { OnboardingSettingsKey } from 'components/user/user-constants';
import { useOnboardingSettings } from 'pages/templates/_id/components/template-settings-modal/use-onboarding-settings';

const SETTING_KEY = OnboardingSettingsKey.DISABLE_WORKFLOW_SETUP_WIZARD;

export const ShowAtStartCheckbox: React.FC<React.PropsWithChildren<CheckboxProps>> = props => {
  const { onboardingSettingsQuery, updateOnboardingSettings } = useOnboardingSettings();

  // if we mount the component, that means 'show on start' is enabled
  const [isChecked, setIsChecked] = React.useState(true);
  const setting = onboardingSettingsQuery.data?.[SETTING_KEY];

  React.useEffect(() => {
    if (setting) {
      // disable setting on -> checkbox is unchecked
      setIsChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
  }, [onboardingSettingsQuery.data]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const newIsChecked = e.target.checked;
    setIsChecked(newIsChecked);
    // the setting 'hide' is the inverse of checkbox value
    // (so that default is checked for new users)
    const newSetting = !newIsChecked;
    if (onboardingSettingsQuery.isSuccess && setting !== newSetting) {
      updateOnboardingSettings({
        [SETTING_KEY]: newSetting,
      });
    }
  };

  return (
    <Checkbox {...props} isChecked={isChecked} onChange={onChange}>
      Show setup at start
    </Checkbox>
  );
};
