import * as React from 'react';
import { PagesRenderElementProps } from '../pages-plate-types';
import { serialize } from '../utils/serialization';
import { EditorParagraph } from './elements/paragraph';

// can't use React.FC here based on slate editor props
export const _Paragraph = ({ attributes, children }: PagesRenderElementProps): JSX.Element => {
  return <EditorParagraph {...attributes}>{children}</EditorParagraph>;
};

export const Paragraph = React.memo(_Paragraph, (a, b) => {
  return serialize(a.element) === serialize(b.element);
});
