import * as React from 'react';
import { HStack, Icon, Link, Text, Tooltip, useBreakpointValue, VStack } from 'components/design/next';
import { FocusBarIconButton } from 'components/focus-bar/workflow/right-button-group/edit/focus-bar-icon-button';
import { PreviewAsMenu } from './preview-as-menu';
import { useCurrentUser } from 'hooks/use-current-user';
import { Checklist, ChecklistRevision, Template, TemplateRevision } from '@process-street/subgrade/process';
import { RedirectNotificationsToggle } from 'features/template/components/sandbox-focus-bar/redirect-notifications-toggle';
import { SandboxRefreshButton } from 'features/template/components/sandbox-refresh-button';
import { MobileMeatballMenu } from 'features/template/components/sandbox-focus-bar/mobile-meatball-menu';
import { RestartSandboxButton } from './restart-sandbox-button';
import { ThemeProvider2024 } from 'components/design/next/theme-provider-2024';

export interface SandboxFocusBarProps {
  checklistRevision: ChecklistRevision;
  templateRevision: TemplateRevision;
}

const trimSandboxSuffix = (templateName: string) => {
  const suffix = ' - Sandbox';
  if (templateName.endsWith(suffix)) {
    return templateName.slice(0, -suffix.length);
  }
  return templateName;
};

export const SandboxFocusBar: React.FC<React.PropsWithChildren<SandboxFocusBarProps>> = ({
  checklistRevision,
  templateRevision,
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const handleClose = () => window.close();
  const currentUser = useCurrentUser();
  const templateName = trimSandboxSuffix(((checklistRevision.checklist as Checklist).template as Template).name);
  const templateId = ((checklistRevision.checklist as Checklist).template as Template).copiedFromTemplateId;
  return (
    <ThemeProvider2024>
      <HStack
        h="16"
        w="full"
        py="2"
        px={['4', '4', '6']}
        borderBottom="none"
        borderBottomColor="transparent"
        position="fixed"
        background="gray.50"
        zIndex="docked"
        justify="space-between"
      >
        {isMobile ? (
          <VStack minWidth="0" width="full">
            <HStack justifyContent="space-between" width="full">
              <HStack color="gray.600">
                <Text>Previewing</Text>
                {currentUser && (
                  <PreviewAsMenu
                    currentUser={currentUser}
                    checklistRevision={checklistRevision}
                    templateRevision={templateRevision}
                  />
                )}
              </HStack>
              <MobileMeatballMenu
                checklistRevisionId={checklistRevision.id}
                templateId={templateId}
                templateName={templateName}
              />
            </HStack>
          </VStack>
        ) : (
          <HStack minWidth="0" alignItems="center" flex="1" justifyContent="space-between">
            <HStack color="gray.600" spacing="2" maxW="90%">
              <Icon icon="play" variant="fas" size="3" color="gray.600" />
              <Text fontWeight="medium" fontSize="lg" maxW="250px" noOfLines={1}>
                {(checklistRevision.checklist as Checklist).name}
              </Text>
              <RestartSandboxButton templateId={templateId} templateName={templateName} />
            </HStack>
            <HStack>
              <Tooltip
                label={
                  <Text fontSize="xs">
                    In sandbox, automations like Zapier and Salesforce are disabled. Learn more&nbsp;
                    <Link textDecoration="underline" href="https://www.process.st/help/docs/sync-changes/" isExternal>
                      here
                    </Link>
                    .
                  </Text>
                }
                placement="bottom"
                pointerEvents="all"
                closeDelay={1500}
                shouldWrapChildren
                hasArrow
              >
                <Text>Previewing as</Text>
              </Tooltip>
              {currentUser && (
                <PreviewAsMenu
                  currentUser={currentUser}
                  checklistRevision={checklistRevision}
                  templateRevision={templateRevision}
                />
              )}
            </HStack>

            <HStack spacing="5">
              <RedirectNotificationsToggle />
              <SandboxRefreshButton checklistRevisionId={checklistRevision.id} />
              <FocusBarIconButton
                aria-label="Close sandbox"
                border="none"
                icon={<Icon icon="close" size="5" />}
                onClick={handleClose}
              />
            </HStack>
          </HStack>
        )}
      </HStack>
    </ThemeProvider2024>
  );
};
