import { Muid } from '@process-street/subgrade/core';
import { ChecklistRevision } from '@process-street/subgrade/process';
import { SuccessAction } from '@process-street/subgrade/redux/types';
import { CHECKLIST_REVISION_GET_ACTIVE_BY_CHECKLIST } from 'reducers/checklist-revision/checklist-revision.actions';
import { handleSuccessActions } from 'reducers/util';

export interface NavigationModuleState {
  readonly checklistId?: Readonly<Muid>;
  readonly checklistRevisionId?: Readonly<Muid>;
}

export const navigationModuleReducer = handleSuccessActions<NavigationModuleState>(
  {
    [CHECKLIST_REVISION_GET_ACTIVE_BY_CHECKLIST]: (
      state: NavigationModuleState,
      action: SuccessAction<ChecklistRevision, null>,
    ) => {
      return {
        ...state,
        checklistId: action.payload.checklist.id,
        checklistRevisionId: action.payload.id,
      };
    },
  },
  {
    checklistId: undefined,
    checklistRevisionId: undefined,
  },
);
