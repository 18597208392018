import { Muid } from '@process-street/subgrade/core';
import { noop } from '@process-street/subgrade/util';
import { useInjector } from 'components/injection-provider';
import {
  RA_RULE_CREATE,
  RA_RULE_CREATE_ALL,
  RA_RULE_DELETE,
} from 'components/role-assignments/store/role-assignment-rules.actions';
import {
  TT_ASSIGNMENT_CREATE,
  TT_ASSIGNMENT_CREATE_ALL,
  TT_ASSIGNMENT_DELETE,
} from 'components/template-task-assignment/store/template-task-assignment.actions';
import {
  CreateAllTaskAssignmentRulesMutation,
  CreateTaskAssignmentRuleMutation,
  useCreateAllTaskAssignmentRulesMutation,
  useCreateTaskAssignmentRuleMutation,
  DeleteTaskAssignmentRuleMutation,
  useDeleteTaskAssignmentRuleMutation,
} from 'features/task-assignment-rules/query-builder';
import {
  AssignAllTaskTemplatesMutation,
  useAssignAllTaskTemplatesMutation,
  UnassignTaskTemplateMutation,
  useUnassignTaskTemplateMutation,
  AssignTaskTemplateMutation,
  useAssignTaskTemplateMutation,
} from 'features/task-template-assignment/query-builder';
import React from 'react';
import { QueryKey, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';
import { Mutation } from 'react-query/types/core/mutation';
import { useDispatch } from 'react-redux';
import { toSuccess } from 'reducers/util';
import { makeMutationListener } from 'services/react-query';
import { match } from 'ts-pattern';

const KEYS: QueryKey[] = [
  CreateTaskAssignmentRuleMutation.key,
  CreateAllTaskAssignmentRulesMutation.key,
  AssignTaskTemplateMutation.key,
  AssignAllTaskTemplatesMutation.key,
  DeleteTaskAssignmentRuleMutation.key,
  UnassignTaskTemplateMutation.key,
];

type Args = { templateRevisionId: Muid };

type MutationFrom<Result extends (...args: any[]) => UseMutationResult<any, any, any, any>> = Result extends (
  options: infer Options,
) => UseMutationResult<any, any, any, any>
  ? Options extends UseMutationOptions<infer Data, infer Error, infer Variables, infer Context>
    ? Mutation<Data, Error, Variables, Context>
    : never
  : Result extends (...args: any[]) => UseMutationResult<infer Data, infer Error, infer Variables, infer Context>
  ? Mutation<Data, Error, Variables, Context>
  : never;

type AnyMutation =
  | MutationFrom<typeof useCreateTaskAssignmentRuleMutation>
  | MutationFrom<typeof useCreateAllTaskAssignmentRulesMutation>
  | MutationFrom<typeof useAssignTaskTemplateMutation>
  | MutationFrom<typeof useAssignAllTaskTemplatesMutation>
  | MutationFrom<typeof useDeleteTaskAssignmentRuleMutation>
  | MutationFrom<typeof useUnassignTaskTemplateMutation>;

export function useAssignmentPickerReduxSync({ templateRevisionId }: Args) {
  const { UserService } = useInjector('UserService');
  const queryClient = useQueryClient();
  const mutationCache = queryClient.getMutationCache();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const unsubscribe = mutationCache.subscribe(
      makeMutationListener<AnyMutation>({
        keys: KEYS,
        onSuccess: mutation => {
          if (!mutation.options.mutationKey) return;
          match(mutation)
            .when(
              (m): m is MutationFrom<typeof useCreateTaskAssignmentRuleMutation> => {
                return m.options.mutationKey === CreateTaskAssignmentRuleMutation.key;
              },
              m => {
                const rule = m.state.data;
                dispatch({ type: RA_RULE_CREATE, payload: rule, meta: { templateRevisionId } });
                dispatch({ type: toSuccess(RA_RULE_CREATE), payload: rule, meta: { templateRevisionId } });
              },
            )
            .when(
              (m): m is MutationFrom<typeof useCreateAllTaskAssignmentRulesMutation> => {
                return m.options.mutationKey === CreateAllTaskAssignmentRulesMutation.key;
              },
              m => {
                const rules = m.state.data;
                dispatch({ type: RA_RULE_CREATE_ALL, payload: rules, meta: { templateRevisionId } });
                dispatch({ type: toSuccess(RA_RULE_CREATE_ALL), payload: rules, meta: { templateRevisionId } });
              },
            )
            .when(
              (m): m is MutationFrom<typeof useAssignTaskTemplateMutation> => {
                return m.options.mutationKey === AssignTaskTemplateMutation.key;
              },
              m => {
                const assignment = m.state.data;
                if (!assignment) return;
                const assigneeLabel = UserService.getUserLabelByMembership(assignment?.organizationMembership);
                dispatch({
                  type: TT_ASSIGNMENT_CREATE,
                  payload: assignment,
                  meta: { templateRevisionId, assigneeLabel },
                });
                dispatch({ type: toSuccess(TT_ASSIGNMENT_CREATE), payload: assignment, meta: { templateRevisionId } });
              },
            )
            .when(
              (m): m is MutationFrom<typeof useAssignAllTaskTemplatesMutation> => {
                return m.options.mutationKey === AssignAllTaskTemplatesMutation.key;
              },
              m => {
                const assignments = m.state.data;
                if (!assignments) return;
                const assigneeLabel = UserService.getUserLabelByMembership(assignments[0].organizationMembership);
                dispatch({
                  type: TT_ASSIGNMENT_CREATE_ALL,
                  payload: assignments,
                  meta: { templateRevisionId, assigneeLabel },
                });
                dispatch({
                  type: toSuccess(TT_ASSIGNMENT_CREATE_ALL),
                  payload: assignments,
                  meta: { templateRevisionId },
                });
              },
            )
            .when(
              (m): m is MutationFrom<typeof useUnassignTaskTemplateMutation> => {
                return m.options.mutationKey === UnassignTaskTemplateMutation.key;
              },
              m => {
                const { assignmentId } = m.state.variables ?? {};
                dispatch({
                  type: TT_ASSIGNMENT_DELETE,
                  meta: { assignmentId, templateRevisionId, assigneeLabel: m.state.context?.assigneeLabel },
                });
                dispatch({ type: toSuccess(TT_ASSIGNMENT_DELETE), meta: { assignmentId, templateRevisionId } });
              },
            )
            .when(
              (m): m is MutationFrom<typeof useDeleteTaskAssignmentRuleMutation> => {
                return m.options.mutationKey === DeleteTaskAssignmentRuleMutation.key;
              },
              m => {
                const { ruleId } = m.state.variables ?? {};
                dispatch({ type: RA_RULE_DELETE, meta: { ruleId, templateRevisionId } });
                dispatch({ type: toSuccess(RA_RULE_DELETE), meta: { ruleId, templateRevisionId } });
              },
            )
            .otherwise(noop);
        },
      }),
    );

    return () => {
      unsubscribe();
    };
  }, [UserService, dispatch, mutationCache, templateRevisionId]);
}
