import { OrganizationMembership } from './organization-model';
import { User, UserType } from './user-model';

export enum SandboxRole {
  Anonymous = 'Anonymous',
  Guest = 'Guest',
}

export interface SandboxUser {
  user: User;
  organizationMembership: OrganizationMembership;
}

export const mapUserTypeToSandboxRole = (userType: UserType): SandboxRole => {
  switch (userType) {
    case UserType.Anonymous:
      return SandboxRole.Anonymous;
    case UserType.Sandbox:
      return SandboxRole.Guest;
    default:
      throw new Error(`Unsupported UserType: ${userType}`);
  }
};
