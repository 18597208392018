import { Muid } from '@process-street/subgrade/core';
import { MergeTagTarget } from '@process-street/subgrade/form';
import {
  FormFieldKeysContext,
  FormFieldWidget,
  TaskTemplate,
  TaskTemplateTaskType,
  TemplateRevision,
  Widget,
} from '@process-street/subgrade/process';
import { StringUtils } from '@process-street/subgrade/util';
import { IRootScopeService, IScope } from 'angular';
import { AxiosError } from 'axios';
import { useInjector } from 'components/injection-provider';
import { queryClient as defaultQueryClient } from 'components/react-root';
import { WidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { isDataSetLinkedSelectWidget } from 'features/widgets/query-builder/update-form-field-value';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { GetDataSetMergeTagsByTemplateRevisionQuery } from 'pages/reports/data-sets/query-builder/get-data-set-merge-tags';
import * as React from 'react';
import { QueryClient, QueryFunctionContext, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { EventName } from 'services/event-name';
import { MergeTagsServiceUtils, Tags } from 'services/merge-tags';
import { WidgetServiceUtils } from 'services/widget-service.utils';
import { match } from 'ts-pattern';
import { GLOBAL_TAB, MergeTagTabTitle, WORKFLOW_TAB } from '../components/merge-tags-menu';
import { useFeatureFlag } from 'features/feature-flags';

export type MergeTagMenuGroupTitle = 'global variables' | 'workflow variables' | (string & {});
const GLOBAL_VARIABLES: MergeTagMenuGroupTitle = 'global variables';
const WORKFLOW_VARIABLES: MergeTagMenuGroupTitle = 'workflow variables';

export type MergeTagsByTemplateRevisionIdQueryParams = {
  templateRevisionId: Muid;
  mergeTagTarget?: MergeTagTarget;
  includeLegacyTags?: boolean;
};

export type MergeTagsByTemplateRevisionIdQueryResponse<
  Target extends MergeTagTarget = MergeTagTarget,
  UseLegacy extends boolean = true,
> = {
  mergeTagTarget: MergeTagTarget;
  tags: Tags<Target, UseLegacy>;
  widgets: Widget[];
  widgetsGroupedByTask: Record<string, Record<string, FormFieldWidget>>;
};

const keyFactory = {
  key: ['merge-tags', 'template-revision-id'],
  getKey: ({ templateRevisionId, ...rest }: MergeTagsByTemplateRevisionIdQueryParams) => {
    const base: any[] = [...keyFactory.key, templateRevisionId];
    // add additional config query parameters to the last array element
    return isEmpty(rest) ? base : [...base, rest];
  },
};
type QueryKey = ReturnType<typeof keyFactory['getKey']>;

const getQueryParamsFromKey = (key: QueryKey) => {
  const [, , templateRevisionId, rest] = key;
  return {
    mergeTagTarget: rest?.mergeTagTarget,
    includeLegacyTags: rest?.includeLegacyTags,
    templateRevisionId,
  } as MergeTagsByTemplateRevisionIdQueryParams;
};

export const MergeTagsByTemplateRevisionIdQuery = {
  ...keyFactory,
  /**
   * This is somewhat of an experimental query. We're passing the query client to leverage the widgets request cache,
   * then caching the result of the widget and merge tag service transformations.
   * An alternative would be to just use the widgets query in angular and react, and use domain-specific strategies
   * for memoizing and/or caching there, but this is a nice way to make sure both "worlds" are leveraging the same
   * cache.
   */
  queryFn:
    (queryClient = defaultQueryClient, isTableFormFieldEnabled = false) =>
    async <
      Context extends QueryFunctionContext<QueryKey>,
      Target extends MergeTagTarget = Context extends [
        {
          queryKey: { mergeTagTarget: infer T };
        },
      ]
        ? T
        : MergeTagTarget,
      UseLegacy extends boolean = Context extends [{ queryKey: { includeLegacyTags: infer U } }] ? U : true,
    >({
      queryKey,
    }: Context): Promise<MergeTagsByTemplateRevisionIdQueryResponse<Target, UseLegacy>> => {
      const { templateRevisionId, mergeTagTarget, includeLegacyTags } = getQueryParamsFromKey(queryKey);
      const widgetsP = queryClient.fetchQuery(WidgetsByTemplateRevisionIdQuery.getKey(templateRevisionId), () =>
        WidgetsByTemplateRevisionIdQuery.queryFn(templateRevisionId),
      );

      const dataSetMergeTagsResultP = queryClient.fetchQuery(
        GetDataSetMergeTagsByTemplateRevisionQuery.getKey({ templateRevisionId }),
        () => GetDataSetMergeTagsByTemplateRevisionQuery.queryFn({ templateRevisionId }),
        { staleTime: Infinity },
      );

      const [widgets, dataSetMergeTags] = await Promise.all([widgetsP, dataSetMergeTagsResultP]);
      const keys = WidgetServiceUtils.getFormFieldWidgetKeyMap(widgets, FormFieldKeysContext.MERGE_TAG);
      const widgetsGroupedByTask = WidgetServiceUtils.getFormFieldWidgetWithoutDatasetsGroupedByTaskKeyMap(
        widgets,
        FormFieldKeysContext.MERGE_TAG,
      );
      const tags = MergeTagsServiceUtils.getTagsFromKeys(keys, mergeTagTarget ?? MergeTagTarget.GENERAL, {
        includeLegacyTags,
        includeInitialTags: true,
        isTableFormFieldEnabled,
      });
      const dataSetTags = MergeTagsServiceUtils.getTagLabelsFromDataSetMergeTags(dataSetMergeTags);

      return {
        mergeTagTarget,
        tags: { ...tags, ...dataSetTags },
        widgets,
        widgetsGroupedByTask,
      } as MergeTagsByTemplateRevisionIdQueryResponse<Target, UseLegacy>;
    },
  watchWidgetChanges: ({
    queryClient,
    $scope,
    queryParams,
  }: {
    queryClient: QueryClient;
    $scope: IScope | IRootScopeService;
    queryParams: MergeTagsByTemplateRevisionIdQueryParams;
  }) => {
    const { templateRevisionId } = queryParams;
    const widgetsSetter = WidgetsByTemplateRevisionIdQuery.makeCacheSetter({ queryClient, templateRevisionId });
    const listeners = [
      $scope.$on(EventName.WIDGET_UPDATE_OK, async (_event, widget: Widget) => {
        widgetsSetter.update(widget);
        if (isDataSetLinkedSelectWidget(widget)) {
          await queryClient.invalidateQueries(
            GetDataSetMergeTagsByTemplateRevisionQuery.getKey({ templateRevisionId }),
          );
          await queryClient.invalidateQueries(MergeTagsByTemplateRevisionIdQuery.getKey({ templateRevisionId }));
        }
        queryClient.invalidateQueries(MergeTagsByTemplateRevisionIdQuery.getKey(queryParams));
      }),
      $scope.$on(EventName.WIDGET_CREATE_OK, (_event, widget: Widget) => {
        widgetsSetter.add(widget);
        queryClient.invalidateQueries(MergeTagsByTemplateRevisionIdQuery.getKey(queryParams));
      }),
      $scope.$on(EventName.WIDGET_DELETE_OK, async (_event, widget: Widget) => {
        widgetsSetter.delete(widget);
        if (isDataSetLinkedSelectWidget(widget)) {
          await queryClient.invalidateQueries(
            GetDataSetMergeTagsByTemplateRevisionQuery.getKey({ templateRevisionId }),
          );
          await queryClient.invalidateQueries(MergeTagsByTemplateRevisionIdQuery.getKey({ templateRevisionId }));
        }
        queryClient.invalidateQueries(MergeTagsByTemplateRevisionIdQuery.getKey(queryParams));
      }),
      $scope.$on(EventName.TASK_TEMPLATE_BULK_DELETE_OK, (_event, taskTemplates: TaskTemplate[]) => {
        widgetsSetter.deleteByTaskTemplates(taskTemplates);
        queryClient.invalidateQueries(MergeTagsByTemplateRevisionIdQuery.getKey(queryParams));
        queryClient.invalidateQueries(GetDataSetMergeTagsByTemplateRevisionQuery.getKey({ templateRevisionId }));
      }),
    ];

    return () => {
      listeners.forEach(listener => listener());
    };
  },
  useWatchWidgetChanges,
  invalidate: async (queryClient: QueryClient, templateRevisionId?: TemplateRevision['id']) => {
    if (!templateRevisionId) {
      await queryClient.invalidateQueries(WidgetsByTemplateRevisionIdQuery.key);
      await queryClient.invalidateQueries(GetDataSetMergeTagsByTemplateRevisionQuery.key);
      await queryClient.invalidateQueries(MergeTagsByTemplateRevisionIdQuery.key);
    } else {
      await queryClient.invalidateQueries(WidgetsByTemplateRevisionIdQuery.getKey(templateRevisionId));
      await queryClient.invalidateQueries(GetDataSetMergeTagsByTemplateRevisionQuery.getKey({ templateRevisionId }));
      await queryClient.invalidateQueries(MergeTagsByTemplateRevisionIdQuery.getKey({ templateRevisionId }));
    }
  },
  groupMergeTags,
  getWorkflowMergeTagsTab,
  getGlobalMergeTagsTab,
  getDataSetsMergeTagsTab,
  groupMergeTagsTabs,
  GLOBAL_VARIABLES,
  WORKFLOW_VARIABLES,
};

export const useMergeTagsByTemplateRevisionIdQuery = <
  Params extends MergeTagsByTemplateRevisionIdQueryParams,
  Target extends MergeTagTarget = Params extends { mergeTagTarget: infer T } ? T : MergeTagTarget,
  UseLegacy extends boolean = Params extends { includeLegacyTags: infer T } ? T : true,
  Select = MergeTagsByTemplateRevisionIdQueryResponse<Target, UseLegacy>,
>(
  params: Params,
  options: UseQueryOptions<MergeTagsByTemplateRevisionIdQueryResponse, AxiosError, Select, QueryKey> = {},
) => {
  const isTableFormFieldEnabled = useFeatureFlag('tableFormField');
  const queryClient = useQueryClient();
  return useQuery(
    MergeTagsByTemplateRevisionIdQuery.getKey(params),
    MergeTagsByTemplateRevisionIdQuery.queryFn(queryClient, isTableFormFieldEnabled),
    options,
  );
};

function useWatchWidgetChanges({
  templateRevisionId,
  mergeTagTarget,
  includeLegacyTags,
}: MergeTagsByTemplateRevisionIdQueryParams) {
  const { $rootScope } = useInjector('$rootScope');
  const queryClient = useQueryClient();
  React.useEffect(() => {
    return MergeTagsByTemplateRevisionIdQuery.watchWidgetChanges({
      queryClient,
      queryParams: { templateRevisionId, mergeTagTarget, includeLegacyTags },
      $scope: $rootScope,
    });
  }, [$rootScope, queryClient, mergeTagTarget, templateRevisionId, includeLegacyTags]);
}

function groupMergeTags(query: string) {
  return ({ tags, widgets }: MergeTagsByTemplateRevisionIdQueryResponse) => {
    const dataSetKeySavedViewNamesMap = widgets.filter(isDataSetLinkedSelectWidget).reduce((acc, widget) => {
      acc[`form.${widget.key}`] = widget.config.linkedSavedViewName;
      return acc;
    }, {} as Record<string, string>);

    const mappedAndSorted = Object.entries(tags)
      .map(([key, value]) => ({ key, value }))
      .sort(({ value: valueA }, { value: valueB }) => valueA.localeCompare(valueB));

    const grouped = groupBy(mappedAndSorted, tag =>
      match<string, MergeTagMenuGroupTitle>(tag.key)
        .when(
          key => Object.keys(dataSetKeySavedViewNamesMap).find(k => key.startsWith(k)),
          key => {
            const rootKey = key.split('.').slice(0, 2).join('.');
            return `${dataSetKeySavedViewNamesMap[rootKey]} variables`;
          },
        )
        .when(
          key => key.match(/^(current|organization)/),
          () => GLOBAL_VARIABLES,
        )
        .otherwise(() => WORKFLOW_VARIABLES),
    );
    const filtered = Object.entries(grouped)
      .map(
        ([group, tags]) =>
          [
            group,
            tags.filter(({ key, value }) => {
              const isDataSet = Object.keys(dataSetKeySavedViewNamesMap).find(k => key.startsWith(k));
              const isDataSetRoot = isDataSet && key.split('.').length === 2;
              if (isDataSetRoot) return false;

              const matchesQuery = [group, key, value].some(str => StringUtils.containsIgnoreCase(str, query));
              return matchesQuery;
            }),
          ] as [typeof group, typeof tags],
      )
      .filter(([group, tags]) => {
        return tags.length > 0 || StringUtils.containsIgnoreCase(group, query);
      });

    return sortBy(filtered, [byGlobal, byWorkflow, byKey]);
  };
}

function byGlobal<T extends [string, ...any[]]>([key]: T) {
  return key === GLOBAL_VARIABLES || key === GLOBAL_TAB ? -1 : 1;
}

function byWorkflow<T extends [string, ...any[]]>([key]: T) {
  return key === WORKFLOW_VARIABLES || key === WORKFLOW_TAB ? -1 : 1;
}

function byKey<T extends [string, ...any[]]>([key]: T) {
  return key;
}

function getWorkflowMergeTagsTab(
  taskTemplates: TaskTemplate[],
  isTableFormFieldEnabled: boolean,
  isNewWorkflowEditorEnabled: boolean,
) {
  const filteredTaskTemplates = isNewWorkflowEditorEnabled
    ? taskTemplates.filter(tt => tt.taskType !== TaskTemplateTaskType.AI)
    : taskTemplates;

  const getTaskTemplateGroupName = (taskTemplateId: string) => {
    const taskTemplateIndex = filteredTaskTemplates.findIndex(task => task.id === taskTemplateId);
    const taskTemplate = filteredTaskTemplates[taskTemplateIndex];

    if (taskTemplate) {
      return `${taskTemplateIndex + 1}. ${taskTemplate.name}`;
    }
    return '';
  };

  return ({ mergeTagTarget, widgetsGroupedByTask, tags }: MergeTagsByTemplateRevisionIdQueryResponse) => {
    const tagsGroupedByTaskTemplate = Object.entries(widgetsGroupedByTask).reduce(
      (acc, [taskTemplateId, widgetKeys]) => {
        const tags = MergeTagsServiceUtils.getTagsFromKeys(widgetKeys, mergeTagTarget ?? MergeTagTarget.GENERAL, {
          includeLegacyTags: true,
          includeInitialTags: false,
          isTableFormFieldEnabled,
        });

        const mappedAndSorted = Object.entries(tags)
          .map(([key, value]) => ({ key, value }))
          .sort(({ value: valueA }, { value: valueB }) => valueA.localeCompare(valueB));

        const groupName = getTaskTemplateGroupName(taskTemplateId);

        if (mappedAndSorted.length > 0) {
          (acc as Record<string, { key: string; value: string }[]>)[groupName] = mappedAndSorted;
        }

        return acc;
      },
      {} as Record<string, { key: string; value: string }[]>,
    );

    // Workflow specific variables
    const workflowVariables = Object.entries(tags)
      .map(([key, value]) => ({ key, value }))
      .filter(({ key }) => key.match(/^(task|email|run|workflow)/))
      .sort(({ value: valueA }, { value: valueB }) => valueA.localeCompare(valueB));

    const workflowVariablesGroup: Record<string, { key: string; value: string }[]> = {
      other: workflowVariables,
    };

    const total = { ...tagsGroupedByTaskTemplate, ...workflowVariablesGroup };

    return Object.entries(total);
  };
}

function getGlobalMergeTagsTab() {
  return ({ tags }: MergeTagsByTemplateRevisionIdQueryResponse) => {
    return Object.entries(tags)
      .map(([key, value]) => ({ key, value }))
      .filter(({ key }) => key.match(/^(current|organization)/))
      .sort(({ value: valueA }, { value: valueB }) => valueA.localeCompare(valueB));
  };
}

export const DATA_SET_TRIGGER_PREFIX = 'data_set_trigger';
const dataSetTriggerGroup = 'Data Set trigger';

function getDataSetsMergeTagsTab() {
  return ({ tags, widgets }: MergeTagsByTemplateRevisionIdQueryResponse) => {
    const dataSetKeySavedViewNamesMap = widgets.filter(isDataSetLinkedSelectWidget).reduce((acc, widget) => {
      acc[`form.${widget.key}`] = widget.config.linkedSavedViewName;
      return acc;
    }, {} as Record<string, string>);

    const mappedAndSorted = Object.entries(tags)
      .map(([key, value]) => ({ key, value }))
      .filter(({ key }) => {
        const isDataSetTriggerTag = key.startsWith(DATA_SET_TRIGGER_PREFIX);
        const isDatasetTag = Boolean(Object.keys(dataSetKeySavedViewNamesMap).find(k => key.startsWith(k)));
        const isDataSetRoot = key.split('.').length === 2;
        return isDataSetTriggerTag || (isDatasetTag && !isDataSetRoot);
      })
      .sort(({ value: valueA }, { value: valueB }) => valueA.localeCompare(valueB));

    const grouped = groupBy(mappedAndSorted, ({ key }) => {
      const rootKey = key.split('.').slice(0, 2).join('.');
      return key.startsWith(DATA_SET_TRIGGER_PREFIX) ? dataSetTriggerGroup : dataSetKeySavedViewNamesMap[rootKey];
    });

    return Object.entries(grouped).sort();
  };
}

function groupMergeTagsTabs(query: string) {
  return ({ tags, widgets }: MergeTagsByTemplateRevisionIdQueryResponse) => {
    const dataSetKeySavedViewNamesMap = widgets.filter(isDataSetLinkedSelectWidget).reduce((acc, widget) => {
      acc[`form.${widget.key}`] = widget.config.linkedSavedViewName;
      return acc;
    }, {} as Record<string, string>);

    const mappedAndSorted = Object.entries(tags)
      .map(([key, value]) => ({ key, value }))
      .sort(({ value: valueA }, { value: valueB }) => valueA.localeCompare(valueB));

    // When filering, only group by Workglow and Global.
    const grouped = groupBy(mappedAndSorted, tag =>
      match<string, MergeTagTabTitle>(tag.key)
        .when(
          key => key.match(/^(current|organization)/),
          () => GLOBAL_TAB,
        )
        .otherwise(() => WORKFLOW_TAB),
    );
    const filtered = Object.entries(grouped)
      .map(
        ([group, tags]) =>
          [
            group,
            tags.filter(({ key, value }) => {
              const isDataSet = Object.keys(dataSetKeySavedViewNamesMap).find(k => key.startsWith(k));
              const isDataSetRoot = isDataSet && key.split('.').length === 2;
              if (isDataSetRoot) return false;

              const matchesQuery = [group, key, value].some(str => StringUtils.containsIgnoreCase(str, query));
              return matchesQuery;
            }),
          ] as [typeof group, typeof tags],
      )
      .filter(([group, tags]) => {
        return tags.length > 0 || StringUtils.containsIgnoreCase(group, query);
      });

    return sortBy(filtered, [byWorkflow, byGlobal, byKey]);
  };
}
