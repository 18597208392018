import { ChecklistGridCountQuery } from 'features/checklists/query-builder';
import { useStateParam } from 'hooks/use-state-param';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { TemplateType } from '@process-street/subgrade/process';
import {
  ChecklistColumn,
  Clause,
  Condition,
  ConditionalFilterOperandType,
  FilterType,
  ProgressStatus,
} from '@process-street/subgrade/dashboard';

export function useFormResponseCountQuery({
  condition = Condition.Contains,
  progressStatuses = [ProgressStatus.Completed],
}: { progressStatuses?: ProgressStatus[]; condition?: Condition } = {}) {
  const formId = useStateParam({ key: 'id' })!;
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const formCompletedFilter: Clause = {
    id: formId,
    filterType: FilterType.Clause,
    condition,
    columnName: ChecklistColumn.ProgressStatus,
    operand: {
      operandType: ConditionalFilterOperandType.ChecklistStatusList,
      value: progressStatuses,
    },
  };

  return ChecklistGridCountQuery.useQuery({
    organizationId,
    templateTypes: [TemplateType.Form],
    templateIds: [formId],
    filter: [formCompletedFilter],
  });
}
