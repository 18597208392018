export class ObjectUtilsService {
  constructor() {
    'ngInject';
  }

  /**
   * This will make a deep clone of most objects.
   *
   * @param object
   * @returns {any}
   */
  deepClone(object) {
    return JSON.parse(JSON.stringify(object));
  }

  readSession(json) {
    if (!json) {
      return {};
    }

    const { session } = JSON.parse(json);
    return session ? JSON.parse(session) : {};
  }
}
