import { FieldType } from '@process-street/subgrade/process';

export const NON_REVERTIBLE_FIELD_TYPES: Set<FieldType> = new Set([
  FieldType.Text,
  FieldType.Textarea,
  FieldType.Number,
  FieldType.Email,
  FieldType.Date,
]);

export const FormFieldDefaultKey: Record<FieldType, string> = {
  Date: 'date_field',
  Email: 'email_field',
  File: 'file_field',
  Hidden: 'hidden_field',
  Members: 'members_field',
  MultiChoice: 'multi_choice_field',
  MultiSelect: 'multi_select_field',
  Number: 'number_field',
  Select: 'select_field',
  SendRichEmail: 'send_email',
  Snippet: 'snippet_field',
  Table: 'table_field',
  Text: 'text_field',
  Textarea: 'textarea_field',
  Url: 'url_field',
};

export const FormFieldDefaultLabel: Partial<Record<FieldType, string>> = {
  Date: 'Date',
  Email: 'Email',
  Members: 'Member or Group',
  Table: 'Table',
  Url: 'Website',
};
