import { Muid } from '@process-street/subgrade/core';
import {
  FieldType,
  FormFieldConfig,
  Widget,
  WidgetHeader,
  WidgetType,
  WidgetUpdateOrderTreesRequest,
  WidgetUpdateOrderTreesResponse,
} from '@process-street/subgrade/process';
import { axiosService } from 'services/axios-service';

export type CreateWidgetRequest = {
  headerId: Muid;
  taskTemplateId: Muid;
  type: WidgetType;
  orderTree: string;
  content?: string;
  fieldType?: FieldType;
  key?: string;
  label?: string;
  config?: FormFieldConfig;
};

export const createWidget = async (req: CreateWidgetRequest): Promise<Widget> => {
  return axiosService
    .getAxios()
    .put<Widget>(`/1/widgets/${req.headerId}`, req)
    .then(res => res.data);
};

export const updateWidget = <W extends Widget>(widget: W): Promise<W> =>
  axiosService
    .getAxios()
    .patch<W>(`/1/widgets/${widget.header.id}`, widget)
    .then(res => res.data);

export const deleteWidgetByHeaderId = async (headerId: Muid): Promise<Widget> => {
  return axiosService
    .getAxios()
    .delete<Widget>(`/1/widgets/${headerId}`)
    .then(res => res.data);
};

export const undeleteWidgetByHeaderId = async (headerId: Muid): Promise<Widget> => {
  return axiosService
    .getAxios()
    .put<Widget>(`/1/widgets/${headerId}/undelete`)
    .then(res => res.data);
};

export type CopyWidgetOptions = {
  srcHeader: WidgetHeader;
  dstHeader: Pick<WidgetHeader, 'id' | 'taskTemplate' | 'type' | 'orderTree'>;
  key?: string;
  label?: string;
};

export const copyWidget = async (headers: CopyWidgetOptions): Promise<Widget> => {
  return axiosService
    .getAxios()
    .post<Widget>(`/1/widgets/${headers.srcHeader.id}/copy/${headers.dstHeader.id}`, {
      orderTree: headers.dstHeader.orderTree,
      taskTemplateId: headers.dstHeader.taskTemplate.id,
      key: headers.key,
      label: headers.label,
    })
    .then(res => res.data);
};

export const updateOrderTrees = async (
  orderModels: WidgetUpdateOrderTreesRequest,
): Promise<WidgetUpdateOrderTreesResponse> => {
  return axiosService
    .getAxios()
    .put<WidgetUpdateOrderTreesResponse>(`/1/widgets/order-tree`, orderModels)
    .then(res => res.data);
};

// using this to assert the absence of network calls in tests
export const noop = () => {};
