import { LocalStorageService } from 'app/features/storage/local-storage-service';
import { useEffect } from 'react';
import { AI_IMPORT_WORKFLOW_S3_ID, SHOW_AI_WORKFLOW_SETUP_MODAL_FOR } from './wrapper';
import { ImportWorkflowMutation } from 'app/features/ai-generation/query-builder';
import { QueryClient, useQueryClient } from 'react-query';
import { useAiGenerationSlice } from './store';
import { Muid } from '@process-street/subgrade/core';

export type UseImportWorkflowParams = {
  templateRevisionId: Muid;
};

const clearMutationCache = (queryClient: QueryClient) => {
  const mutation = queryClient.getMutationCache().find({ mutationKey: ImportWorkflowMutation.key });

  if (mutation) {
    queryClient.getMutationCache().remove(mutation);
  }
};

export const useImportWorkflow = ({ templateRevisionId }: UseImportWorkflowParams) => {
  const queryClient = useQueryClient();
  const aiGenerationSlice = useAiGenerationSlice();

  const importWorkflowMutation = ImportWorkflowMutation.useMutation({
    onSettled: () => {
      clearMutationCache(queryClient);
    },
  });

  const importWorkflowS3Id = LocalStorageService.getItem(AI_IMPORT_WORKFLOW_S3_ID);
  useEffect(
    function importWorkflow() {
      if (!importWorkflowS3Id) return;

      LocalStorageService.removeItem(AI_IMPORT_WORKFLOW_S3_ID);
      LocalStorageService.removeItem(SHOW_AI_WORKFLOW_SETUP_MODAL_FOR);
      aiGenerationSlice.startWorkflowImport();

      // We look for an existing mutation to prevent running this mutation twice.
      const existingMutation = queryClient.getMutationCache().find({ mutationKey: ImportWorkflowMutation.key });

      // Mutate only once
      if (!existingMutation) {
        importWorkflowMutation.mutate({ templateRevisionId, s3FileId: importWorkflowS3Id });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only once when finding local storage item
    [importWorkflowS3Id],
  );

  return importWorkflowMutation;
};
