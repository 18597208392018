import { Box, Button, HStack, Icon, Text } from 'components/design/next';
import { RunWorkflowButton } from 'components/focus-bar/workflow/run-workflow-button';
import { RunButton } from 'features/new-buttons/run-button/run-button';
import { useTemplateId } from 'pages/templates/_id/automation/utils/use-template-id';
import * as React from 'react';
import { CreateChecklistSource } from 'services/checklist-service.interface';

export const RunWorkflowRenderer = () => {
  const templateId = useTemplateId();
  const content = (
    <Box as={Button} variant="unstyled" w="auto" _hover={{ color: 'brand.500' }}>
      <HStack>
        <Icon variant="far" icon="play" size="4" />
        <Text>Run a workflow</Text>
      </HStack>
    </Box>
  );

  return templateId ? (
    <RunWorkflowButton templateId={templateId}>{content}</RunWorkflowButton>
  ) : (
    <RunButton source={CreateChecklistSource.REPORTS_ROW}>{content}</RunButton>
  );
};
