import * as React from 'react';
import { Text } from 'components/design/next';
import { PagesRenderLeafProps } from '../pages-plate-types';

// can't use React.FC here based on slate editor props
export const SlateLeaf = ({ attributes, children, leaf }: Omit<PagesRenderLeafProps, 'editor'>): JSX.Element => {
  return leaf.placeholder ? (
    <Text as="span" variant="inherit" {...attributes} position="relative">
      {children}
    </Text>
  ) : (
    <Text
      as="span"
      variant="inherit"
      {...attributes}
      {...(leaf.bold ? { fontWeight: 'bold' } : {})}
      {...(leaf.italic ? { fontStyle: 'italic' } : {})}
      {...(leaf.color ? { color: leaf.color } : {})}
      {...(leaf.background ? { background: leaf.background } : {})}
      {...(leaf.underline ? { textDecoration: 'underline' } : {})}
      {...(leaf.strikethrough ? { textDecoration: 'line-through' } : {})}
      // pt needs to be 5px to match selection padding
      // the padding values are carefully matched based on our line height 180% value on Paragraph
      {...(leaf.selected ? { bgColor: 'brand.200', pt: '5px', pb: '1.5' } : {})}
    >
      {children}
    </Text>
  );
};
