import React from 'react';

import { Attachment, Checklist, Task, Template } from '@process-street/subgrade/process';
import { isAdmin, isNotIdRef, S3File, User } from '@process-street/subgrade/core';

import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { HStack, VStack, useDisclosure, Box, Link, useBreakpointValue } from 'components/design/next';
import { HoverableListItem } from '../hoverable-list-item';
import { DeleteButton } from '../delete-button';

import { CDNImage } from 'components/cdn-image';
import { AuditInfoStack } from '../audit-info';
import { DeleteAttachmentDialog } from '../delete-attachment-dialog';
import { isImageFile } from 'features/upload/components/utils';
import { AttachmentFile } from './attachment-file';
import { useFocusComment } from '../use-focus-comment';
import { useGetOrganizationQuery } from 'features/organization/query-builder';
import { DateAuditInfoStack } from '../date-audit-info';

type AttachmentItemProps = {
  attachment: Attachment;
  checklistId: Checklist['id'];
  templateId: Template['id'];
  isFromOneOffTask: boolean;
};

export const AttachmentItem: React.FC<React.PropsWithChildren<AttachmentItemProps>> = ({
  attachment,
  checklistId,
  templateId,
  isFromOneOffTask,
}) => {
  const {
    audit: { createdDate, ...audit },
    file,
    id,
  } = attachment;
  const createdBy = isNotIdRef(audit.createdBy) ? audit.createdBy : ({} as User);
  const task = isNotIdRef(attachment.task) ? attachment.task : ({} as Task);

  const { data } = useGetCurrentUserInfoQuery({
    select: data => ({ currentUser: data.user, userIsAdmin: isAdmin(data?.organizationMembership.role) }),
  });

  const currentUser = data?.currentUser;
  const isCommentAuthor = Boolean(currentUser?.id === createdBy.id);
  const userIsAdmin = Boolean(data?.userIsAdmin);

  const deleteDisclosure = useDisclosure();
  const { isCommentFocused } = useFocusComment({ id });

  const organizationId = attachment.organization.id;
  const organizationQuery = useGetOrganizationQuery({ organizationId });
  const isEnhancedFileSecurityEnabled = organizationQuery.data?.enhancedFileSecurity;
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <HoverableListItem
        id={id}
        key={id}
        border="none"
        w="full"
        px="1"
        py={2}
        bgColor="inherit"
        sx={{
          background: isCommentFocused ? 'brand.100' : 'white',
          transition: 'background 1s',
          transitionDelay: '0.2s',
        }}
      >
        {({ hovered }) => {
          const canDelete = isCommentAuthor || userIsAdmin;
          const shouldDisplay = isMobile || hovered;
          return (
            <HStack alignItems="flex-start" spacing="3" width="full" justifyContent="space-between">
              <VStack alignItems="flex-start" width="full">
                <HStack width="full" h="8">
                  {isFromOneOffTask ? (
                    <DateAuditInfoStack {...{ createdBy, createdDate }} flex="1" />
                  ) : (
                    <AuditInfoStack {...{ createdBy, createdDate }} flex="1" />
                  )}
                  {shouldDisplay && canDelete && <DeleteButton onClick={deleteDisclosure.onOpen} />}
                </HStack>
                {isImageFile(file.mimeType) ? (
                  <Box position="relative" pl={isFromOneOffTask ? '0' : '9'}>
                    <Link href={file.url} target="_blank">
                      <Box position="relative" width="full">
                        <CDNImage
                          {...{
                            isDisabled: isEnhancedFileSecurityEnabled,
                            s3File: file as S3File,
                            alt: file.originalName,
                            transformation: {
                              width: 'auto',
                              height: 300,
                            },
                            maxHeight: '300px',
                          }}
                        ></CDNImage>
                      </Box>
                    </Link>
                  </Box>
                ) : (
                  <AttachmentFile {...{ file }} pl={isFromOneOffTask ? '0' : '9'} />
                )}
              </VStack>
            </HStack>
          );
        }}
      </HoverableListItem>
      <DeleteAttachmentDialog {...{ ...deleteDisclosure, id, task, checklistId, templateId }} />
    </>
  );
};
