import { Icon, Button, Flex, HStack, Text, VStack } from 'components/design/next';
import * as React from 'react';

export type AiGeneratingWorkflowErrorToastProps = {
  onRetry?: () => void;
};

export const AiGeneratingWorkflowErrorToast: React.FC<React.PropsWithChildren<AiGeneratingWorkflowErrorToastProps>> = ({
  onRetry,
}) => (
  <HStack
    bgColor="red.100"
    borderRadius="base"
    px="4"
    py="3"
    ml="-10"
    spacing="4"
    shadow="md"
    w="full"
    minW="554px"
    justifyContent="space-between"
  >
    <HStack spacing="3" alignItems="flex-start" w="full">
      <Flex w="5" h="5" borderRadius="full" alignItems="center" justifyContent="center">
        <Icon icon="circle-exclamation" variant="fas" size="5" color="red.500" />
      </Flex>

      <VStack spacing="0.5" alignItems="flex-start" mt="-2">
        <Text color="gray.700" fontWeight="700">
          ChatGPT is experiencing an issue
        </Text>
        {onRetry && (
          <Text color="gray.600">
            You can navigate away or{' '}
            <Button
              onClick={onRetry}
              variant="link"
              p="0"
              fontWeight="normal"
              textDecoration="underline"
              colorScheme="blue"
            >
              Try again
            </Button>
          </Text>
        )}
      </VStack>
    </HStack>
  </HStack>
);
