import * as React from 'react';
import { ListItem, List, ListProps, ListItemProps } from 'components/design/next';
import { ListboxState, useListboxState } from './use-listbox-state';
import { createUsableContext } from '@process-street/subgrade/util';

export const ListboxOption: React.FC<
  React.PropsWithChildren<
    ListItemProps & {
      isSelected?: boolean;
      setSelectedId?: (id: string) => void;
    }
  >
> = ({ children, setSelectedId, isSelected, id, ...props }) => {
  if (!setSelectedId || !id || isSelected === undefined) return null;
  return (
    <ListItem
      // controlled by arrow keys so we disable tabs
      tabIndex={-1}
      role="option"
      id={id}
      aria-selected={isSelected}
      onMouseEnter={() => {
        setSelectedId(id);
      }}
      {...props}
    >
      {children}
    </ListItem>
  );
};

type Context = Omit<ListboxState, 'ref'>;
export const [useListboxContext, ListboxContext] = createUsableContext<Context>({
  hookName: 'useListboxContext',
  providerName: 'ListboxProvider',
});

export const Listbox: React.FC<React.PropsWithChildren<ListProps>> = ({ children, ...props }) => {
  const ids = React.useMemo(() => {
    const result =
      React.Children.map(children, el => {
        if (!React.isValidElement(el) || el.type !== ListboxOption) {
          throw new Error('Direct children of `Listbox` must be `ListboxOption`');
        }

        if (el.key === undefined || el.key === null) {
          throw new Error('`ListboxOption` children must have a unique key property');
        }

        return el.key.toString();
      }) ?? [];

    if (result.length !== new Set(result).size) {
      throw new Error('`ListboxOption` children must have a unique key property');
    }

    return result;
  }, [children]);

  const { selectedId, ref, setSelectedId } = useListboxState({
    ids,
  });

  const options = React.Children.map(children, el => {
    if (React.isValidElement(el) && el.type === ListboxOption) {
      return React.cloneElement(el, {
        ...el.props,
        setSelectedId,
        id: el.key,
        isSelected: selectedId === el.key,
      });
    }
    return null;
  });

  return (
    <ListboxProvider selectedId={selectedId} setSelectedId={setSelectedId}>
      <List {...props} ref={ref} role="listbox" tabIndex={0} aria-activedescendant={selectedId}>
        {options}
      </List>
    </ListboxProvider>
  );
};

export const ListboxProvider: React.FC<React.PropsWithChildren<Context>> = ({
  children,
  selectedId,
  setSelectedId,
}) => {
  const value = React.useMemo(
    () => ({
      setSelectedId,
      selectedId,
    }),
    [selectedId, setSelectedId],
  );
  return <ListboxContext.Provider value={value}>{children}</ListboxContext.Provider>;
};
