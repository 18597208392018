import { Muid, Option, Period } from '@process-street/subgrade/core';
import {
  DueDateRuleDefinition,
  DueDateRuleOffsetDirection,
  DueDateRuleSourceType,
  DueDateRuleType,
  FormFieldWidget,
  TaskTemplate,
  toFormFieldWidgetMapByHeaderGroupId,
  toTaskTemplateMapByGroupId,
} from '@process-street/subgrade/process';
import {
  BaseDueDateRuleDefinitionSelector,
  BaseTaskTemplateSelector,
  BaseWidgetSelector,
} from '@process-street/subgrade/redux/selector';
import { BaseReduxState, ObjectMap } from '@process-street/subgrade/redux/types';
import { createSelector, Selector } from 'reselect';

export interface DynamicDueDateRule {
  id: Muid;
  templateRevisionId: Muid;
  targetTaskTemplateId?: Muid;
  sourceType: DueDateRuleSourceType;
  formFieldWidgetId?: Muid;
  taskTemplateId?: Muid;
  offsetDirection: DueDateRuleOffsetDirection;
  dueOffset: Period;
  workdaysOnly: boolean;
  ruleType: DueDateRuleType;
}

const isActionable = (
  targetTaskTemplate: Option<TaskTemplate>,
  sourceFormFieldWidget: Option<FormFieldWidget>,
  sourceTaskTemplate: Option<TaskTemplate>,
  ruleDefinition: DueDateRuleDefinition,
) => {
  if (!targetTaskTemplate) {
    return false;
  }
  switch (ruleDefinition.sourceType) {
    case DueDateRuleSourceType.TaskCompletedDate:
    case DueDateRuleSourceType.TaskDueDate:
      return !!sourceTaskTemplate;
    case DueDateRuleSourceType.FormFieldValue:
      return !!sourceFormFieldWidget;
    default:
      return true;
  }
};

function pushToMap(key: Muid, rule: DynamicDueDateRule, agg: ObjectMap<DynamicDueDateRule[]>) {
  if (agg[key]) {
    agg[key].push(rule);
  } else {
    agg[key] = [rule];
  }
}

const getByTemplateRevisionId = (templateRevisionId: Muid): Selector<BaseReduxState, DynamicDueDateRule[]> =>
  createSelector(
    BaseDueDateRuleDefinitionSelector.getByTemplateRevisionId(templateRevisionId),
    BaseTaskTemplateSelector.getAllByTemplateRevisionId(templateRevisionId),
    BaseWidgetSelector.getAllFormFieldWidgetsByTemplateRevisionId(templateRevisionId),
    (ruleDefinitions, taskTemplates, formFieldWidgets) => {
      const taskTemplateByGroupIdMap = toTaskTemplateMapByGroupId(taskTemplates);
      const formFieldWidgetByHeaderGroupIdMap = toFormFieldWidgetMapByHeaderGroupId(formFieldWidgets);

      const rules: DynamicDueDateRule[] = ruleDefinitions.reduce(
        (agg: DynamicDueDateRule[], ruleDefinition: DueDateRuleDefinition) => {
          const targetTaskTemplate: Option<TaskTemplate> =
            ruleDefinition.targetTaskTemplateGroup &&
            taskTemplateByGroupIdMap[ruleDefinition.targetTaskTemplateGroup.id];
          const sourceFormFieldWidget: Option<FormFieldWidget> = ruleDefinition.formFieldWidgetGroup
            ? formFieldWidgetByHeaderGroupIdMap[ruleDefinition.formFieldWidgetGroup.id]
            : undefined;
          const sourceTaskTemplate: Option<TaskTemplate> = ruleDefinition.taskTemplateGroup
            ? taskTemplateByGroupIdMap[ruleDefinition.taskTemplateGroup.id]
            : undefined;

          if (
            targetTaskTemplate &&
            isActionable(targetTaskTemplate, sourceFormFieldWidget, sourceTaskTemplate, ruleDefinition)
          ) {
            agg.push({
              dueOffset: ruleDefinition.dueOffset,
              formFieldWidgetId: sourceFormFieldWidget ? sourceFormFieldWidget.id : undefined,
              id: ruleDefinition.id,
              offsetDirection: ruleDefinition.offsetDirection,
              sourceType: ruleDefinition.sourceType,
              targetTaskTemplateId: targetTaskTemplate.id,
              taskTemplateId: sourceTaskTemplate ? sourceTaskTemplate.id : undefined,
              templateRevisionId: ruleDefinition.templateRevision.id,
              workdaysOnly: ruleDefinition.workdaysOnly,
              ruleType: ruleDefinition.ruleType,
            });
          }
          return agg;
        },
        [],
      );

      return rules;
    },
  );

const getByTemplateRevisionIdGroupedByTaskTemplateId = (
  templateRevisionId: Muid,
): Selector<BaseReduxState, ObjectMap<DynamicDueDateRule[]>> =>
  createSelector(getByTemplateRevisionId(templateRevisionId), (rules: DynamicDueDateRule[]) => {
    return rules.reduce((agg: ObjectMap<DynamicDueDateRule[]>, rule: DynamicDueDateRule) => {
      if (rule.taskTemplateId) {
        pushToMap(rule.taskTemplateId, rule, agg);
      }
      return agg;
    }, {});
  });

const getByTemplateRevisionIdWithPreviousTaskCompleteGroupedByTargetTaskTemplateId = (
  templateRevisionId: Muid,
): Selector<BaseReduxState, ObjectMap<DynamicDueDateRule[]>> =>
  createSelector(getByTemplateRevisionId(templateRevisionId), (rules: DynamicDueDateRule[]) => {
    return rules.reduce((agg: ObjectMap<DynamicDueDateRule[]>, rule: DynamicDueDateRule) => {
      if (rule.targetTaskTemplateId && rule.sourceType === DueDateRuleSourceType.PreviousTaskCompletedDate) {
        pushToMap(rule.targetTaskTemplateId, rule, agg);
      }
      return agg;
    }, {});
  });

const getByTemplateRevisionIdGroupedByFormFieldWidgetId = (
  templateRevisionId: Muid,
): Selector<BaseReduxState, ObjectMap<DynamicDueDateRule[]>> =>
  createSelector(getByTemplateRevisionId(templateRevisionId), (rules: DynamicDueDateRule[]) => {
    return rules.reduce((agg: ObjectMap<DynamicDueDateRule[]>, rule: DynamicDueDateRule) => {
      if (rule.formFieldWidgetId) {
        pushToMap(rule.formFieldWidgetId, rule, agg);
      }
      return agg;
    }, {});
  });

const getByTemplateRevisionIdWithChecklistDueDate = (
  templateRevisionId: Muid,
): Selector<BaseReduxState, DynamicDueDateRule[]> =>
  createSelector(getByTemplateRevisionId(templateRevisionId), (rules: DynamicDueDateRule[]) => {
    return rules.filter(rule => rule.sourceType === DueDateRuleSourceType.ChecklistDueDate);
  });

export const BaseDynamicDueDateRuleSelector = {
  getByTemplateRevisionId,
  getByTemplateRevisionIdGroupedByFormFieldWidgetId,
  getByTemplateRevisionIdGroupedByTaskTemplateId,
  getByTemplateRevisionIdWithChecklistDueDate,
  getByTemplateRevisionIdWithPreviousTaskCompleteGroupedByTargetTaskTemplateId,
};
