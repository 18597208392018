import React from 'react';
import { SlackConnect } from '../SlackConnect';
import { useInjector } from 'components/injection-provider';

export const SlackConnectPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { $state } = useInjector('$state');
  const slackTeamId = $state.params.slackTeamId as string | undefined;
  const slackUserId = $state.params.slackUserId as string | undefined;
  const enterpriseId = $state.params.enterpriseId as string | undefined;
  const hmac = $state.params.hmac as string | undefined;

  return <SlackConnect slackTeamId={slackTeamId} slackUserId={slackUserId} enterpriseId={enterpriseId} hmac={hmac} />;
};
