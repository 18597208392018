import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type SendEmailMutationParams = {
  widgetId: Muid;
  checklistRevisionId: Muid;
};

export enum SendEmailResponseType {
  AllSent = 'AllSent',
  NoneSent = 'NoneSent',
  PartiallyInvalid = 'PartiallyInvalid',
}

export type SendEmailMutationResponse = {
  sentDate: number;
  senderUserId: Muid;
  responseType: SendEmailResponseType;
  summaryMessage: string;
  results: Array<{
    id: Muid;
    email: string;
    status: string;
    rejectReason?: string;
  }>;
};

export const SendEmailMutation = {
  key: ['checklist-revisions', 'widgets', 'send-email'],
  mutationFn: ({ checklistRevisionId, widgetId }: SendEmailMutationParams) =>
    axiosService
      .getAxios()
      .post(`/1/checklist-revisions/${checklistRevisionId}/widgets/${widgetId}/send`)
      .then(res => res.data),
};

export const useSendEmailMutation = (
  options: UseMutationOptions<SendEmailMutationResponse, AxiosError, SendEmailMutationParams> = {},
) => {
  return useMutation(SendEmailMutation.mutationFn, options);
};
