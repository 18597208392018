import { ChakraTheme, ToastId, UseToastOptions } from 'components/design/next';
import { createStandaloneToast } from '@chakra-ui/react';
import { theme as psTheme } from 'components/design/next/theme';
import React from 'react';
import DOMPurify from 'dompurify';

export class ToastService {
  private theme: ChakraTheme;
  private toast: ReturnType<typeof createStandaloneToast>['toast'];

  constructor(theme: ChakraTheme = psTheme) {
    this.theme = theme;
    const { toast } = createStandaloneToast({ theme: this.theme });

    this.toast = toast;
  }

  public setTheme(theme: ChakraTheme) {
    this.theme = theme;
    const { toast } = createStandaloneToast({ theme: this.theme });

    this.toast = toast;
  }

  public openToast(options?: UseToastOptions | undefined): ToastId | undefined {
    const parsedTitle = this.parseContent(options?.title);

    const parsedDescription = this.parseContent(options?.description);

    return this.toast({
      position: 'top',
      isClosable: true,
      duration: 5000,
      variant: 'subtle',
      ...options,
      title: parsedTitle,
      description: parsedDescription,
    });
  }

  public closeToast(toastId: ToastId) {
    return this.toast.close(toastId);
  }

  // Parsing the content to HTML allows Angular code to can control the
  // markup (eg. bold and italic) via HTML tags (eg. <b>Hey!</b>)
  private parseContent(content: React.ReactNode | string | undefined) {
    if (typeof content === 'string') {
      const clean = DOMPurify.sanitize(content);
      return <span dangerouslySetInnerHTML={{ __html: clean }} />;
    } else {
      return content;
    }
  }
}

export const ToastServiceImpl = new ToastService();
