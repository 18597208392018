import * as React from 'react';
import { Box, Flex, Grid, GridItem, useBreakpointValue, VStack } from 'components/design/next';
import { PagesEditor } from './components';
import { useSelector } from '@xstate/react';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import _isEqual from 'lodash/isEqual';
import { Template } from '@process-street/subgrade/process';
import { PAGES_FOCUS_BAR_HEIGHT } from '../edit/page/components/focus-bar';
import { PageMedia } from './components/page-media';
import { TOP_BAR_HEIGHT_TOKEN, useUiDisclosure } from 'app/pages/forms/_id/shared';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';
import { PAGES_FOCUS_BAR_HEIGHT_V2 } from './components/focus-bar';
import { usePasteFile } from 'app/pages/workflows/_id/edit-v2/hooks/use-paste-file';

export type PagesEditorPageProps = {
  template: Template;
};

export const sharedPageContentStyles = {
  '.image-content__aspect-ratio': { w: 'full', maxW: 'unset' },
  '.image-content': { maxW: 'unset' },
  '.file-container': { w: 'full' },
  '[data-component="FileUpload"]': { w: 'full' },
  'hr': { borderColor: 'gray.200' },
  'li': { lineHeight: 'base' },
};

export const PagesEditorPage = ({ template }: PagesEditorPageProps) => {
  const actor = useFormEditorPageActorRef();

  const uiActorRef = useSelector(actor, FormEditorPageActorSelectors.getUIActorRef, _isEqual);
  const isReadOnly = useSelector(actor, FormEditorPageActorSelectors.isReadOnly);
  const isMobile = useBreakpointValue({ base: true, lg: false }) ?? false;

  React.useEffect(
    function notifyScreenSizeToUiMachine() {
      uiActorRef.send({ type: 'SET_IS_MOBILE', isMobile });
    },
    [isMobile, uiActorRef],
  );

  React.useEffect(
    function updatePageTitle() {
      if (template.name) {
        document.title = `${template.name} | Process Street`;
      }
    },
    [template.name],
  );
  usePasteFile();

  const { isOpen: isSidebarOpen } = useUiDisclosure('insertWidget');

  const handlePageNameKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'ArrowDown') {
      e.preventDefault();
      actor.send({ type: 'FOCUS_FIRST_TEXT_WIDGET' });
    }
  };

  return (
    <VStack w="full" alignItems="stretch" justifyContent="stretch" spacing={0}>
      <PagesEditor.FocusBar />
      <Grid
        gridTemplateColumns={{ base: '1fr 0', lg: `1fr  ${isSidebarOpen ? '155px' : '0'}` }}
        gridTemplateRows={{ base: 'auto', lg: '1fr' }}
        position="relative"
        pt={PAGES_FOCUS_BAR_HEIGHT_V2}
        background="transparent"
        minW="100%"
        transition="grid-template-columns 0.3s ease"
      >
        <GridItem gridColumn="1/-1" position="relative">
          <PageMedia template={template} />
        </GridItem>
        <GridItem w="full" as={Flex} flexDirection="column" alignItems="center" justifyContent="center" pt={50}>
          <Box
            background="white"
            marginTop="-100px"
            borderRadius="md"
            color="gray.700"
            maxW="888px"
            sx={sharedPageContentStyles}
            p={{ base: 8, md: 11 }}
            w="full"
            zIndex={1}
          >
            <PagesEditor.Title autoFocus={!isReadOnly} onKeyDown={handlePageNameKeyDown} />
            <PagesEditor.WidgetsList />
          </Box>
        </GridItem>
        <GridItem>
          <PagesEditor.InsertWidgetsSection
            buttonProps={{ sx: { top: TOP_BAR_HEIGHT_TOKEN + PAGES_FOCUS_BAR_HEIGHT }, mt: 5 }}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
};
