import { Badge, Box, HStack, Text } from 'components/design/next';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { NodeType } from 'directives/rules/template/task-templates-selector/selector-helper';
import { ConditionalLogicUtils } from 'features/conditional-logic/utils/conditional-logic-utils';
import * as React from 'react';
import { components, ValueContainerProps } from 'react-select';
import { OptionType } from './types';
import { CollapsedValuesTooltip } from './collapsed-values-tooltip';
import { NodeName } from '../node-name';

export const ValueContainer = ({ children, ...props }: ValueContainerProps<OptionType, true>) => {
  const selectValue = props.selectProps.value;

  const values = React.useMemo(
    () => (BlvdSelectHelpers.isOptionsType<OptionType>(selectValue) ? selectValue : []),
    [selectValue],
  );

  // Holds the amount of items (widgets or tasks) that was rendered at each index
  const itemsRenderedCountRef = React.useRef<number[]>([]);

  const valuesMap = React.useMemo(() => {
    return Object.fromEntries(values.map(value => [value.id, value]));
  }, [values]);

  const valuesByParentMap = React.useMemo(() => {
    return values.reduce<Record<string, OptionType[]>>((acc, value) => {
      const parentId = ConditionalLogicUtils.getNodeParentId(value);
      const isParentSelected = valuesMap[parentId ?? ''];

      if (value.type !== NodeType.Widget || !parentId || !isParentSelected) return acc;

      if (acc[parentId]) {
        acc[parentId].push(value);
      } else {
        acc[parentId] = [value];
      }

      return acc;
    }, {});
  }, [values, valuesMap]);

  const childrenAsArray = React.Children.toArray(children);

  // last child is always the (hidden) search input
  // see: frontend/app/components/design/BlvdSelect/components/Input.tsx
  // we need to keep that item to track blur of the select component
  const lastChild = childrenAsArray.pop();
  // limit number of "pills" rendered otherwise
  const limitedChildren = childrenAsArray.slice(0, 20);

  itemsRenderedCountRef.current = [];

  const pills = limitedChildren.map((child, index) => {
    const value = values[index];
    const parentId = value ? ConditionalLogicUtils.getNodeParentId(value) : undefined;
    const isParentSelected = parentId ? valuesMap[parentId] : false;

    const isValueComponent = index <= values.length - 1;

    // We hide the value if the parent is selected
    if (isParentSelected) return null;

    // If the child is not bound to a value we render it
    if (!isValueComponent) return child;

    const childrenValues = valuesByParentMap[value.id] ?? [];

    const previousCount = itemsRenderedCountRef.current[itemsRenderedCountRef.current.length - 1] ?? 0;
    const itemsToRender = 1 + childrenValues.length;
    const itemsRenderedSoFar = previousCount + itemsToRender;
    const remainingValues = values.slice(previousCount + itemsToRender, values.length);
    const remainingCount = remainingValues.length;

    itemsRenderedCountRef.current.push(itemsRenderedSoFar);

    const clonedValue = React.cloneElement(child as React.ReactElement, {
      children: (
        <HStack>
          <Text fontSize="sm" maxW="125px" noOfLines={1}>
            <NodeName node={value} />{' '}
          </Text>

          {childrenValues.length > 0 && (
            <Box as="span" color="gray.400">
              ({childrenValues.length ?? 0})
            </Box>
          )}
        </HStack>
      ),
    });

    return (
      <Box key={value.id} position="relative">
        <CollapsedValuesTooltip parentLabel={value.name} valuesByParentMap={valuesByParentMap} values={childrenValues}>
          <Box>{clonedValue}</Box>
        </CollapsedValuesTooltip>

        {remainingCount > 0 && (
          <CollapsedValuesTooltip values={remainingValues} valuesByParentMap={valuesByParentMap}>
            <Badge
              position="absolute"
              right="0"
              top="0"
              transform="translateX(calc(100% + 3px))"
              fontSize="sm"
              py="0"
              px="3"
              my="3px"
              borderRadius="16px"
              bgColor="brand.100"
              height="7"
              lineHeight="28px"
            >
              +{remainingCount}
            </Badge>
          </CollapsedValuesTooltip>
        )}
      </Box>
    );
  });

  return (
    <components.ValueContainer {...props}>
      {values.length > 0 && [...pills, lastChild]}

      {!values.length ? children : null}
    </components.ValueContainer>
  );
};
