import { Alert, AlertDescription, AlertTitle, Box, Flex } from '@chakra-ui/react';
import * as React from 'react';
import { Icon } from 'components/design/next/icon';

export const LoadingIcon: React.FC = () => {
  return (
    <Box w="4" h="4">
      <Icon w="100%" h="100%" animation="spin" variant="far" icon="spinner-third" display="inline" />
    </Box>
  );
};

export interface LoadingToastProps {
  title: string;
  description: string;
}

export const loadingToast = (loadingToastProps: LoadingToastProps) => (): React.ReactNode => {
  return (
    <>
      <Alert status="info">
        <Flex gap={4} alignItems="start">
          <LoadingIcon />
          <Flex direction="column" alignItems="start">
            <AlertTitle flex="1">{loadingToastProps.title}</AlertTitle>
            <AlertDescription>{loadingToastProps.description}</AlertDescription>
          </Flex>
        </Flex>
      </Alert>
    </>
  );
};
