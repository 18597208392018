import { Option } from '@process-street/subgrade/core';
import { Webhook } from '@process-street/subgrade/process';
import { EntityMap } from '@process-street/subgrade/redux/types';
import { ReduxAppState } from 'reducers/types';
import { createSelector, Selector } from 'reselect';

const webhooksEntitySelector = (state: ReduxAppState) => state.entities.webhooks;

export const webhooksSelector: Selector<ReduxAppState, Option<Webhook[]>> = createSelector(
  webhooksEntitySelector,
  (webhookEntityMap: EntityMap<Webhook>) => webhookEntityMap && Object.values(webhookEntityMap),
);
