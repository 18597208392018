import { User } from '@process-street/subgrade/core';
import { Comment } from '@process-street/subgrade/process';
import { EmbeddedVideo } from 'components/widgets/video/EmbeddedVideo';
import { DateFormat, DateUtils, dayjs as moment } from '@process-street/subgrade/util';
import React from 'react';
import { Avatar } from '../../common/Avatar';
import styles from './CommentItem.module.scss';
import { usePrintStore } from 'components/react-root/print-store';
import { useCurrentUser } from 'hooks/use-current-user';

interface EmbeddedVideoProps {
  service: string;
  serviceCode: string;
}

export interface CommentItemProps {
  comment: Comment;
  commentContent: string;
  commentAuthor: User;
  commentCreatedDate: number;
  userActivityUrl: string;
  embeddedVideos?: EmbeddedVideoProps[];
  onDelete: (comment: Comment) => void;
  onAnswer: (comment: Comment) => void;
  disabled: boolean;
  deletable: boolean;
  answerable: boolean;
}

export const CommentItem: React.FunctionComponent<React.PropsWithChildren<CommentItemProps>> = ({
  comment,
  commentContent,
  commentAuthor,
  commentCreatedDate,
  userActivityUrl,
  embeddedVideos,
  onDelete,
  onAnswer,
  disabled,
  deletable,
  answerable,
}) => {
  const renderEmbeddedVideos = () =>
    embeddedVideos &&
    embeddedVideos.map((embeddedVideo, i) => (
      <div className={styles.embeddedVideo} key={i}>
        <EmbeddedVideo service={embeddedVideo.service} code={embeddedVideo.serviceCode} />
      </div>
    ));

  const handleDelete = () => onDelete(comment);
  const handleReply = () => onAnswer(comment);

  const renderCommentInteractions = () => (
    <div className={styles.commentInteractionsContainer}>
      {deletable && (
        <span>
          {' '}
          &middot; <a onClick={handleDelete}>Delete</a>
        </span>
      )}
      {answerable && (
        <span>
          {' '}
          &middot; <a onClick={handleReply}>Reply</a>
        </span>
      )}
    </div>
  );
  const currentUser = useCurrentUser();
  const timezone = currentUser?.timeZone || moment.tz.guess();

  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;

  const displayFormatDate = DateUtils.formatDateTime(commentCreatedDate, DateFormat.DateMonthDayAtTime);

  const auditDateFormat = DateUtils.formatDateTime(
    commentCreatedDate,
    DateFormat.DateTimeLongWeekdayShortMonthWithZone,
    timezone,
  );

  return (
    <div className={styles.doodad}>
      <a href={userActivityUrl} className={styles.commentAuthorAvatar}>
        <Avatar user={commentAuthor} />
      </a>

      <div className={styles.commentAuthor}>{commentAuthor.username}</div>

      <div dangerouslySetInnerHTML={{ __html: commentContent }} className={styles.commentContent} />

      {renderEmbeddedVideos()}

      <div className={styles.commentDate}>
        {isPrintMode ? auditDateFormat : displayFormatDate}

        {!disabled && renderCommentInteractions()}
      </div>
    </div>
  );
};
