import { Template, TemplateShareLevel } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateShareLevelMutationParams = { templateId: string; level: TemplateShareLevel };

export type UpdateShareLevelMutationResult = Template;

export const UpdateShareLevelMutation = {
  key: ['template', 'share-level'],
  mutationFn: ({ templateId, level }: UpdateShareLevelMutationParams) =>
    axiosService
      .getAxios()
      .put<UpdateShareLevelMutationResult>(`1/templates/${templateId}/share-level/${level}`, {
        id: templateId,
        level,
      })
      .then(r => r.data),
};

export const useUpdateShareLevelMutation = (
  options: UseMutationOptions<UpdateShareLevelMutationResult, AxiosError, UpdateShareLevelMutationParams> = {},
) => {
  return useMutation<UpdateShareLevelMutationResult, AxiosError, UpdateShareLevelMutationParams>(
    UpdateShareLevelMutation.mutationFn,
    options,
  );
};
