import * as React from 'react';
import { Divider, Flex, HStack, Icon, Text, VStack } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { ThemeProvider2024 } from 'components/design/next/theme-provider-2024';
import { ErrorBoundary } from 'components/error-boundary';
import { useGetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { GetAllRulesByTemplateRevisionIdQuery } from 'features/conditional-logic/query-builder';

import { RuleDefinition } from './components/rule-definition';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { Widget, Template, TaskTemplate } from '@process-street/subgrade/process';

import { getLabelForWidget, SelectorHelper } from 'directives/rules/template/task-templates-selector/selector-helper';
import { PrintRulesContextProvider, WidgetAttributes } from '../../context';
import { OrganizationLogo } from 'components/organization-logo/component';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { HiddenByDefaultSection } from './components/hidden-by-default-section';
import { useGetAllOrganizationMembershipsQuery } from 'app/features/organization-memberships/query-builder';
import { isAssignableUserOrGroupOm } from '@process-street/subgrade/util/membership-utils';
import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';

export interface PrintTemplateRulesProps {}

export interface TaskTemplatePrintAttributes extends TaskTemplate {
  taskOrder: number;
  ruleAppliesToTask?: boolean;
  widgets?: Widget[];
}

export const PrintTemplateRules: React.FC<React.PropsWithChildren<PrintTemplateRulesProps>> = () => {
  const { $stateParams } = useInjector('$stateParams', '$state');
  const { id: templateId } = $stateParams;

  const selectedOrganization = useSelectedOrganization();

  const latestRevisionQuery = useGetNewestTemplateRevisionsByTemplateIdQuery(
    { templateId },
    { enabled: Boolean(templateId) },
  );
  const latestTemplateRevision = latestRevisionQuery.data?.[0].template as Template;

  React.useEffect(() => {
    if (latestRevisionQuery.isSuccess && latestTemplateRevision?.name) {
      document.title = `${latestTemplateRevision.name} - Conditional Logic Rules | Process Street`;
    }
  }, [latestTemplateRevision?.name, latestRevisionQuery.isSuccess]);

  const tasksQuery = useTaskTemplatesByTemplateRevisionIdQuery({
    templateRevisionId: latestRevisionQuery.data?.[0]?.id,
  });

  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(latestRevisionQuery.data?.[0]?.id);

  const rulesQuery = GetAllRulesByTemplateRevisionIdQuery.useQuery({
    templateRevisionId: latestRevisionQuery.data?.[0]?.id,
  });

  const organizationMembershipsQuery = useGetAllOrganizationMembershipsQuery(
    { organizationId: selectedOrganization?.id },
    {
      enabled: Boolean(selectedOrganization?.id),
      select: oms => oms.filter(isAssignableUserOrGroupOm),
    },
  );

  const mappedOrganizationMemberships = new Map<string, OrganizationMembershipWithUser>();
  if (organizationMembershipsQuery.data) {
    organizationMembershipsQuery.data.forEach(om => {
      mappedOrganizationMemberships.set(om.id, om);
    });
  }

  const isLoading =
    !latestRevisionQuery.isSuccess ||
    !tasksQuery.isSuccess ||
    !widgetsQuery.isSuccess ||
    !rulesQuery.isSuccess ||
    !organizationMembershipsQuery.isSuccess;

  const taskGroupIds = new Map<string, TaskTemplatePrintAttributes>();
  if (tasksQuery.isSuccess) {
    tasksQuery.data?.forEach((taskTemplate, index) => {
      taskGroupIds.set(taskTemplate.group.id, { taskOrder: index + 1, ...taskTemplate });
    });
  }
  const widgetGroupIds = new Map<string, WidgetAttributes>();
  if (widgetsQuery.isSuccess) {
    widgetsQuery.data?.forEach((widget: Widget) =>
      widgetGroupIds.set(widget.header.group.id, {
        label: getLabelForWidget(widget),
        icon: SelectorHelper.getIconForWidget(widget),
        widget,
      }),
    );
  }
  React.useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        window.print();
      }, 1500);
    }
  }, [isLoading]);

  return (
    <ThemeProvider2024>
      <ErrorBoundary>
        {isLoading ? (
          <>
            <Text as="b">Loading...</Text>
            <Text>Please wait while we load the rules for this workflow</Text>
          </>
        ) : (
          <PrintRulesContextProvider
            widgetGroupIds={widgetGroupIds}
            taskGroupIds={taskGroupIds}
            organizationMemberships={mappedOrganizationMemberships}
          >
            <VStack
              padding={8}
              gap={2}
              alignItems="flex-start"
              sx={{ '.chakra-checkbox__control': { borderColor: 'gray.500 !important' } }}
            >
              <HStack>
                <OrganizationLogo organization={selectedOrganization} />
                <Text fontSize="xl">{selectedOrganization?.name}</Text>
              </HStack>
              <Text as="h1" fontSize="lg">
                <Icon icon="shuffle" size="4" mr={2} />
                <Text as="span" fontWeight="bold">
                  Conditional Logic
                </Text>
                &nbsp;
                <Text as="span">{latestTemplateRevision?.name}</Text>
              </Text>
              <Text>
                Version:&nbsp;
                <Text as="span" fontWeight="bold">
                  {latestRevisionQuery.data?.[0].revision}
                </Text>
              </Text>
              {latestTemplateRevision?.description && <Text>{latestTemplateRevision?.description}</Text>}
              <HiddenByDefaultSection />
              <Flex justifyContent="space-between" w="full" mt={4}>
                <Text as="h3" fontSize="lg">
                  Rules:
                </Text>
                <Text>{rulesQuery.data?.definitions?.length}</Text>
              </Flex>
              <Divider />
              {rulesQuery.data?.definitions?.length &&
                rulesQuery.data?.definitions?.length > 0 &&
                rulesQuery.data?.definitions.map((rule, index) => <RuleDefinition rule={rule} key={index} />)}
              {rulesQuery.data?.definitions.length === 0 && <Text>No rules found for this Workflow</Text>}
            </VStack>
          </PrintRulesContextProvider>
        )}
      </ErrorBoundary>
    </ThemeProvider2024>
  );
};
