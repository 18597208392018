import * as React from 'react';
import { BasePoint } from 'slate';
import { createPagesPluginFactory } from '../pages-plate-types';

const pointSum = (point: BasePoint) => point.offset + point.path.reduce((a, b) => a + b);
export const createPSPageNameNavigate = (ref: React.RefObject<HTMLInputElement>) =>
  createPagesPluginFactory({
    key: 'PSPageNameNavigate',
    handlers: {
      onKeyDown: editor => event => {
        if (['ArrowUp', 'ArrowLeft'].includes(event.key)) {
          if (!editor.selection) return;
          const { anchor, focus } = editor.selection;
          const selectionSum = pointSum(anchor) + pointSum(focus);
          if (selectionSum !== 0) return;

          event.preventDefault();
          const el = ref.current;
          if (el) {
            el.focus();
            el.selectionStart = el.selectionEnd = el.value.length;
          }
        }
      },
    },
  });
