import angular from 'angular';
import { ObjectType } from 'services/activities/object-type';
import { Verb } from 'services/activities/verb';
import { EventName } from 'services/event-name';
import { TemplateType } from '@process-street/subgrade/process';
import { trace } from 'components/trace';
import { match } from 'ts-pattern';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'OrganizationManageActivityCtrl',
    function (
      $scope,
      ActivityService,
      OrganizationActivityService,
      OrganizationMembershipService,
      SecurityService,
      SessionService,
      $q,
    ) {
      'ngInject';
      const ctrl = this;
      $scope.$ctrl = ctrl;
      const logger = trace({ name: 'OrganizationManageActivityCtrl' });
      logger.info('loading ctrl');

      $scope.paywallEvent = EventName.TEMPLATE_SHOW_PAYWALL;

      let userIsAdmin;

      ctrl.$onInit = () => {
        const user = SessionService.getUser();
        const selectedOrganizationId = SecurityService.getSelectedOrganizationIdByUser(user);
        OrganizationMembershipService.isAdminByUserIdAndOrganizationId(user.id, selectedOrganizationId).then(
          currentUserIsAdmin => {
            userIsAdmin = currentUserIsAdmin;
            $scope.loadOlderActivities();
          },
        );
      };

      $scope.Filter = {
        None: 'Displaying all activity',
        DeletedPages: 'Displaying deleted pages',
        DeletedForms: 'Displaying deleted forms',
        DeletedFormResponses: 'Displaying deleted form responses',
        DeletedTemplates: 'Displaying deleted workflows',
        DeletedChecklists: 'Displaying deleted workflow runs',
      };

      $scope.filter = $scope.Filter.None;
      $scope.verb = null;
      $scope.objectType = null;
      $scope.templateType = null;

      $scope.clearFilter = () => {
        $scope.filter = $scope.Filter.None;
        $scope.verb = null;
        $scope.objectType = null;
        $scope.templateType = null;

        $scope.sentences = [];
        $scope.loadOlderActivities();
      };

      $scope.onFilterChange = filter => {
        $scope.filter = match(filter)
          .with(
            {
              objectType: ObjectType.TEMPLATE,
              verb: Verb.DELETED,
              templateType: TemplateType.Playbook,
            },
            () => {
              $scope.filterByDeletedWorkflows();
            },
          )
          .with(
            {
              objectType: ObjectType.TEMPLATE,
              verb: Verb.DELETED,
              templateType: TemplateType.Page,
            },
            () => {
              $scope.filterByDeletedPages();
            },
          )
          .with(
            {
              objectType: ObjectType.TEMPLATE,
              verb: Verb.DELETED,
              templateType: TemplateType.Form,
            },
            () => {
              $scope.filterByDeletedForms();
            },
          )
          .with(
            {
              objectType: ObjectType.CHECKLIST,
              verb: Verb.DELETED,
              templateType: TemplateType.Form,
            },
            () => {
              $scope.filterByDeletedFormResponses();
            },
          )
          .with(
            {
              objectType: ObjectType.CHECKLIST,
              verb: Verb.DELETED,
              templateType: TemplateType.Playbook,
            },
            () => {
              $scope.filterByDeletedWorkflowRuns();
            },
          )
          .otherwise(() => {
            $scope.clearFilter();
          });
      };

      $scope.filterByDeletedWorkflows = () => {
        $scope.filter = $scope.Filter.DeletedTemplates;
        $scope.verb = Verb.DELETED;
        $scope.objectType = ObjectType.TEMPLATE;
        $scope.templateType = TemplateType.Playbook;

        $scope.sentences = [];
        $scope.loadOlderActivities();
      };

      $scope.filterByDeletedPages = () => {
        $scope.filter = $scope.Filter.DeletedPages;
        $scope.verb = Verb.DELETED;
        $scope.objectType = ObjectType.TEMPLATE;
        $scope.templateType = TemplateType.Page;

        $scope.sentences = [];
        $scope.loadOlderActivities();
      };

      $scope.filterByDeletedForms = () => {
        $scope.filter = $scope.Filter.DeletedForms;
        $scope.verb = Verb.DELETED;
        $scope.objectType = ObjectType.TEMPLATE;
        $scope.templateType = TemplateType.Form;

        $scope.sentences = [];
        $scope.loadOlderActivities();
      };

      $scope.filterByDeletedFormResponses = () => {
        $scope.filter = $scope.Filter.DeletedFormResponses;
        $scope.verb = Verb.DELETED;
        $scope.objectType = ObjectType.CHECKLIST;
        $scope.templateType = TemplateType.Form;

        $scope.sentences = [];
        $scope.loadOlderActivities();
      };

      $scope.filterByDeletedWorkflowRuns = () => {
        $scope.filter = $scope.Filter.DeletedChecklists;
        $scope.verb = Verb.DELETED;
        $scope.objectType = ObjectType.CHECKLIST;
        $scope.templateType = TemplateType.Playbook;

        $scope.sentences = [];
        $scope.loadOlderActivities();
      };

      const PAGE_SIZE = 20;

      $scope.loadOlderActivities = function () {
        $scope.loadingSentences = true;

        const oldSentences = $scope.sentences || [];
        const offsetId = oldSentences[oldSentences.length - 1]?.activity?.id;
        const selectedOrganizationId = SessionService.getSelectedOrganizationId();

        $q((resolve, reject) => {
          OrganizationActivityService.getActivitiesByIdLimitAndOffsetId(
            selectedOrganizationId,
            PAGE_SIZE,
            offsetId,
            $scope.verb,
            $scope.objectType,
            $scope.templateType,
          )
            .then(result => {
              $scope.hiddenTotal = result.hiddenTotal;
              $scope.sentences = $scope.sentences || [];

              if (result.data.length) {
                const { data } = result;

                const newSentences = data.map(OrganizationActivityService.interpretSimple).filter(Boolean);
                $scope.sentences = $scope.sentences || [];
                $scope.sentences = $scope.sentences.concat(newSentences);
                $scope.moreSentences = result.data.length === PAGE_SIZE;
              } else {
                $scope.moreSentences = false;
              }

              resolve(result);
            })
            .catch(reject)
            .finally(() => {
              $scope.loadingSentences = false;
            });
        });
      };

      $scope.isUndeletable = function (activity) {
        return userIsAdmin && ActivityService.isUndeletable(activity);
      };

      $scope.undelete = function (activity) {
        ActivityService.undelete(activity);
      };
    },
  );
