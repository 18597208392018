import { KeyStrings } from 'services/key';

const STOP_PROPAGATION_KEYS = [KeyStrings.ARROW_LEFT, KeyStrings.ARROW_RIGHT];

/**
 * The inbox uses the left/right switch the visible item so we need to prevent these propagating.
 * @param event
 */
export const formFieldKeyUpHandler: React.KeyboardEventHandler = event => {
  if (STOP_PROPAGATION_KEYS.includes(event.key)) {
    event.stopPropagation();
  }
};
