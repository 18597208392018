import * as React from 'react';
import {
  FormControl,
  forwardRef,
  Textarea,
  FormLabel,
  Input,
  Stack,
  InputRightElement,
  FormControlProps,
  FormLabelProps,
  Button,
  Icon,
  Box,
  Center,
  Spinner,
} from 'components/design/next';
import { EmailTemplateWidgetProps } from '..';
import { EMAIL_PLACEHOLDER, EmailMetaDataInputGroup } from '../../common';
import { MergeTagsMenu, MergeTagsMenuButton } from 'features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { useMergeTaggableInput } from 'hooks/use-merge-taggable-input';
import { isBrowserSafari } from 'services/util-pure';
import { mailto } from '@process-street/subgrade/process';
import { useGetOrganizationQuery } from 'features/organization/query-builder';
import { usePlanQuery } from 'features/plans/query-builder';
import { isPlanFree } from '@process-street/subgrade/billing';
import { useDebouncedCallback } from 'use-debounce';
import { useWidgetUpdateOverrideListener } from 'hooks/use-widget-update-override-listener';
import { useTemplateWidgetsStore } from 'features/widgets/use-template-widgets-store';

export type EditProps = EmailTemplateWidgetProps;

export const Edit: React.FC<React.PropsWithChildren<EditProps>> = ({ widget, onUpdate, templateRevision }) => {
  const updatingWidgetGroupIds = useTemplateWidgetsStore(s => s.updatingWidgetGroupIds);
  const isUpdating = React.useMemo(
    // Optimistic widget from angular might not have group 🙄
    () => updatingWidgetGroupIds.has(widget.header.group?.id),
    [updatingWidgetGroupIds, widget.header.group?.id],
  );

  const updateDebounced = useDebouncedCallback(() => {
    onUpdate({ ...widget, recipient: to, cc, bcc, subject, body });
  }, 500);

  const [to, setTo] = React.useState(widget.recipient ?? '');
  const handleToChange = React.useCallback(
    (value: string) => {
      setTo(value);
      updateDebounced();
    },
    [updateDebounced],
  );

  const [cc, setCc] = React.useState(widget.cc ?? '');
  const handleCcChange = React.useCallback(
    (value: string) => {
      setCc(value);
      updateDebounced();
    },
    [updateDebounced],
  );

  const [bcc, setBcc] = React.useState(widget.bcc ?? '');
  const handleBccChange = React.useCallback(
    (value: string) => {
      setBcc(value);
      updateDebounced();
    },
    [updateDebounced],
  );

  const [subject, setSubject] = React.useState(widget.subject ?? '');
  const handleSubjectChange = React.useCallback(
    (value: string) => {
      setSubject(value);
      updateDebounced();
    },
    [updateDebounced],
  );

  const [body, setBody] = React.useState(widget.body ?? '');
  const handleBodyChange = React.useCallback(
    (value: string) => {
      setBody(value);
      updateDebounced();
    },
    [updateDebounced],
  );

  useWidgetUpdateOverrideListener(widget, updatedWidget => {
    setTo(updatedWidget.recipient ?? '');
    setCc(updatedWidget.cc ?? '');
    setBcc(updatedWidget.bcc ?? '');
    setSubject(updatedWidget.subject ?? '');
    setBody(updatedWidget.body ?? '');
  });

  const { ref: toRef, insertMergeTag: insertTo } = useMergeTaggableInput({ get: () => to, set: handleToChange });

  const { ref: ccRef, insertMergeTag: insertCc } = useMergeTaggableInput({ get: () => cc, set: handleCcChange });

  const { ref: bccRef, insertMergeTag: insertBcc } = useMergeTaggableInput({ get: () => bcc, set: handleBccChange });

  const { ref: subjectRef, insertMergeTag: insertSubject } = useMergeTaggableInput({
    get: () => subject,
    set: handleSubjectChange,
  });

  const { ref: bodyRef, insertMergeTag: insertBody } = useMergeTaggableInput<HTMLTextAreaElement>({
    get: () => body,
    set: handleBodyChange,
  });

  const organizationQuery = useGetOrganizationQuery({ organizationId: templateRevision.organization.id });
  const planQuery = usePlanQuery({ planId: organizationQuery.data?.subscription.plan.id });
  const planIsFree = planQuery.data ? isPlanFree(planQuery.data) : false;

  const mailUrl = mailto({
    to: widget.recipient,
    cc: widget.cc,
    bcc: widget.bcc,
    subject: widget.subject,
    body: `${widget.body}${planIsFree ? ' \n\nSent by Process.st' : ''}`,
  });

  const openMailTo = () => {
    // Fixing issue with blocked popup window for Safari
    if (isBrowserSafari()) {
      window.location.href = mailUrl;
    } else {
      window.open(mailUrl);
    }
  };

  return (
    <Stack spacing="4" {...(isUpdating ? { pointerEvents: 'none' } : {})}>
      {isUpdating && (
        <Box
          borderRadius="xl"
          position="absolute"
          top="0"
          left="0"
          w="full"
          h="full"
          bg="gray.100"
          opacity="0.5"
          zIndex="overlay"
        >
          <Center w="full" h="full">
            <Spinner size="xl" />
          </Center>
        </Box>
      )}

      <Field>
        <Label>To</Label>
        <EmailMetaDataInputGroup>
          <Input
            aria-label="to emails"
            bg="white"
            variant="outline"
            placeholder={EMAIL_PLACEHOLDER}
            value={to}
            onChange={e => handleToChange(e.target.value)}
            ref={toRef}
          />
          <InputRightElement>
            <MergeTagsMenu
              {...{
                templateRevisionId: templateRevision.id,
                onSelect: (key, _fieldId, fallback) => insertTo(key, fallback),
                mergeTagTarget: MergeTagsConstants.Target.EMAIL,
                menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
              }}
            />
          </InputRightElement>
        </EmailMetaDataInputGroup>
      </Field>

      <Field>
        <Label>Cc</Label>
        <EmailMetaDataInputGroup>
          <Input
            aria-label="cc emails"
            bg="white"
            variant="outline"
            placeholder={EMAIL_PLACEHOLDER}
            value={cc}
            onChange={e => handleCcChange(e.target.value)}
            ref={ccRef}
          />
          <InputRightElement>
            <MergeTagsMenu
              {...{
                templateRevisionId: templateRevision.id,
                onSelect: (key, _fieldId, fallback) => insertCc(key, fallback),
                mergeTagTarget: MergeTagsConstants.Target.EMAIL,
                menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
              }}
            />
          </InputRightElement>
        </EmailMetaDataInputGroup>
      </Field>

      <Field>
        <Label>Bcc</Label>
        <EmailMetaDataInputGroup>
          <Input
            aria-label="bcc emails"
            bg="white"
            variant="outline"
            placeholder={EMAIL_PLACEHOLDER}
            value={bcc}
            onChange={e => handleBccChange(e.target.value)}
            ref={bccRef}
          />
          <InputRightElement>
            <MergeTagsMenu
              {...{
                templateRevisionId: templateRevision.id,
                onSelect: (key, _fieldId, fallback) => insertBcc(key, fallback),
                mergeTagTarget: MergeTagsConstants.Target.EMAIL,
                menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
              }}
            />
          </InputRightElement>
        </EmailMetaDataInputGroup>
      </Field>

      <Field>
        <Label>Subject</Label>
        <EmailMetaDataInputGroup>
          <Input
            bg="white"
            variant="outline"
            placeholder="Subject"
            value={subject}
            onChange={e => handleSubjectChange(e.target.value)}
            ref={subjectRef}
          />
          <InputRightElement>
            <MergeTagsMenu
              {...{
                templateRevisionId: templateRevision.id,
                onSelect: (key, _fieldId, fallback) => insertSubject(key, fallback),
                mergeTagTarget: MergeTagsConstants.Target.GENERAL,
                menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
              }}
            />
          </InputRightElement>
        </EmailMetaDataInputGroup>
      </Field>

      <Field>
        <Label>Body</Label>
        <EmailMetaDataInputGroup>
          <Textarea
            rows={2}
            bg="white"
            variant="outline"
            placeholder="Body"
            value={body}
            onChange={e => handleBodyChange(e.target.value)}
            ref={bodyRef}
          />
          <InputRightElement>
            <MergeTagsMenu
              {...{
                templateRevisionId: templateRevision.id,
                onSelect: (key, _fieldId, fallback) => insertBody(key, fallback),
                mergeTagTarget: MergeTagsConstants.Target.GENERAL,
                menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
              }}
            />
          </InputRightElement>
        </EmailMetaDataInputGroup>
      </Field>

      <Field>
        <Label></Label>

        <Button
          variant="tertiary"
          onClick={openMailTo}
          leftIcon={<Icon icon="paper-plane" size="4" variant="far" />}
          iconSpacing="2"
        >
          Test Send
        </Button>
      </Field>
    </Stack>
  );
};

const Field = forwardRef<FormControlProps, 'div'>((props, ref) => {
  return <FormControl {...{ ref, display: 'flex', alignItems: 'center', ...props }} />;
});

const Label = forwardRef<FormLabelProps, 'div'>((props, ref) => {
  return <FormLabel {...{ ref, mb: 0, textAlign: 'right', minW: 16, ...props }} />;
});
