import * as React from 'react';
import { Icon, IconButton } from 'components/design/next';
import { CustomHeaderProps } from '@ag-grid-community/react';

export type AddColumnHeaderProps = CustomHeaderProps & {
  onAddColumn: () => void;
};

export const AddColumnHeader: React.FC<AddColumnHeaderProps> = ({ onAddColumn }) => {
  return (
    <IconButton
      variant="ghost"
      size="sm"
      icon={<Icon size="4" variant="far" icon="plus" color="gray.500" />}
      aria-label={'add column'}
      onClick={onAddColumn}
    />
  );
};
