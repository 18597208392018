import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { GroupsPage } from '.';

export const GroupsPageModule = angular
  .module(
    'groupsPage',
    [], // dependencies
  )
  .component(
    'psGroupsPage',
    reactToAngular(
      GroupsPage,
      [], // props
    ),
  ).name;
