import { VStack, StackProps } from 'components/design/next';
import * as React from 'react';

export const MobileActionsWrapper = (props: StackProps) => {
  return (
    <VStack
      display={{ base: undefined, md: 'none' }}
      bgColor="gray.50"
      justifyContent="flex-start"
      alignItems="flex-start"
      w="100%"
      pl="12"
      spacing="0"
      {...props}
    />
  );
};
