import * as React from 'react';
import { Icon } from 'components/design/next';
import { useUserDeactivationHelpers } from 'features/user/use-user-deactivation-helpers';
import { User } from '@process-street/subgrade/core';
import { getAvatarUrl } from 'components/common/Avatar/avatar-helper';
import { Avatar, AvatarProps } from '@chakra-ui/react';

export type ChakraAvatarProps = {
  /** When supplying user, can determine if user is deactivated, and displays name, image. */
  user?: User;
  /** When supplying user ID, can determine if user is deactivated. */
  userId?: User['id'];
  /** Used for testing only. */
  isDeactivated?: boolean;
  url?: string;
  name?: string;
} & AvatarProps;

/* Chakra Avatar displaying a PS User. Supports deactivated user display. */
export const ChakraAvatar = ({ isDeactivated, user, userId, url, name, ...avatarProps }: ChakraAvatarProps) => {
  const deactivationHelpers = useUserDeactivationHelpers();
  const userIdFromProps = user?.id ?? userId;
  const isUserDeactivated = isDeactivated || (userIdFromProps && !deactivationHelpers.isActive(userIdFromProps));
  const displayName = name ?? user?.username;

  return isUserDeactivated ? (
    <DeactivatedAvatar {...avatarProps} />
  ) : (
    <Avatar name={displayName} src={url ?? getAvatarUrl(user)} title={displayName} {...avatarProps} />
  );
};

const deactivatedIconSizeTokenMap = {
  '2xs': '2',
  'xs': '3',
  'sm': '4',
  'md': '4',
  'lg': '7',
  'xl': '9',
  '2xl': '10',
};

const DeactivatedAvatar = (props: AvatarProps) => {
  return (
    <Avatar
      borderStyle="dashed"
      borderColor="gray.300"
      borderWidth="thin"
      borderRadius="full"
      backgroundColor="white"
      aria-label="Deactivated user avatar"
      icon={
        <Icon
          size={deactivatedIconSizeTokenMap[(props.size as keyof typeof deactivatedIconSizeTokenMap) ?? 'md']}
          icon="user"
          color="gray.300"
        />
      }
      {...props}
      name={undefined}
      src={undefined}
    ></Avatar>
  );
};
