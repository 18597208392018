import * as React from 'react';
import {
  Icon,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  ModalHeader,
  HStack,
} from 'components/design/next';
import { Widget } from '@process-street/subgrade/process';

type DeleteSubtaskButtonProps = {
  onDelete: (widget: Widget, index: number) => void;
  widget: Widget;
  index: number;
};

export const DeleteSubtaskButton: React.FC<React.PropsWithChildren<DeleteSubtaskButtonProps>> = ({
  onDelete,
  widget,
  index,
}) => {
  const modalDisclosure = useDisclosure();

  const handleConfirm = () => {
    onDelete(widget, index);
    modalDisclosure.onClose();
  };

  const handleCancel = () => {
    modalDisclosure.onClose();
  };

  return (
    <>
      <IconButton
        onClick={modalDisclosure.onOpen}
        aria-label="Delete subtask"
        color="gray.500"
        colorScheme="gray"
        variant="ghost"
        icon={<Icon icon="trash-can" size="4" />}
        size="sm"
        _hover={{ bgColor: 'brand.50' }}
      />
      <Modal {...modalDisclosure}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" color="gray.700">
            Delete subtask
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody color="gray.600" fontWeight="500">
            The subtask will be deleted and irrecoverable.
          </ModalBody>

          <ModalFooter as={HStack} spacing="2">
            <Button colorScheme="gray" variant="ghost" onClick={handleCancel}>
              Cancel
            </Button>

            <Button onClick={handleConfirm} variant="danger">
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
