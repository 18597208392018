import * as React from 'react';
import { Box, Button, Icon, Link, MotionWrapper, Text, VStack } from 'components/design/next';
import { ConditionalLogicEmptyStateIllustration } from './empty-state-illustration';
import { useTemplateTypeContext } from 'utils/template/template-type-context';

const GUIDE_URL = 'https://www.process.st/help/docs/conditional-logic/';

export type ConditionalLogicEmptyStateProps = {
  onAddRule?: () => void;
};

export const ConditionalLogicEmptyState: React.FC<React.PropsWithChildren<ConditionalLogicEmptyStateProps>> = ({
  onAddRule,
}) => {
  const { isForm } = useTemplateTypeContext();
  const recordName = isForm ? 'forms' : 'workflow runs';

  return (
    <VStack w="full" spacing="6" px="20" py="10">
      <ConditionalLogicEmptyStateIllustration />

      <MotionWrapper initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.8 }}>
        <Box>
          <Text maxWidth="500px" color="gray.500" textAlign="center">
            Conditional logic allows you to create dynamic {recordName} with if-this-then-that logic. Learn more{' '}
            <Link target="_blank" referrerPolicy="no-referrer" href={GUIDE_URL} color="brand.500">
              here
            </Link>
            .
          </Text>
          <Text mt={3} maxWidth="500px" color="gray.500" textAlign="center">
            These <strong>rules are implemented in sequential order</strong>.
          </Text>
        </Box>
      </MotionWrapper>

      <MotionWrapper initial={{ scale: 0, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ delay: 0.9 }}>
        <Button onClick={onAddRule} leftIcon={<Icon icon="plus" size="4" />}>
          Add rule
        </Button>
      </MotionWrapper>
    </VStack>
  );
};
