import * as React from 'react';
import { TableLoadingSkeleton } from '../../table-loading-skeleton';
import { DataSetTableProps } from './data-set-table';

const DataSetTable = React.lazy(() =>
  import('./data-set-table').then(({ DataSetTable }) => ({ default: DataSetTable })),
);

export const DataSetTableWrapper: React.FC<React.PropsWithChildren<DataSetTableProps>> = props => {
  return (
    <React.Suspense fallback={<TableLoadingSkeleton />}>
      <DataSetTable {...props} />
    </React.Suspense>
  );
};
