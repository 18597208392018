import { AnalyticsConstants, ChecklistViewedSource } from '@process-street/subgrade/analytics';
import { UserType } from '@process-street/subgrade/core';
import { ChecklistMigrationStatus, ChecklistStatus, TemplateType } from '@process-street/subgrade/process';
import { BaseNavigationSelector } from '@process-street/subgrade/redux/selector/navigation.selectors';
import { abbreviateForTitle } from '@process-street/subgrade/util';
import angular from 'angular';
import { TaskStatsSelector } from 'reducers/task-stats/task-stats.selectors';
import { createSelector } from 'reselect';
import { ChecklistSelector } from 'reducers/checklist/checklist.selectors';
import { connectService } from 'reducers/util';
import './checklist.scss';
import { ablyService } from 'app/pusher/ably.service';
import { AblyEvent } from 'app/pusher/ably-event';
import { match } from 'ts-pattern';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { trace } from 'components/trace';
import { queryClient } from 'components/react-root';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { MergeTagsByChecklistRevisionIdQuery } from 'features/merge-tags/query-builder';
import { UpdateFormFieldValueMutation } from 'features/widgets/query-builder';
import { ChecklistEvent } from 'services/checklists/checklist-event';
import { OneOffTaskAngularHelper } from 'features/one-off-tasks/components/shared/angular/one-off-task-angular-helper';
import { oneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { UpdateErrorToasts } from 'pages/tasks/helpers';
import { SANDBOX_PREVIEWER_PARAMS } from 'app/hooks/use-create-sandbox-and-redirect';
import { AllowedCustomHeaders, axiosService } from 'app/services/axios-service';
import { PromiseQueueKeyGenerator } from 'app/services/promise-queue/promise-queue-key-generator-pure';
import { EventName } from 'app/services/event-name';
import { TaskListEvent } from 'directives/task-list/task-list-event';
import { FormFieldEvent } from 'services/form-field-event';
import { AnalyticsService } from 'components/analytics/analytics.service';
import { FeatureFlagSelector } from 'app/services/features/feature-flags/store/feature-flags.selectors';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'ChecklistCtrl',
    function (
      $anchorScroll,
      $ngRedux,
      $q,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $timeout,
      $window,
      AnonymousAuthService,
      ChecklistEditorService,
      ChecklistMigrationActions,
      ChecklistMigrationService,
      ChecklistService,
      ChecklistRevisionService,
      DueDateRuleDefinitionDao,
      DynamicDueDateService,
      FeatureFlagService,
      FormFieldValueActions,
      FormFieldValueEvent,
      GrowSumoService,
      MessageBox,
      OrganizationService,
      PromiseQueueService,
      RequiredFieldEvent,
      RoleAssignmentRulesActions,
      SecurityService,
      SessionService,
      TempDataService,
      TaskStatsActions,
      TemplateRevisionService,
      ToastService,
      UserSettingsService,
      WidgetActions,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'ChecklistCtrl' });
      logger.info('loading ctrl');

      $scope.state = {
        initialized: undefined, // Keep this as undefined and not false for one-time binding
        taskStatsMap: undefined,
        featureFlags: { initialized: false },
      };

      $scope.isSandboxMode = $state.includes('sandboxChecklist') ?? false;

      let unsubscribe = () => {
        /* do nothing */
      };

      const notifyOnWorkflowUpdate = () => {
        if (!$scope.revision || !$scope.checklistId) return;

        return queryClient
          .fetchQuery({
            queryKey: GetActiveChecklistRevisionByChecklistIdQuery.getKey({ checklistId: $scope.checklistId }),
            queryFn: () => GetActiveChecklistRevisionByChecklistIdQuery.queryFn({ checklistId: $scope.checklistId }),
          })
          .then(data => {
            if (data.id !== $scope.revision.id) {
              MessageBox.confirm({
                title: 'Workflow Run Updated',
                message: 'Click refresh to get the new version!',
                okButton: {
                  type: 'success',
                  text: 'Refresh',
                  action: () => $window.location.reload(),
                },
              });
            }
          });
      };

      $scope.$on(EventName.SANDBOX_USER_CHANGE, __event => {
        $state.go('sandboxChecklist', { id: $state.params.id }, { reload: true });
      });

      ctrl.$onInit = () => {
        if ($scope.state.featureFlags?.initialized) {
          redirectToV2IfNeeded();
        }

        $scope.taskTemplateGroupId = $state.params.groupId;
        $scope.checklistId = $state.params.id;

        if ($scope.isSandboxMode) {
          const sandboxPreviewerParams = JSON.parse(sessionStorage.getItem(SANDBOX_PREVIEWER_PARAMS || '{}'));
          if (sandboxPreviewerParams) {
            axiosService.setCustomHeader(AllowedCustomHeaders.SandboxUser, sandboxPreviewerParams.userId);
          }
        }

        $window.addEventListener('focus', notifyOnWorkflowUpdate);

        if (
          $scope.taskTemplateGroupId ||
          SessionService.getChecklistEditorProperty(`checklist:${$scope.checklistId}:activeStep`)
        ) {
          showWidgets(true);
        }

        //  TODO refactor init organization data to service
        AnonymousAuthService.checklistViewAnonymousAuth($scope.checklistId)
          .then(user => {
            $scope.user = user;
            redirectToV2IfNeeded();

            initChecklistData().then(() => {
              initOrganization();

              reconnectRedux();

              subscribeToFormFieldValueLiveUpdates($scope.checklistId);
              redirectToV2IfNeeded();
            });
          })
          .catch(error => ($scope.message = error.message));

        initializeStatusTransitionListeners();
      };

      async function redirectToV2IfNeeded() {
        const shouldRedirectToNewRunner = (await FeatureFlagService.getFeatureFlagsAsync()).runnerMigration;

        if (shouldRedirectToNewRunner) {
          $state.go('checklistV2', $state.params);

          return;
        }
      }

      /**
       * @param {{data: AblyEvent.FormFieldValueUpdatedAblyEvent}} message
       */
      async function formFieldValueUpdateListener(message) {
        const data = JSON.parse(message.data);
        logger.debug('live form field value update ', data);
        const isUpdatedByDifferentUser = $scope.user.id !== data.updatedBy.id;
        // e.g. AI task update
        const isNativeAutomationUpdate = data.source === AblyEvent.FormFieldValueUpdateSource.NativeAutomation;
        const isDefaultValueUpdated = data.updatedFormFieldValue?.fieldValue?.hasDefaultValue;
        const shouldUpdateFormFieldValue =
          (isUpdatedByDifferentUser || isDefaultValueUpdated || isNativeAutomationUpdate) && data.updatedFormFieldValue;
        if (!shouldUpdateFormFieldValue) {
          return;
        }
        $ngRedux.dispatch(
          FormFieldValueActions.setFormFieldValue(data.updatedFormFieldValue, data.checklistTaskAssignments),
        );
        $rootScope.$broadcast(FormFieldEvent.FORM_FIELD_VALUE_LIVE_UPDATED, data.updatedFormFieldValue);
        await UpdateFormFieldValueMutation.updateFormFieldValuesOnSuccess(queryClient)({
          formFieldValue: data.updatedFormFieldValue,
        });
        await queryClient.invalidateQueries({ queryKey: MergeTagsByChecklistRevisionIdQuery.key });
      }

      const formFieldValueDeleteListener = message => {
        const data = JSON.parse(message.data);
        logger.debug('live form field value delete ', data);
        if (data.updatedFormFieldValue) {
          const updatedFormFieldValue = { ...data.updatedFormFieldValue, value: '' };
          $ngRedux.dispatch(
            FormFieldValueActions.setFormFieldValue(updatedFormFieldValue, data.checklistTaskAssignments),
          );

          $ngRedux.dispatch(FormFieldValueActions.syncDeletedFormFieldValue(data.updatedFormFieldValue));
          $rootScope.$broadcast(FormFieldEvent.FORM_FIELD_VALUE_LIVE_DELETED, data.updatedFormFieldValue);
        }
      };

      function subscribeToFormFieldValueLiveUpdates(checklistId) {
        logger.info('live update is enabled - connecting to ably');

        const channelName = ablyService.getChannelNameForChecklist(checklistId);
        const channel = ablyService.getChannel(channelName);
        channel.subscribe(AblyEvent.EventType.FormFieldValueUpdated, formFieldValueUpdateListener);
        channel.subscribe(AblyEvent.EventType.FormFieldValueDeleted, formFieldValueDeleteListener);
      }

      function reconnectRedux() {
        unsubscribe();

        const mapStateToThis = createSelector(
          [
            BaseNavigationSelector.getCurrentChecklistId,
            BaseNavigationSelector.getCurrentChecklistRevisionId,
            ChecklistSelector.getForCurrentChecklistId,
            TaskStatsSelector.getEntityMapForCurrentChecklistRevisionId,
            FeatureFlagSelector.getFeatureFlags,
          ],
          (currentChecklistId, currentChecklistRevisionId, checklist, taskStatsMap, featureFlags) => {
            if ($scope.revision && checklist && $scope.revision.checklist.status !== checklist.status) {
              $scope.revision.checklist.status = checklist.status;
              $scope.revision.checklist.completedBy = checklist.completedBy;
              $scope.revision.checklist.completedDate = checklist.completedDate;
            }

            // Only set the ctrl to initialized when the Redux state is ready
            const initialized =
              taskStatsMap &&
              checklist &&
              $scope.checklistId === currentChecklistId &&
              $scope.revision &&
              $scope.revision.id === currentChecklistRevisionId;

            return {
              taskStatsMap,
              initialized,
              featureFlags,
            };
          },
        );

        const mapDispatchToThis = {
          setStatsByChecklist: ChecklistMigrationActions.setStatsByChecklist,
        };

        unsubscribe = connectService('ChecklistCtrl', $ngRedux, mapStateToThis, mapDispatchToThis)($scope);
      }

      function initChecklistData() {
        $scope.initOneOffTasks();

        return ChecklistRevisionService.getActiveByChecklistId($scope.checklistId).then(
          revision => {
            $scope.revision = revision;
            $scope.templateRevision = $scope.revision.templateRevision;

            const { checklist } = revision;

            if (!$scope.isSandboxMode && checklist.template.templateType === TemplateType.WorkflowSandbox) {
              $state.go('sandboxChecklist', { id: checklist.id });
              return;
            }
            if (checklist.template.templateType === TemplateType.Task && !$scope.taskTemplateGroupId) {
              $state.go('dashboard');
              return;
            }

            // Switch to another organization if needed
            if (SecurityService.getSelectedOrganizationIdByUser($scope.user) !== checklist.organization.id) {
              OrganizationService.switchById(checklist.organization.id);
              return;
            }

            initializePermissions(checklist).then(() => {
              $scope.updatePageTitle($scope.revision.checklist.name);
            });

            trackAnalyticsEvent(checklist);

            initTaskRules($scope.templateRevision.id);

            $ngRedux.dispatch(WidgetActions.getAllByTemplateRevisionId($scope.templateRevision.id));
            $ngRedux.dispatch(WidgetActions.getAllChecklistWidgetsByChecklistRevisionId($scope.revision.id));
            $ngRedux.dispatch(RoleAssignmentRulesActions.getAllByTemplateRevisionId($scope.templateRevision.id));
            $ngRedux.dispatch(TaskStatsActions.getAllByChecklistRevisionId($scope.revision.id));
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading the workflow`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });

            if ($state.includes('checklist')) {
              $state.go('dashboard');
            }
          },
        );
      }

      function initTaskRules(templateRevisionId) {
        DueDateRuleDefinitionDao.getAllByTemplateRevisionId(templateRevisionId).then(rules => {
          logger.info('rules', rules);
          $scope.taskRules = rules;
          $scope.checklistRule = DynamicDueDateService.findRuleForChecklist(rules);
          $scope.taskRulesInited = true;
        });
      }

      function initOrganization() {
        if ($scope.user.userType === UserType.Anonymous) {
          OrganizationService.getById($scope.revision.checklist.organization.id).then(organization => {
            GrowSumoService.setPartnerKeyCookie(organization.growSumoPartnerKey);
          });
        }
      }

      /**
       * Updates page title
       */
      $scope.updatePageTitle = function (checklistName) {
        const title = abbreviateForTitle(checklistName);
        if (title && title.length) {
          $state.go($state.current, { title: `${title}-` }, { location: 'replace' });
        }

        $window.document.title = `${checklistName} | Process Street`;
      };

      /**
       * Tracks analytics related event
       *
       * @param checklist An assumption that checklist object includes template object in it
       */
      function trackAnalyticsEvent(checklist) {
        const { template } = checklist;

        const source = resolveSourceFromPreviousState();

        AnalyticsService.trackEvent(AnalyticsConstants.Event.CHECKLIST_VIEWED, {
          'template id': template.id,
          'template name': template.name,
          'checklist id': checklist.id,
          'checklist name': checklist.name,
          source,
        });
      }

      function resolveSourceFromPreviousState() {
        const previousState = TempDataService.getPreviousState();
        let source = ChecklistViewedSource.Direct;
        if (previousState && previousState.state.name) {
          if (previousState.state.name.startsWith('myWork')) {
            source = ChecklistViewedSource.Inbox;
          } else if (previousState.state.name.startsWith('dashboard')) {
            source = ChecklistViewedSource.Dashboard;
          } else if (previousState.state.name.startsWith('reports')) {
            source = ChecklistViewedSource.ChecklistDashboard;
          } else if (previousState.state.name.startsWith('template')) {
            source = ChecklistViewedSource.Template;
          }
        }

        return source;
      }

      $scope.permissionMap = {};

      function initializePermissions(checklist) {
        return $q
          .all({
            checklistUpdate: SecurityService.canUpdateChecklistByChecklist(checklist),
            templateRead: SecurityService.canReadTemplateByTemplate(checklist.template),
            taskManage: SecurityService.canManageTaskByChecklist(checklist),
            templateUpdate: SecurityService.canUpdateTemplateByTemplate(checklist.template),
          })
          .then(permissionMap => {
            $scope.permissionMap = permissionMap;
          });
      }

      $scope.$on('$destroy', () => {
        $scope.unsubscribeOneOffTasks?.();
        $window.removeEventListener('focus', notifyOnWorkflowUpdate);

        // Save the editor properties to server
        UserSettingsService.updateChecklistEditor(ChecklistEditorService.getProperties());
        UserSettingsService.updateChecklistStats(SessionService.getChecklistStatsProperties());

        if (!$scope.state.featureFlags?.runnerMigration) {
          AnonymousAuthService.logoutIfAuthedAnonymously();
        }
        unsubscribe();

        ablyService.close();
      });

      $scope.oneOffTasks = [];
      $scope.initOneOffTasks = () => {
        OneOffTaskAngularHelper.fetchOneOffTasksForChecklist(queryClient, $scope.checklistId).then(data => {
          $scope.oneOffTasks = data;
          // Open Task modal if provided in url param
          if (
            $stateParams[AppModalQueryParam.Modal] === AppModalName.OneOffTask &&
            $stateParams[AppModalQueryParam.ModalTaskId]
          ) {
            const task = data.find(t => t.id === $stateParams.modalTaskId);
            if (task) {
              oneOffTaskDrawerStore.getState().viewTask({ task });
            }
          }
        });
      };

      // subscribe to query updates
      $scope.unsubscribeOneOffTasks = OneOffTaskAngularHelper.subscribeToOneOffTasksForChecklist(
        queryClient,
        $scope.checklistId,
        $scope.initOneOffTasks,
      );

      $scope.completeChecklist = () => {
        ChecklistService.validateAndCompleteByChecklistRevision({
          checklistRevision: $scope.revision,
          formFieldValueMap: $scope.formFieldValueMap,
          complete: true,
          taskStatsMap: $scope.state.taskStatsMap,
          oneOffTasks: $scope.oneOffTasks,
        });
      };

      function initializeStatusTransitionListeners() {
        $scope.$on(ChecklistEvent.UPDATE_FAILED, (__event, data) => {
          let verb;
          if (data.errorHandled) return;

          switch (data.editedChecklist.status) {
            case ChecklistStatus.Active:
              verb = 'reactivating';
              break;
            case ChecklistStatus.Archived:
              verb = 'archiving';
              break;
            case ChecklistStatus.Completed:
              verb = 'completing';
              break;
            default:
              verb = null;
          }

          // Invalid checklist status to display toast
          if (!verb) return;
          const title = `We're having problems ${verb} the workflow run`;

          ToastService.openToast(UpdateErrorToasts.getToastOptions(data.response, title, title, $scope.user?.timeZone));
        });

        $scope.$on(RequiredFieldEvent.CHECKLIST_HAS_INVALID_FORM_FIELDS, (__event, data) => {
          if (data.invalidFormFields && data.invalidFormFields.length) {
            ToastService.openToast({
              status: 'warning',
              title: `We couldn't complete the workflow run`,
              description: `${data.invalidFormFields.length} field(s) still need to be completed.`,
            });
          }
        });
      }

      // Editor

      $scope.shouldShowStatusbar = () => {
        const { revision } = $scope;
        if (!revision) {
          return false;
        }

        return (
          ChecklistService.isChecklistCompleted(revision.checklist) ||
          ChecklistService.isChecklistArchived(revision.checklist)
        );
      };

      $scope.shouldShowRightbar = function () {
        if (FeatureFlagService.getFeatureFlags().sandboxMode && $scope.isSandboxMode) {
          return false;
        }
        return $scope.user && !isAnonymousUser($scope.user) && !$scope.isEmbeddedInMsTeams();
      };

      $scope.shouldShowSandboxBar = function () {
        return FeatureFlagService.getFeatureFlags().sandboxMode && $scope.isSandboxMode;
      };

      $scope.isUserAnonymous = function () {
        return $scope.user && isAnonymousUser($scope.user);
      };

      $scope.handleSubTitleClick = function () {
        $state.go('templateDashboard', { id: $scope.revision.checklist.template.id });
      };

      // Checklists

      $scope.isChecklistActionable = ChecklistService.isChecklistActionable;

      // Steps

      $scope.isEmptyChecklist = function () {
        return $scope.steps.length === 0;
      };

      $scope.selectTaskTemplate = function (taskTemplate, replace) {
        match($state)
          .when(
            $state => $state.includes('checklist'),
            () => {
              if (taskTemplate?.group?.id) {
                const commentId = $stateParams['#'];
                if (commentId) {
                  $state.go(
                    'checklist.task.comment',
                    { id: $stateParams.id, groupId: taskTemplate.group.id, commentId },
                    { location: replace ? 'replace' : true },
                  );
                } else {
                  $state.go(
                    'checklist.task',
                    { groupId: taskTemplate.group.id },
                    { location: replace ? 'replace' : true },
                  );
                }
              } else {
                $state.go('checklist');
              }
            },
          )
          .when(
            $state => $state.includes('sandboxChecklist'),
            () => {
              if (taskTemplate?.group?.id) {
                $state.go(
                  'sandboxChecklist.task',
                  { groupId: taskTemplate.group.id },
                  { location: replace ? 'replace' : true },
                );
              } else {
                $state.go('sandboxChecklist');
              }
            },
          )
          .when(
            $state => $state.includes('microsoftTeams'),
            () => {
              if (taskTemplate?.group?.id) {
                $state.go(
                  'msTeamsChecklist.task',
                  { groupId: taskTemplate.group.id },
                  { location: replace ? 'replace' : true },
                );
              } else {
                $state.go('msTeamsChecklist');
              }
            },
          )
          .run();
      };

      $scope.setActiveTaskTemplate = function (taskTemplate, task, completable, assignees, __canMoveUp, canMoveDown) {
        const groupId = taskTemplate.group.id;

        $scope.activeStep = {
          id: groupId,
          name: taskTemplate.name,
          status: task.status,
          taskType: taskTemplate.taskType,
        };
        $scope.activeStepTaskTemplate = taskTemplate;
        $scope.activeChecklistTask = task;
        $scope.activeTaskCompletable = completable;
        $scope.activeStepAssignees = assignees;
        $scope.activeStepHasAssignees = assignees && assignees.length > 0;
        $scope.activeStepHasDueDateOffset = taskTemplate.dueOffset;
        $scope.activeStepIsLastTask = !canMoveDown;

        $scope.doodadsMap[groupId] = $scope.doodadsMap[groupId] || [];

        $scope.activeTaskRule = getRuleForTask(groupId);
      };

      function getRuleForTask(groupId) {
        const taskRule =
          $scope.taskRules && $scope.taskRules.find(rule => rule.targetTaskTemplateGroup?.id === groupId);

        logger.info('taskRule', taskRule);
        return taskRule;
      }

      $scope.setActiveTaskStatus = function (taskTemplate, status) {
        // We need to know when the active step is updated so we can change the complete button
        if ($scope.activeStep && $scope.activeStep.id === taskTemplate.group.id) {
          $scope.activeStep.status = status;
        }
      };

      $scope.selectStepBelow = function (taskTemplate) {
        $rootScope.$broadcast(TaskListEvent.SELECT_TASK_BELOW_REQUEST, taskTemplate);
      };

      $scope.$on('$stateChangeSuccess', (__event, __toState, toParams) => {
        if (toParams.groupId) {
          $scope.taskTemplateGroupId = toParams.groupId;
        }
      });

      $scope.toggleStepStatus = function (taskTemplate) {
        $rootScope.$broadcast(TaskListEvent.TOGGLE_TASK_STATUS_REQUEST, taskTemplate);
      };

      $scope.toStepId = function (task) {
        return task.taskTemplate.group.id;
      };

      /**
       * A map indexed by task template group id, with each value being an array of sorted widgets.
       *
       * @type {{}}
       */
      $scope.widgetsMap = {};
      $scope.formFieldValueMap = {};

      $scope.initTaskTemplateWidgets = function (taskTemplateId) {
        $scope.widgetsMap[taskTemplateId] = $scope.widgetsMap[taskTemplateId] || [];
      };

      $scope.onTaskTemplatesLoaded = taskTemplates => {
        $scope.taskTemplates = taskTemplates;

        const { checklist } = $scope.revision;
        if ($scope.permissionMap.templateRead && checklist.status === ChecklistStatus.Active) {
          TemplateRevisionService.getAllNewestByTemplateId(checklist.template.id).then(tmplRevs => {
            const finishedTemplateRevision = tmplRevs[0];
            const newestTmplRevId = finishedTemplateRevision?.id;

            const { migrationStatus } = checklist;
            const migrating = migrationStatus !== ChecklistMigrationStatus.Inactive;

            $scope.migratable =
              newestTmplRevId && newestTmplRevId !== $scope.revision.templateRevision.id && !migrating;

            $scope.actions.setStatsByChecklist(checklist.id, migrationStatus, $scope.migratable);

            const migrationRequired = finishedTemplateRevision?.checklistMigrationStrategy !== 'Optional';

            if (migrating) {
              ChecklistMigrationService.showChecklistMigrationModal($scope.revision);
            } else if (
              $scope.migratable &&
              migrationRequired &&
              !AnonymousAuthService.isAuthenticatedAnonymously() &&
              !$scope.isSandboxMode
            ) {
              $scope._showMigrationMessage(migrationStatus);
            }
          });
        }
      };

      $scope._showMigrationMessage = migrationStatus => {
        if (migrationStatus === ChecklistMigrationStatus.Inactive) {
          ToastService.openToast({
            status: 'warning',
            title: `This workflow run is based on an old version of the workflow`,
            description: 'Click the Update to Latest button in the menu to update this workflow to the latest version.',
          });
        }
      };

      $scope.setChecklistProgress = function (progress) {
        $scope.checklistTasksProgress = progress;
      };

      // Redirecting

      let userConfirmedRedirect = false;

      // Adopted from https://github.com/angular-ui/ui-router/issues/1399#issuecomment-173949193
      $scope.$on('$stateChangeStart', (event, toState, toParams, __fromState, fromParams) => {
        if (toState.name === 'logout') {
          // Don't block logout
          return;
        }
        if (userConfirmedRedirect) {
          userConfirmedRedirect = false;
          return;
        }

        const checklistIsActive = $scope.revision && $scope.revision.checklist.status === ChecklistStatus.Active;
        const stateChangeIsWithinSameChecklist = toParams.id === $scope.checklistId;
        if (checklistIsActive && !stateChangeIsWithinSameChecklist) {
          const queueKey = PromiseQueueKeyGenerator.generateByChecklistId($scope.checklistId);
          if (PromiseQueueService.isInProgressByKey(queueKey)) {
            event.preventDefault();

            MessageBox.confirm({
              title: 'Are you sure you want to leave this page?',
              message: 'Please wait while we save your changes and try again.',
              okButton: {
                type: 'danger',
                text: 'Yes, I want to leave',
                action() {
                  userConfirmedRedirect = true;
                  $state.go(toState.name, toParams, __fromState, fromParams);
                },
              },
              cancelButton: {
                text: 'No, I will stay here',
              },
            });
          }
        }
      });

      // Tasks

      // We need to keep track of the progress of the tasks
      // The key is the task template id
      $scope.taskProgressMap = $scope.taskProgressMap || {};

      // Widgets

      // This method is fired by task list
      $scope.setWidgetsVisibility = function (visible, instantly) {
        if (visible) {
          showWidgets(instantly);
        } else {
          hideWidgets();
        }
      };

      $scope.toggleWidgets = function () {
        if ($scope.widgetsShown) {
          hideWidgets();
        } else {
          showWidgets();
        }
      };

      function showWidgets(instantly) {
        $scope.instantly = instantly;
        $scope.widgetsShown = true;

        $timeout(() => {
          $anchorScroll('widgets-top');
        });
      }

      function hideWidgets() {
        $scope.widgetsShown = false;
      }

      // Doodads

      $scope.doodadsMap = {};
      $scope.commentsCountMap = {};

      //Ms Teams
      $scope.isEmbeddedInMsTeams = function () {
        return $state.includes('microsoftTeams');
      };

      $scope.isAnonymousUser = user => isAnonymousUser(user);

      // complete WFR
      $rootScope.$on(
        ChecklistEvent.COMPLETED_FROM_ATTACHED_TASK,
        (_event, { oneOffTask, completedDate, completedBy }) => {
          const { checklist } = $scope.revision;

          if (oneOffTask.linkedChecklist.id === checklist.id) {
            $rootScope.$broadcast(ChecklistEvent.UPDATE_STARTED, {
              updatedChecklist: {
                ...checklist,
                status: ChecklistStatus.Completed,
                completedDate,
                completedBy,
              },
              originalChecklist: checklist,
            });
          }
        },
      );

      $rootScope.$on(ChecklistEvent.COMPLETED_FROM_APPROVAL_TASK, (_event, { approvalTask }) => {
        const { checklist } = $scope.revision;

        if (approvalTask.checklistRevision?.id === $scope.revision.id) {
          $rootScope.$broadcast(ChecklistEvent.UPDATE_STARTED, {
            updatedChecklist: {
              ...checklist,
              status: ChecklistStatus.Completed,
              completedDate: approvalTask.audit.updatedDate,
              completedBy: approvalTask.audit.updatedBy,
            },
            originalChecklist: checklist,
          });
        }
      });

      $scope.$on(EventName.TASK_DYNAMIC_DUE_DATE_UPDATED, (__event, { updatedTask }) => {
        if (updatedTask?.id === $scope.activeChecklistTask.id) {
          $scope.activeChecklistTask = { ...$scope.activeChecklistTask, ...updatedTask };
        }
      });
    },
  );
