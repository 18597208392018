import { Muid } from '@process-street/subgrade/core';
import { ChecklistMigrationStatus } from '@process-street/subgrade/process';
import { MigrationStatsByChecklist } from 'components/migration/store/migration.types';
import { Action, createAction } from 'redux-actions';

export type ChecklistMigrationActionsSetStatsByChecklistType = (
  checklistId: Muid,
  migrationStatus: ChecklistMigrationStatus,
  migratable?: boolean,
) => Action<MigrationStatsByChecklist>;

export const CHECKLIST_MIGRATION_SET_STATS_BY_CHECKLIST = 'checklistMigration/SET_STATS_BY_CHECKLIST';

export class ChecklistMigrationActions {
  public setStatsByChecklist: ChecklistMigrationActionsSetStatsByChecklistType = createAction(
    CHECKLIST_MIGRATION_SET_STATS_BY_CHECKLIST,
    (checklistId: Muid, migrationStatus: ChecklistMigrationStatus, migratable = false) => {
      const migrating = migrationStatus !== ChecklistMigrationStatus.Inactive;

      const stats: MigrationStatsByChecklist = {
        id: checklistId,
        migratable: migratable && !migrating,
        migrating,
        migrationStatus,
      };

      return stats;
    },
  );
}
