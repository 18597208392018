import {
  GetAllFoldersByOrganizationId,
  GetAllFoldersByOrganizationIdResult,
  useAllFoldersByOrganizationQuery,
} from 'features/folder/query-builder';
import { useInjector } from 'components/injection-provider';
import { Folder } from '@process-street/subgrade/process';
import { findPrivateRootFolder, sanitizePathParam } from 'features/folder/lib';
import { useSelectedOrganization } from 'hooks/use-selected-organization';

const selectCurrentFolderOrPrivateRootFolder =
  ({ path, pathType }: { path: string; pathType: 'folder' | 'tag' }) =>
  (folders: GetAllFoldersByOrganizationIdResult): Folder => {
    if (path) {
      return GetAllFoldersByOrganizationId.select.currentFolder({ path, pathType })(folders);
    } else {
      return findPrivateRootFolder(folders)!;
    }
  };

export const useCurrentOrPrivateRootFolderQuery = () => {
  const selectedOrganizationId = useSelectedOrganization()?.id;

  const { $stateParams } = useInjector('$stateParams');
  const path = sanitizePathParam($stateParams.path);
  const pathType = $stateParams.type;

  return useAllFoldersByOrganizationQuery(selectedOrganizationId!, {
    select: selectCurrentFolderOrPrivateRootFolder({ path, pathType }),
    enabled: !!selectedOrganizationId,
  });
};
