import { isValidEmail } from '@process-street/subgrade/process/validation';

function generateUsername() {
  let username = '';
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';

  const n = +(5 * Math.random());

  for (let i = 0; i < 8 + n; i += 1) {
    username += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
  }

  return username;
}

const isValidEmailAddress = isValidEmail;

function expandEmailAddress(email: string) {
  return email === '*@process.st' ? `${generateUsername()}@process.st` : email;
}

export const EmailService = {
  generateUsername,
  isValidEmailAddress,
  expandEmailAddress,
};
