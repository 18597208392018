export enum AppModalName {
  GlobalSearch = 'GlobalSearch',
  RunChecklist = 'RunChecklist',
  TemplateGallery = 'TemplateGallery',
  OneOffTask = 'OneOffTask',
  SignUpFirstWorkflow = 'SignUpFirstWorkflow',
  ImportWorkflow = 'ImportWorkflow',
  ConditionalLogic = 'ConditionalLogic',
  Automations = 'Automations',
}

export enum GlobalSearchVariant {
  Search = 'Search',
  Run = 'Run',
}

export enum AppModalQueryParam {
  Modal = 'modal',
  ModalTemplateId = 'modalTemplateId',
  ModalVariant = 'modalVariant',
  Source = 'source',
  SelectedFolderId = 'selectedFolderId',
  ModalTaskId = 'modalTaskId',
}
