import angular, { IRootScopeService } from 'angular';
import { EventName } from 'services/event-name';
import { CookieService } from 'features/cookies/cookie-service';
import { User, UserType } from '@process-street/subgrade/core';
import { SessionService } from 'services/session-service.interface';
import ngRedux from 'ng-redux';
import { ReduxAppState } from 'reducers/types';
import { SessionSelector } from 'reducers/session/session.selectors';
import { connectService } from 'reducers/util';
import { Trace, trace } from 'components/trace';
import { IStateService } from 'angular-ui-router';
import { AuthEvent } from 'services/auth-event/AuthEvent';
import { env } from 'components/common/env';
import { FeatureFlagService } from 'services/features/feature-flags/feature-flag-service';
import { OrganizationService } from 'services/organization-service.interface';

export enum UserIdCookieStrings {
  /** Cookie magic string to use for logged out users. */
  LOGGED_OUT = '<user logged out>',
  /** Cookie magic string set by app-boot-service for anonymous users. */
  ANONYMOUS = 'anonymous',
  MASQUERADING = '<user masquerading>',
}

const serviceName = 'subdomainRedirectService';

export interface SubdomainRedirectService {
  /**
   * Check whether
   * - user ID cookie matches current user to prevent different users logged in across subdomains
   * - current subdomain matches organization subdomain
   * @param options
   * - isLoggedIn: if true, user is already logged in, this is not a new login
   */
  setupSubdomainRedirectOnBoot(options: { isLoggedIn?: boolean; isMasquerading?: boolean }): void;
}

export class SubdomainRedirectServiceImpl implements SubdomainRedirectService {
  public static $inject = [
    '$rootScope',
    '$ngRedux',
    '$state',
    'SessionService',
    'FeatureFlagService',
    'OrganizationService',
  ];

  constructor(
    private $rootScope: IRootScopeService,
    private $ngRedux: ngRedux.INgRedux,
    private $state: IStateService,
    private sessionService: SessionService,
    private featureFlagService: FeatureFlagService,
    private organizationService: OrganizationService,
  ) {
    connectService(serviceName, this.$ngRedux, this.stateMapper, null)(this);
    this.logger = trace({ name: 'SubdomainRedirectService' });
  }

  private stateMapper = (state: ReduxAppState) => ({
    user: SessionSelector.getCurrentUser(state),
    plan: SessionSelector.getCurrentPlan(state),
    organization: SessionSelector.getSelectedOrganization(state),
    organizationSubdomainsMap: SessionSelector.getOrganizationSubdomainsMap(state) ?? {},
  });
  private logger: Trace;
  state!: ReturnType<typeof this.stateMapper>;

  async setupSubdomainRedirectOnBoot({ isLoggedIn = false, isMasquerading = false } = {}) {
    this.setListeners();
    if (isMasquerading) {
      // setting a special cookie survives reloads until logout
      // before masquerading, we clear the session, so cookie at this point is LOGGED_OUT
      // we set to MASQUERADING to not log the user out
      CookieService.setUserIdCookie(UserIdCookieStrings.MASQUERADING);
    }
    if (isLoggedIn) {
      const checkResult = this.isLoginValid();
      if (!checkResult) {
        this.$state.go('logout');
        return;
      }
    }
    await this.redirectToOrganizationSubdomain();
  }

  setListeners() {
    this.$rootScope.$on(AuthEvent.USER_LOGGED_IN, (_event, user: User) => {
      CookieService.setUserIdCookie(user.id);
    });
    this.$rootScope.$on(AuthEvent.USER_LOGGED_OUT, () => {
      CookieService.setUserIdCookie(UserIdCookieStrings.LOGGED_OUT);
    });
    this.$rootScope.$on(EventName.SELECTED_ORGANIZATION_UPDATED, async () => {
      // Switch user to update the feature flags based on the new organization
      if (!this.state.user) {
        // we are not logged in, no need to redirect or refetch FFs for a new user
        return;
      }
      this.featureFlagService.initializeForUser(this.state.user, this.state.organization, this.state.plan);
      this.redirectToOrganizationSubdomain();
    });
  }

  /**
   * Check whether user ID cookie matches current user ID. If not, log user out.
   * Returns false if check fails.
   * @private
   */
  isLoginValid(): boolean {
    if (this.isMasquerading() || this.isGoNativeTesting()) {
      this.logger.info('Masquerading; skipping user ID cookie check.');
      return true;
    }
    const isAnonymous = this.sessionService.getUser()?.userType === UserType.Anonymous;
    const cookieUserId = CookieService.getUserIdCookie();
    if (cookieUserId === UserIdCookieStrings.LOGGED_OUT) {
      this.logger.info('Logging user out due to logged out cookie.');
      return false;
    }
    const currentUserId = this.sessionService.getUser()?.id;
    const userMatchesCookie = cookieUserId === (isAnonymous ? UserIdCookieStrings.ANONYMOUS : currentUserId);
    if (!userMatchesCookie) {
      this.logger.info(
        `Logging user out due to mismatch between cookie user ID (${cookieUserId}) and local user ID (${currentUserId}).`,
      );
      return false;
    }
    return true;
  }

  /**
   * Check if current subdomain matches organization subdomain. If not, redirect user.
   * @private
   */
  async redirectToOrganizationSubdomain() {
    if (this.isGoNativeTesting()) {
      this.logger.info('Using ngrok.io; skipping subdomain redirect check.');
      return;
    }
    this.logger.info('Checking for subdomain redirect.');
    const { organizationSubdomainsMap } = this.state;
    const selectedOrganizationId = this.sessionService.getSelectedOrganizationId();
    if (!selectedOrganizationId) {
      return;
    }
    const subdomain = organizationSubdomainsMap[selectedOrganizationId];
    const expectedSubdomain = subdomain ?? env.APP_DEFAULT_SUBDOMAIN;
    await this.redirectToSubdomain(expectedSubdomain);
  }

  private isGoNativeTesting() {
    // If there's ngrok.io in the hostname, we're doing iOS/Android testing so skip the redirect check
    return (
      env.APP_DEFAULT_SUBDOMAIN.startsWith('gonative') ||
      env.APP_DEFAULT_SUBDOMAIN.startsWith('median') ||
      window.location.hostname.endsWith('ngrok.io')
    );
  }

  private isMasquerading() {
    return CookieService.getUserIdCookie() === UserIdCookieStrings.MASQUERADING;
  }

  async redirectToSubdomain(expectedSubdomain: string) {
    const expectedHostname = getHostnameWithSubdomain(expectedSubdomain);

    // Permanent feature flag to temporarily disable redirection for certain users
    // Allows for a 'grace period' while setting up subdomains for a customer
    // To use both 'app' and their new subdomain.
    const shouldSkipRedirection = (await this.featureFlagService.getFeatureFlagsAsync()).skipSubdomainRedirection;
    if (shouldSkipRedirection) {
      this.logger.info('"Skip subdomain redirection" flag enabled for user, allow using "app" or their subdomain.');
      if (window.location.hostname === getHostnameWithSubdomain(env.APP_DEFAULT_SUBDOMAIN)) {
        return;
      }
    }

    const currentHostnameIsMaskSubdomain = expectedSubdomain === 'app' && window.location.hostname === 'on.process.st';
    const currentHostnameMatches = window.location.hostname === expectedHostname;
    if (currentHostnameIsMaskSubdomain) {
      this.logger.info("Don't redirect because subdomain is the mask subdomain.");
    } else if (currentHostnameMatches) {
      this.logger.info("Don't redirect because subdomain is already correct.");
    } else {
      if (shouldSkipRedirection) {
        this.logger.info('"Skip subdomain redirection" flag enabled for user, redirecting to "app" subdomain.');
        window.location.hostname = getHostnameWithSubdomain(env.APP_DEFAULT_SUBDOMAIN);
        return;
      }
      this.logger.info(`Setting subdomain to (${expectedSubdomain}).`);
      window.location.hostname = expectedHostname;
      this.organizationService.removeLastSelectedOrganizationId();
    }
  }
}

function getHostnameWithSubdomain(subdomain: string) {
  const hostnameParts = window.location.hostname.split('.');
  hostnameParts[0] = subdomain;
  return hostnameParts.join('.');
}

angular.module('frontStreetApp.services').service(serviceName, SubdomainRedirectServiceImpl);
