import * as React from 'react';
import { Approval, ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { HStack, Text } from 'components/design/next';
import { User } from '@process-street/subgrade/core';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import { Divider, VStack } from '@chakra-ui/react';
import { ChakraAvatar } from 'components/design/next/chakra-avatar';

export type TaskApprovalInfoProps = {
  approval: Approval;
};

export const TaskApprovalInfo: React.FC<TaskApprovalInfoProps> = ({ approval }) => {
  const approvalDate = DateUtils.formatDateTime(
    approval.audit.updatedDate,
    DateFormat.DateTimeLongWeekdayShortMonthWithZone,
  );

  const approver = approval.audit.updatedBy as User;
  const color = approval.status === ApprovalStatus.Approved ? 'green.500' : 'red.500';

  return (
    <VStack border="1px solid" borderColor={color} padding={4} borderRadius={5} alignItems="stretch">
      <HStack justifyContent="space-between">
        <Text color="gray.500">
          This task has been{' '}
          <Text as="span" color={color}>
            {approval.status.toLowerCase()}
          </Text>{' '}
          by {(approval.audit.updatedBy as User).username}
        </Text>
        <Text color="gray.400">{approvalDate}</Text>
      </HStack>

      {approval.comment && (
        <VStack alignItems="stretch" gap={2}>
          <Divider borderColor="gray.200" my={3} />
          <HStack>
            <ChakraAvatar size="md" user={approver} />
            <Text color="gray.500" ml={2}>
              <Text as="span" fontWeight="bold">
                {approver.username}
              </Text>{' '}
              commented:
            </Text>
          </HStack>
          <Text color="gray.500" fontStyle="italic">
            &quot;{approval.comment}&quot;
          </Text>
        </VStack>
      )}
    </VStack>
  );
};
