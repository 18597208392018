import { createSelector, Selector } from 'reselect';
import { GroupMembership, Muid } from '../../core';
import { EntityMap, LightReduxState, ObjectMap } from '../types';

const getEntityMap = (state: LightReduxState): EntityMap<GroupMembership> => state.entities.groupMemberships;

const groupMembershipByGroupId: Selector<LightReduxState, ObjectMap<GroupMembership[]>> = createSelector(
  getEntityMap,
  groupMemberships =>
    Object.values(groupMemberships).reduce((agg, groupMembership) => {
      if (agg[groupMembership.group.id] === undefined) {
        agg[groupMembership.group.id] = [groupMembership];
      } else {
        agg[groupMembership.group.id].push(groupMembership);
      }
      return agg;
    }, {} as ObjectMap<GroupMembership[]>),
);

const getAllGroupMembershipsByGroupId =
  (groupId: Muid) =>
  (state: LightReduxState): GroupMembership[] =>
    groupMembershipByGroupId(state)[groupId] ?? [];

export const BaseGroupMembershipSelector = {
  getEntityMap,
  getAllGroupMembershipsByGroupId,
};
