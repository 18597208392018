import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  VStack,
} from 'components/design/next';
import React from 'react';
import { useActor } from '@xstate/react';
import { NumberFormFieldActor } from './number-form-field-machine';
import { ValidationSelectors } from '../validation-machine';
import { useSelector } from '@xstate/react';
import { RulesEngineTargetSelectors } from '../../form-response-body/rules-engine-machine';
import { StringUtils } from '@process-street/subgrade/util';
import { FormResponseLabel } from '../common';

export interface NumberFormFieldProps {
  actor: NumberFormFieldActor;
}

export const NumberFormField: React.FC<React.PropsWithChildren<NumberFormFieldProps>> = ({ actor }) => {
  const [state, send] = useActor(actor);
  const { widget } = state.context;
  const isHiddenByRule = useSelector(actor, RulesEngineTargetSelectors.getIsHiddenByRule);

  const onFocus = () => {
    send({ type: 'FOCUS' });
  };

  const onBlur = () => {
    send({ type: 'BLUR' });
  };

  const setValue: React.ChangeEventHandler<HTMLInputElement> = e => {
    send({ type: 'CHANGE', value: e.target.value });
  };

  const isInvalid = ValidationSelectors.isActorInvalidVisible(state.context.validationActor);

  const ref = React.useRef<HTMLDivElement | null>(null);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !state.context.inputNode) {
          actor.send({ type: 'SET_NODE', node });
        }
      }}
      as={VStack}
      alignItems="stretch"
      isInvalid={isInvalid}
      isRequired={widget.required}
    >
      <FormResponseLabel>{widget.label || 'Untitled Number'}</FormResponseLabel>
      <InputGroup>
        {widget.config.unitLocation === 'prefix' && widget.config.unit ? (
          <InputLeftAddon>{widget.config.unit}</InputLeftAddon>
        ) : null}

        <Input
          w={{ base: '100%', lg: '78' }}
          borderColor="gray.300"
          value={state.context.value ?? ''}
          placeholder={StringUtils.getNonEmpty(widget.config.placeholder, 'Type answer here...')}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={setValue}
          isDisabled={state.matches('input.disabled')}
          autoFocus={state.matches('autoFocus.enabled')}
        />

        {widget.config.unitLocation === 'suffix' && widget.config.unit ? (
          <InputRightAddon>{widget.config.unit}</InputRightAddon>
        ) : null}
      </InputGroup>
      <FormErrorMessage>{ValidationSelectors.errorMessage(state.context.validationActor)}</FormErrorMessage>
    </FormControl>
  );
};
