import {
  DueDateRuleDefinition,
  DueDateRuleOffsetDirection,
  DueDateRuleSourceType,
  FormFieldWidget,
  TaskTemplate,
} from '@process-street/subgrade/process';

function getOffsetLabel(value: number, singularLabel: string, pluralLabel: string) {
  if (value === 1) {
    return `${value} ${singularLabel}`;
  } else if (value > 1) {
    return `${value} ${pluralLabel}`;
  } else {
    return '';
  }
}

function getLabelByRule(rule: DueDateRuleDefinition, taskTemplate?: TaskTemplate, formFieldWidget?: FormFieldWidget) {
  const { sourceType } = rule;

  switch (sourceType) {
    case DueDateRuleSourceType.ChecklistDueDate:
      return 'workflow run due date';
    case DueDateRuleSourceType.ChecklistStartDate:
      return 'workflow run start date';
    case DueDateRuleSourceType.PreviousTaskCompletedDate:
      return 'previous task checked';
    case DueDateRuleSourceType.TaskCompletedDate: {
      const name = taskTemplate ? taskTemplate.name : 'unknown';
      return `task '${name}' completed date`;
    }
    case DueDateRuleSourceType.TaskDueDate: {
      const name = taskTemplate ? taskTemplate.name : 'unknown';
      return `task '${name}' due date`;
    }
    case DueDateRuleSourceType.FormFieldValue: {
      const name = formFieldWidget ? formFieldWidget.label : 'unknown';
      return `form value '${name}'`;
    }
    default:
      return sourceType;
  }
}

const appendOffset = (offset: number, singular: string, plural: string) => (label: string) =>
  label + getOffsetLabel(offset, singular, plural);

const appendOffsetDirection = (direction: DueDateRuleOffsetDirection) => (label: string) => {
  if (direction === DueDateRuleOffsetDirection.After) return label + 'after ';

  return label + 'before ';
};

const appendLabelByRule =
  (rule: DueDateRuleDefinition, taskTemplate?: TaskTemplate, formFieldWidget?: FormFieldWidget) => (label: string) =>
    label + getLabelByRule(rule, taskTemplate, formFieldWidget);

export namespace TaskListItemDynamicDueDateIndicatorHelpers {
  export function getFullLabelByRule(
    rule: DueDateRuleDefinition,
    taskTemplate?: TaskTemplate,
    formFieldWidget?: FormFieldWidget,
  ) {
    const offset = rule.dueOffset;

    const label = [
      appendOffset(offset.years, 'year ', 'years '),
      appendOffset(offset.months, 'month ', 'months '),
      appendOffset(offset.days, 'day ', 'days '),
      appendOffset(offset.hours, 'hr ', 'hrs '),
      appendOffset(offset.minutes, 'min ', 'mins '),
      appendOffsetDirection(rule.offsetDirection),
      appendLabelByRule(rule, taskTemplate, formFieldWidget),
    ].reduce((finalLabel, currentFn) => currentFn(finalLabel), 'Due ');

    return label;
  }
}
