import { Box, Button, Icon, Tooltip, useMediaQuery } from 'components/design/next';
import * as React from 'react';
import { ChecklistRuleDefinition, ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';
import { useRuleOperations, UseRuleOperationsReturn } from './rule-operations';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { RuleListProps } from './rule-list';
import { useScrollToInvalidRule, VIRTUALIZED_RULE_LIST_CONTAINER_CLASS_NAME } from './use-scroll-to-invalid-rule';
import { useField } from 'formik';
import { RuleDefinition } from 'features/conditional-logic/components/rule-definition';
import { getRuleHeight } from 'features/conditional-logic/components/rules-manager/get-rule-height';
import { useConditionalLogicModalStore } from '../modal/store';

export type VirtualizedRuleListProps = RuleListProps & {
  rules: ChecklistRuleDefinition[];
};

export const VirtualizedRuleList: React.FC<React.PropsWithChildren<VirtualizedRuleListProps>> = ({
  arrayHelpers,
  rules,
  widgets,
  taskTemplates,
  formFieldSelectOptions,
  selectedTaskWidgetGroupIds,
  initialRuleIds,
  showErrors,
}) => {
  const [isDesktop] = useMediaQuery('(min-width: 768px)');
  const ruleOperations = useRuleOperations(arrayHelpers);

  const variableSizeListRef = React.createRef<VariableSizeList>();
  React.useEffect(() => {
    variableSizeListRef.current?.resetAfterIndex(0);
  }, [rules, variableSizeListRef]);

  useScrollToInvalidRule(rules);
  const ruleHeights = React.useMemo(() => rules.map(rule => getRuleHeight(rule, isDesktop)), [isDesktop, rules]);

  return (
    <ReactVirtualizedAutoSizer>
      {({ height, width }: { height: number; width: number }) => (
        <VariableSizeList
          ref={variableSizeListRef}
          className={VIRTUALIZED_RULE_LIST_CONTAINER_CLASS_NAME}
          itemData={{
            ...ruleOperations,
            arrayHelpers,
            widgets,
            taskTemplates,
            formFieldSelectOptions,
            selectedTaskWidgetGroupIds,
            initialRuleIds,
            showErrors,
            rules,
          }}
          height={height}
          width={width}
          itemCount={rules.length + 1}
          itemSize={i => ruleHeights[i]}
          estimatedItemSize={ruleHeights.reduce((s, h) => s + h, 0) / ruleHeights.length}
          useIsScrolling
        >
          {Row}
        </VariableSizeList>
      )}
    </ReactVirtualizedAutoSizer>
  );
};

type RowProps = {
  index: number;
  style: React.CSSProperties;
  data: UseRuleOperationsReturn &
    Omit<RuleListProps, 'arrayHelpers'> & { showErrors: boolean; rules: ChecklistRuleDefinition[] };
  isScrolling?: boolean;
};

const Row: React.FC<React.PropsWithChildren<RowProps>> = React.memo(({ index, style, data, isScrolling }) => {
  const {
    formFieldSelectOptions,
    widgets,
    taskTemplates,
    selectedTaskWidgetGroupIds,
    showErrors,
    initialRuleIds,
    handleChange,
    handleDelete,
    handleDuplicate,
    handleAddRuleBelow,
    handleMoveUp,
    handleMoveDown,
    handleAddRuleAtTheBottom,
    rules,
  } = data;

  const { selectedWidget, selectedTask, selectedRule } = useConditionalLogicModalStore();
  const rule = rules[index];
  const [, { error }] = useField(`rules[${index}]`);

  const conditionWidgetGroupIds = React.useMemo(() => {
    return rule ? ConditionalLogicCommonUtils.getConditionFormFieldWidgetGroupIdsFromRule(rule) : new Set<string>();
  }, [rule]);

  if (!rule) {
    return (
      <Box
        style={{ ...style, height: 'auto' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingBottom="100px"
      >
        <Tooltip openDelay={500} label="Add a new rule at the end of the list">
          <Button
            bgColor="gray.100"
            color="gray.600"
            w="10"
            h="10"
            _hover={{ bgColor: 'gray.200' }}
            _active={{ bgColor: 'gray.300' }}
            onClick={handleAddRuleAtTheBottom}
            aria-label="Add a new rule at the end of the list"
          >
            <Icon icon="plus" size="4" />
          </Button>
        </Tooltip>
      </Box>
    );
  }

  if (selectedWidget && !conditionWidgetGroupIds.has(selectedWidget.header.group.id)) {
    return null;
  }
  if (selectedTask && !Array.from(conditionWidgetGroupIds).some(id => selectedTaskWidgetGroupIds.has(id))) {
    return null;
  }

  return (
    <Box id={`rule-definition-row-${index}`} key={rule.id} style={style}>
      <RuleDefinition
        formFieldSelectOptions={formFieldSelectOptions}
        widgets={widgets}
        taskTemplates={taskTemplates}
        rule={rule}
        onChange={rule => handleChange(index, rule as ChecklistRuleDefinition)}
        onDelete={() => handleDelete(index)}
        onDuplicate={() => handleDuplicate(index)}
        onAddRuleBelow={() => handleAddRuleBelow(index)}
        onMoveUp={rules[index - 1] ? () => handleMoveUp(index) : undefined}
        onMoveDown={rules[index + 1] ? () => handleMoveDown(index) : undefined}
        showErrors={showErrors}
        initialError={Boolean(error)}
        isNew={!initialRuleIds.has(rule.id)}
        hasRuleSelected={Boolean(selectedRule)}
        isScrolling={isScrolling}
      />
    </Box>
  );
});
