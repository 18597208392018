import * as React from 'react';
import { Flex, Stack, VStack, Text } from 'components/design/next';
import { SendRichEmailTemplateWidgetProps } from '..';
import {
  EmailBodyIframe,
  EmailMetaData,
  EmailMetaDataRowKey,
  EmailMetaDataRowValue,
  sanitizeEmailBody,
  sanitizeEmailRecipientsField,
} from '../../common/common';
import { SendTestEmailButton } from '../send-test-email-button';
import { getEmailBody, isSendTestEmailDisabled, normalizeBodyToHtml } from '../../util';
import { useParseContentForTemplateQuery } from './use-parse-content-for-template-query';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { safeEscapeHtml, preserveEmptyParagraphsWithNbsp } from '@process-street/subgrade/util';
import { SendTestMailtoButton } from '../send-test-mailto-button';
import { EmailAttachmentItem } from 'features/widgets/components/send-rich-email/email_attachment/email-attachment-item';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';

export type ViewProps = SendRichEmailTemplateWidgetProps;

export const View: React.FC<React.PropsWithChildren<ViewProps>> = ({ widget, templateRevision }) => {
  const { to, cc, bcc, subject, attachments } = widget.config;
  const body = getEmailBody(widget.config);

  const parseEmailContent = useParseContentForTemplateQuery({
    templateRevisionId: templateRevision.id,
    target: MergeTagsConstants.Target.EMAIL,
  });

  const parseGeneralContent = useParseContentForTemplateQuery({
    templateRevisionId: templateRevision.id,
    target: MergeTagsConstants.Target.RICH_CONTENT,
  });

  const sanitizeEmailHtml = (value: string) => {
    return sanitizeEmailRecipientsField(parseEmailContent(safeEscapeHtml(value)));
  };

  const parsedTo = sanitizeEmailHtml(to?.join(',') ?? '');
  const parsedCc = sanitizeEmailHtml(cc?.join(',') ?? '');
  const parsedBcc = sanitizeEmailHtml(bcc?.join(',') ?? '');

  const parsedSubject = sanitizeEmailRecipientsField(parseGeneralContent(safeEscapeHtml(subject ?? '')));

  const emailFormat = widget.config.emailFormat ?? 'RichTextOrHtml';
  const parsedBody = React.useMemo(
    () =>
      preserveEmptyParagraphsWithNbsp(
        sanitizeEmailBody(parseGeneralContent(normalizeBodyToHtml(body ?? '', emailFormat))),
      ),
    [body, emailFormat, parseGeneralContent],
  );

  const richEmailAutomationQuery = GetAllNativeAutomationsQuery.useFormFieldWidgetAutomationQuery({
    templateRevisionId: templateRevision?.id,
    formFieldWidgetId: widget?.id,
  });
  const isAutoSendNoticeDisplayed =
    richEmailAutomationQuery.isSuccess && richEmailAutomationQuery.data?.status === 'Active';

  return (
    <>
      <Stack spacing="4" backgroundColor="brand.50" paddingX={10} paddingY={6} borderRadius="12px">
        <EmailMetaData mb="4" ccVisible={(cc?.length ?? 0) > 0} bccVisible={(bcc?.length ?? 0) > 0}>
          <EmailMetaDataRowKey row="to">To</EmailMetaDataRowKey>
          <EmailMetaDataRowValue row="to" dangerouslySetInnerHTML={{ __html: parsedTo }} />

          {(cc?.length ?? 0) > 0 && (
            <>
              <EmailMetaDataRowKey row="cc">CC</EmailMetaDataRowKey>
              <EmailMetaDataRowValue row="cc" dangerouslySetInnerHTML={{ __html: parsedCc }} />
            </>
          )}

          {(bcc?.length ?? 0) > 0 && (
            <>
              <EmailMetaDataRowKey row="bcc">BCC</EmailMetaDataRowKey>
              <EmailMetaDataRowValue row="bcc" dangerouslySetInnerHTML={{ __html: parsedBcc }} />
            </>
          )}

          <EmailMetaDataRowKey row="subject">Subject</EmailMetaDataRowKey>
          <EmailMetaDataRowValue row="subject" dangerouslySetInnerHTML={{ __html: parsedSubject }} />

          <EmailMetaDataRowKey row="body">Body</EmailMetaDataRowKey>
          <EmailMetaDataRowValue row="body" borderLeft="solid 1px" borderLeftColor="gray.300">
            <EmailBodyIframe srcDoc={parsedBody} />
          </EmailMetaDataRowValue>
        </EmailMetaData>

        {attachments && attachments.length > 0 && (
          <VStack alignItems="start" paddingLeft={22}>
            {attachments.map(attachment => (
              <EmailAttachmentItem
                key={attachment.attachment.id}
                attachment={attachment}
                mode="view"
                backgroundColor="gray.100"
              />
            ))}
          </VStack>
        )}

        <Flex justifyContent="end">
          {emailFormat === 'RichTextOrHtml' && (
            <SendTestEmailButton
              widgetHeaderId={widget.header.id}
              isDisabled={isSendTestEmailDisabled(widget.config)}
              isRequired={widget.required}
            />
          )}

          {emailFormat === 'PlainText' && (
            <SendTestMailtoButton
              config={widget.config}
              isDisabled={isSendTestEmailDisabled(widget.config)}
              isRequired={widget.required}
            />
          )}
        </Flex>
      </Stack>
      {isAutoSendNoticeDisplayed && (
        <Text fontStyle="italic" fontSize="sm" mt={2}>
          This email will be sent automatically when the task is complete.
        </Text>
      )}
    </>
  );
};
