import * as React from 'react';
import { SkeletonText, Text } from 'components/design/next';
import { Muid } from '@process-street/subgrade/core';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { match, P } from 'ts-pattern';

export type Props = { templateId: Muid };

export const TemplateTitle: React.FC<React.PropsWithChildren<Props>> = ({ templateId }) => {
  const templateQuery = useGetTemplateQuery({ templateId });
  return match(templateQuery)
    .with({ status: 'loading' }, () => <SkeletonText skeletonHeight="2" />)
    .with({ status: 'success', data: { name: P.select('name') } }, ({ name }) => <Text variant="inherit">{name}</Text>)
    .with({ status: 'error' }, () => <Text variant="inherit">(Error)</Text>)
    .otherwise(() => null);
};
