import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { handleActionsOnSuccess, setDeletedByIds, toSuccess, toFailure, toRequest } from 'reducers/util';
import { TASK_TEMPLATE_DELETE_ALL } from 'reducers/task-template/task-template.actions';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { WIDGET_DELETE_BY_HEADER_ID } from '../../widgets/store/widget.actions';
import {
  RA_RULES_GET_ALL_BY_TEMPLATE_REVISION,
  RA_RULE_DELETE,
  RA_RULE_CREATE,
  RA_RULE_CREATE_ALL,
  RA_RULE_DELETE_ALL,
} from './role-assignment-rules.actions';

export const roleAssignmentRuleEntitiesReducer = handleActions(
  {
    [toSuccess(RA_RULES_GET_ALL_BY_TEMPLATE_REVISION)]: (state, { payload: rules }) =>
      EntitiesReducerUtils.upsertAll(state, rules),
    [toSuccess(RA_RULE_CREATE)]: (state, { payload: rule }) => EntitiesReducerUtils.upsert(state, rule),
    [toSuccess(RA_RULE_CREATE_ALL)]: (state, { payload: rules }) => EntitiesReducerUtils.upsertAll(state, rules),
    [toRequest(RA_RULE_DELETE)]: (state, { meta: { ruleId } }) => setDeletedByIds(state, [ruleId], true /*deleted*/),
    [toSuccess(RA_RULE_DELETE)]: (state, { meta: { ruleId } }) => EntitiesReducerUtils.delete(state, { id: ruleId }),
    [toFailure(RA_RULE_DELETE)]: (state, { meta: { ruleId } }) => setDeletedByIds(state, [ruleId], false /*deleted*/),
    [toRequest(RA_RULE_DELETE_ALL)]: (state, { meta: { ruleIds } }) =>
      setDeletedByIds(state, ruleIds, true /*deleted*/),
    [toSuccess(RA_RULE_DELETE_ALL)]: (state, { meta: { ruleIds } }) =>
      EntitiesReducerUtils.deleteAll(
        state,
        ruleIds.map(id => ({ id })),
      ),
    [toFailure(RA_RULE_DELETE_ALL)]: (state, { meta: { ruleIds } }) =>
      setDeletedByIds(state, ruleIds, false /*deleted*/),
    [TASK_TEMPLATE_DELETE_ALL]: (state, { meta: { relatedRoleAssignmentRules } }) =>
      EntitiesReducerUtils.deleteAll(state, relatedRoleAssignmentRules),
    [WIDGET_DELETE_BY_HEADER_ID]: (state, { meta: { relatedRoleAssignmentRules } }) =>
      EntitiesReducerUtils.deleteAll(state, relatedRoleAssignmentRules),
  },
  {},
);

export const roleAssignmentRuleByTemplateRevisionIdReducer = handleActionsOnSuccess(
  {
    [RA_RULES_GET_ALL_BY_TEMPLATE_REVISION]: (state, { payload: rules, meta: { templateRevisionId } }) =>
      LookupsReducerUtils.replaceAllUsingSelectorFunctions(state, rules, () => templateRevisionId),
    [RA_RULE_CREATE]: (state, { payload: rule, meta: { templateRevisionId } }) =>
      LookupsReducerUtils.upsertUsingSelectorFunctions(state, rule, () => templateRevisionId),
    [RA_RULE_CREATE_ALL]: (state, { payload: rules, meta: { templateRevisionId } }) =>
      LookupsReducerUtils.upsertAllUsingSelectorFunctions(state, rules, () => templateRevisionId),
    [RA_RULE_DELETE]: (state, { meta: { templateRevisionId, ruleId } }) =>
      LookupsReducerUtils.deleteAllByIds(state, templateRevisionId, [ruleId]),
    [RA_RULE_DELETE_ALL]: (state, { meta: { templateRevisionId, ruleIds } }) =>
      LookupsReducerUtils.deleteAllByIds(state, templateRevisionId, ruleIds),
    [TASK_TEMPLATE_DELETE_ALL]: (state, { payload: taskTemplates, meta: { relatedRoleAssignmentRules } }) => {
      if (relatedRoleAssignmentRules.length === 0) {
        return state;
      }

      // here we must assume that this action is performed under the same template revision
      const templateRevisionId = taskTemplates[0].templateRevision.id;
      return LookupsReducerUtils.deleteAllUsingSelectorFunctions(
        state,
        relatedRoleAssignmentRules,
        () => templateRevisionId,
      );
    },
    [WIDGET_DELETE_BY_HEADER_ID]: (state, { meta: { templateRevisionId, relatedRoleAssignmentRules } }) => {
      if (relatedRoleAssignmentRules.length === 0) {
        return state;
      }

      return LookupsReducerUtils.deleteAllUsingSelectorFunctions(
        state,
        relatedRoleAssignmentRules,
        () => templateRevisionId,
      );
    },
  },
  {},
);

export const roleAssignmentRuleLookupsReducer = combineReducers({
  byTemplateRevisionId: roleAssignmentRuleByTemplateRevisionIdReducer,
});
