import { escapeHtml as esc, unescapeHtml as unesc } from '@process-street/subgrade/util';
import { sprintf } from 'sprintf-js';
import { Verb } from 'services/activities/verb';
import { StringService } from 'services/string-service';
import { TemplateType, tmplTypeNameLower } from '@process-street/subgrade/process';
import { trace } from 'components/trace';
import { interpret, valuateData } from './activity-service.pure';
import {
  Activity,
  ActivityObjectType,
  AttachmentActivity,
  ChecklistActivity,
  ChecklistAssignmentActivity,
  ChecklistRevisionActivity,
  ChecklistTaskAssignmentActivity,
  CommentActivity,
  FolderActivity,
  ManyChecklistTaskAssignmentsActivity,
  MaskedActivity,
  TagActivity,
  TagMembershipActivity,
  TemplateActivity,
  TemplatePermitActivity,
  TemplateRevisionActivity,
} from '@process-street/subgrade/activity';
import { InterpretedActivity } from './interpreters/InterpreterContext';
import { match, P } from 'ts-pattern';
import { Muid, toModelRef } from '@process-street/subgrade/core';
import { generateAnonymousUser } from '@process-street/subgrade/test';
import _get from 'lodash/get';

const logger = trace({ name: 'TemplateActivityUtils' });
const { abbreviate: abbr } = StringService;
const spf = sprintf;

export function interpretTemplate(
  activity: TemplateActivity,
  timeZone: string | undefined,
): InterpretedActivity | undefined {
  const template = toModelRef(activity.object);
  let sentence: InterpretedActivity | undefined = interpret(activity);
  const templateTypeName = tmplTypeNameLower(template);
  switch (activity.verb) {
    case Verb.CREATED:
      sentence.verb.value = 'created';
      sentence.objects.push({ value: templateTypeName });
      break;
    case Verb.DELETED:
      sentence.verb.value = 'deleted';
      sentence.objects.push({ value: templateTypeName });
      break;
    case Verb.RESTORED:
      sentence.verb.value = 'restored';
      sentence.objects.push({ value: templateTypeName });
      break;
    case Verb.UPDATED:
      switch (activity.data.status) {
        case 'Active':
          sentence.verb.value = 'unarchived';
          sentence.objects.push({ value: templateTypeName });
          break;
        case 'Archived':
          sentence.verb.value = 'archived';
          sentence.objects.push({ value: templateTypeName });
          break;
        case 'Deleted':
          sentence.verb.value = 'deleted';
          sentence.objects.push({ value: templateTypeName });
          break;
        default:
          if (activity.data.public) {
            sentence.verb.value = 'made';
            sentence.objects.push({ value: `${templateTypeName} public` });
          } else if (activity.data.newFolder) {
            sentence.verb.value = 'added';
            const newFolderName = esc(abbr(activity.data.newFolder.name));
            sentence.objects.push({ value: spf('%s to folder "%s"', templateTypeName, newFolderName) });
          } else if (activity.data.oldFolder) {
            sentence.verb.value = 'removed';
            const oldFolderName = esc(abbr(activity.data.oldFolder.name));
            sentence.objects.push({ value: spf('%s from folder "%s"', templateTypeName, oldFolderName) });
          } else {
            sentence.verb.value = 'changed';
            sentence.objects.push({ value: templateTypeName });
            sentence.objects.push(valuateData(activity.data, timeZone));
          }
      }
      break;
    default:
      logger.error('unrecognized verb: %s', activity.verb);
      sentence = undefined;
  }
  return sentence;
}

function interpretFolder(activity: FolderActivity): InterpretedActivity | undefined {
  const sentence = interpret(activity);
  const base = {
    type: activity.objectType,
    id: activity.object.id,
  };
  const name = esc(abbr(activity.data.name));
  switch (activity.verb) {
    case Verb.ATTACHED:
      sentence.verb.value = 'added';
      sentence.objects.push({ ...base, value: spf('template to folder "%s"', name) });
      break;
    case Verb.DETACHED:
      sentence.verb.value = 'removed';
      sentence.objects.push({ ...base, value: spf('template from folder "%s"', name) });
      break;
    default:
      logger.error('unrecognized verb: %s', activity.verb);
      return undefined;
  }
  return sentence;
}

function interpretTag(activity: TagActivity): InterpretedActivity | undefined {
  const sentence = interpret(activity);
  const name = esc(abbr(activity.data.name));
  switch (activity.verb) {
    case Verb.DELETED:
      sentence.verb.value = 'removed';
      sentence.objects.push({ value: spf('template from tag "%s"', name) });
      break;
    default:
      logger.error('unrecognized verb: %s', activity.verb);
      return undefined;
  }
  return sentence;
}

function interpretTagMembership(activity: TagMembershipActivity): InterpretedActivity | undefined {
  const sentence = interpret(activity);
  const tagName = esc(abbr(activity.data.tag.name));
  switch (activity.verb) {
    case Verb.CREATED:
      sentence.verb.value = 'added';
      sentence.objects.push({ value: spf('template to tag "%s"', tagName) });
      break;
    case Verb.DELETED:
      sentence.verb.value = 'removed';
      sentence.objects.push({ value: spf('template from tag "%s"', tagName) });
      break;
    default:
      logger.error('unrecognized verb: %s', activity.verb);
      return undefined;
  }
  return sentence;
}

function interpretTemplateRevision(activity: TemplateRevisionActivity): InterpretedActivity | undefined {
  const sentence = interpret(activity);
  const templateRevision = toModelRef(activity.object);
  const template = toModelRef(templateRevision?.template);
  const templateTypeName = tmplTypeNameLower(template);
  switch (activity.verb) {
    case Verb.CREATED:
      sentence.verb.value = 'started editing a';
      sentence.objects.push({ value: templateTypeName });
      break;
    case Verb.UPDATED:
      switch (activity.data.status) {
        case 'Finished':
          sentence.verb.value = 'published changes to a';
          sentence.objects.push({ value: templateTypeName });
          break;
        case 'Deleted':
          sentence.verb.value = 'discarded changes to a';
          sentence.objects.push({ value: templateTypeName });
          break;
        default:
          logger.error('unrecognized template revision status: %s', activity.data.status);
          return undefined;
      }
      break;
    default:
      logger.error('unrecognized verb: %s', activity.verb);
      return undefined;
  }
  if (templateRevision) {
    sentence.contextObjects.push({
      value: `Version ${templateRevision.revision}`,
    });
  }
  return sentence;
}

function interpretChecklistAssignment(activity: ChecklistAssignmentActivity): InterpretedActivity | undefined {
  const sentence = interpret(activity);

  const checklistId = _get(activity.data, 'checklist.id');
  const checklistName = (_get(activity.data, 'checklist.name') as string) || 'Some checklist';
  const userId = _get(activity.data, 'user.id') as Muid;
  const username = (_get(activity.data, 'user.username') as string) || 'Someone';

  const base = {
    value: spf('<a>%s</a>', esc(abbr(username))),
    tooltip: username,
    type: ActivityObjectType.User,
    id: userId,
  };
  switch (activity.verb) {
    case Verb.CREATED:
      sentence.verb.value = 'assigned';
      sentence.objects.push({ ...base });
      sentence.objects.push({
        value: spf('to a <a>workflow run</a> "%s"', esc(abbr(checklistName))),
        tooltip: checklistName,
        type: ActivityObjectType.Checklist,
        id: checklistId,
      });
      break;
    case Verb.DELETED:
      sentence.verb.value = 'unassigned';
      sentence.objects.push({ ...base });
      sentence.objects.push({
        value: spf('from a <a>workflow run</a> "%s"', esc(abbr(checklistName))),
        tooltip: checklistName,
        type: ActivityObjectType.Checklist,
        id: checklistId,
      });
      break;
    default:
      logger.error('unrecognized verb: %s', activity.verb);
      return undefined;
  }
  return sentence;
}

function getValueFromChecklistActivity(activity: ChecklistActivity): string {
  const checklist = toModelRef(activity.object);
  const template = toModelRef(checklist?.template);
  const templateType = template?.templateType ?? TemplateType.Playbook;
  return templateType === TemplateType.Form
    ? 'a <a>form response</a>'
    : spf(`a <a>workflow run</a> "%s"`, esc(abbr(checklist?.name)));
}

function getValueFromChecklistRevisionActivity(activity: ChecklistRevisionActivity): string {
  const checklistRevision = toModelRef(activity.object);
  const checklist = toModelRef(checklistRevision?.checklist);
  const template = toModelRef(checklist?.template);

  const templateType = template?.templateType ?? TemplateType.Playbook;
  return templateType === TemplateType.Form
    ? 'a <a>form response</a>'
    : spf(`a <a>workflow run</a> "%s"`, esc(abbr(checklist?.name)));
}

function interpretChecklist(
  activity: ChecklistActivity,
  timeZone: string | undefined,
): InterpretedActivity | undefined {
  const sentence = interpret(activity);
  const checklist = toModelRef(activity.object);
  const value = getValueFromChecklistActivity(activity);

  const base = {
    value,
    tooltip: checklist?.name,
    type: activity.objectType,
    id: activity.object.id,
  };
  switch (activity.verb) {
    case Verb.CREATED:
      sentence.verb.value = 'started';
      sentence.objects.push({ ...base });
      break;
    case Verb.DELETED:
      sentence.verb.value = 'deleted';
      sentence.objects.push({ ...base });
      break;
    case Verb.RESTORED:
      sentence.verb.value = 'restored';
      sentence.objects.push({ ...base });
      break;
    case Verb.UPDATED:
      switch (activity.data.status) {
        case 'Active':
          sentence.verb.value = 'unarchived';
          sentence.objects.push({ ...base });
          break;
        case 'Archived':
          sentence.verb.value = 'archived';
          sentence.objects.push({ ...base });
          break;
        case 'Deleted':
          sentence.verb.value = 'deleted';
          sentence.objects.push({ ...base });
          break;
        default:
          sentence.verb.value = 'changed';
          sentence.objects.push({ ...base, value: 'a <a>workflow run</a>' });
          sentence.objects.push(valuateData(activity.data, timeZone));
      }
      break;
    default:
      logger.error('unrecognized verb: %s', activity.verb);
      return undefined;
  }
  return sentence;
}

function interpretChecklistRevision(
  activity: ChecklistRevisionActivity,
  timeZone: string | undefined,
): InterpretedActivity | undefined {
  const sentence = interpret(activity);
  const checklistRevision = toModelRef(activity.object);
  const checklist = toModelRef(checklistRevision?.checklist);

  const value = getValueFromChecklistRevisionActivity(activity);
  const base = {
    value,
    tooltip: checklist?.name,
    type: ActivityObjectType.Checklist,
    id: checklist?.id,
  };
  switch (activity.verb) {
    case Verb.UPDATED:
      switch (activity.data.status) {
        case 'Migrated':
          sentence.verb.value = 'updated';
          sentence.objects.push({ ...base, value: `${base.value} to the latest version` });
          break;
        default:
          sentence.verb.value = 'changed';
          sentence.objects.push({ ...base, value: 'a <a>workflow run</a>' });
          sentence.objects.push(valuateData(activity.data, timeZone));
      }
      break;
    default:
      logger.error('unrecognized verb: %s', activity.verb);
      return undefined;
  }
  return sentence;
}

function interpretComment(activity: CommentActivity, extended?: boolean): InterpretedActivity | undefined {
  const comment = toModelRef(activity.object);
  const task = toModelRef(comment?.task);
  const taskTemplate = toModelRef(task?.taskTemplate);
  const checklistRevision = toModelRef(task?.checklistRevision);
  const checklist = toModelRef(checklistRevision?.checklist);

  const checklistName = activity.data.checklistName ?? checklist?.name;
  const taskTemplateName = activity.data.taskTemplateName ?? taskTemplate?.name;

  const sentence = interpret(activity);
  const base = {
    type: activity.objectType,
    id: activity.object.id,
  };
  switch (activity.verb) {
    case Verb.CREATED:
      sentence.verb.value = 'posted';
      sentence.objects.push({ ...base, value: 'a <a>comment</a>' });
      sentence.objects.push({
        ...base,
        value: spf('to <a>task</a> "%s"', esc(abbr(taskTemplateName))),
        tooltip: taskTemplateName,
      });
      sentence.objects.push({
        ...base,
        value: spf('in <a>workflow run</a> "%s"', esc(abbr(checklistName))),
        tooltip: checklistName,
      });
      if (extended) {
        sentence.quote = activity.data.content;
      } else {
        sentence.objects.push({
          value: spf('saying "%s"', esc(abbr(unesc(activity.data.content)))),
          tooltip: activity.data.content,
        });
      }
      break;
    case Verb.UPDATED:
      sentence.verb.value = 'deleted';
      sentence.objects.push({
        ...base,
        value: spf('a comment from <a>task</a> "%s"', esc(abbr(taskTemplateName))),
        tooltip: taskTemplateName,
      });
      sentence.objects.push({
        ...base,
        value: spf('in <a>workflow run</a> "%s"', esc(abbr(checklistName))),
        tooltip: checklistName,
      });
      sentence.objects.push({
        value: spf('saying "%s"', esc(abbr(unesc(activity.data.content)))),
        tooltip: activity.data.content,
      });
      break;
    default:
      logger.error('unrecognized verb: %s', activity.verb);
      return undefined;
  }
  return sentence;
}

function interpretAttachment(activity: AttachmentActivity, extended?: boolean): InterpretedActivity | undefined {
  const attachment = toModelRef(activity.object);
  const task = toModelRef(attachment?.task);
  const taskTemplate = toModelRef(task?.taskTemplate);
  const checklistRevision = toModelRef(task?.checklistRevision);
  const checklist = toModelRef(checklistRevision?.checklist);

  const checklistName = match(activity.data)
    .with({ checklistName: P.string }, data => data.checklistName)
    .otherwise(() => checklist?.name);

  const taskTemplateName = match(activity.data)
    .with({ taskTemplateName: P.string }, data => data.taskTemplateName)
    .otherwise(() => taskTemplate?.name);

  const originalName = match(activity.data)
    .with({ fileOriginalName: P.string }, data => data.fileOriginalName)
    .otherwise(() => attachment?.file.originalName);

  const sentence = interpret(activity);
  const base = {
    type: activity.objectType,
    id: activity.object.id,
  };
  switch (activity.verb) {
    case Verb.CREATED:
      sentence.verb.value = 'attached';
      sentence.objects.push({
        ...base,
        value: spf('a file to <a>task</a> "%s"', esc(abbr(taskTemplateName))),
        tooltip: taskTemplateName,
      });
      sentence.objects.push({
        ...base,
        value: spf('in <a>workflow run</a> "%s"', esc(abbr(checklistName))),
        tooltip: checklistName,
      });
      sentence.objects.push({
        value: spf('named "%s"', esc(abbr(unesc(originalName)))),
        tooltip: originalName,
      });
      if (extended) {
        sentence.file = attachment?.file;
      }
      break;
    case Verb.UPDATED:
      sentence.verb.value = 'deleted';
      sentence.objects.push({
        value: spf('an attachment from <a>task</a> "%s"', esc(abbr(taskTemplateName))),
        tooltip: taskTemplateName,
      });
      sentence.objects.push({
        ...base,
        value: spf('in <a>workflow run</a> "%s"', esc(abbr(checklistName))),
        tooltip: checklistName,
      });
      sentence.objects.push({
        value: spf('named "%s"', esc(abbr(unesc(originalName)))),
        tooltip: originalName,
      });
      break;
    default:
      logger.error('unrecognized verb: %s', activity.verb);
      return undefined;
  }
  return sentence;
}

function interpretTemplatePermit(activity: TemplatePermitActivity): InterpretedActivity | undefined {
  const sentence = interpret(activity);
  const { data } = activity;

  switch (activity.verb) {
    case Verb.CREATED:
      sentence.verb.value = 'added';
      sentence.objects.push({
        value: spf('<a>%s</a> to template', esc(abbr(data.user.username))),
        tooltip: data.user.username,
        type: ActivityObjectType.User,
        id: data.user.id,
      });
      sentence.objects.push({
        value: spf('with access level <b>%s</b>', data.level || '???'),
      });
      break;
    case Verb.UPDATED:
      sentence.verb.value = 'set access level of';
      sentence.objects.push({
        value: spf('<a>%s</a>', esc(abbr(data.user.username))),
        tooltip: data.user.username,
        type: ActivityObjectType.User,
        id: data.user.id,
      });
      sentence.objects.push({
        value: spf('to <b>%s</b>', data.level || '???'),
      });
      break;
    case Verb.DELETED:
      sentence.verb.value = 'removed';
      sentence.objects.push({
        value: spf('<a>%s</a> from template', esc(abbr(data.user.username))),
        tooltip: data.user.username,
        type: ActivityObjectType.User,
        id: data.user.id,
      });
      break;
    default:
      logger.error('unrecognized verb: %s', activity.verb);
      return undefined;
  }

  return sentence;
}

function interpretChecklistTaskAssignment(activity: ChecklistTaskAssignmentActivity): InterpretedActivity | undefined {
  const sentence = interpret(activity);
  const { data } = activity;

  switch (activity.verb) {
    case Verb.CREATED:
      sentence.verb.value = 'assigned';
      sentence.objects.push({
        value: spf('<a>task</a> "%s"', esc(abbr(data.taskTemplate.name))),
        tooltip: data.taskTemplate.name,
        type: ActivityObjectType.Task,
        id: data.task.id,
      });
      sentence.objects.push({
        value: spf('to <a>%s</a>', esc(abbr(data.assignee.username))),
        tooltip: data.assignee.username,
        type: ActivityObjectType.User,
        id: data.assignee.id,
      });
      sentence.objects.push({
        value: spf('in <a>workflow run</a> "%s"', esc(abbr(data.checklist.name))),
        tooltip: data.checklist.name,
        type: ActivityObjectType.Checklist,
        id: data.checklist.id,
      });
      break;
    case Verb.DELETED:
      sentence.verb.value = 'unassigned';
      sentence.objects.push({
        value: spf('<a>%s</a>', esc(abbr(data.assignee.username))),
        tooltip: data.assignee.username,
        type: ActivityObjectType.User,
        id: data.assignee.id,
      });
      sentence.objects.push({
        value: spf('from <a>task</a> "%s"', esc(abbr(data.taskTemplate.name))),
        tooltip: data.taskTemplate.name,
        type: ActivityObjectType.Task,
        id: data.task.id,
      });
      sentence.objects.push({
        value: spf('in <a>workflow run</a> "%s"', esc(abbr(data.checklist.name))),
        tooltip: data.checklist.name,
        type: ActivityObjectType.Checklist,
        id: data.checklist.id,
      });
      break;
    default:
      logger.error('unrecognized verb: %s', activity.verb);
      return undefined;
  }
  return sentence;
}

function interpretManyChecklistTaskAssignments(
  activity: ManyChecklistTaskAssignmentsActivity,
): InterpretedActivity | undefined {
  const sentence = interpret(activity);
  const { data } = activity;

  const assignmentsTooltip = activity.data.assignments
    .map(a => spf('%s to "%s"', a.assignee.username, abbr(a.taskTemplate.name)))
    .join(', ');

  switch (activity.verb) {
    case Verb.CREATED:
      sentence.verb.value = 'assigned';
      sentence.objects.push({
        value: 'one or more tasks',
        tooltip: assignmentsTooltip,
      });
      sentence.objects.push({
        value: spf('in <a>workflow run</a> "%s"', esc(abbr(data.checklist.name))),
        tooltip: data.checklist.name,
        type: ActivityObjectType.Checklist,
        id: data.checklist.id,
      });
      break;
    default:
      logger.error('unrecognized verb: %s', activity.verb);
      return undefined;
  }
  return sentence;
}

const interpretMasked = (activity: MaskedActivity): InterpretedActivity => ({
  date: activity.date,
  verb: {
    value: "You don't have permission to see this activity.",
  },
  activity,
  subject: generateAnonymousUser(),
  objects: [],
  contextObjects: [],
});

const interpretTemplateActivity = function (
  activity: Activity,
  { timeZone = 'UTC', extended = false }: Partial<{ timeZone: string | undefined; extended: boolean }>,
): InterpretedActivity | undefined {
  try {
    const sentence = match(activity)
      .with({ objectType: ActivityObjectType.Folder }, activity => interpretFolder(activity))
      .with({ objectType: ActivityObjectType.Tag }, activity => interpretTag(activity))
      .with({ objectType: ActivityObjectType.TagMembership }, activity => interpretTagMembership(activity))
      .with({ objectType: ActivityObjectType.Template }, activity => interpretTemplate(activity, timeZone))
      .with({ objectType: ActivityObjectType.TemplateRevision }, activity => interpretTemplateRevision(activity))
      .with({ objectType: ActivityObjectType.ChecklistAssignment }, activity => interpretChecklistAssignment(activity))
      .with({ objectType: ActivityObjectType.Checklist }, activity => interpretChecklist(activity, timeZone))
      .with({ objectType: ActivityObjectType.ChecklistRevision }, activity =>
        interpretChecklistRevision(activity, timeZone),
      )
      .with({ objectType: ActivityObjectType.Comment }, activity => interpretComment(activity, extended))
      .with({ objectType: ActivityObjectType.Attachment }, activity => interpretAttachment(activity, extended))
      .with({ objectType: ActivityObjectType.TemplatePermit }, activity => interpretTemplatePermit(activity))
      .with({ objectType: ActivityObjectType.ChecklistTaskAssignment }, activity =>
        interpretChecklistTaskAssignment(activity),
      )
      .with({ objectType: ActivityObjectType.ManyChecklistTaskAssignments }, activity =>
        interpretManyChecklistTaskAssignments(activity),
      )
      .with({ objectType: ActivityObjectType.Masked }, activity => interpretMasked(activity))
      .otherwise(() => {
        logger.error('unrecognized object type: %s', activity.objectType);
        return undefined;
      });
    return sentence;
  } catch (e) {
    logger.error('an error occurred interpreting the activity data', e);
    return undefined;
  }
};

export const TemplateActivityUtils = {
  interpret: interpretTemplateActivity,
};
