import { ChecklistStatus, TaskStatus } from '@process-street/subgrade/process';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { UpdateTaskStatusMutation } from 'features/task/query-builder';
import { GetOneOffTasksByChecklistQuery } from 'features/one-off-tasks/query-builder';
import { useInjector } from 'components/injection-provider';
import { ChecklistEvent } from 'services/checklists/checklist-event';
import { useNextThemeToast } from 'components/design/next/use-next-theme-toast';
import { useQueryClient } from 'react-query';
import { useOneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { OneOffTaskHelper } from '../one-off-task-helper';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { InboxItem, InboxItemType } from '@process-street/subgrade/inbox';
import { isFunction } from 'lodash';

export const useCompleteTaskAndOpenNext = (task: OneOffTask) => {
  const toast = useNextThemeToast();
  const queryClient = useQueryClient();
  const { $rootScope } = useInjector('$rootScope');
  const { viewTask, onClose } = useOneOffTaskDrawerStore();

  const oneOffTasksQuery = GetOneOffTasksByChecklistQuery.useQuery(
    {
      checklistId: task.linkedChecklist?.id,
    },
    { enabled: Boolean(task.linkedChecklist?.id) },
  );

  return UpdateTaskStatusMutation.useMutation({
    onMutate: variables => {
      const isTargetInboxItem = (item: InboxItem) => {
        return item.itemType === InboxItemType.OneOffTask && item.task.id === variables.taskId;
      };

      return GetInboxItemsQuery.removeItem(queryClient, isTargetInboxItem);
    },
    onError: (_error, _variables, context) => {
      if (isFunction(context)) {
        // Revert the optimistic update in case of error
        context();
      }

      toast({ status: 'error', title: "We're having problems updating the task." });
    },
    onSuccess: async data => {
      const isTaskCompleted = data.task.status === TaskStatus.Completed;
      if (isTaskCompleted) {
        toast({ status: 'success', title: 'Task completed' });
      }

      GetInboxItemsQuery.invalidate(queryClient);

      if (task.linkedChecklist) {
        await queryClient
          .invalidateQueries(GetOneOffTasksByChecklistQuery.getKey({ checklistId: task.linkedChecklist.id }))
          .then(() => {
            if (isTaskCompleted && data.checklistStatus === ChecklistStatus.Completed) {
              $rootScope.$broadcast(ChecklistEvent.COMPLETED_FROM_ATTACHED_TASK, {
                oneOffTask: task,
                completedDate: data.task.completedDate,
                completedBy: data.task.completedBy,
              });
            } else {
              const oneOffTasks = (oneOffTasksQuery.data ?? []).filter(t => t.id !== task.id);
              const nextRequiredTask =
                data.task.completedBy &&
                OneOffTaskHelper.findFirstRequiredNotCompletedUserTask(oneOffTasks, data.task.completedBy.id);

              if (nextRequiredTask) {
                viewTask({ task: nextRequiredTask });
              } else {
                onClose();
              }
            }
          });
      } else {
        onClose();
      }
    },
  });
};
