import { TemplateSolutionInstance } from '@process-street/subgrade/automation';
import { Muid, Option } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateTemplateSolutionInstanceMutationParams = {
  templateId: Muid;
  solutionInstanceId: string;
  enabled: boolean;
  configured?: boolean;
};

export type UpdateTemplateSolutionInstanceMutationResponse = Option<TemplateSolutionInstance>;

export const UpdateTemplateSolutionInstanceMutation = {
  key: ['patch', 'template-solution-instance'],
  mutationFn: ({ templateId, solutionInstanceId, ...params }: UpdateTemplateSolutionInstanceMutationParams) =>
    axiosService
      .getAxios()
      .patch<UpdateTemplateSolutionInstanceMutationResponse>(
        `/1/templates/${templateId}/automations/template-solution-instances/${solutionInstanceId}`,
        params,
      )
      .then(res => res.data),
};

export const useUpdateTemplateSolutionInstanceMutation = (
  options: UseMutationOptions<
    UpdateTemplateSolutionInstanceMutationResponse,
    AxiosError,
    UpdateTemplateSolutionInstanceMutationParams
  > = {},
) => {
  return useMutation(UpdateTemplateSolutionInstanceMutation.mutationFn, options);
};
