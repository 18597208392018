import * as React from 'react';
import { Link, Tab, TabList, TabProps, Tabs } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { useCanViewDataSets } from 'pages/reports/data-sets/hooks/use-can-view-data-sets';

export const MainTabs = () => {
  const { $state } = useInjector('$state');
  const canViewDataSets = useCanViewDataSets();

  const selectedTabIndex = $state.current.name?.includes('dataSets') ? 1 : 0;

  const tabStyles: Partial<TabProps> = {
    fontWeight: '500',
    textColor: 'gray.500',
    _hover: { textDecoration: 'none', textColor: 'brand.500' },
    sx: {
      '&[aria-selected=true]': {
        borderColor: 'brand.500',
        textColor: 'brand.500',
      },
    },
  };

  return (
    <Tabs w="full" index={selectedTabIndex}>
      <TabList>
        <Tab h="14" as={Link} href="/reports" {...tabStyles}>
          Workflow Runs
        </Tab>
        {canViewDataSets && (
          <Tab h="14" as={Link} href="/data-sets" {...tabStyles}>
            Data Sets
          </Tab>
        )}
      </TabList>
    </Tabs>
  );
};
