import { FormControl, FormLabel, Select, useToast, Text, Button } from 'components/design/next';
import { FolderPickerModal, PickButtonProps, PickerAddonTop } from 'app/components/folder-picker/modal';
import React from 'react';
import { useTemplateMenuContext, useTemplateMenuDisclosureContext } from '../template-menu';
import { useGetTemplateQuery } from 'app/features/template/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useGetCurrentUserInfoQuery, useGetUserOrganizationsMembershipsQuery } from 'app/features/user/query-builder';
import { isNotIdRef } from '@process-street/subgrade/core';
import { useInjector } from 'app/components/injection-provider';
import { Folder, Template, TemplateType, tmplTypeName, tmplTypeNameLower } from '@process-street/subgrade/process';
import { stateNames } from '../constants';
import { SHOW_WORKFLOW_SETUP_MODAL_FOR } from 'app/components/focus-bar/workflow/right-button-group';
import { HttpStatus } from '@process-street/subgrade/util';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';

export const DuplicateTemplateModal = () => {
  const { TemplateService, $state } = useInjector('TemplateService', '$state');
  const { templateId } = useTemplateMenuContext();
  const { duplicateTemplateModalDisclosure: disclosure } = useTemplateMenuDisclosureContext();

  const [selectedOrganizationId, setSelectedOrganizationId] = React.useState('');
  const sessionOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const [duplicating, setDuplicating] = React.useState(false);
  const [copiedTemplate, setCopiedTemplate] = React.useState<Template | null>(null);
  const copiedTemplateId = copiedTemplate?.id;

  // Queries
  const { data: { user } = {} } = useGetCurrentUserInfoQuery();

  const currentUserId = user?.id;

  const { data: organizations = [] } = useGetUserOrganizationsMembershipsQuery(currentUserId!, {
    enabled: !!currentUserId,
    select: data => data.map(om => om.organization).filter(isNotIdRef),
  });

  const { name: copiedOrgName = undefined, id: copiedOrgId = undefined } = copiedTemplate
    ? organizations.find(org => org.id === copiedTemplate.organization.id) ?? {}
    : {};

  const { data: template } = useGetTemplateQuery({ templateId });
  const initialFolderId = template?.folder.id ?? '';

  React.useEffect(() => {
    if (!selectedOrganizationId) {
      setSelectedOrganizationId(sessionOrganizationId ?? '');
    }
  }, [sessionOrganizationId, selectedOrganizationId]);

  const toast = useToast();

  const templateType = template?.templateType ?? TemplateType.Page;
  const stateName = stateNames[templateType];

  React.useEffect(() => {
    if (copiedTemplateId) {
      disclosure.onClose();
      localStorage.setItem(SHOW_WORKFLOW_SETUP_MODAL_FOR, copiedTemplateId);

      if (sessionOrganizationId === copiedOrgId) {
        $state.go(stateName, { id: copiedTemplateId });
      } else {
        toast({
          title: (
            <Text>
              {tmplTypeName(template)} copied to{' '}
              <Text fontWeight="bold" as="b">
                {copiedOrgName}
              </Text>
              .
            </Text>
          ),

          status: 'success',
        });
      }
    }
  }, [
    $state,
    copiedOrgId,
    copiedOrgName,
    copiedTemplateId,
    disclosure,
    sessionOrganizationId,
    stateName,
    template,
    toast,
  ]);

  const handleError = React.useCallback(
    (folder: Folder) => (response: Response) => {
      if (response.status === HttpStatus.PAYMENT_REQUIRED) {
        const targetOrganization = organizations.find(org => org.id === folder.organization.id);
        toast({
          status: 'error',
          title: "We're having problems copying the workflow",
          description: (
            <>
              <Text as="b" fontWeight="bold">
                {targetOrganization?.name}
              </Text>{' '}
              is at its workflow limit.
            </>
          ),
        });
      } else {
        toast({
          status: 'error',
          title: `We're having problems copying the ${tmplTypeNameLower(template)}`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      }
    },
    [organizations, toast, template],
  );

  const copyToFolder = React.useCallback(
    (folder: Folder) => {
      if (!template) return;
      setDuplicating(true);
      TemplateService.copy(template, folder.id)
        .then(template => {
          setCopiedTemplate(template);
        })
        .catch(handleError(folder))
        .finally(() => {
          setDuplicating(false);
        });
    },
    [template, TemplateService, handleError],
  );

  return (
    <FolderPickerModal
      title="Duplicate to..."
      verb="duplicate"
      initialFolderId={initialFolderId}
      pickButton={PickButton}
      onPick={copyToFolder}
      isLoading={duplicating}
      loadingText="Duplicating&hellip;"
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      organizationId={selectedOrganizationId}
    >
      <PickerAddonTop>
        <FormControl id="duplicate template organization" mb="2">
          <FormLabel>Organization</FormLabel>
          <Select
            value={selectedOrganizationId}
            onChange={e => {
              setSelectedOrganizationId(e.target.value);
            }}
          >
            {organizations.map(org => (
              <option key={org.id} value={org.id}>
                {org.name}
                {org.id === sessionOrganizationId ? ' (current organization)' : ''}
              </option>
            ))}
          </Select>
        </FormControl>
      </PickerAddonTop>
    </FolderPickerModal>
  );
};

const PickButton: React.FC<React.PropsWithChildren<PickButtonProps>> = ({ pickType, ...props }) => (
  <Button {...props}>
    Duplicate
    {pickType === 'to_private' ? ' to Private' : null}
    {pickType === 'to_organization' ? ' to Organization' : null}
  </Button>
);
