import * as React from 'react';
import { PlanAlert } from 'pages/organizations/manage/billing/components/plan-alert/component';
import { Flex, Text } from 'components/design/next';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import { StripeBillingPortalButton } from 'components/billing/stripe-billing-portal-button';
import { Organization } from '@process-street/subgrade/core';
import { Button } from 'components/design/next';

type ActionMessageProps = {
  action: string;
  message?: string;
};
const ActionMessage: React.FC<React.PropsWithChildren<ActionMessageProps & { onClick: () => void }>> = ({
  action,
  message,
  onClick,
}) => (
  <Flex>
    {message && <Text as="span">{message}</Text>}
    <Button onClick={onClick} variant="unstyled" ml={2}>
      <Text as="span" color="brand.500">
        {action}
      </Text>
    </Button>
  </Flex>
);

const StripeActionMessage: React.FC<
  React.PropsWithChildren<
    ActionMessageProps & { organization: Organization; onStripeError?: () => Promise<void> | void }
  >
> = ({ action, message, organization, onStripeError }) => (
  <Flex>
    {message && (
      <Text as="span" lineHeight="normal">
        {message}
      </Text>
    )}
    <StripeBillingPortalButton onStripeError={onStripeError} organization={organization} variant="unstyled" ml={1}>
      <Text as="span" color="brand.500" lineHeight="normal">
        {action}
      </Text>
    </StripeBillingPortalButton>
  </Flex>
);

export const PlanAlertActiveFree: React.FC<React.PropsWithChildren<unknown>> = () => (
  <PlanAlert status="info" title="Get unlimited workflows and workflow runs." iconName={'star'} iconColor="brand.500">
    Check out our Pro plan.
  </PlanAlert>
);

type PlanAlertActiveProProps = {
  onContactClick: () => void;
};

export const PlanAlertActivePro: React.FC<React.PropsWithChildren<PlanAlertActiveProProps>> = ({ onContactClick }) => (
  <PlanAlert status="info" title="Have questions?" iconName={'user-headset'} iconColor="brand.500">
    <ActionMessage
      action="Contact Us."
      onClick={onContactClick}
      message="Our customer support team is ready to assist you."
    />
  </PlanAlert>
);

export const PlanAlertPastDue: React.FC<
  React.PropsWithChildren<{ organization: Organization; errorCallback?: () => Promise<void> | void }>
> = ({ organization, errorCallback }) => (
  <PlanAlert status="error" title="Payment past due." iconName="exclamation-triangle" iconColor="red.500">
    <StripeActionMessage
      onStripeError={errorCallback}
      organization={organization}
      action="Update now"
      message="Please update your credit card information."
    />
  </PlanAlert>
);

type PlanAlertExceededProps = {
  onUpgradeClick: () => void;
};

export const PlanAlertExceeded: React.FC<React.PropsWithChildren<PlanAlertExceededProps>> = ({ onUpgradeClick }) => (
  <PlanAlert status="error" title="You’ve hit your plan limits." iconName="exclamation-triangle" iconColor="red.500">
    <ActionMessage action="Upgrade to new plan" onClick={onUpgradeClick} />
  </PlanAlert>
);

type PlanAlertCancelAtPeriodEndProps = {
  currentPeriodEndDate: number;
};

export const PlanAlertCancelAtPeriodEnd: React.FC<React.PropsWithChildren<PlanAlertCancelAtPeriodEndProps>> = ({
  currentPeriodEndDate,
}) => {
  const date = DateUtils.formatDateTime(currentPeriodEndDate, DateFormat.DateLongMonth);

  return (
    <PlanAlert status="error" iconName="exclamation-triangle" iconColor="red.500">
      You've canceled your Pro plan, but you can use premium features until {date}.
    </PlanAlert>
  );
};

type PlanAlertTrialProps = {
  daysLeft: number;
};

export const PlanAlertTrial: React.FC<React.PropsWithChildren<PlanAlertTrialProps>> = ({ daysLeft }) => (
  <PlanAlert status="info" iconName="star" iconColor="brand.500" title={`${daysLeft} days left in Pro trial.`}>
    At the end of your trial, you may upgrade or stay on Free.
  </PlanAlert>
);

export const PlanAlertTrialExpired: React.FC<React.PropsWithChildren<unknown>> = () => (
  <PlanAlert status="error" iconName="exclamation-triangle" iconColor="red.500" title="Your trial has expired.">
    Select a plan to continue using Process Street.
  </PlanAlert>
);

type PlanAlertLegacyProps = {
  pricePerMember: number;
  onUpgradeClick: () => void;
};

export const PlanAlertLegacy: React.FC<React.PropsWithChildren<PlanAlertLegacyProps>> = ({
  pricePerMember,
  onUpgradeClick,
}) => (
  <PlanAlert status="info">
    <ActionMessage
      action="Upgrade to new plan"
      onClick={onUpgradeClick}
      message={`You're currently on a legacy plan for $${pricePerMember} per member per month.`}
    />
  </PlanAlert>
);

export const PlanAlertExpired: React.FC<React.PropsWithChildren<unknown>> = () => (
  <PlanAlert status="error" iconName="exclamation-triangle" iconColor="red.500" title="Your plan has expired.">
    Choose a new plan to continue using Process Street.
  </PlanAlert>
);
