import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { axiosService } from 'services/axios-service';
import { trace } from 'components/trace';
import { useToast } from 'components/design/next';
import { GetCoverIconByTemplateId } from 'features/cover-icon/query-builder/cover-icon-by-template-id';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export namespace DeleteCoverIconMutation {
  export type Params = { templateId: Template['id'] };

  export type Response = {};

  export const key = ['delete', 'icon'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .delete<Response>(`/1/templates/${params.templateId}/cover-icon`)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) =>
    useRQMutation(mutationFn, options);

  export const useMutationWithToast = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    const toast = useToast();
    const logger = trace({ name: 'RemoveIcon' });
    const queryClient = useQueryClient();
    return useRQMutation(mutationFn, {
      onSuccess: (_, variables) => {
        toast({
          status: 'success',
          title: 'Icon deleted',
        });
        queryClient.invalidateQueries(GetCoverIconByTemplateId.getKey({ templateId: variables.templateId }));
      },
      onError: e => {
        logger.error(e.message);
        toast({
          status: 'error',
          title: "We're having problems deleting the icon",
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      },
      ...options,
    });
  };
}
