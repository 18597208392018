import * as React from 'react';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useSelector } from '@xstate/react';
import {
  isHeading,
  TaskConstants,
  TaskTemplate,
  TaskTemplateTaskType,
  TemplateRevision,
} from '@process-street/subgrade/process';
import { components, GroupBase, MultiValue } from 'react-select';
import { BlvdSelect, BlvdSelectProps } from 'app/components/design/BlvdSelect';
import { Box, Button, Checkbox, Flex, HStack, Icon, Text } from 'components/design/next';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { ApprovalRuleSubject, ApprovalUtils } from '@process-street/subgrade/approval-rule';
import { MenuSearchHeader } from 'app/components/design/BlvdSelect/components';
import { ExtendedComponentsConfig } from 'app/components/design/BlvdSelect/types';
import {
  CreateApprovalRulesMutation,
  DeleteApprovalRuleMutation,
  GetApprovalRulesByTemplateRevisionIdQuery,
} from 'app/features/approval-rules/query-builder';
import { useQueryClient } from 'react-query';
import { ApprovalTaskUtils } from './utils';
import { Muid } from '@process-street/subgrade/core';
import { Dictionary } from 'lodash';
import { useHighlightedTasksContext } from '../../../providers/highlighted-tasks-context';
import _keyBy from 'lodash/keyBy';
import { useDeepCompareEffect } from 'react-use';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';

export interface TaskSelectProps {
  taskTemplateGroupId: Muid;
  tasksFromApprovalRule: ApprovalRuleSubject[];
  tasksMap: Dictionary<TaskTemplate>;
  templateRevisionId: TemplateRevision['id'];
}

export interface TaskTemplateItem extends TaskTemplate {
  isChecked: boolean;
  isDisabled: boolean;
  isHeader: boolean;
  approvalRuleId?: Muid;
}

type Option = TaskTemplateItem;

const MENU_SCREEN_PADDING = 20;

export const TaskSelect: React.FC<TaskSelectProps> = ({
  taskTemplateGroupId,
  tasksFromApprovalRule,
  tasksMap,
  templateRevisionId,
}) => {
  const actor = useFormEditorPageActorRef();
  const { setHighlightedTasksMap } = useHighlightedTasksContext();
  const tasks = useSelector(actor, FormEditorPageActorSelectors.getTaskTemplates);
  const queryClient = useQueryClient();
  const createApprovalRulesMutation = CreateApprovalRulesMutation.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(GetApprovalRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId }));
    },
  });

  const deleteApprovalRuleMutation = DeleteApprovalRuleMutation.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(GetApprovalRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId }));
    },
  });

  const [selectedTasks, setSelectedTasks] = React.useState<MultiValue<TaskTemplateItem>>([]);

  const currentTaskOrder = tasksMap[taskTemplateGroupId].orderTree;

  const taskTemplateOptions = React.useMemo(() => {
    const convertTaskTemplateToOption = (taskTemplate: TaskTemplate): Option => {
      const taskFromList = tasksFromApprovalRule.find(task => task.subjectTaskTemplateGroupId === taskTemplate.id);
      return {
        ...taskTemplate,
        isChecked: Boolean(taskFromList),
        isDisabled: !ApprovalTaskUtils.isTaskTemplateEnabled(taskTemplate, currentTaskOrder, tasks),
        isHeader: isHeading(taskTemplate),
        approvalRuleId: taskFromList?.id,
      };
    };
    return tasks.map(convertTaskTemplateToOption);
  }, [tasks, currentTaskOrder, tasksFromApprovalRule]);

  useDeepCompareEffect(
    function setSelectedFromQuery() {
      if (!ApprovalTaskUtils.rulesAreTheSame(tasksFromApprovalRule, selectedTasks)) {
        const initialTasks = tasksFromApprovalRule.map(task => {
          const taskTemplate = tasksMap[task.subjectTaskTemplateGroupId];
          return {
            approvalRuleId: task.id,
            isChecked: true,
            isHeader: isHeading(taskTemplate),
            isDisabled: !ApprovalTaskUtils.isTaskTemplateEnabled(taskTemplate, currentTaskOrder, tasks),
            ...taskTemplate,
          };
        });
        setSelectedTasks(initialTasks);
      }
    },
    [tasksFromApprovalRule, tasksMap, currentTaskOrder],
  );

  useDeepCompareEffect(
    function addOrRemoveHighlights() {
      const taskListFromRules = tasksFromApprovalRule.map(taskRule => tasksMap[taskRule.subjectTaskTemplateGroupId]);
      const highLightedTasksMap = _keyBy(taskListFromRules, task => task.group.id);
      setHighlightedTasksMap(highLightedTasksMap);

      return () => setHighlightedTasksMap(undefined);
    },
    [tasksFromApprovalRule, setHighlightedTasksMap],
  );

  const filterTaskOption: BlvdSelectProps<Option>['filterOption'] = (option, inputValue) => {
    const taskTemplateItem = option.data as Option;
    return taskTemplateItem.name?.toLowerCase().includes(inputValue.toLowerCase()) ?? false;
  };

  const addedHeaderTask = (rules: ApprovalRuleSubject[]) => {
    for (const rule of rules) {
      if (isHeading(tasksMap[rule.subjectTaskTemplateGroupId])) return true;
    }
  };

  const getSubsequentTasks = (headerGroupId: Muid): ApprovalRuleSubject[] => {
    const headerIndex = tasks.findIndex((task: TaskTemplate) => task.group.id === headerGroupId);
    if (headerIndex === -1) return [];

    let shouldContinue = true;
    const currentRulesMap = _keyBy(tasksFromApprovalRule, rule => rule.subjectTaskTemplateGroupId);
    return tasks
      .slice(headerIndex + 1)
      .filter((task: TaskTemplate) => {
        if (!shouldContinue) return false;
        if (isHeading(task) || task.group.id === taskTemplateGroupId) {
          shouldContinue = false;
          return false;
        }
        if (currentRulesMap[task.group.id]) return false;
        return task.taskType === TaskTemplateTaskType.Standard;
      })
      .map((task: TaskTemplate) =>
        ApprovalUtils.createApprovalFromTaskTemplate({
          taskTemplate: task as TaskTemplate,
          organizationId: task.organization.id,
          templateRevisionId,
          approvalTaskTemplateGroupId: taskTemplateGroupId,
        }),
      );
  };

  const handleChange = (value: MultiValue<TaskTemplateItem>) => {
    setSelectedTasks(value);
    const tasksAsApprovalTask = value.map(task =>
      ApprovalUtils.createApprovalFromTaskTemplate({
        id: task.approvalRuleId,
        taskTemplate: task as TaskTemplate,
        organizationId: task.organization.id,
        templateRevisionId,
        approvalTaskTemplateGroupId: taskTemplateGroupId,
      }),
    );
    const { added, removed } = ApprovalTaskUtils.findArrayChanges({
      originalArray: tasksFromApprovalRule,
      modifiedArray: tasksAsApprovalTask,
    });

    if (added && added.length > 0) {
      if (addedHeaderTask(added)) {
        const headerGroup = added[0].subjectTaskTemplateGroupId;
        added.pop(); // remove task header
        added.push(...getSubsequentTasks(headerGroup));
      }
      createApprovalRulesMutation.mutate({
        approvalRuleSubjects: added,
        taskTemplateGroupId,
        templateRevisionId,
      });
    }

    if (removed && removed.length > 0) {
      deleteApprovalRuleMutation.mutate({
        templateRevisionId,
        ids: removed,
      });
    }
  };

  const hasRules = tasksFromApprovalRule.length > 0;

  return (
    <Flex justifyContent="center" w="full" pr={8} pl={8}>
      <BlvdSelect
        id="task-select"
        filterOption={filterTaskOption}
        getOptionValue={option => option.id}
        placeholder="Select Task(s) to Approve"
        options={taskTemplateOptions}
        value={selectedTasks}
        components={{
          ...BlvdTaskSelectPickerComponents,
          DropdownIndicator: hasRules ? AddNewTaskTrigger : SelectTasksTrigger,
        }}
        menuPosition="fixed"
        menuPlacement="top"
        onChange={handleChange}
        styles={{
          ...ApprovalTaskUtils.BLVD_CONTROL_STYLES,
          container: () => ({
            'width': '598px',
            '.blvd-select__indicators': { width: '100%' },
            '.blvd-select__control': { minHeight: 'unset' },
          }),
          option: (provided, _state) => ({
            ...provided,
            paddingTop: '4px',
            paddingBottom: '4px',
          }),
          menuPortal: (provided, state) => ({
            ...provided,
            // make sure the dropdown won't fall off the screen
            maxWidth: `calc(100vw - ${state.rect.left + MENU_SCREEN_PADDING}px)`,
            width: '600px',
            zIndex: 1500,
          }),
        }}
        isMulti
        isSearchable
      />
    </Flex>
  );
};

export const BlvdTaskSelectPickerComponents: ExtendedComponentsConfig<Option, true, GroupBase<Option>> = {
  Option: ({ children: _, ...props }) => {
    const { name, isDisabled, isHeader } = props.data as Option;
    return (
      <components.Option {...props}>
        <HStack
          w="full"
          spacing="2"
          ml={isHeader ? 0 : 2}
          height="32px"
          alignItems="center"
          _hover={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
        >
          <Checkbox isChecked={props.isSelected} isDisabled={isDisabled} pointerEvents="none" />
          <Text as="span" fontWeight={isHeader ? '700' : '400'} isTruncated color={isDisabled ? 'gray.400' : 'inherit'}>
            {name ?? TaskConstants.DefaultTaskName}
          </Text>
        </HStack>
      </components.Option>
    );
  },
  ValueContainer: props => {
    return (
      <Box w="0" overflow="hidden" h="0">
        <components.ValueContainer {...props} />
      </Box>
    );
  },
  Menu: props => {
    const { children } = props;
    return (
      <components.Menu {...props}>
        <ThemeProvider2024>
          <Box>
            <MenuSearchHeader key="menu-search-header" {...props} autoFocus={false} />
            {children}
          </Box>
        </ThemeProvider2024>
      </components.Menu>
    );
  },
};

const SelectTasksTrigger = () => (
  <HStack color="gray.600" gap={2} alignItems="center" w="full">
    <Button w="full">Select tasks to approve</Button>
  </HStack>
);

const AddNewTaskTrigger = () => (
  <HStack color="gray.600" gap={2} alignItems="center" w="full">
    <Button variant="secondary" w="full" h="32px" bgColor="white">
      <Icon icon="plus" size="4" />
      &nbsp;Add new
    </Button>
  </HStack>
);
