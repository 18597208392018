import * as React from 'react';
import {
  ChecklistRuleDefinitionOperandType,
  FormFieldCondition as FormFieldConditionModel,
  FormFieldOperandValue,
} from '@process-street/subgrade/conditional-logic';
import { HStack, Text } from 'components/design/next';
import { match } from 'ts-pattern';
import { usePrintRulesContext } from '../../../context';
import { WidgetIcon } from './widget-icon';
import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { FormFieldWidget, TaskTemplate } from '@process-street/subgrade/process';

interface FormFieldConditionProps {
  condition: FormFieldConditionModel;
  isTitle?: boolean;
}

export const FormFieldCondition: React.FC<FormFieldConditionProps> = ({ condition, isTitle = false }) => {
  const { widgetGroupIds, taskGroupIds, organizationMemberships } = usePrintRulesContext();
  const { formFieldWidgetGroupId, operandValue, operandType, operator } = condition;

  const widgetGroup = widgetGroupIds.get(formFieldWidgetGroupId ?? '');

  if (!widgetGroup) return null;
  const task = taskGroupIds.get((widgetGroup.widget?.header.taskTemplate as TaskTemplate).group.id);
  if (!task) return null;
  const returnValue = match(operandType)
    .with(ChecklistRuleDefinitionOperandType.String, () =>
      handleStringValue({
        value: operandValue.value,
        widget: widgetGroup.widget as FormFieldWidget,
        organizationMemberships,
      }),
    )
    .with(ChecklistRuleDefinitionOperandType.DateTime, () =>
      operandValue.value ? new Date(operandValue.value).toDateString() : null,
    )
    .otherwise(() => operandValue.value);
  const fontSize = isTitle ? 'lg' : 'md';
  return (
    <HStack spacing={2}>
      <Text as="span" fontWeight="bold" color="gray.400" fontSize={fontSize}>
        {task.taskOrder}
      </Text>
      <Text fontSize={fontSize}>{task.name ?? 'unnamed task'}</Text>
      <Text as="span" fontWeight="bold">
        &gt;
      </Text>

      <WidgetIcon iconName={widgetGroup.icon} size={isTitle ? '4' : '3'} />
      <Text fontSize={fontSize}>{widgetGroup.label}</Text>
      {!isTitle && (
        <>
          <Text as="span" fontWeight="bold">
            {getOperatorDisplayValue(operator)}
          </Text>
          {returnValue && <Text>&nbsp;{returnValue}</Text>}
        </>
      )}
    </HStack>
  );
};

const handleStringValue = ({
  value,
  widget,
  organizationMemberships,
}: {
  value: FormFieldOperandValue | null;
  widget: FormFieldWidget;
  organizationMemberships: Map<string, OrganizationMembershipWithUser>;
}) => {
  if (!value || !widget) {
    return null;
  }
  if (widget.fieldType === 'Members') {
    const om = organizationMemberships.get(value as string);
    return om?.user?.username;
  }
  return value as string;
};

export const getOperatorDisplayValue = (str: string): string => {
  const words = str.split(/(?=[A-Z])/);
  const sentence = words.join('  ');
  return sentence.toLocaleLowerCase();
};
