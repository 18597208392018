import { PermissionsStats } from 'components/permissions/models/permissions-stats';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { ALL_MEMBERS_GROUP_USERNAME } from '@process-street/subgrade/util/membership-utils';

const buildPermissionsStatsDescription = (permissionsStats: PermissionsStats): string => {
  const descriptionParts = [];

  if (permissionsStats.allMembersGroupsCount > 0 || permissionsStats.inheritedAllMembersGroupsCount > 0) {
    descriptionParts.push(ALL_MEMBERS_GROUP_USERNAME);
  } else {
    if (permissionsStats.membersCount > 0 || permissionsStats.inheritedMembersCount > 0) {
      const totalMembersCount = permissionsStats.membersCount + permissionsStats.inheritedMembersCount;
      descriptionParts.push(`${totalMembersCount} member${totalMembersCount > 1 ? 's' : ''}`);
    }

    if (permissionsStats.groupsCount > 0 || permissionsStats.inheritedGroupsCount) {
      const totalGroupsCount = permissionsStats.groupsCount + permissionsStats.inheritedGroupsCount;
      descriptionParts.push(`${totalGroupsCount} group${totalGroupsCount > 1 ? 's' : ''}`);
    }
  }

  if (permissionsStats.guestsCount > 0 || permissionsStats.inheritedGuestsCount) {
    const totalGuestsCount = permissionsStats.guestsCount + permissionsStats.inheritedGuestsCount;
    descriptionParts.push(`${totalGuestsCount} guest${totalGuestsCount > 1 ? 's' : ''}`);
  }

  return descriptionParts.join(', ');
};

export type PermissionIcon = Extract<IconName, 'building' | 'users' | 'id-badge' | 'globe'>;

export const ICON_LABELS: Record<PermissionIcon, string> = {
  'building': 'Shared with all members',
  'globe': 'Shared with anyone with the link',
  'users': 'Shared with group or members',
  'id-badge': 'Shared with guests',
};

const resolvePermissionsIcons = (permissionsStats: PermissionsStats): PermissionIcon[] => {
  const icons: PermissionIcon[] = [];

  if (permissionsStats.allMembersGroupsCount > 0 || permissionsStats.inheritedAllMembersGroupsCount > 0) {
    icons.push('building');
  } else {
    if (
      permissionsStats.membersCount > 0 ||
      permissionsStats.groupsCount > 0 ||
      permissionsStats.inheritedMembersCount > 0 ||
      permissionsStats.inheritedGroupsCount
    ) {
      icons.push('users');
    }
  }

  if (permissionsStats.templateId && permissionsStats.templateShared) {
    icons.push('globe');
  }

  return icons;
};

export const PermissionsStatsUtils = {
  resolvePermissionsIcons,
  buildPermissionsStatsDescription,
};
