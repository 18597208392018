import { ConditionalFilterAssigneeListOperand } from '@process-street/subgrade/dashboard';
import { MemberItem } from 'components/common/MemberOption';
import { currentUserMemberItem } from 'components/dashboard/models/saved-views';
import { GridHelper } from 'components/dashboard/services/grid-helper';
import { BlvdMembersMultiselect } from 'components/design/BlvdMembersMultiselect';
import { Box } from 'components/design/next';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { ReduxAppState } from 'reducers/types';
import { createSelector } from 'reselect';

interface PropsFromState {
  allMemberItems: MemberItem[];
}

export interface PureAssigneeListOperandEditorProps {
  operand: ConditionalFilterAssigneeListOperand;
  onChange: (operand: ConditionalFilterAssigneeListOperand) => void;
}

export interface AssigneeListOperandEditorProps extends PureAssigneeListOperandEditorProps, PropsFromState {}

export const PureAssigneeListOperandEditor: React.FunctionComponent<
  React.PropsWithChildren<AssigneeListOperandEditorProps>
> = ({ allMemberItems, operand, onChange }) => {
  const [selectedOmIds, setSelectedOmIds] = useState<string[]>(operand.value);
  useEffect(() => {
    setSelectedOmIds(operand.value);
  }, [operand.value]);

  const handleChange = (selectedItems: MemberItem[]) => {
    const omIds = selectedItems.map(item => item.organizationMembershipId);

    onChange({ ...operand, value: omIds });
  };

  const allItems = [currentUserMemberItem].concat(allMemberItems);

  return (
    <Box
      w="100"
      maxW={{
        md: '69',
        base: 'full',
      }}
    >
      <BlvdMembersMultiselect
        id="AssigneeListOperandMultiselect"
        items={allItems}
        selectedOmIds={selectedOmIds}
        onMenuClose={handleChange}
      />
    </Box>
  );
};

const mapStateToProps = createSelector(
  OrganizationMembershipSelector.getAllAssignableBySelectedOrganizationId,
  allMemberships => {
    const allMemberItems = GridHelper.convertOrganizationMembershipsWithUserToMemberItems(allMemberships);
    return { allMemberItems };
  },
);

export const AssigneeListOperandEditor = connect<
  PropsFromState,
  Record<string, unknown>,
  Record<string, unknown>,
  ReduxAppState
>(mapStateToProps)(PureAssigneeListOperandEditor);
