import * as React from 'react';
import omit from 'lodash/omit';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { useOnStateChangeSuccess } from 'hooks/use-on-state-change-success';
import { CreateTemplateSource } from 'services/template-service.interface';
import { useLeakyLocation, useNavigate } from '@process-street/adapters/navigation';
import { queryString } from '@process-street/subgrade/util';
import { Muid } from '@process-street/subgrade/core';

export type Result = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  modalTemplateId?: string;
  source: CreateTemplateSource;
  selectedFolderId?: string;
};

export function useNgStateModalControls(modalKey: AppModalName): Result {
  const location = useLeakyLocation();
  const navigate = useNavigate();

  const params = queryString.parse(location.search ?? '');

  const [isOpen, setIsOpen] = React.useState(params.modal === modalKey);

  useOnStateChangeSuccess(
    ({ toParams }) => {
      setIsOpen(toParams[AppModalQueryParam.Modal] === modalKey);
    },
    [modalKey],
  );

  const onOpen = React.useCallback(() => {
    setIsOpen(true);
    const openStateParams = { ...params, [AppModalQueryParam.Modal]: modalKey };
    navigate({ pathname: location.pathname, search: queryString.stringify(openStateParams) });
  }, [modalKey, navigate, params, location.pathname]);

  const onClose = React.useCallback(() => {
    setIsOpen(false);
    const closeStateParams = omit(params, ...Object.values(AppModalQueryParam));
    navigate({ pathname: location.pathname, search: queryString.stringify(closeStateParams) });
  }, [location.pathname, navigate, params]);

  return {
    isOpen,
    onClose,
    onOpen,
    source: params.source as CreateTemplateSource,
    selectedFolderId: params.selectedFolderId as Muid,
  };
}
