import { Identifiable } from '@process-street/subgrade/core';

export function referencesNormalizer<T extends Identifiable>(references: string[]) {
  return (obj: T): T => {
    const normObj = { ...obj };
    const { audit } = obj;
    if (audit) {
      normObj.audit = {
        createdBy: { id: audit.createdBy.id },
        createdDate: audit.createdDate,
        updatedBy: { id: audit.updatedBy.id },
        updatedDate: audit.updatedDate,
      };
    }
    references.forEach(ref => {
      // @ts-expect-error -- TODO
      if (normObj[ref] && normObj[ref].id) {
        // TODO
        // @ts-expect-error -- TODO
        normObj[ref] = { id: normObj[ref].id };
      } else {
        // TODO Add appropriate error notification
      }
    });
    return normObj;
  };
}
