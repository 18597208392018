import { Identifiable, Option } from '@process-street/subgrade/core';
import { Checklist, FormFieldValue, Task, TaskStats } from '@process-street/subgrade/process';
import { BaseReduxEntitiesState, EntityMap } from '@process-street/subgrade/redux/types';
import { AnyAction } from 'redux';
import { OptimisticAction, OptimisticEvent, OptimisticEventType, OptimisticMeta, OptimisticUpdate } from '../model';
import { reduceOptimisticEventsByEntityId } from './merge-optimistic-events';

export function applyOptimisticEvents<T extends Identifiable>(
  state: EntityMap<T>,
  events?: Array<OptimisticEvent<T>>,
): EntityMap<T> {
  if (events === undefined || events.length === 0) {
    return state;
  }

  return reduceOptimisticEventsByEntityId(events).reduce(
    (nextState: EntityMap<T>, event: OptimisticEvent<T>) => {
      switch (event.eventType) {
        case OptimisticEventType.Create:
          nextState[event.entity.id] = event.entity;
          break;
        case OptimisticEventType.Update:
          const entity = nextState[event.entity.id];
          if (entity) {
            nextState[event.entity.id] = { ...entity, ...event.entity };
          }
          break;
        case OptimisticEventType.Delete:
          delete nextState[event.entity.id];
          break;
      }
      return nextState;
    },
    { ...state },
  );
}

export function applyOptimisticUpdate<T extends BaseReduxEntitiesState>(
  state: T,
  update: Partial<OptimisticUpdate>,
): T {
  return {
    ...state,
    approval: applyOptimisticEvents(state.approval, update.approvalEvents),
    attachment: applyOptimisticEvents(state.attachment, update.attachmentEvents),
    checklistAssignments: applyOptimisticEvents(state.checklistAssignments, update.checklistAssignmentEvents),
    checklistTaskAssignments: applyOptimisticEvents(state.checklistTaskAssignments, update.taskAssignmentEvents),
    checklists: applyOptimisticEvents(state.checklists, update.checklistEvents),
    comment: applyOptimisticEvents(state.comment, update.commentEvents),
    formFieldValue: applyOptimisticEvents(state.formFieldValue, update.formFieldValueEvents),
    organizationMemberships: applyOptimisticEvents(state.organizationMemberships, update.organizationMembershipEvents),
    task: applyOptimisticEvents(state.task, update.taskEvents),
    taskStats: applyOptimisticEvents(state.taskStats, update.taskStatEvents),
    users: applyOptimisticEvents(state.users, update.userEvents),
  };
}

export function findEventByType<T extends Identifiable>(
  eventType: OptimisticEventType,
  events: Array<OptimisticEvent<T>>,
): Option<T> {
  const createdFormFieldValueEvent = events.find(event => event.eventType === eventType);
  return createdFormFieldValueEvent ? (createdFormFieldValueEvent.entity as T) : undefined;
}

export function findFormFieldValueByEventType(
  eventType: OptimisticEventType,
  events: Partial<OptimisticUpdate>,
): Option<FormFieldValue> {
  if (!events.formFieldValueEvents) {
    return undefined;
  }
  return findEventByType<FormFieldValue>(eventType, events.formFieldValueEvents);
}

export function findChecklistByEventType(
  eventType: OptimisticEventType,
  events: Partial<OptimisticUpdate>,
): Option<Checklist> {
  if (!events.checklistEvents) {
    return undefined;
  }
  return findEventByType(eventType, events.checklistEvents);
}

export function findTaskByEventType(eventType: OptimisticEventType, events: Partial<OptimisticUpdate>): Option<Task> {
  if (!events.taskEvents) {
    return undefined;
  }
  return findEventByType(eventType, events.taskEvents);
}

export function findTaskStatsByEventType(
  eventType: OptimisticEventType,
  events: Partial<OptimisticUpdate>,
): Option<TaskStats> {
  if (!events.taskStatEvents) {
    return undefined;
  }
  return findEventByType(eventType, events.taskStatEvents);
}

export function isOptimisticAction<P, M extends OptimisticMeta>(action: AnyAction): action is OptimisticAction<P, M> {
  return action.meta && action.meta.optimistic;
}
