import angular from 'angular';
import { connectService } from 'reducers/util';
import { HttpStatus } from '@process-street/subgrade/util';
import { queryClient } from 'components/react-root';
import { OrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { EventName } from 'services/event-name';

angular
  .module('frontStreetApp.services')
  .service(
    'InvitationService',
    function (
      $ngRedux,
      $q,
      $rootScope,
      InvitationActions,
      MessageBox,
      ResponseUtilsService,
      SessionService,
      ToastService,
    ) {
      connectService('InvitationService', $ngRedux, null, InvitationActions)(this);

      const self = this;

      /** returns InvitationWithOrganizationMembershipInfo */
      self.getById = id => this.actions.getById(id).then(({ payload }) => payload);

      self.resend = function (user) {
        const selectedOrganizationId = SessionService.getSelectedOrganizationId();
        return this.actions.resend(user.id, selectedOrganizationId).then(({ payload }) => payload);
      };

      self.invite = function (email, resource, options) {
        const deferred = $q.defer();

        const opts = angular.extend({}, options);

        this.actions.create(email, resource, opts).then(
          ({ payload: response }) => {
            queryClient.invalidateQueries(OrganizationMembershipsQuery.key);
            const membership = response.organizationMembership;
            membership.user.avatarUrl = opts.avatarUrl;

            deferred.resolve(response);
          },
          response => {
            const isUserDeactivated = response.status === HttpStatus.BAD_REQUEST && response.data.includes('inactive');

            if (ResponseUtilsService.isUsersLimitReached(response)) {
              const message = ResponseUtilsService.getUsersLimitReachedMessage(response);
              ToastService.openToast({
                status: 'warning',
                title: `We couldn't invite that user.`,
                description: message,
              });
              deferred.reject(response);
            } else if (isUserDeactivated) {
              const message = `User ${email} has been deactivated.`;
              ToastService.openToast({
                status: 'warning',
                title: `We couldn't invite that user.`,
                description: message,
              });
              deferred.reject(response);
            } else {
              deferred.reject(response);
            }

            $rootScope.$broadcast(EventName.ORGANIZATION_MEMBERSHIP_CREATE_FAILED);
          },
        );

        return deferred.promise;
      };
    },
  );
