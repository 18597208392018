import * as React from 'react';
import { DisclosureContext } from 'features/template/components/template-menu/template-menu';
import { useDisclosure } from 'components/design/next';

type Props = {
  setCloseOnBlur: (v: boolean) => void;
};

export const useTemplateMenuDisclosureContextValue = ({ setCloseOnBlur }: Props) => {
  const tagsModalDisclosure = useDisclosure({
    onOpen: () => {
      setCloseOnBlur(false);
    },
    onClose: () => {
      setCloseOnBlur(true);
    },
  });

  const moveTemplateModalDisclosure = useDisclosure({
    id: 'move',
    onOpen: () => {
      setCloseOnBlur(false);
    },
    onClose: () => {
      setCloseOnBlur(true);
    },
  });

  const discardTemplateAlertDisclosure = useDisclosure({
    id: 'discardTemplate',
    onOpen: () => {
      setCloseOnBlur(false);
    },
    onClose: () => {
      setCloseOnBlur(true);
    },
  });

  const duplicateTemplateModalDisclosure = useDisclosure({
    id: 'duplicate',
    onOpen: () => {
      setCloseOnBlur(false);
    },
    onClose: () => {
      setCloseOnBlur(true);
    },
  });

  const archiveTemplateAlertDisclosure = useDisclosure({
    id: 'archive',
    onOpen: () => {
      setCloseOnBlur(false);
    },
    onClose: () => {
      setCloseOnBlur(true);
    },
  });

  const deleteTemplateAlertDisclosure = useDisclosure({
    id: 'delete',
    onOpen: () => {
      setCloseOnBlur(false);
    },
    onClose: () => {
      setCloseOnBlur(true);
    },
  });

  const addToPublicModalDisclosure = useDisclosure({ id: 'publicize' });
  const publicShareModalDisclosure = useDisclosure({ id: 'public share' });

  return React.useMemo<DisclosureContext>(
    () => ({
      addToPublicModalDisclosure,
      archiveTemplateAlertDisclosure,
      deleteTemplateAlertDisclosure,
      discardTemplateAlertDisclosure,
      duplicateTemplateModalDisclosure,
      moveTemplateModalDisclosure,
      publicShareModalDisclosure,
      tagsModalDisclosure,
    }),
    [
      addToPublicModalDisclosure,
      archiveTemplateAlertDisclosure,
      deleteTemplateAlertDisclosure,
      discardTemplateAlertDisclosure,
      duplicateTemplateModalDisclosure,
      moveTemplateModalDisclosure,
      publicShareModalDisclosure,
      tagsModalDisclosure,
    ],
  );
};
