import * as React from 'react';
import { Group, GroupMembership, GroupType, Muid, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  HStack,
  Text,
  VStack,
} from 'components/design/next';
import { Avatar, AvatarSize } from 'components/common/Avatar';
import { UserUtils } from '@process-street/subgrade/util';
import pluralize from 'pluralize';
import { GroupMemberList } from 'components/permissions/group-member-list/component';
import { concatInDistinctArray } from '@process-street/subgrade/redux';

export type GroupRowProps = {
  organizationMembership: OrganizationMembershipWithUser;
  onChange: (checked: boolean, omIds: Muid[]) => void;
  groups: Group[];
  allMemberships: OrganizationMembershipWithUser[];
  allGroupsMemberships: GroupMembership[];
  selectedUserIds: Muid[];
};

export const GroupRow: React.FC<React.PropsWithChildren<GroupRowProps>> = ({
  onChange,
  organizationMembership,
  groups,
  allMemberships,
  allGroupsMemberships,
  selectedUserIds,
}) => {
  const allMembersGroup = groups.find(g => g.groupType === GroupType.AllMembers);
  const allFreeMembersGroup = groups.find(g => g.groupType === GroupType.AllFreeMembers);

  const group = groups.find(g => g.user.id === organizationMembership.user.id);
  if (!group || !allMembersGroup || !allFreeMembersGroup) {
    return null;
  }

  const groupIds = group.id === allMembersGroup.id ? [allFreeMembersGroup.id, group.id] : [group.id];

  const groupMembershipIds = allGroupsMemberships
    .filter(gm => groupIds.includes(gm.group.id))
    .map(gm => gm.organizationMembership.id);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    onChange(e.target.checked, groupMembershipIds);
  };

  const groupMembers = allMemberships.filter(om => groupMembershipIds.includes(om.id)).map(gm => gm.user);
  const groupMemberIds = groupMembers.map(u => u.id);

  const areAllMembersSelected =
    concatInDistinctArray(groupMemberIds, selectedUserIds).length === selectedUserIds.length;
  const { user } = organizationMembership;
  const membersCount = groupMembers.length;
  if (areAllMembersSelected || membersCount === 0) return null;

  return (
    <Checkbox
      onChange={handleChange}
      width="full"
      padding={1}
      _hover={{
        backgroundColor: 'brand.100',
      }}
    >
      <HStack alignItems="flex-start">
        <Avatar size={AvatarSize.Normal} user={user} />
        <VStack alignItems="flex-start">
          <Text noOfLines={1} variant="-1">
            {UserUtils.getLabel(user, false)}
          </Text>
          <Accordion w="full" allowToggle>
            <AccordionItem>
              <AccordionButton p={0} _hover={{ backgroundColor: 'transparent' }} _focus={{ boxShadow: 'none' }}>
                <Text fontSize="xs" color="brand.500" mr={1}>
                  Show {membersCount} {pluralize('member', membersCount)}
                </Text>
                <AccordionIcon fontSize="xs" color="brand.500" />
              </AccordionButton>

              <AccordionPanel pb={0} pl={0}>
                <Box pb={2}>
                  <GroupMemberList members={groupMembers} limit={20} showEmail={false} />
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </VStack>
      </HStack>
    </Checkbox>
  );
};
