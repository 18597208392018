import angular from 'angular';
import { DaoCacheConstants } from 'app/dao/dao-cache-constants';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';

const ALL_BY_TEMPLATE_REVISION_ID = '*trid';

export class DueDateRuleDefinitionDao {
  constructor($q, $rootScope, DaoCacheService, DueDateRuleDefinitionApi) {
    'ngInject';

    this.logger = trace({ name: 'DueDateRuleDefinitionDao' });
    this.cache = DaoCacheService.getDaoCache();

    this.$q = $q;
    this.promiseMap = {};
    this.DueDateRuleDefinitionApi = DueDateRuleDefinitionApi;

    this.k = DaoCacheService.generateK(DaoCacheConstants.Namespace.DUE_DATE_RULE);

    $rootScope.$on(EventName.WIDGET_DELETE_OK, (__event, widget) => {
      // assume that we have all the object structure
      this.cache.remove(this.k(ALL_BY_TEMPLATE_REVISION_ID, widget.header.taskTemplate.templateRevision.id));
    });

    $rootScope.$on(EventName.TASK_TEMPLATE_BULK_DELETE_OK, (__event, taskTemplatesToDelete) => {
      // the list of task templates to delete should never be empty
      this.cache.remove(this.k(ALL_BY_TEMPLATE_REVISION_ID, taskTemplatesToDelete[0].templateRevision.id));
    });

    $rootScope.$on(EventName.TASK_TEMPLATE_BULK_MOVE_OK, (__event, taskTemplatesToUpdate) => {
      // the list of task templates to update should never be empty
      this.cache.remove(this.k(ALL_BY_TEMPLATE_REVISION_ID, taskTemplatesToUpdate[0].templateRevision.id));
    });
  }

  /**
   * Gets a list of all rules by template revision id
   *
   * @param templateRevisionId
   * @param flushCache
   * @return {Promise}
   */
  getAllByTemplateRevisionId(templateRevisionId, flushCache) {
    const cacheKey = this.k(ALL_BY_TEMPLATE_REVISION_ID, templateRevisionId);
    const rules = this.cache.get(cacheKey);
    const { promiseMap } = this;

    let promise;
    if (angular.isDefined(rules) && !flushCache) {
      promise = this.$q.resolve(rules);
    } else if (promiseMap[cacheKey]) {
      promise = promiseMap[cacheKey];
    } else {
      promise = this.DueDateRuleDefinitionApi.getAllByTemplateRevisionId({ templateRevisionId })
        .$promise.then(result => this.cache.put(cacheKey, result))
        .finally(() => {
          delete promiseMap[cacheKey];
        });
      promiseMap[cacheKey] = promise;
    }

    return promise;
  }

  createOrUpdateByTemplateRevisionId(templateRevisionId, rules) {
    return this.DueDateRuleDefinitionApi.createOrUpdateByTemplateRevisionId({
      templateRevisionId,
      definitions: rules,
    }).$promise.then(
      result => {
        this.cache.remove(this.k(ALL_BY_TEMPLATE_REVISION_ID, templateRevisionId));
        return result;
      },
      response => {
        const { message, messages } = response.data;

        let error;
        if (message) {
          error = message;
        } else {
          error = `Validation failed: ${messages}`;
        }

        this.logger.error('createOrUpdateByTemplateRevisionId failed. Message: %s', error);
        return this.$q.reject(error);
      },
    );
  }

  deleteAllByTemplateRevisionIdAndIds(templateRevisionId, ids) {
    return this.DueDateRuleDefinitionApi.deleteAllByTemplateRevisionIdAndIds({
      templateRevisionId,
      ids,
    }).$promise.then(result => {
      this.cache.remove(this.k(ALL_BY_TEMPLATE_REVISION_ID, templateRevisionId));
      return result;
    });
  }

  getAllFilteredByChecklistRevisionId(checklistRevisionId) {
    return this.DueDateRuleDefinitionApi.getAllFilteredByChecklistRevisionId({
      checklistRevisionId,
    }).$promise;
  }
}
