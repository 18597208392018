import { FormFieldValue, FormFieldWidget } from '@process-street/subgrade/process';

function getUpdatedFormFieldValues(
  formFieldValues: FormFieldValue[],
  newFormFieldValue: FormFieldValue | undefined,
): FormFieldValue[] {
  if (!newFormFieldValue) {
    return formFieldValues;
  }

  const formFieldWidgetId = newFormFieldValue.formFieldWidget.id;

  // We search via the widget id because the form field value id might not exist (for new values)
  const existingValueIndex = formFieldValues.findIndex(v => v.formFieldWidget.id === formFieldWidgetId);
  if (existingValueIndex !== -1) {
    return formFieldValues.map((ffv, index) => (index === existingValueIndex ? newFormFieldValue : ffv));
  } else {
    return formFieldValues.concat(newFormFieldValue);
  }
}

function getOriginalFormFieldValue(
  formFieldWidgets: FormFieldWidget[],
  formFieldValues: FormFieldValue[],
  formFieldValue: FormFieldValue,
): {
  originalFormFieldValue?: FormFieldValue;
  formFieldWidget?: FormFieldWidget;
  formFieldValueWithWidget?: FormFieldValue & { formFieldWidget: FormFieldWidget };
} {
  const formFieldWidget = formFieldWidgets.find(widget => widget.id === formFieldValue.formFieldWidget.id);

  if (!formFieldWidget) {
    return {};
  }

  const originalFormFieldValue = formFieldValues.find(v => v.formFieldWidget.id === formFieldWidget.id);
  const formFieldValueWithWidget = { ...formFieldValue, formFieldWidget };

  return { originalFormFieldValue, formFieldValueWithWidget, formFieldWidget };
}

export const TaskListHelper = {
  getOriginalFormFieldValue,
  getUpdatedFormFieldValues,
};
