import { PlaceholderField } from './placeholder';
import { EmailDomainFields } from './email-domains';
import { MinMaxFields, MinMaxTo, MinMaxLabel } from './min-max';
import { SnippetValueField } from './snippet-value/snippet-value';
import { MembersValueField } from './members/members-value-field';
import { DefaultValueField } from './default-value/default-value-field';
import { InlineDefaultValueField } from './default-value';
import { EmailSettingsFields } from './email-settings-fields/email-settings-fields';
import { TextFormatSelector } from './text-format-selector/text-format-selector';

export const SettingsModalFields = {
  DefaultValue: DefaultValueField,
  EmailDomains: EmailDomainFields,
  EmailSettings: EmailSettingsFields,
  InlineDefaultValue: InlineDefaultValueField,
  Members: MembersValueField,
  MinMaxLabel,
  MinMaxTo,
  MinMax: MinMaxFields,
  SnippetValue: SnippetValueField,
  Placeholder: PlaceholderField,
  TextFormatSelector,
};
