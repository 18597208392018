import angular from 'angular';
import { TemplateStatus } from '@process-street/subgrade/process';
import './future-checklist.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { trace } from 'components/trace';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'FutureChecklistCtrl',
    function (
      $q,
      $scope,
      $state,
      FutureChecklistService,
      FutureChecklists,
      MessageBox,
      SessionService,
      TemplateService,
      ToastService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'FutureChecklistCtrl' });
      logger.info('loading ctrl');

      const { Frequency } = FutureChecklistService;

      ctrl.$onInit = () => {
        $scope.info = $scope.info || {};

        const futureChecklistRequest = FutureChecklists.getById({ id: $state.params.id }).$promise;

        const selectedOrganizationId = SessionService.getSelectedOrganizationId();
        const templatesRequest = TemplateService.getAllByOrganizationIdAndStatusForSchedule(
          selectedOrganizationId,
          TemplateStatus.Active,
        );

        $q.all({
          futureChecklistResult: futureChecklistRequest,
          templates: templatesRequest,
        }).then(
          responses => {
            $scope.resourcesLoaded = true;

            $scope.futureChecklist = responses.futureChecklistResult.futureChecklist;
            $scope.futureChecklistJobs = responses.futureChecklistResult.jobs;

            $scope.templates = responses.templates;

            const template = $scope.templates.find(tmpl => tmpl.id === $scope.futureChecklist.template.id);

            const assignees = responses.futureChecklistResult.assignments.map(
              assignment => assignment.organizationMembership.user,
            );

            $scope.info = $scope.info || {};
            angular.extend($scope.info, {
              template,
              name: $scope.futureChecklist.name,
              assignees,
              frequency: Frequency.ONCE,
              timeZone: $scope.futureChecklist.timeZone,
              startDate: $scope.futureChecklist.startDate,
              duePeriod: $scope.futureChecklist.duePeriod,
              repeat: {},
            });

            $scope.futureChecklistJobs.forEach(job => {
              if (job.yearInterval) {
                $scope.info.frequency = Frequency.YEARLY;
                $scope.info.repeat.yearInterval = job.yearInterval;
              } else if (job.monthInterval) {
                $scope.info.frequency = Frequency.MONTHLY;
                $scope.info.repeat.monthInterval = job.monthInterval;
                $scope.info.repeat.monthBy = job.monthBy;
              } else if (job.weekInterval) {
                $scope.info.frequency = Frequency.WEEKLY;
                $scope.info.repeat.weekInterval = job.weekInterval;
                $scope.info.repeat.weekDays = $scope.info.repeat.weekDays || {};
                $scope.info.repeat.weekDays[job.weekDay] = true;
              } else if (job.dayInterval) {
                $scope.info.frequency = Frequency.DAILY;
                $scope.info.repeat.dayInterval = job.dayInterval;
              }
            });
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading the scheduled workflow`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
            FutureChecklistService.returnToWorkflow();
          },
        );
      };

      $scope.validate = function (info) {
        const duePeriod = info.duePeriod || FutureChecklistService.calculateDuePeriod(info.startDate, info.dueDate);
        return info.startDate && (!duePeriod || FutureChecklistService.isPositivePeriod(duePeriod));
      };

      $scope.updateFutureChecklist = function (futureChecklist, info) {
        if ($scope.updating) {
          return;
        }
        $scope.updating = true;

        // Only update start date if it has changed
        let startDate;
        if (info.startDate !== futureChecklist.startDate) {
          ({ startDate } = info);
        }

        // If there's no due period defined, then convert from the due date
        const duePeriod = info.duePeriod || FutureChecklistService.calculateDuePeriod(info.startDate, info.dueDate);

        const jobs = FutureChecklistService.generateJobs(info).map(job => {
          job.futureChecklistId = futureChecklist.id;
          return job;
        });

        FutureChecklists.update({
          id: futureChecklist.id,
          templateId: info.template.id,
          name: info.name || null,
          timeZone: info.timeZone,
          startDate,
          duePeriod: duePeriod || null, // This is null so it will clear it if it's been left blank
          jobs,
          emails: info.assignees.map(assignee => assignee.email),
        })
          .$promise.then(
            results => {
              AnalyticsService.trackEvent('scheduled checklist updated');

              ToastService.openToast({
                status: 'success',
                title: `Scheduled workflow updated`,
              });

              $scope.futureChecklistJobs = results.jobs;

              FutureChecklistService.returnToWorkflow();
            },
            () => {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems updating the scheduled workflow`,
                description: 'The workflow run has likely been updated, please refresh and try again.',
              });
            },
          )
          .finally(() => {
            $scope.updating = false;
          });
      };

      $scope.copyFutureChecklist = function (futureChecklist) {
        if ($scope.copying) {
          return;
        }
        $scope.copying = true;

        FutureChecklists.copy({
          id: futureChecklist.id,
        }).$promise.then(
          copiedFutureChecklist => {
            $state.go('futureChecklist', { id: copiedFutureChecklist.id });
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems copying the scheduled workflow`,
              description: 'The workflow run has likely been updated, please refresh and try again.',
            });

            $scope.copying = false;
          },
        );
      };

      let confirmDeleteFutureChecklistShown = false;
      $scope.confirmDeleteFutureChecklist = function (futureChecklist) {
        if (confirmDeleteFutureChecklistShown) {
          return;
        }
        confirmDeleteFutureChecklistShown = true;

        MessageBox.confirm({
          title: 'Delete this scheduled workflow?',
          message: 'The scheduled workflow will be deleted and irrecoverable!',
          okButton: {
            type: 'danger',
            text: 'Delete',
            action: deleteFutureChecklist.bind(null, futureChecklist),
          },
        }).result.finally(() => {
          confirmDeleteFutureChecklistShown = false;
        });
      };

      function deleteFutureChecklist(futureChecklist) {
        $scope.deleting = true;

        return FutureChecklists.delete({ id: futureChecklist.id }).$promise.then(
          response => {
            AnalyticsService.trackEvent('scheduled checklist deleted');

            ToastService.openToast({
              status: 'success',
              title: `Scheduled workflow deleted`,
            });

            FutureChecklistService.returnToWorkflow();

            return response;
          },
          response => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems deleting the schedule workflow`,
              description: 'The workflow run has likely been updated, please refresh and try again.',
            });

            $scope.deleting = false;

            return $q.reject(response);
          },
        );
      }

      let confirmCancelFutureChecklistShown = false;
      $scope.confirmCancelFutureChecklist = function (form) {
        if (form.$dirty) {
          if (confirmCancelFutureChecklistShown) {
            return;
          }
          confirmCancelFutureChecklistShown = true;
          MessageBox.confirm({
            title: 'Cancel scheduled workflow changes?',
            message: 'All edits to this scheduled workflow will be discarded and irrecoverable!',
            okButton: {
              type: 'danger',
              text: 'Ok',
              action: cancelFutureChecklist,
            },
          }).result.finally(() => {
            confirmCancelFutureChecklistShown = false;
          });
        } else {
          cancelFutureChecklist();
        }
      };

      function cancelFutureChecklist() {
        FutureChecklistService.returnToWorkflow();
      }
    },
  );
