import { AccessLevel } from '@process-street/subgrade/permission/permit-constants';
import { FolderPermit } from '@process-street/subgrade/permission/permission-model';
import { match } from 'ts-pattern';

export interface FolderPermitDelta {
  folderCreate: boolean;
  folderRead: boolean;
  folderUpdate: boolean;
  folderDelete: boolean;
  templateCreate: boolean;
  templateRead: boolean;
  templateUpdate: boolean;
  templateDelete: boolean;
  pageCreate: boolean;
  pageRead: boolean;
  pageUpdate: boolean;
  pageDelete: boolean;
  checklistCreate: boolean;
  checklistRead: boolean;
  checklistUpdate: boolean;
  checklistDelete: boolean;
  doodadCreate: boolean;
  doodadRead: boolean;
  doodadUpdate: boolean;
  doodadDelete: boolean;
}

const emptyFolderPermit: FolderPermitDelta = {
  folderCreate: false,
  folderRead: false,
  folderUpdate: false,
  folderDelete: false,
  templateCreate: false,
  templateRead: false,
  templateUpdate: false,
  templateDelete: false,
  pageCreate: false,
  pageRead: false,
  pageUpdate: false,
  pageDelete: false,
  checklistCreate: false,
  checklistRead: false,
  checklistUpdate: false,
  checklistDelete: false,
  doodadCreate: false,
  doodadRead: false,
  doodadUpdate: false,
  doodadDelete: false,
};

const folderEditPermissions = {
  ...emptyFolderPermit,
  folderCreate: true,
  folderRead: true,
  folderUpdate: true,
  folderDelete: true,
  templateCreate: true,
  templateRead: true,
  templateUpdate: true,
  templateDelete: true,
  pageCreate: true,
  pageRead: true,
  pageUpdate: true,
  pageDelete: true,
  checklistCreate: true,
  checklistRead: true,
  checklistUpdate: true,
  checklistDelete: true,
  doodadCreate: true,
  doodadRead: true,
  doodadUpdate: true,
};

const folderEditViewOwnPermissions = {
  ...emptyFolderPermit,
  folderCreate: true,
  folderRead: true,
  folderUpdate: true,
  folderDelete: true,
  templateCreate: true,
  templateRead: true,
  templateUpdate: true,
  templateDelete: true,
  pageCreate: true,
  pageRead: true,
  pageUpdate: true,
  pageDelete: true,
  checklistCreate: true,
};

const legacyFolderEditPermissions = {
  ...emptyFolderPermit,
  folderCreate: true,
  folderRead: true,
  folderUpdate: true,
  folderDelete: false,
  templateCreate: true,
  templateRead: true,
  templateUpdate: true,
  templateDelete: false,
  checklistCreate: true,
  checklistRead: true,
  checklistUpdate: true,
  checklistDelete: false,
  doodadCreate: true,
  doodadRead: true,
  doodadUpdate: true,
};

const folderViewAllPermissions = {
  ...emptyFolderPermit,
  folderRead: true,
  templateRead: true,
  pageRead: true,
  checklistCreate: true,
  checklistRead: true,
  checklistUpdate: true,
  doodadCreate: true,
  doodadRead: true,
};

const folderViewAllPermissionsLegacy = {
  ...emptyFolderPermit,
  folderRead: true,
  templateRead: true,
  checklistCreate: true,
  checklistRead: true,
  checklistUpdate: true,
  doodadCreate: true,
  doodadRead: true,
};

const folderRunPermissions = {
  ...emptyFolderPermit,
  folderRead: true,
  templateRead: true,
  pageRead: true,
  checklistCreate: true,
};

const folderRunPermissionsLegacy = {
  ...emptyFolderPermit,
  folderRead: true,
  templateRead: true,
  checklistCreate: true,
};

const folderViewPermissions = {
  ...emptyFolderPermit,
  folderRead: true,
  pageRead: true,
  templateRead: true,
};

const folderViewPermissionsLegacy = {
  ...emptyFolderPermit,
  folderRead: true,
  templateRead: true,
};

const folderEditFreeMemberPermissions = {
  ...emptyFolderPermit,
  folderCreate: true,
  folderRead: true,
  folderUpdate: true,
  templateRead: true,
  pageCreate: true,
  pageRead: true,
  pageUpdate: true,
  pageDelete: true,
};

const folderViewFreeMemberPermissions = {
  ...emptyFolderPermit,
  folderRead: true,
  templateRead: true,
  pageRead: true,
};

const folderWiewWithRunFreeMemberPermissions = {
  ...emptyFolderPermit,
  folderRead: true,
  templateRead: true,
  pageRead: true,
  checklistCreate: true,
};

const folderEditWithRunFreeMemberPermissions = {
  ...emptyFolderPermit,
  folderCreate: true,
  folderRead: true,
  folderUpdate: true,
  templateRead: true,
  pageCreate: true,
  pageUpdate: true,
  pageRead: true,
  pageDelete: true,
  checklistCreate: true,
};

export const folderPermitLookup: { [index in AccessLevel]?: FolderPermitDelta[] } = {
  [AccessLevel.FOLDER_EDIT]: [folderEditPermissions, legacyFolderEditPermissions],
  [AccessLevel.FOLDER_EDIT_VIEW_OWN]: [folderEditViewOwnPermissions],
  [AccessLevel.FOLDER_VIEW_ALL]: [folderViewAllPermissions, folderViewAllPermissionsLegacy],
  [AccessLevel.FOLDER_RUN]: [folderRunPermissions, folderRunPermissionsLegacy],
  [AccessLevel.FOLDER_VIEW]: [folderViewPermissions, folderViewPermissionsLegacy],
  [AccessLevel.FOLDER_EDIT_FREE_MEMBER]: [folderEditFreeMemberPermissions],
  [AccessLevel.FOLDER_VIEW_FREE_MEMBER]: [folderViewFreeMemberPermissions],
  [AccessLevel.FOLDER_VIEW_WITH_RUN_FREE_MEMBER]: [folderWiewWithRunFreeMemberPermissions],
  [AccessLevel.FOLDER_EDIT_WITH_RUN_FREE_MEMBER]: [folderEditWithRunFreeMemberPermissions],
};

const folderAccessLevels = [
  AccessLevel.FOLDER_EDIT,
  AccessLevel.FOLDER_EDIT_VIEW_OWN,
  AccessLevel.FOLDER_VIEW_ALL,
  AccessLevel.FOLDER_RUN,
  AccessLevel.FOLDER_VIEW,
];

const freeMemberFolderAccessLevels = [AccessLevel.FOLDER_EDIT_FREE_MEMBER, AccessLevel.FOLDER_VIEW_FREE_MEMBER];

const freeMemberWithRunFolderAccessLevels = [
  AccessLevel.FOLDER_VIEW_FREE_MEMBER,
  AccessLevel.FOLDER_EDIT_WITH_RUN_FREE_MEMBER,
  AccessLevel.FOLDER_VIEW_WITH_RUN_FREE_MEMBER,
];

const getKeys = (permissions: FolderPermitDelta) => Object.keys(permissions) as Array<keyof FolderPermitDelta>;

const hasFolderAccessLevel = (permit: FolderPermit, level: AccessLevel) => {
  const permissions = folderPermitLookup[level];

  const hasLevel =
    permissions && permissions.some(permission => getKeys(permission).every(key => permission[key] === permit[key]));

  return hasLevel;
};

export type FindFolderAccessLevelProps = {
  permit: FolderPermit;
  isFreeMember: boolean;
  isFreeMemberAndCanRun: boolean;
};
const findFolderAccessLevel = ({ permit, isFreeMember, isFreeMemberAndCanRun }: FindFolderAccessLevelProps) => {
  const pattern = { isFreeMemberAndCanRun, isFreeMember };
  const levels = match<typeof pattern, AccessLevel[]>(pattern)
    .with({ isFreeMemberAndCanRun: true }, () => freeMemberWithRunFolderAccessLevels)
    .with({ isFreeMember: true }, () => freeMemberFolderAccessLevels)
    .otherwise(() => folderAccessLevels);

  return levels.find(level => hasFolderAccessLevel(permit, level));
};

export const FolderPermitResolver = {
  hasFolderAccessLevel,
  findFolderAccessLevel,
  folderPermitLookup,
};
