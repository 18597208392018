import { ChecklistStatus, MultiSelectItemValueStatus, TaskStatus, WidgetType } from '@process-street/subgrade/process';
import angular from 'angular';
import { DateFormat, DateUtils, htmlEscaped } from '@process-street/subgrade/util';
import './print.scss';
import { ProgressService } from 'services/progress-service';

angular
  .module('frontStreetApp.services')
  .service(
    'PrintService',
    function ($filter, $q, ChecklistTaskAssignmentService, FeatureFlagService, UserService, util, WidgetService) {
      const self = this;

      this.FeatureFlagService = FeatureFlagService;

      function toHeader(widget) {
        return widget.header;
      }

      const widgetComparer = util.getOrderableComparer(toHeader);

      function sortWidgets(widgets) {
        widgets.sort(widgetComparer);
      }

      self.generateWidgetsMap = function (widgets) {
        const widgetsMap = {};
        const { timeZone } = UserService.getCurrentUser();

        widgets.forEach(widget => {
          if (!WidgetService.isEmpty(widget)) {
            if (widget.header.type === WidgetType.File && widget.file) {
              widget.file.formattedCreatedDate = WidgetService.formatDateToTz(widget.file.audit.createdDate, timeZone);
            }

            const groupId = widget.header.taskTemplate.group.id;
            widgetsMap[groupId] = widgetsMap[groupId] || [];
            widgetsMap[groupId].push(widget);
          }
        });

        angular.forEach(widgetsMap, sortWidgets);

        return widgetsMap;
      };

      self.generateFormFieldValueMap = function (formFieldValues, widgetsMap) {
        const formFieldValueMap = {};

        formFieldValues.forEach(formFieldValue => {
          formFieldValueMap[formFieldValue.formFieldWidget.header.id] = formFieldValue;
        });

        angular.forEach(widgetsMap, widgets => {
          widgets.forEach(widget => {
            if (widget.header.type === WidgetType.FormField) {
              if (angular.isUndefined(formFieldValueMap[widget.header.id])) {
                formFieldValueMap[widget.header.id] = {
                  fieldValue: {},
                  formFieldWidget: widget,
                };
              }
            }
          });
        });

        return formFieldValueMap;
      };

      self.generateDoodadsMap = function (comments, attachments, taskActivities, formFieldValueActivities) {
        const doodadsMap = {};

        siloDoodads(comments, doodadsMap);
        siloDoodads(attachments, doodadsMap);
        siloDoodads(taskActivities, doodadsMap);
        siloDoodads(formFieldValueActivities, doodadsMap);

        return doodadsMap;
      };

      self.generateTaskAssignmentsMap = function (taskAssignments) {
        const taskAssignmentsMap = {};

        const map = ChecklistTaskAssignmentService.toAssigneesMap(taskAssignments);

        angular.forEach(map, (assignments, taskId) => {
          let assigneesSeparatedByComma = '';
          const assignmentsLength = assignments.length;
          assignments.forEach((assignee, index) => {
            if (index === assignmentsLength - 2) {
              assigneesSeparatedByComma += `${assignee.username || assignee.email} and `;
            } else if (index === assignmentsLength - 1) {
              assigneesSeparatedByComma += assignee.username || assignee.email;
            } else {
              assigneesSeparatedByComma += `${assignee.username || assignee.email}, `;
            }
          });

          taskAssignmentsMap[taskId] = assigneesSeparatedByComma;
        });

        return taskAssignmentsMap;
      };

      self.generateChecklistStatusInfo = function (checklist, userMap) {
        let checklistStatusInfo;

        const updatedByUser = userMap[checklist.audit.updatedBy.id];
        const timeZone = updatedByUser?.timeZone || 'UTC';

        const formattedUpdatedDate = self.formatDate(checklist.audit.updatedDate, timeZone);

        const info = `<b>${self.resolveUsername(updatedByUser)}</b> ${formattedUpdatedDate}`;

        switch (checklist.status) {
          case ChecklistStatus.Archived:
            checklistStatusInfo = `Archived by ${info}`;
            break;
          case ChecklistStatus.Completed:
            checklistStatusInfo = `Completed by ${info}`;
            break;
          default:
            checklistStatusInfo = '';
        }

        return checklistStatusInfo;
      };

      self.generateChecklistCreatedInfo = function (checklist, userMap) {
        const createdByUser = userMap[checklist.audit.createdBy.id];

        const formattedCreatedDate = self.formatDate(checklist.audit.createdDate, createdByUser?.timeZone);

        return htmlEscaped`Created by <b>${self.resolveUsername(createdByUser)}</b> ${formattedCreatedDate}`;
      };

      /**
       * Generates the info for a task.
       *
       * @param {Object[]} tasks
       * @param {{}} userMap
       * @returns {{}}
       */
      self.generateTaskInfoMap = tasks => {
        const taskInfoMap = {};

        tasks.forEach(task => {
          if (task.status === TaskStatus.Completed) {
            const completedByUser = task.completedBy;
            const completedByUsername = self.resolveUsername(completedByUser);
            const formattedCompletedDate = self.formatDate(task.completedDate, completedByUser.timeZone);
            taskInfoMap[task.id] = `by <b>${completedByUsername}</b><br>${formattedCompletedDate}`;
          }
        });

        return taskInfoMap;
      };

      self.generateTaskProgressMap = function (formFieldValues) {
        const taskProgressMap = {};

        formFieldValues.forEach(formFieldValue => {
          const widget = formFieldValue.formFieldWidget;
          const groupId = widget.header.taskTemplate.group.id;

          taskProgressMap[groupId] = taskProgressMap[groupId] || {
            progress: 0,
            total: 0,
            completed: 0,
          };

          angular.forEach(widget.config.items, item => {
            if (item.name && item.name.trim()) {
              taskProgressMap[groupId].total += 1;
            }
          });

          angular.forEach(formFieldValue.fieldValue.itemValues, itemValue => {
            const completed = itemValue.status === MultiSelectItemValueStatus.Completed;
            taskProgressMap[groupId].completed += completed ? 1 : 0;
          });

          taskProgressMap[groupId].progress = ProgressService.calculate(
            taskProgressMap[groupId].completed,
            taskProgressMap[groupId].total,
          ).value;
        });

        return taskProgressMap;
      };

      /**
       * Gets a user from the user map if they exist, otherwise it will get it from the server.
       *
       * @param {{}} userMap
       * @param {String} id
       * @returns {Promise}
       */
      self.resolveUserById = function (userMap, id) {
        let request;

        if (userMap[id]) {
          request = $q.resolve(userMap[id]);
        } else {
          request = UserService.getById(id);
        }

        return request;
      };

      self.resolveUsername = function (user) {
        return user ? htmlEscaped`${user.username || user.email}` : 'Somebody';
      };

      function toStepId(task) {
        return task.taskTemplate.group.id;
      }

      function siloDoodads(doodads, doodadsMap) {
        doodads.forEach(doodad => {
          const stepId = toStepId(doodad.task);

          doodadsMap[stepId] = doodadsMap[stepId] || [];
          doodadsMap[stepId].push(doodad);
        });

        return doodads;
      }
      self.formatDate = function (date, timezone = 'UTC') {
        if (this.FeatureFlagService.getFeatureFlags().pdfEmbellishment) {
          return DateUtils.formatDateTime(date, DateFormat.DateTimeLongWeekdayShortMonthWithZone, timezone);
        } else {
          return $filter('date')(date, "MMM d 'at' h:mma", timezone);
        }
      };
    },
  );
