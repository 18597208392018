import * as React from 'react';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { isStandardUserOm } from '@process-street/subgrade/util/membership-utils';
import { mapUserTypeToSandboxRole, SandboxRole, User } from '@process-street/subgrade/core';
import { Box, useToast } from 'components/design/next';
import { BlvdSelect, GroupBase, OnChangeValue, SelectComponentsConfig } from 'components/design/BlvdSelect';
import { components } from 'react-select';
import { UserOption, UserOptionTransformer } from 'utils/user-option-transformer';

import { SANDBOX_PREVIEWER_PARAMS } from 'app/hooks/use-create-sandbox-and-redirect';
import { ChecklistRevision, TemplateRevision } from '@process-street/subgrade/process';
import {
  BLVD_CONTAINER_STYLES,
  BLVD_CONTROL_STYLES,
  getPreviewerType,
  PreviewerOption,
  SandboxUserType,
  UserTypeOption,
  UserTypeOptionCategory,
  UserTypeOptions,
} from './utils';
import { CustomDropdownIndicator } from './custom-dropdown-indicator';
import { PreviewAsOption } from './preview-as-option';
import { match } from 'ts-pattern';
import { useRoleOptions, useSetInitialSelection } from './hooks';
import { AssignmentType, RoleOption } from './role-option-transformer';
import { CreateSandboxUserMutation } from 'app/features/sandbox-user/query-builder';
import { CustomMenuContainer } from './custom-menu-container';
import { useInjector } from 'components/injection-provider';
import { EventName } from 'app/services/event-name';

export interface PreviewAsMenuProps {
  currentUser: User;
  checklistRevision: ChecklistRevision;
  templateRevision: TemplateRevision;
}

export const PreviewAsMenu: React.FC<React.PropsWithChildren<PreviewAsMenuProps>> = ({
  currentUser,
  checklistRevision,
  templateRevision,
}) => {
  const [selectedMember, setSelectedMember] = React.useState<PreviewerOption | null>(null);
  const organizationId = useSelectedOrganization()?.id;
  const { $rootScope } = useInjector('$rootScope');
  const { data: organizationMemberships } = useGetAllOrganizationMembershipsQuery(
    { organizationId },
    {
      enabled: Boolean(organizationId),
      select: oms => oms.filter(isStandardUserOm), // exclude groups from user listing
    },
  );
  const users = React.useMemo(() => organizationMemberships?.map(om => om.user), [organizationMemberships]);
  const toast = useToast();
  const handleSetSessionStorageAndReload = ({
    userId,
    userType,
    sandboxRole,
    assignmentType,
  }: {
    userId: string;
    userType: SandboxUserType;
    sandboxRole?: SandboxRole;
    assignmentType?: AssignmentType;
  }) => {
    sessionStorage.setItem(
      SANDBOX_PREVIEWER_PARAMS,
      JSON.stringify({
        userId,
        userType,
        sandboxRole,
        assignmentType,
      }),
    );
    $rootScope.$broadcast(EventName.SANDBOX_USER_CHANGE);
  };
  const userOptions = React.useMemo(() => UserOptionTransformer.transformUsersToOptions(users ?? []), [users]);
  const roleOptions = useRoleOptions({ templateRevision, checklistRevision });
  useSetInitialSelection({ users, currentUser, setSelectedMember, roleOptions });
  const createSandboxUserMutation = CreateSandboxUserMutation.useMutation({
    onSuccess: data => {
      handleSetSessionStorageAndReload({
        userId: data.user.id,
        userType: SandboxUserType.UserType,
        sandboxRole: mapUserTypeToSandboxRole(data.user.userType),
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: `We're having problems showing the sandbox for this user type.`,
      });
    },
  });
  const handleChange = (value: OnChangeValue<PreviewerOption, false>) => {
    if (!value) {
      return;
    }
    const valueType = getPreviewerType(value);
    const selectedMemberType = getPreviewerType(selectedMember as PreviewerOption);

    match({ value, selectedMember, valueType, selectedMemberType })
      .with({ valueType: SandboxUserType.User, selectedMemberType: SandboxUserType.User }, () => {
        if ((value as UserOption).user.id === (selectedMember as UserOption).user.id) {
          return;
        }
        handleSetPreviewAsUser((value as UserOption).user);
      })
      .with({ valueType: SandboxUserType.UserType, value: { category: UserTypeOptionCategory.WorkflowRunner } }, () => {
        handleSetPreviewAsUser(currentUser);
      })
      .with({ valueType: SandboxUserType.UserType }, () => {
        handleSetPreviewAsUserType(value as UserTypeOption);
      })
      .with({ valueType: SandboxUserType.UserType, selectedMemberType: SandboxUserType.UserType }, () => {
        if ((value as UserTypeOption).category === (selectedMember as UserTypeOption).category) {
          return;
        }
        handleSetPreviewAsUserType(value as UserTypeOption);
      })
      .with({ valueType: SandboxUserType.Role }, () => {
        handleSetPreviewAsRole(value as RoleOption);
      })
      .otherwise(() => {
        const userOption = value as UserOption;
        handleSetPreviewAsUser(userOption.user);
      });
  };

  const handleSetPreviewAsUser = (user: User) => {
    const userId = user.id;
    setSelectedMember(UserOptionTransformer.transformUserToOption(user));
    handleSetSessionStorageAndReload({ userId, userType: SandboxUserType.User });
  };

  const handleSetPreviewAsUserType = (userType: UserTypeOption) => {
    setSelectedMember(userType);
    createSandboxUserMutation.mutate({
      id: checklistRevision.checklist.id,
      sandboxRole: userType.sandboxRole as SandboxRole,
    });
  };

  const handleSetPreviewAsRole = (role: RoleOption) => {
    setSelectedMember(role);
    handleSetSessionStorageAndReload({
      userId: role.user.id,
      userType: SandboxUserType.Role,
      assignmentType: role.assignmentType,
    });
  };

  const groupedOptions = React.useMemo(() => {
    const groupedOptions: GroupBase<PreviewerOption>[] = [];
    groupedOptions.push({ options: UserTypeOptions });
    if (roleOptions.length) {
      groupedOptions.push({ label: 'Roles', options: roleOptions });
    }
    if (userOptions.length) {
      groupedOptions.push({ label: 'Users', options: userOptions });
    }
    return groupedOptions;
  }, [roleOptions, userOptions]);

  return (
    <Box maxWidth="600px" sx={BLVD_CONTAINER_STYLES}>
      <BlvdSelect
        components={COMPONENTS}
        options={groupedOptions}
        searchPlaceholder="Enter name or email"
        onChange={handleChange}
        value={selectedMember}
        isSearchable
        menuControls={false}
        closeMenuOnSelect={true}
        menuPortalTarget={document.body}
        styles={BLVD_CONTROL_STYLES}
        getOptionValue={option => {
          const valueType = getPreviewerType(option);
          return match(valueType)
            .with(SandboxUserType.User, () => (option as UserOption).user.id)
            .with(SandboxUserType.UserType, () => (option as UserTypeOption).category)
            .with(SandboxUserType.Role, () => (option as RoleOption).formFieldWidgetId)
            .otherwise(() => '');
        }}
      />
    </Box>
  );
};

const COMPONENTS: SelectComponentsConfig<PreviewerOption, false, GroupBase<PreviewerOption>> = {
  Option: PreviewAsOption,
  DropdownIndicator: CustomDropdownIndicator,
  Menu: CustomMenuContainer,
  // Prevents arbitrary label appearing before dropdown
  ValueContainer: props => {
    return (
      <Box w="0" overflow="hidden" h="0">
        <components.ValueContainer {...props} />
      </Box>
    );
  },
};
