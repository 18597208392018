import * as React from 'react';
import { BarStackHorizontal } from '@visx/shape';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Box, useToken, useBreakpointValue } from 'components/design/next';
import { ParentSize } from '@visx/responsive';
import { ParentSizeRenderProps } from '../common/types';
import { getRandomInt } from '@process-street/subgrade/core';
import { NotEnoughData } from '../common/not-enough-data';
import { useChartBoxContext } from '../chart-box/context';
import { useEffectOnce } from 'react-use';

export type EmptyBarStackHorizontalChartProps = { data: Stat[]; parentSize: ParentSizeRenderProps };

const KEYS = ['a', 'b', 'c', 'd'] as const;
const COLORS = ['gray.100', 'gray.200', 'gray.50', 'gray.300'];
type Status = typeof KEYS[number];
type Stat = { id: string } & Record<Status, number>;

const MX_BASE = 48;
const MX_MD = 120;

export const EmptyBarStackHorizontalChart: React.VFC<EmptyBarStackHorizontalChartProps> = ({
  parentSize: { width: parentWidth, height: parentHeight },
  data,
}) => {
  const margin = useBreakpointValue({
    base: { left: MX_BASE, right: MX_BASE, top: 48 },
    md: { left: MX_MD, right: MX_MD, top: 48 },
  }) ?? { left: MX_MD, right: MX_MD, top: 48 };

  const [labelHeight, setLabelHeight] = React.useState(0);

  // bounds
  const xMax = parentWidth - margin.left - margin.right;
  const yMax = parentHeight - labelHeight - margin.top;

  const totals = data.map(stat => {
    return KEYS.reduce((acc, status) => {
      acc += Number(stat[status]);
      return acc;
    }, 0);
  });

  // scales
  const taskStatusScale = scaleLinear<number>({
    domain: [0, Math.max(...totals)],
    nice: true,
  }).rangeRound([0, xMax]);

  const taskNameScale = scaleBand<string>({
    domain: data.map(d => d.id),
    paddingInner: 0.5,
  }).rangeRound([yMax, 0]);

  const colorScale = scaleOrdinal<Status, string>({
    domain: [...KEYS],
    range: useToken('colors', COLORS),
  });
  const [gray500] = useToken('colors', ['gray.100']);

  return parentWidth < 10 ? null : (
    <Box
      {...{
        'position': 'relative',
        'aria-label': 'empty chart',
      }}
    >
      <Box height={parentHeight - labelHeight} as="svg" width={parentWidth} display="block">
        <Group left={margin.left} top={margin.top}>
          <BarStackHorizontal<Stat, Status>
            data={data}
            keys={[...KEYS]}
            height={yMax}
            y={x => x.id}
            xScale={taskStatusScale}
            yScale={taskNameScale}
            color={colorScale}
          >
            {barStacks =>
              barStacks.map(barStack =>
                barStack.bars.map(bar => (
                  <rect
                    key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                    x={bar.x}
                    y={bar.y}
                    width={bar.width}
                    height={bar.height}
                    fill={bar.color}
                  />
                )),
              )
            }
          </BarStackHorizontal>
          <AxisLeft
            hideTicks
            hideZero
            numTicks={0}
            scale={taskNameScale}
            stroke={gray500}
            tickStroke={gray500}
            strokeWidth={2}
          />
          <AxisBottom
            top={yMax}
            scale={taskStatusScale}
            stroke={gray500}
            tickStroke={gray500}
            strokeWidth={2}
            hideTicks
            numTicks={0}
          />
        </Group>
      </Box>

      <NotEnoughData
        ref={node => {
          setLabelHeight(node?.offsetHeight ?? 0);
        }}
      />
    </Box>
  );
};

export type EmptyBarStackHorizontalProps = { rowCount?: number };
export const EmptyBarStackHorizontal: React.VFC<EmptyBarStackHorizontalProps> = ({ rowCount = 15 }) => {
  const { setIsEmpty } = useChartBoxContext();
  useEffectOnce(() => {
    setIsEmpty.on();
    return () => {
      setIsEmpty.off();
    };
  });

  const data: Stat[] = Array.from({ length: rowCount }, (_, i) => ({
    id: String(i),
    ...KEYS.reduce((acc, k) => {
      acc[k] = getRandomInt({ min: 0, max: 100 });
      return acc;
    }, {} as Record<Status, number>),
  }));
  return <ParentSize>{parentSize => <EmptyBarStackHorizontalChart data={data} parentSize={parentSize} />}</ParentSize>;
};
