import { Muid, Option, OrganizationMembership, User, UserType } from '@process-street/subgrade/core';
import {
  isOrganizationMembershipActive,
  Organization,
  OrganizationMembershipWithUser,
} from '@process-street/subgrade/core/organization-model';
import { BaseOrganizationMembershipSelector, BaseSessionSelector } from '@process-street/subgrade/redux/selector';
import { EntityMap, LookupMap } from '@process-street/subgrade/redux/types';
import { Entities } from 'reducers/entities/entities';
import { getIsLoadedSelector } from 'reducers/statuses/statuses-utils';
import { ReduxAppState } from 'reducers/types';
import { UserSelector } from 'reducers/user/user.selectors';
import { createSelector, Selector } from 'reselect';
import { OrganizationMembershipUtils } from '@process-street/subgrade/core/organization-membership-utils';

const getEntityMap = (state: ReduxAppState): EntityMap<OrganizationMembership> =>
  state.entities.organizationMemberships;

const getById =
  (organizationMembershipId: Muid) =>
  (state: ReduxAppState): Option<OrganizationMembership> =>
    getEntityMap(state)[organizationMembershipId];

const _getAllByUserIdMap = (state: ReduxAppState): LookupMap => state.organizationMembership.byUserId;

const getAllByUserId =
  (userId: Muid): Selector<ReduxAppState, OrganizationMembership[]> =>
  (state: ReduxAppState): OrganizationMembership[] => {
    const entityMap = getEntityMap(state);
    const organizationMemberships = _getAllByUserIdMap(state)[userId] || [];
    return organizationMemberships.map(id => entityMap[id]);
  };

const getByOrganizationIdAndCurrentUserId =
  (orgId: Muid): Selector<ReduxAppState, Option<OrganizationMembership>> =>
  (state: ReduxAppState) => {
    const userId = BaseSessionSelector.getCurrentUserId(state);
    if (userId) {
      return getByOrganizationIdAndUserId(orgId, userId)(state);
    }
    return undefined;
  };

const getWithUserBySelectedOrganizationIdAndUserId = (userId: Muid) =>
  createSelector(
    [BaseSessionSelector.getSelectedOrganizationId, UserSelector.getById(userId), getAllByUserId(userId)],
    (selectedOrganizationId, user, userOrganizationMemberships) => {
      const organizationMembership = userOrganizationMemberships.find(
        om => om.organization.id === selectedOrganizationId,
      );

      if (user && organizationMembership) {
        return { ...organizationMembership, user };
      }
      return undefined;
    },
  );

const getBySelectedOrganizationIdAndUserEmail =
  (email: string) =>
  (state: ReduxAppState): Option<OrganizationMembership> => {
    const user = UserSelector.getByPrimaryEmail(email)(state);
    if (user) {
      const selectedOrganizationId = BaseSessionSelector.getSelectedOrganizationId(state);
      const membership = Object.values(getEntityMap(state)).find(
        om => om.organization.id === selectedOrganizationId && om.user.id === user.id,
      );
      return membership;
    }
    return undefined;
  };

const getBySelectedOrganizationIdAndCurrentUserId: Selector<
  ReduxAppState,
  Option<OrganizationMembership>
> = createSelector(
  [BaseSessionSelector.getCurrentUserId, BaseSessionSelector.getSelectedOrganizationId, getEntityMap],
  (userId, selectedOrganizationId, entityMap) => {
    if (userId && selectedOrganizationId) {
      return Object.values(entityMap).find(
        om => om.organization.id === selectedOrganizationId && om.user.id === userId,
      );
    }

    return undefined;
  },
);

const getAllWithUserByOrganizationId =
  (organizationId: Muid): Selector<ReduxAppState, OrganizationMembershipWithUser[]> =>
  (state: ReduxAppState): OrganizationMembershipWithUser[] => {
    const entityMap = getEntityMap(state);
    return Object.values(entityMap).reduce((results: OrganizationMembershipWithUser[], om) => {
      const user = UserSelector.getById(om.user.id)(state);
      if (om.organization.id === organizationId && user) {
        results.push({ ...om, user });
      }
      return results;
    }, []);
  };

const getAllStandardWithUserByOrganizationId =
  (organizationId: Muid): Selector<ReduxAppState, OrganizationMembershipWithUser[]> =>
  (state: ReduxAppState): OrganizationMembershipWithUser[] => {
    const entityMap = getEntityMap(state);
    return Object.values(entityMap).reduce((results: OrganizationMembershipWithUser[], om) => {
      const user = UserSelector.getById(om.user.id)(state);
      if (om.organization.id === organizationId && user && user.userType === UserType.Standard) {
        results.push({ ...om, user });
      }
      return results;
    }, []);
  };

const getAllWithUserBySelectedOrganizationId: Selector<ReduxAppState, OrganizationMembershipWithUser[]> =
  createSelector(
    [BaseSessionSelector.getSelectedOrganizationId, getEntityMap, UserSelector.getEntityMap],
    (selectedOrganizationId, organizationMembershipMap, userMap) =>
      Object.values(organizationMembershipMap).reduce((results: OrganizationMembershipWithUser[], om) => {
        const user = userMap[om.user.id];
        if (om.organization.id === selectedOrganizationId && user) {
          results.push({ ...om, user });
        }
        return results;
      }, []),
  );

const getAllAssignableBySelectedOrganizationId: Selector<ReduxAppState, OrganizationMembershipWithUser[]> =
  createSelector(getAllWithUserBySelectedOrganizationId, memberships =>
    memberships.filter(OrganizationMembershipUtils.isAssignable),
  );

const getAllByOrganizationId =
  (organizationId: Muid): Selector<ReduxAppState, OrganizationMembership[]> =>
  (state: ReduxAppState): OrganizationMembership[] => {
    const entityMap = getEntityMap(state);
    return Object.values(entityMap).filter(om => om.organization.id === organizationId);
  };

const getAllByOrganizationIdMapByUserId =
  (organizationId: Muid): Selector<ReduxAppState, Record<User['id'], OrganizationMembership>> =>
  (state: ReduxAppState): Record<User['id'], OrganizationMembership> => {
    return Object.fromEntries(getAllByOrganizationId(organizationId)(state).map(om => [om.user.id, om]));
  };

export const getAllByOrganizationIdMapByUserIdMemoized = (organizationId: Muid) =>
  createSelector(
    [(state: ReduxAppState) => getAllByOrganizationId(organizationId)(state)],
    (orgMemberships: OrganizationMembership[]) => Object.fromEntries(orgMemberships.map(om => [om.user.id, om])),
  );

const getMapByUserIdForSelectedOrganization = createSelector<
  ReduxAppState,
  OrganizationMembership[],
  Option<Organization['id']>,
  Record<User['id'], OrganizationMembership>
>(
  state => Object.values(getEntityMap(state)),
  BaseSessionSelector.getSelectedOrganizationId,
  (memberships, organizationId) => {
    const result = Object.fromEntries(
      memberships.filter(om => om.organization.id === organizationId).map(om => [om.user.id, om]),
    );

    return result;
  },
);

const getAllActiveByOrganizationId =
  (organizationId: Muid): Selector<ReduxAppState, OrganizationMembership[]> =>
  (state: ReduxAppState): OrganizationMembership[] => {
    const entityMap = getEntityMap(state);
    return Object.values(entityMap).filter(
      om => om.organization.id === organizationId && isOrganizationMembershipActive(om),
    );
  };

const getByOrganizationIdAndUserId =
  (organizationId: Muid, userId: Muid): Selector<ReduxAppState, Option<OrganizationMembership>> =>
  (state: ReduxAppState): Option<OrganizationMembership> => {
    const organizationMemberships = getAllByUserId(userId)(state);
    return organizationMemberships.find(om => om.organization.id === organizationId);
  };

const isLoadedByOrganizationId = (organizationId: Muid): Selector<ReduxAppState, boolean> =>
  getIsLoadedSelector(Entities.ORGANIZATION_MEMBERSHIP, 'byOrganizationId', organizationId);

const isLoadedByUserId = (userId: Muid): Selector<ReduxAppState, boolean> =>
  getIsLoadedSelector(Entities.ORGANIZATION_MEMBERSHIP, 'byUserId', userId);

export const OrganizationMembershipSelector = {
  getAllAssignableBySelectedOrganizationId,
  getAllByOrganizationId,
  getAllActiveByOrganizationId,
  getAllByOrganizationIdMapByUserId,
  getAllByOrganizationIdMapByUserIdMemoized,
  getAllByUserId,
  getAllUsersByOrganizationMembershipIds: BaseOrganizationMembershipSelector.getAllUsersByOrganizationMembershipIds,
  getAllStandardWithUserByOrganizationId,
  getAllWithUserByOrganizationId,
  getAllWithUserBySelectedOrganizationId,
  getById,
  getByOrganizationIdAndCurrentUserId,
  getByOrganizationIdAndUserId,
  getBySelectedOrganizationIdAndCurrentUserId,
  getBySelectedOrganizationIdAndUserEmail,
  getEntityMap,
  getWithUserById: BaseOrganizationMembershipSelector.getWithUserById,
  getWithUserBySelectedOrganizationIdAndUserId,
  isLoadedByOrganizationId,
  isLoadedByUserId,
  getMapByUserIdForSelectedOrganization,
};
