import * as React from 'react';
import {
  HStack,
  Icon,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
  TooltipProps,
  useBoolean,
  useToast,
  VStack,
} from 'components/design/next';
import { TemplateRevision } from '@process-street/subgrade/process';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import { useUpdateShareableLinkMutation } from 'features/template/query-builder/update-shareable-link';
import { TemplatePermissionsStatsQueryService } from 'components/permissions/services/template-permissions-stats-query-service';
import { HttpStatus } from '@process-street/subgrade/util';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useGetCanShareLinks } from 'utils/plans/get-can-share-links';
import { useQueryClient } from 'react-query';
import { GetTemplateQuery, useGetTemplateQuery } from 'features/template/query-builder';

export const PermissionsSwitch: React.FC<React.PropsWithChildren<{ templateRevision: TemplateRevision }>> = ({
  templateRevision,
}) => {
  const toast = useToast();
  const [shared, setShared] = useBoolean();

  const canShareLinks = useGetCanShareLinks();

  const templateQuery = useGetTemplateQuery({ templateId: templateRevision.template.id });

  const template = templateQuery.data;
  const templateId = template?.id ?? templateRevision.template?.id;
  const isPrivateTemplate = useIsPrivateTemplate(templateId);

  const orgId = template?.organization.id ?? '';
  const folderId = template?.organization.id ?? '';

  const queryClient = useQueryClient();

  const updateSharedRunLink = useUpdateShareableLinkMutation({
    onSuccess: () => {
      TemplatePermissionsStatsQueryService.invalidateCache(orgId, folderId);
      queryClient.invalidateQueries(GetTemplateQuery.getKey({ templateId }));
    },
    onError: error => {
      setShared.toggle();
      const description =
        error.response?.status === HttpStatus.FORBIDDEN
          ? 'Please verify your email address and try again.'
          : DefaultErrorMessages.unexpectedErrorDescription;

      toast({
        status: 'error',
        title: `We're having problems ${shared ? 'disabling' : 'enabling'} the shareable run link`,
        description,
      });
    },
  });

  React.useEffect(() => {
    const sharedRunLink = template?.sharedRunLink;

    setShared[sharedRunLink ? 'on' : 'off']();
  }, [setShared, template]);

  return (
    <VStack spacing="2" alignItems="start" py="6">
      <Text variant="1" color="gray.600" fontWeight="medium">
        Permissions
      </Text>

      {isPrivateTemplate ? (
        <HStack spacing="2">
          <Icon variant="far" icon="lock" size="4" color="gray.600" />
          <Text variant="1" color="gray.600">
            Only organization members can run this private workflow.
          </Text>
        </HStack>
      ) : (
        <RadioGroup
          as={VStack}
          alignItems="start"
          onChange={() => {
            updateSharedRunLink.mutate({ templateId: templateId!, shared: !shared });
            setShared.toggle();
          }}
          value={shared.toString()}
          name="runLinkShared"
        >
          <Radio value="false">
            <Text color="gray.600" fontWeight="normal">
              Only organization members can run this workflow.
            </Text>
          </Radio>
          {!canShareLinks ? (
            <ShareLinksDisabledTooltip>
              <Radio value="true" fontWeight="normal" isDisabled={true}>
                <Text color="gray.600" fontWeight="normal">
                  Anyone can run this workflow.
                </Text>
              </Radio>
            </ShareLinksDisabledTooltip>
          ) : (
            <Radio value="true" fontWeight="normal">
              <Text color="gray.600" fontWeight="normal">
                Anyone can run this workflow.
              </Text>
            </Radio>
          )}
        </RadioGroup>
      )}
    </VStack>
  );
};

const ShareLinksDisabledTooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({ children, ...props }) => {
  return (
    <Tooltip
      w="100%"
      label={
        <Text>
          Share links are enabled after a 7-day period on any paid plan. For expedited access, please contact our
          support&nbsp;team.
        </Text>
      }
      hasArrow
      shouldWrapChildren
      {...props}
    >
      {children}
    </Tooltip>
  );
};
