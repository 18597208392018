import * as React from 'react';
import { MenuItem, MenuItemProps } from 'components/design/next';
import { useToolbarMenuItemOnClick } from './use-toolbar-menu-item-on-click';

export const ToolbarMenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = React.memo(({ onClick, ...props }) => {
  const onClickOverride = useToolbarMenuItemOnClick();
  return (
    <MenuItem
      onClick={e => {
        e.preventDefault();
        onClickOverride(() => {
          onClick?.(e);
        });
      }}
      {...props}
    />
  );
});
