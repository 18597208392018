import cloneDeep from 'lodash.clonedeep';
import { combineReducers } from 'redux';
import { checklistDashboardSettingsReducer } from 'components/dashboard/store/checklist-dashboard.reducers';
import { dayjs as moment } from '@process-street/subgrade/util';

import { AUTHENTICATE_ANONYMOUS_USER, SET_TIME_OFFSET } from 'reducers/auth/authentication.actions';

import {
  SESSION_CHECKLIST_EDITOR_CLEAR_PROPERTY,
  SESSION_CHECKLIST_EDITOR_SET_PROPERTY,
  SESSION_CLEAR,
  SESSION_CLEAR_TOKEN,
  SESSION_IMPORT,
  SESSION_ORGANIZATION_USE_FREQUENCY_INCREMENT,
  SESSION_SET_CHECKLIST_STATS_PROPERTY,
  SESSION_SET_DEFAULT_HOME_PAGE,
  SESSION_SET_ELEVIO_HASH,
  SESSION_SET_INBOX_PROPERTY,
  SESSION_SET_INTERCOM_HASH,
  SESSION_SET_ONBOARDING_PROPERTY,
  SESSION_SET_ORGANIZATION_MEMBERSHIPS,
  SESSION_SET_ORGANIZATION_SUBDOMAINS_MAP,
  SESSION_SET_PROFILE,
  SESSION_SET_SELECTED_ORGANIZATION_ID,
  SESSION_SET_TOKEN,
  SESSION_SET_USER,
  SESSION_TEMPLATE_EDITOR_CLEAR_PROPERTY,
  SESSION_TEMPLATE_EDITOR_SET_PROPERTY,
} from 'reducers/session/session.actions';
import { handleActionsOnSuccess } from 'reducers/util';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';

/**
 * Import value to a current state.
 *
 * @param state - current session state
 * @param val - imported value
 * @param initialState - initial property state
 * @return merged session state
 */
const importValue = (state, val, initialState) => {
  // Case 1. If imported value is undefined ignore it
  if (val === undefined) {
    return state;
    // Case 2. If value is object, merge with current state, replacing original value
  } else if (typeof val === 'object' && val !== null) {
    return { ...state, ...val };
  }
  // Case 3. Use newVal if it's not null otherwise use initialState
  return val || initialState;
};

const sessionPropertySetActionReducer = (key, setPropertyAction) =>
  handleActionsOnSuccess(
    {
      [setPropertyAction]: (state, { payload }) => ({
        ...state,
        [payload.key]: cloneDeep(payload.value),
      }),
      [SESSION_CLEAR]: () => ({}),
      [SESSION_IMPORT]: (state, { payload }) => importValue(state, cloneDeep(payload[key]), {}),
    },
    {},
  );

const sessionKeySetReducer = (key, initialState, setKeyAction) =>
  handleActionsOnSuccess(
    {
      [setKeyAction]: (__state, { payload }) => cloneDeep(payload),
      [SESSION_CLEAR]: () => initialState,
      [SESSION_IMPORT]: (state, { payload }) => importValue(state, cloneDeep(payload[key]), initialState),
    },
    initialState,
  );

const sessionUserReducer = () => {
  const initialState = null;
  return handleActionsOnSuccess(
    {
      // We need to have user in session to perform actions on behalf of Anonymous user,
      // but this is only if user is undefined.
      // So this checks if there is a user keep it, otherwise set Anonymous user from payload.
      [AUTHENTICATE_ANONYMOUS_USER]: (state, { payload }) => {
        if (state === null || state.id !== payload.user.id) {
          const user = cloneDeep(payload.user);
          if (isAnonymousUser(user)) {
            user.timeZone = moment.tz.guess();
          }
          return user;
        } else {
          return state;
        }
      },
      [SESSION_SET_USER]: (__state, { payload }) => cloneDeep(payload),
      [SESSION_CLEAR]: () => initialState,
      [SESSION_IMPORT]: (state, { payload }) => importValue(state, cloneDeep(payload[SESSION_SET_USER]), initialState),
    },
    initialState,
  );
};

const tokenReducer = handleActionsOnSuccess(
  {
    [SESSION_SET_TOKEN]: (__oldToken, { payload: token }) => token,
    [SESSION_IMPORT]: (oldToken, { payload: { token: importedToken } }) => importedToken || oldToken,
    [SESSION_CLEAR]: () => null,
    [SESSION_CLEAR_TOKEN]: () => null,
  },
  null,
);

const organizationUseFrequencyMapReducer = handleActionsOnSuccess(
  {
    [SESSION_ORGANIZATION_USE_FREQUENCY_INCREMENT]: (state, { payload: organizationId }) => {
      const newState = { ...state };
      newState[organizationId] = (newState[organizationId] || 0) + 1;
      return newState;
    },
    [SESSION_CLEAR]: () => ({}),
    [SESSION_IMPORT]: (state, { payload }) => importValue(state, cloneDeep(payload.organizationUseFrequencyMap), {}),
  },
  {},
);

const checklistEditorReducer = handleActionsOnSuccess(
  {
    [SESSION_CHECKLIST_EDITOR_SET_PROPERTY]: (state, { payload: { key, value } }) => ({
      ...state,
      [key]: value,
    }),
    [SESSION_CHECKLIST_EDITOR_CLEAR_PROPERTY]: (state, { payload: property }) => {
      const newState = { ...state };
      delete newState[property];
      return newState;
    },
    [SESSION_CLEAR]: () => ({}),
    [SESSION_IMPORT]: (state, { payload: { checklistEditor = {} } }) => ({ ...state, ...checklistEditor }),
  },
  {},
);

const templateEditorReducer = handleActionsOnSuccess(
  {
    [SESSION_TEMPLATE_EDITOR_SET_PROPERTY]: (state, { payload: { key, value } }) => ({
      ...state,
      [key]: value,
    }),
    [SESSION_TEMPLATE_EDITOR_CLEAR_PROPERTY]: (state, { payload: property }) => {
      const newState = { ...state };
      delete newState[property];
      return newState;
    },
    [SESSION_CLEAR]: () => ({}),
    [SESSION_IMPORT]: (state, { payload: { templateEditor = {} } }) => ({ ...state, ...templateEditor }),
  },
  {},
);

const timeOffsetReducer = handleActionsOnSuccess(
  {
    [SET_TIME_OFFSET]: (__state, { payload }) => {
      return Date.now() - payload.currentTime;
    },
  },
  0,
);

const elevioHashReducer = handleActionsOnSuccess(
  {
    [SESSION_SET_ELEVIO_HASH]: (__state, { payload: hash }) => hash,
  },
  null,
);

const intrecomHashReducer = handleActionsOnSuccess(
  {
    [SESSION_SET_INTERCOM_HASH]: (__state, { payload: hash }) => hash,
  },
  null,
);

export const sessionReducer = combineReducers({
  checklistDashboard: checklistDashboardSettingsReducer,
  checklistEditor: checklistEditorReducer,
  checklistStats: sessionPropertySetActionReducer('checklistStats', SESSION_SET_CHECKLIST_STATS_PROPERTY),
  defaultHomepage: sessionKeySetReducer('defaultHomepage', 'dashboard', SESSION_SET_DEFAULT_HOME_PAGE),
  inbox: sessionPropertySetActionReducer('inbox', SESSION_SET_INBOX_PROPERTY),
  onboarding: sessionPropertySetActionReducer('onboarding', SESSION_SET_ONBOARDING_PROPERTY),
  organizationUseFrequencyMap: organizationUseFrequencyMapReducer,
  profile: sessionKeySetReducer('profile', null, SESSION_SET_PROFILE),
  selectedOrganizationId: sessionKeySetReducer('selectedOrganizationId', null, SESSION_SET_SELECTED_ORGANIZATION_ID),
  token: tokenReducer,
  templateEditor: templateEditorReducer,
  user: sessionUserReducer(),
  organizationMemberships: sessionKeySetReducer('organizationMemberships', [], SESSION_SET_ORGANIZATION_MEMBERSHIPS),
  organizationSubdomainsMap: sessionKeySetReducer(
    'organizationSubdomainsMap',
    [],
    SESSION_SET_ORGANIZATION_SUBDOMAINS_MAP,
  ),
  timeOffset: timeOffsetReducer,
  elevioHash: elevioHashReducer,
  intercomHash: intrecomHashReducer,
});
