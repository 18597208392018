import { User } from '@process-street/subgrade/core';
import { TaskPermissionRule } from '@process-street/subgrade/permission';
import { FormFieldWidget } from '@process-street/subgrade/process';
import { UserOptionTransformer, UserOption } from 'utils/user-option-transformer';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import { getRuleLabel, resolveRuleIcon } from '@process-street/subgrade/util/task-permission-rule-utils';

export interface TaskPermissionRuleOption {
  avatarIcon: CustomIconName;
}

export type UserOrRuleOption = { label: string; value: string } & (
  | { type: 'Rule'; rule: TaskPermissionRuleOption }
  | { type: 'User'; user: UserOption }
);

export const transformRulesToOptions = (rules: TaskPermissionRule[], widgets: FormFieldWidget[]) => {
  return rules.map(
    rule =>
      ({
        label: getRuleLabel(rule, widgets),
        value: rule.id,
        type: 'Rule',
        rule: {
          avatarIcon: resolveRuleIcon(rule, widgets),
        },
      } as UserOrRuleOption),
  );
};

export const transformUsersToOptions = (users: User[]) =>
  users.map(
    user =>
      ({
        label: user.username,
        value: user.id,
        type: 'User',
        user: UserOptionTransformer.transformUserToOption(user),
      } as UserOrRuleOption),
  );
