import { Template } from '@process-street/subgrade/process';
import { TemplateSolutionInstance } from '@process-street/subgrade/automation';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type SolutionInstancesByTemplateIdQueryParams = { templateId?: Template['id'] };

export type SolutionInstancesByTemplateIdQueryResponse = TemplateSolutionInstance[];

export const SolutionInstancesByTemplateIdQuery = {
  key: ['solution-instances'],
  getKey: (params: SolutionInstancesByTemplateIdQueryParams): QueryKey => [
    ...SolutionInstancesByTemplateIdQuery.key,
    params.templateId,
  ],
  queryFn: ({ templateId }: SolutionInstancesByTemplateIdQueryParams) =>
    axiosService
      .getAxios()
      .get(`/1/templates/${templateId}/automations/template-solution-instances`)
      .then(res => res.data),
};

export function useSolutionInstancesByTemplateIdQuery<Select = SolutionInstancesByTemplateIdQueryResponse>(
  params: SolutionInstancesByTemplateIdQueryParams,
  options: UseQueryOptions<SolutionInstancesByTemplateIdQueryResponse, AxiosError, Select> = {},
) {
  return useQuery(
    SolutionInstancesByTemplateIdQuery.getKey(params),
    () => SolutionInstancesByTemplateIdQuery.queryFn(params),
    {
      ...options,
      staleTime: 1000 * 60,
      retry: 0,
      enabled: Boolean(params.templateId) && options.enabled !== false,
    },
  );
}
