import * as React from 'react';
import { AxisBottom } from '@visx/axis';
import { SharedAxisProps, AxisScale } from '@visx/axis/lib/types';
import { useToken } from 'components/design/next';

export const BarStackHorizontalAxisBottom = <Scale extends AxisScale>(props: SharedAxisProps<Scale>) => {
  const [gray500] = useToken('colors', ['gray.500']);
  const fontSizeXs = useToken('fontSizes', 'xs');

  return (
    <AxisBottom
      stroke={gray500}
      tickStroke={gray500}
      // prevent decimals for if max is less than default tick count (10)
      numTicks={Math.min(10, props.scale.domain()[1])}
      // don't show decimals on small numbers
      tickFormat={d => parseInt(d).toString()}
      tickLabelProps={() => ({
        fill: gray500,
        fontSize: fontSizeXs,
        textAnchor: 'middle',
      })}
      {...props}
    />
  );
};
