import angular from 'angular';

angular.module('frontStreetApp.services').factory('Comments', function (ResourceFactory) {
  return ResourceFactory.create({
    getByChecklistRevision: 'GET[] /1/comments/by-checklist-revision/:checklistRevisionId',
    create: 'POST /1/comments',
    get: 'GET /1/comments/:id',
    delete: 'DELETE /1/comments/:id',
  });
});
