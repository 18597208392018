import React, { useEffect } from 'react';

import { Text, useToast } from 'components/design/next';
import { AttachmentType, Checklist, Task } from '@process-street/subgrade/process';
import last from 'lodash/last';
import { UploadIconButton } from 'features/upload/components';
import { SIZE_TOO_LARGE, useUploadAttachmentComment } from './use-upload-attachment-comment';
import { DefaultErrorMessages } from 'components/utils/error-messages';

type UploadAttachmentProps = {
  taskId: Task['id'];
  checklistId: Checklist['id'];
  onFinish: () => void;
  setUploadingCount: (count: number) => void;
  progress: number | undefined;
  setProgress: (progress: number | undefined) => void;
  disabled: boolean;
  rightText?: string;
  shouldBroadcastEvents: boolean;
  attachmentType?: AttachmentType;
};
export const UploadAttachmentComment: React.FC<React.PropsWithChildren<UploadAttachmentProps>> = ({
  checklistId,
  taskId,
  onFinish,
  setUploadingCount,
  setProgress,
  progress,
  disabled,
  rightText,
  shouldBroadcastEvents,
  attachmentType,
}) => {
  const toast = useToast();

  const { dropzoneState, uploadError } = useUploadAttachmentComment({
    taskId,
    checklistId,
    setUploadingCount,
    onFinish,
    setProgress,
    isDropDisabled: true, // Disabling drag-drop files to clip icon, this button is only a file-chooser.
    shouldBroadcastEvents,
    attachmentType,
  });

  useEffect(() => {
    if (uploadError) {
      toast({
        status: 'error',
        title: "We're having problems uploading the attachment",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    }
  }, [uploadError, toast]);

  const fileTooLarge =
    dropzoneState.fileRejections.length > 0 && last(dropzoneState.fileRejections[0].errors)?.code === SIZE_TOO_LARGE;

  return (
    <div {...dropzoneState.getRootProps()}>
      {progress === undefined && (
        <UploadIconButton
          aria-label="add attachment"
          dropzoneInputProps={dropzoneState.getInputProps()}
          disabled={disabled}
          rightText={rightText}
        />
      )}

      {fileTooLarge && (
        <Text color="red.500" align="center" fontWeight="medium">
          {last(dropzoneState.fileRejections[0].errors)?.message}
        </Text>
      )}
    </div>
  );
};
