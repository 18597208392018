import * as React from 'react';
import { ButtonProps } from 'components/design/next';
import { Template } from '@process-street/subgrade/process';
import { useCreateSandboxAndRedirect } from 'hooks/use-create-sandbox-and-redirect';

export interface SandboxButtonProps {
  template: Template;
  children: React.ReactElement<ButtonProps>;
}

export const SandboxButton = ({ template, children }: SandboxButtonProps) => {
  const runChecklistAndRedirect = useCreateSandboxAndRedirect({
    templateName: template?.name,
    templateId: template?.id,
    openInNewTab: true,
  });
  // const isEditorV2 = useMatch('templateV2');
  if (!React.isValidElement(children)) throw new Error('A valid `children` must be provided.');

  return React.cloneElement(children, {
    onClick: runChecklistAndRedirect,
  });
};
