import { SavedViewPermit } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpsertSavedViewPermitMutationParams = {
  dataSetId: string;
  savedViewId: string;
  organizationMembershipId: string;
  savedViewUpdate: boolean;
  savedViewRead: boolean;
};

export type UpsertSavedViewPermitMutationResponse = SavedViewPermit;

export const UpsertSavedViewPermitMutation = {
  key: ['upsert', 'saved-view-permits'],
  getKey() {
    return [...UpsertSavedViewPermitMutation.key];
  },
  mutationFn: (params: UpsertSavedViewPermitMutationParams) =>
    axiosService
      .getAxios()
      .post<UpsertSavedViewPermitMutationResponse>(
        `/1/data-sets/${params.dataSetId}/saved-views/${params.savedViewId}/permits`,
        params,
      )
      .then(res => res.data),
};

export const useUpsertSavedViewPermitMutation = (
  options: UseMutationOptions<UpsertSavedViewPermitMutationResponse, AxiosError, UpsertSavedViewPermitMutationParams>,
) => {
  return useMutation(UpsertSavedViewPermitMutation.getKey(), UpsertSavedViewPermitMutation.mutationFn, options);
};
