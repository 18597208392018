import {
  UserType,
  UserStatus,
  OrganizationMembershipRole,
  OrganizationMembership,
  Muid,
  isOrganizationMembershipWithUser,
} from '@process-street/subgrade/core';

export const ALL_ANONYMOUS_GROUP_USERNAME = 'All Anonymous';
export const ALL_MEMBERS_GROUP_USERNAME = 'All Members';
export const ALL_GUESTS_GROUP_USERNAME = 'All Guests';
export const ALL_ADMINS_GROUP_USERNAME = 'All Admins';
export const ALL_FREE_MEMBERS_GROUP_USERNAME = 'All Free Members';

export const isStandardUserOm = (om: OrganizationMembership) =>
  isOrganizationMembershipWithUser(om) && om.user.userType === UserType.Standard;

export const isAnonymousUserOm = (om: OrganizationMembership) =>
  isOrganizationMembershipWithUser(om) && om.user.userType === UserType.Anonymous;

export const isGroupUserOm = (om: OrganizationMembership) =>
  isOrganizationMembershipWithUser(om) && om.user.userType === UserType.Group;

export const isApiUserOm = (om: OrganizationMembership) =>
  isOrganizationMembershipWithUser(om) && om.user.userType === UserType.Api;

export const isStandardGroupUserOm = (om: OrganizationMembership) =>
  isGroupUserOm(om) && !isHiddenSystemGroupUserOm(om);

export const isHiddenSystemGroupUserOm = (om: OrganizationMembership) =>
  isOrganizationMembershipWithUser(om) &&
  isGroupUserOm(om) &&
  [
    ALL_GUESTS_GROUP_USERNAME,
    ALL_ANONYMOUS_GROUP_USERNAME,
    ALL_ADMINS_GROUP_USERNAME,
    ALL_FREE_MEMBERS_GROUP_USERNAME,
  ].includes(om.user.username);

export const isSystemGroupUserOm = (om: OrganizationMembership) =>
  isOrganizationMembershipWithUser(om) &&
  isGroupUserOm(om) &&
  [
    ALL_MEMBERS_GROUP_USERNAME,
    ALL_GUESTS_GROUP_USERNAME,
    ALL_ANONYMOUS_GROUP_USERNAME,
    ALL_ADMINS_GROUP_USERNAME,
    ALL_FREE_MEMBERS_GROUP_USERNAME,
  ].includes(om.user.username);

const omsVisibilityOrder = [
  ALL_MEMBERS_GROUP_USERNAME,
  ALL_GUESTS_GROUP_USERNAME,
  ALL_ANONYMOUS_GROUP_USERNAME,
  ALL_ADMINS_GROUP_USERNAME,
  ALL_FREE_MEMBERS_GROUP_USERNAME,
];

export const sortByVisibility = (a: OrganizationMembership, b: OrganizationMembership) => {
  const indexA = isOrganizationMembershipWithUser(a) ? omsVisibilityOrder.indexOf(a.user.username) : -1;
  const indexB = isOrganizationMembershipWithUser(b) ? omsVisibilityOrder.indexOf(b.user.username) : -1;
  return indexA - indexB;
};

export const isTaskPermitDisplayableSystemGroupUserOm = (om: OrganizationMembership) =>
  isOrganizationMembershipWithUser(om) &&
  isGroupUserOm(om) &&
  [ALL_MEMBERS_GROUP_USERNAME, ALL_GUESTS_GROUP_USERNAME, ALL_ANONYMOUS_GROUP_USERNAME].includes(om.user.username);

export const isStandardUserOrGroupOm = (om: OrganizationMembership) => isStandardUserOm(om) || isGroupUserOm(om);

export const isStandardUserOrStandardGroupOm = (membership: OrganizationMembership) =>
  isStandardUserOm(membership) || isStandardGroupUserOm(membership);

export const isAssignableUserOrGroupOm = (om: OrganizationMembership) =>
  (isStandardUserOm(om) && om.role !== OrganizationMembershipRole.Guest) ||
  (isGroupUserOm(om) && !isSystemGroupUserOm(om));

export const isStandardUserOrNonSystemGroupOm = (om: OrganizationMembership) =>
  isStandardUserOm(om) || (isGroupUserOm(om) && !isSystemGroupUserOm(om));

export const getMembershipsSuggestions = (
  oms: OrganizationMembership[],
  assignedMembershipIds: Muid[],
  partial: string,
) => {
  const trimmedPartial = (partial || '').trim().toLowerCase();

  return oms.filter(isOrganizationMembershipWithUser).filter(om => {
    if (assignedMembershipIds.includes(om.id) || !isAssignableUserOrGroupOm(om)) {
      return false;
    }

    if (isGroupUserOm(om)) {
      return om.user.username.toLowerCase().includes(trimmedPartial);
    } else {
      return (
        om.user.email.toLowerCase().includes(trimmedPartial) || om.user.username.toLowerCase().includes(trimmedPartial)
      );
    }
  });
};

export const isBillableUser = (om: OrganizationMembership) =>
  isOrganizationMembershipWithUser(om) &&
  [OrganizationMembershipRole.FullMember, OrganizationMembershipRole.Admin].includes(om.role) &&
  om.user &&
  om.user.status !== UserStatus.Deleted &&
  om.user.userType === UserType.Standard;
