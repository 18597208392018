import { Folder } from '@process-street/subgrade/process';
import {
  Text,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  ButtonGroup,
  Button,
  AlertDialogProps,
} from 'components/design/next';
import * as React from 'react';

export type Props = Omit<AlertDialogProps, 'children' | 'leastDestructiveRef'> & {
  folder: Folder;
  onDelete: () => void;
  leastDestructiveRef: React.RefObject<HTMLButtonElement>;
};

export const DeleteAlertDialog: React.FC<React.PropsWithChildren<Props>> = ({ folder, onDelete, ...alertProps }) => {
  return (
    <AlertDialog {...alertProps}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg">Delete this folder?</AlertDialogHeader>
        <AlertDialogBody>
          <Text>
            Are you sure you want to delete the folder <Text as="b">{folder.name}?</Text>
          </Text>
        </AlertDialogBody>
        <AlertDialogFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={alertProps.onClose} ref={alertProps.leastDestructiveRef}>
              Cancel
            </Button>
            <Button variant="danger" onClick={onDelete}>
              Delete
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
