import { FormResponsePageProviders } from 'app/pages/checklists/_id/providers';
import { FormResponsePageMachineSelectors } from './form-response-page-selectors';
import { useSelector } from '@xstate/react';
import { Muid } from '@process-street/subgrade/core';
import { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { TaskTemplate } from '@process-street/subgrade/process';
import * as React from 'react';

export namespace FormResponsePageMachineHooks {
  export const useTasks = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    return useSelector(actor, FormResponsePageMachineSelectors.getTasks);
  };

  export const useOneOffTasks = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    return useSelector(actor, FormResponsePageMachineSelectors.getOneOffTasks);
  };

  export const useApprovalRules = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    return useSelector(actor, FormResponsePageMachineSelectors.getApprovalRules);
  };

  export const useChecklist = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    return useSelector(actor, FormResponsePageMachineSelectors.getChecklist);
  };

  export const useChecklistRevision = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    return useSelector(actor, FormResponsePageMachineSelectors.getChecklistRevision);
  };

  export const useTaskAssignments = (taskId: Muid) => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    const selector = useMemo(() => FormResponsePageMachineSelectors.getTaskAssignments(taskId), [taskId]);

    return useSelector(actor, selector, isEqual);
  };

  export const useDueDateRules = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();

    return useSelector(actor, FormResponsePageMachineSelectors.getDueDateRules);
  };

  export const useTaskTemplates = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();

    return useSelector(actor, FormResponsePageMachineSelectors.getTaskTemplates);
  };

  export const useDateWidgets = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();

    return useSelector(actor, FormResponsePageMachineSelectors.getDateWidgets, isEqual);
  };

  export const useDueDateRuleForTaskTemplate = (taskTemplate: TaskTemplate) => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    const selector = useMemo(
      () => FormResponsePageMachineSelectors.getDueDateRuleByTaskTemplate(taskTemplate),
      [taskTemplate],
    );

    return useSelector(actor, selector, isEqual);
  };

  export const useSubjectGroupIdToApprovalGroupIdMap = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    const approvalRules = useSelector(actor, FormResponsePageMachineSelectors.getApprovalRules);

    return React.useMemo(
      () =>
        new Map(
          (approvalRules ?? []).map(approvalRule => [
            approvalRule.subjectTaskTemplateGroupId,
            approvalRule.approvalTaskTemplateGroupId,
          ]),
        ),
      [approvalRules],
    );
  };

  export const useSubjectIdToApprovalMap = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    const approvals = useSelector(actor, FormResponsePageMachineSelectors.getApprovals);

    return React.useMemo(
      () => new Map((approvals ?? []).map(approval => [approval.subjectTaskId, approval])),
      [approvals],
    );
  };
}
