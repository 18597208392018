import angular from 'angular';
import { connectService } from 'reducers/util';
import { SubscriptionStatus } from '@process-street/subgrade/core';
import { isTrialing } from 'utils/organization';
import { isSubscribed } from 'utils/billing';
import { dayjs as moment } from '@process-street/subgrade/util';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { ChecklistEvent } from 'services/checklists/checklist-event';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.services')
  .service(
    'OrganizationService',
    function (
      $location,
      $ngRedux,
      $q,
      $rootScope,
      $state,
      OrganizationActions,
      SessionService,
      UserActions,
      ToastService,
    ) {
      const logger = trace({ name: 'OrganizationService' });

      /**
       * @typedef {Object} Subscription
       * @property {SubscriptionStatus} status
       * @property {boolean} cancelAtPeriodEnd
       * @property {Date} currentPeriodEndDate
       * @property {Date} trialEndDate
       */

      /**
       * @typedef {Object} Organization
       * @property {number} id
       * @property {Subscription} subscription
       */

      const self = this;

      connectService('OrganizationService', $ngRedux, null, {
        ...OrganizationActions,
        getUserById: UserActions.getById,
        getAllOrganizationMemberships: UserActions.getAllOrganizationMemberships,
      })(self);

      // DAO ACCESS

      self.create = name =>
        this.actions.create(name, moment.tz.guess()).then(({ payload: response }) => {
          const { organization } = response;
          organization._useFrequency = 0; // it was just created

          $rootScope.$broadcast(
            EventName.ORGANIZATION_CREATED,
            {
              organization,
              plan: {
                name: 'Free',
                cost: 0,
              },
            },
            false /*authFlow*/,
          );

          return response;
        });

      self.getById = (id, { flushCache } = {}) => {
        return this.actions.getById(id, { flushCache }).then(({ payload }) => payload);
      };

      self.update = (organization, info) => {
        const onUpdateSuccess = () => {
          AnalyticsService.trackEvent('organization updated info');

          ToastService.openToast({
            status: 'success',
            title: `Settings updated`,
          });

          if (organization.name !== info.name) {
            organization.name = info.name;
            $rootScope.$broadcast(EventName.ORGANIZATION_NAME_UPDATED, info.name);
          }

          organization.industry = info.industry;
        };

        const onUpdateFailure = () => {
          logger.error('failed to update organization');

          ToastService.openToast({
            status: 'error',
            title: `We're having problems updating the settings`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        };

        return this.actions.updateById(organization, info).then(onUpdateSuccess, onUpdateFailure);
      };

      self.getCardByOrganizationId = function (organizationId) {
        return this.actions.getCardById(organizationId).then(({ payload }) => payload);
      };

      self.getDiscountByOrganizationId = function (organizationId) {
        return this.actions.getDiscountByOrganizationId(organizationId).then(({ payload }) => payload);
      };

      self.getAllOrganizationMemberships = function (userId, flushCache) {
        return this.actions
          .getAllOrganizationMemberships({
            userId,
            flushCache,
            selectedOrganizationId: SessionService.getSelectedOrganizationId(),
          })
          .then(({ payload }) => payload);
      };

      self.setCardById = function (organizationId, cardToken) {
        return this.actions.setCardById(organizationId, cardToken).then(({ payload: card }) => card);
      };

      /**
       * Sets the coupon for an organization.
       *
       * @param organizationId
       * @param coupon
       *
       * @return {Promise}
       */
      self.setCouponById = function (organizationId, coupon) {
        return this.actions.setCouponById(organizationId, coupon).then(({ payload }) => payload);
      };

      /**
       * Repairs stripe for given organization
       *
       * @param organization
       *
       * @return {Promise} with updated organization
       */
      self.repairStripeByOrganization = function (organization) {
        return this.actions.repairById(organization.id).then(({ payload }) => payload);
      };

      /**
       * Updates plan for given organization
       *
       * @param organization
       * @param planId
       *
       * @return {Promise} with updated organization
       */
      self.updateSubscriptionPlanByOrganization = function (organization, planId) {
        return this.actions.updateSubscriptionPlanById(organization.id, planId).then(({ payload }) => payload);
      };

      /**
       * Cancels subscription by organization with feedback
       *
       * @param organization
       * @param feedback
       *
       * @return {Promise} with updated organization
       */
      self.cancelSubscriptionByOrganization = function (organization, feedback) {
        return this.actions.cancelSubscriptionById(organization.id, feedback).then(({ payload }) => payload);
      };

      /**
       * Cancels subscription by organization id with feedback
       *
       * @param organizationId
       * @param feedback
       *
       * @return {Promise} with updated organization
       * // TODO This or the cancelSubscriptionByOrganization should be removed
       */
      self.cancelSubscriptionByOrganizationId = function (organizationId, cancellationForm) {
        return this.actions.cancelSubscriptionById(organizationId, cancellationForm).then(({ payload }) => payload);
      };

      /**
       * Starts trial on a paid plan by organization for a given plan
       *
       * @param organization
       * @param paidPlanId
       *
       * @return {Promise} with updated organization
       */
      self.startSubscriptionTrialByOrganizationId = function (organizationId, paidPlanId) {
        return this.actions.startSubscriptionTrialById(organizationId, paidPlanId).then(({ payload }) => payload);
      };

      /**
       * Ends trial by organization
       *
       * @param organization
       *
       * @return {Promise} empty
       */
      self.endSubscriptionTrialByOrganizationId = function (organizationId) {
        return this.actions.endSubscriptionTrialById(organizationId).then(({ payload }) => payload);
      };

      /**
       * Gets all API keys for given organization
       *
       * @param organization
       *
       * @return {Promise} with key objects
       */
      self.getAllApiKeysByOrganizationId = function (organizationId) {
        return this.actions.getAllApiKeysById(organizationId).then(({ payload: apiKeys }) => {
          apiKeys.forEach(apiKey => {
            apiKey.user._originalUsername = apiKey.user.username;
          });
          return apiKeys;
        });
      };

      /**
       * Creates a new API key for given organization with given label
       *
       * @param organization
       * @param label
       *
       * @return {Promise} with key object
       */
      self.createApiKeyByOrganization = (organizationId, label) => {
        const onCreateApiKeySuccess = ({ payload: apiKey }) => {
          AnalyticsService.trackEvent('api key created', {
            name: apiKey.user.username,
          });

          ToastService.openToast({
            status: 'success',
            title: `API key updated`,
          });

          return apiKey;
        };

        const onCreateApiKeyFailure = () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems updating the API key`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
          return $q.reject();
        };

        return this.actions.createApiKeyById(organizationId, label).then(onCreateApiKeySuccess, onCreateApiKeyFailure);
      };

      /**
       * Updates API by it's organization and key object itself
       *
       * @param organization
       * @param apiKey
       * @param changes
       *
       * @return {Promise} with the same key
       */
      self.updateApiKeyByOrganizationIdAndApiKeyId = function (organizationId, apiKeyId, changes) {
        return this.actions.updateApiKeyByOrganizationIdAndApiKeyId(organizationId, apiKeyId, changes);
      };

      /**
       * Deletes API by it's organization and key object itself
       *
       * @param organization
       * @param apiKey
       *
       * @return {Promise} empty
       */
      self.deleteApiKeyByOrganizationIdAndApiKeyId = function (organizationId, apiKeyId) {
        return this.actions.deleteApiKeyByOrganizationIdAndApiKeyId(organizationId, apiKeyId);
      };

      // THE REST

      self.getSelectedOrganization = function ({ flushCache } = {}) {
        return self.getById(SessionService.getSelectedOrganizationId(), { flushCache });
      };

      self.isSubscribed = isSubscribed;

      self.isTrialing = isTrialing;

      self.isActive = function (subscription) {
        return subscription.status === SubscriptionStatus.Active;
      };

      self.isPastDue = function (subscription) {
        return subscription.status === SubscriptionStatus.PastDue;
      };

      self.isCanceled = function (subscription) {
        return subscription.status === SubscriptionStatus.Canceled;
      };

      /**
       * Switch to the given organization id.
       *
       * @param organizationId
       * @param redirectToHome
       */
      self.switchById = (organizationId, redirectToHome) => {
        const switchParams = {
          organizationId,
          current: {
            stateName: $state.current.name,
            stateParams: angular.copy($state.params),
            searchParams: $location.search(),
            redirectToHome,
          },
        };
        self.setLastSelectedOrganizationId(organizationId);
        $state.go('switchOrganization', switchParams);
      };

      self.switchToNewOrganization = organizationId => {
        const switchParams = {
          organizationId,

          current: {
            stateName: 'dashboard.type',
            stateParams: {
              [AppModalQueryParam.Modal]: AppModalName.TemplateGallery,
              type: 'folder',
              path: 'home',
            },
            searchParams: '',
            redirectToHome: false,
          },
        };
        $state.go('switchOrganization', switchParams);
      };

      self.setEmail = function (id, email) {
        return this.actions.setEmail(id, email).then(
          ({ payload: updatedOrganization }) => updatedOrganization,
          error => $q.reject(error),
        );
      };

      /**
       * Gets all organizations for current user
       *
       * @param user
       * @param fullMembershipOnly
       * @param flushCache - We don't need this any more as we dont use cache.
       *
       * @return {Promise}
       */
      /*eslint-disable no-unused-vars*/
      self.getAllByUser = function (user, fullMembershipOnly, flushCache) {
        const allOrganizationMembershipsAction = this.getAllOrganizationMemberships(user.id, flushCache);
        //TODO: Remove this action.then()
        //  Action should dispatch further actions and we should use mapStateToThis(i.e. rely on store for values)
        return allOrganizationMembershipsAction.then(
          memberships => {
            let filteredMemberships = memberships;
            if (fullMembershipOnly) {
              filteredMemberships = memberships.filter(m => !m.guest);
            }

            const organizations = filteredMemberships.map(membership => membership.organization);

            return organizations;
          },
          response => $q.reject(response),
        );
      };

      // Organization Stats

      /**
       * @typedef {Object} OrganizationStats
       * @property {number} checklistsRunsCountCurrentPeriod
       * @property {number} templatesActiveCount
       * @property {number} checklistsActiveCount
       */

      /**
       * Returns the given organization's stats.
       *
       * @param organizationId
       * @returns {Promise}
       */
      self.getOrganizationStatsById = function (organizationId) {
        return this.actions.getOrganizationStatsById(organizationId).then(({ payload }) => payload);
      };

      self.getAllUsersWithNotMatchingEmailDomainById = function (organizationId) {
        return this.actions.getAllUsersWithNotMatchingEmailDomainById(organizationId).then(({ payload }) => payload);
      };

      self.getAllUsersWithMatchingEmailDomainAndMultipleEmailsById = function (organizationId) {
        return this.actions
          .getAllUsersWithMatchingEmailDomainAndMultipleEmailsById(organizationId)
          .then(({ payload }) => payload);
      };

      self.getAllUsersWithMatchingEmailDomainAndMultipleOrganizationsById = function (organizationId) {
        return this.actions
          .getAllUsersWithMatchingEmailDomainAndMultipleOrganizationsById(organizationId)
          .then(({ payload }) => payload);
      };

      self.lockToSsoByOrganizationId = function (organizationId) {
        return this.actions.lockToSsoByOrganizationId(organizationId).then(({ payload }) => payload);
      };

      self.hasSsoByOrganizationDomains = function (organizationDomains) {
        return organizationDomains.some(domain => !!domain.authConnection);
      };

      self.isLockedByOrganizationDomains = function (organizationDomains) {
        return organizationDomains.some(domain => domain.authConnection && domain.authConnection.locked);
      };

      /**
       * Updates the given organization's GrowSumo partner key.
       *
       * @param organizationId
       * @param partnerKey
       * @returns {*}
       */
      self.updateGrowSumoPartnerKeyById = function (organizationId, partnerKey) {
        return this.actions.updateGrowSumoPartnerKeyById(organizationId, partnerKey).then(({ payload }) => payload);
      };

      /**
       * Updates the given organization's default SSO organization membership level.
       *
       * @param organizationId
       * @param organizationMembershipLevel
       * @returns {*}
       */
      self.updateDefaultSsoOrganizationMembershipLevelById = function (organizationId, organizationMembershipLevel) {
        return this.actions
          .updateDefaultSsoOrganizationMembershipLevelById(organizationId, organizationMembershipLevel)
          .then(({ payload }) => payload);
      };

      self.getLastSelectedOrganizationId = function () {
        return sessionStorage.getItem(LAST_SELECTED_ORGANIZATION_STORAGE_KEY);
      };

      self.getLastSelectedOrganization = function () {
        const organizationId = self.getLastSelectedOrganizationId();
        const organizationMemberships = SessionService.getOrganizationMemberships();

        if (!organizationId) return null;

        const organization = organizationMemberships.find(om => om.organization.id === organizationId)?.organization;

        return organization ?? null;
      };

      self.setLastSelectedOrganizationId = function (organizationId) {
        sessionStorage.setItem(LAST_SELECTED_ORGANIZATION_STORAGE_KEY, organizationId);
      };

      self.removeLastSelectedOrganizationId = function () {
        sessionStorage.removeItem(LAST_SELECTED_ORGANIZATION_STORAGE_KEY);
      };

      // Events

      $rootScope.$on(EventName.TEMPLATE_UPDATE_OK, () => {
        $rootScope.$broadcast(EventName.ORGANIZATION_STATS_UPDATED);
      });

      $rootScope.$on(EventName.TEMPLATE_CREATE_OK, () => {
        $rootScope.$broadcast(EventName.ORGANIZATION_STATS_UPDATED);
      });

      $rootScope.$on(EventName.TEMPLATE_DELETE_OK, () => {
        $rootScope.$broadcast(EventName.ORGANIZATION_STATS_UPDATED);
      });

      $rootScope.$on(ChecklistEvent.UPDATE_OK, () => {
        $rootScope.$broadcast(EventName.ORGANIZATION_STATS_UPDATED);
      });

      $rootScope.$on(ChecklistEvent.CREATE_OK, () => {
        $rootScope.$broadcast(EventName.ORGANIZATION_STATS_UPDATED);
      });

      $rootScope.$on(ChecklistEvent.DELETE_OK, () => {
        $rootScope.$broadcast(EventName.ORGANIZATION_STATS_UPDATED);
      });
    },
  );

const LAST_SELECTED_ORGANIZATION_STORAGE_KEY = 'lastSelectedOrganizationId';
