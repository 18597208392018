import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { List } from 'components/design/next';
import { AttachmentItem } from 'features/comments/components/common/attachment/attachment-item';
import * as React from 'react';
import { useOneOffTaskAttachments } from '../hooks/use-one-off-task-attachments';

export type AttachmentsListProps = {
  task: OneOffTask;
};

export const AttachmentsList: React.FC<React.PropsWithChildren<AttachmentsListProps>> = ({ task }) => {
  const { taskAttachments, checklistId, templateId } = useOneOffTaskAttachments(task);
  if (!taskAttachments) {
    return null;
  }
  return (
    <List w="full">
      {taskAttachments.map(attachment => (
        <AttachmentItem
          {...{
            key: attachment.id,
            attachment,
            checklistId,
            templateId,
            isFromOneOffTask: true,
          }}
        />
      ))}
    </List>
  );
};
