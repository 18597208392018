import { Muid } from '@process-street/subgrade/core';
import { Widget } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions, QueryClient, QueryObserver } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace WidgetsByChecklistRevisionIdQuery {
  export type Params = { checklistRevisionId?: Muid };
  export type Response = Widget[];

  export const key = ['checklist-revisions', 'widgets'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = ({ checklistRevisionId }: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/checklist-revisions/${checklistRevisionId}/widgets`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.checklistRevisionId) && options.enabled !== false,
    });
  };

  export const makeObserver = ({
    checklistRevisionId,
    queryClient,
  }: Params & {
    queryClient: QueryClient;
  }) => {
    return new QueryObserver<Response, AxiosError>(queryClient, {
      queryKey: getKey({ checklistRevisionId }),
      queryFn: () => queryFn({ checklistRevisionId }),
      enabled: Boolean(checklistRevisionId),
    });
  };
}
