import {
  isNotIdRef,
  OrganizationMembershipRole,
  OrganizationMembershipWithUser,
  UserType,
} from '@process-street/subgrade/core';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'app/features/checklist-revisions/query-builder';
import { useGetTasksAssignmentsByChecklistRevisionQuery } from 'app/features/checklist-revisions/query-builder/get-tasks-assignments-by-checklist-revision-query';
import { GetUserGroupMembershipsQuery } from 'app/features/group-memberships/query-builder/get-user-group-memberships-query';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import React from 'react';

export const useCanEditOneOfftask = (task: OneOffTask): boolean => {
  const currentUserInfoQuery = useGetCurrentUserInfoQuery();

  const isAdmin = currentUserInfoQuery.data?.organizationMembership.role === OrganizationMembershipRole.Admin;
  const isExternalGuest = currentUserInfoQuery.data?.organizationMembership.role === OrganizationMembershipRole.Guest;
  const isTaskCreator = currentUserInfoQuery.data?.user.id === task.audit.createdBy.id;

  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({
    checklistId: task.internalChecklistId,
  });

  const taskAssignmentsQuery = useGetTasksAssignmentsByChecklistRevisionQuery(
    { checklistRevisionId: checklistRevisionQuery.data?.id ?? '' },
    {
      enabled: Boolean(checklistRevisionQuery.data?.id),
      onSuccess: data => data.filter(taskAssignment => taskAssignment.task.id === task.id),
    },
  );

  const groupMembershipsQuery = GetUserGroupMembershipsQuery.useQuery({
    userId: currentUserInfoQuery.data?.user.id ?? '',
  });

  const assignedMemberships = React.useMemo(() => {
    if (taskAssignmentsQuery.data) {
      return taskAssignmentsQuery.data
        .map(assignment => isNotIdRef(assignment.organizationMembership) && assignment.organizationMembership)
        .filter(Boolean) as OrganizationMembershipWithUser[];
    }
    return [];
  }, [taskAssignmentsQuery.data]);

  const currentUserGroupUsersIdSet = React.useMemo(() => {
    if (groupMembershipsQuery.data) {
      return new Set(groupMembershipsQuery.data.map(groupMembership => groupMembership.user.id));
    }
    return new Set<string>();
  }, [groupMembershipsQuery.data]);

  const isAssignedDirectly = React.useMemo(() => {
    if (assignedMemberships && currentUserInfoQuery.data) {
      return assignedMemberships.map(assignment => assignment.user.id).includes(currentUserInfoQuery.data.user.id);
    }
    return false;
  }, [assignedMemberships, currentUserInfoQuery.data]);

  const isAssignedByGroup = React.useMemo(() => {
    if (assignedMemberships && currentUserGroupUsersIdSet) {
      const assignedGroupMemberships = assignedMemberships.filter(
        membership => membership.user.userType === UserType.Group,
      );
      // return true if the current user is part of any of the assigned groups
      return assignedGroupMemberships.some(membershipWithUser =>
        currentUserGroupUsersIdSet.has(membershipWithUser.user.id),
      );
    }

    return false;
  }, [assignedMemberships, currentUserGroupUsersIdSet]);

  const isAdminOrCreator = isAdmin || isTaskCreator;
  const isAssignedAndNoExternalGuest = (isAssignedDirectly || isAssignedByGroup) && !isExternalGuest;
  return isAdminOrCreator || isAssignedAndNoExternalGuest;
};
