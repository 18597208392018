import * as React from 'react';
import { Avatar, AvatarSize } from 'components/common/Avatar';
import { HStack, Text, Checkbox, VStack } from 'components/design/next';
import { Muid, OrganizationMembershipWithUser } from '@process-street/subgrade/core';

import { UserUtils } from '@process-street/subgrade/util';

export type UserRowProps = {
  organizationMembership: OrganizationMembershipWithUser;
  onChange: (checked: boolean, omIds: Muid[]) => void;
};

export const UserRow: React.FC<React.PropsWithChildren<UserRowProps>> = ({ organizationMembership, onChange }) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    onChange(e.target.checked, [organizationMembership.id]);
  };

  const { user } = organizationMembership;

  return (
    <Checkbox
      onChange={handleChange}
      width="full"
      padding={1}
      _hover={{
        backgroundColor: 'brand.100',
      }}
      mt="0"
    >
      <HStack>
        <Avatar size={AvatarSize.Normal} user={user} />
        <VStack alignItems="flex-start" spacing={0}>
          <Text noOfLines={1} variant="-1">
            {UserUtils.getLabel(user, false)}
          </Text>

          <Text variant="-2" color="gray.500" noOfLines={1} mt={0}>
            {user.email}
          </Text>
        </VStack>
      </HStack>
    </Checkbox>
  );
};
