import angular from 'angular';
import templateUrl from './billing-details.component.html';

angular.module('frontStreetApp.directives').component('psBillingDetails', {
  bindings: {
    organization: '<',
  },
  require: {
    billingCtrl: '^psBilling',
  },
  templateUrl,
  controller() {},
});
