import * as React from 'react';
import { Button, Icon, Text } from 'components/design/next';

type DetailsButtonProps = {
  onToggleDetails: () => void;
  expanded: boolean;
};

export const DetailsButton = ({ onToggleDetails, expanded }: DetailsButtonProps) => {
  const text = expanded ? 'Hide plan details' : 'View plan details';
  const icon = expanded ? 'caret-up' : 'caret-down';

  return (
    <Button variant="unstyled" onClick={onToggleDetails} mt={8}>
      <Text color="gray.500" fontWeight="medium">
        {text}
        <Icon icon={icon} variant="fas" size="4" marginLeft={1} />
      </Text>
    </Button>
  );
};
