import {
  Checklist,
  DueDateRuleChecklistType,
  DueDateRuleDefinition,
  DueDateRuleSourceType,
  DueDateRuleType,
  isHeading,
  OrderTree,
  OrderTreeUtils,
  Task,
  TaskTemplate,
} from '@process-street/subgrade/process';
import { Muid, Option, Period } from '@process-street/subgrade/core';
import { PartialDueDateRule } from 'features/dynamic-due-dates/models/models';
import { trace } from 'components/trace';

const logger = trace({ name: 'DueDateHelper' });

function getFirstOrderTree(
  selectedTaskTemplate: Option<TaskTemplate>,
  selectedTaskTemplates: TaskTemplate[],
): OrderTree {
  if (selectedTaskTemplate) {
    return selectedTaskTemplate.orderTree;
  } else {
    let firstOrderTree: OrderTree = '0';

    selectedTaskTemplates.forEach(taskTemplate => {
      const { orderTree } = taskTemplate;

      if (!firstOrderTree || OrderTreeUtils.compare(orderTree, firstOrderTree) < 0) {
        firstOrderTree = orderTree;
      }
    });

    return firstOrderTree;
  }
}

function isTaskBeforeExcludeHeading(taskTemplate: TaskTemplate, orderTree: OrderTree) {
  const isBefore = OrderTreeUtils.compare(taskTemplate.orderTree, orderTree) < 0;
  return !isHeading(taskTemplate) && isBefore;
}

function normalizePeriodYears(period: Period): Period {
  return {
    ...period,
    months: period.years * 12 + period.months,
    years: 0,
  };
}

function findLastApplicableTask(taskTemplates: TaskTemplate[]): Option<TaskTemplate> {
  const noHeadings = taskTemplates.filter(tt => !isHeading(tt));
  return noHeadings.length > 0 ? noHeadings[noHeadings.length - 1] : undefined;
}

function getSelectedOrLastApplicableTask(
  ruleType: DueDateRuleType,
  selectedTaskTemplate: Option<TaskTemplate>,
  taskTemplates: TaskTemplate[],
) {
  const isChecklistRule = ruleType === 'Checklist';

  return isChecklistRule ? findLastApplicableTask(taskTemplates) : selectedTaskTemplate;
}

/** Converting to legacy service format :| */
function convertRuleToServiceFormat(selectedRule: PartialDueDateRule) {
  return {
    offsetDirection: selectedRule.offsetDirection.toLowerCase(),
    rule: {
      sourceType: selectedRule.sourceType,
      widget: {
        header: { group: selectedRule.formFieldWidgetGroup },
        group: selectedRule.taskTemplateGroup,
      },
    },
    offset: {
      ...selectedRule.dueOffset,
      workdaysOnly: selectedRule.workdaysOnly,
    },
    ruleType: selectedRule.ruleType,
  };
}

function findAllRulesByPreviousTaskCompleteSource(rules: DueDateRuleDefinition[]): DueDateRuleDefinition[] {
  return rules.filter(rule => rule.sourceType === DueDateRuleSourceType.PreviousTaskCompletedDate);
}

function findAllRulesByFormFieldWidgetGroupId(
  rules: DueDateRuleDefinition[],
  formFieldWidgetGroupId: Muid,
): DueDateRuleDefinition[] {
  return rules.filter(
    rule =>
      rule.sourceType === DueDateRuleSourceType.FormFieldValue &&
      rule.formFieldWidgetGroup &&
      rule.formFieldWidgetGroup.id === formFieldWidgetGroupId,
  );
}

function withChecklistRule<T>(checklist: Checklist, rule: DueDateRuleDefinition, callback: () => T): T | undefined {
  if (rule.ruleType !== 'Checklist') {
    return;
  }

  if (checklist.dueDateOverridden) {
    logger.info(`Checklist has dueDateOverridden - skipping [${checklist.id}]`);
    return;
  }

  callback();
}

type WithTaskRuleFnParams = {
  groupId: Muid;
  targetTask: Task;
};

function withTaskRule<T>(
  tasksMap: Record<Muid, Task>,
  rule: DueDateRuleDefinition,
  callback: (params: WithTaskRuleFnParams) => T,
): T | undefined {
  if (rule.ruleType !== 'Task') {
    return;
  }

  const groupId = rule.targetTaskTemplateGroup?.id;
  if (!groupId) {
    logger.error(`Task rule is missing group id [${rule.id}]`);
    return;
  }

  const targetTask = tasksMap[groupId];
  if (!targetTask) {
    logger.error(`Could not find target task by task template group id [${groupId}]`);
  } else if (targetTask.dueDateOverridden) {
    logger.info(`Target task has dueDateOverridden - skipping [${groupId}]`);
  } else {
    callback({ groupId, targetTask });
  }
}

function findRuleForChecklist(rules: DueDateRuleDefinition[]): Option<DueDateRuleDefinition> {
  return rules.find(rule => rule.ruleType === DueDateRuleChecklistType);
}

function findRuleForTask(rules: DueDateRuleDefinition[], taskTemplate: TaskTemplate): Option<DueDateRuleDefinition> {
  return rules.find(rule => rule.targetTaskTemplateGroup?.id === taskTemplate.group.id);
}

export const DueDateHelper = {
  convertRuleToServiceFormat,
  findLastApplicableTask,
  getFirstOrderTree,
  getSelectedOrLastApplicableTask,
  isTaskBeforeExcludeHeading,
  normalizePeriodYears,
  findAllRulesByPreviousTaskCompleteSource,
  findAllRulesByFormFieldWidgetGroupId,
  findRuleForChecklist,
  findRuleForTask,
  withChecklistRule,
  withTaskRule,
};
