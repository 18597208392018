import { Muid, MuidUtils, Option, OrganizationMembership } from '@process-street/subgrade/core';
import { ChecklistColumn } from '@process-street/subgrade/dashboard/columns';
import { StateService } from '@uirouter/core';
import { MemberItem } from 'components/common/MemberOption';
import { SavedViewEditorMode } from 'components/dashboard/components/checklist/ChecklistDashboardSavedViews/SavedViewEditor';
import {
  allActiveChecklistsFiltering,
  allNotArchivedChecklistsFiltering,
  getNewSavedView,
  SavedView,
} from 'components/dashboard/models/saved-views';
import { GridHelper } from 'components/dashboard/services/grid-helper';
import { ChecklistDashboardSelectors } from 'components/dashboard/store/checklist-dashboard.selectors';
import deepEqual from 'deep-equal';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { createSelector } from 'reselect';
import { TemplateDto } from 'components/dashboard/models/filters';
import { createUsableContext } from '@process-street/subgrade/util';
import { TReportsTab } from 'utils/routing';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ToastService } from 'services/toast-service.interface';

export const mapStateToProps = createSelector(
  [
    SessionSelector.getSelectedOrganizationId,
    OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId,
    ChecklistDashboardSelectors.getSavedViews,
    ChecklistDashboardSelectors.savedViewsLoaded,
    OrganizationMembershipSelector.getAllAssignableBySelectedOrganizationId,
    SessionSelector.getSession,
  ],
  (organizationId, organizationMembership, savedViews, savedViewsLoaded, allOrganizationMemberships, session) => {
    const allMembers: MemberItem[] = GridHelper.convertOrganizationMembershipsWithUserToMemberItems(
      allOrganizationMemberships.filter(om => !om.guest), // excluding guests
    );

    const savedSavedViewId =
      organizationId &&
      session.checklistDashboard &&
      session.checklistDashboard.selectedSavedView &&
      session.checklistDashboard.selectedSavedView[organizationId];

    return {
      allMembers,
      allOrganizationMemberships,
      organizationId,
      organizationMembership,
      savedSavedViewId,
      savedViews,
      savedViewsLoaded,
    };
  },
);

export type ViewModifier = 'templates' | 'columns' | 'conditionalFilter';

export interface ChecklistScreenState {
  editedSavedView: Option<SavedView>; // copy of selected view or new view
  editorMode: SavedViewEditorMode;
  editorSavedView: Option<SavedView>; // view for independent editing from gear menu
  editorVisible: boolean;
  selectedSavedView: Option<SavedView>; // currently selected saved view
  viewInited: boolean;
  viewModified: boolean;
  viewModifiers: ViewModifier[];
  templates: TemplateDto[];
}

type ChecklistScreenAction =
  | {
      type: 'SetInitialView';
      meta: {
        savedSavedViewId: Option<Muid>;
        organizationMembership: OrganizationMembership;
        savedViews: SavedView[];
        tab: TReportsTab;
      };
    }
  | { type: 'SetSelectedView'; payload: SavedView }
  | { type: 'SetEditedView'; payload: SavedView }
  | { type: 'ShowEditorForGearEdit'; payload: SavedView }
  | { type: 'ShowEditorForSaveChanges' }
  | { type: 'ViewDeleted'; payload: SavedView }
  | { type: 'SetTemplates'; payload: TemplateDto[] }
  | { type: 'HideEditor' }
  | {
      type: 'ShowEditorForNew';
      meta: { organizationMembership: OrganizationMembership };
    };

const setSelectedView = (payload: SavedView): ChecklistScreenAction => ({
  payload,
  type: 'SetSelectedView',
});

const setEditedView = (payload: SavedView): ChecklistScreenAction => ({
  payload,
  type: 'SetEditedView',
});

const viewDeleted = (payload: SavedView): ChecklistScreenAction => ({
  payload,
  type: 'ViewDeleted',
});

const setInitialView = ({
  savedSavedViewId,
  organizationMembership,
  savedViews,
  tab,
}: {
  savedSavedViewId: Option<Muid>;
  organizationMembership: OrganizationMembership;
  savedViews: SavedView[];
  tab: TReportsTab;
}): ChecklistScreenAction => ({
  meta: {
    organizationMembership,
    savedSavedViewId,
    savedViews,
    tab,
  },
  type: 'SetInitialView',
});

const hideEditor = (): ChecklistScreenAction => ({
  type: 'HideEditor',
});

const showEditorForNew = (organizationMembership: OrganizationMembership): ChecklistScreenAction => ({
  meta: { organizationMembership },
  type: 'ShowEditorForNew',
});

const showEditorForGearEdit = (payload: SavedView): ChecklistScreenAction => ({
  payload,
  type: 'ShowEditorForGearEdit',
});

const showEditorForSaveChanges = (): ChecklistScreenAction => ({
  type: 'ShowEditorForSaveChanges',
});

const setTemplates = (templates: TemplateDto[]): ChecklistScreenAction => ({
  type: 'SetTemplates',
  payload: templates,
});

export enum DashboardRoute {
  Dashboard = 'reports',
  SavedViewById = 'reports.savedView',
}

export type ChecklistScreenReducerType = (
  state: ChecklistScreenState,
  action: ChecklistScreenAction,
) => ChecklistScreenState;

const getSavedViewDiff = (
  current: SavedView,
  draft: Option<SavedView>,
): { modified: boolean; viewModifiers: ViewModifier[] } => {
  const modified = !deepEqual(current, draft);

  const viewModifiers: Partial<Record<ViewModifier, boolean>> = modified
    ? {
        templates: !deepEqual(current.filters.selectedTemplates, draft?.filters.selectedTemplates),
        columns: !deepEqual(current.columnsConfig.visibleIds, draft?.columnsConfig.visibleIds),
        conditionalFilter: !deepEqual(current.filters.conditionalFilter, draft?.filters.conditionalFilter),
      }
    : {};
  return {
    modified,
    viewModifiers: Object.entries(viewModifiers)
      .filter(([, bool]) => bool)
      .map(([x]) => x as ViewModifier),
  };
};

export class ChecklistScreenReducer {
  constructor(private $state: StateService, private toastService: ToastService) {}

  public reducer: ChecklistScreenReducerType = (state: ChecklistScreenState, action: ChecklistScreenAction) => {
    switch (action.type) {
      case 'SetInitialView': {
        if (state.viewInited) {
          return state; // already inited
        }

        const { savedViewId, templateId, folderId } = this.$state.params;

        const { savedViews, savedSavedViewId, organizationMembership, tab } = action.meta;

        if (savedViewId) {
          const selectedSavedView = savedViews.find(view => view.id === savedViewId);
          if (selectedSavedView) {
            const editedSavedView = { ...selectedSavedView };
            return {
              ...state,
              selectedSavedView,
              editedSavedView,
              viewModified: false,
              viewInited: true,
              viewModifiers: [],
            };
          } else if (!state.selectedSavedView || savedViewId !== state.selectedSavedView.id) {
            window.setTimeout(() => {
              this.toastService.openToast({
                status: 'error',
                title: `You don't have access to this saved view`,
                description: DefaultErrorMessages.permissionErrorDescription('saved view'),
              });
            }, 500);

            this.$state.go(DashboardRoute.Dashboard);
          }
        } else if ((templateId && MuidUtils.isMuid(templateId)) || (folderId && MuidUtils.isMuid(folderId))) {
          const columnsConfig = { sorting: { sortColumn: ChecklistColumn.LastActiveDate, sortAsc: false } };
          const selectedTemplates: string[] = templateId
            ? [templateId]
            : state.templates.reduce<string[]>((acc, el) => {
                if (el.folderId === folderId) {
                  acc.push(el.id);
                }
                return acc;
              }, []);

          const filters = {
            ...(tab === 'table' ? allActiveChecklistsFiltering : allNotArchivedChecklistsFiltering),
            selectedTemplates,
          };

          const newSavedView = getNewSavedView(
            { organizationId: organizationMembership.organization.id, columnsConfig, filters },
            organizationMembership.user.id,
          );

          return {
            ...state,
            selectedSavedView: undefined,
            editedSavedView: newSavedView,
            viewModified: false,
            viewModifiers: [],
            viewInited: true,
          };
        } else {
          // check if selection still valid or select first
          const foundView =
            (state.selectedSavedView && savedViews.find(sv => sv.id === state.selectedSavedView!.id)) ||
            (savedSavedViewId && savedViews.find(sv => sv.id === savedSavedViewId)) ||
            (savedViews.length > 0 && savedViews[0]);

          if (foundView) {
            const editedSavedView = { ...foundView };

            this.$state.go(DashboardRoute.SavedViewById, { savedViewId: foundView.id });

            return {
              ...state,
              selectedSavedView: foundView,
              editedSavedView,
              viewModified: false,
              viewModifiers: [],
              viewInited: true,
            };
          } else {
            const editedSavedView = getNewSavedView(
              { organizationId: organizationMembership.organization.id },
              organizationMembership.user.id,
            );

            return {
              ...state,
              selectedSavedView: undefined,
              editedSavedView,
              viewModified: false,
              viewModifiers: [],
              viewInited: true,
            };
          }
        }
        return state;
      }
      case 'SetSelectedView': {
        const selectedSavedView = action.payload;
        const editedSavedView = { ...selectedSavedView };
        this.$state.go(DashboardRoute.SavedViewById, { savedViewId: selectedSavedView.id });

        return { ...state, selectedSavedView, editedSavedView, viewModified: false, viewModifiers: [] };
      }
      case 'SetEditedView': {
        const editedSavedView = action.payload;
        const { modified, viewModifiers } = getSavedViewDiff(editedSavedView, state.selectedSavedView);

        return {
          ...state,
          editedSavedView,
          viewModified: modified,
          viewModifiers,
        };
      }
      case 'HideEditor': {
        return { ...state, editorVisible: false };
      }
      case 'ShowEditorForNew': {
        const { organizationMembership } = action.meta;

        const editorSavedView = getNewSavedView(
          { organizationId: organizationMembership.organization.id },
          organizationMembership.user.id,
        );
        const editorMode = SavedViewEditorMode.NEW;

        return { ...state, editorSavedView, editorVisible: true, editorMode };
      }
      case 'ShowEditorForGearEdit': {
        const editorSavedView = action.payload;
        const editorMode = SavedViewEditorMode.EDIT;

        return { ...state, editorSavedView, editorVisible: true, editorMode };
      }
      case 'ShowEditorForSaveChanges': {
        const editorSavedView = state.editedSavedView;

        const editorMode = state.selectedSavedView ? SavedViewEditorMode.EDIT : SavedViewEditorMode.NEW;
        return { ...state, editorSavedView, editorVisible: true, editorMode };
      }
      case 'ViewDeleted': {
        const deletedSavedView = action.payload;

        if (state.selectedSavedView && state.selectedSavedView.id === deletedSavedView.id) {
          this.$state.go(DashboardRoute.Dashboard);
          return { ...state, selectedSavedView: undefined, viewModified: false, viewModifiers: [], viewInited: false };
        } else {
          return state;
        }
      }
      case 'SetTemplates': {
        return { ...state, templates: action.payload };
      }
      default:
        return state;
    }
  };
}

// TODO remove partial once prop drilling is mitigated
export const [useChecklistScreenState, ChecklistScreenContext] = createUsableContext<Partial<ChecklistScreenState>>({
  hookName: 'useChecklistScreenState',
  providerName: 'ChecklistScreenContext.Provider',
});

export const [useChecklistScreenDispatch, ChecklistScreenDispatch] = createUsableContext<
  React.Dispatch<ChecklistScreenAction>
>({
  hookName: 'useChecklistScreenDispatch',
  providerName: 'ChecklistScreenDispatchProvider',
});

export const ChecklistScreenActions = {
  hideEditor,
  setEditedView,
  setInitialView,
  setSelectedView,
  setTemplates,
  showEditorForGearEdit,
  showEditorForNew,
  showEditorForSaveChanges,
  viewDeleted,
};
