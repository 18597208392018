import { SavedViewPermit } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type DeleteSavedViewPermissionMutationParams = {
  dataSetId: string;
  savedViewId: string;
  permitId: string;
};

export type DeleteSavedViewPermissionMutationResponse = SavedViewPermit;

export const DeleteSavedViewPermitMutation = {
  mutationFn: (params: DeleteSavedViewPermissionMutationParams) =>
    axiosService
      .getAxios()
      .delete<DeleteSavedViewPermissionMutationResponse>(
        `/1/data-sets/${params.dataSetId}/saved-views/${params.savedViewId}/permits/${params.permitId}`,
      )
      .then(res => res.data),
};

export const useDeleteSavedViewPermitMutation = (
  options: UseMutationOptions<
    DeleteSavedViewPermissionMutationResponse,
    AxiosError,
    DeleteSavedViewPermissionMutationParams
  >,
) => {
  return useMutation(DeleteSavedViewPermitMutation.mutationFn, options);
};
