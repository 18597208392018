import { createSelector, Selector } from 'reselect';
import { Muid, Option } from '../../core';
import { TaskTemplate } from '../../process';
import { toEntityMap } from '../reducer-utils';
import { safeEntityMapToArrayByIdsWith } from '../safe-entity-map-to-array-by-ids';
import { BaseReduxState, EntityMap } from '../types';

const getEntityMap: Selector<BaseReduxState, EntityMap<TaskTemplate>> = (
  state: BaseReduxState,
): EntityMap<TaskTemplate> => state.entities.taskTemplates;

const getById =
  (id: Muid): Selector<BaseReduxState, Option<TaskTemplate>> =>
  (state: BaseReduxState): Option<TaskTemplate> =>
    state.entities.taskTemplates[id];

const getAllIdsByTemplateRevisionId =
  (revisionId: Muid): Selector<BaseReduxState, Muid[]> =>
  (state: BaseReduxState): Muid[] =>
    state.lookups.taskTemplates.byTemplateRevisionId[revisionId] ?? [];

const getAllByTemplateRevisionId = (templateRevisionId: Muid): Selector<BaseReduxState, TaskTemplate[]> =>
  createSelector(getAllIdsByTemplateRevisionId(templateRevisionId), getEntityMap, (taskTemplateIds, taskTempalteMap) =>
    safeEntityMapToArrayByIdsWith(taskTempalteMap, taskTemplateIds),
  );

const getEntityMapByTemplateRevisionId = (
  templateRevisionId: Muid,
): Selector<BaseReduxState, EntityMap<TaskTemplate>> =>
  createSelector(getAllByTemplateRevisionId(templateRevisionId), taskTemplates => toEntityMap(taskTemplates));

export const BaseTaskTemplateSelector = {
  getAllByTemplateRevisionId,
  getAllIdsByTemplateRevisionId,
  getById,
  getEntityMap,
  getEntityMapByTemplateRevisionId,
};
