import * as React from 'react';
import { VStack, HStack, ListItem, Avatar, Text, IconButton, Spacer } from '@chakra-ui/react';
import { Muid, User } from '@process-street/subgrade/core';
import { Icon } from 'components/design/next';
import { useQueryClient } from 'react-query';
import {
  GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery,
  GetTemplateTaskAssignmentsByTemplateRevisionIdResult,
  UnassignAllTaskTemplatesMutation,
} from 'features/task-template-assignment/query-builder';
import { getAvatar } from 'components/common/Avatar';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';

interface TaskAssignmentUserProps {
  user: User & { organizationMembershipId: Muid; assignmentId: string };
  taskTemplatesIds: Array<TaskTemplate['id']>;
  templateRevisionId: TemplateRevision['id'];
  isReadOnly?: boolean;
  isEditorV2?: boolean;
}

export const TaskAssignmentUser: React.FC<React.PropsWithChildren<TaskAssignmentUserProps>> = ({
  user,
  taskTemplatesIds,
  templateRevisionId,
  isReadOnly = false,
  isEditorV2 = false,
}) => {
  const queryClient = useQueryClient();
  const unassignMutation = UnassignAllTaskTemplatesMutation.useMutation({
    mutationKey: UnassignAllTaskTemplatesMutation.key,
    onMutate: () => {
      return { assigneeLabel: user.username };
    },
    onSuccess: () => {
      queryClient.setQueryData<GetTemplateTaskAssignmentsByTemplateRevisionIdResult>(
        GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
        current => {
          if (!current) return [];
          const taskTemplatesIdsSet = new Set(taskTemplatesIds);
          return current.filter(assignment => {
            // Keep the assignments that do not belong to the user and are not in the specified task templates
            const isDifferentUser = assignment.organizationMembership.id !== user.organizationMembershipId;
            const isNotInTaskTemplates = !taskTemplatesIdsSet.has(assignment.taskTemplate.id);
            return isDifferentUser || isNotInTaskTemplates;
          });
        },
      );
    },
  });

  const removeRule = () => unassignMutation.mutate({ email: user.email, taskTemplatesIds });
  const avatar = getAvatar(user);

  return (
    <HStack as={ListItem} w="full">
      <Avatar src={avatar.url} name={user.username} size={isEditorV2 ? 'xs' : 'inherit'} />
      <VStack alignItems="flex-start" w="full" spacing="px">
        <Text variant="-1" fontWeight="medium" fontSize={isEditorV2 ? 'xs' : 'inherit'}>
          {user.username}
        </Text>
        <Text variant="-2" color="gray.400" fontSize={isEditorV2 ? 'xs' : 'inherit'}>
          {isGroupUser(user) ? 'Group' : user.email}
        </Text>
      </VStack>
      <Spacer />
      {!isReadOnly && (
        <IconButton
          aria-label="Remove assignment"
          icon={<Icon icon="user-xmark" size="4" />}
          onClick={removeRule}
          variant="ghost"
          size="sm"
          color="gray.500"
          colorScheme="gray"
        />
      )}
    </HStack>
  );
};
