import { FieldType, WidgetType } from '@process-street/subgrade/process';
import { noop } from '@process-street/subgrade/util';
import angular from 'angular';
import { match } from 'ts-pattern';
import templateUrl from './template-widget.component.html';
import './template-widget.scss';

angular.module('frontStreetApp.directives').component('psTemplateWidget', {
  bindings: {
    widget: '<',
    templateRevision: '<',
    mergeTagsEnabled: '<',
    organization: '<',
    disabled: '<',
    onUpdate: '&',
  },
  templateUrl,
  controller() {
    const ctrl = this;

    ctrl.updateWidget = function (widget) {
      // React components lose reference to bound objects and therefore do not start the AngularJS digest cycle
      match(widget)
        .with({ header: { type: WidgetType.Embed } }, () => {
          ctrl.widget.url = widget.url;
        })
        .with({ header: { type: WidgetType.CrossLink } }, () => {
          ctrl.widget.templateId = widget.templateId;
        })
        .with({ fieldType: FieldType.Number }, () => {
          Object.assign(ctrl.widget, widget);
        })
        .otherwise(noop);
      ctrl.onUpdate({ widget });
    };
  },
});
