import angular from 'angular';

angular.module('frontStreetApp.services').factory('TemplateSubscriptions', function (ResourceFactory) {
  return ResourceFactory.create({
    query: 'GET[] /1/template-subscriptions',
    counts: 'GET /1/template-subscriptions/counts',
    create: 'POST /1/template-subscriptions',
    get: 'GET /1/template-subscriptions/:id',
    update: 'PATCH /1/template-subscriptions/:id',
    delete: 'DELETE /1/template-subscriptions/:id',
  });
});
