import { Muid, Option } from '@process-street/subgrade/core';
import { Webhook, WebhookType } from '@process-street/subgrade/process';
import { WebhookActions } from 'components/organization-manage/webhooks/store/webhooks.actions';
import { webhooksSelector } from 'components/organization-manage/webhooks/store/webhooks.selector';
import React from 'react';
import { connect } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { ReduxAppState } from 'reducers/types';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { WebhookList } from '../WebhookList';
import { AddWebhookButton } from '../AddWebhookButton';
import { Divider, Text } from 'components/design/next';

interface StateToProps {
  userId: Option<Muid>;
  webhooks: Option<Webhook[]>;
}

interface DispatchFromProps {
  addEmptyWebhook: (id: Muid, webhookType: WebhookType) => void;
  fetchWebhooks: () => void;
  onWebhookUpdate: (webhook: Webhook) => void;
  onWebhookDelete: (id: Muid) => void;
}

export interface WebhooksManagerProps extends StateToProps, DispatchFromProps {}

const MaximumNumberOfWebhooks = 50;

export class PureWebhooksManager extends React.PureComponent<WebhooksManagerProps> {
  constructor(props: WebhooksManagerProps) {
    super(props);
  }

  public componentDidMount(): void {
    if (this.props.userId) {
      this.props.fetchWebhooks();
    }
  }

  public render() {
    const { userId } = this.props;
    if (!userId) {
      return null;
    }

    const userDefinedWebhooks = (this.props.webhooks ?? []).filter(
      webhook => !['PowerAutomate', 'Tray'].includes(webhook.integrationType),
    );

    const isAddButtonDisabled = userDefinedWebhooks.length >= MaximumNumberOfWebhooks;
    const buttonTooltip = isAddButtonDisabled ? 'Maximum number of webhooks reached.' : '';

    return (
      <div>
        <Text as="h5" color="gray.500" variant="-2u" mt={6} mb={4}>
          Webhooks&nbsp;
          <a href="https://www.process.st/help/docs/webhooks/" target="_blank" rel="noopener noreferrer">
            <i className="far fa-question-circle" />
          </a>
        </Text>
        <Divider />

        <div className="row item mt-2">
          <div className="col-sm-8 details">
            <p>You can use webhooks to notify an application when an event happens in your organization.</p>
          </div>
          <div className="col-sm-3 col-sm-offset-1 action">
            <AddWebhookButton disabled={isAddButtonDisabled} tooltip={buttonTooltip} onClick={this.addWebhook} />
          </div>
        </div>
        <WebhookList
          webhooks={this.props.webhooks}
          onWebhookUpdate={this.props.onWebhookUpdate}
          onWebhookDelete={this.props.onWebhookDelete}
        />
      </div>
    );
  }

  public addWebhook = (webhookType: WebhookType) => {
    if (this.props.userId) {
      this.props.addEmptyWebhook(this.props.userId, webhookType);
    }
  };
}

const mapStateToProps = (state: ReduxAppState): StateToProps =>
  createSelector([SessionSelector.getCurrentUserId, webhooksSelector], (userId, webhooks) => {
    return {
      userId,
      webhooks,
    };
  })(state);

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>,
): DispatchFromProps => ({
  addEmptyWebhook: (userId: Muid, webhookType: WebhookType) =>
    dispatch(WebhookActions.addEmptyWebhook(userId, webhookType)),
  fetchWebhooks: () => dispatch(WebhookActions.fetchWebhooks()),
  onWebhookDelete: (id: Muid) => dispatch(WebhookActions.deleteWebhook(id)),
  onWebhookUpdate: (webhook: Webhook) => dispatch(WebhookActions.updateWebhook(webhook)),
});

export const WebhooksManager = connect<StateToProps, DispatchFromProps, Record<string, unknown>, ReduxAppState>(
  mapStateToProps,
  mapDispatchToProps,
)(PureWebhooksManager);
