import { Comment } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type DeleteCommentMutationParams = { commentId: Comment['id'] };

export type DeleteCommentMutationResponse = Comment;

export const DeleteCommentMutation = {
  key: ['delete', 'comment'],
  mutationFn: (params: DeleteCommentMutationParams) =>
    axiosService
      .getAxios()
      .delete<DeleteCommentMutationResponse>(`/1/comments/${params.commentId}`)
      .then(res => res.data),
};

export const useDeleteCommentMutation = (
  options: UseMutationOptions<DeleteCommentMutationResponse, AxiosError, DeleteCommentMutationParams> = {},
) => {
  return useMutation(DeleteCommentMutation.mutationFn, options);
};
