import { FieldType, FieldValueJson, FormFieldWidget, Widget } from '../widget-model';
import { isEmailWidget, isFormFieldWidget, isImageWidget, isVideoWidget } from '../widget-utils';
import { getNumberFormFieldWidgetErrorTypes, NumberFormFieldValidation } from './number-form-field';
import { getTextFormFieldWidgetErrorType, TextFormFieldValidation } from './text-form-field';
import { getFileFormFieldWidgetErrorType, FileFormFieldValidation } from './file-form-field';
import { getEmailFormFieldWidgetErrorType, EmailFormFieldValidation } from './email-form-field';
import { match, P } from 'ts-pattern';
import { getTextareaFormFieldWidgetErrorType, TextareaFormFieldValidation } from './textarea-form-field';
import { getDateFormFieldWidgetErrorType, DateFormFieldValidation } from './date-form-field';

const isValidWidget = (widget: Widget): boolean => {
  if (isEmailWidget(widget)) {
    const anyDefinedField = widget.recipient || widget.cc || widget.bcc || widget.subject || widget.body;
    return anyDefinedField !== undefined && anyDefinedField !== '';
  } else if (isImageWidget(widget)) {
    return widget.file !== undefined;
  } else if (isVideoWidget(widget)) {
    const fileDefined = widget.file !== undefined;
    const serviceDefined = widget.serviceCode !== undefined && widget.serviceCode !== '';
    return fileDefined || serviceDefined;
  }
  return true;
};

function validationCIF<
  Key extends string,
  Code extends string,
  T extends Partial<
    Record<
      FieldType,
      Record<Key, { code: Code; isInvalid: (constraints: Record<string, unknown>, value: any) => boolean }>
    >
  >,
>(obj: T): T {
  return obj;
}

export const FormFieldValidation = validationCIF({
  [FieldType.Text]: TextFormFieldValidation,
  [FieldType.Textarea]: TextareaFormFieldValidation,
  [FieldType.Number]: NumberFormFieldValidation,
  [FieldType.File]: FileFormFieldValidation,
  [FieldType.Email]: EmailFormFieldValidation,
  [FieldType.Date]: DateFormFieldValidation,
});

// TODO: not all the value types will be strings so we should find a better approach for this.
const getFormFieldWidgetErrorType = (widget: FormFieldWidget, value: string) => {
  return match(widget)
    .with({ fieldType: FieldType.Text }, w => getTextFormFieldWidgetErrorType(w.constraints, value))
    .with({ fieldType: FieldType.Textarea }, w => getTextareaFormFieldWidgetErrorType(w.constraints, value))
    .with({ fieldType: FieldType.Email }, w => getEmailFormFieldWidgetErrorType(w.constraints, value))
    .with({ fieldType: FieldType.File }, w => getFileFormFieldWidgetErrorType(w.constraints, value))
    .otherwise(() => undefined);
};

const hasConstraints = (widget: FormFieldWidget) => {
  return widget.constraints && Object.keys(widget.constraints).length > 0;
};

const hasConstraintValidation = (widget: Widget) =>
  isFormFieldWidget(widget) && widget.fieldType in FormFieldValidation;

const widgetValue = (widget: FormFieldWidget, fieldValue?: FieldValueJson): string => {
  return match({ fieldType: widget.fieldType, fieldValue })
    .with({ fieldValue: P.nullish }, () => '')
    .with(
      {
        fieldType: P.union(FieldType.Text, FieldType.Textarea, FieldType.Email),
        fieldValue: { value: P.string },
      },
      ({ fieldValue: { value } }) => value,
    )
    .with({ fieldType: FieldType.File, fieldValue: { url: P.string } }, ({ fieldValue: { url } }) => url)
    .with(
      {
        fieldType: P.union(FieldType.Text, FieldType.Textarea, FieldType.File, FieldType.Email),
        fieldValue: {},
      },
      () => '',
    )
    .otherwise(() => {
      throw new Error(`Unable to unpack value for widget.fieldType ${widget.fieldType}\n${JSON.stringify(fieldValue)}`);
    });
};

export const hasFailedConstraints = (widget: FormFieldWidget, fieldValue?: FieldValueJson): boolean => {
  const val = widgetValue(widget, fieldValue);
  return WidgetValidation.getFormFieldWidgetErrorType(widget, val) !== undefined;
};

export const WidgetValidation = {
  isValidWidget,
  getTextFormFieldWidgetErrorType,
  getTextareaFormFieldWidgetErrorType,
  getEmailFormFieldWidgetErrorType,
  getNumberFormFieldWidgetErrorTypes,
  getFileFormFieldWidgetErrorType,
  getDateFormFieldWidgetErrorType,
  getFormFieldWidgetErrorType,
  hasConstraints,
  hasConstraintValidation,
  hasFailedConstraints,
};

export * from './date-form-field';
export * from './email-form-field';
export * from './file-form-field';
export * from './number-form-field';
export * from './text-form-field';
