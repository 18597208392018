import * as React from 'react';
import { Checkbox, FormControl, FormLabel, HStack, Icon, Switch, Text, Tooltip, VStack } from 'components/design/next';
import { BlvdSelect, BlvdSelectHelpers } from 'components/design/BlvdSelect';
import { Checklist, Template, TemplateType } from '@process-street/subgrade/process';
import { OnChangeValue } from 'react-select';
import { SimpleOption } from 'components/design/BlvdSelect/types';
import { GetTemplatesByOrganizationQuery } from 'features/template/query-builder';
import { Option } from '@process-street/subgrade/core';
import { useGetFormResponsesSearchQuery } from 'features/forms/query-builder';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { ChecklistColumn, ConditionalFilterUtils } from '@process-street/subgrade/dashboard';

export type WorkflowRunLinkProps = {
  wfrLinkEnabled: boolean;
  isRequired: boolean;
  onWfrLinkEnabledChange: (enabled: boolean) => void;
  selectedWorkflowId?: Template['id'];
  selectedWorkflowRunId?: Checklist['id'];
  onWorkflowIdChange: (id: Template['id']) => void;
  onWorkflowRunIdChange: (id: Checklist['id']) => void;
  onRequiredChange: (required: boolean) => void;
  autoFocus?: boolean;
  disableWfWfrLink?: boolean;
};

export const WorkflowRunLink: React.FC<React.PropsWithChildren<WorkflowRunLinkProps>> = ({
  wfrLinkEnabled,
  isRequired,
  onWfrLinkEnabledChange,
  selectedWorkflowId,
  selectedWorkflowRunId,
  onWorkflowIdChange,
  onWorkflowRunIdChange,
  onRequiredChange,
  autoFocus,
  disableWfWfrLink,
}) => {
  const selectedOrganization = useSelectedOrganization();

  const templatesQuery = GetTemplatesByOrganizationQuery.useQuery(
    { organizationId: selectedOrganization?.id },
    { enabled: wfrLinkEnabled && Boolean(selectedOrganization) },
  );
  const templateOptions: SimpleOption[] = (templatesQuery.data ?? []).map(templateDto => ({
    value: templateDto.id,
    label: templateDto.name,
  }));

  const selectedTemplate: Option<SimpleOption> = templateOptions.find(opt => opt.value === selectedWorkflowId);

  const checklistsQuery = useGetFormResponsesSearchQuery(
    {
      organizationId: selectedOrganization?.id,
      searchCriteria: {
        templateIds: selectedWorkflowId ? [selectedWorkflowId] : [],
        templateTypes: [TemplateType.Playbook],
        pageSize: 50,
        sortBy: ChecklistColumn.LastActiveDate,
        sortAsc: false,
        filter: [ConditionalFilterUtils.initialConditionalFilterActiveWorkflowRuns],
      },
    },
    { enabled: wfrLinkEnabled && Boolean(selectedOrganization) && Boolean(selectedTemplate) },
  );

  const checklistOptions: SimpleOption[] = (checklistsQuery.data ?? []).map(row => ({
    value: row.id,
    label: row.checklistName ?? '<unnamed workflow run>',
  }));

  const selectedChecklist: Option<SimpleOption> = checklistOptions.find(opt => opt.value === selectedWorkflowRunId);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    onWfrLinkEnabledChange(checked);
  };

  const handleWorkflowIdChange = (option: OnChangeValue<SimpleOption, false>) => {
    if (BlvdSelectHelpers.isOptionType<SimpleOption>(option)) {
      onWorkflowIdChange(option.value);
    }
  };

  const handleWorkflowRunIdChange = (option: OnChangeValue<SimpleOption, false>) => {
    if (BlvdSelectHelpers.isOptionType<SimpleOption>(option)) {
      onWorkflowRunIdChange(option.value);
    }
  };

  const handleOnRequiredChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onRequiredChange(e.target.checked);
  };

  return (
    <VStack width="full" alignItems="start" spacing="5">
      <Switch
        size="lg"
        display="flex"
        alignItems="center"
        spacing="2"
        isChecked={wfrLinkEnabled}
        isDisabled={disableWfWfrLink}
        onChange={handleSwitchChange}
      >
        <Text as="span">Link to a Workflow Run</Text>
      </Switch>

      {wfrLinkEnabled && (
        <VStack
          width="full"
          alignItems="start"
          sx={{
            '.blvd-select__single-value': {
              fontSize: 'var(--ps-fontSizes-md) !important',
            },
          }}
        >
          <HStack>
            <Icon icon="workflow" variant="far" size="3" color="gray.500" />
            <Text>Workflow</Text>
          </HStack>
          <BlvdSelect
            autoFocus={autoFocus}
            options={templateOptions}
            isSearchable
            placeholder="Select Workflow"
            fixedSize
            menuControls
            styles={{
              singleValue: ({ ...css }) => ({ ...css, fontSize: 'var(--ps-fontSizes-md) !important' }),
              control: ({ ...css }) => ({ ...css, fontSize: 'var(--ps-fontSizes-md) !important' }),
              container: ({ ...css }) => ({ ...css, fontSize: 'var(--ps-fontSizes-md) !important', width: '100%' }),
              placeholder: ({ ...css }) => ({ ...css, fontSize: 'var(--ps-fontSizes-md) !important' }),
              option: ({ ...css }) => ({ ...css, fontSize: 'var(--ps-fontSizes-md) !important' }),
            }}
            onChange={handleWorkflowIdChange}
            value={selectedTemplate}
            isDisabled={disableWfWfrLink}
            enableSearchAutoFocus
          />
        </VStack>
      )}

      {wfrLinkEnabled && selectedTemplate && (
        <>
          <VStack
            width="full"
            alignItems="start"
            sx={{
              '.blvd-select__single-value': {
                fontSize: 'var(--ps-fontSizes-md) !important',
              },
            }}
          >
            <HStack>
              <Icon icon="play" variant="far" size="3" color="gray.500" />
              <Text>Workflow Run</Text>
            </HStack>
            <BlvdSelect
              options={checklistOptions}
              isSearchable
              placeholder="Select Workflow Run"
              fixedSize
              menuControls
              styles={{
                container: ({ ...css }) => ({ ...css, width: '100%' }),
                placeholder: ({ ...css }) => ({ ...css, fontSize: 'var(--ps-fontSizes-md) !important' }),
                option: ({ ...css }) => ({ ...css, fontSize: 'var(--ps-fontSizes-md) !important' }),
              }}
              onChange={handleWorkflowRunIdChange}
              value={selectedChecklist}
              isDisabled={disableWfWfrLink}
              enableSearchAutoFocus
            />
          </VStack>
          <FormControl>
            <HStack alignItems="center">
              <Checkbox onChange={handleOnRequiredChange} isChecked={isRequired}>
                <FormLabel aria-label="Required" m="0">
                  Required
                </FormLabel>
              </Checkbox>
              <Tooltip
                shouldWrapChildren
                hasArrow
                label="This task must be completed before the linked workflow run can be completed"
              >
                <Icon icon="info-circle" variant="far" size="4" color="gray.400" />
              </Tooltip>
            </HStack>
          </FormControl>
        </>
      )}
    </VStack>
  );
};
