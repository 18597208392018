/**
 * Simple utility, that instead of binding state directly to the controller,
 * binds it to state variable in that controller.
 */
export const bindStateToStateProperty = selector => {
  if (selector === null || selector === undefined) {
    return null;
  } else {
    return reduxState => ({ state: selector(reduxState) });
  }
};
