import * as React from 'react';
import { ButtonProps } from 'components/design/next';
import { TemplateType } from '@process-street/subgrade/process';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useTemplateMenuContext, useTemplateMenuDisclosureContext } from '../template-menu';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { ButtonWithUpgradeTooltip } from 'components/button-with-upgrade-tooltip';
import { match, P } from 'ts-pattern';

export interface MoveTemplateButtonProps {
  children: React.ReactElement<ButtonProps>;
}

export const MoveTemplateButton: React.FC<React.PropsWithChildren<MoveTemplateButtonProps>> = React.memo(
  ({ children }) => {
    const { templateId } = useTemplateMenuContext();
    const { moveTemplateModalDisclosure: disclosure } = useTemplateMenuDisclosureContext();

    const { data: template } = useGetTemplateQuery({ templateId });

    const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId, {
      enabled: !!templateId,
    });

    const canMoveTemplate = match({ template, permissionMap })
      .with(
        {
          template: { templateType: P.union(TemplateType.Playbook, TemplateType.Form) },
          permissionMap: { templateUpdate: true },
        },
        () => true,
      )
      .with(
        {
          template: { templateType: TemplateType.Page },
          permissionMap: { pageUpdate: true },
        },
        () => true,
      )
      .otherwise(() => false);

    if (!React.isValidElement(children) || Array.isArray(children)) {
      throw new Error('MoveTemplateButton child must be a single clickable element');
    }

    const isFree = useIsCurrentUserFreeMember();
    if (isFree) return <ButtonWithUpgradeTooltip>{children}</ButtonWithUpgradeTooltip>;

    const btn = React.cloneElement(children, {
      onClick: () => {
        disclosure.onToggle();
      },
    });

    return canMoveTemplate ? <>{btn}</> : null;
  },
);
