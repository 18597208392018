import { NotificationConfig, NotificationConfigPropertyKey } from '@process-street/subgrade/core';
import {
  OrganizationNotificationConfig,
  OrganizationNotificationConfigKeys,
} from '@process-street/subgrade/core/organization-notifications/organization-notification-config';
import { FormControl, FormLabel, HStack, Switch, Tooltip } from 'components/design/next';
import React from 'react';

interface ConfigItemProps {
  id: NotificationConfigPropertyKey;
  organizationConfigKey?: OrganizationNotificationConfigKeys;
  label: string;
  notificationConfig: NotificationConfig;
  organizationNotificationConfig?: OrganizationNotificationConfig;
  onChange: (id: NotificationConfigPropertyKey, e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ConfigItem: React.FunctionComponent<React.PropsWithChildren<ConfigItemProps>> = ({
  id,
  label,
  organizationConfigKey,
  notificationConfig,
  organizationNotificationConfig,
  onChange,
}) => {
  const { emailsAllowed } = notificationConfig;

  const switchIsEmailsAllowed = id === 'emailsAllowed';
  const checked = emailsAllowed && (notificationConfig[id] || false);
  const isChecked = switchIsEmailsAllowed ? !checked : checked;

  const disabledByOrgConfig =
    organizationConfigKey &&
    organizationNotificationConfig &&
    (organizationNotificationConfig[organizationConfigKey] === false ||
      organizationNotificationConfig['emailsAllowed'] === false);

  // Show tooltip only first time for new users
  const showTooltip =
    disabledByOrgConfig &&
    !isChecked &&
    notificationConfig?.audit &&
    notificationConfig.audit.createdDate === notificationConfig.audit.updatedDate;

  return (
    <FormControl w="full" key={id}>
      <HStack justifyContent="space-between">
        <FormLabel htmlFor={id} color="gray.500">
          {label}
        </FormLabel>

        {showTooltip ? (
          <>
            <Tooltip
              w="100%"
              label={`This has been switched ${organizationConfigKey === 'emailsAllowed' ? 'on' : 'off'} by an admin`}
              hasArrow
              shouldWrapChildren
            >
              <Switch size="lg" isChecked={isChecked} id={id} onChange={e => onChange(id, e)} />
            </Tooltip>
          </>
        ) : (
          <Switch size="lg" isChecked={isChecked} id={id} onChange={e => onChange(id, e)} />
        )}
      </HStack>
    </FormControl>
  );
};
