import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from 'components/design/next';

export type DeleteTaskConfirmationModal = {
  handleDeleteTasks: () => void;
  onClose: () => void;
};
const confirmationMessage = 'These tasks and all of their content will be deleted and irrecoverable!';

export const DeleteTaskConfirmationModal = ({ handleDeleteTasks, onClose }: DeleteTaskConfirmationModal) => {
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  return (
    <AlertDialog isOpen onClose={onClose} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete selected tasks?
          </AlertDialogHeader>
          <AlertDialogBody>{confirmationMessage}</AlertDialogBody>
          <AlertDialogFooter>
            <Button variant="secondary" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleDeleteTasks();
                onClose();
              }}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
