import * as React from 'react';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverBody,
  Portal,
  PopoverTrigger,
  Input,
  Text,
  Divider,
  VStack,
  Icon,
} from 'components/design/next';
import {
  CreateLabelRelationshipMutation,
  CreateLabelWithRelationshipMutation,
  GetLabelsByOrganizationQuery,
} from 'features/checklist-grid/query-builder';
import { Checklist, Label } from '@process-street/subgrade/process';
import { LabelItem } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers/LabelRenderer/LabelItem';
import { useDashboardGridContextStore } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/ChecklistDashboardGridContextStore';
import { ChecklistColumn } from '@process-street/subgrade/dashboard';
import { queryClient } from 'components/react-root';
import { RowValues } from 'components/dashboard/models/grid';
import { DeleteLabelRelationshipByChecklistIdMutation } from 'features/checklist-grid/query-builder/delete-label-relationship-by-checklist-id-mutation';

export type LabelSelectorProps = {
  checklistId: Checklist['id'];
  labelId?: Label['id'];
  rowValues?: RowValues;
  isOpen: boolean;
  onClose: () => void;
};

export const LABEL_COLORS = [
  '#A0CCF0',
  '#AEE4D2',
  '#F1B1BC',
  '#FFEFBF',
  '#FFE4C6',
  '#E9D3BA',
  '#C5CAFF',
  '#DAD1F9',
  '#B9EBEE',
  '#B9EBEE',
  '#B9EBEE',
  '#DEE4E8',
].map(l => l.replace('#', ''));

export const LabelSelector: React.FC<React.PropsWithChildren<LabelSelectorProps>> = ({
  isOpen,
  onClose,
  checklistId,
  labelId,
  rowValues,
}) => {
  const [filter, setFilter] = React.useState('');
  const createRelationshipMutation = CreateLabelRelationshipMutation.useMutation();
  const createLabelWithRelationshipMutation = CreateLabelWithRelationshipMutation.useMutation();
  const deleteRelationshipByChecklistIdMutation = DeleteLabelRelationshipByChecklistIdMutation.useMutation();
  const gridContextStore = useDashboardGridContextStore();

  React.useEffect(() => {
    if (isOpen) {
      setFilter('');
    }
  }, [isOpen]);

  const labelsQuery = GetLabelsByOrganizationQuery.useQuery();
  if (!labelsQuery.data) {
    return null;
  }

  const handleLabelSelection = (label: Label) => {
    createRelationshipMutation.mutateAsync({ checklistId, labelId: label.id }).then(() => {
      refreshLabelCell(label.id);
      onClose();
    });
  };

  const refreshLabelCell = (labelId?: Label['id']) => {
    if (rowValues) {
      rowValues[ChecklistColumn.ChecklistLabelId] = {
        checklistId,
        labelId,
      };
      gridContextStore.gridApi?.refreshCells();
    }
  };

  const handleLabelCreation = () => {
    const color = LABEL_COLORS[labelsQuery.data.length % LABEL_COLORS.length];

    createLabelWithRelationshipMutation.mutateAsync({ checklistId, name: filter, color }).then(res => {
      refreshLabelCell(res.labelRelationship.labelId);
      queryClient.invalidateQueries(GetLabelsByOrganizationQuery.getKey({}));
      labelsQuery.refetch();
      onClose();
    });
  };

  const handleLabelDeletion = () => {
    deleteRelationshipByChecklistIdMutation.mutateAsync({ checklistId }).then(() => {
      refreshLabelCell(undefined);
    });
  };

  const labels = labelsQuery.data.filter(label => label.name.toLowerCase().includes(filter.toLowerCase()));
  const currentLabel = labelsQuery.data.find(label => label.id === labelId);

  return (
    <Popover isLazy placement="right-start" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <span />
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="sm" maxH="xl" overflowY="auto">
          <PopoverBody as={VStack} alignItems="start">
            {currentLabel && (
              <LabelItem key={currentLabel.id} label={currentLabel} onDelete={handleLabelDeletion} maxW="358px" />
            )}
            <Input placeholder="Search or create labels..." value={filter} onChange={e => setFilter(e.target.value)} />
            <Divider />
            <VStack gap="4px" alignItems="flex-start">
              {labels.map(label => (
                <LabelItem key={label.id} label={label} onClick={handleLabelSelection} maxW="358px" />
              ))}

              {labels.length === 0 && (
                <Button
                  variant="link"
                  colorScheme="gray"
                  leftIcon={<Icon icon="plus" size="4" />}
                  onClick={handleLabelCreation}
                >
                  <Text fontSize="sm">Create as a new label</Text>
                </Button>
              )}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
