import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { AutomationsModalWrapper } from './modal-wrapper';

export const AutomationsModalWrapperModule = angular
  .module('AutomationsModalWrapper', [])
  .component(
    'psAutomationsModalWrapper',
    reactToAngular(AutomationsModalWrapper, ['templateRevision', 'templateType']),
  ).name;
