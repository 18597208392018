import { schema } from '@process-street/normalizr';
import { AuditMetadata as IAuditMetadata } from '../../core';

const AuditMetadata = new schema.Object({});
// the index.d.ts is off for normalizr
// @ts-expect-error -- TODO
AuditMetadata.denormalize = (input: IAuditMetadata) => input;

const Organization = new schema.Entity('organizations', {
  audit: AuditMetadata,
});

const User = new schema.Entity('users', {
  audit: AuditMetadata,
});

AuditMetadata.define({ createdBy: User, updatedBy: User });

const OrganizationMembership = new schema.Entity('organizationMemberships', {
  organization: Organization,
  user: User,
});

const Plan = new schema.Entity('plans', {
  audit: AuditMetadata,
});

const Group = new schema.Entity('groups', {
  organization: Organization,
  user: User,
});

const GroupMembership = new schema.Entity('groupMemberships', {
  organization: Organization,
  group: Group,
  organizationMembership: OrganizationMembership,
});

const Folder = new schema.Entity('folders', {
  audit: AuditMetadata,
  deletedBy: User,
  organization: Organization,
});

Folder.define({ parentFolder: Folder });

const FolderPermit = new schema.Entity('folderPermits', {
  audit: AuditMetadata,
  organization: Organization,
  folder: Folder,
  organizationMembership: OrganizationMembership,
});

const Template = new schema.Entity('templates', {
  audit: AuditMetadata,
  organization: Organization,
  folder: Folder,
});

const TemplatePermit = new schema.Entity('templatePermits', {
  audit: AuditMetadata,
  organization: Organization,
  template: Template,
  organizationMembership: OrganizationMembership,
});

const TemplateRevision = new schema.Entity('templateRevision', {
  audit: AuditMetadata,
  organization: Organization,
  template: Template,
});

const UserInfo = new schema.Object({
  organizationMembership: OrganizationMembership,
  selectedOrganization: Organization,
  selectedPlan: Plan,
  user: User,
});

export const Schemas = {
  AuditMetadata,
  Folder,
  FolderPermit,
  Group,
  GroupMembership,
  Organization,
  OrganizationMembership,
  Plan,
  Template,
  TemplatePermit,
  TemplateRevision,
  User,
  UserInfo,
};
