import { EmailContent, EmailContentActorRef, makeEmailContentMachine } from './email-content';
import { FileContent, FileContentActor, makeFileContentMachine } from './file-content';
import { ImageContent, ImageContentActorRef, makeImageContentMachine } from './image-content';
import { makePageContentMachine, PageContentActorRef, PageContent } from './page-content/';
import { TextContent, TextContentActor, makeTextContentMachine } from './text-content';
import { SubtasksContent, SubtasksFormFieldActor, makeSubtasksFormFieldMachine } from './subtasks-content';
import { makeVideoContentMachine, VideoContent, VideoContentActorRef } from './video-content';
import { makeEmbedContentMachine, EmbedContent, EmbedContentActorRef } from './embed-content';
import { TableContentWidget, TableContentActor, makeTableContentMachine } from './table-content-widget';

export const Content = {
  Image: ImageContent,
  Text: TextContent,
  File: FileContent,
  Video: VideoContent,
  Page: PageContent,
  Email: EmailContent,
  Subtasks: SubtasksContent,
  Embed: EmbedContent,
  Table: TableContentWidget,
};

export type ContentActor = {
  Text: TextContentActor;
  Image: ImageContentActorRef;
  File: FileContentActor;
  Video: VideoContentActorRef;
  Page: PageContentActorRef;
  Email: EmailContentActorRef;
  Subtasks: SubtasksFormFieldActor;
  Embed: EmbedContentActorRef;
  Table: TableContentActor;
};

export const ContentMachineFactories = {
  Text: makeTextContentMachine,
  Image: makeImageContentMachine,
  File: makeFileContentMachine,
  Video: makeVideoContentMachine,
  Page: makePageContentMachine,
  Email: makeEmailContentMachine,
  Subtasks: makeSubtasksFormFieldMachine,
  Embed: makeEmbedContentMachine,
  Table: makeTableContentMachine,
};
