import { UseQueryOptions } from 'react-query';
import {
  GetAllFoldersByOrganizationId,
  GetAllFoldersByOrganizationIdResult,
  useAllFoldersByOrganizationQuery,
} from 'features/folder/query-builder';
import { useInjector } from 'components/injection-provider';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { sanitizePathParam } from 'features/folder/lib';

export const useFoldersQuery = <Select = GetAllFoldersByOrganizationIdResult>(
  options: UseQueryOptions<GetAllFoldersByOrganizationIdResult, Error, Select> = {},
) => {
  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  return useAllFoldersByOrganizationQuery(selectedOrganizationId!, {
    ...options,
    enabled: Boolean(selectedOrganizationId) && options.enabled !== false,
  });
};

export const useCurrentFolderQuery = () => {
  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const { $stateParams } = useInjector('$stateParams');
  const path = $stateParams.path ? sanitizePathParam($stateParams.path) : 'home';
  const pathType = $stateParams.type;
  return useAllFoldersByOrganizationQuery(selectedOrganizationId!, {
    enabled: Boolean(selectedOrganizationId),
    select: GetAllFoldersByOrganizationId.select.currentFolder({ path, pathType }),
  });
};
