import * as React from 'react';
import {
  Button,
  Tab,
  TabList,
  Tabs,
  MenuGroup,
  Text,
  Menu,
  MenuButton,
  Portal,
  MenuList,
  MenuItem,
} from 'components/design/next';
import { CreateSavedViewButton } from 'pages/reports/data-sets/components/create-saved-view-button';
import { useSelectedSavedView } from 'pages/reports/data-sets/components/data-set-page/selected-saved-view-id-store';
import { SavedViewTabItem } from './saved-view-tab-item';

const VISIBLE_TABS_COUNT = 8;

export const SavedViewTabs: React.FC = () => {
  const { dataSetPageStore, selectedDataSet, savedViews } = useSelectedSavedView();
  const { savedViewId } = dataSetPageStore;

  const { selectedTab, visibleTabs } = (() => {
    const selectedTab = savedViews.findIndex(sv => sv.id === savedViewId);

    if (selectedTab >= VISIBLE_TABS_COUNT) {
      return {
        selectedTab: VISIBLE_TABS_COUNT - 1,
        visibleTabs: savedViews.slice(0, VISIBLE_TABS_COUNT - 1).concat(savedViews[selectedTab]),
      };
    } else {
      return {
        selectedTab,
        visibleTabs: savedViews.slice(0, VISIBLE_TABS_COUNT),
      };
    }
  })();

  const hiddenTabsLength = Math.max(savedViews.length - VISIBLE_TABS_COUNT, 0);
  const canCreateSavedView = Boolean(selectedDataSet?.canAccessDataSet);

  return selectedDataSet ? (
    <Tabs index={selectedTab}>
      <TabList>
        {visibleTabs.map(savedView => (
          <SavedViewTabItem
            key={savedView.id}
            selectedDataSet={selectedDataSet}
            savedView={savedView}
            onClick={() => dataSetPageStore.setSavedViewId(savedView.id)}
            isSelected={savedView.id === savedViewId}
          />
        ))}

        {hiddenTabsLength > 0 && (
          <Tab paddingX={2}>
            <Menu isLazy placement="bottom">
              <MenuButton as={Button} variant="ghost" fontWeight="medium">
                <Text whiteSpace="nowrap" color="gray.400">
                  {hiddenTabsLength} more...
                </Text>
              </MenuButton>
              <Portal>
                <MenuList
                  color="gray.600"
                  zIndex="modal"
                  padding={2}
                  gap={0}
                  width="300px"
                  maxH="60vh"
                  overflowY="auto"
                >
                  <MenuGroup title="All views">
                    {savedViews.map(sv => (
                      <MenuItem key={sv.id} onClick={() => dataSetPageStore.setSavedViewId(sv.id)}>
                        {sv.name}
                      </MenuItem>
                    ))}
                  </MenuGroup>
                </MenuList>
              </Portal>
            </Menu>
          </Tab>
        )}
        <Tab paddingX={2}>
          <CreateSavedViewButton
            dataSet={selectedDataSet}
            isDisabled={!canCreateSavedView}
            variant="icon"
            onSavedViewCreated={sv => dataSetPageStore.setSavedViewId(sv.id)}
          />
        </Tab>
      </TabList>
    </Tabs>
  ) : null;
};
