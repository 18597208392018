import { User } from '@process-street/subgrade/core';
import { htmlEscaped } from '@process-street/subgrade/util';

const tagMentionedUsers = function (text: string, users: User[]): string {
  const usernames = users.map(user => user.username);

  // Sort usernames to ensure that the longest one is matched first
  usernames.sort((a, b) => b.length - a.length);

  let replacedText = text;
  usernames.forEach(username => {
    const atUsername = `@${username}`;
    const atUsernameHtml = htmlEscaped`$1<span class="at-mention">${atUsername}</span>$5`;
    const escapedAtUsername = htmlEscaped`${atUsername}`;
    const atUsernameRegex = generateUsernameRegexStr(escapedAtUsername);
    const match = new RegExp(atUsernameRegex, 'gi');

    replacedText = replacedText.replace(match, atUsernameHtml);
  });

  return replacedText;
};

const countMentionedUsers = function (text: string, users: User[]): number {
  // Sort usernames to ensure that the longest one is matched first
  const usernames = users.map(user => user.username).sort((a, b) => b.length - a.length);

  let count = 0;
  const replacedText = text;

  usernames.forEach(username => {
    const atUsername = `@${username}`;
    const atUsernameRegex = generateUsernameRegexStr(atUsername);
    const match = new RegExp(atUsernameRegex, 'gi');

    const found = replacedText.match(match);

    if (found) {
      count += found.length;
    }
  });

  return count;
};

const generateUsernameRegexStr = function (username: string): string {
  const punctuations = '[!"#$%&\'()*+,-. /:;<=>?[\\]^_`{|}~\\s]';
  return `((^${punctuations}*)+|(\\b${punctuations}+))(${username})(${punctuations}*\\b)`;
};

export const CommentService = {
  tagMentionedUsers,
  countMentionedUsers,
  generateUsernameRegexStr,
};
