import * as React from 'react';
import { useKey } from 'react-use';

export const upPressed = (e: KeyboardEvent | React.KeyboardEvent): boolean => {
  return e.key === 'ArrowUp' || (e.ctrlKey && e.key === 'p') || (e.shiftKey && e.key === 'Tab');
};

export const downPressed = (e: KeyboardEvent | React.KeyboardEvent): boolean => {
  return e.key === 'ArrowDown' || (e.ctrlKey && e.key === 'n') || (!e.shiftKey && e.key === 'Tab');
};

export type ListboxState = {
  selectedId: string;
  setSelectedId: (id: string) => void;
  ref: React.RefObject<HTMLUListElement>;
};

export function useListboxState({ ids }: { ids: string[] }): ListboxState {
  const [selectedId, setSelectedId] = React.useState<string>(() => ids[0] ?? '');

  useKey(
    downPressed,
    () => {
      setSelectedId(id => {
        const index = ids.findIndex(item => item === id);
        // this allows you to jump from the top to the bottom
        const nextIndex = (index + 1) % ids.length;
        const nextItemId = ids[nextIndex] ? ids[nextIndex] : '';
        return nextItemId;
      });
    },
    {},
    [ids],
  );

  useKey(
    upPressed,
    () => {
      setSelectedId(id => {
        const index = ids.findIndex(item => item === id);
        const nextIndex = index - 1;
        // this allows you to jump from the bottom to the top
        const adjustedIndex = nextIndex >= 0 ? nextIndex : ids.length + nextIndex;
        const nextItemId = ids[adjustedIndex] ? ids[adjustedIndex] : '';
        return nextItemId;
      });
    },
    {},
    [ids],
  );

  const ref = React.useRef<HTMLUListElement>(null);

  // adjust scroll if selection is beyond view
  React.useEffect(() => {
    // https://w3c.github.io/aria-practices/examples/listbox/listbox-scrollable.html
    if (!ref.current) return;
    const listbox = ref.current;

    const selectedOption = listbox.querySelector("[aria-selected='true'") as HTMLLIElement;
    if (!selectedOption) return;

    // is there overflow?
    if (selectedOption && listbox.scrollHeight > listbox.clientHeight) {
      const scrollBottom = listbox.clientHeight + listbox.scrollTop;
      const elementBottom = selectedOption.offsetTop + selectedOption.offsetHeight;

      // is the element fully visible?
      if (elementBottom > scrollBottom) {
        listbox.scrollTop = elementBottom - listbox.clientHeight;
      } else if (selectedOption.offsetTop < listbox.scrollTop) {
        listbox.scrollTop = selectedOption.offsetTop;
      }
    }
  }, [selectedId]);

  // reset the selection if the results change
  React.useEffect(() => {
    setSelectedId(ids[0] ?? '');
  }, [ids]);

  return React.useMemo(() => ({ selectedId, setSelectedId, ref }), [selectedId]);
}
