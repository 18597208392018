import * as React from 'react';
import {
  Icon,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  PopoverArrow,
  useDisclosure,
  Box,
  VisuallyHidden,
} from 'components/design/next';
import { ToolbarButton, ToolbarTooltip } from '../common';
import { MARK_BACKGROUND, MARK_COLOR } from '../../plugins/ps-color';
import { ColorPicker } from '../color-picker';
import { useBalloonToolbarControls } from '../balloon-toolbar/context';
import { useSelectionContext } from '../../selection-context';
import { useToolbarMenuButtonOnMouseDown } from '../balloon-toolbar/menu/use-toolbar-menu-button-on-mouse-down';
import { useToolbarMenuItemOnClick } from '../balloon-toolbar/menu';
import { PagesCustomColor, PagesEditor, PagesRichText, usePagesEditorRef } from '../../pages-plate-types';
import { getMarks, addMark as addEditorMark, removeEditorMark, select } from '@udecode/slate';
import { CustomIconName } from 'components/design/next/icon/icon-name';

export const markValue = (editor: PagesEditor, type: keyof Omit<PagesRichText, 'text'>) => {
  const marks = getMarks(editor);
  return marks?.[type] || undefined;
};

type ColorButtonVariant = 'color' | 'background';

export type Props = {
  variant: ColorButtonVariant;
};

const isHexCode = (code?: string): boolean => !!code && /#[\d,a-f]{3,6}/i.test(code);

const VARIANTS = {
  color: {
    MARK: MARK_COLOR,
    icon: 'font' as CustomIconName,
    label: 'Color',
  },
  background: {
    MARK: MARK_BACKGROUND,
    icon: 'highlighter' as CustomIconName,
    label: 'Background Color',
  },
};

export const ColorButton: React.FC<React.PropsWithChildren<Props>> = React.memo(({ variant = 'color' }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { setForcedState } = useBalloonToolbarControls();
  const { lastSelection } = useSelectionContext();
  const currentVariant = VARIANTS[variant];
  const editor = usePagesEditorRef();
  const addMark = (color?: PagesCustomColor) => {
    // This happens when you first open the color picker, no need to fire an effect.
    if (!color && getMarks(editor)?.[currentVariant.MARK] === undefined) return;
    // Don't do anything if the colors are the same.
    if (color === getMarks(editor)?.[currentVariant.MARK]) return;

    if (!isHexCode(color)) {
      return removeEditorMark(editor, currentVariant.MARK);
    }
    if (!editor.selection && lastSelection) {
      select(editor, lastSelection);
      return addEditorMark(editor, currentVariant.MARK, color);
    }
    addEditorMark(editor, currentVariant.MARK, color);
    if (editor.selection) {
      return select(editor, editor.selection);
    }
    if (lastSelection) {
      return select(editor, lastSelection);
    }
  };
  const ref = React.useRef(null);
  const currentColor = markValue(editor, currentVariant.MARK) as string;
  const onMouseDown = useToolbarMenuButtonOnMouseDown();
  const onClick = useToolbarMenuItemOnClick({ preventForcedStateUpdate: true });
  return (
    <Popover
      isLazy={true}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setForcedState('none');
      }}
      autoFocus={true}
      onOpen={() => {
        setForcedState('open');
        onOpen();
      }}
      gutter={52}
    >
      <ToolbarTooltip label={currentVariant.label}>
        {/* Box needed to make tooltip work on popover trigger */}
        <Box>
          <PopoverTrigger>
            <ToolbarButton
              color="white"
              rightIcon={<Icon icon="caret-down" variant="fas" size="3" />}
              onMouseDown={onMouseDown}
            >
              <Icon size="4" icon={currentVariant.icon} />
              <VisuallyHidden>{currentVariant.label}</VisuallyHidden>
            </ToolbarButton>
          </PopoverTrigger>
        </Box>
      </ToolbarTooltip>

      <PopoverContent w="78" background="black" color="white">
        <PopoverArrow bg="black" />
        <PopoverBody
          ref={ref}
          onFocus={() => {
            setForcedState('open');
          }}
        >
          <Box>
            <ColorPicker
              onChange={color => {
                onClick(() => {
                  addMark(color);
                });
              }}
              color={currentColor}
            />
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});
