import React from 'react';

import { S3File } from '@process-street/subgrade/core';
import prettyBytes from 'pretty-bytes';
import { HStack, VStack, Link, Text, IconButton, Icon, StackProps, Center } from 'components/design/next';

export const AttachmentFile = ({
  file,
  canDownload = true,
  ...props
}: { file: Partial<S3File>; canDownload?: boolean } & StackProps) => {
  return (
    <HStack h="13" spacing="0" w="full" {...props}>
      <Center bg="brand.600" w="13" h="13" borderLeftRadius="base" px="4">
        <Text fontWeight="medium" variant="-1" color="white" textTransform="uppercase">
          {file.extension}
        </Text>
      </Center>
      <HStack
        h="full"
        borderColor="gray.200"
        borderWidth="px"
        borderStyle="solid"
        borderRadius="base"
        borderLeft="none"
        borderLeftRadius="none"
        ml="0"
        w="full"
        minWidth="0"
        flex="1"
      >
        <VStack justifyContent={file.size ? 'center' : 'flex-start'} spacing="1" pl="3" minW="0" flex="1">
          {canDownload ? (
            <Text variant="-1" as={Link} download href={file.url} noOfLines={1} w="full" textAlign="center">
              {file.originalName}
            </Text>
          ) : (
            <Text variant="-1" noOfLines={1} w="full" textAlign="center">
              {file.originalName}
            </Text>
          )}
          <Text variant="-2" color="gray.500">
            {prettyBytes(file.size ?? 0)}
          </Text>
        </VStack>

        {canDownload && (
          <IconButton
            as={Link}
            href={file.url}
            download
            bg="white"
            variant="ghost"
            size="sm"
            color="gray.500"
            _hover={{ bg: 'white' }}
            _active={{ bg: 'white' }}
            _focus={{ bg: 'white' }}
            icon={<Icon size="4" variant="far" icon="arrow-down-to-line" />}
            aria-label="download attachment"
          />
        )}
      </HStack>
    </HStack>
  );
};
