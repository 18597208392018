import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Stack,
  Input,
  Text,
  ListItem,
  UnorderedList,
  Spinner,
} from 'components/design/next';
import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { Option, Organization } from '@process-street/subgrade/core';
import { useInjector } from 'components/injection-provider';

export type NewOrganizationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

enum Mode {
  Creating,
  Created,
}

export const NewOrganizationModal: React.FC<React.PropsWithChildren<NewOrganizationModalProps>> = ({
  isOpen,
  onClose,
}) => {
  const [name, setName] = useState('');
  const [mode, setMode] = useState<Mode>(Mode.Creating);
  const [submitting, setSubmitting] = useState(true);
  const [organization, setOrganization] = useState<Option<Organization>>(undefined);

  const { OrganizationService: organizationService } = useInjector('OrganizationService');

  useEffect(() => {
    if (isOpen) {
      setName('');
      setMode(Mode.Creating);
      setOrganization(undefined);
      setSubmitting(false);
    }
  }, [isOpen]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSwitchOrganization = () => {
    organizationService.switchToNewOrganization(organization!.id);
  };

  const addOrganization = () => {
    setSubmitting(true);
    organizationService.create(name).then(response => {
      setMode(Mode.Created);
      setOrganization(response.organization);
      setSubmitting(false);
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="lg" color="gray.700">
            Add new organization
          </Text>
        </ModalHeader>
        <ModalCloseButton top={3} color="gray.600" />
        {mode === Mode.Creating && (
          <ModalBody>
            <Stack mt={2}>
              <Text fontSize="md" color="gray.600" fontWeight="medium">
                Organization Name
              </Text>
              <Input variant="outline" placeholder="Enter a name" value={name} onChange={handleNameChange} />
            </Stack>
            <Box mt={6}>
              <Text mb={1} fontSize="md" color="gray.600" fontWeight="medium">
                You will be able to:
              </Text>
              <UnorderedList spacing={3} color="gray.600" padding={2}>
                <ListItem>Create your own processes and workflows</ListItem>
                <ListItem>Assign tasks and collaborate with your team</ListItem>
              </UnorderedList>
            </Box>
          </ModalBody>
        )}
        {mode === Mode.Created && (
          <ModalBody>
            <Text fontSize="lg" color="gray.700" fontWeight="bold" textAlign="center">
              {name}
            </Text>
            <Text fontSize="md" color="gray.600" textAlign="center">
              has been created. <br />
              Would you like to go there now?
            </Text>
          </ModalBody>
        )}

        {mode === Mode.Creating && (
          <ModalFooter>
            <Button variant="ghost" onClick={onClose} mr={4}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={addOrganization}
              isLoading={submitting}
              loadingText="Add Organization"
              spinner={<Spinner sx={{ color: 'white' }} />}
              isDisabled={submitting || name.trim().length === 0}
            >
              Add Organization
            </Button>
          </ModalFooter>
        )}
        {mode === Mode.Created && (
          <ModalFooter>
            <Button variant="ghost" onClick={onClose} mr={4}>
              No thanks
            </Button>
            <Button colorScheme="brand" onClick={handleSwitchOrganization}>
              Go to {name.trim().substr(0, 20)}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
