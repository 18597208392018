import { Muid } from '@process-street/subgrade/core';
import { TaskApprovalInfo } from 'components/approvals/components/TaskApprovalInfo';
import React from 'react';

interface ApprovalInfoReactWrapperProps {
  taskId: Muid;
}

export class TaskApprovalInfoReactWrapper extends React.PureComponent<
  ApprovalInfoReactWrapperProps,
  Record<string, unknown>
> {
  public render() {
    return <TaskApprovalInfo taskId={this.props.taskId} />;
  }
}
