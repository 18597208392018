import * as React from 'react';
import { RichTextEditor, TinyMCEEditor } from 'features/rich-text';
import { match } from 'ts-pattern';
import { TextAreaRichEditorHelper } from './textarea-rich-editor-helper';
import { usePrintStore } from 'app/components/react-root/print-store';
import { Box, BoxProps } from 'components/design/next';
import { positioningMaskProps } from 'app/features/widgets/components/masked-input';

type TextAreaRichEditorProps = {
  hasDefaultValue?: boolean;
  readOnly: boolean;
  editable: boolean;
  setValue: (value: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  placeholder?: string;
  markdownValue?: string;
  maskHtml?: string;
  maskProps?: BoxProps;
};

const toolbar = [['bold', 'italic', 'strikethrough'], ['blocks'], ['bullist', 'numlist']]
  .map(section => section.join(' '))
  .join(' ');

export const TextAreaRichEditor: React.FC<TextAreaRichEditorProps> = ({
  setValue,
  hasDefaultValue = false,
  readOnly,
  editable,
  onFocus,
  onBlur,
  markdownValue,
  placeholder,
  maskHtml,
  maskProps,
}) => {
  const backgroundColor = match({ hasDefaultValue, readOnly })
    .with({ readOnly: true }, () => 'gray.200')
    .with({ hasDefaultValue: true }, () => 'yellow.100')
    .otherwise(() => undefined);

  const initialValue = React.useRef(TextAreaRichEditorHelper.markdown2html(markdownValue ?? '')).current;
  const sanitizedHtml = maskHtml || '';
  const [editor, setEditor] = React.useState<TinyMCEEditor | undefined>(undefined);

  React.useEffect(
    function syncWidgetContentInEditor() {
      if (markdownValue === undefined || !editor) return;
      const editorMarkdownValue = TextAreaRichEditorHelper.html2markdown(editor.getContent());
      if (editorMarkdownValue !== markdownValue) {
        editor.setContent(TextAreaRichEditorHelper.markdown2html(markdownValue));
      }
    },
    [editor, markdownValue],
  );

  const onChange = (content: string) => {
    const markdown = TextAreaRichEditorHelper.html2markdown(content);
    setValue(markdown);
  };

  const handleFocus = React.useCallback(() => {
    onFocus();
  }, [onFocus]);

  const handleBlur = React.useCallback(() => {
    onBlur();
  }, [onBlur]);

  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;

  const shouldShowMask = !isPrintMode && readOnly;

  return (
    <>
      <RichTextEditor
        source="LongTextWidgetChecklist"
        editorWrapperProps={{
          w: 'full',
          px: 4,
          borderWidth: '1px',
          borderColor: 'gray.200',
          borderRadius: '4px',
          borderStyle: 'solid',
          backgroundColor,
          minH: '80px',
          _focusWithin: { borderColor: 'blue.500', boxShadow: '0 0 0 1px var(--ps-colors-blue-500)' },
        }}
        init={{
          placeholder,
          toolbar,
        }}
        editorProps={{
          onInit: (_evt, editor) => setEditor(editor),
          initialValue,
          onEditorChange: onChange,
          onFocus: handleFocus,
          onBlur: handleBlur,
          disabled: !editable,
        }}
      />
      {shouldShowMask && (
        <Box
          {...{
            ...positioningMaskProps,
            // "insetting" the div so it doesn't cover the input's border.
            'top': '27px',
            'left': '1px',
            // -2px to account for the left and right border at 1px
            'w': 'calc(100% - 2px)',
            // -2px to account for the top and bottom border at 1px
            'h': 'calc(100% - 2px)',
            'lineHeight': 'short',
            'whiteSpace': 'pre-wrap',
            'height': 'auto',
            'py': 'calc(var(--ps-sizes-2) - 1px)',
            'px': 'calc(var(--ps-sizes-4) - 1px)',
            'bgColor': readOnly ? 'gray.100' : 'transparent',
            'data-testid': 'mask',
            'borderWidth': 'px',
            'borderStyle': 'solid',
            'borderColor': 'transparent',
            'borderRadius': 'base',
            'overflow': 'hidden',
            'sx': {
              'wordWrap': 'break-word',
              '& a': { pointerEvents: 'auto' },
            },
            'pointerEvents': 'none',
            'color': maskHtml ? undefined : 'gray.400',
            'dangerouslySetInnerHTML': { __html: sanitizedHtml },
          }}
          {...maskProps}
        />
      )}
    </>
  );
};
