import { useUpdateEffect } from 'react-use';
import { createMachine, assign, InterpreterFrom } from 'xstate';
import { useEffect } from 'react';

export type Context = { value: string; isValid: boolean; shouldShowError: boolean };
export type Event =
  | { type: 'BLUR' | 'FOCUS' }
  | { type: 'CHANGE' | 'RESET' | 'SYNC' | 'MASK'; value: string }
  | { type: 'FOCUS' };

/**
 * This machine started as a way to manage form field widget state, but ended up having no widget specific details in the machine.
 **/
export const validatedInputMachine = createMachine(
  {
    context: { value: '', isValid: true, shouldShowError: false },
    tsTypes: {} as import('./validated-input-machine.typegen').Typegen0,
    schema: { context: {} as Context, events: {} as Event },
    preserveActionOrder: true,
    predictableActionArguments: true,
    initial: 'idle',
    id: 'validated-input-machine',
    on: {
      RESET: {
        actions: ['hideError', 'updateValue', 'validate', 'onReset'],
      },
      // SYNC and MASK have the same actions for now but are triggered for different reasons so keeping them separate
      SYNC: {
        actions: ['hideError', 'updateValue', 'validate'],
      },
      MASK: {
        actions: ['hideError', 'updateValue', 'validate'],
      },
    },
    states: {
      idle: {
        on: {
          FOCUS: {
            target: 'focused',
          },
        },
      },
      focused: {
        on: {
          // Don't overwrite while focused
          SYNC: {},
          CHANGE: {
            actions: ['updateValue', 'validate', 'maybeHideError', 'onChange'],
          },
          BLUR: [
            {
              target: 'valid',
              cond: 'isValid',
            },
            {
              target: 'invalid',
            },
          ],
        },
        exit: ['onInteractionEnd'],
      },
      invalid: {
        entry: ['showError'],
        on: {
          FOCUS: {
            target: 'focused',
          },
        },
      },
      valid: {
        entry: ['normalizeValue'],
        on: {
          FOCUS: {
            target: 'focused',
          },
        },
      },
    },
  },
  {
    guards: {
      isValid: ctx => ctx.isValid,
    },
    actions: {
      onInteractionEnd: () => {},
      onReset: () => {},
      updateValue: assign({
        value: (_, e) => e.value,
      }),
      normalizeValue: assign({
        value: ctx => ctx.value,
      }),
      showError: assign({
        shouldShowError: (_ctx, _e) => true,
      }),
      hideError: assign({
        shouldShowError: (_ctx, _e) => false,
      }),
      maybeHideError: assign({
        shouldShowError: (ctx, _e) => (ctx.isValid ? false : ctx.shouldShowError),
      }),
    },
  },
);

/* Update if source value changes (e.g., Ably change) */
export function useSyncedValue({
  send,
  value,
  enabled,
}: {
  send: InterpreterFrom<typeof validatedInputMachine>['send'];
  value: string;
  enabled?: boolean;
}) {
  useUpdateEffect(() => {
    if (enabled) {
      send({ type: 'SYNC', value });
    }
  }, [send, value, enabled]);
}

/* This sets an unvalidated "mask" value only in the client in case it failed validation */
export function useMaskedValue({
  send,
  value,
  maskedValue,
  enabled,
}: {
  send: InterpreterFrom<typeof validatedInputMachine>['send'];
  value?: string;
  maskedValue?: string;
  enabled: boolean;
}) {
  useEffect(() => {
    if (enabled && !value && maskedValue) {
      send({ type: 'MASK', value: maskedValue });
    }
  }, [value, maskedValue, enabled, send]);
}
