import {
  Box,
  Divider,
  Link,
  List,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from 'components/design/next';
import * as React from 'react';
import { match } from 'ts-pattern';
import { useReassignModal } from '.';
import { useReassignModalStore } from '../../../ChecklistDashboardGridReassignModalStore';
import { AssigneesSearchBox } from './assignees-search-box/assignees-search-box';
import { AssignmentItemSkeleton, AssignmentsList } from './assignments-list/assignments-list';
import { TaskAssignmentItemSkeleton, TasksAssignmentsLists } from './tasks-assignments-list';

export interface ReassignModalProps extends Partial<ModalProps> {
  /** Show/hide aggregate task assignment count display (if task ID is not provided) */
  shouldShowTasksCount?: boolean;
  /** Tells parent that items have changed, to refetch. */
  onChange?: () => void;
}

/**
 * If taskId exists, we allow individually assigning/removing users from the task.
 * If it is missing, we allow assigning/removing users from the checklist.
 */
export const ReassignModal: React.FC<React.PropsWithChildren<ReassignModalProps>> = ({
  shouldShowTasksCount,
  onChange,
  ...modalProps
}) => {
  const { isOpen, onClose, checklistId, taskId } = useReassignModalStore();
  const hasChecklistId = Boolean(checklistId);
  const hasTaskId = hasChecklistId && Boolean(taskId);

  const {
    checklistAssignmentsQuery,
    tasksAssignmentsQuery,
    taskOrChecklistQuery,
    assignableUserOptions,
    handleOnRemoveChecklistAssignment,
    handleOnRemoveTaskAssignment,
    handleOnAddAssignee,
  } = useReassignModal({ checklistId, taskId, shouldShowTasksCount, onChange });

  const checklistLink = `/checklists/${checklistId}`;

  return (
    <Modal size="md" {...{ ...modalProps, isOpen, onClose }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center" pb="2">
          <Text fontWeight="500" fontSize="sm" color="gray.700">
            Assignees
          </Text>
        </ModalHeader>
        <Divider mx="6" w="calc(100% - 48px)" />

        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems="flex-start" spacing="2">
            <Text variant="-2u" fontWeight="bold" color="gray.400">
              {hasTaskId ? 'Task' : 'Workflow Run'}
            </Text>
            <Box w="full">
              <AssigneesSearchBox
                userOptions={assignableUserOptions}
                onAddSelectedOption={handleOnAddAssignee}
                placeholder={hasTaskId ? 'Add new Task assignee' : 'Add new Workflow run assignee'}
              />
            </Box>
            <Box w="full">
              <Box overflowY="auto" mx="-6">
                <VStack alignItems="flex-start" spacing="2" h={{ base: '340px', md: '440px' }} px="6">
                  {match(taskOrChecklistQuery)
                    .with({ status: 'loading' }, () => (
                      <List w="full">
                        {Array.from({ length: 2 }, (_, i) => (
                          <AssignmentItemSkeleton key={i} />
                        ))}
                      </List>
                    ))
                    .with({ status: 'success', data: [] }, () => (
                      <Text color="gray.400" fontSize="sm">
                        No {hasTaskId ? 'Task' : 'Workflow run'} assignees
                      </Text>
                    ))
                    .with({ status: 'success' }, () =>
                      hasTaskId ? (
                        <TasksAssignmentsLists
                          tasksAssignments={tasksAssignmentsQuery.data ?? []}
                          onRemove={handleOnRemoveTaskAssignment}
                        />
                      ) : (
                        <AssignmentsList
                          assignments={checklistAssignmentsQuery.data ?? []}
                          onRemoveAssignment={handleOnRemoveChecklistAssignment}
                        />
                      ),
                    )
                    .otherwise(() => null)}

                  {shouldShowTasksCount && (
                    <>
                      <Divider />
                      <Text variant="-2u" fontWeight="bold" color="gray.400" pt="2">
                        Tasks
                      </Text>
                      {match(tasksAssignmentsQuery)
                        .with({ status: 'loading' }, () => (
                          <List w="full">
                            {Array.from({ length: 2 }, (_, i) => (
                              <TaskAssignmentItemSkeleton key={i} />
                            ))}
                          </List>
                        ))
                        .with({ status: 'success', data: [] }, () => (
                          <Text color="gray.400" fontSize="sm">
                            No task assignees
                          </Text>
                        ))
                        .with({ status: 'success' }, () => (
                          <>
                            <TasksAssignmentsLists tasksAssignments={tasksAssignmentsQuery.data ?? []} />
                            <Link
                              fontWeight="normal"
                              fontSize="sm"
                              color="brand.500"
                              w="full"
                              _hover={{ color: 'brand.600' }}
                              href={checklistLink}
                            >
                              Reassign tasks in Workflow run
                            </Link>
                          </>
                        ))
                        .otherwise(() => null)}
                    </>
                  )}
                </VStack>
              </Box>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
