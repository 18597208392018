import { connectController } from 'reducers/util';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { DynamicDueDatesSelector } from '../store/dynamic-due-dates.selectors';
import templateUrl from './template-popup.component.html';
import './template-popup.scss';

export const TemplatePopupComponent = {
  bindings: {
    disabled: '<',
    onRemove: '&',
    onSave: '&',
    selectedTaskTemplate: '<',
    selectedTaskTemplates: '<',
    templateRevision: '<',
    title: '<',
  },
  templateUrl,
  controller: class {
    constructor($ngRedux, DynamicDueDateService) {
      'ngInject';

      this.DynamicDueDateService = DynamicDueDateService;

      this.offsetDirection = 'after';
      this.offset = {};
      this.selectedRule = null;

      const mapStateToThis = () => state => {
        const templateRevisionId = this.templateRevision && this.templateRevision.id;

        const taskRules = templateRevisionId
          ? DynamicDueDatesSelector.getAllByTemplateRevisionId(templateRevisionId)(state)
          : [];

        const taskTemplates = templateRevisionId
          ? TaskTemplateSelector.getAllByTemplateRevisionId(templateRevisionId)(state)
          : [];

        const widgets = templateRevisionId
          ? DynamicDueDatesSelector.getDateWidgetsByTemplateRevisionId(templateRevisionId)(state)
          : [];

        const widgetsLoaded = templateRevisionId
          ? WidgetSelector.getLoadedStatusByTemplateRevisionId(templateRevisionId)(state)
          : false;

        let taskRule =
          this.selectedTaskTemplate && DynamicDueDateService.findRuleForTask(taskRules, this.selectedTaskTemplate);

        if (this.selectedTaskTemplates && this.selectedTaskTemplates.length > 0) {
          taskRule = null;
        }

        this.initRuleFields(taskRule);

        return {
          taskRule,
          taskTemplates,
          widgets,
          widgetsLoaded,
        };
      };

      const shouldChange = change =>
        (change.selectedTaskTemplate && change.selectedTaskTemplate.currentValue) ||
        (change.templateRevision && change.templateRevision.currentValue);

      connectController($ngRedux, mapStateToThis, null, shouldChange)(this);
    }

    initRuleFields(taskRule) {
      if (taskRule) {
        const offset = taskRule.dueOffset;

        this.offset = {
          ...offset,
          months: offset.years * 12 + offset.months,
          years: 0,
          workdaysOnly: taskRule.workdaysOnly,
        };
        this.offsetDirection = taskRule.offsetDirection.toLowerCase();
        this.selectedRule = {
          sourceType: taskRule.sourceType,
        };
      } else {
        this.offsetDirection = 'after';
        this.offset = {
          workdaysOnly: true, // default for new rules
        };
        this.selectedRule = {
          sourceType: 'ChecklistStartDate',
        };
      }
    }

    isFormValid() {
      const { offset } = this;
      const isOffsetSet = offset.months || offset.days || offset.hours || offset.minutes;
      return isOffsetSet && this.selectedRule;
    }

    saveOffset() {
      const data = {
        offset: this.offset,
        offsetDirection: this.offsetDirection,
        rule: this.selectedRule,
      };
      this.onSave({ data });
    }

    removeOffset() {
      this.onRemove();
    }

    isRemoveEnabled() {
      return this.state.taskRule || (this.selectedTaskTemplates && this.selectedTaskTemplates.length > 0);
    }

    getOffsetDirections() {
      return ['after', 'before'];
    }

    setOffsetDirection(option) {
      this.offsetDirection = option;
      this.selectedRule = null;
    }

    onDueDateSelected(rule) {
      this.selectedRule = rule;
    }
  },
};
