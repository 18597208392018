import { ActorRefFrom, createMachine } from 'xstate';

type Context = {};
type Event = { type: 'OPEN' } | { type: 'CLOSE' };

type CreateDisclosureMachineArgs = Partial<{
  initialState: 'open' | 'closed';
}>;

export const makeDisclosureMachine = ({ initialState = 'closed' }: CreateDisclosureMachineArgs = {}) => {
  return createMachine({
    id: 'disclosure',
    initial: initialState,
    schema: {
      context: {} as Context,
      events: {} as Event,
    },
    tsTypes: {} as import('./disclosure-machine.typegen').Typegen0,
    predictableActionArguments: true,
    states: {
      closed: {
        on: {
          OPEN: 'open',
        },
      },
      open: {
        on: {
          CLOSE: 'closed',
        },
      },
    },
  });
};

export type DisclosureMachine = ReturnType<typeof makeDisclosureMachine>;
export type DisclosureActor = ActorRefFrom<DisclosureMachine>;
export type { Event as DisclosureEvent };
