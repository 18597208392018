import { AuditMetadata } from '@process-street/subgrade/core';
import { DateFormat, DateUtils, UserUtils } from '@process-street/subgrade/util';
import { usePrintStore } from 'components/react-root/print-store';
import { useUserByIdQuery } from 'features/user/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { UserSelector } from 'reducers/user/user.selectors';

export const useAudit = ({ audit }: { audit: AuditMetadata }) => {
  const currentUser = useSelector(SessionSelector.getCurrentUser);
  const auditedByFromRedux = useSelector(UserSelector.getById(audit.updatedBy.id));

  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;

  // Try and load the user directly if they haven't already been loaded.
  const auditedByUserQuery = useUserByIdQuery({ id: audit.updatedBy.id }, { enabled: !auditedByFromRedux?.id });

  // Note - The user may still be undefined if they've left the organization
  const auditedBy = auditedByFromRedux || auditedByUserQuery.data;

  const username = UserUtils.getUsername(auditedBy);

  const formattedPrintDate = auditedBy ? DateUtils.formatDateFromNow(audit.updatedDate) : 'at some point';

  const formattedDate = auditedBy
    ? DateUtils.formatDateTime(audit.updatedDate, undefined, currentUser?.timeZone)
    : 'at some point';

  const formattedDateWithTimezone = auditedBy
    ? DateUtils.formatDateTime(
        audit.updatedDate,
        DateFormat.DateTimeLongWeekdayShortMonthWithZone,
        currentUser?.timeZone,
      )
    : 'at some point';

  return {
    username,
    formattedPrintDate,
    formattedDate,
    formattedDateWithTimezone,
    isPrintMode,
    isLoading: !auditedByFromRedux && auditedByUserQuery.isLoading,
  };
};
