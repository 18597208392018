import * as React from 'react';
import { VStack } from 'components/design/next';
import { APIKeys } from '../api-keys';
import { WebhooksManager } from 'components/organization-manage/webhooks/components/WebhooksManager';

export const Integrations: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <VStack w="full" alignItems="flex-start" spacing={4}>
      <APIKeys />
      <WebhooksManager />
    </VStack>
  );
};
