import { useNavigate } from '@process-street/adapters/navigation';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  HStack,
  Badge,
  ModalBody,
  Image,
  VStack,
  ModalFooter,
  Button,
  Link,
} from 'components/design/next';
import * as React from 'react';
import { match } from 'ts-pattern';

export type StopTaskModalProps = {
  disclosure: ReturnType<typeof useDisclosure>;
  isAdmin: boolean;
  isFeatureAvailable: boolean;
  onAddStop: () => void;
};

export const StopTaskModal = ({ disclosure, isAdmin, isFeatureAvailable, onAddStop }: StopTaskModalProps) => {
  const navigate = useNavigate();
  const onUpgradeClick = () => {
    navigate('organizationManage.tab', { replace: true, state: { tab: 'billing' } });
  };

  return (
    <Modal size="xl" {...disclosure}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isFeatureAvailable && <Text>Add Stop Task</Text>}
          {!isFeatureAvailable && (
            <HStack w="full" alignItems="center" justifyContent="center">
              <Text>Upgrade to use Stop Tasks</Text>
              <Badge color="white" bgColor="green" fontWeight="medium">
                Standard
              </Badge>
            </HStack>
          )}
        </ModalHeader>
        <ModalBody>
          <VStack spacing="4" w="full" alignItems="center">
            <Image src={require('app/images/task-template/stop-task-upgrade.svg')} />
            <Text textAlign="center">
              Stop a workflow from continuing until a task is completed.
              <br />
              Automatically notify the next person when its their turn to start working.
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <VStack w="full" alignItems="center" spacing="2">
            {match({ isFeatureAvailable, isAdmin })
              .with({ isFeatureAvailable: true }, () => <Button onClick={onAddStop}>Ok, Add a stop!</Button>)
              .with({ isFeatureAvailable: false, isAdmin: true }, () => (
                <Button onClick={onUpgradeClick}>Upgrade to Standard</Button>
              ))
              .with({ isFeatureAvailable: false, isAdmin: false }, () => (
                <Text fontSize="sm">Contact an administrator to upgrade your plan.</Text>
              ))
              .otherwise(() => null)}

            <Link target="_blank" href="https://process.st/help/stop-task" rel="noopener noreferrer">
              Learn more &raquo;
            </Link>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
