import angular from 'angular';
import templateUrl from './startup-plan.component.html';
import './startup-plan.scss';
import { PlanLevel } from '@process-street/subgrade/billing';
import { IntercomService } from 'services/interop/intercom-service';

angular.module('frontStreetApp.directives').component('psStartupPlan', {
  bindings: {
    discount: '<',
    organization: '<',
    plan: '<',
    quantity: '<',
    upgradePlan: '<',
    upgradeTotal: '<',
  },
  templateUrl,
  controller(OrganizationService) {
    const ctrl = this;

    ctrl.$onInit = function () {
      ctrl.planIsFree = ctrl.plan?.level === PlanLevel.Free;
      ctrl.subscriptionIsCanceled = OrganizationService.isCanceled(ctrl.organization.subscription);
      ctrl.subscriptionIsTrailing = OrganizationService.isTrialing(ctrl.organization.subscription);
      ctrl.showBillingSummary = !(ctrl.subscriptionIsTrailing || ctrl.subscriptionIsCanceled);
    };

    ctrl.onClick = function () {
      ctrl.showBillingSummary = true;
    };

    ctrl.openIntercom = function () {
      IntercomService.showNewMessage('I would like to purchase the Startup Plan.');
    };
  },
});
