import React from 'react';
import emptyStateImage from '../assets/empty-state.svg';
import { Text, Container, Img, Center } from 'components/design/next';

export const AutomationEmptyState: React.FC = () => {
  return (
    <Container as={Center} h="100%" centerContent={true} maxWidth="xs" color="gray.500">
      <Img w="60%" src={emptyStateImage} />
      <Text variant="1">
        Quickly add automation to power up your most important workflows. Press&nbsp;“
        <Text color="brand.500" variant="1" as="strong">
          +
        </Text>
        ”&nbsp;to add an automation.
      </Text>
    </Container>
  );
};
