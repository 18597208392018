import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';

type Context = {
  setPaywall: (paywall: React.ReactElement | null) => void;
  isOpen: boolean;
};

export const [usePaywall, PaywallContext] = createUsableContext<Context>({
  hookName: 'usePaywall',
  providerName: 'PaywallProvider',
});

export const PaywallProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [paywall, setPaywall] = React.useState<React.ReactElement | null>(null);
  const isOpen = Boolean(paywall);
  const context = React.useMemo<Context>(() => ({ setPaywall, isOpen }), [setPaywall, isOpen]);

  return (
    <PaywallContext.Provider value={context}>
      {children}
      {paywall}
    </PaywallContext.Provider>
  );
};
