import {
  PaywallBodyDescription,
  PaywallBodyDescriptionEmphasis,
  PaywallBodyHeading,
  PaywallBodyWithIcon,
  PaywallDivider,
  PaywallFooter,
  PaywallGoBack,
  PaywallHeader,
  PaywallIconUserLock,
  PaywallModal,
  PaywallPrimaryActionLinkButton,
} from 'components/paywalls/common';
import * as React from 'react';
import { ModalProps } from 'components/design/next';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { useRoleNames } from 'hooks/use-role-names';

export type Props = {
  onGoBack: () => void;
  userName: string;
  contactHref: string;
  userRole: OrganizationMembershipRole;
} & Partial<ModalProps>;

export const NonAdmin: React.FC<React.PropsWithChildren<Props>> = ({
  onGoBack,
  userName,
  contactHref,
  userRole,
  ...props
}) => {
  const roleNames = useRoleNames();
  const roleText = userRole === OrganizationMembershipRole.Guest ? roleNames.Guest.plural : roleNames.FreeMember.plural;

  return (
    <PaywallModal {...props}>
      <PaywallHeader>
        <PaywallGoBack onClick={onGoBack} />
      </PaywallHeader>

      <PaywallBodyWithIcon>
        <PaywallIconUserLock />

        <PaywallBodyHeading>{roleText} cannot be a default assignee on workflows</PaywallBodyHeading>

        <PaywallBodyDescription>
          {userName} needs to be upgraded to a{' '}
          <PaywallBodyDescriptionEmphasis>{roleNames.FullMember.single}.</PaywallBodyDescriptionEmphasis>
        </PaywallBodyDescription>
        <PaywallBodyDescription>Contact your administrator to have them upgraded.</PaywallBodyDescription>
      </PaywallBodyWithIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallPrimaryActionLinkButton href={contactHref}>Contact Administrator</PaywallPrimaryActionLinkButton>
      </PaywallFooter>
    </PaywallModal>
  );
};
