import * as React from 'react';
import { Icon, Tooltip } from 'components/design/next';
import { SingleValueProps, components } from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { PathOption as TPathOption } from '../utils';

export const PathSingleValue: React.FC<React.PropsWithChildren<SingleValueProps<TPathOption, false>>> = props => {
  const value = props.data;
  if (BlvdSelectHelpers.isOptionType<TPathOption>(value)) {
    return (
      <components.SingleValue {...props}>
        {props.children}
        {value.isInvalid ? (
          <Tooltip label="This path is not valid in the most recent payload test" shouldWrapChildren hasArrow>
            <Icon ml="1" icon="warning" color="yellow.500" size="4" variant="far" />
          </Tooltip>
        ) : null}
      </components.SingleValue>
    );
  }
  return null;
};
