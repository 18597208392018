import { Muid } from '@process-street/subgrade/core';
import { useQuery, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, CanUseResponse } from './api';

export const useCanUseChecklistQuery = (
  checklistId: Muid,
  options: UseQueryOptions<CanUseResponse> = {},
): UseQueryResult<CanUseResponse> => {
  return useQuery(['checklists', 'can-use', checklistId] as QueryKey, () => API.canUseChecklist(checklistId), options);
};
