import { Muid } from '@process-street/subgrade/core';
import { Webhook, WebhookType } from '@process-street/subgrade/process';
import { AxiosResponse } from 'axios';
import { FlashActions } from 'reducers/flash/flash.actions';
import { ReduxAppState } from 'reducers/types';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { axiosService } from 'services/axios-service';

export const WEBHOOKS_ADD_EMPTY_WEBHOOK = 'webhooks/WEBHOOKS_ADD_EMPTY_WEBHOOK';
export const WEBHOOKS_FETCH_SUCCESS = 'webhooks/WEBHOOKS_FETCH_SUCCESS';
export const WEBHOOKS_UPDATE_SUCCESS = 'webhooks/WEBHOOKS_UPDATE_SUCCESS';
export const WEBHOOKS_DELETE_SUCCESS = 'webhooks/WEBHOOKS_DELETE_SUCCESS';

const SuccessNotificationDuration = 2000;

export interface WebhooksFetchSuccessActionType {
  type: string;
  payload: Webhook[];
}

export interface AddEmptyWebhookActionType {
  type: string;
  payload: { userId: Muid; webhookType: WebhookType };
}

export interface UpdateWebhookSuccessActionType {
  type: string;
  payload: Webhook;
}

export interface DeleteWebhookSuccessActionType {
  type: string;
  payload: Muid;
}

export const WebhookActions = {
  addEmptyWebhook: (userId: Muid, webhookType: WebhookType): AnyAction => ({
    payload: { userId, webhookType },
    type: WEBHOOKS_ADD_EMPTY_WEBHOOK,
  }),

  deleteWebhook:
    (id: Muid) =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>, getState: () => ReduxAppState) => {
      if (getState().entities.webhooks[id] && getState().entities.webhooks[id].existsOnServer === false) {
        dispatch({
          payload: id,
          type: WEBHOOKS_DELETE_SUCCESS,
        });
      } else {
        const { selectedOrganizationId } = getState().session;
        axiosService
          .getAxios()
          .delete(`/1/organizations/${selectedOrganizationId}/webhooks/${id}`)
          .then(
            () => {
              dispatch({
                payload: id,
                type: WEBHOOKS_DELETE_SUCCESS,
              });
            },
            () => {
              dispatch(FlashActions.showCriticalDangerNotice('Cannot delete webhook. Please contact support.'));
            },
          );
      }
    },

  fetchWebhooks:
    (): ThunkAction<void, ReduxAppState, Record<string, unknown>, AnyAction> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>, getState: () => ReduxAppState) => {
      const { selectedOrganizationId } = getState().session;
      axiosService
        .getAxios()
        .get(`/1/organizations/${selectedOrganizationId}/webhooks`)
        .then(
          (response: AxiosResponse) => {
            const webhooks = response.data as Webhook[];
            dispatch({
              payload: webhooks,
              type: WEBHOOKS_FETCH_SUCCESS,
            });
          },
          () => {
            dispatch(FlashActions.showCriticalDangerNotice('Oops, cannot fetch webhooks. Please contact support.'));
          },
        );
    },

  updateWebhook:
    (webhook: Webhook): ThunkAction<void, ReduxAppState, Record<string, unknown>, AnyAction> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>, getState: () => ReduxAppState) => {
      const { selectedOrganizationId } = getState().session;
      axiosService
        .getAxios()
        .put(`/1/organizations/${selectedOrganizationId}/webhooks`, webhook)
        .then(
          () => {
            dispatch({
              payload: {
                ...webhook,
                existsOnServer: true,
              },
              type: WEBHOOKS_UPDATE_SUCCESS,
            });
            dispatch(FlashActions.showSuccessNotice('Webhook updated.', SuccessNotificationDuration));
          },
          () => {
            dispatch(FlashActions.showCriticalDangerNotice('Oops, cannot update webhooks. Please contact support'));
          },
        );
    },
};
