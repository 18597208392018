import { StateService } from '@uirouter/angularjs';
import { Auth0Service } from 'services/auth0-service.interface';
import templateUrl from './organization-specific-sso.component.html';

export class OrganizationSpecificSsoController {
  private readonly connectionName: string;

  static $inject = ['$state', 'Auth0Service'];
  constructor(private $state: StateService, private auth0Service: Auth0Service) {
    this.connectionName = this.$state.params.connectionName;
  }

  public onLogin = () => {
    this.auth0Service.loginUsingConnection(this.connectionName);
  };

  public onLoginWithoutSso = () => {
    this.$state.go('login');
  };
}

export const OrganizationSpecificSso: angular.IComponentOptions = {
  controller: OrganizationSpecificSsoController,
  templateUrl,
};
