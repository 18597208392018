import * as React from 'react';
import { Button, VStack, IconButton, Icon } from 'components/design/next';
import { useGetUserDetailsQuery, useUpsertUserDetailsMutation } from 'features/user/query-builder/user-details';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { match, P } from 'ts-pattern';
import { BookingWidget } from '../components/booking-widget';
import { Shell } from '../components/shell';
import { ShellContent } from '../components/shell-content';
import { ShellLeftPane } from '../components/shell-left-pane';
import { Title } from '../components/title';
import { useFeatureFlag } from 'features/feature-flags';
import { useRedirectToAppEntryPoint } from 'pages/sign-up/use-redirect-to-app-entry-point';

export const ConsultationAppointment: React.VFC = () => {
  const { data: userInfo } = useGetCurrentUserInfoQuery();
  const userId = userInfo?.user.id;

  const { redirectToAppEntryPoint } = useRedirectToAppEntryPoint();

  const { data: userDetails } = useGetUserDetailsQuery(userId ?? '', {
    enabled: Boolean(userId),
  });

  const upsertUserDetailsMutation = useUpsertUserDetailsMutation({
    onSuccess: redirectToAppEntryPoint,
  });

  const prefill = match(userInfo?.user)
    .with(P.not(P.nullish), user => {
      const [firstName, ...lastNames] = user.username.split(' ');

      return { name: user.username, firstName, lastName: lastNames.join(' '), email: user.email, customAnswers: {} };
    })
    .otherwise(() => ({}));

  const handleBooking = () => {
    if (!userId) return;

    upsertUserDetailsMutation.mutate({
      ...userDetails,
      userId,
      hasScheduledDemo: true,
    });
  };

  const hideMaybeLaterButton = useFeatureFlag('hideSkipConsultation');

  const maybeLaterButton = (
    <Button
      variant="solid"
      px="5"
      height="12"
      bgColor="gray.500"
      color="white"
      fontWeight="medium"
      onClick={redirectToAppEntryPoint}
      mt={-25}
    >
      Maybe Later
    </Button>
  );

  return (
    <Shell alignItems={{ base: 'center', lg: 'flex-start' }}>
      <ShellLeftPane />

      <ShellContent minHeight={{ base: 'unset', lg: '100vh' }} maxWidth={{ base: 'xl', lg: 'full' }}>
        <VStack spacing="4" alignItems="center" maxWidth="100%" w="full">
          <VStack w="full" position="relative">
            <Title>Talk with an expert</Title>

            <IconButton
              position="absolute"
              right="0"
              borderRadius="full"
              borderWidth="thin"
              borderColor="black"
              borderStyle="solid"
              bgColor="transparent"
              size="sm"
              aria-label="Skip"
              icon={<Icon icon="close" color="black" size="4" />}
              _hover={{ bgColor: 'gray.100' }}
              onClick={redirectToAppEntryPoint}
            />
          </VStack>

          <BookingWidget onBook={handleBooking} eventLink={EVENT_LINK} prefill={prefill} />

          {!hideMaybeLaterButton && maybeLaterButton}
        </VStack>
      </ShellContent>
    </Shell>
  );
};

const EVENT_LINK = 'https://calendly.com/ps-consult/process-consultation';
