import { ConditionalFilterProgressStatusOperand, ProgressStatus } from '@process-street/subgrade/dashboard';
import { ChecklistStatusMultiselect } from 'components/common/checklist-status-multiselect';
import React, { useEffect, useState } from 'react';

export interface ChecklistStatusListOperandEditorProps {
  operand: ConditionalFilterProgressStatusOperand;
  onChange: (operand: ConditionalFilterProgressStatusOperand) => void;
}

export const ChecklistStatusListOperandEditor: React.FC<
  React.PropsWithChildren<ChecklistStatusListOperandEditorProps>
> = ({ operand, onChange }) => {
  const [selectedStatuses, setSelectedStatuses] = useState<ProgressStatus[]>(operand.value);
  useEffect(() => {
    setSelectedStatuses(operand.value);
  }, [operand.value]);

  const handleSelect = (statuses: ProgressStatus[]) => {
    setSelectedStatuses(statuses);

    onChange({ ...operand, value: statuses });
  };

  return (
    <ChecklistStatusMultiselect
      id="ChecklistStatusListOperandMultiselect"
      statuses={selectedStatuses}
      onDone={handleSelect}
    />
  );
};
