import * as React from 'react';
import { Box, Icon, IconButton, Popover, PopoverTrigger, Tooltip, useDisclosure, Portal } from 'components/design/next';
import { InboxItem } from '@process-street/subgrade/inbox';
import { useSnoozeHandler } from 'pages/tasks/hooks/use-snooze-handler';
import { getInboxItemDisplayProps } from '../cell-renderer/name-renderer';
import { SnoozeButtonPopoverContent } from './popover-content';
import { DateUtils, dayjs } from '@process-street/subgrade/util';
import { useTimezone } from 'app/hooks/use-timezone';

export type SnoozeButtonProps = {
  inboxItem: InboxItem;
  mode?: 'inbox' | 'drawer';
  onSnooze?: (untilDate?: number) => void;
};

export const SnoozeButton = ({ inboxItem, onSnooze, mode = 'inbox' }: SnoozeButtonProps) => {
  const popoverDisclosure = useDisclosure();
  const timezone = useTimezone({ fallback: 'UTC' });
  const { snoozedUntilDate } = inboxItem;

  const handleSnooze = useSnoozeHandler(inboxItem, {
    onSuccess: (_data, variables) => {
      popoverDisclosure.onClose();
      onSnooze?.(variables.untilDate);
    },
  });

  const { name } = getInboxItemDisplayProps(inboxItem);

  const isInbox = mode === 'inbox';
  const button = (
    <IconButton
      colorScheme="gray"
      variant={isInbox ? 'ghost' : 'outline'}
      size={isInbox ? 'xs' : 'sm'}
      aria-label={`${snoozedUntilDate ? 'Unsnooze' : 'Snooze'} ${name}`}
      icon={
        <Icon
          icon="snooze"
          size={isInbox ? '3' : '4'}
          color={!isInbox && snoozedUntilDate ? 'purple.500' : undefined}
        />
      }
      borderRadius={'4px'}
      _hover={{
        bgColor: 'gray.100',
      }}
      color="gray.500"
      borderWidth="px"
      borderColor="gray.300"
      bgColor={popoverDisclosure.isOpen ? 'gray.50' : undefined}
      // Keep button visible while the popover is open
      _groupHover={{
        opacity: 1,
      }}
      {...(snoozedUntilDate ? { onClick: () => handleSnooze(null) } : {})}
    />
  );

  if (snoozedUntilDate) {
    const snoozedUntilDateFormatted = DateUtils.formatSnoozeDate(dayjs(snoozedUntilDate).tz(timezone));

    return (
      <Box position={isInbox ? 'absolute' : 'unset'} right={isInbox ? '4' : 'unset'}>
        <Tooltip
          label={
            <Box>
              <Box>End snooze.</Box> (Snoozed until {snoozedUntilDateFormatted})
            </Box>
          }
          maxW="45"
          textAlign="center"
        >
          {button}
        </Tooltip>
      </Box>
    );
  }

  return (
    <Popover isLazy {...popoverDisclosure}>
      <PopoverTrigger>
        <Box position={isInbox ? 'absolute' : 'unset'} right={isInbox ? '4' : 'unset'}>
          <Tooltip label="Snooze this item to hide it from this list for a while" maxW="200px" textAlign="center">
            {button}
          </Tooltip>
        </Box>
      </PopoverTrigger>

      {isInbox ? (
        <Portal>
          <SnoozeButtonPopoverContent isOpen={popoverDisclosure.isOpen} onSnooze={handleSnooze} />
        </Portal>
      ) : (
        <SnoozeButtonPopoverContent isOpen={popoverDisclosure.isOpen} onSnooze={handleSnooze} />
      )}
    </Popover>
  );
};
