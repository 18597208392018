import angular from 'angular';
import { DaoCacheConstants } from 'app/dao/dao-cache-constants';
import { EventName } from 'services/event-name';

angular.module('frontStreetApp.dao').service('RuleDao', function ($q, $rootScope, DaoCacheService, RulesApi) {
  const self = this;

  const cache = DaoCacheService.getDaoCache();
  const promiseMap = {};

  const k = DaoCacheService.generateK(DaoCacheConstants.Namespace.RULE);

  const ALL_BY_TEMPLATE_REVISION_ID = '*trid';
  const ALL_BY_CHECKLIST_REVISION_ID = '*crid';

  $rootScope.$on(EventName.WIDGET_DELETE_OK, (__event, widget) => {
    cache.remove(k(ALL_BY_TEMPLATE_REVISION_ID, widget.header.taskTemplate.templateRevision.id));
  });

  $rootScope.$on(EventName.TASK_TEMPLATE_BULK_DELETE_OK, (__event, taskTemplatesToDelete) => {
    // the list of task templates to delete should never be empty
    cache.remove(k(ALL_BY_TEMPLATE_REVISION_ID, taskTemplatesToDelete[0].templateRevision.id));
  });

  $rootScope.$on(EventName.TASK_TEMPLATE_BULK_MOVE_OK, (__event, taskTemplatesToUpdate) => {
    // the list of task templates to update should never be empty
    cache.remove(k(ALL_BY_TEMPLATE_REVISION_ID, taskTemplatesToUpdate[0].templateRevision.id));
  });

  /**
   * Gets a list of all rules by template revision id
   *
   * @param templateRevisionId
   * @param flushCache
   * @return {Promise}
   */
  self.getAllByTemplateRevisionId = function (templateRevisionId, flushCache) {
    const cacheKey = k(ALL_BY_TEMPLATE_REVISION_ID, templateRevisionId);

    const rules = cache.get(cacheKey);

    let promise;

    if (angular.isDefined(rules) && !flushCache) {
      promise = $q.resolve(rules);
    } else if (promiseMap[cacheKey]) {
      promise = promiseMap[cacheKey];
    } else {
      promise = RulesApi.getAllByTemplateRevisionId({ templateRevisionId })
        .$promise.then(result => cache.put(cacheKey, result.definitions))
        .finally(() => {
          delete promiseMap[cacheKey];
        });
      promiseMap[cacheKey] = promise;
    }

    return promise;
  };

  self.getAllByChecklistRevisionId = (checklistRevisionId, flushCache) => {
    const cacheKey = k(ALL_BY_CHECKLIST_REVISION_ID, checklistRevisionId);

    const rules = cache.get(cacheKey);

    let promise;

    if (angular.isDefined(rules) && !flushCache) {
      promise = $q.resolve(rules);
    } else if (promiseMap[cacheKey]) {
      promise = promiseMap[cacheKey];
    } else {
      promise = RulesApi.getAllByChecklistRevisionId({ checklistRevisionId })
        .$promise.then(result => cache.put(cacheKey, result.definitions))
        .finally(() => {
          delete promiseMap[cacheKey];
        });
      promiseMap[cacheKey] = promise;
    }

    return promise;
  };

  self.createOrUpdateByTemplateRevisionId = (
    templateRevisionId,
    rules,
    hiddenByDefaultModels,
    hiddenByDefaultWidgetModels,
  ) => {
    return RulesApi.createOrUpdateByTemplateRevisionId({
      templateRevisionId,
      definitions: rules,
      hiddenByDefaults: hiddenByDefaultModels,
      widgetsHiddenByDefault: hiddenByDefaultWidgetModels,
    }).$promise.then(result => {
      cache.remove(k(ALL_BY_TEMPLATE_REVISION_ID, templateRevisionId));

      return result;
    });
  };

  self.deltaUpdate = (templateRevisionId, delta) => {
    return RulesApi.deltaUpdate({
      templateRevisionId,
      ...delta,
    }).$promise.then(result => {
      cache.remove(k(ALL_BY_TEMPLATE_REVISION_ID, templateRevisionId));

      return result;
    });
  };
});
