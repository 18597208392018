import angular from 'angular';
import {
  ChecklistStatus,
  ConflictResultType,
  TaskStatus,
  TaskTemplateTaskType,
} from '@process-street/subgrade/process';

angular
  .module('frontStreetApp.services')
  .service(
    'TaskListService',
    function ($q, RequiredFieldService, SessionService, TaskService, TaskTemplateService, ToastService, $state) {
      const self = this;

      /**
       * Task List Mode
       * @readonly
       * @enum {string}
       */
      self.TaskListModeMap = {
        TEMPLATE: 'template',
        CHECKLIST: 'checklist',
      };

      self.isTemplateMode = function (mode) {
        return mode === self.TaskListModeMap.TEMPLATE;
      };

      self.isChecklistMode = function (mode) {
        return !self.isTemplateMode(mode);
      };

      self.areAllTasksCompleted = function (taskTemplates, taskMap) {
        return (
          taskTemplates &&
          taskTemplates.every(
            taskTemplate =>
              TaskTemplateService.isHeading(taskTemplate) ||
              taskMap[taskTemplate.group.id].hidden ||
              taskMap[taskTemplate.group.id].status === TaskStatus.Completed,
          )
        );
      };

      self.isTaskDue = function (dueDate) {
        return dueDate < Date.now();
      };

      /**
       * Returns a task template (taskTemplate) above <b>task template</b> to be selected (active).
       * Looks for a task that is not in <b>_deleting</b> state.
       *
       * @param taskTemplate will be used as a lower bound and it cannot be selected
       * @param taskTemplates
       * @param tasks [optional] list of tasks. Used only for checklist case
       * @returns {TaskTemplate} <b>taskTemplate</b> above if it exists and not in deleting state,
       *                    same <b>taskTemplate</b> - otherwise
       */
      self.getTaskTemplateAbove = function (taskTemplate, taskTemplates, tasks) {
        const index = taskTemplates.indexOf(taskTemplate);
        let newIndex = Math.max(index - 1, 0);
        const taskMap = buildTaskTemplateIdTaskMap(tasks);

        for (; newIndex >= 0; newIndex -= 1) {
          const taskTemplateAbove = taskTemplates[newIndex];
          if (canGoToTaskTemplate({ taskTemplate: taskTemplateAbove, taskMap })) {
            return taskTemplateAbove;
          }
        }

        return taskTemplate;
      };

      function canGoToTaskTemplate({ taskTemplate, taskMap }) {
        return (
          taskTemplate &&
          !taskTemplate._deleting &&
          !isTaskHidden(taskTemplate, taskMap) &&
          ($state.includes('checklist') ? taskTemplate.taskType !== TaskTemplateTaskType.AI : true)
        );
      }

      /**
       * Returns a task template (taskTemplate) below <b>taskTemplate</b> to be selected (active).
       * Looks for a taskTemplate that is not in <b>_deleting</b> state.
       *
       * @param taskTemplate will be used as an upper bound and it cannot be selected
       * @param taskTemplates
       * @param tasks [optional] list of tasks. Used only for checklist case
       * @returns {TaskTemplate} <b>taskTemplate</b> below if t exists and not in deleting state,
       *                    same <b>taskTemplate</b> - otherwise
       */
      self.getTaskTemplateBelow = function (taskTemplate, taskTemplates, tasks) {
        const taskTemplateIds = taskTemplates.map(tt => tt.id);
        const index = taskTemplateIds.indexOf(taskTemplate.id);
        let newIndex = Math.min(index + 1, taskTemplates.length - 1);
        const taskMap = buildTaskTemplateIdTaskMap(tasks);

        for (; newIndex < taskTemplates.length; newIndex += 1) {
          const taskTemplateBelow = taskTemplates[newIndex];
          if (canGoToTaskTemplate({ taskTemplate: taskTemplateBelow, taskMap })) {
            return taskTemplateBelow;
          }
        }

        return taskTemplate;
      };

      function buildTaskTemplateIdTaskMap(tasks) {
        let taskMap;
        if (tasks) {
          taskMap = {};
          tasks.forEach(t => {
            taskMap[t.taskTemplate.id] = t;
          });
        }

        return taskMap;
      }

      function isTaskHidden(taskTemplate, taskMap) {
        return taskMap && taskMap[taskTemplate.id].hidden;
      }

      self.updateTaskStatus = ({
        task,
        newStatus,
        checklistRevision,
        taskTemplates,
        taskMap,
        taskStatsMap,
        oneOffTasks,
      }) => {
        const { checklist } = checklistRevision;
        const originalChecklistStatus = checklist.status;

        //We only reach this point if task is updatable
        return TaskService.updateTaskStatus(task, newStatus, checklist).then(
          response => {
            // Rollback checklist status if any invalid field
            if (response.invalidFormFields && response.invalidFormFields.length > 0) {
              RequiredFieldService.broadcastChecklistHasInvalidFormFields(response.invalidFormFields);
            }
            if (response.checklistStatus === ChecklistStatus.Completed) {
              // Autocomplete checklist on FE if BE signals autocomplete OK
              TaskService.autoCompleteChecklistIfAllTasksCompleted({
                checklistRevision,
                taskTemplates,
                taskMap,
                taskStatsMap,
                oneOffTasks,
              });
            }
            if (response.conflictType === ConflictResultType.TimeBasedRuleRequired) {
              ToastService.openToast({
                status: 'info',
                title: 'All done for now',
                description: 'We’ll let you know when there is more to do.',
              });
            }

            return response;
          },
          response => {
            checklist.status = originalChecklistStatus;

            RequiredFieldService.broadcastChecklistHasInvalidFormFields(
              response.data && response.data.invalidFormFields,
            );

            return Promise.reject(response);
          },
        );
      };
    },
  );
