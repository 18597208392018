import * as yup from 'yup';

function minMax({ minConst = 0, maxConst = Number.MAX_VALUE, maxKey = 'max' }) {
  return {
    min: yup
      .number()
      .min(minConst)
      .when(maxKey, {
        is: max => max === undefined || max === '',
        then: (schema: yup.NumberSchema) => schema.max(maxConst),
        otherwise: (schema: yup.NumberSchema) => schema.max(yup.ref(maxKey), 'Min must be less than max.'),
      }),
    max: yup.number().min(minConst).max(maxConst),
  };
}

const defaultStringValue = (maxLength: number, minKey = 'min', maxKey = 'max') =>
  yup.string().when('hasVariables', {
    is: true,
    then: (schema: yup.StringSchema) => schema.max(maxLength).min(0),
    otherwise: (schema: yup.StringSchema) =>
      schema
        .when(minKey, (min: number | undefined, schema: yup.StringSchema) =>
          typeof min === 'number' ? schema.min(min, `Default value must be at least ${min} characters`) : schema.min(0),
        )
        .when(maxKey, (max: number | undefined, schema: yup.StringSchema) =>
          typeof max === 'number'
            ? schema.max(max, `Default value must be less than ${max} characters`)
            : schema.max(maxLength),
        ),
  });

const placeholder = (maxLength: number) =>
  yup.string().max(maxLength, `Placeholder must be less than ${maxLength} characters`);

export const SettingsSchemaValidators = {
  minMax,
  placeholder,
  defaultStringValue,
};
