import { Muid, Option } from '@process-street/subgrade/core';
import { ReduxAppState, TemplateMigrationModuleState, TemplateMigrationState } from 'reducers/types';
import { createSelector } from 'reselect';

const moduleState = (state: ReduxAppState): TemplateMigrationModuleState => state.modules.templateMigration;

const templateMigrationState = (templateId: Muid): ((appState: ReduxAppState) => Option<TemplateMigrationState>) =>
  createSelector(moduleState, (state: TemplateMigrationModuleState) => state[templateId]);

export const TemplateMigrationSelectors = {
  templateMigrationState,
};
