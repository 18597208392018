enum CustomErrorCode {
  FORCED_SSO_RELOGIN = 'forced_sso_relogin',
  LOCKED = 'locked',
  UNCONSTRAINED_LOCKED = 'unconstrained-locked',
}

const NoLoggingRequiredCustomErrorCodes = [CustomErrorCode.FORCED_SSO_RELOGIN, CustomErrorCode.LOCKED];

enum StandardErrorCode {
  INVALID_USER_PASSWORD = 'invalid_user_password',
  LOGIN_REQUIRED = 'login_required',
  REQUEST_ERROR = 'request_error',
}

export enum Auth0ConnectionName {
  Google = 'google-oauth2',
  Microsoft = 'microsoft-login',
}

const NoLoggingRequiredStandardErrorCodes = [StandardErrorCode.LOGIN_REQUIRED, StandardErrorCode.INVALID_USER_PASSWORD];

const ConnectionLabels: Record<Auth0ConnectionName, string> = {
  [Auth0ConnectionName.Google]: 'Google',
  [Auth0ConnectionName.Microsoft]: 'Microsoft',
};

export const Auth0Constants = {
  Connection: Auth0ConnectionName,
  ConnectionLabels,
  CustomErrorCode,
  NoLoggingRequiredCustomErrorCodes,
  StandardErrorCode,
  NoLoggingRequiredStandardErrorCodes,
};
