import { Identifiable, isModelRef, Muid, Option } from '../core';
import { identity, idSelector, toEntityMap, toEntityMapUsingIdSelector } from './reducer-utils';
import { EntityMap, EntityNormalizer, SelectorFunction } from './types';

/**
 * Upserts entities to entity map
 * @param state
 * @param entities
 * @param normalize
 */
function upsertAll<T extends Identifiable>(
  state: EntityMap<T>,
  entities: T[],
  normalize: EntityNormalizer<T> = identity,
): EntityMap<T> {
  return { ...state, ...toEntityMap(entities, normalize) };
}

/**
 * Upserts entities to entity map using id selector
 * @param state
 * @param entities
 * @param selectId
 * @param normalize
 */
function upsertAllUsingIdSelector<T extends Identifiable>(
  state: EntityMap<T>,
  entities: T[],
  selectId: SelectorFunction<T> = idSelector,
  normalize: EntityNormalizer<T> = identity,
): EntityMap<T> {
  return { ...state, ...toEntityMapUsingIdSelector(entities, selectId, normalize) };
}

/**
 * Upserts one entity to entity map
 * @param state
 * @param entity
 * @param normalize
 */
function upsert<T extends Identifiable>(
  state: EntityMap<T>,
  entity: Option<T>,
  normalize: EntityNormalizer<T> = identity,
): EntityMap<T> {
  return isModelRef(entity) ? Object.assign({}, state, { [entity.id]: normalize(entity) }) : state;
}

/**
 * Deletes one entity from entity map
 * @param state
 * @param entity
 */
function deleteOne<T extends Identifiable>(state: EntityMap<T>, entity?: T): EntityMap<T> {
  if (!entity) {
    return state;
  }
  const newState = Object.assign({}, state);
  delete newState[entity.id];
  return newState;
}

/**
 * Deletes one entity from entity map by its id
 * @param state
 * @param entity
 */
function deleteById<T extends Identifiable>(state: EntityMap<T>, id: Muid): EntityMap<T> {
  const newState = Object.assign({}, state);
  delete newState[id];
  return newState;
}

/**
 * Deletes all given entities from entity map
 * @param state
 * @param entities
 */
function deleteAll<T extends Identifiable>(state: EntityMap<T>, entities: T[]): EntityMap<T> {
  const newState = Object.assign({}, state);
  entities.forEach(entity => {
    delete newState[entity.id];
  });
  return newState;
}

/**
 * Deletes all entities from entity map by given ids
 * @param state
 * @param ids
 */
function deleteAllByIds<T extends Identifiable>(state: EntityMap<T>, ids: Muid[]) {
  const newState = Object.assign({}, state);
  ids.forEach(id => {
    delete newState[id];
  });
  return newState;
}

export const EntitiesReducerUtils = {
  delete: deleteOne,
  deleteAll,
  deleteAllByIds,
  deleteById,
  upsert,
  upsertAll,
  upsertAllUsingIdSelector,
};
