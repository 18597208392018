import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  useToast,
} from 'components/design/next';

import { GetAttachmentsQuery, useDeleteAttachmentMutation } from 'features/comments/query-builder';
import { useInjector } from 'components/injection-provider';
import { queryClient } from 'components/react-root';
import { Checklist, Task, Template, Attachment } from '@process-street/subgrade/process';
import { HttpStatus } from '@process-street/subgrade/util';
import { AttachmentEvent } from 'services/events/attachment-event';
import { GetAllActivitiesByTypeQuery } from 'features/activities/query-builder';
import { ActivityObjectType } from '@process-street/subgrade/activity';
import { DefaultErrorMessages } from 'components/utils/error-messages';

type DeleteAttachmentDialogProps = {
  id: Attachment['id'];
  task: Task;
  checklistId: Checklist['id'];
  templateId: Template['id'];
} & Omit<AlertDialogProps, 'children' | 'leastDestructiveRef'>;

export const DeleteAttachmentDialog: React.FC<React.PropsWithChildren<DeleteAttachmentDialogProps>> = ({
  id,
  task,
  checklistId,
  templateId,
  onClose,
  isOpen,
  ...props
}) => {
  const { $rootScope } = useInjector('$rootScope');
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const toast = useToast();

  const deleteAttachmentMutation = useDeleteAttachmentMutation({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Attachment deleted',
      });

      $rootScope.$broadcast(AttachmentEvent.ATTACHMENT_DELETE_OK, task.id, checklistId);

      queryClient.invalidateQueries(
        GetAttachmentsQuery.getKey({ checklistRevisionId: task.checklistRevision.id, templateId }),
      );
      queryClient.invalidateQueries(
        GetAllActivitiesByTypeQuery.getKey({
          checklistId,
          type: 'checklist',
          objectType: ActivityObjectType.Attachment,
        }),
      );
    },
    onError: e => {
      const description =
        e.response?.status === HttpStatus.CONFLICT
          ? `We couldn't delete your attachment because the workflow run has been updated`
          : DefaultErrorMessages.unexpectedErrorDescription;

      $rootScope.$broadcast(AttachmentEvent.ATTACHMENT_DELETE_FAILED, task.id, checklistId);

      toast({
        status: 'error',
        title: "We're having problems deleting the attachment",
        description,
      });
    },
  });
  const handleDeleteAttachment = () => deleteAttachmentMutation.mutate({ id });

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} {...props} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Attachment
          </AlertDialogHeader>

          <AlertDialogBody>This attachment will be deleted and cannot be recovered.</AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="secondary" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleDeleteAttachment();
                onClose();
              }}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
