import {
  DateFormFieldConstraints,
  DateFormFieldConstraintsSourceType,
  DateFormFieldWidget,
  FormFieldWidget,
  isDateFormFieldWidget,
  isFormFieldWidget,
} from '@process-street/subgrade/process';
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  useModalContext,
  Text,
  ModalFooter,
  ButtonGroup,
  Button,
  Box,
  List,
  ListItem,
  HStack,
  IconButton,
  Icon,
} from 'components/design/next';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import * as React from 'react';
import { DateFieldConstraint, useDateSettingsConstraints } from './use-date-settings-constraints';
import { AddConstraintButton, DateFieldAddConstraintItem } from './add-constraint-button';
import { useDebouncedCallback } from 'use-debounce';

export type DateSettingsWithDynamicParametersModalContentProps = {
  widget: DateFormFieldWidget;
  onUpdate: (widget: DateFormFieldWidget) => Promise<DateFormFieldWidget> | void;
};

// Once this is live, rename this file and component to date-selector.tsx and remove the old one.
export const DateSettingsWithDynamicParametersModalContent = ({
  widget,
  onUpdate,
}: DateSettingsWithDynamicParametersModalContentProps) => {
  const { onClose } = useModalContext();
  const [key, setKey] = React.useState(0);
  const [hasRemovedExistingConstraint, setHasRemovedExistingConstraint] = React.useState(false);

  const dateWidgetsQuery = useWidgetsByTemplateRevisionIdQuery(widget.templateRevision.id, {
    select: widgets =>
      widgets.filter(
        (w): w is FormFieldWidget => isFormFieldWidget(w) && isDateFormFieldWidget(w) && w.id !== widget.id,
      ),
  });

  const dateWidgets = dateWidgetsQuery.data ?? [];

  const { constraints, setConstraints, getConstraintDefinitionString } = useDateSettingsConstraints(
    widget.constraints,
    dateWidgets,
  );
  const existingConstraintDirection = constraints[0]?.direction;

  const handleOnSubmit = () => {
    const afterConstraint = constraints.find(constraint => constraint.direction === 'after');
    const beforeConstraint = constraints.find(constraint => constraint.direction === 'before');
    const updatedConstraints: Partial<DateFormFieldConstraints> = {
      afterDate: afterConstraint?.date,
      beforeDate: beforeConstraint?.date,
      afterDateFormFieldWidgetGroupId: afterConstraint?.targetDateWidgetGroupId,
      beforeDateFormFieldWidgetGroupId: beforeConstraint?.targetDateWidgetGroupId,
      afterDateSourceType: afterConstraint?.sourceType,
      beforeDateSourceType: beforeConstraint?.sourceType,
      afterDateOffset:
        afterConstraint?.sourceType === DateFormFieldConstraintsSourceType.SpecificDate
          ? undefined
          : afterConstraint?.offset,
      beforeDateOffset:
        beforeConstraint?.sourceType === DateFormFieldConstraintsSourceType.SpecificDate
          ? undefined
          : beforeConstraint?.offset,
    };

    const updatedWidget = { ...widget, constraints: updatedConstraints };

    onUpdate(updatedWidget);
    onClose();
  };

  const handleOnAddConstraint = (newConstraintValues: DateFieldAddConstraintItem) => {
    const newConstraint: DateFieldConstraint = {
      date: newConstraintValues.specificDate,
      offset: newConstraintValues.offset,
      targetDateWidgetGroupId: newConstraintValues.formFieldWidgetGroupId,
      direction: newConstraintValues.constraintDirection,
      sourceType: newConstraintValues.sourceType,
      isPersisted: false,
    };
    setConstraints(prev => [...prev, newConstraint]);
  };

  const handleOnRemoveConstraint = (constraint: DateFieldConstraint) => {
    setConstraints(prev => [...prev.filter(c => c !== constraint)]);
    if (constraint.isPersisted) {
      setHasRemovedExistingConstraint(true);
    }
  };

  const hasChanges = React.useMemo(() => {
    // Has changes if some of the constraint is new (not persisted)
    if (constraints.some(constraint => !constraint.isPersisted)) {
      return true;
    }
    // Has changes if an existing constraint is not in state anymore
    return hasRemovedExistingConstraint;
  }, [constraints, hasRemovedExistingConstraint]);

  // Hack to re render add constriant popover, if not debounced works fine but causes issues
  // in RTL, where tests never ends.
  const reRenderAddButtonPopover = useDebouncedCallback(() => setKey(prev => prev + 1), 100);

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader p="8">
        <Text variant="2">{widget.label || 'Untitled date field'}</Text>
      </ModalHeader>

      <ModalBody px="8" py="2">
        {constraints && (
          <List>
            {constraints.map((constraint, index) => {
              const constraintDescription = getConstraintDefinitionString(constraint);
              return (
                <ListItem w="full" key={`constraint-${index}`} pb="4">
                  <HStack justifyContent="space-between">
                    <Box w="full" display="flex" alignItems="center" bgColor="gray.100" borderRadius="4" py="2">
                      <Text px="2" fontWeight="medium" color="gray.600">
                        {constraintDescription}
                      </Text>
                    </Box>
                    <IconButton
                      bgColor="inherit"
                      aria-label="remove constraint"
                      icon={<Icon variant="far" icon="trash-can" size="4" color="gray.500" />}
                      onClick={() => handleOnRemoveConstraint(constraint)}
                    />
                  </HStack>
                </ListItem>
              );
            })}
          </List>
        )}
        {constraints.length < 2 && (
          <AddConstraintButton
            key={`add-constraint-${key}`}
            currentWidget={widget}
            dateWidgets={dateWidgets}
            existingConstraintDirection={existingConstraintDirection}
            onAddConstraint={handleOnAddConstraint}
            forceRerender={() => reRenderAddButtonPopover()}
          />
        )}
      </ModalBody>
      <ModalFooter p={6}>
        <ButtonGroup>
          <Button aria-label="cancel changes" variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button aria-label="set constraints" type="submit" isDisabled={!hasChanges} onClick={handleOnSubmit}>
            Apply
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  );
};
