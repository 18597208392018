import angular from 'angular';
import { ChecklistStatus } from '@process-street/subgrade/process';
import { HttpStatus } from '@process-street/subgrade/util';
import mainTemplate from './template-left-pane.component.html';
import shareTemplate from '../../features/template/share.component.html';
import './template-left-pane.scss';
import { trace } from 'components/trace';
import { TaskListEvent } from 'directives/task-list/task-list-event';

angular.module('frontStreetApp.directives').component('psTemplateLeftPane', {
  bindings: {
    editable: '<',
    templateRevision: '<',
    singleTaskTemplateGroupId: '<',
    widgetsMap: '<',

    userCanManageTasks: '<',
    userCanUpdateTemplate: '<',
    userCanShareTemplate: '<',
    userAnonymous: '<',

    onSelectTaskTemplate: '&',
    onSingleTaskTemplateSet: '&',
    onTaskTemplateNameChanged: '&',

    onToggleWidgetsVisibility: '&',
    onSetWidgetsVisibility: '&',

    onTitleUpdated: '&',

    onInitWidgets: '&',
    onCleanUpWidgets: '&',
    onGetDuplicatedWidgets: '&',
  },
  templateUrl: mainTemplate,
  controller($scope, $ngRedux, $timeout, MessageBox, SessionService, TemplateService, ToastService) {
    const ctrl = this;

    const logger = trace({ name: 'psTemplateLeftPane' });
    logger.info('loading ctrl');

    ctrl.multiSelectMenuOn = false;

    const mapStateToThis = () => _state => {
      const showBottomMenu = ctrl.editable && ctrl.multiSelectMenuOn;
      return {
        templateId: ctrl.templateId,
        shouldShowFocusBar: !ctrl.userAnonymous,
        shouldShowTrailingAutomationsButton: ctrl.userCanUpdateTemplate,
        shouldShowCreateTaskMenu: showBottomMenu,
      };
    };

    ctrl.unsubscribe = $ngRedux.connect(mapStateToThis)(ctrl);
    ctrl.$onDestroy = () => {
      ctrl.unsubscribe();
    };

    ctrl.$onChanges = function (changes) {
      if (changes.templateRevision && changes.templateRevision.currentValue) {
        ctrl.templateRevision = changes.templateRevision.currentValue;
        initializeAsTemplate(ctrl.templateRevision);
      }
    };

    function initializeAsTemplate(templateRevision) {
      ctrl.templateTitle = templateRevision.template.name;
      ctrl.templateId = templateRevision.template.id;

      $scope.$watch('templateRevision.template.status', status => {
        ctrl.message = status === ChecklistStatus.Archived && 'This workflow is archived.';
      });
    }

    ctrl.setSingleTaskTemplate = function (taskTemplate, assignees, canMoveUp, canMoveDown) {
      ctrl.activeTaskTemplate = taskTemplate;
      ctrl.activeTaskTemplateCanMoveUp = canMoveUp;
      ctrl.activeTaskTemplateCanMoveDown = canMoveDown;

      ctrl.onSingleTaskTemplateSet({
        taskTemplate,
        assignees,
        canMoveUp,
        canMoveDown,
      });
    };

    ctrl.taskTemplatesLoaded = false;
    ctrl.setTaskTemplatesAsLoaded = function () {
      ctrl.taskTemplatesLoaded = true;
    };

    let shareTemplateShown = false;
    ctrl.showShareTemplate = function (template) {
      if (shareTemplateShown) {
        return;
      }

      shareTemplateShown = true;
      MessageBox.custom({
        templateUrl: shareTemplate,
        controller: 'TemplateShareCtrl',
        options: {
          template,
          steps: ctrl.tasks, // FIXME pass here whether tasks empty?
        },
      }).result.finally(() => {
        // Get the template, as it may have been updated
        TemplateService.get(template.id).then(updatedTemplate => {
          ctrl.templateRevision.template = updatedTemplate;
        });

        shareTemplateShown = false;
      });
    };

    ctrl.getTemplate = function () {
      return ctrl.templateRevision && ctrl.templateRevision.template;
    };

    ctrl.showTemplateNameRequiredMessage = function () {
      ToastService.openToast({
        status: 'warning',
        title: `We couldn't updating the workflow`,
        description: 'The workflow name is required.',
      });
    };

    function updateTitle(title) {
      ctrl.templateTitle = title;
      ctrl.onTitleUpdated({ title });
    }

    ctrl.updateTemplateName = function (name) {
      const { templateRevision } = ctrl;

      const cleanedName = name && name.replace(/(?:\r\n|\r|\n)/g, ' ');
      if (cleanedName && cleanedName.length) {
        templateRevision.template.name = cleanedName;

        TemplateService.update(templateRevision.template, { name: cleanedName }).then(
          () => {
            ToastService.openToast({
              status: 'success',
              title: `Workflow name updated`,
            });

            updateTitle(templateRevision.template.name);
          },
          response => {
            if (response.status === HttpStatus.PAYMENT_REQUIRED) {
              TemplateService.showLimitReachedMessageAndRedirect();
            } else {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems updating the workflow name`,
                description: 'The workflow run has likely been updated, please refresh and try again.',
              });
            }
          },
        );
      }
    };

    ctrl.updateTemplateDescription = function (description) {
      const { templateRevision } = ctrl;
      templateRevision.template.description = description;

      TemplateService.update(templateRevision.template, { description: description || null }).then(
        () => {
          ToastService.openToast({
            status: 'success',
            title: `Workflow description updated`,
          });
        },
        response => {
          if (response.status === HttpStatus.PAYMENT_REQUIRED) {
            TemplateService.showLimitReachedMessageAndRedirect();
          } else {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems updating the workflow description`,
              description: 'The workflow run has likely been updated, please refresh and try again.',
            });
          }
        },
      );
    };

    // Task template list callbacks

    ctrl.taskTemplateNameChanged = function (taskTemplate, name) {
      ctrl.onTaskTemplateNameChanged({ taskTemplate, name });
    };

    ctrl.toggleWidgetsVisibility = function () {
      ctrl.onToggleWidgetsVisibility();
    };

    ctrl.setWidgetsVisibility = function (visible, instantly) {
      ctrl.onSetWidgetsVisibility({ visible, instantly });
    };

    ctrl.selectTaskTemplate = function (taskTemplateGroupId, replace) {
      ctrl.onSelectTaskTemplate({ taskTemplateGroupId, replace });
    };

    ctrl.initWidgets = function (taskTemplateGroupId) {
      ctrl.onInitWidgets({ taskTemplateGroupId });
    };

    ctrl.cleanUpWidgets = function (taskTemplateGroupId) {
      ctrl.onCleanUpWidgets({ taskTemplateGroupId });
    };

    ctrl.getDuplicatedWidgets = function (duplicatedTaskTemplate, newTaskTemplate) {
      ctrl.onGetDuplicatedWidgets({
        duplicatedTaskTemplate,
        newTaskTemplate,
      });
    };

    // Listeners

    $scope.$on(TaskListEvent.SHOW_MULTI_SELECT_MENU, () => {
      ctrl.multiSelectMenuOn = false;
    });

    $scope.$on(TaskListEvent.HIDE_MULTI_SELECT_MENU, () => {
      ctrl.multiSelectMenuOn = true;
    });
  },
});
