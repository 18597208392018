import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import {
  BodyScrollEvent,
  CellMouseOverEvent,
  ColDef,
  GridReadyEvent,
  ModelUpdatedEvent,
  SelectionChangedEvent,
} from '@ag-grid-community/core';
import * as AgGridReactModule from '@ag-grid-community/react';
import { AgGridReact } from '@ag-grid-community/react';
import { OrganizationMembership, OrganizationMembershipRole } from '@process-street/subgrade/core';
import { ChecklistColumn, ColumnDto } from '@process-street/subgrade/dashboard';
import { AngularDependenciesContext } from 'components/common/context/angular-dependencies-context';
import { Sorting } from 'components/dashboard/models/filters';
import { ColumnOrderMap, ColumnSizeMap, RowValues } from 'components/dashboard/models/grid';
import { SavedView } from 'components/dashboard/models/saved-views';
import { GridDataSource } from 'components/dashboard/services/grid-data-source';
import { GridHelper } from 'components/dashboard/services/grid-helper';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDeepCompareEffect, usePrevious } from 'react-use';
import styles from './ChecklistDashboardGrid.module.scss';
import '@ag-grid-community/styles/ag-grid-no-native-widgets.css';
import classNames from 'classnames';
import { useFeatureFlag } from 'features/feature-flags';
import { useSelector } from 'react-redux';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { useDashboardGridContextStore } from './ChecklistDashboardGridContextStore';
import { useCanRunWorkflows } from 'hooks/use-can-run-workflows';
import { ReassignModal } from './renderers/AssigneesRenderer/reassign-modal';
import { Box } from '@chakra-ui/react';
import { useChecklistScreenSlice } from '../ChecklistDashboardScreen/checklist-screen-store';
import { ChecklistDashboardSelectionBarActionsWrapper } from '../ChecklistDashboardSelectionBarActionsWrapper';
import { checklistDashboardGridOptions } from 'components/dashboard/services/grid-options';

export interface ChecklistDashboardGridProps {
  columns: ColumnDto[];
  savedView: SavedView;
  disableBulkSelection?: boolean;
  showEnhancedEmptyState?: boolean;
  organizationMembership: OrganizationMembership;
  onOrderingChange?: (map: ColumnOrderMap) => void;
  onSortingChange?: (sorting?: Sorting) => void;
  onSizingChange?: (map: ColumnSizeMap) => void;
  onModelUpdated?: (event: ModelUpdatedEvent) => void;
}

export const ChecklistDashboardGrid: React.FunctionComponent<React.PropsWithChildren<ChecklistDashboardGridProps>> = ({
  columns,
  savedView,
  organizationMembership,
  showEnhancedEmptyState,
  onOrderingChange,
  onSortingChange,
  onSizingChange,
  onModelUpdated,
  disableBulkSelection = false,
}) => {
  const {
    services: { gridDataService },
  } = useContext(AngularDependenciesContext);

  const orgMembership = useSelector(OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId);
  const isBulkDeleteArchiveEnabled = useFeatureFlag('deleteArchiveMultipleWorkflowRunsFromReports');

  const gridRef = useRef<AgGridReact>(null);
  const [gridColumnDefs, setGridColumnDefs] = useState<ColDef[]>();

  const gridContextStore = useDashboardGridContextStore();

  const { selectedNodes, setSelectedNodes } = useChecklistScreenSlice();

  const orderingChangeHandler = React.useMemo(
    () => onOrderingChange && GridHelper.onDragStoppedHandler(onOrderingChange),
    [onOrderingChange],
  );

  const resizingHandler = React.useMemo(
    () => onSizingChange && GridHelper.onColumnResizedHandler(onSizingChange),
    [onSizingChange],
  );

  const sortChangedHandler = React.useMemo(
    () => onSortingChange && GridHelper.onSortChangedHandler(onSortingChange),
    [onSortingChange],
  );

  const gridContext = React.useMemo(
    () => ({
      savedView,
      organizationMembership,
    }),
    [organizationMembership, savedView],
  );

  /**
   * The grid will automatically reload on certain changes so we track the previous
   * saved view and col defs to track these situations.
   */
  const previousSavedView = usePrevious(savedView) ?? savedView;
  const previousColDefs: ColDef[] | undefined = usePrevious(gridColumnDefs);

  /**
   * Switching views sets a new saved view _then_ loads a new set of columns.
   * We wait for both to complete before we render the grid to avoid it loading
   * the wrong data or loading the data twice.
   */
  const loading = previousSavedView ? previousSavedView.id !== savedView.id || columns.length === 0 : true;
  const isWorkflowRunLabelEnabled = useFeatureFlag('customLabelsForWorkflowRuns');

  useEffect(() => {
    if (loading) {
      return;
    }

    let colDefs = GridHelper.getColumnDefs(columns, savedView, isWorkflowRunLabelEnabled);
    if (!isBulkDeleteArchiveEnabled || disableBulkSelection) {
      colDefs = colDefs.filter(col => col.field !== ChecklistColumn.Selection);
    }
    setGridColumnDefs(colDefs);
    const manuallyUpdateFilter = GridHelper.shouldManuallyUpdateFilters(colDefs, previousColDefs);
    if (gridRef.current?.api && manuallyUpdateFilter) {
      gridRef.current.api.onFilterChanged();
      gridRef.current.api.redrawRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on columns change
  }, [
    columns,
    isBulkDeleteArchiveEnabled,
    disableBulkSelection,
    savedView.columnsConfig.visibleIds,
    isWorkflowRunLabelEnabled,
  ]);

  useDeepCompareEffect(() => {
    if (!loading && gridRef.current?.api) {
      gridRef.current.api.onFilterChanged();
    }
  }, [savedView.filters]);

  const canRunWorkflows = useCanRunWorkflows();

  const onGridReady = React.useCallback(
    (event: GridReadyEvent) => {
      const dataSource = new GridDataSource(gridDataService, event.api, {
        showRunWorkflow: canRunWorkflows,
        showEnhancedEmptyState,
        isBulkDeleteArchiveEnabled,
      });

      // Fix AG Grid error if the user changes saved views too often
      setTimeout(() => {
        event.api.setGridOption('datasource', dataSource);
        gridContextStore.setGridApi(event.api);
      }, 0);
    },
    [canRunWorkflows, gridContextStore, gridDataService, isBulkDeleteArchiveEnabled, showEnhancedEmptyState],
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- Stop IntelliJ from showing red underlines
  const wrapperClass = classNames(styles.checklistDashboardGrid, styles.withDataSetsEnabled);

  const onCellMouseOver = React.useCallback(
    (event: CellMouseOverEvent<RowValues>) => {
      if (event.data) {
        gridContextStore.setHoveredRowId(event.data.id);
        gridContextStore.setHoveredColumnId(event.column.getColId());
      }
    },
    [gridContextStore],
  );

  const onBodyScroll = React.useCallback(
    (event: BodyScrollEvent) => {
      if (event.direction === 'vertical') {
        gridContextStore.setHoveredRowId(null);
        gridContextStore.setHoveredColumnId(null);
      }
    },
    [gridContextStore],
  );

  const handleSelectionChanged = React.useCallback(
    ({ api }: SelectionChangedEvent) => {
      setSelectedNodes(api?.getSelectedNodes() ?? []);
    },
    [setSelectedNodes],
  );

  useEffect(() => {
    gridContextStore.setCanReassign(
      !!orgMembership &&
        [OrganizationMembershipRole.Admin, OrganizationMembershipRole.FullMember].includes(orgMembership.role),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore gridContextStore
  }, [orgMembership]);

  return (
    <>
      <ChecklistDashboardSelectionBarActionsWrapper
        mode="WorkflowRun"
        onActionCompleted={async () => {
          gridRef.current?.api.refreshInfiniteCache();
          gridRef.current?.api.getSelectedNodes().forEach(node => node.setSelected(false));
        }}
      />

      <Box
        className={wrapperClass}
        sx={{
          '.ag-selection-checkbox': {
            'minH': '55px',
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',

            '&.ag-invisible': {
              display: 'none',
            },

            '.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper': {
              'w': 5,
              'h': 5,
              'mr': 3,
              'mt': -1,
              'border': '1px solid',
              'borderColor': 'gray.400',
              'borderRadius': 'sm',
              'cursor': 'pointer',
              'opacity': selectedNodes.length > 0 ? 1 : 0,

              'input': {
                appearance: 'none',
                opacity: 0,
                cursor: 'pointer',
                w: 'full',
                h: 'full',
              },

              '&.ag-checked': {
                'bgColor': 'brand.500',
                'borderColor': 'brand.500',
                'opacity': 1,

                '&::before': {
                  content: '""',
                  position: 'absolute',
                  w: 'full',
                  h: 'full',
                  bg: `url(${require('app/images/reports/check-icon.svg')})`,
                  bgPosition: 'center',
                  bgRepeat: 'no-repeat',
                },
              },
              '&.ag-disabled': {
                bgColor: 'gray.300',
              },
            },

            '&:hover': {
              '.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper': {
                opacity: '1',
              },
            },
          },

          '.ag-row-hover': {
            '.ag-selection-checkbox': {
              '.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper': {
                opacity: '1',
              },
            },
          },

          // fixes selected cells moving away by 1px
          '--ag-range-selection-border-color': 'transparent',
          '--ag-range-selection-border-style': 'solid',
        }}
      >
        {!loading && (
          <AgGridReactModule.AgGridReact
            {...checklistDashboardGridOptions}
            onDragStopped={orderingChangeHandler}
            onSortChanged={sortChangedHandler}
            onColumnResized={resizingHandler}
            columnDefs={gridColumnDefs}
            context={gridContext}
            modules={[InfiniteRowModelModule]}
            onBodyScroll={onBodyScroll}
            onCellMouseOver={onCellMouseOver}
            onGridReady={onGridReady}
            onModelUpdated={onModelUpdated}
            onSelectionChanged={handleSelectionChanged}
            ref={gridRef}
            rowSelection={isBulkDeleteArchiveEnabled ? 'multiple' : undefined}
            suppressRowClickSelection
          />
        )}
      </Box>
      <ReassignModal shouldShowTasksCount />
    </>
  );
};
