import angular from 'angular';
import { TemplateTaskAssignmentActions } from './store/template-task-assignment.actions';
import { TemplateTaskAssignmentsComponent } from './assignments/template-task-assignments.component';
import { TemplateTaskBulkAssignerComponent } from './bulk-assigner/template-task-bulk-assigner.component';

export const TemplateTaskAssignmentModule = angular
  .module('taskTemplateAssignerModule', [])
  .component('psTemplateTaskBulkAssigner', TemplateTaskBulkAssignerComponent)
  .component('psTemplateTaskAssignments', TemplateTaskAssignmentsComponent)
  .factory('TemplateTaskAssignmentActions', TemplateTaskAssignmentActions).name;
