import * as React from 'react';

import { useSelector } from '@xstate/react';
import { TaskListItem, TaskListItemProps } from '../task-list-item';
import { DueDateRuleDefinition, TemplateTaskAssignment } from '@process-street/subgrade/process';
import { TaskTemplateListActorSelectors } from './task-template-list-machine';
import { Option } from 'space-monad';
import _map from 'lodash/fp/map';
import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { useTaskTemplateListActorRef } from '../../hooks/use-task-templates-list-actor';
import { Dictionary } from 'lodash';
import { TaskAssignmentRule } from '@process-street/subgrade/role-assignment';
import { Box } from 'components/design/next';
import { Reorder, useInView } from 'framer-motion';
import { ReorderAutoScrollContext } from '../../providers/reorder-scroll-context';

type TaskListItemWrapperProps = Omit<
  TaskListItemProps,
  'assignmentRules' | 'organizationMemberships' | 'isSelected' | 'isMultiSelecting'
> & {
  isSelected: boolean;
  dueDateRulesMap: Dictionary<DueDateRuleDefinition>;
  taskAssignmentsMap: Dictionary<TemplateTaskAssignment[]>;
  organizationMembershipsMap: Dictionary<OrganizationMembershipWithUser>;
  taskAssignmentRulesMap: Dictionary<TaskAssignmentRule[]>;
};

/**
 * If the task gets this close to the viewport, render the widget.
 * (See docs for useInView and IntersectionObserver options)
 */
const TASK_VIEWPORT_MARGIN_PX = 500;

export const TaskListItemWrapper = React.memo(function TaskListItemWrapper({
  dueDateRulesMap,
  taskAssignmentsMap,
  organizationMembershipsMap,
  taskAssignmentRulesMap,
  ...props
}: TaskListItemWrapperProps) {
  const taskTemplateListActor = useTaskTemplateListActorRef();
  const taskItemRef = React.useRef(null);
  const scrollContainerRef =
    React.useContext(ReorderAutoScrollContext)?.taskAutoScroll?.scrollContainerProps.scrollContainerRef;
  const isInViewport = useInView(taskItemRef, { margin: TASK_VIEWPORT_MARGIN_PX + 'px', root: scrollContainerRef });
  const isReorderEnabled = !props.isReadOnly;

  const dueDateRuleDefinition = React.useMemo(
    () => dueDateRulesMap[props.taskTemplate.group.id],
    [dueDateRulesMap, props.taskTemplate.group.id],
  );

  const organizationMemberships = React.useMemo(
    () =>
      Option(taskAssignmentsMap[props.taskTemplate.id])
        .map(_map(taskAssignment => organizationMembershipsMap[taskAssignment.organizationMembership.id]))
        .getOrElse([]),
    [organizationMembershipsMap, props.taskTemplate.id, taskAssignmentsMap],
  );

  const assignmentRules = React.useMemo(() => {
    return taskAssignmentRulesMap[props.taskTemplate.group.id] ?? [];
  }, [props.taskTemplate.group.id, taskAssignmentRulesMap]);
  const isMultiSelecting = useSelector(taskTemplateListActor, TaskTemplateListActorSelectors.isMultiSelecting);
  const reorderItemProps = React.useContext(ReorderAutoScrollContext)?.taskAutoScroll?.reorderItemProps;

  return (
    <Box
      // if it was HStack, we'd get typing issues about onDrag
      ref={taskItemRef}
      height={10}
      display="flex"
      value={props.taskTemplate}
      w="full"
      {...(isReorderEnabled
        ? {
            as: Reorder.Item,
            ...reorderItemProps,
            key: props.taskTemplate.id,
          }
        : {})}
    >
      <>
        {isInViewport && (
          <TaskListItem
            assignmentRules={assignmentRules}
            dueDateRuleDefinition={dueDateRuleDefinition}
            organizationMemberships={organizationMemberships}
            isMultiSelecting={isMultiSelecting}
            {...props}
          />
        )}
      </>
    </Box>
  );
});
