import * as React from 'react';
import { Icon, Text, UnorderedList, ListItem } from 'components/design/next';
import { MultiSelectItemValueStatus } from '@process-street/subgrade/process';
import { useGetAllActivitiesByTypeQuery } from 'features/activities/query-builder';
import { ActivityObjectType, ActivityVerb, SubtaskUpdatedActivity } from '@process-street/subgrade/activity';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { User } from '@process-street/subgrade/core';
import { DateUtils } from '@process-street/subgrade/util';
import { useCurrentUser } from 'hooks/use-current-user';

type PrintActivityProps = {
  task: OneOffTask;
};

export const PrintActivity = ({ task }: PrintActivityProps) => {
  const oneOffTasksAcitvitiesQuery = useGetAllActivitiesByTypeQuery(
    {
      type: 'checklist',
      checklistId: task.internalChecklistId,
      objectType: ActivityObjectType.FormFieldValue,
    },
    {
      enabled: Boolean(task.internalChecklistId),
    },
  );
  const currentUser = useCurrentUser();

  return (
    <UnorderedList mt={5}>
      {oneOffTasksAcitvitiesQuery.data?.data
        .filter((activity): activity is SubtaskUpdatedActivity => {
          return activity.verb === ActivityVerb.Updated && 'itemsUpdated' in activity.data;
        })
        .map(activity => {
          const sentence = activity;
          return (
            <ListItem listStyleType="none" key={sentence.id}>
              <Text fontSize="xs" color="gray.400" w="full">
                <Icon mx="2" color="gray.200" icon="bolt" size="2.5" variant="fas" />

                <Text fontSize="xs" fontWeight="bold" as="span">
                  {(sentence.subject as User).username}{' '}
                </Text>
                <Text fontSize="xs" as="span">
                  marked subtask{' '}
                </Text>

                {sentence.data.itemsUpdated.map(item => (
                  <Text fontSize="xs" as="span" key={item.id}>
                    <Text fontSize="xs" fontWeight="bold" as="span">
                      "{item.name}"
                    </Text>{' '}
                    as {item.newData.status === MultiSelectItemValueStatus.Completed ? 'completed' : 'not completed'}.
                    <Text pl={4} as="span" fontSize="xs">
                      {DateUtils.formatActivityWithTimezone({ date: sentence.date, timeZone: currentUser?.timeZone })}
                    </Text>
                  </Text>
                ))}
              </Text>
            </ListItem>
          );
        })}
    </UnorderedList>
  );
};
