export const TaskPermissionRuleApi = function (TaskPermissionRules) {
  'ngInject';

  const getAllByTemplateRevisionId = templateRevisionId =>
    TaskPermissionRules.getAllByTemplateRevisionId({ templateRevisionId }).$promise;

  const upsert = (templateRevisionId, createRequest) =>
    TaskPermissionRules.upsert({ templateRevisionId, ...createRequest }).$promise;

  const upsertAll = (templateRevisionId, createRequest) =>
    TaskPermissionRules.upsertAll({ templateRevisionId, ...createRequest }).$promise;

  const deleteById = (templateRevisionId, ruleId) =>
    TaskPermissionRules.deleteById({ templateRevisionId, ruleId }).$promise;

  const deleteAllByIds = (templateRevisionId, ruleIds) =>
    TaskPermissionRules.deleteAllByIds({ templateRevisionId, ids: ruleIds }).$promise;

  return {
    deleteAllByIds,
    deleteById,
    getAllByTemplateRevisionId,
    upsert,
    upsertAll,
  };
};
