import * as React from 'react';
import { TickRendererProps } from '@visx/axis/lib/types';
import { useBreakpointValue, forwardRef, useToken } from 'components/design/next';
import { getStringWidth } from '@visx/text';
import { useBarStackHorizontalMargin } from './bar-stack-horizontal-margin-context';

const MAX_STRING_LENGTH_BASE = 6;
const MAX_STRING_LENGTH_MD = 36;

export const BarStackHorizontalTick = forwardRef<TickRendererProps, 'text'>(
  ({ formattedValue = '', ...tickProps }, ref) => {
    const fontSizeXs = useToken('fontSizes', 'xs');
    const maxStringLength =
      useBreakpointValue({ base: MAX_STRING_LENGTH_BASE, md: MAX_STRING_LENGTH_MD }) ?? MAX_STRING_LENGTH_MD;

    const margin = useBarStackHorizontalMargin();

    const marginLeft = margin.left;

    const width = getStringWidth(formattedValue, { fontSize: fontSizeXs }) ?? 0;
    const xMargin = parseInt(useToken('space', '4'), 10);
    const adjustedName =
      width > marginLeft - xMargin ? `${formattedValue.slice(0, maxStringLength - 1)}…` : formattedValue;

    return (
      <g>
        <text ref={ref} {...tickProps}>
          {adjustedName}
        </text>
      </g>
    );
  },
);
