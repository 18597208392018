import _keyBy from 'lodash/keyBy';
import { DataSetRow, SelectFormFieldConfigItem, SelectFormFieldValue } from '@process-street/subgrade/process';
import { Dictionary } from 'lodash';
import { isUndefined, MuidUtils } from '@process-street/subgrade/core';
import { DataSetUtils } from 'app/pages/reports/data-sets/components/data-set-page/data-set-utils';

export namespace ChecklistSelectFormFieldWidgetHelper {
  export type Option = {
    value: string;
    label: string;
    orphaned?: boolean;
  };

  export const getLinkedOptions = (
    data: DataSetRow[],
    linkedColumnId: string,
    formFieldValue: SelectFormFieldValue | undefined,
  ): [Option[], Dictionary<Option>] => {
    const options = data.map(row => ({
      value: row.id,
      label: DataSetUtils.parseDataSetValue(row.cells[linkedColumnId!]?.value),
    }));

    const optionsWithOrphanedEntry = getOptionsWithOrphanedOption(formFieldValue, options);
    const optionsMap = _keyBy(optionsWithOrphanedEntry, 'value');

    return [optionsWithOrphanedEntry, optionsMap];
  };

  const getOptionsWithOrphanedOption = (
    formFieldValue: SelectFormFieldValue | undefined,
    options: Option[],
  ): Option[] => {
    if (!formFieldValue || !formFieldValue.fieldValue.dataSetRowId) {
      return options;
    }

    const dataSetRowId = formFieldValue.fieldValue.dataSetRowId;
    const existingOption = dataSetRowId ? options.find(o => o.value === dataSetRowId) : undefined;

    if (existingOption) {
      return options;
    } else {
      const orphanedOption = { value: dataSetRowId, label: formFieldValue.fieldValue.value ?? '', orphaned: true };
      return options.concat(orphanedOption);
    }
  };

  export const getConfigOptions = (
    items: SelectFormFieldConfigItem[],
    stringValue: string,
    keyBy: string = 'label',
  ): [Option[], Dictionary<Option>] => {
    const options = items
      .filter(item => item.name !== '')
      .map(item => ({
        value: item.id,
        label: item.name,
      }));

    // We add an extra option for the current value if it doesn't exist as an option anymore.
    const orphanedOption =
      Boolean(stringValue) && isUndefined(items.find(item => item.name === stringValue))
        ? [{ value: MuidUtils.randomMuid(), label: stringValue, orphaned: true }]
        : [];

    const optionsWithOrphanedEntry = [...options, ...orphanedOption];

    const optionsMap = _keyBy(optionsWithOrphanedEntry, keyBy);

    return [optionsWithOrphanedEntry, optionsMap];
  };

  export const getOptionLabel = (formFieldValue: SelectFormFieldValue | undefined) => (option: Option) => {
    if (!formFieldValue) {
      return option.label;
    }

    const { fieldValue } = formFieldValue;

    if (fieldValue.dataSetRowId === option.value && fieldValue.value && fieldValue.value !== option.label) {
      return fieldValue.value;
    } else {
      return option.label;
    }
  };

  export const isOptionOrphaned = (option: Option) => option.orphaned ?? false;
}
