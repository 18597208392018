import create from 'zustand';

export type PrintStore = {
  isPrintView: boolean;
  isPrintPage: boolean;
  setIsPrintView: (isPrintView: boolean) => void;
  setIsPrintPage: (isPrintPage: boolean) => void;
};

export const usePrintStore = create<PrintStore>(set => ({
  isPrintView: false,
  isPrintPage: false,
  setIsPrintView: (isPrintView: boolean) => set({ isPrintView }),
  setIsPrintPage: (isPrintPage: boolean) => set({ isPrintPage }),
}));
