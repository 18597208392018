import { entitiesSelector } from 'reducers/entities/entities.selectors';
import { lookupsSelector } from 'reducers/lookups/lookups.selectors';
import { createSelector } from 'reselect';
import {
  selectedOrganizationMembershipWithUsersOnlySystemGroupsSelector,
  selectedOrganizationMembershipWithUsersNoSystemGroupsSelector,
} from 'reducers/group/group.selectors';
import { Entities } from 'reducers/entities/entities';

const entityMap = state => entitiesSelector(state)[Entities.TASK_TEMPLATE_PERMIT];
const lookups = state => lookupsSelector(state)[Entities.TASK_TEMPLATE_PERMIT];

const getAllIdsByTaskTemplateIds = taskTemplateIds => state => {
  let res = [];
  taskTemplateIds.forEach(taskTemplateId => {
    const lookup = lookups(state).byTaskTemplateId[taskTemplateId];
    if (lookup) {
      res = res.concat(lookup);
    }
  });

  return res;
};

const getAllByTaskTemplateIds = taskTemplateIds =>
  createSelector([getAllIdsByTaskTemplateIds(taskTemplateIds), entityMap], (ids, permits) =>
    (ids || []).map(id => permits[id]),
  );

const getAllByTaskTemplateIdsOnlySystemGroups = taskTemplateIds =>
  createSelector(
    [getAllByTaskTemplateIds(taskTemplateIds), selectedOrganizationMembershipWithUsersOnlySystemGroupsSelector],
    (permits, memberships) =>
      (permits || [])
        .map(permit => {
          const organizationMembership = memberships.find(m => m.id === permit.organizationMembership.id);
          return Object.assign({}, permit, { organizationMembership });
        })
        .filter(permit => permit.organizationMembership),
  );

const getAllByTaskTemplateIdsNoSystemGroups = taskTemplateIds =>
  createSelector(
    [getAllByTaskTemplateIds(taskTemplateIds), selectedOrganizationMembershipWithUsersNoSystemGroupsSelector],
    (permits, memberships) =>
      (permits || [])
        .map(permit => {
          const organizationMembership = memberships.find(m => m.id === permit.organizationMembership.id);
          return Object.assign({}, permit, { organizationMembership });
        })
        .filter(permit => permit.organizationMembership),
  );

const getCountsByTaskTemplateIds = taskTemplateIds =>
  createSelector(
    [
      TaskTemplatePermitsSelector.getAllByTaskTemplateIdsOnlySystemGroups(taskTemplateIds),
      TaskTemplatePermitsSelector.getAllByTaskTemplateIdsNoSystemGroups(taskTemplateIds),
    ],
    (taskTemplatePermitsSystemGroups, taskTemplatePermitsNoSystemGroups) => {
      const systemPermitsCount = taskTemplatePermitsSystemGroups.length
        ? taskTemplatePermitsSystemGroups.filter(permit => permit.taskRead).length
        : 4;
      const nonSystemPermitsCount = taskTemplatePermitsNoSystemGroups.filter(permit => permit.taskRead).length;

      return {
        systemPermits: systemPermitsCount,
        nonSystemPermits: nonSystemPermitsCount,
        all: systemPermitsCount + nonSystemPermitsCount,
      };
    },
  );

export const TaskTemplatePermitsSelector = {
  entityMap,
  getAllIdsByTaskTemplateIds,
  getAllByTaskTemplateIds,
  getAllByTaskTemplateIdsOnlySystemGroups,
  getAllByTaskTemplateIdsNoSystemGroups,
  getCountsByTaskTemplateIds,
};
