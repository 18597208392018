import { axiosService } from 'services/axios-service';
import { Tag, TagMembership, TagMembershipWithTag } from '@process-street/subgrade/process/tag-model';
import { Muid, Organization } from '@process-street/subgrade/core';
import { Template, TemplateStatus } from '@process-street/subgrade/process';
import intersectionWith from 'lodash/fp/intersectionWith';
import remove from 'lodash/fp/remove';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';

export type GetTagsByOrganizationIdResult = Tag[];
export type GetTagsByOrganizationIdParams = { organizationId: Organization['id'] };

const getTagsByOrganizationId = ({
  organizationId,
}: GetTagsByOrganizationIdParams): Promise<GetTagsByOrganizationIdResult> =>
  axiosService
    .getAxios()
    .get<Tag[]>(`/1/tags`, {
      data: {
        where: {
          organizationId: {
            _eq: organizationId,
          },
        },
      },
    })
    .then(res => res.data);

export const GetTagsByOrganizationId = {
  key: ['tags', 'organization'],
  getKey({ organizationId }: GetTagsByOrganizationIdParams): QueryKey {
    return [...GetTagsByOrganizationId.key, organizationId];
  },
  queryFn: getTagsByOrganizationId,
};

export const useGetOrganizationTagsQuery = <Select = GetTagsByOrganizationIdResult>(
  organizationId: Organization['id'],
  options: UseQueryOptions<GetTagsByOrganizationIdResult, AxiosError, Select> = {},
) => {
  return useQuery(
    GetTagsByOrganizationId.getKey({ organizationId: organizationId ?? '' }),
    () => GetTagsByOrganizationId.queryFn({ organizationId: organizationId! }),
    {
      ...options,
      enabled: Boolean(organizationId) && (options.enabled ?? true),
    },
  );
};

export type TagByTagParams = Pick<Tag, 'id' | 'name'> & { organizationId: Organization['id'] };

export type PutTagByTagResult = Tag;

const putTagByTag = (params: TagByTagParams) =>
  axiosService
    .getAxios()
    .put<PutTagByTagResult>(`/1/tags/${params.id}`, params)
    .then(res => res.data);
export const PutTagByTag = {
  key: ['tags', 'id', 'put'],
  getKey(): QueryKey {
    return PutTagByTag.key;
  },
  mutationFn: putTagByTag,
};

export type TagByTemplateIdParams = { templateId: Template['id']; tag: Tag };
export type PutTagByTemplateIdParams = { templateId: Template['id']; tagName: string };

export type PutTagByTemplateIdResult = TagMembershipWithTag;
const putTagByTemplateId = (params: PutTagByTemplateIdParams) =>
  axiosService
    .getAxios()
    .put<PutTagByTemplateIdResult>(`/1/templates/${params.templateId}/tags/${params.tagName}`, {})
    .then(res => res.data);
export const PutTagByTemplateId = {
  getKey(): QueryKey {
    return ['templates', 'id', 'tags', 'name', 'put'];
  },
  mutationFn: putTagByTemplateId,
};

export type GetTagMembershipsParams = { templateId?: Template['id'] };
export type GetTagMembershipsResult = TagMembership[];
const getTagMemberships = (params: GetTagMembershipsParams = {}): Promise<GetTagMembershipsResult> =>
  axiosService
    .getAxios()
    .get<GetTagMembershipsResult>('/1/tag-memberships', { params })
    .then(res => res.data);

export type GetTagsByTemplateIdParams = { templateId: NonNullable<GetTagMembershipsParams['templateId']> };
export type GetTagsByTemplateIdResult = GetTagMembershipsResult;

export const GetTagMemberships = {
  getKey(params: GetTagMembershipsParams): QueryKey {
    return ['tag-memberships', params.templateId];
  },
  queryFn: getTagMemberships,
};

export const useGetTagMembershipsQuery = <Select = GetTagMembershipsCountsResult>(
  params: GetTagMembershipsParams = {},
  options: UseQueryOptions<GetTagMembershipsResult, AxiosError, Select> = {},
) => {
  return useQuery(GetTagMemberships.getKey(params), () => GetTagMemberships.queryFn(params), options);
};

export const GetTagsByTemplateId = {
  key: ['tags'],
  getKey({ templateId }: GetTagsByTemplateIdParams): QueryKey {
    return [...GetTagsByTemplateId.key, templateId];
  },
  queryFn: (params: GetTagsByTemplateIdParams) => getTagMemberships(params),
  select: {
    fromMemberships: intersectionWith<Tag, TagMembership>((x, y) => x.id === y.tag?.id),
    remove:
      (ctx: TagByTemplateIdParams) =>
      (all: Tag[] = []) =>
      (current: TagMembership[] = []) =>
        remove<TagMembership>(membership => membership.tag.id === all?.find(tag => tag.name === ctx.tag.name)?.id)(
          current,
        ),
  },
};

const deleteTagByTemplateId = (params: TagByTemplateIdParams) =>
  axiosService
    .getAxios()
    .delete<never>(`/1/templates/${params.templateId}/tags/${params.tag.name}`)
    .then(res => res.data);
export const DeleteTagByTemplateId = {
  getKey(): QueryKey {
    return ['templates', 'id', 'tags', 'name', 'delete'];
  },
  mutationFn: deleteTagByTemplateId,
};

export type GetTagMembershipsCountsParams = {
  organizationId: Muid;
  templateStatus: TemplateStatus;
};
export type GetTagMembershipsCountsResult = Record<Tag['id'], number>;

export const GetTagMembershipsCounts = {
  key: ['tag-memberships', 'counts'],
  getKey(params: GetTagMembershipsCountsParams): QueryKey {
    return [...GetTagMembershipsCounts.key, params];
  },
  queryFn: (params: GetTagMembershipsCountsParams) =>
    axiosService
      .getAxios()
      .get<GetTagMembershipsCountsResult>(`/1/tag-memberships/counts`, { params })
      .then(res => res.data),
};

export const useGetTagMembershipsCountsQuery = (
  params: GetTagMembershipsCountsParams,
  options: UseQueryOptions<GetTagMembershipsCountsResult, AxiosError> = {},
) => {
  return useQuery<GetTagMembershipsCountsResult, AxiosError>(
    GetTagMembershipsCounts.getKey(params),
    () => GetTagMembershipsCounts.queryFn(params),
    options,
  );
};
