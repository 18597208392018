import { ChecklistGridSearchRequest } from '@process-street/subgrade/dashboard';
import { useChecklistScreenState } from 'components/dashboard/components/checklist/ChecklistDashboardScreen/checklist-screen-state';
import { conditionalFilterTreeToList } from 'components/dashboard/services/grid-data.service.impl';
import { useSelector } from 'react-redux';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { TemplateType } from '@process-street/subgrade/process';

export function useChecklistSearchCriteria(): ChecklistGridSearchRequest {
  const { editedSavedView } = useChecklistScreenState();
  const { selectedTemplates, searchPattern, conditionalFilter } = editedSavedView?.filters ?? {};
  const orgMembership = useSelector(OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId);

  return {
    templateIds: selectedTemplates,
    templateTypes: [TemplateType.Playbook],
    searchPattern,
    ...(conditionalFilter && orgMembership
      ? { filter: conditionalFilterTreeToList(conditionalFilter, orgMembership.id) }
      : {}),
  };
}
