import { createAction } from 'redux-actions';
import { SESSION_SET_ORGANIZATION_MEMBERSHIPS } from 'reducers/session/session.actions';
import { createCachedAction } from '../util';
import { UserSelector } from './user.selectors';

export const USER_GET_BY_ID = 'user/GET_BY_ID';
export const USER_GET_ALL_EMAILS_BY_ID = 'user/GET_ALL_EMAILS_BY_ID';
export const USER_GET_SETTINGS_BY_ID = 'user/GET_SETTINGS_BY_ID';

export const USER_CREATE_USER_EMAIL = 'user/CREATE_USER_EMAIL';
export const USER_UPDATE_SETTINGS = 'user/UPDATE_SETTINGS';
export const USER_GET_BY_TOKEN = 'user/GET_BY_TOKEN';
export const USER_COMPLETE_LOGIN = 'user/COMPLETE_LOGIN';
export const USER_UPDATE = 'user/UPDATE';
export const USER_UPDATE_USING_TOKEN = 'user/UPDATE_USING_TOKEN';
export const USER_SEND_EMAIL_VERIFICATION = 'user/SEND_EMAIL_VERIFICATION';
export const USER_REQUEST_PASSWORD_RESET = 'user/REQUEST_PASSWORD_RESET';
export const USER_RESET_PASSWORD = 'user/RESET_PASSWORD';
export const USER_GET_ACCESS_TOKEN = 'user/GET_ACCESS_TOKEN';
export const USER_VALIDATE_EMAIL_VERIFICATION = 'user/VALIDATE_EMAIL_VERIFICATION';
export const USER_DELETE_USER_EMAIL = 'user/DELETE_USER_EMAIL';
export const USER_SET_PRIMARY_EMAIL = 'user/SET_PRIMARY_EMAIL';
export const USER_GET_USER_LINKABLE_DOMAINS = 'user/GET_USER_LINKABLE_DOMAINS';

export const UserActions = UserApi => {
  'ngInject';

  // IMPORTANT : DONT USE createCachedAction here as we dont want cache for couple of scenarios
  // Refer to usage of OrganizationService.getAllByUser and you will see a flag being passed
  const getAllOrganizationMemberships = createAction(
    SESSION_SET_ORGANIZATION_MEMBERSHIPS,
    UserApi.getAllOrganizationMemberships,
    ({ userId, flushCache, selectedOrganizationId }) => ({ userId, flushCache, selectedOrganizationId }),
  );

  /**
   * Gets a single user by id
   */
  const getById = createCachedAction(
    USER_GET_BY_ID,
    UserApi.getById,
    (id, flushCache) => ({ id, flushCache }),
    (state, userId) => UserSelector.getById(userId)(state) !== undefined,
    UserSelector.getById,
  );

  /**
   * Fetches a user's emails from back end by user id
   */
  const getAllEmailsById = createCachedAction(USER_GET_ALL_EMAILS_BY_ID, UserApi.getAllEmailsById, userId => ({
    userId,
  }));

  /**
   * Fetches user settings from backend by user id
   */
  const getSettingsById = createCachedAction(USER_GET_SETTINGS_BY_ID, UserApi.getSettingsById);

  const createUserEmail = createCachedAction(USER_CREATE_USER_EMAIL, UserApi.createUserEmail);

  const updateSettings = createCachedAction(USER_UPDATE_SETTINGS, UserApi.updateSettings);

  const getByToken = createCachedAction(USER_GET_BY_TOKEN, UserApi.getByToken);

  const completeLogin = createCachedAction(USER_COMPLETE_LOGIN, UserApi.completeLogin);

  const update = createCachedAction(USER_UPDATE, UserApi.update);

  const updateUsingToken = createCachedAction(USER_UPDATE_USING_TOKEN, UserApi.updateUsingToken);

  const sendEmailVerification = createCachedAction(
    USER_SEND_EMAIL_VERIFICATION,
    UserApi.sendEmailVerification,
    (userId, email) => ({ userId, email }),
  );

  const requestPasswordReset = createCachedAction(USER_REQUEST_PASSWORD_RESET, UserApi.requestPasswordReset);

  const resetPassword = createCachedAction(USER_RESET_PASSWORD, UserApi.resetPassword);

  const validateEmailVerification = createCachedAction(
    USER_VALIDATE_EMAIL_VERIFICATION,
    UserApi.validateEmailVerification,
  );

  const deleteUserEmail = createCachedAction(USER_DELETE_USER_EMAIL, UserApi.deleteUserEmail);

  const setPrimaryEmail = createCachedAction(USER_SET_PRIMARY_EMAIL, UserApi.setPrimaryEmail);

  const getUserLinkableDomains = createCachedAction(USER_GET_USER_LINKABLE_DOMAINS, UserApi.getUserLinkableDomains);

  return {
    getAllOrganizationMemberships,
    getById,
    getAllEmailsById,
    getSettingsById,
    createUserEmail,
    updateSettings,
    getByToken,
    completeLogin,
    update,
    updateUsingToken,
    sendEmailVerification,
    requestPasswordReset,
    resetPassword,
    validateEmailVerification,
    deleteUserEmail,
    setPrimaryEmail,
    getUserLinkableDomains,
  };
};
