import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { EntityMap } from '@process-street/subgrade/redux/types';
import { CHECKLIST_MIGRATION_SET_STATS_BY_CHECKLIST } from 'components/migration/store/checklist-migration.actions';
import { ChecklistMigrationModuleState, MigrationStatsByChecklist } from 'components/migration/store/migration.types';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { combineReducers } from 'redux';
import { Action } from 'redux-actions';

export const migrationModuleByChecklistReducer = handleActionsOnSuccess(
  {
    [CHECKLIST_MIGRATION_SET_STATS_BY_CHECKLIST]: (
      state: EntityMap<MigrationStatsByChecklist>,
      action: Action<MigrationStatsByChecklist>,
    ) => EntitiesReducerUtils.upsert(state, action.payload),
  },
  {},
);

export const checklistMigrationModuleReducer = combineReducers<ChecklistMigrationModuleState>({
  byChecklist: migrationModuleByChecklistReducer,
});
