import { Plan } from '@process-street/subgrade/billing';
import {
  Option,
  Organization,
  OrganizationMembership,
  OrganizationMembershipRole,
  User,
  CompleteLoginUserInfo,
} from '@process-street/subgrade/core';
import { BasePlanSelector, BaseSessionSelector } from '@process-street/subgrade/redux/selector';
import { SubscriptionUtils } from '@process-street/subgrade/util/subscription-utils';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { OrganizationSubdomainsMap, PropertyMap, ReduxAppState, SessionState } from 'reducers/types';
import { createSelector, Selector } from 'reselect';

export const getSession: Selector<ReduxAppState, SessionState> = (state: ReduxAppState): SessionState => state.session;
export const getOrganizationSubdomainsMap: Selector<ReduxAppState, OrganizationSubdomainsMap> = (
  state: ReduxAppState,
): OrganizationSubdomainsMap => state.session.organizationSubdomainsMap;

export const getUser: Selector<ReduxAppState, Option<User>> = createSelector(getSession, (session: SessionState) =>
  session.user ? session.user : undefined,
);

const getOnboardingProperties: Selector<ReduxAppState, PropertyMap<boolean>> = (
  state: ReduxAppState,
): PropertyMap<boolean> => state.session.onboarding;

const getToken: Selector<ReduxAppState, string> = createSelector(getSession, (session: SessionState) => session.token);

const getSelectedPlan: Selector<ReduxAppState, Option<Plan>> = createSelector(
  [BaseSessionSelector.getSelectedOrganization, BasePlanSelector.getEntityMap],
  (selectedOrganization, plansMap) => {
    if (selectedOrganization && plansMap) {
      return plansMap[selectedOrganization.subscription.plan.id];
    }

    return undefined;
  },
);

const getElevioHash: Selector<ReduxAppState, Option<string>> = (state: ReduxAppState): Option<string> => {
  return state.session.elevioHash;
};

const getIntercomHash: Selector<ReduxAppState, Option<string>> = (state: ReduxAppState): Option<string> => {
  return state.session.intercomHash;
};

const getUserInfo: Selector<ReduxAppState, Option<CompleteLoginUserInfo>> = createSelector(
  [
    getUser,
    BaseSessionSelector.getSelectedOrganization,
    getSelectedPlan,
    OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId,
    getElevioHash,
    getIntercomHash,
  ],
  (user, selectedOrganization, selectedPlan, organizationMembership, elevioHash, intercomHash) => {
    if (user && selectedOrganization && selectedPlan && organizationMembership && elevioHash && intercomHash) {
      return {
        elevioHash,
        intercomHash,
        organizationMembership,
        selectedOrganization,
        selectedPlan,
        user,
      } as CompleteLoginUserInfo;
    }

    return undefined;
  },
);

const getAllOrganizationMembershipsOfCurrentUser: Selector<ReduxAppState, OrganizationMembership[]> = (
  state: ReduxAppState,
): OrganizationMembership[] => state.session.organizationMemberships;

const isCanceled: Selector<ReduxAppState, boolean> = createSelector(
  BaseSessionSelector.getSelectedOrganization,
  (selectedOrganization: Option<Organization>) => {
    if (!selectedOrganization) {
      return false;
    }
    const { status } = selectedOrganization.subscription;
    return SubscriptionUtils.isCancelled(status);
  },
);

const isUserGuestOfSelectedOrganization: Selector<ReduxAppState, boolean> = createSelector(
  [BaseSessionSelector.getSelectedOrganizationId, getAllOrganizationMembershipsOfCurrentUser],
  (selectedOrganizationId, userOrganizationMemberships) => {
    let isGuestUser = true;
    if (userOrganizationMemberships) {
      const memberships = Object.values(userOrganizationMemberships);
      isGuestUser =
        memberships.length > 0 &&
        memberships.some(
          membership =>
            membership.organization?.id === selectedOrganizationId &&
            // There are still plentiful uses of \.guest so keeping this for compatibility
            (membership.role === OrganizationMembershipRole.Guest || membership.guest),
        );
    }
    return isGuestUser;
  },
);

export const SessionSelector = {
  getAllOrganizationMembershipsOfCurrentUser,
  getCurrentPlan: BaseSessionSelector.getCurrentPlan,
  getCurrentUser: BaseSessionSelector.getCurrentUser,
  getCurrentUserId: BaseSessionSelector.getCurrentUserId,
  getSelectedOrganization: BaseSessionSelector.getSelectedOrganization,
  getSelectedOrganizationId: BaseSessionSelector.getSelectedOrganizationId,
  getOnboardingProperties,
  getSession,
  getToken,
  getUserInfo,
  isCanceled,
  isUserGuestOfSelectedOrganization,
  getOrganizationSubdomainsMap,
};
