import angular from 'angular';
import { Method } from 'services/template-service.interface';
import { HttpStatus } from '@process-street/subgrade/util';
import './add.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { CreateTemplateSource } from 'services/template-service.interface';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller('TemplateAddCtrl', function ($scope, $state, PremadeTemplateService, ToastService) {
    'ngInject';
    const ctrl = this;
    const logger = trace({ name: 'TemplateAddCtrl' });
    logger.info('loading ctrl');

    ctrl.$onInit = () => {
      if ($state.params.templateId) {
        $scope.creating = true;

        const template = { id: $state.params.templateId };
        const folderId = null; // This will default it to the home folder
        const templateName = null; // Default to pre-made name
        $scope._createTemplate(template, folderId, templateName, Method.IMPORT);
      } else {
        ToastService.openToast({
          status: 'error',
          title: `We're having problems find that workflow.`,
          description: 'Please try again and confirm the address is correct.',
        });
      }
    };

    $scope._createTemplate = (premadeTemplate, folderId, name) => {
      $scope.creating = true;

      return PremadeTemplateService.importTemplateToFolderAndRedirect({
        folderId,
        premadeTemplateId: premadeTemplate.id,
        name,
        source: CreateTemplateSource.IMPORT_TEMPLATE_PAGE,
      })
        .then(
          () => {
            ToastService.openToast({
              status: 'success',
              title: `Workflow imported`,
            });
          },
          response => {
            if (response.status === HttpStatus.FORBIDDEN) {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems importing the workflow`,
                description: 'You do not have permission to import this workflow, please reach out to an admin.',
              });
            } else {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems importing the workflow`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            }

            $state.go('dashboard');
          },
        )
        .finally(() => {
          $scope.creating = false;
        });
    };
  });
