import { axiosService } from 'services/axios-service';
import { Organization } from '@process-street/subgrade/core';

type GetStripeBillingPortalByOrganizationParams = {
  organizationId: Organization['id'];
  returnUrl: string;
};

export type GetStripeBillingPortalByOrganizationResult = {
  portalUrl: string;
};

const getStripeBillingPortalByOrganizationImpl = ({
  organizationId,
  returnUrl,
}: GetStripeBillingPortalByOrganizationParams): Promise<GetStripeBillingPortalByOrganizationResult> =>
  axiosService
    .getAxios()
    .post<GetStripeBillingPortalByOrganizationResult>(
      `/1/organizations/${organizationId}/stripe-portal-session`,
      null,
      { params: { returnUrl } },
    )
    .then(res => res.data);

export const GetStripeBillingPortalByOrganization = {
  key: ['stripe-billing-portal'],
  getKey({ organizationId, returnUrl }: GetStripeBillingPortalByOrganizationParams) {
    return [...GetStripeBillingPortalByOrganization.key, organizationId, returnUrl];
  },
  queryFn: getStripeBillingPortalByOrganizationImpl,
  select: (result: GetStripeBillingPortalByOrganizationResult) => result.portalUrl,
};
