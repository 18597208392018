import { Box, BoxProps, Button, Spacer, Heading, List, Text, Flex } from 'components/design/next';
import * as React from 'react';
import { LevelToPlanInfoMap, PlanStates } from 'pages/organizations/manage/billing/models/models';
import { PricingDetail } from './PricingDetail';
import { PlanFeatureSet, PlanLevel } from '@process-street/subgrade/billing';
import { PlanLevelSelectorState } from 'directives/billing/plan-level-selector/plan-level-selector-service.interface';
import { useInjector } from 'components/injection-provider';

type FreePricingCardProps = {
  onPlanSelect: (level: PlanLevel) => void;
  level: PlanLevel;
  selected: boolean;
  planStates: PlanStates;
  featureSet: PlanFeatureSet;
} & BoxProps;

export const FreePricingCard = (props: FreePricingCardProps) => {
  const { onPlanSelect, planStates, level, selected, featureSet, ...rest } = props;

  const { $state } = useInjector('$state');

  const planState = planStates[level]!;
  const levelIsCurrent = planState === PlanLevelSelectorState.CURRENT_PLAN;
  const trialExpired = levelIsCurrent && $state.params.trialExpired;

  const actionText = trialExpired ? 'Stay on Free' : planState;
  const { features, name, description: Description, featuresHeader } = LevelToPlanInfoMap[level]!; // safe as we have all freemium levels in this map (but not other levels)
  const disabled = !trialExpired && (selected || levelIsCurrent);

  const handleClick = () => onPlanSelect(level);

  return (
    <Flex direction="row" p={{ base: '4', md: '8' }} bgColor="white" {...rest} alignItems="baseline">
      <Box mr={12}>
        <Heading fontSize="md" textTransform="none" mt={0}>
          {name}
        </Heading>
      </Box>
      <Box>
        <Text fontSize="md" color="gray.600">
          <Description featureSet={featureSet} />
        </Text>

        <Box>
          <Text fontWeight="bold" mb="4">
            {featuresHeader}
          </Text>
          <List spacing="4">
            {features.map((feature, index) => (
              <PricingDetail key={index} checkIcon={false} iconColor="gray.600">
                {feature}
              </PricingDetail>
            ))}
          </List>
        </Box>
      </Box>

      <Spacer />

      <Button size="lg" fontSize="md" ml={2} variant="tertiary" isDisabled={disabled} onClick={handleClick}>
        {actionText}
      </Button>
    </Flex>
  );
};
