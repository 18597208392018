import angular from 'angular';
import { HttpStatus } from '@process-street/subgrade/util';

angular.module('frontStreetApp.services').service('TagService', function ($q, Tags) {
  const self = this;

  self.createAt = function (id, organizationId, name) {
    return Tags.createAt({
      id,
      organizationId,
      name,
    }).$promise;
  };

  /**
   * Get all tags in a particular organization.
   *
   * @param organizationId
   */
  self.getAllByOrganizationId = function (organizationId) {
    return Tags.query({
      where: {
        organizationId: { _eq: organizationId },
      },
    }).$promise;
  };

  /**
   * Get tag by ID
   *
   * @param id
   */
  self.getById = function (id) {
    return Tags.query({ where: { id: { _eq: id } } }).$promise.then(tags => {
      if (tags.length) {
        return tags[0];
      } else {
        return $q.reject({ status: HttpStatus.NOT_FOUND });
      }
    });
  };

  /**
   * Get tag by name
   *
   * @param name
   */
  self.getByName = function (name) {
    return Tags.query({ where: { name: { _eq: name } } }).$promise.then(tags => {
      if (tags.length) {
        return tags[0];
      } else {
        return $q.reject({ status: HttpStatus.NOT_FOUND });
      }
    });
  };
});
