import angular from 'angular';
import templateUrl from './verification-panel.component.html';
import { DefaultErrorMessages } from 'components/utils/error-messages';

angular.module('frontStreetApp.directives').component('psSsoVerificationPanel', {
  bindings: {
    organization: '<',
  },
  templateUrl,
  controller($q, OrganizationService, ToastService) {
    const ctrl = this;

    ctrl.verificationCheck = {
      NON_DOMAIN_EMAILS: 'Non-domain emails',
      MULTIPLE_EMAILS: 'Multiple emails',
      MULTIPLE_ORGANIZATIONS: 'Multiple organizations',
    };

    ctrl.verificationChecks = [
      ctrl.verificationCheck.NON_DOMAIN_EMAILS,
      ctrl.verificationCheck.MULTIPLE_EMAILS,
      ctrl.verificationCheck.MULTIPLE_ORGANIZATIONS,
    ];

    ctrl.verificationCheckToHelpTextMap = {};
    ctrl.verificationCheckToHelpTextMap[ctrl.verificationCheck.NON_DOMAIN_EMAILS] =
      'Please remove these users or re-add them with a company email';
    ctrl.verificationCheckToHelpTextMap[ctrl.verificationCheck.MULTIPLE_EMAILS] =
      'Please remove any secondary email addresses';
    ctrl.verificationCheckToHelpTextMap[ctrl.verificationCheck.MULTIPLE_ORGANIZATIONS] =
      'Please remove these users from other organizations';

    ctrl.verificationCheckToFailedUsersMap = ctrl.verificationChecks.reduce((map, check) => {
      map[check] = 0;
      return map;
    }, {});

    ctrl.$onInit = function () {
      // This is here to allow unit test to run
    };

    ctrl.visible = false;
    ctrl.$onChanges = function (changes) {
      if (changes.organization && ctrl.organization) {
        ctrl.visible = true;
        ctrl.runVerification();
      }
    };

    ctrl.loading = true;
    ctrl.verificationPassed = false;
    ctrl.runVerification = function () {
      const { organization } = ctrl;

      const verificationCheckToRequestMap = {};
      verificationCheckToRequestMap[ctrl.verificationCheck.NON_DOMAIN_EMAILS] =
        OrganizationService.getAllUsersWithNotMatchingEmailDomainById(organization.id);
      verificationCheckToRequestMap[ctrl.verificationCheck.MULTIPLE_EMAILS] =
        OrganizationService.getAllUsersWithMatchingEmailDomainAndMultipleEmailsById(organization.id);
      verificationCheckToRequestMap[ctrl.verificationCheck.MULTIPLE_ORGANIZATIONS] =
        OrganizationService.getAllUsersWithMatchingEmailDomainAndMultipleOrganizationsById(organization.id);

      ctrl.loading = true;
      $q.all(verificationCheckToRequestMap)
        .then(verificationCheckToFailedUsersMap => {
          ctrl.verificationCheckToFailedUsersMap = verificationCheckToFailedUsersMap;

          ctrl.verificationPassed = ctrl.verificationChecks.every(
            check => ctrl.verificationCheckToFailedUsersMap[check].length === 0,
          );
        })
        .catch(() => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems verifying the SSO setup`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        })
        .finally(() => {
          ctrl.loading = false;
        });
    };

    ctrl.getIconClass = function (validationCheck) {
      const verificationCheckLength = ctrl.verificationCheckToFailedUsersMap[validationCheck].length;
      return {
        'fa-times-circle text-danger': verificationCheckLength > 0,
        'fa-check-circle text-success': verificationCheckLength === 0,
      };
    };

    ctrl.locked = false;
    ctrl.lockOrganizationToSSO = function () {
      if (ctrl.verificationPassed) {
        ctrl.loading = true;
        ctrl.locked = false;
        OrganizationService.lockToSsoByOrganizationId(ctrl.organization.id)
          .then(
            () => {
              ctrl.locked = true;
            },
            () => {
              // Do nothing
            },
          )
          .finally(() => {
            ctrl.loading = false;
          });
      }
    };
  },
});
