import { MuidConverter } from '../core';

export const TEST_BUSINESS_PRO_PLAN = 'hynvsBS2WQ_q9qZPIANOOA';
export const TEST_BUSINESS_PLAN = 'sBGGm7yZIXZdm19pEodH9w';
export const TEST_LEGACY_PLAN = 'jEtFFg1a5MkwwjJSa2ROxg';
export const TEST_FREEMIUM_FREE_PLAN = MuidConverter.fromUuid('1f2f2a06-a1cc-466b-a92e-a515b99c46de');
export const TEST_FREEMIUM_PRO_PLAN = MuidConverter.fromUuid('abce12e5-e002-4cdc-bc12-a0d53a56517f');
export const TEST_FREE_TRIAL_FREE_PLAN = MuidConverter.fromUuid('9a10b19e-995d-4de8-918f-f15730302cec');
export const TEST_FREE_TRIAL_STANDARD_PLAN = MuidConverter.fromUuid('482dc7a3-46c0-4373-bffd-a8e3248f7658');
export const TEST_FREE_TRIAL_ENTERPRISE_T5K_STARTUP_PLAN = MuidConverter.fromUuid(
  '0d113551-bb47-478c-b440-3587bb78f4ee',
);
export const TEST_FREE_TRIAL_PRO_T5K_STARTUP_PLAN = MuidConverter.fromUuid('ec0eb63c-b4ed-4790-b575-70fae554b148');
export const TEST_PRO_PLAN = MuidConverter.fromUuid('2169f474-4314-4a77-93d8-654cb4e46790');
