import angular from 'angular';
import templateUrl from './file-renderer.component.html';

export class ApprovalSubjectTaskWidgetFileRendererController {}

export const ApprovalSubjectTaskWidgetFileRenderer: angular.IComponentOptions = {
  bindings: {
    formFieldValue: '<',
  },
  controller: ApprovalSubjectTaskWidgetFileRendererController,
  templateUrl,
};
