import angular from 'angular';

angular.module('frontStreetApp.services').factory('Tags', function (ResourceFactory) {
  return ResourceFactory.create({
    query: 'GET[] /1/tags',
    create: 'POST /1/tags',
    createAt: 'PUT /1/tags/:id',
    update: 'PATCH /1/tags/:id',
    delete: 'DELETE /1/tags/:id',
  });
});
