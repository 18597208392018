import { Folder } from '@process-street/subgrade/process';
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Icon,
  useDisclosure,
} from 'components/design/next';
import { FolderPickerItem } from './item';
import { FolderPickerTree } from './tree';
import { useFolderPickerRootContext } from './root';
import { useFolderPickerModalContext } from './modal';
import { FolderPermissionsIndicator } from 'components/permissions/folder-permissions-indicator/component';

interface FolderPickerTreeNodeProps {
  folder: Folder;
  onBranchSelect?: () => void;
}
export const FolderPickerTreeNode: React.FC<React.PropsWithChildren<FolderPickerTreeNodeProps>> = ({
  folder,
  onBranchSelect,
}) => {
  const { candidateType, candidateId } = useFolderPickerModalContext();
  const { folders, writeableFolderIdSet, selectedId, onSelect } = useFolderPickerRootContext();
  const writeable = writeableFolderIdSet.has(folder.id);
  const isSelf = candidateType === 'folder' && candidateId === folder.id;
  const shouldAllowWrite = writeable && !isSelf;
  const expandable = folders.some(f => f.parentFolder?.id === folder.id);
  const expandedDisclosure = useDisclosure();
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(folder.id === selectedId);
  }, [folder, selectedId]);
  useEffect(() => {
    if (selected) {
      onBranchSelect?.();
    }
  }, [onBranchSelect, selected]);
  const handleClick = () => {
    expandedDisclosure.onOpen();
    onSelect?.(folder, shouldAllowWrite);
  };
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    expandedDisclosure.onToggle();
    event.stopPropagation();
  };
  const handleBranchSelect = () => {
    expandedDisclosure.onOpen();
    onBranchSelect?.();
  };
  return (
    <Accordion index={expandedDisclosure.isOpen ? [0] : []} reduceMotion={true} role="group">
      <AccordionItem>
        <AccordionButton
          {...(isSelf
            ? { bgColor: 'gray.100', _hover: { cursor: 'not-allowed' }, color: 'gray.300' }
            : selected
            ? { bgColor: 'brand.100', textColor: 'brand.500', fontWeight: 'bold' }
            : null)}
          onClick={handleClick}
          py="0"
          px="6"
          fontSize="md"
          lineHeight="base"
          aria-selected={selected ? 'true' : 'false'}
          role="treeitem"
        >
          <FolderPickerItem name={folder.name} writeable={writeable} />

          <Box color="gray" pl="2">
            <FolderPermissionsIndicator folderId={folder.id} organizationId={folder.organization.id} target="_blank" />
          </Box>

          <Box w="4" ml="2">
            {expandable ? (
              <Box
                as="span"
                role="button"
                _hover={{ cursor: 'pointer' }}
                onClick={handleToggle}
                aria-label={expandedDisclosure.isOpen ? 'collapse' : 'expand'}
              >
                <Icon
                  icon={expandedDisclosure.isOpen ? 'chevron-up' : 'chevron-down'}
                  variant="far"
                  size="4"
                  color="gray.500"
                />
              </Box>
            ) : null}
          </Box>
        </AccordionButton>
        <AccordionPanel p="0" pl="4">
          <FolderPickerTree rootId={folder.id} onBranchSelect={handleBranchSelect} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
