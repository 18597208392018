import { approvalRuleSubjectLookupsReducer } from 'components/approval-rules/store/approval-rules.reducers';
import { approvalLookupsReducer } from 'components/approvals/store/approval.reducers';
import { authConnectionLookupReducers } from 'components/auth-connection/store/auth-connection.reducers';
import { checklistTaskAssignmentLookupsReducer } from 'components/checklist-task-assignment/store/checklist-task-assignment.reducer';
import { dynamicDueDatesLookupsReducer } from 'components/dynamic-due-dates/store/dynamic-due-dates.reducers';
import { folderPermitsLookupsReducer } from 'components/folder/membership/store/folder-membership.reducer';
import { formFieldValueLookupReducer } from 'components/form-field-value/store/form-field-value.reducer';
import { roleAssignmentRuleLookupsReducer } from 'components/role-assignments/store/role-assignment-rules.reducer';
import { taskPermissionRuleLookupsReducer } from 'components/task-permission/store/task-permission-rule.reducer';
import { taskTemplatePermitLookupsReducer } from 'components/task-permission/store/task-template-permit.reducer';
import { templateTaskAssignmentLookupReducer } from 'components/template-task-assignment/store/template-task-assignment.reducer';
import { templatePermitsLookupsReducer } from 'components/template/membership/store/template-membership.reducer';
import { widgetLookupsReducer } from 'components/widgets/store/widget.reducer';
import { taskStatsLookupsReducer } from 'reducers/task-stats/task-stats.reducers';
import { taskTemplateLookupsReducer } from 'reducers/task-template/task-template.reducers';
import { taskLookupReducer } from 'reducers/task/task.reducer';
import { templateLookupsReducer } from 'reducers/template/template-lookups.reducer';
import { LookupState } from 'reducers/types';
import { usersLookupsReducer } from 'reducers/user/user.reducer';
import { combineReducers } from 'redux';
import { checklistWidgetLookupsReducer } from 'components/widgets/store/checklist-widget.reducer';

export const lookupsReducer = combineReducers<LookupState>({
  approval: approvalLookupsReducer,
  approvalRuleSubject: approvalRuleSubjectLookupsReducer,
  authConnection: authConnectionLookupReducers,
  checklistTaskAssignments: checklistTaskAssignmentLookupsReducer,
  checklistWidget: checklistWidgetLookupsReducer,
  dynamicDueDate: dynamicDueDatesLookupsReducer,
  folderPermits: folderPermitsLookupsReducer,
  formFieldValue: formFieldValueLookupReducer,
  roleAssignmentRules: roleAssignmentRuleLookupsReducer,
  task: taskLookupReducer,
  taskPermissionRules: taskPermissionRuleLookupsReducer,
  taskStats: taskStatsLookupsReducer,
  taskTemplatePermits: taskTemplatePermitLookupsReducer,
  taskTemplates: taskTemplateLookupsReducer,
  templatePermits: templatePermitsLookupsReducer,
  templateTaskAssignments: templateTaskAssignmentLookupReducer,
  templates: templateLookupsReducer,
  users: usersLookupsReducer,
  widget: widgetLookupsReducer,
});
