import { captureException } from '@sentry/browser';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import {
  DeleteTagByTemplateId,
  GetTagMembershipsCounts,
  GetTagMembershipsCountsResult,
  GetTagsByOrganizationId,
  GetTagsByOrganizationIdResult,
  GetTagsByTemplateId,
  GetTagsByTemplateIdParams,
  GetTagsByTemplateIdResult,
  PutTagByTemplateId,
  PutTagByTemplateIdParams,
  PutTagByTemplateIdResult,
  TagByTemplateIdParams,
  useGetOrganizationTagsQuery,
} from 'features/tags/query-builder';
import { Tag } from '@process-street/subgrade/process/tag-model';
import { Template, TemplateStatus } from '@process-street/subgrade/process';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

type UseTagManager = {
  /** @param onTagAdded - a callback to be invoked when a tag has been successfully added */
  onTagAdded?: () => void;
  /** @param onTagRemoved - a callback to be invoked when a tag has been successfully removed */
  onTagRemoved?: () => void;
  /** @param templateId - The ID of the Template you wish to manage the tags of */
  templateId: Template['id'];
};

type UseTagManagerReturn = {
  createTag: (name: Tag['name']) => void;
  removeTag: (tag: Tag) => void;
  templateTags: UseQueryResult<Tag[]>;
  organizationTags: UseQueryResult<GetTagsByOrganizationIdResult>;
};
/**
 * Facilitates the creation and removal of tags from an `Organization` and `Template`
 *
 * @returns A hook with all of a selected organization's available Tags, all of a Template (`templateId`)'s current Tags, and methods for adding a Tag (TagMembership) to a Template (and an Organization if necessary) and removing a Tag (TagMembership) from a Template (`templateId`).
 * @param createTag create a Tag, adding it to the current Organization if necessary
 * @param removeTag remove a Tag, keeping it in the current Organization
 * @param templateTags Tags applied to the current Template
 * @param organizationTags Tags that exist on the entire Organization, useful for creating an autocomplete list
 */
export function useTagManager({ templateId, onTagRemoved, onTagAdded }: UseTagManager): UseTagManagerReturn {
  const queryClient = useQueryClient();

  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const organizationTagsQuery = useGetOrganizationTagsQuery(selectedOrganizationId ?? '');
  const tagsByTemplateIdParams: GetTagsByTemplateIdParams = { templateId };

  const templateTags = useQuery<GetTagsByTemplateIdResult, Error, Tag[]>(
    GetTagsByTemplateId.getKey(tagsByTemplateIdParams),
    () => GetTagsByTemplateId.queryFn(tagsByTemplateIdParams),
    {
      enabled: Boolean(tagsByTemplateIdParams.templateId) && organizationTagsQuery.isSuccess,
      select: data => GetTagsByTemplateId.select.fromMemberships(organizationTagsQuery.data, data),
    },
  );

  const putTagByTemplateId = useMutation<PutTagByTemplateIdResult, Error, PutTagByTemplateIdParams>(
    PutTagByTemplateId.mutationFn,
    {
      mutationKey: PutTagByTemplateId.getKey(),
      onError: (error, variables, context) => {
        captureException({
          ...error,
          variables,
          context,
          message: `useTagManager failed putting Tag by Template ID: ${error.message}`,
        });
      },
      onSettled: data => {
        if (data) {
          queryClient.setQueryData(
            GetTagsByTemplateId.getKey(tagsByTemplateIdParams),
            (oldData: GetTagsByTemplateIdResult = []) => [...oldData, data],
          );
          queryClient.setQueryData(
            GetTagsByTemplateId.getKey(tagsByTemplateIdParams),
            (oldData: GetTagsByTemplateIdResult = []) => [...oldData, data],
          );
          queryClient.setQueryData(
            GetTagMembershipsCounts.getKey({
              organizationId: selectedOrganizationId ?? '',
              templateStatus: TemplateStatus.Active,
            }),
            (oldData: GetTagMembershipsCountsResult = {}) => ({
              ...oldData,
              [data.tag.id]: (oldData[data.tag.id] ?? 0) + 1,
            }),
          );
          onTagAdded?.();
        }
      },
      onSuccess: data => {
        const { tag } = data;

        queryClient.setQueryData(
          GetTagsByOrganizationId.getKey({ organizationId: selectedOrganizationId ?? '' }),
          (oldData: GetTagsByOrganizationIdResult = []) =>
            oldData.find(t => t.id === tag.id) ? oldData : [...oldData, tag],
        );
      },
    },
  );

  const handleAddTagToTemplate = (tagName: string) => {
    putTagByTemplateId.mutate({ templateId, tagName });
  };

  const deleteTagByTemplateId = useMutation<never, Error, TagByTemplateIdParams>(DeleteTagByTemplateId.mutationFn, {
    mutationKey: DeleteTagByTemplateId.getKey(),
    onError: (error, variables, ctx) => {
      captureException({
        ...error,
        variables,
        ctx,
        message: `useTagManager failed deteting Tag by Template ID: ${error.message}`,
      });
    },
    onSuccess: (_data, ctx) => {
      onTagRemoved?.();
      queryClient.setQueryData(
        GetTagsByTemplateId.getKey(tagsByTemplateIdParams),
        GetTagsByTemplateId.select.remove(ctx)(organizationTagsQuery?.data),
      );
    },
  });
  const removeTag = (tag: Tag) => {
    deleteTagByTemplateId.mutate({
      tag,
      templateId,
    });
  };

  const createTag = (value: Tag['name']) => {
    handleAddTagToTemplate(value);
  };

  return {
    createTag,
    removeTag,
    templateTags,
    organizationTags: organizationTagsQuery,
  };
}
