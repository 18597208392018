import * as React from 'react';
import {
  Badge,
  Button,
  Flex,
  FlexProps,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackProps,
  Text,
  VStack,
} from 'components/design/next';
import { PaywallButton } from 'components/paywalls';
import { useCreateTemplatePaywall } from 'components/paywalls/create-template';
import { useNewBlankWorkflowButton } from 'features/new-buttons/use-new-blank-workflow-button';
import { CreateTemplateSource } from 'services/template-service.interface';
import { useNewWorkflowFromTemplateButton } from 'features/new-buttons/use-new-workflow-from-template-button';
import { FolderPickerModal, PickButtonProps } from 'components/folder-picker/modal';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { queryString } from '@process-street/subgrade/util';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { useNavigate } from 'app/adapters/navigation';

type CreateWorkflowOptionProps = {
  title: React.ReactNode;
  description: string;
  imageSrc: string;
  imageContainerProps?: FlexProps;
  isDisabled?: boolean;
  onClick?: React.MouseEventHandler;
  onClose?: () => void;
  stackProps?: StackProps;
};

export const CreateWorkflowOption: React.FC<React.PropsWithChildren<CreateWorkflowOptionProps>> = ({
  title,
  description,
  imageSrc,
  imageContainerProps,
  onClick,
  onClose,
  isDisabled,
  stackProps,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    onClick?.(e);
    onClose?.();
  };

  return (
    <VStack
      borderWidth="thin"
      borderColor="gray.200"
      borderStyle="solid"
      boxShadow="1px 2px 4px 1px rgba(0, 0, 0, 0.06)"
      borderRadius="base"
      overflow="hidden"
      maxW="56"
      w="full"
      onClick={isDisabled ? undefined : handleClick}
      opacity={isDisabled ? 0.5 : 1}
      cursor={isDisabled ? 'default' : 'pointer'}
      pt="3"
      {...stackProps}
    >
      <Flex h="22" p="2.5" alignItems="flex-end" justifyContent="center" {...imageContainerProps} w="full">
        <Image src={imageSrc} />
      </Flex>

      <VStack position="relative" pt="4" px="4" pb="6" justifyContent="flex-start" alignItems="flex-start">
        <Text color="black" fontWeight="500">
          {title}
        </Text>

        <Text fontSize="sm" color="gray.500">
          {description}
        </Text>
      </VStack>
    </VStack>
  );
};

export type NewWorkflowModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const PickButton: React.FC<React.PropsWithChildren<PickButtonProps>> = ({ pickType, ...props }) => (
  <Button {...props}>
    Create
    {pickType === 'to_private' ? ' to Private' : null}
    {pickType === 'to_organization' ? ' to Organization' : null}
  </Button>
);

export const NewWorkflowModal: React.FC<React.PropsWithChildren<NewWorkflowModalProps>> = ({ isOpen, onClose }) => {
  const organizationId = useSelectedOrganization()?.id;

  const { buttonProps: newAiGeneratedWorkflowButtonProps, folderPickerProps: newAiGeneratedWorkflowFolderPickerProps } =
    useNewBlankWorkflowButton({
      source: CreateTemplateSource.MAIN_NAV_BAR,
      isAiGenerated: true,
      allowSelectFolder: true,
    });

  const { buttonProps: newBlankWorkflowButtonProps, folderPickerProps: newBlankWorkflowFolderPickerProps } =
    useNewBlankWorkflowButton({
      source: CreateTemplateSource.MAIN_NAV_BAR,
      allowSelectFolder: true,
    });

  const {
    buttonProps: newWorkflowFromTemplateButtonProps,
    folderPickerProps: newWorkflowFromTemplateFolderPickerProps,
  } = useNewWorkflowFromTemplateButton({
    source: CreateTemplateSource.MAIN_NAV_BAR,
    allowSelectFolder: true,
  });

  const handleBlankWorkflowButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClose?.();
    newBlankWorkflowButtonProps.onClick?.(e);
  };

  const navigate = useNavigate();
  const onImportClick = () => {
    navigate({
      search: queryString.stringify({ [AppModalQueryParam.Modal]: AppModalName.ImportWorkflow }),
    });
    onClose();
  };

  const folderPickerProps = [
    newAiGeneratedWorkflowFolderPickerProps,
    newBlankWorkflowFolderPickerProps,
    newWorkflowFromTemplateFolderPickerProps,
  ].find(props => props.isOpen);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="18px" color="gray.700" py="6" px="8">
            Create New Workflow
          </ModalHeader>

          <ModalCloseButton right="7" top="6" />
          <ModalBody pt="6" px={{ base: '9', xs: '4', md: '9' }} pb="10">
            <VStack spacing="9">
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                flexWrap={{ base: 'wrap', md: 'nowrap' }}
                alignItems={{ base: 'center', sm: 'stretch' }}
                justifyContent="center"
                gap={{ base: '6', sm: '2', md: '3' }}
                spacing="0"
              >
                <PaywallButton useHook={useCreateTemplatePaywall}>
                  <CreateWorkflowOption
                    title={
                      <>
                        Generate with AI
                        <Badge ml="2" colorScheme="purple" color="purple.600" bgColor="purple.100">
                          Beta
                        </Badge>
                      </>
                    }
                    description="Use Process AI to generate a custom workflow for your use case."
                    imageSrc={require('app/images/ai-workflow-generation/sparkles.png')}
                    onClose={onClose}
                    {...newAiGeneratedWorkflowButtonProps}
                    isDisabled={false}
                  />
                </PaywallButton>

                <PaywallButton useHook={useCreateTemplatePaywall}>
                  <CreateWorkflowOption
                    title={
                      <>
                        Import Document
                        <Badge ml="2" colorScheme="purple" color="purple.600" bgColor="purple.100">
                          Beta
                        </Badge>
                      </>
                    }
                    description="Use Process AI to generate a custom workflow based on your document."
                    imageSrc={require('app/images/create-workflow-modal/file-import.png')}
                    onClick={onImportClick}
                    onClose={onClose}
                  />
                </PaywallButton>

                <PaywallButton useHook={useCreateTemplatePaywall}>
                  <CreateWorkflowOption
                    title="Explore Templates"
                    description="Choose from our extensive library and customize to your needs."
                    imageSrc={require('app/images/create-workflow-modal/template-library-icon.png')}
                    onClose={onClose}
                    {...newWorkflowFromTemplateButtonProps}
                    isDisabled={false}
                  />
                </PaywallButton>
              </Stack>
              <PaywallButton useHook={useCreateTemplatePaywall}>
                <Button
                  {...newBlankWorkflowButtonProps}
                  onClick={handleBlankWorkflowButtonClick}
                  variant="link"
                  fontWeight="normal"
                  isDisabled={false}
                  color="blue.500"
                  _hover={{
                    textDecoration: 'unset',
                  }}
                >
                  <Text>or</Text>
                  <Text ml="1" fontWeight="semibold">
                    Start a Blank Workflow
                  </Text>
                </Button>
              </PaywallButton>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {folderPickerProps?.isOpen && (
        <FolderPickerModal {...folderPickerProps} verb="add" organizationId={organizationId} pickButton={PickButton} />
      )}
    </>
  );
};
