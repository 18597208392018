import { orderTreeService } from '@process-street/subgrade/core';
import { ArrayService } from './array-service';

export type CreateOrderTreeBulkUpdaterOptions<T extends object> = {
  getOrderTree: (subject: T) => string;
  setOrderTree: (subject: T, orderTree: string) => T;
};

export const createOrderTreeBulkUpdater = <T extends object>({
  getOrderTree,
  setOrderTree,
}: CreateOrderTreeBulkUpdaterOptions<T>) => {
  const moveAt = (indexes: number[], newIndexes: number[], items: T[]) => {
    const itemsOrdered = [...items];

    indexes.forEach((fromIndex, i) => {
      const toIndex = newIndexes[i];

      ArrayService.move(itemsOrdered, fromIndex, toIndex);
    });

    const orderTreesSorted = items.map(getOrderTree).sort(orderTreeService.compare);

    return itemsOrdered.map((item, i) => setOrderTree(item, orderTreesSorted[i]));
  };

  const moveDown = (index: number, items: T[]) => {
    // Can't move the last item down
    if (index === items.length - 1) return items;
    return moveAt([index], [index + 1], items);
  };

  const moveUp = (index: number, items: T[]) => {
    // Can't move the first item up
    if (index === 0) return items;

    return moveAt([index], [index - 1], items);
  };

  return {
    moveAt,
    moveDown,
    moveUp,
  };
};
