import { useFeatureFlag } from 'features/feature-flags';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useGetIsPaidAccount } from '../get-is-paid-account/use-get-is-paid-account';
import { getIsOrganizationSafeToShare } from './get-is-organization-safe-to-share';

/**
 * Determines if an organization can share links or public library.
 * Returns true if organization is paid and has at least 7-days age.
 *
 * @returns {boolean}
 */
export function useGetCanShareLinks(): boolean {
  const isShareLinksEntitlementEnabled = useFeatureFlag('shareLinksEntitlement');
  const isPaidAccount = useGetIsPaidAccount();
  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const plan = useSelector(SessionSelector.getCurrentPlan);

  const isOrganizationSafeToShare = getIsOrganizationSafeToShare(selectedOrganization, plan);

  return isShareLinksEntitlementEnabled || (isPaidAccount && isOrganizationSafeToShare);
}
