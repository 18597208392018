import angular from 'angular';
import templateUrl from './progressbar.component.html';
import './progressbar.scss';

angular.module('frontStreetApp.directives').component('psProgressbar', {
  bindings: {
    percent: '<',
  },
  templateUrl,
  controller($state, $scope) {
    $scope.isEmbeddedInMsTeams = $state.includes('microsoftTeams');
  },
});
