import angular from 'angular';
import { groupWithUserSelector } from 'reducers/group/group.selectors';
import { groupMembershipByGroupSelector } from 'reducers/group-membership/group-membership.selectors';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { Rules } from 'components/invitation/invitation-widget/rules';
import { IdentityProvider, OrganizationMembershipRole, UserStatus } from '@process-street/subgrade/core';
import { SourcesToRolesMap } from 'pages/organizations/manage/users/components/role-selector/model';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { isGroupUser, isStandardUser } from '@process-street/subgrade/util/user-type-utils';
import { isT5KPlan } from 'services/plans';
import { trace } from 'components/trace';
import { GroupActions } from 'reducers/group/group.actions';
import { HttpStatus } from '@process-street/subgrade/util';
import { queryClient } from 'components/react-root';
import { GetGroupMembershipsQuery } from 'features/group-memberships/query-builder';

class GroupManagementMembersCtrl {
  constructor(
    $scope,
    $state,
    $ngRedux,
    FeatureFlagService,
    GroupMembershipActions,
    InvitationService,
    SyncService,
    ToastService,
    UserSuggester,
  ) {
    'ngInject';

    const logger = trace({ name: 'GroupManageMembersCtrl' });
    logger.info('loading ctrl');

    this.store = $ngRedux;
    this.$scope = $scope;
    this.$state = $state;
    this.SyncService = SyncService;
    this.InvitationService = InvitationService;
    this.ToastService = ToastService;

    const mapStateToScope = state => {
      const group = groupWithUserSelector(state, $state.params.id);
      const groupMembership = groupMembershipByGroupSelector(state, $state.params.id);
      const memberships = groupMembership.map(({ organizationMembership }) => organizationMembership);
      const featureFlags = FeatureFlagSelector.getFeatureFlags(state);
      const plan = SessionSelector.getCurrentPlan(state);
      const organizationMembership = OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId;
      const allowAddFreeMembers = isT5KPlan(plan.id);

      return {
        group,
        memberships,
        featureFlags,
        plan,
        organizationMembership,
        allowAddFreeMembers,
      };
    };

    const mapDispatchToScope = {
      queryGroupMembershipByGroup: GroupMembershipActions.queryGroupMembershipByGroup,
      addUserToGroup: GroupActions.addUserToGroup,
      removeUserFromGroup: GroupActions.removeUserFromGroup,
    };

    this.unsubscibe = $ngRedux.connect(mapStateToScope, mapDispatchToScope)($scope);

    $scope.inviteConfig = Rules.fullMember;
    $scope.availableRoles = $scope.allowAddFreeMembers
      ? [...SourcesToRolesMap.Groups, OrganizationMembershipRole.FreeMember]
      : SourcesToRolesMap.Groups;

    // Suggestions
    const excludedOrganizationMemberships = $scope.allowAddFreeMembers
      ? [OrganizationMembershipRole.Guest]
      : [OrganizationMembershipRole.FreeMember, OrganizationMembershipRole.Guest];

    const userSuggester = new UserSuggester(
      [IdentityProvider.ProcessStreet],
      membership => isStandardUser(membership.user) && !excludedOrganizationMemberships.includes(membership.role),
    );
    $scope.suggestedUsers = [];

    $scope.initializeSuggestedUsers = () => {
      const excludeUsers = $scope.memberships?.map(membership => membership.user) ?? [];
      userSuggester.suggest(undefined, excludeUsers).then(users => ($scope.suggestedUsers = users));
    };

    $scope.selectSuggestion = ({ email }) => {
      $scope.email = email;
      this.add({ email });
    };
  }

  $onInit = () => {
    this.$scope.queryGroupMembershipByGroup(this.$state.params.id);
    this.$scope.initializeSuggestedUsers();
  };

  addOrInvite = (membership, email) => {
    if (membership) {
      const inviteeInGroup = this.$scope.memberships.find(mem => mem.id === membership.id);
      if (inviteeInGroup) {
        const errorMessage = `${inviteeInGroup.user.username} is already a member of this group.`;
        this.ToastService.openToast({
          status: 'warning',
          title: errorMessage,
        });
        return Promise.reject(errorMessage);
      } else {
        return this.$scope.addUserToGroup(this.$scope.group, email);
      }
    } else {
      const resource = { type: 'Group', id: this.$scope.group.id };

      return this.InvitationService.invite(email, resource).then(({ organizationMembership }) => {
        this.ToastService.openToast({
          status: 'success',
          title: `Invitation sent to ${email}`,
        });
        this.$scope.memberships.push(organizationMembership);
        // The invitation also adds the user to the group, so we need that membership
        return this.SyncService.pullGroupMemberships(organizationMembership.user.id);
      });
    }
  };

  getOrganizationMembershipByEmail = email =>
    OrganizationMembershipSelector.getBySelectedOrganizationIdAndUserEmail(email)(this.store.getState());

  add = ({ email }) => {
    this.$scope.adding = true;

    const membership = this.getOrganizationMembershipByEmail(email);

    if (!this.$scope.allowAddFreeMembers && membership?.role === OrganizationMembershipRole.FreeMember) {
      this.ToastService.openToast({
        status: 'warning',
        title: 'Free members cannot be added to groups',
      });
      this.$scope.adding = false;
      return;
    }

    this.addOrInvite(membership, email)
      .then(response => {
        // Reset form
        this.$scope.email = '';
        this.$scope.form?.$setPristine();

        return response;
      })
      .then(() => queryClient.invalidateQueries(GetGroupMembershipsQuery.key))
      .catch(error => {
        if (error.response) {
          const status = error.response.status === HttpStatus.BAD_REQUEST ? 'warning' : 'error';
          this.ToastService.openToast({
            status,
            title: `We're having problems adding that user to the group`,
            description: DefaultErrorMessages.getUserInactiveDescription(email, error.response),
          });
        }
      })
      .finally(() => {
        this.$scope.initializeSuggestedUsers();
        this.$scope.adding = false;
      });
  };

  remove = membership => {
    this.$scope
      .removeUserFromGroup(this.$scope.group, membership)
      .then(() => queryClient.invalidateQueries(GetGroupMembershipsQuery.key));
  };

  $onDestroy = () => {
    this.unsubscibe();
  };

  isCreated = status => status === UserStatus.Created;

  isGroupUser = user => isGroupUser(user);
}

angular.module('frontStreetApp.controllers').controller('GroupManageMembersCtrl', GroupManagementMembersCtrl);
