import * as React from 'react';
import { Box, Input, Text } from 'components/design/next';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { WorkflowOption, workflowOptions } from './predefined-workflows-options';
import { components, MenuProps, ValueContainerProps } from 'react-select';
import { useAiGeneratedModalSettingsContext } from './context';

export type WorkflowNameSelectorFieldProps = {
  value: string;
  description: string;
  onSelect: (option: WorkflowOption) => void;
  isDisabled?: boolean;
};

export const WorkflowNameSelectorField: React.FC<React.PropsWithChildren<WorkflowNameSelectorFieldProps>> = ({
  value,
  description,
  onSelect,
  isDisabled,
}) => {
  return (
    <Box
      sx={{
        '&& .blvd-select': {
          '.blvd-select__menu': {
            mt: -10,
            boxShadow: 'unset',
          },
          '.blvd-select__control': {
            'height': 10,
            'cursor': 'text',
            '&--is-focused': {
              borderColor: 'gray.200',
              boxShadow: 'unset',
            },
          },
          '.blvd-select__value-container': {
            'px': 4,

            '&.blvd-select__value-container--has-value': {
              display: 'flex',
            },
          },
          '.blvd-select__placeholder': {
            color: 'gray.400',
          },
          '.blvd-select__indicator, .blvd-select__indicator-separator': {
            display: 'none',
          },
          '.blvd-select__menu-notice--no-options': {
            display: 'none',
          },
          '.blvd-select__option__wrapper': {
            padding: 0,
          },
          '.blvd-select__option': {
            borderRadius: 0,
            py: 1,
            px: 4,
          },
          '.blvd-select__option--is-selected, .blvd-select__option--is-focused': {
            bgColor: 'brand.500',
            color: 'white',
          },
          '.blvd-select__menu-list': {
            maxHeight: '200px',
          },
        },
      }}
    >
      <BlvdSelect
        options={workflowOptions}
        value={value ? { name: value, description } : undefined}
        onChange={value => {
          if (BlvdSelectHelpers.isOptionType<WorkflowOption>(value)) {
            onSelect(value);
          }
        }}
        components={{
          Menu: CustomMenu,
          ValueContainer: CustomValueContainer,
        }}
        inputValue={value}
        placeholder="e.g., “Employee Onboarding”"
        getOptionLabel={o => o.name}
        getOptionValue={o => o.name}
        enableSearchAutoFocus
        isDisabled={isDisabled}
        isSearchable
      />
    </Box>
  );
};

const CustomMenu = (props: MenuProps<WorkflowOption>) => {
  const { selectProps } = props;
  const { name, onInputChange } = useAiGeneratedModalSettingsContext();

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectProps.onInputChange && e.currentTarget) {
      selectProps.onInputChange(e.currentTarget.value, {
        action: 'input-change',
        prevInputValue: name,
      });
      onInputChange(e.currentTarget.value);
    }
  };

  const handleSearchInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (selectProps.onSearchInputBlur) {
      selectProps.onSearchInputBlur(e);
    }
  };

  const handleSearchInputMouseDown = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.currentTarget.focus();
  };

  const handleSearchInputTouchEnd = (e: React.TouchEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.currentTarget.focus();
  };

  const matches = props.options
    .flatMap(BlvdSelectHelpers.getGroupOptionsOrOption)
    .filter(option => option.name.toLowerCase().includes(name.toLowerCase()));

  return (
    <components.Menu {...props}>
      <>
        <Input
          id="blvd-select__menu__header__search-input"
          type="text"
          autoComplete="off"
          onMouseDown={handleSearchInputMouseDown}
          onTouchEnd={handleSearchInputTouchEnd}
          onChange={handleSearchInputChange}
          onBlur={handleSearchInputBlur}
          tabIndex={1}
          autoFocus
          value={name ?? ''}
          placeholder="e.g., “Employee Onboarding”"
        />

        {matches.length > 0 && (
          <Box borderWidth="1px" borderStyle="solid" borderColor="gray.200" boxShadow="lg" borderRadius="3px">
            <Text variant="-2u" color="gray.400" pt="2" px="4">
              Suggested
            </Text>

            {props.children}
          </Box>
        )}
      </>
    </components.Menu>
  );
};

const CustomValueContainer: React.FC<React.PropsWithChildren<ValueContainerProps<WorkflowOption>>> = props => {
  const { name } = useAiGeneratedModalSettingsContext();

  return (
    <components.ValueContainer {...props}>
      <Text>{name}</Text> {props.children}
    </components.ValueContainer>
  );
};
