import * as React from 'react';
import { useState } from 'react';
import { NewOrganizationModal } from 'features/create-organization/components/new-organization-modal';
import { Button, Icon } from 'components/design/next';

export const NewOrganizationButton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setModalOpen(true)} leftIcon={<Icon icon="plus" size="4" mr="2" />}>
        New Organization
      </Button>

      <NewOrganizationModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};
