import * as React from 'react';
import { Template, TemplateRevision } from '@process-street/subgrade/process';
import { Box, ButtonGroup, ButtonGroupProps, Icon, Tooltip } from 'components/design/next';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';
import { useGetDraftTmplRevisionByTmplIdQuery } from 'app/features/template-revisions/query-builder';
import { MiddleGroupButton } from 'app/components/focus-bar/workflow/middle-button-group/view-v2/view-middle-button';
import { PermissionPopover } from 'app/components/focus-bar/workflow/middle-button-group/view-v2/permission-popover';
import { queryString } from '@process-street/subgrade/util';
import { useNavigate } from '@process-street/adapters/navigation';
import { useDefaultOpenDisclosure } from 'app/hooks/use-default-open-disclosure';

export type ViewMiddleButtonGroupProps = ButtonGroupProps & {
  templateRevision: TemplateRevision;
  templateId: Template['id'];
};

export enum MiddleGroupItems {
  Edit = 'Edit',
  View = 'View',
}

export const ViewMiddleButtonGroup: React.FC<ViewMiddleButtonGroupProps> = ({
  templateId,
  templateRevision,
  ...props
}) => {
  const draftTmplRevisionQuery = useGetDraftTmplRevisionByTmplIdQuery({ templateId: templateRevision.template.id });
  const draftExists = Boolean(draftTmplRevisionQuery.data);
  const viewButtonInfoRef = React.useRef<HTMLDivElement>(null);

  const templatePermissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateRevision.template.id);
  const hasPermission = Boolean(templatePermissionsQuery.data?.permissionMap.pageUpdate);

  const navigate = useNavigate();
  const handleClick = React.useCallback(() => {
    navigate({ pathname: 'pageV2', search: queryString.stringify({ id: templateId }) });
  }, [navigate, templateId]);

  const tooltipDisclosure = useDefaultOpenDisclosure({ ref: viewButtonInfoRef });

  return (
    <ButtonGroup spacing="2" {...props} alignItems="center">
      <PermissionPopover templateId={templateRevision.template.id} isDisabled={hasPermission}>
        <MiddleGroupButton isDisabled={!hasPermission} onClick={handleClick}>
          {MiddleGroupItems.Edit}
        </MiddleGroupButton>
      </PermissionPopover>
      <Icon icon="chevron-right" size="4" color="gray.500" />
      <MiddleGroupButton
        rightIcon={
          draftExists ? (
            <Tooltip
              label="Latest published version"
              hasArrow
              isOpen={tooltipDisclosure.isOpen}
              onClose={tooltipDisclosure.onClose}
            >
              <Box ref={viewButtonInfoRef} mb="-1px">
                <Icon icon="info-circle" size="3.5" />
              </Box>
            </Tooltip>
          ) : undefined
        }
        isActive
      >
        {MiddleGroupItems.View}
      </MiddleGroupButton>
    </ButtonGroup>
  );
};
