import * as React from 'react';
import * as yup from 'yup';
import { Box, FormLabel, FormLabelProps, HStack, Text, TextProps } from 'components/design/next';
import { TEXT_MAX_LENGTH } from '@process-street/subgrade/process';
import { NumberField } from '../common/number-field';
import { useField } from 'formik';
import { StringUtils } from '@process-street/subgrade/util';

export type MinMaxFieldsProps<T> = T extends yup.ObjectSchema<infer P>
  ? P extends { constraints: object }
    ? {
        minFieldName: `constraints.${keyof P['constraints'] & string}`;
        maxFieldName: `constraints.${keyof P['constraints'] & string}`;
        unit: string;
        isRange?: boolean;
      }
    : never
  : never;

export function MinMaxFields<T extends yup.ObjectSchema<any>>({
  maxFieldName,
  minFieldName,
  unit,
  children,
}: React.PropsWithChildren<MinMaxFieldsProps<T>>) {
  const [minField] = useField<number>(minFieldName as string);
  const [maxField] = useField<number>(maxFieldName as string);
  let to: React.ReactChild | null = null;
  let formLabel: React.ReactChild | null = null;

  React.Children.forEach(children, child => {
    if (!React.isValidElement(child)) return;
    if (child.type === MinMaxTo) to = child;
    if (child.type === MinMaxLabel) formLabel = child;
  });

  return (
    <Box w="full">
      {formLabel ?? <MinMaxLabel>{StringUtils.capitalize(unit)}</MinMaxLabel>}
      <HStack spacing={2.5} alignItems="start">
        <NumberField
          name={minFieldName as string}
          aria-label={`min ${unit}`}
          min={0}
          max={maxField.value || TEXT_MAX_LENGTH}
        >
          Min
        </NumberField>
        {to}
        <NumberField
          name={maxFieldName as string}
          aria-label={`max ${unit}`}
          min={minField.value || 0}
          max={TEXT_MAX_LENGTH}
        >
          Max
        </NumberField>
      </HStack>
    </Box>
  );
}

export const MinMaxLabel: React.FC<React.PropsWithChildren<FormLabelProps>> = props => (
  <FormLabel color="gray.700" {...props} />
);

export const MinMaxTo: React.FC<React.PropsWithChildren<TextProps>> = props => (
  <Box pt="10px">
    <Text variant="-1" color="gray.500" {...props}>
      {props.children ?? 'to'}
    </Text>
  </Box>
);
