import { createCachedAction } from '../util';
import { OrganizationDomainSelector } from './organization-domain.selectors';

export const ORGANIZATION_DOMAIN_GET_ALL_BY_ORGANIZATION_ID = 'organization/domain/GET_ALL_BY_ORGANIZATION_ID';
export const ORGANIZATION_DOMAIN_CREATE_BY_ORGANIZATION_ID_AND_NAME =
  'organization/domain/CREATE_BY_ORGANIZATION_ID_AND_NAME';
export const ORGANIZATION_DOMAIN_DELETE_BY_ID_AND_NAME = 'organization/domain/DELETE_BY_ID_AND_NAME';

export const OrganizationDomainActions = OrganizationDomainApi => {
  'ngInject';

  /**
   * Gets all organization domains by organization id
   */
  const getAllByOrganizationId = createCachedAction(
    ORGANIZATION_DOMAIN_GET_ALL_BY_ORGANIZATION_ID,
    OrganizationDomainApi.getAllByOrganizationId,
    (organizationId, flushCache) => ({ organizationId, flushCache }),
    (state, organizationId) =>
      OrganizationDomainSelector.organizationDomainByOrganizationId(organizationId)(state) !== undefined,
    OrganizationDomainSelector.organizationDomainByOrganizationId,
  );

  /**
   * Create organization domain by organization id
   */
  const createByOrganizationIdAndName = createCachedAction(
    ORGANIZATION_DOMAIN_CREATE_BY_ORGANIZATION_ID_AND_NAME,
    OrganizationDomainApi.createByOrganizationIdAndName,
    (organizationId, name) => ({ organizationId, name }),
  );

  /**
   * Deletes domain by organization id
   */
  const deleteByOrganizationIdAndName = createCachedAction(
    ORGANIZATION_DOMAIN_DELETE_BY_ID_AND_NAME,
    OrganizationDomainApi.deleteByOrganizationIdAndName,
    (organizationId, name) => ({ organizationId, name }),
  );

  return {
    getAllByOrganizationId,
    createByOrganizationIdAndName,
    deleteByOrganizationIdAndName,
  };
};
