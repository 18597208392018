import {
  EMAIL_BRAND_FOOTER_DEFAULT,
  EMAIL_BRAND_FOOTER_MAX_LENGTH,
  OrganizationTheme,
} from '@process-street/subgrade/core';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Link,
  Text,
  Textarea,
} from 'components/design/next';
import DOMPurify from 'dompurify';
import { useCreateOrganizationTheme } from 'features/brand-customization/query-builder/create-organization-theme';
import {
  GetOrganizationTheme,
  useGetOrganizationTheme,
} from 'features/brand-customization/query-builder/get-organization-theme';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useDebouncedCallback } from 'use-debounce';

const DEBOUNCE_MS = 5000;
const DOCS_URL = 'https://www.process.st/help/docs/org-level-notifications/';
const ALLOWED_TAGS = ['p', 'a', 'b', 'strong', 'i', 'em'];
const ALLOWED_ATTR = ['style', 'href', 'target', 'mailto'];

const sanitizeHTML = (input: string) =>
  DOMPurify.sanitize(input, {
    ALLOWED_TAGS,
    ALLOWED_ATTR,
  });

// As sanitize doesn't preserve attribute order, using this: https://github.com/cure53/DOMPurify/issues/435#issuecomment-782418861.
const isValid = (rawHtml: string) => rawHtml === sanitizeHTML(sanitizeHTML(rawHtml));

export const EmailFooterTextarea: React.FC<React.PropsWithChildren<unknown>> = () => {
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const queryClient = useQueryClient();
  const restoreDefaultRef = React.useRef<HTMLButtonElement | null>(null);

  const organizationThemeQuery = useGetOrganizationTheme({ organizationId });

  const [footer, setFooter] = React.useState<string>();
  const [shouldShowError, setShouldShowError] = React.useState(false);

  const updateOrganizationThemeMutation = useCreateOrganizationTheme({
    onSuccess: async () => {
      await queryClient.invalidateQueries(GetOrganizationTheme.getKey({ organizationId }));

      organizationThemeQuery.refetch();
    },
  });

  const onUpdate = (overrides?: Partial<OrganizationTheme>) => {
    if (!isValid(overrides?.emailFooter ?? '')) {
      setShouldShowError(true);
    } else {
      updateOrganizationThemeMutation.mutate({
        organizationId,
        brandColor: organizationThemeQuery.data?.brandColor ?? '',
        emailCustomizationEnabled: organizationThemeQuery.data?.emailCustomizationEnabled ?? false,
        emailPrimaryColor: organizationThemeQuery.data?.emailPrimaryColor ?? '',
        emailBackgroundColor: organizationThemeQuery.data?.emailBackgroundColor ?? '',
        emailFooter: overrides?.emailFooter ?? '',
        emailHeaderLogoFileId: organizationThemeQuery.data?.emailHeaderLogoFileId ?? null,
      });
    }
  };

  const debouncedUpdate = useDebouncedCallback((value: string) => {
    onUpdate({ emailFooter: value });
  }, DEBOUNCE_MS);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    setFooter(value);

    if (shouldShowError && isValid(value)) {
      setShouldShowError(false);
    }

    debouncedUpdate(value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (restoreDefaultRef.current && !restoreDefaultRef.current.contains(e.relatedTarget as Node)) {
      debouncedUpdate.flush();
    }
  };

  const handleRestoreDefault = () => {
    debouncedUpdate.cancel();
    onUpdate({ emailFooter: EMAIL_BRAND_FOOTER_DEFAULT });
    setFooter(EMAIL_BRAND_FOOTER_DEFAULT);
    setShouldShowError(false);
  };

  if (footer === undefined && organizationThemeQuery.isSuccess) {
    // organizationThemeQuery.data could be undefined as organizations doens't have a theme when created. We set to default footer in that case, or when is empty string.
    setFooter(
      organizationThemeQuery.data?.emailFooter && organizationThemeQuery.data?.emailFooter !== ''
        ? organizationThemeQuery.data?.emailFooter
        : EMAIL_BRAND_FOOTER_DEFAULT,
    );
  }

  return (
    <FormControl
      as={Grid}
      isInvalid={shouldShowError}
      w="full"
      mt="8"
      templateColumns={{ base: '1fr', md: '220px 1fr' }}
      alignItems="start"
    >
      <GridItem display="flex" alignItems="center">
        <FormLabel color="gray.600" as="label">
          Footer
        </FormLabel>
      </GridItem>

      <GridItem mt={{ base: '-4', md: '0' }}>
        <Textarea
          minH="34"
          maxLength={EMAIL_BRAND_FOOTER_MAX_LENGTH}
          value={footer}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </GridItem>
      <GridItem></GridItem>
      <GridItem>
        <Button
          ref={restoreDefaultRef}
          variant="unstyled"
          color="gray.400"
          display="inline-flex"
          onClick={handleRestoreDefault}
          leftIcon={<Icon icon="undo" size="3"></Icon>}
        >
          <Text as="u" fontSize="sm">
            Restore default
          </Text>
        </Button>
      </GridItem>
      <GridItem></GridItem>
      <FormErrorMessage as={GridItem}>
        <Text variant="-2">
          Please use valid HTML syntax.{' '}
          <Link href={DOCS_URL} isExternal>
            Learn more.
          </Link>
        </Text>
      </FormErrorMessage>
    </FormControl>
  );
};
