import { FocusBarV2 } from 'app/components/focus-bar/workflow';
import { EditorModal } from 'app/pages/forms/_id/shared/editor-modal/editor-modal';
import { InsertWidgetsSection } from './insert-widgets-section/insert-widgets-section';
import { TaskListSection } from './task-list-section';
import { WidgetListSection } from './widgets-list-section';

export const Editor = {
  FocusBar: FocusBarV2,
  TaskListSection,
  WidgetListSection,
  InsertWidgetsSection,
  Modal: EditorModal,
};
