import * as React from 'react';
import {
  Button,
  Grid,
  GridItem,
  HStack,
  ModalBody as ModalBodyContainer,
  Skeleton,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from 'components/design/next';
import { Form, useFormikContext } from 'formik';
import { ConditionalLogicUtils } from 'features/conditional-logic/utils/conditional-logic-utils';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { SideMenu } from '../sidemenu';
import { HiddenRules } from '../hidden-rules';
import { RulesManager } from '../rules-manager';
import { useNormalizedData } from 'features/conditional-logic/hooks/useNormalizedData';
import { ConditionalLogicModalFormValues } from './get-conditional-logic-delta';
import { SelectorHelper } from 'directives/rules/template/task-templates-selector/selector-helper';
import { useNodes } from 'features/conditional-logic/hooks/useNodes';
import { EmptyRuleDefinition } from '../empty-rule-definition/empty-rule-definition';
import { EmptySideMenu } from '../empty-side-menu';
import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { useConditionalLogicModalStore } from './store';

type ModalBodyProps = {
  taskTemplates: TaskTemplate[];
  widgets: Widget[];
  initialRules: ChecklistRuleDefinition[];
  isLoading?: boolean;
  isSaving?: boolean;
  onCancel: () => void;
};

enum CL_TABS {
  RULES = 0,
  HIDDEN = 1,
}

export const ModalBody: React.FC<React.PropsWithChildren<ModalBodyProps>> = ({
  taskTemplates,
  initialRules,
  widgets,
  isLoading,
  isSaving,
  onCancel,
}) => {
  const { selectedWidget, selectedTask, dispatch: dispatchModalState } = useConditionalLogicModalStore();
  const shouldShowTabPanel = !(selectedWidget || selectedTask);

  const { values } = useFormikContext<ConditionalLogicModalFormValues>();

  const nodes = useNodes();
  const { selectedWidgets: widgetsHiddenByDefault, selectedTaskTemplates: tasksHiddenByDefault } =
    SelectorHelper.getSelection(values.nodeInfoMap, nodes);

  const normalizedData = useNormalizedData({
    rules: values.rules,
    tasks: taskTemplates,
    widgets,
  });

  React.useEffect(() => {
    dispatchModalState({
      type: 'SET_NODES_STATUS',
      payload: ConditionalLogicUtils.getNodesStatus(values.rules, normalizedData),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- rules change
  }, [values.rules]);

  React.useEffect(() => {
    dispatchModalState({
      type: 'SET_NORMALIZED_DATA',
      payload: normalizedData,
    });

    return () => {
      dispatchModalState({
        type: 'SET_NORMALIZED_DATA',
        // Reset normalized data when component unmounts
        payload: ConditionalLogicUtils.getNormalizedData([], [], []),
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- data change
  }, [normalizedData]);

  const actionButtons = (
    <HStack
      w="full"
      justifyContent="flex-end"
      position="sticky"
      bottom="0"
      bgColor="white"
      py="6"
      px="4"
      background="linear-gradient(0deg, #fff 0%, #fff 80%, rgba(0,0,0,0) 100%)"
    >
      <Button onClick={() => onCancel()} type="button" variant="ghost" fontWeight="400" h="12" colorScheme="gray">
        Cancel
      </Button>

      <Button
        type="submit"
        colorScheme="brand"
        h="12"
        mr={3}
        isLoading={isSaving}
        isDisabled={isSaving}
        loadingText="Saving..."
      >
        Save
      </Button>
    </HStack>
  );

  const [tabIndex, setTabIndex] = React.useState<CL_TABS>(CL_TABS.RULES);
  // When a task/widget is selected and tab panel is hidden, always show rules content
  if (!shouldShowTabPanel && tabIndex !== CL_TABS.RULES) {
    setTabIndex(CL_TABS.RULES);
  }

  return (
    <ModalBodyContainer display="grid" px="0" py="0" borderBottomRightRadius="2xl" borderBottomLeftRadius="2xl">
      <Grid
        position="relative"
        overflowY="hidden"
        gridTemplateColumns={{ md: '1fr', lg: '380px 1fr', xl: '452px 1fr' }}
        gridTemplateRows={{ lg: '100% auto' }}
      >
        {isLoading ? (
          <EmptySideMenu />
        ) : (
          <SideMenu rules={values.rules} taskTemplates={taskTemplates} widgets={widgets} />
        )}

        <GridItem overflowY="auto" as="main">
          <Tabs
            index={tabIndex}
            onChange={setTabIndex}
            sx={{
              '&&': {
                '.chakra-tabs__tab': {
                  'fontWeight': 500,

                  '&[aria-selected=true]': {
                    color: 'brand.500',
                    borderColor: 'brand.500',
                  },

                  '&:not([aria-selected=true])': {
                    color: 'gray.500',
                  },
                },
              },
            }}
            isFitted
          >
            {shouldShowTabPanel && (
              <TabList position="sticky" top="0" bgColor="white" zIndex="2" h="16" fontWeight="semibold">
                <Tab isDisabled={isLoading}>
                  <Skeleton isLoaded={!isLoading}>Rules ({values.rules.length ?? 0})</Skeleton>
                </Tab>
                <Tab isDisabled={isLoading}>
                  <Skeleton isLoaded={!isLoading}>
                    Hidden ({(tasksHiddenByDefault?.length ?? 0) + (widgetsHiddenByDefault?.length ?? 0)})
                  </Skeleton>
                </Tab>
              </TabList>
            )}

            <TabPanels as={Form}>
              <TabPanel pb="0" px="0">
                {isLoading ? (
                  <GridItem as="main">
                    <VStack spacing="5" px="4" w="full">
                      <EmptyRuleDefinition isLoading />
                      <EmptyRuleDefinition isLoading />
                      <EmptyRuleDefinition isLoading />
                    </VStack>
                  </GridItem>
                ) : (
                  <>
                    <RulesManager
                      initialRules={initialRules}
                      taskTemplates={taskTemplates}
                      widgets={widgets}
                      selectedWidget={selectedWidget}
                    />
                    {actionButtons}
                  </>
                )}
              </TabPanel>
              <TabPanel pb="0" px="0">
                {isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <HiddenRules taskTemplates={taskTemplates} widgets={widgets} />
                    {actionButtons}
                  </>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </GridItem>
      </Grid>
    </ModalBodyContainer>
  );
};
