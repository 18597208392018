import * as React from 'react';
import { AvatarBadge, Box, Tooltip } from 'components/design/next';
import { TemplateTaskAssignment } from '@process-street/subgrade/process';
import { ChakraAvatar } from 'app/components/design/next/chakra-avatar';
import { OrganizationMembership, User } from '@process-street/subgrade/core';

export type ApprovalTaskListAssigneesProps = {
  taskAssignments: TemplateTaskAssignment[];
};

export const ApprovalTaskListAssignees: React.FC<ApprovalTaskListAssigneesProps> = ({ taskAssignments }) => {
  const firstAssignee = (taskAssignments[0].organizationMembership as OrganizationMembership).user as User;
  const count = taskAssignments.length;
  const tooltipLabel =
    count > 1
      ? `Assigned to ${firstAssignee.username} and ${count} ${count > 1 ? 'others' : 'other'}`
      : `Assigned to ${firstAssignee.username}`;

  return (
    <Tooltip label={tooltipLabel}>
      <Box position="relative">
        <ChakraAvatar user={firstAssignee}>
          {count > 1 && (
            <AvatarBadge borderColor="white" borderWidth="thin" bg="gray.500" boxSize="1.25em" fontSize="xs">
              {taskAssignments.length}
            </AvatarBadge>
          )}
        </ChakraAvatar>
      </Box>
    </Tooltip>
  );
};
