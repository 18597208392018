import { GroupType, isOrganizationMembershipActive, Organization } from '@process-street/subgrade/core';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import {
  isAssignableUserOrGroupOm,
  isStandardUserOrStandardGroupOm,
} from '@process-street/subgrade/util/membership-utils';
import { useGetAllGroupsQuery } from 'features/group/query-builder/get-all-groups';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { GetTaskTemplatePermitsQuery } from 'features/template-revisions/query-builder';
import React from 'react';
import { transformUsersToOptions } from '../transformer';

export function useGetMembershipsOptions({
  taskTemplates,
  templateRevisionId,
  organizationId,
}: {
  taskTemplates: TaskTemplate[];
  templateRevisionId: TemplateRevision['id'];
  organizationId: Organization['id'];
}) {
  const taskTemplatesIdsSet = new Set(taskTemplates.map(taskTemplate => taskTemplate.id));
  const { data: systemGroups } = useGetAllGroupsQuery(
    { include: 'user' },
    {
      select: data => data.filter(group => group.groupType !== GroupType.Standard),
    },
  );

  const { data: permits } = GetTaskTemplatePermitsQuery.useQuery(
    {
      templateRevisionId,
    },
    {
      select: data => data.permits.filter(permit => taskTemplatesIdsSet.has(permit.taskTemplate.id)),
    },
  );

  const allOrganizationMembershipsQuery = useGetAllOrganizationMembershipsQuery(
    {
      organizationId,
    },
    {
      select: data => data.filter(isStandardUserOrStandardGroupOm),
    },
  );

  // Assigned memberships for to all task templates.
  const assignedMembershipToAllTaskTemplatesIds = React.useMemo(() => {
    if (permits) {
      const filteredPermits = permits.filter(permit => permit.taskRead || permit.taskUpdate);

      // Group permits by organization membership ID
      type PermitsByMembership = {
        [key: string]: Set<string>;
      };
      const permitsByMembership = filteredPermits.reduce<PermitsByMembership>((acc, permit) => {
        if (!acc[permit.organizationMembership.id]) {
          acc[permit.organizationMembership.id] = new Set();
        }
        acc[permit.organizationMembership.id].add(permit.taskTemplate.id);
        return acc;
      }, {});

      // Check which memberships have permits for all task templates
      return Object.entries(permitsByMembership)
        .filter(([, taskTemplatesIds]) => taskTemplatesIdsSet.size === taskTemplatesIds.size)
        .map(([membershipId]) => membershipId);
    }

    return [];
  }, [permits, taskTemplatesIdsSet.size]);

  const organizationMemberships = React.useMemo(() => {
    if (allOrganizationMembershipsQuery.data && systemGroups) {
      const systemGroupUserIdsSet = new Set(systemGroups.map(group => group.user.id));

      return allOrganizationMembershipsQuery.data.filter(membership => !systemGroupUserIdsSet.has(membership.user.id));
    }

    return [];
  }, [allOrganizationMembershipsQuery.data, systemGroups]);

  const membershipsWithoutPermitsOptions = React.useMemo(() => {
    const assignedMembershipIdsSet = new Set(assignedMembershipToAllTaskTemplatesIds);
    const memberships = organizationMemberships.filter(
      membership =>
        isAssignableUserOrGroupOm(membership) &&
        !assignedMembershipIdsSet.has(membership.id) &&
        isOrganizationMembershipActive(membership),
    );

    const users = memberships.map(membership => membership.user);
    return transformUsersToOptions(users);
  }, [assignedMembershipToAllTaskTemplatesIds, organizationMemberships]);

  const membershipsWithPermits = React.useMemo(() => {
    if (permits) {
      const filteredPermits = permits.filter(permit => permit.taskRead || permit.taskUpdate);
      const membershipIdsWithPermitsSet = new Set(filteredPermits.map(permit => permit.organizationMembership.id));
      return organizationMemberships.filter(membership => membershipIdsWithPermitsSet.has(membership.id));
    }
    return [];
  }, [organizationMemberships, permits]);

  const allMemberships = React.useMemo(() => {
    if (allOrganizationMembershipsQuery.data) {
      return allOrganizationMembershipsQuery.data.filter(membership => isOrganizationMembershipActive(membership));
    }

    return [];
  }, [allOrganizationMembershipsQuery.data]);

  return { membershipsWithoutPermitsOptions, membershipsWithPermits, allMemberships };
}
