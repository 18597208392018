const DEFAULT_SEARCH_LIMIT = 100;

export const ChecklistApi = function (Checklists) {
  'ngInject';

  const getAllByOrganizationId = query => Checklists.getAllByOrganizationId(query).$promise;

  const getStats = checklistIds => Checklists.getStats({ checklistIds }).$promise;

  const getTaskStatsById = checklistId => Checklists.getTaskStatsById({ id: checklistId }).$promise;

  const getById = checklistId => Checklists.getById({ id: checklistId }).$promise;

  const create = createRequest => Checklists.create(createRequest).$promise;

  const updateNameById = (id, newName) => Checklists.updateNameById({ id, name: newName }).$promise;

  const updateDueDateById = (id, dueDate) => Checklists.updateDueDateById({ id, dueDate }).$promise;

  const updateStatus = (id, status) => Checklists.updateStatus({ id, status }).$promise;

  const updateShareable = (checklistId, shared) => Checklists.updateShareable({ id: checklistId, shared }).$promise;

  const deleteById = id => Checklists.deleteById({ id }).$promise;

  const undelete = id => Checklists.undelete({ id }).$promise;

  const search = (criteria = {}, limit = DEFAULT_SEARCH_LIMIT) => {
    const params = criteria || {};
    params.limit = limit || DEFAULT_SEARCH_LIMIT;
    return Checklists.search(params).$promise;
  };

  return {
    create,
    deleteById,
    getAllByOrganizationId,
    getById,
    getStats,
    getTaskStatsById,
    search,
    undelete,
    updateDueDateById,
    updateNameById,
    updateShareable,
    updateStatus,
  };
};
