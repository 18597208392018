import * as React from 'react';
import {
  AspectRatio,
  Button,
  Box,
  GridItem,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  Center,
  Portal,
} from 'components/design/next';
import { Icon } from 'components/design/next';
import { HexColorPicker } from 'react-colorful';
import { getContrastRatio, lighten } from 'utils/colorManipulator';
import { useUpdateEffect } from 'react-use';
import { useDebounce } from 'use-debounce';
import { match, P } from 'ts-pattern';

export type ColorInputProps = {
  onChange: (color: string) => void;
  onError?: (errorMessage: string) => void;
  onValid?: () => void;
  value: string | null;
  isLoading?: boolean;
  inputTestId?: string;
  showContrastRatioWarning?: boolean;
};

export const ColorInput: React.FC<React.PropsWithChildren<ColorInputProps>> = ({
  onChange,
  onError,
  onValid,
  value,
  isLoading,
  inputTestId = 'hex-color-input',
  showContrastRatioWarning = true,
}) => {
  const [color, setColor] = React.useState(value ?? '');
  const contrastRatio: number | null = color ? getContrastRatio(`#${color}`, '#fff') : null;

  const [debouncedColor, { isPending }] = useDebounce(color, 500);

  const errorMessage = React.useMemo(() => {
    if (color.length === 0 || validateHexColor(color)) return null;

    return INVALID_COLOR_ERROR;
  }, [color]);

  const updateColor = React.useCallback((color: string) => {
    // The API doesn't expect the Hex code to contain uppercase characters
    setColor(color.replace('#', '').toLowerCase());
  }, []);

  useUpdateEffect(() => {
    onChange?.(debouncedColor);
  }, [debouncedColor]);

  // Keep the inner state in sync with the external state
  useUpdateEffect(() => {
    if (!isPending() && value && value !== color) setColor(value ?? '');
  }, [value, color, isPending]);

  useUpdateEffect(() => {
    if (errorMessage) {
      onError?.(errorMessage);
    } else {
      onValid?.();
    }
  }, [errorMessage]);

  return (
    <Box w="full">
      <InputGroup>
        <InputLeftAddon borderColor="gray.300">#</InputLeftAddon>

        <Input
          borderColor="gray.300"
          value={color}
          onChange={e => updateColor(e.target.value)}
          data-testid={inputTestId}
          placeholder="Enter Hex Code"
          isDisabled={isLoading}
          _disabled={{ bgColor: 'gray.100', opacity: 1 }}
        />

        <InputRightElement zIndex="0" right="16">
          <HStack spacing="2">
            <Box data-testid="color-preview" borderRadius="full" w="6" h="6" bgColor={color ? `#${color}` : 'white'} />

            <Popover isLazy>
              <PopoverTrigger>
                <IconButton isDisabled={isLoading} variant="ghost" aria-label="Color picker" size="sm">
                  <Icon variant="fas" icon="eye-dropper" color="gray.500" size="4" />
                </IconButton>
              </PopoverTrigger>

              <Portal>
                <PopoverContent
                  maxW="310px"
                  px="3"
                  py="4"
                  sx={{
                    '& .react-colorful': {
                      width: '100% !important',
                      height: 'unset !important',
                      minH: '200px',
                    },
                  }}
                >
                  <Box py="4">
                    <Input
                      isDisabled={isLoading}
                      size="sm"
                      value={color}
                      onChange={e => updateColor(e.target.value)}
                      placeholder=""
                    />
                  </Box>

                  <SimpleGrid gap="1" columns={8} mb="4">
                    {DEFAULT_COLORS.map(color => (
                      <AspectRatio key={color} ratio={1}>
                        <GridItem
                          as={Button}
                          bgColor={color}
                          borderRadius="base"
                          borderColor="gray.100"
                          borderWidth="1px"
                          borderStyle="solid"
                          w="auto"
                          h="auto"
                          minW="unset"
                          minH="unset"
                          p="0"
                          aria-label={`Select color: ${color}`}
                          onClick={() => updateColor(color)}
                          _hover={{ bgColor: color }}
                        />
                      </AspectRatio>
                    ))}
                  </SimpleGrid>

                  <HexColorPicker
                    color={color}
                    onChange={(selectedColor: string) => {
                      if (!/NaN/.test(selectedColor)) {
                        updateColor(selectedColor);
                      }
                    }}
                  />

                  {color && (
                    <VStack mt="4" spacing="2" alignItems="stretch">
                      <Center
                        h="10"
                        bgColor={`#${color}`}
                        color="white"
                        borderWidth="1px"
                        borderColor="gray.100"
                        borderRadius="base"
                        borderStyle="solid"
                      >
                        This is an example text
                      </Center>

                      <Center
                        h="10"
                        bgColor="gray.50"
                        color={`#${color}`}
                        borderWidth="1px"
                        borderColor="gray.100"
                        borderRadius="base"
                        borderStyle="solid"
                      >
                        This is an example text
                      </Center>

                      <Center
                        h="10"
                        bgColor="white"
                        color={`#${color}`}
                        borderWidth="1px"
                        borderColor="gray.100"
                        borderRadius="base"
                        borderStyle="solid"
                      >
                        This is an example text
                      </Center>

                      <Center
                        h="10"
                        bgColor={lighten(`#${color}`, 0.9)}
                        color={`#${color}`}
                        borderWidth="1px"
                        borderColor="gray.100"
                        borderRadius="base"
                        borderStyle="solid"
                      >
                        This is an example text
                      </Center>
                    </VStack>
                  )}
                </PopoverContent>
              </Portal>
            </Popover>
          </HStack>
        </InputRightElement>

        <InputRightAddon
          borderColor="gray.300"
          px="3"
          cursor="pointer"
          onClick={() => updateColor('')}
          bgColor={isLoading ? 'gray.100' : 'transparent'}
          _hover={{ bgColor: isLoading ? 'transparent' : 'gray.100' }}
        >
          {isLoading ? <Spinner /> : <Icon icon="times" color="gray.500" size="4" />}
        </InputRightAddon>
      </InputGroup>
      {match({ errorMessage, contrastRatio, showContrastRatioWarning })
        .with({ errorMessage: P.not(P.nullish) }, ({ errorMessage }) => (
          <HStack spacing="2">
            <Icon variant="fas" icon="warning" color="red.500" size="4" />
            <Text lineHeight="short" color="red.500" fontSize="sm">
              {errorMessage}
            </Text>
          </HStack>
        ))
        .with({ contrastRatio: P.number, showContrastRatioWarning }, ({ contrastRatio, showContrastRatioWarning }) =>
          contrastRatio < 1.5 && showContrastRatioWarning ? (
            <HStack spacing="2">
              <Icon variant="fas" icon="warning" color="red.500" size="4" />
              <Text lineHeight="short" color="red.500" fontSize="sm">
                This color is very light, it will affect text readability
              </Text>
            </HStack>
          ) : null,
        )
        .otherwise(() => null)}
    </Box>
  );
};

const INVALID_COLOR_ERROR = 'This color is invalid. Please provide a valid hexadecimal color.';
const validateHexColor = (color: string) => {
  const regexp = /^#([0-9a-f]{3}){1,2}$/i;

  return regexp.test(color[0] === '#' ? color : `#${color}`);
};

const DEFAULT_COLORS = [
  '#94D9FB',
  '#2E75D2',
  '#75FA81',
  '#68C9CA',
  '#071F3D',
  '#EC5142',
  '#DC3AB9',
  '#579673',
  '#04AFFF',
  '#8301F6',
  '#E5FF01',
  '#FFFFFF',
  '#111111',
  '#7A214A',
  '#5A01F9',
  '#FF015D',
];
