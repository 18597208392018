import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';
import { match } from 'ts-pattern';
import { VirtualElement } from '@popperjs/core/lib/popper-lite';

export type TooltipState<TooltipData> = { data: TooltipData | null; referenceElement: VirtualElement | Element | null };

export type TooltipAction<TooltipData> = { type: 'show'; payload: TooltipState<TooltipData> } | { type: 'hide' };

export function makeTooltipContext<TooltipData>() {
  const makeInitialTooltipState = (): TooltipState<TooltipData> => ({ data: null, referenceElement: null });

  const tooltipReducer = (
    _state: TooltipState<TooltipData>,
    action: TooltipAction<TooltipData>,
  ): TooltipState<TooltipData> => {
    return match(action)
      .with({ type: 'show' }, ({ payload }) => payload)
      .with({ type: 'hide' }, makeInitialTooltipState)
      .exhaustive();
  };

  const usableTooltipContext = createUsableContext<React.Dispatch<TooltipAction<TooltipData>>>({
    hookName: 'useTooltipEffect',
    providerName: 'TooltipEffectContext.Provider',
  });

  return {
    makeInitialTooltipState,
    tooltipReducer,
    usableTooltipContext,
  };
}
