import * as React from 'react';
import { useField } from 'formik';

import {
  FormControl,
  FormHelperText,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberDecrementStepper,
  NumberInputProps,
} from 'components/design/next';

export const NumberField: React.FC<React.PropsWithChildren<NumberInputProps>> = ({
  name = 'numberField',
  children,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  const { setValue } = helpers;
  const onChange = (str: string, num: number) => {
    if (Number.isNaN(num)) {
      setValue(str);
    } else {
      setValue(num);
    }
  };

  return (
    <FormControl width="25">
      <NumberInput
        id={field.name}
        type="text"
        inputMode="numeric"
        {...field}
        {...props}
        onChange={onChange}
        isInvalid={!!meta.error && meta.touched}
      >
        <NumberInputField name={field.name} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <FormHelperText>{children}</FormHelperText>
    </FormControl>
  );
};
