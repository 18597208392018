import {
  ChecklistRuleDefinition,
  ChecklistRuleDefinitionLogicalOperator,
  ChecklistRuleDefinitionOperandType,
  ChecklistRuleDefinitionOperator,
} from '@process-street/subgrade/conditional-logic';
import { Muid, MuidUtils, OrderTreeServiceImpl } from '@process-street/subgrade/core';
import { FieldArrayRenderProps, useField } from 'formik';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useConditionalLogicModalStore } from '../modal/store';
import { Widget } from '@process-street/subgrade/process';
import { ConditionalLogicUtils } from 'features/conditional-logic/utils/conditional-logic-utils';

type GenerateEmptyRuleParams = {
  atIndex: number;
  rules: ChecklistRuleDefinition[];
  organizationId?: Muid;
  selectedWidget?: Widget | null;
};

export const generateEmptyRule = ({ atIndex, rules, organizationId, selectedWidget }: GenerateEmptyRuleParams) => {
  const orderTreeService = OrderTreeServiceImpl.getInstance();
  const referenceTree = rules[atIndex] ? rules[atIndex].orderTree : null;
  const orderTrees = rules.map(rule => rule.orderTree);

  const formFieldWidgetGroupId = selectedWidget?.header.group.id;

  const rule = {
    id: MuidUtils.randomMuid(),
    orderTree: orderTreeService.after(orderTrees, referenceTree)[0],
    organizationId,
    formFieldWidgetGroupId,
    operator: ChecklistRuleDefinitionOperator.Is,
    operand: {
      operandType: ChecklistRuleDefinitionOperandType.String,
      value: '',
    },
    taskTemplateGroupIds: [],
    widgetGroupIds: [],
    hidden: false,
  };

  const [operator] = selectedWidget
    ? ConditionalLogicUtils.getAvailableOperatorListByWidget(selectedWidget)
    : [ChecklistRuleDefinitionOperator.Is];

  const condition = {
    operator,
    operandType: ChecklistRuleDefinitionOperandType.String,
    operandValue: { value: '' },
    formFieldWidgetGroupId: rule.formFieldWidgetGroupId,
  };

  return {
    ...rule,
    operator: ChecklistRuleDefinitionOperator.LogicalOr,
    operand: {
      operandType: ChecklistRuleDefinitionOperandType.Logical,
      data: {
        operator: ChecklistRuleDefinitionLogicalOperator.LogicalOr,
        conditions: [
          {
            operator: ChecklistRuleDefinitionLogicalOperator.LogicalAnd,
            conditions: [condition],
          },
        ],
      },
    },
  };
};

export type UseRuleOperationsReturn = {
  handleChange: (index: number, rule: ChecklistRuleDefinition) => void;
  handleDelete: (index: number) => void;
  handleDuplicate: (index: number) => void;
  handleAddRuleBelow: (index: number) => void;
  handleMoveUp: (index: number) => void;
  handleMoveDown: (index: number) => void;
  handleAddRuleAtTheBottom: () => void;
};

export const useRuleOperations = (arrayHelpers: FieldArrayRenderProps): UseRuleOperationsReturn => {
  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const { selectedWidget } = useConditionalLogicModalStore();
  const [{ value: rules }] = useField('rules');

  const duplicateRule = (atIndex: number, rules: ChecklistRuleDefinition[]) => {
    const orderTreeService = OrderTreeServiceImpl.getInstance();
    const sourceRule = rules[atIndex];
    if (!sourceRule) {
      return generateEmptyRule({
        atIndex,
        rules,
        organizationId: selectedOrganization?.id,
        selectedWidget,
      });
    }
    const referenceTree = sourceRule.orderTree;
    const orderTrees = rules.map(rule => rule.orderTree);

    return {
      ...sourceRule,
      id: MuidUtils.randomMuid(),
      orderTree: orderTreeService.after(orderTrees, referenceTree)[0],
    };
  };

  const handleDelete = (index: number) => {
    arrayHelpers.remove(index);
  };

  const handleChange = (index: number, rule: ChecklistRuleDefinition) => {
    arrayHelpers.handleReplace(index, rule)();
  };

  const handleDuplicate = (index: number) => {
    arrayHelpers.insert(index + 1, duplicateRule(index, rules));
  };

  const handleMoveUp = (index: number) => {
    const srcOrderTree = rules[index].orderTree;
    const destOrderTree = rules[index - 1].orderTree;

    arrayHelpers.form.setFieldValue(`rules.${index}.orderTree`, destOrderTree);
    arrayHelpers.form.setFieldValue(`rules.${index - 1}.orderTree`, srcOrderTree);

    arrayHelpers.swap(index, index - 1);
  };

  const handleMoveDown = (index: number) => {
    const srcOrderTree = rules[index].orderTree;
    const destOrderTree = rules[index + 1].orderTree;

    arrayHelpers.form.setFieldValue(`rules.${index}.orderTree`, destOrderTree);
    arrayHelpers.form.setFieldValue(`rules.${index + 1}.orderTree`, srcOrderTree);

    arrayHelpers.swap(index, index + 1);
  };

  const handleAddRuleBelow = (index: number) => {
    arrayHelpers.insert(
      index + 1,
      generateEmptyRule({
        atIndex: index,
        rules,
        organizationId: selectedOrganization?.id,
        selectedWidget,
      }),
    );
  };

  const handleAddRuleAtTheBottom = () => handleAddRuleBelow(rules.length - 1);

  return {
    handleDelete,
    handleChange,
    handleDuplicate,
    handleMoveUp,
    handleMoveDown,
    handleAddRuleBelow,
    handleAddRuleAtTheBottom,
  };
};
