import { PublicTemplate } from '@process-street/subgrade/process';
import { sprintf } from 'sprintf-js';

export function generateEmbed(template: PublicTemplate): string {
  return sprintf('<iframe src="%s" width="100%%" height="600" frameborder="0"></iframe>', template.publicUrl);
}

type SharingMedium = 'facebook' | 'twitter' | 'linkedIn' | 'email';
type ShareLink = Record<SharingMedium, string>;

export function generateShareLinks(template: PublicTemplate): ShareLink {
  return {
    facebook: generateFacebookShareLink(template.publicUrl, template.name),
    twitter: generateTwitterShareLink(template.publicUrl, template.name),
    linkedIn: generateLinkedInShareLink(template.publicUrl, template.name, template.description),
    email: generateEmailShareLink(template.publicUrl, template.name),
  };
}

function generateFacebookShareLink(url: string, title: string) {
  const format = 'https://www.facebook.com/sharer.php?u=%s&t=%s';
  return sprintf(format, url, encodeURIComponent(title));
}

function generateTwitterShareLink(url: string, title: string) {
  const format = 'https://twitter.com/intent/tweet?url=%s&text=%s';
  const text = `${title} via @ProcessStreet`;
  return sprintf(format, url, encodeURIComponent(text));
}

function generateLinkedInShareLink(url: string, title: string, description: string = '') {
  const format = 'https://www.linkedin.com/shareArticle?mini=true&url=%s&title=%s&summary=%s&source=%s';
  return sprintf(format, url, encodeURIComponent(title), encodeURIComponent(description), 'Process Street');
}

function generateEmailShareLink(url: string, title: string) {
  const format = 'mailto:?subject=%s&body=%s';
  const subject = encodeURIComponent(title);
  const body = encodeURIComponent(
    sprintf(
      'Check out this great workflow "%s" @ %s\n\n' +
        'This workflow was created with love using Process Street (http://www.process.st).',
      title,
      url,
    ),
  );
  return sprintf(format, subject, body);
}
