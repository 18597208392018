import * as React from 'react';
import {
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
} from 'components/design/next';
import { AutomationSelectorProps, AutomationSelectorProvider } from './components/selector/context';
import { IncomingWebhook, IncomingWebhookOptimistic, SolutionTypeTag } from '@process-street/subgrade/automation';
import { useDataSetId } from './utils/use-data-set-id';
import { DataSetAutomationsSelector } from './data-set-automations-selector';
import { DataSetAutomationDetails } from './data-set-automation-details';
import { GetAllDataSetsQuery } from 'pages/reports/data-sets/query-builder';
import { useSavedViewId } from './utils/use-saved-view-id';

type DataSetAutomationModalProps = UseDisclosureReturn & {
  webhook: IncomingWebhook | IncomingWebhookOptimistic;
};

export const DataSetAutomationModal: React.FC<React.PropsWithChildren<DataSetAutomationModalProps>> = ({
  webhook,
  ...modalDisclosure
}) => {
  const dataSetId = useDataSetId();
  const savedViewId = useSavedViewId();

  const dataSetQuery = GetAllDataSetsQuery.useQuery({
    select: dataSets => dataSets.find(dataSet => dataSet.id === dataSetId),
    enabled: Boolean(dataSetId),
  });

  const automationSelectorProviderProps: AutomationSelectorProps = savedViewId
    ? { automationType: 'native', solutionTypeTag: SolutionTypeTag.CreateChecklistWhen }
    : {
        automationType: 'webhook',
        solutionTypeTag: SolutionTypeTag.WhenChecklistCompletedThen,
        automationInstanceId: webhook.id,
      };

  return (
    <Modal {...modalDisclosure}>
      <ModalOverlay />
      <ModalContent maxW="5xl">
        <ModalHeader fontSize="lg" borderBottomWidth="thin" borderBottomStyle="solid" borderBottomColor="gray.100">
          <strong>Automations for</strong> {dataSetQuery.data?.name} <strong>Data Set</strong>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pl="0" pt="0">
          <AutomationSelectorProvider {...automationSelectorProviderProps}>
            <Grid
              templateColumns={{ base: '1fr', lg: '400px 1fr' }}
              sx={{
                '& .blvd-select .blvd-select__control': {
                  cursor: 'pointer',
                },
                '& .blvd-select .blvd-select__control .blvd-select__placeholder': {
                  color: 'gray.400',
                },
                '& .blvd-select .blvd-select__menu': {
                  maxW: '400px',
                },
              }}
            >
              <DataSetAutomationsSelector webhook={webhook} />
              <DataSetAutomationDetails closeModal={modalDisclosure.onClose} webhook={webhook} />
            </Grid>
          </AutomationSelectorProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
