import { Muid } from '@process-street/subgrade/core';
import { Checklist, ChecklistStatus, TemplateStatus } from '@process-street/subgrade/process';
import { useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetChecklistsByOrganizationIdParams = {
  templateStatus: TemplateStatus;
  checklistStatus: ChecklistStatus;
  organizationId: Muid;
};

export type GetChecklistsByOrganizationIdResult = Checklist[];

export const GetChecklistsByOrganizationId = {
  getKey: (params: GetChecklistsByOrganizationIdParams) => [
    'checklists',
    params.checklistStatus,
    params.templateStatus,
    params.organizationId,
  ],
  queryFn: ({ organizationId, ...params }: GetChecklistsByOrganizationIdParams) =>
    axiosService
      .getAxios()
      .get<GetChecklistsByOrganizationIdResult>(`/1/organizations/${organizationId}/checklists`, { params })
      .then(res => res.data),
};

export const useGetChecklistsByOrganizationIdQuery = (
  params: GetChecklistsByOrganizationIdParams,
  options: UseQueryOptions<GetChecklistsByOrganizationIdResult, Error> = {},
) => {
  return useQuery<GetChecklistsByOrganizationIdResult, Error>(
    GetChecklistsByOrganizationId.getKey(params),
    () => GetChecklistsByOrganizationId.queryFn(params),
    options,
  );
};
