import { FolderPermissionMap, Muid, TemplatePermissionMap } from '@process-street/subgrade/core';
import { Folder, Template, TemplateType } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';

export const canMoveTemplate = (template: Template, permissionMap: TemplatePermissionMap) =>
  match({ template, permissionMap })
    .with(
      {
        template: { templateType: P.union(TemplateType.Playbook, TemplateType.Form) },
        permissionMap: { templateUpdate: true },
      },
      () => true,
    )
    .with(
      {
        template: { templateType: TemplateType.Page },
        permissionMap: { pageUpdate: true },
      },
      () => true,
    )
    .otherwise(() => false);

export const canMoveFolder = (folder: Folder, permissionMap: FolderPermissionMap, writeableFolderIdSet: Set<Muid>) =>
  match({ permissionMap, folder, isWriteable: writeableFolderIdSet.has(folder.id) })
    .with(
      {
        permissionMap: { folderUpdate: true },
        isWriteable: true,
      },
      () => true,
    )
    .otherwise(() => false);
