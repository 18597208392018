import { EmailWidget, TemplateRevision } from '@process-street/subgrade/process';
import { Edit } from './edit';

export type EmailTemplateWidgetProps = {
  widget: EmailWidget;
  isDisabled: boolean;
  onUpdate: (widget: EmailWidget) => void;
  templateRevision: TemplateRevision;
};

// Just Edit mode for now, View is handled by angular
export const EmailTemplateWidget = Edit;
