import { queryClient } from 'components/react-root';
import { CompleteLoginMutation } from 'features/user/query-builder';

export class UserApi {
  constructor(Users, $q) {
    'ngInject';

    this.Users = Users;
    this.$q = $q;
  }

  getAllOrganizationMemberships = ({ userId }) => this.Users.getOrganizations({ id: userId }).$promise;

  getById = userId => this.Users.getById({ id: userId }).$promise;

  getAllEmailsById = userId => this.Users.getAllEmailsById({ id: userId }).$promise;

  getSettingsById = userId => this.Users.getSettings({ id: userId }).$promise;

  createUserEmail = (userId, email, password) => this.Users.createUserEmail({ id: userId, email, password }).$promise;

  updateSettings = (userId, key, value) =>
    this.Users.updateSettings({
      id: userId,
      key,
      value,
    }).$promise;

  getByToken = () => this.Users.getByToken().$promise;

  completeLogin = () =>
    this.$q((resolve, reject) =>
      queryClient
        .executeMutation({
          mutationFn: CompleteLoginMutation.mutationFn,
          mutationKey: CompleteLoginMutation.key,
        })
        .then(resolve)
        .catch(reject),
    );

  update = (userId, info) =>
    this.Users.update({
      id: userId,
      username: info.username,
      whatIDo: info.whatIDo,
      telephoneNumber: info.telephoneNumber,
      timeZone: info.timeZone,
      beta: info.beta,
    }).$promise;

  updateUsingToken = token =>
    this.Users.updateUsingToken({
      token,
    }).$promise;

  sendEmailVerification = (userId, email) =>
    this.Users.sendEmailVerification({
      id: userId,
      email,
    }).$promise;

  requestPasswordReset = email =>
    this.Users.requestPasswordReset({
      email,
    }).$promise;

  resetPassword = (resetId, newPassword, confirmNewPassword) =>
    this.Users.resetPassword({
      passwordResetId: resetId,
      newPassword,
      confirmNewPassword,
    }).$promise;

  validateEmailVerification = codeId => this.Users.validateEmailVerification({ codeId }).$promise;

  deleteUserEmail = (userId, email) => this.Users.deleteUserEmail({ id: userId, email }).$promise;

  setPrimaryEmail = (userId, email) => this.Users.setPrimaryEmail({ id: userId, email }).$promise;

  getUserLinkableDomains = organizationId => this.Users.getUserLinkableDomains({ organizationId }).$promise;
}
