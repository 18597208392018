import { Muid, MuidUtils, orderTreeService } from '@process-street/subgrade/core';
import { WidgetType, WidgetUpdateOrderTreesRequest } from '@process-street/subgrade/process';
import { PSEditor } from 'pages/pages/_id/edit/page/utils/ps-editor';
import { noop } from 'pages/pages/_id/edit/page/utils/widget.api';
import { Path } from 'slate';
import { ELEMENT_PS_FILE } from 'pages/pages/_id/edit/page/plugins/ps-file';
import { ELEMENT_PS_IMAGE } from 'pages/pages/_id/edit/page/plugins/ps-image';
import * as Mutations from 'features/widgets/query-builder';
import { pageEventsSubject } from './persistence-event-observables';
import { PersistentEditor } from './persistent-editor';
import { Trace } from 'components/trace';
import { ELEMENT_PS_TABLE, ELEMENT_PS_VIDEO } from '..';
import { ELEMENT_PS_EMBED } from '../ps-embed';
import { ELEMENT_PS_CROSS_LINK } from '../ps-cross-link';
import {
  OptimisticWidget,
  PagesInsertNodeOperation,
  PagesInsertTextOperation,
  PagesMergeNodeOperation,
  PagesMoveNodeOperation,
  PagesNode,
  PagesOperation,
  PagesRemoveNodeOperation,
  PagesRemoveTextOperation,
  PagesSetNodeOperation,
  PagesSplitNodeOperation,
  PagesWidgetElement,
  PagesWithOverride,
} from '../../pages-plate-types';
import { getNode, isElement, setNodes } from '@udecode/slate';

interface Props {
  create: ReturnType<typeof Mutations.useCreateWidgetMutation>['mutateAsync'];
  copy: ReturnType<typeof Mutations.useCopyWidgetMutation>['mutateAsync'];
  deleteWidget: ReturnType<typeof Mutations.useDeleteWidgetMutation>['mutateAsync'];
  update: ReturnType<typeof Mutations.useUpdateWidgetMutation>['mutateAsync'];
  updateOrderTrees: ReturnType<typeof Mutations.useUpdateWidgetOrderTreesMutation>['mutateAsync'];
  undelete: ReturnType<typeof Mutations.useUndeleteWidgetMutation>['mutateAsync'];
}

/**
 * Slate Plugin which converts Slate operations to PageEvents which are then
 * sent to the page event subject for processing and persisting.
 */
export const withOverrides: (props: Props, logger: Trace) => PagesWithOverride = (mutations, logger) => editor => {
  const { apply } = editor;

  const pageEvents = pageEventsSubject(mutations, editor, logger);

  const persistentEditor = Object.assign(editor, {
    apply: (op: PagesOperation) => {
      switch (op.type) {
        case 'insert_text':
          handleInsertText(op);
          break;
        case 'remove_text':
          handleRemoveText(op);
          break;
        case 'insert_node':
          handleInsertNode(op);
          break;
        case 'remove_node':
          handleRemoveNode(op);
          break;
        case 'split_node':
          handleSplitNode(op);
          break;
        case 'merge_node':
          handleMergeNode(op);
          break;
        case 'set_node':
          handleSetNode(op);
          break;
        case 'move_node':
          handleMoveNode(op);
          break;
        default:
          apply(op);
          break;
      }
    },
  });

  const handleInsertText = (op: PagesInsertTextOperation) => {
    // Allow slate to update the node first so we can serialize the result
    apply(op);

    updateSelectedWidget(op.path);
  };

  const handleRemoveText = (op: PagesRemoveTextOperation) => {
    // Allow slate to update the node first so we can serialize the result
    apply(op);

    updateSelectedWidget(op.path);
  };

  const handleInsertNode = (op: PagesInsertNodeOperation) => {
    if (Path.parent(op.path).length > 0) {
      // Nested change.  Update the parent widget.
      apply(op);
      updateSelectedWidget(op.path);
    } else if (PSEditor.isWidgetElement(op.node) && PSEditor.isHistoryUndeleteOperation(editor, op.node.widget)) {
      // Root widget element undeleted.
      undeleteWidget(op);
    } else if (PSEditor.isWidgetElement(op.node) && PSEditor.isFullWidgetElement(op.node)) {
      // Root widget element copy.
      copyWidget(op);
    } else if (
      isElement(op.node) &&
      (PSEditor.isMediaElementType(op.node.type) ||
        PSEditor.isCrossLinkWidgetType(op.node.type) ||
        PSEditor.isTableWidgetType(op.node.type))
    ) {
      // Root widget element create.
      insertWidget(op);
    } else {
      // Root widget text element create.
      insertTextWidget(op);
    }
  };

  const undeleteWidget = (op: PagesInsertNodeOperation) => {
    if (PSEditor.isWidgetElement(op.node)) {
      apply(op);

      pageEvents.next({
        type: 'undelete',
        element: op.node,
      });
    }
  };

  const copyWidget = async (op: PagesInsertNodeOperation) => {
    if (PSEditor.isWidgetElement(op.node)) {
      const generatedWidgetElement = generateWidgetElement(op, op.node.widget.header.type);

      const widgetElement = {
        ...generatedWidgetElement,
        widget: {
          ...op.node.widget,
          id: MuidUtils.randomMuid(),
          header: {
            ...generatedWidgetElement.widget.header,
          },
        },
      };

      apply({
        ...op,
        node: widgetElement,
      });

      pageEvents.next({
        type: 'copy',
        srcElement: op.node,
        element: widgetElement,
      });
    }
  };

  const insertWidget = (op: PagesInsertNodeOperation) => {
    if (isElement(op.node)) {
      const widgetType = convertElementTypeToWidgetType(op.node.type);
      const optimisticWidgetElement = generateWidgetElement(op, widgetType);

      apply({
        ...op,
        node: optimisticWidgetElement,
      });

      pageEvents.next({
        type: 'create',
        element: optimisticWidgetElement,
      });
    }
  };

  const convertElementTypeToWidgetType = (type: string) => {
    switch (type) {
      case ELEMENT_PS_FILE:
        return WidgetType.File;
      case ELEMENT_PS_IMAGE:
        return WidgetType.Image;
      case ELEMENT_PS_VIDEO:
        return WidgetType.Video;
      case ELEMENT_PS_EMBED:
        return WidgetType.Embed;
      case ELEMENT_PS_CROSS_LINK:
        return WidgetType.CrossLink;
      case ELEMENT_PS_TABLE:
        return WidgetType.Table;
      default:
        throw new Error(`Element type [${type}] does not support s3 file.`);
    }
  };

  const insertTextWidget = (op: PagesInsertNodeOperation) => {
    if (isElement(op.node)) {
      const optimisticWidgetElement = generateWidgetElement(op, WidgetType.Text);

      apply({
        ...op,
        node: optimisticWidgetElement,
      });

      pageEvents.next({
        type: 'create',
        element: optimisticWidgetElement,
      });
    }
  };

  const handleRemoveNode = (op: PagesRemoveNodeOperation) => {
    if (PSEditor.isWidgetElement(op.node)) {
      // Never delete the last widget element as inserts depend on one widget existing.
      if (editor.children.length !== 1) {
        apply(op);
        pageEvents.next({
          type: 'delete',
          element: op.node,
        });
      }
    } else {
      apply(op);
      updateSelectedWidget(op.path);
    }
  };

  const handleSplitNode = (op: PagesSplitNodeOperation) => {
    const widgetElementBeingSplit = getNode(editor, op.path);
    if (PSEditor.isWidgetElement(widgetElementBeingSplit)) {
      const widgetBeingSplit = widgetElementBeingSplit.widget;
      const optimisticWidgetElement = generateWidgetElement(op, WidgetType.Text);

      apply({
        ...op,
        properties: optimisticWidgetElement,
      });

      const newWidgetElement = getNode(editor, Path.next(op.path));
      if (PSEditor.isWidgetElement(newWidgetElement)) {
        pageEvents.next({
          type: 'create',
          element: newWidgetElement,
        });
      }

      // We also update the current widget as it's content may have changed
      updateTextWidgetByHeaderId(widgetBeingSplit.header.id);
    } else {
      apply(op);
    }
  };

  const handleMergeNode = (op: PagesMergeNodeOperation) => {
    const node = getNode(editor, op.path);
    apply(op);

    if (PSEditor.isWidgetElement(node)) {
      pageEvents.next({
        type: 'delete',
        element: node,
      });
      updateSelectedWidget(Path.previous(op.path));
    }
  };

  const handleSetNode = (op: PagesSetNodeOperation) => {
    apply(op);

    if (PersistentEditor.isPersisting(editor)) {
      updateSelectedWidget(op.path);
    }
  };

  const handleMoveNode = (op: PagesMoveNodeOperation) => {
    // assign the relevant widgets by index before apply, since it mutates editor.children
    const elementsBefore = editor.children;
    const widgetsBefore = elementsBefore.map(el => el.widget);
    const orderTrees = widgetsBefore.map(widget => widget.header.orderTree ?? '');

    // update the editor before the request
    apply(op);

    // only execute order tree operations for root widget elements
    if (!PSEditor.isRootPath(op.path)) {
      noop();
      const widgetElement = getNode(editor, PSEditor.rootPath(op.newPath));
      // Some `move_node` operations happen to children of a text widget (e.g., inline links).
      // and table row/columns
      if (PSEditor.isTextWidgetElement(widgetElement) || PSEditor.isTableWidgetElement(widgetElement)) {
        pageEvents.next({ type: 'update', element: widgetElement });
      }
      return;
    }

    const {
      path: [index],
      newPath: [newIndex],
    } = op;

    const movedElement = elementsBefore[index];
    const movedWidget = widgetsBefore[index];
    const displacedWidget = widgetsBefore[newIndex];

    const newOrderTree = orderTreeService[newIndex < index ? 'before' : 'after'](
      orderTrees,
      displacedWidget?.header.orderTree ?? '',
    );

    const orderModels: WidgetUpdateOrderTreesRequest['orderModels'] = [
      { orderTree: newOrderTree[0], widgetHeaderId: movedWidget?.header.id },
    ];

    PersistentEditor.withoutPersisting(editor, () => {
      setNodes(
        editor,
        {
          widget: { ...movedWidget, header: { ...movedWidget.header, orderTree: newOrderTree[0] } },
        },
        { at: [newIndex] },
      );
    });

    pageEvents.next({
      type: 'move',
      element: movedElement,
      orderModels,
    });
  };

  const generateWidgetElement = (op: PagesInsertNodeOperation | PagesSplitNodeOperation, widgetType: WidgetType) => {
    const previousWidgetElement = getPreviousWidgetElement(op);
    const previousWidget = previousWidgetElement.widget;
    const previousOrderTree = getOrderTree(previousWidgetElement);

    const orderTrees = editor.children.map(getOrderTree);
    const [nextOrderTree] = orderTreeService.after(orderTrees, previousOrderTree);

    const newWidgetHeaderId = MuidUtils.randomMuid();
    const taskTemplateId = previousWidget.header.taskTemplate.id;

    const nodeProperties = op.type === 'insert_node' ? op.node : { type: 'p' };

    return {
      ...nodeProperties,
      widget: {
        header: {
          id: newWidgetHeaderId,
          orderTree: nextOrderTree,
          taskTemplate: {
            id: taskTemplateId,
          },
          type: widgetType,
        },
      },
    } as PagesWidgetElement<OptimisticWidget>;
  };

  const updateSelectedWidget = (path: Path) => {
    // Get the top level widget
    const widgetElement = getNode(editor, PSEditor.rootPath(path));

    if (PSEditor.isTextWidgetElement(widgetElement) || PSEditor.isTableWidgetElement(widgetElement)) {
      pageEvents.next({
        type: 'update',
        element: widgetElement,
      });

      return;
    }

    console.error('Updating this widget is not supported', widgetElement);
  };

  const updateTextWidgetByHeaderId = (widgetHeaderId: Muid) => {
    const [widgetElement] = PSEditor.getWidgetNodeEntryByHeaderId(editor, widgetHeaderId) ?? [];

    if (PSEditor.isTextWidgetElement(widgetElement)) {
      pageEvents.next({
        type: 'update',
        element: widgetElement,
      });
    }
  };

  const getOrderTree = (element: PagesNode) => {
    if (PSEditor.isWidgetElement(element)) {
      return element.widget.header.orderTree ? element.widget.header.orderTree : '';
    } else {
      throw Error('A widget element is required to retrieve the order tree.');
    }
  };

  const getPreviousWidgetElement = (op: PagesInsertNodeOperation | PagesSplitNodeOperation): PagesWidgetElement => {
    const element = (() => {
      if (op.type === 'insert_node') {
        // InsertNodeOperation
        return getNode(editor, Path.previous(op.path));
      } else {
        // SplitNodeOperation
        return getNode(editor, op.path);
      }
    })();

    if (PSEditor.isWidgetElement(element)) {
      return element;
    } else {
      throw Error('Creating a new widget element failed. Previous element is not a widget element.');
    }
  };

  return persistentEditor;
};
