import angular from 'angular';
import './loader.scss';
import { trace } from 'components/trace';

// Used for authenticating, then redirecting to a secure S3 file.
class FileRedirectCtrl {
  constructor($location, $scope, $state, $timeout) {
    'ngInject';

    this.$location = $location;
    this.$scope = $scope;
    this.$state = $state;
    this.$timeout = $timeout;
    const logger = trace({ name: 'FileRedirectCtrl' });
    this.logger = logger;

    this.redirectToFile();
  }

  redirectToFile = () => {
    this.$timeout(() => {
      const { url } = this.$state.params;

      this.logger.info('redirecting to "%s"', url);

      try {
        const urlParsed = new URL(url);
        // if it can be parsed, it's a full URL
        // $location doesn't support setting the hostname
        window.location.assign(urlParsed);
      } catch (e) {
        if (e instanceof TypeError) {
          this.logger.warn(`Invalid URL parameter [${url}], redirecting to loader.`);
          // if it's not a valid full URL, then it's not valid for this ctrl, go to loader
          this.$location.url('/loader');
          this.$location.replace();
        } else {
          throw e;
        }
      }
    });
  };
}

angular.module('frontStreetApp.controllers').controller('FileRedirectCtrl', FileRedirectCtrl);
