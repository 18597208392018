export const EditorTitle = {
  baseStyle: {
    fontSize: '9',
    fontWeight: 700,
    lineHeight: 'base',
    color: 'gray.700',
  },
};

export const EditorHeading = {
  baseStyle: {
    fontWeight: 700,
    color: 'gray.700',
  },
  variants: {
    '1': {
      fontSize: 7,
      lineHeight: 'short',
      marginTop: '6',
      marginBottom: '2',
    },
    '2': {
      fontSize: '6',
      lineHeight: 'base',
      marginTop: '4',
      marginBottom: '1',
    },
    '3': {
      fontSize: '5',
      lineHeight: 'base',
      marginTop: '2',
    },
  },
};

export const EditorParagraph = {
  baseStyle: {
    'marginBottom': '4',
    'fontSize': '18px',
    'lineHeight': '1.8',
    'fontWeight': 400,
    'color': 'gray.600',
    /** Disable margin in tables, controlled by table padding */
    'table &': {
      m: 0,
    },
    '.chakra-link': {
      'color': 'blue.500',

      '&:hover': {
        color: 'blue.600',
      },

      '&:focus': {
        color: 'blue.700',
      },
    },
  },
  variants: {
    widget: {
      fontSize: '16px',
      lineHeight: '1.6',
    },
  },
};

export const EditorVoid = {
  baseStyle: {
    marginTop: 2,
    marginBottom: 6,
    color: 'gray.500',
    fontSize: 'sm',
    lineHeight: 'base',
  },
};

export const EditorListItem = {
  baseStyle: {
    marginTop: 0,
    color: 'gray.600',
    fontSize: '18px',
    lineHeight: '1.8',
    marginBottom: 2,
  },
};
