import { Muid } from '@process-street/subgrade/core';
import { axiosService } from 'services/axios-service';

export type DeleteFormFieldValueMutationParams = {
  checklistRevisionId: Muid;
  widgetId: Muid;
};

export type DeleteFormFieldValueMutationResponse = void;

export const DeleteFormFieldValueMutation = {
  key: ['form-field-value', 'delete'],
  mutationFn: (params: DeleteFormFieldValueMutationParams) =>
    axiosService
      .getAxios()
      .delete<DeleteFormFieldValueMutationResponse>(
        `/1/checklist-revisions/${params.checklistRevisionId}/widgets/${params.widgetId}/form-field-value`,
      )
      .then(r => r.data),
};
