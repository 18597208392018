// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../images/back-soon.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"container simple-page-container unavailable\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> <h3>We're making some improvements.</h3> <div class=\"message\">Process Street is updating and should be back in a jiffy.</div> <div class=\"message-btn-container\"> <button type=\"button\" data-ng-click=\"ping()\" data-ng-disabled=\"pinging\" class=\"btn btn-default\"> <span data-ng-show=\"pinging\"> <ps-spinner data-spinner-class=\"'spinner-beside-text spinner-white'\" data-spinner-text=\"'Checking&hellip;'\"></ps-spinner> </span> <span data-ng-hide=\"pinging\">Are you back, Process Street?</span> </button> </div> Been a while? Check our <a href=\"http://status.process.st/\">status page</a> to see what's up. </div> ";
// Exports
var _module_exports = code;;
var path = '/features/unavailable/unavailable.component.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;