import * as React from 'react';
import { useSearchStatus } from 'features/global-search/components/search-context';
import { Muid } from '@process-street/subgrade/core';
import { useListboxContext } from 'components/listbox';

export const useDoClickIfSelected = <T extends HTMLElement>({ id, node }: { id: Muid; node: T | null }) => {
  const { selectedId, setSelectedId } = useListboxContext();
  const { status, reset } = useSearchStatus();
  const selected = selectedId === id;
  React.useEffect(() => {
    if (status === 'selected' && selected) {
      node?.click();
      reset();
      setSelectedId('');
    }
  }, [status, selected, reset, setSelectedId, node]);
};
