import * as React from 'react';
import { Box, Divider, Flex, HStack, Text, UnorderedList, VStack } from 'components/design/next';
import { MenuItemSkeleton } from 'pages/reports/data-sets/components/saved-views-sidebar/menu-item-skeleton';
import { DataSetSidebarMenuItem } from './data-set-sidebar-menu-item';
import { CreateDataSetButton } from 'pages/reports/data-sets/components/create-data-set-button';
import { DataSet } from '@process-street/subgrade/process';
import { useSelectedSavedView } from 'pages/reports/data-sets/components/data-set-page/selected-saved-view-id-store';
import { useFeatureFlag } from 'app/features/feature-flags';
import { DataSetQuickFilter } from '../data-set-quick-filter';
import { CreateActions } from 'app/pages/library/left-nav/create-actions';
import { CreateTemplateSource } from 'app/services/template-service.interface';
import { useSyncSelectedSavedView } from 'pages/reports/data-sets/components/data-set-page/use-sync-selected-saved-view';

export const DataSetSidebar = React.memo(function DataSetSidebar() {
  const { dataSetPageStore, dataSets, selectedDataSet, isFetching } = useSelectedSavedView();
  const isDataSetV2Enabled = useFeatureFlag('dataSetsV2');
  const [quickFilter, setQuickFilter] = React.useState('');

  const { selectDefaultSavedView } = useSyncSelectedSavedView();

  const handleDataSetSelection = (dataSet: DataSet) => {
    dataSetPageStore.setDataSetId(dataSet.id);
    selectDefaultSavedView(dataSet.savedViews);
  };

  return (
    <VStack w="full" h="full" spacing={4} as="aside" bgColor="gray.50" aria-busy={isFetching}>
      {/* Align '+' button with cog icons */}
      <HStack w="full" px="4" pt="6" justifyContent="space-between">
        <Text fontWeight="700" variant="-1u" color="gray.500">
          Data Sets
        </Text>

        {!isDataSetV2Enabled && <CreateDataSetButton left={1} mode="icon" onCreateSuccess={handleDataSetSelection} />}
      </HStack>

      <Box w="full" px={4}>
        {isDataSetV2Enabled && <DataSetQuickFilter inputGroupProps={{ w: 'full' }} onChange={setQuickFilter} />}
      </Box>

      <Box w="full" h="full" px="4" overflowY="auto">
        <VStack as={UnorderedList} alignItems="stretch" w="full" listStyleType="none" ml="0" gap={1}>
          {(!isFetching || dataSets.length > 0) &&
            dataSets
              .filter(dataSet => dataSet.name.toLowerCase().includes(quickFilter.toLowerCase()))
              .map(dataSet => (
                <DataSetSidebarMenuItem
                  dataSet={dataSet}
                  onSelect={handleDataSetSelection}
                  isSelected={dataSet.id === selectedDataSet?.id}
                  key={dataSet.id}
                />
              ))}

          {(dataSets.length === 0 || (isFetching && dataSets.length === 0)) && (
            <Flex justifyContent="center" position="relative">
              <VStack opacity="0.7" spacing={2} w="full">
                <MenuItemSkeleton isLoaded={!isFetching} />
                <MenuItemSkeleton isLoaded={!isFetching} />
                <MenuItemSkeleton isLoaded={!isFetching} />
                <MenuItemSkeleton isLoaded={!isFetching} />
              </VStack>
            </Flex>
          )}
        </VStack>
      </Box>

      {isDataSetV2Enabled && (
        <VStack spacing={3} w="full">
          <Divider />
          <CreateActions source={CreateTemplateSource.DATA_SETS_SIDEBAR} />
        </VStack>
      )}
    </VStack>
  );
});
