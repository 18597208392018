import { BlvdSelect, BlvdSelectHelpers } from 'components/design/BlvdSelect';
import * as React from 'react';
import {
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from 'components/design/next';
import { Period } from '@process-street/subgrade/core';
import { SimpleOption } from 'components/design/BlvdSelect/types';
import { match, P } from 'ts-pattern';
import { OnChangeValue } from 'react-select';

export type OperandValuePeriodProps = {
  value: Partial<Period>;
  onChange: (value: Partial<Period>) => void;
};

type ExtendedOption = SimpleOption & { maxValue: number };

const minutesOption = { value: 'minutes', label: 'minutes ago', maxValue: 59 };
const hoursOption = { value: 'hours', label: 'hours ago', maxValue: 23 };
const daysOption = { value: 'days', label: 'days ago', maxValue: 31 };
const weeksOption = { value: 'weeks', label: 'weeks ago', maxValue: 4 };
const monthsOption = { value: 'months', label: 'months ago', maxValue: 12 };

const options: ExtendedOption[] = [minutesOption, hoursOption, daysOption, weeksOption, monthsOption];

export const OperandValuePeriod: React.FC<React.PropsWithChildren<OperandValuePeriodProps>> = ({ value, onChange }) => {
  const [number, setNumber] = React.useState(
    () =>
      match(value)
        .with({ minutes: P.when(v => v > 0) }, () => value.minutes)
        .with({ hours: P.when(v => v > 0) }, () => value.hours)
        .with({ days: P.when(v => v > 0) }, () => value.days)
        .with({ weeks: P.when(v => v > 0) }, () => value.weeks)
        .with({ months: P.when(v => v > 0) }, () => value.months)
        .otherwise(() => 1) ?? 1,
  );

  const [option, setOption] = React.useState<ExtendedOption>(() =>
    match(value)
      .with({ minutes: P.when(v => v > 0) }, () => minutesOption)
      .with({ hours: P.when(v => v > 0) }, () => hoursOption)
      .with({ days: P.when(v => v > 0) }, () => daysOption)
      .with({ weeks: P.when(v => v > 0) }, () => weeksOption)
      .with({ months: P.when(v => v > 0) }, () => monthsOption)
      .otherwise(() => daysOption),
  );

  const handleChange = (number: number, option: ExtendedOption) => {
    const value = {
      [option.value]: number,
    };
    onChange(value);
  };

  const handlePeriodChange = (option: OnChangeValue<ExtendedOption, false>) => {
    if (BlvdSelectHelpers.isOptionType<ExtendedOption>(option)) {
      setOption(option);
      handleChange(number, option);
    }
  };

  const handleNumberChange = (number: number) => {
    setNumber(number);
    handleChange(number, option);
  };

  return (
    <HStack>
      <NumberInput
        defaultValue={number}
        min={1}
        max={option.maxValue}
        onChange={(_, valueAsNumber) => handleNumberChange(valueAsNumber)}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>

      <BlvdSelect placeholder="Choose:" options={options} value={option} onChange={handlePeriodChange} />
    </HStack>
  );
};
