import angular from 'angular';
import { connectService } from 'reducers/util';
import { htmlEscaped } from '@process-street/subgrade/util';
import {
  getAncestorsById,
  toSlugPath,
  getNamePath,
  isOrganizationRootFolder,
  isPrivateRootFolder,
  isPrivateFolder,
  getAncestorFolders,
} from 'features/folder/lib';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.services')
  .service(
    'FolderService',
    function ($ngRedux, $rootScope, $q, DataService, FolderActions, MessageBox, OrganizationService) {
      const self = this;

      Object.assign(self, { isOrganizationRootFolder, isPrivateRootFolder, getNamePath });

      connectService('FolderService', $ngRedux, null, FolderActions)(self);

      const logger = trace({ name: 'FolderService' });

      // DAO Access
      self.getById = id => self.actions.getById(id).then(({ payload }) => payload);

      self.getAllByOrganizationId = organizationId =>
        self.actions.getAllByOrganizationId(organizationId).then(({ payload }) => payload);

      self.getAllByOrganizationIdAndPermission = (organizationId, permission) =>
        self.actions.getAllByOrganizationId(organizationId, permission).then(({ payload }) => payload);

      // The Rest
      self.getAllByCurrentOrganization = function () {
        return OrganizationService.getSelectedOrganization().then(organization =>
          self.getAllByOrganizationIdAndPermission(organization.id, 'folder_read'),
        );
      };

      /**
       * Get the ancestor folders of this folder, if any exist.
       *
       * @param folder
       * @returns {Array} The folders in the order they are found going up the tree.
       */
      self.getAncestorFolders = function (folder) {
        if (folder.parentFolder && folder.parentFolder.id) {
          const folderCollection = DataService.getCollection('folders');
          return getAncestorFolders(folder, folderCollection.getAll());
        }

        return [];
      };

      /**
       * Get the folder and its ancestors, by id.
       *
       * @param folderId
       * @returns {Array} The folders in the order they are found going up the tree.
       */
      self.getAndAncestorsById = function (folderId) {
        return self.getAllByCurrentOrganization().then(folders => {
          return getAncestorsById({ folderId, folders });
        });
      };

      /**
       * Gets a parent folder of given folder
       * Returns null if given folder is home folder
       *
       * @param folder
       * @returns Folder|null
       */
      self.getParentFolder = function (folder) {
        let parentFolder = null;

        if (folder.parentFolder && folder.parentFolder.id) {
          parentFolder = DataService.getCollection('folders').get(folder.parentFolder.id);
        }

        return parentFolder;
      };

      self.getPathFoldersHead = function (path) {
        const folderCollection = DataService.getCollection('folders');
        const segments = path.split('/');

        return folderCollection.find({ slug: segments[segments.length - 1] });
      };

      self.toSlugPath = folder => toSlugPath(folder, self.getAncestorFolders(folder));

      self.toSlugPathFromList = function (folder, folders) {
        return [folder]
          .concat(getAncestorFolders(folder, folders))
          .reverse()
          .map(fl => fl.slug)
          .join('/');
      };

      self.toSlugPathAsync = function (folder) {
        return self.getAndAncestorsById(folder.id).then(folders =>
          folders
            .reverse()
            .map(fl => fl.slug)
            .join('/'),
        );
      };

      self.update = function (folder, changes) {
        return self.actions.update(folder.id, changes.name).then(
          ({ payload: response }) => {
            logger.info('succeeded to update folder');
            folder.name = changes.name;
            $rootScope.$broadcast(EventName.FOLDER_UPDATED, folder, response);
            return response;
          },
          response => {
            logger.error('failed to update folder. Reason: %s', JSON.stringify(response));
            $rootScope.$broadcast(EventName.FOLDER_UPDATE_FAILED, folder, response);
            return $q.reject(response);
          },
        );
      };

      /**
       * Show a message box asking the user to confirm the folder deletion.
       *
       * @param folder
       * @param callback
       */
      self.confirmDelete = function (folder, callback) {
        const message = htmlEscaped`Are you sure you want to delete the folder <b>${folder.name}</b>?`;

        MessageBox.confirm({
          title: 'Delete this folder?',
          message,
          okButton: {
            type: 'danger',
            text: 'Delete',
            action: callback.bind(null, folder),
          },
        });
      };

      /**
       * Deletes a folder and updates the data cache.
       *
       * @param folder
       */
      self.deleteFolder = function (folder) {
        return self.actions.deleteById(folder.id).then(
          ({ payload: response }) => {
            logger.info('succeeded to delete folder');

            $rootScope.$broadcast(EventName.FOLDER_DELETED, folder, response);

            return response;
          },
          response => {
            logger.error('failed to delete folder. Reason: %s', JSON.stringify(response));

            $rootScope.$broadcast(EventName.FOLDER_DELETE_FAILED, folder, response);

            return $q.reject(response);
          },
        );
      };

      /**
       * Gets home folder instance
       *
       * @returns {*}
       */
      self.getOrganizationRootFolder = () =>
        DataService.getCollection('folders').find({ 'parentFolder.id': undefined });

      /**
       * Returns the current folder the user is in
       *
       * @param path
       * @param pathType
       * @returns {*}
       */
      self.getCurrentFolder = function (path, pathType) {
        const folder = pathType === 'folder' && self.getPathFoldersHead(path);
        return folder || self.getOrganizationRootFolder();
      };

      self.isPrivateFolder = function (folder) {
        return isPrivateFolder(folder, DataService.getCollection('folders'));
      };
    },
  );
