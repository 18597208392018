import { FormFieldWidget, isFormFieldWidget } from '@process-street/subgrade/process';
import {
  WidgetsByTemplateRevisionIdQuery,
  WidgetsByTemplateRevisionIdQueryResponse,
} from 'app/features/widgets/query-builder';
import { FormFieldDefaultKey } from 'app/services/form-field-service-constants';
import { QueryClient } from 'react-query';
import { ActorRefFrom, assign, createMachine, sendParent, StateFrom } from 'xstate';
import { FormFieldHelpers } from '../../../../helpers/form-field';

type Context<TFormFieldWidget extends FormFieldWidget> = {
  widget: TFormFieldWidget;
  initialWidget: TFormFieldWidget;
  queryClient: QueryClient;
};

type Event<TFormFieldWidget> =
  | { type: 'CHANGE'; value: string }
  | { type: 'BLUR' }
  | { type: 'KEY_PRESS'; event: React.KeyboardEvent<HTMLInputElement> }
  | { type: 'TOGGLE_EDIT' }
  | { type: 'AUTO_FOCUS' }
  | { type: 'UPDATE_WIDGET'; widget: TFormFieldWidget }
  | { type: 'UPDATE_DONE'; data: TFormFieldWidget }
  | { type: 'UPDATE_ERROR' };

export type FormFieldLabelMachine<TFormFieldWidget extends FormFieldWidget> = ReturnType<
  typeof makeFormFieldLabelMachine<TFormFieldWidget>
>;
export type FormFieldLabelActor<TFormFieldWidget extends FormFieldWidget> = ActorRefFrom<
  FormFieldLabelMachine<TFormFieldWidget>
>;

export const makeFormFieldLabelMachine = <TFormFieldWidget extends FormFieldWidget>({
  widget,
  queryClient,
}: {
  widget: TFormFieldWidget;
  queryClient: QueryClient;
}) =>
  createMachine(
    {
      id: `form-field-label:${widget.header.id}`,
      predictableActionArguments: true,
      schema: {
        events: {} as Event<TFormFieldWidget>,
        context: {} as Context<TFormFieldWidget>,
      },
      tsTypes: {} as import('./form-field-label-machine.typegen').Typegen0,
      context: { widget, initialWidget: widget, queryClient },
      type: 'parallel',
      states: {
        input: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                AUTO_FOCUS: { target: 'focused' },
                TOGGLE_EDIT: { target: 'focused' },
                CHANGE: { actions: 'assignLabel' },
              },
            },
            focused: {
              on: {
                CHANGE: { actions: 'assignLabel' },
                BLUR: 'idle',
                KEY_PRESS: { cond: 'pressedEnter', target: 'idle' },
              },
            },
          },
        },

        mutation: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                BLUR: {
                  target: 'saving',
                  cond: 'labelChanged',
                },
                KEY_PRESS: {
                  target: 'saving',
                  cond: 'pressedEnterAndLabelChanged',
                },
              },
            },
            saving: {
              entry: ['sendUpdateWidget'],
              on: {
                UPDATE_DONE: {
                  target: 'idle',
                  actions: ['assignWidget', 'assignInitialWidget'],
                },
                UPDATE_ERROR: {
                  target: 'error',
                  actions: ['resetWidget'],
                },
              },
            },
            error: {},
          },
        },

        autoFocus: {
          id: 'autoFocus',
          initial: 'disabled',
          states: {
            disabled: {
              on: { AUTO_FOCUS: 'enabled' },
            },
            enabled: {
              on: {
                CHANGE: 'disabled',
                BLUR: 'disabled',
              },
            },
          },
        },
      },
      on: {
        UPDATE_DONE: {
          actions: ['assignWidget', 'assignInitialWidget'],
        },
      },
    },
    {
      guards: {
        pressedEnterAndLabelChanged: (ctx, event) => {
          return event.event.key === 'Enter' && ctx.initialWidget?.label !== ctx.widget?.label;
        },
        pressedEnter: (_ctx, event) => {
          return event.event.key === 'Enter';
        },
        labelChanged: (ctx, _event) => {
          return ctx.initialWidget?.label !== ctx.widget?.label;
        },
      },
      actions: {
        assignLabel: assign({
          widget: (context, e) => {
            const label = e.value ?? FormFieldDefaultKey[widget.fieldType];
            const newKey = getNewKey(widget, queryClient, e.value);
            return {
              ...context.widget,
              label,
              key: newKey,
            };
          },
        }),
        assignWidget: assign({
          widget: (_, e) => e.data,
        }),
        assignInitialWidget: assign({
          initialWidget: (_, e) => e.data,
        }),
        resetWidget: assign({
          widget: context => context.initialWidget,
        }),
        sendUpdateWidget: sendParent(ctx => ({ type: 'UPDATE_WIDGET', widget: ctx.widget })),
      },
    },
  );

export const FormFieldLabelActorSelectors = {
  isEditing(state: StateFrom<FormFieldLabelMachine<any>>) {
    return state.matches('input.focused');
  },
  isSaving(state: StateFrom<FormFieldLabelMachine<any>>) {
    return state.matches('mutation.saving');
  },
};

export function getNewKey(widget: FormFieldWidget, queryClient: QueryClient, newLabel?: string): string {
  const widgets =
    queryClient.getQueryData<WidgetsByTemplateRevisionIdQueryResponse>(
      WidgetsByTemplateRevisionIdQuery.getKey(widget.templateRevision.id),
    ) ?? [];
  const label = newLabel ?? FormFieldDefaultKey[widget.fieldType];
  const key = FormFieldHelpers.generateKeyFromLabel(label.trim());
  const oldKey = widget.key;
  const keys = widgets.filter((w): w is FormFieldWidget => isFormFieldWidget(w) && w.key !== oldKey).map(w => w.key);
  const newKey = FormFieldHelpers.generateUniqueKey(keys, key);

  return newKey;
}
