import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';
import { DropzoneState, useDropzone } from 'react-dropzone';
import { FormFieldWidget, RichEmailWidgetAttachmentWithS3File, Template } from '@process-street/subgrade/process';
import { useUploadToS3Mutation } from 'features/upload/query-builder';
import { useFinishEmailAttachmentTemplateUploadMutation } from '../query-builder/finish-email-attachment-template-upload-mutation';
import { useUploadUrlTemplateEmailAttachmentMutation } from '../query-builder/upload-url-template-email-attachment-mutation';

export const SIZE_TOO_LARGE = 'size-too-large';
const MAX_FILE_SIZE_MB = 25;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

type UploadAttachmentReturn = {
  dropzoneState: DropzoneState;
  uploadError: AxiosError | null;
};

type UploadTemplateEmailAttachmentProps = {
  templateId: Template['id'];
  widgetId: FormFieldWidget['id'];
  onFinish: (attachment: RichEmailWidgetAttachmentWithS3File) => void;
  setIsUploading: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  setProgress: (progress: number | undefined) => void;
  totalSize: number;
};

export const useUploadTemplateEmailAttachment = ({
  templateId,
  widgetId,
  onFinish,
  setIsUploading,
  setProgress,
  totalSize,
}: UploadTemplateEmailAttachmentProps): UploadAttachmentReturn => {
  const uploadUrlMutation = useUploadUrlTemplateEmailAttachmentMutation();
  const uploadToS3Mutation = useUploadToS3Mutation();

  const finishUploadAttachmentMutation = useFinishEmailAttachmentTemplateUploadMutation({
    onSuccess: data => {
      onFinish(data);
    },
    onError: () => {},
  });

  const uploadError = uploadUrlMutation.error ?? uploadToS3Mutation.error ?? finishUploadAttachmentMutation.error;

  useEffect(() => {
    if (uploadError) {
      setProgress(undefined);
      setIsUploading.off();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query error
  }, [uploadError]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;

      const [file] = acceptedFiles;

      const reader = new FileReader();

      const { url, key } = await uploadUrlMutation.mutateAsync(
        {
          templateId,
          fileName: file.name,
          mimeType: file.type,
        },
        {
          onSuccess: () => {
            setIsUploading.on();
            setProgress(1);

            reader.readAsArrayBuffer(file);
          },
        },
      );

      reader.onload = async () => {
        if (reader.readyState === 2 && reader.result !== null) {
          uploadToS3Mutation.mutateAsync(
            {
              file,
              url,
              data: reader.result,
              onProgress: (progress: number) => setProgress(progress),
            },
            {
              onSuccess: () => {
                finishUploadAttachmentMutation.mutateAsync({
                  templateId,
                  widgetId,
                  contentType: file.type,
                  originalFilename: file.name,
                  key,
                });
              },
            },
          );
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
    [templateId, widgetId],
  );

  const dropzoneState = useDropzone({
    onDrop,
    validator: file => {
      if (file.size > MAX_FILE_SIZE - totalSize) {
        return {
          code: SIZE_TOO_LARGE,
          message: `Uploaded files must not exceed ${MAX_FILE_SIZE_MB} MB in total.`,
        };
      }
      return null;
    },
  });

  return {
    dropzoneState,
    uploadError,
  };
};
