import { TypeOfEnumValue } from '@process-street/subgrade/core';
import { FileFormFieldWidget } from '../widget-model';

function fileFormFieldCIF<
  Code extends string,
  Config extends Record<
    string,
    { code: Code; isInvalid: (constraints: NonNullable<FileFormFieldWidget['constraints']>, value: string) => boolean }
  >,
>(config: Config): Config {
  return config;
}

export const FileFormFieldValidation = fileFormFieldCIF({
  extensions: {
    code: 'extensions',
    isInvalid: (constraints, value) => {
      return (constraints.extensions?.length ?? 0) > 0 && !constraints.extensions?.some(ext => value.endsWith(ext));
    },
  },
});

export const getFileFormFieldWidgetErrorType = (constraints: FileFormFieldWidget['constraints'], value: string) => {
  if (!value || !constraints) return;
  return (
    Object.values(FileFormFieldValidation).find(config => {
      return config.isInvalid(constraints, value);
    })?.code ?? undefined
  );
};

export const getFileFormFieldWidgetErrorMessage = (constraints: FileFormFieldWidget['constraints'], value: string) => {
  if (value === '' || !constraints) return '';
  if (getFileFormFieldWidgetErrorType(constraints, value)) {
    return `File extension not allowed. Allowed extensions ${constraints.extensions?.join(' ')}`;
  }
  return '';
};
export type FileFormFieldValidationCode = TypeOfEnumValue<typeof FileFormFieldValidation>['code'];
