import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { getConditionCount } from 'features/conditional-logic/components/rule-definition/use-logical-rule';

const conditionHeights = {
  desktop: 100,
  mobile: 150,
};
const buttonRowHeights = {
  desktop: 50,
  mobile: 50,
};
export const simpleRuleHeights = {
  desktop: 200,
  mobile: 300,
};

export function getRuleHeight(rule: ChecklistRuleDefinition, isDesktop: boolean) {
  if (!rule) {
    // "add" button
    return 100;
  }
  const conditionCountData = getConditionCount(rule);
  const fieldsHeight =
    // +1 is the action part
    (conditionCountData.conditions + 1) * (isDesktop ? conditionHeights.desktop : conditionHeights.mobile);
  const logicalButtonsHeight =
    conditionCountData.buttonRows * (isDesktop ? buttonRowHeights.desktop : buttonRowHeights.mobile);
  const descriptionExtraRowCount = [...(rule.description ?? '').matchAll(/\n/gm)].length;
  const descriptionBaseHeight = 90;
  return fieldsHeight + logicalButtonsHeight + descriptionBaseHeight + Math.min(descriptionExtraRowCount, 2) * 22;
}
