import angular from 'angular';
import { canAccess, Feature } from 'services/features/features';
import '../_users.scss';
import { trace } from 'components/trace';

class OrganizationManageUsersCtrl {
  constructor($scope, SessionService, Subject, UserService) {
    'ngInject';
    const ctrl = this;
    const logger = trace({ name: 'OrganizationManageUsersCtrl' });
    logger.info('loading ctrl');

    // Main

    ctrl.$onInit = () => {
      const currentPlan = SessionService.getCurrentPlan();
      const planCanAccessFullPermissions = canAccess(Feature.FULL_PERMISSIONS, currentPlan.id);

      $scope.restricted = !planCanAccessFullPermissions || SessionService.isCanceled();

      const selectedOrganizationId = SessionService.getSelectedOrganizationId();

      $scope.selectedOrganizationId = selectedOrganizationId;
      $scope.userIsAdmin = new Subject(UserService.getCurrentUser().id, selectedOrganizationId).isAdmin();
    };
  }
}

angular.module('frontStreetApp.controllers').controller('OrganizationManageUsersCtrl', OrganizationManageUsersCtrl);
