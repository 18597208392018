import angular from 'angular';
import './unavailable.scss';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller('UnavailableCtrl', function ($location, $scope, $state, $timeout, Ping, UserService) {
    const ctrl = this;
    const logger = trace({ name: 'UnavailableCtrl' });
    logger.info('loading ctrl');

    ctrl.$onInit = () => {
      UserService.validateSessionUser().then(valid => {
        if (!valid) {
          $state.go('logout');
        }
      });
    };

    $scope.ping = function () {
      if ($scope.pinging) {
        return;
      }

      $scope.message = 'Asking Process Street&hellip;';

      $scope.pinging = true;
      Ping.ping({}).$promise.then(
        () => {
          $scope.message = 'Process Street said "Sure am!"';

          $location.url($state.params.url || '/dashboard');
          $location.replace();
        },
        () => {
          $scope.message = 'Process Street said "Not yet!"';
          $timeout(() => {
            $scope.pinging = false;
          }, 5000);
        },
      );
    };
  });
