import { TaskTemplate, Template, TemplateRevision } from '@process-street/subgrade/process';
import * as React from 'react';
import { TaskContentRenderer } from '../task-content-renderer';

export type WidgetListSectionProps = {
  currentTaskTemplate: TaskTemplate;
  template: Template;
  templateRevision: TemplateRevision;
  isMobile: boolean;
};

export const WidgetListSection = React.memo(
  ({ currentTaskTemplate, template, templateRevision, isMobile }: WidgetListSectionProps) => (
    <TaskContentRenderer
      key={currentTaskTemplate.id}
      template={template}
      templateRevision={templateRevision}
      taskTemplate={currentTaskTemplate}
      isMobile={isMobile}
    />
  ),
);

WidgetListSection.displayName = 'WidgetListSection';
