import angular from 'angular';

angular.module('frontStreetApp.directives').directive('psInViewport', $parse => ({
  restrict: 'A',
  link: function postLink(scope, element, attributes) {
    var inViewportExpression = $parse(attributes.psInViewport);
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        scope.$applyAsync(function () {
          inViewportExpression(scope, {
            $inview: entry.isIntersecting,
          });
        });
      });
    });
    observer.observe(element[0]);
  },
}));
