/** Calculate the label and left margin based on the digits length of the x axis */
export function getScaledXAxisMeasurements({ max }: { max: number }): {
  axisLabelOffset: number;
  marginLeft: number;
} {
  const maxDigits = max.toString().length;
  const axisLabelOffset = 16 + (maxDigits - 1) * 8;
  const marginLeft = axisLabelOffset * 2 + 16;

  return {
    axisLabelOffset,
    marginLeft,
  };
}
