import {
  CHECKLIST_PERMIT_DELETE,
  CHECKLIST_PERMIT_GET_ALL_BY_ORGANIZATION_ID,
  CHECKLIST_PERMIT_PUT,
} from 'reducers/checklist-permit/checklist-permit.actions';
import {
  cascadeCleanOnDeleteReducerObject,
  entitiesCollectionReducerObject,
  referencesNormalizer,
} from 'reducers/entities/entities.utils';
import { CHECKLIST_DELETE_BY_ID } from 'reducers/checklist/checklist.actions';
import { composeReducerObjects } from 'reducers/util';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';

const normalizeChecklistPermit = referencesNormalizer(['organization', 'organizationMembership', 'checklist']);

export const checklistPermitEntitiesReducer = composeReducerObjects(
  entitiesCollectionReducerObject(CHECKLIST_PERMIT_PUT, CHECKLIST_PERMIT_DELETE, normalizeChecklistPermit),

  {
    [CHECKLIST_PERMIT_GET_ALL_BY_ORGANIZATION_ID]: (state, { payload: checklistPermits }) =>
      EntitiesReducerUtils.upsertAll(state, checklistPermits, normalizeChecklistPermit),
  },

  cascadeCleanOnDeleteReducerObject(CHECKLIST_DELETE_BY_ID, 'checklist.id', 'id'),
);
