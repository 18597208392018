import angular from 'angular';
import { htmlEscaped } from '@process-street/subgrade/util';
import templateUrl from './checklist-assignments.component.html';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ArrayService } from 'services/array-service';

angular.module('frontStreetApp.directives').component('psChecklistAssignments', {
  bindings: {
    checklist: '<',
    users: '<',
    unassignable: '<',
    onUnassign: '&',
  },
  require: {
    checklistAssignerCtrl: '^psChecklistAssigner',
  },
  templateUrl,
  controller(ChecklistAssignmentService, SessionService, UserService, ToastService) {
    const ctrl = this;

    ctrl.unassignUser = function (user) {
      ArrayService.desplice(ctrl.users, user);

      const name = UserService.getLabel(user);

      ChecklistAssignmentService.unassign(ctrl.checklist, user).then(
        () => {
          ToastService.openToast({
            status: 'success',
            title: htmlEscaped`${name} has been removed from this workflow run`,
          });

          if (ctrl.onUnassign) {
            ctrl.onUnassign({ user });
          }
        },
        () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems removing the user from this workflow run`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });

          ctrl.users.push(user);
        },
      );
    };

    ctrl.$onChanges = function (changes) {
      if (changes.users) {
        ctrl.users = changes.users.currentValue;
      }
    };

    ctrl.getCoverUser = () => UserService.getCoverUser(ctrl.users);

    ctrl.getBadge = function () {
      return ctrl.users.length > 1 && ctrl.users.length;
    };
  },
});
