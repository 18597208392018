import * as React from 'react';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { components, GroupBase, MenuProps } from 'react-select';
import { PreviewerOption } from './utils';
import { MenuSearchHeader } from 'app/components/design/BlvdSelect/components';

export const CustomMenuContainer = (props: MenuProps<PreviewerOption, false, GroupBase<PreviewerOption>>) => {
  const { children } = props;
  return (
    <components.Menu {...props}>
      <ThemeProvider2024>
        <MenuSearchHeader key="menu-search-header" autoFocus={false} {...props} />
        {children}
      </ThemeProvider2024>
    </components.Menu>
  );
};
