import {
  DataSetColumnState,
  DataSetFilterType,
  DataSetColumnFilterForm,
  DataSetFilterParams,
  SavedViewFilterOperator,
} from '@process-street/subgrade/process';
import create from 'zustand';
import { combine } from 'zustand/middleware';
import { MuidUtils } from '@process-street/subgrade/core';

export type DataSetFiltersSlice = {
  columns: DataSetColumnState[];
  filters: DataSetColumnFilterForm[];
  operator: SavedViewFilterOperator;
  version: number;
};

const INITIAL_STATE: DataSetFiltersSlice = {
  columns: [],
  filters: [],
  operator: SavedViewFilterOperator.And,
  version: 0,
};

export const useDataSetFilters = create(
  combine(INITIAL_STATE, set => {
    const setColumnsState = ({ columns }: { columns: DataSetColumnState[] }) => set({ columns });
    const applyFilters = () => set(state => ({ version: state.version + 1 }));

    const updateColumnState = ({ column }: { column: DataSetColumnState }) => {
      return set(state => {
        const updatedColumns = state.columns.map(c => {
          if (c.id === column.id) return column;

          return c;
        });

        return { columns: updatedColumns };
      });
    };

    const addFilter = () =>
      set(state => ({
        filters: [
          ...state.filters,
          {
            id: MuidUtils.randomMuid(),
            columnId: undefined,
            filterType: DataSetFilterType.Text,
            filterParams: {
              value: undefined,
              condition: undefined,
            },
          },
        ],
      }));

    const removeFilter = (index: number) =>
      set(state => ({
        filters: state.filters.filter((_, i) => i !== index),
      }));

    const setFilters = ({ filters }: { filters: DataSetColumnFilterForm[] }) => set({ filters });

    const setFilter = ({
      index,
      columnId,
      condition,
      value,
    }: { index: number; columnId: DataSetColumnFilterForm['columnId'] } & Pick<
      DataSetFilterParams,
      'condition' | 'value'
    >) => {
      return set(state => ({
        filters: state.filters.map((filter, i) => {
          if (i !== index) return filter;
          return { ...filter, columnId, filterParams: { ...filter.filterParams, condition, value } };
        }),
      }));
    };

    const setOperator = (operator: SavedViewFilterOperator) => {
      return set({ operator });
    };

    return {
      setColumnsState,
      updateColumnState,
      addFilter,
      removeFilter,
      setFilters,
      setFilter,
      setOperator,
      applyFilters,
    };
  }),
);
