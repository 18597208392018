import { combineReducers } from 'redux';
import { DataServiceActions } from 'reducers/data-service/data-service.actions';
import { handleActions } from 'redux-actions';
import { FOLDER_PERMIT_DELETE, FOLDER_PERMIT_PUT } from 'reducers/folder-permit/folder-permit.actions';
import { TEMPLATE_PERMIT_DELETE, TEMPLATE_PERMIT_PUT } from 'reducers/template-permit/template-permit.actions';
import { CHECKLIST_DELETE, CHECKLIST_PUT } from 'reducers/checklist/checklist.actions';

const collectionEventReducer = (putActionName, deleteActionName) => (state, action) => {
  switch (action.type) {
    case putActionName:
      return action;
    case deleteActionName:
      return action;
    default:
      return false;
  }
};

const eventReducers = combineReducers({
  folderPermits: collectionEventReducer(FOLDER_PERMIT_PUT, FOLDER_PERMIT_DELETE),
  templatePermits: collectionEventReducer(TEMPLATE_PERMIT_PUT, TEMPLATE_PERMIT_DELETE),
  checklists: collectionEventReducer(CHECKLIST_PUT, CHECKLIST_DELETE),
});

/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "state" }]*/
const checklistDataInitializedReducer = handleActions(
  {
    [DataServiceActions.setChecklistDataInitialized]: (state, { payload }) => payload,
  },
  false,
);

/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "state" }]*/
const initializedReducer = handleActions(
  {
    [DataServiceActions.setInitialized]: (state, { payload }) => payload,
  },
  false,
);

const flagReducers = combineReducers({
  initialized: initializedReducer,
  checklistDataInitialized: checklistDataInitializedReducer,
  event: () => ({}),
});

export const dataServiceMetaReducer = (state, action) => {
  const meta = flagReducers(state, action);
  if (meta.initialized) {
    return { ...meta, event: eventReducers(state.event, action) };
  } else {
    return meta;
  }
};
