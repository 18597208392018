export const customSpace = {
  'px': '1px',
  '0.5': '2px',
  '0': '0px',
  '1': '4px',
  '1.5': '6px',
  '2': '8px',
  '2.5': '10px',
  '3': '12px',
  '3.5': '14px',
  '4': '16px',
  '5': '20px',
  '6': '24px',
  '7': '28px',
  '8': '32px',
  '9': '36px',
  '10': '40px',
  '11': '44px',
  '12': '48px',
  '13': '52px',
  '14': '56px',
  '15': '60px',
  '16': '64px',
  '17': '68px',
  '18': '72px',
  '19': '76px',
  '20': '80px',
  '21': '84px',
  '22': '88px',
  '23': '92px',
  '24': '96px',
  '25': '100px',
  '26': '104px',
  '27': '108px',
  '28': '112px',
  '29': '116px',
  '30': '120px',
  '31': '124px',
  '32': '128px',
  '33': '132px',
  '34': '136px',
  '35': '140px',
  '36': '144px',
  '37': '148px',
  '38': '152px',
  '39': '156px',
  '40': '160px',
  '41': '164px',
  '42': '168px',
  '43': '172px',
  '44': '176px',
  '45': '180px',
  '46': '184px',
  '47': '188px',
  '48': '192px',
  '49': '196px',
  '50': '200px',
  '51': '204px',
  '52': '208px',
  '53': '212px',
  '54': '216px',
  '55': '220px',
  '56': '224px',
  '57': '228px',
  '58': '232px',
  '59': '236px',
  '60': '240px',
  '61': '244px',
  '62': '248px',
  '63': '252px',
  '64': '256px',
  '65': '260px',
  '66': '264px',
  '67': '268px',
  '68': '272px',
  '69': '276px',
  '70': '280px',
  '71': '284px',
  '72': '288px',
  '73': '292px',
  '74': '296px',
  '75': '300px',
  '76': '304px',
  '77': '308px',
  '78': '312px',
  '79': '316px',
  '80': '320px',
  '88': '352px',
  '96': '384px',
  '100': '400px',
  '175': '700px',
  'full': '100%',
  '3xs': '224px',
  '2xs': '256px',
  'xs': '320px',
  'sm': '384px',
  'md': '448px',
  'lg': '512px',
  'xl': '576px',
  '2xl': '672px',
  '3xl': '768px',
  '4xl': '896px',
  '5xl': '1024px',
  '6xl': '1152px',
  '7xl': '1280px',
};
