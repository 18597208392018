import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { EditTemplateButton } from '.';

export const EditTemplateButtonModule = angular
  .module('editTemplateButton', [])
  .component(
    'psEditTemplateButton',
    reactToAngular(EditTemplateButton, ['label', 'onClick', 'variant', 'draftExists', 'templateType']),
  ).name;
