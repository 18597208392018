import angular from 'angular';

angular.module('frontStreetApp.services').factory('Permits', function (ResourceFactory) {
  return ResourceFactory.create({
    query: 'GET[] /1/permits/:type',
    getAllByOrganizationId: 'GET[] /1/organizations/:organizationId/permits/:type',
    create: 'POST /1/permits/:type',
    setLevel: 'PUT /1/permits/:type/:id/level/:level',
    delete: 'DELETE /1/permits/:type/:id',
    getAllAndInherited: 'GET /1/permits/:type/:id/inherited',
  });
});
