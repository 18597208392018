import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Box,
  CloseButton,
  useDisclosure,
} from 'components/design/next';
import * as React from 'react';
import { OnboardingSettingsKey } from 'components/user/user-constants';
import { useOnboardingSettings } from 'pages/templates/_id/components/template-settings-modal/use-onboarding-settings';

export const CustomizeFirstWorkflowAlert: React.FC<React.PropsWithChildren<AlertProps>> = props => (
  <DismissableOnboardingAlert {...props} settingsKey={OnboardingSettingsKey.CUSTOMIZE_WORKFLOW_ALERT_DISMISSED}>
    <AlertTitle my={1}>Customize your first workflow.</AlertTitle>
    <AlertDescription>
      A workflow is a set of instructions that lay out exactly how a process should be completed.
    </AlertDescription>
  </DismissableOnboardingAlert>
);

export const CreateFirstTasksAlert: React.FC<React.PropsWithChildren<AlertProps>> = props => (
  <DismissableOnboardingAlert {...props} settingsKey={OnboardingSettingsKey.CREATE_TASKS_ALERT_DISMISSED}>
    <AlertTitle my={1}>Create your first tasks</AlertTitle>
    <AlertDescription>
      Your workflows are completely customizable, so what’s contained inside them is entirely up to you!
    </AlertDescription>
  </DismissableOnboardingAlert>
);

const DismissableOnboardingAlert: React.FC<
  React.PropsWithChildren<AlertProps & { settingsKey: OnboardingSettingsKey }>
> = ({ settingsKey, children, ...props }) => {
  // we start with not open and show after setting has been loaded
  const { isOpen: isVisible, onClose, onOpen } = useDisclosure({ defaultIsOpen: false });
  const { onboardingSettingsQuery, updateOnboardingSettings } = useOnboardingSettings();

  React.useEffect(() => {
    if (!onboardingSettingsQuery.data?.[settingsKey]) {
      onOpen();
    }
  }, [onOpen, onboardingSettingsQuery.data, settingsKey]);

  const onDismiss = async () => {
    updateOnboardingSettings({ [settingsKey]: true });
    onClose();
  };

  if (!isVisible) return null;

  return (
    <Alert status="info" {...props}>
      <AlertIcon alignSelf="flex-start" />
      <Box>{children}</Box>
      <CloseButton
        color="gray.400"
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={onDismiss}
      />
    </Alert>
  );
};
