import { EmailFormFieldWidget, MembersFormFieldWidget } from '@process-street/subgrade/process';
import { toEntityMapUsingIdSelector } from '@process-street/subgrade/redux';
import { TaskAssignmentRule, TaskAssignmentRuleSourceType } from '@process-street/subgrade/role-assignment';
import { match, P } from 'ts-pattern';

const makeRuleTitleGetter = (
  emailAndMembersFormfieldWidgets: (EmailFormFieldWidget | MembersFormFieldWidget)[] = [],
) => {
  const widgetsByGroupId = toEntityMapUsingIdSelector(emailAndMembersFormfieldWidgets, w => w.header.group.id);

  return (rule: TaskAssignmentRule) => {
    if (rule.sourceType === TaskAssignmentRuleSourceType.ChecklistInitiator) {
      return 'Workflow Runner';
    } else if (rule.sourceType === TaskAssignmentRuleSourceType.FormField && rule.sourceFormFieldWidgetGroup?.id) {
      const widget = widgetsByGroupId[rule.sourceFormFieldWidgetGroup?.id];

      return match(widget)
        .with({ label: P.intersection(P.string, P.not('')) }, w => w.label)
        .with({ key: P.intersection(P.string, P.not('')) }, w => w.key)
        .otherwise(() => '(unnamed form field)');
    } else {
      throw new Error('could not resolve rule title');
    }
  };
};

export const RoleAssignmentUtils = {
  makeRuleTitleGetter,
};
