import * as React from 'react';
import { ChangeEventHandler } from 'react';
import { Input, InputRightElement } from 'components/design/next';
import { Muid } from '@process-street/subgrade/core';
import { useMergeTaggableInput } from 'hooks/use-merge-taggable-input';
import { InputGroup } from 'components/design/next';
import { MergeTagsMenu, MergeTagsMenuButton } from 'features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { MaskedInput, MaskedInputParsers } from 'features/widgets/components/masked-input';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { useFeatureFlag } from 'features/feature-flags';

export interface FutureChecklistNameInputProps {
  templateId?: Muid;
  onChange: (newValue: string) => void;
  value?: string;
}

const maskedInputParser = MaskedInputParsers.makeParser('merge-tags');

export const FutureChecklistNameInput: React.FC<React.PropsWithChildren<FutureChecklistNameInputProps>> = ({
  templateId,
  value = '',
  ...props
}) => {
  const { ref: futureChecklistNameRef, insertMergeTag: insertVariable } = useMergeTaggableInput({
    get: () => value,
    set: props.onChange,
  });
  const templateRevisionQuery = useNewestTemplateRevisionQuery({ templateId });
  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    props.onChange(e.target.value);
  };
  const isMergeTagImprovementsEnabled = useFeatureFlag('mergeTagImprovements');

  const html = React.useMemo(
    () =>
      isMergeTagImprovementsEnabled
        ? maskedInputParser(MergeTagStringReplacementUtils.replaceUnknownTagsValues(value))
        : value,
    [value, isMergeTagImprovementsEnabled],
  );

  return (
    <InputGroup>
      <MaskedInput w="full" html={html}>
        <Input
          w="full"
          aria-label="Scheduled workflow run name"
          maxLength={255}
          placeholder="Leave blank to use default name"
          value={value}
          onChange={onChange}
          ref={futureChecklistNameRef}
          borderColor="gray.300"
        />
      </MaskedInput>
      <InputRightElement _focusWithin={{ zIndex: 3 }}>
        <MergeTagsMenu
          {...{
            templateRevisionId: templateRevisionQuery.data?.id,
            onSelect: (key, _fieldId, fallback) => insertVariable(key, fallback),
            mergeTagTarget: MergeTagsConstants.Target.CHECKLIST,
            menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
          }}
        />
      </InputRightElement>
    </InputGroup>
  );
};
