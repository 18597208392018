import { TemplateSelector } from 'reducers/template/template.selectors';
import { createCachedAction } from 'reducers/util';
import { createAction } from 'redux-actions';

// Actions that do not affect template entities state
export const TEMPLATE_COUNT_RESULTS = 'template/COUNT_RESULTS';
export const TEMPLATE_GET_BY_ID = 'template/GET_BY_ID';
export const TEMPLATE_GET_FORM_FIELD_RESULTS = 'template/GET_FORM_FIELD_RESULTS';
export const TEMPLATE_GET_RESULTS = 'template/GET_RESULTS';
export const TEMPLATE_GET_SCHEMA = 'template/GET_SCHEMA';
export const TEMPLATE_GET_TASK_DETAILS = 'template/GET_TASK_DETAILS';
export const TEMPLATE_GET_TASK_RESULTS = 'template/GET_TASK_RESULTS';

// Actions that do change template entities state
export const TEMPLATE_COPY = 'template/COPY';
export const TEMPLATE_CREATE = 'template/CREATE';
export const TEMPLATE_DELETE_BY_ID = 'template/DELETE_BY_ID';
export const TEMPLATE_GET_ALL_BY_ORGANIZATION_ID_AND_STATUS = 'template/GET_ALL_BY_ORGANIZATION_ID_AND_STATUS';
export const TEMPLATE_GET_ALL_BY_ORGANIZATION_ID_AND_STATUS_FOR_SCHEDULE =
  'template/GET_ALL_BY_ORGANIZATION_ID_AND_STATUS_FOR_SCHEDULE';
export const TEMPLATE_GET_PREMADE_BY_ID = 'template/GET_PREMADE_BY_ID';
export const TEMPLATE_UNDELETE = 'template/UNDELETE';
export const TEMPLATE_SET = 'template/SET';
export const TEMPLATE_UPDATE = 'template/UPDATE';
export const TEMPLATE_UPDATE_PUBLIC = 'template/UPDATE_PUBLIC';
export const TEMPLATE_UPDATE_SHARE_LEVEL = 'template/UPDATE_SHARE_LEVEL';
export const TEMPLATE_UPDATE_SHAREABLE_RUN_LINK = 'template/UPDATE_SHAREABLE_RUN_LINK';
export const TEMPLATE_UPDATE_STATUS = 'template/UPDATE_STATUS';

export const TemplateActions = TemplateApi => {
  'ngInject';

  /**
   * Gets a single template by id
   *
   * @param id
   * @param flushCache
   * @return {Promise}
   */
  const getById = createCachedAction(
    TEMPLATE_GET_BY_ID,
    TemplateApi.getById,
    templateId => ({ templateId }),
    (state, templateId) => TemplateSelector.getById(templateId)(state) !== undefined,
    TemplateSelector.getById,
  );

  const getPublicById = createCachedAction(
    TEMPLATE_GET_BY_ID,
    TemplateApi.getPublicById,
    templateId => ({ templateId }),
    (state, templateId) => TemplateSelector.getById(templateId)(state) !== undefined,
    TemplateSelector.getById,
  );

  const getAllByOrganizationIdAndStatus = createCachedAction(
    TEMPLATE_GET_ALL_BY_ORGANIZATION_ID_AND_STATUS,
    TemplateApi.getAllByOrganizationIdAndStatus,
    (organizationId, status) => ({ organizationId, status }),
  );

  const getAllByOrganizationIdAndStatusForSchedule = createCachedAction(
    TEMPLATE_GET_ALL_BY_ORGANIZATION_ID_AND_STATUS_FOR_SCHEDULE,
    TemplateApi.getAllByOrganizationIdAndStatusForSchedule,
    organizationId => ({ organizationId }),
  );

  const set = createAction(TEMPLATE_SET, template => ({ template }));

  const update = createCachedAction(TEMPLATE_UPDATE, TemplateApi.update);

  const undelete = createCachedAction(TEMPLATE_UNDELETE, TemplateApi.undelete);

  /**
   * Updates share level of a template
   *
   * @param id
   * @param level
   *
   * @return {Promise}
   */
  const updateShareLevelById = createCachedAction(TEMPLATE_UPDATE_SHARE_LEVEL, TemplateApi.updateShareLevelById);

  const updateShareableRunLink = createCachedAction(
    TEMPLATE_UPDATE_SHAREABLE_RUN_LINK,
    TemplateApi.updateShareableRunLink,
    (templateId, sharedRunLink) => ({ templateId, sharedRunLink }),
  );

  const updateStatus = createCachedAction(TEMPLATE_UPDATE_STATUS, TemplateApi.updateStatus);

  const updatePublic = createCachedAction(TEMPLATE_UPDATE_PUBLIC, TemplateApi.updatePublic);

  /**
   * Copies template by template id to target folder id with target template name
   *
   * @param templateId
   * @param targetFolderId
   * @param targetTemplateName
   *
   * @return {Promise}
   */
  const copy = createCachedAction(TEMPLATE_COPY, TemplateApi.copy);

  const create = createCachedAction(TEMPLATE_CREATE, TemplateApi.create);

  /**
   * Deletes a template by id
   *
   * @param id
   */
  const deleteById = createCachedAction(TEMPLATE_DELETE_BY_ID, TemplateApi.deleteById, templateId => ({ templateId }));

  return {
    copy,
    create,
    deleteById,
    getAllByOrganizationIdAndStatus,
    getAllByOrganizationIdAndStatusForSchedule,
    getById,
    getPublicById,
    undelete,
    update,
    set,
    updatePublic,
    updateShareLevelById,
    updateShareableRunLink,
    updateStatus,
  };
};
