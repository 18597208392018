import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import { TableFormFieldConfig } from '@process-street/subgrade/process/configs/table-form-field-config';
import { MuidUtils } from '@process-street/subgrade/core';
import { match, P } from 'ts-pattern';
import { TableFormFieldWidget } from '@process-street/subgrade/process';
import { escapeHtml } from '@process-street/subgrade/util';
import Papa from 'papaparse';

export namespace TableFormFieldUtils {
  export function isRowEmpty(row: TableFieldValue.Row): boolean {
    return Object.values(row.cells).every(cell =>
      match(cell.value)
        .with(P.string, value => value.trim() === '')
        .with(P.number, value => isNaN(value))
        .otherwise(() => true),
    );
  }

  export function isLastRowEmpty(rows: TableFieldValue.Row[]): boolean {
    if (rows.length > 0) {
      return isRowEmpty(rows[rows.length - 1]);
    }
    return false;
  }

  export function removeLastEmptyRows(rows: TableFieldValue.Row[]): TableFieldValue.Row[] {
    if (isLastRowEmpty(rows)) {
      return removeLastEmptyRows(rows.slice(0, rows.length - 1));
    }
    return rows;
  }

  export function addLastEmptyRow(
    rows: TableFieldValue.Row[],
    columnDefs: TableFormFieldConfig.ColumnDef[],
  ): TableFieldValue.Row[] {
    if (!isLastRowEmpty(rows)) {
      const cells = columnDefs.reduce<TableFieldValue.Row['cells']>((cs, def) => {
        return { ...cs, [def.id]: { value: null } };
      }, {});
      return [...rows, { id: MuidUtils.randomMuid(), cells }];
    }
    return rows;
  }

  export function addMissingRowCells(
    row: TableFieldValue.Row,
    columnDefs: TableFormFieldConfig.ColumnDef[],
  ): TableFieldValue.Row {
    const cells = columnDefs.reduce<TableFieldValue.Row['cells']>((cs, def) => {
      if (!cs[def.id]) {
        return { ...cs, [def.id]: { value: null } };
      }
      return cs;
    }, row.cells);
    return { ...row, cells };
  }

  export const WRAPPER_CSS_CLASS = 'table-form-field-merge-tag';

  export function toHtmlString(formFieldWidget: TableFormFieldWidget, fieldValue: TableFieldValue.FieldValue): string {
    const { columnDefs } = formFieldWidget.config;
    const headerCells = columnDefs.map(columnDef => `<th>${escapeHtml(columnDef.name)}</th>`).join('');
    const bodyRows = fieldValue.rows
      .map(row => {
        const bodyCells = columnDefs
          .map(columnDef => {
            const value = row.cells[columnDef.id]?.value;
            return `<td>${value ? escapeHtml(value.toString()) : ''}</td>`;
          })
          .join('');
        return `<tr>${bodyCells}</tr>`;
      })
      .join('');

    return `<div class='${WRAPPER_CSS_CLASS}'><table><thead><tr>${headerCells}</tr></thead><tbody>${bodyRows}</tbody></table></div>`;
  }

  export function toCsv(
    config: TableFormFieldConfig.Config,
    fieldValue: TableFieldValue.FieldValue,
    includeHeader = false,
  ): string {
    const { columnDefs } = config;
    const header = columnDefs.map(columnDef => columnDef.name);
    const rows = fieldValue.rows.map(row =>
      columnDefs.map(columnDef => {
        const { value } = row.cells[columnDef.id];
        return value;
      }),
    );
    const data = includeHeader ? [header, ...rows] : rows;
    return Papa.unparse(data, { newline: '\n' });
  }
}
