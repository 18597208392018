import { createCachedAction } from '../util';
import { PlanFeatureSetSelectors } from './plan-feature-set.selectors';

export const PLAN_FEATURE_SET_GET_BY_ID = 'plan/featureSet/GET_BY_ID';

export const PlanFeatureSetActions = PlanFeatureSetApi => {
  'ngInject';

  const getById = createCachedAction(
    PLAN_FEATURE_SET_GET_BY_ID,
    PlanFeatureSetApi.getById,
    (id, flushCache) => ({ id, flushCache }),
    (state, featureSetId) => PlanFeatureSetSelectors.plansFeatureSetByIdSelectors(featureSetId)(state),
    PlanFeatureSetSelectors.plansFeatureSetByIdSelectors,
  );

  return {
    getById,
  };
};
