import { Muid } from '@process-street/subgrade/core';
import Encase from 'encase';
import { AccessLevel, FolderPermit, PermitType, TemplatePermit } from '@process-street/subgrade/permission';
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type Params = { id: Muid };
type Result = {
  inheritedPermits: FolderPermit[];
  permits: (TemplatePermit | FolderPermit)[];
};

export { Params as GetPermitsByTypeAndIdParams, Result as GetPermitsByTypeAndIdResult };

export const getTemplatePermits = ({ id }: Params) => {
  return axiosService
    .getAxios()
    .get<Result>(`/1/permits/${PermitType.TEMPLATE}/${id}/inherited`)
    .then(r => r.data);
};
export const GetTemplatePermits = {
  key: ['permits', 'template', 'inherited'],
  getKey: (params: Params): QueryKey => [...GetTemplatePermits.key, params],
  queryFn: getTemplatePermits,
};

export const useTemplatePermitsQuery = (params: Params, options: UseQueryOptions<Result> = {}) =>
  useQuery(GetTemplatePermits.getKey(params), () => GetTemplatePermits.queryFn(params), options);

export const getFolderPermits = ({ id }: Params) =>
  axiosService
    .getAxios()
    .get<Result>(`/1/permits/${PermitType.FOLDER}/${id}/inherited`)
    .then(r => r.data);

export const GetFolderPermits = {
  key: ['permits', 'folder', 'inherited'],
  getKey: (params: Params): QueryKey => [...GetFolderPermits.key, params],
  queryFn: getFolderPermits,
};

export const useFolderPermitsQuery = (params: Params, options: UseQueryOptions<Result> = {}) =>
  useQuery(GetFolderPermits.getKey(params), () => GetFolderPermits.queryFn(params), options);

export type SetPermitLevelParams = { id: Muid; type: PermitType; level: AccessLevel };
export type SetPermitLevelResult = TemplatePermit | FolderPermit;

export const setPermitLevel = ({ id, type, level }: SetPermitLevelParams) => {
  return axiosService
    .getAxios()
    .put<SetPermitLevelResult>(`/1/permits/${type}/${id}/level/${Encase.toUpperCamel(level)}`, { id, type, level })
    .then(r => r.data);
};
export const SetPermitLevel = {
  mutationKey: ['permits', 'set'],
  mutationFn: setPermitLevel,
};

export const useSetPermitLevelMutation = (
  options: UseMutationOptions<SetPermitLevelResult, Error, SetPermitLevelParams> = {},
) => {
  return useMutation(
    SetPermitLevel.mutationKey,
    (params: SetPermitLevelParams) => SetPermitLevel.mutationFn(params),
    options,
  );
};

export type RemovePermitParams = { id: Muid; type: PermitType };
export type RemovePermitResult = TemplatePermit | FolderPermit;

// /1/permits/templates
// /1/permits/folders
export const removePermit = ({ id, type }: RemovePermitParams) => {
  return axiosService
    .getAxios()
    .delete<RemovePermitResult>(`/1/permits/${type}/${id}`)
    .then(r => r.data);
};

export const RemovePermit = {
  mutationKey: ['permits', 'remove'],
  mutationFn: removePermit,
};

export const useRemovePermitMutation = (
  options: UseMutationOptions<RemovePermitResult, Error, RemovePermitParams> = {},
) => {
  return useMutation(RemovePermit.mutationKey, RemovePermit.mutationFn, options);
};
