import { SessionSelector } from 'reducers/session/session.selectors';
import { connectController } from 'reducers/util';
import templateUrl from './role-assignment-upgrade.component.html';
import './role-assignment-upgrade.scss';

export const RoleAssignmentUpgradeComponent = {
  templateUrl,
  controller: class RoleAssignmentUpgradeComponent {
    constructor($ngRedux, Subject) {
      'ngInject';

      const mapStateToThis = () => state => {
        const selectedOrganization = SessionSelector.getSelectedOrganization(state);
        if (!selectedOrganization) {
          return {};
        }

        const currentUserId = SessionSelector.getCurrentUserId(state);
        const userIsAdmin = new Subject(currentUserId, selectedOrganization.id).isAdmin();

        return { userIsAdmin };
      };

      connectController($ngRedux, mapStateToThis, null)(this);
    }
  },
};
