import * as React from 'react';
import {
  Button,
  ButtonGroup,
  Input,
  Modal,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalContent,
  ModalBody,
} from 'components/design/next';
import { ChangeEvent } from 'react';

export interface AddGroupModalProps {
  isOpen: boolean;
  onGroupCreate: (name: string) => void;
  onClose: () => void;
  onCancel: () => void;
}

export const AddGroupModal: React.FC<React.PropsWithChildren<AddGroupModalProps>> = ({
  isOpen,
  onClose,
  onCancel,
  onGroupCreate,
}) => {
  const [name, setName] = React.useState('');

  React.useEffect(() => {
    if (isOpen) {
      setName('');
    }
  }, [isOpen]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleCreateClick = () => {
    onGroupCreate(name.trim());
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent top="10">
        <ModalHeader display="flex" alignItems="center" py="6" px="8">
          <Text variant="1" color="gray.700" marginLeft={2}>
            What should this group be called?
          </Text>
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody pt="6" pb="8" px="8" borderTop="1px solid" borderColor="gray.200">
          <Input value={name} onChange={handleNameChange} maxLength={255} />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup spacing={6}>
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="cta" onClick={handleCreateClick} isDisabled={name.trim().length === 0}>
              Create
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
