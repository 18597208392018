import { Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { Icon } from 'components/design/next';
import capitalize from 'lodash/capitalize';
import * as React from 'react';

export type AiGeneratingWorkflowSuccessToastProps = {
  subject: 'workflow' | 'task';
  onPublishAndRun?: () => Promise<void>;
  onClose?: () => void;
  onRegenerate?: () => void;
};

export const AiGeneratingWorkflowSuccessToast: React.FC<
  React.PropsWithChildren<AiGeneratingWorkflowSuccessToastProps>
> = ({ subject = 'workflow', onPublishAndRun, onClose, onRegenerate }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handlePublishAndRun = async () => {
    if (onPublishAndRun) {
      setIsLoading(true);
      try {
        await onPublishAndRun?.();
        onClose?.();
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRegenerate = () => {
    onRegenerate?.();
    onClose?.();
  };

  return (
    <HStack
      bgColor="white"
      borderRadius="base"
      px="4"
      py="3"
      spacing="4"
      shadow="md"
      w="full"
      minW="554px"
      justifyContent="space-between"
    >
      <HStack spacing="4" w="full" alignItems="flex-start">
        <Flex
          w="5"
          h="5"
          maxW="5"
          maxH="5"
          flexBasis="5"
          minW="5"
          bgColor="blue.500"
          borderRadius="full"
          alignItems="center"
          justifyContent="center"
        >
          <Icon icon="check" size="3" color="white" />
        </Flex>

        <VStack spacing="1" w="full" alignItems="flex-start">
          <Text color="gray.700" fontWeight="700">
            {capitalize(subject)} generated
          </Text>

          {subject === 'workflow' && <Text color="gray.600">To see your workflow in action, publish and run it.</Text>}

          {(onRegenerate || onPublishAndRun) && (
            <HStack spacing="6" w="full" justifyContent="flex-end" pt="3">
              {onRegenerate && (
                <Button
                  size="sm"
                  variant="ghost"
                  colorScheme="gray"
                  color="gray.600"
                  onClick={handleRegenerate}
                  isDisabled={isLoading}
                  loadingText="Regenerating..."
                >
                  Regenerate
                </Button>
              )}

              {onPublishAndRun && (
                <Button
                  size="sm"
                  isDisabled={isLoading}
                  onClick={handlePublishAndRun}
                  isLoading={isLoading}
                  loadingText="Publishing..."
                >
                  Publish and Run
                </Button>
              )}
            </HStack>
          )}
        </VStack>
      </HStack>
    </HStack>
  );
};
