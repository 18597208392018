import React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { ExecuteAiPrompt, NativeAutomation } from '@process-street/subgrade/process';
import { useDebouncedCallback } from 'use-debounce';
import { match } from 'ts-pattern';
import { useAiTaskTemplateFormContext } from '../../ai-task-template-form-context';
import { isNativeAutomationPristine } from '../../is-native-automation-pristine';
import { useQueryClient } from 'react-query';
import {
  GetAllNativeAutomationsQuery,
  UpdateNativeAutomationActionsMutation,
} from 'features/native-automations/query-builder';

export interface UseAiTaskTemplateFormArgs {
  promptConfig: ExecuteAiPrompt.GetPromptConfig<ExecuteAiPrompt.BasicPromptType>;
}

type Form = { prompt: string; outputWidgetId?: Muid };

export const useBasicAiTaskPromptForm = (_: UseAiTaskTemplateFormArgs) => {
  const { aiTaskTemplate, isDisabled, templateRevision, isLoading, setIsPristine } = useAiTaskTemplateFormContext();
  const { nativeAutomation, taskTemplate } = aiTaskTemplate;

  const [form, setForm] = React.useState<Form>({ outputWidgetId: undefined, prompt: '' });
  const [isFocused, setIsFocused] = React.useState(false);
  const { outputWidgetId, prompt } = form;

  React.useEffect(() => {
    // don't clobber changes if the user is focused on the form
    if (isFocused) return;
    setForm(
      nativeAutomation.actions.reduce(
        (acc, action) => {
          match(action)
            .with({ actionType: 'ExecuteAiPrompt' }, ({ config }) => {
              acc['prompt'] = config.prompt ?? ExecuteAiPrompt.PROMPT_CONFIGS_BY_TYPE[config.promptType].prompt ?? '';
            })
            .with({ actionType: 'UpdateFormFields' }, ({ config }) => {
              acc['outputWidgetId'] = config.mapping[NativeAutomation.DEFAULT_MAPPING_KEY] ?? null;
            })
            .otherwise(() => {});
          return acc;
        },
        { outputWidgetId: undefined, prompt: '' } as Form,
      ),
    );
  }, [isFocused, nativeAutomation.actions]);

  React.useEffect(() => {
    const isPristine = isNativeAutomationPristine(nativeAutomation);
    setIsPristine(isPristine);
  }, [nativeAutomation, setIsPristine]);

  const queryClient = useQueryClient();

  const updateNativeAutomationActions = UpdateNativeAutomationActionsMutation.useMutation({
    onSuccess: data => {
      queryClient.setQueryData<GetAllNativeAutomationsQuery.Response>(
        GetAllNativeAutomationsQuery.getKey({ templateRevisionId: taskTemplate!.templateRevision.id }),
        GetAllNativeAutomationsQuery.taskTemplateAutomationUpdater(taskTemplate!.id, data),
      );
    },
  });

  const save = React.useCallback(
    ({ prompt, outputWidgetId }: Form) => {
      if (!taskTemplate?.id) return;
      const { actions } = nativeAutomation;
      const newActions = actions.map(action => {
        return match(action)
          .with({ actionType: 'ExecuteAiPrompt' }, action => ({
            ...action,
            config: { ...action.config, prompt },
          }))
          .with({ actionType: 'UpdateFormFields' }, action => ({
            ...action,
            key: action.key,
            config: outputWidgetId
              ? {
                  ...action.config,
                  mapping: {
                    [NativeAutomation.DEFAULT_MAPPING_KEY]: outputWidgetId,
                  },
                }
              : { ...action.config, mapping: {} },
          }))
          .otherwise(() => action) as typeof action;
      });

      updateNativeAutomationActions.mutate({
        nativeAutomationId: aiTaskTemplate.nativeAutomation.id,
        actions: newActions,
      });
    },
    [taskTemplate?.id, nativeAutomation, updateNativeAutomationActions, aiTaskTemplate.nativeAutomation.id],
  );

  const onOutputWidgetIdChanged = React.useCallback(
    (value: Muid | undefined) => {
      const outputWidgetId = value;
      setForm(c => ({ ...c, outputWidgetId }));
      save({ prompt, outputWidgetId });
    },
    [prompt, save],
  );

  const debouncedSave = useDebouncedCallback(save, 500);

  const onPromptChanged = React.useCallback(
    (value: string) => {
      setForm(c => ({ ...c, prompt: value }));
      debouncedSave({ prompt: value, outputWidgetId });
    },
    [debouncedSave, outputWidgetId],
  );

  return {
    isDisabled,
    prompt,
    onPromptChanged,
    onOutputWidgetIdChanged,
    outputWidgetId,
    templateRevision,
    aiTaskTemplate,
    isLoading,
    setIsFocused,
  };
};
