import * as React from 'react';
import { Link, Skeleton, Tooltip, Box } from 'components/design/next';
import { CellRenderer } from './common';
import { useInjector } from 'components/injection-provider';
import { useGetAllTemplatesQuery } from 'features/template/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { TemplateType, TemplateStatus } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { MergeTagHighlighter } from 'features/merge-tags/components/merge-tag-highlighter';

export const NameCell: CellRenderer<'name'> = ({ cell: { value: name }, row }) => {
  const { $state } = useInjector('$state');
  const orgId = useSelector(SessionSelector.getSelectedOrganizationId);

  // You might be able to see a scheduled run but not be allowed to edit it
  const templateForScheduleQuery = useGetAllTemplatesQuery(
    {
      organizationId: orgId!,
      templateStatus: TemplateStatus.Active,
      schedule: true,
      templateType: TemplateType.Playbook,
    },
    {
      enabled: Boolean(orgId),
      select: templates => templates.find(t => t.id === row.original.template.id),
    },
  );
  const nameNode = <MergeTagHighlighter fontSize="md" lineHeight="base" fontWeight="medium" content={name} />;

  return match(templateForScheduleQuery)
    .with({ status: 'loading' }, () => (
      <Skeleton>
        <Link>Blah</Link>
      </Skeleton>
    ))
    .with({ status: 'success', data: P.nullish }, () => (
      <Tooltip hasArrow label="You don’t have permission to edit this scheduled workflow.">
        <Box cursor="not-allowed">{nameNode}</Box>
      </Tooltip>
    ))
    .with({ status: 'success' }, () => (
      <Link href={$state.href('futureChecklist', { id: row.original.id })} display="block">
        {nameNode}
      </Link>
    ))
    .otherwise(() => null);
};
