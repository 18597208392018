import { Muid, Option, Organization, OrganizationMembershipWithUser, User } from '@process-street/subgrade/core';
import {
  Checklist,
  ChecklistRevision,
  FormFieldValueWithWidget,
  Task,
  TaskTemplate,
  Template,
} from '@process-street/subgrade/process';
import { ChecklistRevisionQuery } from 'features/checklist-revisions/query-builder';
import { GetChecklistQuery } from 'features/checklists/query-builder';
import { GetFormFieldValuesByChecklistRevisionIdQuery } from 'features/widgets/query-builder';
import { GetOrganizationQuery } from 'features/organization/query-builder';
import { OrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { TemplateRevisionQuery } from 'features/template-revisions/query-builder';
import { GetTemplateQuery } from 'features/template/query-builder';
import { TaskQuery } from 'features/task/query-builder';
import { TaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { GetCurrentUserInfo } from 'features/user/query-builder';
import { QueryClient } from 'react-query';
import {
  GetEmailMergeTagsQuery,
  GetEmailMergeTagsResult,
} from 'features/run-via-email/query-builder/get-email-merge-tags';
import {
  GetDataSetMergeTagsByChecklistRevisionQuery,
  GetDataSetMergeTagsByChecklistRevisionResult,
} from 'pages/reports/data-sets/query-builder/get-data-set-merge-tags';

export interface RecordStore {
  getChecklistRevision: () => Promise<ChecklistRevision>;
  getChecklist: () => Promise<Checklist>;
  getCurrentUser: () => Promise<User>;
  getFormFieldValues: () => Promise<FormFieldValueWithWidget[]>;
  getOrganization: () => Promise<Organization>;
  getOrganizationMemberships: () => Promise<OrganizationMembershipWithUser[]>;
  getTask: () => Promise<Task | undefined>;
  getTaskTemplate: () => Promise<TaskTemplate | undefined>;
  getTemplate: () => Promise<Template>;
  getUserById: (id: string) => Promise<User | undefined>;
  getEmailMergeTags: () => Promise<GetEmailMergeTagsResult>;
  getDataSetMergeTags: () => Promise<GetDataSetMergeTagsByChecklistRevisionResult>;
}

export type DefaultRecordStoreArgs = {
  queryClient: QueryClient;
  checklistRevisionId: Muid;
  taskId: Option<Muid>;
};

export const STALE_TIME = Infinity;

export function makeDefaultRecordStore({
  queryClient,
  checklistRevisionId,
  taskId,
}: DefaultRecordStoreArgs): RecordStore {
  const getChecklistRevision = async () => {
    return queryClient.fetchQuery(
      ChecklistRevisionQuery.getKey({ checklistRevisionId }),
      () => ChecklistRevisionQuery.queryFn({ checklistRevisionId }),
      { staleTime: STALE_TIME },
    );
  };

  const getChecklist = async () => {
    const checklistId = await getChecklistRevision().then(({ checklist }) => checklist.id);
    return queryClient.fetchQuery(
      GetChecklistQuery.getKey({ checklistId }),
      () => GetChecklistQuery.queryFn({ checklistId }),
      { staleTime: STALE_TIME },
    );
  };

  const getCurrentUser = async () => {
    return queryClient
      .fetchQuery(GetCurrentUserInfo.key, GetCurrentUserInfo.queryFn, { staleTime: STALE_TIME })
      .then(userInfo => userInfo.user);
  };

  // Don't mess with stale time for form field values as those are likely changing often
  const getFormFieldValues = async () => {
    return queryClient.fetchQuery(GetFormFieldValuesByChecklistRevisionIdQuery.getKey({ checklistRevisionId }), () =>
      GetFormFieldValuesByChecklistRevisionIdQuery.queryFn({ checklistRevisionId }),
    );
  };

  const getOrganization = async () => {
    const organizationId = await getChecklistRevision().then(({ organization }) => organization.id);
    return queryClient.fetchQuery(
      GetOrganizationQuery.getKey({ organizationId }),
      () => GetOrganizationQuery.queryFn({ organizationId }),
      { staleTime: STALE_TIME },
    );
  };

  const getOrganizationMemberships = async () => {
    const organizationId = await getChecklistRevision().then(({ organization }) => organization.id);
    return queryClient.fetchQuery(
      OrganizationMembershipsQuery.getKey({ organizationId }),
      () => OrganizationMembershipsQuery.queryFn({ organizationId }),
      { staleTime: STALE_TIME },
    );
  };

  const getTemplateRevision = async () => {
    const templateRevisionId = await getChecklistRevision().then(({ templateRevision }) => templateRevision.id);
    return queryClient.fetchQuery(
      TemplateRevisionQuery.getKey({ templateRevisionId }),
      () => TemplateRevisionQuery.queryFn({ templateRevisionId }),
      { staleTime: STALE_TIME },
    );
  };

  const getTemplate = async () => {
    const templateId = await getTemplateRevision().then(({ template }) => template.id);
    return queryClient.fetchQuery(
      GetTemplateQuery.getKey({ templateId }),
      () => GetTemplateQuery.queryFn({ templateId }),
      {
        staleTime: STALE_TIME,
      },
    );
  };

  const getTask = async () => {
    if (!taskId) return Promise.resolve(undefined);
    return queryClient.fetchQuery(TaskQuery.getKey({ taskId }), () => TaskQuery.queryFn({ taskId }), {
      staleTime: STALE_TIME,
    });
  };

  const getTaskTemplate = async () => {
    const task = await getTask();
    if (!task) return Promise.resolve(undefined);

    const templateRevisionId = await getChecklistRevision().then(({ templateRevision }) => templateRevision.id);
    const taskTemplates = await queryClient.fetchQuery(
      TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
      () => TaskTemplatesByTemplateRevisionIdQuery.queryFn({ templateRevisionId }),
      { staleTime: STALE_TIME },
    );
    return taskTemplates.find(tt => tt.id === task.taskTemplate.id);
  };

  const getUserById = async (id: string) => {
    return getOrganizationMemberships().then(memberships => {
      return memberships.find(m => m.user.id === id)?.user;
    });
  };

  const getEmailMergeTags = async () => {
    const checklistId = await getChecklistRevision().then(({ checklist }) => checklist.id);
    return queryClient.fetchQuery(
      GetEmailMergeTagsQuery.getKey({ checklistId }),
      () => GetEmailMergeTagsQuery.queryFn({ checklistId }),
      { staleTime: STALE_TIME },
    );
  };

  const getDataSetMergeTags = async () => {
    return queryClient.fetchQuery(
      GetDataSetMergeTagsByChecklistRevisionQuery.getKey({ checklistRevisionId }),
      () => GetDataSetMergeTagsByChecklistRevisionQuery.queryFn({ checklistRevisionId }),
      { staleTime: STALE_TIME },
    );
  };

  return {
    getChecklistRevision,
    getChecklist,
    getCurrentUser,
    getFormFieldValues,
    getOrganization,
    getOrganizationMemberships,
    getTask,
    getTaskTemplate,
    getTemplate,
    getUserById,
    getEmailMergeTags,
    getDataSetMergeTags,
  };
}
