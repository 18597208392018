interface Progress {
  value: number;
  type: '' | 'danger';
}

const calculate = (numerator: number, denominator: number): Progress => {
  if (denominator > 0) {
    return {
      value: Math.trunc((numerator / denominator) * 100),
      type: numerator >= denominator ? 'danger' : '',
    };
  } else {
    return { value: 0, type: '' };
  }
};

export const ProgressService = {
  calculate,
};
