import { INBOX_GET_ALL_BY_ORGANIZATION_ID } from 'reducers/inbox/inbox.actions';
import { handleActionsOnSuccess } from 'reducers/util';

const normalizeItem = item => {
  const { itemType, checklist, task } = item;
  const normalizedItem = { itemType };
  if (checklist) {
    normalizedItem.checklist = { id: checklist.id, dueDate: checklist.dueDate };
  }
  if (task) {
    normalizedItem.task = { id: task.id, dueDate: task.dueDate };
  }
  return normalizedItem;
};

const userOrgInboxReducer = handleActionsOnSuccess(
  {
    [INBOX_GET_ALL_BY_ORGANIZATION_ID]: (state = [], { payload: items }) => {
      const normalizedItems = items.map(normalizeItem);
      return state.concat(normalizedItems).filter((v, i, a) => a.indexOf(v) === i);
    },
  },
  [],
);

const userInboxReducer = handleActionsOnSuccess(
  {
    [INBOX_GET_ALL_BY_ORGANIZATION_ID]: (state = {}, action) => {
      const { organizationId } = action.meta;
      return { ...state, [organizationId]: userOrgInboxReducer(state[organizationId], action) };
    },
  },
  {},
);
/**
 * Currently inbox reducer is used only to present latest HTTP request to the server
 * It is supposed to change in future to be more efficient state presentation.
 */
export const inboxReducer = handleActionsOnSuccess(
  {
    [INBOX_GET_ALL_BY_ORGANIZATION_ID]: (state, action) => {
      const {
        meta: {
          filters: { userId },
        },
      } = action;
      const newUserState = userInboxReducer(state[userId], action);
      return { ...state, [userId]: newUserState };
    },
  },
  {},
);
