import * as React from 'react';
import { Icon, IconProps, Tooltip } from 'components/design/next';

export const UnsavedChangesIcon: React.FC<React.PropsWithChildren<Partial<IconProps>>> = props => {
  return (
    <Tooltip label={'Invalid values have not been saved.'} shouldWrapChildren hasArrow>
      <Icon icon="exclamation-triangle" variant="far" color="orange.400" size="4" {...props} />
    </Tooltip>
  );
};
