import * as React from 'react';
import { useBoolean } from 'components/design/next';

type SetBool = ReturnType<typeof useBoolean> extends readonly [boolean, infer Callbacks] ? Callbacks : never;

export type UseChartBoxResult = {
  isExpanded: boolean;
  setIsExpanded: SetBool;
  chartName: string;
  isEmpty: boolean;
  setIsEmpty: SetBool;
};

export const useChartBox = ({ chartName }: { chartName: string }): UseChartBoxResult => {
  const [isExpanded, setIsExpanded] = useBoolean();
  const [isEmpty, setIsEmpty] = useBoolean();
  const value = React.useMemo<UseChartBoxResult>(() => {
    return { isExpanded, setIsExpanded, chartName, isEmpty, setIsEmpty };
  }, [chartName, isEmpty, isExpanded, setIsEmpty, setIsExpanded]);
  return value;
};
