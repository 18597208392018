import * as React from 'react';
import { Text, Icon, VStack, Box } from 'components/design/next';
import { UniversalNewMenu } from 'features/new-menu/component';

interface EmptyFolderProps {
  isPrivateLibrary?: boolean;
}

export const EmptyFolder: React.FC<React.PropsWithChildren<EmptyFolderProps>> = ({ isPrivateLibrary = false }) => {
  return (
    <VStack spacing="6" mt="26">
      <Icon
        aria-label="folder"
        icon="folder"
        variant="fad"
        size="16"
        primaryLayer={{ color: 'gray.400' }}
        secondaryLayer={{ color: 'gray.500' }}
      />
      <Box textAlign="center">
        <Text variant="3" color="gray.700" fontWeight="bold" mb="4">
          There's nothing here yet.
        </Text>

        {isPrivateLibrary ? (
          <Text color="gray.600">
            Create a new private workflow, page, or form. Only you will be able to see this content.
          </Text>
        ) : (
          <Text color="gray.600">Move existing workflows, pages, and forms here, or create a new one!</Text>
        )}
      </Box>

      <UniversalNewMenu width="auto" size="lg" shouldHideOneOffTask={true} />
    </VStack>
  );
};
