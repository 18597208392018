import {
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Text,
  Icon,
  ModalOverlay,
  Modal,
  ModalProps,
  List,
  ListItem,
  HStack,
  ButtonGroup,
  Link,
  ModalFooterProps,
  StackProps,
  ModalHeaderProps,
  TextProps,
  ListProps,
  ListItemProps,
  ModalBodyProps,
  ButtonProps,
  ButtonGroupProps,
  CenterProps,
  Center,
  LinkProps,
  DividerProps,
  Divider,
  Box,
  BoxProps,
} from 'components/design/next';
import { IconProps } from 'components/design/next/icon';
import * as React from 'react';
import { Flex } from 'components/design/next';
import { useFeatureSet } from 'components/paywalls/use-feature-set';
import { useRoleNames } from 'hooks/use-role-names';

const CheckIcon = <Icon role="presentation" icon="check" color="green.500" size="4" mr="2" />;

export const PaywallModal: React.FC<React.PropsWithChildren<Partial<ModalProps>>> = ({ children, ...props }) => (
  <Modal size="xl" isOpen={true} onClose={() => {}} trapFocus={false} {...props}>
    <ModalOverlay />
    <ModalContent>{children}</ModalContent>
  </Modal>
);

export const PaywallGoBack: React.FC<React.PropsWithChildren<{ onClick: () => void } & StackProps>> = ({
  onClick,
  ...props
}) => {
  return (
    <HStack
      display="inline-flex"
      role="link"
      spacing="2"
      as={Link}
      onClick={onClick}
      color="gray.500"
      _hover={{ color: 'gray.600' }}
      {...props}
    >
      <Icon icon="arrow-left" variant="far" size="4" />
      <Text fontWeight="medium">Go Back</Text>
    </HStack>
  );
};

export const PaywallCloseButton: React.FC<React.PropsWithChildren<Pick<ButtonProps, 'onClick'> & StackProps>> = ({
  onClick,
  ...props
}) => {
  return (
    <HStack
      role="button"
      aria-label="close modal"
      justifyContent="flex-end"
      spacing="2"
      onClick={onClick}
      color="gray.500"
      _hover={{ color: 'gray.600' }}
      cursor="pointer"
      {...props}
    >
      <Icon icon="times" size="5" variant="far" />
    </HStack>
  );
};

export const PaywallHeaderWithIcon: React.FC<React.PropsWithChildren<ModalHeaderProps>> = ({ children, ...props }) => {
  return (
    <ModalHeader pt="8" px="8" pb="16" {...props}>
      {children}
    </ModalHeader>
  );
};

export const PaywallHeader: React.FC<React.PropsWithChildren<ModalHeaderProps>> = ({ children, ...props }) => {
  return (
    <ModalHeader pt="8" px="8" {...props}>
      {children}
    </ModalHeader>
  );
};

export const PaywallAdminHeaderIcon: React.FC<React.PropsWithChildren<CenterProps>> = ({ children, ...props }) => {
  return (
    <Center mt="4" {...props}>
      {children}
    </Center>
  );
};

export const PaywallBodyAdminHeading: React.FC<React.PropsWithChildren<TextProps>> = ({ children, ...props }) => {
  return (
    <Text variant="3" fontWeight="bold" color="gray.700" mb="2" {...props}>
      {children}
    </Text>
  );
};

export const PaywallBodyHeading: React.FC<React.PropsWithChildren<TextProps>> = ({ children, ...props }) => {
  return (
    <PaywallBodyAdminHeading mb="4" {...props}>
      {children}
    </PaywallBodyAdminHeading>
  );
};

export const PaywallAdminBodyDescription: React.FC<React.PropsWithChildren<TextProps>> = ({ children, ...props }) => {
  return (
    <Text color="gray.700" fontWeight="medium" {...props}>
      {children}
    </Text>
  );
};

export const PaywallBodyDescription: React.FC<React.PropsWithChildren<TextProps>> = ({ children, ...props }) => {
  return (
    <Text color="gray.600" {...props}>
      {children}
    </Text>
  );
};

export const PaywallBodyDescriptionEmphasis: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  ...props
}) => {
  return (
    <Text as="span" fontWeight="bold" {...props}>
      {children}
    </Text>
  );
};

export const PaywallAdminFeaturesPrefix: React.FC<React.PropsWithChildren<TextProps>> = ({ children, ...props }) => {
  return (
    <Text color="gray.700" fontWeight="medium" {...props}>
      {children}
    </Text>
  );
};

export const PaywallFeaturesList: React.FC<React.PropsWithChildren<ListProps>> = ({ children, ...props }) => {
  return (
    <List mx="auto" pt="4" my="0" display="inline-block" color="gray.600" textAlign="left" spacing="2" {...props}>
      {children}
    </List>
  );
};

export const PaywallFeaturesListItem: React.FC<React.PropsWithChildren<ListItemProps>> = ({ children, ...props }) => {
  return (
    <ListItem {...props}>
      {CheckIcon}
      {children}
    </ListItem>
  );
};

export const PaywallFeatures: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { usersLimit } = useFeatureSet();
  const roleNames = useRoleNames();

  return (
    <PaywallFeaturesList>
      <PaywallFeaturesListItem>Create unlimited workflows</PaywallFeaturesListItem>
      <PaywallFeaturesListItem>Run unlimited workflows</PaywallFeaturesListItem>
      {usersLimit === 1 ? (
        <PaywallFeaturesListItem>Add more than 1 {roleNames.FullMember.single}</PaywallFeaturesListItem>
      ) : (
        <PaywallFeaturesListItem>
          Add more than {usersLimit} {roleNames.FullMember.plural}
        </PaywallFeaturesListItem>
      )}
    </PaywallFeaturesList>
  );
};

export const PaywallAdminFinePrint: React.FC<React.PropsWithChildren<TextProps>> = ({ children, ...props }) => {
  return (
    <Text color="gray.500" variant="-2" {...props}>
      {children}
    </Text>
  );
};

export const PaywallAdminFinePrintLink: React.FC<React.PropsWithChildren<LinkProps>> = ({ children, ...props }) => {
  return (
    <Link color="brand.500" {...props}>
      {children}
    </Link>
  );
};

export const PaywallLink: React.FC<React.PropsWithChildren<LinkProps>> = ({ children, ...props }) => {
  return <Link {...props}>{children}</Link>;
};

export const PaywallBodyNoIcon: React.FC<React.PropsWithChildren<ModalBodyProps>> = ({ children, ...props }) => {
  return (
    <ModalBody textAlign="center" py="10" {...props}>
      {children}
    </ModalBody>
  );
};

export const PaywallBodyWithIcon: React.FC<React.PropsWithChildren<ModalBodyProps>> = ({ children, ...props }) => {
  return (
    <ModalBody textAlign="center" pt="4" pb="10" px="7" {...props}>
      {children}
    </ModalBody>
  );
};

export const InboxPaywallBodyWithIcon: React.FC<React.PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box textAlign="center" pt="4" pb="10" px="7" {...props}>
      {children}
    </Box>
  );
};

export const PaywallFooter: React.FC<React.PropsWithChildren<ModalFooterProps>> = ({ children, ...props }) => {
  return (
    <ModalFooter justifyContent="center" py="6" {...props} flexDirection="column">
      {children}
    </ModalFooter>
  );
};

export const InboxPaywallFooter: React.FC<React.PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Flex justifyContent="center" {...props} flexDirection="column">
      {children}
    </Flex>
  );
};

export const PaywallPrimaryActionLinkButton: React.FC<
  React.PropsWithChildren<React.HTMLProps<HTMLAnchorElement> & { buttonProps?: ButtonProps }>
> = ({ children, buttonProps = {}, ...props }) => {
  return (
    <a {...props}>
      <Button variant="primary" size="lg" {...buttonProps}>
        {children}
      </Button>
    </a>
  );
};

export const PaywallSecondaryActionLinkButton: React.FC<
  React.PropsWithChildren<React.HTMLProps<HTMLAnchorElement> & { buttonProps?: ButtonProps }>
> = ({ children, buttonProps = {}, ...props }) => {
  return (
    <a target="_blank" rel="noreferrer" {...props}>
      <Button as="span" variant="secondary" size="lg" {...buttonProps}>
        {children}
      </Button>
    </a>
  );
};

export const PaywallSecondaryActionButton: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => {
  return (
    <Button variant="secondary" size="lg" {...props}>
      {children}
    </Button>
  );
};

export const PaywallButtonGroup: React.FC<React.PropsWithChildren<ButtonGroupProps>> = ({ children, ...props }) => {
  return (
    <ButtonGroup spacing="2" {...props}>
      {children}
    </ButtonGroup>
  );
};

export const PaywallDivider: React.FC<React.PropsWithChildren<DividerProps>> = props => <Divider {...props} />;

export const PaywallAdminIconChecklist: React.FC<React.PropsWithChildren<Partial<IconProps>>> = ({ ...props }) => (
  <Icon
    icon="workflow-run"
    variant="fad"
    primaryLayer={{ color: 'brand.500' }}
    secondaryLayer={{ color: 'brand.500', opacity: 0.4 }}
    size="22"
    {...props}
  />
);

export const PaywallAdminIconUserPlus: React.FC<React.PropsWithChildren<Partial<IconProps>>> = ({ ...props }) => (
  <Icon
    icon="user-plus"
    variant="fad"
    primaryLayer={{ color: 'brand.500', opacity: 0.4 }}
    secondaryLayer={{ color: 'brand.500', opacity: 1 }}
    size="26"
    {...props}
  />
);

export const PaywallAdminIconUserLock: React.FC<React.PropsWithChildren<Partial<IconProps>>> = ({ ...props }) => (
  <Icon
    variant="fad"
    icon="user-lock"
    primaryLayer={{ color: 'red.500', opacity: 0.4 }}
    secondaryLayer={{ color: 'red.500', opacity: 1 }}
    size="14"
    mb="4"
    {...props}
  />
);

export const PaywallAdminIconTemplate: React.FC<React.PropsWithChildren<Partial<IconProps>>> = ({ ...props }) => (
  <Icon
    icon="workflow"
    variant="fad"
    primaryLayer={{ color: 'indigo.500' }}
    secondaryLayer={{ color: 'indigo.500', opacity: 0.4 }}
    size="22"
    {...props}
  />
);

export const PaywallAdminIconStars: React.FC<React.PropsWithChildren<Partial<IconProps>>> = ({ ...props }) => (
  <Icon
    icon="stars"
    variant="fad"
    primaryLayer={{ color: 'brand.500' }}
    secondaryLayer={{ color: 'brand.500', opacity: 0.4 }}
    size="22"
    {...props}
  />
);

export const PaywallIconChecklist: React.FC<React.PropsWithChildren<Partial<IconProps>>> = ({ ...props }) => (
  <Icon
    variant="fad"
    icon="workflow-run"
    primaryLayer={{ color: 'red.500' }}
    secondaryLayer={{ color: 'red.500', opacity: 0.4 }}
    size="12"
    mb="4"
    {...props}
  />
);

export const PaywallIconTemplate: React.FC<React.PropsWithChildren<Partial<IconProps>>> = ({ ...props }) => (
  <Icon
    icon="workflow"
    variant="fad"
    primaryLayer={{ color: 'red.500' }}
    secondaryLayer={{ color: 'red.500', opacity: 0.4 }}
    size="12"
    mb="4"
    {...props}
  />
);

export const PaywallIconUserLock: React.FC<React.PropsWithChildren<Partial<IconProps>>> = ({ ...props }) => (
  <Icon
    variant="fad"
    icon="user-lock"
    primaryLayer={{ color: 'red.500', opacity: 0.4 }}
    secondaryLayer={{ color: 'red.500', opacity: 1 }}
    size="14"
    mb="4"
    {...props}
  />
);

export const PaywallIconUsers: React.FC<React.PropsWithChildren<Partial<IconProps>>> = ({ ...props }) => (
  <Icon
    icon="users"
    variant="fad"
    primaryLayer={{ color: 'red.500' }}
    secondaryLayer={{ color: 'red.500', opacity: 0.4 }}
    size="14"
    mb="4"
    {...props}
  />
);

export const PaywallIconUserPlus: React.FC<React.PropsWithChildren<Partial<IconProps>>> = ({ ...props }) => (
  <Icon
    icon="user-plus"
    variant="fad"
    primaryLayer={{ color: 'red.500', opacity: 0.4 }}
    secondaryLayer={{ color: 'red.500', opacity: 1 }}
    size="14"
    {...props}
  />
);
