import { BlvdSearch } from 'components/design/BlvdSearch';
import React from 'react';
import styles from './ChecklistDashboardSearchFilter.module.scss';

export interface ChecklistDashboardSearchFilterProps {
  value: string;
  onFilter: (value: string) => void;
}

export const ChecklistDashboardSearchFilter: React.FunctionComponent<
  React.PropsWithChildren<ChecklistDashboardSearchFilterProps>
> = ({ value, onFilter }) => (
  <div className={styles.checklistDashboardSearchFilter}>
    <BlvdSearch value={value} onChange={onFilter} />
  </div>
);
