import { useInjector } from 'components/injection-provider';
import { getPathFolders, sanitizePathParam } from 'features/folder/lib';
import { useAllFoldersByOrganizationQuery } from 'features/folder/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

export const useFoldersPathQuery = () => {
  const { $stateParams } = useInjector('$stateParams');
  const path = $stateParams.path ? sanitizePathParam($stateParams.path) : 'home';
  const orgId = useSelector(SessionSelector.getSelectedOrganizationId);
  return useAllFoldersByOrganizationQuery(orgId!, {
    enabled: Boolean(orgId),
    select: folders => getPathFolders(path, folders),
  });
};
