import { ICellRendererParams } from '@ag-grid-community/core';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import { GridHelper } from 'components/dashboard/services/grid-helper';
import { Box, Tooltip } from 'components/design/next';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

interface ActivityRendererProps extends Partial<ICellRendererParams> {
  value?: Date;
}

const ActivitySkeleton = ({ rowIndex }: { rowIndex: number }) => {
  const sizes = ['8', '9', '16', '12', '20'];

  return <Box w={sizes[rowIndex % sizes.length]} h="3" bgColor="gray.100" borderRadius="xs" />;
};

export const ActivityRenderer: React.FC<React.PropsWithChildren<ActivityRendererProps>> = ({
  value,
  colDef,
  rowIndex,
  data,
}) => {
  const currentUser = useSelector(SessionSelector.getCurrentUser);

  if (!value && GridHelper.isEmptyStateRow(data)) return <ActivitySkeleton rowIndex={rowIndex ?? 0} />;

  if (!value || !currentUser) {
    return <div />;
  }

  const cell = DateUtils.formatDateFromNow(value);
  const tooltip = DateUtils.formatDateTime(value, DateFormat.Default, currentUser.timeZone);

  return (
    <Tooltip id={`checklist-${rowIndex}-${colDef?.colId ?? 'unknown-column'}`} label={tooltip} hasArrow>
      <div className="truncate-text">{cell}</div>
    </Tooltip>
  );
};
