import { User } from '@process-street/subgrade/core';
import { Checklist, Template } from '@process-street/subgrade/process';
import { TemplateOverview } from '@process-street/subgrade/process/template-overview-model';

export enum CreateChecklistSource {
  MAIN_NAV_BAR = 'Main navigation',
  REPORTS_ROW = 'Reports row',
}

export interface ChecklistService {
  createAndRedirect: (
    template: Template | TemplateOverview,
    name: string,
    user: User,
    isNew?: boolean,
  ) => angular.IPromise<angular.IHttpPromiseCallbackArg<Record<string, unknown>>>;

  resolveChecklistName: (currentUser: User, queryParams?: Record<string, unknown>) => string;

  clearAndRedirect: (checklist: Checklist) => void;

  createWithPaymentRequiredCheck: (params: {
    template: Template | TemplateOverview;
    name: string;
    user: User;
    dueDate?: number;
    autoAssign?: boolean;
  }) => angular.IPromise<Checklist>;
}
