import { SelectionProvider } from 'pages/pages/_id/edit/page/selection-context';
import {
  BalloonToolbarControlsContext,
  ForcedState,
} from 'pages/pages/_id/edit/page/components/balloon-toolbar/context';
import { BalloonToolbar, ColorButton, HeadersButton, MarksButtons } from 'pages/pages/_id/edit/page/components';
import { LinkButton } from 'features/widgets/components/send-rich-email/template/editor/link-button';
import { ReadOnlyCopyWrapper } from 'pages/pages/_id/edit/page/components/read-only-copy-wrapper';
import { Box, InputGroup, InputRightElement } from 'components/design/next';
import * as React from 'react';
import {
  SendRichEmailFormFieldConfig,
  SendRichEmailFormFieldWidget,
  TaskTemplate,
  TemplateRevision,
} from '@process-street/subgrade/process';
import { MergeTagMenuWrapper } from './MergeTagMenuWrapper';
import { SendRichEmailEditorMode } from 'app/features/widgets/components/send-rich-email/template/editor/send-rich-email-template-editor';
import { MutationStatus } from 'react-query';
import { SendRichEmailAiGenerateMenu } from 'features/widgets/components/ai/ai-generate-send-email-content-menu';
import { EditorUtils } from 'features/widgets/components/send-rich-email/template/editor/editor-utils';
import { SendRichEmailEditor } from './send-rich-email-plate-types';

export type EditableRendererProps = {
  balloonToolbarContext: {
    setForcedState: React.Dispatch<React.SetStateAction<ForcedState>>;
    forcedState: ForcedState;
  };
  editableNode: React.ReactNode;
  readOnly?: boolean;
  templateRevisionId: TemplateRevision['id'];
  widget: SendRichEmailFormFieldWidget;
  isAiGeneratingContent?: boolean;
  onContentGeneration: (config: SendRichEmailFormFieldConfig) => void;
  onContentGenerationStatusChange?: (status: MutationStatus) => void;
  mode: SendRichEmailEditorMode;
  editor: SendRichEmailEditor;
  taskTemplate?: TaskTemplate;
};

export const EditableRenderer: React.FC<React.PropsWithChildren<EditableRendererProps>> = ({
  balloonToolbarContext,
  editableNode,
  mode,
  onContentGeneration,
  onContentGenerationStatusChange,
  readOnly,
  templateRevisionId,
  isAiGeneratingContent,
  widget,
  editor,
  taskTemplate,
}) => (
  <SelectionProvider>
    <BalloonToolbarControlsContext.Provider value={balloonToolbarContext}>
      <BalloonToolbar>
        <HeadersButton />
        <MarksButtons />
        <ColorButton variant="color" />
        <ColorButton variant="background" />
        <LinkButton {...{ templateRevisionId }} />
      </BalloonToolbar>

      {!readOnly && (
        <InputGroup w="auto">
          <InputRightElement top="-px" pr="1" transform="translateY(-100%)" bg="transparent" w="auto" h="8">
            <MergeTagMenuWrapper
              templateRevisionId={templateRevisionId}
              isRichText={widget.config.emailFormat === 'RichTextOrHtml'}
            />
            {mode === 'Template' && (
              <SendRichEmailAiGenerateMenu
                widget={widget}
                onContentGeneration={onContentGeneration}
                onContentGenerationStatusChange={onContentGenerationStatusChange}
                getWidgetContent={() => EditorUtils.getEditorContent(editor)}
                getWidgetSelection={() => EditorUtils.getEditorSelection(editor)}
                taskTemplate={taskTemplate}
              />
            )}
          </InputRightElement>
        </InputGroup>
      )}

      <ReadOnlyCopyWrapper>
        <Box
          height="100%"
          color="gray.600"
          overflowY="auto"
          p="4"
          wordBreak="break-word"
          bgColor={isAiGeneratingContent ? 'gray.100' : undefined}
          sx={{
            '[data-slate-editor="true"]': {
              outline: 'none',
            },
          }}
        >
          {isAiGeneratingContent ? (
            <Box mt="5" opacity="0.3">
              Draft being generated by AI
            </Box>
          ) : (
            editableNode
          )}
        </Box>
      </ReadOnlyCopyWrapper>
    </BalloonToolbarControlsContext.Provider>
  </SelectionProvider>
);
