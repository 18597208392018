import { OrganizationMembershipRole } from '@process-street/subgrade/core';

export type InviteConfig = {
  defaultRole: OrganizationMembershipRole | 'match-inviter';
  alternateRole?: OrganizationMembershipRole;
  checkboxLabel?: string;
  checkboxWidth?: number;
};

export type InviteButtonConfig = {
  text: string;
  width: number;
};

export const InviteButtonConfigs: Record<string, InviteButtonConfig> = {
  INVITE: {
    text: 'Invite',
    width: 28,
  },
  INVITE_AND_SHARE: {
    text: 'Invite & Share',
    width: 37,
  },
};

// Constrained identity function: enforces values and infers keys
function constrainer<Rules extends Record<string, InviteConfig>>(rules: Rules) {
  return rules;
}

const InviteAsFreeMember: Pick<InviteConfig, 'checkboxLabel' | 'checkboxWidth'> = {
  checkboxLabel: 'Invite as Free Member',
  checkboxWidth: 42,
};

const InviteAsGuest: Pick<InviteConfig, 'checkboxLabel' | 'checkboxWidth'> = {
  checkboxLabel: 'Invite as guest',
  checkboxWidth: 32,
};

// https://miro.com/app/board/o9J_lKWwK0U=/?moveToWidget=3074457357926325559&cot=14
export const Rules = constrainer({
  fullMemberOrGuest: {
    ...InviteAsGuest,
    defaultRole: OrganizationMembershipRole.FullMember,
    alternateRole: OrganizationMembershipRole.Guest,
  },
  fullMemberOrFreeMember: {
    ...InviteAsFreeMember,
    defaultRole: OrganizationMembershipRole.FullMember,
    alternateRole: OrganizationMembershipRole.FreeMember,
  },
  matchInviterOrFreeMember: {
    ...InviteAsFreeMember,
    defaultRole: 'match-inviter',
    alternateRole: OrganizationMembershipRole.FreeMember,
  },
  freeMember: {
    defaultRole: OrganizationMembershipRole.FreeMember,
  },
  fullMember: {
    defaultRole: OrganizationMembershipRole.FullMember,
  },
});
