import angular from 'angular';
import { ResourceFactory } from 'app/resources/factory/resource-factory';

export const Attachments = (resourceFactory: ResourceFactory) => {
  'ngInject';

  return resourceFactory.create({
    delete: 'DELETE /1/attachments/:id',
    get: 'GET /1/attachments/:id',
    query: 'GET[] /1/attachments',
  });
};

angular.module('frontStreetApp.services').factory('Attachments', ['ResourceFactory', Attachments]);
