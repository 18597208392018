import angular from 'angular';
import { AnalyticsConstants } from '@process-street/subgrade/analytics/analytics-constants';
import { TaskListEvent } from 'directives/task-list/task-list-event';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular.module('frontStreetApp.services').service('TaskListAnalyticsListener', function () {
  this.listen = function (scope) {
    scope.$on(TaskListEvent.STOPPED_TASKS_SHOWN_TOGGLED, (__event, stoppedTasksShown) => {
      const action = stoppedTasksShown
        ? AnalyticsConstants.Event.TASK_LIST_STOPPED_TASKS_SHOWN
        : AnalyticsConstants.Event.TASK_LIST_STOPPED_TASKS_HIDDEN;

      AnalyticsService.trackEvent(action);
    });
  };
});
