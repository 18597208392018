import { createSelector, Selector } from 'reselect';
import { Muid, Option } from '../../core';
import { FormFieldValue, FormFieldValueWithWidgetMap, FormFieldWidget } from '../../process';
import { safeEntityMapToArrayByIdsWith } from '../safe-entity-map-to-array-by-ids';
import { BaseReduxState, EntityMap, ImmutableLookupMap } from '../types';
import { BaseTaskSelector } from './task.selectors';
import { BaseWidgetSelector } from './widget.selectors';

const getById =
  (valueId: Muid): Selector<BaseReduxState, Option<FormFieldValue>> =>
  (state: BaseReduxState): Option<FormFieldValue> =>
    state.entities.formFieldValue[valueId];

const getEntityMap: Selector<BaseReduxState, EntityMap<FormFieldValue>> = (state: BaseReduxState) =>
  state.entities.formFieldValue;

const getLookupByChecklistRevisionId: Selector<BaseReduxState, ImmutableLookupMap> = (state: BaseReduxState) =>
  state.lookups.formFieldValue.byChecklistRevisionId;

const getAllByChecklistRevisionId = (revisionId: Muid): Selector<BaseReduxState, FormFieldValue[]> =>
  createSelector(
    getLookupByChecklistRevisionId,
    getEntityMap,
    (formFieldValuesLookup: ImmutableLookupMap, formFieldValues: EntityMap<FormFieldValue>) =>
      safeEntityMapToArrayByIdsWith(formFieldValues, formFieldValuesLookup[revisionId] ?? []),
  );

const getAllByTaskId =
  (taskId: Muid) =>
  (state: BaseReduxState): FormFieldValue[] => {
    const task = BaseTaskSelector.getById(taskId)(state);
    if (!task) {
      return [];
    }
    const values = getAllByChecklistRevisionId(task.checklistRevision.id)(state);
    return values.filter(value => {
      const formFieldWidget = BaseWidgetSelector.getById(value.formFieldWidget.id)(state);
      if (!formFieldWidget) {
        return false;
      }
      return formFieldWidget.header.taskTemplate.id === task.taskTemplate.id;
    });
  };

const getFormFieldValueMapByChecklistRevisionId = (
  revisionId: Muid,
): Selector<BaseReduxState, FormFieldValueWithWidgetMap> =>
  createSelector(
    [getAllByChecklistRevisionId(revisionId), BaseWidgetSelector.getEntityMap],
    (fieldValues, widgetsMap) => {
      return fieldValues.reduce((agg: FormFieldValueWithWidgetMap, fieldValue) => {
        const formFieldWidget = widgetsMap[fieldValue.formFieldWidget.id] as Option<FormFieldWidget>;
        if (formFieldWidget) {
          agg[formFieldWidget.header.group.id] = { ...fieldValue, formFieldWidget };
        }
        return agg;
      }, {});
    },
  );

export const BaseFormFieldValueSelector = {
  getLookupByChecklistRevisionId,
  getAllByChecklistRevisionId,
  getAllByTaskId,
  getFormFieldValueMapByChecklistRevisionId,
  getById,
  getEntityMap,
};
