import { CoverIcon } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery as useRQ, useQueryClient, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { HttpStatus } from '@process-street/subgrade/util';
import { GetAllCoverIconsQuery } from 'features/cover-icon/query-builder/all-cover-icons';

export namespace GetCoverIconByTemplateId {
  export type Params = { templateId: Template['id'] };
  export type Response = CoverIcon | undefined;
  export const key = ['template', 'icon'];
  export const getKey = (params: Params): QueryKey => [...key, params];
  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/templates/${params.templateId}/cover-icon`, {
        validateStatus: status => {
          return status === HttpStatus.OK || status === HttpStatus.NOT_FOUND;
        },
      })
      .then(res => {
        if (res.status === HttpStatus.NOT_FOUND) {
          return undefined;
        } else {
          return res.data;
        }
      });
  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    const queryClient = useQueryClient();
    return useRQ(getKey(params), () => queryFn(params), {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      initialDataUpdatedAt: () => {
        return queryClient.getQueryState(GetAllCoverIconsQuery.getKey())?.dataUpdatedAt;
      },
      ...options,
    });
  };
}
