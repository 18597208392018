import { escapeHtml } from '@process-street/subgrade/util';
import { getTagsFromKeys } from './get-tags-from-keys';
import { getAllTextNodesAsSpans } from './get-all-text-nodes-as-spans';
import { replaceInTextSpansInPlace } from './replace-in-text-spans-in-place';
import { divToHtmlString } from './div-to-html-string';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';

export const EMPTY_MERGE_TAG_CLASS = 'empty-merge-tag';

export const EMPTY_MERGE_TAG_STYLES = [
  ['background-color', 'var(--ps-colors-brand-100)'],
  ['border-radius', '3px'],
  ['display', 'inline-block'],
  ['margin', '-1px 0'],
  ['overflow-wrap', 'anywhere'],
  ['padding', '1px 4px'],
]
  .map(keyval => keyval.join(': ').concat(';'))
  .join(' ');

export function injectMergeTagLabels(content: string, tags: ReturnType<typeof getTagsFromKeys>) {
  let value = content;
  const el = document.createElement('div');
  el.innerHTML = content;
  const textSpans = getAllTextNodesAsSpans(el);

  Object.entries(tags).forEach(([key, label]) => {
    const escapedLabel = `<span class='${EMPTY_MERGE_TAG_CLASS}' style='${EMPTY_MERGE_TAG_STYLES}'>${escapeHtml(
      label,
    )}</span>`;
    replaceInTextSpansInPlace(textSpans, key, escapedLabel);
  });

  value = divToHtmlString(el);

  return value;
}

const REMOVE_CHEVRONS_REGEXP = new RegExp(
  `[${MergeTagStringReplacementUtils.LEFT_CHEVRON}${MergeTagStringReplacementUtils.RIGHT_CHEVRON}]`,
  'g',
);

export function highlightUnknownMergeTags(content: string) {
  const escapedContent = escapeHtml(content);
  const unknownTags = Array.from(escapedContent.match(MergeTagStringReplacementUtils.CHEVRONS_REGEXP) ?? []);
  const el = document.createElement('div');

  el.innerHTML = unknownTags.reduce((acc, tag) => {
    const labelWithoutChevrons = tag.replace(REMOVE_CHEVRONS_REGEXP, '');
    const label = `<span class='${EMPTY_MERGE_TAG_CLASS}' style='${EMPTY_MERGE_TAG_STYLES}'>${labelWithoutChevrons}</span>`;

    return acc.replace(tag, label);
  }, escapedContent);

  return divToHtmlString(el);
}
