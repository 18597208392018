import { Button } from 'components/design/next';
import { Icon } from 'components/design/next';
import * as React from 'react';
import { QueryObserverResult } from 'react-query';

export type DeleteSavedViewButtonProps = {
  onDelete: () => void;
  status?: QueryObserverResult['status'];
  isDisabled?: boolean;
};

export const DeleteSavedViewButton: React.FC<React.PropsWithChildren<DeleteSavedViewButtonProps>> = ({
  onDelete,
  isDisabled,
  status,
}) => {
  const isLoading = status === 'loading';

  return (
    <Button
      color="red.500"
      leftIcon={<Icon icon="trash-can" size="4" color="red.500" />}
      px="0"
      fontWeight="500"
      variant="ghost"
      colorScheme="red"
      onClick={onDelete}
      loadingText="Deleting Saved View..."
      isLoading={isLoading}
      isDisabled={isDisabled}
    >
      Delete Saved View
    </Button>
  );
};
