import angular from 'angular';
import { EventName } from 'services/event-name';

angular.module('frontStreetApp.services').service('GrowSumoListener', function ($rootScope, GrowSumoService) {
  this.listen = function () {
    $rootScope.$on(EventName.ORGANIZATION_CREATED, (__event, data) => {
      GrowSumoService.signUp(data.organization);
    });
  };
});
