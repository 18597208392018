import * as React from 'react';
import { FeatureItem } from 'components/billing/FeatureItem';
import { Features, PlanFeatureMap, Feature } from 'components/billing/plans/features';
import { Box, Icon, Text } from 'components/design/next';

interface PlanFeatureListProps {
  featureMap: PlanFeatureMap;
}

type FeatureProps = { feature: Feature; featureValue: PlanFeatureMap[Feature] };

const OnDesktop: React.FC<React.PropsWithChildren<FeatureProps>> = ({ feature, featureValue }) => {
  const item = (() => {
    switch (featureValue) {
      case true:
        return <Icon size="4" icon="check" variant="far" color="gray.600" />;
      case false:
        return <Icon size="4" icon="times" variant="far" color="gray.300" />;

      default:
        return (
          <Text display="inline-block" color="gray.500" fontSize="xs">
            {featureValue.desktop}
          </Text>
        );
    }
  })();

  const height = typeof featureValue !== 'boolean' || feature === 'SingleSignOn' ? 12 : 8;

  return <Box height={height}>{item}</Box>;
};

const OnMobile: React.FC<React.PropsWithChildren<FeatureProps>> = ({ feature, featureValue }) => {
  const item = (() => {
    if (typeof featureValue === 'boolean') {
      const { name } = Features[feature];
      return <FeatureItem checked={featureValue} name={name} />;
    }

    return <FeatureItem checked={true} name={featureValue.mobile} />;
  })();

  return (
    // using a classname here because the container spacing is more chakra work than it's worth right now
    <Box className="col-xs-6" minHeight="8" maxHeight="10">
      {item}
    </Box>
  );
};

const mobileDisplaySequence = ['block', null, 'none'];
export const PlanFeatureList: React.FunctionComponent<React.PropsWithChildren<PlanFeatureListProps>> = ({
  featureMap,
}) => {
  return (
    <Box textAlign="center">
      <Box display={mobileDisplaySequence.slice().reverse()}>
        {Object.entries(featureMap).map(([feature, featureValue]) => (
          <OnDesktop key={feature} feature={feature as Feature} featureValue={featureValue} />
        ))}
      </Box>

      <Box
        // using a classname here because I can't find the source for this style so assuming library
        className="row"
        display={mobileDisplaySequence}
        color="gray.500"
        fontSize="xs"
        px="4"
        py="2"
        textAlign="left"
      >
        {Object.entries(featureMap).map(([feature, featureValue]) => (
          <OnMobile key={feature} feature={feature as Feature} featureValue={featureValue} />
        ))}
      </Box>
    </Box>
  );
};
