import { StringUtils } from '@process-street/subgrade/util';

/** Convert a get/set object for a separatable string */
export function toGetSetForSeparatableString({
  separator = ',',
  ...opts
}: {
  get: () => string;
  set: (value: string) => void;
  separator?: string;
}): { get: () => string; set: (merged: string) => void } {
  const { tail, head } = StringUtils.getReversedHeadAndTail({ value: opts.get(), separator });
  return {
    get: () => head,
    set: (value: string) => {
      opts.set([...tail, value].join(separator));
    },
  };
}
