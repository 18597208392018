import { entitiesSelector } from 'reducers/entities/entities.selectors';
import { Muid } from '@process-street/subgrade/core';
import { ReduxAppState } from 'reducers/types';

export const folderPermitsSelector = (state: ReduxAppState) => entitiesSelector(state).folderPermits;
export const folderPermitByIdSelector = (permitId: Muid) => (state: ReduxAppState) =>
  folderPermitsSelector(state)[permitId];

export const FolderPermitSelector = {
  getEntityMap: folderPermitsSelector,
};
