export const DataServiceConstants = {
  PLANS: 'plans',
  USERS: 'users',
  ORGANIZATIONS: 'organizations',
  ORGANIZATION_MEMBERSHIPS: 'organizationMemberships',
  GROUPS: 'groups',
  GROUP_MEMBERSHIPS: 'groupMemberships',
  FOLDERS: 'folders',
  TEMPLATES: 'templates',
  CHECKLISTS: 'checklists',
  CHECKLIST_ASSIGNMENTS: 'checklistAssignments',
  FOLDER_PERMITS: 'folderPermits',
  TEMPLATE_PERMITS: 'templatePermits',
  CHECKLIST_PERMITS: 'checklistPermits',
};
