import { Icon } from 'app/components/design/next';
import { CustomIconName } from 'app/components/design/next/icon/icon-name';
import * as React from 'react';

const resolveIconName = (icon: string) => {
  const newIconName = icon.replace('fa-', '');
  return newIconName as CustomIconName;
};

export const WidgetIcon = ({ iconName, size = '3' }: { iconName?: string; size?: string }) => {
  if (!iconName) return null;
  return <Icon icon={resolveIconName(iconName)} size={size} />;
};
