import * as React from 'react';
import { UserTypeOption, PreviewerOption, getPreviewerType, SandboxUserType } from './utils';
import { Flex, HStack, Icon, Text } from 'components/design/next';
import { UserOption } from 'app/utils/user-option-transformer';
import { components, OptionProps } from 'react-select';
import { PreviewAvatarComponent } from './preview-avatar-component';

import { useCurrentUser } from 'app/hooks/use-current-user';
import { SandboxInfoTooltip } from '../sandbox-info-tooltip';
import { UserType } from '@process-street/subgrade/core';
import { match } from 'ts-pattern';
import { AssignmentType, RoleOption } from './role-option-transformer';
import { getUserTypeContent } from './custom-dropdown-indicator';

export const PreviewAsOption = (props: OptionProps<PreviewerOption, false> & { data: PreviewerOption }) => {
  const currentUser = useCurrentUser();
  const previewerTypeOption = getPreviewerType(props.data);
  return match(previewerTypeOption)
    .with(SandboxUserType.User, () => {
      const { user } = props.data as UserOption;
      const isEmailVisible = user.userType === UserType.Standard;
      return (
        <components.Option {...props}>
          <PreviewAvatarComponent user={user} isEmailVisible={isEmailVisible} />
        </components.Option>
      );
    })
    .with(SandboxUserType.UserType, () => {
      const { tooltipText, helpUrl } = props.data as UserTypeOption;
      const optionContent = getUserTypeContent({ userType: props.data as UserTypeOption, currentUser });

      return (
        <components.Option {...props}>
          <HStack gap={1} color="black">
            {optionContent}
            {tooltipText && helpUrl && <SandboxInfoTooltip label={tooltipText} link={helpUrl} iconColor="gray.400" />}
          </HStack>
        </components.Option>
      );
    })
    .with(SandboxUserType.Role, () => {
      const { name, assignmentType } = props.data as RoleOption;
      return (
        <components.Option {...props}>
          <HStack gap={1}>
            <Flex bgColor="green.500" borderRadius="full" w={6} h={6} alignItems="center" justifyContent="center">
              <Icon
                size="4"
                icon={assignmentType === AssignmentType.Members ? 'users' : 'envelope'}
                variant="fas"
                color="white"
              />
            </Flex>
            <Text>{name}</Text>
          </HStack>
        </components.Option>
      );
    })
    .otherwise(() => null);
};
