import { Runnable } from '@process-street/subgrade/conditional-logic';
import { Muid } from '@process-street/subgrade/core';
import {} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { QueryClient, QueryObserver, QueryObserverOptions, QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetAllRulesByChecklistRevisionIdQueryParams = {
  checklistRevisionId?: Muid;
};

export type GetAllRulesByChecklistRevisionIdQueryResponse = {
  definitions: Runnable.ChecklistRuleDefinition[];
};

const key = ['checklist-revisions', 'rule-definitions'];
export const GetAllRulesByChecklistRevisionIdQuery = {
  key,
  getKey: (params: GetAllRulesByChecklistRevisionIdQueryParams): QueryKey => [...key, params],
  queryFn: ({ checklistRevisionId }: GetAllRulesByChecklistRevisionIdQueryParams) =>
    axiosService
      .getAxios()
      .get<GetAllRulesByChecklistRevisionIdQueryResponse>(
        `/1/checklist-revisions/${checklistRevisionId}/rule-definitions`,
      )
      .then(res => res.data),
};

export const useGetAllRulesByChecklistRevisionIdQuery = <Select = GetAllRulesByChecklistRevisionIdQueryResponse>(
  params: GetAllRulesByChecklistRevisionIdQueryParams,
  options: UseQueryOptions<GetAllRulesByChecklistRevisionIdQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetAllRulesByChecklistRevisionIdQuery.getKey(params),
    () => GetAllRulesByChecklistRevisionIdQuery.queryFn(params),
    {
      ...options,
      enabled: Boolean(params.checklistRevisionId) && options?.enabled !== false,
    },
  );
};

export const makeGetAllRulesByChecklistRevisionIdQueryObserver = ({
  queryClient,
  options,
  ...params
}: GetAllRulesByChecklistRevisionIdQueryParams & {
  queryClient: QueryClient;
  options: QueryObserverOptions<GetAllRulesByChecklistRevisionIdQueryResponse, AxiosError>;
}) => {
  return new QueryObserver<GetAllRulesByChecklistRevisionIdQueryResponse, AxiosError>(queryClient, {
    queryKey: GetAllRulesByChecklistRevisionIdQuery.getKey(params),
    queryFn: () => GetAllRulesByChecklistRevisionIdQuery.queryFn(params),
    ...options,
    enabled: Boolean(params.checklistRevisionId) && options?.enabled !== false,
  });
};
