import { Option } from '@process-street/subgrade/core';
import { Template, TemplateRevision } from '@process-street/subgrade/process';
import { templateMigrationStatsService } from 'components/migration/services/template-migration-stats-service';
import { TemplateMigrationActions } from 'components/migration/store/template-migration.actions';
import { TemplateMigrationSelectors } from 'components/migration/store/template-migration.selector';
import templateUrl from 'components/migration/template/template-migration-progress.component.html';
import ngRedux from 'ng-redux';
import { MigrationStats, ReduxAppState } from 'reducers/types';
import { connectController } from 'reducers/util';
import './template-migration-progress.scss';

interface InternalState {
  stats: MigrationStats;
}

export class TemplateMigrationProgressController {
  public static $inject = ['$ngRedux', 'TemplateMigrationActions'];

  public actions: Option<TemplateMigrationActions>;
  public templateRevision: Option<TemplateRevision>;
  public template: Option<Template>;

  public countMigrated = 0;
  public countTotal = 0;
  public percentage = 0;

  private state: Option<InternalState>;

  constructor(private $ngRedux: ngRedux.INgRedux, private templateMigrationActions: TemplateMigrationActions) {}

  public $onChanges = (changes: angular.IOnChangesObject) => {
    const { template, templateRevision } = changes;

    if (template) {
      this.template = template.currentValue;
      this.connectWithReduxStoreAndInitializeState();
    }

    if (templateRevision) {
      const previousTemplateRevision = templateRevision.previousValue;

      // This is the case when another workflow is selected from dashboard
      if (previousTemplateRevision && !templateRevision.currentValue && !templateRevision.isFirstChange()) {
        this.actions!.stopPollerForTemplateRevisionId(
          previousTemplateRevision.template.id,
          previousTemplateRevision.id,
        );
      }

      this.templateRevision = templateRevision.currentValue;
      this.connectWithReduxStoreAndInitializeState();
    }
  };

  public $onInit = () => {
    this.connectWithReduxStoreAndInitializeState();
  };

  public $onDestroy = () => {
    if (this.template && this.templateRevision) {
      this.actions!.stopPollerForTemplateRevisionId(this.template.id, this.templateRevision.id);
    }
  };

  public connectWithReduxStoreAndInitializeState = () => {
    if (this.template && this.templateRevision) {
      const mapStateToThis =
        () =>
        (state: ReduxAppState): InternalState | undefined => {
          // We recheck this as the template could be set to null when its deselected from folders view
          if (this.template && this.templateRevision) {
            const newTemplateMigrationState = TemplateMigrationSelectors.templateMigrationState(this.template!.id)(
              state,
            );
            if (newTemplateMigrationState && (this.state === undefined || this.state !== newTemplateMigrationState)) {
              const newStats = newTemplateMigrationState.stats;
              if (newStats) {
                this.countMigrated = newStats.countMigrated || 0;
                this.countTotal = newStats.countTotal;
                this.percentage = this.countTotal
                  ? templateMigrationStatsService.getMigrationPercentage(this.countMigrated, this.countTotal)
                  : 0;
                return { stats: newStats };
              }
            }
          } else {
            return { stats: { countTotal: 0 } };
          }
        };

      const mapDispatchToActions = () => this.templateMigrationActions;
      connectController(this.$ngRedux, mapStateToThis, mapDispatchToActions)(this);
      this.actions!.pollMigrationStatsIfChecklistsAreBeingMigrated(this.template!.id, this.templateRevision!.id);
    }
  };
}

export const TemplateMigrationProgressComponent: angular.IComponentOptions = {
  bindings: {
    template: '<',
    templateRevision: '<',
  },
  controller: TemplateMigrationProgressController,
  templateUrl,
};
