import { AnyAction } from 'redux';
import { Action } from 'redux-actions';
import { Plan } from '../billing';
import { ConditionalRules } from '../conditional-logic/model';
import {
  Group,
  GroupMembership,
  Identifiable,
  Muid,
  Organization,
  OrganizationMembership,
  ProcessingError,
  User,
} from '../core';
import {
  Attachment,
  Checklist,
  ChecklistRevision,
  Comment,
  DueDateRuleDefinition,
  Folder,
  FormFieldValue,
  Task,
  TaskStats,
  TaskTemplate,
  Template,
  TemplateRevision,
  TemplateTaskAssignment,
  Widget,
} from '../process';
import { ChecklistAssignment, TaskAssignment } from '../role-assignment';
import { Approval, ApprovalRuleSubject } from '../approval-rule';
import { FolderPermit, TemplatePermit } from '@process-street/subgrade/permission';

export interface ObjectMap<T> {
  [id: string]: T;
}

export interface GrouppedObjectMap<T> {
  [id: string]: T[];
}

/**
 * represents state.entities.`given entity`
 */
export interface EntityMap<T> {
  [id: string]: Readonly<T>;
}

/**
 * Immutable version of EntityMap
 */
export interface ImmutableEntityMap<T> {
  readonly [id: string]: Readonly<T>;
}

/**
 * represents state.lookups.`given entity`.`given selector`
 * @deprecated use ImmutableLookupMap
 */
export interface LookupMap {
  [id: string]: Muid[];
}

/**
 * Immutable Lookup Map
 */
export interface ImmutableLookupMap {
  readonly [id: string]: Muid[];
}

/**
 * Representation of 1 to 1 reference that is maintained in state
 */
export interface ReferenceMap {
  [id: string]: Muid;
}

/**
 * Immutable variation of ReferenceMap
 */
export interface ImmutableReferenceMap {
  readonly [id: string]: Muid;
}

export type EntityNormalizer<T extends Identifiable> = (e: T) => T;

export type SelectorFunction<T extends Identifiable> = (e: T) => Muid;

export interface LightReduxEntitiesState {
  readonly users: ImmutableEntityMap<User>;
  readonly organizations: ImmutableEntityMap<Organization>;
  readonly organizationMemberships: ImmutableEntityMap<OrganizationMembership>;
  readonly groupMemberships: ImmutableEntityMap<GroupMembership>;
  readonly groups: ImmutableEntityMap<Group>;
  readonly plans: ImmutableEntityMap<Plan>;
  readonly folders: ImmutableEntityMap<Folder>;
  readonly templates: ImmutableEntityMap<Template>;
  readonly templateRevision: ImmutableEntityMap<TemplateRevision>;
}

export interface LightSessionState {
  readonly user: User | null;
  readonly selectedOrganizationId: Muid | null;
}

export interface LightReduxState {
  readonly consumer?: 'mobile' | 'web';
  readonly entities: Readonly<LightReduxEntitiesState>;
  readonly session: Readonly<LightSessionState>;
}

export interface BaseReduxEntitiesState extends LightReduxEntitiesState {
  readonly approval: EntityMap<Approval>;
  readonly attachment: EntityMap<Attachment>;
  readonly approvalRuleSubject: EntityMap<ApprovalRuleSubject>;
  readonly users: EntityMap<User>;
  readonly organizations: EntityMap<Organization>;
  readonly organizationMemberships: EntityMap<OrganizationMembership>;
  readonly groupMemberships: EntityMap<GroupMembership>;
  readonly groups: EntityMap<Group>;
  readonly plans: EntityMap<Plan>;
  readonly folders: EntityMap<Folder>;
  readonly folderPermits: EntityMap<FolderPermit>;
  readonly templates: EntityMap<Template>;
  readonly templatePermits: EntityMap<TemplatePermit>;
  readonly templateRevision: EntityMap<TemplateRevision>;
  readonly task: EntityMap<Task>;
  readonly taskStats: EntityMap<TaskStats>;
  readonly taskTemplates: EntityMap<TaskTemplate>;
  readonly dynamicDueDate: EntityMap<DueDateRuleDefinition>;
  readonly conditionalRules: EntityMap<ConditionalRules>;
  readonly comment: EntityMap<Comment>;
  readonly checklists: EntityMap<Checklist>;
  readonly checklistAssignments: EntityMap<ChecklistAssignment>;
  readonly templateTaskAssignments: EntityMap<TemplateTaskAssignment>;
  // TODO this should be just taskAssignment
  readonly checklistTaskAssignments: EntityMap<TaskAssignment>;
  readonly checklistRevision: EntityMap<ChecklistRevision>;
  readonly widgets: EntityMap<Widget>;
  readonly formFieldValue: EntityMap<FormFieldValue>;
}

export interface BaseFormFieldValueLookupState {
  readonly byChecklistRevisionId: Readonly<LookupMap>;
}

export interface BaseWidgetLookupState {
  readonly byTaskTemplateId: Readonly<LookupMap>;
}

export interface BaseTaskTemplateLookupState {
  readonly byTemplateRevisionId: LookupMap;
}

export interface BaseTaskStatsLookups {
  readonly byChecklistId: LookupMap;
  readonly byChecklistRevisionId: LookupMap;
}

export interface BaseDynamicDueDateRuleDefinitionLookups {
  readonly byTemplateRevisionId: LookupMap;
}

export interface BaseTaskLookupState {
  readonly byChecklistRevisionId: LookupMap;
}

export interface BaseApprovalRuleSubjectLookupsState {
  readonly byTemplateRevisionId: LookupMap;
}

export interface BaseApprovalLookupState {
  readonly byChecklistRevisionId: LookupMap;
}
export interface BaseTemplatePermitLookups {
  byTemplateId: {
    [templateId: string]: { hasMore: boolean; permits: Muid[]; totalCount: number };
  };
}
export interface BaseFolderPermitLookups {
  byFolderId: LookupMap;
}

export interface BaseLookupState {
  readonly approval: Readonly<BaseApprovalLookupState>;
  readonly approvalRuleSubject: Readonly<BaseApprovalRuleSubjectLookupsState>;
  readonly task: Readonly<BaseTaskLookupState>;
  readonly taskTemplates: Readonly<BaseTaskTemplateLookupState>;
  readonly taskStats: Readonly<BaseTaskStatsLookups>;
  readonly dynamicDueDate: BaseDynamicDueDateRuleDefinitionLookups;
  readonly formFieldValue: Readonly<BaseFormFieldValueLookupState>;
  readonly widget: Readonly<BaseWidgetLookupState>;
  readonly templateTaskAssignments: Readonly<BaseTemplateTaskAssignmentState>;
  readonly folderPermits: Readonly<BaseFolderPermitLookups>;
  readonly templatePermits: Readonly<BaseTemplatePermitLookups>;
}

export interface BaseSessionState extends LightSessionState {
  readonly user: User | null;
  readonly selectedOrganizationId: Muid | null;
}

export interface BaseTemplateTaskAssignmentState {
  readonly byTemplateRevisionId: LookupMap;
}

export interface BaseNavigationState {
  readonly checklistId?: Readonly<Muid>;
  readonly checklistRevisionId?: Readonly<Muid>;
}

export type BaseFeatureFlagState = Record<string, boolean>;

export interface BaseModulesState {
  readonly navigation: BaseNavigationState;
  readonly featureFlag?: BaseFeatureFlagState;
}

export interface BaseReduxState extends LightReduxState {
  readonly entities: Readonly<BaseReduxEntitiesState>;
  readonly lookups: Readonly<BaseLookupState>;
  readonly modules: Readonly<BaseModulesState>;
  readonly session: Readonly<BaseSessionState>;
}

export enum RequestStage {
  Request = '/REQUEST',
  Success = '/SUCCESS',
  Failure = '/FAILURE',
}

export interface ReduxAction<P, M> extends AnyAction {
  type: string;
  payload?: P;
  meta?: M;
  stage: RequestStage;
}

export interface RequestAction<P, M> extends ReduxAction<P, M> {
  stage: RequestStage.Request;
}

export interface SuccessAction<P, M> extends ReduxAction<P, M>, Action<P> {
  payload: P;
  stage: RequestStage.Success;
}

export interface FailureAction<M> extends ReduxAction<Error | ProcessingError, M> {
  stage: RequestStage.Failure;
}
