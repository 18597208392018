import * as React from 'react';
import {
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
} from 'components/design/next';
import { useActor, useSelector } from '@xstate/react';
import { EmailFormFieldActor } from './email-form-field-machine';
import { ValidationSelectors } from '../validation-machine';
import { RulesEngineTargetSelectors } from '../../form-response-body/rules-engine-machine';
import { FormResponseLabel } from '../common';

export interface EmailFormFieldProps {
  actor: EmailFormFieldActor;
}

export const EmailFormField: React.FC<React.PropsWithChildren<EmailFormFieldProps>> = ({ actor }) => {
  const [state, send] = useActor(actor);
  const isHiddenByRule = useSelector(actor, RulesEngineTargetSelectors.getIsHiddenByRule);
  const { widget } = state.context;

  const onFocus = () => {
    send({ type: 'FOCUS' });
  };

  const onBlur = () => {
    send({ type: 'BLUR' });
  };

  const setValue: React.ChangeEventHandler<HTMLInputElement> = e => {
    send({ type: 'CHANGE', value: e.target.value });
  };

  const ref = React.useRef<HTMLDivElement | null>(null);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !state.context.inputNode) {
          actor.send({ type: 'SET_NODE', node });
        }
      }}
      as={VStack}
      alignItems="stretch"
      isInvalid={ValidationSelectors.isActorInvalidVisible(state.context.validationActor)}
      isRequired={widget.required}
    >
      <FormResponseLabel>{widget.label || 'Untitled Email'}</FormResponseLabel>
      <InputGroup>
        <InputLeftElement px="3">
          {/* mt because it looks slightly off center to input */}
          <Icon mt="px" icon="envelope" size="4" color="gray.400" />
        </InputLeftElement>
        <Input
          w={{ base: '100%', lg: '78' }}
          borderColor="gray.300"
          type="email"
          value={state.context.value}
          placeholder={widget.config.placeholder || 'email@address.com'}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={setValue}
          isDisabled={state.matches('input.disabled')}
          autoFocus={state.matches('autoFocus.enabled')}
        />
      </InputGroup>
      <FormErrorMessage>{ValidationSelectors.errorMessage(state.context.validationActor)}</FormErrorMessage>
    </FormControl>
  );
};
