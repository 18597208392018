import { TEXT_MAX_LENGTH, UrlFormFieldWidget, WidgetUtils } from '@process-street/subgrade/process';
import {
  Button,
  ButtonGroup,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useModalContext,
} from 'components/design/next';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { urlService } from 'services/url-service';
import { DefaultValueField } from '../common/default-value-field';
import { useWidgetSettingsContext } from '../widget-settings-context';
import { UrlSettingsSchema, urlSettingsSchema } from './url-settings-schema';

export const UrlSettings = () => {
  const { widget, onUpdate } = useWidgetSettingsContext<UrlFormFieldWidget>();
  const { onClose } = useModalContext();

  const initialValues: UrlSettingsSchema = {
    defaultValue: widget.config.defaultValue ?? '',
    hasVariables: WidgetUtils.hasVariables(widget.config.defaultValue),
  };

  const handleSubmit = (values: UrlSettingsSchema) => {
    const { hasVariables: _, ...config } = values;

    onUpdate({ ...widget, config });
    onClose();
  };

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader p={8}>
        <Text variant="2">{widget.label || 'Untitled URL field'}</Text>
      </ModalHeader>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={urlSettingsSchema}>
        {({ isValid, dirty, setFieldValue, getFieldProps }) => (
          <Form>
            <ModalBody px={9} py={2}>
              <DefaultValueField
                maxLength={TEXT_MAX_LENGTH}
                onBlur={e => {
                  const value = e.target.value.trim();
                  const hasVariables = WidgetUtils.hasVariables(value);
                  const normalizedValue = hasVariables ? value : urlService.addHttp(value);
                  setFieldValue('defaultValue', normalizedValue);
                  getFieldProps('defaultValue').onBlur(e);
                }}
              />
            </ModalBody>
            <ModalFooter p={6}>
              <ButtonGroup>
                <Button aria-label="cancel changes" variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button aria-label="set constraints" isDisabled={!(isValid && dirty)} type="submit">
                  Apply
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};
