import { TaskTemplate, TaskTemplatesDeleteResponse } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace DeleteTaskTemplatesMutation {
  export type Params = {
    taskTemplatesIds: Array<TaskTemplate['id']>;
  };

  export type Response = Array<TaskTemplatesDeleteResponse>;

  export const key = ['post', 'delete-task-templates'];

  export const mutationFn = async (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/task-templates/delete`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
