import { match, P } from 'ts-pattern';
import { TaskPermissionRule, TaskPermissionRuleSourceType } from '../permission';
import { FieldType, FormFieldWidget } from '../process';

export const CHECKLIST_INITIATIOR_ICON = 'user';
export const FORM_FIELD_EMAIL_ICON = 'envelope';
export const FORM_FIELD_MEMBERS_ICON = 'users';
export const CHECKLIST_INITIATOR_LABEL = 'Workflow Runner';

export const resolveRuleIcon = (rule: TaskPermissionRule, widgets: FormFieldWidget[]) =>
  match({ sourceType: rule.sourceType })
    .with({ sourceType: TaskPermissionRuleSourceType.ChecklistInitiator }, () => CHECKLIST_INITIATIOR_ICON)
    .with({ sourceType: TaskPermissionRuleSourceType.FormField }, () => {
      const widget = widgets.find(widget => widget.header.group.id === rule.sourceFormFieldWidgetGroup?.id);
      return match({ widget })
        .with({ widget: { fieldType: FieldType.Email } }, () => FORM_FIELD_EMAIL_ICON)
        .with({ widget: { fieldType: FieldType.Members } }, () => FORM_FIELD_MEMBERS_ICON)
        .otherwise(() => '');
    })
    .otherwise(() => '');

export const getRuleLabel = (rule: TaskPermissionRule, widgets: FormFieldWidget[]) => {
  return match({ type: rule.sourceType, groupId: rule.sourceFormFieldWidgetGroup?.id })
    .with({ type: TaskPermissionRuleSourceType.ChecklistInitiator }, () => CHECKLIST_INITIATOR_LABEL)
    .with({ type: TaskPermissionRuleSourceType.FormField, groupId: P.not(P.nullish) }, () => {
      const widget = widgets.find(widget => widget.header.group.id === rule.sourceFormFieldWidgetGroup?.id);
      if (widget) {
        return widget.label || widget.key;
      }

      return '';
    })
    .otherwise(() => '');
};
