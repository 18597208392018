import { useInjector } from 'components/injection-provider';

const ChecklistRoutesToSkip = ['checklist', 'checklist.task'];
const RoutesToSkip = ChecklistRoutesToSkip.concat(['checklistAdd', 'checklistRun']);

export const useGoBack = () => {
  const { $state, TempDataService } = useInjector('$state', 'TempDataService');
  return () => {
    const prev = TempDataService.getStates().find(
      ({ state, params }) =>
        // since checklist gets redirected to checklist.task, we look for the first state that
        // * isn't checklist or checklist.task
        // OR isn't this exact checklist
        !RoutesToSkip.includes(state.name) ||
        (ChecklistRoutesToSkip.includes(state.name) && params?.id !== $state.params.id),
    );

    if (prev?.state.name) {
      $state.go(prev.state.name, prev.params);
    } else {
      $state.go('reports');
    }
  };
};
