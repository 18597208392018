import {
  AnonymousUserInfo,
  CreatedUser,
  IdentityProvider,
  Muid,
  MuidUtils,
  Profile,
  Ref,
  User,
  CompleteLoginUserInfo,
  UserOrigin,
  UserOwnership,
  UserSettings,
  UserStatus,
  UserType,
} from '../../core';
import { TEST_FREEMIUM_PRO_PLAN } from '../plans';
import { generateAudit } from './audit-generator';
import { testUserId } from './generator-constants';
import { generateOrganization } from './organization-generator';
import { generateOrganizationMembership } from './organization-membership-generator';
import { generatePlan } from './plan-generator';

export const generateEmail = () => `${Math.random()}@test.com`;

export const generateUser = (user?: Partial<User>): User => {
  const userId = user && user.id ? user.id : MuidUtils.randomMuid();
  const email = user && user.email ? user.email : generateEmail();

  return {
    id: userId,
    audit: generateAudit(userId),
    email,
    primaryUserEmail: {
      id: userId,
    },
    emailVerified: true,
    emailVerificationSentDate: Date.now(),
    emailVerificationCodeId: userId,
    username: 'Bruce Wayne',
    timeZone: 'UTC',
    origin: UserOrigin.FrontStreet,
    status: UserStatus.Active,
    developer: false,
    beta: false,
    userType: UserType.Standard,
    signUpProvider: IdentityProvider.Google,
    ownership: UserOwnership.User,
    ...user,
  };
};

export const generateAnonymousUser = (user?: Partial<User>): User =>
  generateUser({
    ...user,
    emailVerified: false,
    username: 'Anonymous',
    userType: UserType.Anonymous,
  });

export const generateUserForGroup = (groupName: string): User => {
  const userForGroup = generateUser({ email: `group-${Math.random()}@test.com` });

  userForGroup.username = groupName;
  userForGroup.userType = UserType.Group;

  return userForGroup;
};

export function generateAnonymousUserInfo(): AnonymousUserInfo {
  const user: User = generateUser();
  const selectedOrganization = generateOrganization();
  const profile: Profile = {
    email: user.email,
    provider: IdentityProvider.ProcessStreet,
    userId: user.id,
    username: user.username,
  };
  const selectedPlan = generatePlan();
  return {
    identityProvider: IdentityProvider.ProcessStreet,
    organizationSubdomainsMap: {},
    profile,
    selectedOrganization,
    selectedPlan,
    token: 'token',
    user,
    userSettings: {},
  };
}

export const generateUserRef = (userId: Muid = testUserId): Ref<User> => ({
  id: userId,
});

export const generateUserSettings = (settings?: Partial<UserSettings>): UserSettings => ({
  id: MuidUtils.randomMuid(),
  user: { id: MuidUtils.randomMuid() },
  settings: {},
  ...settings,
});

export const generateUserInfo = (
  user?: Partial<User>,
  userInfo?: Partial<CompleteLoginUserInfo>,
): CompleteLoginUserInfo => {
  const genUser = generateUser(user);
  const org = generateOrganization();

  return {
    user: genUser,
    identityProvider: IdentityProvider.ProcessStreet,
    userSettings: {},
    selectedOrganization: org,
    organizationMembership: generateOrganizationMembership({ user: genUser, organization: org }),
    organizationSubdomainsMap: {},
    selectedPlan: generatePlan({ id: TEST_FREEMIUM_PRO_PLAN }),
    elevioHash: 'elevioHash',
    intercomHash: 'intercomHash',
    ...userInfo,
  };
};

export const generateCreatedUser = (user?: Partial<User>): CreatedUser => {
  return generateUser({ ...user, status: UserStatus.Created }) as CreatedUser;
};
