import { Option } from '@process-street/subgrade/core';

export interface TemplateMigrationStatsService {
  getMigrationPercentage(migratedCount: number, totalCount: number): number;
}

class TemplateMigrationStatsServiceImpl implements TemplateMigrationStatsService {
  public static getInstance() {
    return TemplateMigrationStatsServiceImpl.instance;
  }
  private static instance = new TemplateMigrationStatsServiceImpl();
  public InitialPercentage = 5;

  /* eslint @typescript-eslint/no-empty-function: ["error", {"allow": ["private-constructors"]}] */
  private constructor() {}

  public getMigrationPercentage(migratedCount: Option<number>, totalCount: Option<number>): number {
    if (migratedCount === undefined || totalCount === undefined) {
      return this.InitialPercentage;
    }

    if (totalCount > 0) {
      return this.InitialPercentage + Math.floor((migratedCount / totalCount) * 95);
    }

    return 100;
  }
}

export const templateMigrationStatsService = TemplateMigrationStatsServiceImpl.getInstance();
