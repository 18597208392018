import { DragControls, PanInfo, Point, useDragControls } from 'framer-motion';
import { UIEventHandler, useCallback, useRef } from 'react';

interface ReorderAutoScrollOptions {
  /** The threshold in pixels from the edge of the scroll container to start scrolling */
  scrollThresholdPx?: number;
  /** The amount to scroll by in pixels */
  scrollAmountPx?: number;
}

/**
 * Automatic edge scrolling while reordering items is currently not implemented in framer-motion.
 * Code from: https://github.com/framer/motion/issues/1339#issuecomment-2121479726
 * */
export const useReorderAutoScroll = ({ scrollThresholdPx = 25, scrollAmountPx = 5 }: ReorderAutoScrollOptions = {}) => {
  const dragControls = useDragControls();
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollStartRef = useRef<number>(); // scrollTop when the drag starts
  const dragStartRef = useRef<number>(); // y position of the cursor when the drag starts
  const draggingEltControls = useRef<VisualElementDragControls>();

  const onScroll: UIEventHandler<HTMLDivElement> = useCallback(event => {
    if (!draggingEltControls.current) return;
    const startPoint = getDragStartPoint(draggingEltControls.current);
    const target = event.target as HTMLElement | null;
    if (!startPoint || !target || scrollStartRef.current === undefined || dragStartRef.current === undefined) {
      return;
    }
    const scrollDistance = target.scrollTop - scrollStartRef.current; // Distance from where the drag started
    startPoint.y = dragStartRef.current - scrollDistance; // Move the startPoint to account for the scroll
  }, []);

  const onDrag = useCallback(
    (event: Event, info: PanInfo) => {
      const scrollContainer = scrollContainerRef.current;
      if (!scrollContainer) return;
      const scrollContainerRect = scrollContainer.getBoundingClientRect();
      const dragPoint = info.point.y;

      // Check if target is the last elt in its parent container
      const eventTarget = event.target;
      if (!(eventTarget instanceof Element)) return;

      const item = eventTarget.closest('[draggable]');
      const parent = item?.parentElement;
      if (!parent) return;

      if (
        dragPoint < scrollContainerRect.top + scrollThresholdPx &&
        (item !== parent.firstElementChild || scrollContainer.scrollTop > 0)
      ) {
        // User is dragging card to the top of the scroll container
        scrollContainer.scrollTop -= scrollAmountPx;
      } else if (
        dragPoint > scrollContainerRect.bottom - scrollThresholdPx &&
        (item !== parent.lastElementChild || scrollContainer.scrollTop < scrollContainer.scrollHeight)
      ) {
        // User is dragging card to the bottom of the scroll container
        scrollContainer.scrollTop += scrollAmountPx;
      }
    },
    [scrollAmountPx, scrollThresholdPx],
  );

  // Track the scroll distance by capturing scrollTop when the drag starts
  const onDragStart = useCallback(() => {
    const scroller = scrollContainerRef.current;
    const controls = findDraggingElementControls(dragControls);
    if (!scroller || !controls) return;
    draggingEltControls.current = controls;
    scrollStartRef.current = scroller.scrollTop;
    const startPoint = getDragStartPoint(controls);
    if (!startPoint) return;
    dragStartRef.current = startPoint.y;
  }, [dragControls]);

  const onDragEnd = useCallback(() => {
    scrollStartRef.current = undefined;
    dragStartRef.current = undefined;
    draggingEltControls.current = undefined;
  }, []);

  return {
    scrollContainerProps: {
      scrollContainerRef,
      onScroll,
    },
    reorderItemProps: {
      dragControls,
      onDrag,
      onDragStart,
      onDragEnd,
    },
  };
};

// A private Framer class that we're just using one little piece of
// https://github.com/framer/motion/blob/fb227f8b700c6e2d9c3b33d0a2af1a2d2b7849e9/packages/framer-motion/src/gestures/drag/VisualElementDragControls.ts#L53
type VisualElementDragControls = {
  panSession: {
    history: Point[];
  };
};

const findDraggingElementControls = (dragControls: DragControls) => {
  try {
    return Array.from<VisualElementDragControls>(
      // @ts-expect-error - we're reaching into a private prop
      // https://github.com/framer/motion/blob/fb227f8b700c6e2d9c3b33d0a2af1a2d2b7849e9/packages/framer-motion/src/gestures/drag/use-drag-controls.ts#L29
      dragControls.componentControls,
    ).find((c: any) => c.isDragging);
  } catch (err) {
    // If this private prop moves in the future, we'll start logging errors here
    console.error('[caught] findDraggingElementControls', err);
    return;
  }
};

const getDragStartPoint = (controls: VisualElementDragControls): Point | undefined => {
  try {
    // https://github.com/framer/motion/blob/fb227f8b700c6e2d9c3b33d0a2af1a2d2b7849e9/packages/framer-motion/src/gestures/pan/PanSession.ts#L257
    return controls.panSession.history[0];
  } catch (err) {
    // If this private prop moves in the future, we'll start logging errors here
    console.error('[caught] getDraggingElementStartPoint', err);
    return;
  }
};
