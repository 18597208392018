import { AuthConnection, Muid } from '@process-street/subgrade/core';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { EntityMap, LookupMap } from '@process-street/subgrade/redux/types';
import { AUTH_CONNECTION_GET_ALL_BY_ORGANIZATION_ID } from 'components/auth-connection/store/auth-connection.actions';
import { toSuccess } from 'reducers/util';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { combineReducers } from 'redux';
import { ActionMeta } from 'redux-actions';

export const authConnectionReducers = handleActionsOnSuccess(
  {
    [toSuccess(AUTH_CONNECTION_GET_ALL_BY_ORGANIZATION_ID)]: (
      state: EntityMap<AuthConnection>,
      { payload: authConnections }: ActionMeta<AuthConnection[], { organizationId: Muid }>,
    ) => EntitiesReducerUtils.upsertAll(state, authConnections),
  },
  {},
);

export const authConnectionByOrganizationIdReducers = handleActionsOnSuccess(
  {
    [toSuccess(AUTH_CONNECTION_GET_ALL_BY_ORGANIZATION_ID)]: (
      state: LookupMap,
      { payload: authConnections, meta: { organizationId } }: ActionMeta<AuthConnection[], { organizationId: Muid }>,
    ) => LookupsReducerUtils.upsertAllUsingSelectorFunctions(state, authConnections, () => organizationId),
  },
  {},
);

export const authConnectionLookupReducers = combineReducers({
  byOrganizationId: authConnectionByOrganizationIdReducers,
});
