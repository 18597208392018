import angular from 'angular';

angular.module('frontStreetApp.services').factory('Checklists', function (ResourceFactory) {
  return ResourceFactory.create({
    getAllByOrganizationId: 'GET[] /1/organizations/:organizationId/checklists',
    getStats: 'POST[]  /1/checklists/stats',
    getTaskStatsById: 'GET[]  /1/checklists/:id/task-stats',
    getById: 'GET /1/checklists/:id',
    create: 'POST /1/checklists',
    updateNameById: 'PUT /1/checklists/:id/name',
    updateDueDateById: 'PUT /1/checklists/:id/due',
    updateStatus: 'PUT /1/checklists/:id/status/:status',
    updateShareable: 'PUT /1/checklists/:id/shareable',
    deleteById: 'DELETE /1/checklists/:id',
    undelete: 'PUT /1/checklists/:id/undelete',
    search: 'GET[] /1/checklists/search/:organizationId',
  });
});
