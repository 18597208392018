import { Option } from 'space-monad';

import {
  DataSet,
  DataSetColumnFilterForm,
  DataSetColumnState,
  DataSetColumnStateModel,
} from '@process-street/subgrade/process';

export namespace DataSetPageHelpers {
  export const findFirstAccessibleDataSet = (dataSets: DataSet[]) => dataSets.find(ds => ds.canAccessDataSet);

  // When the page loads for the first time, we try to find the data set that matches the `dataSetId`
  // received from the URL.
  export const handleFirstLoad = (dataSets: DataSet[], $stateParams: Record<string, string>) => {
    const dataSet =
      Option<string>($stateParams.dataSetId)
        .map(dataSetId => {
          const ds = dataSets.find(d => d.id === dataSetId);
          return ds?.canAccessDataSet || $stateParams.savedViewId ? ds : undefined;
        })
        .get() ?? findFirstAccessibleDataSet(dataSets);

    const savedView = Option<string>($stateParams.savedViewId).map(savedViewId =>
      dataSet?.savedViews.find(s => s.id === savedViewId),
    );

    const columns = savedView
      .map(sv => sv.columns)
      .getOrElse(dataSet?.columnDefs.map<DataSetColumnState>(DataSetColumnStateModel.fromColumnDef) ?? []);

    const filters: DataSetColumnFilterForm[] = DataSetColumnStateModel.getFilters(columns);

    return {
      dataSet,
      savedView: savedView.get() ?? null,
      columns,
      filters,
    };
  };
}
