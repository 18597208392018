import { AuditMetadata, User } from '@process-street/subgrade/core';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import { Stack, StackProps, Text } from 'components/design/next';
import React from 'react';

export type DateAuditInfoStackProps = {
  createdBy: User;
  createdDate: AuditMetadata['createdDate'];
} & StackProps;

export const DateAuditInfoStack: React.FC<React.PropsWithChildren<DateAuditInfoStackProps>> = ({
  createdDate,
  createdBy,
  ...props
}) => (
  <Stack {...props}>
    <Text aria-label="comment date" variant="-2" color="gray.500" noOfLines={1}>
      {DateUtils.formatDateTime(
        createdDate,
        DateUtils.isOverYear(createdDate) ? DateFormat.DateMonthDayYearAtTime : DateFormat.DateMonthDayAtTime,
        createdBy?.timeZone,
      )}
    </Text>
  </Stack>
);
