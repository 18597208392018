import { useNavigate } from '@process-street/adapters/navigation';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { Flex, Spinner } from 'components/design/next';
import { GetOneOffTaskQuery } from 'features/one-off-tasks/query-builder';
import { useGetAllConsolidatedChecklistPermissionsQuery } from 'features/permissions/query-builder';
import { useStateParam } from 'hooks/use-state-param';
import qs from 'qs';
import * as React from 'react';

export const ViewOneOffTask = () => {
  const taskId = useStateParam({ key: 'id' });
  const navigate = useNavigate();
  const oneOffTaskQuery = GetOneOffTaskQuery.useQuery({ id: taskId });
  const checklistPermitsQuery = useGetAllConsolidatedChecklistPermissionsQuery(
    {
      checklistIds: [oneOffTaskQuery.data?.linkedChecklist?.id ?? ''],
    },
    {
      enabled: Boolean(oneOffTaskQuery.data) && Boolean(oneOffTaskQuery.data?.linkedChecklist),
    },
  );

  const isLoading = oneOffTaskQuery.isLoading || checklistPermitsQuery.isLoading;

  const navigateToMyWorkAndOpenTask = (taskId: string) =>
    navigate({
      pathname: 'myWork',
      search: qs.stringify({
        [AppModalQueryParam.ModalTaskId]: taskId,
        [AppModalQueryParam.Modal]: AppModalName.OneOffTask,
      }),
    });

  const navigateToWFRAndOpenTask = (taskId: string, checklistId: string) =>
    navigate({
      pathname: 'checklist',
      search: qs.stringify({
        id: checklistId,
        [AppModalQueryParam.ModalTaskId]: taskId,
        [AppModalQueryParam.Modal]: AppModalName.OneOffTask,
      }),
    });

  React.useEffect(
    function redirectAndShowTask() {
      const task = oneOffTaskQuery.data;
      const checklistData = checklistPermitsQuery.data && checklistPermitsQuery.data[0];

      if (task && (!task.linkedChecklist || (task.linkedChecklist && checklistData))) {
        if (task.linkedChecklist && checklistData) {
          const { permissionMap } = checklistData;
          if (!permissionMap.checklistRead) {
            navigateToMyWorkAndOpenTask(task.id);
          } else {
            navigateToWFRAndOpenTask(task.id, task.linkedChecklist.id);
          }
        } else {
          navigateToMyWorkAndOpenTask(task.id);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
    [oneOffTaskQuery.data, checklistPermitsQuery.data],
  );

  return (
    <>
      {isLoading ? (
        <Flex pt="4" w="full" justifyContent="center">
          <Spinner />
        </Flex>
      ) : null}
    </>
  );
};
