import { isValidFullUrl, isValidUrl } from '@process-street/subgrade/process/validation';

export interface UrlService {
  /**
   * Validates whether a value is a valid HTTP or HTTPS URL.
   *
   * @param value
   * @returns {boolean}
   */
  isValidHttpUrl(value: string): boolean;

  /**
   * Check whether url has http or https protocol specified, if not adds 'http://'.
   *
   * From http://stackoverflow.com/questions/11300906/check-if-a-string-starts-with-http-using-javascript
   * with difference that we don't take ftp and check if url is provided
   *
   * @param url
   * @returns {string}
   */
  addHttp(url: string): string;

  /**
   * Sets target for link, _self for internal links and _blank for external
   * @param link
   */
  setTargetForLink(link: HTMLAnchorElement): void;
}
class UrlServiceImpl implements UrlService {
  public static getInstance() {
    return UrlServiceImpl.instance;
  }

  private static instance = new UrlServiceImpl();

  public addHttp(url: string): string {
    if (url && !/^https?:\/\//i.test(url)) {
      return `http://${url}`;
    }
    return url;
  }

  public isValidHttpUrl(value: string): boolean {
    return isValidUrl(value);
  }

  // makes sure that http/https is also present in the url.
  public isValidFullUrl(value: string): boolean {
    return isValidFullUrl(value);
  }

  public setTargetForLink(link: HTMLAnchorElement): void {
    const target = link.href.includes('process.st') ? '_self' : '_blank';
    link.setAttribute('target', target);
  }
}

export const urlService = UrlServiceImpl.getInstance();
