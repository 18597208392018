import { handleActionsOnSuccess } from 'reducers/util';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { combineReducers } from 'redux';

import { CHECKLIST_GET_TASK_STATS } from 'reducers/checklist/checklist.actions';
import { FORM_FIELD_VALUE_UPDATE } from 'components/form-field-value/store/form-field-value.actions';
import {
  TASK_STATS_GET_BY_CHECKLIST_REVISION,
  TASK_STATS_UPDATE_INTERNAL,
  TASK_STATS_UPDATE_ALL_INTERNAL,
} from './task-stats.actions';

export const taskStatsReducer = handleActionsOnSuccess(
  {
    [CHECKLIST_GET_TASK_STATS]: (state, { payload }) => {
      const stats = payload.map(s => {
        s.id = s.taskId;
        return s;
      });
      return EntitiesReducerUtils.upsertAll(state, stats);
    },
    [TASK_STATS_GET_BY_CHECKLIST_REVISION]: (state, { payload }) => {
      const stats = payload.map(s => {
        s.id = s.taskId;
        return s;
      });
      return EntitiesReducerUtils.upsertAll(state, stats);
    },
    [TASK_STATS_UPDATE_INTERNAL]: (state, { payload }) => {
      const { taskStats } = payload;
      return EntitiesReducerUtils.upsert(state, taskStats);
    },
    [TASK_STATS_UPDATE_ALL_INTERNAL]: (state, { payload }) => {
      const { taskStats } = payload;
      return EntitiesReducerUtils.upsertAll(state, taskStats);
    },
    [FORM_FIELD_VALUE_UPDATE]: (state, { payload }) => {
      const { tasks } = payload;
      const newState = { ...state };

      tasks.forEach(task => {
        const taskStats = newState[task.id];

        if (taskStats && taskStats.hidden !== task.hidden) {
          newState[task.id] = { ...taskStats, hidden: task.hidden };
        }
      });

      return newState;
    },
  },
  {},
);

const taskStatsLookupByChecklistIdReducer = handleActionsOnSuccess(
  {
    [CHECKLIST_GET_TASK_STATS]: (state, { payload, meta: { checklistId } }) =>
      LookupsReducerUtils.replaceAllUsingSelectorFunctions(
        state,
        payload,
        () => checklistId,
        s => s.taskId,
      ),
  },
  {},
);

const taskStatsLookupByChecklistRevisionIdReducer = handleActionsOnSuccess(
  {
    [TASK_STATS_GET_BY_CHECKLIST_REVISION]: (state, { payload, meta: { checklistRevisionId } }) =>
      LookupsReducerUtils.replaceAllUsingSelectorFunctions(
        state,
        payload,
        () => checklistRevisionId,
        s => s.taskId,
      ),
  },
  {},
);

export const taskStatsLookupsReducer = combineReducers({
  byChecklistId: taskStatsLookupByChecklistIdReducer,
  byChecklistRevisionId: taskStatsLookupByChecklistRevisionIdReducer,
});
