import { UniversalNewMenu } from 'features/new-menu/component';
import * as React from 'react';

export const NewMenuRowRenderer = () => (
  <UniversalNewMenu
    shouldHideFolder
    shouldHideRunWorkflow
    h={9}
    mt={0}
    pl={12}
    borderRadius="none"
    fontWeight="medium"
    colorScheme="gray"
    color="gray.400"
    backgroundColor="inherit"
    _hover={{
      backgroundColor: 'gray.100',
    }}
    _active={{
      backgroundColor: 'gray.50',
    }}
  />
);
