import React from 'react';
import { match, P } from 'ts-pattern';
import { useHover } from 'react-use';
import { Template } from '@process-street/subgrade/process';
import { CoverImage } from '@process-street/subgrade/core';
import { Box, BoxProps, HStack, Icon, Text, useDisclosure } from 'components/design/next';

import { EditCoverLayer } from '../common/edit-cover-layer';
import { CDNImage } from 'components/cdn-image';
import { useGetTemplateQuery } from 'features/template/query-builder';

const CoverImageUploadModal = React.lazy(() =>
  import('features/cover-image/components/common/modal').then(({ CoverImageUploadModal }) => ({
    default: CoverImageUploadModal,
  })),
);

type CoverImageBoxProps = {
  children: (props: { hovered: boolean }) => React.ReactElement;
} & Omit<BoxProps, 'children'>;

const CoverImageBox = ({ children, ...props }: CoverImageBoxProps) => {
  const [hoverable] = useHover((hovered: boolean) => (
    <Box data-testid="cover-image-container" height="150px" position="relative" {...props}>
      {children({ hovered })}
    </Box>
  ));
  return hoverable;
};

export type CoverImageProps = { templateId: Template['id']; editable?: boolean; coverImage?: CoverImage } & BoxProps;

export const TemplateCoverImage: React.FC<React.PropsWithChildren<CoverImageProps>> = ({
  templateId,
  editable,
  coverImage,
  ...props
}) => {
  const uploadDisclosure = useDisclosure();
  const template = useGetTemplateQuery({ templateId });

  const onFinish = () => {
    uploadDisclosure.onClose();
  };

  return (
    <>
      {match<[CoverImage | undefined, boolean | undefined]>([coverImage, editable])
        .with([undefined, true], () => (
          <HStack spacing="2" onClick={uploadDisclosure.onOpen} cursor="pointer">
            <Icon icon="image" size="4" color="gray.400" />
            <Text aria-label="add cover" color="gray.400">
              Add cover
            </Text>
          </HStack>
        ))
        .with([P.not(undefined), P.any], ([coverImage, _]) => (
          <CoverImageBox {...props}>
            {({ hovered }) => {
              return (
                <>
                  <CDNImage
                    alt={template.data?.name ? `${template.data?.name} cover` : 'Cover image'}
                    s3File={coverImage.s3File}
                    transformation={{
                      height: 150,
                      width: 676, //max displayed width
                    }}
                    height="150px"
                  />
                  {editable && hovered && (
                    <EditCoverLayer onClickChange={uploadDisclosure.onOpen} templateId={templateId} />
                  )}
                </>
              );
            }}
          </CoverImageBox>
        ))
        .otherwise(() => null)}

      <React.Suspense fallback="">
        {editable && <CoverImageUploadModal {...uploadDisclosure} templateId={templateId} onFinish={onFinish} />}
      </React.Suspense>
    </>
  );
};
