import Muid from 'node-muid';
import { Plan, PlanInterval, PlanLevel, PlanTrack } from '@process-street/subgrade/billing';
import { match } from 'ts-pattern';

export const GodPlanId = Muid.fromUuid('36d40776-ca35-42ca-b609-8d60177ad67e');
export const MindsharePlanId = Muid.fromUuid('b2d1e341-5b5a-4002-9ff1-6a9eb65071cd');
export const WholesalePlanId = Muid.fromUuid('13d4ab18-14eb-415e-88ac-35c709847b1b');

export type PlanIdMap = { [id: string]: { track: PlanTrack; level: PlanLevel; interval: PlanInterval } };

export const FreeTrialPlans: PlanIdMap = {
  [Muid.fromUuid('00c41fa8-b651-4d6e-af88-c13d9a497c43')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Professional,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('2169f474-4314-4a77-93d8-654cb4e46790')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Professional,
    interval: PlanInterval.Yearly,
  },

  [Muid.fromUuid('9a10b19e-995d-4de8-918f-f15730302cec')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Free,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('84624aa7-4cfe-4c99-8764-9a8dae1f7d7e')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Free,
    interval: PlanInterval.Yearly,
  },
  [Muid.fromUuid('885de23b-8d25-493e-a1d1-6aa37e6ce147')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Basic,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('56baff1d-4af2-4aad-9fde-790659c90aae')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Basic,
    interval: PlanInterval.Yearly,
  },
  [Muid.fromUuid('482dc7a3-46c0-4373-bffd-a8e3248f7658')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Standard,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('9454d115-96a6-4e18-a3a1-abf540c2e12a')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Standard,
    interval: PlanInterval.Yearly,
  },
  [Muid.fromUuid('d45fb989-1507-4a90-b69e-a6d7764cf813')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Enterprise,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('72b36703-d7f4-4f6e-851a-d0d55c68d425')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Enterprise,
    interval: PlanInterval.Yearly,
  },
};

export const FreeTrialT5KPlans: PlanIdMap = {
  [Muid.fromUuid('0d113551-bb47-478c-b440-3587bb78f4ee')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Enterprise,
    interval: PlanInterval.Yearly,
  },
  [Muid.fromUuid('af8b897d-ba84-458e-8e91-0c110498dc1d')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Enterprise,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('5153799e-2575-4be5-8bfe-45fae7babb2d')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Professional,
    interval: PlanInterval.Yearly,
  },
  [Muid.fromUuid('e7330d0b-eafd-46bf-9610-6e479d5c8964')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Professional,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('143a705e-f5fd-4d06-aba1-4d9a2fc6a098')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Professional,
    interval: PlanInterval.Yearly,
  },
  [Muid.fromUuid('ec0eb63c-b4ed-4790-b575-70fae554b148')]: {
    track: PlanTrack.FreeTrial,
    level: PlanLevel.Professional,
    interval: PlanInterval.Monthly,
  },
};

const FreemiumPlans: PlanIdMap = {
  [Muid.fromUuid('1f2f2a06-a1cc-466b-a92e-a515b99c46de')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Free,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('d5f8969f-6080-453c-b955-1cf2236c8527')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Free,
    interval: PlanInterval.Yearly,
  },
  [Muid.fromUuid('ffc9a063-c31f-4bd2-a3e7-b135aa03618a')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Professional,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('abce12e5-e002-4cdc-bc12-a0d53a56517f')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Professional,
    interval: PlanInterval.Yearly,
  },
  [Muid.fromUuid('0511385e-5448-4022-ac0c-a1c2de208f34')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Enterprise,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('fbf0efef-4661-4fa3-84ad-1d805b20e802')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Enterprise,
    interval: PlanInterval.Yearly,
  },
};

const Freemium111Plans: PlanIdMap = {
  [Muid.fromUuid('1f974d58-f465-4bf0-97b1-1aa59ff65d0d')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Free,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('b13187a1-67b5-41b0-aef6-ec53dcb3ed90')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Free,
    interval: PlanInterval.Yearly,
  },
  [Muid.fromUuid('9e1859e4-4914-4225-af8f-8c6b8188d012')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Professional,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('b31fc0f3-aaa2-4bc3-bde2-cbb5f4fbbc66')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Professional,
    interval: PlanInterval.Yearly,
  },
  [Muid.fromUuid('dbbb8922-4121-4ab7-a405-6bf009010730')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Enterprise,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('4dac95a9-660b-4ad6-9c86-9d59a413fc66')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Enterprise,
    interval: PlanInterval.Yearly,
  },
  [Muid.fromUuid('5f2c0cf8-1c50-49ce-b393-5dae8c5dad09')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Enterprise,
    interval: PlanInterval.Monthly,
  },
  [Muid.fromUuid('854a7ad7-8848-46f7-92ae-1d70b2fb54af')]: {
    track: PlanTrack.Freemium,
    level: PlanLevel.Enterprise,
    interval: PlanInterval.Yearly,
  },
};

export const getFreemiumPlansByCurrentPlan = (plan: Plan): PlanIdMap => {
  if (FreemiumPlans[plan.id]) {
    return FreemiumPlans;
  } else if (Freemium111Plans[plan.id]) {
    return Freemium111Plans;
  } else {
    throw new Error(`Freemium plan not found for id [${plan.id}]`);
  }
};

export const getFreemiumAndFreeTrialPlansByCurrentPlan = (plan: Plan): PlanIdMap => {
  return match(plan.id)
    .when(
      id => FreemiumPlans[id],
      () => FreemiumPlans,
    )
    .when(
      id => Freemium111Plans[id],
      () => Freemium111Plans,
    )
    .when(
      id => FreeTrialPlans[id],
      () => FreeTrialPlans,
    )
    .when(
      id => FreeTrialT5KPlans[id],
      () => FreeTrialT5KPlans,
    )
    .otherwise(() => {
      throw new Error(`Plan not found for id [${plan.id}]`);
    });
};

export const isFreeTrialPlan = (planId: string): boolean => !!FreeTrialPlans[planId];

export const isT5KPlan = (planId: string): boolean => !!FreeTrialT5KPlans[planId];

export const isT5KStartupPlan = (planId: string): boolean =>
  [
    Muid.fromUuid('143a705e-f5fd-4d06-aba1-4d9a2fc6a098'),
    Muid.fromUuid('ec0eb63c-b4ed-4790-b575-70fae554b148'),
    Muid.fromUuid('5153799e-2575-4be5-8bfe-45fae7babb2d'),
    Muid.fromUuid('e7330d0b-eafd-46bf-9610-6e479d5c8964'),
  ].includes(planId);

export const AllPlanDescriptors = [FreeTrialPlans, FreeTrialT5KPlans, FreemiumPlans, Freemium111Plans];
