import { Folder } from '@process-street/subgrade/process';
import { noop } from '@process-street/subgrade/util/noop';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { ButtonProps, useDisclosure } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { useCurrentOrPrivateRootFolderQuery } from 'features/new-menu/query';
import { useGetConsolidatedFolderPermissionsQuery } from 'features/permissions/query-builder';
import * as React from 'react';
import { CreateTemplateSource } from 'services/template-service.interface';
import { match, P } from 'ts-pattern';

export type Props = {
  source: CreateTemplateSource;
  allowSelectFolder: boolean;
};

export const useNewWorkflowFromTemplateButton = ({
  source,
  allowSelectFolder,
}: Props): {
  buttonProps: Partial<ButtonProps>;
  folderPickerProps: {
    isOpen: boolean;
    onClose: () => void;
    onPick?: (folder: Folder) => void;
    initialFolderId: string;
    title: string;
  };
} => {
  const { $state } = useInjector('$state');
  const disclosure = useDisclosure();

  const createFromTemplate = React.useCallback(
    (selectedFolderId?: string) => {
      $state.go($state.current, {
        ...$state.params,
        [AppModalQueryParam.Modal]: AppModalName.TemplateGallery,
        [AppModalQueryParam.Source]: source,
        [AppModalQueryParam.SelectedFolderId]: selectedFolderId,
      });
    },
    [$state, source],
  );

  const { data: currentFolder, ...foldersQuery } = useCurrentOrPrivateRootFolderQuery();

  const { data: { permissionMap } = {}, ...permissionsQuery } = useGetConsolidatedFolderPermissionsQuery(
    currentFolder?.id ?? '',
    {
      enabled: !!currentFolder,
    },
  );

  const isDisabled = !permissionMap?.templateCreate;

  const onClick = React.useCallback(() => {
    match({ allowSelectFolder, currentFolder, isDisabled })
      .with({ allowSelectFolder: true, isDisabled: true }, () => {
        disclosure.onToggle();
      })
      .with({ currentFolder: P.not(undefined), isDisabled: false }, ({ currentFolder }) => {
        createFromTemplate(currentFolder.id);
      })
      .otherwise(noop);
  }, [allowSelectFolder, currentFolder, isDisabled, disclosure, createFromTemplate]);

  const onPick = React.useCallback(
    (folder: Folder) => {
      disclosure.onClose();
      createFromTemplate(folder.id);
    },
    [createFromTemplate, disclosure],
  );

  return {
    buttonProps: {
      isLoading: foldersQuery.isLoading || permissionsQuery.isLoading,
      isDisabled,
      onClick,
    },
    folderPickerProps: {
      isOpen: disclosure.isOpen,
      onClose: disclosure.onClose,
      onPick,
      initialFolderId: currentFolder?.id ?? '',
      title: 'Create Workflow from Template in...',
    },
  };
};
