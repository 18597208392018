export const ChecklistEvent = {
  COMPLETED_FROM_ATTACHED_TASK: 'checklist completed from attached task',
  COMPLETED_FROM_APPROVAL_TASK: 'checklist completed from approval task',
  CREATE_STARTED: 'checklist create started',
  CREATE_OK: 'checklist create ok',
  CREATE_FAILED: 'checklist create failed',
  CREATE_FINISHED: 'checklist create finished',

  UPDATE_STARTED: 'checklist update started',
  UPDATE_OK: 'checklist update ok',
  UPDATE_FAILED: 'checklist update failed',

  DELETE_OK: 'checklist delete ok',
  DELETE_FAILED: 'checklist delete failed',
  DELETE_UNDO: 'checklist delete undo',

  UNDELETE_OK: 'checklist undelete ok',
  UNDELETE_FAILED: 'checklist undelete failed',

  UPDATE_DUE_DATE_STARTED: 'checklist update due date started',
  UPDATE_DUE_DATE_OK: 'checklist update due date ok',
} as const;
