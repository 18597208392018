import { Muid } from '@process-street/subgrade/core';
import { Checklist } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type DeleteChecklistsMutationParams = {
  checklistIds: Muid[];
};

export type DeleteChecklistsMutationResponse = Checklist[];

export const DeleteChecklistsMutation = {
  key: ['checklist', 'delete'],
  mutationFn: ({ checklistIds }: DeleteChecklistsMutationParams) =>
    axiosService
      .getAxios()
      .post(`/1/checklists/delete`, {
        ids: checklistIds,
      })
      .then(r => r.data),
};

export const useDeleteChecklistsMutation = (
  options: UseMutationOptions<DeleteChecklistsMutationResponse, AxiosError, DeleteChecklistsMutationParams> = {},
) =>
  useMutation(DeleteChecklistsMutation.mutationFn, {
    mutationKey: DeleteChecklistsMutation.key,
    ...options,
  });
