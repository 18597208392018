import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { InvitationWidgetWrapper } from 'components/invitation/invitation-widget/wrapper';

export const InvitationModule = angular
  .module('invitationModule', [])
  .component(
    'psInvitationWidget',
    reactToAngular(InvitationWidgetWrapper, [
      'isDisabled',
      'users',
      'onInvite',
      'onSelectSuggestion',
      'config',
      'isBulkEnabled',
      'availableRoles',
      'placeholder',
      'suggestionsAreLoading',
      'inviteButtonConfig',
      'roleSelectorEnabled',
    ]),
  ).name;
