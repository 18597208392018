import { UserType } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, QueryKey } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetTemplateReadPermissionsQueryParams = { templateId: Template['id'] };

export type GetTemplateReadPermissionsQueryResponse = { userType: UserType; template: Template };

export const GetTemplateReadPermissionsQuery = {
  key: ['templates', 'permissions', 'read-template', 'who'],
  getKey: (params: GetTemplateReadPermissionsQueryParams): QueryKey => [...GetTemplateReadPermissionsQuery.key, params],
  queryFn: (params: GetTemplateReadPermissionsQueryParams) =>
    axiosService
      .getAxios()
      .get<GetTemplateReadPermissionsQueryResponse>(`/1/templates/${params.templateId}/permissions/read-template/who`)
      .then(res => res.data),
};

export const useGetTemplateReadPermissionsQuery = <Select = GetTemplateReadPermissionsQueryResponse>(
  params: GetTemplateReadPermissionsQueryParams,
  options: UseQueryOptions<GetTemplateReadPermissionsQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetTemplateReadPermissionsQuery.getKey(params),
    () => GetTemplateReadPermissionsQuery.queryFn(params),
    options,
  );
};
