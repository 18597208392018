import angular from 'angular';

angular.module('frontStreetApp.directives').directive(
  'psCompile',
  $compile =>
    function (scope, element, attributes) {
      scope.$watch(
        () => scope.$eval(attributes.psCompile),
        expression => {
          element.html(expression);
          $compile(element.contents())(scope);
        },
      );
    },
);
