import { Button, Flex, Link, MotionWrapper, Text, useBreakpointValue } from 'components/design/next';
import React from 'react';
import { useInjector } from 'components/injection-provider';
import { useStateParam } from 'hooks/use-state-param';
import { match } from 'ts-pattern';
import { useBackgroundCdnImage } from 'features/forms/hooks/use-background-cdn-image';
import { GetTemplateOverviewQuery } from 'features/template/query-builder';
import { OrganizationLogo } from 'pages/responses/_id/components/organization-logo/organization-logo';
import { inIframe } from 'pages/forms/_id/login/utils';
import { useMount } from 'react-use';

export const FormLoginPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { $state, auth } = useInjector('$state', 'auth');
  const templateId = useStateParam({ key: 'id' });
  const templateOverviewQuery = GetTemplateOverviewQuery.useQuery({ templateId });
  const cdnImage = useBackgroundCdnImage({ templateId });
  const backgroundSize = useBreakpointValue({ base: 'contain', lg: 'cover' });
  const backgroundPosition = useBreakpointValue({ base: 'center top', lg: 'center center' });

  useMount(() => {
    if (auth.isLoggedIn()) {
      $state.go('addFormResponse', { id: templateId });
    }
  });

  const isEmbedded = inIframe();

  const loginHref = isEmbedded
    ? $state.href('login')
    : $state.href('login', { url: $state.href('addFormResponse', { id: templateId }) });

  return (
    <>
      <Flex
        h="100vh"
        justifyContent="center"
        pt={{ base: '32', lg: '0' }}
        pb={{ base: '0', lg: '24' }}
        {...{
          ...match(cdnImage)
            .with({ status: 'loaded' }, ({ src }) => ({
              backgroundImage: `url(${src})`,
            }))
            .with({ lqipStatus: 'loaded' }, ({ lqipSrc }) => ({
              backgroundImage: `url(${lqipSrc}) `,
            }))
            .otherwise(() => {}),
          backgroundRepeat: 'no-repeat',
          backgroundPosition,
          backgroundAttachment: 'fixed',
          backgroundSize,
        }}
        alignItems="center"
        bgColor="brand.100"
      >
        <MotionWrapper layout>
          <Flex
            w={{ base: 'full', lg: '3xl' }}
            minH={{ base: 'calc(100vh - var(--ps-sizes-32))', lg: '77' }}
            h={{ base: 'calc(100vh - var(--ps-sizes-32))', lg: 'fit-content' }}
            bgColor="white"
            borderRadius={{ base: '0', lg: 'lg' }}
            p={{ base: '4', lg: '8' }}
            alignItems="center"
            justifyContent="center"
            direction="column"
            gap="4"
          >
            {templateOverviewQuery.data ? (
              <>
                {templateOverviewQuery.data.description && (
                  <Text color="gray.600" fontWeight="bolder">
                    {templateOverviewQuery.data.description}
                  </Text>
                )}

                <Text color="gray.600">
                  To complete this{' '}
                  <Text as="span" fontWeight="bolder">
                    {templateOverviewQuery.data.name}
                  </Text>{' '}
                  form, you need to login first.
                </Text>
              </>
            ) : (
              <Text>&nbsp;</Text>
            )}

            <Button
              variant="primary"
              as={Link}
              target={isEmbedded ? '_blank' : '_self'}
              aria-label="login"
              mt={4}
              href={loginHref}
              _hover={{
                textDecoration: 'none',
                color: 'white',
              }}
              _visited={{
                textDecoration: 'none',
                color: 'white',
              }}
            >
              Login
            </Button>

            <Flex direction="column" w="full" alignItems="center" mt="4">
              <OrganizationLogo />
            </Flex>
          </Flex>
        </MotionWrapper>
      </Flex>
    </>
  );
};
