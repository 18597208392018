import { createCachedAction } from 'reducers/util/index';
import { createAction } from 'redux-actions';

export const GROUP_MEMBERSHIP_PUT = 'groupMembership/PUT';
export const GROUP_MEMBERSHIP_DELETE = 'groupMembership/DELETE';

export const GROUP_MEMBERSHIP_QUERY = 'groupMembership/QUERY';
export const GROUP_MEMBERSHIP_QUERY_BY_GROUP = 'groupMembership/QUERY_BY_GROUP';
export const GROUP_MEMBERSHIP_QUERY_BY_USER = 'groupMembership/QUERY_BY_USER';
export const GROUP_MEMBERSHIP_GET_ALL_BY_ORGANIZATION_ID = 'groupMembership/GET_ALL_BY_ORGANIZATION_ID';

export const GROUP_MEMBERSHIP_REMOVE_FROM_ALL_GROUPS = 'groupMembership/REMOVE_FROM_ALL_GROUPS';

export const GroupMembershipActions = GroupMembershipApi => {
  'ngInject';

  const queryGroupMembership = createCachedAction(GROUP_MEMBERSHIP_QUERY, GroupMembershipApi.queryGroupMembership);

  const queryGroupMembershipByGroup = createCachedAction(
    GROUP_MEMBERSHIP_QUERY_BY_GROUP,
    GroupMembershipApi.queryGroupMembershipByGroup,
  );

  const queryGroupMembershipByUser = createCachedAction(GROUP_MEMBERSHIP_QUERY_BY_USER, userId =>
    GroupMembershipApi.queryGroupMembershipByUser(userId),
  );

  const getAllByOrganizationMembershipUserId = createCachedAction(
    GROUP_MEMBERSHIP_GET_ALL_BY_ORGANIZATION_ID,
    GroupMembershipApi.getAllByOrganizationMembershipUserId,
    userId => ({ userId }),
  );

  const removeFromAllGroups = createAction(GROUP_MEMBERSHIP_REMOVE_FROM_ALL_GROUPS, membership => ({ membership }));

  return {
    queryGroupMembership,
    queryGroupMembershipByGroup,
    queryGroupMembershipByUser,
    getAllByOrganizationMembershipUserId,
    removeFromAllGroups,
  };
};
