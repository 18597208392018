import { OptionProps, components } from 'react-select';
import { UserType } from '@process-street/subgrade/core';
import { Box, HStack, Text } from 'components/design/next';
import { Avatar, AvatarSize } from 'components/common/Avatar';
import React from 'react';
import { UserOption } from 'utils/user-option-transformer';

export const UserOptionItem = (props: OptionProps<UserOption, false> & { data: UserOption }) => {
  const {
    data: { user },
  } = props;

  const subtitle = user.userType === UserType.Group ? 'Group' : user.email;
  return (
    <components.Option {...props}>
      <HStack opacity={props.isSelected ? '0.4' : '1'}>
        <Box w={6} mr={1}>
          <Avatar user={user} size={AvatarSize.Small} showUnconfirmedBadge />
        </Box>

        <Box flex={1} role="cell" maxW={78}>
          <Text variant="-1" fontWeight="medium" color="gray.600" noOfLines={1}>
            {user.username}
          </Text>

          <Text variant="-2" color="gray.500" noOfLines={1}>
            {subtitle}
          </Text>
        </Box>
      </HStack>
    </components.Option>
  );
};
