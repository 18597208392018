import { LogoSpinner } from 'components/common/LogoSpinner';
import React from 'react';

export enum SpinnerState {
  Authorizing = 'Authorizing',
  Success = 'Success',
  Failure = 'Failure',
}

interface SlackAuthorizeSpinnerProps {
  state: SpinnerState;
}

export const SlackAuthorizeSpinner: React.FC<React.PropsWithChildren<SlackAuthorizeSpinnerProps>> = ({ state }) => {
  switch (state) {
    case SpinnerState.Authorizing:
      return <LogoSpinner text="Connecting Process Street to Slack&hellip;" />;
    case SpinnerState.Success:
      return (
        <>
          <h1>🥰</h1>
          <div>Process Street is now connected to Slack.</div>
        </>
      );
    default:
      return (
        <>
          <h1>😢</h1>
          <div>We weren't able to connect Process Street to Slack.</div>
        </>
      );
  }
};
