import React from 'react';
import { isNotIdRef, User } from '@process-street/subgrade/core';

import { HStack, Icon, ListItem, Text, VStack } from 'components/design/next';

import { ContentText } from '../content-text';
import { AuditInfoStack } from '../audit-info';

import { useInjector } from 'components/injection-provider';
import { Activity, CommentActivityData } from '@process-street/subgrade/activity';

export type OverviewCommentItemProps = {
  activity: Activity;
  currentUserId: User['id'];
  type: 'checklist' | 'template';
};

export const OverviewCommentItem: React.FC<React.PropsWithChildren<OverviewCommentItemProps>> = ({
  activity,
  type,
}) => {
  const { $state } = useInjector('$state');

  const { data, subject, date, id } = activity;

  const { content, checklistId, checklistName, taskTemplateName, commentId, taskTemplateGroupId } =
    data as CommentActivityData;

  const createdBy = isNotIdRef(subject) ? subject : ({} as User);

  return (
    <ListItem
      key={id}
      onClick={e => {
        e.stopPropagation();
        $state.go('checklist.task.comment', {
          id: checklistId,
          groupId: taskTemplateGroupId,
          commentId,
        });
      }}
      cursor="pointer"
    >
      <HStack alignItems="flex-start" spacing="3" width="full" justifyContent="space-between" minW="0" flex="1">
        <VStack alignItems="flex-start" width="full">
          <AuditInfoStack {...{ createdBy, createdDate: date }} />
          <ContentText
            {...{
              content: content!,
              checklistId,
              minW: '0',
              flex: '1',
              pl: '9',
              variant: '-1',
              mt: '0',
            }}
          />
          {type === 'checklist' ? (
            <Text variant="-2" color="gray.500" pl="9">
              {taskTemplateName}
            </Text>
          ) : (
            <HStack pl="8">
              <Icon size="3" icon="play" variant="far" color="gray.300" />
              <Text variant="-2" color="gray.500">
                {checklistName}
              </Text>
            </HStack>
          )}
        </VStack>
      </HStack>
    </ListItem>
  );
};
