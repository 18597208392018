export const EventName = {
  // Templates
  TEMPLATE_CREATE_OK: 'template create ok',
  TEMPLATE_UPDATE_OK: 'template update ok',

  PAGE_VIEWED: 'page viewed',

  TEMPLATE_VIEWED: 'template viewed',
  TEMPLATE_SIDEBAR_TOGGLED: 'template sidebar toggled',

  TEMPLATE_MOVED: 'template moved',

  TEMPLATE_DELETE_OK: 'template delete ok',
  TEMPLATE_DELETE_FAILED: 'template delete failed',
  TEMPLATE_DELETE_UNDO: 'template delete undo',

  TEMPLATE_UNDELETE_OK: 'template undelete ok',
  TEMPLATE_UNDELETE_FAILED: 'template undelete failed',
  TEMPLATE_SHOW_PAYWALL: 'template show paywall',

  TEMPLATE_PERMIT_CREATE_OK: 'template permit create ok',
  TEMPLATE_PERMIT_DELETE_OK: 'template permit delete ok',

  // Tasks

  TASK_STATUS_UPDATE_STARTED: 'task status update started',
  TASK_STATUS_UPDATE_OK: 'task status update ok',
  TASK_STATUS_UPDATE_FAILED: 'task status update failed',

  TASK_STATUS_MANY_UPDATE_STARTED: 'task status many update started',
  TASK_STATUS_MANY_UPDATE_OK: 'task status many update ok',
  TASK_STATUS_MANY_UPDATE_FAILED: 'task status many update failed',

  TASK_DUE_DATE_UPDATE_STARTED: 'task due date update started',
  TASK_DUE_DATE_UPDATE_OK: 'task due date update ok',
  TASK_DUE_DATE_UPDATE_FAILED: 'task due update failed',

  CHECKLIST_DUE_DATE_UPDATE_OK: 'checklist due date update ok',

  TASK_DYNAMIC_DUE_DATE_UPDATED: 'task dynamic due date updated',
  CHECKLIST_DYNAMIC_DUE_DATE_UPDATED: 'checklist dynamic due date updated',

  TASK_HIDDEN_UPDATED: 'task hidden updated',

  // Task Templates

  TASK_TEMPLATE_CREATE_STARTED: 'task template create started',
  TASK_TEMPLATE_CREATE_OK: 'task template create ok',

  TASK_TEMPLATE_UPDATE_OK: 'task template update ok',

  TASK_TEMPLATE_BULK_MOVE_OK: 'task template bulk move ok',

  TASK_TEMPLATE_BULK_DELETE_STARTED: 'task template bulk delete started',
  TASK_TEMPLATE_BULK_DELETE_OK: 'task template bulk delete ok',
  TASK_TEMPLATE_BULK_DELETE_FAILED: 'task template bulk delete failed',
  TASK_TEMPLATE_BULK_DELETE_FINISHED: 'task template bulk delete finished',

  TASK_TEMPLATE_BULK_ASSIGN_STARTED: 'task template bulk assign started',
  TASK_TEMPLATE_BULK_ASSIGN_OK: 'task template bulk assign ok',

  TASK_TEMPLATE_BULK_UNASSIGN_STARTED: 'task template bulk unassign started',
  TASK_TEMPLATE_BULK_UNASSIGN_OK: 'task template bulk unassign ok',

  TASKS_CHECKED_UNCHECKED: 'tasks checked/unchecked',
  TASK_ASSIGNED_UNASSIGNED: 'task assigned/unassigned',

  // Widgets

  WIDGET_CREATE_STARTED: 'widget create started',
  WIDGET_CREATE_OK: 'widget create ok',
  WIDGET_CREATE_FAILED: 'widget create failed',
  WIDGET_CREATE_FINISHED: 'widget create finished',

  WIDGET_UPDATE_STARTED: 'widget update started',
  WIDGET_UPDATE_OK: 'widget update ok',
  WIDGET_UPDATE_FAILED: 'widget update failed',
  WIDGET_UPDATE_FINISHED: 'widget update finished',
  TEXT_WIDGET_CONTENT_GENERATION_OK: 'text widget content generation ok',
  WIDGET_UPDATE_OVERRIDE: 'widget update override',
  WIDGET_UPDATE_SETTINGS: 'widget update settings',

  WIDGET_DELETE_STARTED: 'widget delete started',
  WIDGET_DELETE_OK: 'widget delete ok',
  WIDGET_DELETE_FAILED: 'widget delete failed',
  WIDGET_DELETE_FINISHED: 'widget delete finished',

  WIDGET_UPLOAD_FAILED: 'widget upload failed',

  WIDGET_UPLOAD_INVALID_FILE_TYPE: 'widget upload invalid file type',

  WIDGET_DROP_ADD: 'widget drop add',
  WIDGET_DROP_PROCESS_DONE: 'widget drop process done',
  WIDGET_DROP_PROCESS_FAIL: 'widget drop process fail',
  WIDGET_DROP_PROGRESS: 'widget drop progress',
  WIDGET_DROP_DONE: 'widget drop done',
  WIDGET_DROP_FAIL: 'widget drop fail',

  // Media Menu

  MEDIA_MENU_CREATE_IMAGE_CLICKED: 'media menu create image clicked',
  MEDIA_MENU_CREATE_VIDEO_CLICKED: 'media menu create video clicked',
  MEDIA_MENU_CREATE_FILE_CLICKED: 'media menu create file clicked',

  // Organizations

  ORGANIZATION_CREATED: 'organization created',
  ORGANIZATION_REMOVED: 'organization removed',
  ORGANIZATION_NAME_UPDATED: 'organization name updated',
  ORGANIZATION_MEMBERSHIP_UPDATED: 'organization membership updated',
  ORGANIZATION_MEMBERSHIP_DELETED: 'organization membership deleted',
  ORGANIZATION_UPDATED: 'organization updated',

  ORGANIZATION_MEMBERSHIP_CREATE_FAILED: 'organization membership create failed',

  USER_UPDATED: 'user updated',

  SELECTED_ORGANIZATION_UPDATED: 'selected organization updated',

  SELECTED_ORGANIZATION_STATS_UPDATED: 'selected organization stats updated',

  ORGANIZATION_SWITCHED: 'switched organization',

  ORGANIZATION_STATS_UPDATED: 'organization stats updated',

  // Folders

  FOLDER_UPDATED: 'folder updated',
  FOLDER_UPDATE_FAILED: 'folder update failed',
  FOLDER_DELETED: 'folder deleted',
  FOLDER_DELETE_FAILED: 'folder delete failed',

  GROUP_UPDATED: 'group updated',

  PLAN_UPDATED: 'plan updated',

  TAG_UPDATED: 'tag updated',

  DEFAULT_VALUE_SETTINGS_CLICK: 'default value settings click',
  AUTO_SEND_RICH_EMAIL_TOGGLE: 'auto send rich email toggle',

  // Sandbox
  SANDBOX_USER_CHANGE: 'sandbox user change',
} as const;
export type EventName = typeof EventName;
export type EventNameKey = keyof EventName;
export type EventNameValue = EventName[EventNameKey];
