import { Muid, MuidUtils } from '../core';
import { ChecklistColumn } from './columns';

export enum ProgressStatus {
  Archived = 'Archived',
  Completed = 'Completed',
  DueSoon = 'DueSoon',
  OnTrack = 'OnTrack',
  Overdue = 'Overdue',
}

export interface Filter {
  id: Muid;
  parentId?: Muid;
  filterType: FilterType;
  children?: Filter[];
}

export enum FilterType {
  Or = 'Or',
  And = 'And',
  Clause = 'Clause',
}

export interface OrFilter extends Filter {
  filterType: FilterType.Or;
  children?: Filter[];
}

export interface AndFilter extends Filter {
  filterType: FilterType.And;
  children?: Filter[];
}

export interface Clause extends Filter {
  filterType: FilterType.Clause;
  columnName: ChecklistColumn;
  condition: Condition;
  operand: ConditionalFilterOperand;
}

export enum Condition {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  Contains = 'Contains',
  DoesNotContain = 'DoesNotContain',
  IsBefore = 'IsBefore',
  IsAfter = 'IsAfter',
  IsBetween = 'IsBetween',
}

export const conditionNameMap: { [key in Condition]: string } = {
  [Condition.Equal]: 'is equal',
  [Condition.NotEqual]: 'is not equal',
  [Condition.GreaterThan]: 'is greater than',
  [Condition.LessThan]: 'is less than',
  [Condition.Contains]: 'contains',
  [Condition.DoesNotContain]: 'does not contain',
  [Condition.IsBefore]: 'is before',
  [Condition.IsAfter]: 'is after',
  [Condition.IsBetween]: 'is between',
};

const checklistColumnToConditionMap: { [key in ChecklistColumn]: Condition[] } = {
  [ChecklistColumn.Assignees]: [Condition.Contains, Condition.DoesNotContain],
  [ChecklistColumn.ChecklistLabelId]: [Condition.Contains, Condition.DoesNotContain],
  [ChecklistColumn.ChecklistCompletedBy]: [],
  [ChecklistColumn.ChecklistCompletedDate]: [Condition.IsBefore, Condition.IsAfter, Condition.IsBetween],
  [ChecklistColumn.ChecklistCreateDate]: [Condition.IsBefore, Condition.IsAfter, Condition.IsBetween],
  [ChecklistColumn.ChecklistDueDate]: [Condition.IsBefore, Condition.IsAfter, Condition.IsBetween],
  [ChecklistColumn.ChecklistName]: [Condition.Contains, Condition.Equal, Condition.NotEqual, Condition.DoesNotContain],
  [ChecklistColumn.CompletedTasksCount]: [Condition.Equal, Condition.GreaterThan, Condition.LessThan],
  [ChecklistColumn.LastActiveDate]: [Condition.IsBefore, Condition.IsAfter, Condition.IsBetween],
  [ChecklistColumn.OverdueTasksCount]: [Condition.Equal, Condition.GreaterThan, Condition.LessThan],
  [ChecklistColumn.ProgressStatus]: [Condition.Contains, Condition.DoesNotContain],
  [ChecklistColumn.Selection]: [],
  [ChecklistColumn.TemplateName]: [Condition.Contains, Condition.Equal, Condition.NotEqual, Condition.DoesNotContain],
  [ChecklistColumn.TemplateVersion]: [],
  [ChecklistColumn.TotalCommentsAndAttachmentsCount]: [],
};

const getConditionsForChecklistColumn = (column: ChecklistColumn) => checklistColumnToConditionMap[column] ?? [];

export type ConditionalFilterOperand =
  | {
      operandType: ConditionalFilterOperandType.String;
      value: string;
    }
  | {
      operandType: ConditionalFilterOperandType.DateTime;
      value: number;
    }
  | {
      operandType: ConditionalFilterOperandType.DateTimeRange;
      value: DateTimeRange;
    }
  | {
      operandType: ConditionalFilterOperandType.ChecklistLabelList;
      value: string[];
    }
  | {
      operandType: ConditionalFilterOperandType.AssigneeListOperand;
      value: string[];
    }
  | {
      operandType: ConditionalFilterOperandType.Integer;
      value: number;
    }
  | {
      operandType: ConditionalFilterOperandType.ChecklistStatusList;
      value: ProgressStatus[];
    };

export enum ConditionalFilterOperandType {
  String = 'String',
  DateTime = 'DateTime',
  DateTimeRange = 'DateTimeRange',
  Integer = 'Integer',
  ChecklistStatusList = 'ChecklistStatusList',
  ChecklistLabelList = 'ChecklistLabelList',
  AssigneeListOperand = 'AssigneeListOperand',
}

export type ConditionalFilterOperandValue = ConditionalFilterOperand['value'];

export interface ConditionalFilterStringOperand
  extends Extract<ConditionalFilterOperand, { operandType: ConditionalFilterOperandType.String }> {}

export interface ConditionalFilterSingleDateOperand
  extends Extract<ConditionalFilterOperand, { operandType: ConditionalFilterOperandType.DateTime }> {}

export interface DateTimeRange {
  from?: number;
  to?: number;
}
export interface ConditionalFilterDateTimeRangeOperand
  extends Extract<ConditionalFilterOperand, { operandType: ConditionalFilterOperandType.DateTimeRange }> {}

export interface ConditionalFilterChecklistLabelListOperand
  extends Extract<ConditionalFilterOperand, { operandType: ConditionalFilterOperandType.ChecklistLabelList }> {}

export interface ConditionalFilterAssigneeListOperand
  extends Extract<ConditionalFilterOperand, { operandType: ConditionalFilterOperandType.AssigneeListOperand }> {}

export interface ConditionalFilterIntegerOperand
  extends Extract<ConditionalFilterOperand, { operandType: ConditionalFilterOperandType.Integer }> {}

export interface ConditionalFilterProgressStatusOperand
  extends Extract<ConditionalFilterOperand, { operandType: ConditionalFilterOperandType.ChecklistStatusList }> {}

const getProgressStatusListOperand = (value: ProgressStatus[]): ConditionalFilterProgressStatusOperand => ({
  operandType: ConditionalFilterOperandType.ChecklistStatusList,
  value,
});

const createProgressStatusFilter = (condition: Condition, value: ProgressStatus[], parentId?: Muid): Clause => ({
  id: MuidUtils.randomMuid(),
  filterType: FilterType.Clause,
  operand: getProgressStatusListOperand(value),
  columnName: ChecklistColumn.ProgressStatus,
  condition,
  parentId,
});

const getStringOperand = (value: string): ConditionalFilterStringOperand => ({
  operandType: ConditionalFilterOperandType.String,
  value,
});

const getSingleDateOperand = (value: number): ConditionalFilterSingleDateOperand => ({
  operandType: ConditionalFilterOperandType.DateTime,
  value,
});

const getDateRangeOperand = (from?: number, to?: number): ConditionalFilterDateTimeRangeOperand => ({
  operandType: ConditionalFilterOperandType.DateTimeRange,
  value: { from, to },
});

const getAssigneeListOperand = (value: Muid[]): ConditionalFilterAssigneeListOperand => ({
  operandType: ConditionalFilterOperandType.AssigneeListOperand,
  value,
});

const getIntegerOperand = (value: number): ConditionalFilterIntegerOperand => ({
  operandType: ConditionalFilterOperandType.Integer,
  value,
});

const getLabelListOperand = (value: Muid[]): ConditionalFilterChecklistLabelListOperand => ({
  operandType: ConditionalFilterOperandType.ChecklistLabelList,
  value,
});

const now = () => new Date().getTime();

type OperandMapValueType = {
  [key in Condition]?: ConditionalFilterOperand;
};
interface OperandMapValue extends OperandMapValueType {
  default: ConditionalFilterOperand;
}

const checklistColumnToOperandMap: { [key in ChecklistColumn]: OperandMapValue } = {
  [ChecklistColumn.Selection]: { default: getStringOperand('') },
  [ChecklistColumn.Assignees]: { default: getAssigneeListOperand([]) },
  [ChecklistColumn.ChecklistCompletedBy]: { default: getStringOperand('') },
  [ChecklistColumn.ChecklistCompletedDate]: {
    default: getSingleDateOperand(now()),
    [Condition.IsBetween]: getDateRangeOperand(),
  },
  [ChecklistColumn.ChecklistCreateDate]: {
    default: getSingleDateOperand(now()),
    [Condition.IsBetween]: getDateRangeOperand(),
  },
  [ChecklistColumn.ChecklistDueDate]: {
    default: getSingleDateOperand(now()),
    [Condition.IsBetween]: getDateRangeOperand(),
  },
  [ChecklistColumn.ChecklistName]: { default: getStringOperand('') },
  [ChecklistColumn.TemplateName]: { default: getStringOperand('') },
  [ChecklistColumn.CompletedTasksCount]: { default: getIntegerOperand(0) },
  [ChecklistColumn.LastActiveDate]: {
    default: getSingleDateOperand(now()),
    [Condition.IsBetween]: getDateRangeOperand(),
  },
  [ChecklistColumn.OverdueTasksCount]: { default: getIntegerOperand(0) },
  [ChecklistColumn.TotalCommentsAndAttachmentsCount]: { default: getIntegerOperand(0) },
  [ChecklistColumn.ProgressStatus]: { default: getProgressStatusListOperand([]) },
  [ChecklistColumn.TemplateVersion]: { default: getStringOperand('') },
  [ChecklistColumn.ChecklistLabelId]: { default: getLabelListOperand([]) },
};

export const resolveConditionalFilterOperand = (
  column: ChecklistColumn,
  condition: Condition,
): ConditionalFilterOperand => {
  const mapValue = checklistColumnToOperandMap[column];

  return mapValue[condition] ?? mapValue.default;
};

export const initialConditionalFilter: AndFilter = {
  children: [],
  filterType: FilterType.And,
  id: MuidUtils.randomMuid(),
};

const initialConditionalFilterFormResponses: AndFilter = (() => {
  const parentId = MuidUtils.randomMuid();
  return {
    children: [
      {
        id: MuidUtils.randomMuid(),
        filterType: FilterType.Clause,
        columnName: ChecklistColumn.ProgressStatus,
        condition: Condition.Contains,
        operand: { operandType: ConditionalFilterOperandType.ChecklistStatusList, value: [ProgressStatus.Completed] },
      } as Clause,
    ],
    filterType: FilterType.And,
    id: parentId,
  };
})();

const initialConditionalFilterActiveWorkflowRuns: AndFilter = (() => {
  return {
    children: [
      {
        id: MuidUtils.randomMuid(),
        filterType: FilterType.Clause,
        columnName: ChecklistColumn.ProgressStatus,
        condition: Condition.Contains,
        operand: {
          operandType: ConditionalFilterOperandType.ChecklistStatusList,
          value: [ProgressStatus.OnTrack, ProgressStatus.DueSoon, ProgressStatus.Overdue],
        },
      } as Clause,
    ],
    filterType: FilterType.And,
    id: MuidUtils.randomMuid(),
  };
})();

const isClauseFilter = (filter: Filter): filter is Clause => filter.filterType === FilterType.Clause;

const isAndFilter = (filter: Filter): filter is AndFilter => filter.filterType === FilterType.And;

const isOrFilter = (filter: Filter): filter is OrFilter => filter.filterType === FilterType.Or;

const getClauseConditionAndOperand = ({
  column,
  condition: currentCondition,
  operand: currentOperand,
}: {
  column: ChecklistColumn;
  condition?: Condition;
  operand?: ConditionalFilterOperand;
}) => {
  const availableConditions = getConditionsForChecklistColumn(column);
  const condition =
    currentCondition && availableConditions.includes(currentCondition) ? currentCondition : availableConditions[0];
  const updatedOperand = resolveConditionalFilterOperand(column, condition);
  // if the same operand type, copy the value
  if (updatedOperand.operandType === currentOperand?.operandType) {
    updatedOperand.value = currentOperand.value;
  }

  return { condition, operand: updatedOperand };
};

export const ConditionalFilterUtils = {
  createProgressStatusFilter,
  getAssigneeListOperand,
  getClauseConditionAndOperand,
  getConditionsForChecklistColumn,
  getDateRangeOperand,
  getIntegerOperand,
  getLabelListOperand,
  getProgressStatusListOperand,
  getSingleDateOperand,
  getStringOperand,
  initialConditionalFilter,
  initialConditionalFilterActiveWorkflowRuns,
  initialConditionalFilterFormResponses,
  isAndFilter,
  isClauseFilter,
  isOrFilter,
  resolveConditionalFilterOperand,
};
