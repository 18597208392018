import { Muid, Option } from '@process-street/subgrade/core';
import {
  ChecklistWidget,
  FieldType,
  isFormFieldWidget,
  TaskTemplate,
  Widget,
  WidgetHeader,
  WidgetUtils,
} from '@process-street/subgrade/process';
import { safeEntityMapToArrayByIdsWith } from '@process-street/subgrade/redux/safe-entity-map-to-array-by-ids';
import { BaseWidgetSelector } from '@process-street/subgrade/redux/selector/widget.selectors';
import { EntityMap, LookupMap, ObjectMap } from '@process-street/subgrade/redux/types';
import { ChecklistRevisionSelector } from 'reducers/checklist-revision/checklist-revision.selectors';
import { safeEntityMapToArrayByIds } from 'reducers/entities/safe-entity-map-to-array-by-ids';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { ReduxAppState, Status, WidgetStatuses } from 'reducers/types';
import { createSelector, Selector } from 'reselect';
import { ChecklistWidgetSelector } from 'components/widgets/store/checklist-widget.selector';

const getStatuses = (state: ReduxAppState): WidgetStatuses => state.statuses.widgets;

const getLookupByTaskTemplateId = (state: ReduxAppState): LookupMap => state.lookups.widget.byTaskTemplateId;
const getLookupByChecklistRevisionId = (state: ReduxAppState): LookupMap => state.lookups.widget.byChecklistRevisionId;

const getAllIdsByTemplateRevisionId = (templateRevisionId: Muid): Selector<ReduxAppState, Muid[]> =>
  createSelector(
    [TaskTemplateSelector.getAllLookupByTemplateRevisionId(templateRevisionId), getLookupByTaskTemplateId],
    (tasks: Muid[], widgetsByTaskTemplate: LookupMap): Muid[] =>
      tasks.reduce((agg: Muid[], taskId: Muid) => {
        if (widgetsByTaskTemplate[taskId]) {
          agg.push(...widgetsByTaskTemplate[taskId]);
        }
        return agg;
      }, []),
  );

const getAllIdsByChecklistRevisionId =
  (checklistRevisionId: Muid) =>
  (state: ReduxAppState): Muid[] =>
    getLookupByChecklistRevisionId(state)[checklistRevisionId] || [];

const getAllByTemplateRevisionId = (templateRevisionId: Muid): Selector<ReduxAppState, Widget[]> =>
  createSelector(
    [BaseWidgetSelector.getEntityMap, getAllIdsByTemplateRevisionId(templateRevisionId)],
    (widgetsMap, widgetIds) => safeEntityMapToArrayByIds(widgetsMap, widgetIds),
  );

const getAllByChecklistRevisionId = (checklistRevisionId: Muid): Selector<ReduxAppState, Widget[]> =>
  createSelector(
    [BaseWidgetSelector.getEntityMap, getAllIdsByChecklistRevisionId(checklistRevisionId)],
    (widgetsMap, widgetIds) => safeEntityMapToArrayByIds(widgetsMap, widgetIds),
  );

const combineByIdWithTaskTemplates = (
  widgetIds: Muid[],
  widgetsMap: EntityMap<Widget>,
  taskTemplateMap: EntityMap<TaskTemplate>,
  checklistWidgets?: ChecklistWidget[],
) => {
  const hiddenWidgetMap = checklistWidgets
    ? checklistWidgets.reduce((agg: { [groupId: string]: boolean }, checklistWidget) => {
        agg[checklistWidget.groupId] = checklistWidget.hidden;

        return agg;
      }, {})
    : {};
  return safeEntityMapToArrayByIdsWith<Widget>(widgetsMap, widgetIds, (widget: Widget) => {
    const taskTemplate = taskTemplateMap[widget.header.taskTemplate.id];
    const widgetIsHidden = !!hiddenWidgetMap[widget.header.group.id];
    if (taskTemplate && !widgetIsHidden) {
      const header: WidgetHeader = { ...widget.header, taskTemplate };
      return { ...widget, header } as Widget;
    }
    return undefined;
  });
};

const getAllWithTaskTemplateByTemplateRevisionId = (templateRevisionId: Muid): Selector<ReduxAppState, Widget[]> =>
  createSelector(
    [
      getAllIdsByTemplateRevisionId(templateRevisionId),
      BaseWidgetSelector.getEntityMap,
      TaskTemplateSelector.getEntityMap,
    ],
    combineByIdWithTaskTemplates,
  );

const getTaskTemplateGroupIdToWidgetByChecklistRevisionId =
  (checklistRevisionId: Muid, visibleOnly = false): Selector<ReduxAppState, EntityMap<Widget[]>> =>
  (state: ReduxAppState) => {
    const checklistRevision = ChecklistRevisionSelector.getById(checklistRevisionId)(state);
    if (!checklistRevision) {
      return {};
    }

    const allWidgets = getAllByTemplateRevisionId(checklistRevision.templateRevision.id)(state);
    const taskTemplates = TaskTemplateSelector.getAllByTemplateRevisionId(checklistRevision.templateRevision.id)(state);

    const checklistWidgets = ChecklistWidgetSelector.getAllByChecklistRevisionId(checklistRevisionId)(state);
    const visibleWidgets = visibleOnly ? WidgetUtils.filterVisibleWidgets(allWidgets, checklistWidgets) : allWidgets;

    const widgetByTaskTemplate: ObjectMap<Widget[]> = visibleWidgets.reduce((agg: ObjectMap<Widget[]>, widget) => {
      if (agg[widget.header.taskTemplate.id]) {
        agg[widget.header.taskTemplate.id].push(widget);
      } else {
        agg[widget.header.taskTemplate.id] = [widget];
      }
      return agg;
    }, {});

    const widgetsByTaskTemplateGroupId = taskTemplates.reduce((agg: EntityMap<Widget[]>, taskTemplate) => {
      agg[taskTemplate.group.id] = widgetByTaskTemplate[taskTemplate.id] || [];
      return agg;
    }, {});

    return widgetsByTaskTemplateGroupId;
  };

const getAllWithTaskTemplateByChecklistRevisionId = (
  checklistRevisionId: Muid,
  excludeHidden = false,
): Selector<ReduxAppState, Widget[]> =>
  createSelector(
    [
      getAllIdsByChecklistRevisionId(checklistRevisionId),
      BaseWidgetSelector.getEntityMap,
      TaskTemplateSelector.getEntityMap,
      ChecklistWidgetSelector.getAllByChecklistRevisionId(checklistRevisionId),
    ],
    (
      widgetIds: Muid[],
      widgetsMap: EntityMap<Widget>,
      taskTemplateMap: EntityMap<TaskTemplate>,
      checklistWidgets: ChecklistWidget[],
    ) =>
      combineByIdWithTaskTemplates(
        widgetIds,
        widgetsMap,
        taskTemplateMap,
        excludeHidden ? checklistWidgets : undefined,
      ),
  );

const getAllEmailAndMembersWidgetsByTemplateRevisionId = (revisionId: Muid): Selector<ReduxAppState, Widget[]> =>
  createSelector(getAllByTemplateRevisionId(revisionId), widgets =>
    widgets.filter(w => isFormFieldWidget(w) && (w.fieldType === FieldType.Email || w.fieldType === FieldType.Members)),
  );

const getAllMembersFieldWidgetsByTemplateRevisionId = (templateRevisionId: Muid): Selector<ReduxAppState, Widget[]> =>
  createSelector(getAllByTemplateRevisionId(templateRevisionId), widgets =>
    widgets.filter(w => isFormFieldWidget(w) && w.fieldType === FieldType.Members),
  );

const getAllFormFieldWidgetsByTemplateRevisionId = (
  revisionId: Muid,
  excludedTypes: FieldType[],
): Selector<ReduxAppState, Widget[]> =>
  createSelector(getAllByTemplateRevisionId(revisionId), widgets =>
    widgets.filter(w => isFormFieldWidget(w) && !(excludedTypes || []).includes(w.fieldType)),
  );

const getStatusByTemplateRevisionId = (templateRevisionId: Muid): Selector<ReduxAppState, Option<Status>> =>
  createSelector(getStatuses, status => status.byTemplateRevisionId[templateRevisionId]);

const getLoadedStatusByTemplateRevisionId = (templateRevisionId: Muid): Selector<ReduxAppState, boolean> =>
  createSelector(getStatusByTemplateRevisionId(templateRevisionId), (status: Option<Status>) => {
    if (!status) {
      return false;
    }
    return status.loaded;
  });

export const WidgetSelector = {
  getAllByChecklistRevisionId,
  getAllByTaskTemplateId: BaseWidgetSelector.getAllByTaskTemplateId,
  getAllByTemplateRevisionId,
  getAllEmailAndMembersWidgetsByTemplateRevisionId,
  getAllFormFieldWidgetsByTemplateRevisionId,
  getAllMembersFieldWidgetsByTemplateRevisionId,
  getAllWithTaskTemplateByChecklistRevisionId,
  getAllWithTaskTemplateByTemplateRevisionId,
  getByHeaderId: BaseWidgetSelector.getByHeaderId,
  getById: BaseWidgetSelector.getById,
  getEntityMap: BaseWidgetSelector.getEntityMap,
  getLoadedStatusByTemplateRevisionId,
  getTaskTemplateGroupIdToWidgetByChecklistRevisionId,
};
