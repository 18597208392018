import { Skeleton, Table, Tbody, Thead, Td, Th, Tr } from 'components/design/next';
import * as React from 'react';

export const TableLoadingSkeleton = () => {
  const row = (
    <Tr>
      <Td>
        <Skeleton h="4" w="220px" />
      </Td>
      <Td>
        <Skeleton h="4" w="140px" />
      </Td>
      <Td>
        <Skeleton h="4" w="200px" />
      </Td>
    </Tr>
  );

  return (
    <Table
      w="max-content"
      sx={{
        'th': {
          bgColor: 'brand.100',
          h: '12',
        },
        'th, td': {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'gray.100',
          borderCollapse: 'collapse',
        },
      }}
    >
      <Thead>
        <Tr>
          <Th>
            <Skeleton h="4" w="40" />
          </Th>
          <Th>
            <Skeleton h="4" w="60" />
          </Th>
          <Th>
            <Skeleton h="4" w="30" />
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {row}
        {row}
        {row}
      </Tbody>
    </Table>
  );
};
