import React from 'react';
import { ApiKey } from '@process-street/subgrade/api-keys';
import { ApiKeyField } from './api-key-field';
import { Box, Divider, Flex, Grid, Text } from 'components/design/next';
import { ApiLabelField } from './api-label-field';
import { DeleteApiButton } from './delete-api-button';

interface ApiKeysListProps {
  apiKeys: ApiKey[];
}
export const ApiKeysList: React.FC<React.PropsWithChildren<ApiKeysListProps>> = ({ apiKeys }) => {
  return apiKeys.length > 0 ? (
    <>
      <Grid display={{ base: 'none', md: 'grid' }} w="full" gap="4" gridTemplateColumns="1fr 1fr 40px">
        <Box>
          <Text as="span" color="gray.600" variant="-1u">
            Label
          </Text>
        </Box>
        <Box>
          <Text as="span" color="gray.600" variant="-1u">
            API Key
          </Text>
        </Box>
      </Grid>
      <Divider />
      <Flex data-testid="api-key-list" w="full" gap="4" flexDirection="column">
        {apiKeys.map(apiKey => {
          const apiKeyValue = `api_${apiKey.id}`;
          const apiKeyLabel = apiKey.user.username;
          return (
            <Grid
              key={`api-key-${apiKey.id}`}
              gap={{ base: 1, md: 4 }}
              gridTemplateColumns={{ base: '1fr 24px', md: '1fr 40px' }}
              w="full"
            >
              <Flex flexDirection={{ base: 'column', md: 'row' }} w="full" gap="4">
                <ApiLabelField apiKeyLabel={apiKeyLabel} id={apiKey.id} />
                <ApiKeyField apiKeyValue={apiKeyValue} />
              </Flex>
              <DeleteApiButton apiKey={apiKey.id} apiKeyLabel={apiKeyLabel} />
            </Grid>
          );
        })}
      </Flex>
    </>
  ) : (
    <Text>There's no API keys in this organization. Maybe you should generate one?</Text>
  );
};
