import * as React from 'react';
import { Image, HStack } from 'components/design/next';
import { NgLink } from '../ng-link';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useStateParam } from 'hooks/use-state-param';
import { useGetInvitationQuery } from 'features/invitations/query-builder';
import { match, P } from 'ts-pattern';

export interface MinbarProps {}

export const Minbar: React.FC<React.PropsWithChildren<MinbarProps>> = () => {
  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const invitationId = useStateParam({ key: 'id', stateName: 'completeSignUp.invitation' });
  const invitationQuery = useGetInvitationQuery({ invitationId }, { staleTime: 1000 * 60 });
  const org = match(invitationQuery.data)
    .with(
      // only prefer the invitation's organization if it has a logo and custom brand color
      { theme: { brandColor: P.union(P.string, P.not('')) }, organization: { logoFile: { url: P.string } } },
      ({ organization }) => organization,
    )
    .otherwise(() => selectedOrganization);
  const logoUrl = org?.logoFile?.url ?? '../../../../images/ps-logo-blue.svg';

  return (
    <HStack role="navigation" py="3" px="4" border="none" borderBottom="2px solid" borderColor="gray.300" h="16">
      <NgLink h="full" display="flex" alignItems="center" to="dashboard" options={{ reload: true }}>
        <Image maxH="full" src={logoUrl} alt={`${org?.name ?? 'Process Street'} logo`} />
      </NgLink>
    </HStack>
  );
};
