import { FutureChecklistJob, FutureChecklistMonthBy } from '@process-street/subgrade/process';
import { dayjs as moment } from '@process-street/subgrade/util';
import { match, P } from 'ts-pattern';

const getWeekOfMonth = (someMoment: moment.Dayjs) => {
  return Math.ceil(someMoment.date() / 7);
};

//TODO Check if we can replace with dayjs utils
const getOrdinal = (n: number) => {
  if (n > 3 && n < 21) {
    return `${n}th`;
  }
  const suffix = n % 10;
  switch (suffix) {
    case 1:
      return `${n}st`;
    case 2:
      return `${n}nd`;
    case 3:
      return `${n}rd`;
    default:
      return `${n}th`;
  }
};
export const generateSummary = ({ startDate, jobs }: { startDate: number; jobs: FutureChecklistJob[] }) => {
  const startMoment = moment(startDate);
  const [firstJob] = jobs;

  if (!firstJob) return `Once on ${startMoment.format('ddd MMM D YYYY')}`;

  return match(jobs)
    .with(P.array({ dayInterval: P.number }), () => `Every ${firstJob.dayInterval} day(s)`)
    .with(P.array({ weekInterval: P.number }), () => {
      const weekDayNames = jobs.map(job =>
        moment()
          .day(job.weekDay as number)
          .format('dddd'),
      );
      return `Every ${firstJob.weekInterval} week(s) on ${weekDayNames.join(', ')}`;
    })
    .with(
      P.array({ monthInterval: P.number, monthBy: FutureChecklistMonthBy.DAY_OF_MONTH }),
      () => `Every ${firstJob.monthInterval} month(s) on day ${startMoment.date()}`,
    )
    .with(P.array({ monthInterval: P.number, monthBy: FutureChecklistMonthBy.DAY_OF_WEEK }), () => {
      const weekOfMonth = getWeekOfMonth(startMoment);
      const startMomentIsLastWeek =
        weekOfMonth >= 4 && startMoment.clone().add(7, 'days').month() !== startMoment.month();
      const adjective = startMomentIsLastWeek ? 'last' : getOrdinal(weekOfMonth);

      return `Every ${firstJob.monthInterval} month(s) on the ${adjective} ${moment()
        .day(startMoment.day())
        .format('dddd')}`;
    })
    .with(
      P.array({ yearInterval: P.number }),
      () => `Every ${firstJob.yearInterval} year(s) on ${startMoment.format('MMM D')}`,
    )
    .run();
};
