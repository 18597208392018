import * as React from 'react';
import { Muid, TypeOfEnumValue } from '@process-street/subgrade/core';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { MergeTagsServiceUtils } from 'services/merge-tags';
import { useMergeTagsByTemplateRevisionIdQuery } from 'features/merge-tags/query-builder';

export function useParseContentForTemplateQuery({
  templateRevisionId,
  target,
}: {
  templateRevisionId: Muid;
  target: TypeOfEnumValue<typeof MergeTagsConstants.Target>;
}) {
  const tagsQuery = useMergeTagsByTemplateRevisionIdQuery(
    {
      includeLegacyTags: true,
      templateRevisionId,
      mergeTagTarget: target,
    },
    // invalidation happens via MergeTagsByTemplateRevisionIdQuery.watchWidgetChanges
    // also needs invalidation later when data set columns can be renamed
    { staleTime: Infinity },
  );

  return React.useCallback(
    (content: string) => {
      return tagsQuery.data?.tags ? MergeTagsServiceUtils.injectMergeTagLabels(content, tagsQuery.data.tags) : content;
    },
    [tagsQuery.data?.tags],
  );
}
