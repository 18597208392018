import * as React from 'react';

import { DropzoneInputProps } from 'react-dropzone';
import {
  Center,
  Stack,
  Text,
  Progress,
  Box,
  IconButtonProps,
  IconButton,
  Icon,
  BoxProps,
  Button,
} from 'components/design/next';

export const UploadArea: React.FC<
  React.PropsWithChildren<{ dropzoneInputProps: DropzoneInputProps; boxProps?: Partial<BoxProps> }>
> = ({ dropzoneInputProps, boxProps, children }) => {
  return (
    <Center w="100%" p={2} {...boxProps}>
      <input aria-label="file-upload" {...dropzoneInputProps} />
      <Box fontWeight="bold" w="full">
        {children}
      </Box>
    </Center>
  );
};

export type UploadIconButtonProps = {
  dropzoneInputProps: DropzoneInputProps;
  rightText?: string;
} & IconButtonProps;

export const UploadIconButton: React.FC<React.PropsWithChildren<UploadIconButtonProps>> = ({
  dropzoneInputProps,
  rightText,
  ...props
}) => {
  return (
    <Center w="100%">
      <input aria-label="file-upload" disabled={props.disabled} {...dropzoneInputProps} />
      {rightText ? (
        <Button
          role="group"
          justifyContent="flex-start"
          iconSpacing="2"
          display="flex"
          variant="unstyled"
          color="gray.500"
          fontWeight="500"
          fontSize="md"
          leftIcon={<Icon size="4" color="gray.500" variant="far" icon="paperclip" />}
        >
          {rightText}
        </Button>
      ) : (
        <IconButton
          color="gray.600"
          variant="ghost"
          size="sm"
          icon={<Icon size="4" variant="far" icon="paperclip" />}
          {...props}
        />
      )}
    </Center>
  );
};

export const UploadProgress: React.FC<React.PropsWithChildren<{ progress: number; message: string }>> = ({
  progress,
  message,
}) => {
  return (
    <Stack w="100%" p={0}>
      <Center as={Text} variant="1" fontSize="sm" color="brand.600" pb={1}>
        {message}
      </Center>
      <Progress value={progress} size="sm" />
    </Stack>
  );
};
