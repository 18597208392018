import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace DeleteTaskPermissionRuleMutation {
  export type Params = { templateRevisionId: Muid; taskPermissionRuleId: Muid };

  export type Response = {};

  export const key = ['delete', 'rule'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .delete<Response>(
        `/1/template-revisions/${params.templateRevisionId}/task-permission-rules/${params.taskPermissionRuleId}`,
      )
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
