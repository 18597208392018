import { Muid } from '@process-street/subgrade/core';
import { TemplateRevision } from '@process-street/subgrade/process';
import { TemplateMigrationActions } from 'components/migration/store/template-migration.actions';
import ngRedux from 'ng-redux';
import { connectService } from 'reducers/util';
import { TemplateMigrationService } from './template-migration-service';

export class TemplateMigrationServiceImpl implements TemplateMigrationService {
  public static $inject = ['$ngRedux', 'TemplateMigrationActions'];
  public actions!: TemplateMigrationActions;

  constructor(private $ngRedux: ngRedux.INgRedux, private templateMigrationActions: TemplateMigrationActions) {
    const mapDispatchToThis = {
      redirectToViewPage: this.templateMigrationActions.redirectToViewPage,
      scheduleAllMigrationsByTemplateRevisionId:
        this.templateMigrationActions.scheduleAllMigrationsByTemplateRevisionId,
      stopPollerForTemplateRevisionId: this.templateMigrationActions.stopPollerForTemplateRevisionId,
    };

    connectService('TemplateMigrationService', this.$ngRedux, null, mapDispatchToThis)(this);
  }

  public redirect(): void {
    this.actions.redirectToViewPage();
  }

  public scheduleAllByTemplateRevision(templateRevision: TemplateRevision): void {
    this.actions.scheduleAllMigrationsByTemplateRevisionId(templateRevision.template.id, templateRevision.id);
  }

  public stopPollerForTemplateRevisionId(templateId: Muid, templateRevisionId: Muid): void {
    this.actions.stopPollerForTemplateRevisionId(templateId, templateRevisionId);
  }
}
