import { useGetOrganizationTheme } from 'features/brand-customization/query-builder/get-organization-theme';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { match, P } from 'ts-pattern';
import { EmailBrandHeaderLogoUploader } from './email-brand-header-logo-uploader';
import { EmailBrandLogo } from './email-brand-logo';

export const EmailBrandHeaderLogoWrapper: React.FC<React.PropsWithChildren<unknown>> = () => {
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const organizationThemeQuery = useGetOrganizationTheme({ organizationId });

  const logoUrl = React.useMemo(() => {
    if (organizationThemeQuery.data) {
      return organizationThemeQuery.data.emailHeaderLogoFileUrl;
    }
    return null;
  }, [organizationThemeQuery.data]);

  return match({ logoUrl })
    .with({ logoUrl: P.not(P.nullish) }, ({ logoUrl }) => (
      <EmailBrandLogo logoUrl={logoUrl} organizationId={organizationId} />
    ))
    .with({ logoUrl: P.nullish }, () => <EmailBrandHeaderLogoUploader />)
    .exhaustive();
};
