import React from 'react';

import { Muid } from '@process-street/subgrade/core';
import { Folder, Template } from '@process-street/subgrade/process';
import { Button, useToast } from 'components/design/next';
import { FolderPickerModal, FolderPickerModalProps, PickButtonProps } from 'components/folder-picker/modal';
import { trace } from 'components/trace';
import { useMoveFolderMutation } from 'features/folder/query-builder';
import { useMoveTemplateMutation } from 'features/template/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { HttpStatus } from '@process-street/subgrade/util';

export type MoveToFolderModalProps = {
  folderId: Muid;
  isOpen: boolean;
  onClose?: () => void;
  onTemplateMove?: (template: Template) => void;
  onFolderMove?: (folder: Folder) => void;
  candidateId: NonNullable<FolderPickerModalProps['candidateId']>;
  candidateType?: FolderPickerModalProps['candidateType'];
};

export const MoveToFolderModal: React.FC<React.PropsWithChildren<MoveToFolderModalProps>> = ({
  candidateType = 'template',
  candidateId,
  folderId,
  isOpen,
  onClose = () => void 0,
  onTemplateMove,
  onFolderMove,
}) => {
  const toast = useToast();
  const logger = trace({ name: 'MoveToFolderModal' });

  const moveTemplateMutation = useMoveTemplateMutation({
    onSuccess: template => {
      onTemplateMove?.(template);
    },
    onError: e => {
      const description = e.message;
      logger.error(description);
      toast({
        status: 'error',
        title: "We're having problems moving the template",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const moveFolderMutation = useMoveFolderMutation({
    onSuccess: folder => {
      onFolderMove?.(folder);
    },
    onError: e => {
      const description = e.message;
      logger.error(description);
      switch (e.response?.status) {
        case HttpStatus.CONFLICT:
          toast({
            status: 'error',
            title: 'Folder name already exists',
            description:
              'There is already a folder with the same name in the destination folder. ' +
              'Please rename this folder and try again.',
          });
          break;
        default:
          toast({
            status: 'error',
            title: "We're having problems moving the folder",
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
      }
    },
  });

  const moveMutation = candidateType === 'template' ? moveTemplateMutation : moveFolderMutation;

  // return
  const handlePick = async (toFolder: Folder) => {
    try {
      if (candidateType === 'template') {
        moveTemplateMutation.mutate({ candidateId, toFolderId: toFolder.id });
      } else if (candidateType === 'folder') {
        moveFolderMutation.mutate({ candidateId, toFolderId: toFolder.id });
      }
    } catch (e) {
      toast({
        status: 'error',
        title: "We're having problems moving this folder",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    }
  };

  const pickButton: React.FC<React.PropsWithChildren<PickButtonProps>> = ({ pickType, ...props }) => (
    <Button isLoading={moveMutation.isLoading} {...props}>
      Move
    </Button>
  );

  return (
    <FolderPickerModal
      candidateId={candidateId}
      candidateType={candidateType}
      initialFolderId={folderId}
      title="Move to..."
      verb="move"
      pickButton={pickButton}
      isOpen={isOpen}
      onPick={handlePick}
      onClose={onClose}
    />
  );
};
