import { EditorParagraph } from 'pages/pages/_id/edit/page/components/elements/paragraph';
import { serialize } from 'pages/pages/_id/edit/page/utils/serialization';
import * as React from 'react';
import { SendRichEmailRenderElementProps } from './send-rich-email-plate-types';

// can't use React.FC here based on slate editor props
export const _Paragraph = ({ attributes, children }: SendRichEmailRenderElementProps): JSX.Element => {
  return <EditorParagraph {...{ ...attributes, variant: 'widget' }}>{children}</EditorParagraph>;
};

export const Paragraph = React.memo(_Paragraph, (a, b) => {
  return serialize(a.element) === serialize(b.element);
});
