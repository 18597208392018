import * as React from 'react';
import { ChecklistStatus, Template, TemplateStatus, TemplateType } from '@process-street/subgrade/process';
import { useGetChecklistsByOrganizationIdQuery } from 'features/checklists/query-builder';
import { GetTemplateQuery } from 'features/template/query-builder';

export const useChecklistCountByTemplate = (template: Template) => {
  const { id: templateId } = template;
  const [count, setCount] = React.useState(0);
  const { data: checklists = [], isLoading } = useGetChecklistsByOrganizationIdQuery(
    {
      organizationId: template.organization.id,
      templateStatus: TemplateStatus.Active,
      checklistStatus: ChecklistStatus.Active,
    },
    { enabled: template.templateType === TemplateType.Playbook, staleTime: GetTemplateQuery.staleTime },
  );

  const map = React.useMemo(() => {
    return checklists.reduce((acc, checklist) => {
      acc[checklist.template.id] = (acc[templateId] ?? 0) + 1;
      return acc;
    }, {} as Record<Template['id'], number>);
  }, [checklists, templateId]);

  React.useEffect(() => {
    setCount(map[templateId]);
  }, [map, templateId]);

  return {
    isLoading,
    count,
  };
};
