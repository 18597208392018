import {
  GroupStats,
  isOrganizationMembershipActive,
  Option,
  OrganizationMembership,
  User,
  UserType,
} from '@process-street/subgrade/core';
import { ArrayService } from 'services/array-service';
import { PseudoGroup } from 'services/group-constants';
import { SessionService } from 'services/session-service.interface';
import templateUrl from './filter-tasks-for-assignee.component.html';
import './filter-tasks-for-assignee.scss';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';
import {
  ALL_FREE_MEMBERS_GROUP_USERNAME,
  ALL_MEMBERS_GROUP_USERNAME,
} from '@process-street/subgrade/util/membership-utils';
import ngRedux from 'ng-redux';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { connectController } from 'reducers/util';
import { ReduxAppState } from 'reducers/types';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import angular from 'angular';

export interface PseudoGroup {
  name: string;
  title: string;
}

interface Changes extends angular.IOnChangesObject {
  hiddenPseudoGroups: angular.IChangesObject<string[]>;
  selectMode: angular.IChangesObject<string>;
  selectedPseudoGroup: angular.IChangesObject<string | undefined>;
  selectedUserEmails: angular.IChangesObject<string[]>;
  title: angular.IChangesObject<string | undefined>;
  users: angular.IChangesObject<User[]>;
}

interface UserWithGroupStatus extends User {
  groupStats?: GroupStats;
}

interface InternalState {
  membershipMapByUserId: Record<User['id'], OrganizationMembership>;
  isUserDeactivationEnabled: boolean;
}

export class FilterTasksForAssigneeController {
  public static $inject = ['$ngRedux', 'SessionService'];

  public ALL_MEMBERS_USERNAME = ALL_MEMBERS_GROUP_USERNAME;
  public ALL_FREE_MEMBERS_USERNAME = ALL_FREE_MEMBERS_GROUP_USERNAME;

  public state: Option<InternalState>;

  public users: User[] = [];
  public selectedUserEmails: Option<string[]>;
  public selectedPseudoGroup: Option<string>;
  public currentUser: Option<User>;

  public hiddenPseudoGroups: string[] = [];

  public pseudoGroups: PseudoGroup[] = [
    {
      name: PseudoGroup.EVERYONE,
      title: 'ALL',
    },
    {
      name: PseudoGroup.UNASSIGNED,
      title: 'UN',
    },
  ];

  public visiblePseudoGroups: PseudoGroup[] = [];
  public search = '';
  public currentUserDescription = '';

  constructor(private $ngRedux: ngRedux.INgRedux, private sessionService: SessionService) {
    const mapStateToThis =
      () =>
      (state: ReduxAppState): InternalState => ({
        membershipMapByUserId: OrganizationMembershipSelector.getMapByUserIdForSelectedOrganization(state),
        isUserDeactivationEnabled: FeatureFlagSelector.getFeatureFlags(state).deactivatingUsers,
      });

    connectController(this.$ngRedux, mapStateToThis, null)(this);
  }

  public $onInit() {
    this.currentUser = this.sessionService.getUser();
    this.currentUserDescription = this.getDescription(this.currentUser);
  }

  public $onChanges(changes: Changes) {
    if (changes.users) {
      this.users = changes.users.currentValue;
    }

    if (changes.selectedUserEmails) {
      this.selectedUserEmails = changes.selectedUserEmails.currentValue;
    }

    if (changes.selectedPseudoGroup) {
      this.selectedPseudoGroup = changes.selectedPseudoGroup.currentValue;
    }

    if (changes.hiddenPseudoGroups) {
      this.hiddenPseudoGroups = changes.hiddenPseudoGroups.currentValue;
    }

    if (this.hiddenPseudoGroups) {
      this.visiblePseudoGroups = this.pseudoGroups.filter(item => this.hiddenPseudoGroups.indexOf(item.name) < 0);
    } else {
      this.visiblePseudoGroups = this.pseudoGroups || [];
    }
  }

  public searchUsers(): User[] {
    if (this.search) {
      const lowerCaseSearch = this.search.toLowerCase();

      return this.users.filter((user: User) => {
        const username = user.username.toLowerCase();
        const email = user.email.toLowerCase();

        const userGroupCondition = isGroupUser(user) && username.includes(lowerCaseSearch);

        const notGroupUserCondition =
          !isGroupUser(user) && (username.includes(lowerCaseSearch) || email.includes(lowerCaseSearch));

        return !this.isCurrentUserOrHiddenGroup(user) && (userGroupCondition || notGroupUserCondition);
      });
    }

    const users: User[] = this.users || [];

    return users.filter(item => !this.isCurrentUserOrHiddenGroup(item));
  }

  public isCurrentUserOrHiddenGroup(user: User) {
    return (
      (this.currentUser && user.id === this.currentUser.id) ||
      (isGroupUser(user) && user.username === this.ALL_MEMBERS_USERNAME) ||
      (isGroupUser(user) && user.username === this.ALL_FREE_MEMBERS_USERNAME)
    );
  }

  public getUsername(user: User) {
    return user.username || '???';
  }

  public getDescriptionWithPlural(cnt: number, text: string) {
    return `${cnt} ${text}${cnt > 1 ? 's' : ''}`;
  }

  public getDescription(user: Option<UserWithGroupStatus>) {
    if (user) {
      if (user.userType === UserType.Group) {
        return user.groupStats ? this.getDescriptionWithPlural(user.groupStats.totalCnt, 'member') : 'Group';
      }
      return user.email;
    }
    return '';
  }

  public selectUser(user: User) {
    // @ts-expect-error -- - this function is part of binding and is not available in this component.
    this.onSelect({ user });
  }

  public selectPseudoGroup(group: PseudoGroup) {
    // @ts-expect-error -- - this function is part of binding and is not available in this component.
    this.onSelect({ pseudoGroup: group.name });
  }

  public isUserEmailIncluded(userEmail: string) {
    return ArrayService.includes(this.selectedUserEmails || [], userEmail);
  }

  public isGroupIncluded(name: string) {
    return name === this.selectedPseudoGroup;
  }

  public isUserOrganizationMembershipInactive = (user: User): boolean => {
    const membership = this.state?.membershipMapByUserId?.[user.id];

    if (!membership || !this.state?.isUserDeactivationEnabled) return false;

    return !isOrganizationMembershipActive(membership);
  };
}

export const FilterTasksForAssigneeComponent = {
  bindings: {
    hiddenPseudoGroups: '<',
    onSelect: '&',
    selectMode: '<',
    selectedPseudoGroup: '<',
    selectedUserEmails: '<',
    title: '<',
    users: '<',
  },
  controller: FilterTasksForAssigneeController,
  templateUrl,
  transclude: true,
};
