import angular from 'angular';
import { BillingConstants } from '@process-street/subgrade/billing/billing-constants';
import { isT5KPlan } from 'services/plans';
import { PlanLevel } from '@process-street/subgrade/billing';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.services')
  .service('PlanDetailsService', function ($filter, BillingService, PlanService, SessionService) {
    const logger = trace({ name: 'PlanDetailsService' });

    const self = this;

    /**
     * Get plan limit exceed message
     *
     * @param {LimitDetails} limits
     * @param {Plan} plan
     * @param {Subscription} subscription
     * @returns {string}
     */
    self.getPlanLimitExceededMessage = function (limits, plan, subscription) {
      const periodEndDate = BillingService.getEndDateOfCurrentSubscriptionPeriod(plan, subscription);

      let message;
      if (limits.activeTemplates.limitIsReached && limits.checklistsRuns.limitIsReached) {
        // Both limits reached
        message =
          "You've hit your plan limits! " +
          'Upgrade your plan to get more workflows, or archive ' +
          "workflows you aren't using. Your monthly workflow run limit will reset on " +
          periodEndDate.format('MMMM D YYYY') +
          '.';
      } else if (limits.activeTemplates.limitIsReached) {
        // Active Template Limit Reached
        message =
          "You've hit your active workflow limit! " +
          "Upgrade your plan to get more workflows, or archive workflows you aren't using.";
      } else {
        // Checklist Runs Limit Reached
        message =
          "You've hit your monthly workflow run limit! " +
          'Upgrade your plan to get more workflow runs. Your limit will reset on ' +
          periodEndDate.format('MMMM D YYYY') +
          '.';
      }

      return message;
    };

    /**
     * Get details message by subscription state
     *
     * @param subscriptionState
     * @param {LimitDetails} limits
     * @param {Plan} plan
     * @param {Subscription} subscription
     * @returns {string}
     */
    self.generateSubscriptionDetailsMessage = function (subscriptionState, limits, plan, subscription) {
      let message;

      switch (subscriptionState) {
        case BillingConstants.SubscriptionState.TRIALING:
          message = self.generateTrialSubscriptionMessage(subscription, plan);
          break;
        case BillingConstants.SubscriptionState.EXPIRED:
          message = 'Your plan has expired. Choose a new plan to continue using Process Street.';
          break;
        case BillingConstants.SubscriptionState.EXCEEDED:
          message = self.getPlanLimitExceededMessage(limits, plan, subscription);
          break;
        case BillingConstants.SubscriptionState.CANCEL_AT_PERIOD_END: {
          const endDate = $filter('date')(subscription.currentPeriodEndDate, 'MMMM d, yyyy');
          message =
            `You've canceled the ${PlanService.getDisplayName(plan)} plan, ` +
            `but you can use the premium features until <b>${endDate}</b>.`;
          break;
        }
        default: // Active
      }

      return message;
    };

    self.generateTrialSubscriptionMessage = function (subscription, plan) {
      let message;
      const days = BillingService.getTrialDaysRemaining(subscription);
      const planTitle = PlanService.getTitleForPlanLevel(plan.level);

      if (days > 0) {
        message = `You're currently on a 14 days trial of the <b>${planTitle}</b> plan. `;
        const selectedOrganization = SessionService.getSelectedOrganization();
        if ((!selectedOrganization || !selectedOrganization.cardDefined) && !isT5KPlan(plan.id)) {
          message += "At the end of the trial, you'll be able to upgrade your plan or stay on the Free plan!";
        }
      } else {
        message = `Your <b>${planTitle}</b> trial has ended. Choose a plan to continue using Process Street.`;
      }

      return message;
    };

    self.getLegacyMessage = (plan, quantity, discount) => {
      const title = PlanService.getTitleForPlanLevel(plan.level);
      const planInterval = PlanService.convertPlanIntervalToWord(plan.interval);
      const planCostMap = PlanService.calculatePlanCost(plan, quantity, discount);

      return (
        `You're currently on the legacy <b>${title}</b> plan for ` +
        `$<span>${planCostMap.cost}</span> per member per <span>${planInterval}</span>. Upgrade to a new plan today.`
      );
    };

    /**
     * Get details message by subscription state
     *
     * @param {Plan} plan
     * @returns {string}
     */
    self.generatePlanDetailsMessage = (plan, quantity, discount) => {
      const legacy = PlanService.isPlanIdLegacy(plan.id);

      if (legacy) {
        return self.getLegacyMessage(plan, quantity, discount);
      } else {
        switch (plan.level) {
          case PlanLevel.Free:
            return 'Need Unlimited Workflows and Workflow Runs? Check out our <b>Basic</b> plan.';
          case PlanLevel.Basic:
            return 'Need Conditional Logic and Stop Tasks? Check out our <b>Standard</b> plan.';
          case PlanLevel.Standard:
            return 'Need SSO features? Check out our <b>Enterprise</b> plan.';
          case PlanLevel.Professional:
            return 'Need SSO features? Check out our <b>Enterprise</b> plan.';
          case PlanLevel.Enterprise:
            return 'Need anything? Our customer support team is ready to assist you.';
          default:
            return self.getLegacyMessage(plan, quantity, discount);
        }
      }
    };

    /**
     * Plan details message
     *
     * @param subscriptionState
     * @param {LimitDetails} limits
     * @param {Plan} plan
     * @param {Subscription} subscription
     * @returns {string}
     */
    self.resolveDetailsMessage = (subscriptionState, limits, plan, subscription, quantity, discount) => {
      const active = subscriptionState === BillingConstants.SubscriptionState.ACTIVE;
      const pastDue = subscriptionState === BillingConstants.SubscriptionState.PAST_DUE;

      let message;
      if (active) {
        // If state is active, then by plan level name (includes legacy)
        message = self.generatePlanDetailsMessage(plan, quantity, discount);
      } else if (pastDue) {
        message = 'Your payment is past due. Please update your credit card information.';
      } else {
        // Resolve by subscription state if not active
        message = self.generateSubscriptionDetailsMessage(subscriptionState, limits, plan, subscription);
      }

      return message;
    };

    /**
     * Gets subscription status text
     *
     * @param {Subscription} subscription
     * @param subscriptionState
     * @returns {string}
     */
    self.resolveSubscriptionStatusText = (subscription, subscriptionState) => {
      switch (subscriptionState) {
        case BillingConstants.SubscriptionState.CANCEL_AT_PERIOD_END:
          return 'Canceled';
        case BillingConstants.SubscriptionState.ACTIVE:
          return 'Active';
        case BillingConstants.SubscriptionState.TRIALING: {
          const trialDaysRemaining = BillingService.getTrialDaysRemaining(subscription);
          return `Trialing<br>${trialDaysRemaining} ${trialDaysRemaining > 1 ? 'days' : 'day'} left`;
        }
        case BillingConstants.SubscriptionState.EXPIRED:
          return 'Expired';
        case BillingConstants.SubscriptionState.EXCEEDED:
          return 'Limit Exceeded';
        case BillingConstants.SubscriptionState.PAST_DUE:
          return 'Past Due';
        default:
          logger.error(`unknown subscription state: ${subscriptionState}`);
          return '';
      }
    };

    /**
     * Get top message
     *
     * @param subscriptionState
     * @returns {string}
     */
    self.resolveTopMessage = function (subscriptionState) {
      switch (subscriptionState) {
        case BillingConstants.SubscriptionState.EXPIRED:
          return 'Current Plan - Plan Expired';
        case BillingConstants.SubscriptionState.EXCEEDED:
          return 'Current Plan - Limit Exceeded';
        case BillingConstants.SubscriptionState.CANCEL_AT_PERIOD_END:
          return 'Current Plan - Canceled';
        default:
          // Trialing, Active
          return 'Current Plan';
      }
    };

    self.getPlanInfoClass = (subscription, plan, subscriptionState) => {
      const legacy = PlanService.isPlanIdLegacy(plan.id);

      const active = subscriptionState === BillingConstants.SubscriptionState.ACTIVE;
      const trialing = subscriptionState === BillingConstants.SubscriptionState.TRIALING;
      const expired = subscriptionState === BillingConstants.SubscriptionState.EXPIRED;
      const cancelAtPeriodEnd = subscriptionState === BillingConstants.SubscriptionState.CANCEL_AT_PERIOD_END;
      const pastDue = subscriptionState === BillingConstants.SubscriptionState.PAST_DUE;

      const days = BillingService.getTrialDaysRemaining(subscription);

      const infoVisible = active && !legacy;
      const defaultVisible = (active && legacy) || (trialing && days > 0) || cancelAtPeriodEnd;
      const warningVisible = trialing && days === 0;
      const dangerVisible = expired || pastDue;

      return {
        'plan-info-info': infoVisible,
        'plan-info-default': defaultVisible,
        'plan-info-warning': warningVisible,
        'plan-info-danger': dangerVisible,
      };
    };
  });
