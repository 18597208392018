import React from 'react';
import { components, OptionProps } from 'react-select';
import { Box, HStack, Icon, Text } from 'components/design/next';
import { Avatar, AvatarSize } from 'components/common/Avatar';
import { User, UserType } from '@process-street/subgrade/core';
import { TaskPermissionRuleOption, UserOrRuleOption } from './transformer';
import { match } from 'ts-pattern';
import { SHOW_MORE_USER_VALUE } from './add-new-permission-search-box';

export type Props = Omit<OptionProps<UserOrRuleOption, false>, 'data'> & { data: UserOrRuleOption };

export const OptionItem = (props: Props) => {
  return (
    <components.Option {...props}>
      {match(props.data)
        .with({ type: 'User', value: SHOW_MORE_USER_VALUE }, () => <ShowMore />)
        .with({ type: 'User' }, ({ user }) => <UserOptionItem user={user.user} />)
        .with({ type: 'Rule' }, ({ rule, label }) => <RuleOptionItem rule={rule} label={label} />)
        .otherwise(() => null)}
    </components.Option>
  );
};

const ShowMore = () => {
  return (
    <Text color="blue.500" fontSize="sm">
      Show more
    </Text>
  );
};

interface UserOptionItemProps {
  user: User;
}
const UserOptionItem: React.FC<React.PropsWithChildren<UserOptionItemProps>> = ({ user }) => {
  const subtitle = user && user.userType === UserType.Group ? 'Group' : user.email;

  return (
    <HStack aria-label={user.username}>
      <Box w={6} mr={1}>
        <Avatar user={user} size={AvatarSize.Small} showUnconfirmedBadge />
      </Box>

      <Box flex={1} role="cell" maxW={78}>
        <Text variant="-1" fontWeight="medium" color="gray.600" noOfLines={1}>
          {user.username}
        </Text>

        <Text variant="-2" color="gray.500" noOfLines={1}>
          {subtitle}
        </Text>
      </Box>
    </HStack>
  );
};

interface RuleOptionItemProps {
  rule: TaskPermissionRuleOption;
  label: string;
}
const RuleOptionItem: React.FC<React.PropsWithChildren<RuleOptionItemProps>> = ({ rule, label }) => {
  return (
    <HStack>
      <Box
        bg="gray.300"
        border="1px solid"
        borderColor="white"
        borderRadius="50%"
        color="white"
        display="inline-block"
        h="6"
        textAlign="center"
        w="6"
        aria-label={label}
      >
        <Icon variant="far" size="3" icon={rule.avatarIcon} pb="1" />
      </Box>

      <Box flex={1} role="cell" maxW={78}>
        <Text variant="-1" fontWeight="medium" color="gray.600" noOfLines={1}>
          {label}
        </Text>

        <Text variant="-2" color="gray.500" noOfLines={1}>
          {label}
        </Text>
      </Box>
    </HStack>
  );
};
