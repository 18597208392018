import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
} from 'components/design/next';
import { CoverImageByTemplateIdQuery, DeleteCoverImageMutation } from 'features/cover-image/query-builder';
import { useStateParam } from 'hooks/use-state-param';
import React from 'react';
import { useQueryClient } from 'react-query';

type DeleteCoverImageAlertDialogProps = Omit<AlertDialogProps, 'children' | 'leastDestructiveRef'>;

export const DeleteBackgroundImageAlertDialog: React.FC<
  React.PropsWithChildren<DeleteCoverImageAlertDialogProps>
> = props => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const templateId = useStateParam({ key: 'id' })!;
  const queryClient = useQueryClient();
  const deleteCoverImageMutation = DeleteCoverImageMutation.useMutation({
    onSuccess: async () => {
      queryClient.invalidateQueries(CoverImageByTemplateIdQuery.getKey({ templateId }));
      props.onClose();
    },
  });

  return (
    <AlertDialog {...props} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Delete Background Image
        </AlertDialogHeader>
        <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>
        <AlertDialogFooter>
          <Button colorScheme="gray" variant="ghost" ref={cancelRef} onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            variant="solid"
            colorScheme="red"
            onClick={() => deleteCoverImageMutation.mutate({ templateId })}
            ml={3}
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
