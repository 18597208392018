import * as React from 'react';
import { Formik, Form } from 'formik';
import { TextFormFieldWidget, TEXT_MAX_LENGTH, WidgetUtils } from '@process-street/subgrade/process';
import {
  ModalBody,
  ModalFooter,
  Text,
  ButtonGroup,
  Button,
  HStack,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  VStack,
  Box,
  useModalContext,
} from 'components/design/next';
import { NumberField, Placeholder } from '../fields';
import { DefaultValueField } from 'components/widgets/form-field/settings/common/default-value-field';
import {
  TextSettingsSchema,
  textSettingsSchema,
} from 'components/widgets/form-field/settings/text-settings/text-settings-schema';
import { useWidgetSettingsContext } from '../widget-settings-context';

/**
 * Removes undefined or false values from the constraints object. Allows a user to reset the constraints to {}.
 */
const sanitizeData = ({
  max,
  min,
  hasVariables: _,
  ...config
}: TextSettingsSchema): Pick<TextFormFieldWidget, 'config' | 'constraints'> => {
  return {
    config,
    constraints: {
      ...(Boolean(min) && { min }),
      ...(Boolean(max) && { max }),
    },
  };
};

export const TextSettings = () => {
  const { widget, onUpdate } = useWidgetSettingsContext<TextFormFieldWidget>();

  const initialValues: TextSettingsSchema = {
    ...widget.constraints,
    ...widget.config,
    hasVariables: WidgetUtils.hasVariables(widget.config.defaultValue),
  };
  const { onClose } = useModalContext();

  const handleSubmit = (values: TextSettingsSchema) => {
    void onUpdate({ ...widget, ...sanitizeData(values) });
    onClose();
  };

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader p={8}>
        <Text variant="2">{widget.label || 'Untitled short text field'}</Text>
      </ModalHeader>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={textSettingsSchema}>
        {({ isValid, dirty, values, getFieldProps }) => (
          <Form>
            <ModalBody as={VStack} spacing="6" px={9} py={2}>
              <Box w="full">
                <Text variant="1" fontWeight="medium" color="gray.600" mb={1.5}>
                  Character Limit
                </Text>
                <HStack spacing={2.5}>
                  <NumberField
                    {...getFieldProps('min')}
                    aria-label="set min character limit"
                    max={values.max || TEXT_MAX_LENGTH}
                    min={0}
                  >
                    Min
                  </NumberField>
                  <Text variant="-1" color="gray.500">
                    to
                  </Text>
                  <NumberField
                    {...getFieldProps('max')}
                    aria-label="set max character limit"
                    min={values.min || 0}
                    max={TEXT_MAX_LENGTH}
                  >
                    Max
                  </NumberField>
                </HStack>
              </Box>

              <Placeholder name="placeholder" />

              <DefaultValueField maxLength={TEXT_MAX_LENGTH} />
            </ModalBody>
            <ModalFooter p={6}>
              <ButtonGroup>
                <Button aria-label="cancel changes" variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button aria-label="save settings" isDisabled={!(isValid && dirty)} type="submit">
                  Apply
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};
