import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule/approval-rule-subject.model';
import { generateUUID, Muid, MuidConverter, Option, Organization } from '@process-street/subgrade/core';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import angular from 'angular';
import { ApprovalRulesActions } from 'components/approval-rules/store/approval-rules.actions';
import { ApprovalRuleSelector } from 'components/approval-rules/store/approval-rules.selectors';
import ngRedux from 'ng-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { TaskTemplateActions } from 'reducers/task-template/task-template.actions';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { connectController } from 'reducers/util/index';
import { createSelector } from 'reselect';
import { TaskTemplateService } from 'services/task-template-service.interface';
import templateUrl from './manager.component.html';
import { ToastService } from 'services/toast-service.interface';
import { DefaultErrorMessages } from 'components/utils/error-messages';

interface InternalState {
  approvalRuleSubjects: ApprovalRuleSubject[];
  selectedOrganization: Organization;
}

interface TaskTemplateChanges {
  currentValue: TaskTemplate;
  previousValue: TaskTemplate;
}

interface TemplateRevisionChanges {
  currentValue: TemplateRevision;
  previousValue: TemplateRevision;
}

interface InternalActions {
  getAllTaskTemplatesByTemplateRevisionId(templateRevisionId: Muid): Promise<void>;
  getAllApprovalRuleSubjectsByTemplateRevisionId(templateRevisionId: Muid): Promise<void>;
  createAllApprovalRuleSubjects(
    templateRevisionId: Muid,
    taskTemplateGroupId: Muid,
    rules: ApprovalRuleSubject[],
  ): Promise<void>;
  deleteAllApprovalRuleSubjects(templateRevisionId: Muid, ruleIds: Muid[]): Promise<void>;
}

export class ApprovalRuleSubjectTasksManagerController {
  public actions: Option<InternalActions>;

  public templateRevision: Option<TemplateRevision>;
  public taskTemplate: Option<TaskTemplate>;

  private state: Option<InternalState>;

  static $inject = ['$ngRedux', 'TaskTemplateActions', 'ApprovalRulesActions', 'TaskTemplateService', 'ToastService'];

  constructor(
    private $ngRedux: ngRedux.INgRedux,
    private taskTemplateActions: TaskTemplateActions,
    private approvalRulesActions: ApprovalRulesActions,
    private taskTemplateService: TaskTemplateService,
    private toastService: ToastService,
  ) {
    const mapStateToThis = () => {
      if (this.templateRevision && this.taskTemplate) {
        return createSelector(
          [
            TaskTemplateSelector.getAllByTemplateRevisionId(this.templateRevision.id),
            ApprovalRuleSelector.getAllByTaskTemplateGroupIds(this.templateRevision.id, [this.taskTemplate.group.id]),
            SessionSelector.getSelectedOrganization,
          ],
          (taskTemplates: TaskTemplate[], approvalRuleSubjects: ApprovalRuleSubject[], selectedOrganization) => {
            const selectedTaskTemplateGroupIds = this.getSubjectTaskTemplateGroupIds(
              approvalRuleSubjects,
              this.taskTemplate!,
            );

            const selectedTaskTemplates = taskTemplates.filter(tt => {
              return selectedTaskTemplateGroupIds.indexOf(tt.group.id) > -1;
            });

            return {
              approvalRuleSubjects,
              selectedOrganization,
              selectedTaskTemplates,
              taskTemplates,
            };
          },
        );
      }
      return () => ({});
    };

    const mapDispatchToThis = () => ({
      createAllApprovalRuleSubjects: this.approvalRulesActions.createAll,
      deleteAllApprovalRuleSubjects: this.approvalRulesActions.deleteAll,
      getAllApprovalRuleSubjectsByTemplateRevisionId: this.approvalRulesActions.getAllByTemplateRevisionId,
      getAllTaskTemplatesByTemplateRevisionId: this.taskTemplateActions.getAllByTemplateRevisionId,
    });

    connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, this.shouldChange)(this);
  }

  public $onChanges(change: { taskTemplate?: TaskTemplateChanges; templateRevision?: TemplateRevisionChanges }) {
    const { taskTemplate, templateRevision } = change;
    if (taskTemplate && taskTemplate.currentValue) {
      this.taskTemplate = taskTemplate.currentValue;
    }
    if (templateRevision && templateRevision.currentValue) {
      this.templateRevision = templateRevision.currentValue;
    }

    if (this.shouldChange(change)) {
      this.actions!.getAllTaskTemplatesByTemplateRevisionId(this.templateRevision!.id).catch(() => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems loading the tasks`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
      this.actions!.getAllApprovalRuleSubjectsByTemplateRevisionId(this.templateRevision!.id).catch(() => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems loading the approval rules`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
    }
  }

  public shouldChange(change: { taskTemplate?: TaskTemplateChanges; templateRevision?: TemplateRevisionChanges }) {
    return (
      !!(change.templateRevision && change.templateRevision.currentValue) ||
      !!(change.taskTemplate && change.taskTemplate.currentValue)
    );
  }

  public setSelectedTaskTemplates(selected: TaskTemplate[]) {
    const unselectedRuleIds = this.getUnselectedRuleIds(selected, this.state!.approvalRuleSubjects);

    if (unselectedRuleIds.length > 0) {
      this.actions!.deleteAllApprovalRuleSubjects(this.templateRevision!.id, unselectedRuleIds).catch(() => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems deleting the approval rule`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
    }

    const nonHeadingSelected = selected.filter(tt => !this.taskTemplateService.isHeading(tt));

    const newlySelectedARSubjects = this.getNewlySelectedApprovalRuleSubjects(
      nonHeadingSelected,
      this.state!.approvalRuleSubjects,
      this.templateRevision!,
    );

    if (newlySelectedARSubjects.length > 0 && this.templateRevision && this.taskTemplate) {
      this.actions!.createAllApprovalRuleSubjects(
        this.templateRevision.id,
        this.taskTemplate.group.id,
        newlySelectedARSubjects,
      ).catch(() => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems creating the approval rule`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
    }

    // @ts-expect-error -- TODO
    this.onDone();
  }

  public getUnselectedRuleIds(selected: TaskTemplate[], oldApprovalRuleSubjects: ApprovalRuleSubject[]) {
    const selectedGroupIds = selected.map(tt => tt.group.id);

    return oldApprovalRuleSubjects.reduce((arr: Muid[], ars: ApprovalRuleSubject) => {
      if (selectedGroupIds.length === 0 || selectedGroupIds.indexOf(ars.subjectTaskTemplateGroupId) === -1) {
        arr.push(ars.id);
      }
      return arr;
    }, []);
  }

  public getNewlySelectedApprovalRuleSubjects(
    selected: TaskTemplate[],
    oldApprovalRuleSubjects: ApprovalRuleSubject[],
    templateRevision: TemplateRevision,
  ) {
    const subjectGroupIdToRuleSubject: { [key: string]: ApprovalRuleSubject } = oldApprovalRuleSubjects.reduce(
      (map: { [key: string]: ApprovalRuleSubject }, ars: ApprovalRuleSubject) => {
        map[ars.subjectTaskTemplateGroupId] = ars;
        return map;
      },
      {},
    );

    return selected
      .filter(tt => {
        return !subjectGroupIdToRuleSubject[tt.group.id];
      })
      .map(tt => {
        return this.generateApprovalRuleSubject(tt, templateRevision);
      });
  }

  private generateApprovalRuleSubject(
    taskTemplate: TaskTemplate,
    templateRevision: TemplateRevision,
  ): ApprovalRuleSubject {
    return {
      approvalTaskTemplateGroupId: this.taskTemplate!.group.id,
      audit: {
        createdBy: { id: generateUUID() },
        createdDate: Date.now(),
        updatedBy: { id: generateUUID() },
        updatedDate: Date.now(),
      },
      id: MuidConverter.fromUuid(generateUUID()),
      organizationId: this.state!.selectedOrganization.id,
      subjectTaskTemplateGroupId: taskTemplate.group.id,
      templateRevisionId: templateRevision.id,
    };
  }

  private getSubjectTaskTemplateGroupIds(
    approvalRuleSubjects: ApprovalRuleSubject[],
    approvalTaskTemplate: TaskTemplate,
  ) {
    return approvalRuleSubjects
      .filter(s => s.approvalTaskTemplateGroupId === approvalTaskTemplate.group.id)
      .reduce((acc: Muid[], approvalRuleSubject) => {
        acc.push(approvalRuleSubject.subjectTaskTemplateGroupId);
        return acc;
      }, []);
  }
}

export const ApprovalRuleSubjectTasksManagerComponent: angular.IComponentOptions = {
  bindings: {
    onDone: '&',
    taskTemplate: '<',
    templateRevision: '<',
  },
  controller: ApprovalRuleSubjectTasksManagerController,
  templateUrl,
};
