import * as React from 'react';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { Box, useDisclosure, useOutsideClick } from 'components/design/next';
import {
  Node,
  NodeInfoMap,
  NodeType,
  SelectorHelper,
} from 'directives/rules/template/task-templates-selector/selector-helper';
import { ActionMeta, components, MenuListProps, OnChangeValue } from 'react-select';
import { TaskVisibility } from '@process-street/subgrade/conditional-logic';
import produce from 'immer';
import { usePrevious } from 'react-use';
import { VirtualizedMenuList } from 'components/design/BlvdSelect/components/VirtualizedMenuList';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { MultiValueLabel } from './multi-value-label';
import { TaskTemplatesSelectorContext } from './context';
import { Option } from './option';
import { OptionType } from './types';
import { ValueContainer } from './value-container';
import { Control } from './control';
import { useLazyMenuOptions } from 'features/conditional-logic/hooks/useLazyMenuOptions';
import { ConditionalLogicUtils } from 'features/conditional-logic/utils/conditional-logic-utils';
import { match, P } from 'ts-pattern';
import { useConditionalLogicModalStore } from '../modal/store';
import { useMatch } from '@process-street/adapters/navigation';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';

export type TaskTemplatesSelectorProps = {
  disabled?: boolean;
  onChange?: (widgets: Widget[], taskTemplates: TaskTemplate[]) => void;
  canRemoveValues?: boolean;
  selectedWidgets: Widget[];
  selectedTaskTemplates: TaskTemplate[];
};

const getLevel = (node: Node) => {
  return [NodeType.Heading, NodeType.Task, NodeType.Widget].indexOf(node.type);
};

const nodeToOption = (node: Node): OptionType => ({
  ...node,
  value: node.id,
  level: getLevel(node),
});

export const TaskTemplatesSelector: React.VFC<TaskTemplatesSelectorProps> = ({
  disabled,
  canRemoveValues,
  selectedWidgets,
  selectedTaskTemplates,
  onChange,
}) => {
  const { normalizedData } = useConditionalLogicModalStore();
  const nodesList = React.useMemo(() => normalizedData.nodes.all, [normalizedData.nodes.all]);
  const {
    value: nodeInfoMap,
    setValue: setNodeInfoMap,
    handleMenuOpen,
    hasFullyLoaded,
  } = useLazyMenuOptions<NodeInfoMap>({
    getValue: () => {
      const newNodeInfoMap = ConditionalLogicUtils.getInitialNodeInfoMap(
        nodesList,
        selectedWidgets,
        selectedTaskTemplates,
      );

      return newNodeInfoMap;
    },
    defaultValue: {},
  });
  const isEditorV2 = useMatch('templateV2');
  const [searchTerm, setSearchTerm] = React.useState('');
  const previousSearchTerm = usePrevious(searchTerm);

  const matchingNodes = React.useMemo(() => {
    if (!hasFullyLoaded) return nodesList;

    return SelectorHelper.findNodes(nodeInfoMap, nodesList, searchTerm.trim());
  }, [nodeInfoMap, nodesList, searchTerm, hasFullyLoaded]);

  const options = React.useMemo(
    () =>
      matchingNodes
        .filter(node => {
          const { parentId, type, ref, label } = node;
          const regex = new RegExp(searchTerm, 'i');
          const isWidget = type === NodeType.Widget;
          const parentNodeInfo = parentId ? nodeInfoMap[parentId] : null;
          const parentTask = type === NodeType.Widget ? ((ref as Widget)?.header?.taskTemplate as TaskTemplate) : null;

          const headingNode = match({ parentTask, node })
            .with({ node: { type: NodeType.Heading } }, ({ node }) => node)
            .with({ parentTask: P.not(P.nullish) }, ({ parentTask }) => {
              const task = normalizedData.nodes.byId[parentTask.id];
              if (task?.parentId) return normalizedData.nodes.byId[task.parentId] ?? null;

              return null;
            })
            .with({ node: { type: NodeType.Task } }, ({ node }) => {
              if (node.parentId) return normalizedData.nodes.byId[node.parentId] ?? null;

              return null;
            })
            .otherwise(() => null);

          const headingNodeInfo = headingNode ? nodeInfoMap[headingNode.id] : null;

          if (searchTerm.length > 0) {
            const isParentNameMatchingSearch = parentTask?.name ? regex.test(parentTask.name) : false;
            const isWidgetNameMatchingSearch = regex.test(label);

            // Hide widgets when either its name or parent task name doesn't matches the search
            if (isWidget && !isWidgetNameMatchingSearch && !isParentNameMatchingSearch) return false;
          }

          // Hide widgets or tasks if their parent or heading is folded
          if (node.type !== NodeType.Heading && (parentNodeInfo?.folded || headingNodeInfo?.folded)) return false;

          return true;
        })
        .map(nodeToOption),
    [matchingNodes, searchTerm, nodeInfoMap, normalizedData],
  );

  const values = React.useMemo(() => {
    if (!hasFullyLoaded) {
      return getValues({ selectedWidgets, selectedTaskTemplates });
    }

    const selection = SelectorHelper.getSelection(nodeInfoMap, nodesList);

    return getValues({
      selectedTaskTemplates: selection.selectedTaskTemplates,
      selectedWidgets: selection.selectedWidgets,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- act on loading once
  }, [nodeInfoMap, nodesList, hasFullyLoaded]);

  const toggleNodeFolded = React.useCallback(
    (node: Node) => {
      const nodeInfo = nodeInfoMap[node.id];

      setNodeInfoMap({
        ...nodeInfoMap,
        [node.id]: {
          ...nodeInfo,
          folded: !nodeInfo.folded,
        },
      });
    },
    [nodeInfoMap, setNodeInfoMap],
  );

  const contextValue = React.useMemo(
    () => ({
      nodeInfoMap,
      searchTerm,
      onFold: toggleNodeFolded,
    }),
    [nodeInfoMap, searchTerm, toggleNodeFolded],
  );

  const handleChange = (selectedValue: OnChangeValue<OptionType, true>, actionMeta: ActionMeta<OptionType>) => {
    if (actionMeta.action === 'remove-value' && !canRemoveValues) return;

    const nodes = matchingNodes.flatMap(node => [node, ...(node.widgets ?? [])]);
    const node = actionMeta.option ?? actionMeta.removedValue;

    // Selecting more than one
    if (BlvdSelectHelpers.isOptionsType(selectedValue)) {
      const selectedAll = selectedValue.length === options.length;

      const updatedNodeInfoMap = produce(nodeInfoMap, draft => {
        SelectorHelper.selectAll(draft, nodes, selectedAll);
      });

      setNodeInfoMap(updatedNodeInfoMap);
      updateSelection(updatedNodeInfoMap);
    }

    if (!node) return;

    const updatedNodeInfoMap = produce(nodeInfoMap, draft => {
      SelectorHelper.changeSelection(draft, nodes, node, actionMeta.action === 'select-option', TaskVisibility.SHOW);
    });

    setNodeInfoMap(updatedNodeInfoMap);
    updateSelection(updatedNodeInfoMap);
  };

  // Sync nodeInfoMap
  React.useEffect(() => {
    if (previousSearchTerm === searchTerm || previousSearchTerm === undefined) return;

    const updatedNodeInfoMap = produce(nodeInfoMap, draft =>
      SelectorHelper.updateNodeInfoMap(draft, nodesList, searchTerm),
    );

    setNodeInfoMap(updatedNodeInfoMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- act on search term change
  }, [searchTerm, previousSearchTerm]);

  const updateSelection = (updatedNodeInfoMap: NodeInfoMap) => {
    const { selectedTaskTemplates, selectedWidgets } = SelectorHelper.getSelection(updatedNodeInfoMap, nodesList);

    onChange?.(selectedWidgets, selectedTaskTemplates);
  };

  const containerRef = React.useRef<HTMLDivElement>(null);
  const disclosure = useDisclosure();
  useOutsideClick({
    ref: containerRef,
    handler: () => disclosure.onClose(),
  });

  return (
    <TaskTemplatesSelectorContext.Provider value={contextValue}>
      <Box
        ref={containerRef}
        onMouseOver={
          !hasFullyLoaded
            ? // Initialized the dropdown values on the first hover
              handleMenuOpen
            : undefined
        }
        w="full"
        sx={{
          '.blvd-select .blvd-select__control': {
            'flexWrap': 'nowrap',
            'opacity': disabled ? 0.4 : 1,
            'cursor': disabled ? 'not-allowed' : 'default',

            '.blvd-select__value-container': {
              pr: 12,
              maxHeight: '34px',
              w: 'full',
            },

            '.blvd-select__multi-value': {
              zIndex: 2,
              bgColor: 'brand.100',
              borderRadius: '16px',
              px: 2,
            },

            '.blvd-select__multi-value__label': {
              fontSize: 'sm',
              fontWeight: 'semibold',
            },

            '.blvd-select__multi-value .blvd-select__multi-value__remove:hover': {
              bgColor: 'brand.300',
              borderRadius: '16px',
            },
          },
          '.blvd-select .blvd-select__menu .blvd-select__option': {
            paddingTop: isEditorV2 ? 0.5 : 2,
            paddingBottom: isEditorV2 ? 0.5 : 2,
          },
        }}
      >
        <BlvdSelect
          value={values}
          options={options}
          onChange={handleChange}
          onInputChange={(searchTerm, meta) => {
            // We avoid updating the searchTerm when the action is `set-value` because by default
            // `react-select` resets the search term when the user selects a value from the list
            // but we don't want this behavior.
            if (meta.action === 'set-value') return;

            setSearchTerm(searchTerm);
          }}
          inputValue={searchTerm}
          filterOption={() => true}
          components={{
            Option: props => <Option {...props} />,
            MenuList: options.length > 200 ? VirtualizedMenuListWithoutAutoScroll : CustomMenuList,
            ValueContainer,
            MultiValueLabel,
            Control,
          }}
          onMenuOpen={() => {
            disclosure.onOpen();
            handleMenuOpen();
          }}
          onMenuClose={() => {
            disclosure.onClose();
          }}
          menuIsOpen={disclosure.isOpen}
          isSearchable
          isMulti
          menuControls
          enableSearchAutoFocus
        />
      </Box>
    </TaskTemplatesSelectorContext.Provider>
  );
};

const VirtualizedMenuListWithoutAutoScroll = (props: MenuListProps<OptionType, true>) => {
  const isEditorV2 = useMatch('templateV2');

  return isEditorV2 ? (
    <ThemeProvider2024>
      <VirtualizedMenuList disableScrollToFocusedOption {...props} />
    </ThemeProvider2024>
  ) : (
    <VirtualizedMenuList disableScrollToFocusedOption {...props} />
  );
};

const CustomMenuList = (props: MenuListProps<OptionType, true>) => {
  const isEditorV2 = useMatch('templateV2');

  return isEditorV2 ? (
    <ThemeProvider2024>
      <components.MenuList {...props} />
    </ThemeProvider2024>
  ) : (
    <components.MenuList {...props} />
  );
};

const getValues = ({
  selectedTaskTemplates,
  selectedWidgets,
}: {
  selectedTaskTemplates: TaskTemplate[];
  selectedWidgets: Widget[];
}) => {
  const selectedNodes = [];

  for (let i = 0; i < Math.max(selectedTaskTemplates.length, selectedWidgets.length); i++) {
    const widget = selectedWidgets[i];
    const task = selectedTaskTemplates[i];

    if (widget) selectedNodes.push(nodeToOption(SelectorHelper.widgetToNode(widget)));
    if (task) selectedNodes.push(nodeToOption(SelectorHelper.taskTemplateToNode(task, i)));
  }

  return selectedNodes;
};
