import { RequestStage } from '@process-street/subgrade/redux/types';

const ACTION_TYPE_DELIMITER = '||';

export function toSuccess(...actionTypes: string[]) {
  return actionTypes.map(type => type + RequestStage.Success).join(ACTION_TYPE_DELIMITER);
}

export function toFailure(...actionTypes: string[]) {
  return actionTypes.map(type => type + RequestStage.Failure).join(ACTION_TYPE_DELIMITER);
}

export function toRequest(...actionTypes: string[]) {
  return actionTypes.map(type => type + RequestStage.Request).join(ACTION_TYPE_DELIMITER);
}
