import { Muid, Organization } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { QueryKey, QueryClient, QueryObserver, QueryObserverOptions, useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

const getOrganizationById = async (organizationId?: Organization['id']): Promise<Organization> => {
  return axiosService
    .getAxios()
    .get(`/1/organizations/${organizationId}`)
    .then(res => res.data);
};

type GetOrganizationQueryParams = { organizationId?: Muid | undefined };
type GetOrganizationQueryResponse = Organization;

export const GetOrganizationQuery = {
  key: ['organization', 'get'],
  getKey: ({ organizationId }: GetOrganizationQueryParams): QueryKey => [...GetOrganizationQuery.key, organizationId],
  queryFn: ({ organizationId }: GetOrganizationQueryParams) => getOrganizationById(organizationId),
};

export const useGetOrganizationQuery = <Select = Organization>(
  { organizationId }: GetOrganizationQueryParams,
  options: UseQueryOptions<GetOrganizationQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetOrganizationQuery.getKey({ organizationId }),
    () => GetOrganizationQuery.queryFn({ organizationId }),
    {
      ...options,
      enabled: Boolean(organizationId) && options.enabled !== false,
      notifyOnChangeProps: 'tracked',
    },
  );
};

export const makeGetOrganizationQueryObserver = ({
  queryClient,
  options,
  organizationId,
}: GetOrganizationQueryParams & {
  queryClient: QueryClient;
  options?: QueryObserverOptions<GetOrganizationQueryResponse, AxiosError>;
}) => {
  return new QueryObserver<GetOrganizationQueryResponse, AxiosError>(queryClient, {
    queryKey: GetOrganizationQuery.getKey({ organizationId }),
    queryFn: () => GetOrganizationQuery.queryFn({ organizationId }),
    enabled: Boolean(organizationId) && options?.enabled !== false,
    ...options,
  });
};
