// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../images/task-template/task-permission-upgrade.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"add-stop-task\"> <div class=\"modal-header\"> <button type=\"button\" data-ng-click=\"$ctrl.close()\" class=\"close\">&times;</button> <h4 class=\"modal-title text-center\"> <span class=\"upgrade-title\"> <span>Upgrade to use Task Permissions</span> <span class=\"badge badge-success ml-1\">Standard</span> </span> </h4> </div> <div class=\"modal-body text-center\"> <div class=\"row\"> <div class=\"col-xs-12 explanation-box\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </div> </div> <div class=\"row\"> <p class=\"upgrade-description\"> Have sensitive work? Hide certain tasks from anyone on your workflow. Perfect for HR processes or sharing certain tasks with clients. </p> </div> <div class=\"upgrade-btn-box\"> <div data-ng-if=\"!featureIsAvailable\"> <a class=\"btn btn-primary\" data-ng-if=\"$ctrl.userIsAdmin\" data-ng-click=\"$ctrl.close()\" data-ui-sref=\"organizationManage.tab({ tab: 'billing' })\" data-ui-sref-opts=\"{ reload: true }\"> Upgrade to Standard </a> <p ng-if=\"!$ctrl.userIsAdmin\">Contact an administrator to upgrade your plan.</p> </div> </div> <div class=\"upgrade-btn-box\"> <a href=\"https://www.process.st/help/docs/task-permissions/\" target=\"_blank\" rel=\"noopener noreferrer\"> Learn more &raquo; </a> </div> </div> </div> ";
// Exports
var _module_exports = code;;
var path = '/components/task-permission/modal/task-permission-upgrade-modal.component.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;