import * as React from 'react';
import { Icon, MenuItem, MenuItemProps } from 'components/design/next';
import { useWidgetActorRef } from '../../../shared/widget-context';

export const DuplicateMenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = props => {
  const { widgetActorRef } = useWidgetActorRef();
  return (
    <MenuItem
      iconSpacing="2"
      aria-label="Duplicate"
      icon={<Icon icon="copy" size="4" color="gray.700" />}
      color="gray.700"
      onClick={() => widgetActorRef.send({ type: 'DUPLICATE' })}
      {...props}
    >
      Duplicate
    </MenuItem>
  );
};
