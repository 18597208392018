import * as React from 'react';
import { Center, Spinner, SpinnerProps } from 'components/design/next';
import { useTimeout } from 'react-use';

export type DelayedSpinnerProps = {
  /** Delay in ms */
  delay?: number;
  isLoaded: boolean;
  children?: React.ReactNode;
  isCentered?: boolean;
} & SpinnerProps;

const DEFAULT_DELAY_MS = 500;

export const DelayedSpinner = ({
  delay = DEFAULT_DELAY_MS,
  isLoaded,
  children,
  isCentered = false,
  ...props
}: DelayedSpinnerProps) => {
  const [isDelayOver] = useTimeout(delay);

  if (isLoaded) {
    return <>{children}</>;
  }

  if (isDelayOver()) {
    return isCentered ? (
      <Center w="100%">
        <Spinner {...props} />
      </Center>
    ) : (
      <Spinner {...props} />
    );
  }

  return null;
};
