import { Muid } from '@process-street/subgrade/core';
import { axiosService } from 'services/axios-service';

export type CreateWidgetUploadUrlByChecklistRevisionIdMutationParams = {
  checklistRevisionId: Muid;
  widgetId: Muid;
  fileName: string;
  mimeType: string;
};

export type CreateWidgetUploadUrlByChecklistRevisionIdMutationResponse = {
  key: string;
  url: string;
};

export const CreateWidgetUploadUrlByChecklistRevisionIdMutation = {
  key: ['widget', 'upload-url'],
  mutationFn: (params: CreateWidgetUploadUrlByChecklistRevisionIdMutationParams) =>
    axiosService
      .getAxios()
      .post<CreateWidgetUploadUrlByChecklistRevisionIdMutationResponse>(
        `/1/checklist-revisions/${params.checklistRevisionId}/widgets/${params.widgetId}/upload-url`,
        params,
      )
      .then(r => r.data),
};
