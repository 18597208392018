import {} from '@process-street/subgrade/core';
import { OneOffTask, UpdateOneOffTaskRequest } from '@process-street/subgrade/one-off-task';
import {} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateOneOffTaskMutation {
  export type Params = UpdateOneOffTaskRequest;

  export type Response = OneOffTask;

  export const key = ['put', 'one-off-task'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/one-off-task/${params.id}`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
