import angular from 'angular';
import { PlanLevel } from '@process-street/subgrade/billing';
import { BillingConstants } from '@process-street/subgrade/billing/billing-constants';
import templateUrl from './plan-details.component.html';
import { features } from 'services/features/features';
import { isT5KStartupPlan } from 'services/plans';
import './plan-details.scss';
import { IntercomService } from 'services/interop/intercom-service';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { trace } from 'components/trace';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular.module('frontStreetApp.directives').component('psPlanDetails', {
  bindings: {
    plan: '<',
    organization: '<',
    quantity: '<',
    discount: '<',
  },
  require: {
    billingCtrl: '^psBilling',
  },
  templateUrl,
  controller(
    $q,
    BillingService,
    OrganizationService,
    PlanDetailsService,
    PlanService,
    PlanFeatureSetService,
    SessionService,
    ToastService,
  ) {
    const logger = trace({ name: 'psPlanDetails' });
    logger.info('loading ctrl');

    const ctrl = this;

    ctrl.$onChanges = function (changes) {
      if (changes.organization || changes.discount) {
        ctrl.initialize();
      }
    };

    ctrl.initialize = () => {
      $q.all({
        featureSet: PlanFeatureSetService.getById(ctrl.plan.featureSet.id),
        organizationStats: OrganizationService.getOrganizationStatsById(ctrl.organization.id),
      }).then(
        results => {
          const { featureSet } = results;
          const { organizationStats } = results;
          ctrl.initializeStateAndControls(ctrl.plan, featureSet, organizationStats);
        },
        () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems loading your details`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
      );

      ctrl.currentSubIsPremiumAndWillBeRenewed = BillingService.subscriptionIsPremiumAndWillBeRenewed(
        ctrl.organization,
        ctrl.plan,
      );

      ctrl.isEnterpriseOrStartup = isT5KStartupPlan(ctrl.plan.id) || ctrl.plan.level === PlanLevel.Enterprise;
    };

    /**
     * Initializes subscription state and controls' details (messages, titles, limits)
     *
     * @param {Plan} plan
     * @param {PlanFeatureSet} featureSet
     * @param {OrganizationStats} organizationStats
     */
    ctrl.initializeStateAndControls = (plan, featureSet, organizationStats) => {
      ctrl.initialized = true;

      const { subscription } = ctrl.organization;

      ctrl.subscriptionState = BillingService.getSubscriptionState(plan, featureSet, subscription, organizationStats);

      // Organization limits
      ctrl.limits = {
        checklistsRuns: BillingService.getChecklistRunsLimitDetails(featureSet, organizationStats),
        activeChecklists: BillingService.getActiveChecklistsLimitDetails(featureSet, organizationStats),
        activeTemplates: BillingService.getActiveTemplatesLimitDetails(featureSet, organizationStats),
      };

      // Page Controls
      ctrl.topMessage = PlanDetailsService.resolveTopMessage(ctrl.subscriptionState);
      ctrl.titleOfPlanLevel = isT5KStartupPlan(plan.id)
        ? plan.name.replace('PS', '')
        : PlanService.getTitleForPlanLevel(plan.level);
      ctrl.freemium = features.isFreemiumTrack(plan.id);
      ctrl.subscriptionStateText = PlanDetailsService.resolveSubscriptionStatusText(
        subscription,
        ctrl.subscriptionState,
      );

      ctrl.planInfoMessage = PlanDetailsService.resolveDetailsMessage(
        ctrl.subscriptionState,
        ctrl.limits,
        plan,
        subscription,
        ctrl.quantity,
        ctrl.discount,
      );

      ctrl.changePlanButtonTitle = resolveChangePlanButtonTitle(ctrl.subscriptionState, plan.level);

      ctrl.initPlanInfoClass(plan, ctrl.subscriptionState);
    };

    ctrl.initPlanInfoClass = (plan, subscriptionState) => {
      const { subscription } = ctrl.organization;
      ctrl.planInfoClass = PlanDetailsService.getPlanInfoClass(subscription, plan, subscriptionState);
    };

    /**
     * Title of change plan button
     * @readonly
     * @enum {string}
     */
    const ChangePlanButtonTitle = {
      CHOOSE: 'Choose Plan',
      CHANGE: 'Change Plan',
      UPGRADE: 'Upgrade Now',
      RENEW_SUBSCRIPTION: 'Renew Subscription',
      CONTACT_US: 'Contact Us',
    };

    /**
     * Returns title for change plan button
     *
     * @param subscriptionState
     * @param planLevelName
     * @returns {ChangePlanButtonTitle}
     */
    function resolveChangePlanButtonTitle(subscriptionState, planLevelName) {
      let title;

      // First try to resolve by subscription state
      switch (subscriptionState) {
        case BillingConstants.SubscriptionState.TRIALING:
          if (ctrl.organization.cardDefined) {
            title = ChangePlanButtonTitle.CHANGE;
          } else {
            title = ChangePlanButtonTitle.UPGRADE;
          }
          break;
        case BillingConstants.SubscriptionState.EXCEEDED:
          title = ChangePlanButtonTitle.UPGRADE;
          break;
        case BillingConstants.SubscriptionState.EXPIRED:
          title = ChangePlanButtonTitle.CHOOSE;
          break;
        case BillingConstants.SubscriptionState.CANCEL_AT_PERIOD_END:
          title = ChangePlanButtonTitle.RENEW_SUBSCRIPTION;
          break;
        default: // Active
      }

      if (!title && PlanService.isPlanIdLegacy(ctrl.plan.id)) {
        // If plan is legacy let user choose a plan
        title = ChangePlanButtonTitle.CHOOSE;
      }

      if (!title) {
        // If state is active, then by plan level name
        switch (planLevelName) {
          case PlanLevel.Free:
            title = ChangePlanButtonTitle.UPGRADE;
            break;
          case PlanLevel.Basic:
          case PlanLevel.Standard:
            title = ChangePlanButtonTitle.CHANGE;
            break;
          case PlanLevel.Professional:
          case PlanLevel.Enterprise:
            title = ChangePlanButtonTitle.CONTACT_US;
            break;
          default:
        }
      }

      if (!title) {
        logger.error("button text wasn't resolved but should have");
        title = ChangePlanButtonTitle.CHANGE;
      }

      return title;
    }

    /**
     * Returns true if subscription's state is TRIAL
     *
     * @returns {boolean}
     */
    ctrl.isTrialing = function () {
      return ctrl.subscriptionState === BillingConstants.SubscriptionState.TRIALING;
    };

    /**
     * Returns true if subscription's state is expired or exceeded limits
     *
     * @returns {boolean}
     */
    ctrl.isExpiredOrExceeded = function () {
      return (
        ctrl.subscriptionState === BillingConstants.SubscriptionState.EXPIRED ||
        ctrl.subscriptionState === BillingConstants.SubscriptionState.EXCEEDED
      );
    };

    /**
     * Choose plan action
     */
    ctrl.choosePlan = function () {
      if (ctrl.changePlanButtonTitle === ChangePlanButtonTitle.CONTACT_US) {
        AnalyticsService.trackEvent('contact us link clicked', { location: 'billing page' });
        logger.info('contact us link clicked');
        IntercomService.show();
      } else if (ctrl.changePlanButtonTitle === ChangePlanButtonTitle.RENEW_SUBSCRIPTION) {
        BillingService.renewSubscription(ctrl.organization, ctrl.plan).then(() => {
          ctrl.initialize();
        });
      } else {
        ctrl.billingCtrl.showPlanSelector();
      }
    };
  },
});
