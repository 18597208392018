import { OverflowingValuesIndicator } from 'components/design/BlvdSelect/components/multi/OverflowingValuesIndicator';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-hooks-window-size';
import { components, GroupBase, ValueContainerProps } from 'react-select';
import { ExtendedComponentsConfig } from 'components/design/BlvdSelect/types';
import { Box, Tooltip } from 'components/design/next';
import { getTooltipLabel } from './get-tooltip-label';

export const FixedMultiValueContainer = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: ValueContainerProps<Option, IsMulti, Group>,
) => {
  const windowSize = useWindowSize();
  const valuesWrapper = useRef<HTMLDivElement>(null);
  const [visibleState, setVisibleState] = useState({
    visibleLimitReached: false,
    visibleValuesCount: 1,
  });

  const extendedComponents = props.selectProps.components as ExtendedComponentsConfig<Option, IsMulti, Group>;
  const value = props.getValue();
  const selectedValuesCount = BlvdSelectHelpers.valuesCount(value);

  /**
   * Sets the initial state when the selected values or window size change.
   */
  useLayoutEffect(() => {
    setVisibleState({
      visibleLimitReached: false,
      visibleValuesCount: 1,
    });
  }, [value, windowSize]);

  /**
   * Increments the number of visible values one at a time
   * until they are overflowing.
   */
  useLayoutEffect(() => {
    if (valuesWrapper.current) {
      if (visibleState.visibleValuesCount > selectedValuesCount) {
        return;
      }
      if (!visibleState.visibleLimitReached && valuesWrapper.current.scrollWidth <= valuesWrapper.current.clientWidth) {
        setVisibleState({
          visibleLimitReached: false,
          visibleValuesCount: visibleState.visibleValuesCount + 1,
        });
      } else if (!visibleState.visibleLimitReached) {
        setVisibleState({
          visibleLimitReached: true,
          visibleValuesCount: visibleState.visibleValuesCount - 1,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- visibleState change
  }, [visibleState]);

  const renderChildren = () => {
    if (Array.isArray(props.children) && Array.isArray(props.children[0])) {
      const allChildren = React.Children.toArray(props.children[0]);
      const visibleChildren = allChildren.slice(0, visibleState.visibleValuesCount);
      const hiddenChildren = allChildren.slice(visibleState.visibleValuesCount);

      const tooltipLabel = getTooltipLabel({
        options: props.getValue(),
        getOptionLabel: props.selectProps.getOptionLabel,
        visibleLimitReached: visibleState.visibleLimitReached,
        visibleValuesCount: visibleState.visibleValuesCount,
      });

      const overflowingValuesIndicator = extendedComponents.OverflowingValuesIndicator ? (
        <extendedComponents.OverflowingValuesIndicator
          key={'overflowing-values-indicator'}
          visible={visibleState.visibleLimitReached}
          count={selectedValuesCount - visibleState.visibleValuesCount}
        >
          {hiddenChildren}
        </extendedComponents.OverflowingValuesIndicator>
      ) : (
        <Tooltip label={tooltipLabel} shouldWrapChildren hasArrow key={'overflowing-values-indicator'}>
          <OverflowingValuesIndicator
            visible={visibleState.visibleLimitReached}
            count={selectedValuesCount - visibleState.visibleValuesCount}
          />
        </Tooltip>
      );

      return [[...visibleChildren, overflowingValuesIndicator], props.children[1]];
    } else {
      return props.children;
    }
  };

  return (
    <components.ValueContainer {...props}>
      <Box
        display="flex"
        alignItems="center"
        height="35px"
        overflowX="hidden"
        className={'blvd-select__fixed-sized-value-container'}
        ref={valuesWrapper}
      >
        {renderChildren()}
      </Box>
    </components.ValueContainer>
  );
};
