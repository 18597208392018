import angular from 'angular';

angular.module('frontStreetApp.services').factory('Tasks', function (ResourceFactory) {
  return ResourceFactory.create({
    countAllByOrganizationId: 'GET /1/organizations/:id/tasks/count',
    get: 'GET /1/tasks/:id',
    updateDueDate: 'PUT /1/tasks/:id/due',
    updateStatus: 'PUT /1/tasks/:id/status/:status',
    assignToTask: 'PUT /1/tasks/:id/assignments/:email',
    unassignFromTask: 'DELETE /1/tasks/:id/assignments/:email',
    getAssignment: 'GET /1/tasks/:id/assignments/:organizationMembershipId',
  });
});
