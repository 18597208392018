import { Muid, Option, TaskError, updateAuditMetadata, User } from '@process-street/subgrade/core';
import { Task, TaskStatus } from '@process-street/subgrade/process';
import { BaseTaskSelector, BaseTaskTemplateSelector } from '@process-street/subgrade/redux/selector';
import { selectorWithCurrentUser } from '@process-street/subgrade/redux/selector/util/selector-with-current-user';
import { BaseReduxState } from '@process-street/subgrade/redux/types';
import { Selector } from 'reselect';
import { OptimisticService } from './model';
import { ChecklistCompleteEngineFactory } from './rules/checklist-complete';
import { DynamicDueDateEngine } from './rules/dynamic-due-date';
import { StopTaskEngineFactory } from './rules/stop-task';
import { TaskUpdateValidator } from './task-update-validator';
import { OptimisticResultBuilder } from './util/optimistic-result-builder';

type SelectorWithCurrentUserAndTask<S extends BaseReduxState, T> = (
  currentUser: User,
  task: Task,
  state: S,
) => Option<T>;

export function selectorWithCurrentUserAndTask<S extends BaseReduxState, T>(
  taskId: Muid,
  selector: SelectorWithCurrentUserAndTask<S, T>,
): Selector<S, Option<T>> {
  return selectorWithCurrentUser<S, T>((currentUser, state) => {
    const originalTask = BaseTaskSelector.getById(taskId)(state);
    if (!originalTask) {
      return undefined;
    }
    return selector(currentUser, originalTask, state);
  });
}

const updateDueDate = (taskId: Muid, dueDate: number | null) =>
  selectorWithCurrentUserAndTask(taskId, (currentUser, originalTask, state) => {
    const updateError: Option<TaskError> = TaskUpdateValidator.validateUpdateDueDate(dueDate);
    if (updateError) {
      return updateError;
    }

    const updatedTask: Task = {
      ...originalTask,
      audit: updateAuditMetadata(currentUser.id, originalTask),
      dueDate: dueDate ? dueDate : undefined,
    };

    const resultBuilder = new OptimisticResultBuilder(false).task.appendUpdateEvent(updatedTask, originalTask);

    DynamicDueDateEngine.applyOnTaskDueDateChange(updatedTask, resultBuilder, state);

    return resultBuilder.build();
  });

const updateStatus = (taskId: Muid, status: TaskStatus) => (state: BaseReduxState) => {
  const resultBuilder = new OptimisticResultBuilder(false);
  const result = applyStatusUpdateWithBuilder(taskId, status, resultBuilder)(state);

  if (result instanceof OptimisticResultBuilder) {
    return resultBuilder.build();
  }
  return result;
};

export const applyStatusUpdateWithBuilder = (
  taskId: Muid,
  status: TaskStatus,
  resultBuilder: OptimisticResultBuilder,
) =>
  selectorWithCurrentUserAndTask(taskId, (currentUser, originalTask, state) => {
    if (originalTask.status === status) {
      return undefined;
    }

    const updateError: Option<TaskError> = TaskUpdateValidator.validateUpdateTaskStatus(originalTask.id, status, state);
    if (updateError) {
      return updateError;
    }

    const updatedTask: Task = {
      ...originalTask,
      audit: updateAuditMetadata(currentUser.id, originalTask),
      completedBy: { id: currentUser.id },
      completedDate: Date.now(),
      status,
    };
    resultBuilder.task.appendUpdateEvent({ id: originalTask.id, status }, originalTask);

    const taskTemplate = BaseTaskTemplateSelector.getById(originalTask.taskTemplate.id)(state);
    if (taskTemplate) {
      const stopEngine = StopTaskEngineFactory.getByTemplateRevisionId(taskTemplate.templateRevision.id)(state);
      stopEngine.applyChangesOnTaskUpdate(updatedTask, resultBuilder, state);
    }

    ChecklistCompleteEngineFactory.getInstance().applyChangesOnTaskStatusChange(updatedTask, resultBuilder, state);
    DynamicDueDateEngine.applyOnTaskStatusChange(updatedTask, resultBuilder, state);

    return resultBuilder;
  });

export const OptimisticTask: OptimisticService<TaskError> = {
  updateDueDate,
  updateStatus,
};
