import * as React from 'react';
import {
  SearchFutureChecklistsByOrganizationIdResult,
  SearchFutureChecklistsByOrganizationIdResultItem,
  useSearchFutureChecklistsByOrganizationIdQuery,
} from 'features/future-checklist/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { FutureChecklistUtils } from 'services/future-checklist-service.utils';
import { FutureChecklist, FutureChecklistAssignment } from '@process-street/subgrade/process';
import { resetStore, useScheduledViewStore } from './store';
import { useDebounce } from 'react-use';
import { Muid } from '@process-street/subgrade/core';
import { useGetNewestTemplateRevisionsByTemplateIdsQuery } from 'features/template-revisions/query-builder';

export const DEFAULT_SEARCH_LIMIT = 10;
export const DEBOUNCE_DELAY = 500;
const DEFAULT_CHECKLIST_NAME = '<unnamed workflow run>';

export type FutureChecklistRow = FutureChecklist & {
  dueDate?: number;
  assignments: FutureChecklistAssignment[];
};

export const useScheduleStoreAndQuery = () => {
  const store = useScheduledViewStore();
  const { searchQuery, setHasMoreResults, offset, setRows } = store;
  const [debouncedValue, setDebouncedValue] = React.useState(searchQuery);
  const [offsetId, setOffsetId] = React.useState<Muid>();
  const nextOffsetIdRef = React.useRef<Muid>();
  const lastSearchRef = React.useRef('');

  React.useEffect(() => {
    setOffsetId(nextOffsetIdRef.current);
  }, [offset]);

  React.useEffect(() => {
    if (debouncedValue === '') {
      setOffsetId(undefined);
      setHasMoreResults(true);
    }
  }, [debouncedValue, setHasMoreResults]);

  useDebounce(
    () => {
      setDebouncedValue(searchQuery);
    },
    DEBOUNCE_DELAY,
    [searchQuery],
  );

  const orgId = useSelector(SessionSelector.getSelectedOrganizationId);
  React.useEffect(() => {
    resetStore();
  }, [orgId]);

  const [futureChecklistsQuerySuccess, setFutureChecklistsQuerySuccess] = React.useState(false);

  const futureChecklistQuery = useSearchFutureChecklistsByOrganizationIdQuery(
    {
      organizationId: orgId ?? '',
      query: debouncedValue,
      limit: DEFAULT_SEARCH_LIMIT,
      offsetId,
    },
    {
      // Prevent jumpy search results
      keepPreviousData: true,
      enabled: Boolean(orgId),
      onSuccess: () => setFutureChecklistsQuerySuccess(true),
      // This component is dependant to be in sync with the server and if not breaks the UI.
      // The solution should be refact it, but for now, we can set the staleTime 1s.
      staleTime: 1000,
    },
  );

  const templateIdsToQuery =
    futureChecklistQuery.data
      ?.filter(futureChecklist => !futureChecklist.futureChecklist.name)
      .map(futureChecklist => futureChecklist.futureChecklist.template.id) ?? [];
  const templateRevisionsQueries = useGetNewestTemplateRevisionsByTemplateIdsQuery(
    { templateIds: templateIdsToQuery },
    { enabled: futureChecklistQuery.isSuccess },
  );

  const isLoading = futureChecklistQuery.isLoading || templateRevisionsQueries.some(q => q.isLoading);
  const isSuccess = futureChecklistQuery.isSuccess && templateRevisionsQueries.every(q => q.isSuccess);

  const getDefaultNameForTemplate = (templateId: Muid) => {
    const index = templateIdsToQuery.indexOf(templateId);
    if (index === -1) return DEFAULT_CHECKLIST_NAME;
    const defaultChecklistName = templateRevisionsQueries[index].data?.[0]?.defaultChecklistName;
    return defaultChecklistName || DEFAULT_CHECKLIST_NAME;
  };

  const addFutureChecklistDefaultNames = (futureChecklist: SearchFutureChecklistsByOrganizationIdResultItem) => {
    const name =
      futureChecklist.futureChecklist.name || getDefaultNameForTemplate(futureChecklist.futureChecklist.template.id);
    return {
      ...futureChecklist,
      futureChecklist: {
        ...futureChecklist.futureChecklist,
        name,
      },
    };
  };

  const futureChecklists = (futureChecklistQuery.data ?? []).map(addFutureChecklistDefaultNames);
  const futureChecklistRows = transformToFutureChecklistRows(futureChecklists);

  React.useEffect(() => {
    if (!futureChecklistsQuerySuccess || !isSuccess) {
      return;
    }
    const shouldReset = debouncedValue !== lastSearchRef.current;
    if (shouldReset) {
      lastSearchRef.current = debouncedValue;
    }
    setRows({ reset: shouldReset, newRows: futureChecklistRows });
    const nextOffsetChecklist = futureChecklistRows[DEFAULT_SEARCH_LIMIT - 1];
    if (nextOffsetChecklist) {
      nextOffsetIdRef.current = nextOffsetChecklist.id;
    } else {
      setHasMoreResults(false);
    }
    setFutureChecklistsQuerySuccess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
  }, [isSuccess, futureChecklistsQuerySuccess]);

  return { store, futureChecklists, isLoading };
};

function transformToFutureChecklistRows(
  futureChecklists: SearchFutureChecklistsByOrganizationIdResult,
): FutureChecklistRow[] {
  return futureChecklists.map(({ futureChecklist, assignments }) => {
    const dueDate = futureChecklist.duePeriod
      ? FutureChecklistUtils.calculateDueDate({
          startDate: futureChecklist.nextRunDate,
          duePeriod: futureChecklist.duePeriod,
        })
      : undefined;

    return {
      ...futureChecklist,
      name: futureChecklist.name,
      dueDate,
      assignments,
    };
  });
}

export const useScheduleStore = () => {
  const { store } = useScheduleStoreAndQuery();
  return store;
};
