import {
  ChecklistColumn,
  ColumnDef,
  ConditionalFilterUtils,
  Filter,
  FormFieldValueColumnDef,
  isFormFieldValueColumnDef,
} from '@process-street/subgrade/dashboard';
import { GridHelper } from 'components/dashboard/services/grid-helper';
import { GetTemplateSchemaQueryResponse } from 'features/template/query-builder';
import create from 'zustand';
import { FormResponsesColDef, LINK_COLUMN_NAME, PREDEFINED_COLUMN_DEFS, SELECTION_COLUMN_NAME } from './model';

export type VisibleColumnField = FormResponsesColDef['field'];

interface FormResponsesStoreValues {
  conditionalFilter: Filter | undefined;
  visibleColumnFields: VisibleColumnField[];
  predefinedColDefs: FormResponsesColDef[];
  sorting?: { sortBy: ChecklistColumn; sortAsc: boolean };
}

type ValueOrLazy<T> = T | ((current: T) => T);

interface FormResponsesStoreEffects {
  setVisibleColumnFields: (columnFields: ValueOrLazy<FormResponsesStoreValues['visibleColumnFields']>) => void;
  setConditionalFilter: (filter: FormResponsesStoreValues['conditionalFilter']) => void;
  setSorting: (sorting: FormResponsesStoreValues['sorting']) => void;
}

type FormResponsesStore = FormResponsesStoreValues & FormResponsesStoreEffects;

const initialState: FormResponsesStoreValues = {
  conditionalFilter: ConditionalFilterUtils.initialConditionalFilterFormResponses,
  visibleColumnFields: ['checklistCompletedDate', 'link'],
  predefinedColDefs: PREDEFINED_COLUMN_DEFS.map(GridHelper.decorateSelectableColumn),
  sorting: { sortBy: ChecklistColumn.ChecklistCompletedDate, sortAsc: false },
};

export const useFormResponsesStore = create<FormResponsesStore>(set => {
  return {
    ...initialState,
    setVisibleColumnFields: visibleColumnFields =>
      set({
        visibleColumnFields:
          typeof visibleColumnFields === 'function'
            ? visibleColumnFields(initialState.visibleColumnFields)
            : visibleColumnFields,
      }),
    setConditionalFilter: filter => set({ conditionalFilter: filter }),
    setSorting: sorting => set({ sorting }),
  };
});

const getColDefs =
  (schema?: GetTemplateSchemaQueryResponse) =>
  ({ visibleColumnFields, predefinedColDefs }: FormResponsesStoreValues): FormResponsesColDef[] => {
    const formFieldValueColumns = getFormFieldValueColumns(schema);
    const shouldDisplayAllColumns = visibleColumnFields.length === 0;
    const predefinedColDefsBeginning = predefinedColDefs.filter(colDef => colDef.field !== LINK_COLUMN_NAME);
    const predefinedColDefsTail = predefinedColDefs.filter(colDef => colDef.field === LINK_COLUMN_NAME);
    return [...predefinedColDefsBeginning, ...formFieldValueColumns, ...predefinedColDefsTail].filter(colDef => {
      const isSelectionColumn = colDef.field === SELECTION_COLUMN_NAME;
      const isSelectedByUser = visibleColumnFields.includes(colDef.field);
      return shouldDisplayAllColumns || isSelectionColumn || isSelectedByUser;
    });
  };

const getFormFieldValueColumns = (schema?: GetTemplateSchemaQueryResponse) => {
  if (!schema?.columns) return [];
  return schema.columns?.filter(isFormFieldValueColumnDef).map(formFieldColumnDefToColDef);
};

function formFieldColumnDefToColDef(column: ColumnDef<FormFieldValueColumnDef>) {
  const dto = GridHelper.formFieldColumnDefToColumnDto(column);
  return {
    cellRenderer: GridHelper.getCellRenderer(dto),
    colId: dto.field,
    field: dto.field,
    headerName: dto.name,
    headerTooltip: dto.name,
    type: dto.columnType,
  };
}

const getConditionalFilter = ({ conditionalFilter }: FormResponsesStoreValues) => conditionalFilter;
const getSorting = ({ sorting }: FormResponsesStoreValues) => sorting;

export const FormResponsesStoreSelectors = {
  getConditionalFilter,
  getSorting,
  getVisibleColumns: getColDefs,
  getFormFieldValueColumns,
  formFieldColumnDefToColDef,
};
