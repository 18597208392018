import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';

type Context = {
  multiple: boolean;
  label: string;
  columnsChanged: boolean;
};

export const [useChecklistDashboardFiltersContext, ChecklistDashboardColumnsFilterContext] =
  createUsableContext<Context>({
    hookName: 'useChecklistDashboardFiltersContext',
    providerName: 'ChecklistDashboardColumnsFilterProvider',
  });

export const ChecklistDashboardColumnsFilterProvider: React.FunctionComponent<React.PropsWithChildren<Context>> = ({
  children,
  multiple,
  label,
  columnsChanged,
}) => {
  const value = React.useMemo(() => ({ multiple, label, columnsChanged }), [multiple, label, columnsChanged]);
  return (
    <ChecklistDashboardColumnsFilterContext.Provider value={value}>
      {children}
    </ChecklistDashboardColumnsFilterContext.Provider>
  );
};
