import angular from 'angular';

angular.module('frontStreetApp.directives').directive('psEqual', () => ({
  restrict: 'A',
  require: 'ngModel',
  link(scope, __element, attributes, ctrl) {
    const matcher = function () {
      const password1 = scope.$eval(attributes.ngModel);
      const password2 = scope.$eval(attributes.psEqual);
      return password1 === password2;
    };
    scope.$watch(matcher, match => {
      ctrl.$setValidity('equal', match);
    });
  },
}));
