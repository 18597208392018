import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetPublicTemplateQueryParams = {
  templateId: Template['id'];
};

export type GetPublicTemplateQueryResponse = Template;

export const GetPublicTemplateQuery = {
  key: ['public-templates'],
  getKey: (params: GetPublicTemplateQueryParams): QueryKey => [...GetPublicTemplateQuery.key, params],
  queryFn: (params: GetPublicTemplateQueryParams) =>
    axiosService
      .getAxios()
      .get<GetPublicTemplateQueryResponse>(`/1/templates/${params.templateId}/public`)
      .then(res => res.data),
  staleTime: 1000 * 10,
};

export const useGetPublicTemplateQuery = <Select = GetPublicTemplateQueryResponse>(
  params: GetPublicTemplateQueryParams,
  options: UseQueryOptions<GetPublicTemplateQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetPublicTemplateQuery.getKey(params), () => GetPublicTemplateQuery.queryFn(params), {
    staleTime: GetPublicTemplateQuery.staleTime,
    ...options,
  });
};
