import { FieldType, isFormFieldWidget, WidgetType } from '@process-street/subgrade/process';
import { dayjs as moment } from '@process-street/subgrade/util';
import { WidgetConstants } from '@process-street/subgrade/process/widget-constants';
import angular from 'angular';
import { connectService } from 'reducers/util';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { WidgetServiceUtils } from './widget-service.utils';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.services')
  .service(
    'WidgetService',
    function ($q, $ngRedux, $rootScope, PlanService, SessionService, util, WidgetActions, ToastService) {
      const mapStateToThis = state => ({
        featureFlags: FeatureFlagSelector.getFeatureFlags(state),
      });
      connectService('WidgetService', $ngRedux, mapStateToThis, WidgetActions)(this);

      // TODO
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;

      const logger = trace({ name: 'WidgetService' });

      /**
       * Gets list of widgets from backend for given template revision id.
       *
       * @param templateRevisionId
       * @param flushCache
       * @returns {Promise}
       */
      self.getAllByTemplateRevisionId = (templateRevisionId, flushCache) =>
        this.actions.getAllByTemplateRevisionId(templateRevisionId, flushCache).then(({ payload }) => payload);

      /**
       * Gets list of widgets from backend for given template revision id.
       *
       * @param templateRevisionId
       * @returns {Promise}
       */
      self.getAllByChecklistRevisionId = checklistRevisionId =>
        this.actions.getAllByChecklistRevisionId(checklistRevisionId).then(({ payload }) => payload);

      /**
       * Gets list of Checklist Widgets given a checklist revision id.
       *
       * @param checklistRevisionId
       * @returns {Promise}
       * */
      self.getAllChecklistWidgetsByChecklistRevisionId = checklistRevisionId =>
        this.actions.getAllChecklistWidgetsByChecklistRevisionId(checklistRevisionId).then(({ payload }) => payload);
      /**
       * Updates hiddenByDefault based on models
       * @param templateRevisionId
       * @param hiddenByDefaultModels
       * @returns {ThunkAction}
       */
      self.updateAllHiddenByDefault = (templateRevisionId, hiddenByDefaultModels) =>
        this.actions.updateAllHiddenByDefault(templateRevisionId, hiddenByDefaultModels);

      /**
       * Gets all form field only widgets by template revision id
       *
       * @param templateRevisionId
       * @return {Promise}
       */
      self.getAllFormFieldWidgetsByTemplateRevisionId = function (templateRevisionId) {
        return this.getAllByTemplateRevisionId(templateRevisionId).then(widgets =>
          widgets.filter(widget => isFormFieldWidget(widget) && widget.fieldType !== FieldType.MultiSelect),
        );
      };

      /**
       * Gets all keys of form field widgets under given template revision
       *
       * @param templateRevision
       * @param context
       * @returns {Promise}
       */
      self.getInjectableFormFieldKeys = function (templateRevision, context) {
        return self.getAllByTemplateRevisionId(templateRevision.id).then(
          widgets => WidgetServiceUtils.getFormFieldWidgetKeyMap(widgets, context),
          response => $q.reject(response),
        );
      };

      /**
       * Gets all keys of form field widgets under given checklist revision
       *
       * @param checklistRevisionId
       * @param context
       * @returns {Promise}
       */
      self.getInjectableFormFieldKeysByChecklistRevisionId = function (checklistRevisionId, context) {
        return self.getAllByChecklistRevisionId(checklistRevisionId).then(
          widgets => WidgetServiceUtils.getFormFieldWidgetKeyMap(widgets, context),
          response => $q.reject(response),
        );
      };

      /**
       * Create a widget on the server.
       *
       * @param widget
       * @param data
       * @returns {Promise}
       */
      self.create = (widget, data) => {
        const combinedData = angular.extend({}, data, {
          taskTemplate: widget.header.taskTemplate,
        });

        $rootScope.$broadcast(EventName.WIDGET_CREATE_STARTED, widget, combinedData);

        const createRequest = {
          // Widget
          id: widget.id,
          headerId: widget.header.id,
          taskTemplateId: widget.header.taskTemplate.id,
          type: widget.header.type,
          orderTree: widget.header.orderTree,
          // Form Field
          fieldType: widget.fieldType,
          key: widget.key,
          label: widget.label,
          config: widget.config,
        };

        return this.actions
          .createAt(createRequest)
          .then(
            ({ payload: createdWidget }) => {
              // TODO Make task template an explicit argument
              const { taskTemplate } = widget.header;

              const { config, key, label, required, _onCreated } = widget;
              angular.extend(widget, createdWidget, { config, key, label, required, _onCreated });

              widget.header.taskTemplate = taskTemplate;

              $rootScope.$broadcast(EventName.WIDGET_CREATE_OK, widget, combinedData);

              // TODO This should not be part of this service
              if (widget._onCreated) {
                widget._onCreated();
              }

              return widget;
            },
            response => {
              $rootScope.$broadcast(EventName.WIDGET_CREATE_FAILED, widget, combinedData);

              return $q.reject(response);
            },
          )
          .finally(() => {
            $rootScope.$broadcast(EventName.WIDGET_CREATE_FINISHED, widget, combinedData);
          });
      };

      self.createFile = (headerId, url) => this.actions.createFile(headerId, url).then(({ payload }) => payload);

      self.copy = (srcWidget, dstWidget) => {
        const data = {
          taskTemplate: srcWidget.header.taskTemplate,
        };

        $rootScope.$broadcast(EventName.WIDGET_CREATE_STARTED, dstWidget, data);

        const params = {
          srcHeaderId: srcWidget.header.id,
          dstHeaderId: dstWidget.header.id,
          orderTree: dstWidget.header.orderTree,
          key: dstWidget.key,
          label: dstWidget.label,
        };

        return this.actions
          .copy(params)
          .then(
            ({ payload: copiedWidget }) => {
              angular.copy(copiedWidget, dstWidget);

              dstWidget.header.taskTemplate = srcWidget.header.taskTemplate;

              $rootScope.$broadcast(EventName.WIDGET_CREATE_OK, dstWidget, data);

              if (dstWidget._onCreated) {
                dstWidget._onCreated();
              }

              return dstWidget;
            },
            response => {
              $rootScope.$broadcast(EventName.WIDGET_CREATE_FAILED, dstWidget, data);

              return $q.reject(response);
            },
          )
          .finally(() => {
            $rootScope.$broadcast(EventName.WIDGET_CREATE_FINISHED, dstWidget, data);
          });
      };

      /**
       * Update a widget on the server.
       *
       * @param widget
       * @param data
       * @returns {Promise}
       */
      self.update = (widget, data) => {
        const combinedData = angular.extend({}, data, {
          taskTemplate: widget.header.taskTemplate,
        });

        $rootScope.$broadcast(EventName.WIDGET_UPDATE_STARTED, widget, combinedData);

        const params = angular.extend({}, widget, { headerId: widget.header.id });
        return this.actions
          .update(angular.extend(params))
          .then(
            ({ payload: response }) => {
              $rootScope.$broadcast(EventName.WIDGET_UPDATE_OK, widget, combinedData);

              return response;
            },
            response => {
              $rootScope.$broadcast(EventName.WIDGET_UPDATE_FAILED, widget, combinedData);

              return $q.reject(response);
            },
          )
          .finally(() => {
            $rootScope.$broadcast(EventName.WIDGET_UPDATE_FINISHED, widget, combinedData);
          });
      };

      self.onOrderTreesBulkUpdateSuccess = function (bulkUpdateResponses) {
        let atLeastOneFailed = false;
        bulkUpdateResponses.forEach(res => {
          if (res.response !== WidgetConstants.BulkUpdateResponse.OK) {
            atLeastOneFailed = true;
          }
        });

        if (atLeastOneFailed) {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems moving the widgets`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        }
      };

      self.onOrderTreesBulkUpdateFailure = function (__response, __movedTaskTemplates, __orderTreeMap, widgets) {
        self.sortWidgets(widgets);
      };

      /**
       * Updates order trees for a list of widget headers.
       *
       * @param widgetsToUpdate
       * @param newOrderTreeMap - This is a map from task template id to order tree
       * @returns {*}
       */
      self.updateOrderTrees = (widgetsToUpdate, newOrderTreeMap) => {
        const orderModels = widgetsToUpdate.map(widget => ({
          widgetHeaderId: widget.header.id,
          orderTree: newOrderTreeMap[widget.id],
        }));

        return this.actions.updateAllOrderTrees({ orderModels }).then(
          ({ payload: response }) => {
            return response;
          },
          response => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems moving the widgets`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });

            return $q.reject(response);
          },
        );
      };

      /**
       * Delete a widget on the server.
       *
       * @param widget
       * @param data
       * @returns {Promise}
       */
      self.delete = (widget, data) => {
        const combinedData = angular.extend({}, data, {
          taskTemplate: widget.header.taskTemplate,
        });

        return this.actions
          .deleteByHeaderId(widget.header.id)
          .then(
            ({ payload: response }) => {
              $rootScope.$broadcast(EventName.WIDGET_DELETE_OK, widget, combinedData);

              return response;
            },
            response => {
              $rootScope.$broadcast(EventName.WIDGET_DELETE_FAILED, widget, combinedData);

              return $q.reject(response);
            },
          )
          .finally(() => {
            $rootScope.$broadcast(EventName.WIDGET_DELETE_FINISHED, widget, combinedData);
          });
      };

      self.isEmpty = function (widget) {
        let nonEmpty = false;

        switch (widget.header.type) {
          case WidgetType.Email:
            nonEmpty = widget.recipient || widget.cc || widget.bcc || widget.subject || widget.body;
            break;
          case WidgetType.Embed:
            nonEmpty = widget.url;
            break;
          case WidgetType.CrossLink:
            nonEmpty = !!widget.templateId;
            break;
          case WidgetType.FormField:
            nonEmpty = widget.fieldType !== FieldType.Snippet && widget.fieldType !== FieldType.Hidden;
            break;
          case WidgetType.File:
            nonEmpty = widget.description || (widget.file && widget.file.url);
            break;
          case WidgetType.Image:
            nonEmpty = widget.caption || (widget.file && widget.file.url);
            break;
          case WidgetType.Text:
            nonEmpty = widget.content && widget.content !== '<p><br data-mce-bogus="1"></p>';
            break;
          case WidgetType.Video:
            nonEmpty =
              widget.description ||
              (widget.service && widget.serviceCode) ||
              (widget.service && widget.file && widget.file.url);
            break;
          default:
            logger.error('unexpected error: invalid widget type "%s"', widget.header.type);
        }

        return !nonEmpty;
      };

      self.getAllByTaskTemplateId = taskTemplateId =>
        this.actions.getAllByTaskTemplateId(taskTemplateId).then(({ payload }) => payload);

      self.cacheAll = widgets => this.actions.cacheWidgets(widgets);

      /**
       * Format date to "5 min ago" style
       *
       * @param date timestamp
       * @param timeZone
       * @returns {{updatedDate: *}}
       */
      self.formatDateToTz = function (date, timeZone) {
        return moment.tz(date, timeZone || 'UTC').fromNow();
      };

      /**
       * This is the comparer used to sort widgets.
       */
      const widgetComparer = util.getOrderableComparer(widget => widget.header);

      /**
       * Sorts a list of widgets in-place and then returns it.
       *
       * @param widgets
       * @returns The same array that was passed in.
       */
      self.sortWidgets = function (widgets) {
        return widgets.sort(widgetComparer);
      };

      /**
       * Generates a service URL that can be displayed to the user.
       *
       * @param service
       * @param serviceCode
       * @returns {*}
       */
      self.generateServiceUrl = function (service, serviceCode) {
        let url;
        switch (service) {
          case 'Vimeo':
            url = `https://vimeo.com/${serviceCode}`;
            break;
          case 'Wistia':
            url = `https://process.wistia.com/medias/${serviceCode}`;
            break;
          case 'YouTube':
            url = `https://youtube.com/watch?v=${serviceCode}`;
            break;
          default:
            url = undefined;
        }
        return url;
      };

      /**
       * Adds a Process Street message at the end of the email body for free accounts.
       *
       * @param organization
       * @param body
       */
      self.generateEmailBody = function (organization, body) {
        if (organization?.subscription?.plan?.id !== undefined) {
          return PlanService.getById(organization.subscription.plan.id).then(plan => {
            let newBody = body;

            if (PlanService.isPlanFree(plan)) {
              newBody += ' \n\nSent by Process.st';
            }

            return newBody;
          });
        } else {
          return $q.resolve(body);
        }
      };
    },
  );
