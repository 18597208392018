import {
  Condition,
  ChecklistRuleDefinition,
  FormFieldCondition as FormFieldConditionModel,
  TaskCondition as TaskConditionModel,
  TimeBasedCondition as TimeBasedConditionModel,
} from '@process-street/subgrade/conditional-logic';
import { TaskTemplatePrintAttributes } from './print-template-rules';
import { Muid } from '@process-street/subgrade/core';
import { WidgetAttributes } from '../../context';
import { TaskTemplate } from '@process-street/subgrade/process';

export const getGroupedTasksAndWidgets = (
  taskGroupIds: Map<string, TaskTemplatePrintAttributes>,
  widgetGroupIds: Map<string, WidgetAttributes>,
  rule?: ChecklistRuleDefinition,
) => {
  const groupedTasksWidgetsMap = new Map<string, { task: TaskTemplatePrintAttributes; widgets: WidgetAttributes[] }>();
  let hiddenByDefaultCount = 0;

  const setTask = ({
    taskTemplateGroupId,
    ruleAppliesToTask = false,
  }: {
    taskTemplateGroupId: Muid;
    ruleAppliesToTask?: boolean;
  }) => {
    const task = taskGroupIds.get(taskTemplateGroupId);
    if (!task) {
      return;
    }
    if (task.hiddenByDefault) {
      hiddenByDefaultCount++;
    }
    groupedTasksWidgetsMap.set(task.group.id, {
      task: { ...task, ruleAppliesToTask },
      widgets: [],
    });
  };

  const setWidget = ({
    widgetGroupId,
    ruleAppliesToWidget = false,
  }: {
    widgetGroupId: Muid;
    ruleAppliesToWidget?: boolean;
  }) => {
    const displayValue = widgetGroupIds.get(widgetGroupId);
    if (!displayValue) {
      return;
    }
    const { widget } = displayValue;
    if (widget.header.hiddenByDefault) {
      hiddenByDefaultCount++;
    }
    const taskTemplateGroupId = (widget?.header.taskTemplate as TaskTemplate).group.id;
    const taskTemplate = groupedTasksWidgetsMap.get(taskTemplateGroupId);
    if (taskTemplate) {
      taskTemplate.widgets.push({ ruleAppliesToWidget, ...displayValue });
    } else {
      setTask({ taskTemplateGroupId });
      groupedTasksWidgetsMap.get(taskTemplateGroupId)?.widgets.push({ ruleAppliesToWidget, ...displayValue });
    }
  };

  if (rule) {
    rule.taskTemplateGroupIds.forEach(taskTemplateGroupId => setTask({ taskTemplateGroupId, ruleAppliesToTask: true }));
    Array.from(widgetGroupIds.keys()).forEach(widgetGroupId => {
      const ruleAppliesToWidget = rule.widgetGroupIds.includes(widgetGroupId);
      setWidget({ widgetGroupId, ruleAppliesToWidget });
    });
  } else {
    Array.from(taskGroupIds.keys()).forEach(taskTemplateGroupId => setTask({ taskTemplateGroupId }));
    Array.from(widgetGroupIds.keys()).forEach(widgetGroupId => setWidget({ widgetGroupId }));
  }
  const groupedTasksWidgets = Array.from(groupedTasksWidgetsMap.values());

  return { groupedTasksWidgets, hiddenByDefaultCount };
};

export function isFormFieldCondition(condition: Condition): condition is FormFieldConditionModel {
  return 'formFieldWidgetGroupId' in condition && 'operandValue' in condition;
}

export function isTaskCondition(condition: Condition): condition is TaskConditionModel {
  return 'taskTemplateGroupId' in condition && 'operand' in condition;
}

export function isTimeBasedCondition(condition: Condition): condition is TimeBasedConditionModel {
  return 'dateType' in condition;
}
