import {
  AutomationInstance,
  AutomationInstanceUtils,
  getPrefixedAutomationAppName,
} from '@process-street/subgrade/automation';
import React from 'react';
import { Text, HStack } from 'components/design/next';
import { AutomationAppIcon } from 'features/automations/components/automation-app-icon';

export const AutomationInstanceBadge: React.FC<
  React.PropsWithChildren<{
    automationInstance: AutomationInstance;
    shouldCollapseLabels: boolean;
  }>
> = ({ automationInstance: i, shouldCollapseLabels }) => {
  const label = AutomationInstanceUtils.getLabel(i);
  const safeAppName = getPrefixedAutomationAppName(i);

  return (
    <HStack
      cursor="default"
      justifyContent="center"
      bg="white"
      borderRadius="base"
      fontWeight="normal"
      color="gray.600"
      px="2"
      h="8"
      {...{
        ...(shouldCollapseLabels
          ? {
              'spacing': '0',
              'aria-label': label,
              'children': <AutomationAppIcon automationApp={safeAppName} w="4" />,
            }
          : {
              spacing: '1',
              children: (
                <>
                  <AutomationAppIcon automationApp={safeAppName} w="4" flexShrink={0} />
                  <Text as="span" flex="0 1 auto" noOfLines={1} variant="-1">
                    {label}
                  </Text>
                </>
              ),
            }),
      }}
    />
  );
};
