import { AnalyticsConstants } from '@process-street/subgrade/analytics';
import { dayjs as moment, htmlEscaped, HttpStatus } from '@process-street/subgrade/util';
import { ChecklistStatus, ConflictResultType, TaskStatus } from '@process-street/subgrade/process';
import { ProcessingErrorUtils } from '@process-street/subgrade/core';
import { Direction } from '@process-street/subgrade/search/checklist-search-constants';
import angular from 'angular';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { TaskSelector } from 'reducers/task/task.selectors';
import { connectService } from 'reducers/util';
import { queryClient } from 'components/react-root';
import isEmpty from 'lodash/isEmpty';
import { FeatureLimit } from './features/features';
import { isChecklistActionable } from 'app/utils/checklist';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { trace } from 'components/trace';
import { OneOffTaskHelper } from 'features/one-off-tasks/components/shared/one-off-task-helper';
import { StopTaskEvent } from 'services/stop-task-event';
import { ChecklistEvent } from 'services/checklists/checklist-event';
import { PromiseQueueKeyGenerator } from './promise-queue/promise-queue-key-generator-pure';
import { AnalyticsService } from 'components/analytics/analytics.service';
import { match } from 'ts-pattern';

const MIN_ORGANIZATION_CREATED_DAYS_FOR_SHARE = 7;
const DAYS_IN_MILIS = 24 * 60 * 60 * 1000;

angular
  .module('frontStreetApp.services')
  .service(
    'ChecklistService',
    function (
      $ngRedux,
      $q,
      $rootScope,
      $state,
      $timeout,
      ChecklistApi,
      ChecklistAssignmentService,
      ChecklistActions,
      DataService,
      FeatureFlagService,
      FormFieldValueService,
      PermitService,
      PlanService,
      PromiseQueueDescGenerator,
      PromiseQueueService,
      RequiredFieldService,
      SecurityService,
      SessionService,
      ToastService,
      StopTaskService,
      Subject,
      TaskStatsService,
      UserService,
    ) {
      const logger = trace({ name: 'ChecklistService' });

      const self = this;
      connectService('ChecklistService', $ngRedux, null, ChecklistActions)(self);

      $rootScope.$on(ChecklistEvent.DELETE_UNDO, (__event, checklist) => {
        self.undelete(checklist.id, checklist.status);
      });

      self.create = function (template, options) {
        $rootScope.$broadcast(ChecklistEvent.CREATE_STARTED, template, options);

        return self.actions
          .create({
            templateId: template.id,
            name: options.name,
            dueDate: options.dueDate,
            formFields: options.formFields || {},
          })
          .then(
            ({ payload: checklist }) => {
              $rootScope.$broadcast(ChecklistEvent.CREATE_OK, checklist, template, options);

              const permitsRequest = PermitService.getAllWithOrganizationMembershipAndUser(
                'checklist',
                checklist.id,
              ).then(permits => {
                DataService.getCollection('checklistPermits').put(permits);

                return checklist;
              });

              if (options.autoAssign) {
                return UserService.getById(checklist.audit.createdBy.id)
                  .then(createdBy =>
                    ChecklistAssignmentService.assignOrInvite(checklist, createdBy, true /* autoAssign */),
                  )
                  .then(
                    () => permitsRequest,
                    () => permitsRequest,
                  );
              } else {
                return permitsRequest;
              }
            },
            response => {
              $rootScope.$broadcast(ChecklistEvent.CREATE_FAILED, response, template, options);
              return $q.reject(response);
            },
          );
      };

      /**
       * Clear relevant react-query cached data
       */
      self.clearReactQueryCache = function () {
        queryClient.invalidateQueries(['checklists', 'can-use']);
      };

      self.clearAndRedirect = checklist => {
        self.clearReactQueryCache();
        $state.go('checklist', { id: checklist.id }, { inherit: false });
      };

      self.createAndRedirect = (template, name, user) =>
        self
          .createWithPaymentRequiredCheck({ template, name, user, autoAssign: !isAnonymousUser(user) })
          .then(self.clearAndRedirect);

      self.createWithPaymentRequiredCheck = ({ template, name, user, dueDate, autoAssign = false }) =>
        self.create(template, { name, autoAssign, dueDate }).catch(response => {
          if (response.status === HttpStatus.PAYMENT_REQUIRED) {
            const { featureLimit } = response.data;
            self.showLimitReachedMessageAndRedirect(user, featureLimit);
          } else {
            const description = match(response.data)
              .with({ message: 'due date must be in the future' }, () => 'The due date must be in the future')
              .otherwise(() => DefaultErrorMessages.unexpectedErrorDescription);

            ToastService.openToast({
              status: 'error',
              title: `We're having problems creating the workflow run`,
              description,
            });
          }
        });

      self.getInvalidFieldsMap = function ({
        taskTemplates,
        taskMap,
        widgetsMap,
        formFieldValueMap,
        ignoreRequiredFields = false,
      }) {
        // Filtering out disabled tasks to avoid checking for required tasks in them
        const { disabledTaskTemplateGroupIds, firstStopGroupId } =
          StopTaskService.getDisabledTaskTemplateGroupIdsByFormFieldValue(
            taskTemplates,
            widgetsMap,
            formFieldValueMap,
            taskMap,
          );

        const enabledTaskTemplates = taskTemplates.filter(
          taskTemplate =>
            taskTemplate.group.id === firstStopGroupId || !disabledTaskTemplateGroupIds.includes(taskTemplate.group.id),
        );

        const invalidFormFieldsMap = RequiredFieldService.getAllTaskToInvalidFieldsMap(
          enabledTaskTemplates,
          widgetsMap,
          formFieldValueMap,
          taskMap,
        );
        const failedConstraintsMap = FormFieldValueService.getAllTasksFailedConstraintsMap(
          enabledTaskTemplates,
          widgetsMap,
          formFieldValueMap,
          taskMap,
        );

        // only include keys if there are invalid widgets
        return {
          ...(!isEmpty(invalidFormFieldsMap) && !ignoreRequiredFields && { invalidFormFields: invalidFormFieldsMap }),
          ...(!isEmpty(failedConstraintsMap) && { failedConstraintsFormFields: failedConstraintsMap }),
        };
      };

      self.validateAndCompleteByChecklistRevision = ({
        checklistRevision,
        formFieldValueMap,
        complete,
        taskStatsMap,
        oneOffTasks,
      }) => {
        const state = $ngRedux.getState();

        const { checklist } = checklistRevision;

        const taskTemplates = TaskTemplateSelector.getAllByTemplateRevisionId(checklistRevision.templateRevision.id)(
          state,
        );
        const taskMap = TaskSelector.getTaskMapByChecklistIdGroupedByTaskTemplateGroupId(checklist.id)(state);

        const visibleOnly = true;
        const widgetsMap = WidgetSelector.getTaskTemplateGroupIdToWidgetByChecklistRevisionId(
          checklistRevision.id,
          visibleOnly,
        )(state);

        self.validateAndComplete({
          checklist,
          taskTemplates,
          taskMap,
          widgetsMap,
          formFieldValueMap,
          complete,
          taskStatsMap,
          oneOffTasks,
        });
      };

      self.validateNonEmptyValues = ({ taskTemplates, taskMap, widgetsMap, formFieldValueMap }) => {
        const invalidFieldsMap = self.getInvalidFieldsMap({
          taskTemplates,
          taskMap,
          widgetsMap,
          formFieldValueMap,
          ignoreRequiredFields: true,
        });
        const invalidFieldsMapIsEmpty = angular.equals(invalidFieldsMap, {});

        if (!invalidFieldsMapIsEmpty) {
          self._notifyOfInvalidFields({
            taskTemplates,
            invalidFieldsMap,
            ignoreRequiredFields: true,
            showDangerNotice: false,
          });
        }
      };

      self.validateAndComplete = ({
        checklist,
        taskTemplates,
        taskMap,
        widgetsMap,
        formFieldValueMap,
        complete = true,
        taskStatsMap = {},
        oneOffTasks = [],
      }) => {
        const invalidFieldsMap = self.getInvalidFieldsMap({ taskTemplates, taskMap, widgetsMap, formFieldValueMap });
        const invalidFieldsMapIsEmpty = angular.equals(invalidFieldsMap, {});

        const notCompletedStopTasks = StopTaskService.getNotCompletedVisibleStopTasks(taskTemplates, taskMap);
        const requiredOneOffTasks = OneOffTaskHelper.getRequiredNotCompletedTasks(oneOffTasks);

        // Don't do optimistic update if there are hidden incomplete tasks - they may be hidden because of time-based CL
        const hasHiddenIncompleteTasks = Object.values(taskMap).some(
          task => task.hidden && task.status !== TaskStatus.Completed,
        );

        if (!invalidFieldsMapIsEmpty) {
          self._notifyOfInvalidFields({ taskTemplates, invalidFieldsMap });
        } else if (notCompletedStopTasks.length) {
          self._notifyOfInvalidStopTasks(notCompletedStopTasks);
        } else if (TaskStatsService.isChecklistStoppedByNotPermittedTask(Object.values(taskMap), taskStatsMap)) {
          self._notifyOfInvalidNotPermittedTask();
        } else if (requiredOneOffTasks.length) {
          self._notifyOfRequiredOneOffTasks(requiredOneOffTasks);
        } else if (complete) {
          const isOptimistic = !hasHiddenIncompleteTasks;
          self.updateStatus(checklist, ChecklistStatus.Completed, isOptimistic);
        }
      };

      self._notifyOfRequiredOneOffTasks = requiredOneOffTasks => {
        $rootScope.$broadcast(StopTaskEvent.CHECKLIST_HAS_NOT_COMPLETED_ATTACHED_TASKS, requiredOneOffTasks);

        const message =
          requiredOneOffTasks.length === 1
            ? '1 task still needs to be completed.'
            : `${requiredOneOffTasks.length} tasks still need to be completed.`;

        ToastService.openToast({
          status: 'warning',
          title: `We couldn't complete the workflow run`,
          description: message,
        });
      };

      self._notifyOfInvalidStopTasks = notCompletedStopTasks => {
        StopTaskService.broadcastChecklistHasNotCompletedStopTasks(notCompletedStopTasks);

        const message =
          notCompletedStopTasks.length === 1
            ? '1 task still needs to be completed.'
            : `${notCompletedStopTasks.length} tasks still need to be completed.`;

        ToastService.openToast({
          status: 'warning',
          title: `We couldn't complete the workflow run`,
          description: message,
        });
      };

      self._notifyOfInvalidNotPermittedTask = () => {
        ToastService.openToast({
          status: 'warning',
          title: `We couldn't complete the workflow run`,
          description:
            'Some form fields still need to be completed by someone else before you can continue with this workflow run.',
        });
      };

      self._notifyOfInvalidFields = ({
        taskTemplates,
        invalidFieldsMap,
        ignoreRequiredFields = false,
        showDangerNotice = true,
      }) => {
        const invalidTotal = {
          requiredCount: 0,
          failedCount: 0,
        };
        taskTemplates.forEach(taskTemplate => {
          const invalidFields = invalidFieldsMap.invalidFormFields?.[taskTemplate.group.id] ?? [];
          const failedConstraintsFormFields =
            invalidFieldsMap.failedConstraintsFormFields?.[taskTemplate.group.id] ?? [];

          if (invalidFields.length > 0 && !ignoreRequiredFields) {
            RequiredFieldService.broadcastTaskHasInvalidFormFields(taskTemplate, invalidFields);
            invalidTotal.requiredCount += invalidFields.length;
          }
          if (failedConstraintsFormFields.length > 0) {
            FormFieldValueService.broadcastTaskHasFailedConstraintsFormFields(
              taskTemplate,
              failedConstraintsFormFields,
            );
            FormFieldValueService.broadcastChecklistHasFailedConstraintsFormFields({
              taskTemplate,
              failedConstraintsFormFields,
              ignoreRequiredFields,
            });
            invalidTotal.failedCount += failedConstraintsFormFields.length;
          }
        });

        if (showDangerNotice) {
          const errorMessage = ProcessingErrorUtils.makeRequiredOrFailedMessage(invalidTotal);

          ToastService.openToast({
            status: 'warning',
            title: `We couldn't complete the workflow run`,
            description: errorMessage,
          });
        }
      };

      self.updateStatus = function (checklist, newStatus, isOptimistic = true) {
        const originalChecklist = angular.copy(checklist);

        const currentUser = SessionService.getUser();
        switch (newStatus) {
          case ChecklistStatus.Archived:
            checklist.archivedBy = currentUser;
            checklist.archivedDate = Date.now();
            break;
          case ChecklistStatus.Completed:
            checklist.completedBy = currentUser;
            checklist.completedDate = Date.now();
            break;
          default: // It's ok
        }

        isOptimistic && self._mutateChecklistStatus(checklist, newStatus);

        $rootScope.$broadcast(ChecklistEvent.UPDATE_STARTED, {
          updatedChecklist: checklist,
          originalChecklist,
          isOptimistic,
        });

        const queueDesc = PromiseQueueDescGenerator.generateUpdateStatusByChecklistId(checklist.id, newStatus);
        const queueKey = PromiseQueueKeyGenerator.generateByChecklistId(checklist.id);
        return PromiseQueueService.enqueue(
          queueKey,
          () =>
            ChecklistApi.updateStatus(checklist.id, newStatus).then(
              updatedChecklist => {
                const data = {
                  updatedChecklist,
                  originalChecklist,
                  isOptimistic,
                };
                $rootScope.$broadcast(ChecklistEvent.UPDATE_OK, data);

                checklist.audit = updatedChecklist.audit;
                checklist.archivedBy = updatedChecklist.archivedBy;
                checklist.archivedDate = updatedChecklist.archivedDate;
                checklist.completedBy = updatedChecklist.completedBy;
                checklist.completedDate = updatedChecklist.completedDate;
                !isOptimistic && self._mutateChecklistStatus(checklist, newStatus);

                self.clearReactQueryCache();

                return updatedChecklist;
              },
              response => {
                logger.warn(
                  'failed to update checklist status ' +
                    `from '${originalChecklist.status}' to '${newStatus}' (${response.status})`,
                );

                const data = {
                  editedChecklist: { ...checklist, status: newStatus },
                  originalChecklist,
                  response,
                };
                $rootScope.$broadcast(ChecklistEvent.UPDATE_FAILED, data);
                if (response.data && response.data.conflictType === ConflictResultType.InvalidFormFields) {
                  RequiredFieldService.broadcastChecklistHasInvalidFormFields(response.data.invalidFormFields);
                }

                if (response.status === HttpStatus.PAYMENT_REQUIRED) {
                  const { featureLimit } = response.data;
                  self.showLimitReachedMessageAndRedirect(UserService.getCurrentUser(), featureLimit);
                }

                checklist.status = originalChecklist.status;
                checklist.archivedBy = originalChecklist.archivedBy;
                checklist.archivedDate = originalChecklist.archivedDate;
                checklist.completedDate = originalChecklist.completedDate;
                checklist.completedBy = originalChecklist.completedBy;

                return $q.reject(response);
              },
            ),
          queueDesc,
        );
      };

      self._mutateChecklistStatus = function (checklist, newStatus) {
        // This mutation displays green "completed" status bar
        checklist.status = newStatus;

        // Keep some stats, so we can control how often we get confetti
        if (checklist.status === ChecklistStatus.Completed) {
          const count = SessionService.getChecklistStatsProperty('completedCount') || 0;
          SessionService.setChecklistStatsProperty('completedCount', count + 1);
        }
      };

      self.isChecklistCompleted = function (checklist) {
        return checklist && checklist.status === ChecklistStatus.Completed;
      };

      self.isChecklistArchived = function (checklist) {
        return checklist && checklist.status === ChecklistStatus.Archived;
      };

      self.isChecklistActionable = isChecklistActionable;

      self.getStatsByIds = checklistIds => self.actions.getStats(checklistIds).then(({ payload: stats }) => stats);

      self.getAllTaskStatsById = function (checklistId) {
        return self.actions.getTaskStatsById(checklistId).then(({ payload: taskStats }) => taskStats);
      };

      self.search = function (
        organizationId,
        templateId,
        query,
        referenceChecklistId,
        direction = Direction.AFTER,
        limit,
      ) {
        const criteria = {
          organizationId,
          templateId,
          query: query && query.trim(),
          referenceChecklistId,
          direction,
        };
        return self.actions.search(criteria, limit).then(({ payload: searchResults }) => searchResults);
      };

      /**
       * Gets checklist by id
       *
       * @param checklistId
       *
       * @returns {Promise}
       */
      self.get = function (checklistId) {
        return self.actions.getById(checklistId).then(({ payload: checklist }) => checklist);
      };

      self.getAllByOrganizationId = query =>
        self.actions.getAllByOrganizationId(query).then(({ payload: checklists }) => checklists);

      self.updateDueDate = function (checklist, newDueDate, originalDueDate) {
        const originalChecklist = angular.copy(checklist);
        // TODO this should not happen, but Inbox list updates checklist with new dueDate, effectively loosing the
        if (originalDueDate) {
          originalChecklist.dueDate = originalDueDate;
        }
        checklist.dueDate = newDueDate;

        $rootScope.$broadcast(ChecklistEvent.UPDATE_STARTED, {
          updatedChecklist: checklist,
          originalChecklist,
        });
        $rootScope.$broadcast(ChecklistEvent.UPDATE_DUE_DATE_STARTED, {
          updatedChecklist: checklist,
          originalChecklist,
        });

        return self.actions.updateDueDateById(checklist.id, checklist.dueDate).then(
          ({ payload: response }) => {
            const { updatedChecklist, dueDateTaskStates } = response;

            const data = {
              updatedChecklist: { ...updatedChecklist, template: checklist.template },
              originalChecklist,
            };

            $rootScope.$broadcast(ChecklistEvent.UPDATE_OK, data);
            $rootScope.$broadcast(ChecklistEvent.UPDATE_DUE_DATE_OK, dueDateTaskStates);

            return updatedChecklist;
          },
          response => {
            const data = {
              editedChecklist: checklist,
              originalChecklist,
              errorHandled: true,
            };

            $rootScope.$broadcast(ChecklistEvent.UPDATE_FAILED, data);

            return $q.reject(response);
          },
        );
      };

      self.updateName = function (checklist, newName) {
        const originalChecklist = angular.copy(checklist);

        const processedName = newName && newName.replace(/(?:\r\n|\r|\n)/g, ' ');
        checklist.name = newName;

        $rootScope.$broadcast(ChecklistEvent.UPDATE_STARTED, {
          updatedChecklist: checklist,
          originalChecklist,
        });

        return self.actions.updateNameById(checklist.id, processedName).then(
          ({ payload: updatedChecklist }) => {
            const data = {
              updatedChecklist,
              originalChecklist,
            };

            $rootScope.$broadcast(ChecklistEvent.UPDATE_OK, data);

            return updatedChecklist;
          },
          response => {
            const data = {
              editedChecklist: checklist,
              originalChecklist,
              errorHandled: true,
            };

            $rootScope.$broadcast(ChecklistEvent.UPDATE_FAILED, data);

            return $q.reject(response);
          },
        );
      };

      self.delete = function (checklist) {
        return self.actions.deleteById(checklist.id).then(
          ({ payload: response }) => {
            AnalyticsService.trackEvent(AnalyticsConstants.Event.CHECKLIST_DELETED);

            $rootScope.$broadcast(ChecklistEvent.DELETE_OK, checklist);
            self.clearReactQueryCache();

            return response;
          },
          response => {
            $rootScope.$broadcast(ChecklistEvent.DELETE_FAILED);

            return $q.reject(response);
          },
        );
      };

      self.undelete = function (id, status) {
        const queueDesc = PromiseQueueDescGenerator.generateUpdateStatusByChecklistId(id, status);
        const queueKey = PromiseQueueKeyGenerator.generateByChecklistId(id);
        return PromiseQueueService.enqueue(
          queueKey,
          () =>
            self.actions
              .undelete(id)
              .then(({ payload: checklist }) => {
                if (status !== ChecklistStatus.Active) {
                  return ChecklistApi.updateStatus(id, status).then(response => {
                    checklist.status = response.status;
                    $rootScope.$broadcast(ChecklistEvent.UNDELETE_OK, checklist);

                    return checklist;
                  });
                } else {
                  $rootScope.$broadcast(ChecklistEvent.UNDELETE_OK, checklist);
                  self.clearReactQueryCache();

                  return checklist;
                }
              })
              .catch(response => {
                $rootScope.$broadcast(ChecklistEvent.UNDELETE_FAILED);

                return $q.reject(response);
              }),
          queueDesc,
        );
      };

      /**
       * Resolves the name for new checklist based on query params or generates default one
       *
       * @param currentUser
       * @param queryParams
       *
       * @returns string
       */
      self.resolveChecklistName = function (currentUser, queryParams) {
        const now = moment();
        const nowStr = now.format('h:mmA');

        let checklistName = htmlEscaped`${currentUser.username}'s ${nowStr} workflow run`;

        const params = queryParams || {};

        if (params['checklist_name']) {
          // legacy
          checklistName = params['checklist_name'];
        } else if (params['run_name']) {
          checklistName = params['run_name'];
        } else if (isAnonymousUser(currentUser)) {
          checklistName = `Shared ${nowStr} workflow run`;
        }

        return checklistName;
      };

      self.canShare = function () {
        const selectedOrganization = SessionService.getSelectedOrganization();
        return PlanService.getById(selectedOrganization.subscription.plan.id).then(currentPlan => {
          const isShareLinksEntitlementEnabled = FeatureFlagService.getFeatureFlags().shareLinksEntitlement;
          const isPaidAccount = PlanService.isPaidAccount(currentPlan, selectedOrganization.subscription.status);

          const organiationCreatedDateMilis = selectedOrganization?.audit.createdDate;

          if (!organiationCreatedDateMilis) {
            return false;
          }

          const minimumDate = new Date(new Date().getTime() - MIN_ORGANIZATION_CREATED_DAYS_FOR_SHARE * DAYS_IN_MILIS);

          const isOrganizationOldEnough = organiationCreatedDateMilis < minimumDate.getTime();

          return isShareLinksEntitlementEnabled || (isPaidAccount && isOrganizationOldEnough);
        });
      };

      self.getLimitReachedMessage = function (featureLimit) {
        const selectedOrganization = SessionService.getSelectedOrganization();
        return PlanService.getById(selectedOrganization.subscription.plan.id).then(currentPlan => {
          const planTitle = PlanService.getTitleForPlanLevel(currentPlan.level);
          let limit = '???';

          let message = '';
          switch (featureLimit) {
            case FeatureLimit.ACTIVE_CHECKLIST:
              if (PlanService.isPlanIdLegacy(currentPlan.id)) {
                message =
                  'Free accounts have a limit of 5 active checklists.<br>' +
                  'To add more, either upgrade your plan or archive some checklists.';
              } else {
                limit = currentPlan.featureSet.activeChecklistsLimit;
                message =
                  `${planTitle} plan has a limit of ${limit} active checklists.<br>` +
                  'To add more, either upgrade your plan or archive some checklists.';
              }
              break;
            case FeatureLimit.CHECKLIST_RUN:
              limit = currentPlan.featureSet.checklistRunsLimit;
              message =
                `${planTitle} plan has a limit of ${limit} checklists runs per month.<br>` +
                'Upgrade your plan to have more.';
              break;
            default:
          }

          return message;
        });
      };

      self.showLimitReachedMessageAndRedirect = function (user, featureLimit) {
        const selectedOrganizationId = SecurityService.getSelectedOrganizationIdByUser(user);
        const subject = new Subject(user.id, selectedOrganizationId);
        if (subject.admin) {
          $state.go('organizationManage.tab', { tab: 'billing' });
        }

        // This is necessary because if we're switching between loader and non-loader pages the message is lost
        // Adding this timeout makes it fire after the route has changed
        $timeout(() => {
          self.getLimitReachedMessage(featureLimit).then(message => {
            ToastService.openToast({
              status: 'warning',
              title: `You've reached your account limits`,
              description: message,
            });
          });
        });
      };

      self.updateShareable = (checklistId, shared) =>
        self.actions.updateShareable(checklistId, shared).then(({ payload: updateResult }) => updateResult);

      self.getById = (id, flushCache) =>
        self.actions.getById(id, flushCache).then(({ payload: checklist }) => checklist);

      self.assignUser = (checklistId, email, autoAssign) =>
        self.actions.assignUser(checklistId, email, autoAssign).then(({ payload: assignment }) => assignment);

      self.unassignUser = (checklistId, email) =>
        self.actions.unassignUser(checklistId, email).then(({ payload }) => payload);
    },
  );
