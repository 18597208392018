import * as React from 'react';
import { Box, Text } from 'components/design/next';
import { LimitInfo } from 'pages/organizations/manage/billing/models';

export type LimitsInfoProps = {
  title: string;
  limitInfo: LimitInfo;
};

export const LimitsInfo: React.FC<React.PropsWithChildren<LimitsInfoProps>> = ({ title, limitInfo }) => {
  const limitText = limitInfo.limit ? `${limitInfo.usage} / ${limitInfo.limit}` : `${limitInfo.usage}`;

  return (
    <Box mr={12}>
      <Text variant="-2u" fontWeight="bold" color="gray.500">
        {title}
      </Text>
      <Text color="gray.600">{limitText}</Text>
    </Box>
  );
};
