import angular from 'angular';
import { DateUtils } from '@process-street/subgrade/util';
import { SnoozeService } from 'services/snooze-service';
import templateUrl from './snoozer.component.html';
import './snoozer.scss';

angular
  .module('frontStreetApp.directives')
  .controller('SnoozerBoxCtrl', function ($scope) {
    let box;

    $scope.snoozeTargets = SnoozeService.generateSnoozeTargets();

    $scope.resolveSnoozeDateFormat = DateUtils.formatSnoozeDate;

    $scope.$on('init', (__event, values) => {
      ({ box } = values);
      $scope.dueDate = values.dueDate;
      $scope.timeZone = values.timeZone;
    });

    $scope.$on('changes', (__event, changes) => {
      if (changes.box && changes.box.currentValue) {
        $scope.box = changes.box.currentValue;
      }

      if (changes.dueDate && changes.dueDate.currentValue) {
        $scope.dueDate = changes.dueDate.currentValue;
      }

      if (changes.timeZone && changes.timeZone.currentValue) {
        $scope.timeZone = changes.timeZone.currentValue;
      }
    });

    $scope.saveDueDate = function (dueDate) {
      $scope.$emit('save', dueDate);
      box.visible = false;
    };

    $scope.removeDueDate = function () {
      $scope.$emit('remove');
      box.visible = false;
    };
  })
  .component('psSnoozer', {
    transclude: true,
    bindings: {
      dueDate: '<',
      timeZone: '<',
      hash: '<',
      onSave: '&',
      onRemove: '&',
    },
    templateUrl,
    controller($scope) {
      const ctrl = this;

      // This is used to control the box visibility
      ctrl.box = { visible: false };

      ctrl.title = 'Snooze until...';

      // This component communicates with the controller in the pop box via events in the scope

      ctrl.init = function () {
        $scope.$broadcast('init', {
          box: ctrl.box,
          dueDate: ctrl.dueDate,
          timeZone: ctrl.timeZone,
        });
      };

      ctrl.$onChanges = function (changes) {
        $scope.$broadcast('changes', changes);
      };

      ctrl.show = function () {
        ctrl.box.visible = true;
      };

      ctrl.hide = function () {
        ctrl.box.visible = false;
      };

      $scope.$on('save', (event, dueDate) => {
        // Don't let the event escape the component
        event.stopPropagation();
        ctrl.hide();

        ctrl.onSave({ dueDate });
      });

      $scope.$on('remove', event => {
        // Don't let the event escape the component
        event.stopPropagation();
        ctrl.hide();

        ctrl.onRemove({});
      });
    },
  });
