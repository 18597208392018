import {
  Plan,
  PlanFeatureSet,
  PlanInterval,
  PlanLevel,
  PlanTrack,
  SubscriptionState,
} from '@process-street/subgrade/billing';
import { Muid } from '@process-street/subgrade/core';
import { PlanLevelSelectorState } from 'directives/billing/plan-level-selector/plan-level-selector-service.interface';
import {
  DurationComponent,
  EnterprisePlanInfo,
  FreePlanInfo,
  PlanDescriptionComponent,
  ProPlanInfo,
} from 'pages/organizations/manage/billing/models/plan-info';

export type PlanInfo = {
  features: string[];
  name: string;
  duration: DurationComponent;
  description: PlanDescriptionComponent;
  featuresHeader: string;
  planDetails?: DetailSection[];
};

export type DetailSection = {
  header: string;
  items: string[];
};

export type LevelToPlanInfo = {
  [level in PlanLevel]?: PlanInfo;
};

export const LevelToPlanInfoMap: LevelToPlanInfo = {
  [PlanLevel.Free]: FreePlanInfo,
  [PlanLevel.Professional]: ProPlanInfo,
  [PlanLevel.Enterprise]: EnterprisePlanInfo,
};

export type PlanMap = {
  [level in PlanLevel]?: {
    [interval in PlanInterval]?: Plan;
  };
};

export type Descriptor = {
  [track in PlanTrack]?: LevelToIntervalToMuid;
};

export type IntervalToMuid = {
  [interval in PlanInterval]?: Muid;
};

export type LevelToIntervalToMuid = {
  [level in PlanLevel]?: IntervalToMuid;
};

export type FeatureSetMap = {
  [level in PlanLevel]?: {
    [interval in PlanInterval]?: PlanFeatureSet;
  };
};

export type CardInfo = {
  name: string;
  last4: string;
  expMonth: number;
  expYear: number;
  addressZip?: string;
  brand: string;
};

export type PlanCost = {
  rawCost: number;
  rawTotalCost: number;
  cost: number;
  totalCost: number;
};

export type PlanCostInfo = {
  perMember: string;
  total: string;
  totalNew: string;
  planCost: PlanCost;
};

export type IntervalToPlanCostInfoMap = {
  [interval in PlanInterval]?: PlanCostInfo;
};

export type BillingDetails = {
  name?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressState?: string;
  addressCountry?: string;
  addressZip?: string;
};

export type LimitInfo = {
  usage: number;
  limit?: number;
  limitIsReached: boolean;
};

export type PlanSubscriptionLimits = {
  activeChecklists: LimitInfo;
  activeTemplates: LimitInfo;
  checklistsRuns: LimitInfo;
  fullMembers: LimitInfo;
};

export type PlanSubscriptionDetails = {
  subscriptionState: SubscriptionState;
  limits: PlanSubscriptionLimits;
};

export type PlanStates = {
  [level in PlanLevel]?: PlanLevelSelectorState;
};

export type PlanActionType = 'contact' | 'selectPlan' | 'renewSubscription';

export type PlanAction = {
  actionText: string;
  action: PlanActionType;
};

export type PlanCostQuantity = {
  quantity: number;
  minQuantity: number;
};

export const PlanActions: { [index: string]: PlanAction } = {
  changePlan: {
    action: 'selectPlan',
    actionText: 'Change Plan',
  },
  selectPlan: {
    action: 'selectPlan',
    actionText: 'Select Plan',
  },
  renewSubscription: {
    action: 'renewSubscription',
    actionText: 'Renew Subscription',
  },
  upgradePlan: {
    action: 'selectPlan',
    actionText: 'Upgrade Plan',
  },
  contactUs: {
    action: 'contact',
    actionText: 'Contact Us',
  },
};
