import { Muid } from '@process-street/subgrade/core';
import { TemplateRevision } from '@process-street/subgrade/process';
import { axiosService } from 'services/axios-service';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export type CreateTemplateRevisionParams = {
  templateId: Muid;
  baseTemplateRevisionId?: Muid;
};

export type CreateTemplateRevisionResult = TemplateRevision;

export const CreateTemplateRevisionMutation = {
  key: ['create', 'template-revision'],
  mutationFn: (params: CreateTemplateRevisionParams) =>
    axiosService
      .getAxios()
      .post<CreateTemplateRevisionResult>('/1/template-revisions', params)
      .then(res => res.data),
};

export const useCreateTemplateRevisionMutation = (
  options: UseMutationOptions<CreateTemplateRevisionResult, AxiosError, CreateTemplateRevisionParams> = {},
) => {
  return useRQMutation(CreateTemplateRevisionMutation.mutationFn, {
    ...options,
    mutationKey: CreateTemplateRevisionMutation.key,
  });
};
