import * as React from 'react';
import { Button, ButtonProps } from 'components/design/next';

const buttonStyles = {
  bgColor: 'transparent',
  textDecor: 'none',
  borderWidth: 'thin',
  borderStyle: 'solid',
  borderColor: 'gray.600',
};

export const MiddleGroupButton = ({ isActive = false, ...props }: ButtonProps) => {
  return (
    <Button
      variant="link"
      color="gray.600"
      fontSize="sm"
      textTransform="uppercase"
      borderWidth={buttonStyles.borderWidth}
      borderStyle={buttonStyles.borderStyle}
      borderColor={isActive ? 'gray.600' : 'transparent'}
      borderRadius="15px"
      px={3}
      py={1}
      _active={buttonStyles}
      _focus={buttonStyles}
      _hover={buttonStyles}
      {...props}
    >
      {props.children}
    </Button>
  );
};
