import { StateService } from '@uirouter/core';
import { SessionService } from 'services/session-service.interface';

export class NewDashboardController {
  public static $inject = ['$state', 'SessionService'];

  constructor(private $state: StateService, private sessionService: SessionService) {
    const user = this.sessionService.getUser();
    if (!user) {
      this.$state.go('dashboard');
    }
  }
}
