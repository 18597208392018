import { UserOption } from 'utils/user-option-transformer';
import { Box, HStack, Text } from 'components/design/next';
import { Avatar, AvatarSize } from 'components/common/Avatar';
import React from 'react';
import { MultiValueGenericProps } from 'react-select';

export type UserSelectedItemProps = MultiValueGenericProps<UserOption, true> & { data: UserOption };

export const UserSelectedItem: React.FC<React.PropsWithChildren<UserSelectedItemProps>> = ({ data: { user } }) => (
  <HStack paddingX={2} paddingY={1}>
    <Box w={6} mr={0} display="contents">
      <Avatar user={user} size={AvatarSize.Small} showUnconfirmedBadge={true} />
    </Box>

    <Box flex={1} role="cell" maxW={78}>
      <Text variant="-1" fontWeight="medium" color="gray.600" noOfLines={1}>
        {user.username}
      </Text>
    </Box>
  </HStack>
);
