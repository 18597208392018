import { SandboxRole } from '@process-street/subgrade/core';
import { RoleOption } from './role-option-transformer';
import { UserOption } from 'app/utils/user-option-transformer';

export type PreviewerOption = UserOption | UserTypeOption | RoleOption;

export interface UserTypeOption {
  category: UserTypeOptionCategory;
  sandboxRole?: SandboxRole;
  label: string;
  tooltipText?: string;
  helpUrl?: string;
}

export enum SandboxUserType {
  User = 'user',
  UserType = 'userType',
  Role = 'role',
}

export enum UserTypeOptionCategory {
  WorkflowRunner = 'Workflow runner',
  Guest = 'Guest (External)',
  ShareLink = 'Someone with the share link',
}

export const isUserOption = (option: PreviewerOption): option is UserOption => {
  if (!option) return false;
  return (option as UserOption).user !== undefined;
};

export function isUserTypeOption(option: PreviewerOption): option is UserTypeOption {
  return (option as UserTypeOption).category !== undefined;
}

export function isRoleOption(option: PreviewerOption): option is RoleOption {
  return (option as RoleOption).name !== undefined;
}

export function isUserOption1(option: PreviewerOption): option is UserOption {
  return (option as UserOption).label !== undefined;
}

export const getPreviewerType = (option: PreviewerOption) => {
  if (!option) return null;
  if (isUserTypeOption(option)) {
    return SandboxUserType.UserType;
  } else if (isRoleOption(option)) {
    return SandboxUserType.Role;
  } else if (isUserOption(option)) {
    return SandboxUserType.User;
  }
  return null;
};

export const UserTypeOptions: UserTypeOption[] = [
  {
    category: UserTypeOptionCategory.WorkflowRunner,
    label: 'Workflow runner',
    tooltipText: 'Workflow runner variable automatically assign tasks to the user who manually runs the Workflow.',
    helpUrl: 'https://www.process.st/help/docs/role-assignments/#workflow-runner',
  },
  {
    category: UserTypeOptionCategory.Guest,
    label: 'Guest (External)',
    sandboxRole: SandboxRole.Guest,
  },
  {
    category: UserTypeOptionCategory.ShareLink,
    label: 'Someone with the share link',
    tooltipText:
      "When this option is enabled, Workflow Runs can be shared with anyone, whether they're inside or outside the organization.",
    helpUrl: 'https://www.process.st/help/docs/sharing-workflow-runs/',
    sandboxRole: SandboxRole.Anonymous,
  },
];

export const BLVD_CONTAINER_STYLES = {
  '.blvd-select .blvd-select__control': {
    'border': '0',
    'background': 'none',
    '&--is-focused': {
      boxShadow: 'unset',
      bgColor: 'gray.100',
    },
    '&:hover': {
      bgColor: 'gray.100',
    },
  },
  '.blvd-select__menu': {
    zIndex: 'modal',
    minWidth: '329px',
  },
  '.blvd-select__menu__separator': {
    my: '2',
  },
};
export const BLVD_CONTROL_STYLES = {
  menuPortal: (base: any) => ({
    ...base,
    'minWidth': '329px',
    '.blvd-select__menu__header': { display: 'flex', padding: '8px 16px' },
    '.blvd-select__menu__header input': { paddingLeft: '32px' },
    '.blvd-select__menu__header__search-icon': {
      position: 'absolute',
      left: '24px',
      top: '20px',
      color: 'var(--ps-colors-gray-400)',
    },
    '.blvd-select__menu__separator': { display: 'none' },
    '.blvd-select__option': { cursor: 'pointer' },
  }),
};
