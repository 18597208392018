import { useCallback, useEffect, useState } from 'react';

export type UseLazyMenuOptionsParams<T = unknown> = {
  getValue: () => T;
  defaultValue: T;
};

export const useLazyMenuOptions = <T = unknown>({ getValue, defaultValue }: UseLazyMenuOptionsParams<T>) => {
  const [shouldFullyLoad, setShouldFullyLoad] = useState<boolean>(false);
  const [hasFullyLoaded, setHasFullyLoaded] = useState<boolean>(false);
  const [value, setValue] = useState<T>(defaultValue);

  const handleMenuOpen = useCallback(() => {
    setShouldFullyLoad(true);
  }, []);

  useEffect(() => {
    if (shouldFullyLoad && !hasFullyLoaded) {
      setValue(getValue());
      setHasFullyLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore getValue prop
  }, [shouldFullyLoad, hasFullyLoaded]);

  return { value, setValue, hasFullyLoaded, handleMenuOpen };
};
