import * as React from 'react';
import {
  forwardRef,
  Input,
  HStack,
  InputGroup,
  Tag,
  TagLabel,
  TagCloseButton,
  InputGroupProps,
  VisuallyHiddenInput,
} from '@chakra-ui/react';
import { KeyStrings } from 'services/key';
import { StringUtils } from '@process-street/subgrade/util';
import { useFunctionRef } from 'hooks/use-function-ref';

export interface TaggedInputProps extends Omit<InputGroupProps, 'onChange'> {
  'value': string;
  'separator'?: string;
  'onChange': (value: string) => void;
  'placeholder'?: string;
  'isInvalid'?: boolean;
  'aria-label': string;
  'isTagValid'?: (value: string) => boolean;
}

const trimLastChar = (value: string) => value.slice(0, -1);

export const TaggedInput = forwardRef<TaggedInputProps, 'input'>(
  (
    { separator = ',', isInvalid, value = '', onChange, placeholder, 'aria-label': ariaLabel, isTagValid, ...props },
    ref,
  ) => {
    const repeatingSeparatorPattern = React.useMemo(() => new RegExp(`${separator}{2,}`, 'gi'), [separator]);
    const orphanSeparatorPattern = React.useMemo(() => new RegExp(`^${separator}$`, 'gi'), [separator]);

    const onChangeRef = useFunctionRef(onChange);

    const { head, tail } = StringUtils.getReversedHeadAndTail({ value, separator });

    const handleUpdate = (newTail: string) => {
      const sanitized = newTail.replace(repeatingSeparatorPattern, separator).replace(orphanSeparatorPattern, '');

      // deduplicate when adding new tags
      if (sanitized.endsWith(separator)) {
        if (isTagValid ? isTagValid(trimLastChar(sanitized)) : true) {
          const withoutSeparator = trimLastChar(sanitized);
          const combined = [...new Set([...tail, withoutSeparator])].join(separator);
          onChangeRef.current(`${combined}${separator}`);
          return;
        }
        return;
      }

      const combined = [...tail, sanitized].join(separator);
      onChangeRef.current(combined);
    };

    return (
      <InputGroup
        {...{
          className: 'chakra-tagged-input',
          borderWidth: 'px',
          borderStyle: 'solid',
          px: '4',
          borderRadius: 'base',
          h: '10',
          overflowX: 'auto',
          ...(isInvalid
            ? {
                borderColor: 'red.200',
                _hover: {
                  borderColor: 'red.300',
                },
                _focusWithin: {
                  borderColor: 'red.500',
                  boxShadow: '0 0 0 1px var(--ps-colors-red-500)',
                },
              }
            : {
                borderColor: 'gray.200',
                _hover: { borderColor: 'gray.300' },
                _focusWithin: { borderColor: 'brand.500', boxShadow: '0 0 0 1px var(--ps-colors-brand-500)' },
              }),
          ...props,
        }}
      >
        <HStack aria-hidden="true" mr={tail.size ? 2 : 0} aria-label={`${ariaLabel} tags`}>
          {[...tail].map(t => (
            <Tag key={t} aria-label={t}>
              <TagLabel>{t}</TagLabel>
              <TagCloseButton
                onClick={() => {
                  const newTags = new Set(tail);
                  newTags.delete(t);
                  const combined = [...newTags, head].join(separator);
                  onChange(combined);
                }}
              />
            </Tag>
          ))}
        </HStack>

        <Input
          aria-hidden="true"
          ref={ref}
          minW="fit-content"
          pr="8"
          value={head}
          variant="unstyled"
          px="0"
          onKeyDown={e => {
            if (e.key === KeyStrings.BACKSPACE && !head) {
              e.preventDefault();
              onChange([...tail].join(separator));
              return;
            }
          }}
          onPaste={e => {
            e.preventDefault();
            const value = e.clipboardData.getData('Text');
            handleUpdate(value);
          }}
          onChange={e => handleUpdate(e.target.value)}
          placeholder={placeholder}
        />

        <VisuallyHiddenInput defaultValue={value} tabIndex={-1} />
      </InputGroup>
    );
  },
);
