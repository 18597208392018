import React from 'react';

export type OverflowingValuesIndicatorProps = {
  visible: boolean;
  count: number;
};

export const OverflowingValuesIndicator = (props: OverflowingValuesIndicatorProps) => {
  const { count, visible } = props;

  const overflowIndicatorStyle = {
    opacity: visible ? '1' : '0',
  };

  return (
    <div
      key={'overflow-indicated'}
      className={'blvd-select__overflowing-values-indicator'}
      style={overflowIndicatorStyle}
    >
      +{count}
    </div>
  );
};
