import * as React from 'react';
import {
  Button,
  ButtonGroup,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from 'components/design/next';
import { Muid } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { CreateFormSharesMutation, GetFormSharesQuery } from 'features/forms/query-builder';
import { Divider } from '@chakra-ui/react';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useQueryClient } from 'react-query';
import { concatInDistinctArray } from '@process-street/subgrade/redux';
import { useUiDisclosure } from 'pages/forms/_id/shared';
import { UsersAndGroups } from './users-and-groups';
import { SharedList } from './shared-list';

export type SendLinkViaEmailModalProps = {
  template: Template;
};

export const SendLinkViaEmailModal: React.FC<React.PropsWithChildren<SendLinkViaEmailModalProps>> = ({ template }) => {
  const { isOpen, onClose } = useUiDisclosure('shareViaEmail');

  // state
  const [emails, setEmails] = React.useState('');
  const [selectedMembershipIds, setSelectedMembershipIds] = React.useState<Muid[]>([]);

  // queries
  const createFormSharesMutation = CreateFormSharesMutation.useMutation();
  const getFormSharesQuery = GetFormSharesQuery.useQuery({ templateId: template.id });

  // hooks
  const toast = useToast();
  const queryClient = useQueryClient();

  const formShares = getFormSharesQuery.data ?? [];
  const sharedEmails = new Set(
    formShares.map(fs => fs.formShare.email ?? fs.user.email).map(email => email.toLowerCase()),
  );

  const sharedMembershipIds = new Set(formShares.map(fs => fs.formShare.organizationMembershipId));

  const getEmails = () => {
    const filteredEmails = emails
      .split(/[;,]/)
      .map(s => s.trim().toLowerCase())
      .filter(s => s.length > 0 && !sharedEmails.has(s));

    return [...new Set(filteredEmails)]; // distinct
  };

  const isSaveEnabled = selectedMembershipIds.length > 0 || getEmails().length > 0;

  const handleSave = () => {
    const organizationMembershipIds = selectedMembershipIds.filter(omId => !sharedMembershipIds.has(omId));
    createFormSharesMutation.mutateAsync(
      {
        templateId: template.id,
        organizationMembershipIds,
        emails: getEmails(),
      },
      {
        onSuccess: async () => {
          toast({
            status: 'success',
            title: 'Form has been shared',
          });
          setSelectedMembershipIds([]);
          setEmails('');
          await queryClient.invalidateQueries(GetFormSharesQuery.key);
          onClose();
        },
        onError: () => {
          toast({
            status: 'error',
            title: "We're having problems sharing the form",
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
      },
    );
  };

  const handleUserSelection = (checked: boolean, omIds: Muid[]) => {
    if (checked) {
      setSelectedMembershipIds(concatInDistinctArray(selectedMembershipIds, omIds));
    } else {
      setSelectedMembershipIds(selectedMembershipIds.filter(id => !omIds.includes(id)));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent pt="0">
        <ModalHeader>
          <HStack spacing="2">
            <Icon icon="envelope" size="4" color="gray.600" />

            <Text size="xl" fontWeight="700" color="gray.700">
              Send link
            </Text>

            <ModalCloseButton />
          </HStack>
        </ModalHeader>

        <ModalBody py="3">
          <VStack alignItems="start">
            <Text fontWeight="medium" fontSize="sm">
              Email addresses
            </Text>
            <Input
              fontSize="sm"
              placeholder="Enter email address"
              aria-label="Email addresses"
              value={emails}
              onChange={evt => setEmails(evt.target.value)}
            />
            <Divider marginY="16px!important" />

            <SharedList formShares={formShares} />

            <Text variant="-2u" fontWeight="bold" color="gray.400">
              Users and groups
            </Text>

            {getFormSharesQuery.data && (
              <UsersAndGroups onUserSelect={handleUserSelection} template={template} formShares={formShares} />
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose} variant="ghost" colorScheme="gray" fontWeight="normal">
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              variant="primary"
              isLoading={createFormSharesMutation.isLoading}
              isDisabled={!isSaveEnabled}
            >
              Send
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
