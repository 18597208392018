import { useEffect, useState } from 'react';
import { MSTeamsUtils } from 'features/microsoft-teams/utils';

export function useMicrosoftTeams(): {
  microsoftTeams: typeof microsoftTeams | undefined;
  microsoftTeamsContext?: microsoftTeams.Context;
  isReady: boolean;
  isEmbeddedInMsTeams: boolean;
} {
  const [teamsSdk, setTeamsSdk] = useState<typeof microsoftTeams>();
  const [isEmbeddedInMsTeams, setIsEmbeddedInMsTeams] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [microsoftTeamsContext, setMicrosoftTeamsContext] = useState<microsoftTeams.Context>();

  useEffect(() => {
    import('@microsoft/teams-js').then(result => setTeamsSdk(result));
  }, []);

  useEffect(() => {
    if (!teamsSdk) {
      return;
    }
    teamsSdk.initialize(() => {
      teamsSdk.getContext(context => {
        setMicrosoftTeamsContext(context);
        setIsReady(true);
      });
    });

    setIsEmbeddedInMsTeams(MSTeamsUtils.isEmbeddedInMsTeams());
  }, [teamsSdk]);

  return { microsoftTeams: teamsSdk, microsoftTeamsContext, isReady, isEmbeddedInMsTeams };
}
