import angular from 'angular';
import { connectService } from 'reducers/util';

angular
  .module('frontStreetApp.services')
  .service('TemplateRevisionService', function ($ngRedux, TemplateRevisionActions) {
    connectService('TemplateRevisionService', $ngRedux, null, TemplateRevisionActions)(this);

    const self = this;

    self.getById = id => this.actions.getById(id).then(({ payload: templateRevision }) => templateRevision);

    self.getAllDraftsByFolderIds = folderIds =>
      this.actions.getAllDraftsByFolderIds(folderIds).then(({ payload: templateRevisions }) => templateRevisions);

    /**
     * Create a template revision.
     *
     * @param templateId
     * @param baseTemplateRevisionId The revision to base it on (optional, defaults to latest version)
     * @returns {*}
     */
    self.create = (templateId, baseTemplateRevisionId) => {
      return this.actions
        .create(templateId, baseTemplateRevisionId)
        .then(({ payload: templateRevision }) => templateRevision);
    };

    /**
     * Delete the template revision by id.
     *
     * @param templateRevisionId
     * @returns {*}
     */
    self.deleteById = templateRevisionId => this.actions.deleteById(templateRevisionId).then(({ payload }) => payload);

    /**
     * Finish the template revision by id.
     *
     * @param templateRevisionId
     * @returns {*}
     */
    self.finishById = templateRevisionId => this.actions.finishById(templateRevisionId).then(({ payload }) => payload);

    /**
     * Gets the newest revisions by template id.
     * The first one is the Finished one, the second one is the Draft one, if it exists
     *
     * @param templateId
     * @param flushCache
     *
     * @return {Promise<[Object, Object]>}
     */
    self.getAllNewestByTemplateId = (templateId, flushCache) =>
      this.actions
        .getAllNewestByTemplateId(templateId, flushCache)
        .then(({ payload: templateRevisions }) => templateRevisions);
  });
