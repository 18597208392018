import * as React from 'react';
import { Box, Modal, ModalCloseButton, ModalContent, ModalOverlay } from 'components/design/next';
import { ContactSalesService, UpdateContactSalesVariablesProps } from 'services/interop/contact-sales-service';
import { match, P } from 'ts-pattern';
import { Organization, UserInfo } from '@process-street/subgrade/core';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { GetEmployeeCountQuery } from 'features/create-organization/query-builder';
import { useMount } from 'react-use';

export interface ContactSalesModalProps {
  onClose: () => void;
  selectedOrganization: Organization;
  utm: CalendlyUtmCampaign;
}

export type CalendlyUtmCampaign = 'app_topbar' | 'app_billing_tab' | 'app_trial_block_paywall';

type CalendlyEvent =
  | { event: 'calendly.event_type_viewed'; payload: {} }
  | { event: 'calendly.date_and_time_selected'; payload: {} }
  | { event: 'calendly.event_scheduled'; payload: {} };

const now = () => Math.floor(new Date().getTime() / 1000);

const CS_CALENDLY_URL =
  'https://calendly.com/process-street-cs/process-street-demo?hide_event_type_details=1&hide_gdpr_banner=1';
const AE1_CALENDLY_URL =
  'https://calendly.com/ps-consult/process-street-consult-12?hide_event_type_details=1&hide_gdpr_banner=1';
const AE2_CALENDLY_URL =
  'https://calendly.com/ps-consult/process-street-consult?hide_event_type_details=1&hide_gdpr_banner=1';

const getPrefillData = (userInfo: UserInfo | undefined, selectedOrganizationName: string = '') =>
  match(userInfo?.user)
    .with(P.not(P.nullish), user => {
      const [firstName, ...lastNames] = user.username.split(' ');
      return {
        name: user.username,
        firstName,
        lastName: lastNames.join(' '),
        email: user.email,
        customAnswers: { a1: selectedOrganizationName },
      };
    })
    .otherwise(() => ({}));

export const ContactSalesModal: React.FC<React.PropsWithChildren<ContactSalesModalProps>> = ({
  onClose,
  utm,
  selectedOrganization,
}) => {
  const { data: userInfo } = useGetCurrentUserInfoQuery();
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const trackContactSales = (properties: UpdateContactSalesVariablesProps) => {
    ContactSalesService.updateIntercomVariables(properties);
  };

  const employeeCountQuery = GetEmployeeCountQuery.useQuery(
    { organizationId: selectedOrganization.id },
    {
      select: data => data.employeeCount,
    },
  );

  const calendlyWidgetUrl = React.useMemo(() => {
    if (!employeeCountQuery.isLoading) {
      return match(employeeCountQuery.data)
        .with(P.nullish, () => CS_CALENDLY_URL)
        .with('1-29', () => CS_CALENDLY_URL)
        .with('30-99', () => AE1_CALENDLY_URL)
        .otherwise(() => AE2_CALENDLY_URL);
    }

    return CS_CALENDLY_URL;
  }, [employeeCountQuery.isLoading, employeeCountQuery.data]);

  const prefill = React.useMemo(() => {
    if (!employeeCountQuery.isLoading) {
      return match(employeeCountQuery.data)
        .with(P.nullish, () => getPrefillData(userInfo, selectedOrganization.name))
        .with('1-29', () => getPrefillData(userInfo, selectedOrganization.name))
        .otherwise(() => getPrefillData(userInfo));
    }

    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps -- employee count query
  }, [employeeCountQuery.isLoading, employeeCountQuery.data]);

  const isOpenModal = !employeeCountQuery.isLoading;

  useMount(() => {
    trackContactSales({ company: { id: selectedOrganization.id, contact_sales_click_at: now() } });

    const handleMessage = (e: MessageEvent<CalendlyEvent>) => {
      if (e.data.event === 'calendly.event_type_viewed') {
        trackContactSales({ company: { id: selectedOrganization.id, contact_sales_event_type_viewed_at: now() } });
      }

      if (e.data.event === 'calendly.date_and_time_selected') {
        trackContactSales({ company: { id: selectedOrganization.id, contact_sales_date_and_time_selected_at: now() } });
      }

      if (e.data.event === 'calendly.event_scheduled') {
        trackContactSales({ company: { id: selectedOrganization.id, contact_sales_event_scheduled_at: now() } });
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  });

  React.useEffect(() => {
    if (isOpenModal) {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.onload = initWidget;

      document.head.appendChild(script);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- modal open
  }, [isOpenModal]);

  const initWidget = () => {
    // @ts-expect-error -- TODO
    window.Calendly.initInlineWidget({
      url: calendlyWidgetUrl,
      parentElement: containerRef.current,
      utm: { utmMedium: 'calendly', utmSource: 'app', utmCampaign: utm },
      prefill,
    });
  };

  return (
    <Modal onClose={onClose} isOpen={isOpenModal} isCentered blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent
        minH={{ base: '600px', md: '900px' }}
        minW={{ base: '500px', md: '800px', lg: '1000px' }}
        shadow={0}
        borderRadius={5}
        bgColor="whiteAlpha.100"
        backdropFilter="auto"
        backdropBlur="2px"
      >
        <Box
          display={'block'}
          position="absolute"
          minH={{ base: '600px', md: '900px' }}
          minW={{ base: '500px', md: '800px', lg: '1000px' }}
          height="0"
          borderRadius={10}
          border="none"
          ref={containerRef}
        />
        <ModalCloseButton
          bgColor="blackAlpha.400"
          p="20px"
          position="fixed"
          top="20px"
          right="40px"
          fontSize="18px"
          color="gray.200"
        />
      </ModalContent>
    </Modal>
  );
};
