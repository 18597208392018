import { PromiseQueueConstants } from './promise-queue-constants';

export namespace PromiseQueueDescGenerator {
  export function generateUpdateStatusByChecklistId(id: string, status: string): string {
    return generateByChecklistIdAndAction(id, PromiseQueueConstants.ChecklistAction.UPDATE_STATUS, status);
  }

  export function generateByChecklistIdAndAction(id: string, action: string, value: string): string {
    return `checklist (id: ${id}) ${action} to ${value}`;
  }

  export function generateUpdateStatusByTaskId(id: string, status: string): string {
    return generateByTaskIdAndAction(id, PromiseQueueConstants.TaskAction.UPDATE_STATUS, status);
  }

  export function generateForMergeTagsParsingByWidgetId(id: string): string {
    return `merge tags parsing for widget (id: ${id})`;
  }

  export function generateByTaskIdAndAction(id: string, action: string, value: string): string {
    return `task (id: ${id}) ${action} to ${value}`;
  }
}
