import { Muid } from '@process-street/subgrade/core';
import {
  CREATE_TEMPLATE_PERMIT,
  CreateOrUpdateTemplatePermitActionType,
  DELETE_TEMPLATE_PERMIT,
  DeleteTemplatePermitActionType,
  GetAllTemplatePermitsActionType,
  GetPaginatedTemplatePermitsActionType,
  TEMPLATE_PERMITS_GET_ALL_BY_TEMPLATE_ID,
  TEMPLATE_PERMITS_GET_EXISTING_AND_NEW_BY_TEMPLATE_ID,
} from 'components/template/membership/store/template-membership.actions';
import { getStatusReducer } from 'reducers/statuses/statuses-utils';
import { PaginatedTemplatePermits, PaginatedTemplatePermitsMap, TemplatePermitsStatuses } from 'reducers/types';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { toSuccess } from 'reducers/util/handlers';
import { combineReducers } from 'redux';
import { TEMPLATE_PERMIT_GET_ALL_BY_ORGANIZATION_ID } from 'reducers/template-permit/template-permit.actions';
import groupBy from 'lodash/groupBy';

const templatePermitActionHandlers = {
  [toSuccess(TEMPLATE_PERMITS_GET_ALL_BY_TEMPLATE_ID)]: (
    state: PaginatedTemplatePermitsMap,
    { payload }: GetPaginatedTemplatePermitsActionType,
  ): PaginatedTemplatePermitsMap => {
    const permits = payload.response.data.map(permit => permit.id);
    return updateState(state, payload.templateId, payload.response.hasMore, permits, payload.response.totalCount || 0);
  },

  [toSuccess(TEMPLATE_PERMIT_GET_ALL_BY_ORGANIZATION_ID)]: (
    state: PaginatedTemplatePermitsMap,
    { payload }: GetAllTemplatePermitsActionType,
  ): PaginatedTemplatePermitsMap => {
    const grouped = groupBy(payload, permit => permit.template.id);

    const collector: PaginatedTemplatePermitsMap = {};
    Object.keys(grouped).forEach(templateId => {
      collector[templateId] = {
        hasMore: false,
        permits: grouped[templateId].map(p => p.id),
        totalCount: grouped[templateId].length,
      };
    });

    return {
      ...state,
      ...collector,
    };
  },

  [toSuccess(TEMPLATE_PERMITS_GET_EXISTING_AND_NEW_BY_TEMPLATE_ID)]: (
    state: PaginatedTemplatePermitsMap,
    { payload }: GetPaginatedTemplatePermitsActionType,
  ): PaginatedTemplatePermitsMap => {
    const permits = payload.response.data.map(permit => permit.id);
    return updateState(state, payload.templateId, payload.response.hasMore, permits, payload.response.totalCount || 0);
  },

  [toSuccess(CREATE_TEMPLATE_PERMIT)]: (
    state: PaginatedTemplatePermitsMap,
    { payload }: CreateOrUpdateTemplatePermitActionType,
  ): PaginatedTemplatePermitsMap => {
    const existingState: PaginatedTemplatePermits = state[payload.templateId];
    if (existingState) {
      const newPermits = [payload.permit.id, ...existingState.permits];
      return updateState(state, payload.templateId, existingState.hasMore, newPermits, existingState.totalCount + 1);
    } else {
      return updateState(state, payload.templateId, false /* hasMore */, [payload.permit.id], 1);
    }
  },

  [toSuccess(DELETE_TEMPLATE_PERMIT)]: (
    state: PaginatedTemplatePermitsMap,
    { payload }: DeleteTemplatePermitActionType,
  ): PaginatedTemplatePermitsMap => {
    const existingState: PaginatedTemplatePermits = state[payload.templateId];
    if (existingState) {
      const newPermits = existingState.permits.filter(permitId => permitId !== payload.permitId);
      const totalCount = existingState.totalCount - 1;
      return updateState(state, payload.templateId, existingState.hasMore, newPermits, totalCount);
    } else {
      return state;
    }
  },
};

function updateState(
  state: PaginatedTemplatePermitsMap,
  templateId: Muid,
  hasMore: boolean,
  permits: Muid[],
  totalCount: number,
): PaginatedTemplatePermitsMap {
  return {
    ...state,
    [templateId]: {
      hasMore,
      permits,
      totalCount,
    },
  };
}

export const templatePermitsLookupsReducer = combineReducers({
  byTemplateId: handleActionsOnSuccess(templatePermitActionHandlers, {}),
});

export const templatePermitsStatusesReducer = combineReducers<TemplatePermitsStatuses>({
  byTemplateId: getStatusReducer(TEMPLATE_PERMITS_GET_ALL_BY_TEMPLATE_ID, 'templateId'),
});
