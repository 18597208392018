import { Muid } from '@process-street/subgrade/core';
import { ChecklistRevision, ChecklistRevisionStatus, ChecklistStatus } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetChecklistRevisionsByTemplateIdParams = {
  checklistRevisionStatus?: ChecklistRevisionStatus;
  checklistStatus?: ChecklistStatus;
  templateId?: Muid;
};
export type GetChecklistRevisionsByTemplateIdResult = ChecklistRevision[];

export const GetChecklistRevisionsByTemplateId = {
  key: 'checklist-revisions',
  getKey: (params: GetChecklistRevisionsByTemplateIdParams) => [GetChecklistRevisionsByTemplateId.key, params],
  queryFn: ({
    templateId,
    checklistRevisionStatus = ChecklistRevisionStatus.Active,
    checklistStatus = ChecklistStatus.Active,
  }: GetChecklistRevisionsByTemplateIdParams) =>
    axiosService
      .getAxios()
      .get<GetChecklistRevisionsByTemplateIdResult>(`/1/checklist-revisions`, {
        params: {
          where: {
            'checklist.templateId': { _eq: templateId },
            'checklist.status': { _eq: checklistStatus },
            'status': { _eq: checklistRevisionStatus },
          },
          include: 'checklist',
        },
      })
      .then(res => res.data),
};

export const useGetChecklistRevisionsByTemplateIdQuery = (
  params: GetChecklistRevisionsByTemplateIdParams,
  options: UseQueryOptions<GetChecklistRevisionsByTemplateIdResult, AxiosError> = {},
) => {
  return useQuery<GetChecklistRevisionsByTemplateIdResult, AxiosError>(
    GetChecklistRevisionsByTemplateId.getKey(params),
    () => GetChecklistRevisionsByTemplateId.queryFn(params),
    { ...options, enabled: Boolean(params.templateId) && options.enabled !== false },
  );
};
