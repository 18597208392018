import { TemplateRevision } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateTemplateRevisionMutationParams = {
  id: TemplateRevision['id'];
  defaultChecklistName?: TemplateRevision['defaultChecklistName'];
};

export type UpdateTemplateRevisionMutationResponse = TemplateRevision;

export const UpdateTemplateRevisionMutation = {
  key: ['put', 'template-revisions'],
  mutationFn: (params: UpdateTemplateRevisionMutationParams) =>
    axiosService
      .getAxios()
      .put<UpdateTemplateRevisionMutationResponse>(`/1/template-revisions/${params.id}`, params)
      .then(res => res.data),
};

export const useUpdateTemplateRevisionRevisionMutation = (
  options: UseMutationOptions<
    UpdateTemplateRevisionMutationResponse,
    AxiosError,
    UpdateTemplateRevisionMutationParams
  > = {},
) => {
  return useMutation(UpdateTemplateRevisionMutation.mutationFn, options);
};
