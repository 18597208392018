import { Muid } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { IPromise } from 'angular';

export enum Method {
  COPY = 'Copy',
  BLANK = 'Blank',
  PREMADE = 'Premade',
  IMPORT = 'Import',
  AI_AUTOGENERATED = 'AiAutoGenerated',
}

export enum ShareLevel {
  NONE = 'None',
  VIEW = 'View',
  RUN = 'Run',
}

export enum CreateTemplateSource {
  EMPTY_FOLDER = 'Empty folder',
  IMPORT_TEMPLATE_PAGE = 'Import template page',
  LIBRARY_ROW = 'Library row',
  LIBRARY_SIDEBAR = 'Library sidebar',
  MAIN_NAV_BAR = 'Main navigation',
  REPORTS_SIDEBAR = 'Reports sidebar',
  TEMPLATE_GALLERY_MODAL = 'Template gallery modal',
  AI_STATIC_URL = 'Ai static url',
  DATA_SETS_SIDEBAR = 'Datasets sidebar',
}

export interface TemplateService {
  create: (
    premadeTemplate: Template,
    folderId: Muid,
    name: string,
    creationMethod: Method,
    source: CreateTemplateSource,
    organizationId?: Muid,
  ) => IPromise<Template>;

  createByPremadeId: (
    premadeTemplateId: Muid,
    folderId: Muid,
    name: string,
    creationMethod: Method,
    source: CreateTemplateSource,
    organizationId?: Muid,
  ) => IPromise<Template>;

  getPremade: (templateId: Muid) => IPromise<Template>;

  copy: (template: Template, folderId: Muid) => IPromise<Template>;

  get: (templateId: Muid) => IPromise<Template>;
}
