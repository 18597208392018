import { Folder } from '@process-street/subgrade/process';
import { useToast } from 'components/design/next';
import { GetAllFoldersByOrganizationId, useMoveFolderMutation } from 'features/folder/query-builder';
import { useQueryClient } from 'react-query';

type UseMoveFolderParams = {
  folder: Folder;
};

export const useMoveFolder = ({ folder }: UseMoveFolderParams) => {
  const organizationId = folder.organization.id;
  const queryClient = useQueryClient();
  const queryKey = GetAllFoldersByOrganizationId.getKey(organizationId);
  const toast = useToast();

  const moveFolderMutation = useMoveFolderMutation({
    onMutate: ({ candidateId, toFolderId }) => {
      const currentFolders = queryClient.getQueryData<Folder[] | undefined>(queryKey);

      // Apply optimistic updates
      queryClient.setQueryData(
        queryKey,
        (folders: Folder[] | undefined) =>
          folders?.map(folder => {
            if (folder.id === candidateId) {
              return {
                ...folder,
                parentFolder: {
                  id: toFolderId,
                },
              };
            }

            return folder;
          }) ?? [],
      );

      return currentFolders;
    },
    onError: (_, __, currentFolders) => {
      // Revert optimistic update
      queryClient.setQueryData(queryKey, () => currentFolders);
      toast({
        status: 'warning',
        title: 'You don’t have permission to move to this folder',
      });
    },
  });

  return moveFolderMutation;
};
