import * as React from 'react';
import { Icon, MenuItem, MenuItemProps } from 'components/design/next';
import { FieldType, FormFieldWidget, FormFieldWidgetOfType } from '@process-street/subgrade/process';
import { SettingsModalContent } from 'pages/forms/_id/edit/components/form-fields/common/settings/settings-modal-content';
import { useWidgetActorRef } from 'pages/forms/_id/shared/widget-context';
import { useUIActorRef } from 'pages/forms/_id/shared';
import { ObjectSchema } from 'yup';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';

export type SettingsMenuItemProps<Type extends FieldType> = React.PropsWithChildren<
  MenuItemProps & {
    widget: FormFieldWidgetOfType<Type>;
    schema: ObjectSchema<WidgetSettings<Type>>;
  }
>;

export const SettingsMenuItem = <Type extends FieldType>({
  widget,
  schema,
  children,
  ...props
}: SettingsMenuItemProps<Type>) => {
  const { widgetActorRef } = useWidgetActorRef<FormFieldWidget>();
  const { uiActorRef } = useUIActorRef();

  const modal = (
    <SettingsModalContent
      widget={widget}
      schema={schema}
      onUpdate={widget => widgetActorRef.send({ type: 'UPDATE_WIDGET', widget })}
      onClose={() => uiActorRef.send({ type: 'CLOSE_MODAL' })}
    >
      {children}
    </SettingsModalContent>
  );

  return (
    <MenuItem
      iconSpacing="2"
      aria-label="Widget settings"
      icon={<Icon icon="cog" size="4" />}
      onClick={() => uiActorRef.send({ type: 'OPEN_MODAL', modal })}
      {...props}
    >
      Settings
    </MenuItem>
  );
};
