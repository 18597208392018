import angular from 'angular';

angular.module('frontStreetApp.services').factory('TaskPermissionRules', function (ResourceFactory) {
  return ResourceFactory.create({
    deleteAllByIds: 'POST[] /1/template-revisions/:templateRevisionId/task-permission-rules/delete',
    deleteById: 'DELETE /1/template-revisions/:templateRevisionId/task-permission-rules/:ruleId',
    getAllByTemplateRevisionId: 'GET[] /1/template-revisions/:templateRevisionId/task-permission-rules',
    upsert: 'PUT /1/template-revisions/:templateRevisionId/task-permission-rules',
    upsertAll: 'PUT[] /1/template-revisions/:templateRevisionId/task-permission-rules/all',
  });
});
