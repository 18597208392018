import { createAction } from 'redux-actions';

export const SUCCESS_NOTICE_TYPE = 'success';
export const WARNING_NOTICE_TYPE = 'warning';
export const DANGER_NOTICE_TYPE = 'danger';

export const NOTICE_SHOW = 'flash/notice/SHOW';
export const NOTICE_CLOSE = 'flash/notice/CLOSE';

export const CRITICAL_NOTICE_SHOW = 'flash/critical/SHOW';
export const CRITICAL_NOTICE_CLOSE = 'flash/critical/CLOSE';

const showFlash = createAction(NOTICE_SHOW, notice => notice);

const showCriticalFlash = createAction(CRITICAL_NOTICE_SHOW, notice => notice);

const closeFlash = key => dispatch => {
  if (key === 'notice') {
    dispatch({ type: NOTICE_CLOSE });
  } else {
    dispatch({ type: CRITICAL_NOTICE_CLOSE });
  }
};

const showSuccessNotice = (message, duration) =>
  showFlash({
    type: SUCCESS_NOTICE_TYPE,
    message,
    duration,
  });

const showDangerNotice = (message, duration) =>
  showFlash({
    type: DANGER_NOTICE_TYPE,
    message,
    duration,
  });

const showWarningNotice = (message, duration) =>
  showFlash({
    type: WARNING_NOTICE_TYPE,
    message,
    duration,
  });

const showWarningNoticeWithButton = (message, button, duration) =>
  showFlash({
    type: WARNING_NOTICE_TYPE,
    message,
    duration,
    button,
  });

const showCriticalSuccessNotice = message =>
  showCriticalFlash({
    type: SUCCESS_NOTICE_TYPE,
    message,
  });

const showCriticalDangerNotice = message =>
  showCriticalFlash({
    type: DANGER_NOTICE_TYPE,
    message,
  });

export const FlashActions = {
  closeFlash,
  showCriticalDangerNotice,
  showCriticalSuccessNotice,
  showDangerNotice,
  showWarningNotice,
  showWarningNoticeWithButton,
  showSuccessNotice,
};
