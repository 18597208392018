import { handleActions } from 'redux-actions';
import { ScopeEventActions } from './scope-event.actions';

/**
 * Take all ScopeActions and specify the same action, which just replaces latest state with a taken action
 */
const scopeEventReducerObj = Object.keys(ScopeEventActions)
  /*eslint no-unused-vars:0*/
  .reduce((agg, event) => ({ ...agg, [ScopeEventActions[event]]: (state, action) => action }), {});

export const scopeEventReducer = handleActions(scopeEventReducerObj, {});
