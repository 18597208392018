import angular from 'angular';
import templateUrl from './statusbar.component.html';
import './statusbar.scss';

angular.module('frontStreetApp.directives').component('psStatusbar', {
  bindings: {
    user: '<',
    checklist: '<',
  },
  templateUrl,
  controller($q, $scope, $state, AuditFormatService, ChecklistService, FeatureFlagService, SecurityService) {
    const ctrl = this;
    ctrl.isPdfEmbellishmentEnabled = FeatureFlagService.getFeatureFlags().pdfEmbellishment;
    ctrl.$onChanges = function (values) {
      ctrl.checklist = values.checklist.currentValue;
      initializePermissions(ctrl.checklist);
      calculateFormattedAudit();
    };

    ctrl.$onInit = function () {
      ctrl.timeZone = ctrl.user.timeZone;

      $scope.$watch(
        () => ctrl.checklist && ctrl.checklist.completedDate,
        () => {
          calculateFormattedAudit();
        },
      );
      $scope.$watch(
        () => ctrl.checklist && ctrl.checklist.archivedDate,
        () => {
          calculateFormattedAudit();
        },
      );
    };

    function calculateFormattedAudit() {
      if (ctrl.shouldShowArchivedState()) {
        AuditFormatService.formatAudit(
          {
            by: ctrl.checklist.archivedBy,
            date: ctrl.checklist.archivedDate,
          },
          ctrl.timeZone,
        ).then(formattedAudit => {
          ctrl.formattedAudit = formattedAudit;
        });

        ctrl.formFieldChangeDate = AuditFormatService.formatAuditDate(ctrl.checklist.archivedDate, ctrl.timeZone);
      } else if (ctrl.shouldShowCompletedState()) {
        ctrl.formattedAudit = AuditFormatService.formatAudit(
          {
            by: ctrl.checklist.completedBy,
            date: ctrl.checklist.completedDate,
          },
          ctrl.timeZone,
        ).then(formattedAudit => {
          ctrl.formattedAudit = formattedAudit;
        });

        ctrl.formFieldChangeDate = AuditFormatService.formatAuditDate(ctrl.checklist.completedDate, ctrl.timeZone);
      } else {
        ctrl.formattedAudit = undefined;
      }
    }

    ctrl.shouldShowCompletedState = function () {
      return ChecklistService.isChecklistCompleted(ctrl.checklist);
    };

    ctrl.shouldShowArchivedState = function () {
      return ChecklistService.isChecklistArchived(ctrl.checklist);
    };

    // Permissions

    ctrl.permissionMap = {};

    function initializePermissions(checklist) {
      if (checklist) {
        $q.all({
          checklistExport: SecurityService.canExportChecklistByChecklist(checklist),
        }).then(permissionMap => {
          ctrl.permissionMap = permissionMap;
        });
      }
    }

    ctrl.isEmbeddedInMsTeams = function () {
      return $state.includes('microsoftTeams');
    };
  },
});
