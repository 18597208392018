import * as React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { SearchBoxProvided } from 'react-instantsearch-core';
import debounce from 'lodash/debounce';
import { Icon, Input, InputGroup, InputLeftElement, InputProps } from 'components/design/next';
import { useSearchStatus } from 'features/global-search/components/search-context';
import { downPressed, upPressed } from 'components/listbox';
import { useCrossLinkSearchContext } from '..';
import { useTemplateTypeContext } from 'utils/template/template-type-context';
import { useMount } from 'react-use';

const ignoreUpDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
  if (upPressed(e) || downPressed(e)) {
    e.preventDefault();
  }
};

export const SearchDebounce = 100;
type SearchBoxUIProps = { inputProps?: InputProps };
export const SearchBoxUI: React.FC<React.PropsWithChildren<SearchBoxProvided & SearchBoxUIProps>> = ({
  refine,
  inputProps,
}) => {
  const { send, onFocus } = useCrossLinkSearchContext();
  const { isPage } = useTemplateTypeContext();

  const { select, query, setQuery } = useSearchStatus();

  // refine the results right away
  useMount(() => {
    if (query !== '') {
      processChange(query);
    }
  });

  const onChangeDebounced = React.useMemo(
    () =>
      debounce((filter: string) => {
        refine(filter);
      }, SearchDebounce),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore refine prop
    [],
  );

  const processChange = (value: string) => {
    const minCharsMet = value.length >= 3;
    const queryIsNewlyEmpty = value === '' && query !== '';

    setQuery(value);
    // we want to limit the calls to algolia
    if (minCharsMet || queryIsNewlyEmpty) {
      onChangeDebounced(value);
      return;
    }
  };

  const handleChange: React.FormEventHandler<HTMLInputElement> = e => {
    const { value } = e.currentTarget;
    processChange(value);
  };

  return (
    <form
      name="crosslink-link-search"
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        select();
      }}
    >
      <InputGroup>
        <InputLeftElement>
          <Icon size="3.5" color="gray.500" icon={isPage ? 'file-alt' : 'workflow'} />
        </InputLeftElement>
        <Input
          onFocus={() => {
            send('FOCUS');
            // @ts-expect-error This is never empty as its default value is `noop`
            onFocus();
          }}
          onBlur={() => send('BLUR')}
          bg="white"
          placeholder={`Search ${isPage ? 'pages' : 'workflows'}`}
          onKeyPress={ignoreUpDown}
          onKeyDown={ignoreUpDown}
          name="search"
          type="search"
          value={query}
          onChange={handleChange}
          autoFocus={true}
          variant="outline"
          borderLeft="none"
          borderTop="none"
          borderRight="none"
          {...inputProps}
        />
      </InputGroup>
    </form>
  );
};

export const SearchBox = connectSearchBox(SearchBoxUI);
