import * as React from 'react';
import { HStack, StackProps, Link, Icon } from 'components/design/next';
import { useInjector } from 'components/injection-provider';

type MSTeamsTopbarProps = StackProps;
export const MSTeamsTopbar = ({ ...props }: MSTeamsTopbarProps) => {
  const { $state } = useInjector('$state');

  return (
    <HStack
      bg="white"
      width="100%"
      fontWeight="medium"
      background="white"
      px={5}
      height="15"
      alignItems="center"
      justifyContent="flex-end"
      py="3"
      spacing="5"
      {...props}
    >
      <Link
        aria-label="log out"
        href={$state.href('logout')}
        variant="noline"
        _focus={{ color: 'inherit', bgColor: 'gray.100' }}
      >
        <Icon icon="sign-out" variant="far" size="4" color="gray.600" mr={3} /> Logout
      </Link>
    </HStack>
  );
};
