import angular from 'angular';

angular.module('frontStreetApp.services').factory('TemplateRevisions', function (ResourceFactory) {
  return ResourceFactory.create({
    query: 'GET[] /1/template-revisions',
    counts: 'GET /1/template-revisions/counts',
    create: 'POST /1/template-revisions',
    getById: 'GET /1/template-revisions/:id',
    getAllNewestByTemplateId: 'GET[] /1/templates/:id/template-revisions/newest',
    finishById: 'POST /1/template-revisions/:id/finish',
    deleteById: 'DELETE /1/template-revisions/:id',
  });
});
