import * as React from 'react';
import { Template } from '@process-street/subgrade/process';
import { HStack, Icon, Link } from 'components/design/next';
import { useInjector } from 'components/injection-provider';

export type ActivityButtonProps = {
  templateId: Template['id'];
};

export const ActivityButton: React.FC<React.PropsWithChildren<ActivityButtonProps>> = ({ templateId }) => {
  const { $state } = useInjector('$state');
  const href = $state.href('formManage.tab', { id: templateId, tab: 'activity' });

  return (
    <HStack color="gray.500">
      <Icon icon="clock-rotate-left" size="4" color="gray.400" />
      <Link href={href} target="_blank">
        Activity
      </Link>
    </HStack>
  );
};
