const STATUS = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
  DELETED: 'Deleted',
};

const BLANK_WORKFLOW_NAME = 'Blank Workflow';
const BLANK_FORM_NAME = 'My form';
const BLANK_PAGE_NAME = 'Untitled';

export const RECENTLY_CREATED_THRESHHOLD = 1000 * 30; // 30 seconds

export const TemplateConstants = {
  STATUS,
  BLANK_WORKFLOW_NAME,
  BLANK_FORM_NAME,
  BLANK_PAGE_NAME,
  RECENTLY_CREATED_THRESHHOLD,
};
