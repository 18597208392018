import { useToast } from '@chakra-ui/react';
import { useInjector } from 'app/components/injection-provider';
import { AuthInternalErrors, DefaultErrorMessages } from 'app/components/utils/error-messages';
import { useStateParam } from 'app/hooks/use-state-param';
import { useMount, usePromise } from 'react-use';

export const useChecklistViewAnonymousAuth = () => {
  const formResponseId = useStateParam({ key: 'id' })!;
  const { AnonymousAuthService } = useInjector('AnonymousAuthService');
  const mounted = usePromise();
  const toast = useToast();

  useMount(() => {
    mounted(AnonymousAuthService.checklistViewAnonymousAuth(formResponseId)).catch(error => {
      if (error.message === AuthInternalErrors.generalError) {
        toast({
          status: 'warning',
          title: 'Login to complete form',
          description: 'This form requires you to be logged in to complete it.',
        });
      } else {
        toast({
          status: 'error',
          title: "We're having problems loading the form",
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      }
    });
  });
};
