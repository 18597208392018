import { ApiKey } from '@process-street/subgrade/api-keys';
import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace CreateApiKeyMutation {
  export type Params = {
    id?: Muid;
    label: string;
  };

  export type Response = ApiKey;

  export const key = ['organization', 'api-keys'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/organizations/${params.id}/api-keys`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
