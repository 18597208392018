import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { SavedViewFolder } from 'components/dashboard/models/saved-views';
import { Nullable } from '@udecode/plate-core';

export namespace UpdateSavedViewFolderMutation {
  export type Params = {
    organizationId: Muid;
    id: Muid;
    name: string;
  };

  export type Response = Nullable<SavedViewFolder>;

  export const key = ['put', 'saved-view-folders'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/organizations/${params.organizationId}/checklist-grid/saved-view-folders/${params.id}`, {
        name: params.name,
      })
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
