import { Button, Icon, Text, PopoverTrigger } from 'components/design/next';
import * as React from 'react';
import { Option } from '@process-street/subgrade/core';
import { DueDateRuleDefinition } from '@process-street/subgrade/process';

export type ChecklistTriggerButtonProps = {
  disabled: boolean;
  rule: Option<DueDateRuleDefinition>;
  buttonTitle: string | null;
};

export const ChecklistTriggerButton: React.FC<React.PropsWithChildren<ChecklistTriggerButtonProps>> = ({
  disabled,
  rule,
  buttonTitle,
}) => (
  <PopoverTrigger>
    <Button
      display="flex"
      color="gray.500"
      justifyContent="flex-start"
      isDisabled={disabled}
      variant={disabled ? 'ghost' : 'outline'}
      size="md"
      borderWidth="px"
      borderColor="gray.300"
      px={'12px'}
      width="full"
      leftIcon={<Icon icon="clock" variant="far" size="4" color={rule && !disabled ? 'purple.500' : 'gray.600'} />}
      iconSpacing={3}
      aria-label="Due date"
    >
      <Text
        variant="1"
        color="gray.400"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        fontWeight="normal"
      >
        {buttonTitle}
      </Text>
    </Button>
  </PopoverTrigger>
);
