import angular from 'angular';
import { WidgetValidation } from '@process-street/subgrade/process';
import { DateFieldUtils, DateUtils } from '@process-street/subgrade/util';
import templateUrl from './checklist-date-field.component.html';
import { match } from 'ts-pattern';
import { DateContextUtils } from '@process-street/subgrade/core/date-context';
angular.module('frontStreetApp.directives').component('psChecklistDateFormField', {
  bindings: {
    user: '<',
    widget: '<',
    editable: '<',
    formFieldValue: '<',
    onUpdateValue: '&',
    onInteractionEnd: '&',
    readOnly: '<',
  },
  templateUrl,
  controller($q, SessionService) {
    const ctrl = this;
    this.$q = $q;
    ctrl.fieldValue = {};
    ctrl.errorMessage = '';
    ctrl.organization = SessionService.getSelectedOrganization();

    ctrl.saveDate = (date, timeHidden) => {
      updateDate(date, timeHidden);
    };

    ctrl.removeDate = function () {
      updateDate(null, true);
    };

    function updateDate(date, timeHidden) {
      ctrl.fieldValue.value = date;
      ctrl.fieldValue.timeHidden = timeHidden;

      ctrl.errorMessage = ctrl.getDateFieldWidgetErrorMessage(ctrl.widget, ctrl.fieldValue.value);
      return ctrl.onUpdateValue({
        widget: ctrl.widget,
        fieldValue: {
          value: ctrl.fieldValue.value,
          timeHidden: ctrl.fieldValue.timeHidden,
        },
      });
    }

    ctrl.onBlur = function () {
      return ctrl.onInteractionEnd({
        widget: ctrl.widget,
        fieldValue: {
          value: ctrl.fieldValue.value,
          timeHidden: ctrl.fieldValue.timeHidden,
        },
      });
    };

    ctrl.$onChanges = function (changes) {
      ctrl.dateContext =
        ctrl.organization && ctrl.user ? DateContextUtils.getDateContext(ctrl.organization, ctrl.user) : undefined;

      if (changes.widget?.currentValue) {
        const newErrorMessage = ctrl.getDateFieldWidgetErrorMessage(ctrl.widget, ctrl.fieldValue.value);

        if (ctrl.errorMessage && !newErrorMessage) {
          ctrl.saveDate(ctrl.fieldValue.value, ctrl.fieldValue.timeHidden);
        }

        ctrl.errorMessage = newErrorMessage;
      }

      if (
        changes.formFieldValue &&
        changes.formFieldValue.currentValue &&
        changes.formFieldValue.currentValue.fieldValue
      ) {
        ctrl.fieldValue = angular.copy(changes.formFieldValue.currentValue.fieldValue);

        ctrl.errorMessage = ctrl.getDateFieldWidgetErrorMessage(ctrl.widget, ctrl.fieldValue.value);
      }
    };

    ctrl.shouldShowAudit = () => Boolean(ctrl.formFieldValue?.audit);

    ctrl.formatInTimeZone = () =>
      DateFieldUtils.formatDateFieldValueToLongWeekdayShortMonth(ctrl.fieldValue, ctrl.dateContext);

    ctrl.formatInTimeZoneOrEmpty = () => (ctrl.fieldValue.value ? ctrl.formatInTimeZone() : '');

    ctrl.isReadOnly = function () {
      return !ctrl.fieldValue || !ctrl.editable;
    };

    ctrl.getDateFieldWidgetErrorMessage = (widget = {}, value) => {
      const { constraints } = widget;

      const errorType = WidgetValidation.getDateFormFieldWidgetErrorType(constraints, value);

      // TODO: when removing CustomDateValidationWithDynamicParameters use only calculated dates (and test it!)
      const afterDate = constraints?.calculatedAfterDate || constraints?.afterDate;
      const beforeDate = constraints?.calculatedBeforeDate || constraints?.beforeDate;

      return match(errorType)
        .with(
          'not-in-between',
          () =>
            `Select a date between ${ctrl.formatDateConstraint(afterDate)} and ${ctrl.formatDateConstraint(
              beforeDate,
            )}`,
        )
        .with('after-date', () => `Select a date after ${ctrl.formatDateConstraint(afterDate)}`)
        .with('before-date', () => `Select a date before ${ctrl.formatDateConstraint(beforeDate)}`)
        .otherwise(() => null);
    };

    ctrl.formatDateConstraint = value => {
      return DateUtils.formatDateToMonthDay(value, { showYearIfPast: true, timeZone: ctrl.user.timeZone });
    };
  },
});
