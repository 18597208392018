import angular from 'angular';

angular.module('frontStreetApp.services').factory('ChecklistAssignments', function (ResourceFactory) {
  return ResourceFactory.create({
    assignUser: 'PUT /1/checklists/:id/assignments/:email',
    getAllByChecklistId: 'GET[] /1/checklists/:checklistId/assignments',
    getAllByOrganizationId: 'GET[] /1/organizations/:organizationId/checklist-assignments',
    unassignUser: 'DELETE /1/checklists/:id/assignments/:email',
  });
});
