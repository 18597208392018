import * as React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { FormEditorTopBar, useSharedContext, UIActorProvider } from '../shared';
import * as yup from 'yup';
import { Template } from '@process-street/subgrade/process';
import { useStateParam } from 'hooks/use-state-param';
import { makeFormSettingsPageMachine, FormSettingsPageActorSelectors } from './settings-page-machine';
import { useMachine, useSelector } from '@xstate/react';

export type FormSettingsPageProps = {};

const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Required field'),
});

export const FormSettingsPage = () => {
  const templateId = useStateParam({ key: 'id' })!;
  const sharedContext = useSharedContext();
  const machine = React.useMemo(() => {
    return makeFormSettingsPageMachine({ templateId, sharedContext });
  }, [sharedContext, templateId]);
  const [state, send, actor] = useMachine(machine);
  const template = useSelector(actor, FormSettingsPageActorSelectors.getTemplate);

  const handleSubmit = (form: Pick<Template, 'name'>) => {
    send({ type: 'SUBMIT', form });
  };

  return (
    <UIActorProvider uiActorRef={state.context.uiActorRef}>
      <VStack spacing="0" alignItems="center">
        <FormEditorTopBar actor={state.context.topBarActorRef} />

        <Box pt="10" w="full" maxW="512px" px={{ base: '4', md: '0' }}>
          {state.matches('loading') ? (
            <Spinner />
          ) : template ? (
            <Formik validationSchema={validationSchema} onSubmit={handleSubmit} initialValues={{ name: template.name }}>
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <VStack alignItems="flex-start" w="full" spacing="8">
                    <Text fontSize="lg" fontWeight="bold" color="gray.500">
                      Settings
                    </Text>

                    <VStack alignItems="flex-start" w="full" spacing="4">
                      <Text variant="-2u">General</Text>
                      <FormControl isInvalid={Boolean(props.errors.name)}>
                        <FormLabel>Form Name</FormLabel>
                        <Input
                          name="name"
                          value={props.values.name}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          type="text"
                          isDisabled={state.matches('submitting')}
                        />
                        {props.errors.name && <FormErrorMessage>{props.errors.name}</FormErrorMessage>}
                      </FormControl>
                    </VStack>

                    <HStack
                      borderTopStyle="solid"
                      borderTopWidth="thin"
                      borderTopColor="gray.200"
                      spacing="4"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      w="full"
                      py="8"
                    >
                      <Button variant="ghost" type="reset" colorScheme="gray" isDisabled={state.matches('submitting')}>
                        Cancel
                      </Button>
                      <Button
                        isLoading={state.matches('submitting') || !props.isValid}
                        type="submit"
                        loadingText="Saving..."
                      >
                        Save
                      </Button>
                    </HStack>
                  </VStack>
                </Form>
              )}
            </Formik>
          ) : null}
        </Box>
      </VStack>
    </UIActorProvider>
  );
};
