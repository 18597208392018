import angular from 'angular';
import templateUrl from './pop-box.component.html';
import './pop-box.scss';
import { TaskListEvent } from 'directives/task-list/task-list-event';

export const PopBoxComponent = {
  bindings: {
    title: '<',
    disabled: '<',
    dialogClass: '@',
    onShow: '&',
    onHide: '&',
    onPopBoxCtrlInit: '&',
    // onFirstShow can be used to lazily initialize the body
    // See Inbox assignees filter (PS-7125) for more details
    onFirstShow: '&',
  },
  transclude: {
    trigger: 'popBoxTrigger',
    body: '?popBoxBody',
  },
  templateUrl,
  controller: class PopBoxComponent {
    PADDING = 5;

    shownFirstTime = false;

    deregisters = [];

    constructor($scope, $timeout, $window) {
      'ngInject';

      this.$scope = $scope;
      this.$timeout = $timeout;
      this.window = angular.element($window);
      this.document = angular.element($window.document);
    }

    $onInit() {
      this._registerEvents();
      this._initializeEvenListeners();
      if (this.onPopBoxCtrlInit) {
        this.onPopBoxCtrlInit({ popBox: this });
      }
    }

    $onDestroy() {
      this.deregisters.forEach(deregister => {
        deregister();
      });
    }

    _getBox = () => {
      if (!this.box) {
        this.box = angular.element('#pop-box-' + this.$scope.$id);
      }

      return this.box;
    };

    _getTrigger = () => {
      if (!this.trigger) {
        this.trigger = angular.element('#pop-box-trigger-' + this.$scope.$id);
      }

      return this.trigger;
    };

    _register(element, events, handler) {
      element.on(events, handler);

      return function () {
        element.off(events, handler);
      };
    }

    showBox() {
      if (this.disabled) {
        return;
      }

      const box = this._getBox();
      const trigger = this._getTrigger();

      // As per: https://stackoverflow.com/a/31604344/1293827
      angular.element('body').append(box);

      box.css('display', 'block');

      this._position(box, trigger);

      if (this.onShow) {
        this.onShow({});
      }

      if (!this.shownFirstTime) {
        this.shownFirstTime = true;
        if (this.onFirstShow) {
          this.$timeout(() => {
            this.onFirstShow();
          });
        }
      }
    }

    _registerEvents() {
      this.deregisters.push(
        this._register(this.document, 'click', event => {
          const box = this._getBox();
          const trigger = this._getTrigger();
          if (box && !box[0].contains(event.target) && !trigger[0].contains(event.target)) {
            this.hideBox();
          }
        }),
      );

      // Listen for escape key

      this.deregisters.push(
        this._register(this.document, 'keydown', event => {
          if (event.which === 27) {
            this.hideBox();
          }
        }),
      );
    }

    _initializeEvenListeners() {
      // Events

      this.$scope.$on('$stateChangeStart', () => {
        this.hideBox();
      });

      this.$scope.$on(TaskListEvent.SHOW_MULTI_SELECT_MENU, () => {
        this.hideBox();
      });

      this.$scope.$on(TaskListEvent.HIDE_MULTI_SELECT_MENU, () => {
        this.hideBox();
      });

      this.$scope.$on(TaskListEvent.HIDE_POP_BOX, () => {
        this.hideBox();
      });
    }

    hideBox() {
      const box = this._getBox();

      box.css('display', 'none');

      if (this.onHide) {
        this.onHide({});
      }
    }

    _position(box, element) {
      // Reset top and bottom so that the calculations can be done properly
      box.css({ bottom: '', top: '' });

      const boxWidth = box.outerWidth();
      const boxHeight = box.outerHeight();

      const windowWidth = this.window.width();
      const windowHeight = this.window.height();

      const elementOffset = element.offset();
      const elementHeight = element.outerHeight();

      if (elementOffset.left + boxWidth + this.PADDING >= windowWidth) {
        elementOffset.left -= elementOffset.left + boxWidth + this.PADDING - windowWidth;
      }

      elementOffset.top += elementHeight + this.PADDING - this.window.scrollTop();
      if (elementOffset.top + boxHeight + this.PADDING >= windowHeight) {
        elementOffset.top -= elementOffset.top + boxHeight + this.PADDING - windowHeight;
      }

      box.css(elementOffset);
    }
  },
};
