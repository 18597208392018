import { Template } from '@process-street/subgrade/process';
import { ButtonProps } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import * as React from 'react';

export interface ScheduleWorkflowButtonProps {
  templateId?: Template['id'];
  children: React.ReactElement<ButtonProps>;
  mode?: 'view' | 'create';
}

export const ScheduleWorkflowButton: React.FC<React.PropsWithChildren<ScheduleWorkflowButtonProps>> = React.memo(
  ({ children, templateId, mode = 'create' }) => {
    const { $state, $stateParams } = useInjector('$state', '$stateParams');

    const id = templateId ?? $stateParams.id;

    const go = React.useCallback(() => {
      mode === 'create'
        ? $state.go(`futureChecklistAdd`, { templateId: id })
        : $state.go('dashboard.type', { type: 'scheduled' });
    }, [$state, id, mode]);

    if (!React.isValidElement(children) || Array.isArray(children)) {
      throw new Error('ScheduleWorkflowButton child must be a single clickable element');
    }

    const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(id, { enabled: Boolean(id) });
    if (!permissionsQuery.data?.permissionMap.checklistSchedule) return null;

    const btn = React.cloneElement(children, { onClick: go });

    return btn;
  },
);
