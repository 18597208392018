import angular from 'angular';
import toolsTemplate from './tools.component.html';
import promptsTemplate from './prompts.component.html';
import organizationTemplate from './organizations.component.html';

angular.module('frontStreetApp.controllers').controller('DeveloperConsoleCtrl', function ($scope, $state, $timeout) {
  const ctrl = this;
  const possibleTabs = [
    {
      name: 'tools',
      heading: 'Tools',
      templateUrl: toolsTemplate,
    },
    {
      name: 'prompts',
      heading: 'Prompts',
      templateUrl: promptsTemplate,
    },
    {
      name: 'organizations',
      heading: 'Organizations',
      templateUrl: organizationTemplate,
    },
  ];

  $scope.$on('$stateChangeSuccess', (__event, __toState, toParams) => {
    $scope.activeIndex = $scope.tabs.findIndex(tab => tab.name === toParams.tab);
  });

  ctrl.$onInit = () => {
    $scope.tabs = possibleTabs;
    $scope.activeIndex = $scope.tabs.findIndex(tab => tab.name === $state.params.tab);

    $timeout(() => {
      initializeTabs($scope.tabs);
    });
  };

  function initializeTabs(tabs) {
    let selectedTab = null;
    tabs.forEach(tab => {
      tab.select = function () {
        this.selectedOnce = true;

        const location = $state.current.name.endsWith('.tab') || 'replace';
        $state.go('developer.tab', { tab: tab.name }, { location });
      };

      if (tab.name === $state.params.tab) {
        selectedTab = tab;
      }
    });

    selectedTab = selectedTab || tabs[0];
    selectedTab.select();
  }
});
