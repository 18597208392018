import { Organization, User } from '@process-street/subgrade/core';
import { InboxItem, InboxItemType } from '@process-street/subgrade/inbox';

import { AxiosError } from 'axios';
import { QueryClient, useInfiniteQuery, QueryKey, UseInfiniteQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { Template } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import isEqual from 'lodash/isEqual';
import negate from 'lodash/negate';
import { cloneDeep } from 'lodash';

export namespace GetInboxItemsQuery {
  export enum SortBy {
    TaskTemplateName = 'TaskTemplateName',
    ChecklistName = 'ChecklistName',
    AssignmentDueDate = 'AssignmentDueDate',
    TemplateName = 'TemplateName',
  }

  export enum GroupBy {
    DueDate = 'DueDate',
    WorkflowRun = 'WorkflowRun',
  }

  export enum SnoozeStatus {
    Active = 'Active',
    Snoozed = 'Snoozed',
  }

  export type Params = {
    organizationId: Organization['id'];
    userIds: User['id'][];
    pageSize?: number;
    offset?: number;
    search?: string;
    itemsType?: InboxItemType[];
    templateIds?: Template['id'][];
    sortBy?: SortBy;
    sortAsc?: boolean;
    snoozeStatus?: SnoozeStatus;
    includeCompleted?: boolean;
  };

  export type Response = InboxItem[];

  export const key = ['inbox-items'] as QueryKey;

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const DEFAULT_PAGE_SIZE = 25;

  export const queryFn = async (params: Params) => {
    const { userIds, itemsType, templateIds } = params;
    return axiosService
      .getAxios()
      .get<Response>(`/1/organizations/${params.organizationId}/inbox-items`, {
        params: {
          offset: 0,
          ...params,
          includeNoDueDate: true,
          userIds: toJoined(userIds),
          itemsType: toJoined(itemsType),
          templateIds: toJoined(templateIds),
        },
      })
      .then(res => res.data);
  };

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseInfiniteQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    const pageSize = params.pageSize ?? DEFAULT_PAGE_SIZE;
    return useInfiniteQuery(getKey(params), ({ pageParam = 0 }) => queryFn({ ...params, offset: pageParam }), {
      getNextPageParam: (lastPage, allPages) => (lastPage.length < pageSize ? undefined : allPages.length * pageSize),
      queryKey: getKey(params),
      ...options,
    });
  };

  export const invalidate = (queryClient: QueryClient, params?: Params) =>
    queryClient.invalidateQueries(params ? getKey(params) : key);

  export const removeItem = (queryClient: QueryClient, matcher: (item: InboxItem) => boolean, params?: Params) => {
    const setQueryData = (data: { pages: Array<Array<InboxItem>> } | Array<InboxItem> | undefined) => {
      return (
        match(data)
          // Update paged data
          .with({ pages: P.not(P.nullish) }, pagedData => ({
            ...pagedData,
            pages: pagedData.pages.map(page => page.filter(negate(matcher))),
          }))
          // Update flat data
          .with(P.array(P.any), flatData => flatData.filter(negate(matcher)))
          .otherwise(() => data)
      );
    };

    // Remove from a single query
    if (params) {
      const originalValue = cloneDeep(queryClient.getQueryData(getKey(params)));
      queryClient.setQueryData<{ pages: InboxItem[][] } | InboxItem[] | undefined>(getKey(params), setQueryData);

      return () => {
        queryClient.setQueryData(getKey(params), originalValue);
      };
    }

    // Find all the `inbox-items` queries to remove the snoozed task
    const queriesToUpdate = cloneDeep(
      queryClient.getQueryCache().findAll({
        predicate: query => {
          try {
            return isEqual(query.queryKey.slice(0, GetInboxItemsQuery.key.length), GetInboxItemsQuery.key);
          } catch {
            return false;
          }
        },
      }),
    );

    // Traverse every inbox-items query and remove the snoozed task from there.
    queriesToUpdate.forEach(query => {
      queryClient.setQueryData<{ pages: InboxItem[][] } | InboxItem[] | undefined>(query.queryKey, setQueryData);
    });

    return () => {
      // Revert the cache
      queriesToUpdate.forEach(query => {
        queryClient.setQueryData(query.queryKey, query.state.data);
      });
    };
  };

  export const updateItem = (
    queryClient: QueryClient,
    matcher: (item: InboxItem) => boolean,
    update: (item: InboxItem) => InboxItem,
    params?: Params,
  ) => {
    const setQueryData = (data: { pages: Array<Array<InboxItem>> } | Array<InboxItem> | undefined) => {
      return (
        match(data)
          // Update paged data
          .with({ pages: P.not(P.nullish) }, pagedData => ({
            ...pagedData,
            pages: pagedData.pages.map(page => page.map(item => (matcher(item) ? update(item) : item))),
          }))
          // Update flat data
          .with(P.array(P.any), flatData => flatData.map(item => (matcher(item) ? update(item) : item)))
          .otherwise(() => data)
      );
    };

    // Update inside a single query
    if (params) {
      const originalValue = cloneDeep(queryClient.getQueryData(getKey(params)));
      queryClient.setQueryData<{ pages: InboxItem[][] } | InboxItem[] | undefined>(getKey(params), setQueryData);

      return () => {
        queryClient.setQueryData(getKey(params), originalValue);
      };
    }

    // Find all the `inbox-items` queries to update the item
    const queriesToUpdate = cloneDeep(
      queryClient.getQueryCache().findAll({
        predicate: query => {
          try {
            return isEqual(query.queryKey.slice(0, GetInboxItemsQuery.key.length), GetInboxItemsQuery.key);
          } catch {
            return false;
          }
        },
      }),
    );

    // Traverse every inbox-items query to update the item
    queriesToUpdate.forEach(query => {
      queryClient.setQueryData<{ pages: InboxItem[][] } | InboxItem[] | undefined>(query.queryKey, setQueryData);
    });

    return () => {
      // Revert the cache
      queriesToUpdate.forEach(query => {
        queryClient.setQueryData(query.queryKey, query.state.data);
      });
    };
  };
}

/** Omits query parameter if array is empty, otherwise joins items with comma. */
function toJoined<T extends string>(items: T[] | undefined): string | undefined {
  if (Array.isArray(items) && items.length > 0) {
    return items.join(',');
  }
}
