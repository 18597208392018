import { TemplateRevision } from '@process-street/subgrade/process';
import { Muid } from '@process-street/subgrade/core';
import {
  GetNewestTemplateRevisionsByTemplateIdQueryResponse,
  useGetNewestTemplateRevisionsByTemplateIdQuery,
} from 'features/template-revisions/query-builder/get-newest-template-revisions-by-template-id';
import { UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import identity from 'lodash/identity';

export type GetDraftTmplRevisionByTmplIdParams = { templateId?: Muid };

export type GetDraftTmplRevisionByTmplIdResponse = TemplateRevision | undefined;

export const useGetDraftTmplRevisionByTmplIdQuery = <Select = GetDraftTmplRevisionByTmplIdResponse>(
  params: GetDraftTmplRevisionByTmplIdParams,
  {
    select,
    ...options
  }: Omit<UseQueryOptions<GetNewestTemplateRevisionsByTemplateIdQueryResponse, AxiosError, Select>, 'select'> & {
    select?: (result: GetDraftTmplRevisionByTmplIdResponse) => Select;
  } = {},
) => {
  return useGetNewestTemplateRevisionsByTemplateIdQuery<Select>(params, {
    ...options,
    select: newestTmplRevisions => (select ?? identity)(newestTmplRevisions[1]) as Select,
  });
};
