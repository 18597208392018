import * as React from 'react';
import { ListItem, Text, Tooltip, TooltipProps, UnorderedList } from 'components/design/next';
import { OptionType } from './types';
import { NodeName } from '../node-name';

type CollapsedValuesTooltipProps = TooltipProps & {
  parentLabel?: React.ReactNode;
  values: OptionType[];
  valuesByParentMap: Record<string, OptionType[]>;
};

export const CollapsedValuesTooltip: React.FC<React.PropsWithChildren<CollapsedValuesTooltipProps>> = ({
  parentLabel,
  values,
  valuesByParentMap,
  children,
  ...props
}) => {
  const label = (
    <>
      <Text as="span" fontSize="xs" fontWeight="bold">
        {parentLabel}
      </Text>
      <UnorderedList mb="0">
        {values.slice(0, 10).map(value => {
          const childrenValues = valuesByParentMap[value.id] ?? [];

          return (
            <React.Fragment key={value.id}>
              <ListItem key={value.id}>
                <NodeName node={value} />
              </ListItem>

              {childrenValues.map(childValue => (
                <ListItem key={childValue.id} ml="2">
                  <NodeName node={childValue} />
                </ListItem>
              ))}
            </React.Fragment>
          );
        })}
      </UnorderedList>
    </>
  );

  return (
    <Tooltip label={label} {...props}>
      {children}
    </Tooltip>
  );
};
