import { Muid } from '@process-street/subgrade/core';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { makeReceptionist } from 'utils/machines';

type WidgetHiddenByRuleKey = `rules-engine-target-actor:widget-${Widget['header']['group']['id']}`;
type TaskHiddenByRuleKey = `rules-engine-target-actor:task-${TaskTemplate['group']['id']}`;

type Recipients = 'rules-engine-actor' | WidgetHiddenByRuleKey | TaskHiddenByRuleKey;

export const FormResponseMachineReceptionist = makeReceptionist<Recipients>();

export const makeRulesEngineTargetWidgetKey = (widget: Widget | Muid): WidgetHiddenByRuleKey => {
  return `rules-engine-target-actor:widget-${typeof widget === 'string' ? widget : widget.header.group.id}`;
};

export const makeRulesEngineTargetTaskKey = (taskTemplate: TaskTemplate | Muid): TaskHiddenByRuleKey => {
  return `rules-engine-target-actor:task-${typeof taskTemplate === 'string' ? taskTemplate : taskTemplate.group.id}`;
};
