import * as React from 'react';
import {
  Icon,
  HStack,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
  StackProps,
} from 'components/design/next';

import { SolutionTypeTag, withTrayPrefix } from '@process-street/subgrade/automation';

import { AutomationAppIcon } from 'features/automations/components/automation-app-icon';
import { useSolutionTypeTagInstances } from 'pages/templates/_id/automation/components/selector/use-solution-type-tag-instances';

import { MAX_SOLUTION_INSTANCES_VISIBLE } from '../../../constants';
import { TemplateSolutionInstancesList } from '../solution-instances';
import { useWorkflowAutomationsContext } from '../context';
import { Muid } from '@process-street/subgrade/core';

const IconStack: React.FC<React.PropsWithChildren<StackProps>> = props => {
  const { templateRevision } = useWorkflowAutomationsContext();
  const templateId = templateRevision?.template?.id;

  const {
    instances: { ps_when_checklist_completed_then },
  } = useSolutionTypeTagInstances({ templateId });

  const outgoing = ps_when_checklist_completed_then.filter(i => i.configured);

  const notVisibleCount =
    outgoing.length > MAX_SOLUTION_INSTANCES_VISIBLE ? outgoing.length - MAX_SOLUTION_INSTANCES_VISIBLE : 0;

  return (
    <HStack spacing="2" pr="4" {...props}>
      {outgoing.length
        ? outgoing
            .slice(0, MAX_SOLUTION_INSTANCES_VISIBLE)
            .map(templateSolutionInstance => (
              <AutomationAppIcon
                key={templateSolutionInstance.id}
                cursor="pointer"
                w="5"
                {...{ automationApp: withTrayPrefix(templateSolutionInstance.automationApp) }}
              />
            ))
        : null}
      {notVisibleCount && <Text>+{notVisibleCount}</Text>}
    </HStack>
  );
};

type Props = { isDisabled?: boolean; templateId?: Muid };

export const ActiveOutgoingList: React.FC<React.PropsWithChildren<Props>> = ({ isDisabled, templateId }) => {
  return (
    <>
      <Flex w="full" bg="white" borderRadius="lg" mb="-2">
        <Accordion w="full" allowToggle>
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <Flex>
                  <AccordionButton
                    aria-label="show automations"
                    borderWidth="px"
                    borderColor="gray.200"
                    borderStyle="solid"
                    borderRadius="lg"
                    p="0"
                    pl="6"
                    height="12"
                    _expanded={{ borderBottomRadius: 0 }}
                    transition="all 0.3s ease-in-out"
                    _hover={{ bg: 'inherit' }}
                    disabled={false}
                    w="full"
                    justifyContent="space-between"
                  >
                    <HStack alignItems="center" spacing="3" minW="0" flex="1">
                      <Icon
                        variant="far"
                        icon={isExpanded ? 'chevron-down' : 'chevron-right'}
                        size="4"
                        color="gray.600"
                      />

                      <Icon variant="far" icon="bolt" size="4" color="purple.500" />
                      <Text noOfLines={1}>When a workflow run is completed then ... </Text>
                    </HStack>
                    <IconStack />
                  </AccordionButton>
                </Flex>
                <AccordionPanel p="0">
                  <Flex flexDirection="column">
                    <TemplateSolutionInstancesList
                      {...{
                        solutionTypeTag: SolutionTypeTag.WhenChecklistCompletedThen,
                        isDisabled,
                        templateId,
                      }}
                    />
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Flex>
    </>
  );
};
