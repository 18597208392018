import {} from '@process-street/subgrade/core';
import { TaskPermissionRule, TemplateRevision } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace DeleteAllTaskTemplateRulesMutation {
  export type Params = {
    templateRevisionId: TemplateRevision['id'];
    ids: Array<TaskPermissionRule['id']>;
  };

  export type Response = Array<TaskPermissionRule>;

  export const key = ['task-templates', 'rules', 'delete', 'all'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/template-revisions/${params.templateRevisionId}/task-permission-rules/delete`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
