import * as React from 'react';

import { useAdminEmails } from 'components/paywalls/use-admin-emails';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { mailto } from '@process-street/subgrade/process';
import {
  Button,
  Icon,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from 'components/design/next';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { Box } from 'components/design/next';
import { useRoleNames } from 'hooks/use-role-names';

export type Props = {
  icon?: IconName;
  label?: string;
  isButton?: boolean;
};

export const ButtonWithUpgradeTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  icon,
  label,
  isButton,
}) => {
  const currentUser = useSelector(SessionSelector.getCurrentUser);
  const [adminEmail, ...bcc] = useAdminEmails();
  const roleNames = useRoleNames();

  const href = mailto({
    to: adminEmail,
    subject: `Someone on your team is requesting an upgrade on your Process Street plan`,
    body: `A ${roleNames.FreeMember.single} on your Process Street account${
      currentUser?.username ? `, ${currentUser.username}, ` : ''
    }would like you to upgrade them to a ${roleNames.FullMember.single}. ${
      roleNames.FullMember.plural
    } can create and run workflows.\n\nUpgrade here: https://app.process.st/organizations/manage/billing`,
    bcc,
  });
  const btn = React.useMemo(
    () =>
      children ?? (
        <Button
          {...(isButton
            ? { borderColor: 'gray.300', borderWidth: 'px', borderStyle: 'solid', borderRadius: 'base' }
            : {})}
          padding="7px 9px"
          color="gray.400"
          bgColor="white"
          position="relative"
          variant="unstyled"
          iconSpacing="2"
          display="flex"
          w="full"
          _hover={{ bgColor: 'gray.100' }}
          // unfortunate nudging to get it to line up with other angular bootstrap anchor elements
          justifyContent="flex-start"
          leftIcon={icon ? <Icon icon={icon} variant="far" size="4" /> : undefined}
        >
          {label}
        </Button>
      ),
    [icon, isButton, label, children],
  );

  return (
    <Box position="relative">
      {btn}

      <Popover trigger="hover" size="auto" variant="tooltip-dark">
        <PopoverTrigger>
          <Box w="full" top="0" left="0" h="full" position="absolute" />
        </PopoverTrigger>
        <PopoverContent w="auto">
          <PopoverArrow />
          <PopoverBody>
            <Link href={href} isExternal>
              Contact your admin
            </Link>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
