import { Muid, Option } from '@process-street/subgrade/core';
import { DataSetTriggerType, TemplateTriggerType, Webhook, WebhookTriggerType } from '@process-street/subgrade/process';
import * as React from 'react';
import { urlService } from 'services/url-service';
import './WebhookItem.scss';
import { Switch, FormControl, Icon, useBreakpointValue } from 'components/design/next';
import { DataSetSelector } from 'components/organization-manage/webhooks/components/DataSetSelector/DataSetSelector';
import { match, P } from 'ts-pattern';
import { FormSelector } from 'components/organization-manage/webhooks/components/FormSelector/FormSelector';
import { WorkflowSelector } from 'components/organization-manage/webhooks/components/WorkflowSelector/WorkflowSelector';
import { IconButton } from '@chakra-ui/react';

export type WebhookItemProps = {
  webhook: Webhook;
  onWebhookUpdate: (webhook: Webhook) => void;
  onWebhookDelete: (id: Muid) => void;
};

export const WebhookItem: React.FC<React.PropsWithChildren<WebhookItemProps>> = props => {
  const [triggersHaveError, setTriggersHaveError] = React.useState(false);
  const [urlHasError, setUrlHasError] = React.useState(false);
  const [webhook, setWebhook] = React.useState(props.webhook);

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const handleTemplateIdUpdate = (templateId: Option<Muid>): void => {
    const updatedWebhook = {
      ...webhook,
      templateId,
    };

    setWebhook(updatedWebhook);
    updateIfValid(updatedWebhook);
  };

  const handleDataSetIdUpdate = (dataSetId: Option<Muid>): void => {
    const updatedWebhook = {
      ...webhook,
      dataSetId,
    };

    setWebhook(updatedWebhook);
    updateIfValid(updatedWebhook);
  };

  const getAvailableTriggers = () =>
    match({ webhookType: webhook.webhookType })
      .with({ webhookType: P.union('Form') }, () => [TemplateTriggerType.ChecklistCompleted])
      .with({ webhookType: P.union('Workflow') }, () => [
        TemplateTriggerType.ChecklistCreated,
        TemplateTriggerType.ChecklistCompleted,
        TemplateTriggerType.TaskCheckedUnchecked,
        TemplateTriggerType.TaskReady,
        TemplateTriggerType.TaskNotReady,
        TemplateTriggerType.TaskApproved,
        TemplateTriggerType.TaskRejected,
      ])
      .with({ webhookType: 'DataSet' }, () => [
        DataSetTriggerType.DataSetRowCreated,
        DataSetTriggerType.DataSetRowUpdated,
        DataSetTriggerType.DataSetRowDeleted,
      ])
      .otherwise(() => []);

  const getTriggerHeading = (trigger: WebhookTriggerType) => {
    const checklistType = webhook.webhookType === 'Workflow' ? 'workflow run' : 'form';
    const action = webhook.webhookType === 'Workflow' ? 'completed' : 'submitted';

    return match(trigger)
      .with(TemplateTriggerType.ChecklistCreated, () => 'When a workflow is run')
      .with(TemplateTriggerType.ChecklistCompleted, () => `When a ${checklistType} is ${action}`)
      .with(TemplateTriggerType.TaskCheckedUnchecked, () => 'When tasks are checked/unchecked')
      .with(TemplateTriggerType.TaskReady, () => 'When tasks are ready to be checked')
      .with(TemplateTriggerType.TaskNotReady, () => 'When tasks are not ready to be checked')
      .with(TemplateTriggerType.TaskApproved, () => 'When a task is approved')
      .with(TemplateTriggerType.TaskRejected, () => 'When a task is rejected')
      .with(DataSetTriggerType.DataSetCreated, () => 'When a data set is created')
      .with(DataSetTriggerType.DataSetDeleted, () => 'When a data set is deleted')
      .with(DataSetTriggerType.DataSetRowCreated, () => 'When a record is added to a data set')
      .with(DataSetTriggerType.DataSetRowDeleted, () => 'When a record is deleted from a data set')
      .with(DataSetTriggerType.DataSetRowUpdated, () => 'When a record is updated in a data set')
      .otherwise(() => `Unknown trigger [${trigger}]`);
  };

  const getTriggerCheckbox = (trigger: WebhookTriggerType) => {
    const heading = getTriggerHeading(trigger);
    const isSelected = webhook.triggers.includes(trigger);

    const toggleFunction = () => handleTriggerChange(trigger, !isSelected);

    return (
      <div className="row" key={trigger}>
        <div className="col-sm-12">
          <span className="trigger-selection">
            <a onClick={toggleFunction}>
              <span className={`webhook-trigger-checkbox ${isSelected ? ' checked' : ''}`}>
                <i className="far fa-check" />
              </span>
            </a>
          </span>
          {heading}
        </div>
        <div className="col-sm-2" />
      </div>
    );
  };

  const handleValidateUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const urlValue = event.target.value;

    if (urlService.isValidFullUrl(urlValue)) {
      setUrlHasError(false);

      if (urlValue !== props.webhook.url) {
        updateIfValid(webhook);
      }
    } else {
      setUrlHasError(true);
      setTriggersHaveError(webhook.triggers.length === 0);
    }
  };

  const updateIfValid = (webhook: Webhook) => {
    if (isValid(webhook)) {
      props.onWebhookUpdate(webhook);
    }
  };

  const handleUpdateUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const urlValue = event.target.value;

    setUrlHasError(false);
    setWebhook({
      ...webhook,
      url: urlValue,
    });
  };

  const handleActiveSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    const updatedWebhook: Webhook = {
      ...webhook,
      status: checked ? 'Active' : 'Disabled',
    };

    setWebhook(updatedWebhook);
    updateIfValid(updatedWebhook);
  };

  const isValid = (webhook: Webhook) => {
    const validUrl = urlService.isValidFullUrl(webhook.url);
    const validTriggers = webhook.triggers.length > 0;
    const validSelector = webhook.webhookType === 'DataSet' ? webhook.dataSetId !== undefined : true;

    return validUrl && validTriggers && validSelector;
  };

  const handleTriggerChange = (trigger: WebhookTriggerType, isSelected: boolean) => {
    const triggers = isSelected
      ? [...webhook.triggers, trigger]
      : [...webhook.triggers.filter((value: WebhookTriggerType) => value !== trigger)];

    setTriggersHaveError(triggers.length === 0);

    const updatedWebhook = {
      ...webhook,
      triggers,
    };
    setWebhook(updatedWebhook);

    updateIfValid(updatedWebhook);
  };

  const handleDeleteWebhook = () => {
    props.onWebhookDelete(webhook.id);
  };

  const getUrlError = () => {
    if (urlHasError) {
      return <div className="help-block">Invalid URL!</div>;
    }
  };

  const getTriggersError = () => {
    if (triggersHaveError) {
      return (
        <div className={`form-group row ${triggersHaveError ? 'has-error' : ''} `}>
          <div className="col-sm-12 help-block">At least one trigger needs to be selected!</div>
        </div>
      );
    }
  };

  const selector = match(webhook.webhookType)
    .with(P.union('Workflow'), () => (
      <>
        <label>Workflow</label>
        <WorkflowSelector selectedTemplateId={webhook.templateId} onChange={handleTemplateIdUpdate} />
      </>
    ))
    .with(P.union('Form'), () => (
      <>
        <label>Form</label>
        <FormSelector selectedTemplateId={webhook.templateId} onChange={handleTemplateIdUpdate} />
      </>
    ))
    .with('DataSet', () => (
      <>
        <label>Data Set</label>
        <DataSetSelector dataSetId={webhook.dataSetId} onChange={handleDataSetIdUpdate} />
      </>
    ))
    .otherwise(() => null);

  const deleteButton = (
    <IconButton
      variant="ghost"
      color="gray.500"
      onClick={handleDeleteWebhook}
      aria-label="delete this webhook"
      icon={<Icon icon="trash-alt" variant="far" size="4" />}
      mt="29px"
    />
  );

  return (
    <div className="webhook-row-container" key={`webhook-${webhook.id}`}>
      <div className="row" style={{ width: '100%' }}>
        <div className={`col-sm-5`}>
          {isSmallScreen ? (
            <div className="row">
              <div className="col-xs-11 form-group">{selector}</div>
              <div className="col-xs-1">{deleteButton}</div>
            </div>
          ) : (
            <div className="form-group">{selector}</div>
          )}
        </div>
        <div className="col-sm-7">
          <div className="row">
            <div className={`col-xs-11 form-group ${urlHasError ? 'has-error' : ''} `}>
              <label>URL</label>
              <input
                type="text"
                onBlur={handleValidateUrl}
                onChange={handleUpdateUrl}
                className="form-control"
                value={webhook.url}
              />
              {getUrlError()}
            </div>
            <div className="col-xs-1 hidden-xs">{deleteButton}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="webhook-triggers">
            <FormControl display="flex" alignItems="center">
              <Switch
                size="lg"
                isChecked={webhook.status === 'Active'}
                onChange={handleActiveSwitch}
                display="contents"
              >
                Active
              </Switch>
            </FormControl>

            {getAvailableTriggers().map(trigger => getTriggerCheckbox(trigger))}
            {getTriggersError()}
          </div>
        </div>
      </div>
    </div>
  );
};
