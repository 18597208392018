import { SessionSelector } from 'reducers/session/session.selectors';
import { createCachedAction } from 'reducers/util';
import { OrganizationSelector } from './organization.selectors';

export const ORGANIZATION_CREATE = 'organization/CREATE';
export const ORGANIZATION_GET_BY_ID = 'organization/GET_BY_ID';
export const ORGANIZATION_UPDATE_BY_ID = 'organization/UPDATE_BY_ID';
export const ORGANIZATION_REPAIR_BY_ID = 'organization/REPAIR_BY_ID';
export const ORGANIZATION_SET_EMAIL = 'organization/SET_EMAIL';

export const ORGANIZATION_USER_GET_ALL_WITH_NOT_MATCHING_EMAIL_DOMAIN_BY_ID =
  'organization/user/GET_ALL_WITH_NOT_MATCHING_EMAIL_DOMAIN_BY_ID';
export const ORGANIZATION_USER_GET_ALL_WITH_MATCHING_EMAIL_DOMAIN_AND_MULTIPLE_EMAILS_BY_ID =
  'organization/user/GET_ALL_WITH_MATCHING_EMAIL_DOMAIN_AND_MULTIPLE_EMAILS_BY_ID';
export const ORGANIZATION_USER_GET_ALL_WITH_MATCHING_EMAIL_DOMAIN_AND_MULTIPLE_ORGANIZATIONS_BY_ID =
  'organization/user/GET_ALL_WITH_MATCHING_EMAIL_DOMAIN_AND_MULTIPLE_ORGANIZATIONS_BY_ID';

export const ORGANIZATION_UPDATE_GROW_SUMO_PARTNER_KEY_BY_ID = 'organization/UPDATE_GROW_SUMO_PARTNER_KEY_BY_ID';

export const ORGANIZATION_UPDATE_DEFAULT_SSO_ORGANIZATION_MEMBERSHIP_LEVEL_BY_ID =
  'organization/UPDATE_DEFAULT_SSO_ORGANIZATION_MEMBERSHIP_LEVEL_BY_ID';

export const ORGANIZATION_SUBSCRIPTION_START_TRIAL_BY_ID = 'organization/subscription/START_TRIAL_BY_ID';
export const ORGANIZATION_SUBSCRIPTION_UPDATE_PLAN_BY_ID = 'organization/subscription/UPDATE_PLAN_BY_ID';
export const ORGANIZATION_SUBSCRIPTION_CANCEL_BY_ID = 'organization/subscription/CANCEL_BY_ID';
export const ORGANIZATION_SUBSCRIPTION_END_TRIAL_BY_ID = 'organization/subscription/END_TRIAL_BY_ID';

export const ORGANIZATION_CARD_GET_BY_ID = 'organization/card/GET_BY_ID';
export const ORGANIZATION_CARD_SET_BY_ID = 'organization/card/SET_BY_ID';

export const ORGANIZATION_COUPON_SET_BY_ID = 'organization/coupon/SET_BY_ID';

export const ORGANIZATION_API_KEYS_GET_ALL_BY_ID = 'organization/apiKeys/GET_ALL_BY_ID';
export const ORGANIZATION_API_KEYS_CREATE_BY_ID = 'organization/apiKeys/CREATE_BY_ID';
export const ORGANIZATION_API_KEYS_UPDATE_BY_ORG_ID_AND_KEY_ID = 'organization/apiKeys/UPDATE_BY_ORG_ID_AND_KEY_ID';
export const ORGANIZATION_API_KEYS_DELETE_BY_ORG_ID_AND_KEY_ID = 'organization/apiKeys/DELETE_BY_ORG_ID_AND_KEY_ID';

export const ORGANIZATION_STATS_GET_BY_ID = 'organization/stats/GET_BY_ID';

export const ORGANIZATION_SSO_LOCK_BY_ID = 'organization/sso/LOCK_BY_ID';

export const ORGANIZATION_GET_DISCOUNT = 'organization/GET_DISCOUNT';

export const OrganizationActions = OrganizationApi => {
  'ngInject';

  const getById = createCachedAction(
    ORGANIZATION_GET_BY_ID,
    OrganizationApi.getById,
    (organizationId, { flushCache } = {}) => ({ organizationId, flushCache }),
    (state, organizationId) => OrganizationSelector.getById(organizationId)(state) !== undefined,
    OrganizationSelector.getById,
  );

  const getDiscountByOrganizationId = createCachedAction(
    ORGANIZATION_GET_DISCOUNT,
    OrganizationApi.getDiscountByOrganizationId,
    id => ({ organizationId: id }),
  );

  const loadSelectedOrganization = () => (dispatch, getState) => {
    const state = getState();
    const organizationId = SessionSelector.getSelectedOrganizationId(state);

    return dispatch(getById(organizationId));
  };

  /**
   * Creates a new organization
   *
   * @param name
   * @param timeZone
   *
   * @return {Promise} organization and organizationMembership are returned
   */
  const create = createCachedAction(ORGANIZATION_CREATE, OrganizationApi.create);

  /**
   * Updates organization details by id
   */
  const updateById = createCachedAction(ORGANIZATION_UPDATE_BY_ID, OrganizationApi.updateById);

  /**
   * Gets card by organization id
   */
  const getCardById = createCachedAction(ORGANIZATION_CARD_GET_BY_ID, OrganizationApi.getCardById);

  /**
   * Sets a new card by organization id using given card token
   */
  const setCardById = createCachedAction(
    ORGANIZATION_CARD_SET_BY_ID,
    OrganizationApi.setCardById,
    (organizationId, cardToken) => ({ organizationId, cardToken }),
  );

  /**
   * Set new coupon by organization id
   */
  const setCouponById = createCachedAction(ORGANIZATION_COUPON_SET_BY_ID, OrganizationApi.setCouponById);

  /**
   * Repairs stripe by organization id
   *
   * @param id
   *
   * @return {Promise} with organization that may or may not be updated
   */
  const repairById = createCachedAction(ORGANIZATION_REPAIR_BY_ID, OrganizationApi.repairById);

  /**
   * Updates subscription plan by organization id
   *
   * @param id
   * @param planId
   *
   * @return {Promise} with updated organization
   */
  const updateSubscriptionPlanById = createCachedAction(
    ORGANIZATION_SUBSCRIPTION_UPDATE_PLAN_BY_ID,
    OrganizationApi.updateSubscriptionPlanById,
  );

  /**
   * Cancels subscription by organization id with feedback
   *
   * @param id
   * @param feedback
   *
   * @return {Promise} with updated organization
   */
  const cancelSubscriptionById = createCachedAction(
    ORGANIZATION_SUBSCRIPTION_CANCEL_BY_ID,
    OrganizationApi.cancelSubscriptionById,
  );

  /**
   * Starts trial on a paid plan by organization id
   *
   * @param id
   * @param paidPlanId
   *
   * @return {Promise} with updated organization
   */
  const startSubscriptionTrialById = createCachedAction(
    ORGANIZATION_SUBSCRIPTION_START_TRIAL_BY_ID,
    OrganizationApi.startSubscriptionTrialById,
  );

  /**
   * Ends trial by organization id
   */
  const endSubscriptionTrialById = createCachedAction(
    ORGANIZATION_SUBSCRIPTION_END_TRIAL_BY_ID,
    OrganizationApi.endSubscriptionTrialById,
  );

  /**
   * Gets all api keys by organization id
   */
  const getAllApiKeysById = createCachedAction(ORGANIZATION_API_KEYS_GET_ALL_BY_ID, OrganizationApi.getAllApiKeysById);

  /**
   * Creates an api key by organization id
   */
  const createApiKeyById = createCachedAction(ORGANIZATION_API_KEYS_CREATE_BY_ID, OrganizationApi.createApiKeyById);

  /**
   * Updates API key by organization id and key id
   */
  const updateApiKeyByOrganizationIdAndApiKeyId = createCachedAction(
    ORGANIZATION_API_KEYS_UPDATE_BY_ORG_ID_AND_KEY_ID,
    OrganizationApi.updateApiKeyByOrganizationIdAndApiKeyId,
  );

  /**
   * Deletes API key by organization id and key id
   */
  const deleteApiKeyByOrganizationIdAndApiKeyId = createCachedAction(
    ORGANIZATION_API_KEYS_DELETE_BY_ORG_ID_AND_KEY_ID,
    OrganizationApi.deleteApiKeyByOrganizationIdAndApiKeyId,
  );

  /**
   * Gets organization stats by organization id
   */
  const getOrganizationStatsById = createCachedAction(
    ORGANIZATION_STATS_GET_BY_ID,
    OrganizationApi.getOrganizationStatsById,
    () => ({ flushCache: true }),
  );

  /**
   * Locks organization to SSO
   *
   * @param organizationId
   * @returns {Promise}
   */
  const lockToSsoByOrganizationId = createCachedAction(
    ORGANIZATION_SSO_LOCK_BY_ID,
    OrganizationApi.lockToSsoByOrganizationId,
  );

  /**
   * Updates the GrowSumo partner key.
   *
   * @param organizationId
   * @param partnerKey the GrowSumo partner key
   */
  const updateGrowSumoPartnerKeyById = createCachedAction(
    ORGANIZATION_UPDATE_GROW_SUMO_PARTNER_KEY_BY_ID,
    OrganizationApi.updateGrowSumoPartnerKeyById,
  );

  /**
   * Updates the default SSO organization membership level.
   *
   * @param organizationId
   * @param organizationMembershipLevel
   */
  const updateDefaultSsoOrganizationMembershipLevelById = createCachedAction(
    ORGANIZATION_UPDATE_DEFAULT_SSO_ORGANIZATION_MEMBERSHIP_LEVEL_BY_ID,
    OrganizationApi.updateDefaultSsoOrganizationMembershipLevelById,
  );

  const setEmail = createCachedAction(ORGANIZATION_SET_EMAIL, OrganizationApi.setEmail);

  const getAllUsersWithNotMatchingEmailDomainById = createCachedAction(
    ORGANIZATION_USER_GET_ALL_WITH_NOT_MATCHING_EMAIL_DOMAIN_BY_ID,
    OrganizationApi.getAllUsersWithNotMatchingEmailDomainById,
  );

  const getAllUsersWithMatchingEmailDomainAndMultipleEmailsById = createCachedAction(
    ORGANIZATION_USER_GET_ALL_WITH_MATCHING_EMAIL_DOMAIN_AND_MULTIPLE_EMAILS_BY_ID,
    OrganizationApi.getAllUsersWithMatchingEmailDomainAndMultipleEmailsById,
  );

  const getAllUsersWithMatchingEmailDomainAndMultipleOrganizationsById = createCachedAction(
    ORGANIZATION_USER_GET_ALL_WITH_MATCHING_EMAIL_DOMAIN_AND_MULTIPLE_ORGANIZATIONS_BY_ID,
    OrganizationApi.getAllUsersWithMatchingEmailDomainAndMultipleOrganizationsById,
  );

  const getDiscount = () => (dispatch, getState) => {
    const state = getState();
    const organizationId = SessionSelector.getSelectedOrganizationId(state);

    return dispatch(getDiscountByOrganizationId(organizationId));
  };

  return {
    cancelSubscriptionById,
    create,
    createApiKeyById,
    deleteApiKeyByOrganizationIdAndApiKeyId,
    endSubscriptionTrialById,
    getAllApiKeysById,
    getAllUsersWithMatchingEmailDomainAndMultipleEmailsById,
    getAllUsersWithMatchingEmailDomainAndMultipleOrganizationsById,
    getAllUsersWithNotMatchingEmailDomainById,
    getById,
    getCardById,
    getDiscount,
    getDiscountByOrganizationId,
    getOrganizationStatsById,
    loadSelectedOrganization,
    lockToSsoByOrganizationId,
    repairById,
    setCardById,
    setCouponById,
    setEmail,
    startSubscriptionTrialById,
    updateApiKeyByOrganizationIdAndApiKeyId,
    updateById,
    updateGrowSumoPartnerKeyById,
    updateDefaultSsoOrganizationMembershipLevelById,
    updateSubscriptionPlanById,
  };
};
