import { TaskTemplate, TaskTemplateTaskType } from '@process-street/subgrade/process';
import { useSelector } from '@xstate/react';
import { AiWorkflowGenerationActorSelector } from 'app/pages/forms/_id/edit/ai-workflow-generation-machine';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';
import { useCallback, useEffect, useState } from 'react';

export type TaskTemplateGenerationStatus = 'loaded' | 'loading' | 'error' | 'ignored';
export type TaskTemplateStatusMap = Record<TaskTemplate['id'], TaskTemplateGenerationStatus>;

export const useTaskTemplateStatusMap = () => {
  const [taskTemplateStatusMap, setTaskTemplateStatusMap] = useState<TaskTemplateStatusMap>({});
  const editorActorRef = useFormEditorPageActorRef();
  const aiGenerationActorRef = useSelector(editorActorRef, FormEditorPageActorSelectors.getAiWorkflowGeneratorActorRef);
  const machineTaskTemplateStatusMap = useSelector(
    aiGenerationActorRef,
    AiWorkflowGenerationActorSelector.getTaskTemplateStatusMap,
  );

  const update = useCallback((taskTemplates: TaskTemplate[]) => {
    const newStatusMap: TaskTemplateStatusMap = Object.fromEntries(
      // ignore approval tasks during generation
      taskTemplates.map(tt => [tt.id, tt.taskType === TaskTemplateTaskType.Approval ? 'ignored' : 'loading']),
    );

    setTaskTemplateStatusMap(newStatusMap);
  }, []);

  const reset = useCallback(() => {
    setTaskTemplateStatusMap({});
  }, []);

  useEffect(() => {
    setTaskTemplateStatusMap(previous => ({
      ...previous,
      ...machineTaskTemplateStatusMap,
    }));
  }, [machineTaskTemplateStatusMap]);

  return { taskTemplateStatusMap, update, reset };
};
