import angular from 'angular';
import { reactToAngular } from 'angulareact';
import { withErrorBoundary } from 'components/error-boundary';
import { TasksPage } from './tasks';
import { SnoozeButton } from 'pages/tasks/components/snooze-button';

export const TasksModule = angular
  .module('tasksPage', [])
  .component('psSnoozeButton', reactToAngular(SnoozeButton, ['inboxItem', 'onSnooze', 'mode']))
  .component('psTasksPage', reactToAngular(withErrorBoundary(TasksPage), [])).name;
