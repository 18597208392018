import React, { useEffect } from 'react';
import { useFunctionRef } from 'hooks/use-function-ref';
import { BlvdSelect, BlvdSelectHelpers, GroupBase } from 'components/design/BlvdSelect';
import { Box, Checkbox, HStack } from 'components/design/next';
import { Muid } from '@process-street/subgrade/core';
import { Label } from '@process-street/subgrade/process';
import { components, SelectComponentsConfig } from 'react-select';
import { LabelItem } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers/LabelRenderer/LabelItem';

export interface ChecklistLabelMultiselectProps {
  id: string;
  labels: Label[];
  selectedLabels: Muid[];
  onDone: (selectedLabels: Muid[]) => void;
}

type Option = { label: string; value: Label };

const toOption = (label: Label): Option => ({ value: label, label: label.name });

const toValue = (option: Option) => option.value.id;

export const ChecklistLabelMultiselect: React.FunctionComponent<
  React.PropsWithChildren<ChecklistLabelMultiselectProps>
> = ({ id, labels, selectedLabels, onDone }) => {
  const [value, setValue] = React.useState<Option[]>([]);
  useEffect(() => {
    setValue(labels.filter(l => selectedLabels.includes(l.id)).map(toOption));
  }, [labels, selectedLabels]);

  const options = React.useMemo(() => labels.map(toOption), [labels]);

  const onDoneRef = useFunctionRef(onDone);
  const onDoneCallback = React.useCallback(
    (options: Option | Option[]) => {
      onDoneRef.current(BlvdSelectHelpers.isOptionsType<Option>(options) ? options.map(toValue) : []);
    },
    [onDoneRef],
  );

  return (
    <Box
      id={id}
      w={100}
      maxW={{
        md: 69,
        base: 'full',
      }}
    >
      <BlvdSelect
        options={options}
        value={value}
        onMenuClose={() => onDone(value.map(toValue))}
        onDone={onDoneCallback}
        onChange={items => {
          if (BlvdSelectHelpers.isOptionsType<Option>(items)) {
            setValue(items as Option[]);
          } else {
            setValue([]);
          }
        }}
        components={COMPONENTS}
        isMulti
        menuControls
        fixedSize
      />
    </Box>
  );
};

const COMPONENTS: SelectComponentsConfig<Option, true, GroupBase<Option>> = {
  Option: ({ children, ...props }) => {
    const { value } = props.data as Option;
    return (
      <components.Option {...props}>
        <HStack spacing="2">
          {/* Disable default pointer events to prevent menu closing  */}
          <Checkbox isChecked={props.isSelected} size="lg" pointerEvents="none" />
          <LabelItem label={value} maxW="220px" />
        </HStack>
      </components.Option>
    );
  },
  MultiValue: ({ children, ...props }) => {
    const { value } = props.data as Option;
    return (
      <Box {...props.innerProps} _notFirst={{ ml: 1 }} flexShrink={0}>
        <LabelItem
          maxW="100px"
          label={value}
          removeProps={props.removeProps}
          onDelete={e => {
            // @ts-expect-error -- conflict between react-select expecting a div element an the chakra component being a button
            props.removeProps.onClick?.(e);
            const { selectProps } = props;
            if (BlvdSelectHelpers.isOptionsType<Option>(selectProps.value)) {
              const filteredStatuses = selectProps.value.filter(item => item.value !== value);
              selectProps.onDone?.(filteredStatuses);
            }
          }}
        />
      </Box>
    );
  },
};
