import { entitiesSelector } from 'reducers/entities/entities.selectors';
import { referencesNormalizer } from 'reducers/entities/entities.utils';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { UserSelector } from 'reducers/user/user.selectors';
import { createSelector } from 'reselect';

export const normalizeChecklistAssignment = referencesNormalizer([
  'organization',
  'organizationMembership',
  'checklist',
]);

export const checklistAssignmentSelectors = state => entitiesSelector(state).checklistAssignments;
export const checklistAssignmentByIdSelector = assignmentId => state =>
  checklistAssignmentSelectors(state)[assignmentId];

export const checklistsToChecklistAssignments = state => state.checklistAssignment.byChecklist;
export const checklistAssignmentByChecklistSelector = checklistId => state =>
  state.checklistAssignment.byChecklist[checklistId] || [];

export const checklistAssignmentsByChecklistSelector = checklistId => state => {
  const assignmentIds = checklistsToChecklistAssignments(state)[checklistId] || [];
  return assignmentIds.map(assignmentId => checklistAssignmentByIdSelector(assignmentId)(state));
};

export const checklistAssignmentIdsByChecklistSelector = checklistId => state =>
  state.checklistAssignment.byChecklist[checklistId];

export const assignmentsByChecklistSelector = checklistId =>
  createSelector(
    [checklistAssignmentByChecklistSelector(checklistId), checklistAssignmentSelectors],
    (checklistAssignments, entities) => checklistAssignments.map(assignmentId => entities[assignmentId]),
  );

export const assignmentsMapSelector = createSelector(
  [
    checklistsToChecklistAssignments,
    checklistAssignmentSelectors,
    OrganizationMembershipSelector.getEntityMap,
    UserSelector.getEntityMap,
  ],
  (assignmentByChecklist, allChecklistAssignments, organizationMemberships, users) => {
    const assignmentsMap = Object.keys(assignmentByChecklist).reduce((agg, checklistId) => {
      const checklistAssignments = assignmentByChecklist[checklistId].map(id => allChecklistAssignments[id]);
      checklistAssignments.sort((a, b) => {
        const userAOrgMem = organizationMemberships[a.organizationMembership.id];
        const userBOrgMem = organizationMemberships[b.organizationMembership.id];

        if (userAOrgMem && userBOrgMem) {
          const userA = users[userAOrgMem.user.id] || {};
          const userB = users[userBOrgMem.user.id] || {};
          return userA ? userA.username.localeCompare(userB.username) : -1;
        } else if (userAOrgMem) {
          return -1;
        } else if (userBOrgMem) {
          return 1;
        } else {
          return 0;
        }
      });
      agg[checklistId] = checklistAssignments;
      return agg;
    }, {});

    return { assignmentsMap };
  },
);

export const checklistAssignmentIdsByUserSelector = checklistId => state =>
  state.checklistAssignment.byUser[checklistId];

export const checklistUserAssignmentsSelector = checklistId => state => {
  const userAssignments = checklistAssignmentIdsByUserSelector(checklistId)(state);
  if (userAssignments !== undefined) {
    return userAssignments;
  }

  const checklistAssignments = checklistAssignmentsByChecklistSelector(checklistId)(state);
  const users = checklistAssignments.map(assignment => {
    const {
      organizationMembership: { id },
    } = assignment;
    const organizationMembership = OrganizationMembershipSelector.getById(id)(state);
    return organizationMembership.user.id;
  });
  return users;
};

export const checklistAssignmentsByOrganizationMembership = state => state.checklistAssignment.byOrganizationMembership;
