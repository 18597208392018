import { FileUploadStats } from 'components/file-upload/store/file-upload.types';
import { Action, createAction } from 'redux-actions';

export const FILE_UPLOAD_SET_STATS = 'fileUpload/SET_STATS';

export type FileUploadActionsSetStatsType = (stats: FileUploadStats) => Action<FileUploadStats>;

export class FileUploadActions {
  public setStats: FileUploadActionsSetStatsType = createAction(
    FILE_UPLOAD_SET_STATS,
    (stats: FileUploadStats) => stats,
  );
}
