import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Skeleton,
  SkeletonCircle,
  Text,
  Tooltip,
  useToast,
  VStack,
} from 'components/design/next';
import { getUserProfilePicUrl } from 'features/comments/utils';
import { Checklist, Comment, CommentMethod } from '@process-street/subgrade/process';
import { isNotIdRef, Muid, User } from '@process-street/subgrade/core';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import { ContentText } from '../../common/content-text';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { useMentionableUsers } from 'features/comments/use-mentionable-users';
import { CommentEditor } from 'components/comments/CommentEditor';
import { useCreateCommentMutation } from 'features/comments/query-builder';
import { ReplyCommentButton } from '../../common/reply-comment-button';
import { CommentHelpBlock } from '../../common/comment-help-block';
import { useQueryClient } from 'react-query';
import { GetCommentsQuery, useGetCommentsQuery } from 'features/comments/query-builder/get-comments-query';

import { match, P } from 'ts-pattern';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export type ReplyCommentPopoverProps = {
  checklistId: Muid;
  templateId: Muid;
  taskId: Muid;
} & PopoverProps;

type CommentPreviewProps = {
  comment: Comment;
  checklistId: Checklist['id'];
};

const CommentPreviewSkeleton = () => {
  return (
    <HStack alignItems="flex-start" spacing="3" width="full">
      <SkeletonCircle size="6"></SkeletonCircle>
      <VStack alignItems="flex-start" minW="0" width="full" flex="1" spacing="4">
        <HStack justifyContent="space-between" alignItems="flex-start" width="full">
          <Skeleton height="4" width="80" />
        </HStack>
        <HStack minW="0" flex="1" mb="4">
          <Skeleton height="4" width="40"></Skeleton>
          <Center height="3">
            <Divider borderColor="gray.500" borderLeftWidth="0.5" orientation="vertical" />
          </Center>
          <Skeleton height="4" width="40"></Skeleton>
        </HStack>
        <Skeleton height="4" width="full"></Skeleton>
        <Skeleton height="4" width="full"></Skeleton>
      </VStack>
    </HStack>
  );
};

const CommentPreview: React.FC<React.PropsWithChildren<CommentPreviewProps>> = ({ comment, checklistId }) => {
  const {
    audit: { createdDate, ...audit },
    content,
  } = comment;

  const createdBy = isNotIdRef(audit.createdBy) ? audit.createdBy : ({} as User);

  const userInfoQuery = useGetCurrentUserInfoQuery();

  return (
    <HStack alignItems="flex-start" spacing="3" width="full">
      <Avatar name={createdBy?.username} src={getUserProfilePicUrl(createdBy)} size="xs" border="none"></Avatar>
      <VStack alignItems="flex-start" flex="1" minWidth="0">
        <HStack>
          <Text aria-label="comment sender" variant="-1" color="gray.600" fontWeight="medium">
            {createdBy?.username}{' '}
          </Text>
          <Text aria-label="comment date" variant="-2" color="gray.500" noOfLines={1}>
            {DateUtils.formatDateTime(
              createdDate,
              DateUtils.isOverYear(createdDate) ? DateFormat.DateMonthDayYearAtTime : DateFormat.DateMonthDayAtTime,
              userInfoQuery.data?.user.timeZone,
            )}
          </Text>
        </HStack>
        <ContentText
          {...{
            content,
            checklistId,
            minW: '0',
            flex: '1',
          }}
        />
      </VStack>
    </HStack>
  );
};

const ReplyCommentPopoverContent: React.FC<React.PropsWithChildren<ReplyCommentPopoverProps>> = ({
  checklistId,
  templateId,
  taskId,
}) => {
  const queryClient = useQueryClient();

  const scrollableContainerRef = React.useRef<HTMLInputElement>(null);
  const [scrolled, setScrolled] = React.useState(false);

  const { data: currentUser } = useGetCurrentUserInfoQuery({ select: data => data.user });
  const { users: mentionableUsers } = useMentionableUsers({ checklistId });

  const [content, setContent] = React.useState('');

  const commentsQuery = useGetCommentsQuery(
    { templateId, taskId },
    {
      select: comments => comments.sort((a, b) => a.audit.createdDate - b.audit.createdDate),
    },
  );

  React.useEffect(() => {
    if (scrollableContainerRef.current?.scrollTo && !scrolled && commentsQuery.status === 'success') {
      scrollableContainerRef.current.scrollTo({ top: scrollableContainerRef.current.scrollHeight });
      setScrolled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
  }, [commentsQuery]);

  const toast = useToast();

  const createCommentMutation = useCreateCommentMutation({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Comment created',
      });
      setContent('');
      queryClient.invalidateQueries(GetCommentsQuery.getKey({ templateId, taskId }));
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems creating that comment",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });
  const handleOnCommentClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      createCommentMutation.mutate({
        content,
        audit: {
          createdBy: currentUser,
          createdDate: new Date().getTime(),
        },
        taskId,
        method: CommentMethod.Task,
      });
    },
    [content, currentUser, taskId, createCommentMutation],
  );

  const isCommentHelpBlockVisible = content.length > 0;

  return (
    <Box maxH="100" overflowY="auto" ref={scrollableContainerRef}>
      <VStack alignItems="flex-start" spacing="4" width="full" flex="1" px="5" py="6">
        {match(commentsQuery)
          .with({ status: 'success', data: P.select() }, comments => {
            return (
              <React.Fragment>
                {comments.map(comment => (
                  <CommentPreview
                    aria-label="comment preview"
                    key={comment.id}
                    comment={comment}
                    checklistId={checklistId}
                  />
                ))}
                <HStack alignItems="flex-start" spacing={[0, 3]} width="full">
                  <Avatar
                    name={currentUser?.username}
                    src={getUserProfilePicUrl(currentUser)}
                    size="xs"
                    border="none"
                    display={['none', 'block']}
                  ></Avatar>{' '}
                  <VStack bg="brand.50" width="full" alignItems="start" p="4" borderRadius="sm">
                    <CommentEditor
                      w="full"
                      bg="brand.50"
                      user={currentUser as User}
                      /* Avoid overflowing from popover */
                      maxSuggestions={3}
                      mentionableUsers={mentionableUsers}
                      content={content}
                      onUpdate={setContent}
                      aria-label={'add comment'}
                    />
                    <HStack justifyContent="flex-end" w="full">
                      {isCommentHelpBlockVisible && <CommentHelpBlock {...{ fontSize: 'sm' }} />}
                      <Button
                        aria-label="send comment"
                        variant="tertiary"
                        size="sm"
                        px={6}
                        isDisabled={content === ''}
                        onClick={handleOnCommentClick}
                        placeSelf="flex-end"
                      >
                        Send
                      </Button>
                    </HStack>
                  </VStack>
                </HStack>
              </React.Fragment>
            );
          })
          .with({ status: 'loading' }, () =>
            Array.from({ length: 2 }).map((_, i) => <CommentPreviewSkeleton key={i} />),
          )
          .otherwise(() => null)}
      </VStack>
    </Box>
  );
};

export const ReplyCommentPopover: React.FC<ReplyCommentPopoverProps> = ({
  checklistId,
  templateId,
  taskId,
  ...props
}) => {
  return (
    <Popover isLazy placement="bottom-start" {...props}>
      {({ isOpen }) => (
        <>
          <Tooltip label="Reply" hasArrow shouldWrapChildren>
            <PopoverTrigger>
              <ReplyCommentButton
                {...(isOpen
                  ? {
                      bg: 'brand.100',
                      color: 'brand.500',
                      borderColor: 'brand.500',
                    }
                  : {
                      bg: 'white',
                      color: 'gray.700',
                      borderColor: 'gray.300',
                    })}
              />
            </PopoverTrigger>
          </Tooltip>
          <PopoverContent width={['70', '500px']}>
            <PopoverBody p="0">
              <ReplyCommentPopoverContent {...{ checklistId, templateId, taskId }} />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
