import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { TaskTemplate } from '@process-street/subgrade/process';
import { useTemplateId } from '../../utils/use-template-id';

type TaskMap = {
  [taskGroupIdOrId: string]: TaskTemplate['name'];
};

/**
 * Returns a map of either task template group IDs or task template IDs to their names.
 * Uses the Draft template revision, use the hook in the WF Editor.
 */
export const useTaskTemplateNamesMap = () => {
  const templateId = useTemplateId();
  const newestTemplateRevisionQuery = useNewestTemplateRevisionQuery({ templateId, editable: true });

  const templateRevisionId = newestTemplateRevisionQuery.data?.id;
  const query = useTaskTemplatesByTemplateRevisionIdQuery(
    { templateRevisionId: templateRevisionId! },
    {
      enabled: Boolean(templateRevisionId),
      select: data =>
        data.reduce((acc, taskTemplate) => {
          return { ...acc, [taskTemplate.group.id]: taskTemplate.name, [taskTemplate.id]: taskTemplate.name };
        }, {} as TaskMap),
    },
  );
  return query.data;
};
