import { useInjector } from 'components/injection-provider';
import { MSTeamsUtils } from 'features/microsoft-teams/utils';
import { LocalStorageService } from 'features/storage/local-storage-service';

export const useRedirectToAppEntryPoint = () => {
  const { $location, $state, $timeout } = useInjector('$location', '$state', '$timeout');

  const redirectToAppEntryPoint = () => {
    const isMSTeams = MSTeamsUtils.isEmbeddedInMsTeams();
    const publicTemplateId = LocalStorageService.getItem('publicTemplateId');
    const publicPageId = LocalStorageService.getItem('publicPageId');
    const signUpRedirectUrl = LocalStorageService.getItem('signUpRedirectUrl');
    const redirectFromConvert = LocalStorageService.getItem('redirectFromConvert');

    if (signUpRedirectUrl) {
      LocalStorageService.removeItem('signUpRedirectUrl');
      $timeout(() => {
        $location.url(signUpRedirectUrl);
      });
    } else if (isMSTeams) {
      $state.go('microsoftTeamsInbox');
    } else if (publicTemplateId) {
      LocalStorageService.removeItem('publicTemplateId');
      $state.go('templateAdd', { templateId: publicTemplateId });
    } else if (publicPageId) {
      LocalStorageService.removeItem('publicPageId');
      $state.go('pageAdd', { pageId: publicPageId });
    } else if (redirectFromConvert) {
      LocalStorageService.removeItem('redirectFromConvert');
      $timeout(() => {
        $location.url(redirectFromConvert);
      });
    } else {
      $state.go('dashboard');
    }
  };

  return { redirectToAppEntryPoint };
};
