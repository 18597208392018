import templateUrl from './task-permission-upgrade-modal.component.html';
import './task-permission-upgrade-modal.scss';

export const TaskPermissionUpgradeModal = {
  bindings: {
    close: '&',
  },
  templateUrl,
  controller: class TaskPermissionUpgradeModalCtrl {
    constructor(SessionService, Subject, UserService) {
      'ngInject';

      this.organization = SessionService.getSelectedOrganization();
      const subject = new Subject(UserService.getCurrentUser().id, this.organization.id);

      this.userIsAdmin = subject.isAdmin();
    }
  },
};
