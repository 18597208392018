import { FormControl, FormErrorMessage, FormLabel, Text } from '@chakra-ui/react';
import { TaskTemplate, TaskTemplateTaskType, Template } from '@process-street/subgrade/process';
import { formikMetaToFormControlProps } from 'app/components/widgets/form-field/settings/fields/utils';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { useField } from 'formik';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import * as React from 'react';

export const TaskSelector = ({ templateId }: { templateId: Template['id'] }) => {
  const [field, meta, helper] = useField<string | undefined>('taskTemplateGroupId');
  const templateRevisionId = useNewestTemplateRevisionQuery({ templateId, editable: true }).data?.id;
  const { data: taskTemplates, isLoading } = useTaskTemplatesByTemplateRevisionIdQuery({ templateRevisionId });

  const standardTasks = taskTemplates
    ? taskTemplates.filter(taskTemplate => taskTemplate.taskType === TaskTemplateTaskType.Standard)
    : [];
  const options = standardTasks.map(function (taskTemplate: TaskTemplate) {
    return {
      value: taskTemplate.group.id,
      label: taskTemplate.name ?? 'Unnamed task',
    };
  });
  type OptionItem = typeof options[number];

  const value = options.find(option => option.value === field.value);

  React.useEffect(
    function setRemovedError() {
      if (!meta.error && !meta.touched && taskTemplates && field.value && !value) {
        helper.setError('Task has been removed, select a different task');
        void helper.setTouched(true, false /* shouldValidate */);
      }
    },
    [options.length, value, meta.error, helper, meta.touched, isLoading, field.value, taskTemplates],
  );

  return (
    <FormControl {...formikMetaToFormControlProps(meta)} isRequired>
      <FormLabel>Task with Page content</FormLabel>
      <Text fontSize="sm" color="gray.500" my={2}>
        The content of this task will populate the new updated Page
      </Text>
      <BlvdSelect<OptionItem>
        isDisabled={isLoading}
        isSearchable
        placeholder="Select task"
        options={options}
        value={value}
        onChange={item => item && helper.setValue(item.value)}
      ></BlvdSelect>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
