import { OrganizationStats } from '@process-street/subgrade/core';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { EntityMap, SuccessAction } from '@process-street/subgrade/redux/types';
import { ORGANIZATION_STATS_GET_BY_ID } from 'reducers/organization/organization.actions';
import { handleActionsOnSuccess } from 'reducers/util';

export const organizationStatsEntitiesReducer = handleActionsOnSuccess(
  {
    [ORGANIZATION_STATS_GET_BY_ID]: (
      state: EntityMap<OrganizationStats>,
      action: SuccessAction<OrganizationStats, Record<string, unknown>>,
    ) => EntitiesReducerUtils.upsert(state, action.payload),
  },
  {},
);
