import * as React from 'react';
import { match, P } from 'ts-pattern';
import { abbreviateForTitle } from '@process-street/subgrade/util';
import qs from 'qs';
import { Link } from '@process-street/adapters/navigation';
import { TasksTableUtils } from '../tasks-table/tasks-table-utils';
import { Icon, HStack, Flex, Text, Tooltip } from 'components/design/next';
import { useIsTextTruncated } from 'app/hooks/use-is-text-truncated';

export type WorkflowRendererProps = {
  data: TasksTableUtils.TasksTableItem;
};

export const WorkflowRenderer = ({ data }: WorkflowRendererProps) => {
  const template = match(data)
    .with({ template: { name: P.string } }, item => item.template)
    .otherwise(() => null);

  const textRef = React.useRef<HTMLParagraphElement>(null);
  const { isTextTruncated } = useIsTextTruncated({ textRef });

  if (!template || !template?.name) return null;

  const content = (
    <Text ref={textRef} noOfLines={1}>
      {template.name}
    </Text>
  );

  return (
    <Flex h="full" alignItems="center" role="group" px={2}>
      <Link
        to={{
          pathname: 'templateDashboard',
          search: qs.stringify({
            id: template.id,
            title: `${abbreviateForTitle(template.name)}-`,
          }),
        }}
        color="gray.700"
        _groupHover={{ color: 'brand.500', textDecoration: 'unset' }}
        w="full"
        target="_blank"
      >
        <HStack justifyContent="space-between">
          {isTextTruncated ? (
            <Tooltip label={template.name} hasArrow>
              {content}
            </Tooltip>
          ) : (
            content
          )}

          <Icon
            icon="arrow-up-right-from-square"
            size="3"
            color="brand.500"
            _groupHover={{ display: 'block' }}
            display="none"
          />
        </HStack>
      </Link>
    </Flex>
  );
};
