import angular from 'angular';
import { WidgetUtils } from '@process-street/subgrade/process';

angular.module('frontStreetApp.services').service('RequiredFieldService', function ($rootScope, RequiredFieldEvent) {
  /**
   * The service is replicating behaviour of the BE methods of RequiredFieldService
   */

  const self = this;
  self._getAllInvalidByTaskTemplateGroupId = function (taskTemplateGroupId, formFieldWidgetsMap, formFieldValueMap) {
    if (!formFieldWidgetsMap[taskTemplateGroupId]) {
      return [];
    }

    return formFieldWidgetsMap[taskTemplateGroupId].reduce((invalidWidgets, widget) => {
      const formFieldValue = formFieldValueMap[widget.header.id];

      const fieldValue = formFieldValue && formFieldValue.fieldValue;
      if (widget.required) {
        if (WidgetUtils.isFormFieldValueEmpty(widget, fieldValue)) {
          invalidWidgets.push(widget);
        }
      }
      return invalidWidgets;
    }, []);
  };

  self.getInvalidCountMapUsingFormFieldValue = function (taskTemplates, formFieldWidgetsMap, formFieldValueMap) {
    return taskTemplates.reduce((countMap, taskTemplate) => {
      const taskTemplateGroupId = taskTemplate.group.id;
      countMap[taskTemplateGroupId] = self._getAllInvalidByTaskTemplateGroupId(
        taskTemplateGroupId,
        formFieldWidgetsMap,
        formFieldValueMap,
      ).length;
      return countMap;
    }, {});
  };

  self.getInvalidCountMapUsingTaskStats = function (taskTemplates, taskMap, taskStatsMap) {
    return taskTemplates.reduce((countMap, taskTemplate) => {
      const groupId = taskTemplate.group.id;
      const task = taskMap[groupId];
      const taskStats = task ? taskStatsMap[task.id] : null;
      countMap[groupId] = (taskStats && taskStats.invalidFieldsCount) || 0;
      return countMap;
    }, {});
  };

  self.getAllInvalidFormFieldsMap = function (taskTemplates, formFieldWidgetsMap, formFieldValueMap) {
    return taskTemplates.reduce((invalidFormFieldsMap, taskTemplate) => {
      const taskTemplateGroupId = taskTemplate.group.id;
      invalidFormFieldsMap[taskTemplateGroupId] = self._getAllInvalidByTaskTemplateGroupId(
        taskTemplateGroupId,
        formFieldWidgetsMap,
        formFieldValueMap,
      );
      return invalidFormFieldsMap;
    }, {});
  };

  self.broadcastChecklistHasInvalidFormFields = function (invalidFormFields) {
    const data = {
      invalidFormFields: invalidFormFields || [],
    };

    $rootScope.$broadcast(RequiredFieldEvent.CHECKLIST_HAS_INVALID_FORM_FIELDS, data);
  };

  self.broadcastTaskHasInvalidFormFields = function (taskTemplate, invalidFormFields) {
    const data = {
      taskTemplate,
      invalidFormFields: invalidFormFields || [],
    };

    $rootScope.$broadcast(RequiredFieldEvent.TASK_HAS_INVALID_FORM_FIELDS, data);
  };

  /**
   * Gets a map of invalid form fields with a task template group id as a key
   *
   * @param taskTemplates List of task templates
   * @param formFieldWidgetsMap Map that holds lists of form field widgets with task template group id as a key
   * @param formFieldValueMap Map of form field values with widget header id as a key
   * @param taskMap Map of tasks with task template group id as a key
   *
   * @returns A map of form field widgets for which the value considered invalid,
   * with a task template group id as a key
   */
  self.getAllTaskToInvalidFieldsMap = function (taskTemplates, formFieldWidgetsMap, formFieldValueMap, taskMap) {
    const taskToInvalidFieldsMap = {};

    const invalidFormFieldsMap = self.getAllInvalidFormFieldsMap(taskTemplates, formFieldWidgetsMap, formFieldValueMap);

    taskTemplates.forEach(taskTemplate => {
      const invalidFormFields = invalidFormFieldsMap[taskTemplate.group.id] || [];

      if (
        !(taskMap[taskTemplate.group.id] && taskMap[taskTemplate.group.id].hidden) &&
        invalidFormFields &&
        invalidFormFields.length > 0
      ) {
        taskToInvalidFieldsMap[taskTemplate.group.id] = invalidFormFields;
      }
    });

    return taskToInvalidFieldsMap;
  };

  /**
   * Gets a list of invalid form fields for a given task
   *
   * @param task Task of interest
   * @param formFieldWidgetsMap Map that holds lists of form field widgets with task template group id as a key
   * @param formFieldValueMap Map of form field values with widget header id as a key
   * @param taskMap Map of tasks with task template group id as a key
   *
   * @returns A list of form field widgets for which the value considered invalid
   */
  self.getTaskInvalidFieldsByFormFieldValues = function (task, formFieldWidgetsMap, formFieldValueMap, taskMap) {
    const invalidFieldsMap = self.getAllTaskToInvalidFieldsMap(
      [task.taskTemplate],
      formFieldWidgetsMap,
      formFieldValueMap,
      taskMap,
    );

    return invalidFieldsMap[task.taskTemplate.group.id];
  };

  self.getTaskInvalidFieldsByTaskStats = function (task, taskStats) {
    // Creates placeholders that emulate form fields based on task stats (Inbox case)

    const invalidFormFieldsPlaceholders = [];
    for (let i = 0; i < taskStats.invalidFieldsCount; i += 1) {
      invalidFormFieldsPlaceholders.push({ id: i, header: { taskTemplate: { id: task.taskTemplate.id } } });
    }

    return invalidFormFieldsPlaceholders;
  };
});
