import { useCanAddTemplateQuery } from './query';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { useOrganizationMembershipRole } from '../use-organization-membership-role';
import { useOnPlanTrack } from 'services/use-plan-track';
import { PlanTrack } from '@process-street/subgrade/billing';

export const useShouldBlockCreateTemplate = (): {
  shouldBlock: boolean;
  isLoading: boolean;
  role: OrganizationMembershipRole | undefined;
} => {
  const onFreemium = useOnPlanTrack(PlanTrack.Freemium);
  const isTemplateQueryEnabled = onFreemium;
  const role = useOrganizationMembershipRole();
  const isFreeUser = role === OrganizationMembershipRole.FreeMember;
  const { data, isLoading: isTemplateQueryLoading } = useCanAddTemplateQuery({ enabled: isTemplateQueryEnabled });
  const shouldBlockAction = Boolean(isFreeUser || (isTemplateQueryEnabled && role && data && data.canAdd === false));
  const isLoading = !role || (isTemplateQueryEnabled && isTemplateQueryLoading);
  return { shouldBlock: shouldBlockAction, isLoading, role };
};
