import * as React from 'react';
import {
  FieldType,
  FormFieldWidget,
  isFormFieldWidget,
  TaskTemplate,
  TemplateRevision,
  WidgetGroup,
} from '@process-street/subgrade/process';
import {
  TaskPermissionRule,
  TaskPermissionRuleSourceType,
  UpdateTaskTemplatePermit,
} from '@process-street/subgrade/permission';
import { Muid, MuidUtils } from '@process-street/subgrade/core';
import {
  Box,
  HStack,
  Icon,
  Link,
  PopoverBody,
  PopoverCloseButton,
  PopoverHeader,
  Text,
  VStack,
} from 'components/design/next';
import { useQueryClient } from 'react-query';
import {
  DeleteAllTaskTemplateRulesMutation,
  GetTaskTemplatePermitsQuery,
  GetTaskTemplateRulesQuery,
  UpdateAllTaskTemplatesPermitsMutation,
  UpdateAllTaskTemplatesRulesMutation,
} from 'features/template-revisions/query-builder';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { usePredefinedGroupMemberships } from '../hooks/use-predefined-group-memberships';
import { AddNewPermissionSearchBox } from './add-new-permission-search-box/add-new-permission-search-box';
import { useGetMembershipsOptions } from './add-new-permission-search-box/hooks/use-get-membership-options';
import { useGetRulesOptions } from './add-new-permission-search-box/hooks/use-get-rules-options';
import { MembershipPermissionItem } from './membership-permission-item';
import { RulePermissionItem } from './rule-permission-item';
import { useMatch } from '@process-street/adapters/navigation';

export type TaskPermissionsPopoverContentProps = {
  organizationId: Muid;
  taskTemplates: TaskTemplate[];
  templateRevisionId: TemplateRevision['id'];
};

export const TaskPermissionsPopoverContent = ({
  organizationId,
  taskTemplates,
  templateRevisionId,
}: TaskPermissionsPopoverContentProps) => {
  const isEditorV2 = Boolean(useMatch('templateV2')) || Boolean(useMatch('templateViewV2'));
  const taskTemplatesGroupIdsSet = new Set(taskTemplates.map(taskTemplate => taskTemplate.group.id));

  const queryClient = useQueryClient();
  const { membershipsWithoutPermitsOptions, membershipsWithPermits, allMemberships } = useGetMembershipsOptions({
    taskTemplates,
    templateRevisionId,
    organizationId,
  });

  const updateAllTaskTemplatesPermitsMutation = UpdateAllTaskTemplatesPermitsMutation.useMutation({
    onSuccess: _ => {
      queryClient.invalidateQueries(GetTaskTemplatePermitsQuery.getKey({ templateRevisionId }));
    },
  });

  const updateAllTaskTemplatesRulesMutation = UpdateAllTaskTemplatesRulesMutation.useMutation({
    onSuccess: _ => {
      queryClient.invalidateQueries(GetTaskTemplateRulesQuery.getKey({ templateRevisionId }));
    },
  });

  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevisionId, {
    select: widgets =>
      widgets.filter(
        (widget): widget is FormFieldWidget =>
          isFormFieldWidget(widget) && (widget.fieldType === FieldType.Email || widget.fieldType === FieldType.Members),
      ),
  });

  const widgets = React.useMemo(() => {
    if (widgetsQuery.data) {
      return widgetsQuery.data;
    }
    return [];
  }, [widgetsQuery.data]);

  const handleAddPermit = (userId: Muid) => {
    const membership = allMemberships.find(membership => membership.user.id === userId);

    if (membership) {
      const permits: Array<UpdateTaskTemplatePermit> = taskTemplates.map(taskTemplate => ({
        organizationMembershipId: membership.id,
        taskRead: true,
        taskUpdate: true,
        taskTemplateId: taskTemplate.id,
        templateRevisionId: taskTemplate.templateRevision.id,
      }));

      updateAllTaskTemplatesPermitsMutation.mutate({
        templateRevisionId,
        permits,
      });
    }
  };

  const handleRemovePermit = (membershipId: Muid) => {
    const permits: Array<UpdateTaskTemplatePermit> = taskTemplates.map(taskTemplate => ({
      organizationMembershipId: membershipId,
      taskRead: false,
      taskUpdate: false,
      taskTemplateId: taskTemplate.id,
      templateRevisionId: taskTemplate.templateRevision.id,
    }));

    updateAllTaskTemplatesPermitsMutation.mutate({
      templateRevisionId,
      permits,
    });
  };
  const { assignableRuleOptions, allAssignableTaskPermissionRules, existingRules } = useGetRulesOptions({
    taskTemplates,
    templateRevisionId,
    organizationId,
  });
  const { allMembersMembership, allGuestsMembership, allAnonymousMembership } = usePredefinedGroupMemberships({
    organizationId,
  });
  const deleteAllTaskTemplatesRulesMutation = DeleteAllTaskTemplateRulesMutation.useMutation({
    onSuccess: _ => {
      queryClient.invalidateQueries(GetTaskTemplateRulesQuery.getKey({ templateRevisionId }));
    },
  });

  const handleRemoveRule = (sourceType: TaskPermissionRuleSourceType, sourceFormFieldWidgetGroup?: WidgetGroup) => {
    // find matching rules for selected task templates (bulk)
    const rulesToRemove = existingRules.filter(
      rule =>
        taskTemplatesGroupIdsSet.has(rule.targetTaskTemplateGroup.id) &&
        rule.sourceType === sourceType &&
        rule.sourceFormFieldWidgetGroup === sourceFormFieldWidgetGroup,
    );
    const ids = rulesToRemove.map(rule => rule.id);
    deleteAllTaskTemplatesRulesMutation.mutate({
      templateRevisionId,
      ids,
    });
  };

  const handleAddRule = (ruleId: Muid) => {
    const rule = allAssignableTaskPermissionRules.find(rule => ruleId === rule.id);

    if (rule) {
      const rules: Array<TaskPermissionRule> = taskTemplates.map(taskTemplate => {
        const isExistingRule = rule.targetTaskTemplateGroup.id === taskTemplate.group.id;
        if (isExistingRule) {
          return rule;
        } else {
          return {
            id: MuidUtils.randomMuid(),
            organization: rule.organization,
            templateRevision: rule.templateRevision,
            targetTaskTemplateGroup: taskTemplate.group,
            sourceType: rule.sourceType,
            sourceFormFieldWidgetGroup: rule.sourceFormFieldWidgetGroup,
            taskRead: true,
            taskUpdate: true,
          };
        }
      });

      updateAllTaskTemplatesRulesMutation.mutate({
        templateRevisionId,
        rules,
      });
    }
  };

  const options = React.useMemo(() => {
    return [
      { label: 'Users', options: membershipsWithoutPermitsOptions },
      { label: 'Roles', options: assignableRuleOptions },
    ];
  }, [membershipsWithoutPermitsOptions, assignableRuleOptions]);

  return (
    <>
      <PopoverHeader p="0" mb="2" mx="3">
        <Text variant="-1" color="gray.700" fontWeight="medium" fontSize={isEditorV2 ? 'lg' : 'inherit'}>
          Task permissions
        </Text>
        <PopoverCloseButton
          padding="0"
          textTransform="none"
          borderStyle="none"
          outline="initial"
          m="0"
          mt="1"
          cursor="pointer"
          backgroundColor="transparent"
          fontSize="xs"
          fontWeight="md"
          color="gray.500"
        />
      </PopoverHeader>

      <PopoverBody mt="0" p="3">
        <VStack alignItems="flex-start">
          <HStack spacing="2">
            <Text variant="-1" color="gray.500" fontSize={isEditorV2 ? 'md' : 'inherit'}>
              Administrators can always see and complete tasks.
            </Text>
            <Link href="https://www.process.st/help/docs/task-permissions/" isExternal>
              <Icon icon="info-circle" variant="far" size="4" color="gray.400" />
            </Link>
          </HStack>
          {allMembersMembership && (
            <MembershipPermissionItem
              membership={allMembersMembership}
              key={allMembersMembership.id}
              taskTemplates={taskTemplates}
              templateRevisionId={templateRevisionId}
              isPredefinedGroup={true}
              onRemoveMembership={handleRemovePermit}
            />
          )}
          {allGuestsMembership && (
            <MembershipPermissionItem
              membership={allGuestsMembership}
              key={allGuestsMembership.id}
              taskTemplates={taskTemplates}
              templateRevisionId={templateRevisionId}
              isPredefinedGroup={true}
              onRemoveMembership={handleRemovePermit}
            />
          )}
          {allAnonymousMembership && (
            <MembershipPermissionItem
              membership={allAnonymousMembership}
              key={allAnonymousMembership.id}
              taskTemplates={taskTemplates}
              templateRevisionId={templateRevisionId}
              isPredefinedGroup={true}
              onRemoveMembership={handleRemovePermit}
            />
          )}
        </VStack>
        <AddNewPermissionSearchBox
          handleAddPermit={handleAddPermit}
          handleAddRule={handleAddRule}
          options={options}
          maxWidth="70"
          pt="4"
        />

        <Text casing="uppercase" color="gray.500" variant="-2u" pt="4">
          Specific permissions
        </Text>
        <Box w="full">
          <Box maxH="50" overflowY="auto" mx="-4">
            <VStack alignItems="flex-start" spacing="2" pt="2" px="4">
              {existingRules.map(rule => (
                <RulePermissionItem
                  rule={rule}
                  taskTemplates={taskTemplates}
                  widgets={widgets}
                  templateRevisionId={templateRevisionId}
                  key={rule.id}
                  onRemoveRule={handleRemoveRule}
                />
              ))}
              {membershipsWithPermits.map(membership => (
                <MembershipPermissionItem
                  membership={membership}
                  key={membership.id}
                  taskTemplates={taskTemplates}
                  templateRevisionId={templateRevisionId}
                  isPredefinedGroup={false}
                  onRemoveMembership={handleRemovePermit}
                />
              ))}
            </VStack>
          </Box>
        </Box>
      </PopoverBody>
    </>
  );
};
