import angular from 'angular';
import { ArrayService } from 'services/array-service';
import { UserType } from '@process-street/subgrade/core';

/**
 * @deprecated use organizationMembershipService.isAdminByUserIdAndOrganizationId
 */
angular.module('frontStreetApp.services').factory('Subject', (DataService, FolderService, UserService) => {
  'ngInject';

  function Subject(userId, organizationId) {
    this.userId = userId;
    this.organizationId = organizationId;

    this._memberships = UserService.getOrganizationMemberships(userId, organizationId);

    const [userMembership] = this._memberships;
    if (!this._memberships.length) {
      this.admin = false;
      this.guest = false;
      this.developer = false;
    } else {
      this.admin = userMembership.admin;
      this.guest = userMembership.guest;

      const user = DataService.getCollection('users').get(userId);
      this.developer = user && user.developer;
      this.anonymous = user && user.userType === UserType.Anonymous;
    }

    this._cache = {};
  }

  // Membership

  Subject.prototype.isAdmin = function () {
    return this.admin;
  };

  Subject.prototype.isGuest = function () {
    return this.guest;
  };

  Subject.prototype.isDeveloper = function () {
    return this.developer;
  };

  // Folder

  Subject.prototype.canUpdateFolder = function (folderId) {
    return this.checkFolderPermission(folderId, 'folderUpdate');
  };

  Subject.prototype.canDeleteFolder = function (folderId) {
    return this.checkFolderPermission(folderId, 'folderDelete');
  };

  // Helpers

  Subject.prototype.checkFolderPermission = function (folderId, key) {
    const cacheKey = ['folder', folderId, key].join(':');
    const cached = cacheKey in this._cache;

    if (!cached) {
      const folder = DataService.getCollection('folders').get(folderId);
      if (folder && folder.organization.id === this.organizationId) {
        this._cache[cacheKey] = this.admin || Subject._hasFolderPermissions(this._memberships, folder.id, key);
      }
    }

    return this._cache[cacheKey];
  };

  Subject.prototype.checkTemplatePermission = function (templateId, key) {
    const cacheKey = ['template', templateId, key].join(':');
    const cached = cacheKey in this._cache;

    if (!cached) {
      const template = DataService.getCollection('templates').get(templateId);
      if (template && template.organization.id === this.organizationId) {
        this._cache[cacheKey] =
          this.admin ||
          Subject._hasFolderPermissions(this._memberships, template.folder.id, key) ||
          Subject._hasPermission(this._memberships, 'template', template.id, key);
      }
    }

    return this._cache[cacheKey];
  };

  Subject._hasPermission = function (memberships, type, id, key) {
    return DataService.getCollection(`${type}Permits`).some(
      permit =>
        permit[type].id === id &&
        permit[key] === true &&
        ArrayService.includes(memberships, permit.organizationMembership, 'id'),
    );
  };

  Subject._hasFolderPermissions = function (memberships, folderId, key) {
    const folder = DataService.getCollection('folders').get(folderId);

    let currentFolderPermissions = false;
    if (Subject._hasPermission(memberships, 'folder', folder.id, key)) {
      currentFolderPermissions = true;
    } else {
      const parentFolder = FolderService.getParentFolder(folder);

      if (parentFolder) {
        currentFolderPermissions = Subject._hasFolderPermissions(memberships, parentFolder.id, key);
      }
    }

    return currentFolderPermissions;
  };

  return Subject;
});
