import { RichEmailWidgetAttachmentWithS3File } from '@process-street/subgrade/process';
import React from 'react';
import { Flex, HStack, Link, Spacer, Icon, IconButton, Text, FlexProps } from 'components/design/next';
import prettyBytes from 'pretty-bytes';

export type EmailAttachmentItemProps = {
  attachment: RichEmailWidgetAttachmentWithS3File;
  onDelete?: (attachment: RichEmailWidgetAttachmentWithS3File) => void;
  mode?: 'view' | 'edit';
} & FlexProps;

export const EmailAttachmentItem: React.FC<React.PropsWithChildren<EmailAttachmentItemProps>> = ({
  attachment,
  onDelete,
  mode = 'edit',
  ...flexProps
}) => {
  const file = attachment.s3File;
  const size = prettyBytes(file.size ?? 0);

  const handleDelete = () => {
    if (onDelete) {
      onDelete(attachment);
    }
  };

  return (
    <Flex
      w="full"
      alignItems="center"
      paddingLeft={5}
      backgroundColor={mode === 'edit' ? 'gray.50' : 'brand.100'}
      {...flexProps}
    >
      <Link href={file.url} isExternal color="brand.500" variant="noline">
        <HStack marginY={1}>
          <Text variant="1" color="brand.500">
            {file.originalName}
          </Text>
          <Text variant="1" color="brand.500" paddingLeft={1}>
            ({size})
          </Text>
        </HStack>
      </Link>
      <Spacer />

      {mode === 'edit' && (
        <IconButton
          variant="ghost"
          size="sm"
          color="gray.500"
          icon={<Icon size="4" variant="far" icon="trash-can" />}
          aria-label="delete attachment"
          onClick={handleDelete}
        />
      )}
    </Flex>
  );
};
