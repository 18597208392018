import angular from 'angular';
import { PermitConstants } from '@process-street/subgrade/permission/permit-constants';
import { UserStatus } from '@process-street/subgrade/core';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { isAnonymousUser, isGroupUser } from '@process-street/subgrade/util/user-type-utils';
import { EmailService } from 'services/email-service';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'ChecklistManageMembersCtrl',
    function (
      $scope,
      $state,
      DataService,
      PermitService,
      SecurityService,
      SessionService,
      Subject,
      UserService,
      ToastService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'ChecklistManageMembersCtrl' });
      logger.info('loading ctrl');

      ctrl.$onInit = () => {
        initializeChecklist();
        initializeMemberships();
      };

      function initializeChecklist() {
        // We need the template too for calculating inherited permissions
        $scope.checklist = DataService.getCollection('checklists').getWithTemplateById($state.params.id);
      }

      function initializeMemberships() {
        PermitService.getAllAndInherited(PermitConstants.Type.CHECKLIST, $state.params.id).then(
          result => {
            const user = UserService.getCurrentUser();
            const subject = new Subject(user.id, SecurityService.getSelectedOrganizationIdByUser(user));

            $scope.memberships = PermitService.initializeMemberships(result.permits).filter(
              membership => !isAnonymousUser(membership.user),
            );

            const foldersCollection = DataService.getCollection('folders');
            const templatesCollection = DataService.getCollection('templates');

            $scope.inheritedPermits = result.inheritedPermits
              .filter(permit => {
                // Filter out folders we don't have access to and the Home folder
                const template = templatesCollection.get(permit.template && permit.template.id);
                const folder = foldersCollection.get(permit.folder && permit.folder.id);
                return template || (folder && folder.parentFolder);
              })
              .filter(
                permit =>
                  !isAnonymousUser(permit.organizationMembership.user) &&
                  (!subject.isGuest() ||
                    !permit.organizationMembership.guest ||
                    $scope.isCurrentUser(permit.organizationMembership)),
              );

            $scope.inheritedPermits.forEach(permit => {
              const template = templatesCollection.get(permit.template && permit.template.id);
              const folder = foldersCollection.get(permit.folder && permit.folder.id);
              permit._resourceName = (template && template.name) || (folder && folder.name);
              permit._level = PermitService.getLevel(permit);
            });
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading the members for the workflow`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          },
        );
      }

      $scope.viewPermit = function (permit) {
        if (permit.template) {
          $state.go('workflowManage.tab', { id: permit.template.id, tab: 'members' });
        } else if (permit.folder) {
          $state.go('folderManage.tab', { id: permit.folder.id, tab: 'permissions' });
        } else {
          logger.error('unknown permit: %s', permit.id);
        }
      };

      // Utilities

      $scope.expandEmailAddress = EmailService.expandEmailAddress;

      $scope.isCurrentUser = function (membership) {
        const user = UserService.getCurrentUser();
        return user.id === membership.user.id;
      };

      $scope.isCreated = status => status === UserStatus.Created;

      $scope.isGroupUser = user => isGroupUser(user);
    },
  );
