import { Muid } from '@process-street/subgrade/core';

export * from './create-cached-action';
export * from './handle-actions-on-success';
export * from './compose-reducer-object';
export * from './bind-action-creators-to-actions';
export * from './bind-state-to-state-property';
export * from './connect-service';
export * from './optimistic-action';
export * from './concat-in-dictionary-array';
export * from './connect-controller';
export * from './status-state';
export * from './handlers';

export const toArray = <T>(obj: T): T[] => {
  if (obj === null || obj === undefined) {
    return [];
  }
  return Array.isArray(obj) ? obj : [obj];
};

export const setDeletedByIds = (state: any, ids: Muid[], deleted = true) => {
  const newState = Object.assign({}, state);
  ids.forEach(id => {
    if (deleted) {
      newState[id]._deleted = deleted;
    } else {
      delete newState[id]._deleted;
    }
  });
  return newState;
};
