import { Icon, ListItem, ListItemProps, Text } from 'components/design/next';
import * as React from 'react';

type PricingDetailProps = ListItemProps & {
  iconColor: string;
  checkIcon?: boolean;
};

export const PricingDetail = (props: PricingDetailProps) => {
  const { children, iconColor, checkIcon = true, ...rest } = props;
  return (
    <ListItem display="flex" alignItems="flex-start" fontWeight="medium" {...rest}>
      {checkIcon ? (
        <Icon color={iconColor} icon="check" variant="fas" size="4" mt={1} mr={2} />
      ) : (
        <Icon color={iconColor} icon="circle" variant="fas" size="2" mt={2} mr={2} />
      )}

      <Text as="span" display="inline-block" color="gray.600" fontWeight="normal">
        {children}
      </Text>
    </ListItem>
  );
};
