import classNames from 'classnames';
import { Icon, IconSize, IconType } from 'components/design/Icon';
import React, { useEffect, useState } from 'react';
import styles from './BlvdCheckbox.module.scss';

export interface BlvdCheckboxProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  className?: string;
}

export const BlvdCheckbox: React.FunctionComponent<React.PropsWithChildren<BlvdCheckboxProps>> = props => {
  const [checked, setChecked] = useState(props.checked || false);
  useEffect(() => {
    setChecked(props.checked || false);
  }, [props.checked]);

  const toogleCheck = () => {
    const value = !checked;
    setChecked(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const containerClasses = classNames({
    [styles.blvdCheckbox]: true,
    [props.className || 'forgetit']: !!props.className,
  });

  const classes = classNames({
    [styles.checkbox]: true,
    [styles.checked]: checked,
    [styles.unchecked]: !checked,
  });

  return (
    <div className={containerClasses} onClick={toogleCheck} role="checkbox">
      <div className={classes}>{checked && <Icon size={IconSize.Small} iconType={IconType.Check} />}</div>
      <span className={styles.label}>{props.children}</span>
    </div>
  );
};
