import { Folder } from '@process-street/subgrade/process';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateFolderParams = { parentFolderId: Folder['id']; name: string; id: Folder['id'] };
export type CreateFolderResult = Folder;

export const CreateFolder = {
  mutationFn: async (params: CreateFolderParams): Promise<CreateFolderResult> => {
    return axiosService
      .getAxios()
      .put<CreateFolderResult>(`/1/folders/${params.id}`, params)
      .then(res => res.data);
  },
};

export const useCreateFolderMutation = (
  options: UseMutationOptions<CreateFolderResult, Error, CreateFolderParams> = {},
) => {
  return useMutation<CreateFolderResult, Error, CreateFolderParams>(CreateFolder.mutationFn, options);
};
