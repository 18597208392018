import {
  AutomationLog,
  AutomationLogStatus,
  IncomingWebhook,
  IncomingWebhookLog,
  IncomingWebhookOptimistic,
  SolutionTypeTag,
} from '@process-street/subgrade/automation';
import { Box, Divider, useToast } from 'components/design/next';
import {
  GetAllIncomingWebhooksByDataSetIdQuery,
  GetIncomingWebhookLogsQuery,
} from 'features/automations/query-builder';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { match, P } from 'ts-pattern';
import { DataSetNativeAutomationEditor } from './components/editor/data-set-native-automation-editor';
import { IncomingWebhookEditor } from './components/editor/incoming-webhook-editor';
import { AutomationLogList } from './components/log-list';
import { useAutomationSelector } from './components/selector/context';
import { AutomationTopBar } from './components/top-bar';
import { useDataSetId } from './utils/use-data-set-id';

export type DataSetAutomationDetailsProps = {
  closeModal: () => void;
  webhook: IncomingWebhook | IncomingWebhookOptimistic;
};

export const DataSetAutomationDetails: React.FC<React.PropsWithChildren<DataSetAutomationDetailsProps>> = ({
  closeModal,
  webhook,
}) => {
  const [state] = useAutomationSelector();
  const queryClient = useQueryClient();
  const dataSetId = useDataSetId();
  const toast = useToast();

  const webhookLogsQuery = GetIncomingWebhookLogsQuery.useQuery({
    webhookId: webhook.id,
  });

  const {
    solutionTypeTag: selectedSolutionType,
    automationType: selectedAutomationType,
    automationInstanceId: selectedAutomationInstanceId,
  } = state.context;
  const hasMappedColumns = match(webhook.config)
    .with({ dataSetColumns: P.not(P.nullish) }, config => Object.keys(config.dataSetColumns).length > 0)
    .otherwise(() => false);

  const handleDelete = () => {
    if (selectedAutomationType === 'webhook') {
      closeModal();
    }

    queryClient.invalidateQueries(GetAllIncomingWebhooksByDataSetIdQuery.getKey({ dataSetId }));
    queryClient.invalidateQueries(GetAllNativeAutomationsQuery.getKey({ dataSetId }));
    toast({
      title: 'Automation deleted',
      status: 'success',
    });
  };

  return (
    <Box w="full" p="6" maxH="75vh" overflowY="scroll">
      <AutomationTopBar onDelete={handleDelete} />

      <Divider mt="2" mb="6" />

      {match({ selectedSolutionType })
        .with({ selectedSolutionType: SolutionTypeTag.CreateChecklistWhen }, () => (
          <DataSetNativeAutomationEditor
            key={selectedAutomationInstanceId}
            nativeAutomationId={selectedAutomationInstanceId}
          />
        ))
        .otherwise(() => (
          <>
            <IncomingWebhookEditor webhookId={webhook.id} initialState={hasMappedColumns ? 'editing' : undefined} />

            {match(webhook.config)
              .with({ dataSetColumns: P.not(P.nullish) }, config => {
                const hasFieldsMapped = Object.keys(config.dataSetColumns).length > 0;
                if (!hasFieldsMapped && !webhookLogsQuery.data?.length) return null;

                return (
                  <>
                    <Divider my="8" />
                    <AutomationLogList
                      isLoaded={webhookLogsQuery.isSuccess}
                      isLoading={webhookLogsQuery.isLoading}
                      logs={(webhookLogsQuery.data ?? []).map(toAutomationLog)}
                    />
                  </>
                );
              })
              .otherwise(() => null)}
          </>
        ))}
    </Box>
  );
};

const toAutomationLog = (webhookLog: IncomingWebhookLog): AutomationLog => ({
  id: webhookLog.id,
  eventDate: webhookLog.createdDate,
  status: match(webhookLog.status)
    .with('Error', () => AutomationLogStatus.Failure)
    .with('Ok', () => AutomationLogStatus.Success)
    .otherwise(() => AutomationLogStatus.Processing),
  templateSolutionInstanceId: webhookLog.webhookId,
  error: webhookLog.error,
});
