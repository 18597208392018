import * as React from 'react';
import { Spinner, Center, Text, Fade } from 'components/design/next';
import { useTimeoutFn } from 'react-use';

export const ChartSpinner = () => {
  const [longQuery, setLongQuery] = React.useState(false);

  useTimeoutFn(() => setLongQuery(true), 3000);

  return (
    <Center w="full" h="full" flexDirection="column">
      <Spinner size="xl" />
      <Fade in={longQuery}>
        <Text color="gray.500" variant="-2" fontWeight="medium" mt="4">
          Sit tight - this may take a while
        </Text>
      </Fade>
    </Center>
  );
};
