import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { TemplateRevision } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import {
  useQuery as useRQ,
  QueryKey,
  UseQueryOptions,
  QueryClient,
  QueryObserverOptions,
  QueryObserver,
} from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetAllRulesByTemplateRevisionIdQuery {
  export type Params = {
    templateRevisionId?: TemplateRevision['id'];
  };

  export type Response = { definitions: ChecklistRuleDefinition[] };

  export const key = ['templateRevision', 'rules'];

  export const getKey = ({ templateRevisionId }: Params): QueryKey => [...key, templateRevisionId];

  export const queryFn = ({ templateRevisionId }: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/template-revisions/${templateRevisionId}/rule-definitions?v=4`)
      .then(r => r.data);

  export const useQuery = (params: Params, options?: UseQueryOptions<Response, AxiosError>) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.templateRevisionId) && options?.enabled !== false,
    });
  };

  export const makeQueryObserver = ({
    queryClient,
    options,
    ...params
  }: Params & {
    queryClient: QueryClient;
    options?: QueryObserverOptions<Response, AxiosError>;
  }) => {
    return new QueryObserver<Response, AxiosError>(queryClient, {
      ...options,
      queryKey: getKey(params),
      queryFn: () => queryFn(params),
      enabled: Boolean(params.templateRevisionId) && options?.enabled !== false,
    });
  };
}
