import React from 'react';

import { isNotIdRef, S3File, User } from '@process-street/subgrade/core';

import { Box, HStack, Icon, Link, ListItem, Text, VStack } from 'components/design/next';

import { CDNImage } from 'components/cdn-image';
import { AuditInfoStack } from '../audit-info';
import { isImageFile } from 'features/upload/components/utils';
import { AttachmentFile } from '../attachment/attachment-file';
import { useInjector } from 'components/injection-provider';
import { Activity, AttachmentActivityData } from '@process-street/subgrade/activity';
import { useGetOrganizationQuery } from 'features/organization/query-builder';

export type OverviewAttachmentItemProps = {
  activity: Activity;
  currentUserId: User['id'];
  type: 'checklist' | 'template';
};

export const OverviewAttachmentItem: React.FC<React.PropsWithChildren<OverviewAttachmentItemProps>> = ({
  activity,
  type,
}) => {
  const { $state } = useInjector('$state');

  const { data, date, subject, id } = activity;

  const {
    fileUrl,
    fileOriginalName,
    fileSize,
    checklistId,
    checklistName,
    taskTemplateName,
    attachmentId,
    taskTemplateGroupId,
    fileMimeType,
    fileExtension,
    fileName,
  } = data as AttachmentActivityData;

  const createdBy = isNotIdRef(subject) ? subject : ({} as User);

  const organizationId = activity.organization.id;
  const organizationQuery = useGetOrganizationQuery({ organizationId });
  const isEnhancedFileSecurityEnabled = organizationQuery.data?.enhancedFileSecurity;

  return (
    <ListItem
      key={id}
      onClick={e => {
        e.stopPropagation();
        $state.go('checklist.task.comment', {
          id: checklistId,
          groupId: taskTemplateGroupId,
          commentId: attachmentId,
        });
      }}
      cursor="pointer"
    >
      <HStack alignItems="flex-start" spacing="3" width="full" justifyContent="space-between" minW="0" flex="1">
        <VStack alignItems="flex-start" width="full" spacing="2">
          <AuditInfoStack {...{ createdBy, createdDate: date }} />
          {isImageFile(fileMimeType) ? (
            <Box position="relative" pl="9" width="80%">
              <Link href={fileUrl} target="_blank">
                <Box position="relative" width="full" height="full">
                  <CDNImage
                    {...{
                      isDisabled: isEnhancedFileSecurityEnabled,
                      s3File: {
                        name: fileOriginalName,
                        mimeType: fileMimeType,
                        url: fileUrl,
                        key: fileName,
                      } as S3File,
                      alt: fileOriginalName,
                      transformation: {
                        width: 'auto',
                        height: 300,
                      },
                      maxHeight: '300px',
                    }}
                  />
                </Box>
              </Link>
            </Box>
          ) : (
            <AttachmentFile
              {...{
                file: {
                  url: fileUrl,
                  originalName: fileOriginalName,
                  size: fileSize,
                  extension: fileExtension,
                },
              }}
              pl="9"
            />
          )}
          {type === 'checklist' ? (
            <Text variant="-2" color="gray.500" pl="8">
              {taskTemplateName}
            </Text>
          ) : (
            <HStack pl="8">
              <Icon size="3" icon="play" variant="far" color="gray.300" />
              <Text variant="-2" color="gray.500">
                {checklistName}
              </Text>
            </HStack>
          )}
        </VStack>
      </HStack>
    </ListItem>
  );
};
