import * as React from 'react';
import { VStack, SkeletonCircle, HStack, Skeleton, Center, Divider } from 'components/design/next';

export const CommentSkeleton = () => {
  return (
    <HStack
      border="1px"
      borderColor="gray.200"
      borderRadius="sm"
      px={{ base: 4, lg: 6 }}
      py={{ base: 3, lg: 4 }}
      bg="white"
      width="full"
      height="min-content"
      mt="0"
      minW="0"
      flex="1"
      alignItems="flex-start"
    >
      <HStack spacing="6" alignItems="flex-start" width="full">
        <SkeletonCircle size="10"></SkeletonCircle>
        <VStack alignItems="flex-start" minW="0" width="full" flex="1" spacing="4">
          <HStack justifyContent="space-between" alignItems="flex-start" width="full">
            <Skeleton height="8" width="80" />
          </HStack>
          <HStack minW="0" flex="1" mb="4">
            <Skeleton height="4" width="60"></Skeleton>
            <Center height="3">
              <Divider borderColor="gray.500" borderLeftWidth="0.5" orientation="vertical" />
            </Center>
            <Skeleton height="4" width="60"></Skeleton>
          </HStack>
          <Skeleton height="4" width="full"></Skeleton>
          <Skeleton height="4" width="full"></Skeleton>
        </VStack>
      </HStack>
    </HStack>
  );
};
