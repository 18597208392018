const BulkResponse = {
  OK: 'Ok',
  CONFLICT: 'Conflict',
  NOT_FOUND: 'NotFound',
  FORBIDDEN: 'Forbidden',
  NOTHING_TO_UPDATE: 'NothingToUpdate',
  NOT_UPDATED: 'NotUpdated',
};

const BulkResponseCode = {
  NOT_MEMBER: 'NotMember',
  NOT_HUMAN_OR_GROUP: 'NotHumanOrGroup',
  ALL_MEMBERS_GROUP_USER: 'AllMembersGroupUser',
  NO_RIGHTS_TO_UPDATE: 'NoRightsToUpdate',
  USER_NOT_FOUND: 'UserNotFound',
  MEMBERSHIP_NOT_FOUND: 'MembershipNotFound',
};

export const TemplateTaskAssignmentConstants = {
  BulkResponse,
  BulkResponseCode,
};
