import * as React from 'react';
import { useFeatureFlag } from 'app/features/feature-flags';
import { ButtonProps } from 'components/design/next';
import { Muid } from '@process-street/subgrade/core';

import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';

export type CustomNotificationsButtonProps = {
  templateId: Muid;
  onOpen: () => void;
  children: React.ReactElement<ButtonProps>;
};

export const CustomNotificationsButton = ({ templateId, onOpen, children }: CustomNotificationsButtonProps) => {
  const isCustomNotificationsEnabled = useFeatureFlag('customNotifications');

  const templatePermissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId);
  const hasPermission = Boolean(templatePermissionsQuery.data?.permissionMap.templateUpdate);

  if (!isCustomNotificationsEnabled) return null;

  if (!React.isValidElement(children) || Array.isArray(children)) {
    throw new Error('CustomNotificationsButton child must be a single clickable element');
  }

  return <>{React.cloneElement(children, { onClick: onOpen, isDisabled: !hasPermission })}</>;
};
