import { Runnable } from '@process-street/subgrade/conditional-logic';
import { Muid } from '@process-street/subgrade/core';
import {
  Checklist,
  ChecklistRevision,
  ChecklistState,
  ChecklistWidget,
  FormFieldValue,
  FormFieldValueWithWidget,
  FormFieldWidget,
  isFormFieldWidget,
  TaskTemplate,
  TaskWithTaskTemplate,
  Widget,
} from '@process-street/subgrade/process';
import { ChecklistRulesEngineService } from 'directives/rules/checklist';
import { ActorRefFrom, createMachine, assign, spawn } from 'xstate';
import {
  FormResponseMachineReceptionist,
  makeRulesEngineTargetTaskKey,
  makeRulesEngineTargetWidgetKey,
} from '../../../form-response-machine-receptionist';
import { RulesEngineTargetActorRef } from './rules-engine-target-machine';
import {
  GetAllRulesByChecklistRevisionIdQueryResponse,
  makeGetAllRulesByChecklistRevisionIdQueryObserver,
} from 'features/conditional-logic/query-builder';
import {
  GetAllChecklistWidgetsQueryResponse,
  makeGetAllChecklistWidgetsQueryObserver,
} from 'features/widgets/query-builder';
import {
  makeQueryMachine,
  QueryActor,
  QueryActorSelectors,
  RefetchOnMountOnly,
  SystemUpdateEvent,
} from 'utils/query-builder';
import { FormResponseMachineSharedContext } from 'pages/responses/_id/types';
import { match } from 'ts-pattern';

type Context = {
  checklistState: ChecklistState;
  taskVisibilityRules: Runnable.TaskVisibilityRule[];
  formFieldValues: FormFieldValueWithWidget[];
  rulesQuery?: QueryActor<GetAllRulesByChecklistRevisionIdQueryResponse>;
  checklistWidgetsQuery?: QueryActor<GetAllChecklistWidgetsQueryResponse>;
};

type ExternalEvent = {
  type: 'RULES_UPDATE';
  taskVisibilityMap: Record<TaskTemplate['group']['id'], boolean>;
  widgetVisibilityMap: Record<ChecklistWidget['groupId'], boolean>;
};
export { ExternalEvent as RulesEngineMachineExternalEvent };

type Event =
  | {
      type: 'FORM_FIELD_VALUE_UPDATE';
      formFieldWidget: FormFieldWidget;
      formFieldValue: FormFieldValue;
    }
  | { type: 'REQUEST_VISIBILITY_STATE' }
  | SystemUpdateEvent<
      | { id: 'rules-query'; data: GetAllRulesByChecklistRevisionIdQueryResponse }
      | { id: 'checklist-widgets-query'; data: GetAllChecklistWidgetsQueryResponse }
    >;
export { Event as RulesEngineMachineEvent };

type MakeRulesEngineMachineArgs = {
  checklist: Checklist;
  checklistRevision: ChecklistRevision;
  tasks: TaskWithTaskTemplate[];
  // might be worth refactoring this to have a FFV query observer so we don't have to manually track FFV changes
  formFieldValues: FormFieldValueWithWidget[];
  widgets: Widget[];
  sharedContext: FormResponseMachineSharedContext;
};
export const makeRulesEngineMachine = ({
  checklist,
  checklistRevision,
  tasks,
  formFieldValues,
  widgets,
  sharedContext,
}: MakeRulesEngineMachineArgs) => {
  const { queryClient } = sharedContext;

  const formFieldWidgets = widgets.filter(isFormFieldWidget);

  return createMachine(
    {
      id: 'rules',
      predictableActionArguments: true,
      tsTypes: {} as import('./rules-engine-machine.typegen').Typegen0,
      schema: {
        context: {} as Context,
        events: {} as Event,
      },
      context: () => {
        const checklistState = ChecklistRulesEngineService.buildChecklistStateObject(checklist, tasks, []);
        return { taskVisibilityRules: [], checklistState, formFieldValues } as Context;
      },
      initial: 'loading',
      states: {
        loading: {
          entry: ['spawnRulesQuery', 'spawnChecklistWidgetsQuery'],
          on: {
            'xstate.update': {
              cond: 'isDataReady',
              target: 'idle',
              actions: ['assignTaskVisibilityRules', 'assignChecklistStateInitial'],
            },
          },
        },
        idle: {
          entry: ['sendTasksAndWidgetsRulesUpdate'],
          on: {
            'REQUEST_VISIBILITY_STATE': { actions: 'sendTasksAndWidgetsRulesUpdate' },
            'FORM_FIELD_VALUE_UPDATE': {
              actions: [
                'assignFormFieldValues',
                'assignTaskVisibilityRules',
                'assignChecklistState',
                'sendTasksAndWidgetsRulesUpdate',
              ],
            },
            'xstate.update': [
              {
                cond: 'isChecklistWidgetsSuccess',
                actions: ['assignChecklistState', 'sendTasksAndWidgetsRulesUpdate'],
              },
            ],
          },
        },
      },
    },
    {
      actions: {
        spawnRulesQuery: assign({
          rulesQuery: (ctx, _evt) => {
            return (
              ctx.rulesQuery ??
              spawn(
                makeQueryMachine({
                  observer: makeGetAllRulesByChecklistRevisionIdQueryObserver({
                    checklistRevisionId: checklistRevision.id,
                    queryClient,
                    options: { ...RefetchOnMountOnly },
                  }),
                }),
                { sync: true, name: 'rules-query' },
              )
            );
          },
        }),

        spawnChecklistWidgetsQuery: assign({
          checklistWidgetsQuery: (ctx, _evt) => {
            return (
              ctx.checklistWidgetsQuery ??
              spawn(
                makeQueryMachine({
                  observer: makeGetAllChecklistWidgetsQueryObserver({
                    checklistRevisionId: checklistRevision.id,
                    queryClient,
                    options: { ...RefetchOnMountOnly },
                  }),
                }),
                { sync: true, name: 'checklist-widgets-query' },
              )
            );
          },
        }),

        assignFormFieldValues: assign({
          formFieldValues: (ctx, event) => {
            let formFieldValueIsNew = true;

            const updatedFormFieldValues = ctx.formFieldValues.map(ffv => {
              if (ffv.formFieldWidget.id === event.formFieldWidget.id) {
                formFieldValueIsNew = false;
                return { ...ffv, ...event.formFieldValue } as FormFieldValueWithWidget;
              }
              return ffv;
            });

            if (formFieldValueIsNew) {
              updatedFormFieldValues.push({
                ...event.formFieldValue,
                formFieldWidget: event.formFieldWidget,
              } as FormFieldValueWithWidget);
            }
            return updatedFormFieldValues;
          },
        }),

        assignTaskVisibilityRules: assign({
          taskVisibilityRules: (ctx, _event) => {
            return ChecklistRulesEngineService.buildTaskVisibilityRules({
              formFieldValues: ctx.formFieldValues,
              formFieldWidgets,
              ruleDefinitions: QueryActorSelectors.getQueryData(ctx.rulesQuery)!.definitions,
              tasks,
            });
          },
        }),

        assignChecklistStateInitial: assign({
          checklistState: (ctx, _event) => {
            const { taskVisibilityRules } = ctx;
            const checklistWidgets = QueryActorSelectors.getQueryData(ctx.checklistWidgetsQuery)!;
            const newState = ChecklistRulesEngineService.buildChecklistStateObject(checklist, tasks, checklistWidgets);
            const checklistState = ChecklistRulesEngineService.execute(
              taskVisibilityRules,
              ChecklistRulesEngineService.resetChecklistState(
                newState,
                tasks.map(t => t.taskTemplate),
                widgets,
              ),
            );
            return checklistState;
          },
        }),

        assignChecklistState: assign({
          checklistState: (ctx, event) => {
            const { taskVisibilityRules } = ctx;
            const newState = match(event)
              .with({ type: 'xstate.update', id: 'checklist-widgets-query' }, ({ state }) => {
                const checklistWidgets = state.context.data ?? [];
                return ChecklistRulesEngineService.buildChecklistStateObject(checklist, tasks, checklistWidgets);
              })
              .otherwise(() => ctx.checklistState);

            const checklistState = ChecklistRulesEngineService.execute(taskVisibilityRules, newState);
            return checklistState;
          },
        }),

        sendTasksAndWidgetsRulesUpdate: ctx => {
          const taskVisibilityMap = ctx.checklistState.taskStates.reduce((acc, ts) => {
            acc[ts.taskTemplateGroupId] = ts.task.hidden;
            return acc;
          }, {} as Record<Muid, boolean>);

          const widgetVisibilityMap = ctx.checklistState.checklistWidgetStates.reduce((acc, cws) => {
            acc[cws.widgetGroupId] = cws.checklistWidget.hidden;
            return acc;
          }, {} as Record<Muid, boolean>);

          ctx.checklistState.checklistWidgetStates.forEach(({ widgetGroupId }) => {
            const actor = FormResponseMachineReceptionist.get<RulesEngineTargetActorRef>(
              makeRulesEngineTargetWidgetKey(widgetGroupId),
            );
            actor?.send({ type: 'RULES_UPDATE', taskVisibilityMap, widgetVisibilityMap });
          });

          ctx.checklistState.taskStates.forEach(({ taskTemplateGroupId }) => {
            const actor = FormResponseMachineReceptionist.get<RulesEngineTargetActorRef>(
              makeRulesEngineTargetTaskKey(taskTemplateGroupId),
            );
            actor?.send({ type: 'RULES_UPDATE', taskVisibilityMap, widgetVisibilityMap });
          });
        },
      },
      guards: {
        isDataReady: (ctx, _event) => {
          const queries = [ctx.rulesQuery, ctx.checklistWidgetsQuery];
          return queries.every(query => QueryActorSelectors.getQueryData(query) !== undefined);
        },
        isChecklistWidgetsSuccess: (_ctx, evt) => {
          return QueryActorSelectors.isUpdateEventSuccess(evt, 'checklist-widgets-query');
        },
      },
    },
  );
};

export type RulesEngineMachine = ReturnType<typeof makeRulesEngineMachine>;
export type RulesEngineMachineActorRef = ActorRefFrom<RulesEngineMachine>;
