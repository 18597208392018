import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateRunViaEmailParams = { templateId: string; enabled: boolean };

export type UpdateRunViaEmailResult = void;

export const UpdateRunViaEmail = {
  key: ['template', 'run-via-email'],
  mutationFn: ({ templateId, ...body }: UpdateRunViaEmailParams) =>
    axiosService
      .getAxios()
      .put<UpdateRunViaEmailResult>(`/1/templates/${templateId}/run-via-email-config`, body)
      .then(r => r.data),
};

export const useUpdateRunViaEmailMutation = (
  options: UseMutationOptions<UpdateRunViaEmailResult, AxiosError, UpdateRunViaEmailParams> = {},
) => {
  return useMutation<UpdateRunViaEmailResult, AxiosError, UpdateRunViaEmailParams>(
    UpdateRunViaEmail.mutationFn,
    options,
  );
};
