import angular, { IHttpResponse } from 'angular';
import { Resource, Resources } from 'app/resources/factory/axios-resource-factory';
import { QResource, QResources } from 'app/resources/factory/resource-factory';
import { AxiosError } from 'axios';

export type QCompat = <T>(resources: Resources<T>) => QResources<T>;

angular.module('frontStreetApp.services').factory('qCompat', function ($q: angular.IQService) {
  function transformError(error: AxiosError): Partial<IHttpResponse<unknown>> {
    return {
      data: error.response?.data,
      status: error.response?.status ?? 500,
      statusText: error.response?.statusText ?? 'Internal Server Error',
      headers: error.response?.headers,
      xhrStatus: 'error',
    };
  }

  function wrapWithQWhen(f: Resource): QResource {
    return (params: Record<string, unknown>): { $promise: angular.IPromise<unknown> } => {
      const result = f(params);
      // Use $q.when to convert native Promise to IPromise
      return {
        $promise: $q.when(result).catch((error: AxiosError) => $q.reject(transformError(error))),
      };
    };
  }

  function qCompat<T>(resources: Resources<T>): QResources<T> {
    const wrappedResources = Object.keys(resources).reduce((acc, key) => {
      const resourceKey = key as keyof T;
      acc[resourceKey] = wrapWithQWhen(resources[resourceKey]);
      return acc;
    }, {} as QResources<T>);
    return wrappedResources;
  }

  return qCompat;
});
