import * as React from 'react';
import { useField } from 'formik';
import {
  Text,
  FormControl,
  HStack,
  Input,
  FormLabel,
  Tooltip,
  Icon,
  InputProps,
  FormErrorMessage,
} from 'components/design/next';
import { formikMetaToFormControlProps } from '../utils';

export const Placeholder: React.FC<React.PropsWithChildren<InputProps>> = ({
  placeholder,
  name = 'config.placeholder',
}) => {
  const [field, meta] = useField<string>(name);

  return (
    <FormControl {...formikMetaToFormControlProps(meta)}>
      <FormLabel htmlFor={name}>
        <HStack>
          <Text color="gray.700">Text placeholder</Text>
          <Tooltip
            label={<Text fontSize="xs">You can use this to give specific instructions on field requirements</Text>}
            hasArrow
            placement="top"
            shouldWrapChildren
          >
            <Icon size="4" icon="info-circle" variant="far" color="gray.500" />
          </Tooltip>
        </HStack>
      </FormLabel>
      <Input
        {...field}
        value={field.value ?? ''}
        id={name}
        aria-label="optional instructions about field requirements"
        placeholder={placeholder ? placeholder : 'e.g. Required more than 50 characters'}
        variant="outline"
      />

      {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};
