import { TaskTemplatePermit, UpdateTaskTemplatePermit } from '@process-street/subgrade/permission';
import {} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateTaskTemplatePermitsMutation {
  export type Params = { permit: UpdateTaskTemplatePermit };

  export type Response = TaskTemplatePermit[];

  export const key = ['template-revision', 'permits'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/template-revisions/${params.permit.templateRevisionId}/task-template-permits`, params.permit)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
