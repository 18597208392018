import * as React from 'react';
import { Template } from '@process-street/subgrade/process';
import { RunWorkflow } from './run-workflow';
import { EditWorkflow } from './edit-workflow';
import { HStack, useDisclosure } from '@chakra-ui/react';
import { PreviewWorkflow } from './preview-workflow';

type WorkflowActionsProps = {
  template: Template;
};

export const WorkflowActions: React.FC<React.PropsWithChildren<WorkflowActionsProps>> = ({ template }) => {
  const runWorkflowDisclosure = useDisclosure();
  const editWorkflowDisclosure = useDisclosure();

  const handleRunWorkflowDisclosureOpen = () => {
    // Close the edit tooltip so they don't overlap
    editWorkflowDisclosure.onClose();
    runWorkflowDisclosure.onOpen();
  };

  const handleEditWorkflowDisclosureOpen = () => {
    editWorkflowDisclosure.onOpen();
    // Close the run tooltip so they don't overlap
    runWorkflowDisclosure.onClose();
  };

  return (
    <HStack display={{ base: 'none', md: 'flex' }} spacing="2">
      <RunWorkflow template={template} {...runWorkflowDisclosure} onOpen={handleRunWorkflowDisclosureOpen} />
      <EditWorkflow template={template} {...editWorkflowDisclosure} onOpen={handleEditWorkflowDisclosureOpen} />
      <PreviewWorkflow template={template} />
    </HStack>
  );
};
