import * as React from 'react';
import {
  IconButton,
  Icon,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  Text,
  Link,
} from 'components/design/next';

export const LearnMorePopover: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Popover placement="top">
      <PopoverTrigger>
        <IconButton
          variant="ghost"
          size="xs"
          aria-label="variables info"
          icon={<Icon icon="info-circle" size="4" variant="far" />}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Text>Variables allow you to add dynamic content to workflow runs.</Text>
          <Link
            color="brand.500"
            href="http://www.process.st/help/docs/variables/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more &raquo;
          </Link>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
