import { MuidUtils } from '@process-street/subgrade/core';
import {
  AutomationLog,
  AutomationLogStatus,
  ExecutionStatus,
  TemplateSolutionInstance,
  TraySolution,
} from '@process-street/subgrade/automation';

export const generateTemplateSolutionInstance = (
  templateSolutionInstance?: Partial<TemplateSolutionInstance>,
): TemplateSolutionInstance => ({
  id: MuidUtils.randomMuid(),
  organizationId: MuidUtils.randomMuid(),
  templateId: MuidUtils.randomMuid(),
  solutionInstanceId: MuidUtils.randomMuid(),
  solutionId: MuidUtils.randomMuid(),
  name: 'template solution instance',
  description: 'Some description',
  enabled: true,
  configured: true,
  tags: [],
  automationApp: 'Salesforce',
  ...templateSolutionInstance,
});

export const generateTraySolution = (solution?: Partial<TraySolution>): TraySolution => ({
  id: MuidUtils.randomMuid(),
  title: 'tray solution',
  description: 'Some description',
  tags: [],
  automationApp: 'Salesforce',
  ...solution,
});

const DEFAULT_EXECUTION_LOG: Omit<ExecutionStatus, 'id'> = {
  eventDate: Date.now(),
  automationApp: 'Salesforce',
};
export function generateExecutionLog({
  empty = false,
  status,
  ...overrides
}: Partial<ExecutionStatus & { empty: boolean }>): ExecutionStatus | undefined {
  if (empty) {
    return undefined;
  }
  return {
    ...DEFAULT_EXECUTION_LOG,
    // a new id needs to be calculated each time
    id: MuidUtils.randomMuid(),
    status,
    ...(status === AutomationLogStatus.Failure && {
      errorMessage:
        '\n Automation failed on the step "Find a record by id" \n with the following error: Bad API request. Try checking your input properties.\n',
    }),
    ...overrides,
  };
}

export const generateAutomationLog = (log?: Partial<AutomationLog>) => ({
  id: MuidUtils.randomMuid(),
  eventDate: Date.now(),
  templateSolutionInstanceId: MuidUtils.randomMuid(),
  ...log,
});
