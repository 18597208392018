import { User } from '@process-street/subgrade/core';

declare global {
  interface Window {
    _elev: any;
  }
}

function toElevioTraits(user: User) {
  const traits: Record<string, string> = {
    UserId: user.id,
    Name: user.username,
  };
  if (user.telephoneNumber) {
    traits['Telephone Number'] = user.telephoneNumber;
  }
  if (user.whatIDo) {
    traits['What I Do'] = user.whatIDo;
  }
  if (user.origin) {
    traits['Origin'] = user.origin;
  }
  if (user.source) {
    traits['Source'] = user.source;
  }
  return traits;
}

function changeUser(user: User, userHash: string) {
  const elevioUser = {
    email: user.email,
    user_hash: userHash,
    traits: toElevioTraits(user),
  };
  if (window._elev.changeUser) {
    // If Elevio is already loaded, use the change user method
    delete window._elev.user;
    window._elev.changeUser(elevioUser);
  } else {
    // Otherwise, set the user property so it'll grab it once loaded
    window._elev.user = elevioUser;
  }
}

function logoutUser() {
  if (window._elev.logoutUser) {
    window._elev.logoutUser();
  } else {
    // If there is a user queued to login, delete it
    delete window._elev.user;
  }
}

function openElevioArticles() {
  window._elev.openModule('articles');
}

function setMainColor(color: string) {
  window._elev.setSettings?.({ main_color: color });
}

export const ElevioUtils = {
  changeUser,
  logoutUser,
  openElevioArticles,
  setMainColor,
  toElevioTraits,
};
