import * as React from 'react';
import { Button, Icon, Text, Box } from 'components/design/next';
import {
  FormResponsesColDef,
  FormResponsesStoreSelectors,
  SELECTION_COLUMN_NAME,
  useFormResponsesStore,
} from '../../../store';
import pluralize from 'pluralize';
import { components, GroupBase, OnChangeValue } from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { useStateParam } from 'hooks/use-state-param';
import { useGetTemplateSchemaQuery } from 'features/template/query-builder';

type ColumnOptionType = {
  value: FormResponsesColDef['field'];
  label: string;
};
export const FormResponsesColumnsFilter: React.FC<React.PropsWithChildren<unknown>> = () => {
  const formId = useStateParam({ key: 'id' });
  const schemaQuery = useGetTemplateSchemaQuery({ templateId: formId }, { staleTime: Infinity });
  const formFieldValueColumns = React.useMemo(
    () => FormResponsesStoreSelectors.getFormFieldValueColumns(schemaQuery.data),
    [schemaQuery.data],
  );

  const { setVisibleColumnFields, visibleColumnFields, predefinedColDefs } = useFormResponsesStore();

  const options: GroupBase<ColumnOptionType>[] = React.useMemo(
    () => [
      {
        label: 'Form response',
        options: predefinedColDefs
          .filter(v => v.field !== SELECTION_COLUMN_NAME)
          .map(col => ({ value: col.field, label: col.headerName ?? col.field })),
      },
      {
        label: 'Form fields',
        options: formFieldValueColumns.map(col => ({ value: col.field, label: col.headerName ?? col.field })),
      },
    ],
    [formFieldValueColumns, predefinedColDefs],
  );
  const value = React.useMemo(
    () => visibleColumnFields.map(field => options.flatMap(opt => opt.options).find(option => option.value === field)!),
    [options, visibleColumnFields],
  );

  // Show all form field values by default
  React.useEffect(() => {
    const ids = formFieldValueColumns.map(col => col.field);
    setVisibleColumnFields(curr => [...curr, ...ids]);
  }, [formFieldValueColumns, setVisibleColumnFields]);

  const handleChange = (selectedValue: OnChangeValue<ColumnOptionType, true>) => {
    if (BlvdSelectHelpers.isOptionsType<ColumnOptionType>(selectedValue)) {
      setVisibleColumnFields(selectedValue.map(option => option.value));
    }
    if (BlvdSelectHelpers.isGroupOptionsType<ColumnOptionType>(selectedValue)) {
      setVisibleColumnFields(
        selectedValue.flatMap(BlvdSelectHelpers.getGroupOptionsOrOption).map(option => option.value),
      );
    }
  };

  return (
    <Box
      sx={{
        '.blvd-select': {
          'display': 'inline-block',

          '.blvd-select__menu': {
            minW: '250px',
          },

          '.blvd-select__control': {
            boxShadow: 'none',
            borderColor: 'transparent',
            minH: '0',
          },

          '.blvd-select__option': {
            '&.blvd-select__option--is-focused, &:hover': {
              bgColor: 'brand.100',
              color: 'brand.500',
            },
          },
          '.blvd-select__value-container': {
            px: 0,
          },
          '.blvd-select__dummy-input': {
            position: 'absolute',
          },
        },
      }}
    >
      <BlvdSelect
        value={value}
        onChange={handleChange}
        options={options}
        menuControls
        fixedSize
        isClearable
        isMulti
        isSearchable
        components={{
          MultiValue: Empty,
          IndicatorsContainer: Empty,
          Placeholder: Empty,
          ValueContainer: props => {
            return (
              <components.ValueContainer {...props}>
                <Button
                  isActive={props.selectProps.menuIsOpen}
                  size="sm"
                  leftIcon={<Icon icon="eye-slash" size="4" variant="far" />}
                  rightIcon={<Icon icon="chevron-down" size="4" variant="far" />}
                  variant="ghost"
                  width={{ base: 'full', md: 'auto' }}
                  fontWeight="normal"
                  colorScheme="gray"
                  aria-label="Filter by column"
                >
                  <Text mr="auto" as="span" variant="inherit">
                    {pluralize('column', visibleColumnFields.length, true)}
                  </Text>
                </Button>
                {props.children}
              </components.ValueContainer>
            );
          },
        }}
      />
    </Box>
  );
};

const Empty: React.FC<React.PropsWithChildren<unknown>> = () => null;
