import { StringUtils } from '@process-street/subgrade/util';

/** A utility type to map capitalized names to names. */
export type TabEnum<Tab extends string> = Record<Capitalize<Tab>, Tab>;
/** A utility type to map capitalized names with an `Index` suffix to indices. */
export type TabIndexMap<Tab extends string> = Record<`${Capitalize<Tab>}Index`, number>;
/** A utility type to map capitalized names with an `Label` suffix to labels. */
export type TabLabelMap<Tab extends string> = Record<`${Capitalize<Tab>}Label`, string>;

/** An interface to define possible tabs for a page and access tab strings and indices. */
export type TabConfig<Tab extends string> = TabEnum<Tab> &
  TabIndexMap<Tab> &
  TabLabelMap<Tab> & {
    /** Type guard for {@link Tab} type */
    isTab: (tab: unknown) => tab is Tab;
    /** Type coercion for {@link Tab} type */
    asTab: (tab: unknown) => Tab;
    /** Convert numerical index to {@link Tab} */
    getTabFromIndex: (index: number) => Tab;
    /** Convert {@link Tab} to numerical index */
    getIndexFromTab: (tab: Tab | unknown) => number;
  };

/** Create a {@link TabConfig} object based on variadic string params */
export function generateTabConfig<Tab extends string>({
  tabs,
  format = StringUtils.capitalize,
}: {
  tabs: Tab[];
  format?: (t: Tab) => string;
}): TabConfig<Tab> {
  function isTab(tab: unknown): tab is Tab {
    return typeof tab === 'string' && tabs.includes(tab as Tab);
  }

  function asTab(tab: unknown): Tab {
    return isTab(tab) ? tab : tabs[0];
  }

  const tabIndices = tabs.reduce((acc, el, i) => {
    acc[`${StringUtils.capitalize(el)}Index`] = i;
    return acc;
  }, {} as TabIndexMap<Tab>);

  const tabLabels = tabs.reduce((acc, el) => {
    acc[`${StringUtils.capitalize(el)}Label`] = format(el);
    return acc;
  }, {} as TabLabelMap<Tab>);

  const tabEnum = tabs.reduce((acc, el) => {
    acc[StringUtils.capitalize(el)] = el;
    return acc;
  }, {} as TabEnum<Tab>);

  return {
    ...tabEnum,
    ...tabIndices,
    ...tabLabels,

    asTab,
    isTab,
    getTabFromIndex: index => {
      return tabs[index] ?? tabs[0];
    },
    getIndexFromTab: tab => {
      return tabIndices[`${StringUtils.capitalize(asTab(tab))}Index`];
    },
  };
}
