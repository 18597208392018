import { useFoldersQuery } from 'pages/library/components/template-library/query';
import { isTemplatePrivate } from 'reducers/template/template.selectors';
import { useMemo } from 'react';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { Template } from '@process-street/subgrade/process';

export const useIsPrivateTemplate = (templateId?: Template['id']) => {
  const foldersQuery = useFoldersQuery();
  const templateQuery = useGetTemplateQuery({ templateId: templateId! }, { enabled: Boolean(templateId) });
  return useMemo(() => {
    if (templateQuery.data && foldersQuery.data) {
      return isTemplatePrivate(templateQuery.data, foldersQuery.data);
    } else {
      return false;
    }
  }, [templateQuery.data, foldersQuery.data]);
};
