import * as React from 'react';
import { Icon, Text, useToast, Button } from 'components/design/next';
import { useCopyToClipboard } from 'features/ui/hooks/use-copy-to-clipboard';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export type CopyRunLinkProps = {
  textToCopy: string;
};

export const CopyRunLink: React.FC<React.PropsWithChildren<CopyRunLinkProps>> = ({ textToCopy }) => {
  const toast = useToast();

  const onError = () =>
    toast({
      status: 'error',
      title: "We're having problems copying the run link",
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });

  const onSuccess = () =>
    toast({
      status: 'success',
      title: 'Copied run link to clipboard',

      variant: 'subtle',
    });

  const copyToClipboard = useCopyToClipboard({ onSuccess, onError });
  const onClick = () => {
    if (textToCopy) {
      copyToClipboard(textToCopy);
    }
  };

  return (
    <Button onClick={onClick} leftIcon={<Icon icon="link" size="4" />} variant="unstyled">
      <Text as="span" variant="2">
        Copy run link
      </Text>
    </Button>
  );
};
