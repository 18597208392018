import { InboxItemType, InboxItemUtils } from '@process-street/subgrade/inbox';
import { TaskStatus } from '@process-street/subgrade/process';
import { GetInboxItemsQuery } from 'app/features/microsoft-teams/query-builder';
import { GetAllTasksByChecklistRevisionIdQuery } from 'app/features/task/query-builder';
import { useToast } from 'components/design/next';
import { UpdateTaskStatusMutation } from 'features/task/query-builder/update-task-status-mutation';
import { isFunction } from 'lodash';
import { useQueryClient } from 'react-query';
import { UpdateErrorToasts } from '../helpers';

export const useCompleteTaskMutation = (isShowingCompleted?: boolean) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return UpdateTaskStatusMutation.useMutation({
    onMutate: variables => {
      if (isShowingCompleted) {
        return GetInboxItemsQuery.updateItem(
          queryClient,
          currentItem => InboxItemUtils.isTask(currentItem) && currentItem.task.id === variables.taskId,
          itemToUpdate => {
            if (InboxItemUtils.isTask(itemToUpdate)) {
              return { ...itemToUpdate, task: { ...itemToUpdate.task, status: TaskStatus.Completed } };
            } else {
              return { ...itemToUpdate };
            }
          },
        );
      } else {
        return GetInboxItemsQuery.removeItem(
          queryClient,
          item =>
            (item.itemType === InboxItemType.OneOffTask || item.itemType === InboxItemType.StandardTask) &&
            item.task.id === variables.taskId,
        );
      }
    },
    onSuccess: async response => {
      toast({ status: 'success', title: 'Task completed' });

      await queryClient.invalidateQueries(
        GetAllTasksByChecklistRevisionIdQuery.getKey({ checklistRevisionId: response.task.checklistRevision.id }),
      );
    },
    onError: (error, _variables, context) => {
      if (isFunction(context)) {
        // Revert the optimistic update in case of error
        context();
      }

      toast(UpdateErrorToasts.getTaskUpdateErrorToastOptions(error.response));
    },
  });
};
