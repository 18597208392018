import {
  CHECKLIST_WIDGET_GET_ALL_BY_CHECKLIST_REVISION_ID,
  CHECKLIST_WIDGET_UPDATE_ALL_INTERNAL,
  ChecklistWidgetGetAllByChecklistRevisionIdAction,
} from 'components/widgets/store/widget.actions';
import { handleSuccessActions, toSuccess } from 'reducers/util';
import { EntitiesReducerUtils, EntityMap, LookupMap, LookupsReducerUtils } from '@process-street/subgrade/redux';
import { ChecklistWidget } from '@process-street/subgrade/process';
import { Action, handleActions } from 'redux-actions';
import { combineReducers, Reducer } from 'redux';
import { ChecklistWidgetLookups } from 'reducers/types';
import { FORM_FIELD_VALUE_UPDATE } from 'components/form-field-value/store/form-field-value.actions';
import { FormFieldValueUpdateAction } from 'components/form-field-value/store/types';

export const checklistWidgetEntitiesReducer = handleActions(
  {
    [toSuccess(CHECKLIST_WIDGET_GET_ALL_BY_CHECKLIST_REVISION_ID)]: (
      state: EntityMap<ChecklistWidget>,
      action: Action<ChecklistWidget[]>,
    ) => EntitiesReducerUtils.upsertAll(state, action.payload),
    // @ts-expect-error -- It's a complex case, handle actions cannot handle it properly
    [CHECKLIST_WIDGET_UPDATE_ALL_INTERNAL]: (
      state: EntityMap<ChecklistWidget>,
      action: Action<{ checklistWidgets: ChecklistWidget[] }>,
    ) => EntitiesReducerUtils.upsertAll(state, action.payload.checklistWidgets),
    [toSuccess(FORM_FIELD_VALUE_UPDATE)]: (state: EntityMap<ChecklistWidget>, action: FormFieldValueUpdateAction) =>
      EntitiesReducerUtils.upsertAll(state, action.payload.checklistWidgets),
  },
  {},
);

const checklistWidgetByChecklistRevisionIdReducer: Reducer<LookupMap> = handleSuccessActions(
  {
    [CHECKLIST_WIDGET_GET_ALL_BY_CHECKLIST_REVISION_ID]: (
      state: LookupMap,
      action: ChecklistWidgetGetAllByChecklistRevisionIdAction,
    ) =>
      LookupsReducerUtils.replaceAllUsingSelectorFunctions(
        state,
        action.payload,
        () => action.meta.checklistRevisionId,
      ),
    [FORM_FIELD_VALUE_UPDATE]: (state: LookupMap, action: FormFieldValueUpdateAction) =>
      LookupsReducerUtils.upsertAllUsingSelectorFunctions(
        state,
        action.payload.checklistWidgets,
        () => action.meta.checklistRevisionId,
      ),
  },
  {},
);

export const checklistWidgetLookupsReducer = combineReducers<ChecklistWidgetLookups>({
  byChecklistRevisionId: checklistWidgetByChecklistRevisionIdReducer,
});
