import { Muid } from '@process-street/subgrade/core';
import { OverdueTaskDto } from 'components/dashboard/models/grid';
import { SavedView } from 'components/dashboard/models/saved-views';
import { GridHelper } from 'components/dashboard/services/grid-helper';
import { ChecklistDashboardModuleState, ChecklistDashboardSettings, PropertyMap } from 'reducers/types';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { combineReducers } from 'redux';
import { Action, ActionMeta } from 'redux-actions';
import {
  CHECKLIST_DASHBOARD_DELETE_SAVED_VIEW,
  CHECKLIST_DASHBOARD_GET_ALL_SAVED_VIEWS_FOR_USER,
  CHECKLIST_DASHBOARD_GET_OVERDUE_TASKS_BY_CHECKLIST_ID,
  CHECKLIST_DASHBOARD_SAVE_SAVED_VIEW,
  CHECKLIST_DASHBOARD_SET_SELECTED_SAVED_VIEW,
} from './checklist-dashboard.actions';

const savedViewsReducer = handleActionsOnSuccess(
  {
    [CHECKLIST_DASHBOARD_GET_ALL_SAVED_VIEWS_FOR_USER]: (_state: SavedView[], action: Action<SavedView[]>) =>
      action.payload,
    [CHECKLIST_DASHBOARD_SAVE_SAVED_VIEW]: (state: SavedView[], action: Action<SavedView>) => {
      const updatedViews = state.filter(view => view.id !== action.payload.id).concat(action.payload);
      return GridHelper.orderViews(updatedViews);
    },
    [CHECKLIST_DASHBOARD_DELETE_SAVED_VIEW]: (state: SavedView[], action: ActionMeta<SavedView, { viewId: Muid }>) =>
      state.filter(view => view.id !== action.meta.viewId),
  },
  [] as SavedView[],
);

const selectedSavedViewReducer = handleActionsOnSuccess(
  {
    [CHECKLIST_DASHBOARD_SET_SELECTED_SAVED_VIEW]: (
      state: PropertyMap<string>,
      action: Action<{ organizationId: Muid; selectedSavedViewId: Muid }>,
    ) => ({ ...state, [action.payload.organizationId]: action.payload.selectedSavedViewId }),
  },
  {},
);

export const overdueTasksReducer = handleActionsOnSuccess(
  {
    [CHECKLIST_DASHBOARD_GET_OVERDUE_TASKS_BY_CHECKLIST_ID]: (
      state: PropertyMap<string>,
      action: ActionMeta<OverdueTaskDto[], { checklistId: Muid }>,
    ) => ({ ...state, [action.meta.checklistId]: action.payload }),
  },
  {},
);

export const overdueTasksLoadedReducer = handleActionsOnSuccess(
  {
    [CHECKLIST_DASHBOARD_GET_OVERDUE_TASKS_BY_CHECKLIST_ID]: (
      state: PropertyMap<string>,
      action: ActionMeta<OverdueTaskDto[], { checklistId: Muid }>,
    ) => ({ ...state, [action.meta.checklistId]: true }),
  },
  {},
);

export const checklistDashboardSettingsReducer = combineReducers<ChecklistDashboardSettings>({
  selectedSavedView: selectedSavedViewReducer,
});

const savedViewsLoadedReducer = handleActionsOnSuccess(
  {
    [CHECKLIST_DASHBOARD_GET_ALL_SAVED_VIEWS_FOR_USER]: (_state: SavedView[], _action: Action<SavedView[]>) => true,
  },
  false,
);

export const checklistDashboardModuleReducer = combineReducers<ChecklistDashboardModuleState>({
  overdueTasks: overdueTasksReducer,
  overdueTasksLoaded: overdueTasksLoadedReducer,
  savedViews: savedViewsReducer,
  savedViewsLoaded: savedViewsLoadedReducer,
});
