import { useInjector } from 'components/injection-provider';
import { DeleteTemplateMutation, DeleteTemplateMutationGenerics } from 'features/template/mutation-builder';
import { useGetTemplateQuery } from 'features/template/query-builder';
import * as React from 'react';
import { MutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';
import { TEMPLATE_REVISION_GET_ALL_NEWEST_BY_TEMPLATE_ID } from 'reducers/template-revision/template-revision.actions';
import { TEMPLATE_DELETE_BY_ID, TEMPLATE_SET } from 'reducers/template/template.actions';
import { keysAreEqual, makeMutationListener } from 'services/react-query';
import { MUTATION_KEYS } from './mutation-keys';
import { useGetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template-revisions/query-builder';

export function useReactQueryReduxSync() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mutationCache = queryClient.getMutationCache();
  const { $stateParams } = useInjector('$stateParams');

  const { id: templateId } = $stateParams;

  const { data: template } = useGetTemplateQuery({ templateId });
  const { data: templateRevisions } = useGetNewestTemplateRevisionsByTemplateIdQuery({ templateId });

  // use a ref here so the value change is instantaneous and outside of the render cycle
  const deleted = React.useRef(false);

  React.useEffect(() => {
    const unsubscribe = mutationCache.subscribe(
      makeMutationListener({
        keys: MUTATION_KEYS,
        onSuccess: mutation => {
          if (keysAreEqual(mutation.options.mutationKey!, DeleteTemplateMutation.key)) {
            const options = mutation.options as unknown as MutationOptions<
              DeleteTemplateMutationGenerics['data'],
              DeleteTemplateMutationGenerics['error'],
              DeleteTemplateMutationGenerics['variables']
            >;
            const { templateId } = options.variables ?? {};
            deleted.current = true;
            dispatch({ type: TEMPLATE_DELETE_BY_ID, meta: { templateId } });
          }
        },
      }),
    );

    return () => {
      unsubscribe();
    };
  }, [dispatch, mutationCache, templateId]);

  useUnmount(() => {
    if (deleted.current) {
      return;
    }

    if (template) {
      dispatch({ type: TEMPLATE_SET, payload: { template } });
    }
    if (templateRevisions) {
      dispatch({
        type: TEMPLATE_REVISION_GET_ALL_NEWEST_BY_TEMPLATE_ID,
        payload: templateRevisions,
        meta: { templateId: template?.id },
      });
    }
  });
}
