export const WidgetsApi = function (Widgets) {
  'ngInject';

  const getAllByTemplateRevisionId = templateRevisionId =>
    Widgets.getAllByTemplateRevisionId({ id: templateRevisionId }).$promise;

  const getAllByChecklistRevisionId = checklistRevisionId =>
    Widgets.getAllByChecklistRevisionId({ id: checklistRevisionId }).$promise;

  const getAllChecklistWidgetsByChecklistRevisionId = checklistRevisionId =>
    Widgets.getAllChecklistWidgetsByChecklistRevisionId({ id: checklistRevisionId }).$promise;

  const getAllByTaskTemplateId = taskTemplateId => Widgets.getAllByTaskTemplateId({ taskTemplateId }).$promise;

  const createAt = widget => Widgets.createAt(widget).$promise;

  const createFile = (headerId, url) =>
    Widgets.createFile({
      headerId,
      url,
    }).$promise;

  const copy = params => Widgets.copy(params).$promise;

  const update = widget => Widgets.update(widget).$promise;

  const updateAllOrderTrees = updates => Widgets.updateAllOrderTrees(updates).$promise;

  const deleteByHeaderId = headerId => Widgets.deleteByHeaderId({ headerId }).$promise;

  return {
    copy,
    createAt,
    createFile,
    deleteByHeaderId,
    getAllByChecklistRevisionId,
    getAllByTaskTemplateId,
    getAllByTemplateRevisionId,
    getAllChecklistWidgetsByChecklistRevisionId,
    update,
    updateAllOrderTrees,
  };
};
