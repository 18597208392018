import React from 'react';
import { GroupType, OrganizationMembershipRole, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import {
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  Link,
  Spacer,
  Spinner,
  Text,
  useToast,
  VStack,
} from 'components/design/next';
import { Avatar, AvatarSize } from 'components/common/Avatar';
import { UserUtils } from '@process-street/subgrade/util';
import { PermissionsSelector } from 'components/permissions/permissions-selector/component';
import {
  AccessLevel,
  FolderPermit,
  isFolderPermit,
  isTemplatePermit,
  PermitType,
  TemplatePermit,
} from '@process-street/subgrade/permission';
import { GroupMembersIndicator } from 'components/permissions/group-members-indicator/component';
import { useIsAssigned } from 'components/share-modal/participant-row/use-is-assigned';
import { useInjector } from 'components/injection-provider';
import { match, P } from 'ts-pattern';
import { useAccessLevels } from '../use-access-levels';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import { FolderPermitResolver } from '@process-street/subgrade/permission/folder-permit-resolver';
import { TemplatePermitResolver } from '@process-street/subgrade/permission/template-permit-resolver';
import { useCanChangeLevel } from '../use-can-change-level';
import {
  GetFolderPermits,
  GetTemplatePermits,
  useRemovePermitMutation,
  useSetPermitLevelMutation,
} from 'features/permits/query-builder';
import { GetTemplatesPermissionStatsByOrganization } from 'components/permissions/services/query';
import { useQueryClient } from 'react-query';
import { useParticipantsContext } from '../participants';
import { useGetAllGroupsQuery } from 'features/group/query-builder/get-all-groups';
import { useFoldersQuery } from 'pages/library/components/template-library/query';
import { useDispatch, useSelector } from 'react-redux';
import { TemplateMembershipActions } from 'components/template/membership/store/template-membership.actions';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';
import { SessionSelector } from 'reducers/session/session.selectors';
import { isT5KPlan } from 'services/plans';
import { useUserDeactivationHelpers } from 'features/user/use-user-deactivation-helpers';
import { TemplateType } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export type ParticipantRowProps = {
  permit: (TemplatePermit | FolderPermit) & { organizationMembership: OrganizationMembershipWithUser };
  onRemove: (permit?: TemplatePermit | FolderPermit) => void;
  templateType?: TemplateType;
};

export const ParticipantRow: React.FC<React.PropsWithChildren<ParticipantRowProps>> = ({
  permit,
  onRemove,
  templateType,
}) => {
  const { subject, id, restricted } = useParticipantsContext();
  const plan = useSelector(SessionSelector.getCurrentPlan);
  const organizationMembershipWithUser = permit.organizationMembership;
  const organizationId = organizationMembershipWithUser.organization.id;
  const { user } = organizationMembershipWithUser;
  const rowWidth = 32;
  const deactivationHelpers = useUserDeactivationHelpers();

  /**
   * Here and in {@link ../participants/index}, we have to disambiguate between folders and templates because the API endpoint itself is dynamic.
   * You'll see a few places where we set up two queries (one for template, one for folder) but only one of them will run based on the permit type.
   */
  const templateId = isTemplatePermit(permit) ? permit.template.id : undefined;
  const folderId = isFolderPermit(permit) ? permit.folder.id : undefined;
  const templateQuery = useGetTemplateQuery({ templateId: templateId! }, { enabled: Boolean(templateId) });

  const folderQuery = useFoldersQuery({
    select: folders => folders.find(f => f.id === folderId),
    enabled: !!folderId,
  });

  const isPrivateTemplate = useIsPrivateTemplate(templateId);

  const query = isFolderPermit(permit) ? folderQuery : templateQuery;
  // It's an ancestor if it's a folder AND if its id is not the same as the subject folder id
  const isAncestorFolder = !!folderId && (subject === 'folder' ? id !== folderId : true);
  const ancestorFolder = isAncestorFolder ? folderQuery.data : undefined;

  const accessLevels = useAccessLevels({
    membership: organizationMembershipWithUser,
    record: query.data,
  });

  const isFreeMember = organizationMembershipWithUser.role === OrganizationMembershipRole.FreeMember;
  const isFreeMemberAndCanRun = isFreeMember && isT5KPlan(plan!.id);

  const selectedAccessLevel = isFolderPermit(permit)
    ? FolderPermitResolver.findFolderAccessLevel({ permit, isFreeMember, isFreeMemberAndCanRun })
    : match(templateType)
        .with(TemplateType.Form, () => TemplatePermitResolver.findFormTemplateAccessLevel(permit))
        .otherwise(() => TemplatePermitResolver.findWorkflowTemplateAccessLevel(permit));

  const { canChangeLevel } = useCanChangeLevel(permit);

  const groupsQuery = useGetAllGroupsQuery({ include: 'user' });
  const group = groupsQuery.data?.find(g => g.user.id === user.id);
  const allFreeMembersGroup = groupsQuery.data?.find(g => g.groupType === GroupType.AllFreeMembers);

  const { $state } = useInjector('$state');

  const assigned = useIsAssigned({ templateId, organizationMembershipId: organizationMembershipWithUser.id });

  const permitType = isFolderPermit(permit) ? PermitType.FOLDER : PermitType.TEMPLATE;
  const toast = useToast();
  const queryClient = useQueryClient();
  const setPermissionLevelMutation = useSetPermitLevelMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(isFolderPermit(permit) ? GetFolderPermits.key : GetTemplatePermits.key);
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems updating the permissions",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });
  const onPermissionChange = React.useCallback(
    (level: AccessLevel) => {
      setPermissionLevelMutation.mutate({ id: permit.id, type: permitType, level });
    },
    [permit.id, permitType, setPermissionLevelMutation],
  );

  const dispatch = useDispatch();
  const removePermissionMutation = useRemovePermitMutation({
    onSuccess: () => {
      if (isFolderPermit(permit)) {
        queryClient.invalidateQueries(GetFolderPermits.key);
        // for folder permits, permission stats are invalidated in members-ctrl (onRemove callback below)
      } else if (templateId) {
        // for template permits, parent onRemove is currently unused
        // dispatch Redux action to update template member list avatars
        dispatch(TemplateMembershipActions.deleteTemplatePermit(templateId, permit.id));
        queryClient.invalidateQueries(GetTemplatePermits.key);
        const parentFolderId = templateQuery.data?.folder.id;
        parentFolderId &&
          queryClient.invalidateQueries(
            GetTemplatesPermissionStatsByOrganization.getKey({
              organizationId,
              parentFolderId,
            }),
          );
      }

      onRemove(permit);
    },
  });
  const handleRemove = React.useCallback(() => {
    removePermissionMutation.mutate({ id: permit.id, type: permitType });
  }, [permit.id, permitType, removePermissionMutation]);

  if (!user) {
    return null;
  }

  const isGroup = isGroupUser(user);

  const rowHeight = isGroup && (assigned || isAncestorFolder) ? 20 : 16;

  return (
    <Flex minH={rowHeight} backgroundColor={isAncestorFolder ? 'gray.50' : 'white'} px={3}>
      {deactivationHelpers.withTooltip(
        user.id,
        <Center w={8} h={rowHeight}>
          <Avatar size={AvatarSize.Normal} user={user} />
        </Center>,
      )}

      <Center minH={rowHeight} color="gray.600" display={['none', 'flex']} pt={3} pb={3}>
        <VStack spacing="0.5" alignItems="left" pl="4">
          {deactivationHelpers.withTooltip(
            user.id,
            <Text
              opacity={deactivationHelpers.isActive(user.id) ? 1 : 0.6}
              as="span"
              dangerouslySetInnerHTML={{ __html: UserUtils.getLabel(user, false) }}
            />,
          )}

          {match({ assigned, isAncestorFolder, ancestorFolder, isLoading: folderQuery.isLoading })
            .with({ isAncestorFolder: true, isLoading: true }, () => <Spinner />)
            .with({ assigned: true, isAncestorFolder: false }, () => (
              <Text as="span" variant="-1" color="gray.500">
                Assigned to task
              </Text>
            ))
            .with({ assigned: false, ancestorFolder: P.not(undefined), isLoading: false }, ({ ancestorFolder }) => (
              <Text as="span" variant="-1" color="gray.500">
                Inherited from <Icon icon="folder" variant="fas" size="3" color="gray.400" />{' '}
                <Text as="span" fontWeight="medium" fontSize="inherit">
                  {ancestorFolder.name}
                </Text>
              </Text>
            ))
            .with({ assigned: true, ancestorFolder: P.not(undefined) }, ({ ancestorFolder }) => (
              <Text as="span" variant="-1" color="gray.500">
                Assigned to task | Inherited from <Icon icon="folder" variant="fas" size="3" color="gray.400" />{' '}
                <Text as="span" fontWeight="medium" fontSize="inherit">
                  {ancestorFolder.name}
                </Text>
              </Text>
            ))
            .otherwise(() => null)}

          {isGroup && group && allFreeMembersGroup && (
            <GroupMembersIndicator group={group} allFreeMembersGroup={allFreeMembersGroup} />
          )}
        </VStack>
      </Center>

      <Spacer />
      <Center h={rowHeight}>
        <Box w={'full'} textAlign={'right'}>
          <PermissionsSelector
            isDisabled={
              restricted ||
              !canChangeLevel ||
              !!ancestorFolder ||
              setPermissionLevelMutation.isLoading ||
              isPrivateTemplate ||
              accessLevels.length === 1
            }
            accessLevels={accessLevels}
            selectedAccessLevel={selectedAccessLevel}
            onChange={onPermissionChange}
          />
        </Box>
      </Center>
      <Center w={rowWidth} h={rowHeight} ml={4} color="gray.500" display={['none', 'flex']} justifyContent="flex-end">
        <Text as="span">{UserUtils.getMembershipType(organizationMembershipWithUser)}</Text>
      </Center>
      <Center w={6} h={rowHeight} ml={4}>
        {match({ isFolder: isFolderPermit(permit), ancestorFolder, isLoading: folderQuery.isLoading })
          .with({ isFolder: true, isLoading: true }, () => <Spinner />)
          .with({ ancestorFolder: P.not(undefined) }, ({ ancestorFolder }) => (
            <IconButton
              size="md"
              color="gray.500"
              variant="unstyled"
              aria-label="Manage"
              display="inherit"
              icon={<Icon variant="far" size="4" icon="cog" />}
              _focus={{ boxShadow: 'none' }}
              as={Link}
              href={$state.href('folderManage.tab', { id: ancestorFolder.id, tab: 'permissions' }, { inherit: false })}
            />
          ))
          .otherwise(() => (
            <IconButton
              size="md"
              color="gray.500"
              variant="unstyled"
              aria-label="Remove"
              icon={<Icon variant="far" size="5" icon="user-times" />}
              _focus={{ boxShadow: 'none' }}
              onClick={handleRemove}
              isDisabled={restricted || !canChangeLevel}
              isLoading={removePermissionMutation.isLoading}
            />
          ))}
      </Center>
    </Flex>
  );
};
