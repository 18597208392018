import * as React from 'react';
import { Tooltip, Link } from 'components/design/next';
import { useAdminEmails } from 'components/paywalls/use-admin-emails';
import { DataSet, mailto as buildMailTo, SavedView } from '@process-street/subgrade/process';
import { useCurrentUser } from 'hooks/use-current-user';
import { useHref } from 'app/adapters/navigation';
import { queryString } from '@process-street/subgrade/util';

export type NoAccessTooltipProps = {
  dataSet: DataSet;
  savedView?: SavedView;
  isDisabled?: boolean;
  contactAdmin?: boolean;
  label: string;
};

export const NoAccessTooltip: React.FC<React.PropsWithChildren<NoAccessTooltipProps>> = ({
  isDisabled,
  contactAdmin,
  label,
  dataSet,
  savedView,
  children,
}) => {
  const [adminEmail, ...bcc] = useAdminEmails();
  const currentUser = useCurrentUser();

  const entity = savedView ? 'saved view' : 'data set';
  const entityName = savedView ? savedView.name : dataSet.name;
  const pathname = savedView ? 'dataSets.savedView' : 'dataSets.dataSet';
  const href = useHref(
    {
      pathname,
      search: queryString.stringify({ dataSetId: dataSet.id, savedViewId: savedView?.id }),
    },
    { relative: 'path' },
  );

  const mailto = buildMailTo({
    to: adminEmail,
    subject: `Access request for the ${entity} ${entityName}`,
    body: `Hello, I would like access to the ${entity} ${entityName}.
    
You can give me access by going to ${href}, clicking the share icon, and adding me.

Sincerely,
${currentUser?.username}`,
    bcc,
  });

  const labelElem = (
    <>
      {label}
      {contactAdmin && (
        <>
          {' '}
          <Link textDecoration="underline" href={mailto} _hover={{ color: 'gray.500', textDecor: 'none' }}>
            Contact your Admin
          </Link>
        </>
      )}
    </>
  );

  return (
    <Tooltip
      hasArrow
      shouldWrapChildren
      label={labelElem}
      isDisabled={isDisabled}
      closeDelay={contactAdmin ? 1000 : undefined}
      sx={{ pointerEvents: 'auto!important' }}
    >
      {children}
    </Tooltip>
  );
};
