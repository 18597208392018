import * as React from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  HStack,
  Box,
  VStack,
  ButtonGroup,
  IconButton,
  Center,
  ButtonProps,
} from 'components/design/next';
import { BlvdSearch, InputColour } from 'components/design/BlvdSearch';
import { getLastPage, getPageButtonRange, getPageSlice, ShownUserType } from './state';
import { useController } from './controller';
import { Member } from './member';

const PaginationButton: ButtonProps = { _hover: { color: 'brand.500', bgColor: 'brand.100' }, variant: 'ghost' };

export const UserList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    shownUserType,
    usernameOrEmail,
    onSearchChanged,
    updateSelection,
    filteredMemberships,
    previousPage,
    nextPage,
    goToPage,
    page,
  } = useController();

  const membershipsOnPage = getPageSlice({
    items: filteredMemberships,
    page,
  });

  const lastPage = getLastPage(filteredMemberships);
  const pageBtnNumbers = getPageButtonRange({ page, lastPage });
  const isLastPage = page === lastPage;

  return (
    <Box>
      <HStack spacing="4" mb="4">
        <Box flex="1">
          <BlvdSearch
            inputColour={InputColour.Default}
            placeholder="Search..."
            debounceDelay={500}
            value={usernameOrEmail}
            onChange={onSearchChanged}
          ></BlvdSearch>
        </Box>

        <Box>
          <Menu autoSelect={false}>
            <MenuButton
              as={Button}
              variant="outline"
              colorScheme="gray"
              rightIcon={<Icon icon="chevron-down" size="3" variant="far" />}
              fontWeight="normal"
              aria-label="user role filter"
            >
              {shownUserType}
            </MenuButton>

            <MenuList>
              {(
                [
                  ['All Users', 'All Users'],
                  ['Admins', 'Admin'],
                  ['Members', 'Member'],
                  ['Guests', 'Guest'],
                ] as Array<[ShownUserType, string]>
              ).map(([option, label]) => (
                <MenuItem
                  key={option}
                  onClick={() => updateSelection(option)}
                  data-item-selected={shownUserType === option}
                >
                  {label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Box>
      </HStack>

      <Box role="table">
        <VStack role="rowgroup" mb="4" spacing="0">
          {membershipsOnPage.map(membership => (
            <HStack
              p={{ base: 0, sm: 2 }}
              spacing={{ base: 0, sm: 2 }}
              h="15"
              w="full"
              borderBottomStyle="solid"
              borderBottomColor="gray.200"
              borderBottomWidth="px"
              key={typeof membership === 'string' ? membership : membership.id}
              role="row"
            >
              <Member membership={membership} key={membership.id} />
            </HStack>
          ))}
        </VStack>

        {lastPage > 1 ? (
          <Center>
            <ButtonGroup>
              <IconButton
                {...PaginationButton}
                aria-label="first page"
                onClick={() => goToPage(1)}
                icon={<Icon icon="chevron-double-left" size="4" />}
                isDisabled={page === 1}
              />

              <IconButton
                {...PaginationButton}
                aria-label="previous page"
                onClick={previousPage}
                icon={<Icon icon="chevron-left" size="4" />}
                isDisabled={page === 1}
              />

              {pageBtnNumbers.map(p =>
                p ? (
                  <Button
                    {...PaginationButton}
                    fontWeight="medium"
                    aria-label={`go to page ${p}`}
                    key={p}
                    {...(p === page
                      ? {
                          bgColor: 'brand.100',
                          color: 'brand.500',
                          onClick: () => {},
                          cursor: 'default',
                        }
                      : {
                          color: 'gray.600',
                          onClick: () => goToPage(p),
                        })}
                  >
                    {p}
                  </Button>
                ) : null,
              )}

              <IconButton
                {...PaginationButton}
                aria-label="next page"
                onClick={nextPage}
                icon={<Icon icon="chevron-right" size="4" />}
                isDisabled={isLastPage}
              />

              <IconButton
                {...PaginationButton}
                aria-label="last page"
                onClick={() => goToPage(lastPage)}
                icon={<Icon icon="chevron-double-right" size="4" />}
                isDisabled={isLastPage}
              />
            </ButtonGroup>
          </Center>
        ) : null}
      </Box>
    </Box>
  );
};
