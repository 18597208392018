import angular from 'angular';
import { HttpStatus } from '@process-street/subgrade/util';
import { ObjectType } from 'services/activities/object-type';
import { Verb } from 'services/activities/verb';
import { TemplateConstants } from 'services/template-constants';
import { ChecklistStatus, TemplateType } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { interpret, valuateData } from './activity-service.pure';

angular
  .module('frontStreetApp.services')
  .service('ActivityService', function (ChecklistService, SessionService, TemplateService, ToastService) {
    const self = this;
    const currentUser = SessionService.getUser();

    self.interpret = interpret;

    self.valuateData = data => valuateData(data, currentUser?.timeZone);

    self.isUndeletable = function (activity) {
      let objectIsUndeletable;

      switch (activity.objectType) {
        case ObjectType.TEMPLATE: {
          const activityIsTemplateDeleted =
            activity.verb === Verb.DELETED ||
            (activity.verb === Verb.UPDATED && activity.data.status === TemplateConstants.STATUS.DELETED);
          const templateIsStillDeleted = activity.object.status === TemplateConstants.STATUS.DELETED;
          objectIsUndeletable = activityIsTemplateDeleted && templateIsStillDeleted;
          break;
        }
        case ObjectType.CHECKLIST: {
          const activityIsChecklistDeleted =
            activity.verb === Verb.DELETED ||
            (activity.verb === Verb.UPDATED && activity.data.status === ChecklistStatus.Deleted);
          const checklistIsStillDeleted = activity.object.status === ChecklistStatus.Deleted;
          const templateIsActive = activity.object.template.status === TemplateConstants.STATUS.ACTIVE;
          objectIsUndeletable = activityIsChecklistDeleted && checklistIsStillDeleted && templateIsActive;
          break;
        }
        default:
          objectIsUndeletable = false;
      }

      return objectIsUndeletable;
    };

    self.undelete = function (activity) {
      const { query, resourceType } = match(activity)
        .with({ objectType: ObjectType.TEMPLATE }, () => {
          return {
            query: TemplateService.undelete(activity.object.id, TemplateConstants.STATUS.ACTIVE),
            resourceType: activity.object.templateType === TemplateType.Page ? 'Page' : 'Workflow',
          };
        })
        .with({ objectType: ObjectType.CHECKLIST }, () => {
          return {
            query: ChecklistService.undelete(activity.object.id, ChecklistStatus.Active),
            resourceType: 'Workflow Run',
          };
        })
        .run();

      return query
        .then(() => {
          ToastService.openToast({
            status: 'success',
            title: `${resourceType} restored`,
          });
        })
        .catch(response => {
          if (response.status === HttpStatus.NOT_FOUND) {
            ToastService.openToast({
              status: 'warning',
              title: `You cannot restore the ${resourceType} it was already restored`,
            });
          } else if (response.status === HttpStatus.PAYMENT_REQUIRED && activity.objectType === ObjectType.TEMPLATE) {
            // paywall - no action
          } else {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems restoring the ${resourceType}`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          }
        });
    };
  });
