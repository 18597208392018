import angular from 'angular';
import { FieldType } from '@process-street/subgrade/process';
import { PromiseQueueConstants } from './promise-queue-constants';
import { StringService } from 'services/string-service';
import { trace } from 'components/trace';
import { PromiseQueueDescGenerator } from './promise-queue-desc-generator-pure';

angular.module('frontStreetApp.services').service('PromiseQueueDescGenerator', function () {
  const logger = trace({ name: 'PromiseQueueDescGenerator' });

  const self = this;

  self.generateUpdateStatusByChecklistId = PromiseQueueDescGenerator.generateUpdateStatusByChecklistId;

  self.generateByChecklistIdAndAction = PromiseQueueDescGenerator.generateByChecklistIdAndAction;

  self.generateUpdateStatusByTaskId = PromiseQueueDescGenerator.generateUpdateStatusByTaskId;

  self.generateForMergeTagsParsingByWidgetId = PromiseQueueDescGenerator.generateForMergeTagsParsingByWidgetId;

  self.generateByTaskIdAndAction = PromiseQueueDescGenerator.generateByTaskIdAndAction;

  self.generateUpdateByFormField = function (widget, fieldValue) {
    return self.generateByFormFieldAndAction(widget, PromiseQueueConstants.FormFieldAction.UPDATE, fieldValue);
  };

  self.generateDeleteByFormField = function (widget, fieldValue) {
    return self.generateByFormFieldAndAction(widget, PromiseQueueConstants.FormFieldAction.DELETE, fieldValue);
  };

  self.generateByFormFieldAndAction = function (widget, action, fieldValue) {
    let postfix = '';
    const value = self.convertFieldValueToStringByFieldType(fieldValue, widget.fieldType);

    switch (action) {
      case PromiseQueueConstants.FormFieldAction.UPDATE:
        postfix = ` ${action} to "${StringService.abbreviate(value, 150)}"`;
        break;
      case PromiseQueueConstants.FormFieldAction.DELETE:
        postfix = ` ${action} value "${StringService.abbreviate(value, 150)}"`;
        break;
      default:
        logger.error('could not recognize action %s', action);
    }

    return `${widget.fieldType} field (id: ${widget.id})${postfix}`;
  };

  self.convertFieldValueToStringByFieldType = function (fieldValue, fieldType) {
    let result = '<unaccessible value>';
    switch (fieldType) {
      case FieldType.Select:
        result = fieldValue.value || '<empty>';
        break;
      case FieldType.Date:
        result = fieldValue.value || '<empty>';
        break;
      case FieldType.Text:
      case FieldType.Textarea:
      case FieldType.Email:
      case FieldType.Number:
      case FieldType.Url:
        result = fieldValue.value || '<empty>';
        break;
      case FieldType.File:
        result = fieldValue.name;
        break;
      case FieldType.Members:
        result = fieldValue.organizationMembershipIds.join(', ');
        break;
      case FieldType.MultiChoice:
      case FieldType.MultiSelect:
        result = fieldValue.itemValues.map(value => `${value.id}: ${value.status}`).join(', ');
        break;
      case FieldType.SendRichEmail: {
        const by = fieldValue.lastSentByUserId;
        const on = fieldValue.lastSentDate;
        result = `Sent ${on} by ${by}`;
        break;
      }
      default:
        result = '<unrecognised field type>';
        logger.error('could not recognize field type %s', fieldType);
    }

    return result;
  };
});
