enum Actions {
  COMPLETE_ALL = 'completeAll',
  ASSIGN_ALL = 'assignAll', // This is not use
  UPDATE_ALL_DUE_DATES = 'updateAllDueDates',
}

enum StdGroupKey {
  OVERDUE = 'Overdue',
  TODAY = 'Today',
  THIS_WEEK = 'ThisWeek',
  NEXT_WEEK = 'NextWeek',
  THIS_MONTH = 'ThisMonth',
  NEXT_MONTH = 'NextMonth',
  NO_DUE_DATE = 'NoDueDate',
}

const OrderedStdGroupKeys = [
  StdGroupKey.OVERDUE,
  StdGroupKey.TODAY,
  StdGroupKey.THIS_WEEK,
  StdGroupKey.NEXT_WEEK,
  StdGroupKey.THIS_MONTH,
  StdGroupKey.NEXT_MONTH,
  StdGroupKey.NO_DUE_DATE,
];

enum Group {
  DUE_DATE = 'DueDate',
  PROCESS = 'Process',
}

enum Filter {
  INBOX = 'inbox',
  UPCOMING = 'upcoming',
  COMMENTS = 'comments',
}

enum Status {
  COMPLETED = 'Completed',
  NOT_COMPLETED = 'NotCompleted',
}

enum TodoResponse {
  OK = 'Ok',
  CONFLICT = 'Conflict',
  NOT_FOUND = 'NotFound',
  FORBIDDEN = 'Forbidden',
  NOTHING_TO_UPDATE = 'NothingToUpdate',
  NOT_UPDATED = 'NotUpdated',
}

enum TodoCode {
  CHECKLIST_LIMIT_REACHED = 'ChecklistLimitReached',
  SOFT_CHECKLIST_LIMIT_REACHED = 'SoftChecklistLimitReached',
  INVALID_DUE_DATE = 'InvalidDueDate',
  TEMPLATE_ARCHIVED = 'TemplateArchived',
  TRANSITION_NOT_ALLOWED = 'TransitionNotAllowed',
}

enum TaskItemDetailsMode {
  INBOX_ITEM = 'InboxItem',
  CHECKLIST_TASK = 'ChecklistTask',
}

export const InboxConstants = {
  Actions,
  Filter,
  Group,
  OrderedStdGroupKeys,
  Status,
  StdGroupKey,
  TaskItemDetailsMode,
  TodoCode,
  TodoResponse,
};
