import * as React from 'react';
import { Box, HStack, Tooltip, Text, BoxProps } from 'components/design/next';
import { FormFieldValue } from '@process-street/subgrade/process';
import { useAudit } from 'components/widgets/form-field/common/FormFieldAudit/use-audit';
import { AiTaskAuditHelperText, CommonAuditProps, ProcessPeteCircle, ProcessPeteIcon } from './common';

export interface FilledAiTaskAuditProps extends BoxProps, CommonAuditProps {
  formFieldValue: FormFieldValue;
}

export const FilledAiTaskAudit: React.FC<React.PropsWithChildren<FilledAiTaskAuditProps>> = ({
  aiTaskTemplate: _,
  formFieldValue,
  ...props
}) => {
  const { audit } = formFieldValue;
  const { formattedDate, formattedPrintDate, isLoading, isPrintMode, username } = useAudit({ audit });

  if (isLoading) return null;
  return (
    <Box display="inline-block" {...props}>
      <HStack>
        <Tooltip label="This form field is an output of an AI task" shouldWrapChildren hasArrow>
          <ProcessPeteCircle bg="blue.100">
            <ProcessPeteIcon color="blue.500" />
          </ProcessPeteCircle>
        </Tooltip>

        <Tooltip label={formattedDate} hasArrow shouldWrapChildren>
          <AiTaskAuditHelperText color={isPrintMode ? 'blue.200' : 'blue.500'}>
            Updated by{' '}
            <Text as="span" variant="inherit">
              {username.replace(/Automation User/, 'Process AI')}
              {username.includes('Automation User') ? ' ' : ' (with AI) '}
            </Text>
            {isPrintMode ? (
              <Text as="span" variant="inherit">
                {formattedDate}
              </Text>
            ) : (
              <Text as="span" variant="inherit">
                {formattedPrintDate}
              </Text>
            )}
          </AiTaskAuditHelperText>
        </Tooltip>
      </HStack>
    </Box>
  );
};
