import { useToast } from 'components/design/next';
import { Muid } from '@process-street/subgrade/core';
import { useInjector } from 'components/injection-provider';
import { RuleEvent } from 'directives/rules/services/rule-event';
import {
  GetAllRulesByTemplateRevisionIdQuery,
  useUpdateRulesDeltaByTemplateRevisionIdMutation,
} from 'features/conditional-logic/query-builder';
import { TaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { WidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { useQueryClient } from 'react-query';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';

type UseSaveConditionalLogicMutationParams = {
  templateRevisionId: Muid;
  templateId?: Muid;
  closeModal: () => void;
};

export const useSaveConditionalLogicMutation = ({
  templateRevisionId,
  templateId,
  closeModal,
}: UseSaveConditionalLogicMutationParams) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { $rootScope } = useInjector('$rootScope');

  const updateRulesMutation = useUpdateRulesDeltaByTemplateRevisionIdMutation({
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems updating the conditional logic rule",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
    onSuccess: (result, variables) => {
      queryClient.invalidateQueries(WidgetsByTemplateRevisionIdQuery.getKey(templateRevisionId));
      queryClient.invalidateQueries(TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }));
      queryClient.invalidateQueries(GetAllRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId }));

      if (templateId) {
        const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
          queryClient,
          templateId,
        });

        templateRevisionCacheSetter.updateDraftLastUpdatedDate();
      }

      $rootScope.$broadcast(
        RuleEvent.RULES_UPDATE_OK,
        templateRevisionId,
        result.definitions,
        result.updatedTaskTemplates,
      );

      const isHidingTaskOrWidgetByDefault = Boolean(
        variables.delta.widgetsHiddenByDefault.length || variables.delta.taskTemplatesHiddenByDefault.length,
      );
      const mainMessage = 'The conditional logic was successfully updated.';
      const autoHideMessage =
        'Form fields added to show rules will be hidden automatically unless the field is impacted by more than one rule.';

      toast({
        title: mainMessage,
        description: isHidingTaskOrWidgetByDefault ? autoHideMessage : '',
        status: 'success',
      });

      closeModal();
    },
  });

  return updateRulesMutation;
};
