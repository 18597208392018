import { FieldType, isFormFieldWidget, isSelectFormFieldWidget, Widget } from '@process-street/subgrade/process';

const ALLOWED_DATA_SET_FORM_FIELD_TYPES = new Set([FieldType.Select]);

export function useDataSetButton(widget: Widget) {
  const widgetSupportsDataSetLinking =
    isFormFieldWidget(widget) && ALLOWED_DATA_SET_FORM_FIELD_TYPES.has(widget.fieldType);

  const shouldShowDataSetButton = widgetSupportsDataSetLinking;
  const shouldShowLinkDataSetButton =
    shouldShowDataSetButton && isSelectFormFieldWidget(widget) && !widget.config.linkId;
  const shouldShowUnlinkDataSetButton =
    shouldShowDataSetButton && isSelectFormFieldWidget(widget) && widget.config.linkId;

  const dataSetButtonToShow: 'link' | 'unlink' | 'none' = shouldShowLinkDataSetButton
    ? 'link'
    : shouldShowUnlinkDataSetButton
    ? 'unlink'
    : 'none';

  return {
    dataSetButtonToShow,
  };
}
