import { FieldValueJson, SimpleFieldValue } from '@process-street/subgrade/process';
import angular from 'angular';
import templateUrl from './email-renderer.component.html';

export class ApprovalSubjectTaskWidgetEmailRendererController {
  public getValue(fieldValue: FieldValueJson) {
    return (fieldValue && (fieldValue as SimpleFieldValue).value) || '';
  }
}

export const ApprovalSubjectTaskWidgetEmailRenderer: angular.IComponentOptions = {
  bindings: {
    formFieldValue: '<',
  },
  controller: ApprovalSubjectTaskWidgetEmailRendererController,
  templateUrl,
};
