import { Muid } from '@process-street/subgrade/core';
import {
  SelectFormFieldConfig,
  FormFieldWidget,
  MultiChoiceItemValueStatus,
  MultiOptionFieldValue,
} from '@process-street/subgrade/process';
import angular from 'angular';
import templateUrl from './multi-choice-renderer.component.html';

export class ApprovalSubjectTaskWidgetMultiChoiceRendererController {
  public getValue(widget: FormFieldWidget, fieldValue?: MultiOptionFieldValue) {
    return this.getRenderedValueForMultiChoice(widget, fieldValue);
  }

  public getRenderedValueForMultiChoice(widget: FormFieldWidget, fieldValue?: MultiOptionFieldValue) {
    if (fieldValue) {
      const selectedIds = fieldValue.itemValues.reduce((ids: Muid[], item) => {
        if (item.status === MultiChoiceItemValueStatus.Selected) {
          ids.push(item.id);
        }
        return ids;
      }, []);

      return (widget.config as SelectFormFieldConfig).items
        .filter(item => selectedIds.includes(item.id))
        .map(item => item.name)
        .join(', ');
    } else {
      return '';
    }
  }
}

export const ApprovalSubjectTaskWidgetMultiChoiceRenderer: angular.IComponentOptions = {
  bindings: {
    formFieldValue: '<',
    widget: '<',
  },
  controller: ApprovalSubjectTaskWidgetMultiChoiceRendererController,
  templateUrl,
};
