import { PremadeTemplateOverview } from '@process-street/subgrade/process/premade-template-model';
import React from 'react';
import styles from './TemplateGalleryCard.module.scss';

interface TemplateGalleryCardProps {
  premadeTemplateOverview: PremadeTemplateOverview;
  onSelect: (templateOverview: PremadeTemplateOverview) => void;
}

export const TemplateGalleryCard: React.FunctionComponent<React.PropsWithChildren<TemplateGalleryCardProps>> = ({
  premadeTemplateOverview,
  onSelect,
}) => {
  const { name, description, previewImageUrl } = premadeTemplateOverview;
  const onTemplateClick = () => {
    onSelect(premadeTemplateOverview);
  };
  return (
    <div className={styles.container} onClick={onTemplateClick}>
      <img loading="lazy" src={previewImageUrl} alt="Template Preview Image" />
      <div className={styles.textContent}>
        <h5 className={styles.templateName}>{name}</h5>
        <p className={styles.templateDescription}>{description}</p>
      </div>
    </div>
  );
};
