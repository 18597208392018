import React from 'react';
import { Group, GroupType, User } from '@process-street/subgrade/core';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
  Spinner,
  Text,
} from 'components/design/next';
import { GroupMemberList } from 'components/permissions/group-member-list/component';
import { useInjector } from 'components/injection-provider';
import { useGroupMemberships } from 'features/group-memberships/query-builder';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { toEntityMap } from '@process-street/subgrade/redux';
import { trace } from 'components/trace';
import { isStandardUserOm } from '@process-street/subgrade/util/membership-utils';
import sortBy from 'lodash/sortBy';

export interface GroupMembersIndicatorProps {
  group: Group;
  allFreeMembersGroup: Group;
  showLimit?: number;
}

const membersRenderLimit = 10;

export const GroupMembersIndicator: React.FC<React.PropsWithChildren<GroupMembersIndicatorProps>> = ({
  group,
  allFreeMembersGroup,
  showLimit = membersRenderLimit,
}) => {
  const logger = trace({ name: 'GroupMembersIndicator' });
  const organizationMembershipsQuery = useGetAllOrganizationMembershipsQuery(
    {
      organizationId: group.organization.id,
    },
    {
      select: oms => toEntityMap(oms.filter(isStandardUserOm)),
    },
  );

  const isAllMembersGroup = group.groupType === GroupType.AllMembers;

  // AllMembers group contains AllFreeMembers group underneath, so we need to include it
  const groupIds = isAllMembersGroup ? [allFreeMembersGroup.id, group.id] : [group.id];

  const groupMembershipsQuery = useGroupMemberships(
    {},
    {
      select: groupMemberships =>
        groupMemberships.filter(gm => groupIds.includes(gm.group.id)).map(gm => gm.organizationMembership),
    },
  );

  const { $state } = useInjector('$state');

  const count = groupMembershipsQuery.data?.length ?? 0;
  const indicatorString = count ? `Show ${count} ${count > 1 ? 'members' : 'member'}` : '';
  const members =
    organizationMembershipsQuery.isSuccess && groupMembershipsQuery.isSuccess
      ? sortBy(
          groupMembershipsQuery.data.reduce<User[]>((users, om) => {
            const user = organizationMembershipsQuery.data[om.id]?.user;
            if (user) {
              users.push(user);
            } else {
              logger.error(`User not found for organization membership ${om.id}`);
            }
            return users;
          }, []),
          [u => u.username.toLowerCase()],
        )
      : [];

  if (!count && organizationMembershipsQuery.isSuccess && groupMembershipsQuery.isSuccess) {
    return null;
  }

  const viewAllHref = isAllMembersGroup
    ? $state.href('organizationManage.tab', { tab: 'users' })
    : $state.href('groupManage.tab', { id: group.id, tab: 'members' });

  return groupMembershipsQuery.isLoading ? (
    <Spinner />
  ) : (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton p={0} _hover={{ backgroundColor: 'transparent' }} _focus={{ boxShadow: 'none' }}>
          <Text fontSize="xs" color="brand.500" mr={1}>
            {indicatorString}
          </Text>
          <AccordionIcon fontSize="xs" color="brand.500" />
        </AccordionButton>
        <AccordionPanel pb={0} pl={0}>
          <Box pb={2}>
            <GroupMemberList members={members} limit={showLimit} showEmail={false} />
          </Box>
          {count > showLimit && (
            <Link
              href={viewAllHref}
              fontSize="xs"
              textDecor="none"
              color="brand.500"
              _hover={{ color: 'brand.500', textDecor: 'none' }}
            >
              View All
            </Link>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
