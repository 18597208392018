import angular from 'angular';
import { PlanLevel, PlanInterval } from '@process-street/subgrade/billing';
import { SubscriptionStatus } from '@process-street/subgrade/core';
import { PlanLevelSelectorState } from './plan-level-selector-service.interface';

angular
  .module('frontStreetApp.services')
  .service('PlanLevelSelectorService', function (BillingService, PlanService, OrganizationService) {
    const self = this;

    /**
     * Plan's panel possible state
     * @readonly
     * @enum {string}
     */
    self.PlanPanelStateMap = PlanLevelSelectorState;

    /**
     * Whether this plan level is downgrading comparably to current plan
     *
     * @param {PlanLevel} newPlanLevel
     * @param {PlanLevel} selectedPlanLevel
     * @returns {boolean}
     */
    self.isPlanLevelDowngrading = function (newPlanLevel, selectedPlanLevel) {
      return (
        newPlanLevel !== PlanLevel.Free &&
        selectedPlanLevel &&
        newPlanLevel !== selectedPlanLevel &&
        !PlanService.isPlanLevelAfter(newPlanLevel /* comparingLevel */, selectedPlanLevel /* toLevel */)
      );
    };

    /**
     * Returns level name's state
     *
     * @param {Object} organization
     * @param {Plan} currentPlan
     * @param {PlanLevel} planLevel
     * @param {boolean} selected
     * @returns {PlanPanelStateMap}
     */
    self.getPlanLevelState = function (organization, currentPlan, planLevel, selected) {
      const trialing = organization && OrganizationService.isTrialing(organization.subscription);
      const expired =
        organization && (!currentPlan || BillingService.isSubscriptionExpired(organization.subscription, currentPlan));
      const legacy = currentPlan && PlanService.isPlanIdLegacy(currentPlan.id);

      let state = PlanLevelSelectorState.SELECT_PLAN;
      const subscription = organization && organization.subscription;
      const downgradeAllowed =
        subscription &&
        (subscription.status === SubscriptionStatus.Trialing ||
          subscription.status === SubscriptionStatus.PastDue ||
          subscription.status === SubscriptionStatus.Canceled);

      if (!expired && !legacy && !trialing && currentPlan && currentPlan.level === planLevel) {
        state = PlanLevelSelectorState.CURRENT_PLAN;
      } else if (selected) {
        state = PlanLevelSelectorState.SELECTED_PLAN;
      } else if (
        PlanService.isPlanLevelEnterprise(planLevel) ||
        (self.isPlanLevelDowngrading(planLevel, currentPlan.level) && !downgradeAllowed)
      ) {
        state = PlanLevelSelectorState.CONTACT_US;
      }

      return state;
    };

    // Cost

    /**
     * Returns formatted cost as required by the PS-2471
     *
     * @param {number} cost
     * @returns {string}
     */
    self.formatCostAsString = function (cost) {
      const intPart = Math.floor(cost);
      const remainderPart = `${Math.floor((cost % 1) * 10).toFixed(0)}0`;
      return `${intPart}.${remainderPart}`;
    };

    /**
     * @typedef {Object} PlanCostDetailsDescription
     * @property {string} perMember
     * @property {string} total
     */

    /**
     * Returns cost description for selected interval of a plan
     *
     * @param {Plan} plan
     * @param {PlanInterval} interval
     * @param {number} quantity
     * @param {number} discount
     * @param {Organization} organization
     * @returns {PlanCostDetailsDescription}
     */
    self.getPlanCostDetailsDescription = function (plan, interval, quantity, discount, organization) {
      const planCost = PlanService.calculatePlanCost(plan, quantity, discount, organization);

      if (interval === PlanInterval.Monthly) {
        const monthlyCost = planCost.cost;
        const monthlyTotalCost = planCost.totalCost;

        return {
          planCost,
          perMember: `$${monthlyCost}`,
          total: `${quantity} members for $${monthlyTotalCost} per month`,
          totalNew: `${quantity} members for $${planCost.rawTotalCost} / month`,
        };
      } else {
        const yearlyCost = self.formatCostAsString(planCost.cost / 12);
        const yearlyTotalCost = planCost.totalCost;

        return {
          planCost,
          perMember: `$${yearlyCost} (Save&nbsp;17%!)`,
          total: `${quantity} members for $${yearlyTotalCost} per year`,
          totalNew: `${quantity} members for $${planCost.rawTotalCost} / year`,
        };
      }
    };
  });
