import { Muid, OrganizationMembershipWithUser, UserType } from '@process-street/subgrade/core';
import { Box, HStack, VStack, Text, Icon, Link } from 'components/design/next';
import * as React from 'react';
import { Avatar, AvatarSize } from 'components/common/Avatar';
import { UserUtils } from '@process-street/subgrade/util';
import { MembershipPermissionSelector } from './membership-permission-selector';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { GetTaskTemplatePermitsQuery } from 'features/template-revisions/query-builder';
import { ALL_ANONYMOUS_GROUP_USERNAME } from '@process-street/subgrade/util/membership-utils';
import { match } from 'ts-pattern';
import { useMatch } from '@process-street/adapters/navigation';

export interface MembershipPermissionItemProps {
  membership: OrganizationMembershipWithUser;
  taskTemplates: TaskTemplate[];
  templateRevisionId: TemplateRevision['id'];
  isPredefinedGroup: boolean;
  onRemoveMembership: (membershipId: Muid) => void;
}

export const MembershipPermissionItem: React.FC<React.PropsWithChildren<MembershipPermissionItemProps>> = ({
  membership,
  taskTemplates,
  templateRevisionId,
  isPredefinedGroup,
  onRemoveMembership,
}) => {
  const isEditorV2 = useMatch('templateV2');
  const { user } = membership;
  const taskTemplatesIdsSet = new Set(taskTemplates.map(taskTemplate => taskTemplate.id));
  const { data: permits } = GetTaskTemplatePermitsQuery.useQuery(
    {
      templateRevisionId,
    },
    {
      select: data =>
        data.permits.filter(
          permit =>
            taskTemplatesIdsSet.has(permit.taskTemplate.id) && permit.organizationMembership.id === membership.id,
        ),
    },
  );

  const handleRemove = () => {
    if (isPredefinedGroup) return;
    onRemoveMembership(membership.id);
  };

  const isGroup = user.userType === UserType.Group;

  const isAllAnonymousGroup = user.username === ALL_ANONYMOUS_GROUP_USERNAME && user.userType === UserType.Group;

  return (
    <HStack justifyContent="space-between" w="100%">
      <HStack>
        <Box w="8">
          <Avatar user={user} showUnconfirmedBadge={true} size={isEditorV2 ? AvatarSize.Small : undefined} />
        </Box>
        <VStack alignItems="flex-start" minW="40" spacing="0">
          <Text fontWeight="medium" variant="-1" color="gray.600" fontSize={isEditorV2 ? 'sm' : 'inherit'}>
            {isAllAnonymousGroup ? 'Share Link' : UserUtils.getLabel(user, false)}
          </Text>

          {match({ isGroup, isPredefinedGroup, isAllAnonymousGroup })
            .with({ isPredefinedGroup: true, isAllAnonymousGroup: true }, () => (
              <HStack>
                <Text fontWeight="medium" variant="-2" color="gray.400" fontSize={isEditorV2 ? 'sm' : 'inherit'}>
                  Option
                </Text>
                <Link href="https://www.process.st/help/docs/sharing-workflow-runs/" isExternal display="flex">
                  <Icon icon="info-circle" variant="far" size="3" color="gray.400" />
                </Link>
              </HStack>
            ))
            .with({ isGroup: true }, () => (
              <Text fontWeight="medium" variant="-2" color="gray.400" fontSize={isEditorV2 ? 'sm' : 'inherit'}>
                Group
              </Text>
            ))
            .otherwise(() => (
              <Text fontWeight="medium" variant="-2" color="gray.400">
                {user.email}
              </Text>
            ))}
        </VStack>
      </HStack>
      <HStack>
        <Box pb="5">
          <MembershipPermissionSelector
            membership={membership}
            permits={permits}
            isPredefinedGroup={isPredefinedGroup}
            taskTemplates={taskTemplates}
            templateRevisionId={templateRevisionId}
          />
        </Box>

        {!isPredefinedGroup && (
          <Icon
            icon="user-times"
            variant="far"
            size="4"
            color="gray.400"
            pb="4"
            _hover={{ cursor: 'pointer' }}
            onClick={handleRemove}
          />
        )}
      </HStack>
    </HStack>
  );
};
