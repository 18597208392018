import * as React from 'react';
import { useIsEditable } from 'features/rich-text';
import { ThemeTypings } from 'components/design/next';

export const useGetFocusBarColor = () => {
  const editable = useIsEditable();
  return React.useCallback(
    ({ light, dark }: { light: ThemeTypings['colors']; dark: ThemeTypings['colors'] }) => {
      return editable ? dark : light;
    },
    [editable],
  );
};
