const UUID_DIGITS = 32;
const MUID_DIGITS = 22;
const URL_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_=';

const flip = (bytes: number[]): number[] => {
  const p1 = bytes.slice(0, bytes.length / 2);
  const p2 = bytes.slice(bytes.length / 2);
  return p2.concat(p1);
};

const decode64 = (str: string, alphabet: string) => {
  const last1 = alphabet.indexOf(str.charAt(str.length - 1));
  const last2 = alphabet.indexOf(str.charAt(str.length - 2));

  let length = (str.length / 4) * 3;
  if (last1 === 64) length--; // Padding chars, so skip
  if (last2 === 64) length--; // Padding chars, so skip

  const bytes: number[] = [];
  let chr1: number, chr2: number, chr3: number;
  let enc1: number, enc2: number, enc3: number, enc4: number;
  let i,
    j = 0;

  for (i = 0; i < length; i += 3) {
    // Get the 3 octets in 4 ascii chars
    enc1 = alphabet.indexOf(str.charAt(j++));
    enc2 = alphabet.indexOf(str.charAt(j++));
    enc3 = alphabet.indexOf(str.charAt(j++));
    enc4 = alphabet.indexOf(str.charAt(j++));

    chr1 = (enc1 << 2) | (enc2 >> 4);
    chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
    chr3 = ((enc3 & 3) << 6) | enc4;

    bytes[i] = chr1;
    if (enc3 !== 64) bytes[i + 1] = chr2;
    if (enc4 !== 64) bytes[i + 2] = chr3;
  }

  return bytes;
};

const encode64 = (bytes: number[], alphabet: string) => {
  let str = '';
  let chr1, chr2, chr3;
  let enc1, enc2, enc3, enc4;
  let i,
    j = 0;

  for (i = 0; i < bytes.length; i += 3) {
    chr1 = bytes[j++];
    chr2 = bytes[j++];
    chr3 = bytes[j++];

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = 64;
      enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }

    str += alphabet.charAt(enc1);
    str += alphabet.charAt(enc2);
    str += alphabet.charAt(enc3);
    str += alphabet.charAt(enc4);
  }

  return str;
};

export const MuidConverter = {
  fromUuid: (uuid: string) => {
    const normalizedUuid = uuid && uuid.split('-').join('').toLowerCase();
    if (!normalizedUuid || normalizedUuid.length !== UUID_DIGITS) {
      throw new RangeError('UUID must have 32 hexadecimal digits (case insensitive, optional dashes)');
    }

    const bytes: number[] = [];
    let i;
    let j = 0;

    for (i = 0; i < UUID_DIGITS; i += 2) {
      const hex1 = parseInt(normalizedUuid.charAt(j++), 16);
      const hex2 = parseInt(normalizedUuid.charAt(j++), 16);
      const byte = parseInt(hex1.toString(16) + hex2.toString(16), 16);
      bytes.push(byte);
    }

    return encode64(flip(bytes), URL_ALPHABET).split('=').join('');
  },
  toUuid: (muid: string) => {
    if (!muid || muid.length !== MUID_DIGITS) {
      throw new RangeError('muid must have 22 URL-safe base64 digits');
    }

    const bytes = flip(decode64(muid, URL_ALPHABET).slice(0, 16));
    const hexes: string[] = [];
    let i;

    for (i = 0; i < bytes.length; i++) {
      hexes.push(`00${bytes[i].toString(16)}`.substr(-2));
    }

    hexes.splice(10, 0, '-');
    hexes.splice(8, 0, '-');
    hexes.splice(6, 0, '-');
    hexes.splice(4, 0, '-');

    return hexes.join('');
  },
};
