import { Box, Divider, Flex, Text } from 'components/design/next';
import { IntervalSelector } from 'pages/organizations/manage/billing/components/interval-selector';
import { CreditCard } from 'pages/organizations/manage/billing/components/credit-card';
import { PlanSummary } from 'pages/organizations/manage/billing/components/plan-summary';
import * as React from 'react';
import {
  IntervalToPlanCostInfoMap,
  LevelToPlanInfoMap,
  PlanCostQuantity,
} from 'pages/organizations/manage/billing/models';
import { Plan, PlanInterval, PlanLevel } from '@process-street/subgrade/billing';
import { Option } from '@process-street/subgrade/core';
import { useIsStartupPlan } from 'hooks/use-is-startup-plan';

export type PlanBillingSummaryMode = 'planUpgrade' | 'intervalChange';

export type PlanBillingSummaryProps = {
  cardDefined: boolean;
  costMap: Option<IntervalToPlanCostInfoMap>;
  currentPlan: Plan;
  mode: PlanBillingSummaryMode;
  onCountryChange: (country: string) => void;
  onIntervalChange: (interval: PlanInterval) => void;
  onSubmit: () => void;
  onZipChange: (zip: string) => void;
  planCostQuantity: PlanCostQuantity;
  selectedInterval: PlanInterval;
  selectedPlanLevel: PlanLevel;
  submitting: boolean;
};

const getPlanLevelTitle = (level: PlanLevel) => {
  if (level === PlanLevel.Professional) {
    return 'Pro';
  } else {
    return level;
  }
};

const getTitle = (mode: PlanBillingSummaryMode, level: PlanLevel, isStartupPlan: boolean) => {
  if (isStartupPlan) {
    return 'Purchase Startup plan';
  }

  if (mode === 'planUpgrade') {
    return `Upgrade to ${getPlanLevelTitle(level)}`;
  } else if (mode === 'intervalChange') {
    return 'Change Billing Cycle';
  } else {
    return '';
  }
};

const getButtonTitle = (
  mode: PlanBillingSummaryMode,
  level: PlanLevel,
  interval: PlanInterval,
  isStartupPlan: boolean,
) => {
  if (isStartupPlan) {
    return 'Buy now';
  }

  if (mode === 'planUpgrade') {
    return `Upgrade to ${getPlanLevelTitle(level)}`;
  } else if (mode === 'intervalChange') {
    const text = interval === PlanInterval.Monthly ? 'Monthly' : 'Yearly';
    return `Change to ${text}`;
  } else {
    return '';
  }
};

export const PlanBillingSummary: React.FC<React.PropsWithChildren<PlanBillingSummaryProps>> = ({
  costMap,
  onCountryChange,
  onZipChange,
  onIntervalChange,
  onSubmit,
  planCostQuantity,
  selectedInterval,
  selectedPlanLevel,
  submitting,
  cardDefined,
  currentPlan,
  mode,
}) => {
  const isStartupPlan = useIsStartupPlan(currentPlan);
  const planInfo = LevelToPlanInfoMap[selectedPlanLevel];

  const buttonAllowed =
    (mode === 'intervalChange' && currentPlan.interval !== selectedInterval) || mode === 'planUpgrade';

  const title = getTitle(mode, selectedPlanLevel, isStartupPlan);
  const buttonTitle = buttonAllowed
    ? getButtonTitle(mode, selectedPlanLevel, selectedInterval, isStartupPlan)
    : undefined;
  const cardFormVisible = !cardDefined && mode === 'planUpgrade';

  return (
    <Box>
      <Divider opacity={1} borderColor="gray.200" my={8} />
      <Text variant="1" color="gray.700" fontWeight="bold">
        {title}
      </Text>

      <Flex direction="row" mt={8}>
        <Flex direction="column">
          {costMap && (
            <IntervalSelector selectedInterval={selectedInterval} costMap={costMap} onChange={onIntervalChange} />
          )}

          {cardFormVisible && (
            <Box mt={8}>
              <CreditCard onCountryChange={onCountryChange} onZipChange={onZipChange} />
            </Box>
          )}
        </Flex>

        {planInfo && costMap && (
          <PlanSummary
            buttonTitle={buttonTitle}
            costMap={costMap}
            onSubmit={onSubmit}
            planCostQuantity={planCostQuantity}
            planInfoName={planInfo.name}
            planInterval={selectedInterval}
            submitting={submitting}
          />
        )}
      </Flex>
    </Box>
  );
};
