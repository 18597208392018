import { Muid } from '@process-street/subgrade/core';
import {
  Attachment,
  FormFieldValueUpdateResult,
  GetUploadUrlForAttachmentResponse,
  GetUploadUrlForFormFieldWidgetResponse,
  GetUploadUrlForTemplateWidgetResponse,
  Widget,
} from '@process-street/subgrade/process';
import angular from 'angular';
import { FileUploadResource } from 'components/file-upload/services/file-upload.resource';

export interface FileUploadApi {
  getUploadUrlForAttachment(
    taskId: Muid,
    fileName: string,
    mimeType: string,
  ): angular.IPromise<GetUploadUrlForAttachmentResponse>;

  finishUploadForAttachment(
    taskId: Muid,
    key: string,
    originalFilename: string,
    contentType: string,
  ): angular.IPromise<Attachment>;

  getUploadUrlForFormFieldWidget(
    checklistRevisionId: Muid,
    widgetId: Muid,
    fileName: string,
    mimeType: string,
  ): angular.IPromise<GetUploadUrlForFormFieldWidgetResponse>;

  finishUploadForFormFieldWidget(
    checklistRevisionId: Muid,
    widgetId: Muid,
    key: string,
    originalFilename: string,
    contentType: string,
  ): angular.IPromise<FormFieldValueUpdateResult>;

  getUploadUrlForTemplateWidget(
    headerId: Muid,
    fileName: string,
    mimeType: string,
  ): angular.IPromise<GetUploadUrlForTemplateWidgetResponse>;

  finishUploadForTemplateWidget(
    headerId: Muid,
    key: string,
    originalFilename: string,
    contentType: string,
  ): angular.IPromise<Widget>;
}

export const FileUploadApiImpl = function (fileUploadResource: FileUploadResource): FileUploadApi {
  'ngInject';

  const getUploadUrlForAttachment = (taskId: Muid, fileName: string, mimeType: string) =>
    fileUploadResource.getUploadUrlForAttachment({ taskId, fileName, mimeType }).$promise;

  const finishUploadForAttachment = (taskId: Muid, key: string, originalFilename: string, contentType: string) =>
    fileUploadResource.finishUploadForAttachment({ contentType, key, originalFilename, taskId }).$promise;

  const getUploadUrlForFormFieldWidget = (
    checklistRevisionId: Muid,
    widgetId: Muid,
    fileName: string,
    mimeType: string,
  ) =>
    fileUploadResource.getUploadUrlForFormFieldWidget({ checklistRevisionId, widgetId, fileName, mimeType }).$promise;

  const finishUploadForFormFieldWidget = (
    checklistRevisionId: Muid,
    widgetId: Muid,
    key: string,
    originalFilename: string,
    contentType: string,
  ) =>
    fileUploadResource.finishUploadForFormFieldWidget({
      checklistRevisionId,
      contentType,
      key,
      originalFilename,
      widgetId,
    }).$promise;

  const getUploadUrlForTemplateWidget = (headerId: Muid, fileName: string, mimeType: string) =>
    fileUploadResource.getUploadUrlForTemplateWidget({ headerId, fileName, mimeType }).$promise;

  const finishUploadForTemplateWidget = (headerId: Muid, key: string, originalFilename: string, contentType: string) =>
    fileUploadResource.finishUploadForTemplateWidget({ contentType, key, originalFilename, headerId }).$promise;

  return {
    finishUploadForAttachment,
    finishUploadForFormFieldWidget,
    finishUploadForTemplateWidget,
    getUploadUrlForAttachment,
    getUploadUrlForFormFieldWidget,
    getUploadUrlForTemplateWidget,
  };
};
