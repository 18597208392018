import angular from 'angular';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { connectService } from '../reducers/util';
import { OrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { PERMISSION_STATS_KEY } from 'components/permissions/services/query';
import { queryClient } from 'components/react-root';
import { EventName } from 'services/event-name';

angular
  .module('frontStreetApp.services')
  .service(
    'OrganizationMembershipService',
    function ($ngRedux, $rootScope, GroupMembershipService, OrganizationMembershipActions) {
      connectService('OrganizationMembershipService', $ngRedux, null, OrganizationMembershipActions)(this);

      const self = this;

      // DAO access

      /**
       * Gets all organization memberships (used to get the list of all users)
       * This call will not return memberships of users that they have through groups
       *
       * @param organizationId
       * @param flushCache
       *
       * @return {Promise} with list of organization memberships
       */
      self.getAllByOrganizationId = (organizationId, flushCache) =>
        this.actions
          .getAllOrgMembershipByOrgId(organizationId, flushCache)
          .then(() =>
            OrganizationMembershipSelector.getAllWithUserByOrganizationId(organizationId)($ngRedux.getState()),
          );

      self.getByOrganizationIdAndUserId = (organizationId, userId, flushCache) =>
        self
          .getAllByOrganizationId(organizationId, flushCache)
          .then(memberships => memberships.find(membership => membership.user.id === userId));

      // THE Rest

      self.isAdminByUserIdAndOrganizationId = function (userId, organizationId, flushCache) {
        return self
          .getAllByOrganizationId(organizationId, flushCache)
          .then(memberships => memberships.some(membership => membership.user.id === userId && membership.admin));
      };

      self.isGuestByUserIdAndOrganizationId = function (userId, organizationId, flushCache) {
        return self
          .getAllByOrganizationId(organizationId, flushCache)
          .then(memberships => memberships.some(membership => membership.user.id === userId && membership.guest));
      };

      self.setLevel = function (membershipId, newLevel) {
        return this.actions.setLevel(membershipId, newLevel).then(({ payload: updatedMembership }) => {
          if (newLevel === 'Guest') {
            GroupMembershipService.removeFromAllGroups(updatedMembership);
          }
          $rootScope.$broadcast(EventName.ORGANIZATION_MEMBERSHIP_UPDATED, updatedMembership);

          queryClient.invalidateQueries(OrganizationMembershipsQuery.key);
          queryClient.invalidateQueries(PERMISSION_STATS_KEY);

          return updatedMembership;
        });
      };

      self.getAllByUserId = function (userId, flushCache) {
        return this.actions.getAllByUserId(userId, flushCache).then(({ payload }) => payload);
      };

      self.deleteByOrganizationIdAndUserId = function (organizationId, userId) {
        return this.actions.deleteByOrganizationIdAndUserId(organizationId, userId).then(({ payload: membership }) => {
          $rootScope.$broadcast(EventName.ORGANIZATION_MEMBERSHIP_DELETED, membership);

          queryClient.invalidateQueries(OrganizationMembershipsQuery.key);
          queryClient.invalidateQueries(PERMISSION_STATS_KEY);

          return membership;
        });
      };

      self.find = (query, includes) => this.actions.find(query, includes);
    },
  );
