import { SolutionTypeTag, AutomationAppName, AutomationAppType } from '@process-street/subgrade/automation';
import { assign, createMachine } from 'xstate';
import { match, P } from 'ts-pattern';
import { Muid } from '@process-street/subgrade/core';

export interface Context {
  modalView: 'all' | 'task';
  automationInstanceId?: Muid;
  automationAppName?: AutomationAppName;
  automationType?: AutomationAppType;
  solutionTypeTag?: SolutionTypeTag;
  hasUnsavedChanges: boolean;
}

export type Event =
  | {
      type: 'SOLUTION_TYPE_SELECTED';
      payload:
        | {
            solutionTypeTag: SolutionTypeTag;
            automationAppName?: AutomationAppName;
          }
        | {
            solutionTypeTag: SolutionTypeTag.WhenTaskCheckedThen;
            modalView?: Context['modalView'];
          };
    }
  | {
      type: 'AUTOMATION_SELECTED';
      payload: {
        id: Muid;
        automationType: AutomationAppType;
        solutionTypeTag?: SolutionTypeTag;
        modalView?: Context['modalView'];
      };
    }
  | {
      type: 'AUTOMATION_PLANTED';
      payload: {
        automationAppName: AutomationAppName;
      };
    }
  | {
      type: 'AUTOMATION_CONFIGURED';
      payload: {
        id: Muid;
        automationType: AutomationAppType;
      };
    }
  | {
      type: 'AUTOMATION_DELETED';
    }
  | {
      type: 'SET_HAS_UNSAVED_CHANGES';
      hasUnsavedChanges: boolean;
    }
  | {
      type: 'LEAVE';
    }
  | {
      type: 'STAY';
    };

export type State =
  | {
      value: 'idle' | 'hidden';
      context: Context & {
        selectedAutomation: undefined;
        selectedSolutionType: undefined;
        selectedAutomationAppName: undefined;
        selectedAutomationType: undefined;
      };
    }
  | {
      value: 'creating';
      context: Context & {
        selectedAutomation: undefined;
        selectedAutomationAppName: AutomationAppName | undefined;
        selectedAutomationType: undefined;
        selectedSolutionType: SolutionTypeTag;
      };
    }
  | {
      value: 'editing' | 'editing.initial' | 'editing.unsavedChanges';
      context: Context & {
        selectedAutomation: Muid;
        selectedAutomationType: AutomationAppType;
        selectedSolutionType: SolutionTypeTag;
        selectedAutomationAppName: AutomationAppName;
        hasUnsavedChanges: boolean;
      };
    };

export enum Actions {
  SetSelectedSolutionType = 'setSelectedSolutionType',
  SetAutomationAppName = 'setAutomationAppName',
  SetSelectedAutomation = 'setSelectedAutomation',
  DeleteAutomation = 'deleteAutomation',
}

export type Action = { type: Actions };

export const automationSelectorMachine = createMachine<Context, Event, State>(
  {
    predictableActionArguments: true,
    schema: {
      actions: {} as Action,
      context: {} as Context,
      events: {} as Event,
    },
    id: 'AutomationSelector',
    initial: 'hidden',
    context: {
      modalView: 'all',
      automationInstanceId: undefined,
      automationType: undefined,
      solutionTypeTag: undefined,
      automationAppName: undefined,
      hasUnsavedChanges: false,
    },
    on: {
      AUTOMATION_DELETED: {
        target: 'idle',
        actions: [Actions.DeleteAutomation],
      },
      SOLUTION_TYPE_SELECTED: {
        target: 'creating',
        actions: [Actions.SetSelectedSolutionType],
      },
      AUTOMATION_SELECTED: {
        target: 'editing',
        actions: [Actions.SetSelectedAutomation],
      },
      LEAVE: {
        target: 'hidden',
        actions: [Actions.DeleteAutomation],
      },
    },
    states: {
      hidden: {
        always: [
          {
            target: 'creating',
            cond: ctx => Boolean(ctx.solutionTypeTag),
          },
        ],
      },
      idle: {},
      creating: {
        on: {
          AUTOMATION_PLANTED: {
            target: 'creating',
            actions: [Actions.SetAutomationAppName],
          },
          AUTOMATION_CONFIGURED: {
            target: 'editing',
            actions: [Actions.SetSelectedAutomation],
          },
        },
      },
      editing: {
        initial: 'initial',
        on: {
          SET_HAS_UNSAVED_CHANGES: {
            actions: assign({
              hasUnsavedChanges: (_ctx, event) => event.hasUnsavedChanges,
            }),
          },
          LEAVE: [
            {
              target: '.unsavedChanges',
              cond: ctx => ctx.hasUnsavedChanges,
            },
            {
              target: 'hidden',
              actions: [Actions.DeleteAutomation],
            },
          ],
          SOLUTION_TYPE_SELECTED: [
            {
              target: '.unsavedChanges',
              cond: ctx => ctx.hasUnsavedChanges,
            },
            {
              target: 'creating',
              actions: [Actions.SetSelectedSolutionType],
            },
          ],
          AUTOMATION_SELECTED: [
            {
              target: '.unsavedChanges',
              cond: ctx => ctx.hasUnsavedChanges,
            },
            {
              target: 'editing',
              actions: [Actions.SetSelectedAutomation],
            },
          ],
        },
        states: {
          initial: {},
          unsavedChanges: {
            on: {
              STAY: 'initial',
            },
          },
        },
      },
    },
  },
  {
    actions: {
      [Actions.DeleteAutomation]: assign<Context, Event>({
        automationAppName: undefined,
        automationInstanceId: undefined,
        automationType: undefined,
        solutionTypeTag: undefined,
        modalView: 'all',
        hasUnsavedChanges: false,
      }),

      [Actions.SetSelectedAutomation]: assign<Context, Event>({
        automationInstanceId: (ctx, evt) =>
          match(evt)
            .with({ type: P.union('AUTOMATION_CONFIGURED', 'AUTOMATION_SELECTED') }, ({ payload }) => payload.id)
            .otherwise(() => ctx.automationInstanceId),
        automationType: (ctx, evt) =>
          match(evt)
            .with(
              { type: P.union('AUTOMATION_CONFIGURED', 'AUTOMATION_SELECTED') },
              ({ payload }) => payload.automationType,
            )
            .otherwise(() => ctx.automationType),
        modalView: (ctx, evt) =>
          match(evt)
            .with(
              {
                type: 'AUTOMATION_SELECTED',
                payload: { modalView: P.string },
              },
              ({ payload }) => payload.modalView ?? ctx.modalView,
            )
            .otherwise(() => ctx.modalView),
        solutionTypeTag: (ctx, evt) =>
          match(evt)
            .with({ type: 'AUTOMATION_SELECTED' }, ({ payload }) => payload.solutionTypeTag)
            .otherwise(() => ctx.solutionTypeTag),
      }),

      [Actions.SetSelectedSolutionType]: assign<Context, Event>({
        solutionTypeTag: (ctx, evt) =>
          match(evt)
            .with({ type: 'SOLUTION_TYPE_SELECTED' }, ({ payload }) => payload.solutionTypeTag)
            .otherwise(() => ctx.solutionTypeTag),
        automationAppName: (_ctx, evt) =>
          match(evt)
            .with(
              { type: 'SOLUTION_TYPE_SELECTED', payload: { automationAppName: P.string } },
              ({ payload }) => payload.automationAppName,
            )
            .otherwise(() => undefined),
        automationInstanceId: undefined,
        automationType: undefined,
        modalView: (ctx, evt) =>
          match(evt)
            .with(
              { type: 'SOLUTION_TYPE_SELECTED', payload: { modalView: P.string } },
              ({ payload }) => payload.modalView ?? ctx.modalView,
            )
            .otherwise(() => ctx.modalView),
      }),

      [Actions.SetAutomationAppName]: assign<Context, Event>({
        automationAppName: (ctx, evt) =>
          match(evt)
            .with({ type: 'AUTOMATION_PLANTED' }, ({ payload }) => payload.automationAppName)
            .otherwise(() => ctx.automationAppName),
      }),
    },
  },
);
