import { Store } from 'redux';
import { Persistor, persistStore } from 'redux-persist';
import { ReduxPersistorService } from './redux-persistor.service';

export class ReduxPersistorServiceImpl implements ReduxPersistorService {
  public static $inject = ['$ngRedux'];

  private readonly persistor: Persistor;

  constructor(private readonly store: Store) {
    this.persistor = persistStore(this.store);
  }

  public resumePersist = () => this.persistor.persist();

  public pausePersist = () => this.persistor.pause();

  public purge = () => this.persistor.purge();
}
