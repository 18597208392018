import * as React from 'react';
import { Icon, MenuItem, MenuItemProps } from 'components/design/next';
import { useWidgetActorRef } from '../../../shared/widget-context';

export const MoveDownMenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = props => {
  const { widgetActorRef } = useWidgetActorRef();
  const isDisabled = props.isDisabled;
  const color = isDisabled ? undefined : 'gray.700';
  return (
    <MenuItem
      iconSpacing="2"
      aria-label="Move down"
      icon={<Icon icon="arrow-down" size="4" color={color} />}
      onClick={() => widgetActorRef.send({ type: 'MOVE_DOWN' })}
      color={color}
      {...props}
    >
      Move Down
    </MenuItem>
  );
};
