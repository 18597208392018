import { TableFormFieldWidget } from '@process-street/subgrade/process';
import angular from 'angular';
import templateUrl from './table-renderer.component.html';
import { TableFormFieldUtils } from '@process-street/subgrade/process/widget-utils/table-form-field-utils';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';

export class ApprovalSubjectTaskWidgetTableRendererController {
  public getValue(formFieldWidget: TableFormFieldWidget, formFieldValue: TableFieldValue.FieldValue) {
    if (!formFieldValue || !formFieldWidget) return;
    return TableFormFieldUtils.toHtmlString(formFieldWidget, formFieldValue);
  }
}

export const ApprovalSubjectTaskWidgetTableRenderer: angular.IComponentOptions = {
  bindings: {
    formFieldValue: '<',
    widget: '<',
  },
  controller: ApprovalSubjectTaskWidgetTableRendererController,
  templateUrl,
};
