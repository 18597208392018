import { AuditMetadata, Muid, User } from '../core';
import {
  Checklist,
  ChecklistRevision,
  FormFieldWidget,
  MultiSelectFieldValue,
  TaskStatus,
  TaskTemplateGroup,
  Template,
} from '@process-street/subgrade/process';

export interface OneOffTask {
  id: Muid;
  audit: AuditMetadata;
  linkedChecklist?: LinkedChecklist;
  internalChecklistId: Checklist['id'];
  name: string;
  description: string;
  subtasks: string[];
  visibility: OneOffTaskVisibility;
  assignments: OneOffTaskAssignmentDto[];
  required: boolean;
  dueDate?: number;
  status: TaskStatus;
  taskTemplateGroupId: TaskTemplateGroup['id'];
}

export type LinkedChecklist = {
  id: Checklist['id'];
  name: string;
  templateId: Template['id'];
  templateName: string;
};

export interface CreateOneOffTaskRequest {
  linkedChecklistId?: Checklist['id'];
  name: string;
  description: string;
  subtasks: string[];
  visibility: OneOffTaskVisibility;
  assigneeEmails: string[];
  required: boolean;
  dueDate?: number;
}

export interface UpdateOneOffTaskRequest {
  linkedChecklistId?: Checklist['id'];
  id: OneOffTask['id'];
  name: string;
  description: string;
  visibility: OneOffTaskVisibility;
  required: boolean;
}

export interface UpdateOneOffTaskSubtasksRequest {
  id: OneOffTask['id'];
  subtasks: MultiSelectFieldValue[];
  checklistRevisionId: ChecklistRevision['id'];
  subtasksFormFieldWidgetId?: FormFieldWidget['id'];
}

export enum OneOffTaskVisibility {
  Public = 'Public',
  Private = 'Private',
}

export type OneOffTaskAssignmentDto = {
  id: Muid;
  organizationMembershipId: Muid;
  user: User;
};
