import { Input, InputGroup, InputLeftElement, Box } from 'components/design/next';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { Icon } from 'components/design/next';
import { NodeInfoMap, SelectorHelper } from 'directives/rules/template/task-templates-selector/selector-helper';
import { useField } from 'formik';
import * as React from 'react';
import { TabContainer } from '../tab-container';
import { HiddenTabChecklist } from './checklist';
import { useDebounce } from 'use-debounce';

export type HiddenRulesProps = {
  widgets: Widget[];
  taskTemplates: TaskTemplate[];
};

export const HiddenRules: React.FC<React.PropsWithChildren<HiddenRulesProps>> = ({ widgets, taskTemplates }) => {
  const [{ value }] = useField<NodeInfoMap>('nodeInfoMap');
  const [searchTerm, setSearchTerm] = React.useState('');

  const [searchTermDebounced] = useDebounce(searchTerm, 500);

  const nodes = React.useMemo(() => SelectorHelper.createNodes(taskTemplates, widgets), [taskTemplates, widgets]);
  const matchingNodes = React.useMemo(() => {
    return SelectorHelper.findNodes(value, nodes, searchTerm);
  }, [searchTerm, nodes, value]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <Box px="4" mb="4">
        <InputGroup>
          <InputLeftElement pointerEvents="none" children={<Icon icon="search" color="gray.300" size="4" />} />
          <Input type="search" placeholder="Search" onChange={handleSearch} />
        </InputGroup>
      </Box>

      <TabContainer mb="-60px" spacing={4} w="full" alignItems="flex-start">
        <HiddenTabChecklist isSearching={Boolean(searchTermDebounced)} nodes={matchingNodes} />
      </TabContainer>
    </>
  );
};
