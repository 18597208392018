import { SystemStyleObject } from '@chakra-ui/system';
import * as React from 'react';

export const ADD_TASK_BUTTON_HEIGHT = 48;

export const useAddTaskVisibility = (scrollContainerRef: React.MutableRefObject<HTMLDivElement | null>) => {
  const [isOffScreenState, setIsOffScreen] = React.useState<boolean>(false);

  const menuButtonRef = React.useRef<HTMLDivElement | null>(null);

  const handleOnScroll = React.useCallback(() => {
    if (menuButtonRef.current && scrollContainerRef.current) {
      const buttonRect = menuButtonRef.current.getBoundingClientRect();
      const containerRect = scrollContainerRef.current.getBoundingClientRect();

      const containerBottom = containerRect.bottom;
      const buttonBottom = buttonRect.bottom;
      const isOffScreen = buttonBottom - ADD_TASK_BUTTON_HEIGHT > containerBottom;
      if (isOffScreen !== isOffScreenState) {
        setIsOffScreen(isOffScreen);
      }
    }
  }, [scrollContainerRef, isOffScreenState]);

  React.useEffect(
    () => handleOnScroll(),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only need to run this once
    [],
  );

  const addTaskButtonStyles: SystemStyleObject = React.useMemo(
    () => ({
      '.chakra-menu__menu-button': isOffScreenState
        ? { position: 'fixed', bottom: 34, ml: 8, width: 96, zIndex: 10 }
        : { borderRadius: 'full', position: 'relative' },
    }),
    [isOffScreenState],
  );

  return { menuButtonRef, scrollContainerRef, addTaskButtonStyles, handleOnScroll };
};
