import { Muid } from '@process-street/subgrade/core';
import { CancelTokenSource } from 'axios';

export interface UploadsMap {
  [index: string]: UploadWork;
}

export interface File {
  data: string;
  name: string;
  size: number;
}

export interface XhrMarker {
  _jqXHR?: JqueryFileUploadConvenienceObject;
  id: string;
}

export interface SubmitMetaData {
  checklistRevisionId?: Muid;
  groupId?: Muid;
  headerId?: Muid;
  widgetId?: Muid;
  taskId?: Muid;
  key: string;
  originalName: string;
  mimeType: string;
}

export interface SubmitData {
  url: string;
  type: string;
  headers: string;
  submit: () => JqueryFileUploadConvenienceObject;
  meta?: SubmitMetaData;
}

export interface UploadWork extends XhrMarker {
  type: UploadedItemType;
  request?: CancelTokenSource;
}

export enum UploadedItemType {
  Attachment = 'Attachment',
  FormFieldValue = 'FormFieldValue',
  Widget = 'Widget',
}

export enum RequestActions {
  Abort = 'abort',
}

export interface BlobWithName extends Blob {
  name: string;
}
