import { AuditMetadata, Muid } from '../core';

export enum TemplateTriggerType {
  ChecklistCompleted = 'ChecklistCompleted',
  ChecklistCreated = 'ChecklistCreated',
  TaskApproved = 'TaskApproved',
  TaskChecked = 'TaskChecked',
  TaskCheckedUnchecked = 'TaskCheckedUnchecked',
  TaskReady = 'TaskReady',
  TaskNotReady = 'TaskNotReady',
  TaskRejected = 'TaskRejected',
  TaskUnchecked = 'TaskUnchecked',
}

export enum DataSetTriggerType {
  DataSetCreated = 'DataSetCreated',
  DataSetDeleted = 'DataSetDeleted',
  DataSetRowCreated = 'DataSetRowCreated',
  DataSetRowDeleted = 'DataSetRowDeleted',
  DataSetRowUpdated = 'DataSetRowUpdated',
}

export type WebhookTriggerType = TemplateTriggerType | DataSetTriggerType;

export interface Webhook {
  id: Muid;
  audit: AuditMetadata;
  existsOnServer?: boolean;
  triggers: WebhookTriggerType[];
  url: string;
  templateId?: Muid;
  dataSetId?: Muid;
  traySolutionInstanceId?: string;
  status: 'Active' | 'Disabled';
  webhookType: WebhookType;
  integrationType: 'PowerAutomate' | 'Slack' | 'Standard' | 'Tray';
}

export type WebhookType = 'Workflow' | 'Form' | 'DataSet';
