import * as React from 'react';
import { Box, HStack, Icon, IconButton } from 'components/design/next';
import { RecipientSelect } from './components/recipient-select';
import { CustomNotificationTimingConfig } from './components/custom-notification-timing-config';
import { useCustomNotificationsStore } from './custom-notifications-store';
import { useCustomNotificationConfigContext } from './custom-notification-config-provider';
import { CustomMessage } from './components/custom-message';

export type CustomNotificationConfigRowProps = {
  isReadOnly?: boolean;
};

export const CustomNotificationConfigRow = ({ isReadOnly }: CustomNotificationConfigRowProps) => {
  const removeConfig = useCustomNotificationsStore(state => state.removeConfig);

  const { config } = useCustomNotificationConfigContext();

  return (
    <HStack>
      <Box w="35">
        <RecipientSelect isReadOnly={isReadOnly} />
      </Box>

      <Box flex="1" overflow="hidden">
        <CustomNotificationTimingConfig isReadOnly={isReadOnly} />
      </Box>

      {!isReadOnly && <CustomMessage />}

      {!isReadOnly && (
        <IconButton
          w="4"
          icon={<Icon icon="trash-alt" size="4" color="gray.500" />}
          size="sm"
          aria-label="remove custom notification"
          onClick={() => removeConfig(config)}
          variant="ghost"
        />
      )}
    </HStack>
  );
};
