import * as React from 'react';
import {
  Avatar,
  AvatarGroup,
  HStack,
  VStack,
  Text,
  Icon,
  Spacer,
  Box,
  Button,
  IconButton,
  Spinner,
  Center,
  forwardRef,
  AvatarProps,
} from 'components/design/next';
import { BlvdSelect, BlvdSelectProps } from 'components/design/BlvdSelect';
import { components, GroupBase, SelectComponentsConfig } from 'react-select';
import { match, P } from 'ts-pattern';
import { useRecipientSelect, Option } from './use-recipient-select';

export interface RecipientSelectProps extends Partial<BlvdSelectProps<Option, true, GroupBase<Option>>> {
  isReadOnly?: boolean;
}

export const RecipientSelect: React.FC<React.PropsWithChildren<RecipientSelectProps>> = ({ isReadOnly, ...props }) => {
  const { isLoading, onMenuClose, sortedOptions, values, onChange } = useRecipientSelect();

  if (isReadOnly) {
    return (
      <>
        {values.map(option => (
          <OptionAvatar key={option.key} option={option} />
        ))}
      </>
    );
  }

  return (
    <Box sx={{ '.blvd-select .blvd-select__menu': { minWidth: 100 } }} w="full" pl="2">
      {isLoading ? (
        <Center w="35" h="10">
          <Spinner />
        </Center>
      ) : (
        <BlvdSelect
          isMulti
          optionHeight={56}
          components={COMPONENTS}
          isSearchable
          searchPlaceholder="Enter name or email"
          onMenuClose={onMenuClose}
          filterOption={filterOption}
          getOptionValue={getOptionValue}
          options={sortedOptions}
          value={values}
          onChange={onChange}
          {...props}
        />
      )}
    </Box>
  );
};

const COMPONENTS: SelectComponentsConfig<Option, true, GroupBase<Option>> = {
  Option: ({ children: _, ...props }) => {
    const data = props.data as Option;

    return (
      <components.Option {...props}>
        <HStack w="full">
          <OptionAvatar option={data} />
          <VStack alignItems="flex-start" opacity={props.isSelected ? '0.6' : '1'}>
            <Text fontWeight="medium" variant="-1">
              {data.primaryLabel}
            </Text>
            {data.secondaryLabel ? (
              <Text variant="-2" color="gray.500">
                {data.secondaryLabel}
              </Text>
            ) : null}
          </VStack>
          <Spacer />
          {props.isSelected ? <Icon icon="user-times" size="4" color="gray.500" /> : null}
        </HStack>
      </components.Option>
    );
  },

  MultiValue: () => null,
  // ValueContainer needed for clicking outside menu to close
  ValueContainer: ({ children }) => {
    return (
      <Box as="span" w="0" h="0" overflow="hidden">
        {children}
      </Box>
    );
  },
  Control: ({ children, ...props }) => {
    return (
      <Box {...props.innerProps} ref={props.innerRef} display="flex">
        {children}
      </Box>
    );
  },
  IndicatorsContainer: ({ children }) => (
    <Box flex="1" maxW="full">
      {children}
    </Box>
  ),
  IndicatorSeparator: () => null,
  Placeholder: ({ children }) => (
    <Button variant="ghost" colorScheme="gray" fontWeight="normal">
      {children}
    </Button>
  ),
  DropdownIndicator: ({ children: _, ...props }) => {
    const value = props.getValue() as Option[];

    return (
      // @ts-expect-error div and button ref clash
      <IconButton
        {...props.innerProps}
        w="full"
        justifyContent="flex-start"
        icon={
          <AvatarGroup
            max={4}
            spacing="-12px"
            sx={{
              '.chakra-avatar__excess': {
                marginInlineStart: '-2',
              },
            }}
          >
            {value.map(option => (
              <OptionAvatar key={option.key} option={option} />
            ))}
            {value.length === 0 ? <Avatar icon={<Icon icon="plus" variant="far" size="4" />} /> : null}
          </AvatarGroup>
        }
        variant="ghost"
        aria-label="Select users"
      />
    );
  },
};

const OptionAvatar = forwardRef<AvatarProps & { option: Option }, 'div'>(({ option, ...props }, ref) => {
  return (
    <Avatar
      ref={ref}
      {...props}
      {...match(option)
        .with({ avatarUrl: P.string }, ({ avatarUrl, primaryLabel }) => ({ src: avatarUrl, name: primaryLabel }))
        .with({ avatarIcon: P.string }, ({ avatarIcon, avatarBg }) => ({
          icon: <Icon icon={avatarIcon} variant="far" size="4" />,
          bg: avatarBg,
        }))
        .otherwise(() => ({ name: option.primaryLabel }))}
    />
  );
});

const getOptionValue = (option: Option) => option.key;

const filterOption = (option: { data: Option }, query: string) => {
  const data = option.data as Option;
  return match(data)
    .with({ type: 'user' }, ({ user: { email, username } }) =>
      `${email} ${username}`.toLowerCase().includes(query.toLowerCase()),
    )
    .with({ type: 'formField' }, ({ widget: { key, label } }) =>
      `${key} ${label}`.toLowerCase().includes(query.toLowerCase()),
    )
    .with({ role: 'runner' }, () => 'workflow runner'.includes(query.toLowerCase()))
    .otherwise(() => false);
};
