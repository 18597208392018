// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../images/update-modal-icon.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"modal-body template-migration-modal\"> <div class=\"row\"> <div class=\"col-xs-12\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </div> </div> <div class=\"row updating-checklists\"> <p> Updating {{ $ctrl.state.formattedTotal }} <ng-pluralize count=\"$ctrl.state.formattedTotal\" when=\"{'1': 'workflow run', 'other': 'workflow runs'}\"></ng-pluralize> </p> </div> <div class=\"row migration-progress-bar-container\"> <div class=\"col-xs-2\"></div> <div class=\"col-xs-8\"> <uib-progressbar class=\"migration-progress-bar\" value=\"$ctrl.state.percentage\" type=\"migration\"> </uib-progressbar> </div> <div class=\"col-xs-2\"> <span class=\"migration-progress-info\">{{ $ctrl.state.percentage }}%</span> </div> </div> <div class=\"row this-update\"> <p> This update can be done in the background, feel free to <br/>navigate away from this page. </p> </div> <div class=\"row\"> <button data-ng-click=\"$ctrl.onButtonClick()\" class=\"btn btn-primary got-it-button\">Got it!</button> </div> </div> ";
// Exports
var _module_exports = code;;
var path = '/components/migration/template/template-migration-modal.component.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;