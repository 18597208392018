import { Muid } from '@process-street/subgrade/core';
import create from 'zustand';
import { GridApi } from '@ag-grid-community/core';

interface DashboardGridContextState {
  hoveredRowId: Muid | null;
  hoveredColumnId: string | null;
  canReassign: boolean;
  gridApi?: GridApi;
}

interface DashboardGridContextStateEffects {
  setHoveredRowId: (hoveredRowId: Muid | null) => void;
  setHoveredColumnId: (column: string | null) => void;
  setCanReassign: (opt: boolean) => void;
  setGridApi: (gridApi: GridApi) => void;
}

type DashboardGridContextStore = DashboardGridContextState & DashboardGridContextStateEffects;

export const useDashboardGridContextStore = create<DashboardGridContextStore>(set => ({
  hoveredRowId: null,
  hoveredColumnId: null,
  canReassign: false,
  reassignWorkflowRunInReportsPage: false,
  setHoveredRowId: (hoveredRowId: Muid | null) => set({ hoveredRowId }),
  setHoveredColumnId: (hoveredColumnId: string | null) => set({ hoveredColumnId }),
  setCanReassign: (canReassign: boolean) => set({ canReassign }),
  setGridApi: (gridApi: GridApi) => set({ gridApi }),
}));
