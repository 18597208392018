import { Muid, OrganizationMembershipStatus } from '@process-street/subgrade/core';
import { createUsableContext } from '@process-street/subgrade/util';
import { useFunctionRef } from 'hooks/use-function-ref';
import React from 'react';

type Context = {
  getOrganizationMembershipStatus: (memberId: Muid) => OrganizationMembershipStatus | undefined;
};
export const [useBlvdMembersMultiselectContext, BlvdMembersMultiselectContext] = createUsableContext<Context>({
  hookName: 'useBlvdMembersMultiselectContext',
  providerName: 'BlvdMembersMultiselectProvider',
});

export const BlvdMembersMultiselectProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<Context>>> = ({
  children,
  getOrganizationMembershipStatus,
}) => {
  const getOrganizationMembershipStatusRef = useFunctionRef(getOrganizationMembershipStatus);
  const value = React.useMemo(
    () => ({ getOrganizationMembershipStatus: getOrganizationMembershipStatusRef.current }),
    [getOrganizationMembershipStatusRef],
  );

  return <BlvdMembersMultiselectContext.Provider value={value}>{children}</BlvdMembersMultiselectContext.Provider>;
};
