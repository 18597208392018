import { TaskTemplate } from '@process-street/subgrade/process';
import { AiGeneratorAnimationService } from 'app/services/ai-generator-animation-service';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { ActorRefFrom, createMachine, sendParent } from 'xstate';
import { SharedContext } from '../../../shared';

type Context = {
  taskTemplate: TaskTemplate;
  sharedContext: SharedContext;
};

type Event =
  | { type: 'ANIMATE' }
  | { type: 'INTERNAL_UPDATE_TASK_TEMPLATE'; taskTemplate: TaskTemplate }
  | { type: 'SEND_PARENT_ANIMATION_FINISH'; taskTemplate: TaskTemplate };

export type TaskTemplateGenerationMachine = ReturnType<typeof makeTaskTemplateGenerationMachine>;
export type TaskTemplateGenerationActor = ActorRefFrom<TaskTemplateGenerationMachine>;

export const makeTaskTemplateGenerationMachine = ({ taskTemplate, sharedContext }: Context) => {
  const id = `task-template-generation:${taskTemplate.group.id}`;

  return createMachine(
    {
      id,
      initial: 'idle',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      tsTypes: {} as import('./task-template-generation-machine.typegen').Typegen0,
      context: () =>
        ({
          taskTemplate,
          sharedContext,
        } as Context),
      states: {
        idle: {
          on: {
            ANIMATE: { target: 'animating' },
          },
        },
        animating: {
          invoke: [
            {
              id: 'animateTaskTemplate',
              src: 'animateTaskTemplateService',
              onDone: { target: 'done' },
              onError: { target: 'done', actions: 'logError' },
            },
          ],
          on: {
            INTERNAL_UPDATE_TASK_TEMPLATE: { actions: 'sendParentUpdateTaskTemplate' },
          },
        },
        done: {
          type: 'final',
          entry: 'sendParentAnimationFinish',
        },
      },
    },
    {
      actions: {
        logError: makeErrorLoggerAction(id),
        sendParentUpdateTaskTemplate: sendParent((_ctx, evt) => ({
          type: 'UPDATE_TASK_TEMPLATE',
          taskTemplate: evt.taskTemplate,
        })),
        sendParentAnimationFinish: sendParent(ctx => ({
          type: 'TASK_TEMPLATE_ANIMATION_FINISH',
          taskTemplate: ctx.taskTemplate,
        })),
      },
      services: {
        animateTaskTemplateService: ctx => async send => {
          const animator = AiGeneratorAnimationService.createTaskTemplatesAnimator([ctx.taskTemplate], {
            timeout: ((callback: (args: void) => void, ms: number) => {
              return setTimeout(callback, ms);
            }) as typeof setTimeout,
            update: (taskTemplate, _) => {
              send({ type: 'INTERNAL_UPDATE_TASK_TEMPLATE', taskTemplate });
            },
          });

          await animator();
        },
      },
    },
  );
};
