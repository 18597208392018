import { Box, Center, Icon, MotionWrapper, ResponsiveValue, Spinner, Tooltip } from 'components/design/next';
import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { CustomIconName } from 'components/design/next/icon/icon-name';

export enum AutomationStatus {
  Processing = 'running',
  Succeeded = 'success',
  Failed = 'error',
  Idle = 'idle',
}

const getIconForStatus = (status?: AutomationStatus): CustomIconName => {
  switch (status) {
    case AutomationStatus.Succeeded:
      return 'check';
    case AutomationStatus.Failed:
      return 'xmark';
    case AutomationStatus.Processing:
    case AutomationStatus.Idle:
    default:
      return 'bolt';
  }
};

const tooltipLabelMap: Record<AutomationStatus, string> = {
  [AutomationStatus.Succeeded]: 'The latest automation completed successfully.',
  [AutomationStatus.Failed]: 'The latest automation failed.',
  [AutomationStatus.Processing]: 'An automation is currently running…',
  [AutomationStatus.Idle]: 'View the automation run log.',
};

export type AutomationStatusIndicatorIconComponentProps = {
  status: AutomationStatus;
  size?: ResponsiveValue<string | number>;
};

export const AutomationStatusIndicatorIconComponent: React.FC<
  React.PropsWithChildren<AutomationStatusIndicatorIconComponentProps>
> = ({ status, size = 8 }) => {
  const { $state } = useInjector('$state');
  const minSize = Math.max(Number(size), 8);
  const icon = getIconForStatus(status);
  const color = $state.includes('microsoftTeams') ? 'gray.400' : 'brand.500';
  const iconColor = status === AutomationStatus.Failed ? 'red.500' : color;

  return (
    <Tooltip hasArrow={true} placement="bottom" label={tooltipLabelMap[status]}>
      <Box width={minSize} height={minSize}>
        <Center pos="relative" alignContent="center" width={minSize} height={minSize}>
          <MotionWrapper animate={{ opacity: icon ? 1 : 0 }} transition={{ duration: 0.25 }} initial={{ opacity: 0 }}>
            <Box as={Center} width={minSize} height={minSize}>
              <Icon
                color={iconColor}
                icon={icon}
                sx={{
                  '&.fa-w-12': {
                    width: `${Math.floor(minSize * 0.5)}`,
                    height: `${Math.floor(minSize * 0.5)}`,
                  },
                }}
              />
            </Box>
          </MotionWrapper>
          {status === AutomationStatus.Processing && (
            <MotionWrapper animate={{ opacity: 1 }} transition={{ duration: 0.25 }} initial={{ opacity: 0 }}>
              <Box pos="absolute" left="0" top="0" width={minSize} height={minSize}>
                <Spinner
                  thickness="2px"
                  speed="1s"
                  emptyColor="brand.200"
                  color="brand.500"
                  sx={{ width: '100%', height: '100%' }}
                />
              </Box>
            </MotionWrapper>
          )}
        </Center>
      </Box>
    </Tooltip>
  );
};
