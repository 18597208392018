import angular from 'angular';
import './add-stop-task.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';

angular
  .module('frontStreetApp.controllers')
  .controller('AddStopTaskCtrl', function ($scope, $uibModalInstance, modalOptions, ToastService) {
    const ctrl = this;
    ctrl.$onInit = () => {
      $scope.featureIsAvailable = modalOptions.featureIsAvailable;
      $scope.userIsAdmin = modalOptions.userIsAdmin;
    };

    $scope.addStop = function () {
      $scope.adding = true;

      modalOptions.addStop().then(
        () => {
          $uibModalInstance.close();
        },
        () => {
          $scope.adding = false;

          ToastService.openToast({
            status: 'error',
            title: `We're having problems adding the stop task`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
      );
    };
  });
