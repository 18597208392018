import { Identifiable, Muid, Ref } from './core-model';
import { MuidConverter } from './muid';
import { AuditMetadata } from './user-model';

export const newAuditMetadata = (user: Muid): AuditMetadata => {
  return {
    createdBy: { id: user },
    createdDate: Date.now(),
    updatedBy: { id: user },
    updatedDate: Date.now(),
  };
};

export const updateAuditMetadata = (user: Muid, entity?: Identifiable): AuditMetadata => {
  if (entity && entity.audit) {
    return {
      createdBy: entity.audit.createdBy,
      createdDate: entity.audit.createdDate,
      updatedBy: { id: user },
      updatedDate: Date.now(),
    };
  }
  return newAuditMetadata(user);
};

export const generateUUID = () => {
  function randomDigit() {
    return ((Math.random() * 16) | 0).toString(16);
  }

  return 'xxxxxxxx-xxxx-4xxx-8xxx-xxxxxxxxxxxx'.replace(/x/g, randomDigit);
};

/**
 * Returns a random integer between the specified values.
 * The value is no lower than min (or the next integer greater than min if min isn't an integer), and is less than (but not equal to) max.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_integer_between_two_values
 */
export function getRandomInt({ min = 0, max = 100 }: Partial<{ max: number; min: number }> = {}) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

const randomMuid = () => MuidConverter.fromUuid(generateUUID());

export const MuidUtils = {
  randomMuid,
  isMuid: (muid: string) => /[a-zA-Z0-9-_]{22}/.test(muid),
  MUID_REGEXP: /[a-zA-Z0-9-_]{22}/,
};

export function isModelRef<T>(ref?: Ref<T>): ref is T {
  if (!ref) {
    return false;
  }
  return Object.keys(ref).length > 1;
}

export function toModelRef<T>(ref?: Ref<T>): T | undefined {
  return isModelRef(ref) ? ref : undefined;
}

export type TypeOfEnumValue<T> = T[keyof T];

export type Camelize<T extends string> = T extends `${infer F}_${infer R}`
  ? `${Lowercase<F>}${Capitalize<Camelize<Lowercase<R>>>}`
  : Lowercase<T>;

// https://github.com/microsoft/TypeScript/issues/49656#issuecomment-1164633164
export type FieldTypePreservingOmit<T, K extends keyof any> = { [P in keyof T as Exclude<P, K>]: T[P] };

export type KeysEndingWith<T, S extends string> = keyof T extends infer K
  ? K extends `${string}${S}`
    ? K
    : never
  : never;

/**
 * Gets all possible paths as a union of tuples
 **/
export type GetPathTuples<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? [Key, ...GetPathTuples<ObjectType[Key]>]
    : [Key];
}[keyof ObjectType & (string | number)];

export type GetPathStrings<ObjectType extends object> = Join<GetPathTuples<ObjectType>>;

export type Join<T, separator extends string = '.'> = T extends [infer S, ...infer Rest]
  ? Rest extends []
    ? S
    : `${Extract<S, string>}${separator}${Join<Extract<Rest, string[]>>}`
  : never;
