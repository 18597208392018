import { trace } from 'components/trace';

const logger = trace({ name: 'WatchdogService' });
let localTimeoutId: ReturnType<typeof setTimeout>;
const WATCHDOG_LOCAL_STORAGE_KEY = 'app-boot-watchdog';
const TIMEOUT_MS = 60 * 1000; // 60 seconds

/** Each item type denotes the beginning of a certain phase. */
export enum WatchdogItemType {
  BOOT = 'boot',
  TOKEN_RENEW = 'tokenRenew',
  COMPLETE_LOGIN = 'completeLogin',
  COMPLETE_LOGIN_ERROR = 'completeLoginError',
  SIGNUP_SSO = 'signupSso',
  SET_SELECTED_ORGANIZATION = 'setSelectedOrganization',
}

interface WatchdogItem {
  itemType: WatchdogItemType;
  timeoutAt: number;
}

export namespace WatchdogService {
  export function clear() {
    clearTimeout(localTimeoutId);
    localStorage?.removeItem(WATCHDOG_LOCAL_STORAGE_KEY);
  }

  const doTimeoutError = (itemType: WatchdogItemType) => {
    const errorMessage = `Watchdog timeout, phase [${itemType}].`;
    clear();
    logger.error(errorMessage);
  };

  export function set(itemType: WatchdogItemType) {
    const item: WatchdogItem = {
      itemType,
      timeoutAt: TIMEOUT_MS + Date.now(),
    };
    clear();
    localStorage?.setItem(WATCHDOG_LOCAL_STORAGE_KEY, JSON.stringify(item));
    localTimeoutId = setTimeout(() => {
      doTimeoutError(item.itemType);
    }, TIMEOUT_MS);
  }

  export function init() {
    const item = localStorage?.getItem(WATCHDOG_LOCAL_STORAGE_KEY);
    if (!item) {
      return;
    }
    const itemParsed = JSON.parse(item) as WatchdogItem;
    if (itemParsed.timeoutAt < Date.now()) {
      // Already timed out
      doTimeoutError(itemParsed.itemType);
    } else {
      // Set timeout for remaining time
      const remainingTime = itemParsed.timeoutAt - Date.now();
      localTimeoutId = setTimeout(() => {
        doTimeoutError(itemParsed.itemType);
      }, remainingTime);
    }
  }
}
