import * as React from 'react';
import { Box, FormControl, FormErrorMessage, VStack } from 'components/design/next';
import { useActor } from '@xstate/react';
import { SelectFormFieldActor } from './select-form-field-machine';
import { BlvdSelect, BlvdSelectProps } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import _keyBy from 'lodash/keyBy';
import { ValidationSelectors } from '../validation-machine';
import { useSelector } from '@xstate/react';
import { RulesEngineTargetSelectors } from '../../form-response-body/rules-engine-machine';
import { FieldType, isMultiChoiceFormFieldWidget, SelectFormFieldValue } from '@process-street/subgrade/process';
import { FormResponseLabel } from '../common';
import { useGetAllLinkedDataSetRowsQuery } from 'app/pages/reports/data-sets/query-builder';
import { ChecklistSelectFormFieldWidgetHelper } from 'app/pages/runs/_id/components/checklist-select-form-field-widget/helper';

export interface SelectFormFieldProps {
  actor: SelectFormFieldActor;
}

type OptionType = {
  label: string;
  value: string;
};

export const SelectFormField: React.FC<React.PropsWithChildren<SelectFormFieldProps>> = ({ actor }) => {
  const [state, send] = useActor(actor);
  const { widget, formFieldValue } = state.context;
  const { linkedDataSetId, linkId, linkedSavedViewId, linkedColumnId } = widget.config;

  const isHiddenByRule = useSelector(actor, RulesEngineTargetSelectors.getIsHiddenByRule);

  const isDataSetLinkedDropdown =
    Boolean(linkedDataSetId) && Boolean(linkId) && Boolean(linkedSavedViewId) && Boolean(linkedColumnId);

  const dataSetRowsQuery = useGetAllLinkedDataSetRowsQuery(
    {
      dataSetId: linkedDataSetId!,
      linkId: linkId!,
      savedViewId: linkedSavedViewId!,
    },
    {
      enabled: isDataSetLinkedDropdown,
    },
  );

  const onFocus = () => {
    send({ type: 'FOCUS' });
  };

  const onBlur = () => {
    send({ type: 'BLUR' });
  };

  const isMulti = isMultiChoiceFormFieldWidget(widget);
  type IsMulti = typeof isMulti;
  const setValue: BlvdSelectProps<OptionType, IsMulti>['onChange'] = value => {
    if (BlvdSelectHelpers.isOptionsType<OptionType>(value)) {
      send({ type: 'CHANGE', value: value.map(option => ({ value: option.value })) });
      return;
    }
    if (BlvdSelectHelpers.isOptionType<OptionType>(value) && isDataSetLinkedDropdown) {
      send({ type: 'CHANGE', value: [{ value: value.label, dataSetRowId: value.value }] });
      return;
    }
    if (BlvdSelectHelpers.isOptionType<OptionType>(value) && !isDataSetLinkedDropdown) {
      send({ type: 'CHANGE', value: [{ value: value.value }] });
      return;
    }
    if (value === null && isDataSetLinkedDropdown) {
      send({ type: 'CHANGE', value: [{ value: '' }] });
      return;
    }

    send({ type: 'CHANGE', value: [] });
  };

  const [options, optionsMap]: [OptionType[], Record<string, OptionType>] = React.useMemo(() => {
    if (widget.fieldType === FieldType.Select) {
      const fieldValue = formFieldValue as SelectFormFieldValue | undefined;
      if (isDataSetLinkedDropdown) {
        return ChecklistSelectFormFieldWidgetHelper.getLinkedOptions(
          dataSetRowsQuery.data ?? [],
          linkedColumnId!,
          fieldValue,
        );
      } else {
        return ChecklistSelectFormFieldWidgetHelper.getConfigOptions(
          widget.config.items,
          fieldValue?.fieldValue?.value ?? '',
          'value',
        );
      }
    } else {
      // MultiChoice widget
      const opts = widget.config.items
        .filter(item => item.name !== '')
        .map(item => ({
          value: item.id,
          label: item.name,
        }));
      const optsMap = _keyBy(opts, 'value');
      return [opts, optsMap];
    }
  }, [
    widget.fieldType,
    widget.config.items,
    formFieldValue,
    dataSetRowsQuery.data,
    linkedColumnId,
    isDataSetLinkedDropdown,
  ]);

  const selectedOption = React.useMemo(
    () =>
      state.context.value.map(v => optionsMap[isDataSetLinkedDropdown && !!v.dataSetRowId ? v.dataSetRowId : v.value]),
    [optionsMap, isDataSetLinkedDropdown, state.context.value],
  );

  const isInvalid = ValidationSelectors.isActorInvalidVisible(state.context.validationActor);

  const ref = React.useRef<HTMLDivElement | null>(null);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !state.context.inputNode) {
          actor.send({ type: 'SET_NODE', node });
        }
      }}
      as={VStack}
      alignItems="stretch"
      isRequired={widget.required}
      isInvalid={isInvalid}
    >
      <FormResponseLabel htmlFor={`form-field-widget-${widget.id}`}>
        {widget.label || 'Untitled Dropdown'}
      </FormResponseLabel>

      <Box maxW="175" w="full">
        <BlvdSelect
          inputId={`form-field-widget-${widget.id}`}
          placeholder="Select"
          isMulti={isMulti}
          value={selectedOption}
          options={options}
          onChange={setValue}
          isDisabled={state.matches('input.disabled')}
          autoFocus={state.matches('autoFocus.enabled')}
          onFocus={onFocus}
          onBlur={onBlur}
          isInvalid={isInvalid}
          isClearable
        />

        <FormErrorMessage>{ValidationSelectors.errorMessage(state.context.validationActor)}</FormErrorMessage>
      </Box>
    </FormControl>
  );
};
