import * as React from 'react';
import { IconButton, Icon, IconButtonProps, forwardRef } from 'components/design/next';

export type ReplyButtonProps = Omit<IconButtonProps, 'aria-label'>;

export const ReplyCommentButton = forwardRef<ReplyButtonProps, 'button'>((props, ref) => (
  <IconButton
    ref={ref}
    bg="white"
    color="gray.600"
    borderWidth="px"
    borderColor="gray.300"
    _hover={{
      'borderColor': 'brand.500',
      'backgroundColor': 'brand.100',
      '& svg': {
        color: 'brand.500',
      },
    }}
    _active={{
      'borderColor': 'brand.500',
      'backgroundColor': 'brand.100',
      '& svg': {
        color: 'brand.500',
      },
    }}
    variant="outline"
    size="sm"
    icon={<Icon size="4" variant="far" icon="reply" />}
    aria-label="reply comment"
    {...props}
  />
));
