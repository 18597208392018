import * as React from 'react';
import { Condition, isConditionLogical, LogicalCondition } from '@process-street/subgrade/conditional-logic';
import { Box, VStack, Text, Flex } from 'components/design/next';
import { FormFieldCondition } from './form-field-condition';
import { TaskCondition } from './task-condition';
import { TimeBasedCondition } from './time-based-condition';
import { isFormFieldCondition, isTaskCondition, isTimeBasedCondition } from '../utils';

interface LogicalRuleProps {
  rule: LogicalCondition;
  indent?: number;
}

enum LogicalOperatorDisplayValue {
  LogicalOr = 'OR',
  LogicalAnd = 'AND',
}

export const LogicalRule: React.FC<React.PropsWithChildren<LogicalRuleProps>> = ({ rule, indent = 0 }) => {
  const renderCondition = (condition: Condition) => {
    if (isConditionLogical(condition)) {
      return <LogicalRule rule={condition} indent={indent + 1} />;
    } else if (isFormFieldCondition(condition)) {
      return <FormFieldCondition condition={condition} />;
    } else if (isTaskCondition(condition)) {
      return <TaskCondition condition={condition} />;
    } else if (isTimeBasedCondition(condition)) {
      return <TimeBasedCondition condition={condition} />;
    }
    return null;
  };

  const renderConditions = (conditions: Condition[]) => {
    const isFirstCondition = indent === 0;
    return conditions.map((condition, index) => (
      <Flex key={index} display="flex" flexDirection="row">
        {index === 0 && isFirstCondition && (
          <Text as="span" fontWeight="bold" mr={2} width="32px">
            IF
          </Text>
        )}
        {index > 0 && (
          <Text as="span" fontWeight="bold" mr={2} width="32px">
            {LogicalOperatorDisplayValue[rule.operator]}
          </Text>
        )}
        <Box>{renderCondition(condition)}</Box>
      </Flex>
    ));
  };

  return (
    <VStack alignItems="flex-start" ml={indent * 2}>
      {renderConditions(rule.conditions)}
    </VStack>
  );
};
