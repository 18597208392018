export function getAllTextNodesAsSpans(el: HTMLDivElement): HTMLSpanElement[] {
  return getTextNodesIn(el).map(textNode => {
    const textSpan = document.createElement('span');
    textSpan.textContent = textNode.nodeValue;
    textNode.parentNode?.insertBefore(textSpan, textNode);
    textNode.parentNode?.removeChild(textNode);
    return textSpan;
  });
}

// https://gist.github.com/my8bit/7143332
const WHITESPACE_PATTERN = /^\s*$/;
const STYLE = 'STYLE';
export function getTextNodesIn(node: Node): Node[] {
  const textNodes: Node[] = [];

  function getTextNodes(node: Node) {
    if (node.nodeName === STYLE) return;

    if (node.nodeType === Node.TEXT_NODE) {
      if (!WHITESPACE_PATTERN.test(node.nodeValue ?? '') && (node.nodeValue?.trim().length ?? 0) > 0) {
        textNodes.push(node);
      }
    } else {
      for (let i = 0, len = node.childNodes.length; i < len; ++i) {
        getTextNodes(node.childNodes[i]);
      }
    }
  }
  getTextNodes(node);
  return textNodes;
}
