import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import * as React from 'react';
import { match } from 'ts-pattern';
import { usePaywall } from '../paywall-provider';
import { useOrganizationMembershipRole } from '../use-organization-membership-role';
import { UIs } from './ui';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  invitee: string;
};

export const InviteFullMemberPaywall: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen: isOpenProp,
  onClose,
  invitee,
}) => {
  const { setPaywall, isOpen } = usePaywall();
  const role = useOrganizationMembershipRole();

  React.useEffect(() => {
    // don't run this effect unless the isOpen prop (from angular for example) is true
    // Really, this component shouldn't render at all if isOpen is false, but this is a quick fix
    if (!role || !isOpenProp) return;

    const paywall = match(role)
      .with(OrganizationMembershipRole.FullMember, () => <UIs.FullMember invitee={invitee} />)
      .otherwise(() => React.createElement(UIs[role]));

    setPaywall(paywall);

    return () => {
      setPaywall(null);
    };
  }, [setPaywall, role, isOpenProp, invitee]);

  React.useEffect(() => {
    if (!isOpen) {
      onClose();
    }
  }, [isOpen, onClose]);

  return null;
};
