import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { MigrationStats } from 'reducers/types';
import { axiosService } from 'services/axios-service';

export type GetMigrationStatsByTemplateRevisionIdParams = { templateRevisionId: Muid };
export type GetMigrationStatsByTemplateRevisionIdResult = MigrationStats;

const GetMigrationStatsByTemplateRevisionId = {
  key: ['template-revisions', 'checklist-revisions', 'migration-stats'],
  getKey: (params: GetMigrationStatsByTemplateRevisionIdParams) => [
    ...GetMigrationStatsByTemplateRevisionId.key,
    params,
  ],
  queryFn: (params: GetMigrationStatsByTemplateRevisionIdParams) =>
    axiosService
      .getAxios()
      .get<GetMigrationStatsByTemplateRevisionIdResult>(
        `/1/template-revisions/${params.templateRevisionId}/checklist-revisions/migration-stats`,
      )
      .then(r => r.data),
};
export const useGetMigrationStatsByTemplateRevisionIdQuery = (
  params: GetMigrationStatsByTemplateRevisionIdParams,
  options: UseQueryOptions<GetMigrationStatsByTemplateRevisionIdResult, AxiosError> = {},
) => {
  return useQuery<GetMigrationStatsByTemplateRevisionIdResult, AxiosError>(
    GetMigrationStatsByTemplateRevisionId.getKey(params),
    () => GetMigrationStatsByTemplateRevisionId.queryFn(params),
    options,
  );
};
