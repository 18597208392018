import { NativeTemplateTrigger } from '@process-street/subgrade/process';
import { CustomIconName } from 'components/design/next/icon/icon-name';

export const TriggerIcons: Record<NativeTemplateTrigger, CustomIconName> = {
  manual: 'play',
  email: 'envelope',
  runLink: 'link',
  schedule: 'clock',
  runMultiple: 'fast-forward',
  api: 'plug',
  webhook: 'webhook',
};

export const TriggerLabels: Record<NativeTemplateTrigger, string> = {
  manual: 'Manual',
  email: 'Email',
  runLink: 'Run Link',
  schedule: 'Schedule',
  runMultiple: 'Run Multiple',
  api: 'API',
  webhook: 'Webhook',
};
