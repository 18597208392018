import { Plan } from '@process-street/subgrade/billing';
import { BasePlanSelector } from '@process-street/subgrade/redux/selector';
import { ReduxAppState } from 'reducers/types';
import { Selector } from 'reselect';

const getAll: Selector<ReduxAppState, Plan[]> = (state: ReduxAppState): Plan[] =>
  Object.values(PlanSelector.getEntityMap(state));

export const PlanSelector = {
  getAll,
  getById: BasePlanSelector.getById,
  getEntityMap: BasePlanSelector.getEntityMap,
};
