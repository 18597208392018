import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { SandboxFocusBar } from '.';

export const SandboxFocusBarModule = angular
  .module(
    'sandboxFocusBar',
    [], // dependencies
  )
  .component('psSandboxFocusBar', reactToAngular(SandboxFocusBar, ['checklistRevision', 'templateRevision'])).name;
