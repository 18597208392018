import { groupsByGroupTypesSelector } from 'reducers/group/group.selectors';
import { connectController } from 'reducers/util';
import { htmlEscaped } from '@process-street/subgrade/util';
import { GroupType } from '@process-street/subgrade/core';
import templateUrl from './template-members-field.component.html';
import '../members-field.scss';
import { GroupActions } from 'reducers/group/group.actions';
import { FormFieldEvent } from 'services/form-field-event';

export const TemplateMembersFieldComponent = {
  bindings: {
    widget: '<',
    editable: '<',
    onUpdateWidget: '&',
  },
  templateUrl,
  controller: class ChecklistMembersFieldComponentController {
    ready = false;

    constructor($ngRedux, $rootScope, $timeout) {
      'ngInject';

      this.$rootScope = $rootScope;
      this.$timeout = $timeout;

      const mapStateToThis = () => state => {
        const groups = groupsByGroupTypesSelector([GroupType.Standard, GroupType.AllMembers])(state);
        const selectedGroup = this.resolveSelectedGroup(groups);

        return { groups, selectedGroup };
      };

      const mapDispatchToThis = () => ({
        queryGroups: GroupActions.queryGroups,
      });

      connectController($ngRedux, mapStateToThis, mapDispatchToThis, this.shouldChange)(this);
    }

    shouldChange = changes => changes.widget && changes.widget.currentValue;

    $onInit() {
      this.actions.queryGroups().then(() => {
        this.ready = true;
      });
    }

    resolveSelectedGroup = groups => {
      const selectedGroupId = this.widget && this.widget.config && this.widget.config.groupId;

      let selectedGroup;

      if (selectedGroupId) {
        selectedGroup = groups.find(g => g.id === selectedGroupId);

        if (!selectedGroup) {
          selectedGroup = { id: selectedGroupId, user: undefined };
        }
      }

      return selectedGroup;
    };

    getMatchingGroups(search) {
      const groups = this.state.groups || [];

      let matchingGroups = groups;
      if (search) {
        matchingGroups = groups.filter(group => group.user.username.toLowerCase().includes(search.toLowerCase()));
      }

      return matchingGroups;
    }

    // Editable

    updateFormFieldWidget = () => {
      this.widget.config.groupId = this.state.selectedGroup && this.state.selectedGroup.id;
      this.updateWidget(this.widget);
    };

    /**
     * Used to keep track of when to update
     */
    updateWidgetTimeout;

    /**
     * Update in 500 ms, unless another change is made
     *
     * @param widget
     */
    updateWidget(widget) {
      this.$timeout.cancel(this.updateWidgetTimeout);
      this.updateWidgetTimeout = this.$timeout(() => {
        this.onUpdateWidget({ widget });
      }, 500);
    }

    resolveGroupName = user => {
      if (user) {
        return htmlEscaped`${user.username}`;
      } else if (this.ready) {
        return '(Group Removed)';
      } else {
        return undefined;
      }
    };

    clickDisabledInput = () => {
      this.$rootScope.$broadcast(FormFieldEvent.DISABLED_FORM_FIELD_CLICKED);
    };
  },
};
