import { isTemplateSolutionInstance, withTrayPrefix } from '@process-street/subgrade/automation';
import {
  GetTemplateQuery,
  GetTemplateQueryResponse,
  useGetTemplateQuery,
  useUpdateTemplateMutation,
} from 'features/template/query-builder';
import { useDeepCompareEffect } from 'react-use';
import { useTemplateId } from '../../automation/utils/use-template-id';
import intersection from 'lodash/intersection';
import { useQueryClient } from 'react-query';
import produce from 'immer';
import { Template, TraySolutionIdTrigger } from '@process-street/subgrade/process';
import { useSelectedTemplateTriggers } from './use-selected-template-triggers';

/** Remove preselected tray triggers if a template solution instance id exists */
export function useWatchPreferredTraySolution() {
  const { configuredInstances, preferredTrayTriggerAndAppNames } = useSelectedTemplateTriggers(
    'configuredInstances',
    'preferredTrayTriggerAndAppNames',
  );

  const templateId = useTemplateId();
  const templateQuery = useGetTemplateQuery({ templateId });

  useDeepCompareEffect(() => {
    if (!templateQuery.data) return;
    const { id: templateId, name, preferredTriggers } = templateQuery.data;
    const configuredAppNames = configuredInstances
      .filter(isTemplateSolutionInstance)
      .map(i => withTrayPrefix(i.automationApp));

    const sharedNames = intersection(
      configuredAppNames,
      preferredTrayTriggerAndAppNames.map(([_, appName]) => appName),
    );
    if (!sharedNames.length) return;

    const traySolutionIdTriggers = sharedNames
      .map(appName => {
        const traySolution = preferredTrayTriggerAndAppNames.find(([, name]) => name === appName)?.[0];
        return traySolution;
      })
      .filter((trigger): trigger is TraySolutionIdTrigger => Boolean(trigger));

    const updatedTriggers = produce(new Set(preferredTriggers), draft => {
      traySolutionIdTriggers.forEach(idTrigger => {
        draft.delete(idTrigger);
      });
    });

    updateTemplateMutation.mutate({ templateId, name, preferredTriggers: [...updatedTriggers] });
  }, [configuredInstances, templateQuery.data]);

  const queryClient = useQueryClient();
  const updateTemplateMutation = useUpdateTemplateMutation<Template['preferredTriggers']>({
    onMutate: variables => {
      if (!templateQuery.data) return;
      queryClient.setQueryData<GetTemplateQueryResponse | undefined>(
        GetTemplateQuery.getKey({ templateId }),
        current => {
          if (!current) return;
          return {
            ...current,
            preferredTriggers: variables.preferredTriggers ?? [],
          };
        },
      );
      return templateQuery.data.preferredTriggers ?? [];
    },
    onError: (_err, _vars, ctx) => {
      queryClient.setQueryData<GetTemplateQueryResponse | undefined>(
        GetTemplateQuery.getKey({ templateId }),
        current => {
          if (!current) return;
          return {
            ...current,
            preferredTriggers: ctx ?? [],
          };
        },
      );
    },
  });
}
