import { Box, Button, VStack } from 'components/design/next';
import { ActivityObjectType, ActivityVerb } from '@process-street/subgrade/activity';
import { BlvdSelect, BLVD_SELECT_MAGIC_GRID_AREA } from 'components/design/BlvdSelect';
import { ActivitySentence } from './sentence';
import * as React from 'react';
import { TemplateType } from '@process-street/subgrade/process';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { OnChangeValue } from 'react-select';
import { InterpretedActivity } from 'services/activities/interpreters/InterpreterContext';
import { LoadingState } from './loading-state';
import { match } from 'ts-pattern';
import { EmptyState } from './empty-state';

type FilterOptionType = {
  verb: ActivityVerb | null;
  objectType: ActivityObjectType | null;
  templateType: TemplateType | null;
  label: string;
};

export type OrganizationActivityPageProps = {
  sentences: InterpretedActivity[];
  onFilterChange: (option: FilterOptionType) => void;
  isLoading?: boolean;
  showLoadMore?: boolean;
  loadMore: () => void;
};

export const OrganizationActivityPage: React.FC<React.PropsWithChildren<OrganizationActivityPageProps>> = ({
  sentences = [],
  onFilterChange,
  showLoadMore,
  loadMore,
  isLoading,
}) => {
  const [filter, setFilter] = React.useState<FilterOptionType>(allActivityOption);

  const handleChange = (value: OnChangeValue<FilterOptionType, false>) => {
    if (!BlvdSelectHelpers.isOptionType<FilterOptionType>(value)) return;

    const option = value ? value : allActivityOption;

    setFilter(option);
    onFilterChange(option);
  };

  return (
    <VStack w="full" alignItems="start" spacing="4">
      <BlvdSelect
        value={filter}
        getOptionValue={o => o.label}
        options={filterOptions}
        onChange={handleChange}
        components={{
          SingleValue: props => {
            return (
              <Box color="gray.600" px="1" gridArea={BLVD_SELECT_MAGIC_GRID_AREA}>
                Displaying {props.data.label.toLowerCase()}
              </Box>
            );
          },
        }}
      />

      {match({ sentences, isLoading })
        .when(
          ({ sentences, isLoading }) => !sentences.length && !isLoading,
          () => <EmptyState />,
        )
        .when(
          ({ sentences, isLoading }) => !sentences.length && isLoading,
          () => <LoadingState />,
        )
        .otherwise(() => (
          <VStack
            w="full"
            spacing="0"
            borderWidth="thin"
            borderColor="gray.200"
            borderRadius="base"
            borderStyle="solid"
          >
            {sentences.map(sentence => {
              return <ActivitySentence key={sentence.activity.id} sentence={sentence} />;
            })}

            {showLoadMore && (
              <Button
                variant="ghost"
                colorScheme="gray"
                fontWeight="normal"
                color="gray.600"
                w="full"
                justifyContent="flex-start"
                borderTopWidth="thin"
                borderTopColor="gray.200"
                borderTopRadius="base"
                borderTopStyle="solid"
                borderRadius="0"
                pl="16"
                onClick={() => loadMore()}
                isLoading={isLoading}
                isDisabled={isLoading}
                loadingText="Loading older activities..."
              >
                Show older activities...
              </Button>
            )}
          </VStack>
        ))}
    </VStack>
  );
};

const allActivityOption = {
  label: 'All activity',
  objectType: null,
  verb: null,
  templateType: null,
};

const deletedPagesOption = {
  label: 'Deleted pages',
  objectType: ActivityObjectType.Template,
  verb: ActivityVerb.Deleted,
  templateType: TemplateType.Page,
};

const deletedWorkflowsOption = {
  label: 'Deleted workflows',
  objectType: ActivityObjectType.Template,
  verb: ActivityVerb.Deleted,
  templateType: TemplateType.Playbook,
};

const deletedWorkflowRunsOption = {
  label: 'Deleted workflow runs',
  objectType: ActivityObjectType.Checklist,
  verb: ActivityVerb.Deleted,
  templateType: TemplateType.Playbook,
};

const deletedFormsOption = {
  label: 'Deleted forms',
  objectType: ActivityObjectType.Template,
  verb: ActivityVerb.Deleted,
  templateType: TemplateType.Form,
};

const deletedFormResponseOption = {
  label: 'Deleted form responses',
  objectType: ActivityObjectType.Checklist,
  verb: ActivityVerb.Deleted,
  templateType: TemplateType.Form,
};

const filterOptions: FilterOptionType[] = [
  allActivityOption,
  deletedPagesOption,
  deletedFormsOption,
  deletedFormResponseOption,
  deletedWorkflowsOption,
  deletedWorkflowRunsOption,
];
