import * as React from 'react';
import { Alert, Box, Button, CloseButton, Text } from 'components/design/next';

export type CreateWorkflowProps = {
  onClose: () => void;
  onOpenNewOrganizationModal: () => void;
};

export const CreateWorkflow: React.FC<React.PropsWithChildren<CreateWorkflowProps>> = ({
  onOpenNewOrganizationModal,
  onClose,
}) => {
  return (
    <>
      <Alert
        flexDirection="column"
        alignItems="center"
        color="gray.500"
        backgroundColor="brand.100"
        borderRadius="base"
        border="1px solid"
        borderColor="brand.300"
      >
        <Box marginTop={6} marginBottom={4}>
          <iframe
            src="https://www.youtube.com/embed/Ow0086yLROw"
            title="Process Street"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>

        <Text fontSize="md" color="gray.700" fontWeight="bold">
          Supercharge your workflows
        </Text>
        <Text fontSize="md" color="gray.600">
          Create a workflow for your team
        </Text>

        <Button colorScheme="brand" w="100%" mt={4} onClick={onOpenNewOrganizationModal}>
          Get Started
        </Button>
        <CloseButton size="sm" position="absolute" right="8px" top="8px" onClick={onClose} />
      </Alert>
    </>
  );
};
