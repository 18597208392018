import * as React from 'react';
import { Box } from 'components/design/next';
import { useIsEditable } from 'features/rich-text';
import { setFragmentData, toSlateRange } from '@udecode/slate-react';
import { select } from '@udecode/slate';
import { usePagesEditorState } from '../../pages-plate-types';

export const ReadOnlyCopyWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const editor = usePagesEditorState();
  const readOnly = !useIsEditable();

  const handleReadOnlyCopy: React.ClipboardEventHandler<HTMLDivElement> = evt => {
    // Based on: https://slate-js.slack.com/archives/C1RH7AXSS/p1620141644335000?thread_ts=1620060633.309400&cid=C1RH7AXSS
    // If editor is readOnly, we still want to enable Slate JSON copy/paste.
    if (readOnly) {
      evt.preventDefault();
      // Get the user's current selection range
      const domRange = window.getSelection();
      if (domRange) {
        // If they have a range, convert to a slate range in the editor
        const range = toSlateRange(editor, domRange, { exactMatch: true, suppressThrow: true });
        if (range) {
          // Apply the slate range as a selection in the editor
          select(editor, range);
          // Get the selected Slate data and copy it to the clipboard
          setFragmentData(editor, evt.clipboardData);
        }
      }
    }
  };

  return (
    <Box as="span" onCopy={handleReadOnlyCopy}>
      {children}
    </Box>
  );
};
