import { Checklist } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateChecklistNameMutation {
  export type Params = { checklistId: Checklist['id']; name: string };

  export type Response = Checklist;

  export const key = ['post', 'checklist', 'name'];

  export const mutationFn = (params: Params): Promise<Response> =>
    axiosService
      .getAxios()
      .put<Response>(`/1/checklists/${params.checklistId}/name`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
