import React from 'react';
import {
  TaskAssignmentRuleWithWidget,
  UserWithAssignmentId,
} from 'app/pages/templates/_id/components/assign-task-button/use-task-assignments';
import { useSelector } from '@xstate/react';
import { TaskTemplate } from '@process-street/subgrade/process';
import _groupBy from 'lodash/groupBy';
import _flatMap from 'lodash/flatMap';
import _keyBy from 'lodash/keyBy';
import { GroupType } from '@process-street/subgrade/core';
import { UseAssignmentsQuerySelectors } from 'app/pages/templates/_id/components/assignment-picker/use-assignments-query';
import { uniqWith } from 'lodash';
import { TaskAssignmentRuleUtils } from '@process-street/subgrade/role-assignment';
import { FormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-types';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';

/**
 * Based on frontend/app/pages/templates/_id/components/assign-task-button/use-task-assignments.ts
 * but this version uses xstate selectors and handles many tasks.
 */
export function useTaskAssignments({
  taskTemplates,
  editorActor,
}: {
  taskTemplates: TaskTemplate[];
  editorActor: FormEditorPageActorRef;
}): {
  assignmentRules: TaskAssignmentRuleWithWidget[];
  uniqueRules: TaskAssignmentRuleWithWidget[];
  assignees: UserWithAssignmentId[];
} {
  // Task assignments
  const taskAssignments = useSelector(editorActor, FormEditorPageActorSelectors.getTaskAssignments);
  const taskAssignmentsMap = React.useMemo(
    () => _groupBy(taskAssignments, assignment => assignment.taskTemplate.id),
    [taskAssignments],
  );

  // TODO: check if we need to remove dupes (frontend/app/components/template-task-assignment/bulk-assigner/template-task-bulk-assigner.component.js)
  const selectedTasksTaskAssignments = React.useMemo(() => {
    return _flatMap(taskTemplates, taskTemplate => taskAssignmentsMap[taskTemplate.id] || []);
  }, [taskTemplates, taskAssignmentsMap]);

  // Task rule assignments
  const taskAssignmentRules = useSelector(editorActor, FormEditorPageActorSelectors.getTaskAssignmentRules);
  const taskAssignmentRulesMap = React.useMemo(
    () => _groupBy(taskAssignmentRules, assignment => assignment.targetTaskTemplateGroup.id),
    [taskAssignmentRules],
  );
  const selectedTasksTaskAssignmentsRules = React.useMemo(() => {
    return _flatMap(taskTemplates, taskTemplate => taskAssignmentRulesMap[taskTemplate.group.id] || []);
  }, [taskTemplates, taskAssignmentRulesMap]);

  const groups = useSelector(editorActor, FormEditorPageActorSelectors.getGroups);

  const organizationMemberships = useSelector(editorActor, FormEditorPageActorSelectors.getOrganizationMemberships);

  const organizationMembershipAssignmentMap = React.useMemo(() => {
    return selectedTasksTaskAssignments.reduce((acc, assignment) => {
      acc[assignment.organizationMembership.id] = assignment.id;
      return acc;
    }, {} as Record<string, string>);
  }, [selectedTasksTaskAssignments]);

  const assignees = React.useMemo(() => {
    const systemGroups = groups.filter(group => group.groupType !== GroupType.Standard);
    const systemGroupUserIds = new Set(systemGroups.map(group => group.user.id));
    return organizationMemberships
      .filter(om => !systemGroupUserIds.has(om.user.id) && organizationMembershipAssignmentMap[om.id])
      .map(om => ({
        ...om.user,
        organizationMembershipId: om.id,
        assignmentId: organizationMembershipAssignmentMap[om.id],
      }));
  }, [groups, organizationMembershipAssignmentMap, organizationMemberships]);

  const widgets = useSelector(editorActor, FormEditorPageActorSelectors.getWidgets);
  const emailOrMembersWidgets = React.useMemo(
    () => widgets.filter(UseAssignmentsQuerySelectors.isEmailOrMembersFormFieldWidget),
    [widgets],
  );

  const assignmentRules = React.useMemo(() => {
    return selectedTasksTaskAssignmentsRules.map(rule => ({
      ...rule,
      widget: emailOrMembersWidgets.find(widget => widget.header.group.id === rule.sourceFormFieldWidgetGroup?.id),
    }));
  }, [selectedTasksTaskAssignmentsRules, emailOrMembersWidgets]);

  const uniqueRules = uniqWith(assignmentRules, TaskAssignmentRuleUtils.areRulesSameType);

  return { uniqueRules, assignees, assignmentRules };
}
