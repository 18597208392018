export function downloadUrl({ url, filename }: { url: string; filename: string }) {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = filename;
  const handler = () => {
    anchor.removeEventListener('click', handler);
    document.body.removeChild(anchor);
  };
  anchor.addEventListener('click', handler);
  document.body.appendChild(anchor);
  anchor.click();
}
