import { createWidget } from 'pages/pages/_id/edit/page/utils/widget.api';
import { useMutation } from 'react-query';

export const CreateWidgetMutation = {
  key: ['widget', 'create'],
  getKey() {
    return [...CreateWidgetMutation.key];
  },
  mutationFn: createWidget,
};

export const useCreateWidgetMutation = () => {
  return useMutation(CreateWidgetMutation.mutationFn, {
    mutationKey: CreateWidgetMutation.getKey(),
  });
};
