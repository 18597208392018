import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { ButtonWithUpgradeTooltip } from './index';

export const ButtonWithUpgradeTooltipModule = angular
  .module('buttonWithUpgradeTooltip', [])
  .component(
    'psButtonWithUpgradeTooltip',
    reactToAngular(ButtonWithUpgradeTooltip, ['icon', 'label', 'isButton']),
  ).name;
