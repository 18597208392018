import React from 'react';
import { DayPicker } from 'react-day-picker';
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack,
} from 'components/design/next';
import 'react-day-picker/dist/style.css';
import { DateFieldUtils } from '@process-street/subgrade/util';
import { match } from 'ts-pattern';
import { FocusableDatePickerInput, useBlvdDatePicker, UseBlvdDatePickerProps } from './use-blvd-date-picker';
import { SystemStyleObject } from '@chakra-ui/system';

export interface BlvdDatePickerProps extends Omit<UseBlvdDatePickerProps, 'ref'> {
  formatTriggerLabel?: (date: Date | undefined) => string;
  triggerProps?: ButtonProps;
  id?: string;
}

const BlvdDatePickerComponent: React.ForwardRefRenderFunction<FocusableDatePickerInput, BlvdDatePickerProps> = (
  props,
  ref,
) => {
  const { dateContext = {} } = props;

  const {
    dateStringInputProps,
    dayPickerProps,
    disclosure,
    handleSave,
    onTimeStatusChange,
    saveBtnRef,
    timeStatus,
    timeStringInputProps,
  } = useBlvdDatePicker({ ...props, ref });

  const formatTriggerLabel = React.useCallback(
    (d: Date | undefined) => {
      if (props.formatTriggerLabel) {
        return props.formatTriggerLabel(d);
      }
      return d
        ? DateFieldUtils.formatDateFieldValueToLongWeekdayShortMonth(
            { value: d.getTime(), timeHidden: props.timeStatus !== 'visible' },
            dateContext,
          )
        : 'Select date';
    },
    [dateContext, props],
  );

  // Use parent props to calculate trigger label (for the "onSave" case)
  const formattedDate = formatTriggerLabel(props.value);

  return (
    <Box sx={RDP_STYLES}>
      <Popover {...disclosure} placement="bottom-start" isLazy>
        <Tooltip label={formattedDate} openDelay={1000} hasArrow isDisabled={disclosure.isOpen || !props.value}>
          <span>
            <PopoverTrigger>
              <Button
                id={props.id}
                textAlign="left"
                iconSpacing="3"
                w="auto"
                maxW="full"
                variant="outline"
                colorScheme="gray"
                leftIcon={<Icon icon="calendar-days" size="4" mb="px" color="gray.400" />}
                fontWeight="normal"
                color={!props.value ? 'gray.500' : 'gray.600'}
                isDisabled={props.isDisabled}
                {...(props.isInvalid ? { 'borderColor': 'red.500', 'aria-invalid': true } : {})}
                {...props.triggerProps}
              >
                <Text w="full" noOfLines={1}>
                  {formattedDate}
                </Text>
              </Button>
            </PopoverTrigger>
          </span>
        </Tooltip>
        <PopoverContent w="xs">
          <PopoverBody as={VStack}>
            <HStack w="full">
              <FormControl flex="1">
                <FormLabel>Date</FormLabel>

                <Input type="text" {...dateStringInputProps} />
              </FormControl>

              <Box flex="1">
                {match(timeStatus)
                  .with('hidden', () => (
                    <FormControl>
                      <FormLabel>Time</FormLabel>

                      <Button
                        w="full"
                        variant="outline"
                        borderWidth="px"
                        colorScheme="gray"
                        onClick={() => onTimeStatusChange('visible')}
                        leftIcon={<Icon icon="clock" size="4" />}
                        fontWeight="normal"
                      >
                        Add time
                      </Button>
                    </FormControl>
                  ))
                  .with('visible', () => (
                    <FormControl>
                      <FormLabel>Time</FormLabel>
                      <InputGroup
                        sx={{
                          'input[type="time"]::-webkit-calendar-picker-indicator': {
                            display: 'none',
                          },
                        }}
                      >
                        <Input type="time" {...timeStringInputProps} />
                        <InputRightElement>
                          <IconButton
                            variant="ghost"
                            colorScheme="gray"
                            size="xs"
                            icon={<Icon icon="close" size="4" />}
                            onClick={() => onTimeStatusChange('hidden')}
                            aria-label="remove time"
                          />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  ))
                  .otherwise(() => null)}
              </Box>
            </HStack>

            <Box pt="2" w="full">
              <Button
                w="full"
                variant="solid"
                colorScheme="gray"
                onClick={e => {
                  dayPickerProps.onSelect?.(undefined, new Date(), {}, e);
                }}
              >
                Today
              </Button>
            </Box>

            <DayPicker {...dayPickerProps} />
          </PopoverBody>

          {props.onSave ? (
            <PopoverFooter display="flex" justifyContent="flex-end">
              <ButtonGroup>
                <Button variant="ghost" colorScheme="gray" fontWeight="normal" onClick={disclosure.onClose}>
                  Cancel
                </Button>
                <Button ref={saveBtnRef} variant="primary" onClick={handleSave}>
                  Save
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          ) : null}
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export const BlvdDatePicker = React.forwardRef(BlvdDatePickerComponent);

const RDP_STYLES: SystemStyleObject = {
  '.rdp-day:not(.rdp-day_selected)': {
    borderRadius: '4px',
  },
  '.rdp-day_range_start, .rdp-day_range_end': {
    borderRadius: '4px',
    fontWeight: 'bold',
  },

  '.rdp-day_range_middle': {
    backgroundColor: 'var(--ps-colors-brand-200)',
    borderRadius: 'initial',
    color: 'initial',
  },
  '--rdp-accent-color': 'var(--ps-colors-brand-500)',
  '--rdp-background-color': 'var(--ps-colors-gray-100)',
  '--rdp-outline': 'none',
  '--rdp-outline-selected': 'none',
};
