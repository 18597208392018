import { ConditionalFilterStringOperand } from '@process-street/subgrade/dashboard';
import { BlvdTextInput } from 'components/design/BlvdTextInput';
import React from 'react';
import styles from './StringOperandEditor.module.scss';

export interface StringOperandEditorProps {
  operand: ConditionalFilterStringOperand;
  onChange: (operand: ConditionalFilterStringOperand) => void;
}

export const StringOperandEditor: React.FunctionComponent<React.PropsWithChildren<StringOperandEditorProps>> = ({
  operand,
  onChange,
}) => {
  const handleChange = (value: string) => {
    onChange({ ...operand, value });
  };

  return (
    <div className={styles.stringOperand}>
      <BlvdTextInput value={operand.value} onChange={handleChange} debounceDelay={0} />
    </div>
  );
};
