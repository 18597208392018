import angular from 'angular';
import templateUrl from './form-field-audit.component.html';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';

angular.module('frontStreetApp.directives').component('psFormFieldAudit', {
  bindings: {
    audit: '<',
    timeZone: '<',
  },
  templateUrl,
  controller($filter, AuditFormatService, FeatureFlagService) {
    const ctrl = this;
    this.FeatureFlagService = FeatureFlagService;
    ctrl.$onInit = function () {
      calculateFormattedAudit();
    };

    ctrl.$onChanges = function () {
      calculateFormattedAudit();
    };

    ctrl.getFormattedDate = function () {
      if (!ctrl.audit) return '';

      const auditorTimezone = ctrl.audit?.updatedBy?.timeZone || ctrl.timeZone;
      const date = ctrl.audit.updatedDate;

      if (this.FeatureFlagService.getFeatureFlags().pdfEmbellishment) {
        return DateUtils.formatDateTime(date, DateFormat.DateTimeLongWeekdayShortMonthWithZone, auditorTimezone);
      } else {
        return $filter('date')(date, "MMM d 'at' h:mma", auditorTimezone);
      }
    };

    function calculateFormattedAudit() {
      if (ctrl.audit) {
        AuditFormatService.formatAudit(
          {
            by: ctrl.audit.updatedBy,
            date: ctrl.audit.updatedDate,
          },
          ctrl.timeZone,
        ).then(formattedAudit => {
          ctrl.formattedAudit = formattedAudit;
        });

        ctrl.formFieldChangeDate = AuditFormatService.formatAuditDate(ctrl.audit.updatedDate, ctrl.timeZone);
      } else {
        ctrl.formattedAudit = undefined;
      }
    }
  },
});
