import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import {
  RulesDeltaUpdate,
  TaskTemplateVisibility,
  WidgetVisibility,
} from 'directives/rules/template/rules-manager/rules-manager-helper';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type UpdateRulesDeltaByTemplateRevisionIdMutationParams = {
  templateRevisionId: string;
  delta: {
    rules: RulesDeltaUpdate;
    taskTemplatesHiddenByDefault: TaskTemplateVisibility[];
    widgetsHiddenByDefault: WidgetVisibility[];
  };
};

type UpdateRulesDeltaByTemplateRevisionIdMutationResult = {
  definitions: ChecklistRuleDefinition[];
  updatedTaskTemplates: TaskTemplate[];
  updatedWidgets: Widget[];
};

export const UpdateRulesDeltaByTemplateRevisionIdMutation = {
  key: ['template-revision', 'rules'],
  mutationFn: ({ templateRevisionId, delta }: UpdateRulesDeltaByTemplateRevisionIdMutationParams) =>
    axiosService
      .getAxios()
      .post(`/1/template-revisions/${templateRevisionId}/rule-definitions/delta`, delta)
      .then(r => r.data),
};

export const useUpdateRulesDeltaByTemplateRevisionIdMutation = (
  options: UseMutationOptions<
    UpdateRulesDeltaByTemplateRevisionIdMutationResult,
    AxiosError,
    UpdateRulesDeltaByTemplateRevisionIdMutationParams
  > = {},
) => {
  return useMutation(UpdateRulesDeltaByTemplateRevisionIdMutation.mutationFn, options);
};
