import { isAi, isHeading, TaskStatus, TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { StateFrom } from 'xstate';
import { FormResponseMachine } from './form-response-machine';
import { FormMachineUtils } from './form-response-machine-utils';

export type State = StateFrom<FormResponseMachine>;

export const FormResponseMachineSelectors = {
  getTotalTaskCount: (state: State) =>
    Object.values(state.context.taskActorsMap).filter(actor => !FormMachineUtils.taskActorIsHiddenByRule(actor)).length,
  getCurrentTaskIndex: (state: State) =>
    FormMachineUtils.getCurrentTaskIndex(state.context.taskActorsMap, state.context.currentTaskActor),
  getTaskActorsMap: (state: State) => state.context.taskActorsMap,
  getCurrentTaskActor: (state: State) => state.context.currentTaskActor,
  getIsCompletingCurrentTask: (state: State) => state.matches('response.active.completingCurrentTask'),
  getIsUncompletingCurrentTask: (state: State) =>
    state.matches('response.active.uncompletingPreviousTask') ||
    state.matches('response.active.uncompletingCurrentTask'),
  getIsComplete: (state: State) => state.matches('response.complete'),
};

export const FormResponseMachineChecklistSelectors = {
  getChecklist: (state: State) => state.context.checklist,
  getApprovals: (state: State) => state.context.approvals,
  getApprovalRules: (state: State) => state.context.approvalRules,
  getTasks: (state: State) =>
    Object.values(state.context.taskActorsMap)
      .map(actor => actor.getSnapshot()?.context.task)
      .filter((task): task is TaskWithTaskTemplate => Boolean(task)),
  getCompletionPercentage: (state: State) => {
    const { oneOffTasks } = state.context;
    const completedOneOffTasksCount = oneOffTasks.filter(task => task.status === TaskStatus.Completed).length;

    const tasks = FormResponseMachineChecklistSelectors.getTasks(state)
      .filter((task): task is TaskWithTaskTemplate => Boolean(task))
      .filter(task => !isHeading(task.taskTemplate) && !isAi(task.taskTemplate) && !task.hidden);

    const completedCount = tasks.filter(task => task.status === TaskStatus.Completed).length;

    const totalCompletedCount = completedCount + completedOneOffTasksCount;
    const totalTaskCount = tasks.length + oneOffTasks.length;

    return (totalCompletedCount / totalTaskCount) * 100;
  },
  getShouldHideCompletedTasks: (state: State) => state.context.shouldHideCompletedTasks,
  getShouldHideStoppedTasks: (state: State) => state.context.shouldHideStoppedTasks,
};
