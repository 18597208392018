import angular from 'angular';
import { TemplateSelector } from 'reducers/template/template.selectors';
import { connectController } from 'reducers/util';
import { canAccess, Feature } from 'services/features/features';
import './template-share-modal.scss';
import { TemplateType } from '@process-street/subgrade/process';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'TemplateShareModal',
    function ($scope, $ngRedux, modalOptions, OrganizationService, SessionService, Subject, UserService) {
      const ctrl = this;

      ctrl.$onInit = () => {
        $scope.template = modalOptions.template;
        $scope.templateRevision = modalOptions.templateRevision;
        $scope.user = modalOptions.user;
        $scope.tab = modalOptions.tab;
        $scope.hideTabs = modalOptions.hideTabs;

        initPermissions();
        initFeatureAvailability();
        initStore();

        $scope.$on('$stateChangeStart', () => {
          $scope.$close();
        });

        $scope.activeIndex = (() => {
          switch ($scope.tab) {
            case 'runlink':
              return 2;
            case 'sharelink':
              return 1;
            default:
              return undefined;
          }
        })();
      };

      $scope.shouldShowChecklistRunLink = () =>
        $scope.checklistRunLinkAvailable && $scope.template.templateType === 'Playbook';

      $scope.shouldShowTemplateSharing = () =>
        $scope.templateSharingAvailable &&
        ($scope.template.templateType === 'Playbook' || $scope.template.templateType === 'Page');

      $scope.getIcon = () => {
        switch ($scope.template.templateType) {
          case TemplateType.Page:
            return 'file-alt';
          case TemplateType.Form:
            return 'ballot';
          default:
            return 'workflow';
        }
      };

      $scope.getIconColor = () => {
        switch ($scope.template.templateType) {
          case TemplateType.Page:
            return 'teal.500';
          case TemplateType.Form:
            return 'orange.500';
          default:
            return 'indigo.500';
        }
      };

      $scope.getModalTitle = () => {
        if ($scope.hideTabs) {
          switch ($scope.tab) {
            case 'runlink':
              return 'Workflow Run Link';
            case 'sharelink':
              return `Share ${$scope.template?.name}`;
            default:
              return `Permissions for ${$scope.template?.name}`;
          }
        }

        return $scope.template?.name;
      };

      $scope.getModalIcon = () => {
        if ($scope.hideTabs) {
          switch ($scope.tab) {
            case 'runlink':
              return 'play';
            case 'sharelink':
              return 'share-nodes';
            default:
              return 'lock';
          }
        }
      };

      function initFeatureAvailability() {
        OrganizationService.getById($scope.subject.organizationId).then(organization => {
          const planId = organization && organization.subscription.plan.id;

          $scope.checklistRunLinkAvailable = canAccess(Feature.CHECKLIST_RUN_LINK, planId);
          $scope.templateSharingAvailable = canAccess(Feature.TEMPLATE_SHARING, planId);
        });
      }

      function initPermissions() {
        $scope.subject = new Subject(UserService.getCurrentUser().id, SessionService.getSelectedOrganizationId());
      }

      function initStore() {
        const mapStateToThis = () => state => ({
          isTemplatePrivate: TemplateSelector.isTemplatePrivate(state)($scope.template.id),
        });

        connectController($ngRedux, mapStateToThis)(ctrl);
      }
    },
  );
