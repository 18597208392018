export const FormFieldEvent = {
  DISABLED_FORM_FIELD_CLICKED: 'disabled form field clicked',

  SUB_CHECKLIST_ITEM_STATUS_UPDATED: 'sub-checklist item status updated',
  FORM_FIELD_VALUE_UPDATED: 'form field value updated',
  SUB_CHECKLIST_ADD_ITEM_BUTTON_CLICKED: 'sub-checklist add item button clicked',

  // Request events

  FORM_FIELD_VALUE_UPDATE_STARTED: 'form field value update started',
  FORM_FIELD_VALUE_UPDATE_OK: 'form field value update ok',
  FORM_FIELD_VALUE_UPDATE_FAILED: 'form field value update failed',
  FORM_FIELD_VALUE_UPDATE_FINISHED: 'form field value update finished',

  FORM_FIELD_VALUE_LIVE_UPDATED: 'form field value live updated',
  FORM_FIELD_VALUE_LIVE_DELETED: 'form field value live deleted',

  FORM_FIELD_INTERACTION_ENDED: 'form field interaction ended',
} as const;
