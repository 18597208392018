import { forwardRef, omitThemingProps, ThemingProps, useStyleConfig, HTMLChakraProps } from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import * as React from 'react';
import { Box } from 'components/design/next';

export interface EditorListItemProps extends HTMLChakraProps<'div'>, ThemingProps {}

export const EditorListItem = forwardRef<EditorListItemProps, 'div'>((props, ref) => {
  const { className, ...rest } = omitThemingProps(props);
  const styles = useStyleConfig('EditorListItem', props);
  const _className = cx('chakra-editor-list-item', className);

  return <Box role="listitem" sx={styles} ref={ref} className={_className} {...rest} />;
});
