import { Checklist } from '@process-street/subgrade/process';
import * as React from 'react';
import { AutomationsStatusIndicator } from '../../../automations-status-indicator/component';
import { useSolutionInstancesByTemplateIdQuery } from 'app/features/automations/query-builder';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';

export const AutomationsIndicator = ({ checklist }: { checklist: Checklist }) => {
  const templateId = checklist.template.id;
  const canUpdateTemplate =
    useGetConsolidatedTemplatePermissionsQuery(templateId, {
      select: data => data.permissionMap.templateUpdate,
    }).data ?? false;
  const automationsQuery = useSolutionInstancesByTemplateIdQuery({ templateId }, { enabled: canUpdateTemplate });
  const hasAutomations = automationsQuery.data?.some(automation => automation.enabled);

  return canUpdateTemplate && hasAutomations ? (
    <AutomationsStatusIndicator checklistId={checklist.id} checklistOpenedAt={Date.now()} />
  ) : null;
};
