import * as React from 'react';
import type { Template } from '@process-street/subgrade/process';

import { useCopyTemplateLink } from 'hooks/use-copy-template-link';

export type Props = {
  templateId: Template['id'];
  children: (props: {
    copiedValue: string;
  }) => React.ReactElement<{ onClick: React.MouseEventHandler<HTMLButtonElement> }>;
};
export const CopyShareLinkButton: React.FC<Props> = React.memo(({ children, templateId }) => {
  const [copiedValue, handleCopyShareLink] = useCopyTemplateLink(templateId);

  if (typeof children !== 'function') {
    throw new Error('CopyShareLinkButton must be a render prop function');
  }
  const result = children({ copiedValue });
  if (result === null || !React.isValidElement(result) || Array.isArray(result)) {
    throw new Error('The render prop function child must return a single clickable element  or a render prop function');
  }

  // UI
  const btn = React.cloneElement(result, {
    onClick: handleCopyShareLink,
  });

  return btn;
});
