import { InboxItemType } from '@process-street/subgrade/inbox';
import { ChecklistStatus } from '@process-street/subgrade/process';
import { GetInboxItemsQuery } from 'app/features/microsoft-teams/query-builder';
import { useNextThemeToast } from 'components/design/next/use-next-theme-toast';
import { UpdateChecklistStatusMutation } from 'features/checklists/query-builder/update-checklist-status-mutation';
import isFunction from 'lodash/isFunction';
import { useQueryClient } from 'react-query';
import { UpdateErrorToasts } from '../helpers';

export const useCompleteChecklistMutation = (isShowingCompleted?: boolean) => {
  const toast = useNextThemeToast();
  const queryClient = useQueryClient();

  return UpdateChecklistStatusMutation.useMutation({
    onMutate: variables => {
      if (variables.status === ChecklistStatus.Completed) {
        if (isShowingCompleted) {
          return GetInboxItemsQuery.updateItem(
            queryClient,
            item => item.itemType === InboxItemType.Checklist && item.checklist.id === variables.checklistId,
            item => ({ ...item, checklist: { ...item.checklist, status: ChecklistStatus.Completed } }),
          );
        } else {
          return GetInboxItemsQuery.removeItem(
            queryClient,
            item => item.itemType === InboxItemType.Checklist && item.checklist.id === variables.checklistId,
          );
        }
      }
    },
    onError: (error, _variables, context) => {
      if (isFunction(context)) {
        // Revert the optimistic update in case of error
        context();
      }

      toast(UpdateErrorToasts.getWorkflowUpdateErrorToastOptions(error.response));
    },
  });
};
