import * as React from 'react';
import { PlanSelector, PlanSelectorProps } from 'pages/organizations/manage/billing/components/plan-selector/component';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Text,
} from 'components/design/next';

export type PlanSelectorModalProps = {
  isOpen: boolean;
  onClose: () => void;
} & PlanSelectorProps;

export const PlanSelectorModal: React.FC<React.PropsWithChildren<PlanSelectorModalProps>> = ({
  isOpen,
  onClose,
  ...rest
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false} size="3xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text variant="2" color="gray.700">
            Plans
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PlanSelector {...rest} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
