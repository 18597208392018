import { DateFormat } from '@process-street/subgrade/util';
import { useParentWidthValue } from './use-parent-width-value';

export function useResponsiveDateTicks({ width, total }: { width: number; total: number }) {
  const format = useParentWidthValue(width, {
    xl: DateFormat.DateShortMonth,
    base: 'MM/DD/YY',
  });

  const tickCount = useParentWidthValue(width, {
    base: 2,
    md: 5,
    lg: Math.min(total, 10),
  });

  return { format, tickCount };
}
