import {
  CHECKLIST_ARRAY_ACTIONS,
  CHECKLIST_DELETE_BY_ID,
  CHECKLIST_SEARCH,
  CHECKLIST_SINGLE_ACTION,
} from 'reducers/checklist/checklist.actions';
import { composeReducerObjects, concatInDistinctArray } from 'reducers/util';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';

export const checklistByTemplateReducer = composeReducerObjects({
  [CHECKLIST_ARRAY_ACTIONS]: (state, { payload: checklists }) =>
    LookupsReducerUtils.upsertAll(state, checklists, 'template.id', 'id'),
  [CHECKLIST_SEARCH]: (state, { payload: searchResults }) =>
    LookupsReducerUtils.upsertAll(
      state,
      searchResults.map(({ checklist }) => checklist),
      'template.id',
      'id',
    ),
  [CHECKLIST_SINGLE_ACTION]: (state, { payload: checklist }) => {
    const {
      id: checklistId,
      template: { id: templateId },
    } = checklist;
    return {
      ...state,
      [templateId]: concatInDistinctArray(state[templateId], checklistId),
    };
  },
  [CHECKLIST_DELETE_BY_ID]: (state, { meta: { checklistId, checklist } }) => {
    if (checklist) {
      const newTemplateState = (state[checklist.template.id] || []).filter(id => id !== checklistId);
      return { ...state, [checklist.template.id]: newTemplateState };
    } else {
      return state;
    }
  },
});
