import * as React from 'react';
import { Button, Icon } from 'app/components/design/next';
import {
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack,
} from 'components/design/next';
import { TinyMCEEditor } from 'app/features/rich-text';
import { getFormatButtonStyles } from './format-button';

export type TextStyleProps = {
  editor?: TinyMCEEditor;
};

const foregroundColors = [
  { label: 'Default', color: '#333333', menuClass: 'c text-color-1' },
  { label: 'Gray', color: '#b3b3b3', menuClass: 'c text-color-2' },
  { label: 'Red', color: '#E15951', menuClass: 'c text-color-3' },
  { label: 'Blue', color: '#007DB6', menuClass: 'c text-color-4' },
  { label: 'Green', color: '#00B4A6', menuClass: 'c text-color-5' },
];

const backgroundColors = [
  { label: 'Yellow', color: '#FCE8A7', menuClass: 'c highlight-1' },
  { label: 'Orange', color: '#FFD0B9', menuClass: 'c highlight-2' },
  { label: 'Green', color: '#B3F0C1', menuClass: 'c highlight-3' },
  { label: 'Blue', color: '#B4E5FA', menuClass: 'c highlight-4' },
  { label: 'Clear', color: 'rgba(0,0,0,0)', menuClass: 'c highlight-5' },
];

export const TextStyle = ({ editor }: TextStyleProps) => {
  return (
    <Popover>
      {({ isOpen }) => (
        <>
          <Tooltip label="Color" shouldWrapChildren>
            <PopoverTrigger>
              <IconButton
                {...getFormatButtonStyles({ isActive: isOpen })}
                aria-label="Color"
                icon={<Icon icon="paintbrush" size="3.5" />}
              />
            </PopoverTrigger>
          </Tooltip>

          <PopoverContent bgColor="gray.700" color="white" w="max-content" minW="max-content">
            <PopoverBody>
              <VStack w="full" spacing={4}>
                <VStack w="full" alignItems="flex-start">
                  <Text color="white" variant="-2u">
                    Text color
                  </Text>
                  <HStack spacing={2}>
                    {foregroundColors.map(color => (
                      <Tooltip key={color.color} label={color.label} placement="top">
                        <Button
                          variant="unstyled"
                          borderWidth="thin"
                          borderStyle="solid"
                          borderColor="gray.500"
                          onClick={() => editor?.execCommand('ForeColor', false, color.color)}
                          aria-label={color.label}
                          w={10}
                          h={6}
                          style={{ backgroundColor: color.color }}
                        />
                      </Tooltip>
                    ))}
                  </HStack>
                </VStack>

                <VStack w="full" alignItems="flex-start">
                  <Text color="white" variant="-2u">
                    Highlight
                  </Text>
                  <HStack spacing={2}>
                    {backgroundColors.map(color => (
                      <Tooltip key={color.color} label={color.label} placement="top">
                        <Button
                          variant="unstyled"
                          onClick={() => editor?.execCommand('Hilitecolor', false, color.color)}
                          aria-label={color.label}
                          borderWidth="thin"
                          borderStyle="solid"
                          borderColor="gray.500"
                          w={10}
                          h={6}
                          style={{ backgroundColor: color.color }}
                        />
                      </Tooltip>
                    ))}
                  </HStack>
                </VStack>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
