import * as React from 'react';
import { BlvdSelect, BlvdSelectHelpers, BlvdSelectProps } from 'components/design/BlvdSelect';
import { Timing, OffsetDirection, OFFSET_DIRECTIONS, getNewTimingFromOffsetDirection } from './rule-trees';
import { FieldTypePreservingOmit } from '@process-street/subgrade/core';

export type OffsetDirectionSelectProps = FieldTypePreservingOmit<BlvdSelectProps<OffsetDirectionOption>, 'onChange'> & {
  timing: Timing;
  onChange: React.Dispatch<React.SetStateAction<Timing>>;
};

export type OffsetDirectionOption = { value: OffsetDirection; label: string };

export const OffsetDirectionSelect: React.FC<React.PropsWithChildren<OffsetDirectionSelectProps>> = ({
  timing,
  onChange,
  ...props
}) => {
  return (
    <BlvdSelect
      value={offsetDirectionToValue(timing.offsetDirection)}
      options={OFFSET_DIRECTIONS.map(offsetDirectionToOption)}
      placeholder="When"
      onChange={option => {
        if (BlvdSelectHelpers.isOptionType<OffsetDirectionOption>(option)) {
          onChange(current => {
            const offsetDirection = option.value;
            return getNewTimingFromOffsetDirection(current, offsetDirection);
          });
        } else {
          onChange({});
        }
      }}
      {...props}
    />
  );
};

const offsetDirectionToOption = (offsetDirection: OffsetDirection): OffsetDirectionOption => ({
  value: offsetDirection,
  label: offsetDirection,
});

const offsetDirectionToValue = (offsetDirection?: OffsetDirection): OffsetDirectionOption | null =>
  offsetDirection ? offsetDirectionToOption(offsetDirection) : null;
