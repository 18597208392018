import * as React from 'react';
import { FeatureConfig, filterFeaturesByFeatureFlags } from 'components/billing/plans/features';
import { Box, Text, Tooltip } from 'components/design/next';
import { useFeatureFlags } from 'features/feature-flags';

const PricingFeature: React.FC<React.PropsWithChildren<{ feature: FeatureConfig }>> = ({ feature }) => {
  const { name, description } = feature;

  return (
    <Box height={feature.multiline ? '12' : '8'}>
      <Tooltip label={description} hasArrow={true} placement="bottom" fontSize="xs">
        <Text
          display="inline-block"
          color="gray.500"
          cursor="help"
          fontSize="xs"
          borderBottomWidth="px"
          borderBottomColor="gray.400"
          borderBottomStyle="dotted"
        >
          {name}
        </Text>
      </Tooltip>
    </Box>
  );
};

export const PricingFeatureList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const featureFlags = useFeatureFlags();
  const features = filterFeaturesByFeatureFlags(featureFlags);

  return (
    <Box>
      {features.map(([feature, config]) => (
        <PricingFeature key={feature} feature={config} />
      ))}
    </Box>
  );
};
