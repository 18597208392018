import { connectService } from 'reducers/util';

export class TaskTemplatePermitService {
  constructor($ngRedux, TaskTemplatePermitActions) {
    'ngInject';

    connectService('TaskTemplatePermitService', $ngRedux, null, TaskTemplatePermitActions)(this);
  }

  getMembershipsIntersection(taskTemplatePermits, taskTemplatesCount) {
    const omMap = taskTemplatePermits
      .map(ttp => ttp.organizationMembership)
      .reduce((map, om) => {
        if (!map[om.id]) {
          map[om.id] = { om, count: 1 };
        } else {
          map[om.id].count += 1;
        }
        return map;
      }, {});

    return Object.values(omMap)
      .filter(omItem => omItem.count === taskTemplatesCount)
      .map(omItem => omItem.om);
  }

  getMembershipsConcatenation(taskTemplatePermits) {
    const omMap = taskTemplatePermits
      .map(ttp => ttp.organizationMembership)
      .reduce((map, om) => {
        map[om.id] = om;
        return map;
      }, {});

    return Object.values(omMap);
  }

  _getRequestPermitCreate(taskTemplate, membership, permitted) {
    return {
      taskTemplateId: taskTemplate.id,
      organizationMembershipId: membership.id,
      taskRead: permitted,
      taskUpdate: permitted,
    };
  }

  upsertPermit(membership, isBulk, taskTemplate, taskTemplates, permitted) {
    if (isBulk) {
      const createRequest = taskTemplates.map(tt => this._getRequestPermitCreate(tt, membership, permitted));

      const templateRevisionId = taskTemplates[0].templateRevision.id;
      this.actions.upsertAll(templateRevisionId, createRequest);
    } else {
      const createRequest = this._getRequestPermitCreate(taskTemplate, membership, permitted);
      const templateRevisionId = taskTemplate.templateRevision.id;
      this.actions.upsert(templateRevisionId, createRequest);
    }
  }
}
