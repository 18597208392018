import angular from 'angular';
import { InboxConstants } from '@process-street/subgrade/inbox/inbox-constants';
import { InboxItemType } from '@process-street/subgrade/inbox/inbox-model';
import { trace } from 'components/trace';

angular.module('frontStreetApp.services').service('TodoService', function (InboxService, TodoDao, ToastService) {
  const logger = trace({ name: 'TodoService' });

  const self = this;

  self.completeAll = function (items) {
    const todos = convertInboxItemsToTodos(items);

    return TodoDao.updateAllStatuses(todos, InboxConstants.Status.COMPLETED).then(results => {
      logger.info('Update all statuses result: ', results);

      return results;
    });
  };

  self.updateAllDueDates = function (items, dueDate) {
    const todos = convertInboxItemsToTodos(items);

    return TodoDao.updateAllDueDates(todos, dueDate).then(results => {
      logger.info('Update all due dates result: ', results);

      return results;
    });
  };

  self.excludeApprovalTasks = items => {
    return items.reduce((arr, item) => {
      if (item.itemType === InboxItemType.ApprovalTask) {
        ToastService.openToast({
          status: 'warning',
          title: `We couldn't complete some of the tasks`,
          description: 'Approval tasks must be completed one by one.',
        });
      } else {
        arr.push(item);
      }
      return arr;
    }, []);
  };

  function convertInboxItemsToTodos(items) {
    const todos = [];

    items.forEach(item => {
      let id = null;
      if (item.itemType === InboxItemType.Checklist) {
        ({ id } = item.checklist);
      } else if (InboxService.isTask(item.itemType)) {
        ({ id } = item.task);
      } else {
        logger.error('item type: %s is not supported', item.itemType);
      }

      if (id) {
        todos.push({ id, todoType: item.itemType });
      }
    });

    return todos;
  }
});
