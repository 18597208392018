import angular from 'angular';
import { InboxConstants } from '@process-street/subgrade/inbox/inbox-constants';
import { WidgetUtils, WidgetType, TaskStatus, ChecklistStatus } from '@process-street/subgrade/process';
import { ProcessingErrorUtils } from '@process-street/subgrade/core';
import { connectController } from 'reducers/util';
import { TaskSelector } from 'reducers/task/task.selectors';
import { createSelector } from 'reselect';
import { ChecklistWidgetSelector } from 'components/widgets/store/checklist-widget.selector';
import templateUrl from './task-details.component.html';
import './task-details.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { queryClient } from 'components/react-root';
import { GetConsolidatedTaskPermitsQuery } from 'features/checklist-revisions/query-builder';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { FormFieldEvent } from 'services/form-field-event';
import { InboxItemUtils } from '@process-street/subgrade/inbox';

angular.module('frontStreetApp.directives').component('psTaskInboxItemDetails', {
  bindings: {
    mode: '<', // Needed, so we can differentiate if the task shown within the context of a checklist
    user: '<',
    item: '<',
    organization: '<',
    inboxListCtrl: '<',
    isTasksPageModal: '<',
    onLoaded: '&',
    onDueDateUpdate: '&',
    onDueDateRemove: '&',
    onComplete: '&',
  },
  require: {
    inboxItemDetailsCtrl: '^psInboxItemDetails',
    inboxChecklistDetailsCtrl: '^?psChecklistInboxItemDetails',
  },
  templateUrl,
  controller(
    $ngRedux,
    $q,
    $scope,
    $state,
    $window,
    ApprovalActions,
    ChecklistRevisionService,
    ChecklistService,
    FormFieldValueService,
    RequiredFieldEvent,
    SecurityService,
    SessionService,
    TaskActions,
    TaskService,
    TaskTemplateService,
    WidgetActions,
    WidgetService,
    ToastService,
  ) {
    const ctrl = this;

    ctrl.loaded = false;
    ctrl.widgetsMap = {};
    ctrl.formFieldWidgetsMap = {};
    ctrl.visibleFormFieldWidgetsMap = {};
    ctrl.taskWidgets = [];
    ctrl.checklistWidgets = [];
    ctrl.visibleWidgets = [];
    ctrl.readOnly = false;

    ctrl.$onChanges = function (changes) {
      if (changes.item && changes.item.currentValue) {
        ctrl.item = changes.item.currentValue;
        ctrl.invalidFormFieldMap = {};
        ctrl.setIsReadOnly();
        initialize();
      }
    };

    ctrl.$onInit = function () {
      ctrl.inboxItemDetailsCtrl.registerTaskItemDetailsController(ctrl);

      const mapStateToThis = () =>
        createSelector(
          [
            TaskSelector.getTaskMapByChecklistIdGroupedByTaskTemplateGroupId(ctrl.item.checklist.id),
            ChecklistWidgetSelector.getAllByChecklistRevisionId(ctrl.item.task.checklistRevision.id),
            FeatureFlagSelector.getFeatureFlags,
            OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId,
          ],
          (checklistTaskMap, checklistWidgets, featureFlags, currentOrganizationMembership) => {
            ctrl.visibleWidgets = ctrl.getVisibleWidgets(checklistWidgets);
            ctrl.visibleFormFieldWidgetsMap = WidgetUtils.filterVisibleWidgetsMap(
              ctrl.formFieldWidgetsMap,
              checklistWidgets,
            );

            return { checklistTaskMap, checklistWidgets, featureFlags, currentOrganizationMembership };
          },
        );

      const mapDispatchToThis = () => ({
        getAllApprovalsByChecklistRevisionId: ApprovalActions.getAllByChecklistRevisionId,
        getAllTasksByChecklistRevisionId: TaskActions.getAllByChecklistRevisionId,
      });

      connectController($ngRedux, mapStateToThis, mapDispatchToThis, ctrl.shouldChange)(ctrl);
      ctrl.actions.getAllTasksByChecklistRevisionId(ctrl.item.task.checklistRevision.id);

      ctrl.setIsReadOnly();
    };

    ctrl.shouldChange = changes => changes.item && changes.item.currentValue;

    ctrl.permissionMap = {};

    ctrl.currentUserIsFreeMember = function () {
      return (
        ctrl.state.currentOrganizationMembership &&
        ctrl.state.currentOrganizationMembership.role === OrganizationMembershipRole.FreeMember
      );
    };

    function initializePermissions() {
      return $q
        .all({
          checklistUpdate: SecurityService.canUpdateChecklistByChecklist(ctrl.item.checklist),
          templateRead: SecurityService.canReadTemplateByTemplate(ctrl.item.checklist.template),
          taskManage: SecurityService.canManageTaskByChecklist(ctrl.item.checklist),
          templateUpdate: SecurityService.canUpdateTemplateByTemplate(ctrl.item.checklist.template),
        })
        .then(permissionMap => {
          ctrl.permissionMap = permissionMap;
        });
    }

    async function initialize() {
      await $ngRedux.dispatch(
        WidgetActions.getAllChecklistWidgetsByChecklistRevisionId(ctrl.item.task.checklistRevision.id),
      );

      const taskWidgetsRequest = retrieveTaskWidgets();
      const checklistRevisionRequest = retrieveChecklistRevision();
      const taskTemplatesRequest = retrieveTaskTemplates();

      const responses = await $q.all({
        taskWidgets: taskWidgetsRequest,
        checklistRevision: checklistRevisionRequest,
        taskTemplates: taskTemplatesRequest,
      });

      ctrl.checklistRevision = responses.checklistRevision;
      ctrl.taskWidgets = responses.taskWidgets;
      ctrl.taskTemplates = responses.taskTemplates;
      ctrl.visibleWidgets = ctrl.getVisibleWidgets(ctrl.state.checklistWidgets);

      ctrl.actions.getAllApprovalsByChecklistRevisionId(ctrl.checklistRevision.id);
      await loadFormFieldValues(ctrl.checklistRevision);

      initializePermissions();

      ctrl.loaded = true;

      ctrl.validateIfTaskStatusUpdatable({ ignoreRequiredFields: true });

      ctrl.onLoaded();
    }

    ctrl.getVisibleWidgets = checklistWidgets => WidgetUtils.filterVisibleWidgets(ctrl.taskWidgets, checklistWidgets);

    ctrl.onAssigneesUpdate = function () {
      ctrl.inboxListCtrl.onTaskItemAssigneesUpdate(ctrl.item);
    };

    ctrl.onSnooze = () => ctrl.inboxItemDetailsCtrl?.onSnooze();

    ctrl.getIsChecklistTaskAssignerDisabled = function () {
      return !ctrl.permissionMap.taskManage || ctrl.currentUserIsFreeMember() || ctrl.readOnly;
    };

    function retrieveTaskWidgets() {
      return WidgetService.getAllByChecklistRevisionId(ctrl.item.task.checklistRevision.id).then(allWidgets => {
        ctrl.widgetsMap = {};

        const { widgetsMap, formFieldWidgetsMap } = ctrl;

        allWidgets.forEach(widget => {
          if (WidgetService.isEmpty(widget)) {
            widget._hidden = true;
          }

          const stepId = widget.header.taskTemplate.group.id;
          const stepWidgets = widgetsMap[stepId] || [];
          const stepFieldWidgets = formFieldWidgetsMap[stepId] || [];
          stepWidgets.push(widget);
          if (widget.header.type === WidgetType.FormField) {
            stepFieldWidgets.push(widget);
          }

          widgetsMap[stepId] = stepWidgets;
          formFieldWidgetsMap[stepId] = stepFieldWidgets;
        });

        ctrl.visibleFormFieldWidgetsMap = WidgetUtils.filterVisibleWidgetsMap(
          ctrl.formFieldWidgetsMap,
          ctrl.state.checklistWidgets,
        );

        angular.forEach(widgetsMap, WidgetService.sortWidgets);

        return widgetsMap[ctrl.item.task.taskTemplate.group.id] || [];
      });
    }

    function retrieveTaskTemplates() {
      return TaskTemplateService.getAllByTemplateRevisionId(ctrl.item.task.taskTemplate.templateRevision.id);
    }

    function retrieveChecklistRevision() {
      // get active checklist revision
      return ChecklistRevisionService.getActiveByChecklistId(ctrl.item.checklist.id).then(
        revision => revision,
        () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems loading the workflow version`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });

          $state.go('myWork');
        },
      );
    }

    function loadFormFieldValues(checklistRevision) {
      return FormFieldValueService.getAllByChecklistRevisionId(checklistRevision.id).then(
        formFieldValues => {
          ctrl.formFieldValueMap = {};
          FormFieldValueService.initializeFormFieldValueMap(ctrl.formFieldValueMap, formFieldValues, ctrl.widgetsMap);

          if (ctrl.inboxChecklistDetailsCtrl) {
            ctrl.inboxChecklistDetailsCtrl.updateFormFieldValueMap(ctrl.formFieldValueMap);
          }
        },
        () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems loading the form field values`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
      );
    }

    ctrl.shouldShowTaskControls = function () {
      return ctrl.mode === InboxConstants.TaskItemDetailsMode.CHECKLIST_TASK;
    };
    ctrl.shouldShowCoverImage = function () {
      return ctrl.mode !== InboxConstants.TaskItemDetailsMode.CHECKLIST_TASK;
    };

    ctrl.shouldShowStatusToggle = function () {
      return ChecklistService.isChecklistActionable(ctrl.item.checklist) && !ctrl.isHeading();
    };

    ctrl.isHeading = function () {
      return TaskTemplateService.isHeading(ctrl.item.task.taskTemplate);
    };

    ctrl.shouldShowChecklistTaskAssigner = () => ctrl.item && ctrl.item.task && !ctrl.isHeading();

    ctrl.resolveStatusToggleClass = function () {
      const classes = [];

      classes.push(ctrl.item.task.status === 'NotCompleted' ? 'btn-primary' : 'btn-default');
      if (!isTaskCompletable()) {
        classes.push('disabled');
      }

      return classes.join(' ');
    };

    function isTaskCompletable() {
      let completable = false;

      if (ctrl.inboxChecklistDetailsCtrl) {
        completable = !ctrl.inboxChecklistDetailsCtrl.isTaskTemplateDisabled(ctrl.item.task.taskTemplate);
      }

      return completable;
    }

    ctrl.shouldShowNextTaskButton = function () {
      return (
        ChecklistService.isChecklistActionable(ctrl.item.checklist) &&
        canMoveToNextTaskTemplate() &&
        ctrl.item.task.status === TaskStatus.NotCompleted
      );
    };

    ctrl.isCompleted = function () {
      return InboxItemUtils.isCompleted(ctrl.item);
    };

    ctrl.toggleTaskStatus = function () {
      if (ctrl.validateIfTaskStatusUpdatable({ flashErrors: true }) && ctrl.inboxChecklistDetailsCtrl) {
        ctrl.inboxChecklistDetailsCtrl.toggleSelectedChecklistTaskStatus();
      }
    };

    ctrl.validateIfTaskStatusUpdatable = function ({ flashErrors, ignoreRequiredFields }) {
      const newStatus = TaskService.getReversedTaskStatus(ctrl.item.task.status);

      const taskMap = ctrl.state.checklistTaskMap;

      const validationResult = TaskService.validateIfTaskStatusUpdatable(
        ctrl.item.task,
        newStatus,
        ctrl.visibleFormFieldWidgetsMap,
        ctrl.formFieldValueMap,
        taskMap,
        ctrl.taskTemplates,
      );
      const { updatable, errors } = validationResult;

      if (errors && errors.invalidFieldCount) {
        if (flashErrors) {
          const errorMessage = ProcessingErrorUtils.makeRequiredOrFailedMessage({
            requiredCount: errors.invalidFormFields?.length,
            failedCount: errors.failedConstraintsFormFields?.length,
          });

          ToastService.openToast({
            status: 'warning',
            title: `We couldn't complete task`,
            description: errorMessage,
          });
        }

        const allErrors = ignoreRequiredFields
          ? errors.failedConstraintsFormFields
          : concatAllErrors({
              invalidFormFields: errors.invalidFormFields,
              failedConstraintsFormFields: errors.failedConstraintsFormFields,
            });
        updateInvalidFormFieldMap(allErrors);
      } else if (errors && errors.stop && flashErrors) {
        ChecklistService.validateAndComplete({
          checklist: ctrl.checklistRevision.checklist,
          taskTemplates: ctrl.taskTemplates,
          taskMap,
          widgetsMap: ctrl.visibleFormFieldWidgetsMap,
          formFieldValueMap: ctrl.formFieldValueMap,
          complete: false,
        });
      }

      return updatable;
    };

    function concatAllErrors({ invalidFormFields = [], failedConstraintsFormFields = [] }) {
      return invalidFormFields.concat(failedConstraintsFormFields);
    }

    function canMoveToNextTaskTemplate() {
      let canMoveToNext = false;

      if (ctrl.inboxChecklistDetailsCtrl) {
        canMoveToNext = ctrl.inboxChecklistDetailsCtrl.canMoveToNextTaskTemplate(ctrl.item.task.taskTemplate);
      }

      return canMoveToNext;
    }

    ctrl.selectNextTask = function () {
      if (ctrl.inboxChecklistDetailsCtrl) {
        ctrl.inboxChecklistDetailsCtrl.selectNextTaskTemplate();
      }
    };

    ctrl.getDueDate = function () {
      return ctrl.item.task.dueDate;
    };

    ctrl.getHash = function () {
      return `${ctrl.item.itemType}|${ctrl.item.task.id}`;
    };

    ctrl.isApprovalTask = function (task) {
      if (!task) {
        return false;
      }
      return TaskTemplateService.isApproval(task.taskTemplate);
    };

    ctrl.canSnoozeItem = () => ctrl.item && ctrl.mode !== InboxConstants.TaskItemDetailsMode.CHECKLIST_TASK;

    ctrl.onSubjectTaskClick = ({ taskTemplate }) => {
      const href = $state.href(
        'checklist.task',
        {
          id: ctrl.item.checklist.id,
          groupId: taskTemplate.group.id,
        },
        { location: 'replace' },
      );
      $window.open(href, '_blank');
    };

    // show if approval note is present
    ctrl.shouldShowChecklistWidgets = task =>
      ctrl.loaded && (!ctrl.isApprovalTask(task) || ctrl.taskWidgets.length > 0);

    ctrl.setIsReadOnly = () => {
      const params = {
        checklistRevisionId: ctrl.item.task.checklistRevision.id,
      };

      const isChecklistCompleted = ctrl.item.checklist?.status === ChecklistStatus.Completed;
      ctrl.readOnly = isChecklistCompleted;

      return $q((resolve, reject) =>
        queryClient
          .fetchQuery(GetConsolidatedTaskPermitsQuery.getKey(params), () =>
            GetConsolidatedTaskPermitsQuery.queryFn(params),
          )
          .then(tasksPermits => resolve(tasksPermits))
          .catch(e => reject(e)),
      ).then(tasksPermits => {
        const taskPermits = tasksPermits.find(t => t.taskId === ctrl.item.task.id);
        ctrl.readOnly = isChecklistCompleted || !taskPermits?.taskPermissionMap.canUpdate;
      });
    };

    function updateInvalidFormFieldMap(invalidFormFields) {
      invalidFormFields.forEach(formFieldWidget => {
        ctrl.invalidFormFieldMap[formFieldWidget.id] = formFieldWidget;
      });
    }

    function onFormFieldValueUpdate(formFieldValue) {
      const { formFieldWidget } = formFieldValue;
      if (ctrl.formFieldValueMap[formFieldWidget.header.id]) {
        ctrl.formFieldValueMap[formFieldWidget.header.id] = formFieldValue;
      }
      if (ctrl.invalidFormFieldMap[formFieldWidget.id]) {
        const valueIsEmpty = WidgetUtils.isEmpty(formFieldWidget, formFieldValue);
        if (!valueIsEmpty) {
          delete ctrl.invalidFormFieldMap[formFieldWidget.id];
        }
      }
    }

    $scope.$on(RequiredFieldEvent.CHECKLIST_HAS_INVALID_FORM_FIELDS, (__event, data) => {
      if (data && data.invalidFormFields && data.invalidFormFields.length) {
        updateInvalidFormFieldMap(data.invalidFormFields);
      }
    });

    $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_UPDATED, (__event, formFieldValue) => {
      onFormFieldValueUpdate(formFieldValue);
    });

    $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_LIVE_UPDATED, (__event, formFieldValue) => {
      const formFieldWidget = ctrl.visibleWidgets.find(w => w.id === formFieldValue.formFieldWidget.id);
      if (!formFieldWidget) return;

      onFormFieldValueUpdate({ ...formFieldValue, formFieldWidget });
    });

    $scope.$on(EventName.TASK_STATUS_UPDATE_STARTED, (__event, task) => {
      ctrl.item.task.status = task.status;
    });
  },
});
