import * as React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { TemplateType } from '@process-street/subgrade/process';
import { CrossLinkWidget as TCrossLinkWidget } from '@process-street/subgrade/process/widget-model';
import { match, P } from 'ts-pattern';
import { CrossLinkCardDisplay, CrossLinkCardLoading } from './display';
import { CrossLinkCardSearch } from './search';
import { TemplateTypeProvider } from 'utils/template/template-type-context';

export type Props = {
  widget: TCrossLinkWidget;
  editable: boolean;
  onUpdate: (widget: TCrossLinkWidget) => void;
  searchParams: { templateType: TemplateType };
  // This prop is used to support the bridge between angular and react, since the a nested change (widget.templateId) does't trigger a re-render
  isLoading?: boolean;
};

export const CrossLinkWidget: React.FC<React.PropsWithChildren<Props>> = ({
  editable,
  onUpdate,
  widget,
  searchParams,
  isLoading = false,
}) => {
  const [templateId, setTemplateId] = React.useState(widget.templateId);

  React.useEffect(() => {
    setTemplateId(widget.templateId);
  }, [widget.templateId]);

  const onSelect = React.useCallback(
    (id: Muid) => {
      setTemplateId(id);
      onUpdate({ ...widget, templateId: id });
    },
    [onUpdate, widget],
  );

  return (
    <TemplateTypeProvider templateType={searchParams.templateType}>
      {match({ templateId, editable, isLoading })
        .with({ isLoading: true }, () => <CrossLinkCardLoading />)
        .with({ templateId: P.not(undefined) }, ({ templateId }) => <CrossLinkCardDisplay templateId={templateId} />)
        .with({ editable: true }, () => <CrossLinkCardSearch onSelect={onSelect} />)
        .otherwise(() => null)}
    </TemplateTypeProvider>
  );
};
