import { createMachine, InterpreterFrom } from 'xstate';

export type Context = {};
type Typestate = {
  value: 'idle' | 'focused' | 'blurring' | 'selected';
  context: Context;
};
type Event = { type: 'FOCUS' } | { type: 'BLUR' } | { type: 'SELECT' };

export type Status = Typestate['value'];

export const crosslinkSearchMachine = createMachine<Context, Event, Typestate>({
  id: 'crosslink-search',
  initial: 'idle',
  predictableActionArguments: true,
  states: {
    idle: { on: { FOCUS: 'focused' } },
    focused: { on: { BLUR: 'blurring', SELECT: 'selected' } },
    blurring: {
      on: { SELECT: 'selected' },
      // HACK to allow the callback for a selected item to fire before the blur effect closes the listbox
      after: { 300: 'idle' },
    },
    selected: { on: { FOCUS: 'focused' } },
  },
});

export type Send = InterpreterFrom<typeof crosslinkSearchMachine>['send'];
