import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { useUserListQuery } from './query';
import { reducer, ShownUserType } from './state';

export const useController = () => {
  const {
    data: { memberships },
  } = useUserListQuery();

  const [state, dispatch] = React.useReducer(reducer, {
    shownUserType: 'All Users',
    usernameOrEmail: '',
    filteredMemberships: [],
    page: 1,
  });

  const { $timeout } = useInjector('$timeout');

  const onSearchChanged = React.useCallback(
    (value: string) => {
      $timeout(() => {
        dispatch({ type: 'searchChanged', value, memberships });
      });
    },
    [$timeout, memberships],
  );

  const updateSelection = React.useCallback(
    (userType: ShownUserType) => {
      if (userType !== state.shownUserType) {
        dispatch({ type: 'userTypeChanged', userType, memberships });
      }
    },
    [state.shownUserType, memberships],
  );

  const nextPage = React.useCallback(() => {
    dispatch({ type: 'nextPage' });
  }, []);

  const previousPage = React.useCallback(() => {
    dispatch({ type: 'previousPage' });
  }, []);

  const goToPage = React.useCallback((page: number) => {
    dispatch({ type: 'setPage', page });
  }, []);

  React.useEffect(() => {
    if (state.filteredMemberships.length === 0 && !state.usernameOrEmail) {
      dispatch({ type: 'loadMemberships', memberships });
    }
  }, [state.filteredMemberships, memberships, state.usernameOrEmail]);

  const membershipsKey = JSON.stringify(memberships);
  React.useEffect(() => {
    dispatch({ type: 'loadMemberships', memberships });
    // use serialized string key instead of the whole array
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore memberships
  }, [membershipsKey]);

  return React.useMemo(
    () => ({
      ...state,
      onSearchChanged,
      updateSelection,
      previousPage,
      nextPage,
      goToPage,
    }),
    [state, onSearchChanged, updateSelection, previousPage, nextPage, goToPage],
  );
};
