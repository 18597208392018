import * as React from 'react';
import { createMultiOption } from 'components/design/BlvdSelect/components/multi/createMultiOption';
import { OptionProps } from 'react-select';
import { useTaskTemplatesSelectorContext } from './context';
import { Box } from 'components/design/next';
import { OptionType } from './types';
import { CrossLinkWidget, WidgetType } from '@process-street/subgrade/process';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { NodeName } from '../node-name';

export const MultiOption = createMultiOption((props: OptionProps<OptionType, true>) => {
  const templateQuery = useGetTemplateQuery(
    { templateId: (props.data?.ref as CrossLinkWidget)?.templateId ?? '' },
    {
      enabled: props.data?.widgetType === WidgetType.CrossLink,
    },
  );

  const { searchTerm } = useTaskTemplatesSelectorContext();

  const label = templateQuery.data?.name ?? props.label;

  const regex = new RegExp(searchTerm ?? '', 'i');
  const matches = label.match(regex);

  if (!searchTerm || !matches) return <NodeName node={props.data} />;

  const highlightedLabel = matches.reduce<React.ReactNodeArray>((acc, match, index, array) => {
    const lastMatch = array[index - 1];
    const lastMatchIndex = lastMatch && props.label.indexOf(array[index - 1]);
    const startIndex = lastMatchIndex ? lastMatchIndex + lastMatch.length : 0;
    const matchIndex = props.label.indexOf(match, startIndex);
    const before = props.label.slice(startIndex, matchIndex);
    const after = index === array.length - 1 ? props.label.slice(matchIndex + match.length, props.label.length) : null;

    return [
      ...acc,
      before,
      <Box as="span" position="relative" fontWeight="bold" bgColor="brand.200">
        {match}
      </Box>,
      after,
    ];
  }, []);

  return (
    <>
      {highlightedLabel.map((item, i) => (
        <React.Fragment key={i}>{item}</React.Fragment>
      ))}
    </>
  );
});
