import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';

/**
 * We use our own boolean context for this because `slate-react`'s `useReadOnly` cannot be mocked or injected
 * unless you render an entire Slate editing context.
 **/
export const [useIsEditable, IsEditableContext] = createUsableContext<boolean>({
  hookName: 'useIsEditable',
  providerName: 'IsEditableContext.Provider',
});

export const IsEditableProvider: React.FC<React.PropsWithChildren<{ isEditable: boolean }>> = ({
  children,
  isEditable,
}) => <IsEditableContext.Provider value={isEditable}>{children}</IsEditableContext.Provider>;
