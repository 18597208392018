import { User, UserDetails } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from 'react-query';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { axiosService } from 'services/axios-service';
import { HttpStatus } from '@process-street/subgrade/util';

type CreateUserDetailsParams = Pick<UserDetails, 'userId' | 'department' | 'useCase' | 'referral' | 'hasScheduledDemo'>;

type CreateUserDetailsResult = void;

export const CreateUserDetails = {
  key: ['user', 'details', 'update'],
  mutationFn: ({ userId, ...body }: CreateUserDetailsParams) =>
    axiosService
      .getAxios()
      .post<CreateUserDetailsResult>(`/1/users/${userId}/details`, { hasScheduledDemo: false, ...body })
      .then(r => r.data),
};

export const useCreateUserDetailsMutation = (
  options: UseMutationOptions<CreateUserDetailsResult, AxiosError, CreateUserDetailsParams> = {},
) => {
  return useMutation<CreateUserDetailsResult, AxiosError, CreateUserDetailsParams>(
    CreateUserDetails.mutationFn,
    options,
  );
};

type UpdateUserDetailsParams = Pick<UserDetails, 'userId' | 'department' | 'useCase' | 'referral' | 'hasScheduledDemo'>;

type UpdateUserDetailsResult = void;

export const UpdateUserDetails = {
  key: ['user', 'details', 'update'],
  mutationFn: ({ userId, ...body }: UpdateUserDetailsParams) =>
    axiosService
      .getAxios()
      .put<UpdateUserDetailsResult>(`/1/users/${userId}/details`, body)
      .then(r => r.data),
};

export const useUpdateUserDetailsMutation = (
  options: UseMutationOptions<UpdateUserDetailsResult, AxiosError, CreateUserDetailsParams> = {},
) => {
  return useMutation<UpdateUserDetailsResult, AxiosError, CreateUserDetailsParams>(
    UpdateUserDetails.mutationFn,
    options,
  );
};

export const GetUserDetails = {
  key: ['user', 'details'],
  getKey: (id: User['id']) => [...GetUserDetails.key, id],
  queryFn: (userId: User['id']) =>
    axiosService
      .getAxios()
      .get<UserDetails>(`/1/users/${userId}/details`, {
        validateStatus: status => {
          return status === HttpStatus.OK || status === HttpStatus.NOT_FOUND;
        },
      })
      .then(res => {
        if (res.status === HttpStatus.NOT_FOUND) {
          return undefined;
        } else {
          return res.data;
        }
      }),
};

export const useGetUserDetailsQuery = (
  id: User['id'],
  options?: UseQueryOptions<UserDetails | undefined, AxiosError>,
) => {
  return useQuery<UserDetails | undefined, AxiosError>(GetUserDetails.getKey(id), () => GetUserDetails.queryFn(id), {
    refetchOnWindowFocus: false,
    ...options,
  });
};

/*
 * useUpsertUserDetailsMutation
 *
 * This hook returns a react-query mutation based on the existence of the user details. If the user
 * details already exists, it will return a mutation to update the current user details, otherwise
 * it will return a mutation to create the user details.
 *
 * When we call the `POST /1/users/${userId}/details` and a user details already exists, it will
 * override the existing one, and will "nullify" all the fields that haven't been provided during
 * the creation.
 */
export const useUpsertUserDetailsMutation = (
  options: UseMutationOptions<
    CreateUserDetailsResult | UpdateUserDetailsResult,
    AxiosError,
    CreateUserDetailsParams | UpdateUserDetailsParams
  >,
) => {
  const sessionUserId = useSelector(SessionSelector.getCurrentUserId);
  const userDetailsQuery = useGetUserDetailsQuery(sessionUserId ?? '', { enabled: Boolean(sessionUserId) });
  const updateUserDetailsMutation = useUpdateUserDetailsMutation(options);
  const createUserDetailsMutation = useCreateUserDetailsMutation(options);

  if (userDetailsQuery.data) {
    return {
      ...updateUserDetailsMutation,
      mutate: (variables, options) =>
        updateUserDetailsMutation.mutate(
          {
            ...userDetailsQuery.data,
            ...variables,
            hasScheduledDemo: (userDetailsQuery.data?.hasScheduledDemo || variables.hasScheduledDemo) ?? false,
          },
          options,
        ),
    } as typeof updateUserDetailsMutation;
  }

  return createUserDetailsMutation;
};
