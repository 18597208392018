import * as React from 'react';
import { Text, Link, VStack, HStack, Center, Divider } from 'components/design/next';
import { TaskInboxItem } from '@process-street/subgrade/inbox';
import { useInjector } from 'components/injection-provider';
import { InboxCoverIcon } from 'features/cover-icon/components/inbox';
import { isNotIdRef, toModelRef } from '@process-street/subgrade/core';
import { useGetChecklistQuery } from 'app/features/checklists/query-builder';
import { useListenToWorkflowRunUpdated } from 'app/pages/inbox/hooks/use-listen-to-checklist-run-update';

type TaskHeaderProps = {
  item: TaskInboxItem;
  resolveItemTitle: () => string;
  resolveTaskItemSubtitle: () => string;
};

export const TaskHeader = ({ item, resolveItemTitle, resolveTaskItemSubtitle }: TaskHeaderProps) => {
  const { $state } = useInjector('$state');
  const title = resolveItemTitle();
  const templateName = isNotIdRef(item.template) ? item.template.name : '';

  const groupId = item && isNotIdRef(item.task.taskTemplate) ? item.task.taskTemplate.group.id : '';

  const { data: subtitle } = useGetChecklistQuery(
    {
      checklistId: item.checklist.id,
    },
    {
      select: checklist => {
        return checklist.name ?? resolveTaskItemSubtitle();
      },
      initialData: toModelRef(item.checklist),
    },
  );

  const taskHref = $state.href('checklist.task', { id: item.checklist.id, groupId });
  const checklistHref = $state.href('checklist', { id: item.checklist.id });
  const dashboardHref = $state.href('templateDashboard', { id: item.template.id });

  useListenToWorkflowRunUpdated(item.checklist.id, { shouldUpdateInboxQuery: true });

  return (
    <HStack spacing="4">
      <InboxCoverIcon templateId={item.template.id} />
      <VStack align="left" minW="0" width="full" flex="1" spacing="0">
        {item.masked ? (
          <Text
            aria-label="task title"
            color="gray.700"
            fontWeight="bold"
            fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
            noOfLines={1}
          >
            {title}
          </Text>
        ) : (
          <Link
            aria-label="task title"
            href={taskHref}
            variant="noline"
            color="gray.700"
            fontWeight="bold"
            fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
            noOfLines={1}
          >
            {title}
          </Link>
        )}

        <HStack minW="0" flex="1">
          <Link
            href={checklistHref}
            aria-label="workflow run title"
            variant="noline"
            color="gray.500"
            fontSize="sm"
            maxWidth="45%"
            noOfLines={1}
          >
            {subtitle}
          </Link>
          <Center height="3">
            <Divider borderColor="gray.500" borderLeftWidth="0.5" orientation="vertical" />
          </Center>
          <Link
            aria-label="workflow title"
            href={dashboardHref}
            variant="noline"
            color="gray.500"
            fontSize="sm"
            maxWidth="45%"
            noOfLines={1}
          >
            {templateName}
          </Link>
        </HStack>
      </VStack>
    </HStack>
  );
};
