import * as React from 'react';
import {
  Box,
  Button,
  HStack,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from 'components/design/next';
import { Avatar } from 'components/common/Avatar';
import { useInjector } from 'components/injection-provider';
import { createSentenceMachine } from './sentence-machine';
import { DateUtils } from '@process-street/subgrade/util';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { S3File, User } from '@process-street/subgrade/core';
import { useMachine } from '@xstate/react';
import { useQueryClient } from 'react-query';
import { InterpretedActivity, InterpretedActivityObject } from 'services/activities/interpreters/InterpreterContext';
import { TemplateType } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';

type ActivitySentenceProps = {
  sentence: InterpretedActivity;
};

export const ActivitySentence: React.FC<React.PropsWithChildren<ActivitySentenceProps>> = ({ sentence }) => {
  const currentUser = useSelector(SessionSelector.getCurrentUser);
  const { $state, $rootScope } = useInjector('$state', '$rootScope');
  const queryClient = useQueryClient();
  const plan = useSelector(SessionSelector.getCurrentPlan);
  const machine = React.useRef(
    createSentenceMachine({
      sentence,
      queryClient,
      $rootScope,
      $state,
      plan,
    }),
  ).current;

  const [state, send] = useMachine(machine);

  const username = (sentence.subject as User).userType === 'Anonymous' ? 'Guest (Anonymous)' : sentence.subject.value;

  const redirectObject =
    ({ type, id }: InterpretedActivityObject) =>
    (e: React.MouseEvent) => {
      const target = e.target as Element;

      if (type && id && target.tagName.toLowerCase() === 'a') {
        $state.go('objectRedirect', { type, id });
      }
    };

  const { data } = sentence.activity;

  return (
    <HStack
      w="full"
      spacing="3"
      borderBottomWidth="thin"
      borderBottomColor="gray.200"
      borderBottomStyle="solid"
      py="3"
      _last={{
        borderBottom: 'none',
      }}
      px="5"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Avatar user={sentence.subject as User} />

      <Box w="full">
        <Box>
          <Link
            href={$state.href('userManage', { id: sentence.subject.id, tab: 'activity' })}
            fontWeight="bold"
            colorScheme="brand"
            color="brand.500"
            lineHeight="shorter"
          >
            {username}
          </Link>{' '}
          <Text as="span" lineHeight="shorter">
            {sentence.verb.value}
          </Text>{' '}
          {sentence.objects.map((o, index, array) => {
            const isLast = array.length - 1 === index;

            return (
              <Box as="span" key={`${o.id}:${o.value}`}>
                <Tooltip label={o.tooltip} openDelay={500} placement="top">
                  <Box
                    as="span"
                    dangerouslySetInnerHTML={{ __html: o.value }}
                    lineHeight="shorter"
                    onClick={redirectObject(o)}
                  />
                </Tooltip>

                {isLast ? '.' : ' '}
              </Box>
            );
          })}
        </Box>

        {sentence.quote && (
          <Text color="gray.800" m="2.5" mb="3" fontSize="sm" dangerouslySetInnerHTML={{ __html: sentence.quote }} />
        )}

        <Box color="gray.400" mt="1px">
          {/* @ts-expect-error -- TODO */}
          {match(data?.templateType)
            .with(TemplateType.Task, () => null)
            .otherwise(() =>
              sentence.contextObjects.map((o, index, array) => {
                const isLast = array.length - 1 === index;
                return (
                  <Box key={`context-object:${o.id}`} as="span">
                    <Tooltip label={o.tooltip} openDelay={500} placement="top">
                      <Text
                        color="gray.500"
                        as="span"
                        variant="-2"
                        dangerouslySetInnerHTML={{ __html: o.value }}
                        sx={{
                          a: {
                            color: 'gray.500',
                          },
                        }}
                      />
                    </Tooltip>

                    {isLast ? (
                      <Text as="span" px="1">
                        &middot;
                      </Text>
                    ) : (
                      ' '
                    )}
                  </Box>
                );
              }),
            )}

          <Text as="span" variant="-2">
            {DateUtils.formatActivityTime({
              date: sentence.date,
              timeZone: currentUser?.timeZone,
            })}
          </Text>
        </Box>

        {sentence.file?.mimeType?.startsWith('image/') && <SentenceImage file={sentence.file} />}
      </Box>

      {(state.matches('undeletable') || state.matches('undeleting')) && (
        <Button
          variant="link"
          colorScheme="gray"
          fontWeight="normal"
          textDecoration="underline"
          size="sm"
          onClick={() => send({ type: 'UNDELETE' })}
          isLoading={state.matches('undeleting')}
          isDisabled={state.matches('undeleting')}
          loadingText="Restoring..."
        >
          Restore
        </Button>
      )}
    </HStack>
  );
};

type ImageModalProps = {
  file: S3File;
};

const SentenceImage: React.FC<React.PropsWithChildren<ImageModalProps>> = ({ file }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Image
        onClick={onOpen}
        src={file.url}
        alt={file.originalName ?? file.name}
        title={file.originalName ?? file.name}
        maxH="75"
        rounded="md"
        mt="2"
        mx="auto"
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="fit-content">
          <ModalCloseButton top="-8" right="-2" color="white" />

          <ModalBody p="0">
            <Image
              src={file.url}
              alt={file.originalName ?? file.name}
              title={file.originalName ?? file.name}
              maxH="90vh"
              rounded="md"
              mx="auto"
              h="auto"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
