import { CommentInboxItem } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetInboxCommentsQueryParams = {};
export type GetInboxCommentsQueryResponse = CommentInboxItem[];

export const GetInboxCommentsQuery = {
  key: ['comments', 'inbox'],
  getKey: (params: GetInboxCommentsQueryParams): QueryKey => [...GetInboxCommentsQuery.key, params],
  queryFn: (_params: GetInboxCommentsQueryParams) =>
    axiosService
      .getAxios()
      .get<GetInboxCommentsQueryResponse>(`/1/comment-inbox/comments`)
      .then(res => res.data),
};

export const useGetInboxCommentsQuery = <Select = GetInboxCommentsQueryResponse>(
  params: GetInboxCommentsQueryParams,
  options: UseQueryOptions<GetInboxCommentsQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetInboxCommentsQuery.getKey(params), () => GetInboxCommentsQuery.queryFn(params), {
    ...options,
  });
};
