import * as React from 'react';
import { Text, VStack } from 'components/design/next';
import { ActorRefFrom } from 'xstate';
import { TaskMachine } from 'pages/responses/_id/components/task/task-machine';
import { FormResponseActor } from 'pages/responses/_id/components/form-response-body/form-response-machine';
import { useSelector } from '@xstate/react';
import { FormResponseMachineChecklistSelectors } from 'pages/responses/_id/components/form-response-body/form-response-machine-selectors';
import { FormResponsePageMachineHooks } from 'pages/responses/_id/form-response-page-hooks';
import { ApprovalUtils } from '@process-street/subgrade/approval-rule';
import { ApprovalButtons } from 'pages/responses/_id/components/approval-task/approval-buttons';

export type ApprovalTaskProps = {
  taskMachine: ActorRefFrom<TaskMachine>;
  formResponseActor: FormResponseActor;
};

export const ApprovalTask: React.FC<ApprovalTaskProps> = ({ taskMachine, formResponseActor }) => {
  const approvals = useSelector(formResponseActor, FormResponseMachineChecklistSelectors.getApprovals);
  const tasks = useSelector(formResponseActor, FormResponseMachineChecklistSelectors.getTasks);
  const approvalTask = taskMachine.getSnapshot()?.context.task;

  const subjectGroupIdToApprovalGroupIdMap = FormResponsePageMachineHooks.useSubjectGroupIdToApprovalGroupIdMap();

  const subjectTasks = React.useMemo(
    () =>
      approvalTask
        ? tasks.filter(
            task =>
              subjectGroupIdToApprovalGroupIdMap.get(task.taskTemplate.group.id) === approvalTask.taskTemplate.group.id,
          )
        : [],
    [approvalTask, tasks, subjectGroupIdToApprovalGroupIdMap],
  );

  const subjectTaskIdToApprovalMap = React.useMemo(
    () =>
      Object.fromEntries(
        approvals
          .filter(approval => approval.approvalTaskId === approvalTask?.id)
          .map(approval => [approval.subjectTaskId, approval]),
      ),
    [approvals, approvalTask],
  );

  const taskGroupsMap = React.useMemo(
    () => ApprovalUtils.groupTaskByApprovalStatus(subjectTasks, subjectTaskIdToApprovalMap),
    [subjectTasks, subjectTaskIdToApprovalMap],
  );

  if (!approvalTask) return;

  return (
    <VStack width="full" gap={6}>
      {taskGroupsMap.awaitingTasks.length > 0 && (
        <VStack alignItems="stretch" gap={4} width="full">
          <Text variant="1u" color="gray.400" fontWeight="bold">
            Waiting for approval
          </Text>

          {taskGroupsMap.awaitingTasks.map(task => (
            <VStack key={task.id} alignItems="flex-start" backgroundColor="gray.50" padding={4}>
              <Text>{task.taskTemplate.name}</Text>
              <ApprovalButtons subjectTask={task} approvalTaskId={approvalTask.id} />
            </VStack>
          ))}
        </VStack>
      )}
      {taskGroupsMap.notSubmittedTasks.length > 0 && (
        <VStack alignItems="stretch" gap={4} width="full">
          <Text variant="1u" color="gray.400" fontWeight="bold">
            Waiting for submission
          </Text>

          {taskGroupsMap.notSubmittedTasks.map(task => (
            <VStack key={task.id} alignItems="flex-start" backgroundColor="gray.50" padding={4}>
              <Text>{task.taskTemplate.name}</Text>
              <Text fontStyle="italic" color="gray.500">
                Will be submitted
              </Text>
            </VStack>
          ))}
        </VStack>
      )}
      {taskGroupsMap.rejectedTasks.length > 0 && (
        <VStack alignItems="stretch" gap={4} width="full">
          <Text
            alignSelf="flex-start"
            padding={1}
            variant="1u"
            color="red.500"
            backgroundColor="red.50"
            fontWeight="bold"
          >
            Rejected
          </Text>

          {taskGroupsMap.rejectedTasks.map(task => (
            <VStack
              key={task.id}
              alignItems="flex-start"
              backgroundColor="gray.50"
              padding={4}
              borderLeft="solid 2px"
              borderColor="red.500"
            >
              <Text>{task.taskTemplate.name}</Text>
            </VStack>
          ))}
        </VStack>
      )}
      {taskGroupsMap.approvedTasks.length > 0 && (
        <VStack alignItems="stretch" gap={4} width="full">
          <Text
            alignSelf="flex-start"
            padding={1}
            variant="1u"
            color="green.500"
            backgroundColor="green.50"
            fontWeight="bold"
          >
            Approved
          </Text>

          {taskGroupsMap.approvedTasks.map(task => (
            <VStack
              key={task.id}
              alignItems="flex-start"
              backgroundColor="gray.50"
              padding={4}
              borderLeft="solid 2px"
              borderColor="green.500"
            >
              <Text>{task.taskTemplate.name}</Text>
            </VStack>
          ))}
        </VStack>
      )}
    </VStack>
  );
};
