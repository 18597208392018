import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule/approval-rule-subject.model';
import { Muid } from '@process-street/subgrade/core';
import { ReduxAppState } from 'reducers/types';
import { createCachedAction } from 'reducers/util';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

// Rules
export const APPROVAL_RULE_GET_ALL_BY_TEMPLATE_REVISION = 'frontStreet/approvalRules/GET_ALL_BY_TEMPLATE_REVISION';
export const APPROVAL_RULE_CREATE_ALL = 'frontStreet/approvalRules/CREATE_ALL';
export const APPROVAL_RULE_DELETE_ALL = 'frontStreet/approvalRules/DELETE_ALL';

export interface ApprovalRulesActions {
  createAll(
    templateRevisionId: Muid,
    taskTemplateGroupId: Muid,
    rules: ApprovalRuleSubject[],
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;
  deleteAll(
    templateRevisionId: Muid,
    ruleIds: Muid[],
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;
  getAllByTemplateRevisionId(templateRevisionId: Muid): Promise<void>;
}

// TODO
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error -- TODO
export const ApprovalRulesActionsImpl = ApprovalRulesApi => {
  'ngInject';

  const getAllByTemplateRevisionId = createCachedAction(
    APPROVAL_RULE_GET_ALL_BY_TEMPLATE_REVISION,
    ApprovalRulesApi.getAllByTemplateRevisionId,
    (templateRevisionId: Muid) => ({ templateRevisionId }),
  );

  const createAll = createCachedAction(
    APPROVAL_RULE_CREATE_ALL,
    ApprovalRulesApi.createAll,
    (templateRevisionId: Muid, taskTemplateGroupId: Muid, rules: ApprovalRuleSubject[]) => ({
      rules,
      taskTemplateGroupId,
      templateRevisionId,
    }),
  );

  const deleteAll = createCachedAction(
    APPROVAL_RULE_DELETE_ALL,
    ApprovalRulesApi.deleteAll,
    (templateRevisionId: Muid, ids: Muid[]) => ({
      ids,
      templateRevisionId,
    }),
  );

  return {
    createAll,
    deleteAll,
    getAllByTemplateRevisionId,
  };
};
