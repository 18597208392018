import { Muid, MuidUtils } from '@process-street/subgrade/core';
import {
  ChecklistColumn,
  Clause,
  ColumnDto,
  ColumnType,
  ConditionalFilterUtils,
  Filter,
  FilterType,
  ProgressStatus,
} from '@process-street/subgrade/dashboard';
import { generateAudit } from '@process-street/subgrade/test';
import { checklistColumns, filterableColumns } from 'components/dashboard/models/columns';
import { TemplateDto } from 'components/dashboard/models/filters';
import { OverdueTaskDto, RowValues, UserValue } from 'components/dashboard/models/grid';
import { SavedView, SavedViewAccessLevel, SavedViewFolder } from 'components/dashboard/models/saved-views';
import { mapWithIndex } from 'test/test-utils';

export const generateAssignee = (index: number): UserValue => ({
  id: MuidUtils.randomMuid(),
  organizationMembershipId: MuidUtils.randomMuid(),
  initials: `BW ${index}`,
});

export const generateAssignees = (limit: number): UserValue[] => mapWithIndex(limit, index => generateAssignee(index));

const statuses = [ProgressStatus.Completed, ProgressStatus.DueSoon, ProgressStatus.OnTrack, ProgressStatus.Overdue];

const usernames = ['AJ', 'Alex', 'Anton', 'Cameron', 'Jorge', 'Madison', 'Marcin', 'Robin', 'Zharkyn'];

export const generateRowData = (templates: TemplateDto[], limit: number): RowValues[] => {
  const assignees = generateAssignees(110);

  return mapWithIndex(limit, i => {
    const index = i + 1;
    const status = statuses[i % 4];
    const completedTasks = status === 'Completed' ? 12 : i % 13;

    const template = templates[i % templates.length];
    const checklistName = {
      checklistId: MuidUtils.randomMuid(),
      checklistName: `${usernames[index % usernames.length]}'s ${template.name.substr(0, 10)} ${index}`,
    };
    const templateName = {
      templateId: template.id,
      templateName: template.name,
    };

    const progress = {
      completedTasks,
      status,
      totalTasks: 12,
    };

    const overdue = (i % 5) * 30;

    return {
      checklistRevisionId: MuidUtils.randomMuid(),
      id: checklistName.checklistId,
      [ChecklistColumn.LastActiveDate]: Date.now() - index * 999999,
      [ChecklistColumn.Assignees]: assignees.slice(0, i % 10),
      [ChecklistColumn.ChecklistCompletedDate]: new Date(2020, (index % 12) + 1, (index % 28) + 1),
      [ChecklistColumn.ChecklistDueDate]: new Date(2020, (index % 12) + 1, (index % 28) + 1),
      [ChecklistColumn.ChecklistName]: checklistName,
      [ChecklistColumn.TemplateName]: templateName,
      [ChecklistColumn.OverdueTasksCount]: {
        checklistId: checklistName.checklistId,
        count: overdue,
      },
      [ChecklistColumn.CompletedTasksCount]: progress,
      [ChecklistColumn.ChecklistCreateDate]: new Date(2020, (index % 12) + 1, (index % 28) + 1),
      [ChecklistColumn.ProgressStatus]: status,
      [ChecklistColumn.TemplateVersion]: i % 10,
    };
  });
};

const templateNames = [
  'How to migrate an EC2 Classic RDS instance to VPC',
  'How to update the logo in the Chrome Web Store and Google Apps Marketplace',
  'Employee Onboarding for Virtual Assistants (to be completed by the VA)',
  'How to generate a pgBadger (Docker) report for PostgreSQL log',
  'Quarter End Promo Process',
  'Blog Post Creation Checklist',
  'Privacy Breach Disciplinary Process',
  'Access Control Policy',
  'Fortnightly Dependency Check',
  'Sprint Retrospective',
  'Sales to CS Hand Off Procedures',
  'Kashish Weekly Tasks',
  '1. Issues',
  'Deployment',
  'Blog Post Promotion',
  'Interview: Content Writer/Editor',
  'Project Roadmap (Copy)',
  'Inbox',
  'Payroll Checklist',
  'Integration Article Promotion',
  'Email Autoresponder Series - New Signups',
  'Ben Weekly Tasks',
  'Daily Status Update',
  'Paying User Questionnaire',
  'Aging HVT Followup Call',
  'Client Onboarding Form (Copy)',
  'Super Critical Issue Procedure',
  'Blank Template',
  'Physical Mail Processing',
  'Monthly Manager 1 on 1s',
  'Blog Post Production (Copy)',
  'Screencast Recording Checklist',
  'Blank Template',
  'Website Launch Checklist',
  'HVT Sales Qualification / Demo Call V2',
  'Test Emails',
  'Support Squad Debrief',
  'Sales Onboarding v2',
  'HVT Sales Qualification / Demo Call V3',
  'Project Roadmap',
  'Setup close2warehouse',
  'z (Example) Divorce Process',
  'Customer Support Checklist',
  'PS Contact Formatting',
  'HVT Sales Qualification / Demo Call V3.1',
  'How to Setup Zapier CLI',
  'VA Weekly Meeting Summary',
  'Incident Response',
  'Product Meeting',
  'Customer Analysis',
  'Building Technical Design Document',
  'Glassdoor Reference Check',
  'Main Content Creation Process',
  'Customer Success Employee Onboarding',
  'Call Scheduling',
  'List generation- Outbound Campaigns',
  'Content Creation Checklist',
  'Client Onboarding Checklist for an Accounting Firm (Copy)',
  'Blank Template',
  'Microsite Development',
  'Qualification Process for Profitect',
  'Blog Style Guide',
];

export const generateTemplates = (count: number): TemplateDto[] =>
  mapWithIndex(count, index => ({
    id: MuidUtils.randomMuid(),
    name: templateNames[index % templateNames.length],
    folderId: MuidUtils.randomMuid(),
  }));

export const generateSavedView = (savedView?: Partial<SavedView>): SavedView => ({
  accessLevel: SavedViewAccessLevel.Private,
  audit: generateAudit(),
  columnsConfig: {},
  filters: {
    searchPattern: '',
    selectedTemplates: [],
  },
  id: MuidUtils.randomMuid(),
  name: 'my saved view',
  organizationId: MuidUtils.randomMuid(),
  predefined: false,
  ...savedView,
});

export const generateSavedViewFolder = (savedViewFolder?: Partial<SavedViewFolder>) => ({
  audit: generateAudit(),
  id: MuidUtils.randomMuid(),
  name: 'Folder 1',
  organizationId: MuidUtils.randomMuid(),
  ...savedViewFolder,
});

export const generateColumns = (tasksCount: number, formFieldsCount: number): ColumnDto[] => {
  const predefined = checklistColumns.map(col => ({
    columnType: ColumnType.Checklist,
    field: col.field as string,
    name: col.headerName as string,
  }));

  const tasks = mapWithIndex(tasksCount, index => ({
    columnType: ColumnType.Task,
    field: `task_${index}`,
    groupId: MuidUtils.randomMuid(),
    name: `Task ${index + 1}`,
  }));

  const formFields = mapWithIndex(formFieldsCount, index => ({
    columnType: ColumnType.FormField,
    field: `formFields.formField_${index}`,
    groupId: MuidUtils.randomMuid(),
    name: `Form Field ${index + 1}`,
  }));

  return predefined.concat(tasks).concat(formFields);
};

export const generateConditionalFilter = (count: number, parentId: Muid = MuidUtils.randomMuid()): Filter => {
  const children = mapWithIndex(count, index => {
    const column = filterableColumns[index % filterableColumns.length];
    const { condition, operand } = ConditionalFilterUtils.getClauseConditionAndOperand({ column: column.field });

    const filter: Clause = {
      columnName: column.field,
      condition,
      filterType: FilterType.Clause,
      id: MuidUtils.randomMuid(),
      operand,
      parentId,
    };

    return filter;
  });

  return {
    children,
    filterType: FilterType.And,
    id: parentId,
  };
};

export const generateOverdueTasks = (count: number): OverdueTaskDto[] =>
  mapWithIndex(count, index => {
    const assignee = generateAssignee(index);
    return {
      dueDate: Date.now() - index * 100000000,
      taskId: MuidUtils.randomMuid(),
      taskName: `Really Long Long Long Task Name Goes Here ${index}`,
      taskTemplateGroupId: MuidUtils.randomMuid(),
      user: {
        avatarUrl: assignee.avatarUrl,
        id: assignee.id!,
        organizationMembershipId: assignee.organizationMembershipId!,
      },
      masked: false,
    };
  });
