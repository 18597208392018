import React, { useImperativeHandle, useRef, useState } from 'react';
import { DateRange, DayPickerRangeProps } from 'react-day-picker';
import { InputProps, useDisclosure } from 'components/design/next';
import 'react-day-picker/dist/style.css';
import { DateFormat, dayjs } from '@process-street/subgrade/util';
import { DateContext, DateContextUtils } from '@process-street/subgrade/core/date-context';

const DATE_FORMAT = DateFormat.DatePickerInputDefault;
const TIME_FORMAT = DateFormat.DatePickerTimeDefault;
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

const DEFAULT_TIME = '08:00';

export type TimeStatus = 'hidden' | 'visible' | 'disabled';

export interface UseBlvdDatePickerProps {
  disclosure?: ReturnType<typeof useDisclosure>;
  value: Date | undefined;
  onSave?: (date: Date | undefined, timeStatus: TimeStatus) => void;
  onDayChange?: (date?: Date) => void;
  onTimeStatusChange?: (timeStatus: TimeStatus) => void;
  dayPickerProps?: Partial<DayPickerRangeProps>;
  rangeFrom?: Date;
  rangeTo?: Date;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isRequired?: boolean;
  timeStatus?: TimeStatus;
  dateContext?: DateContext;
  ref: React.ForwardedRef<FocusableDatePickerInput>;
}

interface UseBlvdDatePicker {
  dateStringInputProps: InputProps;
  dayPickerProps: DayPickerRangeProps;
  disclosure: ReturnType<typeof useDisclosure>;
  handleSave: () => void;
  onTimeStatusChange: (timeStatus: TimeStatus) => void;
  saveBtnRef: React.RefObject<HTMLButtonElement>;
  timeStatus: TimeStatus;
  timeStringInputProps: InputProps;
}

export interface FocusableDatePickerInput {
  focus(): void;
}

export const useBlvdDatePicker = ({
  value,
  onSave,
  dayPickerProps: dayPickerPropsProp,
  onDayChange: onDayChangeProp,
  rangeFrom,
  rangeTo,
  onTimeStatusChange: onTimeStatusChangeProp,
  timeStatus: timeStatusProp = 'disabled',
  isDisabled,
  dateContext = {},
  ref,
  disclosure: disclosureProp,
}: UseBlvdDatePickerProps): UseBlvdDatePicker => {
  const disclosure = useDisclosure(disclosureProp);

  const [errorType, setErrorType] = React.useState<'time' | 'date' | undefined>(undefined);

  const [timeStatus, setTimeStatus] = React.useState(timeStatusProp);
  const isTimeHidden = timeStatus === 'hidden';

  const timeZone =
    isTimeHidden || !dateContext.userTimeZone
      ? DateContextUtils.getOrganizationTimeZone(dateContext)
      : dateContext.userTimeZone;

  const [dateString, setDateString] = useState(() => {
    return value ? dayjs(value).format(DATE_FORMAT) : '';
  });
  // Keep a stateful version for the datepicker months and range while the input is edited and in an invalid state
  const [lastValidDateString, setLastValidDateString] = useState(dateString);

  const [timeString, setTimeString] = React.useState(() => {
    return value ? dayjs(value).format(TIME_FORMAT) : DEFAULT_TIME;
  });

  useImperativeHandle(ref, () => ({
    focus: () => {
      disclosure.onOpen();
    },
  }));

  const setDateAndTime = React.useCallback(
    ({ datePart, timePart, shouldSave = false }: { datePart: string; timePart: string; shouldSave?: boolean }) => {
      if (datePart !== '' && !dayjs(datePart, DATE_FORMAT).isValid()) {
        setErrorType('date');
        setDateString('');
        setTimeString(DEFAULT_TIME);
        return { status: 'error' };
      }

      if (!dayjs(timePart, TIME_FORMAT).isValid()) {
        setErrorType('time');
        return { status: 'error' };
      }

      if (datePart === '') {
        if (shouldSave) {
          onSave?.(undefined, 'hidden');
        } else {
          onDayChangeProp?.(undefined);
        }
        return { status: 'success' };
      }

      const dateStr = `${datePart} ${timePart}`;
      setErrorType(undefined);
      setTimeString(timePart);
      setDateString(datePart);
      setLastValidDateString(datePart);
      const dateTimeMoment = dayjs.tz(dateStr, DATE_TIME_FORMAT, timeZone);
      setMonth(dateTimeMoment.toDate());

      if (shouldSave) {
        onSave?.(dateTimeMoment.toDate(), timeStatus);
      } else {
        onDayChangeProp?.(dateTimeMoment.toDate());
      }
      return { status: 'success' };
    },
    [onDayChangeProp, onSave, timeStatus, timeZone],
  );

  const onTimeStatusChange = React.useCallback(
    (status: TimeStatus) => {
      setTimeStatus(status);
      !onSave && onTimeStatusChangeProp?.(status);
    },
    [onSave, onTimeStatusChangeProp],
  );

  const onTimeChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    e => {
      const newTime = e.target.value;
      setDateAndTime({ datePart: dateString, timePart: newTime });
    },
    [dateString, setDateAndTime],
  );

  const handleDateStringChange: React.FocusEventHandler<HTMLInputElement> = React.useCallback(e => {
    const eventValue = e.currentTarget.value;
    setDateString(eventValue);
  }, []);

  const handleDateStringBlur = React.useCallback(() => {
    return setDateAndTime({ datePart: dateString, timePart: timeString });
  }, [dateString, setDateAndTime, timeString]);

  const saveBtnRef = useRef<HTMLButtonElement>(null);
  const handleDateStringKeyPress: React.KeyboardEventHandler<HTMLInputElement> = React.useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (saveBtnRef.current) {
          saveBtnRef.current.focus();
          return;
        }
        const { status } = handleDateStringBlur();
        if (status === 'success') {
          disclosure.onClose();
        }
      }
    },
    [disclosure, handleDateStringBlur],
  );

  const handleDaySelect = React.useCallback(
    (_: any, date?: Date) => {
      const datePart = date ? dayjs(date).format(DATE_FORMAT) : '';
      setDateAndTime({ datePart, timePart: timeString });
      !onSave && disclosure.onClose();
    },
    [disclosure, onSave, setDateAndTime, timeString],
  );

  const range: DateRange = React.useMemo(() => {
    const date = dayjs(lastValidDateString, DATE_FORMAT).toDate();
    return { from: rangeFrom ?? date, to: rangeTo ?? date };
  }, [lastValidDateString, rangeFrom, rangeTo]);

  const handleSave = React.useCallback(() => {
    setDateAndTime({ datePart: dateString, timePart: timeString, shouldSave: true });
    disclosure.onClose();
  }, [dateString, disclosure, setDateAndTime, timeString]);

  const [month, setMonth] = React.useState(value);

  const timeStringInputProps = React.useMemo<InputProps>(() => {
    return {
      value: timeString,
      isDisabled,
      isInvalid: errorType === 'time',
      onChange: onTimeChange,
    };
  }, [errorType, isDisabled, onTimeChange, timeString]);

  const dateStringInputProps = React.useMemo<InputProps>(() => {
    return {
      onBlur: handleDateStringBlur,
      onChange: handleDateStringChange,
      onKeyPress: handleDateStringKeyPress,
      value: dateString,
      isInvalid: errorType === 'date',
      isDisabled,
      placeholder: DATE_FORMAT,
    };
  }, [dateString, errorType, handleDateStringBlur, handleDateStringChange, handleDateStringKeyPress, isDisabled]);

  const dayPickerProps = React.useMemo<DayPickerRangeProps>(() => {
    return {
      ...dayPickerPropsProp,
      month,
      selected: range,
      mode: 'range' as const,
      onSelect: handleDaySelect,
      onMonthChange: setMonth,
    };
  }, [dayPickerPropsProp, handleDaySelect, month, range]);

  return React.useMemo(() => {
    return {
      dateStringInputProps,
      dayPickerProps,
      disclosure,
      handleDaySelect,
      handleSave,
      onTimeStatusChange,
      saveBtnRef,
      timeStatus,
      timeStringInputProps,
    };
  }, [
    dateStringInputProps,
    dayPickerProps,
    disclosure,
    handleDaySelect,
    handleSave,
    onTimeStatusChange,
    timeStatus,
    timeStringInputProps,
  ]);
};

export const formatMMDDYYYY = (date: Date | undefined) => {
  return date ? dayjs(date).format(DateFormat.DatePickerInputDefault) : DateFormat.DatePickerInputDefault;
};
