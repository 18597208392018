import { Muid } from '@process-street/subgrade/core';
import { TaskTemplate } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import {
  useQuery as useRQ,
  QueryKey,
  UseQueryOptions,
  QueryClient,
  QueryObserverOptions,
  QueryObserver,
} from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetAllTaskTemplatesByChecklistRevisionIdQuery {
  export type Params = { checklistRevisionId?: Muid };
  export type Response = TaskTemplate[];

  export const key = ['checklist-revisions', 'task-templates'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/checklist-revisions/${params.checklistRevisionId}/task-templates`)
      .then(r => r.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.checklistRevisionId) && options.enabled !== false,
    });
  };

  export const makeQueryObserver = ({
    queryClient,
    options,
    ...params
  }: Params & {
    queryClient: QueryClient;
    options?: QueryObserverOptions<Response, AxiosError>;
  }) =>
    new QueryObserver(queryClient, {
      ...options,
      queryKey: getKey(params),
      queryFn: () => queryFn(params),
      enabled: Boolean(params.checklistRevisionId) && options?.enabled !== false,
    });
}
