import { ApprovalTasksGroupType } from '@process-street/subgrade/approval-rule/approval.types';
import { Option } from '@process-street/subgrade/core';
import { Task } from '@process-street/subgrade/process';
import angular from 'angular';
import { ChecklistTaskAssignmentSelector } from 'components/checklist-task-assignment/store/checklist-task-assignment.selectors';
import ngRedux from 'ng-redux';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { connectController } from 'reducers/util';
import { createSelector } from 'reselect';
import templateUrl from './item.component.html';
import './item.component.scss';

enum Mode {
  NO_CONTROLS = 'NO_CONTROLS',
  APPROVE_CONTROLS = 'APPROVE_CONTROLS',
  APPROVE_COMMENT_CONTROLS = 'APPROVE_COMMENT_CONTROLS',
  EDIT_STATUS_CONTROLS = 'EDIT_STATUS_CONTROLS',
  REJECT_CONTROLS = 'REJECT_CONTROLS',
  REJECT_COMMENT_CONTROLS = 'REJECT_COMMENT_CONTROLS',
}

export class ApprovalSubjectTasksListItemController {
  public task: Option<Task>;
  public disabled = false;

  public mode = Mode.NO_CONTROLS;
  // TODO
  // @ts-expect-error -- TODO
  public type: ApprovalTasksGroupType;
  public widgetsReplacingText: Option<string> = undefined;

  static $inject = ['$ngRedux'];
  static $ngRedux: ngRedux.INgRedux;

  constructor(private $ngRedux: ngRedux.INgRedux) {
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    const mapStateToThis = () =>
      createSelector(
        [
          TaskTemplateSelector.getById(this.task!.taskTemplate.id),
          ChecklistTaskAssignmentSelector.getAllByTaskIdWithOrganizationMembershipAndUser(this.task!.id),
        ],
        (taskTemplate, checklistTaskAssignments) => ({ taskTemplate, checklistTaskAssignments }),
      );

    connectController(this.$ngRedux, mapStateToThis, null, false)(this);
  }

  public $onChanges(changes: {
    disabled: angular.IChangesObject<boolean>;
    task: angular.IChangesObject<Task>;
    type: angular.IChangesObject<ApprovalTasksGroupType>;
  }) {
    const { disabled, task, type } = changes;
    if (type) {
      switch (type.currentValue) {
        case ApprovalTasksGroupType.NotSubmitted:
          this.mode = Mode.NO_CONTROLS;
          this.widgetsReplacingText = 'Will be submitted';
          break;
        case ApprovalTasksGroupType.Rejected:
          this.mode = Mode.NO_CONTROLS;
          break;
        case ApprovalTasksGroupType.Awaiting:
          this.mode = Mode.APPROVE_CONTROLS;
          break;
        case ApprovalTasksGroupType.Approved:
          this.mode = Mode.EDIT_STATUS_CONTROLS;
          break;
      }
    }

    if (task && task.currentValue) {
      this.task = task.currentValue;
    }
    if (disabled && disabled.currentValue) {
      this.disabled = disabled.currentValue;
    }

    if (type && type.currentValue) {
      this.type = type.currentValue;
    }
  }

  public approve(comment?: string) {
    // @ts-expect-error -- TODO
    this.onApprove({ task: this.task, comment });
  }

  public reject(comment?: string) {
    // @ts-expect-error -- TODO
    this.onReject({ task: this.task, comment });
  }

  public getIsEditingStatus() {
    return (
      this.mode !== Mode.EDIT_STATUS_CONTROLS &&
      this.mode !== Mode.NO_CONTROLS &&
      this.type !== ApprovalTasksGroupType.Awaiting
    );
  }

  public getShowEditStatus() {
    return this.type === ApprovalTasksGroupType.Approved;
  }
}

export const ApprovalSubjectTasksListItem: angular.IComponentOptions = {
  bindings: {
    disabled: '<',
    onApprove: '&',
    onReject: '&',
    onSelect: '&',
    task: '<',
    type: '<',
    isCondensed: '<',
  },
  controller: ApprovalSubjectTasksListItemController,
  templateUrl,
};
