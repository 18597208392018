import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { TaskAutomationIndicator } from '.';

export const TaskAutomationIndicatorModule = angular
  .module('TaskAutomationIndicatorModule', [])
  .component(
    'psTaskAutomationIndicator',
    reactToAngular(TaskAutomationIndicator, [
      'editable',
      'taskTemplate',
      'templateId',
      'isActive',
      'templateRevision',
      'isDisabled',
    ]),
  ).name;
