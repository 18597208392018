import { GetRowIdParams, GridOptions as TGridOptions, IsRowSelectable } from '@ag-grid-community/core';
import {
  ActivityRenderer,
  AssigneesRenderer,
  CommentsAndAttachmentsRenderer,
  CompletedByRenderer,
  MonthDayRenderer,
  NameRenderer,
  NoRowsRenderer,
  OverdueTasksRenderer,
  ProgressRenderer,
  StatusRenderer,
  TemplateRenderer,
} from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers';
import { RowValues } from 'components/dashboard/models/grid';
import { ColumnType } from '@process-street/subgrade/dashboard';
import { ChecklistDashboardSelectionHeaderRenderer } from '../components/checklist/ChecklistDashboardGrid/renderers/SelectionHeaderRenderer';
import { LabelRenderer } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers/LabelRenderer/LabelRenderer';

const icons = {
  sortAscending: '<i class="far fa-sort-up"/>',
  sortDescending: '<i class="far fa-sort-down"/>',
  sortUnSort: '<i class="far fa-sort"/>',
};

const isRowSelectable: IsRowSelectable = node => {
  const permissionMap = node.data?.permissionMap ?? {};

  return permissionMap.checklistArchive && permissionMap.checklistDelete;
};

export const checklistDashboardGridOptions: TGridOptions = {
  cacheBlockSize: 25,
  getRowId: (params: GetRowIdParams<RowValues>) => params.data.id,
  headerHeight: 40,
  infiniteInitialRowCount: 5,
  maxConcurrentDatasourceRequests: 1, // Important as we're using offset pagination in the grid-data-source
  noRowsOverlayComponent: NoRowsRenderer,
  rowHeight: 56,
  rowModelType: 'infinite',
  rowSelection: 'multiple',
  isRowSelectable,
  suppressFieldDotNotation: true,
  unSortIcon: true,
  columnTypes: {
    [ColumnType.Checklist]: { icons },
    [ColumnType.Task]: {
      sortable: false,
      width: 100,
    },
    [ColumnType.FormField]: {
      icons,
      sortable: false,
      width: 200,
    },
  },
  components: {
    ActivityRenderer,
    AssigneesRenderer,
    CommentsAndAttachmentsRenderer,
    CompletedByRenderer,
    MonthDayRenderer,
    NameRenderer,
    LabelRenderer,
    OverdueTasksRenderer,
    ProgressRenderer,
    StatusRenderer,
    TemplateRenderer,
    SelectionHeaderRenderer: ChecklistDashboardSelectionHeaderRenderer,
  },
};
