import * as React from 'react';
import { useCopyRunLink } from 'features/run-via-link/use-copy-run-link';
import { Button, HStack, Icon } from 'components/design/next';
import { Template } from '@process-street/subgrade/process';
import { WorkflowRunLinkButton } from 'features/template/components/template-menu/workflow-run-link-button';

interface WorkflowRunLinkProps {
  templateId: Template['id'];
}

const CHECK_MARK_TIMEOUT = 5000;

export const WorkflowRunLink = ({ templateId }: WorkflowRunLinkProps) => {
  const { copyToClipboard } = useCopyRunLink({ templateId });

  const [isRunLinkCopied, setIsRunLinkCopied] = React.useState(false);

  const onCopyRunLink = () => {
    copyToClipboard();
    setIsRunLinkCopied(true);
    setTimeout(() => setIsRunLinkCopied(false), CHECK_MARK_TIMEOUT);
  };

  return (
    <HStack spacing={3}>
      <Button
        variant="tertiary"
        size="sm"
        leftIcon={
          <Icon
            size="3"
            variant="far"
            icon={isRunLinkCopied ? 'check' : 'copy'}
            color={isRunLinkCopied ? 'green.500' : 'inherit'}
          />
        }
        onClick={onCopyRunLink}
        fontSize="xs"
      >
        Copy Workflow Run link
      </Button>
      <WorkflowRunLinkButton templateId={templateId} hideTabs>
        <Button variant="link" fontSize="xs" color="gray.400">
          Link settings
        </Button>
      </WorkflowRunLinkButton>
    </HStack>
  );
};
