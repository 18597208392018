import React from 'react';
import { ButtonProps, Icon, IconButton } from 'components/design/next';
import { useUIActorRef, TOP_BAR_HEIGHT_CSS_VAR } from 'pages/forms/_id/shared';

export type InsertWidgetDrawerButtonProps = ButtonProps;

export const InsertWidgetDrawerButton = (props: InsertWidgetDrawerButtonProps) => {
  const { uiActorRef } = useUIActorRef();
  return (
    <IconButton
      variant="tertiary"
      borderRightRadius={0}
      icon={<Icon icon="plus" variant="far" size="4" />}
      aria-label="open widgets menu"
      position="fixed"
      top={`calc(${TOP_BAR_HEIGHT_CSS_VAR} + var(--ps-sizes-8))`}
      right="0"
      onClick={() => uiActorRef.send({ type: 'OPEN_DISCLOSURE', name: 'insertWidget' })}
      {...props}
    />
  );
};
