import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateOrganizationDomainParams = {
  organizationId?: string;
  domain: string;
};

export const CreateOrganizationDomainMutation = {
  key: ['organization', 'domains'],
  mutationFn: (params: CreateOrganizationDomainParams) =>
    axiosService
      .getAxios()
      .put(`/1/organizations/${params.organizationId}/domains/${params.domain}`)
      .then(r => r.data),
};

export const useCreateOrganizationDomainMutation = (
  options?: UseMutationOptions<unknown, AxiosError, CreateOrganizationDomainParams>,
) => {
  return useMutation(params => CreateOrganizationDomainMutation.mutationFn(params), options);
};
