import * as React from 'react';
import { HStack, Icon, Link, Text, VStack } from 'components/design/next';
import { CustomIconName } from 'components/design/next/icon/icon-name';

type SecondaryStatProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  icon: CustomIconName;
  value: number;
  docsUrl: string;
};

export const SecondaryStat: React.FC<React.PropsWithChildren<SecondaryStatProps>> = ({
  title,
  description,
  icon,
  value,
  docsUrl,
}) => {
  return (
    <VStack spacing="1" w="full" alignItems="flex-start">
      <HStack spacing="2">
        <Icon
          color="gray.600"
          icon={icon}
          size="4"
          style={{
            // overrides HStack styles to align with title
            marginBottom: '3px',
          }}
        />
        <Text color="gray.600">
          {value} {title}
        </Text>
        <Link
          href={docsUrl}
          target="_blank"
          style={{
            marginBottom: '3px',
          }}
        >
          <Icon icon="circle-info" variant="far" size="3" color="gray.400" />
        </Link>
      </HStack>
      <Text fontSize="xs" color="gray.500">
        {description}
      </Text>
    </VStack>
  );
};
