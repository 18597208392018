import angular from 'angular';
import { ChecklistStatus, TaskStatus } from '@process-street/subgrade/process';
import { connectService } from 'reducers/util';
import { HttpStatus } from '@process-street/subgrade/util';
import { canAccess, Feature } from './features/features';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { ChecklistEvent } from 'services/checklists/checklist-event';
import { OneOffTaskHelper } from 'features/one-off-tasks/components/shared/one-off-task-helper';
import { oneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { PromiseQueueKeyGenerator } from './promise-queue/promise-queue-key-generator-pure';

angular
  .module('frontStreetApp.services')
  .service(
    'TaskService',
    function (
      $ngRedux,
      $rootScope,
      $q,
      $window,
      ChecklistService,
      FormFieldValueService,
      MessageBox,
      OrganizationService,
      PromiseQueueDescGenerator,
      PromiseQueueService,
      RequiredFieldService,
      SessionService,
      StopTaskService,
      TaskActions,
      TaskApi,
      TaskStatsService,
      TaskTemplateService,
      ToastService,
    ) {
      const logger = trace({ name: 'TaskService' });

      const self = this;

      connectService('TaskService', $ngRedux, null, TaskActions)(self);

      /**
       * Gets a single task by it's id
       *
       * @param id
       *
       * @return {Promise}
       */
      self.getById = function (id) {
        return self.actions.get(id).then(action => action.payload);
      };

      self.getAllByChecklistRevisionId = function (checklistRevisionId, flushCache = false) {
        return self.actions.getAllByChecklistRevisionId(checklistRevisionId, flushCache).then(action => action.payload);
      };

      self.updateDueDate = function (id, dueDate, dueDateOverridden) {
        return self.actions.updateDueDate(id, dueDate, dueDateOverridden).then(action => action.payload);
      };

      self.calculatePercentageComplete = (taskTemplates, taskMap, attachedTasks) => {
        let count = 0;
        let completedCount = 0;

        (taskTemplates || []).forEach(taskTemplate => {
          const task = taskMap[taskTemplate.group.id];
          if (!TaskTemplateService.isHeading(taskTemplate) && task && !task.hidden) {
            count++;
            if (!task.hidden && task.status === TaskStatus.Completed) {
              completedCount++;
            }
          }
        });

        (attachedTasks || []).forEach(attachedTask => {
          count++;
          if (attachedTask.status === TaskStatus.Completed) {
            completedCount++;
          }
        });

        return count !== 0 ? (completedCount / count) * 100 : 0;
      };

      self.countCompleted = function (tasks) {
        let count = 0;

        tasks.forEach(task => {
          if (!TaskTemplateService.isHeading(task.taskTemplate) && task.status === TaskStatus.Completed) {
            count += 1;
          }
        });

        return count;
      };

      self.anyInvalidField = (taskStatsMap = {}) => {
        return Object.values(taskStatsMap).some(task => task.invalidFieldsCount > 0 && !task.hidden);
      };

      self.canOrganizationAccessCompletedState = function (checklistRevision) {
        return OrganizationService.getById(checklistRevision.checklist.organization.id).then(
          organization => {
            const planId = organization && organization.subscription.plan.id;

            return canAccess(Feature.COMPLETED_STATE, planId);
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading the organization`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          },
        );
      };

      // Without calling BE, autocompletes checklist on FE after BE has
      // supposedly autocompleted the checklist when completing the last task.
      self.autoCompleteChecklistIfAllTasksCompleted = async ({
        checklistRevision,
        taskTemplates,
        taskMap,
        taskStatsMap,
        oneOffTasks = [],
      }) => {
        const organizationCanAccessCompletedState = await self.canOrganizationAccessCompletedState(checklistRevision);
        if (!organizationCanAccessCompletedState) {
          return;
        }

        const checklistCompleted = ChecklistService.isChecklistCompleted(checklistRevision.checklist);
        const tasksIncomplete = self.calculatePercentageComplete(taskTemplates, taskMap) < 100;
        const invalidFieldsFound = self.anyInvalidField(taskStatsMap);

        // Don't do optimistic update if there are hidden incomplete tasks - they may be hidden because of time-based CL
        const hasHiddenIncompleteTasks = Object.values(taskMap).some(
          task => task.hidden && task.status !== TaskStatus.Completed,
        );

        if (hasHiddenIncompleteTasks) {
          const checklistId = checklistRevision.checklist.id;
          const updatedChecklist = await ChecklistService.getById(checklistId, true);
          if (updatedChecklist.status === ChecklistStatus.Completed) {
            const data = {
              updatedChecklist,
              originalChecklist: checklistRevision.checklist,
            };

            $rootScope.$broadcast(ChecklistEvent.UPDATE_OK, data);
          }
        }

        if (checklistCompleted || tasksIncomplete || invalidFieldsFound || hasHiddenIncompleteTasks) {
          return;
        }

        const requiredAttachedTasks = OneOffTaskHelper.getRequiredNotCompletedTasks(oneOffTasks);
        if (requiredAttachedTasks.length > 0) {
          const completedByUserId = SessionService.getUser()?.id;
          const userTask = OneOffTaskHelper.findFirstRequiredNotCompletedUserTask(oneOffTasks, completedByUserId);
          if (userTask) {
            oneOffTaskDrawerStore.getState().viewTask({ task: userTask });
          } else {
            ToastService.openToast({
              status: 'info',
              title: `You’re done! 🎉`,
              description: `There are some tasks that need to be done by another user to mark this Workflow Run as completed.`,
            });
          }
          return;
        }

        if (!TaskStatsService.isChecklistStoppedByNotPermittedTask(Object.values(taskMap), taskStatsMap)) {
          const originalChecklist = angular.copy(checklistRevision.checklist);
          const updatedChecklist = checklistRevision.checklist;
          updatedChecklist.status = ChecklistStatus.Completed;
          updatedChecklist.completedBy = SessionService.getUser();
          updatedChecklist.completedDate = Date.now();

          const data = {
            updatedChecklist,
            originalChecklist,
          };

          $rootScope.$broadcast(ChecklistEvent.UPDATE_OK, data);
        }
      };

      /**
       * Validates is task status is updatable
       *
       * This function returns a validation result is form of an object structured as follows
       *
       * {
       *     updatable: boolean,
       *     errors: {
       *         stopped: boolean, <- will be true if the task marked as stopped for any reason
       *         invalidFormFields: [
       *             // Array of all invalid fields related to this task
       *         ],
       *        failedConstraintsFormFields: [
       *          // Array of all fields with invalid constraints
       *        ],
       * i      invalidFieldCount: unique invalid fields count
       *     }
       * }
       *
       * @param task
       * @param newStatus
       * @param formFieldWidgetsMap Map that holds lists of form field widgets with task template group id as a key
       * @param formFieldValueMap Map of form field values with widget header id as a key
       * @param taskMap Map of tasks with task template group id as a key
       * @returns object The validation result object described in the details
       */
      self.validateIfTaskStatusUpdatable = function (
        task,
        newStatus,
        formFieldWidgetsMap,
        formFieldValueMap,
        taskMap,
        taskTemplates,
        taskStatsMap = {},
      ) {
        const validationResult = {
          updatable: true,
        };

        if (newStatus === TaskStatus.NotCompleted) {
          return validationResult;
        }

        const errors = {};

        const { disabledTaskTemplateGroupIds } = StopTaskService.getDisabledTaskTemplateGroupIdsByFormFieldValue(
          taskTemplates,
          formFieldWidgetsMap,
          formFieldValueMap,
          taskMap,
        );

        // if the task is marked as stopped, we cannot do anything with it
        if (disabledTaskTemplateGroupIds.includes(task.taskTemplate.group.id)) {
          validationResult.updatable = false;
          errors.stopped = true;
          errors.stop = task.taskTemplate.stop;
        }

        const invalidFormFields = RequiredFieldService.getTaskInvalidFieldsByFormFieldValues(
          task,
          formFieldWidgetsMap,
          formFieldValueMap,
          taskMap,
        );

        const failedConstraintsFormFields = FormFieldValueService.getFailedFormFieldWidgets({
          taskWidgets: formFieldWidgetsMap[task.taskTemplate.group.id],
          formFieldValueMap,
        });

        const invalidFieldCount = self._getUniqueInvalidFieldsCount({ invalidFormFields, failedConstraintsFormFields });
        if (invalidFieldCount) {
          validationResult.updatable = false;
          errors.invalidFormFields = invalidFormFields;
          errors.failedConstraintsFormFields = failedConstraintsFormFields;
          errors.invalidFieldCount = invalidFieldCount;
        }
        if (
          validationResult.updatable &&
          TaskStatsService.isTaskStoppedByNotPermittedTask(task, Object.values(taskMap), taskStatsMap)
        ) {
          validationResult.updatable = false;
          errors.stoppedByHiddenTask = true;
        }

        if (!validationResult.updatable) {
          validationResult.errors = errors;
        }

        return validationResult;
      };

      self._getUniqueInvalidFieldsCount = ({ invalidFormFields = [], failedConstraintsFormFields = [] }) =>
        new Set(invalidFormFields.concat(failedConstraintsFormFields)).size;

      self.getReversedTaskStatus = function (status) {
        let newStatus;
        switch (status) {
          case TaskStatus.Completed:
            newStatus = TaskStatus.NotCompleted;
            break;
          case TaskStatus.NotCompleted:
            newStatus = TaskStatus.Completed;
            break;
          default:
            logger.error('unexpected status: %s', status);
        }

        return newStatus;
      };

      self.updateTaskStatus = function (task, newStatus, checklist) {
        const queueDesc = PromiseQueueDescGenerator.generateUpdateStatusByTaskId(task.id, newStatus);
        const queueKey = PromiseQueueKeyGenerator.generateByChecklistId(checklist.id);
        return PromiseQueueService.enqueue(
          queueKey,
          () => {
            let statusUpdateRequest;
            if ([TaskStatus.Completed, TaskStatus.NotCompleted].includes(newStatus)) {
              statusUpdateRequest = TaskApi.updateStatus(task.id, newStatus);
            } else {
              logger.error('unexpected status: %s', newStatus);
            }

            self.actions.updateTaskStatusRequest(task);
            $rootScope.$broadcast(EventName.TASK_STATUS_UPDATE_STARTED, task);

            return statusUpdateRequest.then(
              response => {
                const data = {
                  status: newStatus,
                  checklistName: checklist.name,
                  templateName: checklist.template.name,
                  templateId: checklist.template.id,
                };

                $rootScope.$broadcast(EventName.TASKS_CHECKED_UNCHECKED, data);

                self.actions.updateTaskStatusSuccess(task);
                $rootScope.$broadcast(EventName.TASK_STATUS_UPDATE_OK, response.task, response.dueDateTaskStates);

                return response;
              },
              response => {
                switch (response.status) {
                  case HttpStatus.CONFLICT:
                    if (response.data && response.data.invalidFormFields) {
                      ToastService.openToast({
                        status: 'warning',
                        title: `We couldn't complete the task`,
                        description: `${response.data.invalidFormFields.length} field(s) still need to be completed.`,
                      });
                    } else {
                      MessageBox.confirm({
                        title: 'Workflow Run Updated',
                        message:
                          "We couldn't update the task because the workflow run has been updated. " +
                          'Click refresh to get the new version!',
                        okButton: {
                          type: 'success',
                          text: 'Refresh',
                          action: () => $window.location.reload(),
                        },
                      });
                    }
                    break;
                  default:
                    ToastService.openToast({
                      status: 'error',
                      title: `We're having problems updating the task`,
                      description: DefaultErrorMessages.unexpectedErrorDescription,
                    });
                }

                self.actions.updateTaskStatusFailure(task);
                $rootScope.$broadcast(EventName.TASK_STATUS_UPDATE_FAILED, task);

                return $q.reject(response);
              },
            );
          },
          queueDesc,
        );
      };
    },
  );
