import * as React from 'react';
import { Icon, IconButton, useDisclosure, useToast } from 'components/design/next';
import { DataSet, SavedView } from '@process-street/subgrade/process';
import {
  GetAllIncomingWebhooksByDataSetIdQuery,
  useCreateIncomingWebhookMutation,
  useGetAllIncomingWebhooksByDataSetIdQuery,
} from 'features/automations/query-builder';
import { MuidUtils } from '@process-street/subgrade/core';
import { DataSetAutomationModal } from 'pages/templates/_id/automation/data-set-automation-modal';
import { useQueryClient } from 'react-query';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { IncomingWebhook } from '@process-street/subgrade/automation';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import { useFeatureFlag } from 'features/feature-flags';
import { NoAccessTooltip } from 'pages/reports/data-sets/components/data-set-page/no-access-tooltip';

export type WebhooksButtonProps = {
  dataSet: DataSet;
  savedViewId?: SavedView['id'];
  isDisabled: boolean;
};

export const WebhooksButton: React.FC<WebhooksButtonProps> = ({ dataSet, savedViewId, isDisabled }) => {
  const disclosure = useDisclosure();
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');
  const queryClient = useQueryClient();
  const incomingWebhooksQuery = useGetAllIncomingWebhooksByDataSetIdQuery(
    {
      dataSetId: dataSet.id,
    },
    {
      enabled: !isDisabled,
    },
  );
  const nativeAutomationsQuery = GetAllNativeAutomationsQuery.useQuery({
    dataSetId: dataSet.id,
  });

  const hasAutomations =
    nativeAutomationsQuery.data?.some(automation => automation.automation.status === 'Active') ||
    incomingWebhooksQuery.data?.some(incomingWebhook => incomingWebhook.status === 'Active');

  const [incomingWebhook] = incomingWebhooksQuery.data ?? [];
  const toast = useToast();

  const createIncomingWebhookMutation = useCreateIncomingWebhookMutation({
    onSuccess: async webhook => {
      queryClient.setQueryData<IncomingWebhook[]>(
        GetAllIncomingWebhooksByDataSetIdQuery.getKey({ dataSetId: dataSet.id }),
        webhooks => [...(webhooks ?? []), webhook],
      );
      disclosure.onOpen();
    },
    onError: () => {
      toast({
        title: `We're having problems creating the automation`,
        status: 'error',
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const disabledTooltip =
    `You don't have access to edit this ${savedViewId ? 'saved view' : 'data set'}.` +
    (isDataSetsV2Enabled ? '' : ' Please reach out to the owner or an admin.');

  if (incomingWebhooksQuery.isLoading) return null;

  const handleClick = () => {
    if (!incomingWebhook) {
      createIncomingWebhookMutation.mutate({
        id: MuidUtils.randomMuid(),
        dataSetId: dataSet.id,
        automationApp: 'Default',
        status: 'Disabled',
        config: {
          dataSetColumns: {},
        },
        name: `${dataSet.name}'s webhook`,
        webhookType: 'DataSet',
      });
    } else {
      disclosure.onOpen();
    }
  };

  return (
    <>
      <NoAccessTooltip
        isDisabled={!isDisabled}
        label={disabledTooltip}
        dataSet={dataSet}
        contactAdmin={isDisabled && isDataSetsV2Enabled}
      >
        <IconButton
          isDisabled={isDisabled}
          aria-label="Automations"
          variant="ghost"
          icon={<Icon color={hasAutomations ? 'purple.500' : 'gray.500'} size="4" icon="bolt" />}
          colorScheme="gray"
          borderRadius="4px"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.300"
          onClick={handleClick}
        />
      </NoAccessTooltip>
      {incomingWebhook && !isDisabled && <DataSetAutomationModal webhook={incomingWebhook} {...disclosure} />}
    </>
  );
};
