import angular from 'angular';
import './spinner.scss';

angular
  .module('frontStreetApp.controllers')
  .controller('MessageBoxSpinnerCtrl', function ($scope, $uibModalInstance, modalOptions, focusById, util) {
    $scope.getTitle = util.functionize(modalOptions.title);
    $scope.getMessage = util.functionize(modalOptions.message);

    const buttonOptions = modalOptions.button;

    $scope.button = buttonOptions && {
      getType: util.functionize(buttonOptions.type),
      getText: util.functionize(buttonOptions.text),
      isDisabled: util.functionize(buttonOptions.disabled),
      action: buttonOptions.action || angular.noop,
    };

    focusById('button');

    $scope.click = function () {
      $uibModalInstance.close($scope.button && $scope.button.action());
    };
  });
