import angular from 'angular';
import './magnific-popup.scss';
import 'magnific-popup';

angular.module('frontStreetApp.directives').directive('psMagnificPopup', () => ({
  restrict: 'A',
  link(scope, element, attributes) {
    element.magnificPopup({
      type: 'image',
      image: {
        titleSrc() {
          return scope.$eval(attributes.psMagnificPopupCaption);
        },
      },
    });
  },
}));
