import { concatInDistinctArray, onSuccess } from 'reducers/util';
import { referencesNormalizer } from 'reducers/entities/entities.utils';
import { combineReducers } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import {
  FORM_FIELD_VALUE_SET,
  FORM_FIELD_VALUE_UPDATE,
} from 'components/form-field-value/store/form-field-value.actions';
import {
  TASK_ASSIGNMENT_ASSIGN,
  TASK_ASSIGNMENT_ASSIGN_START,
  TASK_ASSIGNMENT_ADD,
  TASK_ASSIGNMENT_GET_ALL_BY_CHECKLIST_REVISION,
  TASK_ASSIGNMENT_INVITE,
  TASK_ASSIGNMENT_UNASSIGN,
  TASK_ASSIGNMENT_REMOVE,
} from './checklist-task-assignment.actions';
import { toLookupMap } from '@process-street/subgrade/redux';

const normalizeAssignment = referencesNormalizer(['organizationMembership']);

export const checklistTaskAssignmentEntitiesReducer = handleActions(
  {
    [combineActions(TASK_ASSIGNMENT_ASSIGN_START, TASK_ASSIGNMENT_ADD, TASK_ASSIGNMENT_INVITE)]: onSuccess(
      (state, { payload: assignment }) => EntitiesReducerUtils.upsert(state, assignment, normalizeAssignment),
    ),
    [TASK_ASSIGNMENT_ASSIGN]: (state, { payload: assignment, error, meta: { newAssignment } }) => {
      const stateWithoutNewAssignment = EntitiesReducerUtils.delete(state, newAssignment);
      if (error) {
        return stateWithoutNewAssignment;
      } else {
        return EntitiesReducerUtils.upsert(stateWithoutNewAssignment, assignment, normalizeAssignment);
      }
    },
    [TASK_ASSIGNMENT_GET_ALL_BY_CHECKLIST_REVISION]: onSuccess((state, { payload: assignments }) =>
      EntitiesReducerUtils.upsertAll(state, assignments, normalizeAssignment),
    ),
    [combineActions(TASK_ASSIGNMENT_UNASSIGN, TASK_ASSIGNMENT_REMOVE)]: onSuccess((state, { payload: assignment }) =>
      EntitiesReducerUtils.deleteById(state, assignment.id),
    ),
    [combineActions(FORM_FIELD_VALUE_UPDATE, FORM_FIELD_VALUE_SET)]: onSuccess(
      (state, { payload: { checklistTaskAssignments } }) => {
        const { created, deleted } = checklistTaskAssignments;
        let stateCopy = { ...state };
        if (deleted.length > 0) {
          stateCopy = EntitiesReducerUtils.deleteAllByIds(
            state,
            deleted.map(a => a.id),
          );
        }
        if (created.length > 0) {
          stateCopy = EntitiesReducerUtils.upsertAll(stateCopy, created, normalizeAssignment);
        }
        return stateCopy;
      },
    ),
  },
  {},
);

export const checklistTaskAssignmentLookupByTaskIdReducer = handleActions(
  {
    [TASK_ASSIGNMENT_GET_ALL_BY_CHECKLIST_REVISION]: onSuccess((state, { payload: assignments }) =>
      toLookupMap(assignments, a => a.task.id),
    ),
    [combineActions(TASK_ASSIGNMENT_UNASSIGN, TASK_ASSIGNMENT_REMOVE)]: onSuccess((state, { payload: assignment }) =>
      LookupsReducerUtils.deleteUsingSelectorFunctions(state, assignment, a => a.task.id),
    ),
    [combineActions(TASK_ASSIGNMENT_INVITE, TASK_ASSIGNMENT_ASSIGN_START, TASK_ASSIGNMENT_ADD)]: onSuccess(
      (state, { payload: assignment }) =>
        LookupsReducerUtils.upsertUsingSelectorFunctions(state, assignment, a => a.task.id),
    ),
    [TASK_ASSIGNMENT_ASSIGN]: (state, { payload: assignment, error, meta: { newAssignment } }) => {
      const stateWithoutNewAssignment = LookupsReducerUtils.deleteUsingSelectorFunctions(
        state,
        newAssignment,
        a => a.task.id,
      );

      if (error) {
        return stateWithoutNewAssignment;
      } else {
        return LookupsReducerUtils.upsertUsingSelectorFunctions(stateWithoutNewAssignment, assignment, a => a.task.id);
      }
    },
    [combineActions(FORM_FIELD_VALUE_UPDATE, FORM_FIELD_VALUE_SET)]: onSuccess(
      (state, { payload: { checklistTaskAssignments } }) => {
        const { created, deleted } = checklistTaskAssignments;
        const idsToDeleteMap = deleted.reduce((agg, assignment) => {
          const taskId = assignment.task.id;
          if (!agg[taskId]) {
            agg[taskId] = [];
          }

          agg[taskId].push(assignment.id);

          return agg;
        }, {});

        const idsToAddMap = created.reduce((agg, assignment) => {
          const taskId = assignment.task.id;
          if (!agg[taskId]) {
            agg[taskId] = [];
          }

          agg[taskId].push(assignment.id);

          return agg;
        }, {});

        const stateCopy = { ...state };
        Object.keys(idsToDeleteMap).forEach(taskId => {
          stateCopy[taskId] = (state[taskId] || []).slice().filter(id => !idsToDeleteMap[taskId].includes(id));
        });

        Object.keys(idsToAddMap).forEach(taskId => {
          idsToAddMap[taskId].forEach(id => {
            stateCopy[taskId] = concatInDistinctArray(stateCopy[taskId], id);
          });
        });

        return stateCopy;
      },
    ),
  },
  {},
);

export const checklistTaskAssignmentLookupsReducer = combineReducers({
  byTaskId: checklistTaskAssignmentLookupByTaskIdReducer,
});
