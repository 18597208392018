import { Muid } from '@process-street/subgrade/core';
import { Template, TemplateStatus } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateTemplateStatusMutationParams = { templateId: Template['id']; status: keyof typeof TemplateStatus };

export type UpdateTemplateStatusMutationResponse = Template;

export const UpdateTemplateStatusMutation = {
  getKey({ templateId }: UpdateTemplateStatusMutationParams) {
    return ['update', 'templates', templateId, 'status'];
  },
  mutationFn: (params: UpdateTemplateStatusMutationParams) =>
    axiosService
      .getAxios()
      .put<UpdateTemplateStatusMutationResponse>(`/1/templates/${params.templateId}/status/${params.status}`, params)
      .then(res => res.data),
};

export const useUpdateTemplateStatusMutation = (
  options: UseMutationOptions<
    UpdateTemplateStatusMutationResponse,
    AxiosError,
    UpdateTemplateStatusMutationParams
  > = {},
) => {
  return useMutation(UpdateTemplateStatusMutation.mutationFn, options);
};

type UseUpdateTemplatesStatusMutationParams = {
  templateIds: Muid[];
  status: keyof typeof TemplateStatus;
};

export const useUpdateTemplatesStatusMutation = (
  options: UseMutationOptions<
    UpdateTemplateStatusMutationResponse[],
    AxiosError,
    UseUpdateTemplatesStatusMutationParams
  > = {},
) => {
  return useMutation(
    ({ templateIds, status }: UseUpdateTemplatesStatusMutationParams) =>
      Promise.all(templateIds.map(templateId => UpdateTemplateStatusMutation.mutationFn({ templateId, status }))),
    options,
  );
};
