import { Muid } from '@process-street/subgrade/core';
import { FileUploadModuleState } from 'components/file-upload/store/file-upload.types';
import { ReduxAppState } from 'reducers/types';
import { createSelector } from 'reselect';

const moduleState = (state: ReduxAppState): FileUploadModuleState => state.modules.fileUpload;

const getUploadByWidgetId = (widgetId: Muid) =>
  createSelector(moduleState, (state: FileUploadModuleState) => state.uploadsMap[widgetId] || {});

export const FileUploadModuleSelector = {
  getUploadByWidgetId,
};
