import angular from 'angular';
import { HttpStatus } from '@process-street/subgrade/util';
import billingTemplate from './billing.component.html';
import './_billing.scss';
import { features } from 'services/features/features';
import { isT5KPlan } from 'services/plans';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { IntercomService } from 'services/interop/intercom-service';
import { EventName } from 'services/event-name';
import { PlanLevel } from '@process-street/subgrade/billing';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular.module('frontStreetApp.directives').component('psBilling', {
  bindings: {},
  templateUrl: billingTemplate,
  controller($scope, $state, $timeout, BillingService, PlanService, OrganizationService, SessionService, ToastService) {
    const ctrl = this;

    ctrl.subscriptionCancellationModalIsVisible = false;

    ctrl.$onInit = function () {
      OrganizationService.getSelectedOrganization({ flushCache: true }).then(
        organization => {
          ctrl.organization = organization;
          ctrl.currentPlan = SessionService.getCurrentPlan();

          ctrl.showStartupPlan = isT5KPlan(ctrl.currentPlan.id) || ctrl.currentPlan?.level === PlanLevel.Free;
          ctrl.freemium = !ctrl.showStartupPlan && features.isFreemiumTrack(ctrl.currentPlan.id);

          AnalyticsService.trackEvent('organization billing tab viewed', {
            'plan id': ctrl.currentPlan.id,
            'plan name': ctrl.currentPlan.name,
            'plan cost': ctrl.currentPlan.cost,
          });

          ctrl.planSelectorIsShown = false;

          ctrl.quantity = ctrl.organization.subscription.quantity;

          OrganizationService.getDiscountByOrganizationId(ctrl.organization.id)
            .catch(() => ({}))
            .then(discount => {
              ctrl.discount = discount;
            });

          initializeSubscriptionInfo(ctrl.organization, ctrl.currentPlan);
        },
        errorResponse => {
          switch (errorResponse.status) {
            case HttpStatus.FORBIDDEN:
              $state.go('userManage.tab', { id: 'me', tab: 'organizations' });
              break;
            default:
              ToastService.openToast({
                status: 'error',
                title: `We're having problems loading your organization`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
              break;
          }
        },
      );
    };

    ctrl.shouldShowPlanSelectorByDefault = () => ctrl.currentPlan && PlanService.isPlanFree(ctrl.currentPlan);

    ctrl.showPlanSelector = () => {
      ctrl.planSelectorIsShown = true;
    };

    ctrl.hidePlanSelector = () => {
      ctrl.planSelectorIsShown = false;
      // User should be able to upgrade only from plan selector page
      ctrl.onUpgradePlanSelect(null, null, ctrl.quantity);
    };

    ctrl.onUpgradePlanSelect = (upgradePlan, total, quantity) => {
      ctrl.upgradePlan = upgradePlan;
      ctrl.quantity = quantity;
      ctrl.upgradeTotal = total;
    };

    function setOrganization(organization) {
      ctrl.organization = organization;
    }

    function initializeSubscriptionInfo(organization, plan) {
      ctrl.currentSubIsCancelable = BillingService.subscriptionIsCancelable(organization, plan);

      ctrl.currentSubIsPremiumAndWillBeCanceled = BillingService.willSubscriptionCancelAtPeriodEnd(
        organization.subscription,
        plan,
      );
    }

    ctrl.doCancelSubscription = function (cancellationForm) {
      OrganizationService.cancelSubscriptionByOrganizationId(ctrl.organization.id, cancellationForm).then(
        updatedOrganization => {
          AnalyticsService.trackEvent('subscription canceled', {
            'plan id': ctrl.currentPlan.id,
            'plan name': ctrl.currentPlan.name,
            'plan interval': ctrl.currentPlan.interval,
            'plan level': ctrl.currentPlan.level,
            'cancellation reason': cancellationForm.reason,
            'alternative solution': cancellationForm.alternativeSolution,
            'additional cancellation details': cancellationForm.additionalDetails,
          });

          setOrganization(updatedOrganization);

          initializeSubscriptionInfo(updatedOrganization, ctrl.currentPlan);
          ctrl.$onInit();
        },
        () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems cancelling your subscription`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });

          IntercomService.show();
        },
      );
    };

    ctrl.cancelSubscription = function () {
      $timeout(() => {
        ctrl.subscriptionCancellationModalIsVisible = true;
      });
    };

    ctrl.hideSubscriptionCancellationModal = function () {
      $timeout(() => {
        ctrl.subscriptionCancellationModalIsVisible = false;
      });
    };
    // Events

    $scope.$on('$stateChangeSuccess', () => {
      if ($state.is('organizationManage.tab', { tab: 'billing' })) {
        ctrl.$onInit();
      }
    });

    $scope.$on(EventName.ORGANIZATION_MEMBERSHIP_UPDATED, () => {
      ctrl.$onInit();
    });

    $scope.$on(EventName.ORGANIZATION_MEMBERSHIP_DELETED, () => {
      ctrl.$onInit();
    });
  },
});
