import * as React from 'react';
import { Link, Icon, LinkProps } from 'components/design/next';
import { EmbedUtils } from '@process-street/subgrade/util';

export const DomainLink: React.FC<React.PropsWithChildren<LinkProps>> = props => {
  return React.useMemo(() => {
    return (
      <Link isExternal {...props}>
        {EmbedUtils.extractDomain(props.href || '')}{' '}
        <Icon size="3" icon="external-link" variant="far" color="gray.600" />
      </Link>
    );
  }, [props]);
};
