import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { canAccess, Feature } from 'services/features/features';
import { Option } from 'space-monad';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { ButtonWithUpgradeTooltip } from 'components/button-with-upgrade-tooltip';
import messageTemplate from 'directives/template-share/modal/template-share-modal.component.html';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { ButtonProps } from 'components/design/next';
import { Template } from '@process-street/subgrade/process';

export interface WorkflowRunLinkButtonProps {
  children: React.ReactElement<ButtonProps>;
  templateId: Template['id'];
  hideTabs?: boolean;
}

export const WorkflowRunLinkButton: React.FC<WorkflowRunLinkButtonProps> = React.memo(
  ({ templateId, children, hideTabs }) => {
    const { MessageBox } = useInjector('MessageBox');

    const currentUser = useSelector(SessionSelector.getCurrentUser);

    const { data: template } = useGetTemplateQuery({ templateId });
    const { data: templateRevision } = useNewestTemplateRevisionQuery({ templateId });

    const go = () => {
      MessageBox.custom({
        animation: false,
        templateUrl: messageTemplate,
        controller: 'TemplateShareModal',
        size: 'lg',
        options: { template, templateRevision, user: currentUser, tab: 'runlink', hideTabs },
      });
    };

    if (!React.isValidElement(children) || Array.isArray(children)) {
      throw new Error('WorkflowRunLinkButton child must be a single clickable element');
    }

    const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId, { enabled: !!templateId });
    const plan = useSelector(SessionSelector.getCurrentPlan);

    const isFree = useIsCurrentUserFreeMember();
    if (isFree) return <ButtonWithUpgradeTooltip>{children}</ButtonWithUpgradeTooltip>;

    const checklistRunLinkAvailable = canAccess(Feature.CHECKLIST_RUN_LINK, plan?.id);
    const hasPermission = Option(permissionsQuery.data)
      .map(
        ({ permissionMap: pm }) =>
          pm.templatePermitsManage || pm.templateShareLevelUpdate || pm.templateShareLinkUpdate,
      )
      .getOrElse(false);

    if (!(checklistRunLinkAvailable && hasPermission)) return null;

    const btn = React.cloneElement(children, { onClick: go });

    return btn;
  },
);
