import {
  isAdmin,
  isGuest,
  OrganizationMembershipRole,
  OrganizationMembershipWithUser,
} from '@process-street/subgrade/core';
import { FolderPermit, isFolderPermit, TemplatePermit } from '@process-street/subgrade/permission';
import { FolderPermitResolver } from '@process-street/subgrade/permission/folder-permit-resolver';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import {
  useGetConsolidatedFolderPermissionsQuery,
  useGetConsolidatedGlobalPermissionsQuery,
  useGetConsolidatedTemplatePermissionsQuery,
} from 'features/permissions/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { canAccess, Feature } from 'services/features/features';
import { isT5KPlan } from 'services/plans';

export const useCanChangeLevel = (
  permit: (TemplatePermit | FolderPermit) & { organizationMembership: OrganizationMembershipWithUser },
): { canChangeLevel: boolean; isLoading: boolean } => {
  const { organizationMembership: membership } = permit;
  const templateId = isFolderPermit(permit) ? undefined : permit.template.id;
  const folderId = isFolderPermit(permit) ? permit.folder.id : undefined;
  const currentUserInfoQuery = useGetCurrentUserInfoQuery();
  const globalPermissionsMapQuery = useGetConsolidatedGlobalPermissionsQuery(membership.organization.id);
  const templatePermissionsMapQuery = useGetConsolidatedTemplatePermissionsQuery(templateId!, {
    enabled: !!templateId,
  });
  const folderPermissionsMapQuery = useGetConsolidatedFolderPermissionsQuery(folderId!, { enabled: !!folderId });
  const currentPlan = useSelector(SessionSelector.getCurrentPlan);

  // We get better type narrowing if we use separate return blocks
  if (templatePermissionsMapQuery.isLoading || globalPermissionsMapQuery.isLoading || currentUserInfoQuery.isLoading)
    return { isLoading: true, canChangeLevel: false };

  if (
    (templatePermissionsMapQuery.isSuccess || folderPermissionsMapQuery.isSuccess) &&
    globalPermissionsMapQuery.isSuccess &&
    currentUserInfoQuery.isSuccess
  ) {
    // Bad name here to describe a record-specific logic gate based on template or folder
    const canUpdateRecord = (() => {
      if (folderPermissionsMapQuery.isSuccess && isFolderPermit(permit)) {
        const { folderUpdate } = folderPermissionsMapQuery.data?.permissionMap ?? {};
        const guestCanRunChecklist = canAccess(Feature.GUESTS_CAN_CREATE_CHECKLISTS, currentPlan?.id);
        const isFreeMember = membership.role === OrganizationMembershipRole.FreeMember;
        const isFreeMemberAndCanRun = isFreeMember && isT5KPlan(currentPlan!.id);

        const level = FolderPermitResolver.findFolderAccessLevel({ permit, isFreeMember, isFreeMemberAndCanRun });
        return !!level && folderUpdate && (!isGuest(membership) || guestCanRunChecklist);
      }
      if (templatePermissionsMapQuery.isSuccess) {
        const { templateUpdate, pageUpdate } = templatePermissionsMapQuery.data.permissionMap;
        return templateUpdate || pageUpdate;
      }
      return false;
    })();

    const { organizationMembershipLevelUpdate } = globalPermissionsMapQuery.data.permissionMap;
    const hasPermission = canUpdateRecord || organizationMembershipLevelUpdate;

    const isCurrentUser = membership.user.id === currentUserInfoQuery.data.user.id;

    return {
      canChangeLevel: hasPermission && (!isCurrentUser || isAdmin(membership)),
      isLoading: false,
    };
  }

  return {
    canChangeLevel: false,
    isLoading: false,
  };
};
