import { TypeOfEnumValue } from '@process-street/subgrade/core';
import { isValidEmail } from '..';
import { EmailFormFieldWidget } from '../widget-model';

function emailFormFieldCIF<
  Code extends string,
  Config extends Record<
    string,
    { code: Code; isInvalid: (constraints: EmailFormFieldWidget['constraints'], value: string) => boolean }
  >,
>(config: Config): Config {
  return config;
}

export const EmailFormFieldValidation = emailFormFieldCIF({
  invalidEmail: {
    code: 'invalid-email',
    isInvalid: (_, value) => !isValidEmail(value),
  },
  allow: {
    code: 'allow',
    isInvalid: (constraints, value) =>
      !!constraints?.domains &&
      constraints.restriction === 'Allow' &&
      !constraints?.domains.includes(value.split('@')[1]),
  },
  block: {
    code: 'block',
    isInvalid: (constraints, value) =>
      !!constraints?.domains &&
      constraints.restriction === 'Block' &&
      constraints?.domains?.includes(value.split('@')[1]),
  },
});

export const getEmailFormFieldWidgetErrorType = (constraints: EmailFormFieldWidget['constraints'], value: string) => {
  if (!value || !constraints) return;
  return (
    Object.values(EmailFormFieldValidation).find(config => config.isInvalid(constraints, value))?.code ?? undefined
  );
};
export type EmailFormFieldValidationCode = TypeOfEnumValue<typeof EmailFormFieldValidation>['code'] | undefined;
