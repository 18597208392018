import * as React from 'react';
import { Text, Tooltip, VStack } from 'components/design/next';
import {
  FileContentWidgetMachineActorRef,
  FileContentWidgetMachineSelectors as Selectors,
} from './file-content-widget-machine';
import { useSelector } from '@xstate/react';
import { RulesEngineTargetSelectors } from '../../form-response-body/rules-engine-machine';
import { StringUtils } from '@process-street/subgrade/util';
import {
  DownloadFileIconButton,
  FileUpload,
  FileUploadLeftElement,
  FileUploadRightElement,
  FileUploadRightElementInfo,
  FileUploadSizeLabel,
} from 'features/files';

export type FileContentWidgetProps = {
  actor: FileContentWidgetMachineActorRef;
};

export const FileContentWidget: React.FC<React.PropsWithChildren<FileContentWidgetProps>> = ({ actor }) => {
  const widget = useSelector(actor, Selectors.getWidget);
  const isHiddenByRule = useSelector(actor, RulesEngineTargetSelectors.getIsHiddenByRule);

  if (isHiddenByRule || !widget.file) return null;

  const label = StringUtils.getNonEmpty(widget.description, widget.file.originalName);

  return (
    <VStack position="relative" w="full" alignItems="flex-start">
      <FileUpload>
        <FileUploadLeftElement />

        <FileUploadRightElement>
          <FileUploadRightElementInfo>
            <Tooltip label={label} openDelay={500} hasArrow>
              <Text color="gray.600" fontWeight="medium" noOfLines={1} w="full">
                {label}
              </Text>
            </Tooltip>
            <FileUploadSizeLabel>{widget.file.size}</FileUploadSizeLabel>
          </FileUploadRightElementInfo>

          <DownloadFileIconButton href={widget.file.url} />
        </FileUploadRightElement>
      </FileUpload>
    </VStack>
  );
};
