export const ChecklistAssignmentApi = function (ChecklistAssignments) {
  'ngInject';

  const getAllByOrganizationId = query => ChecklistAssignments.getAllByOrganizationId(query).$promise;

  const getAllByChecklistId = checklistId => ChecklistAssignments.getAllByChecklistId({ checklistId }).$promise;

  const assignUser = (checklistId, email, autoAssign) =>
    ChecklistAssignments.assignUser({
      id: checklistId,
      email,
      autoAssign,
    }).$promise;

  const unassignUser = (checklistId, userEmail) =>
    ChecklistAssignments.unassignUser({
      id: checklistId,
      email: userEmail,
    }).$promise;

  return {
    assignUser,
    getAllByChecklistId,
    getAllByOrganizationId,
    unassignUser,
  };
};
