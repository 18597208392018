import * as React from 'react';
import {
  ChecklistRuleDefinition,
  isConditionLogical,
  isOperandLogical,
  FormFieldCondition as FormFieldConditionModel,
  LogicalOperand,
  SimpleChecklistRuleDefinition,
} from '@process-street/subgrade/conditional-logic';
import { Box, Checkbox, HStack, Text, VStack } from 'components/design/next';
import { LogicalRule } from './logical-rule';
import { usePrintRulesContext } from '../../../context';
import { getGroupedTasksAndWidgets, isFormFieldCondition, isTaskCondition, isTimeBasedCondition } from '../utils';
import { WidgetInfo } from './widget-info';
import { SimpleRule } from './simple-rule';
import { TimeBasedCondition } from './time-based-condition';
import { FormFieldCondition } from './form-field-condition';
import { TaskCondition } from './task-condition';

interface RuleDefinitionProps {
  rule: ChecklistRuleDefinition;
}

const getRuleHeader = (rule: ChecklistRuleDefinition) => {
  if (isOperandLogical(rule.operand)) {
    const logicalRule = (rule.operand as LogicalOperand).data;
    const firstCondition = logicalRule.conditions[0];
    if (isConditionLogical(firstCondition)) {
      const firstRule = firstCondition.conditions[0];
      if (isFormFieldCondition(firstRule)) {
        return <FormFieldCondition condition={firstRule} isTitle />;
      }
      if (isTimeBasedCondition(firstRule)) {
        return <TimeBasedCondition condition={firstRule} isTitle />;
      }
      if (isTaskCondition(firstRule)) {
        return <TaskCondition condition={firstRule} isTitle />;
      }
    }
  } else {
    const condition: FormFieldConditionModel = {
      formFieldWidgetGroupId: rule.formFieldWidgetGroupId,
      operator: rule.operator,
      operandType: rule.operand.operandType,
      operandValue: { value: (rule as SimpleChecklistRuleDefinition).operand.value },
    };
    return <FormFieldCondition condition={condition} isTitle />;
  }
};

export const RuleDefinition: React.FC<RuleDefinitionProps> = ({ rule }) => {
  const { taskGroupIds, widgetGroupIds } = usePrintRulesContext();
  const { groupedTasksWidgets } = getGroupedTasksAndWidgets(taskGroupIds, widgetGroupIds, rule);
  const itemsToShow = groupedTasksWidgets.filter(
    entry =>
      !(entry.task.ruleAppliesToTask === false && entry.widgets.every(widget => widget.ruleAppliesToWidget === false)),
  );

  const renderOperand = () => {
    if (isOperandLogical(rule.operand)) {
      return <LogicalRule rule={(rule.operand as LogicalOperand).data} />;
    } else {
      return <SimpleRule rule={rule as SimpleChecklistRuleDefinition} />;
    }
  };
  return (
    <Box
      my={4}
      px={4}
      pt={2}
      pb={4}
      borderColor="gray.200"
      borderStyle="solid"
      borderWidth="thin"
      borderRadius="md"
      w="full"
    >
      <Text fontWeight="bold" fontSize="lg" my={4}>
        {getRuleHeader(rule)}
      </Text>
      {rule?.description && (
        <Text mt={2}>
          <Text as="b">Notes:</Text>&nbsp;
          {rule?.description}
        </Text>
      )}
      <HStack my={2}>{renderOperand()}</HStack>
      <Text fontWeight="bold" fontSize="md" my={2}>
        THEN: {rule.hidden ? 'Hide' : 'Show'}
      </Text>

      {itemsToShow.length > 0 && (
        <Box ml={2}>
          {itemsToShow.map(taskWidgetGroup => (
            <React.Fragment key={`task-${taskWidgetGroup.task.id}`}>
              <HStack spacing={2} mt={2}>
                <Text as="span" color="gray.400">
                  {taskWidgetGroup.task.taskOrder}
                </Text>
                <Checkbox defaultChecked={taskWidgetGroup.task.ruleAppliesToTask} isReadOnly />
                <Text as="span">{taskWidgetGroup.task.name ?? 'unnamed task'}</Text>
              </HStack>
              <VStack ml={10} mt={2} spacing={2} alignItems="flex-start">
                {taskWidgetGroup.widgets.map(({ widget, label, icon, ruleAppliesToWidget }) => (
                  <HStack key={`widget-${widget.id}`} spacing={2}>
                    <WidgetInfo icon={icon} isChecked={!!ruleAppliesToWidget} label={label} />
                  </HStack>
                ))}
              </VStack>
            </React.Fragment>
          ))}
        </Box>
      )}
    </Box>
  );
};
