import * as React from 'react';
import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { FormResponsePageMachineHooks } from 'app/pages/responses/_id/form-response-page-hooks';
import { toModelRef } from '@process-street/subgrade/core';
import { TaskListItemAutomationIndicator as AutomationIndicator } from 'app/pages/workflows/_id/edit-v2/components/task-list-item-automation-indicator';

export type TaskListItemAutomationIndicatorProps = {
  task: TaskWithTaskTemplate;
};

export const TaskListItemAutomationIndicator = ({ task }: TaskListItemAutomationIndicatorProps) => {
  const checklistRevision = FormResponsePageMachineHooks.useChecklistRevision();

  const templateRevision = toModelRef(checklistRevision?.templateRevision);

  if (!templateRevision) return null;

  return (
    <AutomationIndicator
      taskTemplate={task.taskTemplate}
      templateRevision={templateRevision}
      editable={false}
      isDisabled={false}
      templateId={templateRevision.template.id}
    />
  );
};
