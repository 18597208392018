import * as React from 'react';
import { BarStackHorizontal } from '@visx/shape';
import { Box, HStack, Stat, StatLabel, StatNumber, StatHelpText, Avatar, Text } from 'components/design/next';
import { usePopper } from 'react-popper';
import { ParentSize } from '@visx/responsive';
import { useChecklistAnalyticsQuery } from 'features/checklists/query-builder';
import { match, P } from 'ts-pattern';
import { DerivedChecklistStatus as Status } from '@process-street/subgrade/dashboard';
import { ChecklistStat, tooltipReducer, makeInitialTooltipState, TooltipEffectContext } from './tooltip-state';
import { LegendItem } from './legend-item';
import { Rect } from './rect';
import { Tick } from './tick';
import { CHECKLIST_STATUS_BY_USER } from '../common/visualization-names';
import { ParentSizeRenderProps } from '../common/types';
import { useChecklistSearchCriteria } from 'pages/reports/hooks/use-checklist-search-criteria';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { EmptyBarStackHorizontal } from '../empty-bar-stack-horizontal';
import {
  CHECKLIST_STATUS_COLORS as COLORS,
  CHECKLIST_STATUS_KEYS as KEYS,
  CHECKLIST_STATUS_LABELS as STATUS_LABELS,
} from '../common/constants';
import {
  useBarStackHorizontal,
  BarStackHorizontalAxisLeft,
  makeBarStackHorizontalLegend,
  BarStackHorizontalAxisBottom,
  BarStackHorizontalScrollable,
} from '../bar-stack-horizontal';
import { ChartTooltip } from '../chart-tooltip';
import { ChartSpinner } from '../common/chart-spinner';
import { ChartAlert } from '../chart-alert';
import { getDerivedChecklistStatusPercentage } from '../common/utils';
import { Muid } from '@process-street/subgrade/core';
import { getAvatar } from 'components/common/Avatar';

export type ChecklistStatusByUserProps = {
  data: ChecklistStat[];
  userDataByUserId: Record<Muid, { username: string; avatarUrl: string }>;
  parentSize: ParentSizeRenderProps;
};

const BarStackHorizontalLegend = makeBarStackHorizontalLegend<Status>();

export const ChecklistStatusByUserChart: React.VFC<ChecklistStatusByUserProps> = ({
  parentSize,
  data,
  userDataByUserId,
}) => {
  const { width: parentWidth } = parentSize;

  const [tooltipState, dispatch] = React.useReducer(tooltipReducer, makeInitialTooltipState());
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const popper = usePopper(tooltipState.referenceElement, popperElement);

  const getUsername = React.useCallback(
    (id: string) => {
      return userDataByUserId[id]?.username ?? '(Unknown)';
    },
    [userDataByUserId],
  );

  const getAvatarUrl = React.useCallback(
    (id: string) => {
      return userDataByUserId[id]?.avatarUrl ?? '';
    },
    [userDataByUserId],
  );

  const {
    legendRef,
    colorScale,
    xScale,
    yScale,
    yMax,
    chartHeight,
    chartOverflowHeight,
    xAxisHeight,
    margin,
    invertYCoord,
  } = useBarStackHorizontal({
    data,
    keys: KEYS,
    yDomain: datum => datum.userId,
    yTickLabelDomain: d => getUsername(d.userId),
    parentSize,
    colors: COLORS,
  });

  return parentWidth < 10 ? null : (
    <TooltipEffectContext.Provider value={dispatch}>
      <Box
        {...{
          'position': 'relative',
          'aria-label': CHECKLIST_STATUS_BY_USER,
        }}
      >
        <BarStackHorizontalScrollable
          {...{
            chartHeight,
            chartOverflowHeight,
            margin,
            parentWidth,
            xAxisHeight,
            barStackHorizontal: (
              <BarStackHorizontal<ChecklistStat, Status>
                data={data}
                keys={KEYS}
                height={yMax}
                y={x => x.userId}
                xScale={xScale}
                yScale={yScale}
                color={colorScale}
              >
                {barStacks =>
                  barStacks.map(barStack =>
                    barStack.bars.map(bar => (
                      <Rect key={`barstack-horizontal-${barStack.index}-${bar.index}`} bar={bar} />
                    )),
                  )
                }
              </BarStackHorizontal>
            ),
            axisLeft: (
              <BarStackHorizontalAxisLeft
                numTicks={data.length}
                scale={yScale}
                tickComponent={Tick}
                tickFormat={getUsername}
              />
            ),
            axisBottom: <BarStackHorizontalAxisBottom scale={xScale} />,
          }}
        />

        <BarStackHorizontalLegend ref={legendRef} scale={colorScale} aria-label="workflow run status legend">
          {label => <LegendItem key={label.datum} {...label} />}
        </BarStackHorizontalLegend>

        {tooltipState.data && (
          <ChartTooltip ref={setPopperElement} style={popper.styles.popper} {...popper.attributes.popper}>
            {match(tooltipState.data)
              .with({ bar: P.not(P.nullish) }, ({ key: status, bar: { data: checklistStat } }) => {
                const percent = getDerivedChecklistStatusPercentage(checklistStat)(status);
                return (
                  <Stat aria-label="workflow run status percentage and total by assigned user">
                    <StatLabel>{STATUS_LABELS[status]}</StatLabel>
                    <StatNumber color={colorScale(status)}>{percent}%</StatNumber>
                    <StatHelpText>Total: {checklistStat[status]}</StatHelpText>
                  </Stat>
                );
              })
              .with({ status: P.not(P.nullish) }, ({ total, status }) => (
                <Stat aria-label="workflow run status total">
                  <StatLabel>Total</StatLabel>
                  <StatNumber color={colorScale(status)}>{total}</StatNumber>
                </Stat>
              ))
              .with({ y: P.number }, ({ y }) => {
                const id = invertYCoord(y) ?? '';
                const username = getUsername(id);
                const avatarUrl = getAvatarUrl(id);
                return (
                  <Stat aria-label="assigned user username">
                    <HStack as={StatLabel}>
                      <Avatar src={avatarUrl} name={username} />
                      <Text variant="-1">{username}</Text>
                    </HStack>
                  </Stat>
                );
              })
              .exhaustive()}
          </ChartTooltip>
        )}
      </Box>
    </TooltipEffectContext.Provider>
  );
};

export const ChecklistStatusByUser = () => {
  const searchCriteria = useChecklistSearchCriteria();
  const { templateIds } = searchCriteria;
  const checklistAnalyticsQuery = useChecklistAnalyticsQuery(searchCriteria, {
    select: analytics =>
      Object.entries(analytics.checklistStatusStatsByAssigneeUserId).map(([id, rest]) => ({ userId: id, ...rest })),
  });

  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  // fetch all the users for the axis tick labels
  const orgMembershipsQuery = useGetAllOrganizationMembershipsQuery(
    { organizationId },
    {
      select: orgMemberships =>
        orgMemberships.reduce((acc, om) => {
          acc[om.user.id] = { username: om.user.username, avatarUrl: getAvatar(om.user).url };
          return acc;
        }, {} as ChecklistStatusByUserProps['userDataByUserId']),
      enabled: Boolean(organizationId && checklistAnalyticsQuery.data),
    },
  );

  return match({ checklistAnalyticsQuery, orgMembershipsQuery })
    .with({ checklistAnalyticsQuery: { status: 'loading' } }, { orgMembershipsQuery: { status: 'loading' } }, () => (
      <ChartSpinner />
    ))
    .with({ checklistAnalyticsQuery: { status: 'error' } }, ({ checklistAnalyticsQuery: { error } }) => (
      <ChartAlert statusCode={error.response?.status} />
    ))
    .with(
      { checklistAnalyticsQuery: { status: 'success', data: [] }, orgMembershipsQuery: { status: 'success' } },
      () => <EmptyBarStackHorizontal />,
    )
    .with(
      {
        checklistAnalyticsQuery: { status: 'success', data: P.not(P.nullish) },
        orgMembershipsQuery: { status: 'success' },
      },
      ({ checklistAnalyticsQuery: { data }, orgMembershipsQuery: { data: userDataByUserId } }) => {
        return (
          <ParentSize>
            {parentSize => (
              <ChecklistStatusByUserChart
                key={templateIds?.join(':')}
                data={data}
                userDataByUserId={userDataByUserId}
                parentSize={parentSize}
              />
            )}
          </ParentSize>
        );
      },
    )
    .otherwise(() => null);
};
