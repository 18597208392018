import {
  AutomationAppName,
  isIncomingWebhookAppName,
  stripAutomationAppNamePrefix,
} from '@process-street/subgrade/automation';
import featuredAppsCsv from './FeaturedWebhookEvents.csv';
import appsByUseCase from './WebhookAppsByUseCase.csv';
import { UserDetailUseCase } from '@process-street/subgrade/core';
import { match } from 'ts-pattern';
import { FeaturedIncomingWebhooksUtils } from './featured-incoming-webhook-apps.utils';

const useCasesAndEvents = FeaturedIncomingWebhooksUtils.getUseCasesAndEvents(appsByUseCase.trim());

const useCasesByApp = FeaturedIncomingWebhooksUtils.getUseCasesByApp(useCasesAndEvents);

const events = FeaturedIncomingWebhooksUtils.getEvents(featuredAppsCsv.trim());

const appNames = FeaturedIncomingWebhooksUtils.getAppNames(events);
const enabledAppNames = FeaturedIncomingWebhooksUtils.getEnabledAppNames(events);
const eventsByApp = FeaturedIncomingWebhooksUtils.getEventsByApp(events);

const getAutomationAppNameMatchesUseCase = ({
  automationAppName,
  useCase,
}: {
  automationAppName: AutomationAppName;
  useCase?: UserDetailUseCase;
}) => {
  return match(automationAppName)
    .when(
      isIncomingWebhookAppName,
      a => useCasesByApp.get(stripAutomationAppNamePrefix(a))?.includes(useCase as UserDetailUseCase) ?? false,
    )
    .otherwise(() => false);
};

export const FeaturedIncomingWebhooks = {
  events,
  appNames,
  enabledAppNames,
  eventsByApp,
  useCasesAndEvents,
  useCasesByApp,
  getAutomationAppNameMatchesUseCase,
};
