import * as React from 'react';

import { Box, Checkbox } from 'components/design/next';
import { WidgetIcon } from './widget-icon';
import { removeHtmlTags } from 'app/features/conditional-logic/components/node-name';

interface RuleDefinitionProps {
  icon: string;
  isChecked: boolean;
  label: string;
}

export const WidgetInfo: React.FC<RuleDefinitionProps> = ({ icon, isChecked, label }) => {
  const widgetContent = removeHtmlTags(label);
  return (
    <>
      <Checkbox defaultChecked={isChecked} isReadOnly />
      <WidgetIcon iconName={icon} />
      <Box
        fontSize="md"
        dangerouslySetInnerHTML={{
          __html: widgetContent,
        }}
      />
    </>
  );
};
