import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { DropzoneState, useDropzone } from 'react-dropzone';
import { useUploadToS3Mutation } from 'features/upload/query-builder';
import {
  FinishUploadWorkflowSourceMutation,
  UploadUrlWorkflowSourceMutation,
} from 'features/ai-generation/query-builder';

const SIZE_TOO_LARGE = 'size-too-large';
const INVALID_FILE_TYPE = 'invalid-file-type';
const MAX_FILE_SIZE_MB = 25;
// GPT Vision limitation https://platform.openai.com/docs/guides/vision/is-there-a-limit-to-the-size-of-the-image-i-can-upload
const MAX_IMAGE_FILE_SIZE_MB = 20;
const convertMbToBytes = (sizeMb: number) => sizeMb * 1024 * 1024;

const ACCEPTED_IMPORT_TYPES = [
  'text/plain',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/html',
  'text/xml',
  'text/csv',
];

const ACCEPTED_IMAGE_TYPES = ['image/png', 'image/jpeg'];

type UploadWorkflowDocResult = {
  dropzoneState: DropzoneState;
  uploadError: AxiosError | null;
  acceptedImportTypes: string[];
};

type UploadWorkflowDocProps = {
  onFileUploadFinish: (id: string) => void;
  setFile: (file: File) => void;
  setProgress: (progress: number | undefined) => void;
  // remove with GPT-4o feature flag
  isImageImportEnabled?: boolean;
};

const UPLOAD_URL_PROGRESS = 20;
const S3_PROGRESS = 70;

export const useUploadWorkflowDoc = ({
  setProgress,
  setFile,
  onFileUploadFinish,
  isImageImportEnabled,
}: UploadWorkflowDocProps): UploadWorkflowDocResult => {
  const uploadUrlMutation = UploadUrlWorkflowSourceMutation.useMutation();
  const finishUploadUrlMutation = FinishUploadWorkflowSourceMutation.useMutation();
  const uploadToS3Mutation = useUploadToS3Mutation();

  const uploadError = uploadUrlMutation.error ?? uploadToS3Mutation.error ?? finishUploadUrlMutation.error;

  useEffect(() => {
    if (uploadError) {
      setProgress(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query error
  }, [uploadError]);

  const uploadSingleFile = async (file: File) => {
    setProgress(0);
    setFile(file);

    const mimeType = file.type || 'application/octet-stream';

    const { url, key } = await uploadUrlMutation.mutateAsync({
      fileName: file.name,
      mimeType,
    });

    setProgress(UPLOAD_URL_PROGRESS);

    const fileBuffer = await readFile(file);

    await uploadToS3Mutation.mutateAsync({
      file,
      url,
      data: fileBuffer,
      onProgress: (progress: number) =>
        setProgress((progress * (S3_PROGRESS - UPLOAD_URL_PROGRESS)) / 100 + UPLOAD_URL_PROGRESS),
    });

    setProgress(S3_PROGRESS);

    const s3File = await finishUploadUrlMutation.mutateAsync({
      mimeType,
      fileName: file.name,
      key,
    });

    setProgress(100);

    onFileUploadFinish?.(s3File.id);
  };

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles.shift();
    if (file) {
      await uploadSingleFile(file);
    }
  };

  const acceptedImportTypes = isImageImportEnabled
    ? ACCEPTED_IMPORT_TYPES.concat(ACCEPTED_IMAGE_TYPES)
    : ACCEPTED_IMPORT_TYPES;

  const dropzoneState = useDropzone({
    maxFiles: 1,
    onDropAccepted: onDrop,
    validator: file => {
      if (!acceptedImportTypes.includes(file.type)) {
        return {
          code: INVALID_FILE_TYPE,
          message: `Uploaded file must have a valid file type.`,
        };
      }
      const isImage = ACCEPTED_IMAGE_TYPES.includes(file.type);
      const maxSizeMb = isImage ? MAX_IMAGE_FILE_SIZE_MB : MAX_FILE_SIZE_MB;
      if (file.size > convertMbToBytes(maxSizeMb)) {
        return {
          code: SIZE_TOO_LARGE,
          message: `Uploaded file size must be under ${maxSizeMb} MB.`,
        };
      }
      return null;
    },
  });

  return {
    dropzoneState,
    uploadError,
    acceptedImportTypes,
  };
};

export function readFile(file: File): Promise<ArrayBuffer> {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);

  return new Promise((resolve, reject) => {
    reader.onload = async () => {
      if (reader.readyState === 2 && reader.result !== null) {
        resolve(reader.result as ArrayBuffer);
      } else {
        reject(reader.error);
      }
    };
  });
}
