import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Divider,
  Flex,
  Hide,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Show,
  Skeleton,
  Spinner,
  Text,
  Tooltip,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { Breadcrumbs } from 'components/breadcrumbs';
import { useInjector } from 'components/injection-provider';
import { Icon, useToast } from 'components/design/next';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { GetTemplateQuery, useUpdateTemplateMutation } from 'features/template/query-builder';
import React from 'react';
import { CopyFormShareLink } from '../copy-form-share-link';
import { useQueryClient } from 'react-query';
import { SaveIndicator } from '../save-indicator';
import { AutomationsModalWrapper } from 'pages/templates/_id/automation';
import { match, P } from 'ts-pattern';
import { useSelector } from '@xstate/react';
import { TemplateRevisionStatus, TemplateRevisionWithTemplate, TemplateType } from '@process-street/subgrade/process';
import { NgLink } from 'features/app/components/ng-link';
import { InsertMenuItem } from './insert-menu-item';
import { DesignDrawer, DesignDrawerButton } from '../design-drawer';
import { DesignMenuItem } from './design-menu-item';
import { useBackProps } from './use-back-props';
import { useUIActorRef, useUiDisclosure } from '../ui-context';
import { UpgradePlanAlert } from '../upgrade-plan-alert';
import { TopBarActorRef, TopBarActorSelectors } from './top-bar-machine';
import { FormsBreadcrumbLink, ResponsesLabel, STEPS } from './steps';
import { FormConditionalLogicModalWrapper } from './form-conditional-logic-modal-wrapper';
import { abbreviateForTitle } from '@process-street/subgrade/util';
import { TemplateRevisionProvider } from 'pages/templates/_id/components/custom-notifications-modal/template-revision-context';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { RequestFormEditPermission } from '../request-form-edit-permission';
import { ShareButton } from '../share-button';
import { SendLinkViaEmailModal } from '../../edit/components/send-link-via-email-modal';
import { ShareMenuItem } from './share-menu-item';

export type FormEditorTopBarProps = {
  actor: TopBarActorRef;
};

export const TOP_BAR_HEIGHT_TOKEN = 16;
export const TOP_BAR_HEIGHT_CSS_VAR = `var(--ps-sizes-${TOP_BAR_HEIGHT_TOKEN})`;

export const FormEditorTopBar: React.FC<React.PropsWithChildren<FormEditorTopBarProps>> = ({ actor }) => {
  const { isOpen: isConditionalLogicOpen } = useUiDisclosure('conditionalLogic');

  const template = useSelector(actor, TopBarActorSelectors.getTemplate);
  const templateRevision = useSelector(actor, TopBarActorSelectors.getTemplateRevision);
  const isFinished = templateRevision?.status === TemplateRevisionStatus.Finished;
  const hasChanges = (templateRevision?.lastActiveDate ?? 0) > (templateRevision?.audit.updatedDate ?? 0);

  const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(template?.id);

  const isViewOnlyForm = !permissionMap?.templateUpdate;

  const isPublishDisabled = !templateRevision || isFinished || !hasChanges;
  const publishTooltip = match({ templateRevision, isFinished, hasChanges })
    .with({ isFinished: true }, () => 'No draft to publish')
    .with({ hasChanges: false }, () => 'No changes to publish')
    .otherwise(() => '');

  const isPublishing = useSelector(actor, TopBarActorSelectors.getIsPublishing);

  const { uiActorRef } = useUIActorRef();
  const isMobile = useBreakpointValue({ base: true, lg: false }) ?? false;
  React.useEffect(() => {
    uiActorRef.send({ type: 'SET_IS_MOBILE', isMobile });
  }, [isMobile, uiActorRef]);

  const { $state } = useInjector('$state');
  const toast = useToast();
  const [isEditingTemplateName, setIsEditingTemplateName] = React.useState(false);
  const [templateName, setTemplateName] = React.useState(template?.name ?? '');
  const queryClient = useQueryClient();

  const updateTemplateMutation = useUpdateTemplateMutation({
    onSuccess: async template => {
      await queryClient.invalidateQueries(GetTemplateQuery.getKey({ templateId: template.id }));
      toast({ status: 'success', title: 'Form name updated' });
    },
    onError: () => {
      template && setTemplateName(template.name);

      toast({
        status: 'error',
        title: `We're having problems updating the form name`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const saveTemplate = () => {
    if (!template) return;

    if (!templateName) {
      setTemplateName(template.name);

      toast({
        status: 'warning',
        title: `We couldn't update the form name`,
        description: `The form name can't be empty`,
      });

      return;
    }

    // Don't update if the name hasn't changed
    if (templateName === template.name) return;

    updateTemplateMutation.mutate({
      templateId: template.id,
      name: templateName,
    });
  };

  React.useEffect(() => {
    if (template?.name) setTemplateName(template?.name);
  }, [template?.name]);

  const backProps = useBackProps({ template });

  const shouldShowEditNameInput = isEditingTemplateName;

  return (
    <>
      <HStack
        position="relative"
        h={TOP_BAR_HEIGHT_TOKEN}
        w="full"
        bgColor="white"
        py="2"
        px={{ base: '2', md: '4' }}
        borderBottomStyle="solid"
        borderColor="gray.200"
        borderWidth="px"
        boxShadow="sm"
      >
        {templateRevision && (
          <AutomationsModalWrapper templateRevision={templateRevision} templateType={TemplateType.Form} />
        )}
        <FormConditionalLogicModalWrapper actor={actor} />
        {template && <SendLinkViaEmailModal template={template} />}

        <Flex flex="1" minW="0" justifyContent="flex-start" alignItems="center" overflow="hidden">
          <IconButton
            variant="ghost"
            aria-label="Back"
            {...backProps}
            as={NgLink}
            icon={<Icon icon="arrow-left" size="4" color="gray.500" />}
          />

          <Divider orientation="vertical" color="gray.200" h="10" ml="0" mr="4" />

          <VStack alignItems="flex-start" flex="1" minW="0">
            <HStack
              _hover={{
                '& .chakra-button': {
                  opacity: '1',
                },
              }}
              w="full"
            >
              <Icon icon="ballot" size="4" color="orange.500" variant="fas" />

              {shouldShowEditNameInput ? (
                <Input
                  autoFocus
                  mt="-2"
                  h="6"
                  left="0"
                  top="0"
                  w="full"
                  fontWeight="medium"
                  border="none"
                  p="0"
                  color="gray.700"
                  type="text"
                  minW="4"
                  value={templateName}
                  onChange={e => setTemplateName(e.target.value)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      setIsEditingTemplateName(false);
                      saveTemplate();
                    }
                  }}
                  onBlur={() => {
                    setIsEditingTemplateName(false);
                    saveTemplate();
                  }}
                  _focus={{
                    outline: 'none',
                  }}
                />
              ) : (
                <Text
                  fontWeight="medium"
                  pr={templateName.endsWith(' ') ? '1' : undefined}
                  color="gray.700"
                  minH="6"
                  noOfLines={1}
                  _hover={{
                    cursor: 'text',
                  }}
                  onClick={() => setIsEditingTemplateName(true)}
                >
                  {templateName}
                </Text>
              )}

              {updateTemplateMutation.isLoading && <Spinner size="sm" />}

              <IconButton
                visibility={shouldShowEditNameInput ? 'hidden' : 'visible'}
                minW="unset"
                p="0"
                bgColor="transparent"
                variant="ghost"
                w="5"
                h="5"
                aria-label="Edit template name"
                opacity="0"
                _hover={{ bgColor: 'transparent' }}
                onClick={() => setIsEditingTemplateName(true)}
              >
                <Icon icon="edit" size="3.5" color="gray.500" />
              </IconButton>
            </HStack>

            <Breadcrumbs templateId={template?.id} folderId={template?.folder.id} />
          </VStack>
        </Flex>

        {templateRevision && (
          <Show above="md">
            <TemplateRevisionProvider templateRevision={templateRevision as TemplateRevisionWithTemplate}>
              <Flex width="500px" alignItems="center" justifyContent="center">
                <Breadcrumb
                  aria-label="form builder breadcrumb"
                  spacing="3"
                  separator={<Icon size="3" icon="chevron-right" color="gray.300" display="flex" />}
                >
                  {STEPS.map(step =>
                    match({ step, isViewOnlyForm })
                      .with({ step: { key: P.not('responses') }, isViewOnlyForm: true }, ({ step }) => (
                        <BreadcrumbItem key={step.key}>
                          <Popover trigger="hover" variant="tooltip-dark">
                            <PopoverTrigger>
                              <span>
                                <Button
                                  isDisabled
                                  variant="link"
                                  role="button"
                                  textTransform="uppercase"
                                  fontWeight="500"
                                  fontSize="sm"
                                  color="gray.500"
                                  letterSpacing="0.1em"
                                >
                                  {step.label}
                                </Button>
                              </span>
                            </PopoverTrigger>

                            <PopoverContent w="auto">
                              <PopoverArrow />
                              <PopoverBody>{template && <RequestFormEditPermission template={template} />}</PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </BreadcrumbItem>
                      ))
                      .with({ step: { stateName: P.not(undefined) } }, ({ step }) => {
                        const isCurrentPage = $state.includes(step.stateName) && !isConditionalLogicOpen;
                        const breadcrumbLinkStyles = isCurrentPage && {
                          color: 'brand.500',
                          fontWeight: '600',
                          _hover: { fontWeight: '600', color: 'brand.500' },
                        };

                        return (
                          <BreadcrumbItem key={step.key}>
                            {match(step)
                              .with({ breadcrumbLink: P.not(P.nullish) }, step => (
                                <step.breadcrumbLink
                                  isCurrentPage={isCurrentPage}
                                  href={$state.href(step.stateName, {
                                    id: template?.id,
                                    title: `${abbreviateForTitle(template?.name)}-`,
                                  })}
                                  {...breadcrumbLinkStyles}
                                />
                              ))
                              .otherwise(step => (
                                <FormsBreadcrumbLink
                                  isCurrentPage={isCurrentPage}
                                  href={$state.href(step.stateName, {
                                    id: template?.id,
                                    title: `${abbreviateForTitle(template?.name)}-`,
                                  })}
                                  {...breadcrumbLinkStyles}
                                >
                                  {step.label}
                                </FormsBreadcrumbLink>
                              ))}
                          </BreadcrumbItem>
                        );
                      })
                      .with({ step: { render: P.not(undefined) } }, ({ step }) => (
                        <BreadcrumbItem cursor="pointer" key={step.key}>
                          {step.render(
                            <Button
                              variant="link"
                              role="button"
                              textTransform="uppercase"
                              fontWeight={step.key === 'logic' && isConditionalLogicOpen ? '600' : '500'}
                              fontSize="sm"
                              color={step.key === 'logic' && isConditionalLogicOpen ? 'brand.500' : 'gray.500'}
                              letterSpacing="0.1em"
                              _hover={{ fontWeight: '500', color: 'black' }}
                            >
                              {step.label}
                            </Button>,
                          )}
                        </BreadcrumbItem>
                      ))
                      .otherwise(() => null),
                  )}
                </Breadcrumb>
              </Flex>
            </TemplateRevisionProvider>
          </Show>
        )}

        <Show above="md">
          <HStack flex="1" spacing="2" justifyContent="flex-end">
            {!isViewOnlyForm && (
              <>
                <SaveIndicator />

                <DesignDrawerButton />

                {template && <ShareButton template={template} />}

                <Tooltip shouldWrapChildren label={publishTooltip} isDisabled={!isPublishDisabled} hasArrow>
                  <Button
                    isLoading={isPublishing}
                    isDisabled={isPublishDisabled}
                    loadingText="Publishing"
                    variant="solid"
                    colorScheme="brand"
                    onClick={() => actor.send('PUBLISH')}
                  >
                    {isPublishDisabled ? 'Published' : 'Publish'}
                  </Button>
                </Tooltip>
              </>
            )}

            {!template ? (
              <Skeleton w="10" h="10" />
            ) : (
              <CopyFormShareLink template={template} isViewOnlyForm={isViewOnlyForm} />
            )}
          </HStack>
        </Show>

        <Hide above="md">
          <Menu>
            <MenuButton
              as={IconButton}
              variant="tertiary"
              aria-label="Open menu"
              icon={<Icon icon="bars" variant="far" size="4" color="gray.700" />}
            />
            <MenuList zIndex="dropdown">
              {!$state.includes('form') && (
                <MenuItem
                  as={NgLink}
                  to="form"
                  params={{ id: template?.id, title: template?.name }}
                  icon={<Icon icon="pencil" variant="far" size="4" color="gray.500" />}
                >
                  Build
                </MenuItem>
              )}

              {!$state.includes('formResponses') && (
                <MenuItem
                  as={NgLink}
                  to="formResponses"
                  params={{ id: template?.id, title: template?.name }}
                  icon={<Icon icon="ballot" variant="far" size="4" color="gray.500" />}
                >
                  <ResponsesLabel />
                </MenuItem>
              )}

              {$state.includes('form') && (
                <>
                  <InsertMenuItem />
                  {template && <ShareMenuItem template={template} />}
                  <DesignMenuItem />
                </>
              )}

              {$state.includes('form') && (
                <MenuItem
                  icon={<Icon icon="paper-plane" variant="far" size="4" color="gray.500" />}
                  isDisabled={isPublishDisabled || isPublishing}
                  onClick={() => actor.send('PUBLISH')}
                  closeOnSelect={false}
                >
                  {match({ isPublishDisabled, isPublishing })
                    .with({ isPublishing: true }, () => 'Publishing')
                    .with({ isPublishDisabled: true }, () => 'Published')
                    .otherwise(() => 'Publish')}
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Hide>

        {/* Box to offset the hstack child margin */}
        <Box>
          <UpgradePlanAlert
            position="absolute"
            top={TOP_BAR_HEIGHT_CSS_VAR}
            left="0"
            w="full"
            mode="flash"
            h={{ base: undefined, sm: '8' }}
            justifyContent="center"
            zIndex="1"
            borderRadius="0"
            borderBottom="1px solid var(--ps-colors-gray-200)"
            boxShadow="sm"
          />
        </Box>
      </HStack>

      {templateRevision && template && <DesignDrawer templateRevision={templateRevision} template={template} />}
    </>
  );
};
