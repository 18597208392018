import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { AutomationAppName } from '@process-street/subgrade/automation';
import {
  Box,
  Button,
  ButtonProps,
  Text,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
  Spinner,
  ScaleFade,
} from 'components/design/next';
import noop from 'lodash/noop';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useAutomationAppButton } from './use-automation-app-button';
import { AutomationApp } from '../../automation-app';

export type Props = {
  automationApp: AutomationAppName;
};

const UPGRADE_ROUTE = '/organizations/manage/billing';

export const AutomationAppButton: React.FC<React.PropsWithChildren<Props>> = ({ automationApp }) => {
  const { automationIsPremium, hasAccess, isDisabled, isSelected, setAutomationApp, status } = useAutomationAppButton({
    automationApp,
  });

  const premiumProps: ButtonProps = automationIsPremium
    ? { rightIcon: <Box color="gray.400" as={FontAwesomeIcon} icon={faStar} /> }
    : {};
  const hasAccessProps: ButtonProps = hasAccess
    ? { onClick: () => setAutomationApp(automationApp) }
    : {
        onClick: noop,
        _hover: { cursor: 'default' },
        bgColor: 'gray.200',
      };

  return (
    <Box>
      <Popover trigger="hover" aria-label={`${automationApp} automation tooltip`} variant="tooltip-dark">
        <PopoverTrigger>
          <span tabIndex={0}>
            <Button
              fontWeight="normal"
              isDisabled={isDisabled}
              aria-disabled={isDisabled || !hasAccess}
              variant={isSelected ? 'secondary' : 'tertiary'}
              isLoading={status === 'loading'}
              spinner={<Spinner color="gray.600" />}
              aria-selected={isSelected}
              {...premiumProps}
              {...hasAccessProps}
              position="relative"
            >
              <AutomationApp automationApp={automationApp} />
              <Box lineHeight="none" position="absolute" transform="translate(25%, -25%)" top="0" right="0">
                <ScaleFade initialScale={0.5} in={isSelected}>
                  <Box lineHeight="none" as={FontAwesomeIcon} color="brand.500" icon={faCheckCircle as IconProp} />
                </ScaleFade>
              </Box>
            </Button>
          </span>
        </PopoverTrigger>

        {hasAccess ? null : (
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Text fontWeight="medium" fontSize="xs">
                  <Link href={UPGRADE_ROUTE}>Upgrade</Link> account to use this premium automation.
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        )}
      </Popover>
    </Box>
  );
};
