import React from 'react';
import { GetAllDataSetsQuery } from 'pages/reports/data-sets/query-builder';
import { Muid, Option } from '@process-street/subgrade/core';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { OnChangeValue } from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { DataSet } from '@process-street/subgrade/process';

export type DataSetSelectorProps = {
  dataSetId?: Muid;
  onChange: (dataSetId: Option<Muid>) => void;
};

export type DataSetSelectorOptionType = { dataSet?: DataSet; label: string; value?: string };

export const DataSetSelector: React.FC<React.PropsWithChildren<DataSetSelectorProps>> = ({ dataSetId, onChange }) => {
  const dataSetsQuery = GetAllDataSetsQuery.useQuery();

  const options: DataSetSelectorOptionType[] = (dataSetsQuery.data ?? []).map(dataSet => ({
    label: dataSet.name,
    value: dataSet.id,
    dataSet,
  }));

  const selectedOption = options.find(o => o.value === dataSetId);

  const handleChange = (value: OnChangeValue<DataSetSelectorOptionType, false>) => {
    if (BlvdSelectHelpers.isOptionType<DataSetSelectorOptionType>(value)) {
      onChange(value.value);
    }
  };

  return <BlvdSelect options={options} value={selectedOption} onChange={handleChange} isSearchable />;
};
