import { CoverImage } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type FinishUploadCoverImageMutationParams = {
  templateId: Template['id'];
  key: string;
  originalFilename: string;
  contentType: string;
};

export type FinishUploadCoverImageMutationResponse = CoverImage;

export const FinishUploadCoverImageMutation = {
  key: ['cover-image', 'finish-upload'],
  mutationFn: (params: FinishUploadCoverImageMutationParams) =>
    axiosService
      .getAxios()
      .post<FinishUploadCoverImageMutationResponse>(
        `/1/templates/${params.templateId}/cover-image/finish-upload`,
        params,
      )
      .then(res => res.data),
};

export const useFinishUploadCoverImageMutation = (
  options: UseMutationOptions<
    FinishUploadCoverImageMutationResponse,
    AxiosError,
    FinishUploadCoverImageMutationParams
  > = {},
) => {
  return useMutation(FinishUploadCoverImageMutation.mutationFn, {
    mutationKey: FinishUploadCoverImageMutation.key,
    ...options,
  });
};
