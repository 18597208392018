import * as React from 'react';
import { Input, Radio, RadioGroup, FormControl, FormLabel, Checkbox, Text, HStack } from 'components/design/next';
import { SettingsModalFields } from '../common/settings/fields';
import { numberSettingsSchema } from './number-form-field-schema';
import { Field, FieldProps } from 'formik';
import {
  DigitsInfoPopover,
  ValueInfoPopover,
} from 'components/widgets/form-field/settings/number-settings/info-popovers';
import { BlvdSelect, BlvdSelectHelpers } from 'components/design/BlvdSelect';
import { TemplateRevision, TemplateType } from '@process-street/subgrade/process';

export type NumberFormFieldSettingsFieldsProps = {
  templateRevisionId: TemplateRevision['id'];
  templateType: TemplateType;
};
export const NumberFormFieldSettingsFields: React.FC<React.PropsWithChildren<NumberFormFieldSettingsFieldsProps>> = ({
  templateRevisionId,
  templateType,
}) => {
  return (
    <>
      <SettingsModalFields.MinMax<typeof numberSettingsSchema>
        minFieldName="constraints.minValue"
        maxFieldName="constraints.maxValue"
        unit="value"
      >
        <SettingsModalFields.MinMaxLabel>
          <HStack>
            <Text>Value</Text>
            <ValueInfoPopover />
          </HStack>
        </SettingsModalFields.MinMaxLabel>
      </SettingsModalFields.MinMax>

      <SettingsModalFields.MinMax<typeof numberSettingsSchema>
        minFieldName="constraints.minDigits"
        maxFieldName="constraints.maxDigits"
        unit="digits"
      >
        <SettingsModalFields.MinMaxLabel>
          <HStack>
            <Text>Digits</Text>
            <DigitsInfoPopover />
          </HStack>
        </SettingsModalFields.MinMaxLabel>
      </SettingsModalFields.MinMax>

      <Field name="constraints.decimalPlaces">
        {({ field, meta, form }: FieldProps) => {
          const options = [
            { value: 1, label: '1 decimal' },
            { value: 2, label: '2 decimals' },
            { value: 3, label: '3 decimals' },
          ];
          const optionsMap = new Map(options.map(o => [o.value, o]));

          return (
            <FormControl>
              <FormLabel>
                <Text variant="1" fontWeight="medium" color="gray.700">
                  Decimal places
                </Text>
              </FormLabel>
              <BlvdSelect
                options={options}
                isClearable
                value={Number.isNaN(field.value) ? null : optionsMap.get(field.value)}
                placeholder="Decimals not allowed"
                isInvalid={meta.touched && !!meta.error}
                onChange={option => {
                  if (BlvdSelectHelpers.isOptionType<{ value: number; label: string }>(option)) {
                    form.setFieldValue(field.name, option.value);
                  } else {
                    form.setFieldValue(field.name, undefined);
                  }
                }}
              />
            </FormControl>
          );
        }}
      </Field>

      <Field name="constraints.allowNegative">
        {({ field }: FieldProps) => (
          <FormControl display="flex" alignItems="center">
            <Checkbox {...field} isChecked={field.value} spacing="2">
              Allow negative numbers
            </Checkbox>
          </FormControl>
        )}
      </Field>

      <Field name="config.unit">
        {({ field, meta, form }: FieldProps) => (
          <>
            <FormControl>
              <FormLabel>Unit</FormLabel>
              <Input
                {...field}
                value={field.value ?? ''}
                id="unit"
                maxLength={3}
                aria-label="unit"
                placeholder="%, $, €, £, °F, °C, 📞,..."
                variant="outline"
                isInvalid={meta.touched && !!meta.error}
              />
            </FormControl>

            {field.value ? (
              <Field name="config.unitLocation">
                {({ field: unitLocationField, meta: unitLocationMeta }: FieldProps) => {
                  if (!unitLocationField.value) {
                    form.setFieldValue(unitLocationField.name, 'prefix');
                  }
                  return (
                    <FormControl isInvalid={unitLocationMeta.touched && !!unitLocationMeta.error}>
                      <FormLabel>Unit location</FormLabel>
                      <RadioGroup
                        id="unitLocation"
                        defaultValue="prefix"
                        aria-label="unit location"
                        {...unitLocationField}
                      >
                        <HStack spacing="4">
                          <Radio onChange={unitLocationField.onChange} value="prefix">
                            Prefix
                          </Radio>
                          <Radio onChange={unitLocationField.onChange} value="suffix">
                            Suffix
                          </Radio>
                        </HStack>
                      </RadioGroup>
                    </FormControl>
                  );
                }}
              </Field>
            ) : null}
          </>
        )}
      </Field>

      <SettingsModalFields.Placeholder label="Number placeholder" />
      {templateType === TemplateType.Playbook && (
        <SettingsModalFields.DefaultValue templateRevisionId={templateRevisionId} />
      )}
    </>
  );
};
