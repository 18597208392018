import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type PublicizeTemplateMutationParams = { templateId: Template['id'] };

export type PublicizeTemplateMutationResponse = Template;

export const PublicizeTemplateMutation = {
  key: ['put', 'templates', 'public'],
  mutationFn: (params: PublicizeTemplateMutationParams) =>
    axiosService
      .getAxios()
      .put<PublicizeTemplateMutationResponse>(`/1/templates/${params.templateId}/public`, {
        id: params.templateId,
        public: true,
      })
      .then(res => res.data),
};

export const usePublicizeTemplateMutation = (
  options: UseMutationOptions<PublicizeTemplateMutationResponse, AxiosError, PublicizeTemplateMutationParams> = {},
) => {
  return useMutation(PublicizeTemplateMutation.mutationFn, options);
};
