import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateTemplateMutationParams = {
  templateId: Template['id'];
} & Partial<
  Pick<
    Template,
    'name' | 'description' | 'checklistCommentsEnabled' | 'checklistShareLinkEnabled' | 'preferredTriggers'
  >
>;

export type UpdateTemplateMutationResponse = Template;

export const UpdateTemplateMutation = {
  key: ['put', 'templates'],
  mutationFn: ({ templateId, ...params }: UpdateTemplateMutationParams) =>
    axiosService
      .getAxios()
      .put<UpdateTemplateMutationResponse>(`/1/templates/${templateId}`, params)
      .then(res => res.data),
};

export function useUpdateTemplateMutation<Context = unknown>(
  options: UseMutationOptions<UpdateTemplateMutationResponse, AxiosError, UpdateTemplateMutationParams, Context> = {},
) {
  return useMutation(UpdateTemplateMutation.mutationFn, options);
}
