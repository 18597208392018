import * as React from 'react';
import { Button, Flex } from 'components/design/next';
import { useScheduleStore } from './query';

export const TablePagination = () => {
  const { hasMoreResults, showMore } = useScheduleStore();

  return (
    <Flex align="center" justify="space-between">
      <Button variant="secondary" ml="auto" onClick={showMore} isDisabled={!hasMoreResults}>
        Show more
      </Button>
    </Flex>
  );
};
