import angular from 'angular';
import { SentryService } from 'components/sentry/sentry.service';

angular.module('frontStreetApp.exceptionOverwrite', []).factory(
  '$exceptionHandler',
  $log =>
    function exceptionHandler(exception, cause) {
      SentryService.captureException(exception, {
        extra: { cause },
      });
      $log.error(exception);
    },
);
