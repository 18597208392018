import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';

export const useIsCurrentUserFreeMember = () => {
  const currentUser = useGetCurrentUserInfoQuery();

  return (
    Boolean(currentUser.data) && currentUser.data!.organizationMembership.role === OrganizationMembershipRole.FreeMember
  );
};
