import * as React from 'react';
import {
  Box,
  ButtonProps,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from 'components/design/next';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useAdminEmails } from 'components/paywalls/use-admin-emails';
import { mailto } from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { AppModalName, AppModalQueryParam, GlobalSearchVariant } from 'app/app.constants';
import { useRoleNames } from 'hooks/use-role-names';
import { CreateChecklistSource } from 'services/checklist-service.interface';
import { useCanRunWorkflows } from 'hooks/use-can-run-workflows';
import { AnalyticsService } from 'components/analytics/analytics.service';

export const RunButton: React.FC<
  React.PropsWithChildren<{ children: React.ReactElement<ButtonProps>; source: CreateChecklistSource }>
> = ({ children, source }) => {
  const { $state } = useInjector('$state');
  const roleNames = useRoleNames();

  const handleRunChecklistClick = React.useCallback(() => {
    AnalyticsService.trackEvent('run checklist clicked', { location: source });
    $state.go(
      $state.current,
      {
        ...$state.params,
        ...{
          [AppModalQueryParam.Modal]: AppModalName.GlobalSearch,
          [AppModalQueryParam.ModalVariant]: GlobalSearchVariant.Run,
        },
      },
      { inherit: false },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
  }, []);

  const currentUser = useSelector(SessionSelector.getCurrentUser);
  const canRunWorkflows = useCanRunWorkflows();

  const runButtonDisabled = !canRunWorkflows;
  const [adminEmail, ...bcc] = useAdminEmails();

  const href = mailto({
    to: adminEmail,
    subject: `Someone on your team is requesting an upgrade on your Process Street plan`,
    body: `A ${roleNames.FreeMember.single} on your Process Street account${
      currentUser?.username ? `, ${currentUser.username}, ` : ''
    }would like you to upgrade them to a ${roleNames.FullMember.single}. ${
      roleNames.FullMember.plural
    } can create and run workflows.\n\nUpgrade here: https://app.process.st/organizations/manage/billing`,
    bcc,
  });

  if (!React.isValidElement(children)) {
    throw new Error('Invalid children for RunButton');
  }

  const btn = React.useMemo(
    () => React.cloneElement(children, { isDisabled: runButtonDisabled, onClick: handleRunChecklistClick }),
    [children, handleRunChecklistClick, runButtonDisabled],
  );

  if (runButtonDisabled) {
    // HACK: disabled buttons cause tricky behavior with popovers because it affects the cursor events property
    // Here we render an empty div over the button when it is disabled.
    return (
      <Box position="relative">
        {btn}

        <Popover trigger="hover" size="auto" variant="tooltip-dark">
          <PopoverTrigger>
            <Box w="full" top="0" left="0" h="full" position="absolute" />
          </PopoverTrigger>
          <PopoverContent w="auto">
            <PopoverArrow />
            <PopoverBody>
              <Link href={href} isExternal>
                Contact your admin
              </Link>{' '}
              for access.
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    );
  }

  return btn;
};
