import * as React from 'react';
import { Button, FormControl, FormLabel, Grid, GridItem, HStack, Input, Skeleton } from 'components/design/next';
import { useGetOrganizationQuery } from 'features/organization/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { isUndefined } from '@process-street/subgrade/core';

export type PartnerKeyFieldProps = {
  onSave: (params: { growSumoPartnerKey?: string }) => void;
  isLoading: boolean;
};

export const PartnerKeyField: React.FC<React.PropsWithChildren<PartnerKeyFieldProps>> = ({ onSave, isLoading }) => {
  const currentOrganization = useSelector(SessionSelector.getSelectedOrganization);

  const [partnerKey, setPartnerKey] = React.useState<string>();

  const organizationQuery = useGetOrganizationQuery({ organizationId: currentOrganization?.id });

  React.useEffect(() => {
    if (isUndefined(partnerKey) && organizationQuery.data) {
      setPartnerKey(organizationQuery.data.growSumoPartnerKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
  }, [organizationQuery.data]);

  return (
    <FormControl as={Grid} templateColumns={{ base: '1fr', md: '220px 1fr' }}>
      <GridItem display="flex" alignItems="center">
        <FormLabel color="gray.600" as="label">
          Partner Key
        </FormLabel>
      </GridItem>
      <GridItem>
        <HStack>
          <Skeleton w="full" isLoaded={!organizationQuery.isLoading}>
            <Input
              value={partnerKey ?? ''}
              onChange={e => setPartnerKey(e.target.value)}
              placeholder="e.g. brucewayne-42"
            />
          </Skeleton>
          <Button
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.300"
            px="4"
            h="10"
            size="sm"
            variant="solid"
            colorScheme="gray"
            onClick={() => onSave({ growSumoPartnerKey: partnerKey ?? undefined })}
            aria-label="Save partner key"
            isDisabled={
              (!partnerKey && !organizationQuery.data?.growSumoPartnerKey) ||
              organizationQuery.data?.growSumoPartnerKey === partnerKey ||
              isLoading
            }
          >
            Save
          </Button>
        </HStack>
      </GridItem>
    </FormControl>
  );
};
