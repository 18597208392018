import { Text } from '@chakra-ui/react';
import React from 'react';
import { AxiosResponse } from 'axios';
import { HttpStatus } from '@process-street/subgrade/util';
import { OrganizationMembershipErrorCodes } from '@process-street/subgrade/core';

export const DefaultErrorMessages = {
  unexpectedErrorDescription: (
    <Text>
      We have been notified about the issue. Please try again and if the problem persists reach out to our support team.
    </Text>
  ),
  notFoundErrorDescription: (
    <Text>
      Please confirm the address is correct and try again. If the problem persists reach out to our support team.
    </Text>
  ),
  permissionErrorTitle: (resource: string) => <Text>You don't have permission to view the {resource}</Text>,
  permissionErrorDescription: (resource: string): React.ReactNode => (
    <Text>Please reach out to the {resource} owner or an admin to request access.</Text>
  ),
  getUserInactiveDescription: (email: string, response: AxiosResponse) => {
    const isUserInactive =
      response.status === HttpStatus.BAD_REQUEST &&
      response.data?.code === OrganizationMembershipErrorCodes.UserInactive;
    return isUserInactive
      ? `The user ${email} has been deactivated in the organization. `
      : DefaultErrorMessages.unexpectedErrorDescription;
  },
};

export const AuthInternalErrors = {
  generalError: '[1] Unauthorized access, redirecting...',
  formResponseLoginRequired: '[2] Unauthorized access. Redirecting.',
};
