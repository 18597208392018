import { Template } from '@process-street/subgrade/process';
import { Button, Icon } from 'components/design/next';
import { NgLink } from 'features/app/components/ng-link';
import * as React from 'react';

interface RespondFormProps {
  template: Template;
}

export const RespondForm: React.FC<React.PropsWithChildren<RespondFormProps>> = ({ template }) => {
  return (
    <Button
      as={NgLink}
      to="addFormResponse"
      params={{ id: template.id }}
      isExternal
      size="sm"
      variant="ghost"
      leftIcon={<Icon icon="play" size="3" color="gray.600" />}
      aria-label={`Respond ${template.name}`}
      colorScheme="gray"
      color="gray.600"
      backgroundColor="white"
      borderWidth="thin"
      borderColor="gray.300"
      borderStyle="solid"
      fontWeight="normal"
      fontSize="xs"
      _hover={{
        textDecoration: 'unset',
        color: 'gray.600',
        backgroundColor: 'gray.100',
      }}
    >
      Respond
    </Button>
  );
};
