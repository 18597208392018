import React from 'react';
import { IDatasource, IGetRowsParams } from '@ag-grid-community/core';
import {
  GetFormResponsesSearchQuery,
  GetFormResponsesSearchQueryParams,
  GetFormResponsesSearchQueryResponse,
} from 'features/forms/query-builder';
import { useStateParam } from 'hooks/use-state-param';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { FormResponsesStoreSelectors, useFormResponsesStore } from '../../store';
import { Muid } from '@process-street/subgrade/core';
import { useQueryClient } from 'react-query';
import {
  GetChecklistGridFormFieldDataQuery,
  GetChecklistGridFormFieldDataQueryParams,
} from 'features/checklist-grid/query-builder';
import { GetTemplateSchemaQuery } from 'features/template/query-builder';
import {
  FormFieldDataRow,
  FormFieldValueColumnField,
  isFormFieldValueColumnDef,
} from '@process-street/subgrade/dashboard';
import { GridHelper } from 'components/dashboard/services/grid-helper';
import { TemplateType } from '@process-street/subgrade/process';

export function useFormResponsesDatasource(): IDatasource {
  const formId = useStateParam({ key: 'id' })!;
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const conditionalFilter = useFormResponsesStore(FormResponsesStoreSelectors.getConditionalFilter);
  const { sortAsc, sortBy } = useFormResponsesStore(FormResponsesStoreSelectors.getSorting) ?? {};
  const offsetIdRef = React.useRef<Muid>();

  const queryClient = useQueryClient();

  return React.useMemo(() => {
    return {
      getRows: async (getRowParams: IGetRowsParams): Promise<void> => {
        const isRefresh = getRowParams.startRow === 0;

        if (isRefresh) {
          offsetIdRef.current = undefined;
        }

        const pageSize = getRowParams.endRow - getRowParams.startRow;
        const params: GetFormResponsesSearchQueryParams = {
          organizationId,
          searchCriteria: {
            templateIds: [formId],
            // add 1 to the page size to determine if there are more rows
            pageSize: pageSize + 1,
            offsetId: offsetIdRef.current,
            filter: conditionalFilter ? [conditionalFilter] : [],
            sortAsc,
            sortBy,
            templateTypes: [TemplateType.Form],
          },
        };

        const rowsPlusOne = await queryClient
          .fetchQuery({
            queryKey: GetFormResponsesSearchQuery.getKey(params),
            queryFn: () => GetFormResponsesSearchQuery.queryFn(params),
          })
          .catch(() => {
            getRowParams.failCallback();
            return [] as GetFormResponsesSearchQueryResponse;
          });

        const hasMore = rowsPlusOne.length > pageSize;
        const rows = hasMore ? rowsPlusOne.slice(0, rowsPlusOne.length - 1) : rowsPlusOne;

        offsetIdRef.current = hasMore ? (rows[rows.length - 1].id as Muid) : undefined;

        const lastRow = hasMore ? undefined : getRowParams.startRow + rows.length;
        getRowParams.successCallback(rows, lastRow);

        if (formId && rows.length > 0) {
          const colDefsByWidgetGroupId = await queryClient
            .fetchQuery({
              queryKey: GetTemplateSchemaQuery.getKey({ templateId: formId }),
              queryFn: () => GetTemplateSchemaQuery.queryFn({ templateId: formId }),
              staleTime: Infinity,
            })
            .then(result =>
              result.columns
                .filter(isFormFieldValueColumnDef)
                .map(columnDef => GridHelper.formFieldColumnDefToColumnDto(columnDef))
                .reduce((acc, column) => {
                  const widgetGroupId = column.widgetGroupId!;
                  acc[widgetGroupId] = !acc[widgetGroupId] ? [column.field] : [column.field, ...acc[widgetGroupId]];
                  return acc;
                }, {} as Record<Muid, FormFieldValueColumnField[]>),
            );

          const widgetGroupIds = Object.keys(colDefsByWidgetGroupId);
          const checklistRevisionIds = rows.map(r => r.checklistRevisionId);

          const ffvParams: GetChecklistGridFormFieldDataQueryParams = {
            organizationId,
            templateId: formId,
            checklistRevisionIds,
            widgetGroupIds,
          };
          const formFieldValuesByChecklistRevisionId = await queryClient
            .fetchQuery({
              queryKey: GetChecklistGridFormFieldDataQuery.getKey(ffvParams),
              queryFn: () => GetChecklistGridFormFieldDataQuery.queryFn(ffvParams),
            })
            .then(result =>
              result.reduce((acc, row) => {
                acc[row.checklistRevisionId] = row;
                return acc;
              }, {} as Record<string, FormFieldDataRow>),
            );
          if (Object.keys(formFieldValuesByChecklistRevisionId).length === 0) return;

          // BEWARE mutating rows elementns in place
          rows.forEach(row => {
            const formFieldValueRow = formFieldValuesByChecklistRevisionId[row.checklistRevisionId];

            formFieldValueRow?.formFieldValues.forEach(formFieldValues => {
              const fields = colDefsByWidgetGroupId[formFieldValues.widgetGroupId];
              fields?.forEach(field => {
                row[field] = formFieldValues;
              });
            });
          });
          getRowParams.context.gridRef.current?.api?.refreshCells();
        }
      },
    };
  }, [conditionalFilter, formId, organizationId, queryClient, sortAsc, sortBy]);
}
