import * as React from 'react';
import { PlanInterval } from '@process-street/subgrade/billing';
import { Box, Flex, RadioGroup } from 'components/design/next';
import { IntervalToPlanCostInfoMap } from 'pages/organizations/manage/billing/models/models';
import { IntervalCard } from 'pages/organizations/manage/billing/components/interval-selector/IntervalCard';

export type IntervalSelectorProps = {
  selectedInterval: PlanInterval;
  costMap: IntervalToPlanCostInfoMap;
  onChange: (interval: PlanInterval) => void;
};

export const IntervalSelector: React.FC<React.PropsWithChildren<IntervalSelectorProps>> = ({
  selectedInterval,
  costMap,
  onChange,
}) => {
  const handleChange = (nextValue: string) => {
    onChange(nextValue as PlanInterval);
  };

  const yearlyCostInfo = costMap[PlanInterval.Yearly];
  const monthlyCostInfo = costMap[PlanInterval.Monthly];

  return (
    <RadioGroup onChange={handleChange} value={selectedInterval}>
      <Flex>
        {yearlyCostInfo && (
          <IntervalCard
            planInterval={PlanInterval.Yearly}
            selected={selectedInterval === PlanInterval.Yearly}
            planCostInfo={yearlyCostInfo}
          />
        )}

        <Box w={4} />

        {monthlyCostInfo && (
          <IntervalCard
            planInterval={PlanInterval.Monthly}
            selected={selectedInterval === PlanInterval.Monthly}
            planCostInfo={monthlyCostInfo}
          />
        )}
      </Flex>
    </RadioGroup>
  );
};
