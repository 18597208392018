import { isPartialUrl } from '@process-street/subgrade/process';
import {
  GridItem,
  HStack,
  Input,
  Button,
  VStack,
  FormErrorMessage,
  Grid,
  FormControl,
  FormLabel,
  Skeleton,
} from 'components/design/next';
import { useGetOrganizationQuery } from 'features/organization/query-builder';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { isUndefined } from '@process-street/subgrade/core';

export type NameFieldProps = {
  onSave: (params: { name: string }) => void;
  isLoading?: boolean;
};

export const NameField: React.FC<React.PropsWithChildren<NameFieldProps>> = ({ onSave, isLoading }) => {
  const currentOrganization = useSelector(SessionSelector.getSelectedOrganization);

  const [name, setName] = React.useState<string>();

  const organizationQuery = useGetOrganizationQuery({ organizationId: currentOrganization?.id });

  React.useEffect(() => {
    if (isUndefined(name) && organizationQuery.data) {
      setName(organizationQuery.data.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
  }, [organizationQuery.data]);

  const nameError = React.useMemo(() => {
    if (!name) return null;
    if (name.length === 0) return 'The organization name must have at least one character.';
    if (name.length > 256) return 'The organization name can not have more than 256 characters.';
    if (isPartialUrl(name)) return 'The organization name can not be an URL.';

    return null;
  }, [name]);

  return (
    <FormControl as={Grid} templateColumns={{ base: '1fr', md: '220px 1fr' }} isInvalid={Boolean(nameError)}>
      <GridItem display="flex" alignItems="center">
        <FormLabel color="gray.600" as="label">
          Name
        </FormLabel>
      </GridItem>
      <GridItem>
        <HStack w="full">
          <VStack w="full" alignItems="flex-start">
            <Skeleton w="full" isLoaded={!organizationQuery.isLoading}>
              <Input
                type="text"
                required
                value={name ?? ''}
                onChange={e => setName(e.target.value)}
                isInvalid={Boolean(nameError)}
              />
              <FormErrorMessage>{nameError}</FormErrorMessage>
            </Skeleton>
          </VStack>

          <Button
            alignSelf="flex-start"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.300"
            px="4"
            h="10"
            size="sm"
            variant="solid"
            colorScheme="gray"
            isDisabled={Boolean(nameError) || organizationQuery.data?.name === name || isLoading}
            onClick={() => name && onSave({ name })}
            aria-label="Save organization name"
          >
            Save
          </Button>
        </HStack>
      </GridItem>
    </FormControl>
  );
};
