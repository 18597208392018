import { TraySolutionInstance } from './tray-solution-instance';
import { Muid } from '../core';
import { UnsafeTrayAutomationAppName } from '.';

export interface TrayCreateSolutionInstanceResponse {
  data: {
    createSolutionInstance: {
      solutionInstance: TraySolutionInstance;
    };
  };
}

export interface TrayUpdateSolutionInstanceResponse {
  data: {
    updateSolutionInstance: {
      solutionInstance: TraySolutionInstance;
    };
  };
}

export interface TrayAuthorizeConfigResponse {
  authorizationCode: string;
}

export enum AutomationLogStatus {
  Success = 'Success',
  Failure = 'Failure',
  Processing = 'Processing',
}

export interface AutomationLog {
  id: Muid;
  eventDate: number;
  templateSolutionInstanceId: Muid;
  status: AutomationLogStatus;
  error?: string;
}

export interface ExecutionStatus {
  id: Muid;
  eventDate: number;
  taskId?: Muid;
  status?: AutomationLogStatus;
  errorMessage?: string;
  automationApp: UnsafeTrayAutomationAppName;
}
