import { DerivedTaskStatByUser, DerivedTaskStatus as Status } from '@process-street/subgrade/dashboard';
import { makeTooltipContext } from '../chart-tooltip';
import { BarStackBar, LegendStat } from '../common/types';

export type TaskStat = DerivedTaskStatByUser;

export type TaskStatusBar = BarStackBar<TaskStat, Status>;

export type TooltipData = TaskStatusBar | LegendStat<Status> | { y: number };

const {
  tooltipReducer,
  makeInitialTooltipState,
  usableTooltipContext: usableContext,
} = makeTooltipContext<TooltipData>();

export { tooltipReducer, makeInitialTooltipState };

export const [useTooltipEffect, TooltipEffectContext] = usableContext;
