import { toArray } from 'reducers/util/index';
import { createAction } from 'redux-actions';
import { entitiesSelector } from 'reducers/entities/entities.selectors';

export const EntitiesCollectionActions = (collection, putActionName, deleteActionName) => {
  const putAction = createAction(putActionName, element => toArray(element).map(el => (el.toJSON ? el.toJSON() : el)));

  const deleteAction = createAction(
    deleteActionName,
    id => id,
    (id, element) => ({
      element,
      id,
    }),
  );

  const putToReduxDataStore = element => dispatch => {
    // Some of the methods add Promise to the DataStore, which should not happen
    if (element.$promise) {
      if (element.$resolved) {
        return dispatch(putAction(element));
      } else {
        return element.$promise.then(res => dispatch(putAction(res)));
      }
    } else {
      return dispatch(putAction(element));
    }
  };

  const removeFromReduxDataStore = id => (dispatch, getState) => {
    const element = entitiesSelector(getState())[collection][id];
    return dispatch(deleteAction(id, element));
  };

  return {
    putToReduxDataStore,
    removeFromReduxDataStore,
  };
};
