import React from 'react';
import { GroupBase, SingleValueProps } from 'react-select';
import { Icon, IconSize, IconType } from 'components/design/Icon';
import { Text } from 'components/design/next';
import { useToken } from '@chakra-ui/react';

export const SingleValue = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SingleValueProps<Option, IsMulti, Group>,
) => {
  const { children, data } = props;
  const icon = props.selectProps.getOptionIcon?.<Option, IconType>(data);
  const [fontMd] = useToken('fontSizes', ['md']);

  return (
    <div className={'blvd-select__single-value'} title={props.selectProps.getOptionLabel(data)}>
      {icon && <Icon size={IconSize.Normal} iconType={icon} className={'blvd-select__single-value__icon'} />}
      <Text as="span" fontSize={fontMd} className={'blvd-select__ellipsis'}>
        {children}
      </Text>
    </div>
  );
};
