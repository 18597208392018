import {
  GroupedOrganizationNotificationConfig,
  OrganizationNotificationConfigKeys,
} from '@process-street/subgrade/core/organization-notifications/organization-notification-config';
import { Box, Divider, FormControl, FormLabel, Switch, Text, VStack } from 'components/design/next';
import {
  GetOrganizationNotificationConfigQuery,
  UpdateOrganizationNotificationConfigMutation,
} from 'features/organization/query-builder';
import { GetNotificationConfigQuery } from 'features/user/query-builder';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

export const OrganizationNotifications: React.FC<React.PropsWithChildren<unknown>> = () => {
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const queryClient = useQueryClient();

  const { data } = GetOrganizationNotificationConfigQuery.useQuery({ organizationId });

  const updateConfigMutation = UpdateOrganizationNotificationConfigMutation.useMutation({
    onMutate: params => {
      queryClient.setQueryData(GetOrganizationNotificationConfigQuery.getKey({ organizationId }), params.config);
      queryClient.invalidateQueries(GetNotificationConfigQuery.getKey({}));
    },
    onError: _ => {
      data
        ? queryClient.setQueryData(GetOrganizationNotificationConfigQuery.getKey({ organizationId }), data)
        : queryClient.invalidateQueries(GetOrganizationNotificationConfigQuery.getKey({ organizationId }));
    },
  });

  const handleUpdate = (key: OrganizationNotificationConfigKeys, e: React.ChangeEvent<HTMLInputElement>) => {
    if (data) {
      const enabled = e.target.checked;
      const config = {
        ...data,
        [key]: key === 'emailsAllowed' ? !enabled : enabled,
      };

      updateConfigMutation.mutate({
        organizationId,
        config,
      });
    }
  };

  return (
    <VStack alignItems="start">
      <Text color="gray.600">Set the default notifications for new users you invite to your organization.</Text>
      <Box w="full" pt="2">
        {GroupedOrganizationNotificationConfig.map(([title, configs]) => (
          <VStack pt="5" alignItems="start" justifyContent="space-between" w="full" key={title}>
            <Text variant="-2u" color="gray.400">
              {title}
            </Text>
            {configs.map(([key, label]) => {
              const checked = data?.[key] ?? false;
              const isChecked = key === 'emailsAllowed' ? !checked : checked;
              const shouldBeDisabledAndOff = key !== 'emailsAllowed' ? !data?.emailsAllowed : false;
              return (
                <FormControl display="flex" justifyContent="space-between" key={key}>
                  <FormLabel htmlFor={key} color="gray.500">
                    {label}
                  </FormLabel>
                  <Switch
                    isDisabled={!data || shouldBeDisabledAndOff}
                    pt="0.5"
                    size="lg"
                    isChecked={shouldBeDisabledAndOff ? false : isChecked}
                    id={key}
                    onChange={e => handleUpdate(key, e)}
                  />
                </FormControl>
              );
            })}
            <Divider pt="3" />
          </VStack>
        ))}
      </Box>
    </VStack>
  );
};
