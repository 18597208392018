import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react';
import React from 'react';

export const useToast = () => {
  const chakraToast = useChakraToast();

  // Refresh if root toast changes (related to custom brand)
  return React.useMemo(
    () =>
      Object.assign(
        (props?: UseToastOptions): ReturnType<typeof chakraToast> =>
          chakraToast({
            position: 'top',
            isClosable: true,
            duration: 5000,
            variant: 'subtle',
            ...props,
          }),
        chakraToast, // Object.assign leaves out the "call" part
      ),
    [chakraToast],
  );
};
