import * as React from 'react';
import { OneOffTaskDrawer } from 'features/one-off-tasks/components/shared';

export const DrawerContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <OneOffTaskDrawer />
    </>
  );
};
