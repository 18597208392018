import * as React from 'react';
import { useBalloonToolbarControls } from '../context';
import { useSelectionContext } from '../../../selection-context';
import { select } from '@udecode/slate';
import { usePagesEditorState } from '../../../pages-plate-types';

type UseToolbarMenuItemOnClickConfig = {
  preventForcedStateUpdate?: boolean;
};

export const useToolbarMenuItemOnClick = (config: UseToolbarMenuItemOnClickConfig = {}) => {
  const { lastSelection } = useSelectionContext();
  const { setForcedState } = useBalloonToolbarControls();
  const editor = usePagesEditorState();
  return React.useCallback(
    (cb: () => void) => {
      if (!editor.selection && lastSelection) {
        select(editor, lastSelection);
      }
      cb();

      if (!config?.preventForcedStateUpdate) setForcedState('none');
    },
    [config?.preventForcedStateUpdate, editor, lastSelection, setForcedState],
  );
};
