import { Muid, PaginatedResponse } from '@process-street/subgrade/core';
import { TemplatePermit } from '@process-street/subgrade/permission';
import { createRequestAction, createSuccessAction } from '@process-street/subgrade/redux/action-creator';
import { SuccessAction } from '@process-street/subgrade/redux/types';
import { AxiosResponse } from 'axios';
import { FlashActions } from 'reducers/flash/flash.actions';
import { ReduxAppState } from 'reducers/types';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { axiosService } from 'services/axios-service';

export const TEMPLATE_PERMITS_GET_ALL_BY_TEMPLATE_ID = 'permits/template/TEMPLATE_PERMITS_FETCH';
export const TEMPLATE_PERMITS_GET_EXISTING_AND_NEW_BY_TEMPLATE_ID = 'permits/template/TEMPLATE_PERMITS_RE_FETCH';
export const DELETE_TEMPLATE_PERMIT = 'permits/template/DELETE_TEMPLATE_PERMITS';
export const CREATE_TEMPLATE_PERMIT = 'permits/template/CREATE_TEMPLATE_PERMITS';
export const UPDATE_TEMPLATE_PERMIT = 'permits/template/UPDATE_TEMPLATE_PERMITS';

export type GetAllTemplatePermitsActionType = SuccessAction<TemplatePermit[], null>;

interface GetPaginatedTemplatePermitsActionPayload {
  templateId: Muid;
  response: PaginatedResponse<TemplatePermit>;
}

interface CreateOrUpdateTemplatePermitActionPayload {
  permit: TemplatePermit;
  templateId: Muid;
}

interface DeleteTemplatePermitActionPayload {
  permitId: Muid;
  templateId: Muid;
}

export type GetPaginatedTemplatePermitsActionType = SuccessAction<
  GetPaginatedTemplatePermitsActionPayload,
  { templateId: Muid }
>;

export type CreateOrUpdateTemplatePermitActionType = SuccessAction<CreateOrUpdateTemplatePermitActionPayload, null>;

export type DeleteTemplatePermitActionType = SuccessAction<DeleteTemplatePermitActionPayload, null>;

const createTemplatePermitAction = (templateId: Muid, permit: TemplatePermit) =>
  createSuccessAction(CREATE_TEMPLATE_PERMIT, { permit, templateId });

const deleteTemplatePermitAction = (templateId: Muid, permitId: Muid) =>
  createSuccessAction(DELETE_TEMPLATE_PERMIT, { permitId, templateId });

const getAllExistingAndNewTemplatePermitsAction = (
  templateId: Muid,
  paginatesResponse: PaginatedResponse<TemplatePermit>,
) =>
  createSuccessAction(TEMPLATE_PERMITS_GET_EXISTING_AND_NEW_BY_TEMPLATE_ID, {
    response: paginatesResponse,
    templateId,
  });

export const getTemplatePermitsRequestAction = (templateId: Muid) =>
  createRequestAction(
    TEMPLATE_PERMITS_GET_ALL_BY_TEMPLATE_ID,
    {
      templateId,
    },
    { templateId },
  );

const getTemplatePermitsSuccessAction = (templateId: Muid, paginatesResponse: PaginatedResponse<TemplatePermit>) =>
  createSuccessAction(
    TEMPLATE_PERMITS_GET_ALL_BY_TEMPLATE_ID,
    {
      response: paginatesResponse,
      templateId,
    },
    { templateId },
  );

const updateTemplatePermitAction = (templateId: Muid, permit: TemplatePermit) =>
  createSuccessAction(UPDATE_TEMPLATE_PERMIT, {
    permit,
    templateId,
  });

export const TemplateMembershipActions = {
  createTemplatePermit:
    (templateId: Muid, permit: TemplatePermit): ThunkAction<void, ReduxAppState, Record<string, unknown>, AnyAction> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>) =>
      dispatch(createTemplatePermitAction(templateId, permit)),

  deleteTemplatePermit:
    (templateId: Muid, permitId: Muid): ThunkAction<void, ReduxAppState, Record<string, unknown>, AnyAction> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>) =>
      dispatch(deleteTemplatePermitAction(templateId, permitId)),

  getAllExistingAndNewTemplatePermits:
    (templateId: Muid): ThunkAction<void, ReduxAppState, Record<string, unknown>, AnyAction> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>, getState: () => ReduxAppState) => {
      const appState =
        (getState().lookups.templatePermits.byTemplateId[templateId] &&
          getState().lookups.templatePermits.byTemplateId[templateId].permits &&
          // we add +1 to fetch the new permit as well
          getState().lookups.templatePermits.byTemplateId[templateId].permits.length + 1) ||
        20;

      axiosService
        .getAxios()
        .get(`/1/permits/paginated/template?templateId=${templateId}&limit=${appState}`)
        .then(
          (response: AxiosResponse) => {
            const paginatesResponse = response.data as PaginatedResponse<TemplatePermit>;
            dispatch(getAllExistingAndNewTemplatePermitsAction(templateId, paginatesResponse));
          },
          () => {
            dispatch(
              FlashActions.showCriticalDangerNotice(
                'Oops, cannot fetch memberships for a workflow. Please contact support.',
              ),
            );
          },
        );
    },
  getTemplatePermits:
    (templateId: Muid, limit: number): ThunkAction<void, ReduxAppState, Record<string, unknown>, AnyAction> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>) => {
      dispatch(getTemplatePermitsRequestAction(templateId));

      return axiosService
        .getAxios()
        .get(`/1/permits/paginated/template?templateId=${templateId}&limit=${limit}`)
        .then(
          (response: AxiosResponse) => {
            const paginatesResponse = response.data as PaginatedResponse<TemplatePermit>;
            dispatch(getTemplatePermitsSuccessAction(templateId, paginatesResponse));
          },
          () => {
            dispatch(
              FlashActions.showCriticalDangerNotice(
                'Oops, cannot fetch memberships for a workflow. Please contact support.',
              ),
            );
          },
        );
    },
  updateTemplatePermit:
    (templateId: Muid, permit: TemplatePermit): ThunkAction<void, ReduxAppState, Record<string, unknown>, AnyAction> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>) => {
      dispatch(updateTemplatePermitAction(templateId, permit));
    },
};
