import { InboxItem, InboxItemType, InboxItemUtils } from '@process-street/subgrade/inbox';
import { match, P } from 'ts-pattern';
import { useInjector } from 'components/injection-provider';
import { UibModalInstance } from 'services/uib-modal-service.interface';
import { InboxItemDetailsEvents } from 'directives/inbox/item/details/inbox-item-details.events';
import { ChecklistStatus, TaskStatus } from '@process-street/subgrade/process';
import { trace } from 'components/trace';
import { useCallback, useRef, useState } from 'react';
import { useCompleteChecklistMutation } from './use-complete-checklist-mutation';
import { useCompleteTaskMutation } from './use-complete-task-mutation';
import { useCurrentUser } from 'hooks/use-current-user';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { useInboxItemsDataSource } from './use-inbox-items-data-source';
import { GridApi, IRowNode } from '@ag-grid-community/core';
import { wait } from 'utils/wait';
import { TasksTableUtils } from '../components/tasks-table/tasks-table-utils';
import { useMoveElevioLauncher } from 'features/one-off-tasks/components/shared/hooks';
import { useViewTaskByItem } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { getRowId } from '../helpers';
import { ResolvedMergeTagsByChecklistRevisionIdQuery } from 'app/features/merge-tags/query-builder';
import { useQueryClient } from 'react-query';
import { GetFormFieldValuesByChecklistRevisionIdQuery } from 'app/features/widgets/query-builder';
import { useFormFieldValueListeners } from 'app/hooks/use-form-field-value-listeners';
import { useInboxItemsGridActions } from '../use-inbox-items-grid-context-store';
import { PromiseQueueDescGenerator } from 'app/services/promise-queue/promise-queue-desc-generator-pure';
import { PromiseQueueKeyGenerator } from 'app/services/promise-queue/promise-queue-key-generator-pure';
import { useFocusedItemActions } from './use-focused-item-store';
import { GetInboxItemsQuery } from 'app/features/microsoft-teams/query-builder';

const logger = trace({
  name: 'useInboxItemDetailsModal',
});

export const DRAWER_MODAL_ANIMATION_DELAY_IN_MS = 500;

export const getNodeById = (item: InboxItem, api: GridApi<InboxItem>): IRowNode<InboxItem> | null => {
  const rowId = getRowId(item);

  if (!rowId) return null;

  return api.getRowNode(rowId) ?? null;
};

export const useInboxItemDetailsModal = (
  api?: GridApi,
  isShowingCompleted?: boolean,
  groupBy?: TasksTableUtils.TasksTableGroupBy,
) => {
  const queryClient = useQueryClient();
  const { $uibModal, $rootScope, PromiseQueueService } = useInjector('$uibModal', '$rootScope', 'PromiseQueueService');
  const modalInstanceRef = useRef<UibModalInstance | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const unsubscribeFromCloseEventRef = useRef<(() => void) | null>(null);

  const focusManagerActions = useFocusedItemActions();
  const inboxItemsGridActions = useInboxItemsGridActions();

  const viewTaskByItem = useViewTaskByItem();

  const { listenToFormFieldValueUpdate } = useFormFieldValueListeners();

  const { invalidate: invalidateInboxData } = useInboxItemsDataSource();
  const user = useCurrentUser();
  const organization = useSelectedOrganization();

  const completeChecklistMutation = useCompleteChecklistMutation(isShowingCompleted);
  const completeTaskMutation = useCompleteTaskMutation(isShowingCompleted);

  const setFocusedRow = (item: InboxItem) => {
    if (!api) return;
    const itemId = InboxItemUtils.getUniqueId(item);

    if (!itemId) return;
    const rowIndex = api.getRowNode(itemId)?.rowIndex;

    if (typeof rowIndex !== 'number') return;

    const sortBy =
      groupBy === TasksTableUtils.TasksTableGroupBy.WorkflowRun
        ? GetInboxItemsQuery.SortBy.AssignmentDueDate
        : GetInboxItemsQuery.SortBy.ChecklistName;

    api.setFocusedCell(rowIndex, sortBy);
  };

  const openNextItemIfAvailable = async (
    currentItem: InboxItem,
    nextNode: IRowNode<InboxItem> | null,
  ): Promise<boolean> => {
    if (!api) return false;

    const itemId = getRowId(currentItem);

    if (
      !nextNode?.data ||
      // Only inbox items can be opened
      !TasksTableUtils.isInboxItemRow(nextNode.data) ||
      // Don't open the same item
      nextNode.id === itemId
    ) {
      return false;
    }

    await wait(DRAWER_MODAL_ANIMATION_DELAY_IN_MS);

    focusManagerActions.setItem({ item: nextNode.data });
    setFocusedRow(nextNode.data);

    if (nextNode.data.itemType === InboxItemType.OneOffTask) {
      const inboxNextNode = getNextNode(nextNode.data);
      viewTaskByItem({ inboxItem: nextNode.data, inboxCallback: openNextItemIfAvailable, inboxNextNode });
    } else {
      await onOpen(nextNode.data);
    }

    return true;
  };

  const getNextNode = (currentItem: InboxItem): IRowNode<InboxItem> | null => {
    if (!api) return null;
    const row = getNodeById(currentItem, api);

    if (!row || row.rowIndex === null) return null;

    const visibleNodes: IRowNode<InboxItem>[] = api.getRenderedNodes();

    // The row.rowIndex points to the index of the whole dataset on the table. Now, we need
    // to find the index of the current item on the rendered nodes array.
    const currentNodeIndex = visibleNodes.findIndex(node => node.rowIndex === row.rowIndex);

    const nextNode = visibleNodes[currentNodeIndex + 1];
    if (!nextNode) return null;

    if (nextNode.group) {
      // Skip the group node
      return visibleNodes[currentNodeIndex + 2];
    }

    return nextNode;
  };

  const completeItem = async (item: InboxItem, isAutocomplete?: boolean) => {
    try {
      const nextNode = getNextNode(item);

      const { checklist } = item;

      focusManagerActions.reset();
      api?.clearFocusedCell();

      const promise = match(item)
        // when called by autocomplete, don't actually do complete checklist call
        // only invalidate inbox & go to next task
        .with({ itemType: InboxItemType.Checklist }, item =>
          isAutocomplete
            ? Promise.resolve()
            : completeChecklistMutation.mutateAsync({
                status: ChecklistStatus.Completed,
                checklistId: item.checklist.id,
              }),
        )
        .with({ itemType: InboxItemType.StandardTask }, item => {
          const queueDesc = PromiseQueueDescGenerator.generateUpdateStatusByTaskId(item.task.id, TaskStatus.Completed);
          const queueKey = PromiseQueueKeyGenerator.generateByChecklistId(checklist.id);

          return PromiseQueueService.enqueue(
            queueKey,
            () =>
              completeTaskMutation.mutateAsync({
                status: TaskStatus.Completed,
                taskId: item.task.id,
              }),
            queueDesc,
          );
        })
        .with({ itemType: InboxItemType.ApprovalTask }, _ =>
          // Poor solution to wait for approval request to finish (this request is sent by angular) before invalidating the inbox data
          wait(500),
        )
        .otherwise(() => Promise.reject(`Unsupported inbox item type ${item.itemType}`));

      await promise;
      await invalidateInboxData();

      modalInstanceRef.current?.close?.();
      await openNextItemIfAvailable(item, nextNode);
    } catch (e) {
      logger.error((e as Error).message);
    }
  };

  // Mock some basic inboxListCtrl functions
  const inboxListCtrl = {
    registerInboxItemDetailsController: () => {},
    isFirstItem: () => false,
    isLastItem: () => false,
    showNextItemDetails: () => {},
    showPreviousItemDetails: () => {},
    completeItem,
    onChecklistItemAssigneesUpdate: async () => {
      await invalidateInboxData();
      focusManagerActions.reset();
      api?.clearFocusedCell();
    },
  };

  const onClose = useCallback(
    (target: EventTarget | null) => {
      const isOpeningANewItem = Boolean(
        (target as HTMLElement)?.closest(`.ag-cell[col-id="${GetInboxItemsQuery.SortBy.TaskTemplateName}"]`),
      );

      if (!isOpeningANewItem) {
        focusManagerActions.reset();
        api?.clearFocusedCell();
      }

      modalInstanceRef.current?.close?.();
      setIsOpen(false);
    },
    [api, focusManagerActions],
  );

  // This will invalidate the merge tags query and form field values query to ensure that the drawer will have
  // fresh data for the widget values.
  const invalidateMergeTags = async (item: InboxItem) => {
    const checklistRevisionId = match(item)
      .with(
        { itemType: P.union(InboxItemType.OneOffTask, InboxItemType.ApprovalTask, InboxItemType.StandardTask) },
        ({ task }) => task.checklistRevision.id,
      )
      .otherwise(() => undefined);

    if (checklistRevisionId) {
      await queryClient.invalidateQueries(GetFormFieldValuesByChecklistRevisionIdQuery.getKey({ checklistRevisionId }));
      await queryClient.invalidateQueries(ResolvedMergeTagsByChecklistRevisionIdQuery.key);
    }
  };

  const onOpen = async (item: InboxItem, options: Partial<{ shouldScrollToComments: boolean }> = {}) => {
    await invalidateMergeTags(item);

    // angular-bootstrap adds the `.modal-open` class to the body when any modal is open
    const hasOpenModal = document.querySelector('body.modal-open');

    if (hasOpenModal) {
      $rootScope.$broadcast(InboxItemDetailsEvents.INBOX_ITEM_DETAILS_CLOSED);
      await wait(DRAWER_MODAL_ANIMATION_DELAY_IN_MS);
    }

    focusManagerActions.setItem({ item });
    setFocusedRow(item);

    const unsubscribeFromFormFieldValueUpdate = listenToFormFieldValueUpdate(item.checklist.id);
    const subscribeToClickListener = () => {
      logger.info('subscribing to drawer click outside.');
      const listener = (event: MouseEvent) => {
        const modalElement = document.querySelector('.modal.inbox-item-modal.my-work') as HTMLElement;
        const rect = modalElement.getBoundingClientRect();

        const clickX = event.clientX;
        const clickY = event.clientY;
        const isClickInsideModal =
          clickX >= rect.left && clickX <= rect.right && clickY >= rect.top && clickY <= rect.bottom;

        if (!isClickInsideModal) {
          onClose(event.target);
        }
      };

      document.addEventListener('click', listener);

      return () => {
        logger.info('unsubscribing from drawer click outside.');
        document.removeEventListener('click', listener);
      };
    };

    let unsubscribeFromClickListener = () => {};

    inboxItemsGridActions.selectRow(item);

    const inboxNextNode = getNextNode(item);
    const onSnooze = () => {
      modalInstanceRef.current?.close?.();
      openNextItemIfAvailable(item, inboxNextNode);
    };

    modalInstanceRef.current = $uibModal.open({
      component: 'ps-inbox-item-details',
      animation: true,
      size: 'inbox-medium',
      windowClass: 'inbox-item-modal my-work',
      backdrop: false,
      resolve: {
        item,
        user,
        organization,
        hideNavigation: true,
        isTasksPageModal: true,
        inboxListCtrl,
        shouldScrollToComments: options.shouldScrollToComments,
        onSnooze: { onSnooze },
      },
    });

    modalInstanceRef.current?.closed.then(() => {
      inboxItemsGridActions.deselectRow();

      unsubscribeFromCloseEventRef.current?.();
      unsubscribeFromCloseEventRef.current = null;

      unsubscribeFromFormFieldValueUpdate();
      unsubscribeFromClickListener();

      focusManagerActions.reset();
    });

    modalInstanceRef.current?.opened.then(() => {
      unsubscribeFromClickListener = subscribeToClickListener();
    });

    // Initially this event listener was subscribed inside an `useEffect` but by doing that
    // every table row would be listening to the event.
    // Subscribing from the click event, will ensure that we only have one active listener.
    unsubscribeFromCloseEventRef.current = $rootScope.$on(
      InboxItemDetailsEvents.INBOX_ITEM_DETAILS_CLOSED,
      (_: unknown, inboxItem: InboxItem) => {
        if (inboxItem) {
          focusManagerActions.reset();
          api?.clearFocusedCell();
        }
        modalInstanceRef.current?.close();
      },
    );
    setIsOpen(true);
  };
  useMoveElevioLauncher(isOpen);

  return { onOpen, instance: modalInstanceRef.current, completeItem, openNextItemIfAvailable, getNextNode };
};
