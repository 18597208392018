import React from 'react';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import { AutomationLogStatus, ExecutionStatus } from '@process-street/subgrade/automation';
import {
  Text,
  HStack,
  Box,
  Center,
  Flex,
  Tooltip,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  PopoverHeader,
  Button,
} from 'components/design/next';
import { faCheck, faClipboard, faClipboardCheck, faSyncAlt, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useCopyToClipboard } from 'react-use';

interface AutomationLogItemProps {
  log: ExecutionStatus;
  timeZone?: string;
}

const statusLabel: { [key in AutomationLogStatus]: string } = {
  [AutomationLogStatus.Processing]: 'Running',
  [AutomationLogStatus.Success]: 'Success',
  [AutomationLogStatus.Failure]: 'Error',
};

const CopyToClipboardButton: React.FC<React.PropsWithChildren<{ content: string }>> = ({ content, children }) => {
  const [{ value }, copyToClipboard] = useCopyToClipboard();

  const copy = React.useCallback(() => copyToClipboard(content), [content, copyToClipboard]);

  return (
    <Button variant="unstyled" onClick={copy}>
      <Box as={FontAwesomeIcon} icon={(value ? faClipboardCheck : faClipboard) as IconDefinition}>
        {children}
      </Box>
    </Button>
  );
};

export const LogItem: React.FunctionComponent<React.PropsWithChildren<AutomationLogItemProps>> = ({
  log,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}) => {
  const eventDate = DateUtils.formatDateTime(log.eventDate, DateFormat.DateTimeShortMonthFixed, timeZone);
  const icon = {
    [AutomationLogStatus.Success]: {
      color: 'green.500',
      icon: faCheck as IconDefinition,
    },
    [AutomationLogStatus.Failure]: {
      color: 'red.500',
      icon: faTimes as IconDefinition,
    },
    [AutomationLogStatus.Processing]: {
      color: 'gray.400',
      icon: faSyncAlt as IconDefinition,
    },
  }[log.status || AutomationLogStatus.Processing];

  return (
    <HStack p="2" spacing="10">
      <Flex flexGrow={1}>
        <HStack spacing="2">
          <Center w="4">
            <Box as={FontAwesomeIcon} {...icon} />
          </Center>
          {log.status === AutomationLogStatus.Failure && log.errorMessage ? (
            <Popover isLazy={true} trigger="click" placement="bottom" closeOnBlur={true}>
              <PopoverTrigger>
                <Button variant="unstyled">
                  <Text variant="-1" noOfLines={1}>
                    {!!log.status && statusLabel[log.status]}
                  </Text>
                </Button>
              </PopoverTrigger>
              <PopoverContent backgroundColor="gray.50" color="black">
                <PopoverHeader backgroundColor="white" color="red.500">
                  <Flex alignItems="center" justifyContent="space-between">
                    <Box>
                      <Text variant="-1u">Error</Text>
                    </Box>
                    <Tooltip hasArrow={true} placement="bottom" label="Copy this error">
                      <Box color="gray.500">
                        <CopyToClipboardButton content={log.errorMessage} />
                      </Box>
                    </Tooltip>
                  </Flex>
                </PopoverHeader>
                <PopoverArrow bg="white" />
                <PopoverBody>
                  <Box>
                    <Text variant="-1" as="code">
                      {log.errorMessage}
                    </Text>
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : (
            <Text variant="-1" noOfLines={1}>
              {!!log.status && statusLabel[log.status]}
            </Text>
          )}
        </HStack>
      </Flex>
      <Box>
        <Text variant="-1" noOfLines={1}>
          {log.automationApp}
        </Text>
      </Box>
      <Box>
        <Text variant="-1" noOfLines={1}>
          {eventDate}
        </Text>
      </Box>
    </HStack>
  );
};
