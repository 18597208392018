import * as React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Spacer,
  VStack,
} from 'components/design/next';
import { ItemTypeSelector } from 'pages/tasks/components/filter-bar/item-type-selector/item-type-selector';
import { SearchBox } from 'pages/tasks/components/filter-bar/search-box';
import { AssigneeSelector } from 'pages/tasks/components/filter-bar/assignee-selector';
import { WorkflowSelector } from 'pages/tasks/components/filter-bar/workflow-selector';
import { InboxItemType } from '@process-street/subgrade/inbox';
import { Template } from '@process-street/subgrade/process';
import { isGuest, User } from '@process-street/subgrade/core';
import isEqual from 'lodash/isEqual';
import { ButtonProps, useAccordion, useBreakpointValue, useToken } from '@chakra-ui/react';
import { GroupingSelector } from 'pages/tasks/components/filter-bar/grouping-selector';
import { useCreateProjectTask } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { TasksTableUtils } from '../tasks-table/tasks-table-utils';
import { SnoozedToggle } from './snoozed-toggle';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { ShowCompletedToggle } from './show-completed-toggle';

export interface FilterBarProps {
  onChange: (params: TasksTableUtils.TasksTableFilters) => void;
  filters: TasksTableUtils.TasksTableFilters;
}

enum AccordionItems {
  None = -1,
  Search = 0,
  Filters = 1,
}

export const FilterBar = ({ onChange, filters }: FilterBarProps) => {
  const createProjectTask = useCreateProjectTask();

  const userInfo = useGetCurrentUserInfoQuery();

  const accordionState = useAccordion({});

  const onItemsTypeChange = (itemsType?: InboxItemType[]) => onChange({ ...filters, itemsType });
  const onUserIdsChange = (userIds: User['id'][] = []) => {
    if (!isEqual(userIds, filters.userIds)) {
      onChange({ ...filters, userIds });
    }
  };
  const onWorkflowsChange = (templateIds?: Template['id'][]) => {
    if (!isEqual(templateIds, filters.templateIds)) {
      onChange({ ...filters, templateIds });
    }
  };
  const onGroupByChange = (groupBy: TasksTableUtils.TasksTableGroupBy) => {
    if (!isEqual(groupBy, filters.groupBy)) {
      const newFilters = {
        ...filters,
        includeCompleted: groupBy === TasksTableUtils.TasksTableGroupBy.DueDate ? false : filters.includeCompleted,
      };
      onChange({ ...newFilters, groupBy });
    }
  };

  const onSnoozedChange = (snoozeStatus: GetInboxItemsQuery.SnoozeStatus) => {
    if (snoozeStatus !== filters.snoozeStatus) {
      onChange({ ...filters, snoozeStatus });
    }
  };

  const onIncludeCompletedChange = (includeCompleted: boolean) => {
    if (includeCompleted !== filters.includeCompleted) {
      onChange({ ...filters, includeCompleted });
    }
  };

  const isDesktop = useBreakpointValue({ base: false, md: true }, { fallback: 'md' });
  const [gray200] = useToken('colors', ['gray.200']);

  const createBtn = (
    <Button leftIcon={<Icon icon="plus" size="4" />} variant="secondary" onClick={createProjectTask}>
      Task
    </Button>
  );

  const divider = (
    <Box
      as="hr"
      mx={-4} // span the entire mobile screen, stretching into container
      w="100vw"
      alignSelf="center"
      borderBottom={`1px solid ${gray200}`}
      boxShadow={`0 4px 2px -2px ${gray200}`}
    />
  );

  const filterComponents = (
    <>
      <ItemTypeSelector itemTypes={filters.itemsType ?? []} onChange={onItemsTypeChange} />
      {userInfo.data && !isGuest(userInfo.data.organizationMembership) && (
        <AssigneeSelector assigneeUserIds={filters.userIds} onChange={onUserIdsChange} />
      )}
      <WorkflowSelector templateIds={filters.templateIds} onChange={onWorkflowsChange} />
      <GroupingSelector groupBy={filters.groupBy} onChange={onGroupByChange} />
      <SnoozedToggle status={filters.snoozeStatus} onChange={onSnoozedChange} />

      <ShowCompletedToggle
        isChecked={filters.includeCompleted}
        groupBy={filters.groupBy}
        onChange={onIncludeCompletedChange}
      />
      <Spacer />
      {isDesktop && (
        <Button leftIcon={<Icon icon="plus" size="4" />} variant="secondary" onClick={createProjectTask}>
          Task
        </Button>
      )}
    </>
  );

  const toggleAccordionIndex = (newValue: AccordionItems) => {
    if (accordionState.index !== newValue) {
      accordionState.setIndex(newValue);
    } else {
      accordionState.setIndex(AccordionItems.None);
    }
  };

  return isDesktop ? (
    <HStack mb={{ base: 6, lg: 9 }} spacing={4} wrap="wrap" rowGap={4}>
      <SearchBox w={70} searchTerm={filters.search} onSearchTermChange={search => onChange({ ...filters, search })} />
      {filterComponents}
    </HStack>
  ) : (
    <VStack>
      <HStack w="full">
        {React.cloneElement<ButtonProps>(createBtn, { w: 'full' })}

        <IconButton
          w="auto"
          p={0}
          variant="ghost"
          color="gray.500"
          onClick={() => {
            toggleAccordionIndex(AccordionItems.Search);
          }}
          aria-label={accordionState.index === AccordionItems.Search ? 'collapse search' : 'expand search'}
          icon={
            <Icon
              icon={accordionState.index === AccordionItems.Search ? 'xmark' : 'magnifying-glass'}
              variant="far"
              size="4"
            />
          }
        />

        <IconButton
          w="auto"
          p={0}
          variant="ghost"
          color="gray.500"
          onClick={() => {
            toggleAccordionIndex(AccordionItems.Filters);
          }}
          aria-label={accordionState.index === AccordionItems.Filters ? 'collapse filters' : 'expand filters'}
          icon={
            <Icon icon={accordionState.index === AccordionItems.Filters ? 'xmark' : 'filter'} variant="far" size="4" />
          }
        />
      </HStack>

      <Accordion index={accordionState.index} allowToggle allowMultiple w="100%" mb={3}>
        <AccordionItem>
          <AccordionButton display="none" />
          <AccordionPanel p="0" mt={4}>
            {accordionState.index === AccordionItems.Search && (
              <>
                <SearchBox
                  w="full"
                  searchTerm={filters.search}
                  onSearchTermChange={search => onChange({ ...filters, search })}
                />
                {React.cloneElement(divider, { ml: -2 })}
              </>
            )}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton display="none" />
          <AccordionPanel p="0" mt={4}>
            {accordionState.index === AccordionItems.Filters && (
              <VStack w="full" alignItems="stretch" spacing={4}>
                {filterComponents}
                {divider}
              </VStack>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
};
