import { Muid, Option } from '@process-street/subgrade/core';
import {
  FormFieldValue,
  FormFieldValueWithWidget,
  FormFieldValueWithWidgetMap,
  FormFieldWidget,
  TaskTemplate,
  Widget,
  WidgetType,
} from '@process-street/subgrade/process';
import { SelectorFactories } from '@process-street/subgrade/redux/selector/factories/selector-factories';
import { BaseFormFieldValueSelector } from '@process-street/subgrade/redux/selector/form-field-value.selectors';
import { BaseNavigationSelector } from '@process-street/subgrade/redux/selector/navigation.selectors';
import { EntityMap } from '@process-street/subgrade/redux/types';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import { modulesSelector } from 'reducers/modules/modules.selectors';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { ReduxAppState } from 'reducers/types';
import { createSelector, Selector } from 'reselect';

const getEntityMapForCurrentChecklistRevisionId = SelectorFactories.getEntitiesMapByLookupId(
  BaseNavigationSelector.getCurrentChecklistRevisionId,
  BaseFormFieldValueSelector.getEntityMap,
  BaseFormFieldValueSelector.getLookupByChecklistRevisionId,
);

const getAllWithFormFieldWithWidgetByChecklistRevisionId = (
  revisionId: Muid,
): Selector<ReduxAppState, FormFieldValue[]> =>
  createSelector(
    [
      BaseFormFieldValueSelector.getAllByChecklistRevisionId(revisionId),
      WidgetSelector.getEntityMap,
      TaskTemplateSelector.getEntityMap,
    ],
    (formFieldValues: FormFieldValue[], widgetMap: EntityMap<Widget>, taskTemplateMap: EntityMap<TaskTemplate>) => {
      return formFieldValues.reduce((result: FormFieldValue[], formFieldValue: FormFieldValue) => {
        result.push(denormaliseFormFieldValueWithWidget(formFieldValue, widgetMap, taskTemplateMap));

        return result;
      }, []);
    },
  );

const getFormFieldValueWithWidgetMapForCurrentChecklistRevisionId = createSelector(
  [getEntityMapForCurrentChecklistRevisionId, WidgetSelector.getEntityMap, TaskTemplateSelector.getEntityMap],
  (
    formFieldValueMap: EntityMap<FormFieldValue>,
    widgetMap: EntityMap<Widget>,
    taskTemplateMap: EntityMap<TaskTemplate>,
  ) => {
    return Object.values(formFieldValueMap).reduce(
      (result: FormFieldValueWithWidgetMap, formFieldValue: FormFieldValue) => {
        result[formFieldValue.id] = denormaliseFormFieldValueWithWidget(formFieldValue, widgetMap, taskTemplateMap);

        return result;
      },
      {} as FormFieldValueWithWidgetMap,
    );
  },
);

const getByWidgetId = (widgetId: Muid) =>
  createSelector(getEntityMapForCurrentChecklistRevisionId, (formFieldValueMap: EntityMap<FormFieldValue>) =>
    Object.values(formFieldValueMap).find(v => v.formFieldWidget.id === widgetId),
  );

const denormaliseFormFieldValueWithWidget = (
  formFieldValue: FormFieldValue,
  widgetMap: EntityMap<Widget>,
  taskTemplateMap: EntityMap<TaskTemplate>,
) => {
  const formFieldWidget = denormaliseFormFieldWidget(formFieldValue.formFieldWidget.id, widgetMap, taskTemplateMap);

  return { ...formFieldValue, formFieldWidget } as FormFieldValueWithWidget;
};

const denormaliseFormFieldWidget = (
  formFieldWidgetId: Muid,
  widgetMap: EntityMap<Widget>,
  taskTemplateMap: EntityMap<TaskTemplate>,
): Option<FormFieldWidget> => {
  const widget = widgetMap[formFieldWidgetId];
  if (widget && widget.header.type === WidgetType.FormField) {
    const formFieldWidget = widget as FormFieldWidget;
    const taskTemplate = taskTemplateMap[formFieldWidget.header.taskTemplate.id];
    if (taskTemplate) {
      return {
        ...formFieldWidget,
        header: { ...formFieldWidget.header, taskTemplate },
      };
    } else {
      // TODO https://processstreet.atlassian.net/browse/PS-6424
      // Log this error to sentry: "Error denormalising FormFieldWidget.  Missing Widget or incorrect type ."
      return undefined;
    }
  } else {
    // TODO https://processstreet.atlassian.net/browse/PS-6424
    // Log this error to sentry: "Error denormalising FormFieldWidget.  Missing Widget or incorrect type ."
    return undefined;
  }
};

const getUpdatePending = createSelector(modulesSelector, state => state.formFieldValue.updatePending);

export const FormFieldValueSelector = {
  getAllByChecklistRevisionId: BaseFormFieldValueSelector.getAllByChecklistRevisionId,
  getAllByTaskId: BaseFormFieldValueSelector.getAllByTaskId,
  getAllWithFormFieldWithWidgetByChecklistRevisionId,
  getById: BaseFormFieldValueSelector.getById,
  getByWidgetId,
  getEntityMap: BaseFormFieldValueSelector.getEntityMap,
  getEntityMapForCurrentChecklistRevisionId,
  getFormFieldValueWithWidgetMapForCurrentChecklistRevisionId,
  getLookupByChecklistRevisionId: BaseFormFieldValueSelector.getLookupByChecklistRevisionId,
  getUpdatePending,
};
