import { Muid } from '@process-street/subgrade/core';
import { TemplateTaskAssignmentWithOrganizationMembership } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type AssignTaskTemplateMutationParams = {
  taskTemplateId: Muid;
  email: string;
};

export type AssignTaskTemplateMutationResponse = TemplateTaskAssignmentWithOrganizationMembership;

export const AssignTaskTemplateMutation = {
  key: ['put', 'task-templates', 'assignments'],
  getKey: (params: AssignTaskTemplateMutationParams) => [
    ...AssignTaskTemplateMutation.key,
    params.taskTemplateId,
    params.email,
  ],
  mutationFn: ({ taskTemplateId, email }: AssignTaskTemplateMutationParams) =>
    axiosService
      .getAxios()
      .put<AssignTaskTemplateMutationResponse>(`/1/task-templates/${taskTemplateId}/assignments/${email}`)
      .then(res => res.data),
};

export const useAssignTaskTemplateMutation = (
  options: UseMutationOptions<AssignTaskTemplateMutationResponse, AxiosError, AssignTaskTemplateMutationParams> = {},
) => {
  return useMutation(AssignTaskTemplateMutation.mutationFn, options);
};
