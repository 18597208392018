import * as React from 'react';
import { useToast } from 'components/design/next';
import { useIsMutating, useQueryClient } from 'react-query';
import {
  GetAllTemplatesQuery,
  GetTemplateQuery,
  UpdateTemplateStatusMutation,
  useGetTemplateQuery,
  useUpdateTemplateStatusMutation,
} from 'features/template/query-builder';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useTemplateMenuContext, useTemplateMenuDisclosureContext } from '../template-menu';
import { TemplateStatus, tmplTypeName, tmplTypeNameLower } from '@process-street/subgrade/process';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { ButtonWithUpgradeTooltip } from 'components/button-with-upgrade-tooltip';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { AnalyticsService } from 'components/analytics/analytics.service';

export type ArchiveTemplateButtonProps = {
  children: (props: { templateStatus: TemplateStatus; isMutating: boolean }) => React.ReactElement<{
    icon?: React.ReactElement;
    isDisabled: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  }> | null;
};

export const ArchiveTemplateButton: React.FC<ArchiveTemplateButtonProps> = React.memo(({ children }) => {
  const { templateId } = useTemplateMenuContext();
  const { archiveTemplateAlertDisclosure: disclosure } = useTemplateMenuDisclosureContext();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { data: template } = useGetTemplateQuery({ templateId });
  const templateStatus = template?.status ?? TemplateStatus.Active;

  const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId, {
    enabled: !!templateId,
  });

  const archiveTemplateMutation = useUpdateTemplateStatusMutation({
    mutationKey: UpdateTemplateStatusMutation.getKey({ templateId, status: 'Active' }),
    onSuccess: res => {
      AnalyticsService.trackEvent('template unarchived', {});
      queryClient.setQueryData(GetTemplateQuery.getKey({ templateId }), res);
      void queryClient.invalidateQueries(GetAllTemplatesQuery.getKey({ organizationId: res.organization.id }));
      toast({
        status: 'success',
        title: `${tmplTypeName(template)} restored`,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: `We're having problems restoring this ${tmplTypeNameLower(template)}`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const archiveMutations = useIsMutating({
    mutationKey: UpdateTemplateStatusMutation.getKey({ templateId, status: 'Archived' }),
  });

  const unarchiveMutations = useIsMutating({
    mutationKey: UpdateTemplateStatusMutation.getKey({ templateId, status: 'Active' }),
  });

  const isMutating = archiveMutations > 0 || unarchiveMutations > 0;

  const canArchiveTemplate = permissionMap?.templateArchive;

  if (typeof children !== 'function') {
    throw new Error('`ArchiveTemplateButton` children must be a render prop function');
  }

  const child = children({ templateStatus, isMutating });
  if (child === null || !React.isValidElement(child) || Array.isArray(child)) {
    throw new Error('The render prop function child must return a single clickable element or a render prop function');
  }

  const isFree = useIsCurrentUserFreeMember();

  if (isFree) {
    return <ButtonWithUpgradeTooltip>{child}</ButtonWithUpgradeTooltip>;
  } else if (!canArchiveTemplate) {
    return null;
  }

  const btn = React.cloneElement(child, {
    onClick: () => {
      if (templateStatus === TemplateStatus.Active) {
        if (canArchiveTemplate && !isMutating) {
          disclosure.onToggle();
        }
      }
      if (templateStatus === TemplateStatus.Archived) {
        if (canArchiveTemplate && !isMutating) {
          archiveTemplateMutation.mutate({ templateId, status: 'Active' });
        }
      }
    },
  });

  return <>{btn}</>;
});
