import { DataSetLinkedWidgetData } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateDataSetLinkMutationParams = {
  dataSetId: string;
  savedViewId: string;
  columnId: string;
  templateRevisionId: string;
  taskTemplateId: string;
  formFieldWidgetId: string;
};

export type CreateDataSetLinkMutationResponse = DataSetLinkedWidgetData;

export const CreateDataSetLinkMutation = {
  mutationFn: (params: CreateDataSetLinkMutationParams) =>
    axiosService
      .getAxios()
      .post<CreateDataSetLinkMutationResponse>(
        `/1/data-sets/${params.dataSetId}/saved-views/${params.savedViewId}/links`,
        params,
      )
      .then(res => res.data),
};

export const useCreateDataSetLinkMutation = (
  options: UseMutationOptions<CreateDataSetLinkMutationResponse, AxiosError, CreateDataSetLinkMutationParams>,
) => {
  return useMutation(CreateDataSetLinkMutation.mutationFn, options);
};
