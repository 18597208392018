import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { Categories } from '@process-street/subgrade/process/premade-template-model';

export namespace GetAllPremadeTemplateCategoriesQuery {
  export type Params = { organizationId?: Muid };

  export type Response = Categories;

  export const key = ['premade-template-categories'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/premade-templates/categories`, { params })
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.organizationId) && options.enabled !== false,
    });
  };
}
