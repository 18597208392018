import { Link } from '@process-street/adapters/navigation';
import { Template } from '@process-street/subgrade/process';
import { abbreviateForTitle, queryString } from '@process-street/subgrade/util';
import { Icon } from 'components/design/next';
import { useFeatureFlag } from 'features/feature-flags';
import * as React from 'react';
import { MobileActions } from '../../mobile-actions';
import { EditWorkflow } from './edit-workflow';
import { RunWorkflow } from './run-workflow';

type MobileWorkflowActionsProps = React.PropsWithChildren<{
  template: Template;
}>;

export const MobileWorkflowActions = ({ template, children: otherActions }: MobileWorkflowActionsProps) => {
  const isWorkflowDashboardEnabled = useFeatureFlag('workflowDashboard');

  return (
    <MobileActions.Wrapper>
      {isWorkflowDashboardEnabled && (
        <MobileActions.Item
          as={Link}
          // @ts-expect-error The props aren't being propagated by the `as` prop
          to={{
            pathname: 'templateDashboard',
            search: queryString.stringify({ id: template.id, title: `${abbreviateForTitle(template?.name)}-` }),
          }}
          leftIcon={<Icon icon="chart-line" size="4" />}
        >
          Dashboard
        </MobileActions.Item>
      )}

      <EditWorkflow
        template={template}
        textProps={{
          fontSize: 'sm',
          color: 'gray.600',
        }}
        iconProps={{
          size: '4',
          color: 'gray.600',
        }}
        button={<MobileActions.Item leftIcon={<Icon icon="edit" size="4" />}>Edit</MobileActions.Item>}
      />

      <RunWorkflow
        template={template}
        button={<MobileActions.Item leftIcon={<Icon icon="play" size="4" />}>Run</MobileActions.Item>}
      />

      <MobileActions.Item
        as={Link}
        // @ts-expect-error The props aren't being propagated by the `as` prop
        to={{
          pathname: 'templateView',
          search: queryString.stringify({ id: template.id, title: `${abbreviateForTitle(template.name)}` }),
        }}
        leftIcon={<Icon icon="eye" size="4" />}
      >
        Preview
      </MobileActions.Item>

      {otherActions}
    </MobileActions.Wrapper>
  );
};
