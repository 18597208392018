import * as React from 'react';
import { TickRendererProps } from '@visx/axis/lib/types';
import { useTooltipEffect } from './tooltip-state';
import { BarStackHorizontalTick } from '../bar-stack-horizontal';

export const Tick: React.VFC<TickRendererProps> = ({ formattedValue, ...tickProps }) => {
  const ref = React.useRef<SVGTextElement>(null);
  const dispatch = useTooltipEffect();

  return (
    <BarStackHorizontalTick
      ref={ref}
      aria-label="username"
      {...{
        formattedValue,
        onMouseLeave: () => {
          dispatch({ type: 'hide' });
        },
        onMouseMove: () => {
          if (!ref.current) return;
          dispatch({
            type: 'show',
            payload: {
              referenceElement: ref.current,
              data: { y: tickProps.y },
            },
          });
        },
      }}
      {...tickProps}
    />
  );
};
