import * as React from 'react';

import { TaskTemplate, Template, TemplateRevision } from '@process-street/subgrade/process';
import { useSolutionTypeTagInstances } from 'pages/templates/_id/automation/components/selector/use-solution-type-tag-instances';
import { AutomationAppIcon, AutomationAppIconProps } from '../automation-app-icon';
import { Badge, Button, HStack, ListItem, StackProps, Text, Tooltip, UnorderedList } from 'components/design/next';

import {
  AutomationInstance,
  AutomationInstanceUtils,
  getPrefixedAutomationAppName,
  SolutionTypeTag,
} from '@process-street/subgrade/automation';
import { MAX_TASK_SOLUTION_INSTANCES_VISIBLE } from '../workflow-automations/constants';
import { useAutomationsModalEvents } from '../workflow-automations/components/common/context';
import { TaskAutomationInstanceLabel } from 'app/features/automations/components/task-automation-indicator/task-automation-instance-label';

export type TaskAutomationIndicatorProps = StackProps & {
  taskTemplate: TaskTemplate;
  templateRevision: TemplateRevision;
  templateId: Template['id'];
  editable: boolean;
  isActive: boolean;
  isDisabled: boolean;
  iconVariant?: AutomationAppIconProps['variant'];
  maxItems?: number;
  itemProps?: StackProps;
};

export const TaskAutomationIndicator: React.FC<React.PropsWithChildren<TaskAutomationIndicatorProps>> = ({
  taskTemplate,
  templateRevision,
  templateId,
  isActive,
  editable,
  iconVariant,
  isDisabled,
  maxItems = MAX_TASK_SOLUTION_INSTANCES_VISIBLE,
  itemProps,
  ...stackProps
}) => {
  const {
    instances: { ps_when_task_checked_then },
  } = useSolutionTypeTagInstances({ templateId, templateRevisionId: templateRevision.id });

  const activeTaskAutomations = ps_when_task_checked_then.filter(i =>
    AutomationInstanceUtils.isEnabledTaskInstance(i, taskTemplate),
  );

  if (!activeTaskAutomations.length) return null;

  return (
    <HStack spacing="2" {...stackProps}>
      {activeTaskAutomations.slice(0, maxItems).map((automationInstance, index) => {
        return (
          <TaskAutomationInstanceItem
            {...{
              key: AutomationInstanceUtils.getInstanceId(automationInstance),
              automationInstance,
              templateRevision,
              taskTemplate,
              editable,
              isLast: index === maxItems - 1,
              notVisibleCount: activeTaskAutomations.length - maxItems,
              isSelected: isActive && editable,
              iconVariant,
              isDisabled,
              ...itemProps,
            }}
          />
        );
      })}
    </HStack>
  );
};

export type TaskAutomationInstanceItemProps = StackProps & {
  templateRevision: TemplateRevision;
  editable: boolean;
  taskTemplate: TaskTemplate;
  automationInstance: AutomationInstance;
  isLast: boolean;
  isSelected: boolean;
  notVisibleCount: number;
  isDisabled: boolean;
  iconVariant?: AutomationAppIconProps['variant'];
  isTooltipDisabled?: boolean;
  notVisibleCountVariant?: 'default' | 'badge';
};

export const TaskAutomationInstanceItem: React.FC<React.PropsWithChildren<TaskAutomationInstanceItemProps>> = ({
  templateRevision,
  editable = false,
  taskTemplate,
  automationInstance,
  iconVariant,
  isLast,
  notVisibleCount,
  isSelected,
  isDisabled,
  isTooltipDisabled,
  notVisibleCountVariant,
  ...stackProps
}) => {
  const automationId = AutomationInstanceUtils.getInstanceId(automationInstance);
  const { openAutomationInstance } = useAutomationsModalEvents();
  const disableButton = !editable || isDisabled;
  return (
    <HStack w="8" h="8" justifyContent="center" spacing="2" {...stackProps}>
      <Tooltip
        aria-label={`when ${taskTemplate.name} is checked, then...`}
        label={<TaskAutomationInstanceLabel automationInstance={automationInstance} taskTemplate={taskTemplate} />}
        hasArrow
        placement="bottom"
        shouldWrapChildren
        isDisabled={isTooltipDisabled}
      >
        <AutomationAppIcon
          {...{
            role: 'button',
            automationApp: getPrefixedAutomationAppName(automationInstance),
            variant: iconVariant ?? (isSelected ? 'white' : undefined),
            w: 5,
            ...(!disableButton &&
              automationId && {
                onClick: () =>
                  openAutomationInstance({
                    id: automationId,
                    automationType: AutomationInstanceUtils.getAutomationInstanceType(automationInstance),
                    modalView: 'task',
                    solutionTypeTag: SolutionTypeTag.WhenTaskCheckedThen,
                  }),
              }),
          }}
        />
      </Tooltip>
      {isLast && notVisibleCount && (
        <LastAutomationInstanceItem
          {...{
            templateRevision,
            taskTemplate,
            editable,
            notVisibleCount,
            isSelected,
            isDisabled,
            iconVariant,
            notVisibleCountVariant,
          }}
        />
      )}
    </HStack>
  );
};

const LastAutomationInstanceItem: React.FC<
  React.PropsWithChildren<Omit<TaskAutomationInstanceItemProps, 'isLast' | 'automationInstance'>> & {
    notVisibleCountVariant?: 'default' | 'badge';
  }
> = ({
  templateRevision,
  taskTemplate,
  notVisibleCount,
  isSelected,
  isDisabled,
  iconVariant,
  notVisibleCountVariant = 'default',
}) => {
  const templateId = templateRevision?.template?.id;

  const {
    instances: { ps_when_task_checked_then },
  } = useSolutionTypeTagInstances({ templateId });

  const { openAutomationInstance } = useAutomationsModalEvents();

  const activeTaskAutomations = ps_when_task_checked_then.filter(i =>
    AutomationInstanceUtils.isEnabledTaskInstance(i, taskTemplate),
  );

  return (
    <Tooltip
      aria-label={`when ${taskTemplate.name} is checked, then...`}
      isDisabled={notVisibleCountVariant === 'badge'}
      label={
        <>
          <Text variant="-2" mb="2">
            When
            <Text as="span" fontWeight="bold" variant="inherit">
              {' '}
              {taskTemplate.name}
            </Text>{' '}
            is checked ...
          </Text>
          <AutomationDescription {...{ taskAutomations: activeTaskAutomations.slice(-notVisibleCount), iconVariant }} />
        </>
      }
      hasArrow
      placement="bottom"
      shouldWrapChildren
    >
      <Button
        aria-label="show more automations"
        variant="unstyled"
        cursor="default"
        position="relative"
        {...(!isDisabled && {
          onClick: () => {
            const id = AutomationInstanceUtils.getInstanceId(activeTaskAutomations.slice(-notVisibleCount)[0]);
            id &&
              openAutomationInstance({
                id,
                automationType: 'tray',
                modalView: 'task',
                solutionTypeTag: SolutionTypeTag.WhenTaskCheckedThen,
              });
          },
          cursor: 'pointer',
        })}
      >
        {notVisibleCountVariant === 'default' ? (
          <Text
            fontSize="md"
            color={(iconVariant === 'brand' ? 'gray.600' : undefined) ?? (isSelected ? 'white' : 'gray.600')}
          >
            +{notVisibleCount}
          </Text>
        ) : (
          <Badge
            position="absolute"
            colorScheme="gray"
            bottom="-4"
            right="-1.5"
            w="3.5"
            h="3.5"
            bgColor="gray.500"
            borderWidth="px"
            borderColor="white"
            borderStyle="solid"
            color="white"
            fontWeight="400"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {notVisibleCount}
          </Badge>
        )}
      </Button>
    </Tooltip>
  );
};

export type SolutionInstancesTooltipProps = React.PropsWithChildren<{
  templateRevision: TemplateRevision;
  taskTemplate: TaskTemplate;
  notVisibleCount: number;
  iconVariant?: AutomationAppIconProps['variant'];
}>;

export const SolutionInstancesTooltip = ({
  templateRevision,
  taskTemplate,
  notVisibleCount,
  iconVariant,
  children,
}: SolutionInstancesTooltipProps) => {
  const templateId = templateRevision?.template?.id;

  const {
    instances: { ps_when_task_checked_then },
  } = useSolutionTypeTagInstances({ templateId });

  const activeTaskAutomations = ps_when_task_checked_then.filter(i =>
    AutomationInstanceUtils.isEnabledTaskInstance(i, taskTemplate),
  );

  return (
    <Tooltip
      aria-label={`when ${taskTemplate.name} is checked, then...`}
      label={
        <>
          <Text variant="-2" mb="1">
            When
            <Text as="span" fontWeight="bold" variant="inherit">
              {' '}
              {taskTemplate.name}
            </Text>{' '}
            is checked ...
          </Text>
          <AutomationDescription {...{ taskAutomations: activeTaskAutomations.slice(-notVisibleCount), iconVariant }} />
        </>
      }
      hasArrow
      placement="bottom"
      shouldWrapChildren
    >
      {children}
    </Tooltip>
  );
};

const AutomationDescription: React.FC<
  React.PropsWithChildren<{
    taskAutomations: AutomationInstance[];
    iconVariant?: AutomationAppIconProps['variant'];
  }>
> = ({ taskAutomations, iconVariant }) => {
  return (
    <UnorderedList spacing="0" mb="0" ml={5}>
      {taskAutomations.map(taskAutomation => (
        <ListItem key={AutomationInstanceUtils.getInstanceId(taskAutomation)}>
          <HStack ml={-1.5}>
            <Text as="span" fontWeight="bold" display="inline" variant="inherit">
              {AutomationInstanceUtils.getLabel(taskAutomation)}
            </Text>

            <Text display="flex" as="span" variant="inherit">
              {'('}
              <HStack as="span">
                <AutomationAppIcon
                  as="span"
                  w="3"
                  automationApp={getPrefixedAutomationAppName(taskAutomation)}
                  display="inline-flex"
                  variant={iconVariant}
                />{' '}
                <Text as="span" variant="inherit">
                  {AutomationInstanceUtils.getAutomationAppName(taskAutomation)}
                </Text>
              </HStack>
              {')'}
            </Text>
          </HStack>
        </ListItem>
      ))}
    </UnorderedList>
  );
};
