import { Muid } from '@process-street/subgrade/core';
import { EntityMap } from '@process-street/subgrade/redux/types';
import { Status } from 'reducers/types';

export const statusState = (
  state: EntityMap<Status>,
  id: Muid,
  loading: boolean,
  error: boolean,
  loaded: boolean,
): EntityMap<Status> => ({
  ...state,
  [id]: {
    error,
    loaded,
    loading,
  },
});
