import create from 'zustand';

type State = {
  statuses: Record<string, { isDragging: boolean }>;
};

type Effects = {
  updateStatus: (type: string, isDragging: boolean) => void;
};

type Store = State & Effects;

export const useInsertWidgetDragStatusStore = create<Store>(set => ({
  statuses: {},
  updateStatus: (type, isDragging) =>
    set(state => ({
      statuses: {
        ...state.statuses,
        [type]: { isDragging },
      },
    })),
}));
