import * as React from 'react';
import { useDisclosure } from 'components/design/next';
import { createUsableContext } from '@process-street/subgrade/util';
import { Muid } from '@process-street/subgrade/core';
import { TemplateSettingsModal } from './template-settings-modal';

/**
 * Type of the modal.
 * - `setupScreen`: single workflow settings dialog, in Edit mode
 * - `dashboardSetupScreen`: single workflow settings dialog, in Dashboard mode (hides revision-specific items)
 * - `onboardingWizard`: setup wizard to be shown on creation of new workflow
 */
export type TemplateSettingsModalVariant = 'setupScreen' | 'dashboardSetupScreen' | 'onboardingWizard';

type Context = ReturnType<typeof useDisclosure> & {
  templateId: Muid;
  templateRevisionId?: Muid;
  variant: TemplateSettingsModalVariant;
  setVariant: React.Dispatch<React.SetStateAction<TemplateSettingsModalVariant>>;
  shouldAutofocusName: boolean;
  setShouldAutofocusName: React.Dispatch<React.SetStateAction<boolean>>;
  openSetup: () => void;
  openOnboarding: () => void;
  editName: () => void;
};

export const [useTemplateSettingsModalContext, TemplateSettingsModalContext] = createUsableContext<Context>({
  hookName: 'useTemplateSettingsModalContext',
  providerName: 'TemplateSettingsModalProvider',
});

interface TemplateSettingsDashboardModalProps {
  templateId: Muid;
  variant: 'dashboardSetupScreen';
  children?: React.ReactNode;
  templateRevisionId?: Muid;
}

interface TemplateSettingsModalProps {
  templateId: Muid;
  templateRevisionId: Muid;
  variant?: TemplateSettingsModalVariant;
  children?: React.ReactNode;
}

export const TemplateSettingsModalProvider = ({
  templateId,
  templateRevisionId,
  variant: variantProp = 'setupScreen',
  children,
}: TemplateSettingsModalProps | TemplateSettingsDashboardModalProps) => {
  const disclosure = useDisclosure();
  const [variant, setVariant] = React.useState<TemplateSettingsModalVariant>(variantProp);
  const [shouldAutofocusName, setShouldAutofocusName] = React.useState(false);

  const value = React.useMemo<Context>(() => {
    const openSetup = () => {
      setVariant('setupScreen');
      disclosure.onOpen();
    };

    const openOnboarding = () => {
      setVariant('onboardingWizard');
    };

    const editName = () => {
      openSetup();
      setShouldAutofocusName(true);
    };

    return {
      ...disclosure,
      templateId,
      templateRevisionId,
      variant,
      setVariant,
      openSetup,
      editName,
      shouldAutofocusName,
      setShouldAutofocusName,
      openOnboarding,
    };
  }, [disclosure, shouldAutofocusName, templateId, templateRevisionId, variant]);

  return (
    <TemplateSettingsModalContext.Provider value={value}>
      {children}
      <TemplateSettingsModal />
    </TemplateSettingsModalContext.Provider>
  );
};
