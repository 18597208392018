import * as React from 'react';
import { Form, Formik, useField } from 'formik';
import { EmailFormFieldWidget, TEXT_MAX_LENGTH, WidgetUtils } from '@process-street/subgrade/process';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Text,
  Textarea,
  useModalContext,
  VStack,
} from 'components/design/next';
import { Placeholder } from '../fields';
import { EmailSettingsSchema, emailSettingsSchema } from './email-settings-schema';
import { DefaultValueField } from '../common/default-value-field';
import { useWidgetSettingsContext } from '../widget-settings-context';

type ConstraintsKey = keyof EmailFormFieldWidget['constraints'];

const ValidationTypeSelectField: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [field, meta] = useField('restriction' as ConstraintsKey);

  return (
    <FormControl>
      <FormLabel htmlFor="restriction">
        <Text variant="1" fontWeight="medium" color="gray.700">
          Domain Validation
        </Text>
      </FormLabel>
      <Select placeholder="Select option" id="restriction" {...field} w="full" isInvalid={meta.touched && !!meta.error}>
        <option value="Allow">Allow specific domains</option>
        <option value="Block">Block specific domains</option>
      </Select>
    </FormControl>
  );
};

const DomainsTextarea: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [field, meta, { setValue }] = useField('domains' as ConstraintsKey);

  const setDomains = (value: string) => {
    const domains = value.trim() !== '' ? splitAndSanitizeDomains(value) : undefined;

    setValue(domains);
  };

  const splitAndSanitizeDomains = (value: string) => {
    return value.split(',').map((d: string) => d.trim());
  };

  const domains = (field.value ?? []).join(',');

  return (
    <FormControl isInvalid={!!meta.error && meta.touched}>
      <Textarea
        {...field}
        id={field.name}
        onChange={e => setDomains(e.target.value)}
        value={domains}
        name="domains"
        aria-label="set list of domains"
        placeholder="example.com, example.org"
        variant="outline"
        size="sm"
        resize="none"
      />
      {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};

/**
 * Removes undefined or false values from the constraints object. Allows a user to reset the constraints to {}.
 */
const sanitizeData = ({
  domains,
  restriction,
  hasVariables: _,
  ...config
}: EmailSettingsSchema): Pick<EmailFormFieldWidget, 'config' | 'constraints'> => {
  return {
    config,
    constraints: {
      ...(Boolean(domains) && { domains }),
      ...(Boolean(restriction) && { restriction }),
    },
  };
};

export const EmailSettings = () => {
  const { widget, onUpdate } = useWidgetSettingsContext<EmailFormFieldWidget>();
  const { onClose } = useModalContext();

  const initialValues = {
    ...widget.config,
    ...widget.constraints,
    defaultValue: widget.config.defaultValue ?? '',
    hasVariables: WidgetUtils.hasVariables(widget.config.defaultValue),
  };

  const handleSubmit = (values: EmailSettingsSchema) => {
    onUpdate({ ...widget, ...sanitizeData(values) });
    onClose();
  };

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader p={8}>
        <Text variant="2">{widget.label || 'Untitled email field'}</Text>
      </ModalHeader>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={emailSettingsSchema}>
        {({ isValid, dirty }) => (
          <Form>
            <ModalBody as={VStack} spacing={6} px={9} py={2}>
              <VStack w="full" spacing={4}>
                <ValidationTypeSelectField />
                <DomainsTextarea />
              </VStack>
              <Placeholder name="placeholder" placeholder="e.g. yourname@domain.com" />
              <DefaultValueField maxLength={TEXT_MAX_LENGTH} />
            </ModalBody>
            <ModalFooter p={6}>
              <ButtonGroup>
                <Button aria-label="cancel changes" variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button aria-label="set constraints" isDisabled={!(isValid && dirty)} type="submit">
                  Apply
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};
