import {
  Box,
  Button,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from 'components/design/next';
import { DataSetColumnDef } from '@process-street/subgrade/process';
import * as React from 'react';
import { useSelectedDataSetContext, useSelectedSavedViewContext } from '../../context';
import _keyBy from 'lodash/keyBy';
import { match } from 'ts-pattern';
import { useDataSetFilters } from '../../store';
import { ColumnOptionType, ColumnsFilterItem } from './columns-filter-item';

export type DataSetColumnsFilterProps = {
  isDisabled: boolean;
};

export const DataSetColumnsFilterV1: React.FC<React.PropsWithChildren<DataSetColumnsFilterProps>> = ({
  isDisabled,
}) => {
  const [selectedDataSet] = useSelectedDataSetContext();
  const [selectedSavedView] = useSelectedSavedViewContext();
  const disclosure = useDisclosure();

  const dataSetFilters = useDataSetFilters();
  const hasFilters = dataSetFilters.filters.length > 0;

  const columnsMap: Record<string, DataSetColumnDef> = React.useMemo(() => {
    if (!selectedDataSet) return {};

    return _keyBy(selectedDataSet.columnDefs, 'id');
  }, [selectedDataSet]);

  const columnOptions: ColumnOptionType[] = React.useMemo(() => {
    if (!selectedDataSet) return [];

    if (selectedSavedView) {
      const columns = selectedDataSet.savedViews.find(sv => sv.id === selectedSavedView.id)?.columns ?? [];

      return columns.map(col => ({
        value: col.id,
        label: columnsMap[col.id]?.name ?? col.id,
      }));
    }

    return selectedDataSet.columnDefs.map(col => ({
      value: col.id,
      label: col.name,
    }));
  }, [selectedDataSet, selectedSavedView, columnsMap]);

  const handleAddFilter = () => {
    dataSetFilters.addFilter();
  };

  const shouldHighlightButton = disclosure.isOpen || hasFilters;

  return (
    <Popover {...disclosure} placement="bottom-start">
      <Tooltip
        label="You don't have access to edit this saved view. Please reach out to the owner or an admin."
        hasArrow={true}
        placement="bottom"
        fontSize="xs"
        isDisabled={!isDisabled}
        shouldWrapChildren
      >
        <PopoverTrigger>
          <Button
            isDisabled={isDisabled}
            onClick={disclosure.onOpen}
            variant="ghost"
            color={shouldHighlightButton ? 'brand.500' : 'gray.600'}
            fontSize="sm"
            fontWeight={shouldHighlightButton ? 500 : 400}
            leftIcon={<Icon icon="filter" size="4" color="inherit" />}
            rightIcon={
              <Icon
                transform={disclosure.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                transition="200ms ease transform"
                icon="chevron-down"
                size="3"
                color="inherit"
              />
            }
            borderWidth="1px"
            borderColor={shouldHighlightButton ? 'brand.500' : 'transparent'}
            borderStyle="solid"
            borderRadius="full"
            bgColor={shouldHighlightButton ? 'brand.100' : 'transparent'}
            px="2"
            _hover={{
              borderColor: 'brand.500',
              bgColor: 'brand.100',
            }}
          >
            {match(dataSetFilters.filters.length)
              .with(0, () => `Filters`)
              .with(1, () => '1 Filter')
              .otherwise(l => `${l} Filters`)}
          </Button>
        </PopoverTrigger>
      </Tooltip>
      <PopoverContent w="700px">
        <PopoverBody p="4">
          <VStack alignItems="flex-start" spacing="4">
            {dataSetFilters.filters.length === 0 && <Text color="gray.500">No filters are currently applied</Text>}
            {dataSetFilters.filters.map((filter, index) => (
              <ColumnsFilterItem
                key={index}
                filter={filter}
                columnOptions={columnOptions}
                columnsMap={columnsMap}
                index={index}
              />
            ))}

            <Box>
              <Button
                onClick={handleAddFilter}
                variant="tertiary"
                leftIcon={<Icon color="inherit" icon="plus" size="4" />}
                color="gray.600"
                colorScheme="brand"
              >
                Add Filter
              </Button>
            </Box>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
