import { FORM_FIELD_VALUE_KEY_PREFIX } from 'services/merge-tags/utils/constants';

export namespace RunLinkService {
  /**
   * Extracts form field values from query params.
   */
  export function extractFieldValueMapFromQueryParams(queryParams: Record<string, string>): Record<string, string> {
    const formFieldValueMap: Record<string, string> = {};

    Object.keys(queryParams).forEach(key => {
      if (key.startsWith(FORM_FIELD_VALUE_KEY_PREFIX)) {
        const strippedKey = key.slice(FORM_FIELD_VALUE_KEY_PREFIX.length);
        formFieldValueMap[strippedKey] = queryParams[key];
      }
    });

    return formFieldValueMap;
  }
}
