import { Muid } from '@process-street/subgrade/core';
import { SavedView } from 'components/dashboard/models/saved-views';
import { GridDataService } from 'components/dashboard/services/grid-data.service';
import { createCachedAction } from 'reducers/util';
import { createAction } from 'redux-actions';

export const CHECKLIST_DASHBOARD_GET_ALL_SAVED_VIEWS_FOR_USER =
  'frontStreet/checklist-dashboard/GET_ALL_SAVED_VIEWS_FOR_USER';
export const CHECKLIST_DASHBOARD_SAVE_SAVED_VIEW = 'frontStreet/checklist-dashboard/SAVE_SAVED_VIEW';
export const CHECKLIST_DASHBOARD_DELETE_SAVED_VIEW = 'frontStreet/checklist-dashboard/DELETE_SAVED_VIEW';
export const CHECKLIST_DASHBOARD_SET_SELECTED_SAVED_VIEW = 'frontStreet/checklist-dashboard/SET_SELECTED_SAVED_VIEW';
export const CHECKLIST_DASHBOARD_GET_OVERDUE_TASKS_BY_CHECKLIST_ID =
  'frontStreet/checklist-dashboard/CHECKLIST_DASHBOARD_GET_OVERDUE_TASKS_BY_CHECKLIST_ID';

export class ChecklistDashboardActions {
  public getSavedViewsByOrganizationId = createCachedAction(
    CHECKLIST_DASHBOARD_GET_ALL_SAVED_VIEWS_FOR_USER,
    (organizationId: Muid) => this.gridDataService.getSavedViewsByOrganizationId(organizationId),
    (organizationId: Muid) => ({ organizationId }),
  );

  public saveSavedView = createCachedAction(CHECKLIST_DASHBOARD_SAVE_SAVED_VIEW, (view: SavedView) =>
    this.gridDataService.saveSavedView(view),
  );

  public deleteSavedView = createCachedAction(
    CHECKLIST_DASHBOARD_DELETE_SAVED_VIEW,
    (organizationId: Muid, viewId: Muid) => this.gridDataService.deleteSavedView(organizationId, viewId),
    (organizationId: Muid, viewId: Muid) => ({ organizationId, viewId }),
  );

  public setSelectedSavedView = createAction(
    CHECKLIST_DASHBOARD_SET_SELECTED_SAVED_VIEW,
    (organizationId: Muid, selectedSavedViewId: Muid) => ({ organizationId, selectedSavedViewId }),
  );

  public getOverdueTasksByChecklistId = createCachedAction(
    CHECKLIST_DASHBOARD_GET_OVERDUE_TASKS_BY_CHECKLIST_ID,
    (checklistId: Muid) => this.gridDataService.getOverdueTasksByChecklistId(checklistId),
    (checklistId: Muid) => ({ checklistId }),
  );

  constructor(private gridDataService: GridDataService) {}
}
