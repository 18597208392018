import { Muid, SandboxRole, SandboxUser } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace CreateSandboxUserMutation {
  export type Params = {
    id: Muid;
    sandboxRole: SandboxRole;
  };

  export type Response = SandboxUser;

  export const key = ['checklist', 'sandbox-user'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/checklists/${params.id}/sandbox-user`, { role: params.sandboxRole })
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
