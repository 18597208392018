import ngRedux from 'ng-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { canAccess, Feature } from 'services/features/features';

const NormalGuestInboxRedirectRoutes = ['organizationManage', 'organizationManage.tab', 'dashboard', 'dashboard.type'];

const LegacyGuestInboxRedirectRoutes = ['organizationManage', 'organizationManage.tab'];

export interface GuestRedirectService {
  getGuestRedirectStateName(targetName: string): string | undefined;
}

export class GuestRedirectServiceImpl implements GuestRedirectService {
  public static $inject = ['$ngRedux'];

  constructor(private readonly $ngRedux: ngRedux.INgRedux) {}

  isGuest() {
    const state = this.$ngRedux.getState();
    return SessionSelector.isUserGuestOfSelectedOrganization(state);
  }

  canGuestRunChecklist() {
    const state = this.$ngRedux.getState();
    const plan = SessionSelector.getCurrentPlan(state);
    return canAccess(Feature.GUESTS_CAN_CREATE_CHECKLISTS, plan?.id);
  }

  public getGuestRedirectStateName(targetName: string): string | undefined {
    const userIsNormalGuest = this.isGuest() && !this.canGuestRunChecklist();
    const userIsLegacyGuest = this.isGuest() && this.canGuestRunChecklist();

    const redirectNormalGuest = userIsNormalGuest && NormalGuestInboxRedirectRoutes.includes(targetName);
    const redirectLegacyGuest = userIsLegacyGuest && LegacyGuestInboxRedirectRoutes.includes(targetName);

    if (redirectNormalGuest || redirectLegacyGuest) {
      return 'inbox';
    }
  }
}
