import { Button, VStack } from '@chakra-ui/react';
import {
  Category,
  PremadeTemplateOverview,
  PremadeTemplatesSearchCriteria,
} from '@process-street/subgrade/process/premade-template-model';
import { LogoSpinner } from 'components/common/LogoSpinner';
import { BlvdSearch, InputColour } from 'components/design/BlvdSearch';
import { useInjector } from 'components/injection-provider';
import { PaywallButton } from 'components/paywalls';
import { useCreateTemplatePaywall } from 'components/paywalls/create-template';
import { useCurrentOrPrivateRootFolderQuery } from 'features/new-menu/query';
import { useNewBlankWorkflowButton } from 'features/new-buttons/use-new-blank-workflow-button';
import { GetAllPremadeTemplateCategoriesQuery, GetPremadeTemplatesSearchQuery } from 'features/template/query-builder';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { CreateTemplateSource } from 'services/template-service.interface';
import { match, P } from 'ts-pattern';
import { TemplateGalleryCards } from '../TemplateGalleryCards';
import { TemplateGalleryCategories } from '../TemplateGalleryCategories';
import { TemplateGalleryHelper } from './template-gallery-helpers';
import styles from './TemplateGallery.module.scss';

enum Mode {
  CategoryView,
  SearchViewWithResults,
  SearchViewNoResults,
}

export const TemplateGallery: React.FC<
  React.PropsWithChildren<{ source: CreateTemplateSource; selectedFolderId?: string }>
> = ({ source, selectedFolderId }) => {
  const { PremadeTemplateService } = useInjector('PremadeTemplateService');
  const { data: currentFolder } = useCurrentOrPrivateRootFolderQuery();
  const handleTemplateCreate = (template: PremadeTemplateOverview) => {
    const folderId = match({ selectedFolderId, currentFolder })
      .with({ selectedFolderId: P.not(undefined) }, () => selectedFolderId)
      .with({ currentFolder: P.not(undefined) }, ({ currentFolder }) => currentFolder.id)
      .otherwise(() => undefined);

    if (folderId) {
      void PremadeTemplateService.createInFolderAndRedirectByOverview({
        folderId,
        premadeTemplateOverview: template,
        source,
      });
    }
  };

  const handleBlankTemplateCreate = () => {
    if (currentFolder) {
      void PremadeTemplateService.createBlankWorkflowInFolderAndRedirect({ folderId: currentFolder.id, source });
    }
  };

  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const [userSearchTerm, setUserSearchTerm] = useState<string>('');
  const [searchCriteria, setSearchCriteria] = useState<PremadeTemplatesSearchCriteria>();
  const [mode, setMode] = useState<Mode>(Mode.CategoryView);

  const categoriesQuery = GetAllPremadeTemplateCategoriesQuery.useQuery(
    { organizationId: selectedOrganizationId },
    {
      select: res => ({
        categories: TemplateGalleryHelper.sortCategories(res.categories, res.defaultCategory),
        defaultCategory: res.defaultCategory,
      }),
      staleTime: Infinity,
    },
  );

  const { buttonProps: newAiGeneratedWorkflowButtonProps } = useNewBlankWorkflowButton({
    source: CreateTemplateSource.LIBRARY_SIDEBAR,
    isAiGenerated: true,
    allowSelectFolder: true,
  });

  const templatesQuery = GetPremadeTemplatesSearchQuery.useQuery(searchCriteria, {
    select: premadeTemplates => {
      if (searchCriteria?.category) {
        return TemplateGalleryHelper.sortTemplates(premadeTemplates);
      } else {
        return premadeTemplates;
      }
    },
    staleTime: Infinity,
  });

  useEffect(() => {
    if (categoriesQuery.data) {
      setSearchCriteria({
        organizationId: selectedOrganizationId,
        category: categoriesQuery.data?.defaultCategory,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
  }, [categoriesQuery.data]);

  useEffect(() => {
    if (templatesQuery.data?.length === 0) {
      setMode(Mode.SearchViewNoResults);
      setSearchCriteria({
        organizationId: selectedOrganizationId,
        category: categoriesQuery.data?.defaultCategory,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
  }, [templatesQuery.data]);

  const onSearchTermChange = (term: string) => {
    setUserSearchTerm(term);
    if (term !== '') {
      setMode(Mode.SearchViewWithResults);
      setSearchCriteria({
        organizationId: selectedOrganizationId,
        searchTerm: term,
      });
    } else {
      setMode(Mode.CategoryView);
      setSearchCriteria({
        organizationId: selectedOrganizationId,
        category: categoriesQuery.data?.defaultCategory,
      });
    }
  };

  const onCategorySelect = (selectedCategory: Category) => {
    setMode(Mode.CategoryView);
    setUserSearchTerm('');
    setSearchCriteria({
      organizationId: selectedOrganizationId,
      category: selectedCategory,
    });
  };

  return (
    <>
      <div className={styles.templateGalleryCategoriesPanel}>
        <div>
          <h5>Choose a Workflow Template</h5>
          <BlvdSearch
            inputColour={InputColour.Default}
            onChange={onSearchTermChange}
            placeholder={'Search 800+ templates'}
            value={userSearchTerm}
          />
          <TemplateGalleryCategories
            categories={categoriesQuery.data?.categories || []}
            selectedCategory={mode === Mode.CategoryView ? searchCriteria?.category : undefined}
            onSelectCategory={onCategorySelect}
          />
        </div>
        <VStack w="full" spacing="4">
          <div className={styles.dividerContainer}>
            <span>or</span>
          </div>

          <PaywallButton useHook={useCreateTemplatePaywall}>
            <Button colorScheme="brand" w="full" {...newAiGeneratedWorkflowButtonProps}>
              Generate with AI
            </Button>
          </PaywallButton>

          <Button w="full" onClick={handleBlankTemplateCreate} variant="solid" colorScheme={'gray'}>
            New Blank Workflow
          </Button>
        </VStack>
      </div>
      <div className={styles.templateContentContainer}>
        <header>
          <h4 className={styles.templateContentHeadline} data-testid="header-results">
            {mode === Mode.CategoryView && <>{searchCriteria?.category?.name}</>}
            {mode === Mode.SearchViewWithResults && templatesQuery.data?.length && (
              <>
                {templatesQuery.data?.length} Result{(templatesQuery.data?.length ?? 0) > 1 ? 's' : ''} for{' '}
                {searchCriteria?.searchTerm}
              </>
            )}
            {mode === Mode.SearchViewNoResults && <>No results for {userSearchTerm}</>}
          </h4>
        </header>
        {templatesQuery.isLoading && (
          <div className={styles.spinnerContainer}>
            <LogoSpinner text="Loading workflows" />
          </div>
        )}
        {mode === Mode.CategoryView && (
          <TemplateGalleryCards onSelect={handleTemplateCreate} templates={templatesQuery.data || []} />
        )}
        {mode === Mode.SearchViewWithResults && templatesQuery.data?.length && (
          <TemplateGalleryCards onSelect={handleTemplateCreate} templates={templatesQuery.data || []} />
        )}
        {mode === Mode.SearchViewNoResults && (
          <>
            <div className={styles.templateContentSubheadline}>Check out these recommended workflows instead:</div>
            <TemplateGalleryCards onSelect={handleTemplateCreate} templates={templatesQuery.data || []} />
          </>
        )}
      </div>
    </>
  );
};
