/**
 * List of all standard tags, that need to be supported by MergeTag functionality
 */
export enum StdMergeTagKey {
  RunCreatedByEmail = 'run.created_by.email',
  RunCreatedByName = 'run.created_by.name',
  RunCreatedDate = 'run.created_date',
  RunDueDate = 'run.due_date.full',
  RunName = 'run.name',
  RunUrl = 'run.url',
  CurrentDate = 'current_date.full',
  CurrentDay = 'current_date.day',
  CurrentMonth = 'current_date.month',
  CurrentYear = 'current_date.year',
  CurrentUserEmail = 'current_user.email',
  CurrentUserName = 'current_user.name',
  OrganizationName = 'organization.name',
  TaskDueDate = 'task.due_date.full',
  TaskName = 'task.name',
  TaskUrl = 'task.url',
  WorkflowName = 'workflow.name',
  WorkflowUrl = 'workflow.url',
  EmailTriggerSubject = 'email_trigger.subject',
  EmailTriggerBody = 'email_trigger.body',
  EmailTriggerSenderName = 'email_trigger.sender.name',
  EmailTriggerSenderEmail = 'email_trigger.sender.email',
}

/**
 * Legacy merge tag names
 */
export enum StdMergeTagKeyLegacy {
  RunCreatedByEmail = 'checklist.created_by.email',
  RunCreatedByName = 'checklist.created_by.name',
  RunCreatedDate = 'checklist.created_date',
  RunDueDate = 'checklist.due_date.full',
  RunName = 'checklist.name',
  RunUrl = 'checklist.url',
  WorkflowName = 'template.name',
  WorkflowUrl = 'template.url',
}

export enum AiTaskPromptMergeTagKey {
  RunCreatedByEmail = 'run.created_by.email',
  RunCreatedByName = 'run.created_by.name',
  RunCreatedDate = 'run.created_date',
  RunDueDate = 'run.due_date.full',
  RunName = 'run.name',
  RunUrl = 'run.url',
  CurrentDate = 'current_date.full',
  CurrentDay = 'current_date.day',
  CurrentMonth = 'current_date.month',
  CurrentYear = 'current_date.year',
  CurrentUserEmail = 'current_user.email',
  CurrentUserName = 'current_user.name',
  OrganizationName = 'organization.name',
  WorkflowName = 'workflow.name',
  WorkflowUrl = 'workflow.url',
  EmailTriggerSubject = 'email_trigger.subject',
  EmailTriggerBody = 'email_trigger.body',
  EmailTriggerSenderName = 'email_trigger.sender.name',
  EmailTriggerSenderEmail = 'email_trigger.sender.email',
}

/**
 * List of all generaly supported tags with names
 */
export const StdMergeTagLabel: Record<StdMergeTagKey, string> = {
  [StdMergeTagKey.WorkflowName]: 'Workflow name',
  [StdMergeTagKey.WorkflowUrl]: 'Workflow URL',
  [StdMergeTagKey.RunName]: 'Workflow Run name',
  [StdMergeTagKey.RunUrl]: 'Workflow Run URL',
  [StdMergeTagKey.RunDueDate]: 'Workflow Run due date',
  [StdMergeTagKey.RunCreatedDate]: 'Workflow Run created date',
  [StdMergeTagKey.RunCreatedByName]: 'Workflow Runner (name)',
  [StdMergeTagKey.RunCreatedByEmail]: 'Workflow Runner (email)',
  [StdMergeTagKey.OrganizationName]: 'Organization name',
  [StdMergeTagKey.TaskName]: 'Task name',
  [StdMergeTagKey.TaskUrl]: 'Task URL',
  [StdMergeTagKey.TaskDueDate]: 'Task due date',
  [StdMergeTagKey.CurrentDate]: 'Current date',
  [StdMergeTagKey.CurrentYear]: 'Current year',
  [StdMergeTagKey.CurrentMonth]: 'Current month',
  [StdMergeTagKey.CurrentDay]: 'Current day of the month',
  [StdMergeTagKey.CurrentUserName]: "Current user's name",
  [StdMergeTagKey.CurrentUserEmail]: "Current user's email",
  [StdMergeTagKey.EmailTriggerSubject]: "Email trigger's subject",
  [StdMergeTagKey.EmailTriggerBody]: "Email trigger's body",
  [StdMergeTagKey.EmailTriggerSenderName]: "Email trigger's sender name",
  [StdMergeTagKey.EmailTriggerSenderEmail]: "Email trigger's sender email",
};

export const StdMergeTagLabelLegacy: Record<StdMergeTagKeyLegacy, string> = {
  [StdMergeTagKeyLegacy.WorkflowName]: 'Workflow name',
  [StdMergeTagKeyLegacy.WorkflowUrl]: 'Workflow URL',
  [StdMergeTagKeyLegacy.RunName]: 'Workflow Run name',
  [StdMergeTagKeyLegacy.RunUrl]: 'Workflow Run URL',
  [StdMergeTagKeyLegacy.RunDueDate]: 'Workflow Run due date',
  [StdMergeTagKeyLegacy.RunCreatedDate]: 'Workflow Run created date',
  [StdMergeTagKeyLegacy.RunCreatedByName]: 'Workflow Runner (name)',
  [StdMergeTagKeyLegacy.RunCreatedByEmail]: 'Workflow Runner (email)',
};

export const AiTaskPromptMergeTagLabel: Record<AiTaskPromptMergeTagKey, string> = {
  [StdMergeTagKey.WorkflowName]: 'Workflow name',
  [StdMergeTagKey.WorkflowUrl]: 'Workflow URL',
  [StdMergeTagKey.RunName]: 'Workflow Run name',
  [StdMergeTagKey.RunUrl]: 'Workflow Run URL',
  [StdMergeTagKey.RunDueDate]: 'Workflow Run due date',
  [StdMergeTagKey.RunCreatedDate]: 'Workflow Run created date',
  [StdMergeTagKey.RunCreatedByName]: 'Workflow Runner (name)',
  [StdMergeTagKey.RunCreatedByEmail]: 'Workflow Runner (email)',
  [StdMergeTagKey.OrganizationName]: 'Organization name',
  [StdMergeTagKey.CurrentDate]: 'Current date',
  [StdMergeTagKey.CurrentYear]: 'Current year',
  [StdMergeTagKey.CurrentMonth]: 'Current month',
  [StdMergeTagKey.CurrentDay]: 'Current day of the month',
  [StdMergeTagKey.CurrentUserName]: "Current user's name",
  [StdMergeTagKey.CurrentUserEmail]: "Current user's email",
  [StdMergeTagKey.EmailTriggerSubject]: "Email trigger's subject",
  [StdMergeTagKey.EmailTriggerBody]: "Email trigger's body",
  [StdMergeTagKey.EmailTriggerSenderName]: "Email trigger's sender name",
  [StdMergeTagKey.EmailTriggerSenderEmail]: "Email trigger's sender email",
};
