import { Muid } from '@process-street/subgrade/core';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import deepEqual from 'deep-equal';

export type TaskTemplateVisibility = {
  taskTemplateId: Muid;
  hiddenByDefault: boolean;
};

export type WidgetVisibility = {
  widgetId: Muid;
  widgetHeaderId: Muid;
  widgetGroupId: Muid;
  hiddenByDefault: boolean;
};

const getDeltaUpdateForHiddenTaskIds = (
  initialHiddenByDefaultIds: Muid[],
  currentHiddenByDefaultIds: Muid[],
): TaskTemplateVisibility[] => {
  const res: TaskTemplateVisibility[] = [];

  const initialIdsSet = new Set(initialHiddenByDefaultIds);
  const currentIdsSet = new Set(currentHiddenByDefaultIds);

  initialHiddenByDefaultIds
    .filter(id => !currentIdsSet.has(id))
    .forEach(id => {
      res.push({
        taskTemplateId: id,
        hiddenByDefault: false,
      });
    });

  currentHiddenByDefaultIds
    .filter(id => !initialIdsSet.has(id))
    .forEach(id => {
      res.push({
        taskTemplateId: id,
        hiddenByDefault: true,
      });
    });

  return res;
};

const getDeltaUpdateForHiddenTasks = (
  initialHiddenByDefaultTasks: TaskTemplate[],
  currentHiddenByDefaultTasks: TaskTemplate[],
) =>
  getDeltaUpdateForHiddenTaskIds(
    initialHiddenByDefaultTasks.map(tt => tt.id),
    currentHiddenByDefaultTasks.map(tt => tt.id),
  );

const getDeltaUpdateForHiddenWidgets = (
  initialWidgetsHiddenByDefault: Widget[],
  currentWidgetsHiddenByDefault: Widget[],
): WidgetVisibility[] => {
  const res: WidgetVisibility[] = [];

  const initialIdsSet = new Set(initialWidgetsHiddenByDefault.map(w => w.id));
  const currentIdsSet = new Set(currentWidgetsHiddenByDefault.map(w => w.id));

  initialWidgetsHiddenByDefault
    .filter(w => !currentIdsSet.has(w.id))
    .forEach(w => {
      res.push({
        widgetId: w.id,
        widgetGroupId: w.header.group.id,
        widgetHeaderId: w.header.id,
        hiddenByDefault: false,
      });
    });

  currentWidgetsHiddenByDefault
    .filter(w => !initialIdsSet.has(w.id))
    .forEach(w => {
      res.push({
        widgetId: w.id,
        widgetGroupId: w.header.group.id,
        widgetHeaderId: w.header.id,
        hiddenByDefault: true,
      });
    });

  return res;
};

export type RulesDeltaUpdate = {
  deletedRuleIds: Muid[];
  newRules: ChecklistRuleDefinition[];
  updatedRules: ChecklistRuleDefinition[];
};

const getDeltaUpdateForRules = (
  initialRules: ChecklistRuleDefinition[],
  currentRules: ChecklistRuleDefinition[],
): RulesDeltaUpdate => {
  const initialIds = initialRules.map(rule => rule.id);
  const currentIds = currentRules.map(rule => rule.id);

  const deletedRuleIds = initialIds.filter(id => !currentIds.includes(id));
  const newRules = currentRules.filter(rule => !initialIds.includes(rule.id));
  const updatedRules = currentRules.filter(rule => {
    const initialRule = initialRules.find(r => r.id === rule.id);

    return initialRule && !deepEqual(initialRule, rule);
  });

  return {
    deletedRuleIds,
    newRules,
    updatedRules,
  };
};

export const RulesManagerHelper = {
  getDeltaUpdateForHiddenTaskIds,
  getDeltaUpdateForHiddenTasks,
  getDeltaUpdateForHiddenWidgets,
  getDeltaUpdateForRules,
};
