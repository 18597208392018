import * as React from 'react';
import { TemplateLibrary } from 'pages/library/components/template-library';
import { LibraryPageProvider, useLibraryContext } from './context';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  StackDivider,
  Text,
  VStack,
} from 'components/design/next';
import { Breadcrumbs } from 'components/breadcrumbs';
import { match } from 'ts-pattern';
import { useFoldersPathQuery } from 'hooks/use-folders-path-query';
import { LeftNav } from './left-nav';
import { FolderRowMenu, MenuItemLink } from 'pages/library/components/template-library/folder-row/menu';
import { FolderPermissionsIndicator } from 'components/permissions/folder-permissions-indicator/component';
import { useInjector } from 'components/injection-provider';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useGetOrganizationTagsQuery } from 'features/tags/query-builder';
import { isAdmin } from '@process-street/subgrade/core';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const Library = () => {
  return (
    <LibraryPageProvider>
      <DndProvider backend={HTML5Backend} context={window}>
        <Flex as="section" h="calc(100vh - var(--ps-navbar-offset))" w="100vw">
          <LeftNav />
          <Content />
        </Flex>
      </DndProvider>
    </LibraryPageProvider>
  );
};

const TextHeader: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <nav aria-label="breadcrumb">
    <Flex h="8" alignItems="center" color="gray.500">
      <Text as="h1">{children}</Text>
    </Flex>
  </nav>
);

const Content: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { view } = useLibraryContext();

  return (
    <VStack
      divider={<StackDivider borderColor="gray.200" borderStyle="solid" sx={{ my: '0 !important' }} />}
      w="full"
      alignItems="start"
      spacing="0"
      flex="1"
      overflow="hidden"
    >
      {match(view)
        .with('folder', () => <FolderHeader />)
        .with('scheduled', () => <ScheduledHeader />)
        .with('tag', () => <TagHeader />)
        .exhaustive()}
      <TemplateLibrary />
    </VStack>
  );
};

const ScheduledHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <HStack py="4" px="8">
      <TextHeader>Scheduled</TextHeader>
    </HStack>
  );
};

const TagHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { $state } = useInjector('$state');
  const { path: tagName, templateStatusView, setTemplateStatusView } = useLibraryContext();
  const orgId = useSelector(SessionSelector.getSelectedOrganizationId);
  const tagQuery = useGetOrganizationTagsQuery(orgId ?? '', {
    select: tags => tags.find(tag => tag.name === tagName),
  });

  const orgMembership = useSelector(OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId);
  const canRename = orgMembership ? isAdmin(orgMembership) : false;

  return (
    <HStack py="4" px="8">
      <TextHeader>{tagName}</TextHeader>

      {templateStatusView === 'active' ? (
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="sm"
            icon={<Icon icon="cog" variant="far" size="4" color="gray.500" />}
            aria-label="manage tag"
            isLoading={tagQuery.isLoading}
          />
          <MenuList>
            {canRename ? (
              <MenuItemLink
                href={tagQuery.data ? $state.href('tagManage', { id: tagQuery.data.id }) : '#'}
                icon={<Icon size="4" variant="far" icon="edit" />}
              >
                Rename
              </MenuItemLink>
            ) : null}

            <MenuItem
              onClick={() => setTemplateStatusView('archived')}
              icon={<Icon icon="eye-slash" variant="far" size="4" />}
            >
              Show archived
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <Button variant="solid" colorScheme="red" size="xs" onClick={() => setTemplateStatusView('active')}>
          Stop showing archived
        </Button>
      )}
    </HStack>
  );
};

const FolderHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const foldersPathQuery = useFoldersPathQuery();
  const { templateStatusView, setTemplateStatusView } = useLibraryContext();

  return match(foldersPathQuery)
    .with({ status: 'success' }, ({ data: foldersPath }) => {
      const [currentFolder] = foldersPath.slice(-1);
      if (!currentFolder) return null;

      return (
        <HStack w="full" minH="8" py="4" px="8">
          <Breadcrumbs folderId={currentFolder.id} enableDnd />
          <Box color="gray.500">
            <FolderPermissionsIndicator folderId={currentFolder.id} organizationId={currentFolder.organization.id} />
          </Box>

          {templateStatusView === 'active' ? (
            <FolderRowMenu folder={currentFolder}>
              <MenuItem
                onClick={() => setTemplateStatusView('archived')}
                icon={<Icon icon="eye-slash" variant="far" size="4" />}
              >
                Show archived
              </MenuItem>
            </FolderRowMenu>
          ) : (
            <Button variant="solid" colorScheme="red" size="xs" onClick={() => setTemplateStatusView('active')}>
              Stop showing archived
            </Button>
          )}
        </HStack>
      );
    })
    .otherwise(() => null);
};
