import * as React from 'react';
import { match } from 'ts-pattern';

import { ChecklistInboxItem, InboxItemType, TaskInboxItem, InboxItem } from '@process-street/subgrade/inbox';

import { HStack } from 'components/design/next';

import { ChecklistItem } from './checklist';
import { TaskItem } from './task';

export const Item = ({ item }: { item: InboxItem }) => {
  return (
    <HStack
      as="li"
      spacing="6"
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      px="6"
      py="3"
      bg="white"
      width="full"
    >
      {match(item)
        .with({ itemType: InboxItemType.Checklist }, () => <ChecklistItem item={item as ChecklistInboxItem} />)
        .with({ itemType: InboxItemType.StandardTask }, () => <TaskItem item={item as TaskInboxItem} />)
        .with({ itemType: InboxItemType.ApprovalTask }, () => <TaskItem item={item as TaskInboxItem} />)
        .with({ itemType: InboxItemType.OneOffTask }, () => <TaskItem item={item as TaskInboxItem} />)
        .otherwise(() => null)}
    </HStack>
  );
};
