import angular from 'angular';
import { getTabIndexByName, initializeTabs } from 'services/tab-service';
import membersTemplate from './members.component.html';
import activityTemplate from './activity.component.html';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { trace } from 'components/trace';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'ChecklistManageCtrl',
    function ($scope, $state, $timeout, DataService, SessionService, ChecklistService, ToastService) {
      const ctrl = this;
      const logger = trace({ name: 'ChecklistManageCtrl' });
      logger.info('loading ctrl');

      const possibleTabs = [
        {
          name: 'members',
          heading: 'Members',
          templateUrl: membersTemplate,
        },
        {
          name: 'activity',
          heading: 'Activity',
          templateUrl: activityTemplate,
        },
      ];

      ctrl.$onInit = () => {
        const userInfo = SessionService.getUserInfo();
        const permittedRoles = [
          OrganizationMembershipRole.Admin,
          OrganizationMembershipRole.FullMember,
          OrganizationMembershipRole.FreeMember,
        ];
        if (permittedRoles.includes(userInfo?.organizationMembership.role)) {
          $scope.tabs = possibleTabs;
        } else {
          $scope.tabs = possibleTabs.filter(tab => tab.name === 'activity');
        }

        $scope.activeIndex = getTabIndexByName($scope.tabs, $state.params.tab) || 0;

        initializeChecklist().then(
          () => {
            initializeTabs($scope.tabs, $state, $scope);
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading that workflow run`,
              description: DefaultErrorMessages.notFoundErrorDescription,
            });

            $state.go('dashboard');
          },
        );
      };

      $scope.$on('$stateChangeSuccess', (__event, __toState, toParams) => {
        $scope.activeIndex = getTabIndexByName($scope.tabs, toParams.tab) || 0;
      });

      // Initialization

      function initializeChecklist() {
        $scope.checklist = DataService.getCollection('checklists').getById($state.params.id);

        if ($scope.checklist) {
          return $timeout();
        } else {
          return ChecklistService.getById($state.params.id).then(checklist => {
            $scope.checklist = checklist;
          });
        }
      }
    },
  );
