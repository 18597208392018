import { Box, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { Icon } from 'components/design/next';
import {
  ChartBox,
  ChartBoxChart,
  ChartBoxHeader,
  ChartBoxProps,
  ChartBoxTitle,
} from 'pages/reports/components/chart-box';
import {
  ChecklistStatusSummary,
  ChecklistStatusSummaryChartProps,
} from 'pages/reports/components/checklist-status-summary';
import { ChecklistTaskStatusSummary } from 'pages/reports/components/checklist-task-status-summary';
import { TASK_STATUS_KEYS_COLORS, TASK_STATUS_LABELS } from 'pages/reports/components/common/constants';
import * as React from 'react';
import { SectionWrapper } from '../section-wrapper';

const chartBoxProps: ChartBoxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  bgColor: 'transparent',
  w: 'full',
  border: 0,
  p: 0,
};

const chartConfig: ChecklistStatusSummaryChartProps['config'] = {
  thickness: 20,
  hideLegend: true,
  margin: 0,
};

const PIE_CHART_SIZE = '140px';

export const PieCharts = () => {
  return (
    <VStack as={SectionWrapper} minH="70" justifyContent="flex-start" spacing={9}>
      <SimpleGrid templateColumns={{ base: '1fr', md: '1fr 1fr' }} w="100%" rowGap={9}>
        <ChartBox {...chartBoxProps}>
          <ChartBoxHeader color="gray.600" spacing="2">
            <Icon icon="table-list" size="4" />
            <ChartBoxTitle>Active runs</ChartBoxTitle>
          </ChartBoxHeader>

          <ChartBoxChart w="full" maxW={PIE_CHART_SIZE} maxH={PIE_CHART_SIZE}>
            <ChecklistStatusSummary config={chartConfig} mode={'Limited'} />
          </ChartBoxChart>
        </ChartBox>

        <ChartBox {...chartBoxProps}>
          <ChartBoxHeader color="gray.600" spacing="2">
            <Icon icon="check-square" size="4" />
            <ChartBoxTitle>All tasks</ChartBoxTitle>
          </ChartBoxHeader>

          <ChartBoxChart w="full" maxW={PIE_CHART_SIZE} maxH={PIE_CHART_SIZE}>
            <ChecklistTaskStatusSummary config={chartConfig} mode={'Limited'} />
          </ChartBoxChart>
        </ChartBox>
      </SimpleGrid>

      <SimpleGrid
        templateColumns={{ base: 'repeat(2, max-content)', md: 'repeat(5, max-content)' }}
        alignItems="center"
        justifyContent="center"
        w="full"
        columnGap={6}
        rowGap={4}
      >
        {TASK_STATUS_KEYS_COLORS.map(({ status, color }) => (
          <HStack key={status}>
            <Box w="3.5" h="3.5" bgColor={color} borderRadius="sm" />

            <Text variant="-2" color="gray.400">
              {TASK_STATUS_LABELS[status]}
            </Text>
          </HStack>
        ))}
      </SimpleGrid>
    </VStack>
  );
};
