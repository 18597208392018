import * as React from 'react';
import {
  Center,
  HStack,
  StackProps,
  TextProps,
  VStack,
  Text,
  Flex,
  FlexProps,
  Spinner,
  Button,
  ButtonProps,
  Box,
  useBreakpointValue,
} from 'components/design/next';

import { EmptyFolder } from './empty-folder';
import { NgLink, NgLinkProps } from 'features/app/components/ng-link';

export type RowProps = StackProps;

export const Row: React.FC<React.PropsWithChildren<RowProps>> = props => {
  return <HStack h="16" px={{ base: '2', md: '4' }} w="full" overflow="hidden" {...props} />;
};

export const RightButton: React.FC<React.PropsWithChildren<StackProps>> = props => {
  return <HStack spacing="2" w={{ base: '10', md: '28' }} justifyContent="flex-end" {...props} />;
};

export const NoWorkflowRunsText: React.FC<React.PropsWithChildren<TextProps>> = props => {
  return <Text variant="-2" as="span" color="gray.500" {...props} />;
};

export const IconLinkContainer: React.FC<React.PropsWithChildren<FlexProps>> = ({ ...props }) => {
  return <Flex display={['none', null, 'flex']} justifyContent="flex-start" w={'15'} mr="8" {...props} />;
};

export const ReportsLink: React.FC<
  React.PropsWithChildren<ButtonProps & NgLinkProps & { icon?: React.ReactElement }>
> = ({ icon, children, ...props }) => {
  return (
    <Button
      as={NgLink}
      minWidth="0"
      variant="ghost"
      size="sm"
      color="gray.500"
      px="2"
      _hover={{ textDecor: 'none', color: 'gray.500', bg: 'gray.100' }}
      {...props}
    >
      <HStack>
        {icon}
        {children}
      </HStack>
    </Button>
  );
};

export const WorkflowRunsLinkText: React.FC<React.PropsWithChildren<TextProps>> = props => {
  return <Text variant="-2" noOfLines={1} {...props} />;
};

export const RowPermissionsIndicator: React.FC<React.PropsWithChildren<FlexProps>> = props => {
  return <Flex display={['none', null, 'flex']} ml="0" mr="4" w="23" color="gray.500" {...props} />;
};

export const RowLink: React.FC<React.PropsWithChildren<NgLinkProps & { icon: React.ReactElement }>> = ({
  icon,
  children,
  ...props
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const containerProps = { flex: '1', minW: '0', display: 'flex', alignSelf: 'stretch', alignItems: 'center' };

  const content = (
    <HStack spacing="6" w="full" aria-label={`library-row ${children}`}>
      {React.cloneElement(icon)}
      <Text noOfLines={1}>{children}</Text>
    </HStack>
  );

  if (isMobile)
    return React.cloneElement(content, {
      ...props,
      ...containerProps,
    });

  return (
    <NgLink {...containerProps} {...props}>
      {content}
    </NgLink>
  );
};

export const RowItem: React.FC<React.PropsWithChildren<StackProps & { icon: React.ReactElement }>> = ({
  icon,
  children,
  ...props
}) => {
  return (
    <Box flex="1" minW="0" display="flex" alignSelf="stretch" alignItems="center">
      <HStack spacing="6" w="full" aria-label={`library-row ${children}`} {...props}>
        {React.cloneElement(icon)}
        <Text noOfLines={1}>{children}</Text>
      </HStack>
    </Box>
  );
};

type LibraryListProps = StackProps & { isLoading: boolean; isEmpty: boolean; isPrivateLibrary?: boolean };

export const LibraryList: React.FC<React.PropsWithChildren<LibraryListProps>> = ({
  isLoading,
  isEmpty,
  isPrivateLibrary,
  children,
  ...props
}) => {
  return (
    <VStack alignItems="stretch" w="full" h="full" flex="1 1 auto" {...props}>
      {isLoading ? (
        <Center h="full">
          <Spinner />
        </Center>
      ) : null}

      {isEmpty ? <EmptyFolder isPrivateLibrary={isPrivateLibrary} /> : children}
    </VStack>
  );
};
