import * as React from 'react';
import { Input, useMenuItem, InputProps, MenuItemProps } from 'components/design/next';
import { KeyStrings } from 'services/key';

const NAV_KEYS = [KeyStrings.ARROW_DOWN, KeyStrings.ARROW_UP, KeyStrings.ESCAPE];

// https://github.com/chakra-ui/chakra-ui/issues/3697#issuecomment-811118964
export const MenuInput: React.FC<React.PropsWithChildren<InputProps>> = props => {
  const { role, ...rest } = useMenuItem(props as MenuItemProps);
  return (
    <Input
      {...{
        ...rest,
        onClick: e => e.stopPropagation(),
        onKeyDown: e => {
          if (!NAV_KEYS.includes(e.key)) {
            e.stopPropagation();
          }
        },
      }}
    />
  );
};
