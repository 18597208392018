import { useFunctionRef } from 'hooks/use-function-ref';
import * as React from 'react';
import { match, P } from 'ts-pattern';
import { MergeTagFilter } from '@process-street/subgrade/merge-tags';

export function insertText({
  selectionEnd,
  selectionStart,
  value,
  injection,
}: {
  selectionStart: number;
  selectionEnd: number;
  value: string;
  injection: string;
}) {
  const before = value.substring(0, selectionStart);
  const after = value.substring(selectionEnd, value.length);
  return `${before}${injection}${after}`;
}

export const createMergeTag = (key: string, fallback?: string) =>
  match(fallback)
    .with(P.string, fallback => `{{${key}|${MergeTagFilter.Fallback}:'${fallback}'}}`)
    .otherwise(() => `{{${key}}}`);

export function useInsertMergeTagCallback({
  value,
  getStartIndex,
  getEndIndex,
  onChange,
}: {
  value: string;
  /** lazy to support refs that won't cause re-renders */
  getStartIndex: () => number | undefined;
  /** lazy to support refs that won't cause re-renders */
  getEndIndex: () => number | undefined;
  onChange: ({ injection, result }: { injection: string; result: string }) => void;
}): (tag: string, fallback?: string) => void {
  const onChangeRef = useFunctionRef(onChange);
  const cb = React.useCallback(
    (tag: string, fallback?: string) => {
      const injection = createMergeTag(tag, fallback);

      const result = insertText({
        selectionStart: getStartIndex() ?? value.length,
        selectionEnd: getEndIndex() ?? value.length,
        value,
        injection,
      });
      onChangeRef.current({ result, injection });
    },
    [getStartIndex, onChangeRef, getEndIndex, value],
  );
  return cb;
}
