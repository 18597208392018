import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { OrganizationActivityPage } from './activity';

export const OrganizationActivityPageModule = angular
  .module('OrganizationActivityPageModule', [])
  .component(
    'psOrganizationActivityPage',
    reactToAngular(OrganizationActivityPage, ['sentences', 'onFilterChange', 'showLoadMore', 'loadMore', 'isLoading']),
  ).name;
