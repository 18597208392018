import * as React from 'react';
import { Muid } from '@process-street/subgrade/core';
import create from 'zustand';
import { GetAllDataSetsQuery } from 'pages/reports/data-sets/query-builder';
import { SavedViewType } from '@process-street/subgrade/process';

interface SelectedSavedViewIdState {
  dataSetId?: Muid;
  savedViewId?: Muid;
}

interface SelectedSavedViewIdEffects {
  setDataSetId: (dataSetId: Muid | undefined) => void;
  setSavedViewId: (savedViewId: Muid | undefined) => void;
}

type SelectedSavedViewIdStore = SelectedSavedViewIdState & SelectedSavedViewIdEffects;

export const useSelectedSavedViewIdStore = create<SelectedSavedViewIdStore>(set => ({
  dataSetId: undefined,
  savedViewId: undefined,
  setDataSetId: (dataSetId: Muid | undefined) => set({ dataSetId }),
  setSavedViewId: (savedViewId: Muid | undefined) => set({ savedViewId }),
}));

export const useSelectedSavedView = () => {
  const dataSetPageStore = useSelectedSavedViewIdStore();
  const { isFetching, data } = GetAllDataSetsQuery.useQuery();

  const { dataSets, selectedDataSet, selectedSavedView, savedViews } = React.useMemo(() => {
    const dataSets = (data ?? []).slice().sort((a, b) => a.name.localeCompare(b.name));
    const selectedDataSet = dataSets.find(ds => ds.id === dataSetPageStore.dataSetId);

    // Saved views sorted by type, so All Data will be always the first.
    const savedViews = (selectedDataSet?.savedViews.slice() ?? []).sort((a, b) => {
      if (a.savedViewType === SavedViewType.AllData) {
        return -100;
      } else if (b.savedViewType === SavedViewType.AllData) {
        return 100;
      } else {
        return a.name.localeCompare(b.name);
      }
    });

    const selectedSavedView = savedViews.find(sv => sv.id === dataSetPageStore.savedViewId);
    return {
      dataSets,
      selectedDataSet,
      selectedSavedView,
      savedViews,
    };
  }, [data, dataSetPageStore.dataSetId, dataSetPageStore.savedViewId]);

  return {
    dataSetPageStore,
    dataSets,
    isFetching,
    savedViews,
    selectedDataSet,
    selectedSavedView,
  };
};
