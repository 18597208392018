import * as React from 'react';
import { Form, Formik } from 'formik';
import { Alert, AlertIcon, Box, Button, FormControl, FormLabel, Select, VStack } from 'components/design/next';
import { Icon } from 'components/design/next';
import { getError, hasError } from '../helpers';
import { Title } from '../components/title';
import { ShellContentActions } from '../components/shell-content-actions';
import { referralFormSchema } from 'pages/sign-up/referral/form.schema';
import { TextInputField } from 'pages/sign-up/components/text-input-field';
import { USE_CASE_OPTIONS } from '@process-street/subgrade/core';

export type FormValues = {
  referral: string;
  useCase: string;
};

export type ReferralFormProps = {
  error?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  initialValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
};

const defaultValues: FormValues = {
  referral: '',
  useCase: '',
};

export const ReferralForm: React.VFC<ReferralFormProps> = ({
  onSubmit,
  isLoading,
  isDisabled,
  initialValues,
  error,
}) => {
  return (
    <Formik
      validationSchema={referralFormSchema}
      onSubmit={onSubmit}
      initialValues={{ ...defaultValues, ...initialValues }}
      enableReinitialize
    >
      {({ handleSubmit, handleChange, handleBlur, values, submitCount, touched, errors }) => (
        <Box w="full" sx={{ '&>form': { width: 'full' } }}>
          <Form onSubmit={handleSubmit}>
            <VStack m="0 auto" spacing="8" alignItems="flex-start" maxWidth="xl">
              <Title>
                Why are you thinking about
                <br />
                Process Street?
              </Title>

              {error && (
                <Alert status="error">
                  <AlertIcon />
                  {error}
                </Alert>
              )}

              <FormControl isInvalid={hasError('useCase', submitCount, errors, touched)}>
                <FormLabel mb="2.5">What use case do you have in mind?</FormLabel>

                <Select
                  color={values.useCase ? 'gray.600' : 'gray.400'}
                  borderWidth="thin"
                  borderColor="gray.300"
                  backgroundColor="white"
                  borderRadius="base"
                  height="12"
                  width="full"
                  name="useCase"
                  value={values.useCase}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoFocus={true}
                  aria-describedby="text-input-department-error"
                  placeholder="Select use case"
                >
                  {USE_CASE_OPTIONS.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <TextInputField
                error={getError('referral', submitCount, errors, touched)}
                label="How did you hear about us?"
                name="referral"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.referral}
                placeholder="Let us know what led you to Process Street"
                height="24"
                pt="1"
                as="textarea"
              />

              <ShellContentActions pt="33px">
                <Button
                  px="5"
                  height="12"
                  iconSpacing="2"
                  type="submit"
                  leftIcon={<Icon size="4" variant="far" icon="check" />}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                >
                  Next
                </Button>
              </ShellContentActions>
            </VStack>
          </Form>
        </Box>
      )}
    </Formik>
  );
};
