import React from 'react';

import type { Template } from '@process-street/subgrade/process';

import { Box, Text } from 'components/design/next';

import { TemplateCopyLinkButton } from 'components/template-copy-link-button';
import { useGetTemplateQuery } from 'features/template/query-builder';

export type Props = { templateId: Template['id'] };
export const TemplateHeaderTitle: React.FC<React.PropsWithChildren<Props>> = ({ templateId }) => {
  const { data: template } = useGetTemplateQuery({ templateId });
  return (
    <Text
      display="inline-flex"
      as="h2"
      variant="2"
      fontWeight="bold"
      m="0"
      color="gray.900"
      lineHeight="shorter"
      px="2"
      mt="6"
      mb="1.5"
    >
      {template?.name}
      <Box my="-2">
        <TemplateCopyLinkButton aria-label="copy template share link" templateId={templateId} />
      </Box>
    </Text>
  );
};
