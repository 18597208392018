import { isPartialUrl } from '@process-street/subgrade/process';
import * as yup from 'yup';
import { createTestFunction } from '../helpers';

export const employeeCountOptions = ['1-29', '30-99', '100-249', '250-999', '1000+'];

export const departmentOptions = [
  'Engineering',
  'Human Resources',
  'Legal',
  'Marketing',
  'IT',
  'Product',
  'Sales',
  'Support',
  'Other',
  'Finance',
  'Operations',
  'Company Wide',
  'Customer Success',
];

const requiredValidationMsg = 'Required field';
const organizationValidationMsg = 'Invalid organization name';

export const validateOrganizationNameURL = createTestFunction(organizationName => !isPartialUrl(organizationName));

export const organizationDetailsSchema = yup.object().shape({
  organizationName: yup
    .string()
    .trim()
    .required(requiredValidationMsg)
    .test('Is not an URL', organizationValidationMsg, validateOrganizationNameURL),
  employeeCount: yup.string().oneOf(employeeCountOptions).required(requiredValidationMsg),
  department: yup.string().oneOf(departmentOptions).required(requiredValidationMsg),
});
