import * as React from 'react';
import {
  Box,
  Divider,
  AbsoluteCenter,
  Menu,
  MenuButton,
  MenuList,
  Input,
  Button,
  FormControl,
  Text,
  Icon,
  FormLabel,
  MenuItem,
  useMenuItem,
  MenuItemProps,
  MenuDivider,
} from 'components/design/next';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { StringUtils } from '@process-street/subgrade/util';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { getStepName } from '../../helpers/steps';
import { faArrowsUpDownLeftRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type StepHeaderCellProps = {
  index: number;
  onNameChange: (name: string) => void;
  onMove: (toIndex: number) => void;
  onDuplicate: () => void;
  onDelete: () => void;
  taskTemplates: TaskTemplate[];
  widgets: Widget[];
};

export const StepHeaderCell: React.FC<React.PropsWithChildren<StepHeaderCellProps>> = ({
  index,
  onNameChange,
  onMove,
  taskTemplates,
  widgets,
  onDelete,
  onDuplicate,
}) => {
  const taskTemplate = taskTemplates[index];
  const stepName = StringUtils.getNonEmpty(taskTemplate.name, `Step ${index + 1}`);

  const isDeleteDisabled = taskTemplates.length === 1 && widgets.length === 0;

  const renderKey = `${stepName}:${index}`;

  return (
    <Box position="relative" py="6">
      <Divider />

      <AbsoluteCenter bg="white" px="4" zIndex="2">
        <Menu>
          {({ onClose }) => (
            <>
              <MenuButton
                as={Button}
                color="gray.400"
                variant="ghost"
                size="sm"
                colorScheme="gray"
                iconSpacing="0"
                pr={6}
                rightIcon={<Icon icon="cog" size="3.5" mr="-4" pl="1" />}
              >
                <Text variant="-2u" fontWeight="700">
                  {stepName}
                </Text>
              </MenuButton>
              <MenuList
                zIndex="dropdown"
                sx={{
                  '.chakra-menu__menuitem': {
                    // match the height of the option with the BlvdSelect
                    h: '56px',
                  },
                }}
              >
                <MenuItemWithRenderProps>
                  {({ role, ...rest }) => (
                    <FormControl py="2" px="4" role={role}>
                      <Input
                        {...(rest as any)}
                        key={renderKey}
                        defaultValue={stepName}
                        onBlur={e => {
                          const newName = e.target.value;
                          if (newName !== stepName) {
                            onNameChange(newName);
                          }
                        }}
                        onKeyDown={e => {
                          if (!NAVIGATION_KEYS.includes(e.key)) {
                            e.stopPropagation();
                          }
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                            e.currentTarget.blur();
                            onClose();
                          }
                        }}
                      />
                    </FormControl>
                  )}
                </MenuItemWithRenderProps>

                <MenuItem icon={<Icon icon="copy" size="4" variant="far" color="gray.600" />} onClick={onDuplicate}>
                  Duplicate
                </MenuItem>

                <MenuItemWithRenderProps>
                  {({ role }) => (
                    <FormControl
                      py="2"
                      px="4"
                      display="flex"
                      alignItems="center"
                      sx={{ '.blvd-select': { flex: 1 } }}
                      role={role}
                      gap="2"
                      _hover={{ bgColor: 'brand.100' }}
                    >
                      <Box as={FontAwesomeIcon} icon={faArrowsUpDownLeftRight} color="gray.600" />
                      <FormLabel mb="0">Move to</FormLabel>
                      <BlvdSelect
                        key={renderKey}
                        isDisabled={taskTemplates.length === 1}
                        onChange={value => {
                          if (!value) return;
                          onMove(value.value);
                        }}
                        options={taskTemplates
                          .map((tt, i) => ({
                            label: `${i + 1} (${getStepName({ taskTemplate: tt, number: i + 1 })})`,
                            value: i,
                          }))
                          .filter(i => i.value !== index)}
                      />
                    </FormControl>
                  )}
                </MenuItemWithRenderProps>

                <MenuDivider />
                <MenuItem
                  onClick={onDelete}
                  isDisabled={isDeleteDisabled}
                  icon={<Icon icon="trash-alt" size="4" variant="far" color="red.500" />}
                  color="red.500"
                  closeOnSelect={false}
                >
                  Delete step
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </AbsoluteCenter>
    </Box>
  );
};

// An easy way to get the MenuItem props without having to use the MenuItems (for custom items)
const MenuItemWithRenderProps = ({ children }: { children: (props: MenuItemProps) => React.ReactElement }) => {
  return children(useMenuItem());
};

const NAVIGATION_KEYS = ['ArrowUp', 'ArrowDown', 'Escape'];
