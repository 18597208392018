import React from 'react';
import { User } from '@process-street/subgrade/core';
import { HStack, Text, VStack } from 'components/design/next';
import { Avatar, AvatarSize } from 'components/common/Avatar';
import { UserUtils } from '@process-street/subgrade/util';

export interface GroupMemberListProps {
  members: User[];
  showEmail?: boolean;
  limit?: number;
}

export const GroupMemberList: React.FC<React.PropsWithChildren<GroupMemberListProps>> = ({
  members,
  showEmail = false,
  limit = 10,
}) => {
  return (
    <VStack spacing={2} align="stretch">
      {members.slice(0, limit).map(member => (
        <HStack spacing="2" key={member.id}>
          <Avatar size={AvatarSize.Small} user={member} />
          <Text fontSize="sm" color="gray.600">
            {UserUtils.getLabel(member, showEmail)}
          </Text>
        </HStack>
      ))}
    </VStack>
  );
};
