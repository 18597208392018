import { Muid, Option } from '@process-street/subgrade/core';
import { FormFieldValue, TaskTemplate, Widget, WidgetType } from '@process-street/subgrade/process';
import { ObjectMap } from '@process-street/subgrade/redux/types';
import angular from 'angular';
import { FormFieldValueActions } from 'components/form-field-value/store/form-field-value.actions';
import { FormFieldValueSelector } from 'components/form-field-value/store/form-field-value.selectors';
import { WidgetActions } from 'components/widgets/store/widget.actions';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import ngRedux from 'ng-redux';
import { ReduxAppState } from 'reducers/types';
import { connectController } from 'reducers/util';
import { WidgetService } from 'services/widget-service.interface';
import templateUrl from './widgets-container.component.html';
import './widgets-container.component.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ToastService } from 'services/toast-service.interface';

interface InternalState {
  widgets: Widget[];
  formFieldValueMap: ObjectMap<FormFieldValue>;
}

interface InternalActions {
  getAllWidgetsByChecklistRevisionId(checklistRevisionId: Muid): Promise<void>;
  getAllFormFieldValuesByChecklistRevisionId(checklistRevisionId: Muid): Promise<void>;
  getAllChecklistWidgetsByChecklistRevisionId(checklistRevisionId: Muid): Promise<void>;
}

export class ApprovalSubjectTaskWidgetsContainerController {
  public checklistRevisionId: Option<Muid>;
  public taskId: Option<Muid>;
  public taskTemplate: Option<TaskTemplate>;
  public widgetsReplacingText: Option<string>;

  public state: Option<InternalState>;
  public actions: Option<InternalActions>;

  static $inject = ['$ngRedux', 'ToastService', 'FormFieldValueActions', 'WidgetActions', 'WidgetService'];
  constructor(
    private $ngRedux: ngRedux.INgRedux,
    private toastService: ToastService,
    private formFieldValueActions: FormFieldValueActions,
    private widgetActions: WidgetActions,
    private widgetService: WidgetService,
  ) {
    const mapStateToThis = () => (state: ReduxAppState) => {
      const checklistWidgets =
        this.checklistRevisionId && this.taskTemplate
          ? WidgetSelector.getAllWithTaskTemplateByChecklistRevisionId(
              this.checklistRevisionId!,
              true /* excludeHidden  */,
            )(state)
          : [];

      const formFieldValues = this.taskId ? FormFieldValueSelector.getAllByTaskId(this.taskId!)(state) : [];

      const formFieldValueMap = formFieldValues.reduce((map: ObjectMap<FormFieldValue>, formFieldValue) => {
        map[formFieldValue.formFieldWidget.id] = formFieldValue;
        return map;
      }, {});

      const widgets = checklistWidgets.filter(
        widget =>
          (widget.header.taskTemplate as TaskTemplate).group.id === this.taskTemplate!.group.id &&
          widget.header.type === WidgetType.FormField,
      );

      return { widgets: this.widgetService.sortWidgets(widgets), formFieldValueMap };
    };

    const mapDispatchToThis = () => ({
      getAllFormFieldValuesByChecklistRevisionId: this.formFieldValueActions.getAllByChecklistRevisionId,
      getAllWidgetsByChecklistRevisionId: this.widgetActions.getAllByChecklistRevisionId,
    });

    connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, this.shouldChange)(this);
  }

  public shouldChange(changes: {
    checklistRevisionId: angular.IChangesObject<Muid>;
    taskId: angular.IChangesObject<Muid>;
    taskTemplate: angular.IChangesObject<TaskTemplate>;
  }) {
    const { checklistRevisionId, taskTemplate, taskId } = changes;
    return (
      (checklistRevisionId && checklistRevisionId.currentValue) ||
      (taskTemplate && taskTemplate.currentValue) ||
      (taskId && taskId.currentValue)
    );
  }

  public $onChanges(changes: {
    checklistRevisionId: angular.IChangesObject<Muid>;
    taskId: angular.IChangesObject<Muid>;
    taskTemplate: angular.IChangesObject<TaskTemplate>;
    widgetsReplacingText: angular.IChangesObject<Option<string>>;
  }) {
    const { checklistRevisionId, taskId, taskTemplate, widgetsReplacingText } = changes;
    if (checklistRevisionId && checklistRevisionId.currentValue) {
      this.checklistRevisionId = checklistRevisionId.currentValue;
    }
    if (taskId && taskId.currentValue) {
      this.taskId = taskId.currentValue;
    }
    if (taskTemplate && taskTemplate.currentValue) {
      this.taskTemplate = taskTemplate.currentValue;
    }
    if (widgetsReplacingText && widgetsReplacingText.currentValue) {
      this.widgetsReplacingText = widgetsReplacingText.currentValue;
    }

    if (this.shouldChange(changes)) {
      this.actions!.getAllWidgetsByChecklistRevisionId(this.checklistRevisionId!).catch(() => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems loading the widgets`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
      this.actions!.getAllFormFieldValuesByChecklistRevisionId(this.checklistRevisionId!).catch(() => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems loading the form field values`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
    }
  }
}

export const ApprovalSubjectTaskWidgetsContainer: angular.IComponentOptions = {
  bindings: {
    checklistRevisionId: '<',
    taskId: '<',
    taskTemplate: '<',
    widgetsReplacingText: '<',
  },
  controller: ApprovalSubjectTaskWidgetsContainerController,
  templateUrl,
};
