import { Icon, Text, VStack } from 'components/design/next';
import * as React from 'react';
import { TasksTableEmptyStateNewButton } from './tasks-table-empty-state-new-button';
import { match } from 'ts-pattern';

export type TasksTableEmptyStateProps = {
  mode: 'allClear' | 'noResults';
};

export const TasksTableEmptyState: React.FC<TasksTableEmptyStateProps> = ({ mode }) => {
  return (
    <VStack spacing={3}>
      <Icon
        variant="fad"
        icon="party-horn"
        size="14"
        mt={15}
        primaryLayer={{ color: 'blue.300', opacity: 1 }}
        secondaryLayer={{ color: 'blue.200', opacity: 1 }}
      />
      {match(mode)
        .with('allClear', () => (
          <VStack color="gray.500">
            <Text fontSize="xl">Congratulations, all clear!</Text>
            <Text>What do you want to do next?</Text>
          </VStack>
        ))
        .with('noResults', () => (
          <VStack color="gray.500">
            <Text fontSize="xl">No results found</Text>
            <Text>Try readjusting your search and filters.</Text>
          </VStack>
        ))
        .exhaustive()}

      <TasksTableEmptyStateNewButton />
    </VStack>
  );
};
