import {
  FieldType,
  FormFieldWidget,
  TaskTemplate,
  TemplateRevision,
  TemplateTaskAssignment,
} from '@process-street/subgrade/process';
import { TaskAssignmentRule, TaskAssignmentRuleSourceType } from '@process-street/subgrade/role-assignment';
import { DynamicAssignment } from 'pages/templates/_id/models/assignments';
import { MuidConverter, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { uuid } from 'services/uuid';
import intersection from 'lodash/intersection';

const defaultWorkflowRunner: DynamicAssignment = {
  assignmentType: 'workflowRunner',
  title: 'Workflow Runner',
  description: 'The user assigned to this workflow when it is run',
  disabled: false,
};

const zeroState: DynamicAssignment[] = [
  defaultWorkflowRunner,
  {
    assignmentType: 'emailField',
    title: 'Email Field',
    description: 'Dynamically assign to an email',
    disabled: true,
    tooltip: 'Add an email field to a previous task to dynamically assign to it here',
  },
  {
    assignmentType: 'membersField',
    title: 'Member Field',
    description: 'Dynamically assign to a member',
    disabled: true,
    tooltip: 'Add a member field to a previous task to dynamically assign to it here',
  },
];

const getAvailableDynamicAssignments = (
  emailAndMembersFieldWidgets: FormFieldWidget[],
  allRules: TaskAssignmentRule[],
  taskTemplates: TaskTemplate[],
): DynamicAssignment[] => {
  const taskCount = taskTemplates.length;
  const taskGroupIds = new Set(taskTemplates.map(tt => tt.group.id));
  const taskRules = allRules.filter(rule => taskGroupIds.has(rule.targetTaskTemplateGroup?.id));

  const initiatorKey = 'initiator';
  // counting rules usage for given templates, grouped by widget group id (for form field rules) or under initiatorKey for initiator rules
  const widgetCountMap = new Map<string, number>();
  taskRules.forEach(rule => {
    const key = rule.sourceFormFieldWidgetGroup?.id ?? initiatorKey;
    widgetCountMap.set(key, (widgetCountMap.get(key) ?? 0) + 1);
  });

  // excluding widgets used in rules in all tasks (taskCount)
  const widgets = emailAndMembersFieldWidgets.filter(w => widgetCountMap.get(w.header.group.id) !== taskCount);

  if (widgets.length === 0 && taskRules.length === 0) {
    return zeroState;
  } else {
    const assignments: DynamicAssignment[] = widgets.map(widget => ({
      assignmentType: widget.fieldType === FieldType.Email ? 'emailField' : 'membersField',
      title: widget.label || (widget.fieldType === FieldType.Email ? 'Unknown Email Field' : 'Unknown Member Field'),
      description: widget.fieldType === FieldType.Email ? 'Email' : 'Member',
      disabled: false,
      widget,
    }));

    // was initiator rule defined in all tasks?
    const hasInitiatorRule = widgetCountMap.get(initiatorKey) === taskCount;

    return hasInitiatorRule ? assignments : [defaultWorkflowRunner, ...assignments];
  }
};

const getAvailableMemberships = (
  memberships: OrganizationMembershipWithUser[],
  taskAssignmentsMap: Record<string, TemplateTaskAssignment[]>,
  taskTemplates: TaskTemplate[],
) => {
  const organizationMembershipIdsArrays = taskTemplates.map(taskTemplate =>
    (taskAssignmentsMap[taskTemplate.id] || []).map(a => a.organizationMembership.id),
  );
  const ids = new Set(intersection(...organizationMembershipIdsArrays));

  return memberships.filter(om => !ids.has(om.id));
};

const getRuleFromDynamicAssignment = (
  assignment: DynamicAssignment,
  templateRevision: TemplateRevision,
  taskTemplate: TaskTemplate,
): TaskAssignmentRule => {
  switch (assignment.assignmentType) {
    case 'workflowRunner':
      return {
        id: MuidConverter.fromUuid(uuid()),
        organization: templateRevision.organization,
        templateRevision,
        targetTaskTemplateGroup: {
          id: taskTemplate.group.id,
        },
        sourceType: TaskAssignmentRuleSourceType.ChecklistInitiator,
      };
    default:
      return {
        id: MuidConverter.fromUuid(uuid()),
        organization: templateRevision.organization,
        templateRevision,
        targetTaskTemplateGroup: taskTemplate.group,
        sourceType: TaskAssignmentRuleSourceType.FormField,
        sourceFormFieldWidgetGroup: assignment.widget!.header.group,
      };
  }
};

const getRulesFromDynamicAssignment = (
  assignment: DynamicAssignment,
  templateRevision: TemplateRevision,
  taskTemplates: TaskTemplate[],
  allRules: TaskAssignmentRule[],
) =>
  taskTemplates
    .filter(
      tt =>
        !allRules.some(
          rule =>
            rule.targetTaskTemplateGroup?.id === tt.group.id && isRuleSimilarAsDynamicAssignment(rule, assignment),
        ),
    )
    .map(tt => getRuleFromDynamicAssignment(assignment, templateRevision, tt));

const isRuleSimilarAsDynamicAssignment = (rule: TaskAssignmentRule, assignment: DynamicAssignment) => {
  const sameType =
    (rule.sourceType === TaskAssignmentRuleSourceType.ChecklistInitiator &&
      assignment.assignmentType === 'workflowRunner') ||
    (rule.sourceType === TaskAssignmentRuleSourceType.FormField && assignment.assignmentType !== 'workflowRunner');

  const sameWidget = rule.sourceFormFieldWidgetGroup?.id === assignment.widget?.header?.group.id;

  return sameType && sameWidget;
};

export const AssignmentPickerService = {
  getAvailableDynamicAssignments,
  getAvailableMemberships,
  getRuleFromDynamicAssignment,
  getRulesFromDynamicAssignment,
  isRuleSimilarAsDynamicAssignment,
};
