import { Muid } from '@process-street/subgrade/core';
import { EntityMap, RequestStage } from '@process-street/subgrade/redux/types';
import { statusesSelector } from 'reducers/statuses/statuses.selectors';
import { ReduxAppState, Status } from 'reducers/types';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { statusState } from 'reducers/util/status-state';
import { ReducerObject } from 'reducers/util/types';
import { Reducer } from 'redux';
import { createSelector, Selector } from 'reselect';

type MetaType = { [key in string]: Muid };

export const getStatusReducer = (actionName: string, metaKey: string): Reducer<EntityMap<Status>> =>
  handleActionsOnSuccess<EntityMap<Status>>(
    {
      [actionName + RequestStage.Request]: (state: EntityMap<Status>, { meta }: { meta: MetaType }) =>
        statusState(state, meta[metaKey], true, false, false),
      [actionName + RequestStage.Success]: (state: EntityMap<Status>, { meta }: { meta: MetaType }) =>
        statusState(state, meta[metaKey], false, false, true),
      [actionName + RequestStage.Failure]: (state: EntityMap<Status>, { meta }: { meta: MetaType }) =>
        statusState(state, meta[metaKey], false, true, true),
    },
    {},
  );

/*
 * This reduces an array of action names to an object of action/{REQUEST,SUCCESS,FAILURE} objects that set the state for the whole key
 */
export const getStatusByActions = (...actionNames: string[]): Reducer<Status> =>
  handleActionsOnSuccess<Status>(
    actionNames.reduce((acc, actionName) => {
      acc[actionName + RequestStage.Request] = (state: EntityMap<Status>) => ({ ...state, loading: true });
      acc[actionName + RequestStage.Success] = (state: EntityMap<Status>) => ({
        ...state,
        loading: false,
        loaded: true,
        error: false,
      });
      acc[actionName + RequestStage.Failure] = (state: EntityMap<Status>) => ({
        ...state,
        loading: false,
        loaded: true,
        error: true,
      });
      return acc;
    }, {} as ReducerObject<Status>),
    {
      error: false,
      loading: false,
      loaded: false,
    },
  );

export const getStatusForEntityByPathSelector = (entityName: string, statusPath: string) =>
  createSelector(statusesSelector, statuses => {
    // @ts-expect-error -- TODO
    const statusesForEntity = statuses[entityName];
    return statusesForEntity[statusPath] || {};
  });
export const getIsLoadedSelector = (
  entityName: string,
  statusPath: string,
  entityId: Muid,
): Selector<ReduxAppState, boolean> =>
  createSelector(getStatusForEntityByPathSelector(entityName, statusPath), statuses => {
    const status = statuses[entityId] || {};
    return !!status.loaded;
  });
