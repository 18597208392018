import angular from 'angular';
import { TempDataServiceImpl } from 'services/temp-data-service';
import { SessionService } from 'services/session-service';

export const ServicesModule = angular
  .module('services', [])
  .factory('SessionService', function () {
    return SessionService;
  })
  .factory('TempDataService', function () {
    return TempDataServiceImpl;
  }).name;
