// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../images/update-modal-icon.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"modal-body checklist-migration-modal\"> <div class=\"row\"> <div class=\"col-xs-12\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </div> </div> <div class=\"row updating-checklists\"> <p>This workflow run is being updated to the latest version of the workflow.</p> </div> <div class=\"row this-update\"> <p> This workflow run is scheduled to be updated to the latest version of the workflow soon. <br/>Would you like to update this workflow run now? </p> </div> <div class=\"row\"> <button data-ng-click=\"$ctrl.onButtonClick()\" data-ng-disabled=\"$ctrl.buttonDisabled\" class=\"btn btn-primary got-it-button\"> Update to latest now </button> </div> </div> ";
// Exports
var _module_exports = code;;
var path = '/components/migration/checklist/checklist-migration-modal.component.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;