import React from 'react';

// models
import { User } from '@process-street/subgrade/core';
import { UploadAttachment } from './CommentAttachment.interface';

// components
import { Avatar } from 'components/common/Avatar';
import { CommentAttachmentActions } from './CommentAttachmentActions';
import { CommentAttachmentContent } from './CommentAttachmentContent';

import styles from './CommentAttachment.module.scss';

export interface CommentAttachmentProps {
  onDeleteAttachment: () => void;
  onCancelUpload: () => void;
  createdByUser: User;
  userLink: string;
  attachment: UploadAttachment;
  deletable: boolean;
}

export const CommentAttachment: React.FunctionComponent<React.PropsWithChildren<CommentAttachmentProps>> = ({
  createdByUser,
  onDeleteAttachment,
  onCancelUpload,
  attachment,
  deletable,
  userLink,
}) => {
  return (
    <div className={styles.commentAttachment}>
      <a href={userLink} className={styles.commentAttachmentAvatar}>
        {createdByUser && <Avatar user={createdByUser} />}
      </a>
      {createdByUser && <span className={styles.commentAttachmentAuthor}>{createdByUser.username}</span>}
      <CommentAttachmentContent attachment={attachment} />
      {deletable && (
        <CommentAttachmentActions
          attachment={attachment}
          onDeleteAttachment={onDeleteAttachment}
          onCancelUpload={onCancelUpload}
        />
      )}
    </div>
  );
};
