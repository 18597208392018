import { S3File } from '@process-street/subgrade/core';
import { env, getEnv } from 'components/common/env';
import { useImage, usePrevious } from 'components/design/next';
import React from 'react';

export type ImageKitTransformation = {
  width: number | 'auto';
  height?: number;
  cropMode?:
    | 'cm-pad_resize'
    | 'c-force'
    | 'c-at_max'
    | 'c-at_least'
    | 'c-maintain_ratio'
    | 'cm-extract'
    | 'cm-pad_extract';
};

export type UseCDNImageProps = {
  /** Disable CDN & LQIP, use S3 file URL instead. */
  isDisabled?: boolean;
  s3File?: Partial<S3File>;
  /** LQIP => low quality image placeholder. If set to true, it will load a low quality image placeholder */
  withLqip?: boolean;
  transformation: ImageKitTransformation;
};

export function useCDNImage({ transformation, s3File, withLqip = true, isDisabled = false }: UseCDNImageProps) {
  const { width, height, cropMode = 'c-maintain_ratio' } = transformation;

  const transformationSettings = `w-${width},h-${height},cm-${cropMode}`;
  const retinaSupport = `dpr-2`;
  const IMAGEKIT_URL_ENDPOINT = env.IMAGEKIT_URL_ENDPOINT ?? getEnv().IMAGEKIT_URL_ENDPOINT;

  const isGIF = s3File?.mimeType?.includes('image/gif');
  const lqipTransformationSettings = {
    quality: 5,
    blur: 40,
  };
  // We do this instead of using the key because the URL is encoded properly for S3
  const imageKitUrl = s3File?.url?.replace(/^.+\.s3\.amazonaws\.com\//, IMAGEKIT_URL_ENDPOINT);

  const lqipSrc =
    s3File && withLqip && !isGIF
      ? isDisabled
        ? s3File.url
        : `${imageKitUrl}?tr=q-${lqipTransformationSettings.quality},bl-${lqipTransformationSettings.blur},${transformationSettings}`
      : '';

  const src = s3File ? (isDisabled ? s3File.url : `${imageKitUrl}?tr=${transformationSettings},${retinaSupport}`) : '';
  const previousSrc = usePrevious(src);
  const srcHasChanged = src !== previousSrc;

  const status = useImage({ src });
  const lqipStatus = useImage({ src: lqipSrc });

  const lqipLoaded = lqipStatus === 'loaded';
  const anySrcLoaded = lqipLoaded || status === 'loaded';

  // This is a workaround where Chakra doesn't parse URLs with commas properly since 2.5.4, should be fixed in 3.0
  // See: https://github.com/chakra-ui/chakra-ui/issues/7548
  const urlEncodeComma = (src: string | undefined) => src?.replace(/,/g, '%2C');

  return React.useMemo(() => {
    // The `useImage` status gets out of sync with the `src` when it changes,
    // as a workaround, we return the loading state when the `src` has changed.
    if (srcHasChanged) {
      return {
        anySrcLoaded: false,
        isGIF,
        lqipLoaded: false,
        lqipSrc,
        lqipStatus: 'loading',
        src: urlEncodeComma(src),
        status: 'loading',
      };
    }

    return {
      anySrcLoaded,
      isGIF,
      lqipLoaded,
      lqipSrc,
      lqipStatus,
      src: urlEncodeComma(src),
      status,
    };
  }, [anySrcLoaded, isGIF, lqipLoaded, lqipSrc, lqipStatus, src, status, srcHasChanged]);
}
