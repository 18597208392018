import { Widget } from '@process-street/subgrade/process';
import produce from 'immer';
import { match } from 'ts-pattern';
import { ActorRef } from 'xstate';
import create from 'zustand';
import { WidgetEvent } from '../../../types';

export type MoveToStepAction =
  | { type: 'SET_SELECTED_WIDGET'; payload: Widget }
  | { type: 'SET_SELECTED_WIDGET_ACTOR_REF'; payload: ActorRef<WidgetEvent> };

export type MoveToStepState = {
  selectedWidget: Widget | null;
  selectedWidgetActorRef: ActorRef<WidgetEvent> | null;
};

export type MoveToStepStore = MoveToStepState & {
  dispatch: (action: MoveToStepAction) => void;
};

export const reducer = (state: MoveToStepState, action: MoveToStepAction): MoveToStepState => {
  return match(action)
    .with({ type: 'SET_SELECTED_WIDGET' }, ({ payload }) =>
      produce(state, draft => {
        draft.selectedWidget = payload;
      }),
    )
    .with({ type: 'SET_SELECTED_WIDGET_ACTOR_REF' }, ({ payload }) =>
      produce(state, draft => {
        draft.selectedWidgetActorRef = payload;
      }),
    )
    .otherwise(() => state);
};

export const useMoveToStepStore = create<MoveToStepStore>(set => ({
  selectedWidget: null,
  selectedWidgetActorRef: null,
  dispatch: action => set(state => reducer(state, action)),
}));
