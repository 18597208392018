import { dayjs } from '@process-street/subgrade/util';

const timeRegex = /[0-9]?[0-9]:[0-9]{2}(am|pm)/i;

const getTimeParts = (time: string) => {
  return (
    time
      // Remove the AM/PM part
      .replace(/[A-Z]/gi, '')
      .split(':')
  );
};

const isTimeStringValid = (time: string) => {
  if (!timeRegex.test(time)) return false;

  const [hours, minutes] = getTimeParts(time);

  const hoursAsNumber = parseInt(hours);
  const minutesAsNumber = parseInt(minutes);

  return hoursAsNumber >= 0 && hoursAsNumber <= 12 && minutesAsNumber >= 0 && minutesAsNumber <= 59;
};

const getFallbackDate = (timeZone: string) => dayjs().tz(timeZone).startOf('day').add(1, 'day').set('h', 8).toDate();

const getInitialValue = (value: number | undefined | null, timeZone: string) => {
  // dayjs may crash when calling with null
  const dateExistsAndIsValid = Boolean(value) && dayjs(value).isValid();
  return dateExistsAndIsValid ? dayjs(value).toDate() : getFallbackDate(timeZone);
};

export const DatePickerHelpers = {
  timeRegex,
  getTimeParts,
  isTimeStringValid,
  getFallbackDate,
  getInitialValue,
};
