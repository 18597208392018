import angular from 'angular';
import { membersByUsernameComparator } from 'services/user-utils';
import { canAccess, Feature, features } from 'services/features/features';
import { PermitConstants } from '@process-street/subgrade/permission/permit-constants';
import { HttpStatus } from '@process-street/subgrade/util';
import { connectController } from 'reducers/util';
import { TemplateMembershipActions } from 'components/template/membership/store/template-membership.actions';
import { IdentityProvider, OrganizationMembershipRole, UserType } from '@process-street/subgrade/core';
import templateUrl from './template-members.component.html';
import './template-members.scss';
import { createSelector } from 'reselect';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { TemplatePermissionsStatsQueryService } from 'components/permissions/services/template-permissions-stats-query-service';
import { TemplateType } from '@process-street/subgrade/process';
import { InviteButtonConfigs, Rules } from 'components/invitation/invitation-widget/rules';
import { SessionSelector } from 'reducers/session/session.selectors';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { GetTemplatePermits } from 'features/permits/query-builder';
import { queryClient } from 'components/react-root';
import { TemplateSelector } from 'reducers/template/template.selectors';
import { SourcesToRolesMap } from 'pages/organizations/manage/users/components/role-selector/model';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ArrayService } from 'services/array-service';
import { isAnonymousUser, isGroupUser } from '@process-street/subgrade/util/user-type-utils';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular.module('frontStreetApp.directives').component('psTemplateMembers', {
  bindings: {
    template: '<',
    templateRevision: '<',
    user: '<',
  },
  templateUrl,
  controller(
    $ngRedux,
    $q,
    $rootScope,
    $state,
    $scope,
    $timeout,
    FolderService,
    GroupMembershipActions,
    InvitationService,
    OrganizationMembershipService,
    OrganizationService,
    PermitService,
    ResponseUtilsService,
    SecurityService,
    SessionService,
    UserSuggester,
    ToastService,
  ) {
    const ctrl = this;

    const logger = trace({ name: 'psTemplateMembers component' });
    logger.info('loading ctrl');

    const { Type } = PermitConstants;

    const mapDispatchToActions = () => ({
      ...TemplateMembershipActions,
      queryGroupMembership: GroupMembershipActions.queryGroupMembership,
    });

    ctrl.$onInit = function () {
      const mapStateToThis = () =>
        createSelector(
          [
            FeatureFlagSelector.getFeatureFlags,
            SessionSelector.getCurrentPlan,
            OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId,
            TemplateSelector.isTemplatePrivate,
          ],
          (featureFlags, plan, organizationMembership, isTemplatePrivate) => ({
            featureFlags,
            plan,
            organizationMembership,
            templateIsPrivate: isTemplatePrivate(ctrl.template.id),
          }),
        );

      ctrl.unsubscribe = connectController($ngRedux, mapStateToThis, mapDispatchToActions)(ctrl);
      ctrl.paywallIsOpen = false;
      ctrl.invitee = '';
      ctrl.isFreemium = features.isFreemiumTrack(ctrl.state.plan.id);

      ctrl.inviteConfig = (() => {
        switch (ctrl.template.templateType) {
          case TemplateType.Playbook: {
            if (ctrl.isFreemium) {
              switch (ctrl.state.organizationMembership.role) {
                case OrganizationMembershipRole.FreeMember:
                  return Rules.freeMember;

                default:
                  return Rules.matchInviterOrFreeMember;
              }
            }
            return Rules.fullMemberOrGuest;
          }

          case TemplateType.Page:
            return Rules.freeMember;
          default:
            return Rules.fullMemberOrGuest;
        }
      })();
      ctrl.availableRoles =
        ctrl.template.templateType === TemplateType.Page ? SourcesToRolesMap.Pages : SourcesToRolesMap.Workflows;
      ctrl.inviteButtonConfig = InviteButtonConfigs.INVITE_AND_SHARE;

      OrganizationService.getSelectedOrganization().then(organization => {
        const currentPlanId = organization && organization.subscription.plan.id;

        const planCanAccessFullPermissions = canAccess(Feature.FULL_PERMISSIONS, currentPlanId);

        ctrl.restricted = !planCanAccessFullPermissions;
        ctrl.guest = true;

        ctrl._initAccessLevels(currentPlanId);
        ctrl._initializePermissions();
        ctrl._initializeSuggester();
        ctrl._initializeMemberships();
      });

      ctrl.actions.queryGroupMembership();
    };

    ctrl._initAccessLevels = currentPlanId => {
      ctrl.accessLevels =
        ctrl.template.templateType === TemplateType.Playbook
          ? PermitConstants.WorkflowFullMemberAccessLevels
          : PermitConstants.PageFullMemberAccessLevels;

      if (canAccess(Feature.GUESTS_CAN_CREATE_CHECKLISTS, currentPlanId)) {
        ctrl.guestAccessLevels = PermitConstants.WorkflowLegacyGuestAccessLevels;
      } else {
        ctrl.guestAccessLevels = PermitConstants.WorkflowGuestAccessLevels;
      }

      ctrl.freeAccessLevels =
        ctrl.template.templateType === TemplateType.Playbook
          ? PermitConstants.WorkflowFreeMemberAccessLevels
          : PermitConstants.PageFreeMemberAccessLevels;
    };

    // Invitation suggestions
    let userSuggester;
    ctrl.suggestedUsers = [];

    ctrl._initializeSuggester = function () {
      userSuggester = new UserSuggester([IdentityProvider.ProcessStreet]);
    };

    ctrl._initializeSuggestedUsers = () => {
      const excludeUsers = ctrl.templatePermitMemberships.map(membership => membership.user);

      userSuggester.suggest(undefined, excludeUsers).then(users => (ctrl.suggestedUsers = users));
    };

    ctrl.selectSuggestion = function (suggestion) {
      if (isGroupUser(suggestion)) {
        ctrl.addGroup(suggestion.email);
      } else {
        ctrl.email = suggestion.email;
        ctrl.add(suggestion.email);
      }
    };

    // Add
    ctrl.addMemberToTemplate = function (membership) {
      ctrl.templatePermitMemberships.push(membership);

      return PermitService.db
        .create(Type.TEMPLATE, ctrl.template, membership)
        .then(
          permit => {
            AnalyticsService.trackEvent('user added someone to template', {
              'someone email': membership.user.email,
            });
            ctrl.actions.createTemplatePermit(ctrl.template.id, permit);

            const userName =
              membership.user.userType === UserType.Group && membership.user.username === 'All Members'
                ? 'All Organization'
                : membership.user.username;
            ToastService.openToast({
              status: 'success',
              title: `Permissions updated for <b>${userName}</b>`,
            });

            queryClient.invalidateQueries(GetTemplatePermits.key);

            ctrl._initializeMemberships();
          },
          response => {
            ArrayService.desplice(ctrl.templatePermitMemberships, membership);

            return $q.reject(response);
          },
        )
        .finally(() => {
          ctrl._initializeSuggestedUsers();
        });
    };

    function inviteUserToTemplateByEmail(email, role) {
      const resource = { type: 'Template', id: ctrl.template.id };

      return InvitationService.invite(email, resource, { role })
        .then(response => {
          AnalyticsService.trackEvent('user added someone to template', {
            'someone email': email,
          });

          ToastService.openToast({
            status: 'success',
            title: `Invitiation sent to <b>${email}</b>`,
          });

          ctrl.actions.getAllExistingAndNewTemplatePermits(ctrl.template.id);
          ctrl.templatePermitMemberships.push(response.organizationMembership);
          return response;
        })
        .finally(() => {
          ctrl._initializeSuggestedUsers();
        });
    }

    ctrl.addGroup = function (email) {
      ctrl.adding = true;

      const groupMembership = ctrl.memberships.find(membership => membership.user.email === email);

      ctrl
        .addMemberToTemplate(groupMembership)
        .then(response => {
          ctrl.actions.getAllExistingAndNewTemplatePermits(ctrl.template.id);
          ctrl._initializeMemberships();

          $rootScope.$broadcast(EventName.TEMPLATE_PERMIT_CREATE_OK);
          TemplatePermissionsStatsQueryService.invalidateCache(ctrl.template.organization.id, ctrl.template.folder.id);

          return response;
        })
        .finally(() => {
          $timeout(() => {
            ctrl.adding = false;
          });
          ctrl._initializeSuggestedUsers();
        });
    };

    ctrl.invite = ({ email, role = OrganizationMembershipRole.FullMember }) => {
      const adjustedRole = (() => {
        if (ctrl.restricted) return OrganizationMembershipRole.Admin;
        return role;
      })();
      ctrl.add(email, adjustedRole);
    };

    ctrl.add = function (email, role) {
      if (!ctrl.templatePermitMemberships) {
        return;
      }

      const membership = ctrl.memberships.find(mbsp => mbsp.user.email.toLowerCase() === email.toLowerCase());
      // Check if the user already exists in case that user types the email manually.
      const inviteeInTemplate = ArrayService.includes(ctrl.templatePermitMemberships, membership, 'id');
      if (inviteeInTemplate) {
        ToastService.openToast({
          status: 'warning',
          title: `We couldn't add <b>${email}</b> to the workflow`,
          description: 'They are already a member of this workflow.',
        });
        return;
      }

      ctrl.adding = true;
      ctrl.invitee = membership?.user?.username ?? email;

      let request;

      if (membership) {
        request = ctrl.addMemberToTemplate(membership);
      } else {
        request = inviteUserToTemplateByEmail(email, role);
      }

      request
        .then(response => {
          ctrl._initializeMemberships();

          $rootScope.$broadcast(EventName.TEMPLATE_PERMIT_CREATE_OK);
          TemplatePermissionsStatsQueryService.invalidateCache(ctrl.template.organization.id, ctrl.template.folder.id);
          queryClient.invalidateQueries(GetTemplatePermits.key);

          return response;
        }, ctrl._handleAddErrorResponse(email))
        .finally(() => {
          ctrl.adding = false;
          ctrl._initializeSuggestedUsers();
        });
    };

    ctrl.closePaywall = () => {
      ctrl.paywallIsOpen = false;
      ctrl.invitee = '';
    };

    ctrl._handleAddErrorResponse = email => response => {
      if (ResponseUtilsService.isUsersLimitReached(response)) {
        const message = ResponseUtilsService.getUsersLimitReachedMessage(response);
        ToastService.openToast({
          status: 'warning',
          title: `We couldn't add <b>${email}</b> to the workflow`,
          description: message,
        });
        ctrl._initializeMemberships(); // we added user but as guest only
      } else if (response.status === HttpStatus.PAYMENT_REQUIRED && ctrl.isFreemium) {
        ctrl.paywallIsOpen = true;
      } else {
        const status = response.status === HttpStatus.BAD_REQUEST ? 'warning' : 'error';
        const templateTypeName = ctrl.template.templateType === TemplateType.Page ? 'page' : 'workflow';
        ToastService.openToast({
          status,
          title: `We're having problems adding <b>${email}</b> to the ${templateTypeName}`,
          description: DefaultErrorMessages.getUserInactiveDescription(email, response),
        });
      }
      return $q.reject(response);
    };

    ctrl._initializeMemberships = function () {
      const foldersRequest = FolderService.getAllByOrganizationIdAndPermission(
        ctrl.template.organization.id,
        'folder_read',
      );
      const permitsRequest = PermitService.getAllAndInherited(Type.TEMPLATE, ctrl.template.id);
      const membershipsRequest = OrganizationMembershipService.getAllByOrganizationId(ctrl.template.organization.id);

      if (ctrl.loaded === undefined) {
        ctrl.loaded = false;
      }

      ctrl.memberships = [];
      $q.all({
        folders: foldersRequest,
        permitsResult: permitsRequest,
        memberships: membershipsRequest,
      })
        .then(
          result => {
            ctrl.permits = result.permitsResult.permits;
            ctrl.memberships = result.memberships;

            ctrl.refreshTemplatePermitMemberships();

            ctrl.inheritedPermits = result.permitsResult.inheritedPermits.filter(permit => {
              // Filter out folders we don't have access to and the Home folder
              const folder = result.folders.find(fld => fld.id === permit.folder.id);
              return folder && folder.parentFolder;
            });
            ctrl.inheritedPermits.forEach(permit => {
              const folder = result.folders.find(fld => fld.id === permit.folder.id);
              permit._resource = folder;
              permit._resourceName = folder ? folder.name : '???';
              permit._level = PermitService.getLevel(permit);
            });
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading the members for this workflow`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });

            // If we don't do this, the "Loading" message never goes away if the request fails.
            ctrl.inheritedPermits = [];
            ctrl.templatePermitMemberships = [];
          },
        )
        .finally(() => {
          ctrl.loaded = true;
          ctrl._initializeSuggestedUsers();
        });
    };

    ctrl.refreshTemplatePermitMemberships = () => {
      ctrl.templatePermitMemberships = PermitService.initializeMemberships(ctrl.permits)
        .filter(membership => !isAnonymousUser(membership.user))
        .sort(membersByUsernameComparator);
    };

    // Permissions
    ctrl.permissionMap = {};

    ctrl._initializePermissions = function () {
      $q.all({
        templateUpdate: SecurityService.canUpdateTemplateByTemplate(ctrl.template),
        pageUpdate: SecurityService.canUpdatePageByTemplate(ctrl.template),
        organizationMembershipLevelUpdate: SecurityService.canUpdateOrganizationMembershipLevelByTemplate(
          ctrl.template,
        ),
      }).then(permissionMap => {
        ctrl.permissionMap = permissionMap;
        const { templateUpdate, pageUpdate } = ctrl.permissionMap;
        ctrl.showInvite = templateUpdate || pageUpdate;
      });
    };
  },
});
