import { Muid } from '@process-street/subgrade/core';
import { TemplateRevision } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { Mutation } from 'react-query/types/core/mutation';
import { axiosService } from 'services/axios-service';

export namespace PublishDraftMutation {
  export type ChecklistMigrationStrategy = 'Required' | 'Optional';
  export type Params = { tmplRevId: Muid; checklistMigrationStrategy?: ChecklistMigrationStrategy };

  export type Result = TemplateRevision;

  export const key = 'publish-draft';
  export const mutationFn = async (
    tmplRevId: Muid,
    checklistMigrationStrategy?: ChecklistMigrationStrategy,
  ): Promise<TemplateRevision> => {
    return axiosService
      .getAxios()
      .post<TemplateRevision>(`/1/template-revisions/${tmplRevId}/finish`, {
        checklistMigrationStrategy,
      })
      .then(res => res.data);
  };

  export const useMutation = (
    options: UseMutationOptions<PublishDraftMutation.Result, AxiosError, PublishDraftMutation.Params> = {},
  ): UseMutationResult<PublishDraftMutation.Result, AxiosError, PublishDraftMutation.Params> =>
    useRQMutation(
      ({ tmplRevId, checklistMigrationStrategy }) =>
        PublishDraftMutation.mutationFn(tmplRevId, checklistMigrationStrategy),
      {
        mutationKey: PublishDraftMutation.key,
        ...options,
      },
    );
}

export function isPublishDraftMutation(
  mutation: unknown,
): mutation is Mutation<PublishDraftMutation.Result, AxiosError, PublishDraftMutation.Params> {
  return typeof mutation === 'object' && (mutation as Mutation)?.options?.mutationKey === PublishDraftMutation.key;
}
