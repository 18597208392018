import { AuditMetadata, IdRef, Muid, Organization, Ref, S3File } from '../core';
import { Task } from './checklist-model';

export enum AttachmentStatus {
  Active = 'Active',
  Deleted = 'Deleted',
}

export enum AttachmentType {
  Comment = 'Comment',
  Task = 'Task',
}

export type AttachmentGroup = IdRef;

export interface Attachment {
  id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  group: AttachmentGroup;
  task: Ref<Task>;
  file: S3File;
  status: AttachmentStatus;
  type: AttachmentType;
}
