import * as React from 'react';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { Checkbox } from 'components/design/next';
import { Checklist, ChecklistStatus, TaskStatus } from '@process-street/subgrade/process';
import { useCompleteTaskAndOpenNext } from 'features/one-off-tasks/components/shared/hooks';
import { GetChecklistQuery } from 'features/checklists/query-builder';
import { queryClient } from 'components/react-root';

type OneOffTaskCheckboxProps = {
  task: OneOffTask;
  checklist: Checklist;
};

export const OneOffTaskCheckbox: React.FC<React.PropsWithChildren<OneOffTaskCheckboxProps>> = ({ task, checklist }) => {
  const updateTaskMutation = useCompleteTaskAndOpenNext(task);
  const handleToggleCheck = () => {
    const newStatus = task.status === TaskStatus.Completed ? TaskStatus.NotCompleted : TaskStatus.Completed;
    updateTaskMutation.mutateAsync({ taskId: task.id, status: newStatus }).then(() => {
      if (task.linkedChecklist?.id) {
        queryClient.invalidateQueries(GetChecklistQuery.getKey({ checklistId: task.linkedChecklist.id }));
      }
    });
  };

  return (
    <Checkbox
      aria-label={`${task.name} checkbox`}
      isChecked={task.status === TaskStatus.Completed}
      onChange={handleToggleCheck}
      isDisabled={checklist.status !== ChecklistStatus.Active}
      sx={{
        '.chakra-checkbox__control': {
          borderWidth: '1px',
          borderRadius: '4px',
          mt: '-2px',
        },
      }}
    />
  );
};
