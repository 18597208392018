import { Muid } from '@process-street/subgrade/core';
import { TaskTemplate } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { PromptOverrideLookup } from 'features/developer/components/prompt-override/prompt-override.component';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type AiGenerationTaskTemplatesMutationParams = {
  templateRevisionId: Muid;
  name: string;
  retryCount: number;
  description?: string;
  isPredefined?: boolean;
  promptOverride?: PromptOverrideLookup;
};

type AiGenerationTaskTemplatesMutationResult = TaskTemplate[];

export const AiGenerationTaskTemplatesMutation = {
  key: ['ai-generation', 'task-templates'],
  mutationFn: (params: AiGenerationTaskTemplatesMutationParams) =>
    axiosService
      .getAxios()
      .post<AiGenerationTaskTemplatesMutationResult>('/1/ai-generation/task-templates', params)
      .then(r => r.data),
};

export const useAiGenerationTaskTemplatesMutation = (
  options: UseMutationOptions<
    AiGenerationTaskTemplatesMutationResult,
    AxiosError,
    AiGenerationTaskTemplatesMutationParams
  > = {},
) => {
  return useMutation(AiGenerationTaskTemplatesMutation.mutationFn, {
    mutationKey: AiGenerationTaskTemplatesMutation.key,
    ...options,
  });
};
