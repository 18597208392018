import * as React from 'react';
import { isFormFieldWidget, Widget } from '@process-street/subgrade/process';
import { GetAllRulesByTemplateRevisionIdQuery } from 'features/conditional-logic/query-builder';
import { useTemplateId } from '../../automation/utils/use-template-id';
import { useGetDraftTmplRevisionByTmplIdQuery } from 'features/template-revisions/query-builder';
import { ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';
import { ConditionalLogicUtils } from 'features/conditional-logic/utils/conditional-logic-utils';
import { useFeatureFlags } from 'features/feature-flags';

export function useConditionalLogicButton(widget: Widget) {
  const featureFlags = useFeatureFlags();
  const templateId = useTemplateId();
  const templateRevisionQuery = useGetDraftTmplRevisionByTmplIdQuery({ templateId });
  const templateRevision = templateRevisionQuery.data;
  const templateRevisionId = templateRevision?.id;

  const rulesQuery = GetAllRulesByTemplateRevisionIdQuery.useQuery({ templateRevisionId }, {});

  const rules = React.useMemo(() => {
    return rulesQuery.data?.definitions ?? [];
  }, [rulesQuery.data?.definitions]);

  const widgetHasConditionalLogic = React.useMemo(() => {
    const widgetHasAssociatedRule = ConditionalLogicCommonUtils.makeWidgetHasAssociatedRule(rules);
    return Boolean(widget.header?.group?.id) && widgetHasAssociatedRule({ widget });
  }, [rules, widget]);

  const allowedFormFieldTypes = ConditionalLogicUtils.getAllowedFormFieldTypes(featureFlags);
  const widgetIsAllowedToHaveConditionalLogic =
    isFormFieldWidget(widget) && allowedFormFieldTypes.has(widget.fieldType);

  const shouldShowConditionalLogicButton = Boolean(widgetIsAllowedToHaveConditionalLogic && templateRevision);

  return {
    widgetHasConditionalLogic,
    shouldShowConditionalLogicButton,
    templateRevision,
  };
}
