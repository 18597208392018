export enum SubscriptionState {
  CANCEL_AT_PERIOD_END = 'CancelAtPeriodEnd',
  EXPIRED = 'Expired',
  EXCEEDED = 'Exceeded',
  TRIALING = 'Trialing',
  ACTIVE = 'Active',
  PAST_DUE = 'PastDue',
}

export enum CancellationReasons {
  CouldNotFigureItOut = 'I couldn’t figure it out',
  MissingFeatures = 'Missing feature(s)',
  CouldNotGetBuyIn = 'I couldn’t get buy-in from my team and/or managers',
  TooExpensive = "It's too expensive",
  MyBusinessIsClosing = 'My business is closing',
  ProjectPriorityChanged = 'The priority of this project changed',
}

export enum CancellationAlternatives {
  UseAnotherTool = 'Use another Process Management Tool',
  TrackThingsManually = 'Track things manually',
  NoLongerImportant = 'This is no longer important to me',
}

export const BillingConstants = {
  CancellationAlternatives,
  CancellationReasons,
  SubscriptionState,
};
