import * as React from 'react';

import { SolutionTypeTag } from '@process-street/subgrade/automation';

import { useGetActiveAutomationsCount } from '../../hooks/use-get-active-automations-count';
import { WithActiveAutomationsButton } from './buttons';
import { useWorkflowAutomationsContext } from '../common/context';
import { match, P } from 'ts-pattern';
import { SelectedTemplateTriggers } from 'pages/templates/_id/components/selected-template-triggers';

export const Edit: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { templateRevision, solutionTypeTag } = useWorkflowAutomationsContext();

  const { outgoingCount } = useGetActiveAutomationsCount({ templateId: templateRevision?.template.id });

  // Display triggers or outgoing automations based on solutionTypeTag
  return match({ solutionTypeTag, outgoingCount })
    .with({ solutionTypeTag: SolutionTypeTag.CreateChecklistWhen }, () => {
      return <SelectedTemplateTriggers />;
    })
    .with({ outgoingCount: P.when(count => count > 0) }, () => <WithActiveAutomationsButton />)
    .otherwise(() => null);
};

export const View: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { templateRevision, solutionTypeTag } = useWorkflowAutomationsContext();

  const { outgoingCount } = useGetActiveAutomationsCount({ templateId: templateRevision?.template.id });

  return match({
    solutionTypeTag,
    outgoingCount,
  })
    .with({ solutionTypeTag: SolutionTypeTag.CreateChecklistWhen }, () => <SelectedTemplateTriggers />)
    .with({ outgoingCount: P.when(count => count > 0) }, () => <WithActiveAutomationsButton />)
    .otherwise(() => null);
};

export const TemplateWorkflowAutomations: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { editable } = useWorkflowAutomationsContext();
  return editable ? <Edit /> : <View />;
};
