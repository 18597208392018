import * as React from 'react';
import { Text, HStack, Icon, Tooltip, Code } from 'components/design/next';
import { OptionProps, components } from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { PathOption as TPathOption } from '../utils';

export const PathOption: React.FC<React.PropsWithChildren<OptionProps<TPathOption, false>>> = props => {
  const value = BlvdSelectHelpers.castOptionData<TPathOption>(props.data);

  if (!value) return null;

  return (
    <div className={'blvd-select__option__wrapper'}>
      <components.Option {...props}>
        <Tooltip
          closeDelay={0}
          openDelay={0}
          label={
            <Text variant="-2">
              JSON Path: <Code fontSize="xs">{value.value}</Code>
            </Text>
          }
          hasArrow
        >
          <HStack justifyContent="space-between">
            <Text as="span" lineHeight="1">
              {value.label}
            </Text>
            {props.isSelected ? <Icon icon="check" size="4" variant="far" /> : null}
          </HStack>
        </Tooltip>
      </components.Option>
    </div>
  );
};
