import classNames from 'classnames';
import React from 'react';

import styles from './AvatarCounter.module.scss';
import { AvatarSize } from './AvatarSize';

export interface AvatarCounterProps {
  count: number | string;
  round?: boolean;
  size?: AvatarSize;
  onClick?: () => void;
}

export const AvatarCounter: React.FunctionComponent<React.PropsWithChildren<AvatarCounterProps>> = ({
  count = 0,
  size = AvatarSize.Normal,
  onClick,
}) => {
  const extraSmall = size === AvatarSize.ExtraSmall;
  const small = size === AvatarSize.Small;
  const medium = size === AvatarSize.Medium;
  const large = size === AvatarSize.Large;
  const normal = !extraSmall && !small && !medium && !large;

  const avatarClass = classNames({
    [styles.avatarCounter]: true,
    [styles.sizeExtraSmall]: extraSmall,
    [styles.sizeSmall]: small,
    [styles.sizeNormal]: normal,
    [styles.sizeMedium]: medium,
    [styles.sizeLarge]: large,
  });

  const onAvatarClick = () => {
    if (onClick instanceof Function) {
      onClick();
    }
  };

  if (count === 0) {
    return null;
  }

  return (
    <div className={`avatar ${avatarClass}`} onClick={onAvatarClick}>
      <span title={count.toString()}>+{count}</span>
    </div>
  );
};
