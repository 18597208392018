export const CommentEvent = {
  COMMENT_CREATE_STARTED: 'comment create started',
  COMMENT_CREATE_OK: 'comment create ok',
  COMMENT_CREATE_FAILED: 'comment create failed',
  COMMENT_CREATE_FINISHED: 'comment create finished',

  COMMENT_DELETE_STARTED: 'comment delete started',
  COMMENT_DELETE_OK: 'comment delete ok',
  COMMENT_DELETE_FAILED: 'comment delete failed',
  COMMENT_DELETE_FINISHED: 'comment delete finished',
} as const;
