import angular from 'angular';
import { CookieService } from 'features/cookies/cookie-service';
import templateUrl from './csv-export-modal.component.html';
import './csv-export-modal.scss';
import { getEnv } from 'components/common/env';

class CsvExportModalCtrl {
  constructor($sce, auth, SessionService, CsvExportModalService) {
    'ngInject';

    this.$sce = $sce;
    this.auth = auth;
    this.CsvExportModalService = CsvExportModalService;
    this.SessionService = SessionService;

    this.exporting = false;
    this.template = {};
  }

  $onInit() {
    const { template } = this.resolve;
    this.loading = true;
    this.template = template;
    this.templateId = template.id;
    const url = `${getEnv().APP_API_URL}/1/templates/${this.templateId}/checklists/export`;
    this.url = this.$sce.trustAsResourceUrl(url);
  }

  exportToCsv() {
    this.exporting = true;
    const form = angular.element('#exportForm');

    const token = this.SessionService.getToken();
    CookieService.setTemporaryTokenCookie(token);
    form.submit();

    this.CsvExportModalService.trackExportEvent(this.template);
    this.close();
  }
}

export const CsvExportModal = {
  bindings: {
    resolve: '<',
    close: '&',
  },
  templateUrl,
  controller: CsvExportModalCtrl,
};
