import * as React from 'react';
import { OneOffTaskAssignmentDto } from '@process-street/subgrade/one-off-task';
import { Avatar, Box, HStack, Text, VStack } from 'components/design/next';
import { getAvatar } from 'components/common/Avatar';
import { match, P } from 'ts-pattern';

interface AssigneeListProps {
  assignments: OneOffTaskAssignmentDto[];
}

export const AssigneeList: React.FC<React.PropsWithChildren<AssigneeListProps>> = ({ assignments }) => {
  return (
    <Box mt={4} style={{ breakInside: 'avoid' }}>
      <Text as="h3" mb={4}>
        Assignees
      </Text>
      <HStack mt={4} flexWrap="wrap">
        {assignments.map(assignment => {
          return (
            <VStack key={assignment.id} width="70px" height="80px" gap={2} justifyContent="flex-start" mx="0">
              <Avatar
                size="sm"
                bg="gray.300"
                className="assignee-list__avatar"
                {...match(assignment?.user)
                  .with({ avatarUrl: P.string }, { avatarFile: { id: P.string } }, { username: P.string }, user => {
                    const avatar = getAvatar(user);
                    return {
                      src: avatar.url,
                      name: user.username,
                    };
                  })
                  .otherwise(() => ({}))}
              />
              <Text fontSize="xs" textAlign="center">
                {assignment.user.username}
              </Text>
            </VStack>
          );
        })}
      </HStack>
    </Box>
  );
};
