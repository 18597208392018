import { Muid } from '../core/core-model';
import { FieldType } from '../process/widget-model';

export enum ChecklistColumn {
  Assignees = 'Assignees',
  ChecklistCompletedBy = 'ChecklistCompletedBy',
  ChecklistCompletedDate = 'ChecklistCompletedDate',
  ChecklistCreateDate = 'ChecklistCreateDate',
  ChecklistDueDate = 'ChecklistDueDate',
  ChecklistLabelId = 'ChecklistLabelId',
  ChecklistName = 'ChecklistName',
  CompletedTasksCount = 'CompletedTasksCount',
  LastActiveDate = 'LastActiveDate',
  OverdueTasksCount = 'OverdueTasksCount',
  ProgressStatus = 'ProgressStatus',
  Selection = 'Selection',
  TemplateName = 'TemplateName',
  TemplateVersion = 'TemplateVersion',
  TotalCommentsAndAttachmentsCount = 'TotalCommentsAndAttachmentsCount',
}

export const alwaysVisibleColumns = [ChecklistColumn.Selection];

export const defaultColumns = [
  ChecklistColumn.Selection,
  ChecklistColumn.ChecklistName,
  ChecklistColumn.TemplateName,
  ChecklistColumn.Assignees,
  ChecklistColumn.ChecklistLabelId,
  ChecklistColumn.ProgressStatus,
  ChecklistColumn.TotalCommentsAndAttachmentsCount,
  ChecklistColumn.LastActiveDate,
  ChecklistColumn.ChecklistDueDate,
  ChecklistColumn.OverdueTasksCount,
];

export const templateDashboardDefaultColumns = [
  ChecklistColumn.ChecklistName,
  ChecklistColumn.Assignees,
  ChecklistColumn.ChecklistCreateDate,
  ChecklistColumn.LastActiveDate,
  ChecklistColumn.ChecklistDueDate,
  ChecklistColumn.ProgressStatus,
  ChecklistColumn.ChecklistLabelId,
  ChecklistColumn.CompletedTasksCount,
  ChecklistColumn.OverdueTasksCount,
  ChecklistColumn.TotalCommentsAndAttachmentsCount,
  ChecklistColumn.TemplateVersion,
];

/** columns available for conditional filters */
export const filterableColumnTypes = [
  ChecklistColumn.ChecklistCompletedDate,
  ChecklistColumn.ChecklistCreateDate,
  ChecklistColumn.ChecklistDueDate,
  ChecklistColumn.ChecklistName,
  ChecklistColumn.LastActiveDate,
  ChecklistColumn.CompletedTasksCount,
  ChecklistColumn.OverdueTasksCount,
  ChecklistColumn.Assignees,
  ChecklistColumn.ProgressStatus,
  ChecklistColumn.ChecklistLabelId,
];

export enum ColumnType {
  Checklist = 'Checklist',
  Task = 'Task',
  FormField = 'FormField',
}

export interface ColumnDef<
  D extends Record<string, any> | TaskColumnDef | FormFieldValueColumnDef = Record<string, any>,
> {
  kind: ColumnType;
  descriptor: ColDescriptor;
  data: D;
}

export enum ColDescriptor {
  ValueData = 'ValueData',
  Status = 'Status',
}

export interface TaskColumnDef {
  type: string;
  status: string;
  key: string;
  label: string;
  groupId: Muid;
}

export interface FormFieldValueColumnDef {
  type: string;
  status: string;
  fieldType: FieldType;
  widgetGroupId: Muid;
  fieldKey: string;
  fieldLabel: string;
  itemId?: string;
  itemKey?: string;
  itemLabel?: string;
}
export function isFormFieldValueColumnDef(col: ColumnDef): col is ColumnDef<FormFieldValueColumnDef> {
  return col.kind === ColumnType.FormField && col.descriptor === ColDescriptor.ValueData;
}

export interface ColumnDto<F extends string = string> {
  field: F;
  name: string;
  columnType: ColumnType;
  groupId?: Muid;
  itemId?: Muid;
  widgetGroupId?: Muid;
  sortable?: boolean;
}

export type FormFieldValueColumnField = `formFields.${Muid}`;
