import { DateUtils } from '@process-street/subgrade/util';
import { AssigneesRenderer } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers';
import { OverdueTaskDto } from 'components/dashboard/models/grid';
import React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import styles from './OverdueTasksModal.module.scss';

interface OverdueTaskItemProps {
  overdueTask: OverdueTaskDto;
}

export const OverdueTaskItem: React.FunctionComponent<React.PropsWithChildren<OverdueTaskItemProps>> = ({
  overdueTask,
}) => {
  const currentUser = useSelector(SessionSelector.getCurrentUser);

  if (!currentUser) {
    return null;
  }

  const date = DateUtils.formatDateToMonthDay(new Date(overdueTask.dueDate), {
    showYearIfPast: false,
    timeZone: currentUser.timeZone,
  });

  return (
    <div className={styles.overdueTaskItem}>
      <p className={styles.taskName}>
        {overdueTask.masked ? (
          <span className={styles.maskedTaskName}>
            <i className="far fa-lock" aria-hidden="true" />
            &nbsp;{overdueTask.taskName}
          </span>
        ) : (
          overdueTask.taskName
        )}
      </p>
      <div className={styles.taskInfo}>
        {!!overdueTask.assignees && overdueTask.assignees.length > 0 && (
          <span className={styles.assignees}>
            <AssigneesRenderer value={overdueTask.assignees} />
          </span>
        )}

        <span className={styles.dueDate}>{date}</span>
      </div>
    </div>
  );
};
