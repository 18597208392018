import angular from 'angular';
import './drag-drop.scss';

angular.module('frontStreetApp.directives').directive('psDragDrop', ($parse, $timeout, $window, jQuery, util) => ({
  restrict: 'A',
  link(scope, element, attributes) {
    const dragEnabled = !util.isMobile() && scope.$eval(attributes.drag);
    const dropEnabled = !util.isMobile() && scope.$eval(attributes.drop);

    const wrap = function (callback) {
      return function (event, ui) {
        $timeout(() => {
          callback(scope, { $event: event, $ui: ui });
        });
      };
    };

    // Fixed scroll parent
    jQuery.fn.scrollParent = function (includeHidden) {
      const position = this.css('position');
      const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;
      const scrollParent = this.parents()
        .filter(function () {
          const parent = jQuery(this);
          return overflowRegex.test(parent.css('overflow') + parent.css('overflow-y') + parent.css('overflow-x'));
        })
        .eq(0);

      return position === 'fixed' || !scrollParent.length
        ? jQuery(this[0].ownerDocument || $window.document)
        : scrollParent;
    };

    jQuery.ui.plugin.add('draggable', 'fixed', {
      drag(__event, __ui, i) {
        i.offset.click = { left: 0, top: 0 };
      },
    });

    if (dragEnabled) {
      const connectToSortable = scope.$eval(attributes.dragConnectSortable);
      scope.dragStopped = false;

      element.draggable({
        fixed: true,
        revert: false,
        zIndex: 2000,
        appendTo: 'body',
        revertDuration: 500,
        cancel: true,
        scroll: true,
        helper(e) {
          const dataType = jQuery(e.currentTarget).attr('data-type');
          const html =
            `${
              '<a href="javascript:"' +
              '   class="btn btn-default btn-dragging ui-draggable ui-draggable-handle"' +
              '   data-type="'
            }${dataType}">` +
            `  <i class="${attributes.dragIcon}"></i>` +
            `  <span>${attributes.dragName}<span></a>`;
          return jQuery(html);
        },
        connectToSortable: connectToSortable !== null ? connectToSortable : false,
        drag(__event, ui) {
          const el = angular.element(ui.helper[0]);
          ui.position.top -= el.outerHeight() / 2;
          ui.position.left -= el.outerWidth() / 2;
          scope.dragStopped = false;
        },
        stop() {
          scope.dragStopped = true;
        },
      });
    } else {
      element.on('dragstart', event => {
        event.preventDefault();
      });
    }

    if (dropEnabled) {
      const overCallback = $parse(attributes.dropOnOver);
      const dropCallback = $parse(attributes.dropOnDrop);
      const outCallback = $parse(attributes.dropOnOut);
      const dropScope = scope.$eval(attributes.dropScope);

      element.droppable({
        over: wrap(overCallback),
        drop: wrap(dropCallback),
        out: wrap(outCallback),
        scope: dropScope,
      });
    }
  },
}));
