import { noop } from '@process-street/subgrade/util';
import { useInjector } from 'components/injection-provider';
import { useFeatureFlag } from 'features/feature-flags';
import { SHOULD_SHOW_ONBOARDING_SLIDESHOW } from 'features/onboarding-slideshow/components/onboarding-slideshow/onboarding-slideshow';
import {
  useCreateOrganizationDetailsMutation,
  useGetOrganizationDetails,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
} from 'features/organization/query-builder';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { useGetUserDetailsQuery, useUpsertUserDetailsMutation } from 'features/user/query-builder/user-details';
import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import usePromise from 'react-use/lib/usePromise';
import { SessionSelector } from 'reducers/session/session.selectors';
import { Shell } from '../components/shell';
import { ShellContent } from '../components/shell-content';
import { ShellLeftPane } from '../components/shell-left-pane';
import { getSignUpErrorMessage } from '../helpers';
import { SignUpLoadingState } from '../loading-state';
import { FormValues, OrganizationDetailsForm } from './form';

export const OrganizationDetails: React.VFC = () => {
  const onboardingSlideshowEnabled = useFeatureFlag('onboardingSlideshow');
  useEffect(() => {
    if (onboardingSlideshowEnabled) {
      localStorage.setItem(SHOULD_SHOW_ONBOARDING_SLIDESHOW, 'true');
    }
  }, [onboardingSlideshowEnabled]);

  const { $stateParams, $state } = useInjector('$state', '$stateParams');
  const sessionUserId = useSelector(SessionSelector.getCurrentUserId);
  const { organizationId } = $stateParams;
  const mounted = usePromise();

  const goToNextStep = () => {
    $state.go('newCompleteSignUp.referral', {
      email: $stateParams.email,
      organizationId,
    });
  };

  const organizationQuery = useGetOrganizationQuery({ organizationId });
  const organizationDetailsQuery = useGetOrganizationDetails({ organizationId });
  const userDetailsQuery = useGetUserDetailsQuery(sessionUserId ?? '', {
    enabled: Boolean(sessionUserId),
  });
  const userInfoQuery = useGetCurrentUserInfoQuery();

  // some data may be 404 during initial setup, but we only show the form after tried to fetch
  // to prevent content flashes
  const allDataFetched = [organizationQuery, organizationDetailsQuery, userDetailsQuery, userInfoQuery]
    .map(q => q.isFetched)
    .every(q => Boolean(q));

  const emailUsername = userInfoQuery.data && getEmailUserName(userInfoQuery.data.user.email);
  const organizationName = organizationQuery.data?.name;
  // Newly created organization has its name automatically set to `${emailUsername}'s organization`.
  // Ex.: john@test.com -> "john's organization"
  // We need to know if the organization has a default name to decide whether or not to pass the
  // existing organization name to the initial form value.
  const isDefaultOrganizationName = organizationName && emailUsername && organizationName.startsWith(emailUsername);

  const updateOrganizationMutation = useUpdateOrganizationMutation({
    onSuccess: () => {
      organizationQuery.refetch();
    },
  });
  const createOrganizationDetailsMutation = useCreateOrganizationDetailsMutation({
    onSuccess: () => {
      organizationDetailsQuery.refetch();
    },
  });
  const upsertUserDetailsMutation = useUpsertUserDetailsMutation({
    onSuccess: () => {
      userDetailsQuery.refetch();
    },
  });

  const errorMessage =
    getSignUpErrorMessage(updateOrganizationMutation.error) ??
    getSignUpErrorMessage(createOrganizationDetailsMutation.error) ??
    getSignUpErrorMessage(upsertUserDetailsMutation.error);

  const isLoading =
    createOrganizationDetailsMutation.isLoading ||
    updateOrganizationMutation.isLoading ||
    upsertUserDetailsMutation.isLoading;

  const handleSubmit = async (values: FormValues) => {
    const createOrgDetails = createOrganizationDetailsMutation.mutateAsync({
      organizationId,
      employeeCount: values.employeeCount,
    });

    const upsertUser = sessionUserId
      ? upsertUserDetailsMutation.mutateAsync({
          userId: sessionUserId,
          department: values.department,
        })
      : Promise.resolve();

    const upsertOrg = organizationQuery.data
      ? updateOrganizationMutation.mutateAsync({
          ...organizationQuery.data,
          id: organizationId,
          name: values.organizationName,
        })
      : Promise.resolve();

    mounted(Promise.all([createOrgDetails, upsertUser, upsertOrg])).then(() => {
      goToNextStep();
    }, noop);
  };

  if (!allDataFetched) {
    return <SignUpLoadingState />;
  }

  return (
    <Shell>
      <ShellLeftPane />

      <ShellContent>
        <OrganizationDetailsForm
          initialValues={{
            organizationName: isDefaultOrganizationName ? '' : organizationQuery.data?.name,
            department: userDetailsQuery.data?.department,
            employeeCount: organizationDetailsQuery.data?.employeeCount,
          }}
          isLoading={isLoading}
          error={isLoading ? '' : errorMessage}
          onSubmit={handleSubmit}
        />
      </ShellContent>
    </Shell>
  );
};

const getEmailUserName = (email: string) => email.split('@')[0];
