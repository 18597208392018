import { axiosService } from 'services/axios-service';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export interface MicrosoftTeamsConnectParams {
  teamsConversationId: string;
  teamsVerificationCode: string;
}

export const ConnectMicrosoftTeams = {
  key: ['microsoft-teams', 'connect'],
  mutationFn: (params: MicrosoftTeamsConnectParams) =>
    axiosService
      .getAxios()
      .post(`/1/microsoft-teams/connect`, params)
      .then(r => r.data),
};

export const useConnectMicrosoftTeamsMutation = (
  options: UseMutationOptions<undefined, AxiosError, MicrosoftTeamsConnectParams> = {},
) => {
  return useMutation<undefined, AxiosError, MicrosoftTeamsConnectParams>(ConnectMicrosoftTeams.mutationFn, options);
};
