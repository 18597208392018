import React from 'react';
import { match, P } from 'ts-pattern';

import { Template, TemplateType } from '@process-street/subgrade/process';
import { Icon, Text } from 'components/design/next';

export type SearchCoverIconProps = {
  emoji?: string;
  templateType: Template['templateType'];
};

export const SearchCoverIcon: React.FC<React.PropsWithChildren<SearchCoverIconProps>> = ({ emoji, templateType }) => {
  /**
   * The emoji is undefined when a template is first created but an empty string if it's been deleted.
   * This is because you can't easily delete properties in Algolia so we reset it to "".
   */
  const normalisedEmoji = emoji === '' ? undefined : emoji;

  return match([normalisedEmoji, templateType])
    .with([P.nullish, TemplateType.Playbook], () => (
      <Icon icon="workflow" variant="fas" color="indigo.500" size="4" aria-label="workflow icon" mr="2" />
    ))
    .with([P.nullish, TemplateType.Form], () => (
      <Icon icon="ballot" variant="fas" color="orange.500" size="4" aria-label="form icon" mr="2" />
    ))
    .with([P.nullish, TemplateType.Page], () => (
      <Icon icon="file-alt" variant="fas" color="teal.500" size="4" aria-label="page icon" mr="2" />
    ))
    .with([P.not(undefined), P.any], () => (
      <Text fontSize="md" lineHeight="none" mr="2">
        {emoji}
      </Text>
    ))
    .otherwise(() => null);
};
