import { Muid } from '@process-street/subgrade/core';
import { NativeAutomation } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetAllNativeAutomationLogsByChecklistIdQuery {
  export type Params = { checklistId?: Muid };

  export type Response = Record<NativeAutomation['id'], NativeAutomation.Log[]>;

  export const key = ['native-automations-logs'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = ({ checklistId }: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/native-automations-logs`, {
        params: {
          checklistId,
        },
      })
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.checklistId) && options.enabled !== false,
    });
  };
}
