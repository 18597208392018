import { Approval, ApprovalStatus } from '@process-street/subgrade/approval-rule/approval.model';
import { Muid } from '@process-street/subgrade/core';
import { AngularResource } from 'reducers/angular-types';
import angular from 'angular';

interface ApprovalResource {
  getAllByChecklistRevisionId(request: { checklistRevisionId: Muid }): AngularResource<Approval[]>;
  upsertAll(request: { approvals: Approval[]; checklistRevisionId: Muid }): AngularResource<Approval[]>;
  upsertAllByTask(request: { taskId: Muid; approvalCommand: ApprovalStatus }): AngularResource<Approval[]>;
}

export interface ApprovalsApi {
  getAllByChecklistRevisionId(checklistRevisionId: Muid): angular.IPromise<Approval[]>;
  upsertAll(checklistRevisionId: Muid, approvals: Approval[]): angular.IPromise<Approval[]>;
  upsertAllByTask(taskId: Muid, approvalCommand: ApprovalStatus): angular.IPromise<Approval[]>;
}

export const ApprovalsApiImpl = function (Approvals: ApprovalResource): ApprovalsApi {
  'ngInject';

  const getAllByChecklistRevisionId = (checklistRevisionId: Muid) =>
    Approvals.getAllByChecklistRevisionId({ checklistRevisionId }).$promise;

  const upsertAll = (checklistRevisionId: Muid, approvals: Approval[]) =>
    Approvals.upsertAll({ approvals, checklistRevisionId }).$promise;

  const upsertAllByTask = (taskId: Muid, approvalCommand: ApprovalStatus) =>
    Approvals.upsertAllByTask({ taskId, approvalCommand }).$promise;

  return {
    getAllByChecklistRevisionId,
    upsertAll,
    upsertAllByTask,
  };
};
