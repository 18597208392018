import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace DeleteApiKeyMutation {
  export type Params = {
    apiKeyId: Muid;
    organizationId?: Muid;
  };

  export type Response = {};

  export const key = ['organization', 'api-keys'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .delete<Response>(`/1/organizations/${params.organizationId}/api-keys/${params.apiKeyId}`)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
