import angular from 'angular';
import { dayjs as moment } from '@process-street/subgrade/util';
import { InboxConstants } from '@process-street/subgrade/inbox/inbox-constants';
import { InboxItemType, InboxPathStateItemType } from '@process-street/subgrade/inbox/inbox-model';
import { connectService } from 'reducers/util';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.services')
  .service('InboxService', function ($ngRedux, $filter, InboxActions, TaskTemplateService) {
    const logger = trace({ name: 'InboxService' });

    const self = this;

    connectService('InboxService', $ngRedux, null, InboxActions)(self);

    function InboxException(message, item) {
      this.message = message;
      this.item = item;
      this.name = 'InboxException';
    }

    /**
     * Gets all inbox items for given organization and logged in user
     *
     * @param organization
     * @param filters
     * @param offset
     * @return {Promise} with list of available items
     */
    self.getAllItemsByOrganization = function (organization, filters, offset) {
      const requestFilterMap = {
        search: filters.search,
        group: filters.group,
        userIds: filters.userId,
        pseudoGroup: filters.pseudoGroup,
        itemsType: filters.itemsType,
      };

      if (filters.filter === InboxConstants.Filter.INBOX) {
        requestFilterMap.dateTo = moment().endOf('day').format('x');
        requestFilterMap.includeNoDueDate = true;
      } else {
        // this one is implicitly upcoming to avoid a situation when there are no limits passed
        requestFilterMap.dateFrom = moment().endOf('day').format('x');
      }

      return self.actions
        .getAllByOrganizationId(organization.id, requestFilterMap, offset)
        .then(({ payload }) => payload);
    };

    self.isDueDateInInbox = function (dueDate) {
      return !dueDate || moment().endOf('day').isAfter(moment(dueDate, 'x'));
    };

    /**
     * Groups given items by their due date
     *
     * @param items
     * @return {{}}
     */
    self.groupItemsByDueDate = function (items) {
      let groups = [];

      let ranges = self.generateDueDateRangeMap();
      items.forEach(item => {
        const itemDueDate = resolveDueDateByItem(item);

        let resolvedGroupKey = null;
        if (itemDueDate) {
          resolvedGroupKey = resolveGroupKeyByDueDate(ranges, itemDueDate);
        } else {
          resolvedGroupKey = InboxConstants.StdGroupKey.NO_DUE_DATE;
        }

        if (resolvedGroupKey) {
          groups = addItemToGroup(groups, item, resolvedGroupKey);
        } else {
          logger.info('due date range is missing for %s attempting to create additional for this date', itemDueDate);

          ranges = self.addRangeForDueDate(ranges, itemDueDate);

          resolvedGroupKey = resolveGroupKeyByDueDate(ranges, itemDueDate);

          if (resolvedGroupKey) {
            groups = addItemToGroup(groups, item, resolvedGroupKey);
          } else {
            // TODO: Talk to Cameron about this one
            throw new InboxException('could not add and/or resolve range for item', item);
          }
        }
      });

      return groups;
    };

    /**
     * Merges two groups objects based on the group's key
     *
     * @param targetGroups
     * @param sourceGroups
     *
     * @return {{}}
     */
    self.mergeGroups = function (targetGroups, sourceGroups) {
      const groups = targetGroups || {};

      sourceGroups.forEach(group => {
        const existingGroup = groups.find(grp => grp.key === group.key);

        if (existingGroup) {
          existingGroup.items = existingGroup.items.concat(group.items);
        } else {
          groups.push(group);
        }
      });

      return groups;
    };

    /**
     * Groups items by attached template name
     *
     * @param items
     * @return {{}}
     */
    self.groupItemsByProcess = function (items) {
      let groups = [];

      items.forEach(item => {
        groups = addItemToGroup(groups, item, item.template.name);
      });

      return groups;
    };

    /**
     * Resolves group key for a given due date
     *
     * @param ranges
     * @param dueDate
     * @return {undefined}
     */
    function resolveGroupKeyByDueDate(ranges, dueDate) {
      const parsedDueDate = moment(dueDate);

      // Resolving key using standard group keys
      let dueDateKey = InboxConstants.OrderedStdGroupKeys.find(key => isDueDateInRange(parsedDueDate, ranges[key]));

      // Trying to resolve using added keys (non-standard)
      if (!dueDateKey) {
        angular.forEach(ranges, (__range, key) => {
          if (InboxConstants.OrderedStdGroupKeys.indexOf(key) === -1 && isDueDateInRange(parsedDueDate, ranges[key])) {
            dueDateKey = key;
          }
        });
      }

      return dueDateKey;
    }

    function isDueDateInRange(parsedDueDate, range) {
      return (
        range &&
        ((!range.from && range.to.isSameOrAfter(parsedDueDate, 'second')) ||
          (range.from &&
            range.from.isSameOrBefore(parsedDueDate, 'second') &&
            range.to.isSameOrAfter(parsedDueDate, 'second')))
      );
    }

    /**
     * Adds an item into the correct group. Making sure that the group is actually created on the way.
     *
     * @param groups
     * @param item
     * @param groupKey
     * @return {*}
     */
    function addItemToGroup(groups, item, groupKey) {
      const existingGroup = groups.find(grp => grp.key === groupKey);
      if (existingGroup) {
        existingGroup.items.push(item);
      } else {
        groups.push({
          key: groupKey,
          items: [item],
        });
      }
      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }

      return groups;
    }

    /**
     * Resolves due date based on the item type
     *
     * @param item
     * @return {*}
     */
    function resolveDueDateByItem(item) {
      let dueDate;
      switch (item.itemType) {
        case InboxItemType.Checklist:
          ({ dueDate } = item.checklist);
          break;
        case InboxItemType.StandardTask:
        case InboxItemType.ApprovalTask:
        case InboxItemType.OneOffTask:
          ({ dueDate } = item.task);
          break;
        default:
          logger.error('unsupported inbox item type: %s', item.itemType);
      }

      return dueDate;
    }

    /**
     * Generates default collection of due date ranges
     *
     * @return {{}}
     */
    self.generateDueDateRangeMap = function () {
      const rangeMap = {};

      const overdue = {
        from: null,
        to: moment(),
      };
      rangeMap[InboxConstants.StdGroupKey.OVERDUE] = overdue;

      rangeMap[InboxConstants.StdGroupKey.TODAY] = {
        from: overdue.to.clone().add(1, 'seconds'),
        to: moment().endOf('day'),
      };

      // Setting 'from' the same as 'to' of TODAY if the end of a week
      // Otherwise the 'from' will fall into the range of NEXT_WEEK not THIS_WEEK
      const todayIsEndOfWeek = moment().endOf('week').isSame(moment().endOf('day'));
      const startOfThisWeek = todayIsEndOfWeek ? moment().endOf('day') : moment().endOf('day').add(1, 'seconds');
      const endOfThisWeek = moment().startOf('week').add(1, 'weeks').add(-1, 'seconds');
      rangeMap[InboxConstants.StdGroupKey.THIS_WEEK] = {
        from: startOfThisWeek,
        to: endOfThisWeek,
      };

      // Correcting 'to' of next week if it fall in the range of next month
      const endOfNextWeek = moment().startOf('week').add(2, 'weeks').add(-1, 'seconds');
      rangeMap[InboxConstants.StdGroupKey.NEXT_WEEK] = {
        from: moment().startOf('week').add(1, 'weeks'),
        to: endOfNextWeek,
      };

      rangeMap[InboxConstants.StdGroupKey.THIS_MONTH] = {
        from: rangeMap[InboxConstants.StdGroupKey.TODAY].to.clone().add(1, 'seconds'),
        to: moment().startOf('month').add(1, 'months').add(-1, 'seconds'),
      };

      rangeMap[InboxConstants.StdGroupKey.NEXT_MONTH] = {
        from: moment().startOf('month').add(1, 'months'),
        to: moment().startOf('month').add(2, 'months').add(-1, 'seconds'),
      };

      return rangeMap;
    };

    /**
     * Adds another range into given rangeMap to cover given due date.
     * Added range is a month long.
     *
     * @param rangeMap
     * @param dueDate
     * @return {*}
     */
    self.addRangeForDueDate = function (rangeMap, dueDate) {
      const latestRange = findLatestRange(rangeMap);

      if (latestRange.to.isAfter(moment(dueDate))) {
        return rangeMap;
      }

      const rangeToAdd = {
        from: moment(dueDate).startOf('month'),
        to: moment(dueDate).startOf('month').add(1, 'months').add(-1, 'seconds'),
      };

      // Let's not surprise our clients with modified objects
      const modifiedRangeMap = angular.copy(rangeMap);
      modifiedRangeMap[rangeToAdd.from.format('YYYY-MM-DD')] = rangeToAdd;

      return modifiedRangeMap;
    };

    self.MASKED_ITEM_TEXT = "You don't have permission to see this task";

    self.resolveTaskName = item => {
      if (item.masked) {
        return self.MASKED_ITEM_TEXT;
      }
      return item.task.taskTemplate.name || TaskTemplateService.DEFAULT_NAME;
    };

    /**
     * Finds the latest range in the list of existing rangeMap
     *
     * @param rangeMap
     * @return {*}
     */
    function findLatestRange(rangeMap) {
      let latest;

      angular.forEach(rangeMap, range => {
        if (!latest) {
          latest = range;
        } else if (range.to.isAfter(latest.to, 'second')) {
          latest = range;
        }
      });

      return latest;
    }

    /**
     * Returns true if task is Standard Task or Approval Task
     *
     * @param itemType
     * @returns {boolean}
     */
    self.isTask = itemType => itemType === InboxItemType.StandardTask || itemType === InboxItemType.ApprovalTask;

    self.isApprovalTask = itemType => itemType === InboxItemType.ApprovalTask;

    /**
     * Converts inbox item type to path state type
     * @param itemType
     * @returns {InboxPathStateItemType.Checklist|InboxPathStateItemType.Task|InboxPathStateItemType.Approval}
     */
    self.convertItemTypeToPathStateType = itemType => {
      switch (itemType) {
        case InboxItemType.Checklist:
          return InboxPathStateItemType.Checklist;
        case InboxItemType.StandardTask:
        case InboxItemType.OneOffTask:
          return InboxPathStateItemType.Task;
        case InboxItemType.ApprovalTask:
          return InboxPathStateItemType.Approval;
        default:
          throw new Error(`unsupported item type ${itemType} in inbox`);
      }
    };

    /**
     * Converts path state type to inbox item type
     * @param itemType
     * @returns {InboxItemType.Checklist|InboxItemType.StandardTask|InboxItemType.ApprovalTask}
     */
    self.convertPathStateTypeToItemType = itemType => {
      switch (itemType) {
        case InboxPathStateItemType.Checklist:
          return InboxItemType.Checklist;
        case InboxPathStateItemType.Task:
          return InboxItemType.StandardTask;
        case InboxPathStateItemType.Approval:
          return InboxItemType.ApprovalTask;
        default:
          throw new Error(`unsupported path state type ${itemType} in inbox`);
      }
    };

    self.getOverdueClassName = function (dueDate) {
      const dueMoment = moment(dueDate);
      const now = moment(Date.now());

      if (dueMoment.isBefore(now)) {
        return 'badge-danger';
      }

      if (dueMoment.diff(now, 'h') <= 24) {
        return 'badge-warning';
      }

      return 'badge-primary';
    };

    self.resolveDueDate = function (dueDate) {
      const dueMoment = moment(dueDate);

      return dueDate && moment().startOf('day').isBefore(dueMoment) && moment().endOf('day').isAfter(dueMoment)
        ? $filter('date')(dueDate, 'h:mm a')
        : $filter('date')(dueDate, 'MMM d');
    };

    self.isOverdue = function (dueDate) {
      return dueDate && moment(dueDate, 'x').isBefore(moment());
    };
  });
