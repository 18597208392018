import * as React from 'react';
import {
  FormFieldValueWithWidget,
  isMultiSelectFormFieldWidget,
  MultiSelectFieldValue,
  MultiSelectFormFieldValue,
  MultiSelectItemValueStatus,
} from '@process-street/subgrade/process';
import { Box, HStack, Text, VStack } from 'components/design/next';
import { Checkbox } from '@chakra-ui/react';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import {
  GetFormFieldValuesByChecklistRevisionIdQuery,
  useGetFormFieldValuesByChecklistRevisionIdQuery,
  useUpdateFormFieldValueMutation,
} from 'features/widgets/query-builder';
import { useQueryClient } from 'react-query';
import { isFunction } from 'lodash';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { wrapLinks } from 'utils/wrap-links';

interface SubTasksProps {
  task: OneOffTask;
  isPrintView?: boolean;
}

export const SubTasks: React.FC<React.PropsWithChildren<SubTasksProps>> = ({ task, isPrintView = false }) => {
  const queryClient = useQueryClient();

  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({
    checklistId: task.internalChecklistId,
  });
  const subtasksQuery = useGetFormFieldValuesByChecklistRevisionIdQuery(
    { checklistRevisionId: checklistRevisionQuery.data?.id },
    {
      select: ffvs =>
        ffvs.find(ffv => isMultiSelectFormFieldWidget(ffv.formFieldWidget)) as MultiSelectFormFieldValue | undefined,
    },
  );
  const updateFormFieldValue = useUpdateFormFieldValueMutation({
    onMutate: variables => {
      const queryKey = GetFormFieldValuesByChecklistRevisionIdQuery.getKey({
        checklistRevisionId: checklistRevisionQuery.data?.id,
      });
      const cachedData = queryClient.getQueryData<FormFieldValueWithWidget[]>(queryKey);

      queryClient.setQueryData<FormFieldValueWithWidget[]>(queryKey, prev => {
        return (prev ?? []).map(ffv => {
          if (ffv.id === subtasksQuery.data?.id) {
            return {
              ...ffv,
              fieldValue: {
                itemValues: (variables as MultiSelectFormFieldValue['fieldValue']).itemValues,
              },
            };
          }

          return ffv;
        });
      });

      return () => {
        queryClient.setQueryData(queryKey, cachedData);
      };
    },
    onError: (_, __, context) => {
      if (isFunction(context)) {
        // revert cache on error
        context();
      }
    },
  });
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, fieldValue: MultiSelectFieldValue) => {
    if (!checklistRevisionQuery.data || !subtasksQuery.data) return;

    const newFieldValue: MultiSelectFieldValue = {
      ...fieldValue,
      status: e.target.checked ? MultiSelectItemValueStatus.Completed : MultiSelectItemValueStatus.NotCompleted,
    };
    const newItemValues = subtasksQuery.data.fieldValue.itemValues.map(itemValue => {
      if (itemValue.id === newFieldValue.id) return newFieldValue;

      return itemValue;
    });

    updateFormFieldValue.mutate({
      itemValues: newItemValues,
      checklistRevisionId: checklistRevisionQuery.data.id,
      widgetId: subtasksQuery.data.formFieldWidget.id,
    });
  };
  if (!subtasksQuery.data || subtasksQuery.data.fieldValue.itemValues.length === 0) {
    return null;
  }
  return (
    <VStack w="full" alignItems="flex-start" pt={8} spacing={2}>
      <Text variant="-2u" fontWeight="bold" color="gray.600">
        Subtasks
      </Text>
      <VStack w="full" alignItems="flex-start" spacing={2}>
        {subtasksQuery.data.fieldValue.itemValues.map((itemValue, index) => {
          const isChecked = itemValue.status === MultiSelectItemValueStatus.Completed;
          return (
            <HStack key={itemValue.id} spacing={2}>
              <Box
                minW={2}
                sx={{
                  '@media print': {
                    p: { margin: '0 !important' }, // fix print overrides
                  },
                }}
              >
                <Text fontSize="sm" color="gray.400">
                  {index + 1}
                </Text>
              </Box>
              <Checkbox
                isChecked={isChecked}
                onChange={e => handleCheckboxChange(e, itemValue)}
                sx={{
                  '.chakra-checkbox__control': {
                    borderWidth: 'thin',
                    borderColor: 'gray.300',
                    borderRadius: '4px',
                    w: 5,
                    h: 5,
                  },
                }}
                isDisabled={isPrintView}
              />
              <Text
                color={isChecked ? 'gray.400' : 'gray.600'}
                lineHeight="24px"
                textDecoration={isChecked ? 'line-through' : undefined}
                fontStyle={isChecked ? 'italic' : undefined}
                dangerouslySetInnerHTML={{
                  __html: wrapLinks(itemValue.name ?? '<unnamed subtask>'),
                }}
              />
            </HStack>
          );
        })}
      </VStack>
    </VStack>
  );
};
