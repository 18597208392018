import { RowNode } from '@ag-grid-community/core';
import { Identifiable } from '@process-street/subgrade/core';
import { createUsableContext } from '@process-street/subgrade/util';
import { TasksTableUtils } from 'pages/tasks/components/tasks-table/tasks-table-utils';

export type IdentifiableTaskRow = Omit<RowNode<TasksTableUtils.TasksTableItem>, 'id'> & Identifiable;

type Context = [selectedRows: IdentifiableTaskRow[], setSelectedRows: (rows: IdentifiableTaskRow[]) => void];

export const [useTasksRowSelectionProvider, TasksRowSelectionContext] = createUsableContext<Context>({
  hookName: 'useTasksRowSelectionProvider',
  providerName: 'TasksRowSelectionProvider',
});
