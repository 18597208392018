import { TemplateStatus } from '@process-street/subgrade/process';
import { Icon, MenuDivider } from 'components/design/next';
import { MoveTemplateButton } from 'features/template/components/template-menu/move-template-button';
import { TemplateActivityButton } from 'features/template/components/template-menu/template-activity-button';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import * as React from 'react';
import { TemplateMenuItem, useTemplateMenuContext } from './template-menu';
import { ArchiveTemplateButton } from './archive-template-button';
import { DuplicateTemplateButton } from './duplicate-template-button';
import { DeleteTemplateButton } from './delete-template-button';
import { CopyShareLinkButton } from './copy-share-link-button';
import { ManageTemplateTagsButton } from './manage-template-tags-button/manage-template-tags-button';
import { useMatch } from '@process-street/adapters/navigation';

export const PageMenu: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { templateId } = useTemplateMenuContext();
  const pageIsPrivate = useIsPrivateTemplate(templateId);
  const isPageViewV2 = useMatch('pageViewV2');
  const menuItemFontSize = isPageViewV2 ? 'sm' : 'md';
  return (
    <>
      {children}
      {!pageIsPrivate && (
        <CopyShareLinkButton templateId={templateId}>
          {({ copiedValue }) => (
            <TemplateMenuItem
              fontSize={menuItemFontSize}
              icon={
                <Icon
                  icon={copiedValue ? 'check' : 'link'}
                  size="4"
                  variant="far"
                  color={copiedValue ? 'green.500' : 'gray.500'}
                />
              }
            >
              Copy share link
            </TemplateMenuItem>
          )}
        </CopyShareLinkButton>
      )}

      <TemplateActivityButton>
        <TemplateMenuItem
          fontSize={menuItemFontSize}
          icon={<Icon icon="history" size="4" variant="far" color="gray.500" />}
        >
          Activity
        </TemplateMenuItem>
      </TemplateActivityButton>

      <ManageTemplateTagsButton>
        <TemplateMenuItem
          fontSize={menuItemFontSize}
          icon={<Icon icon="tag" color="gray.500" variant="far" size="4" />}
          closeOnSelect={false}
        >
          Manage tags
        </TemplateMenuItem>
      </ManageTemplateTagsButton>

      <MenuDivider />

      <MoveTemplateButton>
        <TemplateMenuItem
          fontSize={menuItemFontSize}
          icon={<Icon icon="arrow-right" size="4" variant="far" color="gray.500" />}
        >
          Move
        </TemplateMenuItem>
      </MoveTemplateButton>

      <DuplicateTemplateButton>
        <TemplateMenuItem
          fontSize={menuItemFontSize}
          icon={<Icon icon="copy" size="4" variant="far" color="gray.500" />}
        >
          Duplicate
        </TemplateMenuItem>
      </DuplicateTemplateButton>

      <ArchiveTemplateButton>
        {({ templateStatus, isMutating }) => {
          const icon = isMutating ? (
            <Icon icon="spinner-third" animation="spin" size="4" variant="far" color="gray.500" />
          ) : (
            <Icon icon="archive" size="4" variant="far" color="gray.500" />
          );
          return (
            <TemplateMenuItem fontSize={menuItemFontSize} isDisabled={isMutating} icon={icon}>
              {templateStatus === TemplateStatus.Active ? 'Archive' : 'Unarchive'}
            </TemplateMenuItem>
          );
        }}
      </ArchiveTemplateButton>
      <MenuDivider />

      <DeleteTemplateButton>
        {({ isMutating }) => {
          const icon = isMutating ? (
            <Icon icon="spinner-third" animation="spin" size="4" variant="far" color="gray.500" />
          ) : (
            <Icon icon="trash-alt" size="4" variant="far" color="red.500" />
          );

          return (
            <TemplateMenuItem fontSize={menuItemFontSize} isDisabled={isMutating} color="red.500" icon={icon}>
              Delete
            </TemplateMenuItem>
          );
        }}
      </DeleteTemplateButton>
    </>
  );
};
