import angular from 'angular';

angular
  .module('frontStreetApp.services')
  .run(
    (
      $rootScope,
      AnalyticsListener,
      GrowSumoListener,
      ProfitwellListener,
      SatisMeterListener,
      SentryListener,
      TaskListAnalyticsListener,
      TemplateAnalyticsListener,
    ) => {
      // Interop
      GrowSumoListener.listen();
      ProfitwellListener.listen();
      SatisMeterListener.listen();
      SentryListener.listen();

      // Analytics
      AnalyticsListener.listen($rootScope);
      TaskListAnalyticsListener.listen($rootScope);
      TemplateAnalyticsListener.listen($rootScope);
    },
  );
