import React from 'react';
import { useChecklistScreenState } from '../../../ChecklistDashboardScreen/checklist-screen-state';
import { Box } from 'components/design/next';
import {
  TemplatesFilter,
  TemplatesFilterProps,
} from 'components/dashboard/components/checklist/ChecklistDashboardFilters/filters/ChecklistDashboardTemplatesFilter/templates-filter';

export const ChecklistDashboardTemplatesFilter = (props: TemplatesFilterProps) => {
  const { viewModifiers } = useChecklistScreenState();
  const templatesChanged = viewModifiers?.includes('templates') ?? false;

  return (
    <Box
      aria-label={templatesChanged ? 'Template filter has been modified' : undefined}
      w={{ base: 'full', md: '69' }}
      sx={{
        '.blvd-select .blvd-select__menu': {
          width: { md: '80' },
          zIndex: 'dropdown',
        },
        ...(templatesChanged
          ? {
              '.blvd-select .blvd-select__control': {
                'borderColor': 'brand.500',

                '.blvd-select__multi-value, .blvd-select__overflowing-values-indicator': {
                  backgroundColor: 'brand.100',
                },
                '.blvd-select__indicator': {
                  color: 'brand.500',
                },
              },
            }
          : {}),
      }}
    >
      <TemplatesFilter {...props} />
    </Box>
  );
};
