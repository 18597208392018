import * as React from 'react';
import { FormEvent } from 'react';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Progress,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from 'components/design/next';
import { Muid } from '@process-street/subgrade/core';
import { useInjector } from 'components/injection-provider';
import {
  PublishDraftMutation,
  useGetMigrationStatsByTemplateRevisionIdQuery,
} from 'features/template-revisions/query-builder';
import { useDebounce } from 'react-use';
import {
  getFirstStateMatching,
  redirectToRelevantState,
  RELEVANT_MIGRATION_STATES,
} from 'components/migration/store/util';
import { match } from 'ts-pattern';
import { usePublishAndRun } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/use-publish-and-run';
import { LocalStorageService } from 'features/storage/local-storage-service';
import { useFeatureFlag } from 'features/feature-flags';
import { ScheduleAllMigrationsByTemplateRevisionIdMutation } from 'features/checklist-revisions/query-builder';
import { useQueryClient } from 'react-query';
import { PostReportsSearchQuery } from 'app/features/checklist-grid/query-builder';

export type Props = {
  templateId: Muid;
  templateName: string;
  templateRevisionId: Muid;
  workflowRunCount: number;
  runWorkflow?: boolean;
} & Omit<ModalProps, 'children'>;

type PublishType = 'update' | 'dont-update';

const defaultPublishTypeKey = 'ChecklistMigrationModal_defaultPublishType';
const defaultChecklistMigrationStrategyKey = 'ChecklistMigrationModal_defaultChecklistMigrationStrategy';

export const ChecklistMigrationModal: React.FC<React.PropsWithChildren<Props>> = ({
  templateId,
  templateName,
  templateRevisionId,
  workflowRunCount,
  runWorkflow = false,
  ...props
}) => {
  const queryClient = useQueryClient();
  const publishDraftMutation = PublishDraftMutation.useMutation();

  const publishAndRunMutation = usePublishAndRun({
    templateId,
    templateName,
    templateRevisionId,
  });

  const wfrMigrationUpdatesFeatureEnabled = useFeatureFlag('wfrMigrationUpdates');
  const isReactWorkflowEditorEnabled = useFeatureFlag('reactWorkflowEditor');

  const workflowRunPlural = `workflow run${workflowRunCount > 1 ? 's' : ''}`;
  const { $state, TempDataService } = useInjector('$state', 'TempDataService');
  // Progress target does jumps like 0 -> 33 -> 66 -> 100
  const [progressTarget, setProgressTarget] = React.useState(0);
  // Progress is linear by 1
  const [progress, setProgress] = React.useState(0);

  const defaultPublishType = (LocalStorageService.getItem(defaultPublishTypeKey) ?? 'update') as PublishType;
  const [publishType, setPublishType] = React.useState<PublishType>(defaultPublishType);

  const defaultAlertUsers = LocalStorageService.getItem(defaultChecklistMigrationStrategyKey) !== 'Optional';
  const [alertUsers, setAlertUsers] = React.useState(defaultAlertUsers);

  // Scale up progress when progressTarget changes
  React.useEffect(() => {
    if (progress < progressTarget) {
      setProgress(progress + 1);
    }
  }, [progressTarget, progress]);

  const returnState = getFirstStateMatching(TempDataService.getStates(), ...RELEVANT_MIGRATION_STATES);
  const redirect = React.useCallback(() => {
    setGetStatsState('done');
    redirectToRelevantState({ $state, returnState, isReactWorkflowEditorEnabled });
  }, [$state, returnState, isReactWorkflowEditorEnabled]);

  // Use state to drive polling enabled state
  const [getStatsState, setGetStatsState] = React.useState<'idle' | 'polling' | 'done'>('idle');
  const migrationStatsQuery = useGetMigrationStatsByTemplateRevisionIdQuery(
    { templateRevisionId },
    {
      enabled: getStatsState === 'polling',
      refetchInterval: 1000,
      onSuccess: async ({ countScheduled = 0, countMigrated = 0, countMigrating = 0 }) => {
        const total = countScheduled + countMigrated + countMigrating;
        const completed = countMigrated === total;
        setProgressTarget((countMigrated / total) * 100);
        if (completed) {
          setGetStatsState('done');
        }
      },
    },
  );

  const inMigrationStatsView = props.isOpen && getStatsState !== 'idle' && migrationStatsQuery.isSuccess;
  // If the migration stats query refetches due to tab change or something and we're on the polling screen, redirect.
  React.useEffect(() => {
    if (inMigrationStatsView) {
      return () => redirect();
    }
  }, [inMigrationStatsView, redirect]);

  // Debounce the final redirect to show the 100% state
  useDebounce(
    () => {
      if (getStatsState === 'done') {
        redirect();
      }
    },
    1000,
    [getStatsState, redirect],
  );

  const scheduleMutation = ScheduleAllMigrationsByTemplateRevisionIdMutation.useMutation({
    onSuccess: () => {
      setGetStatsState('polling');
    },
  });

  const update = React.useCallback(async () => {
    saveModalSettings('Required');
    await publishDraftMutation.mutateAsync({ tmplRevId: templateRevisionId }).then(async () => {
      await PostReportsSearchQuery.invalidate(queryClient);
      scheduleMutation.mutate({ templateRevisionId });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
  }, [publishDraftMutation, scheduleMutation, templateRevisionId]);

  const dontUpdate = React.useCallback(async () => {
    const checklistMigrationStrategy = alertUsers ? 'Required' : 'Optional';
    saveModalSettings(checklistMigrationStrategy);

    await publishDraftMutation
      .mutateAsync({ tmplRevId: templateRevisionId, checklistMigrationStrategy })
      .then(async () => {
        await PostReportsSearchQuery.invalidate(queryClient);
        redirect();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
  }, [publishDraftMutation, redirect, templateRevisionId, alertUsers]);

  const updateAndRun = React.useCallback(async () => {
    saveModalSettings('Required');
    await publishAndRunMutation.mutateAsync({}).then(async () => {
      await PostReportsSearchQuery.invalidate(queryClient);
      scheduleMutation.mutate({ templateRevisionId });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
  }, [publishAndRunMutation, scheduleMutation, templateRevisionId]);

  const dontUpdateButRun = React.useCallback(async () => {
    const checklistMigrationStrategy = alertUsers ? 'Required' : 'Optional';
    saveModalSettings(checklistMigrationStrategy);

    await PostReportsSearchQuery.invalidate(queryClient);
    publishAndRunMutation.mutate({ checklistMigrationStrategy });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
  }, [publishAndRunMutation, alertUsers]);

  const handleAlertUsersChange = (event: FormEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;
    setAlertUsers(checked);
  };

  const saveModalSettings = (checklistMigrationStrategy: PublishDraftMutation.ChecklistMigrationStrategy) => {
    LocalStorageService.setItem(defaultPublishTypeKey, publishType);
    LocalStorageService.setItem(defaultChecklistMigrationStrategyKey, checklistMigrationStrategy);
  };

  const publish = () =>
    match({ publishType, runWorkflow })
      .with({ publishType: 'update', runWorkflow: false }, update)
      .with({ publishType: 'dont-update', runWorkflow: false }, dontUpdate)
      .with({ publishType: 'update', runWorkflow: true }, updateAndRun)
      .with({ publishType: 'dont-update', runWorkflow: true }, dontUpdateButRun)
      .run();

  return (
    <Modal {...props} onClose={inMigrationStatsView ? redirect : props.onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        {inMigrationStatsView ? (
          <>
            <ModalHeader>
              <Text variant="2">
                Updating {workflowRunCount} {workflowRunPlural}
              </Text>
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody pt="4" pb="8">
              <VStack spacing="4">
                <Progress w="full" value={progress} />
                <Text variant="1">
                  This update can be done in the background, feel free to navigate away from this page.
                </Text>
              </VStack>
            </ModalBody>
            <ModalFooter borderTop="1px" borderColor="gray.200">
              <ButtonGroup>
                <Button variant="secondary" onClick={redirect} isLoading={getStatsState === 'done'}>
                  {returnState?.state.name.includes('checklist') ? 'Return to Workflow Run' : 'Return to Workflow'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalHeader>
              {wfrMigrationUpdatesFeatureEnabled ? (
                <Text variant="2">
                  There are {workflowRunCount} active {workflowRunPlural} in progress
                </Text>
              ) : (
                <Text variant="2">Confirm real-time workflow run update?</Text>
              )}
            </ModalHeader>
            <ModalCloseButton />
            {wfrMigrationUpdatesFeatureEnabled ? (
              <ModalBody pt="4" pb="8">
                <RadioGroup value={publishType} onChange={v => setPublishType(v as PublishType)}>
                  <VStack mt="4" spacing="2" alignItems="flex-start">
                    <Radio value={'update' as PublishType}>Update all in-progress workflow runs</Radio>
                    <Radio value={'dont-update' as PublishType}>Update each one manually</Radio>
                    {publishType === 'dont-update' && (
                      <Checkbox isChecked={alertUsers} paddingLeft={5} onChange={handleAlertUsersChange}>
                        Alert users to update to latest version
                      </Checkbox>
                    )}
                  </VStack>
                </RadioGroup>
              </ModalBody>
            ) : (
              <ModalBody pt="4" pb="8">
                <Text variant="1">
                  This workflow has{' '}
                  <Text as="b" fontWeight="bold">
                    {workflowRunCount}
                  </Text>{' '}
                  {workflowRunPlural} in progress.
                </Text>
                <Text variant="1">Do you want to update them to reflect the published changes?</Text>
                <Text mt="4">Otherwise, you will be able to manually update them 1-by-1.</Text>

                <RadioGroup value={publishType} onChange={v => setPublishType(v as PublishType)}>
                  <VStack mt="4" spacing="2" alignItems="flex-start">
                    <Radio value={'update' as PublishType}>
                      Update {workflowRunCount} {workflowRunPlural}
                    </Radio>

                    <Radio value={'dont-update' as PublishType}>Don't update in progress {workflowRunPlural}</Radio>
                  </VStack>
                </RadioGroup>
              </ModalBody>
            )}

            <ModalFooter borderTop="1px" borderColor="gray.200">
              <ButtonGroup>
                <Button variant="ghost" onClick={props.onClose} fontWeight="normal">
                  Cancel
                </Button>

                <Button
                  variant="primary"
                  onClick={publish}
                  isLoading={
                    publishDraftMutation.isLoading ||
                    publishDraftMutation.isSuccess ||
                    scheduleMutation.isLoading ||
                    scheduleMutation.isSuccess ||
                    publishAndRunMutation.isLoading ||
                    publishAndRunMutation.isSuccess ||
                    getStatsState === 'done'
                  }
                >
                  {runWorkflow ? 'Publish and Run' : 'Publish'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
