import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import {
  PaywallAdminBodyDescription,
  PaywallAdminFinePrint,
  PaywallAdminFinePrintLink,
  PaywallAdminHeaderIcon,
  PaywallAdminIconUserPlus,
  PaywallBodyAdminHeading,
  PaywallBodyDescription,
  PaywallBodyHeading,
  PaywallBodyNoIcon,
  PaywallBodyWithIcon,
  PaywallButtonGroup,
  PaywallCloseButton,
  PaywallDivider,
  PaywallFeatures,
  PaywallFooter,
  PaywallHeader,
  PaywallHeaderWithIcon,
  PaywallIconUserPlus,
  PaywallModal,
  PaywallPrimaryActionLinkButton,
  PaywallSecondaryActionButton,
} from 'components/paywalls/common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useAdminEmails } from '../use-admin-emails';
import { mailto as buildMailToLink } from '@process-street/subgrade/process';
import { usePaywall } from '../paywall-provider';
import { useFeatureSet } from 'components/paywalls/use-feature-set';
import { IntercomService } from 'services/interop/intercom-service';
import { useRoleNames } from 'hooks/use-role-names';
import { AnalyticsService } from 'components/analytics/analytics.service';

export const FullMember: React.FC<React.PropsWithChildren<{ invitee?: string }>> = ({ invitee = 'That user' }) => {
  const { setPaywall } = usePaywall();
  const { usersLimit } = useFeatureSet();

  const { name: orgName } = useSelector(SessionSelector.getSelectedOrganization) ?? {};
  const [adminEmail, ...bcc] = useAdminEmails();
  const roleNames = useRoleNames();

  const mailto = buildMailToLink({
    to: adminEmail,
    subject: `Someone on your team is requesting an upgrade on your Process Street plan`,
    body: `Help your team create and run more workflows in Process Street.\n\nUpgrade here: https://app.process.st/organizations/manage/billing`,
    bcc,
  });

  const closePaywall = React.useCallback(() => {
    setPaywall(null);
  }, [setPaywall]);
  return (
    <PaywallModal>
      <PaywallHeader>
        <PaywallCloseButton onClick={closePaywall} />
      </PaywallHeader>

      <PaywallBodyWithIcon>
        <PaywallIconUserPlus />

        <PaywallBodyHeading>
          {orgName} has reached {usersLimit} member limit
        </PaywallBodyHeading>

        <PaywallBodyDescription mb="6">
          {invitee} cannot be added as a {roleNames.FullMember.single}. Your organization is already at the max that
          comes with your Free plan.
        </PaywallBodyDescription>
        <PaywallBodyDescription>
          Add more {roleNames.FullMember.plural} by requesting your admin upgrade to Pro.
        </PaywallBodyDescription>
      </PaywallBodyWithIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallPrimaryActionLinkButton href={mailto}>Send Request</PaywallPrimaryActionLinkButton>
      </PaywallFooter>
    </PaywallModal>
  );
};

export const Admin: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setPaywall } = usePaywall();
  const { usersLimit } = useFeatureSet();
  const { name: orgName } = useSelector(SessionSelector.getSelectedOrganization) ?? {};
  const roleNames = useRoleNames();

  const contactSales = React.useCallback(() => {
    AnalyticsService.trackEvent('contact us link clicked', { location: 'use checklist' });
    IntercomService.show();
  }, []);

  const closePaywall = React.useCallback(() => {
    setPaywall(null);
  }, [setPaywall]);

  return (
    <PaywallModal>
      <PaywallHeaderWithIcon bgColor="brand.100">
        <PaywallCloseButton onClick={closePaywall} />

        <PaywallAdminHeaderIcon>
          <PaywallAdminIconUserPlus />
        </PaywallAdminHeaderIcon>
      </PaywallHeaderWithIcon>

      <PaywallBodyNoIcon>
        <PaywallBodyAdminHeading>
          {orgName} has reached {usersLimit} member limit
        </PaywallBodyAdminHeading>
        <PaywallAdminBodyDescription>
          Your organization is at the max that comes with your free plan. Add more {roleNames.FullMember.plural} by
          upgrading {orgName} to Pro.
        </PaywallAdminBodyDescription>

        <PaywallFeatures />

        <PaywallAdminFinePrint pt="4">
          You may{' '}
          <PaywallAdminFinePrintLink href="/organizations/manage/users">
            demote another {roleNames.FullMember.single} to proceed on the Free plan.
          </PaywallAdminFinePrintLink>
        </PaywallAdminFinePrint>
      </PaywallBodyNoIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallButtonGroup>
          <PaywallSecondaryActionButton onClick={contactSales} className="intercom-bot-contact-sales">
            Contact Sales
          </PaywallSecondaryActionButton>

          <PaywallPrimaryActionLinkButton href="/organizations/manage/billing">
            Upgrade Now
          </PaywallPrimaryActionLinkButton>
        </PaywallButtonGroup>
      </PaywallFooter>
    </PaywallModal>
  );
};

export const UIs = {
  [OrganizationMembershipRole.FreeMember]: () => null,
  [OrganizationMembershipRole.FullMember]: FullMember,
  [OrganizationMembershipRole.Admin]: Admin,
  [OrganizationMembershipRole.Guest]: () => null,
};
