import { useGetChecklistAssignmentsQuery } from 'features/checklists/query-builder';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { isStandardUserOm } from '@process-street/subgrade/util/membership-utils';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { Checklist } from '@process-street/subgrade/process';
import { isGuest, isNotIdRef, isOrganizationMembershipActive, User } from '@process-street/subgrade/core';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { useSelectedOrganization } from 'hooks/use-selected-organization';

export const useMentionableUsers = ({
  checklistId,
}: {
  checklistId?: Checklist['id'];
}): { loading: boolean; users: User[] } => {
  const selectedOrganization = useSelectedOrganization();
  const userInfoQuery = useGetCurrentUserInfoQuery({
    select: data => {
      return {
        currentUser: data?.user,
        userIsGuest: isGuest(data?.organizationMembership?.role),
        userIsAnonymous: Boolean(isAnonymousUser(data?.user)),
      };
    },
  });

  const organizationMembershipsQuery = useGetAllOrganizationMembershipsQuery(
    { organizationId: selectedOrganization?.id! },
    {
      enabled: Boolean(selectedOrganization) && Boolean(userInfoQuery.isSuccess),
      select: data =>
        data.filter(membership => isStandardUserOm(membership) && isOrganizationMembershipActive(membership)),
    },
  );

  const checklistAssignmentsQuery = useGetChecklistAssignmentsQuery(
    { checklistId: checklistId! },
    { enabled: Boolean(checklistId), staleTime: 1000 * 30, retry: false },
  );

  const loading =
    userInfoQuery.isLoading || organizationMembershipsQuery.isLoading || checklistAssignmentsQuery.isLoading;

  const isAnonymousGuest = !userInfoQuery.data?.userIsAnonymous && userInfoQuery.data?.userIsGuest;
  if (isAnonymousGuest) {
    const checklistUsersId = checklistAssignmentsQuery.data
      ?.map(a => isNotIdRef(a.organizationMembership) && a.organizationMembership.user.id)
      .filter(Boolean);

    return {
      loading,
      users:
        organizationMembershipsQuery.data
          ?.filter(mbsp => {
            const isGuestUser = isGuest(mbsp.role);
            const isChecklistUser = checklistUsersId && checklistUsersId.indexOf(mbsp?.user?.id) >= 0;
            return !isGuestUser || isChecklistUser;
          })
          .map(mbsp => mbsp.user) ?? [],
    };
  } else {
    return {
      loading,
      users: organizationMembershipsQuery.data?.map(mbsp => mbsp.user) ?? [],
    };
  }
};
