import * as React from 'react';
import { components, GroupBase, SelectComponentsConfig } from 'react-select';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { useInboxDropdownStyles } from '../use-inbox-dropdown-styles';
import { DropdownIndicator } from 'pages/tasks/components/filter-bar/selector-common-components';
import { Center, HStack } from '@chakra-ui/react';
import { Icon, Text } from 'components/design/next';
import { TasksTableUtils } from 'pages/tasks/components/tasks-table/tasks-table-utils';
import TasksTableGroupBy = TasksTableUtils.TasksTableGroupBy;

interface OptionData {
  value: TasksTableGroupBy;
  valueContainerLabel?: string;
  label: string;
}

const selectOptions: OptionData[] = [
  {
    value: TasksTableGroupBy.Ungrouped,
    label: 'None',
    valueContainerLabel: 'Group by',
  },
  {
    value: TasksTableGroupBy.DueDate,
    label: 'Due date',
    valueContainerLabel: 'Group by: Due date',
  },
];

const workflowRunOption: OptionData = {
  value: TasksTableGroupBy.WorkflowRun,
  label: 'Workflow run',
  valueContainerLabel: 'Group by: Workflow run',
};

export interface GroupingSelectorProps {
  groupBy?: TasksTableGroupBy;
  isDisabled?: boolean;
  onChange: (value: TasksTableGroupBy) => void;
}

export const GroupingSelector = (props: GroupingSelectorProps) => {
  const styles = useInboxDropdownStyles<OptionData, false>();

  const options = [...selectOptions, workflowRunOption];
  const groupedOptions = [
    {
      label: 'Group by',
      options,
    },
  ];

  return (
    <BlvdSelect<OptionData, false>
      options={groupedOptions}
      menuControls={true}
      isDisabled={props.isDisabled}
      components={COMPONENTS}
      styles={styles}
      value={options.filter(v => props.groupBy === v.value)}
      onChange={value => value && props.onChange(value.value)}
    />
  );
};

const COMPONENTS: SelectComponentsConfig<OptionData, false, GroupBase<OptionData>> = {
  Option: props => {
    return (
      <components.Option {...props}>
        <HStack px={2} py={1}>
          <Center flexShrink={0} w={5} h={5}>
            {props.isSelected && <Icon icon="check" variant="far" color="gray.500" size="4" mr={1} />}
          </Center>
          <Text color="gray.700">{props.data.label}</Text>
        </HStack>
      </components.Option>
    );
  },
  SingleValue: props => {
    const value = props.getValue();
    const [selected] = value;
    return (
      <components.SingleValue {...props}>
        <HStack spacing={1.5}>
          <Icon icon="layer-group" variant="far" color={props.isDisabled ? 'gray.300' : 'gray.500'} size="3.5" />
          <Text>{selected.valueContainerLabel ?? selected.label}</Text>
        </HStack>
      </components.SingleValue>
    );
  },
  GroupHeading: components.GroupHeading,
  DropdownIndicator,
};
