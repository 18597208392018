import { TaskTemplate } from '@process-street/subgrade/process';
import { StateService } from '@uirouter/angularjs';
import angular from 'angular';
import templateUrl from './approval-rules-container.component.html';
import './approval-rules-container.component.scss';

export enum ApprovalRulesContainerMode {
  Edit,
  View,
}

interface EditableChanges {
  currentValue: boolean;
  previousValue: boolean;
}

export class ApprovalRulesContainerController {
  public editable = false;
  public mode = ApprovalRulesContainerMode.View;

  static $inject = ['$state'];

  constructor(private $state: StateService) {}

  public $onChanges(changes: { editable?: EditableChanges }) {
    const { editable } = changes;
    if (editable && editable.currentValue) {
      this.editable = editable.currentValue;
    }
  }

  public isEdit(mode: ApprovalRulesContainerMode) {
    return mode === ApprovalRulesContainerMode.Edit;
  }

  public switchToEdit() {
    if (this.editable) {
      this.mode = ApprovalRulesContainerMode.Edit;
    }
  }

  public switchToView() {
    this.mode = ApprovalRulesContainerMode.View;
  }

  public redirectToTaskTemplate(taskTemplate: TaskTemplate) {
    if (this.editable) {
      this.$state.go('template.task', { groupId: taskTemplate.group.id }, { location: true });
    } else {
      this.$state.go('templateView.task', { groupId: taskTemplate.group.id }, { location: true });
    }
  }
}

export const ApprovalRulesContainerComponent: angular.IComponentOptions = {
  bindings: {
    editable: '<',
    taskTemplate: '<',
    templateRevision: '<',
  },
  controller: ApprovalRulesContainerController,
  templateUrl,
};
