import { ICellRendererParams } from '@ag-grid-community/core';
import { Box } from '@chakra-ui/react';
import { ProgressStatus } from '@process-street/subgrade/dashboard';
import { GridHelper } from 'components/dashboard/services/grid-helper';
import { BlvdPill, PillSize, PillStyle } from 'components/design/BlvdPill';
import * as React from 'react';

interface StatusRendererProps extends Partial<Partial<ICellRendererParams>> {
  value?: ProgressStatus;
}

const StatusSkeleton = ({ rowIndex }: { rowIndex: number }) => {
  const sizes = ['20', '22', '24', '18'];

  return <Box w={sizes[rowIndex % sizes.length]} h="5" bgColor="gray.200" borderRadius="full" />;
};

export const StatusRenderer: React.FC<React.PropsWithChildren<StatusRendererProps>> = ({ value, data, rowIndex }) => {
  if (!value && GridHelper.isEmptyStateRow(data)) return <StatusSkeleton rowIndex={rowIndex ?? 0} />;

  if (!value) {
    return <div />;
  }

  return <BlvdPill status={value} pillStyle={PillStyle.Solid} size={PillSize.Small} />;
};
