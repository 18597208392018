import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  useToast,
} from 'components/design/next';

import { GetCommentsByChecklistRevisionIdQuery, useDeleteCommentMutation } from 'features/comments/query-builder';
import { CommentEvent } from 'services/events/comment-event';
import { Checklist, Comment, Task, Template } from '@process-street/subgrade/process';
import { HttpStatus } from '@process-street/subgrade/util';
import { GetAllActivitiesByTypeQuery } from 'features/activities/query-builder';
import { ActivityObjectType } from '@process-street/subgrade/activity';
import { useQueryClient } from 'react-query';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventEmitterService } from '@process-street/subgrade/util/event-emitter-service';

type DeleteAlertDialogProps = {
  commentId: Comment['id'];
  task: Task;
  checklistId: Checklist['id'];
  templateId: Template['id'];
} & Omit<AlertDialogProps, 'children' | 'leastDestructiveRef'>;

export const DeleteCommentDialog: React.FC<React.PropsWithChildren<DeleteAlertDialogProps>> = ({
  commentId,
  task,
  checklistId,
  templateId,
  onClose,
  isOpen,
  ...props
}) => {
  const queryClient = useQueryClient();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const toast = useToast();

  const deleteCommentMutation = useDeleteCommentMutation({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Comment deleted',
      });

      EventEmitterService.broadcast(CommentEvent.COMMENT_DELETE_OK, task.id, checklistId);

      queryClient.invalidateQueries(
        GetCommentsByChecklistRevisionIdQuery.getKey({ checklistRevisionId: task.checklistRevision.id, templateId }),
      );
      queryClient.invalidateQueries(
        GetAllActivitiesByTypeQuery.getKey({ checklistId, type: 'checklist', objectType: ActivityObjectType.Comment }),
      );
    },
    onError: e => {
      const description =
        e.response?.status === HttpStatus.CONFLICT
          ? `We couldn't delete your comment because the workflow run has been updated`
          : DefaultErrorMessages.unexpectedErrorDescription;

      EventEmitterService.broadcast(CommentEvent.COMMENT_DELETE_FAILED, task.id, checklistId);

      toast({
        status: 'error',
        title: "We're having problems deleting the comment",
        description,
      });
    },
  });
  const handleDeleteComment = () => {
    deleteCommentMutation.mutate({ commentId });
  };

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} {...props} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Comment
          </AlertDialogHeader>

          <AlertDialogBody>This comment will be deleted and irrecoverable!</AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="secondary" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              aria-label="delete comment"
              variant="danger"
              onClick={() => {
                handleDeleteComment();
                onClose();
              }}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
