import classNames from 'classnames';
import React from 'react';
import styles from './OrganizationSpecificSsoPage.module.scss';

interface OrganizationSpecificSsoPageProps {
  onLogin: () => void;
  onLoginWithoutSso: () => void;
}

const handleLoginWithoutSso = (onLoginWithoutSso: () => void) => () => onLoginWithoutSso();

const renderFooter = (onLoginWithoutSso: () => void) => {
  return (
    <div className="menu">
      <p>
        Don't have SSO?
        <a onClick={handleLoginWithoutSso(onLoginWithoutSso)}>&nbsp;Login to Process&nbsp;Street without SSO.</a>
      </p>
      <div className="links">
        <a data-ps-web-href="">About</a>&nbsp;•&nbsp;
        <a data-ps-web-href="blog">Blog</a>&nbsp;•&nbsp;
        <a data-ps-web-href="terms">Terms</a>&nbsp;•&nbsp;
        <a data-ps-web-href="privacy">Privacy</a>
      </div>
    </div>
  );
};

const handleLogin = (onLogin: () => void) => () => onLogin();

const btnSignInClassName = classNames({
  'btn btn-lg btn-primary btn-padded login-btn': true,
  [styles.organizationSpecificSsoPageButton]: true,
});

const renderSignInButton = (onLogin: () => void) => {
  return (
    <button className={btnSignInClassName} onClick={handleLogin(onLogin)}>
      <span>Sign-in</span>
    </button>
  );
};

export const OrganizationSpecificSsoPage: React.FunctionComponent<
  React.PropsWithChildren<OrganizationSpecificSsoPageProps>
> = ({ onLogin, onLoginWithoutSso }) => {
  return (
    <div className="container simple-page-container auth">
      <div className="row">
        <div className="col-sm-offset-3 col-sm-6 side email-password-side">
          <h2>Sign-in with SSO</h2>

          {renderSignInButton(onLogin)}
        </div>
      </div>

      {renderFooter(onLoginWithoutSso)}
    </div>
  );
};
