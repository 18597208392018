import { StateDeclaration } from '@uirouter/core';
import { useInjector } from 'components/injection-provider';
import { useState } from 'react';
import { useMount } from 'react-use';

/**
 * Sometimes $state.current.data is not synced into the $state object, when using $injector.
 * This hook keeps $state.current.data in sync via state change events.
 */
export const useCurrentStateData = () => {
  const { $state, $rootScope } = useInjector('$state', '$rootScope');
  const [data, setData] = useState<Record<string, unknown> | undefined>($state.current.data);

  useMount(() => {
    $rootScope.$on('$stateChangeSuccess', (_event, toState: Pick<StateDeclaration, 'data'>) => {
      setData(toState.data);
    });
  });

  return data;
};
