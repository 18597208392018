import { dayjs as moment, HttpStatus } from '@process-street/subgrade/util';
import templateUrl from './checklist-button.component.html';
import './checklist-button.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { DynamicDueDateLabelService } from 'components/dynamic-due-dates/services/dynamic-due-date-label.service';
import { ResolvedMergeTagsByChecklistRevisionIdQuery } from 'features/merge-tags/query-builder';
import { queryClient } from 'components/react-root';
import { TaskQuery } from 'features/task/query-builder';

export const ChecklistButtonComponent = {
  bindings: {
    user: '<',
    task: '<',
    disabled: '<',
    activeTaskRule: '<',
    taskTemplates: '<',
    widgetsMap: '<',
    onSave: '&',
    onRemove: '&',
  },
  templateUrl,
  controller: class {
    constructor($rootScope, TaskService, ToastService) {
      'ngInject';

      this.$rootScope = $rootScope;

      this.DynamicDueDateLabelService = DynamicDueDateLabelService;
      this.TaskService = TaskService;
      this.ToastService = ToastService;
      this.label = 'Due';
      this.overrideDescription = '';
      this.dateWidgets = [];
    }

    $onChanges(changes) {
      if (changes.activeTaskRule && changes.activeTaskRule.currentValue) {
        this.updateDueLabel();
      }

      if (changes.widgetsMap && changes.widgetsMap.currentValue) {
        this.updateDateWidgets();
        this.updateDueLabel();
      }

      if (changes.task && changes.task.currentValue) {
        this.updateDueLabel();
      }
    }

    updateDateWidgets() {
      this.dateWidgets = Object.values(this.widgetsMap).reduce((a, b) => a.concat(b), []);
    }

    updateDueLabel() {
      if (this.task && this.task.dueDateOverridden && !this.task.dueDate) {
        this.label = 'Due';
      } else {
        this.label = this.DynamicDueDateLabelService.getFullLabelByRule(
          this.activeTaskRule,
          this.taskTemplates,
          this.dateWidgets,
        );

        this.overrideDescription = this.DynamicDueDateLabelService.getModalLabelByRule(
          this.activeTaskRule,
          this.taskTemplates,
          this.dateWidgets,
        );
      }
    }

    isOverrideVisible() {
      return this.activeTaskRule && !this.task.dueDateOverridden;
    }

    saveDueDate(dueDate) {
      this.$rootScope.$broadcast(EventName.TASK_DUE_DATE_UPDATE_STARTED);

      const originalDueDate = this.task.dueDate;
      this.task.dueDate = dueDate;

      this.TaskService.updateDueDate(this.task.id, dueDate, !!this.activeTaskRule).then(
        async result => {
          const data = {
            originalTask: this.task,
            updatedTask: {
              id: result.id,
              dueDate: result.dueDate,
              dueDateOverridden: result.dueDateOverridden,
            },
            updatedDueDate: this.task.dueDate,
            originalDueDate,
          };

          this.$rootScope.$broadcast(EventName.TASK_DUE_DATE_UPDATE_OK, data);

          await queryClient.invalidateQueries(TaskQuery.getKey({ taskId: this.task.id }));
          await queryClient.invalidateQueries(ResolvedMergeTagsByChecklistRevisionIdQuery.key);

          this.onSave({ dueDate: this.task.dueDate });
        },
        response => {
          this.task.dueDate = originalDueDate;

          switch (response.status) {
            case HttpStatus.BAD_REQUEST:
              this.ToastService.openToast({
                status: 'warning',
                title: `We couldn't update the due date`,
                description: 'The due date must be in the future.',
              });
              break;
            default:
              this.ToastService.openToast({
                status: 'error',
                title: `We're having problems updating the due date`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
          }

          this.$rootScope.$broadcast(EventName.TASK_DUE_DATE_UPDATE_FAILED);
        },
      );
    }

    removeDueDate() {
      this.$rootScope.$broadcast(EventName.TASK_DUE_DATE_UPDATE_STARTED);

      const originalDueDate = this.task.dueDate;
      delete this.task.dueDate;

      this.TaskService.updateDueDate(this.task.id, null).then(
        result => {
          const data = {
            originalTask: this.task,
            updatedTask: {
              id: result.id,
              dueDate: result.dueDate,
              dueDateOverridden: result.dueDateOverridden,
            },
            updatedDueDate: null,
            originalDueDate,
          };

          this.$rootScope.$broadcast(EventName.TASK_DUE_DATE_UPDATE_OK, data);

          this.task = result;
          this.updateDueLabel();

          this.onRemove({});
        },
        () => {
          this.task.dueDate = originalDueDate;

          this.ToastService.openToast({
            status: 'error',
            title: "We're having problems removing the due date.",
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });

          this.$rootScope.$broadcast(EventName.TASK_DUE_DATE_UPDATE_FAILED);
        },
      );
    }

    formatInTimeZone(timestamp, tz) {
      const mnt = tz ? moment(timestamp).tz(tz) : moment(timestamp);
      return mnt.format('ddd MMM D [at] h:mmA');
    }
  },
};
