import { ConditionEvaluator, Runnable } from '@process-street/subgrade/conditional-logic';
import { ChecklistState } from '@process-street/subgrade/process';
import { trace } from 'components/trace';
import { match } from 'ts-pattern';

const logger = trace({ name: 'TaskVisibilityRule' });

export const TaskVisibilityRule = {
  /**
   * Executes the rule
   *
   * @param rule All the data related to the current rule and it's definitions
   * @param checklistState An object that represents a state
   */
  execute: <Operator extends Runnable.ChecklistRuleDefinitionOperator>(
    rule: Runnable.TaskVisibilityRule<Operator>,
    checklistState: ChecklistState,
  ) => {
    let state = checklistState;

    const isTrue = match(rule)
      .when(Runnable.isTaskVisibilityRuleForTask, r => r.condition.evaluate(r.task, r.operand))
      .when(Runnable.isTaskVisibilityRuleForFormField, rule => {
        return (rule.condition as ConditionEvaluator).evaluate(rule.formFieldValue, rule.formFieldWidget, rule.operand);
      })
      .otherwise(() => rule.operand);

    if (isTrue) {
      logger.info('rule evaluated to true ', rule);
      state = apply(rule, state);
    }

    return state;
  },
};

/**
 * This function is executes in case that the condition evaluates to true
 * This is the only function that must be implemented
 *
 * @param rule All the data related to the current rule and it's definitions
 * @param checklistState An object that represents a state
 * @return
 */
function apply<Operator extends Runnable.ChecklistRuleDefinitionOperator>(
  rule: Runnable.TaskVisibilityRule<Operator>,
  checklistState: ChecklistState,
) {
  checklistState.taskStates.forEach(taskState => {
    if (rule.targetTaskTemplateGroupIds.includes(taskState.taskTemplateGroupId)) {
      taskState.task.hidden = rule.hidden;
      taskState.updated = true;
    }
  });

  checklistState.checklistWidgetStates.forEach(widgetState => {
    if (rule.targetWidgetGroupIds.includes(widgetState.widgetGroupId)) {
      widgetState.checklistWidget.hidden = rule.hidden;
      widgetState.updated = true;
    }
  });

  return checklistState;
}
