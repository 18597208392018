import { SolutionTypeTag, AutomationAppName, AutomationAppType } from '@process-street/subgrade/automation';
import { useInjector } from 'components/injection-provider';
import { useEffectOnce } from 'react-use';
import { AutomationsModalEvent } from './automations-modal-event';
import { useAutomationSelector } from './components/selector/context';

export function useRootScopeStateMachineEvents() {
  const { $rootScope } = useInjector('$rootScope');

  const [, send] = useAutomationSelector();

  useEffectOnce(() => {
    const unsubs = [
      $rootScope.$on(
        AutomationsModalEvent.OPEN_AUTOMATION_INSTANCE,
        (_event, { id, automationType, modalView, solutionTypeTag }) => {
          if (!id || !(['tray', 'webhook', 'native'] as AutomationAppType[]).includes(automationType)) {
            throw new Error(`Invalid automation instance id or type: ${id}, ${automationType}`);
          }
          send({
            type: 'AUTOMATION_SELECTED',
            payload: { id, automationType, modalView, solutionTypeTag },
          });
        },
      ),

      $rootScope.$on(
        AutomationsModalEvent.OPEN_SOLUTION_TYPE_TAG,
        (_event, solutionTypeTag: SolutionTypeTag, automationAppName: AutomationAppName) => {
          send({
            type: 'SOLUTION_TYPE_SELECTED',
            payload: { solutionTypeTag, automationAppName },
          });
        },
      ),

      $rootScope.$on(AutomationsModalEvent.OPEN_TASK_AUTOMATIONS, _event => {
        send({
          type: 'SOLUTION_TYPE_SELECTED',
          payload: { solutionTypeTag: SolutionTypeTag.WhenTaskCheckedThen, modalView: 'task' },
        });
      }),
    ];

    return () => {
      unsubs.forEach(unsub => unsub());
    };
  });
}
