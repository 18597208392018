import { noop } from '@process-street/subgrade/util';
import React, { FocusEvent } from 'react';
import { GroupBase, InputProps } from 'react-select';

/**
 * Hidden input which represents the select.  It's used to track
 * focus and ensure that you can tab to the select input etc.
 */
export const Input = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: InputProps<Option, IsMulti, Group>,
) => {
  const { id, isDisabled, innerRef, onBlur, onFocus, tabIndex } = props;

  /**
   * Guard the normal onblur function to ensure we don't close the
   * select if the search input is focused.
   */
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (!e.relatedTarget || (e.relatedTarget && e.relatedTarget.id !== 'blvd-select__menu__header__search-input')) {
      onBlur?.(e);
    }
  };

  const ariaAttributes = {
    'aria-autocomplete': 'list' as const,
    'aria-label': props['aria-label'],
    'aria-labelledby': props['aria-labelledby'],
  };

  return (
    <input
      id={id}
      ref={innerRef}
      className={'blvd-select__dummy-input'}
      value=""
      readOnly={true}
      disabled={isDisabled}
      tabIndex={tabIndex}
      onBlur={handleBlur}
      onChange={noop}
      onFocus={onFocus}
      {...ariaAttributes}
    />
  );
};
