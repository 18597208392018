import { Identifiable, Option } from '@process-street/subgrade/core';
import { EntityMap } from '@process-street/subgrade/redux/types';
import {
  asCreateEvent,
  asUpdateEvent,
  OptimisticCreateEvent,
  OptimisticDeleteEvent,
  OptimisticDiff,
  OptimisticEvent,
  OptimisticEventType,
  OptimisticUpdateEvent,
} from '../model';

/**
 * Merges existing create event with another optimistic event
 * 1. If another event is create, we are using latest create
 * 2. If another event is update, we are applying changes to create, and keeping create event
 * 3. If another event is delete, we ignore create and merged event is empty
 */
function mergeToCreateEvent<T extends Identifiable>(
  createEvent: OptimisticCreateEvent<T>,
  nextEvent: OptimisticEvent<T>,
): Option<OptimisticEvent<T>> {
  switch (nextEvent.eventType) {
    case OptimisticEventType.Create:
      return nextEvent;
    case OptimisticEventType.Update:
      const updatedEntity: T = { ...createEvent.entity, ...nextEvent.entity };
      return asCreateEvent(updatedEntity);
    default:
      return undefined;
  }
}

/**
 * Merges existing update event with another optimistic event
 * 1. If another event is create, it is merged with update event
 * 2. If another event is update, updates merged with latest one taking priority over original one
 * 3. If another event is delete, we don't need to apply update and need only delete event
 */
function mergeToUpdateEvent<T extends Identifiable>(
  updateEvent: OptimisticUpdateEvent<T>,
  nextEvent: OptimisticEvent<T>,
): OptimisticEvent<T> {
  switch (nextEvent.eventType) {
    case OptimisticEventType.Create:
      const updatedEntity: T = { ...nextEvent.entity, ...updateEvent.entity };
      return asCreateEvent(updatedEntity);
    case OptimisticEventType.Update:
      const mergedUpdate: OptimisticDiff<T> = { ...updateEvent.entity, ...nextEvent.entity };
      return asUpdateEvent(mergedUpdate);
    case OptimisticEventType.Delete:
    default:
      return nextEvent;
  }
}

/**
 * Merges existing delete devent with another optimistic event
 * 1. If another event is create, it overrides delete
 * 2. In all other cases, we ignore events
 */
function mergeToDeleteEvent<T extends Identifiable>(
  deleteEvent: OptimisticDeleteEvent<T>,
  nextEvent: OptimisticEvent<T>,
): OptimisticEvent<T> {
  switch (nextEvent.eventType) {
    case OptimisticEventType.Create:
      return nextEvent;
    default:
      return deleteEvent;
  }
}

/**
 * Merges pending optimistic event with another one for the same entity, forwarding to appropriate functions
 */
function mergeToPendingEvent<T extends Identifiable>(
  pendingEvent: Option<OptimisticEvent<T>>,
  nextEvent: OptimisticEvent<T>,
): Option<OptimisticEvent<T>> {
  if (!pendingEvent) {
    return nextEvent;
  }
  switch (pendingEvent.eventType) {
    case OptimisticEventType.Create:
      return mergeToCreateEvent(pendingEvent, nextEvent);
    case OptimisticEventType.Update:
      return mergeToUpdateEvent(pendingEvent, nextEvent);
    case OptimisticEventType.Delete:
    default:
      return mergeToDeleteEvent(pendingEvent, nextEvent);
  }
}

/**
 * Merges all changes to related entity to a collection without duplicate
 */
export function reduceOptimisticEventsByEntityId<T extends Identifiable>(
  events: Array<OptimisticEvent<T>>,
): Array<OptimisticEvent<T>> {
  const mergedEventsById = events.reduce((agg: EntityMap<OptimisticEvent<T>>, nextEvent: OptimisticEvent<T>) => {
    const { id } = nextEvent.entity;
    const mergedEvent = mergeToPendingEvent(agg[id], nextEvent);
    if (mergedEvent) {
      // @ts-expect-error -- TODO
      agg[id] = mergedEvent;
    } else {
      delete agg[id];
    }
    return agg;
  }, {});
  return Object.values(mergedEventsById);
}
