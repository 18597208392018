import { Muid, Option } from '@process-street/subgrade/core';
import { TaskTemplate } from '@process-street/subgrade/process';
import { BaseTaskTemplateSelector } from '@process-street/subgrade/redux/selector/task-template.selectors';
import { orderTreeService } from '@process-street/subgrade/core';
import { createSelector, Selector } from 'reselect';
import { ReduxAppState, Status, TaskTemplateLookups, TaskTemplateStatuses } from '../types';

const getStatuses = (state: ReduxAppState): TaskTemplateStatuses => state.statuses.taskTemplates;

const sortTaskTemplates = (taskTemplates: TaskTemplate[]) =>
  taskTemplates.sort((a, b) => orderTreeService.compare(a.orderTree, b.orderTree));

const getAllLookup = (state: ReduxAppState): TaskTemplateLookups => state.lookups.taskTemplates;

const getAllLookupByTemplateRevisionId =
  (templateRevisionId: Muid) =>
  (state: ReduxAppState): Muid[] =>
    getAllLookup(state).byTemplateRevisionId[templateRevisionId] || [];

const getAllByTemplateRevisionId = (templateRevisionId: Muid): Selector<ReduxAppState, TaskTemplate[]> =>
  createSelector(
    [getAllLookupByTemplateRevisionId(templateRevisionId), BaseTaskTemplateSelector.getEntityMap],
    (ids, taskTemplates) =>
      sortTaskTemplates((ids || []).map(id => taskTemplates[id]).filter(taskTemplate => taskTemplate) || []),
  );

const getIdsByChecklistRevisionId =
  (checklistRevisionId: Muid) =>
  (state: ReduxAppState): Muid[] =>
    getAllLookup(state).byChecklistRevisionId[checklistRevisionId] || [];

const getAllByChecklistRevisionId = (checklistRevisionId: Muid): Selector<ReduxAppState, TaskTemplate[]> =>
  createSelector(
    [getIdsByChecklistRevisionId(checklistRevisionId), BaseTaskTemplateSelector.getEntityMap],
    (ids, taskTemplates) => sortTaskTemplates((ids || []).map(id => taskTemplates[id]) || []),
  );

const getStatusByTemplateRevisionId = (templateRevisionId: Muid): Selector<ReduxAppState, Option<Status>> =>
  createSelector(getStatuses, status => status.byTemplateRevisionId[templateRevisionId]);

const getLoadedStatusByTemplateRevisionId = (templateRevisionId: Muid): Selector<ReduxAppState, boolean> =>
  createSelector(getStatusByTemplateRevisionId(templateRevisionId), (status: Option<Status>) => {
    if (!status) {
      return false;
    }
    return status.loaded;
  });

export const TaskTemplateSelector = {
  getAllByChecklistRevisionId,
  getAllByTemplateRevisionId,
  getAllLookupByTemplateRevisionId,
  getById: BaseTaskTemplateSelector.getById,
  getEntityMap: BaseTaskTemplateSelector.getEntityMap,
  getLoadedStatusByTemplateRevisionId,
};
