import * as React from 'react';
import { SendRichEmailFormFieldWidget, TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { View } from './view';
import { Edit } from './edit';

export type SendRichEmailTemplateWidgetProps = {
  widget: SendRichEmailFormFieldWidget;
  isDisabled: boolean;
  onUpdate: (options: { widget: SendRichEmailFormFieldWidget }) => void;
  templateRevision: TemplateRevision;
  taskTemplate?: TaskTemplate;
};

// TODO data fetching
// data-merge-tags-enabled="$ctrl.mergeTagsEnabled" via canAccess
// $scope.mergeTagsEnabled = canAccess(Feature.MERGE_TAGS, planId);
// organization via template revision
export const SendRichEmailTemplateWidget: React.FC<
  React.PropsWithChildren<SendRichEmailTemplateWidgetProps>
> = props => {
  return props.isDisabled ? <View {...props} /> : <Edit {...props} />;
};
