import angular from 'angular';
import { isPrivateRootFolder } from 'features/folder/lib';
import templateUrl from './editor-breadcrumb.component.html';
import './editor-breadcrumb.scss';

angular.module('frontStreetApp.directives').component('psEditorBreadcrumb', {
  bindings: {
    mode: '<',
    template: '<',
  },
  templateUrl,
  controller(FolderService, SegmentService) {
    const ctrl = this;

    ctrl.$onInit = function () {
      initializeSegments(ctrl.mode, ctrl.template);
    };

    ctrl.$onChanges = function (changes) {
      initializeSegments(ctrl.mode, changes.template.currentValue);
    };

    ctrl.showBreadcrumbByIndexAndType = function (index, type) {
      const { segmentParts } = ctrl;
      const segmentPartIsType = segmentParts.visible && segmentParts.visible[index].data.action === type;
      return segmentPartIsType && (index > 0 || !segmentParts.hidden);
    };

    /**
     * Iterates segments, setting up their data.
     *
     * @param {Object[]} segments
     * @param {Number} index
     * @param callback - This is called on the last iteration
     */
    ctrl.iterateSegments = function (segments, index, callback) {
      if (segments.length >= index + 1) {
        segments[index] = {
          type: 'folder',
          model: angular.copy(segments[index]),
        };
        FolderService.toSlugPathAsync(segments[index].model).then(path => {
          segments[index].data = {
            action: 'dashboard.type',
            params: { type: 'folder', path },
          };
          ctrl.iterateSegments(segments, index + 1, callback);
        });
      } else {
        callback();
      }
    };

    function initializeSegments(mode, template) {
      if (!template || !template.folder || !template.folder.id) {
        return;
      }

      FolderService.getAndAncestorsById(template.folder.id).then(folders => {
        const segments = folders.reverse();
        ctrl.iterateSegments(segments, 0, () => {
          const isPrivateRoot = isPrivateRootFolder(segments[0].model);
          segments[0].model.name = isPrivateRoot ? 'Private Library' : 'Library';
          segments[0].model.icon = isPrivateRoot ? 'far fa-user-lock' : 'far fa-home';

          if (mode === 'checklist') {
            // In checklist mode, we also want the template
            segments.push({
              type: 'template',
              model: template,
              data: { action: 'templateDashboard', params: { id: template.id } },
            });
          }

          ctrl.segmentParts = SegmentService.calculateSegmentParts(segments);
        });
      });
    }
  },
});
