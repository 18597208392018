import { Icon, IconButton } from 'components/design/next';
import React from 'react';
import { useDesignDrawerButton } from './use-design-drawer-button';

export const DesignDrawerButton = () => {
  const buttonProps = useDesignDrawerButton();
  return (
    <IconButton
      aria-label="Settings"
      variant="tertiary"
      icon={<Icon icon="cog" variant="far" size="4" color="gray.500" />}
      {...buttonProps}
    />
  );
};
