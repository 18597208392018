import angular from 'angular';
import { isAdmin, Organization } from '@process-street/subgrade/core';
import { SubscriptionUtils } from '@process-street/subgrade/util/subscription-utils';
import { RawParams, StateDeclaration, StateService } from '@uirouter/angularjs';
import { IWindowService } from 'angular';
import { FileUploadService } from 'components/file-upload/services/file-upload.service';
import { RouteService } from 'components/routing/route-service';
import { trace, Trace } from 'components/trace';
import { AuthService } from 'services/auth.interface';
import { DataService } from 'services/data-service.interface';
import { OrganizationService } from 'services/organization-service.interface';
import { SessionService } from 'services/session-service.interface';
import { isT5KPlan } from 'services/plans';

export class RouteServiceImpl implements RouteService {
  public static $inject = [
    '$location',
    '$state',
    '$timeout',
    '$window',
    'auth',
    'DataService',
    'FileUploadService',
    'OrganizationService',
    'SessionService',
  ];

  private readonly logger: Trace;

  constructor(
    private $location: angular.ILocationService,
    private $state: StateService,
    private $timeout: angular.ITimeoutService,
    private $window: IWindowService,
    private auth: AuthService,
    private dataService: DataService,
    private fileUploadService: FileUploadService,
    private organizationService: OrganizationService,
    private sessionService: SessionService,
  ) {
    this.logger = trace({ name: 'RouteServiceImpl' });
  }

  public validateRoute(event: angular.IAngularEvent, toState: StateDeclaration, toParams: RawParams): void {
    // File upload validation
    const showDialogForChecklist = toState.name !== 'checklist.task' && this.fileUploadService.hasChecklistUploads();
    const showDialogForTemplate = toState.name !== 'template.task' && this.fileUploadService.hasTemplateUploads();

    if (showDialogForChecklist || showDialogForTemplate) {
      if (!this.$window.confirm('File upload is in progress and it will be stopped. Do you want to proceed?')) {
        event.preventDefault();
        return;
      }
    }

    if (toState.data.skipLoader) {
      this.logger.info('Skipping the magic loader, everything will be dealt with inside controller.');

      if (!toState.data.skipLoginCheck && !this.auth.isLoggedIn()) {
        this.logger.info('logged out and accessing route "%s", re-routing to "login"', toState.name);

        event.preventDefault();
        this.$state.go('login', { url: this.$state.href(toState.name!, toParams) });
      }

      if (this.auth.isLoggedIn() && this.auth.needsToCompleteSignUp() && toState.name !== 'completeSignUp.user') {
        event.preventDefault();
        this.$state.go('completeSignUp.user', { email: this.sessionService.getProfile().email });
      } else if (this.auth.isLoggedIn() && !toState.data.allowCanceled) {
        this.organizationService.getSelectedOrganization().then((selectedOrganization: Organization) => {
          const { status } = selectedOrganization.subscription;
          const subscriptionIsCancelled = SubscriptionUtils.isCancelled(status);
          if (subscriptionIsCancelled) {
            event.preventDefault();
            const userInfo = this.sessionService.getUserInfo();
            const showTrialExpiredPaywall = isT5KPlan(selectedOrganization.subscription.plan.id);
            if (!!userInfo && isAdmin(userInfo.organizationMembership.role)) {
              this.$state.go('organizationManage.tab', { tab: 'billing', showTrialExpiredPaywall }, { reload: true });
            } else {
              this.$state.go('organizationManage.tab', { tab: 'users', showTrialExpiredPaywall }, { reload: true });
            }
          }
        });
      }
    } else if (this.auth.isLoggedIn()) {
      // Logged in

      if (this.auth.needsToCompleteSignUp() && toState.name !== 'completeSignUp.user') {
        event.preventDefault();
        this.$state.go('completeSignUp.user', { email: this.sessionService.getProfile().email });
      } else if (!toState.data.internal) {
        this.logger.info('logged in and accessing external route "%s", re-routing to "reports"', toState.name);

        event.preventDefault();
        this.$state.go('reports');
      } else if (toState.name === 'loader') {
        this.logger.info('logged in and allowing "loader" through');

        // Always allow loader through
      } else if (!toState.data.external) {
        if (this.dataService.isInitialized()) {
          if (
            this.sessionService.getSelectedOrganization() &&
            this.sessionService.isCanceled() &&
            !toState.data.allowCanceled
          ) {
            event.preventDefault();
            this.$state.go('organizationManage.tab', { tab: 'billing' }, { reload: true });
          }
        } else {
          this.logger.info('logged in and not initialized, re-routing to "loader"');

          event.preventDefault();

          // This is so hack, but needed for now
          // We need to fix this up soon
          let url;
          if (toState.name === 'checklistRun' || toState.name!.startsWith('checklistRunRedirect')) {
            url = this.$location.url();
          } else {
            url = this.$state.href(toState.name!, toParams);
          }

          this.$state.go('loader', { url });
        }
      }

      if (
        this.dataService.isInitialized() &&
        !this.sessionService.getSelectedOrganization() &&
        toState.name !== 'otherwise' &&
        toState.name !== 'switchOrganization'
      ) {
        if (toState.name !== 'userManage.tab' && toState.name !== 'logout') {
          event.preventDefault();

          this.$timeout(() => {
            this.$state.go('userManage.tab', { id: 'me', tab: 'organizations' });
          });
        }
      }
    } else if (!toState.data.external) {
      this.logger.info('logged out and accessing internal route "%s", re-routing to "login"', toState.name);

      event.preventDefault();
      this.$state.go('login', { url: this.$state.href(toState.name!, toParams) });
    }
  }

  public onStateChangeStart(event: angular.IAngularEvent, toState: StateDeclaration, toParams: RawParams) {
    // see https://github.com/angular-ui/ui-router/issues/1240
    if (toState.name === 'otherwise') {
      return;
    }
    this.validateRoute(event, toState, toParams);
  }
}
