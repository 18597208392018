import { DateFieldValue, FieldValueJson } from '@process-street/subgrade/process';
import { DateFieldUtils } from '@process-street/subgrade/util';
import angular, { IComponentController } from 'angular';
import templateUrl from './date-renderer.component.html';
import { SessionService as ISessionService } from 'services/session-service.interface';
import { DateContext, DateContextUtils } from '@process-street/subgrade/core/date-context';

export interface ApprovalSubjectTaskWidgetDateRendererController extends IComponentController {
  getValue(fieldValue: FieldValueJson): string | null;
  getTitle(fieldValue: FieldValueJson): string | null;
}

export const ApprovalSubjectTaskWidgetDateRenderer: angular.IComponentOptions = {
  bindings: {
    formFieldValue: '<',
  },
  // eslint-disable-next-line object-shorthand
  controller: function (this: ApprovalSubjectTaskWidgetDateRendererController, SessionService: ISessionService) {
    'ngInject';

    let dateContext: DateContext | null = null;

    this.$onInit = () => {
      const organization = SessionService.getSelectedOrganization();
      const user = SessionService.getUser();
      dateContext = DateContextUtils.getDateContext(organization, user);
    };

    this.getValue = function (fieldValue: FieldValueJson) {
      const dateFieldValue = fieldValue as DateFieldValue;
      if (dateFieldValue?.value && dateContext) {
        return DateFieldUtils.formatDateFieldValueToShortMonth(dateFieldValue, dateContext);
      } else {
        return null;
      }
    };

    this.getTitle = function (fieldValue: FieldValueJson) {
      const dateFieldValue = fieldValue as DateFieldValue;
      if (dateFieldValue?.value && dateContext) {
        return DateFieldUtils.formatDateFieldValueToLongMonth(dateFieldValue, dateContext);
      } else {
        return null;
      }
    };
  },
  templateUrl,
};
