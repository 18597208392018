import * as React from 'react';
import { axiosService } from 'services/axios-service';
import { InjectableServices, useInjector } from 'components/injection-provider';
import { HttpStatus } from '@process-street/subgrade/util';
import { AxiosError, AxiosInstance } from 'axios';
import { isRequestLoginOrUpdatedPassword } from 'app/interceptors/utils';

// inspired by app/interceptors/unauthorized-interceptor.js
export const makeErrorInterceptor =
  ({
    auth,
    Auth0Service,
    SessionService,
    $state,
    axios,
  }: Pick<InjectableServices, '$state' | 'Auth0Service' | 'auth' | 'SessionService'> & {
    axios: AxiosInstance;
  }) =>
  async (error: AxiosError) => {
    const tokenIsExpired = auth.isExpired();
    const userIsLoggedIn = auth.isLoggedIn();
    const currentToken = SessionService.getToken();
    const rejection = error.response;
    if (!rejection) return Promise.reject(error);

    const attemptedRequest = error.config;

    if (
      rejection.status === HttpStatus.UNAUTHORIZED &&
      userIsLoggedIn &&
      attemptedRequest &&
      attemptedRequest.url &&
      !isRequestLoginOrUpdatedPassword(attemptedRequest.url)
    ) {
      if (tokenIsExpired) {
        const { accessToken } = await Auth0Service.renewTokenLogoutOnFailure();
        attemptedRequest.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        if (currentToken && attemptedRequest.headers.Authorization !== `Bearer ${currentToken}`) {
          attemptedRequest.headers.Authorization = `Bearer ${currentToken}`;
        } else {
          await $state.go('logout');
          return Promise.resolve();
        }
      }
      return axios(attemptedRequest);
    }

    return Promise.reject(error);
  };

export const useTokenExpirationInterceptor = () => {
  const { $state, auth, Auth0Service, SessionService } = useInjector(
    '$state',
    'auth',
    'Auth0Service',
    'SessionService',
  );
  const axios = axiosService.getAxios();

  React.useEffect(() => {
    axios.interceptors.response.use(
      response => response,
      makeErrorInterceptor({ auth, $state, Auth0Service, SessionService, axios }),
    );
  }, [$state, Auth0Service, SessionService, auth, axios]);
};
