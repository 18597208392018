import { FieldType, FormFieldWidget, Widget } from '@process-street/subgrade/process';
import { useMachine } from '@xstate/react';
import { FormControl, FormLabel, Stack, Skeleton, VStack, Text, VisuallyHidden, Show } from 'components/design/next';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { SelectInstance } from 'react-select';
import { match, P } from 'ts-pattern';
import { useAiTaskTemplateFormContext } from '../../ai-task-template-form-context';
import { AiTaskWidgetOption, AiTaskWidgetSelect } from '../ai-task-widget-select';
import { CreatableAiTaskWidgetSelect } from '../ai-task-widget-select/creatable-ai-task-widget-select';
import { makeMathAiTaskFormMachine } from './math-ai-task-form-machine';
import { MathOperatorSelect, TMathOperatorOption } from './math-operator-select/math-operator-select';

export interface MathAiTaskFormProps {}

export const MathAiTaskForm: React.FC<React.PropsWithChildren<MathAiTaskFormProps>> = () => {
  const { aiTaskTemplate, isDisabled, isLoading, setIsPristine } = useAiTaskTemplateFormContext();
  const queryClient = useQueryClient();

  const leftOperandRef = React.useRef<SelectInstance<AiTaskWidgetOption>>(null);
  const operatorRef = React.useRef<SelectInstance<TMathOperatorOption>>(null);
  const rightOperandRef = React.useRef<SelectInstance>(null);
  const outputWidgetIdRef = React.useRef<SelectInstance<AiTaskWidgetOption>>(null);

  const [state, send] = useMachine(() => makeMathAiTaskFormMachine({ queryClient, aiTaskTemplate }), {
    actions: isDisabled
      ? // don't provide these actions when disabled
        {}
      : {
          focusLeftOperand: () => {
            leftOperandRef.current?.openMenu('first');
            leftOperandRef.current?.focus();
          },
          focusOperator: () => {
            operatorRef.current?.openMenu?.('first');
          },
          focusRightOperand: () => {
            rightOperandRef.current?.openMenu('first');
            rightOperandRef.current?.focus();
          },
          focusOutputWidgetId: () => {
            outputWidgetIdRef.current?.openMenu?.('first');
          },
          markAsDirty: () => {
            setIsPristine(false);
          },
        },
  });

  return (
    <VStack spacing="3" w="full">
      <Stack direction={{ base: 'column', lg: 'row' }} w="full" spacing={{ base: '1', md: '3' }}>
        <FormControl isDisabled={isDisabled} flex="1">
          <FormLabel>
            <Text variant="-2u" fontWeight="semibold">
              Value
            </Text>
          </FormLabel>
          <Skeleton isLoaded={!isLoading}>
            <CreatableAiTaskWidgetSelect
              ref={leftOperandRef}
              placeholder="Widget or number"
              filter={isValidInputWidget}
              isValidNewOption={isValidNumber}
              isDisabled={isDisabled}
              value={state.context.leftOperand}
              onChange={option => {
                send({ type: 'LEFT_OPERAND_CHANGE', value: option.value });
              }}
            />
          </Skeleton>
        </FormControl>

        <FormControl isDisabled={isDisabled} w={{ base: 'full', lg: '38' }}>
          <FormLabel>
            <Show above="lg">
              <VisuallyHidden>Operator</VisuallyHidden>
              {/* use nbsp for vertical alignment */}
              <Text variant="-2u">&nbsp;</Text>
            </Show>
            <Show below="lg">
              <Text variant="-2u" fontWeight="semibold">
                Operator
              </Text>
            </Show>
          </FormLabel>
          <Skeleton isLoaded={!isLoading}>
            <MathOperatorSelect
              selectRef={operatorRef}
              isDisabled={isDisabled}
              value={state.context.operator}
              placeholder={'Operator'}
              onChange={option => {
                send({ type: 'OPERATOR_CHANGE', value: option.value });
              }}
            />
          </Skeleton>
        </FormControl>

        <FormControl isDisabled={isDisabled} flex="1">
          <FormLabel>
            <Text variant="-2u" fontWeight="semibold">
              Value
            </Text>
          </FormLabel>
          <Skeleton isLoaded={!isLoading}>
            <CreatableAiTaskWidgetSelect
              ref={rightOperandRef}
              filter={isValidInputWidget}
              placeholder="Widget or number"
              isDisabled={isDisabled}
              isValidNewOption={isValidNumber}
              value={state.context.rightOperand}
              onChange={option => {
                send({ type: 'RIGHT_OPERAND_CHANGE', value: option.value });
              }}
            />
          </Skeleton>
        </FormControl>
      </Stack>

      <FormControl isDisabled={isDisabled}>
        <FormLabel htmlFor="output">Output</FormLabel>
        <Skeleton isLoaded={!isLoading}>
          <AiTaskWidgetSelect
            selectRef={outputWidgetIdRef}
            id="output"
            isDisabled={isDisabled}
            value={state.context.outputWidgetId}
            onChange={option => {
              send({ type: 'OUTPUT_WIDGET_ID_CHANGE', value: option.value });
            }}
          />
        </Skeleton>
      </FormControl>
    </VStack>
  );
};

function isValidInputWidget(widget: Widget): widget is FormFieldWidget {
  return match(widget)
    .with(
      { fieldType: P.union(FieldType.Number, FieldType.Text, FieldType.Textarea, FieldType.Select, FieldType.Hidden) },
      () => true,
    )
    .otherwise(() => false);
}

function isValidNumber(value: string) {
  return value !== '' && (isNaN(Number(value)) ? false : true);
}
