import { SavedView, SavedViewAccessLevel } from 'components/dashboard/models/saved-views';
import { checklistColumns } from 'components/dashboard/models/columns';
import { ChecklistColumn, Clause, templateDashboardDefaultColumns } from '@process-street/subgrade/dashboard';
import { ChecklistColDef } from 'components/dashboard/models/grid';
import { MuidUtils, Option, Muid } from '@process-street/subgrade/core';
import { generateAudit } from '@process-street/subgrade/test';
import { GridHelper } from 'components/dashboard/services/grid-helper';

const createTemplateSavedViewConfig = (options: {
  organizationId: Option<Muid>;
  templateId: Option<Muid>;
  filter: Clause;
}): SavedView => {
  const columnDefs = templateDashboardDefaultColumns
    .map(field => checklistColumns.find(columnDef => columnDef.field === field))
    .filter((column): column is ChecklistColDef => Boolean(column));

  return {
    filters: {
      selectedTemplates: options.templateId ? [options.templateId] : [],
      searchPattern: '',
      conditionalFilter: options.filter,
    },
    name: '',
    id: MuidUtils.randomMuid(),
    accessLevel: SavedViewAccessLevel.Organization,
    audit: generateAudit(),
    columnsConfig: {
      visibleIds: templateDashboardDefaultColumns,
      orderMap: GridHelper.getOrderMap(columnDefs),
      sorting: {
        sortColumn: ChecklistColumn.LastActiveDate,
        sortAsc: false,
      },
    },
    organizationId: options.organizationId ?? '',
    predefined: false,
  };
};

export const TemplateDashboardHelpers = {
  createTemplateSavedViewConfig,
};
