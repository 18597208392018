import angular from 'angular';
import { MultiChoiceItemValueStatus } from '@process-street/subgrade/process';
import { StringUtils } from '@process-street/subgrade/util';
import { UrlUtils } from 'components/utils/url-utils';
import templateUrl from './checklist-multi-choice-field.component.html';

/**
 * Uses ui-select v 0.18.0 since it has input box in one line, the version takes two lines
 * https://github.com/angular-ui/ui-select/issues/1980
 */

angular.module('frontStreetApp.directives').component('psChecklistMultiChoiceFormField', {
  bindings: {
    user: '<',
    checklistRevision: '<',
    widget: '<',
    editable: '<',
    formFieldValue: '<',
    onUpdateValue: '&',
    onInteractionEnd: '&',
    readOnly: '<',
  },
  templateUrl,
  controller($q) {
    const ctrl = this;
    this.$q = $q;

    ctrl.$onChanges = function (changes) {
      if (
        changes.formFieldValue &&
        changes.formFieldValue.currentValue &&
        changes.formFieldValue.currentValue.fieldValue
      ) {
        const fieldValue = angular.copy(changes.formFieldValue.currentValue.fieldValue);
        const { items } = ctrl.widget.config;
        ctrl.fieldValue = ctrl.generateValueMap(items, fieldValue);
        ctrl.selectedItems = ctrl.initializeSelectedItems(items, fieldValue);
      }
    };

    ctrl.updateFormFieldValue = function (itemValues) {
      return ctrl.onUpdateValue({
        widget: ctrl.widget,
        fieldValue: {
          itemValues,
        },
      });
    };

    ctrl.onBlur = () => {
      const itemValues = ctrl.getItemValuesWithOrdering(ctrl.selectedItems, ctrl.widget);

      return ctrl.onInteractionEnd({
        widget: ctrl.widget,
        fieldValue: {
          itemValues,
        },
      });
    };

    ctrl.generateValueMap = function (items, fieldValue) {
      const valueMap = {};

      (items || []).forEach(item => {
        valueMap[item.id] = {
          id: item.id,
          status: MultiChoiceItemValueStatus.NotSelected,
        };
      });

      (fieldValue.itemValues || []).forEach(itemValue => {
        valueMap[itemValue.id] = itemValue;
      });

      return valueMap;
    };

    ctrl.initializeSelectedItems = function (items, fieldValue) {
      const itemsMap = items.reduce((object, item) => {
        object[item.id] = item;
        return object;
      }, {});

      const selectedItems = [];

      const itemIds = items.map(item => item.id);

      (fieldValue.itemValues || []).forEach(itemValue => {
        if (itemIds.indexOf(itemValue.id) >= 0 && itemValue.status === MultiChoiceItemValueStatus.Selected) {
          selectedItems.push(itemsMap[itemValue.id]);
        }
      });

      return selectedItems;
    };

    ctrl.searchItems = function (query) {
      return ctrl.widget.config.items.filter(item => StringUtils.containsIgnoreCase(item.name, query));
    };

    ctrl.selectItem = function (widget, item, checklistRevision) {
      if (checklistRevision && ctrl.editable) {
        ctrl.fieldValue[item.id].status = MultiChoiceItemValueStatus.Selected;
        updateMultiChoiceValue(widget);
      }
    };

    ctrl.removeItem = function (widget, item, checklistRevision) {
      if (checklistRevision && ctrl.editable) {
        ctrl.fieldValue[item.id].status = MultiChoiceItemValueStatus.NotSelected;
        updateMultiChoiceValue(widget);
      }
    };

    /**
     * Returns item values with ordering.
     *
     * @param selectedItems
     * @param widget
     * @returns {Array}
     */
    ctrl.getItemValuesWithOrdering = (selectedItems, widget) => {
      const itemValues = [];

      const addedItemsIds = [];
      selectedItems.forEach(item => {
        if (ctrl.fieldValue[item.id]) {
          itemValues.push(ctrl.fieldValue[item.id]);
          addedItemsIds.push(item.id);
        }
      });

      widget.config.items.forEach(item => {
        if (ctrl.fieldValue[item.id] && addedItemsIds.indexOf(item.id) === -1) {
          itemValues.push(ctrl.fieldValue[item.id]);
        }
      });

      return itemValues;
    };

    function updateMultiChoiceValue(widget) {
      const itemValues = ctrl.getItemValuesWithOrdering(ctrl.selectedItems, widget);

      ctrl.updateFormFieldValue(itemValues);
    }

    ctrl.setTargetAttribute = function (url) {
      return {
        target: UrlUtils.isAppUrl(url) ? '_self' : '_blank',
      };
    };

    ctrl.shouldShowAudit = () => Boolean(ctrl.formFieldValue?.audit);

    ctrl.getSelectedItemValues = function () {
      return (ctrl.selectedItems || []).map(item => item.name);
    };

    ctrl.isReadOnly = function () {
      return !ctrl.fieldValue || !ctrl.editable;
    };
  },
});
