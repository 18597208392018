import { Approval } from '@process-street/subgrade/approval-rule/approval.model';
import { Muid, Option, User } from '@process-street/subgrade/core';
import { BaseApprovalSelector } from '@process-street/subgrade/redux/selector';
import * as React from 'react';
import { connect } from 'react-redux';
import { ReduxAppState } from 'reducers/types';
import { UserSelector } from 'reducers/user/user.selectors';
import { Avatar } from '../../../common/Avatar';
import { DateUtils, DateFormat, dayjs as moment } from '@process-street/subgrade/util';
import './TaskApprovalInfo.scss';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';

interface StateToProps {
  approvalInfo: Option<Approval>;
  approver: Option<User>;
  isPdfEmbellishmentEnabled?: boolean;
}

interface PureTaskApprovalInfoProps {
  taskId: Muid;
}

export type TaskApprovalInfoProps = StateToProps & PureTaskApprovalInfoProps;

export const PureTaskApprovalInfo = (props: TaskApprovalInfoProps) => {
  if (!props.approvalInfo || !props.approver) return null;

  const { comment } = props.approvalInfo;

  const approvalDate = props.isPdfEmbellishmentEnabled
    ? DateUtils.formatDateTime(props.approvalInfo.audit.updatedDate, DateFormat.DateTimeLongWeekdayShortMonthWithZone)
    : moment(props.approvalInfo.audit.updatedDate).fromNow();

  return (
    <div className="widget-container">
      <div className="widget-outer">
        <div className="widget-inner">
          <div className="task-approval-info-container">
            <div className={`main-container ${props.approvalInfo!.status.toLowerCase()}-container`}>
              <div className="content">
                <div className="approval-header">
                  <div>
                    This task has been{' '}
                    <span className={`${props.approvalInfo.status.toLowerCase()}-text`}>
                      {props.approvalInfo!.status.toLowerCase()}
                    </span>{' '}
                    by {props.approver!.username}
                  </div>

                  <div className="approval-date">{approvalDate}</div>
                </div>
                {comment ? (
                  <div className="comment-section">
                    <div className="approver-info">
                      <div>
                        <Avatar user={props.approver as User} />
                      </div>
                      <div className="about-approver">
                        <b>{props.approver.username}</b> commented:
                      </div>
                    </div>
                    <div className="comment">"{comment}"</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxAppState, props: PureTaskApprovalInfoProps): StateToProps => {
  const [approvalInfo] = BaseApprovalSelector.getAllRelevantBySubjectTaskId(props.taskId)(state);
  const approver: Option<User> = approvalInfo
    ? UserSelector.getById(approvalInfo.audit.updatedBy.id)(state)
    : undefined;
  const isPdfEmbellishmentEnabled = FeatureFlagSelector.getFeatureFlags(state).pdfEmbellishment;

  return {
    approvalInfo,
    approver,
    isPdfEmbellishmentEnabled,
  };
};

export const TaskApprovalInfo = connect<StateToProps, null, PureTaskApprovalInfoProps, ReduxAppState>(mapStateToProps)(
  PureTaskApprovalInfo,
);
