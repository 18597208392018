import { Plan, PlanLevel } from '@process-street/subgrade/billing';
import { Option, SubscriptionStatus } from '@process-street/subgrade/core';

export const getIsPaidAccount = (plan: Option<Plan>, status: Option<SubscriptionStatus>): boolean => {
  if (!plan || !status) {
    return false;
  }

  const isTrialing = status === SubscriptionStatus.Trialing;
  const isFree = plan?.level === PlanLevel.Free;
  const isEnterprise = plan?.level === PlanLevel.Enterprise;
  return (!isFree && !isTrialing) || isEnterprise;
};
