import { MergeTagMode, MergeTagTarget } from '@process-street/subgrade/form';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import {
  FormFieldValue,
  MultiOptionFieldValue,
  MultiSelectFieldValue,
  MultiSelectFormFieldWidget,
} from '@process-street/subgrade/process';
import { ObjectMap } from '@process-street/subgrade/redux/types';
import angular, { IChangesObject } from 'angular';
import { queryClient } from 'components/react-root';
import { UrlUtils } from 'components/utils/url-utils';
import {
  ResolvedMergeTagsByChecklistRevisionIdQuery,
  ResolvedMergeTagsByChecklistRevisionIdQueryResponse,
} from 'features/merge-tags/query-builder';
import { QueryObserver } from 'react-query';
import templateUrl from './multi-select-renderer.component.html';
import './multi-select-renderer.component.scss';

export class ApprovalSubjectTaskWidgetMultiSelectRendererController {
  public fieldValueMap: ObjectMap<MultiSelectFieldValue> = {};
  public tags: ResolvedMergeTagsByChecklistRevisionIdQueryResponse = [];
  public widget: MultiSelectFormFieldWidget | undefined;
  public formFieldValue?: FormFieldValue;

  static $inject = ['$filter', 'FeatureFlagService'];

  constructor(private $filter: angular.IFilterService) {}

  public $onInit = () => {
    if (!this.widget) return;

    this.subscribeToMergeTagsQuery();
  };

  public $onDestroy() {
    this.unsubscribeFromMergeTagsQuery?.();
  }

  public $onChanges(changes: { formFieldValue: IChangesObject<FormFieldValue> }) {
    const { formFieldValue } = changes;

    if (formFieldValue && formFieldValue.currentValue) {
      const { itemValues } = formFieldValue.currentValue.fieldValue as MultiOptionFieldValue;
      this.fieldValueMap = itemValues.reduce((map: ObjectMap<MultiSelectFieldValue>, item) => {
        map[item.id] = item;
        return map;
      }, {});
    }
  }

  public subscribeToMergeTagsQuery() {
    const observer = new QueryObserver(queryClient, {
      queryKey: ResolvedMergeTagsByChecklistRevisionIdQuery.getKey({
        checklistRevisionId: this.formFieldValue?.checklistRevision.id,
        mergeTagTarget: MergeTagTarget.CHECKLIST,
        taskId: this.widget?.header.taskTemplate.id,
      }),
      queryFn: ResolvedMergeTagsByChecklistRevisionIdQuery.queryFn(queryClient),
      staleTime: Infinity,
      refetchOnReconnect: 'always',
      refetchOnWindowFocus: 'always',
      refetchOnMount: 'always',
    });

    this.unsubscribeFromMergeTagsQuery = observer.subscribe(result => {
      this.tags = result.data ?? [];
    });
  }

  private unsubscribeFromMergeTagsQuery() {}

  public parseMergeTags(content: string) {
    const value =
      content === undefined
        ? ''
        : MergeTagStringReplacementUtils.replaceResolvedTagsValues(this.tags ?? [], content, MergeTagMode.HTML);

    if (value.includes('Variable value is missing')) return value;

    return (
      this.$filter('psLinky') as (
        value: string,
        target: unknown,
        attributes: (url: string) => {
          target: string;
        },
      ) => string
    )(value, null, this.setTargetAttribute);
  }

  public setTargetAttribute(url: string) {
    return {
      target: UrlUtils.isAppUrl(url) ? '_self' : '_blank',
    };
  }
}

export const ApprovalSubjectTaskWidgetMultiSelectRenderer: angular.IComponentOptions = {
  bindings: {
    formFieldValue: '<',
    widget: '<',
  },
  controller: ApprovalSubjectTaskWidgetMultiSelectRendererController,
  templateUrl,
};
