import { Box, Text } from '@chakra-ui/react';
import { EmbedUtils } from '@process-street/subgrade/util';
import * as React from 'react';
import { FullScreenViewer } from './FullscreenViewer';
import './SandboxedIframe.scss';

interface SandboxedIframeProps {
  url?: string;
}

export const SandboxedIframe: React.FunctionComponent<React.PropsWithChildren<SandboxedIframeProps>> = ({ url }) => {
  if (url) {
    return (
      <div className="sandboxed-iframe">
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            title={`${EmbedUtils.extractDomain(url)} iframe`}
            data-testid="sandboxed-iframe"
            src={url}
            frameBorder="0"
            allowFullScreen={true}
            sandbox="allow-scripts allow-forms allow-popups allow-same-origin allow-presentation allow-downloads allow-storage-access-by-user-activation"
            className="embed-responsive-item"
          />
        </div>
        <Box
          className="domain-bar"
          position="relative"
          display="flex"
          justifyContent="center"
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems="center"
        >
          <Text>
            Content loaded from{' '}
            <a href={url} className="domain" target="_blank" rel="noopener noreferrer">
              {EmbedUtils.extractDomain(url)}
            </a>
          </Text>
          {url && <FullScreenViewer url={url} />}
        </Box>
      </div>
    );
  }
  return null;
};
