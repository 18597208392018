import { Option } from '../core';
import { Checklist, ChecklistStatus, Task, TaskStatus } from './checklist-model';

export interface ChecklistProgress {
  totalTasksCount: number;
  completedTasksCount: number;
}

export namespace ChecklistUtils {
  export const getEarliestDueDate = (checklist: Option<Checklist>, tasks: Task[]): Option<number> =>
    tasks.reduce(
      (dueDate, task) =>
        !task.hidden && task.status !== TaskStatus.Completed && task.dueDate && (!dueDate || dueDate > task.dueDate)
          ? task.dueDate
          : dueDate,
      checklist ? checklist.dueDate : undefined,
    );

  export const getProgress = (tasks: Task[]): ChecklistProgress =>
    tasks.reduce(
      (progress, task) =>
        task.hidden
          ? progress
          : {
              totalTasksCount: progress.totalTasksCount + 1,
              completedTasksCount: progress.completedTasksCount + (task.status === TaskStatus.Completed ? 1 : 0),
            },
      { totalTasksCount: 0, completedTasksCount: 0 },
    );

  export const getOverdueTaskCount = (tasks: Task[]): number =>
    tasks.reduce(
      (overdue, task) =>
        overdue +
        (!task.hidden && task.dueDate && task.dueDate < Date.now() && task.status === TaskStatus.NotCompleted ? 1 : 0),
      0,
    );

  export const isCompleted = (checklist: Checklist) => checklist.status === ChecklistStatus.Completed;
  export const isArchived = (checklist: Checklist) => checklist.status === ChecklistStatus.Archived;
  export const isActive = (checklist: Checklist) => checklist.status === ChecklistStatus.Active;
}
