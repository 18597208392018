import angular from 'angular';
import { FocusById } from 'services/focus.interface';
import templateUrl from './approve-comment.component.html';
import './approve-comment.component.scss';

export class ApprovalSubjectTaskApproveCommentController {
  static $inject = ['focusById'];
  constructor(private focusById: FocusById) {
    this.focusById('comment');
  }
}

export const ApprovalSubjectTaskApproveComment: angular.IComponentOptions = {
  bindings: {
    onCancel: '&',
    onApprove: '&',
  },
  controller: ApprovalSubjectTaskApproveCommentController,
  templateUrl,
};
