import { RunButton } from 'app/features/new-buttons/run-button/run-button';
import { NewMenuMenuItem } from 'app/features/new-menu/component';
import { useOneOffTaskDrawerStore } from 'app/features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { CreateChecklistSource } from 'app/services/checklist-service.interface';
import { Button, HStack, Icon, Menu, MenuButton, MenuList, Portal, Text, VStack } from 'components/design/next';
import * as React from 'react';

export const TasksTableEmptyStateNewButton = () => {
  const { createProjectTask } = useOneOffTaskDrawerStore();

  return (
    <Menu autoSelect={false} isLazy={true}>
      <MenuButton
        as={Button}
        leftIcon={<Icon icon="plus" size="4" />}
        iconSpacing="2"
        textAlign="left"
        colorScheme="brand"
        size="sm"
        variant="outline"
        w="auto"
        color="gray.600"
        borderColor="gray.300"
        borderWidth="1px"
        fontWeight="medium"
      >
        New
      </MenuButton>
      <Portal>
        <MenuList color="gray.600" right="0" width="268px" zIndex="popover">
          <RunButton source={CreateChecklistSource.MAIN_NAV_BAR}>
            <NewMenuMenuItem>
              <HStack spacing="2" py={2}>
                <Icon variant="fas" icon="play" color="brand.500" size="4" />
                <Text fontWeight="medium">Run a Workflow</Text>
              </HStack>
            </NewMenuMenuItem>
          </RunButton>

          <NewMenuMenuItem onClick={createProjectTask}>
            <HStack spacing="2">
              <Icon
                variant="fad"
                icon="circle-check"
                primaryLayer={{
                  opacity: 1,
                  color: 'teal.500',
                }}
                secondaryLayer={{
                  opacity: 0.4,
                  color: 'teal.500',
                }}
                size="4"
              />
              <VStack w="full" ml="2" alignItems="start" spacing="0">
                <HStack>
                  <Text fontWeight="medium">Create a task</Text>
                </HStack>
              </VStack>
            </HStack>
          </NewMenuMenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};
