import * as React from 'react';
import { VStack } from 'components/design/next';
import { useGetOrganizationQuery, useUpdateOrganizationMutation } from 'features/organization/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { BrandCustomization } from './components/brand-customization';
import { ReferralPartnerProgram } from './components/referral-partner-program/referral-partner-program';
import { Organization } from './components/organization';
import { SsoSettings } from './components/sso-settings/sso-settings';
import { EmailBrandCustomization } from './components/email-brand-customization/email-brand-customization';
import { PlanLevel } from '@process-street/subgrade/billing';

export type SaveOrganizationParams = Partial<{
  name: string;
  growSumoPartnerKey: string;
  industry: string;
  timeZone: string;
  dateFormat: string;
}>;

export const OrganizationSettings = () => {
  const currentOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const plan = useSelector(SessionSelector.getCurrentPlan);

  const showEmailBrandCustomization = Boolean(plan) && plan?.level === PlanLevel.Enterprise;

  const organizationQuery = useGetOrganizationQuery({ organizationId: currentOrganization?.id });

  const updateOrganizationMutation = useUpdateOrganizationMutation({
    onSuccess: () => {
      organizationQuery.refetch();
    },
  });

  const handleSaveOrganization = (overrides?: SaveOrganizationParams) => {
    if (!organizationQuery?.data || !currentOrganization?.id)
      throw new Error('The organization details is not available');

    updateOrganizationMutation.mutate({
      ...organizationQuery.data,
      ...overrides,
    });
  };

  return (
    <VStack spacing="16" pb="6" alignItems="flex-start" w="full" maxW="736px" margin="0 auto">
      <Organization onSave={handleSaveOrganization} isLoading={updateOrganizationMutation.isLoading} />
      <BrandCustomization />
      {showEmailBrandCustomization && <EmailBrandCustomization />}
      <ReferralPartnerProgram onSave={handleSaveOrganization} isLoading={updateOrganizationMutation.isLoading} />
      <SsoSettings />
    </VStack>
  );
};
