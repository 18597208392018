import * as React from 'react';
import { Icon, Center, Tooltip } from 'app/components/design/next';

export const TaskListItemAiTaskIndicator = () => {
  return (
    <Tooltip label="AI task">
      <Center w={8} h={8}>
        <Icon icon="sparkles" aria-label="AI task" size="3.5" color="inherit" />
      </Center>
    </Tooltip>
  );
};
