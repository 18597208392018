import { Muid, Option, OrganizationMembership, User } from '@process-street/subgrade/core';
import { FolderPermit, TemplatePermit } from '@process-street/subgrade/permission';
import { EntityMap, LookupMap } from '@process-street/subgrade/redux/types';
import { Membership, PaginatedMemberships } from 'components/template/membership/models';
import isEqual from 'lodash/isEqual';
import { FolderPermitSelector } from 'reducers/folder-permit/folder-permit.selectors';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { statusesSelector } from 'reducers/statuses/statuses.selectors';
import { TemplatePermitSelector } from 'reducers/template-permit/template-permit.selectors';
import { PaginatedTemplatePermits, ReduxAppState, Status } from 'reducers/types';
import { UserSelector } from 'reducers/user/user.selectors';
import { createSelector, createSelectorCreator, defaultMemoize, Selector } from 'reselect';
import { GroupSelector } from 'reducers/group/group.selectors';

function isValueEqual<T>(arg1: T, arg2: T, _index: number): boolean {
  return isEqual(arg1, arg2);
}
// create a "selector creator" that uses lodash.isEqual instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isValueEqual);

const folderPermitsLookupSelector = (state: ReduxAppState) => state.lookups.folderPermits.byFolderId;

const templatePermitsLookupSelector = (state: ReduxAppState) => state.lookups.templatePermits.byTemplateId;

type TemplatePermitsByTemplateId = { [key in string]: PaginatedTemplatePermits };

function getMembershipForPermit(
  organizationMembershipsMap: EntityMap<OrganizationMembership>,
  userMap: EntityMap<User>,
  permit: TemplatePermit | FolderPermit,
): Membership {
  const om = permit && organizationMembershipsMap[permit.organizationMembership.id];
  const user = om && userMap[om.user.id];
  return {
    permit,
    user,
  };
}

interface TemplateAndFolderPermits {
  folderPermits: Muid[];
  paginatedTemplatePermits: PaginatedTemplatePermits;
}

const updateDateDescComparator = (a: Membership, b: Membership): number => {
  if (a.permit === undefined || b.permit === undefined) {
    return 0;
  } else if (a.permit.audit.updatedDate > b.permit.audit.updatedDate) {
    return -1;
  } else {
    return 1;
  }
};

function templatePermitCombinerNew(
  templateAndFolderPermits: TemplateAndFolderPermits,
  templatePermitsMap: EntityMap<TemplatePermit>,
  folderPermitsMap: EntityMap<FolderPermit>,
  organizationMembershipsMap: EntityMap<OrganizationMembership>,
  userMap: EntityMap<User>,
  hiddenUserIds: Muid[],
): PaginatedMemberships {
  const templatePermits: TemplatePermit[] = templateAndFolderPermits.paginatedTemplatePermits.permits.map(
    permitId => templatePermitsMap[permitId],
  );
  const folderPermits: FolderPermit[] = templateAndFolderPermits.folderPermits.map(
    permitId => folderPermitsMap[permitId],
  );

  const templateMemberships: Membership[] = templatePermits.map(permit =>
    getMembershipForPermit(organizationMembershipsMap, userMap, permit),
  );

  const folderMemberships: Membership[] = folderPermits.map(permit =>
    getMembershipForPermit(organizationMembershipsMap, userMap, permit),
  );

  const sortedFolderPermits = folderMemberships.sort(updateDateDescComparator);
  const memberships = [...templateMemberships, ...sortedFolderPermits];

  // We remove the duplicate permits as a permit can be present at a folder level and also at
  // template level
  const userToMembershipMap: { [key in Muid]: Membership } = {};
  const uniqueMemberships = memberships.reduce((agg: Membership[], membership: Membership) => {
    if (membership.user) {
      if (hiddenUserIds.includes(membership.user.id)) {
        return agg;
      }

      if (userToMembershipMap[membership.user.id]) {
        return agg;
      } else {
        userToMembershipMap[membership.user.id] = membership;
        agg.push(membership);
        return agg;
      }
    } else {
      agg.push(membership);
      return agg;
    }
  }, []);
  const duplicateCount = memberships.length - uniqueMemberships.length;
  const totalCount =
    folderMemberships.length + templateAndFolderPermits.paginatedTemplatePermits.totalCount - duplicateCount;

  // we slice five because we show the first five and then we show the number of more memberships
  const membershipsToShow = totalCount > 6 ? uniqueMemberships.slice(0, 5) : uniqueMemberships;

  const loadedTemplatePermitsCount = templateAndFolderPermits.paginatedTemplatePermits.permits.length;
  return {
    hasMore: templateAndFolderPermits.paginatedTemplatePermits.hasMore,
    loadedTemplatePermitsCount,
    memberships: membershipsToShow,
    totalCount,
  };
}

const emptyTemplatePermits = { hasMore: false, permits: [], totalCount: 0 };
const emptyFolderPermits: Muid[] = [];

const templateAndFolderPermitsSelector = (
  templateId: Muid,
  folderId: Muid,
): ((state: ReduxAppState) => TemplateAndFolderPermits) =>
  createDeepEqualSelector(
    [templatePermitsLookupSelector, folderPermitsLookupSelector],
    (permitsByTemplateId: TemplatePermitsByTemplateId, folderLookupMap: LookupMap) => ({
      folderPermits: folderLookupMap[folderId] || emptyFolderPermits,
      paginatedTemplatePermits: permitsByTemplateId[templateId] || emptyTemplatePermits,
    }),
  );

const templateAggregatedPermitsSelector = (
  templateId: Muid,
  folderId: Muid,
): ((state: ReduxAppState) => PaginatedMemberships) =>
  createDeepEqualSelector(
    [
      templateAndFolderPermitsSelector(templateId, folderId),
      TemplatePermitSelector.getEntityMap,
      FolderPermitSelector.getEntityMap,
      OrganizationMembershipSelector.getEntityMap,
      UserSelector.getEntityMap,
      GroupSelector.getHiddenSystemGroupsUserIds,
    ],
    templatePermitCombinerNew,
  );

const getStatusByTemplateId = (templateId: Muid): Selector<ReduxAppState, Option<Status>> =>
  createSelector(statusesSelector, status => status.templatePermits.byTemplateId[templateId]);

export const TemplatePermitsSelector = {
  getStatusByTemplateId,
  templateAggregatedPermitsSelector,
};
