import { AnalyticsConstants } from '@process-street/subgrade/analytics';
import angular from 'angular';
import { MSTeamsUtils } from 'features/microsoft-teams/utils';
import { trace } from 'components/trace';
import { ToastServiceImpl } from 'services/toast-service.impl';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'LoginSSOCtrl',
    function ($rootScope, $scope, $state, Auth0Service, appBootService, focusById, SessionService) {
      const ctrl = this;
      const logger = trace({ name: 'LoginSSOCtrl' });
      logger.info('loading ctrl');

      ctrl.$onInit = () => {
        $scope.msTeamsApp = MSTeamsUtils.isEmbeddedInMsTeams();

        $scope.info = {
          email: $state.params.email,
        };

        focusById('email');
      };

      $scope.login = function (info) {
        if ($scope.form.$valid) {
          $scope.submitting = true;

          const onError = () => {
            ToastServiceImpl.openToast({
              status: 'error',
              title: "We weren't able to sign-in",
              description: 'Please make sure your email address is correct.',
            });
            $scope.submitting = false;
          };

          if ($scope.msTeamsApp) {
            Auth0Service.loginUsingSSOPopup(info.email).then($scope._bootAppWithToken).catch(onError);
          } else {
            Auth0Service.loginUsingSSO(info.email).catch(onError);
          }
        } else {
          const messages = [];

          if ($scope.form.email.$error.required) {
            messages.push('You must enter an email address.');
          } else if ($scope.form.email.$error.email) {
            messages.push('You must enter a valid email address.');
          }

          const message = messages.join('<br>');
          ToastServiceImpl.openToast({
            status: 'error',
            title: message,
          });
        }
      };

      $scope._bootAppWithToken = function (token) {
        appBootService.setupSessionAndBootUsingToken(token).then(() => {
          $rootScope.$broadcast(
            AnalyticsConstants.Event.USER_LOGGED_IN,
            SessionService.getUser(),
            SessionService.getProfile(),
          );
          Auth0Service.redirectToStoredUrl();
        });
      };
    },
  );
