import { INVITATION_CREATE } from 'reducers/invitation/invitation.actions';
import { composeReducerObjects, handleActionsOnSuccess } from 'reducers/util';
import { combineActions } from 'redux-actions';
import { INBOX_GET_ALL_BY_ORGANIZATION_ID } from 'reducers/inbox/inbox.actions';
import {
  GROUP_MEMBERSHIP_QUERY,
  GROUP_MEMBERSHIP_QUERY_BY_GROUP,
} from 'reducers/group-membership/group-membership.actions';
import {
  GROUP_CREATE,
  GROUP_DELETE,
  GROUP_GET_BY_ID,
  GROUP_PUT,
  GROUP_QUERY,
  GROUP_UPDATE,
  GROUP_UPDATE_AVATAR,
} from 'reducers/group/group.actions';
import { ORGANIZATION_MEMBERSHIP_GET_ALL_BY_ORG_ID } from 'reducers/organization-membership/organization-membership.actions';
import { CHECKLIST_PUT } from 'reducers/checklist/checklist.actions';
import { appendOnPutReducerObject, cascadeCleanOnDeleteReducerObject } from 'reducers/entities/entities.utils';
import { USER_GET_BY_ID, USER_SEND_EMAIL_VERIFICATION, USER_UPDATE } from 'reducers/user/user.actions';
import {
  TASK_ASSIGNMENT_GET_ALL_BY_CHECKLIST_REVISION,
  TASK_ASSIGNMENT_INVITE,
} from 'components/checklist-task-assignment/store/checklist-task-assignment.actions';
import {
  FORM_FIELD_VALUE_SET,
  FORM_FIELD_VALUE_UPDATE,
} from 'components/form-field-value/store/form-field-value.actions';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { APPROVAL_GET_ALL_BY_CHECKLIST_REVISION } from 'components/approvals/store/approval.actions';
import { RequestStage } from '@process-street/subgrade/redux/types';
import { combineReducers } from 'redux';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { InboxItemUtils } from '@process-street/subgrade/inbox';

const normalizeUser = user => user;

const userReducerObject = {
  [INBOX_GET_ALL_BY_ORGANIZATION_ID]: (state, { payload: items }) => {
    const inboxAssignees = InboxItemUtils.getChecklists(items).map(item => item.assignees);
    const inboxUsers = inboxAssignees.reduce((agg, assignees) => {
      assignees.forEach(user => {
        agg[user.id] = normalizeUser(user);
      });
      return agg;
    }, {});
    if (inboxAssignees.length > 0) {
      return { ...state, ...inboxUsers };
    } else {
      return state;
    }
  },
  [TASK_ASSIGNMENT_INVITE]: (
    state,
    {
      payload: {
        organizationMembership: { user },
      },
    },
  ) => ({ ...state, [user.id]: normalizeUser(user) }),
  [GROUP_CREATE]: (
    state,
    {
      payload: {
        group: { user },
      },
    },
  ) => ({ ...state, [user.id]: normalizeUser(user) }),
  [GROUP_GET_BY_ID]: (state, { payload: groups }) =>
    EntitiesReducerUtils.upsertAll(
      state,
      groups.map(({ user }) => user),
      normalizeUser,
    ),
  [GROUP_QUERY]: (state, { payload: groups }) =>
    EntitiesReducerUtils.upsertAll(
      state,
      groups.map(({ user }) => user),
      normalizeUser,
    ),
  [GROUP_UPDATE]: (state, { payload: { user } }) => EntitiesReducerUtils.upsert(state, user, normalizeUser),
  [GROUP_UPDATE_AVATAR]: (
    state,
    {
      payload: {
        group: {
          user: { id: userId },
        },
        avatarFile,
      },
    },
  ) => {
    const user = state[userId];
    const newUser = Object.assign({}, user, { avatarFile });
    return EntitiesReducerUtils.upsert(state, newUser, normalizeUser);
  },
  [GROUP_MEMBERSHIP_QUERY]: (state, { payload: groups }) => {
    const users = groups.map(({ group: { user } }) => user);
    return EntitiesReducerUtils.upsertAll(state, users, normalizeUser);
  },
  [GROUP_MEMBERSHIP_QUERY_BY_GROUP]: (state, { payload: groupMemberships }) => {
    const users = groupMemberships.map(({ organizationMembership: { user } }) => user);
    return EntitiesReducerUtils.upsertAll(state, users, normalizeUser);
  },
  [ORGANIZATION_MEMBERSHIP_GET_ALL_BY_ORG_ID]: (state, { payload }) => {
    const users = payload.map(({ user }) => user);
    return EntitiesReducerUtils.upsertAll(state, users, normalizeUser);
  },
  [INVITATION_CREATE]: (
    state,
    {
      payload: {
        organizationMembership: { user },
      },
    },
  ) => EntitiesReducerUtils.upsert(state, user),
  [combineActions(FORM_FIELD_VALUE_UPDATE, FORM_FIELD_VALUE_SET)]: (
    state,
    {
      payload: {
        checklistTaskAssignments: { created: assignments },
      },
    },
  ) =>
    EntitiesReducerUtils.upsertAll(
      state,
      assignments.map(a => a.organizationMembership.user),
      normalizeUser,
    ),

  [APPROVAL_GET_ALL_BY_CHECKLIST_REVISION + RequestStage.Success]: (state, { payload: approvals }) =>
    EntitiesReducerUtils.upsertAll(
      state,
      approvals.map(approval => approval.audit.updatedBy),
    ),
  [TASK_ASSIGNMENT_GET_ALL_BY_CHECKLIST_REVISION]: (state, { payload: assignments }) =>
    EntitiesReducerUtils.upsertAll(
      state,
      assignments.map(assignment => assignment.organizationMembership.user),
      normalizeUser,
    ),
};

const userActionsReducer = {
  [combineActions(USER_GET_BY_ID, USER_UPDATE)]: (state, { payload: user }) => {
    if (user) {
      return EntitiesReducerUtils.upsert(state, user);
    } else {
      return state;
    }
  },
  [USER_SEND_EMAIL_VERIFICATION]: (state, { meta: { userId } }) => {
    if (state[userId]) {
      const updatedUser = { ...state[userId], emailVerificationSentDate: Date.now() };
      return { ...state, [userId]: updatedUser };
    } else {
      return state;
    }
  },
};

export const userEntitiesReducer = composeReducerObjects(
  userActionsReducer,

  userReducerObject,

  appendOnPutReducerObject(GROUP_PUT, 'user', normalizeUser),
  appendOnPutReducerObject(CHECKLIST_PUT, 'completedBy', normalizeUser),
  appendOnPutReducerObject(CHECKLIST_PUT, 'archivedBy', normalizeUser),

  cascadeCleanOnDeleteReducerObject(GROUP_DELETE, 'id', 'user.id'),
);

export const usersByEmailActionHandlers = {
  [ORGANIZATION_MEMBERSHIP_GET_ALL_BY_ORG_ID]: (state, { payload }) => {
    const users = payload.map(({ user }) => user);
    const referenceMap = LookupsReducerUtils.upsertReferencesUsingSelectorFunctions(state, users, user => user.email);
    return referenceMap;
  },
};

export const usersByEmailReducer = handleActionsOnSuccess(usersByEmailActionHandlers, {});

export const usersLookupsReducer = combineReducers({
  byEmail: usersByEmailReducer,
});
