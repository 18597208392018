import angular from 'angular';
import { trace } from 'components/trace';

angular.module('frontStreetApp.services').factory('focusById', ($timeout, util) => {
  const logger = trace({ name: 'focusById' });

  // Shared loop timeout
  // This is so there can be only 1 focus attempt going at once, and each new one cancels the old one
  let loopTimeout;

  return function (id, options) {
    const defaultedOptions = angular.extend(
      {
        mobile: false, // Should this be run on mobile?
        selectionStart: angular.identity,
        selectionEnd: angular.identity,
      },
      options,
    );

    if (!defaultedOptions.mobile && util.isMobile()) {
      // Only focus on mobile if the option is set
      // This is because focus on mobile is wonky
      return;
    }

    function setSelection(element) {
      if (element.is(':text,textarea')) {
        const { length } = element.val();
        const start = defaultedOptions.selectionStart(length);
        const end = defaultedOptions.selectionEnd(length);
        element[0].setSelectionRange(start, end);
      }
    }

    function loop(delay, retries) {
      $timeout.cancel(loopTimeout);
      loopTimeout = $timeout(() => {
        const element = defaultedOptions.useChildElement
          ? angular.element(`[data-focus-id=${id.replace('.', '\\.')}]`).find(':text,textarea')
          : angular.element(`[data-focus-id=${id.replace('.', '\\.')}]`);
        if (element.length) {
          if (element.is(':focus')) {
            setSelection(element);
          } else {
            element[0].focus();
            if (element.is(':focus')) {
              setSelection(element);
            } else if (retries) {
              loop(100, retries - 1);
            } else {
              logger.info('ran out of retries');
            }
          }
        } else {
          loop(100, retries - 1);
        }
      }, delay);
    }
    loop(0, 10);
  };
});
