import { ICellRendererParams } from '@ag-grid-community/core';
import * as React from 'react';
import { GridHelper } from 'components/dashboard/services/grid-helper';
import { Box, Button, Icon, useDisclosure } from 'components/design/next';
import { GetLabelsByOrganizationQuery } from 'features/checklist-grid/query-builder';
import { ChecklistColumn } from '@process-street/subgrade/dashboard';
import { useDashboardGridContextStore } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/ChecklistDashboardGridContextStore';
import { LabelSelector } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers/LabelRenderer/LabelSelector';
import { LabelValue } from 'components/dashboard/models/grid';
import { LabelItem } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers/LabelRenderer/LabelItem';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';

export interface LabelRendererProps extends Partial<ICellRendererParams> {
  value?: LabelValue;
}

const LabelSkeleton = ({ rowIndex }: { rowIndex: number }) => {
  const sizes = ['50', '60', '54', '42', '46'];

  return <Box w={sizes[rowIndex % sizes.length]} h="4" bgColor="gray.100" borderRadius="xs" />;
};

export const LabelRenderer: React.FC<React.PropsWithChildren<LabelRendererProps>> = ({ value, rowIndex, data }) => {
  const labelsQuery = GetLabelsByOrganizationQuery.useQuery();
  const gridContextStore = useDashboardGridContextStore();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const currentUserInfoQuery = useGetCurrentUserInfoQuery();

  if (!value && GridHelper.isEmptyStateRow(data)) return <LabelSkeleton rowIndex={rowIndex ?? 0} />;
  if (!labelsQuery.data) return <LabelSkeleton rowIndex={rowIndex ?? 0} />;

  const { checklistId, labelId } = value ?? {};

  const isHover =
    gridContextStore.hoveredRowId === data?.id && gridContextStore.hoveredColumnId === ChecklistColumn.ChecklistLabelId;
  const isExternalGuest = currentUserInfoQuery.data?.organizationMembership.role === OrganizationMembershipRole.Guest;

  const labels = labelsQuery.data;
  const label = labels.find(l => l.id === labelId);
  return isExternalGuest ? (
    <>{label && <LabelItem label={label} />}</>
  ) : (
    <Button
      onClick={onOpen}
      _hover={{ opacity: isHover ? '0.5' : '1', cursor: isHover ? 'pointer' : 'auto' }}
      display="contents"
      alignItems="center"
      variant="unstyled"
      justifyContent="left"
      rightIcon={<Icon variant="far" icon="edit" size="4" visibility={isHover ? 'visible' : 'hidden'} />}
    >
      {label && <LabelItem label={label} />}
      {checklistId && (
        <LabelSelector
          isOpen={isOpen}
          onClose={onClose}
          checklistId={checklistId}
          labelId={labelId}
          rowValues={value?.rowValues}
        />
      )}
    </Button>
  );
};
