import { trace } from 'app/components/trace';
import { ErrorPlatformEvent } from 'xstate';

/** https://stately.ai/docs/xstate-v4/xstate/actors/promises */
export const makeErrorLoggerAction = (machineName: string = 'unknown machine') => {
  const logger = trace({ name: `XState machine [${machineName}]` });
  return (__: unknown, errorEvent: ErrorPlatformEvent) => {
    logger.error('Error in XState machine', errorEvent.data);
  };
};
