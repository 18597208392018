import { Checklist } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { QueryClient, QueryObserver, QueryObserverOptions, useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetChecklistQueryParams = { checklistId?: Checklist['id'] };

export type GetChecklistQueryResponse = Checklist;

export const GetChecklistQuery = {
  key: ['checklists'],
  getKey: (params: GetChecklistQueryParams): QueryKey => [...GetChecklistQuery.key, params],
  queryFn: ({ checklistId }: GetChecklistQueryParams) =>
    axiosService
      .getAxios()
      .get<GetChecklistQueryResponse>(`/1/checklists/${checklistId}`)
      .then(res => res.data),
};

export const useGetChecklistQuery = <Select = GetChecklistQueryResponse>(
  params: GetChecklistQueryParams,
  options: UseQueryOptions<GetChecklistQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetChecklistQuery.getKey(params), () => GetChecklistQuery.queryFn(params), {
    ...options,
    enabled: Boolean(params.checklistId) && options.enabled !== false,
  });
};

export const makeGetChecklistQueryObserver = ({
  queryClient,
  options,
  ...params
}: GetChecklistQueryParams & {
  queryClient: QueryClient;
  options: QueryObserverOptions<GetChecklistQueryResponse, AxiosError>;
}) => {
  return new QueryObserver<GetChecklistQueryResponse, AxiosError>(queryClient, {
    queryKey: GetChecklistQuery.getKey(params),
    queryFn: () => GetChecklistQuery.queryFn(params),
    enabled: Boolean(params.checklistId) && options.enabled !== false,
    ...options,
  });
};
