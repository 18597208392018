import { Alert, AlertDescription, AlertIcon, AlertTitle, HStack, Link, Spacer, Text } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { useCanUseOrganization, useOrganizationUsageStats } from 'components/paywalls/trial-expired/query';
import React from 'react';
import { useFeatureSet } from 'components/paywalls/use-feature-set';
import { useRoleNames } from 'hooks/use-role-names';

export const MembersOveragesAlert: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { data: canUseOrganization } = useCanUseOrganization();
  const { data: usageStats } = useOrganizationUsageStats();
  const { $state } = useInjector('$state');
  const { usersLimit } = useFeatureSet();
  const roleNames = useRoleNames();

  return canUseOrganization && !canUseOrganization.canUse && usageStats ? (
    <Alert status="warning" bgColor="yellow.100" flexDirection="column" alignItems="start" mb={2} color="gray.700">
      <HStack spacing={1}>
        <AlertIcon mr={2} color="yellow.500" />
        {usersLimit === 1 ? (
          <AlertTitle ms={1}>You are over the maximum of 1 {roleNames.FullMember.single}.</AlertTitle>
        ) : (
          <AlertTitle ms={1}>
            You are over the maximum of {usersLimit} {roleNames.FullMember.plural}.
          </AlertTitle>
        )}
      </HStack>
      <HStack mb={0.5}>
        <Spacer width="20px" mr={2} />
        <AlertDescription>
          You must demote{' '}
          <Text fontWeight="500" as="span">
            {usageStats.fullMembersCount - usersLimit}
          </Text>{' '}
          {usageStats.fullMembersCount - usersLimit > 1
            ? `${roleNames.FullMember.plural} to ${roleNames.FreeMember.plural} to proceed on the Free plan.`
            : `${roleNames.FullMember.single} to a ${roleNames.FreeMember.single} to proceed on the Free plan.`}
        </AlertDescription>
      </HStack>
      <HStack mb={0.5}>
        <Spacer width="20px" mr={2} />
        <Link href={$state.href('organizationManage.tab', { tab: 'billing' })} fontWeight="500" color="brand.500">
          Upgrade Now »
        </Link>
      </HStack>
    </Alert>
  ) : null;
};
