import { AttachmentActivity, CommentActivity } from '@process-street/subgrade/activity';
import { User } from '@process-street/subgrade/core';
import { CommentStatus } from '@process-street/subgrade/process';
import { groupBy } from 'lodash';

export const getUserProfilePicUrl = (user?: User) => {
  if (user?.avatarUrl) {
    return user.avatarUrl;
  }
  if (user?.avatarFile) {
    return `https://aang.s3.amazonaws.com/${user.avatarFile.id}-64.jpg`;
  }
  return '';
};

export const getActiveCommentsFromActivityLog = (data: CommentActivity[]) => {
  /**
   * Activities are loosely structured on the backend.
   * We added a new commentId to the code which stores them,
   * but older activity items won't have it. This code basically handles that situation.
   */
  const supportedCommentActivities = data.filter(activity => activity.data && 'commentId' in activity.data);
  const groupedByCommentId = groupBy(supportedCommentActivities, activity => activity.data.commentId);
  return supportedCommentActivities.filter(activity => {
    const resourceId = activity.data.commentId;
    const isCommentDeleted = groupedByCommentId[resourceId!].some(
      activity => activity.data.status === CommentStatus.Deleted,
    );
    return !isCommentDeleted;
  });
};

export const getActiveAttachmentsFromActivityLog = (data: AttachmentActivity[]) => {
  /**
   * Activities are loosely structured on the backend.
   * We added a new attachmentId to the code which stores them,
   * but older activity items won't have it. This code basically handles that situation.
   */
  const supportedAttachmentActivities = data.filter(activity => activity.data && 'attachmentId' in activity.data);
  const groupedByAttachmentId = groupBy(supportedAttachmentActivities, activity => activity.data.attachmentId);
  return supportedAttachmentActivities.filter(activity => {
    const resourceId = activity.data.attachmentId;
    const isAttachmentDeleted = groupedByAttachmentId[resourceId!].some(
      activity => activity.data.status === CommentStatus.Deleted,
    );
    return !isAttachmentDeleted;
  });
};
