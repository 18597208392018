import { ICellRendererParams } from '@ag-grid-community/core';
import { User } from '@process-street/subgrade/core';
import { ChecklistColumn } from '@process-street/subgrade/dashboard';
import { Avatar as PSAvatar, AvatarSize } from 'components/common/Avatar';
import { AvatarGroup as PSAvatarGroup } from 'components/common/avatar-group';
import { Box, Icon, Tooltip } from 'components/design/next';
import { UserValue } from 'components/dashboard/models/grid';
import * as React from 'react';
import { useDashboardGridContextStore } from '../../ChecklistDashboardGridContextStore';
import { useReassignModalStore } from '../../ChecklistDashboardGridReassignModalStore';
import { BoxProps, HStack, SkeletonCircle } from '@chakra-ui/react';
import { GridHelper } from 'components/dashboard/services/grid-helper';

interface AssigneesRendererProps extends Partial<ICellRendererParams> {
  value?: UserValue[];
}

const AVATAR_COUNT_LIMIT = 3;
const AVATAR_MARGIN = -3;

interface UserAvatarProps extends BoxProps {
  assignee: UserValue;
  isRest?: boolean;
}

const UserAvatar = ({ assignee, isRest = false, ...rest }: UserAvatarProps) => {
  return (
    <PSAvatar
      initialsProps={isRest ? { backgroundColor: 'gray.400' } : undefined}
      url={assignee.avatarUrl}
      initials={assignee.initials}
      size={AvatarSize.Small}
      user={assignee as User}
      {...rest}
    />
  );
};

const avatarCounts = [3, 2, 4, 2, 1, 4];
const colors = ['gray.100', 'gray.50', 'gray.200'];

const AssigneesSkeleton = ({ rowIndex }: { rowIndex: number }) => {
  return (
    <HStack spacing="-2">
      {Array.from({ length: avatarCounts[rowIndex % avatarCounts.length] }, (_, index) => (
        <SkeletonCircle key={index} w="6" h="6" bgColor={colors[index % colors.length]} isLoaded />
      ))}
    </HStack>
  );
};

const RestCountAvatar = ({ restCount }: { restCount: number }) => {
  const initials = restCount < 100 ? `${restCount}` : '99+';
  return <UserAvatar assignee={{ initials }} isRest={true} ml={AVATAR_MARGIN} key="rest" />;
};

export const AssigneesRenderer: React.FC<React.PropsWithChildren<AssigneesRendererProps>> = ({
  value: userValues,
  colDef,
  rowIndex,
  data,
}) => {
  const gridContextStore = useDashboardGridContextStore();
  const reassignModalStore = useReassignModalStore();

  if (!userValues && GridHelper.isRunWorkflowRow(data)) return <div />;

  if (!userValues) {
    return <AssigneesSkeleton rowIndex={rowIndex ?? 0} />;
  }

  const isHover =
    gridContextStore.hoveredRowId === data?.id && gridContextStore.hoveredColumnId === ChecklistColumn.Assignees;

  const handleOnClick = () => {
    reassignModalStore.onOpen({ checklistId: data?.id });
  };

  const avatars = userValues.map((userValue, index) => (
    <UserAvatar assignee={userValue} key={index} ml={index === 0 ? 0 : AVATAR_MARGIN} />
  ));

  return gridContextStore.canReassign ? (
    <Tooltip
      label={userValues.length > 0 ? 'Reassign Workflow Run' : 'Assign Workflow Run'}
      id={`checklist-${rowIndex}-${colDef?.colId ?? 'unknown-assignees-fields-column'}`}
      hasArrow
      display="flex"
    >
      <Box onClick={handleOnClick} _hover={{ opacity: isHover ? '0.5' : '1', cursor: isHover ? 'pointer' : 'auto' }}>
        <PSAvatarGroup
          minH="12"
          minW="26"
          max={AVATAR_COUNT_LIMIT}
          restCountAvatar={RestCountAvatar}
          rightItem={<Icon ml={1.5} variant="far" icon="edit" size="4" visibility={isHover ? 'visible' : 'hidden'} />}
        >
          {avatars}
        </PSAvatarGroup>
      </Box>
    </Tooltip>
  ) : (
    <PSAvatarGroup minH="12" minW="26" max={AVATAR_COUNT_LIMIT} restCountAvatar={RestCountAvatar}>
      {avatars}
    </PSAvatarGroup>
  );
};
