import { Approval } from '@process-street/subgrade/approval-rule/approval.model';
import { Identifiable, OrganizationMembership, User } from '@process-street/subgrade/core';
import { Attachment, Checklist, Comment, FormFieldValue, Task, TaskStats } from '@process-street/subgrade/process';
import { ChecklistAssignment, TaskAssignment } from '@process-street/subgrade/role-assignment';
import {
  asCreateEvent,
  asDeleteEvent,
  asUpdateEvent,
  OptimisticDiff,
  OptimisticEvent,
  OptimisticResult,
  OptimisticUpdate,
} from '../model';
import { reduceOptimisticEventsByEntityId } from './merge-optimistic-events';

enum OptimisticOperation {
  Commit = 'commit',
  Rollback = 'rollback',
}

class EntityEventBuilder<T extends Identifiable> {
  public commit: Array<OptimisticEvent<T>>;
  public rollback: Array<OptimisticEvent<T>>;

  constructor(private builder: OptimisticResultBuilder) {
    this.commit = [];
    this.rollback = [];
  }

  public appendCreateEvent(createdEntity: T) {
    this.commit.push(asCreateEvent(createdEntity));
    this.rollback.push(asDeleteEvent(createdEntity));
    return this.builder;
  }

  public appendUpdateEvent(updatedEntity: OptimisticDiff<T>, originalEntity: OptimisticDiff<T>) {
    this.commit.push(asUpdateEvent(updatedEntity));
    this.rollback.push(asUpdateEvent(originalEntity));
    return this.builder;
  }

  public appendDeleteEvent(removedEntity: T) {
    this.commit.push(asDeleteEvent(removedEntity));
    this.rollback.push(asCreateEvent(removedEntity));
    return this.builder;
  }
}

export class OptimisticResultBuilder {
  public user: EntityEventBuilder<User>;
  public organizationMembership: EntityEventBuilder<OrganizationMembership>;
  public approval: EntityEventBuilder<Approval>;
  public attachment: EntityEventBuilder<Attachment>;
  public formFieldValue: EntityEventBuilder<FormFieldValue>;
  public checklist: EntityEventBuilder<Checklist>;
  public checklistAssignment: EntityEventBuilder<ChecklistAssignment>;
  public comment: EntityEventBuilder<Comment>;
  public task: EntityEventBuilder<Task>;
  public taskAssignment: EntityEventBuilder<TaskAssignment>;
  public taskStats: EntityEventBuilder<TaskStats>;

  private rollbackOnSuccess: boolean;

  constructor(rollbackOnSuccess: boolean) {
    this.rollbackOnSuccess = rollbackOnSuccess;
    this.user = new EntityEventBuilder<User>(this);
    this.organizationMembership = new EntityEventBuilder<OrganizationMembership>(this);
    this.approval = new EntityEventBuilder<Approval>(this);
    this.attachment = new EntityEventBuilder<Attachment>(this);
    this.formFieldValue = new EntityEventBuilder<FormFieldValue>(this);
    this.checklist = new EntityEventBuilder<Checklist>(this);
    this.checklistAssignment = new EntityEventBuilder<ChecklistAssignment>(this);
    this.comment = new EntityEventBuilder<Comment>(this);
    this.task = new EntityEventBuilder<Task>(this);
    this.taskAssignment = new EntityEventBuilder<TaskAssignment>(this);
    this.taskStats = new EntityEventBuilder<TaskStats>(this);
  }

  public isEmpty() {
    const update = this.build();
    return Object.keys(update.commit).length === 0 && Object.keys(update.rollback).length === 0;
  }

  public buildUpdateResult(property: OptimisticOperation): Partial<OptimisticUpdate> {
    const optimisticUpdate: Partial<OptimisticUpdate> = {};
    if (this.user[property].length > 0) {
      optimisticUpdate.userEvents = reduceOptimisticEventsByEntityId(this.user[property]);
    }
    if (this.organizationMembership[property].length > 0) {
      optimisticUpdate.organizationMembershipEvents = reduceOptimisticEventsByEntityId(
        this.organizationMembership[property],
      );
    }
    if (this.approval[property].length > 0) {
      optimisticUpdate.approvalEvents = reduceOptimisticEventsByEntityId(this.approval[property]);
    }
    if (this.attachment[property].length > 0) {
      optimisticUpdate.attachmentEvents = reduceOptimisticEventsByEntityId(this.attachment[property]);
    }
    if (this.checklist[property].length > 0) {
      optimisticUpdate.checklistEvents = reduceOptimisticEventsByEntityId(this.checklist[property]);
    }
    if (this.checklistAssignment[property].length > 0) {
      optimisticUpdate.checklistAssignmentEvents = reduceOptimisticEventsByEntityId(this.checklistAssignment[property]);
    }
    if (this.comment[property].length > 0) {
      optimisticUpdate.commentEvents = reduceOptimisticEventsByEntityId(this.comment[property]);
    }
    if (this.formFieldValue[property].length > 0) {
      optimisticUpdate.formFieldValueEvents = reduceOptimisticEventsByEntityId(this.formFieldValue[property]);
    }
    if (this.taskStats[property].length > 0) {
      optimisticUpdate.taskStatEvents = reduceOptimisticEventsByEntityId(this.taskStats[property]);
    }
    if (this.task[property].length > 0) {
      optimisticUpdate.taskEvents = reduceOptimisticEventsByEntityId(this.task[property]);
    }
    if (this.taskAssignment[property].length > 0) {
      optimisticUpdate.taskAssignmentEvents = reduceOptimisticEventsByEntityId(this.taskAssignment[property]);
    }
    return optimisticUpdate;
  }

  public build(): OptimisticResult {
    return {
      commit: this.buildUpdateResult(OptimisticOperation.Commit),
      rollback: this.buildUpdateResult(OptimisticOperation.Rollback),
      rollbackOnSuccess: this.rollbackOnSuccess,
    };
  }
}
