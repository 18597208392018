import { OverflowingValuesIndicatorProps } from 'components/design/BlvdSelect/components/multi/OverflowingValuesIndicator';
import React from 'react';
import { Tooltip, HStack, Text, UnorderedList, ListItem } from 'components/design/next';
import { UserSelectedItemProps } from 'components/run-checklist/components/RunChecklist/components/UserSelectedItem';

export const OverflowingUsersIndicator: React.FC<React.PropsWithChildren<OverflowingValuesIndicatorProps>> = ({
  count,
  visible,
  children,
}) => {
  const usernames = (children as React.ReactElement<UserSelectedItemProps>[]).map(ch => ch.props.data.user.username);

  const tooltipLabel = (
    <UnorderedList marginBottom={0}>
      {usernames.map(username => (
        <ListItem key={username}>{username}</ListItem>
      ))}
    </UnorderedList>
  );

  return (
    <HStack
      backgroundColor="brand.100"
      borderRadius="20px"
      paddingX={3}
      alignSelf="center"
      opacity={visible ? '1' : '0'}
      height={8}
    >
      <Tooltip label={tooltipLabel} hasArrow shouldWrapChildren isDisabled={!visible}>
        <Text variant="-1" fontWeight="medium" color="gray.600">
          +{count}
        </Text>
      </Tooltip>
    </HStack>
  );
};
