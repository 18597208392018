import * as React from 'react';
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from 'components/design/next';
import { UserTypeAheadSelect } from './user-type-ahead-select';
import { PermitRow } from './permit-row';
import { StringUtils } from '@process-street/subgrade/util';
import { PermissionsDescription } from 'components/permissions/permissions-description/component';
import { useDataSetPermits } from 'pages/reports/data-sets/components/share/use-data-set-permits';
import { useGetCurrentUserInfoQuery } from 'app/features/user/query-builder';
import { OrganizationMembershipRole, User } from '@process-street/subgrade/core';

export type ShareDataSetModalProps = {
  dataSetId: string;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
};

export const ShareDataSetModal: React.FC<React.PropsWithChildren<ShareDataSetModalProps>> = ({
  dataSetId,
  isOpen,
  onClose,
}) => {
  const currentUserInfo = useGetCurrentUserInfoQuery();
  const isAdmin = currentUserInfo.data?.organizationMembership.role === OrganizationMembershipRole.Admin;

  const [searchQuery, setSearch] = React.useState('');

  const { handleRemoveDataSetPermit, dataSetPermitsWithOms, handleAddDataSetPermit } = useDataSetPermits({
    dataSetId,
  });

  const existingDataSetPermitOmIds = dataSetPermitsWithOms.map(permit => permit.organizationMembership.id) || [];

  const dataSetPermitOms =
    dataSetPermitsWithOms.map(dataSetPermitWithOm => dataSetPermitWithOm.organizationMembership) ?? [];

  const visiblePermits = dataSetPermitsWithOms
    .filter(permitWithOm => {
      const { user } = permitWithOm.organizationMembership;
      if (searchQuery === '' && user.id !== currentUserInfo.data?.user.id) return true;
      const inUsername = StringUtils.containsIgnoreCase(user.username, searchQuery);
      const inEmail = StringUtils.containsIgnoreCase(user.email, searchQuery);
      return inUsername || inEmail;
    })
    .slice(0, 50);

  // Disable permit selection for current user if not admin
  const isRowDisabled = (permitUserId: User['id']) => {
    return currentUserInfo.data?.user.id === permitUserId && !isAdmin;
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="2xl">
        <ModalHeader border="0" borderBottomWidth="1px" borderStyle="solid" borderColor="gray.200">
          <HStack spacing="2">
            <Icon icon="share-alt" size="4" color="gray.400" />

            <Text size="xl" fontWeight="700" color="gray.700">
              Share Data Set
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody pt="6" pb="4">
          <Box m={2} marginTop={0} marginBottom={8}>
            <Text color="gray.500">
              Sharing a Data Set gives users permission to{' '}
              <Text as="span" fontWeight="bold">
                create
              </Text>{' '}
              and{' '}
              <Text as="span" fontWeight="bold">
                edit
              </Text>{' '}
              data sets, and{' '}
              <Text as="span" fontWeight="bold">
                create saved views
              </Text>{' '}
              from them.
            </Text>
          </Box>
          {existingDataSetPermitOmIds && (
            <Box m={2}>
              <UserTypeAheadSelect
                organizationMembershipIdsToExclude={existingDataSetPermitOmIds}
                onChange={handleAddDataSetPermit}
              />

              <Divider />
            </Box>
          )}

          <Box m={2}>
            <HStack justifyContent="space-between" my="4">
              {dataSetPermitOms.length > 0 ? <PermissionsDescription flex="1" memberships={dataSetPermitOms} /> : null}

              {existingDataSetPermitOmIds.length > 0 ? (
                <Input
                  variant="outline"
                  w="50%"
                  value={searchQuery}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Filter current users"
                />
              ) : null}
            </HStack>
            <VStack divider={<Divider my="0!important" />} alignItems="stretch" role="list">
              {visiblePermits.map(dataSetPermit => {
                const isDisabled = isRowDisabled(dataSetPermit.organizationMembership.user.id);
                return (
                  <PermitRow
                    isDisabled={isDisabled}
                    key={dataSetPermit.id}
                    permit={dataSetPermit}
                    handleRemove={() => handleRemoveDataSetPermit(dataSetPermit)}
                  />
                );
              })}
            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" size="md" onClick={onClose}>
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
