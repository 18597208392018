import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { InviteFullMemberPaywall } from './invite-full-member-paywall';

export const InviteFullMemberPaywallModule = angular
  .module('inviteFullMemberPaywall', [])
  .component(
    'psInviteFullMemberPaywall',
    reactToAngular(InviteFullMemberPaywall, ['isOpen', 'onClose', 'invitee']),
  ).name;
