import * as React from 'react';
import { Skeleton } from 'components/design/next';
import { CrossLinkWidget, WidgetType } from '@process-street/subgrade/process';
import { Node } from 'directives/rules/template/task-templates-selector/selector-helper';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { match } from 'ts-pattern';

type NodeNameProps = {
  node?: Node;
};

export const removeHtmlTags = (name: string) => {
  return name.replace('&gt;', '').replace('&lt;', '').replace('&#xA0', '').replace('&nbsp;', '');
};

export const NodeName: React.FC<React.PropsWithChildren<NodeNameProps>> = ({ node }) => {
  const templateQuery = useGetTemplateQuery(
    { templateId: (node?.ref as CrossLinkWidget)?.templateId ?? '' },
    {
      enabled: node?.widgetType === WidgetType.CrossLink,
    },
  );

  return match(templateQuery)
    .with({ status: 'success' }, ({ data }) => <>{removeHtmlTags(data.name)}</>)
    .with({ status: 'loading' }, () => <Skeleton display="inline">{removeHtmlTags(node?.name ?? '')}</Skeleton>)
    .otherwise(() => <>{removeHtmlTags(node?.name ?? '')}</>);
};
