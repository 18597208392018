import { createCachedAction } from 'reducers/util';
import { htmlEscaped } from '@process-street/subgrade/util';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import Muid from 'node-muid';
import { uuid } from 'services/uuid';
import { UserSelector } from 'reducers/user/user.selectors';
import { AssignmentStatus } from '@process-street/subgrade/role-assignment';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';

export const TASK_ASSIGNMENT_GET_ALL_BY_CHECKLIST_REVISION =
  'frontStreet/checklistTaskAssignment/GET_ALL_BY_CHECKLIST_REVISION';
export const TASK_ASSIGNMENT_ASSIGN = 'frontStreet/checklistTaskAssignment/ASSIGN';
export const TASK_ASSIGNMENT_ADD = 'frontStreet/checklistTaskAssignment/ADD';
export const TASK_ASSIGNMENT_REMOVE = 'frontStreet/checklistTaskAssignment/REMOVE';
export const TASK_ASSIGNMENT_ASSIGN_START = 'frontStreet/checklistTaskAssignment/ASSIGN_START';
export const TASK_ASSIGNMENT_UNASSIGN = 'frontStreet/checklistTaskAssignment/UNASSIGN';
export const TASK_ASSIGNMENT_INVITE = 'frontStreet/checklistTaskAssignment/INVITE';

export const ChecklistTaskAssignmentActions = ChecklistTaskAssignmentApi => {
  'ngInject';

  const getAllByChecklistRevisionId = createCachedAction(
    TASK_ASSIGNMENT_GET_ALL_BY_CHECKLIST_REVISION,
    ChecklistTaskAssignmentApi.getAllByChecklistRevisionId,
    checklistRevisionId => ({ checklistRevisionId }),
  );

  const doAssign = createCachedAction(
    TASK_ASSIGNMENT_ASSIGN,
    ChecklistTaskAssignmentApi.assign,
    (taskId, email, newAssignment, userLabel) => ({
      taskId,
      email,
      newAssignment,
      userLabel,
    }),
  );

  const assignStart = createCachedAction(TASK_ASSIGNMENT_ASSIGN_START, assignment => assignment);

  const addAssignment = createCachedAction(TASK_ASSIGNMENT_ADD, assignment => assignment);

  const getUserLabel = user => {
    let name;
    if (isGroupUser(user)) {
      name = user.username;
    } else if (user.username) {
      name = `${user.username} (${user.email})`;
    } else {
      name = user.email;
    }
    return htmlEscaped`${name}`;
  };

  const assign =
    (taskId, email, allowInvite = false) =>
    (dispatch, getState) => {
      const state = getState();

      const assignmentMembership = OrganizationMembershipSelector.getBySelectedOrganizationIdAndUserEmail(email)(state);
      if (!assignmentMembership && allowInvite) {
        return dispatch(invite(taskId, email, null, email));
      } else {
        const assignmentUser = UserSelector.getByPrimaryEmail(email)(state);

        const userLabel = getUserLabel(assignmentUser);

        const newAssignment = generateChecklistTaskAssignment(taskId, assignmentMembership);

        dispatch(assignStart(newAssignment));

        return dispatch(doAssign(taskId, email, newAssignment, userLabel));
      }
    };

  const generateChecklistTaskAssignment = (taskId, membership) => ({
    id: Muid.fromUuid(uuid()),
    task: {
      id: taskId,
    },
    organization: {
      id: membership.organization.id,
    },
    organizationMembership: membership,
    status: AssignmentStatus.Active,
  });

  const unassign = createCachedAction(
    TASK_ASSIGNMENT_UNASSIGN,
    ChecklistTaskAssignmentApi.unassign,
    (taskId, email, userLabel) => ({ taskId, email, userLabel }),
  );

  const removeAssignment = createCachedAction(TASK_ASSIGNMENT_REMOVE, assignment => assignment);

  const invite = createCachedAction(
    TASK_ASSIGNMENT_INVITE,
    ChecklistTaskAssignmentApi.invite,
    (taskId, email, userLabel) => ({
      taskId,
      email,
      userLabel,
    }),
  );

  return {
    getAllByChecklistRevisionId,
    assign,
    addAssignment,
    unassign,
    removeAssignment,
    invite,
    // these are for testing
    _assignStart: assignStart,
  };
};
