import { Muid } from '@process-street/subgrade/core';
import { DataSetColumnDef } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type UpdateColumnNameParams = {
  dataSetId: Muid;
  columnId: Muid;
  name: string;
};

type UpdateColumnNameResponse = DataSetColumnDef;

export const UpdateColumnNameMutation = {
  mutationFn: (params: UpdateColumnNameParams) =>
    axiosService
      .getAxios()
      .put(`/1/data-sets/${params.dataSetId}/columns/${params.columnId}`, {
        name: params.name,
      })
      .then(r => r.data),
};

export const useUpdateColumnNameMutation = (
  options: UseMutationOptions<UpdateColumnNameResponse, AxiosError, UpdateColumnNameParams> = {},
) => {
  return useMutation(UpdateColumnNameMutation.mutationFn, options);
};
