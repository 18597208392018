import angular from 'angular';

angular.module('frontStreetApp.filters').filter('psLinky', [
  '$sanitize',
  function ($sanitize) {
    // Regular Expressions for parsing tags and attributes
    const SURROGATE_PAIR_REGEXP = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;

    // Match everything outside of normal chars and " (quote character)
    const NON_ALPHANUMERIC_REGEXP = /([^#-~ |!])/g;

    function toMap(str, lowercaseKeys) {
      const obj = {};
      const items = str.split(',');
      let i;
      for (i = 0; i < items.length; i += 1) {
        obj[lowercaseKeys ? angular.lowercase(items[i]) : items[i]] = true;
      }
      return obj;
    }

    // Blocked Elements (will be stripped)
    const blockedElements = toMap('script,style');

    // Elements that you can, intentionally, leave open (and which close themselves)
    // http://dev.w3.org/html5/spec/Overview.html#optional-tags
    const optionalEndTagBlockElements = toMap('colgroup,dd,dt,li,p,tbody,td,tfoot,th,thead,tr');
    const optionalEndTagInlineElements = toMap('rp,rt');
    const optionalEndTagElements = angular.extend({}, optionalEndTagInlineElements, optionalEndTagBlockElements);

    // Safe Block Elements - HTML5
    const blockElements = angular.extend(
      {},
      optionalEndTagBlockElements,
      toMap(
        'address,article,' +
          'aside,blockquote,caption,center,del,dir,div,dl,figure,figcaption,footer,h1,h2,h3,h4,h5,' +
          'h6,header,hgroup,hr,ins,map,menu,nav,ol,pre,section,table,ul',
      ),
    );

    // Inline Elements - HTML5
    const inlineElements = angular.extend(
      {},
      optionalEndTagInlineElements,
      toMap(
        'a,abbr,acronym,b,' +
          'bdi,bdo,big,br,cite,code,del,dfn,em,font,i,img,ins,kbd,label,map,mark,q,ruby,rp,rt,s,' +
          'samp,small,span,strike,strong,sub,sup,time,tt,u,var',
      ),
    );

    const voidElements = toMap('area,br,col,hr,img,wbr');

    const validElements = angular.extend({}, voidElements, blockElements, inlineElements, optionalEndTagElements);

    //Attributes that have href and hence need to be sanitized
    const uriAttrs = toMap('background,cite,href,longdesc,src,xlink:href');

    const htmlAttrs = toMap(
      'abbr,align,alt,axis,bgcolor,border,cellpadding,cellspacing,class,clear,' +
        'color,cols,colspan,compact,coords,dir,face,headers,height,hreflang,hspace,' +
        'ismap,lang,language,nohref,nowrap,rel,rev,rows,rowspan,rules,' +
        'scope,scrolling,shape,size,span,start,summary,tabindex,target,title,type,' +
        'valign,value,vspace,width',
    );

    const validAttrs = angular.extend({}, uriAttrs, htmlAttrs);

    /**
     * create an HTML/XML writer which writes to buffer
     * @param {Array} buf use buf.join('') to get out sanitized html string
     * @returns {object} in the form of {
     *     start: function(tag, attrs) {},
     *     end: function(tag) {},
     *     chars: function(text) {},
     *     comment: function(text) {}
     * }
     */
    function htmlSanitizeWriter(buf, uriValidator) {
      let ignoreCurrentElement = false;
      const out = angular.bind(buf, buf.push);
      return {
        start(tag, attrs) {
          const lowercaseTag = angular.lowercase(tag);
          if (!ignoreCurrentElement && blockedElements[lowercaseTag]) {
            ignoreCurrentElement = lowercaseTag;
          }
          if (!ignoreCurrentElement && validElements[lowercaseTag] === true) {
            out('<');
            out(lowercaseTag);
            angular.forEach(attrs, (value, key) => {
              const lkey = angular.lowercase(key);
              const isImage = (lowercaseTag === 'img' && lkey === 'src') || lkey === 'background';
              if (validAttrs[lkey] === true && (uriAttrs[lkey] !== true || uriValidator(value, isImage))) {
                out(' ');
                out(key);
                out('="');
                out(encodeEntities(value));
                out('"');
              }
            });
            out('>');
          }
        },
        end(tag) {
          const lowercaseTag = angular.lowercase(tag);
          if (!ignoreCurrentElement && validElements[lowercaseTag] === true && voidElements[lowercaseTag] !== true) {
            out('</');
            out(lowercaseTag);
            out('>');
          }
          if (lowercaseTag === ignoreCurrentElement) {
            ignoreCurrentElement = false;
          }
        },
        chars(chars) {
          if (!ignoreCurrentElement) {
            out(encodeEntities(chars));
          }
        },
      };
    }

    /**
     * Escapes all potentially dangerous characters, so that the
     * resulting string can be safely inserted into attribute or
     * element text.
     * @param value
     * @returns {string} escaped text
     */
    function encodeEntities(value) {
      return value
        .replace(/&/g, '&amp;')
        .replace(SURROGATE_PAIR_REGEXP, surrogatePairValue => {
          const hi = surrogatePairValue.charCodeAt(0);
          const low = surrogatePairValue.charCodeAt(1);
          return `&#${(hi - 0xd800) * 0x400 + (low - 0xdc00) + 0x10000};`;
        })
        .replace(NON_ALPHANUMERIC_REGEXP, nonAlphanumericValue => `&#${nonAlphanumericValue.charCodeAt(0)};`)
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
    }

    const LINKY_URL_REGEXP = /((ftp|https?):\/\/|(www\.)|(mailto:)?[A-Za-z0-9._%+-]+@)\S*[^\s.;,(){}<>"\u201d\u2019]/i;
    const MAILTO_REGEXP = /^mailto:/i;

    const linkyMinErr = angular.$$minErr('linky');
    const { isString } = angular;

    return function (text, target, attributes) {
      function sanitizeText(chars) {
        const buf = [];
        const writer = htmlSanitizeWriter(buf, angular.noop);
        writer.chars(chars);
        return buf.join('');
      }

      function addText(textToAdd) {
        if (!textToAdd) {
          return;
        }
        html.push(sanitizeText(textToAdd));
      }

      function addLink(url, textToAdd) {
        let attrs = attributes;
        html.push('<a ');
        if (angular.isFunction(attrs)) {
          attrs = attrs(url);
        }
        if (angular.isObject(attrs)) {
          angular.forEach(attrs, (value, key) => {
            html.push(`${key}="${value}" `);
          });
        } else {
          attrs = {};
        }
        if (angular.isDefined(target) && !('target' in attrs)) {
          html.push('target="', target, '" ');
        }
        html.push('href="', url.replace(/"/g, '&quot;'), '">');
        addText(textToAdd);
        html.push('</a>');
      }

      if (text === null || text === undefined || text === '') {
        return text;
      }
      if (!isString(text)) {
        throw linkyMinErr('notstring', 'Expected string but received: {0}', text);
      }

      let match;
      let raw = text;
      const html = [];
      let url;
      let i;
      while ((match = raw.match(LINKY_URL_REGEXP))) {
        // We can not end in these as they are sometimes found at the end of the sentence
        [url] = match;
        // if we did not match ftp/http/www/mailto then assume mailto
        if (!match[2] && !match[4]) {
          url = (match[3] ? 'http://' : 'mailto:') + url;
        }
        i = match.index;
        addText(raw.substr(0, i));
        addLink(url, match[0].replace(MAILTO_REGEXP, ''));
        raw = raw.substring(i + match[0].length);
      }
      addText(raw);
      return $sanitize(html.join(''));
    };
  },
]);
