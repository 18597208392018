import * as React from 'react';
import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { IHeaderParams, IRowNode } from '@ag-grid-community/core';
import { useChecklistScreenSlice } from '../../../ChecklistDashboardScreen/checklist-screen-store';

export type SelectionHeaderRendererProps = Partial<IHeaderParams> & {
  checkboxProps?: CheckboxProps;
};

export const SelectionHeaderRenderer: React.FC<SelectionHeaderRendererProps> = ({ api, checkboxProps }) => {
  const { selectedNodes } = useChecklistScreenSlice();
  const selectedNodesCount = selectedNodes.length;
  const allNodesCount = api?.getDisplayedRowCount() ?? 0;
  const isAllSelected = selectedNodesCount > 0 && selectedNodesCount === allNodesCount;
  const isIndeterminate = selectedNodesCount > 0 && !isAllSelected;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const nodesToRedraw: IRowNode[] = [];

    if (e.target.checked) {
      api?.forEachNode(node => {
        if (!node.data?.id) return;

        node.setSelected(true);
        nodesToRedraw.push(node);
      });
    } else {
      selectedNodes.forEach(node => {
        if (!node.data?.id) return;

        node.setSelected(false);
        nodesToRedraw.push(node);
      });
    }

    api?.redrawRows({ rowNodes: nodesToRedraw });
  };

  return (
    <Checkbox
      isChecked={isAllSelected}
      isIndeterminate={isIndeterminate}
      colorScheme={isIndeterminate ? 'gray' : 'brand'}
      onChange={handleChange}
      size="lg"
      opacity={selectedNodes.length > 0 ? 1 : 0}
      sx={{
        '.chakra-checkbox__control': {
          borderWidth: 'thin',
        },
      }}
      {...checkboxProps}
    />
  );
};

export const ChecklistDashboardSelectionHeaderRenderer = (props: SelectionHeaderRendererProps) => (
  <SelectionHeaderRenderer
    checkboxProps={{
      _hover: {
        opacity: 1,
      },
      ml: '1px',
    }}
    {...props}
  />
);

export const FormSelectionHeaderRenderer = (props: SelectionHeaderRendererProps) => (
  <SelectionHeaderRenderer
    checkboxProps={{
      opacity: 1,
      m: 0,
      ml: '1px',
    }}
    {...props}
  />
);
