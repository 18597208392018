import { AxiosResponse } from 'axios';
import {
  TraySolution,
  TemplateSolutionInstance,
  AutomationLog,
  TrayAuthorizeConfigResponse,
} from '@process-street/subgrade/automation';
import { Muid } from '@process-street/subgrade/core';
import { axiosService } from 'services/axios-service';
import { Template } from '@process-street/subgrade/process';

const getAvailableSolutions = (templateId: Muid): Promise<TraySolution[]> =>
  axiosService
    .getAxios()
    .get(`/1/templates/${templateId}/automations/solutions`)
    .then((response: AxiosResponse) => {
      return response.data as TraySolution[];
    });

const authorizeConfig = (templateId: Muid, templateSolutionInstanceId: string): Promise<TrayAuthorizeConfigResponse> =>
  axiosService
    .getAxios()
    .post(`/1/templates/${templateId}/automations/authorize-config`, {
      templateSolutionInstanceId,
    })
    .then((response: AxiosResponse) => response.data as TrayAuthorizeConfigResponse);

const getAutomationLogsByTemplateSolutionInstanceId = (
  templateId: Template['id'],
  templateSolutionInstanceId: TemplateSolutionInstance['id'],
): Promise<AutomationLog[]> =>
  axiosService
    .getAxios()
    .get(`/1/templates/${templateId}/automations/logs/${templateSolutionInstanceId}`)
    .then((response: AxiosResponse) => response.data as AutomationLog[]);

export const AutomationApi = {
  authorizeConfig,
  getAutomationLogsByTemplateSolutionInstanceId,
  getAvailableSolutions,
};
