import { copyWidget } from 'pages/pages/_id/edit/page/utils/widget.api';
import { useMutation } from 'react-query';

export const CopyWidgetByHeaderIdMutation = {
  key: ['widget', 'copy'],
  getKey() {
    return [...CopyWidgetByHeaderIdMutation.key];
  },
  mutationFn: copyWidget,
};

export const useCopyWidgetMutation = () => {
  return useMutation(CopyWidgetByHeaderIdMutation.mutationFn, {
    mutationKey: CopyWidgetByHeaderIdMutation.getKey(),
  });
};
