import { queryClient } from 'components/react-root';
import { GetGroupMembershipsQuery } from 'features/group-memberships/query-builder';

export class GroupMembershipApi {
  constructor($q, GroupMemberships) {
    'ngInject';

    this.$q = $q;
    this.GroupMemberships = GroupMemberships;
  }

  queryGroupMembership = () =>
    this.$q((resolve, reject) =>
      queryClient
        .fetchQuery({
          queryKey: GetGroupMembershipsQuery.getKey({}),
          queryFn: () => GetGroupMembershipsQuery.queryFn({}),
          staleTime: 1000 * 60 * 5, // 5 Minutes
        })
        .then(resolve)
        .catch(reject),
    );

  queryGroupMembershipByGroup = groupId =>
    this.$q((resolve, reject) =>
      queryClient
        .fetchQuery({
          queryKey: GetGroupMembershipsQuery.getKey({ groupId }),
          queryFn: () => GetGroupMembershipsQuery.queryFn({ groupId }),
          staleTime: 1000 * 60 * 5, // 5 Minutes
        })
        .then(resolve)
        .catch(reject),
    );

  queryGroupMembershipByUser = userId =>
    this.$q((resolve, reject) =>
      queryClient
        .fetchQuery({
          queryKey: GetGroupMembershipsQuery.getKey({ userId }),
          queryFn: () => GetGroupMembershipsQuery.queryFn({ userId }),
          staleTime: 1000 * 60 * 5, // 5 Minutes
        })
        .then(resolve)
        .catch(reject),
    );

  getAllByOrganizationMembershipUserId = this.queryGroupMembershipByUser;
}
