import { match, P } from 'ts-pattern';
import { Option } from '../core';

function isString(value: any): value is string {
  return typeof value === 'string' || value instanceof String;
}

function uncapitalize<Str extends string>(str: Str): Uncapitalize<Str> {
  return `${str.charAt(0).toLowerCase()}${str.slice(1)}` as Uncapitalize<Str>;
}

function capitalize<Str extends string>(str: Str): Capitalize<Str> {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}` as Capitalize<Str>;
}

const UNIMPORTANT_TITLE_WORDS = ['of', 'the', 'and', 'in', 'for', 'to', 'a', 'an'];

function toTitleCase(str: string): string {
  return str.replace(/\w\S*/g, word => {
    return UNIMPORTANT_TITLE_WORDS.includes(word) ? word : capitalize(word);
  });
}

function containsIgnoreCase(text: Option<string>, value: string): boolean {
  if (!text) {
    return false;
  }
  return text.toLowerCase().includes((value ?? '').toLowerCase());
}

function stripAnySuffix(str: string, suffixes: string[]) {
  const applicableSuffix = suffixes.find(suffix => str.endsWith(suffix));
  return applicableSuffix ? str.slice(0, -applicableSuffix.length) : str;
}

function getReversedHeadAndTail({ value, separator = ',' }: { value: string; separator?: string }): {
  tail: Set<string>;
  head: string;
} {
  const [head = '', ...tail] = value.split(separator).reverse();
  return { tail: new Set(tail.reverse()), head };
}

function escapeRegExp(str: string): string {
  // Adapted from: https://stackoverflow.com/a/9310752/62571
  return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'); // $& means the whole matched string
}

const NBSP = String.fromCharCode(160);

export const getSentenceList = ({
  items,
  prefix = '',
  suffix = '',
  fallback = '',
  max,
}: {
  items: string[];
  prefix?: string;
  suffix?: string;
  fallback?: string;
  max?: number;
}): string => {
  const named = match(max)
    .with(undefined, () => items)
    .otherwise(max => {
      const shownNames = items.slice(0, max);
      const shownCount = shownNames.length;
      const othersCount = items.slice(max).length;

      return match([shownCount, othersCount])
        .with([0, 0], () => [])
        .with([P.number, 0], () => shownNames)
        .otherwise(() => [...items.slice(0, max), `${othersCount} ${othersCount === 1 ? 'other' : 'others'}`]);
    });

  const names = match(named.length)
    .with(1, () => named[0])
    .with(2, () => named.join(' and '))
    .when(
      count => count >= 3,
      () => {
        const copy = named.slice();
        const last = copy.pop();
        return copy.join(', ') + ', and ' + last;
      },
    )
    .otherwise(() => '');
  if (names) {
    return `${prefix}${names}${suffix}`;
  }
  return fallback;
};

/** Returns true if string is empty or contains only whitespace. */
const isBlank = (str?: string): boolean => (str ?? '').trim() === '';

const getFirstNonEmptyString = (...strings: (string | undefined | null)[]) =>
  strings.find(str => !isBlank(str ?? '')) ?? '';

export const StringUtils = {
  UNIMPORTANT_TITLE_WORDS,
  capitalize,
  containsIgnoreCase,
  getReversedHeadAndTail,
  getSentenceList,
  isString,
  isBlank,
  stripAnySuffix,
  toTitleCase,
  uncapitalize,
  escapeRegExp,
  NBSP,
  getNonEmpty: getFirstNonEmptyString,
};
