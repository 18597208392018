import { Comment } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateCommentMutationParams = {
  commentId: Comment['id'];
  audit: Partial<Comment['audit']>;
  content: Comment['content'];
};

export type UpdateCommentMutationResponse = Comment;

export const UpdateCommentMutation = {
  key: ['put', 'comment'],
  mutationFn: (params: UpdateCommentMutationParams) =>
    axiosService
      .getAxios()
      .put<UpdateCommentMutationResponse>(`/1/comments/${params.commentId}`, params)
      .then(res => res.data),
};

export const useUpdateCommentMutation = (
  options: UseMutationOptions<UpdateCommentMutationResponse, AxiosError, UpdateCommentMutationParams> = {},
) => {
  return useMutation(UpdateCommentMutation.mutationFn, options);
};
