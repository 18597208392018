import angular from 'angular';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.controllers')
  .controller('TemplateUnsubscribeCtrl', function ($scope, $state, TemplateSubscriptions) {
    if ($state.params.id) {
      TemplateSubscriptions.delete({
        id: $state.params.id,
      }).$promise.then(
        () => {
          AnalyticsService.trackEvent('template subscription deleted');
          $scope.outcome = 'success';
        },
        response => {
          switch (response.status) {
            case 404:
              $scope.outcome = 'success';
              break;
            default:
              $scope.outcome = 'failure';
          }
        },
      );
    } else {
      $scope.outcome = 'failure';
    }

    $scope.redirect = function () {
      $state.go('dashboard');
    };
  });
