// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../images/task-template/role-based_icon.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<span class=\"role-assignment-upgrade\"> <div class=\"row\"> <div class=\"col-xs-12 explanation-box-image\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </div> </div> <div class=\"row\"> <h5 class=\"col-xs-12\"> <span class=\"upgrade-title\"> <span>Role Assignments</span> <span class=\"badge badge-success ml-1\">Standard</span> </span> </h5> </div> <div class=\"row\"> <div class=\"col-xs-12\"> <p class=\"upgrade-description\"> Upgrade to dynamically assign tasks based on roles. <a href=\"https://www.process.st/help/docs/role-assignments/\" target=\"_blank\" rel=\"noopener noreferrer\">Learn more &raquo;</a> </p> </div> </div> <div class=\"row\"> <div class=\"col-xs-12 upgrade-btn-box\"> <a class=\"btn btn-primary\" data-ng-if=\"$ctrl.state.userIsAdmin\" data-ng-click=\"$ctrl.close()\" data-ui-sref=\"organizationManage.tab({ tab: 'billing' })\" data-ui-sref-opts=\"{ reload: true }\"> Upgrade </a> <p ng-if=\"!$ctrl.state.userIsAdmin\" class=\"upgrade-description\">Contact an administrator to upgrade your plan.</p> </div> </div> </span> ";
// Exports
var _module_exports = code;;
var path = '/components/role-assignments/upgrade/role-assignment-upgrade.component.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;