import angular from 'angular';
import { uuid } from 'services/uuid';
import { trace } from 'components/trace';

angular.module('frontStreetApp.services').service('PromiseQueueService', function ($q) {
  const logger = trace({ name: 'PromiseQueueService' });

  const self = this;

  const promiseMap = {};
  const promiseQueueCounterMap = {};

  /**
   * Puts promise in a queue by key
   *
   * @param {string} key - Key of the queue
   * @param {Function} promiseWrapper - Promise to put in the queue
   * @param {string} [description]
   * @returns {Promise}
   */
  self.enqueue = function (key, promiseWrapper, description) {
    const id = uuid();
    const resolution = $q.defer();

    logger.info('new queued promise for key "%s" with id "%s": %s', key, id, description || '<no description>');

    if (!promiseMap[key]) {
      promiseMap[key] = $q.resolve();
      promiseQueueCounterMap[key] = 0;
    }

    promiseQueueCounterMap[key] += 1;

    promiseMap[key] = promiseMap[key]
      .finally(() => {
        logger.info('started "%s" in queue[%s]', id, key);

        return promiseWrapper().then(
          response => {
            logger.info('succeeded "%s" in queue[%s]', id, key);

            resolution.resolve(response);
          },
          response => {
            logger.warn('failed "%s" in queue[%s]', id, key);

            resolution.reject(response);
          },
        );
      })
      .finally(() => {
        promiseQueueCounterMap[key] -= 1;
        logger.info(`queue[${key}]: ${promiseQueueCounterMap[key]}`);
      });

    return resolution.promise;
  };

  /**
   * Checks if any of queue has promises to complete
   * @param {string} key queue key
   *
   * @returns {boolean}
   */
  self.isInProgressByKey = function (key) {
    return promiseQueueCounterMap && promiseQueueCounterMap[key] > 0;
  };

  /**
   * Checks if any of queue has promises to complete
   *
   * @returns {boolean}
   */
  self.hasAnyInProgress = function () {
    let foundNonEmptyQueue = false;
    angular.forEach(promiseQueueCounterMap, promiseQueueCounter => {
      if (promiseQueueCounter > 0) {
        foundNonEmptyQueue = true;
      }
    });

    return foundNonEmptyQueue;
  };
});
