import * as React from 'react';
import { useConditionalLogicModalStore } from '../modal/store';
import { useFunctionRef } from 'hooks/use-function-ref';

export function useAutoCreateRule({ addRule, isEmpty }: { addRule?: () => void; isEmpty: boolean }) {
  const addRuleRef = useFunctionRef(addRule);
  const { shouldAutoCreateNewRule, dispatch } = useConditionalLogicModalStore();

  React.useEffect(() => {
    if (shouldAutoCreateNewRule) {
      if (isEmpty) {
        addRuleRef.current?.();
      }
      dispatch({ type: 'CLEAR_AUTO_CREATE_NEW_RULE' });
    }
  }, [addRuleRef, dispatch, isEmpty, shouldAutoCreateNewRule]);
}
