import { UseToastOptions } from '@chakra-ui/react';
import { match, P } from 'ts-pattern';
import { HttpStatus } from '@process-street/subgrade/util';
import { ConflictResultType, FormFieldWidget } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { AxiosError } from 'axios';

export namespace UpdateErrorToasts {
  /**
   * Information about when the next time-based CL blocked task would appear.
   */
  interface TimeBasedTaskStop {
    date: number;
    taskCount: number;
  }

  interface ConflictErrorResponseFields {
    conflictType?: ConflictResultType;
    invalidFormFields?: FormFieldWidget[];
    message?: string;
    // We used to display this in error toasts.
    timeBasedTaskStop?: TimeBasedTaskStop;
  }

  type ConflictErrorResponse = AxiosError<ConflictErrorResponseFields>['response'];

  export const getToastOptions = (
    errorResponse: ConflictErrorResponse,
    conflictTitle: string,
    genericTitle: string,
  ): UseToastOptions => {
    return match<typeof errorResponse | undefined, Pick<UseToastOptions, 'status' | 'title' | 'description'>>(
      errorResponse,
    )
      .with({ status: HttpStatus.CONFLICT, data: { conflictType: ConflictResultType.StopTaskRequired } }, () => ({
        status: 'warning',
        title: conflictTitle,
        description: 'At least one stop task is not completed.',
      }))
      .with(
        {
          status: HttpStatus.CONFLICT,
          data: { conflictType: ConflictResultType.TimeBasedRuleRequired, timeBasedTaskStop: P.any },
        },
        () => ({
          status: 'warning',
          title: 'The workflow run can’t be completed yet…',
          description: 'More tasks coming soon…',
        }),
      )
      .with(
        {
          status: HttpStatus.CONFLICT,
          data: { conflictType: ConflictResultType.InvalidFormFields, invalidFormFields: P.array(P.any) },
        },
        res => ({
          status: 'warning',
          title: conflictTitle,
          description: `${res.data.invalidFormFields.length} field(s) still need to be completed.`,
        }),
      )
      .otherwise(() => ({
        status: 'error',
        title: genericTitle,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      }));
  };

  export const getWorkflowUpdateErrorToastOptions = (error: ConflictErrorResponse) =>
    getToastOptions(error, "We couldn't update the workflow run.", "We're having problems updating the workflow run.");

  export const getTaskUpdateErrorToastOptions = (error: ConflictErrorResponse) =>
    getToastOptions(error, "We couldn't update the task.", "We're having problems updating the task.");
}
