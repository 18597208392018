import angular from 'angular';

angular.module('frontStreetApp.services').factory('Plans', function (ResourceFactory) {
  return ResourceFactory.create({
    getAll: 'GET[] /1/plans',
    create: 'POST /1/plans',
    getById: 'GET /1/plans/:id',
    update: 'PATCH /1/plans/:id',
    makeDefault: 'POST /1/plans/:id/default',
    delete: 'DELETE /1/plans/:id',
  });
});
