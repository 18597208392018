import { AccessLevel } from '@process-street/subgrade/permission/permit-constants';
import { TemplatePermit } from '@process-street/subgrade/permission/permission-model';

export interface TemplatePermitDelta {
  templateRead: boolean;
  templateUpdate: boolean;
  templateDelete: boolean;
  pageRead: boolean;
  pageUpdate: boolean;
  pageDelete: boolean;
  checklistCreate: boolean;
  checklistRead: boolean;
  checklistUpdate: boolean;
  checklistDelete: boolean;
  doodadCreate: boolean;
  doodadRead: boolean;
  doodadUpdate: boolean;
  doodadDelete: boolean;
}

const emptyTemplatePermit: TemplatePermitDelta = {
  templateRead: false,
  templateUpdate: false,
  templateDelete: false,
  pageRead: false,
  pageUpdate: false,
  pageDelete: false,
  checklistCreate: false,
  checklistRead: false,
  checklistUpdate: false,
  checklistDelete: false,
  doodadCreate: false,
  doodadRead: false,
  doodadUpdate: false,
  doodadDelete: false,
};

const workflowEditPermissions = {
  ...emptyTemplatePermit,
  templateRead: true,
  templateUpdate: true,
  templateDelete: true,
  checklistCreate: true,
  checklistRead: true,
  checklistUpdate: true,
  checklistDelete: true,
  doodadCreate: true,
  doodadRead: true,
  doodadUpdate: true,
};

const workflowEditViewOwnPermissions = {
  ...emptyTemplatePermit,
  templateRead: true,
  templateUpdate: true,
  templateDelete: true,
  checklistCreate: true,
};

const legacyWorkflowEditPermissions = {
  ...emptyTemplatePermit,
  templateRead: true,
  templateUpdate: true,
  checklistCreate: true,
  checklistRead: true,
  checklistUpdate: true,
  doodadCreate: true,
  doodadRead: true,
  doodadUpdate: true,
};

const workflowViewAllPermissions = {
  ...emptyTemplatePermit,
  templateRead: true,
  checklistCreate: true,
  checklistRead: true,
  checklistUpdate: true,
  doodadCreate: true,
  doodadRead: true,
};

const workflowRunPermissions = {
  ...emptyTemplatePermit,
  templateRead: true,
  checklistCreate: true,
};

const workflowViewPermissions = {
  ...emptyTemplatePermit,
  templateRead: true,
};

const pageEditPermissions = {
  ...emptyTemplatePermit,
  pageRead: true,
  pageUpdate: true,
  pageDelete: true,
};

const pageViewPermissions = {
  ...emptyTemplatePermit,
  pageRead: true,
};

const formEditPermissions = {
  ...emptyTemplatePermit,
  templateRead: true,
  templateUpdate: true,
  templateDelete: true,
  checklistCreate: true,
  checklistRead: true,
  checklistUpdate: true,
  checklistDelete: true,
};

const formEditViewOwnPermissions = {
  ...emptyTemplatePermit,
  templateRead: true,
  templateUpdate: true,
  templateDelete: true,
  checklistCreate: true,
};

const formViewAllPermissions = {
  ...emptyTemplatePermit,
  templateRead: true,
  checklistCreate: true,
  checklistRead: true,
  checklistUpdate: true,
};

const formViewPermissions = {
  ...emptyTemplatePermit,
  templateRead: true,
};

const formRespondPermissions = {
  ...emptyTemplatePermit,
  templateRead: true,
  checklistCreate: true,
};

/**
 * Forms created before releasing new form permits Dec 2023, were created with same permits as Wokflows.
 * For example, they'll have permits over Doads, which does not exists on Forms. Adding this to keep compatibility for
 * Forms created before that date, until we run a script in the DB.
 */
const legacyFormEditPermissions = {
  ...emptyTemplatePermit,
  templateRead: true,
  templateUpdate: true,
  templateDelete: true,
  checklistCreate: true,
  checklistRead: true,
  checklistUpdate: true,
  checklistDelete: true,
  doodadCreate: true,
  doodadRead: true,
  doodadUpdate: true,
};

const legacyFormViewAllPermissions = {
  ...emptyTemplatePermit,
  templateRead: true,
  checklistCreate: true,
  checklistRead: true,
  checklistUpdate: true,
  doodadCreate: true,
  doodadRead: true,
};

export const workflowTemplatePermitLookup: { [index in AccessLevel]?: TemplatePermitDelta } = {
  [AccessLevel.WORKFLOW_EDIT]: workflowEditPermissions,
  [AccessLevel.WORKFLOW_EDIT_VIEW_OWN]: workflowEditViewOwnPermissions,
  [AccessLevel.WORKFLOW_VIEW_ALL]: workflowViewAllPermissions,
  [AccessLevel.WORKFLOW_RUN]: workflowRunPermissions,
  [AccessLevel.WORKFLOW_VIEW]: workflowViewPermissions,
  [AccessLevel.PAGE_EDIT]: pageEditPermissions,
  [AccessLevel.PAGE_VIEW]: pageViewPermissions,
};

const legacyWorkflowTemplatePermitLookup: { [index in AccessLevel]?: TemplatePermitDelta } = {
  [AccessLevel.WORKFLOW_EDIT]: legacyWorkflowEditPermissions,
};

const formTemplatePermitLookup: { [index in AccessLevel]?: TemplatePermitDelta } = {
  [AccessLevel.FORM_EDIT]: formEditPermissions,
  [AccessLevel.FORM_EDIT_VIEW_OWN]: formEditViewOwnPermissions,
  [AccessLevel.FORM_VIEW_ALL]: formViewAllPermissions,
  [AccessLevel.FORM_VIEW]: formViewPermissions,
  [AccessLevel.FORM_RESPOND]: formRespondPermissions,
};

const legacyFormTemplatePermitLookup: { [index in AccessLevel]?: TemplatePermitDelta } = {
  [AccessLevel.FORM_EDIT]: legacyFormEditPermissions,
  [AccessLevel.FORM_VIEW_ALL]: legacyFormViewAllPermissions,
};

const workflowTemplateAccessLevels = [
  AccessLevel.WORKFLOW_EDIT,
  AccessLevel.WORKFLOW_EDIT_VIEW_OWN,
  AccessLevel.WORKFLOW_VIEW_ALL,
  AccessLevel.WORKFLOW_RUN,
  AccessLevel.WORKFLOW_VIEW,
  AccessLevel.PAGE_EDIT,
  AccessLevel.PAGE_VIEW,
];

const formTemplateAccessLevels = [
  AccessLevel.FORM_EDIT,
  AccessLevel.FORM_EDIT_VIEW_OWN,
  AccessLevel.FORM_VIEW_ALL,
  AccessLevel.FORM_VIEW,
  AccessLevel.FORM_RESPOND,
];

const getKeys = (permissions: TemplatePermitDelta) => Object.keys(permissions) as Array<keyof TemplatePermitDelta>;

const hasWorkflowTemplateAccessLevel = (permit: TemplatePermit, level: AccessLevel) => {
  const permissions = workflowTemplatePermitLookup[level];
  const legacyPermissions = legacyWorkflowTemplatePermitLookup[level];

  const hasLevel = permissions && getKeys(permissions).every(key => permissions[key] === permit[key]);
  const hasLegacyLevel =
    legacyPermissions && getKeys(legacyPermissions).every(key => legacyPermissions[key] === permit[key]);

  return hasLevel || hasLegacyLevel;
};

const findWorkflowTemplateAccessLevel = (permit: TemplatePermit) =>
  workflowTemplateAccessLevels.find(level => hasWorkflowTemplateAccessLevel(permit, level));

const hasFormTemplateAccessLevel = (permit: TemplatePermit, level: AccessLevel) => {
  const permissions = formTemplatePermitLookup[level];
  const legacyPermissions = legacyFormTemplatePermitLookup[level];

  const hasLevel = permissions && getKeys(permissions).every(key => permissions[key] === permit[key]);
  const hasLegacyLevel =
    legacyPermissions && getKeys(legacyPermissions).every(key => legacyPermissions[key] === permit[key]);

  return hasLevel || hasLegacyLevel;
};

const findFormTemplateAccessLevel = (permit: TemplatePermit) =>
  formTemplateAccessLevels.find(level => hasFormTemplateAccessLevel(permit, level));

export const TemplatePermitResolver = {
  findWorkflowTemplateAccessLevel,
  findFormTemplateAccessLevel,
  workflowTemplatePermitLookup,
};
