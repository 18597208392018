import angular from 'angular';
import { getEnv } from 'components/common/env';

angular.module('frontStreetApp.directives').directive('psWebHref', () => ({
  link(__scope, element, attributes) {
    element.attr({
      href: `${getEnv().APP_WEB_URL}/${attributes.psWebHref}`,
      target: '_blank',
      rel: 'noopener noreferrer',
    });
  },
}));
