import { isAdmin, SubscriptionStatus } from '@process-street/subgrade/core';
import { PaywallSettings, UserSettings } from '@process-street/subgrade/core/user-settings-model';
import { useInjector } from 'components/injection-provider';
import { useIsStartupPlan } from 'hooks/use-is-startup-plan';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { features } from 'services/features/features';
import { useCanUseOrganization, useUserSettings } from './query';
import { TrialExpiredPaywallType } from './ui';

export const useShouldBlockApp = (): {
  shouldBlock: boolean;
  type: TrialExpiredPaywallType;
  isLoading: boolean;
} => {
  const { $state } = useInjector('$state');
  const plan = useSelector(SessionSelector.getCurrentPlan);
  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const userInfo = useSelector(SessionSelector.getUserInfo);
  const isStartupPlan = useIsStartupPlan(plan);

  const isFreemiumTrack = !!plan && features.isFreemiumTrack(plan.id);

  const userSettingsQuery = useUserSettings<PaywallSettings>(UserSettings.Paywalls.key, UserSettings.Paywalls.defaults);
  const canUseOrganizationQuery = useCanUseOrganization();

  const protectedRoute = $state.current.name !== 'organizationManage.tab';

  if (isStartupPlan) {
    const isUserAdmin = !!userInfo && isAdmin(userInfo.organizationMembership.role);

    const shouldBlock =
      selectedOrganization?.subscription.status === SubscriptionStatus.Canceled &&
      (protectedRoute || $state.params.showTrialExpiredPaywall === 'true');

    return {
      shouldBlock,
      type: isUserAdmin ? TrialExpiredPaywallType.BlockAdmin : TrialExpiredPaywallType.BlockNonAdmin,
      isLoading: userSettingsQuery.isLoading || canUseOrganizationQuery.isLoading,
    };
  } else {
    const blockedBasedOnOverages = Boolean(
      isFreemiumTrack && protectedRoute && canUseOrganizationQuery.data && !canUseOrganizationQuery.data.canUse,
    );

    const blockedBasedOnTrialExpired = Boolean(
      isFreemiumTrack &&
        protectedRoute &&
        selectedOrganization &&
        userSettingsQuery.data &&
        userSettingsQuery.data.displayTrialExpiredPaywallForOrgIds.includes(selectedOrganization.id),
    );

    return {
      shouldBlock: blockedBasedOnOverages || blockedBasedOnTrialExpired,
      type: blockedBasedOnOverages ? TrialExpiredPaywallType.Overages : TrialExpiredPaywallType.NoOverages,
      isLoading: userSettingsQuery.isLoading || canUseOrganizationQuery.isLoading,
    };
  }
};
