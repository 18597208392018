import { Muid } from '@process-street/subgrade/core';
import { Task } from '@process-street/subgrade/process';
import { TaskAssignment } from '@process-street/subgrade/role-assignment';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace DeleteTaskAssignmentMutation {
  export type Params = {
    taskId: Task['id'];
    assignmentId: Muid;
  };

  export type Response = TaskAssignment;

  export const key = ['delete', 'taskAssignment'];

  export const mutationFn = ({ taskId, assignmentId }: Params) =>
    axiosService
      .getAxios()
      .delete<Response>(`/1/tasks/${taskId}/assignments/${assignmentId}`)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
