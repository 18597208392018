import { Approval, ApprovalStatus } from '@process-street/subgrade/approval-rule/approval.model';
import { Muid, Option } from '@process-street/subgrade/core';
import { TaskStatus } from '@process-street/subgrade/process';
import { BaseApprovalSelector, BaseTaskSelector } from '@process-street/subgrade/redux/selector';
import { BaseReduxState, ObjectMap } from '@process-street/subgrade/redux/types';
import { Selector } from 'reselect';
import { OptimisticResult } from './model';
import { applyStatusUpdateWithBuilder } from './optimistic-task';
import { OptimisticResultBuilder } from './util/optimistic-result-builder';

const updateApprovals =
  (approvalTaskId: Muid, checklistRevisionId: Muid, approvals: Approval[], resultBuilder: OptimisticResultBuilder) =>
  (state: BaseReduxState) => {
    const subjectTaskIdToApprovalMap: ObjectMap<Approval> =
      BaseApprovalSelector.getSubjectTaskIdToApprovalMapByApprovalTaskId(checklistRevisionId, approvalTaskId)(state);

    approvals.forEach(approval => {
      if (subjectTaskIdToApprovalMap[approval.subjectTaskId]) {
        const originalApproval: Approval = subjectTaskIdToApprovalMap[approval.subjectTaskId];
        const updatedApproval: Approval = {
          ...originalApproval,
          comment: approval.comment,
          reviewedById: approval.reviewedById,
          status: approval.status,
        };
        resultBuilder.approval.appendUpdateEvent(updatedApproval, originalApproval);
      } else {
        resultBuilder.approval.appendCreateEvent(approval);
      }
    });
  };

const updateSubjectTasks = (approvals: Approval[], resultBuilder: OptimisticResultBuilder, state: BaseReduxState) => {
  approvals.forEach((approval: Approval) => {
    if (approval.status === ApprovalStatus.Rejected) {
      applyStatusUpdateWithBuilder(approval.subjectTaskId, TaskStatus.NotCompleted, resultBuilder)(state);
    }
  });
};

const updateApprovalTasks = (
  approvalTaskId: Muid,
  approvals: Approval[],
  resultBuilder: OptimisticResultBuilder,
  state: BaseReduxState,
) => {
  const anyRejected = approvals.some(a => a.status === ApprovalStatus.Rejected);
  if (anyRejected) {
    applyStatusUpdateWithBuilder(approvalTaskId, TaskStatus.NotCompleted, resultBuilder)(state);
    return;
  }

  const approvalTaskGroupsMap = BaseApprovalSelector.getApprovalTaskGroupsMapByApprovalTaskId(approvalTaskId)(state);

  if (approvalTaskGroupsMap) {
    const anyNotSubmitted = approvalTaskGroupsMap.notSubmittedTasks.length > 0;
    if (anyNotSubmitted) {
      applyStatusUpdateWithBuilder(approvalTaskId, TaskStatus.NotCompleted, resultBuilder)(state);
      return;
    }

    // If we had any rejected approval we would finish earlier
    const approvedSubjectTaskIds = approvals.map(approval => approval.subjectTaskId);
    const anyAwaiting = approvalTaskGroupsMap.awaitingTasks.some(task => !approvedSubjectTaskIds.includes(task.id));
    const anyNotPermitted = BaseApprovalSelector.areNotPermittedTasksExistByApprovalTaskId(approvalTaskId)(state);

    const status = anyAwaiting || anyNotPermitted ? TaskStatus.NotCompleted : TaskStatus.Completed;
    applyStatusUpdateWithBuilder(approvalTaskId, status, resultBuilder)(state);
  }
};

const getOverlappingApprovals = (
  checklistRevisionId: Muid,
  approvals: Approval[],
  state: BaseReduxState,
): Approval[] => {
  const subjectTaskIdToApprovalsMap =
    BaseApprovalSelector.getSubjectTaskIdToApprovalsMapByChecklistRevisionId(checklistRevisionId)(state);

  const rejectedSubjectTaskIds = new Set(
    approvals.filter(a => a.status === ApprovalStatus.Rejected).map(a => a.subjectTaskId),
  );

  return Array.from(rejectedSubjectTaskIds).reduce((arr: Approval[], subjectTaskId: Muid) => {
    (subjectTaskIdToApprovalsMap[subjectTaskId] || [])
      .filter((a: Approval) => a.status === ApprovalStatus.Approved)
      .forEach(a => arr.push(a));
    return arr;
  }, [] as Approval[]);
};

/**
 * Error result of Optmistic Rules Engine is ignored.
 * Side-effects of approving are:
 * - on reject:
 * -- creating an rejected approval and uncompleting the subject task (should not result in error).
 * -- rejecting approvals of overlapping approvals (share same subject task id)
 * -- uncompleting approval tasks if completed
 * - on approve: creating an approved approval (should not result in error).
 * -- when all subject tasks approved - completing approval task (should not result in error as there is no widgets).
 * -- when all tasks are completed - complete checklist (can result in error but should not prevent approval).
 *
 * @param checklistRevisionId
 * @param approvals
 */
const upsertAll =
  (checklistRevisionId: Muid, approvals: Approval[]): Selector<BaseReduxState, OptimisticResult> =>
  (state: BaseReduxState) => {
    const overlappingApprovalsToReject = getOverlappingApprovals(checklistRevisionId, approvals, state).map(
      (a: Approval) => ({ ...a, status: ApprovalStatus.Rejected }),
    );
    const approvalsToUpsert = [...approvals, ...overlappingApprovalsToReject];

    const approvalTaskIdToApprovalsWithChecklistRevisionIdMap = approvalsToUpsert.reduce(
      (map: ObjectMap<Approval[]>, approval: Approval) => {
        if (!map[approval.approvalTaskId]) {
          map[approval.approvalTaskId] = [];
        }
        map[approval.approvalTaskId].push({
          ...approval,
          checklistRevisionId,
        });
        return map;
      },
      {} as ObjectMap<Approval[]>,
    );

    const resultBuilder = new OptimisticResultBuilder(false);

    const approvalTaskIds = new Set(approvalsToUpsert.map(approval => approval.approvalTaskId));
    approvalTaskIds.forEach(approvalTaskId => {
      const approvalsWithChecklistRevisionId = approvalTaskIdToApprovalsWithChecklistRevisionIdMap[approvalTaskId];
      updateApprovals(approvalTaskId, checklistRevisionId, approvalsWithChecklistRevisionId, resultBuilder)(state);
      updateSubjectTasks(approvalsWithChecklistRevisionId, resultBuilder, state);
      updateApprovalTasks(approvalTaskId, approvalsWithChecklistRevisionId, resultBuilder, state);
    });

    return resultBuilder.build();
  };

const upsertAllByApprovalTaskId =
  (approvalTaskId: Muid, approvals: Approval[]): Selector<BaseReduxState, Option<OptimisticResult>> =>
  (state: BaseReduxState) => {
    const approvalTask = BaseTaskSelector.getById(approvalTaskId)(state);
    if (!approvalTask) {
      return undefined;
    }

    return upsertAll(approvalTask.checklistRevision.id, approvals)(state);
  };

export interface OptimisticApprovalResult {
  approvals: Approval[];
  optimisticResult: Option<OptimisticResult | Error>;
}

export const OptimisticApproval = {
  upsertAll,
  upsertAllByApprovalTaskId,
};
