import { FieldType } from '../process';
import { ChecklistRuleDefinitionOperator } from './model';

export const OperatorNameMap = {
  default: {
    [ChecklistRuleDefinitionOperator.Is]: 'is',
    [ChecklistRuleDefinitionOperator.IsNot]: 'is not',
    [ChecklistRuleDefinitionOperator.IsGreaterThan]: 'is greater than',
    [ChecklistRuleDefinitionOperator.IsLessThan]: 'is less than',
    [ChecklistRuleDefinitionOperator.StartsWith]: 'starts with',
    [ChecklistRuleDefinitionOperator.EndsWith]: 'ends with',
    [ChecklistRuleDefinitionOperator.Contains]: 'contains',
    [ChecklistRuleDefinitionOperator.DoesNotContain]: 'does not contain',
    [ChecklistRuleDefinitionOperator.HasNoValue]: 'has no value',
    [ChecklistRuleDefinitionOperator.HasAnyValue]: 'has any value',
    [ChecklistRuleDefinitionOperator.TaskStatusIs]: 'is',
    [ChecklistRuleDefinitionOperator.PeriodAfter]: 'was',
  },
  [FieldType.MultiChoice]: {
    [ChecklistRuleDefinitionOperator.Is]: 'is exactly',
    [ChecklistRuleDefinitionOperator.Contains]: 'has any of',
    [ChecklistRuleDefinitionOperator.DoesNotContain]: 'has none of',
    [ChecklistRuleDefinitionOperator.HasNoValue]: 'is empty',
    [ChecklistRuleDefinitionOperator.HasAnyValue]: 'is not empty',
  },
  [FieldType.Number]: {
    [ChecklistRuleDefinitionOperator.IsGreaterThan]: 'is greater than',
    [ChecklistRuleDefinitionOperator.IsLessThan]: 'is less than',
    [ChecklistRuleDefinitionOperator.Is]: 'is equal to',
    [ChecklistRuleDefinitionOperator.IsNot]: 'is not equal to',
    [ChecklistRuleDefinitionOperator.HasNoValue]: 'is empty',
    [ChecklistRuleDefinitionOperator.HasAnyValue]: 'is not empty',
  },
  [FieldType.Date]: {
    [ChecklistRuleDefinitionOperator.IsGreaterThan]: 'is after',
    [ChecklistRuleDefinitionOperator.IsLessThan]: 'is before',
    [ChecklistRuleDefinitionOperator.Is]: 'is equal to',
    [ChecklistRuleDefinitionOperator.IsNot]: 'is not equal to',
    [ChecklistRuleDefinitionOperator.HasNoValue]: 'is empty',
    [ChecklistRuleDefinitionOperator.HasAnyValue]: 'is not empty',
  },
  [FieldType.SendRichEmail]: {
    [ChecklistRuleDefinitionOperator.HasNoValue]: 'is not sent',
    [ChecklistRuleDefinitionOperator.HasAnyValue]: 'is sent',
  },
  [FieldType.Members]: {
    [ChecklistRuleDefinitionOperator.Is]: 'is',
    [ChecklistRuleDefinitionOperator.HasNoValue]: 'is empty',
    [ChecklistRuleDefinitionOperator.HasAnyValue]: 'is not empty',
  },
};

export enum OperandFieldType {
  STRING = 'string',
  DATE = 'date',
  SELECT = 'select',
  MULTI_CHOICE = 'multi_choice',
}

export enum OperandType {
  STRING = 'String',
  STRING_LIST = 'StringList',
  DATE_TIME = 'DateTime',
  INTEGER = 'Integer',
}

export enum TaskVisibility {
  SHOW = 'show',
  HIDE = 'hide',
}

export const TaskVisibilityNameMap = {
  [TaskVisibility.SHOW]: 'show',
  [TaskVisibility.HIDE]: 'hide',
};

export const FieldTypeToOperatorListMap = {
  default: [
    ChecklistRuleDefinitionOperator.Is,
    ChecklistRuleDefinitionOperator.IsNot,
    ChecklistRuleDefinitionOperator.StartsWith,
    ChecklistRuleDefinitionOperator.EndsWith,
    ChecklistRuleDefinitionOperator.Contains,
    ChecklistRuleDefinitionOperator.DoesNotContain,
    ChecklistRuleDefinitionOperator.HasNoValue,
    ChecklistRuleDefinitionOperator.HasAnyValue,
  ],
  task: [ChecklistRuleDefinitionOperator.TaskStatusIs],
  timeSource: [ChecklistRuleDefinitionOperator.PeriodAfter],
  [FieldType.Select]: [
    ChecklistRuleDefinitionOperator.Is,
    ChecklistRuleDefinitionOperator.IsNot,
    ChecklistRuleDefinitionOperator.HasNoValue,
    ChecklistRuleDefinitionOperator.HasAnyValue,
  ],
  [FieldType.MultiChoice]: [
    ChecklistRuleDefinitionOperator.Is,
    ChecklistRuleDefinitionOperator.Contains,
    ChecklistRuleDefinitionOperator.DoesNotContain,
    ChecklistRuleDefinitionOperator.HasNoValue,
    ChecklistRuleDefinitionOperator.HasAnyValue,
  ],
  [FieldType.Date]: [
    ChecklistRuleDefinitionOperator.IsGreaterThan,
    ChecklistRuleDefinitionOperator.IsLessThan,
    ChecklistRuleDefinitionOperator.Is,
    ChecklistRuleDefinitionOperator.IsNot,
    ChecklistRuleDefinitionOperator.HasNoValue,
    ChecklistRuleDefinitionOperator.HasAnyValue,
  ],
  [FieldType.Number]: [
    ChecklistRuleDefinitionOperator.IsGreaterThan,
    ChecklistRuleDefinitionOperator.IsLessThan,
    ChecklistRuleDefinitionOperator.Is,
    ChecklistRuleDefinitionOperator.IsNot,
    ChecklistRuleDefinitionOperator.HasNoValue,
    ChecklistRuleDefinitionOperator.HasAnyValue,
  ],
  [FieldType.SendRichEmail]: [ChecklistRuleDefinitionOperator.HasNoValue, ChecklistRuleDefinitionOperator.HasAnyValue],
  [FieldType.Members]: [
    ChecklistRuleDefinitionOperator.Is,
    ChecklistRuleDefinitionOperator.HasNoValue,
    ChecklistRuleDefinitionOperator.HasAnyValue,
  ],
};

export const RuleConstants = {
  OperandFieldType,
  OperandType,
  OperatorNameMap,
  TaskVisibility,
  TaskVisibilityNameMap,
  FieldTypeToOperatorListMap,
};
