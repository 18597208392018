import { IComponentController, IComponentOptions, IFormController } from 'angular';
import templateUrl from './prompt-override.component.html';
import { LocalStorageService } from 'features/storage/local-storage-service';
import { AI_WORKFLOW_PROMPT_OVERRIDE } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/wrapper';
import cloneDeep from 'lodash.clonedeep';

export type ChatCompletionSettings = {
  model: string;
  temperature: number;
  maxTokens: number | null;
  presencePenalty: number;
  frequencyPenalty: number;
};

export type PromptOverride = {
  prompt: string;
  chatCompletionSettings: ChatCompletionSettings;
};

export type PromptOverrideLookup = {
  approvalSubjects?: PromptOverride;
  formFields?: PromptOverride;
  taskList?: PromptOverride;
  taskProperties?: PromptOverride;
};

const DEFAULT_PROMPT_OVERRIDE: PromptOverride = {
  prompt: '',
  chatCompletionSettings: {
    model: 'gpt-3.5-turbo',
    temperature: 1,
    maxTokens: null,
    presencePenalty: 0,
    frequencyPenalty: 0,
  },
};

export const PromptOverrideComponent: IComponentOptions = {
  templateUrl,
  bindings: {
    key: '<',
  },
  // eslint-disable-next-line object-shorthand
  controller: function (
    this: IComponentController & {
      // Bindings
      key: keyof PromptOverrideLookup;
      // Local
      promptOverride: PromptOverride;
      promptEnabled: boolean;
    },
  ) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias -- old ctrl
    const ctrl = this;

    ctrl.$onInit = () => {
      ctrl.promptEnabled = false;
      ctrl.promptOverride = cloneDeep(DEFAULT_PROMPT_OVERRIDE);
      ctrl.loadPromptOverride();
    };

    const getPromptOverrideLookup = (): PromptOverrideLookup =>
      JSON.parse(LocalStorageService.getItem(AI_WORKFLOW_PROMPT_OVERRIDE) || '{}');

    ctrl.loadPromptOverride = () => {
      const promptOverrideLookup: PromptOverrideLookup = getPromptOverrideLookup();
      const promptOverride = promptOverrideLookup[ctrl.key];
      ctrl.promptEnabled = Boolean(promptOverrideLookup[ctrl.key]);
      ctrl.promptOverride = promptOverride ?? ctrl.promptOverride;
    };

    ctrl.savePromptOverride = (form: IFormController) => {
      ctrl.promptEnabled = true;
      const promptOverrideLookup: PromptOverrideLookup = getPromptOverrideLookup();
      const updatedPromptOverrideLookup: PromptOverrideLookup = {
        ...promptOverrideLookup,
        [ctrl.key]: ctrl.promptOverride,
      };
      LocalStorageService.setItem(AI_WORKFLOW_PROMPT_OVERRIDE, JSON.stringify(updatedPromptOverrideLookup));
      form.$setPristine();
    };

    ctrl.removePromptOverride = (form: IFormController) => {
      ctrl.promptEnabled = false;
      ctrl.promptOverride = cloneDeep(DEFAULT_PROMPT_OVERRIDE);
      const promptOverrideLookup: PromptOverrideLookup = getPromptOverrideLookup();
      delete promptOverrideLookup[ctrl.key];
      LocalStorageService.setItem(AI_WORKFLOW_PROMPT_OVERRIDE, JSON.stringify(promptOverrideLookup));
      form.$setPristine();
    };
  },
};
