import { Organization, User } from '@process-street/subgrade/core';
import { queryString } from '@process-street/subgrade/util';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { TasksTableUtils } from '../components/tasks-table/tasks-table-utils';
import {
  myWorkParamsToPersist,
  MyWorkPersistedSettings,
  MY_WORK_SETTINGS_KEY,
} from '../hooks/use-grid-settings-persistence';

export const parseMyWorkFiltersSearchParams = (searchParams: string) => {
  const parsed = queryString.parse(searchParams);
  const arrayStringKeys = ['userIds', 'templateIds', 'itemsType'];

  return Object.entries(parsed).reduce((acc, [key, value]) => {
    if (!myWorkParamsToPersist.has(key)) return acc;

    if (arrayStringKeys.includes(key)) {
      if (typeof value !== 'string') return acc;

      return {
        ...acc,
        [key]: value.split(','),
      };
    }

    return { ...acc, [key]: value };
  }, {});
};

type GetInitialFiltersParams = {
  organization?: Organization;
  user?: User;
};

export const getMyWorkInitialFilters = ({ organization, user }: GetInitialFiltersParams) => {
  const filtersFromURL = parseMyWorkFiltersSearchParams(window.location.search);

  const defaultFilters = {
    organizationId: organization?.id ?? '',
    userIds: user?.id ? [user.id] : [],
    groupBy: TasksTableUtils.TasksTableGroupBy.DueDate,
    snoozeStatus: GetInboxItemsQuery.SnoozeStatus.Active,
    includeCompleted: false,
  };

  if (Object.keys(filtersFromURL).length === 0) {
    const filters = JSON.parse(window.localStorage.getItem(MY_WORK_SETTINGS_KEY) ?? '{}') as MyWorkPersistedSettings;
    const filtersFromLocalStorage = user ? parseMyWorkFiltersSearchParams(filters[user.id] ?? '') : '';

    return {
      ...defaultFilters,
      ...filtersFromLocalStorage,
    };
  }

  return {
    ...defaultFilters,
    ...filtersFromURL,
  };
};
