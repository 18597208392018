import { GridApi } from '@ag-grid-community/core';
import { Muid } from '@process-street/subgrade/core';
import { InboxItem } from '@process-street/subgrade/inbox';
import create from 'zustand';
import { TasksTableUtils } from './components/tasks-table/tasks-table-utils';
import { getRowId } from './helpers';

interface UseInboxItemsGridContextState {
  hoveredRowId: Muid | null;
  hoveredColumnId: string | null;
  hoverStateByRowId: Record<Muid, boolean>;
  hoverStateByColumnId: Record<string, boolean>;
  gridApi?: GridApi;
  selectedRow: InboxItem | null;

  actions: {
    setHoveredRowId: (hoveredRowId: Muid | null) => void;
    setHoveredColumnId: (hoveredColumnId: string | null) => void;
    setGridApi: (gridApi: GridApi) => void;
    selectRow: (row: InboxItem) => void;
    deselectRow: () => void;
  };
}

export const useInboxItemsGridContextStore = create<UseInboxItemsGridContextState>(set => ({
  hoveredRowId: null,
  hoveredColumnId: null,
  hoverStateByRowId: {},
  hoverStateByColumnId: {},
  selectedRow: null,

  actions: {
    setHoveredRowId: hoveredRowId =>
      set({ hoveredRowId, hoverStateByRowId: hoveredRowId ? { [hoveredRowId]: true } : {} }),
    setHoveredColumnId: hoveredColumnId =>
      set({ hoveredColumnId, hoverStateByColumnId: hoveredColumnId ? { [hoveredColumnId]: true } : {} }),
    setGridApi: gridApi => set({ gridApi }),
    selectRow: selectedRow => set({ selectedRow }),
    deselectRow: () => set({ selectedRow: null }),
  },
}));

export const useInboxItemsGridActions = () => {
  return useInboxItemsGridContextStore(state => state.actions);
};

export const useInboxItemsGridHoverState = () => {
  return useInboxItemsGridContextStore(({ hoveredRowId, hoveredColumnId }) => ({
    hoveredColumnId,
    hoveredRowId,
  }));
};

export const useIsCellHovered = (data: TasksTableUtils.TasksTableItem, columnId: string) => {
  // Selecting by rowId and columnId will ensure that only the cell affected by the hover is updated.
  // If we subscribe to the `hoveredRowId` and `hoveredColumnId`, every cell using this hook will be rerendered.
  return useInboxItemsGridContextStore(state => {
    const isRowHovered = state.hoverStateByRowId[getRowId(data)];
    const isColumnHovered = state.hoverStateByColumnId[columnId];

    return isRowHovered && isColumnHovered;
  });
};

export const useSetInboxItemsGridApi = () => {
  return useInboxItemsGridContextStore(state => state.actions.setGridApi);
};

export const useInboxItemsGridApi = () => useInboxItemsGridContextStore(state => state.gridApi);

export const useInboxItemsSelectedRow = () => useInboxItemsGridContextStore(state => state.selectedRow);
