import * as React from 'react';
import { useEffect } from 'react';
import { Tooltip } from 'components/design/next';
import { isOrganizationMembershipActive, OrganizationMembership, User } from '@process-street/subgrade/core';
import { useFeatureFlag } from 'features/feature-flags';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { useInjector } from 'components/injection-provider';

export const useUserDeactivationHelpers = () => {
  const isFeatureEnabled = useFeatureFlag('deactivatingUsers');
  const membershipByUserId = useSelector(OrganizationMembershipSelector.getMapByUserIdForSelectedOrganization);
  const currentUserQuery = useGetCurrentUserInfoQuery();
  const currentOrganizationId = currentUserQuery.data?.organizationMembership.organization.id;
  const dispatch = useDispatch();
  const { OrganizationMembershipActions } = useInjector('OrganizationMembershipActions');
  const isUsersLoaded = useSelector(OrganizationMembershipSelector.isLoadedByOrganizationId(currentOrganizationId!));

  useEffect(() => {
    if (!isUsersLoaded && currentOrganizationId) {
      // Only own user is in map, load all items
      // We are using global selector, so that this mapping happens only once, not per avatar loaded
      dispatch(OrganizationMembershipActions.getAllOrgMembershipByOrgId(currentOrganizationId));
    }
  }, [isUsersLoaded, currentOrganizationId, dispatch, OrganizationMembershipActions]);

  const getUserOrganizationMembership = React.useCallback(
    (userId: User['id']): OrganizationMembership | undefined => membershipByUserId[userId],
    [membershipByUserId],
  );

  const isUserOrganizationMembershipActive = React.useCallback(
    (userId: User['id']) => {
      const membership = getUserOrganizationMembership(userId);
      if (!isFeatureEnabled || !membership) return true;

      return isOrganizationMembershipActive(membership);
    },
    [getUserOrganizationMembership, isFeatureEnabled],
  );

  const withTooltip = React.useCallback(
    (userId: User['id'], element: React.ReactElement) => {
      if (!isFeatureEnabled || isUserOrganizationMembershipActive(userId)) return element;

      return (
        <Tooltip label="User deactivated" placement="top" hasArrow>
          {element}
        </Tooltip>
      );
    },
    [isFeatureEnabled, isUserOrganizationMembershipActive],
  );

  return {
    getUserOrganizationMembership,
    isActive: isUserOrganizationMembershipActive,
    isFeatureEnabled,
    withTooltip,
  };
};
