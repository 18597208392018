import { Selector } from 'reselect';
import { Muid, Option, Organization } from '../../core';
import { EntityMap, LightReduxState } from '../types';

const getEntityMap: Selector<LightReduxState, EntityMap<Organization>> = (
  state: LightReduxState,
): EntityMap<Organization> => state.entities.organizations;

const getById =
  (organizationId: Muid): Selector<LightReduxState, Option<Organization>> =>
  (state: LightReduxState): Organization | undefined =>
    state.entities.organizations[organizationId];

export const BaseOrganizationSelector = {
  getById,
  getEntityMap,
};
