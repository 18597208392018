import { TemplateSolutionInstance } from '@process-street/subgrade/automation';
import { Muid, Option } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateTemplateSolutionInstanceMutationParams = {
  templateId: Muid;
  id: Muid;
  solutionId: string;
  name?: string;
  taskTemplateGroupId?: Muid;
};

export type CreateTemplateSolutionInstanceMutationResponse = Option<TemplateSolutionInstance>;

export const CreateTemplateSolutionInstanceMutation = {
  key: ['post', 'templates', 'automations', 'template-solution-instances'],
  mutationFn: ({ templateId, ...params }: CreateTemplateSolutionInstanceMutationParams) =>
    axiosService
      .getAxios()
      .post<CreateTemplateSolutionInstanceMutationResponse>(
        `/1/templates/${templateId}/automations/template-solution-instances`,
        params,
      )
      .then(res => res.data),
};

export const useCreateTemplateSolutionInstanceMutation = (
  options: UseMutationOptions<
    CreateTemplateSolutionInstanceMutationResponse,
    AxiosError,
    CreateTemplateSolutionInstanceMutationParams
  > = {},
) => {
  return useMutation(CreateTemplateSolutionInstanceMutation.mutationFn, options);
};
