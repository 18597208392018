import * as React from 'react';
import { Icon } from 'app/components/design/next';
import { IconButton, Menu, MenuButton, MenuItem, MenuList, Tooltip } from '@chakra-ui/react';
import { TinyMCEEditor } from 'app/features/rich-text';
import { getFormatButtonStyles } from './format-button';

export type TextAlignmentProps = {
  editor?: TinyMCEEditor;
  activeCommands: Record<string, boolean>;
};

export const TextAlignment = ({ editor, activeCommands }: TextAlignmentProps) => {
  const isSomeAlignmentActive = ['JustifyLeft', 'JustifyRight', 'JustifyCenter', 'JustifyFull'].some(
    alignment => activeCommands[alignment],
  );
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <Tooltip label="Alignment">
            <MenuButton
              as={IconButton}
              {...getFormatButtonStyles({ isActive: isOpen || isSomeAlignmentActive })}
              aria-label="Justify"
              icon={<Icon icon="align-left" size="3.5" />}
            />
          </Tooltip>

          <MenuList
            bgColor="gray.700"
            p={0}
            pl={0}
            pr={0}
            display="flex"
            flexDirection="row"
            gap="2"
            minW="max-content"
            px="2"
          >
            <MenuItem
              {...getFormatButtonStyles({ isActive: activeCommands['JustifyLeft'] })}
              w={8}
              aria-label="Align Left"
              onClick={() => editor?.execCommand('JustifyLeft')}
              justifyContent="center"
            >
              <Icon icon="align-left" size="3.5" />
            </MenuItem>
            <MenuItem
              {...getFormatButtonStyles({ isActive: activeCommands['JustifyCenter'] })}
              w={8}
              aria-label="Align Center"
              onClick={() => editor?.execCommand('JustifyCenter')}
              justifyContent="center"
            >
              <Icon icon="align-center" size="3.5" />
            </MenuItem>
            <MenuItem
              {...getFormatButtonStyles({ isActive: activeCommands['JustifyRight'] })}
              w={8}
              aria-label="Align Right"
              onClick={() => editor?.execCommand('JustifyRight')}
              justifyContent="center"
            >
              <Icon icon="align-right" size="3.5" />
            </MenuItem>
            <MenuItem
              {...getFormatButtonStyles({ isActive: activeCommands['JustifyFull'] })}
              w={8}
              p={0}
              aria-label="Justify"
              justifyContent="center"
              onClick={() => editor?.execCommand('JustifyFull')}
            >
              <Icon icon="align-justify" size="3.5" />
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
