import * as React from 'react';
import { AspectRatio } from 'components/design/next';

export const EmbedUI: React.FC<React.PropsWithChildren<{ url: string; editable: boolean }>> = ({ url, editable }) => {
  return React.useMemo(() => {
    return (
      <AspectRatio
        ratio={16 / 9}
        width="100%"
        borderRadius="lg"
        borderBottomRadius={editable ? 'lg' : '0'}
        border="1px solid"
        borderColor="gray.200"
        overflow="hidden"
      >
        <iframe
          src={url}
          title="embed"
          frameBorder="0"
          allowFullScreen={true}
          sandbox="allow-scripts allow-forms allow-popups allow-same-origin allow-presentation allow-downloads allow-storage-access-by-user-activation"
        />
      </AspectRatio>
    );
  }, [url, editable]);
};
