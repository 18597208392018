import { Muid } from '../../core';
import { OrderTreeUtils } from '../../process';
import { BaseReduxState } from '../types';
import { BaseTaskStatsSelector } from './task-stats.selectors';

const getTaskIdsSortedByOrderTree =
  (taskIds: Muid[]) =>
  (state: BaseReduxState): Muid[] =>
    taskIds.sort((taskAId, taskBId) => {
      const taskStatsA = BaseTaskStatsSelector.getByTaskId(taskAId)(state);
      const taskStatsB = BaseTaskStatsSelector.getByTaskId(taskBId)(state);
      return OrderTreeUtils.compare(taskStatsA ? taskStatsA.orderTree : '', taskStatsB ? taskStatsB.orderTree : '');
    });

export const OrderTreeSelectors = {
  getTaskIdsSortedByOrderTree,
};
