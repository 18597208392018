import { axiosService } from 'services/axios-service';
import { UserInfo } from '@process-street/subgrade/core';
import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';

const getCurrentUserInfo = () =>
  axiosService
    .getAxios()
    .get<UserInfo>('/1/users/me/info')
    .then(res => res.data);

export const GetCurrentUserInfo = {
  key: ['current-user', 'info'],
  getKey: () => [...GetCurrentUserInfo.key],
  queryFn: getCurrentUserInfo,
};

export type GetCurrentUserInfoResponse = UserInfo;

export const useGetCurrentUserInfoQuery = <Select = GetCurrentUserInfoResponse>(
  options: UseQueryOptions<GetCurrentUserInfoResponse, AxiosError, Select> = {},
) => {
  return useQuery<GetCurrentUserInfoResponse, AxiosError, Select>(
    GetCurrentUserInfo.getKey(),
    GetCurrentUserInfo.queryFn,
    {
      ...options,
      staleTime: Infinity, // This mutates the backend so should only be loaded once on login.
    },
  );
};
