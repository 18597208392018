import { Widget } from '@process-street/subgrade/process';
import { createUsableContext } from '@process-street/subgrade/util';
import React from 'react';

type Context<W extends Widget = Widget> = { widget: W };
export const [_useWidgetContext, WidgetContext] = createUsableContext<Context>({
  hookName: 'useWidgetContext',
  providerName: 'WidgetContextProvider',
});

export const useWidgetContext = <W extends Widget = Widget>(): Context<W> => {
  return _useWidgetContext() as Context<W>;
};

export const WidgetProvider: React.FC<React.PropsWithChildren<{ widget: Widget }>> = ({ widget, children }) => {
  const value = React.useMemo(() => {
    return { widget };
  }, [widget]);
  return <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>;
};
