import { Widget } from '@process-street/subgrade/process';
import identity from 'lodash/identity';

export const MAX_KEY_LENGTH = 255;
export const MAX_LABEL_LENGTH = 255;

export const toFieldValuesList = (field: string, widgets: Widget[], excludeWidget?: Widget) => {
  return widgets
    .filter(widget => {
      const hasKey = widget[field as keyof Widget];
      const shouldExclude = excludeWidget && widget.id === excludeWidget.id;

      return hasKey && !shouldExclude;
    })
    .map(widget => widget[field as keyof Widget] as string);
};

export const toKeys = (widgets: Widget[], excludeWidget?: Widget): string[] => {
  return toFieldValuesList('key', widgets, excludeWidget);
};

export const generateUniqueKey = (keys: string[], key: string) => {
  const ordinals: number[] = [];

  // Find all keys that have the given prefix
  keys.forEach(k => {
    if (k === key) {
      ordinals.push(1);
    } else if (k.startsWith(`${key}_`)) {
      // If a key has this key as a prefix, determine its ordinal and add it a list
      const ordinal = +k.substring(key.length + 1);
      if (!isNaN(ordinal)) {
        ordinals.push(ordinal);
      }
    }
  });

  // Sort numbers in descending order
  const sortedOrdinals = ordinals.sort((a, b) => b - a);

  // This will get one more than the greatest ordinal
  return ordinals.length ? `${key}_${sortedOrdinals[0] + 1}` : key;
};

export function toOrderTrees(items: any[], transform: (a: any) => any = identity) {
  return items.map(item => transform(item).orderTree);
}

/**
 * Counting occurrences of a substring in a string.
 * <b>Note:</b> 1. it doesn't taking into account overlapping
 *              2. might be not the best solution for counting singe char in the string
 */
export function countOccurrences(string: string, substring: string): number {
  let count = 0;

  if (substring.length > 0) {
    let pos = string.indexOf(substring);
    while (pos > -1) {
      count += 1;
      pos = string.indexOf(substring, pos + substring.length);
    }
  }

  return count;
}

const COPY_NUM_TPL = '#n#';

/**
 * Generates copy name with ordinal number from original name by looking at other names (copies).
 * Also truncates name with length over specified limit.
 */
export function generateCopyName({
  postfixTpl,
  names,
  originalName,
  limit = MAX_LABEL_LENGTH,
}: {
  //template with COPY_NUM_TPL ('#n#') for ordinal number e.g. ' (Copy #n#)' -> ' (Copy 2)'
  postfixTpl: string;
  // an array of strings to be checked for other copies
  names: string[];
  //name of original item
  originalName: string;
  // max string length e.g. 255
  limit?: number;
}): string {
  if (postfixTpl.indexOf(COPY_NUM_TPL) < 0) {
    throw new Error('Template should have ordinal number tag');
  }

  const [tpl] = new RegExp(`[\\s_]*${COPY_NUM_TPL}`, 'g').exec(postfixTpl) ?? [];

  if (!tpl) throw new Error('Template not found');

  // Acquiring last ordinal number of copies
  const [postfixStart] = postfixTpl.split(tpl);
  const regex = postfixTpl.replace('(', '\\(').replace(')', '\\)').replace(COPY_NUM_TPL, '(\\d+)');

  let lastOrdinalNumber = 0;

  const postfixLevelEquals = function (a: string, b: string) {
    // Strings have same number of postfix elements in the name
    return countOccurrences(a, postfixStart) === countOccurrences(b, postfixStart);
  };

  for (let i = 0; i < names.length; i += 1) {
    const name = names[i];
    const postfixPos = name.lastIndexOf(postfixStart);
    if (postfixPos > 0) {
      const substring = name.substring(0, postfixPos);

      // Ensuring that we count truncated copies, handle copy of copies and truncated names
      if (
        originalName.startsWith(substring) &&
        (postfixLevelEquals(originalName, substring) || (limit && originalName.length === limit))
      ) {
        const match = new RegExp(regex, 'g').exec(name.substring(postfixPos, name.length));
        if (match && match.length > 1) {
          lastOrdinalNumber = Math.max(Number(match[1]), lastOrdinalNumber);
        } else if (lastOrdinalNumber === 0) {
          lastOrdinalNumber = 1;
        }
      }
    }
  }

  // Generating postfix

  let replace = COPY_NUM_TPL;
  let replaceTo: string | number = lastOrdinalNumber + 1;
  // Case for first copy that doesn't have ordinal number e.g.'(Copy)'
  if (lastOrdinalNumber === 0 && postfixTpl.indexOf(tpl) > -1) {
    replace = tpl;
    replaceTo = '';
  }
  const postfix = postfixTpl.replace(replace, String(replaceTo));

  // Truncating by limit
  let newLength = originalName.length;
  if (limit) {
    newLength = Math.min(originalName.length, limit - postfix.length);

    if (newLength < 0) {
      throw new Error('Limit should be bigger than postfix template');
    }
  }

  return originalName.substring(0, newLength) + postfix;
}

export function generateKeyForCopy(keys: string[], originalKey: string) {
  return generateCopyName({
    postfixTpl: '_(Copy_#n#)',
    names: keys,
    limit: MAX_KEY_LENGTH,
    originalName: originalKey,
  });
}

export function generateNameLabelForCopy(labels: string[], originalLabel: string) {
  return generateCopyName({
    postfixTpl: ' (Copy #n#)',
    names: labels,
    originalName: originalLabel,
    limit: MAX_LABEL_LENGTH,
  });
}

function generateKeyFromLabel(label: string): string {
  return label
    .trim()
    .replace(/\s+/g, '_') // Replace all whitespace with underscores, otherwise, wh
    .replace(/[{]{2,}/g, '{')
    .replace(/[}]{2,}/g, '}'); // replace double brackets
}

export const FormFieldHelpers = {
  toFieldValuesList,
  toKeys,
  generateUniqueKey,
  toOrderTrees,
  generateCopyName,
  generateKeyForCopy,
  generateKeyFromLabel,
  generateNameLabelForCopy,
};
