import React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { Image, Link, Box } from 'components/design/next';
import { useGetOrganizationQuery } from 'features/organization/query-builder';

export type OrganizationLogoProps = {
  hasProcessStreetBranding?: boolean;
};

export const OrganizationLogo: React.FC<React.PropsWithChildren<OrganizationLogoProps>> = ({
  hasProcessStreetBranding = false,
}) => {
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const organizationQuery = useGetOrganizationQuery({ organizationId });
  const customLogoUrl = !hasProcessStreetBranding && organizationQuery.data?.logoFile?.url;

  return customLogoUrl ? (
    <Box>
      <Image h="12" alt="organization logo" src={customLogoUrl} />
    </Box>
  ) : (
    <Link href="http://www.process.st" target="_blank">
      <Image h="4" alt="process.st" src={require('../../../../../images/ps-logo-blue.svg')} />
    </Link>
  );
};
