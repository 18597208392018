import { NativeAutomation, NativeAutomationWithLink } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import {
  ConditionOption,
  DataSetColumnOption,
  SavedViewOption,
  TemplateOption,
  TriggerOption,
} from 'pages/templates/_id/automation/components/editor/data-set-native-automation-editor/use-data-set-native-automation-editor-form';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';

export const getTemplateIdFromAutomation = ({ automation, link }: NativeAutomationWithLink) => {
  return match(automation.actions.find(action => 'templateId' in action.config))
    .with({ config: { templateId: P.string } }, ({ config }) => config.templateId)
    .otherwise(() => link.templateId);
};

type FieldValues = {
  trigger: { value?: TriggerOption };
  template: { value?: TemplateOption };
  savedView: { value?: SavedViewOption };
  operandA: { value?: DataSetColumnOption };
  operandB: { value?: string };
  conditionType: { value?: ConditionOption };
};

const singleOperandConditionsSet = new Set<NativeAutomation.ConditionType>([
  NativeAutomation.ConditionType.IsEmpty,
  NativeAutomation.ConditionType.IsNotEmpty,
]);

const isSingleOperandCondition = (value: NativeAutomation.ConditionType) => singleOperandConditionsSet.has(value);

const isConditionValid = (fields: FieldValues): boolean =>
  !!fields.conditionType.value &&
  !!fields.operandA.value &&
  (isSingleOperandCondition(fields.conditionType.value.value) || !!fields.operandB.value);

const isAutomationValid = (params: {
  data?: GetAllNativeAutomationsQuery.Response;
  dataSetId?: string;
  selectedTemplate?: TemplateOption;
  selectedTrigger?: TriggerOption;
  selectedSavedView?: SavedViewOption;
  selectedAutomationWithLink?: NativeAutomationWithLink;
}): boolean => {
  const { selectedTemplate, selectedTrigger, selectedSavedView, dataSetId, data, selectedAutomationWithLink } = params;

  if (!selectedTemplate || !selectedTrigger || !selectedSavedView || !dataSetId || !data) {
    return false;
  }

  const duplicatedAutomation = data.find(
    record =>
      record.link.dataSetId === dataSetId &&
      record.link.templateId === selectedTemplate.value &&
      record.automation.id !== selectedAutomationWithLink?.automation.id,
  );

  return !duplicatedAutomation;
};

export const Helpers = {
  isAutomationValid,
  isConditionValid,
  isSingleOperandCondition,
};
