import { IconDefinition, IconName, IconPrefix } from '@fortawesome/pro-regular-svg-icons';
import { faJira, faSalesforce } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Box, ChakraIcon, Flex, FlexProps, Icon, Image } from 'components/design/next';
import {
  AutomationAppName,
  getTrayAutomationAppDetail,
  isTrayAutomationAppName,
  stripAutomationAppNamePrefix,
  TrayAutomationAppName,
  withIncomingWebhookPrefix,
} from '@process-street/subgrade/automation';
import { NativeAutomation } from '@process-street/subgrade/process';

const psProcessStreet: IconDefinition = {
  prefix: 'ps' as IconPrefix,
  iconName: 'process-street' as IconName,
  icon: [
    380, // width
    380, // height
    [], // ligatures
    '', // unicode
    `M264.404 80.2796C267.272 78.761 270.569 76.8401 273.489 75.2825L206.593 8.38643C195.345 -2.79548 177.177 -2.79548 165.929 8.38643L8.39709 165.97C-2.79903 177.213 -2.79903 195.392 8.39709 206.635L60.9379 259.214C63.6376 255.762 67.0902 251.621 69.2967 248.714C71.5032 245.807 74.4885 242.133 76.8248 239.096C109.314 197.422 146.896 159.984 188.695 127.655C212.979 108.808 237.394 94.5181 264.404 80.2796Z M369.291 171.045L292.271 94.0249C290.142 95.842 287.741 97.9187 285.547 99.879L287.274 98.0875C281.225 103.383 275.268 108.86 269.479 114.442C230.969 151.654 198.663 195.537 173.561 242.575C160.399 267.236 147.29 291.377 139.645 319.361L139.321 319.686C138.503 320.49 138.867 321.191 138.451 322.398C137.465 325.215 135.544 328.888 133.986 332.25L171.043 369.293C182.288 380.482 200.461 380.482 211.707 369.293L369.278 211.723C380.479 200.479 380.485 182.296 369.291 171.045Z`,
  ],
};

const TypeFormIcon: IconDefinition = {
  prefix: 'ps' as IconPrefix,
  iconName: 'typeform' as IconName,
  icon: [
    24, // width
    24, // height
    [], // ligatures
    '', // unicode
    `M11.32.04C14.41-.2 16.62.61 19 3a16.46 16.46 0 014.75 9.45c.51 3.95-.2 6.9-2.37 9.05-2.08 2.06-5.11 2.74-9.16 2.4l-.17-.01a12.25 12.25 0 01-8.26-3.85C1.26 17.48-.07 14.57 0 11.53c.04-1.5.46-2.77 1.28-4.02a16.6 16.6 0 012.21-2.63l.38-.39.42-.42C6.98 1.37 8.75.26 11.32.04zm7.5 3.13C16.49.85 14.35.05 11.34.3c-2.53.21-4.27 1.32-7 4.08l-.2.2A20.9 20.9 0 001.66 7.4l-.08.12-.08.12a7.12 7.12 0 00-1.23 3.88C.2 14.5 1.5 17.34 3.97 19.85a12.04 12.04 0 008.27 3.79l.17.01c3.9.3 6.8-.37 8.79-2.34 2.1-2.1 2.8-4.96 2.29-8.83a16.2 16.2 0 00-4.67-9.3zm-3.23 5.55V10H12.9v7.25h-1.33V10h-2.7V8.72h6.72z`,
  ],
};

const ZapierIcon: IconDefinition = {
  prefix: 'ps' as IconPrefix,
  iconName: 'zapier' as IconName,
  icon: [
    18, // width
    19, // height
    [], // ligatures
    '', // unicode
    `M11.2504 9.14804C11.2504 9.79698 11.1333 10.4406 10.9045 11.0479C10.297 11.2766 9.65324 11.3939 9.0041 11.394H8.99618C8.34705 11.3941 7.70324 11.2769 7.09576 11.0482C6.86664 10.4408 6.74927 9.79693 6.74933 9.14776V9.13927C6.74933 8.47046 6.87206 7.82993 7.09435 7.23888C7.70193 7.00978 8.34598 6.89261 8.99533 6.89302H9.00268C9.67179 6.89302 10.3121 7.01575 10.9031 7.23888C11.1321 7.84629 11.2493 8.49013 11.249 9.13927V9.14776H11.2498L11.2504 9.14804ZM17.8753 7.64356H12.622L16.3363 3.92903C15.7522 3.10877 15.0351 2.39191 14.2147 1.80806L10.4998 5.52259V0.269078C10.0059 0.185988 9.50585 0.144084 9.00494 0.143799H8.99533C8.48572 0.143799 7.98658 0.187349 7.50017 0.269078V5.52259L3.78444 1.80777C3.37524 2.09902 2.99117 2.42405 2.63627 2.77946L2.63458 2.78116C2.27969 3.13631 1.95495 3.52035 1.66372 3.92931L5.37944 7.64356H0.124994C0.124994 7.64356 0 8.63052 0 9.14069V9.14691C0 9.65707 0.0432666 10.1571 0.125279 10.6438H5.37972L1.66343 14.3577C2.24794 15.1779 2.96505 15.8949 3.78529 16.4793L7.50045 12.765V18.0188C7.9863 18.1002 8.4846 18.1432 8.99364 18.1438H9.00636C9.50668 18.1433 10.0061 18.1015 10.4995 18.0188V12.765L14.215 16.4801C14.6243 16.1886 15.0086 15.8636 15.364 15.5084H15.3649C15.7196 15.1529 16.0442 14.7686 16.3354 14.3594L12.6211 10.644H17.8756C17.957 10.1576 18 9.65934 18 9.1503V9.13729C18 8.63674 17.9581 8.13761 17.8756 7.64413L17.8753 7.64356Z`,
  ],
};

const GreenhouseIcon: IconDefinition = {
  prefix: 'ps' as IconPrefix,
  iconName: 'greenhouse' as IconName,
  icon: [
    24, // width
    24, // height
    [], // ligatures
    '', // unicode
    `m 16.278551,7.1309192 c 0,1.1587744 -0.49025,2.183844 -1.292479,2.9860728 -0.891364,0.891365 -2.183844,1.114206 -2.183844,1.871866 0,1.02507 1.649026,0.713092 3.231198,2.295265 1.047353,1.047353 1.693593,2.428969 1.693593,4.033426 C 17.727019,21.481894 15.186629,24 12,24 8.8133705,24 6.2729805,21.481894 6.2729805,18.319777 c 0,-1.606685 0.6462395,-2.988301 1.6935933,-4.035654 1.5821727,-1.582173 3.2311982,-1.270195 3.2311982,-2.295265 0,-0.75766 -1.2924795,-0.980501 -2.1838444,-1.871866 C 8.2116992,9.3147632 7.7214485,8.2896936 7.7214485,7.086351 c 0,-2.3175488 1.8941504,-4.1894151 4.2116995,-4.1894151 0.445682,0 0.846796,0.066852 1.181058,0.066852 0.601671,0 0.913649,-0.2674095 0.913649,-0.6908078 0,-0.2451253 -0.11142,-0.5571031 -0.11142,-0.8913649 C 13.916435,0.62395543 14.562674,0 15.342618,0 c 0.779945,0 1.4039,0.64623955 1.4039,1.4261838 0,0.8245126 -0.646239,1.2033427 -1.13649,1.3816156 -0.401114,0.1337048 -0.713092,0.3119778 -0.713092,0.713092 0,0.7576601 1.381615,1.4930362 1.381615,3.6100278 z M 15.832869,18.319777 c 0,-2.206128 -1.626741,-3.988858 -3.832869,-3.988858 -2.2061281,0 -3.8328691,1.78273 -3.8328691,3.988858 0,2.183844 1.626741,3.988858 3.8328691,3.988858 2.206128,0 3.832869,-1.807242 3.832869,-3.988858 z M 14.518106,7.086351 c 0,-1.4038997 -1.13649,-2.5626741 -2.518106,-2.5626741 -1.381616,0 -2.5181059,1.1587744 -2.5181059,2.5626741 0,1.4038997 1.1364899,2.5626741 2.5181059,2.5626741 1.381616,0 2.518106,-1.1587744 2.518106,-2.5626741 z`,
  ],
};

const Logos: Record<TrayAutomationAppName, React.ReactNode> = {
  'tray:Jira': <Box as={FontAwesomeIcon} key="jira-logo" icon={faJira as IconDefinition} />,
  'tray:ProcessStreet': <Box as={FontAwesomeIcon} key="process-street-logo" icon={psProcessStreet} />,
  'tray:Salesforce': <Box as={FontAwesomeIcon} key="salesforce-logo" icon={faSalesforce as IconDefinition} />,
  'tray:Slack': (
    <ChakraIcon width="100%" height="100%" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          d="M8 0a2.2 2.2 0 100 4.4h2.3V2.2C10.3 1 9.3 0 8 0zm0 5.9H2.3a2.2 2.2 0 000 4.4h5.9a2.2 2.2 0 100-4.4z"
          fill="#36C5F0"
        />
        <path
          d="M22 8a2.2 2.2 0 00-4.4 0v2.3h2.2c1.2 0 2.2-1 2.2-2.2zm-5.9 0V2.3a2.2 2.2 0 00-4.4 0v5.9a2.2 2.2 0 104.4 0z"
          fill="#2EB67D"
        />
        <path
          d="M14 22a2.2 2.2 0 100-4.4h-2.3v2.2c0 1.2 1 2.2 2.2 2.2zm0-5.9h5.8a2.2 2.2 0 000-4.4h-5.9a2.2 2.2 0 000 4.4z"
          fill="#ECB22E"
        />
        <path
          d="M0 14a2.2 2.2 0 004.4 0v-2.3H2.2C1 11.7 0 12.7 0 14zm5.9 0v5.8a2.2 2.2 0 004.4 0v-5.9a2.2 2.2 0 00-4.4 0z"
          fill="#E01E5A"
        />
      </g>
    </ChakraIcon>
  ),
  'tray:GoogleSheets': (
    <ChakraIcon width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0L21 6V22C21 23.1046 20.1046 24 19 24H5C3.89543 24 3 23.1046 3 22V2C3 0.89543 3.89543 0 5 0H15ZM7 19V9H17V19H7ZM12.5 12H16V10H12.5V12ZM16 13H12.5V15H16V13ZM8 15H11.5V13H8V15ZM11.5 12V10H8V12H11.5ZM11.5 16H8V18H11.5V16ZM12.5 16V18H16V16H12.5Z"
        fill="#19B870"
      />
      <path d="M17 6C16.4556 6 15.9633 5.78094 15.6026 5.42804L21 10.8254V6H17Z" fill="url(#paint0_linear)" />
      <path
        opacity="0.1"
        d="M19 23.75H5C3.89545 23.75 3 22.8546 3 21.75V22C3 23.1046 3.89545 24 5 24H19C20.1046 24 21 23.1046 21 22V21.75C21 22.8546 20.1046 23.75 19 23.75Z"
        fill="black"
      />
      <path d="M15 0V4C15 5.10457 15.8954 6 17 6H21L15 0Z" fill="#80D8B0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0L20.75 5.75L21 6V22C21 23.1046 20.1046 24 19 24H5C3.89543 24 3 23.1046 3 22V2.25V2C3 0.895386 3.89545 0 5 0H15Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="16.809"
          y1="4.22168"
          x2="22.2064"
          y2="9.61908"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="0.210064"
          y1="7.53852"
          x2="22.9961"
          y2="18.1638"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </ChakraIcon>
  ),
  'tray:TypeForm': <Box as={FontAwesomeIcon} key="typeform-logo" icon={TypeFormIcon} />,
  'tray:DocuSign': (
    <ChakraIcon
      width="100%"
      height="100%"
      viewBox="0 0 353 353"
      key="docusign-logo"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M353 0H0V353H353V0ZM158.783 47.0667H194.083V183.661L228.433 149.311L253.394 174.272L176.197 251.469L99 174.272L123.961 149.311L158.783 184.134V47.0667ZM47 270.633H305.867V305.933H47V270.633Z"
        fill="black"
      />
    </ChakraIcon>
  ),
  'tray:Zapier': <Box as={FontAwesomeIcon} key="zapier-logo" icon={ZapierIcon} />,
  'tray:Greenhouse': <Box as={FontAwesomeIcon} key="greenhouse-logo" icon={GreenhouseIcon} />,
  'tray:Make': (
    // source: https://www.make.com/en/press
    <ChakraIcon width="100%" height="100%" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Make-App-Icon-Circle" transform="translate(3757 -1767)">
        <circle
          id="Ellipse_10"
          data-name="Ellipse 10"
          cx="256"
          cy="256"
          r="256"
          transform="translate(-3757 1767)"
          fill="#6d00cc"
        />
        <path
          id="Path_141560"
          data-name="Path 141560"
          d="M244.78,14.544a7.187,7.187,0,0,0-7.186,7.192V213.927a7.19,7.19,0,0,0,7.186,7.192h52.063a7.187,7.187,0,0,0,7.186-7.192V21.736a7.183,7.183,0,0,0-7.186-7.192ZM92.066,17.083,5.77,188.795a7.191,7.191,0,0,0,3.192,9.654l46.514,23.379a7.184,7.184,0,0,0,9.654-3.2l86.3-171.711a7.184,7.184,0,0,0-3.2-9.654L101.719,13.886a7.2,7.2,0,0,0-9.654,3.2m72.592.614L127.731,204.876a7.189,7.189,0,0,0,5.632,8.442l51.028,10.306a7.2,7.2,0,0,0,8.481-5.665L229.8,30.786a7.19,7.19,0,0,0-5.637-8.442L173.133,12.038a7.391,7.391,0,0,0-1.427-.144,7.194,7.194,0,0,0-7.048,5.8"
          transform="translate(-3676.356 1905.425)"
          fill="#fff"
        />
      </g>
    </ChakraIcon>
  ),
  'tray:PowerAutomate': (
    <ChakraIcon
      width="100%"
      height="100%"
      viewBox="0 0 16 13"
      key="docusign-logo"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.299 0.143799L4.74641 6.3496L-0.806152 0.143799H10.299Z" fill="url(#paint0_linear_1134_49702)" />
      <g filter="url(#filter0_f_1134_49702)">
        <path
          d="M10.299 0.208984L-0.806152 12.6206H10.299L15.8515 6.41479L10.299 0.208984Z"
          fill="black"
          fillOpacity="0.24"
        />
      </g>
      <g filter="url(#filter1_f_1134_49702)">
        <path
          d="M10.299 0.470459L-0.806152 12.8821H10.299L15.8515 6.67626L10.299 0.470459Z"
          fill="black"
          fillOpacity="0.32"
        />
      </g>
      <path
        d="M-0.806152 12.5554L10.299 0.143799L15.8515 6.3496L10.299 12.5554H-0.806152Z"
        fill="url(#paint1_linear_1134_49702)"
      />
      <path
        d="M-0.806152 12.5554L10.299 0.143799L13.0753 3.2467L4.74641 12.5554H-0.806152Z"
        fill="url(#paint2_linear_1134_49702)"
      />
      <defs>
        <filter
          id="filter0_f_1134_49702"
          x="-1.60615"
          y="-0.591016"
          width="18.2577"
          height="14.0116"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.4" result="effect1_foregroundBlur_1134_49702" />
        </filter>
        <filter
          id="filter1_f_1134_49702"
          x="-8.80615"
          y="-7.52954"
          width="32.6577"
          height="28.4116"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_1134_49702" />
        </filter>
        <linearGradient
          id="paint0_linear_1134_49702"
          x1="7.03276"
          y1="7.49278"
          x2="4.74641"
          y2="0.143799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D36A5" />
          <stop offset="1" stopColor="#1152D4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1134_49702"
          x1="7.52269"
          y1="0.143799"
          x2="7.52269"
          y2="12.5554"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#84CAFF" />
          <stop offset="1" stopColor="#61B1FB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1134_49702"
          x1="6.13455"
          y1="0.143799"
          x2="6.13455"
          y2="12.5554"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B90F5" />
          <stop offset="1" stopColor="#2A78EE" />
        </linearGradient>
      </defs>
    </ChakraIcon>
  ),
};

const getIcon = (automationApp: TrayAutomationAppName): React.ReactNode => {
  return Logos[automationApp] ?? '';
};

export type AutomationAppIconProps = { variant?: 'white' | 'brand'; automationApp: AutomationAppName } & FlexProps;

export const AutomationAppIcon: React.FC<React.PropsWithChildren<AutomationAppIconProps>> = ({
  variant = 'brand',
  automationApp: automationAppProp,
  ...props
}) => {
  // for icon, Tray & native PS2PS are the same
  const automationApp = automationAppProp === NativeAutomation.APP_NAME ? 'tray:ProcessStreet' : automationAppProp;
  return isTrayAutomationAppName(automationApp) ? (
    <Flex
      align="center"
      aria-label={`Automation app: ${getTrayAutomationAppDetail(automationApp).label}`}
      color={getTrayAutomationAppDetail(automationApp).brandColor ?? 'currentcolor'}
      {...props}
      filter={variant === 'white' ? 'brightness(0%) invert()' : undefined}
      sx={{
        '& svg': {
          width: '100% !important',
          height: '100% !important',
        },
      }}
    >
      {getIcon(automationApp)}
    </Flex>
  ) : (
    <Flex
      align="center"
      aria-label={automationApp}
      {...props}
      filter={variant === 'white' ? 'brightness(0%) invert()' : undefined}
    >
      {automationApp === withIncomingWebhookPrefix('Webhook') ? (
        <Icon icon="webhook" variant="far" color="brand.500" />
      ) : (
        <Image
          src={require(`../../../../images/automation-app-icons/${stripAutomationAppNamePrefix(automationApp)}.png`)}
          alt={`${automationApp} logo`}
        />
      )}
    </Flex>
  );
};
