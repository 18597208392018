import { createRemoveEmptyNodesPlugin } from '@udecode/plate-normalizers';
import { ELEMENT_LINK } from '@udecode/plate-link';
import { linkDeserializer } from 'pages/pages/_id/edit/page/plugins/ps-link/link-deserializer';
import { createPagesPluginFactory } from '../../pages-plate-types';

export const createPSLink = createPagesPluginFactory({
  key: ELEMENT_LINK,
  isElement: true,
  deserializeHtml: linkDeserializer,
  isInline: true,
  plugins: [createRemoveEmptyNodesPlugin({ options: { types: ELEMENT_LINK } })],
});
