import { OrganizationMembershipStatuses } from 'reducers/types';
import { combineReducers } from 'redux';
import { getStatusReducer } from '../statuses/statuses-utils';
import {
  ORGANIZATION_MEMBERSHIP_GET_ALL_BY_ORG_ID,
  ORGANIZATION_MEMBERSHIP_GET_ALL_BY_USER_ID,
} from './organization-membership.actions';

export const organizationMembershipStatusesReducer = combineReducers<OrganizationMembershipStatuses>({
  byOrganizationId: getStatusReducer(ORGANIZATION_MEMBERSHIP_GET_ALL_BY_ORG_ID, 'organizationId'),
  byUserId: getStatusReducer(ORGANIZATION_MEMBERSHIP_GET_ALL_BY_USER_ID, 'userId'),
});
