import * as React from 'react';
import { Box, BoxProps } from 'components/design/next';
import { BlvdSelect, BlvdSelectProps } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { ExecuteAiPrompt } from '@process-street/subgrade/process';
import { PromptOption } from './prompt-option';
import { FieldTypePreservingOmit } from '@process-street/subgrade/core';

export interface AiTaskPromptTypeSelectProps
  extends FieldTypePreservingOmit<BlvdSelectProps<Option>, 'value' | 'onChange'> {
  value?: ExecuteAiPrompt.PromptType;
  onChange: (promptType: ExecuteAiPrompt.PromptType) => void;
  containerProps?: BoxProps;
}

type Option = { value: ExecuteAiPrompt.PromptType; label: string };
export type { Option as AiTaskPromptTypeSelectOption };

type GroupedOptions = {
  label: string;
  options: Option[];
}[];

export const AiTaskPromptTypeSelect: React.FC<React.PropsWithChildren<AiTaskPromptTypeSelectProps>> = ({
  onChange,
  value: valueProp,
  containerProps,
  ...props
}) => {
  const value = React.useMemo(() => {
    if (!valueProp) return null;
    const config = ExecuteAiPrompt.PROMPT_CONFIGS_BY_TYPE[valueProp];
    return { value: valueProp, label: config.promptLabel ?? config.promptType };
  }, [valueProp]);

  const options = React.useMemo(() => {
    const lookup =
      ExecuteAiPrompt.PROMPT_CONFIGS.reduce((acc, schema) => {
        const group = 'promptGroup' in schema ? schema.promptGroup : '';
        if (!acc[group]) acc[group] = [];
        acc[group].push({ label: schema.promptLabel ?? schema.promptType, value: schema.promptType });
        return acc;
      }, {} as Record<string, GroupedOptions[number]['options']>) ?? {};

    return Object.entries(lookup).map(([groupLabel, options]) => ({
      label: groupLabel,
      options,
    }));
  }, []);

  return (
    <Box w="full" {...containerProps}>
      <BlvdSelect<Option>
        {...props}
        value={value}
        placeholder="Select category"
        options={options}
        components={{
          Option: PromptOption,
        }}
        onChange={option => {
          if (BlvdSelectHelpers.isOptionType<Option>(option)) {
            onChange(option.value);
          }
        }}
      />
    </Box>
  );
};
