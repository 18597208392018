import angular from 'angular';
import { uuid } from 'services/uuid';

angular.module('frontStreetApp.services').factory('ValueUpdater', ($timeout, PromiseQueueService) => {
  /**
   * An object that puts value updates into the Promise Queue Service.
   */

  /**
   * Construction of the value updater
   *
   * @param {string} queueKey - key of the promise
   * @param {Function} callback - callback to execute when update should occur
   * @param {Function} delay - debounce delay in ms
   */
  function ValueUpdater(queueKey, callback, delay) {
    const self = this;

    self.queueKey = queueKey;
    self.callback = callback;
    self.debounceDelay = delay ?? 500;

    self.valueTouched = false;

    // Delay is subtracted to make sure that first updateValue is enqueued
    self.updateValueLastCalled = Date.now() - self.debounceDelay;
    // Value associated with queued updated using id
    self.valueMap = {};
    // Tail queued update id is the last queued update. Only the tail update has to be debounced.
    self.tailQueuedUpdateId = undefined;
  }

  /**
   * Initiates execution of the callback with debounce
   *
   * @param {Object} [value] - value to pass in callback
   * @param {Object} [description] - updated description
   */
  ValueUpdater.prototype.updateValue = function (value, description) {
    const self = this;

    self.description = description || '<no description>';
    self.valueTouched = true;

    const now = Date.now();

    // Enqueue new update promise when time gap between updates are bigger than debounce delay
    const timeGapBetweenUpdatesIsBiggerThanDelay = now - self.updateValueLastCalled > self.debounceDelay;
    let result = Promise.resolve(value);
    if (timeGapBetweenUpdatesIsBiggerThanDelay) {
      self.tailQueuedUpdateId = uuid();
      result = PromiseQueueService.enqueue(
        self.queueKey,
        self.updateValueInLoop.bind(self, self.tailQueuedUpdateId),
        self.description,
      );
    }
    self.valueMap[self.tailQueuedUpdateId] = angular.copy(value);
    self.updateValueLastCalled = now;
    return result;
  };

  // Private methods

  /**
   * Looping the update until value has not been changed in the given delay.
   *
   * @param {string} queuedUpdateId - uuid of a queued update
   * @access private
   */
  ValueUpdater.prototype.updateValueInLoop = function (queuedUpdateId) {
    const self = this;

    let promise;
    if (queuedUpdateId !== self.tailQueuedUpdateId) {
      // Execute the callback promise if it's not the tail queued update
      promise = self.callback(self.valueMap[queuedUpdateId]);
    } else {
      promise = $timeout(angular.noop, self.debounceDelay).then(() => {
        if (self.valueTouched) {
          self.valueTouched = false;
          return self.updateValueInLoop(queuedUpdateId);
        } else {
          return self.callback(self.valueMap[queuedUpdateId]);
        }
      });
    }

    promise.finally(() => {
      delete self.valueMap[queuedUpdateId];
    });

    return promise;
  };

  return ValueUpdater;
});
