import * as React from 'react';
import { Box, Text, VStack } from 'components/design/next';
import { AddIconButton } from './components/add-icon-button';
import { AddBackgroundButton } from './components/add-background-button';
import { AddCoverButton } from './components/add-cover-button';
import { useTemplateId } from 'app/pages/templates/_id/automation/utils/use-template-id';

export type DesignSectionProps = {};

export const DesignSection: React.FC<DesignSectionProps> = () => {
  const templateId = useTemplateId();

  if (!templateId) return null;

  return (
    <Box gap={2} w="full">
      <Text color="gray.500">Design</Text>
      <VStack>
        <AddIconButton templateId={templateId} />
        <AddBackgroundButton templateId={templateId} />
        <AddCoverButton templateId={templateId} />
      </VStack>
    </Box>
  );
};
