import { PlanInterval, PlanIntervalUtil, PlanLevel } from '@process-street/subgrade/billing';
import angular from 'angular';
import { getDiscountValue } from '@process-street/subgrade/billing/plan-utils';
import { IntercomService } from 'services/interop/intercom-service';
import templateUrl from './plan-level-selector.component.html';
import './plan-level-selector.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular.module('frontStreetApp.directives').component('psPlanLevelSelector', {
  bindings: {
    organization: '<',
    plan: '<',
    discount: '<',
    quantity: '<',
    onSelect: '&',
  },
  require: {
    billingCtrl: '^psBilling',
  },
  templateUrl,
  controller(
    $q,
    $scope,
    BillingService,
    PlanLevelSelectorService,
    PlanFeatureSetService,
    PlanService,
    PlanDetailsService,
    OrganizationService,
    ToastService,
  ) {
    const ctrl = this;

    ctrl.selectedInterval = PlanInterval.Yearly;

    ctrl._onPlanChange = plan => {
      ctrl.plan = plan;
      ctrl.selectedInterval = PlanInterval.Yearly;

      const { subscription } = ctrl.organization;
      ctrl.expired = BillingService.isSubscriptionExpired(subscription, ctrl.plan);
      ctrl.legacy = PlanService.isPlanIdLegacy(ctrl.plan.id);
      ctrl.trialing = OrganizationService.isTrialing(subscription);
      ctrl.canceled = OrganizationService.isCanceled(subscription);

      if (ctrl.legacy) {
        // If legacy plan then we need to show cost
        ctrl.legacyPlanCostMap = PlanService.calculatePlanCost(ctrl.plan, ctrl.quantity, ctrl.discount);
        ctrl.legacyPlanName = `${PlanService.getDisplayName(ctrl.plan)} plan`;
        ctrl.legacyPlanPeriod = PlanService.convertPlanIntervalToWord(ctrl.plan.period);
        ctrl.selectedPlanLevel = undefined;
      } else {
        // In case if not legacy, then it's current plan
        ctrl.currentPlan = ctrl.plan;
        ctrl.selectedPlanLevel = ctrl.plan.level;
      }

      ctrl.initLegacyMessage();

      ctrl._initializePlansAndFeatureSets();
    };

    ctrl._onDiscountChange = discount => {
      ctrl.discountValue = getDiscountValue(discount);
      ctrl._initializePlansAndFeatureSets();
    };

    ctrl.$onChanges = changes => {
      if (changes.plan && changes.plan.currentValue) {
        ctrl._onPlanChange(changes.plan.currentValue);
      }

      if (changes.quantity) {
        ctrl.quantity = changes.quantity.currentValue;
        ctrl._initializePlansAndFeatureSets();
      }

      if (changes.discount && changes.discount.currentValue) {
        ctrl._onDiscountChange(changes.discount.currentValue);
      }
    };

    ctrl.initLegacyMessage = () => {
      if (ctrl.expired || ctrl.currentPlan) {
        return;
      }

      ctrl.legacyMessage = PlanDetailsService.getLegacyMessage(ctrl.plan, ctrl.quantity, ctrl.discount);
    };

    ctrl.plansReady = false;

    ctrl._initializePlansAndFeatureSets = () => {
      const currentPlanDescriptor = PlanService.findDescriptorOrGetDefault(ctrl.plan.id);

      ctrl.planMap = {};
      ctrl.featureSetMap = {};

      const promises = [];
      const planMap = {};

      Object.entries(currentPlanDescriptor).forEach(([track, levelsIntervalsIds]) => {
        Object.entries(levelsIntervalsIds).forEach(([level, intervalsIds]) => {
          planMap[level] = {};
          ctrl.featureSetMap[level] = {};
          Object.entries(intervalsIds).forEach(([interval, _id]) => {
            const planId = currentPlanDescriptor[track][level][interval];
            const promise = PlanService.getById(planId).then(plan => {
              planMap[level][interval] = plan;
              PlanFeatureSetService.getById(plan.featureSet.id).then(featureSet => {
                ctrl.featureSetMap[level][interval] = featureSet;
              });
            });
            promises.push(promise);
          });
        });
      });

      $q.all(promises).then(
        () => {
          ctrl.planMap = planMap; // triggers render of react - mutating doesn't
          ctrl.plansReady = true;

          ctrl.updateCostDetailsPerPlanBasedOnSelections();
        },
        () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems loading your plan information`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
      );
    };

    /**
     * Returns true if plan level is free
     *
     * @param {PlanLevel} level
     * @returns {boolean}
     */
    ctrl.isPlanLevelFree = function (level) {
      return PlanService.isPlanLevelFree(level);
    };

    ctrl.shouldShowOnlyYearlyBillingCycle = () => {
      const yearly = ctrl.plan.interval === PlanInterval.Yearly;
      const paidPlan = ctrl.plan.level !== PlanLevel.Free;
      const canceledAndNotDiscounted = ctrl.canceled && !ctrl.discountValue;

      return !canceledAndNotDiscounted && paidPlan && yearly;
    };

    function getSelectedPlan() {
      return ctrl.planMap[ctrl.selectedPlanLevel] && ctrl.planMap[ctrl.selectedPlanLevel][ctrl.selectedInterval];
    }

    ctrl.updateCostDetailsPerPlanBasedOnSelections = function () {
      const levelPlans = ctrl.planMap && ctrl.planMap[ctrl.selectedPlanLevel];

      // We are showing only yearly interval for some cases
      const onlyYearlyCycle = ctrl.shouldShowOnlyYearlyBillingCycle();
      const planPeriods = onlyYearlyCycle ? [PlanInterval.Yearly] : PlanIntervalUtil.all();

      if (levelPlans) {
        ctrl.costDetailsPerPeriodMap = {};
        planPeriods.forEach(interval => {
          if (levelPlans[interval]) {
            ctrl.costDetailsPerPeriodMap[interval] = PlanLevelSelectorService.getPlanCostDetailsDescription(
              levelPlans[interval],
              interval,
              ctrl.quantity,
              ctrl.discount,
            );
          }
        });
      } else {
        ctrl.costDetailsPerPeriodMap = undefined;
      }

      ctrl.setSelectedPlan();
    };
    /**
     * Marks plan as selected, updates cost details
     * @param {PlanLevel} planLevel
     */
    ctrl.selectPlanByLevel = planLevel => {
      ctrl.selectedPlanLevel = planLevel;

      // For free level, interval is always Monthly
      if (PlanService.isPlanLevelFree(ctrl.selectedPlanLevel)) {
        ctrl.selectedInterval = PlanInterval.Monthly;
      }
      ctrl.updateCostDetailsPerPlanBasedOnSelections();
      $scope.$apply();
    };

    ctrl.selectPlanByLevelAndState = (planLevel, state) => {
      if (state === PlanLevelSelectorService.PlanPanelStateMap.CONTACT_US) {
        AnalyticsService.trackEvent('contact us link clicked', { location: 'billing page' });
        IntercomService.show();
        return false;
      } else if (ctrl.isFreeAndShouldCancelSubscription(planLevel)) {
        ctrl.billingCtrl.cancelSubscription();
        return false;
      } else if (state !== PlanLevelSelectorService.PlanPanelStateMap.CURRENT_PLAN) {
        ctrl.selectPlanByLevel(planLevel);
        return true;
      }
      return false;
    };

    ctrl.isFreeAndShouldCancelSubscription = planLevel => {
      const canceled = ctrl.organization && OrganizationService.isCanceled(ctrl.organization.subscription);
      const selectedPlanIsFree = PlanService.isPlanLevelFree(planLevel);

      if (!ctrl.currentPlan) {
        return selectedPlanIsFree && !canceled; // legacy level
      }

      const expired =
        ctrl.organization && BillingService.isSubscriptionExpired(ctrl.organization.subscription, ctrl.currentPlan);
      const trialing = ctrl.organization && OrganizationService.isTrialing(ctrl.organization.subscription);
      const legacy = ctrl.currentPlan && PlanService.isPlanIdLegacy(ctrl.currentPlan.id);

      const organizationPlanIsFree = PlanService.isPlanFree(ctrl.currentPlan);

      // The logic combinations of the the second part of the boolean expression below:
      //     legacy +     expired +     free => false
      //     legacy +     expired + not free => false
      //     legacy + not expired +     free => false
      //     legacy + not expired + not free => true
      // not legacy +     expired +     free => false
      // not legacy +     expired + not free => true
      // not legacy + not expired +     free => false
      // not legacy + not expired + not free => true
      // Simplified here http://www.wolframalpha.com/input/?
      // i=(A+AND+NOT+B+AND+NOT+C)+OR+((NOT+A+AND+B+AND+NOT+C)+OR+(NOT+A+AND+NOT+B+AND+NOT+C))
      return !canceled && selectedPlanIsFree && !trialing && !(legacy && expired) && !organizationPlanIsFree;
    };

    /**
     * Fires onSelect event with parameters of selected plan
     */
    ctrl.setSelectedPlan = function () {
      const selectedPlan = getSelectedPlan();
      const trialingOrExpired = ctrl.expired || ctrl.trialing;

      if (
        selectedPlan &&
        ctrl.canUpgrade() &&
        (ctrl.legacy || trialingOrExpired || (selectedPlan && ctrl.currentPlan.id !== selectedPlan.id))
      ) {
        const billedQuantity = PlanService.calculateBilledQuantity(selectedPlan, ctrl.quantity);
        const total = PlanService.calculatePlanCost(selectedPlan, ctrl.quantity, ctrl.discount).rawTotalCost;

        ctrl.onSelect({ plan: selectedPlan, total, quantity: billedQuantity });
      } else {
        ctrl.onSelect({ plan: null, total: null, quantity: ctrl.quantity });
      }
    };

    ctrl.getBillingCycleClass = () => {
      const yearlyOnly = ctrl.shouldShowOnlyYearlyBillingCycle();
      return {
        'col-xs-12': yearlyOnly,
        'col-xs-6': !yearlyOnly,
      };
    };

    ctrl.canUpgrade = function () {
      return (
        PlanService.isPlanIdLegacy(ctrl.plan.id) ||
        (ctrl.selectedPlanLevel &&
          (ctrl.selectedPlanLevel !== ctrl.plan.level ||
            ctrl.expired ||
            ctrl.trialing ||
            ctrl.legacy ||
            ctrl.currentPlan.interval !== PlanInterval.Yearly))
      );
    };

    ctrl.shouldShowBillingCycles = function () {
      return (
        ctrl.selectedPlanLevel &&
        ctrl.costDetailsPerPeriodMap &&
        ctrl.canUpgrade() &&
        !ctrl.isPlanLevelFree(ctrl.selectedPlanLevel)
      );
    };

    ctrl.isSelectedLevel = function (level) {
      return ctrl.selectedPlanLevel === level && level !== PlanLevel.Enterprise;
    };
  },
});
