import isObject from 'lodash/isObject';
import { match } from 'ts-pattern';
import { TraySolution } from '../automation';
import { AuditMetadata, Muid, Option, Organization, Ref } from '../core';
import { ColumnDef } from '../dashboard';
import { Folder } from './folder-model';

export type OrderTree = string;

export interface TaskTemplateGroup {
  id: string;
}

export enum TaskTemplateStatus {
  Active = 'Active',
  Deleted = 'Deleted',
}

export enum TaskTemplateTaskType {
  Standard = 'Standard',
  Approval = 'Approval',
  AI = 'AI',
}

export interface TaskTemplate {
  readonly id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  group: TaskTemplateGroup;
  templateRevision: Ref<TemplateRevision>;
  orderTree: OrderTree;
  name?: string;
  status: TaskTemplateStatus;
  dueOffset?: string;
  stop: boolean;
  hiddenByDefault: boolean;
  taskType: TaskTemplateTaskType;
}

export interface TaskTemplateOrderModel {
  taskTemplateId: Muid;
  taskTemplateGroupId: Muid;
  orderTree: OrderTree;
}

export enum TemplateShareLevel {
  None = 'None',
  View = 'View',
  Run = 'Run',
}

export enum TemplateRevisionStatus {
  Draft = 'Draft',
  Finished = 'Finished',
  Revised = 'Revised',
  Deleted = 'Deleted',
}

export enum TemplateStatus {
  Active = 'Active',
  Archived = 'Archived',
  Deleted = 'Deleted',
}

export enum TemplateType {
  Playbook = 'Playbook',
  Form = 'Form',
  Page = 'Page',
  Task = 'Task',
  WorkflowSandbox = 'WorkflowSandbox',
}

export function isTemplate(maybeTemplate: unknown): maybeTemplate is Template {
  if (!isObject(maybeTemplate)) return false;

  return 'templateType' in maybeTemplate;
}

export function isWorkflow(template: Template | TemplateType) {
  return (typeof template === 'string' ? template : template.templateType) === TemplateType.Playbook;
}

export function isPage(template: Template | TemplateType) {
  return (typeof template === 'string' ? template : template.templateType) === TemplateType.Page;
}

export function isForm(template: Template | TemplateType) {
  return (typeof template === 'string' ? template : template.templateType) === TemplateType.Form;
}

export function tmplTypeToChecklistName(template?: Template) {
  return match(template?.templateType)
    .with(TemplateType.Playbook, () => 'Workflow Run')
    .with(TemplateType.Form, () => 'Form Response')
    .otherwise(() => '');
}

export function tmplTypeToChecklistNameLower(template?: Template) {
  return tmplTypeToChecklistName(template).toLowerCase();
}

export function tmplTypeName(template?: Template) {
  return match(template?.templateType)
    .with(TemplateType.Playbook, () => 'Workflow')
    .with(TemplateType.Form, () => 'Form')
    .with(TemplateType.Page, () => 'Page')
    .otherwise(() => '');
}

export function tmplTypeNameLower(template?: Template) {
  return tmplTypeName(template).toLowerCase();
}

export interface Template {
  readonly id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  folder: Ref<Folder>;
  name: string;
  description?: string;
  templateType: TemplateType;
  status: TemplateStatus;
  sharedRunLink: boolean;
  shareLevel: TemplateShareLevel;
  public: boolean;
  publicId?: number;
  publicUrl?: string;
  free: boolean;
  lastRunDate: Option<number>;
  checklistCommentsEnabled: boolean;
  checklistShareLinkEnabled: boolean;
  checklistRequestApprovalEnabled: boolean;
  preferredTriggers: (NativeTemplateTrigger | TraySolutionIdTrigger)[];
  copiedFromTemplateId?: Muid;
  copiedFromTemplateRevisionId?: Muid;
}

export type TraySolutionIdTrigger = `traySolutionId:${TraySolution['id']}`;

export const TraySolutionTriggerUtils = {
  isTraySolutionIdTrigger: (trigger: any): trigger is TraySolutionIdTrigger =>
    typeof trigger === 'string' && trigger.startsWith('traySolutionId:'),
  triggerToId: (trigger: TraySolutionIdTrigger): string => {
    const [, id] = trigger.split(':');
    if (!id) {
      throw new Error(`Invalid trigger: ${trigger}`);
    }
    return id;
  },
  idToTrigger: (id: string): TraySolutionIdTrigger => `traySolutionId:${id}`,
};

export interface PublicTemplate extends Template {
  public: true;
  publicId: number;
  publicUrl: string;
}

export function isPublicTemplate(template: Template): template is PublicTemplate {
  return template.public;
}

export interface TemplateRevision {
  readonly id: Muid;
  audit: AuditMetadata;
  lastActiveDate: number;
  organization: Ref<Organization>;
  template: Ref<Template>;
  revision: number;
  status: TemplateRevisionStatus;
  hasProcessStreetBranding: boolean;
  defaultChecklistName?: string;
}

export interface TemplateRevisionWithTemplate extends TemplateRevision {
  template: Template;
}

export interface TemplateRunViaEmailConfig {
  id: Muid;
  enabled: boolean;
  templateId: Template['id'];
  audit: AuditMetadata;
}

export const NATIVE_TEMPLATE_TRIGGERS = [
  'manual',
  'email',
  'runLink',
  'schedule',
  'runMultiple',
  'api',
  'webhook',
] as const;

export type NativeTemplateTrigger = typeof NATIVE_TEMPLATE_TRIGGERS[number];

export function isNativeTemplateTrigger(trigger: any): trigger is NativeTemplateTrigger {
  return typeof trigger === 'string' && NATIVE_TEMPLATE_TRIGGERS.includes(trigger as NativeTemplateTrigger);
}

export const DEFAULT_HEADING_NAME = 'Heading:';
export const DEFAULT_APPROVAL_NAME = 'Approval: ';
export const DEFAULT_AI_NAME = 'AI Task: ';

export type TemplateSchema = {
  organizationId: Muid;
  templateId: Muid;
  templateRevisionId: Muid;
  templateRevisionNumber: number;
  createdDate: number;
  updatedDate: number;
  columns: ColumnDef[];
};

export const DEFAULT_PAGE_TEMPLATE_COVER = 'jr7_6j_zqpSjxfqJ8ShJLA/default-background.png';
