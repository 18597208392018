import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import {
  PaywallAdminBodyDescription,
  PaywallAdminHeaderIcon,
  PaywallAdminIconTemplate,
  PaywallBodyAdminHeading,
  PaywallBodyDescription,
  PaywallBodyDescriptionEmphasis,
  PaywallBodyHeading,
  PaywallBodyNoIcon,
  PaywallBodyWithIcon,
  PaywallButtonGroup,
  PaywallCloseButton,
  PaywallDivider,
  PaywallFeatures,
  PaywallFooter,
  PaywallHeader,
  PaywallHeaderWithIcon,
  PaywallIconTemplate,
  PaywallIconUserLock,
  PaywallModal,
  PaywallPrimaryActionLinkButton,
  PaywallSecondaryActionButton,
} from 'components/paywalls/common';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { usePaywall } from '../paywall-provider';
import { useAdminEmails } from '../use-admin-emails';
import { OrganizationSelector } from 'reducers/organization/organization.selectors';
import { mailto as buildMailTo } from '@process-street/subgrade/process';
import { useFeatureSet } from 'components/paywalls/use-feature-set';
import { useRoleNames } from 'hooks/use-role-names';
import { IntercomService } from 'services/interop/intercom-service';
import { AnalyticsService } from 'components/analytics/analytics.service';

export const FreeMember: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setPaywall } = usePaywall();
  const orgId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const { name: orgName } = useSelector(OrganizationSelector.getById(orgId)) ?? {};
  const currentUser = useSelector(SessionSelector.getCurrentUser);
  const roleNames = useRoleNames();

  const [adminEmail, ...bcc] = useAdminEmails();

  const closePaywall = React.useCallback(() => {
    setPaywall(null);
  }, [setPaywall]);

  const mailto = buildMailTo({
    to: adminEmail,
    subject: `Someone on your team is requesting an upgrade on your Process Street plan`,
    body: `A ${roleNames.FreeMember.single} on your Process Street account${
      currentUser?.username ? `, ${currentUser.username}, ` : ''
    }would like you to upgrade them to a ${roleNames.FullMember.single}. ${
      roleNames.FullMember.plural
    } can create and run workflows.\n\nUpgrade here: https://app.process.st/organizations/manage/billing`,
    bcc,
  });

  return (
    <PaywallModal>
      <PaywallHeader>
        <PaywallCloseButton onClick={closePaywall} />
      </PaywallHeader>

      <PaywallBodyWithIcon>
        <PaywallIconUserLock />

        <PaywallBodyHeading>Ask your {orgName ? `${orgName} ` : ''}admin for access</PaywallBodyHeading>

        <PaywallBodyDescription>
          Want to create your own workflows? Ask your {orgName ? `${orgName} ` : ''}admin to upgrade your account.
        </PaywallBodyDescription>
      </PaywallBodyWithIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallPrimaryActionLinkButton href={mailto} target="_blank">
          Send Request
        </PaywallPrimaryActionLinkButton>
      </PaywallFooter>
    </PaywallModal>
  );
};

export const FullMember: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setPaywall } = usePaywall();
  const { activeTemplatesLimit } = useFeatureSet();
  const orgId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const { name: orgName } = useSelector(OrganizationSelector.getById(orgId)) ?? {};

  const [adminEmail, ...bcc] = useAdminEmails();

  const closePaywall = React.useCallback(() => {
    setPaywall(null);
  }, [setPaywall]);

  const mailto = buildMailTo({
    to: adminEmail,
    subject: `Someone on your team is requesting an upgrade on your Process Street plan`,
    body: `Help your team create and run more workflows in Process Street.\n\nUpgrade here: https://app.process.st/organizations/manage/billing`,
    bcc,
  });

  return (
    <PaywallModal>
      <PaywallHeader>
        <PaywallCloseButton onClick={closePaywall} />
      </PaywallHeader>

      <PaywallBodyWithIcon>
        <PaywallIconTemplate />

        <PaywallBodyHeading>{orgName} has reached workflow limit</PaywallBodyHeading>

        {activeTemplatesLimit === 1 ? (
          <PaywallBodyDescription>
            Your team is using the <PaywallBodyDescriptionEmphasis>1</PaywallBodyDescriptionEmphasis> workflow that
            comes with your Free plan.
          </PaywallBodyDescription>
        ) : (
          <PaywallBodyDescription>
            Your team is using the{' '}
            <PaywallBodyDescriptionEmphasis>{activeTemplatesLimit}</PaywallBodyDescriptionEmphasis> workflows that come
            with your Free plan.
          </PaywallBodyDescription>
        )}

        <PaywallBodyDescription>
          Get <PaywallBodyDescriptionEmphasis>unlimited</PaywallBodyDescriptionEmphasis> workflows by requesting your
          admin upgrade to Pro.
        </PaywallBodyDescription>
      </PaywallBodyWithIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallPrimaryActionLinkButton href={mailto} target="_blank">
          Send Request
        </PaywallPrimaryActionLinkButton>
      </PaywallFooter>
    </PaywallModal>
  );
};

export const Admin: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setPaywall } = usePaywall();
  const { activeTemplatesLimit } = useFeatureSet();

  const orgId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const { name: orgName } = useSelector(OrganizationSelector.getById(orgId)) ?? {};

  const closePaywall = React.useCallback(() => {
    setPaywall(null);
  }, [setPaywall]);

  const contactSales = React.useCallback(() => {
    AnalyticsService.trackEvent('contact us link clicked', { location: 'create template' });
    IntercomService.show();
  }, []);

  return (
    <PaywallModal>
      <PaywallHeaderWithIcon bgColor="indigo.100">
        <PaywallCloseButton onClick={closePaywall} mb="4" />

        <PaywallAdminHeaderIcon>
          <PaywallAdminIconTemplate />
        </PaywallAdminHeaderIcon>
      </PaywallHeaderWithIcon>

      <PaywallBodyNoIcon>
        <PaywallBodyAdminHeading>{orgName} has reached workflow limit</PaywallBodyAdminHeading>

        {activeTemplatesLimit === 1 ? (
          <PaywallBodyDescription>
            Your team is using the <PaywallBodyDescriptionEmphasis>1</PaywallBodyDescriptionEmphasis> workflow that
            comes with your Free plan.
          </PaywallBodyDescription>
        ) : (
          <PaywallBodyDescription>
            Your team is using the{' '}
            <PaywallBodyDescriptionEmphasis>{activeTemplatesLimit}</PaywallBodyDescriptionEmphasis> workflows that come
            with your Free plan.
          </PaywallBodyDescription>
        )}

        <PaywallAdminBodyDescription>
          Get <PaywallBodyDescriptionEmphasis>unlimited</PaywallBodyDescriptionEmphasis> workflows by upgrading to Pro.
        </PaywallAdminBodyDescription>

        <PaywallFeatures />
      </PaywallBodyNoIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallButtonGroup>
          <PaywallSecondaryActionButton onClick={contactSales} className="intercom-bot-contact-sales">
            Contact Sales
          </PaywallSecondaryActionButton>

          <PaywallPrimaryActionLinkButton href="/organizations/manage/billing">
            Upgrade Now
          </PaywallPrimaryActionLinkButton>
        </PaywallButtonGroup>
      </PaywallFooter>
    </PaywallModal>
  );
};

export const UIs = {
  [OrganizationMembershipRole.FreeMember]: FreeMember,
  [OrganizationMembershipRole.FullMember]: FullMember,
  [OrganizationMembershipRole.Admin]: Admin,
  [OrganizationMembershipRole.Guest]: () => null,
};
