import {
  EmailFormFieldValue,
  FieldType,
  FormFieldValue,
  FormFieldWidget,
  HiddenFormFieldValue,
  MembersFormFieldValue,
  NumberFormFieldValue,
  SelectFormFieldValue,
  TextareaFormFieldValue,
  TextFormFieldValue,
  UrlFormFieldValue,
} from '@process-street/subgrade/process';

// This is designed to match the backend implementation in Condition#convertFormFieldValueToOperand in the backend
function convertToStringOperand(formFieldWidget: FormFieldWidget, formFieldValue?: FormFieldValue): string | null {
  if (formFieldValue === null || formFieldValue === undefined) return null;
  switch (formFieldWidget.fieldType) {
    case FieldType.Text:
      return (formFieldValue as TextFormFieldValue).fieldValue.value ?? null;
    case FieldType.Textarea:
      return (formFieldValue as TextareaFormFieldValue).fieldValue.value ?? null;
    case FieldType.Select:
      return (formFieldValue as SelectFormFieldValue).fieldValue.value ?? null;
    case FieldType.Hidden:
      return (formFieldValue as HiddenFormFieldValue).fieldValue.value ?? null;
    case FieldType.Email:
      return (formFieldValue as EmailFormFieldValue).fieldValue.value ?? null;
    case FieldType.Url:
      return (formFieldValue as UrlFormFieldValue).fieldValue.value ?? null;
    case FieldType.Number:
      return (formFieldValue as NumberFormFieldValue).fieldValue.value ?? null;
    case FieldType.Members:
      return (formFieldValue as MembersFormFieldValue).fieldValue.organizationMembershipIds?.[0] ?? null;
    default:
      return null;
  }
}

export const FormFieldValueConverter = {
  convertToStringOperand,
};
