import axios from 'axios';

export type UploadToS3MutationParams = {
  file: File;
  url: string;
  data: string | ArrayBuffer;
  onProgress?: (value: number) => void;
};

export type UploadToS3MutationResponse = { url: string; key: string };

export type ProgressEvent = {
  lengthComputable: boolean;
  loaded: number;
  total: number;
};
export const UploadToS3Mutation = {
  key: ['upload-to-s3'],
  mutationFn: ({ onProgress = () => {}, ...params }: UploadToS3MutationParams) =>
    axios
      .put<UploadToS3MutationResponse>(params.url, params.data, {
        headers: { 'Content-Type': params.file.type },
        onUploadProgress: (event: ProgressEvent) => {
          onProgress((100 * event.loaded) / event.total);
        },
      })
      .then(r => r.data),
};
