import { ICellRendererParams } from '@ag-grid-community/core';
import {
  DateFieldValue,
  FieldType,
  FileFieldValue,
  MembersFieldValue,
  MultiOptionFieldValue,
  SendRichEmailFieldValue,
  SimpleFieldValue,
} from '@process-street/subgrade/process';
import { FormFieldDto } from '@process-street/subgrade/dashboard';
import { UrlUtils } from 'components/utils/url-utils';
import React from 'react';
import { DateFieldRenderer } from './DateFieldRenderer';
import { EmailFieldRenderer } from './EmailFieldRenderer';
import { FileFieldRenderer } from './FileFieldRenderer';
import styles from './FormFieldValueRenderer.module.scss';
import { MembershipFieldRenderer } from './MembershipFieldRenderer';
import { MultiChoiceFieldRenderer } from './MultiChoiceFieldRenderer';
import { MultiSelectFieldRenderer } from './MultiSelectFieldRenderer';
import { UrlFieldRenderer } from './UrlFieldRenderer';
import { Icon, IconType } from 'components/design/Icon';
import { SendRichEmailFieldRenderer } from './SendRichEmailFieldRenderer';
import { MergeTagHighlighter } from 'features/merge-tags/components/merge-tag-highlighter';
import { TableFieldRenderer } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers/FormFieldValueRenderer/TableFieldRenderer/TableFieldRenderer';

interface FormFieldValueRendererProps extends Partial<ICellRendererParams> {
  value?: FormFieldDto;
  itemId?: string;
}

export const FormFieldValueRenderer: React.FunctionComponent<React.PropsWithChildren<FormFieldValueRendererProps>> = ({
  value,
  itemId,
}) => {
  if (!value) {
    return <div />;
  }

  if (value.hidden) {
    return (
      <div>
        <Icon iconType={IconType.EyeSlash} className={styles.hidden} />
      </div>
    );
  }

  switch (value.fieldType) {
    case FieldType.Date:
      return <DateFieldRenderer value={(value.fieldValue as DateFieldValue).value} />;
    case FieldType.Email:
      return <EmailFieldRenderer value={(value.fieldValue as SimpleFieldValue).value} />;
    case FieldType.SendRichEmail:
      return <SendRichEmailFieldRenderer value={value.fieldValue as SendRichEmailFieldValue} />;
    case FieldType.Url:
      const url = (value.fieldValue as SimpleFieldValue).value as string;
      const external = !UrlUtils.isAppUrl(url);

      return <UrlFieldRenderer url={url} external={external} />;
    case FieldType.File:
      return <FileFieldRenderer value={value.fieldValue as FileFieldValue} />;
    case FieldType.Members:
      return (
        <MembershipFieldRenderer
          organizationMembershipIds={(value.fieldValue as MembersFieldValue).organizationMembershipIds ?? []}
        />
      );

    case FieldType.MultiChoice:
      return <MultiChoiceFieldRenderer value={value} />;
    case FieldType.MultiSelect:
      return <MultiSelectFieldRenderer value={value.fieldValue as MultiOptionFieldValue} itemId={itemId} />;
    case FieldType.Table:
      return <TableFieldRenderer value={value} />;

    default:
      return (
        <div className={styles.trimmedLabel}>
          <MergeTagHighlighter
            lineHeight="1.3"
            content={((value.fieldValue as SimpleFieldValue).value ?? '').toString()}
          />
        </div>
      );
  }
};
