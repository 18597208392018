import { Checklist, ChecklistStatus } from '@process-street/subgrade/process';
import React from 'react';
import ReactConfetti from 'react-confetti';
import { useWindowSize } from 'react-use';

const CONFETTI_DURATION = 15000;

export const Confetti = ({ checklist }: { checklist: Checklist }) => {
  const { width, height } = useWindowSize();
  const checklistStatusRef = React.useRef(checklist.status);
  const [shouldDropConfetti, setShouldDropConfetti] = React.useState(false);

  React.useEffect(() => {
    if (checklist.status === ChecklistStatus.Completed && checklistStatusRef.current !== ChecklistStatus.Completed) {
      checklistStatusRef.current = ChecklistStatus.Completed;
      setShouldDropConfetti(true);
      setTimeout(() => {
        setShouldDropConfetti(false);
      }, CONFETTI_DURATION);
    }
  }, [checklist.status]);

  if (shouldDropConfetti) {
    return <ReactConfetti width={width} height={height} initialVelocityX={7} initialVelocityY={7} gravity={0.2} />;
  } else {
    return null;
  }
};
