import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type DeleteChecklistAssignmentMutationParams = {
  assignmentId: Muid;
};

export type DeleteChecklistAssignmentMutationResponse = {};

export const DeleteChecklistAssignmentMutation = {
  key: ['delete', 'checklist-assignments'],
  mutationFn: ({ assignmentId }: DeleteChecklistAssignmentMutationParams) =>
    axiosService
      .getAxios()
      .delete<DeleteChecklistAssignmentMutationResponse>(`/1/checklist-assignments/${assignmentId}`)
      .then(res => res.data),
};
export const useDeleteChecklistAssignmentMutation = (
  options: UseMutationOptions<
    DeleteChecklistAssignmentMutationResponse,
    AxiosError,
    DeleteChecklistAssignmentMutationParams
  > = {},
) => {
  return useMutation(DeleteChecklistAssignmentMutation.mutationFn, options);
};
