import * as React from 'react';
import { FormControl, FormErrorMessage, FormLabel, InputProps, Text, Textarea, VStack } from 'components/design/next';
import { useField } from 'formik';
import { EmailFormFieldConstraints } from '@process-street/subgrade/process';
import { BlvdSelect } from 'components/design/BlvdSelect';

export const EmailDomainFields: React.FC<React.PropsWithChildren<InputProps>> = () => {
  const [restrictionField, restrictionFieldMeta, restrictionHelpers] =
    useField<EmailFormFieldConstraints['restriction']>(EmailRestrictionFieldName);
  const [domainsField, domainsFieldMeta, { setValue }] = useField<string[] | undefined>(EmailDomainsFieldName);

  const onDomainsChange = (value: string) => {
    const domains = value.trim() !== '' ? value.split(',').map((d: string) => d.trim()) : undefined;

    setValue(domains);
  };

  const domains = (domainsField.value ?? []).join(',');

  const restrictionOptions = [
    { label: 'Allow specific domains', value: 'Allow' as const },
    { label: 'Block specific domains', value: 'Block' as const },
  ];
  const restrictionOptionsMap = new Map(restrictionOptions.map(o => [o.value, o]));
  const domainsRef = React.useRef<HTMLTextAreaElement>(null);

  return (
    <VStack spacing={2} width="full">
      <FormControl isInvalid={restrictionFieldMeta.touched && !!restrictionFieldMeta.error}>
        <FormLabel>
          <Text variant="1" fontWeight="medium" color="gray.700">
            Domain Validation
          </Text>
        </FormLabel>
        <BlvdSelect
          value={restrictionField.value ? restrictionOptionsMap.get(restrictionField.value) : null}
          placeholder="Select option"
          aria-label="domain validation"
          isInvalid={restrictionFieldMeta.touched && !!restrictionFieldMeta.error}
          isClearable
          options={restrictionOptions}
          onChange={value => {
            if (value?.value) {
              restrictionHelpers.setValue(value.value);
              // async to queue after blvdselect focus events
              setTimeout(() => domainsRef.current?.select());
            } else {
              restrictionHelpers.setValue(undefined);
              setValue(undefined);
            }
          }}
        />
      </FormControl>
      <FormControl isInvalid={domainsFieldMeta.touched && !!domainsFieldMeta.error}>
        <Textarea
          {...domainsField}
          ref={domainsRef}
          isDisabled={restrictionField.value === undefined}
          placeholder="gmail.com,hotmail.com"
          aria-label="list of domains"
          onChange={e => onDomainsChange(e.target.value)}
          value={domains}
          variant="outline"
          resize="none"
          isInvalid={domainsFieldMeta.touched && !!domainsFieldMeta.error}
        />
        {domainsFieldMeta.error ? <FormErrorMessage>{domainsFieldMeta.error}</FormErrorMessage> : null}
      </FormControl>
    </VStack>
  );
};

export const EmailRestrictionFieldName = 'constraints.restriction';
export const EmailDomainsFieldName = 'constraints.domains';
