import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { Button, ButtonGroup, Spacer } from 'components/design/next';
import React from 'react';
import { CommonProps, GroupBase, OnChangeValue } from 'react-select';
import { ThemeName, useThemeName } from 'components/design/next/theme-name-provider';

export const MenuControlsFooter = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: CommonProps<Option, IsMulti, Group>,
) => {
  const selectedOptionsCount = BlvdSelectHelpers.valuesCount(props.getValue());

  const clearValue = () => {
    props.clearValue();
  };

  const flatOptions = React.useMemo(
    () => props.options.flatMap(BlvdSelectHelpers.getGroupOptionsOrOption),
    [props.options],
  );

  const selectAll = () => {
    const { filterOption, inputValue, getOptionLabel, getOptionValue } = props.selectProps;
    const filteredOptions = flatOptions.filter(
      option =>
        filterOption?.(
          {
            value: getOptionValue?.(option) ?? '',
            label: getOptionLabel?.(option) ?? '',
            data: option,
          },
          inputValue ?? '',
        ) ?? true,
    ) as OnChangeValue<Option, IsMulti>;
    props.setValue(filteredOptions, 'select-option');
  };

  const done = () => {
    props.selectProps.close?.();
  };

  const isSelectAllDisabled = selectedOptionsCount === flatOptions.length;

  const isClearAllDisabled = selectedOptionsCount === 0;

  const is2024Theme = useThemeName() === ThemeName.TwentyFour;

  const selectAllButton = (
    <Button
      h={6}
      fontSize="12px"
      onClick={selectAll}
      onTouchEnd={selectAll}
      isDisabled={isSelectAllDisabled}
      fontWeight="normal"
    >
      Select All
    </Button>
  );
  const clearAllButton = (
    <Button
      h={6}
      fontSize="12px"
      onClick={clearValue}
      onTouchEnd={clearValue}
      isDisabled={isClearAllDisabled}
      fontWeight="normal"
    >
      Clear All
    </Button>
  );

  return (
    <ButtonGroup
      w="full"
      className="blvd-select__menu__footer"
      variant="ghost"
      colorScheme="gray"
      display="flex"
      py={1}
      px={1}
      onTouchEnd={e => {
        // fix Dropdown closing on mobile touch
        e.stopPropagation();
        e.preventDefault();
      }}
      size={is2024Theme ? 'sm' : 'xs'}
    >
      {is2024Theme ? (
        <>
          {clearAllButton}
          <Spacer />
          {selectAllButton}
        </>
      ) : (
        <>
          {selectAllButton}
          {clearAllButton}
          <Spacer />
          <Button h={6} onClick={done} fontWeight="normal">
            Done
          </Button>
        </>
      )}
    </ButtonGroup>
  );
};
