import angular from 'angular';
import { SessionSelector } from 'reducers/session/session.selectors';
import { connectController } from 'reducers/util';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { shouldShowUser, UserType } from '@process-street/subgrade/core';
import { uuid } from 'services/uuid';
import { ChecklistTaskAssignmentSelector } from '../store/checklist-task-assignment.selectors';
import { ArrayService } from 'services/array-service';
import templateUrl from './add-checklist-task-assignment.component.html';
import './add-checklist-task-assignment.scss';
import { EmailService } from 'services/email-service';
import { Key } from 'services/key';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { queryClient } from 'components/react-root';

export const AddChecklistTaskAssignmentComponent = {
  transclude: true,
  bindings: {
    task: '<',
    title: '<',
    helpText: '<',
    disableGuests: '<',
    disableGroups: '<',
    disabled: '<',
  },
  require: {
    checklistTaskAssignerCtrl: '^?psChecklistTaskAssigner',
  },
  templateUrl,
  controller: class AddChecklistTaskAssignmentComponent {
    title = 'Assign';

    user = { email: '' };

    constructor(
      $ngRedux,
      ChecklistTaskAssignmentActions,
      focusById,
      OrganizationMembershipActions,
      ToastService,
      UserService,
    ) {
      'ngInject';

      this.$ngRedux = $ngRedux;
      this.scopeId = uuid();
      this.focusById = focusById;
      this.ToastService = ToastService;
      this.UserService = UserService;
      this.ChecklistTaskAssignmentActions = ChecklistTaskAssignmentActions;
      this.OrganizationMembershipActions = OrganizationMembershipActions;
    }

    shouldChange = changes => changes.task && changes.task.currentValue;

    $onInit() {
      const mapStateToThis = () => state => {
        const selectedOrganizationId = SessionSelector.getSelectedOrganizationId(state);
        const assignments = ChecklistTaskAssignmentSelector.getAllByTaskIdWithOrganizationMembershipAndUser(
          this.task.id,
        )(state);
        const assignedUsers = assignments.map(assignment => assignment.organizationMembership.user);
        const featureFlags = FeatureFlagSelector.getFeatureFlags(state);

        // prettier-ignore
        const assignableOrganizationMemberships =
          OrganizationMembershipSelector.getAllAssignableBySelectedOrganizationId(state);
        const assignableUsers = this._filterAvailableForAssignment(
          assignableOrganizationMemberships,
          assignedUsers,
        ).map(membership => membership.user);

        const organizationMembershipMapByUserId =
          OrganizationMembershipSelector.getAllByOrganizationIdMapByUserId(selectedOrganizationId)(state);

        return {
          selectedOrganizationId,
          assignments,
          assignedUsers,
          assignableUsers,
          organizationMembershipsMap: organizationMembershipMapByUserId,
          isUserDeactivationEnabled: featureFlags.deactivatingUsers,
        };
      };

      const mapDispatchToThis = () => ({
        getAllOrgMembershipByOrgId: this.OrganizationMembershipActions.getAllOrgMembershipByOrgId,
        getAllAssignmentsByChecklistRevisionId: this.ChecklistTaskAssignmentActions.getAllByChecklistRevisionId,
        assign: this.ChecklistTaskAssignmentActions.assign,
      });

      connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, this.shouldChange)(this);

      this.actions.getAllOrgMembershipByOrgId(this.state.selectedOrganizationId);
      this.actions.getAllAssignmentsByChecklistRevisionId(this.task.checklistRevision.id);

      this._registerWithParent();
    }

    _registerWithParent() {
      if (
        this.checklistTaskAssignerCtrl &&
        angular.isFunction(this.checklistTaskAssignerCtrl.registerTaskAssignmentCtrl)
      ) {
        this.checklistTaskAssignerCtrl.registerTaskAssignmentCtrl(this);
      }
    }

    onShow() {
      this.focusById('user-email-' + this.scopeId);
    }

    $onDestroy() {
      this.user = { email: '' };
    }

    _filterAvailableForAssignment(memberships, assignedUsers) {
      return memberships.filter(membership => {
        if (
          membership.user.userType === UserType.Group &&
          (membership.user.username === 'All Members' ||
            membership.user.username === 'All Anonymous' ||
            membership.user.username === 'All Guests')
        ) {
          return false;
        }

        if (this.disableGuests && membership.guest) {
          return false;
        }

        if (this.disableGroups && membership.user.userType === UserType.Group) {
          return false;
        }

        return !ArrayService.includes(assignedUsers, membership.user, 'id');
      });
    }

    MAX_SUGGESTIONS = 5;

    getSuggestions(partial) {
      const trimmedPartial = partial.toLowerCase().trim();

      return this.state.assignableUsers
        .filter(user => {
          const userMatches =
            user.email.toLowerCase().includes(trimmedPartial) || user.username.toLowerCase().includes(trimmedPartial);

          return (
            userMatches &&
            shouldShowUser(user, this.state.organizationMembershipsMap, this.state.isUserDeactivationEnabled)
          );
        })
        .slice(0, this.MAX_SUGGESTIONS);
    }

    selectUserOnEnter(event, user) {
      if (event.keyCode === Key.ENTER) {
        this.selectUser(event, user);
      }
    }

    selectUser(event, user) {
      // If this is not here, then the box will dismiss after being clicked
      event.stopPropagation();

      this.user = { email: '' };
      return this.actions
        .assign(this.task.id, user.email, true /*allowInvite*/)
        .then(data => {
          GetInboxItemsQuery.invalidate(queryClient);

          return data;
        })
        .catch(response => {
          this.ToastService.openToast({
            status: 'warning',
            title: `We couldn't assign ${user.email}`,
            description: DefaultErrorMessages.getUserInactiveDescription(user.email, response),
          });
        });
    }

    getUserLabel(user) {
      return user && this.UserService.getLabel(user);
    }

    expand(email) {
      this.user = {
        email: EmailService.expandEmailAddress(email),
      };
    }
  },
};
