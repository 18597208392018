import {
  DateFormFieldConstraintsSourceType,
  FormFieldWidget,
  TaskConstants,
  TaskTemplate,
} from '@process-street/subgrade/process';
import { ActionMeta, BlvdSelect, BlvdSelectHelpers, OnChangeValue } from 'components/design/BlvdSelect';
import { IconType } from 'components/design/Icon';
import * as React from 'react';
import { Option } from '@process-street/subgrade/core';
import { DatePicker } from 'features/conditional-logic/components/date-picker';
import { useCurrentUser } from 'hooks/use-current-user';
import { Box, Text, useDisclosure } from 'components/design/next';
import { DateUtils } from '@process-street/subgrade/util';

export type ConstraintSourceSelectorProps = {
  dateWidgets: FormFieldWidget[];
  taskTemplates: TaskTemplate[];
  onSelect: (option: ConstraintSourceOption) => void;
  onSelectSpecificDate: () => void;
};

export type ConstraintSourceOption = {
  label: string;
  value: number | string;
  icon?: IconType;
  type: DateFormFieldConstraintsSourceType;
};

export const ConstraintSourceSelector: React.FC<React.PropsWithChildren<ConstraintSourceSelectorProps>> = ({
  dateWidgets,
  taskTemplates,
  onSelect,
  onSelectSpecificDate,
}) => {
  const currentUser = useCurrentUser();
  const [selectedValue, setSelectedValue] = React.useState<Option<ConstraintSourceOption>>(undefined);
  const datePickerDisclosure = useDisclosure();

  const handleChange = (
    constraintSourceOption: OnChangeValue<ConstraintSourceOption, false>,
    actionMeta: ActionMeta<ConstraintSourceOption>,
  ) => {
    if (
      actionMeta.action === 'select-option' &&
      BlvdSelectHelpers.isOptionType<ConstraintSourceOption>(constraintSourceOption)
    ) {
      setSelectedValue(constraintSourceOption);

      if (constraintSourceOption.type === DateFormFieldConstraintsSourceType.SpecificDate) {
        datePickerDisclosure.onOpen();
        onSelectSpecificDate();
      } else {
        onSelect(constraintSourceOption);
      }
    }
  };

  const dateWidgetOptionsByTask = dateWidgets.reduce((accum, widget) => {
    const taskTemplateId = widget.header.taskTemplate.id;
    const taskTemplate = taskTemplates.find(t => t.id === taskTemplateId);

    if (taskTemplate) {
      const taskTemplateName = taskTemplate.name ?? TaskConstants.DefaultTaskName;
      const taskIndex = taskTemplates.indexOf(taskTemplate) + 1;
      const label = `${taskIndex}. ${taskTemplateName}`;
      const existingGroupIndex = accum.findIndex(group => group.label === label);

      if (existingGroupIndex === -1) {
        // New group for this task template
        accum.push({
          label,
          options: [
            {
              label: widget.label ?? 'Select date',
              value: widget.header.group.id,
              icon: IconType.CalendarAlt,
              type: DateFormFieldConstraintsSourceType.FormFieldValue,
            },
          ],
        });
      } else {
        // Existing group found, add new widget to it
        const newOption = {
          label: widget.label ?? 'Select date',
          value: widget.header.group.id,
          icon: IconType.CalendarAlt,
          type: DateFormFieldConstraintsSourceType.FormFieldValue,
        };
        const updatedGroup = {
          ...accum[existingGroupIndex],
          options: [...accum[existingGroupIndex].options, newOption],
        };

        accum[existingGroupIndex] = updatedGroup;
      }
    }

    return accum;
  }, [] as { label: string; options: ConstraintSourceOption[] }[]);

  const specificDateOption = {
    label: 'Specific date',
    options: [{ label: 'Specific date', value: '', type: DateFormFieldConstraintsSourceType.SpecificDate }],
  };

  const otherOptions = {
    label: 'Other',
    options: [
      { label: 'Task due date', value: '', type: DateFormFieldConstraintsSourceType.TaskDueDate },
      { label: 'Workflow run created date', value: '', type: DateFormFieldConstraintsSourceType.ChecklistStartDate },
      { label: 'Workflow run due date', value: '', type: DateFormFieldConstraintsSourceType.ChecklistDueDate },
    ],
  };

  const [datePickerValue, setDatePickerValue] = React.useState<number | null>(null);

  const handleChangeDatePicker = (date: number | null) => {
    setDatePickerValue(date);
    if (date) {
      onSelect({
        label: 'Specific date',
        value: date,
        type: DateFormFieldConstraintsSourceType.SpecificDate,
      });
    }
    datePickerDisclosure.onClose();
  };

  const options = [specificDateOption, ...dateWidgetOptionsByTask, otherOptions];

  const formatDate = (date: number): string =>
    DateUtils.formatDateToMonthDay(date, { showYearIfPast: true, timeZone: currentUser?.timeZone });

  return (
    <>
      {datePickerValue && (
        <Box w="full" display="flex" justifyContent="center">
          <Text px="2" fontWeight="bold" color="gray.600">
            {formatDate(datePickerValue)}
          </Text>
        </Box>
      )}
      {datePickerDisclosure.isOpen && (
        <DatePicker
          hideTime={true}
          onChange={handleChangeDatePicker}
          value={datePickerValue}
          timeZone={currentUser?.timeZone}
          popoverProps={{
            isOpen: datePickerDisclosure.isOpen,
          }}
          pickerProps={{
            styles: {
              cell: {
                fontSize: 'var(--ps-fontSizes-sm)',
              },
            },
          }}
          size="md"
        />
      )}

      {!datePickerDisclosure.isOpen && !datePickerValue && (
        <BlvdSelect
          styles={{
            singleValue: ({ ...css }) => ({ ...css, fontSize: 'var(--ps-fontSizes-sm) !important' }),
            control: ({ ...css }) => ({ ...css, fontSize: 'var(--ps-fontSizes-sm) !important' }),
            container: ({ ...css }) => ({ ...css, fontSize: 'var(--ps-fontSizes-sm) !important', width: '100%' }),
            placeholder: ({ ...css }) => ({ ...css, fontSize: 'var(--ps-fontSizes-sm) !important' }),
            option: ({ ...css }) => ({ ...css, fontSize: 'var(--ps-fontSizes-sm) !important' }),
          }}
          options={options}
          value={selectedValue ?? null}
          isSearchable={true}
          placeholder={'Choose rule...'}
          searchPlaceholder={'Search...'}
          onChange={handleChange}
        />
      )}
    </>
  );
};
