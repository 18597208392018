import { DetailSection } from 'pages/organizations/manage/billing/models/models';
import { Box, Heading, List } from 'components/design/next';
import * as React from 'react';
import { PricingDetail } from './PricingDetail';

type PlanDetailsSectionProps = {
  section: DetailSection;
  colorSchema: string;
};

export const PlanDetailsSection = ({ section, colorSchema }: PlanDetailsSectionProps) => (
  <Box>
    <Heading fontSize="small" textTransform="uppercase">
      {section.header}
    </Heading>
    <List spacing="4">
      {section.items.map((item, index) => (
        <PricingDetail key={index} iconColor={`${colorSchema}.500`}>
          {item}
        </PricingDetail>
      ))}
    </List>
  </Box>
);
