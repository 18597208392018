import * as React from 'react';
import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { useTemplateId } from '../../automation/utils/use-template-id';
import { match } from 'ts-pattern';

const SelectedTemplateTriggersEdit = React.lazy(() =>
  import('./selected-template-triggers-edit').then(({ SelectedTemplateTriggersEdit }) => ({
    default: SelectedTemplateTriggersEdit,
  })),
);
const SelectedTemplateTriggersView = React.lazy(() =>
  import('./selected-template-triggers-view').then(({ SelectedTemplateTriggersView }) => ({
    default: SelectedTemplateTriggersView,
  })),
);

const SelectedTemplateTriggersRun = React.lazy(() =>
  import('./selected-template-triggers-run').then(({ SelectedTemplateTriggersRun }) => ({
    default: SelectedTemplateTriggersRun,
  })),
);
const TriggersModal = React.lazy(() =>
  import('features/automations/components/workflow-automations/components/template/modal').then(
    ({ TriggersModal }) => ({
      default: TriggersModal,
    }),
  ),
);

export const SelectedTemplateTriggers: React.FC<React.PropsWithChildren<unknown>> = () => {
  const workflowState = useWorkflowState();
  const templateId = useTemplateId();
  const templateRevisionQuery = useNewestTemplateRevisionQuery(
    { templateId, editable: workflowState === 'edit' },
    { enabled: workflowState === 'edit' || workflowState === 'view' },
  );
  const templateRevision = templateRevisionQuery.data;

  return (
    <React.Suspense fallback="">
      {match(workflowState)
        .with('edit', () => <SelectedTemplateTriggersEdit />)
        .with('view', () => <SelectedTemplateTriggersView />)
        .with('run', () => <SelectedTemplateTriggersRun />)
        .otherwise(() => null)}
      {templateRevision ? <TriggersModal templateRevision={templateRevision} /> : null}
    </React.Suspense>
  );
};
