import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UnassignTaskTemplateMutationParams = {
  email: string;
  taskTemplateId: Muid;
  assignmentId: Muid;
};

export type UnassignTaskTemplateMutationResponse = void;

export const UnassignTaskTemplateMutation = {
  key: ['delete', 'task-template-assignment'],
  mutationFn: (params: UnassignTaskTemplateMutationParams) =>
    axiosService
      .getAxios()
      .delete<UnassignTaskTemplateMutationResponse>(
        `/1/task-templates/${params.taskTemplateId}/assignments/${params.email}`,
      )
      .then(res => res.data),
};

type UnassignTaskTemplateMutationContext = { assigneeLabel?: string };

export const useUnassignTaskTemplateMutation = (
  options: UseMutationOptions<
    UnassignTaskTemplateMutationResponse,
    AxiosError,
    UnassignTaskTemplateMutationParams,
    UnassignTaskTemplateMutationContext
  > = {},
) => {
  return useMutation(UnassignTaskTemplateMutation.mutationFn, options);
};
