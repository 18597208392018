import { Hit } from 'react-instantsearch-core';

export namespace GlobalSearchHits {
  type TemplatesResultDoc = {
    name: string;
    description?: string;
    tasks?: string;
    templateType: string;
    content?: string;
    coverIconEmoji?: string;
  };

  type SavedViewsResultDoc = {
    name: string;
    dataSetId: string;
  };

  type IHit<T> = Hit<T> & {
    _snippetResult?: Hit['_highlightResult'];
    _rankingInfo?: {
      userScore: number;
    };
  };

  export type TemplateHit = IHit<TemplatesResultDoc>;
  export type SavedViewHit = IHit<SavedViewsResultDoc>;
  export type AnyHit = SavedViewHit | TemplateHit;

  export function isSavedViewHit(hit: AnyHit): hit is SavedViewHit {
    return 'dataSetId' in hit;
  }

  export function isTemplateHit(hit: AnyHit): hit is TemplateHit {
    return 'templateType' in hit;
  }
}
