import { ICellRendererParams } from '@ag-grid-community/core';
import { TaskStatus } from '@process-street/subgrade/process';
import { TaskDto } from 'components/dashboard/models/grid';
import { Icon, IconType } from 'components/design/Icon';
import React from 'react';
import styles from './TaskStatusRenderer.module.scss';

interface TaskStatusRendererProps extends Partial<ICellRendererParams> {
  value?: TaskDto;
}

export const TaskStatusRenderer: React.FC<React.PropsWithChildren<TaskStatusRendererProps>> = ({ value }) => {
  if (!value) {
    return <div />;
  }

  const { hidden } = value;
  const completed = !hidden && value.status === TaskStatus.Completed;

  return (
    <div>
      {hidden && <Icon iconType={IconType.EyeSlash} className={styles.hidden} />}
      {completed && <Icon iconType={IconType.Check} className={styles.completed} />}
    </div>
  );
};
