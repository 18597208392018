import { Muid } from '@process-street/subgrade/core';
import { TemplateTaskAssignment } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions, QueryObserverOptions, QueryObserver, QueryClient } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetTemplateTaskAssignmentsByTemplateRevisionIdParams = { templateRevisionId?: Muid };
export type GetTemplateTaskAssignmentsByTemplateRevisionIdResult = TemplateTaskAssignment[];

export const getAllTaskTemplateAssignmentsByTemplateRevisionId = (
  params: GetTemplateTaskAssignmentsByTemplateRevisionIdParams,
) => {
  return axiosService
    .getAxios()
    .get<GetTemplateTaskAssignmentsByTemplateRevisionIdResult>(
      `/1/template-revisions/${params.templateRevisionId}/task-templates/assignments`,
    )
    .then(r => r.data);
};

export const GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery = {
  key: ['template-revisions', 'task-templates', 'assignments'],
  getKey: (params: GetTemplateTaskAssignmentsByTemplateRevisionIdParams): QueryKey => [
    ...GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery.key,
    params,
  ],
  queryFn: getAllTaskTemplateAssignmentsByTemplateRevisionId,
};

export const useGetAllTaskTemplatesAssignmentsByTemplateRevisionIdQuery = <
  Select = GetTemplateTaskAssignmentsByTemplateRevisionIdResult,
>(
  params: GetTemplateTaskAssignmentsByTemplateRevisionIdParams,
  options: UseQueryOptions<GetTemplateTaskAssignmentsByTemplateRevisionIdResult, AxiosError, Select> = {},
) => {
  return useQuery(
    GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery.getKey(params),
    () => GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery.queryFn(params),
    options,
  );
};

export const makeGetAllTaskTemplatesAssignmentsByTemplateRevisionIdQuery = ({
  queryClient,
  options,
  ...params
}: GetTemplateTaskAssignmentsByTemplateRevisionIdParams & {
  queryClient: QueryClient;
  options?: QueryObserverOptions<GetTemplateTaskAssignmentsByTemplateRevisionIdResult, AxiosError>;
}) => {
  return new QueryObserver<GetTemplateTaskAssignmentsByTemplateRevisionIdResult, AxiosError>(queryClient, {
    ...options,
    queryKey: GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery.getKey(params),
    queryFn: () => GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery.queryFn(params),
    enabled: Boolean(params.templateRevisionId) && options?.enabled !== false,
  });
};
