import * as React from 'react';
import { ChecklistHeaderActions } from 'app/directives/checklist-left-pane/header/actions';
import { FormResponsePageProviders } from '../../../../providers';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';

export const HeaderActionsMenu = () => {
  const { send } = FormResponsePageProviders.FormResponseActorRef.useActorRef();
  const shouldHideCompletedTasks = FormResponseMachineHooks.useShouldHideCompletedTasks();
  const handleToggleCompletedTasksVisibility = () => {
    send('TOGGLE_COMPLETED_TASKS');
  };

  return (
    <ChecklistHeaderActions
      shouldHideCompletedTasks={shouldHideCompletedTasks}
      onToggleCompletedTasksVisibility={handleToggleCompletedTasksVisibility}
    />
  );
};
