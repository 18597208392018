import { Activity, ActivityObjectType } from '@process-street/subgrade/activity';
import { Muid } from '@process-street/subgrade/core';
import { Checklist, Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { QueryKey, useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetAllActivitiesByTypeQueryParams = {
  type: 'checklist' | 'template' | 'organization' | 'user';
  limit?: number;
  offsetId?: Muid;
  checklistId?: Checklist['id'];
  templateId?: Template['id'];
  objectType?: ActivityObjectType;
};

export type GetAllActivitiesByTypeQueryResponse = {
  data: Activity[];
  hiddenTotal: number;
  total: number;
};

export const GetAllActivitiesByTypeQuery = {
  key: ['activities'],
  getKey: (params: GetAllActivitiesByTypeQueryParams): QueryKey => [...GetAllActivitiesByTypeQuery.key, params],
  queryFn: (params: GetAllActivitiesByTypeQueryParams) =>
    axiosService
      .getAxios()
      .get<GetAllActivitiesByTypeQueryResponse>(`/1/activities/${params.type}`, {
        params: {
          limit: params.limit ?? 1000,
          objectType: params.objectType,
          offsetId: params.offsetId,
          ...(params.type === 'checklist' && params.checklistId && { checklistId: params.checklistId }),
          ...(params.type === 'template' && params.templateId && { templateId: params.templateId }),
        },
      })
      .then(res => res.data),
};

export const useGetAllActivitiesByTypeQuery = <Select = GetAllActivitiesByTypeQueryResponse>(
  params: GetAllActivitiesByTypeQueryParams,
  options: UseQueryOptions<GetAllActivitiesByTypeQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetAllActivitiesByTypeQuery.getKey(params),
    () => GetAllActivitiesByTypeQuery.queryFn(params),
    options,
  );
};

export const useGetAllActivitiesByTypeInfiniteQuery = <Select = GetAllActivitiesByTypeQueryResponse>(
  initialParams: GetAllActivitiesByTypeQueryParams,
  options: UseInfiniteQueryOptions<GetAllActivitiesByTypeQueryResponse, AxiosError, Select> = {},
) => {
  return useInfiniteQuery(
    GetAllActivitiesByTypeQuery.getKey(initialParams),
    ({ pageParam = initialParams }) => GetAllActivitiesByTypeQuery.queryFn(pageParam),
    options,
  );
};
