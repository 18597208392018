import { S3File } from '@process-street/subgrade/core';
import { DropzoneState } from 'react-dropzone';

export const getHighlightColor = (dropzoneState: DropzoneState) => {
  if (dropzoneState.isDragAccept) {
    return 'brand.100';
  }
  if (dropzoneState.isDragReject || dropzoneState.fileRejections.length > 0) {
    return 'red.100';
  }
  if (dropzoneState.isDragActive) {
    return 'brand.100';
  }
};

export const isImageFile = (mimeType?: S3File['mimeType']) => mimeType?.startsWith('image/');
