import { Button, Divider, Flex, Icon, Link, MotionWrapper, Text, useBreakpointValue } from 'components/design/next';
import React from 'react';
import { useInjector } from 'components/injection-provider';
import { useStateParam } from 'hooks/use-state-param';
import { match } from 'ts-pattern';
import { useGetChecklistQuery } from 'features/checklists/query-builder';
import { useGetOrganizationTheme } from 'features/brand-customization/query-builder/get-organization-theme';
import { useBackgroundCdnImage } from 'features/forms/hooks/use-background-cdn-image';
import { useGetTemplateQuery } from 'features/template/query-builder';

const FORM_UTM_TRACKING = '?utm_campaign=forms_cta&utm_medium=referral&utm_source=shared_form';

export const FormResponseFinishPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { $state } = useInjector('$state');
  const checklistId = useStateParam({ key: 'id' });
  const checklistQuery = useGetChecklistQuery({ checklistId });
  const templateId = checklistQuery.data?.template.id;
  const templateQuery = useGetTemplateQuery({ templateId });
  const cdnImage = useBackgroundCdnImage({ templateId });
  const backgroundSize = useBreakpointValue({ base: 'contain', lg: 'cover' });
  const backgroundPosition = useBreakpointValue({ base: 'center top', lg: 'center center' });

  const headingVariant = useBreakpointValue({ base: '2', lg: '3' });

  const themeQuery = useGetOrganizationTheme({
    organizationId: checklistQuery.data?.organization.id,
  });
  const hasTheme = Boolean(themeQuery.data?.brandColor);

  const signUpUrl = $state.href('signUp', {}) + FORM_UTM_TRACKING;

  return (
    <>
      <Flex
        h="100vh"
        justifyContent="center"
        pt={{ base: '32', lg: '0' }}
        pb={{ base: '0', lg: '24' }}
        {...{
          ...match(cdnImage)
            .with({ status: 'loaded' }, ({ src }) => ({
              backgroundImage: `url(${src})`,
            }))
            .with({ lqipStatus: 'loaded' }, ({ lqipSrc }) => ({
              backgroundImage: `url(${lqipSrc}) `,
            }))
            .otherwise(() => {}),
          backgroundRepeat: 'no-repeat',
          backgroundPosition,
          backgroundAttachment: 'fixed',
          backgroundSize,
        }}
        alignItems="center"
        bgColor="brand.100"
      >
        <MotionWrapper layout>
          <Flex
            w={{ base: 'full', lg: '3xl' }}
            minH={{ base: 'calc(100vh - var(--ps-sizes-32))', lg: '77' }}
            h={{ base: 'calc(100vh - var(--ps-sizes-32))', lg: 'fit-content' }}
            bgColor="white"
            borderRadius={{ base: '0', lg: 'lg' }}
            p={{ base: '4', lg: '8' }}
            alignItems="center"
            justifyContent="center"
            direction="column"
            gap="4"
          >
            <Icon
              size="9"
              variant="fad"
              icon="rocket-launch"
              primaryLayer={{ color: 'brand.500' }}
              secondaryLayer={{ color: 'brand.500', opacity: 0.4 }}
            />

            {templateQuery.data?.name ? (
              <Text variant={headingVariant} fontWeight="bolder" color="gray.600" textAlign="center">
                Thank you for completing {templateQuery.data?.name}!
              </Text>
            ) : (
              <Text>&nbsp;</Text>
            )}

            {hasTheme ? null : (
              <>
                <Divider w="lg" />

                <Text variant="-1" color="gray.500">
                  Streamline data collection and task implementation with Process Street.
                </Text>

                <Button
                  variant="ghost"
                  as={Link}
                  target="_blank"
                  aria-label="sign up to process street to create your own forms"
                  href={signUpUrl}
                  border="1px"
                  borderColor="gray.300"
                  color="gray.600"
                  fontWeight="normal"
                  leftIcon={<Icon color="blue.500" size="5" variant="fab" icon="process-street" />}
                  _hover={{
                    textDecoration: 'none',
                    backgroundColor: 'gray.100',
                  }}
                >
                  Create your own form
                </Button>
              </>
            )}
          </Flex>
        </MotionWrapper>
      </Flex>
    </>
  );
};
