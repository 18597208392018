import { Center, Spinner } from '@chakra-ui/react';
import { InboxItem, InboxItemType } from '@process-street/subgrade/inbox';
import { isDateFormFieldWidget } from '@process-street/subgrade/process';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'app/features/checklist-revisions/query-builder';
import { GetDueDateRulesByChecklistRevisionIdQuery } from 'app/features/dynamic-due-dates/query-builder';
import { GetAllTaskTemplatesByChecklistRevisionIdQuery } from 'app/features/task-templates/query-builder/task-templates-by-checklist-revision-id';
import { WidgetsByChecklistRevisionIdQuery } from 'app/features/widgets/query-builder/widgets-by-checklist-revision-id';
import React from 'react';
import { match, P } from 'ts-pattern';
import { DynamicDueDate } from './dynamic-due-date';

interface DynamicDueDateOverrideProps {
  inboxItem: InboxItem;
  /** Rendered after user overrides dynamic due date */
  children: React.ReactNode;
}

export const DynamicDueDateOverride = ({ inboxItem, children }: DynamicDueDateOverrideProps) => {
  const [shouldOverrideDynamicDueDate, setShouldOverrideDynamicDueDate] = React.useState(false);

  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({
    checklistId: inboxItem.checklist.id,
  });
  const checklistRevisionId = checklistRevisionQuery.data?.id;

  const dynamicDueDatesQuery = GetDueDateRulesByChecklistRevisionIdQuery.useQuery(
    {
      checklistRevisionId: checklistRevisionId ?? '',
    },
    {
      enabled: Boolean(checklistRevisionId),
    },
  );

  const dynamicDueDateForItem = match(inboxItem)
    .with({ task: { taskTemplate: { group: { id: P.string } } } }, item =>
      dynamicDueDatesQuery.data?.find(rule => rule.targetTaskTemplateGroup?.id === item.task.taskTemplate.group.id),
    )
    .with({ itemType: InboxItemType.Checklist }, () =>
      dynamicDueDatesQuery.data?.find(rule => rule.ruleType === 'Checklist'),
    )
    .otherwise(() => undefined);

  const shouldShowOverride = dynamicDueDateForItem && !shouldOverrideDynamicDueDate;

  const taskTemplatesQuery = GetAllTaskTemplatesByChecklistRevisionIdQuery.useQuery(
    { checklistRevisionId },
    { enabled: Boolean(checklistRevisionId) && Boolean(shouldShowOverride) },
  );

  const widgetsQuery = WidgetsByChecklistRevisionIdQuery.useQuery(
    { checklistRevisionId },
    { enabled: Boolean(checklistRevisionId) && Boolean(shouldShowOverride) },
  );

  const dateWidgets = widgetsQuery.data?.filter(isDateFormFieldWidget) ?? [];

  const isLoading =
    checklistRevisionQuery.isLoading ||
    dynamicDueDatesQuery.isLoading ||
    taskTemplatesQuery.isLoading ||
    widgetsQuery.isLoading;

  if (isLoading) {
    return (
      <Center w={80} h={80}>
        <Spinner />
      </Center>
    );
  } else if (!shouldShowOverride) {
    return <>{children}</>;
  } else {
    return (
      <DynamicDueDate
        rule={dynamicDueDateForItem}
        dateWidgets={dateWidgets}
        taskTemplates={taskTemplatesQuery.data ?? []}
        onOverride={() => {
          setShouldOverrideDynamicDueDate(true);
        }}
      />
    );
  }
};
