import { Option } from '@process-street/subgrade/core';
import { DueDateRuleDefinition, TemplateRevision } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { HttpStatus } from '@process-street/subgrade/util';

export namespace GetChecklistRuleQuery {
  export type Params = {
    templateRevisionId?: TemplateRevision['id'];
  };

  export type Response = Option<DueDateRuleDefinition>;

  export const key = ['template-revisions', 'due-date-rules', 'checklist'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/template-revisions/${params.templateRevisionId}/due-date-rules/checklist`, {
        validateStatus: status => status === HttpStatus.OK || status === HttpStatus.NOT_FOUND,
      })
      .then(res => {
        if (res.status === HttpStatus.NOT_FOUND) {
          return undefined;
        } else {
          return res.data;
        }
      });

  const QUERY_STALE_TIME_SECONDS = 30;

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.templateRevisionId),
      staleTime: 1000 * QUERY_STALE_TIME_SECONDS,
    });
  };
}
