import { Folder } from '@process-street/subgrade/process';
import { noop } from '@process-street/subgrade/util/noop';
import { ButtonProps, useDisclosure } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { useCurrentOrPrivateRootFolderQuery } from 'features/new-menu/query';
import { useGetConsolidatedFolderPermissionsQuery } from 'features/permissions/query-builder';
import * as React from 'react';
import { CreateTemplateSource } from 'services/template-service.interface';
import { match, P } from 'ts-pattern';

export type Props = {
  source: CreateTemplateSource;
  allowSelectFolder: boolean;
};

export const useNewBlankFormButton = ({
  source,
  allowSelectFolder = false,
}: Props): {
  buttonProps: Partial<ButtonProps>;
  folderPickerProps: {
    isOpen: boolean;
    onClose: () => void;
    onPick?: (folder: Folder) => void;
    initialFolderId: string;
    title: string;
  };
} => {
  const { PremadeTemplateService } = useInjector('PremadeTemplateService');
  const disclosure = useDisclosure();

  const { data: currentFolder, ...foldersQuery } = useCurrentOrPrivateRootFolderQuery();

  const { data: { permissionMap } = {}, ...permissionsQuery } = useGetConsolidatedFolderPermissionsQuery(
    currentFolder?.id ?? '',
    {
      enabled: !!currentFolder,
    },
  );

  const isDisabled = !permissionMap?.templateCreate;

  const onClick = React.useCallback(() => {
    match({ allowSelectFolder, currentFolder, isDisabled })
      .with({ allowSelectFolder: true, currentFolder: P.not(undefined), isDisabled: true }, () => {
        disclosure.onToggle();
      })
      .with({ currentFolder: P.not(undefined), isDisabled: false }, ({ currentFolder }) => {
        PremadeTemplateService.createBlankFormInFolderAndRedirect({ folderId: currentFolder.id, source });
      })
      .otherwise(noop);
  }, [allowSelectFolder, currentFolder, isDisabled, disclosure, PremadeTemplateService, source]);

  const onPick = React.useCallback(
    (folder: Folder) => {
      PremadeTemplateService.createBlankFormInFolderAndRedirect({ folderId: folder.id, source });
    },
    [PremadeTemplateService, source],
  );

  return {
    buttonProps: {
      isLoading: foldersQuery.isLoading || permissionsQuery.isLoading,
      isDisabled,
      onClick,
    },
    folderPickerProps: {
      isOpen: disclosure.isOpen,
      onClose: disclosure.onClose,
      onPick,
      initialFolderId: currentFolder?.id ?? '',
      title: 'Create Form in...',
    },
  };
};
