import { createSelector, Selector } from 'reselect';
import { Muid, Option, User } from '../../core';
import { EmailUtils } from '../../util';
import { safeEntityMapToArrayByIdsWith } from '../safe-entity-map-to-array-by-ids';
import { EntityMap, LightReduxState, ObjectMap } from '../types';

const getEntityMap = (state: LightReduxState): EntityMap<User> => state.entities.users;

const getById =
  (userId: Muid): Selector<LightReduxState, Option<User>> =>
  (state: LightReduxState) =>
    getEntityMap(state)[userId];

const getAllById =
  (userIds: Muid[]): Selector<LightReduxState, User[]> =>
  (state: LightReduxState) =>
    safeEntityMapToArrayByIdsWith(getEntityMap(state), userIds);

const usersByEmailMap: Selector<LightReduxState, ObjectMap<User>> = createSelector(getEntityMap, users =>
  Object.values(users).reduce(
    (usersByEmail: ObjectMap<User>, user: User) =>
      Object.assign(usersByEmail, { [EmailUtils.normalize(user.email)]: user }),
    {},
  ),
);

const getAllForActiveOrganization: Selector<LightReduxState, User[]> = createSelector(
  getEntityMap,
  (usersMap: EntityMap<User>) => Object.values(usersMap),
);

const getByEmail =
  (email: string): Selector<LightReduxState, Option<User>> =>
  (state: LightReduxState) =>
    usersByEmailMap(state)[EmailUtils.normalize(email)];

export const BaseUserSelector = {
  getAllById,
  getAllForActiveOrganization,
  getByEmail,
  getById,
  getEntityMap,
};
