import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FocusEvent, FormEvent, MouseEvent, TouchEvent } from 'react';
import { CommonProps, GroupBase } from 'react-select';
import { Input } from 'components/design/next';

interface MenuSearchHeaderProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>>
  extends CommonProps<Option, IsMulti, Group> {
  autoFocus?: boolean;
}

export const MenuSearchHeader = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MenuSearchHeaderProps<Option, IsMulti, Group>,
) => {
  const { selectProps, autoFocus = true } = props;

  const handleSearchInputChange = (e: FormEvent<HTMLInputElement>) => {
    if (selectProps.onInputChange && e.currentTarget) {
      selectProps.onInputChange(e.currentTarget.value, {
        action: 'input-change',
        prevInputValue: selectProps.inputValue,
      });
    }
  };

  const handleSearchInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (selectProps.onSearchInputBlur) {
      selectProps.onSearchInputBlur(e);
    }
  };

  const handleSearchInputMouseDown = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.currentTarget.focus();
  };

  const handleSearchInputTouchEnd = (e: TouchEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.currentTarget.focus();
  };

  return (
    <div className={'blvd-select__menu__header'}>
      <Input
        id={'blvd-select__menu__header__search-input'}
        type={'text'}
        onMouseDown={handleSearchInputMouseDown}
        onTouchEnd={handleSearchInputTouchEnd}
        onChange={handleSearchInputChange}
        onBlur={handleSearchInputBlur}
        autoComplete="off"
        placeholder={selectProps.searchPlaceholder ? selectProps.searchPlaceholder : 'Search...'}
        tabIndex={1}
        autoFocus={autoFocus}
        value={selectProps.inputValue}
        className={'blvd-select__menu__header__search-input'}
      />
      <FontAwesomeIcon className={'blvd-select__menu__header__search-icon'} icon={faSearch} />
    </div>
  );
};
