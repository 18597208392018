import { createCachedAction } from 'reducers/util';

export const INVITATION_CREATE = 'invitation/CREATE';
export const INVITATION_GET_BY_ID = 'invitation/GET_BY_ID';
export const INVITATION_RESEND = 'invitation/RESEND';

export const InvitationActions = InvitationApi => {
  'ngInject';

  const create = createCachedAction(INVITATION_CREATE, InvitationApi.create);

  const getById = createCachedAction(INVITATION_GET_BY_ID, InvitationApi.getById);

  const resend = createCachedAction(INVITATION_RESEND, InvitationApi.resend);

  return {
    create,
    getById,
    resend,
  };
};
