import * as React from 'react';
import { Box, Button, Center, Flex, Icon, Link, Text, VStack } from 'components/design/next';
import { DEFAULT_PAGE_TEMPLATE_COVER, mailto } from '@process-street/subgrade/process';
import { useAdminEmails } from 'components/paywalls/use-admin-emails';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { useRoleNames } from 'hooks/use-role-names';
import { CDNImage } from 'app/components/cdn-image';
import { env, getEnv } from 'components/common/env';
import { useMount } from 'react-use';

export type RequestAccessProps = {};

const IMAGEKIT_URL_ENDPOINT = env.IMAGEKIT_URL_ENDPOINT ?? getEnv().IMAGEKIT_URL_ENDPOINT;

export const RequestAccess: React.FC<RequestAccessProps> = () => {
  const currentUser = useCurrentUser();
  const [adminEmail, ...bcc] = useAdminEmails();
  const roleNames = useRoleNames();

  useMount(function updateDocumentTitle() {
    document.title = 'Request access | Process Street';
  });

  if (!currentUser) return null;

  const href = mailto({
    to: adminEmail,
    subject: `Someone on your team is requesting an upgrade on your Process Street plan`,
    body: `A ${roleNames.FreeMember.single} on your Process Street account${
      currentUser?.username ? `, ${currentUser.username}, ` : ''
    }would like you to upgrade them to a ${roleNames.FullMember.single}. ${
      roleNames.FullMember.plural
    } can create and run workflows.\n\nUpgrade here: https://app.process.st/organizations/manage/billing`,
    bcc,
  });

  return (
    <VStack position="relative" background="transparent" minW="100%">
      <Flex w="full" position="relative">
        <CDNImage
          alt=""
          s3File={{ url: IMAGEKIT_URL_ENDPOINT + DEFAULT_PAGE_TEMPLATE_COVER }}
          height="50"
          transformation={{
            height: 200,
            width: window.innerWidth,
          }}
        />
      </Flex>
      <Flex w="full" flexDirection="column" alignItems="center" justifyContent="center" px={{ base: 8, md: 0 }}>
        <Box
          maxW="888px"
          w="full"
          background="white"
          marginTop="-100px"
          borderRadius="md"
          sx={{
            '.image-content__aspect-ratio': { w: 'full', maxW: 'unset' },
            '.image-content': { maxW: 'unset' },
            '.file-container': { w: 'full' },
            '[data-component="FileUpload"]': { w: 'full' },
          }}
          p={11}
          zIndex={1}
        >
          <Center flexDirection="column" gap="4">
            <Box>
              <Icon icon="user" size="12" color="gray.400" fill="gray.400" />
              <Icon icon="lock" size="6" color="gray.500" variant="fas" />
            </Box>
            <Text as="h2" fontSize="lg" fontWeight="bold">
              Access denied
            </Text>
            <Text>You don&apos;t have permission to view this page.</Text>
            <Button as={Link} variant="secondary" href={href} isExternal>
              Request access
            </Button>
          </Center>
        </Box>
      </Flex>
    </VStack>
  );
};
