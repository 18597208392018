import { Muid, Organization } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateOrganizationEnhancedFileSecurityParams = {
  organizationId: Muid;
  enhancedFileSecurity: boolean;
};

export const UpdateOrganizationEnhancedFileSecurityMutation = {
  key: ['organizations', 'enhanced-file-security'],
  queryFn: (params: UpdateOrganizationEnhancedFileSecurityParams) =>
    axiosService
      .getAxios()
      .put<Organization>(`/1/organizations/${params.organizationId}/enhanced-file-security`, {
        enhancedFileSecurity: params.enhancedFileSecurity,
      })
      .then(r => r.data),
};

export const useUpdateOrganizationEnhancedFileSecurityMutation = (
  options?: UseMutationOptions<Organization, AxiosError, UpdateOrganizationEnhancedFileSecurityParams>,
) => {
  return useMutation(
    UpdateOrganizationEnhancedFileSecurityMutation.key,
    UpdateOrganizationEnhancedFileSecurityMutation.queryFn,
    options,
  );
};
