import templateUrl from './membership-list-item.component.html';
import './membership-list-item.scss';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';

export const MembershipListItemComponent = {
  bindings: {
    membership: '<',
    deleteVisible: '<',
    onClick: '&',
    onDelete: '&',
  },
  templateUrl,
  controller: class {
    constructor() {
      'ngInject';
    }

    $onInit() {
      this.user = this.membership && this.membership.user;

      this.userName = this.membership && this.membership.user.username;
    }

    getUserDescription() {
      if (!this.membership) {
        return '';
      } else if (isGroupUser(this.membership.user)) {
        return 'Group';
      } else {
        return this.membership.user.email;
      }
    }
  },
};
