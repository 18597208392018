import { connectController } from 'reducers/util';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { DynamicDueDatesSelector } from '../store/dynamic-due-dates.selectors';
import templateUrl from './template-multiselect.component.html';
import { TaskListEvent } from 'directives/task-list/task-list-event';

export const TemplateMultiselectComponent = {
  bindings: {
    selectedTaskTemplates: '<',
    templateRevision: '<',
    user: '<',
    disabled: '<',
  },
  templateUrl,
  controller: class {
    constructor($ngRedux, $rootScope, DynamicDueDateService) {
      'ngInject';

      this.$rootScope = $rootScope;
      this.DynamicDueDateService = DynamicDueDateService;

      const mapStateToThis = () => state => {
        const templateRevisionId = this.templateRevision && this.templateRevision.id;

        const taskRules = templateRevisionId
          ? DynamicDueDatesSelector.getAllByTemplateRevisionId(templateRevisionId)(state)
          : [];

        const taskTemplates = templateRevisionId
          ? TaskTemplateSelector.getAllByTemplateRevisionId(templateRevisionId)(state)
          : [];

        const dateWidgets = templateRevisionId
          ? DynamicDueDatesSelector.getDateWidgetsByTemplateRevisionId(templateRevisionId)(state)
          : [];

        return {
          taskTemplates,
          taskRules,
          dateWidgets,
        };
      };

      const shouldChange = change => change.templateRevision && change.templateRevision.currentValue;

      connectController($ngRedux, mapStateToThis, null, shouldChange)(this);
    }

    saveDueRules(data) {
      const { taskRules, taskTemplates, dateWidgets } = this.state;

      this.DynamicDueDateService.saveDueRules(
        data,
        this.selectedTaskTemplates,
        this.templateRevision,
        taskRules,
        taskTemplates,
        dateWidgets,
      );
      this.$rootScope.$broadcast(TaskListEvent.HIDE_POP_BOX);
    }

    removeDueRules() {
      const templateRevisionId = this.templateRevision.id;

      this.DynamicDueDateService.deleteDueRules(templateRevisionId, this.selectedTaskTemplates, this.state.taskRules);
      this.$rootScope.$broadcast(TaskListEvent.HIDE_POP_BOX);
    }
  },
};
