import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  ButtonGroup,
  ButtonProps,
  Divider,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from 'components/design/next';
import { GetAllTemplatesQuery, useDeleteTemplate, useGetTemplateQuery } from 'features/template/query-builder';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { TemplateStatus, TemplateType, tmplTypeName, tmplTypeNameLower } from '@process-street/subgrade/process';
import { useIsMutating, useQueryClient } from 'react-query';
import { match } from 'ts-pattern';
import { DeleteTemplateMutation } from 'features/template/mutation-builder';
import { Muid } from '@process-street/subgrade/core';
import { AnalyticsService } from 'components/analytics/analytics.service';
import { useRedirectToFolderView } from 'app/features/template/components/template-menu/delete-template-button/use-redirect-to-folder-view';

export type DeleteTemplateAlertProps = Omit<AlertDialogProps, 'leastDestructiveRef' | 'children'> & {
  onDeleted?: () => void;
  templateId: Muid;
  view: 'index' | 'show';
};
export const DeleteTemplateAlert: React.FC<React.PropsWithChildren<DeleteTemplateAlertProps>> = ({
  onClose,
  isOpen,
  onDeleted,
  templateId,
  view,
}) => {
  const { data: template } = useGetTemplateQuery({ templateId }, { enabled: Boolean(templateId) });
  const toast = useToast();

  const queryClient = useQueryClient();
  const deleteTemplateMutation = useDeleteTemplate({
    mutationKey: DeleteTemplateMutation.getKey(templateId),
    onSuccess: async () => {
      AnalyticsService.trackEvent('template deleted', {});

      toast({
        status: 'success',
        title: template ? `${tmplTypeName(template)} deleted` : 'Deleted',
      });

      await queryClient.invalidateQueries(
        GetAllTemplatesQuery.getKey({
          organizationId: template?.organization.id,
          templateStatus: TemplateStatus.Active,
        }),
      );

      switch (view) {
        case 'show': {
          redirectToFolderView();
          return;
        }
        default: {
          onClose?.();
          onDeleted?.();
        }
      }
    },
  });

  const { redirectToFolderView } = useRedirectToFolderView({ templateId });

  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const whatWillBeDeleted = match(template?.templateType)
    .with(undefined, () => '')
    .with(TemplateType.Playbook, () => 'workflow and all of its runs')
    .with(TemplateType.Form, () => 'form and all of its responses')
    .with(TemplateType.Page, () => 'page')
    .run();

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onClose}
      onOverlayClick={onClose}
      scrollBehavior="inside"
      size="xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader py="4" px="8" fontSize="lg">
          Delete this {tmplTypeNameLower(template)}?
        </AlertDialogHeader>
        <AlertDialogCloseButton />

        <AlertDialogBody pt="4" px="8" pb="8">
          <Text>
            Your {whatWillBeDeleted} will be deleted and{' '}
            <Text as="span" fontWeight="700">
              can’t be recovered
            </Text>
            .
          </Text>
        </AlertDialogBody>

        <Divider />

        <AlertDialogFooter py="6" px="8">
          <Spacer />
          <ButtonGroup>
            <Button ref={cancelRef} variant="ghost" onClick={onClose} colorScheme="gray">
              Cancel
            </Button>
            <Button
              isLoading={deleteTemplateMutation.isLoading || deleteTemplateMutation.isSuccess}
              isDisabled={deleteTemplateMutation.isLoading}
              variant="danger"
              onClick={() => deleteTemplateMutation.mutate({ templateId })}
            >
              Delete
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export interface DeleteTemplateButtonProps {
  onDeleted?: () => void;
  children: React.ReactElement | ((props: { isMutating: boolean }) => React.ReactElement<ButtonProps>);
  templateId: Muid;
  view: 'index' | 'show';
  disclosure?: Partial<ReturnType<typeof useDisclosure>>;
}

export const DeleteTemplateButton: React.FC<DeleteTemplateButtonProps> = React.memo(
  ({ onDeleted, templateId, view, children, disclosure: disclosureProp }) => {
    const disclosure = useDisclosure({
      id: 'delete',
      ...disclosureProp,
    });

    const deleteMutations = useIsMutating({
      mutationKey: DeleteTemplateMutation.getKey(templateId),
    });

    const isMutating = deleteMutations > 0;

    const child =
      typeof children === 'function' ? children({ isMutating }) : (children as React.ReactElement<ButtonProps>);
    const btn = React.cloneElement(child, {
      onClick: () => {
        disclosure.onToggle();
      },
    });

    const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId, {
      enabled: !!templateId,
    });
    const { data: template } = useGetTemplateQuery({ templateId });

    const canDeleteTemplate = match({ template, permissionMap })
      .with({ template: { templateType: TemplateType.Playbook }, permissionMap: { templateDelete: true } }, () => true)
      .with({ template: { templateType: TemplateType.Form }, permissionMap: { templateDelete: true } }, () => true)
      .with({ template: { templateType: TemplateType.Page }, permissionMap: { pageDelete: true } }, () => true)
      .otherwise(() => false);

    if (!canDeleteTemplate) return null;

    return (
      <>
        {btn}
        <DeleteTemplateAlert
          onDeleted={onDeleted}
          onClose={disclosure.onClose}
          isOpen={disclosure.isOpen}
          templateId={templateId}
          view={view}
        />
      </>
    );
  },
);
