import { useInjector } from 'components/injection-provider';
import { TrialExpiredPaywallType, UIs } from 'components/paywalls/trial-expired/ui';
import { useShouldBlockApp } from 'components/paywalls/trial-expired/use-should-block-app';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { TrialBlockService } from 'services/interop/trial-block-service';
import { usePaywall } from '../paywall-provider';

const now = () => Math.floor(new Date().getTime() / 1000);

export const TrialExpiredPaywall = () => {
  const { setPaywall } = usePaywall();
  const { shouldBlock, type } = useShouldBlockApp();
  const { $state } = useInjector('$state');
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const userId = useSelector(SessionSelector.getCurrentUserId) ?? '';

  React.useEffect(() => {
    const paywall = shouldBlock ? React.createElement(UIs[type]) : null;
    setPaywall(paywall);

    if (shouldBlock && [TrialExpiredPaywallType.BlockAdmin, TrialExpiredPaywallType.BlockNonAdmin].includes(type)) {
      TrialBlockService.updateIntercomPaywallSeenVariables({
        user_id: userId,
        trial_block_paywall_seen_at: now(),
        company: { id: organizationId, trial_block_paywall_seen_at: now() },
      });
    }

    return () => {
      setPaywall(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- react to $state transition
  }, [setPaywall, shouldBlock, type, $state.current]);

  return null;
};
