import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  VStack,
  Wrap,
  WrapItem,
} from 'components/design/next';
import { Icon } from 'components/design/next';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { ShellContentActions } from '../components/shell-content-actions';
import { TextInputField } from '../components/text-input-field';
import { Title } from '../components/title';
import { ToggleButton } from '../components/toggle-button';
import { getError, hasError } from '../helpers';
import { departmentOptions, employeeCountOptions, organizationDetailsSchema } from './form.schema';

export type FormValues = {
  organizationName: string;
  employeeCount: string;
  department: string;
};

export type OrganizationDetailsFormProps = {
  onSubmit: (values: FormValues) => void;
  isLoading?: boolean;
  error?: string;
  initialValues?: Partial<FormValues>;
};

const defaultValues: FormValues = {
  organizationName: '',
  employeeCount: '',
  department: '',
};

export const OrganizationDetailsForm: React.VFC<OrganizationDetailsFormProps> = ({
  onSubmit,
  initialValues,
  isLoading,
  error,
}) => {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ ...defaultValues, ...initialValues }}
      validationSchema={organizationDetailsSchema}
      enableReinitialize
    >
      {({ handleSubmit, handleChange, handleBlur, setFieldValue, values, errors, touched, submitCount }) => (
        <Box w="full" sx={{ '&>form': { width: 'full' } }}>
          <Form onSubmit={handleSubmit}>
            <VStack m="0 auto" spacing="8" alignItems="flex-start" maxWidth="xl">
              <Title>Set up your organization</Title>

              {error && (
                <Alert status="error">
                  <AlertIcon />
                  {error}
                </Alert>
              )}

              <TextInputField
                label="What should we call your organization?"
                name="organizationName"
                value={values.organizationName}
                autoFocus={true}
                onChange={handleChange}
                onBlur={handleBlur}
                error={getError('organizationName', submitCount, errors, touched)}
                isRequired
              />

              <FormControl isInvalid={hasError('employeeCount', submitCount, errors, touched)} isRequired>
                <FormLabel mb="2.5">How many people work at your company?</FormLabel>

                <Wrap spacing="2.5">
                  {employeeCountOptions.map(option => (
                    <WrapItem key={option}>
                      <ToggleButton
                        isSelected={option === values.employeeCount}
                        onClick={() => setFieldValue('employeeCount', option)}
                      >
                        {option}
                      </ToggleButton>
                    </WrapItem>
                  ))}
                </Wrap>

                {hasError('employeeCount', submitCount, errors, touched) && (
                  <FormErrorMessage role="alert">{errors.employeeCount}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={hasError('department', submitCount, errors, touched)} isRequired>
                <FormLabel mb="2.5">What department do you work in?</FormLabel>
                <Select
                  color={values.department ? 'gray.600' : 'gray.400'}
                  borderWidth="thin"
                  borderColor="gray.300"
                  backgroundColor="white"
                  borderRadius="base"
                  height="12"
                  width="full"
                  name="department"
                  value={values.department}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-describedby="text-input-department-error"
                  placeholder="Select department"
                >
                  {departmentOptions.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>

                {hasError('department', submitCount, errors, touched) && (
                  <FormErrorMessage id="text-input-department-error" role="alert">
                    {errors.department}
                  </FormErrorMessage>
                )}
              </FormControl>

              <ShellContentActions>
                <Button
                  px="5"
                  height="12"
                  iconSpacing="2"
                  type="submit"
                  leftIcon={<Icon size="4" variant="far" icon="check" />}
                  isLoading={isLoading}
                >
                  Next
                </Button>
              </ShellContentActions>
            </VStack>
          </Form>
        </Box>
      )}
    </Formik>
  );
};
