import { HStack, VStack, ButtonGroup, Button, Icon, ButtonProps, Switch } from 'components/design/next';
import { Muid } from '@process-street/subgrade/core';
import React from 'react';
import qs from 'qs';
import { Analytics } from '../analytics';
import {
  activeChecklistsSavedViewFilter,
  activeChecklistsWithCompletedSavedViewFilter,
  allChecklistsSavedViewFilter,
  ChecklistSavedViewConfigProvider,
} from '../../dashboard-provider';
import { ChecklistDashboardGridWrapper } from './checklist-dashboard-grid-wrapper';
import { Clause, Condition, ConditionalFilterUtils, ProgressStatus } from '@process-street/subgrade/dashboard';
import { useDebouncedCallback } from 'use-debounce';
import { Link } from '@process-street/adapters/navigation';

export type ReportsProps = {
  templateId: Muid;
  activeProgressStatus: ProgressStatus | null;
  onResetFilters: () => void;
};

const viewModeButtonProps: ButtonProps = {
  colorScheme: 'brand',
  iconSpacing: '2',
  fontWeight: 'medium',
  borderRadius: 'full',
  color: 'gray.500',
  _active: { color: 'brand.500', bgColor: 'brand.100' },
};

export const Reports = ({ templateId, activeProgressStatus, onResetFilters }: ReportsProps) => {
  const [viewMode, setViewMode] = React.useState<'table' | 'charts'>('table');
  const [filter, setFilter] = React.useState<Clause>(activeChecklistsSavedViewFilter);
  const [isShowCompletedRunsSwitchDisabled, setIsShowCompletedRunsSwitchDisabled] = React.useState(false);

  const onShowCompletedRunsDebounced = useDebouncedCallback(setIsShowCompletedRunsSwitchDisabled, 500);

  const handleShowCompletedRunsSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsShowCompletedRunsSwitchDisabled(true);
    const { checked } = event.target;
    const filter = checked ? activeChecklistsWithCompletedSavedViewFilter : activeChecklistsSavedViewFilter;
    setFilter(filter);
    onShowCompletedRunsDebounced(false);
  };

  return (
    <VStack alignItems="flex-start" spacing={4}>
      <HStack w="full" justifyContent="space-between" gap={4} wrap="wrap" py={1}>
        <ButtonGroup variant="ghost" spacing="4">
          <Button
            {...viewModeButtonProps}
            leftIcon={<Icon icon="table" size="4" />}
            isActive={viewMode === 'table'}
            onClick={() => setViewMode('table')}
          >
            Table
          </Button>

          <Button
            {...viewModeButtonProps}
            leftIcon={<Icon icon="chart-bar" size="4" />}
            isActive={viewMode === 'charts'}
            onClick={() => setViewMode('charts')}
          >
            Analytics
          </Button>

          {activeProgressStatus && (
            <Button
              variant="link"
              fontSize="sm"
              fontWeight="normal"
              leftIcon={<Icon icon="times" size="4" color="gray.600" />}
              color="gray.600"
              textDecoration="underline"
              onClick={onResetFilters}
            >
              Remove filter
            </Button>
          )}
        </ButtonGroup>

        <HStack spacing="4" justifyContent={{ base: 'space-between', md: 'normal' }} w={{ base: 'full', md: 'auto' }}>
          <Switch
            defaultChecked={false}
            onChange={handleShowCompletedRunsSwitch}
            isDisabled={isShowCompletedRunsSwitchDisabled}
            fontSize="sm"
          >
            Show completed runs
          </Switch>
          <Button
            as={Link}
            to={{ pathname: 'reports', search: qs.stringify({ templateId, tab: 'table' }) }}
            fontSize="sm"
            fontWeight="normal"
            color="gray.600"
            variant="outline"
            borderColor="gray.300"
            borderWidth="1px"
          >
            Custom report
          </Button>
        </HStack>
      </HStack>

      {viewMode === 'table' && (
        <ChecklistSavedViewConfigProvider
          templateId={templateId}
          filter={
            activeProgressStatus
              ? ConditionalFilterUtils.createProgressStatusFilter(Condition.Contains, [activeProgressStatus])
              : filter
          }
        >
          <ChecklistDashboardGridWrapper templateId={templateId} />
        </ChecklistSavedViewConfigProvider>
      )}

      {viewMode === 'charts' && (
        <ChecklistSavedViewConfigProvider templateId={templateId} filter={allChecklistsSavedViewFilter}>
          <Analytics />
        </ChecklistSavedViewConfigProvider>
      )}
    </VStack>
  );
};
