import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { components, DropdownIndicatorProps, GroupBase } from 'react-select';

export const DropdownIndicator = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => (
  <components.DropdownIndicator {...props} className={'blvd-select__indicator'}>
    <FontAwesomeIcon icon={faChevronDown} />
  </components.DropdownIndicator>
);
