import * as React from 'react';
import { Form, Formik } from 'formik';
import {
  Button,
  ButtonGroup,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  VStack,
} from 'components/design/next';
import { FieldType, FormFieldWidgetOfType } from '@process-street/subgrade/process';
import { ObjectSchema } from 'yup';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';
import { match } from 'ts-pattern';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { ConfirmationContextProvider } from './context';

export type SettingsModalProps<Type extends FieldType> = React.PropsWithChildren<{
  widget: FormFieldWidgetOfType<Type>;
  schema: ObjectSchema<WidgetSettings<Type>>;
  onUpdate: (widget: FormFieldWidgetOfType<Type>) => void;
  onClose: () => void;
}>;

const CONSTRAINTS_TO_CONVERT_TO_UNDEFINED = ['restriction', 'decimalPlaces'] as const;
const CONFIGS_TO_CONVERT_TO_UNDEFINED = ['unit'] as const;

export const SettingsModalContent = <Type extends FieldType>({
  widget,
  schema,
  onUpdate,
  onClose,
  children,
}: SettingsModalProps<Type>) => {
  const [header, ...fields] = React.Children.toArray(children);

  const initialValues = {
    config: widget.config,
    constraints: widget.constraints,
  } as WidgetSettings<Type>;

  const [hasAcceptedConfirmation, setHasAcceptedConfirmation] = React.useState(false);
  const [shouldShowConfirmation, setShouldShowConfirmation] = React.useState(false);
  const [confirmationContent, setConfirmationContent] = React.useState<React.ReactNode>(null);

  const handleSubmit = (values: WidgetSettings<Type>) => {
    if (confirmationContent && !hasAcceptedConfirmation) {
      setShouldShowConfirmation(true);
    } else {
      handleUpdate(values as WidgetSettings<Type>);
      onClose();
    }
  };

  const handleUpdate = ({ config, constraints }: WidgetSettings<Type>) =>
    onUpdate({
      ...widget,
      config: CONFIGS_TO_CONVERT_TO_UNDEFINED.reduce((acc, key) => {
        return match({ key, acc })
          .with({ key: 'unit', acc: { unit: '' } }, () => ({ ...acc, unit: undefined, unitLocation: undefined }))
          .otherwise(() => acc);
      }, config as WidgetSettings<FieldType>['config']),

      // Using FieldType instead of the generic for better pattern matching
      // https://github.com/gvergnaud/ts-pattern/issues/64
      constraints: CONSTRAINTS_TO_CONVERT_TO_UNDEFINED.reduce((acc, key) => {
        return match({ key, acc })
          .with({ key: 'restriction', acc: { restriction: '' } }, () => ({ ...acc, restriction: undefined }))
          .with({ key: 'decimalPlaces', acc: { decimalPlaces: '' } }, () => ({ ...acc, decimalPlaces: undefined }))
          .otherwise(() => acc);
      }, constraints as WidgetSettings<FieldType>['constraints']),
    });

  const onConfirm = React.useCallback(
    (confirmationCallback: () => void) => {
      setShouldShowConfirmation(false);
      confirmationCallback();
    },
    [setShouldShowConfirmation],
  );

  return (
    <ThemeProvider2024>
      <ConfirmationContextProvider
        confirmationContent={confirmationContent}
        setConfirmationContent={setConfirmationContent}
        onConfirm={onConfirm}
        setShouldShowConfirmation={setShouldShowConfirmation}
        shouldShowConfirmation={shouldShowConfirmation}
        setHasAcceptedConfirmation={setHasAcceptedConfirmation}
      >
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader p={8}>{header}</ModalHeader>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnChange={true}
          >
            {({ isValid, dirty }) => (
              <Form>
                <ModalBody px={9} py={0}>
                  <VStack spacing={4}>{fields}</VStack>
                </ModalBody>
                <ModalFooter p={6}>
                  <ButtonGroup>
                    <Button aria-label="cancel changes" variant="ghost" onClick={() => onClose()}>
                      Cancel
                    </Button>
                    <Button aria-label="save changes" isDisabled={!(isValid && dirty)} type="submit">
                      Apply
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
        {shouldShowConfirmation && confirmationContent}
      </ConfirmationContextProvider>
    </ThemeProvider2024>
  );
};

export const SettingsModalHeader: React.FC<React.PropsWithChildren<React.PropsWithChildren<{}>>> = ({ children }) => (
  <Text variant="2">{children}</Text>
);
