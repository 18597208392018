import angular from 'angular';
import { canAccess, Feature } from 'services/features/features';
import { AnalyticsConstants } from '@process-street/subgrade/analytics/analytics-constants';
import addStopTaskModalTemplate from './add-stop-task/add-stop-task.component.html';
import './stop-task-button.scss';
import { MessageBox } from 'services/message-box.interface';
import { OrganizationMembershipService } from 'services/organization-membership-service.interface';
import { SessionService } from 'services/session-service.interface';
import { TaskTemplateService } from 'services/task-template-service.interface';
import { Organization, User } from '@process-street/subgrade/core';
import { TaskTemplate } from '@process-street/subgrade/process';
import { ToastService } from 'services/toast-service.interface';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { AnalyticsService } from 'components/analytics/analytics.service';

class StopTaskButtonService {
  public static $inject = [
    'MessageBox',
    'OrganizationMembershipService',
    'SessionService',
    'TaskTemplateService',
    'ToastService',
  ];

  constructor(
    private readonly messageBox: MessageBox,
    private readonly organizationMembershipService: OrganizationMembershipService,
    private readonly sessionService: SessionService,
    private readonly taskTemplateService: TaskTemplateService,
    private readonly toastService: ToastService,
  ) {}

  calculateFeatureIsAvailable(organization?: Organization) {
    if (organization) {
      const planId = organization.subscription.plan.id;
      return canAccess(Feature.STOP_TASK, planId);
    }
    return false;
  }

  async calculateUserIsAdmin(organization?: Organization, user?: User) {
    if (organization && user) {
      return this.organizationMembershipService.isAdminByUserIdAndOrganizationId(user.id, organization.id).then(
        userIsAdmin => userIsAdmin,
        () => {
          return false;
        },
      );
    } else {
      return false;
    }
  }

  doAddStop({ taskTemplate }: { taskTemplate: TaskTemplate }) {
    taskTemplate.stop = !taskTemplate.stop;

    return this.taskTemplateService.update(taskTemplate).then(
      () => {
        this.toastService.openToast({
          status: 'success',
          title: `Task updated`,
        });

        AnalyticsService.trackEvent(AnalyticsConstants.Event.STOP_TASK_ADDED_TO_TASK, {
          amount: 'one',
        });
      },
      () => {
        taskTemplate.stop = !taskTemplate.stop;

        this.toastService.openToast({
          status: 'error',
          title: `We're having problems updating the task`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      },
    );
  }

  shouldShowUpgradeModal({ featureIsAvailable }: { featureIsAvailable: boolean }) {
    return !featureIsAvailable || !this.sessionService.getTemplateEditorProperty('addStopTaskModalSeen');
  }

  async addStop({
    taskTemplate,
    user,
    organization,
  }: {
    taskTemplate: TaskTemplate;
    user?: User;
    organization?: Organization;
  }) {
    const featureIsAvailable = this.calculateFeatureIsAvailable(organization);
    const shouldShowUpgradeModal = this.shouldShowUpgradeModal({ featureIsAvailable });
    const userIsAdmin = await this.calculateUserIsAdmin(organization, user);

    if (shouldShowUpgradeModal && !taskTemplate.stop) {
      this.sessionService.setTemplateEditorProperty('addStopTaskModalSeen', true /* value */);

      this.messageBox.custom({
        templateUrl: addStopTaskModalTemplate,
        controller: 'AddStopTaskCtrl',
        options: {
          addStop: () => this.doAddStop({ taskTemplate }),
          featureIsAvailable,
          userIsAdmin,
        },
      });
    } else {
      return this.doAddStop({ taskTemplate });
    }
  }
}

angular.module('frontStreetApp.services').service('StopTaskButtonService', StopTaskButtonService);
