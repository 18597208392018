import { PromptOverrideLookup } from 'features/developer/components/prompt-override/prompt-override.component';
import { LocalStorageService } from 'features/storage/local-storage-service';
import {
  AI_WORKFLOW_INITIAL_CONFIG,
  AI_WORKFLOW_PROMPT_OVERRIDE,
} from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/wrapper';
import { trace } from 'components/trace';
import { OrganizationDetails, UserDetails } from '@process-street/subgrade/core';
import { match } from 'ts-pattern';

export namespace AiLocalStorage {
  const logger = trace({ name: 'AiGeneratedWorkflowSettingsModal' });

  export const getInitialConfigFromLocalStorage = (): {
    name: string;
    description: string;
    retryCount: number;
    promptOverride?: PromptOverrideLookup;
    autoStart: boolean;
  } => {
    let promptOverride;
    try {
      const localStoragePromptOverride = LocalStorageService.getItem(AI_WORKFLOW_PROMPT_OVERRIDE);
      promptOverride = localStoragePromptOverride && JSON.parse(localStoragePromptOverride);
    } catch (e) {
      logger.error(e);
    }

    const initialConfig = getInitialConfig();

    const emptyConfig = {
      name: '',
      description: '',
      retryCount: 0,
      autoStart: false,
    };

    return { ...(initialConfig ?? emptyConfig), promptOverride };
  };

  const parseRetryCount = (retryCount: string): number => {
    const parsedRetryCount = parseInt(retryCount, 10);
    return isNaN(parsedRetryCount) ? 0 : parsedRetryCount;
  };

  export type AiInitialConfig = {
    name: string;
    description: string;
    retryCount: number;
    autoStart: boolean;
  };

  export const setInitialConfig = (config: AiInitialConfig) => {
    LocalStorageService.setItem(AI_WORKFLOW_INITIAL_CONFIG, JSON.stringify(config));
  };

  export const getInitialConfigFromSignUpDetails = (
    organizationDetails: OrganizationDetails | undefined,
    userDetails: UserDetails,
  ): AiInitialConfig => {
    const name = match({ department: userDetails.department || 'Other', useCase: userDetails.useCase || 'Other' })
      .with({ department: 'Other', useCase: 'Other' }, () => 'Employee Onboarding')
      .with({ department: 'Other' }, ({ useCase }) => useCase)
      .with({ useCase: 'Other' }, ({ department }) => department)
      .otherwise(({ department, useCase }) => `${department} ${useCase}`);

    const description = organizationDetails?.employeeCount
      ? `This workflow is for a company with ${organizationDetails.employeeCount} employees.`
      : '';

    return {
      name,
      description,
      retryCount: 0,
      autoStart: false,
    };
  };

  export const setInitialConfigFromSignUpDetails = (
    organizationDetails: OrganizationDetails | undefined,
    userDetails: UserDetails,
  ) => {
    setInitialConfig(getInitialConfigFromSignUpDetails(organizationDetails, userDetails));
  };

  const getInitialConfig = (): AiInitialConfig | undefined => {
    try {
      const localStorageInitialConfig = LocalStorageService.getItem(AI_WORKFLOW_INITIAL_CONFIG);
      if (localStorageInitialConfig) {
        const { name = '', description = '', retryCount, autoStart } = JSON.parse(localStorageInitialConfig);

        return {
          name,
          description,
          retryCount: parseRetryCount(retryCount),
          autoStart,
        };
      }
    } catch (e) {
      logger.error(e);
    }
  };
}
