import { ChecklistStatus, isHeading, Task, TaskStatus } from '@process-street/subgrade/process/checklist-model';
import {
  BaseChecklistRevisionSelector,
  BaseChecklistSelector,
  BaseSessionSelector,
  BaseTaskSelector,
  BaseTaskTemplateSelector,
} from '@process-street/subgrade/redux/selector';
import { BaseReduxState } from '@process-street/subgrade/redux/types';
import { OptimisticResultBuilder } from '../../../optimistic';

export interface ChecklistCompleteEngine {
  applyChangesOnTaskStatusChange(
    updatedTask: Task,
    resultBuilder: OptimisticResultBuilder,
    state: BaseReduxState,
  ): void;
}

function applyChangesOnTaskStatusChange(
  updatedTask: Task,
  resultBuilder: OptimisticResultBuilder,
  state: BaseReduxState,
): void {
  if (updatedTask.status !== TaskStatus.Completed) {
    return;
  }
  const checklistRevision = BaseChecklistRevisionSelector.getById(updatedTask.checklistRevision.id)(state);
  if (!checklistRevision) {
    return;
  }
  const taskTemplatesMap = BaseTaskTemplateSelector.getEntityMapByTemplateRevisionId(
    checklistRevision.templateRevision.id,
  )(state);
  const allTasks = BaseTaskSelector.getAllByChecklistRevisionId(checklistRevision.id)(state).filter(
    task => !isHeading(taskTemplatesMap[task.taskTemplate.id]) && !task.hidden,
  );
  if (allTasks.length === 0) {
    return;
  }
  const currentUser = BaseSessionSelector.getCurrentUser(state);
  if (!currentUser) {
    return;
  }
  const allTaskCompleted = allTasks.every(task => task.id === updatedTask.id || task.status === TaskStatus.Completed);
  if (allTaskCompleted) {
    const originalChecklist = BaseChecklistSelector.getById(checklistRevision.checklist.id)(state);
    if (originalChecklist) {
      resultBuilder.checklist.appendUpdateEvent(
        {
          id: checklistRevision.checklist.id,
          status: ChecklistStatus.Completed,
          completedBy: currentUser,
          completedDate: Date.now(),
        },
        originalChecklist,
      );
    }
  }
}

export const ChecklistCompleteEngineImpl: ChecklistCompleteEngine = {
  applyChangesOnTaskStatusChange,
};

export const ChecklistCompleteEngineFactory = {
  getInstance: (): ChecklistCompleteEngine => ChecklistCompleteEngineImpl,
};
