import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule';
import {
  isHeading,
  OrderTree,
  OrderTreeUtils,
  TaskConstants,
  TaskTemplate,
  TaskTemplateTaskType,
} from '@process-street/subgrade/process';
import { every, sortBy } from 'lodash';
import { MultiValue } from 'react-select';
import { TaskTemplateItem } from './task-select';

export namespace ApprovalTaskUtils {
  export const findArrayChanges = ({
    originalArray,
    modifiedArray,
  }: {
    originalArray: ApprovalRuleSubject[];
    modifiedArray: ApprovalRuleSubject[];
  }): { added: ApprovalRuleSubject[]; removed: ApprovalRuleSubject['id'][] } => {
    const added: ApprovalRuleSubject[] = [];
    const removed: ApprovalRuleSubject['id'][] = [];
    for (const item of modifiedArray) {
      if (
        !originalArray.some(originalItem => originalItem.subjectTaskTemplateGroupId === item.subjectTaskTemplateGroupId)
      ) {
        added.push(item);
      }
    }
    for (const originalTask of originalArray) {
      if (
        !modifiedArray.some(
          modifiedItem => modifiedItem.subjectTaskTemplateGroupId === originalTask.subjectTaskTemplateGroupId,
        )
      ) {
        removed.push(originalTask.id);
      }
    }
    return { added, removed };
  };

  export const getNameForTask = (taskTemplate: TaskTemplate) => taskTemplate?.name ?? TaskConstants.DefaultTaskName;

  export const rulesAreTheSame = (
    tasksFromApprovalRule: ApprovalRuleSubject[],
    taskTemplates: MultiValue<TaskTemplateItem>,
  ): boolean => {
    if (tasksFromApprovalRule.length !== taskTemplates.length) {
      return false;
    }

    const sortedApprovalRules = sortBy(tasksFromApprovalRule, 'approvalTaskTemplateGroupId');
    const sortedTaskTemplates = sortBy(taskTemplates, 'group.id');

    return every(sortedApprovalRules, (approvalRule, index) => {
      const taskTemplate = sortedTaskTemplates[index];
      return approvalRule.subjectTaskTemplateGroupId === taskTemplate.group.id;
    });
  };

  export const BLVD_CONTROL_STYLES = {
    menu: (base: any) => ({
      ...base,
      'width': '100%',
      'minHeight': '200px', // enough space for 3 tasks and the search bar.
      '.blvd-select__menu__header': { display: 'flex', padding: '8px 16px' },
      '.blvd-select__menu__header input': { paddingLeft: '32px' },
      '.blvd-select__menu__header__search-icon': {
        position: 'absolute',
        left: '24px',
        top: '20px',
        color: 'var(--ps-colors-gray-400)',
      },
      '.blvd-select__menu__separator': { display: 'none' },
      '.blvd-select__option': {
        'cursor': 'pointer',
        'height': 'auto',
        'color': 'black',
        'backgroundColor': 'white',
        '&:hover': {
          backgroundColor: 'var(--ps-colors-blue-100)',
        },
        '&:focus': {
          backgroundColor: 'var(--ps-colors-blue-100)',
        },
      },
      '.blvd-select__option--is-selected': {
        'backgroundColor': 'white',
        '&:hover': {
          backgroundColor: 'var(--ps-colors-blue-100)',
        },
      },
    }),
  };

  export const isTaskTemplateEnabled = (
    taskTemplate: TaskTemplate,
    currentTaskOrder: OrderTree,
    taskTemplates: TaskTemplate[],
  ): boolean => {
    const isBeforeTask = OrderTreeUtils.compare(currentTaskOrder, taskTemplate.orderTree) > 0;
    const isHeader = isHeading(taskTemplate);
    const nextTask = taskTemplates.find(task => OrderTreeUtils.compare(task.orderTree, taskTemplate.orderTree) > 0);
    const isEmptyHeader = isHeader && (nextTask?.taskType !== TaskTemplateTaskType.Standard || isHeading(nextTask));
    const isValidType = taskTemplate.taskType === TaskTemplateTaskType.Standard;
    return isBeforeTask && isValidType && !isEmptyHeader;
  };
}
