import * as React from 'react';
import { Template } from '@process-street/subgrade/process';
import { HStack } from '@chakra-ui/react';
import { RespondForm } from './respond-form';

type FormActionsProps = {
  template: Template;
};

export const FormActions: React.FC<React.PropsWithChildren<FormActionsProps>> = ({ template }) => {
  return (
    <HStack display={{ base: 'none', md: 'flex' }} spacing="2">
      <RespondForm template={template} />
    </HStack>
  );
};
