import { Icon, Spacer } from 'components/design/next';
import { Row } from '../common-ui';
import { useFeatureFlag } from 'features/feature-flags';
import * as React from 'react';
import { CreateTemplateSource } from 'services/template-service.interface';
import { UniversalNewMenu } from 'features/new-menu/component';

export const ActionsRow: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isSelectionEnabled = useFeatureFlag('deleteArchiveMultipleTemplatesFromLibrary');
  return (
    <Row role="group">
      {isSelectionEnabled && <Spacer maxW="12" w="12" minW="12" />}
      <UniversalNewMenu
        source={CreateTemplateSource.LIBRARY_ROW}
        shouldHideRunWorkflow
        shouldHideOneOffTask
        _hover={{ color: 'brand.500' }}
        _active={{ backgroundColor: 'auto' }}
        color="gray.400"
        width="full"
        fontWeight="semibold"
        h="full"
        w="full"
        bgColor="inherit"
        borderRadius="0"
        pl={{ base: '7', md: '5' }}
        leftIcon={<Icon icon="plus" size="4" mr={4} />}
      />
    </Row>
  );
};
