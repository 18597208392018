import templateUrl from './load-and-go.component.html';
import './load-and-go.scss';

const DEFAULT_ON_FAILURE = () => ({ to: 'dashboard' });

export const LoadAndGoComponent = {
  templateUrl,
  bindings: { $transition$: '<' },
  controller: class LoadAndGoComponent {
    constructor($state) {
      'ngInject';

      this.$state = $state;
    }

    $onInit() {
      const { promise, onSuccess, onFailure: failureCallback } = this.$transition$.params();
      const onFailure = failureCallback || DEFAULT_ON_FAILURE;

      this.loaded = false;

      if (promise && promise.then) {
        promise
          .then(result => {
            const { to, params, options } = onSuccess(result);
            this.$state.go(to, params, options);
          })
          .catch(error => {
            const { to, params, options } = onFailure(error);
            this.$state.go(to, params, options);
          })
          .finally(() => {
            this.loaded = true;
          });
      } else {
        this.loaded = true;
        this.$state.go('dashboard');
      }
    }
  },
};
