import { Muid } from '@process-street/subgrade/core';
import { NativeAutomation, NativeAutomationWithLink } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { ScheduledTriggerUtils } from './scheduled-trigger-utils';

export namespace CustomNotificationUtils {
  export type Timing = {
    entity?: { type: 'WorkflowRun'; templateRevisionId: Muid } | { type: 'Task'; taskTemplateId: Muid };
    property?: 'startDate' | 'completedDate' | 'dueDate';
  } & Partial<Pick<NativeAutomation.GetTriggerByType<'Scheduled'>['config'], 'offset' | 'offsetDirection'>>;

  // A derived, flattened type from NativeAutomation for easier form work.
  export type Config = {
    nativeAutomationId: Muid;
    timing?: Timing;
    recipients: NativeAutomation.SendEmailActionRecipient[];
    customMessage?: string;
  };

  export const Transformer = {
    automationToConfig: ({ automation }: NativeAutomationWithLink): Config => {
      return {
        nativeAutomationId: automation.id,
        timing: match<NativeAutomation, Timing | undefined>(automation)
          .with(
            { trigger: { triggerType: 'WorkflowRunCreated' } },
            ({
              trigger: {
                config: { templateRevisionId },
              },
            }) => ({
              property: 'startDate',
              entity: { type: 'WorkflowRun', templateRevisionId },
              offsetDirection: 'On',
            }),
          )
          .with(
            { trigger: { triggerType: 'WorkflowRunCompleted' } },
            ({
              trigger: {
                config: { templateRevisionId },
              },
            }) => ({
              property: 'completedDate',
              entity: { type: 'WorkflowRun', templateRevisionId },
              offsetDirection: 'On',
            }),
          )
          .with(
            { trigger: { triggerType: 'TaskCompleted' } },
            ({
              trigger: {
                config: { taskTemplateId },
              },
            }) => ({
              property: 'completedDate',
              entity: { type: 'Task', taskTemplateId },
              offsetDirection: 'On',
            }),
          )
          .with(
            { trigger: { triggerType: 'TaskDueDateReached' } },
            ({
              trigger: {
                config: { taskTemplateId },
              },
            }) => ({
              property: 'dueDate',
              entity: { type: 'Task', taskTemplateId },
              offsetDirection: 'On',
            }),
          )
          .with({ trigger: { triggerType: 'Scheduled' } }, ({ trigger: { config } }) => ({
            offsetDirection: config.offsetDirection,
            offset: config.offset,
            ...match<typeof config.createTrigger, Partial<Config['timing']>>(config.createTrigger)
              .with({ triggerType: 'WorkflowRunCreated' }, ({ config: { templateRevisionId } }) => ({
                entity: { type: 'WorkflowRun', templateRevisionId },
                property: 'startDate',
              }))
              .with({ triggerType: 'WorkflowRunCompleted' }, ({ config: { templateRevisionId } }) => ({
                entity: { type: 'WorkflowRun', templateRevisionId },
                property: 'completedDate',
              }))
              .with({ triggerType: 'TaskCompleted' }, ({ config: { taskTemplateId } }) => ({
                entity: { type: 'Task', taskTemplateId },
                property: 'completedDate',
              }))
              .with({ triggerType: 'TaskDueDateCreated' }, ({ config: { taskTemplateId } }) => ({
                entity: { type: 'Task', taskTemplateId },
                property: 'dueDate',
              }))
              .otherwise(() => ({})),
          }))
          .otherwise(() => undefined),
        recipients:
          automation.actions?.flatMap(action => {
            return match(action)
              .with({ actionType: 'SendEmail' }, ({ config }) => {
                return config.recipients;
              })
              .otherwise(() => []);
          }) ?? [],
        customMessage: automation.actions?.find(
          (action): action is NativeAutomation.GetActionByType<'SendEmail'> => action.actionType === 'SendEmail',
        )?.config.emailTemplateConfig.customMessage,
      };
    },
    makeConfigToAutomationForm:
      (context: { templateRevisionId: Muid; automationsMap: Map<Muid, NativeAutomationWithLink> }) =>
      (config: Config): { id: Muid; form: NativeAutomation.CreateNativeAutomationForm } => {
        const { automation } = context.automationsMap.get(config.nativeAutomationId) ?? {};

        return {
          id: automation?.id ?? config.nativeAutomationId,
          form: {
            automationType: 'CustomTemplateNotification',
            trigger: match<Timing | undefined, NativeAutomation.Trigger>(config.timing)
              .with(
                { entity: { type: 'WorkflowRun' }, property: 'startDate', offsetDirection: 'On' },
                ({ entity: { templateRevisionId } }) => ({
                  triggerType: 'WorkflowRunCreated',
                  config: { templateRevisionId },
                }),
              )
              .with(
                { entity: { type: 'WorkflowRun' }, property: 'completedDate', offsetDirection: 'On' },
                ({ entity: { templateRevisionId } }) => ({
                  triggerType: 'WorkflowRunCompleted',
                  config: { templateRevisionId },
                }),
              )
              .with(
                { entity: { type: 'Task' }, property: 'completedDate', offsetDirection: 'On' },
                ({ entity: { taskTemplateId } }) => ({
                  triggerType: 'TaskCompleted',
                  config: { taskTemplateId },
                }),
              )
              .with(
                { entity: { type: 'Task' }, property: 'dueDate', offsetDirection: 'On' },
                ({ entity: { taskTemplateId } }) => ({
                  triggerType: 'TaskDueDateReached',
                  config: { taskTemplateId },
                }),
              )

              .with(
                { entity: { type: 'Task' }, property: 'dueDate', offsetDirection: 'On' },
                ({ entity: { taskTemplateId } }) => ({
                  triggerType: 'TaskDueDateReached',
                  config: { taskTemplateId },
                }),
              )
              .with({ offsetDirection: P.union('Before', 'After') }, () =>
                ScheduledTriggerUtils.configToScheduledTrigger(config),
              )
              .run(),
            actions: [toSendEmailAction(config)],
            linkedEntity: { templateRevisionId: context.templateRevisionId },
          },
        };
      },
  };

  export const toSendEmailAction = (config: Config): NativeAutomation.GetActionByType<'SendEmail'> => {
    const taskIdPath = match<Timing['entity'] | undefined, string | undefined>(config.timing?.entity)
      .with({ type: 'Task', taskTemplateId: P._ }, () =>
        NativeAutomation.getJsonPath<NativeAutomation.AutomationContext.TaskCompleted>(
          'trigger',
          'event',
          'task',
          'id',
        ),
      )
      .otherwise(() => undefined);

    const customNotificationType = match<Config['timing'], NativeAutomation.CustomNotificationType>(config.timing)
      .with(
        {
          offsetDirection: 'On',
          entity: { type: 'WorkflowRun', templateRevisionId: P._ },
          property: 'startDate',
        },
        () => 'OnWorkflowRunCreated',
      )
      .with(
        {
          offsetDirection: 'After',
          entity: { type: 'WorkflowRun', templateRevisionId: P._ },
          property: 'startDate',
        },
        () => 'AfterWorkflowRunCreated',
      )
      .with(
        {
          offsetDirection: 'On',
          entity: { type: 'WorkflowRun', templateRevisionId: P._ },
          property: 'completedDate',
        },
        () => 'OnWorkflowRunCompleted',
      )
      .with(
        {
          offsetDirection: 'After',
          entity: { type: 'WorkflowRun', templateRevisionId: P._ },
          property: 'completedDate',
        },
        () => 'AfterWorkflowRunCompleted',
      )
      .with(
        {
          offsetDirection: 'On',
          entity: { type: 'Task', taskTemplateId: P._ },
          property: 'completedDate',
        },
        () => 'OnTaskCompleted',
      )
      .with(
        {
          offsetDirection: 'On',
          entity: { type: 'Task', taskTemplateId: P._ },
          property: 'dueDate',
        },
        () => 'OnTaskDueDateReached',
      )
      .with(
        {
          offsetDirection: 'Before',
          entity: { type: 'Task', taskTemplateId: P._ },
          property: 'dueDate',
        },
        () => 'BeforeTaskDueDate',
      )
      .with(
        {
          offsetDirection: 'After',
          entity: { type: 'Task', taskTemplateId: P._ },
          property: 'dueDate',
        },
        () => 'AfterTaskDueDate',
      )
      .with(
        {
          offsetDirection: 'After',
          entity: { type: 'Task', taskTemplateId: P._ },
          property: 'completedDate',
        },
        () => 'AfterTaskCompleted',
      )
      // Placeholder for now
      .run();

    return {
      actionType: 'SendEmail',
      key: 'sendEmail0',
      config: {
        recipients: config.recipients,
        emailTemplate: 'CustomTemplateNotification',
        emailTemplateConfig: {
          customNotificationType,
          customMessage: config.customMessage,
          checklistRevisionIdPath: NativeAutomation.getJsonPath<NativeAutomation.AutomationContext.WorkflowRunCreated>(
            'trigger',
            'event',
            'checklistRevision',
            'id',
          ),
          taskIdPath,
        },
      },
    };
  };

  export const isTimingValid = (timing?: Partial<Timing>): timing is Timing => {
    if (isTimingScheduled(timing)) {
      return Boolean(
        (timing?.offset?.minutes || timing?.offset?.hours || timing?.offset?.days || timing?.offset?.months) &&
          timing.entity &&
          timing.property,
      );
    } else {
      return Boolean(timing?.offsetDirection && timing.entity && timing.property);
    }
  };

  const isTimingScheduled = (timing?: Partial<Timing>): boolean => {
    return Boolean(timing?.offsetDirection === 'After' || timing?.offsetDirection === 'Before');
  };

  export const isConfigValid = (config?: Partial<Config>): config is Config => {
    return Boolean(config?.nativeAutomationId && (config.recipients?.length ?? 0) > 0 && isTimingValid(config.timing));
  };
}
