import angular from 'angular';
import { SentryStateContextUtils } from 'components/sentry/sentry-state-context';
import { SentryService } from 'components/sentry/sentry.service';
import { AuthEvent } from 'services/auth-event/AuthEvent';

angular
  .module('frontStreetApp.services')
  .service('SentryListener', function ($ngRedux, $rootScope, $stateParams, $transitions) {
    this.listen = function () {
      $rootScope.$on(AuthEvent.AUTH0_RETURNED_ERROR, (__event, error) => {
        const formattedError = `Message: '${error}'`;
        SentryService.captureMessage(formattedError);
      });

      $transitions.onStart({}, trans => {
        trans.promise.then(state => {
          SentryService.setStateContext(
            SentryStateContextUtils.fromTransition(state.name, $stateParams, $ngRedux.getState()),
          );
        });
      });
    };
  });
