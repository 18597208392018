import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import { PagesH1Element, PagesH2Element, PagesH3Element } from '../../pages-plate-types';
import { EditorHeading, EditorHeadingProps } from '../elements/heading';

export type HeaderElementProps = {
  element: PagesH1Element | PagesH2Element | PagesH3Element;
  attributes: RenderElementProps['attributes'];
  children: RenderElementProps['children'];
};

export const HeaderElement: React.FC<React.PropsWithChildren<HeaderElementProps>> = ({
  attributes,
  children,
  element,
}): JSX.Element => {
  return (
    <EditorHeading as={element.type} {...attributes} variant={element.type.slice(1) as EditorHeadingProps['variant']}>
      {children}
    </EditorHeading>
  );
};
