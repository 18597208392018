import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { WorkflowAutomationsChecklistWrapper } from './wrapper';

export const ChecklistWorkflowAutomationsModule = angular
  .module('ChecklistWorkflowAutomationsModule', [])
  .component(
    'psChecklistWorkflowAutomations',
    reactToAngular(WorkflowAutomationsChecklistWrapper, ['templateRevision', 'solutionTypeTag']),
  ).name;
