import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useToast } from 'components/design/next';
import * as React from 'react';
import { ConfigWizard, ConfigWizardProps } from '.';
import { useConfigWizardDisclosure } from './context';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export const ConfigWizardModalId = 'ConfigWizardModal';

export const ConfigWizardModal: React.FC<React.PropsWithChildren<Omit<ConfigWizardProps, 'onError'>>> = props => {
  const { isOpen, onClose } = useConfigWizardDisclosure();
  const toast = useToast();

  const handleConfigWizardError = React.useCallback(() => {
    toast({
      status: 'error',
      title: "We're having problems saving the configuration",
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
  }, [toast]);

  return (
    <Modal size="2xl" isCentered={true} onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent overflow="hidden" borderRadius="md" minHeight="75vh" maxHeight="90vh">
        <ModalCloseButton zIndex="overlay" />
        <ModalBody position="relative" padding="0">
          <Box position="absolute" left={0} right={0} top={0} bottom={0} height="100%">
            <ConfigWizard onError={handleConfigWizardError} {...props} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
