import { composeWithDevTools } from 'redux-devtools-extension';
import promiseMiddleware from 'redux-promise';
import thunk from 'redux-thunk';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { stateMerger } from 'reducers/util/state-merger';
import { applyMiddleware, createStore } from 'redux';
import { axiosService } from 'services/axios-service';
import { rootReducer } from 'app/reducers';
import { getSession } from 'reducers/session/session.selectors';
import pick from 'lodash/pick';

const whitelistTransform = createTransform((inboundState, key) => {
  // Select values from the route reducer
  if (key === 'session') {
    return pick(
      inboundState,
      // These are the minimum necessary fields that must be saved to the app be able
      // to boot correctly.
      ['token', 'organizationMemberships', 'selectedOrganizationId', 'user', 'profile'],
    );
  }

  return inboundState;
});

const persistConfig = {
  key: 'root',
  version: 3,
  storage,
  whitelist: ['session'],
  stateReconciler: stateMerger,
  transforms: [whitelistTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// This is a hack to support async configuration in Angular
// Need to have session data warmed in Redux, otherwise this won't work
// eslint-disable-next-line no-undef
const persistedData = localStorage.getItem('persist:root');
const session = persistedData ? JSON.parse(JSON.parse(persistedData).session) : {};
const initialState = { session };

const middlewares = [promiseMiddleware, thunk];

const composeEnhancers = composeWithDevTools({});

/** Special action to reset the state. */
export const RESET_STATE = 'RESET_STATE';

export function setupStore() {
  return createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
}

export const store = setupStore();

axiosService.initializeAxios(() => {
  const session = getSession(store.getState());

  return {
    organizationId: session.selectedOrganizationId,
    token: session.token,
  };
});
