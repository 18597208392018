import { TypeOfEnumValue } from '@process-street/subgrade/core';
import { TextareaFormFieldWidget } from '../widget-model';

function textareaFormFieldCIF<
  Code extends string,
  Config extends Record<
    string,
    {
      code: Code;
      isInvalid: (constraints: NonNullable<TextareaFormFieldWidget['constraints']>, value: string) => boolean;
    }
  >,
>(config: Config): Config {
  return config;
}

export const TextareaFormFieldValidation = textareaFormFieldCIF({
  notInBetween: {
    code: 'not-in-between',
    isInvalid: (constraints, value) =>
      !!constraints?.min &&
      !!constraints?.max &&
      !(value.length >= constraints?.min && value.length <= constraints.max),
  },
  maxChars: {
    code: 'max-chars',
    isInvalid: (constraints, value) => !!constraints.max && constraints.max < value.length,
  },
  minChars: {
    code: 'min-chars',
    isInvalid: (constraints, value) => !!constraints.min && constraints.min > value.length,
  },
});

export const getTextareaFormFieldWidgetErrorType = (
  constraints: TextareaFormFieldWidget['constraints'],
  value: string = '',
) => {
  if (value === '' || !constraints) return;
  return (
    Object.values(TextareaFormFieldValidation).find(config => {
      return config.isInvalid(constraints, value);
    })?.code ?? undefined
  );
};

export type TextareaFormFieldValidationCode = TypeOfEnumValue<typeof TextareaFormFieldValidation>['code'];
