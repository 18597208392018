import * as React from 'react';
import {
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  PopoverArrow,
  Button,
  Icon,
  VStack,
  Text,
} from 'components/design/next';
import { ChecklistColumn, Condition, ConditionalFilterOperandType } from '@process-street/subgrade/dashboard';
import { useFormResponsesStore } from '../../../store';
import { match } from 'ts-pattern';
import { FormResponsesConditionalFilterClause } from './form-responses-conditional-filter-clause';
import { useConditionalFilterState } from 'components/dashboard/components/checklist/ChecklistDashboardFilters/filters/ChecklistDashboardConditionalFilter/use-conditional-filter-state';

export interface FormResponsesConditionalFilterProps {}

export const FormResponsesConditionalFilter: React.FC<
  React.PropsWithChildren<FormResponsesConditionalFilterProps>
> = () => {
  const { setConditionalFilter, conditionalFilter } = useFormResponsesStore();

  const { clauses, onFilterAdd, storedFilter, onFilterSwitchType, onFilterDelete, onFilterChange } =
    useConditionalFilterState({
      currentFilter: conditionalFilter,
      onChange: setConditionalFilter,
      defaultFilter: {
        columnName: ChecklistColumn.ProgressStatus,
        condition: Condition.Contains,
        operand: { operandType: ConditionalFilterOperandType.ChecklistStatusList, value: [] },
      },
    });

  const count = storedFilter.children ? storedFilter.children.length : 0;
  const label = getLabel(count);
  const { filterType } = storedFilter;

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          size="sm"
          colorScheme="gray"
          variant="ghost"
          fontWeight="normal"
          leftIcon={<Icon variant="far" size="4" icon="filter" />}
          rightIcon={<Icon ml="auto" icon="chevron-down" size="4" variant="far" />}
          w={{ base: 'full', md: 'auto' }}
          aria-label="Filter by condition"
        >
          <Text as="span" variant="inherit" mr="auto">
            {label}
          </Text>
        </Button>
      </PopoverTrigger>

      <PopoverContent w={{ base: '100vw', md: '2xl' }} p="6" aria-label="conditional filters dialog">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <VStack alignItems="flex-start">
            {clauses.length === 0 ? (
              <Text>No filters are currently applied</Text>
            ) : (
              clauses.map((clause, i) => (
                <FormResponsesConditionalFilterClause
                  key={clause.id}
                  isFirst={i === 0}
                  clause={clause}
                  filterType={filterType}
                  onFilterSwitchType={onFilterSwitchType}
                  onFilterDelete={onFilterDelete}
                  onChange={onFilterChange}
                />
              ))
            )}

            <Button
              variant="ghost"
              size="sm"
              onClick={onFilterAdd}
              leftIcon={<Icon icon="plus" variant="far" size="3" />}
            >
              Add Filter
            </Button>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const getLabel = (count: number) => {
  return match(count)
    .with(0, () => 'Filters')
    .with(1, () => '1 Filter')
    .when(
      c => c > 99,
      () => '99+ Filters',
    )
    .otherwise(() => `${count} Filters`);
};
