import * as React from 'react';
import {
  SendRichEmailFormFieldConfig,
  SendRichEmailFormFieldWidget,
  TaskTemplate,
  WidgetLike,
} from '@process-street/subgrade/process';
import { MutationStatus } from 'react-query';
import { AiGenerateWidgetContentMenu } from 'features/widgets/components/ai/ai-generate-widget-content-menu/ai-generate-widget-content-menu';
import { AiGenerationTooltip } from 'features/widgets/components/ai/ai-generation-tooltip';
import { Text } from 'components/design/next';
import { StringUtils } from '@process-street/subgrade/util';

export type SendRichEmailAiGenerateMenuProps = {
  widget: SendRichEmailFormFieldWidget;
  onContentGeneration: (config: SendRichEmailFormFieldConfig) => void;
  onContentGenerationStatusChange?: (status: MutationStatus) => void;
  getWidgetContent: () => string;
  getWidgetSelection: () => string;
  menuButton?: React.ReactElement;
  taskTemplate?: TaskTemplate;
};

export const SendRichEmailAiGenerateMenu: React.FC<React.PropsWithChildren<SendRichEmailAiGenerateMenuProps>> = ({
  widget,
  onContentGeneration,
  onContentGenerationStatusChange,
  getWidgetContent,
  getWidgetSelection,
  menuButton,
  taskTemplate,
}) => {
  const handleContentGenerated = (res: WidgetLike) => {
    onContentGeneration((res as SendRichEmailFormFieldWidget).config);
  };

  const tooltip = (
    <Text key="email-subject-error" fontSize="xs">
      Please enter a subject for the email to generate with AI.
    </Text>
  );
  const [hasTaskError, setHasTaskError] = React.useState(false);
  const hasSubjectError = StringUtils.isBlank(widget.config.subject);

  return (
    <AiGenerationTooltip
      extraErrorItems={hasSubjectError ? [tooltip] : undefined}
      setHasError={setHasTaskError}
      taskTemplate={taskTemplate ?? (widget.header.taskTemplate as TaskTemplate)}
    >
      <AiGenerateWidgetContentMenu
        headerId={widget.header.id}
        getWidgetContent={getWidgetContent}
        getWidgetSelection={getWidgetSelection}
        onContentGenerated={handleContentGenerated}
        onGenerationStatusChange={onContentGenerationStatusChange}
        isDisabled={hasSubjectError || hasTaskError}
        buttonSize="sm"
        menuButton={menuButton}
      />
    </AiGenerationTooltip>
  );
};
