import { TaskWithTaskTemplate } from '../process';

export enum ApprovalTasksGroupType {
  NotSubmitted = 'NotSubmitted',
  Awaiting = 'Awaiting',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export interface ApprovalTaskGroupsMap {
  awaitingTasks: TaskWithTaskTemplate[];
  approvedTasks: TaskWithTaskTemplate[];
  rejectedTasks: TaskWithTaskTemplate[];
  notSubmittedTasks: TaskWithTaskTemplate[];
}
