import { Group, Muid, OrganizationMembership } from '@process-street/subgrade/core';
import {} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateGroupMutationParams = {
  organizationId: Muid;
  name: string;
};

export type CreateGroupMutationResponse = {
  group: Group;
  organizationMembership: OrganizationMembership;
};

export const CreateGroupMutation = {
  key: ['post', 'group'],
  mutationFn: (params: CreateGroupMutationParams) =>
    axiosService
      .getAxios()
      .post<CreateGroupMutationResponse>(`/1/groups`, params)
      .then(res => res.data),
};

export const useCreateGroupMutation = (
  options: UseMutationOptions<CreateGroupMutationResponse, AxiosError, CreateGroupMutationParams> = {},
) => {
  return useMutation(CreateGroupMutation.mutationFn, options);
};
