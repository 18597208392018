import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { connectController } from 'reducers/util';
import { createSelector } from 'reselect';
import { HttpStatus } from '@process-street/subgrade/util';
import templateUrl from './user-invite.component.html';
import './user-invite.scss';
import { features } from 'services/features/features';
import { Rules } from 'components/invitation/invitation-widget/rules';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { SourcesToRolesMap } from 'pages/organizations/manage/users/components/role-selector/model';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { isGroupUserOm } from '@process-street/subgrade/util/membership-utils';
import { trace } from 'components/trace';

export const UserInviteComponent = {
  templateUrl,
  controller: class {
    constructor($ngRedux, $scope, InvitationService, ResponseUtilsService, UserSuggester, ToastService) {
      'ngInject';

      this.logger = trace({ name: 'OrganizationManageUsersCtrl' });
      this.logger.info('loading ctrl');

      this.$scope = $scope;
      this.InvitationService = InvitationService;
      this.ResponseUtilsService = ResponseUtilsService;
      this.ToastService = ToastService;
      $scope.info = {};
      $scope.info.guest = false;
      this.$scope.forms = {};

      this.inviting = false;
      this.paywallIsOpen = false;

      this.userSuggester = new UserSuggester([], membership => !isGroupUserOm(membership));

      const mapStateToCtrl = createSelector(
        [
          SessionSelector.getSelectedOrganizationId,
          SessionSelector.getCurrentPlan,
          OrganizationMembershipSelector.getAllWithUserBySelectedOrganizationId,
          FeatureFlagSelector.getFeatureFlags,
        ],
        (selectedOrganizationId, plan, organizationMemberships, featureFlags) => {
          const freemium = features.isFreemiumTrack(plan.id);
          return { selectedOrganizationId, organizationMemberships, featureFlags, freemium };
        },
      );

      const mapDispatchToCtrl = () => ({});

      connectController($ngRedux, () => mapStateToCtrl, mapDispatchToCtrl)(this);
    }

    resetForm() {
      this.$scope.info = { guest: false };
      this.$scope.forms.form.$setPristine();
    }

    inviteConfig = Rules.fullMemberOrFreeMember;
    availableRoles = SourcesToRolesMap.All;

    // Invite member
    doInvite = ({ email, role, username, resetForm = false }) => {
      this.inviting = true;
      this.invitee = username ?? email;

      const existingUser = this.state.organizationMemberships.find(
        omWithUser => omWithUser.user.email.toLowerCase() === email.toLowerCase(),
      );

      if (existingUser) {
        const isUserInactive = !existingUser.isActive;
        const inactiveCopy = isUserInactive ? 'a deactivated member' : 'a member';
        this.ToastService.openToast({
          status: 'warning',
          title: `We couldn't invite ${email}`,
          description: `They are already ${inactiveCopy} of this organization.`,
        });
        this.inviting = false;
      } else {
        const resource = {
          type: 'Organization',
          id: this.state.selectedOrganizationId,
        };

        const user = this.userSuggester.cacheGet(email) || {};
        const options = {
          username,
          avatarUrl: user.avatarUrl,
          provider: user.provider,
          role,
        };

        this.InvitationService.invite(email, resource, options)
          .then(
            () => {
              this.ToastService.openToast({
                status: 'success',
                title: `Invitation sent to ${email}`,
              });

              if (resetForm) {
                this.resetForm();
              }
            },
            errorResponse => this._showMessageByResponse(errorResponse, email),
          )
          .finally(() => {
            this.inviting = false;
          });
      }
    };

    closePaywall = () => {
      this.paywallIsOpen = false;
      this.invitee = '';
    };

    _showMessageByResponse(errorResponse, email) {
      switch (errorResponse.status) {
        case HttpStatus.FORBIDDEN:
          this.logger.warn('could not invite member, too many members');
          this.ToastService.openToast({
            status: 'warning',
            title: `We couldn't invite ${email}`,
            description: 'You cannot add any more members. Please reach out to your admin.',
          });
          break;
        case HttpStatus.TOO_MANY_REQUESTS:
          this.logger.error('rate limited');
          this.ToastService.openToast({
            status: 'warning',
            title: `We couldn't invite ${email}`,
            description: 'You have invited too many members too quickly. Please try again later or contact support.',
          });
          break;
        default:
          if (this.ResponseUtilsService.isUsersLimitReached(errorResponse)) {
            const message = this.ResponseUtilsService.getUsersLimitReachedMessage(errorResponse);
            this.ToastService.openToast({
              status: 'warning',
              title: `We couldn't invite ${email}`,
              description: message,
            });
          } else if (errorResponse.status === HttpStatus.PAYMENT_REQUIRED && this.state.freemium) {
            this.paywallIsOpen = true;
          } else if (
            errorResponse.status === HttpStatus.BAD_REQUEST &&
            errorResponse.data?.includes?.("Domain isn't valid for this SSO organization")
          ) {
            this.ToastService.openToast({
              status: 'error',
              title: `We can't invite that user`,
              description:
                'You can only invite users from domains registered with your organization. ' +
                'For further assistance, contact our support team.',
            });
          } else if (errorResponse.status === HttpStatus.CONFLICT && errorResponse.data.message) {
            this.ToastService.openToast({
              status: 'error',
              title: `We're having problems inviting ${email}`,
              description: errorResponse.data.message,
            });
          } else {
            this.logger.error('failed to invite member');
            this.ToastService.openToast({
              status: 'error',
              title: `We're having problems inviting ${email}`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          }
          break;
      }
    }
  },
};
