import * as React from 'react';
import { BoxProps } from 'components/design/next';
import { FormFieldValue } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { EmptyAiTaskAudit } from './empty-ai-task-audit';
import { RunningAiTaskAudit } from './running-ai-task-audit';
import { FailedAiTaskAudit } from './failed-ai-task-audit';
import { FilledAiTaskAudit } from './filled-ai-task-audit';
import { useChecklistRevisionNativeAutomationStatus } from '../../hooks/use-checklist-revision-native-automation-status';
import { CommonAuditProps } from './common';

export interface AiTaskAuditProps extends BoxProps, CommonAuditProps {
  formFieldValue?: FormFieldValue;
}

export const AiTaskAudit: React.FC<React.PropsWithChildren<AiTaskAuditProps>> = ({ formFieldValue, ...props }) => {
  const {
    aiTaskTemplate: { nativeAutomation },
  } = props;
  const { status: automationStatus, errorMessage } = useChecklistRevisionNativeAutomationStatus({ nativeAutomation });

  return match({ automationStatus, formFieldValue })
    .with({ automationStatus: undefined }, () => <EmptyAiTaskAudit {...props} />)
    .with({ automationStatus: 'AutomationRunning' }, () => <RunningAiTaskAudit {...props} />)
    .with({ automationStatus: 'AutomationFailed' }, () => <FailedAiTaskAudit errorMessage={errorMessage} {...props} />)
    .with({ formFieldValue: { audit: P.not(P.nullish) } }, ({ formFieldValue }) => (
      <FilledAiTaskAudit formFieldValue={formFieldValue} {...props} />
    ))
    .otherwise(() => null);
};
