import { DeserializeHtml, ELEMENT_DEFAULT, getPluginType, toggleNodeType } from '@udecode/plate-core';
import {
  getBlockAbove,
  getNode,
  getNodeEntries,
  getParentNode,
  isFirstChild,
  someNode,
  getPath,
  setNodes,
  withoutNormalizing,
  TNodeEntry,
  getNodeChildren,
} from '@udecode/plate-common';
import { isElement, splitNodes, unwrapNodes } from '@udecode/slate';
import { ELEMENT_PS_UNORDERED_LIST_ITEM } from '../../components/unordered-list';
import { PSEditor } from '../../utils/ps-editor';
import { ELEMENT_LI } from '@udecode/plate-list';
import {
  PagesListItemElement,
  PagesEditor,
  createPagesPluginFactory,
  PagesWithOverride,
  PagesRichText,
  PagesDOMHandlers,
  PagesNodeEntry,
  PagesAutoformatRule,
} from '../../pages-plate-types';

export function moveBlockUp(editor: PagesEditor, [block, path]: TNodeEntry<PagesListItemElement>) {
  if (block.depth === 1) {
    toggleNodeType(
      editor,
      { activeType: getPluginType(editor, ELEMENT_DEFAULT), inactiveType: block.type },
      {
        at: path,
      },
    );
  }
  withoutNormalizing(editor, () => {
    setNodes(
      editor,
      { depth: block.depth ? block.depth - 1 : 0 },
      {
        at: path,
      },
    );
  });
}

export function moveBlockDown(editor: PagesEditor, [block, path]: TNodeEntry<PagesListItemElement>) {
  withoutNormalizing(editor, () => {
    setNodes(
      editor,
      { depth: (block.depth ?? 0) + 1 },
      {
        at: path,
      },
    );
  });
}

const onKeyDownListHandler: PagesDOMHandlers['onKeyDown'] = editor => event => {
  if (event.key === 'Tab') {
    const blocksAbove = getNodeEntries<PagesListItemElement>(editor, {
      match: PSEditor.isTypedElement,
      mode: 'lowest',
    });

    const shiftTab = event.shiftKey;
    if (shiftTab) {
      if (blocksAbove) {
        for (const block of blocksAbove) {
          if (block) {
            moveBlockUp(editor, block);
          }
        }
        event.preventDefault();
      }
    }

    // move down with tab
    const tab = !event.shiftKey;
    if (tab && blocksAbove) {
      for (const [block, blockPath] of blocksAbove) {
        if (!isFirstChild(blockPath)) {
          moveBlockDown(editor, [block, blockPath]);
        }
      }
      event.preventDefault();
    }
  }
};

const onDeleteBackward: PagesWithOverride = editor => {
  const { deleteBackward } = editor;
  editor.deleteBackward = unit => {
    if (editor.selection) {
      const parentNode = getParentNode(editor, editor.selection);
      const currentNode = getNode<PagesRichText>(editor, getPath(editor, editor.selection));
      if (parentNode) {
        const [parentBlock] = parentNode;
        if (PSEditor.isListItemElement(parentBlock)) {
          if (currentNode?.text === '') {
            setNodes(editor, { type: 'p' });
            return editor;
          }
        }
      }
    }

    deleteBackward(unit);
  };
  return editor;
};

const onInsertBreak: PagesWithOverride = editor => {
  const { insertBreak } = editor;
  editor.insertBreak = () => {
    const previousNode = getBlockAbove<PagesListItemElement>(editor);

    if (previousNode) {
      const [previousBlock] = previousNode;
      if (PSEditor.isListItemElement(previousBlock)) {
        splitNodes(editor, { always: true });

        setNodes(editor, {
          type: ELEMENT_PS_UNORDERED_LIST_ITEM,
          depth: previousBlock.depth,
          children: [{ text: '' }],
        });

        PSEditor.selectNextEmptyWidget(editor);

        return;
      }
    }

    insertBreak();
  };
  return editor;
};

/**
 * Ensures list items only ever contain leaf text nodes or inline elements.
 * Invalid:
 *  li -> p -> text
 *  li -> h1 -> text
 *
 * Valid:
 *  li -> text
 */
const withNormalizer: PagesWithOverride = editor => {
  const { normalizeNode } = editor;

  return Object.assign(editor, {
    normalizeNode: (entry: PagesNodeEntry) => {
      const [node, path] = entry;
      if (isElement(node) && node.type === ELEMENT_LI) {
        const children = getNodeChildren(editor, path);
        for (const [node, path] of children) {
          if (isElement(node) && !editor.isInline(node)) {
            unwrapNodes(editor, { at: path });

            return;
          }
        }
      }
      normalizeNode(entry);
    },
  });
};

export const listDeserializer: DeserializeHtml = {
  getNode: el => ({
    type: ELEMENT_PS_UNORDERED_LIST_ITEM,
    depth: Number(el.getAttribute('aria-level')),
  }),
  rules: [{ validNodeName: 'LI' }],
};

const composeOverrides: (overrides: PagesWithOverride[]) => PagesWithOverride = overrides => (editor, plugin) =>
  overrides.reduce((accEditor, override) => override(accEditor, plugin), editor);

export const createPSList = createPagesPluginFactory({
  key: ELEMENT_PS_UNORDERED_LIST_ITEM,
  handlers: { onKeyDown: onKeyDownListHandler },
  withOverrides: composeOverrides([onInsertBreak, onDeleteBackward, withNormalizer]),
  isElement: true,
  deserializeHtml: listDeserializer,
});

export const autoformatUnorderedListRule: PagesAutoformatRule = {
  type: ELEMENT_PS_UNORDERED_LIST_ITEM,
  match: ['-', '*'],
  mode: 'block',
  allowSameTypeAbove: true,
  format: editor => {
    const isActive = someNode(editor, { match: { type: ELEMENT_PS_UNORDERED_LIST_ITEM } });
    if (!isActive) {
      const nodes = [
        ...getNodeEntries(editor, {
          match: { type: getPluginType(editor, ELEMENT_DEFAULT) },
        }),
      ];
      for (const [, path] of nodes) {
        toggleNodeType(
          editor,
          {
            activeType: ELEMENT_PS_UNORDERED_LIST_ITEM,
            inactiveType: getPluginType(editor, ELEMENT_DEFAULT),
          },
          {
            at: path,
          },
        );

        setNodes(
          editor,
          {
            type: ELEMENT_PS_UNORDERED_LIST_ITEM,
            depth: 1,
            children: [{ text: '' }],
          },
          {
            at: path,
          },
        );
      }
    }
  },
};
