import angular from 'angular';
import { FileUploadModuleSelector } from 'components/file-upload/store/file-upload.selectors';
import { connectController } from 'reducers/util';
import templateUrl from './checklist-file-field.component.html';
import './checklist-file-field.scss';
import { FormFieldEvent } from 'services/form-field-event';

angular.module('frontStreetApp.directives').component('psChecklistFileFormField', {
  bindings: {
    user: '<',
    widget: '<',
    editable: '<',
    formFieldValue: '<',
    fileFieldUpload: '<',
    onDeleteValue: '&',
    onInteractionEnd: '&',
    errorMessage: '<',
    readOnly: '<',
  },
  templateUrl,
  controller($ngRedux, $q, $rootScope, MessageBox) {
    const ctrl = this;
    this.$q = $q;

    const mapStateToThis = () => state => {
      const upload = FileUploadModuleSelector.getUploadByWidgetId(ctrl.widget.id)(state);

      return { upload };
    };

    connectController($ngRedux, mapStateToThis, null, null)(ctrl);

    ctrl.$onChanges = function (values) {
      if (
        values.formFieldValue &&
        values.formFieldValue.currentValue &&
        values.formFieldValue.currentValue.fieldValue
      ) {
        ctrl.fieldValue = angular.copy(values.formFieldValue.currentValue.fieldValue);
      }
    };

    ctrl.clickDisabledButton = function () {
      $rootScope.$broadcast(FormFieldEvent.DISABLED_FORM_FIELD_CLICKED);
    };

    ctrl.processDone = function (event, data) {
      ctrl.fileFieldUpload.processDone(event, data);
    };

    ctrl.processFail = function (event, data) {
      ctrl.fileFieldUpload.processFail(event, data);
    };

    ctrl.done = function (event, data) {
      ctrl.fileFieldUpload.done(event, data);
    };

    ctrl.fail = function (event, data) {
      ctrl.fileFieldUpload.fail(event, data);
    };

    ctrl.remove = function () {
      const message = 'If you remove this file, it will be deleted and irrecoverable.';
      MessageBox.confirm({
        title: 'Remove this file?',
        message,
        okButton: {
          type: 'danger',
          text: 'Remove file',
          action: () => {
            // Remove right away for responsiveness
            angular.copy({}, ctrl.fieldValue);

            $rootScope.$broadcast(FormFieldEvent.FORM_FIELD_VALUE_UPDATED, ctrl.formFieldValue);

            ctrl.formFieldValue.audit = {
              updatedBy: { id: ctrl.user.id },
              updatedDate: Date.now(),
            };

            ctrl.onDeleteValue({ widget: ctrl.widget });
          },
        },
        cancelButton: { text: 'Keep file' },
      });
    };

    ctrl.getExtension = function (filename) {
      return filename && filename.split('.').pop();
    };

    ctrl.isImage = () => ctrl.formFieldValue?.fieldValue?.mimeType?.indexOf('image') >= 0;
  },
});
