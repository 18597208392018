import angular from 'angular';
import { AnalyticsConstants } from '@process-street/subgrade/analytics/analytics-constants';
import { EventName } from 'services/event-name';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular.module('frontStreetApp.services').service('TemplateAnalyticsListener', function () {
  this.listen = function (scope) {
    const { Key } = AnalyticsConstants;

    scope.$on(EventName.TEMPLATE_VIEWED, (__event, data) => {
      const props = {};
      props[Key.TEMPLATE_ID] = data.template && data.template.id;
      props[Key.TEMPLATE_NAME] = data.template && data.template.name;

      AnalyticsService.trackEvent(AnalyticsConstants.Event.TEMPLATE_VIEWED, props);
    });
  };
});
