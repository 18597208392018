import { Muid } from '@process-street/subgrade/core';
import { Widget } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { QueryClient, QueryObserver, QueryObserverOptions, useQuery, UseQueryOptions, QueryKey } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetWidgetsByChecklistRevisionIdQueryParams = { checklistRevisionId?: Muid };

export type GetWidgetsByChecklistRevisionIdQueryResponse = Widget[];

export const GetWidgetsByChecklistRevisionIdQuery = {
  key: ['checklist-revisions', 'widgets'],
  getKey: (params: GetWidgetsByChecklistRevisionIdQueryParams): QueryKey => [
    ...GetWidgetsByChecklistRevisionIdQuery.key,
    params,
  ],
  queryFn: ({ checklistRevisionId }: GetWidgetsByChecklistRevisionIdQueryParams) =>
    axiosService
      .getAxios()
      .get<GetWidgetsByChecklistRevisionIdQueryResponse>(`/1/checklist-revisions/${checklistRevisionId}/widgets`)
      .then(res => res.data),
};

export const useGetWidgetsByChecklistRevisionIdQuery = <Select = GetWidgetsByChecklistRevisionIdQueryResponse>(
  params: GetWidgetsByChecklistRevisionIdQueryParams,
  options: UseQueryOptions<GetWidgetsByChecklistRevisionIdQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetWidgetsByChecklistRevisionIdQuery.getKey(params),
    () => GetWidgetsByChecklistRevisionIdQuery.queryFn(params),
    {
      ...options,
      enabled: Boolean(params.checklistRevisionId) && options.enabled !== false,
    },
  );
};

export const makeGetWidgetsByChecklistRevisionIdQueryObserver = ({
  queryClient,
  options,
  ...params
}: GetWidgetsByChecklistRevisionIdQueryParams & {
  queryClient: QueryClient;
  options: QueryObserverOptions<GetWidgetsByChecklistRevisionIdQueryResponse, AxiosError>;
}) => {
  return new QueryObserver<GetWidgetsByChecklistRevisionIdQueryResponse, AxiosError>(queryClient, {
    queryKey: GetWidgetsByChecklistRevisionIdQuery.getKey(params),
    queryFn: () => GetWidgetsByChecklistRevisionIdQuery.queryFn(params),
    enabled: Boolean(params.checklistRevisionId) && options.enabled !== false,
    ...options,
  });
};
