import { ProgressStatus } from '@process-street/subgrade/dashboard';
import React from 'react';
import { Progress as ChProgress, ProgressProps as ChProgressProps } from 'components/design/next';

const classes: Record<ProgressStatus, ChProgressProps['colorScheme']> = {
  [ProgressStatus.Archived]: 'purple',
  [ProgressStatus.Completed]: 'green',
  [ProgressStatus.DueSoon]: 'yellow',
  [ProgressStatus.OnTrack]: 'blue',
  [ProgressStatus.Overdue]: 'red',
};

interface ProgressProps extends Partial<ChProgressProps> {
  status: ProgressStatus;
}

export const Progress: React.FunctionComponent<React.PropsWithChildren<ProgressProps>> = ({ status, ...props }) => {
  return <ChProgress colorScheme={classes[status]} {...props} />;
};
