import React, { ChangeEvent, useRef } from 'react';
import { Muid } from '@process-street/subgrade/core';
import { Box, Button, Text, Input, ListItem, Link, UnorderedList } from 'components/design/next';
import { useQuery } from 'react-query';
import { CsvLastRun } from '../model/CsvLastRun';
import { RunFromCsvApi } from '../services/RunFromCsvApi';
import { LastRunInfo } from './LastRunInfo';
import { useState } from 'react';
import { usePromise } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faSync } from '@fortawesome/pro-regular-svg-icons';
import { useInjector } from 'components/injection-provider';
import { CookieService } from 'features/cookies/cookie-service';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useToast } from 'components/design/next';
import { getEnv } from 'components/common/env';

export interface RunFromCsvProps {
  templateId: Muid;
  onSupportClick: () => void;
}

export const RunFromCsv: React.FunctionComponent<React.PropsWithChildren<RunFromCsvProps>> = ({
  onSupportClick,
  templateId,
}) => {
  const { $state, SessionService } = useInjector('$state', 'SessionService');
  const inputRef = useRef<HTMLInputElement>();
  const inputRefHandler = (instance: HTMLInputElement) => (inputRef.current = instance);
  const [processing, setProcessing] = useState(false);
  const mounted = usePromise();

  const toast = useToast();

  const lastRunQuery = useQuery<CsvLastRun | undefined, Error>(
    ['csv-last-run', templateId],
    () =>
      RunFromCsvApi.getLastRun(templateId).then(res => {
        const ready = !res || res.recordCount === res.processedCount;
        if (processing && ready && res) {
          const successfulCount = res.processedCount - res.errorCount;
          if (successfulCount > 0) {
            toast({
              status: 'success',
              title: `${successfulCount} workflow run(s) created`,
            });

            $state.go('reports', { templateId });
          }
        }

        setProcessing(!ready);
        return res;
      }),
    {
      enabled: !!templateId,
      staleTime: Infinity,
    },
  );

  const exampleCsvUrl = `${getEnv().APP_API_URL}/1/templates/${templateId}/run-csv/example`;

  const submitForm = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    mounted(
      RunFromCsvApi.uploadCsvFile(templateId, file)
        .then(result => {
          if (result.error) {
            toast({
              status: 'warning',
              title: `We couldn't process the the upload file`,
              description: result.error,
            });
          } else {
            setProcessing(true);
            toast({
              status: 'success',
              title: `File uploaded`,
              description: `${result.linesCount} line(s) will be now processed.`,
            });
          }
        })
        .catch(() => {
          toast({
            status: 'error',
            title: `We're having problems uploading the file`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        }),
    );
  };

  const uploadClick = () => {
    inputRef?.current?.click();
    refreshQuery();
  };

  const exampleCsvSubmit = () => {
    const token = SessionService.getToken();
    CookieService.setTemporaryTokenCookie(token!);
  };

  const refreshQuery = () => lastRunQuery.refetch();

  return (
    <Box color="gray.600">
      <Text color="gray.500" mb="4">
        You can run multiple workflows at once by uploading a CSV file.
      </Text>

      <Box display="flex" alignItems="flex-start" justifyContent="space-between">
        <Input ref={inputRefHandler} type="file" accept="text/csv" className="hidden" onChange={submitForm} />
        <Button
          variant="outline"
          colorScheme="gray"
          onClick={uploadClick}
          isDisabled={processing}
          fontWeight="normal"
          leftIcon={<FontAwesomeIcon icon={faCloudUploadAlt} />}
        >
          Upload and run CSV file
        </Button>

        <Button
          variant="outline"
          colorScheme="gray"
          fontWeight="normal"
          onClick={refreshQuery}
          isDisabled={!processing || lastRunQuery.isFetching}
          leftIcon={<FontAwesomeIcon icon={faSync} />}
        >
          Refresh logs
        </Button>

        <form action={exampleCsvUrl} target="_blank" onSubmit={exampleCsvSubmit}>
          <Button type="submit" variant="ghost" color="brand.500" fontWeight="normal">
            Download example CSV file
          </Button>
        </form>
      </Box>

      <Text color="gray.500" mt="4">
        Last runs
      </Text>
      {lastRunQuery.data && <LastRunInfo lastRun={lastRunQuery.data} />}
      {!lastRunQuery.data && <Text>No workflow runs yet.</Text>}

      <Box mt={8}>
        <Text fontSize="lg">Tips</Text>
        <UnorderedList>
          <ListItem>
            Make sure the first row of your data is a header row (contains the column names) from the example CSV file.
          </ListItem>
          <ListItem>
            The due date format (if not blank) must be in ISO format: <b>yyyy-MM-ddTHH:mm</b> or <b>yyyy-MM-dd HH:mm</b>
          </ListItem>
          <ListItem>
            Please{' '}
            <Link color="brand.500" onClick={onSupportClick}>
              contact support
            </Link>{' '}
            if you need assistance.
          </ListItem>
        </UnorderedList>
      </Box>
    </Box>
  );
};
