import * as React from 'react';
import { BlvdSelect, BlvdSelectHelpers } from 'components/design/BlvdSelect';
import { DataSet } from '@process-street/subgrade/process';
import { OnChangeValue } from 'react-select';
import { DataSetDropdownSingleValue } from './single-value';
import { DataSetDropdownOption } from './option';

export type OptionType = 'DataSet' | 'SavedView';

export type DataSetDropdownOptionType = {
  type: OptionType;
  dataSet: DataSet;
  label: string;
  value: string;
};

export type DataSetsDropdownProps = {
  dataSets: DataSet[];
  value: DataSetDropdownOptionType | undefined;
  onChange: (option: DataSetDropdownOptionType | undefined | null) => void;
};

export const DataSetsDropdown: React.FC<React.PropsWithChildren<DataSetsDropdownProps>> = ({
  dataSets,
  value,
  onChange,
}) => {
  const options: DataSetDropdownOptionType[] = dataSets.flatMap(dataSet => [
    {
      label: dataSet.name,
      value: dataSet.id,
      type: 'DataSet' as OptionType,
      dataSet,
    },
    ...dataSet.savedViews.map(savedView => ({
      label: savedView.name,
      value: savedView.id,
      type: 'SavedView' as OptionType,
      dataSet,
    })),
  ]);

  const handleChange = (value: OnChangeValue<DataSetDropdownOptionType, false>) => {
    if (BlvdSelectHelpers.isOptionsType<DataSetDropdownOptionType>(value)) {
      // Get the first value in case it's a multi value select.
      onChange(value[0]);
    } else {
      onChange(value);
    }
  };

  return (
    <BlvdSelect
      options={options}
      value={value}
      isOptionDisabled={option => option.type === 'DataSet' && !option.dataSet.canAccessDataSet}
      components={{
        SingleValue: DataSetDropdownSingleValue,
        Option: DataSetDropdownOption,
      }}
      onChange={handleChange}
      isSearchable
    />
  );
};
