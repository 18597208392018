import {
  AutomationInstance,
  IncomingWebhook,
  isIncomingWebhookInstance,
  isTemplateSolutionInstance,
  isTrayAutomationAppName,
  TemplateSolutionInstance,
  TrayAutomationAppName,
} from '@process-street/subgrade/automation';
import { isNativeTemplateTrigger, NativeTemplateTrigger } from '@process-street/subgrade/process';

export type DisplayableTrigger = AutomationInstance | NativeTemplateTrigger | TrayAutomationAppName;

export const DisplayableTriggerUtils = {
  isTrayAutomationAppName(trigger: DisplayableTrigger): trigger is TrayAutomationAppName {
    return typeof trigger === 'string' && !isNativeTemplateTrigger(trigger) && isTrayAutomationAppName(trigger);
  },
  isIncomingWebhookInstance: (trigger: DisplayableTrigger): trigger is IncomingWebhook => {
    if (typeof trigger === 'string') return false;
    return isIncomingWebhookInstance(trigger);
  },
  isTemplateSolutionInstance: (trigger: DisplayableTrigger): trigger is TemplateSolutionInstance => {
    if (typeof trigger === 'string') return false;
    return isTemplateSolutionInstance(trigger);
  },
};
