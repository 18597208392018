import { AxiosError } from 'axios';
import { QueryKey, QueryObserver } from 'react-query';
import { ActorRefFrom, assign, createMachine, StateFrom } from 'xstate';

type Context<Data, Error> = {
  data?: Data;
  error?: Error;
};

type Event<Data, Error> = { type: 'SUCCESS'; data: Data } | { type: 'ERROR'; error: Error } | { type: 'FETCHING' };

type QueryIdentifier = { id: string; data: any };

// `I extends unknown` always passes, it's a trick to take a union and distribute over each member.
// This allows SystemUpdateEvent<Foo | Bar> to evaluate to SystemUpdateEvent<Foo> | SystemUpdateEvent<Bar>
export type SystemUpdateEvent<I extends QueryIdentifier> = I extends unknown ? _SystemUpdateEvent<I> : never;
type _SystemUpdateEvent<I extends QueryIdentifier> =
  | {
      type: 'xstate.update';
      state: StateFrom<QueryMachine<I['data'], AxiosError>>;
      id: I['id'];
    }
  // this never provides beter type info (i.e., expands the type)
  | never;

/** This function converts a React Query `QueryObserver` into a state machine so queries can be used more easily with XState. */
export function makeQueryMachine<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>({ observer }: { observer: QueryObserver<TQueryFnData, TError, TData, TQueryData, TQueryKey> }) {
  return createMachine(
    {
      id: `query-machine:${observer.options.queryKey}`,
      type: 'parallel',
      predictableActionArguments: true,
      schema: {
        context: {} as Context<TData, TError>,
        events: {} as Event<TData, TError>,
      },
      tsTypes: {} as import('./query-machine.typegen').Typegen0,
      context: {
        data: undefined,
        error: undefined,
      },
      invoke: { id: 'query', src: 'query' },
      states: {
        fetchStatus: {
          initial: 'idle',
          states: {
            idle: {
              on: { FETCHING: 'fetching' },
            },
            fetching: {
              on: { SUCCESS: 'idle', ERROR: 'idle' },
            },
          },
        },
        status: {
          initial: 'loading',
          on: {
            SUCCESS: { target: '.success', actions: 'assignData' },
            ERROR: { target: '.error', actions: 'assignError' },
          },
          states: { loading: {}, success: {}, error: {} },
        },
      },
    },
    {
      actions: {
        assignData: assign({ data: (_ctx, evt) => evt.data }),
        assignError: assign({ error: (_ctx, evt) => evt.error }),
      },
      services: {
        query: (_ctx, _evt) => (send, _onReceive) => {
          return observer.subscribe(result => {
            if (result.isFetching) {
              return send({ type: 'FETCHING' });
            }
            if (result.isError) {
              return send({ type: 'ERROR', error: result.error });
            }
            if (result.isSuccess) {
              return send({ type: 'SUCCESS', data: result.data });
            }
          });
        },
      },
    },
  );
}

export type QueryMachine<Data, Error = AxiosError> = ReturnType<typeof makeQueryMachine<Data, Error>>;
export type QueryActor<Data, Error = AxiosError> = ActorRefFrom<QueryMachine<Data, Error>>;

function getQueryData<
  T extends QueryActor<any, AxiosError>,
  Data = T extends QueryActor<infer Data, any> ? Data : never,
>(query: T | undefined): Data | undefined {
  return query?.getSnapshot()?.context.data;
}

function isQueryActorSuccess<T extends QueryActor<any, AxiosError>>(query: T | undefined): boolean {
  return Boolean(query?.getSnapshot()?.matches('status.success'));
}

type GetSystemEventId<Evt extends SystemUpdateEvent<any>> = Evt extends SystemUpdateEvent<infer I> ? I['id'] : string;

function isUpdateEventSuccess<Evt extends SystemUpdateEvent<any>, Id extends GetSystemEventId<Evt>>(
  evt: Evt,
  id: Id,
  // this type assertion allows the `state` property to be narrowed
): evt is Extract<Evt, { id: Id }> {
  return evt.id === id && evt.state.matches('status.success');
}

export const QueryActorSelectors = {
  getQueryData,
  isQueryActorSuccess,
  isUpdateEventSuccess,
};
