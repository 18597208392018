import * as React from 'react';
import { IconButton, Icon, IconButtonProps, forwardRef } from 'components/design/next';

export type DeleteButtonProps = Omit<IconButtonProps, 'aria-label'>;

export const DeleteButton = forwardRef<DeleteButtonProps, 'button'>((props, ref) => (
  <IconButton
    ref={ref}
    bg="white"
    color="gray.600"
    variant="outline"
    colorScheme="gray"
    size="sm"
    borderWidth="px"
    icon={<Icon size="4" variant="far" icon="trash" />}
    aria-label="delete"
    {...props}
  />
));
