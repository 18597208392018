import { Selector } from 'reselect';
import { Option, User } from '../../../core';
import { LightReduxState } from '../../types';
import { BaseSessionSelector } from '../session.selectors';

type SelectorWithCurrentUser<S extends LightReduxState, T> = (currentUser: User, state: S) => Option<T>;

export function selectorWithCurrentUser<S extends LightReduxState, T>(
  selector: SelectorWithCurrentUser<S, T> | SelectorWithCurrentUser<S, Option<T>>,
): Selector<S, Option<T>> {
  return (state: S) => {
    const currentUser = BaseSessionSelector.getCurrentUser(state);
    if (!currentUser) {
      return undefined;
    }
    return selector(currentUser, state);
  };
}
