import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { DueDatePicker } from '.';

export const DueDatePickerModule = angular
  .module(
    'dynamicDueDates',
    [], // dependencies
  )
  .component(
    'psDueDatePicker',
    reactToAngular(DueDatePicker, [
      'selectedTaskTemplate',
      'selectedTaskTemplates',
      'templateRevision',
      'editable',
      'disabled',
    ]),
  ).name;
