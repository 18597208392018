import { canAccess, Feature } from 'services/features/features';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { usePlan } from 'services/use-plan-track';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { isGuest } from '@process-street/subgrade/core';

export const useGlobalSearchIsEnabled = (): boolean => {
  const userInfo = useGetCurrentUserInfoQuery();
  const user = userInfo.data?.user;
  const organizationMembership = userInfo.data?.organizationMembership;
  const userIsGuest = organizationMembership && isGuest(organizationMembership);
  const plan = usePlan();

  const guestCanRunChecklist = canAccess(Feature.GUESTS_CAN_CREATE_CHECKLISTS, plan?.id);

  return Boolean(user) && (!userIsGuest || guestCanRunChecklist) && !isAnonymousUser(user!);
};
