import { AxiosResourceFactory } from 'app/resources/factory/axios-resource-factory';

export const Groups = AxiosResourceFactory.create({
  query: 'GET[] /1/groups',
  createGroup: 'POST /1/groups',
  updateGroup: 'PATCH /1/groups/:id',
  updateGroupAvatar: 'POST /1/groups/:id/avatar-file',
  deleteGroup: 'DELETE /1/groups/:id',
  addUserToGroup: 'PUT /1/groups/:id/users/:email',
  removeUserFromGroup: 'DELETE /1/groups/:id/users/:email',
  getGroupStatsByOrganizationId: 'GET[] /1/organizations/:organizationId/group-stats',
});
