import angular from 'angular';
import { MergeTagsConstants } from '@process-street/subgrade/form/merge-tags-constants';
import templateUrl from './merge-tag-selector.component.html';
import './merge-tag-selector.scss';
import { queryClient } from 'components/react-root';
import { MergeTagsByTemplateRevisionIdQuery } from 'features/merge-tags/query-builder';
import { QueryObserver } from 'react-query';

angular
  .module('frontStreetApp.directives')
  .controller('MergeTagSelectorBoxCtrl', function ($scope, focusById) {
    $scope.$on('init', (__event, values) => {
      $scope.templateRevision = values.templateRevision;
      $scope.target = values.target;
      $scope.fieldId = values.fieldId;
      subscribeToMergeTagsQuery();
    });

    function subscribeToMergeTagsQuery() {
      const {
        target: mergeTagTarget,
        templateRevision: { id: templateRevisionId },
      } = $scope;

      const observer = new QueryObserver(queryClient, {
        queryKey: MergeTagsByTemplateRevisionIdQuery.getKey({
          templateRevisionId,
          mergeTagTarget,
          includeLegacyTags: false,
        }),
        queryFn: MergeTagsByTemplateRevisionIdQuery.queryFn(queryClient),
        staleTime: Infinity,
        refetchOnReconnect: 'always',
        refetchOnWindowFocus: 'always',
        refetchOnMount: 'always',
      });

      $scope.mergeTagsQueryUnSubscribe = observer.subscribe(result => {
        $scope.tags = [];
        angular.forEach(result.data.tags, (label, key) => {
          $scope.tags.push({
            key,
            label,
          });
        });
      });
    }

    $scope.$on('$destroy', () => {
      $scope.mergeTagsQueryUnSubscribe();
    });

    $scope.$on('show', () => {
      focusById('query');
    });

    $scope.selectTag = function (key) {
      $scope.query = '';
      $scope.$emit('select', key, $scope.fieldId);
    };

    $scope.getTagLabel = function (tag) {
      return tag.label || tag.key;
    };
  })
  .component('psMergeTagSelector', {
    transclude: true,
    bindings: {
      title: '<',
      target: '<',
      templateRevision: '<',
      fieldId: '<',
      disabled: '<',
      onSelect: '&',
    },
    templateUrl,
    controller($scope) {
      const ctrl = this;

      // This is used to control the box visibility
      ctrl.box = { visible: false };

      // This component communicates with the controller in the pop box via events in the scope

      ctrl.init = function () {
        $scope.$broadcast('init', {
          templateRevision: ctrl.templateRevision,
          target: ctrl.target,
          fieldId: ctrl.fieldId,
        });

        $scope.unsubscribeFromWatchWidgetChanges = MergeTagsByTemplateRevisionIdQuery.watchWidgetChanges({
          queryClient,
          $scope,
          queryParams: {
            templateRevisionId: ctrl.templateRevision.id,
            mergeTagTarget: ctrl.target,
            includeLegacyTags: false,
          },
        });
      };

      $scope.$on('$destroy', () => {
        $scope.unsubscribeFromWatchWidgetChanges();
      });

      ctrl.$onChanges = function (changes) {
        if (changes.templateRevision) {
          ctrl.templateRevision = changes.templateRevision.currentValue;
        }

        if (changes.fieldId) {
          ctrl.fieldId = changes.fieldId.currentValue;
        }

        if (changes.title) {
          ctrl.title = changes.title.currentValue || 'Select a variable';
        }
        if (changes.target) {
          ctrl.target = changes.target.currentValue || MergeTagsConstants.Target.GENERAL;
        }

        $scope.$broadcast('changes', changes);
      };

      $scope.$on('select', (event, key, fieldId) => {
        // Don't let the event escape the component
        event.stopPropagation();

        ctrl.box.visible = false;
        ctrl.onSelect({ key, fieldId });
      });

      ctrl.show = function () {
        ctrl.box.visible = true;
        $scope.$broadcast('show');
      };

      ctrl.hide = function () {
        ctrl.box.visible = false;
      };
    },
  });
