import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import {
  GetUserDetails,
  useGetUserDetailsQuery,
  useUpsertUserDetailsMutation,
} from 'features/user/query-builder/user-details';
import { Shell } from '../components/shell';
import { getSignUpErrorMessage } from '../helpers';
import { FormValues, ReferralForm } from './form';
import { match, P } from 'ts-pattern';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useGetOrganizationDetails } from 'features/organization/query-builder';
import { ShellLeftPane } from '../components/shell-left-pane';
import { ShellContent } from '../components/shell-content';
import { useFeatureFlag } from 'features/feature-flags';
import { useRedirectToAppEntryPoint } from 'pages/sign-up/use-redirect-to-app-entry-point';

export const Referral = () => {
  const { $state } = useInjector('$state');
  const sessionUserId = useSelector(SessionSelector.getCurrentUserId);
  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const queryClient = useQueryClient();

  const { redirectToAppEntryPoint } = useRedirectToAppEntryPoint();

  const { data: organizationDetails } = useGetOrganizationDetails(
    { organizationId: selectedOrganizationId },
    {
      enabled: Boolean(selectedOrganizationId),
    },
  );

  const userDetailsQuery = useGetUserDetailsQuery(sessionUserId ?? '', { enabled: Boolean(sessionUserId) });
  const consultationSuggestionThreshold = useFeatureFlag(
    'signupSegmenentationConsultationThreshold',
  ) as unknown as number;

  const upsertUserDetailsMutation = useUpsertUserDetailsMutation({
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries(GetUserDetails.getKey(variables.userId));

      const isConsultationOffered = checkIfConsultationShouldBeOffered(
        organizationDetails?.employeeCount,
        consultationSuggestionThreshold,
      );

      if (isConsultationOffered) {
        $state.go('newCompleteSignUp.consultationPrompt');
      } else {
        redirectToAppEntryPoint();
      }
    },
  });

  const errorMessage = getSignUpErrorMessage(upsertUserDetailsMutation.error);

  const handleSubmit = async (values: FormValues) => {
    if (!sessionUserId) return;

    upsertUserDetailsMutation.mutate({
      userId: sessionUserId,
      referral: values.referral,
      useCase: values.useCase,
    });
  };

  return (
    <Shell>
      <ShellLeftPane />

      <ShellContent>
        <ReferralForm
          onSubmit={handleSubmit}
          isLoading={upsertUserDetailsMutation.isLoading || upsertUserDetailsMutation.isSuccess}
          error={errorMessage}
          initialValues={{ useCase: userDetailsQuery.data?.useCase, referral: userDetailsQuery.data?.referral }}
        />
      </ShellContent>
    </Shell>
  );
};

const MIN_EMPLOYEE_COUNT_TO_SUGGEST_CONSULTATION = 30;

const checkIfConsultationShouldBeOffered = (
  employeeCount: string = '',
  threshold = MIN_EMPLOYEE_COUNT_TO_SUGGEST_CONSULTATION,
) => {
  const countParts = employeeCount.split('-');
  const partsAsNumber = countParts.map(value =>
    // Remove all non numeric characters.
    // Ex.: 1000+ -> 1000
    parseInt(value.replace(/[^0-9]/g, '')),
  );

  const highestNumber = Math.max(...partsAsNumber);

  const employees = match(highestNumber)
    .with(P.number, value => value)
    .otherwise(() => 0);

  return employees >= threshold;
};
