enum PasswordValidationErrorCode {
  TOO_SHORT = 'TOO_SHORT',
  INSUFFICIENT_UPPERCASE = 'INSUFFICIENT_UPPERCASE',
  INSUFFICIENT_LOWERCASE = 'INSUFFICIENT_LOWERCASE',
  INSUFFICIENT_SPECIAL = 'INSUFFICIENT_SPECIAL',
  INSUFFICIENT_DIGIT = 'INSUFFICIENT_DIGIT',
  ILLEGAL_WHITESPACE = 'ILLEGAL_WHITESPACE',
}

const PasswordValidationErrorCodeToMessage = {
  [PasswordValidationErrorCode.TOO_SHORT]: 'be 8 or more characters',
  [PasswordValidationErrorCode.INSUFFICIENT_UPPERCASE]: 'contain 1 uppercase letter',
  [PasswordValidationErrorCode.INSUFFICIENT_LOWERCASE]: 'contain 1 lowercase letter',
  [PasswordValidationErrorCode.INSUFFICIENT_DIGIT]: 'contain 1 digit character',
  [PasswordValidationErrorCode.INSUFFICIENT_SPECIAL]: 'contain 1 special character',
  [PasswordValidationErrorCode.ILLEGAL_WHITESPACE]: 'not contain whitespace',
};

export const AuthConstants = {
  PasswordValidationErrorCode,
  PasswordValidationErrorCodeToMessage,
};
