import { CoverIcon } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetAllCoverIconResponse = CoverIcon[];

export const GetAllCoverIconsQuery = {
  key: ['cover-icons'],
  getKey: (): QueryKey => [...GetAllCoverIconsQuery.key],
  queryFn: () =>
    axiosService
      .getAxios()
      .get<GetAllCoverIconResponse>(`/1/cover-icons`)
      .then(res => res.data),
};

export const useGetAllCoverIconsQuery = <Select = GetAllCoverIconResponse>(
  options: UseQueryOptions<GetAllCoverIconResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetAllCoverIconsQuery.getKey(), () => GetAllCoverIconsQuery.queryFn(), {
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    ...options,
  });
};
