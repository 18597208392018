import * as React from 'react';
import { Icon, IconButton, Tooltip } from 'app/components/design/next';
import { TinyMCEEditor } from 'app/features/rich-text';
import { CustomIconName } from 'app/components/design/next/icon/icon-name';

export type FormatButtonProps = {
  editor?: TinyMCEEditor;
  command: string;
  icon: CustomIconName;
  label: string;
  isActive: boolean;
  isDisabled?: boolean;
};

export const getFormatButtonStyles = (params: { isActive?: boolean } = {}) => {
  return {
    variant: 'ghost',
    size: 'sm',
    color: 'white',
    bgColor: params?.isActive ? 'gray.600' : 'transparent',
    borderRadius: '0',
    _active: { bgColor: 'gray.600' },
    _focus: { bgColor: 'gray.600' },
    _hover: { bgColor: 'gray.600' },
  };
};

export const FormatButton = ({ editor, command, icon, label, isActive, isDisabled }: FormatButtonProps) => {
  return (
    <Tooltip label={label}>
      <IconButton
        {...getFormatButtonStyles({ isActive })}
        aria-label={label}
        colorScheme="gray"
        onClick={() => editor?.execCommand(command)}
        icon={<Icon icon={icon} size="3.5" />}
        isDisabled={isDisabled}
      />
    </Tooltip>
  );
};
