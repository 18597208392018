import React from 'react';
import classNames from 'classnames';
import styles from './TemplateGalleryCategoriesItem.module.scss';
import { Category } from '@process-street/subgrade/process/premade-template-model';

export interface TemplateGalleryCategoriesListItemProps {
  category: Category;
  onClick: (category: Category) => void;
  selected: boolean;
}

export const TemplateGalleryCategoriesItem: React.FunctionComponent<
  React.PropsWithChildren<TemplateGalleryCategoriesListItemProps>
> = ({ category, onClick, selected }) => {
  const itemClass = classNames({
    'template-gallery-categories-item': true,
    [styles.templateGalleryCategoriesItem]: true,
    [styles.selected]: selected,
  });

  const handleClick = () => {
    onClick(category);
  };

  return (
    <div className={itemClass} onClick={handleClick} role={'menuitem'}>
      <span>{category.name}</span>
    </div>
  );
};
