import { useBoolean } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import * as React from 'react';
import { Shell } from '../components/shell';
import { ShellContent } from '../components/shell-content';
import { ShellLeftPane } from '../components/shell-left-pane';
import { CreateAccountForm, FormValues } from './form';

export const SignUpCreateAccount: React.VFC = () => {
  const { SignUpService, $state } = useInjector('SignUpService', '$state');
  const [isLoading, setLoading] = useBoolean();

  const handleSubmit = async (values: FormValues) => {
    const { email } = $state.params;

    setLoading.on();

    SignUpService.completeAndLogin({ ...values, email }).catch(() => {
      setLoading.off();
    });
  };

  return (
    <Shell>
      <ShellLeftPane />
      <ShellContent>
        <CreateAccountForm isLoading={isLoading} onSubmit={handleSubmit} />
      </ShellContent>
    </Shell>
  );
};
