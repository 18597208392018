import * as React from 'react';
import { Button, Icon, Text, Tooltip, Flex } from 'components/design/next';
import { useUIActorRef } from 'pages/forms/_id/shared';
import { useGetCanShareLinks } from 'utils/plans/get-can-share-links';

export const SendLinkButton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { uiActorRef } = useUIActorRef();
  const canShareLinks = useGetCanShareLinks();

  return (
    <Tooltip
      label={
        <Text>
          Share links are enabled after a 7-day period on any paid plan. For expedited access, please contact our
          support&nbsp;team.
        </Text>
      }
      hasArrow
      isDisabled={canShareLinks}
      width="full"
    >
      <Flex width="full">
        <Button
          onClick={() => {
            uiActorRef.send({ type: 'OPEN_DISCLOSURE', name: 'shareViaEmail' });
          }}
          leftIcon={<Icon icon="envelope" size="4" color="gray.600" />}
          width="full"
          variant="outline"
          colorScheme="gray"
          color="gray.600"
          fontWeight="normal"
          isDisabled={!canShareLinks}
        >
          Send link
        </Button>
      </Flex>
    </Tooltip>
  );
};
