import { Template, TemplateRunViaEmailConfig } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { queryClient } from 'components/react-root';
import { QueryClient, useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetRunViaEmailParams = { templateId: Template['id'] };

export type GetRunViaEmailResult = TemplateRunViaEmailConfig;

export const GetRunViaEmail = {
  key: ['templates', 'run-via-email'],
  getKey: ({ templateId }: GetRunViaEmailParams) => [...GetRunViaEmail.key, templateId],
  queryFn: ({ templateId }: GetRunViaEmailParams) =>
    axiosService
      .getAxios()
      .get<GetRunViaEmailResult>(`/1/templates/${templateId}/run-via-email-config`)
      .then(r => r.data),
  updateCache: (
    template: Partial<TemplateRunViaEmailConfig> & { templateId: Template['id'] },
    options: { queryClient: QueryClient } = { queryClient },
  ) =>
    options.queryClient.setQueryData<TemplateRunViaEmailConfig | undefined>(
      GetRunViaEmail.getKey({ templateId: template.templateId }),
      runViaEmailConfig => {
        if (!runViaEmailConfig) return;

        return {
          ...runViaEmailConfig,
          ...template,
        };
      },
    ),
};

export const useGetRunViaEmailQuery = (
  params: GetRunViaEmailParams,
  options?: UseQueryOptions<GetRunViaEmailResult, AxiosError>,
) =>
  useQuery<GetRunViaEmailResult, AxiosError>(
    GetRunViaEmail.getKey(params),
    () => GetRunViaEmail.queryFn(params),
    options,
  );
