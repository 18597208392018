import * as React from 'react';
import { useSavedViewPermits } from 'pages/reports/data-sets/components/share/use-saved-view-permits';
import { useDataSetPermits } from 'pages/reports/data-sets/components/share/use-data-set-permits';
import uniqBy from 'lodash/uniqBy';
import { AvatarGroup } from 'components/design/next';
import { ChakraAvatar } from 'components/design/next/chakra-avatar';

export type DataSetAvatarsProps = {
  dataSetId: string;
  savedViewId: string;
};

export const DataSetAvatars: React.FC<DataSetAvatarsProps> = ({ dataSetId, savedViewId }) => {
  const { savedViewPermitsWithOms } = useSavedViewPermits({
    dataSetId,
    savedViewId,
  });

  const { dataSetPermitsWithOms } = useDataSetPermits({
    dataSetId,
  });

  const oms = uniqBy(
    savedViewPermitsWithOms
      .map(s => s.organizationMembership)
      .concat(dataSetPermitsWithOms.map(s => s.organizationMembership)),
    'id',
  );

  return (
    <AvatarGroup aria-label="item assignees" size="md" max={3} spacing="-4" mr={2}>
      {oms.map(om => (
        <ChakraAvatar key={om.id} user={om.user} />
      ))}
    </AvatarGroup>
  );
};
