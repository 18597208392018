import angular from 'angular';
import { StateDeclaration, Transition, TransitionService } from '@uirouter/angularjs';
import { Trace, trace } from 'components/trace';
import { LocalStorageService } from 'features/storage/local-storage-service';
import { axiosService } from 'services/axios-service';
import { AxiosResponse } from 'axios';
import { env } from 'components/common/env';

const VersionCheckDelay = 10000;
const VersionFile = env.APP_URL + '/version2.txt';

// Don't trigger reload if the "to" state is one of these
const EXCLUDED_TO_STATE_NAMES = ['switchOrganization'];

export interface VersionCheckService {
  checkVersion(fromState: StateDeclaration, toState: StateDeclaration): void;
  readServerVersion(): angular.IPromise<string>;
}

export class VersionCheckServiceImpl implements VersionCheckService {
  public static $inject = ['$timeout', '$window'];

  private versionCheckInProgress = false;
  private readonly logger: Trace;

  constructor(private readonly $timeout: angular.ITimeoutService, private readonly $window: angular.IWindowService) {
    this.logger = trace({ name: 'VersionCheckService' });
  }

  public checkVersion(fromState: StateDeclaration, toState: StateDeclaration) {
    if (this.versionCheckInProgress) {
      return;
    }

    if (this.isExcludedToState(toState) || !this.isMajorStateChange(fromState, toState)) {
      return;
    }

    this.versionCheckInProgress = true;
    void this.$timeout(() => {
      this.versionCheckInProgress = false;
    }, VersionCheckDelay);

    this.readServerVersion().then(
      serverVersion => {
        const version = LocalStorageService.getItem('version');

        if (!version) {
          this.logger.info('version is not set, setting to "%s" version', serverVersion);
          LocalStorageService.setItem('version', serverVersion);
        } else if (serverVersion !== version) {
          this.logger.info('version changed from "%s" to "%s"', version, serverVersion);
          LocalStorageService.setItem('version', serverVersion);
          this.$window.location.reload();
        }
      },
      () => {
        this.logger.error('failed to check version');
      },
    );
  }

  public async readServerVersion() {
    return axiosService
      .getAxios()
      .get<string>(VersionFile, { responseType: 'text', transformResponse: [data => data] })
      .then((response: AxiosResponse<string>) => response.data.trim());
  }

  public isMajorStateChange(fromState: StateDeclaration, toState: StateDeclaration) {
    return fromState.name?.split('.')[0] !== toState.name?.split('.')[0];
  }

  public isExcludedToState(toState: StateDeclaration) {
    const majorState = toState.name?.split('.')[0];
    return majorState && EXCLUDED_TO_STATE_NAMES.includes(majorState);
  }
}

angular
  .module('frontStreetApp.services')
  .service('versionCheckService', VersionCheckServiceImpl)
  .run(($transitions: TransitionService, versionCheckService: VersionCheckService) => {
    $transitions.onStart({}, (transition: Transition) => {
      versionCheckService.checkVersion(transition.from(), transition.to());
    });
  });
