import * as React from 'react';
import { RichTextEditor, TinyMCEEditor } from 'features/rich-text';
import { VStack } from 'components/design/next';
import { TextContentActor } from './text-content-machine';
import { useActor } from '@xstate/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { TextWidgetToolbar } from './components/toolbar';
import { FormatBlockType } from 'app/directives/text-widget-toolbar/toolbar-blocks';
import { useMatch } from '@process-street/adapters/navigation';
import { unescapeAll } from 'markdown-it/lib/common/utils';
import { useDebouncedCallback } from 'use-debounce';
import { isPage, TaskTemplateTaskType, TemplateType } from '@process-street/subgrade/process';
import { useTextContentKeyboardNavigation } from './use-text-content-keyboard-navigation';

export interface TextContentProps {
  actor: TextContentActor;
  isFirst?: boolean;
  isLast?: boolean;
  placeholder?: string;
}

const DEBOUNCE_MS = 300;

/**
 * Normalize a string by replacing non-breaking spaces with regular spaces
 * and esuring that anchor tags with `rel="nofollow"` are matched when TinyMCE replaces them
 * adding `noopener` for security purposes.
 * @param str
 * @returns
 */
const normalize = (str: string) =>
  str.replace(/\u00A0/g, ' ').replace(/(<a\s[^>]*?)rel="nofollow"(.*?>)/g, '$1rel="nofollow noopener"$2');

const normalizeForComparison = (content: string) => {
  const normalizedContent = normalize(unescapeAll(content ?? ''));
  return normalizedContent.replace(/\s+<\/p>/g, '</p>').trim();
};

export const TextContent: React.FC<React.PropsWithChildren<TextContentProps>> = ({
  actor,
  isFirst = false,
  isLast = false,
  placeholder = 'Write something',
}) => {
  const [state, send] = useActor(actor);
  const { widget, template, recentlyMovedFrom, isReadOnly, editor } = state.context;
  const isEditorV2 = Boolean(useMatch('templateV2'));
  const isPageEditorV2 = Boolean(useMatch('pageV2'));
  const ref = React.useRef<HTMLDivElement | null>(null);
  const shouldShowNewEditor = isEditorV2 || isPageEditorV2;
  const isTemplatePage = isPage(template);
  const toolbarPopoverContentRef = React.useRef<HTMLDivElement | null>(null);

  const initialValue = React.useRef(state.context.widget.content).current;
  const [isSelecting, setIsSelecting] = React.useState(false);

  const debouncedSyncWidgetContentInEditor = useDebouncedCallback((editor: TinyMCEEditor, widgetContent: string) => {
    // sometimes (e.g. Firefox) editor content uses non-breaking spaces for trailing spaces
    const normalizedEditorContent = normalizeForComparison(editor.getContent());
    // widget.content is sometimes HTML escaped (e.g. leading spaces)
    const normalizedWidgetContent = normalizeForComparison(widget.content ?? '');

    if (normalizedEditorContent !== normalizedWidgetContent) {
      // this resets the cursor in TinyMCE so we should only do when really needed
      editor.setContent(widgetContent);
    }
  }, DEBOUNCE_MS);

  React.useEffect(
    function syncWidgetContentInEditor() {
      if (!widget.content || !editor) return;
      // Debouncing the sync to avoid regex computation on every keystroke.
      debouncedSyncWidgetContentInEditor(editor, widget.content);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- debouncedSyncWidgetContentInEditor
    [widget.content, editor],
  );

  const { onKeyDown, onFocus } = useTextContentKeyboardNavigation(editor, actor);

  const shouldShowWidgetActions = !isReadOnly && widget.header.taskTemplate.taskType !== TaskTemplateTaskType.Approval;

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer
        sx={{
          'minHeight': '37px',
          '.style-success': {
            color: 'gray.600',
            bgColor: 'green.200',
            borderWidth: 'thin',
            borderStyle: 'solid',
            borderColor: 'green.300',
            borderRadius: '4px',
            marginTop: '1',
            p: 4,
            w: 'full',
          },
          '.style-info': {
            color: 'gray.600',
            bgColor: 'blue.200',
            borderWidth: 'thin',
            borderStyle: 'solid',
            borderColor: 'blue.300',
            borderRadius: '4px',
            marginTop: '1',
            p: 4,
            w: 'full',
          },
          '.style-warning': {
            color: 'gray.600',
            bgColor: 'yellow.200',
            borderWidth: 'thin',
            borderStyle: 'solid',
            borderColor: 'yellow.300',
            borderRadius: '4px',
            marginTop: '1',
            p: 4,
            w: 'full',
          },
          '.style-danger': {
            color: 'gray.600',
            bgColor: 'red.200',
            borderWidth: 'thin',
            borderStyle: 'solid',
            borderColor: 'red.300',
            borderRadius: '4px',
            marginTop: '1',
            p: 4,
            w: 'full',
          },
          '.style-blockquote': {
            color: 'gray.600',
            borderLeftStyle: 'solid',
            borderLeftWidth: '3px',
            borderLeftColor: 'gray.200',
            p: 4,
          },
        }}
        alignItems="flex-start"
        fontSize="md"
      >
        <VStack
          alignItems="normal"
          ref={(node: HTMLDivElement) => {
            ref.current = node;
            if (node && !state.context.inputNode) {
              send({ type: 'SET_NODE', node });
            }
          }}
          scrollMarginBottom={17}
          w="full"
        >
          {!isReadOnly && shouldShowNewEditor && (
            <TextWidgetToolbar
              editor={state.context.editor}
              widget={widget}
              popoverContentRef={toolbarPopoverContentRef}
            />
          )}

          {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}

          <RichTextEditor
            source={
              template.templateType === TemplateType.Playbook
                ? 'TextContentWidgetWorkflowEditor'
                : 'TextContentWidgetPageEditor'
            }
            templateType={template.templateType}
            editorProps={{
              initialValue,
              onSelectionChange: (_evt, editor) => {
                const selectionContent = editor.selection.getContent();
                setIsSelecting(!(selectionContent.trim() === ''));
              },
              onInit: (_evt, editor) => {
                send({ type: 'SET_EDITOR', editor });

                editor.formatter.register('bq', {
                  block: 'p',
                  // Use attributes instead so that it replaces the old classes instead of merging them
                  attributes: {
                    class: FormatBlockType.Blockquote,
                  },
                });

                editor.formatter.register('success', {
                  block: 'p',
                  attributes: {
                    class: FormatBlockType.Success,
                  },
                });
                editor.formatter.register('info', {
                  block: 'p',
                  attributes: {
                    class: FormatBlockType.Info,
                  },
                });
                editor.formatter.register('reminder', {
                  block: 'p',
                  attributes: {
                    class: FormatBlockType.Warning,
                  },
                });
                editor.formatter.register('critical', {
                  block: 'p',
                  attributes: {
                    class: FormatBlockType.Danger,
                  },
                });
              },
              disabled: isReadOnly,
              onEditorChange: content => send({ type: 'CHANGE', content }),
              onBlur: (_event, editor) => {
                send({ type: 'BLUR' });
                const focusedElement = document.activeElement as HTMLElement | null;
                if (focusedElement && toolbarPopoverContentRef.current?.contains(focusedElement)) {
                  return;
                }
                editor.selection.collapse();
                setIsSelecting(false);
              },
              onFocus: (_, editor) => {
                send({ type: 'FOCUS' });
                onFocus(editor);
              },
              onKeyDown,
            }}
            editorWrapperProps={{
              'aria-label': 'text content editor container',
              'flex': '1',
              'userSelect': isReadOnly ? 'text' : undefined,
              'sx': {
                '.mce-content-body': {
                  color: 'gray.700',
                  border: 'none',
                  outline: 'none',
                  mx: -1,
                  px: 1,
                },
                '.mce-content-body p': {
                  lineHeight: 1.5,
                  my: 0,
                  py: 2,
                },
                '.mce-content-body .style-blockquote': {
                  my: 3,
                  color: 'gray.700',
                },
                '.mce-content-body .style-success': {
                  px: 4,
                  py: 3,
                  color: 'gray.700',
                  my: 3,
                },
                '.mce-content-body .style-info': {
                  px: 4,
                  py: 3,
                  color: 'gray.700',
                  my: 3,
                },
                '.mce-content-body .style-warning': {
                  px: 4,
                  py: 3,
                  color: 'gray.700',
                  my: 3,
                },
                '.mce-content-body .style-danger': {
                  px: 4,
                  py: 3,
                  color: 'gray.700',
                  my: 3,
                },
                '.mce-content-body li': {
                  py: 1,
                },
                '.mce-content-body h1': {
                  fontSize: '30px',
                  mb: 2,
                  mt: 3,
                },
                '.mce-content-body h2': {
                  fontSize: '24px',
                  my: 2,
                },
                '.mce-content-body h3': {
                  fontSize: '20px',
                  my: 2,
                },
                '.mce-content-body ol, .mce-content-body ul': {
                  pl: 9,
                },
                // Being verbose to avoid kebab-casae
                '.mce-content-body ol > li > ol': {
                  listStyleType: 'lower-alpha',
                },
                '.mce-content-body ol > li > ol > li > ol': {
                  listStyleType: 'lower-roman',
                },
                '.mce-content-body ul': {
                  listStyleType: 'disc',
                },
                '.mce-content-body ul > li > ul': {
                  listStyleType: 'circle',
                },
                '.mce-content-body ul > li > ul > li > ul': {
                  listStyleType: 'square',
                },
                '.mce-content-body pre': {
                  bgColor: 'gray.100',
                  p: 4,
                },
                '.mce-content-body code': {
                  bgColor: 'red.100',
                  color: 'red.700',
                  p: 1,
                },
                '.mce-content-body hr': {
                  my: 5,
                },
                '.mce-content-body [data-slate-depth="2"]': {
                  ml: 9,
                  listStyleType: 'circle',
                },
                '.mce-content-body [data-slate-depth="3"]': {
                  ml: 18,
                  listStyleType: 'square',
                },
                '.mce-content-body [data-slate-depth="4"]': {
                  ml: 27,
                  listStyleType: 'square',
                },
              },
            }}
            init={{ placeholder, ...(shouldShowNewEditor ? { toolbar: false } : {}) }}
          />
        </VStack>

        {shouldShowWidgetActions && (
          <>
            <WidgetListItemDragIcon isSelecting={isSelecting} />
            <FormsWidgetMenu>
              <FormsWidgetMenuItems.Duplicate />
              {!isTemplatePage && <FormsWidgetMenuItems.MoveToStep widget={widget} />}
              <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
              <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
              <FormsWidgetMenuItems.Delete />
            </FormsWidgetMenu>
          </>
        )}
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
