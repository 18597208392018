import * as React from 'react';
import { HStack, Icon, Image, Spacer, Text } from 'components/design/next';
import { Checklist, ChecklistUtils } from '@process-street/subgrade/process';
import { IconButton, Link, Show, VStack } from '@chakra-ui/react';
import { useAudit } from 'app/components/widgets/form-field/common/FormFieldAudit/use-audit';
import { useGetAllConsolidatedChecklistPermissionsQuery } from 'app/features/permissions/query-builder';
import { head } from 'lodash';
import { useLocation } from 'react-use';

export const StatusBar = ({ checklist }: { checklist: Checklist }) => {
  if (!(ChecklistUtils.isArchived(checklist) || ChecklistUtils.isCompleted(checklist))) return null;
  const isArchived = ChecklistUtils.isArchived(checklist);

  const { formattedPrintDate, formattedDateWithTimezone, isLoading, isPrintMode, username } = useAudit({
    audit: checklist.audit,
  });
  const auditDate = isPrintMode ? formattedDateWithTimezone : formattedPrintDate;

  const printPath = `/runs/${checklist.id}/print-wfr`;
  const permissionsQuery = useGetAllConsolidatedChecklistPermissionsQuery({ checklistIds: [checklist.id] });
  const canPrint = head(permissionsQuery.data)?.permissionMap.checklistExport ?? false;

  const location = useLocation();
  const isMicrosoftTeams = location.pathname?.startsWith('/microsoft-teams') ?? true;

  if (isLoading) return null;

  return (
    <HStack justifyContent="space-between" w="full" py={2} h={16} bgColor={isArchived ? '#f0ad4e' : '#1bc98e'}>
      <Spacer />
      <HStack spacing={2}>
        <Show above="sm">
          <Image
            alignSelf="end"
            src={isArchived ? '../../images/statusbar/archived-left.svg' : '../../images/statusbar/completed-left.svg'}
          />
        </Show>
        <VStack color="white" spacing="0">
          <Text as="h3" fontSize={{ base: '18px', sm: '22px' }} lineHeight="1.2">
            Workflow run {isArchived ? 'archived' : 'completed'}
          </Text>
          <Text variant={{ base: '1', sm: '2' }}>
            by {username} {auditDate}
          </Text>
        </VStack>
        <Show above="sm">
          <Image
            alignSelf="end"
            src={
              isArchived ? '../../images/statusbar/archived-right.svg' : '../../images/statusbar/completed-right.svg'
            }
          />
        </Show>
      </HStack>
      <Spacer />
      {canPrint && !isMicrosoftTeams && (
        <IconButton
          position="absolute"
          right={8}
          icon={<Icon variant="far" icon="print" size="4" />}
          aria-label="print workflow run"
          background="transparent"
          color="white"
          backgroundColor="rgba(0,0,0,0.15)"
          _hover={{ backgroundColor: 'rgba(0,0,0,0.25)', color: 'white' }}
          _focus={{ backgroundColor: 'rgba(0,0,0,0.3)', color: 'white' }}
          _active={{ backgroundColor: 'rgba(0,0,0,0.3)', color: 'white' }}
          as={Link}
          href={printPath}
          target="_blank"
        />
      )}
    </HStack>
  );
};
