import * as React from 'react';
import { DateFormat } from '@process-street/subgrade/util';
import { Button, ButtonProps, Icon, IconButton, Text } from 'components/design/next';
import dayjs from 'dayjs';
import { DatePicker } from 'features/conditional-logic/components/date-picker';

export type DueDatePickerProps = {
  value?: number;
  timeZone?: string;
  onChange?: (date: number | null) => void;
  buttonProps?: ButtonProps;
};

export const DueDatePicker: React.FC<React.PropsWithChildren<DueDatePickerProps>> = ({
  value,
  timeZone,
  onChange,
  buttonProps,
}) => {
  const renderTrigger = (value: number | undefined | null, timeZone: string) => {
    if (!value) {
      return (
        <IconButton
          justifyContent="center"
          aria-label="Due date picker"
          size="sm"
          variant="outline"
          bgColor="white"
          borderWidth="px"
          borderColor="gray.300"
          color="gray.500"
          icon={<Icon icon="clock" size="4" variant="far" />}
        />
      );
    }
    const formattedDate = dayjs(value).tz(timeZone).format(DateFormat.DateTimeMergeTagLong);

    return (
      <Button
        aria-label="Due date picker"
        size="sm"
        variant="outline"
        bgColor="white"
        borderWidth="px"
        borderColor="gray.300"
        color="gray.500"
        fontSize="md"
        {...buttonProps}
      >
        <Icon icon="clock" size="4" variant="far" color="purple.500" mr="2" /> Due {formattedDate}
      </Button>
    );
  };

  return (
    <DatePicker
      title={
        <Text fontWeight="bold" px={2} alignSelf="flex-start">
          Due date
        </Text>
      }
      onChange={onChange}
      value={value}
      timeZone={timeZone}
      renderTrigger={renderTrigger}
      pickerProps={{
        disabled: [
          {
            before: dayjs().toDate(),
          },
        ],
        styles: {
          cell: {
            fontSize: 'var(--ps-fontSizes-sm)',
          },
        },
      }}
      showWeekdayToggle={true}
      size="md"
    />
  );
};
