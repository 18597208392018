import angular from 'angular';

angular.module('frontStreetApp.services').factory('Templates', function (ResourceFactory) {
  return ResourceFactory.create({
    getAllByOrganizationIdAndStatus: 'GET[] /1/organizations/:organizationId/templates',
    counts: 'GET /1/templates/counts',
    create: 'POST /1/templates',
    getById: 'GET /1/templates/:id',
    getPublic: 'GET /1/templates/:id/public',
    update: 'PUT /1/templates/:id',
    updatePublic: 'PUT /1/templates/:id/public',
    updateStatus: 'PUT /1/templates/:id/status/:status',
    updateShareableRunLink: 'PUT /1/templates/:id/shareableRunLink',
    updateShareLevelById: 'PUT /1/templates/:id/share-level/:level',
    copy: 'PUT /1/templates/:id/copy',
    move: 'PUT /1/templates/:id/move/:folderId',
    deleteById: 'DELETE /1/templates/:id',
    undelete: 'PUT /1/templates/:id/undelete',
  });
});
