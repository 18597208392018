import { NotificationConfig } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetNotificationConfigQuery {
  export type Params = {};

  export type Response = NotificationConfig;

  export const key = ['user', 'notification', 'config'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (_params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/users/me/notification-config`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: options.enabled !== false,
    });
  };
}
