import {
  FieldType,
  NativeAutomationWithLink,
  TaskTemplate,
  TemplateRevision,
  Widget,
  WidgetType,
} from '@process-street/subgrade/process';
import { htmlUnescaped, StringUtils } from '@process-street/subgrade/util';
import { IRootScopeService } from 'angular';
import { TemplateRevisionQuery, UpdateTemplateRevisionMutation } from 'features/template-revisions/query-builder';
import { QueryClient } from 'react-query';
import { EventName } from 'services/event-name';
import { match, P } from 'ts-pattern';
import { useTemplateWidgetsStore } from './use-template-widgets-store';
import {
  GetAllNativeAutomationsQuery,
  UpdateNativeAutomationActionsMutation,
} from 'features/native-automations/query-builder';
import { UpdateWidgetMutation } from './query-builder/update-widget';

const makeKeyPattern = (key: string) =>
  new RegExp(
    `{{\\s*form\\.(${StringUtils.escapeRegExp(key)})(\\.\\w+)?(\\s*\\|\\s*\\w+(?::\\s*'[^']*'|:"[^"]*")*)*\\s*}}`,
    'g',
  );

const makeReplaceMergeTags =
  ({ newKey, oldKeyPattern }: { newKey: string; oldKeyPattern: RegExp }) =>
  (value: string) => {
    return value.replace(oldKeyPattern, `{{form.${newKey}$2$3}}`);
  };

function updateWidgets({ widgets, oldKey, newKey }: { widgets: Widget[]; oldKey: string; newKey: string }): Widget[] {
  const oldKeyPattern = makeKeyPattern(oldKey);
  const replaceMergeTags = makeReplaceMergeTags({ newKey, oldKeyPattern });

  const updatedWidgets: Widget[] = [];

  widgets.forEach(widget => {
    match(widget)
      .with({ header: { type: WidgetType.Text } }, w => {
        if (w.content) {
          const escapedContent = htmlUnescaped`${w.content}`;
          if (w.content?.match(oldKeyPattern) || escapedContent.match(oldKeyPattern)) {
            w.content = replaceMergeTags(escapedContent);
            updatedWidgets.push(w);
          }
        }
      })
      .with({ fieldType: FieldType.Snippet }, w => {
        if (w.config.value?.match(oldKeyPattern)) {
          w.config.value = replaceMergeTags(w.config.value);
          updatedWidgets.push(w);
        }
      })
      .with(
        {
          fieldType: P.union(
            FieldType.Text,
            FieldType.Number,
            FieldType.Textarea,
            FieldType.Hidden,
            FieldType.Url,
            FieldType.Email,
            FieldType.Table,
          ),
        },
        w => {
          if (w.config.defaultValue?.match(oldKeyPattern)) {
            w.config.defaultValue = replaceMergeTags(w.config.defaultValue);
            updatedWidgets.push(w);
          }
        },
      )
      .with({ header: { type: WidgetType.Embed } }, w => {
        if (w.url?.match(oldKeyPattern)) {
          w.url = replaceMergeTags(w.url);
          updatedWidgets.push(w);
        }
      })
      .with({ header: { type: WidgetType.Email } }, w => {
        let changed = false;
        const emailProps = ['recipient', 'cc', 'bcc', 'body', 'subject'] as const;
        emailProps.forEach(prop => {
          if (w[prop]?.match(oldKeyPattern)) {
            w[prop] = replaceMergeTags(w[prop]!);
            changed = true;
          }
        });

        if (changed) {
          updatedWidgets.push(w);
        }
      })
      .with({ fieldType: FieldType.SendRichEmail }, w => {
        let changed = false;
        const addressProps = ['to', 'cc', 'bcc'] as const;

        addressProps.forEach(prop => {
          if (w.config[prop]?.some(address => address.match(oldKeyPattern))) {
            w.config[prop] = w.config[prop]?.map(address => replaceMergeTags(address)) as string[];
            changed = true;
          }
        });
        const bodyProps = ['plainTextBody', 'richEditorBody', 'rawHTMLBody', 'subject'] as const;
        bodyProps.forEach(prop => {
          if (w.config[prop]?.match(oldKeyPattern)) {
            w.config[prop] = replaceMergeTags(w.config[prop]!);
            changed = true;
          }
        });
        if (changed) {
          updatedWidgets.push(w);
        }
      })
      .with({ fieldType: FieldType.MultiSelect }, w => {
        let changed = false;

        w.config.items.forEach((item, index) => {
          if (item.name.match(oldKeyPattern)) {
            w.config.items[index].name = replaceMergeTags(item.name);
            changed = true;
          }
        });

        if (changed) updatedWidgets.push(w);
      })
      .otherwise(() => {});
  });

  return updatedWidgets;
}

function updateTemplateRevision({
  templateRevision,
  newKey,
  oldKey,
}: {
  templateRevision: TemplateRevision;
  newKey: string;
  oldKey: string;
}): TemplateRevision | undefined {
  const oldKeyPattern = makeKeyPattern(oldKey);
  const replaceMergeTags = makeReplaceMergeTags({ newKey, oldKeyPattern });
  if (templateRevision.defaultChecklistName?.match(oldKeyPattern)) {
    templateRevision.defaultChecklistName = replaceMergeTags(templateRevision.defaultChecklistName);
    return templateRevision;
  }
}

function updateNativeAutomations({
  nativeAutomations,
  newKey,
  oldKey,
}: {
  nativeAutomations: NativeAutomationWithLink[];
  newKey: string;
  oldKey: string;
}): NativeAutomationWithLink[] {
  const oldKeyPattern = makeKeyPattern(oldKey);
  const replaceMergeTags = makeReplaceMergeTags({ newKey, oldKeyPattern });
  const updatedCustomNotifications: NativeAutomationWithLink[] = [];

  nativeAutomations.forEach(nativeAutomation => {
    let changed = false;
    nativeAutomation.automation.actions.forEach(action => {
      match(action)
        .with({ actionType: 'ExecuteAiPrompt' }, action => {
          const keys = ['prompt', 'systemPrompt', 'formatPrompt'] as const;
          keys.forEach(key => {
            if (action.config[key]?.match(oldKeyPattern)) {
              action.config[key] = replaceMergeTags(action.config[key]!);
              changed = true;
            }
          });
        })
        .with({ actionType: 'SendEmail' }, action => {
          if (action.config.emailTemplateConfig.customMessage?.match(oldKeyPattern)) {
            action.config.emailTemplateConfig.customMessage = replaceMergeTags(
              action.config.emailTemplateConfig.customMessage!,
            );
            changed = true;
          }

          action.config.recipients.forEach(recipient => {
            if (recipient.recipientType === 'MergeTag' && recipient.key.match(oldKeyPattern)) {
              recipient.key = replaceMergeTags(recipient.key!);
              changed = true;
            }
          });
        })
        .otherwise(() => {});
    });
    if (changed) {
      updatedCustomNotifications.push(nativeAutomation);
    }
  });

  return updatedCustomNotifications;
}

interface PersistArgs {
  widgets: Widget[];
  oldKey: string;
  newKey: string;
  templateRevision: TemplateRevision;
  updateWidget: (widget: Widget, data: { taskTemplate: TaskTemplate }) => Promise<Widget>;
  queryClient: QueryClient;
  $rootScope: IRootScopeService;
}

async function persist({
  widgets,
  oldKey,
  newKey,
  templateRevision,
  updateWidget,
  queryClient,
  $rootScope,
}: PersistArgs) {
  if (oldKey === newKey) return Promise.resolve();

  const allTemplateAutomations = await queryClient.fetchQuery({
    queryKey: GetAllNativeAutomationsQuery.getKey({ templateRevisionId: templateRevision.id }),
    queryFn: () => GetAllNativeAutomationsQuery.queryFn({ templateRevisionId: templateRevision.id }),
  });

  const updatedNativeAutomations = updateNativeAutomations({
    nativeAutomations: allTemplateAutomations,
    oldKey,
    newKey,
  });

  const updatedWidgets = updateWidgets({ widgets, oldKey, newKey });

  const updatedTemplateRevision = updateTemplateRevision({ templateRevision, newKey, oldKey });

  const { addUpdatingWidgetGroupId, removeUpdatingWidgetGroupId, clearUpdatingWidgetGroupIds } =
    useTemplateWidgetsStore.getState();

  const widgetPromises = updatedWidgets.map(w => {
    addUpdatingWidgetGroupId(w.header.group.id);
    return updateWidget(w, { taskTemplate: w.header.taskTemplate as TaskTemplate }).then(() => {
      removeUpdatingWidgetGroupId(w.header.group.id);
      $rootScope.$broadcast(EventName.WIDGET_UPDATE_OVERRIDE, w);
    });
  });

  const nativeAutomationsPromises = updatedNativeAutomations.map(({ automation }) => {
    return queryClient.executeMutation({
      variables: { nativeAutomationId: automation.id, actions: automation.actions },
      mutationFn: UpdateNativeAutomationActionsMutation.mutationFn,
      mutationKey: UpdateNativeAutomationActionsMutation.key,
    });
  });

  const templateRevisionPromise = updatedTemplateRevision
    ? queryClient.executeMutation({
        variables: {
          id: templateRevision.id,
          defaultChecklistName: updatedTemplateRevision.defaultChecklistName,
        },
        mutationFn: UpdateTemplateRevisionMutation.mutationFn,
        mutationKey: UpdateTemplateRevisionMutation.key,
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: TemplateRevisionQuery.getKey({ templateRevisionId: templateRevision.id }),
          });
        },
      })
    : Promise.resolve();

  return Promise.all([...widgetPromises, templateRevisionPromise, ...nativeAutomationsPromises]).then(() => {
    clearUpdatingWidgetGroupIds();
    if (nativeAutomationsPromises.length > 0) {
      queryClient.invalidateQueries({
        queryKey: GetAllNativeAutomationsQuery.getKey({ templateRevisionId: templateRevision.id }),
      });
    }
  });
}

interface UpdateReferencesArgs {
  widgets: Widget[];
  oldKey: string;
  newKey: string;
  templateRevision: TemplateRevision;
  queryClient: QueryClient;
  onWidgetUpdated: (widget: Widget) => void;
}

async function updateReferences({
  widgets,
  oldKey,
  newKey,
  templateRevision,
  queryClient,
  onWidgetUpdated,
}: UpdateReferencesArgs) {
  if (oldKey === newKey) return Promise.resolve();

  const allTemplateAutomations = await queryClient.fetchQuery({
    queryKey: GetAllNativeAutomationsQuery.getKey({ templateRevisionId: templateRevision.id }),
    queryFn: () => GetAllNativeAutomationsQuery.queryFn({ templateRevisionId: templateRevision.id }),
  });

  const updatedNativeAutomations = updateNativeAutomations({
    nativeAutomations: allTemplateAutomations,
    oldKey,
    newKey,
  });

  const updatedWidgets = updateWidgets({ widgets, oldKey, newKey });

  const updatedTemplateRevision = updateTemplateRevision({ templateRevision, newKey, oldKey });

  const { addUpdatingWidgetGroupId, removeUpdatingWidgetGroupId, clearUpdatingWidgetGroupIds } =
    useTemplateWidgetsStore.getState();

  const widgetPromises = updatedWidgets.map(w => {
    addUpdatingWidgetGroupId(w.header.group.id);
    return queryClient.executeMutation({
      mutationFn: () => UpdateWidgetMutation.mutationFn<Widget>(w),
      mutationKey: UpdateWidgetMutation.key,
      onSuccess: res => {
        onWidgetUpdated(res);
      },
      onSettled: () => {
        removeUpdatingWidgetGroupId(w.header.group.id);
      },
    });
  });

  const nativeAutomationsPromises = updatedNativeAutomations.map(({ automation }) => {
    return queryClient.executeMutation({
      variables: { nativeAutomationId: automation.id, actions: automation.actions },
      mutationFn: UpdateNativeAutomationActionsMutation.mutationFn,
      mutationKey: UpdateNativeAutomationActionsMutation.key,
    });
  });

  const templateRevisionPromise = updatedTemplateRevision
    ? queryClient.executeMutation({
        variables: {
          id: templateRevision.id,
          defaultChecklistName: updatedTemplateRevision.defaultChecklistName,
        },
        mutationFn: UpdateTemplateRevisionMutation.mutationFn,
        mutationKey: UpdateTemplateRevisionMutation.key,
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: TemplateRevisionQuery.getKey({ templateRevisionId: templateRevision.id }),
          });
        },
      })
    : Promise.resolve();

  return Promise.all([...widgetPromises, templateRevisionPromise, ...nativeAutomationsPromises]).then(() => {
    clearUpdatingWidgetGroupIds();
    if (nativeAutomationsPromises.length > 0) {
      queryClient.invalidateQueries({
        queryKey: GetAllNativeAutomationsQuery.getKey({ templateRevisionId: templateRevision.id }),
      });
    }
  });
}

export const MergeTagReferenceUpdateService = {
  persist,
  updateReferences,
  updateNativeAutomations,
  updateWidgets,
  updateTemplateRevision,
  makeReplaceMergeTags,
  makeKeyPattern,
};
