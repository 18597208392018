import * as React from 'react';
import { Divider, Text, VStack } from 'components/design/next';
import { DomainsField } from '../domains-field';
import { IndustryField } from '../industry-field';
import { NameField } from '../name-field';
import { EnhancedFileSecurityField } from 'app/features/organization/manage/settings/components/enhanced-file-security-field';
import { useFeatureFlags } from 'features/feature-flags';
import { TimeZoneField } from 'features/organization/manage/settings/components/time-zone-field';
import { DateFormatField } from 'features/organization/manage/settings/components/date-format-field';
import { SaveOrganizationParams } from 'features/organization/manage/settings/settings';

type OrganizationProps = {
  onSave: (params: SaveOrganizationParams) => void;
  isLoading: boolean;
};

export const Organization: React.FC<React.PropsWithChildren<OrganizationProps>> = ({ onSave, isLoading }) => {
  const {
    enhancedFileSecurity: enhancedFileSecurityEnabled,
    organizationTimeZone: isOrganizationTimeZoneEnabled,
    organizationDateFormat: isOrganizationDateFormatEnabled,
  } = useFeatureFlags('enhancedFileSecurity', 'organizationTimeZone', 'organizationDateFormat');

  return (
    <VStack alignItems="flex-start" w="full">
      <Text color="gray.500" variant="-2u">
        Organization
      </Text>
      <Divider mt="4" />

      <VStack justifyContent="stretch" mt="8" spacing="6" w="full">
        <NameField onSave={onSave} isLoading={isLoading} />
        <DomainsField />
        <IndustryField onSave={onSave} />
        {isOrganizationTimeZoneEnabled && <TimeZoneField onSave={onSave} />}

        {isOrganizationDateFormatEnabled && <DateFormatField onSave={onSave} />}

        {enhancedFileSecurityEnabled && <EnhancedFileSecurityField />}
      </VStack>
    </VStack>
  );
};
