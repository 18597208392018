import * as React from 'react';
import { chakra } from 'components/design/next';
import { motion, MotionProps } from 'framer-motion';

export const ConditionalLogicEmptyStateIllustration = () => {
  return (
    <chakra.svg xmlns="http://www.w3.org/2000/svg" width="325" height="186" fill="none" viewBox="0 0 325 186">
      <Backdrop />

      <Box1 />
      <Box2 />
      <Box3 />
      <Box4 />
      <Arrows />
      <IfNo />
      <IfYes />
      <Stars />

      <defs>
        <filter
          id="filter0_i_829_24594"
          width="86.924"
          height="42.342"
          x="113.755"
          y="10.085"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-2" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_829_24594"></feBlend>
        </filter>
        <filter
          id="filter1_i_829_24594"
          width="86.924"
          height="42.342"
          x="113.755"
          y="64.549"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-2" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_829_24594"></feBlend>
        </filter>
        <filter
          id="filter2_i_829_24594"
          width="86.924"
          height="42.342"
          x="67.248"
          y="123.045"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-2" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_829_24594"></feBlend>
        </filter>
        <filter
          id="filter3_i_829_24594"
          width="86.924"
          height="42.342"
          x="162.282"
          y="123.045"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-2" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_829_24594"></feBlend>
        </filter>
        <filter
          id="filter4_i_829_24594"
          width="56.305"
          height="31.6"
          x="267.842"
          y="95.214"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-2" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_829_24594"></feBlend>
        </filter>
        <filter
          id="filter5_i_829_24594"
          width="60.638"
          height="25.821"
          x="-1.5"
          y="30.257"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-2" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_829_24594"></feBlend>
        </filter>
        <radialGradient
          id="paint0_radial_829_24594"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(44.492 -130.27 163.231) scale(29.9706 29.9278)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22DCC8"></stop>
          <stop offset="1" stopColor="#2ED0D2"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial_829_24594"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(15.70994 14.79726 -14.77642 15.68781 123.529 75.013)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C98E3"></stop>
          <stop offset="1" stopColor="#2CBAED"></stop>
        </radialGradient>
        <radialGradient
          id="paint2_radial_829_24594"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(15.70994 14.79726 -14.77642 15.68781 123.529 20.55)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C98E3"></stop>
          <stop offset="1" stopColor="#2CBAED"></stop>
        </radialGradient>
        <radialGradient
          id="paint3_radial_829_24594"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(44.492 -82.752 279.403) scale(29.9706 29.9278)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC5E77"></stop>
          <stop offset="1" stopColor="#F86C6F"></stop>
        </radialGradient>
      </defs>
    </chakra.svg>
  );
};

const defaultAnimationProps: MotionProps = {
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
};

const Box1 = () => (
  <motion.g {...defaultAnimationProps} transition={{ delay: 0.4 }}>
    <g filter="url(#filter0_i_829_24594)">
      <path
        fill="#000"
        d="M197.063 10.085H119.37a3.614 3.614 0 00-3.615 3.612v33.119a3.614 3.614 0 003.615 3.612h77.693a3.613 3.613 0 003.615-3.612V13.697a3.613 3.613 0 00-3.615-3.612z"
      ></path>
    </g>
    <path
      fill="#FAFAFA"
      d="M197.063 10.085H119.37a3.614 3.614 0 00-3.615 3.612v33.119a3.614 3.614 0 003.615 3.612h77.693a3.613 3.613 0 003.615-3.612V13.697a3.613 3.613 0 00-3.615-3.612z"
    ></path>
    <path
      stroke="#C3C3C3"
      strokeOpacity="0.5"
      d="M196.159 10.988h-75.885a3.613 3.613 0 00-3.615 3.612v31.313a3.613 3.613 0 003.615 3.612h75.885a3.614 3.614 0 003.616-3.612V14.6a3.614 3.614 0 00-3.616-3.612z"
    ></path>
    <path
      fill="#CECECE"
      fillRule="evenodd"
      d="M152.151 22.188h42.463v6.051h-42.463v-6.051zm0 10.085h18.198v6.052h-18.198v-6.052z"
      clipRule="evenodd"
    />
    <path
      fill="url(#paint2_radial_829_24594)"
      fillRule="evenodd"
      d="M123.842 20.17h20.221v20.172h-20.221V20.171z"
      clipRule="evenodd"
    />
  </motion.g>
);

const Box2 = () => (
  <motion.g {...defaultAnimationProps} transition={{ delay: 0.6 }}>
    <g filter="url(#filter1_i_829_24594)">
      <path
        fill="#000"
        d="M197.063 64.55H119.37a3.614 3.614 0 00-3.615 3.611v33.119a3.614 3.614 0 003.615 3.611h77.693a3.613 3.613 0 003.615-3.611V68.161a3.613 3.613 0 00-3.615-3.612z"
      />
    </g>

    <path
      fill="#FAFAFA"
      d="M197.063 64.55H119.37a3.614 3.614 0 00-3.615 3.611v33.119a3.614 3.614 0 003.615 3.611h77.693a3.613 3.613 0 003.615-3.611V68.161a3.613 3.613 0 00-3.615-3.612z"
    />

    <path
      stroke="#C3C3C3"
      strokeOpacity="0.5"
      d="M196.159 65.452h-75.885a3.613 3.613 0 00-3.615 3.612v31.313a3.613 3.613 0 003.615 3.611h75.885a3.614 3.614 0 003.616-3.611V69.064a3.614 3.614 0 00-3.616-3.612z"
    />

    <path
      fill="#CECECE"
      fillRule="evenodd"
      d="M152.151 76.652h42.463v6.051h-42.463v-6.051zm0 10.085h18.198v6.052h-18.198v-6.052z"
      clipRule="evenodd"
    />

    <path
      fill="url(#paint1_radial_829_24594)"
      fillRule="evenodd"
      d="M123.842 74.635h20.221v20.17h-20.221v-20.17z"
      clipRule="evenodd"
    />
  </motion.g>
);

const Arrows = () => (
  <motion.g {...defaultAnimationProps} transition={{ delay: 0.75, duration: 0.4 }}>
    <path
      fill="#2CA8E8"
      d="M158.002 64.547h.429c.998 0 1.808-.808 1.808-1.805V50.216c0-.997-.81-1.806-1.808-1.806h-.429c-.998 0-1.807.809-1.807 1.806v12.526c0 .997.809 1.805 1.807 1.805z"
    />
    <path
      fill="#2CA8E8"
      fillRule="evenodd"
      d="M166.558 57.54l-7.071 7.063a1.81 1.81 0 01-2.557 0l-7.06-7.053a1.805 1.805 0 011.276-3.083l14.132-.01a1.809 1.809 0 011.776 2.158 1.8 1.8 0 01-.496.925zM207.235 116.992v-6.067c0-.426-.083-.78-.241-1.075a1.64 1.64 0 00-.683-.682 2.184 2.184 0 00-.981-.241 4.472 4.472 0 00-.163-.003h-44.439v-4.051c0-.426-.083-.78-.24-1.074a1.641 1.641 0 00-.683-.683 2.253 2.253 0 00-1.078-.242h-.04c-.428 0-.782.083-1.077.242a1.641 1.641 0 00-.683.683c-.157.294-.242.65-.242 1.074v4.051h-44.437c-.056 0-.11 0-.163.003a2.19 2.19 0 00-.981.241c-.292.154-.53.391-.684.682-.157.295-.24.65-.24 1.075v6.071l-5.049.004a1.812 1.812 0 00-1.67 1.114 1.803 1.803 0 00.392 1.968l6.701 6.694c.569.569.837.717 1.157.813.314.096.651.096.965 0 .32-.096.586-.244 1.157-.813l6.71-6.703a1.806 1.806 0 00-.273-2.781 1.81 1.81 0 00-1.006-.303l-5.04.003v-4.028h88.967v4.032l-5.049.004a1.802 1.802 0 00-1.668 1.115 1.808 1.808 0 00.392 1.967l6.701 6.694c.569.569.835.717 1.157.813.314.096.651.096.965 0 .32-.096.586-.244 1.155-.813l6.711-6.703a1.806 1.806 0 00-1.28-3.084l-5.04.003z"
      clipRule="evenodd"
    />
  </motion.g>
);

const Box3 = () => (
  <motion.g {...defaultAnimationProps} transition={{ delay: 0.8 }}>
    <g filter="url(#filter2_i_829_24594)">
      <path
        fill="#000"
        d="M150.557 123.045H72.864a3.614 3.614 0 00-3.616 3.612v33.119a3.614 3.614 0 003.616 3.612h77.693a3.614 3.614 0 003.615-3.612v-33.119a3.614 3.614 0 00-3.615-3.612z"
      />
    </g>

    <path
      fill="#FAFAFA"
      d="M150.557 123.045H72.864a3.614 3.614 0 00-3.616 3.612v33.119a3.614 3.614 0 003.616 3.612h77.693a3.614 3.614 0 003.615-3.612v-33.119a3.614 3.614 0 00-3.615-3.612z"
    />

    <path
      stroke="#C3C3C3"
      strokeOpacity="0.5"
      d="M149.653 123.948H73.768a3.614 3.614 0 00-3.616 3.612v31.313a3.614 3.614 0 003.616 3.612h75.885a3.614 3.614 0 003.615-3.612V127.56a3.614 3.614 0 00-3.615-3.612z"
    />

    <path
      fill="#CECECE"
      fillRule="evenodd"
      d="M105.644 135.148h42.463v6.051h-42.463v-6.051zm0 10.085h18.199v6.052h-18.199v-6.052z"
      clipRule="evenodd"
    />

    <path
      fill="url(#paint0_radial_829_24594)"
      fillRule="evenodd"
      d="M77.336 133.131h20.22v20.171h-20.22v-20.171z"
      clipRule="evenodd"
    />
  </motion.g>
);

const Box4 = () => (
  <motion.g {...defaultAnimationProps} transition={{ delay: 0.8 }}>
    <g filter="url(#filter3_i_829_24594)">
      <path
        fill="#000"
        d="M245.591 123.045h-77.693a3.614 3.614 0 00-3.616 3.612v33.119a3.614 3.614 0 003.616 3.612h77.693a3.614 3.614 0 003.615-3.612v-33.119a3.614 3.614 0 00-3.615-3.612z"
      />
    </g>
    <path
      fill="#FAFAFA"
      d="M245.591 123.045h-77.693a3.614 3.614 0 00-3.616 3.612v33.119a3.614 3.614 0 003.616 3.612h77.693a3.614 3.614 0 003.615-3.612v-33.119a3.614 3.614 0 00-3.615-3.612z"
    />
    <path
      stroke="#C3C3C3"
      strokeOpacity="0.5"
      d="M244.687 123.948h-75.885a3.614 3.614 0 00-3.616 3.612v31.313a3.614 3.614 0 003.616 3.612h75.885a3.614 3.614 0 003.615-3.612V127.56a3.614 3.614 0 00-3.615-3.612z"
    />
    <path
      fill="#CECECE"
      fillRule="evenodd"
      d="M200.678 135.148h42.463v6.051h-42.463v-6.051zm0 10.085h18.199v6.052h-18.199v-6.052z"
      clipRule="evenodd"
    />
    <path
      fill="url(#paint3_radial_829_24594)"
      fillRule="evenodd"
      d="M172.37 133.131h20.221v20.171H172.37v-20.171z"
      clipRule="evenodd"
    />
  </motion.g>
);

const IfYes = () => (
  <motion.g initial={{ opacity: 0, x: -15 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.6 }}>
    <g filter="url(#filter5_i_829_24594)">
      <path
        fill="#000"
        d="M55.523 30.257H4.114A3.614 3.614 0 00.5 33.868v16.598a3.614 3.614 0 003.615 3.611h51.407a3.613 3.613 0 003.616-3.611V33.868a3.613 3.613 0 00-3.615-3.611z"
      ></path>
    </g>
    <path
      fill="#FBFBFB"
      d="M55.523 30.257H4.114A3.614 3.614 0 00.5 33.868v16.598a3.614 3.614 0 003.615 3.611h51.407a3.613 3.613 0 003.616-3.611V33.868a3.613 3.613 0 00-3.615-3.611z"
    ></path>
    <path
      stroke="#C3C3C3"
      strokeOpacity="0.5"
      d="M54.619 31.16h-49.6a3.614 3.614 0 00-3.615 3.611v14.792a3.614 3.614 0 003.615 3.611h49.6a3.614 3.614 0 003.615-3.611V34.77a3.614 3.614 0 00-3.615-3.611z"
    ></path>
    <path
      fill="#50E3C2"
      fillRule="evenodd"
      d="M10.473 47.358V36.173h-1.89v11.185h1.89zm9.385-9.396v-1.79h-6.885v11.186h1.89V42.42h3.86v-1.79h-3.86v-2.669h4.995zm13.392-1.79h-2.003l-2.802 4.49-2.802-4.49h-2.005l3.86 6.168v5.018h1.905v-5.02l3.845-6.167.002.002zm8.393 11.186v-1.79h-5.302v-3.147h4.02v-1.79h-4.02v-2.669h5.141v-1.79H34.42v11.186h7.224zm5.045.224c3.813 0 4.357-1.967 4.357-3.213 0-2.461-2.146-3.084-3.955-3.626-1.251-.384-2.307-.751-2.307-1.694 0-1.024 1.233-1.327 2.194-1.327 1.089 0 2.098.385 3.267 1.008l.192-1.95c-.592-.367-2.05-.831-3.556-.831-3.042 0-4.051 1.852-4.051 3.323 0 1.966 1.744 2.526 3.427 3.084 1.441.48 2.69.895 2.69 2.076 0 1.008-.96 1.377-2.209 1.377-1.634 0-2.867-.593-3.507-1.167l-.242 2.156c.658.4 2.02.784 3.7.784z"
      clipRule="evenodd"
    ></path>
  </motion.g>
);

const IfNo = () => (
  <motion.g initial={{ opacity: 0, x: 10 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 1, duration: 0.5 }}>
    <g filter="url(#filter4_i_829_24594)">
      <path
        fill="#000"
        d="M320.972 100.757l-45-5.516a3.615 3.615 0 00-4.029 3.146l-2.074 16.859a3.613 3.613 0 003.147 4.025l45.001 5.516a3.617 3.617 0 004.029-3.146l2.074-16.859a3.613 3.613 0 00-3.148-4.025z"
      />
    </g>

    <path
      fill="#FBFBFB"
      d="M320.972 100.757l-45-5.516a3.615 3.615 0 00-4.029 3.146l-2.074 16.859a3.613 3.613 0 003.147 4.025l45.001 5.516a3.617 3.617 0 004.029-3.146l2.074-16.859a3.613 3.613 0 00-3.148-4.025z"
    />

    <path
      stroke="#C3C3C3"
      strokeOpacity="0.5"
      d="M319.965 101.543l-43.206-5.296a3.615 3.615 0 00-4.029 3.146l-1.854 15.066a3.613 3.613 0 003.148 4.026l43.206 5.295a3.615 3.615 0 004.029-3.145l1.853-15.067a3.611 3.611 0 00-3.147-4.025z"
    />

    <path
      fill="#FC5E77"
      fillRule="evenodd"
      d="M280.134 112.85l1.307-10.624-1.794-.22-1.307 10.624 1.794.22zm10.01-7.832l.209-1.7-6.54-.802-1.307 10.625 1.794.22.577-4.69 3.666.449.209-1.7-3.666-.449.312-2.535 4.746.582zm12.136 10.547l1.306-10.625-1.794-.22-.884 7.193-3.879-7.777-1.81-.222-1.307 10.625 1.794.22.939-7.636 4.156 8.261 1.477.181h.002zm6.971 1.069c-3.043-.373-4.983-2.813-4.57-6.167.412-3.355 2.886-5.255 5.927-4.883 3.043.373 4.981 2.813 4.568 6.169-.412 3.353-2.886 5.254-5.927 4.881h.002zm1.152-9.364c-2.177-.267-3.489 1.236-3.76 3.437-.27 2.2.639 3.975 2.813 4.241 2.175.267 3.487-1.236 3.758-3.435.27-2.202-.639-3.977-2.811-4.243z"
      clipRule="evenodd"
    />
  </motion.g>
);

const defaultStarAnimationProps: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

const Stars = () => (
  <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.7 }}>
    <motion.path
      fill="#50E3C2"
      d="M312.9 42.36a1.684 1.684 0 01-1.687-1.68 1.685 1.685 0 00-1.684-1.681 1.69 1.69 0 01-1.242-.464 1.675 1.675 0 01-.385-1.88 1.669 1.669 0 01.958-.914 1.69 1.69 0 01.669-.105 1.686 1.686 0 001.684-1.68 1.684 1.684 0 011.686-1.683 1.687 1.687 0 011.686 1.684c0 .926.754 1.68 1.684 1.68a1.69 1.69 0 011.242.463 1.675 1.675 0 01.385 1.88 1.669 1.669 0 01-.958.914 1.69 1.69 0 01-.669.105 1.686 1.686 0 00-1.684 1.68 1.684 1.684 0 01-1.685 1.68z"
      initial={{ opacity: 0, y: -10, x: 10 }}
      animate={{ opacity: 1, y: 0, x: 0 }}
      transition={{ delay: 0.6, duration: 2 }}
    />

    <motion.path
      fill="#FC5E77"
      d="M292.679 56.48a1.818 1.818 0 01-1.82-1.816 5.464 5.464 0 00-5.459-5.446 1.822 1.822 0 01-1.754-1.101 1.824 1.824 0 010-1.428 1.818 1.818 0 011.754-1.1 5.465 5.465 0 005.459-5.447 1.819 1.819 0 013.107-1.286c.342.34.534.803.534 1.286a5.452 5.452 0 001.602 3.85 5.465 5.465 0 003.857 1.596 1.82 1.82 0 011.899 1.815 1.806 1.806 0 01-.561 1.313 1.825 1.825 0 01-1.338.502 5.464 5.464 0 00-3.857 1.596 5.452 5.452 0 00-1.602 3.85 1.82 1.82 0 01-1.821 1.817zm-1.826-9.077a9.235 9.235 0 011.826 1.824 9.235 9.235 0 011.826-1.824 9.23 9.23 0 01-1.826-1.824 9.23 9.23 0 01-1.826 1.824z"
      initial={{ opacity: 0, y: -10, x: 5 }}
      animate={{ opacity: 1, y: 0, x: 0 }}
      transition={{ delay: 0.3, duration: 1 }}
    />

    <motion.path
      fill="#2C98E3"
      d="M28.796 114.977c0 1.116-.814 2.021-1.817 2.021-3.006 0-5.452 2.717-5.452 6.058 0 1.116-.813 2.021-1.818 2.021-1.004 0-1.817-.905-1.817-2.021 0-3.341-2.446-6.058-5.452-6.058-1.003 0-1.817-.905-1.817-2.021 0-1.114.814-2.019 1.817-2.019 3.006 0 5.452-2.72 5.452-6.06 0-1.116.813-2.021 1.817-2.021 1.003 0 1.818.905 1.818 2.021 0 3.34 2.446 6.06 5.452 6.06 1.002 0 1.817.903 1.817 2.019zm-9.087 2.028a9.809 9.809 0 011.825-2.028 9.809 9.809 0 01-1.825-2.028 9.809 9.809 0 01-1.826 2.028 9.738 9.738 0 011.826 2.028z"
      {...defaultStarAnimationProps}
      initial={{ opacity: 0, y: 10, x: -15 }}
      animate={{ opacity: 1, y: 0, x: 0 }}
      transition={{ delay: 0.6, duration: 1.5 }}
    />
  </motion.g>
);

const Backdrop = () => (
  <motion.path
    initial={{ scale: 0, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    transition={{ delay: 0.1, duration: 0.8 }}
    fill="#F5F5F5"
    d="M158.216 185.577c51.369 0 93.013-41.542 93.013-92.788C251.229 41.543 209.585 0 158.216 0c-51.37 0-93.013 41.543-93.013 92.789s41.643 92.788 93.013 92.788z"
    opacity="0.5"
  />
);
