import React from 'react';
import { AutomationAppName, getAutomationAppNameLabel } from '@process-street/subgrade/automation';
import { Box, Text, HStack, Flex } from 'components/design/next';
import { AutomationAppIcon } from 'features/automations/components/automation-app-icon';

export type AutomationAppProps = { automationApp: AutomationAppName };

export const AutomationApp: React.FunctionComponent<React.PropsWithChildren<AutomationAppProps>> = props => {
  if (!props.automationApp) {
    return null;
  }

  const label = getAutomationAppNameLabel(props.automationApp);

  return (
    <HStack align="center">
      <Flex align="center" justify="center" h="1.5em" w="1.5em">
        <AutomationAppIcon automationApp={props.automationApp} />
      </Flex>
      <Box>
        <Text fontSize="inherit" as="span" color="inherit" fontWeight="inherit">
          {label}
        </Text>
      </Box>
    </HStack>
  );
};
