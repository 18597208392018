import { Muid, Option } from '../core';
import { abbreviateForTitle } from './abbreviate-for-title';
import { getBaseUrl } from './get-base-url';

const urlBase: string = getBaseUrl();

const templateHref = (templateId: Muid, templateName: Option<string>): string => {
  const title = abbreviateForTitle(templateName);
  const templateQueryPath = title ? `${encodeURIComponent(title)}-${templateId}` : templateId;
  return `${urlBase}/templates/${templateQueryPath}/view`;
};

const formHref = (templateId: Muid, templateName: Option<string>): string => {
  const title = abbreviateForTitle(templateName);
  const templateQueryPath = title ? `${encodeURIComponent(title)}-${templateId}` : templateId;
  return `${urlBase}/forms/${templateQueryPath}`;
};

const checklistHref = (checklistId: Muid, checklistName: Option<string>): string => {
  const title = abbreviateForTitle(checklistName);
  const checklistQueryPath = title ? `${encodeURIComponent(title)}-${checklistId}` : checklistId;
  return `${urlBase}/checklists/${checklistQueryPath}`;
};

const taskHref = (checklistId: Muid, groupId: Muid, checklistName: Option<string>): string => {
  const checklistPath = checklistHref(checklistId, checklistName);
  return `${checklistPath}/tasks/${groupId}`;
};

const editStepHref = (templateId: Muid, groupId: Muid, templateName: Option<string>): string => {
  const formPath = editFormHref(templateId, templateName);
  return `${formPath}/steps/${groupId}`;
};

const editFormHref = (templateId: Muid, templateName: Option<string>): string => {
  const formPath = formHref(templateId, templateName);
  return `${formPath}/edit`;
};

const editTaskTemplateHref = (templateId: Muid, taskGroupId: Muid): string => {
  return `${urlBase}/workflows/v2/${templateId}/edit/tasks/${taskGroupId}`;
};

export const LocationService = {
  templateHref,
  formHref,
  editFormHref,
  checklistHref,
  taskHref,
  editStepHref,
  editTaskTemplateHref,
};
