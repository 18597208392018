import * as React from 'react';
import { Grid, GridItem, useBreakpointValue, VStack } from 'components/design/next';
import { useSelector } from '@xstate/react';
import { TOP_BAR_HEIGHT_CSS_VAR, TOP_BAR_HEIGHT_TOKEN } from 'app/pages/forms/_id/shared';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-context';
import { WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT_V2 } from 'app/components/focus-bar/workflow/focus-bar-hstack';
import { useStateParam } from 'app/hooks/use-state-param';
import { Template, TemplateRevision } from '@process-street/subgrade/process';
import { Option } from 'space-monad';
import { StateFrom } from 'xstate';
import { useRedirectToTaskOnLoad } from '../edit-v2/hooks';
import { Editor } from '../edit-v2/components';
import { BackgroundImage } from '../edit-v2/components/background-image/background-image';
import { AnalyticsService } from 'app/components/analytics/analytics.service';
import { AnalyticsConstants } from '@process-street/subgrade/analytics';
import { useDebouncedCallback } from 'use-debounce';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';
import { FormEditorPageMachine } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-types';

const { Key, Event } = AnalyticsConstants;

const Content = ({ template, templateRevision }: { template: Template; templateRevision: TemplateRevision }) => {
  const actor = useFormEditorPageActorRef();
  const uiActorRef = useSelector(actor, FormEditorPageActorSelectors.getUIActorRef);

  const taskTemplateGroupId = useStateParam({ key: 'groupId' });

  const currentTaskTemplateSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return Option(taskTemplateGroupId)
        .map(groupId => FormEditorPageActorSelectors.getTaskTemplateByGroupId(groupId)(state))
        .get();
    },
    [taskTemplateGroupId],
  );

  const currentTaskTemplate = useSelector(actor, currentTaskTemplateSelector);
  const isLoading = useSelector(actor, FormEditorPageActorSelectors.isLoading);

  useRedirectToTaskOnLoad({ isLoading, templateId: template.id, taskTemplateGroupId });

  const isMobile = useBreakpointValue({ base: true, lg: false }, { ssr: false }) ?? false;
  React.useEffect(
    function notifyScreenSizeToUiMachine() {
      uiActorRef.send({ type: 'SET_IS_MOBILE', isMobile });
    },
    [isMobile, uiActorRef],
  );

  React.useEffect(
    function updatePageTitle() {
      if (template.name) {
        document.title = `${template.name} | Process Street`;
      }
    },
    [template.name],
  );

  const trackViewTemplateEvent = () => {
    const props = {
      [Key.TEMPLATE_ID]: template.id,
      [Key.TEMPLATE_NAME]: template.name,
    };
    AnalyticsService.trackEvent(Event.TEMPLATE_VIEWED, props);
  };

  const debouncedTrackViewTemplateEvent = useDebouncedCallback(trackViewTemplateEvent, 2000);

  React.useEffect(
    function trackIntercomEvents() {
      // Debouncing because this component renders twice, and here we want to track when an user sees the template,
      // so we debounce to avoid duplicates. Remove the debaunced callback when de double render bug is fixed.
      debouncedTrackViewTemplateEvent();
    },
    [template, debouncedTrackViewTemplateEvent],
  );

  return (
    <>
      <BackgroundImage
        templateId={template.id}
        topOffset={TOP_BAR_HEIGHT_TOKEN + WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT_V2}
      />

      <Grid
        gridTemplateColumns={{ base: '0 1fr 0', lg: '480px 1fr 0' }}
        h={`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR})`}
        position="relative"
        pt={WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT_V2}
        background="transparent"
      >
        <GridItem h="full">{<Editor.TaskListSection template={template} isMobile={isMobile} />}</GridItem>

        <GridItem
          pt={{ base: 8, md: 32, lg: 10 }}
          px={{ base: 2, md: 0 }}
          overflowY="auto"
          h="full"
          position="relative"
          display="flex"
          flexDirection="column"
          w="full"
        >
          {currentTaskTemplate && (
            <Editor.WidgetListSection
              currentTaskTemplate={currentTaskTemplate}
              template={template}
              templateRevision={templateRevision}
              isMobile={isMobile}
            />
          )}
        </GridItem>
      </Grid>

      <Editor.Modal />
    </>
  );
};

export const ViewPage = ({
  template,
  templateRevision,
}: {
  template: Template;
  templateRevision: TemplateRevision;
}) => {
  return (
    <>
      <VStack w="full" alignItems="stretch" justifyContent="stretch" spacing={0} bgColor="brand.50">
        <Editor.FocusBar />

        <Content template={template} templateRevision={templateRevision} />
      </VStack>
    </>
  );
};
