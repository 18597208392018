import { Muid, User } from '@process-street/subgrade/core';
import { UpdateContactSalesVariablesProps } from './contact-sales-service';
import {
  UpdateTrialBlockPaywallSeenVariablesProps,
  UpdateTrialBlockPaywallVariablesProps,
} from './trial-block-service';

type IntercomEvent = 'boot' | 'update' | 'shutdown' | 'show' | 'showNewMessage' | 'hide' | 'trackEvent' | 'startTour';

type IntercomApi = {
  (event: IntercomEvent): void;
  (event: IntercomEvent, properties: unknown): void;
  (event: IntercomEvent, action: string, properties: unknown): void;
};

declare const window: {
  Intercom: IntercomApi;
};

export type UpdateProperties =
  | UpdateTrialBlockPaywallSeenVariablesProps
  | UpdateTrialBlockPaywallVariablesProps
  | UpdateContactSalesVariablesProps
  | {
      last_request_at: number;
    }
  | {
      hide_default_launcher: boolean;
    };

// This is needed to make sure we can always call window.Intercom
const i = function (...args: unknown[]) {
  i.c(args);
};
// @ts-expect-error -- TODO
i.q = [];
i.c = function (args: unknown[]) {
  i.q.push(args);
};

window.Intercom = window.Intercom || i;

const boot = (user?: User, intercomHash?: string, selectedOrganizationId?: Muid) => {
  let settings = {};
  if (user) {
    settings = {
      ...settings,
      user_hash: intercomHash,
      user_id: user.id,
      name: user.username,
      email: user.email,
      origin: user.origin,
    };
  }
  if (selectedOrganizationId) {
    settings = {
      ...settings,
      company: {
        id: selectedOrganizationId,
      },
    };
  }
  window.Intercom('boot', settings);
};

const ping = () => {
  const now = Math.floor(new Date().getTime() / 1000);
  update({ last_request_at: now });
};

const update = (properties: UpdateProperties) => {
  window.Intercom('update', properties);
};

const shutdown = () => {
  window.Intercom('shutdown');
};

const show = () => {
  window.Intercom('show');
};

const showNewMessage = (content?: string) => {
  window.Intercom('showNewMessage', content);
};

const hide = () => {
  window.Intercom('hide');
};

const trackEvent = (action: string, properties: unknown) => {
  window.Intercom('trackEvent', action, properties);
};

const startTour = (tourId: number) => {
  window.Intercom('startTour', tourId);
};

export const IntercomService = {
  boot,
  ping,
  update,
  shutdown,
  show,
  showNewMessage,
  hide,
  trackEvent,
  startTour,
};
