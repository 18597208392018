import angular, { IController, IRootScopeService, ITimeoutService } from 'angular';
import * as Encase from 'encase';
import { PromptOverrideLookup } from 'features/developer/components/prompt-override/prompt-override.component';

type PromptKey = keyof PromptOverrideLookup;

angular.module('frontStreetApp.controllers').controller(
  'DeveloperConsolePromptsCtrl',
  function (
    this: IController & {
      promptKeys: PromptKey[];
    },
    $rootScope: IRootScopeService,
    $timeout: ITimeoutService,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias -- old ctrl
    const ctrl = this;

    ctrl.$onInit = () => {
      ctrl.promptKeys = ['taskList', 'taskProperties', 'formFields', 'approvalSubjects'];

      // Hack: this causes the textarea auto-size to work more consistently
      $timeout(() => $rootScope.$broadcast('elastic:adjust'), 500);
      $timeout(() => $rootScope.$broadcast('elastic:adjust'), 1000);
      $timeout(() => $rootScope.$broadcast('elastic:adjust'), 2000);
      $timeout(() => $rootScope.$broadcast('elastic:adjust'), 5000);
      $timeout(() => $rootScope.$broadcast('elastic:adjust'), 10000);
    };

    ctrl.formatKey = (key: PromptKey) => Encase.separate(Encase.toUpperCamel(key)).join(' ');
  },
);
