import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';
import { FormResponsePageActor } from 'app/pages/responses/_id/form-response-page-machine';

export const [useFormResponsePageActorRef, FormResponsePageActorRefContext] =
  createUsableContext<FormResponsePageActor>({
    hookName: 'useFormResponsePageActorRef',
    providerName: 'FormResponsePageActorRefProvider',
  });

export const FormResponsePageActorRefProvider: React.FC<React.PropsWithChildren<{ actor?: FormResponsePageActor }>> = ({
  actor,
  children,
}) => {
  return <FormResponsePageActorRefContext.Provider value={actor}>{children}</FormResponsePageActorRefContext.Provider>;
};
