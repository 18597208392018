import axios from 'axios';
import _random from 'lodash/random';
import truncate from 'lodash/truncate';
import dayjs from 'dayjs';

const requireCoverImageUrl = (fileName: string) => {
  return require(`../../../../../images/ai-workflow-generation/${fileName}`);
};

const coverImages = [
  requireCoverImageUrl('ai-cover-image-1.png'),
  requireCoverImageUrl('ai-cover-image-2.png'),
  requireCoverImageUrl('ai-cover-image-3.png'),
  requireCoverImageUrl('ai-cover-image-4.png'),
  requireCoverImageUrl('ai-cover-image-5.png'),
];

const getRandomCoverImageUrl = () => coverImages[_random(0, coverImages.length - 1)];

// Gets a image and converts to a file
const createFileFromImageUrl = async (url: string) => {
  return axios
    .get(url, {
      responseType: 'arraybuffer',
    })
    .then(res => {
      const arraybuffer: ArrayBuffer = res.data;

      const file = new File([arraybuffer], 'ai-cover-image.png', {
        type: 'image/png',
      });

      return file;
    });
};

const getCoverImageFile = async (): Promise<File> => {
  return createFileFromImageUrl(getRandomCoverImageUrl());
};

const getDefaultChecklistName = ({
  templateName,
  defaultChecklistName,
}: {
  templateName: string;
  defaultChecklistName?: string;
}) => {
  const now = dayjs();
  const nowStr = now.format('h:mmA');

  return (
    defaultChecklistName ??
    `${truncate(templateName, {
      length: 50,
      omission: '…',
    })} ${nowStr} workflow run`
  );
};

export const AiGeneratedWorkflowSettingsModalHelpers = {
  getCoverImageFile,
  getDefaultChecklistName,
};
