import { ButtonProps } from 'components/design/next';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import * as React from 'react';
import { useTemplateMenuContext, useTemplateMenuDisclosureContext } from '../template-menu';

interface ManageTemplateTagsButtonProps {
  children: React.ReactElement<ButtonProps>;
}

export const ManageTemplateTagsButton: React.FC<React.PropsWithChildren<ManageTemplateTagsButtonProps>> = React.memo(
  ({ children }) => {
    const { templateId } = useTemplateMenuContext();
    const { tagsModalDisclosure: disclosure } = useTemplateMenuDisclosureContext();

    // UI
    if (!React.isValidElement(children) || Array.isArray(children)) {
      throw new Error('ManageTemplateTagsButton child must be a single clickable element');
    }
    const btn = React.cloneElement(children, {
      onClick: () => {
        disclosure.onToggle();
      },
    });

    const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId, {
      enabled: !!templateId,
    });
    const canManageTemplateTags = permissionMap?.templateUpdate;

    return canManageTemplateTags ? <>{btn}</> : null;
  },
);
