import {
  FilteredItem,
  SelectedTemplateIdsState,
} from 'components/dashboard/components/checklist/ChecklistDashboardFilters/filters/ChecklistDashboardTemplatesFilter/use-selected-template-ids-state';
import React from 'react';
import { match } from 'ts-pattern';
import { BlvdSelect, BlvdSelectHelpers, BlvdSelectProps } from 'components/design/BlvdSelect';

export interface TemplatesFilterProps extends Omit<SelectedTemplateIdsState, 'setSelectedTemplateIds'> {
  blvdSelectProps?: BlvdSelectProps<FilteredItem, true>;
}

type Option = { value: string; label: string };
export const TemplatesFilter = ({
  selectedTemplateIds,
  filteredItems,
  onSelectionChange,
  blvdSelectProps,
}: TemplatesFilterProps) => {
  const value = React.useMemo(() => {
    const valueLabelMap = new Map(filteredItems.map(item => [item.value, item.label]));
    return selectedTemplateIds.map(id => ({ value: id, label: valueLabelMap.get(id) ?? 'Loading…' }));
  }, [filteredItems, selectedTemplateIds]);

  // We want to "pin" selected templates to the top.
  const getSortedOptions = React.useCallback(() => {
    const selectedSet = new Set(value.map(({ value }) => value));
    return [...filteredItems].sort((a, b) => {
      const aSelected = selectedSet.has(a.value);
      const bSelected = selectedSet.has(b.value);
      return match({ aSelected, bSelected })
        .with({ aSelected: true, bSelected: false }, () => -1)
        .with({ aSelected: false, bSelected: true }, () => 1)
        .otherwise(() => 0);
    });
  }, [filteredItems, value]);
  const [sortedOptions, setSortedOptions] = React.useState(getSortedOptions);

  // There can be a rerendering race condition on initial render
  if (sortedOptions.length === 0 && filteredItems.length > 0) {
    setSortedOptions(getSortedOptions);
  }

  // Don't modify the list order until selections are done so the scroll position doesn't change.
  const handleMenuClose = React.useCallback(() => {
    setSortedOptions(getSortedOptions);
  }, [getSortedOptions]);

  return (
    <BlvdSelect
      options={sortedOptions}
      isMulti
      onMenuClose={handleMenuClose}
      isSearchable
      value={value}
      placeholder="All workflows"
      fixedSize
      menuControls
      onChange={value => {
        if (value === null) {
          onSelectionChange([]);
        }
        if (BlvdSelectHelpers.isOptionsType<Option>(value)) {
          onSelectionChange(value.map(({ value }) => value));
        }
      }}
      {...blvdSelectProps}
    />
  );
};
