import { Option } from '../../../core';
import { FormFieldValueUpdateResult, Task } from '../../../process';
import { toEntityMap } from '../../reducer-utils';
import { BaseReduxState } from '../../types';
import { BaseTaskSelector } from '../task.selectors';

function getInvisibleTasks(updateResult: FormFieldValueUpdateResult, state: BaseReduxState): Option<Task[]> {
  const {
    formFieldValue: { checklistRevision },
    tasksAffectedByTaskPermits: { forbidden },
  } = updateResult;
  // Checking if forbidden tasks changed for the user
  if (forbidden.length === 0) {
    return undefined;
  }

  const taskMap = toEntityMap(BaseTaskSelector.getAllByChecklistRevisionId(checklistRevision.id)(state));
  const invisibleTasks = forbidden.filter((task: Task) => taskMap[task.id] !== undefined);

  return invisibleTasks.length > 0 ? invisibleTasks : undefined;
}

/**
 * Checks if due to Permission changes need to reload checklist
 */
function needReloadChecklistAfterFormFieldUpdate(
  updateResult: FormFieldValueUpdateResult,
  state: BaseReduxState,
): boolean {
  const {
    formFieldValue: { checklistRevision },
    tasksAffectedByTaskPermits: { forbidden, permitted },
  } = updateResult;
  // Checking if forbidden tasks changed for the user
  if (forbidden.length > 0) {
    const taskMap = toEntityMap(BaseTaskSelector.getAllByChecklistRevisionId(checklistRevision.id)(state));
    const taskWasVisible = forbidden.some((task: Task) => taskMap[task.id] !== undefined);

    if (taskWasVisible) {
      return true; // tasks were affected by tp rule - refresh the page
    }
  }

  // Checking if permitted tasks changed for the user
  if (permitted.length > 0) {
    const taskMap = toEntityMap(BaseTaskSelector.getAllByChecklistRevisionId(checklistRevision.id)(state));
    const taskWasNotVisible = permitted.some((task: Task) => taskMap[task.id] === undefined);

    if (taskWasNotVisible) {
      return true; // tasks were affected by tp rule - refresh the page
    }
  }

  return false;
}

export const TaskPermitUtils = {
  getInvisibleTasks,
  needReloadChecklist: needReloadChecklistAfterFormFieldUpdate,
};
