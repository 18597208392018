import { AuditMetadata, Muid, Organization, OrganizationMembership, Ref, User } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

// create: 'POST /1/template-subscriptions',
export type TemplateSubscription = {
  id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  template: Ref<Template>;
  organizationMembership: Ref<OrganizationMembership>;
};

export type TemplateSubscriptionWithOrganizationMembership = TemplateSubscription & {
  organizationMembership: OrganizationMembership;
};

type SubscribeToTemplateMutationParams = { templateId: Template['id']; userId: User['id'] };
type SubscribeToTemplateMutationResult = TemplateSubscription;
const SubscribeToTemplateMutation = {
  key: ['template-subscriptions', 'post'],
  mutationFn: (params: SubscribeToTemplateMutationParams) =>
    axiosService
      .getAxios()
      .post<SubscribeToTemplateMutationResult>('/1/template-subscriptions', params)
      .then(r => r.data),
};
export const useSubscribeToTemplateMutation = (
  options: UseMutationOptions<SubscribeToTemplateMutationResult, AxiosError, SubscribeToTemplateMutationParams> = {},
) => {
  return useMutation(SubscribeToTemplateMutation.mutationFn, {
    mutationKey: SubscribeToTemplateMutation.key,
    ...options,
  });
};

type UnsubscribeFromTemplateMutationParams = { templateSubscriptionId: TemplateSubscription['id'] };
type UnsubscribeFromTemplateMutationResult = void;
const UnsubscribeFromTemplateMutation = {
  key: ['template-subscriptions', 'delete'],
  mutationFn: (params: UnsubscribeFromTemplateMutationParams) =>
    axiosService
      .getAxios()
      .delete<UnsubscribeFromTemplateMutationResult>(`/1/template-subscriptions/${params.templateSubscriptionId}`)
      .then(r => r.data),
};
export const useUnsubscribeFromTemplateMutation = (
  options: UseMutationOptions<
    UnsubscribeFromTemplateMutationResult,
    AxiosError,
    UnsubscribeFromTemplateMutationParams
  > = {},
) => {
  return useMutation(UnsubscribeFromTemplateMutation.mutationFn, {
    mutationKey: UnsubscribeFromTemplateMutation.key,
    ...options,
  });
};

type GetTemplateSubscriptionsParams = {
  templateId: Template['id'];
  userId: User['id'];
};
export type GetTemplateSubscriptionsResult = TemplateSubscriptionWithOrganizationMembership[];
const GetTemplateSubscriptions = {
  key: ['template-subscriptions', 'query'],
  getKey: (params: GetTemplateSubscriptionsParams): QueryKey => [...GetTemplateSubscriptions.key, params],
  queryFn: (params: GetTemplateSubscriptionsParams) =>
    axiosService
      .getAxios()
      .get<GetTemplateSubscriptionsResult>(`/1/template-subscriptions`, {
        params: {
          where: JSON.stringify({
            'templateId': { _eq: params.templateId },
            'organizationMembership.userId': { _eq: params.userId },
          }),
          include: 'organizationMembership',
        },
      })
      .then(r => r.data),
};
export const useGetTemplateSubscriptionsQuery = (
  params: GetTemplateSubscriptionsParams,
  options: UseQueryOptions<GetTemplateSubscriptionsResult, AxiosError> = {},
) => {
  return useQuery(GetTemplateSubscriptions.getKey(params), () => GetTemplateSubscriptions.queryFn(params), {
    ...options,
  });
};
