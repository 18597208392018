import { Muid } from '@process-street/subgrade/core';
import {
  GetNewestTemplateRevisionsByTemplateIdQueryResponse,
  useGetNewestTemplateRevisionsByTemplateIdQuery,
} from 'features/template-revisions/query-builder/get-newest-template-revisions-by-template-id';
import { UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import identity from 'lodash/identity';
import { TemplateRevisionWithTemplate } from '@process-street/subgrade/process';

export type GetFinishedTmplRevisionByTmplIdParams = { templateId: Muid };

export type GetFinishedTmplRevisionByTmplIdResponse = TemplateRevisionWithTemplate | undefined;

export const useGetFinishedTmplRevisionByTmplIdQuery = <Select = GetFinishedTmplRevisionByTmplIdResponse>(
  params: GetFinishedTmplRevisionByTmplIdParams,
  {
    select,
    ...options
  }: Omit<UseQueryOptions<GetNewestTemplateRevisionsByTemplateIdQueryResponse, AxiosError, Select>, 'select'> & {
    select?: (result: GetFinishedTmplRevisionByTmplIdResponse) => Select;
  } = {},
) => {
  return useGetNewestTemplateRevisionsByTemplateIdQuery<Select>(params, {
    ...options,
    select: newestTmplRevisions => (select ?? identity)(newestTmplRevisions[0]) as Select,
  });
};
