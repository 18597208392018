import { useCopyToClipboard as useCopyToClipboardHook } from 'react-use';
import { useEffect } from 'react';

export type CopyToClipboardHookArgs = {
  onError?: (error: Error) => void;
  onSuccess?: () => void;
};

export const useCopyToClipboard = ({ onSuccess, onError }: CopyToClipboardHookArgs) => {
  const [{ error, value }, copyToClipboard] = useCopyToClipboardHook();

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [error, onError]);

  useEffect(() => {
    if (value && onSuccess) {
      onSuccess();
    }
  });

  return (textToCopy: string) => copyToClipboard(textToCopy);
};
