import * as yup from 'yup';
import { FormFieldValueSchemaTests } from '../common/validation/form-field-value-schema-tests';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';
import { FieldType, UrlFormFieldWidget } from '@process-street/subgrade/process';
import { FormFieldWidgetSettingsSchemas } from '../common/validation/form-field-widget-settings-schema';

export const urlSettingsSchema: yup.ObjectSchema<WidgetSettings<FieldType.Url>> = yup
  .object({
    config: yup
      .object<UrlFormFieldWidget['config']>({
        ...FormFieldWidgetSettingsSchemas.defaultValueUrl(),
      })
      .required(),
  })
  .required();

type ValidationSchemaProps = {
  required: boolean;
};

export const makeUrlValidationSchema = ({ required }: ValidationSchemaProps) => {
  return yup.string().test(FormFieldValueSchemaTests.required(required)).test(FormFieldValueSchemaTests.url());
};
