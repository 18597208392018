import { Identifiable, Muid } from '@process-street/subgrade/core';
import { combine } from 'zustand/middleware';
import create from 'zustand';

type TemplateLibrarySelectionSlice = {
  selectedItemsMap: Map<Muid, Identifiable>;
  selectedIds: Set<Muid>;
};

const INITIAL_STATE: TemplateLibrarySelectionSlice = {
  selectedItemsMap: new Map([]),
  selectedIds: new Set([]),
};

export const useTemplateLibrarySelectionSlice = create(
  combine(INITIAL_STATE, (set, getState) => {
    const select = (item: Identifiable) =>
      set(state => {
        state.selectedIds.add(item.id);
        state.selectedItemsMap.set(item.id, item);
        return {
          selectedIds: new Set(state.selectedIds.values()),
          selectedItemsMap: new Map(state.selectedItemsMap.entries()),
        };
      });

    const deselect = (id: Muid) =>
      set(state => {
        state.selectedIds.delete(id);
        state.selectedItemsMap.delete(id);
        return {
          selectedIds: new Set(state.selectedIds.values()),
          selectedItemsMap: new Map(state.selectedItemsMap.entries()),
        };
      });

    const deselectAll = () =>
      set(() => ({
        selectedIds: new Set([]),
        selectedItemsMap: new Map([]),
      }));

    const isSelected = (id: Muid) => getState().selectedIds.has(id);

    return {
      select,
      deselect,
      deselectAll,
      isSelected,
    };
  }),
);
