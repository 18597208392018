import { Muid, Period } from '@process-street/subgrade/core';
import { match, P } from 'ts-pattern';
import { CustomNotificationUtils } from '../../custom-notification-utils';

export type Timing = CustomNotificationUtils.Timing;

export type OffsetDirection = NonNullable<Timing['offsetDirection']>;
export type Entity = NonNullable<Timing['entity']>;
export type EntityType = Entity['type'];
export type Property = NonNullable<Timing['property']>;

export const RULE_TREES: Record<OffsetDirection, { [k in EntityType]?: Array<Property> }> = {
  Before: { Task: ['dueDate'] },
  On: { WorkflowRun: ['startDate', 'completedDate'], Task: ['completedDate', 'dueDate'] },
  After: { WorkflowRun: ['startDate', 'completedDate'], Task: ['completedDate', 'dueDate'] },
};

export const OFFSET_DIRECTIONS = Object.keys(RULE_TREES) as OffsetDirection[];

export const getOffsetDirectionEntities = ({
  timing,
  taskTemplateIds,
  templateRevisionId,
}: {
  timing: Timing;
  templateRevisionId: Muid;
  taskTemplateIds: Muid[];
}): Entity[] =>
  timing.offsetDirection
    ? Object.keys(RULE_TREES[timing.offsetDirection]).flatMap(entityType =>
        match<EntityType, Entity[]>(entityType as EntityType)
          .with('WorkflowRun', t => [{ type: t, templateRevisionId }])
          .with('Task', type => taskTemplateIds.map(taskTemplateId => ({ type, taskTemplateId })))
          .exhaustive(),
      )
    : [];

export const getOffsetDirectionEntityProperties = (timing: Timing): Property[] =>
  (timing.offsetDirection && timing.entity && RULE_TREES[timing.offsetDirection][timing.entity.type]) ?? [];

const EMPTY_TIMING_LABEL_TEXT = 'Select...';

export const getTimingLabel = (timing: Timing) => {
  return match(timing)
    .with(
      { offsetDirection: 'On', entity: { type: 'WorkflowRun' }, property: 'startDate' },
      () => 'On workflow run start date',
    )
    .with(
      { offsetDirection: 'On', entity: { type: 'WorkflowRun' }, property: 'completedDate' },
      () => 'On workflow run complete',
    )
    .with(
      {
        offsetDirection: 'After',
        entity: { type: 'WorkflowRun' },
        property: 'completedDate',
        offset: P.not(P.nullish),
      },
      ({ offset }) => `${Period.format(offset)} after workflow run is complete`,
    )
    .with(
      { offsetDirection: 'After', entity: { type: 'WorkflowRun' }, property: 'startDate', offset: P.not(P.nullish) },
      ({ offset }) => `${Period.format(offset)} after workflow run start date`,
    )
    .with({ offsetDirection: 'On', entity: { type: 'Task' }, property: 'completedDate' }, () => 'On task complete')
    .with(
      {
        offsetDirection: 'Before',
        entity: { type: 'Task' },
        property: 'dueDate',
        offset: P.not(P.nullish),
      },
      ({ offset }) => `${Period.format(offset)} before task is due`,
    )
    .with({ offsetDirection: 'On', entity: { type: 'Task' }, property: 'dueDate' }, () => 'On task due date')
    .with(
      {
        offsetDirection: 'After',
        entity: { type: 'Task' },
        property: 'dueDate',
        offset: P.not(P.nullish),
      },
      ({ offset }) => `${Period.format(offset)} after task is due`,
    )
    .with(
      { offsetDirection: 'After', entity: { type: 'Task' }, property: 'completedDate', offset: P.not(P.nullish) },
      ({ offset }) => `${Period.format(offset)} after task is complete`,
    )
    .otherwise(() => EMPTY_TIMING_LABEL_TEXT);
};

export const getTimingLabelColor = (label: string) => (label === EMPTY_TIMING_LABEL_TEXT ? 'gray.400' : 'gray.600');

export const getNewTimingFromOffsetDirection = (timing: Timing, offsetDirection?: OffsetDirection) => {
  return {
    offsetDirection,
    // Some nested ternaries to leverage TS type narrowing.
    // tl;dr is keep entity and property if they're valid for the new offsetDirection
    ...(timing.entity && offsetDirection && RULE_TREES[offsetDirection][timing.entity.type]
      ? {
          entity: timing.entity,
          ...(timing.property && RULE_TREES[offsetDirection][timing.entity.type]?.includes(timing.property)
            ? { property: timing.property }
            : {}),
        }
      : {}),
  };
};

export const getNewTimingFromEntity = (timing: Timing, entity?: Entity): Timing => {
  return {
    offsetDirection: timing.offsetDirection,
    offset: timing.offset,
    // Some nested ternaries to leverage TS type narrowing.
    // tl;dr is keep entity and property if they're valid for the new offsetDirection
    ...(entity && timing.offsetDirection && RULE_TREES[timing.offsetDirection][entity.type]
      ? {
          entity,
          ...(timing.property && RULE_TREES[timing.offsetDirection][entity.type]?.includes(timing.property)
            ? { property: timing.property }
            : {}),
        }
      : {}),
  };
};
