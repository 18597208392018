import { Muid, MuidUtils } from '@process-street/subgrade/core';
import {
  ColumnDto,
  ChecklistColumn,
  Filter,
  ProgressStatus,
  FormFieldDataRow,
} from '@process-street/subgrade/dashboard';
import { GetDataRequest, TemplateDto } from 'components/dashboard/models/filters';
import {
  GetAvatarsRequest,
  MembershipAvatarDto,
  NameValue,
  OverdueTaskDto,
  ProgressValue,
  RowValues,
  TaskDataRow,
  TemplateValue,
} from 'components/dashboard/models/grid';
import { predefinedViews, SavedView } from 'components/dashboard/models/saved-views';
import {
  generateAssignees,
  generateColumns,
  generateRowData,
  generateSavedView,
  generateTemplates,
} from 'components/dashboard/models/test-data';
import { GridDataService } from 'components/dashboard/services/grid-data.service';
import { mapWithIndex } from 'test/test-utils';

export class TestGridDataServiceImpl implements GridDataService {
  public static getInstance() {
    return TestGridDataServiceImpl.instance;
  }

  private static instance = new TestGridDataServiceImpl();

  // temporary test data
  private templates: TemplateDto[] = generateTemplates(30);
  private columns: ColumnDto[] = generateColumns(10, 10);

  private allRows: RowValues[] = generateRowData(this.templates, 250);

  public getTemplatesByOrganizationId(): Promise<TemplateDto[]> {
    return Promise.resolve(this.templates);
  }

  public getColumns(): Promise<ColumnDto[]> {
    return Promise.resolve(this.columns);
  }

  public getRowCount(request: GetDataRequest): Promise<number> {
    const rows = this.getTestData(request);

    const promise: Promise<number> = new Promise(resolve => {
      window.setTimeout(() => {
        resolve(rows.length);
      }, 500);
    });

    return promise;
  }

  public getData(request: GetDataRequest): Promise<RowValues[]> {
    const rows = this.getTestData(request).slice(request.pageSize);

    const promise: Promise<RowValues[]> = new Promise(resolve => {
      window.setTimeout(() => {
        resolve(rows);
      }, 1000);
    });

    return promise;
  }

  public getTaskData(
    _organizationId: Muid,
    _templateId: Muid,
    _checklistRevisionIds: Muid[],
    _taskGroupIds: Muid[],
  ): Promise<TaskDataRow[]> {
    return Promise.resolve([]);
  }

  public getAvatars(_request: GetAvatarsRequest): Promise<MembershipAvatarDto[]> {
    return Promise.resolve([]);
  }

  public getFormFieldValuesData(
    _organizationId: Muid,
    _templateId: Muid,
    _checklistRevisionIds: Muid[],
    _widgetGroupIds: Muid[],
  ): Promise<FormFieldDataRow[]> {
    return Promise.resolve([]);
  }

  public getSavedViewsByOrganizationId(_organizationId: Muid): Promise<SavedView[]> {
    const savedViews = mapWithIndex(20, index => generateSavedView({ name: `custom ${index}` }));
    return Promise.resolve(predefinedViews.concat(savedViews));
  }

  public saveSavedView(view: SavedView): Promise<SavedView> {
    return Promise.resolve({ ...view, saved: true });
  }

  public deleteSavedView(_organizationId: Muid, _viewId: Muid): Promise<void> {
    return Promise.resolve();
  }

  public getOverdueTasksByChecklistId(_checklistId: Muid): Promise<OverdueTaskDto[]> {
    const overdueTask: OverdueTaskDto = {
      assignees: generateAssignees(3),
      dueDate: 1,
      taskId: MuidUtils.randomMuid(),
      taskName: 'some task',
      taskTemplateGroupId: MuidUtils.randomMuid(),
      masked: false,
    };

    const promise: Promise<OverdueTaskDto[]> = new Promise(resolve => {
      window.setTimeout(() => {
        resolve([overdueTask]);
      }, 1000);
    });

    return promise;
  }

  public conditionalFilterTreeToList(
    _conditionalFilter: Filter,
    _organizationMembershipId: string,
    _result: Filter[],
  ): Filter[] {
    return [];
  }

  private getChecklistAndTemplateData(row: RowValues): NameValue & TemplateValue {
    return {
      ...(row[ChecklistColumn.ChecklistName] as NameValue),
      ...(row[ChecklistColumn.TemplateName] as TemplateValue),
    };
  }

  private getTestData(request: GetDataRequest): RowValues[] {
    let rows = this.allRows;

    if (request.filtering) {
      const pattern = request.filtering.searchPattern.trim().toLowerCase();

      if (pattern) {
        rows = rows.filter(row => {
          const value = this.getChecklistAndTemplateData(row);
          const checklistName = value.checklistName?.toLowerCase() ?? '';
          const templateName = value.templateName.toLowerCase();

          return checklistName.includes(pattern) || templateName.includes(pattern);
        });
      }

      const { selectedTemplates } = request.filtering;
      if (selectedTemplates.length) {
        rows = rows.filter(row => {
          const template = row[ChecklistColumn.TemplateName] as TemplateValue;
          return selectedTemplates.includes(template.templateId);
        });
      }
    }

    return !request.sorting ? rows : this.getSorted(rows, request.sorting.sortColumn, request.sorting.sortAsc);
  }

  private getSorted(rows: RowValues[], colId: string, sortAsc: boolean): RowValues[] {
    return rows.slice().sort((a, b) => {
      if ([ChecklistColumn.ChecklistName, ChecklistColumn.TemplateName].some(col => col === colId)) {
        const [aVal, bVal] = [a, b].map(this.getChecklistAndTemplateData);

        const compared = `${aVal.templateName}/${aVal.checklistName}`.localeCompare(
          `${bVal.templateName}/${bVal.checklistName}`,
        );
        return sortAsc ? compared : -compared;
      } else if (colId === ChecklistColumn.ProgressStatus) {
        const compared = (a[ChecklistColumn.ProgressStatus] as ProgressStatus).localeCompare(
          b[ChecklistColumn.ProgressStatus] as ProgressStatus,
        );
        return sortAsc ? compared : -compared;
      } else if (colId === ChecklistColumn.CompletedTasksCount) {
        const compared =
          (a[ChecklistColumn.CompletedTasksCount] as ProgressValue).completedTasks -
          (b[ChecklistColumn.CompletedTasksCount] as ProgressValue).completedTasks;
        return sortAsc ? compared : -compared;
      } else if (
        [ChecklistColumn.LastActiveDate as string, ChecklistColumn.OverdueTasksCount as string].includes(colId)
      ) {
        const compared = (a[colId] as number) - (b[colId] as number);
        return sortAsc ? compared : -compared;
      } else {
        return 0;
      }
    });
  }
}

export const testGridDataService = TestGridDataServiceImpl.getInstance();
