import {
  EmailFormFieldWidget,
  FieldType,
  MembersFormFieldWidget,
  TaskTemplate,
  Widget,
} from '@process-street/subgrade/process';
import {
  Group,
  GroupType,
  isOrganizationMembershipActive,
  Muid,
  OrganizationMembershipWithUser,
} from '@process-street/subgrade/core';
import { useSelector } from 'react-redux';
import { AssignmentPickerService } from 'pages/templates/services/assignment-picker-service';
import { useGetAllTaskTemplatesAssignmentsByTemplateRevisionIdQuery } from 'features/task-template-assignment/query-builder';
import { GetTaskAssignmentRulesByTemplateRevisionIdQuery } from 'features/task-assignment-rules/query-builder';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useGetAllGroupsQuery } from 'features/group/query-builder/get-all-groups';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { isStandardUserOrGroup } from '@process-street/subgrade/util/user-type-utils';
import { match, P } from 'ts-pattern';
import groupBy from 'lodash/groupBy';
import { useFeatureFlag } from 'features/feature-flags';

export const useAssignmentsQuery = (templateRevisionId: Muid, taskTemplates: TaskTemplate[]) => {
  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevisionId, {
    select: widgets => widgets.filter(Selectors.isEmailOrMembersFormFieldWidget),
  });
  const widgets = widgetsQuery.data ?? [];
  const isUserDeactivationEnabled = useFeatureFlag('deactivatingUsers');

  const assignmentRulesQuery = GetTaskAssignmentRulesByTemplateRevisionIdQuery.useQuery({ templateRevisionId });
  const assignmentRules = assignmentRulesQuery.data ?? [];

  const availableDynamicAssignments = AssignmentPickerService.getAvailableDynamicAssignments(
    widgets,
    assignmentRules,
    taskTemplates,
  );

  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const groupsQuery = useGetAllGroupsQuery({ include: 'user' });
  const groups = groupsQuery.data ?? [];

  const organizationMembershipsQuery = useGetAllOrganizationMembershipsQuery(
    { organizationId: selectedOrganizationId },
    {
      enabled: groupsQuery.isSuccess,
      select: organizationMemberships => {
        return Selectors.getStandardOrNonSystemGroupMemberships({ organizationMemberships, groups });
      },
    },
  );
  const organizationMemberships = (organizationMembershipsQuery.data ?? []).filter(om => {
    if (!isUserDeactivationEnabled) return true;

    return isOrganizationMembershipActive(om);
  });

  const taskAssignmentsQuery = useGetAllTaskTemplatesAssignmentsByTemplateRevisionIdQuery(
    { templateRevisionId },
    {
      select: assignments => groupBy(assignments, assignment => assignment.taskTemplate.id),
    },
  );
  const taskAssignmentsMap = taskAssignmentsQuery.data ?? {};
  const availableMemberships = AssignmentPickerService.getAvailableMemberships(
    organizationMemberships,
    taskAssignmentsMap,
    taskTemplates,
  );

  return {
    allRules: assignmentRules,
    availableDynamicAssignments,
    availableMemberships,
    allMembers: organizationMemberships,
  };
};

const Selectors = {
  isEmailOrMembersFormFieldWidget: (widget: Widget): widget is EmailFormFieldWidget | MembersFormFieldWidget =>
    match(widget)
      .with({ fieldType: P.union(FieldType.Email, FieldType.Members) }, () => true)
      .otherwise(() => false),
  getStandardOrNonSystemGroupMemberships: ({
    organizationMemberships,
    groups,
  }: {
    organizationMemberships: OrganizationMembershipWithUser[];
    groups: Group[];
  }) => {
    const systemGroups = groups.filter(group => group.groupType !== GroupType.Standard);
    const systemGroupUserIds = new Set(systemGroups.map(group => group.user.id));
    return organizationMemberships.filter(om => !systemGroupUserIds.has(om.user.id) && isStandardUserOrGroup(om.user));
  },
};

export { Selectors as UseAssignmentsQuerySelectors };
