import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Text,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from 'components/design/next';
import * as React from 'react';
import { useMergeTaggableInput } from 'hooks/use-merge-taggable-input';
import { MergeTagsMenu, MergeTagsMenuButton } from 'features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { Muid } from '@process-street/subgrade/core';
import { ToolbarButton, ToolbarTooltip } from 'pages/pages/_id/edit/page/components/common';
import {
  useLinkButton,
  TargetBlank,
  TargetSelf,
} from 'pages/pages/_id/edit/page/components/link-button/use-link-button';

export type LinkButtonProps = { templateRevisionId: Muid };

export const LinkButton: React.VFC<LinkButtonProps> = React.memo(({ templateRevisionId }) => {
  const {
    href,
    setHref,
    target,
    setTarget,
    linkActive,
    isOpen,
    onClose,
    onOpen,
    onMouseDown,
    removeLink,
    submit,
    linkEntry,
    restoreSelection,
    setForcedState,
  } = useLinkButton();

  const { ref: inputRef, insertMergeTag } = useMergeTaggableInput({
    get: () => href,
    set: setHref,
  });

  const mergeTagsMenuDisclosure = useDisclosure();

  return (
    <Popover
      isLazy={true}
      isOpen={isOpen}
      initialFocusRef={inputRef}
      closeOnBlur={!mergeTagsMenuDisclosure.isOpen}
      onClose={() => {
        restoreSelection();
        onClose();
        setForcedState('none');
      }}
      onOpen={onOpen}
    >
      <ToolbarTooltip label="Link">
        {/* Box needed to make tooltip work on popover trigger */}
        <Box>
          <PopoverTrigger>
            <ToolbarButton
              aria-label={linkActive ? 'Link applied to selection' : 'Link not applied to selection'}
              color={linkActive ? 'brand.300' : 'white'}
              rightIcon={<Icon icon="caret-down" variant="fas" size="3" />}
              onMouseDown={onMouseDown}
            >
              <Icon icon="link" size="4" />
            </ToolbarButton>
          </PopoverTrigger>
        </Box>
      </ToolbarTooltip>

      <PopoverContent w="80">
        <PopoverArrow />
        <PopoverBody>
          <InputGroup>
            <Input
              mb="2"
              placeholder="Enter URL"
              type="text"
              ref={inputRef}
              variant="outline"
              value={href}
              onChange={e => setHref(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  submit();
                }
              }}
            />
            <InputRightElement onClick={e => e.stopPropagation}>
              <MergeTagsMenu
                disclosure={mergeTagsMenuDisclosure}
                {...{
                  templateRevisionId,
                  onSelect: (key, _fieldId, fallback) => insertMergeTag(key, fallback),
                  mergeTagTarget: MergeTagsConstants.Target.GENERAL,
                  menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
                }}
              />
            </InputRightElement>
          </InputGroup>

          {href ? (
            <>
              <Switch
                id="link-target"
                isChecked={target === TargetBlank}
                onChange={() => setTarget(target === TargetBlank ? TargetSelf : TargetBlank)}
                size="lg"
                spacing="2"
                display="flex"
                alignItems="center"
              >
                <Text variant="-1" fontWeight="normal" color="gray.500" as="span">
                  Open in new window
                </Text>
              </Switch>

              <Divider mt="4" mb="2" />

              <HStack justifyContent="space-between">
                <Button variant="ghost" onClick={removeLink} isDisabled={!linkEntry} size="xs" color="black">
                  Remove link
                </Button>

                <Button variant="primary" onClick={() => submit()} size="sm">
                  Save
                </Button>
              </HStack>
            </>
          ) : null}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});
