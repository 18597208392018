import { GridOptions } from '@ag-grid-community/core';
import { ColumnType } from '@process-street/subgrade/dashboard';

import { FormSelectionHeaderRenderer } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers/SelectionHeaderRenderer';

const icons = {
  sortAscending: '<i class="far fa-sort-up"/>',
  sortDescending: '<i class="far fa-sort-down"/>',
  sortUnSort: '<i class="far fa-sort"/>',
};

const ROW_HEIGHT = 36;
const HEADER_HEIGHT = 36;

export const GRID_OPTIONS: GridOptions = {
  cacheBlockSize: 25,
  maxConcurrentDatasourceRequests: 1, // Important as we're using offset pagination in the grid-data-source
  rowModelType: 'infinite',
  rowSelection: 'multiple',
  maintainColumnOrder: true,
  // TODO isRowSelectable - permission
  headerHeight: HEADER_HEIGHT,
  rowHeight: ROW_HEIGHT,
  infiniteInitialRowCount: 5,
  suppressFieldDotNotation: true,
  unSortIcon: true,
  columnTypes: {
    [ColumnType.Checklist]: { icons },
    [ColumnType.FormField]: {
      sortable: false,
      width: 200,
    },
  },
  components: { SelectionHeaderRenderer: FormSelectionHeaderRenderer },
};
