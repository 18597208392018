import { LocalStorageService } from 'features/storage/local-storage-service';
import { trace } from 'components/trace';

const logger = trace({ name: 'SignUpStateService' });

const NAMESPACE = 'ps:signUp:';

const SOURCE_KEY = NAMESPACE + 'source';

const saveSource = (source: string | undefined): void => {
  const currentSource = LocalStorageService.getItem(SOURCE_KEY);
  if (source) {
    logger.info('setting source based on parameter', source);
    LocalStorageService.setItem(SOURCE_KEY, source);
  } else if (!currentSource) {
    const source = JSON.stringify({
      tid: 'referrer_header',
      url: window.document.referrer,
    });
    logger.info('setting source based on referrer', source);
    LocalStorageService.setItem(SOURCE_KEY, source);
  } else {
    logger.info('not setting source, already set before', currentSource);
  }
};

const getSource = (): string | undefined => LocalStorageService.getItem(SOURCE_KEY) ?? undefined;

const removeSource = (): void => LocalStorageService.removeItem(SOURCE_KEY);

const COUPON_KEY = NAMESPACE + 'coupon';

const saveCoupon = (coupon: string | undefined): void => {
  if (coupon) {
    logger.info(`setting coupon to "${coupon}"`);
    LocalStorageService.setItem(COUPON_KEY, coupon);
  }
};

const getCoupon = (): string | undefined => {
  const coupon = LocalStorageService.getItem(COUPON_KEY) ?? undefined;
  return coupon === 'undefined' ? undefined : coupon;
};

const removeCoupon = (): void => LocalStorageService.removeItem(COUPON_KEY);

const PLAN_ID_KEY = NAMESPACE + 'planId';

const savePlanId = (planId: string | undefined): void => {
  if (planId?.length === 22) {
    logger.info(`setting planId to "${planId}"`);
    LocalStorageService.setItem(PLAN_ID_KEY, planId);
  }
};

const getPlanId = (): string | undefined => {
  const planId = LocalStorageService.getItem(PLAN_ID_KEY) ?? undefined;
  return planId?.length === 22 ? planId : undefined;
};

const removePlanId = (): void => LocalStorageService.removeItem(PLAN_ID_KEY);

interface Params {
  [key: string]: any;
}

const PAGE_ID_KEY = NAMESPACE + 'pageId';
const TEMPLATE_ID_KEY = NAMESPACE + 'templateId';

const saveTemplateIds = (params: Params): void => {
  const { pageId, templateId } = params;
  if (pageId?.length === 22) {
    logger.info(`setting pageId to "${pageId}"`);
    LocalStorageService.setItem(PAGE_ID_KEY, pageId);
  }
  if (templateId?.length === 22) {
    logger.info(`setting templateId to "${templateId}"`);
    LocalStorageService.setItem(TEMPLATE_ID_KEY, templateId);
  }
};

interface TemplateIds {
  pageId: string | undefined;
  templateId: string | undefined;
}

const getTemplateIds = (): TemplateIds => ({
  pageId: LocalStorageService.getItem(PAGE_ID_KEY) ?? undefined,
  templateId: LocalStorageService.getItem(TEMPLATE_ID_KEY) ?? undefined,
});

const removeTemplateIds = (): void => {
  LocalStorageService.removeItem(PAGE_ID_KEY);
  LocalStorageService.removeItem(TEMPLATE_ID_KEY);
};

export const SignUpStateService = {
  saveSource,
  getSource,
  removeSource,
  saveCoupon,
  getCoupon,
  removeCoupon,
  savePlanId,
  getPlanId,
  removePlanId,
  saveTemplateIds,
  getTemplateIds,
  removeTemplateIds,
};
