import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { createSelector } from 'reselect';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { OrganizationDiscountSelector } from 'reducers/organization/organization-discount.selector';
import { isBillableUser } from '@process-street/subgrade/util/membership-utils';

const shouldShowAdditionalCost = (additionalCost: number, quantity: number, minQuantity: number) =>
  additionalCost > 0.1 && quantity >= minQuantity;

export const selector = createSelector(
  [
    OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId,
    SessionSelector.getCurrentPlan,
    SessionSelector.getSelectedOrganization,
    OrganizationDiscountSelector.getCurrentOrganizationAdditionalMemberCost,
    OrganizationMembershipSelector.getAllWithUserBySelectedOrganizationId,
  ],
  (currentOrganizationMembership, currentPlan, organization, additionalMemberCost, memberships) => {
    const currentMembersQuantity = memberships.filter(isBillableUser).length;

    return {
      userIsAdmin: currentOrganizationMembership?.role === OrganizationMembershipRole.Admin,
      currentOrganizationMembership,
      currentPlan,
      additionalMemberCost,
      organization,
      shouldShowAdditionalCost:
        !!organization &&
        !!currentPlan &&
        shouldShowAdditionalCost(
          additionalMemberCost,
          currentMembersQuantity,
          organization.minQuantity ?? currentPlan.minQuantity,
        ),
    };
  },
);
