import { Task } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type TaskQueryParams = { taskId: Task['id'] };

export type TaskQueryResponse = Task;

export const TaskQuery = {
  key: ['tasks'],
  getKey: (params: TaskQueryParams): QueryKey => [...TaskQuery.key, params],
  queryFn: ({ taskId }: TaskQueryParams) =>
    axiosService
      .getAxios()
      .get<TaskQueryResponse>(`/1/tasks/${taskId}`)
      .then(res => res.data),
};

export const useTaskQuery = <Select = TaskQueryResponse>(
  params: TaskQueryParams,
  options: UseQueryOptions<TaskQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(TaskQuery.getKey(params), () => TaskQuery.queryFn(params), options);
};
