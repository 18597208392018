import React from 'react';
import { useToast } from 'components/design/next';
import { HStack, Text, Button } from 'components/design/next';
import { FallbackProps } from 'react-error-boundary';
import { useEffectOnce } from 'react-use';
import { trace } from 'components/trace';

export type Props = FallbackProps;

export const ConditionalLogicErrorFallback: React.FC<React.PropsWithChildren<Props>> = ({
  error,
  resetErrorBoundary,
}) => {
  const toast = useToast();
  const logger = trace({ name: 'ConditionalLogicModal' });

  useEffectOnce(() => {
    logger.error(error);

    toast({
      title: "We're having problems loading the conditional logic rules",
      description: (
        <HStack>
          <Text letterSpacing="normal">Please try again. If the problem persists, contact support.</Text>

          <Button _hover={{ bgColor: 'transparent' }} onClick={() => resetErrorBoundary()} variant="ghost" size="sm">
            Retry
          </Button>
        </HStack>
      ),
      status: 'error',
    });
  });

  return null;
};
