import angular from 'angular';
import { CHECKLIST_PERMIT_DELETE, CHECKLIST_PERMIT_PUT } from 'reducers/checklist-permit/checklist-permit.actions';
import { CHECKLIST_DELETE, CHECKLIST_PUT } from 'reducers/checklist/checklist.actions';
import { ChecklistSelector } from 'reducers/checklist/checklist.selectors';
import { DataServiceConstants } from 'reducers/data-service/data-service-constants';
import { DataServiceActions } from 'reducers/data-service/data-service.actions';
import { dataServiceLatestEventSelector, dataServiceMetaSelector } from 'reducers/data-service/data-service.selectors';
import { EntitiesCollectionActions } from 'reducers/entities/entities.actions';
import { EntitiesSelectors } from 'reducers/entities/entities.selectors';
import { FOLDER_PERMIT_DELETE, FOLDER_PERMIT_PUT } from 'reducers/folder-permit/folder-permit.actions';
import { FolderSelector } from 'reducers/folder/folder.selectors';
import { GROUP_MEMBERSHIP_DELETE, GROUP_MEMBERSHIP_PUT } from 'reducers/group-membership/group-membership.actions';
import { GROUP_DELETE, GROUP_PUT } from 'reducers/group/group.actions';
import { TEMPLATE_PERMIT_DELETE, TEMPLATE_PERMIT_PUT } from 'reducers/template-permit/template-permit.actions';
import { TemplateSelector } from 'reducers/template/template.selectors';
import { trace } from 'components/trace';

class ReduxReadOnlyCollection {
  constructor($ngRedux, collection) {
    this.store = $ngRedux;
    this.collection = collection;

    this.selectors = new EntitiesSelectors(collection, this.store);
  }

  get = (id, includes) => this.selectors.calculateGet(id, includes);

  getAll = includes => this.filter(() => true, includes);

  find = (predicate, includes) => this.selectors.calculateFind(predicate, includes);

  some = (predicate, includes) => this.selectors.calculateSome(predicate, includes);

  filter = (predicate, includes) => this.selectors.calculateFilter(predicate, includes);
}

class ReduxCollection extends ReduxReadOnlyCollection {
  constructor($ngRedux, collection, putActionName, deleteActionName) {
    super($ngRedux, collection);

    this.store = $ngRedux;
    this.collection = collection;
    this.putActionName = putActionName;
    this.deleteActionName = deleteActionName;

    this.actions = EntitiesCollectionActions(this.collection, putActionName, deleteActionName);
    this.selectors = new EntitiesSelectors(collection, this.store);
  }

  put = object => this.actions.putToReduxDataStore(object)(this.store.dispatch);

  delete = id => {
    const element = this.selectors.calculateGet(id, []);
    this.store.dispatch(this.actions.removeFromReduxDataStore(id, element));
    return element;
  };

  onDeleteAndPut = (deleteCallback, putCallback) => {
    const latestCollectionEventSelector = dataServiceLatestEventSelector(this.collection);

    return this.store.subscribe(() => {
      const lastAction = latestCollectionEventSelector(this.store.getState());
      if (!lastAction) {
        return;
      }
      if (lastAction.type === this.putActionName) {
        lastAction.payload.forEach(putCallback);
      } else if (lastAction.type === this.deleteActionName) {
        deleteCallback(lastAction.meta.element);
      }
    });
  };
}

class ChecklistReduxCollection extends ReduxCollection {
  constructor($ngRedux) {
    super($ngRedux, DataServiceConstants.CHECKLISTS, CHECKLIST_PUT, CHECKLIST_DELETE);
  }

  getWithTemplateById = checklistId => ChecklistSelector.getWithTemplateById(checklistId)(this.store.getState());

  getById = checklistId => ChecklistSelector.getById(checklistId)(this.store.getState());
}

class ChecklistAssignmentReduxCollection extends ReduxReadOnlyCollection {
  constructor($ngRedux) {
    super($ngRedux, DataServiceConstants.CHECKLIST_ASSIGNMENTS);
  }
}

class TemplateReduxCollection extends ReduxReadOnlyCollection {
  constructor($ngRedux) {
    super($ngRedux, DataServiceConstants.TEMPLATES);
  }

  getOrphanTemplates = (organizationId, statuses) =>
    TemplateSelector.getAllOrphansByOrganizationIdAndTemplateStatuses(organizationId, statuses)(this.store.getState());
}

class FolderReduxCollection extends ReduxReadOnlyCollection {
  constructor($ngRedux) {
    super($ngRedux, DataServiceConstants.FOLDERS);
  }

  getOrphanFolders = organizationId =>
    FolderSelector.getAllOrphansByOrganizationId(organizationId)(this.store.getState());
}

export class DataService {
  static subscribe(unsubscribe) {
    if (DataService.unsubscibe) {
      DataService.unsubscibe();
    }
    DataService.unsubscibe = unsubscribe;
  }

  constructor($ngRedux) {
    'ngInject';

    const store = $ngRedux;
    this.logger = trace({ name: 'DataService' });

    const allCollections = [
      new ReduxReadOnlyCollection(store, DataServiceConstants.PLANS),
      new ReduxReadOnlyCollection(store, DataServiceConstants.USERS),
      new ReduxReadOnlyCollection(store, DataServiceConstants.ORGANIZATIONS),
      new ReduxReadOnlyCollection(store, DataServiceConstants.ORGANIZATION_MEMBERSHIPS),
      new ReduxCollection(store, DataServiceConstants.GROUPS, GROUP_PUT, GROUP_DELETE),
      new ReduxCollection(store, DataServiceConstants.GROUP_MEMBERSHIPS, GROUP_MEMBERSHIP_PUT, GROUP_MEMBERSHIP_DELETE),
      new FolderReduxCollection(store),
      new ReduxCollection(store, DataServiceConstants.FOLDER_PERMITS, FOLDER_PERMIT_PUT, FOLDER_PERMIT_DELETE),
      new TemplateReduxCollection(store, DataServiceConstants.TEMPLATES),
      new ChecklistReduxCollection(store),
      new ChecklistAssignmentReduxCollection(store),
      new ReduxCollection(store, DataServiceConstants.TEMPLATE_PERMITS, TEMPLATE_PERMIT_PUT, TEMPLATE_PERMIT_DELETE),
      new ReduxCollection(store, DataServiceConstants.CHECKLIST_PERMITS, CHECKLIST_PERMIT_PUT, CHECKLIST_PERMIT_DELETE),
    ];

    this.dataMap = allCollections.reduce((agg, reduxCollection) => {
      agg[reduxCollection.collection] = reduxCollection;
      return agg;
    }, {});

    const mapStateToThis = dataServiceMetaSelector;

    DataService.subscribe(store.connect(mapStateToThis, DataServiceActions)(this));
  }

  // Initialized

  clear = () => {
    this.clearDataStore();
  };

  isInitialized = () => this.initialized;

  isChecklistDataInitialized = () => this.checklistDataInitialized;

  getCollection = name => this.dataMap[name];
}

angular.module('frontStreetApp.services').service('DataService', DataService);
