import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { isT5KPlan } from 'services/plans';
import { canAccess, Feature } from 'services/features/features';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';

export const useCanRunWorkflows = (): boolean => {
  const plan = useSelector(SessionSelector.getCurrentPlan);
  const currentUserInfoQuery = useGetCurrentUserInfoQuery();

  if (!currentUserInfoQuery.data?.user) {
    return false;
  } else if (isAnonymousUser(currentUserInfoQuery.data.user)) {
    return true; // Anonymous case
  } else if (!plan) {
    return false;
  }

  const freeMembersCanRun = isT5KPlan(plan.id);
  const guestsCanRun = canAccess(Feature.GUESTS_CAN_CREATE_CHECKLISTS, plan.id);
  const allowedRoles = [OrganizationMembershipRole.Admin, OrganizationMembershipRole.FullMember];

  if (freeMembersCanRun) {
    allowedRoles.push(OrganizationMembershipRole.FreeMember);
  }
  if (guestsCanRun) {
    allowedRoles.push(OrganizationMembershipRole.Guest);
  }

  return allowedRoles.includes(currentUserInfoQuery.data.organizationMembership.role);
};
