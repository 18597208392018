import angular from 'angular';

angular.module('frontStreetApp.services').factory('Organizations', function (ResourceFactory) {
  return ResourceFactory.create({
    create: 'POST /1/organizations',
    getById: 'GET /1/organizations/:id',
    updateById: 'PATCH /1/organizations/:id',
    updateGrowSumoPartnerKeyById: 'PUT /1/organizations/:organizationId/grow-sumo-partner-key',
    updateDefaultSsoOrganizationMembershipLevelById:
      'PUT /1/organizations/:organizationId/default-sso-organization-membership-level',
    getCardById: 'GET /1/organizations/:id/card',
    getDiscountByOrganizationId: 'GET /1/organizations/:id/discount',
    setCardById: 'PUT /1/organizations/:id/card/:cardToken',
    setEmail: 'PUT /1/organizations/:id/email/:email',
    setCouponById: 'PUT /1/organizations/:id/coupon/:coupon',
    repairById: 'POST /1/organizations/:id/repair',
    updateSubscriptionPlanById: 'PATCH /1/organizations/:id/subscription',
    cancelSubscriptionById: 'PUT /1/organizations/:id/subscription',
    startSubscriptionTrialById: 'POST /1/organizations/:id/subscription/trial/:planId',
    endSubscriptionTrialById: 'DELETE /1/organizations/:id/subscription/trial',
    getAllApiKeysById: 'GET[] /1/organizations/:id/api-keys',
    createApiKeyById: 'POST /1/organizations/:id/api-keys',
    updateApiKeyByOrganizationIdAndApiKeyId: 'PATCH /1/organizations/:id/api-keys/:apiKeyId',
    deleteApiKeyByOrganizationIdAndApiKeyId: 'DELETE /1/organizations/:id/api-keys/:apiKeyId',
    getAllUsersWithNotMatchingEmailDomainById: 'GET[] /1/organizations/:id/users/non-matching-email-domain',
    getAllUsersWithMatchingEmailDomainAndMultipleEmailsById: 'GET[] /1/organizations/:id/users/multiple-emails',
    getAllUsersWithMatchingEmailDomainAndMultipleOrganizationsById:
      'GET[] /1/organizations/:id/users/multiple-organizations',
    lockToSsoByOrganizationId: 'PUT /1/organizations/:id/sso-lock',
  });
});
