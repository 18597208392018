import { Widget, TemplateRevision, TaskTemplate } from '@process-street/subgrade/process';
import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import {
  QueryClient,
  useQuery,
  QueryKey,
  UseQueryOptions,
  QueryObserver,
  QueryObserverResult,
  QueryObserverOptions,
} from 'react-query';
import { axiosService } from 'services/axios-service';

export type WidgetsByTemplateRevisionIdQueryResponse = Widget[];
export type WidgetsByTemplateRevisionIdQueryParams = TemplateRevision['id'] | undefined;

export const WidgetsByTemplateRevisionIdQuery = {
  key: ['template-revisions', 'widgets'],
  getKey: (params: WidgetsByTemplateRevisionIdQueryParams): QueryKey => [
    ...WidgetsByTemplateRevisionIdQuery.key,
    params,
  ],
  queryFn: (params: WidgetsByTemplateRevisionIdQueryParams) =>
    axiosService
      .getAxios()
      .get<WidgetsByTemplateRevisionIdQueryResponse>(`/1/template-revisions/${params}/widgets`)
      .then(res => res.data),
  makeCacheSetter: ({
    templateRevisionId,
    queryClient,
  }: {
    queryClient: QueryClient;
    templateRevisionId: TemplateRevision['id'];
  }) => {
    return {
      update: (widget: Widget) => {
        queryClient.setQueryData(
          WidgetsByTemplateRevisionIdQuery.getKey(templateRevisionId),
          (widgets: WidgetsByTemplateRevisionIdQueryResponse | undefined) => {
            if (!widgets) return [widget];
            let matchFound = false;
            const updated = widgets.map(w => {
              if (w.header.id === widget.header.id) {
                matchFound = true;
                return widget;
              }
              return w;
            });
            if (!matchFound) updated.push(widget);
            return updated;
          },
        );
      },
      add: (widget: Widget) => {
        return queryClient.setQueryData(
          WidgetsByTemplateRevisionIdQuery.getKey(templateRevisionId),
          (widgets: WidgetsByTemplateRevisionIdQueryResponse | undefined) => {
            return [...(widgets ?? []), widget];
          },
        );
      },
      delete: (widget: Widget) => {
        return queryClient.setQueryData(
          WidgetsByTemplateRevisionIdQuery.getKey(templateRevisionId),
          (widgets: WidgetsByTemplateRevisionIdQueryResponse | undefined) => {
            return widgets?.filter(w => w.header.id !== widget.header.id) ?? [];
          },
        );
      },
      deleteByTaskTemplates: (taskTemplates: TaskTemplate[]) => {
        return queryClient.setQueryData(
          WidgetsByTemplateRevisionIdQuery.getKey(templateRevisionId),
          (widgets: WidgetsByTemplateRevisionIdQueryResponse | undefined) => {
            return widgets?.filter(w => !taskTemplates.some(({ id }) => id === w.header.taskTemplate.id)) ?? [];
          },
        );
      },
    };
  },
};

export const useWidgetsByTemplateRevisionIdQuery = <Select = WidgetsByTemplateRevisionIdQueryResponse>(
  id: WidgetsByTemplateRevisionIdQueryParams,
  options: UseQueryOptions<WidgetsByTemplateRevisionIdQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(WidgetsByTemplateRevisionIdQuery.getKey(id), () => WidgetsByTemplateRevisionIdQuery.queryFn(id), {
    enabled: Boolean(id) && options.enabled !== false,
    ...options,
  });
};

export const makeGetWidgetsByTemplateRevisionIdObserver = <Select = WidgetsByTemplateRevisionIdQueryResponse>({
  templateRevisionId,
  queryClient,
  options,
}: {
  templateRevisionId?: Muid;
  queryClient: QueryClient;
  options?: QueryObserverOptions<WidgetsByTemplateRevisionIdQueryResponse, AxiosError, Select>;
}) => {
  return new QueryObserver<WidgetsByTemplateRevisionIdQueryResponse, AxiosError, Select>(queryClient, {
    queryKey: WidgetsByTemplateRevisionIdQuery.getKey(templateRevisionId!),
    queryFn: () => WidgetsByTemplateRevisionIdQuery.queryFn(templateRevisionId!),
    enabled: Boolean(templateRevisionId) && options?.enabled !== false,
    ...options,
  });
};

export type GetWidgetsByTemplateRevisionIdObserverResult = QueryObserverResult<
  WidgetsByTemplateRevisionIdQueryResponse,
  AxiosError
>;
