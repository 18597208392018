import { Folder, FolderType } from '@process-street/subgrade/process';

export function findParentFolder(folder: Folder, folders: Folder[]): Folder | undefined {
  if (hasParentFolder(folder)) {
    return folders.find(fld => fld.id === folder?.parentFolder?.id);
  }
}

/**
 * Includes the original folder plus its ancestors
 */
export function getAncestorsById({ folderId, folders }: { folderId: Folder['id']; folders: Folder[] }): Folder[] {
  const folder = folders.find(fl => fl.id === folderId);

  if (!folder) {
    // no access to folder, return public root
    const home = findOrganizationRootFolder(folders);
    return home ? [home] : [];
  }
  return [folder].concat(getAncestorFolders(folder, folders));
}

export function toSlugPath(folder: Folder, folders: Folder[]) {
  return [folder]
    .concat(folders)
    .reverse()
    .map(fl => fl.slug)
    .join('/');
}

/**
 * Based on some folderId, add breadcrumb paths to a list of folders.
 * @param folderId the folder to base the path upon
 * @returns folders with 'path' field
 */
export const getBreadcrumbPaths = (folderId: Folder['id']) => (folders: Folder[]) => {
  const ancestorsReversed = getAncestorsById({ folders, folderId }).reverse();
  return ancestorsReversed.map((folder, i: number) => {
    const ancestorsSlice = ancestorsReversed.filter((_fld: Folder, idx: number) => i > idx).reverse();
    const path = toSlugPath(folder, ancestorsSlice);
    return {
      ...folder,
      path,
    };
  });
};

/**
 * Hoists to public root if some ancestor is not found
 */
export function getAncestorFolders(folder: Folder, folders: Folder[]): Folder[] {
  if (!hasParentFolder(folder)) {
    // valid root
    return [];
  }
  const foundParentFolder = findParentFolder(folder, folders);
  if (!foundParentFolder) {
    // hoist to public root
    // in case the folder is orphaned (no access to some parent)
    const home = findOrganizationRootFolder(folders);
    return home ? [home] : [];
  }

  return [foundParentFolder].concat(getAncestorFolders(foundParentFolder, folders));
}

export const isPrivateFolder = (folder: Folder, folders: Folder[]) => {
  return isPrivateRootFolder(findRootFolderForFolder(folder, folders));
};

export function isPrivateRootFolder(folder: Folder) {
  return folder && !hasParentFolder(folder) && folder.folderType === FolderType.PrivateRoot;
}

export function isOrganizationRootFolder(folder: Folder) {
  return folder && !hasParentFolder(folder) && folder.folderType === FolderType.OrganizationRoot;
}

export function getNamePath(folder: Folder, folders: Folder[]) {
  return [folder]
    .concat(getAncestorFolders(folder, folders))
    .reverse()
    .map(fl => fl.name)
    .join(' / ');
}

export function getPathFolders(path: string, folders: Folder[]): Folder[] {
  const segments = path.split('/');
  const result = [];

  for (const segment of segments) {
    const folder = folders.find(f => f.slug === segment.toLowerCase());
    if (folder) {
      result.push(folder);
    } else {
      break;
    }
  }

  return result;
}

export const findPrivateRootFolder = (folders: Folder[]) => folders.find(isPrivateRootFolder);

export const findOrganizationRootFolder = (folders: Folder[]) => folders.find(isOrganizationRootFolder);

function findRootFolderForFolder(folder: Folder, folders: Folder[]) {
  const ancestors = getAncestorFolders(folder, folders);
  if (!ancestors.length) {
    // this is a root
    return folder;
  }
  const lastAncestor = ancestors.pop()!;
  return lastAncestor;
}

function hasParentFolder(folder: Folder) {
  return folder?.parentFolder?.id;
}

export function sanitizePathParam(pathParam?: string): string {
  return pathParam?.replace(/(?:%2F|%252F)/g, '/')?.replace(/%20/, ' ') ?? '';
}
