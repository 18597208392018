import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useDisclosure,
  VStack,
} from 'components/design/next';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  getRoleDescription,
  Roles,
  RolesMap,
  sameOrLessPrivilegeRoles,
} from 'pages/organizations/manage/users/components/role-selector/model';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { useRoleButtonWidth } from 'hooks/use-role-button-width';
import { SessionSelector } from 'reducers/session/session.selectors';

export type InvitationRoleSelectorProps = {
  availableRoles: OrganizationMembershipRole[];
  selectedRole: OrganizationMembershipRole;
  handleUserRoleSelected: (selectedRole: OrganizationMembershipRole) => void;
  isDisabled: boolean;
};

export const InvitationRoleSelector: React.FC<React.PropsWithChildren<InvitationRoleSelectorProps>> = ({
  selectedRole,
  handleUserRoleSelected,
  availableRoles = Roles,
  isDisabled = false,
}) => {
  const menuDisclosure = useDisclosure();

  const handleChange = (role: OrganizationMembershipRole) => () => handleUserRoleSelected(role);

  const buttonWidth = useRoleButtonWidth(selectedRole);

  const organizationMembership = useSelector(
    OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId,
  );
  const currentPlan = useSelector(SessionSelector.getCurrentPlan);
  const rolesToDisplay = sameOrLessPrivilegeRoles(availableRoles, organizationMembership?.role);

  return (
    <>
      <Menu autoSelect={false} isLazy={true} {...menuDisclosure}>
        <MenuButton
          as={Button}
          variant="unstyled"
          rightIcon={<Icon icon="angle-down" size="4" variant="far" />}
          title={isDisabled ? 'Selecting a role only applies to new users' : RolesMap[selectedRole].title}
          size="sm"
          color="gray.600"
          _focus={{ boxShadow: 'none' }}
          w={buttonWidth}
          textAlign="right"
          display="inline-flex"
          alignItems="center"
          isDisabled={isDisabled}
        >
          {RolesMap[selectedRole].title}
        </MenuButton>

        {menuDisclosure.isOpen ? (
          <Portal>
            <MenuList color="gray.600" right={0} width="295px" zIndex="popover">
              {rolesToDisplay.map(role => (
                <MenuItem
                  onClick={handleChange(role)}
                  key={role}
                  data-item-selected={role === selectedRole}
                  isDisabled={role === selectedRole}
                >
                  <HStack spacing="2">
                    <VStack w="full" align="left" spacing="0">
                      <Text fontWeight="medium">{RolesMap[role].title}</Text>
                      <Text fontSize="xs" color="gray.500" data-item-description={true}>
                        {getRoleDescription(role, currentPlan)}
                      </Text>
                    </VStack>
                  </HStack>
                </MenuItem>
              ))}
            </MenuList>
          </Portal>
        ) : null}
      </Menu>
    </>
  );
};
