import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { FieldType, isFormFieldWidget, Template } from '@process-street/subgrade/process';
import { formikMetaToFormControlProps } from 'app/components/widgets/form-field/settings/fields/utils';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { useField } from 'formik';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import * as React from 'react';

const ALLOWED_FIELD_TYPES = [FieldType.Text, FieldType.Hidden, FieldType.Textarea];

export const FormFieldSelector = ({ templateId }: { templateId: Template['id'] }) => {
  const [field, meta, helper] = useField<string | undefined>('widgetGroupId');
  const templateRevisionId = useNewestTemplateRevisionQuery({ templateId, editable: true }).data?.id;

  const { data: widgets, isLoading } = useWidgetsByTemplateRevisionIdQuery(templateRevisionId);

  const allowedWidgets = widgets
    ? widgets.filter(isFormFieldWidget).filter(widget => ALLOWED_FIELD_TYPES.includes(widget.fieldType))
    : [];
  const options = allowedWidgets.map(widget => ({
    value: widget.header.group.id,
    label: widget.label ?? widget.key,
  }));
  type OptionItem = typeof options[number];

  const value = options.find(option => option.value === field.value);

  React.useEffect(
    function setRemovedError() {
      if (!meta.error && !meta.touched && widgets && field.value && !value) {
        helper.setError('Field has been removed, select a different field');
        void helper.setTouched(true, false /* shouldValidate */);
      }
    },
    [options.length, value, meta.error, helper, meta.touched, isLoading, field.value, widgets],
  );

  return (
    <FormControl {...formikMetaToFormControlProps(meta)} isRequired>
      <FormLabel>Field with Page ID</FormLabel>
      <BlvdSelect<OptionItem>
        isDisabled={isLoading}
        isSearchable
        placeholder="Select field"
        options={options}
        value={value}
        onChange={item => item && helper.setValue(item.value)}
      ></BlvdSelect>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
