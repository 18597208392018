import { TaskTemplateUpdateResponse } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateTaskTemplateOrderTreesMutation {
  export type Params = {
    orderModels: { orderTree: string; taskTemplateGroupId: string; taskTemplateId: string }[];
  };

  export type Response = TaskTemplateUpdateResponse[];

  export const key = ['put', 'task-templates', 'order-tree'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/task-templates/order-tree`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
