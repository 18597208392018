import * as React from 'react';
import { Modal, ModalContent, ModalHeader, ModalOverlay, ModalBody, ModalCloseButton } from 'components/design/next';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';

export type DesignItemButtonProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

export const AddFileModal = ({ children, isOpen, onClose, title }: DesignItemButtonProps) => (
  <Modal size="lg" isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ThemeProvider2024>
        <ModalHeader fontSize="lg">{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>{children}</ModalBody>
      </ThemeProvider2024>
    </ModalContent>
  </Modal>
);
