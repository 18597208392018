import {
  ChecklistRuleDefinitionOperandType,
  ChecklistRuleDefinitionOperator,
  LogicalCondition,
  TaskCondition,
  TimeBasedCondition,
} from '@process-street/subgrade/conditional-logic';
import * as yup from 'yup';
import { ConditionalLogicUtils, NormalizedData } from './utils/conditional-logic-utils';

export function getRuleSchema(normalizedData: NormalizedData) {
  return yup.object().shape({
    orderTree: yup.string().required(),
    formFieldWidgetGroupId: yup.string().when('operator', {
      is: ChecklistRuleDefinitionOperator.LogicalOr,
      then: yup.string(),
      otherwise: yup.string().required(),
    }),
    operator: yup.string().oneOf(Object.values(ChecklistRuleDefinitionOperator)).required(),
    operand: yup
      .object()
      .when('operator', {
        is: operator =>
          operator !== ChecklistRuleDefinitionOperator.LogicalOr &&
          ConditionalLogicUtils.isOperandValueRequired(operator),
        then: (schema: yup.ObjectSchema) =>
          schema.shape({
            operandType: yup.string().required(),
            value: yup.string().required(),
          }),
        otherwise: (schema: yup.ObjectSchema) =>
          schema.shape({
            operandType: yup.string().required(),
          }),
      })
      .when('operator', {
        is: operator => operator === ChecklistRuleDefinitionOperator.LogicalOr,
        then: (schema: yup.ObjectSchema) =>
          schema.shape({
            operandType: yup.string().equals([ChecklistRuleDefinitionOperandType.Logical]),
            data: logicalCondition,
          }),
      })
      .required(),
    taskTemplateGroupIds: yup
      .array()
      .of(yup.string())
      .test({
        name: 'taskTemplateGroupIdsValid',
        test(v) {
          const value = v as string[] | undefined;
          const valueIsArray = Array.isArray(value);
          const hasWidgets = this.parent.widgetGroupIds.length > 0;
          const valuesCount = value?.length ?? 0;
          const valuesValid = valueIsArray && value?.every(groupId => groupId in normalizedData.tasks.byGroupId);

          // a rule must either have widgets or tasks selected
          return hasWidgets || (valuesCount > 0 && valuesValid);
        },
      }),

    widgetGroupIds: yup
      .array()
      .of(yup.string())
      .test({
        name: 'widgetGroupIdsIsValid',
        test(v) {
          const value = v as string[] | undefined;
          const valueIsArray = Array.isArray(value);
          const hasTasks = this.parent.taskTemplateGroupIds.length > 0;
          const valuesCount = (value as string[] | undefined)?.length ?? 0;
          const valuesValid = valueIsArray && value?.every(groupId => groupId in normalizedData.widgets.byGroupId);

          // a rule must either have widgets or tasks selected
          return hasTasks || (valuesCount > 0 && valuesValid);
        },
      }),
    hidden: yup.boolean().required(),
  });
}

const formFieldCondition = yup.object().shape({
  formFieldWidgetGroupId: yup.string().required(),
  operator: yup.string().required(),
  operandType: yup.string().required(),
  operandValue: yup
    .object()
    .when('operator', {
      is: operator => ConditionalLogicUtils.isOperandValueRequired(operator),
      then: (schema: yup.ObjectSchema) =>
        schema
          .shape({
            value: yup.lazy(value => {
              switch (typeof value) {
                case 'number':
                  return yup.number().required();
                case 'string':
                default:
                  return yup.string().required();
              }
            }),
          })
          .required(),
      otherwise: yup
        .object()
        .shape({ value: yup.mixed().equals([null]) })
        .required(),
    })
    .nullable(),
});

const taskCondition = yup.object().shape({
  taskTemplateGroupId: yup.string().when('operator', {
    is: ChecklistRuleDefinitionOperator.TaskStatusIs,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  operator: yup.string().required(),
  operand: yup.object().shape({
    operandType: yup.string().required(),
    value: yup.string().required(),
  }),
});

const timeBasedCondition = yup.object().shape({
  operator: yup.string().required(),
  dateType: yup.string().required(),
  operand: yup.object().shape({
    operandType: yup.string().required(),
    value: yup.string().required(),
  }),
});

const condition = yup.lazy(value => {
  if ((value as LogicalCondition)?.conditions) {
    return logicalCondition.default(undefined);
  } else if ((value as TaskCondition).taskTemplateGroupId) {
    return taskCondition;
  } else if ((value as TimeBasedCondition).dateType) {
    return timeBasedCondition;
  } else {
    return formFieldCondition;
  }
});

const logicalCondition: yup.ObjectSchema = yup.object().shape({
  operator: yup.string().oneOf([ChecklistRuleDefinitionOperator.LogicalAnd, ChecklistRuleDefinitionOperator.LogicalOr]),
  conditions: yup.array().of(condition),
});
