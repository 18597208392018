import * as React from 'react';
import { Divider, Text, VStack, Link } from 'components/design/next';
import { PartnerKeyField } from '../partner-key-field';

export type ReferralPartnerProgramProps = {
  onSave: (params: { growSumoPartnerKey?: string }) => void;
  isLoading: boolean;
};

export const ReferralPartnerProgram: React.FC<React.PropsWithChildren<ReferralPartnerProgramProps>> = ({
  onSave,
  isLoading,
}) => {
  return (
    <VStack spacing="6" alignItems="flex-start" w="full">
      <Text color="gray.500" variant="-2u">
        Referral partner program
      </Text>
      <Divider />

      <Text color="gray.700">
        Earn commission sharing your workflows with the world. Add your GrowSumo partner key here and any workflows you
        share or make public could earn you cash.{' '}
        <Link href={PARTNER_PROGRAM_DOCS_URL} color="brand.500" isExternal>
          Learn more »
        </Link>
      </Text>

      <PartnerKeyField onSave={onSave} isLoading={isLoading} />
    </VStack>
  );
};

const PARTNER_PROGRAM_DOCS_URL = 'https://www.process.st/help/docs/referral-program/';
