import { Selector } from 'reselect';
import { Muid, Option } from '../../core';
import { BaseReduxState } from '../types';

const getCurrentChecklistId: Selector<BaseReduxState, Option<Muid>> = (state: BaseReduxState): Option<Muid> =>
  state.modules.navigation.checklistId;

const getCurrentChecklistRevisionId: Selector<BaseReduxState, Option<Muid>> = (state: BaseReduxState): Option<Muid> =>
  state.modules.navigation.checklistRevisionId;

export const BaseNavigationSelector = {
  getCurrentChecklistId,
  getCurrentChecklistRevisionId,
};
