import * as React from 'react';
import { useEffect } from 'react';
import {
  TableFormFieldWidget as TTableFormFieldWidget,
  Template,
  TemplateType,
} from '@process-street/subgrade/process';
import {
  makeTableFormFieldMachine,
  TableFormFieldMachine,
  TableFormFieldWidget,
} from 'pages/forms/_id/edit/components/form-fields/table-form-field';
import { useMachine } from '@xstate/react';
import { useSharedContext } from 'pages/forms/_id/shared';

export type WorkflowTableFormFieldWidgetProps = {
  /** False in WF View mode, true in Edit mode */
  isEditable: boolean;
  widget: TTableFormFieldWidget;
  template: Template;
};

export const WorkflowTableFormFieldWidget = ({ isEditable, widget, template }: WorkflowTableFormFieldWidgetProps) => {
  const sharedContext = useSharedContext();
  const [state, send, actor] = useMachine<TableFormFieldMachine>(() =>
    makeTableFormFieldMachine({ widget, template, sharedContext }),
  );
  useEffect(() => {
    if (widget.required !== state.context.widget.required) {
      send({ type: 'SET_WIDGET', widget });
    }
  }, [send, state.context.widget.required, widget]);

  return (
    <TableFormFieldWidget
      actor={actor}
      isDisabled={!isEditable}
      isFirst={false}
      isLast={false}
      templateType={TemplateType.Playbook}
    />
  );
};
