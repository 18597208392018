import * as React from 'react';
import { Button, Icon, InputGroup, InputRightAddon, Textarea, Input, useToast } from 'components/design/next';

import ResizeTextarea from 'react-textarea-autosize';

import { Template } from '@process-street/subgrade/process';

import { SelectedVariables } from './component';
import { useCopyRunLink } from 'features/run-via-link/use-copy-run-link';

type RunLinkProps = {
  templateId: Template['id'];
  selectedVariables: SelectedVariables | null;
};
export const RunLink: React.FC<React.PropsWithChildren<RunLinkProps>> = ({ templateId, selectedVariables }) => {
  const { hasQueryParams, url, copyToClipboard } = useCopyRunLink({ templateId, selectedVariables });
  const toast = useToast();

  return (
    <InputGroup>
      {hasQueryParams ? (
        <Textarea
          minH="unset"
          overflow="hidden"
          w="100%"
          resize="none"
          minRows={1}
          as={ResizeTextarea}
          isReadOnly
          value={url}
        />
      ) : (
        <Input h="12" isReadOnly value={url} />
      )}
      <InputRightAddon h="12">
        <Button
          aria-label="copy run link"
          style={{ display: 'flex' }}
          onClick={() => {
            copyToClipboard();
            toast({
              description: 'Workflow run link copied to clipboard',

              status: 'success',
            });
          }}
          variant="unstyled"
          color="gray.700"
          fontWeight="bold"
          leftIcon={<Icon variant="far" icon="copy" size="4" color="gray.700" />}
        >
          Copy
        </Button>
      </InputRightAddon>
    </InputGroup>
  );
};
