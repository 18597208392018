import { DateFormFieldWidget } from '@process-street/subgrade/process';
import * as React from 'react';
import {
  Button,
  ButtonGroup,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  VStack,
  useModalContext,
} from 'components/design/next';
import { Form, Formik } from 'formik';
import { DateSelector } from 'components/widgets/form-field/settings/date-settings/date-selector';
import { useWidgetSettingsContext } from '../widget-settings-context';

type DateFormFieldSettings = Pick<DateFormFieldWidget, 'config' | 'constraints'>;

export const DateSettings = () => {
  const { widget, onUpdate } = useWidgetSettingsContext<DateFormFieldWidget>();
  const { onClose } = useModalContext();

  const initialValues = {
    config: widget.config,
    constraints: widget.constraints,
  };

  const handleSubmit = ({ config, constraints }: DateFormFieldSettings) => {
    const updatedData = { config, constraints };
    onUpdate({ ...widget, ...updatedData });
    onClose();
  };

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader p={8}>
        <Text variant="2">{widget.label || 'Untitled date field'}</Text>
      </ModalHeader>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isValid, dirty, values }) => (
          <Form>
            <ModalBody px={9} py={2}>
              <VStack spacing={4}>
                <DateSelector
                  condition="after"
                  dayPickerProps={{
                    disabled: values.constraints.beforeDate
                      ? { after: new Date(values.constraints.beforeDate!) }
                      : undefined,
                  }}
                />
                <DateSelector
                  condition="before"
                  dayPickerProps={{
                    disabled: values.constraints.afterDate
                      ? { before: new Date(values.constraints.afterDate!) }
                      : undefined,
                  }}
                />
              </VStack>
            </ModalBody>
            <ModalFooter p={6}>
              <ButtonGroup>
                <Button aria-label="cancel changes" variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button aria-label="set constraints" isDisabled={!(isValid && dirty)} type="submit">
                  Apply
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};
