import * as React from 'react';
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, InputProps } from 'components/design/next';

export type TextInputFieldProps = InputProps & {
  error?: string;
  helperText?: string;
  label: string;
};

export const TextInputField: React.VFC<TextInputFieldProps> = ({ error, helperText, label, ...inputProps }) => {
  const { isRequired, name } = inputProps;
  const invalid = Boolean(error);

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <FormLabel mb="2.5" htmlFor={`text-input-${name}`}>
        {label}
      </FormLabel>

      <Input
        aria-describedby={`text-input-${name}-error`}
        backgroundColor="white"
        borderColor="gray.300"
        borderRadius="base"
        borderWidth="thin"
        color="gray.700"
        id={`text-input-${name}`}
        height="12"
        type="text"
        {...inputProps}
      />
      {error && (
        <FormErrorMessage position="absolute" id={`text-input-${name}-error`} role="alert">
          {error}
        </FormErrorMessage>
      )}

      {!invalid && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
