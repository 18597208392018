export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

// copied from ramda: https://github.com/ramda/ramda/blob/v0.28.0/source/sortWith.js
export function sortWith<T>(list: T[], fns: Array<(a: T, b: T) => -1 | 1 | 0>): T[] {
  return Array.prototype.slice.call(list, 0).sort(function (a, b) {
    let result = 0;
    let i = 0;
    while (result === 0 && i < fns.length) {
      result = fns[i](a, b);
      i += 1;
    }
    return result;
  });
}

export function comparator<T>(pred: (a: T, b: T) => boolean): (a: T, b: T) => -1 | 1 | 0 {
  return (a, b) => {
    return pred(a, b) ? -1 : pred(b, a) ? 1 : 0;
  };
}
