import { Checklist } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace DeleteLabelRelationshipByChecklistIdMutation {
  export type Params = {
    checklistId: Checklist['id'];
  };

  export type Response = {};

  export const key = ['delete', 'labelRelationship', 'byChecklistId'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .delete<Response>(`/1/label-relationships/by-checklist/${params.checklistId}`)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
