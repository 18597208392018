import angular from 'angular';
import { WidgetConstants } from '@process-street/subgrade/process/widget-constants';
import { UploadedItemType } from 'components/file-upload/types';
import { FileUploadConstants } from '../file-upload/file-upload-constants';
import templateUrl from './template-video-widget.component.html';
import './template-video-widget.scss';
import { StringService } from 'services/string-service';
import { getEnv } from 'components/common/env';
import { EventName } from 'services/event-name';
import { parseEmbedServiceValue } from '@process-street/subgrade/util/embed-service-code-parser';

angular.module('frontStreetApp.directives').component('psTemplateVideoWidget', {
  bindings: {
    widget: '<',
    disabled: '<',
    onUpdate: '&',
  },
  templateUrl,
  controller($rootScope, $scope, $timeout, $ngRedux, FileUploadService, WidgetService) {
    const ctrl = this;

    ctrl.videoMimeTypes = WidgetConstants.VIDEO_MIME_TYPES;
    ctrl.videoMaxFileSize = WidgetConstants.VIDEO_MAX_FILE_SIZE;
    ctrl.add = function (__event, data) {
      // Disable autoUpload so we can get the promise from submit
      data.autoUpload = false;

      $timeout(() => {
        data.process(function () {
          return this.process(data);
        });
      });
    };

    ctrl.done = function (_, data) {
      ctrl.widget.service = 'Wistia';

      // One time the Wistia API started returning the result with text/plain, so we need to be defensive
      const result = angular.isString(data.result) ? JSON.parse(data.result) : data.result;
      ctrl.widget.serviceCode = result.hashed_id;

      ctrl.onUpdate({ widget: ctrl.widget });

      // Clean up
      FileUploadService.finishUpload(ctrl.widget);

      delete ctrl.uploadProgress;
    };

    ctrl.fail = function () {
      $rootScope.$broadcast(EventName.WIDGET_UPLOAD_FAILED, ctrl.widget);

      // Clean up

      delete ctrl.widget.file;
      delete ctrl.widget.description;

      FileUploadService.finishUpload(ctrl.widget);
      delete ctrl.uploadProgress;
    };

    ctrl.progress = function (_, data) {
      ctrl.uploadProgress = parseInt((data.loaded / data.total) * 100, 10);
    };

    ctrl.processDone = function (_, data) {
      data.multipart = true;
      data.url = getEnv().WISTIA_UPLOAD_URL;
      data.formData = {
        api_password: getEnv().WISTIA_API_PASSWORD,
        project_id: getEnv().WISTIA_PROJECT_ID,
      };

      // Start the upload
      $timeout(() => {
        FileUploadService.submitUpload(data, ctrl.widget, UploadedItemType.Widget);
      });

      ctrl.uploadProgress = 0;
    };

    ctrl.processFail = function (_, data) {
      let message;
      const { error } = data.files[0];
      switch (error) {
        case FileUploadConstants.Error.FILE_TOO_LARGE: {
          const prettyMaxFileSize = StringService.getPrettySize(data.maxFileSize);
          message = `Sorry, the video must be smaller than ${prettyMaxFileSize}.`;
          break;
        }
        case FileUploadConstants.Error.FILE_TYPE_NOT_ALLOWED:
          message =
            'Sorry, you can only upload ' +
            'MOV, MPG, AVI, FLV, F4V, MP4, M4V, ASF, WMV, VOB, MOD, 3GP, MKV, DIVX, and XVID videos.';
          break;
        default:
          message = 'Sorry, we were unable to upload your video.';
      }

      $rootScope.$broadcast(EventName.WIDGET_UPLOAD_INVALID_FILE_TYPE, message);

      if (ctrl.widget) {
        delete ctrl.widget.file;
      }
    };

    ctrl.updateWidget = function () {
      ctrl.onUpdate({ widget: ctrl.widget });
    };

    // Service code parser

    ctrl.service = { value: '' };
    $scope.$watch(
      () => ctrl.service && ctrl.service.value,
      value => {
        const result = parseEmbedServiceValue(value);
        if (result) {
          const { widget } = ctrl;
          widget.service = result.service;
          widget.serviceCode = result.serviceCode;
          ctrl.onUpdate({ widget });
        }
      },
    );

    ctrl.getServiceUrl = function () {
      const { service, serviceCode } = ctrl.widget;
      return WidgetService.generateServiceUrl(service, serviceCode);
    };

    // For drops

    $scope.$on(EventName.WIDGET_DROP_ADD, (_, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.add(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_DONE, (_, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.done(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_FAIL, (_, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.fail(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_PROCESS_DONE, (_, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.processDone(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_PROCESS_FAIL, (_, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.processFail(null, data);
      }
    });

    $scope.$on(EventName.WIDGET_DROP_PROGRESS, (_, data) => {
      if (ctrl.widget === data._widget) {
        ctrl.progress(null, data);
      }
    });
  },
});
