import { CookieService } from 'features/cookies/cookie-service';
import { dayjs as moment } from '@process-street/subgrade/util/dayjs';
import { match } from 'ts-pattern';
import { getEnv } from 'components/common/env';

const MASQUERADE_KEY = match(getEnv().APP_STAGE)
  .with('test', () => `st.staging.app.masquerade`)
  .otherwise(() => `st.process.app.masquerade`);

let masquerading = !!CookieService.getSimpleCookie(MASQUERADE_KEY);

export namespace MasqueradeService {
  export function trackStart() {
    masquerading = true;
    // Set to 1 hour because that's how long the token lasts
    CookieService.setSimpleCookie(MASQUERADE_KEY, 'true', { expires: moment().add(1, 'hour').toDate() });
  }
  export function trackStop() {
    masquerading = false;
    CookieService.removeSimpleCookie(MASQUERADE_KEY);
  }
  export function isMasquerading() {
    return masquerading;
  }
}
