import { Muid } from '@process-street/subgrade/core';
import { Widget, Template, TemplateRevision } from '@process-street/subgrade/process';
import { axiosService } from 'services/axios-service';

export type UpdateTemplateForm = {
  name: string;
  description?: string;
};

const getTemplate = async (templateId: Muid): Promise<Template> => {
  return axiosService
    .getAxios()
    .get<Template>(`/1/templates/${templateId}`)
    .then(res => res.data);
};

const updateTemplate = async (templateId: Muid, body: UpdateTemplateForm): Promise<Template> => {
  return axiosService
    .getAxios()
    .put<Template>(`/1/templates/${templateId}`, body)
    .then(res => res.data);
};

/**
 * This returns a tuple with the latest finished template revision in [0]
 * and the latest draft template revision at [1] (if one exists)
 */
const getNewestTmplRevisionsByTmplId = (templateId: Muid): Promise<TemplateRevision[]> =>
  axiosService
    .getAxios()
    .get<TemplateRevision[]>(`/1/templates/${templateId}/template-revisions/newest`)
    .then(res => res.data);

const getWidgetsByTmplRevisionId = (templateRevisionId: Muid): Promise<Widget[]> =>
  axiosService
    .getAxios()
    .get<Widget[]>(`/1/template-revisions/${templateRevisionId}/widgets`)
    .then(res => res.data);

const createDraft = async (templateId: Muid): Promise<TemplateRevision> => {
  return axiosService
    .getAxios()
    .post<TemplateRevision>(`/1/template-revisions`, { templateId })
    .then(res => res.data);
};

const discardDraft = async (tmplRevId: Muid): Promise<TemplateRevision> => {
  return axiosService
    .getAxios()
    .delete<TemplateRevision>(`/1/template-revisions/${tmplRevId}`)
    .then(res => res.data);
};

const deleteTemplate = async (templateId: Muid): Promise<void> => {
  return axiosService
    .getAxios()
    .delete(`/1/templates/${templateId}`)
    .then(res => res.data);
};

export const API = {
  createDraft,
  discardDraft,
  deleteTemplate,
  getTemplate,
  updateTemplate,
  getNewestTmplRevisionsByTmplId,
  getWidgetsByTmplRevisionId,
};
