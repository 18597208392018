import { useHref } from 'app/adapters/navigation';
import { Box, Icon, IconButton, Link } from 'components/design/next';
import React from 'react';
import { UnreadCommentsBadge } from './topbar';

export const CommentsButton = ({ shouldShowUnreadCommentsBadge }: { shouldShowUnreadCommentsBadge: boolean }) => {
  const href = useHref('comments');

  return (
    <Box position="relative">
      <IconButton
        href={href}
        aria-label="comments"
        icon={<Icon variant="far" icon="comment" size="4" />}
        variant="ghost"
        colorScheme="brand"
        as={Link}
      />
      {shouldShowUnreadCommentsBadge && <UnreadCommentsBadge top={2} left={6} />}
    </Box>
  );
};
