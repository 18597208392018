import { useCanUseChecklistQuery } from './query';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { useInjector } from 'components/injection-provider';
import { useOrganizationMembershipRole } from '../use-organization-membership-role';
import { useOnPlanTrack } from 'services/use-plan-track';
import { PlanTrack } from '@process-street/subgrade/billing';

export const useShouldBlockUseChecklist = (): {
  shouldBlock: boolean;
  isLoading: boolean;
  role: OrganizationMembershipRole | undefined;
} => {
  const { $stateParams } = useInjector('$stateParams');
  const { id } = $stateParams;
  const onFreemium = useOnPlanTrack(PlanTrack.Freemium);
  const isChecklistQueryEnabled = onFreemium;
  const role = useOrganizationMembershipRole();
  const { data, isLoading: isChecklistQueryLoading } = useCanUseChecklistQuery(id, {
    enabled: isChecklistQueryEnabled,
  });
  const shouldBlockAction = Boolean(isChecklistQueryEnabled && role && data && !data.canUse);
  const isLoading = !role || (isChecklistQueryEnabled && isChecklistQueryLoading);
  return { shouldBlock: shouldBlockAction, isLoading, role };
};
