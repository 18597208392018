import angular from 'angular';
import { QResources, ResourceFactory } from 'app/resources/factory/resource-factory';

angular
  .module('frontStreetApp.services')
  .factory('Authentication', function (ResourceFactory: ResourceFactory): QResources<unknown> {
    return ResourceFactory.create({
      signUp: {
        method: 'POST',
        url: '/1/authentication/sign-up',
        withCredentials: true,
      },
      complete: 'POST /1/authentication/complete',
      masquerade: 'POST /1/authentication/masquerade',
      signUpByToken: {
        method: 'POST',
        url: '/1/authentication/sign-up/auth0',
        withCredentials: true,
      },
      getConnectionName: 'GET /1/authentication/auth0/connection/:email',
      authenticateAnonymous: 'POST /1/authentication/anonymous',
    });
  });
