// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../images/signup/ps-logo.svg");
var ___HTML_LOADER_IMPORT_1___ = require("../../images/signup/drift.svg");
var ___HTML_LOADER_IMPORT_2___ = require("../../images/signup/gap.svg");
var ___HTML_LOADER_IMPORT_3___ = require("../../images/signup/salesforce.svg");
var ___HTML_LOADER_IMPORT_4___ = require("../../images/signup/colliers.svg");
var ___HTML_LOADER_IMPORT_5___ = require("../../images/signup/gov-of-canada.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var code = "<div class=\"left-panel\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" class=\"left-panel__ps-logo\" alt=\"logo\"/> <h2 class=\"hidden-xs left-panel__subtitle\">AI-Powered Process&nbsp;Management</h2> <h3 class=\"left-panel--opacity hidden-xs hidden-sm left-panel__subsubtitle\"> Join over 1 million Process&nbsp;Street&nbsp;Users </h3> <ul class=\"left-panel__testimonials left-panel--opacity hidden-xs hidden-sm\"> <li><img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"image\"/></li> <li><img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"image\"/></li> <li><img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"image\"/></li> <li><img src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" alt=\"image\"/></li> <li><img src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\" alt=\"image\"/></li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/features/user/sign-up-left-panel.component.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;