import * as React from 'react';
import { fieldChecklistColumnNameMap, useFormResponsesStore } from '../../store';
import { ColDef, SortChangedEvent } from '@ag-grid-community/core';
import { ChecklistGridData } from '@process-street/subgrade/dashboard';
import { Option } from 'space-monad';

export function useSortChangedHandler<T extends ChecklistGridData = ChecklistGridData>() {
  const setSorting = useFormResponsesStore(state => state.setSorting);

  const handleSortChanged = React.useCallback(
    (e: SortChangedEvent<T>) => {
      const sortCol = e.api
        .getColumnDefs()
        ?.filter((colDef): colDef is ColDef => !('children' in colDef))
        .find(cd => cd.sort);

      const sorting = Option(sortCol)
        .map(({ field, sort }) => ({
          sortBy: fieldChecklistColumnNameMap[field as keyof typeof fieldChecklistColumnNameMap],
          sortAsc: sort === 'asc',
        }))
        .get();
      setSorting(sorting);
    },
    [setSorting],
  );

  return handleSortChanged;
}
