import { SessionSelector } from 'reducers/session/session.selectors';
import { selectedOrganizationMembershipWithUsersNoSystemGroupsSelector } from 'reducers/group/group.selectors';
import { TemplateTaskAssignmentSelector } from 'components/template-task-assignment/store/template-task-assignment.selector';
import { connectController } from 'reducers/util';
import { RoleAssignmentRuleSelector } from 'components/role-assignments/store/role-assignment-rules.selector';
import groupBy from 'lodash/groupBy';
import templateUrl from './template-task-bulk-assigner.component.html';
import './template-task-bulk-assigner.scss';

export const TemplateTaskBulkAssignerComponent = {
  bindings: {
    user: '<',
    templateRevision: '<',
    taskTemplates: '<',
  },
  templateUrl,
  controller: class TemplateTaskBulkAssignerComponent {
    helpText = 'Search for a person in your organization by name or email address.';

    constructor($ngRedux, OrganizationMembershipActions, RoleAssignmentRulesActions, TemplateTaskAssignmentActions) {
      'ngInject';

      this.$ngRedux = $ngRedux;
      this.OrganizationMembershipActions = OrganizationMembershipActions;
      this.RoleAssignmentRulesActions = RoleAssignmentRulesActions;
      this.TemplateTaskAssignmentActions = TemplateTaskAssignmentActions;
    }

    $onInit() {
      this.templateRevisionId = this.templateRevision.id;

      const mapStateToThis = () => state => {
        const selectedOrganizationId = SessionSelector.getSelectedOrganizationId(state);
        const organizationMemberships = selectedOrganizationMembershipWithUsersNoSystemGroupsSelector(state);
        const taskAssignmentsMap = TemplateTaskAssignmentSelector.getTaskTemplateIdAssignmentsMapByTemplateRevisionId(
          this.templateRevisionId,
        )(state);

        const assignees = this._getAssigneesForAllSelectedTaskTemplates(organizationMemberships, taskAssignmentsMap);

        const allAssignmentRules = RoleAssignmentRuleSelector.getAllByTemplateRevisionId(this.templateRevisionId)(
          state,
        );
        const groupedAssignmentRules = this._groupAllAssignmentRuleByTaskTemplateGroupId(allAssignmentRules);

        return { selectedOrganizationId, assignees, groupedAssignmentRules };
      };

      const mapDispatchToThis = () => ({
        getAllTemplateTaskAssignments: this.TemplateTaskAssignmentActions.getAllByTemplateRevisionId,
        getAllOrgMembershipByOrgId: this.OrganizationMembershipActions.getAllOrgMembershipByOrgId,
        geAllRulesByTemplateRevisionId: this.RoleAssignmentRulesActions.getAllByTemplateRevisionId,
      });

      connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, this.shouldChange)(this);

      this.actions.getAllOrgMembershipByOrgId(this.state.selectedOrganizationId);
      this.actions.getAllTemplateTaskAssignments(this.templateRevisionId);
      this.actions.geAllRulesByTemplateRevisionId(this.templateRevisionId);
    }

    shouldChange = changes =>
      (changes.templateRevisionId && changes.templateRevisionId.currentValue) ||
      (changes.taskTemplates && changes.taskTemplates.currentValue);

    isAnyAssigned = () =>
      (this.state && this.state.assignees && this.state.assignees.length) ||
      this._getSelectedTaskTemplatesAssignmentRules().length;

    _groupAllAssignmentRuleByTaskTemplateGroupId = allAssignmentRules =>
      groupBy(allAssignmentRules, rule => rule.targetTaskTemplateGroup?.id);

    _getSelectedTaskTemplatesAssignmentRules() {
      let rules = [];
      if (this.state && this.state.groupedAssignmentRules) {
        this.taskTemplates.forEach(tt => {
          rules = rules.concat(this.state.groupedAssignmentRules[tt.group.id] || []);
        });
      }
      return rules;
    }

    _getAssigneesForAllSelectedTaskTemplates(organizationMemberships, taskAssignmentsMap) {
      const organizationMembershipMap = organizationMemberships.reduce((map, om) => {
        map[om.id] = om;

        return map;
      }, {});

      let assignees = this.taskTemplates.reduce((assigneesList, taskTemplate) => {
        const templateTaskAssignments = taskAssignmentsMap[taskTemplate.id] || [];

        templateTaskAssignments.forEach(tta => {
          const membership = organizationMembershipMap[tta.organizationMembership.id];

          if (membership) {
            assigneesList.push(membership.user);
          }
        });

        return assigneesList;
      }, []);

      // We need only unique users
      assignees = assignees.filter((user, i, users) => users.indexOf(user) === i);
      return assignees;
    }
  },
};
