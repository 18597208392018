import { ELEMENT_LINK } from '@udecode/plate-link';
import { collapseSelection, findNode, isElement, select, unwrapNodes, wrapNodes } from '@udecode/slate';
import { focusEditor } from '@udecode/slate-react';
import { useDisclosure } from 'components/design/next';
import * as React from 'react';
import { PagesLinkElement, usePagesEditorRef } from '../../pages-plate-types';
import { useSelectionContext } from '../../selection-context';
import { useBalloonToolbarControls } from '../balloon-toolbar/context';
import { useToolbarMenuButtonOnMouseDown } from '../balloon-toolbar/menu/use-toolbar-menu-button-on-mouse-down';

export const Type = ELEMENT_LINK;
export const TargetBlank = '_blank';
export const TargetSelf = '_self';

const defaultTarget = TargetBlank;

export const useLinkButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const editor = usePagesEditorRef();

  const [linkEntry, setLinkEntry] = React.useState(findNode<PagesLinkElement>(editor, { match: { type: Type } }));
  React.useEffect(() => {
    if (editor.selection) {
      setLinkEntry(findNode<PagesLinkElement>(editor, { match: { type: Type } }));
    }
  }, [editor, editor.selection]);
  const linkActive = !!linkEntry || isOpen;

  const { setForcedState } = useBalloonToolbarControls();
  const { lastSelection } = useSelectionContext();

  const [href, setHref] = React.useState('');
  const [target, setTarget] = React.useState(defaultTarget);

  React.useEffect(() => {
    if (linkEntry && linkEntry[0]) {
      const [{ href, target }] = linkEntry;
      setHref(href);
      setTarget(target);
    } else {
      setHref('');
      setTarget(defaultTarget);
    }
  }, [linkEntry]);

  const [submitAttempted, setSubmitAccepted] = React.useState(false);

  const restoreSelection = () => {
    focusEditor(editor);
    if (lastSelection) {
      select(editor, lastSelection);
    }
  };

  const submit = ({ validationMessage }: { validationMessage?: string } = {}) => {
    setSubmitAccepted(true);
    if (validationMessage || !lastSelection) return;
    restoreSelection();
    if (!editor.selection) return;

    const prefixedHref =
      href.startsWith('http://') || href.startsWith('https://') || href.startsWith('{{') ? href : `http://${href}`;

    unwrapLink();
    wrapNodes(
      editor,
      { type: Type, href: prefixedHref, target, rel: 'nofollow', children: [] },
      { at: editor.selection, split: true },
    );
    collapseSelection(editor, { edge: 'end' });
    setForcedState('none');
    onClose();
  };

  const unwrapLink = () => {
    if (linkEntry && lastSelection) {
      unwrapNodes(editor, {
        at: lastSelection,
        match: n => isElement(n) && n.type === Type,
      });
    }
  };

  const removeLink = () => {
    restoreSelection();
    unwrapLink();
    setForcedState('none');
  };

  React.useEffect(() => {
    if (isOpen) {
      setForcedState('open');
    } else {
      setSubmitAccepted(false);
      setHref('');
      setTarget(defaultTarget);
    }
  }, [isOpen, editor, setForcedState]);

  const onMouseDown = useToolbarMenuButtonOnMouseDown();

  return {
    href,
    setHref,
    target,
    setTarget,
    linkActive,
    isOpen,
    onClose,
    onOpen,
    onMouseDown,
    removeLink,
    submit,
    linkEntry,
    restoreSelection,
    setForcedState,
    submitAttempted,
  };
};
