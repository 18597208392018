import { OrganizationMembership } from '@process-street/subgrade/core';
import { SavedView, SavedViewAccessLevel } from 'components/dashboard/models/saved-views';
import { SavedViewEditorMode } from './SavedViewEditor';

export function copyView(
  view: SavedView,
  canSavePredefined: boolean,
  mode: SavedViewEditorMode,
  organizationMembership: OrganizationMembership,
) {
  const sourceSavedViewId = view.predefined && !canSavePredefined ? view.id : undefined;
  const organizationLevelDisabled =
    view.accessLevel === SavedViewAccessLevel.Organization && !organizationMembership.admin;
  const isNewView = mode === SavedViewEditorMode.NEW;
  const accessLevel = isNewView || organizationLevelDisabled ? SavedViewAccessLevel.Private : view.accessLevel;
  const name = isNewView ? '' : view.name;

  return {
    ...view,
    accessLevel,
    name,
    organizationMembershipIds: view.organizationMembershipIds || [],
    sourceSavedViewId,
  };
}
