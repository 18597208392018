import { Checklist } from '@process-street/subgrade/process';
import { trace } from 'app/components/trace';
import { GetChecklistQuery } from 'app/features/checklists/query-builder';
import { GetInboxItemsQuery } from 'app/features/microsoft-teams/query-builder';
import { AblyEvent } from 'app/pusher/ably-event';
import { ablyService } from 'app/pusher/ably.service';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

const logger = trace({ name: 'useListenToWorkflowRunUpdated' });

export const useListenToWorkflowRunUpdated = (
  checklistId: Checklist['id'],
  options: {
    shouldUpdateInboxQuery?: boolean;
  } = {},
) => {
  const queryClient = useQueryClient();
  const { shouldUpdateInboxQuery } = options;

  useEffect(
    function listenToWorkflowRunUpdated() {
      const channelName = ablyService.getChannelNameForChecklist(checklistId);
      const channel = ablyService.getChannel(channelName);

      const listener = async (message: { data: string }) => {
        logger.info(`message from ${AblyEvent.EventType.WorkflowRunUpdated}`);
        const { checklistId: updatedChecklistId } = JSON.parse(message.data);
        if (updatedChecklistId !== checklistId) return;
        await queryClient.invalidateQueries(GetChecklistQuery.getKey({ checklistId }));

        if (shouldUpdateInboxQuery) {
          await queryClient
            .fetchQuery(GetChecklistQuery.getKey({ checklistId }), {
              queryFn: () => GetChecklistQuery.queryFn({ checklistId }),
            })
            .then(checklist => {
              GetInboxItemsQuery.updateItem(
                queryClient,
                currentItem => currentItem.checklist.id === checklistId,
                itemToUpdate => ({ ...itemToUpdate, checklist }),
              );
            });
        }
      };

      logger.info(`subscribing to ${AblyEvent.EventType.WorkflowRunUpdated}`);
      channel.subscribe(AblyEvent.EventType.WorkflowRunUpdated, listener);

      return () => {
        logger.info(`unsubscribing from ${AblyEvent.EventType.WorkflowRunUpdated}`);

        channel.unsubscribe(AblyEvent.EventType.WorkflowRunUpdated);
      };
    },
    [checklistId, queryClient, shouldUpdateInboxQuery],
  );
};
