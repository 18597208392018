import { DataSet } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateDataSetMutation {
  export type Params = Pick<DataSet, 'id' | 'name' | 'columnDefs'>;
  export type Response = DataSet;

  export const mutationFn = async (params: Params) => {
    return axiosService
      .getAxios()
      .put<Response>(`/1/data-sets/${params.id}`, params)
      .then(r => r.data);
  };

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, options);
  };
}
