import { MuidUtils } from '../../core';
import { S3File } from '../../core/core-model';
import { generateAudit } from './audit-generator';

export const generateS3File = (o?: Partial<S3File>): S3File => {
  return {
    id: MuidUtils.randomMuid(),
    audit: generateAudit(),
    name: 'Test name',
    mimeType: 'image/png',
    url: 'https://upload.wikimedia.org/wikipedia/commons/6/6a/PNG_Test.png',
    ...o,
  } as S3File;
};
