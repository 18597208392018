import { ConditionalFilterIntegerOperand } from '@process-street/subgrade/dashboard';
import { BlvdNumberInput } from 'components/design/BlvdNumberInput';
import React from 'react';
import styles from './IntegerOperandEditor.module.scss';

export interface IntegerOperandEditorProps {
  operand: ConditionalFilterIntegerOperand;
  onChange: (operand: ConditionalFilterIntegerOperand) => void;
}

export const IntegerOperandEditor: React.FunctionComponent<React.PropsWithChildren<IntegerOperandEditorProps>> = ({
  operand,
  onChange,
}) => {
  const handleChange = (value: number) => {
    onChange({ ...operand, value });
  };

  return (
    <div className={styles.integerOperand}>
      <BlvdNumberInput value={operand.value} onChange={handleChange} debounceDelay={0} />
    </div>
  );
};
