import { ConditionEvaluator, ConditionEvaluatorOperand } from '@process-street/subgrade/conditional-logic';
import { OperandType, RuleConstants } from '@process-street/subgrade/conditional-logic/rule-constants';
import { FieldType, SimpleFieldValue } from '@process-street/subgrade/process';
import { trace } from 'components/trace';
import { Option } from 'space-monad';
import { OperandTypeResolver } from './operand-type-resolver';

enum ArithmeticCondition {
  IsLessThanCondition = 'IsLessThanCondition',
  IsGreaterThanCondition = 'IsGreaterThanCondition',
}

function getArithmeticCondition(conditionType: ArithmeticCondition): ConditionEvaluator {
  const logger = trace({ name: conditionType });

  const comparator = (lhs: number, rhs: number) =>
    conditionType === ArithmeticCondition.IsLessThanCondition ? lhs < rhs : lhs > rhs;

  const compareNumbers = (
    fieldValue: string | number,
    rightOperand: ConditionEvaluatorOperand,
    operandType?: OperandType,
  ) => {
    switch (operandType) {
      case RuleConstants.OperandType.STRING:
        // Math.fround will round to the nearest float representation
        const lhs = Math.fround(typeof fieldValue === 'string' ? parseFloat(fieldValue) : fieldValue);
        const rhs = Option(rightOperand)
          .filter(
            (v): v is Exclude<ConditionEvaluatorOperand, null | undefined | boolean> =>
              typeof v === 'string' || typeof v === 'number',
          )
          .map(v => (typeof v === 'string' ? parseFloat(v) : v))
          .map(v => Math.fround(v))
          .getOrElse(0);
        const result = comparator(lhs, rhs);
        return isNaN(result as any) ? false : result;
      default:
        logger.error('unsupported operand type: %s', operandType);
        return false;
    }
  };

  const compareDates = (
    fieldValue: string | number,
    rightOperand: ConditionEvaluatorOperand,
    operandType?: OperandType,
  ) => {
    switch (operandType) {
      case RuleConstants.OperandType.DATE_TIME:
        const lhs = typeof fieldValue === 'string' ? parseInt(fieldValue, 10) : fieldValue;
        const rhs = Option(rightOperand)
          .filter(
            (v): v is Exclude<ConditionEvaluatorOperand, null | undefined | boolean> =>
              typeof v === 'string' || typeof v === 'number',
          )
          .map(v => (typeof v === 'string' ? parseInt(v, 10) : v))
          .getOrElse(0);
        const result = comparator(lhs, rhs);
        return isNaN(result as any) ? false : result;
      default:
        logger.error('unsupported operand type: %s', operandType);
        return false;
    }
  };

  const evaluate: ConditionEvaluator['evaluate'] = (formFieldValue, formFieldWidget, operand) => {
    if (!formFieldValue || !formFieldWidget || !operand) return false;

    const fieldType = formFieldWidget?.fieldType;
    const operandType = OperandTypeResolver.resolve(formFieldWidget);
    const fieldValue = (formFieldValue?.fieldValue as SimpleFieldValue)?.value;

    if (!fieldValue) return false;

    switch (fieldType) {
      case FieldType.Number:
        return compareNumbers(fieldValue, operand, operandType);
      case FieldType.Date:
        return compareDates(fieldValue, operand, operandType);
      default:
        logger.error('unsupported field type: %s', fieldType);
        return false;
    }
  };

  return { evaluate };
}

export const IsLessThanCondition = getArithmeticCondition(ArithmeticCondition.IsLessThanCondition);
export const IsGreaterThanCondition = getArithmeticCondition(ArithmeticCondition.IsGreaterThanCondition);
