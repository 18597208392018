import * as React from 'react';
import { Box, HStack, ListItem, Skeleton, SkeletonCircle, Text } from 'components/design/next';

export const TaskAssignmentItemSkeleton: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <ListItem py="2">
      <HStack spacing="1" justifyContent="space-between">
        <HStack>
          <SkeletonCircle size="6" />
          <Box>
            <Skeleton h="3">
              <Text>username</Text>
            </Skeleton>

            <Skeleton h="2" mt="1">
              <Text>user@mail.com</Text>
            </Skeleton>
          </Box>
        </HStack>
        <Skeleton>
          <Text>3 Tasks</Text>
        </Skeleton>
      </HStack>
    </ListItem>
  );
};
