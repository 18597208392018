import { ActivityObjectType } from '@process-street/subgrade/activity';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { GetAllActivitiesByTypeQuery } from 'features/activities/query-builder';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { useGetChecklistQuery } from 'features/checklists/query-builder';
import { GetAttachmentsQuery, useGetAttachmentsQuery } from 'features/comments/query-builder';
import { useQueryClient } from 'react-query';
import * as React from 'react';
import { Attachment, AttachmentType, Checklist, ChecklistRevision, Template } from '@process-street/subgrade/process';
import { useCanCompleteOneOffTask, useCanEditOneOfftask } from '.';

export const useOneOffTaskAttachments = (
  task: OneOffTask,
): {
  taskAttachments: Attachment[];
  canUploadAttachments: boolean;
  checklistId: Checklist['id'];
  checklistRevision?: ChecklistRevision;
  templateId: Template['id'];
  onAddAttachment: () => void;
} => {
  const queryClient = useQueryClient();

  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({
    checklistId: task.internalChecklistId,
  });

  const internalChecklistQuery = useGetChecklistQuery({ checklistId: task.internalChecklistId });

  const attachmentsQuery = useGetAttachmentsQuery(
    {
      checklistRevisionId: checklistRevisionQuery.data?.id ?? '',
      templateId: internalChecklistQuery.data?.template.id ?? '',
    },
    {
      enabled: Boolean(checklistRevisionQuery.data) && Boolean(internalChecklistQuery.data),
      select: data => data.filter(attachment => attachment.type === AttachmentType.Task),
    },
  );

  const onAddAttachment = () => {
    queryClient.invalidateQueries(
      GetAttachmentsQuery.getKey({
        checklistRevisionId: checklistRevisionQuery.data?.id ?? '',
        templateId: internalChecklistQuery.data?.template.id ?? '',
      }),
    );
    queryClient.invalidateQueries(
      GetAllActivitiesByTypeQuery.getKey({
        checklistId: task.internalChecklistId,
        type: 'checklist',
        objectType: ActivityObjectType.Attachment,
      }),
    );
  };

  const taskAttachments = React.useMemo(() => {
    if (attachmentsQuery.data) {
      return attachmentsQuery.data;
    }
    return [];
  }, [attachmentsQuery.data]);

  const canCompleteTask = useCanCompleteOneOffTask(task);
  const canEditTask = useCanEditOneOfftask(task);

  // creators or assigned users can upload attachments
  const canUploadAttachments =
    Boolean(checklistRevisionQuery.data) && Boolean(internalChecklistQuery.data) && (canCompleteTask || canEditTask);

  return {
    taskAttachments,
    canUploadAttachments,
    checklistId: task.internalChecklistId,
    checklistRevision: checklistRevisionQuery.data,
    templateId: internalChecklistQuery.data?.template.id ?? '',
    onAddAttachment,
  };
};
