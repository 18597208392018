import { Switch, Tooltip } from 'components/design/next';

import * as React from 'react';
import { TasksTableUtils } from '../../tasks-table/tasks-table-utils';

export type ShowCompletedToggleProps = {
  isChecked?: boolean;
  groupBy?: TasksTableUtils.TasksTableGroupBy;
  onChange: (includeCompleted: boolean) => void;
};

export const ShowCompletedToggle = ({ onChange, groupBy, isChecked }: ShowCompletedToggleProps) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    onChange(checked);
  };

  const isDisabled = groupBy === TasksTableUtils.TasksTableGroupBy.DueDate;

  return (
    <Tooltip hasArrow shouldWrapChildren isDisabled={!isDisabled} label="Unavailable when grouped by due date">
      <Switch
        aria-label="show completed"
        isDisabled={isDisabled}
        display="flex"
        alignItems="center"
        isChecked={isChecked}
        onChange={handleOnChange}
        fontSize="sm"
      >
        Show completed
      </Switch>
    </Tooltip>
  );
};
