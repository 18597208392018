import { DataSetRow } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type DeleteDataSetRecordMutationParams = {
  dataSetId: string;
  rowId: string;
};

type DeleteDataSetMutationResponse = DataSetRow;

export const DeleteDataSetRecordMutation = {
  mutationFn: (params: DeleteDataSetRecordMutationParams) =>
    axiosService
      .getAxios()
      .delete<DeleteDataSetMutationResponse>(`/1/data-sets/${params.dataSetId}/rows/${params.rowId}`)
      .then(res => res.data),
};

export const useDeleteDataSetRowMutation = (
  options: UseMutationOptions<DeleteDataSetMutationResponse, AxiosError, DeleteDataSetRecordMutationParams> = {},
) => {
  return useMutation(DeleteDataSetRecordMutation.mutationFn, options);
};
