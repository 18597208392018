import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { TaskTemplateConditionalLogicButton } from '.';

export const TaskTemplateConditionalLogicButtonModule = angular
  .module('taskTemplateConditionalLogicButton', [])
  .component(
    'psTaskTemplateConditionalLogicButton',
    reactToAngular(TaskTemplateConditionalLogicButton, ['templateRevision', 'taskTemplate', 'setHasConditionalLogic']),
  ).name;
