import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Flex, HStack, Image, Portal, Progress, Text, VStack } from '@chakra-ui/react';
import { match, P } from 'ts-pattern';
import { AiGenerationType } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/store';
import { TaskTemplateStatusMap } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/use-task-template-status-map';
import { useInterval } from 'react-use';
import clamp from 'lodash/clamp';

export type AiGenerationLoadingStateProps = {
  generationType: AiGenerationType;
  taskTemplateStatusMap: TaskTemplateStatusMap;
};

/** Current prompt is between 6..10, with 1..2 approval tasks, so we take 6 as average */
const DEFAULT_TASK_COUNT = 6;
/** Amount of estimated time it takes to generate initial task list. */
const TASK_LIST_GENERATION_ESTIMATE_MS = 10000;
/** Amount of estimated time it takes to stream form fields for a single task. */
const TASK_FORM_FIELDS_GENERATION_ESTIMATE_MS = 10000;
const SINGLE_TASK_GENERATION_ESTIMATE_MS = 15000;
const IMPORT_ESTIMATE_MS = 60000;
/** Intervals to update progress. */
const PROGRESS_UPDATE_MS = 500;

const TOTAL_GENERATION_ESTIMATE_MS =
  TASK_LIST_GENERATION_ESTIMATE_MS + DEFAULT_TASK_COUNT * TASK_FORM_FIELDS_GENERATION_ESTIMATE_MS;
/** Fixed task list generation progress estimate to prevent progress jerkiness. */
const TASK_LIST_PROGRESS_PERCENT = (TASK_LIST_GENERATION_ESTIMATE_MS / TOTAL_GENERATION_ESTIMATE_MS) * 100;
const FORM_FIELDS_PROGRESS_PERCENT = 100 - TASK_LIST_PROGRESS_PERCENT;

const IMPORT_PROGRESS_INCREMENT_PERCENT = (PROGRESS_UPDATE_MS / IMPORT_ESTIMATE_MS) * 100;
const SINGLE_TASK_PROGRESS_INCREMENT_PERCENT = (PROGRESS_UPDATE_MS / SINGLE_TASK_GENERATION_ESTIMATE_MS) * 100;

const TASK_GENERATION_LABELS = ['Generating the workflow...', 'Generating tasks...'];

const WORKFLOW_GENERATION_LABELS = [
  'Generating form fields...',
  'Generating dependencies...',
  'Generating due dates...',
  'Generating text content and variables...',
];

export const AiGenerationLoadingState: React.FC<React.PropsWithChildren<AiGenerationLoadingStateProps>> = ({
  generationType,
  taskTemplateStatusMap,
}) => {
  const [progress, setProgress] = useState(0);
  const label = getLabel(progress, generationType);
  const updateProgress = () =>
    setProgress(
      getUpdatedProgress({
        currentProgress: progress,
        generationType,
        taskTemplateStatusMap,
      }),
    );

  // prevents jerkiness in Storybook & tests
  useEffect(() => {
    if (generationType === AiGenerationType.WorkflowFormFields) {
      updateProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't care about updateProgress
  }, [generationType]);

  // update progress bar
  useInterval(updateProgress, PROGRESS_UPDATE_MS);

  return (
    <Portal>
      <Box zIndex="toast" position="fixed" top="0" left="0" w="full" h="full" bgColor="blackAlpha.400" />

      <HStack top="0" mt="16" position="fixed" zIndex="toast" w="full" justifyContent="center">
        <VStack bgColor="white" borderRadius="base" px="4" py="3" spacing="4" shadow="md" w="full" maxW="554px" top="0">
          <Flex w="12" h="12" borderRadius="full" alignItems="center" justifyContent="center">
            <Image
              src={require('app/images/ai-workflow-generation/process-pete-loading.gif')}
              w="full"
              h="full"
              borderRadius="full"
            />
          </Flex>

          <Progress value={progress} w="full" borderRadius="base" />

          <Box w="full">
            <Text mt="-2" variant="-1" alignSelf="flex-start" color="gray.500">
              {label}
            </Text>
          </Box>
        </VStack>
      </HStack>
    </Portal>
  );
};

/**
 * For task list & form field generation we divide the progress into equal parts
 * and display the corresponding label.
 * */
function getLabel(currentProgress: number, generationType: AiGenerationType): string {
  return match(generationType)
    .with(AiGenerationType.WorkflowTasks, () => {
      const partialProgress = Math.min(TASK_LIST_PROGRESS_PERCENT, currentProgress) / TASK_LIST_PROGRESS_PERCENT;
      const labelCount = TASK_GENERATION_LABELS.length;
      const index = Math.min(Math.floor(partialProgress * labelCount), labelCount - 1);
      return TASK_GENERATION_LABELS[index];
    })
    .with(P.union(AiGenerationType.ImportWorkflowFormFields, AiGenerationType.WorkflowFormFields), () => {
      const partialProgress =
        (Math.max(TASK_LIST_PROGRESS_PERCENT, currentProgress) - TASK_LIST_PROGRESS_PERCENT) /
        (100 - TASK_LIST_PROGRESS_PERCENT);
      const labelCount = WORKFLOW_GENERATION_LABELS.length;
      const index = Math.min(Math.floor(partialProgress * labelCount), labelCount - 1);
      return WORKFLOW_GENERATION_LABELS[index];
    })
    .with(AiGenerationType.SingleTask, () => 'Generating your task...')
    .with(AiGenerationType.ImportWorkflowTasks, () => 'Importing your workflow...')
    .exhaustive();
}

function getUpdatedProgress({
  currentProgress,
  generationType,
  taskTemplateStatusMap,
}: {
  currentProgress: number;
  generationType: AiGenerationType;
  taskTemplateStatusMap: TaskTemplateStatusMap;
}): number {
  if (generationType === AiGenerationType.ImportWorkflowTasks) {
    return clamp(currentProgress + IMPORT_PROGRESS_INCREMENT_PERCENT, 0, 100);
  } else if (generationType === AiGenerationType.SingleTask) {
    return clamp(currentProgress + SINGLE_TASK_PROGRESS_INCREMENT_PERCENT, 0, 100);
  } else if (generationType === AiGenerationType.WorkflowTasks) {
    const increment = (PROGRESS_UPDATE_MS / TASK_LIST_GENERATION_ESTIMATE_MS) * TASK_LIST_PROGRESS_PERCENT;
    return clamp(currentProgress + increment, 0, TASK_LIST_PROGRESS_PERCENT);
  }
  // Else "workflow form fields" or "import workflow form fields" case
  const initialProgress = generationType === AiGenerationType.ImportWorkflowFormFields ? 0 : TASK_LIST_PROGRESS_PERCENT;

  // Map is empty until task template list has been generated
  // We ignore approval tasks as they don't take up generation time
  const taskStatuses = Object.values(taskTemplateStatusMap).filter(taskStatus => taskStatus !== 'ignored');
  const taskCount = taskStatuses.length;
  const maxTaskCount = taskCount > 0 ? taskCount : DEFAULT_TASK_COUNT;
  const generatedTaskCount = taskStatuses.filter(taskStatus => taskStatus === 'loaded').length;

  const getProgressForTaskCount = (taskCount: number) =>
    initialProgress + (taskCount / maxTaskCount) * FORM_FIELDS_PROGRESS_PERCENT;

  const maxPossibleProgressPercent = getProgressForTaskCount(generatedTaskCount + 1);
  const minPossibleProgressPercent = getProgressForTaskCount(generatedTaskCount);
  const increment =
    (FORM_FIELDS_PROGRESS_PERCENT * PROGRESS_UPDATE_MS) / (maxTaskCount * TASK_FORM_FIELDS_GENERATION_ESTIMATE_MS);

  return clamp(currentProgress + increment, minPossibleProgressPercent, maxPossibleProgressPercent);
}
