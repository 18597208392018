import { Muid, MuidConverter } from '@process-street/subgrade/core';
import { FormFieldDataRow } from '@process-street/subgrade/dashboard';
import {} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetChecklistGridFormFieldDataQueryParams = {
  organizationId?: Muid;
  templateId: Muid;
  checklistRevisionIds: Muid[];
  widgetGroupIds: Muid[];
};

export type GetChecklistGridFormFieldDataQueryResponse = FormFieldDataRow[];

const key = ['organizations', 'checklist-grid', 'form-field-data'];
export const GetChecklistGridFormFieldDataQuery = {
  key,
  getKey: (params: GetChecklistGridFormFieldDataQueryParams): QueryKey => [...key, params],
  queryFn: ({ organizationId, ...data }: GetChecklistGridFormFieldDataQueryParams) =>
    axiosService
      .getAxios()
      .post<GetChecklistGridFormFieldDataQueryResponse>(
        `/1/organizations/${organizationId}/checklist-grid/form-field-data`,
        data,
      )
      .then(res => {
        // convert uuids to muids
        res.data.forEach(row => {
          row.formFieldValues.forEach(formFieldValue => {
            formFieldValue.widgetGroupId = MuidConverter.fromUuid(formFieldValue.widgetGroupId);
          });
        });

        return res.data;
      }),
};

export const useGetChecklistGridFormFieldDataQuery = <Select = GetChecklistGridFormFieldDataQueryResponse>(
  params: GetChecklistGridFormFieldDataQueryParams,
  options: UseQueryOptions<GetChecklistGridFormFieldDataQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetChecklistGridFormFieldDataQuery.getKey(params),
    () => GetChecklistGridFormFieldDataQuery.queryFn(params),
    {
      ...options,
      enabled: Boolean(params.organizationId) && options.enabled !== false,
    },
  );
};
