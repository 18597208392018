import { Muid, Option, Period } from '@process-street/subgrade/core';
import {
  OrderTree,
  TaskTemplate,
  TaskTemplateStatus,
  TaskTemplateUpdateRequest,
  TaskTemplateUpdateResponse,
  TaskTemplateUpdateResult,
  TemplateRevisionStatus,
} from '@process-street/subgrade/process';
import { TaskTemplateOrderModel } from '@process-street/subgrade/process/template-model';
import { queryClient } from 'components/react-root';
import {
  TaskTemplatesByTemplateRevisionIdQuery,
  TaskTemplatesByTemplateRevisionIdQueryResponse,
} from 'features/task-templates/query-builder';
import { AngularResource } from 'reducers/angular-types';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import angular from 'angular';

interface TaskTemplates {
  query(params: Record<string, unknown>): AngularResource<TaskTemplate[]>;

  getAllByTemplateRevisionId(request: { id: Muid }): AngularResource<TaskTemplate[]>;

  getAllByChecklistRevisionId(request: { id: Muid }): AngularResource<TaskTemplate[]>;

  deleteAllByIds(request: { taskTemplatesIds: Muid[] }): AngularResource<TaskTemplateUpdateResponse[]>;

  updateAllOrderTrees(request: {
    orderModels: TaskTemplateOrderModel[];
  }): AngularResource<TaskTemplateUpdateResponse[]>;

  updateAllDueOffset(request: {
    taskTemplatesIds: Muid[];
    dueOffset: Period;
  }): AngularResource<TaskTemplateUpdateResponse[]>;

  updateAllStop(request: { taskTemplatesIds: Muid[]; stop: boolean }): AngularResource<TaskTemplateUpdateResponse[]>;

  updateDueOffset(request: { id: Muid; dueOffset: Period }): AngularResource<TaskTemplate>;

  create(taskTemplate: TaskTemplate): AngularResource<TaskTemplate>;

  update(request: TaskTemplateUpdateRequest): AngularResource<TaskTemplateUpdateResult>;

  duplicate(request: {
    groupId: Muid;
    orderTree: OrderTree;
    id: Muid;
    duplicateId: Muid;
  }): AngularResource<TaskTemplate>;
}

export interface TaskTemplateApi {
  create(taskTemplate: TaskTemplate): angular.IPromise<TaskTemplate>;

  getAllByTemplateRevisionId(templateRevisionId: Muid, flushCache: boolean): angular.IPromise<TaskTemplate[]>;

  getAllByChecklistRevisionId(checklistRevisionId: Muid): angular.IPromise<TaskTemplate[]>;

  getFirstByTemplateId(templateId: Muid): angular.IPromise<Option<TaskTemplate>>;

  update(request: TaskTemplateUpdateRequest): angular.IPromise<TaskTemplateUpdateResult>;

  updateDueOffset(id: Muid, dueOffset: Period): angular.IPromise<TaskTemplate>;

  updateAllDueOffset(taskTemplatesIds: Muid[], dueOffset: Period): angular.IPromise<TaskTemplateUpdateResponse[]>;

  updateAllStop(taskTemplatesIds: Muid[], stop: boolean): angular.IPromise<TaskTemplateUpdateResponse[]>;

  updateAllOrderTrees(orderModels: TaskTemplateOrderModel[]): angular.IPromise<TaskTemplateUpdateResponse[]>;

  duplicate(id: Muid, duplicateId: Muid, groupId: Muid, orderTree: OrderTree): angular.IPromise<TaskTemplate>;

  deleteAllByIds(taskTemplatesIds: Muid[]): angular.IPromise<TaskTemplateUpdateResponse[]>;
}

export class TaskTemplateApiImpl implements TaskTemplateApi {
  private taskTemplates: TaskTemplates;
  private $q: angular.IQService;

  static $inject = ['TaskTemplates', '$q'];

  constructor(TaskTemplates: TaskTemplates, $q: angular.IQService) {
    this.taskTemplates = TaskTemplates;
    this.$q = $q;
  }

  public create = (taskTemplate: TaskTemplate) => {
    return this.taskTemplates.create(taskTemplate).$promise.then(response => {
      queryClient.invalidateQueries(GetAllNativeAutomationsQuery.key);
      return response;
    });
  };

  public getAllByTemplateRevisionId = (templateRevisionId: Muid, flushCache: boolean = false) => {
    if (flushCache) {
      queryClient.invalidateQueries(TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }));
    }

    return this.$q<TaskTemplatesByTemplateRevisionIdQueryResponse>((resolve, reject) =>
      queryClient
        .fetchQuery({
          queryKey: TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
          queryFn: () => TaskTemplatesByTemplateRevisionIdQuery.queryFn({ templateRevisionId }),
        })
        .then(resolve)
        .catch(reject),
    );
  };

  public getAllByChecklistRevisionId = (checklistRevisionId: Muid) =>
    this.taskTemplates.getAllByChecklistRevisionId({ id: checklistRevisionId }).$promise;

  public getFirstByTemplateId = (templateId: Muid) => {
    const where = JSON.stringify({
      'status': { _eq: TaskTemplateStatus.Active },
      'templateRevision.status': { _eq: TemplateRevisionStatus.Finished },
      'templateRevision.templateId': { _eq: templateId },
    });

    const firstTaskByTemplateIdQuery = {
      where,

      include: 'templateRevision',
      limit: 1,
      orderBy: '+orderTree',
    };

    return this.taskTemplates
      .query(firstTaskByTemplateIdQuery)
      .$promise.then(taskTemplates => (taskTemplates.length > 0 ? taskTemplates[0] : undefined));
  };

  public update = (request: TaskTemplateUpdateRequest) => {
    return this.taskTemplates.update(request).$promise;
  };

  public updateDueOffset = (id: Muid, dueOffset: Period) => {
    return this.taskTemplates.updateDueOffset({
      dueOffset,
      id,
    }).$promise;
  };

  public updateAllDueOffset = (taskTemplatesIds: Muid[], dueOffset: Period) => {
    return this.taskTemplates.updateAllDueOffset({
      dueOffset,
      taskTemplatesIds,
    }).$promise;
  };

  public updateAllStop = (taskTemplatesIds: Muid[], stop: boolean) => {
    return this.taskTemplates.updateAllStop({
      stop,
      taskTemplatesIds,
    }).$promise;
  };

  public updateAllOrderTrees = (orderModels: TaskTemplateOrderModel[]) => {
    return this.taskTemplates
      .updateAllOrderTrees({
        orderModels,
      })
      .$promise.then(response => {
        queryClient.invalidateQueries(GetAllNativeAutomationsQuery.key);
        return response;
      });
  };

  public duplicate = (id: Muid, duplicateId: Muid, groupId: Muid, orderTree: OrderTree) => {
    return this.taskTemplates
      .duplicate({
        duplicateId,
        groupId,
        id,
        orderTree,
      })
      .$promise.then(response => {
        queryClient.invalidateQueries(GetAllNativeAutomationsQuery.key);
        return response;
      });
  };

  public deleteAllByIds = (taskTemplatesIds: Muid[]) => {
    return this.taskTemplates
      .deleteAllByIds({
        taskTemplatesIds,
      })
      .$promise.then(response => {
        queryClient.invalidateQueries(GetAllNativeAutomationsQuery.key);
        return response;
      });
  };
}
