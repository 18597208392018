import * as React from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from 'components/design/next';

import {
  TemplateMenu,
  TemplateMenuContext,
  TemplateMenuDisclosureContext,
} from 'app/features/template/components/template-menu/template-menu';

import { useDraftStatus } from '../../draft-status-indicator/use-draft-status';
import { DraftStatusIndicator } from '../../draft-status-indicator';
import { useGetTemplateQuery } from 'features/template/query-builder';
import {
  DiscardTemplateAlert,
  DiscardTemplateButton,
} from 'features/template/components/template-menu/discard-template-button';
import { noop } from '@process-street/subgrade/util';
import { useFeatureFlags } from 'features/feature-flags';
import { FocusBarIconButton } from './focus-bar-icon-button';
import { ConditionalLogicButtonWrapper } from 'pages/templates/_id/components/conditional-logic-button-wrapper';
import { useTemplateSettingsModalContext } from 'pages/templates/_id/components/template-settings-modal/template-settings-modal-context';
import { PublishDraftMutation, useTemplateRevisionQuery } from 'features/template-revisions/query-builder';
import { ChecklistMigrationModal } from '../../migration-modal';
import { TemplateCommentsOverviewPopover } from 'features/comments/components/template/comments-overview-popover';
import { useGetChecklistRevisionsByTemplateIdQuery } from 'features/checklist-revisions/query-builder';
import { useInjector } from 'components/injection-provider';
import { useDisplayWorkflowSetupModal } from 'components/focus-bar/workflow/right-button-group/edit/use-display-workflow-setup-modal';
import { usePublishAndRun } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/use-publish-and-run';
import { AiGeneratedWorkflowSettingsModalHelpers } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/helpers';
import { RunChecklist } from 'app/components/run-checklist/components/RunChecklist/run-checklist';
import { CustomNotificationsModal } from 'pages/templates/_id/components/custom-notifications-modal';
import { CustomNotificationIcon } from 'features/custom-notifications/components/custom-notification-icon';
import { SandboxButton } from 'features/template/components/sandbox-button';
import { useCreateSandboxAndRedirect } from 'hooks/use-create-sandbox-and-redirect';
import { useQueryClient } from 'react-query';
import { PostReportsSearchQuery } from 'app/features/checklist-grid/query-builder';
import { useTemplateMenuDisclosureContextValue } from 'features/template/components/template-menu/hooks/use-template-menu-disclosure-context-value';

export const SHOW_WORKFLOW_SETUP_MODAL_FOR = 'showWorkflowSetupModalFor';

const buttonStyles = { bgColor: 'gray.200', color: 'gray.700', textDecor: 'none' };

export const EditButtonGroup: React.FC<React.PropsWithChildren<ButtonGroupProps>> = props => {
  const templateMenuDisclosure = useDisclosure();
  const migrationDisclosure = useDisclosure();
  const runChecklistDisclosure = useDisclosure();
  const customNotificationsDisclosure = useDisclosure();
  const { openSetup, templateId, templateRevisionId } = useTemplateSettingsModalContext();
  const queryClient = useQueryClient();
  if (!templateRevisionId) {
    throw new Error('Expecting template revision ID for this component.');
  }
  const [shouldPublishAndRun, setShouldPublishAndRun] = React.useState(false);

  const draftStatus = useDraftStatus();

  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;
  const ffs = useFeatureFlags('publishAndRun', 'customNotifications', 'sandboxMode');

  const newestTemplateRevisionQuery = useTemplateRevisionQuery({ templateRevisionId });

  useDisplayWorkflowSetupModal();

  const publishDraftMutation = PublishDraftMutation.useMutation();
  const canPublish = !!template && draftStatus === 'saved' && publishDraftMutation.isIdle;

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const { $state } = useInjector('$state');
  const checklistRevisionsQuery = useGetChecklistRevisionsByTemplateIdQuery({ templateId });
  const workflowRunCount = checklistRevisionsQuery.data?.length ?? 0;

  const publishAndRunMutation = usePublishAndRun({
    templateId,
    templateRevisionId,
    templateName: template?.name ?? '',
  });

  const publish = async () => {
    return checklistRevisionsQuery.refetch().then(res => {
      if (res.data?.length === 0) {
        return publishDraftMutation.mutateAsync({ tmplRevId: templateRevisionId }).then(async () => {
          await PostReportsSearchQuery.invalidate(queryClient);
          $state.go('templateDashboard', { id: templateId });
        });
      }

      setShouldPublishAndRun(false);
      migrationDisclosure.onOpen();

      return;
    });
  };

  const handlePublishAndRun = async () => {
    checklistRevisionsQuery.refetch().then(async res => {
      if (res.data?.length === 0) {
        // Show the WFR setup modal in case the defaultChecklistName is not set
        // to allow the user to choose a name for the WF
        if (!newestTemplateRevisionQuery.data?.defaultChecklistName) {
          await publishDraftMutation.mutateAsync({ tmplRevId: templateRevisionId });
          runChecklistDisclosure.onOpen();

          return;
        }

        return publishAndRunMutation.mutate({});
      }

      setShouldPublishAndRun(true);
      migrationDisclosure.onOpen();
    });
  };

  const runChecklistAndRedirect = useCreateSandboxAndRedirect({
    templateName: template?.name,
    templateId: template?.id,
    openInNewTab: true,
  });

  const templateMenuDisclosureContextValue = useTemplateMenuDisclosureContextValue({ setCloseOnBlur: () => {} });

  return (
    <ButtonGroup spacing="2" alignItems="center" {...props}>
      <HStack color="white">
        <DraftStatusIndicator />
        <Box as="span" display="flex">
          <TemplateMenuContext.Provider value={{ templateId, view: 'show', closeOnBlur: false, setCloseOnBlur: noop }}>
            <TemplateMenuDisclosureContext.Provider value={templateMenuDisclosureContextValue}>
              <Text as="span" variant="-1">
                {'('}
              </Text>
              <DiscardTemplateButton>
                <Button
                  display="inline-block"
                  variant="link"
                  fontWeight="normal"
                  color="white"
                  fontSize="sm"
                  textDecor="underline"
                  {...(() => ({
                    _active: buttonStyles,
                    _focus: buttonStyles,
                    _hover: buttonStyles,
                  }))()}
                >
                  <Text variant="-1">discard</Text>
                </Button>
              </DiscardTemplateButton>
              <DiscardTemplateAlert />
              <Text as="span" variant="-1">
                {')'}
              </Text>
            </TemplateMenuDisclosureContext.Provider>
          </TemplateMenuContext.Provider>
        </Box>
      </HStack>

      {isSmallScreen ? (
        <TemplateMenu autoSelect={false} mode="edit" {...templateMenuDisclosure}>
          <MenuItem onClick={publish} isDisabled={!canPublish} color="gray.700" aria-label="publish changes">
            Publish
          </MenuItem>
          {ffs.sandboxMode && template && (
            <MenuItem
              aria-label="sandbox"
              onClick={runChecklistAndRedirect}
              color="gray.700"
              icon={<Icon icon="eye" color="gray.700" size="4" />}
              isDisabled={!canPublish}
            >
              Preview
            </MenuItem>
          )}
          {ffs.publishAndRun && checklistRevisionsQuery.isSuccess && workflowRunCount === 0 && (
            <MenuItem
              onClick={handlePublishAndRun}
              isDisabled={!canPublish || publishAndRunMutation.isLoading}
              color="gray.700"
              aria-label="publish changes and run workflow"
            >
              Publish and Run
            </MenuItem>
          )}
        </TemplateMenu>
      ) : (
        <>
          <FocusBarIconButton
            aria-label="Workflow Settings"
            icon={<Icon icon="gear" variant="far" size="4" color="white" />}
            onClick={openSetup}
            tooltipText={`Workflow Setup`}
          />

          <Tooltip shouldWrapChildren hasArrow bgColor="gray.600" label={<Text fontSize="sm">Run Comments</Text>}>
            <TemplateCommentsOverviewPopover {...{ templateId }}>
              <IconButton
                borderColor="gray.600"
                variant="outline"
                aria-label="workflow comments"
                icon={<Icon icon="comment" variant="far" size="4" color="white" />}
                _hover={{ bgColor: 'gray.500' }}
                _active={{ bgColor: 'gray.500' }}
                _focus={{ bgColor: 'gray.500' }}
              />
            </TemplateCommentsOverviewPopover>
          </Tooltip>

          {ffs.customNotifications ? (
            <FocusBarIconButton
              sx={{
                bgColor: 'gray.700',
                _hover: {
                  'bgColor': 'gray.500',
                  '& .ps-custom-notification-icon': {
                    bgColor: 'gray.500',
                    transitionProperty: 'var(--ps-transition-property-common)',
                    transitionDuration: 'var(--ps-transition-duration-normal)',
                  },
                },
              }}
              icon={<CustomNotificationIcon color="white" />}
              aria-label="Custom notifications"
              onClick={customNotificationsDisclosure.onOpen}
              tooltipText={`Custom notifications`}
            />
          ) : null}

          {newestTemplateRevisionQuery.data && (
            <ConditionalLogicButtonWrapper templateRevisionId={newestTemplateRevisionQuery.data.id}>
              <FocusBarIconButton
                icon={<Icon icon="shuffle" variant="far" size="4" color="white" />}
                aria-label="Conditional logic"
                tooltipText={`Conditional logic`}
              />
            </ConditionalLogicButtonWrapper>
          )}

          {ffs.sandboxMode && template && (
            <SandboxButton template={template}>
              <Button
                mr={2}
                aria-label="Sandbox"
                borderColor="gray.600"
                variant="outline"
                colorScheme="gray"
                color="white"
                fontSize="sm"
                px={4}
                _hover={{ bgColor: 'gray.500' }}
                _active={{ bgColor: 'gray.500' }}
                _focus={{ bgColor: 'gray.500' }}
              >
                <Icon icon="eye" variant="far" size="4" color="white" mr={2} />
                Preview
              </Button>
            </SandboxButton>
          )}

          {ffs.publishAndRun ? (
            <ButtonGroup isAttached>
              <Button
                variant="cta"
                onClick={publish}
                isDisabled={
                  !canPublish ||
                  publishDraftMutation.isLoading ||
                  publishDraftMutation.isSuccess ||
                  publishAndRunMutation.isLoading ||
                  publishAndRunMutation.isSuccess
                }
                isLoading={
                  publishDraftMutation.isLoading || (publishDraftMutation.isSuccess && !runChecklistDisclosure.isOpen)
                }
                color="gray.700"
                aria-label="publish changes"
              >
                Publish
              </Button>

              <Menu>
                <MenuButton
                  as={IconButton}
                  borderLeftColor="gray.700"
                  borderLeftWidth="thin"
                  borderLeftStyle="solid"
                  variant="cta"
                  color="gray.700"
                  aria-label="Open menu"
                  icon={<Icon color="gray.700" icon="chevron-down" size="3" />}
                  isDisabled={
                    !canPublish ||
                    publishDraftMutation.isLoading ||
                    publishDraftMutation.isSuccess ||
                    publishAndRunMutation.isLoading ||
                    publishAndRunMutation.isSuccess
                  }
                  isLoading={publishAndRunMutation.isLoading || publishAndRunMutation.isSuccess}
                />

                <MenuList py="2">
                  <MenuItem
                    onClick={handlePublishAndRun}
                    icon={<Icon icon="play" size="4" />}
                    py="3"
                    isDisabled={
                      !canPublish ||
                      publishDraftMutation.isLoading ||
                      publishDraftMutation.isSuccess ||
                      publishAndRunMutation.isLoading ||
                      publishAndRunMutation.isSuccess
                    }
                  >
                    Publish and Run
                  </MenuItem>
                </MenuList>
              </Menu>
            </ButtonGroup>
          ) : (
            <Button
              variant="cta"
              onClick={publish}
              isDisabled={!canPublish}
              isLoading={publishDraftMutation.isLoading || publishDraftMutation.isSuccess}
              color="gray.700"
              aria-label="publish changes"
            >
              Publish
            </Button>
          )}
        </>
      )}

      {migrationDisclosure.isOpen && (
        <ChecklistMigrationModal
          {...{
            ...migrationDisclosure,
            templateId,
            templateRevisionId,
            workflowRunCount,
            templateName: template?.name ?? '',
            defaultChecklistName: AiGeneratedWorkflowSettingsModalHelpers.getDefaultChecklistName({
              templateName: template?.name ?? '',
              defaultChecklistName: newestTemplateRevisionQuery.data?.defaultChecklistName,
            }),
            runWorkflow: shouldPublishAndRun,
          }}
        />
      )}

      <RunChecklist
        {...runChecklistDisclosure}
        onCancel={runChecklistDisclosure.onClose}
        templateId={templateId}
        onChecklistCreate={runChecklistDisclosure.onClose}
      />

      {ffs.customNotifications && newestTemplateRevisionQuery.data && (
        <CustomNotificationsModal
          {...customNotificationsDisclosure}
          templateRevision={newestTemplateRevisionQuery.data}
        />
      )}
    </ButtonGroup>
  );
};
