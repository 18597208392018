export const AttachmentEvent = {
  ATTACHMENT_CREATE_STARTED: 'attachment create started',
  ATTACHMENT_CREATE_OK: 'attachment create ok',
  ATTACHMENT_CREATE_FAILED: 'attachment create failed',
  ATTACHMENT_CREATE_FINISHED: 'attachment create finished',

  ATTACHMENT_DELETE_STARTED: 'attachment delete started',
  ATTACHMENT_DELETE_OK: 'attachment delete ok',
  ATTACHMENT_DELETE_FAILED: 'attachment delete failed',
  ATTACHMENT_DELETE_FINISHED: 'attachment delete finished',
} as const;
