import {} from '@process-street/subgrade/core';
import { Checklist, Label, LabelRelationship } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace CreateLabelWithRelationshipMutation {
  export type Params = {
    checklistId: Checklist['id'];
    name: string;
    color: string;
  };

  export type Response = {
    label: Label;
    labelRelationship: LabelRelationship;
  };

  export const key = ['post', '...'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/labels/with-relationship`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
