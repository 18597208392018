import * as React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export const SectionWrapper = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      w="full"
      p={6}
      bgColor="whiteAlpha.900"
      borderRadius="12px"
      borderWidth="thin"
      borderStyle="solid"
      borderColor="gray.100"
      zIndex="2"
      {...props}
    >
      {children}
    </Box>
  );
};
