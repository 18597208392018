import { Muid } from '@process-street/subgrade/core';
import { Widget } from '@process-street/subgrade/process';
import { TaskAssignmentRule } from '@process-street/subgrade/role-assignment';
import { AxiosError } from 'axios';
import {
  useQuery as useRQ,
  QueryKey,
  UseQueryOptions,
  QueryObserverOptions,
  QueryClient,
  QueryObserver,
  QueryObserverResult,
} from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetTaskAssignmentRulesByTemplateRevisionIdQuery {
  export type Params = {
    templateRevisionId?: Muid;
  };

  export type Response = TaskAssignmentRule[];

  export const key = ['template-revisions', 'task-assignment-rules'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = ({ templateRevisionId }: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/template-revisions/${templateRevisionId}/task-assignment-rules`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), options);
  };

  export const makeQueryObserver = <Select = Response>({
    queryClient,
    options,
    ...params
  }: Params & {
    queryClient: QueryClient;
    options?: QueryObserverOptions<Response, AxiosError, Select>;
  }) => {
    return new QueryObserver<Response, AxiosError, Select>(queryClient, {
      ...options,
      queryKey: getKey(params),
      queryFn: () => queryFn(params),
      enabled: Boolean(params.templateRevisionId) && options?.enabled !== false,
    });
  };

  export const makeCacheSetter = ({
    queryClient,
    templateRevisionId,
  }: {
    queryClient: QueryClient;
    templateRevisionId: Muid;
  }) => {
    return {
      deleteWidget: (widget: Widget) => {
        queryClient.setQueryData<Response>(getKey({ templateRevisionId }), current => {
          if (!current) {
            return [];
          }
          return current.filter(
            taskAssignmentRule => taskAssignmentRule.sourceFormFieldWidgetGroup?.id !== widget.header.group.id,
          );
        });
      },
    };
  };

  export type Observer = QueryObserverResult<Response, AxiosError>;
}
