import { OneOffTask } from '@process-street/subgrade/one-off-task';
import * as yup from 'yup';
import { ONE_OFF_TASK_NAME_MAX_LENGTH } from '../create/schema';
import { Checklist, MultiSelectFieldValue, MultiSelectItemValueStatus } from '@process-street/subgrade/process';

export type EditOneOffTaskSettingsSchema = Pick<OneOffTask, 'name' | 'description' | 'required'> & {
  wfrLinkEnabled: boolean;
  linkedChecklistId?: Checklist['id'];
  subtasks?: MultiSelectFieldValue[];
};

export const editOneOffTaskSettingsSchema = yup.object().shape<EditOneOffTaskSettingsSchema>({
  name: yup
    .string()
    .max(ONE_OFF_TASK_NAME_MAX_LENGTH, `Name must be at most ${ONE_OFF_TASK_NAME_MAX_LENGTH} characters.`)
    .trim()
    .required('Name is required.'),
  description: yup.string(),
  linkedChecklistId: yup.string().when('wfrLinkEnabled', {
    is: true,
    then: yup.string().required(),
  }),
  wfrLinkEnabled: yup.boolean(),
  required: yup.boolean(),
  subtasks: yup
    .array()
    .of(
      yup
        .object()
        .shape<MultiSelectFieldValue>({
          itemType: yup.string(),
          id: yup.string().required(),
          name: yup.string().trim().required("Subtask can't be empty"),
          status: yup.string().oneOf(Object.values(MultiSelectItemValueStatus)).required(),
        })
        .required(),
    )
    .optional() as yup.Schema<MultiSelectFieldValue[] | undefined>,
});
