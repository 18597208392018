import React from 'react';
import { useInjector } from 'components/injection-provider';
import { getAncestorFolders, toSlugPath } from 'features/folder/lib';
import { useReadableFoldersQuery } from 'features/folder/query-builder';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { Muid } from '@process-street/subgrade/core';

/**
 * Returns a function that redirects to the folder view of some template.
 */
export function useRedirectToFolderView({ templateId }: { templateId: Muid }) {
  const { $state } = useInjector('$state');

  const { data: template } = useGetTemplateQuery({ templateId });
  const folderId = template?.folder?.id;

  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const { data: folders } = useReadableFoldersQuery(organizationId!, {
    enabled: !!organizationId,
  });
  const folder = folders?.find(f => f.id === folderId);
  const ancestors = folder && folders ? getAncestorFolders(folder, folders) : [];
  const folderPath = folder ? toSlugPath(folder, ancestors) : null;

  const redirectToFolderView = React.useCallback(() => {
    if (folderPath) {
      $state.go('dashboard.type', { type: 'folder', path: folderPath });
    }
  }, [$state, folderPath]);

  return {
    redirectToFolderView,
  };
}
