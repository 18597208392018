import { useInjector } from 'app/components/injection-provider';
import { useEffect } from 'react';

type UseMyWorkBackNavigationParams = {
  onChange?: (nextURL: URL, currentURL: URL) => void;
};

export const useMyWorkBackNavigation = ({ onChange }: UseMyWorkBackNavigationParams = {}) => {
  const { $rootScope } = useInjector('$rootScope');

  useEffect(() => {
    // Subscribe to $locationChangeSuccess so we know when the location has changed.
    const unsubscribe = $rootScope.$on('$locationChangeSuccess', function (_, next, current) {
      // Convert next and current into a URL object so it's easier to check values like `pathname` and `search`
      const nextURL = new URL(next);
      const currentURL = new URL(current);

      // Checks if after the route change the application still in `/work`
      // We do this to ensure that we don't execute any code inside other location
      const isInMyWork = nextURL.pathname === '/work' && currentURL.pathname === '/work';

      // When the `nextURL.search` is empty, it means that the user has navigated back (the filters are removed from the URL).
      if (isInMyWork && !nextURL.search && Boolean(currentURL.search)) {
        window.history.back();
      }

      // Ensure that the application still in my work page, otherwise, we won't execute the onChange callback
      if (isInMyWork && nextURL.search !== currentURL.search) {
        onChange?.(nextURL, currentURL);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [$rootScope, onChange]);
};
