import angular from 'angular';

angular.module('frontStreetApp.services').factory('Permissions', function (ResourceFactory) {
  return ResourceFactory.create({
    canUpdateChecklist: 'GET /1/checklists/:checklistId/permissions/checklist-update',
    canCreateChecklist: 'GET /1/templates/:templateId/permissions/create-checklist',
    whoCanReadTemplate: 'GET /1/templates/:templateId/permissions/read-template/who',
    whoCanReadPage: 'GET /1/pages/:pageId/permissions/read-page/who',
    whoCanReadChecklist: 'GET /1/checklists/:checklistId/permissions/read-checklist/who',
    whoCanCreateChecklist: 'GET /1/templates/:templateId/permissions/create-checklist/who',
    getConsolidatedGlobalPermissions: 'GET /1/organization/:organizationId/permissions/consolidated',
    getConsolidatedChecklistPermissions: 'GET /1/checklists/:checklistId/permissions/consolidated',
    getConsolidatedChecklistsPermissions: 'GET[] /1/checklists/permissions/consolidated',
    getConsolidatedTemplatePermissions: 'GET /1/templates/:templateId/permissions/consolidated',
    getConsolidatedFolderPermissions: 'GET /1/folders/:folderId/permissions/consolidated',
  });
});
