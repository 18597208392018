import * as React from 'react';
import { Button, ButtonProps, Icon } from 'components/design/next';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { GetSnoozeAssignmentCountQuery } from 'app/pages/tasks/query-builder/get-snooze-assignment-count-query';
import { useDebounce } from 'use-debounce';

export type SnoozedToggleProps = {
  status?: GetInboxItemsQuery.SnoozeStatus;
  onChange: (status: GetInboxItemsQuery.SnoozeStatus) => void;
};

export const SnoozedToggle = ({ status, onChange }: SnoozedToggleProps) => {
  const isSnoozed = status === GetInboxItemsQuery.SnoozeStatus.Snoozed;
  const enabledProps: ButtonProps = {
    bgColor: 'brand.100',
    color: 'brand.500',
  };

  const snoozeAssignmentCountQuery = GetSnoozeAssignmentCountQuery.useQuery();

  const count = React.useMemo(
    () => snoozeAssignmentCountQuery.data?.count ?? 0,
    [snoozeAssignmentCountQuery.data?.count],
  );

  const [debouncedCount] = useDebounce(count, 1000);

  const hasRecentlySnoozedAnItem = count !== debouncedCount;

  return (
    <Button
      aria-label={isSnoozed ? 'Show active items' : 'Show snoozed items'}
      leftIcon={<Icon icon="snooze" size="3" variant={hasRecentlySnoozedAnItem || isSnoozed ? 'fas' : 'far'} />}
      color="gray.600"
      _disabled={{
        color: 'gray.300',
        cursor: 'not-allowed',
      }}
      fontWeight={debouncedCount !== count ? 700 : 400}
      variant="ghost"
      px={{ base: 3, md: 4 }}
      onClick={() =>
        onChange(
          status === GetInboxItemsQuery.SnoozeStatus.Active || !status
            ? GetInboxItemsQuery.SnoozeStatus.Snoozed
            : GetInboxItemsQuery.SnoozeStatus.Active,
        )
      }
      _hover={{
        bgColor: 'brand.100',
      }}
      justifyContent="flex-start"
      transition="500ms ease font-weight"
      {...(isSnoozed ? enabledProps : {})}
    >
      Snoozed ({count >= 100 ? `99+` : count})
    </Button>
  );
};
