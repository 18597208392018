import { Muid, Ref } from './core-model';
import { Organization, OrganizationMembership } from './organization-model';
import { User } from './user-model';

export enum GroupType {
  AllAdmins = 'AllAdmins',
  AllFreeMembers = 'AllFreeMembers',
  AllMembers = 'AllMembers',
  AllGuests = 'AllGuests',
  AllAnonymous = 'AllAnonymous',
  Standard = 'Standard',
}

export interface Group {
  readonly id: Muid;
  organization: Ref<Organization>;
  user: Ref<User>;
  groupType: GroupType;
}

export interface GroupMembership {
  readonly id: Muid;
  organization: Ref<Organization>;
  group: Ref<Group>;
  organizationMembership: Ref<OrganizationMembership>;
}

export interface GroupMembershipWithUser {
  groupMembership: GroupMembership;
  organizationMembership: OrganizationMembership;
  user: User;
}

export interface GroupStats {
  id: Muid;
  totalCnt: number;
}
