import { bindStateToStateProperty } from './bind-state-to-state-property';
import { bindActionCreatorsToActions } from './bind-action-creators-to-actions';

/**
 * Global map of service subscriptions,
 * This is a utility to support testing in Angular, otherwise services get recreated but never unsubscribe
 */
const serviceUnsubscribeMap = {};
/**
 * Utility function that should be used to subscribe services to the Redux.
 * Advantages of using it:
 *  - Automatically following convention for dispatch (bind all actions to actions property)
 *  - Automatically following convention fo state mapping (bind Redux state to state variable)
 *  - Automatically manage subscription (useful for tests memory leaks)
 */
export const connectService = (serviceName, store, mapStateToThis, mapDispatchToThis) => service => {
  if (serviceUnsubscribeMap[serviceName]) {
    serviceUnsubscribeMap[serviceName]();
  }

  const bindState = bindStateToStateProperty(mapStateToThis);
  const bindActions = bindActionCreatorsToActions(mapDispatchToThis);

  const unsubscribe = store.connect(bindState, bindActions)(service);

  serviceUnsubscribeMap[serviceName] = unsubscribe;

  return unsubscribe;
};
