export const MacosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'] as const;
export type MacosPlatform = typeof MacosPlatforms[number];
export const isMacos = (platform: string): platform is MacosPlatform => {
  return MacosPlatforms.includes(platform as MacosPlatform);
};

export const WindowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'] as const;
export type WindowsPlatform = typeof WindowsPlatforms[number];
export const isWindows = (platform: string): platform is WindowsPlatform => {
  return WindowsPlatforms.includes(platform as WindowsPlatform);
};

export const IosPlatforms = ['iPhone', 'iPad', 'iPod'] as const;
export type IosPlatform = typeof IosPlatforms[number];
export const isIos = (platform: string): platform is IosPlatform => {
  return IosPlatforms.includes(platform as IosPlatform);
};

export const isAndroid = (userAgent: string): boolean => /Android/.test(userAgent);
export const isLinux = (platform: string): boolean => /Linux/.test(platform);

export type OS = 'MacOS' | 'iOS' | 'Windows' | 'Android' | 'Linux';

export const MetaKeyMap: Record<OS, string> = {
  Android: '',
  Linux: 'Ctrl',
  MacOS: '⌘',
  Windows: 'Ctrl',
  iOS: '',
};

export const getOS = ({ platform, userAgent }: { userAgent: string; platform: string }): OS | undefined => {
  if (isMacos(platform)) {
    return 'MacOS';
  } else if (isIos(platform)) {
    return 'iOS';
  } else if (isWindows(platform)) {
    return 'Windows';
  } else if (isAndroid(userAgent)) {
    return 'Android';
  } else if (isLinux(platform)) {
    return 'Linux';
  }
  return undefined;
};

export const useOS = (): { os: OS | undefined; metaSymbol: string } => {
  if (typeof window === 'undefined') {
    return { os: undefined, metaSymbol: '' };
  }

  const os = getOS({ platform: window.navigator.platform, userAgent: window.navigator.userAgent });
  return { os, metaSymbol: os ? MetaKeyMap[os] : '' };
};
