import { isAdmin, UserType } from '@process-street/subgrade/core';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

export const useAdminEmails = (): string[] => {
  const org = useSelector(SessionSelector.getSelectedOrganization);
  const omsQuery = useGetAllOrganizationMembershipsQuery({ organizationId: org?.id });

  const emails = new Set(
    omsQuery.data?.filter(om => isAdmin(om.role) && om.user.userType === UserType.Standard).map(om => om.user.email) ??
      [],
  );

  const [adminEmail, ...bcc] = Array.from(emails);

  return [adminEmail, ...bcc];
};
