import { ActorRefFrom, assign, createMachine, send, Sender, sendParent, spawn } from 'xstate';
import { Muid } from '@process-street/subgrade/core';
import {
  FormFieldWidgetMachine,
  makeFormFieldMachine,
} from 'pages/responses/_id/components/form-fields/form-field-machine';
import {
  Checklist,
  ChecklistRevision,
  ChecklistStatus,
  FieldType,
  FormFieldValue,
  FormFieldValueByFieldType,
  isFormFieldWidget,
  Task,
  TaskStatus,
  TaskUtils,
  TaskWithTaskTemplate,
  Widget,
  WidgetType,
} from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { FormFieldMachineEvent, WithSharedContext } from '../../types';
import { makeTextContentWidgetMachine } from '../content/text-content-widget/text-content-widget-machine';
import { makeImageContentWidgetMachine } from '../content/image-content-widget/image-content-widget-machine';
import {
  makeRulesEngineTargetMachine,
  RulesEngineTargetParentEvent,
} from '../form-response-body/rules-engine-machine/rules-engine-target-machine';
import {
  FormResponseMachineReceptionist,
  makeRulesEngineTargetWidgetKey,
} from '../../form-response-machine-receptionist';
import { ResponseWidgetActorRef } from '../form-fields';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { makeFileContentWidgetMachine } from '../content/file-content-widget/file-content-widget-machine';
import { UpdateTaskStatusMutation } from 'features/task/query-builder';
import { GetChecklistQuery } from 'app/features/checklists/query-builder';
import { GetAllTasksByChecklistRevisionIdQuery } from 'features/task/query-builder';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { Approval, ApprovalRuleSubject } from '@process-street/subgrade/approval-rule';

export type Context = {
  approvalRules: ApprovalRuleSubject[];
  approvals: Approval[];
  task: TaskWithTaskTemplate;
  widgetActorsMap: Record<Muid, ResponseWidgetActorRef>;
  widgets: Widget[];
  invalidWidgetMap: Record<Muid, boolean>;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
};

export type Event =
  | { type: 'ASSIGN_TASK'; data: { task: Task } }
  | { type: 'COMPLETE_TASK' }
  | { type: 'UNCOMPLETE_TASK' }
  | { type: 'SKIP_TASK' }
  | { type: 'TASK_STATUS_UPDATE_SUCCESS' }
  | { type: 'TASK_STATUS_UPDATE_FAILED' }
  | { type: 'FORM_COMPLETE' }
  | { type: 'REVEAL_INVALID' }
  | {
      type: `${'INVALID' | 'VALID'}_WIDGET`;
      widgetId: Muid;
    }
  | RulesEngineTargetParentEvent;

export type TaskMachineBuilderProps = {
  checklist: Checklist;
  checklistRevision: ChecklistRevision;
  task: TaskWithTaskTemplate;
  widgets: Widget[];
  formFieldValues: FormFieldValue[];
  isEditable: boolean;
  approvalRules: ApprovalRuleSubject[];
  approvals: Approval[];
};

export type TaskMachineBuilderInternalProps = WithSharedContext<TaskMachineBuilderProps>;

export const makeTaskMachine = (props: TaskMachineBuilderInternalProps) => {
  const { sharedContext, checklistRevision, task, widgets, formFieldValues, isEditable, checklist } = props;
  const { queryClient } = sharedContext;

  const id = `task-machine:${task.id}`;

  return createMachine(
    {
      context: () =>
        ({
          task,
          widgets,
          widgetActorsMap: {},
          invalidWidgetMap: {},
          rulesEngineTargetActor: spawn(
            makeRulesEngineTargetMachine({ type: 'task', taskTemplateGroupId: task.taskTemplate.group.id }),
            { name: 'hidden-by-rule-actor' },
          ),
        } as Context),
      tsTypes: {} as import('./task-machine.typegen').Typegen0,
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as {
          updateTaskStatus: {
            data: UpdateTaskStatusMutation.Response;
          };
        },
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
      id,
      type: 'parallel',
      states: {
        task: {
          initial: 'init',
          states: {
            init: {
              entry: ['spawnWidgetActors'],
              always: [
                {
                  target: 'complete',
                  cond: 'isComplete',
                },
                {
                  target: 'incomplete',
                },
              ],
            },
            incomplete: {
              on: {
                COMPLETE_TASK: {
                  target: '#mutation.waiting',
                },
                SKIP_TASK: {
                  actions: ['sendParentSkipTask'],
                },
                TASK_STATUS_UPDATE_SUCCESS: {
                  target: 'complete',
                  actions: ['sendParentTaskUpdateSuccess'],
                },
                TASK_STATUS_UPDATE_FAILED: {
                  actions: ['sendParentTaskUpdateFailed'],
                },
                ASSIGN_TASK: {
                  actions: ['updateTaskContext', 'sendParentTaskUpdateSuccess'],
                },
              },
            },
            complete: {
              on: {
                COMPLETE_TASK: {
                  actions: ['sendParentTaskUpdateSuccess'],
                },
                UNCOMPLETE_TASK: {
                  target: '#mutation.waiting',
                },
                TASK_STATUS_UPDATE_SUCCESS: {
                  target: 'incomplete',
                  actions: ['sendParentTaskUpdateSuccess'],
                },
                TASK_STATUS_UPDATE_FAILED: {
                  actions: ['sendParentTaskUpdateFailed'],
                },
                ASSIGN_TASK: {
                  actions: ['updateTaskContext', 'sendParentTaskUpdateSuccess'],
                },
              },
            },
          },
        },
        mutation: {
          initial: 'idle',
          id: 'mutation',
          states: {
            idle: {},
            waiting: {
              after: {
                WIDGET_STATUS_POLLING: [
                  {
                    cond: 'areAllWidgetsIdle',
                    target: 'updating',
                  },
                  {
                    target: 'waiting',
                  },
                ],
              },
            },
            updating: {
              invoke: {
                src: 'updateTaskStatus',
                onDone: {
                  target: 'idle',
                  actions: ['updateTaskContext', 'sendTaskUpdateSuccess'],
                },
                onError: {
                  target: 'idle',
                  actions: ['logError', 'showErrorToast', 'sendTaskUpdateFailed'],
                },
              },
            },
          },
        },
        validation: {
          initial: 'valid',
          states: {
            valid: {
              on: {
                INVALID_WIDGET: { target: 'invalid', actions: ['addInvalidWidget', 'sendParentInvalid'] },
              },
            },
            invalid: {
              on: {
                REVEAL_INVALID: { target: '.visible', actions: 'sendRevealInvalidToWidgets' },
                INVALID_WIDGET: { actions: ['addInvalidWidget', 'sendParentInvalid'] },
                VALID_WIDGET: [
                  {
                    cond: 'areAllWidgetsValid',
                    target: 'valid',
                    actions: ['removeInvalidWidget', 'sendParentValid'],
                  },
                  { actions: ['removeInvalidWidget'] },
                ],
              },
              initial: 'hidden',
              states: {
                hidden: {},
                visible: {
                  entry: ['sendScrollIntoViewToFirstInvalidWidget'],
                },
              },
            },
          },
        },
        visibility: {
          initial: 'visible',
          states: {
            visible: {
              on: {
                HIDE: { target: 'hidden' },
              },
            },
            hidden: {
              on: {
                REVEAL: { target: 'visible' },
              },
            },
          },
        },
      },
      on: {
        FORM_COMPLETE: {
          actions: ['sendFormCompleteEventToWidgets'],
        },
      },
    },
    {
      guards: {
        isComplete: (ctx, _) => {
          return ctx.task.status === TaskStatus.Completed;
        },
        areAllWidgetsValid: (ctx, evt) => {
          const { [evt.widgetId]: _, ...rest } = ctx.invalidWidgetMap;
          return Object.keys(rest).length === 0;
        },
        areAllWidgetsIdle: (ctx, _) => {
          return Object.values(ctx.widgetActorsMap)
            .filter(widgetActor => isFormFieldWidget(widgetActor.getSnapshot()!.context.widget))
            .every(widgetActor => {
              // @ts-expect-error -- TODO
              // All form field actors should have the "mutation.idle" state but the typegen types are too strict
              return widgetActor.getSnapshot()?.matches('mutation.idle');
            });
        },
      },
      services: {
        updateTaskStatus: async (ctx, _) => {
          const status = ctx.task.status === TaskStatus.Completed ? TaskStatus.NotCompleted : TaskStatus.Completed;

          return makeMutation(queryClient, {
            mutationKey: UpdateTaskStatusMutation.key,
            mutationFn: () =>
              UpdateTaskStatusMutation.mutationFn({
                taskId: ctx.task.id,
                status,
              }),
            onMutate: () => {
              const tasksQueryKey = GetAllTasksByChecklistRevisionIdQuery.getKey({
                checklistRevisionId: checklistRevision.id,
              });
              const previousTasks = queryClient.getQueryData(tasksQueryKey);

              const rollback = () => {
                queryClient.setQueryData(tasksQueryKey, previousTasks);
              };

              queryClient.setQueryData<TaskWithTaskTemplate[] | undefined>(tasksQueryKey, (tasks = []) => {
                const tasksWithTargetTaskUpdated = tasks.map(task =>
                  task.id === ctx.task.id
                    ? {
                        ...task,
                        status,
                      }
                    : task,
                );

                // Finds the next stop task that is not completed yet
                const firstUncompletedStopTaskIndex = tasksWithTargetTaskUpdated.findIndex(
                  task => !TaskUtils.isCompleted(task) && TaskUtils.isStop(task),
                );

                const tasksWithStoppedUpdated = tasksWithTargetTaskUpdated.map((task, index) => {
                  if (status === TaskStatus.Completed) {
                    return {
                      ...task,
                      stopped:
                        // When completing a task, mark every task before the next uncompleted stop task as not `stopped`
                        firstUncompletedStopTaskIndex === -1 || index <= firstUncompletedStopTaskIndex
                          ? false
                          : task.stopped,
                    };
                  } else {
                    return {
                      ...task,
                      // When uncompleting a task, mark every task after the next uncompleted stop task as `stopped`
                      stopped: index > firstUncompletedStopTaskIndex ? true : task.stopped,
                    };
                  }
                });

                return tasksWithStoppedUpdated;
              });

              return rollback;
            },
            onSuccess: response => {
              void queryClient.invalidateQueries(
                GetAllTasksByChecklistRevisionIdQuery.getKey({
                  checklistRevisionId: checklistRevision.id,
                }),
              );
              if (response.checklistStatus === ChecklistStatus.Completed) {
                void sharedContext.queryClient.invalidateQueries(
                  GetChecklistQuery.getKey({ checklistId: checklist.id }),
                );
              }
              queryClient.setQueryData<TaskWithTaskTemplate[]>(
                GetAllTasksByChecklistRevisionIdQuery.getKey({ checklistRevisionId: checklistRevision.id }),
                existingData => {
                  if (!existingData) return [];

                  const newData = existingData.map(task => {
                    if (task.id !== response.task.id) return task;

                    return {
                      ...response.task,
                      taskTemplate: task.taskTemplate,
                    };
                  });

                  return newData;
                },
              );
            },
            onError: (_error, _variables, rollback) => {
              if (typeof rollback === 'function') {
                (rollback as () => {})();
              }
            },
          }).execute();
        },
      },
      delays: {
        WIDGET_STATUS_POLLING: 100,
      },
      actions: {
        spawnWidgetActors: assign({
          widgetActorsMap: (_, __) => {
            return Object.fromEntries(
              widgets.flatMap((widget, index) => {
                return match(widget)
                  .with(
                    {
                      fieldType: P.union(
                        FieldType.Text,
                        FieldType.Textarea,
                        FieldType.Email,
                        FieldType.Url,
                        FieldType.Select,
                        FieldType.MultiChoice,
                        FieldType.File,
                        FieldType.Date,
                        FieldType.Number,
                        FieldType.Table,
                      ),
                    },
                    w => {
                      const formFieldValue = formFieldValues.find(ffv => ffv.formFieldWidget.id === widget.id);
                      const formFieldWidgetMachine = spawn(
                        makeFormFieldMachine({
                          sharedContext,
                          checklistRevisionId: checklistRevision.id,
                          formFieldWidget: w,
                          formFieldValue: formFieldValue as FormFieldValueByFieldType<typeof w['fieldType']>,
                          autoFocus: index === 0,
                          isEditable,
                          inputNode: null,
                        }),
                        `form-field-machine:${widget.id}`,
                      ) as ResponseWidgetActorRef;
                      FormResponseMachineReceptionist.register({
                        name: makeRulesEngineTargetWidgetKey(widget),
                        actorRef: formFieldWidgetMachine.getSnapshot()!.context.rulesEngineTargetActor,
                      });

                      return [[widget.id, formFieldWidgetMachine]];
                    },
                  )
                  .with({ header: { type: WidgetType.Text } }, w => {
                    const actor = spawn(makeTextContentWidgetMachine({ widget: w }), {
                      name: `text-content-widget-machine:${w.id}`,
                    });
                    FormResponseMachineReceptionist.register({
                      name: makeRulesEngineTargetWidgetKey(w),
                      actorRef: actor.getSnapshot()!.context.rulesEngineTargetActor,
                    });
                    return [[w.id, actor]];
                  })
                  .with({ header: { type: WidgetType.Image } }, w => {
                    const actor = spawn(makeImageContentWidgetMachine({ widget: w }), {
                      name: `image-content-widget-machine:${w.id}`,
                    });
                    FormResponseMachineReceptionist.register({
                      name: makeRulesEngineTargetWidgetKey(w),
                      actorRef: actor.getSnapshot()!.context.rulesEngineTargetActor,
                    });
                    return [[w.id, actor]];
                  })
                  .with({ header: { type: WidgetType.File } }, w => {
                    const actor = spawn(makeFileContentWidgetMachine({ widget: w }), {
                      name: `file-content-widget-machine:${w.id}`,
                    });
                    FormResponseMachineReceptionist.register({
                      name: makeRulesEngineTargetWidgetKey(w),
                      actorRef: actor.getSnapshot()!.context.rulesEngineTargetActor,
                    });
                    return [[w.id, actor]];
                  })
                  .otherwise(() => []);
              }),
            ) as Record<Muid, ResponseWidgetActorRef>;
          },
        }),
        updateTaskContext: assign({
          task: (ctx, event) => {
            return {
              ...event.data.task,
              taskTemplate: ctx.task.taskTemplate,
            };
          },
        }),
        sendTaskUpdateSuccess: send('TASK_STATUS_UPDATE_SUCCESS'),
        sendTaskUpdateFailed: send('TASK_STATUS_UPDATE_FAILED'),
        sendParentTaskUpdateSuccess: sendParent(() => ({
          type: 'TASK_STATUS_UPDATE_SUCCESS',
        })),
        sendParentTaskUpdateFailed: sendParent('TASK_STATUS_UPDATE_FAILED'),
        sendParentSkipTask: sendParent('SKIP_TASK'),
        sendFormCompleteEventToWidgets: ctx => {
          Object.values(ctx.widgetActorsMap)
            .filter(isFormFieldWidgetActor)
            .forEach(widgetActor => {
              (widgetActor.send as Sender<Event>)({ type: 'FORM_COMPLETE' });
            });
        },
        showErrorToast: (_, __) => {
          ToastServiceImpl.openToast({
            status: 'error',
            title: "We're having problems completing the task",
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
        addInvalidWidget: assign({
          invalidWidgetMap: (ctx, event) => {
            ctx.invalidWidgetMap[event.widgetId] = true;
            return ctx.invalidWidgetMap;
          },
        }),
        removeInvalidWidget: assign({
          invalidWidgetMap: (ctx, event) => {
            delete ctx.invalidWidgetMap[event.widgetId];
            return ctx.invalidWidgetMap;
          },
        }),
        sendParentInvalid: sendParent({ type: 'INVALID_TASK', taskId: task.id }),
        sendParentValid: sendParent({ type: 'VALID_TASK', taskId: task.id }),
        sendRevealInvalidToWidgets: ctx => {
          Object.values(ctx.widgetActorsMap)
            .filter(isFormFieldWidgetActor)
            .forEach(widgetActor => {
              (widgetActor.send as Sender<Event>)({ type: 'REVEAL_INVALID' });
            });
        },
        sendScrollIntoViewToFirstInvalidWidget: ctx => {
          const firstInvalidWidgetId = Object.keys(ctx.invalidWidgetMap)[0];
          const firstInvalidWidgetActor = ctx.widgetActorsMap[firstInvalidWidgetId];
          if (firstInvalidWidgetActor) {
            (firstInvalidWidgetActor.send as Sender<FormFieldMachineEvent>)({ type: 'SCROLL_INTO_VIEW' });
          }
        },
        logError: makeErrorLoggerAction(id),
      },
    },
  );
};

function isFormFieldWidgetActor(actor: ResponseWidgetActorRef): actor is ActorRefFrom<FormFieldWidgetMachine> {
  return isFormFieldWidget(actor.getSnapshot()?.context.widget!);
}

export type TaskMachine = ReturnType<typeof makeTaskMachine>;
export type TaskActor = ActorRefFrom<TaskMachine>;
