import { Option } from '@process-street/subgrade/core';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import angular from 'angular';
import templateUrl from './item.component.html';
import './item.component.scss';

export class ApprovalRuleSubjectTasksListItemController {
  public templateRevision: Option<TemplateRevision>;
  public taskTemplate: Option<TaskTemplate>;

  public $onChanges(changes: angular.IOnChangesObject) {
    if (changes.taskTemplate) {
      this.taskTemplate = changes.taskTemplate.currentValue;
    }
    if (changes.templateRevision) {
      this.templateRevision = changes.templateRevision.currentValue;
    }
  }
}

export const ApprovalRuleSubjectTasksListItemComponent: angular.IComponentOptions = {
  bindings: {
    disabled: '<',
    onRemove: '&',
    onSelect: '&',
    taskTemplate: '<',
    templateRevision: '<',
  },
  controller: ApprovalRuleSubjectTasksListItemController,
  templateUrl,
};
