import * as React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Spacer,
  useDisclosure,
} from 'components/design/next';
import { TagManager } from '../tag-manager';
import { Muid } from '@process-street/subgrade/core';

export interface TagsModalProps extends Partial<ModalProps> {
  templateId: Muid;
}

export const TagsModal: React.FC<React.PropsWithChildren<TagsModalProps>> = React.memo(({ templateId, ...props }) => {
  const modal = useDisclosure({ id: 'manage-tags', ...props });
  return (
    <Modal {...modal} size="xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent pt="0">
        <ModalHeader fontSize="lg">Manage Tags</ModalHeader>
        <ModalCloseButton />
        <ModalBody px="6">
          <TagManager autoFocus templateId={templateId} />
        </ModalBody>
        <ModalFooter borderTop="1px" borderColor="gray.200">
          <Spacer />
          <Button onClick={() => modal.onClose()} variant="secondary">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
