import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { OneOffTask } from '@process-street/subgrade/one-off-task';

export namespace GetOneOffTasksByChecklistQuery {
  export type Params = { checklistId?: Muid };

  export type Response = OneOffTask[];

  export const key = ['checklists', 'one-off-tasks'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/checklists/${params.checklistId}/one-off-tasks`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.checklistId) && options.enabled !== false,
    });
  };
}
