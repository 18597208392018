import { ClearIndicator } from 'components/design/BlvdSelect/components/ClearIndicator';
import { DropdownIndicator } from 'components/design/BlvdSelect/components/DropdownIndicator';
import { Input } from 'components/design/BlvdSelect/components/Input';
import { Menu } from 'components/design/BlvdSelect/components/Menu';
import { FixedMultiValueContainer } from 'components/design/BlvdSelect/components/multi/FixedMultiValueContainer';
import { MultiOption } from 'components/design/BlvdSelect/components/multi/MultiOption';
import { MultiValueLabel } from 'components/design/BlvdSelect/components/multi/MultiValueLabel';
import { MultiValueRemove } from 'components/design/BlvdSelect/components/multi/MultiValueRemove';
import { SingleOption } from 'components/design/BlvdSelect/components/single/SingleOption';
import { SingleValue } from 'components/design/BlvdSelect/components/single/SingleValue';
import { VirtualizedMenuList } from 'components/design/BlvdSelect/components/VirtualizedMenuList';
import React, { FocusEvent, useRef } from 'react';

import Select, {
  components as defaultComponents,
  createFilter,
  GroupBase,
  InputActionMeta,
  Props as SelectProps,
  SelectComponentsConfig,
  SelectInstance,
} from 'react-select';
import './BlvdSelect.scss';
import classNames from 'classnames';
import { useMergeRefs } from 'components/design/next';
import { useBlvdSelectStyles } from 'components/design/BlvdSelect/use-blvd-select-styles';
import { useBlvdSelectMaxHeight } from './hooks/useBlvdSelectMaxHeight';

export * from 'react-select';

// This value is used for the value container children by default, but our custom components need to declare it.
export const BLVD_SELECT_MAGIC_GRID_AREA = '1/1/2/3';

export type BlvdSelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = SelectProps<Option, IsMulti, Group> & {
  selectRef?: React.RefObject<SelectInstance<Option, IsMulti, Group>>;
  enableSearchAutoFocus?: boolean;
  maxOptionsBeforeVirtualization?: number;
  optionHeight?: number;
  isInvalid?: boolean;
  shouldKeepInputOnSelect?: boolean;
};

export const BlvdSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: BlvdSelectProps<Option, IsMulti, Group>,
) => {
  const {
    options,
    className,
    components,
    onMenuOpen,
    isMulti = false,
    isSearchable = false,
    isClearable = false,
    menuControls = false,
    fixedSize = false,
    maxOptionsBeforeVirtualization = 200,
    filterOption,
    enableSearchAutoFocus,
    isInvalid,
    selectRef: ref,
    getOptionValue = defaultGetOptionValue,
    getOptionLabel = defaultGetOptionLabel,
    getOptionIcon = defaultGetOptionIcon,
    shouldKeepInputOnSelect,
    ...rest
  } = props;

  const selectRef = useRef<SelectInstance<Option, IsMulti, Group>>(null);
  const mergedRef = useMergeRefs(ref, selectRef);
  const maxHeight = useBlvdSelectMaxHeight<Option, IsMulti, Group>();

  // Unfortunately our current version of react-select doesn't support aria-invalid, so using this effect hack.
  React.useEffect(() => {
    if (selectRef.current?.controlRef) {
      const div = selectRef.current.controlRef;
      div.setAttribute('aria-invalid', isInvalid ? 'true' : 'false');
      const input = selectRef.current.inputRef;
      input?.setAttribute('aria-invalid', isInvalid ? 'true' : 'false');
    }
  }, [isInvalid]);

  const commonComponents: SelectComponentsConfig<Option, IsMulti, Group> = {
    ClearIndicator,
    DropdownIndicator,
    Input,
    Menu,
    MenuList:
      Array.isArray(options) && options.length > maxOptionsBeforeVirtualization
        ? VirtualizedMenuList
        : defaultComponents.MenuList,
  };

  const singleComponents: SelectComponentsConfig<Option, IsMulti, Group> = {
    Option: SingleOption,
    SingleValue,
  };

  const multiComponents: SelectComponentsConfig<Option, IsMulti, Group> = {
    MultiValueLabel,
    MultiValueRemove,
    Option: MultiOption,
    ValueContainer: fixedSize ? FixedMultiValueContainer : defaultComponents.ValueContainer,
  };

  const selectComponents = isMulti
    ? { ...commonComponents, ...multiComponents, ...components }
    : { ...commonComponents, ...singleComponents, ...components };

  /**
   * Ensure the select retains focus when the search input looses focus
   */
  const onSearchInputBlur = (_: FocusEvent<HTMLInputElement>) => {
    selectRef.current?.focus();
  };

  /**
   * Allows us to close the select from child components
   */
  const close = () => {
    selectRef.current?.blur();
  };

  const onMenuOpenHandler = () => {
    onMenuOpen?.();
    maxHeight.onMenuOpen(selectRef);

    if (enableSearchAutoFocus)
      requestAnimationFrame(() => {
        const input = document.getElementById('blvd-select__menu__header__search-input');
        input?.focus();
      });
  };

  const selectClassNames = classNames(['blvd-select', className]);
  const styles = useBlvdSelectStyles<Option, IsMulti, Group>(props.styles);

  return (
    <Select<Option, IsMulti, Group>
      ref={mergedRef}
      className={selectClassNames}
      classNamePrefix={'blvd-select'}
      components={selectComponents}
      filterOption={filterOption ?? createFilter({ ignoreAccents: false })}
      closeMenuOnSelect={!props.isMulti}
      isClearable={isClearable}
      menuControls={isMulti ? menuControls : false}
      isSearchable={isSearchable}
      isMulti={props.isMulti}
      maxMenuHeight={276}
      onSearchInputBlur={onSearchInputBlur}
      tabSelectsValue={false}
      backspaceRemovesValue={false}
      hideSelectedOptions={false}
      options={options}
      close={close}
      onMenuOpen={onMenuOpenHandler}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      getOptionIcon={getOptionIcon}
      onInputChange={shouldKeepInputOnSelect ? keepInputOnSelect : undefined}
      {...rest}
      // styles prop is already processed via the hook
      styles={styles}
    />
  );
};

function defaultGetOptionLabel<Option>(option: Option) {
  return typeof option === 'object' && option !== null ? (option as any).label : undefined;
}

function defaultGetOptionValue<Option>(option: Option) {
  return typeof option === 'object' && option !== null ? (option as any).value : undefined;
}

function defaultGetOptionIcon<Option>(option: Option) {
  return typeof option === 'object' && option !== null ? (option as any).icon : undefined;
}

// Don't reset search bar input on item selection
function keepInputOnSelect(inputValue: string, { action, prevInputValue }: InputActionMeta) {
  if (action === 'input-change') return inputValue;
  return prevInputValue;
}
