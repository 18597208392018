import { Muid, Option } from '@process-street/subgrade/core';
import { TaskTemplate, TaskTemplateTaskType } from '@process-street/subgrade/process';
import { htmlEscaped, StringUtils } from '@process-street/subgrade/util';
import angular from 'angular';
import { FocusById } from 'services/focus.interface';
import { TaskTemplateService } from 'services/task-template-service.interface';
import { uuid } from 'services/uuid';
import templateUrl from './select.component.html';
import './select.component.scss';
import { trace } from 'components/trace';

export class ApprovalRuleSubjectTasksSelectorModalController implements angular.IController {
  public approvalTaskTemplate: Option<TaskTemplate>;
  public selectedTaskTemplates: Option<TaskTemplate[]>;
  public taskTemplates: Option<TaskTemplate[]>;

  public localTaskTemplates: TaskTemplate[] = [];
  public matchingTaskTemplates: TaskTemplate[] = [];
  public disabledTaskTemplateGroupIds: Muid[] = [];
  public selectedTaskTemplateGroupIds: Muid[] = [];

  public taskTemplateSearch = '';
  public loadingMore = false;
  public allTaskTemplatesLoaded = false;

  private focusId = uuid();

  private readonly matchingTaskTemplatesPageSize = 50;

  private logger;

  static $inject = ['focusById', 'TaskTemplateService'];

  constructor(private focusById: FocusById, private taskTemplateService: TaskTemplateService) {
    this.logger = trace({ name: 'psTaskTemplatesSelect' });
  }

  public $onChanges(changes: angular.IOnChangesObject) {
    if (changes.selectedTaskTemplates && changes.selectedTaskTemplates.currentValue) {
      this.selectedTaskTemplates = angular.copy(changes.selectedTaskTemplates.currentValue);
      this.flagSelectedTaskTemplates();
    }

    if (changes.taskTemplates && changes.taskTemplates.currentValue) {
      this.taskTemplates = changes.taskTemplates.currentValue;
      this.localTaskTemplates = angular.copy(this.taskTemplates!);
      this.flagSelectedTaskTemplates();
    }

    if (changes.approvalTaskTemplate && changes.approvalTaskTemplate.currentValue) {
      this.approvalTaskTemplate = angular.copy(changes.approvalTaskTemplate.currentValue);
    }

    this.searchTaskTemplates('');
  }

  public searchTaskTemplates(query: string) {
    this.taskTemplateSearch = query;

    this.allTaskTemplatesLoaded = false;

    this.matchingTaskTemplates = this.localTaskTemplates
      .filter((tt: TaskTemplate) => StringUtils.containsIgnoreCase(tt.name, query))
      .slice(0, this.matchingTaskTemplatesPageSize);
  }

  public loadMoreMatchingTaskTemplates() {
    this.loadingMore = true;
    const query = this.taskTemplateSearch;

    const start = this.matchingTaskTemplates.length;
    const end = this.matchingTaskTemplates.length + this.matchingTaskTemplatesPageSize;

    const nextPage = this.localTaskTemplates
      .filter(tmpl => StringUtils.containsIgnoreCase(tmpl.name, query))
      .slice(start, end);

    this.matchingTaskTemplates = this.matchingTaskTemplates.concat(nextPage);

    if (this.localTaskTemplates.length === this.matchingTaskTemplates.length) {
      this.allTaskTemplatesLoaded = true;
    }

    this.loadingMore = false;
  }

  public isInfiniteScrollDisabled() {
    return this.loadingMore || this.allTaskTemplatesLoaded;
  }

  public areTasksFound() {
    return this.matchingTaskTemplates.length > 0;
  }

  public onTaskTemplateSelectorToggle() {
    this.taskTemplateSearch = '';
    this.focusById(`taskTemplateSearch-${this.focusId}`);
  }

  public isHeading(taskTemplate: TaskTemplate) {
    return this.taskTemplateService.isHeading(taskTemplate);
  }

  public toggleTaskTemplateSelection(taskTemplate: TaskTemplate) {
    if (this.isTaskTemplateSelected(taskTemplate)) {
      this.unselectTaskTemplate(taskTemplate);
    } else if (this.isSelectable(taskTemplate)) {
      this.selectedTaskTemplateGroupIds.push(taskTemplate.group.id);
      this.selectedTaskTemplates!.push(taskTemplate);
    }

    if (this.isHeading(taskTemplate)) {
      this.syncChildren(taskTemplate);
    }
  }

  public selectAll() {
    this.selectedTaskTemplates = [];
    this.localTaskTemplates
      .filter(tt => this.isSelectable(tt as TaskTemplate))
      .forEach(tt => {
        this.selectedTaskTemplates!.push(tt);
      });
  }

  public unSelectAll() {
    this.selectedTaskTemplates = [];
  }

  public done() {
    // @ts-expect-error -- TODO
    this.onChange({ selected: this.selectedTaskTemplates });
  }

  public isDisabled(taskTemplate: TaskTemplate) {
    return this.disabledTaskTemplateGroupIds.includes(taskTemplate.group.id);
  }

  public flagSelectedTaskTemplates() {
    const approvalTaskTemplateIndex = this.getIndexOfApprovalTaskTemplate();

    this.disabledTaskTemplateGroupIds = [];
    this.selectedTaskTemplateGroupIds = [];
    this.localTaskTemplates.forEach((taskTemplate, index) => {
      const taskIsAfterApproval = index >= approvalTaskTemplateIndex;
      const taskIsApproval = taskTemplate.taskType === TaskTemplateTaskType.Approval;
      const taskIsAi = taskTemplate.taskType === TaskTemplateTaskType.AI;
      if (taskIsAfterApproval || taskIsApproval || taskIsAi) {
        this.disabledTaskTemplateGroupIds.push(taskTemplate.group.id);
      }
      if (this.isTaskTemplateSelected(taskTemplate)) {
        this.selectedTaskTemplateGroupIds.push(taskTemplate.group.id);
      }
    });
  }

  public isTaskTemplateSelected(taskTemplate: TaskTemplate) {
    return this.findIndexOfSelectedTaskTemplate(taskTemplate) !== -1;
  }

  public resolveTaskTemplateLabel(taskTemplate: TaskTemplate) {
    return htmlEscaped`${taskTemplate.name || this.taskTemplateService.DEFAULT_NAME}`;
  }

  public getTaskSelectionClass(taskTemplate: TaskTemplate) {
    let result = '';
    if (this.isTaskTemplateSelected(taskTemplate)) {
      result = 'checked';
    }

    return result;
  }

  public isCurrentApprovalTask = (taskTemplate: TaskTemplate) =>
    this.approvalTaskTemplate && taskTemplate.group.id === this.approvalTaskTemplate.group.id;

  private getIndexOfApprovalTaskTemplate() {
    return this.approvalTaskTemplate
      ? this.localTaskTemplates.map(tt => tt.group.id).indexOf(this.approvalTaskTemplate.group.id)
      : this.localTaskTemplates.length;
  }

  private isSelectable = (taskTemplate: TaskTemplate) =>
    !this.isDisabled(taskTemplate) && !this.isCurrentApprovalTask(taskTemplate);

  private unselectTaskTemplate(taskTemplate: TaskTemplate) {
    this.selectedTaskTemplateGroupIds.splice(this.selectedTaskTemplateGroupIds.indexOf(taskTemplate.group.id), 1);
    const index = this.findIndexOfSelectedTaskTemplate(taskTemplate);
    this.selectedTaskTemplates!.splice(index, 1);
  }

  private syncChildren(heading: TaskTemplate) {
    if (!this.isHeading(heading)) {
      this.logger.error('cannot sync children for non heading task');
    }

    const children = this.findAllChildren(heading);

    if (this.isTaskTemplateSelected(heading)) {
      children.filter(tt => !this.isTaskTemplateSelected(tt)).forEach(tt => this.toggleTaskTemplateSelection(tt));
    } else {
      const selectedChildren = children.filter(tt => this.isTaskTemplateSelected(tt));
      selectedChildren.forEach(this.unselectTaskTemplate.bind(this));
    }
  }

  private canBeSubjectTask(taskTemplate: TaskTemplate) {
    return !this.isHeading(taskTemplate) || taskTemplate.taskType === TaskTemplateTaskType.Approval;
  }

  private findAllChildren(heading: TaskTemplate) {
    const children: TaskTemplate[] = [];

    const headingIndex = this.localTaskTemplates.findIndex(tt => tt.id === heading.id);

    for (let i = headingIndex + 1; i < this.localTaskTemplates.length; i += 1) {
      if (this.canBeSubjectTask(this.localTaskTemplates[i])) {
        children.push(this.localTaskTemplates[i] as TaskTemplate);
      } else {
        break;
      }
    }

    return children;
  }

  private findIndexOfSelectedTaskTemplate(taskTemplate: TaskTemplate) {
    return this.selectedTaskTemplates!.findIndex(t => t.id === taskTemplate.id);
  }
}

export const TaskTemplatesSelectComponent: angular.IComponentOptions = {
  bindings: {
    approvalTaskTemplate: '<',
    onCancel: '&',
    onChange: '&',
    selectedTaskTemplates: '<',
    taskTemplates: '<',
  },
  controller: ApprovalRuleSubjectTasksSelectorModalController,
  templateUrl,
};
