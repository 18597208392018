import { CreatedUser, OrganizationMembershipRole } from '@process-street/subgrade/core';
import { useInjector } from 'components/injection-provider';
import * as React from 'react';
import { HttpStatus } from '@process-street/subgrade/util';
import { Button, Flex, Text, TextProps, useToast } from 'components/design/next';
import { SUCCESS_DEFAULT_DURATION, WARNING_DEFAULT_DURATION } from 'reducers/flash/flash.reducer';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { useSelector } from 'react-redux';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useFeatureFlag } from 'features/feature-flags';

export type Props = { user: CreatedUser };

const Separator: React.FC<React.PropsWithChildren<TextProps>> = props => (
  <Text as="span" variant="-2" color="gray.400" {...props}>
    &nbsp;&bull;&nbsp;
  </Text>
);

export const PendingUser: React.FC<React.PropsWithChildren<Props>> = ({ user }) => {
  const { InvitationService } = useInjector(
    'InvitationService',
    'SessionService',
    'UserService',
    'OrganizationMembershipService',
  );
  const [shouldShowResendLink, setShouldShowResendLink] = React.useState(true);

  const toast = useToast();

  const { role } = useSelector(OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId) ?? {};

  const isCurrentUserAdmin = role === OrganizationMembershipRole.Admin;
  const isUserDeactivationEnabled = useFeatureFlag('deactivatingUsers');

  const resend = () => {
    const name = user.username || user.email;
    InvitationService.resend(user).then(
      () => {
        toast({
          title: (
            <Text fontWeight="normal">
              Invitation email resent to{' '}
              <Text as="span" fontWeight="bold">
                {name}
              </Text>
              .
            </Text>
          ),
          status: 'success',
          duration: SUCCESS_DEFAULT_DURATION,
        });

        setShouldShowResendLink(false);
      },
      response => {
        setShouldShowResendLink(true);

        switch (response.status) {
          case HttpStatus.TOO_MANY_REQUESTS:
            toast({
              title: 'The invitation has already been sent',
              description: 'Please try again in 10 minutes or contact support.',
              status: 'warning',
              duration: WARNING_DEFAULT_DURATION,
            });
            setShouldShowResendLink(false);
            break;
          default:
            toast({
              status: 'error',
              title: `We're having problems resending the invitation email`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
            break;
        }
      },
    );
  };

  return (
    <Flex color="gray.400" display="inline-flex">
      {!isUserDeactivationEnabled && (
        <>
          <Separator display={['none', 'inline-flex']} />
          <Text as="span" fontWeight="bold" variant="-2">
            Pending
          </Text>
        </>
      )}
      {isCurrentUserAdmin ? (
        <>
          <Separator />
          <Button
            onClick={resend}
            isDisabled={!shouldShowResendLink}
            title={shouldShowResendLink ? undefined : 'Invitation sent less than 10 minutes ago.'}
            variant="link"
            size="xs"
            color="brand.500"
            fontWeight="normal"
          >
            {isUserDeactivationEnabled ? 'Resend invitation' : 'Resend'}
          </Button>
        </>
      ) : null}
    </Flex>
  );
};
