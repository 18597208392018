import { useToast } from 'components/design/next';
import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { axiosService } from 'services/axios-service';
import { trace } from 'components/trace';
import { CoverImageByTemplateIdQuery } from 'features/cover-image/query-builder/cover-image-by-template-id';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export namespace DeleteCoverImageMutation {
  export type Params = { templateId: Template['id'] };
  export type Response = {};

  export const key = ['delete', 'cover-image'];
  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .delete<Response>(`/1/templates/${params.templateId}/cover-image`)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, options);
  };

  export const useMutationWithToast = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    const toast = useToast();
    const logger = trace({ name: 'RemoveIcon' });
    const queryClient = useQueryClient();

    return useRQMutation(DeleteCoverImageMutation.mutationFn, {
      onSuccess: (_, variables) => {
        toast({
          status: 'success',
          title: 'Cover image deleted',
        });
        queryClient.invalidateQueries(CoverImageByTemplateIdQuery.getKey({ templateId: variables.templateId }));
      },
      onError: e => {
        logger.error(e.message);
        toast({
          status: 'error',
          title: "We're having problems deleting the cover image",
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      },
      ...options,
    });
  };
}
