import { FormFieldValue } from '@process-street/subgrade/process';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { EntityMap, LookupMap } from '@process-street/subgrade/redux/types';
import { referencesNormalizer } from 'reducers/entities/reference-normalizer';
import { FormFieldValueLookupState } from 'reducers/types';
import { toFailure, toSuccess } from 'reducers/util';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { combineReducers } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import {
  FORM_FIELD_VALUE_DELETE,
  FORM_FIELD_VALUE_GET_ALL_BY_CHECKLIST_REVISION,
  FORM_FIELD_VALUE_SET,
  FORM_FIELD_VALUE_UPDATE,
  FORM_FIELD_VALUE_UPDATE_PENDING,
  FORM_FIELD_VALUE_UPLOADED_FILE,
} from './form-field-value.actions';
import {
  FormFieldValueDeleteAction,
  FormFieldValueGetByChecklistRevisionIdAction,
  FormFieldValueSetAction,
  FormFieldValueUpdateAction,
} from './types';

const normalizeFormFieldValue = referencesNormalizer<FormFieldValue>(['checklistRevision', 'formFieldWidget']);

export const formFieldValueEntitiesReducer = handleActionsOnSuccess<EntityMap<FormFieldValue>>(
  {
    [FORM_FIELD_VALUE_GET_ALL_BY_CHECKLIST_REVISION]: (
      state: EntityMap<FormFieldValue>,
      action: FormFieldValueGetByChecklistRevisionIdAction,
    ) => EntitiesReducerUtils.upsertAll<FormFieldValue>(state, action.payload, normalizeFormFieldValue),
    // @ts-expect-error -- TODO
    [combineActions(FORM_FIELD_VALUE_UPDATE, FORM_FIELD_VALUE_UPLOADED_FILE)]: (
      state: EntityMap<FormFieldValue>,
      action: FormFieldValueUpdateAction,
    ) => EntitiesReducerUtils.upsert<FormFieldValue>(state, action.payload.formFieldValue, normalizeFormFieldValue),
    [FORM_FIELD_VALUE_SET]: (state: EntityMap<FormFieldValue>, action: FormFieldValueSetAction) =>
      EntitiesReducerUtils.upsert<FormFieldValue>(state, action.payload.formFieldValue),
    [FORM_FIELD_VALUE_DELETE]: (state: EntityMap<FormFieldValue>, action: FormFieldValueDeleteAction) =>
      EntitiesReducerUtils.delete(state, action.meta.fieldValue),
  },
  {},
);

const formFieldValueByChecklistRevisionIdLookupReducer = handleActionsOnSuccess<LookupMap>(
  {
    [FORM_FIELD_VALUE_GET_ALL_BY_CHECKLIST_REVISION]: (
      state: LookupMap,
      action: FormFieldValueGetByChecklistRevisionIdAction,
    ) =>
      LookupsReducerUtils.upsertAllUsingSelectorFunctions<FormFieldValue>(
        state,
        action.payload,
        value => value.checklistRevision.id,
      ),
    // @ts-expect-error -- TODO
    [combineActions(FORM_FIELD_VALUE_UPDATE, FORM_FIELD_VALUE_UPLOADED_FILE)]: (
      state: LookupMap,
      action: FormFieldValueUpdateAction,
    ) =>
      LookupsReducerUtils.upsertByKeyAndValue(
        state,
        action.payload.formFieldValue.checklistRevision.id,
        action.payload.formFieldValue.id,
      ),
    [FORM_FIELD_VALUE_SET]: (state: LookupMap, action: FormFieldValueSetAction) =>
      LookupsReducerUtils.upsertByKeyAndValue(
        state,
        action.payload.formFieldValue.checklistRevision.id,
        action.payload.formFieldValue.id,
      ),
    [FORM_FIELD_VALUE_DELETE]: (state: LookupMap, action: FormFieldValueDeleteAction) => {
      const {
        meta: { revisionId, fieldValue },
      } = action;
      if (fieldValue) {
        return LookupsReducerUtils.deleteByKeyAndValue(state, revisionId, fieldValue.id);
      }
      return state;
    },
  },
  {},
);

export const formFieldValueLookupReducer = combineReducers<FormFieldValueLookupState>({
  byChecklistRevisionId: formFieldValueByChecklistRevisionIdLookupReducer,
});

export interface FormFieldValueModuleState {
  readonly updatePending?: boolean;
}

export const formFieldValueModuleReducers = handleActions<FormFieldValueModuleState>(
  {
    [FORM_FIELD_VALUE_UPDATE_PENDING]: () => ({
      updatePending: true,
    }),
    // @ts-expect-error -- TODO
    [combineActions(toSuccess(FORM_FIELD_VALUE_UPDATE), toFailure(FORM_FIELD_VALUE_UPDATE))]: () => ({
      updatePending: false,
    }),
  },
  {},
);
