import { match, P } from 'ts-pattern';
import { FieldType, FormFieldWidget, Widget } from '../widget-model';
import { NativeAutomation } from './native-automation';
import { Prompts } from './prompts';

export namespace ExecuteAiPrompt {
  export type PromptType =
    | 'Custom'
    | 'Blog'
    | 'Parse'
    | 'Translate'
    | 'SocialMedia'
    | 'Newsletter'
    | 'Summarize'
    | 'Sentiment'
    | 'Theme'
    // HACK while math is not chat gpt based
    | 'Math';

  export type BasicPromptType = Exclude<PromptType, 'Parse'>;

  export const PROMPT_CONFIGS = (
    [
      { promptType: 'Custom', promptLabel: 'Create from scratch', prompt: '', promptGroup: '' },
      { promptType: 'Blog', promptLabel: 'Blog post', promptGroup: 'Create content', ...Prompts.Blog },
      {
        promptType: 'SocialMedia',
        promptLabel: 'Social media update',
        promptGroup: 'Create content',
        ...Prompts.SocialMedia,
      },
      { promptType: 'Newsletter', promptGroup: 'Create content', ...Prompts.Newsletter },
      // HACK while math is not chat gpt based
      { promptType: 'Math', promptLabel: 'Math', promptGroup: 'Formulas', prompt: '' },
      { promptType: 'Translate', promptGroup: 'Transform', ...Prompts.Translate },
      {
        promptType: 'Parse',
        promptLabel: 'Parsing',
        promptGroup: 'Transform',
        ...Prompts.Parse,
        promptInputs: {
          source: '',
          variables: '',
        },
      },
      { promptType: 'Summarize', promptGroup: 'Analyze', ...Prompts.Summarize },
      { promptType: 'Sentiment', promptLabel: 'Sentiment analysis', promptGroup: 'Analyze', ...Prompts.Sentiment },
      { promptType: 'Theme', promptLabel: 'Thematic breakdown', promptGroup: 'Analyze', ...Prompts.Theme },
    ] satisfies Array<{
      promptType: PromptType;
      prompt?: string;
      promptInputs?: Record<string, string>;
      readonly systemPrompt?: string;
      readonly formatPrompt?: string;

      // UI Only
      promptGroup?: string;
      promptLabel?: string;
    }>
  ).map(config => ({
    ...config,
    ...('systemPrompt' in config ? { systemPrompt: config.systemPrompt.trim() } : {}),
    ...('formatPrompt' in config ? { formatPrompt: config.formatPrompt.trim() } : {}),
    ...(config.prompt ? { prompt: config.prompt.trim() } : {}),
  }));

  export const PROMPT_CONFIGS_BY_TYPE = PROMPT_CONFIGS.reduce((acc, config) => {
    acc[config.promptType] = config;
    return acc;
  }, {} as Record<PromptType, typeof PROMPT_CONFIGS[number]>);

  export type PromptLibraryConfig = typeof PROMPT_CONFIGS[number];
  export type GetPromptLibraryConfig<T extends PromptType> = Extract<PromptLibraryConfig, { promptType: T }>;

  // Some advanced typing here to preserve the union through the Pick filter.
  export type PromptConfig =
    | {
        [k in PromptType]: Omit<GetPromptLibraryConfig<k>, 'promptGroup' | 'promptLabel'>;
      }[PromptType]
    | Record<string, never>;
  export type GetPromptConfig<T extends PromptType> = Extract<PromptConfig, { promptType: T }>;

  export const isOutputWidget = (widget: Widget): widget is FormFieldWidget =>
    match(widget)
      .with(
        {
          fieldType: P.union(
            FieldType.Text,
            FieldType.Textarea,
            FieldType.Number,
            FieldType.Hidden,
            FieldType.Email,
            FieldType.SendRichEmail,
            FieldType.Url,
          ),
        },
        () => true,
      )
      .otherwise(() => false);

  export const INPUT_PREFIX = 'input.';
  export const sanitizePromptInputs = (promptInputs: Record<string, string>) =>
    Object.fromEntries(
      Object.entries(promptInputs).map(([key, value]) => [`${INPUT_PREFIX}${key}`, value.replace(/\{\{.*\}\}/, '')]),
    );

  export function isActionValid(action: NativeAutomation.GetActionByType<'ExecuteAiPrompt'>) {
    const { prompt, systemPrompt } = action.config;
    const isCombinedPromptEntered = Boolean(systemPrompt) || Boolean(prompt);
    return isCombinedPromptEntered;
  }
}
