import { OrganizationMembership, User } from '@process-street/subgrade/core';
import { SavedView, SavedViewAccessLevel } from 'components/dashboard/models/saved-views';
import { getEnv } from 'components/common/env';

const mrProcesspantsOrganizationId = getEnv().APP_PROCESSPANTS_ORG_ID;

const isOwner = (sv: SavedView, om: OrganizationMembership) => sv.audit.createdBy.id === om.user.id;
const isNotGuest = (om: OrganizationMembership) => !om.guest;
const isProcesspants = (om: OrganizationMembership) => om.organization.id === mrProcesspantsOrganizationId;
const isDeveloper = (om: OrganizationMembership) => (om.user as User).developer;
const canEditAsAdmin = (sv: SavedView, om: OrganizationMembership) =>
  om.admin && sv.accessLevel !== SavedViewAccessLevel.Private;

const canEditAsOwnerOrAdmin = (sv: SavedView, om: OrganizationMembership) => isOwner(sv, om) || canEditAsAdmin(sv, om);

const isOrganizationValid = (sv: SavedView, om: OrganizationMembership) => sv.organizationId === om.organization.id;

const canUserPublishView = (sv: SavedView, om: OrganizationMembership) =>
  isNotGuest(om) && isProcesspants(om) && canEditAsOwnerOrAdmin(sv, om) && isDeveloper(om);

const canUserDeleteSavedView = (sv: SavedView, om: OrganizationMembership): boolean =>
  isNotGuest(om) && isOrganizationValid(sv, om) && canEditAsOwnerOrAdmin(sv, om);

const canUserEditSavedView = (sv: SavedView, om: OrganizationMembership): boolean =>
  isNotGuest(om) && isOrganizationValid(sv, om) && canEditAsOwnerOrAdmin(sv, om);

export const SavedViewsPermissionService = {
  canEditAsAdmin,
  canEditAsOwnerOrAdmin,
  canUserDeleteSavedView,
  canUserEditSavedView,
  canUserPublishView,
  isOwner,
};
