import { DateFormFieldWidget, FieldType } from '@process-street/subgrade/process';
import * as yup from 'yup';
import { WidgetSettings } from '../common/settings/widget-settings';
import { FormFieldValueSchemaTests } from '../common/validation/form-field-value-schema-tests';

type ValidationSchemaProps = {
  required: boolean;
  constraints?: DateFormFieldWidget['constraints'];
};

export const makeDateValidationSchema = ({ required, constraints }: ValidationSchemaProps) => {
  return yup
    .number()
    .test(FormFieldValueSchemaTests.required(required))
    .test(FormFieldValueSchemaTests.beforeDate(constraints?.beforeDate))
    .test(FormFieldValueSchemaTests.afterDate(constraints?.afterDate));
};

export const dateSettingsSchema: yup.ObjectSchema<WidgetSettings<FieldType.Date>> = yup
  .object({
    config: yup.object({}),
    constraints: yup
      .object<DateFormFieldWidget['constraints']>({
        afterDate: yup.number().optional().min(0).max(Number.MAX_VALUE),
        beforeDate: yup.number().optional().min(0).max(Number.MAX_VALUE),
      })
      .required(),
  })
  .required();
