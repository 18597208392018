import angular from 'angular';
import { reactToAngular } from 'angulareact';
import { TaskTemplateContextMenu } from './task-template-context-menu';

export const TaskTemplateContextMenuModule = angular
  .module('psTaskTemplateContextMenuModule', [])
  .component(
    'psTaskTemplateContextMenu',
    reactToAngular(TaskTemplateContextMenu, [
      'hasConditionalLogic',
      'hasStop',
      'isActive',
      'isDuplicateButtonDisabled',
      'onAddStop',
      'onAutomate',
      'onConditionalLogic',
      'onDelete',
      'onDuplicate',
      'onMoveDown',
      'onMoveUp',
      'onRemoveStop',
      'shouldShowMenuItem',
    ]),
  ).name;
