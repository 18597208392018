import angular from 'angular';
import { uuid } from 'services/uuid';
import Muid from 'node-muid';
import { FieldType, WidgetType } from '@process-street/subgrade/process';
import { RuleConstants } from '@process-street/subgrade/conditional-logic/rule-constants';
import { trace } from 'components/trace';
import { ChecklistRuleDefinitionOperator } from '@process-street/subgrade/conditional-logic';

angular
  .module('frontStreetApp.services')
  .service(
    'RuleService',
    function (
      $q,
      $rootScope,
      FeatureFlagService,
      OrderTreeService,
      RuleDao,
      RuleEvent,
      SessionService,
      TaskTemplateService,
      util,
      WidgetService,
    ) {
      const self = this;

      const logger = trace({ name: 'RuleService' });

      const FieldTypeToOperatorListMap = {
        default: [
          ChecklistRuleDefinitionOperator.Is,
          ChecklistRuleDefinitionOperator.IsNot,
          ChecklistRuleDefinitionOperator.StartsWith,
          ChecklistRuleDefinitionOperator.EndsWith,
          ChecklistRuleDefinitionOperator.Contains,
          ChecklistRuleDefinitionOperator.DoesNotContain,
          ChecklistRuleDefinitionOperator.HasNoValue,
          ChecklistRuleDefinitionOperator.HasAnyValue,
        ],
      };
      FieldTypeToOperatorListMap[FieldType.Select] = [
        ChecklistRuleDefinitionOperator.Is,
        ChecklistRuleDefinitionOperator.IsNot,
        ChecklistRuleDefinitionOperator.HasNoValue,
        ChecklistRuleDefinitionOperator.HasAnyValue,
      ];
      FieldTypeToOperatorListMap[FieldType.MultiChoice] = [
        ChecklistRuleDefinitionOperator.Is,
        ChecklistRuleDefinitionOperator.Contains,
        ChecklistRuleDefinitionOperator.DoesNotContain,
        ChecklistRuleDefinitionOperator.HasNoValue,
        ChecklistRuleDefinitionOperator.HasAnyValue,
      ];
      FieldTypeToOperatorListMap[FieldType.Date] = [
        ChecklistRuleDefinitionOperator.Is,
        ChecklistRuleDefinitionOperator.IsNot,
        ChecklistRuleDefinitionOperator.HasNoValue,
        ChecklistRuleDefinitionOperator.HasAnyValue,
      ];
      FieldTypeToOperatorListMap[FieldType.Date + 'V2'] = [
        ChecklistRuleDefinitionOperator.IsGreaterThan,
        ChecklistRuleDefinitionOperator.IsLessThan,
        ChecklistRuleDefinitionOperator.Is,
        ChecklistRuleDefinitionOperator.IsNot,
        ChecklistRuleDefinitionOperator.HasNoValue,
        ChecklistRuleDefinitionOperator.HasAnyValue,
      ];
      FieldTypeToOperatorListMap[FieldType.Number] = [
        ChecklistRuleDefinitionOperator.Is,
        ChecklistRuleDefinitionOperator.IsNot,
        ChecklistRuleDefinitionOperator.HasNoValue,
        ChecklistRuleDefinitionOperator.HasAnyValue,
      ];
      FieldTypeToOperatorListMap[FieldType.Number + 'V2'] = [
        ChecklistRuleDefinitionOperator.IsGreaterThan,
        ChecklistRuleDefinitionOperator.IsLessThan,
        ChecklistRuleDefinitionOperator.Is,
        ChecklistRuleDefinitionOperator.IsNot,
        ChecklistRuleDefinitionOperator.HasNoValue,
        ChecklistRuleDefinitionOperator.HasAnyValue,
      ];
      FieldTypeToOperatorListMap[FieldType.SendRichEmail] = [
        ChecklistRuleDefinitionOperator.HasNoValue,
        ChecklistRuleDefinitionOperator.HasAnyValue,
      ];

      const FieldTypeToOperandFieldTypeMap = {
        default: RuleConstants.OperandFieldType.STRING,
      };
      FieldTypeToOperandFieldTypeMap[FieldType.Date] = RuleConstants.OperandFieldType.DATE;
      FieldTypeToOperandFieldTypeMap[FieldType.SendRichEmail] = RuleConstants.OperandFieldType.DATE;
      FieldTypeToOperandFieldTypeMap[FieldType.Select] = RuleConstants.OperandFieldType.SELECT;
      FieldTypeToOperandFieldTypeMap[FieldType.MultiChoice] = RuleConstants.OperandFieldType.MULTI_CHOICE;

      const FieldTypeToOperandTypeMap = {
        default: RuleConstants.OperandType.STRING,
      };
      FieldTypeToOperandTypeMap[FieldType.Date] = RuleConstants.OperandType.DATE_TIME;
      FieldTypeToOperandTypeMap[FieldType.SendRichEmail] = RuleConstants.OperandType.DATE_TIME;

      /**
       * Gets all rules by a given template revision id
       * @param templateRevisionId
       * @return {Promise}
       */
      self.getAllByTemplateRevisionId = function (templateRevisionId) {
        return RuleDao.getAllByTemplateRevisionId(templateRevisionId);
      };

      /**
       * Gets all rules by a given checklist revision id
       * @param checklistRevisionId
       * @return {Promise}
       */
      self.getAllByChecklistRevisionId = (checklistRevisionId, flushCache) =>
        RuleDao.getAllByChecklistRevisionId(checklistRevisionId, flushCache);

      self.deltaUpdate = (templateRevisionId, delta) => {
        return RuleDao.deltaUpdate(templateRevisionId, delta)
          .then(
            result => {
              $rootScope.$broadcast(
                RuleEvent.RULES_UPDATE_OK,
                templateRevisionId,
                result.definitions,
                result.updatedTaskTemplates,
              );

              // update task templates & widgets hiddenByDefault in redux as they're cached
              TaskTemplateService.updateAllHiddenByDefaultByTemplateRevisionId(
                templateRevisionId,
                delta.taskTemplatesHiddenByDefault,
              );
              WidgetService.updateAllHiddenByDefault(templateRevisionId, delta.widgetsHiddenByDefault);

              return result;
            },
            response => {
              $rootScope.$broadcast(RuleEvent.RULES_UPDATE_FAILED, templateRevisionId, response);

              return $q.reject(response);
            },
          )
          .finally(() => {
            $rootScope.$broadcast(RuleEvent.RULES_UPDATE_FINISHED, templateRevisionId);
          });
      };

      self.createOrUpdateByTemplateRevisionId = (
        templateRevisionId,
        rules,
        tasksHiddenByDefault,
        widgetsHiddenByDefault,
      ) => {
        $rootScope.$broadcast(RuleEvent.RULES_UPDATE_STARTED, templateRevisionId, rules, tasksHiddenByDefault);

        return RuleDao.createOrUpdateByTemplateRevisionId(
          templateRevisionId,
          rules,
          tasksHiddenByDefault,
          widgetsHiddenByDefault,
        )
          .then(
            result => {
              $rootScope.$broadcast(
                RuleEvent.RULES_UPDATE_OK,
                templateRevisionId,
                result.definitions,
                result.updatedTaskTemplates,
              );

              // update task templates & widgets hiddenByDefault in redux as they're cached
              TaskTemplateService.updateAllHiddenByDefaultByTemplateRevisionId(
                templateRevisionId,
                tasksHiddenByDefault,
              );
              WidgetService.updateAllHiddenByDefault(templateRevisionId, widgetsHiddenByDefault);

              return result;
            },
            response => {
              $rootScope.$broadcast(RuleEvent.RULES_UPDATE_FAILED, templateRevisionId, response);

              return $q.reject(response);
            },
          )
          .finally(() => {
            $rootScope.$broadcast(RuleEvent.RULES_UPDATE_FINISHED, templateRevisionId);
          });
      };

      self.getAvailableOperatorListByWidget = function (widget) {
        if (widget.header.type !== WidgetType.FormField) {
          logger.error('widget type %s is not supported', widget.header.type || 'none');

          return [];
        }

        let mapKey = 'default';
        if (FieldTypeToOperatorListMap[widget.fieldType]) {
          mapKey = widget.fieldType;
        }

        const fieldTypeSupportsV2 = [FieldType.Number, FieldType.Date].includes(mapKey);
        if (fieldTypeSupportsV2) {
          mapKey += 'V2';
        }

        return FieldTypeToOperatorListMap[mapKey];
      };

      self.resolveOperatorName = function (operator, widget) {
        if (widget.header.type !== WidgetType.FormField) {
          logger.error('widget type %s is not supported', widget.header.type || 'none');

          return [];
        }

        let mapKey = 'default';
        if (RuleConstants.OperatorNameMap[widget.fieldType]) {
          mapKey = widget.fieldType;
        }

        return RuleConstants.OperatorNameMap[mapKey][operator];
      };

      self.resolveOperandFieldTypeByWidget = function (widget) {
        if (widget.header.type !== WidgetType.FormField) {
          logger.error('widget type %s is not supported', widget.header.type || 'none');

          return null;
        }

        let mapKey = 'default';
        if (FieldTypeToOperandFieldTypeMap[widget.fieldType]) {
          mapKey = widget.fieldType;
        }

        return FieldTypeToOperandFieldTypeMap[mapKey];
      };

      self.resolveOperandTypeByWidget = function (widget) {
        if (widget.header.type !== WidgetType.FormField) {
          logger.error('widget type %s is not supported', widget.header.type || 'none');

          return null;
        }

        let mapKey = 'default';
        if (FieldTypeToOperandTypeMap[widget.fieldType]) {
          mapKey = widget.fieldType;
        }

        return FieldTypeToOperandTypeMap[mapKey];
      };

      self.generateNewRule = function (atIndex, rules) {
        if (atIndex > rules.length) {
          throw new RangeError(`atIndex (${atIndex}) is out of bound (0 - ${rules.length})`);
        }

        const referenceTree = rules[atIndex - 1] ? rules[atIndex - 1].orderTree : null;
        const selectedOrganizationId = SessionService.getSelectedOrganizationId();
        return {
          id: Muid.fromUuid(uuid()),
          orderTree: OrderTreeService.after(util.toOrderTrees(rules), referenceTree)[0],
          organizationId: selectedOrganizationId,
          formFieldWidgetGroupId: undefined,
          operator: ChecklistRuleDefinitionOperator.Is,
          operand: {
            operandType: RuleConstants.OperandType.STRING,
            value: '',
          },
          taskTemplateGroupIds: [],
          hidden: undefined,
        };
      };

      self.isRuleValid = function (rule) {
        let valid = true;

        // the basics
        if (
          !rule.id ||
          !rule.orderTree ||
          !rule.formFieldWidgetGroupId ||
          !rule.operator ||
          !rule.operand ||
          !rule.operand.operandType ||
          !angular.isArray(rule.taskTemplateGroupIds) ||
          (rule.taskTemplateGroupIds.length === 0 && rule.widgetGroupIds.length === 0) ||
          !self.isBoolean(rule.hidden)
        ) {
          valid = false;
        } else if (self.isOperandValueRequired(rule) && !rule.operand.value) {
          valid = false;
        }

        return valid;
      };

      const RequiredOperandValueMap = {};
      RequiredOperandValueMap[ChecklistRuleDefinitionOperator.IsGreaterThan] = true;
      RequiredOperandValueMap[ChecklistRuleDefinitionOperator.IsLessThan] = true;
      RequiredOperandValueMap[ChecklistRuleDefinitionOperator.Is] = true;
      RequiredOperandValueMap[ChecklistRuleDefinitionOperator.IsNot] = true;
      RequiredOperandValueMap[ChecklistRuleDefinitionOperator.StartsWith] = true;
      RequiredOperandValueMap[ChecklistRuleDefinitionOperator.EndsWith] = true;
      RequiredOperandValueMap[ChecklistRuleDefinitionOperator.Contains] = true;
      RequiredOperandValueMap[ChecklistRuleDefinitionOperator.DoesNotContain] = true;
      RequiredOperandValueMap[ChecklistRuleDefinitionOperator.HasNoValue] = false;
      RequiredOperandValueMap[ChecklistRuleDefinitionOperator.HasAnyValue] = false;

      self.isOperandValueRequired = function (rule) {
        return rule.operator && !!RequiredOperandValueMap[rule.operator];
      };

      self.isBoolean = function (value) {
        return value === true || value === false;
      };
    },
  );
