import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UploadOrganizationLogoFileParams = {
  organizationId: Muid;
  industry?: string;
  file: File;
};

export const UploadOrganizationLogoFileMutation = {
  key: ['organization', 'logo-file'],
  mutationFn: async (params: UploadOrganizationLogoFileParams) => {
    const body = new FormData();

    body.append('name', params.file.name);
    if (params.industry) body.append('industry', params.industry);
    body.append('file', params.file);

    return axiosService
      .getAxios()
      .post(`/1/organizations/${params.organizationId}/logo-file`, body, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(r => r.data);
  },
};

export const useUploadOrganizationLogoFileMutation = (
  options?: UseMutationOptions<unknown, AxiosError, UploadOrganizationLogoFileParams>,
) => {
  return useMutation(UploadOrganizationLogoFileMutation.key, UploadOrganizationLogoFileMutation.mutationFn, options);
};
