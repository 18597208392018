import { INSERT_WIDGET_DRAWER_MAX_W_PX } from 'app/pages/workflows/_id/edit-v2/components/insert-widgets-section/insert-widgets-section';
import { Box, Image, Text, VStack } from 'components/design/next';
import * as React from 'react';
import { useDrop } from 'react-dnd';
import { useUIActorRef } from '../../../shared';
import { useFormEditorPageActorRef } from '../../form-editor-page-machine';
import { DraggableType, NewWidgetItem } from '../../types';
import { useSelector } from '@xstate/react';

export type PageEmptyStateProps = {
  isReadOnly?: boolean;
};

export const PageEmptyState = ({ isReadOnly }: PageEmptyStateProps) => {
  const actor = useFormEditorPageActorRef();
  const { uiActorRef } = useUIActorRef();
  const isMobile = useSelector(uiActorRef, state => state.context.isMobile);
  const ref = React.useRef<HTMLDivElement>(null);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [DraggableType.FormField, DraggableType.Content],
    drop: (item: NewWidgetItem) => {
      actor.send({ type: 'CREATE_WIDGET', payload: item });
    },
    collect: monitor => ({ isOver: monitor.isOver() }),
  }));

  React.useEffect(() => {
    drop(ref);
  }, [drop]);

  if (isReadOnly) {
    return (
      <VStack py="8" spacing="6" ref={ref}>
        <Image maxW="10" src={require('app/images/templates/template-editor-empty-state-illustration.svg')} />

        <Text color="gray.400">Nothing here</Text>
      </VStack>
    );
  }

  return (
    <VStack py="8" spacing="6" ref={ref}>
      {isOver ? <Box h="2" w="full" bg="brand.200" transform="translateY(-50%)" /> : null}

      <Text variant="1" color="gray.400" pl={isMobile ? '0' : INSERT_WIDGET_DRAWER_MAX_W_PX}>
        Drop content here
      </Text>
    </VStack>
  );
};
