import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { useQueryClient } from 'react-query';
import {
  GetSavedViewPermitsQuery,
  GetSavedViewPermitsResponse,
  useGetSavedViewPermitsQuery,
  useUpsertSavedViewPermitMutation,
} from 'pages/reports/data-sets/query-builder';
import { useDeleteSavedViewPermitMutation } from 'pages/reports/data-sets/query-builder/delete-saved-view-permit-mutation';
import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { SavedViewPermitWithOm } from '@process-street/subgrade/process';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { isStandardUserOrStandardGroupOm } from '@process-street/subgrade/util/membership-utils';

interface UseSavedViewPermitsParams {
  dataSetId: string;
  savedViewId: string;
}

export function useSavedViewPermits({ dataSetId, savedViewId }: UseSavedViewPermitsParams) {
  const organizationId = useSelectedOrganization()?.id;

  const savedViewPermitsQuery = useGetSavedViewPermitsQuery({ savedViewId, dataSetId });

  const organizationMembershipsByIdQuery = useGetAllOrganizationMembershipsQuery(
    { organizationId },
    {
      enabled: Boolean(organizationId),
      select: oms => {
        return Object.fromEntries(oms.filter(isStandardUserOrStandardGroupOm).map(om => [om.id, om]));
      },
    },
  );

  const savedViewPermitsWithOms: SavedViewPermitWithOm[] =
    savedViewPermitsQuery.data
      ?.flatMap(savedViewPermit => {
        const om = organizationMembershipsByIdQuery.data?.[savedViewPermit.organizationMembershipId];

        if (om) {
          return [
            {
              ...savedViewPermit,
              organizationMembership: om,
            },
          ];
        } else {
          return [];
        }
      })
      .filter(permitWithOm => isStandardUserOrStandardGroupOm(permitWithOm.organizationMembership)) ?? [];

  const queryClient = useQueryClient();
  const upsertSavedViewPermitMutation = useUpsertSavedViewPermitMutation({});
  const deleteSavedViewPermitMutation = useDeleteSavedViewPermitMutation({});

  const handleAddSavedViewPermit = (user: OrganizationMembershipWithUser) =>
    upsertSavedViewPermitMutation
      .mutateAsync({
        organizationMembershipId: user.id,
        dataSetId,
        savedViewId,
        savedViewRead: true,
        savedViewUpdate: false,
      })
      .then(response => {
        queryClient.setQueryData<GetSavedViewPermitsResponse | undefined>(
          GetSavedViewPermitsQuery.getKey({ dataSetId, savedViewId }),
          current => {
            if (!current) return current;
            const index = current.findIndex(permit => permit.id === response.id);
            if (index !== -1) {
              const newItems = current.slice();
              newItems.splice(index, 1, response);
              return newItems;
            }
            return [...current, response];
          },
        );
      });

  const handleRemoveSavedViewPermit = (permit: SavedViewPermitWithOm) =>
    deleteSavedViewPermitMutation
      .mutateAsync({
        dataSetId,
        savedViewId,
        permitId: permit.id,
      })
      .then(_ => {
        queryClient.setQueryData<GetSavedViewPermitsResponse | undefined>(
          GetSavedViewPermitsQuery.getKey({ dataSetId, savedViewId }),
          savedViewPermits => {
            if (!savedViewPermits) return savedViewPermits;
            return savedViewPermits.filter(p => p.id !== permit.id);
          },
        );
      });

  return {
    handleAddSavedViewPermit,
    handleRemoveSavedViewPermit,
    savedViewPermitsWithOms,
  };
}
