import * as React from 'react';
import { ablyService } from 'app/pusher/ably.service';
import { AblyEvent } from 'app/pusher/ably-event';
import { toResult } from '@process-street/subgrade/util';
import { NativeAutomation } from '@process-street/subgrade/process';
import { useStateParam } from 'hooks/use-state-param';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { match } from 'ts-pattern';
import { GetAllNativeAutomationLogsByChecklistIdQuery } from 'features/native-automations/query-builder/get-all-native-automation-logs-by-checklist-id-query';
import { useInboxItemsSelectedRow } from 'app/pages/tasks/use-inbox-items-grid-context-store';

export const useChecklistRevisionNativeAutomationStatus = ({
  nativeAutomation,
}: {
  nativeAutomation?: NativeAutomation;
}) => {
  const nativeAutomationId = nativeAutomation?.id;
  const nativeAutomationStableId = nativeAutomation?.stableId;
  const selectedRow = useInboxItemsSelectedRow();
  const checklistId =
    // Get the checklist id when the user is in the WF run page
    useStateParam({ key: 'id' }) ??
    // When not in the WF run page, we use the My work's page currently selected row
    selectedRow?.checklist.id;
  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({ checklistId });
  const checklistRevisionId = checklistRevisionQuery.data?.id;

  const logsQuery = GetAllNativeAutomationLogsByChecklistIdQuery.useQuery(
    { checklistId },
    { enabled: Boolean(nativeAutomationStableId), select: logs => logs[nativeAutomationStableId!] ?? [] },
  );

  const [status, setStatus] = React.useState<NativeAutomation.EventStatus | undefined>(undefined);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

  const latestLogStatus = logsQuery.data?.[0]?.automationStatus;
  const latestErrorMessage = logsQuery.data?.find(log => log.errorMessage)?.errorMessage;
  const latestExecutionId = logsQuery.data?.[0]?.executionId;

  React.useEffect(() => {
    if (latestLogStatus) {
      const mappedStatus = match<typeof latestLogStatus, NativeAutomation.EventStatus>(latestLogStatus)
        .with('Completed', () => 'AutomationComplete')
        .with('Failed', () => 'AutomationFailed')
        .with('Running', () => 'AutomationRunning')
        .exhaustive();

      setErrorMessage(latestErrorMessage);
      setStatus(mappedStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- react to log status
  }, [latestLogStatus]);

  React.useEffect(() => {
    if (!nativeAutomationId || !checklistRevisionId) return;
    const channelName = ablyService.getChannelNameForNativeAutomation(nativeAutomationId, checklistRevisionId);
    const channel = ablyService.getChannel(channelName);

    const listener: NonNullable<Parameters<typeof channel.subscribe>[1]> = message => {
      if (message.name !== AblyEvent.EventType.ChecklistRevisionNativeAutomationEvent) return;

      toResult<NativeAutomation.Event>(() => JSON.parse(message.data))
        .toOption()
        .forEach(event => {
          if (event.nativeAutomationId === nativeAutomationId && event.checklistRevisionId === checklistRevisionId) {
            setStatus(event.status);
            setErrorMessage(event.errorMessage);
          }
        });
    };

    channel.subscribe(AblyEvent.EventType.ChecklistRevisionNativeAutomationEvent, listener);

    return () => {
      channel.unsubscribe(AblyEvent.EventType.ChecklistRevisionNativeAutomationEvent, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore nativeAutomationId
  }, [nativeAutomationStableId, checklistRevisionId]);

  return { latestExecutionId, status, errorMessage };
};
