import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import newDashboardTemplate from 'components/dashboard/angular/new-dashboard.component.html';
import auth0CallbackTemplate from './features/auth0/callback.component.html';
import addMultipleChecklistTemplate from './features/checklist/add-multiple.component.html';
import checklistTemplate from './features/checklist/checklist.component.html';
import checklistManageTemplate from './features/checklist/manage/manage.component.html';
import runChecklistTemplate from './features/checklist/run.component.html';
import libraryTemplate from './pages/library/library.component.html';
import developerConsoleTemplate from './features/developer/console.component.html';
import folderManageTemplate from './features/folder/manage/manage.component.html';
import addFutureChecklistTemplate from './features/future-checklist/add.component.html';
import futureChecklistTemplate from './features/future-checklist/future-checklist.component.html';
import groupManageTemplate from './features/group/manage/manage.component.html';
import loaderTemplate from './features/loader/loader.component.html';
import orgManageTemplate from './features/organization/manage/manage.component.html';
import printChecklistTemplate from './features/print/print-checklist.component.html';
import printChecklistTemplateNew from './features/print/print-checklist-new.component.html';
import printTemplate from './features/print/print-template.component.html';
import redeemTemplate from './features/redeem/redeem.component.html';
import sharedRedirectTemplate from './features/shared/redirect.component.html';
import unsubscribeTemplate from './features/subscription/unsubscribe.component.html';
import userUnsubscribeTemplate from './features/subscription/user/unsubscribe.component.html';
import tagManageTemplate from './features/tag/manage/manage.component.html';
import taskTemplate from './features/task/task.component.html';
import addTemplate from './features/template/add-workflow/add.component.html';
import addPage from './features/template/add-page/add.component.html';
import editTemplate from './features/template/edit.component.html';
import editTemplateV2 from './pages/workflows/_id/edit-v2/editor.component.html';
import templateManageTemplate from './features/template/manage/manage.component.html';
import templateViewTemplate from './features/template/view.component.html';
import templateViewV2 from './pages/workflows/_id/view-v2/view.component.html';
import pageViewTemplate from './features/template/page-view.component.html';
import pageViewV2 from './pages/pages/_id/view/components/view-page/view-page.component.html';
import appTemplate from './features/app/app.component.html';
import appLightTemplate from './features/app/app-light.component.html';
import unavailableTemplate from './features/unavailable/unavailable.component.html';
import emailVerificationTemplate from './features/user/email-verification.component.html';
import loginSSOTemplate from './features/user/login/login-sso.component.html';
import loginTemplate from './features/user/login/login.component.html';
import logoutTemplate from './features/user/logout.component.html';
import userManageTemplate from './features/user/manage/manage.component.html';
import requestPasswordResetTemplate from './features/user/request-password-reset.component.html';
import resetPasswordTemplate from './features/user/reset-password.component.html';
import signUpCompleteTemplate from './features/user/sign-up/complete.component.html';
import signUpInviteMembersTemplate from './features/user/sign-up/invite-members.component.html';
import signUpTemplate from './features/user/sign-up/sign-up.component.html';
import editPageTemplate from 'pages/pages/_id/edit/index.component.html';
import editPageTemplateV2 from 'pages/pages/_id/edit-v2/editor.component.html';
import editFormTemplate from 'pages/forms/_id/edit/index.component.html';
import formSettingsPageTemplate from 'pages/forms/_id/settings/index.component.html';
import formResponsesPageTemplate from 'pages/forms/_id/responses/form-responses-page.component.html';
import addFormResponseTemplate from 'pages/forms/_id/respond/index.component.html';
import formLoginTemplate from 'pages/forms/_id/login/index.component.html';
import formResponseTemplate from 'pages/responses/_id/form-response-page.component.html';
import formResponseFinishTemplate from 'pages/responses/_id/finish/index.component.html';
import viewOneOffTaskTemplate from './features/one-off-tasks/components/shared/view-one-off-task/index.component.html';
import microsoftTeamsAppTemplate from 'features/microsoft-teams/components/app.component.html';
import signUpEmailTemplate from 'pages/sign-up/create-account/index.component.html';
import signUpOrganizationDetailsTemplate from 'pages/sign-up/organization-details/index.component.html';
import signUpReferralTemplate from 'pages/sign-up/referral/index.component.html';
import signUpConsultationAppointmentTemplate from 'pages/sign-up/consultation-appointment/index.component.html';
import signUpConsultationPromptTemplate from 'pages/sign-up/consultation-prompt/index.component.html';
import dataSetPageTemplate from 'pages/reports/data-sets/components/data-set-page/data-set-page.template.html';
import aiGenerateTemplate from 'pages/templates/ai-generate/index.component.html';
import { ReportsTab } from 'utils/routing/reports-tab';
import { AppModalQueryParam } from './app.constants';
import { AppRedirectHandlers } from './app-redirect-handlers';
import workflowDashboardTemplate from 'pages/templates/_id/dashboard/index.component.html';
import printRulesTemplate from 'pages/templates/_id/print-rules/components/print-template-rules/print-rules.component.html';
import tasksTemplate from './pages/tasks/tasks.component.html';
import commentsTemplate from './pages/comments/comments-page.component.html';
import checklistV2Template from './pages/checklists/_id/checklist.component.html';
import requestAccessTemplate from './pages/request-access/components/request-access/request-access.component.html';
import inboxTemplate from './features/inbox/inbox.component.html';
import { InboxConstants } from '@process-street/subgrade/inbox';

angular.module('frontStreetApp.states', [uiRouter]).config(($stateProvider, $urlRouterProvider) => {
  // Abstract root state updates token before user can access any route
  $stateProvider
    .state('app', {
      abstract: true,
      reloadOnSearch: false,
      url: `?${Object.values(AppModalQueryParam).join('&')}`,
      templateUrl: appTemplate,
    })
    .state('appLight', {
      abstract: true,
      templateUrl: appLightTemplate,
    })
    .state('inbox', {
      parent: 'app',
      url: '/inbox?tab&searchTerm',
      templateUrl: inboxTemplate,
      controller: 'InboxCtrl',
      data: {
        skipLoader: true,
        internal: true,
        pageTitle: 'Inbox | Process Street',
      },
      params: {
        tab: {
          value: InboxConstants.Filter.INBOX,
          dynamic: true,
        },
      },
    })
    .state('myWork', {
      parent: 'app',
      url: '/work?userIds&search&itemsType&templateIds&groupBy&checklistId',
      templateUrl: tasksTemplate,
      reloadOnSearch: false,
      params: {
        taskModal: {
          dynamic: true,
        },
        modalTaskId: {
          dynamic: true,
        },
        templateId: {
          dynamic: true,
        },
      },
      data: {
        skipLoader: true,
        internal: true,
        pageTitle: 'My Work | Process Street',
      },
    })
    .state('comments', {
      parent: 'app',
      url: '/comments',
      templateUrl: commentsTemplate,
      data: {
        skipLoader: true,
        internal: true,
        pageTitle: 'Comments | Process Street',
      },
    })
    // Organization
    .state('organizationManage', {
      parent: 'app',
      url: '/organizations/manage',
      templateUrl: orgManageTemplate,
      controller: 'OrganizationManageCtrl',
      data: {
        skipLoader: true,
        allowCanceled: true,
        internal: true,
      },
    })
    .state('organizationManage.tab', {
      url: '/:tab?trialExpired?showTrialExpiredPaywall',
      data: {
        internal: true,
        pageTitle: 'Manage Organization | Process Street',
      },
    })
    // Folder
    .state('groupManage', {
      parent: 'app',
      url: '/groups/{id:[\\w-]{22}}/manage',
      templateUrl: groupManageTemplate,
      controller: 'GroupManageCtrl',
      data: {
        skipLoader: true,
        internal: true,
      },
    })
    .state('groupManage.tab', {
      url: '/:tab',
      data: {
        internal: true,
        pageTitle: 'Manage Group | Process Street',
      },
    })
    // Developer
    .state('developer', {
      parent: 'app',
      url: '/developer',
      templateUrl: developerConsoleTemplate,
      controller: 'DeveloperConsoleCtrl',
      data: {
        skipLoader: true,
        allowCanceled: true,
        internal: true,
        pageTitle: 'Developer Console | Process Street',
      },
    })
    .state('developer.tab', {
      url: '/:tab',
      data: {
        skipLoader: true,
        internal: true,
      },
    })
    // User
    .state('auth0Callback', {
      url: '/auth0-callback?url&signedUp',
      controller: 'Auth0CallbackCtrl',
      templateUrl: auth0CallbackTemplate,
      data: {
        external: true,
      },
    })
    .state('login', {
      url: '/login?email&url&source&templateId',
      templateUrl: loginTemplate,
      controller: 'LoginCtrl',
      data: {
        external: true,
      },
    })
    .state('loginSSO', {
      url: '/login/sso?email',
      templateUrl: loginSSOTemplate,
      controller: 'LoginSSOCtrl',
      data: {
        external: true,
      },
    })
    .state('signUp', {
      url: '/sign-up?email&planId&coupon&source&templateId&pageId',
      templateUrl: signUpTemplate,
      controller: 'SignUpCtrl',
      data: {
        internal: true,
        external: true,
      },
      redirectTo(trans) {
        const { templateId, pageId } = trans.params();
        const auth = trans.injector().get('auth');
        const loggedIn = auth.isLoggedIn();

        if (loggedIn) {
          if (templateId) {
            return { state: 'templateAdd', params: { templateId } };
          } else if (pageId) {
            return { state: 'pageAdd', params: { pageId } };
          } else {
            return { state: 'reports' };
          }
        } else {
          return undefined;
        }
      },
    })
    .state('completeSignUp', {
      parent: 'appLight',
      abstract: true,
      url: '/sign-up/complete',
      templateUrl: signUpCompleteTemplate,
      controller: 'CompleteCtrl',
      data: {
        external: true,
      },
    })
    .state('completeSignUp.user', {
      url: '/user/:email?planId&coupon&codeId',
      data: {
        internal: true,
        external: true,
      },
    })
    .state('completeSignUp.invitation', {
      url: '/invitation/:id?codeId&url',
      data: {
        external: true,
      },
    })
    .state('newCompleteSignUp', {
      abstract: true,
      url: '/new/sign-up/complete',
      data: {
        external: true,
      },
    })
    .state('newCompleteSignUp.user', {
      url: '/user/:email?planId&coupon&codeId',
      templateUrl: signUpEmailTemplate,
      data: {
        internal: true,
        external: true,
      },
    })
    .state('newCompleteSignUp.organization', {
      url: '/organization/:organizationId?organizationName',
      templateUrl: signUpOrganizationDetailsTemplate,
      data: {
        internal: true,
        external: true,
      },
    })
    .state('newCompleteSignUp.referral', {
      url: '/referral',
      templateUrl: signUpReferralTemplate,
      data: {
        internal: true,
        external: true,
      },
    })
    .state('newCompleteSignUp.consultationPrompt', {
      url: '/consultation-prompt',
      templateUrl: signUpConsultationPromptTemplate,
      data: {
        internal: true,
        external: true,
      },
    })
    .state('newCompleteSignUp.consultation', {
      url: '/consultation',
      templateUrl: signUpConsultationAppointmentTemplate,
      data: {
        internal: true,
        external: true,
      },
    })
    .state('inviteMembers', {
      parent: 'appLight',
      url: '/sign-up/invite-members',
      templateUrl: signUpInviteMembersTemplate,
      controller: 'InviteMembersCtrl',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('logout', {
      parent: 'appLight',
      url: '/logout',
      templateUrl: logoutTemplate,
      controller: 'LogoutCtrl',
      data: {
        internal: true,
        external: true,
      },
    })
    .state('requestPasswordReset', {
      url: '/request-password-reset?email',
      templateUrl: requestPasswordResetTemplate,
      controller: 'RequestPasswordResetCtrl',
      data: {
        internal: true,
        external: true,
      },
    })
    .state('resetPassword', {
      parent: 'appLight',
      url: '/reset-password/:id',
      templateUrl: resetPasswordTemplate,
      controller: 'ResetPasswordCtrl',
      data: {
        internal: true,
        external: true,
      },
    })
    .state('verifyEmail', {
      parent: 'appLight',
      url: '/email-verification/:codeId',
      templateUrl: emailVerificationTemplate,
      controller: 'EmailVerificationCtrl',
      data: {
        internal: true,
        external: true,
      },
    })
    .state('userManage', {
      parent: 'app',
      url: '/users/:id/manage',
      templateUrl: userManageTemplate,
      controller: 'UserManageCtrl',
      data: {
        allowCanceled: true,
        internal: true,
        pageTitle: 'My Profile & Settings | Process Street',
        skipLoader: true,
      },
    })
    .state('userManage.tab', {
      url: '/:tab',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    // Loader
    .state('loader', {
      parent: 'appLight',
      url: '/loader?url',
      templateUrl: loaderTemplate,
      controller: 'LoaderCtrl',
      data: {
        internal: true,
      },
    })
    // S3 file redirect
    .state('file-redirect', {
      parent: 'appLight',
      url: '/file-redirect?url',
      templateUrl: loaderTemplate,
      controller: 'FileRedirectCtrl',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    // Unavailable
    .state('unavailable', {
      parent: 'appLight',
      url: '/unavailable?url',
      templateUrl: unavailableTemplate,
      controller: 'UnavailableCtrl',
      data: {
        internal: true,
        external: true,
      },
    })
    // Redeem
    .state('redeem', {
      parent: 'app',
      url: '/redeem?coupon',
      templateUrl: redeemTemplate,
      controller: 'RedeemCtrl',
      data: {
        internal: true,
      },
    })
    // Dashboard
    .state('dashboard', {
      parent: 'app',
      url: '/library',
      templateUrl: libraryTemplate,
      data: {
        skipLoader: true,
        internal: true,
      },
    })
    .state('dashboard.type', {
      url: '/{type}s/{path:.*}',
      params: {
        path: '',
      },
      data: {
        internal: true,
        pageTitle: 'Library | Process Street',
      },
    })
    // Folder
    .state('folderManage', {
      parent: 'app',
      url: '/folders/{id:[\\w-]{22}}/manage',
      templateUrl: folderManageTemplate,
      controller: 'FolderManageCtrl',
      data: {
        internal: true,
        pageTitle: 'Manage Folder | Process Street',
      },
    })
    .state('folderManage.tab', {
      url: '/:tab',
      data: {
        internal: true,
      },
    })
    // Tag
    .state('tagManage', {
      parent: 'app',
      url: '/tags/{id:[\\w-]{22}}/manage',
      templateUrl: tagManageTemplate,
      controller: 'TagManageCtrl',
      data: {
        internal: true,
        pageTitle: 'Manage Tag | Process Street',
      },
    })
    .state('tagManage.tab', {
      url: '/:tab',
      data: {
        internal: true,
      },
    })
    .state('pageView', {
      parent: 'app',
      url: '/pages/:title{id:[\\w-]{22}}/view',
      templateUrl: pageViewTemplate,
      controller: 'PageViewCtrl',
      params: {
        title: {
          dynamic: true,
          value: '',
        },
      },
      data: {
        internal: true,
        skipLoginCheck: true,
        skipLoader: true,
      },
    })
    .state('pageViewV2', {
      parent: 'app',
      url: '/pages/v2/:title{id:[\\w-]{22}}/view',
      templateUrl: pageViewV2,
      params: {
        title: {
          dynamic: true,
          value: '',
        },
      },
      data: {
        internal: true,
        skipLoginCheck: true,
        skipLoader: true,
      },
    })
    .state('templateAiGenerate', {
      parent: 'app',
      url: '/workflows/ai-generate?name&description&retryCount',
      templateUrl: aiGenerateTemplate,
      data: {
        internal: true,
        skipLoader: true,
      },
      params: {
        name: null,
        description: null,
        retryCount: null,
      },
    })
    .state('templateView', {
      parent: 'app',
      url: '/workflows/:title{id:[\\w-]{22}}/view',
      templateUrl: templateViewTemplate,
      controller: 'TemplateViewCtrl',
      params: {
        title: {
          dynamic: true,
          value: '',
        },
        redirectedFromDashboard: false,
      },
      data: {
        internal: true,
        skipLoginCheck: true,
        skipLoader: true,
      },
    })
    .state('templateViewV2', {
      parent: 'app',
      url: '/workflows/v2/:title{id:[\\w-]{22}}/view',
      templateUrl: templateViewV2,
      params: {
        title: {
          dynamic: true,
          value: '',
        },
        redirectedFromDashboard: false,
      },
      data: {
        internal: true,
        skipLoginCheck: true,
        skipLoader: true,
      },
    })
    .state('templateDashboard', {
      parent: 'app',
      url: '/workflows/:title{id:[\\w-]{22}}/dashboard',
      templateUrl: workflowDashboardTemplate,
      params: {
        title: {
          dynamic: true,
          value: '',
        },
      },
      data: {
        internal: true,
        skipLoader: true,
        hideTopBar: false,
      },
    })
    .state('templateView.task', {
      url: '/tasks/{groupId:[\\w-]{22}}',
      data: {
        internal: true,
        skipLoader: true,
        hideTopBar: false,
      },
    })
    .state('templateViewV2.task', {
      url: '/tasks/{groupId:[\\w-]{22}}',
      data: {
        internal: true,
        skipLoader: true,
        hideTopBar: false,
      },
    })
    .state('templatePrint', {
      url: '/workflows/{id:[\\w-]{22}}/print',
      templateUrl: printTemplate,
      controller: 'TemplatePrintCtrl',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('templatePrintRules', {
      url: '/workflows/{id:[\\w-]{22}}/print-rules',
      templateUrl: printRulesTemplate,
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('template', {
      parent: 'app',
      url: '/workflows/{id:[\\w-]{22}}/edit',
      templateUrl: editTemplate,
      controller: 'TemplateEditCtrl',
      data: {
        internal: true,
        skipLoader: true,
        hideTopBar: false,
      },
    })
    .state('template.task', {
      url: '/tasks/{groupId:[\\w-]{22}}',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('templateV2', {
      parent: 'app',
      url: '/workflows/v2/{id:[\\w-]{22}}/edit',
      templateUrl: editTemplateV2,
      data: {
        internal: true,
        skipLoader: true,
        hideTopBar: false,
      },
    })
    .state('templateV2.task', {
      url: '/tasks/{groupId:[\\w-]{22}}',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('templateAdd', {
      parent: 'app',
      url: '/workflows/new?templateId',
      templateUrl: addTemplate,
      controller: 'TemplateAddCtrl',
      onEnter: [
        '$q',
        '$state',
        '$stateParams',
        ($q, $state, $stateParams) => {
          if (!$stateParams.templateId) {
            $state.go('otherwise');
            return $q.reject('Cannot create workflow, template id was not defined');
          }
        },
      ],
      data: {
        internal: true,
      },
    })
    .state('pageAdd', {
      parent: 'app',
      url: '/pages/new?pageId',
      templateUrl: addPage,
      controller: 'PageAddCtrl',
      onEnter: [
        '$q',
        '$state',
        '$stateParams',
        ($q, $state, $stateParams) => {
          if (!$stateParams.pageId) {
            $state.go('otherwise');
            return $q.reject('Cannot create page, page id was not defined');
          }
        },
      ],
      data: {
        internal: true,
      },
    })
    .state('workflowManage', {
      parent: 'app',
      url: '/workflows/{id:[\\w-]{22}}/manage',
      templateUrl: templateManageTemplate,
      controller: 'TemplateManageCtrl',
      data: {
        internal: true,
        pageTitle: 'Manage Workflow | Process Street',
      },
    })
    .state('workflowManage.tab', {
      url: '/:tab',
      data: {
        internal: true,
      },
    })
    .state('pageManage', {
      parent: 'app',
      url: '/pages/{id:[\\w-]{22}}/manage',
      templateUrl: templateManageTemplate,
      controller: 'TemplateManageCtrl',
      data: {
        internal: true,
        pageTitle: 'Manage Page | Process Street',
      },
    })
    .state('pageManage.tab', {
      url: '/:tab',
      data: {
        internal: true,
      },
    })
    .state('formManage', {
      parent: 'app',
      url: '/forms/{id:[\\w-]{22}}/manage',
      templateUrl: templateManageTemplate,
      controller: 'TemplateManageCtrl',
      data: {
        internal: true,
        pageTitle: 'Manage Form | Process Street',
      },
    })
    .state('formManage.tab', {
      url: '/:tab',
      data: {
        internal: true,
      },
    })
    .state('checklistRun', {
      parent: 'app',
      url: '/workflows/:title{templateId:[\\w-]{22}}/run-link',
      templateUrl: runChecklistTemplate,
      controller: 'ChecklistRunCtrl',
      params: {
        title: '',
      },
      data: {
        internal: true,
        skipLoader: true,
        skipLoginCheck: true,
        pageTitle: 'Run Workflow from Link | Process Street',
      },
    })
    .state('checklist', {
      parent: 'app',
      url: '/runs/:title{id:[\\w-]{22}}?comments',
      templateUrl: checklistTemplate,
      controller: 'ChecklistCtrl',
      params: {
        title: {
          dynamic: true,
          value: '',
        },
        comments: {
          dynamic: true,
        },
        taskModal: {
          dynamic: true,
        },
        modalTaskId: {
          dynamic: true,
        },
      },
      data: {
        internal: true,
        skipLoginCheck: true,
        skipLoader: true,
      },
    })
    .state('checklist.task', {
      url: '/tasks/{groupId:[\\w-]{22}}?approvalAction',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('checklist.task.comment', {
      url: '#{commentId:[\\w-]{22}}',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('checklistV2', {
      parent: 'app',
      url: '/v2/runs/:title{id:[\\w-]{22}}?comments',
      templateUrl: checklistV2Template,
      params: {
        title: {
          dynamic: true,
          value: '',
        },
        comments: {
          dynamic: true,
        },
        taskModal: {
          dynamic: true,
        },
        modalTaskId: {
          dynamic: true,
        },
      },
      data: {
        internal: true,
        skipLoginCheck: true,
        skipLoader: true,
      },
    })
    .state('checklistV2.task', {
      url: '/tasks/{groupId:[\\w-]{22}}?approvalAction',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('checklistV2.task.comment', {
      url: '#{commentId:[\\w-]{22}}',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('checklistShared.task', {
      url: '/tasks/{groupId:[\\w-]{22}}',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('checklistManage', {
      parent: 'app',
      url: '/runs/{id:[\\w-]{22}}/manage',
      templateUrl: checklistManageTemplate,
      controller: 'ChecklistManageCtrl',
      data: {
        internal: true,
        pageTitle: 'Manage Workflow Run | Process Street',
      },
    })
    .state('checklistManage.tab', {
      url: '/:tab',
      data: {
        internal: true,
      },
    })
    .state('checklistAddMultiple', {
      parent: 'app',
      url: '/runs/new-multiple?templateId',
      templateUrl: addMultipleChecklistTemplate,
      controller: 'ChecklistAddMultipleCtrl',
      data: {
        internal: true,
        pageTitle: 'Run Multiple Workflows | Process Street',
        skipLoader: true,
      },
    })
    .state('checklistPrint', {
      url: '/runs/{id:[\\w-]{22}}/print',
      templateUrl: printChecklistTemplate,
      controller: 'ChecklistPrintCtrl',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('checklistPrintNew', {
      url: '/runs/{id:[\\w-]{22}}/print-wfr',
      templateUrl: printChecklistTemplateNew,
      controller: 'ChecklistPrintCtrl',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('sandboxChecklist', {
      parent: 'app',
      url: '/preview/runs/:title{id:[\\w-]{22}}',
      templateUrl: checklistTemplate,
      controller: 'ChecklistCtrl',
      params: {
        title: {
          dynamic: true,
          value: '',
        },
      },
      data: {
        internal: true,
        skipLoginCheck: true,
        skipLoader: true,
        hideTopBar: true,
      },
    })
    .state('sandboxChecklist.task', {
      url: '/tasks/{groupId:[\\w-]{22}}',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    // Future Checklist
    .state('futureChecklistAdd', {
      parent: 'app',
      url: '/scheduled-workflows/new?templateId',
      templateUrl: addFutureChecklistTemplate,
      controller: 'FutureChecklistAddCtrl',
      params: {
        templateId: null,
      },
      data: {
        skipLoader: true,
        internal: true,
        pageTitle: 'Schedule Workflow | Process Street',
      },
    })
    .state('futureChecklist', {
      parent: 'app',
      url: '/scheduled-workflows/:id',
      templateUrl: futureChecklistTemplate,
      controller: 'FutureChecklistCtrl',
      data: {
        skipLoader: true,
        internal: true,
      },
    })
    // Task
    .state('task', {
      parent: 'app',
      url: '/tasks/:id',
      templateUrl: taskTemplate,
      controller: 'TaskCtrl',
      data: {
        internal: true,
      },
    })
    // Subscription
    .state('templateSubscriptionUnsubscribe', {
      parent: 'appLight',
      url: '/subscriptions/template/:id/unsubscribe',
      templateUrl: unsubscribeTemplate,
      controller: 'TemplateUnsubscribeCtrl',
      data: {
        internal: true,
        external: true,
      },
    })
    .state('checklistSubscriptionUnsubscribe', {
      parent: 'appLight',
      url: '/subscriptions/checklist/:id/unsubscribe',
      templateUrl: unsubscribeTemplate,
      controller: 'ChecklistUnsubscribeCtrl',
      data: {
        internal: true,
        external: true,
      },
    })
    .state('userUnsubscribe', {
      url: '/subscriptions/user/:token/unsubscribe',
      templateUrl: userUnsubscribeTemplate,
      controller: 'UserUnsubscribeCtrl',
      data: {
        internal: true,
        external: true,
      },
    })
    .state('objectRedirect', {
      parent: 'app',
      url: '/object-redirect/:type/:id',
      template: '',
      controller: 'ObjectRedirectCtrl',
      data: {
        internal: true,
      },
    })
    .state('redirect', {
      url: '/redirect/:to?id',
      templateUrl: sharedRedirectTemplate,
      controller: 'RedirectCtrl',
      data: {
        internal: true,
      },
    })
    // Reports
    .state('reports', {
      url: '/reports?templateId&folderId&tab',
      parent: 'app',
      templateUrl: newDashboardTemplate,
      controller: 'NewDashboardController',
      data: {
        internal: true,
        skipLoader: true,
        pageTitle: 'Reports | Process Street',
      },
      params: {
        tab: {
          value: ReportsTab.Table,
          dynamic: true,
        },
      },
    })
    .state('reports.savedView', {
      url: '/saved-views/{savedViewId:[\\w-]{22}}',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    // Data Sets
    .state('dataSets', {
      url: '/data-sets?recordId',
      parent: 'app',
      templateUrl: dataSetPageTemplate,
      data: {
        internal: true,
        skipLoader: true,
        pageTitle: 'Data Sets | Process Street',
      },
    })
    .state('dataSets.dataSet', {
      url: '/:dataSetId',
    })
    .state('dataSets.savedView', {
      url: '/:dataSetId/saved-views/:savedViewId',
    })
    .state('slackAuthorize', {
      url: '/slack/auth/callback?code',
      component: 'psSlackAuthorizePage',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('slackConnect', {
      url: '/slack/connect?slackTeamId&slackUserId&hmac&enterpriseId',
      component: 'psSlackConnectPage',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('microsoftTeams', {
      url: '/microsoft-teams',
      templateUrl: microsoftTeamsAppTemplate,
    })
    .state('msTeamsChecklist', {
      parent: 'microsoftTeams',
      url: '/runs/:title{id:[\\w-]{22}}',
      templateUrl: checklistTemplate,
      controller: 'ChecklistCtrl',
      params: {
        title: {
          dynamic: true,
          value: '',
        },
      },
      data: {
        internal: true,
        skipLoginCheck: true,
        skipLoader: true,
      },
    })
    .state('msTeamsChecklist.task', {
      url: '/tasks/{groupId:[\\w-]{22}}',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('microsoftTeamsConnect', {
      url: '/microsoft-teams/connect?teamsConversationId',
      component: 'psMicrosoftTeamsConnect',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('microsoftTeamsInbox', {
      parent: 'microsoftTeams',
      url: '/inbox',
      component: 'psMicrosoftTeamsInbox',
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('microsoftTeamsRedirect', {
      parent: 'microsoftTeams',
      url: '/redirect?provider',
      component: 'psMsTeamsLoginRedirect',
      data: { external: true },
    })
    .state('startHere', {
      parent: 'app',
      url: '/start-here',
      component: 'psStartHere',
      data: {
        internal: true,
        skipLoader: true,
        skipLoginCheck: true,
      },
    })
    .state('page', {
      parent: 'app',
      url: '/pages/:title{id:[\\w-]{22}}/edit',
      templateUrl: editPageTemplate,
      params: {
        title: {
          dynamic: true,
          value: '',
        },
      },
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('pageV2', {
      parent: 'app',
      url: '/pages/v2/:title{id:[\\w-]{22}}/edit',
      templateUrl: editPageTemplateV2,
      params: {
        title: {
          dynamic: true,
          value: '',
        },
      },
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('form', {
      url: '/forms/:title{id:[\\w-]{22}}/edit',
      templateUrl: editFormTemplate,
      params: {
        title: {
          dynamic: true,
          value: '',
        },
      },
      data: {
        internal: true,
        skipLoader: true,
        hideTopBar: true,
      },
    })
    .state('form.step', {
      url: '/steps/{groupId:[\\w-]{22}}',
      data: {
        internal: true,
        skipLoader: true,
        hideTopBar: true,
      },
    })
    .state('formSettings', {
      url: '/forms/:title{id:[\\w-]{22}}/settings',
      templateUrl: formSettingsPageTemplate,
      params: {
        title: {
          dynamic: true,
          value: '',
        },
      },
      data: {
        internal: true,
        skipLoader: true,
        hideTopBar: true,
      },
    })
    .state('formResponses', {
      url: '/forms/:title{id:[\\w-]{22}}/responses',
      templateUrl: formResponsesPageTemplate,
      params: {
        title: {
          dynamic: true,
          value: '',
        },
      },
      data: {
        internal: true,
        skipLoader: true,
        hideTopBar: true,
      },
    })
    .state('addFormResponse', {
      url: '/forms/{id:[\\w-]{22}}/respond',
      templateUrl: addFormResponseTemplate,
      params: {},
      data: {
        internal: true,
        skipLoginCheck: true,
        skipLoader: true,
      },
    })
    .state('formLogin', {
      url: '/forms/{id:[\\w-]{22}}/login',
      templateUrl: formLoginTemplate,
      params: {},
      data: {
        internal: true,
        skipLoginCheck: true,
        skipLoader: true,
      },
    })
    .state('formResponse', {
      url: '/responses/{id:[\\w-]{22}}',
      templateUrl: formResponseTemplate,
      params: {},
      data: {
        internal: true,
        skipLoginCheck: true,
        skipLoader: true,
      },
    })
    .state('formResponseFinish', {
      url: '/responses/{id:[\\w-]{22}}/finish',
      templateUrl: formResponseFinishTemplate,
      params: {},
      data: {
        internal: true,
        skipLoginCheck: true,
        skipLoader: true,
      },
    })
    .state('openOneOffTask', {
      url: '/tasks/{id:[\\w-]{22}}/view',
      templateUrl: viewOneOffTaskTemplate,
      params: {},
      data: {
        internal: true,
        skipLoginCheck: true,
        skipLoader: true,
      },
    })
    .state('requestAccess', {
      parent: 'app',
      url: '/request-access/{id:[\\w-]{22}}',
      templateUrl: requestAccessTemplate,
      params: {},
      data: {
        internal: true,
        skipLoader: true,
      },
    })
    .state('otherwise', {
      url: '/',
      controller: 'OtherwiseCtrl',
      data: {
        internal: true,
        external: true,
      },
    });

  // URL re-mappings
  const redirectChecklistRunLink = ($match, $location) => {
    const params = $location.url().split('?').slice(1).join('?'); // In case there's a second question mark
    return `/workflows/${$match[1]}/run-link` + (params ? `?${params}` : '');
  };
  const redirectBulkApprovalLink = $match =>
    `/runs/${$match.id}/tasks/${$match.taskId}?approvalAction=${$match.action}`;
  // BEWARE This does not evaluate in order, UI Router is terrible and just does it in some weird order
  $urlRouterProvider
    .when(new RegExp('^/templates/(.*?)/create/checklist(.*)$'), redirectChecklistRunLink)
    .when(new RegExp('^/templates/(.*?)/checklists/create(.*)$'), redirectChecklistRunLink)
    .when(new RegExp('^/shared/templates/(.*?)/checklists/create(.*)$'), redirectChecklistRunLink)
    .when(new RegExp('^/templates/(.*?)/checklists/run(.*)$'), redirectChecklistRunLink)
    .when('/future-checklists/add/{templateId:[\\w-]{22}}', '/scheduled-workflows/new?templateId')
    .when('/future-checklists/:id', '/scheduled-workflows/:id')
    .when('/checklists/add/{templateId:[\\w-]{22}}', AppRedirectHandlers.runWorkflow)
    .when('/checklists/new?templateId', AppRedirectHandlers.runWorkflow)
    .when('/runs/new?templateId', AppRedirectHandlers.runWorkflow)
    .when('/checklists/new-multiple?templateId', '/runs/new-multiple?templateId')
    .when('/checklists/:id', '/runs/:id')
    .when('/checklists/:id/tasks/:taskId', '/runs/:id/tasks/:taskId')
    .when('/checklists/:id/print', '/runs/:id/print')
    .when('/checklist-dashboard/saved-views/:id', '/reports/saved-views/:id')
    .when('/checklist-dashboard?templateId&folderId', '/reports?templateId&folderId')
    .when('/dashboard', '/library')
    .when('/dashboard/:type/:path', '/library/{type}s/:path')
    .when('/templates/:id/view', '/workflows/:id/view')
    .when('/templates/:id/view/tasks/:taskId', '/workflows/:id/view/tasks/:taskId')
    .when('/templates/:id/print', '/workflows/:id/print')
    .when('/templates/:id/edit', '/workflows/:id/edit')
    .when('/templates/:id/edit/tasks/:taskId', '/workflows/:id/edit/tasks/:taskId')
    .when('/templates/add?templateId', '/workflows/new?templateId')
    .when('/templates/:id/manage', '/workflows/:id/manage')
    .when('/templates/:id/manage/:tab', '/workflows/:id/manage/:tab')
    .when('/shared/checklists/:id', '/runs/:id')
    .when('/shared/checklists/:id/tasks/:taskId', '/runs/:id/tasks/:taskId')
    // Legacy "review with token" links - redirect to URL without token
    .when('/checklists/:id/tasks/:taskId/:action/:token', redirectBulkApprovalLink)
    .when(
      '/new/sign-up?email&planId&coupon&source&templateId&pageId',
      '/sign-up?email&planId&coupon&source&templateId&pageId',
    )
    .otherwise('/');
});
