import { from, interval, merge, Observable, Subject } from 'rxjs';
import {
  catchError,
  concatAll,
  debounceTime,
  groupBy,
  map,
  mergeMap,
  reduce,
  window,
  tap,
  concatMap,
  filter,
  shareReplay,
  buffer,
} from 'rxjs/operators';
import * as Mutations from 'features/widgets/query-builder';
import { serialize } from 'pages/pages/_id/edit/page/utils/serialization';
import { PSEditor } from 'pages/pages/_id/edit/page/utils/ps-editor';
import { PageEvent } from './page-event';
import { TableWidget, TextWidget, Widget, WidgetHeader } from '@process-street/subgrade/process';
import { Trace } from 'components/trace';
import { PagesEditor } from '../../pages-plate-types';
import { withoutNormalizing, withoutSavingHistory } from '@udecode/slate';

/** Events will be consolidated within the debounce time. */
const EVENT_DEBOUNCE_TIME = 500;
/**
 * If there are too many event requests (e.g. pasting large documents), force a Slate rerender on this interval.
 */
const RENDER_INTERVAL = 1000;
/**
 * Debounce Slate renders with this interval, instead of rendering on every event.
 */
const RENDER_DEBOUNCE = 100;

/**
 * Builds the subject {@link https://rxjs.dev/guide/subject} and observable stream for page events.
 *
 * Events are published to the subject/stream from the ps-persistence Slate plugin then processed and
 * persisted to the backend using React Query.
 */
export const pageEventsSubject = (
  mutations: {
    create: ReturnType<typeof Mutations.useCreateWidgetMutation>['mutateAsync'];
    copy: ReturnType<typeof Mutations.useCopyWidgetMutation>['mutateAsync'];
    deleteWidget: ReturnType<typeof Mutations.useDeleteWidgetMutation>['mutateAsync'];
    update: ReturnType<typeof Mutations.useUpdateWidgetMutation>['mutateAsync'];
    updateOrderTrees: ReturnType<typeof Mutations.useUpdateWidgetOrderTreesMutation>['mutateAsync'];
    undelete: ReturnType<typeof Mutations.useUndeleteWidgetMutation>['mutateAsync'];
  },
  editor: PagesEditor,
  logger: Trace,
) => {
  const subject = new Subject<PageEvent>();
  subject.pipe(processPageEvents(), persistPageEvents(mutations, logger), updateSlateElement(editor)).subscribe();

  return subject;
};

const logErrorAndRecover = (logger: Trace) => {
  return catchError(error => {
    logger.error('An exception occurred persisting the page state. Skipping the event.', error);
    return from(Promise.resolve());
  });
};

const updateSlateElement = (editor: PagesEditor) => {
  const callback: (responses: Widget[]) => void = responses =>
    withoutSavingHistory(editor, () => {
      withoutNormalizing(editor, () => responses.forEach(r => PSEditor.setWidgetElement(editor, r)));
    });

  return deferRerenders({
    bufferCallback: callback,
    renderDebounce: RENDER_DEBOUNCE,
    renderInterval: RENDER_INTERVAL,
  });
};

/**
 * Creates an RXJS operator which updates the Slate elements with the newly created/updated Widgets.
 * Render after 'RENDER_DEBOUNCE' ms time for short edits, but if there are many requests to complete,
 * render every 'RENDER_INTERVAL' ms.
 *
 * bufferNotifier: when it emits, a batch of events will be created
 *  - debouncedResponses
 *      emit after events complete and no more is in progress
 *      this should be short so that Slate is updated quickly after small edits
 *  - emit after every interval
 *
 * main:
 *  - filter
 *      filter for widget change events only
 *  - buffer
 *      create batches of events to render, each time when bufferNotifier emits.
 *  - filter
 *      filter out empty emissions
 *  - tap
 *      update nodes in the batch in Slate synchronously (a rerender will follow)
 */
export function deferRerenders<T>(options: {
  bufferCallback: (responses: Widget[]) => void;
  renderInterval: number;
  renderDebounce: number;
}) {
  const { bufferCallback, renderDebounce, renderInterval } = options;
  return (responses: Observable<T>) => {
    // Cache the event responses so that we won't send HTTP requests with each subscription below.
    const cachedResponses = responses.pipe(shareReplay());

    const debouncedResponses = cachedResponses.pipe(debounceTime(renderDebounce));
    const bufferNotifier = merge(debouncedResponses, interval(renderInterval));

    return cachedResponses.pipe(
      filter(PSEditor.isWidget),
      buffer(bufferNotifier),
      filter(responses => responses.length > 0),
      tap(bufferCallback),
    );
  };
}

/**
 * Creates an RXJS operator which handles persisting the page events via React Query
 */
const persistPageEvents = (
  mutations: {
    create: ReturnType<typeof Mutations.useCreateWidgetMutation>['mutateAsync'];
    copy: ReturnType<typeof Mutations.useCopyWidgetMutation>['mutateAsync'];
    deleteWidget: ReturnType<typeof Mutations.useDeleteWidgetMutation>['mutateAsync'];
    update: ReturnType<typeof Mutations.useUpdateWidgetMutation>['mutateAsync'];
    updateOrderTrees: ReturnType<typeof Mutations.useUpdateWidgetOrderTreesMutation>['mutateAsync'];
    undelete: ReturnType<typeof Mutations.useUndeleteWidgetMutation>['mutateAsync'];
  },
  logger: Trace,
) => {
  const persistor = (event: PageEvent) => {
    const {
      element,
      element: { widget },
    } = event;

    if (!PSEditor.isWidgetElement(element)) {
      logger.error('Page persistent event must include an element.', event);
      return from(Promise.resolve());
    }

    switch (event.type) {
      case 'create':
        return from(
          mutations.create({
            headerId: widget.header.id,
            taskTemplateId: widget.header.taskTemplate.id,
            type: widget.header.type,
            orderTree: widget.header.orderTree ?? '',
            content: serialize(element),
          }),
        ).pipe(logErrorAndRecover(logger));

      case 'update':
        return from(
          mutations.update({
            ...element.widget,
            content: serialize(element),
          } as TextWidget | TableWidget),
        ).pipe(logErrorAndRecover(logger));
      case 'delete': {
        const {
          element: { widget },
        } = event;

        return from(mutations.deleteWidget(widget.header.id)).pipe(logErrorAndRecover(logger));
      }
      case 'undelete':
        return from(mutations.undelete(widget.header.id)).pipe(logErrorAndRecover(logger));
      case 'copy': {
        const { element, srcElement } = event;

        if (PSEditor.isWidgetElement(element) && PSEditor.isWidgetElement(srcElement)) {
          const request = {
            srcHeader: srcElement.widget.header as WidgetHeader,
            dstHeader: element.widget.header as WidgetHeader,
          };

          return from(mutations.copy(request)).pipe(logErrorAndRecover(logger));
        } else {
          logger.error('Copy page persistent event must include an element and srcElement.', event);
          return from(Promise.resolve());
        }
      }
      case 'move': {
        const { orderModels } = event;

        return from(mutations.updateOrderTrees({ orderModels })).pipe(logErrorAndRecover(logger));
      }
      default:
        logger.error('Unhandled page event.', { event });
        return from(Promise.resolve());
    }
  };

  const grouper = (event: PageEvent) => event.element.widget.header.id;

  return groupByMapConcurrent({ grouper, mapper: persistor });
};

/**
 * Groups incoming events. Runs events inside individual groups one after the other.
 *
 *  - groupBy
 *      Splits the events into an ordered observable stream per widget
 *      Group observables hang around 'forever', until next navigation.
 *      Small chance of memory leak, but no real risk.
 *  - mergeMap
 *      Subscribe and run groups.
 *      Concurrency is infinite but browser request limit is ~6: https://stackoverflow.com/a/985704
 *      - concatMap(mapper)
 *          'mapper' is how we create HTTP requests from events.
 *          concatMap ensures to chain requests one after the other inside each group.
 */
export function groupByMapConcurrent<T>(options: { grouper: (item: T) => any; mapper: (event: T) => Observable<any> }) {
  const { grouper, mapper } = options;
  return (events: Observable<T>) =>
    events.pipe(
      groupBy(grouper),
      mergeMap(group => group.pipe(concatMap(mapper))),
    );
}

/**
 * Creates an RXJS operator which handles debouncing, de-duplicating and filtering out redundant page events.
 *
 * What's happening:
 *  - groupBy
 *      Splits the events into an ordered observable stream per widget
 *  - window/debouncedWindow
 *      Creates a dynamically sized batch (window) of events based on the debounce time.
 *      This means the batch will continue growing whilst user events are still coming in.
 *  - reduce
 *      Now we have a batch of events (and the user has stopped typing) we can reduce over them to filters out duplicates and merge superseded events.
 *      Eg.
 *        A create then an update in the same batch is merged into just a create
 *        Multiple updates in the same batch are merged into a single update
 *        See the specs for more examples
 *  - concatAll
 *      Merges all the individual observables together again whilst preserving order
 */
export const processPageEvents =
  () =>
  (allWidgetEvents: Observable<PageEvent>): Observable<PageEvent> => {
    return allWidgetEvents.pipe(
      groupBy(event => event.element.widget.header.id),
      mergeMap(singleWidgetEventsObservable => {
        const debouncedWindow = singleWidgetEventsObservable.pipe(debounceTime(EVENT_DEBOUNCE_TIME));

        return singleWidgetEventsObservable.pipe(
          window(debouncedWindow),
          map(window =>
            window.pipe(
              reduce((acc: PageEvent[], event: PageEvent) => {
                const lastEvent = acc.pop();

                if (lastEvent?.type === 'create' && event.type === 'update') {
                  acc.push({
                    type: 'create' as const,
                    element: event.element,
                  });

                  return acc;
                } else if (lastEvent?.type === 'update' && event.type === 'delete') {
                  acc.push(event);

                  return acc;
                } else if (lastEvent?.type === 'update' && event.type === 'update') {
                  acc.push({
                    type: 'update' as const,
                    element: event.element,
                  });

                  return acc;
                } else if (lastEvent) {
                  acc.push(lastEvent, event);

                  return acc;
                } else {
                  acc.push(event);

                  return acc;
                }
              }, [] as PageEvent[]),
              mergeMap((events: PageEvent[]) => from(events)),
            ),
          ),
          concatAll(),
        );
      }),
    );
  };
