import { Muid } from '@process-street/subgrade/core';
import { NativeAutomation } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateNativeAutomationTriggerMutation {
  export type Params = { nativeAutomationId: Muid; trigger: NativeAutomation.Trigger };

  export type Response = NativeAutomation;

  export const key = ['put', 'native-automation', 'trigger'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/native-automations/${params.nativeAutomationId}/trigger`, params.trigger)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
