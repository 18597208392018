import {
  FormLabel,
  Input,
  FormControl,
  InputProps,
  InputGroup,
  InputRightAddon,
  Button,
  FormErrorMessage,
  useBoolean,
} from 'components/design/next';
import { Icon } from 'components/design/next';
import * as React from 'react';

export type PasswordInputFieldProps = Omit<InputProps, 'type'> & {
  error?: string;
  helperText?: string;
  label: string;
};

export const PasswordInputField: React.VFC<PasswordInputFieldProps> = ({
  error,
  label,
  name,
  isRequired,
  onBlur,
  onFocus,
  ...props
}) => {
  const [isPasswordVisible, { toggle: togglePasswordVisibility }] = useBoolean();
  const [isInputFocused, setInputFocused] = useBoolean();

  return (
    <FormControl isRequired={isRequired} isInvalid={Boolean(error)}>
      <FormLabel mb="2.5" htmlFor="text-input-password">
        {label}
      </FormLabel>

      <InputGroup>
        <Input
          aria-describedby={`text-input-${name}-error`}
          backgroundColor="white"
          borderColor="gray.300"
          borderRadius="base"
          borderWidth="thin"
          color="gray.700"
          height="12"
          id={`text-input-${name}`}
          name={name}
          type={isPasswordVisible ? 'text' : 'password'}
          onFocus={e => {
            setInputFocused.on();
            onFocus?.(e);
          }}
          onBlur={e => {
            setInputFocused.off();
            onBlur?.(e);
          }}
          {...props}
        />
        <InputRightAddon h="12" bgColor="white" p="0" borderColor="gray.300">
          <Button
            bgColor="transparent"
            color="gray.400"
            onClick={togglePasswordVisibility}
            _hover={{ bgColor: 'transparent' }}
            _active={{ bgColor: 'transparent' }}
          >
            <Icon icon={isPasswordVisible ? 'eye-slash' : 'eye'} size="4" />
          </Button>
        </InputRightAddon>
      </InputGroup>

      {error && !isInputFocused && (
        <FormErrorMessage position="absolute" id={`text-input-${name}-error`} role="alert">
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
