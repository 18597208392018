import * as React from 'react';
import { Box, Button, Icon, Text, useBoolean } from 'components/design/next';

import { match, P } from 'ts-pattern';

import { TemplateRevision } from '@process-street/subgrade/process';
import { WidgetConstants } from '@process-street/subgrade/process/widget-constants';

import { WidgetServiceUtils } from 'services/widget-service.utils';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';

import { BlvdSelect } from 'components/design/BlvdSelect/BlvdSelect';
import { OnChangeValue } from 'react-select';
import { SelectedVariableOption } from './component';
import { useFeatureFlag } from 'features/feature-flags';

type LinkVariablesSelectProps = {
  templateRevision: TemplateRevision;
  handleChange: (value: OnChangeValue<SelectedVariableOption, true>) => void;
};
export const LinkVariablesSelect: React.FC<React.PropsWithChildren<LinkVariablesSelectProps>> = ({
  templateRevision,
  handleChange,
}) => {
  const [expanded, setExpanded] = useBoolean();
  const icon = expanded ? 'caret-down' : 'caret-right';
  const isDataSetDropdownAutomationsEnabled = useFeatureFlag('dataSetDropdownAutomations');

  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevision?.id, {
    enabled: Boolean(templateRevision?.id),
    select: widgets => {
      const formFieldKeysMap = WidgetServiceUtils.getFormFieldWidgetKeyMap(
        widgets,
        WidgetConstants.FormFieldKeysContext.RUN_LINK,
        isDataSetDropdownAutomationsEnabled,
      );
      const options = Object.keys(formFieldKeysMap).map(key => ({
        value: `form.${key}`,
        label: formFieldKeysMap[key].label,
      }));
      return [{ value: 'run_name', label: 'run name' }, ...options] as SelectedVariableOption[];
    },
  });

  return (
    <>
      <Button
        aria-label="select link options"
        style={{ display: 'flex' }}
        color="brand.500"
        fontWeight="medium"
        variant="unstyled"
        onClick={setExpanded.toggle}
        rightIcon={<Icon icon={icon} variant="fas" size="4" />}
      >
        Link Options
      </Button>
      {expanded && (
        <>
          <Text color="gray.400">
            Variables allow you to pass data from other apps into your new workflow run.
            <br />
            Example: Add an email variable and pass your client's email into a workflow run from your CRM.
          </Text>
          {match(widgetsQuery)
            .with({ status: 'success', data: P.not(P.nullish) }, ({ data }) => {
              return (
                <Box w="full">
                  <BlvdSelect isMulti onChange={handleChange} options={data} placeholder="Add variable" />
                </Box>
              );
            })
            .otherwise(() => null)}
        </>
      )}
    </>
  );
};
