import classNames from 'classnames';
import React, { ReactNode, useLayoutEffect, useRef } from 'react';
import { components, GroupBase, MenuListProps } from 'react-select';
import { FixedSizeList } from 'react-window';

export const VirtualizedMenuList = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MenuListProps<Option, IsMulti, Group> & { disableScrollToFocusedOption?: boolean },
) => {
  const { children, maxHeight, isMulti, disableScrollToFocusedOption, selectProps } = props;
  const listRef = useRef<FixedSizeList>(null);

  const menuListPadding = 0;
  const optionHeight = selectProps?.optionHeight ?? 40;

  /**
   * When the children change due to searching or keyboard navigation
   * ensure the focused option is kept in view.
   */
  useLayoutEffect(() => {
    if (listRef.current && !disableScrollToFocusedOption) {
      const focusedOptionIndex = React.Children.toArray(children).findIndex(child => {
        if (React.isValidElement(child)) {
          return child.props.isFocused;
        }
        return false;
      });

      listRef.current.scrollToItem(focusedOptionIndex);
    }
  }, [children, disableScrollToFocusedOption]);

  const renderNoOptions = () => <components.NoOptionsMessage {...props} innerProps={{}} />;

  const renderOptions = (childrenArray: ReactNode[]) => {
    const height = Math.min(maxHeight, optionHeight * React.Children.count(childrenArray) + menuListPadding);

    const classes = classNames(['blvd-select__menu-list'], {
      'blvd-select__menu-list--is-multi': isMulti,
      'blvd-select__menu-list--overflow-hidden': height < maxHeight,
    });

    return (
      <FixedSizeList
        ref={listRef}
        width={''}
        height={height}
        itemCount={childrenArray.length}
        itemSize={optionHeight}
        className={classes}
      >
        {({ index, style }) => {
          return (
            <div
              style={{
                ...style,
                top: `${parseFloat(style.top ? style.top.toString() : '0') + menuListPadding}px`,
              }}
            >
              {childrenArray[index]}
            </div>
          );
        }}
      </FixedSizeList>
    );
  };

  return Array.isArray(children) ? renderOptions(children) : renderNoOptions();
};
