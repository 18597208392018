export class OrganizationDomainApi {
  constructor(OrganizationDomains) {
    'ngInject';

    this.OrganizationDomains = OrganizationDomains;
  }

  /**
   * Gets all organization domains by organization id
   */
  getAllByOrganizationId = organizationId =>
    this.OrganizationDomains.getAllByOrganizationId({ organizationId }).$promise;

  /**
   * Create organization domain by organization id
   */
  createByOrganizationIdAndName = (organizationId, name) =>
    this.OrganizationDomains.createByOrganizationIdAndName({ organizationId, name }).$promise;

  /**
   * Deletes domain by organization id
   */
  deleteByOrganizationIdAndName = (organizationId, name) =>
    this.OrganizationDomains.deleteByOrganizationIdAndName({ organizationId, name }).$promise;
}
