import * as React from 'react';
import { usePageNameEditor } from './use-page-name-editor';
import { InputProps, Flex, Icon, Input, InputGroup, InputLeftElement, Tooltip, Text } from 'components/design/next';
import { DEFAULT_PAGE_NAME, EnterTitleLabel } from '../../constants';
import { EditorTitle } from '../elements';
import Textarea from 'react-autosize-textarea';
import { TemplateCopyLinkButton } from 'components/template-copy-link-button';

export const PageName = React.memo(
  React.forwardRef<HTMLInputElement, InputProps>(
    ({ autoFocus: shouldAutoFocus, isInvalid: _invalid, ...props }, ref) => {
      const { autoFocus, isInvalid, editable, value, onChange, templateId } = usePageNameEditor({
        autoFocus: shouldAutoFocus,
      });

      React.useEffect(() => {
        if (typeof document !== 'undefined') {
          document.title = `${value} | Process Street`;
        }
      }, [value]);

      if (!editable && templateId) {
        return (
          <Text {...EditorTitle.baseStyle} mb="0">
            {value}
            <TemplateCopyLinkButton aria-label="copy page link" templateId={templateId} />
          </Text>
        );
      }
      return (
        <InputGroup mb="4">
          {isInvalid ? (
            <InputLeftElement
              height="full"
              children={
                <Tooltip label={EnterTitleLabel} placement="top" hasArrow={true}>
                  <Flex role="alert">
                    <Icon variant="far" icon="exclamation-circle" color="red.500" size="4" />
                  </Flex>
                </Tooltip>
              }
            />
          ) : null}
          <Input
            as={Textarea}
            variant="filled"
            wrap="soft"
            resize="none"
            autoFocus={autoFocus}
            ref={ref}
            aria-label="name"
            bgColor="transparent"
            px="0"
            border="none"
            height="auto"
            _hover={{ bgColor: 'transparent' }}
            placeholder={DEFAULT_PAGE_NAME}
            {...EditorTitle.baseStyle}
            isInvalid={isInvalid}
            isReadOnly={!editable}
            onChange={onChange}
            value={value ?? ''}
            onFocus={evt => {
              if (value && value === DEFAULT_PAGE_NAME) {
                evt.target.select();
              }
            }}
            {...props}
          />
        </InputGroup>
      );
    },
  ),
);
