import { TemplateRevisionSelectors } from 'reducers/template-revision/template-revision.selectors';
import { createCachedAction } from 'reducers/util';
import { combineActions } from 'redux-actions';

export const TEMPLATE_REVISION_GET_ALL_BY_TEMPLATE_ID = 'templateRevision/GET_ALL_BY_TEMPLATE_ID';
export const TEMPLATE_REVISION_CREATE = 'templateRevision/CREATE';
export const TEMPLATE_REVISION_GET_BY_ID = 'templateRevision/GET_BY_ID';
export const TEMPLATE_REVISION_GET_ALL_DRAFTS_BY_FOLDER_IDS = 'templateRevision/GET_ALL_DRAFTS_BY_FOLDER_IDS';
export const TEMPLATE_REVISION_GET_ALL_NEWEST_BY_TEMPLATE_ID = 'templateRevision/GET_ALL_NEWEST_BY_TEMPLATE_ID';
export const TEMPLATE_REVISION_FINISH_BY_ID = 'templateRevision/FINISH_BY_ID';
export const TEMPLATE_REVISION_DELETE_BY_ID = 'templateRevision/DELETE_BY_ID';
export const TEMPLATE_REVISION_DISCARD_BY_ID_SUCCESS = 'templateRevision/DISCARD_BY_ID/SUCCESS';
export const TEMPLATE_REVISION_DISCARD_BY_ID = 'templateRevision/DISCARD_BY_ID';

/**
 * Combines all actions that result in templateRevision into a single action,
 * that can be reused in handleActions
 * @type {{toString}}
 */
export const TEMPLATE_REVISION_SINGLE_RESPONSE_ACTIONS = combineActions(
  TEMPLATE_REVISION_GET_BY_ID,
  TEMPLATE_REVISION_CREATE,
  TEMPLATE_REVISION_FINISH_BY_ID,
);

/**
 * Combines all actions that result in multiple templateRevision into a single action,
 * that can be reused in handleActions.
 * @type {{toString}}
 */
export const TEMPLATE_REVISION_MULTIPLE_RESPONSE_ACTIONS = combineActions(
  TEMPLATE_REVISION_GET_ALL_BY_TEMPLATE_ID,
  TEMPLATE_REVISION_GET_ALL_NEWEST_BY_TEMPLATE_ID,
  TEMPLATE_REVISION_GET_ALL_DRAFTS_BY_FOLDER_IDS,
);

export const TemplateRevisionActions = TemplateRevisionApi => {
  'ngInject';

  const getAllTemplateRevisionsByTemplateId = createCachedAction(
    TEMPLATE_REVISION_GET_ALL_BY_TEMPLATE_ID,
    TemplateRevisionApi.getAllTemplateRevisionsByTemplateId,
    templateId => ({ templateId }),
  );

  const getAllDraftsByFolderIds = createCachedAction(
    TEMPLATE_REVISION_GET_ALL_DRAFTS_BY_FOLDER_IDS,
    TemplateRevisionApi.getAllDraftsByFolderIds,
  );

  const create = createCachedAction(TEMPLATE_REVISION_CREATE, TemplateRevisionApi.create);

  const getById = createCachedAction(TEMPLATE_REVISION_GET_BY_ID, TemplateRevisionApi.getById);

  const getAllNewestByTemplateId = createCachedAction(
    TEMPLATE_REVISION_GET_ALL_NEWEST_BY_TEMPLATE_ID,
    TemplateRevisionApi.getAllNewestByTemplateId,
    (templateId, flushCache = false) => ({ templateId, flushCache }),
    (state, templateId) => TemplateRevisionSelectors.getNewestByTemplateId(templateId)(state).length !== 0,
    TemplateRevisionSelectors.getNewestByTemplateId,
  );

  const finishById = createCachedAction(TEMPLATE_REVISION_FINISH_BY_ID, TemplateRevisionApi.finishById);

  const deleteById = createCachedAction(TEMPLATE_REVISION_DELETE_BY_ID, TemplateRevisionApi.deleteById);

  return {
    create,
    deleteById,
    finishById,
    getAllTemplateRevisionsByTemplateId,
    getById,
    getAllDraftsByFolderIds,
    getAllNewestByTemplateId,
  };
};
