import React from 'react';
import { UseQueryResult } from 'react-query';
import { match, P } from 'ts-pattern';

import { Template, TemplateType } from '@process-street/subgrade/process';
import { Icon, Flex, FlexProps, Text, Box, IconProps } from 'components/design/next';
import { CDNImage } from 'components/cdn-image';
import { CoverIcon } from '@process-street/subgrade/core';
import { useGetAllCoverIconsQuery } from 'features/cover-icon/query-builder/all-cover-icons';

export type LibraryCoverIconProps = {
  templateId: Template['id'];
  templateType: Template['templateType'];
  iconProps?: Omit<IconProps, 'icon'>;
} & FlexProps;

export const LibraryCoverIcon: React.FC<React.PropsWithChildren<LibraryCoverIconProps>> = ({
  templateId,
  templateType,
  iconProps,
  ...props
}) => {
  const coverIconQuery = useGetAllCoverIconsQuery({
    select: coverIcons => coverIcons.find(ci => ci.templateId === templateId),
  });

  return match<[UseQueryResult<CoverIcon | undefined>, TemplateType]>([coverIconQuery, templateType])
    .with([{ status: 'success', data: P.nullish }, TemplateType.Playbook], () => (
      <Icon icon="workflow" variant="fas" color="indigo.500" size="4" aria-label="workflow icon" {...iconProps} />
    ))
    .with([{ status: 'success', data: P.nullish }, TemplateType.Form], () => (
      <Icon icon="ballot" variant="fas" color="orange.500" size="4" aria-label="form icon" {...iconProps} />
    ))
    .with([{ status: 'success', data: P.nullish }, TemplateType.Page], () => (
      <Icon icon="file-alt" variant="fas" color="teal.500" size="4" aria-label="page icon" {...iconProps} />
    ))
    .with([{ status: 'success', data: { emoji: P.not(P.nullish) } }, P.any], ([{ data }, _]) => (
      <Text fontSize="md" lineHeight="none">
        {data.emoji}{' '}
      </Text>
    ))
    .with([{ status: 'success', data: { s3File: P.not(P.nullish) } }, P.any], ([{ data }, _]) => (
      <Flex
        data-testid="cover-icon-container"
        alignItems="center"
        justifyContent="center"
        height="4"
        width="4"
        position="relative"
        bg="white"
        borderRadius="50%"
        {...props}
      >
        <CDNImage
          withLqip={false}
          alt={data.s3File.originalName}
          s3File={data.s3File}
          transformation={{
            height: 16,
            width: 16,
          }}
          borderRadius="50%"
        />
      </Flex>
    ))
    .otherwise(() => <Box w="4" />);
};
