import * as React from 'react';
import { MoveWidgetModal } from '../move-widget-modal';
import { LinkDataSetModal } from '../link-data-set-modal';
import { Muid } from '@process-street/subgrade/core';
import { FormFieldSettings } from 'components/widgets/form-field/settings';
import { DeleteWidgetAlertDialog } from './delete-widget-alert-dialog';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { DisconnectDataSetConfirmationModal } from 'pages/reports/data-sets/components/disconnect-data-set-confirmation-modal/disconnect-data-set-confirmation-modal';
import { useAssignmentPickerReduxSync } from '../assignment-picker/use-assignment-picker-redux-sync';
import { ConnectDataSetModal } from 'pages/templates/_id/components/connect-data-set-modal';
import { DatasetCreateFromCsvModal } from 'pages/reports/data-sets/components/data-set-creation-modal';

export interface WidgetMenuModalsProps {
  templateRevisionId: Muid;
  taskTemplateId: Muid;
  onMove: (widget: Widget, targetTaskTemplate: TaskTemplate) => void;
  onUpdate: (widget: Widget) => Promise<Widget>;
  onDelete: (widget: Widget) => void;
}

export const WidgetMenuModals: React.FC<React.PropsWithChildren<WidgetMenuModalsProps>> = ({
  templateRevisionId,
  taskTemplateId,
  onMove,
  onUpdate,
  onDelete,
}) => {
  // HACK here since there are multiple assignment picker button instances (bulk and single),
  // we just want this listener invoked once and this is a "singleton" component.
  useAssignmentPickerReduxSync({ templateRevisionId });
  return (
    <>
      <MoveWidgetModal {...{ currentTaskTemplateId: taskTemplateId, templateRevisionId, onMove }} />
      <LinkDataSetModal {...{ templateRevisionId, taskTemplateId, onUpdate }} />
      <ConnectDataSetModal />
      <DisconnectDataSetConfirmationModal />
      <FormFieldSettings {...{ templateRevisionId, taskTemplateId, onUpdate }} />
      <DeleteWidgetAlertDialog {...{ onDelete, templateRevisionId }} />
      <DatasetCreateFromCsvModal />
    </>
  );
};
