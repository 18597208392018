import { Muid } from '@process-street/subgrade/core';
import { Checklist } from '@process-street/subgrade/process';
import { SessionService } from 'services/session-service.interface';
import { UserService } from 'services/user-service.interface';
import { Settings, SettingsObject } from './types';
import { SettingsKey } from './user-constants';
import { UserSettingsService } from './UserSettingsService';
import angular from 'angular';

type ChecklistMap = { [key in Muid]: Checklist };

const CleanupIntervalMillis = 30 * 24 * 60 * 60 * 1000; // clean up settings every thirty days

export class UserSettingsServiceImpl implements UserSettingsService {
  constructor(private userService: UserService, private sessionService: SessionService) {}

  public updateChecklistEditor(value: SettingsObject): void {
    this.userService.updateCurrentUserSettings(SettingsKey.CHECKLIST_EDITOR, value);
  }

  public updateChecklistStats(value: SettingsObject): void {
    this.userService.updateCurrentUserSettings(SettingsKey.CHECKLIST_STATS, value);
  }

  public updateDefaultHomepage(value: SettingsObject): void {
    this.userService.updateCurrentUserSettings(SettingsKey.DEFAULT_HOMEPAGE, value);
  }

  public updateTemplateEditor(value: SettingsObject): void {
    this.userService.updateCurrentUserSettings(SettingsKey.TEMPLATE_EDITOR, value);
  }

  public updateSelectedOrganizationId(value: SettingsObject): angular.IPromise<Settings> {
    return this.userService.updateCurrentUserSettings(SettingsKey.SELECTED_ORGANIZATION_ID, value);
  }

  public updateOrganizationUseFrequencyMap(value: SettingsObject): void {
    this.userService.updateCurrentUserSettings(SettingsKey.ORGANIZATION_USE_FREQUENCY_MAP, value);
  }

  public updateDashboard(value: SettingsObject): void {
    this.userService.updateCurrentUserSettings(SettingsKey.DASHBOARD, value);
  }

  public updateInbox(value: SettingsObject): void {
    this.userService.updateCurrentUserSettings(SettingsKey.INBOX, value);
  }

  public updateOnboardingProperty(current: SettingsObject, key: string, value: boolean): void {
    const updatedOnboardingProperties = { ...current, [key]: value };
    // Update Redux
    this.sessionService.setOnboardingProperty(key, value);

    // Update the backend
    this.userService.updateCurrentUserSettings(SettingsKey.ONBOARDING, updatedOnboardingProperties);
  }

  public cleanupEditorSettings(allActiveChecklists: Checklist[]): void {
    const editorProperties = this.sessionService.getChecklistEditorProperties();
    if (editorProperties) {
      const dateNow = Date.now();
      const needsCleanup =
        editorProperties.lastCleanupDate === undefined ||
        dateNow - (editorProperties.lastCleanupDate as number) > CleanupIntervalMillis;

      if (needsCleanup) {
        const allChecklistMap: ChecklistMap = this.getChecklistMap(allActiveChecklists);
        const cleanedEditorProperties: SettingsObject = this.removeKeysForInactiveChecklists(
          editorProperties,
          allChecklistMap,
        );

        cleanedEditorProperties.lastCleanupDate = dateNow;
        this.userService.updateCurrentUserSettings(SettingsKey.CHECKLIST_EDITOR, cleanedEditorProperties);
      }
    }
  }

  private getChecklistMap(allActiveChecklists: Checklist[]): ChecklistMap {
    return allActiveChecklists.reduce((checklistMap: ChecklistMap, checklist: Checklist) => {
      checklistMap[checklist.id] = checklist;
      return checklistMap;
    }, {});
  }

  private removeKeysForInactiveChecklists(
    editorSettingsKeys: SettingsObject,
    allChecklistMap: ChecklistMap,
  ): SettingsObject {
    return Object.keys(editorSettingsKeys).reduce((propertiesMap: SettingsObject, property: string) => {
      if (property.startsWith('checklist:')) {
        const checklistId = this.getChecklistIdFromSetting(property);
        if (allChecklistMap[checklistId]) {
          propertiesMap[property] = editorSettingsKeys[property];
        }
      } else {
        propertiesMap[property] = editorSettingsKeys[property];
      }
      return propertiesMap;
    }, {});
  }

  private getChecklistIdFromSetting(property: string) {
    return property.replace('checklist:', '').replace(':activeStep', '') as Muid;
  }
}
