import * as React from 'react';
import { createConnector } from 'react-instantsearch-dom';
import { HitsProvided } from 'react-instantsearch-core';
import { ListProps, Text, TextProps } from 'components/design/next';
import { Listbox, ListboxOption } from 'components/listbox';
import { GlobalSearchHits } from 'features/global-search/components/model';
import sortBy from 'lodash/sortBy';

type ProvidedProps = { listProps?: ListProps };
export type UIProps = HitsProvided<GlobalSearchHits.AnyHit> & { loading?: boolean };

export const HitsHeading: React.FC<React.PropsWithChildren<TextProps>> = props => {
  return <Text as="h3" variant="-2u" color="gray.400" pl="4" py="2" {...props} />;
};

type HitRenderProp = (hit: UIProps['hits'][number]) => React.ReactNode;

type HitsUIChild = React.ReactElement<TextProps> | HitRenderProp | null;
export type HitsUIProps = UIProps & {
  children: HitsUIChild | HitsUIChild[];
} & ProvidedProps;

export const HitsUI: React.FC<HitsUIProps> = ({ hits, loading, children, listProps }) => {
  const shouldShowLoading = hits.length === 0 && loading;
  const noResults = hits.length === 0 && !loading;

  let heading: React.ReactElement<TextProps> | null = null;
  let hitRenderProp: HitRenderProp | null = null;
  // Using this manual array strategy because React.Children utils filter out functions
  (Array.isArray(children) ? children : [children]).forEach(child => {
    if (React.isValidElement(child) && child.type === HitsHeading) {
      heading = child as React.ReactElement<TextProps>;
    }
    if (typeof child === 'function') {
      hitRenderProp = child as NonNullable<typeof hitRenderProp>;
    }
  });

  return shouldShowLoading || noResults ? null : (
    <>
      {heading}

      <Listbox
        paddingLeft="0"
        mb="0"
        borderWidth="px"
        _focus={{ borderColor: 'gray.300', borderStyle: 'solid', borderWidth: 'px', outline: 'none' }}
        {...listProps}
      >
        {hits.map(hit => (
          <ListboxOption
            key={hit.objectID}
            _first={{ borderTopColor: 'gray.200', borderTopWidth: 'px', borderTopStyle: 'solid' }}
            _notLast={{ borderBottomColor: 'gray.200', borderBottomWidth: 'px', borderBottomStyle: 'solid' }}
          >
            {hitRenderProp?.(hit)}
          </ListboxOption>
        ))}
      </Listbox>
    </>
  );
};

const connectHits = createConnector<UIProps, ProvidedProps>({
  displayName: 'Hits',
  getProvidedProps(props, _searchState, searchResults) {
    // Merge data set results with template results.
    // While searching is not complete, searchResults.results may be null
    // If only a single index was searched at first, searchResults.results directly contains 'hits'
    // For multi-index search, searchResults.results contains index names as keys.
    if (!searchResults.results) return { hits: [], loading: searchResults.searching };
    if (searchResults.results?.hits) {
      // single-index
      return { hits: searchResults.results.hits, loading: searchResults.searching };
    }
    // multi-index
    // userScore can be any number and is internal to Algolia
    // in order to merge hits from different indexes (comparing apples to oranges)
    // we normalize the userScore for each search, between 0 and 1
    const normalizedHits: GlobalSearchHits.AnyHit[] = Object.values(searchResults.results)
      .map(results => normalizeHits(results.hits))
      .flat();
    // sort equal relevances by name
    const sortedHits = sortBy(normalizedHits, [hit => hit._rankingInfo?.userScore ?? 1, 'name']).reverse();
    return { hits: sortedHits, loading: searchResults.searching, ...props };
  },
});

export const Hits = connectHits(HitsUI);

function normalizeHits(hits: GlobalSearchHits.AnyHit[]): GlobalSearchHits.AnyHit[] {
  // not ranked hits
  if (!hits.some(hit => hit._rankingInfo)) return hits;
  const maxUserScore = Math.max(...hits.map(hit => hit._rankingInfo?.userScore ?? 1)) ?? 1;
  return hits.map(hit => {
    return {
      ...hit,
      _rankingInfo: {
        ...hit._rankingInfo,
        userScore: (hit._rankingInfo?.userScore ?? 1) / maxUserScore,
      },
    };
  });
}
