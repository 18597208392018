import React from 'react';
import { Group, GroupType, Muid, Organization, Ref, User } from '@process-street/subgrade/core';
import { components, GroupBase, SelectComponentsConfig } from 'react-select';
import { Box, HStack, Icon, Text, VStack } from 'components/design/next';
import { BlvdSelect, BlvdSelectHelpers, BlvdSelectProps } from 'app/components/design/BlvdSelect';
import { ChakraAvatar } from 'app/components/design/next/chakra-avatar';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { MenuSearchHeader } from 'app/components/design/BlvdSelect/components';

export interface GroupPickerProps {
  id: string;
  value?: GroupItem;
  items: GroupItem[];
  isDisabled?: boolean;
  onSelect: (value: GroupItem) => void;
  onMenuClose?: () => void;
}

export interface GroupItem {
  id: Muid;
  organization: Ref<Organization>;
  user: User;
  groupType: GroupType;
}

type Option = GroupItem;

export const convertGroupToMemberItem = (group: Group): GroupItem => ({
  id: group.id,
  organization: group.organization,
  user: group.user as User,
  groupType: group.groupType,
});

export const GroupPicker: React.FunctionComponent<React.PropsWithChildren<GroupPickerProps>> = ({
  id,
  items,
  value: outerValue,
  isDisabled,
  onSelect,
  onMenuClose,
}) => {
  const filterOption: BlvdSelectProps<Option>['filterOption'] = (option, pattern) => {
    const { user } = option.data as Option;
    return user.username.toLowerCase().includes(pattern.toLowerCase());
  };
  const value: Option[] = outerValue ? [outerValue] : [];

  return (
    <BlvdSelect
      id={id}
      filterOption={filterOption}
      isSearchable
      getOptionValue={option => option.id}
      placeholder="Select a group"
      options={items}
      value={value}
      menuControls={false}
      closeMenuOnSelect
      isDisabled={isDisabled}
      components={BlvdMembersPickerComponents}
      onChange={item => {
        if (BlvdSelectHelpers.isOptionType<Option>(item)) {
          onSelect(item);
        }
      }}
      onMenuClose={onMenuClose}
      menuPortalTarget={document.body}
      styles={GROUP_SELECT_STYLES}
    />
  );
};

export const BlvdMembersPickerComponents: SelectComponentsConfig<Option, false, GroupBase<Option>> = {
  Option: ({ children: _, ...props }) => {
    const { user } = props.data as Option;
    return (
      <components.Option {...props}>
        <HStack spacing="2">
          <HStack>
            <GroupData user={user} />
          </HStack>
        </HStack>
      </components.Option>
    );
  },
  DropdownIndicator: ({ children: _, ...props }) => {
    const value = props.getValue();
    return (
      <HStack color="gray.600" gap={2} justifyContent="space-between" w="full" px={2}>
        <HStack>{value.length > 0 && <GroupData user={value[0].user} />}</HStack>
        <Icon icon="chevron-down" size="4" variant="far" color="gray.600" />
      </HStack>
    );
  },
  // Prevents arbitrary label appearing before dropdown
  ValueContainer: props => {
    return (
      <Box w="0" overflow="hidden" h="0">
        <components.ValueContainer {...props} />
      </Box>
    );
  },
  Menu: props => {
    const { children } = props;
    return (
      <components.Menu {...props}>
        <ThemeProvider2024>
          <MenuSearchHeader key="menu-search-header" autoFocus={false} {...props} />
          {children}
        </ThemeProvider2024>
      </components.Menu>
    );
  },
};

const GroupData = ({ user }: { user: User }) => (
  <>
    <ChakraAvatar user={user} size="xs" />
    <VStack alignItems="flex-start" spacing="0">
      <Text color="gray.600">{user.username}</Text>
    </VStack>
  </>
);

const GROUP_SELECT_STYLES = {
  control: (base: any) => ({
    ...base,
    'justifyContent': 'flex-start',
    'width': '100%',
    'height': '40px',
    '.blvd-select__indicators': { flexGrow: 1 },
    '.chakra-avatar__initials': { fontSize: 'var(--ps-font-sizes-sm)' },
  }),
  menuPortal: (base: any) => ({
    ...base,
    '.blvd-select__menu__separator': { display: 'none' },
    '.blvd-select__option': { cursor: 'pointer' },
    '.chakra-avatar__initials': { fontSize: 'var(--ps-font-sizes-sm)' },
    '.blvd-select__menu__header input': { paddingLeft: '32px' },
    '.blvd-select__menu__header__search-icon': {
      position: 'absolute',
      left: '12px',
      top: '12px',
      color: 'var(--ps-colors-gray-400)',
    },
  }),
};
