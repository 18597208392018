import { FieldType, FieldValueJson, FormFieldWidget, SimpleFieldValue } from '@process-street/subgrade/process';
import angular from 'angular';
import { TextAreaRichEditorHelper } from 'app/pages/runs/_id/components/checklist-textarea-form-field-widget/textarea-rich-editor-helper';
import { escapeHtml } from 'markdown-it/lib/common/utils';
import templateUrl from './text-renderer.component.html';
import './text-renderer.component.scss';

export class ApprovalSubjectTaskWidgetTextRendererController {
  public getValue(widget: FormFieldWidget, fieldValue?: FieldValueJson) {
    const isRichText = widget.fieldType === FieldType.Textarea && widget.config.format === 'RichText';
    const escapedValue = escapeHtml((fieldValue && (fieldValue as SimpleFieldValue).value) ?? '-');
    return isRichText ? TextAreaRichEditorHelper.markdown2html(escapedValue) : escapedValue;
  }
}

export const ApprovalSubjectTaskWidgetTextRenderer: angular.IComponentOptions = {
  bindings: {
    formFieldValue: '<',
    widget: '<',
  },
  controller: ApprovalSubjectTaskWidgetTextRendererController,
  templateUrl,
};
