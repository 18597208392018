import { useSelectedSavedView } from 'pages/reports/data-sets/components/data-set-page/selected-saved-view-id-store';
import * as React from 'react';
import { useNavigate, useOnRouteChange, useStateParams } from 'app/adapters/navigation';
import { SavedView, SavedViewType } from '@process-street/subgrade/process';
import sortBy from 'lodash/sortBy';
import { queryString } from '@process-street/subgrade/util';
import { useMount } from 'react-use';

/** Syncs the current router state & URL with the selected data set. */
export function useSyncSelectedSavedView() {
  const { dataSetPageStore, dataSets, selectedDataSet, selectedSavedView, isFetching } = useSelectedSavedView();

  const stateParams = useStateParams();
  const navigate = useNavigate();

  /** Selects the All Data saved view for the current data set, or the first saved view if it doesn't exist */
  const selectDefaultSavedView = React.useCallback(
    (savedViews: SavedView[]) => {
      const allDataSavedView = savedViews?.find(sv => sv.savedViewType === SavedViewType.AllData);
      if (allDataSavedView) {
        dataSetPageStore.setSavedViewId(allDataSavedView.id);
      } else if (savedViews.length) {
        dataSetPageStore.setSavedViewId(sortBy(savedViews, 'name')[0].id);
      }
    },
    [dataSetPageStore],
  );

  /** Initialize store when directly opening a data set from a URL. */
  const syncUrlToStore = () => {
    const { dataSetId, savedViewId } = stateParams;
    dataSetPageStore.setDataSetId(dataSetId as string | undefined);
    dataSetPageStore.setSavedViewId(savedViewId as string | undefined);
  };
  useMount(syncUrlToStore);

  /** Syncs store state when switching data sets via global search. */ useOnRouteChange(() => {
    if (stateParams.dataSetId && stateParams.dataSetId !== dataSetPageStore.dataSetId) {
      dataSetPageStore.setDataSetId(stateParams.dataSetId as string);
    }
    if (stateParams.savedViewId && stateParams.savedViewId !== dataSetPageStore.savedViewId) {
      dataSetPageStore.setSavedViewId(stateParams.savedViewId as string);
    }
  });

  /**
   * Selects default data set/saved view when the data set query has been fetched.
   * This happens when the page first loads, or when e.g. a data set has been deleted.
   * */
  const onLoadDataSets = () => {
    if (isFetching) return;

    if (selectedDataSet) {
      if (!selectedSavedView) {
        selectDefaultSavedView(selectedDataSet.savedViews);
      }
    } else {
      // Fallback to 1st data set in list e.g. when deleting
      const [firstDataSet] = dataSets;
      if (!firstDataSet) return;

      const dataSetId = firstDataSet.id;
      const savedViews = firstDataSet.savedViews ?? [];
      dataSetPageStore.setDataSetId(dataSetId);
      selectDefaultSavedView(savedViews);

      navigate({
        pathname: 'dataSets.dataSet',
        search: queryString.stringify({ dataSetId }),
      });
    }
  };

  React.useEffect(
    onLoadDataSets,
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only react to new query data
    [isFetching, selectedDataSet, selectedSavedView, dataSets],
  );

  return {
    selectDefaultSavedView,
  };
}
