import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Center,
  HStack,
  Icon,
  InputGroup,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useBoolean,
  useDisclosure,
  VStack,
} from 'components/design/next';
import { SendRichEmailTemplateWidgetProps } from '..';
import { useUnmount } from 'react-use';
import { SendRichEmailEditorV1 } from '../editor';
import { BodyEditorTab, EmailBodyIframe, Field, Label, sanitizeEmailBody } from '../../common/common';
import { SendTestEmailButton } from '../send-test-email-button';
import { calculateTotalSizeOfAttachments, isSendTestEmailDisabled } from '../../util';
import { MergeTagsMenu, MergeTagsMenuButton } from 'features/merge-tags/components/merge-tags-menu';
import { MergeTagTarget } from '@process-street/subgrade/form';
import { useMergeTaggableInput } from 'hooks/use-merge-taggable-input';
import {
  EmailEditor,
  EmailFormat,
  RichEmailWidgetAttachmentWithS3File,
  SendRichEmailFormFieldConfig,
} from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { useDebouncedCallback } from 'use-debounce';
import { useQueryClient } from 'react-query';
import { makeMutationListener } from 'services/react-query';
import { PublishDraftMutation } from 'features/template-revisions/query-builder';
import { SendTestMailtoButton } from '../send-test-mailto-button';
import { EmailFields, EmailFieldValues } from 'features/widgets/components/send-rich-email/common/email-fields';
import { UploadTemplateEmailAttachment } from 'features/widgets/components/send-rich-email/email_attachment/upload-template-email-attachment';
import { UploadProgress } from 'features/upload/components';
import { EmailAttachmentItem } from 'features/widgets/components/send-rich-email/email_attachment/email-attachment-item';
import { Option } from '@process-street/subgrade/core';
import { useDeleteTemplateEmailAttachmentMutation } from 'features/widgets/components/send-rich-email/query-builder/delete-template-email-attachment';
import { useFeatureFlag } from 'features/feature-flags';
import { SendRichEmailAiGenerateMenu } from '../../../ai/ai-generate-send-email-content-menu';
import { useWidgetUpdateOverrideListener } from 'hooks/use-widget-update-override-listener';
import { useTemplateWidgetsStore } from 'features/widgets/use-template-widgets-store';
import { useWidgetUpdateSettingsListener } from 'hooks/use-widget-update-settings-listener';
import { Divider } from '@chakra-ui/react';
import { AutoSendEmailToggle } from 'features/widgets/components/send-rich-email/template/edit/auto-send-email-toggle';
import { SendRichEmailTemplateEditor } from '../editor/send-rich-email-template-editor-v2';

const SANITIZE_BLUR_TIMEOUT = 500;

export const DEBOUNCE_TIME = 500;

export type EditProps = SendRichEmailTemplateWidgetProps;

const EDITOR_TABS: SendRichEmailFormFieldConfig['editor'][] = ['RichEditor', 'RawHTMLEditor', 'PlainTextEditor'];
const EDITOR_TAB_INDICES = EDITOR_TABS.reduce((acc, tab, i) => {
  acc[tab] = i;
  return acc;
}, {} as Record<SendRichEmailFormFieldConfig['editor'], number>);

export const Edit: React.FC<React.PropsWithChildren<EditProps>> = ({
  widget,
  onUpdate,
  templateRevision,
  taskTemplate,
}) => {
  const updatingWidgetGroupIds = useTemplateWidgetsStore(s => s.updatingWidgetGroupIds);
  const isUpdating = React.useMemo(
    () => updatingWidgetGroupIds.has(widget.header.group?.id),
    // Optimistic widget from angular might not have group 🙄
    [updatingWidgetGroupIds, widget.header.group?.id],
  );

  // Makes sure that we keep current optimistic values (of user edits) when we receive new data from the server
  const lastUpdateTimeRef = React.useRef<number>(Date.now());

  const debouncedOnUpdate = useDebouncedCallback((partial: Partial<SendRichEmailFormFieldConfig>) => {
    onUpdate({
      widget: {
        ...widget,
        config: {
          ...widget.config,
          to: emailFieldValues.to,
          cc: emailFieldValues.cc,
          bcc: emailFieldValues.bcc,
          subject: emailFieldValues.subject,
          rawHTMLBody,
          plainTextBody,
          editor: EDITOR_TABS[editorTabIndex],
          ...partial,
        },
      },
    });
  }, DEBOUNCE_TIME);

  const updateDebounced = React.useCallback(
    (partial: Partial<SendRichEmailFormFieldConfig>) => {
      // When the user makes edits, update the last updated time ref, before debouncing the call
      // Updated widget prop value might appear any time after a successful API call
      lastUpdateTimeRef.current = Date.now();
      debouncedOnUpdate(partial);
    },
    [debouncedOnUpdate],
  );

  const [emailFieldValues, setEmailFieldValues] = React.useState<EmailFieldValues>({
    to: widget.config.to,
    cc: widget.config.cc,
    bcc: widget.config.bcc,
    subject: widget.config.subject,
  });

  const [rawHTMLBody, setRawHTMLBody] = React.useState(
    sanitizeEmailBody(
      (widget.config.rawHTMLBody === '' ? widget.config.richEditorBody : widget.config.rawHTMLBody) ?? '',
    ),
  );
  const [richEditorBody, setRichEditorBody] = React.useState(widget.config.richEditorBody ?? '');

  const [plainTextBody, setPlainTextBody] = React.useState(widget.config.plainTextBody ?? '');
  const [editorTabIndex, setEditorTabIndex] = React.useState(EDITOR_TAB_INDICES[widget.config.editor]);
  const [attachments, setAttachments] = React.useState<RichEmailWidgetAttachmentWithS3File[]>(
    widget.config.attachments ?? [],
  );

  const hasRecipient = [emailFieldValues.to, emailFieldValues.cc, emailFieldValues.bcc].some(e => (e?.length ?? 0) > 0);
  const isAutoSendRichEmailToggleEnabled = !isSendTestEmailDisabled(widget.config) && hasRecipient;

  const totalSize = calculateTotalSizeOfAttachments(attachments);

  const deleteAlertDisclosure = useDisclosure();
  const deleteAlertCancelRef = React.useRef<HTMLButtonElement>(null);

  const [attachmentToDelete, setAttachmentToDelete] = React.useState<Option<RichEmailWidgetAttachmentWithS3File>>();
  const handleDeleteAttachment = (attachment: RichEmailWidgetAttachmentWithS3File) => {
    setAttachmentToDelete(attachment);
    deleteAlertDisclosure.onOpen();
  };

  const deleteTemplateEmailAttachmentMutation = useDeleteTemplateEmailAttachmentMutation();
  const doDeleteAttachment = async () => {
    deleteAlertDisclosure.onClose();
    if (attachmentToDelete) {
      const updatedAttachments = attachments.filter(a => a.attachment.id !== attachmentToDelete.attachment.id);
      setAttachments(updatedAttachments);

      await deleteTemplateEmailAttachmentMutation.mutateAsync({ attachmentId: attachmentToDelete.attachment.id });

      onUpdate({
        widget: {
          ...widget,
          config: {
            ...widget.config,
            attachments: updatedAttachments,
          },
        },
      });
    }
  };

  React.useEffect(() => {
    setAttachments(widget.config.attachments ?? []);
  }, [widget.config]);

  const syncWidgetChanges = React.useCallback((updated: EditProps['widget']): void => {
    setEmailFieldValues({
      to: updated.config.to,
      cc: updated.config.cc,
      bcc: updated.config.bcc,
      subject: updated.config.subject,
    });
    setRawHTMLBody(
      sanitizeEmailBody(
        (updated.config.rawHTMLBody === '' ? updated.config.richEditorBody : updated.config.rawHTMLBody) ?? '',
      ),
    );
    setPlainTextBody(updated.config.plainTextBody ?? '');
    setRichEditorBody(updated.config.richEditorBody ?? '');
    setEditorTabIndex(EDITOR_TAB_INDICES[updated.config.editor]);
  }, []);

  useWidgetUpdateOverrideListener(widget, syncWidgetChanges);

  const isReactWorkflowEditorEnabled = useFeatureFlag('reactWorkflowEditor');
  React.useEffect(
    function syncPropChanges() {
      // keep local optimistic values if they are newer
      if (isReactWorkflowEditorEnabled && widget.header.audit.updatedDate > lastUpdateTimeRef.current) {
        syncWidgetChanges(widget);
      }
    },
    [isReactWorkflowEditorEnabled, syncWidgetChanges, widget],
  );

  useWidgetUpdateSettingsListener(widget, updated => {
    setEditorTabIndex(EDITOR_TAB_INDICES[updated.config.editor]);
  });

  const handleRawHTMLBodyChange = React.useCallback(
    (value: string) => {
      setRawHTMLBody(value);
      updateDebounced({ rawHTMLBody: value });
    },
    [updateDebounced],
  );

  const handleEmailFieldValuesChange = React.useCallback(
    (value: EmailFieldValues) => {
      setEmailFieldValues(value);
      updateDebounced({ emailFieldValues: value });
    },
    [updateDebounced],
  );

  const handlePlainTextBodyChange = React.useCallback(
    (value: string) => {
      setPlainTextBody(value);
      updateDebounced({ plainTextBody: value });
    },
    [updateDebounced],
  );

  const handleEditorTabIndexChange = React.useCallback(
    (value: number) => {
      setEditorTabIndex(value);
      updateDebounced({ editorTabIndex: value });
    },
    [updateDebounced],
  );

  const { ref: rawHTMLBodyRef, insertMergeTag: insertRawHTMLBody } = useMergeTaggableInput<HTMLTextAreaElement>({
    get: () => rawHTMLBody,
    set: handleRawHTMLBodyChange,
  });

  const { ref: plainTextBodyRef, insertMergeTag: insertPlainTextBody } = useMergeTaggableInput<HTMLTextAreaElement>({
    get: () => plainTextBody,
    set: handlePlainTextBodyChange,
  });

  const warningDisclosure = useDisclosure();

  const handleTabChange = (index: number) => {
    const sanitizedRichBody = sanitizeEmailBody(richEditorBody);
    const rawHtmlHasDivergedFromRichText = rawHTMLBody !== sanitizedRichBody;
    const attemptingToLeaveRawHtml = index === EDITOR_TAB_INDICES.RichEditor;
    if (rawHtmlHasDivergedFromRichText && attemptingToLeaveRawHtml) {
      warningDisclosure.onOpen();
      return;
    }

    if (index === EDITOR_TAB_INDICES.RawHTMLEditor) {
      handleRawHTMLBodyChange(sanitizedRichBody);
    }

    handleEditorTabIndexChange(index);
  };

  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const previewDisclosure = useDisclosure({
    onOpen: () => {
      sanitizeHTMLBody();
    },
  });

  const attachmentAdded = (attachment: RichEmailWidgetAttachmentWithS3File) => {
    setIsUploading.off();
    setProgress(undefined);

    const updatedAttachments = [...attachments, attachment];
    setAttachments(updatedAttachments);

    onUpdate({
      widget: {
        ...widget,
        config: {
          ...widget.config,
          attachments: updatedAttachments,
        },
      },
    });
  };

  const sanitizeHTMLBody = React.useCallback(
    () => handleRawHTMLBodyChange(sanitizeEmailBody(rawHTMLBody)),
    [handleRawHTMLBodyChange, rawHTMLBody],
  );
  const debouncedSanitizeHtmlBody = useDebouncedCallback(() => {
    sanitizeHTMLBody();
  }, SANITIZE_BLUR_TIMEOUT);

  useUnmount(() => {
    debouncedSanitizeHtmlBody.cancel();
  });

  const queryClient = useQueryClient();
  React.useEffect(() => {
    const mutationCache = queryClient.getMutationCache();
    return mutationCache.subscribe(
      makeMutationListener({
        keys: [PublishDraftMutation.key],
        onLoading: () => {
          debouncedSanitizeHtmlBody.cancel();
        },
      }),
    );
  }, [debouncedSanitizeHtmlBody, queryClient]);

  const emailFormat = widget.config.emailFormat ?? 'RichTextOrHtml';

  React.useEffect(
    function handleExternalEditorChange() {
      match({ emailFormat, editor: widget.config.editor })
        .with({ emailFormat: EmailFormat.PlainText }, () => {
          setEditorTabIndex(EDITOR_TAB_INDICES.PlainTextEditor);
        })
        .with({ emailFormat: EmailFormat.RichTextOrHtml, editor: EmailEditor.RichEditor }, () => {
          setEditorTabIndex(EDITOR_TAB_INDICES.RichEditor);
        })
        .with({ emailFormat: EmailFormat.RichTextOrHtml, editor: P._ }, () => {
          setEditorTabIndex(EDITOR_TAB_INDICES.RawHTMLEditor);
        })
        .otherwise(() => setEditorTabIndex(EDITOR_TAB_INDICES.RichEditor));
    },
    [emailFormat, widget.config.editor],
  );

  // upload
  const [isUploading, setIsUploading] = useBoolean(false);
  const [progress, setProgress] = React.useState<number | undefined>(undefined);
  const shouldShowAttachmentUpload = emailFormat !== 'PlainText' && !isUploading;

  const handleAiContentGeneration = (config: SendRichEmailFormFieldConfig) => {
    handlePlainTextBodyChange(config.plainTextBody ?? '');
  };

  const getPlainTextSelection = () => {
    const textarea = plainTextBodyRef.current;
    return textarea ? textarea.value.substring(textarea.selectionStart, textarea.selectionEnd) : '';
  };

  const handleCancelTabSwitch = () => {
    handleEditorTabIndexChange(1);
    warningDisclosure.onClose();
  };

  const handleConfirmTabSwitch = () => {
    handleEditorTabIndexChange(0);
    handleRawHTMLBodyChange(richEditorBody);
    warningDisclosure.onClose();
  };

  const isMCEEditorEnabled = useFeatureFlag('sendEmailMCE');

  return (
    <Stack spacing="4" mb="4" {...(isUpdating ? { pointerEvents: 'none' } : {})} overflow="hidden">
      {isUpdating && (
        <Box
          borderRadius="xl"
          position="absolute"
          top="0"
          left="0"
          w="full"
          h="full"
          bg="gray.100"
          opacity="0.5"
          zIndex="overlay"
        >
          <Center w="full" h="full">
            <Spinner size="xl" />
          </Center>
        </Box>
      )}
      <EmailFields
        templateRevisionId={templateRevision.id}
        fieldValues={emailFieldValues}
        onChange={handleEmailFieldValuesChange}
        widget={widget}
      />

      <Field alignItems="flex-start">
        <Label py="2">Body</Label>
        <Stack w="full" spacing="4">
          <Tabs
            {...{
              variant: 'enclosed',
              cursor: 'auto',
              index: editorTabIndex,
              onChange: handleTabChange,
              isLazy: true,
            }}
          >
            {emailFormat === 'RichTextOrHtml' && (
              <TabList borderBottom="none">
                <BodyEditorTab py="2" px="3">
                  <Icon icon="text" variant="far" size="4" />
                  <Text>Rich Text</Text>
                </BodyEditorTab>
                <BodyEditorTab py="2" px="3">
                  <Icon icon="code" variant="far" size="4" />
                  <Text>HTML</Text>
                </BodyEditorTab>
              </TabList>
            )}

            <TabPanels borderStyle="solid" borderColor="gray.300" borderWidth="px" h="2xs" bg="white">
              <TabPanel h="full" p="0" tabIndex={-1}>
                {isMCEEditorEnabled ? (
                  <SendRichEmailTemplateEditor
                    widget={widget}
                    templateRevisionId={templateRevision.id}
                    body={richEditorBody}
                    onUpdate={({ richEditorBody }) => {
                      setRichEditorBody(richEditorBody ?? '');
                      onUpdate({ widget: { ...widget, config: { ...widget.config, richEditorBody } } });
                    }}
                    taskTemplate={taskTemplate}
                  />
                ) : (
                  <SendRichEmailEditorV1
                    mode="Template"
                    id={widget.header.id}
                    widget={widget}
                    templateRevisionId={templateRevision.id}
                    body={richEditorBody}
                    onUpdate={({ richEditorBody }) => {
                      onUpdate({ widget: { ...widget, config: { ...widget.config, richEditorBody } } });
                    }}
                    taskTemplate={taskTemplate}
                  />
                )}
              </TabPanel>

              <TabPanel p="0" h="full">
                <InputGroup h="full">
                  <Textarea
                    h="full"
                    borderRadius="none"
                    border="none"
                    fontFamily="mono"
                    resize="none"
                    ref={rawHTMLBodyRef}
                    placeholder="TODO"
                    value={rawHTMLBody}
                    onChange={e => handleRawHTMLBodyChange(e.target.value)}
                    onBlur={debouncedSanitizeHtmlBody}
                  />
                  <InputRightElement top="-px" transform="translateY(-100%)" bg="transparent" w="8" h="8">
                    <MergeTagsMenu
                      {...{
                        templateRevisionId: templateRevision.id,
                        onSelect: (key, _fieldId, fallback) => insertRawHTMLBody(key, fallback),
                        mergeTagTarget:
                          emailFormat === 'RichTextOrHtml' ? MergeTagTarget.RICH_CONTENT : MergeTagTarget.GENERAL,
                        menuButton: (
                          <MergeTagsMenuButton
                            size="sm"
                            onClick={debouncedSanitizeHtmlBody.cancel}
                            onFocus={debouncedSanitizeHtmlBody.cancel}
                          />
                        ),
                      }}
                    />
                  </InputRightElement>
                </InputGroup>

                <AlertDialog
                  {...{
                    ...warningDisclosure,
                    leastDestructiveRef: cancelRef,
                    // Don't return focus to the clicked tab because it fires onChange again
                    returnFocusOnClose: false,
                  }}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Switch to rich text?
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        <Text fontWeight="bold" as="span" variant="inherit">
                          All content will be discarded
                        </Text>{' '}
                        if you return to the rich text editor.
                      </AlertDialogBody>

                      <AlertDialogFooter>
                        <ButtonGroup>
                          <Button variant="ghost" ref={cancelRef} onClick={handleCancelTabSwitch}>
                            Stay in HTML Editor
                          </Button>

                          <Button variant="danger" onClick={handleConfirmTabSwitch} ml={3}>
                            Switch
                          </Button>
                        </ButtonGroup>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </TabPanel>

              <TabPanel p="0" h="full">
                <InputGroup h="full">
                  <Textarea
                    h="full"
                    borderRadius="none"
                    border="none"
                    resize="none"
                    ref={plainTextBodyRef}
                    placeholder="Body"
                    value={plainTextBody}
                    onChange={e => handlePlainTextBodyChange(e.target.value)}
                  />
                  <InputRightElement top="8" transform="translateY(-100%)" bg="transparent" w="auto" h="8">
                    <MergeTagsMenu
                      {...{
                        templateRevisionId: templateRevision.id,
                        onSelect: (key, _fieldId, fallback) => insertPlainTextBody(key, fallback),
                        mergeTagTarget:
                          emailFormat === 'RichTextOrHtml' ? MergeTagTarget.RICH_CONTENT : MergeTagTarget.GENERAL,
                        menuButton: <MergeTagsMenuButton size="sm" />,
                      }}
                    />
                    <SendRichEmailAiGenerateMenu
                      widget={widget}
                      onContentGeneration={handleAiContentGeneration}
                      getWidgetContent={() => plainTextBody}
                      getWidgetSelection={getPlainTextSelection}
                      taskTemplate={taskTemplate}
                    />
                  </InputRightElement>
                </InputGroup>
              </TabPanel>
            </TabPanels>
          </Tabs>

          {attachments.length > 0 && (
            <VStack alignItems="start">
              {attachments.map(attachment => (
                <EmailAttachmentItem
                  key={attachment.attachment.id}
                  attachment={attachment}
                  onDelete={handleDeleteAttachment}
                />
              ))}
            </VStack>
          )}

          <Box>
            {shouldShowAttachmentUpload && (
              <UploadTemplateEmailAttachment
                {...{
                  templateId: templateRevision.template.id,
                  widgetId: widget.id,
                  setIsUploading,
                  onFinish: attachmentAdded,
                  progress,
                  setProgress,
                  totalSize,
                }}
              />
            )}
            {isUploading && <UploadProgress progress={progress as number} message="Uploading attachment..." />}
          </Box>
          <Divider />
          {emailFormat === 'RichTextOrHtml' && (
            <AutoSendEmailToggle
              widget={widget}
              isEnabled={isAutoSendRichEmailToggleEnabled}
              onWidgetUpdate={onUpdate}
            />
          )}
          <HStack>
            {emailFormat === 'RichTextOrHtml' && (
              <SendTestEmailButton
                widgetHeaderId={widget.header.id}
                onMouseEnter={() => sanitizeHTMLBody()}
                onFocus={() => sanitizeHTMLBody()}
                isDisabled={isSendTestEmailDisabled(widget.config)}
              />
            )}
            {emailFormat === 'PlainText' && (
              <SendTestMailtoButton config={widget.config} isDisabled={isSendTestEmailDisabled(widget.config)} />
            )}

            {match(editorTabIndex)
              .with(EDITOR_TAB_INDICES.RawHTMLEditor, () => (
                <>
                  <Button variant="ghost" fontWeight="normal" onClick={previewDisclosure.onOpen}>
                    Preview
                  </Button>
                  <Modal {...previewDisclosure} size="3xl">
                    <ModalOverlay />
                    <ModalContent>
                      <ModalCloseButton />
                      <ModalBody pt="10" pb="4" maxH="75vh" overflowY="auto">
                        <EmailBodyIframe srcDoc={rawHTMLBody} maxH="none" />
                      </ModalBody>
                    </ModalContent>
                  </Modal>

                  <Spacer />

                  <Link href="https://www.process.st/help/docs/email-widget" color="brand.500" isExternal>
                    Learn more about HTML email »
                  </Link>
                </>
              ))
              .otherwise(() => null)}
          </HStack>
        </Stack>
      </Field>

      <AlertDialog
        {...{
          ...deleteAlertDisclosure,
          leastDestructiveRef: deleteAlertCancelRef,
          // Don't return focus to the clicked tab because it fires onChange again
          returnFocusOnClose: false,
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Confirmation
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text variant="inherit">Are you sure to delete this attachment?</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup>
                <Button variant="ghost" ref={deleteAlertCancelRef} onClick={deleteAlertDisclosure.onClose}>
                  Cancel
                </Button>

                <Button variant="danger" onClick={doDeleteAttachment} ml={3}>
                  Delete
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Stack>
  );
};
