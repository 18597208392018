import * as React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  Icon,
  IconButton,
  useBreakpointValue,
  useDisclosure,
} from 'components/design/next';

export const DataSetDrawer = ({ children }: React.PropsWithChildren<{}>) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const disclosure = useDisclosure({ defaultIsOpen: true });

  const toggleBtn = (
    <IconButton
      display={{ base: 'block', lg: 'none' }}
      zIndex="2"
      position={disclosure.isOpen ? 'absolute' : 'fixed'}
      right={disclosure.isOpen ? '-6' : undefined}
      left={disclosure.isOpen ? undefined : 0}
      top={disclosure.isOpen ? '6' : '22'}
      size="xs"
      aria-label={disclosure.isOpen ? 'Close sidebar' : 'Open sidebar'}
      borderRadius="0"
      bgColor="gray.50"
      borderWidth="thin"
      borderColor="gray.100"
      borderLeftWidth="0"
      borderStyle="solid"
      icon={<Icon icon={disclosure.isOpen ? 'chevron-left' : 'chevron-right'} size="3" color="gray.400" />}
      _hover={{ bgColor: 'gray.100' }}
      onClick={disclosure.onToggle}
    />
  );

  React.useEffect(
    function switchDrawerMode() {
      if (isDesktop) {
        disclosure.onOpen();
      } else {
        disclosure.onClose();
      }
    },
    // We want this to run only when the screen size changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDesktop],
  );

  if (isDesktop) return <>{children}</>;

  return (
    <>
      {!disclosure.isOpen && toggleBtn}

      <Drawer placement="left" variant="aside" trapFocus={false} autoFocus={false} {...disclosure}>
        <DrawerContent maxW="270px" h={`calc(100vh - 64px)`} mt="16">
          <DrawerBody p="0" position="relative" overflow="visible">
            {toggleBtn}

            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
