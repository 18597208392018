import { Muid } from '@process-street/subgrade/core';
import { TaskStats } from '@process-street/subgrade/process';
import { ChecklistRevisionApi } from 'reducers/checklist-revision/checklist-revision.api';
import { ReduxAppState } from 'reducers/types';
import { createCachedAction } from 'reducers/util';
import { Action } from 'redux';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';

export const TASK_STATS_GET_BY_CHECKLIST_REVISION = 'taskStats/GET_BY_CHECKLIST_REVISION';
export const TASK_STATS_UPDATE_INTERNAL = 'taskStats/UPDATE_INTERNAL';
export const TASK_STATS_UPDATE_ALL_INTERNAL = 'taskStats/UPDATE_ALL_INTERNAL';

export interface TaskStatsActions {
  getAllByChecklistRevisionId(
    checklistRevisionId: Muid,
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;
}

export const TaskStatsActionsImpl = (ChecklistRevisionApi: ChecklistRevisionApi) => {
  'ngInject';

  const getAllByChecklistRevisionId = createCachedAction(
    TASK_STATS_GET_BY_CHECKLIST_REVISION,
    ChecklistRevisionApi.getTaskStatsByChecklistRevisionId,
    checklistRevisionId => ({
      checklistRevisionId,
    }),
  );

  const updateInternal = createAction(TASK_STATS_UPDATE_INTERNAL, (taskStats: TaskStats) => ({ taskStats }));
  const updateAllInternal = createAction(TASK_STATS_UPDATE_ALL_INTERNAL, (taskStats: TaskStats[]) => ({ taskStats }));

  return {
    getAllByChecklistRevisionId,
    updateAllInternal,
    updateInternal,
  };
};
