import * as React from 'react';
import { Box, Button, Grid, GridItem, HStack, Text } from 'components/design/next';
import { Icon } from 'components/design/next';
import { DateFormat, dayjs } from '@process-street/subgrade/util';

export type YearPickerProps = {
  year: number;
  onSelectYear?: (date: Date) => void;
  onRangeChange?: (date: Date) => void;
  selected?: Date;
};

const getRange = (year: number) => {
  let start = year;

  while (start % 20 !== 0) {
    start--;
  }

  return Array.from({ length: 20 }, (_, index) => start + index + 1);
};

export const YearPicker: React.FC<React.PropsWithChildren<YearPickerProps>> = ({
  year,
  selected,
  onSelectYear,
  onRangeChange,
}) => {
  const range = getRange(year);
  const selectedYear = selected?.getFullYear();

  const handleNextRangeClick = () => {
    const lastYearOfTheRange = range[range.length - 1] + 1;
    onRangeChange?.(new Date(`${lastYearOfTheRange}-01-01`));
  };

  const handlePreviousRangeClick = () => {
    onRangeChange?.(new Date(`${range[0] - 1}-01-01`));
  };

  return (
    <Box minH="296px">
      <HStack justify="space-between" alignItems="center">
        <Button onClick={handlePreviousRangeClick} p="3" w="10" h="10" variant="ghost" borderRadius="full">
          <Icon icon="chevron-left" />
        </Button>

        <Text fontSize="lg" fontWeight="semibold">
          {range[0]} - {range[range.length - 1]}
        </Text>

        <Button onClick={handleNextRangeClick} p="3" w="10" h="10" variant="ghost" borderRadius="full">
          <Icon icon="chevron-right" />
        </Button>
      </HStack>

      <Grid gridTemplateColumns="repeat(4, 1fr)" gap="1">
        {range.map(currentYear => {
          const date = dayjs().startOf('year').set('year', currentYear);
          const isSelected = selectedYear === currentYear;

          return (
            <GridItem
              key={`year-${currentYear}`}
              onClick={() => onSelectYear?.(date.toDate())}
              as={Button}
              variant="ghost"
              fontWeight={isSelected ? 'semibold' : 'normal'}
              bgColor={isSelected ? 'brand.300' : 'transparent'}
            >
              {date.format(DateFormat.Year)}
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};
