import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { MicrosoftTeamsIdentityProviderLoadingAnimation } from './components/identity-provider-loading-animation';
import { MicrosoftTeamsLoginLoadingState } from './components/login-loading-state';
import { MicrosoftTeamsLoginRedirect } from './components/login-redirect';

export const MicrosoftTeamsModule = angular
  .module('msTeams', [])
  .component('psMsTeamsLoginLoadingState', reactToAngular(MicrosoftTeamsLoginLoadingState, ['children']))
  .component(
    'psMsTeamsIdentityProviderLoadingAnimation',
    reactToAngular(MicrosoftTeamsIdentityProviderLoadingAnimation, ['provider', 'text']),
  )
  .component('psMsTeamsLoginRedirect', reactToAngular(MicrosoftTeamsLoginRedirect)).name;
