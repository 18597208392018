import { useBreakpointPxMap, BreakpointSize } from 'components/design/next';
import takeWhile from 'lodash/takeWhile';

export function useParentWidthValue<Values extends Partial<Record<BreakpointSize, any>>>(
  parentWidth: number,
  values: Values,
): Values[keyof Values] | undefined {
  const breakpointPxMap = useBreakpointPxMap();

  const sizesDesc = Object.entries(breakpointPxMap).sort(([, a], [, b]) => b - a) as [BreakpointSize, number][];
  const parentSize: BreakpointSize = sizesDesc.find(([_size, value]) => parentWidth >= value)?.[0] ?? 'base';
  const trail = takeWhile(Object.keys(breakpointPxMap), key => key !== parentSize).reverse() as BreakpointSize[];

  return values[parentSize] ?? trail.reduce((result, size) => result ?? values[size], undefined) ?? values.base;
}
