import { TemplateRevision } from '@process-street/subgrade/process';
import { axiosService } from 'services/axios-service';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';

export type GetAllTemplateRevisionQueryResponse = TemplateRevision[];

export const GetAllTemplateRevisionsQuery = {
  key: ['template-revisions'],
  getKey: (): QueryKey => [...GetAllTemplateRevisionsQuery.key],
  queryFn: () =>
    axiosService
      .getAxios()
      .get<GetAllTemplateRevisionQueryResponse>(`/1/template-revisions/newest`)
      .then(res => res.data),
};

export const useGetAllTemplateRevisions = (
  options: UseQueryOptions<GetAllTemplateRevisionQueryResponse, AxiosError> = {},
) => {
  return useQuery(GetAllTemplateRevisionsQuery.getKey(), () => GetAllTemplateRevisionsQuery.queryFn(), options);
};
