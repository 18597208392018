import * as React from 'react';
import { Box, Button, Grid, GridItem, HStack, Icon } from 'components/design/next';
import { DateFormat, dayjs } from '@process-street/subgrade/util';
import { CalendarMode } from 'features/conditional-logic/utils/conditional-logic-utils';

export type MonthPickerProps = {
  year: number;
  selected?: Date;
  size: 'sm' | 'md';
  onChange?: (date: Date) => void;
  onModeChange?: (mode: CalendarMode) => void;
  onRangeChange?: (date: Date) => void;
};

export const MonthPicker: React.FC<React.PropsWithChildren<MonthPickerProps>> = ({
  year,
  selected,
  size,
  onChange,
  onModeChange,
  onRangeChange,
}) => {
  const selectedMonth = selected?.getMonth();
  const selectedDay = selected?.getDate();
  const iconSize = size === 'sm' ? '3' : '4';

  const handleNextMonthClick = () => {
    onRangeChange?.(new Date(`${year + 1}-${selectedMonth ? selectedMonth + 1 : 1}-${selectedDay ?? 1}`));
  };

  const handlePreviousMonthClick = () => {
    onRangeChange?.(new Date(`${year - 1}-${selectedMonth ? selectedMonth + 1 : 1}-${selectedDay ?? 1}`));
  };

  const renderMonth = (_: unknown, index: number) => {
    const date = dayjs().set('year', year).set('month', index);
    const monthFormated = date.format(DateFormat.MonthShort);
    const isSelected = selected
      ? dayjs(selected).format(DateFormat.DatePickerInputDefault) === date.format(DateFormat.DatePickerInputDefault)
      : false;

    return (
      <GridItem
        key={monthFormated}
        onClick={() => onChange?.(date.toDate())}
        as={Button}
        variant="ghost"
        fontWeight={isSelected ? 'semibold' : 'normal'}
        bgColor={isSelected ? 'brand.300' : 'transparent'}
        fontSize={size}
      >
        {monthFormated}
      </GridItem>
    );
  };

  return (
    <Box w="full" minH="296px">
      <HStack spacing="0" w="full" justify="space-between" alignItems="center">
        <Button onClick={handlePreviousMonthClick} p="3" w="10" h="10" variant="ghost" borderRadius="full">
          <Icon icon="chevron-left" size={iconSize} />
        </Button>

        <Button onClick={() => onModeChange?.('year')} variant="ghost" fontSize={size} fontWeight="semibold">
          {year}
        </Button>

        <Button onClick={handleNextMonthClick} p="3" w="10" h="10" variant="ghost" borderRadius="full">
          <Icon icon="chevron-right" size={iconSize} />
        </Button>
      </HStack>

      <Grid gridTemplateColumns="repeat(4, 1fr)" gap="1">
        {Array.from({ length: 12 }, renderMonth)}
      </Grid>
    </Box>
  );
};
