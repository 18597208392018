import { InboxItem } from '@process-street/subgrade/inbox';
import { useCallback } from 'react';
import create from 'zustand';
import { combine } from 'zustand/middleware';

type FocusedItemStoreSlice = {
  item: InboxItem | null;
};

const INITIAL_STATE: FocusedItemStoreSlice = {
  item: null,
};

export const useFocusedItemStore = create(
  combine(INITIAL_STATE, set => {
    const setItem = ({ item }: { item: FocusedItemStoreSlice['item'] }) => {
      return set({ item });
    };

    const reset = () => set({ item: null });

    return {
      actions: {
        setItem,
        reset,
      },
    };
  }),
);

export const useFocusedItemActions = () => useFocusedItemStore(useCallback(state => state.actions, []));
