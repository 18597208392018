import { MultiValueGenericProps } from 'react-select';
import React from 'react';
import { HStack } from 'components/design/next';
import { UserOption } from 'utils/user-option-transformer';

export const UserSelectedContainer = (props: MultiValueGenericProps<UserOption, true>) => (
  <HStack marginRight={2} backgroundColor="brand.100" borderRadius="20px" alignSelf="center" spacing={0}>
    {props.children}
  </HStack>
);
