import { useQuery } from 'react-query';
import { Organization } from '@process-street/subgrade/core';
import {
  GetStripeBillingPortalByOrganization,
  GetStripeBillingPortalByOrganizationResult,
} from 'components/billing/stripe-billing-portal-button/query-builder';

export function useStripeBillingPortalSessionQuery({ id: organizationId }: Organization, returnUrl: string) {
  return useQuery<GetStripeBillingPortalByOrganizationResult, Error>(
    GetStripeBillingPortalByOrganization.getKey({ organizationId, returnUrl }),
    () => GetStripeBillingPortalByOrganization.queryFn({ organizationId, returnUrl }),
    {
      enabled: false,
    },
  );
}
