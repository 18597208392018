import { useToast } from 'components/design/next';
import { MutateOptions, useQueryClient } from 'react-query';
import {
  DeleteDataSetMutation,
  GetAllDataSetsQuery,
  UpdateDataSetMutation,
} from 'pages/reports/data-sets/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { DataSet } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';

export function useEditDataSet(dataSetId: string) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const updateDataSetMutation = UpdateDataSetMutation.useMutation({
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems updating the data set",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const deleteDataSetMutation = DeleteDataSetMutation.useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(GetAllDataSetsQuery.key);

      toast({
        status: 'success',
        title: 'Data set deleted',
      });
    },
    onError: () => {
      toast({
        title: "We're having problems deleting the data set",
        description: DefaultErrorMessages.unexpectedErrorDescription,
        status: 'error',
      });
    },
  });

  const handleSubmit = (
    dataSetDelta: Pick<DataSet, 'name' | 'columnDefs'>,
    options?: MutateOptions<DataSet, AxiosError, UpdateDataSetMutation.Params>,
  ) => {
    return updateDataSetMutation.mutate(
      {
        id: dataSetId,
        name: dataSetDelta.name,
        columnDefs: dataSetDelta.columnDefs,
      },
      {
        ...options,

        onSuccess: async (data, variables, context) => {
          options?.onSuccess?.(data, variables, context);
          await queryClient.invalidateQueries(GetAllDataSetsQuery.key);
          toast({
            status: 'success',
            title: 'Data set updated',
          });
        },
      },
    );
  };

  const handleDelete = (options?: MutateOptions<void, AxiosError, DeleteDataSetMutation.Params>) =>
    deleteDataSetMutation.mutate(
      {
        dataSetId,
      },
      options,
    );

  return {
    handleSubmit,
    handleDelete,
    updateDataSetMutation,
    deleteDataSetMutation,
  };
}
