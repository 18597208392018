import { handleActions } from '@process-street/subgrade/redux/util';
import { StringUtils } from '@process-street/subgrade/util';
import { FeatureFlagAction, SetFeatureFlagsAction } from 'services/features/feature-flags/store/feature-flags.actions';
import { FeatureFlagKey, initialFeatureFlagState } from '../feature-flag-constants';

export const featureFlagReducer = handleActions(
  {
    [FeatureFlagAction.setFlags.type]: (draft, action: SetFeatureFlagsAction) => {
      Object.keys(FeatureFlagKey).forEach(key => {
        const uncapitalized = StringUtils.uncapitalize(key as keyof typeof FeatureFlagKey);
        draft[uncapitalized] = action.payload[uncapitalized];
      });
      draft.isInitialized = action.payload.isInitialized ?? draft.isInitialized;
    },
  },
  initialFeatureFlagState,
);

export const featureFlagsStatusReducer = handleActions(
  {
    [FeatureFlagAction.setFlags.type]: draft => {
      Object.assign(draft, {
        error: false,
        loading: false,
        loaded: true,
      });
    },
  },
  { error: false, loading: true, loaded: false },
);

export { initialFeatureFlagState };
