import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { WorkflowAutomationsTemplateWrapper } from './wrapper';

export const TemplateWorkflowAutomationsModule = angular
  .module('TemplateWorkflowAutomationsModule', [])
  .component(
    'psTemplateWorkflowAutomations',
    reactToAngular(WorkflowAutomationsTemplateWrapper, ['editable', 'templateRevision', 'solutionTypeTag']),
  ).name;
