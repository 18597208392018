import * as React from 'react';
import { FormLabel, Radio, RadioGroup, Text, VStack } from 'components/design/next';
import { useField } from 'formik';
import { TextareaFormFieldConfig } from '@process-street/subgrade/process';

export type RichPlainFormatProps = {
  fieldName: string;
};

export const TextFormatSelector: React.FC<RichPlainFormatProps> = ({ fieldName }) => {
  const [field, , helpers] = useField<TextareaFormFieldConfig['format']>(fieldName);
  const { setValue } = helpers;
  const emailFormat = field.value ?? 'PlainText';

  const handleOnChange = (value: string) => {
    setValue(value as TextareaFormFieldConfig['format']);
  };

  return (
    <VStack alignItems="flex-start" spacing="1">
      <FormLabel>Formatting</FormLabel>

      <RadioGroup onChange={handleOnChange} value={emailFormat}>
        <VStack alignItems="flex-start">
          <Radio value="RichText" alignItems="flex-start">
            <Text color="gray.600" variant="1" position="relative" top="-2px">
              Rich text
            </Text>
            <Text color="gray.500" variant="-1">
              Format your text with bold, italics, headings, and more for better readability and emphasis.
            </Text>
          </Radio>

          <Radio value="PlainText" alignItems="flex-start">
            <Text color="gray.600" variant="1" position="relative" top="-2px">
              Plain text
            </Text>
            <Text color="gray.500" variant="-1">
              Keeps your text simple with no formatting, just the characters as you type them.
            </Text>
          </Radio>
        </VStack>
      </RadioGroup>
    </VStack>
  );
};
