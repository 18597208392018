export const RoleAssignmentRulesApi = function (RoleAssignmentRules) {
  'ngInject';

  const getAllByTemplateRevisionId = templateRevisionId =>
    RoleAssignmentRules.getAllByTemplateRevisionId({ templateRevisionId }).$promise;

  const create = (templateRevisionId, rule) => RoleAssignmentRules.create({ ...rule, templateRevisionId }).$promise;

  const createAll = (templateRevisionId, rules) =>
    RoleAssignmentRules.createAll({ templateRevisionId, rules }).$promise;

  const deleteRule = (templateRevisionId, ruleId) =>
    RoleAssignmentRules.delete({ templateRevisionId, ruleId }).$promise;

  const deleteAll = (templateRevisionId, ruleIds) =>
    RoleAssignmentRules.deleteAll({ templateRevisionId, ruleIds }).$promise;

  return {
    getAllByTemplateRevisionId,
    create,
    createAll,
    deleteRule,
    deleteAll,
  };
};
