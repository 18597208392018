import { GroupBase, Options } from 'react-select';

const isOptionsType = <Option>(x: any): x is Options<Option> => {
  return Array.isArray(x);
};

const isOptionType = <OptionType>(x: any): x is OptionType => {
  return !isOptionsType(x) && x !== null && x !== undefined;
};

const coalesceToArray = <T>(value: undefined | T | T[]): T[] => {
  if (Array.isArray(value)) {
    return value;
  } else if (value !== undefined) {
    return [value];
  } else {
    return [];
  }
};

const getOptionData = <Option>(data: unknown): Option | null | undefined => {
  if (isOptionType<Option>(data)) {
    return data;
  }
  return undefined;
};

const isGroupOptionsType = <Option, Group extends GroupBase<Option> = GroupBase<Option>>(
  x: any,
): x is ReadonlyArray<Group> => {
  return Array.isArray(x) && x.length > 0 && x.every(group => isOptionsType(group?.options));
};

const valuesCount = <Option>(values?: Option | Options<Option>) => {
  if (Array.isArray(values)) {
    return values.length;
  } else if (values !== undefined) {
    return 1;
  } else {
    return 0;
  }
};

const getGroupOptionsOrOption = <Option>(optionOrGroup: Option | GroupBase<Option>): readonly Option[] => {
  return optionOrGroup && typeof optionOrGroup === 'object' && 'options' in optionOrGroup
    ? optionOrGroup.options
    : [optionOrGroup];
};

export const BlvdSelectHelpers = {
  isOptionType,
  isOptionsType,
  coalesceToArray,
  valuesCount,
  castOptionData: getOptionData,
  getGroupOptionsOrOption,
  isGroupOptionsType,
};
