import angular from 'angular';
import { reactToAngular } from 'angulareact';
import { withErrorBoundary } from 'components/error-boundary';
import { WorkflowTableFormFieldWidget } from 'pages/workflows/_id/edit/components/form-fields/table-form-field-widget';

export const WorkflowsEditPageModule = angular
  .module('workflowsEditPage', [])
  .component(
    'psWorkflowTableFormFieldWidget',
    reactToAngular(withErrorBoundary(WorkflowTableFormFieldWidget), ['isEditable', 'widget', 'template']),
  ).name;
