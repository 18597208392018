import * as React from 'react';
import { AvatarGroup, HStack, Icon, Tooltip } from 'components/design/next';
import { InboxItemType } from '@process-street/subgrade/inbox';
import { useReassignModalStore } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/ChecklistDashboardGridReassignModalStore';
import sortBy from 'lodash/sortBy';
import { ChakraAvatar } from 'components/design/next/chakra-avatar';
import { TasksTableUtils } from '../tasks-table/tasks-table-utils';
import { useIsCellHovered } from '../../use-inbox-items-grid-context-store';

export type AssigneeRendererProps = {
  data: TasksTableUtils.TasksTableItem;
};

export const AssigneeRenderer = ({ data }: AssigneeRendererProps) => {
  const reassignModalStore = useReassignModalStore();
  const isHovered = useIsCellHovered(data, 'assignees');
  const isCompleted = TasksTableUtils.isInboxItemCompleted(data);

  const sortedAssignees = React.useMemo(() => {
    if (!TasksTableUtils.isInboxItemRow(data)) return [];

    return sortBy(data.assignees, 'username');
  }, [data]);

  if (!TasksTableUtils.isInboxItemRow(data)) return null;

  const taskId = data.itemType === InboxItemType.Checklist ? undefined : data.task.id;
  const handleOpenModal = () => {
    if (isCompleted) return;

    reassignModalStore.onOpen({
      checklistId: data.checklist.id,
      taskId,
    });
  };

  return (
    <Tooltip
      isDisabled={isCompleted}
      label={taskId ? 'Reassign Task' : 'Reassign Workflow Run'}
      hasArrow
      display="flex"
    >
      <HStack
        px={2}
        w="full"
        h="full"
        alignItems="center"
        onClick={handleOpenModal}
        _hover={{
          opacity: isHovered ? '0.5' : '1',
          cursor: isHovered && !isCompleted ? 'pointer' : 'auto',
        }}
      >
        <AvatarGroup aria-label="Item assignees" size="xs" max={3} spacing={-2}>
          {sortedAssignees.map((user, index) => (
            <ChakraAvatar user={user} key={index}></ChakraAvatar>
          ))}
        </AvatarGroup>
        {!isCompleted && (
          <Icon ml={1.5} variant="far" icon="edit" size="4" visibility={isHovered ? 'visible' : 'hidden'} />
        )}
      </HStack>
    </Tooltip>
  );
};
