import { FieldType, TemplateType, WidgetType } from '@process-street/subgrade/process';
import React from 'react';
import { TaskMachine } from 'pages/responses/_id/components/task/task-machine';
import { TextFormField } from 'pages/responses/_id/components/form-fields/text-form-field';
import { useSelector } from '@xstate/react';
import { ActorRefFrom } from 'xstate';
import { match, P } from 'ts-pattern';
import { TextareaFormField } from '../form-fields/textarea-form-field';
import { EmailFormField } from '../form-fields/email-form-field';
import { UrlFormField } from '../form-fields/url-form-field';
import { SelectFormField } from '../form-fields/select-form-field';
import { FormFieldWidgetActorLookup as ActorLookup } from '../form-fields/form-field-machine';
import { FileFormField } from '../form-fields/file-form-field';
import { ImageContentWidget } from '../content/image-content-widget/image-content-widget';
import { TextContentWidgetMachineActorRef } from '../content/text-content-widget/text-content-widget-machine';
import { ImageContentWidgetMachineActorRef } from '../content/image-content-widget/image-content-widget-machine';
import { FileContentWidget } from '../content/file-content-widget';
import { FileContentWidgetMachineActorRef } from '../content/file-content-widget/file-content-widget-machine';
import { DateFormField } from '../form-fields/date-form-field';
import { NumberFormField } from '../form-fields/number-form-field';
import { TableFormField } from '../form-fields/table-form-field';
import { TaskMachineSelectors } from './task-machine-selectors';

const TextContentResponseWidget = React.lazy(() =>
  import('pages/responses/_id/components/content/text-content-widget').then(({ TextContentWidget }) => ({
    default: TextContentWidget,
  })),
);

export interface TaskProps {
  taskMachine: ActorRefFrom<TaskMachine>;
}

export const Task: React.FC<React.PropsWithChildren<TaskProps>> = ({ taskMachine }) => {
  const widgetActors = useSelector(taskMachine, TaskMachineSelectors.getWidgetActors);

  return (
    <React.Suspense fallback="">
      {widgetActors.map(widgetActor => {
        const widget = widgetActor.getSnapshot()?.context.widget;
        if (!widget) return null;

        return match({ widget, actor: widgetActor })
          .with({ widget: { fieldType: FieldType.Text }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
            <TextFormField key={widget.id} actor={actor as ActorLookup<typeof w>} />
          ))
          .with({ widget: { fieldType: FieldType.Textarea }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
            <TextareaFormField key={widget.id} actor={actor as ActorLookup<typeof w>} />
          ))
          .with({ widget: { fieldType: FieldType.Email }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
            <EmailFormField key={widget.id} actor={actor as ActorLookup<typeof w>} />
          ))
          .with({ widget: { fieldType: FieldType.Url }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
            <UrlFormField key={widget.id} actor={actor as ActorLookup<typeof w>} />
          ))
          .with(
            { widget: { fieldType: P.union(FieldType.Select, FieldType.MultiChoice) }, actor: P.not(undefined) },
            ({ widget: w, actor }) => <SelectFormField key={widget.id} actor={actor as ActorLookup<typeof w>} />,
          )
          .with({ widget: { fieldType: FieldType.File }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
            <FileFormField key={w.id} actor={actor as ActorLookup<typeof w>} />
          ))
          .with({ widget: { fieldType: FieldType.Date }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
            <DateFormField key={w.id} actor={actor as ActorLookup<typeof w>} />
          ))
          .with({ widget: { fieldType: FieldType.Number }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
            <NumberFormField key={w.id} actor={actor as ActorLookup<typeof w>} />
          ))
          .with({ widget: { fieldType: FieldType.Table }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
            <TableFormField key={w.id} actor={actor as ActorLookup<typeof w>} templateType={TemplateType.Form} />
          ))
          .with({ widget: { header: { type: WidgetType.Text } } }, ({ widget: w, actor }) => (
            <TextContentResponseWidget key={w.id} actor={actor as TextContentWidgetMachineActorRef} />
          ))
          .with({ widget: { header: { type: WidgetType.Image } } }, ({ widget: w, actor }) => (
            <ImageContentWidget key={w.id} actor={actor as ImageContentWidgetMachineActorRef} />
          ))
          .with({ widget: { header: { type: WidgetType.File } } }, ({ widget: w, actor }) => (
            <FileContentWidget key={w.id} actor={actor as FileContentWidgetMachineActorRef} />
          ))
          .otherwise(() => null);
      })}
    </React.Suspense>
  );
};
