import * as React from 'react';
import { Input } from 'components/design/next';
import { useDebounce } from 'react-use';

export type RuleFilterSearchProps = { doSearch: (text: string) => void };

export const RuleFilterSearch: React.FC<React.PropsWithChildren<RuleFilterSearchProps>> = ({ doSearch }) => {
  const [value, setValue] = React.useState('');

  useDebounce(
    () => {
      doSearch(value);
    },
    200,
    [value],
  );

  return (
    <Input
      placeholder="Search"
      bgColor="white"
      borderColor="gray.300"
      type="search"
      value={value}
      onChange={evt => setValue(evt.target.value)}
    />
  );
};
