import * as React from 'react';
import { SolutionTypeTag } from '@process-street/subgrade/automation';
import { useGetActiveAutomationsCount } from '../../hooks/use-get-active-automations-count';
import { Box, Flex, Divider } from 'components/design/next';
import { match } from 'ts-pattern';
import { ActiveOutgoingList } from '../common/active-outgoing-list';
import { useWorkflowAutomationsContext } from '../common/context';

const SelectedTemplateTriggers = React.lazy(() =>
  import('pages/templates/_id/components/selected-template-triggers').then(({ SelectedTemplateTriggers }) => ({
    default: SelectedTemplateTriggers,
  })),
);

export const ChecklistWorkflowAutomations: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { templateRevision, solutionTypeTag } = useWorkflowAutomationsContext();
  const { outgoingCount } = useGetActiveAutomationsCount({ templateId: templateRevision?.template.id });

  return (
    <React.Suspense fallback="">
      {match({ solutionTypeTag })
        .with({ solutionTypeTag: SolutionTypeTag.CreateChecklistWhen }, () => <SelectedTemplateTriggers />)
        .with({ solutionTypeTag: SolutionTypeTag.WhenChecklistCompletedThen }, () => {
          return outgoingCount ? (
            <Flex w="full" alignItems="center" flexDirection="column" mt="-5" mb="7">
              <Box height="6">
                <Divider orientation="vertical" borderStyle="solid" borderColor="gray.300" />
              </Box>
              <ActiveOutgoingList {...{ templateId: templateRevision.template.id, isDisabled: true }} />
            </Flex>
          ) : null;
        })
        .otherwise(() => null)}
    </React.Suspense>
  );
};
