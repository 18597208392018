import { useFunctionRef } from 'hooks/use-function-ref';
import * as React from 'react';

export const COMMA = ', ';

export function useEmailField({
  initialValue,
  onChange: onChangeProp,
}: {
  initialValue?: string[];
  onChange: (emails: string[]) => void;
}) {
  const onChangeRef = useFunctionRef(onChangeProp);
  const [value, setValue] = React.useState(initialValue?.join(COMMA) ?? '');

  const onBlur = React.useCallback<React.FocusEventHandler>(() => {
    setValue(value);
  }, [value]);

  const onChange = React.useCallback(
    (value: string) => {
      setValue(value);
      onChangeRef.current([value]);
    },
    [onChangeRef],
  );

  return React.useMemo(() => ({ onBlur, onChange, value, setValue }), [onBlur, onChange, value]);
}
