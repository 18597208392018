export const ObjectType = {
  ATTACHMENT: 'Attachment',
  CHECKLIST_ASSIGNMENT: 'ChecklistAssignment',
  CHECKLIST_REVISION: 'ChecklistRevision',
  CHECKLIST: 'Checklist',
  COMMENT: 'Comment',
  FOLDER: 'Folder',
  FORM_FIELD_VALUE: 'FormFieldValue',
  GROUP: 'Group',
  GROUP_MEMBERSHIP: 'GroupMembership',
  ORGANIZATION_MEMBERSHIP: 'OrganizationMembership',
  TASKS: 'Tasks',
  TASK: 'Task',
  TAG: 'Tag',
  TAG_MEMBERSHIP: 'TagMembership',
  TEAM: 'Team',
  TEMPLATE_PERMIT: 'TemplatePermit',
  TEMPLATE_REVISION: 'TemplateRevision',
  TEMPLATE: 'Template',
  USER: 'User',
  CHECKLIST_TASK_ASSIGNMENT: 'ChecklistTaskAssignment',
  MANY_CHECKLIST_TASK_ASSIGNMENTS: 'ManyChecklistTaskAssignments',
  MASKED: 'Masked',
};
