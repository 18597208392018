import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';
import { Widget } from '@process-street/subgrade/process';
import { TaskTemplatePrintAttributes } from './components/print-template-rules';
import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';

type Context = {
  widgetGroupIds: Map<string, WidgetAttributes>;
  taskGroupIds: Map<string, TaskTemplatePrintAttributes>;
  organizationMemberships: Map<string, OrganizationMembershipWithUser>;
};

export interface WidgetAttributes {
  label: string;
  icon: string;
  ruleAppliesToWidget?: boolean;
  widget: Widget;
}

export const [usePrintRulesContext, PrintRulesContext] = createUsableContext<Context>({
  hookName: 'usePrintRulesContext',
  providerName: 'PrintRulesContext',
});

export const PrintRulesContextProvider: React.FunctionComponent<React.PropsWithChildren<Context>> = ({
  children,
  widgetGroupIds,
  taskGroupIds,
  organizationMemberships,
}) => {
  const value = React.useMemo(
    () => ({ widgetGroupIds, taskGroupIds, organizationMemberships }),
    [widgetGroupIds, taskGroupIds, organizationMemberships],
  );
  return <PrintRulesContext.Provider value={value}>{children}</PrintRulesContext.Provider>;
};
