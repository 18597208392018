import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { SwitchOrganizationComponent } from './switch-organization.component';

export const SwitchOrganizationModule = angular
  .module('switchOrganization', [uiRouter])
  .component('psSwitchOrganization', SwitchOrganizationComponent)
  .config($stateProvider => {
    'ngInject';

    $stateProvider.state('switchOrganization', {
      parent: 'appLight',
      url: '/switch-organization',
      component: 'psSwitchOrganization',
      data: {
        allowCanceled: true,
        internal: true,
        skipLoader: true,
      },
      params: {
        organizationId: null,
        current: null,
      },
    });
  }).name;
