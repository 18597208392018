import * as React from 'react';
import { Text, HStack, Button, Tooltip, List, ListItem } from 'components/design/next';

import {
  SolutionTypeTag,
  isTemplateSolutionInstance,
  isIncomingWebhookInstance,
  AutomationInstance,
  AutomationInstanceUtils,
  getPrefixedAutomationAppName,
} from '@process-street/subgrade/automation';
import { AutomationAppIcon } from 'features/automations/components/automation-app-icon';

import { useSolutionTypeTagInstances } from 'pages/templates/_id/automation/components/selector/use-solution-type-tag-instances';
import { match } from 'ts-pattern';
import { MAX_SOLUTION_INSTANCES_VISIBLE } from '../../constants';
import { useWorkflowAutomationsContext } from './context';
import { useTemplateId } from 'pages/templates/_id/automation/utils/use-template-id';
import { Muid } from '@process-street/subgrade/core';

type TemplateSolutionInstanceListProps = {
  solutionTypeTag: SolutionTypeTag;
  isDisabled?: boolean;
  templateId?: Muid;
};
export const TemplateSolutionInstancesList: React.FC<React.PropsWithChildren<TemplateSolutionInstanceListProps>> = ({
  solutionTypeTag,
  isDisabled = false,
  templateId: templateIdProp,
}) => {
  const templateId = useTemplateId() ?? templateIdProp;

  const { instances } = useSolutionTypeTagInstances({ templateId });

  const configuredInstances = (instances[solutionTypeTag] as AutomationInstance[])
    .filter(i =>
      match(i)
        .when(isTemplateSolutionInstance, i => i.configured)
        .when(isIncomingWebhookInstance, i => i.status !== 'Deleted')
        .otherwise(() => false),
    )
    .sort((a, b) => instanceToComarableNum(b) - instanceToComarableNum(a));

  return (
    <>
      {configuredInstances.length
        ? configuredInstances.slice(0, MAX_SOLUTION_INSTANCES_VISIBLE).map((automationInstance, index) => (
            <SolutionInstanceItem
              {...{
                key: AutomationInstanceUtils.getInstanceId(automationInstance),
                automationInstance,
                isLast: index === MAX_SOLUTION_INSTANCES_VISIBLE - 1,
                notVisibleCount: configuredInstances.length - MAX_SOLUTION_INSTANCES_VISIBLE,
                isDisabled,
              }}
            />
          ))
        : null}
    </>
  );
};

type SolutionInstanceItemProps = {
  automationInstance: AutomationInstance;
  isLast: boolean;
  notVisibleCount: number;
  isDisabled?: boolean;
};

const SolutionInstanceItem: React.FC<React.PropsWithChildren<SolutionInstanceItemProps>> = ({
  automationInstance,
  isLast,
  notVisibleCount,
  isDisabled,
}) => {
  const { editable, openAutomationInstance, openSolutionTypeTag } = useWorkflowAutomationsContext();
  const automationInstanceId = AutomationInstanceUtils.getInstanceId(automationInstance);
  const isEnabled = AutomationInstanceUtils.isEnabled(automationInstance);
  const solutionTypeTag = AutomationInstanceUtils.getSolutionTypeTag(automationInstance);
  const label = AutomationInstanceUtils.getLabel(automationInstance);
  const automationType = AutomationInstanceUtils.getAutomationInstanceType(automationInstance);
  const disableAutomationButton = !editable || isDisabled || !isEnabled;
  return (
    <HStack
      spacing="4"
      role="button"
      flex="1"
      minH="12"
      borderWidth="px"
      borderStyle="solid"
      borderColor="gray.100"
      borderTop="none"
      pl="6"
      pr="5"
      m="0"
      justifyContent="space-between"
      _last={{
        borderBottomRadius: 'lg',
      }}
    >
      <HStack
        spacing="4"
        cursor="cursor"
        {...(!disableAutomationButton && {
          cursor: 'pointer',
          onClick: () => {
            openAutomationInstance({ id: automationInstanceId ?? '', automationType });
          },
        })}
      >
        <AutomationAppIcon
          w="5"
          opacity={isEnabled ? undefined : 0.5}
          {...{ automationApp: getPrefixedAutomationAppName(automationInstance) }}
        />
        {match(solutionTypeTag)
          .with(SolutionTypeTag.CreateChecklistWhen, () => (
            <Text
              {...(!isEnabled && {
                textDecorationLine: 'line-through',
                color: 'gray.400',
              })}
            >
              {match(automationInstance)
                .when(isTemplateSolutionInstance, () => `When ${label}`)
                .when(isIncomingWebhookInstance, () => label)
                .otherwise(() => null)}
            </Text>
          ))
          .with(SolutionTypeTag.WhenChecklistCompletedThen, () => (
            <Text
              {...(!isEnabled && {
                textDecorationLine: 'line-through',
                color: 'gray.400',
              })}
            >
              {label}
            </Text>
          ))
          .otherwise(() => null)}
      </HStack>

      {isLast &&
        notVisibleCount &&
        (isDisabled && solutionTypeTag ? (
          <HiddenItems {...{ solutionTypeTag }} />
        ) : (
          <Button
            variant="unstyled"
            onClick={() => {
              if (solutionTypeTag) {
                openSolutionTypeTag(solutionTypeTag);
              }
            }}
          >
            +{notVisibleCount}
          </Button>
        ))}
    </HStack>
  );
};

type HiddenItemsProps = {
  solutionTypeTag: SolutionTypeTag;
};

const HiddenItems: React.FC<React.PropsWithChildren<HiddenItemsProps>> = ({ solutionTypeTag }) => {
  const { templateRevision } = useWorkflowAutomationsContext();

  const templateId = templateRevision?.template?.id;
  const { instances } = useSolutionTypeTagInstances({ templateId });

  const enabledInstances = instances[solutionTypeTag].filter(i => isTemplateSolutionInstance(i) && i.configured);

  const notVisibleCount = enabledInstances.length - MAX_SOLUTION_INSTANCES_VISIBLE;

  return (
    <Tooltip
      aria-label="Rest of automations"
      label={
        <List spacing="2" mb="0">
          {enabledInstances.slice(-notVisibleCount).map(instance =>
            match(solutionTypeTag)
              .with(SolutionTypeTag.CreateChecklistWhen, () => (
                <ListItem key={AutomationInstanceUtils.getInstanceId(instance)} as={HStack}>
                  <AutomationAppIcon w="5" {...{ automationApp: getPrefixedAutomationAppName(instance) }} />
                  <Text>
                    When{' '}
                    {match(instance)
                      .when(isTemplateSolutionInstance, ({ description }) => description)
                      .when(isIncomingWebhookInstance, ({ name }) => name)
                      .otherwise(() => '')}
                  </Text>{' '}
                </ListItem>
              ))
              .with(SolutionTypeTag.WhenChecklistCompletedThen, () => (
                <ListItem key={AutomationInstanceUtils.getInstanceId(instance)} as={HStack}>
                  <AutomationAppIcon w="5" {...{ automationApp: getPrefixedAutomationAppName(instance) }} />
                  <Text>
                    ...{' '}
                    {match(instance)
                      .when(isTemplateSolutionInstance, ({ description }) => description)
                      .when(isIncomingWebhookInstance, ({ name }) => name)
                      .otherwise(() => '')}
                  </Text>{' '}
                </ListItem>
              ))
              .otherwise(() => null),
          )}
        </List>
      }
      hasArrow
      placement="bottom"
      shouldWrapChildren
    >
      <Text cursor="default">+{notVisibleCount}</Text>
    </Tooltip>
  );
};

function instanceToComarableNum(automationInstance: AutomationInstance): number {
  return match(automationInstance)
    .when(isTemplateSolutionInstance, i => Number(i.enabled))
    .when(isIncomingWebhookInstance, i => (i.status === 'Active' ? 1 : 0))
    .otherwise(() => 0);
}
