import * as React from 'react';

import { HStack, Icon, Link, Text, VStack } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { Breadcrumbs } from 'components/breadcrumbs';

import { useIsEditable } from 'features/rich-text';

import { usePageNameEditor } from 'pages/pages/_id/edit/page/components/page-name/use-page-name-editor';
import { useGetFocusBarColor } from './use-get-focus-bar-color';
import { EditButtonGroup, ViewButtonGroup } from './right-button-group';
import { useGetCurrentUserQuery } from 'features/user/query-builder';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';

export const PAGES_FOCUS_BAR_HEIGHT = 18;

export const FocusBarUI: React.FC<React.PropsWithChildren<unknown>> = () => {
  const editable = useIsEditable();
  const { $state } = useInjector('$state');
  const {
    params: { id: templateId },
  } = $state;

  const { isInvalid: nameInvalid, value: title } = usePageNameEditor({ autoFocus: true });

  const getFocusBarColor = useGetFocusBarColor();

  const currentUserQuery = useGetCurrentUserQuery();
  const isAnonymous = currentUserQuery.data && isAnonymousUser(currentUserQuery.data);

  return (
    <HStack
      data-hide-on-print
      h="18"
      w="full"
      py="2"
      px={['4', '4', '6']}
      borderBottom={editable ? 'none' : '2px solid'}
      borderBottomColor={getFocusBarColor({ light: 'gray.200', dark: 'transparent' })}
      position="fixed"
      background={getFocusBarColor({ light: 'gray.50', dark: 'gray.700' })}
      zIndex="docked"
      justify="space-between"
    >
      <VStack minWidth="0" alignItems="flex-start" flex="1">
        <HStack color={getFocusBarColor({ light: 'gray.600', dark: 'white' })} spacing="2" maxW="90%">
          <Text fontWeight="bold">{editable ? 'Editing' : 'Viewing'}</Text>
          <Icon icon="file-alt" variant="fas" size="3" color="teal.500" />
          <Link href={$state.href('pageView', { id: templateId })} fontWeight="medium" noOfLines={1} minW="0">
            {title}
          </Link>
        </HStack>

        {!isAnonymous && <Breadcrumbs getColor={getFocusBarColor} templateId={templateId} />}
      </VStack>

      {editable ? <EditButtonGroup nameInvalid={nameInvalid} /> : <ViewButtonGroup templateId={templateId} />}
    </HStack>
  );
};

export const FocusBar = React.memo(FocusBarUI);
