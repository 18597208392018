import * as React from 'react';
import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList } from 'components/design/next';

export type AuthorActionsMenuProps = {
  onEdit: () => void;
  onDelete: () => void;
};

export const AuthorActionsMenu: React.FC<React.PropsWithChildren<AuthorActionsMenuProps>> = ({ onEdit, onDelete }) => {
  return (
    <Menu isLazy placement="bottom-end">
      <MenuButton
        aria-label="comment options"
        as={IconButton}
        size="sm"
        icon={<Icon size="4" variant="far" icon="ellipsis-h" />}
        variant="outline"
        colorScheme="gray"
        borderWidth="px"
        _active={{ bg: 'white', borderColor: 'gray.500' }}
        _hover={{ bg: 'white', borderColor: 'gray.500' }}
      />
      <MenuList>
        <MenuItem onClick={onEdit} icon={<Icon variant="far" icon="edit" size="4" />}>
          Edit
        </MenuItem>

        <MenuItem
          onClick={onDelete}
          color="red.500"
          icon={<Icon variant="far" icon="trash" size="4" color="red.500" />}
        >
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
