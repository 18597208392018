import { createPagesPluginFactory } from '../pages-plate-types';

export const ELEMENT_PS_FRAGMENT = 'fragment';

/**
 * Required for backwards compatability with text elements created with TinyMCE which can
 * have two root elements in the HTML for a text widget.
 */
export const createPSFragment = createPagesPluginFactory({
  key: ELEMENT_PS_FRAGMENT,
  isElement: true,
});
