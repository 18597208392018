import * as React from 'react';
import { FileWidget, ImageWidget } from '@process-street/subgrade/process/widget-model';
import { PagesRenderElementProps, PagesWidgetElement, usePagesEditorRef } from '../../pages-plate-types';
import { DownloadElement } from './components/download';
import { PSEditor } from 'pages/pages/_id/edit/page/utils/ps-editor';
import { UploadElement } from 'pages/pages/_id/edit/page/components/upload';
import { Box, Icon } from 'components/design/next';
import { EditorVoid } from '../elements/void';
import { useIsEditable } from 'features/rich-text';

export const FileElement: React.FC<
  React.PropsWithChildren<PagesRenderElementProps<PagesWidgetElement<FileWidget>>>
> = ({ attributes, element, children }) => {
  const { widget } = element;
  const editor = usePagesEditorRef();
  const editable = useIsEditable();

  const handleOnUploadFinish = React.useCallback(
    (updatedWidget: FileWidget | ImageWidget) => {
      // Mutation Slate state through node's widget change
      PSEditor.setWidgetElement(editor, updatedWidget);
    },
    [editor],
  );

  return (
    <EditorVoid attributes={attributes} contentEditable={false}>
      {editable && widget.file === undefined && (
        <UploadElement widget={widget} onFinish={handleOnUploadFinish} uploadingMessage="Uploading file...">
          <Icon icon="paperclip" size="4" color="gray.600" mr="2" />
          Upload File Attachment
        </UploadElement>
      )}
      {widget.file !== undefined && (
        <Box bg="gray.100" p={4} contentEditable={false} borderRadius="md">
          <DownloadElement {...widget.file} />
        </Box>
      )}
      {children}
    </EditorVoid>
  );
};
