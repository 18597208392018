import * as React from 'react';
import { Text, HStack, Icon, Tooltip } from 'components/design/next';
import { OptionProps, components } from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { ChecklistOrFormFieldOrColumnDefinitionOption as TChecklistOrFormFieldOption } from '../utils';
import { match } from 'ts-pattern';
import { toEntityMap } from '@process-street/subgrade/redux';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';

export const ChecklistOrFormFieldOption: React.FC<
  React.PropsWithChildren<OptionProps<TChecklistOrFormFieldOption, false>>
> = props => {
  const value = BlvdSelectHelpers.castOptionData<TChecklistOrFormFieldOption>(props.data);
  const templateRevisionId = match(value)
    .with({ type: 'formField' }, option => option.templateRevisionId)
    .otherwise(() => undefined);

  const tasksQuery = useTaskTemplatesByTemplateRevisionIdQuery(
    { templateRevisionId: templateRevisionId ?? '' },
    {
      enabled: Boolean(templateRevisionId),
      select: toEntityMap,
    },
  );

  if (!value) return null;

  return (
    <div className={'blvd-select__option__wrapper'}>
      <components.Option {...props}>
        {match(value)
          .with({ type: 'formField' }, option => (
            <Tooltip
              closeDelay={0}
              openDelay={0}
              label={<Text variant="-2">Task: {tasksQuery.data?.[option.taskTemplateId]?.name}</Text>}
              hasArrow
            >
              <HStack justifyContent="space-between">
                <Text as="span" lineHeight="1">
                  {value.label}
                </Text>
                {props.isSelected ? <Icon icon="check" size="4" variant="far" /> : null}
              </HStack>
            </Tooltip>
          ))
          .otherwise(() => (
            <HStack justifyContent="space-between">
              <Text as="span" lineHeight="1">
                {value.label}
              </Text>
              {props.isSelected ? <Icon icon="check" size="4" variant="far" /> : null}
            </HStack>
          ))}
      </components.Option>
    </div>
  );
};
