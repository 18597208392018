import * as React from 'react';
import { Checkbox, FormControl, FormErrorMessage, Text } from 'components/design/next';
import { useField } from 'formik';
import { BlvdDatePicker, BlvdDatePickerProps, formatMMDDYYYY } from 'components/design/BlvdDatePicker';

type DateCondition = 'after' | 'before';

type DateSelectorProps = {
  condition: DateCondition;
  dayPickerProps?: BlvdDatePickerProps['dayPickerProps'];
};

export const DateSelector: React.FC<React.PropsWithChildren<DateSelectorProps>> = ({ condition, dayPickerProps }) => {
  const constraintField = condition === 'after' ? 'constraints.afterDate' : 'constraints.beforeDate';
  const [field, { touched }, { setValue, setTouched }] = useField<number | undefined>(constraintField);
  const [expanded, setExpanded] = React.useState<boolean>(!!field.value);

  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;
    setExpanded(checked);
    if (!checked) {
      setValue(undefined); // clearing value on uncheck
    }
  };

  const handleDateChange = (date?: Date) => {
    setTouched(true);
    setValue(date?.getTime());
  };

  const date = field.value === undefined ? undefined : new Date(field.value);

  return (
    <FormControl isInvalid={expanded && touched && !date}>
      <Checkbox onChange={handleCheckboxClick} isChecked={expanded}>
        <Text color="gray.600">
          Date must be{' '}
          <Text fontWeight="bold" as="span">
            {condition}
          </Text>{' '}
          a specific calendar date
        </Text>
      </Checkbox>
      {expanded && (
        <BlvdDatePicker
          value={date}
          onDayChange={handleDateChange}
          dayPickerProps={dayPickerProps}
          formatTriggerLabel={formatMMDDYYYY}
        />
      )}
      <FormErrorMessage>
        <Text variant="-2">Please select a valid date</Text>
      </FormErrorMessage>
    </FormControl>
  );
};
