import { AuditMetadata, Muid, Organization, OrganizationMembership, Ref } from '../core';
import {
  Checklist,
  Folder,
  Task,
  TaskTemplate,
  TaskTemplateGroup,
  Template,
  TemplateRevision,
  WidgetGroup,
} from '../process';

export interface TaskPermit {
  readonly id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  task: Ref<Task>;
  organizationMembership: Ref<OrganizationMembership>;
  taskRead: boolean;
  taskUpdate: boolean;
}

export enum TaskPermissionRuleSourceType {
  ChecklistInitiator = 'ChecklistInitiator',
  FormField = 'FormField',
}

export interface TaskPermissionRule {
  id: Muid;
  organization: Ref<Organization>;
  templateRevision: Ref<TemplateRevision>;
  targetTaskTemplateGroup: TaskTemplateGroup;
  sourceType: TaskPermissionRuleSourceType;
  sourceFormFieldWidgetGroup?: WidgetGroup;
  taskRead: boolean;
  taskUpdate: boolean;
}

export interface ChecklistPermit {
  readonly id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  checklist: Ref<Checklist>;
  organizationMembership: Ref<OrganizationMembership>;
  checklistRead: boolean;
  checklistUpdate: boolean;
  checklistDelete: boolean;
  doodadCreate: boolean;
  doodadRead: boolean;
  doodadUpdate: boolean;
  doodadDelete: boolean;
}

export interface TemplatePermit {
  readonly id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  template: Ref<Template>;
  organizationMembership: Ref<OrganizationMembership>;
  templateRead: boolean;
  templateUpdate: boolean;
  templateDelete: boolean;
  pageRead: boolean;
  pageUpdate: boolean;
  pageDelete: boolean;
  checklistCreate: boolean;
  checklistRead: boolean;
  checklistUpdate: boolean;
  checklistDelete: boolean;
  doodadCreate: boolean;
  doodadRead: boolean;
  doodadUpdate: boolean;
  doodadDelete: boolean;
}

export interface FolderPermit {
  readonly id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  folder: Ref<Folder>;
  organizationMembership: Ref<OrganizationMembership>;
  folderCreate: boolean;
  folderRead: boolean;
  folderUpdate: boolean;
  folderDelete: boolean;
  templateCreate: boolean;
  templateRead: boolean;
  templateUpdate: boolean;
  templateDelete: boolean;
  pageCreate: boolean;
  pageRead: boolean;
  pageUpdate: boolean;
  pageDelete: boolean;
  checklistCreate: boolean;
  checklistRead: boolean;
  checklistUpdate: boolean;
  checklistDelete: boolean;
  doodadCreate: boolean;
  doodadRead: boolean;
  doodadUpdate: boolean;
  doodadDelete: boolean;
}

export function isFolderPermit(permit: TemplatePermit | FolderPermit): permit is FolderPermit {
  return 'folder' in permit;
}

export function isTemplatePermit(permit: TemplatePermit | FolderPermit): permit is TemplatePermit {
  return 'template' in permit;
}

export interface TaskTemplatePermit {
  readonly id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  taskTemplate: Ref<TaskTemplate>;
  organizationMembership: Ref<OrganizationMembership>;
  taskRead: boolean;
  taskUpdate: boolean;
}

export interface UpdateTaskTemplatePermit {
  organizationMembershipId: Muid;
  taskRead: boolean;
  taskUpdate: boolean;
  taskTemplateId: Muid;
  templateRevisionId: Muid;
}

export enum TaskTemplatePermissionLevel {
  ViewAndComplete = 'View and complete',
  ViewOnly = 'View only',
  NoAccess = 'No access',
}

export const TaskTemplatePermissionLevels = [
  TaskTemplatePermissionLevel.ViewAndComplete,
  TaskTemplatePermissionLevel.ViewOnly,
  TaskTemplatePermissionLevel.NoAccess,
];
