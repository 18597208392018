import {
  FeaturedAppCsvRow,
  FeaturedIncomingWebhookEvent,
  IncomingWebhookAppName,
  UnsafeIncomingWebhookAppName,
  withIncomingWebhookPrefix,
} from '@process-street/subgrade/automation';
import Papa from 'papaparse';
import unzip from 'lodash/unzip';
import { toResult } from '@process-street/subgrade/util';
import { UserDetailUseCase } from '@process-street/subgrade/core';

const getUseCasesAndEvents = (csvString: string) => {
  const [useCases, ...rest] = Papa.parse<UnsafeIncomingWebhookAppName[]>(csvString, {}).data as [
    UserDetailUseCase[],
    UnsafeIncomingWebhookAppName[],
  ];
  const columns = unzip(rest);
  return useCases.map(
    (useCase, index) =>
      [useCase, columns[index].filter(value => value.length > 0)] as [
        UserDetailUseCase,
        UnsafeIncomingWebhookAppName[],
      ],
  );
};

const getUseCasesByApp = (useCasesAndEvents: ReturnType<typeof getUseCasesAndEvents>) => {
  const useCasesByApp = new Map<UnsafeIncomingWebhookAppName, UserDetailUseCase[]>();
  return useCasesAndEvents.reduce((acc, [useCase, apps]) => {
    apps.forEach(app => {
      const useCases = acc.get(app) || [];
      useCases.push(useCase);
      acc.set(app, useCases);
    });
    return acc;
  }, useCasesByApp);
};

const getEvents = (csvString: string) =>
  Papa.parse<FeaturedAppCsvRow>(csvString, {
    header: true,
  }).data.map((row): FeaturedIncomingWebhookEvent => {
    return {
      appName: row['App name'],
      enabled: row['Enabled'].toLowerCase() === 'true',
      event: row['Event'],
      docsUrl: row['Docs url'],
      iconUrl: row['Icon url'],
      samplePayload: toResult(() => JSON.parse(row['Sample payload'] || 'null')).fold(
        () => {
          // Use for testing
          console.warn(`Invalid payload for ${row['App name']}: ${row['Sample payload']}`);
          return null;
        },
        x => x,
      ),
    };
  });

const getAppNames = (events: ReturnType<typeof getEvents>) => [...new Set(events.map(row => row.appName))];
const getEnabledAppNames = (events: ReturnType<typeof getEvents>) => [
  ...new Set(events.filter(e => e.enabled).map(row => row.appName)),
];

const getEventsByApp = (events: ReturnType<typeof getEvents>) =>
  events.reduce((eventsMap, event) => {
    const safeName = withIncomingWebhookPrefix(event.appName);
    if (!eventsMap[safeName]) {
      eventsMap[safeName] = [];
    }
    eventsMap[safeName].push(event);
    return eventsMap;
  }, {} as Record<IncomingWebhookAppName, FeaturedIncomingWebhookEvent[]>);

export const FeaturedIncomingWebhooksUtils = {
  getAppNames,
  getEnabledAppNames,
  getEvents,
  getEventsByApp,
  getUseCasesAndEvents,
  getUseCasesByApp,
};
