import * as React from 'react';
import { Shell } from '../components/shell';
import { ShellContent } from '../components/shell-content';
import { ShellLeftPane } from '../components/shell-left-pane';
import { Spinner } from 'components/design/next';

export const SignUpLoadingState = () => {
  return (
    <Shell>
      <ShellLeftPane />
      <ShellContent>
        <Spinner />
      </ShellContent>
    </Shell>
  );
};
