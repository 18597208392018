export const FolderPermitApi = function (Permits) {
  'ngInject';

  const getAllByOrganizationId = organizationId =>
    Permits.getAllByOrganizationId({
      type: 'folder',
      organizationId,
    }).$promise;

  return {
    getAllByOrganizationId,
  };
};
