import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from 'components/design/next';
import { UploadTemplateCoverImage } from 'features/cover-image/components/common/upload';
import { Template } from '@process-street/subgrade/process';
import { CoverImageByTemplateIdQuery } from 'features/cover-image/query-builder';
import { useQueryClient } from 'react-query';
import { noop } from 'lodash';
import { useMatch } from '@process-street/adapters/navigation';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';

type CoverImageUploadModalProps = {
  templateId: Template['id'];
  onFinish?: () => void;
} & Omit<ModalProps, 'children'> & { children?: React.ReactNode };

export const CoverImageUploadModal = ({
  templateId,
  onFinish = noop,
  children,
  ...props
}: CoverImageUploadModalProps) => {
  const queryClient = useQueryClient();
  const onUploadSuccess = async (templateId: Template['id']) => {
    await queryClient.invalidateQueries(CoverImageByTemplateIdQuery.getKey({ templateId }));
    onFinish();
  };
  const isPageEditorV2 = Boolean(useMatch('pageV2'));

  const uploadModalContent = (
    <>
      <ModalHeader fontSize="lg">Add cover image</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={4}>
        <UploadTemplateCoverImage
          templateId={templateId}
          uploadingMessage="Uploading image..."
          acceptMimeTypes="image/*"
          onFinish={onUploadSuccess}
        >
          <VStack spacing={3}>
            <Button>Choose image</Button>
            {children ?? (
              <VStack>
                <Text align="center" variant="-1" color="gray.500" fontWeight="normal">
                  Cover image will appear as a banner at the top of workflows, runs and emails.
                </Text>
                <Text variant="-1" color="gray.500" fontWeight="normal">
                  Images wider than 700px work best
                </Text>
              </VStack>
            )}
          </VStack>
        </UploadTemplateCoverImage>
      </ModalBody>
    </>
  );

  return (
    <Modal {...props} size="lg">
      <ModalOverlay />
      <ModalContent>
        {isPageEditorV2 ? <ThemeProvider2024>{uploadModalContent}</ThemeProvider2024> : <>{uploadModalContent}</>}
      </ModalContent>
    </Modal>
  );
};
