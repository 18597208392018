import { AxiosError } from 'axios';
import { useQuery as useRQ, UseQueryOptions, QueryKey } from 'react-query';
import { axiosService } from 'services/axios-service';
import {
  PremadeTemplateOverview,
  PremadeTemplatesSearchCriteria,
} from '@process-street/subgrade/process/premade-template-model';

export namespace GetPremadeTemplatesSearchQuery {
  export type Params = PremadeTemplatesSearchCriteria | undefined;

  export type Response = PremadeTemplateOverview[];

  export const key = ['premade-templates-search'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/premade-templates/search`, {
        params: {
          organizationId: params?.organizationId,
          categoryId: params?.category?.id,
          searchTerm: params?.searchTerm,
        },
      })
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params) && options.enabled !== false,
    });
  };
}
