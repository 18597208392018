import React from 'react';
import { CoverIcon as CoverIconType } from '@process-street/subgrade/core';

import { Flex, FlexProps, Text } from 'components/design/next';
import { CDNImage } from 'components/cdn-image';
import { match, P } from 'ts-pattern';

export type ChecklistCoverIconProps = {
  icon: CoverIconType;
} & FlexProps;

export const ChecklistCoverIcon: React.FC<React.PropsWithChildren<ChecklistCoverIconProps>> = ({ icon, ...props }) => {
  return (
    <Flex
      data-testid="cover-icon-container"
      alignItems="center"
      justifyContent="center"
      height="15"
      width="15"
      position="relative"
      borderRadius="50%"
      borderWidth="base"
      borderColor="gray.100"
      borderStyle="solid"
      bg="gray.50"
      {...props}
    >
      {match(icon)
        .with({ emoji: P.not(undefined) }, ({ emoji }) => (
          <Text fontSize="2xl" align="center">
            {emoji}
          </Text>
        ))
        .with({ s3File: P.not(undefined) }, ({ s3File }) => (
          <CDNImage
            alt={s3File.originalName}
            s3File={s3File}
            transformation={{
              height: 60,
              width: 60,
            }}
            borderRadius="50%"
          />
        ))
        .otherwise(() => null)}
    </Flex>
  );
};
