import { Attachment } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type DeleteAttachmentMutationParams = {
  id: Attachment['id'];
};

export type DeleteAttachmentMutationResponse = Attachment;

export const DeleteAttachmentMutation = {
  key: ['delete', 'attachment'],
  mutationFn: (params: DeleteAttachmentMutationParams) =>
    axiosService
      .getAxios()
      .delete<DeleteAttachmentMutationResponse>(`/1/attachments/${params.id}`)
      .then(res => res.data),
};

export const useDeleteAttachmentMutation = (
  options: UseMutationOptions<DeleteAttachmentMutationResponse, AxiosError, DeleteAttachmentMutationParams> = {},
) => {
  return useMutation(DeleteAttachmentMutation.mutationFn, options);
};
