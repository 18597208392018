import { StdMergeTagKey } from '@process-street/subgrade/core';
import {
  VStack,
  InputGroup,
  Input,
  InputRightElement,
  Popover,
  PopoverTrigger,
  IconButton,
  Icon,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Text,
  Link,
  HStack,
  Button,
  useMenuItem,
  MenuItemProps,
  Tooltip,
  Box,
} from 'components/design/next';
import * as React from 'react';
import { KeyStrings } from 'services/key';

export type VariableFallbackTagOption = {
  key: string;
  value: string;
};

export type VariableFallbackFormProps = {
  selectedTag: VariableFallbackTagOption;
  onSubmit: (fallback: string | undefined) => void;
};

export const VariableFallbackForm: React.FC<React.PropsWithChildren<VariableFallbackFormProps>> = ({
  selectedTag,
  onSubmit,
}) => {
  // This is needed to fix a bug where the input would not focus when inside a MenuList
  // https://github.com/chakra-ui/chakra-ui/issues/3697#issuecomment-811118964
  const { role: _, ...inputProps } = useMenuItem({ autoFocus: true } as MenuItemProps);
  const [fallback, setFallback] = React.useState('');
  const isDisabled = !isFallbackEnabled(selectedTag.key);

  // convert empty string to undefined
  const currentValue = fallback?.replaceAll("'", '’') || undefined;

  const input = (
    <Input
      {...inputProps}
      isDisabled={isDisabled}
      p="2"
      fontSize="xs"
      id="fallback"
      name="fallback"
      placeholder="Add a fallback in case we can’t find a value"
      onChange={e => setFallback(e.target.value)}
      onClick={e => e.stopPropagation()}
      onKeyUp={e => {
        if (e.key === KeyStrings.ENTER) {
          onSubmit(currentValue);
        }
      }}
      _disabled={{
        bgColor: 'gray.200',
        borderColor: 'gray.200',
        color: 'gray.400',
        opacity: '1 !important',
      }}
      autoFocus
    />
  );

  return (
    <VStack
      p="3"
      spacing="1.5"
      w="full"
      alignItems="flex-start"
      borderTopWidth="thin"
      borderTopStyle="solid"
      borderTopColor="gray.200"
    >
      <Text as="label" fontSize="xs" color="gray.500" htmlFor="fallback" textAlign="left">
        The value from {selectedTag.value ?? selectedTag.key} will be inserted
      </Text>
      <InputGroup>
        {isDisabled ? (
          <Tooltip label="This variable doesn’t need a fallback">
            <Box w="full">{input}</Box>
          </Tooltip>
        ) : (
          input
        )}

        <InputRightElement>
          <Popover placement="top">
            <PopoverTrigger>
              <IconButton
                variant="ghost"
                size="xs"
                aria-label="variables info"
                icon={<Icon icon="info-circle" size="4" variant="far" />}
              />
            </PopoverTrigger>
            <PopoverContent bgColor="gray.700" color="white">
              <PopoverArrow bgColor="gray.700" />
              <PopoverBody>
                <Text variant="-2" lineHeight="1.5">
                  Fallbacks are values for variables if there is no manual input into that form field.{' '}
                  <Link
                    color="white"
                    textDecoration="underline"
                    href="https://www.process.st/help/docs/variables/#advanced-variable-options"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </Link>
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </InputRightElement>
      </InputGroup>
      <HStack justifyContent="flex-end" w="full">
        <Button fontSize="sm" variant="solid" mt="1" onClick={() => onSubmit(currentValue)}>
          Insert variable
        </Button>
      </HStack>
    </VStack>
  );
};

const MERGE_TAG_KEYS_WITH_FALLBACK = new Set<string>([
  StdMergeTagKey.EmailTriggerBody,
  StdMergeTagKey.EmailTriggerSubject,
  StdMergeTagKey.TaskDueDate,
  StdMergeTagKey.TaskName,
  StdMergeTagKey.RunDueDate,
]);

const isFallbackEnabled = (key: string): boolean => {
  return key.startsWith('form.') || MERGE_TAG_KEYS_WITH_FALLBACK.has(key);
};
