import { NativeAutomation } from '@process-street/subgrade/process';
import { HStack, Icon, Text } from 'components/design/next';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import * as React from 'react';

import { components, GroupBase, OptionProps } from 'react-select';

type Option = { value: NativeAutomation.Operator; label: string };
export type MathOperatorOptionProps = OptionProps<Option, false, GroupBase<Option>>;

const Icons: Record<NativeAutomation.Operator, CustomIconName> = {
  Add: 'plus',
  Subtract: 'minus',
  Multiply: 'xmark',
  Divide: 'divide',
};

export const MathOperatorOption: React.FC<React.PropsWithChildren<MathOperatorOptionProps>> = props => {
  const value = props.data.value as NativeAutomation.Operator;

  return (
    <components.Option<Option, false, GroupBase<Option>> {...props}>
      <HStack spacing="2">
        <Icon icon={Icons[value]} color="gray.500" size="4" />
        <Text>{props.data.label}</Text>
      </HStack>
    </components.Option>
  );
};
