import React from 'react';
import { Box, Fade, HStack, Spinner, Text, VStack } from 'components/design/next';
import { AutomationLogItem } from './item';
import { useAutomationLogsQuery } from './query';
import { AutomationLog, TemplateSolutionInstance } from '@process-street/subgrade/automation';

export type AutomationLogListProps = {
  logs: AutomationLog[];
  timeZone?: string;
  isLoading?: boolean;
  isLoaded?: boolean;
};

export const AutomationLogList: React.FunctionComponent<React.PropsWithChildren<AutomationLogListProps>> = ({
  logs,
  isLoading,
  isLoaded,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}) => {
  const logsPresent = (logs.length ?? 0) > 0;

  return (
    <VStack align="stretch" spacing="4">
      <HStack spacing="4">
        <Text fontWeight="bold">Run Logs</Text>
        <Fade in={isLoading}>
          <Spinner label="checking for new automation runs" />
        </Fade>
      </HStack>
      {isLoaded && logsPresent ? (
        <Box>
          {logs.map(log => (
            <AutomationLogItem key={log.id} log={log} timeZone={timeZone} />
          ))}
        </Box>
      ) : (
        <Box>
          <Text variant="-1u" noOfLines={1}>
            Your automation hasn’t run yet.
          </Text>
        </Box>
      )}
    </VStack>
  );
};

export type AutomationLogListWrapperProps = {
  timeZone?: string;
  templateSolutionInstance?: TemplateSolutionInstance;
};

export const AutomationLogListWrapper: React.FunctionComponent<
  React.PropsWithChildren<AutomationLogListWrapperProps>
> = ({ templateSolutionInstance, timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone }) => {
  const { data, isFetching, isFetched } = useAutomationLogsQuery({ templateSolutionInstance });

  return <AutomationLogList logs={data ?? []} timeZone={timeZone} isLoading={isFetching} isLoaded={isFetched} />;
};
