import * as React from 'react';
import { Box, Link, Stack, Text, useBreakpointValue } from 'components/design/next';
import {
  isCreatedUser,
  isNotIdRef,
  OrganizationMembershipStatus,
  OrganizationMembershipWithUser,
} from '@process-street/subgrade/core';
import { Avatar } from 'components/common/Avatar';
import { RemoveUserButton } from '../RemoveUserButton';
import { RoleSelector } from 'pages/organizations/manage/users/components/role-selector/component';
import { useInjector } from 'components/injection-provider';
import { PendingUser } from 'components/pending-user/pending-user';
import { useUserListQuery } from './query';
import { useFeatureFlag } from 'features/feature-flags';
import { DeactivateUserMenu } from 'features/user/components/deactivate-user-menu';

export const Member: React.FC<React.PropsWithChildren<{ membership: OrganizationMembershipWithUser }>> = ({
  membership,
}) => {
  const emailFontSizeVariant = useBreakpointValue({ base: '-1', sm: '1' }) ?? '1';
  const isUserDeactivationEnabled = useFeatureFlag('deactivatingUsers');

  const {
    data: { userIsAdmin },
  } = useUserListQuery();
  const { $state } = useInjector('$state');
  const userHref = $state.href('userManage.tab', { id: membership.user.id, tab: 'activity' });
  const { user } = membership;
  const isInactive = membership.status === OrganizationMembershipStatus.Inactive;
  const opacity = isInactive ? 0.4 : 1;

  if (!isNotIdRef(user)) return null;

  return (
    <>
      <Box w="8" role="cell" display={{ base: 'none', sm: 'block' }} opacity={opacity}>
        <Link href={userHref}>
          <Avatar user={user} showUnconfirmedBadge={true} />
        </Link>
      </Box>

      <Box flex={1} role="cell" opacity={opacity}>
        <Stack>
          <Link display="inline" href={userHref} color="brand.500">
            <Text variant={emailFontSizeVariant} maxWidth={{ base: '25', sm: '60', md: '100%' }} noOfLines={1}>
              {user.username}
            </Text>
          </Link>
          <Text maxWidth={{ base: '25', sm: '60', md: '100%' }} as="span" variant="-2" color="gray.400" noOfLines={1}>
            {user.email}
            {isCreatedUser(user) ? <PendingUser user={user} /> : null}
          </Text>
        </Stack>
      </Box>

      <Box textAlign="right" role="cell">
        <RoleSelector organizationMembership={membership} />
      </Box>

      {isUserDeactivationEnabled ? (
        <Box w={24} textAlign="right" role="cell">
          <DeactivateUserMenu organizationMembership={membership} />
        </Box>
      ) : null}

      <Box w="11" role="cell">
        {userIsAdmin ? <RemoveUserButton userId={user.id} /> : null}
      </Box>
    </>
  );
};
