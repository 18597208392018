import angular from 'angular';
import { MembershipListItemComponent } from 'components/common/membership-list-item/membership-list-item.component';
import { LogoSpinner } from 'components/common/LogoSpinner';
import { Avatar, AvatarCounter } from 'components/common/Avatar';
import { reactToAngular } from 'components/react-root';

export const CommonModule = angular
  .module('commonModuleModule', [])
  .component('psLogoSpinner', reactToAngular(LogoSpinner, ['text']))
  .component('psAvatarCounter', reactToAngular(AvatarCounter, ['count', 'size', 'onClick']))
  .component('psAvatar', reactToAngular(Avatar, ['url', 'initials', 'size', 'user', 'badge', 'showUnconfirmedBadge']))
  .component('psMembershipListItem', MembershipListItemComponent).name;
