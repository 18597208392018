import * as React from 'react';
import { HStack, FormHelperText, Text, Button, Divider, Tooltip } from 'components/design/next';
import { DefaultableFormFieldValue, WidgetUtils } from '@process-street/subgrade/process';
import { FormFieldAudit } from 'components/widgets/form-field/common/FormFieldAudit';
import { match, P } from 'ts-pattern';
import { useOutputWidgetAiTasksMap } from 'features/native-automations/hooks/use-output-widget-ai-tasks-map';
import { AiTaskAudit } from '../ai-task-audit';
import { useStateParam } from 'hooks/use-state-param';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { useInputWidgetAiTasksMap } from 'features/native-automations/hooks/use-input-widget-ai-tasks-map';
import { ProcessPeteCircle, ProcessPeteIcon } from '../ai-task-audit/common';
import { useWidgetContext } from '../../hooks/use-widget-context';

export interface FormFieldValueHelperTextProps {
  defaultValue?: string;
  formFieldValue?: DefaultableFormFieldValue;
  onResetDefaultValue: () => void;
}

export function FormFieldValueHelperText({
  defaultValue,
  formFieldValue,
  onResetDefaultValue: handleResetDefaultValue,
}: FormFieldValueHelperTextProps) {
  const { widget } = useWidgetContext();
  const checklistId = useStateParam({ key: 'id' });
  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({ checklistId });
  const templateRevisionId = checklistRevisionQuery.data?.templateRevision.id;

  const outputWidgetAiTasksMap = useOutputWidgetAiTasksMap({ templateRevisionId });
  const outputFromAiTasks = React.useMemo(() => {
    return [...(outputWidgetAiTasksMap[widget.id] ?? [])].reverse();
  }, [outputWidgetAiTasksMap, widget.id]);

  const inputWidgetAiTasksMap = useInputWidgetAiTasksMap({ templateRevisionId });
  const inputToAiTasks = React.useMemo(() => {
    return [...(inputWidgetAiTasksMap[widget.id] ?? [])].reverse();
  }, [inputWidgetAiTasksMap, widget.id]);

  const helperText = match({
    defaultValueConfigured: (defaultValue?.length ?? 0) > 0,
    hasVariables: WidgetUtils.hasVariables(defaultValue),
    hasDefaultValue: WidgetUtils.hasDefaultValue({ formFieldValue, defaultValue }),
    hasInvalidDefaultValue: WidgetUtils.hasInvalidDefaultValue({ formFieldValue, defaultValue }),
    formFieldValue,
    outputFromAiTasks,
  })
    // TODO figure out how to find the right native automation if multiple exist
    .with({ outputFromAiTasks: P.not([]) }, ({ formFieldValue, outputFromAiTasks: [aiTaskTemplate] }) => (
      <AiTaskAudit fontSize="xs" formFieldValue={formFieldValue} aiTaskTemplate={aiTaskTemplate} />
    ))
    .with(
      {
        hasDefaultValue: false,
        defaultValueConfigured: true,
        formFieldValue: { audit: P.not(P.nullish) },
      },
      ({ formFieldValue: { audit } }) => (
        <HStack>
          <FormFieldAudit fontSize="xs" audit={audit} />
          <Divider h="3" orientation="vertical" />
          <Button size="xs" textDecor="underline" variant="link" onClick={handleResetDefaultValue}>
            Reset to default value
          </Button>
        </HStack>
      ),
    )
    .with({ defaultValueConfigured: true, hasInvalidDefaultValue: true }, () => (
      <Text variant="-2" color="inherit" fontWeight="bold">
        Invalid default value
      </Text>
    ))
    .with({ hasDefaultValue: true, hasVariables: true }, () => (
      <Text variant="-2" color="inherit" fontWeight="bold">
        Pre-filled with a default value and{' '}
        {/* try to match MaskedInputParsers merge-tags Parser span styling for consistency */}
        <Text as="span" variant="inherit" bgColor="brand.100" borderRadius="base">
          <Text as="span" aria-hidden visibility="hidden" variant="inherit">
            {'{{'}
          </Text>
          variables
          <Text as="span" aria-hidden visibility="hidden" variant="inherit">
            {'}}'}
          </Text>
        </Text>
      </Text>
    ))
    .with({ hasDefaultValue: true, hasVariables: false }, () => (
      <Text variant="-2" color="inherit" fontWeight="bold">
        Pre-filled with a default value
      </Text>
    ))
    .with({ formFieldValue: { audit: P.not(P.nullish) } }, ({ formFieldValue: { audit } }) => (
      <FormFieldAudit fontSize="xs" audit={audit} />
    ))
    .otherwise(() => null);

  return (
    <HStack>
      {inputToAiTasks.length > 0 && outputFromAiTasks.length === 0 ? (
        <Tooltip hasArrow label="Field connected to an AI task" shouldWrapChildren>
          <ProcessPeteCircle bg="blue.100">
            <ProcessPeteIcon color="blue.500" />
          </ProcessPeteCircle>
        </Tooltip>
      ) : null}
      {helperText ? <FormHelperText mt="0">{helperText}</FormHelperText> : null}
    </HStack>
  );
}
