import { TaskTemplate, TaskTemplateTaskType } from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { TaskListEvent } from 'directives/task-list/task-list-event';
import { useCallback, useEffect, useState } from 'react';

export type TaskTemplateGenerationStatus = 'loaded' | 'loading' | 'error' | 'ignored';
export type TaskTemplateStatusMap = Record<TaskTemplate['id'], TaskTemplateGenerationStatus>;

export const useTaskTemplateStatusMap = () => {
  const { $rootScope } = useInjector('$rootScope');
  const [taskTemplateStatusMap, setTaskTemplateStatusMap] = useState<TaskTemplateStatusMap>({});

  const update = useCallback((taskTemplates: TaskTemplate[]) => {
    const newStatusMap: TaskTemplateStatusMap = Object.fromEntries(
      // ignore approval tasks during generation
      taskTemplates.map(tt => [tt.id, tt.taskType === TaskTemplateTaskType.Approval ? 'ignored' : 'loading']),
    );

    setTaskTemplateStatusMap(newStatusMap);
  }, []);

  const reset = useCallback(() => {
    setTaskTemplateStatusMap({});
  }, []);

  useEffect(() => {
    const unsubscribe = $rootScope.$on(
      TaskListEvent.AI_WIDGET_GENERATION_FOR_TASK_ANIMATION_DONE,
      (_: angular.IAngularEvent, tt: TaskTemplate) => {
        setTaskTemplateStatusMap(previous => ({
          ...previous,
          [tt.id]: 'loaded',
        }));
      },
    );

    return () => {
      unsubscribe();
    };
  }, [$rootScope]);

  return { taskTemplateStatusMap, update, reset };
};
