import * as React from 'react';
import { FormControl, FormLabel, Grid, GridItem } from 'components/design/next';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { SaveOrganizationParams } from 'features/organization/manage/settings/settings';
import { OnChangeValue } from 'react-select';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { SimpleOption } from 'components/design/BlvdSelect/types';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { useGetOrganizationQuery } from 'features/organization/query-builder';

export type DateFormatFieldProps = {
  onSave: (params: SaveOrganizationParams) => void;
};

enum DateFormat {
  MonthDayYear = 'MM/DD/YYYY',
  YearMonthDay = 'YYYY/MM/DD',
  DayMonthYear = 'DD/MM/YYYY',
}

const DateFormatKeys = Object.keys(DateFormat) as (keyof typeof DateFormat)[];

export const DateFormatField: React.FC<React.PropsWithChildren<DateFormatFieldProps>> = ({ onSave }) => {
  const options = DateFormatKeys.map(key => ({ value: key, label: DateFormat[key] }));
  const currentOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const [dateFormat, setDateFormat] = React.useState<SimpleOption | null>(null);
  const organizationQuery = useGetOrganizationQuery({ organizationId: currentOrganization?.id });

  React.useEffect(() => {
    const key = DateFormatKeys.find(key => key === organizationQuery.data?.dateFormat);
    if (key) {
      setDateFormat({ value: key, label: DateFormat[key] });
    }
  }, [organizationQuery.data]);

  const handleChange = (option: OnChangeValue<SimpleOption, false>) => {
    if (BlvdSelectHelpers.isOptionType<SimpleOption>(option)) {
      setDateFormat(option);
      onSave({
        dateFormat: option.value,
      });
    }
  };

  return (
    <FormControl as={Grid} templateColumns={{ base: '1fr', md: '220px 1fr' }}>
      <GridItem display="flex" alignItems="center">
        <FormLabel htmlFor="dateformat" color="gray.600" as="label">
          Date format
        </FormLabel>
      </GridItem>
      <GridItem
        sx={{
          '.blvd-select .blvd-select__control .blvd-select__value-container': { pl: 4 },
        }}
      >
        <BlvdSelect id="dateformat" name="dateformat" options={options} onChange={handleChange} value={dateFormat} />
      </GridItem>
    </FormControl>
  );
};
