import * as React from 'react';
import { Link, LinkProps, MenuItem, MenuItemProps } from 'components/design/next';
import { NgLink, NgLinkProps } from 'features/app/components/ng-link';

export const MenuItemLink: React.FC<React.PropsWithChildren<MenuItemProps & LinkProps>> = props => {
  return <MenuItem as={Link} {...props} variant="noline" _focus={{ color: 'inherit', bgColor: 'gray.100' }} />;
};

export const MenuItemNgLink: React.FC<React.PropsWithChildren<MenuItemProps & NgLinkProps>> = props => {
  return <MenuItem as={NgLink} {...props} variant="noline" _focus={{ color: 'inherit', bgColor: 'gray.100' }} />;
};
