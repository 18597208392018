import { TemplateSolutionInstance } from '@process-street/subgrade/automation';
import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type DeleteTemplateSolutionInstanceMutationParams = {
  templateId: Muid;
  solutionInstanceId: TemplateSolutionInstance['solutionInstanceId'];
};

export type DeleteTemplateSolutionInstanceMutationResponse = {};

export const DeleteTemplateSolutionInstanceMutation = {
  key: ['delete', 'template-solution-instance'],
  mutationFn: ({ templateId, solutionInstanceId }: DeleteTemplateSolutionInstanceMutationParams) =>
    axiosService
      .getAxios()
      .post<DeleteTemplateSolutionInstanceMutationResponse>(
        `/1/templates/${templateId}/automations/template-solution-instances/${solutionInstanceId}/delete`,
      )
      .then(res => res.data),
};

export const useDeleteTemplateSolutionInstanceMutation = (
  options: UseMutationOptions<
    DeleteTemplateSolutionInstanceMutationResponse,
    AxiosError,
    DeleteTemplateSolutionInstanceMutationParams
  > = {},
) => {
  return useMutation(DeleteTemplateSolutionInstanceMutation.mutationFn, options);
};
