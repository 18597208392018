import { createCachedAction } from 'reducers/util';

export const CHECKLIST_PERMIT_PUT = 'checklistPermit/PUT';
export const CHECKLIST_PERMIT_DELETE = 'checklistPermit/DELETE';
export const CHECKLIST_PERMIT_GET_ALL_BY_ORGANIZATION_ID = 'checklistPermit/GET_ALL_BY_ORGANIZATION_ID';

export const ChecklistPermitActions = ChecklistPermitApi => {
  'ngInject';

  const getAllByOrganizationId = createCachedAction(
    CHECKLIST_PERMIT_GET_ALL_BY_ORGANIZATION_ID,
    ChecklistPermitApi.getAllByOrganizationId,
  );

  return {
    getAllByOrganizationId,
  };
};
