import * as React from 'react';
import { ProvidedProps, PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { TASK_STATUS_LABELS as STATUS_LABELS } from '../common/constants';
import { TaskStat, useTooltipEffect } from './tooltip-state';
import { useChartContext } from './chart-context';

export const Slice: React.VFC<{ arc: PieArcDatum<TaskStat> } & Pick<ProvidedProps<TaskStat>, 'path'>> = ({
  arc,
  path,
}) => {
  const { getKey, getColor } = useChartContext();
  const ref = React.useRef<SVGGElement>(null);
  const dispatch = useTooltipEffect();

  return (
    <g
      key={getKey(arc)}
      aria-label={`donut chart section ${STATUS_LABELS[getKey(arc)]}`}
      ref={ref}
      onMouseLeave={() => {
        dispatch({ type: 'hide' });
      }}
      onMouseMove={() => {
        if (!ref.current) return;
        dispatch({ type: 'show', payload: { data: arc.data, referenceElement: ref.current } });
      }}
    >
      <path
        // compute interpolated path d attribute from intermediate angle values
        d={path({ ...arc, startAngle: arc.startAngle, endAngle: arc.endAngle }) ?? undefined}
        fill={getColor(arc)}
      />
    </g>
  );
};
