import angular from 'angular';
import { MergeTagsConstants } from '@process-street/subgrade/form/merge-tags-constants';
import templateUrl from './template-snippet-field.component.html';
import './template-snippet-field.scss';
import { useTemplateWidgetsStore } from 'features/widgets/use-template-widgets-store';
import { createMergeTag } from 'hooks/use-insert-merge-tag-callback';

angular.module('frontStreetApp.directives').component('psTemplateSnippetFormField', {
  bindings: {
    widget: '<',
    templateRevision: '<',
    editable: '<',
    onUpdateWidget: '&',
  },
  templateUrl,
  controller(TextInsertService) {
    const ctrl = this;

    ctrl.Target = MergeTagsConstants.Target;

    ctrl.insertVariable = function (key, fieldId, fallback) {
      const [field] = angular.element(`[id="${fieldId}"]`);

      TextInsertService.insert(field, createMergeTag(key, fallback));
    };

    ctrl.updateFormFieldWidget = function () {
      ctrl.onUpdateWidget({ widget: ctrl.widget });
    };

    ctrl.isUpdating = false;
    ctrl.$onInit = () => {
      ctrl.unsubscribeFromTemplateWidgetsStore = useTemplateWidgetsStore.subscribe(state => {
        ctrl.isUpdating = state.updatingWidgetGroupIds.has(ctrl.widget.header.group.id);
      });
    };

    ctrl.$onDestroy = () => {
      ctrl.unsubscribeFromTemplateWidgetsStore();
    };
  },
});
