import * as React from 'react';
import {
  DataSetColumnDef,
  NativeAutomation,
  SavedView,
  Template,
  TemplateStatus,
} from '@process-street/subgrade/process';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useGetAllTemplatesQuery } from 'features/template/query-builder';
import { GetAllDataSetsQuery } from 'pages/reports/data-sets/query-builder';
import { useDataSetId } from '../../../utils/use-data-set-id';
import {
  CreateNativeAutomationMutation,
  GetAllNativeAutomationsQuery,
  UpdateNativeAutomationActionsMutation,
  UpdateNativeAutomationTriggerMutation,
} from 'features/native-automations/query-builder';
import { useQueryClient } from 'react-query';
import { useAutomationSelector } from '../../selector/context';
import { SolutionTypeTag } from '@process-street/subgrade/automation';
import { Muid } from '@process-street/subgrade/core';
import { OnChangeValue } from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect';
import { match, P } from 'ts-pattern';
import { useSavedViewId } from '../../../utils/use-saved-view-id';
import { useToast } from 'components/design/next';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { trace } from 'components/trace';
import { getTemplateIdFromAutomation, Helpers } from './helpers';
import { SimpleOption } from 'components/design/BlvdSelect/types';
import { useFeatureFlag } from 'app/features/feature-flags';

export type TemplateOption = {
  label: string;
  value: Template['id'];
};

export type TriggerOption = {
  label: string;
  value: 'DataSetRowCreated' | 'DataSetRowUpdated' | 'DataSetRowChanged';
};

export type SavedViewOption = {
  label: string;
  value: SavedView['id'];
};

export type DataSetColumnOption = {
  label: string;
  value: DataSetColumnDef['id'];
};

export type ConditionOption = {
  label: string;
  value: NativeAutomation.ConditionType;
};

export type UseDataSetNativeAutomationEditorFormProps = {
  nativeAutomationId?: NativeAutomation['id'];
};

const logger = trace({ name: 'useDataSetNativeAutomationEditorForm' });

export const useDataSetNativeAutomationEditorForm = ({
  nativeAutomationId,
}: UseDataSetNativeAutomationEditorFormProps) => {
  const [selectedTrigger, setSelectedTrigger] = React.useState<TriggerOption | undefined>();
  const [selectedSavedView, setSelectedSavedView] = React.useState<SavedViewOption | undefined>();
  const [selectedTemplate, setSelectedTemplate] = React.useState<TemplateOption | undefined>();
  const [selectedOperandA, setSelectedOperandA] = React.useState<DataSetColumnOption | undefined>();
  const [selectedConditionType, setSelectedConditionType] = React.useState<ConditionOption | undefined>();
  const [operandB, setOperandB] = React.useState<string | undefined>();

  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const dataSetId = useDataSetId();
  const savedViewIdFromParams = useSavedViewId();
  const queryClient = useQueryClient();
  const [, send] = useAutomationSelector();

  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');

  const toast = useToast();

  const templatesQuery = useGetAllTemplatesQuery({
    organizationId: selectedOrganizationId ?? '',
    templateStatus: TemplateStatus.Active,
  });

  const dataSetQuery = GetAllDataSetsQuery.useQuery({
    select: dataSets => dataSets.find(dataSet => dataSet.id === dataSetId),
  });

  const nativeAutomationsQuery = GetAllNativeAutomationsQuery.useQuery({
    dataSetId,
  });

  const selectedAutomationWithLink = nativeAutomationsQuery.data?.find(
    ({ automation }) => automation.id === nativeAutomationId,
  );

  const savedViewId = selectedAutomationWithLink
    ? match(selectedAutomationWithLink.automation)
        .with({ trigger: { config: { savedViewId: P.string } } }, automation => automation.trigger.config.savedViewId)
        .otherwise(() => undefined)
    : undefined;

  const createNativeAutomationMutation = CreateNativeAutomationMutation.useMutation({
    onSuccess: async ({ automation }) => {
      await queryClient.invalidateQueries(GetAllNativeAutomationsQuery.getKey({ dataSetId }));

      toast({
        title: 'Automation created',
        status: 'success',
      });

      send({
        type: 'AUTOMATION_SELECTED',
        payload: {
          solutionTypeTag: SolutionTypeTag.CreateChecklistWhen,
          automationType: 'native',
          id: automation.id,
        },
      });
    },
  });

  const updateNativeAutomationTrigger = UpdateNativeAutomationTriggerMutation.useMutation();
  const updateNativeAutomationActions = UpdateNativeAutomationActionsMutation.useMutation();

  const getTrigger = React.useCallback(
    (selectedTrigger: TriggerOption, selectedSavedView: SavedViewOption): NativeAutomation.Trigger => {
      if (!dataSetId) throw new Error('`dataSetId` is missing');

      return {
        triggerType: selectedTrigger.value,
        config: {
          dataSetId,
          savedViewId: selectedSavedView.value,
        },
      };
    },
    [dataSetId],
  );

  const getActions = React.useCallback(
    (selectedTrigger: TriggerOption, selectedTemplate: TemplateOption) => {
      return match(selectedTrigger.value)
        .with(
          P.union('DataSetRowCreated', 'DataSetRowUpdated'),
          () =>
            [
              {
                actionType: 'CreateWorkflowRun',
                config: {
                  templateId: selectedTemplate.value,
                },
                key: `action1`,
              },
            ] as NativeAutomation.Action[],
        )
        .with('DataSetRowChanged', () => {
          if (!selectedConditionType || !selectedOperandA) throw new Error('Missing condition fields');

          return [
            {
              actionType: 'ConditionalStop',
              key: `action1`,
              config: {
                clause: {
                  conditionType: selectedConditionType.value,
                  operandA: `$.trigger.event.updatedRow.cells.${selectedOperandA.value}.value`,
                  operandB,
                },
              },
            },
            {
              actionType: 'CreateWorkflowRun',
              key: `action2`,
              config: {
                templateId: selectedTemplate.value,
              },
            },
          ] as NativeAutomation.Action[];
        })
        .run();
    },
    [operandB, selectedConditionType, selectedOperandA],
  );

  const createAutomation = React.useCallback(() => {
    if (!selectedTemplate || !selectedTrigger || !selectedSavedView || !dataSetId) return;

    return createNativeAutomationMutation.mutate({
      automationType: 'DataSetWorkflowAutomation',
      actions: getActions(selectedTrigger, selectedTemplate),
      linkedEntity: {
        dataSetId,
        workflowId: selectedTemplate.value,
        savedViewId: selectedSavedView.value,
      },
      trigger: getTrigger(selectedTrigger, selectedSavedView),
    });
  }, [
    selectedTemplate,
    selectedTrigger,
    selectedSavedView,
    dataSetId,
    createNativeAutomationMutation,
    getActions,
    getTrigger,
  ]);

  const updateAutomation = React.useCallback(
    async (nativeAutomationId: Muid) => {
      if (!selectedTemplate || !selectedTrigger || !selectedSavedView || !dataSetId || !selectedAutomationWithLink)
        return;

      try {
        await Promise.all([
          updateNativeAutomationTrigger.mutateAsync({
            nativeAutomationId,
            trigger: getTrigger(selectedTrigger, selectedSavedView),
          }),
          updateNativeAutomationActions.mutateAsync({
            nativeAutomationId,
            actions: getActions(selectedTrigger, selectedTemplate),
          }),
        ]);

        await queryClient.invalidateQueries(GetAllNativeAutomationsQuery.getKey({ dataSetId }));

        toast({
          title: 'Automation updated',
          status: 'success',
        });
      } catch (e) {
        toast({
          status: 'error',
          title: `We're having problems updating the automation`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
        logger.error(e);
      }
    },
    [
      selectedTemplate,
      selectedTrigger,
      selectedSavedView,
      dataSetId,
      selectedAutomationWithLink,
      updateNativeAutomationTrigger,
      getTrigger,
      updateNativeAutomationActions,
      getActions,
      queryClient,
      toast,
    ],
  );

  const validateAutomation = (): boolean => {
    const isAutomationValid = Helpers.isAutomationValid({
      data: nativeAutomationsQuery.data,
      dataSetId,
      selectedTemplate,
      selectedTrigger,
      selectedSavedView,
      selectedAutomationWithLink,
    });

    if (!isAutomationValid) {
      toast({
        title: "There's another automation defined for this data set and workflow.",
        status: 'warning',
      });
    }

    return isAutomationValid;
  };

  const handleSubmit = React.useCallback(() => {
    if (!validateAutomation()) {
      return;
    }

    if (selectedAutomationWithLink) {
      return updateAutomation(selectedAutomationWithLink.automation.id);
    } else {
      return createAutomation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore local validateAutomation
  }, [selectedAutomationWithLink, updateAutomation, createAutomation]);

  const hydrate = React.useCallback(() => {
    if (selectedAutomationWithLink && templatesQuery.data && dataSetQuery.data) {
      const { automation, link } = selectedAutomationWithLink;

      const firstAction = automation.actions.find(action => action.key === 'action1');
      const triggerType: TriggerOption['value'] =
        firstAction?.actionType === 'ConditionalStop'
          ? 'DataSetRowChanged'
          : (automation.trigger.triggerType as TriggerOption['value']);

      setSelectedTrigger({
        value: triggerType,
        label: triggerLabelMap[automation.trigger.triggerType as keyof typeof triggerLabelMap],
      });

      const templateId = getTemplateIdFromAutomation({ automation, link });

      const selectedTemplate = templatesQuery.data.find(template => template.id === templateId);

      if (selectedTemplate)
        setSelectedTemplate({
          value: selectedTemplate.id,
          label: selectedTemplate.name,
        });

      const selectedSavedView = dataSetQuery.data.savedViews?.find(savedView => savedViewId === savedView.id);

      if (selectedSavedView)
        setSelectedSavedView({
          value: selectedSavedView.id,
          label: selectedSavedView.name,
        });

      if (firstAction?.actionType === 'ConditionalStop') {
        const { operandA, operandB, conditionType: condition } = firstAction.config.clause;
        const [_, columnId] = operandA.split('.').reverse();

        setSelectedConditionType({
          value: condition,
          label: conditionLabelsMap[condition],
        });

        const column = dataSetQuery.data.columnDefs.find(col => col.id === columnId);

        if (column) {
          setSelectedOperandA({
            value: column.id,
            label: column.name,
          });
        }

        setOperandB(operandB);
      }
    }
  }, [selectedAutomationWithLink, templatesQuery.data, dataSetQuery.data, savedViewId]);

  const conditionalStopAction = selectedAutomationWithLink?.automation.actions.find(
    action => action.actionType === 'ConditionalStop',
  );

  const clause = match(conditionalStopAction)
    .with({ actionType: 'ConditionalStop' }, action => action.config.clause)
    .otherwise(() => undefined);

  const initialTemplateId = selectedAutomationWithLink
    ? getTemplateIdFromAutomation(selectedAutomationWithLink)
    : undefined;

  const triggerHasChanged = selectedAutomationWithLink?.automation.trigger.triggerType !== selectedTrigger?.value;
  const templateHasChanged = initialTemplateId !== selectedTemplate?.value;
  const savedViewHasChanged = savedViewId !== selectedSavedView?.value;
  const conditionHasChanged = clause?.conditionType !== selectedConditionType?.value;
  const operandAHasChanged = clause?.operandA !== selectedOperandA?.value;
  const operandBHasChanged = clause?.operandB !== operandB;

  const hasUnsavedChanges =
    triggerHasChanged ||
    templateHasChanged ||
    savedViewHasChanged ||
    conditionHasChanged ||
    operandAHasChanged ||
    operandBHasChanged;

  const isLoading =
    createNativeAutomationMutation.isLoading ||
    updateNativeAutomationActions.isLoading ||
    updateNativeAutomationTrigger.isLoading;

  const templatesOptions = React.useMemo(
    () =>
      templatesQuery.data?.map(template => ({
        label: template.name,
        value: template.id,
      })) ?? [],
    [templatesQuery.data],
  );

  const savedViewsOptions: SavedViewOption[] = React.useMemo(
    () =>
      dataSetQuery.data?.savedViews?.map(savedView => ({
        label: savedView.name,
        value: savedView.id,
      })) ?? [],
    [dataSetQuery.data?.savedViews],
  );

  const conditionOptions: ConditionOption[] = React.useMemo(
    () =>
      Object.values(NativeAutomation.ConditionType).map(v => ({
        label: conditionLabelsMap[v],
        value: v,
      })),
    [],
  );

  const columnsOptions: DataSetColumnOption[] = React.useMemo(() => {
    if (!dataSetQuery.data?.savedViews || !selectedSavedView) return [];

    const savedView = dataSetQuery.data.savedViews.find(sv => sv.id === selectedSavedView?.value);

    return (
      savedView?.columns?.map(col => {
        const colDef = dataSetQuery.data?.columnDefs.find(colDef => colDef.id === col.id);

        return {
          label: colDef?.name ?? col.id,
          value: col.id,
        };
      }) ?? []
    );
  }, [dataSetQuery.data?.columnDefs, dataSetQuery.data?.savedViews, selectedSavedView]);

  const triggerOptions: TriggerOption[] = React.useMemo(
    () => [
      { label: triggerLabelMap.DataSetRowCreated, value: 'DataSetRowCreated' },
      { label: triggerLabelMap.DataSetRowUpdated, value: 'DataSetRowUpdated' },
      { label: triggerLabelMap.DataSetRowChanged, value: 'DataSetRowChanged' },
    ],
    [],
  );

  const handleDiscardChanges = React.useCallback(() => {
    hydrate();
  }, [hydrate]);

  const handleTriggerChange = React.useCallback(
    (value: OnChangeValue<SimpleOption, false>) => {
      if (BlvdSelectHelpers.isOptionType<TriggerOption>(value)) {
        setSelectedTrigger(value);

        if (isDataSetsV2Enabled && !selectedSavedView) {
          const [firstSavedView] = dataSetQuery.data?.savedViews ?? [];

          if (firstSavedView) {
            setSelectedSavedView({
              label: firstSavedView.name,
              value: firstSavedView.id,
            });
          }
        }
      }
    },
    [dataSetQuery.data?.savedViews, isDataSetsV2Enabled, selectedSavedView],
  );

  const handleSavedViewChange = React.useCallback((value: OnChangeValue<SimpleOption, false>) => {
    if (BlvdSelectHelpers.isOptionType<SavedViewOption>(value)) {
      setSelectedSavedView(value);
    }
  }, []);

  const handleTemplateChange = React.useCallback((value: OnChangeValue<SimpleOption, false>) => {
    if (BlvdSelectHelpers.isOptionType<TemplateOption>(value)) {
      setSelectedTemplate(value);
    }
  }, []);

  const handleOperandAChange = React.useCallback((value: OnChangeValue<DataSetColumnOption, false>) => {
    if (BlvdSelectHelpers.isOptionType<DataSetColumnOption>(value)) {
      setSelectedOperandA(value);
    }
  }, []);

  const handleConditionTypeChange = React.useCallback((value: OnChangeValue<ConditionOption, false>) => {
    if (BlvdSelectHelpers.isOptionType<ConditionOption>(value)) {
      setSelectedConditionType(value);

      if (
        value.value === NativeAutomation.ConditionType.IsEmpty ||
        value.value === NativeAutomation.ConditionType.IsNotEmpty
      ) {
        setOperandB(undefined);
      }
    }
  }, []);

  const handleOperandBChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setOperandB(e.target.value);
  }, []);

  React.useEffect(() => hydrate(), [hydrate]);

  // Auto select active saved view
  React.useEffect(() => {
    if (dataSetQuery.data && !nativeAutomationId && savedViewIdFromParams && !selectedSavedView) {
      const savedView = dataSetQuery.data.savedViews.find(savedView => savedView.id === savedViewIdFromParams);

      if (savedView) {
        setSelectedSavedView({
          value: savedView.id,
          label: savedView.name,
        });
      }
    }
  }, [dataSetQuery.data, selectedSavedView, savedViewIdFromParams, nativeAutomationId]);

  return React.useMemo(() => {
    return {
      fields: {
        trigger: {
          value: selectedTrigger,
          handler: handleTriggerChange,
        },
        template: {
          value: selectedTemplate,
          handler: handleTemplateChange,
        },
        savedView: {
          value: selectedSavedView,
          handler: handleSavedViewChange,
        },
        operandA: {
          value: selectedOperandA,
          handler: handleOperandAChange,
        },
        conditionType: {
          value: selectedConditionType,
          handler: handleConditionTypeChange,
        },
        operandB: {
          value: operandB,
          handler: handleOperandBChange,
        },
      },
      hasUnsavedChanges,
      handleDiscardChanges,
      handleSubmit,
      templatesOptions,
      savedViewsOptions,
      conditionOptions,
      columnsOptions,
      isLoading,
      nativeAutomation: selectedAutomationWithLink,
      dataSet: dataSetQuery.data,
      triggerOptions,
    };
  }, [
    columnsOptions,
    conditionOptions,
    operandB,
    dataSetQuery.data,
    handleConditionTypeChange,
    handleOperandAChange,
    handleOperandBChange,
    handleDiscardChanges,
    handleSavedViewChange,
    handleSubmit,
    handleTemplateChange,
    handleTriggerChange,
    hasUnsavedChanges,
    isLoading,
    selectedAutomationWithLink,
    savedViewsOptions,
    selectedConditionType,
    selectedOperandA,
    selectedSavedView,
    selectedTemplate,
    selectedTrigger,
    templatesOptions,
    triggerOptions,
  ]);
};

export const triggerLabelMap = {
  DataSetRowCreated: 'When a Data Set record is created',
  DataSetRowUpdated: 'When a Data Set record is updated',
  DataSetRowChanged: 'When a record matches a condition',
};

const conditionLabelsMap: Record<NativeAutomation.ConditionType, string> = {
  Equals: 'Is equal to',
  NotEquals: 'Is not equal to',
  Contains: 'Contains',
  DoesNotContain: 'Does not contain',
  IsNotEmpty: 'Is not empty',
  IsEmpty: 'Is empty',
};
