import { AiTaskTemplateId, Widget } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';

type WidgetAiTasksMap = Record<Widget['id'], AiTaskTemplateId[]>;

export function useOutputWidgetAiTasksMap({ templateRevisionId }: { templateRevisionId?: Muid }): WidgetAiTasksMap {
  const taskTemplateAutomations = GetAllNativeAutomationsQuery.useGetTaskTemplateAutomationsMapQuery({
    templateRevisionId,
  });

  return React.useMemo(
    () => (taskTemplateAutomations.data ? makeWidgetAiTasksMap(taskTemplateAutomations.data) : {}),
    [taskTemplateAutomations.data],
  );
}

export function makeWidgetAiTasksMap(
  automationsMap: GetAllNativeAutomationsQuery.TaskTemplateAutomationMap,
): WidgetAiTasksMap {
  const automations = Object.entries(automationsMap);
  return automations.reduce((acc, [taskTemplateId, nativeAutomations]) => {
    nativeAutomations.forEach(nativeAutomation => {
      nativeAutomation.actions.forEach(action => {
        match(action)
          .with({ actionType: 'UpdateFormFields' }, ({ config: { mapping } }) => {
            const outputWidgetIds = Object.values(mapping);
            outputWidgetIds.forEach(outputWidgetId => {
              if (!acc[outputWidgetId]) acc[outputWidgetId] = [];
              acc[outputWidgetId].push({ taskTemplateId, nativeAutomation });
            });
          })
          .otherwise(() => {});
      });
    });
    return acc;
  }, {} as WidgetAiTasksMap);
}
