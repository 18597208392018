import * as React from 'react';
import { VStack } from 'components/design/next';
import { FormEditorTopBar, makeUIMachine, UIActorProvider, useSharedContext } from '../shared';
import { makeTopBarMachine } from '../shared/form-editor-top-bar/top-bar-machine';
import { useInterpret } from '@xstate/react';
import { FormResponsesPageBody } from './components/form-responses-page-body';

export interface FormResponsesPageProps {}

export const FormResponsesPage: React.FC<React.PropsWithChildren<FormResponsesPageProps>> = () => {
  const sharedContext = useSharedContext();
  const topBarActor = useInterpret(() => makeTopBarMachine({ sharedContext }));
  const uiActor = useInterpret(() => makeUIMachine({ sharedContext }));

  return (
    <UIActorProvider uiActorRef={uiActor}>
      <VStack alignItems="flex-start" spacing="0" w="full" bg="gray.50" h="full">
        <FormEditorTopBar actor={topBarActor} />

        <FormResponsesPageBody />
      </VStack>
    </UIActorProvider>
  );
};
