import { AuditMetadata, Muid, Organization, Ref } from '../core';
import { Task } from './checklist-model';

export enum CommentMethod {
  Task = 'Task',
  Activity = 'Activity',
  Email = 'Email',
}

export enum CommentStatus {
  Active = 'Active',
  Deleted = 'Deleted',
}

export const CommentInboxItemStatus = {
  read: 'Read',
  unread: 'Unread',
} as const;

type Values<T> = T[keyof T];

export interface CommentGroup {
  id: Muid;
}

export interface Comment {
  id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  group: CommentGroup;
  templateId: Muid;
  task: Ref<Task>;
  method: CommentMethod;
  content: string;
  status: CommentStatus;
}
export interface CommentInboxItem {
  id: Muid;
  createdById: Muid;
  createdByUsername: string;
  createdByAvatarUrl?: string;
  createdDate: number;
  status: Values<typeof CommentInboxItemStatus>;
  commentId: Muid;
  commentContent: string;
  canAccessResource: boolean;
  templateId: Muid;
  templateName: string;
  taskTemplateId: Muid;
  taskTemplateGroupId: Muid;
  taskTemplateName?: string;
  checklistId: Muid;
  checklistName?: string;
  checklistRevisionId: Muid;
  taskId: Muid;
  itemType: CommentInboxItemType;
}

export type CommentInboxItemType = 'ProjectTask' | 'AttachedTask' | 'WorkflowTask';

export interface CommentCreationRequest {
  id?: Muid;
  taskId: Muid;
  content: string;
  method: CommentMethod;
}
