import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Text,
  ButtonGroup,
  Button,
} from 'components/design/next';
import { TemplateType } from '@process-street/subgrade/process';

export type DeleteTaskTemplateAlertDialogProps = {
  templateType: TemplateType;
  onConfirm: () => void;
  onCancel: () => void;
};

export const DeleteTaskTemplateAlertDialog: React.FC<React.PropsWithChildren<DeleteTaskTemplateAlertDialogProps>> = ({
  templateType,
  onConfirm,
  onCancel,
}) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const subjectName = templateType === TemplateType.Form ? 'step' : 'task';
  return (
    <AlertDialog isOpen leastDestructiveRef={cancelRef} onClose={onCancel} isCentered>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogCloseButton />
        <AlertDialogHeader>
          <Text variant="2">Delete {subjectName}?</Text>
        </AlertDialogHeader>
        <AlertDialogBody>This {subjectName} and all of its content will be deleted and irrecoverable!</AlertDialogBody>
        <AlertDialogFooter>
          <ButtonGroup>
            <Button onClick={onCancel} ref={cancelRef} variant="ghost" colorScheme="gray">
              Cancel
            </Button>
            <Button colorScheme="red" variant="solid" onClick={onConfirm} ref={React.useRef(null)} ml={3}>
              Delete
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
