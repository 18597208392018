import { Icon, IconButton, IconButtonProps, useDisclosure } from 'components/design/next';
import { DataSet, SavedView } from '@process-street/subgrade/process';
import React from 'react';
import { ShareDataSetModal } from './share-data-set-modal';
import { ShareSavedViewModal } from './share-saved-view-modal';
import { useFeatureFlag } from 'features/feature-flags';
import { NoAccessTooltip } from 'pages/reports/data-sets/components/data-set-page/no-access-tooltip';
import { useSelectedSavedView } from 'pages/reports/data-sets/components/data-set-page/selected-saved-view-id-store';

export type ShareDataSetButtonProps = Partial<IconButtonProps> & {
  dataSetId: DataSet['id'];
  isDisabled: boolean;
  savedViewId?: SavedView['id'];
};

export const ShareDataSetButton: React.FC<React.PropsWithChildren<ShareDataSetButtonProps>> = ({
  dataSetId,
  isDisabled,
  savedViewId,
  ...buttonProps
}) => {
  const shareDataSetDisclosure = useDisclosure();
  const shareSavedViewDisclosure = useDisclosure();
  const { selectedDataSet, selectedSavedView } = useSelectedSavedView();
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');
  const richOutMessage = isDataSetsV2Enabled ? '' : ' Please reach out to the owner or an admin.';
  const toolTipLabel = isDisabled
    ? `You don't have access to edit this saved view.${richOutMessage}`
    : savedViewId
    ? 'Share Saved View'
    : 'Share Data Set';

  if (!selectedDataSet) return null;
  return (
    <>
      <NoAccessTooltip
        label={toolTipLabel}
        contactAdmin={isDataSetsV2Enabled && isDisabled}
        dataSet={selectedDataSet}
        savedView={selectedSavedView}
      >
        <IconButton
          isDisabled={isDisabled}
          onFocus={e => e.preventDefault()}
          variant="ghost"
          colorScheme="gray"
          borderRadius="4px"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.300"
          icon={<Icon color="gray.500" icon="share-alt" size="4" />}
          {...buttonProps}
          onClick={savedViewId ? shareSavedViewDisclosure.onOpen : shareDataSetDisclosure.onOpen}
          aria-label="Share Data Set"
        />
      </NoAccessTooltip>
      <ShareDataSetModal isLoading={false} dataSetId={dataSetId} {...shareDataSetDisclosure} />
      {savedViewId && (
        <ShareSavedViewModal
          isLoading={false}
          dataSetId={dataSetId}
          savedViewId={savedViewId}
          {...shareSavedViewDisclosure}
        />
      )}
    </>
  );
};
