import { Muid } from '@process-street/subgrade/core';
import { Checklist, ChecklistStatus } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateChecklistsStatusMutationParams = {
  checklistIds: Muid[];
  status: ChecklistStatus;
};

export type UpdateChecklistsStatusMutationResponse = Checklist[];

export const UpdateChecklistsStatusMutation = {
  key: ['checklist', 'archive'],
  mutationFn: ({ checklistIds, status }: UpdateChecklistsStatusMutationParams) =>
    axiosService
      .getAxios()
      .patch(`/1/checklists/status/${status}`, {
        ids: checklistIds,
      })
      .then(r => r.data),
};

export const useUpdateChecklistsStatusMutation = (
  options: UseMutationOptions<
    UpdateChecklistsStatusMutationResponse,
    AxiosError,
    UpdateChecklistsStatusMutationParams
  > = {},
) =>
  useMutation(UpdateChecklistsStatusMutation.mutationFn, {
    mutationKey: UpdateChecklistsStatusMutation.key,
    ...options,
  });
