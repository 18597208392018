import * as React from 'react';
import groupBy from 'lodash/groupBy';

import {
  MenuGroup,
  MenuItem,
  MenuList,
  Icon,
  Flex,
  Text,
  MenuListProps,
  HStack,
  Stack,
  Grid,
  GridItem,
  VStack,
  Portal,
  IconProps,
  MenuItemProps,
  LinkProps,
  Link,
  IconButton,
  useDisclosure,
} from 'components/design/next';

import {
  TrayAutomationAppName,
  getTrayAutomationAppDetail,
  TraySolution,
  withTrayPrefix,
} from '@process-street/subgrade/automation';

import { RunMultipleButton, ScheduleWorkflowButton, WorkflowRunLinkButton } from 'components/template-menu-buttons';
import { AutomationAppIcon } from 'features/automations/components/automation-app-icon';
import { useSolutionsByTemplateIdQuery } from 'features/automations/query-builder';
import { useWorkflowAutomationsContext } from '../common/context';

const TriggersModal = React.lazy(() => import('./modal').then(({ TriggersModal }) => ({ default: TriggersModal })));

const PURPLE_ICON_HOVER_FOCUS_PROPS = {
  '& svg': {
    color: 'purple.500',
  },
  'bg': 'gray.100',
};

const TriggerMenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = ({ children, ...props }) => {
  return (
    <MenuItem
      iconSpacing="2"
      _hover={PURPLE_ICON_HOVER_FOCUS_PROPS}
      _focus={PURPLE_ICON_HOVER_FOCUS_PROPS}
      borderRadius="base"
      bg="gray.50"
      w="max-content"
      {...props}
    >
      <Text variant="-1" color="gray.700">
        {children}
      </Text>
    </MenuItem>
  );
};

const AutomationMenuItem: React.FC<
  React.PropsWithChildren<
    Omit<MenuItemProps, 'icon'> & { label: string; description?: string; icon: IconProps['icon'] }
  >
> = ({ label, description, icon, ...props }) => {
  return (
    <MenuItem _hover={PURPLE_ICON_HOVER_FOCUS_PROPS} _focus={PURPLE_ICON_HOVER_FOCUS_PROPS} p="0" {...props}>
      <HStack px={description ? 4 : 2} py="3" bg="gray.50" w="51" borderRadius="base" _hover={{ bg: 'gray.100' }}>
        <Icon variant="far" icon={icon} size="4" color="purple.500" />
        <VStack align="left" pl="2">
          <Text variant="-1" color="gray.700" fontWeight="medium">
            {label}
          </Text>
          {description && (
            <Text variant="-2" color="gray.500">
              {description}
            </Text>
          )}
        </VStack>
      </HStack>
    </MenuItem>
  );
};

const AppMenuItem: React.FC<
  React.PropsWithChildren<MenuItemProps & Partial<LinkProps> & { automationApp: TrayAutomationAppName }>
> = ({ automationApp, ...props }) => {
  return (
    <MenuItem
      _hover={{
        '& svg': {
          color: 'currentColor',
        },
        'bg': 'none',
      }}
      _focus={{
        '& svg': {
          color: 'currentColor',
        },
        'bg': 'none',
      }}
      m="0"
      p="0"
      {...props}
    >
      <HStack
        align="center"
        spacing="2"
        w="full"
        _hover={{
          bg: 'gray.100',
        }}
        _focus={{
          bg: 'gray.100',
        }}
        py="2"
        px="4"
      >
        <Flex
          align="center"
          justify="center"
          h="6"
          w="6"
          bg="white"
          borderColor="gray.100"
          borderRadius="sm"
          borderStyle="solid"
          p="1"
          borderWidth="px"
        >
          <AutomationAppIcon {...{ automationApp }} />
        </Flex>
        <Text variant="-1" fontWeight="normal">
          {getTrayAutomationAppDetail(automationApp).label}
        </Text>
      </HStack>
    </MenuItem>
  );
};

export const TriggerMenuList: React.FC<React.PropsWithChildren<MenuListProps>> = props => {
  const { templateRevision, editable, openSolutionTypeTag, solutionTypeTag } = useWorkflowAutomationsContext();
  const workflowTriggersModal = useDisclosure({ id: 'workflow-triggers-modal' });
  const templateId = templateRevision?.template?.id;

  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const query = useSolutionsByTemplateIdQuery(
    { templateId },
    {
      select: data => {
        const automationApps = groupBy(
          data.filter(s => Boolean(s.automationApp)),
          solution => withTrayPrefix(solution.automationApp),
        ) as Record<TrayAutomationAppName, TraySolution[]>;
        return automationApps;
      },
      enabled: Boolean(templateId),
      staleTime: 1000 * 60 * 60 * 24, //every day
    },
  );

  const handleTabChange = (index: number) => {
    setActiveTabIndex(index);
  };

  return (
    <>
      <Portal>
        <MenuList {...props} zIndex="2">
          <Grid
            templateRows="repeat(4, min-content)"
            templateColumns={{ base: '1fr', sm: 'repeat(2, max-content)' }}
            p="5"
            pb={{ base: '24', sm: '5' }}
            columnGap="4"
            overflowY="auto"
            maxHeight={{ base: '50vh', sm: 'none' }}
          >
            {editable && (
              <>
                <GridItem colSpan={{ base: 1, sm: 2 }}>
                  <Text color="gray.400" variant="-2u" pb="2">
                    Workflow triggers
                  </Text>
                </GridItem>
                <GridItem pb="6" colSpan={{ base: 1, sm: 2 }}>
                  <Stack direction={{ base: 'column', sm: 'row' }} spacing="2" width="full" alignItems="stretch">
                    <WorkflowRunLinkButton>
                      <TriggerMenuItem
                        w={{ base: 'full', sm: 'auto' }}
                        aria-label="run via link"
                        icon={<Icon variant="far" icon="run-link" size="3.5" color="purple.500" />}
                        onClick={() => {
                          setActiveTabIndex(0);
                          workflowTriggersModal.onOpen();
                        }}
                      >
                        Run via link
                      </TriggerMenuItem>
                    </WorkflowRunLinkButton>

                    <TriggerMenuItem
                      aria-label="run via email"
                      w={{ base: 'full', sm: 'auto' }}
                      icon={<Icon variant="far" icon="envelope" size="3.5" color="purple.500" />}
                      onClick={() => {
                        setActiveTabIndex(1);
                        workflowTriggersModal.onOpen();
                      }}
                    >
                      Run via email
                    </TriggerMenuItem>

                    <RunMultipleButton>
                      <TriggerMenuItem
                        w={{ base: 'full', sm: 'auto' }}
                        aria-label="run multiple workflows"
                        icon={<Icon variant="far" icon="forward" size="3.5" color="purple.500" />}
                      >
                        Run multiple
                      </TriggerMenuItem>
                    </RunMultipleButton>
                  </Stack>
                </GridItem>{' '}
              </>
            )}
            <GridItem colSpan={{ base: 1, sm: 2 }}>
              <HStack pb="2">
                <Text color="gray.400" variant="-2u">
                  Automations
                </Text>
                <IconButton
                  as={Link}
                  target="_blank"
                  aria-label="go to automations help article"
                  href="https://www.process.st/help/docs/automations/"
                  variant="ghost"
                  icon={<Icon variant="far" icon="info-circle" size="3" color="gray.500" />}
                  size="4"
                  color="gray.500"
                />
              </HStack>
            </GridItem>
            <GridItem>
              <MenuGroup>
                <VStack bg="gray.50" color="gray.700" borderRadius="base" w="51">
                  {query?.data &&
                    (Object.keys(query.data) as (keyof typeof query.data)[]).map(automationApp => (
                      <AppMenuItem
                        key={automationApp}
                        aria-label={automationApp}
                        onClick={() => {
                          openSolutionTypeTag(solutionTypeTag);
                        }}
                        {...{ automationApp }}
                      />
                    ))}
                </VStack>
              </MenuGroup>
            </GridItem>

            <GridItem mt={{ base: '4', sm: '0' }}>
              <VStack h="full" spacing="3">
                <ScheduleWorkflowButton>
                  <AutomationMenuItem
                    {...{
                      'icon': 'clock',
                      'label': 'Schedule',
                      'description': 'Run on a recurring basis or on a specified date.',
                      'aria-label': 'schedule workflow',
                    }}
                  />
                </ScheduleWorkflowButton>

                <AutomationMenuItem
                  {...{
                    'icon': 'plug',
                    'label': 'API',
                    'description': 'No the need for third-party integration.',
                    'as': Link,
                    'target': '_blank',
                    'variant': 'noline',
                    'href': 'https://developer.process.st/',
                    'aria-label': 'api',
                  }}
                />

                <AutomationMenuItem
                  {...{
                    'icon': 'webhook',
                    'label': 'Webhook',
                    'description': 'Send automated messages to other apps.',
                    'as': Link,
                    'target': '_blank',
                    'variant': 'noline',
                    'href': 'https://www.process.st/help/docs/webhooks/',
                    'aria-label': 'add webhook',
                  }}
                />
              </VStack>
            </GridItem>
            <GridItem colSpan={{ base: 1, sm: 2 }} pt="6">
              <Link
                variant="noline"
                href="https://app.process.st/workflows/Request-an-Automation-uBsXeW4x_nB30-hcvbNBOA/run-link"
                target="_blank"
              >
                <Text variant="-1" color="gray.500" pb="2">
                  Don't see your app?
                </Text>
              </Link>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                color="gray.700"
                borderRadius="base"
                w="full"
                spacing="4"
              >
                <AppMenuItem
                  as={Link}
                  variant="noline"
                  href="https://zapier.com/apps/process-street/integrations"
                  automationApp={withTrayPrefix('Zapier')}
                  target="_blank"
                  bg="gray.50"
                  w="51"
                  _hover={{ color: 'gray.700' }}
                />
                <AppMenuItem
                  as={Link}
                  variant="noline"
                  href="https://www.process.st/help/docs/microsoft-power-automate/"
                  automationApp={withTrayPrefix('PowerAutomate')}
                  target="_blank"
                  bg="gray.50"
                  w="51"
                  _hover={{ color: 'gray.700' }}
                />
              </Stack>
            </GridItem>
          </Grid>
        </MenuList>
      </Portal>

      <React.Suspense fallback="">
        <TriggersModal
          {...{
            ...workflowTriggersModal,
            templateRevision,
            activeTabIndex,
            onTabChange: handleTabChange,
          }}
        />
      </React.Suspense>
    </>
  );
};
