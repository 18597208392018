import { Muid, OrganizationUsageStats } from '@process-street/subgrade/core';
import { AxiosError, AxiosResponse } from 'axios';
import { axiosService } from 'services/axios-service';

export type CanUseResponse = (OrganizationUsageStats & { canUse: false }) | { canUse: true };

const canUseOrganization = async (organizationId: Muid): Promise<CanUseResponse> => {
  return axiosService
    .getAxios()
    .get(`/1/organizations/${organizationId}/can-use`)
    .then(
      () => ({ canUse: true }),
      (error: AxiosError) => {
        if (error.response && error.response.status === 402) {
          return { ...error.response.data, canUse: false };
        } else {
          return { canUse: true };
        }
      },
    );
};

const getOrganizationUsageStats = async (organizationId: Muid): Promise<OrganizationUsageStats> => {
  return axiosService
    .getAxios()
    .get(`/1/organizations/${organizationId}/usage-stats`)
    .then((response: AxiosResponse) => response.data);
};

const getUserSettings = async (userId: Muid): Promise<any> => {
  return axiosService
    .getAxios()
    .get(`/1/users/${userId}/settings`)
    .then((response: AxiosResponse) => response.data);
};

/**
 * Note you can't update nested objects via a JSON path eg. 'a.b.c' with this API.
 * Only the root keys eg. 'onboarding'.
 */
const updateUserSettings = async (userId: Muid, key: string, value: Record<string, unknown>): Promise<void> => {
  return axiosService.getAxios().patch(`/1/users/${userId}/settings/${key}`, {
    value,
  });
};

export const API = {
  canUseOrganization,
  getOrganizationUsageStats,
  getUserSettings,
  updateUserSettings,
};
