import { Muid, Option } from '@process-street/subgrade/core';
import { BaseApprovalRuleSelector } from '@process-street/subgrade/redux/selector/approval-rule.selectors';
import { ApprovalRuleSubjectStatuses, ReduxAppState, Status } from 'reducers/types';
import { createSelector, Selector } from 'reselect';

const getStatuses = (state: ReduxAppState): ApprovalRuleSubjectStatuses => state.statuses.approvalRuleSubject;

const getStatusByTemplateRevisionId = (templateRevisionId: Muid): Selector<ReduxAppState, Option<Status>> =>
  createSelector(getStatuses, status => status.byTemplateRevisionId[templateRevisionId]);

const getLoadedStatusByTemplateRevisionId = (templateRevisionId: Muid): Selector<ReduxAppState, boolean> =>
  createSelector(getStatusByTemplateRevisionId(templateRevisionId), (status: Option<Status>) => {
    if (!status) {
      return false;
    }
    return status.loaded;
  });

export const ApprovalRuleSelector = {
  getAllByTaskTemplateGroupIds: BaseApprovalRuleSelector.getAllByTaskTemplateGroupIds,
  getAllByTemplateRevisionId: BaseApprovalRuleSelector.getAllByTemplateRevisionId,
  getById: BaseApprovalRuleSelector.getById,
  getLoadedStatusByTemplateRevisionId,
};
