import angular from 'angular';
import { WidgetConstants } from '@process-street/subgrade/process/widget-constants';
import 'blueimp-file-upload/js/vendor/jquery.ui.widget';
import 'blueimp-file-upload/js/jquery.iframe-transport';
import 'blueimp-file-upload/js/jquery.fileupload-process';
import 'blueimp-file-upload/js/jquery.fileupload-validate';
import 'blueimp-file-upload/js/jquery.fileupload';

angular
  .module('frontStreetApp.directives')
  .directive('psFileUpload', ($parse, $timeout, $window, jQuery, FileUploadService, ToastService) => ({
    restrict: 'A',
    link(scope, element, attributes) {
      scope.widgetId = scope.$eval(attributes.widgetId);
      const acceptTypes = scope.$eval(attributes.fileAcceptTypes);
      const multipart = scope.$eval(attributes.multipart) || true;
      const maxFileSize = scope.$eval(attributes.fileMaxSize);
      const addCallback = $parse(attributes.fileAdd);
      const doneCallback = $parse(attributes.fileDone);
      const failCallback = $parse(attributes.fileFail);
      const dropCallback = $parse(attributes.fileDrop);
      const progressCallback = $parse(attributes.fileProgress);
      const processDoneCallback = $parse(attributes.fileProcessDone);
      const processFailCallback = $parse(attributes.fileProcessFail);
      const dropzone = attributes.fileDropzone ? jQuery(attributes.fileDropzone) : null;
      const pastezone = attributes.filePastezone ? jQuery(attributes.filePastezone) : null;

      scope.uploadImageUrlCallback = $parse(attributes.uploadImageUrl);
      scope.uploadFileUrlCallback = $parse(attributes.uploadFileUrl);

      scope.setStats = (progress, uploading) => {
        if (scope.widgetId) {
          const stats = {
            id: scope.widgetId,
            progress,
            uploading,
          };
          FileUploadService.setUploadStats(stats);
        }
      };

      scope.progressHandler = (event, data) => {
        const progress = parseInt((data.loaded / data.total) * 100, 10);
        scope.setStats(progress, true);

        if (progressCallback) {
          $timeout(() => {
            progressCallback(scope, { $event: event, $data: data });
          });
        }
      };

      scope.doneHandler = (event, data) => {
        scope.setStats(100, true);

        if (doneCallback) {
          $timeout(() => {
            doneCallback(scope, { $event: event, $data: data });
          });
        }
      };

      scope.failHandler = (event, data) => {
        scope.setStats(0, false);

        if (failCallback) {
          $timeout(() => {
            failCallback(scope, { $event: event, $data: data });
          });
        }
      };

      const wrap = function (callback) {
        return function (event, data) {
          $timeout(() => {
            callback(scope, { $event: event, $data: data });
          });
        };
      };

      const wrapAdd = function (callback) {
        return function (event, data) {
          $timeout(() => {
            callback(scope, { $event: event, $data: data });
          });
        };
      };

      element.fileupload({
        multipart,
        orientation: true,
        acceptFileTypes: acceptTypes,
        maxFileSize: maxFileSize || WidgetConstants.MAX_FILE_SIZE,
        add: wrapAdd(addCallback),
        done: scope.doneHandler,
        fail: scope.failHandler,
        drop: wrap(dropCallback),
        progress: scope.progressHandler,
        processdone: wrap(processDoneCallback),
        processfail: wrap(processFailCallback),
        dropZone: dropzone,
        pasteZone: jQuery(), // Don't auto-listen to paste events
      });

      /**
       * Check if provided string has content in base64 format and parse it
       *
       * @param str provided string
       * @returns {*} Return parsed content(data, type, size) or false if not
       */
      scope.parseBase64 = function (str) {
        const b64 = str.split(',');
        if (b64.length !== 2 || !/base64$/.test(b64[0])) {
          return false;
        }
        return {
          type: b64[0].replace(/^data:/, '').replace(/;base64$/, ''),
          data: b64[1],
          size: b64[1].length * (6 / 8), // bytes (each char represents 6 bits, byte is 8 bits)
        };
      };

      /**
       * Check if provided string is URL
       *
       * @param str provided string
       * @returns {*} Return parsed URL(href, fileName, ext) or false if not
       */
      scope.parseUrl = function (str) {
        if (!str) {
          return false;
        }

        const parser = $window.document.createElement('input');
        parser.setAttribute('type', 'url');
        parser.value = str;

        if (parser.value && /^http(s?):.*$/.test(parser.value)) {
          const fileAttributes = scope.getFileAttributesByUrl(parser.value);

          return {
            href: parser.value,
            fileName: fileAttributes.fileName,
            ext: fileAttributes.ext,
          };
        }

        return false;
      };

      scope.addBase64ContentToFileUploader = function (base64, fileName, type) {
        // decode base64 string, remove space for IE compatibility
        const binary = $window.atob(base64.replace(/\s/g, ''));
        const len = binary.length;
        const contentBuffer = new ArrayBuffer(len);
        const view = new Uint8Array(contentBuffer);
        for (let i = 0; i < len; i += 1) {
          view[i] = binary.charCodeAt(i);
        }
        const blob = new $window.Blob([view], { type });
        blob.name = fileName;
        $timeout(() => {
          element.fileupload('add', { files: [blob] });
        });
      };

      scope.createBuffer = function () {
        const buffer = $window.document.createElement('div');
        $window.document.body.appendChild(buffer);
        buffer.style.width = '1px';
        buffer.style.height = '1px';
        buffer.style.opacity = 0;
        buffer.style.position = 'absolute';
        buffer.style.zIndex = 2000;
        buffer.overflow = 'hidden';
        buffer.setAttribute('buffer', 'true');

        return buffer;
      };

      scope.getFileAttributesByUrl = function (url) {
        const parts = url.split('/');
        const fullFileName = parts[parts.length - 1];
        const fileNameParts = fullFileName.split('.');
        const { length } = fileNameParts;
        const ext = length > 1 ? fileNameParts[length - 1].toLowerCase() : false;

        return {
          fileName: fullFileName,
          ext,
        };
      };

      scope.readBlobContent = function (url, callback, outputFormat) {
        const img = new $window.Image();
        img.crossOrigin = 'anonymous';
        img.onload = function () {
          let canvas = $window.document.createElement('CANVAS');
          const ctx = canvas.getContext('2d');
          canvas.height = this.height;
          canvas.width = this.width;
          ctx.drawImage(this, 0, 0);
          try {
            callback(canvas.toDataURL(outputFormat, 0.5));
          } catch (error) {
            // do nothing
          }
          canvas = null;
        };
        img.src = url;
      };

      function isEditableElement() {
        const elem = $window.document.activeElement;
        const tagName = elem.tagName.toLowerCase();

        return (
          elem &&
          (elem.getAttribute('contenteditable') || tagName === 'input' || tagName === 'textarea') &&
          !elem.getAttribute('buffer')
        );
      }

      scope.isImage = function (fileExt) {
        return WidgetConstants.IMAGE_MIME_TYPES.test(`.${fileExt}`);
      };

      scope.isDocument = function (fileExt) {
        return fileExt && WidgetConstants.DOCUMENT_MIME_TYPES.test(`.${fileExt}`);
      };

      scope.showFileSizeExceededMessage = function () {
        ToastService.openToast({
          status: 'warning',
          title: `We couldn't upload the file`,
          description: 'File size exceeded. You can only upload files smaller than 250 MB.',
        });
      };

      scope.uploadFileInBase64 = function (content, fileName) {
        const b64 = scope.parseBase64(content);
        if (b64) {
          if (b64.size > WidgetConstants.MAX_FILE_SIZE) {
            scope.showFileSizeExceededMessage();
          } else if (scope.isImage(b64.type.split('/')[1])) {
            scope.addBase64ContentToFileUploader(b64.data, fileName, b64.type);
          }
        }
      };

      scope.uploadByUrl = function (url, fileExt) {
        if (scope.isImage(fileExt)) {
          wrap(scope.uploadImageUrlCallback)(url);
        } else if (scope.isDocument(fileExt)) {
          wrap(scope.uploadFileUrlCallback)(url);
        }
      };

      if (pastezone) {
        const pasteEl = pastezone.get(0);
        const objectBuffer = scope.createBuffer();
        let textBuffer = '';

        const getObjectBufferElement = function () {
          if (objectBuffer.querySelector('img')) {
            return objectBuffer.querySelector('img');
          } else if (objectBuffer.querySelector('a')) {
            return objectBuffer.querySelector('a');
          }
          return null;
        };

        const uploadHandler = function (data) {
          textBuffer = typeof data !== 'undefined' ? data : textBuffer;
          if (textBuffer) {
            objectBuffer.innerHTML = textBuffer;
          }
          objectBuffer.removeAttribute('contenteditable');

          const child = getObjectBufferElement();
          if (child && child.tagName === 'IMG') {
            // paste like an "IMG" element
            const fileAttrs = scope.getFileAttributesByUrl(child.src);

            if (fileAttrs.fileName && fileAttrs.ext) {
              scope.uploadByUrl(child.src, fileAttrs.ext);
            } else {
              scope.readBlobContent(child.src, content => {
                scope.uploadFileInBase64(content, fileAttrs.fileName);
              });
            }
          } else {
            // paste like an "A" element or content
            const bufferContent = objectBuffer.innerHTML.trim();
            const linkElement = child && child.tagName === 'A';
            const url = linkElement ? scope.parseUrl(child.href) : scope.parseUrl(bufferContent);

            if (url) {
              scope.uploadByUrl(url.href, url.ext);
            } else {
              scope.uploadFileInBase64(bufferContent, 'unnamed');
            }
          }

          objectBuffer.innerHTML = '';
          textBuffer = '';
        };

        const isFile = function (items) {
          for (let i = 0; i < items.length; i += 1) {
            if (items[i].kind === 'file') {
              return true;
            }
          }
          return false;
        };

        const pastEventListener = function (e) {
          if (isEditableElement()) {
            return;
          }

          const clipboardData = e.clipboardData || e.originalEvent.clipboardData;
          const itemsExist = clipboardData && clipboardData.items && clipboardData.items.length;
          if (itemsExist && isFile(clipboardData.items)) {
            const images = [];
            const { items } = clipboardData;
            for (let i = 0; i < items.length; i += 1) {
              if (items[i].kind === 'file') {
                // array has few elements, so prevent event only once
                if (!i) {
                  e.preventDefault();
                }
                images.push(items[i].getAsFile());
              }
            }
            element.fileupload('add', { files: images });
          } else if (itemsExist && clipboardData.items[0].kind === 'string') {
            clipboardData.items[0].getAsString(uploadHandler);
          } else {
            objectBuffer.innerHTML = '';
            objectBuffer.setAttribute('contenteditable', 'true');
            objectBuffer.focus();
          }
        };
        pasteEl.addEventListener('paste', pastEventListener);

        const KEY_CODE_V = 86;
        const KEY_CODE_COMMAND = 91;
        const KEY_CODE_CTRL = 17;
        const KEY_CODE_CTRL_V = 224;
        const keyCodes = [KEY_CODE_V, KEY_CODE_CTRL, KEY_CODE_COMMAND, KEY_CODE_CTRL_V];

        const keydownEventListener = function (e) {
          if (isEditableElement()) {
            return;
          }

          // mac OS command and windows control keys or 'V'
          if (keyCodes.indexOf(e.which) >= 0 && !textBuffer) {
            objectBuffer.setAttribute('contenteditable', 'true');
            objectBuffer.focus();
          }
          // If we clicked command `Ctrl(Cmd) + V` few times
          if (textBuffer === '') {
            textBuffer = objectBuffer.innerHTML.trim().replace('<br>', ''); // Replace for IE only
          } else {
            objectBuffer.innerHTML = textBuffer;
          }
        };
        pasteEl.addEventListener('keydown', keydownEventListener);

        const keyupEventListener = function (e) {
          if (isEditableElement()) {
            return;
          }

          if (keyCodes.indexOf(e.which) >= 0) {
            uploadHandler();
          }
        };
        objectBuffer.addEventListener('keyup', keyupEventListener);

        scope.$on('$destroy', () => {
          pasteEl.removeEventListener('paste', pastEventListener);
          pasteEl.removeEventListener('keydown', keydownEventListener);
          objectBuffer.removeEventListener('keyup', keyupEventListener);
          $window.document.body.removeChild(objectBuffer);
        });
      }
    },
  }));
