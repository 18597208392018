import { OrganizationMembershipRole, SsoLoginType } from '@process-street/subgrade/core';
import angular from 'angular';
import { createSelector } from 'reselect';
import { AuthConnectionSelector } from 'components/auth-connection/store/auth-connection.selectors';
import { AuthConnectionActions } from 'components/auth-connection/store/auth-connection.actions';
import { features } from 'services/features/features';
import './_settings.scss';
import { queryClient } from 'components/react-root';
import { GetOrganizationTheme } from 'features/brand-customization/query-builder/get-organization-theme';
import { CreateOrganizationTheme } from 'features/brand-customization/query-builder/create-organization-theme';
import { IntercomService } from 'services/interop/intercom-service';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { industries } from 'app/industries';
import { trace } from 'components/trace';
import { AnalyticsService } from 'components/analytics/analytics.service';

// WARNING: this controller is used by the integrations tab
angular
  .module('frontStreetApp.controllers')
  .controller(
    'OrganizationManageSettingsCtrl',
    function (
      $ngRedux,
      $scope,
      $state,
      $q,
      MessageBox,
      OrganizationDomainService,
      OrganizationService,
      SecurityService,
      SessionService,
      UserService,
      ToastService,
    ) {
      'ngInject';
      const ctrl = this;

      const logger = trace({ name: 'OrganizationManageSettingsCtrl' });
      logger.info('loading ctrl');

      const mapStateToThis = organizationId =>
        createSelector([AuthConnectionSelector.getAllByOrganizationId(organizationId)], authConnections => {
          return {
            authConnections,
          };
        });

      const mapDispatchToThis = () => ({
        getAllAuthConnectionByOrganizationId: AuthConnectionActions.getAllByOrganizationId,
      });

      $scope.connectToRedux = organizationId => {
        if ($scope.unsubscribe) {
          $scope.unsubscribe();
        }
        $scope.unsubscribe = $ngRedux.connect(mapStateToThis(organizationId), mapDispatchToThis)($scope);
      };

      $scope.$on('$destroy', () => {
        $scope.unsubscribe?.();
      });

      ctrl.$onInit = () => {
        $scope.industries = industries;
        $scope.currentPlan = SessionService.getCurrentPlan();
        $scope.isThemeLoading = false;
        $scope.userInfo = SessionService.getUserInfo();
        $scope.isAdmin = $scope.userInfo?.organizationMembership?.role === OrganizationMembershipRole.Admin;

        $scope.selectedOrganization = SessionService.getSelectedOrganization();
        $scope.brandingFeatureIsAvailable = features.canAccess(
          features.Feature.BRANDING,
          $scope.selectedOrganization.subscription.plan.id,
        );
        $scope.brandColorFeatureIsAvailable = features.canAccess(
          features.Feature.BRAND_COLOR,
          $scope.selectedOrganization.subscription.plan.id,
        );
        $scope.info = {
          name: $scope.selectedOrganization.name,
          industry: $scope.selectedOrganization.industry,
        };

        $scope.theme = {
          brandColor: '',
        };

        $scope._retrieveOrganizationDomains($scope.selectedOrganization.id);
        $scope._retrieveUserLinkableDomains($scope.selectedOrganization.id);
        $scope._initializeUser();

        $scope.connectToRedux($scope.selectedOrganization.id);
        $scope._retrieveAuthConnections($scope.selectedOrganization.id);

        $scope.loadBrandTheme();
      };

      $scope._initializeUser = function () {
        if (SecurityService.isAuthenticatedFully()) {
          $scope.user = SessionService.getUser();
          $scope.userIsDeveloper = $scope.user.developer;
        } else {
          $state.go('login', { url: $state.href('organizationManage') });
        }
      };

      // Settings

      $scope.updateSettings = function (organization, info, theme) {
        if (!$scope.form.$invalid) {
          $scope.updating = true;

          OrganizationService.update(organization, info)
            .then(() => {
              $scope.form.$setPristine();
            })
            .finally(() => {
              $scope.updating = false;
            });

          $scope.updateTheme(organization, theme);
        } else {
          let message;
          if ($scope.form.name.$error.required) {
            message = 'You must enter an organization name.';
          }
          if ($scope.form.name.$error.maxlength) {
            message = 'Your organization name is too long.';
          }

          ToastService.openToast({
            status: 'warning',
            title: `We couldn't update the organization name`,
            description: message,
          });
        }
      };

      // Brand customization

      $scope.updateBrandColor = function (color) {
        if (color === $scope.theme.brandColor) return;

        $scope.form.$setDirty();
        $scope.theme.brandColor = color;
        $scope.$apply();
      };

      $scope.handleBrandColorError = function () {
        $scope.form.$setValidity('brandColor', false);
      };

      $scope.handleBrandColorValid = function () {
        $scope.form.$setValidity('brandColor', true);
      };

      $scope.updateTheme = function (organization, theme) {
        queryClient.executeMutation({
          mutationFn: CreateOrganizationTheme.mutationFn,
          variables: {
            organizationId: organization.id,
            brandColor: theme.brandColor || null,
          },
          onSuccess: () => {
            queryClient.invalidateQueries(GetOrganizationTheme.getKey({ organizationId: organization.id }));
          },
        });
      };

      $scope.loadBrandTheme = function () {
        if (!$scope.selectedOrganization.id) return;
        $scope.isThemeLoading = true;
        const variables = { organizationId: $scope.selectedOrganization.id };

        $q((resolve, reject) =>
          queryClient
            .fetchQuery(GetOrganizationTheme.getKey(variables), () => GetOrganizationTheme.queryFn(variables))
            .then(theme => resolve(theme))
            .catch(e => reject(e)),
        )
          .then(theme => {
            $scope.theme = theme;
          })
          .finally(() => {
            $scope.isThemeLoading = false;
          });
      };

      $scope.showSupport = function () {
        AnalyticsService.trackEvent('support link clicked', { location: 'organization settings tab' });
        logger.info('support link clicked');
        IntercomService.show();
      };

      $scope.ssoEnabled = false;
      $scope.requiredSsoLoginType = SsoLoginType.Optional;
      $scope.organizationDomains = [];

      $scope._retrieveOrganizationDomains = function (organizationId) {
        OrganizationDomainService.getAllByOrganizationId(organizationId).then(organizationDomains => {
          $scope.organizationDomains = organizationDomains;
        });
      };

      $scope.userLinkableDomains = [];

      $scope._retrieveUserLinkableDomains = function (organizationId) {
        UserService.getUserLinkableDomains(organizationId).then(domains => {
          $scope.userLinkableDomains = domains;
        });
      };

      $scope.shouldShowDomains = function () {
        return $scope.organizationDomains.length > 0 || $scope.userLinkableDomains.length > 0;
      };

      $scope.addDomain = function (organization, organizationDomain) {
        return OrganizationDomainService.createByOrganizationIdAndName(organization.id, organizationDomain.name);
      };

      $scope.deleteDomain = function (organization, organizationDomain) {
        $scope.deletingDomain = true;

        return OrganizationDomainService.deleteByOrganizationIdAndName(organization.id, organizationDomain.name).then(
          deletedOrganizationDomain => {
            $scope.organizationDomains = $scope.organizationDomains.filter(
              od => od.id !== deletedOrganizationDomain.id,
            );

            ToastService.openToast({
              status: 'success',
              title: `Domain deleted`,
            });

            $scope.deletingDomain = false;
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems deleting the domain`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });

            $scope.deletingDomain = false;
            $scope.organizationDomains.push(organizationDomain);
          },
        );
      };

      $scope.showCopyConfirmation = () => {
        ToastService.openToast({
          status: 'success',
          title: `Copied to clipboard!`,
        });
      };

      $scope._retrieveAuthConnections = organizationId => {
        const allAuthConnectionByOrganizationId = $scope.getAllAuthConnectionByOrganizationId(organizationId);
        $ngRedux.dispatch(allAuthConnectionByOrganizationId);
        $scope.$watch('authConnections', authConnections => {
          $scope._setSsoVisibilityFlags(authConnections);
        });
      };

      $scope._setSsoVisibilityFlags = authConnections => {
        $scope.ssoEnabled = authConnections && authConnections.length > 0;
        if ($scope.ssoEnabled) {
          $scope.requiredSsoLoginType = $scope.authConnections[0].ssoLogin;
        }
      };
    },
  );
