import { Organization } from '@process-street/subgrade/core';
import * as React from 'react';
import { canAccess, Feature } from 'services/features/features';
import { Avatar } from 'components/design/next';
import { EventName } from 'services/event-name';
import { useInjector } from 'components/injection-provider';

type Props = { organization?: Organization };

export const OrganizationLogo: React.FC<React.PropsWithChildren<Props>> = ({ organization, children }) => {
  const [file, setFile] = React.useState(organization?.logoFile?.url);

  const { $rootScope } = useInjector('$rootScope');

  /* Keeping this listener because it's triggered from a few points:
  - billing-service
  - tools-ctr
  - logo-uploader
  */

  React.useEffect(() => {
    const unsubscribe = $rootScope.$on(EventName.ORGANIZATION_UPDATED, (_event, org: Organization) => {
      if (org.id === organization?.id) setFile(org.logoFile?.url);
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- subscribe for $rootScope
  }, [$rootScope]);

  React.useEffect(() => {
    setFile(organization?.logoFile?.url);
  }, [organization?.logoFile?.url]);

  const planId = organization?.subscription.plan.id;
  const featureIsAvailable = canAccess(Feature.BRANDING, planId);
  const shouldShowOrganizationLogo = featureIsAvailable && file;

  return organization && file && shouldShowOrganizationLogo ? (
    <Avatar name={organization.name} src={file} size="sm" border="none" getInitials={() => ''} bg="white" />
  ) : (
    <>{children}</>
  );
};
