import React from 'react';
import { useBalloonToolbarControls } from '../context';

export const useToolbarMenuButtonOnMouseDown = (
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement> | undefined,
) => {
  const { setForcedState } = useBalloonToolbarControls();
  return React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setForcedState(s => (s === 'open' ? 'none' : 'open'));
      onMouseDown?.(e);
    },
    [onMouseDown, setForcedState],
  );
};
