import {
  DateFormFieldValue,
  FieldType,
  FormFieldValue,
  FormFieldWidget,
  MultiChoiceFormFieldValue,
  MultiChoiceFormFieldWidget,
  MultiChoiceItemValueStatus,
  SendRichEmailFormFieldValue,
} from '@process-street/subgrade/process';
import { dayjs } from '@process-street/subgrade/util';
import { ArrayService } from 'services/array-service';
import { trace } from 'components/trace';
import { match, P } from 'ts-pattern';
import { Dayjs } from 'dayjs';

const logger = trace({ name: 'FormFieldValueExtractor' });

const extract = (formFieldValue: FormFieldValue, formFieldWidget: FormFieldWidget) => {
  return match({ widget: formFieldWidget, value: formFieldValue })
    .with(
      { widget: { fieldType: FieldType.MultiChoice }, value: { fieldValue: { itemValues: P.not(P.nullish) } } },
      ({ widget, value }) => extractSelectedItemNamesFromMultiChoice(value, widget),
    )
    .with(
      {
        widget: { fieldType: FieldType.Date },
        value: { fieldValue: { value: P.number } },
      },
      ({ value }) => extractDateFromDate(value),
    )
    .with(
      { widget: { fieldType: FieldType.SendRichEmail }, value: { fieldValue: { lastSentDate: P.number } } },
      ({ value }) => extractDateFromSendRichEmail(value),
    )
    .otherwise(() => null);
};

const extractSelectedItemNamesFromMultiChoice = (
  formFieldValue: MultiChoiceFormFieldValue,
  formFieldWidget: MultiChoiceFormFieldWidget,
) => {
  let selectedItemNames: string[] = [];

  if (formFieldWidget.fieldType === FieldType.MultiChoice) {
    if (formFieldValue && formFieldValue.fieldValue && formFieldValue.fieldValue.itemValues) {
      const selectedItemIds = formFieldValue.fieldValue.itemValues
        .filter(itm => itm.status === MultiChoiceItemValueStatus.Selected)
        .map(itm => itm.id);

      selectedItemNames = formFieldWidget.config.items
        .filter(itm => ArrayService.includes(selectedItemIds, itm.id))
        .map(itm => itm.name);
    }
  } else {
    logger.error('could not extract selected items from not multi-choice widget');
  }

  return selectedItemNames;
};

const extractDateFromDate = (formFieldValue: DateFormFieldValue): Dayjs | null => {
  const value = formFieldValue?.fieldValue?.value;
  const parsed = typeof value === 'number' ? value : parseInt(value ?? '0');
  return isNaN(parsed) ? null : dayjs(value);
};

const extractDateFromSendRichEmail = (formFieldValue: SendRichEmailFormFieldValue): Dayjs | null => {
  const value = formFieldValue?.fieldValue?.lastSentDate;
  const parsed = typeof value === 'number' ? value : parseInt(value ?? '0');
  return isNaN(parsed) ? null : dayjs(parsed);
};

export const FormFieldValueExtractor = {
  extract,
  extractSelectedItemNamesFromMultiChoice,
  extractDateFromDate,
  extractDateFromSendRichEmail,
};
