import { isHeading, TaskTemplateTaskType } from '@process-street/subgrade/process';
import { AnalyticsConstants } from '@process-street/subgrade/analytics/analytics-constants';
import angular from 'angular';
import { connectService } from 'reducers/util';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { queryClient } from 'components/react-root';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.services')
  .service(
    'TaskTemplateService',
    function ($ngRedux, $rootScope, SessionService, TaskTemplateActions, util, ToastService) {
      const logger = trace({ name: 'TaskTemplateService' });

      connectService('TaskTemplateService', $ngRedux, undefined, TaskTemplateActions)(this);

      const self = this;

      self.DEFAULT_NAME = '<unnamed task>';

      const taskTemplateComparer = util.getOrderableComparer();
      self.sortTaskTemplates = function (taskTemplates) {
        taskTemplates.sort(taskTemplateComparer);
      };

      self.getTaskTemplatesSorted = function (taskTemplates) {
        return taskTemplates.sort(taskTemplateComparer);
      };

      self.getTaskTemplates = (templateRevisionId, options = { flushCache: false }) =>
        self.actions
          .getAllByTemplateRevisionId(templateRevisionId, options?.flushCache ?? false)
          .then(
            ({ payload: taskTemplates }) => taskTemplates,
            response => {
              logger.error('Could not get task templates by template revision id: %s', templateRevisionId);
              ToastService.openToast({
                status: 'error',
                title: `We're having problems loading the tasks`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
              return Promise.reject(response);
            },
          )
          .then(retrievedTaskTemplates => {
            self.sortTaskTemplates(retrievedTaskTemplates);
            // shallow copy task template list coming from React Query
            // Angular may mutate queried items
            return retrievedTaskTemplates.map(tt => ({ ...tt }));
          });

      self.getTaskTemplatesByChecklistRevision = checklistRevision =>
        this.actions
          .getAllByChecklistRevisionId(checklistRevision.id)
          .then(
            ({ payload: taskTemplates }) => taskTemplates,
            response => {
              logger.error('Could not get task templates by checklist revision id: %s', checklistRevision.id);
              ToastService.openToast({
                status: 'error',
                title: `We're having problems loading the tasks`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
              return Promise.reject(response);
            },
          )
          .then(retrievedTaskTemplates => {
            self.sortTaskTemplates(retrievedTaskTemplates);
            return retrievedTaskTemplates;
          });

      self.updateAllHiddenByDefaultByTemplateRevisionId = (templateRevisionId, hiddenByDefaultModels) =>
        self.actions.updateAllHiddenByDefaultByTemplateRevisionId(templateRevisionId, hiddenByDefaultModels);

      self.updateDueOffset = function (id, dueOffset) {
        return self.actions.updateDueOffset(id, dueOffset).then(({ payload }) => payload);
      };

      self.updateAllDueOffset = function (taskTemplates, dueOffset) {
        const taskTemplateIds = taskTemplates.map(taskTemplate => taskTemplate.id);

        return self.actions.updateAllDueOffset(taskTemplateIds, dueOffset).then(
          ({ payload: response }) => {
            AnalyticsService.trackEvent('task templates due offset updated');

            return response;
          },
          response => {
            return Promise.reject(response);
          },
        );
      };

      self.updateAllStop = function (taskTemplates, stop) {
        const taskTemplateIds = taskTemplates.map(taskTemplate => taskTemplate.id);

        return self.actions.updateAllStop(taskTemplateIds, stop).then(
          ({ payload: response }) => {
            AnalyticsService.trackEvent(AnalyticsConstants.Event.STOP_TASK_ADDED_TO_TASK, {
              amount: 'many',
            });
            return response;
          },
          response => {
            return Promise.reject(response);
          },
        );
      };

      self.getAllByTemplateRevisionId = templateRevisionId => self.getTaskTemplates(templateRevisionId);

      self.isHeading = isHeading;

      self.isApproval = function (taskTemplate) {
        return taskTemplate && taskTemplate.taskType === TaskTemplateTaskType.Approval;
      };

      self.deleteAll = function (taskTemplatesToDelete) {
        $rootScope.$broadcast(EventName.TASK_TEMPLATE_BULK_DELETE_STARTED, taskTemplatesToDelete);

        const taskTemplateIds = taskTemplatesToDelete.map(taskTemplate => taskTemplate.id);

        return self.actions
          .deleteAllByIds(taskTemplateIds)
          .then(
            response => {
              $rootScope.$broadcast(EventName.TASK_TEMPLATE_BULK_DELETE_OK, taskTemplatesToDelete);

              queryClient.invalidateQueries(
                GetAllNativeAutomationsQuery.getKey({
                  templateRevisionId: taskTemplatesToDelete[0].templateRevision.id,
                }),
              );

              AnalyticsService.trackEvent('task templates deleted');

              return response;
            },
            response => {
              $rootScope.$broadcast(EventName.TASK_TEMPLATE_BULK_DELETE_FAILED, taskTemplatesToDelete);

              taskTemplatesToDelete.forEach(taskTemplate => {
                taskTemplate._deleteFailed = true;
              });

              ToastService.openToast({
                status: 'error',
                title: `We're having problems deleting the tasks`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });

              return Promise.reject(response);
            },
          )
          .finally(() => {
            $rootScope.$broadcast(EventName.TASK_TEMPLATE_BULK_DELETE_FINISHED, taskTemplatesToDelete);
          });
      };

      /**
       * Updates order trees for a list of task templates.
       *
       * @param taskTemplatesToUpdate
       * @param newOrderTreeMap - This is a map from task template id to order tree
       * @returns {*}
       */
      self.updateOrderTrees = function (taskTemplatesToUpdate, newOrderTreeMap) {
        const orderModels = taskTemplatesToUpdate.map(taskTemplate => ({
          taskTemplateId: taskTemplate.id,
          taskTemplateGroupId: taskTemplate.group.id,
          orderTree: newOrderTreeMap[taskTemplate.id],
        }));

        return self.actions.updateAllOrderTrees(orderModels, taskTemplatesToUpdate[0].templateRevision.id).then(
          ({ payload: response }) => {
            const updatedTemplates = response.map(item => item.taskTemplate);
            $rootScope.$broadcast(EventName.TASK_TEMPLATE_BULK_MOVE_OK, updatedTemplates);

            return response;
          },
          response => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems moving the task`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });

            return Promise.reject(response);
          },
        );
      };

      self.create = function (taskTemplate) {
        $rootScope.$broadcast(EventName.TASK_TEMPLATE_CREATE_STARTED, taskTemplate);

        return self.actions.create(taskTemplate).then(
          ({ payload: createdTaskTemplate }) => {
            $rootScope.$broadcast(EventName.TASK_TEMPLATE_CREATE_OK, createdTaskTemplate);

            queryClient.invalidateQueries({
              queryKey: GetAllNativeAutomationsQuery.getKey({
                templateRevisionId: createdTaskTemplate.templateRevision.id,
              }),
            });

            AnalyticsService.trackEvent(AnalyticsConstants.Event.TASK_TEMPLATE_CREATED);

            return createdTaskTemplate;
          },
          response => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems creating the task`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });

            return Promise.reject(response);
          },
        );
      };

      self.update = function (taskTemplate) {
        const request = {
          id: taskTemplate.id,
          name: taskTemplate.name && taskTemplate.name.slice(0, 255),
          stop: taskTemplate.stop,
          hiddenByDefault: taskTemplate.hiddenByDefault,
        };

        return self.actions.update(request).then(
          ({ payload: response }) => {
            const { taskTemplate: updatedTaskTemplate, deletedRulesIds } = response;

            $rootScope.$broadcast(EventName.TASK_TEMPLATE_UPDATE_OK, updatedTaskTemplate, deletedRulesIds);
            return updatedTaskTemplate;
          },
          response => {
            return Promise.reject(response);
          },
        );
      };

      self.duplicate = function (sourceTaskTemplate, duplicateTaskTemplatePlaceholder) {
        return self.actions
          .duplicate(
            sourceTaskTemplate.id,
            duplicateTaskTemplatePlaceholder.id,
            duplicateTaskTemplatePlaceholder.group.id,
            duplicateTaskTemplatePlaceholder.orderTree,
          )
          .then(
            ({ payload: duplicatedTaskTemplate }) => duplicatedTaskTemplate,
            response => {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems duplicate the task`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });

              return Promise.reject(response);
            },
          );
      };

      self.hasSameGroupId = function (taskTemplate, TaskTemplateToCompare) {
        return TaskTemplateToCompare && taskTemplate.group.id === TaskTemplateToCompare.group.id;
      };

      self.checkIfDueOffsetEqual = function (dueOffset1, dueOffset2) {
        const dueDateAttributes = ['days', 'hours', 'minutes', 'months', 'seconds', 'years'];
        return dueDateAttributes.every(attr => dueOffset1 && dueOffset2 && dueOffset1[attr] === dueOffset2[attr]);
      };
    },
  );
