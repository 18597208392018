import { User } from '@process-street/subgrade/core';
import { Avatar, getAvatar, extractInitials } from 'components/common/Avatar';
import React, { ReactNode } from 'react';
import { AvatarService } from './avatar-service.interface';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';

class AvatarServiceImpl implements AvatarService {
  public static getInstance() {
    return AvatarServiceImpl.instance;
  }

  private static instance = new AvatarServiceImpl();

  public extractInitials = extractInitials;

  public getAvatar = getAvatar;

  public generateAvatarHtml(user: User): ReactNode {
    const avatar = this.getAvatar(user);
    return <Avatar url={avatar.url} />;
  }

  public generateInitialsHtml(user: User): ReactNode {
    const avatar = this.getAvatar(user);
    return <Avatar initials={avatar.initials} />;
  }

  public generateHtml(user: User): ReactNode {
    return user.avatarFile || isAnonymousUser(user) ? this.generateAvatarHtml(user) : this.generateInitialsHtml(user);
  }
}

export const avatarService = AvatarServiceImpl.getInstance();
