import { ExecuteAiPrompt } from '@process-street/subgrade/process';
import { HStack, Icon, Text } from 'components/design/next';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import * as React from 'react';
import { components, OptionProps } from 'react-select';

export type PromptOptionProps = OptionProps<{ value: ExecuteAiPrompt.PromptType; label: string }>;

const Icons: Record<ExecuteAiPrompt.PromptType, CustomIconName> = {
  Blog: 'blog',
  Custom: 'pencil',
  Parse: 'magnet',
  Newsletter: 'envelope',
  SocialMedia: 'lightbulb',
  Translate: 'language',
  Sentiment: 'face-smile',
  Summarize: 'align-left',
  Theme: 'file-dashed-line',
  Math: 'calculator',
};

export const PromptOption: React.FC<PromptOptionProps> = props => {
  const value = props.data.value as ExecuteAiPrompt.PromptType;
  return (
    <components.Option {...props}>
      <HStack spacing="2">
        <Icon icon={Icons[value]} variant="far" color="gray.500" size="4" />
        <Text>{props.data.label}</Text>
      </HStack>
    </components.Option>
  );
};
