import * as React from 'react';
import { SANDBOX_PREVIEWER_PARAMS } from 'app/hooks/use-create-sandbox-and-redirect';
import { PreviewerOption, SandboxUserType, UserTypeOption, UserTypeOptions } from './utils';
import { UserOptionTransformer } from 'app/utils/user-option-transformer';
import { match, P } from 'ts-pattern';
import { User } from '@process-street/subgrade/core';
import { ChecklistRevision, TemplateRevision, WidgetUtils } from '@process-street/subgrade/process';
import { GetTaskAssignmentRulesByTemplateRevisionIdQuery } from 'app/features/task-assignment-rules/query-builder';
import { useGetTasksAssignmentsByChecklistRevisionQuery } from 'app/features/checklist-revisions/query-builder/get-tasks-assignments-by-checklist-revision-query';
import { useGetFormFieldValuesByChecklistRevisionIdQuery } from 'app/features/widgets/query-builder';
import { AssignmentType, RoleOption, RoleOptionTransformer } from './role-option-transformer';
import { TaskAssignmentRule } from '@process-street/subgrade/role-assignment';

export const useSetInitialSelection = ({
  users,
  currentUser,
  setSelectedMember,
  roleOptions,
}: {
  users: User[] | undefined;
  currentUser: User;
  setSelectedMember: (option: PreviewerOption) => void;
  roleOptions: RoleOption[] | undefined;
}) => {
  React.useEffect(
    function setInitialSelectedMember() {
      const sandboxPreviewerParams = JSON.parse(sessionStorage.getItem(SANDBOX_PREVIEWER_PARAMS) || '{}');
      const userId = sandboxPreviewerParams.userId;
      const userType = sandboxPreviewerParams.userType;
      const assignmentType = sandboxPreviewerParams.assignmentType;
      match({ users, userId, userType })
        .with({ userId: P.nullish, userType: P.nullish }, () => {
          setSelectedMember(UserOptionTransformer.transformUserToOption(currentUser));
        })
        .with({ userType: SandboxUserType.User }, () => {
          const foundUser = users?.find(user => user.id === userId);
          if (foundUser) {
            setSelectedMember(UserOptionTransformer.transformUserToOption(foundUser));
          }
        })
        .with({ userType: SandboxUserType.UserType }, () => {
          const userTypeOption = UserTypeOptions.find(uto => uto.sandboxRole === sandboxPreviewerParams.sandboxRole);
          setSelectedMember(userTypeOption as UserTypeOption);
        })
        .with({ userType: SandboxUserType.Role }, () => {
          const role = roleOptions?.find(role => role?.assignmentType === assignmentType);
          setSelectedMember(role as RoleOption);
        })
        .otherwise(() => {});
    },
    [users, roleOptions, currentUser, setSelectedMember],
  );
};

export const useRoleOptions = ({
  templateRevision,
  checklistRevision,
}: {
  templateRevision: TemplateRevision;
  checklistRevision: ChecklistRevision;
}): RoleOption[] => {
  const { data: roles } = useGetFormFieldValuesByChecklistRevisionIdQuery({
    checklistRevisionId: checklistRevision.id,
  });

  const memberFieldRoles = React.useMemo(() => roles?.filter(WidgetUtils.filterMembershipWidgetsOnly), [roles]);
  const emailFieldRoles = React.useMemo(() => roles?.filter(WidgetUtils.filterEmailWidgetsOnly), [roles]);

  const { data: assignmentRules } = GetTaskAssignmentRulesByTemplateRevisionIdQuery.useQuery({
    templateRevisionId: templateRevision.id,
  });
  const { data: taskAssignments } = useGetTasksAssignmentsByChecklistRevisionQuery({
    checklistRevisionId: checklistRevision.id,
  });

  const assignmentRulesByFormFieldWidgetGroupIdMap = React.useMemo(() => {
    const map = new Map<string, TaskAssignmentRule>();
    assignmentRules?.forEach(ar => map.set(ar.sourceFormFieldWidgetGroup?.id ?? '', ar));
    return map;
  }, [assignmentRules]);

  const isLoading = !memberFieldRoles || !emailFieldRoles || !assignmentRules || !taskAssignments;

  const roleOptions = React.useMemo(
    () =>
      isLoading
        ? []
        : [
            ...RoleOptionTransformer.getOptionsFromAssignmentRules({
              formFieldValues: memberFieldRoles ?? [],
              assignmentRulesByFormFieldWidgetGroupIdMap,
              taskAssignments: taskAssignments ?? [],
              assignmentType: AssignmentType.Members,
            }),
            ...RoleOptionTransformer.getOptionsFromAssignmentRules({
              formFieldValues: emailFieldRoles ?? [],
              assignmentRulesByFormFieldWidgetGroupIdMap,
              taskAssignments: taskAssignments ?? [],
              assignmentType: AssignmentType.Email,
            }),
          ],
    [memberFieldRoles, emailFieldRoles, assignmentRulesByFormFieldWidgetGroupIdMap, taskAssignments, isLoading],
  );

  return roleOptions;
};
