import { Muid, MuidUtils } from '@process-street/subgrade/core';
import { TemplateOverview } from '@process-street/subgrade/process/template-overview-model';
import { AxiosError } from 'axios';
import { useToast } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { useCreateChecklistAssignmentMutation } from 'components/run-checklist/query-builder';
import { trace } from 'components/trace';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { PublishDraftMutation, useTemplateRevisionQuery } from 'features/template-revisions/query-builder';
import { useCallback } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { AiGeneratedWorkflowSettingsModalHelpers } from './helpers';

type UsePublishAndRunParams = {
  templateRevisionId: Muid;
  templateName: string;
  templateId: Muid;
};

const logger = trace({ name: 'usePublishAndRun' });

type PublishAndRunMutationParams = { checklistMigrationStrategy?: PublishDraftMutation.ChecklistMigrationStrategy };

export const usePublishAndRun = (
  { templateRevisionId, templateName, templateId }: UsePublishAndRunParams,
  options?: UseMutationOptions<void, AxiosError, PublishAndRunMutationParams>,
) => {
  const { ChecklistService } = useInjector('ChecklistService');
  const publishMutation = PublishDraftMutation.useMutation();
  const createChecklistAssignmentMutation = useCreateChecklistAssignmentMutation();
  const currentUser = useSelector(SessionSelector.getCurrentUser);
  const toast = useToast();
  const newestTemplateRevisionQuery = useTemplateRevisionQuery({ templateRevisionId });

  const publishAndRun = useCallback(
    async (checklistMigrationStrategy?: PublishDraftMutation.ChecklistMigrationStrategy) => {
      try {
        await publishMutation.mutateAsync({ tmplRevId: templateRevisionId, checklistMigrationStrategy });

        if (!currentUser) return;

        const assignedEmails = currentUser
          ? // Assign the currentUser by default
            [currentUser.email]
          : [];

        const templateOverview: TemplateOverview = {
          id: templateId,
          name: templateName ?? '',
          folderName: '',
        };

        const checklist = await ChecklistService.createWithPaymentRequiredCheck({
          template: templateOverview,
          name: AiGeneratedWorkflowSettingsModalHelpers.getDefaultChecklistName({
            templateName,
            defaultChecklistName: newestTemplateRevisionQuery.data?.defaultChecklistName,
          }),
          user: currentUser,
          dueDate: undefined,
        });

        await Promise.all(
          assignedEmails.map(userOption =>
            createChecklistAssignmentMutation.mutateAsync({
              assignmentId: MuidUtils.randomMuid(),
              checklistId: checklist.id,
              email: userOption,
            }),
          ),
        );

        ChecklistService.clearAndRedirect(checklist);
      } catch (e) {
        logger.error(e);

        toast({
          status: 'error',
          title: "We're having problems publishing the workflow.",
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      }
    },
    [
      publishMutation,
      createChecklistAssignmentMutation,
      templateName,
      templateRevisionId,
      templateId,
      currentUser,
      toast,
      ChecklistService,
      newestTemplateRevisionQuery.data?.defaultChecklistName,
    ],
  );

  return useMutation(({ checklistMigrationStrategy }) => publishAndRun(checklistMigrationStrategy), options);
};
