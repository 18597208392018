import { connectController } from 'reducers/util';
import { SessionSelector } from 'reducers/session/session.selectors';
import { createSelector } from 'reselect';
import templateUrl from './checklist-task-assignments.component.html';
import { ChecklistTaskAssignmentSelector } from '../store/checklist-task-assignment.selectors';

export const ChecklistTaskAssignmentsComponent = {
  bindings: {
    task: '<',
    unassignable: '<',
    showMultiple: '<',
    avatarSize: '<',
  },
  templateUrl,
  controller: class ChecklistTaskAssignmentsComponent {
    constructor($ngRedux, ChecklistTaskAssignmentActions, OrganizationMembershipActions, UserService) {
      'ngInject';

      this.UserService = UserService;
      this.$ngRedux = $ngRedux;
      this.ChecklistTaskAssignmentActions = ChecklistTaskAssignmentActions;
      this.OrganizationMembershipActions = OrganizationMembershipActions;
      this.UserService = UserService;
    }

    shouldChange = changes => changes.task && changes.task.currentValue;

    $onInit() {
      const mapStateToThis = () =>
        createSelector(
          [
            SessionSelector.getSelectedOrganizationId,
            ChecklistTaskAssignmentSelector.getAllByTaskIdWithOrganizationMembershipAndUser(this.task.id),
          ],
          (selectedOrganizationId, checklistTaskAssignments) => ({ selectedOrganizationId, checklistTaskAssignments }),
        );

      const mapDispatchToThis = () => ({
        getAllOrgMembershipByOrgId: this.OrganizationMembershipActions.getAllOrgMembershipByOrgId,
        getAllAssignmentsByChecklistRevisionId: this.ChecklistTaskAssignmentActions.getAllByChecklistRevisionId,
        unassign: this.ChecklistTaskAssignmentActions.unassign,
      });

      connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, this.shouldChange)(this);

      this.actions.getAllOrgMembershipByOrgId(this.state.selectedOrganizationId);
      this.actions.getAllAssignmentsByChecklistRevisionId(this.task.checklistRevision.id);
    }

    unassignUser(user) {
      if (this.unassignable) {
        this.actions.unassign(this.task.id, user.email, this.UserService.getLabel(user));
      }
    }
  },
};
