import React from 'react';
import { Muid, Option } from '@process-street/subgrade/core';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { OnChangeValue } from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { Template } from 'webpack';
import { useGetAllTemplatesQuery } from 'features/template/query-builder';
import { TemplateStatus, TemplateType } from '@process-street/subgrade/process';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

export type WorkflowSelectorProps = {
  selectedTemplateId?: Muid;
  onChange: (dataSetId: Option<Muid>) => void;
};

const allWorkflowsOption: WorkflowSelectorOptionType = {
  label: 'All workflows',
  value: 'all-workflows',
};

export type WorkflowSelectorOptionType = { templateId?: Template; label: string; value?: string };

export const WorkflowSelector: React.FC<React.PropsWithChildren<WorkflowSelectorProps>> = ({
  selectedTemplateId,
  onChange,
}) => {
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const workflowTemplatesQuery = useGetAllTemplatesQuery(
    {
      organizationId: organizationId!,
      templateStatus: TemplateStatus.Active,
      templateType: TemplateType.Playbook,
    },
    {
      enabled: Boolean(organizationId),
    },
  );

  const templateOptions: WorkflowSelectorOptionType[] = (workflowTemplatesQuery.data ?? []).map(formTemplate => ({
    label: formTemplate.name,
    value: formTemplate.id,
    formTemplate,
  }));

  const options = [allWorkflowsOption, ...templateOptions];

  const selectedOption = options.find(o => o.value === selectedTemplateId) ?? allWorkflowsOption;

  const handleChange = (value: OnChangeValue<WorkflowSelectorOptionType, false>) => {
    if (BlvdSelectHelpers.isOptionType<WorkflowSelectorOptionType>(value)) {
      onChange(value.value === 'all-workflows' ? undefined : value.value);
    }
  };

  return <BlvdSelect options={options} value={selectedOption} onChange={handleChange} isSearchable />;
};
