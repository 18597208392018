import * as React from 'react';
import { Button, ButtonProps, Icon, Tooltip } from 'components/design/next';
import { getMailToLink } from 'features/widgets/components/send-rich-email/util';
import { SendRichEmailFormFieldConfig } from '@process-street/subgrade/process';

export type SendTestMailtoButtonProps = {
  config: SendRichEmailFormFieldConfig;
  isRequired?: boolean;
  onClick?: () => void;
  caption?: string;
};

export const SendTestMailtoButton: React.FC<React.PropsWithChildren<ButtonProps & SendTestMailtoButtonProps>> = ({
  config,
  isRequired,
  onClick,
  caption = 'Send Test',
  ...props
}) => {
  const mailToUrl = getMailToLink(config);

  return (
    <Tooltip
      label={
        props.isDisabled ? 'Email is missing subject and body' : 'Email will be sent using your default email client'
      }
      hasArrow
      shouldWrapChildren
    >
      <a href={mailToUrl} target="_blank" rel="noreferrer">
        <Button
          variant="tertiary"
          onClick={onClick}
          leftIcon={
            props.isDisabled ? (
              <Icon icon="exclamation-circle" size="4" variant="far" color="red.500" />
            ) : (
              <Icon icon="paper-plane" size="4" variant="far" />
            )
          }
          iconSpacing="2"
          {...props}
          {...{
            ...(isRequired
              ? {
                  'aria-required': true,
                  '_after': {
                    content: '"*"',
                    color: 'red.500',
                    position: 'absolute',
                    top: '0',
                    right: '-3',
                    lineHeight: 'none',
                  },
                }
              : {}),
          }}
        >
          {caption}
        </Button>
      </a>
    </Tooltip>
  );
};
