import { Plan } from '@process-street/subgrade/billing';
import { Organization, User, UserType } from '@process-street/subgrade/core';
import { StringUtils } from '@process-street/subgrade/util';
import { initialize } from 'launchdarkly-js-client-sdk';
import {
  FeatureFlagKey,
  FeatureFlags,
  LaunchDarklyPublicKey,
} from 'services/features/feature-flags/feature-flag-constants';
import { PSLaunchDarklyUser } from 'services/features/feature-flags/feature-flag-service';
import { useQuery as useRQ, UseQueryOptions } from 'react-query';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { FeatureFlagsUtils } from 'services/features/feature-flags/feature-flag-service.pure';

export namespace GetFeatureFlagsQuery {
  export type Params = { user?: User; organization?: Organization; plan?: Plan };

  export type Response = FeatureFlags;

  export const key = ['feature-flags'];

  export const getKey = (params: Params) => [...key, params];

  export const queryFn = async ({ user, organization, plan }: Params) => {
    const ldUser: PSLaunchDarklyUser =
      user?.userType === UserType.Standard
        ? FeatureFlagsUtils.generateLaunchDarklyUser(user, organization, plan)
        : FeatureFlagsUtils.generateLaunchDarklyAnonymousUser();

    const ldClient = initialize(LaunchDarklyPublicKey, ldUser);

    const flags = await ldClient.identify(ldUser);

    return Object.entries(FeatureFlagKey).reduce((acc, [key, launchDarklyKey]) => {
      const stateKey = StringUtils.uncapitalize(key as keyof typeof FeatureFlagKey);
      acc[stateKey] = flags[launchDarklyKey];
      return acc;
    }, {} as FeatureFlags);
  };

  export const useQuery = <Select = Response>(options: UseQueryOptions<Response, Error, Select> = {}) => {
    const user = useSelector(SessionSelector.getCurrentUser);
    const plan = useSelector(SessionSelector.getCurrentPlan);
    const organization = useSelector(SessionSelector.getSelectedOrganization);

    return useRQ({
      queryKey: getKey({
        user,
        organization,
        plan,
      }),
      queryFn: () => queryFn({ user, plan, organization }),
      ...options,
    });
  };
}
