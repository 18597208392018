import React from 'react';
import { OrganizationMembershipRole, OrganizationMembershipWithUser, UserType } from '@process-street/subgrade/core';
import { Text, TextProps } from 'components/design/next';
import { useSelector } from 'react-redux';
import { GroupSelector } from 'reducers/group/group.selectors';
import { isStandardUserOm } from '@process-street/subgrade/util/membership-utils';

export type PermissionsDescriptionProps = {
  memberships: OrganizationMembershipWithUser[];
} & TextProps;

const getAllMembersDescription = (): string => 'all organization';

const getMembersDescription = (memberships: OrganizationMembershipWithUser[]): string[] => {
  const ret = [];

  const membersCount = memberships.filter(
    om =>
      isStandardUserOm(om) &&
      (om.role === OrganizationMembershipRole.FullMember || om.role === OrganizationMembershipRole.Admin),
  ).length;
  if (membersCount > 0) {
    ret.push(`${membersCount} member${membersCount > 1 ? 's' : ''}`);
  }

  return ret;
};

const getGuestsDescription = (memberships: OrganizationMembershipWithUser[]): string[] => {
  const ret = [];

  const guestsCount = memberships.filter(
    m => m.role === OrganizationMembershipRole.Guest || m.role === OrganizationMembershipRole.FreeMember,
  ).length;
  if (guestsCount > 0) {
    ret.push(`${guestsCount} guest${guestsCount > 1 ? 's' : ''}`);
  }

  return ret;
};

const getGroupsDescription = (memberships: OrganizationMembershipWithUser[]): string[] => {
  const ret = [];

  const groupsCount = memberships.filter(om => om.user.userType === UserType.Group).length;
  if (groupsCount > 0) {
    ret.push(`${groupsCount} group${groupsCount > 1 ? 's' : ''}`);
  }

  return ret;
};

export const PermissionsDescription: React.FC<React.PropsWithChildren<PermissionsDescriptionProps>> = ({
  memberships,
  ...props
}) => {
  const allMembersGroup = useSelector(GroupSelector.getAllMembersGroup);

  const allMembersGroupExists = memberships.find(m => allMembersGroup && m.user.id === allMembersGroup.user.id);

  const descriptionParts = [];

  if (allMembersGroupExists) {
    descriptionParts.push(getAllMembersDescription());
  } else {
    descriptionParts.push(getMembersDescription(memberships));
    descriptionParts.push(getGroupsDescription(memberships));
    descriptionParts.push(getGuestsDescription(memberships));
  }

  const parts = descriptionParts.filter(item => item.length > 0);

  if (parts.length === 0) {
    return null;
  }

  return (
    <Text variant="-1" color="gray.500" {...props}>
      Shared with {parts.join(', ')}
    </Text>
  );
};
