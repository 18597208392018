export enum MergeTagMode {
  PLAINTEXT = 'Plaintext',
  HTML = 'Html',
}

export enum MergeTagTarget {
  GENERAL = 'General',
  /** Rich text context where table form field rendering is allowed */
  RICH_CONTENT = 'RichContent',
  SNIPPET = 'Snippet',
  /** Email addresses/fields */
  EMAIL = 'Email',
  /** Workflow Run name */
  CHECKLIST = 'Checklist',
  APPROVAL_TASK = 'ApprovalTask',
  AI_TASK_PROMPT = 'AiTaskPrompt',
}

export const MergeTagsConstants = {
  Mode: MergeTagMode,
  Target: MergeTagTarget,
};
