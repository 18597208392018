import * as React from 'react';
import { TaskCondition as TaskConditionModel } from '@process-street/subgrade/conditional-logic';
import { HStack, Text } from 'components/design/next';
import { usePrintRulesContext } from '../../../context';
import { getOperatorDisplayValue } from './form-field-condition';
interface TaskConditionProps {
  condition: TaskConditionModel;
  isTitle?: boolean;
}
export const TaskCondition: React.FC<TaskConditionProps> = ({ condition, isTitle = false }) => {
  const { taskGroupIds } = usePrintRulesContext();
  if (!condition.taskTemplateGroupId) return null;
  const task = taskGroupIds.get(condition.taskTemplateGroupId);
  if (!task) return null;
  const fontSize = isTitle ? 'lg' : 'md';
  return (
    <HStack fontSize={fontSize}>
      <Text color="gray.400" fontWeight="bold">
        {task.taskOrder}
      </Text>
      {condition.taskTemplateGroupId && (
        <Text as="span" fontSize={fontSize}>
          {task?.name ?? 'unnamed task'}
        </Text>
      )}
      {!isTitle && (
        <>
          <Text as="span" fontSize="md" fontWeight="bold">
            {getOperatorDisplayValue(condition.operator)}
          </Text>
          <Text as="span" fontSize="md">
            {getOperatorDisplayValue(condition.operand.value as string)}
          </Text>
        </>
      )}
    </HStack>
  );
};
