import { ColDef, ValueGetterParams } from '@ag-grid-community/core';
import { ChecklistColumn, ColumnType, filterableColumnTypes } from '@process-street/subgrade/dashboard';
import { ChecklistColDef } from 'components/dashboard/models/grid';

export const alwaysVisibleColumns: ColDef[] = [
  {
    field: ChecklistColumn.Selection,
    cellRenderer: () => null,
    headerComponent: 'SelectionHeaderRenderer',
    lockPosition: true,
    pinned: 'left',
    sortable: false,
    width: 40,
  },
];

export const checklistColumns: ChecklistColDef[] = [
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.ChecklistName,
    cellRenderer: 'NameRenderer',
    headerName: 'Name',
    lockPosition: true,
    pinned: 'left',
    suppressMovable: true,
    width: 350,
  },
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.TemplateName,
    cellRenderer: 'TemplateRenderer',
    headerName: 'Workflow',
    width: 180,
    lockPinned: true,
  },
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.Assignees,
    cellRenderer: 'AssigneesRenderer',
    headerName: 'Assignees',
    width: 100,
    lockPinned: true,
    sortable: false,
  },
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.ChecklistLabelId,
    cellRenderer: 'LabelRenderer',
    headerName: 'Label',
    width: 140,
    lockPinned: true,
    valueGetter: (params: ValueGetterParams) =>
      params.data && {
        ...params.data[ChecklistColumn.ChecklistLabelId],
        rowValues: params.data,
      },
  },
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.ProgressStatus,
    cellRenderer: 'StatusRenderer',
    headerName: 'Status',
    width: 120,
    lockPinned: true,
  },
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.TotalCommentsAndAttachmentsCount,
    cellRenderer: 'CommentsAndAttachmentsRenderer',
    headerName: 'Comments',
    width: 100,
    lockPinned: true,
    sortable: false,
  },
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.ChecklistCreateDate,
    cellRenderer: 'MonthDayRenderer',
    headerName: 'Started',
    width: 100,
    lockPinned: true,
  },
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.LastActiveDate,
    cellRenderer: 'ActivityRenderer',
    headerName: 'Activity',
    width: 140,
    lockPinned: true,
  },
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.ChecklistDueDate,
    cellRenderer: 'MonthDayRenderer',
    headerName: 'Due',
    width: 100,
    lockPinned: true,
  },
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.ChecklistCompletedDate,
    cellRenderer: 'MonthDayRenderer',
    headerName: 'Completed',
    width: 120,
    lockPinned: true,
  },
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.ChecklistCompletedBy,
    cellRenderer: 'CompletedByRenderer',
    headerName: 'Completed By',
    width: 140,
    lockPinned: true,
  },
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.OverdueTasksCount,
    cellRenderer: 'OverdueTasksRenderer',
    headerName: 'Overdue Tasks',
    width: 180,
    lockPinned: true,
  },
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.CompletedTasksCount,
    cellRenderer: 'ProgressRenderer',
    headerName: 'Tasks Completed',
    width: 300,
    lockPinned: true,
  },
  {
    type: ColumnType.Checklist,
    field: ChecklistColumn.TemplateVersion,
    headerName: 'Version',
    width: 100,
    lockPinned: true,
  },
];

export const getChecklistColumnHeaderName = (column: ChecklistColumn): string => {
  return checklistColumns.find(col => col.field === column)?.headerName || 'Unknown';
};

export const filterableColumns: ChecklistColDef[] = checklistColumns.filter(
  col => col.field && filterableColumnTypes.includes(col.field),
);

export const filterableColumnsWithoutLabel: ChecklistColDef[] = filterableColumns.filter(
  col => col.field !== ChecklistColumn.ChecklistLabelId,
);
