import { Plan, PlanInterval, PlanLevel } from '@process-street/subgrade/billing';
import { Organization, OrganizationDiscount } from '@process-street/subgrade/core';
import { PlanCostInfo } from 'pages/organizations/manage/billing/models/models';

export interface PlanLevelSelectorService {
  formatCostAsString(cost: number): string;
  getPlanLevelState(
    organization: Organization,
    currentPlan: Plan | undefined,
    planLevel: PlanLevel | undefined,
    selected: boolean,
  ): PlanLevelSelectorState;

  getPlanCostDetailsDescription(
    plan: Plan,
    interval: PlanInterval,
    quantity: number,
    discount: OrganizationDiscount,
    organization: Organization,
  ): PlanCostInfo;
}

export enum PlanLevelSelectorState {
  SELECT_PLAN = 'Select',
  CURRENT_PLAN = 'Current Plan',
  SELECTED_PLAN = 'Selected',
  CONTACT_US = 'Contact Us',
}
