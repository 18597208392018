import angular from 'angular';

angular.module('frontStreetApp.services').factory('ChecklistRevisions', function (ResourceFactory) {
  return ResourceFactory.create({
    query: 'GET[] /1/checklist-revisions',
    counts: 'GET /1/checklist-revisions/counts',
    scheduleOneMigrationByChecklistRevisionId: 'POST /1/checklist-revisions/:id/schedule-migration',
    migrateOneWithPriorityByChecklistRevisionId: 'POST[] /1/checklist-revisions/:id/priority-migrate-one',
    scheduleAllMigrationsByTemplateRevisionId: 'POST /1/checklist-revisions/schedule-migration',
    getMigrationStatsByTemplateRevisionId:
      'GET /1/template-revisions/:templateRevisionId/checklist-revisions/migration-stats',
    get: 'GET /1/checklist-revisions/:id',
    getTaskStatsByChecklistRevisionId: 'GET[] /1/checklist-revisions/:id/task-stats',
  });
});
