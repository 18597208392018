import { DeleteTemplateMutation, UpdateTemplateMutation } from 'features/template/mutation-builder';
import {
  CopyWidgetByHeaderIdMutation,
  CreateWidgetMutation,
  DeleteWidgetByHeaderIdMutation,
  UndeleteWidgetByHeaderIdMutation,
  UpdateWidgetMutation,
  UpdateWidgetOrderTreesMutation,
} from 'features/widgets/query-builder';
import { MutationKey } from 'react-query';

export const MUTATION_KEYS: MutationKey[] = [
  UpdateWidgetMutation.getKey(),
  DeleteWidgetByHeaderIdMutation.getKey(),
  CreateWidgetMutation.getKey(),
  UpdateWidgetOrderTreesMutation.getKey(),
  UndeleteWidgetByHeaderIdMutation.getKey(),
  CopyWidgetByHeaderIdMutation.getKey(),
  UpdateTemplateMutation.key,
  DeleteTemplateMutation.key,
];
