import { escapeHtml } from '@process-street/subgrade/util';
import { HtmlServiceUtils } from 'services/html-service.utils';
import { Option } from 'space-monad';
import { match } from 'ts-pattern';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';

// Leaving as a function in case we want to modify the returned span
const ChevronsMask = (children: string) => `<span  style='visibility: hidden;'>${children}</span>`;

function maskMergeTags(string: string) {
  const regexp = string.match(MergeTagStringReplacementUtils.CURLY_BRACES_REGEXP)
    ? MergeTagStringReplacementUtils.CURLY_BRACES_REGEXP
    : MergeTagStringReplacementUtils.CHEVRONS_REGEXP;

  return string.replace(
    regexp,
    [
      '<span style="background-color: var(--ps-colors-brand-100); border-radius: var(--ps-radii-base);" data-testid="merge-tag-mask">',
      ChevronsMask('$1'),
      '<span>$2</span>',
      ChevronsMask('$3'),
      '</span>',
    ].join(''),
  );
}

export type Parser = 'urls' | 'merge-tags';

function makeParser(...parsers: Parser[]) {
  if (parsers.length === 0) {
    parsers.push('urls', 'merge-tags');
  }

  return (value: string | number) => {
    const init = Option(value).map(String).map(escapeHtml);

    const withParsers = parsers.reduce((acc, parser) => {
      return match(parser)
        .with('merge-tags', () => acc.map(maskMergeTags))
        .with('urls', () => acc.map(HtmlServiceUtils.autolinkUrls))
        .otherwise(() => acc);
    }, init);

    return withParsers.map(raw => `${raw}<br>`).get();
  };
}

export const MaskedInputParsers = {
  makeParser,
};
