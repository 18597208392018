import { EmployeeCount, Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery as useRQ, UseQueryOptions, QueryKey } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetEmployeeCountQuery {
  export type Params = { organizationId: Muid };

  export type Response = { employeeCount: EmployeeCount };

  export const key = ['organization', 'employee-count'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/organizations/${params.organizationId}/details/employee-count`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.organizationId) && options.enabled !== false,
    });
  };
}
