import { createUsableContext } from '@process-street/subgrade/util';
import { CalendarMode } from 'features/conditional-logic/utils/conditional-logic-utils';

export type DatePickerContextValue = {
  setMode: (mode: CalendarMode) => void;
  mode: CalendarMode;
};

export const [useDatePickerContext, DatePickerContext] = createUsableContext<DatePickerContextValue>({
  hookName: 'useDatePickerContext',
  providerName: 'DatePickerProvider',
});
