import { EMAIL_REGEXP } from '@process-street/subgrade/process';
import { BlvdTextInput, TextInputType } from 'components/design/BlvdTextInput';
import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from 'components/design/next';

export interface AddNewEmailModalProps {
  onCancel: () => void;
  onAdd: (email: string, password: string) => void;
  passwordRequired: boolean;
  visible: boolean;
}

export const AddNewEmailModal: React.FunctionComponent<React.PropsWithChildren<AddNewEmailModalProps>> = ({
  onCancel,
  onAdd,
  passwordRequired,
  visible,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [btnAddDisabled, setBtnAddDisabled] = useState(true);

  useEffect(() => {
    const disabled = email.length === 0 || (passwordRequired && password.length === 0);
    setBtnAddDisabled(disabled);
  }, [email.length, passwordRequired, password.length]);

  useEffect(() => {
    setEmail('');
    setPassword('');
  }, [visible]);

  const onEmailChange = (newEmail: string) => {
    if (newEmail.match(EMAIL_REGEXP)) {
      setEmail(newEmail);
    } else {
      setEmail('');
    }
  };

  const handleAdd = () => {
    onAdd(email, password);
  };

  return (
    <Modal isOpen={visible} onClose={onCancel} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text variant="2">Add Email</Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <div>
            <p>
              We'll send a verification email to the address you enter.
              <br />
              Click the link in the email to confirm the address.
            </p>
          </div>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <BlvdTextInput
              autoFocus={true}
              placeholder="Enter Email"
              type={TextInputType.Email}
              value=""
              onChange={onEmailChange}
            />
          </FormControl>
          {passwordRequired && (
            <FormControl>
              <FormLabel>Password</FormLabel>
              <BlvdTextInput
                placeholder="Confirm Password"
                type={TextInputType.Password}
                value=""
                onChange={setPassword}
              />
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" colorScheme="gray" onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={handleAdd} isDisabled={btnAddDisabled}>
              Add
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
