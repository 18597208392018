import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { Box, BoxProps } from 'components/design/next';
import { useFeatureFlag } from 'features/feature-flags';
import * as React from 'react';
import { highlightUnknownMergeTags } from 'services/merge-tags/utils/inject-merge-tag-labels';

export type MergeTagHighlighterProps = BoxProps & {
  content: string;
};

export const MergeTagHighlighter: React.FC<React.PropsWithChildren<MergeTagHighlighterProps>> = ({
  content,
  ...props
}) => {
  const isMergeTagImprovementsEnabled = useFeatureFlag('mergeTagImprovements');

  if (isMergeTagImprovementsEnabled) {
    return (
      <Box
        display="inline-block"
        dangerouslySetInnerHTML={{
          __html: highlightUnknownMergeTags(MergeTagStringReplacementUtils.replaceUnknownTagsValues(content)),
        }}
        {...props}
      />
    );
  }
  return <>{content}</>;
};
