import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace ScheduleAllMigrationsByTemplateRevisionIdMutation {
  export type Params = { templateRevisionId: Muid };

  export type Result = { count: number; dateFrom: number };
  export const key = 'checklist-revisions/schedule-migration';

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Result>('/1/checklist-revisions/schedule-migration', {
        finishedTemplateRevisionId: params.templateRevisionId,
      })
      .then(r => r.data);

  export const useMutation = (
    options: UseMutationOptions<
      ScheduleAllMigrationsByTemplateRevisionIdMutation.Result,
      AxiosError,
      ScheduleAllMigrationsByTemplateRevisionIdMutation.Params
    > = {},
  ) => {
    return useRQMutation<
      ScheduleAllMigrationsByTemplateRevisionIdMutation.Result,
      AxiosError,
      ScheduleAllMigrationsByTemplateRevisionIdMutation.Params
    >(ScheduleAllMigrationsByTemplateRevisionIdMutation.mutationFn, options);
  };
}
