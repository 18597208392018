import { useDisclosure, useOutsideClick } from 'components/design/next';
import * as React from 'react';

export type UseDefaultOpenDisclosureParams = {
  ref: React.MutableRefObject<HTMLDivElement | null>;
};

/**
 * Creates a Chakra UI disclosure, that's default open and adds handler to automatically close when clicking outside the ref.
 */
export const useDefaultOpenDisclosure = ({ ref }: UseDefaultOpenDisclosureParams): ReturnType<typeof useDisclosure> => {
  const disclosure = useDisclosure({ defaultIsOpen: true });

  const handler = React.useCallback(() => disclosure.onClose(), [disclosure]);

  useOutsideClick({ ref, handler });

  return disclosure;
};
