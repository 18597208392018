import {
  PaywallAdminFinePrint,
  PaywallBodyDescription,
  PaywallBodyWithIcon,
  PaywallFooter,
  PaywallGoBack,
  PaywallHeader,
  PaywallBodyAdminHeading,
  PaywallModal,
  PaywallPrimaryActionLinkButton,
  PaywallBodyDescriptionEmphasis,
  PaywallDivider,
  PaywallAdminIconUserLock,
} from 'components/paywalls/common';
import * as React from 'react';
import { ModalProps } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { useRoleNames } from 'hooks/use-role-names';

export type Props = {
  onGoBack: () => void;
  userName: string;
  userRole: OrganizationMembershipRole;
} & Partial<ModalProps>;

export const Admin: React.FC<React.PropsWithChildren<Props>> = ({ onGoBack, userName, userRole, ...props }) => {
  const { $state } = useInjector('$state');
  const upgradeRoute = $state.href('organizationManage.tab', { tab: 'members' });
  const roleNames = useRoleNames();
  const roleText =
    userRole === OrganizationMembershipRole.Guest ? `${roleNames.Guest.plural}` : `${roleNames.FreeMember.plural}`;

  return (
    <PaywallModal {...props}>
      <PaywallHeader>
        <PaywallGoBack onClick={onGoBack} />
      </PaywallHeader>

      <PaywallBodyWithIcon>
        <PaywallAdminIconUserLock />

        <PaywallBodyAdminHeading>{roleText} cannot be a default assignee on workflows</PaywallBodyAdminHeading>

        <PaywallBodyDescription>
          Upgrade {userName} to a{' '}
          <PaywallBodyDescriptionEmphasis>{roleNames.FullMember.single}</PaywallBodyDescriptionEmphasis> to make them
          the default assignee.
        </PaywallBodyDescription>
      </PaywallBodyWithIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallPrimaryActionLinkButton href={upgradeRoute}>
          Upgrade to {roleNames.FullMember.single}
        </PaywallPrimaryActionLinkButton>

        <PaywallAdminFinePrint mt="4">Upgrading will add another paid seat to your plan.</PaywallAdminFinePrint>
      </PaywallFooter>
    </PaywallModal>
  );
};
