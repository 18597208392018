import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';
import { FormEditorPageActorRef } from './form-editor-page-machine-types';

export const [useFormEditorPageActorRef, FormEditorPageActorRefContext] = createUsableContext<FormEditorPageActorRef>({
  hookName: 'useFormEditorPageActorRef',
  providerName: 'FormEditorPageActorRefProvider',
});

export const FormEditorPageActorRefProvider = ({
  actor,
  children,
}: React.PropsWithChildren<{ actor: FormEditorPageActorRef }>) => {
  return <FormEditorPageActorRefContext.Provider value={actor}>{children}</FormEditorPageActorRefContext.Provider>;
};
