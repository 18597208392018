import angular from 'angular';
import { DaoCacheConstants } from './dao-cache-constants';
import { trace } from 'components/trace';
import hash from 'object-hash';

angular
  .module('frontStreetApp.dao')
  .service('PermissionsDao', function ($q, DaoCacheService, DataService, Permissions) {
    const logger = trace({ name: 'PermissionsDao' });

    const cache = DaoCacheService.getPermissionsCache();
    const promiseMap = {};

    const k = DaoCacheService.generateK(DaoCacheConstants.Namespace.PERMISSIONS);

    const GLOBAL_BY_ORGANIZATION_ID = 'gl-oid';
    const CONSOLIDATED_BY_CHECKLIST_ID = 'cn-cklid';
    const CONSOLIDATED_BY_CHECKLIST_IDS = 'cn-cklids';
    const CONSOLIDATED_BY_TEMPLATE_ID = 'cn-tmplid';
    const CONSOLIDATED_BY_FOLDER_ID = 'cn-flid';

    DataService.getCollection('folderPermits').onDeleteAndPut(
      () => {
        cache.removeAll();
      },
      () => {
        cache.removeAll();
      },
    );
    DataService.getCollection('templatePermits').onDeleteAndPut(
      () => {
        cache.removeAll();
      },
      () => {
        cache.removeAll();
      },
    );

    /**
     * Gets a single object of consolidated global permissions by organization id
     *
     * @param organizationId
     * @param flushCache
     * @return {Promise}
     */
    this.getConsolidatedGlobalByOrganizationId = function (organizationId, flushCache) {
      const cacheKey = k(GLOBAL_BY_ORGANIZATION_ID, organizationId);

      const permission = cache.get(cacheKey);

      let promise;

      if (angular.isDefined(permission) && !flushCache) {
        promise = $q.resolve(permission);
      } else if (promiseMap[cacheKey]) {
        promise = promiseMap[cacheKey];
      } else {
        promise = Permissions.getConsolidatedGlobalPermissions({ organizationId })
          .$promise.then(permissions => {
            logger.debug('permissions', permissions);

            return cache.put(cacheKey, permissions);
          })
          .finally(() => {
            delete promiseMap[cacheKey];
          });
        promiseMap[cacheKey] = promise;
      }

      return promise;
    };

    /**
     * Gets a single object of consolidated permissions by template id
     *
     * @param templateId
     * @param flushCache
     * @return {Promise}
     */
    this.getConsolidatedByTemplateId = function (templateId, flushCache) {
      const cacheKey = k(CONSOLIDATED_BY_TEMPLATE_ID, templateId);

      const permission = cache.get(cacheKey);

      let promise;

      if (angular.isDefined(permission) && !flushCache) {
        promise = $q.resolve(permission);
      } else if (promiseMap[cacheKey]) {
        promise = promiseMap[cacheKey];
      } else {
        promise = Permissions.getConsolidatedTemplatePermissions({ templateId })
          .$promise.then(permissions => {
            logger.debug('permissions', permissions);

            return cache.put(cacheKey, permissions);
          })
          .finally(() => {
            delete promiseMap[cacheKey];
          });
        promiseMap[cacheKey] = promise;
      }

      return promise;
    };

    /**
     * Gets a single object of consolidated permissions by checklist id
     *
     * @param checklistId
     * @param flushCache
     * @return {Promise}
     */
    this.getConsolidatedByChecklistId = function (checklistId, flushCache) {
      const cacheKey = k(CONSOLIDATED_BY_CHECKLIST_ID, checklistId);

      const permission = cache.get(cacheKey);

      let promise;

      if (angular.isDefined(permission) && !flushCache) {
        promise = $q.resolve(permission);
      } else if (promiseMap[cacheKey]) {
        promise = promiseMap[cacheKey];
      } else {
        promise = Permissions.getConsolidatedChecklistPermissions({ checklistId })
          .$promise.then(permissions => {
            logger.debug('permissions', permissions);

            return cache.put(cacheKey, permissions);
          })
          .finally(() => {
            delete promiseMap[cacheKey];
          });
        promiseMap[cacheKey] = promise;
      }

      return promise;
    };

    /**
     * Gets consolidated permission maps by a list of checklist ids
     *
     * @param checklistIds
     * @param flushCache
     * @return {Promise}
     */
    this.getAllConsolidatedByChecklistIds = function (checklistIds, flushCache) {
      const cacheKey = k(CONSOLIDATED_BY_CHECKLIST_IDS, hash(checklistIds));

      const permission = cache.get(cacheKey);

      let promise;

      if (angular.isDefined(permission) && !flushCache) {
        promise = $q.resolve(permission);
      } else if (promiseMap[cacheKey]) {
        promise = promiseMap[cacheKey];
      } else {
        promise = Permissions.getConsolidatedChecklistsPermissions({ checklistIds: checklistIds.join(',') })
          .$promise.then(permissions => {
            logger.debug('multiple checklist permissions', permissions);

            return cache.put(cacheKey, permissions);
          })
          .finally(() => {
            delete promiseMap[cacheKey];
          });
        promiseMap[cacheKey] = promise;
      }

      return promise;
    };

    /**
     * Gets a single object of consolidated permissions by folder id
     *
     * @param folderId
     * @param flushCache
     * @return {Promise}
     */
    this.getConsolidatedByFolderId = function (folderId, flushCache) {
      const cacheKey = k(CONSOLIDATED_BY_FOLDER_ID, folderId);

      const permission = cache.get(cacheKey);

      let promise;

      if (angular.isDefined(permission) && !flushCache) {
        promise = $q.resolve(permission);
      } else if (promiseMap[cacheKey]) {
        promise = promiseMap[cacheKey];
      } else {
        promise = Permissions.getConsolidatedFolderPermissions({ folderId })
          .$promise.then(permissions => {
            logger.debug('permissions', permissions);

            return cache.put(cacheKey, permissions);
          })
          .finally(() => {
            delete promiseMap[cacheKey];
          });
        promiseMap[cacheKey] = promise;
      }

      return promise;
    };
  });
