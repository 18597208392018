import { useNavigate } from '@process-street/adapters/navigation';
import { Muid } from '@process-street/subgrade/core';
import { StateService } from '@uirouter/core';
import { UserSettingsService } from 'app/components/user/UserSettingsService';
import { SessionService } from 'app/services/session-service.interface';
import { useInjector } from 'components/injection-provider';
import { useStateParam } from 'hooks/use-state-param';
import React from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { TaskTemplateService } from 'services/task-template-service.interface';

export type SharedContext = {
  queryClient: QueryClient;
  $state: StateService;
  taskTemplateService: TaskTemplateService;
  templateId: Muid;
  navigate: ReturnType<typeof useNavigate>;
  dispatch: Dispatch;
  sessionService: SessionService;
  userSettingsService: UserSettingsService;
};

export function useSharedContext(): SharedContext {
  const queryClient = useQueryClient();
  const { $state, SessionService, TaskTemplateService, UserSettingsService } = useInjector(
    '$state',
    'SessionService',
    'TaskTemplateService',
    'UserSettingsService',
  );
  const templateId = useStateParam({ key: 'id' })!;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return React.useMemo(() => {
    return {
      queryClient,
      $state,
      navigate,
      sessionService: SessionService,
      taskTemplateService: TaskTemplateService,
      dispatch,
      templateId,
      userSettingsService: UserSettingsService,
    };
  }, [queryClient, $state, navigate, SessionService, TaskTemplateService, dispatch, templateId, UserSettingsService]);
}
