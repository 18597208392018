import angular from 'angular';
import { EventEmitterService } from '@process-street/subgrade/util/event-emitter-service';

// Adapted from: https://docs.angularjs.org/guide/decorators
angular.module('frontStreetApp').config(($provide: angular.auto.IProvideService) => {
  $provide.decorator('$rootScope', ($delegate: angular.IRootScopeService) => {
    // Relay all events to EventEmitterService
    // https://stackoverflow.com/a/34903433
    const Scope = $delegate.constructor;
    const originalBroadcast = Scope.prototype.$broadcast;
    // using `function` to bind `this` to $scope instance
    Scope.prototype.$broadcast = function (eventName: string, ...eventArgs: unknown[]) {
      EventEmitterService.broadcastFromAngular(eventName, ...eventArgs);
      return originalBroadcast.call(this, eventName, ...eventArgs);
    };
    return $delegate;
  });
});
