import * as React from 'react';
import {
  Button,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
} from 'components/design/next';
import { QueryObserverResult } from 'react-query';
import { DataSet } from '@process-street/subgrade/process';

export type DataSetFormModalProps = {
  isOpen: boolean;
  initialValues?: Partial<DataSet>;
  saveStatus: QueryObserverResult['status'];
  deleteStatus?: QueryObserverResult['status'];
  onSubmit?: (value: Pick<DataSet, 'name' | 'columnDefs'>) => void;
  onClose: () => void;
  onDelete?: () => void;
};

export const DataSetFormModal: React.FC<React.PropsWithChildren<DataSetFormModalProps>> = ({
  isOpen,
  initialValues,
  onClose,
  onSubmit,
  onDelete,
  saveStatus,
  deleteStatus,
}) => {
  const [value, setValue] = React.useState<string>(initialValues?.name ?? '');
  const isSaving = saveStatus === 'loading';
  const isDeleting = deleteStatus === 'loading';
  const isLoading = isSaving || isDeleting;

  const handleSubmit = () => {
    const payload = {
      ...initialValues,
      name: value,
      columnDefs: initialValues?.columnDefs ?? [],
    };

    onSubmit?.(payload);
  };

  React.useEffect(
    function setInitialName() {
      setValue(initialValues?.name ?? '');
    },
    [initialValues?.name],
  );

  return (
    <Modal isOpen={isOpen} onClose={isLoading ? () => {} : onClose}>
      <ModalOverlay />
      <ModalContent
        mt="20"
        as="form"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <ModalHeader border="0" borderBottomWidth="1px" borderStyle="solid" borderColor="gray.200">
          <HStack spacing="2">
            <Icon icon="cog" size="4" color="gray.400" />

            <Text size="xl" fontWeight="700" color="gray.700">
              Data Set
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton isDisabled={isSaving} />

        <ModalBody pt="6" pb="4">
          <VStack alignItems="flex-start">
            <Text fontSize="sm" as="label" htmlFor="name">
              Data Set name
            </Text>
            <Input
              isDisabled={isSaving}
              value={value}
              w="full"
              id="name"
              name="name"
              onChange={e => setValue(e.target.value)}
              placeholder="Type name here"
              autoFocus
            />
          </VStack>
        </ModalBody>

        <ModalFooter pb="6" spacing="0" justifyContent="space-between" as={HStack}>
          {onDelete ? (
            <Button
              color="red.500"
              leftIcon={<Icon icon="trash-can" size="4" color="red.500" />}
              px="0"
              padding={2}
              fontWeight="500"
              variant="ghost"
              colorScheme="red"
              onClick={onDelete}
              loadingText="Deleting Data Set..."
              isLoading={isDeleting}
              isDisabled={isLoading}
            >
              Delete Data Set
            </Button>
          ) : (
            <Spacer />
          )}
          <HStack>
            <Button
              variant="solid"
              colorScheme="gray"
              mr={3}
              onClick={() => {
                setValue(initialValues?.name ?? '');
                onClose();
              }}
              isDisabled={isSaving}
            >
              Cancel
            </Button>
            <Button
              isLoading={isSaving}
              isDisabled={isLoading || !value || value === initialValues?.name}
              colorScheme="brand"
              variant="solid"
              loadingText={'Saving...'}
              type="submit"
            >
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
