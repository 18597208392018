import React from 'react';
import { Box } from 'components/design/next';
import { RichTextEditor } from 'features/rich-text';
import { noop } from 'lodash';
import { Muid } from '@process-street/subgrade/core';
import { SendRichEmailFormFieldWidget, TaskTemplate } from '@process-street/subgrade/process';
import { TinyMCEEditor } from 'features/rich-text';
import { TextWidgetToolbar } from 'app/pages/forms/_id/edit/components/content/text-content/components/toolbar';

interface SendRichEmailTemplateEditorProps {
  widget: SendRichEmailFormFieldWidget;
  body: string;
  templateRevisionId: Muid;
  onUpdate?: (widget: Pick<SendRichEmailFormFieldWidget['config'], 'richEditorBody'>) => void;
  taskTemplate?: TaskTemplate; // Not required for Checklist, but required for Edit
}

export const SendRichEmailTemplateEditor: React.FC<React.PropsWithChildren<SendRichEmailTemplateEditorProps>> =
  React.memo(({ widget, body, onUpdate = noop, templateRevisionId, taskTemplate }) => {
    const [editor, setEditor] = React.useState<TinyMCEEditor>();
    const [editorValue, setEditorValue] = React.useState<string>(() => body ?? '');
    const handleInit = (_event: any, editor: TinyMCEEditor) => setEditor(editor);
    const handleBlur = React.useCallback(() => onUpdate({ richEditorBody: editorValue }), [onUpdate, editorValue]);
    const handleEditorChange = React.useCallback((content: string) => setEditorValue(content), []);
    const toolbarPopoverContentRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(
      function syncPropChanges() {
        if (body !== editorValue) {
          setEditorValue(body);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps -- remove 'editorValue' to only react to incoming prop changes
      [body],
    );

    return (
      <Box w="full" position="relative">
        <TextWidgetToolbar
          editor={editor}
          widget={{
            ...widget,
            header: {
              ...widget.header,
              ...(taskTemplate ? { taskTemplate } : {}),
            },
          }}
          templateRevisionId={templateRevisionId}
          popoverContentRef={toolbarPopoverContentRef}
        />
        <Box w="full" />
        <RichTextEditor
          editorProps={{
            value: editorValue,
            onInit: handleInit,
            onEditorChange: handleEditorChange,
            onBlur: handleBlur,
          }}
          init={{
            convert_newlines_to_brs: false,
            placeholder: 'Start writing something…',
            link_default_target: '_blank',
            toolbar: false,
          }}
          editorWrapperProps={{
            'aria-label': 'send rich email template container',
            'sx': {
              'px': 4,
              'fontSize': 'md',
              'minHeight': '207px',
              // Overrides mce's default styles. This way, the user has control of the styles.
              '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before': {
                fontStyle: 'normal',
                pl: 1,
                fontSize: 'md',
                color: 'gray.400',
              },
            },
          }}
        />
      </Box>
    );
  });
