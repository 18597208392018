import * as React from 'react';
import { AutomationsStatusIndicator, AutomationsStatusIndicatorDisclosureId } from './component';
import { Muid } from '@process-street/subgrade/core';
import { Box, useDisclosure } from 'components/design/next';

export type AutomationsStatusIndicatorContainerProps = {
  checklistId: Muid;
};

export const AutomationsStatusIndicatorContainer: React.FC<
  React.PropsWithChildren<AutomationsStatusIndicatorContainerProps>
> = ({ checklistId }) => {
  const { isOpen } = useDisclosure({
    id: AutomationsStatusIndicatorDisclosureId,
  });

  const styles = isOpen
    ? {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }
    : {
        position: 'static',
      };

  return (
    <Box sx={styles}>
      <AutomationsStatusIndicator checklistId={checklistId} checklistOpenedAt={Date.now()} />
    </Box>
  );
};
