import { cookies } from './cookies';
import { dayjs as moment } from '@process-street/subgrade/util';
import { getEnv } from 'components/common/env';
import { CookieSetOptions } from 'universal-cookie';
import { match } from 'ts-pattern';

const getCookieDomain = () => '.' + new URL(getEnv().APP_URL ?? '').hostname.split('.').slice(-2).join('.');

const getEnvBasedCookieKey = (cookieName: string) =>
  match(getEnv().APP_STAGE)
    .with('dev', () => `st.process.app-dev.${cookieName}`)
    .with('test', () => `st.process.app-test.${cookieName}`)
    .otherwise(() => `st.process.app.${cookieName}`);

const TOKEN_COOKIE_KEY = 'st.process.app.authToken';
const hasTokenCookie = () => Boolean(cookies.get<string | undefined>(TOKEN_COOKIE_KEY, { doNotParse: true }));

const getTokenCookie = () => cookies.get<string | undefined>(TOKEN_COOKIE_KEY, { doNotParse: true });

const setTemporaryTokenCookie = (token: string) => {
  // This is used to give access to the CSV export API endpoint
  setSimpleCookie(TOKEN_COOKIE_KEY, token, {
    expires: moment().add(5, 'minutes').toDate(),
  });
};

const removeTemporaryTokenCookie = () => {
  cookies.remove(TOKEN_COOKIE_KEY, {
    domain: getCookieDomain(),
    path: '/',
    secure: true,
  });
};

const FILES_AUTH_TOKEN_COOKIE_NAME = 'filesAuthToken';
const getFilesAuthTokenCookieKey = () => getEnvBasedCookieKey(FILES_AUTH_TOKEN_COOKIE_NAME);
const getFilesAuthTokenCookie = () => getSimpleCookie(getFilesAuthTokenCookieKey());

const setFilesAuthTokenCookie = (token: string) => {
  setSimpleCookie(getFilesAuthTokenCookieKey(), token, {
    expires: moment().add(1, 'week').toDate(),
    // allows cookie to be accessed in iframe (e.g. Teams, Retool etc.)
    sameSite: 'none',
    secure: true,
    path: '/files',
  });
};

const removeFilesAuthTokenCookie = () => {
  cookies.remove(getFilesAuthTokenCookieKey(), {
    domain: getCookieDomain(),
    path: '/files',
    secure: true,
  });
};

const USER_ID_COOKIE_NAME = 'userId';
const getUserIdCookieKey = () => getEnvBasedCookieKey(USER_ID_COOKIE_NAME);

const setUserIdCookie = (userId: string) => {
  // We store the user id so that marketing knows if this is a app user or not
  // also it syncs user ID across subdomains
  setSimpleCookie(getUserIdCookieKey(), userId, {
    expires: moment().add(1, 'year').toDate(),
    // allows cookie to be accessed in iframe (e.g. Teams, Retool etc.)
    sameSite: 'none',
  });
};

const removeUserIdCookie = () => {
  cookies.remove(getUserIdCookieKey(), {
    domain: getCookieDomain(),
    path: '/',
    secure: true,
  });
};

const getUserIdCookie = () => getSimpleCookie(getUserIdCookieKey());

const getSimpleCookie = (key: string) => cookies.get<string | undefined>(key, { doNotParse: true });

const setSimpleCookie = (key: string, value: string, options: CookieSetOptions): void => {
  cookies.set(key, value, {
    domain: getCookieDomain(),
    path: '/',
    secure: true,
    ...options,
  });
};

const removeSimpleCookie = (key: string) => {
  cookies.remove(key, {
    domain: getCookieDomain(),
    path: '/',
    secure: true,
  });
};

export const CookieService = {
  hasTokenCookie,
  getTokenCookie,
  setTemporaryTokenCookie,
  removeTemporaryTokenCookie,
  getFilesAuthTokenCookie,
  setFilesAuthTokenCookie,
  removeFilesAuthTokenCookie,
  getUserIdCookie,
  setUserIdCookie,
  removeUserIdCookie,
  getSimpleCookie,
  setSimpleCookie,
  removeSimpleCookie,
};
