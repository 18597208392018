import { TinyMCEEditor } from 'app/features/rich-text';
import * as React from 'react';
import { EditorEvent } from 'tinymce';

export type UseCommandsStatusParams = {
  editor?: TinyMCEEditor;
};

export const useCommandsStatus = ({ editor }: UseCommandsStatusParams) => {
  const [activeCommands, setActiveCommands] = React.useState<Record<string, boolean>>({});
  const [disabledCommands, setDisabledCommands] = React.useState<Record<string, boolean>>({});

  React.useEffect(
    function calculateActiveAndDisabledCommands() {
      const handleNodeChange = ({ parents }: EditorEvent<{ parents: Array<Node> }>) => {
        try {
          if (!parents) return;

          setActiveCommands(() => {
            return parents.reduce<Record<string, boolean>>((acc, el) => {
              return {
                Bold: acc['Bold'] || ['B', 'STRONG'].includes(el.nodeName),
                Italic: acc['Italic'] || ['I', 'EM'].includes(el.nodeName),
                Underline:
                  acc['Underline'] || el.nodeName === 'U' || (el as HTMLElement).style?.cssText.includes('underline'),
                Strikethrough: el.nodeName === 'S' || (el as HTMLElement).style?.cssText.includes('line-through'),
                mceLink: acc['mceLink'] || el.nodeName === 'A',
                insertUnorderedList: acc['insertUnorderedList'] || ['UL'].includes(el.nodeName),
                insertOrderedList: acc['insertOrderedList'] || ['OL'].includes(el.nodeName),
                JustifyLeft: acc['JustifyLeft'] || (el as HTMLElement).style?.cssText.includes('text-align: left'),
                JustifyRight: acc['JustifyRight'] || (el as HTMLElement).style?.cssText.includes('text-align: right'),
                JustifyFull: acc['JustifyFull'] || (el as HTMLElement).style?.cssText.includes('text-align: justify'),
                JustifyCenter:
                  acc['JustifyCenter'] || (el as HTMLElement).style?.cssText.includes('text-align: center'),
                h1: acc['h1'] || el.nodeName === 'H1',
                h2: acc['h2'] || el.nodeName === 'H2',
                h3: acc['h3'] || el.nodeName === 'H3',
                pre: acc['pre'] || el.nodeName === 'pre',
                success: acc['success'] || (el as HTMLElement).classList.contains('style-success'),
                info: acc['info'] || (el as HTMLElement).classList.contains('style-info'),
                reminder: acc['reminder'] || (el as HTMLElement).classList.contains('style-warning'),
                critical: acc['critical'] || (el as HTMLElement).classList.contains('style-danger'),
                bq: acc['bq'] || el.nodeName === 'BLOCKQUOTE',
                code: acc['code'] || el.nodeName === 'CODE',
              };
            }, {});
          });

          setDisabledCommands(() => ({
            Redo: !editor?.undoManager.hasRedo(),
            Undo: !editor?.undoManager.hasUndo(),
          }));
        } catch (e) {
          console.error(e);
        }
      };

      editor?.on('NodeChange', handleNodeChange);
      return () => {
        editor?.off('NodeChange', handleNodeChange);
      };
    },
    [editor],
  );

  return { activeCommands, disabledCommands };
};
