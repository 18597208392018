import angular from 'angular';
import { ChecklistStatus } from '@process-street/subgrade/process';
import { ChecklistConstants } from '@process-street/subgrade/process/checklist-constants';
import { AnalyticsConstants } from '@process-street/subgrade/analytics/analytics-constants';
import { EventName } from 'services/event-name';
import { AuthEvent } from 'services/auth-event/AuthEvent';
import { trace } from 'components/trace';
import { ChecklistEvent } from 'services/checklists/checklist-event';
import { ChecklistAssignmentEvent } from 'services/assignments/checklist-assignment-event';
import { FormFieldEvent } from 'services/form-field-event';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.services')
  .service('AnalyticsListener', function ($state, ChecklistService, FacebookService) {
    const self = this;

    const logger = trace({ name: 'AnalyticsListener' });

    self.listen = function (scope) {
      // Authentication

      scope.$on(AuthEvent.USER_LOGGED_IN, (__event, __user, profile) => {
        AnalyticsService.trackEvent(AnalyticsConstants.Event.USER_LOGGED_IN, {
          provider: profile?.provider,
        });
      });

      scope.$on(AuthEvent.USER_CREATED_ON_AUTH, (__event, user) => {
        FacebookService.trackCompleteRegistration();

        if (user.origin !== 'Invitation') {
          AnalyticsService.trackEvent(AnalyticsConstants.Event.USER_SIGNED_UP_STEP_1, [
            AnalyticsConstants.Key.USER_SOURCE,
            user.source,
            AnalyticsConstants.Key.USER_ORIGIN,
            user.origin,
          ]);
        }

        AnalyticsService.trackEvent(AnalyticsConstants.Event.USER_SIGNED_UP_STEP_2, [
          AnalyticsConstants.Key.USER_SOURCE,
          user.source,
          AnalyticsConstants.Key.USER_ORIGIN,
          user.origin,
          AnalyticsConstants.Key.USER_SUBSCRIBED_TO_BLOG,
          false,
        ]);
      });

      scope.$on(EventName.WIDGET_CREATE_OK, (__event, widget, data) => {
        const widgetClassName = `${widget.header.type}Widget`;

        AnalyticsService.trackEvent(AnalyticsConstants.Event.WIDGET_CREATED, {
          'type': widgetClassName, // Legacy property
          'method': data.method,
          'widget id': widget.id,
          'widget type': widget.header.type,
          'field type': widget.fieldType,
          'task id': data.taskTemplate.id,
          'task name': data.taskTemplate.name,
          //'template id': data.template.id,
          //'template name': data.template.name
        });
      });

      scope.$on(EventName.WIDGET_DELETE_OK, (__event, widget, data) => {
        const widgetClassName = `${widget.header.type}Widget`;

        AnalyticsService.trackEvent('widget delete', {
          'type': widgetClassName, // Legacy property
          'widget id': widget.id,
          'widget type': widget.header.type,
          'field type': widget.fieldType,
          'task id': data.taskTemplate.id,
          'task name': data.taskTemplate.name,
        });
      });

      scope.$on(FormFieldEvent.SUB_CHECKLIST_ITEM_STATUS_UPDATED, (__event, item, itemValue, data) => {
        AnalyticsService.trackEvent(AnalyticsConstants.Event.SUB_CHECKLIST_ITEM_STATUS_UPDATED, {
          'item id': item.id,
          'item name': item.name,
          'item status': itemValue.status,
          'task id': data.taskTemplate.id,
          'task name': data.taskTemplate.name,
          'checklist id': data.checklist.id,
          'checklist name': data.checklist.name,
          'template id': data.template.id,
          'template name': data.template.name,
        });
      });

      // Checklists

      scope.$on(ChecklistEvent.CREATE_OK, (__event, checklist, template, options) => {
        let checklistStats;
        ChecklistService.getStatsByIds([checklist.id])
          .then(
            stats => {
              [checklistStats] = stats;
            },
            () => {
              logger.error('failed to retrieve checklist stats for analytics');
            },
          )
          .finally(() => {
            const taskAssignmentRulesStats = (checklistStats && checklistStats.taskAssignmentRulesStats) || {};

            const totalRoleAssignmentRulesCount =
              (taskAssignmentRulesStats.checklistCreator || 0) +
              (taskAssignmentRulesStats.membersField || 0) +
              (taskAssignmentRulesStats.emailField || 0);

            AnalyticsService.trackEvent(AnalyticsConstants.Event.CHECKLIST_CREATED, {
              'template id': template.id,
              'template name': template.name,
              'checklist id': checklist.id,
              'checklist name': checklist.name,
              'checklist due date': checklist.dueDate && new Date(checklist.dueDate).toISOString(),
              'method': options.method || ChecklistConstants.Method.FRONT_END,
              'number of rules': checklistStats && checklistStats.rulesCount,
              'number of task rules': checklistStats && checklistStats.taskRulesCount,
              'number of content block rules': checklistStats && checklistStats.contentRulesCount,
              'number of dynamic due dates': checklistStats && checklistStats.dynamicDueDatesCount,
              'number of stop tasks': checklistStats && checklistStats.stopTasksCount,
              'number of role-based assignments': totalRoleAssignmentRulesCount,
              'number of checklist initiator role-based assignments': taskAssignmentRulesStats.checklistCreator,
              'number of members field role-based assignments': taskAssignmentRulesStats.membersField,
              'number of email field role-based assignments': taskAssignmentRulesStats.emailField,
              'number of task template permits': checklistStats && checklistStats.taskTemplatePermitsCount,
              'number of task permission rules': checklistStats && checklistStats.taskPermissionRulesCount,
              'number of approval tasks': checklistStats && checklistStats.approvalTasksCount,
            });
          });
      });

      scope.$on(ChecklistEvent.UPDATE_OK, (__event, data) => {
        const { originalChecklist, updatedChecklist } = data;
        const eventData = {
          'checklist id': updatedChecklist.id,
          'checklist name': updatedChecklist.name,
          'template id': originalChecklist.template.id,
          'template name': originalChecklist.template.name,
        };

        let analyticsEventName;
        if (
          originalChecklist.status === ChecklistStatus.Archived &&
          updatedChecklist.status === ChecklistStatus.Active
        ) {
          analyticsEventName = AnalyticsConstants.Event.CHECKLIST_UNARCHIVED;
        } else if (
          originalChecklist.status === ChecklistStatus.Completed &&
          updatedChecklist.status === ChecklistStatus.Active
        ) {
          analyticsEventName = AnalyticsConstants.Event.CHECKLIST_REACTIVATED;
        } else if (updatedChecklist.status === ChecklistStatus.Archived) {
          analyticsEventName = AnalyticsConstants.Event.CHECKLIST_ARCHIVED;
        } else if (updatedChecklist.status === ChecklistStatus.Completed) {
          analyticsEventName = AnalyticsConstants.Event.CHECKLIST_COMPLETED;
          const source = resolveChecklistUpdateOkEventSource();
          if (source) {
            eventData.source = source;
          }
        }

        AnalyticsService.trackEvent(analyticsEventName, eventData);
      });

      function resolveChecklistUpdateOkEventSource() {
        const currentStateName = $state.current && $state.current.name;

        let source;
        if (currentStateName.startsWith('inbox.item')) {
          source = 'inbox modal';
        } else if (currentStateName.startsWith('inbox')) {
          source = 'inbox list';
        } else if (currentStateName.startsWith('checklist')) {
          source = 'checklist';
        }

        return source;
      }

      scope.$on(ChecklistAssignmentEvent.CREATE_OK, (__event, data) => {
        AnalyticsService.trackEvent(AnalyticsConstants.Event.CHECKLIST_ASSIGNED, {
          'checklist id': data.checklist.id,
          'checklist name': data.checklist.name,
          'template name': data.checklist.template.name,
        });
      });

      // Tasks

      scope.$on(EventName.TASKS_CHECKED_UNCHECKED, (__event, data) => {
        const eventData = {
          'status': data.status,
          'checklist name': data.checklistName,
          'template name': data.templateName,
        };
        const source = resolveChecklistUpdateOkEventSource();
        if (source) {
          eventData.source = source;
        }

        AnalyticsService.trackEvent(AnalyticsConstants.Event.TASKS_CHECKED_UNCHECKED, eventData);
      });
    };
  });
