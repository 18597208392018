import {
  faBallot,
  faBolt,
  faBrowser,
  faBuilding,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCircle,
  faCircleExclamation,
  faClock,
  faDatabase,
  faDownLeftAndUpRightToCenter,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faEyeDropper,
  faFile,
  faFileAlt,
  faFolder,
  faFolderOpen,
  faGripVertical,
  faHome,
  faImage,
  faInfo,
  faLock,
  faMountainSun,
  faPlay,
  faPlus,
  faSitemap,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faSnooze,
  faTasks,
  faTrash,
  faUpRightAndDownLeftFromCenter,
  faUserHeadset,
  faUserLock,
  faUserSlash,
  faUsers,
  faWandMagicSparkles,
  faWifiSlash,
} from '@fortawesome/pro-solid-svg-icons';

export const fasLibrary = {
  faBallot,
  faBolt,
  faBrowser,
  faBuilding,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCircle,
  faCircleExclamation,
  faClock,
  faDatabase,
  faDownLeftAndUpRightToCenter,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faEyeDropper,
  faFile,
  faFileAlt,
  faFolder,
  faFolderOpen,
  faGripVertical,
  faHome,
  faImage,
  faInfo,
  faLock,
  faMountainSun,
  faPlay,
  faPlus,
  faSitemap,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faSnooze,
  faTasks,
  faTrash,
  faUpRightAndDownLeftFromCenter,
  faUserHeadset,
  faUserLock,
  faUserSlash,
  faUsers,
  faWandMagicSparkles,
  faWifiSlash,
};
