import { createCachedAction } from 'reducers/util';

// Rules
export const RA_RULES_GET_ALL_BY_TEMPLATE_REVISION = 'frontStreet/roleAssignmentRules/GET_ALL_BY_CHECKLIST_REVISION';
export const RA_RULE_CREATE = 'frontStreet/roleAssignmentRules/CREATE';
export const RA_RULE_CREATE_ALL = 'frontStreet/roleAssignmentRules/CREATE_ALL';
export const RA_RULE_DELETE = 'frontStreet/roleAssignmentRules/DELETE';
export const RA_RULE_DELETE_ALL = 'frontStreet/roleAssignmentRules/DELETE_ALL';

export const RoleAssignmentRulesActions = RoleAssignmentRulesApi => {
  'ngInject';

  const getAllByTemplateRevisionId = createCachedAction(
    RA_RULES_GET_ALL_BY_TEMPLATE_REVISION,
    RoleAssignmentRulesApi.getAllByTemplateRevisionId,
    templateRevisionId => ({ templateRevisionId }),
  );

  const create = createCachedAction(RA_RULE_CREATE, RoleAssignmentRulesApi.create, templateRevisionId => ({
    templateRevisionId,
  }));

  const createAll = createCachedAction(RA_RULE_CREATE_ALL, RoleAssignmentRulesApi.createAll, templateRevisionId => ({
    templateRevisionId,
  }));

  const deleteRule = createCachedAction(
    RA_RULE_DELETE,
    RoleAssignmentRulesApi.deleteRule,
    (templateRevisionId, ruleId) => ({ templateRevisionId, ruleId }),
  );

  const deleteAll = createCachedAction(
    RA_RULE_DELETE_ALL,
    RoleAssignmentRulesApi.deleteAll,
    (templateRevisionId, ruleIds) => ({ templateRevisionId, ruleIds }),
  );

  return {
    getAllByTemplateRevisionId,
    create,
    createAll,
    deleteRule,
    deleteAll,
  };
};
