import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { useOrganizationMembershipRole } from '../use-organization-membership-role';
import { useTemplateTypeContext } from 'utils/template/template-type-context';

export const useShouldBlockEditWorkflow = (): {
  shouldBlock: boolean;
  role: OrganizationMembershipRole | undefined;
} => {
  const role = useOrganizationMembershipRole();
  const { isWorkflow } = useTemplateTypeContext();
  const shouldBlockAction = isWorkflow && Boolean(role === OrganizationMembershipRole.FreeMember);
  return { shouldBlock: shouldBlockAction, role };
};
