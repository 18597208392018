import { FormLabel, FormLabelProps, forwardRef } from 'components/design/next';
import React from 'react';
import { FormControl } from '@chakra-ui/react';

export const WorkflowFormFieldLabel = forwardRef<FormLabelProps & { isRequired: boolean }, 'label'>((props, ref) => {
  return (
    <FormControl isRequired={props.isRequired}>
      <FormLabel fontWeight="600" m="0" ref={ref} {...props} />
    </FormControl>
  );
});
