import { AnonymousAutenticationRequest } from '@process-street/subgrade/auth/anonymous-user';
import { AnonymousUserInfo } from '@process-street/subgrade/core';
import { AuthenticationResourceTypes } from '../../resources/authentication-resource-types';
import angular from 'angular';

export interface AuthenticationApi {
  authenticateAnonymous(args: AnonymousAutenticationRequest): angular.IPromise<AnonymousUserInfo>;
}

export class AuthenticationApiImpl implements AuthenticationApi {
  private authenticationResource: AuthenticationResourceTypes;

  static $inject = ['Authentication'];

  constructor(authenticationResource: AuthenticationResourceTypes) {
    this.authenticationResource = authenticationResource;

    this.authenticateAnonymous = this.authenticateAnonymous.bind(this);
  }

  public authenticateAnonymous(request: AnonymousAutenticationRequest): angular.IPromise<AnonymousUserInfo> {
    return this.authenticationResource.authenticateAnonymous(request).$promise;
  }
}
