import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';

export type ForcedState = 'open' | 'closed' | 'none';
type Context = {
  setForcedState: React.Dispatch<React.SetStateAction<ForcedState>>;
  forcedState: ForcedState;
};

export const [useBalloonToolbarControls, BalloonToolbarControlsContext] = createUsableContext<Context>({
  hookName: 'useBalloonToolbarControls',
  providerName: 'BalloonToolbarControlsContext',
});
