import { match } from 'ts-pattern';

export enum AuthenticationError {
  StateDoesNotMatch = 'StateDoesNotMatch',
  UnexpectedFailure = 'UnexpectedFailure',
  CancelledByUser = 'CancelledByUser',
}

const AUTH_STATE_KEY = 'msTeams.auth.state';

const getEmbeddedBy = (): string => {
  return window.location !== window.parent.location ? document.referrer : document.location.href;
};

const isEmbeddedByMsTeamsMobile = (): boolean => {
  // The MS Teams mobile app adds nativeInterface to the window.
  return window.parent === window.self && (window as any).nativeInterface;
};

const isLoadedInsideMsTeams = (): boolean => {
  return window !== window.top && getEmbeddedBy().startsWith('https://teams.microsoft.com');
};

const isEmbeddedByMsTeamsIFrame = (): boolean => {
  return (
    window.name === 'embedded-page-container' ||
    window.name === 'extension-tab-frame' ||
    Boolean(document.querySelector('iframe.embedded-iframe'))
  );
};

const isEmbeddedInMsTeams = (): boolean => {
  return isEmbeddedByMsTeamsMobile() || isEmbeddedByMsTeamsIFrame() || isLoadedInsideMsTeams();
};

const setAuthState = (state: string): void => {
  localStorage.setItem(AUTH_STATE_KEY, state);
};

const getAuthState = (): string | null => {
  return localStorage.getItem(AUTH_STATE_KEY);
};

const getAuthErrorMessage = (error: AuthenticationError): string => {
  return match(error)
    .with(
      AuthenticationError.CancelledByUser,
      () =>
        'The login process was interrupted because the pop-up was closed. Please keep the pop-up open until the login process is completed.',
    )
    .otherwise(() => 'Something went wrong. Please try again.');
};

export const MSTeamsUtils = {
  isEmbeddedInMsTeams,
  setAuthState,
  getAuthState,
  getAuthErrorMessage,
};
