import { dayjs as moment } from '@process-street/subgrade/util';
import React, { memo } from 'react';

// models
import { UploadAttachment } from '../CommentAttachment.interface';

import styles from './CommentAttachmentActions.module.scss';

export interface CommentAttachmentActionsProps {
  attachment: UploadAttachment;
  onDeleteAttachment: () => void;
  onCancelUpload: () => void;
}

export const CommentAttachmentActions: React.FunctionComponent<React.PropsWithChildren<CommentAttachmentActionsProps>> =
  memo(({ attachment, onDeleteAttachment, onCancelUpload }) => {
    const createdDate = moment(attachment.audit.createdDate).format('MMM D [at] h:mmA');
    const showCancelButton = attachment.id && attachment._uploadProgress !== undefined;
    const showDeleteButton = attachment._uploadProgress === undefined && attachment.id;

    return (
      <div className={styles.commentAttachmentDate}>
        {createdDate}
        <span className={styles.commentInteractionsContainer}>
          &nbsp;&middot;&nbsp;
          {showDeleteButton && <a onClick={onDeleteAttachment}>Delete</a>}
          {showCancelButton && <a onClick={onCancelUpload}>Cancel</a>}
        </span>
      </div>
    );
  });
