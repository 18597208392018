import { Muid, User } from '@process-street/subgrade/core';
import { FeatureGateService } from 'components/utils/feature-gate.service';
import { canAccess, Feature } from 'services/features/features';
import { getEnv } from 'components/common/env';

export class FeatureGateServiceImpl implements FeatureGateService {
  public isProduction(): boolean {
    return getEnv().APP_STAGE === 'prod';
  }

  public isBeta(user: User): boolean {
    return user.beta;
  }

  public isDeveloper(user: User): boolean {
    return user.developer;
  }

  public isProcessStEmail(email: string): boolean {
    return email.endsWith('@process.st');
  }

  public isTaskPermissionsFeatureEnabled(_user: User): boolean {
    return true;
  }

  public isRoleBasedTaskPermissionsFeatureEnabled(_user: User): boolean {
    return true;
  }

  public isProcessStreetOrBetaUser(user: User): boolean {
    return this.isProcessStEmail(user.email) || this.isBeta(user);
  }

  public isProcessStreetAndBetaUser(user: User): boolean {
    return this.isProcessStEmail(user.email) && this.isBeta(user);
  }

  public isApprovalsFeatureAvailable(_user: User, planId: Muid): boolean {
    return canAccess(Feature.APPROVALS, planId);
  }
}
