import { Muid, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { BulkResult, TemplateTaskAssignment } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type AssignAllTaskTemplatesMutationParams = {
  taskTemplatesIds: Muid[];
  email: string;
};

export type AssignAllTaskTemplatesMutationResponse = {
  response: BulkResult;
  /** id === taskTemplateAssignment.taskTemplate.id */
  id: Muid;
  taskTemplateAssignment: TemplateTaskAssignment;
  organizationMembership: OrganizationMembershipWithUser;
}[];

export const AssignAllTaskTemplatesMutation = {
  key: ['put', 'task-templates', 'assign'],
  getKey: (params: AssignAllTaskTemplatesMutationParams) => [...AssignAllTaskTemplatesMutation.key, params.email],
  mutationFn: (params: AssignAllTaskTemplatesMutationParams) =>
    axiosService
      .getAxios()
      .put<AssignAllTaskTemplatesMutationResponse>(`/1/task-templates/assign`, params)
      .then(res => res.data),
};

export const useAssignAllTaskTemplatesMutation = (
  options: UseMutationOptions<
    AssignAllTaskTemplatesMutationResponse,
    AxiosError,
    AssignAllTaskTemplatesMutationParams
  > = {},
) => {
  return useMutation(AssignAllTaskTemplatesMutation.mutationFn, options);
};
