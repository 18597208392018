import angular from 'angular';
import templateUrl from './comment.component.html';
import { CommentService } from 'services/comment-service';
import { parseEmbedServiceValue } from '@process-street/subgrade/util/embed-service-code-parser';

angular.module('frontStreetApp.directives').component('psComment', {
  bindings: {
    user: '<',
    comment: '<',
    deletable: '<',
    answerable: '<',
    mentionableUsers: '<',
    disabled: '<',
    onAnswer: '&',
    onDelete: '&',
  },
  templateUrl,
  controller($state, marked, UserService) {
    const ctrl = this;

    ctrl.$onInit = function () {
      const { createdBy } = ctrl.comment.audit;
      if (createdBy.username) {
        ctrl.createdByUser = createdBy;
        resolveUserActivityUrl(ctrl.createdByUser);
      } else if (createdBy.id) {
        UserService.getById(createdBy.id).then(user => {
          ctrl.createdByUser = user;
          resolveUserActivityUrl(ctrl.createdByUser);
        });
      } else {
        throw new Error('createdBy has no id but should have');
      }
    };

    function resolveUserActivityUrl(user) {
      ctrl.userActivityUrl = $state.href('userManage', { id: user.id, tab: 'activity' });
    }

    ctrl.$onChanges = function (changes) {
      if (changes.mentionableUsers) {
        ctrl.mentionableUsers = changes.mentionableUsers.currentValue || [];
      }

      ctrl.commentContent = ctrl.renderCommentContent();
    };

    ctrl.renderCommentContent = function () {
      let renderedContent = ctrl.comment.content;
      renderedContent = marked(renderedContent);

      ctrl.embeddedVideos = ctrl._extractEmbeddedVideos(renderedContent);

      const users = ctrl.mentionableUsers.concat([ctrl.user]);

      renderedContent = CommentService.tagMentionedUsers(renderedContent, users);
      return renderedContent;
    };

    const URL_REGEX = /href="([^'"]+)/g;

    ctrl._extractEmbeddedVideos = function (renderedContent) {
      const embeddedVideos = [];

      let match;
      do {
        match = URL_REGEX.exec(renderedContent);
        if (match) {
          const foundEmbed = parseEmbedServiceValue(match[1]);
          if (foundEmbed) {
            embeddedVideos.push(foundEmbed);
          }
        }
      } while (match);

      return embeddedVideos;
    };

    ctrl.answerComment = function () {
      ctrl.onAnswer({ doodad: ctrl.comment });
    };

    ctrl.deleteComment = function () {
      ctrl.onDelete({ doodad: ctrl.comment });
    };
  },
});
