import * as React from 'react';
import { HStack, Icon, IconButton, Input, Text } from 'components/design/next';
import { DataSet } from '@process-street/subgrade/process';
import { useEditDataSet } from 'pages/reports/data-sets/components/data-set-page/use-edit-data-set';
import { WebhooksButton } from 'pages/reports/data-sets/components/webhooks-button/webhooks-button';
import { StringUtils } from '@process-street/subgrade/util';

export type DataSetToolbarProps = {
  dataSet: DataSet;
  onUpdate: (updated: DataSet) => void;
};

export const DataSetToolbar: React.FC<DataSetToolbarProps> = ({ dataSet, onUpdate }) => {
  const isDataSetRecentlyCreated = React.useMemo(() => {
    return Date.now() - dataSet.audit.createdDate < 5000;
  }, [dataSet.audit.createdDate]);

  const [dataSetName, setDataSetName] = React.useState(dataSet.name);
  const [revertName, setRevertName] = React.useState(dataSet.name);
  const [isEditingName, setIsEditingName] = React.useState(false);
  const shouldShowEditNameInput = isEditingName;
  // Blur runs right after pressing escape, so we recognize the reverting case with this
  const lastKeyPressEventRef = React.useRef<React.KeyboardEvent | null>(null);

  React.useEffect(
    function onDataSetSelected() {
      setDataSetName(dataSet.name);
    },
    [dataSet],
  );

  const canUpdateDataSet = dataSet.canAccessDataSet;

  const handleEditStart = () => {
    setRevertName(dataSetName);
    setIsEditingName(true);
  };

  const handleRevert = () => {
    setDataSetName(revertName);
    setIsEditingName(false);
  };

  const { handleSubmit } = useEditDataSet(dataSet.id);

  const handleSave = () => {
    if (lastKeyPressEventRef.current?.key === 'Escape') return;
    setIsEditingName(false);
    if (dataSetName === dataSet.name) {
      return;
    } else if (StringUtils.isBlank(dataSetName)) {
      handleRevert();
    } else {
      handleSubmit({
        name: dataSetName,
        columnDefs: dataSet.columnDefs,
      });
      onUpdate({ ...dataSet, name: dataSetName });
    }
  };

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = e => {
    if (e.target.value === 'Untitled Data Set') {
      e.target.select();
    }
  };

  React.useEffect(
    function toggleEditModeForRecentlyCreatedDataSets() {
      if (isDataSetRecentlyCreated) {
        setTimeout(
          () => {
            handleEditStart();
          },
          // Wait until the re-renders are finished so the input element won't lose focus
          100,
        );
      }
    },
    // We don't need to run this effect when `handleEditStart` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDataSetRecentlyCreated],
  );

  return (
    <HStack
      as="form"
      justifyContent="space-between"
      onSubmit={e => {
        e.preventDefault();
        handleSave();
      }}
      gap={4}
    >
      <HStack
        _hover={{
          '& .chakra-button': {
            visibility: shouldShowEditNameInput ? 'hidden' : 'visible',
          },
        }}
        w="fit-content"
        maxW="xl"
      >
        {shouldShowEditNameInput ? (
          <Input
            autoFocus
            fontWeight="medium"
            border="none"
            p="0"
            color="gray.600"
            type="text"
            minW={4}
            w="xl"
            top="-5px"
            fontSize="xl"
            value={dataSetName}
            onChange={e => setDataSetName(e.target.value)}
            onBlur={handleSave}
            onFocus={handleFocus}
            onKeyDown={e => {
              lastKeyPressEventRef.current = e;
              if (e.key === 'Escape') handleRevert();
            }}
          />
        ) : (
          <Text
            fontWeight="medium"
            color="gray.600"
            fontSize="xl"
            as="h1"
            mb="10px"
            noOfLines={1}
            _hover={{
              cursor: 'text',
            }}
            wordBreak="break-all"
            onClick={canUpdateDataSet ? handleEditStart : undefined}
          >
            {dataSetName}
          </Text>
        )}
        {canUpdateDataSet && (
          <IconButton
            visibility="hidden"
            minW="unset"
            p="0"
            variant="ghost"
            w={5}
            h={5}
            mb="10px"
            aria-label="Edit template name"
            _hover={{ bgColor: 'transparent' }}
            onClick={handleEditStart}
            color="gray.700"
            icon={<Icon icon="edit" size="4" />}
          />
        )}
      </HStack>
      <WebhooksButton dataSet={dataSet} isDisabled={!canUpdateDataSet} />
    </HStack>
  );
};
