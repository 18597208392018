import * as React from 'react';
import { Skeleton } from 'components/design/next';
import { Template } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import { PageCoverImage } from './page-cover-image';
import { useIsEditable } from 'app/features/rich-text';
import { CoverImageByTemplateIdQuery } from 'app/features/cover-image/query-builder';
import { PAGES_FOCUS_BAR_HEIGHT_V2 } from '../focus-bar';
import { TOP_BAR_HEIGHT_TOKEN } from 'app/pages/forms/_id/shared';

export type PageMediaProps = {
  template: Template;
};

export const PageMedia: React.FC<PageMediaProps> = ({ template }) => {
  const isEditable = useIsEditable();
  const coverImageQuery = CoverImageByTemplateIdQuery.useQuery({ templateId: template.id });

  return (
    <>
      {match(coverImageQuery)
        .with({ isLoading: true }, () => <Skeleton height="200px" width="full" />)
        .otherwise(() => (
          <PageCoverImage
            coverImage={coverImageQuery.data}
            templateId={template.id}
            isEditable={isEditable}
            sx={{ position: 'fixed', top: TOP_BAR_HEIGHT_TOKEN + PAGES_FOCUS_BAR_HEIGHT_V2 }}
          />
        ))}
    </>
  );
};
