import * as React from 'react';
import { DataSet } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import { Button, ButtonGroup, ListItem, Text } from 'components/design/next';
import { UpdateDataSetButton } from 'pages/reports/data-sets/components/update-data-set-button/update-data-set-button';

export type DataSetSidebarMenuItemProps = {
  dataSet: DataSet;
  isSelected: boolean;
  onSelect: (dataSet: DataSet) => void;
};

export const DataSetSidebarMenuItem: React.FC<DataSetSidebarMenuItemProps> = ({ dataSet, isSelected, onSelect }) => {
  const buttonStyles = match({ isSelected })
    .with({ isSelected: true }, () => ({
      fontWeight: '500',
      color: 'brand.500',
    }))
    .otherwise(() => ({
      bgColor: 'transparent',
      fontWeight: '400',
      color: 'gray.600',
    }));

  return (
    <ListItem aria-selected={isSelected} h={8}>
      <ButtonGroup
        isAttached
        _hover={{ bgColor: 'brand.100' }}
        bgColor={isSelected ? 'brand.100' : 'transparent'}
        borderRadius="full"
        display="flex"
        role="group"
        position="relative"
      >
        <Button
          h={8}
          onClick={() => onSelect(dataSet)}
          w="full"
          borderRadius="full"
          variant="ghost"
          justifyContent="flex-start"
          _groupHover={{
            color: 'brand.500',
            bgColor: 'transparent',
            pr: 0,
          }}
          pr={isSelected ? 6 : 0}
          whiteSpace="normal"
          {...buttonStyles}
        >
          <Text noOfLines={1} wordBreak="break-all" textAlign="left">
            {dataSet.name}
          </Text>
        </Button>

        <UpdateDataSetButton
          dataSet={dataSet}
          isSelected={isSelected}
          isDisabled={!dataSet.dataSetUpdate}
          onSuccess={() => onSelect(dataSet)}
        />
      </ButtonGroup>
    </ListItem>
  );
};
