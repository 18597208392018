import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { FormikErrors, useFormikContext } from 'formik';
import { useEffectOnce } from 'react-use';
import { getRuleHeight } from 'features/conditional-logic/components/rules-manager/get-rule-height';
import { useMediaQuery } from 'components/design/next';
import { useCallback } from 'react';
import { useConditionalLogicModalStore } from '../modal/store';

// Scrolls to the first invalid rule
export const useScrollToInvalidRule = (rules: ChecklistRuleDefinition[]) => {
  const { scrollToFirstRuleWithError, dispatch: dispatchModalState } = useConditionalLogicModalStore();

  const { errors } = useFormikContext<{
    rules: ChecklistRuleDefinition[];
  }>();
  const { scrollToFirstInvalidRule } = useScrollToFirstInvalidRule({ rules, errors });

  useEffectOnce(() => {
    if (scrollToFirstRuleWithError) {
      scrollToFirstInvalidRule();
      // Once scrolled, we can reset the flag
      dispatchModalState({ type: 'SET_SCROLL_TO_FIRST_RULE_WITH_ERROR', payload: false });
    }
  });
};

export const useScrollToFirstInvalidRule = ({
  rules,
  errors,
}: {
  rules: ChecklistRuleDefinition[];
  errors: FormikErrors<{ rules: ChecklistRuleDefinition[] }>;
}) => {
  const [isDesktop] = useMediaQuery('(min-width: 768px)');

  return {
    scrollToFirstInvalidRule: useCallback(() => {
      // Narrow the types so TS won't complain
      const errorRules = Array.isArray(errors?.rules) ? errors.rules : [];
      const errorIndex = errorRules.findIndex(error => Boolean(error));
      // Calculate the Y axis position of the rule based on the index of the error and the rule height.
      // for logical rules, add up computed rule heights above the errored rule
      const scrollTop = rules.slice(0, errorIndex).reduce((sum, rule) => sum + getRuleHeight(rule, isDesktop), 0);
      return setTimeout(() => {
        const virtualizedRuleListContainer = document.querySelector(`.${VIRTUALIZED_RULE_LIST_CONTAINER_CLASS_NAME}`);

        if (virtualizedRuleListContainer) {
          virtualizedRuleListContainer.scrollTo({
            top: scrollTop,
            behavior: 'smooth',
          });
        }
      }, 100);
    }, [errors.rules, isDesktop, rules]),
  };
};

export const VIRTUALIZED_RULE_LIST_CONTAINER_CLASS_NAME = 'virtualized-rule-list-container';
