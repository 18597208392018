import { createCachedAction } from 'reducers/util/index';

export const TASK_PERMISSION_RULE_GET_ALL_BY_TEMPLATE_REVISION =
  'templateRevision/taskPermissionRule/GET_ALL_BY_TEMPLATE_REVISION';
export const TASK_PERMISSION_RULE_UPSERT = 'templateRevision/taskPermissionRule/UPSERT';
export const TASK_PERMISSION_RULE_UPSERT_ALL = 'templateRevision/taskPermissionRule/UPSERT_ALL';
export const TASK_PERMISSION_RULE_DELETE_BY_ID = 'templateRevision/taskPermissionRule/DELETE_BY_ID';
export const TASK_PERMISSION_RULE_DELETE_ALL_BY_IDS = 'templateRevision/taskPermissionRule/DELETE_ALL_BY_IDS';

export const TaskPermissionRuleActions = TaskPermissionRuleApi => {
  'ngInject';

  const getAllByTemplateRevisionId = createCachedAction(
    TASK_PERMISSION_RULE_GET_ALL_BY_TEMPLATE_REVISION,
    TaskPermissionRuleApi.getAllByTemplateRevisionId,
    templateRevisionId => ({ templateRevisionId }),
  );

  const upsert = createCachedAction(
    TASK_PERMISSION_RULE_UPSERT,
    TaskPermissionRuleApi.upsert,
    (templateRevisionId, rule) => ({ templateRevisionId, rule }),
  );

  const upsertAll = createCachedAction(
    TASK_PERMISSION_RULE_UPSERT_ALL,
    TaskPermissionRuleApi.upsertAll,
    (templateRevisionId, rules) => ({ templateRevisionId, rules }),
  );

  const deleteById = createCachedAction(
    TASK_PERMISSION_RULE_DELETE_BY_ID,
    TaskPermissionRuleApi.deleteById,
    (templateRevisionId, rule) => ({ templateRevisionId, rule }),
  );

  const deleteAllByIds = createCachedAction(
    TASK_PERMISSION_RULE_DELETE_ALL_BY_IDS,
    TaskPermissionRuleApi.deleteAllByIds,
    (templateRevisionId, rules) => ({ templateRevisionId, rules }),
  );

  return {
    deleteAllByIds,
    deleteById,
    getAllByTemplateRevisionId,
    upsert,
    upsertAll,
  };
};
