import create from 'zustand';
import { GridApi } from '@ag-grid-community/core';
import { DataSetRow } from '@process-street/subgrade/process';

interface TableState {
  gridApi: GridApi<DataSetRow> | null;
}

interface TableEffects {
  setGridApi: (gridApi: GridApi<DataSetRow> | null) => void;
}

type TableStore = TableState & TableEffects;

export const useTableStore = create<TableStore>(set => ({
  gridApi: null,
  setGridApi: (gridApi: GridApi<DataSetRow> | null) => set({ gridApi }),
}));
