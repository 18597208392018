import angular from 'angular';
import templateUrl from './spinner.component.html';
import './spinner.scss';

angular.module('frontStreetApp.directives').component('psSpinner', {
  bindings: {
    spinnerClass: '<',
    spinnerText: '<',
  },
  templateUrl,
});
