import debounce from 'lodash/debounce';
import React, { FormEvent, useEffect, useState } from 'react';
import styles from './BlvdTextInput.module.scss';
import { Input } from 'components/design/next';

export enum TextInputType {
  Text = 'text',
  Password = 'password',
  Email = 'email',
}

export interface BlvdTextInputProps {
  onChange: (value: string) => void;
  value: string;
  ariaDescribedBy?: string;
  autoFocus?: boolean;
  debounceDelay?: number;
  disabled?: boolean;
  id?: string;
  isValid?: boolean;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => unknown;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: TextInputType;
}

const DefaultDebounceDelay = 500;

export const BlvdTextInput: React.FunctionComponent<React.PropsWithChildren<BlvdTextInputProps>> = ({
  onChange,
  value,
  autoFocus,
  ariaDescribedBy,
  debounceDelay,
  disabled,
  id,
  isValid,
  onFocus,
  onBlur,
  onKeyUp,
  placeholder,
  type,
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const delay = debounceDelay ?? DefaultDebounceDelay;

  const onChangeCallback = React.useMemo(
    () => debounce((newValue: string) => onChange(newValue), delay),
    [delay, onChange],
  );

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setCurrentValue(event.currentTarget.value);
    onChangeCallback(event.currentTarget.value);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className={styles.textInput}>
      <Input
        autoFocus={autoFocus}
        id={id}
        type={type || TextInputType.Text}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        value={currentValue}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        isDisabled={disabled}
        aria-describedby={ariaDescribedBy}
        aria-invalid={!isValid}
      />
    </div>
  );
};
