import angular from 'angular';
import { HttpStatus, UserUtils } from '@process-street/subgrade/util';
import Encase from 'encase';
import { TemplatePermitResolver } from '@process-street/subgrade/permission/template-permit-resolver';
import { FolderPermitResolver } from '@process-street/subgrade/permission/folder-permit-resolver';
import { WorkflowRunPermitResolver } from '@process-street/subgrade/permission/workflow-run-permit-resolver';
import { PermitResolver } from '@process-street/subgrade/permission/permit-resolver';
import { UserType } from '@process-street/subgrade/core';
import { GroupSelector } from 'reducers/group/group.selectors';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { isT5KPlan } from 'services/plans';
import { GroupActions } from 'reducers/group/group.actions';
import { PermitTypeDescription } from '@process-street/subgrade/permission';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.services')
  .service('PermitService', function ($q, $ngRedux, Permits, SessionService, Subject, UserService, ToastService) {
    const self = this;

    self.store = $ngRedux;

    const mapDispatchToScope = {
      queryGroups: GroupActions.queryGroups,
    };

    self.unsubscribe = self.store.connect(null, mapDispatchToScope)(self);

    // Remote

    self.db = {};

    self.db.create = function (type, model, membership) {
      const params = {};
      params.type = type;
      params[`${type}Id`] = model.id;
      params.organizationMembershipId = membership.id;

      return Permits.create(params).$promise.then(
        permit => {
          const level = self.getLevel(permit);

          AnalyticsService.trackEvent('permit created', {
            type,
            level,
          });

          const userName =
            membership.user.userType === UserType.Group && membership.user.username === 'All Members'
              ? 'All Organization'
              : membership.user.username;
          ToastService.openToast({
            status: 'success',
            title: `Added ${userName} to ${type}`,
          });

          return permit;
        },
        response => {
          const status = response.status === HttpStatus.BAD_REQUEST ? 'warning' : 'error';
          ToastService.openToast({
            status,
            title: `We're having problems adding ${membership.user.username} to the ${
              PermitTypeDescription[type] ?? type
            }`,
            description: DefaultErrorMessages.getUserInactiveDescription(membership.user.email, response),
          });

          return $q.reject(response);
        },
      );
    };

    self.db.delete = function (type, permit) {
      return Permits.delete({
        type,
        id: permit.id,
      }).$promise.then(response => {
        const level = self.getLevel(permit);

        AnalyticsService.trackEvent('permit deleted', {
          type,
          level,
        });

        return response;
      });
    };

    self.db.setLevel = function (type, permit, level) {
      const params = {};
      params.type = type;
      params.id = permit.id;
      params.level = Encase.toUpperCamel(level);

      return Permits.setLevel(params).$promise.then(updatedPermit => {
        AnalyticsService.trackEvent('permit updated', {
          type: params.type,
          level: params.level,
        });

        return updatedPermit;
      });
    };

    self.getAllWithOrganizationMembershipAndUser = function (type, modelId) {
      const where = {};
      where[`${type}Id`] = { _eq: modelId };

      return Permits.query({
        type,
        where,
        include: 'organizationMembership.user',
      }).$promise;
    };

    self.permitIsHidden = function (permit) {
      const user = permit?.organizationMembership?.user;
      const hiddenUserIds = GroupSelector.getHiddenSystemGroupsUserIds(self.store.getState());
      return hiddenUserIds.includes(user?.id);
    };

    self.getAllAndInherited = function (type, modelId) {
      const params = {};
      params.type = type;
      params.id = modelId;

      return self
        .queryGroups()
        .then(() => Permits.getAllAndInherited(params).$promise)
        .then(permits => {
          permits.inheritedPermits = permits.inheritedPermits.filter(p => !self.permitIsHidden(p));
          permits.permits = permits.permits.filter(p => !self.permitIsHidden(p));
          return permits;
        });
    };

    const { permissionsLookup } = PermitResolver;

    /**
     * @deprecated use subgrade specific permit resolvers
     * (FolderPermitResolver, TemplatePermitResolver, WorkflowRunPermitResolver)
     */
    self.getLevel = (permit, freeMember = false) => {
      const currentPlan = SessionService.getCurrentPlan();
      const isFreeMemberAndCanRun = freeMember && isT5KPlan(currentPlan.id);

      return (
        FolderPermitResolver.findFolderAccessLevel({ permit, isFreeMember: freeMember, isFreeMemberAndCanRun }) ||
        TemplatePermitResolver.findWorkflowTemplateAccessLevel(permit) ||
        TemplatePermitResolver.findFormTemplateAccessLevel(permit) ||
        WorkflowRunPermitResolver.findWorkflowRunAccessLevel(permit)
      );
    };

    self.setLevel = function (permit, level) {
      const permissions = permissionsLookup[level];
      return angular.extend(permit, permissions);
    };

    self.doesUserHaveFolderLevel = function (id, level) {
      const sessionUser = UserService.getCurrentUser();
      const selectedOrganizationId = SessionService.getSelectedOrganizationId();
      const subject = new Subject(sessionUser.id, selectedOrganizationId);

      if (subject.admin) {
        return true;
      }

      const permissions = permissionsLookup[level];
      let userHasLevel = true;
      angular.forEach(permissions, (permitted, key) => {
        userHasLevel = userHasLevel && subject.checkFolderPermission(id, key) === permitted;
      });
      return userHasLevel;
    };

    self.doesUserHaveTemplateLevel = function (id, level) {
      const sessionUser = UserService.getCurrentUser();
      const selectedOrganizationId = SessionService.getSelectedOrganizationId();
      const subject = new Subject(sessionUser.id, selectedOrganizationId);

      if (subject.admin) {
        return true;
      }

      const permissions = permissionsLookup[level];
      let userHasLevel = true;
      angular.forEach(permissions, (permitted, key) => {
        userHasLevel = userHasLevel && subject.checkTemplatePermission(id, key) === permitted;
      });
      return userHasLevel;
    };

    self.initializeMemberships = function (permits) {
      return permits.map(permit => {
        const membership = permit.organizationMembership;

        Object.assign(membership, {
          _level: self.getLevel(permit),
          _type: UserUtils.getMembershipType(membership),
        });

        return membership;
      });
    };
  });
