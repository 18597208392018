import React from 'react';
import {
  AutomationInstance,
  AutomationInstanceUtils,
  getPrefixedAutomationAppName,
  isNativeAutomationWithLink,
  isTemplateSolutionInstance,
} from '@process-street/subgrade/automation';
import { AutomationListItem } from 'pages/templates/_id/automation/components/list-item';
import { VStack } from 'components/design/next';
import { AnimatePresence, motion } from 'framer-motion';
import { useAutomationSelector } from '../selector/context';
import { useTaskTemplateNamesMap } from './use-task-template-names-map';
import { match } from 'ts-pattern';

export interface AutomationListProps {
  automationInstances?: AutomationInstance[];
}

export const AutomationList: React.FC<React.PropsWithChildren<AutomationListProps>> = ({
  automationInstances = [],
}) => {
  const [state, send] = useAutomationSelector();
  const { automationInstanceId } = state.context;

  const taskTemplateNamesMap = useTaskTemplateNamesMap();

  return (
    <VStack align="stretch" spacing="4">
      {automationInstances.length > 0 && (
        <VStack align="stretch" spacing="1">
          <AnimatePresence>
            {automationInstances.map(instance => {
              const handleAutomationSelected: React.MouseEventHandler = () => {
                const solutionTypeTag = AutomationInstanceUtils.getSolutionTypeTag(instance);
                const id = AutomationInstanceUtils.getInstanceId(instance);
                const automationType = AutomationInstanceUtils.getAutomationInstanceType(instance);

                if (solutionTypeTag && id) {
                  send({
                    type: 'AUTOMATION_SELECTED',
                    payload: {
                      id,
                      automationType,
                      solutionTypeTag,
                    },
                  });
                }
              };
              const taskName = match(instance)
                .when(isTemplateSolutionInstance, ({ taskTemplateGroupId }) =>
                  taskTemplateGroupId ? taskTemplateNamesMap?.[taskTemplateGroupId] : undefined,
                )
                .when(isNativeAutomationWithLink, ({ link }) =>
                  link.taskTemplateId ? taskTemplateNamesMap?.[link.taskTemplateId] : undefined,
                )
                .otherwise(() => undefined);

              return (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  key={AutomationInstanceUtils.getInstanceId(instance)}
                >
                  <AutomationListItem
                    templateSolutionInstanceTypeTag={AutomationInstanceUtils.getSolutionTypeTag(instance)}
                    onClick={handleAutomationSelected}
                    status={AutomationInstanceUtils.isEnabled(instance) ? 'on' : 'off'}
                    taskName={taskName}
                    description={AutomationInstanceUtils.getLabel(instance)}
                    automationApp={getPrefixedAutomationAppName(instance)}
                    selected={
                      state.matches('editing') &&
                      automationInstanceId === AutomationInstanceUtils.getInstanceId(instance)
                    }
                  />
                </motion.div>
              );
            })}
          </AnimatePresence>
        </VStack>
      )}
    </VStack>
  );
};
