import angular from 'angular';

angular.module('frontStreetApp.services').factory('FutureChecklists', function (ResourceFactory) {
  return ResourceFactory.create({
    searchAllByOrganizationId: 'GET[] /1/organizations/:organizationId/future-checklists',
    create: 'POST /1/future-checklists',
    getById: 'GET /1/future-checklists/:id',
    copy: 'POST /1/future-checklists/:id/copy',
    update: 'PATCH /1/future-checklists/:id',
    delete: 'DELETE /1/future-checklists/:id',
  });
});
