import * as React from 'react';
import { Stack } from 'components/design/next';
import { FormResponsesConditionalFilter } from './form-responses-conditional-filter';
import { FormResponsesColumnsFilter } from './form-responses-columns-filter';

export interface FormResponsesFiltersProps {}

export const FormResponsesFilters: React.FC<React.PropsWithChildren<FormResponsesFiltersProps>> = () => {
  return (
    <Stack direction="row" spacing={{ base: '0', md: '2' }}>
      <FormResponsesConditionalFilter />
      <FormResponsesColumnsFilter />
    </Stack>
  );
};
