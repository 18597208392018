import { AnonymousAutenticationRequest } from '@process-street/subgrade/auth/anonymous-user';
import { AnonymousUserInfo } from '@process-street/subgrade/core';
import { PingResponse } from '@process-street/subgrade/core/ping-response';
import { PingApi } from 'reducers/core/ping.api';
import { Action, createAction } from 'redux-actions';
import { FeatureFlagService } from 'services/features/feature-flags/feature-flag-service';
import { AuthenticationApi } from './authentication.api';
import angular from 'angular';

export const AUTHENTICATE_ANONYMOUS_USER = 'frontStreet/authentication/AUTHENTICATE_ANONYMOUS_USER';
export const SET_TIME_OFFSET = 'frontStreet/SET_TIME_OFFSET';

export interface AuthenticationActions {
  authenticateAnonymous(request: AnonymousAutenticationRequest): Action<angular.IPromise<AnonymousUserInfo>>;
}

export class AuthenticationActionsImpl implements AuthenticationActions {
  public static $inject = ['AuthenticationApi', 'FeatureFlagService', 'PingApi'];
  constructor(
    private authenticationApi: AuthenticationApi,
    private featureFlagService: FeatureFlagService,
    private pingApi: PingApi,
  ) {
    this.authenticateAnonymous = this.authenticateAnonymous.bind(this);
  }

  public authenticateAnonymous(request: AnonymousAutenticationRequest): Action<angular.IPromise<AnonymousUserInfo>> {
    const actionCreator: (payload: angular.IPromise<AnonymousUserInfo>) => Action<angular.IPromise<AnonymousUserInfo>> =
      createAction(AUTHENTICATE_ANONYMOUS_USER);

    const authenticate = this.authenticationApi.authenticateAnonymous(request).then(authInfo => {
      this.featureFlagService.initializeForUser(authInfo.user, authInfo.selectedOrganization, authInfo.selectedPlan);
      return authInfo;
    });

    return actionCreator(authenticate);
  }

  public setTimeOffset = (): Action<angular.IPromise<PingResponse>> => {
    const actionCreator = createAction(SET_TIME_OFFSET);
    return actionCreator(this.pingApi.ping());
  };
}
