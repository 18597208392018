import unescape from 'lodash.unescape';

export const unescapeHtml = (str?: string): string => {
  if (!str) {
    return '';
  }

  return unescape(str)
    .replace(/&hellip;/g, '…')
    .replace(/&nbsp;/g, ' ');
};

export const htmlUnescaped = (
  literalSections: TemplateStringsArray,
  ...placeholders: ({ toString: () => string } | undefined | null)[]
): string => {
  // Use raw literal sections: we don’t want
  // backslashes (\n etc.) to be interpreted
  const { raw } = literalSections;

  let result = '';

  placeholders.forEach((placeholder, i) => {
    // In the example, map() returns an array:
    // If substitution is an array (and not a string),
    // we turn it into a string
    const normSubst =
      typeof placeholder === 'undefined' || placeholder === null
        ? ''
        : Array.isArray(placeholder)
        ? placeholder.join('')
        : placeholder.toString();

    result += raw[i];
    result += unescapeHtml(normSubst);
  });

  // Take care of last literal section
  // (Never fails, because an empty template string
  // produces one literal section, an empty string)
  result += raw[raw.length - 1]; // (A)

  return result;
};
