import { useStore } from 'zustand';
import { Checklist, Template } from '@process-street/subgrade/process';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import createStore from 'zustand/vanilla';
import { InboxItem, OneOffTaskInboxItem } from '@process-street/subgrade/inbox';
import { IRowNode } from '@ag-grid-community/core';

export type OneOffTaskDrawerMode = 'create' | 'edit' | 'view';
export type InboxCallback = (currentItem: InboxItem, nextNode: IRowNode<InboxItem> | null) => Promise<boolean>;

interface OneOffTaskDrawerState {
  hasUnsavedChanges: boolean;
  isOpen: boolean;
  preselectedTemplateId?: Template['id'];
  preselectedChecklistId?: Checklist['id'];
  task?: OneOffTask;
  taskId?: OneOffTask['id'];
  mode: OneOffTaskDrawerMode;
  options?: ViewOptions;
  inboxItem?: OneOffTaskInboxItem;
  inboxCallback?: InboxCallback;
  inboxNextNode?: IRowNode<InboxItem> | null;
}

type ViewOptions = Partial<{
  shouldScrollToComments: boolean;
}>;

type CreateAttachedTaskParams = {
  preselectedTemplateId: Template['id'];
  preselectedChecklistId: Checklist['id'];
};

type ViewTaskParams = {
  task: OneOffTask;
};

type ViewTaskByIdParams = {
  taskId: OneOffTask['id'];
  options?: ViewOptions;
};

type ViewTaskByInboxItemParams = {
  inboxItem: OneOffTaskInboxItem;
  inboxCallback: InboxCallback;
  inboxNextNode: IRowNode<InboxItem> | null;
};

interface OneOffTaskDrawerActions {
  createAttachedTask: (params: CreateAttachedTaskParams) => void;
  createProjectTask: () => void;
  onClose: () => void;
  snoozeInboxItem: (untilDate: number | undefined) => void;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  setMode: (mode: OneOffTaskDrawerMode) => void;
  viewTask: (params: ViewTaskParams) => void;
  viewTaskById: (params: ViewTaskByIdParams) => void;
  viewTaskByInboxItem: (params: ViewTaskByInboxItemParams) => void;
}

type OneOffTaskDrawerStore = OneOffTaskDrawerState & OneOffTaskDrawerActions;

export const oneOffTaskDrawerStore = createStore<OneOffTaskDrawerStore>(set => ({
  api: undefined,
  hasUnsavedChanges: false,
  isOpen: false,
  inboxItem: undefined,
  inboxCallback: undefined,
  inboxNextNode: null,
  linkedChecklist: undefined,
  mode: 'create',
  preselectedChecklistId: undefined,
  preselectedTemplateId: undefined,
  task: undefined,
  taskId: undefined,
  createProjectTask: () => set({ isOpen: true, mode: 'create' }),
  createAttachedTask: ({ preselectedTemplateId, preselectedChecklistId }) =>
    set({ isOpen: true, mode: 'create', preselectedTemplateId, preselectedChecklistId }),
  viewTask: ({ task }) => set({ isOpen: true, mode: 'view', task }),
  viewTaskById: ({ taskId, options }) => set({ isOpen: true, mode: 'view', taskId, options }),
  viewTaskByInboxItem: ({ inboxItem, inboxCallback, inboxNextNode }) => {
    // creating a copy because inboxNextNode.data is mutable :/
    const nextNode: IRowNode<InboxItem> | null =
      inboxNextNode && inboxNextNode.data ? { ...inboxNextNode, data: { ...inboxNextNode.data } } : null;
    return set({
      isOpen: true,
      mode: 'view',
      taskId: inboxItem.task.id,
      inboxItem,
      inboxCallback,
      inboxNextNode: nextNode,
    });
  },
  snoozeInboxItem: (untilDate: number | undefined) =>
    set(state =>
      state.inboxItem ? { ...state, inboxItem: { ...state.inboxItem, snoozedUntilDate: untilDate } } : state,
    ),
  onClose: () =>
    set({
      hasUnsavedChanges: false,
      isOpen: false,
      inboxItem: undefined,
      inboxCallback: undefined,
      inboxNextNode: null,
      options: undefined,
      preselectedChecklistId: undefined,
      preselectedTemplateId: undefined,
      task: undefined,
      taskId: undefined,
    }),
  setMode: (mode: OneOffTaskDrawerMode) => set({ mode }),
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => set({ hasUnsavedChanges }),
}));

export const useOneOffTaskDrawerStore = (): OneOffTaskDrawerStore => useStore(oneOffTaskDrawerStore);

export const useViewTaskById = () => useStore(oneOffTaskDrawerStore, state => state.viewTaskById);
export const useViewTaskByItem = () => useStore(oneOffTaskDrawerStore, state => state.viewTaskByInboxItem);

export const useViewTask = () => useStore(oneOffTaskDrawerStore, state => state.viewTask);

export const useCloseOneOffTaskDrawer = () => useStore(oneOffTaskDrawerStore, state => state.onClose);

export const useCreateProjectTask = () => useStore(oneOffTaskDrawerStore, state => state.createProjectTask);
