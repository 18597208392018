import { Schema, ValidationError } from 'yup';
import { toResult } from '@process-street/subgrade/util';

const validationErrorMessage = <Value>(validationSchema: Schema<Value>, value: Value) =>
  toResult<Value, ValidationError>(() => validationSchema.validateSync(value, { abortEarly: true }))
    .mapError((error: ValidationError) => error.message)
    .map(() => undefined)
    .get();

export const YupUtils = {
  validationErrorMessage,
};
