import { TemplatePermit } from '@process-street/subgrade/permission';
import { EntityMap } from '@process-street/subgrade/redux/types';
import { ReduxAppState } from 'reducers/types';
import { entitiesSelector } from 'reducers/entities/entities.selectors';

const getEntityMap = (state: ReduxAppState): EntityMap<TemplatePermit> => entitiesSelector(state).templatePermits;

export const TemplatePermitSelector = {
  getEntityMap,
};
