import {
  createBoldPlugin,
  createItalicPlugin,
  createStrikethroughPlugin,
  createUnderlinePlugin,
} from '@udecode/plate-basic-marks';
import { onKeyDownToggleMark } from '@udecode/plate-utils';
import { SlateLeaf } from '../components/slate-leaf';
import { MARK_BACKGROUND } from './ps-color';
import { backgroundDeserializer } from './ps-color/background/background-deserializer';
import { createFontColorPlugin } from '@udecode/plate-font';
import { createPagesPluginFactory, createPagesPlugins } from '../pages-plate-types';

export const createPSBackground = createPagesPluginFactory({
  key: MARK_BACKGROUND,
  handlers: { onKeyDown: onKeyDownToggleMark },
  deserializeHtml: backgroundDeserializer,
  isLeaf: true,
  component: SlateLeaf,
});

export const createPSMarks = createPagesPluginFactory({
  key: 'PS_MARKS',
  plugins: createPagesPlugins([
    createPSBackground(),
    createFontColorPlugin({ component: SlateLeaf }),
    createItalicPlugin({ component: SlateLeaf }),
    createBoldPlugin({ component: SlateLeaf }),
    createUnderlinePlugin({ component: SlateLeaf }),
    createStrikethroughPlugin({ component: SlateLeaf }),
  ]),
});
