import { isOrganizationMembershipActive } from '@process-street/subgrade/core';
import { RoleAssignmentUtils } from '@process-street/subgrade/role-assignment';
import { StringUtils } from '@process-street/subgrade/util';
import angular from 'angular';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { connectController } from 'reducers/util';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { queryClient } from 'components/react-root';
import templateUrl from './task-assignments.component.html';
import './task-assignments.scss';
import { GetTasksAssignmentsByChecklistRevisionQuery } from 'app/features/checklist-revisions/query-builder/get-tasks-assignments-by-checklist-revision-query';

angular.module('frontStreetApp.directives').component('psTaskAssignments', {
  bindings: {
    users: '<',
    assignmentRules: '<',
    emailAndMembersFieldWidgets: '<',
    checklistTaskAssignments: '<',
    unassignable: '<',
    avatarSize: '<',
    onUnassign: '&',
    onRemoveRule: '&',
    showMultiple: '<',
  },
  templateUrl,
  controller($ngRedux, UserService) {
    const ctrl = this;

    ctrl.$onInit = function () {
      const mapStateToThis = () => state => ({
        isDeactivationEnabled: FeatureFlagSelector.getFeatureFlags(state).deactivatingUsers,
        membershipMapByUserId: OrganizationMembershipSelector.getMapByUserIdForSelectedOrganization(state),
      });

      connectController($ngRedux, mapStateToThis)(ctrl);
    };

    ctrl.unassignUser = function (user) {
      ctrl.onUnassign({ user });
    };

    ctrl.removeRule = function (rule) {
      ctrl.onRemoveRule({ rule });
    };

    ctrl.$onChanges = function (changes) {
      if (changes.users) {
        ctrl.users = changes.users.currentValue;
        ctrl.createUsersByUsernameMap();
      }

      if (changes.assignmentRules) {
        ctrl.assignmentRules = changes.assignmentRules.currentValue;
      }

      if (changes.emailAndMembersFieldWidgets) {
        ctrl.emailAndMembersFieldWidgets = changes.emailAndMembersFieldWidgets.currentValue;
        ctrl.invalidateAssignmentsQuery();
      }

      if (changes.checklistTaskAssignments) {
        ctrl.checklistTaskAssignments = changes.checklistTaskAssignments.currentValue;
        ctrl.createUsersByUsernameMap();
        ctrl.invalidateAssignmentsQuery();
      }
    };

    ctrl.resolveUsers = () => {
      if (this.checklistTaskAssignments) {
        return this.checklistTaskAssignments.map(a => a.organizationMembership.user);
      } else if (this.users) {
        return this.users;
      }

      return [];
    };

    ctrl.invalidateAssignmentsQuery = () => {
      queryClient.invalidateQueries(GetTasksAssignmentsByChecklistRevisionQuery.key);
    };

    ctrl.getCoverUser = () => UserService.getCoverUser(ctrl.resolveUsers());

    ctrl.getBadge = function () {
      const usersCount = (ctrl.users && ctrl.users.length) || 0;
      const rulesCount = (ctrl.assignmentRules && ctrl.assignmentRules.length) || 0;

      const assignmentsCount = (ctrl.checklistTaskAssignments && ctrl.checklistTaskAssignments.length) || 0;

      const total = usersCount + rulesCount + assignmentsCount;

      return total > 1 && total;
    };

    ctrl.getAssignedToString = () => {
      const usernames = [
        ...(ctrl.users?.map(user => user.username) ?? []),
        ...(this.checklistTaskAssignments?.map(assignment => assignment.organizationMembership.user.username) ?? []),
        ...(this.assignmentRules?.map(RoleAssignmentUtils.makeRuleTitleGetter(ctrl.emailAndMembersFieldWidgets)) ?? []),
      ].map(username => {
        const user = ctrl.usersByUsername?.[username];
        const membership = this.state.membershipMapByUserId?.[user?.id];

        if (!membership) return username;
        if (!isOrganizationMembershipActive(membership)) return `${username} (deactivated)`;

        return username;
      });

      return StringUtils.getSentenceList({
        items: usernames,
        prefix: 'Assigned to ',
        fallback: 'Assignments',
        max: 1,
      });
    };

    ctrl.getUsersToRender = () => {
      return ctrl.resolveUsers().slice(0, 5);
    };

    ctrl.getRemainingUsersToRender = () => {
      return ctrl.resolveUsers().length - ctrl.getUsersToRender().length;
    };

    ctrl.createUsersByUsernameMap = () => {
      const allUsers = [
        ...(ctrl.users ?? []),
        ...(this.checklistTaskAssignments?.map(assignment => assignment.organizationMembership.user) ?? []),
      ];

      ctrl.usersByUsername = Object.fromEntries(allUsers.map(user => [user.username, user]) ?? []);
    };

    ctrl.isUserInactive = user => {
      const membership = this.state.membershipMapByUserId?.[user.id];

      if (!membership) return false;

      return !isOrganizationMembershipActive(membership);
    };
  },
});
