import { Icon, Input, InputGroup, InputLeftElement, InputProps } from 'components/design/next';
import * as React from 'react';
import debounce from 'lodash/debounce';
import { InputGroupProps } from '@chakra-ui/react';

interface SearchBoxProps extends InputGroupProps {
  onSearchTermChange: (value: string) => void;
  searchTerm?: string;
  inputProps?: Partial<InputProps>;
}

export const SEARCH_DEBOUNCE = 500;

export const SearchBox = ({ onSearchTermChange, searchTerm, inputProps, ...inputGroupProps }: SearchBoxProps) => {
  const onChangeDebounced = React.useMemo(
    () =>
      debounce((filter: string) => {
        onSearchTermChange(filter);
      }, SEARCH_DEBOUNCE),
    [onSearchTermChange],
  );

  return (
    <InputGroup {...inputGroupProps}>
      <InputLeftElement>
        <Icon size="4" variant="far" icon="search" color="gray.500" />
      </InputLeftElement>
      <Input
        name="search"
        type="search"
        placeholder="Search"
        variant="outline"
        defaultValue={searchTerm}
        onChange={e => onChangeDebounced(e.target.value)}
        {...inputProps}
      />
    </InputGroup>
  );
};
