import * as React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { TaskTemplate } from '@process-street/subgrade/process';
import { LocalStorageService } from 'app/features/storage/local-storage-service';
import { useTaskTemplateListActorRef } from './use-task-templates-list-actor';
import _isEmpty from 'lodash/isEmpty';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useSelector } from '@xstate/react';
import { Option } from 'space-monad';
import _isEqual from 'lodash/isEqual';
import { useDeepCompareEffect } from 'react-use';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';

type LastViewedTasks = { [templateId: string]: string };

export const useRedirectToTaskOnLoad = ({
  isLoading,
  templateId,
  taskTemplateGroupId,
  onRedirect,
}: {
  isLoading: boolean;
  templateId: Muid;
  taskTemplateGroupId?: string;
  onRedirect?: () => void;
}) => {
  const editorActor = useFormEditorPageActorRef();
  const taskTemplateListActor = useTaskTemplateListActorRef();
  const selectTask = React.useCallback(
    (taskTemplate: TaskTemplate) => {
      taskTemplateListActor.send({
        type: 'SELECT_TASK_TEMPLATE',
        taskTemplate,
        metaKey: false,
        ctrlKey: false,
        shiftKey: false,
      });
    },
    [taskTemplateListActor],
  );
  const firstTimeRedirected = React.useRef<boolean>(false);

  const firstTaskTemplate = useSelector(editorActor, FormEditorPageActorSelectors.getFirstTaskTemplate);

  const lastViewedTaskSelector = React.useMemo(() => {
    const lastViewedTasks = getLastViewedTasks();
    const lastViewedTaskGroupId = lastViewedTasks[templateId];

    return FormEditorPageActorSelectors.getTaskTemplateByGroupId(lastViewedTaskGroupId);
  }, [templateId]);

  const navigationTaskTemplateSelector = React.useMemo(
    () => FormEditorPageActorSelectors.getTaskTemplateByGroupId(taskTemplateGroupId ?? ''),
    [taskTemplateGroupId],
  );

  const navigationTaskTemplate = useSelector(editorActor, navigationTaskTemplateSelector, _isEqual);
  const lastViewedTaskTemplate = useSelector(editorActor, lastViewedTaskSelector, _isEqual);

  useDeepCompareEffect(
    function selectTaskFromLocalStorage() {
      if (!taskTemplateGroupId && !isLoading && !firstTimeRedirected.current && firstTaskTemplate?.id) {
        const taskToRedirect = Option(lastViewedTaskTemplate)
          .map(task => ({ id: task.id, group: { id: task.group.id } }))
          .fold(
            () => ({ id: firstTaskTemplate.id, group: { id: firstTaskTemplate.group.id } }),
            taskTemplate => taskTemplate,
          );
        if (taskToRedirect?.id && taskToRedirect?.group?.id) {
          firstTimeRedirected.current = true;
          selectTask(taskToRedirect as TaskTemplate);
          onRedirect?.();
        }
      }
    },
    [
      isLoading,
      taskTemplateGroupId,
      selectTask,
      firstTaskTemplate?.id,
      firstTaskTemplate?.group?.id,
      lastViewedTaskTemplate,
    ],
  );

  React.useEffect(
    function selectTaskOnFirstLoad() {
      if (navigationTaskTemplate?.id && navigationTaskTemplate?.group?.id && !firstTimeRedirected.current) {
        firstTimeRedirected.current = true;
        selectTask({ id: navigationTaskTemplate.id, group: { id: navigationTaskTemplate.group.id } } as TaskTemplate);
      }
    },
    [selectTask, navigationTaskTemplate?.id, navigationTaskTemplate?.group.id],
  );
};

const getLastViewedTasks = (): LastViewedTasks => {
  try {
    const storedData = LocalStorageService.getItem('lastViewedTasks');
    return storedData ? JSON.parse(storedData) : {};
  } catch (error) {
    console.error('Error retrieving last viewed tasks:', error);
    return {};
  }
};

export const setLastVisited = ({ groupId, templateId }: { groupId: Muid; templateId: Muid }): void => {
  try {
    const lastViewedTasks = getLastViewedTasks();
    lastViewedTasks[templateId] = groupId;
    LocalStorageService.setItem('lastViewedTasks', JSON.stringify(lastViewedTasks));
  } catch (error) {
    console.error('Error setting last viewed task:', error);
  }
};
