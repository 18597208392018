import * as React from 'react';
import { Box, Button, Tooltip } from 'components/design/next';
import { FieldArrayRenderProps } from 'formik';
import { useRuleOperations } from './rule-operations';
import { Icon } from 'components/design/next';

export type AddRuleAtTheBottomBtnProps = {
  arrayHelpers: FieldArrayRenderProps;
};

export const AddRuleAtTheBottomBtn: React.FC<React.PropsWithChildren<AddRuleAtTheBottomBtnProps>> = ({
  arrayHelpers,
}) => {
  const { handleAddRuleAtTheBottom } = useRuleOperations(arrayHelpers);

  return (
    <Box w="full" display="flex" alignItems="center" justifyContent="center" paddingBottom="100px">
      <Tooltip openDelay={500} label="Add a new rule at the end of the list">
        <Button
          bgColor="gray.100"
          color="gray.600"
          w="10"
          h="10"
          _hover={{ bgColor: 'gray.200' }}
          _active={{ bgColor: 'gray.300' }}
          onClick={handleAddRuleAtTheBottom}
          aria-label="Add a new rule at the end of the list"
        >
          <Icon icon="plus" size="4" />
        </Button>
      </Tooltip>
    </Box>
  );
};
