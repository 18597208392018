import * as React from 'react';

import { Template } from '@process-street/subgrade/process';
import { Button, ButtonGroup, ButtonGroupProps, Center, Icon } from 'components/design/next';
import { EditButton } from 'pages/pages/_id/edit/page/components/edit-button';

import { DisabledFormFieldClickTooltip } from '../../disabled-form-field-click-tooltip';
import { RunWorkflowButton } from '../../run-workflow-button';
import { useGetTemplateQuery } from 'features/template/query-builder';

export type ViewProps = ButtonGroupProps & {
  templateId: Template['id'];
};

export const ViewMiddleButtonGroup: React.FC<React.PropsWithChildren<ViewProps>> = ({ templateId, ...props }) => {
  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;
  const runAriaLabel = `run workflow ${template ? template.name : ''}`;

  return (
    <ButtonGroup spacing="2" {...props}>
      <DisabledFormFieldClickTooltip>
        <RunWorkflowButton>
          <Button aria-label={runAriaLabel} leftIcon={<Icon size="4" icon="play" variant="far" />} variant="primary">
            Run Workflow
          </Button>
        </RunWorkflowButton>
      </DisabledFormFieldClickTooltip>
      <Center display="inline-flex">
        <EditButton templateId={templateId}>
          <Button
            aria-label="edit workflow"
            color="gray.600"
            bgColor="gray.200"
            _hover={{ bgColor: 'gray.100' }}
            variant="secondary"
          >
            Edit
          </Button>
        </EditButton>
      </Center>
    </ButtonGroup>
  );
};
