import angular from 'angular';
import './dropzone.scss';

angular.module('frontStreetApp.directives').directive('psDropzone', ($timeout, $window) => {
  let id = 0;
  let timeout;

  return function postLink(scope, element, attributes) {
    id += 1;
    const namespace = `ns-${id}`;
    const dragoverEvent = `dragover.${namespace}`;
    const dragstartEvent = `dragstart.${namespace}`;
    const dropEvent = `drop.${namespace}`;
    const body = angular.element('body');

    scope.enableDropzone = enableDropzone;
    scope.disableDropzone = disableDropzone;

    element.addClass('dropzone');
    element.on('$destroy', () => {
      scope.disableDropzone();
    });

    //Watch to check if dropzone is still enabled to unregister and register if has been enabled again.
    scope.$watch(attributes.psDropzone, enabled => {
      if (enabled) {
        scope.enableDropzone();
      } else {
        scope.disableDropzone();
      }
    });

    function enableDropzone() {
      // Canceling dragstart because when we are dragging/dropping files we don't use it and this is making
      // links and buttons available to drop
      angular
        .element($window.document)
        .on(
          dragstartEvent,
          event =>
            event.target.attributes['data-ps-drag-drop'] !== undefined ||
            event.target.attributes['ui-select-sort'] !== undefined,
        );

      angular.element($window.document).on(dragoverEvent, event => {
        // Don't show dropzone for multi-choice field sorting
        if ((event.dataTransfer || event.originalEvent.dataTransfer).types[0] !== 'text/plain') {
          handleDropzoneClasses(event);
        }
      });

      // This makes sure you can't drop it elsewhere on the screen and cause it to load
      angular.element($window.document).on(`${dragoverEvent} ${dropEvent}`, preventDefaultIfNotHovered);
    }

    function disableDropzone() {
      angular.element($window.document).off(`.${namespace}`);
    }

    function handleDropzoneClasses(event) {
      if (!timeout) {
        body.addClass('dragover');
      } else {
        $timeout.cancel(timeout);
      }

      let found = false;
      let node = event.target;
      do {
        if (node === element[0]) {
          found = true;
          break;
        }
        node = node.parentNode;
      } while (node !== null);
      if (found) {
        element.addClass('hover');
      } else {
        element.removeClass('hover');
      }
      timeout = $timeout(() => {
        timeout = null;
        body.removeClass('dragover');
        element.removeClass('hover');
      }, 100);
    }

    function preventDefaultIfNotHovered(event) {
      if (!element.hasClass('hover')) {
        event.preventDefault();
      }
    }
  };
});
