import * as React from 'react';

export const useMetaPressed = (): boolean => {
  const [metaPressed, setMetaPressed] = React.useState(false);

  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      setMetaPressed(e.metaKey);
    };
    document.addEventListener('keydown', listener);
    document.addEventListener('keyup', listener);
    return () => {
      setMetaPressed(false);
      document.removeEventListener('keydown', listener);
      document.removeEventListener('keyup', listener);
    };
  }, []);

  return metaPressed;
};
