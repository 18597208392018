import * as React from 'react';
import { Icon, MenuItem } from 'components/design/next';
import { useUIActorRef } from '../ui-context';

export const InsertMenuItem: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { uiActorRef } = useUIActorRef();
  return (
    <MenuItem
      icon={<Icon icon="plus" variant="far" size="4" color="gray.500" />}
      onClick={() => uiActorRef.send({ type: 'OPEN_DISCLOSURE', name: 'insertWidget' })}
    >
      Insert
    </MenuItem>
  );
};
