import { env } from 'components/common/env';

const isAppUrl = (url: string): boolean => {
  const { hostname } = new URL(env.APP_URL);
  const appUrls = [`http://${hostname}`, `https://${hostname}`];
  return appUrls.some(appUrl => url.startsWith(appUrl));
};

export const UrlUtils = {
  isAppUrl,
};
