import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { components, GroupBase, MultiValueRemoveProps } from 'react-select';

export const MultiValueRemove = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MultiValueRemoveProps<Option, IsMulti, Group>,
) => {
  return (
    <components.MultiValueRemove {...props}>
      <FontAwesomeIcon icon={faTimes} />
    </components.MultiValueRemove>
  );
};
