import { BaseApprovalSelector } from '@process-street/subgrade/redux/selector/approval.selectors';

export const ApprovalSelector = {
  getAllApprovedTaskIds: BaseApprovalSelector.getAllApprovedTaskIds,
  getAllByChecklistRevisionId: BaseApprovalSelector.getAllByChecklistRevisionId,
  getAllByTaskIds: BaseApprovalSelector.getAllByTaskIds,
  getApprovalSubjectTasks: BaseApprovalSelector.getApprovalSubjectTasks,
  getApprovalTaskGroupsMap: BaseApprovalSelector.getApprovalTaskGroupsMap,
  getById: BaseApprovalSelector.getById,
  getSubjectTaskIdToApprovalsMapByChecklistRevisionId:
    BaseApprovalSelector.getSubjectTaskIdToApprovalsMapByChecklistRevisionId,
  isTaskLocked: BaseApprovalSelector.isTaskLocked,
};
