import { MergeTagsConstants } from '@process-street/subgrade/form/merge-tags-constants';
import { WidgetConstants } from '@process-street/subgrade/process/widget-constants';
import angular from 'angular';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { MergeTagsServiceUtils } from './utils';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { queryClient } from 'components/react-root';
import {
  MergeTagsByChecklistRevisionIdQuery,
  MergeTagsByTemplateRevisionIdQuery,
} from 'features/merge-tags/query-builder';
import { GetEmailMergeTagsQuery } from 'features/run-via-email/query-builder/get-email-merge-tags';
import { GetDataSetMergeTagsByChecklistRevisionQuery } from 'pages/reports/data-sets/query-builder/get-data-set-merge-tags';

angular
  .module('frontStreetApp.services')
  .service(
    'MergeTagsService',
    function ($q, $ngRedux, ChecklistService, FeatureFlagService, OrganizationService, TemplateService, UserService) {
      const self = this;

      /**
       * Gets all tags for template revision
       *
       * @param revision
       * @param mergeTagTarget
       * @return {Promise}
       */
      self.getAllByTemplateRevision = function (revision, mergeTagTarget) {
        const context = WidgetConstants.FormFieldKeysContext.MERGE_TAG;
        const params = { templateRevisionId: revision.id, mergeTagTarget, context, includeLegacyTags: true };
        return queryClient
          .fetchQuery(
            MergeTagsByTemplateRevisionIdQuery.getKey(params),
            MergeTagsByTemplateRevisionIdQuery.queryFn(queryClient),
            { staleTime: Infinity },
          )
          .then(result => result.tags);
      };

      /**
       * Gets all tags for checklist revision
       *
       * @param checklistRevisionId
       * @param mergeTagTarget
       * @return {Promise}
       */
      self.getAllByChecklistRevisionId = (checklistRevisionId, mergeTagTarget) => {
        const context = WidgetConstants.FormFieldKeysContext.MERGE_TAG;
        return queryClient.fetchQuery(
          MergeTagsByChecklistRevisionIdQuery.getKey({ checklistRevisionId, mergeTagTarget, context }),
          MergeTagsByChecklistRevisionIdQuery.queryFn(queryClient),
          { staleTime: Infinity },
        );
      };

      /**
       * Parse the test values and replaces all the merge tags for template view.
       *
       * @param content
       * @param templateRevision
       * @param target
       * @returns {*}
       */
      self.parseTemplateContent = function (content, templateRevision, target) {
        if (angular.isUndefined(content)) {
          return $q.resolve(content);
        }

        return self.getAllByTemplateRevision(templateRevision, target).then(tags => {
          return MergeTagsServiceUtils.injectMergeTagLabels(content, tags);
        });
      };

      self.getEmailMergeTags = checklistId => {
        return queryClient.fetchQuery(
          GetEmailMergeTagsQuery.getKey({ checklistId }),
          () => GetEmailMergeTagsQuery.queryFn({ checklistId }),
          { staleTime: Infinity },
        );
      };

      self.getDataSetMergeTags = checklistRevisionId => () => {
        return queryClient.fetchQuery(
          GetDataSetMergeTagsByChecklistRevisionQuery.getKey({ checklistRevisionId }),
          () => GetDataSetMergeTagsByChecklistRevisionQuery.queryFn({ checklistRevisionId }),
          { staleTime: Infinity },
        );
      };

      /**
       * Parse the test values and replaces all the merge tags for checklist view
       *
       * @param mode
       * @param target
       * @param content
       * @param templateRevision
       * @param checklistRevision
       * @param task
       * @param formFieldValueMap
       * @param user
       *
       * @returns {Promise}
       *
       * @description
       * ### Steps
       * * Get all possible tags (builtin and forms via widgets)
       * * Find used tags in content
       * * Filter tags to only used
       * * Map through tags to get resolved value ({ key, default, replacement })
       * * Apply replacements (iterate through tags and `replace` for each tag)
       *   * Mode dependent (html vs plaintext)
       *   * Apply filters
       */
      self.parseChecklistContent = function (
        mode,
        target,
        content,
        templateRevision,
        checklistRevision,
        task,
        formFieldValueMap,
        user,
      ) {
        if (angular.isUndefined(content)) {
          return $q.resolve(content);
        }

        const value = content;

        return self.getAllByChecklistRevisionId(checklistRevision.id, target).then(tags => {
          const foundTags = MergeTagStringReplacementUtils.findTags(content, mode);
          const filteredTags = {};

          angular.forEach(tags, (label, key) => {
            if (foundTags.includes(key)) {
              filteredTags[key] = label;
            }
          });

          const getTemplate = async () => TemplateService.get(templateRevision.template.id);
          const getChecklist = async () => ChecklistService.get(checklistRevision.checklist.id);

          const ffs = FeatureFlagService.getFeatureFlags();

          const resolvedTagsPromise = MergeTagsServiceUtils.buildResolvedTags({
            tags: filteredTags,
            checklistRevision,
            getChecklist,
            getCurrentUser: async () => user,
            getFormFieldValues: async () => Object.values(formFieldValueMap),
            getOrganization: async () =>
              getChecklist().then(checklist => OrganizationService.getById(checklist.organization.id)),
            getOrganizationMemberships: async () => {
              const state = $ngRedux.getState();
              const orgId = await getChecklist().then(c => c.organization.id);
              const memberships = OrganizationMembershipSelector.getAllWithUserByOrganizationId(orgId)(state);
              return memberships;
            },
            getTask: async () => task,
            getTaskTemplate: async () => task?.taskTemplate,
            getTemplate,
            getUserById: async _id =>
              getChecklist().then(checklist => UserService.getById(checklist.audit.createdBy.id)),
            getEmailMergeTags: () => getChecklist().then(checklist => self.getEmailMergeTags(checklist.id)),
            getDataSetMergeTags: self.getDataSetMergeTags(checklistRevision.id),
          });

          return resolvedTagsPromise.then(results => {
            switch (mode) {
              case MergeTagsConstants.Mode.PLAINTEXT:
                return MergeTagsServiceUtils.replaceResolvedTagsWithPlaintextValues(results, value);
              case MergeTagsConstants.Mode.HTML:
                return MergeTagsServiceUtils.replaceResolvedTagsWithHtmlValues(results, value, {
                  isMarkdownEnabled: ffs.longTextFieldMarkdown,
                });
              default:
                throw new Error(`unsupported mode: ${mode}`);
            }
          });
        });
      };
    },
  );
