import * as React from 'react';
import { TasksTableUtils } from '../tasks-table/tasks-table-utils';
import { LoadMoreRowRenderer } from './load-more-row-renderer';
import { NewTaskRowRenderer } from './new-task-row-renderer';

type WorkflowRunGroupingFullRowRendererProps = {
  onLoadMore: () => void;
  data?: TasksTableUtils.NewTaskRow;
};

export const WorkflowRunGroupingFullRowRenderer = (props: WorkflowRunGroupingFullRowRendererProps) => {
  try {
    if (props.data?.type === TasksTableUtils.FULL_ROW_BUTTON_TYPE.LOAD_MORE_BUTTON) {
      return <LoadMoreRowRenderer {...props} />;
    } else {
      return <NewTaskRowRenderer data={props.data} />;
    }
  } catch (e) {
    console.error('Unable to render full row for workflow run grouping.', e);
    return <></>;
  }
};
