import React from 'react';

import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal } from 'components/design/next';

export type Props = { shouldHideCompletedTasks: boolean; onToggleCompletedTasksVisibility: () => void };

export const ChecklistHeaderActions: React.FC<React.PropsWithChildren<Props>> = ({
  shouldHideCompletedTasks,
  onToggleCompletedTasksVisibility,
}) => {
  return (
    <Menu autoSelect={false} placement="bottom-start">
      <MenuButton
        as={IconButton}
        icon={<Icon color="gray.500" size="4" variant="far" icon="ellipsis-h" />}
        variant="ghost"
        aria-label="completed task actions"
      />
      <Portal>
        <MenuList zIndex="dropdown">
          <MenuItem
            onClick={onToggleCompletedTasksVisibility}
            h="10"
            color="gray.600"
            icon={
              <Icon icon={shouldHideCompletedTasks ? 'eye' : 'eye-slash'} color="gray.500" variant="far" size="5" />
            }
            iconSpacing="2"
          >
            {shouldHideCompletedTasks ? 'Show' : 'Hide'} Completed
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};
