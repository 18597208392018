import * as React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Icon,
  Button,
  Text,
  Portal,
  useDisclosure,
  useOutsideClick,
} from 'components/design/next';
import classNames from 'classnames';
import { filterableColumns, filterableColumnsWithoutLabel } from 'components/dashboard/models/columns';
import { match } from 'ts-pattern';
import styles from './ChecklistDashboardConditionalFilter.module.scss';
import { FilterRow } from './FilterRow';
import { ConditionalFilterState } from './use-conditional-filter-state';
import { useFeatureFlag } from 'features/feature-flags';
import { ButtonGroup } from '@chakra-ui/react';
import { useDebounce } from 'use-debounce';

type ChecklistDashboardConditionalFilterProps = ConditionalFilterState & {
  applyChanges: () => void;
  filterHasChanged: boolean;
};

const getButtonClass = (count: number) =>
  classNames({
    [styles.buttonActive]: count > 0,
    [styles.buttonInactive]: count === 0,
  });

const getLabel = (count: number) => {
  return match(count)
    .with(0, () => 'Filters')
    .with(1, () => '1 Filter')
    .when(
      c => c > 99,
      () => '99+ Filters',
    )
    .otherwise(() => `${count} Filters`);
};

export const ChecklistDashboardConditionalFilter: React.VFC<ChecklistDashboardConditionalFilterProps> = ({
  onFilterAdd: handleFilterAdd,
  onFilterChange: handleFilterChange,
  onFilterDelete: handleFilterDelete,
  onFilterSwitchType: handleFilterSwitchType,
  storedFilter,
  clauses,
  applyChanges,
  filterHasChanged,
}) => {
  const isWorkflowRunLabelEnabled = useFeatureFlag('customLabelsForWorkflowRuns');
  const count = storedFilter.children ? storedFilter.children.length : 0;
  const btnClass = getButtonClass(count);
  const label = getLabel(count);

  const disclosure = useDisclosure();
  const popoverContentRef = React.useRef<HTMLDivElement>(null);

  const [debouncedIsOpen] = useDebounce(disclosure.isOpen, 500);

  useOutsideClick({
    ref: popoverContentRef,
    handler: () => {
      if (debouncedIsOpen && popoverContentRef.current) {
        if (filterHasChanged) applyChanges();
        disclosure.onClose();
      }
    },
    // Add a debounced value to prevent a glitch where the menu closes as soon as the user clicks on the button
    enabled: debouncedIsOpen,
  });

  const allowedFilterableColumns = isWorkflowRunLabelEnabled ? filterableColumns : filterableColumnsWithoutLabel;

  return (
    <div className={`${styles.checklistDashboardConditionalFilter} ${btnClass}`}>
      <Popover
        id="ChecklistDashboardConditionalFilterDropdown"
        placement="bottom-start"
        {...disclosure}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Button
            {...{
              ...(filterHasChanged
                ? {
                    colorScheme: 'brand',
                    fontWeight: 'medium',
                  }
                : {
                    colorScheme: 'gray',
                    fontWeight: 'normal',
                  }),
            }}
            size="sm"
            variant="ghost"
            leftIcon={<Icon variant="far" size="4" icon="filter" />}
            rightIcon={<Icon ml="auto" icon="chevron-down" size="4" variant="far" />}
            w={{ base: 'full', md: 'auto' }}
            onClick={disclosure.onToggle}
          >
            <Text as="span" variant="inherit" mr="auto">
              {label}
            </Text>
          </Button>
        </PopoverTrigger>

        <Portal>
          <PopoverContent ref={popoverContentRef} w="auto" p="6" zIndex="popover">
            {clauses.length === 0 && <p className={styles.noFiltersAppliedLabel}>No filters are currently applied</p>}
            {clauses.map((clause, index) => (
              <FilterRow
                key={clause.id}
                filter={clause}
                first={index === 0}
                columns={allowedFilterableColumns}
                onChange={handleFilterChange}
                onDelete={handleFilterDelete}
                onSwitchFilterType={handleFilterSwitchType}
                parentFilterType={storedFilter.filterType}
              />
            ))}

            <ButtonGroup width="full" justifyContent="space-between" mt={4}>
              <Button
                onClick={handleFilterAdd}
                variant="tertiary"
                leftIcon={<Icon color="inherit" icon="plus" size="4" />}
                color="gray.600"
                colorScheme="brand"
              >
                Add Filter
              </Button>
              <Button
                onClick={() => {
                  applyChanges();
                  disclosure.onClose();
                }}
                colorScheme="brand"
                isDisabled={!filterHasChanged}
              >
                Apply
              </Button>
            </ButtonGroup>
          </PopoverContent>
        </Portal>
      </Popover>
    </div>
  );
};
