import { noop } from '@process-street/subgrade/util';
import { BlvdCheckbox } from 'components/design/BlvdCheckbox';
import { Icon, IconSize, IconType } from 'components/design/Icon';
import React, { ReactNode } from 'react';
import { components, GroupBase, OptionProps } from 'react-select';
import { ThemeName, useThemeName } from 'components/design/next/theme-name-provider';
import { Checkbox, HStack, Text, Tooltip, useBoolean } from 'components/design/next';

export const createMultiOption =
  <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    label: (props: OptionProps<Option, IsMulti, Group>) => ReactNode,
  ) =>
  (props: OptionProps<Option, IsMulti, Group> & { searchTerm?: string }) => {
    const { isSelected, data } = props;
    const is2024Theme = useThemeName() === ThemeName.TwentyFour;
    const [isHovering, { on, off }] = useBoolean(false);

    const icon = props.selectProps.getOptionIcon?.<Option, IconType>(data);

    return is2024Theme ? (
      <components.Option {...props}>
        <Tooltip label={label(props)} isOpen={props.isFocused && isHovering} placement="top-start" hasArrow>
          <HStack px={2} py={1} w="full" onMouseOver={on} onMouseLeave={off}>
            <Checkbox isChecked={props.isSelected} color="unset" pointerEvents="none" mr={2} />
            <Text color="gray.700" whiteSpace="nowrap" textOverflow="ellipsis">
              {label(props)}
            </Text>
          </HStack>
        </Tooltip>
      </components.Option>
    ) : (
      <div className={'blvd-select__option__wrapper'}>
        <components.Option {...props}>
          <BlvdCheckbox checked={isSelected} onChange={noop}>
            {icon && <Icon size={IconSize.Normal} iconType={icon} className={'blvd-select__option__icon'} />}
            {label(props)}
          </BlvdCheckbox>
        </components.Option>
      </div>
    );
  };
