import { createCachedAction } from 'reducers/util';
import { PlanSelector } from './plan.selectors';

export const PLANS_GET_BY_ID = 'plans/GET_BY_ID';
export const PLANS_GET_ALL = 'plans/GET_ALL';

export const PlanActions = PlanApi => {
  'ngInject';

  /**
   * Get plan by ID
   */
  const getById = createCachedAction(
    PLANS_GET_BY_ID,
    PlanApi.getById,
    undefined,
    (state, planId) => PlanSelector.getById(planId)(state),
    PlanSelector.getById,
  );

  /**
   * Gets all plans
   */
  const getAll = createCachedAction(
    PLANS_GET_ALL,
    PlanApi.getAll,
    flushCache => ({ flushCache }),
    state => PlanSelector.getAll(state).length > 0,
    () => state => PlanSelector.getAll(state),
  );

  return {
    getById,
    getAll,
  };
};
