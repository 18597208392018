import { Flex, HStack, Icon, IconButton, LinkBox, LinkBoxProps, LinkOverlay, Text } from 'components/design/next';
import * as React from 'react';
import { Highlight } from './highlight';
import { useListboxContext } from 'components/listbox';
import { useDoClickIfSelected } from './use-do-click-if-selected';
import { NgLink } from 'features/app/components/ng-link';
import { GlobalSearchHits } from './model';

export type SavedViewHitProps = { hit: GlobalSearchHits.SavedViewHit } & LinkBoxProps;

export const SavedViewHit: React.FC<React.PropsWithChildren<SavedViewHitProps>> = ({ hit, ...props }) => {
  const { selectedId } = useListboxContext();
  const selected = selectedId === hit.objectID;

  const ref = React.useRef<HTMLAnchorElement>(null);
  useDoClickIfSelected({ id: hit.objectID, node: ref.current });

  const urlOptions = {
    to: 'dataSets.savedView',
    params: {
      dataSetId: hit.dataSetId,
      savedViewId: hit.objectID,
    },
    options: {
      inherit: false,
    },
  };

  return (
    <LinkBox
      minHeight="14"
      maxHeight="23"
      display="flex"
      flexDir="column"
      justifyContent="center"
      py="2"
      px="4"
      backgroundColor={selected ? 'gray.100' : 'inherit'}
      // this is a somewhat redundant style because if rendered in a listboxoption, hover will result in `selected` any way
      // this is mostly to allow for an isolated story that doesn't depend on listbox option
      _hover={{ textDecoration: 'none', color: 'inherit', backgroundColor: 'gray.100' }}
      {...props}
    >
      <Flex alignItems="center">
        <Icon variant="fas" icon="database" size="4" mr={2} color="yellow.500" />

        <LinkOverlay as={NgLink} {...urlOptions} overflowX="hidden" ref={ref}>
          <Flex direction="column">
            <Text noOfLines={1} fontSize="sm" color="gray.600" title="title">
              <Highlight hit={hit} attribute="name" />
            </Text>
          </Flex>
        </LinkOverlay>

        {selected ? (
          <HStack ml="auto" spacing="4">
            <LinkOverlay as={NgLink} {...urlOptions}>
              <Icon icon="level-down" variant="far" transform="rotate(90deg)" color="gray.400" size="4" />
            </LinkOverlay>

            <IconButton
              as={NgLink}
              disabled={!hit.dataSetId}
              {...urlOptions}
              variant="tertiary"
              size="sm"
              aria-label="open saved view using enter"
              padding="0"
              icon={<Icon icon="eye" variant="far" color="gray.600" size="3" />}
            />
          </HStack>
        ) : null}
      </Flex>
    </LinkBox>
  );
};
