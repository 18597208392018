import * as React from 'react';

import { ProviderProps, WorkflowAutomationsProvider } from '../common/context';
import { ChecklistWorkflowAutomations } from '.';

export const WorkflowAutomationsChecklistWrapper: React.FC<React.PropsWithChildren<ProviderProps>> = props => {
  return (
    <WorkflowAutomationsProvider {...props}>
      <ChecklistWorkflowAutomations />
    </WorkflowAutomationsProvider>
  );
};
