import * as React from 'react';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { Box } from '@chakra-ui/react';

const ChevronsMask = ({
  children,
  shouldMaskCurlyBraces = false,
}: {
  children: React.ReactNode;
  shouldMaskCurlyBraces?: boolean;
}) => (
  <Box as="span" backgroundColor="brand.100" borderRadius="md">
    <Box as="span" visibility="hidden">
      {shouldMaskCurlyBraces ? '{{' : MergeTagStringReplacementUtils.LEFT_CHEVRON}
    </Box>
    {children}
    <Box as="span" visibility="hidden">
      {shouldMaskCurlyBraces ? '}}' : MergeTagStringReplacementUtils.RIGHT_CHEVRON}
    </Box>
  </Box>
);

export const MaskedChevrons = ({ text }: { text: string }) => {
  const shouldMaskCurlyBraces = MergeTagStringReplacementUtils.CURLY_BRACES_REGEXP.test(text);
  const regexp = shouldMaskCurlyBraces
    ? MergeTagStringReplacementUtils.CURLY_BRACES_REGEXP
    : MergeTagStringReplacementUtils.CHEVRONS_REGEXP;

  const split = text.split(regexp);

  return (
    <>
      {split.map((part, index) => {
        switch (index % 4) {
          // the regexp has 3 capturing groups
          // nb. array starts and ends with empty string if it starts or ends with a match
          // so we can safely use index % 4
          case 0: // unmatched string part
            return part;
          case 2: // middle capturing group
            return (
              <ChevronsMask key="index" shouldMaskCurlyBraces={shouldMaskCurlyBraces}>
                {part}
              </ChevronsMask>
            );
          default:
            // cases 1 & 3 are the left & right chevrons/curlies, added in ChevronsMask
            return null;
        }
      })}
    </>
  );
};
