import { DueDateRuleDefinition, FormFieldWidget, TaskTemplate } from '@process-street/subgrade/process';
import { Button, Divider, Text, VStack, Image, HStack } from 'components/design/next';
import { DynamicDueDateLabelService } from 'components/dynamic-due-dates/services/dynamic-due-date-label.service';
import * as React from 'react';

type DynamicDueDateProps = {
  rule: DueDateRuleDefinition;
  taskTemplates: TaskTemplate[];
  dateWidgets: FormFieldWidget[];
  onOverride: () => void;
};

export const DynamicDueDate = ({ rule, taskTemplates, dateWidgets, onOverride }: DynamicDueDateProps) => {
  const itemTypeCopy = rule.ruleType === 'Checklist' ? 'workflow run' : 'task';
  return (
    <VStack py="3" px="4" w="full" spacing="3">
      <Text>Due Date</Text>
      <Divider />

      <HStack justifyContent="center">
        <Image src={require('app/images/task-template/conditional-due-dates-upgrade.svg')} />
      </HStack>

      <Text>This {itemTypeCopy} will be due</Text>
      <Text fontWeight="bold">{DynamicDueDateLabelService.getModalLabelByRule(rule, taskTemplates, dateWidgets)}</Text>

      <Divider />

      <Button colorScheme="brand" onClick={onOverride}>
        Override due date
      </Button>

      <Text fontSize="xs">(Overriding will break the dynamic link)</Text>
    </VStack>
  );
};
