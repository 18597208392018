import {
  ModalCloseButton,
  ModalHeader,
  Text,
  useDisclosure,
  ModalContent,
  Checkbox,
  Icon,
  ModalBody,
  VStack,
  Radio,
  RadioGroup,
  ButtonGroup,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useModalContext,
} from 'components/design/next';
import { SendRichEmailFormFieldConfig, SendRichEmailFormFieldWidget } from '@process-street/subgrade/process';
import * as React from 'react';
import { HStack } from 'components/design/next';
import { SwitchModeAlert } from 'components/widgets/form-field/settings/send-rich-email-settings/switch-mode-alert';
import { ChangeEvent } from 'react';
import { bodyIsEmpty, getEmailBody } from 'features/widgets/components/send-rich-email/util';
import { useWidgetSettingsContext } from 'components/widgets/form-field/settings/widget-settings-context';

type EmailFormat = SendRichEmailFormFieldConfig['emailFormat'];

export const SendRichEmailSettings = () => {
  const { widget, onUpdate } = useWidgetSettingsContext<SendRichEmailFormFieldWidget>();
  const { onClose } = useModalContext();

  const { isOpen: isAlertOpen, onOpen: openAlert, onClose: onAlertClose } = useDisclosure();

  const initialFormat = widget.config.emailFormat ?? 'RichTextOrHtml';
  const [emailFormat, setEmailFormat] = React.useState<EmailFormat>(initialFormat);
  const [editor, setEditor] = React.useState<SendRichEmailFormFieldConfig['editor']>(widget.config.editor);
  const [editAllowed, setEditAllowed] = React.useState<boolean>(widget.config.editAllowed ?? true);
  const [label, setLabel] = React.useState<string>(widget?.label ?? '');

  const handleAlertConfirm = () => {
    onAlertClose();
    submit();
  };

  const handleAlertCancel = () => {
    onAlertClose();
    onClose();
  };

  const handleRadioChange = (v: string) => {
    const newFormat = v as EmailFormat;
    if (newFormat === 'RichTextOrHtml') {
      setEditor('RichEditor');
    } else {
      setEditor('PlainTextEditor');
    }
    setEmailFormat(newFormat);
  };

  const handleEditAllowedChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;
    setEditAllowed(checked);
  };

  const handleUpdate = () => {
    const isDirty = !bodyIsEmpty(getEmailBody(widget.config));
    if (isDirty && initialFormat !== emailFormat) {
      openAlert();
    } else {
      submit();
    }
  };

  const submit = () => {
    const updatedWidget = {
      ...widget,
      config: {
        ...widget.config,
        editor,
        emailFormat,
        editAllowed,
      },
      label,
    };

    onClose();
    onUpdate(updatedWidget);
  };

  return (
    <>
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader p={8}>
          <HStack spacing={4} color={'gray.400'}>
            <Icon icon="envelope" variant="far" size="5" />
            <Text color="gray.700" variant="2">
              {widget.label || 'Untitled send email field'}
            </Text>
          </HStack>
        </ModalHeader>
        <ModalBody as={VStack} px={9} pt={0} pb={8} alignItems="flex-start" spacing="4">
          <FormControl>
            <FormLabel>Label</FormLabel>
            <Input placeholder="Optional label" value={label} onChange={e => setLabel(e.target.value)} />
          </FormControl>

          <RadioGroup onChange={handleRadioChange} value={emailFormat}>
            <VStack alignItems="flex-start" spacing="4">
              <VStack alignItems="flex-start" spacing="0">
                <Radio value="RichTextOrHtml" aria-label="RichTextOrHtml">
                  <Text color="gray.600" variant="1">
                    Rich text or HTML
                  </Text>
                </Radio>

                <VStack marginTop={0} pl="22px" alignItems="flex-start">
                  <Text color="gray.500" variant="-1">
                    Add flourish to your workflow’s email content. <br />
                    Email is sent from <strong>process-street-mail.com</strong> or a{' '}
                    <a
                      href="https://www.process.st/help/docs/email-widget/#custom-sender-domain"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      custom sender domain
                    </a>
                    .
                  </Text>
                  {emailFormat === 'RichTextOrHtml' && (
                    <Checkbox onChange={handleEditAllowedChange} isChecked={editAllowed}>
                      <Text color="gray.600" fontWeight="normal">
                        Allow workflow runners to edit email prior to sending
                      </Text>
                    </Checkbox>
                  )}
                </VStack>
              </VStack>

              <VStack alignItems="flex-start" spacing="0">
                <Radio value="PlainText">
                  <Text color="gray.600" variant="1">
                    Plain text
                  </Text>
                </Radio>

                <VStack marginTop={0} pl="22px" alignItems="flex-start">
                  <Text color="gray.500" variant="-1">
                    Simple, no frills workflow email content.
                    <br />
                    Email is sent from the current user's email client (e.g. Outlook or Gmail) with their email address.
                  </Text>
                </VStack>
              </VStack>
            </VStack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter borderTop="1px" borderColor="gray.200">
          <ButtonGroup>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUpdate}>
              Apply
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>

      <SwitchModeAlert
        onClose={handleAlertCancel}
        isOpen={isAlertOpen}
        onConfirm={handleAlertConfirm}
        targetEmailFormat={emailFormat}
      />
    </>
  );
};
