import * as React from 'react';
import { GetFormSharesQuery } from 'features/forms/query-builder';
import { HStack, Icon, Text, Center, VStack } from 'components/design/next';
import { Avatar, AvatarSize } from 'components/common/Avatar';
import { UserUtils } from '@process-street/subgrade/util';
import { UserType } from '@process-street/subgrade/core';

export type SharedItemProps = {
  formShare: GetFormSharesQuery.FormShareWithResponse;
};

export const SharedItem: React.FC<React.PropsWithChildren<SharedItemProps>> = ({ formShare }) => {
  const { user } = formShare;
  return user.userType === UserType.Standard ? (
    <HStack marginY={2}>
      <Avatar size={AvatarSize.Normal} user={user} />
      <VStack alignItems="flex-start" spacing={0}>
        <Text noOfLines={1} variant="-1">
          {UserUtils.getLabel(user, false)}
        </Text>
        <Text variant="-2" color="gray.500" noOfLines={1}>
          {user.email}
        </Text>
      </VStack>
    </HStack>
  ) : (
    <HStack marginY={2}>
      <Center w={8} h={8}>
        <Icon icon="user" size="4" color="gray.400" />
      </Center>
      <Text variant="-1" color="gray.500" noOfLines={1}>
        {formShare.formShare.email!}
      </Text>
    </HStack>
  );
};
