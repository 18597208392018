import XRegExp from 'xregexp';
import { Option } from '../core';

/**
 * This is a RegExp that will match any non-(letter, mark, number, space or dash) unicode character.
 * @type {RegExp}
 */
const ILLEGAL_URL_TITLE_CHARACTER_REGEXP = XRegExp('[^\\pL\\pM\\pN -]+', 'g');

export function abbreviateForTitle(str?: string): Option<string> {
  if (!str) {
    return undefined;
  }
  return XRegExp.replace(str, ILLEGAL_URL_TITLE_CHARACTER_REGEXP, '')
    .replace(/[\s-]+/g, ' ') // Collapse all repeated dashes and spaces into a single space
    .trim()
    .substr(0, 128)
    .replace(/ +/g, '-');
}
