import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Text,
} from 'components/design/next';
import { getWidgetModalsMachineService, Selectors } from 'features/widgets/widget-modals-machine';
import { useSelector } from '@xstate/react';
import { Widget } from '@process-street/subgrade/process';
import { GetAllRulesByTemplateRevisionIdQuery } from 'features/conditional-logic/query-builder';
import { ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';
import { Muid } from '@process-street/subgrade/core';

interface DeleteWidgetAlertDialogProps {
  onDelete: (widget: Widget) => void;
  templateRevisionId: Muid;
}

const actor = getWidgetModalsMachineService();

export const DeleteWidgetAlertDialog: React.FC<React.PropsWithChildren<DeleteWidgetAlertDialogProps>> = ({
  onDelete,
  templateRevisionId,
}) => {
  const leastDestructiveRef = React.useRef<HTMLButtonElement>(null);
  const onClose = () => {
    actor.send({ type: 'CLOSE_DELETE' });
  };
  const widget = useSelector(actor, Selectors.getWidget);
  const isOpen = useSelector(actor, Selectors.getIsOpen('delete'));

  const rulesQuery = GetAllRulesByTemplateRevisionIdQuery.useQuery({ templateRevisionId });
  const ruleChecker = React.useCallback(
    (widget: Widget) => {
      if (!rulesQuery.data || !widget) return false;
      const { definitions: rules } = rulesQuery.data;

      const widgetHasAssociatedRule = ConditionalLogicCommonUtils.makeWidgetHasAssociatedRule(rules);

      return Boolean(widget.header?.group?.id) && widgetHasAssociatedRule({ widget });
    },
    [rulesQuery.data],
  );
  const isWidgetUsedInCL = ruleChecker(widget!);

  const handleDelete = () => {
    onDelete(widget!);
    onClose();
  };

  return (
    <AlertDialog {...{ isOpen, onClose, leastDestructiveRef }}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          <Text variant="2">Delete this content block?</Text>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        {isWidgetUsedInCL ? (
          <AlertDialogBody>
            Warning: This widget is part of one or more Conditional Logic rules.
            <br />
            Deleting this could negatively impact your workflow.
            <br />
            Are you sure you want to proceed?
          </AlertDialogBody>
        ) : (
          <AlertDialogBody>This content block will be deleted and irrecoverable!</AlertDialogBody>
        )}
        <AlertDialogFooter>
          <ButtonGroup>
            <Button ref={leastDestructiveRef} variant="secondary" onClick={onClose}>
              Cancel
            </Button>

            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
