import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetOrganizationDomainsParams = {
  organizationId?: Muid;
};

export type OrganizationDomain = {
  id: string;
  name: string;
};

export const GetOrganizationDomains = {
  key: ['organization', 'domains'],
  getKey: (params: GetOrganizationDomainsParams): QueryKey => [...GetOrganizationDomains.key, params],
  queryFn: (params: GetOrganizationDomainsParams) =>
    axiosService
      .getAxios()
      .get<OrganizationDomain[]>(`/1/organizations/${params.organizationId}/domains`, { params: { v: 4 } })
      .then(r => r.data),
};

export const useGetOrganizationDomainsQuery = (
  params: GetOrganizationDomainsParams,
  options?: UseQueryOptions<OrganizationDomain[], AxiosError>,
) => {
  return useQuery(GetOrganizationDomains.getKey(params), () => GetOrganizationDomains.queryFn(params), {
    ...options,
    enabled: Boolean(params.organizationId) && options?.enabled !== false,
  });
};
