import * as React from 'react';
import { NativeAutomationWithLink } from '@process-street/subgrade/process';
import { useCustomNotificationsStore } from './custom-notifications-store';
import deepEqual from 'deep-equal';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import {
  CreateNativeAutomationMutation,
  UpdateNativeAutomationActionsMutation,
  UpdateNativeAutomationStatusMutation,
  UpdateNativeAutomationTriggerMutation,
} from 'features/native-automations/query-builder';
import { CustomNotificationUtils } from './custom-notification-utils';
import { useQueryClient } from 'react-query';
import { Muid } from '@process-street/subgrade/core';

export const useSaveCustomNotifications = ({
  automationsMap,
  templateRevisionId,
}: {
  automationsMap: Map<Muid, NativeAutomationWithLink>;
  templateRevisionId: Muid;
}) => {
  const configToAutomationForm = React.useMemo(
    () =>
      CustomNotificationUtils.Transformer.makeConfigToAutomationForm({
        automationsMap,
        templateRevisionId,
      }),
    [automationsMap, templateRevisionId],
  );

  const createAutomationMutation = CreateNativeAutomationMutation.useMutation();
  const updateTriggerMutation = UpdateNativeAutomationTriggerMutation.useMutation();
  const updateActionsMutation = UpdateNativeAutomationActionsMutation.useMutation();
  const updateStatusMutation = UpdateNativeAutomationStatusMutation.useMutation();
  const automations = Array.from(automationsMap.values());

  const store = useCustomNotificationsStore();
  const isDisabled = React.useMemo(() => {
    const allConfigsAreValid = store.configs.every(CustomNotificationUtils.isConfigValid);
    const converted = store.configs.filter(CustomNotificationUtils.isConfigValid).map(configToAutomationForm);
    const configsHaveChanged = !deepEqual(converted, automations);
    const canSave = allConfigsAreValid && configsHaveChanged;
    return !canSave;
  }, [automations, configToAutomationForm, store.configs]);

  const queryClient = useQueryClient();
  const handleSave = async () => {
    const newAutomations = store.configs.filter(CustomNotificationUtils.isConfigValid).map(configToAutomationForm);
    const existingAutomationIdsSet = new Set(automations.map(({ automation: { id } }) => id) ?? []);
    const newAutomationIdsSet = new Set(newAutomations.map(({ id }) => id));
    const deletedAutomationIdsSet = [...existingAutomationIdsSet].filter(id => !newAutomationIdsSet.has(id)) ?? [];

    await Promise.all([
      ...newAutomations.map(async ({ id, form }) => {
        if (existingAutomationIdsSet.has(id)) {
          await updateTriggerMutation.mutateAsync({ nativeAutomationId: id, trigger: form.trigger! });
          return updateActionsMutation.mutateAsync({ nativeAutomationId: id, actions: form.actions! });
        }

        return createAutomationMutation.mutateAsync(form);
      }),
      ...deletedAutomationIdsSet.map(id =>
        updateStatusMutation.mutateAsync({ nativeAutomationId: id, status: 'Deleted' }),
      ),
    ]);
    return queryClient.invalidateQueries(GetAllNativeAutomationsQuery.getKey({ templateRevisionId }));
  };

  return {
    isDisabled,
    handleSave,
    isLoading:
      createAutomationMutation.isLoading ||
      updateTriggerMutation.isLoading ||
      updateActionsMutation.isLoading ||
      updateStatusMutation.isLoading,
  };
};
