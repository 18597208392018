import * as React from 'react';
import { ExecuteAiPrompt } from '@process-street/subgrade/process';
import { forwardRef } from 'components/design/next';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { FieldTypePreservingOmit } from '@process-street/subgrade/core';
import Creatable, { CreatableProps } from 'react-select/creatable';
import { useAiTaskWidgetSelect, AiTaskWidgetOption, UseAiTaskWidgetSelectArgs } from './use-ai-task-widget-select';
import { ClearIndicator } from 'components/design/BlvdSelect/components/ClearIndicator';
import { DropdownIndicator } from 'components/design/BlvdSelect/components/DropdownIndicator';
import { AiTaskWidgetSelectOption } from './ai-task-widget-select-option';
import { AiTaskWidgetSelectValue } from './ai-task-widget-select-value';
import { GroupBase, SelectComponentsConfig } from 'react-select';

export interface CreatableAiTaskWidgetSelectProps
  extends FieldTypePreservingOmit<
      CreatableProps<AiTaskWidgetOption, false, GroupBase<AiTaskWidgetOption>>,
      'value' | 'onChange'
    >,
    UseAiTaskWidgetSelectArgs {
  onChange: (value: AiTaskWidgetOption) => void;
}

export const CreatableAiTaskWidgetSelect = forwardRef<CreatableAiTaskWidgetSelectProps, any>(
  ({ value: valueProp, onChange, filter = ExecuteAiPrompt.isOutputWidget, ...props }, ref) => {
    const { value, taskWidgetOptions } = useAiTaskWidgetSelect({ filter, value: valueProp });

    return (
      <Creatable<AiTaskWidgetOption>
        className="blvd-select"
        classNamePrefix="blvd-select"
        ref={ref}
        options={taskWidgetOptions ?? []}
        value={value}
        createOptionPosition="first"
        formatCreateLabel={inputValue => `Use '${inputValue}'`}
        components={components}
        onChange={option => {
          if (BlvdSelectHelpers.isOptionType<AiTaskWidgetOption>(option)) {
            onChange(option);
          }
        }}
        {...props}
      />
    );
  },
);

const components: SelectComponentsConfig<AiTaskWidgetOption, false, GroupBase<AiTaskWidgetOption>> = {
  ClearIndicator,
  DropdownIndicator,
  Option: AiTaskWidgetSelectOption,
  SingleValue: AiTaskWidgetSelectValue,
};
