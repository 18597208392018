import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  Text,
  Button,
  Badge,
  HStack,
  VStack,
  Box,
  Link,
  Image,
} from 'components/design/next';

export interface UpgradeModalProps {
  admin: boolean;
  visible: boolean;
  onUpgradeClick: () => void;
  onHide: () => void;
}

export const UpgradeModal: React.FunctionComponent<React.PropsWithChildren<UpgradeModalProps>> = ({
  admin,
  visible,
  onUpgradeClick,
  onHide,
}) => {
  const handleUpgrade = () => {
    onHide();
    onUpgradeClick();
  };

  return (
    <div className="approvals-upgrade-modal">
      <Modal isOpen={visible} onClose={onHide} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <Text>Upgrade to use Approvals</Text>
              <Badge colorScheme="green" variant="solid">
                Standard
              </Badge>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody as={VStack} spacing="4" py="4">
            <Box>
              <Image src="../../../../images/task-template/approval-rules-empty-img.svg" alt="approval rules icon" />
            </Box>
            <Text px="4" textAlign="center">
              Streamline any process that needs authorization by another person with Approvals. You can create one-step,
              multi-stage, or sequential approvals.
            </Text>
            <Box>
              {admin ? (
                <Button variant="primary" onClick={handleUpgrade}>
                  Upgrade to Standard
                </Button>
              ) : (
                <Text>Contact an administrator to upgrade your plan.</Text>
              )}
            </Box>
            <Link href="https://www.process.st/help/docs/approvals/" isExternal>
              Learn more &raquo;
            </Link>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
