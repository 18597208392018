import { OrganizationUsageStats } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { axiosService } from 'services/axios-service';
import { HttpStatus } from '@process-street/subgrade/util';

export type CanAddResponse = ((OrganizationUsageStats | { message: string }) & { canAdd: false }) | { canAdd: true };

const canAddTemplate = async (): Promise<CanAddResponse> => {
  return axiosService
    .getAxios()
    .get(`/1/templates/can-add`)
    .then(
      () => ({ canAdd: true }),
      (error: AxiosError) => {
        switch (error.response?.status) {
          case HttpStatus.PAYMENT_REQUIRED:
          case HttpStatus.FORBIDDEN: {
            return { ...error.response.data, canAdd: false };
          }
          default:
            return { canAdd: true };
        }
      },
    );
};

export const API = {
  canAddTemplate,
} as const;
