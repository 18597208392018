import { Muid } from '@process-street/subgrade/core';
import { TaskStats } from '@process-street/subgrade/process';
import { ChecklistRevision } from '@process-street/subgrade/process/checklist-model';
import { queryClient } from 'components/react-root';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { ChecklistRevisions } from '../../resources/checklist-revisions-resource.interface';
import angular from 'angular';

export interface ChecklistRevisionApi {
  counts(): angular.IPromise<number>;
  get(id: Muid): angular.IPromise<ChecklistRevision>;
  getActiveByChecklistId(id: Muid): Promise<ChecklistRevision>;
  query(params: { where: string; include: string }): angular.IPromise<ChecklistRevision[]>;
  scheduleOneMigrationByChecklistRevisionId(id: Muid): angular.IPromise<{ message: string; code?: string }>;
  migrateOneWithPriorityByChecklistRevisionId(id: Muid): angular.IPromise<ChecklistRevision[]>;
  getTaskStatsByChecklistRevisionId(id: Muid): angular.IPromise<TaskStats[]>;
}

export class ChecklistRevisionApiImpl implements ChecklistRevisionApi {
  public static $inject = ['ChecklistRevisions'];

  constructor(private checklistRevisions: ChecklistRevisions) {}

  public counts = () => this.checklistRevisions.counts().$promise;

  public get = (id: Muid) => this.checklistRevisions.get({ id }).$promise;

  public getActiveByChecklistId = (id: Muid) =>
    queryClient.fetchQuery(GetActiveChecklistRevisionByChecklistIdQuery.getKey({ checklistId: id }), () =>
      GetActiveChecklistRevisionByChecklistIdQuery.queryFn({ checklistId: id }),
    );

  public query = (where: { where: string; include: string }) => this.checklistRevisions.query(where).$promise;

  public scheduleOneMigrationByChecklistRevisionId = (id: Muid) =>
    this.checklistRevisions.scheduleOneMigrationByChecklistRevisionId({ id }).$promise;

  public migrateOneWithPriorityByChecklistRevisionId = (id: Muid) =>
    this.checklistRevisions.migrateOneWithPriorityByChecklistRevisionId({ id }).$promise;

  public getTaskStatsByChecklistRevisionId = (checklistRevisionId: Muid) =>
    this.checklistRevisions.getTaskStatsByChecklistRevisionId({ id: checklistRevisionId }).$promise;
}
