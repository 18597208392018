import * as React from 'react';
import { PlanInterval } from '@process-street/subgrade/billing';
import { Box, Radio, Text, Flex } from 'components/design/next';
import { PlanCostInfo } from 'pages/organizations/manage/billing/models/models';

type IntervalCardProps = {
  planInterval: PlanInterval;
  selected: boolean;
  planCostInfo: PlanCostInfo;
};

export const IntervalCard: React.FC<React.PropsWithChildren<IntervalCardProps>> = ({
  planInterval,
  selected,
  planCostInfo,
}) => {
  const intervalText = planInterval === PlanInterval.Monthly ? 'Monthly' : 'Yearly';
  const perText = planInterval === PlanInterval.Monthly ? 'month' : 'year';

  return (
    <Box
      as="label"
      padding={6}
      bgColor={selected ? 'brand.100' : '#fff'}
      border="1px solid"
      borderColor={selected ? 'brand.500' : 'gray.300'}
      borderRadius="10px"
      minWidth="217px"
    >
      <Radio value={planInterval}>
        <Flex alignItems="center">
          <Text as="span" ml={1} variant="-1" fontWeight="bold" color="gray.700">
            {intervalText}
          </Text>

          {planInterval === PlanInterval.Yearly && (
            <Flex borderRadius="4px" backgroundColor="brand.500" ml={7}>
              <Text as="span" color="#fff" variant="-2" fontWeight="bold" margin="2px 4px">
                Save 17%
              </Text>
            </Flex>
          )}
        </Flex>
      </Radio>

      <Flex alignItems="baseline" mt={2}>
        <Text variant="2" fontWeight="medium" color="gray.600">
          ${planCostInfo.planCost.rawCost}
        </Text>
        <Text variant="-2" ml={1} color="gray.700">
          per user per {perText}
        </Text>
      </Flex>
      <Text variant="-2" mt={1} color="gray.500">
        {planCostInfo.totalNew}
      </Text>
    </Box>
  );
};
