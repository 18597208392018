import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { TaskTemplateAutomationsButton } from '.';

export const TaskTemplateAutomationsButtonModule = angular
  .module('taskTemplateAutomationsButton', [])
  .component(
    'psTaskTemplateAutomationsButton',
    reactToAngular(TaskTemplateAutomationsButton, ['taskTemplate', 'templateRevision', 'setHasAutomations']),
  ).name;
