import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule/approval-rule-subject.model';
import { Muid } from '@process-street/subgrade/core';
import { TaskTemplate, TaskTemplateUpdateResponse } from '@process-street/subgrade/process';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { EntityMap, LookupMap, RequestStage } from '@process-street/subgrade/redux/types';
import { getStatusReducer } from 'reducers/statuses/statuses-utils';
import {
  TASK_TEMPLATE_DELETE_ALL,
  TASK_TEMPLATE_UPDATE_ALL_ORDER_TREES,
} from 'reducers/task-template/task-template.actions';
import { ApprovalRuleSubjectStatuses } from 'reducers/types';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { ReducerObject } from 'reducers/util/types';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import {
  APPROVAL_RULE_CREATE_ALL,
  APPROVAL_RULE_DELETE_ALL,
  APPROVAL_RULE_GET_ALL_BY_TEMPLATE_REVISION,
} from './approval-rules.actions';

interface ApprovalRulesActionType {
  payload: ApprovalRuleSubject[];
  meta: { templateRevisionId: Muid };
}

interface UpdateAllOrderTreesTaskTemplateActionType {
  payload: TaskTemplateUpdateResponse[];
  meta: { approvalRulesToDelete: ApprovalRuleSubject[]; templateRevisionId: Muid };
}

interface DeleteTaskTemplateActionType {
  payload: TaskTemplate[];
  meta: { relatedApprovalRules: ApprovalRuleSubject[] };
}

const approvalRuleSubjectEntitiesActionHandlers: ReducerObject<EntityMap<ApprovalRuleSubject>> = {
  [APPROVAL_RULE_GET_ALL_BY_TEMPLATE_REVISION + RequestStage.Success]: (
    state: EntityMap<ApprovalRuleSubject>,
    { payload: rules }: ApprovalRulesActionType,
  ) => EntitiesReducerUtils.upsertAll(state, rules),
  [APPROVAL_RULE_CREATE_ALL + RequestStage.Success]: (
    state: EntityMap<ApprovalRuleSubject>,
    { payload: rules }: ApprovalRulesActionType,
  ) => EntitiesReducerUtils.upsertAll(state, rules),
  [APPROVAL_RULE_DELETE_ALL + RequestStage.Success]: (
    state: EntityMap<ApprovalRuleSubject>,
    { payload: rules }: ApprovalRulesActionType,
  ) => EntitiesReducerUtils.deleteAll(state, rules),
  [TASK_TEMPLATE_UPDATE_ALL_ORDER_TREES]: (
    state: EntityMap<ApprovalRuleSubject>,
    { meta: { approvalRulesToDelete } }: UpdateAllOrderTreesTaskTemplateActionType,
  ) => EntitiesReducerUtils.deleteAll(state, approvalRulesToDelete),
  [TASK_TEMPLATE_DELETE_ALL]: (
    state: EntityMap<ApprovalRuleSubject>,
    { meta: { relatedApprovalRules } }: DeleteTaskTemplateActionType,
  ) => EntitiesReducerUtils.deleteAll(state, relatedApprovalRules),
};

export const approvalRuleSubjectReducer = handleActions(approvalRuleSubjectEntitiesActionHandlers, {});

const approvalRuleSubjectByTemplateRevisionIdActionHandlers: ReducerObject<LookupMap> = {
  [APPROVAL_RULE_GET_ALL_BY_TEMPLATE_REVISION]: (
    state: LookupMap,
    { payload: rules, meta: { templateRevisionId } }: ApprovalRulesActionType,
  ) => LookupsReducerUtils.replaceAllUsingSelectorFunctions(state, rules, () => templateRevisionId),
  [APPROVAL_RULE_CREATE_ALL]: (
    state: LookupMap,
    { payload: rules, meta: { templateRevisionId } }: ApprovalRulesActionType,
  ) => LookupsReducerUtils.upsertAllUsingSelectorFunctions(state, rules, () => templateRevisionId),
  [APPROVAL_RULE_DELETE_ALL]: (
    state: LookupMap,
    { payload: rules, meta: { templateRevisionId } }: ApprovalRulesActionType,
  ) => LookupsReducerUtils.deleteAllUsingSelectorFunctions(state, rules, () => templateRevisionId),
  [TASK_TEMPLATE_UPDATE_ALL_ORDER_TREES]: (
    state: LookupMap,
    { meta: { approvalRulesToDelete, templateRevisionId } }: UpdateAllOrderTreesTaskTemplateActionType,
  ) => {
    if (approvalRulesToDelete.length === 0) {
      return state;
    }

    return LookupsReducerUtils.deleteAllUsingSelectorFunctions(state, approvalRulesToDelete, () => templateRevisionId);
  },
  [TASK_TEMPLATE_DELETE_ALL]: (
    state: LookupMap,
    { payload: taskTemplates, meta: { relatedApprovalRules } }: DeleteTaskTemplateActionType,
  ) => {
    if (relatedApprovalRules.length === 0) {
      return state;
    }

    // here we must assume that this action is performed under the same template revision
    const templateRevisionId = taskTemplates[0].templateRevision.id;
    return LookupsReducerUtils.deleteAllUsingSelectorFunctions(state, relatedApprovalRules, () => templateRevisionId);
  },
};

export const approvalRuleSubjectByTemplateRevisionIdReducer = handleActionsOnSuccess(
  approvalRuleSubjectByTemplateRevisionIdActionHandlers,
  {},
);

export const approvalRuleSubjectLookupsReducer = combineReducers({
  byTemplateRevisionId: approvalRuleSubjectByTemplateRevisionIdReducer,
});

export const approvalRuleSubjectStatusesReducer = combineReducers<ApprovalRuleSubjectStatuses>({
  byTemplateRevisionId: getStatusReducer(APPROVAL_RULE_GET_ALL_BY_TEMPLATE_REVISION, 'templateRevisionId'),
});
