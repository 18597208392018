import { OrganizationUsageStats, UserType } from '@process-street/subgrade/core';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { API, CanUseResponse } from './api';

export const useCanUseOrganization = (): UseQueryResult<CanUseResponse> => {
  const user = useSelector(SessionSelector.getCurrentUser);
  const org = useSelector(SessionSelector.getSelectedOrganization);

  return useQuery(['organization', org?.id, 'can-use'], () => API.canUseOrganization(org!.id), {
    enabled: Boolean(org && user && user.userType === UserType.Standard),
    staleTime: 1000 * 60,
  });
};

export const useOrganizationUsageStats = (): UseQueryResult<OrganizationUsageStats> => {
  const user = useSelector(SessionSelector.getCurrentUser);
  const org = useSelector(SessionSelector.getSelectedOrganization);

  return useQuery(['organization', org?.id, 'usage-stats'], () => API.getOrganizationUsageStats(org!.id), {
    enabled: Boolean(org && user && user.userType === UserType.Standard),
    staleTime: 1000 * 60,
  });
};

export const useInvalidateCanUseOrganization = () => {
  const queryClient = useQueryClient();
  const org = useSelector(SessionSelector.getSelectedOrganization);

  const invalidateCanUseOrganization = () => {
    queryClient.invalidateQueries(['organization', org?.id, 'usage-stats']);
    queryClient.invalidateQueries(['organization', org?.id, 'can-use']);
  };

  return { invalidateCanUseOrganization };
};

export const useUserSettings = <T>(key: string, defaultValue: T): UseQueryResult<T> => {
  const user = useSelector(SessionSelector.getCurrentUser);

  return useQuery(['users', user?.id, 'settings'], () => API.getUserSettings(user!.id), {
    enabled: Boolean(user && user.userType === UserType.Standard),
    staleTime: 1000 * 60,
    select: data => {
      return { ...defaultValue, ...data[key] };
    },
  });
};

type UpdateUserSettingRequest<T> = {
  key: string;
  value: T;
};

/**
 * Note you can't update nested objects via a JSON path eg. 'onboarding.b.c' with this mutation.
 * Only the root keys eg. 'onboarding'
 */
export const useMutateUserSettings = <T extends Record<string, unknown>>() => {
  const queryClient = useQueryClient();
  const user = useSelector(SessionSelector.getCurrentUser);

  return useMutation<void, unknown, UpdateUserSettingRequest<T>>(
    (request: UpdateUserSettingRequest<T>) => {
      if (user) {
        return API.updateUserSettings(user.id, request.key, request.value);
      } else {
        return Promise.resolve();
      }
    },
    {
      onMutate: async request => {
        if (user) {
          await queryClient.cancelQueries(['users', user.id, 'settings']);
          const previousUserSettings = queryClient.getQueryData<T>(['users', user.id, 'settings']);
          if (previousUserSettings) {
            queryClient.setQueryData(['users', user.id, 'settings'], {
              ...previousUserSettings,
              [request.key]: request.value,
            });
          }
          return { previousUserSettings };
        } else {
          return Promise.resolve();
        }
      },
      onError: (_err, _request, context) => {
        const { previousUserSettings } = (context as { previousUserSettings: unknown }) ?? {};
        if (previousUserSettings && user) {
          queryClient.setQueryData(['users', user.id, 'settings'], previousUserSettings);
        }
      },
      onSettled: () => {
        if (user) {
          return queryClient.invalidateQueries(['users', user.id, 'settings']);
        } else {
          return Promise.resolve();
        }
      },
    },
  );
};
