import * as React from 'react';
import { SubtasksFormFieldActor } from './subtasks-content-machine';
import { useActor } from '@xstate/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { Box } from 'components/design/next';
import { FormFieldLabel } from '../../form-fields/common/form-field-label';
import {
  SubtaskEditor,
  SubtasksFormField,
} from 'app/features/one-off-tasks/components/form-fields/subtasks-form-field';
import { MultiSelectFieldValue, MultiSelectFormFieldWidget } from '@process-street/subgrade/process';
import { ViewModeInteractionWrapper } from '../../view-mode-interaction-wrapper/view-mode-interaction-wrapper';
import { useDebouncedCallback } from 'use-debounce';
import { useUnmount } from 'react-use';
import { PreventClickOverlay } from '../../form-fields/common/prevent-click-overlay';

export interface SubtasksContentProps {
  actor: SubtasksFormFieldActor;
  isFirst?: boolean;
  isLast?: boolean;
}

export const SubtasksContent: React.FC<React.PropsWithChildren<SubtasksContentProps>> = ({
  actor,
  isFirst = false,
  isLast = false,
}) => {
  const [state, send] = useActor(actor);
  const { widget, template, recentlyMovedFrom, labelActor, isReadOnly } = state.context;
  const ref = React.useRef<HTMLDivElement | null>(null);

  const handleSave = React.useCallback(
    (items: MultiSelectFieldValue[]) => {
      send({
        type: 'UPDATE_WIDGET',
        widget: {
          ...widget,
          config: {
            ...widget.config,
            items,
          },
        } as MultiSelectFormFieldWidget,
      });
    },
    [send, widget],
  );

  const handleSaveDebounced = useDebouncedCallback(handleSave, 500);

  useUnmount(() => {
    handleSaveDebounced.flush();
  });

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer alignItems="flex-start">
        {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
        {labelActor && <FormFieldLabel isReadOnly={isReadOnly} actor={labelActor} />}
        <Box
          w="full"
          mt={2}
          ref={node => {
            ref.current = node;
            if (node && !state.context.inputNode) {
              send({ type: 'SET_NODE', node });
            }
          }}
          scrollMarginBottom={17}
        >
          <ViewModeInteractionWrapper templateId={template.id}>
            <SubtasksFormField
              items={widget.config.items as MultiSelectFieldValue[]}
              editorType={SubtaskEditor.WFEditor}
              templateRevisionId={widget.templateRevision.id}
              headerId={widget.header.id}
              isReadOnly={isReadOnly}
              onSave={handleSaveDebounced}
            />
            {isReadOnly && <PreventClickOverlay />}
          </ViewModeInteractionWrapper>
        </Box>
        {!isReadOnly && (
          <>
            <WidgetListItemDragIcon />
            <FormsWidgetMenu>
              <FormsWidgetMenuItems.Required widget={widget} />
              <FormsWidgetMenuItems.Duplicate />
              <FormsWidgetMenuItems.MoveToStep widget={widget} />
              <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
              <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
              <FormsWidgetMenuItems.Delete />
            </FormsWidgetMenu>
          </>
        )}
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
