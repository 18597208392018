import create from 'zustand';

interface ReassignModalState {
  isOpen: boolean;
  checklistId?: string;
  taskId?: string;
}

interface ReassignModalEffects {
  onOpen: (params: { checklistId: string; taskId?: string }) => void;
  onClose: () => void;
}

type ReassignModalStore = ReassignModalState & ReassignModalEffects;

export const useReassignModalStore = create<ReassignModalStore>(set => ({
  isOpen: false,
  checklistId: undefined,
  taskId: undefined,
  isChecklist: true,
  onOpen: ({ checklistId, taskId }) => set({ checklistId, taskId, isOpen: true }),
  onClose: () => set({ checklistId: undefined, taskId: undefined, isOpen: false }),
}));
