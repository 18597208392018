import * as React from 'react';
import {
  Box,
  HStack,
  Icon,
  Image,
  ListIcon,
  ListItem,
  StackProps,
  Text,
  TextProps,
  UnorderedList,
  VStack,
} from 'components/design/next';
import { StringUtils } from '@process-street/subgrade/util';
import { GoNativeService } from 'features/go-native/go-native-service';

export type LeftPaneProps = StackProps & {
  title?: string;
  titleProps?: Partial<TextProps>;
  templateName?: string;
  showBenefits?: boolean;
};

export const ShellLeftPane: React.VFC<LeftPaneProps> = ({
  title = `AI-Powered Process${StringUtils.NBSP}Management`,
  titleProps,
  showBenefits = false,
  templateName,
  ...props
}) => {
  const goNativeApp = GoNativeService.isGoNativeApp();
  return (
    <VStack
      w="full"
      py={{ base: '10', lg: '20' }}
      px="6"
      justifyContent="space-between"
      minHeight={{ lg: '100vh' }}
      bgGradient="linear(to-br, blue.500 25%, purple.500 124%)"
      spacing={{ base: '10', lg: '20' }}
      position={{ base: 'relative', lg: 'sticky' }}
      top="0"
      {...props}
    >
      <HStack justifyContent={{ base: 'center', lg: 'flex-start' }} w="full">
        <Image alt="process.st" src={require('../../../../images/ps-logo-white.svg')} />
      </HStack>

      {!goNativeApp && (
        <VStack maxWidth="2xl" alignItems="center" color="white" spacing={{ base: '5', lg: '11' }} w="full">
          {templateName && (
            <Text
              textAlign="center"
              fontSize={{ base: 'sm', md: 'md' }}
              lineHeight={{ base: 'normal', md: 'base' }}
              textTransform="uppercase"
              opacity="0.8"
              as="h4"
              mb="-4"
            >
              {templateName}
            </Text>
          )}

          <Text
            textAlign="center"
            maxWidth="572px"
            fontSize={{ base: 'xl', md: '2xl' }}
            lineHeight={{ base: 'normal', md: 'base' }}
            fontWeight="medium"
            {...titleProps}
          >
            {title}
          </Text>

          {showBenefits && (
            <HStack w="full" maxWidth="400px" alignItems="center">
              <UnorderedList margin="0" listStyleType="none" spacing="4">
                {benefits.map(benefit => (
                  <HStack as={ListItem} spacing="4" key={benefit}>
                    <ListIcon as={() => <Icon variant="far" icon="check-circle" color="white" size="5" />} />
                    <Text>{benefit}</Text>
                  </HStack>
                ))}
              </UnorderedList>
            </HStack>
          )}

          <VStack opacity="0.7" alignItems="center" w="full" spacing="5">
            <Text variant="-1u" textAlign="center" fontWeight="medium">
              Join over 1 million Process&nbsp;Street&nbsp;Users
            </Text>

            <HStack spacing="10" w="full" maxWidth="md" display={{ base: 'none', lg: 'flex' }} justify="center">
              <Image alt="Drift" src={require('../../../../images/signup/drift.svg')} />
              <Image alt="GAP" src={require('../../../../images/signup/gap.svg')} />
              <Image alt="Salesforce" src={require('../../../../images/signup/salesforce.svg')} />
              <Image alt="Colliers" src={require('../../../../images/signup/colliers.svg')} />
              <Image alt="Goverment of Canada" src={require('../../../../images/signup/gov-of-canada.svg')} />
            </HStack>
          </VStack>
        </VStack>
      )}
      <Box display={{ base: 'none', lg: 'block' }} />
    </VStack>
  );
};

const benefits = [
  'Improve consistency by making steps required and enforcing order',
  'Dynamic due dates and task assignment',
  'Approvals & conditional logic',
  '5000+ automations',
  'Knowledge base pages',
  'Unlimited storage',
];
