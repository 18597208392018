import { Center, FlexProps } from 'components/design/next';
import * as React from 'react';

export type ShellContentProps = FlexProps;

export const ShellContent: React.FC<React.PropsWithChildren<ShellContentProps>> = ({ children, ...props }) => {
  return (
    <Center ml="auto" mr="auto" w="full" p="4" px={{ base: 4, lg: 20 }} {...props}>
      {children}
    </Center>
  );
};
