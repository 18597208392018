import {
  DueDateRuleOffsetDirection,
  DueDateRuleSourceType,
  DueDateRuleTaskType,
  isHeading,
} from '@process-street/subgrade/process';
import Muid from 'node-muid';
import { uuid } from 'services/uuid';
import { connectService } from 'reducers/util';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { DueDateHelper } from 'features/dynamic-due-dates/services/due-date-helper';

export class DynamicDueDateService {
  constructor(
    $ngRedux,
    $q,
    DueDateRuleDefinitionDao,
    DueDateRuleDefinitionValidationService,
    DynamicDueDateActions,
    OrganizationMembershipService,
    OrganizationService,
    SecurityService,
    SessionService,
    TemplateRevisionService,
    ToastService,
    WidgetService,
  ) {
    'ngInject';

    this.$q = $q;
    this.DueDateRuleDefinitionDao = DueDateRuleDefinitionDao;
    this.DueDateRuleDefinitionValidationService = DueDateRuleDefinitionValidationService;
    this.Muid = Muid;
    this.OrganizationMembershipService = OrganizationMembershipService;
    this.OrganizationService = OrganizationService;
    this.SecurityService = SecurityService;
    this.SessionService = SessionService;
    this.TemplateRevisionService = TemplateRevisionService;
    this.uuid = uuid;
    this.WidgetService = WidgetService;
    this.ToastService = ToastService;

    connectService('DynamicDueDateService', $ngRedux, null, DynamicDueDateActions)(this);
  }

  saveRules(templateRevisionId, rules) {
    return this.actions.upsertAll(templateRevisionId, rules);
  }

  deleteRules(templateRevisionId, ids) {
    return this.actions.deleteAllByIds(templateRevisionId, ids);
  }

  findRuleForTask(rules, taskTemplate) {
    return rules && taskTemplate && DueDateHelper.findRuleForTask(rules, taskTemplate);
  }

  findRuleForChecklist(rules) {
    return rules && DueDateHelper.findRuleForChecklist(rules);
  }

  saveDueRule(data, templateRevision, currentRule, taskTemplate, taskTemplates, formFieldWidgets) {
    const rule = this.convertRuleDataToRequest(data, templateRevision, currentRule, taskTemplate);

    try {
      this.DueDateRuleDefinitionValidationService.validate(rule, taskTemplates, formFieldWidgets);

      return this.saveRules(templateRevision.id, [rule]).catch(() => {
        this.ToastService.openToast({
          status: 'error',
          title: `We're having problems updating the due date`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
    } catch (e) {
      const errorMessage = this.extractUserFriendlyValidationError(e);

      this.ToastService.openToast({
        status: 'error',
        title: `We're having problems updating the due date`,
        description: errorMessage,
      });

      return this.$q.reject(errorMessage);
    }
  }

  extractUserFriendlyValidationError(e) {
    let { message } = e;
    if (!message) {
      return e;
    }

    // remove details in brackets
    const index = message.indexOf('(');
    if (index > 0) {
      message = message.substr(0, index);
    }

    return `Oops! ${message}`;
  }

  saveDueRules(data, selectedTaskTemplates, templateRevision, rules, taskTemplates, formFieldWidgets) {
    const convertedRules = selectedTaskTemplates
      .filter(taskTemplates => !isHeading(taskTemplates))
      .map(taskTemplate => {
        const currentRule = this.findRuleForTask(rules, taskTemplate);

        return this.convertRuleDataToRequest(data, templateRevision, currentRule, taskTemplate);
      });

    try {
      convertedRules.forEach(rule => {
        this.DueDateRuleDefinitionValidationService.validate(rule, taskTemplates, formFieldWidgets);
      });

      return this.saveRules(templateRevision.id, convertedRules);
    } catch (e) {
      const errorMessage = `The rule is invalid: ${e}`;

      this.ToastService.openToast({
        status: 'warning',
        title: `We couldn't update the due date`,
        description: errorMessage,
      });

      return this.$q.reject(errorMessage);
    }
  }

  deleteDueRules(templateRevisionId, selectedTaskTemplates, rules) {
    const ruleIds = selectedTaskTemplates
      .map(taskTemplate => {
        const currentRule = this.findRuleForTask(rules, taskTemplate);

        return currentRule ? currentRule.id : null;
      })
      .filter(id => !!id);

    if (ruleIds.length > 0) {
      return this.deleteRules(templateRevisionId, ruleIds);
    } else {
      return this.$q.reject('no rules');
    }
  }

  /**
   * data: {
   *   offset,
   *   offsetDirection,
   *   rule: {
   *     sourceType,
   *     widget
   *   }
   */
  convertRuleDataToRequest(data, templateRevision, currentRule, taskTemplate) {
    const templateRevisionId = templateRevision.id;
    const offsetDirection =
      data.offsetDirection === 'after' ? DueDateRuleOffsetDirection.After : DueDateRuleOffsetDirection.Before;
    const ruleId = currentRule ? currentRule.id : this.Muid.fromUuid(this.uuid());
    const { sourceType } = data.rule;

    const rule = {
      id: ruleId,
      templateRevision: {
        id: templateRevisionId,
      },
      organization: {
        id: templateRevision.organization.id,
      },
      targetTaskTemplateGroup: taskTemplate && {
        id: taskTemplate.group.id,
      },
      sourceType,
      offsetDirection,
      dueOffset: data.offset,
      workdaysOnly: data.offset.workdaysOnly,
      ruleType: data.ruleType ?? DueDateRuleTaskType,
    };

    const { widget } = data.rule;

    if (sourceType === DueDateRuleSourceType.FormFieldValue) {
      const groupId = widget ? widget.header.group.id : currentRule.formFieldWidgetGroup.id;
      rule.formFieldWidgetGroup = {
        id: groupId,
      };
    } else if ([DueDateRuleSourceType.TaskCompletedDate, DueDateRuleSourceType.TaskDueDate].includes(sourceType)) {
      const groupId = widget ? widget.group.id : currentRule.taskTemplateGroup.id;
      rule.taskTemplateGroup = {
        id: groupId,
      };
    }

    return rule;
  }
}
