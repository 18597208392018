import angular from 'angular';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.services')
  .service(
    'TemplateTaskAssignmentService',
    function ($rootScope, $q, SessionService, TemplateTaskAssignments, ToastService) {
      const logger = trace({ name: 'TemplateTaskAssignmentService' });

      const self = this;

      /**
       * Get all template task assignments for a given template revision id.
       *
       * @param templateRevisionId
       * @returns {*}
       */
      self.getAllByTemplateRevisionId = function (templateRevisionId) {
        return TemplateTaskAssignments.getAllByTemplateRevisionId({
          templateRevisionId,
        }).$promise.then(
          assignments => assignments,
          response => {
            logger.error('Could not get assignments by template revision id : %s', templateRevisionId);

            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading the task assignments`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });

            return $q.reject(response);
          },
        );
      };

      /**
       * Converts a list of assignments into a map between task template ids and users.
       *
       * @param assignments
       * @returns {{}}
       */
      self.toAssigneesMap = function (assignments) {
        const assigneesMap = {};
        assignments.forEach(assignment => {
          assigneesMap[assignment.taskTemplate.id] = assigneesMap[assignment.taskTemplate.id] || [];
          assigneesMap[assignment.taskTemplate.id].push(assignment.organizationMembership.user);
        });
        return assigneesMap;
      };

      self.assign = function (taskTemplateId, email) {
        return TemplateTaskAssignments.assign({
          taskTemplateId,
          email,
        }).$promise;
      };

      self.unassign = function (taskTemplateId, email) {
        return TemplateTaskAssignments.unassign({
          taskTemplateId,
          email,
        }).$promise;
      };

      self.assignAll = function (taskTemplates, email) {
        $rootScope.$broadcast(EventName.TASK_TEMPLATE_BULK_ASSIGN_STARTED, taskTemplates);

        const taskTemplateIds = taskTemplates.map(taskTemplate => taskTemplate.id);

        return TemplateTaskAssignments.assignAll({
          taskTemplatesIds: taskTemplateIds,
          email,
        }).$promise.then(
          response => {
            $rootScope.$broadcast(EventName.TASK_TEMPLATE_BULK_ASSIGN_OK, taskTemplates, response);

            return response;
          },
          response => {
            return $q.reject(response);
          },
        );
      };

      self.unassignAll = function (taskTemplates, email) {
        $rootScope.$broadcast(EventName.TASK_TEMPLATE_BULK_UNASSIGN_STARTED, taskTemplates);

        const taskTemplateIds = taskTemplates.map(taskTemplate => taskTemplate.id);

        return TemplateTaskAssignments.unassignAll({
          taskTemplatesIds: taskTemplateIds,
          email,
        }).$promise.then(
          response => {
            $rootScope.$broadcast(EventName.TASK_TEMPLATE_BULK_UNASSIGN_OK, taskTemplates, response);

            return response;
          },
          response => {
            return $q.reject(response);
          },
        );
      };
    },
  );
