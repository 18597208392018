import { IdentifiableItem } from '@process-street/subgrade/core';
import { ProgressStatus } from '@process-street/subgrade/dashboard';
import { BlvdSelect, GroupBase } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { Box, Checkbox, HStack, Tag, TagCloseButton, TagLabel, TagProps } from 'components/design/next';
import { useFunctionRef } from 'hooks/use-function-ref';
import React from 'react';
import { components, SelectComponentsConfig } from 'react-select';

export interface StatusListItem extends IdentifiableItem<ProgressStatus> {
  label: string;
  status: ProgressStatus;
}

type Option = { label: string; value: ProgressStatus };

export interface ChecklistStatusMultiselectProps {
  id: string;
  statuses: ProgressStatus[];
  onDone: (statuses: ProgressStatus[]) => void;
  extractStatuses?: ProgressStatus[];
}

const statusListItemMap: { [key in ProgressStatus]: StatusListItem } = {
  [ProgressStatus.Archived]: {
    id: ProgressStatus.Archived,
    label: 'Archived',
    status: ProgressStatus.Archived,
  },
  [ProgressStatus.Completed]: {
    id: ProgressStatus.Completed,
    label: 'Completed',
    status: ProgressStatus.Completed,
  },
  [ProgressStatus.DueSoon]: {
    id: ProgressStatus.DueSoon,
    label: 'Due soon',
    status: ProgressStatus.DueSoon,
  },
  [ProgressStatus.OnTrack]: {
    id: ProgressStatus.OnTrack,
    label: 'On track',
    status: ProgressStatus.OnTrack,
  },
  [ProgressStatus.Overdue]: {
    id: ProgressStatus.Overdue,
    label: 'Overdue',
    status: ProgressStatus.Overdue,
  },
};

const statusListItems = Object.values(statusListItemMap);

function toOption(item: StatusListItem): Option {
  return { value: item.status, label: item.label };
}

function optionToValue(option: Option): ProgressStatus {
  return option.value;
}

export const ChecklistStatusMultiselect: React.FunctionComponent<
  React.PropsWithChildren<ChecklistStatusMultiselectProps>
> = props => {
  const [value, setValue] = React.useState<Option[]>(() =>
    props.statuses.map(status => toOption(statusListItemMap[status])),
  );
  const options = React.useMemo(
    () => statusListItems.filter(item => props.extractStatuses?.includes(item.status) ?? true).map(toOption),
    [props.extractStatuses],
  );

  const onDoneRef = useFunctionRef(props.onDone);
  const onDone = React.useCallback(
    (options: Option | Option[]) => {
      onDoneRef.current(BlvdSelectHelpers.isOptionsType<Option>(options) ? options.map(optionToValue) : []);
    },
    [onDoneRef],
  );

  return (
    <Box
      id={props.id}
      w="100"
      maxW={{
        md: '69',
        base: 'full',
      }}
    >
      <BlvdSelect
        options={options}
        value={value}
        onMenuClose={() => props.onDone(value.map(optionToValue))}
        onDone={onDone}
        onChange={items => {
          if (BlvdSelectHelpers.isOptionsType<Option>(items)) {
            setValue(items as Option[]);
          } else {
            setValue([]);
          }
        }}
        components={COMPONENTS}
        isMulti
        menuControls
        fixedSize
      />
    </Box>
  );
};

const TAG_COLORS: { [key in ProgressStatus]: TagProps['colorScheme'] } = {
  [ProgressStatus.Archived]: 'purple',
  [ProgressStatus.Completed]: 'green',
  [ProgressStatus.DueSoon]: 'yellow',
  [ProgressStatus.OnTrack]: 'blue',
  [ProgressStatus.Overdue]: 'red',
};

const COMPONENTS: SelectComponentsConfig<Option, true, GroupBase<Option>> = {
  Option: ({ children, ...props }) => {
    const { value } = props.data as Option;
    return (
      <components.Option {...props}>
        <HStack spacing="2">
          {/* Disable default pointer events to prevent menu closing  */}
          <Checkbox isChecked={props.isSelected} size="lg" pointerEvents="none" />
          <Tag colorScheme={TAG_COLORS[value]} variant="solid">
            <TagLabel>{children}</TagLabel>
          </Tag>
        </HStack>
      </components.Option>
    );
  },
  MultiValue: ({ children, ...props }) => {
    const { value } = props.data as Option;
    return (
      <Tag
        {...props.innerProps}
        colorScheme={TAG_COLORS[value]}
        variant="solid"
        _notFirst={{ ml: 1 }}
        flexShrink={0}
        maxW="none"
      >
        <TagLabel>{children}</TagLabel>
        {/* @ts-expect-error -- conflict between react-select expecting a div element an the chakra component being a button */}
        <TagCloseButton
          {...props.removeProps}
          onClick={e => {
            // @ts-expect-error -- conflict between react-select expecting a div element an the chakra component being a button
            props.removeProps.onClick?.(e);
            const { selectProps } = props;
            if (BlvdSelectHelpers.isOptionsType<Option>(selectProps.value)) {
              const filteredStatuses = selectProps.value.filter(item => item.value !== value);
              selectProps.onDone?.(filteredStatuses);
            }
          }}
        />
      </Tag>
    );
  },
};
