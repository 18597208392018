import classNames from 'classnames';
import React from 'react';
import styles from './FeatureItem.module.scss';

interface FeatureItemProps {
  checked: boolean;
  name: string;
}

export const FeatureItem: React.FunctionComponent<React.PropsWithChildren<FeatureItemProps>> = ({ checked, name }) => {
  const iconClass = classNames({
    'fa-check': checked,
    'fa-times': !checked,
    'far': true,
    [styles.check]: checked,
    [styles.cross]: !checked,
  });

  return (
    <span className={styles.featureItem}>
      <i className={iconClass} />
      <span className={styles.label}>{name}</span>
    </span>
  );
};
