import * as React from 'react';
import { Plan, PlanLevel } from '@process-street/subgrade/billing';
import { Organization } from '@process-street/subgrade/core';
import { Box, Button, Flex, Spacer, Text } from 'components/design/next';
import { LimitsInfo } from 'pages/organizations/manage/billing/components/plan-details/LimitsInfo';
import { LevelToPlanInfoMap, PlanActionType, PlanSubscriptionDetails } from 'pages/organizations/manage/billing/models';
import { useInjector } from 'components/injection-provider';
import { useRoleNames } from 'hooks/use-role-names';

export type PlanDetailsProps = {
  onAction: (action: PlanActionType) => void;
  organization: Organization;
  plan: Plan;
  newPlanSelected: boolean;
  planSubscriptionDetails: PlanSubscriptionDetails;
};

const getPlanName = (level: PlanLevel) => {
  const planInfo = LevelToPlanInfoMap[level];

  if (planInfo) {
    return planInfo.name;
  } else {
    return level;
  }
};

export const PlanDetails: React.FC<React.PropsWithChildren<PlanDetailsProps>> = ({
  organization,
  plan,
  planSubscriptionDetails,
  onAction,
  newPlanSelected,
}) => {
  const { BillingTabService } = useInjector('BillingTabService');
  const roleNames = useRoleNames();

  const { subscriptionState, limits } = planSubscriptionDetails;

  const subscriptionStatus = BillingTabService.getSubscriptionStatus(organization.subscription, subscriptionState);

  const planName = getPlanName(plan.level);
  const action = BillingTabService.getPlanAction(plan, organization, subscriptionState, newPlanSelected);
  const actionHandler = () => onAction(action.action);

  return (
    <Box>
      <Text variant="2" fontWeight="bold" color="gray.700">
        {planName}
      </Text>
      <Text color="gray.600" mt={1}>
        {subscriptionStatus}
      </Text>
      <Flex flexWrap="wrap" mt={6}>
        <LimitsInfo title={roleNames.FullMember.plural} limitInfo={limits.fullMembers} />
        <LimitsInfo title="workflows" limitInfo={limits.activeTemplates} />
        <LimitsInfo title="workflow runs" limitInfo={limits.activeChecklists} />
        <Spacer />
        <Button variant={newPlanSelected ? 'tertiary' : 'primary'} onClick={actionHandler}>
          {action.actionText}
        </Button>
      </Flex>
    </Box>
  );
};
