import { getPointAfter, insertNode, insertText, queryNode, setSelection } from '@udecode/slate';
import { getBlockAbove } from '@udecode/slate-utils';
import isHotkey from 'is-hotkey';
import { SendRichEmailKeyboardHandler } from '../../send-rich-email-plate-types';
import { SoftBreakPlugin } from './types';

export const onKeyDownSoftBreak: SendRichEmailKeyboardHandler<SoftBreakPlugin> =
  (editor, { options: { rules = [] } }) =>
  event => {
    const entry = getBlockAbove(editor);
    if (!entry) return;

    rules.forEach(({ hotkey, query }) => {
      if (isHotkey(hotkey, event as any) && queryNode(entry, query)) {
        event.preventDefault();
        event.stopPropagation();

        insertNode(editor, {
          type: 'break',
          children: [{ text: '' }],
        });
        insertText(editor, '\n');
        // the selection is now on the void BR text child
        // find this path to get the range _after_ that
        const location = editor.selection && getPointAfter(editor, editor.selection);
        if (location) {
          setSelection(editor, { anchor: location, focus: location });
        }
      }
    });
  };
