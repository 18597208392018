import * as React from 'react';
import { MultiSelectFieldValue } from '@process-street/subgrade/process';
import { createUsableContext } from '@process-street/subgrade/util';
import { SubtasksFormFieldMachine } from './subtasks-form-field-machine';
import { useActorRef } from '@xstate5/react';
import { Muid } from '@process-street/subgrade/core';
import isEqual from 'lodash/isEqual';
import { useDeepCompareEffect } from 'react-use';

type Value = SubtasksFormFieldMachine.ActorRef;

export const [useSubtasksFormFieldContext, SubtasksFormFieldContext] = createUsableContext<Value>({
  hookName: 'useSubtasksFormFieldContext',
  providerName: 'SubtasksFormFieldProvider',
});

export const SubtasksFormFieldProvider = ({
  items,
  headerId,
  onSave,
  children,
}: React.PropsWithChildren<{
  items: MultiSelectFieldValue[];
  headerId?: Muid;
  onSave: (items: MultiSelectFieldValue[]) => void;
}>) => {
  const machine = React.useRef(
    SubtasksFormFieldMachine.create({ items, headerId }).provide({
      actions: {
        persist: ({ context }) => onSave(context.items),
      },
    }),
  ).current;
  const actorRef = useActorRef(machine);
  const api = SubtasksFormFieldMachine.Hooks.useApi(actorRef);

  useDeepCompareEffect(
    function syncItems() {
      if (!isEqual(actorRef.getSnapshot().context.items, items)) {
        api.setItems({ items });
      }
    },
    [actorRef, api, items],
  );

  return <SubtasksFormFieldContext.Provider value={actorRef}>{children}</SubtasksFormFieldContext.Provider>;
};
