import { RuleConstants } from '@process-street/subgrade/conditional-logic/rule-constants';
import { trace } from 'components/trace';
import { OperandTypeResolver } from './operand-type-resolver';
import { SimpleFieldValue } from '@process-street/subgrade/process';
import { ConditionEvaluator } from '@process-street/subgrade/conditional-logic';

const logger = trace({ name: 'StartsWithCondition' });

export const StartsWithCondition: ConditionEvaluator = {
  evaluate: (formFieldValue, formFieldWidget, operand) => {
    if (!formFieldValue || !formFieldWidget || !operand) return false;

    const operandType = OperandTypeResolver.resolve(formFieldWidget);
    const fieldValue = (formFieldValue?.fieldValue as SimpleFieldValue)?.value;

    let evaluatedAsTrue = false;

    switch (operandType) {
      case RuleConstants.OperandType.STRING:
        evaluatedAsTrue = Boolean(fieldValue?.toString().startsWith(operand.toString()));
        break;
      default:
        logger.error('unsupported operand type: %s', operandType);
    }

    return evaluatedAsTrue;
  },
};
