import angular from 'angular';
import { PopBoxConstants } from './pop-box-constants';
import './pop-box.component.html';
import './pop-box.scss';

angular
  .module('frontStreetApp.directives')
  .directive('psPopBox', ($compile, $q, $templateRequest, $timeout, $window) => {
    const PADDING = 5;
    const win = angular.element($window);
    const doc = angular.element($window.document);

    function register(element, events, handler) {
      element.on(events, handler);

      return function () {
        element.off(events, handler);
      };
    }

    function position(box, element) {
      // Reset top and bottom so that the calculations can be done properly
      box.css({ bottom: '', top: '' });

      const boxWidth = box.outerWidth();
      const boxHeight = box.outerHeight();

      const windowWidth = win.width();
      const windowHeight = win.height();

      const elementOffset = element.offset();
      const elementHeight = element.outerHeight();

      if (elementOffset.left + boxWidth + PADDING >= windowWidth) {
        elementOffset.left -= elementOffset.left + boxWidth + PADDING - windowWidth;
      }

      elementOffset.top += elementHeight + PADDING - win.scrollTop();
      if (elementOffset.top + boxHeight + PADDING >= windowHeight) {
        elementOffset.top -= elementOffset.top + boxHeight + PADDING - windowHeight;
      }

      box.css(elementOffset);
    }

    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        title: '<popBoxTitle',
        templateUrl: '<popBoxTemplateUrl',
        class: '@popBoxClass',
        visible: '<popBoxVisible',
        disabled: '<popBoxDisabled',
        onLoad: '&popBoxOnLoad',
        onShow: '&popBoxOnShow',
        onHide: '&popBoxOnHide',
      },
      controller() {},
      controllerAs: 'ctrl',
      link(scope, element, __attributes, ctrl) {
        const deregisters = [];

        // Keep track internally of whether or not the box is open so we don't "double" open it
        let visible = false;

        function hide(box, hideMethod) {
          if (!visible) {
            return;
          }

          visible = false;

          box.detach();

          deregisters.forEach(deregister => {
            deregister();
          });

          $timeout(() => {
            ctrl.onHide({ hideMethod });
          });
        }

        function show(box) {
          if (visible) {
            return;
          }

          visible = true;

          angular.element('body').append(box);

          // Position box, and listen for window resize events

          position(box, element);
          deregisters.push(register(win, 'resize', position.bind(null, box, element)));

          // Listen for clicks that are not on the box

          deregisters.push(
            register(doc, 'click', event => {
              if (!box[0].contains(event.target) && !element[0].contains(event.target)) {
                hide(box, PopBoxConstants.HideMethod.OUTSIDE_CLICK);
              }
            }),
          );

          // Listen for escape key

          deregisters.push(
            register(doc, 'keydown', event => {
              if (event.which === 27) {
                hide(box, PopBoxConstants.HideMethod.ESCAPE_KEY_PRESS);
              }
            }),
          );

          $timeout(() => {
            ctrl.onShow({});
          });
        }

        const popBoxTemplateUrl = '/directives/pop-box/pop-box.component.html';
        const popBoxTemplateRequest = $templateRequest(popBoxTemplateUrl);

        const contentTemplateRequest = $templateRequest(ctrl.templateUrl);

        $q.all({
          popBoxTemplate: popBoxTemplateRequest,
          contentTemplate: contentTemplateRequest,
        }).then(response => {
          const popBox = angular.element(response.popBoxTemplate);
          $compile(popBox)(scope);

          popBox.addClass(ctrl.class);
          popBox.find('.pop-box-header-close-btn').on('click', () => {
            hide(popBox, PopBoxConstants.HideMethod.CLOSE_BUTTON_CLICK);
          });

          const content = angular.element(response.contentTemplate);
          $compile(content)(scope);

          popBox.find('.pop-box-content').append(content);

          scope.$watch(
            () => ctrl.visible,
            (newVisible, oldVisible) => {
              if (newVisible !== oldVisible) {
                if (newVisible) {
                  show(popBox);
                } else {
                  hide(popBox);
                }
              }
            },
          );

          scope.$on('$destroy', () => {
            hide(popBox);
            popBox.remove();
          });

          element.on('click', () => {
            if (!ctrl.disabled) {
              if (popBox.is(':visible')) {
                hide(popBox);
              } else {
                show(popBox);
              }
            }
          });

          ctrl.onLoad({});
        });
      },
    };
  });
