import { NativeAutomation, TaskTemplate } from '@process-street/subgrade/process';
import { useGetAllTasksByChecklistRevisionIdQuery } from 'features/task/query-builder';
import { useStateParam } from 'hooks/use-state-param';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { useInjector } from 'components/injection-provider';
import { TaskListEvent } from 'directives/task-list/task-list-event';
import React from 'react';

export function useUpdateTaskStatus({
  status,
  taskTemplate,
}: {
  status?: NativeAutomation.EventStatus;
  taskTemplate: TaskTemplate;
}) {
  const checklistId = useStateParam({ key: 'id' });
  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({ checklistId });
  const taskQuery = useGetAllTasksByChecklistRevisionIdQuery(
    { checklistRevisionId: checklistRevisionQuery.data?.id },
    {
      select: tasks => tasks.find(task => task.taskTemplate.id === taskTemplate.id),
    },
  );
  const { $rootScope } = useInjector('$rootScope');
  React.useEffect(() => {
    if (status === 'AutomationComplete' && taskQuery.data) {
      $rootScope.$broadcast(TaskListEvent.AI_TASK_COMPLETED, taskTemplate);
    }
  }, [$rootScope, status, taskQuery.data, taskTemplate]);
}
