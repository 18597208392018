import { Divider, Grid, GridItem, Link, Text, VStack } from 'components/design/next';
import * as React from 'react';
import { BrandColorField } from '../brand-color-field';
import { LogoUploader } from 'features/organization/manage/settings/components/logo-uploader';

export const BrandCustomization = () => {
  return (
    <VStack spacing="6" alignItems="flex-start" w="full">
      <Text color="gray.500" variant="-2u">
        Brand Customization
      </Text>
      <Divider mt="4" />

      <Text color="gray.700">
        Customize your account with your logo, brand color, subdomain and SMTP.{' '}
        <Link href={DOCS_URL} color="brand.500" isExternal>
          Learn more »
        </Link>
      </Text>

      <Grid templateColumns={{ base: '1fr', md: '220px 1fr' }}>
        <GridItem display="flex" alignItems="center">
          <Text color="gray.600" as="label">
            Logo
          </Text>
        </GridItem>
        <GridItem>
          <LogoUploader />
        </GridItem>
      </Grid>
      <BrandColorField />
    </VStack>
  );
};

const DOCS_URL = 'https://www.process.st/help/docs/custom-branding/';
