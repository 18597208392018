import * as React from 'react';
import {
  Box,
  IconButton,
  Icon,
  Menu,
  MenuGroup,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Divider,
  useDisclosure,
  useBoolean,
  useBreakpointValue,
  PlacementWithLogical,
  Badge,
} from 'components/design/next';
import {
  DEFAULT_AI_NAME,
  DEFAULT_APPROVAL_NAME,
  DEFAULT_HEADING_NAME,
  TaskTemplate,
  TaskTemplateTaskType,
  TemplateRevision,
} from '@process-street/subgrade/process';
import { TaskTemplateListMenuEvent } from 'directives/task-template-list/task-template-list-menu-event';
import { UpgradeModal as ApprovalsUpgradeModal } from 'components/approvals/components/UpgradeModal';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { isAdmin } from '@process-street/subgrade/core';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { canAccess, Feature } from 'services/features/features';
import { TriggerMenuList } from 'features/automations/components/workflow-automations/components/template/trigger-menu-list';
import { WorkflowAutomationsProvider } from 'features/automations/components/workflow-automations/components/common/context';
import { SolutionTypeTag } from '@process-street/subgrade/automation';
import { match } from 'ts-pattern';
import { EventEmitterService } from '@process-street/subgrade/util/event-emitter-service';
import { useNavigate } from 'app/adapters/navigation';

export interface CreateTaskMenuProps {
  templateRevision: TemplateRevision;
  activeTaskTemplate: TaskTemplate;
}

export const CreateTaskMenu: React.FC<React.PropsWithChildren<CreateTaskMenuProps>> = ({
  templateRevision,
  activeTaskTemplate,
}) => {
  const createTask = () => {
    EventEmitterService.broadcast(TaskTemplateListMenuEvent.CREATE_REQUEST_FROM_MENU, activeTaskTemplate);
  };

  const createAiTask = () => {
    EventEmitterService.broadcast(TaskTemplateListMenuEvent.CREATE_REQUEST_FROM_MENU, activeTaskTemplate, {
      name: DEFAULT_AI_NAME,
      taskType: TaskTemplateTaskType.AI,
    });
  };

  const createHeadingTask = () => {
    EventEmitterService.broadcast(TaskTemplateListMenuEvent.CREATE_REQUEST_FROM_MENU, activeTaskTemplate, {
      name: DEFAULT_HEADING_NAME,
    });
  };

  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const user = useSelector(SessionSelector.getCurrentUser);
  const planId = selectedOrganization?.subscription.plan.id;
  const approvalsFeatureIsAvailable = user && planId && canAccess(Feature.APPROVALS, planId);
  const currentOrgMembership = useSelector(OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId);
  const currentUserIsAdmin = currentOrgMembership ? isAdmin(currentOrgMembership.role) : false;
  const approvalsUpgradeModalDisclosure = useDisclosure();
  const navigate = useNavigate();
  const onUpgradeClick = () => {
    navigate('organizationManage.tab', { replace: true, state: { tab: 'billing' } });
  };
  const createApprovalTask = () => {
    if (!approvalsFeatureIsAvailable) {
      approvalsUpgradeModalDisclosure.onOpen();
    }

    EventEmitterService.broadcast(TaskTemplateListMenuEvent.CREATE_REQUEST_FROM_MENU, activeTaskTemplate, {
      taskType: TaskTemplateTaskType.Approval,
      name: DEFAULT_APPROVAL_NAME,
    });
  };

  // https://mtm.dev/sticky-stuck-styles
  const [stuck, setStuck] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const container = ref.current;
    if (!container) return;
    const observer = new IntersectionObserver(([e]) => setStuck(e.intersectionRatio < 1), { threshold: [1] });
    observer.observe(container);
    return () => observer.unobserve(container);
  }, []);

  const [automationsMenuIsOpen, automationsMenuState] = useBoolean();
  const isXs = useBreakpointValue({ base: true, sm: false }) ?? true;
  const pattern = { isXs, automationsMenuIsOpen };
  const menuPlacement = match<typeof pattern, PlacementWithLogical | undefined>(pattern)
    .with({ isXs: true }, { automationsMenuIsOpen: false }, () => 'top')
    .otherwise(() => 'auto');

  return (
    <Flex
      ref={ref}
      w="full"
      alignItems="center"
      // flip the order so the height of the divider still keeps the balance in the tree
      flexDirection={stuck ? 'column-reverse' : 'column'}
      mt="-5"
      mb="7"
      position="sticky"
      zIndex="1"
      // this was measured specifically to be 14px
      bottom="-14px"
    >
      <Box height={6}>
        <Divider orientation="vertical" borderStyle="solid" borderColor={stuck ? 'transparent' : 'gray.300'} />
      </Box>
      <Menu onClose={automationsMenuState.off} placement={menuPlacement}>
        <MenuButton
          as={IconButton}
          icon={<Icon icon="plus" size="4" variant="far" />}
          aria-label="Create task menu"
          colorScheme="brand"
          variant="outline"
          bg="white"
          size="sm"
          {...(stuck
            ? {
                w: 'full',
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }
            : {})}
        />

        {automationsMenuIsOpen ? (
          <WorkflowAutomationsProvider
            {...{
              editable: true,
              solutionTypeTag: SolutionTypeTag.WhenChecklistCompletedThen,
              templateRevision,
            }}
          >
            <TriggerMenuList />
          </WorkflowAutomationsProvider>
        ) : (
          <MenuList>
            <MenuGroup title="Create">
              <MenuItem
                color="gray.600"
                icon={<Icon icon="plus-square" variant="far" size="4" color="gray.500" />}
                onClick={createTask}
              >
                Task
              </MenuItem>

              <MenuItem
                color="gray.600"
                icon={<Icon icon="process-pete" variant="far" size="4" color="gray.500" />}
                onClick={createAiTask}
                position="relative"
              >
                AI Task
                <Badge
                  position="absolute"
                  py="1"
                  right="2"
                  top="50%"
                  transform="translateY(-50%)"
                  colorScheme="purple"
                  borderRadius="xl"
                >
                  BETA
                </Badge>
              </MenuItem>

              <MenuItem
                color="gray.600"
                icon={<Icon icon="heading" variant="far" size="4" color="gray.500" />}
                onClick={createHeadingTask}
              >
                Heading
              </MenuItem>

              <MenuItem
                color="gray.600"
                icon={<Icon icon="thumbs-up" variant="far" size="4" color="gray.500" />}
                onClick={createApprovalTask}
              >
                Approval
              </MenuItem>

              <MenuItem
                color="gray.600"
                icon={<Icon icon="bolt" variant="far" size="4" color="gray.500" />}
                onClick={automationsMenuState.on}
                closeOnSelect={false}
              >
                Automations
              </MenuItem>
            </MenuGroup>
          </MenuList>
        )}
      </Menu>

      <ApprovalsUpgradeModal
        admin={currentUserIsAdmin}
        visible={approvalsUpgradeModalDisclosure.isOpen}
        onHide={approvalsUpgradeModalDisclosure.onClose}
        onUpgradeClick={onUpgradeClick}
      />
    </Flex>
  );
};
