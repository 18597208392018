import angular from 'angular';

angular.module('frontStreetApp.directives').directive('psAutofill', () => ({
  priority: 10,
  link(__scope, element) {
    element.on('submit', () => {
      element.find('input[ng-model]').each(function () {
        const input = angular.element(this);
        if (input.attr('type') !== 'checkbox' && input.attr('type') !== 'radio') {
          input.controller('ngModel').$setViewValue(input.val());
        }
      });
    });
  },
}));
