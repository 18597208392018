import { Muid, User } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UserByIdQueryParams = { id: Muid };

export type UserByIdQueryResponse = User;

export const UserByIdQuery = {
  key: ['users'],
  getKey: (params: UserByIdQueryParams): QueryKey => [...UserByIdQuery.key, params],
  queryFn: ({ id }: UserByIdQueryParams) =>
    axiosService
      .getAxios()
      .get<UserByIdQueryResponse>(`/1/users/${id}`)
      .then(res => res.data),
};

export const useUserByIdQuery = <Select = UserByIdQueryResponse>(
  params: UserByIdQueryParams,
  options: UseQueryOptions<UserByIdQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(UserByIdQuery.getKey(params), () => UserByIdQuery.queryFn(params), {
    ...options,
    staleTime: 1000 * 60,
    enabled: Boolean(params.id) && options.enabled !== false,
  });
};
