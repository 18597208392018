import * as React from 'react';
import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  VStack,
} from 'components/design/next';

export type TaskTemplateContextMenuProps = {
  onDuplicate: () => {};
  onAutomate: () => {};
  onConditionalLogic: () => {};
  onAddStop: () => {};
  onRemoveStop: () => {};
  onDelete: () => {};
  onMoveUp: () => {};
  onMoveDown: () => {};
  shouldShowMenuItem: (item: 'duplicate' | 'automate' | 'stop' | 'delete' | 'conditional-logic') => boolean;
  hasConditionalLogic?: boolean;
  isDuplicateButtonDisabled?: boolean;
  hasStop?: boolean;
  isActive?: boolean;
};

export const TaskTemplateContextMenu: React.FC<React.PropsWithChildren<TaskTemplateContextMenuProps>> = ({
  onDuplicate,
  onAutomate,
  onConditionalLogic,
  onAddStop,
  onRemoveStop,
  onMoveUp,
  onMoveDown,
  onDelete,
  shouldShowMenuItem,
  hasConditionalLogic,
  hasStop,
  isActive,
  isDuplicateButtonDisabled,
}) => {
  const boundary = React.useMemo(
    () => document.querySelector<HTMLDivElement>('#steps-wrapper') ?? 'clippingParents',
    [],
  );

  return (
    <Menu boundary={boundary}>
      <MenuButton
        onClick={e => e.stopPropagation()}
        as={IconButton}
        aria-label="Task menu"
        icon={<Icon icon="ellipsis-h" size="4" />}
        variant="ghost"
        _hover={{ bgColor: isActive ? 'transparent' : 'gray.50', border: isActive ? '1px solid white' : undefined }}
        _active={{ bgColor: isActive ? 'transparent' : 'gray.100' }}
        color={isActive ? 'white' : 'gray.500'}
        size="sm"
      />
      <Portal>
        <MenuList
          zIndex="modal"
          sx={{
            '& .chakra-menu__menuitem': {
              py: 3,
            },
            '& .chakra-menu__group__title': {
              my: 0,
            },
          }}
        >
          <MenuGroup title="Task" textAlign="left">
            {shouldShowMenuItem('duplicate') && (
              <MenuItem
                isDisabled={isDuplicateButtonDisabled}
                icon={<Icon icon="copy" size="4" />}
                onClick={onDuplicate}
              >
                Duplicate
              </MenuItem>
            )}

            {shouldShowMenuItem('automate') && (
              <MenuItem onClick={onAutomate} icon={<Icon icon="bolt" size="4" />}>
                Automate
              </MenuItem>
            )}

            {shouldShowMenuItem('conditional-logic') && (
              <MenuItem
                color={hasConditionalLogic ? 'purple.500' : undefined}
                onClick={onConditionalLogic}
                icon={<Icon icon="shuffle" size="4" />}
              >
                Conditional Logic
              </MenuItem>
            )}

            {!hasStop && shouldShowMenuItem('stop') && (
              <MenuItem onClick={onAddStop} icon={<Icon icon="hand" size="4" />}>
                Add Stop
              </MenuItem>
            )}

            {hasStop && shouldShowMenuItem('stop') && (
              <MenuItem
                onClick={onRemoveStop}
                icon={
                  <VStack position="relative" w="4" h="4">
                    <Icon icon="hand" size="4" />
                    <Box position="absolute" top="-1" left="0">
                      <Icon icon="slash" size="4" />
                    </Box>
                  </VStack>
                }
              >
                Remove Stop
              </MenuItem>
            )}

            <MenuItem
              onClick={onMoveUp}
              display={{ base: 'block', md: 'none' }}
              icon={<Icon icon="arrow-up" size="4" />}
            >
              Move Up
            </MenuItem>

            <MenuItem
              onClick={onMoveDown}
              display={{ base: 'block', md: 'none' }}
              icon={<Icon icon="arrow-down" size="4" />}
            >
              Move Down
            </MenuItem>

            {shouldShowMenuItem('delete') && (
              <MenuItem color="red.500" onClick={onDelete} icon={<Icon icon="trash-can" size="4" />}>
                Delete
              </MenuItem>
            )}

            <MenuItem isDisabled color="gray.600" fontStyle="italic" icon={<Icon icon="grip-vertical" size="4" />}>
              You can drag & drop
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
};
