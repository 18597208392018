import * as React from 'react';
import {
  isTrayAutomationAppName,
  trayAutomationAppIsPremium,
  AutomationAppName,
} from '@process-street/subgrade/automation';
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Text,
  VStack,
  Wrap,
  WrapItem,
  HStack,
} from 'components/design/next';
import { AutomationAppButton } from './automation-app-button';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePlanTrack } from 'services/use-plan-track';
import partition from 'lodash/partition';

export interface AppSelectorProps {
  label: string;
  availableAutomationApps: Set<AutomationAppName>;
}

export const AppSelector: React.FC<React.PropsWithChildren<AppSelectorProps>> = ({
  label,
  availableAutomationApps,
}) => {
  const track = usePlanTrack();
  const [premiumApps, standardApps] = partition(Array.from(availableAutomationApps), aa =>
    isTrayAutomationAppName(aa) ? track && trayAutomationAppIsPremium(aa, track) : false,
  );

  return (
    <Box>
      <VStack as={FormControl} spacing="4" align="stretch" id="app">
        <VStack spacing="1" align="stretch">
          <FormLabel marginBottom="0">
            <Text fontWeight="bold">{label}</Text>
          </FormLabel>
          <FormHelperText fontSize="1">Select the app you would like to use.</FormHelperText>
        </VStack>
        <Box maxW="2xl">
          <Wrap color="gray.500" spacing="6" align="stretch">
            {standardApps.map(aa => (
              <WrapItem key={aa}>
                <AutomationAppButton automationApp={aa} />
              </WrapItem>
            ))}
          </Wrap>
        </Box>
        {premiumApps.length > 0 && (
          <VStack maxW="2xl" spacing="2" align="stretch">
            <HStack>
              <Box as={FontAwesomeIcon} icon={faLock} />
              <Text>Premium integrations</Text>
            </HStack>
            <Wrap color="gray.500" spacing="6" align="stretch">
              {premiumApps.map(aa => (
                <WrapItem key={aa}>
                  <AutomationAppButton automationApp={aa} />
                </WrapItem>
              ))}
            </Wrap>
          </VStack>
        )}
      </VStack>
    </Box>
  );
};
