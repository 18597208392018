import { Button, Flex, Toast } from '@chakra-ui/react';
import { FormMachineUtils } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-utils';
import * as React from 'react';
import { FormResponsePageProviders } from '../../providers';
import { Icon } from 'app/components/design/next';
import { TaskMachineHooks } from 'app/pages/responses/_id/components/task/task-machine-hooks';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';

export const TaskFooter = () => {
  const formResponseActor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
  const taskActorsMap = FormResponseMachineHooks.useTaskActorsMap();
  const currentTaskActor = FormResponseMachineHooks.useCurrentTaskActorRef();
  const task = TaskMachineHooks.useTask(currentTaskActor);
  const isCompletingCurrentTask = FormResponseMachineHooks.useIsCompletingCurrentTask();
  const isUncompletingCurrentTask = FormResponseMachineHooks.useIsUncompletingCurrentTask();
  const isChecklistComplete = FormResponseMachineHooks.useIsComplete();
  const isTaskComplete = TaskMachineHooks.useIsTaskComplete(currentTaskActor);
  const taskIsInvalid = currentTaskActor?.getSnapshot()?.matches('validation.invalid.visible');
  const hasMoreTasks = FormMachineUtils.hasMoreTasks(taskActorsMap, currentTaskActor);

  const isNextButtonVisible = hasMoreTasks && !isTaskComplete && !isChecklistComplete;
  const isSkipButtonVisible = hasMoreTasks && !isChecklistComplete;
  const isFinishButtonVisible = !hasMoreTasks && !isTaskComplete && !isChecklistComplete;
  const isUncompleteButtonVisible = isTaskComplete && !isChecklistComplete;
  const completeButtonText = hasMoreTasks ? 'Complete' : 'Finish';

  const handleComplete = React.useCallback(() => {
    const isInvalid = formResponseActor
      .getSnapshot()
      ?.context.currentTaskActor?.getSnapshot()
      ?.matches('validation.invalid.hidden');

    if (isInvalid) {
      Toast({
        title: `We couldn't complete the step`,
        description: `Oops! Some form fields still need to be completed.`,
        status: 'warning',
        isClosable: true,
      });
    }
    formResponseActor.send({ type: 'NEXT_TASK' });
  }, [formResponseActor]);

  const handleUncomplete = React.useCallback(() => {
    if (!task) return;
    formResponseActor.send({ type: 'UNCOMPLETE_CURRENT_TASK' });
  }, [formResponseActor, task]);

  const handleSkip = React.useCallback(() => {
    formResponseActor.send({ type: 'SKIP_TASK' });
  }, [formResponseActor]);

  return (
    <Flex direction="row" w="full" justifyContent="flex-end" gap={2} pt="6">
      {isSkipButtonVisible && (
        <Button
          variant="ghost"
          fontWeight="500"
          colorScheme="gray"
          onClick={handleSkip}
          _hover={{ bgColor: 'transparent' }}
        >
          Skip
        </Button>
      )}
      {isNextButtonVisible ? (
        <Button
          isLoading={isCompletingCurrentTask}
          loadingText={completeButtonText}
          onClick={handleComplete}
          isDisabled={taskIsInvalid}
          borderWidth="1px"
        >
          Complete
        </Button>
      ) : null}
      {isFinishButtonVisible ? (
        <Button
          isLoading={isCompletingCurrentTask}
          loadingText={completeButtonText}
          onClick={handleComplete}
          isDisabled={taskIsInvalid}
        >
          Finish
        </Button>
      ) : null}
      {isUncompleteButtonVisible && (
        <Button
          variant="outline"
          colorScheme="gray"
          borderWidth="px"
          onClick={handleUncomplete}
          leftIcon={<Icon icon="check" size="3" colorScheme="gray.600" />}
          isLoading={isUncompletingCurrentTask}
          loadingText="Completed"
        >
          Completed
        </Button>
      )}
    </Flex>
  );
};
