import { checklistAssignmentsByChecklistSelector } from 'reducers/checklist-assignment/checklist-assignment.selectors';
import { ChecklistSelector } from 'reducers/checklist/checklist.selectors';
import { referencesNormalizer } from 'reducers/entities/entities.utils';
import { createCachedAction, optimisticAction } from 'reducers/util';
import { combineActions } from 'redux-actions';
import { OptimisticChecklist } from '../optimistic';

export const normalizeChecklist = referencesNormalizer(['organization', 'template', 'completedBy', 'archivedBy']);

export const CHECKLIST_PUT = 'checklist/PUT';
export const CHECKLIST_DELETE = 'checklist/DELETE';

export const CHECKLIST_GET_STATS = 'checklist/GET_STATS';
export const CHECKLIST_GET_TASK_STATS = 'checklist/GET_TASK_STATS';

export const CHECKLIST_UPDATE_SHAREABLE = 'checklist/UPDATE_SHAREABLE';

export const CHECKLIST_UPDATE_DUE_DATE = 'checklist/UPDATE_DUE_DATE';

export const CHECKLIST_SEARCH = 'checklist/SEARCH';

export const CHECKLIST_GET_ALL_BY_ORGANIZATION_ID = 'checklist/GET_ALL_BY_ORGANIZATION_ID';

export const CHECKLIST_GET_BY_ID = 'checklist/GET_BY_ID';
export const CHECKLIST_CREATE = 'checklist/CREATE';
export const CHECKLIST_UPDATE_STATUS = 'checklist/UPDATE_STATUS';
export const CHECKLIST_UPDATE_BY_NAME = 'checklist/UPDATE_BY_NAME';
export const CHECKLIST_UNDELETE = 'checklist/UNDELETE';

export const CHECKLIST_DELETE_BY_ID = 'checklist/DELETE_BY_ID';

export const CHECKLIST_SINGLE_ACTION = combineActions(
  CHECKLIST_CREATE,
  CHECKLIST_GET_BY_ID,
  CHECKLIST_UPDATE_BY_NAME,
  CHECKLIST_UPDATE_STATUS,
  CHECKLIST_UNDELETE,
);

export const CHECKLIST_ARRAY_ACTIONS = combineActions(CHECKLIST_GET_ALL_BY_ORGANIZATION_ID);

export const ChecklistActions = ChecklistApi => {
  'ngInject';

  const getAllByOrganizationId = createCachedAction(
    CHECKLIST_GET_ALL_BY_ORGANIZATION_ID,
    ChecklistApi.getAllByOrganizationId,
  );

  const EMPTY_QUEUE_STATE = null;
  let queuedStatsRequests = EMPTY_QUEUE_STATE;
  const getChecklistStats = checklistId => dispatch => {
    if (queuedStatsRequests !== EMPTY_QUEUE_STATE) {
      queuedStatsRequests.push(checklistId);
    } else {
      queuedStatsRequests = [checklistId];
      setTimeout(() => {
        dispatch(getStats(queuedStatsRequests));
        queuedStatsRequests = EMPTY_QUEUE_STATE;
      }, 1000);
    }
  };

  const getStats = createCachedAction(CHECKLIST_GET_STATS, ChecklistApi.getStats);

  const getTaskStatsById = createCachedAction(CHECKLIST_GET_TASK_STATS, ChecklistApi.getTaskStatsById, checklistId => ({
    checklistId,
  }));

  const getById = createCachedAction(
    CHECKLIST_GET_BY_ID,
    ChecklistApi.getById,
    (checklistId, flushCache) => ({ checklistId, flushCache }),
    (state, checklistId) => ChecklistSelector.getById(checklistId)(state) !== undefined,
    ChecklistSelector.getById,
  );

  const create = createCachedAction(CHECKLIST_CREATE, ChecklistApi.create);

  const updateNameById = optimisticAction(
    CHECKLIST_UPDATE_BY_NAME,
    ChecklistApi.updateNameById,
    (checklistId, newName) => ({ checklistId, newName }),
    OptimisticChecklist.updateNameById,
  );

  const updateDueDateById = optimisticAction(
    CHECKLIST_UPDATE_DUE_DATE,
    ChecklistApi.updateDueDateById,
    (checklistId, dueDate) => ({ checklistId, dueDate }),
    OptimisticChecklist.updateDueDateById,
  );

  const updateStatus = createCachedAction(
    CHECKLIST_UPDATE_STATUS,
    ChecklistApi.updateStatus,
    (checklistId, status) => ({ checklistId, status }),
  );

  const updateShareable = createCachedAction(
    CHECKLIST_UPDATE_SHAREABLE,
    ChecklistApi.updateShareable,
    (checklistId, shared) => ({ checklistId, shared }),
  );

  const doDeleteById = createCachedAction(
    CHECKLIST_DELETE_BY_ID,
    ChecklistApi.deleteById,
    // TODO element added to support ChecklistPermit cleanup, remove when ChecklistPermit under redux
    (checklistId, checklist, assignments) => ({
      checklistId,
      checklist,
      element: checklist,
      assignments,
    }),
  );

  const deleteById = checklistId => (dispatch, getState) => {
    const state = getState();

    const checklist = ChecklistSelector.getById(checklistId)(state);
    const assignments = checklistAssignmentsByChecklistSelector(checklistId)(state);

    return dispatch(doDeleteById(checklistId, checklist, assignments));
  };

  const undelete = createCachedAction(CHECKLIST_UNDELETE, ChecklistApi.undelete);

  const search = createCachedAction(CHECKLIST_SEARCH, ChecklistApi.search, (criteria, limit, flushCache) => ({
    criteria,
    limit,
    flushCache,
  }));

  return {
    create,
    deleteById,
    getAllByOrganizationId,
    getById,
    getChecklistStats,
    getStats,
    getTaskStatsById,
    search,
    undelete,
    updateDueDateById,
    updateNameById,
    updateShareable,
    updateStatus,
  };
};
