import { Button, HStack } from 'components/design/next';
import { Icon } from 'components/design/next';
import * as React from 'react';
import { CaptionProps, useDayPicker, useNavigation } from 'react-day-picker';
import { useDatePickerContext } from '../date-picker/context';
import { DateFormat, dayjs } from '@process-street/subgrade/util';

export const DayPickerCaption: React.FC<React.PropsWithChildren<{ size: 'sm' | 'md' } & CaptionProps>> = ({
  size,
  ...props
}) => {
  const { setMode } = useDatePickerContext();
  const { onMonthChange } = useDayPicker();
  const { nextMonth, previousMonth, goToMonth } = useNavigation();
  const iconSize = size === 'sm' ? '3' : '4';

  const handlePreviousClick = () => {
    if (!previousMonth) return;

    goToMonth(previousMonth);
    onMonthChange?.(previousMonth);
  };

  const handleNextClick = () => {
    if (!nextMonth) return;

    goToMonth(nextMonth);
    onMonthChange?.(nextMonth);
  };

  return (
    <HStack w="full" spacing="0" justifyContent="space-between">
      <Button
        aria-label="Previous Month"
        onClick={handlePreviousClick}
        p="3"
        w="10"
        h="10"
        variant="ghost"
        borderRadius="full"
        ml={2.5}
      >
        <Icon icon="chevron-left" size={iconSize} />
      </Button>

      <Button onClick={() => setMode('month')} variant="ghost" fontWeight="semibold" fontSize={size}>
        {dayjs(props.displayMonth).format(`${DateFormat.Month} ${DateFormat.Year}`)}
      </Button>

      <Button
        aria-label="Next Month"
        onClick={handleNextClick}
        p="3"
        w="10"
        h="10"
        variant="ghost"
        borderRadius="full"
        mr={2.5}
      >
        <Icon icon="chevron-right" size={iconSize} />
      </Button>
    </HStack>
  );
};
