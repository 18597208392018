import * as React from 'react';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { Checkbox } from 'components/design/next';
import { Checklist, ChecklistStatus, TaskStatus } from '@process-street/subgrade/process';
import { useCompleteTaskAndOpenNext } from '../hooks/use-complete-task-and-open-next';

type OneOffTaskCompleteCheckProps = {
  task: OneOffTask;
  checklist: Checklist;
};

export const OneOffTaskCompleteCheck: React.FC<React.PropsWithChildren<OneOffTaskCompleteCheckProps>> = ({
  task,
  checklist,
}) => {
  const updateTaskMutation = useCompleteTaskAndOpenNext(task);
  const handleToggleCheck = () => {
    const newStatus = task.status === TaskStatus.Completed ? TaskStatus.NotCompleted : TaskStatus.Completed;
    updateTaskMutation.mutate({ taskId: task.id, status: newStatus });
  };
  return (
    <Checkbox
      aria-label={`${task.name} checkbox`}
      isChecked={task.status === TaskStatus.Completed}
      onChange={handleToggleCheck}
      isDisabled={checklist.status !== ChecklistStatus.Active}
      size="lg"
      sx={{
        '.chakra-checkbox__control': {
          borderWidth: '1px',
          borderColor: 'gray.400',
          borderStyle: 'solid',
          borderRadius: '4px',
        },
      }}
    />
  );
};
