import {} from '@process-street/subgrade/core';
import { TemplateRevision } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateHasProcessStreetBrandingMutation {
  export type Params = {
    templateRevisionId: TemplateRevision['id'];
    hasProcessStreetBranding: boolean;
  };

  export type Response = TemplateRevision;

  export const key = ['put', 'template-revisions', 'process-street-branding'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/template-revisions/${params.templateRevisionId}/process-street-branding`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
