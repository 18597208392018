import { AiTaskTemplate, NativeAutomation } from '@process-street/subgrade/process';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import produce from 'immer';
import { QueryClient } from 'react-query';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { match } from 'ts-pattern';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { assign, createMachine } from 'xstate';
import { UpdateNativeAutomationActionsMutation } from 'features/native-automations/query-builder';
import { makeErrorLoggerAction } from 'app/utils/machines';

interface Context {
  leftOperand?: string;
  rightOperand?: string;
  operator?: NativeAutomation.Operator;
  outputWidgetId?: string;
}

type Event =
  | { type: 'LEFT_OPERAND_CHANGE'; value: string }
  | { type: 'RIGHT_OPERAND_CHANGE'; value: string }
  | { type: 'OPERATOR_CHANGE'; value: NativeAutomation.Operator }
  | { type: 'OUTPUT_WIDGET_ID_CHANGE'; value: string };

interface MakeMathAiTaskFormMachineArgs {
  queryClient: QueryClient;
  aiTaskTemplate: AiTaskTemplate;
}

const leftOperandKey = NativeAutomation.MATH_LEFT_OPERAND_MAPPING_KEY;

const outputKey = NativeAutomation.MATH_MAPPING_KEY;

const rightOperandKey = NativeAutomation.MATH_RIGHT_OPERAND_MAPPING_KEY;

const id = 'math-ai-task-form-machine';

export const makeMathAiTaskFormMachine = ({ queryClient, aiTaskTemplate }: MakeMathAiTaskFormMachineArgs) => {
  const initialContext = getContextFromActions(aiTaskTemplate.nativeAutomation.actions);

  return createMachine(
    {
      id,
      predictableActionArguments: false,
      schema: {
        context: {} as Context,
        events: {} as Event,
      },
      tsTypes: {} as import('./math-ai-task-form-machine.typegen').Typegen0,
      type: 'parallel',
      context: initialContext,
      states: {
        inputs: {
          initial: 'gatherInputs',
          on: {
            LEFT_OPERAND_CHANGE: { actions: ['assignLeftOperand'] },
            RIGHT_OPERAND_CHANGE: { actions: ['assignRightOperand'] },
            OPERATOR_CHANGE: { actions: ['assignOperator'] },
            OUTPUT_WIDGET_ID_CHANGE: { actions: ['assignOutputWidgetId'] },
          },
          states: {
            gatherInputs: {
              always: [
                { cond: 'noLeftOperand', target: 'getLeftOperand' },
                { cond: 'noOperator', target: 'getOperator' },
                { cond: 'noRightOperand', target: 'getRightOperand' },
                { cond: 'noOutputWidgetId', target: 'getOutputWidgetId' },
                { target: 'editing' },
              ],
            },
            getLeftOperand: {
              after: { FOCUS_DELAY: { actions: 'focusLeftOperand' } },
              on: { LEFT_OPERAND_CHANGE: { actions: ['assignLeftOperand'], target: 'gatherInputs' } },
            },
            getOperator: {
              after: { FOCUS_DELAY: { actions: 'focusOperator' } },
              on: { OPERATOR_CHANGE: { actions: ['assignOperator'], target: 'gatherInputs' } },
            },
            getRightOperand: {
              after: { FOCUS_DELAY: { actions: 'focusRightOperand' } },
              on: { RIGHT_OPERAND_CHANGE: { actions: ['assignRightOperand'], target: 'gatherInputs' } },
            },
            getOutputWidgetId: {
              after: { FOCUS_DELAY: { actions: 'focusOutputWidgetId' } },
              on: { OUTPUT_WIDGET_ID_CHANGE: { actions: ['assignOutputWidgetId'], target: 'gatherInputs' } },
            },
            editing: {
              entry: ['markAsDirty'],
            },
          },
        },

        mutation: {
          initial: 'idle',
          on: {
            LEFT_OPERAND_CHANGE: '.mutating',
            RIGHT_OPERAND_CHANGE: '.mutating',
            OPERATOR_CHANGE: '.mutating',
            OUTPUT_WIDGET_ID_CHANGE: '.mutating',
          },
          states: {
            idle: {},
            mutating: {
              entry: ['markAsDirty'],
              invoke: {
                id: 'updateConfigMutation',
                src: 'updateConfigMutation',
                onDone: 'idle',
                onError: {
                  target: 'idle',
                  actions: ['logError', 'showErrorToast'],
                },
              },
            },
          },
        },
      },
    },
    {
      actions: {
        assignLeftOperand: assign({ leftOperand: (_ctx, event) => event.value }),
        assignRightOperand: assign({ rightOperand: (_ctx, event) => event.value }),
        assignOperator: assign({ operator: (_ctx, event) => event.value }),
        assignOutputWidgetId: assign({ outputWidgetId: (_ctx, event) => event.value }),

        showErrorToast: () => {
          ToastServiceImpl.openToast({ title: 'We were unable to save those changes.', status: 'error' });
        },

        // define in component
        focusLeftOperand: () => {},
        focusOperator: () => {},
        focusRightOperand: () => {},
        focusOutputWidgetId: () => {},
        markAsDirty: () => {},
        logError: makeErrorLoggerAction(id),
      },
      guards: {
        noLeftOperand: ctx => typeof ctx.leftOperand !== 'string' || ctx.leftOperand === '',
        noOperator: ctx => typeof ctx.operator !== 'string',
        noRightOperand: ctx => typeof ctx.rightOperand !== 'string' || ctx.rightOperand === '',
        noOutputWidgetId: ctx => typeof ctx.outputWidgetId !== 'string' || ctx.outputWidgetId === '',
      },
      delays: {
        FOCUS_DELAY: 100,
      },
      services: {
        updateConfigMutation: async (ctx, _event) => {
          const { actions } = aiTaskTemplate.nativeAutomation;
          const newActions = actions.map(action => {
            return match(action)
              .with({ actionType: 'UpdateFormFields' }, action => {
                return produce(action, draft => {
                  draft.config.mapping = ctx.outputWidgetId ? { [outputKey]: ctx.outputWidgetId } : {};
                });
              })
              .with({ actionType: 'ExecuteMathFormula' }, action => {
                return produce(action, draft => {
                  draft.config.mapping[leftOperandKey] = ctx.leftOperand ?? '';
                  draft.config.mapping[rightOperandKey] = ctx.rightOperand ?? '';
                  draft.config['operator'] = ctx.operator;
                });
              })
              .otherwise(() => action) as typeof action;
          });

          return makeMutation(queryClient, {
            mutationKey: UpdateNativeAutomationActionsMutation.key,
            mutationFn: UpdateNativeAutomationActionsMutation.mutationFn,
            variables: { nativeAutomationId: aiTaskTemplate.nativeAutomation.id, actions: newActions },
            onSuccess: nativeAutomation => {
              queryClient.setQueryData<GetAllNativeAutomationsQuery.Response>(
                GetAllNativeAutomationsQuery.getKey({
                  templateRevisionId: aiTaskTemplate.taskTemplate.templateRevision.id,
                }),
                GetAllNativeAutomationsQuery.taskTemplateAutomationUpdater(
                  aiTaskTemplate.taskTemplate.id,
                  nativeAutomation,
                ),
              );
            },
          }).execute();
        },
      },
    },
  );
};

const getContextFromActions = (actions: NativeAutomation.Action[]): Context =>
  actions.reduce(
    (acc, action) => {
      match(action)
        .with({ actionType: 'ExecuteMathFormula' }, ({ config }) => {
          acc['leftOperand'] = config.mapping[leftOperandKey] ?? null;
          acc['rightOperand'] = config.mapping[rightOperandKey] ?? null;
          acc['operator'] = config.operator;
        })
        .with({ actionType: 'UpdateFormFields' }, ({ config }) => {
          acc['outputWidgetId'] = config.mapping[outputKey] ?? null;
        })
        .otherwise(() => {});
      return acc;
    },
    {
      leftOperandWidgetId: undefined,
      rightOperandWidgetId: undefined,
      operator: undefined,
      outputWidgetId: undefined,
    } as Context,
  );
