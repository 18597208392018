import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import styles from './DownloadLink.module.scss';

export interface DownloadLinkProps {
  url: string;
}

export const DownloadLink: React.FunctionComponent<React.PropsWithChildren<DownloadLinkProps>> = ({ url }) => {
  return (
    <div className={styles.container}>
      <FontAwesomeIcon className={styles.icon} icon={faArrowToBottom} size="lg" />
      <a className={styles.link} href={url} target="_blank" rel="noopener noreferrer">
        Download
      </a>
    </div>
  );
};
