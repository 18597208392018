import React from 'react';
import {
  Box,
  Icon,
  Link,
  LinkProps,
  List,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
  VStack,
} from 'components/design/next';
import { PermissionsStats } from '../models/permissions-stats';
import { PermissionsDescriptionList } from './permissions-description-list/component';
import { ICON_LABELS, PermissionsStatsUtils } from 'components/permissions/services/permissions-stats.utils';
import { useInjector } from 'components/injection-provider';
import { useDispatch, useSelector } from 'react-redux';
import { TEMPLATE_REVISION_GET_ALL_NEWEST_BY_TEMPLATE_ID } from 'reducers/template-revision/template-revision.actions';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useGetTemplateQuery } from 'features/template/query-builder';

import messageTemplate from 'directives/template-share/modal/template-share-modal.component.html';
import { TemplateRevisionStatus } from '@process-street/subgrade/process';
import {
  GetNewestTemplateRevisionsByTemplateIdQuery,
  useGetNewestTemplateRevisionsByTemplateIdQuery,
} from 'features/template-revisions/query-builder';

export interface LibraryItemPermissionsIndicatorProps {
  permissionsStats: PermissionsStats;
  target?: LinkProps['target'];
  isViewOnlyForm?: boolean;
}

export const LibraryItemPermissionsIndicator: React.FC<
  React.PropsWithChildren<LibraryItemPermissionsIndicatorProps>
> = ({ permissionsStats, target = '_self', isViewOnlyForm = false }) => {
  const { templateId, folderId } = permissionsStats;
  const icons = PermissionsStatsUtils.resolvePermissionsIcons(permissionsStats);
  const disclosure = useDisclosure();

  const currentUser = useSelector(SessionSelector.getCurrentUser);

  const templateQuery = useGetTemplateQuery({ templateId: templateId ?? '' }, { enabled: Boolean(templateId) });

  const { $state, MessageBox } = useInjector('$state', 'MessageBox');
  const dispatch = useDispatch();

  const templateRevisionsQuery = useGetNewestTemplateRevisionsByTemplateIdQuery(
    { templateId: templateId! },
    {
      enabled: false,
      onSuccess: tmplRevs => {
        dispatch({ type: TEMPLATE_REVISION_GET_ALL_NEWEST_BY_TEMPLATE_ID, payload: tmplRevs, meta: { templateId } });
      },
    },
  );

  const showShareTemplate = React.useCallback(() => {
    if (isViewOnlyForm) return;
    const { data: template } = templateQuery;

    templateRevisionsQuery.refetch().then(response => {
      const { data: templateRevisions } = response;

      if (template && templateRevisions) {
        const activeTemplateRevision = GetNewestTemplateRevisionsByTemplateIdQuery.select.where({
          status: TemplateRevisionStatus.Finished,
        })(templateRevisions);

        MessageBox.custom({
          animation: false,
          templateUrl: messageTemplate,
          controller: 'TemplateShareModal',
          size: 'lg',
          options: { template, templateRevision: activeTemplateRevision, user: currentUser },
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- refetch templateRevisionsQuery
  }, [MessageBox, currentUser, templateQuery, isViewOnlyForm]);

  const folderHref = $state.href('folderManage.tab', { id: folderId, tab: 'permissions' });

  const popoverTrigger = React.useMemo(() => {
    const renderedIcons = (
      <>
        {icons.map(icon => (
          <Box as={'span'} mx={1} key={icon}>
            <Icon
              size="4"
              variant="far"
              icon={icon}
              aria-label={ICON_LABELS[icon]}
              color={isViewOnlyForm ? 'gray.200' : 'inherit'}
            />
          </Box>
        ))}
      </>
    );

    const commonProps = {
      'aria-label': 'Permissions information',
      'onMouseEnter': () => disclosure.onOpen(),
      'color': 'currentColor',
      '_hover': { color: 'currentColor' },
    };

    return folderId ? (
      <Link {...commonProps} href={folderHref} target={target}>
        {renderedIcons}
      </Link>
    ) : (
      <Text {...commonProps} as="span" onClick={showShareTemplate} cursor="pointer" role="button">
        {renderedIcons}
      </Text>
    );
  }, [disclosure, folderHref, folderId, icons, isViewOnlyForm, showShareTemplate, target]);

  if (icons.length === 0) {
    return null;
  }

  const shouldShowAssignedToList =
    permissionsStats.allMembersGroupsCount > 0 ||
    permissionsStats.membersCount > 0 ||
    permissionsStats.groupsCount > 0 ||
    permissionsStats.guestsCount > 0 ||
    permissionsStats.templateShared;

  const shouldShowInheritedLList =
    permissionsStats.inheritedAllMembersGroupsCount > 0 ||
    permissionsStats.inheritedMembersCount > 0 ||
    permissionsStats.inheritedGroupsCount > 0 ||
    permissionsStats.inheritedGuestsCount > 0;

  return (
    <Popover trigger="hover" placement="left" isLazy={true} size="auto" closeDelay={0} {...disclosure}>
      <PopoverTrigger>{popoverTrigger}</PopoverTrigger>
      <Portal>
        <PopoverContent w="max-content" maxW="max-content">
          <PopoverArrow />
          <PopoverBody>
            <VStack spacing={3} align="flex-start">
              {shouldShowAssignedToList && (
                <Box>
                  <Text variant="-1" fontWeight="bold" color="gray.700">
                    Accessible to
                  </Text>
                  <List color="gray.600" as="span">
                    <PermissionsDescriptionList permissionsStats={permissionsStats} level={'direct'} />
                  </List>
                </Box>
              )}
              {shouldShowInheritedLList && (
                <Box>
                  <Text variant="-1" fontWeight="bold" color="gray.700">
                    Inherited from folders
                  </Text>
                  <List color="purple.500" as="span">
                    <PermissionsDescriptionList permissionsStats={permissionsStats} level={'inherited'} />
                  </List>
                </Box>
              )}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
