import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import {
  QueryClient,
  QueryObserver,
  QueryObserverOptions,
  QueryObserverResult,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetTemplateQueryParams = {
  templateId?: Template['id'];
};

export type GetTemplateQueryResponse = Template;

export const GetTemplateQuery = {
  key: ['template'],
  getKey: (params: GetTemplateQueryParams): QueryKey => [...GetTemplateQuery.key, params],
  queryFn: (params: GetTemplateQueryParams) =>
    axiosService
      .getAxios()
      .get<GetTemplateQueryResponse>(`/1/templates/${params.templateId}`)
      .then(res => res.data),
  staleTime: 1000 * 10,
};

export const useGetTemplateQuery = <Select = GetTemplateQueryResponse>(
  params: GetTemplateQueryParams,
  options: UseQueryOptions<GetTemplateQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetTemplateQuery.getKey(params), () => GetTemplateQuery.queryFn(params), {
    ...options,
    staleTime: options.staleTime ?? GetTemplateQuery.staleTime,
    enabled: Boolean(params.templateId) && options.enabled !== false,
  });
};

export type GetTemplateQueryObserverResult = QueryObserverResult<GetTemplateQueryResponse, AxiosError>;

export const makeGetTemplateQueryObserver = ({
  queryClient,
  options,
  ...params
}: GetTemplateQueryParams & {
  queryClient: QueryClient;
  options?: QueryObserverOptions<GetTemplateQueryResponse, AxiosError>;
}) => {
  return new QueryObserver<GetTemplateQueryResponse, AxiosError>(queryClient, {
    queryKey: GetTemplateQuery.getKey(params),
    queryFn: () => GetTemplateQuery.queryFn(params),
    enabled: Boolean(params.templateId) && options?.enabled !== false,
    ...options,
  });
};
