import { axiosService } from 'services/axios-service';
import { AxiosResponse } from 'axios';
import { Muid } from '@process-street/subgrade/core';
import { CsvLastRun } from 'components/run-from-csv/model/CsvLastRun';
import { CsvParseResult } from 'components/run-from-csv/model/CsvParseResult';

const getLastRun = (templateId: Muid): Promise<CsvLastRun | undefined> =>
  axiosService
    .getAxios()
    .get(`/1/templates/${templateId}/run-csv/last-run`)
    .then((response: AxiosResponse) => (response.data ? (response.data as CsvLastRun) : undefined))
    .catch(() => undefined);

const uploadCsvFile = (templateId: Muid, file: File): Promise<CsvParseResult> => {
  const formData = new FormData();
  formData.append('file', file);

  return axiosService
    .getAxios()
    .post<CsvParseResult>(`/1/templates/${templateId}/run-csv`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response.data);
};

export const RunFromCsvApi = {
  getLastRun,
  uploadCsvFile,
};
