import * as React from 'react';
import { Icon, IconButton } from 'components/design/next';
import { Widget } from '@process-street/subgrade/process';
import { useOnEditDefaultValueClick } from './use-on-edit-default-value-click';

export const EditDefaultValueButton = ({ widget }: { widget: Widget }) => {
  const onEditClick = useOnEditDefaultValueClick();
  const handleEditClick = () => {
    onEditClick(widget);
  };

  return (
    <IconButton
      variant="ghost"
      colorScheme="gray"
      icon={<Icon icon="gear" variant="far" size="4" color="gray.400" />}
      aria-label="Edit default value"
      onClick={handleEditClick}
      _hover={{ bg: 'gray.200' }}
    />
  );
};
