import { forwardRef, omitThemingProps, ThemingProps, useStyleConfig, HTMLChakraProps } from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import * as React from 'react';
import { Box } from 'components/design/next';

export interface EditorParagraphProps extends HTMLChakraProps<'div'>, ThemingProps {}

export const EditorParagraph = forwardRef<EditorParagraphProps, 'div'>((props, ref) => {
  const { className, ...rest } = omitThemingProps(props);
  const styles = useStyleConfig('EditorParagraph', props);
  const _className = cx('chakra-editor-paragraph', className);

  return <Box as="p" sx={styles} ref={ref} className={_className} {...rest} />;
});
