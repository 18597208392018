import { Template } from '@process-street/subgrade/process';
import * as React from 'react';
import { Box, FormControl, Icon, Switch, Text, Tooltip, useToast } from 'components/design/next';
import { useUpdateShareableLinkMutation } from 'features/template/query-builder/update-shareable-link';
import { useGetCanShareLinks } from 'utils/plans/get-can-share-links';

export type PermissionsSwitchProps = {
  template: Template;
};

export const PermissionsSwitch: React.FC<React.PropsWithChildren<PermissionsSwitchProps>> = ({ template }) => {
  const [sharedRunLink, setSharedRunLink] = React.useState(template.sharedRunLink);
  const toast = useToast();
  const canShareLinks = useGetCanShareLinks();

  const updateSharedRunLink = useUpdateShareableLinkMutation({
    onSuccess: template => {
      setSharedRunLink(template.sharedRunLink);
    },
    onError: () => {
      toast({
        status: 'error',
        title: `We're having problems ${sharedRunLink ? 'disabling' : 'enabling'} the shareable run link`,
        description: 'Please try again in a moment or contact support.',
      });
      setSharedRunLink(template.sharedRunLink);
    },
  });

  const handleAuthSwitch = () => {
    const shared = !sharedRunLink;
    setSharedRunLink(shared);

    updateSharedRunLink.mutateAsync({ templateId: template.id, shared });
  };

  return (
    <FormControl>
      <Tooltip
        isDisabled={canShareLinks}
        label={
          <Text>
            Share links are enabled after a 7-day period on any paid plan. For expedited access, please contact our
            support&nbsp;team.
          </Text>
        }
        hasArrow
      >
        <Box>
          <Switch
            size="lg"
            spacing="2"
            display="flex"
            alignItems="baseline"
            isDisabled={!canShareLinks}
            isChecked={sharedRunLink}
            onChange={handleAuthSwitch}
          >
            <Icon icon="unlock-alt" size="4" variant="far" color="gray.400" mr="2" />
            <Text variant="1" fontWeight="normal" color="gray.500" as="span">
              Anyone with the link can submit this form
            </Text>
            <Tooltip label="No sign in required. Users submit the form anonymously." shouldWrapChildren hasArrow>
              <Icon icon="info-circle" size="4" variant="far" color="gray.400" ml="2" />
            </Tooltip>
          </Switch>
        </Box>
      </Tooltip>
      <Tooltip
        isDisabled={canShareLinks}
        label={
          <Text>
            Share links are enabled after a 7-day period on any paid plan. For expedited access, please contact our
            support&nbsp;team.
          </Text>
        }
        hasArrow
      >
        <Box>
          <Switch
            size="lg"
            spacing="2"
            mt={4}
            display="flex"
            alignItems="baseline"
            isDisabled={!canShareLinks}
            isChecked={!sharedRunLink}
            onChange={handleAuthSwitch}
          >
            <Icon icon="lock" size="4" variant="far" color="gray.400" mr="2" />
            <Text variant="1" fontWeight="normal" color="gray.500" as="span">
              Only organization members can submit this
              <Text>
                form
                <Tooltip label="Requires sign in for users already in your organization." shouldWrapChildren hasArrow>
                  <Icon icon="info-circle" size="4" variant="far" color="gray.400" ml="2" />
                </Tooltip>
              </Text>
            </Text>
          </Switch>
        </Box>
      </Tooltip>
    </FormControl>
  );
};
