import { bindActionCreators } from 'redux';

/**
 * Simple utility, that instead of binding dispatch directly to the controller,
 * binds it to actions variable in that controller.
 */
export const bindActionCreatorsToActions = actions => {
  if (actions === null || actions === undefined) {
    return null;
  } else {
    return dispatch => ({
      actions: bindActionCreators(actions, dispatch),
    });
  }
};
