export const getTabByName = (tabs, name) => tabs && tabs.find(tab => tab.name === name);

export const getTabIndexByName = (tabs, name) => {
  const index = tabs && tabs.findIndex(tab => tab.name === name);
  return index >= 0 ? index : undefined;
};

export const generateTo = currentStateName =>
  currentStateName.endsWith('.tab') ? currentStateName : `${currentStateName}.tab`;

export const generateLocation = currentStateName => currentStateName.endsWith('.tab') || 'replace';

export const initializeTabs = (tabs, $state, $scope, predicate = () => true) => {
  tabs.forEach(tab => {
    tab.select = function () {
      this.selectedOnce = true;

      const to = generateTo($state.current.name);
      const location = generateLocation($state.current.name);
      if (predicate($state)) {
        $state.go(to, { tab: tab.name, index: null }, { location });
      }
    };
  });

  const selectedTab = getTabByName($scope.tabs, $state.params.tab) || tabs[0];
  selectedTab.select();
};
