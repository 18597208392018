import { createUsableContext, noop } from '@process-street/subgrade/util';
import { useBreakpointValue } from 'components/design/next';
import * as React from 'react';
import { match } from 'ts-pattern';
import { sanitizePathParam } from 'features/folder/lib';
import { useUpdateRoute } from './hooks';
import { useFirstMountState } from 'react-use';
import { useStateParam } from 'hooks/use-state-param';

export type LibraryView = 'folder' | 'scheduled' | 'tag';

export type TemplateStatusView = 'active' | 'archived';

export type Context = {
  view: LibraryView;
  templateStatusView: TemplateStatusView;
  setTemplateStatusView: React.Dispatch<React.SetStateAction<TemplateStatusView>>;
  path: string;
  leftNavState: 'closed' | 'open';
  setLeftNavState: React.Dispatch<React.SetStateAction<Context['leftNavState']>>;
};

export const [useLibraryContext, LibraryContext] = createUsableContext<Context>({
  hookName: 'useLibraryContext',
  providerName: 'LibraryProvider',
});

export const makeInitialState = (): Context => ({
  view: 'folder',
  templateStatusView: 'active',
  leftNavState: 'closed',
  path: '',
  setLeftNavState: noop,
  setTemplateStatusView: noop,
});

export const LibraryPageProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  useUpdateRoute();

  const [templateStatusView, setTemplateStatusView] = React.useState<TemplateStatusView>('active');
  const [leftNavState, setLeftNavState] = React.useState<Context['leftNavState']>('open');

  const isXs = useBreakpointValue({ base: true, sm: false });
  const isFirstMount = useFirstMountState();
  React.useEffect(() => {
    if (isXs && !isFirstMount) {
      setLeftNavState('closed');
    }
  }, [isFirstMount, isXs]);

  const viewParam = useStateParam({ key: 'type' });
  const pathParam = useStateParam({ key: 'path' });
  const path = pathParam ? sanitizePathParam(pathParam) : 'home';
  const view = match<string | undefined, Context['view']>(viewParam)
    .with('folder', () => 'folder')
    .with('scheduled', () => 'scheduled')
    .with('tag', () => 'tag')
    .otherwise(() => 'folder');

  const context = React.useMemo<Context>(() => {
    return {
      view,
      templateStatusView,
      path,
      setTemplateStatusView,
      leftNavState,
      setLeftNavState,
    };
  }, [view, templateStatusView, path, leftNavState]);

  return <LibraryContext.Provider value={context}>{children}</LibraryContext.Provider>;
};
