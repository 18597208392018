import { DueDateRuleDefinition, FormFieldWidget, TaskTemplate } from '@process-street/subgrade/process';
import { Center, Icon, Tooltip } from 'components/design/next';
import * as React from 'react';
import { TaskListItemDynamicDueDateIndicatorHelpers } from './task-list-item-dynamic-due-date-indicator.helpers';

export type TaskListItemDynamicDueDateIndicatorProps = {
  ruleDefinition: DueDateRuleDefinition;
  formFieldWidget?: FormFieldWidget;
  taskTemplate?: TaskTemplate;
};

export const TaskListItemDynamicDueDateIndicator = ({
  ruleDefinition,
  taskTemplate,
  formFieldWidget,
}: TaskListItemDynamicDueDateIndicatorProps) => {
  return (
    <Tooltip
      label={TaskListItemDynamicDueDateIndicatorHelpers.getFullLabelByRule(
        ruleDefinition,
        taskTemplate,
        formFieldWidget,
      )}
      textAlign="center"
    >
      <Center w={6} h={8}>
        <Icon aria-label="Dynamic due date" icon="calendar-days" color="purple.500" size="3.5" />
      </Center>
    </Tooltip>
  );
};
