import { IntercomService } from './intercom-service';

export type UpdateTrialBlockPaywallSeenVariablesProps = {
  user_id: string;
  trial_block_paywall_seen_at: number;
  company: {
    id: string;
    trial_block_paywall_seen_at: number;
  };
};

export type UpdateTrialBlockPaywallVariablesProps = {
  company: {
    id: string;
    trial_block_paywall_startup_plan_clicked_at?: number;
    trial_block_paywall_contact_sales_clicked_at?: number;
    trial_block_paywall_see_plan_details_clicked_at?: number;
    trial_block_paywall_contact_admin_clicked_at?: number;
  };
};

const updateIntercomPaywallSeenVariables = (properties: UpdateTrialBlockPaywallSeenVariablesProps) => {
  IntercomService.update(properties);
};

const updateIntercomPaywallClickedVariables = (properties: UpdateTrialBlockPaywallVariablesProps) => {
  IntercomService.update(properties);
};

export const TrialBlockService = {
  updateIntercomPaywallSeenVariables,
  updateIntercomPaywallClickedVariables,
};
