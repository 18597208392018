import { MuidUtils, Organization } from '@process-street/subgrade/core';
import { TaskPermissionRule, TaskPermissionRuleSourceType } from '@process-street/subgrade/permission';
import {
  FieldType,
  FormFieldWidget,
  isFormFieldWidget,
  TaskTemplate,
  TemplateRevision,
} from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { GetTaskTemplateRulesQuery } from 'features/template-revisions/query-builder';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import React from 'react';
import { transformRulesToOptions } from '../transformer';

export function useGetRulesOptions({
  taskTemplates,
  templateRevisionId,
  organizationId,
}: {
  taskTemplates: TaskTemplate[];
  templateRevisionId: TemplateRevision['id'];
  organizationId: Organization['id'];
}) {
  const { TaskPermissionRuleService } = useInjector('TaskPermissionRuleService');

  const rulesQuery = GetTaskTemplateRulesQuery.useQuery({ templateRevisionId });

  // Members and Email widgets for template revision - task template is filtered later by the rules.
  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevisionId, {
    select: widgets =>
      widgets.filter(
        (widget): widget is FormFieldWidget =>
          isFormFieldWidget(widget) && (widget.fieldType === FieldType.Email || widget.fieldType === FieldType.Members),
      ),
  });

  const allTaskPermissionRules = React.useMemo(() => {
    const taskTemplatesGroupIdsSet = new Set(taskTemplates.map(taskTemplate => taskTemplate.group.id));
    if (rulesQuery.data) {
      return rulesQuery.data.filter(rule => taskTemplatesGroupIdsSet.has(rule.targetTaskTemplateGroup.id));
    }
    return [];
  }, [rulesQuery.data, taskTemplates]);

  const taskPermissionRules = React.useMemo(() => {
    if (allTaskPermissionRules) {
      return TaskPermissionRuleService.extractUniqueTypedRules(allTaskPermissionRules);
    }
    return [];
  }, [TaskPermissionRuleService, allTaskPermissionRules]);

  const allPossibleRules = React.useMemo(() => {
    const ret: TaskPermissionRule[] = [
      {
        id: MuidUtils.randomMuid(),
        organization: {
          id: organizationId,
        },
        templateRevision: {
          id: templateRevisionId,
        },
        targetTaskTemplateGroup: {
          id: MuidUtils.randomMuid(), // updated in component, to allow bulk
        },
        sourceType: TaskPermissionRuleSourceType.ChecklistInitiator,
        taskRead: true,
        taskUpdate: true,
      },
    ];

    if (widgetsQuery.data) {
      widgetsQuery.data.forEach(widget => {
        ret.push({
          id: MuidUtils.randomMuid(),
          organization: {
            id: organizationId,
          },
          templateRevision: {
            id: templateRevisionId,
          },
          targetTaskTemplateGroup: {
            id: MuidUtils.randomMuid(), // updated in component, to allow bulk
          },
          sourceType: TaskPermissionRuleSourceType.FormField,
          sourceFormFieldWidgetGroup: {
            id: widget.header.group.id,
          },
          taskRead: true,
          taskUpdate: true,
        });
      });
    }

    return ret;
  }, [organizationId, templateRevisionId, widgetsQuery.data]);

  const assignableRuleOptions = React.useMemo(() => {
    if (widgetsQuery.data) {
      const existingRules = taskPermissionRules;
      const allMatchingPossibleRules = allPossibleRules.filter(rule =>
        TaskPermissionRuleService.isRuleAvailable(existingRules, rule),
      );

      return transformRulesToOptions(allMatchingPossibleRules, widgetsQuery.data);
    }
    return [];
  }, [widgetsQuery.data, taskPermissionRules, allPossibleRules, TaskPermissionRuleService]);

  return React.useMemo(() => {
    const allAssignableTaskPermissionRules = allTaskPermissionRules.concat(allPossibleRules);
    return { assignableRuleOptions, allAssignableTaskPermissionRules, existingRules: taskPermissionRules };
  }, [assignableRuleOptions, allTaskPermissionRules, allPossibleRules, taskPermissionRules]);
}
