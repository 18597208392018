import { Muid, Option } from '@process-street/subgrade/core';
import { Folder, Template, TemplateStatus } from '@process-street/subgrade/process';
import { PremadeTemplateOverview } from '@process-street/subgrade/process/premade-template-model';
import { BaseTemplateSelector } from '@process-street/subgrade/redux/selector/template.selectors';
import { EntityMap } from '@process-street/subgrade/redux/types';
import { getEnv } from 'components/common/env';
import { isPrivateFolder } from 'features/folder/lib';
import memoize from 'lodash/memoize';
import { SessionSelector } from 'reducers/session/session.selectors';
import { createSelector, Selector } from 'reselect';
import { FolderSelector } from '../folder/folder.selectors';
import { ReduxAppState, TemplateLookups } from '../types';

const isSameOrganization = (organizationId: Muid, template: Template): boolean =>
  template && template.organization.id === organizationId;

const isOrphan = (template: Template, folderIds: Muid[]): boolean => !folderIds.find(id => id === template.folder.id);

const isOfRequiredStatus = (template: Template, statuses: TemplateStatus[]): boolean =>
  statuses.indexOf(template.status) >= 0;

const isTemplateInFolder = (template: Template, folderId: Muid): boolean => template.folder.id === folderId;

const getLookups = (state: ReduxAppState): TemplateLookups => state.lookups.templates;

const getAllByFolderId =
  (folderId: Muid) =>
  (state: ReduxAppState): Template[] =>
    Object.values(BaseTemplateSelector.getEntityMap(state)).filter((template: Template) =>
      isTemplateInFolder(template, folderId),
    );

const getLookupForSchedule = (state: ReduxAppState): Readonly<Muid[]> => getLookups(state).forSchedule || [];

const getAllForSchedule = createSelector<ReduxAppState, EntityMap<Template>, Readonly<Muid[]>, Template[]>(
  [BaseTemplateSelector.getEntityMap, getLookupForSchedule],
  (templates: EntityMap<Template>, forLookup: Readonly<Muid[]>) =>
    forLookup.map((id: Muid) => templates[id]).filter((template): template is Template => template !== undefined),
);

/** gets all except predefined */
const getAll = createSelector<ReduxAppState, EntityMap<Template>, Template[]>(
  [BaseTemplateSelector.getEntityMap],
  (templatesMap: EntityMap<Template>) =>
    Object.values(templatesMap).filter(template => template.organization.id !== getEnv().APP_PROCESSPANTS_ORG_ID),
);

const getAllForSelectedOrganization = createSelector(
  [BaseTemplateSelector.getEntityMap, SessionSelector.getSelectedOrganizationId],
  (templatesMap: EntityMap<Template>, selectedOrganizationId: Option<Muid>) =>
    Object.values(templatesMap).filter(template => template.organization.id === selectedOrganizationId),
);

const getAllOrphansByOrganizationIdAndTemplateStatuses =
  (organizationId: Muid, statuses: TemplateStatus[]) =>
  (state: ReduxAppState): Template[] => {
    const folderIds = Object.keys(FolderSelector.getEntityMap(state));
    return Object.values(BaseTemplateSelector.getEntityMap(state)).filter((template: Template) => {
      return (
        isSameOrganization(organizationId, template) &&
        isOrphan(template, folderIds) &&
        isOfRequiredStatus(template, statuses)
      );
    });
  };

export const getAllOrphansAndFolderByFolderAndTemplateStatusesPredicate =
  ({
    folderId,
    organizationId,
    statuses,
    folderIds,
  }: {
    folderId: Muid;
    organizationId: Muid;
    statuses: TemplateStatus[];
    folderIds: Muid[];
  }) =>
  (template: Template) => {
    return (
      isSameOrganization(organizationId, template) &&
      (isOrphan(template, folderIds) || isTemplateInFolder(template, folderId)) &&
      isOfRequiredStatus(template, statuses)
    );
  };
const getAllOrphansAndFolderByFolderAndTemplateStatuses =
  (folder: Folder, statuses: TemplateStatus[]) =>
  (state: ReduxAppState): Template[] => {
    const folderIds = Object.keys(FolderSelector.getEntityMap(state));
    return Object.values(BaseTemplateSelector.getEntityMap(state)).filter(
      getAllOrphansAndFolderByFolderAndTemplateStatusesPredicate({
        folderId: folder.id,
        organizationId: folder.organization.id,
        statuses,
        folderIds,
      }),
    );
  };

export const getAllByFolderAndTemplateStatusesPredicate =
  ({ folderId, organizationId, statuses }: { folderId: Muid; organizationId: Muid; statuses: TemplateStatus[] }) =>
  (template: Template) =>
    isSameOrganization(organizationId, template) &&
    isTemplateInFolder(template, folderId) &&
    isOfRequiredStatus(template, statuses);

const getAllByFolderAndTemplateStatuses =
  (folder: Folder, statuses: TemplateStatus[]) =>
  (state: ReduxAppState): Template[] => {
    return Object.values(BaseTemplateSelector.getEntityMap(state)).filter(
      getAllByFolderAndTemplateStatusesPredicate({
        folderId: folder.id,
        organizationId: folder.organization.id,
        statuses,
      }),
    );
  };

const isLoadedByStatus =
  (status: string): Selector<ReduxAppState, boolean> =>
  (state: ReduxAppState): boolean => {
    const loadingStatus = state.statuses.templates.byStatus[status] || {};
    return !!loadingStatus.loaded;
  };

const getPremadeTemplateOverviewById = (templateId?: Muid): Selector<ReduxAppState, Option<PremadeTemplateOverview>> =>
  createSelector(BaseTemplateSelector.getEntityMap, entityMap => {
    const template = templateId && entityMap[templateId];

    if (template) {
      return {
        templateId: template.id,
        name: template.name,
        description: template.description,
        previewImageUrl: 'https://www.process.st/checklist/wp-content/themes/strada-publica/images/template-2.svg',
      } as PremadeTemplateOverview;
    }
  });

export const isTemplatePrivate = (template: Template, folders: Folder[]): boolean => {
  const folder = folders.find(f => f.id === template.folder.id)!;
  return folder && isPrivateFolder(folder, folders);
};

const isTemplatePrivateSelector = createSelector(
  BaseTemplateSelector.getEntityMap,
  FolderSelector.getEntityMap,
  (templates, folders) =>
    memoize((templateId: Muid) => {
      const template = templates[templateId];
      return template && isTemplatePrivate(template, Object.values(folders));
    }),
);

export const TemplateSelector = {
  getAll,
  getAllByFolderAndTemplateStatuses,
  getPremadeTemplateOverviewById,
  getAllByFolderId,
  getAllForSchedule,
  getAllForSelectedOrganization,
  getAllOrphansAndFolderByFolderAndTemplateStatuses,
  getAllOrphansByOrganizationIdAndTemplateStatuses,
  getById: BaseTemplateSelector.getById,
  getEntityMap: BaseTemplateSelector.getEntityMap,
  isLoadedByStatus,
  isTemplatePrivate: isTemplatePrivateSelector,
};
