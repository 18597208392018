import angular from 'angular';
import { escapeHtml } from '@process-street/subgrade/util';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ArrayService } from 'services/array-service';
import { getEnv } from 'components/common/env';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { isPartialUrl } from '@process-street/subgrade/process';
import { queryClient } from 'components/react-root';
import { GetCurrentUserInfo } from 'features/user/query-builder';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'UserManageProfileCtrl',
    function ($rootScope, $scope, $state, $q, SecurityService, SessionService, UserService, ToastService) {
      'ngInject';

      const ctrl = this;

      const logger = trace({ name: 'UserManageProfileCtrl' });
      logger.info('loading ctrl');

      ctrl.$onInit = async () => {
        if ($state.params.id === 'me') {
          const data = await queryClient.fetchQuery({
            queryFn: () => GetCurrentUserInfo.queryFn(),
            queryKey: GetCurrentUserInfo.getKey(),
          });
          if (data?.user) {
            populateScope({ ...data.user });
            refreshEmails(data.user.id);
          }
        } else {
          UserService.getById($state.params.id).then(
            user => {
              populateScope({ ...user });
              refreshEmails(user.id);
            },
            () => {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems loading the user's details`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });

              $state.go('reports');
            },
          );
        }
      };

      function populateScope(user) {
        $scope.user = user;

        $scope.info = {};
        $scope.info.username = user.username;
        $scope.info.whatIDo = user.whatIDo;
        $scope.info.telephoneNumber = user.telephoneNumber;
        $scope.info.timeZone = user.timeZone;
        $scope.info.beta = user.beta;
      }

      function refreshEmails(userId) {
        $scope.emails = [];

        UserService.getAllEmailsById(userId).then(emails => {
          $scope.emails = emails;
          $scope.emails.forEach(setEmailVerificationSent);

          sortUserEmails();
        });
      }

      function setEmailVerificationSent(userEmail) {
        const tenMinutes = 10 * 60 * 1000;
        const now = Date.now();

        userEmail.emailVerificationSent =
          userEmail.emailVerificationSentDate && now - userEmail.emailVerificationSentDate < tenMinutes;
      }

      function sortUserEmails() {
        $scope.emails.sort(userEmail => userEmail.email !== $scope.user.email);
      }

      $scope.update = function (info) {
        $scope.updating = true;

        UserService.update($scope.user.id, info)
          .then(
            async updatedUser => {
              logger.info('succeeded to update user');

              await queryClient.invalidateQueries(GetCurrentUserInfo.getKey());

              AnalyticsService.trackEvent('user updated info');

              const phoneNumber = info.telephoneNumber;
              if (!updatedUser.telephoneNumber && phoneNumber !== '') {
                AnalyticsService.trackEvent('phone number added', {
                  'phone number': phoneNumber,
                });
              }

              ToastService.openToast({
                status: 'success',
                title: `Profile updated`,
              });

              $scope.form.$setPristine();

              SessionService.setUser(updatedUser);
              $scope.user = updatedUser; // to update avatar initials when the user doesn't have a picture

              $rootScope.$broadcast(EventName.USER_UPDATED, updatedUser);

              return updatedUser;
            },
            response => {
              logger.error('failed to update user. Reason: %s', JSON.stringify(response));

              ToastService.openToast({
                status: 'error',
                title: `We're having problems updating your profile the workflow version`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });

              return $q.reject(response);
            },
          )
          .finally(() => {
            $scope.updating = false;
          });
      };

      // Profile Emails

      $scope.addUserEmail = function () {
        if (!$scope.canCreateAdditionalEmail()) {
          throw new Error('user is not allowed to create an additional email');
        }
        $scope.newEmailModalVisible = true;
      };

      $scope.onNewEmailModalCancel = function () {
        $scope.newEmailModalVisible = false;
        $rootScope.$apply();
      };

      $scope.onAddNewEmail = function (email, password) {
        $scope.newEmailModalVisible = false;
        createUserEmail(email, password);
        $rootScope.$apply();
      };

      function createUserEmail(email, password) {
        $scope.creatingUserEmail = true;

        UserService.createUserEmail($scope.user.id, email, password)
          .then(userEmail => {
            setEmailVerificationSent(userEmail);

            $scope.emails.push(userEmail);
          })
          .finally(() => {
            $scope.creatingUserEmail = false;
          });
      }

      $scope.deleteUserEmail = function (user, userEmail) {
        UserService.deleteUserEmail(user.id, userEmail.email).then(() => {
          ArrayService.desplice($scope.emails, userEmail, 'id');
        });
      };

      $scope.setPrimaryEmail = function (user, userEmail) {
        if (!$scope.canChangePrimaryEmail()) {
          throw new Error('user is not allowed to change their primary email');
        }

        UserService.setPrimaryEmail(user.id, userEmail.email).then(() => {
          $scope.user.email = userEmail.email;

          sortUserEmails();
        });
      };

      // Profile Photo Upload

      $scope.acceptTypes = /([./])(gif|jpe?g|png)$/i;
      $scope.maxFileSize = 2 * 1024 * 1024;

      $scope.add = function (__event, data) {
        AnalyticsService.trackEvent('user uploaded avatar photo');

        const sessionUser = UserService.getCurrentUser();

        const escapedId = escapeHtml(sessionUser.id);
        data.url = `${getEnv().APP_API_URL}/1/users/${escapedId}/avatar-file`;
        data.headers = SessionService.getAuthHeaders();
        $scope.fileUploading = true;

        data.submit();
      };

      $scope.done = function (__event, data) {
        $scope.fileUploading = false;

        ToastService.openToast({
          status: 'success',
          title: `Profile picture updated. Looking good!`,
        });

        const user = UserService.getCurrentUser();
        user.avatarFile = data.result;

        SessionService.setUser(user);
        $scope.user = user;
        $rootScope.$broadcast(EventName.USER_UPDATED, user);
      };

      $scope.fail = function (__event, data) {
        $scope.fileUploading = false;

        const [file] = data.files ?? [];
        let description = DefaultErrorMessages.unexpectedErrorDescription;

        if (file && file.size > $scope.maxFileSize) {
          description = 'Your profile photo must be less than 2 Mb.';
        }

        ToastService.openToast({
          status: 'error',
          title: `We're having problems updating your profile picture`,
          description,
        });
      };

      $scope.processFail = function (__event, data) {
        $scope.fileUploading = false;
        let message;
        if (data.files[0].error === 'File is too large') {
          message = 'Your profile photo must be less than 2 Mb.';
        } else {
          message = 'Your profile photo must be a GIF, JPG or PNG.';
        }
        ToastService.openToast({
          status: 'warning',
          title: `We couldn't update your profile picture`,
          description: message,
        });
      };

      // Email Confirmation

      $scope.resendVerificationEmail = function (user, userEmail) {
        if ($scope.sending) {
          return;
        }

        $scope.sending = true;

        UserService.sendEmailVerification(user.id, userEmail.email)
          .then(
            response => {
              logger.info('succeeded to send email verification code');

              ToastService.openToast({
                status: 'success',
                title: `Confirmation email sent to ${user.email}`,
              });

              const now = Date.now();
              userEmail.emailVerificationSent = true;
              userEmail.emailVerificationSentDate = now;
              user.emailVerificationSentDate = now;

              SessionService.setUser(user);
              $scope.user = user;
              $rootScope.$broadcast(EventName.USER_UPDATED, user);

              return response;
            },
            response => {
              logger.error('failed to send email verification code. Reason: %s', JSON.stringify(response));

              ToastService.openToast({
                status: 'error',
                title: `We're having problems sending the confirmation email to ${user.email}`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });

              return $q.reject(response);
            },
          )
          .finally(() => {
            $scope.sending = false;
          });
      };

      $scope.requestPasswordReset = function (user) {
        if (!$scope.canChangePassword()) {
          throw new Error('user is not allowed to change their password');
        }

        if ($scope.requestingPasswordReset) {
          return;
        }

        $scope.requestingPasswordReset = true;

        UserService.requestPasswordReset(user.email)
          .then(
            () => {
              logger.info('succeeded to request password reset');

              const escapedEmail = escapeHtml(user.email);
              ToastService.openToast({
                status: 'success',
                title: `Password reset instructions sent to ${escapedEmail}`,
              });

              $scope.passwordResetSent = true;
            },
            response => {
              logger.error('failed to request password reset. Reason: %s', JSON.stringify(response));

              ToastService.openToast({
                status: 'error',
                title: `We're having problems sending the password reset instructions`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          )
          .finally(() => {
            $scope.requestingPasswordReset = false;
          });
      };

      $scope.onUsernameChange = function () {
        if (isPartialUrl($scope.info.username)) {
          $scope.usernameError = 'Name can not contain an URL.';
        } else {
          $scope.usernameError = null;
        }
      };

      $scope.canCreateAdditionalEmail = function () {
        return SecurityService.canCreateAdditionalEmail($scope.user);
      };

      $scope.canChangePrimaryEmail = function () {
        return SecurityService.canChangePrimaryEmail($scope.user);
      };

      $scope.canChangePassword = function () {
        return SecurityService.canChangePassword($scope.user);
      };
    },
  );
