import { Muid, MuidUtils } from '@process-street/subgrade/core';
import {
  ChecklistColumn,
  Clause,
  ConditionalFilterUtils,
  Filter,
  FilterType,
} from '@process-street/subgrade/dashboard';
import React, { useEffect, useState } from 'react';

export type ConditionalFilterState = {
  storedFilter: Filter;
  setStoredFilter: React.Dispatch<React.SetStateAction<Filter>>;
  onFilterAdd: () => void;
  onFilterChange: (clause: Clause) => void;
  onFilterDelete: (id: Muid) => void;
  onFilterSwitchType: (filterSwitch: FilterType) => void;
  clauses: Clause[];
};

export function useConditionalFilterState({
  currentFilter: filter,
  onChange,
  defaultFilter: defaultFilterProp,
}: {
  currentFilter?: Filter;
  onChange?: (filter: Filter) => void;
  defaultFilter?: Omit<Clause, 'id' | 'parentId' | 'filterType'>;
} = {}): ConditionalFilterState {
  const [storedFilter, setStoredFilter] = useState<Filter>(filter ?? ConditionalFilterUtils.initialConditionalFilter);

  useEffect(() => {
    setStoredFilter(filter ?? ConditionalFilterUtils.initialConditionalFilter);
  }, [filter]);

  const onFilterSwitchType = React.useCallback(
    (switchedType: FilterType) => {
      const updatedFilter = {
        children: storedFilter.children,
        filterType: switchedType,
        id: storedFilter.id,
      };

      setStoredFilter(updatedFilter);
      onChange?.(updatedFilter);
    },
    [onChange, storedFilter.children, storedFilter.id],
  );

  const onFilterDelete = React.useCallback(
    (id: Muid) => {
      const updatedChildren = (storedFilter.children || []).filter(item => item.id !== id);
      const updatedFilter = { ...storedFilter, children: updatedChildren };

      setStoredFilter(updatedFilter);
      onChange?.(updatedFilter);
    },
    [onChange, storedFilter],
  );

  const getDefaultFilter = () => {
    if (defaultFilterProp)
      return {
        ...defaultFilterProp,
        parentId: storedFilter.id,
        id: MuidUtils.randomMuid(),
        filterType: FilterType.Clause,
      };

    const columnName = ChecklistColumn.ChecklistName;
    const { condition, operand } = ConditionalFilterUtils.getClauseConditionAndOperand({ column: columnName });
    return {
      columnName,
      condition,
      filterType: FilterType.Clause,
      id: MuidUtils.randomMuid(),
      operand,
      parentId: storedFilter.id,
    } as Clause;
  };

  const onFilterAdd = React.useCallback(() => {
    const updatedChildren = (storedFilter.children || []).concat(getDefaultFilter());
    const updatedFilter = { ...storedFilter, children: updatedChildren };

    setStoredFilter(updatedFilter);
    onChange?.(updatedFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore local getDefaultFilter
  }, [onChange, storedFilter]);

  const onFilterChange = React.useCallback(
    (clause: Clause) => {
      const updatedChildren = (storedFilter.children || []).map(f => (f.id === clause.id ? clause : f));
      const updatedFilter = { ...storedFilter, children: updatedChildren };

      setStoredFilter(updatedFilter);
      onChange?.(updatedFilter);
    },
    [onChange, storedFilter],
  );

  const clauses: Clause[] = (storedFilter.children || []).filter(
    (f): f is Clause => f.filterType === FilterType.Clause,
  );

  return React.useMemo<ConditionalFilterState>(() => {
    return {
      storedFilter,
      setStoredFilter,
      onFilterAdd,
      onFilterChange,
      onFilterDelete,
      onFilterSwitchType,
      clauses,
    };
  }, [clauses, onFilterAdd, onFilterChange, onFilterDelete, onFilterSwitchType, storedFilter]);
}
