import { createSelector } from 'reselect';
import { Entities } from 'reducers/entities/entities';
import { entitiesSelector } from '../../../reducers/entities/entities.selectors';
import { lookupsSelector } from '../../../reducers/lookups/lookups.selectors';
// eslint-disable-next-line import/named -- strange issue
import { safeEntityMapToArrayByIds } from '../../../reducers/entities/entities.utils';

const entityMap = state => entitiesSelector(state)[Entities.ROLE_ASSIGNMENT_RULE];
const lookups = state => lookupsSelector(state)[Entities.ROLE_ASSIGNMENT_RULE] || {};

const getById = id => state => entityMap(state)[id];

const lookupByTemplateRevisionId = state => lookups(state).byTemplateRevisionId || {};

const getIdsByTemplateRevisionId = templateRevisionId =>
  createSelector(lookupByTemplateRevisionId, lookupMap => lookupMap[templateRevisionId] || []);

const getAllByTemplateRevisionId = templateRevisionId =>
  createSelector([entityMap, getIdsByTemplateRevisionId(templateRevisionId)], (entitiesMap, ruleIds) =>
    safeEntityMapToArrayByIds(entitiesMap, ruleIds),
  );

const getByTaskTemplateGroupId = (templateRevisionId, taskTemplateGroupId) =>
  createSelector(getAllByTemplateRevisionId(templateRevisionId), allTemplateRevisionRules =>
    allTemplateRevisionRules.filter(r => r.targetTaskTemplateGroup.id === taskTemplateGroupId),
  );

const getAllByTaskTemplateGroupIds = (templateRevisionId, taskTemplateGroupIds) =>
  createSelector(getAllByTemplateRevisionId(templateRevisionId), allTemplateRevisionRules =>
    allTemplateRevisionRules.filter(r => taskTemplateGroupIds.includes(r.targetTaskTemplateGroup.id)),
  );

const getAllBySourceFormFieldWidgetGroupId = (templateRevisionId, sourceFormFieldWidgetGroupId) =>
  createSelector(getAllByTemplateRevisionId(templateRevisionId), allTemplateRevisionRules =>
    allTemplateRevisionRules.filter(
      r => r.sourceFormFieldWidgetGroup && r.sourceFormFieldWidgetGroup.id === sourceFormFieldWidgetGroupId,
    ),
  );

export const RoleAssignmentRuleSelector = {
  getAllByTemplateRevisionId,
  getById,
  getByTaskTemplateGroupId,
  getAllByTaskTemplateGroupIds,
  getAllBySourceFormFieldWidgetGroupId,
};
