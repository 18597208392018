import { useEffect } from 'react';
import { Attachment, Comment } from '@process-street/subgrade/process';
import { useBoolean } from 'components/design/next';
import { useInjector } from 'components/injection-provider';

export const useFocusComment = ({ id }: { id: Comment['id'] | Attachment['id'] }) => {
  const { $stateParams } = useInjector('$stateParams');

  const [isCommentFocused, setIsCommentFocused] = useBoolean($stateParams?.commentId === id);

  useEffect(() => {
    if ($stateParams?.commentId === id) {
      setIsCommentFocused.on();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$stateParams.commentId, id]);

  useEffect(() => {
    if (!isCommentFocused) return;

    const timer = setTimeout(setIsCommentFocused.off, 2000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommentFocused]);

  return {
    isCommentFocused,
  };
};
