import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { PreassigningFreeMembersWrapper } from 'components/paywalls/preassigning-free-members/wrapper';

export const PreassigningFreeMembersModule = angular
  .module('preassigningFreeMembers', [])
  .component(
    'psPreassigningFreeMembersPaywall',
    reactToAngular(PreassigningFreeMembersWrapper, ['isOpen', 'onGoBack', 'userName']),
  ).name;
