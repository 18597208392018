import { createSelector, Selector } from 'reselect';

import { EntityMap, LookupMap } from '@process-street/subgrade/redux';
import { ChecklistWidget } from '@process-street/subgrade/process';
import { ReduxAppState } from 'reducers/types';
import { Muid } from '@process-street/subgrade/core';
import { safeEntityMapToArrayByIds } from 'reducers/entities/safe-entity-map-to-array-by-ids';
import { SelectorFactories } from '@process-street/subgrade/redux/selector/factories/selector-factories';
import { BaseNavigationSelector } from '@process-street/subgrade/redux/selector/navigation.selectors';

const getEntityMap: Selector<ReduxAppState, EntityMap<ChecklistWidget>> = (
  state: ReduxAppState,
): EntityMap<ChecklistWidget> => state.entities.checklistWidget;

const getLookupByChecklistRevisionId = (state: ReduxAppState): LookupMap =>
  state.lookups.checklistWidget.byChecklistRevisionId;

const getAllByChecklistRevisionId = (checklistRevisionId: Muid): Selector<ReduxAppState, ChecklistWidget[]> =>
  createSelector([getEntityMap, getLookupByChecklistRevisionId], (entityMap, lookupMap) =>
    safeEntityMapToArrayByIds(entityMap, lookupMap[checklistRevisionId] || []),
  );

const getAllForCurrentChecklistRevisionId = SelectorFactories.getEntitiesArrayByLookupId(
  BaseNavigationSelector.getCurrentChecklistRevisionId,
  getEntityMap,
  getLookupByChecklistRevisionId,
);

export const ChecklistWidgetSelector = {
  getAllByChecklistRevisionId,
  getAllForCurrentChecklistRevisionId,
  getEntityMap,
};
