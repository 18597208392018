import { DropzoneState, useDropzone } from 'react-dropzone';
import { useCallback, useState } from 'react';
import { finishUpload, getUploadUrl, uploadToS3, uploadToWistia } from 'pages/pages/_id/edit/page/utils/upload.api';
import {
  FileWidget,
  ImageWidget,
  VideoWidget,
  VideoWidgetService,
  WidgetConstants,
} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { isAxiosError } from '@process-street/subgrade/api';
import { noop } from 'lodash';

export const SIZE_TOO_LARGE = 'size-too-large';

export function useUploadQuery(
  widget: FileWidget | ImageWidget,
  onFinish: (widget: FileWidget | ImageWidget) => void,
  accept: string | undefined,
): {
  dropzoneState: DropzoneState;
  progress: number | undefined;
  uploadError: AxiosError | undefined;
  clearUploadError: () => void;
} {
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [uploadError, setUploadError] = useState<AxiosError | undefined>(undefined);

  const clearUploadError = () => {
    setUploadError(undefined);
    setProgress(undefined);
  };

  const onDrop = useCallback(async (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length === 0) return;

    const [file] = acceptedFiles;

    const reader = new FileReader();

    const headerId = widget.header.id;
    const { url, key } = await getUploadUrl({
      headerId,
      fileName: file.name,
      mimeType: file.type,
    });

    setProgress(1);

    reader.onload = async () => {
      if (reader.result !== null) {
        try {
          await uploadToS3({ file, url, data: reader.result }, progress => setProgress(progress));
        } catch (e) {
          if (isAxiosError(e)) {
            setUploadError(e);
          }
        }

        const updatedWidget = await finishUpload({
          headerId,
          contentType: file.type,
          originalFilename: file.name,
          key,
        });
        onFinish(updatedWidget);
      }
    };
    reader.readAsArrayBuffer(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
  }, []);

  const dropzoneState = useDropzone({
    onDrop,
    accept,
    validator: file => {
      if (file.size > WidgetConstants.MAX_FILE_SIZE) {
        return {
          code: SIZE_TOO_LARGE,
          message: `Uploaded files must be under ${WidgetConstants.MAX_FILE_SIZE / 1024 / 1024} MB.`,
        };
      }
      return null;
    },
  });

  return {
    dropzoneState,
    progress,
    uploadError,
    clearUploadError,
  };
}
//TODO try to implement a better abstraction
export const useVideoUploadQuery = ({
  widget,
  onFinish,
  onUploadStarted,
  accept,
  onDropFile = noop,
  isReadOnly = false,
}: {
  widget: VideoWidget;
  onFinish: (widget: VideoWidget) => void;
  onUploadStarted: () => void;
  accept: string | undefined;
  onDropFile?: () => void;
  isReadOnly?: boolean;
}): {
  dropzoneState: DropzoneState;
  progress: number | undefined;
  uploadError: AxiosError | undefined;
  clearUploadError: () => void;
} => {
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [uploadError, setUploadError] = useState<AxiosError | undefined>(undefined);

  const clearUploadError = () => {
    setUploadError(undefined);
    setProgress(undefined);
  };

  const onDrop = useCallback(async (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length === 0) return;
    onDropFile();
    const formData = new FormData();

    formData.append('file', acceptedFiles[0]);
    setProgress(1);
    onUploadStarted();
    try {
      const { data, status } = await uploadToWistia(formData, progress => setProgress(progress));

      if (status === 200) {
        onFinish({ ...widget, service: VideoWidgetService.Wistia, serviceCode: data.hashed_id });
      }
    } catch (e) {
      if (isAxiosError(e)) {
        setUploadError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
  }, []);

  const dropzoneState = useDropzone({
    onDrop,
    accept,
    validator: file => {
      if (file.size > WidgetConstants.MAX_FILE_SIZE) {
        return {
          code: SIZE_TOO_LARGE,
          message: `Uploaded files must be under ${WidgetConstants.MAX_FILE_SIZE / 1024 / 1024} MB.`,
        };
      }
      return null;
    },
    disabled: isReadOnly,
  });

  return {
    dropzoneState,
    progress,
    uploadError,
    clearUploadError,
  };
};
