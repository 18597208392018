import { axiosService } from 'app/services/axios-service';
import { Muid } from '@process-street/subgrade/core';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export namespace AssignmentBulkSnoozeMutation {
  export const mutationKey = ['assignments', 'bulk-snooze'];

  export type Params = {
    assignmentIds: Array<Muid>;
    untilDate: number;
  };

  export const Response = {};

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post('/1/assignments/snoozed', params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey });
  };
}
