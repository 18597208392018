import create from 'zustand';

// This store shares a boolean flag between inbox datasource invalidation users
// And the inbox table component.
interface TasksTablePurgeStore {
  shouldPurge: boolean;
  setShouldPurge: (newState: boolean) => void;
}

export const usePurgeTasksTable = create<TasksTablePurgeStore>(set => ({
  shouldPurge: false,
  setShouldPurge: shouldPurge => set({ shouldPurge }),
}));
