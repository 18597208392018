import * as React from 'react';
import { VStack, Text } from 'components/design/next';
import { UserRow } from 'pages/forms/_id/edit/components/send-link-via-email-modal/user-row';
import { GroupType, isOrganizationMembershipActive, Muid, UserType } from '@process-street/subgrade/core';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { UseAssignmentsQuerySelectors } from 'pages/templates/_id/components/assignment-picker/use-assignments-query';
import { Template } from '@process-street/subgrade/process';
import { useGetAllGroupsQuery } from 'features/group/query-builder/get-all-groups';
import { useGroupMemberships } from 'features/group-memberships/query-builder';
import { GroupRow } from 'pages/forms/_id/edit/components/send-link-via-email-modal/group-row';
import { GetFormSharesQuery } from 'features/forms/query-builder';

export type UsersAndGroupsProps = {
  template: Template;
  onUserSelect: (checked: boolean, omIds: Muid[]) => void;
  formShares: GetFormSharesQuery.FormShareWithResponse[];
};

export const UsersAndGroups: React.FC<React.PropsWithChildren<UsersAndGroupsProps>> = ({
  onUserSelect,
  template,
  formShares,
}) => {
  const groupsQuery = useGetAllGroupsQuery({ include: 'user' });
  const groups = groupsQuery.data ?? [];

  const groupMembershipsQuery = useGroupMemberships();
  const groupMemberships = groupMembershipsQuery.data ?? [];

  const organizationMembershipsQuery = useGetAllOrganizationMembershipsQuery(
    { organizationId: template.organization.id },
    {
      enabled: groupsQuery.isSuccess,
    },
  );
  const allMemberships = organizationMembershipsQuery.data ?? [];
  const standardNonSystemMemberships = UseAssignmentsQuerySelectors.getStandardOrNonSystemGroupMemberships({
    organizationMemberships: allMemberships,
    groups,
  });

  const allMembersGroup = groups.find(g => g.groupType === GroupType.AllMembers);
  const allMembersGroupMembership = allMemberships.filter(om => om.user.id === allMembersGroup?.user.id);

  const selectedUserIds = formShares.map(fs => fs.user.id);

  const organizationMemberships = allMembersGroupMembership
    .concat(standardNonSystemMemberships)
    .filter(om => isOrganizationMembershipActive(om) && !selectedUserIds.includes(om.user.id));

  const areAllUsersSelected = organizationMemberships.every(
    om => om.user.userType !== UserType.Standard || selectedUserIds.includes(om.user.id),
  );

  return areAllUsersSelected ? (
    <Text color="gray.400" fontStyle="italic">
      This form has already been shared with all users.
    </Text>
  ) : (
    <VStack maxHeight="240px" overflowY="scroll" width="full" alignItems="start" paddingY={2}>
      {organizationMemberships.map(om =>
        om.user.userType === UserType.Standard ? (
          <UserRow organizationMembership={om} key={om.id} onChange={onUserSelect} />
        ) : (
          <GroupRow
            organizationMembership={om}
            key={om.id}
            onChange={onUserSelect}
            groups={groups}
            allGroupsMemberships={groupMemberships}
            allMemberships={allMemberships}
            selectedUserIds={selectedUserIds}
          />
        ),
      )}
    </VStack>
  );
};
