import * as React from 'react';
import { FieldType, isFormFieldWidget, Widget, WidgetType } from '@process-street/subgrade/process';
import { useInView } from 'framer-motion';
import { ReorderAutoScrollContext } from 'app/pages/workflows/_id/edit-v2/providers/reorder-scroll-context';
import { WidgetListItemWrapperProps, WidgetListItemWrapper } from './widget-list-item-wrapper';

type SizeInPx = number;

// placeholder sizes
// This could be tweaked more by estimating based on e.g. multiselect item count, text line count etc.
const fieldTypeSizeMap: Partial<Record<FieldType, SizeInPx>> = {
  [FieldType.Date]: 70,
  [FieldType.Email]: 70,
  [FieldType.File]: 80,
  [FieldType.Hidden]: 135,
  [FieldType.Members]: 70,
  [FieldType.MultiChoice]: 290,
  [FieldType.MultiSelect]: 190,
  [FieldType.Number]: 70,
  [FieldType.Select]: 290,
  [FieldType.SendRichEmail]: 200,
  [FieldType.Snippet]: 135,
  [FieldType.Table]: 330,
  [FieldType.Text]: 70,
  [FieldType.Textarea]: 109,
  [FieldType.Url]: 70,
};

const widgetSizeMap: Partial<Record<WidgetType, SizeInPx>> = {
  [WidgetType.CrossLink]: 0,
  [WidgetType.Email]: 570,
  [WidgetType.Embed]: 430,
  [WidgetType.File]: 50,
  [WidgetType.Image]: 250,
  [WidgetType.Text]: 200,
  [WidgetType.Video]: 450,
};

export const getWidgetHeight = (widget: Widget) => {
  if (isFormFieldWidget(widget)) {
    return fieldTypeSizeMap[widget.fieldType] ?? 70;
  }
  return widgetSizeMap[widget.header.type] ?? 120;
};

/**
 * If the widget gets this close to the viewport, render the widget.
 * (See docs for useInView and IntersectionObserver options)
 */
const WIDGET_VIEWPORT_MARGIN_PX = 500;

export const VirtualizedWidgetListItemWrapper = (props: Omit<WidgetListItemWrapperProps, 'shouldRenderLoading'>) => {
  const ref = React.useRef(null);
  const scrollContainerRef =
    React.useContext(ReorderAutoScrollContext)?.widgetAutoScroll.scrollContainerProps.scrollContainerRef;

  const isInViewport = useInView(ref, {
    margin: WIDGET_VIEWPORT_MARGIN_PX + 'px',
    root: scrollContainerRef,
  });

  return <WidgetListItemWrapper shouldRenderLoading={!isInViewport} ref={ref} {...props} />;
};
