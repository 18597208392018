import { useInjector } from 'components/injection-provider';
import { EventName } from 'services/event-name';
import { getWidgetModalsMachineService } from 'features/widgets/widget-modals-machine';
import { Widget } from '@process-street/subgrade/process';
import React from 'react';

const actor = getWidgetModalsMachineService();

export function useOnEditDefaultValueClick() {
  const { $rootScope } = useInjector('$rootScope');

  return React.useCallback(
    (widget: Widget) => {
      actor.send({ type: 'OPEN_SETTINGS', widget });
      // Send it on the event loop so that the modal is open before we focus the input
      setTimeout(() => {
        $rootScope.$broadcast(EventName.DEFAULT_VALUE_SETTINGS_CLICK, widget);
      });
    },
    [$rootScope],
  );
}
