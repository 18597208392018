import angular from 'angular';
import { TaskListConstants } from '@process-street/subgrade/process/task-list-constants';
import templateUrl from './checklist-left-pane.component.html';
import './checklist-left-pane.scss';
import { TaskStatus } from '@process-street/subgrade/process';
import { QueryObserver } from 'react-query';
import { queryClient } from 'components/react-root';
import { match } from 'ts-pattern';
import { noop } from '@process-street/subgrade/util';
import { SolutionInstancesByTemplateIdQuery } from 'features/automations/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { ablyService } from 'app/pusher/ably.service';
import { AblyEvent } from 'app/pusher/ably-event';
import { GetChecklistQuery } from 'features/checklists/query-builder';
import { StopTaskEvent } from 'services/stop-task-event';

/** Allow this much time for the backend before refetching checklist data */
const CHECKLIST_NAME_UPDATE_GRACE_PERIOD = 2000;
const MERGE_TAG_REGEX = /\{\{.*\}\}/;

angular.module('frontStreetApp.directives').component('psChecklistLeftPane', {
  bindings: {
    templateRevision: '<',
    activeTaskTemplateGroupId: '<',
    userCanManageTasks: '<',
    userCanUpdateTemplate: '<',
    userAnonymous: '<',
    widgetsMap: '<',
    formFieldValueMap: '<',

    checklistRevision: '<',
    isSandboxMode: '<',

    onTaskTemplatesLoaded: '&',
    onInitWidgets: '&',

    onActiveTaskTemplateSet: '&',
    onSelectTaskTemplate: '&',
    onTaskStatusChanged: '&',
    onSetChecklistProgress: '&',

    onToggleWidgetsVisibility: '&',
    onSetWidgetsVisibility: '&',
    onUpdateTitle: '&',
  },
  templateUrl,
  controller(
    $ngRedux,
    $rootScope,
    $state,
    $timeout,
    ChecklistService,
    SessionService,
    ToastService,
    $scope,
    FeatureFlagService,
  ) {
    const ctrl = this;
    ctrl.taskListMode = TaskListConstants.Mode.CHECKLIST_EDITOR;
    ctrl.hideCompletedTasks = false;

    ctrl.isEmbeddedInMsTeams = () => $state.includes('microsoftTeams');

    const logger = trace({ name: 'psTaskListLeftPane' });
    logger.info('loading ctrl');

    ctrl.$onDestroy = () => {
      ctrl.unsubscribeFromAutomations?.();
      ctrl.unsubscribeFromWorkflowRunUpdated?.();
    };

    ctrl.$onChanges = function () {
      if (ctrl.checklistRevision && ctrl.templateRevision) {
        ctrl.initializeAsChecklist(ctrl.checklistRevision);
      }
    };

    ctrl.$onInit = () => {
      ctrl.subscribeToAutomations();
      if (FeatureFlagService.getFeatureFlags().mergeTagImprovements) {
        ctrl.subscribeToWorkflowRunUpdates(ctrl.checklistRevision?.checklist?.id);
      }
    };

    ctrl.initializeAsChecklist = function (checklistRevision) {
      ctrl.title = checklistRevision.checklist.name;
    };

    ctrl.setActiveTaskTemplate = function (taskTemplate, task, completable, assignees, canMoveUp, canMoveDown) {
      ctrl.activeTaskTemplate = taskTemplate;

      ctrl.onActiveTaskTemplateSet({
        taskTemplate,
        task,
        completable,
        assignees,
        canMoveUp,
        canMoveDown,
      });
    };

    ctrl.getTemplate = function () {
      return ctrl.checklistRevision && ctrl.checklistRevision.checklist.template;
    };

    ctrl.toggleCompletedTasksVisibility = () => {
      $timeout(() => {
        ctrl.hideCompletedTasks = !ctrl.hideCompletedTasks;
      });
    };

    ctrl.isChecklistActionable = ChecklistService.isChecklistActionable;

    ctrl.updateChecklistTitle = function (title) {
      ChecklistService.updateName(ctrl.checklistRevision.checklist, title).then(
        updatedChecklist => {
          ToastService.openToast({
            status: 'success',
            title: `Workflow run name updated`,
          });

          ctrl.checklistRevision.checklist.name = updatedChecklist.name;

          // Update page title on edit
          ctrl.onUpdateTitle({ title: ctrl.checklistRevision.checklist.name });

          queryClient.invalidateQueries(GetChecklistQuery.getKey({ checklistId: ctrl.checklistRevision.checklist.id }));
        },
        () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems updating the workflow run name`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });

          ctrl.title = ctrl.checklistRevision.checklist.name;
        },
      );
    };

    // Fetch updated checklist name on task completion
    ctrl.fetchUpdatedChecklistTitle = () => {
      ChecklistService.getById(ctrl.checklistRevision.checklist.id, true).then(checklist => {
        ctrl.checklistRevision.checklist.name = checklist.name;
        ctrl.onUpdateTitle({ title: checklist.name });
        ctrl.title = checklist.name;
      });
    };

    ctrl.requiredTaskIds = []; // attached tasks blocking WFR completion
    $rootScope.$on(StopTaskEvent.CHECKLIST_HAS_NOT_COMPLETED_ATTACHED_TASKS, (_event, requiredOneOffTasks) => {
      ctrl.requiredTaskIds = requiredOneOffTasks.map(t => t.id);
    });

    if (!FeatureFlagService.getFeatureFlags().mergeTagImprovements) {
      $rootScope.$on(EventName.TASK_STATUS_UPDATE_OK, (_event, task) => {
        // backend only updates when a task is completed
        const taskIsCompleted = task.status === TaskStatus.Completed;
        const containsMergeTag = MERGE_TAG_REGEX.test(ctrl.checklistRevision.checklist.name);
        if (!taskIsCompleted || !containsMergeTag) {
          return;
        }
        $timeout(() => {
          ctrl.fetchUpdatedChecklistTitle();
        }, CHECKLIST_NAME_UPDATE_GRACE_PERIOD);
      });
    }

    // Task list callbacks

    ctrl.taskTemplatesLoaded = false;
    ctrl.setTaskTemplatesAsLoaded = function (taskTemplates) {
      ctrl.taskTemplatesLoaded = true;
      ctrl.onTaskTemplatesLoaded({ taskTemplates });
    };

    ctrl.setChecklistProgress = function (progress) {
      ctrl.onSetChecklistProgress({ progress });
    };

    ctrl.toggleWidgetsVisibility = function () {
      ctrl.onToggleWidgetsVisibility();
    };

    ctrl.setWidgetsVisibility = function (visible, instantly) {
      ctrl.onSetWidgetsVisibility({ visible, instantly });
    };

    ctrl.selectTaskTemplate = function (taskTemplate, replace) {
      ctrl.onSelectTaskTemplate({ taskTemplate, replace });
    };

    ctrl.initWidgets = function (taskTemplateId) {
      ctrl.onInitWidgets({ taskTemplateId });
    };

    ctrl.changeTaskStatus = function (taskTemplate, status) {
      ctrl.onTaskStatusChanged({ taskTemplate, status });
    };

    ctrl.automations = [];
    ctrl.templateHasAutomations = false;

    ctrl.subscribeToAutomations = () => {
      if (ctrl.userAnonymous || !ctrl.templateRevision?.template?.id) return;

      const templateId = ctrl.templateRevision.template.id;
      const observer = new QueryObserver(queryClient, {
        queryKey: SolutionInstancesByTemplateIdQuery.getKey({ templateId }),
        queryFn: () => SolutionInstancesByTemplateIdQuery.queryFn({ templateId }),
      });

      ctrl.unsubscribeFromAutomations = observer.subscribe(result => {
        match(result)
          .with({ status: 'success' }, () => {
            $scope.$evalAsync(() => {
              const automations = result.data ?? [];
              ctrl.automations = automations;
              ctrl.templateHasAutomations = automations.some(
                templateSolutionInstance => templateSolutionInstance.enabled,
              );
            });
          })
          .otherwise(noop);
      });
    };

    ctrl.subscribeToWorkflowRunUpdates = checklistId => {
      if (!checklistId) return;

      const channelName = ablyService.getChannelNameForChecklist(checklistId);
      const channel = ablyService.getChannel(channelName);

      const listener = message => {
        logger.info(`message from ${AblyEvent.EventType.WorkflowRunUpdated}`);
        const { checklistId: updatedChecklistId } = JSON.parse(message.data);
        if (checklistId === updatedChecklistId) {
          ctrl.fetchUpdatedChecklistTitle();
        }
      };

      logger.info(`subscribing to ${AblyEvent.EventType.WorkflowRunUpdated}`);
      channel.subscribe(AblyEvent.EventType.WorkflowRunUpdated, listener);

      ctrl.unsubscribeFromWorkflowRunUpdated = () => {
        logger.info(`unsubscribing from ${AblyEvent.EventType.WorkflowRunUpdated}`);

        channel.unsubscribe(AblyEvent.EventType.WorkflowRunUpdated);
      };
    };
  },
});
