import * as React from 'react';
import { Box, Center, FormControl, FormLabel, HStack, IconButton, Input, Spinner, Tooltip } from '@chakra-ui/react';
import { Icon } from 'components/design/next';
import { FormFieldLabelActor, FormFieldLabelActorSelectors } from './form-field-label-machine';
import { useActor, useSelector } from '@xstate/react';
import { FormFieldWidget } from '@process-street/subgrade/process';

export type FormFieldLabelProps<TFormFieldWidget extends FormFieldWidget> = {
  actor: FormFieldLabelActor<TFormFieldWidget>;
  isReadOnly?: boolean;
};

export const FormFieldLabel = <TFormFieldWidget extends FormFieldWidget>({
  actor,
  isReadOnly,
}: FormFieldLabelProps<TFormFieldWidget>) => {
  const [current, send] = useActor(actor);
  const isEditing = useSelector(actor, FormFieldLabelActorSelectors.isEditing);
  const isSaving = useSelector(actor, FormFieldLabelActorSelectors.isSaving);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useLayoutEffect(() => {
    if (isEditing && inputRef.current) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus({ preventScroll: true });
          inputRef.current.select();
        }
      }, 0);
    }
  }, [isEditing]);

  return (
    <Box w={isEditing ? 'full' : 'auto'} maxW="full">
      {isEditing && (
        <Input
          ref={inputRef}
          zIndex="2"
          value={current.context.widget?.label ?? ''}
          px="0"
          h="6"
          border="none"
          w="full"
          placeholder="Type label here"
          color="gray.600"
          fontWeight="400"
          _focus={{ boxShadow: 'none' }}
          onFocus={e => {
            if (current.matches('autoFocus.enabled')) e.target.select();
          }}
          onBlur={() => send({ type: 'BLUR' })}
          onChange={e => send({ type: 'CHANGE', value: e.target.value })}
          onKeyPress={e => send({ type: 'KEY_PRESS', event: e })}
        />
      )}

      {/* Keep the label on the DOM so the input will resize with it. */}
      <HStack
        w="full"
        mt={isEditing ? '-6' : undefined}
        opacity={isEditing ? '0' : undefined}
        _hover={{
          '.chakra-button': { opacity: 1 },
        }}
      >
        <FormControl isRequired={current.context.widget.required}>
          <FormLabel
            userSelect="text"
            htmlFor={`form-field-widget-${current.context.widget.id}`}
            m="0"
            color={current.context.widget?.label ? 'gray.600' : 'gray.400'}
            fontWeight="600"
            maxW="full"
            noOfLines={1}
            _hover={{
              cursor: 'text',
            }}
            onClick={isReadOnly ? undefined : () => send({ type: 'TOGGLE_EDIT' })}
          >
            {current.context.widget?.label || '(Untitled)'}
          </FormLabel>
        </FormControl>

        {isSaving ? (
          <Center h="6">
            <Spinner />
          </Center>
        ) : (
          <>
            {!isReadOnly && (
              <IconButton
                minW="unset"
                h="6"
                opacity="0"
                variant="ghost"
                aria-label="Edit"
                _hover={{
                  bgColor: 'transparent',
                }}
                onClick={() => send({ type: 'TOGGLE_EDIT' })}
              >
                <Icon icon="edit" size="3.5" color="gray.400" />
              </IconButton>
            )}

            {current.matches('mutation.error') && (
              <Tooltip label="The label could not be updated. Please try again." placement="top">
                <Box>
                  <Icon
                    icon="warning"
                    size="4"
                    color="yellow.600"
                    aria-label="The label could not be updated. Please try again."
                  />
                </Box>
              </Tooltip>
            )}
          </>
        )}
      </HStack>
    </Box>
  );
};
