import { IntercomService } from './intercom-service';

export type UpdateContactSalesVariablesProps = {
  company: {
    id: string;
    contact_sales_click_at?: number;
    contact_sales_event_type_viewed_at?: number;
    contact_sales_date_and_time_selected_at?: number;
    contact_sales_event_scheduled_at?: number;
  };
};
const updateIntercomVariables = (properties: UpdateContactSalesVariablesProps) => {
  IntercomService.update(properties);
};

export const ContactSalesService = {
  updateIntercomVariables,
};
