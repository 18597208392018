import * as React from 'react';
import { User } from '@process-street/subgrade/core';
import { getAvatar } from 'app/components/common/Avatar';
import { Avatar, HStack, Text, VStack } from 'components/design/next';

export interface PreviewAvatarComponentProps {
  user: User;
  isEmailVisible: boolean;
  isOption?: boolean;
}

export const PreviewAvatarComponent: React.FC<React.PropsWithChildren<PreviewAvatarComponentProps>> = ({
  user,
  isEmailVisible,
}) => {
  const avatar = getAvatar(user);
  const { email, username } = user;
  return (
    <HStack gap="1">
      <Avatar
        showBorder={true}
        name={avatar.title}
        title={avatar.title}
        src={avatar.url}
        getInitials={() => avatar.initials}
        size="xs"
      />
      <VStack alignItems="flex-start" spacing="0">
        <Text color="gray.600">{username}</Text>
        {isEmailVisible ? (
          <Text variant="-2" color="gray.500">
            {email}
          </Text>
        ) : null}
      </VStack>
    </HStack>
  );
};
