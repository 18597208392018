import angular from 'angular';
import templateUrl from './list.component.html';
import './list.component.scss';

export class ApprovalSubjectTasksListController {}

export const ApprovalSubjectTasksList: angular.IComponentOptions = {
  bindings: {
    disabled: '<',
    onApprove: '&',
    onItemSelect: '&',
    onReject: '&',
    tasks: '<',
    type: '<',
    isCondensed: '<',
  },
  controller: ApprovalSubjectTasksListController,
  templateUrl,
};
