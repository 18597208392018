import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { useMemo } from 'react';
import { ConditionalLogicUtils, NormalizedData } from '../utils/conditional-logic-utils';

type UseNormalizedDataParams = {
  tasks: TaskTemplate[];
  widgets: Widget[];
  rules: ChecklistRuleDefinition[];
};

export const useNormalizedData = ({ tasks, widgets, rules }: UseNormalizedDataParams): NormalizedData => {
  const normalizedTasks = useMemo(() => ConditionalLogicUtils.getNormalizedTasks(tasks), [tasks]);
  const normalizedWidgets = useMemo(() => ConditionalLogicUtils.getNormalizedWidgets(widgets), [widgets]);
  const normalizedRules = useMemo(() => ConditionalLogicUtils.getNormalizedRules(rules), [rules]);
  const normalizedNodes = useMemo(() => ConditionalLogicUtils.getNormalizedNodes(tasks, widgets), [tasks, widgets]);

  return useMemo(
    () => ({
      widgets: normalizedWidgets,
      tasks: normalizedTasks,
      nodes: normalizedNodes,
      rules: normalizedRules,
    }),
    [normalizedTasks, normalizedWidgets, normalizedNodes, normalizedRules],
  );
};
