import * as React from 'react';
import { Button, HStack, Text, useToast, VStack } from 'components/design/next';
import { MuidUtils } from '@process-street/subgrade/core';
import { useQueryClient } from 'react-query';
import { useDataSetId } from '../../../../utils/use-data-set-id';
import {
  GetAllDataSetsQuery,
  useCreateDataSetColumnMutation,
  useUpdateColumnNameMutation,
} from 'pages/reports/data-sets/query-builder';
import { useWebhookType } from '../use-webhook-type';
import { components, NoticeProps } from 'react-select';
import { ChecklistOrFormFieldOrColumnDefinitionOption } from '../utils';

export const NoOptionsMessage: React.FC<NoticeProps<ChecklistOrFormFieldOrColumnDefinitionOption>> = props => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const webhookType = useWebhookType();
  const searchTerm = props.selectProps.inputValue ?? '';
  const dataSetId = useDataSetId();
  const dataSetQuery = GetAllDataSetsQuery.useQuery({
    select: dataSets => dataSets.find(ds => ds.id === dataSetId),
    enabled: Boolean(dataSetId),
  });

  const createDataSetColumnMutation = useCreateDataSetColumnMutation();
  const updateColumnNameMutation = useUpdateColumnNameMutation({
    onSuccess: async column => {
      await queryClient.invalidateQueries(GetAllDataSetsQuery.key);
      toast({
        title: 'Column created',
        status: 'success',
      });

      props.setValue(
        {
          label: column.name,
          value: column.id,
          type: 'columnDefinition',
        },
        'select-option',
      );
    },
  });

  const handleCreateColumn = () => {
    if (!dataSetQuery.data) throw new Error('`dataSet` is missing');
    const dataSet = dataSetQuery.data;

    createDataSetColumnMutation.mutate(
      {
        dataSetId: dataSet.id,
        columnId: MuidUtils.randomMuid(),
        position: dataSet.columnDefs?.length ?? 0,
      },
      {
        onSuccess: column => {
          updateColumnNameMutation.mutate({
            dataSetId: dataSet.id,
            columnId: column.id,
            name: searchTerm,
          });
        },
      },
    );
  };

  if (webhookType !== 'DataSet') return <components.NoOptionsMessage {...props} />;

  return (
    <HStack w="full" justifyContent="space-between" p="4">
      <VStack w="full" spacing="0" alignItems="flex-start" justifyContent="center">
        <Text fontWeight="semibold" color="gray.600">
          {searchTerm}
        </Text>
        <Text fontSize="sm" color="gray.500" fontStyle="italic">
          New column
        </Text>
      </VStack>

      <Button
        size="sm"
        variant="solid"
        colorScheme="gray"
        onClick={handleCreateColumn}
        isLoading={createDataSetColumnMutation.isLoading || updateColumnNameMutation.isLoading}
      >
        Create
      </Button>
    </HStack>
  );
};
