import { match } from 'ts-pattern';
import { isModelRef, isNotIdRef } from '../core';
import { Checklist, ChecklistStatus, Task, TaskConstants, TaskStatus } from '../process';
import {
  ApprovalTaskInboxItem,
  ChecklistInboxItem,
  InboxItem,
  InboxItemType,
  OneOffTaskInboxItem,
  StandardTaskInboxItem,
  TaskInboxItem,
} from './inbox-model';
import * as encase from 'encase';

export namespace InboxItemUtils {
  export const isTask = (
    item: InboxItem,
  ): item is StandardTaskInboxItem | ApprovalTaskInboxItem | OneOffTaskInboxItem => {
    return [InboxItemType.StandardTask, InboxItemType.ApprovalTask, InboxItemType.OneOffTask].includes(item.itemType);
  };

  export const isOneOffTask = (item: InboxItem): item is OneOffTaskInboxItem => {
    return InboxItemType.OneOffTask === item.itemType;
  };

  export const isChecklist = (item: InboxItem): item is ChecklistInboxItem => {
    return item.itemType === InboxItemType.Checklist;
  };

  export const getDueDate = (item: InboxItem): number | undefined => {
    if (isTask(item)) {
      return item.task.dueDate;
    }

    if (isModelRef(item.checklist)) {
      return item.checklist.dueDate;
    }
  };

  export const isCompleted = (item: InboxItem): boolean => {
    if (isTask(item)) {
      return item.task.status === TaskStatus.Completed;
    }

    if (isChecklist(item) && isNotIdRef(item.checklist)) {
      return item.checklist.status === ChecklistStatus.Completed;
    }

    return false;
  };

  // Combines the inbox item's task or checklist with the assignmentId to create an unique id.
  export const getUniqueId = (item: InboxItem): Task['id'] | Checklist['id'] | undefined => {
    return match(item)
      .when(isTask, item => `${item.task.id}-${item.assignmentId}`)
      .when(isChecklist, item => `${item.checklist.id}-${item.assignmentId}`)
      .otherwise(() => undefined);
  };

  export const getId = (item: InboxItem): Task['id'] | Checklist['id'] | undefined => {
    return match(item)
      .when(isTask, item => item.task.id)
      .when(isChecklist, item => item.checklist.id)
      .otherwise(() => undefined);
  };

  export const getChecklists = (items: InboxItem[]): ChecklistInboxItem[] => items.filter(isChecklist);

  export const getTasks = (items: InboxItem[]): TaskInboxItem[] => items.filter(isTask);

  const MASKED_TASK_TEXT = 'You don’t have permission to view this task';
  export const getTaskTemplateName = (item: InboxItem): string | undefined => {
    if (!isTask(item)) {
      return;
    }
    if (item.masked) {
      return `<${MASKED_TASK_TEXT}>`;
    }
    const { task } = item;
    if (isNotIdRef(task.taskTemplate) && task.taskTemplate.name) {
      return task.taskTemplate.name;
    }
    return `<${TaskConstants.DefaultTaskName}>`;
  };

  export const getChecklistName = (item: InboxItem): string | undefined => {
    if (isChecklist(item) && isNotIdRef(item.checklist)) {
      return item.checklist.name;
    }
  };

  export const getName = (item: InboxItem): string | undefined => {
    return match(item)
      .when(isTask, getTaskTemplateName)
      .when(isChecklist, getChecklistName)
      .otherwise(() => undefined);
  };

  export const resolveGroupId = (group: string): string => 'inbox_' + encase.toLowerSnake(group);
}
