import * as React from 'react';
import { SavedView } from 'components/dashboard/models/saved-views';
import { Muid } from '@process-street/subgrade/core';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import {
  ChecklistScreenContext,
  ChecklistScreenDispatch,
  ChecklistScreenReducer,
} from 'components/dashboard/components/checklist/ChecklistDashboardScreen/checklist-screen-state';
import { SavedViewEditorMode } from 'components/dashboard/components/checklist/ChecklistDashboardSavedViews/SavedViewEditor';
import { useInjector } from 'components/injection-provider';
import { TemplateDashboardHelpers } from './dashboard-helpers';
import { Clause, Condition, ConditionalFilterUtils, ProgressStatus } from '@process-street/subgrade/dashboard';

type DashboardProviderProps = {
  templateId?: Muid;
  filter: Clause;
};

export const ChecklistSavedViewConfigProvider = ({
  templateId,
  filter,
  children,
}: React.PropsWithChildren<DashboardProviderProps>) => {
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const { $state, ToastService } = useInjector('$stateParams', 'TemplateService', '$state', 'ToastService');

  // Saved view to return all the reports that are not completed or archived.
  const templateSavedViewConfig: SavedView = React.useMemo(
    () =>
      TemplateDashboardHelpers.createTemplateSavedViewConfig({
        organizationId,
        templateId,
        filter,
      }),
    [templateId, organizationId, filter],
  );

  const { reducer } = new ChecklistScreenReducer($state, ToastService);
  const [state, dispatch] = React.useReducer(reducer, {
    editedSavedView: templateSavedViewConfig,
    editorMode: SavedViewEditorMode.NEW,
    editorSavedView: undefined,
    editorVisible: false,
    selectedSavedView: undefined,
    viewInited: true,
    viewModified: false,
    viewModifiers: [],
    templates: [],
  });

  const value = React.useMemo(
    () => ({
      ...state,
      editedSavedView: templateSavedViewConfig,
    }),
    [state, templateSavedViewConfig],
  );

  return (
    <ChecklistScreenContext.Provider value={value}>
      <ChecklistScreenDispatch.Provider value={dispatch}>{children}</ChecklistScreenDispatch.Provider>
    </ChecklistScreenContext.Provider>
  );
};

export const activeChecklistsSavedViewFilter = ConditionalFilterUtils.createProgressStatusFilter(
  Condition.DoesNotContain,
  [ProgressStatus.Completed, ProgressStatus.Archived],
);

export const activeChecklistsWithCompletedSavedViewFilter = ConditionalFilterUtils.createProgressStatusFilter(
  Condition.DoesNotContain,
  [ProgressStatus.Archived],
);

export const allChecklistsSavedViewFilter = ConditionalFilterUtils.createProgressStatusFilter(
  Condition.DoesNotContain,
  [],
);
