import { Muid } from '@process-street/subgrade/core';
import { axiosService } from 'services/axios-service';

export type CreateWidgetUploadUrlMutationParams = {
  headerId: Muid;
  fileName: string;
  mimeType: string;
};

export type CreateWidgetUploadUrlMutationResponse = {
  key: string;
  url: string;
};

export const CreateWidgetUploadUrlMutation = {
  key: ['widget', 'upload-url'],
  mutationFn: (params: CreateWidgetUploadUrlMutationParams) =>
    axiosService
      .getAxios()
      .post<CreateWidgetUploadUrlMutationResponse>(`/1/widgets/${params.headerId}/upload-url`, params)
      .then(r => r.data),
};
