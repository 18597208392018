import angular from 'angular';
import confirmTemplate from '../features/message-box/confirm.component.html';
import promptTemplate from '../features/message-box/prompt.component.html';
import spinnerTemplate from '../features/message-box/spinner.component.html';

angular.module('frontStreetApp.services').factory('MessageBox', function ($uibModal) {
  const calculateAnimation = spec => spec.animation !== false;

  const calculateBackdrop = spec => {
    let { backdrop } = spec;
    if (backdrop !== true && backdrop !== false && backdrop !== 'static') {
      backdrop = true;
    }
    return backdrop;
  };

  function customComponent(spec) {
    const { component, options = {}, keyboard, size } = spec;

    const animation = calculateAnimation(spec);
    const backdrop = calculateBackdrop(spec);

    return $uibModal.open({
      component,
      animation,
      backdrop,
      size,
      keyboard,
      resolve: {
        modalOptions: () => options,
      },
    });
  }

  /**
   * @param spec
   * @returns {*}
   * @deprecated Use customComponent instead
   */
  function custom(spec) {
    const { templateUrl, windowClass, controller, keyboard, size, options = {}, controllerAs = '$ctrl' } = spec;

    const animation = calculateAnimation(spec);
    const backdrop = calculateBackdrop(spec);

    return $uibModal.open({
      templateUrl,
      controller,
      controllerAs,
      animation,
      backdrop,
      keyboard,
      size,
      windowClass,
      resolve: {
        modalOptions: () => options,
      },
    });
  }

  function confirm(options) {
    return custom({
      templateUrl: confirmTemplate,
      controller: 'MessageBoxConfirmCtrl',
      backdrop: options.backdrop,
      options,
    });
  }

  function prompt(options) {
    return custom({
      templateUrl: promptTemplate,
      controller: 'MessageBoxPromptCtrl',
      options,
    });
  }

  function spinner(options, spec) {
    return custom({
      templateUrl: spinnerTemplate,
      controller: 'MessageBoxSpinnerCtrl',
      animation: spec.animation,
      backdrop: spec.backdrop,
      keyboard: spec.keyboard,
      size: spec.size,
      options,
    });
  }

  return {
    custom,
    customComponent,
    confirm,
    prompt,
    spinner,
  };
});
