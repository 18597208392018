import * as React from 'react';
import { useField } from 'formik';

import {
  FormControl,
  FormHelperText,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from 'components/design/next';

export type NumberFieldProps = NumberInputProps & {
  name: string;
};

export const NumberField: React.FC<React.PropsWithChildren<NumberFieldProps>> = ({
  name,
  min,
  max,
  children,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  const { setValue } = helpers;

  const onChange = (val: string) => {
    const parsed = parseInt(val);
    if (isNaN(parsed)) {
      setValue(undefined);
    } else {
      setValue(parsed);
    }
  };

  return (
    <FormControl width="25">
      <NumberInput
        {...field}
        id={field.name}
        type="number"
        inputMode="numeric"
        min={min}
        max={max}
        {...props}
        onChange={onChange}
        isInvalid={!!meta.error && meta.touched}
      >
        <NumberInputField name={field.name} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <FormHelperText>{children}</FormHelperText>
    </FormControl>
  );
};
