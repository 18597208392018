import { Organization, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { QueryClient, QueryObserver, QueryObserverOptions, useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { QueryBuilder } from 'utils/query-builder';

export type OrganizationMembershipsQueryParams = {
  organizationId?: Organization['id'];
};
export type OrganizationMembershipsQueryResult = OrganizationMembershipWithUser[];

export const getAllOrganizationMemberships = ({ organizationId }: OrganizationMembershipsQueryParams) =>
  axiosService
    .getAxios()
    .get<OrganizationMembershipsQueryResult>(`/1/organizations/${organizationId}/users`, {
      params: { include: 'api,group' },
    })
    .then(r => r.data);

const key = ['organizations', 'users'];
export const OrganizationMembershipsQuery = {
  key,
  getKey: (params: OrganizationMembershipsQueryParams) => [...key, params],
  queryFn: getAllOrganizationMemberships,
} satisfies QueryBuilder;

export const useGetAllOrganizationMembershipsQuery = <Select = OrganizationMembershipsQueryResult>(
  params: OrganizationMembershipsQueryParams,
  options: UseQueryOptions<OrganizationMembershipsQueryResult, Error, Select> = {},
) =>
  useQuery<OrganizationMembershipsQueryResult, Error, Select>(
    OrganizationMembershipsQuery.getKey(params),
    () => OrganizationMembershipsQuery.queryFn(params),
    {
      staleTime: 1000 * 60 * 5,
      enabled: Boolean(params.organizationId) && options.enabled !== false,
      ...options,
    },
  );

export const makeGetAllOrganizationMembershipsQueryObserver = ({
  queryClient,
  options,
  ...params
}: OrganizationMembershipsQueryParams & {
  queryClient: QueryClient;
  options?: QueryObserverOptions<OrganizationMembershipsQueryResult, AxiosError>;
}) => {
  return new QueryObserver<OrganizationMembershipsQueryResult, AxiosError>(queryClient, {
    ...options,
    queryKey: OrganizationMembershipsQuery.getKey(params),
    queryFn: () => OrganizationMembershipsQuery.queryFn(params),
    enabled: Boolean(params.organizationId) && options?.enabled !== false,
  });
};
