import angular from 'angular';
import { trace } from 'components/trace';
import { ToastServiceImpl } from 'services/toast-service.impl';

angular
  .module('frontStreetApp.controllers')
  .controller('ResetPasswordCtrl', function ($scope, $state, appBootService, auth, focusById) {
    const ctrl = this;
    const logger = trace({ name: 'ResetPasswordCtrl' });
    logger.info('loading ctrl');

    ctrl.$onInit = () => {
      focusById('newPassword');
    };

    $scope.resetPassword = function (info) {
      if (!$scope.form.$invalid) {
        $scope._doResetPassword(info);
      } else {
        let message;
        if ($scope.form.newPassword.$error.required) {
          message = 'You must enter a new password.';
        }
        if ($scope.form.confirmNewPassword.$error.required) {
          message = 'You must confirm the new password.';
        }
        if ($scope.form.confirmNewPassword.$error.equal) {
          message = 'Passwords must match.';
        }

        ToastServiceImpl.openToast({
          status: 'error',
          title: message,
        });
      }
    };

    $scope._extractResetToken = () => $state.params['#'] || $state.params.id;

    $scope._doResetPassword = function (info) {
      $scope.submitting = true;

      const resetToken = $scope._extractResetToken();
      auth.resetPassword(resetToken, info.newPassword, info.confirmNewPassword).then(
        () => {
          appBootService.cleanUpSessionAndShutdown();
        },
        message => {
          $scope.submitting = false;
          ToastServiceImpl.openToast({
            status: 'error',
            title: message,
          });
        },
      );
    };
  });
