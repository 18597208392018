import * as React from 'react';
import { Link, LinkProps, forwardRef } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { RawParams, StateDeclaration, StateObject, StateService, TransitionOptions } from '@uirouter/angularjs';
import { AppState } from 'app/app.states.typegen';

export interface NgLinkRouteProps {
  to: AppState | StateDeclaration | StateObject;
  params?: RawParams;
  options?: TransitionOptions;
}

export interface NgLinkProps extends Omit<LinkProps, 'href'>, NgLinkRouteProps {}

export const NgLink = forwardRef<NgLinkProps, 'a'>((props, ref) => {
  const { $state } = useInjector('$state');
  return <PureNgLink {...{ $state, ...props, ref }} />;
});

/**
 * Came across a case with `toast()` where the useInjector wasn't working so I had to pass in $state
 */
export const PureNgLink = forwardRef<NgLinkProps & { $state: StateService }, 'a'>(
  ({ to, params, options, onClick: onClickProp, $state, ...props }, ref) => {
    return (
      <Link
        ref={ref}
        href={$state.href(to, params, options)}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onClickProp?.(e);
          if (props.isExternal) {
            window.open($state.href(to, params, options), '_blank');
          } else {
            $state.go(to, params, options);
          }
        }}
        {...props}
      />
    );
  },
);
