import angular from 'angular';
import { generateLocation, generateTo, getTabByName, getTabIndexByName } from 'services/tab-service';
import templateUrl from './properties.component.html';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'TagManageCtrl',
    function ($scope, $state, $timeout, SessionService, Subject, TagService, ToastService, UserService) {
      const ctrl = this;

      const logger = trace({ name: 'TagManageCtrl' });
      logger.info('loading ctrl');

      const possibleTabs = [
        {
          name: 'properties',
          heading: 'Properties',
          templateUrl,
        },
      ];

      ctrl.$onInit = () => {
        initializePermissions();
        initializeTag().finally(() => {
          $scope.initialize();
        });
      };

      $scope.$on('$stateChangeSuccess', (__event, __toState, toParams) => {
        $scope.activeIndex = getTabIndexByName($scope.tabs, toParams.tab) || 0;
      });

      // Initialization

      $scope.initialize = function () {
        if (!$scope.subject.isAdmin()) {
          ToastService.openToast({
            status: 'error',
            title: `You don't have permission to view edit the tags`,
            description: DefaultErrorMessages.permissionErrorDescription,
          });
          $timeout(() => {
            $state.go('dashboard');
          });
        } else if (!$scope.tag) {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems loading the tag`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
          $timeout(() => {
            $state.go('dashboard');
          });
        } else {
          $scope.tabs = possibleTabs;
          $scope.activeIndex = getTabIndexByName($scope.tabs, $state.params.tab) || 0;

          $timeout(() => {
            initializeTabs($scope.tabs);
          });
        }
      };

      function initializePermissions() {
        const sessionUser = UserService.getCurrentUser();
        const selectedOrganizationId = SessionService.getSelectedOrganizationId();
        $scope.subject = new Subject(sessionUser.id, selectedOrganizationId);
      }

      function initializeTabs(tabs) {
        tabs.forEach(tab => {
          tab.select = function () {
            this.selectedOnce = true;

            const to = generateTo($state.current.name);
            const location = generateLocation($state.current.name);
            if ($state.includes('tagManage')) {
              $state.go(to, { tab: tab.name, index: null }, { location });
            }
          };
        });

        const selectedTab = getTabByName($scope.tabs, $state.params.tab) || tabs[0];
        selectedTab.select();
      }

      function initializeTag() {
        $scope.$on(EventName.TAG_UPDATED, (event, tag) => {
          $scope.tag = tag;
          event.stopPropagation();
        });

        return TagService.getById($state.params.id).then(tag => {
          $scope.tag = tag;
        });
      }
    },
  );
