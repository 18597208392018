import { Muid } from '@process-street/subgrade/core';
import { SavedViewPermit } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetSavedViewPermitsResponse = SavedViewPermit[];

export type GetSavedViewPermitsParams = {
  dataSetId: Muid;
  savedViewId: Muid;
};

const key = ['saved-views', 'permits'];

export const GetSavedViewPermitsQuery = {
  getKey: (params: GetSavedViewPermitsParams): QueryKey => [...key, params],
  queryFn: (params: GetSavedViewPermitsParams) =>
    axiosService
      .getAxios()
      .get<GetSavedViewPermitsResponse>(`/1/data-sets/${params.dataSetId}/saved-views/${params.savedViewId}/permits`)
      .then(res => res.data),
};

export const useGetSavedViewPermitsQuery = <Select = GetSavedViewPermitsResponse>(
  params: GetSavedViewPermitsParams,
  options: UseQueryOptions<GetSavedViewPermitsResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetSavedViewPermitsQuery.getKey(params), () => GetSavedViewPermitsQuery.queryFn(params), options);
};
