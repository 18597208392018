import { VStack, Text, Divider, Link, Grid, GridItem } from 'components/design/next';
import * as React from 'react';
import { EmailBackgroundBrandColorField } from './email-brand-fields/email-background-brand-color-field';
import { EmailCustomizationEnabledSwitchField } from './email-brand-fields/email-customization-enabled-switch-field';
import { EmailFooterTextarea } from './email-brand-fields/email-footer-textarea';
import { EmailPrimaryBrandColorField } from './email-brand-fields/email-primary-brand-color-field';
import { SendTestEmail } from './email-brand-fields/send-test-email';
import { EmailBrandHeaderLogoWrapper } from './email-brand-header-logo/email-brand-header-logo-wrapper';

export const EmailBrandCustomization: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <VStack spacing="6" alignItems="flex-start" w="full">
      <Text color="gray.500" variant="-2u">
        Email Notifications
      </Text>
      <Divider mt="4" />

      <Text color="gray.700">
        Customize your email notifications to make them look like your brand.{' '}
        <Link href={DOCS_URL} color="brand.500" isExternal>
          Learn more »
        </Link>
      </Text>

      <Grid templateColumns={{ base: '1fr', md: '220px 1fr' }}>
        <GridItem display="flex" alignItems="center">
          <Text color="gray.600" as="label">
            Header logo
          </Text>
        </GridItem>
        <GridItem>
          <EmailBrandHeaderLogoWrapper />
        </GridItem>
      </Grid>
      <EmailPrimaryBrandColorField />
      <EmailBackgroundBrandColorField />
      <EmailFooterTextarea />
      <EmailCustomizationEnabledSwitchField />
      <SendTestEmail />
    </VStack>
  );
};

const DOCS_URL = 'https://www.process.st/help/docs/custom-notifications/';
