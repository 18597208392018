import { createSelector, Selector } from 'reselect';
import { Muid, Option } from '../../../core';
import { EntityMap, ImmutableLookupMap } from '../../types';

/**
 * Creates a selector which handles extracting a array of related entities via a lookup.
 *
 * @param primaryIdSelector -
 *  Returns the ID of the primary entity.
 *  Eg: ChecklistId
 * @param entityMapSelector -
 *  Returns a map of the related entities keyed by their IDs.
 *  Eg: TaskId -> Task
 * @param lookupSelector -
 *  Returns a lookup table which maps the primary entity ID to a list of related entity IDs.
 *  Eg: ChecklistId -> TaskId[]
 *
 * @returns T[]
 *
 * Note - Return an empty array if the primaryIdSelector or lookupSelector returns undefined.
 */
const getEntitiesArrayByLookupId = <S, T>(
  primaryIdSelector: Selector<S, Option<Muid>>,
  entityMapSelector: Selector<S, EntityMap<T>>,
  lookupSelector: Selector<S, ImmutableLookupMap>,
): Selector<S, T[]> =>
  createSelector(
    [primaryIdSelector, entityMapSelector, lookupSelector],
    (id: Option<Muid>, entityMap: EntityMap<T>, lookup: ImmutableLookupMap) => {
      if (id && lookup[id] !== undefined) {
        return lookup[id]
          .map((entityId: Muid) => entityMap[entityId])
          .filter((entity: Readonly<T>) => entity !== undefined);
      } else {
        return [] as T[];
      }
    },
  );

/**
 * Creates a selector which handles extracting a map of related entities via a lookup.
 *
 * @param primaryIdSelector -
 *  Returns the ID of the primary entity.
 *  Eg: ChecklistId
 * @param entityMapSelector -
 *  Returns a map of the related entities keyed by their IDs.
 *  Eg: TaskId -> Task
 * @param lookupSelector -
 *  Returns a lookup table which maps the primary entity ID to a list of related entity IDs.
 *  Eg: ChecklistId -> TaskId[]
 *
 * @returns {Muid : T}
 *
 * Note - Return an empty map if the primaryIdSelector or lookupSelector returns undefined.
 */
const getEntitiesMapByLookupId = <S, T>(
  primaryIdSelector: Selector<S, Option<Muid>>,
  entityMapSelector: Selector<S, EntityMap<T>>,
  lookupSelector: Selector<S, ImmutableLookupMap>,
): Selector<S, EntityMap<T>> =>
  createSelector(
    [primaryIdSelector, entityMapSelector, lookupSelector],
    (id: Option<Muid>, entityMap: EntityMap<T>, lookup: ImmutableLookupMap) => {
      if (id && lookup[id] !== undefined) {
        return lookup[id].reduce((result: EntityMap<T>, secondaryEntityId: Muid) => {
          result[secondaryEntityId] = entityMap[secondaryEntityId];
          return result;
        }, {} as EntityMap<T>);
      } else {
        return {} as EntityMap<T>;
      }
    },
  );

export const SelectorFactories = {
  getEntitiesArrayByLookupId,
  getEntitiesMapByLookupId,
};
