import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { MergeTagsMenu } from '.';

export const MergeTagsMenuModule = angular
  .module('mergeTagsMenu', [])
  .component(
    'psMergeTagsMenu',
    reactToAngular(MergeTagsMenu, [
      'isDisabled',
      'onSelect',
      'mergeTagTarget',
      'templateRevisionId',
      'fieldId',
      'ngProps',
    ]),
  ).name;
