import { GroupBase, StylesConfig } from 'react-select';
import { useBreakpointValue, useToken } from '@chakra-ui/react';

export function useInboxDropdownStyles<
  Option = unknown,
  IsMulti extends boolean = true,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(): StylesConfig<Option, IsMulti, Group> {
  const [brand100, brand50, gray300, gray500, gray600] = useToken('colors', [
    'brand.100',
    'brand.50',
    'gray.300',
    'gray.500',
    'gray.600',
  ]);
  const [size1, size2] = useToken('sizes', ['1', '2']);
  const [fontSm] = useToken('fontSizes', ['sm']);

  const controlMaxWidth = useBreakpointValue({
    base: 'full',
    md: '300px',
  });

  const menuWidth = useBreakpointValue({
    base: 'calc(100vw - 16px)',
    md: '150px',
  });

  return {
    menu: s => ({
      ...s,
      width: 'max-content',
      maxWidth: '260px',
      minWidth: menuWidth,
      p: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
      },
    }),
    menuList: s => ({ ...s, overflowX: 'hidden' }),
    control: (s, state) => ({
      ...s,
      'padding': `${size2} ${size1}`,
      'border': 'none',
      'boxShadow': 'none',
      ':hover': {
        backgroundColor: brand100,
      },
      'backgroundColor': state.menuIsOpen ? brand50 : 'none',
      'maxWidth': controlMaxWidth,
    }),
    placeholder: s => ({ ...s, color: gray600, margin: 0 }),
    dropdownIndicator: (s, state) => ({
      ...s,
      padding: size1,
      color: state.isDisabled ? gray300 : 'inherit',
    }),
    multiValue: s => ({ ...s, backgroundColor: 'initial', height: 'initial', margin: 'initial' }),
    multiValueLabel: s => ({ ...s, maxWidth: 'initial', paddingLeft: 0 }),
    singleValue: (s, state) => ({ ...s, color: state.isDisabled ? `${gray300} !important` : 'inherit' }),
    valueContainer: s => ({ ...s, padding: `0 ${size1}`, flexWrap: 'nowrap' }),
    groupHeading: s => ({ ...s, textTransform: 'initial', color: gray500, fontSize: fontSm, marginBottom: size2 }),
  };
}
