import * as React from 'react';

import { Button, MenuItem, Text } from 'components/design/next';
import { SANDBOX_PREVIEWER_PARAMS, useCreateSandboxAndRedirect } from 'app/hooks/use-create-sandbox-and-redirect';
import { Template } from '@process-street/subgrade/process';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { SandboxUserType } from '../preview-as-menu/utils';

export interface RestartSandboxButtonProps {
  templateId?: Template['id'];
  templateName: Template['name'];
  isMobile?: boolean;
}

export const RestartSandboxButton: React.FC<React.PropsWithChildren<RestartSandboxButtonProps>> = ({
  templateId,
  templateName,
  isMobile = false,
}) => {
  const currentUser = useCurrentUser();
  const sandboxPreviewerParams = JSON.parse(sessionStorage.getItem(SANDBOX_PREVIEWER_PARAMS) as string);
  const runChecklistAndRedirect = useCreateSandboxAndRedirect({
    templateName,
    templateId,
  });
  const handleClick = () => {
    // If the current previewer is a role, we need to restart as a user since there won't be any in the new run.
    if (sandboxPreviewerParams && sandboxPreviewerParams.userType === SandboxUserType.Role) {
      sessionStorage.setItem(
        SANDBOX_PREVIEWER_PARAMS,
        JSON.stringify({
          userId: currentUser?.id,
          userType: SandboxUserType.User,
        }),
      );
    }
    runChecklistAndRedirect();
  };
  return isMobile ? (
    <MenuItem as={Button} fontSize="lg" justifyContent="flex-start" variant="ghost" onClick={handleClick}>
      Restart
    </MenuItem>
  ) : (
    <Button
      aria-label="Restart Sandbox"
      borderColor="gray.600"
      onClick={handleClick}
      colorScheme="gray"
      color="gray.600"
      fontSize="sm"
      fontWeight="normal"
      mr={2}
      variant="link"
      _hover={{ bgColor: 'gray.200' }}
      _active={{ bgColor: 'gray.200' }}
      _focus={{ bgColor: 'gray.200' }}
    >
      <Text as="span">
        (
        <Text as="span" textDecoration="underline">
          Restart
        </Text>
        )
      </Text>
    </Button>
  );
};
