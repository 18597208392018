import * as React from 'react';
import { Box, Button, Flex, Text, Divider, Spinner } from 'components/design/next';
import {
  IntervalToPlanCostInfoMap,
  PlanCostQuantity,
  PlanInfo,
} from 'pages/organizations/manage/billing/models/models';
import { PlanInterval } from '@process-street/subgrade/billing';
import { Option } from '@process-street/subgrade/core';
import { useRoleNames } from 'hooks/use-role-names';

export type PlanSummaryProps = {
  buttonTitle: Option<string>;
  costMap: IntervalToPlanCostInfoMap;
  onSubmit: () => void;
  planInfoName: PlanInfo['name'];
  planInterval: PlanInterval;
  submitting: boolean;
  planCostQuantity: PlanCostQuantity;
};

const getSummaryNote = (planCostQuantity: PlanCostQuantity, fullMemberTitle: string) => {
  const { minQuantity, quantity } = planCostQuantity;

  if (minQuantity > 0) {
    return quantity > minQuantity ? `${quantity} ${fullMemberTitle}` : `${quantity} ${fullMemberTitle} (minimum)`;
  } else {
    return null;
  }
};

export const PlanSummary: React.FC<React.PropsWithChildren<PlanSummaryProps>> = ({
  planInfoName,
  planInterval,
  costMap,
  onSubmit,
  submitting,
  buttonTitle,
  planCostQuantity,
}) => {
  const roleNames = useRoleNames();
  const monthly = planInterval === PlanInterval.Monthly;
  const interval = monthly ? 'Monthly' : 'Yearly';

  const finalPrice = costMap[planInterval]?.planCost?.rawTotalCost ?? 0;
  const regularPrice = monthly ? finalPrice : (costMap[PlanInterval.Monthly]?.planCost?.rawTotalCost ?? 0) * 12;
  const annualSavings = regularPrice - finalPrice;

  const summaryNote = getSummaryNote(planCostQuantity, roleNames.FullMember.plural);

  return (
    <Box ml={8} backgroundColor="gray.50" w="260px" borderRadius={2} padding={8}>
      <Text variant="2" color="gray.700" fontWeight="medium" mb={6}>
        Plan Summary
      </Text>

      <Flex direction="row" justifyContent="space-between">
        <Text variant="-1" fontWeight="medium" color="gray.600">{`${planInfoName} (${interval})`}</Text>
        <Text variant="-1" color="gray.600">
          ${regularPrice}
        </Text>
      </Flex>

      <Text variant="-2" color="gray.500">
        {summaryNote}
      </Text>

      {annualSavings > 0 && (
        <Flex direction="row" justifyContent="space-between" mt={4}>
          <Text variant="-1" fontWeight="medium" color="green.500">
            Yearly savings
          </Text>
          <Text variant="-1" color="green.500">
            -${annualSavings}
          </Text>
        </Flex>
      )}

      <Divider my={6} borderColor="gray.200" opacity={1} />

      <Flex direction="row" justifyContent="space-between">
        <Text variant="0" fontWeight="medium" color="gray.700">
          Due Now
        </Text>
        <Text variant="0" color="gray.700">
          ${finalPrice}
        </Text>
      </Flex>

      {buttonTitle && (
        <Button
          mt={6}
          w="100%"
          onClick={onSubmit}
          spinner={<Spinner sx={{ color: 'white' }} />}
          isLoading={submitting}
          isDisabled={submitting}
        >
          {buttonTitle}
        </Button>
      )}
    </Box>
  );
};
