import { TaskTemplate, Widget } from '@process-street/subgrade/process';

export const CONDITIONAL_LOGIC_MODAL_EVENT = {
  OPEN: 'CONDITIONAL_LOGIC_MODAL_EVENT_OPEN',
  CLOSE: 'CONDITIONAL_LOGIC_MODAL_EVENT_CLOSE',
  TOGGLE: 'CONDITIONAL_LOGIC_MODAL_EVENT_TOGGLE',
};

export interface ConditionalLogicModalEvent {
  widget?: Widget;
  taskTemplate?: TaskTemplate;
}
