import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { S3File } from '@process-street/subgrade/core';

export namespace FinishUploadWorkflowSourceMutation {
  export type Params = {
    fileName: string;
    mimeType: string;
    key: string;
  };

  export type Response = S3File;

  export const key = ['ai-generation', 'import-template', 'finish-upload'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/ai-generation/import-template/finish-upload`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
