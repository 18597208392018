import { match, P } from 'ts-pattern';
import { AuditMetadata, IdRef, MediaFile, Muid, Option, Organization, Period, Ref, S3File, User } from '../core';
import { TaskAssignment } from '../role-assignment';
import { StringUtils } from '../util/string-utils';
import { ChecklistRevision, DueDateState, Task } from './checklist-model';
import { OrderTree, TaskTemplate, TemplateRevision } from './template-model';
import { TableFormFieldConfig } from '@process-street/subgrade/process/configs/table-form-field-config';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';

export type WidgetGroup = IdRef;

export enum WidgetType {
  CrossLink = 'CrossLink',
  Email = 'Email',
  Embed = 'Embed',
  File = 'File',
  FormField = 'FormField',
  Image = 'Image',
  Table = 'Table',
  Text = 'Text',
  Video = 'Video',
}

export interface WidgetLike {
  readonly id: Muid;
  header: WidgetHeaderLike;
}

export interface WidgetHeaderLike {
  readonly id: Muid;
  audit: AuditMetadata;
  deletedDate?: number;
  deletedBy?: Ref<User>;
  organization: Ref<Organization>;
  group: WidgetGroup;
  taskTemplate: Ref<TaskTemplate>;
  type: WidgetType;
  orderTree?: OrderTree;
  hiddenByDefault: boolean;
}

export type WidgetHeader = Widget['header'];

// Deconstruct the union with the type
export type WidgetHeaderOfType<Type extends WidgetType> = Extract<WidgetHeader, { type: Type }>;

export type Widget =
  | CrossLinkWidget
  | EmailWidget
  | EmbedWidget
  | FileWidget
  | FormFieldWidget
  | ImageWidget
  | TableWidget
  | TextWidget
  | VideoWidget;

export interface ChecklistWidget {
  audit: AuditMetadata;
  checklistRevisionId: Muid;
  groupId: Muid;
  hidden: boolean;
  organizationId: Muid;
  readonly id: Muid;
}

export interface WidgetUpdateOrderTreesRequest {
  orderModels: Array<{ widgetHeaderId: Muid; orderTree: OrderTree }>;
}

export enum WidgetUpdateResponseCode {
  TaskTemplateNotActive = 'TaskTemplateNotActive',
  TemplateRevisionNotDraft = 'TemplateRevisionNotDraft',
}

export enum WidgetUpdateResponseStatus {
  Ok = 'Ok',
  Conflict = 'Conflict',
  NotFound = 'NotFound',
  Forbidden = 'Forbidden',
  NothingToUpdate = 'NothingToUpdate',
  NotUpdated = 'NotUpdated',
}

export interface WidgetUpdateOrderTreesResponse {
  response: WidgetUpdateResponseStatus;
  id: Muid;
  widgetHeader?: WidgetHeaderLike;
  message?: string;
  code?: WidgetUpdateResponseCode;
}

export enum FieldType {
  Date = 'Date',
  Email = 'Email',
  File = 'File',
  Hidden = 'Hidden',
  Members = 'Members',
  MultiChoice = 'MultiChoice',
  MultiSelect = 'MultiSelect',
  Number = 'Number',
  Select = 'Select',
  SendRichEmail = 'SendRichEmail',
  Snippet = 'Snippet',
  Table = 'Table',
  Text = 'Text',
  Textarea = 'Textarea',
  Url = 'Url',
}

export interface FormFieldWidgetLikeHeader extends WidgetHeaderLike {
  type: WidgetType.FormField;
}

export interface FormFieldWidgetLike extends WidgetLike {
  header: FormFieldWidgetLikeHeader;
  fieldType: FieldType;
  templateRevision: Ref<TemplateRevision>;
  config: Record<string, unknown>;
  /** These are made unique on the back-end */
  key: string;
  label?: string;
  helpText?: string;
  constraints?: Record<string, unknown>;
  deleted: boolean;
  required: boolean;
}

export type FormFieldWidget =
  | DateFormFieldWidget
  | EmailFormFieldWidget
  | FileFormFieldWidget
  | HiddenFormFieldWidget
  | MembersFormFieldWidget
  | MultiChoiceFormFieldWidget
  | MultiSelectFormFieldWidget
  | NumberFormFieldWidget
  | SelectFormFieldWidget
  | SendRichEmailFormFieldWidget
  | SnippetFormFieldWidget
  | TableFormFieldWidget
  | TextFormFieldWidget
  | TextareaFormFieldWidget
  | UrlFormFieldWidget;

export type FormFieldWidgetOfType<Type extends FieldType> = Extract<FormFieldWidget, { fieldType: Type }>;

export type FormFieldConfig = FormFieldWidget['config'];

// https://stackoverflow.com/questions/49401866/all-possible-keys-of-an-union-type
type KeysOfUnion<T> = T extends T ? keyof T : never;

export const CONFIGURABLE_CONFIGS = new Set([
  'placeholder',
  'unit',
  'unitLocation',
] as KeysOfUnion<ConfigurableFormFieldConfig>[]) as Set<keyof ConfigurableFormFieldConfig>;

export type ConfigurableFormFieldConfig =
  | NumberFormFieldConfig
  | TextFormFieldConfig
  | FileFormFieldConfig
  | EmailFormFieldConfig;

export interface FormFieldConfigWithDefaultValue {
  defaultValue: string;
}

export const FieldTypeToDefaultLabel: Record<FieldType, string> = {
  [FieldType.Date]: '(Untitled Date)',
  [FieldType.Email]: '(Untitled Email Address)',
  [FieldType.File]: '(Untitled File Upload)',
  [FieldType.Hidden]: '(Untitled Hidden)',
  [FieldType.Members]: '(Untitled Members)',
  [FieldType.MultiChoice]: '(Untitled Multi Choice)',
  [FieldType.MultiSelect]: '(Untitled Sub Tasks)',
  [FieldType.Number]: '(Untitled Number)',
  [FieldType.Select]: '(Untitled Dropdown)',
  [FieldType.SendRichEmail]: '(Untitled Send Email)',
  [FieldType.Snippet]: '(Untitled Snippet)',
  [FieldType.Table]: '(Untitled Table)',
  [FieldType.Text]: '(Untitled Short Text)',
  [FieldType.Textarea]: '(Untitled Long Text)',
  [FieldType.Url]: '(Untitled Website)',
};

export const getFormFieldWidgetLabel = (widget: FormFieldWidget) => {
  const defaultLabel = FieldTypeToDefaultLabel[widget.fieldType];
  return match(widget)
    .with({ fieldType: FieldType.SendRichEmail }, ({ config }) => StringUtils.getNonEmpty(config.subject, defaultLabel))
    .with({ fieldType: FieldType.MultiSelect }, subtasksWidget => {
      const items = subtasksWidget.config.items.map(item => item.name).filter(name => !!name);
      return items.length > 0 ? items.join(', ') : '(No subtasks)';
    })
    .with({ label: P.intersection(P.string, P.not('')) }, ({ label }) => label!)
    .otherwise(() => StringUtils.getNonEmpty(defaultLabel, '(Untitled)'));
};

export interface DateFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.Date;
  constraints: DateFormFieldConstraints;
}

export enum DateFormFieldConstraintsSourceType {
  SpecificDate = 'SpecificDate',
  FormFieldValue = 'FormFieldValue',
  TaskDueDate = 'TaskDueDate',
  ChecklistStartDate = 'ChecklistStartDate',
  ChecklistDueDate = 'ChecklistDueDate',
}

export type DateFormFieldConstraints = Partial<{
  afterDate: number;
  beforeDate: number;
  afterDateFormFieldWidgetGroupId: WidgetGroup['id'];
  beforeDateFormFieldWidgetGroupId: WidgetGroup['id'];
  afterDateSourceType: DateFormFieldConstraintsSourceType;
  beforeDateSourceType: DateFormFieldConstraintsSourceType;
  afterDateOffset: Period;
  beforeDateOffset: Period;
  calculatedAfterDate: number;
  calculatedBeforeDate: number;
}>;

export interface EmailFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.Email;
  config: EmailFormFieldConfig;
  constraints: EmailFormFieldConstraints;
}

export type EmailFormFieldConfig = Partial<
  {
    placeholder: string;
  } & FormFieldConfigWithDefaultValue
>;
export type EmailFormFieldConstraints = Partial<{
  domains: string[];
  restriction: 'Allow' | 'Block';
}>;

export interface FileFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.File;
  config: FileFormFieldConfig;
  constraints: FileFormFieldConstraints;
}

export type FileFormFieldConfig = Partial<{
  placeholder: string;
}>;
export type FileFormFieldConstraints = Partial<{
  extensions?: string[];
}>;

export interface HiddenFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.Hidden;
  config: HiddenFormFieldConfig;
}

export interface HiddenFormFieldConfig extends Partial<FormFieldConfigWithDefaultValue> {}

export interface MembersFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.Members;
  config: MembersFormFieldConfig;
}

export type MembersFormFieldConfig = {
  groupId: Muid;
};

export interface MultiChoiceFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.MultiChoice;
  config: SelectFormFieldConfig;
}

export interface MultiSelectFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.MultiSelect;
  config: SelectFormFieldConfig;
}

export interface NumberFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.Number;
  config: NumberFormFieldConfig;
  constraints: NumberFormFieldConstraints;
}

export type NumberFormFieldConfig = Partial<
  {
    placeholder: string;
    unit: string;
    unitLocation: 'prefix' | 'suffix';
  } & FormFieldConfigWithDefaultValue
>;
export type NumberFormFieldConstraints = Partial<{
  allowNegative: boolean;
  minDigits: number;
  maxDigits: number;
  minValue: number;
  maxValue: number;
  decimalPlaces: number | undefined;
}>;

export interface SelectFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.Select;
  config: SelectFormFieldConfig;
}

export interface SelectFormFieldConfigItem {
  id: Muid;
  name: string;
}

export type SelectFormFieldConfig = {
  items: SelectFormFieldConfigItem[];
  linkId?: Muid;
  linkedDataSetId?: Muid;
  linkedDataSetName?: string;
  linkedSavedViewId?: Muid;
  linkedSavedViewName?: string;
  linkedColumnId?: Muid;
  linkedColumnName?: string;
  linkedColumnData?: string[];
  linkedDataSetRowCount?: number;
};

export interface SendRichEmailFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.SendRichEmail;
  config: SendRichEmailFormFieldConfig;
}

export interface SendRichEmailFormFieldConfig extends Record<string, unknown> {
  to: Option<string[]>;
  cc: Option<string[]>;
  bcc: Option<string[]>;
  subject: Option<string>;
  richEditorBody: Option<string>;
  rawHTMLBody: Option<string>;
  plainTextBody: Option<string>;
  editor: 'RichEditor' | 'RawHTMLEditor' | 'PlainTextEditor';
  emailFormat?: 'RichTextOrHtml' | 'PlainText';
  editAllowed?: boolean;
  attachments?: RichEmailWidgetAttachmentWithS3File[];
}

export enum EmailFormat {
  RichTextOrHtml = 'RichTextOrHtml',
  PlainText = 'PlainText',
}

export enum EmailEditor {
  RichEditor = 'RichEditor',
  RawHTMLEditor = 'RawHTMLEditor',
  PlainTextEditor = 'PlainTextEditor',
}

export type RichEmailWidgetAttachment = {
  id: Muid;
  createdDate: number;
  createdById: Muid;
  deletedDate?: number;
  deletedById?: Muid;
  widgetId: Muid;
  formFieldValueId?: Muid;
  fileId: Muid;
  organizationId: Muid;
};

export type RichEmailWidgetAttachmentWithS3File = {
  attachment: RichEmailWidgetAttachment;
  s3File: S3File;
};

export interface SnippetFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.Snippet;
  config: { value: string };
}

export type SnippetFormFieldWidgetConfig = Partial<{
  value: string;
}>;

export interface TableFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.Table;
  config: TableFormFieldConfig.Config;
}

export interface TextFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.Text;
  config: TextFormFieldConfig;
  constraints: TextFormFieldConstraints;
}

export const TEXT_MAX_LENGTH = 254;
export type TextFormFieldConfig = Partial<
  {
    placeholder: string;
  } & FormFieldConfigWithDefaultValue
>;
export type TextFormFieldConstraints = Partial<{
  min: number;
  max: number;
}>;

export interface TextareaFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.Textarea;
  config: TextareaFormFieldConfig;
  constraints: TextareaFormFieldConstraints;
}

export const TEXTAREA_MAX_LENGTH = 65536;
export type TextareaFormFieldConfig = TextFormFieldConfig & {
  format?: 'PlainText' | 'RichText';
};
export type TextareaFormFieldConstraints = TextFormFieldConstraints;

export interface UrlFormFieldWidget extends FormFieldWidgetLike {
  fieldType: FieldType.Url;
  config: UrlFormFieldConfig;
}

export interface UrlFormFieldConfig extends Partial<FormFieldConfigWithDefaultValue> {}

export enum VideoWidgetService {
  S3 = 'S3',
  YouTube = 'YouTube',
  Vimeo = 'Vimeo',
  Wistia = 'Wistia',
  Loom = 'Loom',
}

export interface VideoWidgetHeader extends WidgetHeaderLike {
  type: WidgetType.Video;
}

export interface VideoWidget extends WidgetLike {
  header: VideoWidgetHeader;
  file?: S3File | MediaFile;
  description?: string;
  service?: VideoWidgetService;
  serviceCode?: string;
}

export interface CrossLinkWidgetHeader extends WidgetHeaderLike {
  type: WidgetType.CrossLink;
}

export interface CrossLinkWidget extends WidgetLike {
  header: CrossLinkWidgetHeader;
  templateId?: Muid;
}

export interface EmailWidgetHeader extends WidgetHeaderLike {
  type: WidgetType.Email;
}

export interface EmailWidget extends WidgetLike {
  header: EmailWidgetHeader;
  recipient?: string;
  cc?: string;
  bcc?: string;
  subject?: string;
  body?: string;
}

export interface EmbedWidgetHeader extends WidgetHeaderLike {
  type: WidgetType.Embed;
}

export interface EmbedWidget extends WidgetLike {
  header: EmbedWidgetHeader;
  url?: string;
}

export interface FileWidgetHeader extends WidgetHeaderLike {
  type: WidgetType.File;
}

export interface FileWidget extends WidgetLike {
  header: FileWidgetHeader;
  file?: S3File;
  description?: string;
}

export interface TextWidgetHeader extends WidgetHeaderLike {
  type: WidgetType.Text;
}

export interface TextWidget extends WidgetLike {
  header: TextWidgetHeader;
  content?: string;
}

export type WithTaskTemplate<W extends Widget> = W & {
  header: W['header'] & {
    taskTemplate: TaskTemplate;
  };
};

export interface TableWidgetHeader extends WidgetHeaderLike {
  type: WidgetType.Table;
}

export interface TableWidget extends WidgetLike {
  header: TableWidgetHeader;
  content?: string;
}

export interface ImageWidgetHeader extends WidgetHeaderLike {
  type: WidgetType.Image;
}

export interface ImageWidget extends WidgetLike {
  header: ImageWidgetHeader;
  file?: S3File;
  caption?: string;
}

export interface DateFieldValue {
  value?: number;
  timeHidden?: boolean;
}

export interface FileFieldValue extends MediaFile {
  id?: Muid;
}

export interface MembersFieldValue {
  organizationMembershipIds?: Muid[];
}

export interface MultiOptionFieldValue {
  itemValues: MultiSelectFieldValue[];
}

type MultiSelectFieldValueItemType = 'Dynamic' | 'Static';

export interface MultiSelectFieldValue {
  id: Muid;
  status: MultiSelectItemValueStatus | MultiChoiceItemValueStatus;
  name?: string;
  itemType?: MultiSelectFieldValueItemType;
}

export enum MultiChoiceItemValueStatus {
  Selected = 'Selected',
  NotSelected = 'NotSelected',
}

export enum MultiSelectItemValueStatus {
  NotCompleted = 'NotCompleted',
  Completed = 'Completed',
}

export interface SelectFieldValue {
  value?: string;
  dataSetRowId?: Muid;
}

export interface SendRichEmailFieldValue {
  lastSentDate?: number;
  lastSentByUserId?: Muid;
  to?: string[];
  cc?: string[];
  bcc?: string[];
  subject?: string;
  body?: string;
  attachments?: RichEmailWidgetAttachmentWithS3File[];
}

// TODO Rename this to StringFieldValue
export interface SimpleFieldValue {
  value?: FieldValue;
  hasDefaultValue?: boolean;
}

export type FieldValue = string | undefined | null;

export type FieldValueJson =
  | DateFieldValue
  | FileFieldValue
  | MembersFieldValue
  | MultiOptionFieldValue
  | SelectFieldValue
  | SendRichEmailFieldValue
  | SimpleFieldValue
  | TableFieldValue.FieldValue;

export interface FormFieldValueLike<Value = FieldValueJson> {
  readonly id: Muid;
  audit: AuditMetadata;
  checklistRevision: Ref<ChecklistRevision>;
  formFieldWidget: Ref<FormFieldWidget>;
  fieldValue: Value;
}

export interface DateFormFieldValue extends FormFieldValueLike<DateFieldValue> {}

export interface EmailFormFieldValue extends FormFieldValueLike<SimpleFieldValue> {}

export interface FileFormFieldValue extends FormFieldValueLike<FileFieldValue> {}

export interface HiddenFormFieldValue extends FormFieldValueLike<SimpleFieldValue> {}

export interface MembersFormFieldValue extends FormFieldValueLike<MembersFieldValue> {}

export interface MultiChoiceFormFieldValue extends FormFieldValueLike<MultiOptionFieldValue> {}

export interface MultiSelectFormFieldValue extends FormFieldValueLike<MultiOptionFieldValue> {}

export interface NumberFormFieldValue extends FormFieldValueLike<SimpleFieldValue> {}

export interface SelectFormFieldValue extends FormFieldValueLike<SelectFieldValue> {}

export interface SendRichEmailFormFieldValue extends FormFieldValueLike<SendRichEmailFieldValue> {}

export interface SnippetFormFieldValue extends FormFieldValueLike {}

export interface TableFormFieldValue extends FormFieldValueLike<TableFieldValue.FieldValue> {}

export interface TextFormFieldValue extends FormFieldValueLike<SimpleFieldValue> {}

export interface TextareaFormFieldValue extends FormFieldValueLike<SimpleFieldValue> {}

export interface UrlFormFieldValue extends FormFieldValueLike<SimpleFieldValue> {}

export type FormFieldValue =
  | DateFormFieldValue
  | EmailFormFieldValue
  | FileFormFieldValue
  | HiddenFormFieldValue
  | MembersFormFieldValue
  | MultiChoiceFormFieldValue
  | MultiSelectFormFieldValue
  | NumberFormFieldValue
  | SelectFormFieldValue
  | SendRichEmailFormFieldValue
  | SnippetFormFieldValue
  | TableFormFieldValue
  | TextFormFieldValue
  | TextareaFormFieldValue
  | UrlFormFieldValue;

/** Can't use enums as an index type, so we do a big ternary check, this allows nice tight type coupling like
 * ```tsx
 * type FormFieldMachineBuilderProps<
 *   W extends FormFieldWidget,
 *   FFV extends FormFieldValueByFieldType<W['fieldType']> = FormFieldValueByFieldType<W['fieldType']>,
 * > = {
 *   widget: W;
 *   formFieldValue: FFV;
 * };
 * ```
 * And FFV will be scoped to the widget field type
 **/
export type FormFieldValueByFieldType<FT extends FieldType> = FT extends FieldType.Date
  ? DateFormFieldValue
  : FT extends FieldType.Email
  ? EmailFormFieldValue
  : FT extends FieldType.File
  ? FileFormFieldValue
  : FT extends FieldType.Hidden
  ? HiddenFormFieldValue
  : FT extends FieldType.Members
  ? MembersFormFieldValue
  : FT extends FieldType.MultiChoice
  ? MultiChoiceFormFieldValue
  : FT extends FieldType.MultiSelect
  ? MultiSelectFormFieldValue
  : FT extends FieldType.Number
  ? NumberFormFieldValue
  : FT extends FieldType.Select
  ? SelectFormFieldValue
  : FT extends FieldType.SendRichEmail
  ? SendRichEmailFormFieldValue
  : FT extends FieldType.Snippet
  ? SnippetFormFieldValue
  : FT extends FieldType.Table
  ? TableFormFieldValue
  : FT extends FieldType.Text
  ? TextFormFieldValue
  : FT extends FieldType.Textarea
  ? TextareaFormFieldValue
  : FT extends FieldType.Url
  ? UrlFormFieldValue
  : never;

export type DefaultableFormFieldValue = Extract<FormFieldValue, { fieldValue: { hasDefaultValue?: boolean } }>;
export type DefaultableFormFieldWidget = Extract<FormFieldWidget, { config: Partial<FormFieldConfigWithDefaultValue> }>;

export type FormFieldValueWithWidget<V = FormFieldValue, W = FormFieldWidget> = V & { formFieldWidget: W };

export type FormFieldValueWithWidgetMap = Record<Widget['header']['id'], FormFieldValueWithWidget>;

export interface FormFieldValueUpdateRequest extends Partial<SimpleFieldValue>, Partial<MultiOptionFieldValue> {
  id: Muid;
  widgetId: Muid;
  formFieldValueId?: Muid;
}

export interface FormFieldValueUpdateResult {
  formFieldValue: FormFieldValue;
  checklistTaskAssignments: {
    created: TaskAssignment[];
    deleted: TaskAssignment[];
  };
  checklistWidgets: ChecklistWidget[];
  dueDateTaskStates: DueDateState[];
  tasks: Task[];
  tasksAffectedByTaskPermits: {
    permitted: Task[];
    forbidden: Task[];
  };
}
