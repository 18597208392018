import { Muid } from '@process-street/subgrade/core';
import { TemplateRevisionWithTemplate } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import {
  useQuery as useRQ,
  QueryKey,
  UseQueryOptions,
  QueryClient,
  QueryObserverOptions,
  QueryObserver,
} from 'react-query';
import { axiosService } from 'services/axios-service';

export type TemplateRevisionQueryParams = { templateRevisionId: Muid };

export type TemplateRevisionQueryResponse = TemplateRevisionWithTemplate;

export const TemplateRevisionQuery = {
  key: ['template-revisions'],
  getKey: (params: TemplateRevisionQueryParams): QueryKey => [...TemplateRevisionQuery.key, params],
  queryFn: ({ templateRevisionId }: TemplateRevisionQueryParams) =>
    axiosService
      .getAxios()
      .get<TemplateRevisionQueryResponse>(`/1/template-revisions/${templateRevisionId}`)
      .then(res => res.data),
};

export const useTemplateRevisionQuery = <Select = TemplateRevisionQueryResponse>(
  params: TemplateRevisionQueryParams,
  options: UseQueryOptions<TemplateRevisionQueryResponse, AxiosError, Select> = {},
) => {
  return useRQ(TemplateRevisionQuery.getKey(params), () => TemplateRevisionQuery.queryFn(params), options);
};

export namespace GetTemplateRevisionQuery {
  export type Params = {
    templateRevisionId: Muid;
  };
  export type Response = TemplateRevisionWithTemplate;
  export const key = ['template-revisions'];
  export const getKey = ({ templateRevisionId }: Params): QueryKey => [...key, templateRevisionId];

  export const queryFn = ({ templateRevisionId }: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/template-revisions/${templateRevisionId}`)
      .then(res => res.data);
  export const useQuery = (params: Params, options?: UseQueryOptions<Response, AxiosError>) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.templateRevisionId) && options?.enabled !== false,
    });
  };

  export const makeQueryObserver = ({
    queryClient,
    options,
    ...params
  }: GetTemplateRevisionQuery.Params & {
    queryClient: QueryClient;
    options: QueryObserverOptions<GetTemplateRevisionQuery.Response, AxiosError>;
  }) => {
    return new QueryObserver<GetTemplateRevisionQuery.Response, AxiosError>(queryClient, {
      queryKey: GetTemplateRevisionQuery.getKey(params),
      queryFn: () => GetTemplateRevisionQuery.queryFn(params),
      ...options,
      enabled: Boolean(params.templateRevisionId) && options?.enabled !== false,
    });
  };
}
