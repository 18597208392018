import * as React from 'react';
import { Icon, IconButton, IconButtonProps, useBoolean, useToast } from 'components/design/next';
import { useChartBoxContext } from './context';
import html2canvas from 'html2canvas';
import { DIALOG_ROLE } from './constants';
import { useChecklistScreenState } from 'components/dashboard/components/checklist/ChecklistDashboardScreen/checklist-screen-state';
import { match } from 'ts-pattern';
import { dayjs } from '@process-street/subgrade/util';
import { downloadUrl } from './download-url';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export const CHART_DOWNLOAD_TIMEOUT = 100;

export const ChartBoxDownloadButton: React.FC<React.PropsWithChildren<Omit<IconButtonProps, 'aria-label'>>> = props => {
  const { isExpanded, chartName } = useChartBoxContext();
  const [isDownloading, setIsDownloading] = useBoolean();
  const toast = useToast();
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const { templates, editedSavedView } = useChecklistScreenState();

  const download = () => {
    setIsDownloading.on();
    if (!btnRef.current) {
      setIsDownloading.off();
      toast({
        status: 'warning',
        title: 'Please try again',
      });
      return;
    }

    const chart = btnRef.current.closest(`[role=${DIALOG_ROLE}]`) as HTMLElement;
    if (!chart) {
      setIsDownloading.off();
      toast({
        title: "We're having problems downloading the chart",
        description: DefaultErrorMessages.unexpectedErrorDescription,
        status: 'error',
      });
      return;
    }

    if (chart) {
      // html2canvas promise is pretty heavy load on the dom, so moving it off the main thread via setTimeout
      setTimeout(() => {
        html2canvas(chart)
          .then(canvas => {
            const url = canvas.toDataURL();
            const templateIds = editedSavedView?.filters.selectedTemplates;
            const templateNames = match(templateIds ?? [])
              .with([], () => 'All workflows')
              .when(
                ids => ids.length > 2,
                ids => `${ids.length} workflows`,
              )
              .otherwise(() =>
                templates
                  ?.filter(t => editedSavedView?.filters.selectedTemplates.includes(t.id))
                  .map(t => t.name)
                  .join('&'),
              );
            const filename = `${templateNames} ${chartName} ${dayjs().format('DD MMM YYYY')}`.replace(/\s+/g, '-');

            downloadUrl({ url, filename });
          })
          .catch(() => {
            toast({
              title: "We're having problems downloading the chart",
              description: DefaultErrorMessages.unexpectedErrorDescription,
              status: 'error',
            });
          })
          .finally(() => {
            setIsDownloading.off();
          });
        // Setting a low timeout to let React re-render with the new downloading state for the loading indicator
      }, CHART_DOWNLOAD_TIMEOUT);
    }
  };

  return isExpanded ? (
    <IconButton
      isLoading={isDownloading}
      ref={btnRef}
      variant="ghost"
      color="gray.500"
      onClick={download}
      icon={<Icon icon="arrow-to-bottom" size="4" variant="far" />}
      aria-label="download chart"
      {...props}
    />
  ) : null;
};
