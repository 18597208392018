import * as React from 'react';
import {
  Modal,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  ModalContent,
  Text,
  Image,
  HStack,
  Button,
  VStack,
  Box,
  Link,
} from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { useSelector } from 'react-redux';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { isAdmin } from '@process-street/subgrade/core';

export type DueDateUpgradeModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const DueDateUpgradeModal: React.FC<React.PropsWithChildren<DueDateUpgradeModalProps>> = ({
  isOpen,
  onClose,
}) => {
  const { $state } = useInjector('$state');
  const om = useSelector(OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId);
  const userIsAdmin = om ? isAdmin(om) : false;

  const goToBillingPage = () => {
    $state.go('organizationManage.tab', { tab: 'billing' });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent pt="0">
        <ModalHeader>
          <Text color="gray.500" display="flex" alignItems="center">
            Upgrade to use Dynamic Due Dates
            <Text as="span" variant="-2" color="white" backgroundColor="green.500" padding={1} borderRadius={4} ml={2}>
              Standard
            </Text>
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={4}>
          <HStack justifyContent="center">
            <Image src={require('app/images/task-template/conditional-due-dates-upgrade.svg')} />
          </HStack>

          <Box marginY={8}>
            <Text textAlign="center">
              Set due dates{' '}
              <Text as="span" fontWeight="bold">
                before or after
              </Text>{' '}
              important dates in your process. Make sure work gets done on time without having to remind anyone.
            </Text>
          </Box>

          <VStack>
            {userIsAdmin ? (
              <Button onClick={goToBillingPage}>Upgrade to Standard</Button>
            ) : (
              <Text>Contact an administrator to upgrade your plan.</Text>
            )}

            <Link href="https://www.process.st/help/docs/dynamic-due-dates/" target="_blank" rel="noopener noreferrer">
              Learn more &raquo;
            </Link>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
