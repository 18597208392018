import { NotificationConfig } from '@process-street/subgrade/core';
import {} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace NotificationConfigMutation {
  export type Params = NotificationConfig;

  export type Response = NotificationConfig;

  export const key = ['put', 'user', 'notification', 'config'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/users/me/notification-config`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
