import * as React from 'react';
import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from 'components/design/next';
import { RunViaEmailTabPanel } from 'features/run-via-email';
import { Template } from '@process-street/subgrade/process';

export type RunViaEmailModalProps = {
  templateId: Template['id'];
};

export const RunViaEmailButton: React.FC<React.PropsWithChildren<RunViaEmailModalProps>> = ({
  templateId,
  ...props
}) => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        variant="tertiary"
        size="sm"
        leftIcon={<Icon icon="envelope" size="3" variant="far" />}
        fontSize="xs"
        onClick={onOpen}
      >
        Run via email
      </Button>
      <Modal size="3xl" {...{ ...props, isOpen, onClose }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between" pt={6} px={8}>
            <HStack spacing="2">
              <Icon variant="far" icon="envelope" size="4" color="gray.500" />
              <Text variant="2" color="gray.700" fontWeight="bold">
                Run Via Email
              </Text>
            </HStack>
            <ModalCloseButton position="static" />
          </ModalHeader>
          <ModalBody px={8} pb={6}>
            <RunViaEmailTabPanel templateId={templateId} onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
