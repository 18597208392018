import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { BillingTabService } from 'pages/organizations/manage/billing/services/billing-tab-service';
import {
  BillingTabStripeWrapper,
  PlanBillingSummaryWrapper,
} from 'pages/organizations/manage/billing/components/billing-tab/component';
import { StartupPlanAlertWrapper } from './components/billing-tab/startup';

export const BillingTabModule = angular
  .module('billingTab', [])
  .component(
    'psFreemiumBillingTab',
    reactToAngular(BillingTabStripeWrapper, ['currentPlan', 'discount', 'organization']),
  )
  .component(
    'psPlanBillingSummary',
    reactToAngular(PlanBillingSummaryWrapper, ['currentPlan', 'discount', 'organization']),
  )
  .component('psStartupPlanAlert', reactToAngular(StartupPlanAlertWrapper, ['organization']))
  .service('BillingTabService', BillingTabService).name;
