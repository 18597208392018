import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateApiKeyMutation {
  export type Params = {
    apiKeyId: Muid;
    changes: Change;
    id?: Muid; // Organization Id
  };

  type Change = {
    label: string;
  };

  type UpdateApiKeyResponse = {
    updatedDate: number;
  };

  export type Response = UpdateApiKeyResponse;

  export const key = ['organization', 'api-keys'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .patch<Response>(`/1/organizations/${params.id}/api-keys/${params.apiKeyId}`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
