import angular from 'angular';

angular.module('frontStreetApp.directives').directive('psPopup', $window => ({
  link(__scope, element) {
    element.on('click', event => {
      const url = element.attr('href');
      $window.open(
        url,
        'share',
        'height=340, width=600, toolbar=no, menubar=no, scrollbars=no, ' +
          'resizable=no, location=no, directories=no, status=no',
      );
      event.preventDefault();
    });
  },
}));
