import * as React from 'react';
import { Button, Text, VStack } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { Shell } from '../components/shell';
import { ShellContent } from '../components/shell-content';
import { ShellLeftPane } from '../components/shell-left-pane';
import { Title } from '../components/title';
import { ShellContentActions } from '../components/shell-content-actions';
import { useFeatureFlag } from 'features/feature-flags';
import { useRedirectToAppEntryPoint } from 'pages/sign-up/use-redirect-to-app-entry-point';

export const ConsultationPrompt: React.VFC = () => {
  const { $state } = useInjector('$state');
  const hideMaybeLaterButton = useFeatureFlag('hideSkipConsultation');

  const { redirectToAppEntryPoint } = useRedirectToAppEntryPoint();

  const maybeLaterButton = (
    <Button
      variant="ghost"
      px="5"
      height="12"
      colorScheme="gray"
      color="gray.500"
      fontWeight="medium"
      onClick={redirectToAppEntryPoint}
    >
      Maybe Later
    </Button>
  );

  return (
    <Shell alignItems={{ base: 'center', lg: 'center' }}>
      <ShellLeftPane />

      <ShellContent minHeight={{ base: 'unset', lg: '100vh' }} maxWidth={{ base: 'xl', lg: '3xl' }}>
        <VStack spacing="8" alignItems="center" maxWidth="100%" w="full">
          <VStack>
            <Title>Book a discovery call</Title>
          </VStack>

          <VStack minH="303px" w="full" color="gray.600" alignItems="flex-start" spacing="6">
            <Text fontWeight="bold">We'd love to learn about your challenges and goals,</Text>

            <Text>
              At Process Street, we're all about getting to know what makes your business tick. Why not schedule a demo
              with our Sales team? It's a great opportunity to see how our platform can align with your goals and drive
              success.
            </Text>
          </VStack>

          <ShellContentActions>
            {!hideMaybeLaterButton && maybeLaterButton}

            <Button
              onClick={() => $state.go('newCompleteSignUp.consultation')}
              autoFocus={true}
              px="5"
              height="12"
              iconSpacing="2"
              type="submit"
            >
              Schedule Now
            </Button>
          </ShellContentActions>
        </VStack>
      </ShellContent>
    </Shell>
  );
};
