import angular from 'angular';
import { StringUtils } from '@process-street/subgrade/util';

angular.module('frontStreetApp.filters').filter('highlight', [
  '$sce',
  function ($sce) {
    // Based on https://github.com/ag-grid/ag-grid/issues/839#issuecomment-211279856
    return function (text, search, caseSensitive) {
      if (text && (search || angular.isNumber(search))) {
        let updText = text.toString();
        const searchValue = search.toString();
        if (caseSensitive) {
          updText = updText.split(searchValue).join(`<span class="highlight">${searchValue}</span>`);
        } else {
          updText = updText.replace(
            new RegExp(StringUtils.escapeRegExp(searchValue), 'gi'),
            '<span class="highlight">$&</span>',
          );
        }
        return $sce.trustAsHtml(updText);
      } else {
        return text;
      }
    };
  },
]);
