import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { TemplateShareButton } from './';

export const TemplateShareButtonModule = angular
  .module('templateShareButton', [])
  .component(
    'psTemplateShareButton',
    reactToAngular(TemplateShareButton, ['templateId', 'isVisible', 'isDisabled']),
  ).name;
