import { DataSet } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateDataSetFromCsvMutationParams = {
  file: File;
};

export type CreateDataSetFromCsvMutationResponse = DataSet;

export const CreateDataSetFromCsvMutation = {
  mutationFn: async (params: CreateDataSetFromCsvMutationParams) => {
    const formData = new FormData();

    formData.append('file', params.file);

    return axiosService
      .getAxios()
      .post<CreateDataSetFromCsvMutationResponse>(`/1/data-sets/create-from-csv`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => res.data);
  },
};

export const useCreateDataSetFromCsvMutation = (
  options: UseMutationOptions<CreateDataSetFromCsvMutationResponse, AxiosError, CreateDataSetFromCsvMutationParams>,
) => {
  return useMutation(CreateDataSetFromCsvMutation.mutationFn, options);
};
