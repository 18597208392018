import React, { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';
import { DropzoneState, useDropzone } from 'react-dropzone';

import { AttachmentType, Checklist, Task } from '@process-street/subgrade/process';

import { useFinishUploadAttachmentMutation, useUploadUrlAttachmentMutation } from 'features/comments/query-builder';

import { useUploadToS3Mutation } from 'features/upload/query-builder';
import { useInjector } from 'components/injection-provider';
import { AttachmentEvent } from 'services/events/attachment-event';

import sum from 'lodash/sum';

export const SIZE_TOO_LARGE = 'size-too-large';
export const MAX_FILE_SIZE = 250 * 1024 * 1024;

type UploadAttachmentReturn = {
  dropzoneState: DropzoneState;
  uploadError: AxiosError | null;
};

type UploadAttachmentCommentProps = {
  taskId: Task['id'];
  checklistId: Checklist['id'];
  onFinish: () => void;
  setUploadingCount: (count: number) => void;
  setProgress: (progress: number | undefined) => void;
  isDropDisabled: boolean;
  shouldBroadcastEvents: boolean;
  attachmentType?: AttachmentType;
};

export const useUploadAttachmentComment = ({
  taskId,
  checklistId,
  onFinish,
  setUploadingCount,
  setProgress,
  isDropDisabled,
  shouldBroadcastEvents,
  attachmentType,
}: UploadAttachmentCommentProps): UploadAttachmentReturn => {
  const { $rootScope } = useInjector('$rootScope');
  const uploadUrlMutation = useUploadUrlAttachmentMutation();

  const uploadToS3Mutation = useUploadToS3Mutation();

  const finishUploadAttachmentMutation = useFinishUploadAttachmentMutation({
    onSuccess: () => {
      // No need to broadcast if attaching to one off task
      if (shouldBroadcastEvents) {
        $rootScope.$broadcast(AttachmentEvent.ATTACHMENT_CREATE_OK, taskId, checklistId);
      }
    },
    onError: () => {
      if (shouldBroadcastEvents) {
        $rootScope.$broadcast(AttachmentEvent.ATTACHMENT_CREATE_FAILED, taskId, checklistId);
      }
    },
  });

  const uploadError = uploadUrlMutation.error ?? uploadToS3Mutation.error ?? finishUploadAttachmentMutation.error;

  useEffect(() => {
    if (uploadError) {
      setProgress(undefined);
      setUploadingCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query error
  }, [uploadError]);

  const fileProgressesRef = React.useRef(new Map<File, number>());
  const resetFileProgresses = () => (fileProgressesRef.current = new Map());

  const setProgressForFile = (file: File, progress: number) => {
    fileProgressesRef.current.set(file, progress);
    const files = Array.from(fileProgressesRef.current.keys());
    const totalFileSize = sum(files.map(file => file.size));
    const relativeProgresses = Array.from(fileProgressesRef.current.entries()).map(
      ([file, progress]) => (file.size / totalFileSize) * progress,
    );
    const totalProgress = sum(relativeProgresses);
    setProgress(totalProgress);
  };

  const uploadSingleFile = async (file: File) => {
    setProgressForFile(file, 0);

    const mimeType = file.type || 'application/octet-stream';

    const { url, key } = await uploadUrlMutation.mutateAsync({
      taskId,
      fileName: file.name,
      mimeType,
    });

    const fileBuffer = await readFile(file);

    await uploadToS3Mutation.mutateAsync({
      file,
      url,
      data: fileBuffer,
      onProgress: (progress: number) => setProgressForFile(file, progress),
    });

    await finishUploadAttachmentMutation.mutateAsync({
      taskId,
      contentType: mimeType,
      originalFilename: file.name,
      key,
      attachmentType,
    });

    setProgressForFile(file, 100);
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      resetFileProgresses();
      setUploadingCount(acceptedFiles.length);
      await Promise.all(acceptedFiles.map(uploadSingleFile));
      resetFileProgresses();
      onFinish();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore local uploadSingleFile
    [onFinish, setUploadingCount],
  );

  const dropzoneState = useDropzone({
    onDrop,
    noDrag: isDropDisabled,
    validator: file => {
      if (file.size > MAX_FILE_SIZE) {
        return {
          code: SIZE_TOO_LARGE,
          message: `Uploaded files must be under ${MAX_FILE_SIZE} MB.`,
        };
      }
      return null;
    },
  });

  return {
    dropzoneState,
    uploadError,
  };
};

export function readFile(file: File): Promise<ArrayBuffer> {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);

  return new Promise((resolve, reject) => {
    reader.onload = async () => {
      if (reader.readyState === 2 && reader.result !== null) {
        resolve(reader.result as ArrayBuffer);
      } else {
        reject(reader.error);
      }
    };
  });
}
