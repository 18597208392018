import { handleActionsOnSuccess } from 'reducers/util';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';

import { combineReducers } from 'redux';
import { TASK_TEMPLATE_DELETE_ALL, TASK_TEMPLATE_UPDATE } from '../../../reducers/task-template/task-template.actions';
import { WIDGET_DELETE_BY_HEADER_ID } from '../../widgets/store/widget.actions';
import {
  DYNAMIC_DUE_DATE_DELETE_ALL_BY_IDS,
  DYNAMIC_DUE_DATE_GET_ALL_BY_TEMPLATE_REVISION,
  DYNAMIC_DUE_DATE_GET_ALL_FILTERED_BY_CHECKLIST_REVISION,
  DYNAMIC_DUE_DATE_REMOVE_ALL_BY_IDS,
  DYNAMIC_DUE_DATE_UPSERT_ALL,
} from './dynamic-due-dates.actions';

export const dynamicDueDatesReducer = handleActionsOnSuccess(
  {
    [DYNAMIC_DUE_DATE_GET_ALL_BY_TEMPLATE_REVISION]: (state, { payload }) =>
      EntitiesReducerUtils.upsertAll(state, payload),
    [DYNAMIC_DUE_DATE_GET_ALL_FILTERED_BY_CHECKLIST_REVISION]: (state, { payload }) =>
      EntitiesReducerUtils.upsertAll(state, payload),
    [DYNAMIC_DUE_DATE_DELETE_ALL_BY_IDS]: (state, { payload }) => EntitiesReducerUtils.deleteAll(state, payload),
    [DYNAMIC_DUE_DATE_REMOVE_ALL_BY_IDS]: (state, { payload: { ids } }) =>
      EntitiesReducerUtils.deleteAllByIds(state, ids),
    [DYNAMIC_DUE_DATE_UPSERT_ALL]: (state, { payload }) => EntitiesReducerUtils.upsertAll(state, payload),
    [WIDGET_DELETE_BY_HEADER_ID]: (state, { meta: { dddRules } }) => EntitiesReducerUtils.deleteAll(state, dddRules),
    [TASK_TEMPLATE_UPDATE]: (state, { payload: { deletedRulesIds } }) =>
      EntitiesReducerUtils.deleteAllByIds(state, deletedRulesIds),
    [TASK_TEMPLATE_DELETE_ALL]: (state, { meta: { dddRules } }) => EntitiesReducerUtils.deleteAll(state, dddRules),
  },
  {},
);

const dynamicDueDatesLookupByTemplateRevisionIdReducer = handleActionsOnSuccess(
  {
    [DYNAMIC_DUE_DATE_GET_ALL_BY_TEMPLATE_REVISION]: (state, { payload }) =>
      LookupsReducerUtils.replaceAll(state, payload, 'templateRevision.id', 'id'),
    [DYNAMIC_DUE_DATE_DELETE_ALL_BY_IDS]: (state, { payload }) =>
      LookupsReducerUtils.deleteAll(state, payload, 'templateRevision.id', 'id'),
    [DYNAMIC_DUE_DATE_REMOVE_ALL_BY_IDS]: (state, { payload: { taskTemplateId, ids } }) =>
      LookupsReducerUtils.deleteAllByIds(state, taskTemplateId, ids),
    [DYNAMIC_DUE_DATE_UPSERT_ALL]: (state, { payload }) =>
      LookupsReducerUtils.upsertAll(state, payload, 'templateRevision.id', 'id'),
    [WIDGET_DELETE_BY_HEADER_ID]: (state, { meta: { dddRules, templateRevisionId } }) =>
      LookupsReducerUtils.deleteAllUsingSelectorFunctions(state, dddRules, () => templateRevisionId),
    [TASK_TEMPLATE_UPDATE]: (state, { payload: { taskTemplate, deletedRulesIds } }) =>
      LookupsReducerUtils.deleteAllByIds(state, taskTemplate.templateRevision.id, deletedRulesIds),
    [TASK_TEMPLATE_DELETE_ALL]: (state, { meta: { templateRevisionId, dddRules } }) =>
      LookupsReducerUtils.deleteAllByIds(
        state,
        templateRevisionId,
        dddRules.map(rule => rule.id),
      ),
  },
  {},
);

const dynamicDueDatesLookupByChecklistRevisionIdReducer = handleActionsOnSuccess(
  {
    [DYNAMIC_DUE_DATE_GET_ALL_FILTERED_BY_CHECKLIST_REVISION]: (state, { payload, meta: { checklistRevisionId } }) =>
      LookupsReducerUtils.replaceAllUsingSelectorFunctions(state, payload, () => checklistRevisionId),
  },
  {},
);

export const dynamicDueDatesLookupsReducer = combineReducers({
  byTemplateRevisionId: dynamicDueDatesLookupByTemplateRevisionIdReducer,
  byChecklistRevisionId: dynamicDueDatesLookupByChecklistRevisionIdReducer,
});
