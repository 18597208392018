import * as React from 'react';
import { Button, FormControl, FormLabel, Input, ModalProps, useToast } from 'components/design/next';
import { Folder } from '@process-street/subgrade/process';
import { MuidUtils } from '@process-street/subgrade/core';
import { FolderPickerModal, PickButtonProps, PickerAddonTop, PickerLabel } from 'components/folder-picker/modal';
import { GetAllFoldersByOrganizationId, useCreateFolderMutation } from 'features/folder/query-builder';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { FOLDER_SET } from 'reducers/folder/folder.actions';
import { GetFoldersPermissionStatsByOrganization } from 'components/permissions/services/query';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { GetAllConsolidatedFolderPermissions } from 'features/permissions/query-builder';
import { AnalyticsService } from 'components/analytics/analytics.service';

export type Props = Omit<ModalProps, 'children'> & { selectedOrganizationId: string; currentFolderId: string };

export const NewFolderModal: React.FC<React.PropsWithChildren<Props>> = ({
  selectedOrganizationId,
  currentFolderId,
  ...modalProps
}) => {
  const [name, setName] = React.useState('');

  const toast = useToast();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { mutate, isLoading } = useCreateFolderMutation({
    onSuccess: createdFolder => {
      toast({
        title: 'Folder created',
        status: 'success',
      });

      dispatch({ type: FOLDER_SET, payload: createdFolder });

      queryClient.invalidateQueries(GetAllFoldersByOrganizationId.getKey(selectedOrganizationId));
      queryClient.invalidateQueries(GetFoldersPermissionStatsByOrganization.getKey(selectedOrganizationId));
      queryClient.invalidateQueries(GetAllConsolidatedFolderPermissions.getKey());

      AnalyticsService.trackEvent('folder created', {
        id: createdFolder.id,
        name: createdFolder.name,
      });

      modalProps.onClose();
      setName('');
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems creating the folder",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const create = (inFolder: Folder) => {
    mutate({
      id: MuidUtils.randomMuid(),
      parentFolderId: inFolder.id,
      name,
    });
  };

  const nameRef = React.useRef<HTMLInputElement>(null);

  const PickButton: React.FC<React.PropsWithChildren<PickButtonProps>> = ({ pickType, ...props }) => (
    <Button isLoading={isLoading} isDisabled={!name} {...props}>
      Create
      {pickType === 'to_private' ? ' in Private' : null}
      {pickType === 'to_organization' ? ' in Organization' : null}
    </Button>
  );

  return (
    <FolderPickerModal
      title="Create folder"
      initialFolderId={currentFolderId}
      verb="add"
      pickButton={PickButton}
      onPick={create}
      organizationId={selectedOrganizationId}
      initialFocusRef={nameRef}
      {...modalProps}
    >
      <PickerAddonTop>
        <FormControl id="folder name" mb="6">
          <FormLabel>Name</FormLabel>
          <Input
            value={name}
            onChange={e => setName(e.target.value)}
            variant="outline"
            placeholder="Folder name"
            ref={nameRef}
          />
        </FormControl>
      </PickerAddonTop>

      <PickerLabel>
        <FormLabel>Location</FormLabel>
      </PickerLabel>
    </FolderPickerModal>
  );
};
