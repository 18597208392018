import * as React from 'react';
import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  UseDisclosureReturn,
} from 'components/design/next';
import { useSelectionBarActionsProvider } from './selection-bar-actions-context';
import { Identifiable } from '@process-street/subgrade/core';

const commonButtonProps: ButtonProps = {
  variant: 'ghost',
  color: 'white',
  borderRadius: 'none',
  minH: '14',
  _hover: {
    bgColor: 'gray.600',
  },
  _last: {
    borderTopRightRadius: 'full',
    borderBottomRightRadius: 'full',
    pr: '6',
  },
};

export type SelectionBarActionsButtonProps<T = unknown> = ButtonProps & {
  confirmationTitle?: React.ReactNode;
  confirmationBody?: React.ReactNode;
  confirmationButtonText?: React.ReactNode;
  confirmationButtonProps?: Partial<ButtonProps>;
  isLoading?: boolean;
  onConfirm: (selectedItems: T[]) => Promise<unknown>;
  /* A modal to replace the default modal */
  modal?: (renderProps: UseDisclosureReturn) => React.ReactElement | null;
  skipConfirmation?: boolean;
};

export const SelectionBarActionsButton = <T extends Identifiable = Identifiable>({
  children,
  confirmationTitle,
  confirmationBody,
  confirmationButtonProps,
  confirmationButtonText,
  isLoading,
  onConfirm,
  modal,
  skipConfirmation,
  ...props
}: React.PropsWithChildren<SelectionBarActionsButtonProps<T>>) => {
  const modalDisclosure = useDisclosure();
  const { selectedItems } = useSelectionBarActionsProvider();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onClick?.(e);
    if (skipConfirmation) {
      onConfirm(selectedItems as T[]);
    } else {
      modalDisclosure.onOpen();
    }
  };

  const handleConfirm = async () => {
    try {
      await onConfirm(selectedItems as T[]);
      modalDisclosure.onClose();
    } catch {
      // noop
    }
  };

  return (
    <>
      <Button
        {...commonButtonProps}
        borderLeft="1px solid"
        borderColor="gray.300"
        borderRight="1px solid"
        minW="30"
        leftIcon={<Icon icon="undo" size="4" />}
        iconSpacing="2"
        data-testid="unarchive"
        {...props}
        onClick={handleClick}
      >
        {children}
      </Button>

      {modal ? (
        modal(modalDisclosure)
      ) : (
        <Modal {...modalDisclosure}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="lg" color="gray.700" fontWeight="bold">
              {confirmationTitle}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody color="gray.600" fontWeight="500">
              {confirmationBody}
            </ModalBody>
            <ModalFooter as={HStack} spacing="3">
              <Button isDisabled={isLoading} onClick={modalDisclosure.onClose} colorScheme="gray" variant="ghost">
                Cancel
              </Button>
              <Button onClick={handleConfirm} isLoading={isLoading} {...confirmationButtonProps}>
                {confirmationButtonText}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
