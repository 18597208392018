import * as React from 'react';
import { Alert, AlertDescription, AlertIcon, AlertStatus, AlertTitle, Icon } from 'components/design/next';
import { IconName } from '@fortawesome/fontawesome-common-types';

export type PlanAlertProps = {
  title?: string;
  status?: AlertStatus;
  iconName?: IconName;
  iconColor?: string;
};

export const PlanAlert: React.FC<React.PropsWithChildren<PlanAlertProps>> = ({
  title,
  status,
  iconName,
  iconColor,
  children,
}) => {
  return (
    <Alert status={status} variant="subtle" borderRadius="8px" mb={6} p={4}>
      {iconName && <Icon icon={iconName} variant="fas" size="5" mr={3} color={iconColor} />}
      {!iconName && <AlertIcon />}
      {title && <AlertTitle mr={2}>{title}</AlertTitle>}
      {children && <AlertDescription>{children}</AlertDescription>}
    </Alert>
  );
};
