import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

// we're only using a single instance of this so that different hook instances don't get out of sync
export const useShowErrors = () => {
  const { submitCount, isValid, values, validateForm } = useFormikContext();

  const [lastSubmitCount, setLastSubmitCount] = useState(0);

  // allow yup 400ms for validation
  const [debouncedSubmitCount] = useDebounce(submitCount, 400);

  useEffect(() => {
    // Validate the whole form on user edits only when it is invalid.
    // (Formik always validates on submission)
    // This way the user will get feedback while they fix the issues.
    if (!isValid) validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- validate on rules change
  }, [(values as any).rules, isValid]);

  useEffect(() => {
    // We keep the submitCount in sync only when the form is valid. So we only show errors when
    // the user tries to submit the rules with an error, once the error is fixed, the error is not shown
    // anymore.
    if (isValid) {
      setLastSubmitCount(submitCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on isValid change
  }, [isValid]);

  const result = lastSubmitCount !== debouncedSubmitCount && !isValid;

  return result;
};
