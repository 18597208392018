import { DataSet, SavedView } from '@process-street/subgrade/process';
import { createUsableContext } from '@process-street/subgrade/util';

export type SelectedSavedViewContextValue = [SavedView | null, React.Dispatch<React.SetStateAction<SavedView | null>>];

export const [useSelectedSavedViewContext, SelectedSavedViewContext] =
  createUsableContext<SelectedSavedViewContextValue>({
    hookName: 'useSelectedSavedViewContext',
    providerName: 'SelectedSavedViewContext',
  });

export type SelectedDataSetContextValue = [DataSet | null, React.Dispatch<React.SetStateAction<DataSet | null>>];

export const [useSelectedDataSetContext, SelectedDataSetContext] = createUsableContext<SelectedDataSetContextValue>({
  hookName: 'useSelectedDataSetContext',
  providerName: 'SelectedDataSetContext',
});
