import angular from 'angular';
import { GroupType } from '@process-street/subgrade/core';

class UserManageGroupsCtrl {
  constructor($scope, $state, GroupMembershipService, OrganizationMembershipService, UserService) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.GroupMembershipService = GroupMembershipService;
    this.OrganizationMembershipService = OrganizationMembershipService;
    this.UserService = UserService;
  }

  $onInit = () => {
    const userId = this.$state.params.id === 'me' ? this.UserService.getCurrentUser().id : this.$state.params.id;
    const sessionUser = this.UserService.getCurrentUser();

    this.UserService.getById(userId).then(user => {
      this.$scope.user = user;
    });

    this.OrganizationMembershipService.isAdminByUserIdAndOrganizationId(
      sessionUser.id,
      this.$scope.organization.id,
    ).then(userIsAdmin => {
      this.$scope.sessionUserIsAdmin = userIsAdmin;
    });

    this.loadGroups(userId);
  };

  loadGroups = userId => {
    this.$scope.groupsLoading = true;
    this.GroupMembershipService.getAllByOrganizationMembershipUserId(userId)
      .then(({ payload: memberships }) => {
        this.$scope.groups = memberships
          .filter(membership => membership.group.groupType === GroupType.Standard)
          .map(membership => membership.group);
      })
      .finally(() => {
        this.$scope.groupsLoading = false;
      });
  };
}

angular.module('frontStreetApp.controllers').controller('UserManageGroupsCtrl', UserManageGroupsCtrl);
