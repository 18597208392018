import * as yup from 'yup';
import { isPartialUrl } from '@process-street/subgrade/process';
import { createTestFunction } from '../helpers';

export const passwordValidationMsg = 'Invalid password';
export const usernameValidationMsg = 'Invalid full name';

export const validatePasswordSize = createTestFunction(p => p.length >= 8);
export const validatePasswordNumbers = createTestFunction(p => /[0-9]/.test(p));
export const validatePasswordLowercase = createTestFunction(p => /[a-z]/.test(p));
export const validatePasswordUppercase = createTestFunction(p => /[A-Z]/.test(p));
export const validatePasswordSpecialChar = createTestFunction(p => /[^A-Za-z0-9]/.test(p));
export const validatePasswordNoWhitespace = createTestFunction(p => !/\s/.test(p));
export const validateUsernameURL = createTestFunction(fullName => !isPartialUrl(fullName));

export const createAccountSchema = yup.object().shape({
  username: yup
    .string()
    .trim()
    .required('Required field')
    .test('Is not an URL', usernameValidationMsg, validateUsernameURL),
  password: yup
    .string()
    .required('Required field')
    .min(8, passwordValidationMsg)
    .test('Contains 1 number', passwordValidationMsg, validatePasswordNumbers)
    .test('Contains 1 lowercase character', passwordValidationMsg, validatePasswordLowercase)
    .test('Contains 1 uppercase character', passwordValidationMsg, validatePasswordUppercase)
    .test('Contains 1 special character', passwordValidationMsg, validatePasswordSpecialChar)
    .test('Contains no whitespace', passwordValidationMsg, validatePasswordNoWhitespace),
});
