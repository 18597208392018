import { ConditionEvaluator } from '@process-street/subgrade/conditional-logic';
import { RuleConstants } from '@process-street/subgrade/conditional-logic/rule-constants';
import { SimpleFieldValue } from '@process-street/subgrade/process';
import { trace } from 'components/trace';
import { OperandTypeResolver } from './operand-type-resolver';

const logger = trace({ name: 'EndsWithCondition' });
export const EndsWithCondition: ConditionEvaluator = {
  evaluate: (formFieldValue, formFieldWidget, operand) => {
    if (!formFieldValue || !formFieldWidget || !operand) return false;

    const operandType = OperandTypeResolver.resolve(formFieldWidget);

    let evaluatedAsTrue = false;

    switch (operandType) {
      case RuleConstants.OperandType.STRING:
        const fieldValue = (formFieldValue?.fieldValue as SimpleFieldValue)?.value;
        evaluatedAsTrue = Boolean(fieldValue?.toString().endsWith(operand.toString()));
        break;
      default:
        logger.error('unsupported operand type: %s', operandType);
    }

    return evaluatedAsTrue;
  },
};
