import * as React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  MenuGroup,
  Box,
  Tooltip,
  Icon,
  MenuItem,
} from 'components/design/next';
import { FormFieldWidget, TaskTemplate } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { useAssociatedAiTaskTemplates } from './use-associated-ai-task-templates';

export interface AiTaskTemplateWidgetIndicatorV2Props {
  widget: FormFieldWidget;
  handleOnSelectTaskTemplate: (taskTemplate: TaskTemplate) => void;
}

export const AiTaskTemplateWidgetIndicatorV2: React.FC<
  React.PropsWithChildren<AiTaskTemplateWidgetIndicatorV2Props>
> = ({ widget, handleOnSelectTaskTemplate }) => {
  const associatedTaskTemplates = useAssociatedAiTaskTemplates({ widget });

  return match(associatedTaskTemplates)
    .with({ inputsTo: [], outputsFrom: [] }, () => null)
    .with({ outputsFrom: [P.select('taskTemplate')], inputsTo: [] }, ({ taskTemplate }) => (
      <Tooltip label="This form field is the output of an AI task" hasArrow>
        <Box>
          <IconButton
            variant="unstyled"
            icon={<Icon icon="sparkles" size="3.5" color="brand.500" />}
            aria-label="AI Task output indicator"
            onClick={() => handleOnSelectTaskTemplate(taskTemplate)}
          />
        </Box>
      </Tooltip>
    ))
    .with({ inputsTo: [P.select('taskTemplate')], outputsFrom: [] }, ({ taskTemplate }) => (
      <Tooltip label="This form field is an input of an AI task" hasArrow>
        <Box>
          <IconButton
            variant="unstyled"
            icon={<Icon icon="sparkles" size="3.5" color="brand.500" />}
            aria-label="AI Task input indicator"
            onClick={() => handleOnSelectTaskTemplate(taskTemplate)}
          />
        </Box>
      </Tooltip>
    ))
    .otherwise(({ inputsTo, outputsFrom }) => (
      <Menu>
        <MenuButton as={IconButton} variant="unstyled" icon={<Icon icon="sparkles" size="3.5" color="brand.500" />} />

        <MenuList>
          {inputsTo.length > 0 && (
            <MenuGroup title="Input to">
              {inputsTo.map(taskTemplate => (
                <MenuItem fontSize="md" key={taskTemplate.id} onClick={() => handleOnSelectTaskTemplate(taskTemplate)}>
                  {taskTemplate.name}
                </MenuItem>
              ))}
            </MenuGroup>
          )}
          {outputsFrom.length > 0 && (
            <MenuGroup title="Output from">
              {outputsFrom.map(taskTemplate => (
                <MenuItem fontSize="md" key={taskTemplate.id} onClick={() => handleOnSelectTaskTemplate(taskTemplate)}>
                  {taskTemplate.name}
                </MenuItem>
              ))}
            </MenuGroup>
          )}
        </MenuList>
      </Menu>
    ));
};
