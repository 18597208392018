import * as React from 'react';
import { PermissionsSelector, PermissionsSelectorProps } from './component';
import { OrganizationMembership } from '@process-street/subgrade/core';
import { AccessLevel } from '@process-street/subgrade/permission';

export const PermissionsSelectorWrapper: React.FC<
  React.PropsWithChildren<
    PermissionsSelectorProps & {
      // This is a bit of a temporary workaround. There is a difficulty to bind the anonymous function as a callback.
      // This allows us to use a fully defined function in the Angular world and still keep the React component pure.
      membership: OrganizationMembership;
      onLevelChange?: (membership: OrganizationMembership, newLevel: AccessLevel) => void;
    }
  >
> = ({ membership, onLevelChange, ...props }) => {
  const handleOnChange = (level: AccessLevel) => {
    if (onLevelChange) {
      onLevelChange(membership, level);
    }
  };

  return <PermissionsSelector {...props} onChange={handleOnChange} />;
};
