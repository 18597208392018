import { EmailMergeTags, StdMergeTagKey, StdMergeTagKeyLegacy } from '@process-street/subgrade/core';
import { MergeTagTarget } from '@process-street/subgrade/form';
import {
  FieldType,
  FormFieldValueWithWidget,
  MembersFormFieldValue,
  MembersFormFieldWidget,
  MultiChoiceFormFieldValue,
  MultiChoiceFormFieldWidget,
  MultiChoiceItemValueStatus,
  SendRichEmailFormFieldValue,
  SendRichEmailFormFieldWidget,
  TableFormFieldValue,
  TableFormFieldWidget,
} from '@process-street/subgrade/process';
import { getTagValuesFromDataSetMergeTags, Tags } from './get-tags-from-keys';
import { DateFieldUtils, DateFormat, DateUtils, LocationService, StringUtils } from '@process-street/subgrade/util';
import { match, P } from 'ts-pattern';
import {
  ResolvedTag,
  ResolvedTagFormat,
  ResolvedTagMeta,
  ResolveTagFactory,
} from '@process-street/subgrade/merge-tags';
import { FORM_FIELD_VALUE_KEY_PREFIX } from './constants';
import { DefaultRecordStoreArgs, makeDefaultRecordStore, RecordStore } from './make-default-record-store';
import { DATA_SET_TRIGGER_PREFIX } from 'features/merge-tags/query-builder';
import { DateContextUtils } from '@process-street/subgrade/core/date-context';
import { TableFormFieldUtils } from '@process-street/subgrade/process/widget-utils/table-form-field-utils';

type OverloadVariants = RecordStore | DefaultRecordStoreArgs;

function isQueryClientOverload(args: OverloadVariants): args is DefaultRecordStoreArgs {
  return (args as DefaultRecordStoreArgs).queryClient !== undefined;
}

export function buildResolvedTags<Target extends MergeTagTarget>(
  args: {
    tags: Tags<Target>;
  } & RecordStore,
): Promise<ResolvedTag[]>;
export function buildResolvedTags<Target extends MergeTagTarget>(
  args: {
    tags: Tags<Target>;
  } & DefaultRecordStoreArgs,
): Promise<ResolvedTag[]>;
export function buildResolvedTags<Target extends MergeTagTarget>({
  tags,
  ...rest
}: {
  tags: Tags<Target>;
} & OverloadVariants): Promise<ResolvedTag[]> {
  const recordStore = isQueryClientOverload(rest) ? makeDefaultRecordStore(rest) : rest;

  return Promise.all(
    Object.entries(tags).map(async ([key, label]) => {
      return resolveMergeTagValueForChecklist(key, recordStore).then(result => {
        const [replacement, meta] = Array.isArray(result) ? result : [result];
        return ResolveTagFactory.asResolvedTag({ key, replacement, label, meta });
      });
    }),
  );
}

type ResolvedValue = string | [string, ResolvedTagMeta] | undefined;

export function resolveMergeTagValueForChecklist(key: string, recordStore: RecordStore): Promise<ResolvedValue> {
  if (key.startsWith(DATA_SET_TRIGGER_PREFIX)) {
    return resolveFormMergeTagValue(key, recordStore);
  } else if (key.startsWith(FORM_FIELD_VALUE_KEY_PREFIX)) {
    return resolveFormMergeTagValue(key.slice(FORM_FIELD_VALUE_KEY_PREFIX.length), recordStore);
  } else {
    return resolveGeneralMergeTagValue(key, recordStore);
  }
}

export async function resolveFormMergeTagValue(key: string, recordStore: RecordStore): Promise<ResolvedValue> {
  const normalizedKey = StringUtils.stripAnySuffix(key, ['.name', '.email']);
  const formFieldValues = await recordStore.getFormFieldValues();

  // Check for data set keys first because .name and .email are valid data set key suffixes
  const dataSetTag = await calculateDataSetMergeTag(key, recordStore);
  if (dataSetTag) {
    return dataSetTag;
  }

  const widgetTagMatch = formFieldValues.find(formFieldValue => {
    return formFieldValue?.formFieldWidget?.key === normalizedKey;
  });
  if (widgetTagMatch) {
    return calculateFormFieldTagValue(key, widgetTagMatch, recordStore);
  }
}

async function calculateRunViaEmailMergeTag(mergeTagKey: keyof EmailMergeTags, recordStore: RecordStore) {
  const emailMergeTags = await recordStore.getEmailMergeTags();
  return emailMergeTags?.[mergeTagKey];
}

async function calculateDataSetMergeTag(mergeTagKey: string, recordStore: RecordStore) {
  try {
    const dataSetMergeTags = await recordStore.getDataSetMergeTags();
    const tags = getTagValuesFromDataSetMergeTags(dataSetMergeTags);

    return mergeTagKey.startsWith(DATA_SET_TRIGGER_PREFIX)
      ? tags[mergeTagKey]
      : tags[FORM_FIELD_VALUE_KEY_PREFIX + mergeTagKey];
  } catch {
    return undefined;
  }
}

async function calculateFormFieldTagValue(
  key: string,
  formFieldValue: FormFieldValueWithWidget,
  recordStore: RecordStore,
): Promise<ResolvedValue> {
  return match<typeof formFieldValue, ResolvedValue | Promise<ResolvedValue>>(formFieldValue)
    .with(
      { formFieldWidget: { fieldType: FieldType.Date }, fieldValue: { value: P.number } },
      async ({ fieldValue }) => {
        const organization = await recordStore.getOrganization();
        const user = await recordStore.getCurrentUser();
        const dateContext = DateContextUtils.getDateContext(organization, user);
        return DateFieldUtils.formatDateFieldValueToShortMonthNoSeconds(fieldValue, dateContext);
      },
    )
    .with(
      { formFieldWidget: { fieldType: FieldType.File }, fieldValue: { url: P.not(P.nullish) } },
      ({ fieldValue }) => fieldValue.url,
    )
    .with({ formFieldWidget: { fieldType: FieldType.MultiChoice } }, convertMultiChoiceValueToPlainText)
    .with(
      { formFieldWidget: { fieldType: FieldType.Members } },
      async (ffv: FormFieldValueWithWidget<MembersFormFieldValue, MembersFormFieldWidget>) => {
        const membershipIds = ffv.fieldValue.organizationMembershipIds ?? [];
        const users = (await recordStore.getOrganizationMemberships())
          .filter(om => membershipIds.includes(om.id))
          .map(orgMembership => orgMembership.user);
        return users.map(user => (key.endsWith('.name') ? user.username : user.email)).join(', ');
      },
    )
    .with(
      { formFieldWidget: { fieldType: FieldType.SendRichEmail } },
      async (ffv: FormFieldValueWithWidget<SendRichEmailFormFieldValue, SendRichEmailFormFieldWidget>) => {
        const { lastSentByUserId, lastSentDate } = ffv.fieldValue;
        const tz = (await recordStore.getCurrentUser()).timeZone;
        if (lastSentByUserId && lastSentDate) {
          const on = DateUtils.formatDateTime(lastSentDate, DateFormat.DateTimeShortMonthNoSeconds, tz);
          return `Sent ${on} by ${lastSentByUserId}`;
        } else {
          return '';
        }
      },
    )
    .with(
      {
        formFieldWidget: { fieldType: FieldType.Textarea },
        fieldValue: { value: P.string },
      },
      async ({ fieldValue: { value }, formFieldWidget }) => {
        const isRichText = formFieldWidget.config.format === 'RichText';
        return isRichText ? [value, { format: ResolvedTagFormat.Markdown }] : value;
      },
    )
    .with(
      {
        formFieldWidget: { fieldType: FieldType.Hidden },
        fieldValue: { value: P.string },
      },
      async ({ fieldValue: { value } }) => {
        return value;
      },
    )
    .with(
      {
        formFieldWidget: { fieldType: FieldType.Table },
      },
      (ffv: FormFieldValueWithWidget<TableFormFieldValue, TableFormFieldWidget>) => {
        return [
          TableFormFieldUtils.toHtmlString(ffv.formFieldWidget, ffv.fieldValue),
          { format: ResolvedTagFormat.Html },
        ];
      },
    )
    .with({ fieldValue: { value: P.not(P.nullish) } }, ({ fieldValue: { value } }) => String(value))
    .otherwise(() => undefined);
}

export async function resolveGeneralMergeTagValue(key: string, recordStore: RecordStore): Promise<string | undefined> {
  return match(key)
    .with(StdMergeTagKeyLegacy.WorkflowName, StdMergeTagKey.WorkflowName, async () => {
      return (await recordStore.getTemplate()).name;
    })
    .with(StdMergeTagKeyLegacy.WorkflowUrl, StdMergeTagKey.WorkflowUrl, async () => {
      const template = await recordStore.getTemplate();
      return LocationService.templateHref(template.id, template.name);
    })
    .with(StdMergeTagKeyLegacy.RunName, StdMergeTagKey.RunName, async () => {
      return (await recordStore.getChecklist()).name;
    })
    .with(StdMergeTagKeyLegacy.RunUrl, StdMergeTagKey.RunUrl, async () => {
      const checklist = await recordStore.getChecklist();
      return LocationService.checklistHref(checklist.id, checklist.name);
    })
    .with(StdMergeTagKeyLegacy.RunDueDate, StdMergeTagKey.RunDueDate, async () => {
      const tz = (await recordStore.getCurrentUser()).timeZone;
      const checklist = await recordStore.getChecklist();
      return checklist.dueDate
        ? DateUtils.formatDateTime(checklist.dueDate, DateFormat.DateTimeShortMonthNoSeconds, tz)
        : undefined;
    })
    .with(StdMergeTagKeyLegacy.RunCreatedDate, StdMergeTagKey.RunCreatedDate, async () => {
      const tz = (await recordStore.getCurrentUser()).timeZone;
      const checklist = await recordStore.getChecklist();
      return DateUtils.formatDateTime(checklist.audit.createdDate, DateFormat.DateTimeShortMonthNoSeconds, tz);
    })
    .with(StdMergeTagKeyLegacy.RunCreatedByName, StdMergeTagKey.RunCreatedByName, async () => {
      const checklist = await recordStore.getChecklist();
      const user = await recordStore.getUserById(checklist.audit.createdBy.id);
      return user?.username;
    })
    .with(StdMergeTagKeyLegacy.RunCreatedByEmail, StdMergeTagKey.RunCreatedByEmail, async () => {
      const checklist = await recordStore.getChecklist();
      const user = await recordStore.getUserById(checklist.audit.createdBy.id);
      return user?.email;
    })
    .with(StdMergeTagKey.TaskName, async () => {
      const task = await recordStore.getTaskTemplate();
      return task?.name;
    })
    .with(StdMergeTagKey.TaskUrl, async () => {
      const checklist = await recordStore.getChecklist();
      const taskTemplate = await recordStore.getTaskTemplate();
      return taskTemplate ? LocationService.taskHref(checklist.id, taskTemplate.group.id, checklist.name) : undefined;
    })
    .with(StdMergeTagKey.TaskDueDate, async () => {
      const tz = (await recordStore.getCurrentUser()).timeZone;
      const task = await recordStore.getTask();

      return task?.dueDate ? DateUtils.formatDateTime(task.dueDate, DateFormat.DateTimeMergeTagLong, tz) : undefined;
    })
    .with(StdMergeTagKey.OrganizationName, async () => {
      const organization = await recordStore.getOrganization();
      return organization.name;
    })
    .with(StdMergeTagKey.CurrentDate, async () => {
      const tz = (await recordStore.getCurrentUser()).timeZone;
      return DateUtils.formatDateTime(new Date(), DateFormat.DateShortMonth, tz);
    })
    .with(StdMergeTagKey.CurrentYear, async () => {
      const tz = (await recordStore.getCurrentUser()).timeZone;
      return DateUtils.formatDateTime(new Date(), DateFormat.Year, tz);
    })
    .with(StdMergeTagKey.CurrentMonth, async () => {
      const tz = (await recordStore.getCurrentUser()).timeZone;
      return DateUtils.formatDateTime(new Date(), DateFormat.Month, tz);
    })
    .with(StdMergeTagKey.CurrentDay, async () => {
      const tz = (await recordStore.getCurrentUser()).timeZone;
      return DateUtils.formatDateTime(new Date(), DateFormat.Day, tz);
    })
    .with(StdMergeTagKey.CurrentUserEmail, async () => {
      const currentUser = await recordStore.getCurrentUser();
      return currentUser.email;
    })
    .with(StdMergeTagKey.CurrentUserName, async () => {
      const currentUser = await recordStore.getCurrentUser();
      return currentUser.username;
    })
    .with(StdMergeTagKey.EmailTriggerSubject, async () =>
      calculateRunViaEmailMergeTag('email_trigger.subject', recordStore),
    )
    .with(StdMergeTagKey.EmailTriggerBody, async () => calculateRunViaEmailMergeTag('email_trigger.body', recordStore))
    .with(StdMergeTagKey.EmailTriggerSenderEmail, async () =>
      calculateRunViaEmailMergeTag('email_trigger.sender.email', recordStore),
    )
    .with(StdMergeTagKey.EmailTriggerSenderName, async () =>
      calculateRunViaEmailMergeTag('email_trigger.sender.name', recordStore),
    )
    .otherwise(async () => undefined);
}

export function convertMultiChoiceValueToPlainText(
  ffv: FormFieldValueWithWidget<MultiChoiceFormFieldValue, MultiChoiceFormFieldWidget>,
) {
  const itemValues = ffv.fieldValue.itemValues ?? [];
  return itemValues
    .reduce((names, itemValue) => {
      if (itemValue.status === MultiChoiceItemValueStatus.Selected) {
        const selectedItem = ffv.formFieldWidget.config.items.find(i => itemValue.id === i.id);
        if (selectedItem) {
          names.push(selectedItem.name);
        }
      }
      return names;
    }, [] as string[])
    .join(', ');
}
