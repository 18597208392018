import * as React from 'react';
import { FormControl, FormErrorMessage, FormLabel, HStack, Input, Spinner, Text, VStack } from 'components/design/next';
import { TextFormFieldValue, TextFormFieldWidget, WidgetUtils } from '@process-street/subgrade/process';
import { UnsavedChangesIcon } from 'components/widgets/form-field/common/unsaved-changes-icon/unsaved-changes-icons';
import { useChecklistTextFormFieldWidget } from './use-checklist-text-form-field-widget';
import { MaskedInput, MaskedInputParsers } from 'features/widgets/components/masked-input';
import { ChecklistFormFieldWidgetProps } from 'components/widgets/form-field/common/types';
import { FormFieldValueHelperText } from 'pages/runs/_id/components/form-field-value-helper-text';
import { WidgetProvider } from '../../hooks/use-widget-context';
import { useWorkflowRunStore } from '../../workflow-run-store';
import { match } from 'ts-pattern';

export interface ChecklistTextFormFieldWidgetProps
  extends ChecklistFormFieldWidgetProps<TextFormFieldWidget, TextFormFieldValue> {
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const ChecklistTextFormFieldWidget: React.FC<
  React.PropsWithChildren<ChecklistTextFormFieldWidgetProps>
> = props => {
  const { formFieldValue, widget, isInvalid, readOnly } = props;
  const { errorMessage, handleReset, inputProps, aiAutomationStatus } = useChecklistTextFormFieldWidget(props);

  const hasDefaultValue = WidgetUtils.hasDefaultValue({ formFieldValue, widget });
  const hasVariables = WidgetUtils.hasVariables(widget.config.defaultValue);
  const maskedInputParser = React.useMemo(
    () =>
      hasDefaultValue && hasVariables
        ? MaskedInputParsers.makeParser('urls', 'merge-tags')
        : MaskedInputParsers.makeParser('urls'),
    [hasDefaultValue, hasVariables],
  );
  const html = React.useMemo(() => maskedInputParser(String(inputProps.value)), [maskedInputParser, inputProps.value]);

  const addOutputWidgetClickedDuringAiTask = useWorkflowRunStore(state => state.addOutputWidgetClickedDuringAiTask);

  return (
    <WidgetProvider widget={widget}>
      <FormControl
        as={VStack}
        alignItems="stretch"
        isInvalid={isInvalid || Boolean(errorMessage)}
        isRequired={widget.required}
        isDisabled={aiAutomationStatus === 'AutomationRunning' || readOnly}
      >
        <HStack lineHeight="normal">
          {errorMessage ? <UnsavedChangesIcon /> : null}
          <FormLabel m={0}>{widget.label || 'Untitled Short Text'}</FormLabel>
          {aiAutomationStatus === 'AutomationRunning' && (
            <Spinner color="gray.300" data-testid="native-automation-running-spinner" />
          )}
        </HStack>

        <MaskedInput
          html={html}
          color={readOnly ? 'gray.400' : undefined}
          onClick={() => {
            if (aiAutomationStatus === 'AutomationRunning') {
              addOutputWidgetClickedDuringAiTask(widget.id);
            }
          }}
          sx={{
            '& input': {
              borderColor: readOnly ? 'gray.400' : 'inherit',
            },
          }}
        >
          <Input
            {...inputProps}
            bgColor={match({ hasDefaultValue, readOnly })
              .with({ readOnly: true }, () => 'gray.100')
              .with({ hasDefaultValue: true }, () => 'yellow.100')
              .otherwise(() => undefined)}
          />
        </MaskedInput>

        <FormFieldValueHelperText
          defaultValue={widget.config.defaultValue}
          formFieldValue={formFieldValue}
          onResetDefaultValue={handleReset}
        />

        {errorMessage ? (
          <FormErrorMessage>
            <Text variant="-2">{errorMessage}</Text>
          </FormErrorMessage>
        ) : null}
      </FormControl>
    </WidgetProvider>
  );
};
