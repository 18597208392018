import { Box } from 'components/design/next';
import { Template } from '@process-street/subgrade/process';
import { CoverImageByTemplateIdQuery } from 'app/features/cover-image/query-builder';
import * as React from 'react';
import { TemplateCoverImage } from 'app/features/cover-image/components/template';

export type TaskListCoverImageProps = {
  templateId: Template['id'];
  isReadOnly?: boolean;
};
export const TaskListCoverImage = ({ templateId, isReadOnly }: TaskListCoverImageProps) => {
  const coverImageQuery = CoverImageByTemplateIdQuery.useQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
      notifyOnChangeProps: 'tracked',
    },
  );

  if (!coverImageQuery.data) return null;

  return (
    <Box>
      <TemplateCoverImage editable={!isReadOnly} coverImage={coverImageQuery.data} templateId={templateId} />
    </Box>
  );
};
