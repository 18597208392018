import { createSelector, Selector } from 'reselect';
import { Muid, Option } from '../../core';
import { FormFieldWidget, isFormFieldWidget, Widget } from '../../process';
import { safeEntityMapToArrayByIdsWith } from '../safe-entity-map-to-array-by-ids';
import { BaseReduxState, EntityMap, LookupMap, ObjectMap } from '../types';
import { BaseTaskTemplateSelector } from './task-template.selectors';
import { BaseTaskSelector } from './task.selectors';

const getEntityMap: Selector<BaseReduxState, EntityMap<Widget>> = (state: BaseReduxState): EntityMap<Widget> =>
  state.entities.widgets;

const getById =
  (widgetId: Muid): Selector<BaseReduxState, Option<Widget>> =>
  (state: BaseReduxState): Option<Widget> =>
    state.entities.widgets[widgetId];

const getFormFieldWidgetById =
  (formFieldWidgetId: Muid): Selector<BaseReduxState, Option<FormFieldWidget>> =>
  (state: BaseReduxState): Option<FormFieldWidget> => {
    const widget = getById(formFieldWidgetId)(state);
    return widget && isFormFieldWidget(widget) ? widget : undefined;
  };

const getLookupMapByTaskTemplateId: Selector<BaseReduxState, LookupMap> = (state: BaseReduxState): LookupMap =>
  state.lookups.widget.byTaskTemplateId;

const byHeaderLookupSelector: Selector<BaseReduxState, ObjectMap<Muid>> = createSelector<
  BaseReduxState,
  EntityMap<Widget>,
  ObjectMap<Muid>
>(getEntityMap, (widgets: EntityMap<Widget>) =>
  Object.values(widgets).reduce((agg: ObjectMap<Muid>, widget: Widget) => {
    agg[widget.header.id] = widget.id;
    return agg;
  }, {}),
);

const getByHeaderId =
  (headerId: Muid): Selector<BaseReduxState, Option<Widget>> =>
  (state: BaseReduxState): Option<Widget> => {
    const widgetId: Option<Muid> = byHeaderLookupSelector(state)[headerId];
    if (!widgetId) {
      return undefined;
    }
    return getById(widgetId)(state);
  };

const getAllByTaskId =
  (taskId: Muid): Selector<BaseReduxState, Widget[]> =>
  (state: BaseReduxState): Widget[] => {
    const task = BaseTaskSelector.getById(taskId)(state);
    if (!task) {
      return [];
    }
    return getAllByTaskTemplateId(task.taskTemplate.id)(state);
  };

const getAllIdsByTaskTemplateId =
  (taskTemplateId: Muid): Selector<BaseReduxState, Muid[]> =>
  (state: BaseReduxState) =>
    state.lookups.widget.byTaskTemplateId[taskTemplateId] ?? [];

const getAllByTaskTemplateId = (taskTemplateId: Muid): Selector<BaseReduxState, Widget[]> =>
  createSelector([getAllIdsByTaskTemplateId(taskTemplateId), getEntityMap], (widgetIds, widgetsMap) =>
    safeEntityMapToArrayByIdsWith(widgetsMap, widgetIds),
  );

const existsByTaskTemplateId = (taskTemplateId: Muid): Selector<BaseReduxState, boolean> =>
  createSelector(getAllIdsByTaskTemplateId(taskTemplateId), widgets => widgets.length > 0);

const getAllByTemplateRevisionId = (revisionId: Muid): Selector<BaseReduxState, Widget[]> =>
  createSelector(
    BaseTaskTemplateSelector.getAllIdsByTemplateRevisionId(revisionId),
    getLookupMapByTaskTemplateId,
    getEntityMap,
    (taskTemplateIds, widgetIdsByTaskTemplateId, widgetsEntityMap) => {
      const widgetIds = taskTemplateIds.reduce((agg: Muid[], taskTemplateId) => {
        const taskTemplateWidgetIds = widgetIdsByTaskTemplateId[taskTemplateId] ?? [];
        agg.push(...taskTemplateWidgetIds);
        return agg;
      }, []);
      return safeEntityMapToArrayByIdsWith(widgetsEntityMap, widgetIds);
    },
  );

const getAllFormFieldWidgetsByTemplateRevisionId = (
  templateRevisionId: Muid,
): Selector<BaseReduxState, FormFieldWidget[]> =>
  createSelector(getAllByTemplateRevisionId(templateRevisionId), (widgets: Widget[]) =>
    widgets.filter((widget): widget is FormFieldWidget => isFormFieldWidget(widget)),
  );

export const BaseWidgetSelector = {
  getById,
  getByHeaderId,
  getEntityMap,
  getAllByTaskId,
  getAllByTaskTemplateId,
  getAllByTemplateRevisionId,
  getAllFormFieldWidgetsByTemplateRevisionId,
  getFormFieldWidgetById,
  existsByTaskTemplateId,
};
