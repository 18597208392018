import angular from 'angular';

const whenAllImagesLoaded = (callback: () => {}) => {
  let incompleteCount = 0;
  angular.element('img').each(function (_i: number, elem: Element) {
    if (elem instanceof HTMLImageElement && !elem.complete) {
      incompleteCount++;
    }
  });

  // See if we're waiting for anymore images, if not, fire the callback
  if (incompleteCount) {
    setTimeout(whenAllImagesLoaded.bind(null, callback), 500);
  } else {
    callback();
  }
};

export const PrintImageService = {
  whenAllImagesLoaded,
};
