import { Organization } from '@process-street/subgrade/core/organization-model';
import { User } from '@process-street/subgrade/core/user-model';

export type DateContext = {
  organizationTimeZone?: string;
  organizationDateFormat?: string;
  userTimeZone?: string;
  userDateFormat?: string; // TBD
};

const getDateContext = (organization: Organization, user?: User): DateContext => ({
  organizationTimeZone: organization.timeZone,
  organizationDateFormat: organization.dateFormat,
  userTimeZone: user?.timeZone,
});

export const DEFAULT_TIME_ZONE = 'America/Los_Angeles';

const getOrganizationTimeZone = (dateContext?: DateContext) => dateContext?.organizationTimeZone ?? DEFAULT_TIME_ZONE;

export const DateContextUtils = {
  getDateContext,
  getOrganizationTimeZone,
};
