import { Box, Tooltip } from '@chakra-ui/react';
import { isFunction } from '@chakra-ui/utils';
import { FolderPermissionMap, Muid } from '@process-street/subgrade/core';
import { Folder, Template } from '@process-street/subgrade/process';
import { useFeatureFlag } from 'features/feature-flags';
import { GetConsolidatedTemplatePermissionsResult } from 'features/permissions/query-builder';
import * as React from 'react';
import { useDrop } from 'react-dnd';
import { match, P } from 'ts-pattern';
import { canMoveFolder, canMoveTemplate } from '../helpers';
import { useMoveFolder } from './use-move-folder';
import { useMoveTemplate } from './use-move-template';

type RenderProps = {
  hasPermissions: boolean;
  isOver: boolean;
  canDrop: boolean;
};

type FolderRowDragZoneWrapperProps = {
  folder: Folder;
  showTooltip?: boolean;
  folderPermissions?: FolderPermissionMap;
  templatePermissionsById?: Record<string, GetConsolidatedTemplatePermissionsResult>;
  // A set of folder ids that the user can write to
  writeableFoldersSet: Set<Muid>;
  children: React.ReactNode | ((props: RenderProps) => React.ReactElement);
};

export const FolderRowDragZoneWrapper: React.FC<FolderRowDragZoneWrapperProps> = ({
  folder,
  showTooltip,
  folderPermissions,
  templatePermissionsById,
  writeableFoldersSet,
  children,
}) => {
  const isDragAndDropEnabled = useFeatureFlag('dragAndDropLibrary');
  const moveFolderMutation = useMoveFolder({ folder });
  const moveTemplateMutation = useMoveTemplate({ folder });

  const canMoveItem = (item: Folder | Template | undefined) => {
    return match(item)
      .with({ templateType: P.string }, template => {
        const templatePermissionMap = templatePermissionsById?.[template.id]?.permissionMap;

        if (!templatePermissionMap || !writeableFoldersSet.has(folder.id)) return false;

        return canMoveTemplate(template, templatePermissionMap);
      })
      .with({ folderType: P.string }, folder => {
        if (!folderPermissions) return false;

        return canMoveFolder(folder, folderPermissions, writeableFoldersSet);
      })
      .otherwise(() => false);
  };

  const [{ canDrop, isOver, hasPermissions }, drop] = useDrop<
    Template | Folder,
    unknown,
    { isOver: boolean; canDrop: boolean; hasPermissions: boolean }
  >(() => ({
    accept: ['template', 'folder'],
    canDrop: item => {
      if (!canMoveItem(item)) return false;

      return (
        match(item)
          // Prevent to drop a template into its current parent folder
          .with({ folder: { id: P.string } }, t => t.folder.id !== folder.id)
          // Prevent to drop a folder into its current parent folder
          .with({ parentFolder: { id: P.string } }, f => f.parentFolder.id !== folder.id)
          // Ensure you can't drag a folder into itself
          .otherwise(item => item.id !== folder.id)
      );
    },
    drop: (item, monitor) => {
      const params = { candidateId: item.id, toFolderId: folder.id };

      match(monitor.getItemType())
        .with('template', () => moveTemplateMutation.mutate(params))
        .with('folder', () => moveFolderMutation.mutate(params))
        .run();
    },
    collect: monitor => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop() && monitor.getItem().id !== folder.id,
        hasPermissions: canMoveItem(monitor.getItem()),
      };
    },
  }));

  const isActive = canDrop && isOver;

  if (!isDragAndDropEnabled)
    return <>{isFunction(children) ? children({ hasPermissions, isOver, canDrop }) : children}</>;

  const withTooltip = (component: React.ReactElement) => {
    if (!showTooltip) return component;

    return (
      <Tooltip label={`Move to "${folder.name}" folder`} isOpen={isActive}>
        {component}
      </Tooltip>
    );
  };

  return withTooltip(
    <Box
      ref={drop}
      w="full"
      bgColor={match({ hasPermissions, isActive, isOver })
        .with({ isActive: true, hasPermissions: true }, () => 'blue.100')
        .with({ isOver: true, hasPermissions: false }, () => 'gray.100')
        .otherwise(() => undefined)}
    >
      {isFunction(children) ? children({ hasPermissions, isOver, canDrop }) : children}
    </Box>,
  );
};
