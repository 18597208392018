import { Approval, ApprovalStatus } from '@process-street/subgrade/approval-rule/approval.model';
import { ApprovalTasksGroupType } from '@process-street/subgrade/approval-rule/approval.types';
import { Muid, Option, User } from '@process-street/subgrade/core';
import { ChecklistRevision, Task, TaskStatus } from '@process-street/subgrade/process';
import { BaseApprovalSelector } from '@process-street/subgrade/redux/selector';
import angular from 'angular';
import { approvalService } from 'components/approvals/approval-service';
import { ApprovalActions } from 'components/approvals/store/approval.actions';
import ngRedux from 'ng-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { ReduxAppState } from 'reducers/types';
import { connectController } from 'reducers/util';
import { SessionService } from 'services/session-service.interface';
import templateUrl from './group.component.html';
import './group.component.scss';
import { StopTaskEvent } from 'services/stop-task-event';
import { ToastService } from 'services/toast-service.interface';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { queryClient } from 'components/react-root';
import { GetAllTasksByChecklistRevisionIdQuery } from 'features/task/query-builder';
import { TaskListEvent } from 'directives/task-list/task-list-event';

interface InternalState {
  selectedOrganizationId: Muid;
  approvals: Approval[];
}

interface InternalActions {
  upsertAllApprovals(checklistRevisionId: Muid, approvals: Approval[], approvalTaskId: Muid): Promise<void>;
}

export class ApprovalSubjectTasksGroupController {
  public actions: Option<InternalActions>;
  public state: Option<InternalState>;

  public type: Option<ApprovalTasksGroupType>;
  public user: Option<User>;

  public tasks: Option<Task[]>;
  public approvalTask: Option<Task>;
  public checklistRevision: Option<ChecklistRevision>;
  public disabled: Option<boolean>;

  static $inject = ['$ngRedux', '$timeout', '$rootScope', 'ApprovalActions', 'SessionService', 'ToastService'];
  constructor(
    private $ngRedux: ngRedux.INgRedux,
    private $timeout: angular.ITimeoutService,
    private $rootScope: angular.IScope,
    private approvalActions: ApprovalActions,
    private sessionService: SessionService,
    private toastService: ToastService,
  ) {
    this.user = this.sessionService.getUser();

    const mapStateToThis = () => (state: ReduxAppState) => {
      const selectedOrganizationId = SessionSelector.getSelectedOrganizationId(state);

      let approvals: Approval[] = [];
      if (this.approvalTask && this.checklistRevision) {
        approvals = BaseApprovalSelector.getAllByTaskIds(this.checklistRevision.id, [this.approvalTask.id])(state);
      }
      return { selectedOrganizationId, approvals };
    };

    const mapDispatchToThis = () => ({
      upsertAllApprovals: this.approvalActions.upsertAll,
    });

    connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, null)(this);
  }

  public $onChanges(changes: {
    type: angular.IChangesObject<ApprovalTasksGroupType>;
    approvalTask: angular.IChangesObject<Task>;
    tasks: angular.IChangesObject<Task[]>;
    checklistRevision: angular.IChangesObject<ChecklistRevision>;
    disabled: angular.IChangesObject<boolean>;
  }) {
    const { type, approvalTask, tasks, checklistRevision, disabled } = changes;

    if (type && type.currentValue) {
      this.type = type.currentValue;
    }
    if (approvalTask && approvalTask.currentValue) {
      this.approvalTask = approvalTask.currentValue;
    }
    if (checklistRevision && checklistRevision.currentValue) {
      this.checklistRevision = checklistRevision.currentValue;
    }
    if (tasks && tasks.currentValue) {
      this.tasks = tasks.currentValue;
    }
    if (disabled && disabled.currentValue) {
      this.disabled = disabled.currentValue;
    }
  }

  public reject(subjectTask: Task, comment?: string) {
    const approval = approvalService.resolveUpdatedApproval(
      this.state!.selectedOrganizationId,
      this.state!.approvals,
      this.approvalTask!,
      subjectTask,
      ApprovalStatus.Rejected,
      this.user!,
      comment,
    );

    this.$rootScope.$broadcast(TaskListEvent.TASK_APPROVAL_STATUS_CHANGED, {
      ...this.approvalTask,
      status: this.getOptmisticTaskStatus([approval]),
    });

    this.$rootScope.$broadcast(TaskListEvent.SUBJECT_TASK_REJECTED, {
      ...subjectTask,
      status: TaskStatus.NotCompleted,
    });

    queryClient.invalidateQueries(
      GetAllTasksByChecklistRevisionIdQuery.getKey({ checklistRevisionId: this.checklistRevision!.id }),
    );

    this.$timeout(() => {
      this.$rootScope.$broadcast(StopTaskEvent.RESET_NON_COMPLETED_STOP_TASKS);
      this.actions!.upsertAllApprovals(this.checklistRevision!.id, [approval], this.approvalTask!.id).catch(() => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems rejecting the task`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });

      // @ts-expect-error -- TODO
      this.onReject();
    });
  }

  public approve(subjectTask: Task, comment?: string) {
    const approval = approvalService.resolveUpdatedApproval(
      this.state!.selectedOrganizationId,
      this.state!.approvals,
      this.approvalTask!,
      subjectTask,
      ApprovalStatus.Approved,
      this.user!,
      comment,
    );

    this.$rootScope.$broadcast(TaskListEvent.TASK_APPROVAL_STATUS_CHANGED, {
      ...this.approvalTask,
      status: this.getOptmisticTaskStatus([approval]),
    });

    queryClient.invalidateQueries(
      GetAllTasksByChecklistRevisionIdQuery.getKey({ checklistRevisionId: this.checklistRevision!.id }),
    );

    // Allow redux updates to happen before the approval actions are fired
    this.$timeout(() => {
      this.$rootScope.$broadcast(StopTaskEvent.RESET_NON_COMPLETED_STOP_TASKS);
      this.actions!.upsertAllApprovals(this.checklistRevision!.id, [approval], this.approvalTask!.id).catch(() => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems accepting the task`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });

      // @ts-expect-error -- TODO
      this.onApprove();
    });
  }

  public getOptmisticTaskStatus(approvals: Approval[]) {
    return approvalService.getOptmisticTaskStatus({
      task: this.approvalTask!,
      approvals,
      $ngRedux: this.$ngRedux,
    });
  }
}

export const ApprovalSubjectTasksGroup: angular.IComponentOptions = {
  bindings: {
    approvalTask: '<',
    checklistRevision: '<',
    disabled: '<',
    onApprove: '&',
    onReject: '&',
    onItemSelect: '&',
    tasks: '<',
    title: '<',
    type: '<',
    isCondensed: '<',
  },
  controller: ApprovalSubjectTasksGroupController,
  templateUrl,
};
