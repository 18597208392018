import { IDatasource, IGetRowsParams } from '@ag-grid-community/core';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { usePurgeTasksTable } from 'pages/tasks/hooks/use-purge-tasks-table';
import { TasksTableUtils } from 'pages/tasks/components/tasks-table/tasks-table-utils';
import { ToastServiceImpl } from 'app/services/toast-service.impl';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';

export const useInboxItemsDataSource = () => {
  const queryClient = useQueryClient();
  const tasksTablePurgeStore = usePurgeTasksTable();

  const getRows = useCallback(
    async (getRowParams: IGetRowsParams) => {
      try {
        const { context, sortModel }: IGetRowsParams & { context: GetInboxItemsQuery.Params } = getRowParams;

        const hasSorting = sortModel.length > 0;
        const sortBy: Partial<GetInboxItemsQuery.Params> = hasSorting
          ? {
              sortBy: sortModel[0].colId as GetInboxItemsQuery.SortBy,
              sortAsc: sortModel[0].sort === 'asc',
            }
          : {
              sortBy: GetInboxItemsQuery.SortBy.TaskTemplateName,
            };

        const params: GetInboxItemsQuery.Params = {
          ...context.filters,
          ...sortBy,
          offset: getRowParams.startRow,
          pageSize: TasksTableUtils.TASKS_TABLE_PAGE_SIZE,
        };

        const rows = (
          await queryClient
            .fetchQuery<GetInboxItemsQuery.Response>({
              queryKey: GetInboxItemsQuery.getKey(params),
              queryFn: () => GetInboxItemsQuery.queryFn(params),
            })
            .catch(() => {
              getRowParams.failCallback();

              ToastServiceImpl.openToast({
                status: 'error',
                title: `We're having problems loading your tasks`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });

              return [] as GetInboxItemsQuery.Response;
            })
        ).map(row => ({ ...row, isFullWidth: false }));

        const fetchedRowLength = rows.length;

        const hasMore = fetchedRowLength >= TasksTableUtils.TASKS_TABLE_PAGE_SIZE;
        const lastRow = hasMore ? undefined : getRowParams.startRow + fetchedRowLength + 1;

        const returnValue = hasMore ? rows : [...rows, TasksTableUtils.ADD_NEW_INFINITE_SCROLL_BUTTON_ROW];
        getRowParams.successCallback(returnValue, lastRow);
      } catch (e) {
        console.error('Unable to fetch inbox items in Tasks table.', e);
        getRowParams.failCallback();
      }
    },
    [queryClient],
  );

  const invalidate = async ({
    shouldPurge = false,
  }: {
    /* Fully refresh inbox tasks table when tasks have been added/removed. */
    shouldPurge?: boolean;
  } = {}) => {
    await queryClient.invalidateQueries(GetInboxItemsQuery.key);
    if (shouldPurge) {
      tasksTablePurgeStore.setShouldPurge(true);
    }
  };

  const dataSource: IDatasource = useMemo(
    () => ({
      getRows,
    }),
    [getRows],
  );

  return {
    dataSource,
    invalidate,
  };
};
