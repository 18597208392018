import {
  DerivedChecklistStatus,
  DerivedChecklistStatusStat,
  DerivedTaskStatByTask,
  DerivedTaskStatByUser,
  DerivedTaskStatus,
} from '@process-street/subgrade/dashboard';
import { TASK_STATUS_KEYS, CHECKLIST_STATUS_KEYS } from '../common/constants';

type TaskStat = DerivedTaskStatByTask | DerivedTaskStatByUser;

export const getDerivedTaskStatusPercentage = (stat: TaskStat) => (status: DerivedTaskStatus) =>
  Math.round((stat[status] / getDerivedTaskStatusTotal(stat)) * 100);

export const getDerivedTaskStatusTotal = (stat: TaskStat) => TASK_STATUS_KEYS.reduce((acc, key) => acc + stat[key], 0);

export const getDerivedChecklistStatusTotal = (stat: DerivedChecklistStatusStat) =>
  CHECKLIST_STATUS_KEYS.reduce((acc, key) => acc + stat[key], 0);

export const getDerivedChecklistStatusPercentage =
  (stat: DerivedChecklistStatusStat) => (status: DerivedChecklistStatus) =>
    Math.round((stat[status] / getDerivedChecklistStatusTotal(stat)) * 100);
