import * as React from 'react';
import { ControlProps, components } from 'react-select';
import { OptionType } from './types';

export const Control = (props: ControlProps<OptionType, true>) => {
  const innerRef = React.useRef<HTMLDivElement | null>(null);

  // There is a bug in the react-select that scrolls the value container down
  // when we click in the control component. This effect will make sure that
  // the value container is never scrolled down when we click to see the options
  // menu.
  React.useEffect(() => {
    if (!innerRef.current) return;

    const containerElement = innerRef.current.querySelector('.blvd-select__value-container');

    if (containerElement) {
      requestAnimationFrame(() => {
        containerElement.scrollTop = 0;
      });
    }
  }, [props.selectProps.menuIsOpen]);

  return <components.Control {...props} innerRef={innerRef} />;
};
