import { Box, Center, Spinner, Text, useToast } from 'components/design/next';
import { UnsafeTrayAutomationAppName } from '@process-street/subgrade/automation';
import { Muid } from '@process-street/subgrade/core';
import * as React from 'react';
import { useLifecycles, usePromise, useToggle } from 'react-use';
import { AutomationMessages, AutomationUtils } from '../../utils';
import { TrayConfigPopupMessageType } from 'pages/templates/_id/automation/lib/config-window';
import { useAutomationSelector } from '../../components/selector/context';
import {
  SolutionInstancesByTemplateIdQuery,
  SolutionInstancesByTemplateIdQueryResponse,
  useSolutionInstancesByTemplateIdQuery,
} from 'features/automations/query-builder';
import { useUpdateTemplateSolutionInstanceMutation } from 'features/automations/query-builder/update-template-solution-instance-mutation';
import { useQueryClient } from 'react-query';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export type ConfigWizardProps = {
  templateId: Muid;
  solutionInstanceId: string;
  automationApp: UnsafeTrayAutomationAppName;
  onClose: () => void;
  onError: (error: Record<'message', string>) => void;
  isOpen?: boolean;
};

export const ConfigWizard: React.FC<React.PropsWithChildren<ConfigWizardProps>> = ({
  templateId,
  solutionInstanceId,
  automationApp,
  onClose,
  onError,
  isOpen,
}) => {
  const mounted = usePromise();
  const toast = useToast();
  const [configWizardUrl, setConfigWizardUrl] = React.useState<string>();
  const [trayConfigurationIsLoaded, setLoaded] = useToggle(false);
  const [state] = useAutomationSelector();
  const { automationInstanceId: selectedTemplateSolutionInstanceId } = state.context;

  React.useEffect(() => {
    if (templateId && solutionInstanceId && automationApp) {
      (async () => {
        const value = await mounted(
          AutomationUtils.getConfigWizard({
            templateId,
            solutionInstanceId,
          }),
        );
        setConfigWizardUrl(value);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore mounted
  }, [isOpen, templateId, solutionInstanceId, automationApp]);

  const templateSolutionInstanceQuery = useSolutionInstancesByTemplateIdQuery(
    { templateId },
    {
      select: data => data.find(instance => instance.id === selectedTemplateSolutionInstanceId),
    },
  );
  const templateSolutionInstance = templateSolutionInstanceQuery.data;

  const queryClient = useQueryClient();
  const updateTemplateSolutionInstanceMutation = useUpdateTemplateSolutionInstanceMutation({
    onSuccess: tsi => {
      if (!tsi) return;
      queryClient.setQueryData<SolutionInstancesByTemplateIdQueryResponse>(
        SolutionInstancesByTemplateIdQuery.getKey({ templateId: tsi.templateId }),
        instances => instances?.map(i => (i.id === tsi.id ? tsi : i)) ?? [tsi],
      );
    },
  });

  const handleIframeEvents = (e: { data: { source?: string; type: TrayConfigPopupMessageType; err?: string } }) => {
    if (!e.data.type) {
      return void 0;
    }
    switch (e.data.type) {
      case TrayConfigPopupMessageType.ERROR: {
        onError({ message: e.data.err || 'an error occurred. Try configuring again or contact your administrator' });
        break;
      }
      case TrayConfigPopupMessageType.CANCEL: {
        onClose();
        break;
      }
      case TrayConfigPopupMessageType.READY: {
        setLoaded(true);
        break;
      }
      case TrayConfigPopupMessageType.FINISH: {
        if (selectedTemplateSolutionInstanceId) {
          updateTemplateSolutionInstanceMutation
            .mutateAsync({
              templateId: templateSolutionInstance?.templateId!,
              solutionInstanceId: templateSolutionInstance?.solutionInstanceId!,
              enabled: templateSolutionInstance?.enabled!,
              configured: true,
            })
            .then(result => {
              if (!result) throw new Error('Failed to update template solution instance');

              toast({
                status: 'success',
                title: 'Automation Ready',
                description: (
                  <Box pt="1">
                    <Text>{AutomationMessages.ConfigurationFinished}</Text>
                  </Box>
                ),
              });
            })
            .catch(() => {
              toast({
                status: 'error',
                title: "We're having problems saving the configuration",
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            })
            .finally(() => {
              onClose();
            });
        }
        break;
      }
      default: {
        break;
      }
    }
    return e;
  };

  useLifecycles(
    () => window.addEventListener('message', handleIframeEvents),
    () => window.removeEventListener('message', handleIframeEvents),
  );

  return (
    <>
      <Box
        display={trayConfigurationIsLoaded ? 'block' : 'none'}
        as="iframe"
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        height="full"
        width="full"
        padding={0}
        margin={0}
        border="none"
        src={configWizardUrl}
      />
      {!trayConfigurationIsLoaded && (
        <Center zIndex="overlay" width="full" height="full">
          <Spinner size="xl" />
        </Center>
      )}
    </>
  );
};
