import classNames from 'classnames';
import { Icon, IconType } from 'components/design/Icon';
import debounce from 'lodash/debounce';
import React, { FormEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Input, InputGroup, InputLeftElement, InputRightElement } from 'components/design/next';
import styles from './BlvdSearch.module.scss';

export interface BlvdSearchProps {
  autoFocus?: boolean;
  className?: string;
  debounceDelay?: number;
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string;
  inputColour?: InputColour;
  enabled?: boolean;
}

const DefaultDebounceDelay = 500;

const DefaultPlaceHolder = 'Search';
export enum InputColour {
  Default = 'Default',
  Gray = 'Gray',
}

export const BlvdSearch: React.FunctionComponent<React.PropsWithChildren<BlvdSearchProps>> = ({
  autoFocus,
  className,
  enabled = true,
  placeholder = DefaultPlaceHolder,
  inputColour = InputColour.Gray,
  debounceDelay = DefaultDebounceDelay,
  onChange,
  value,
}) => {
  const [searchTerm, setSearchTerm] = useState(value || '');
  const [pending, setPending] = useState(false);

  const inputRef = useRef<HTMLInputElement>();
  const inputRefHandler = (instance: HTMLInputElement) => (inputRef.current = instance);

  const clearable = searchTerm ? searchTerm.length !== 0 : false;

  useLayoutEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const onChangeDebounced = React.useMemo(
    () =>
      debounce((filter: string) => {
        onChange(filter);
        setPending(false);
      }, debounceDelay),
    [debounceDelay, onChange],
  );

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setPending(true);
    setSearchTerm(event.currentTarget.value);
    onChangeDebounced(event.currentTarget.value);
  };

  const handleClear = () => {
    setPending(true);
    setSearchTerm('');
    onChangeDebounced('');
  };

  useEffect(() => {
    if (value !== undefined && !pending) {
      setSearchTerm(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- act on value change
  }, [value]);

  const classes = classNames({
    [styles.searchInput]: true,
    [styles.grayColor]: inputColour === InputColour.Gray,
    [`${className}`]: !!className,
    [styles.clearable]: clearable,
  });

  return (
    <div className={classes}>
      <InputGroup>
        <InputLeftElement>
          <Icon className={styles.searchIcon} iconType={IconType.Search} />
        </InputLeftElement>
        <Input
          isReadOnly={!enabled}
          ref={inputRefHandler}
          onChange={handleChange}
          placeholder={placeholder}
          autoFocus={autoFocus}
          type="text"
          value={searchTerm}
        />
        <InputRightElement>
          {clearable && (
            <a onClick={handleClear} className={styles.clearIcon} role={'button'} aria-label={'Clear search'}>
              <Icon iconType={IconType.Close} />
            </a>
          )}
        </InputRightElement>
      </InputGroup>
    </div>
  );
};
