import { Organization } from '@process-street/subgrade/core';
import { Folder } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { FolderPickerApi } from 'components/folder-picker/folder-picker-api';
import { useQuery, UseQueryOptions } from 'react-query';

export type GetAllWritableFoldersByOrganizationIdResult = Folder[];

export type GetAllWritableFoldersByOrganizationIdParams = Organization['id'];

export const GetAllWritableFoldersByOrganizationId = {
  key: 'organizations',
  getKey(selectedOrganizationId: GetAllWritableFoldersByOrganizationIdParams) {
    return [GetAllWritableFoldersByOrganizationId.key, selectedOrganizationId, 'folders', 'writable'];
  },
  queryFn: FolderPickerApi.getAllWriteableFoldersByOrganizationId,
};

export const useWriteableFoldersQuery = <Select = GetAllWritableFoldersByOrganizationIdResult>(
  params: GetAllWritableFoldersByOrganizationIdParams,
  options: UseQueryOptions<GetAllWritableFoldersByOrganizationIdResult, AxiosError, Select> = {},
) =>
  useQuery<GetAllWritableFoldersByOrganizationIdResult, AxiosError, Select>(
    GetAllWritableFoldersByOrganizationId.getKey(params),
    () => GetAllWritableFoldersByOrganizationId.queryFn(params),
    options,
  );
