import { InvitationUserInfo } from '@process-street/subgrade/core';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type GetInvitationParams = { invitationId?: InvitationUserInfo['id'] };
type GetInvitationResult = InvitationUserInfo;

export const GetInvitationQuery = {
  key: 'invitations',
  getKey: (params: GetInvitationParams) => [GetInvitationQuery.key, params],
  queryFn: (params: GetInvitationParams) =>
    axiosService
      .getAxios()
      .get<GetInvitationResult>(`/1/invitations/${params.invitationId}`)
      .then(res => res.data),
};

export const useGetInvitationQuery = (
  params: GetInvitationParams,
  { enabled, ...options }: UseQueryOptions<GetInvitationResult, AxiosError> = {},
) => {
  return useQuery<GetInvitationResult, AxiosError>(
    GetInvitationQuery.getKey(params),
    () => GetInvitationQuery.queryFn(params),
    {
      ...options,
      enabled: Boolean(params.invitationId) && enabled !== false,
    },
  );
};

type PostBulkInviteParams = { file: File };

export type BulkInviteRowError = { email: string; success: false; error: string };
export type BulkInviteResults = { processedCount: number; errorCount: number; errors: BulkInviteRowError[] };

export function isBulkInviteResults(obj: any): obj is BulkInviteResults {
  return obj && 'processedCount' in obj && 'errorCount' in obj && 'errors' in obj;
}

export function isBulkInviteResultsErrorResponse(obj: any): obj is AxiosResponse<BulkInviteResults> {
  return obj && 'data' in obj && isBulkInviteResults(obj.data);
}

export type BadRequestResponse = { message: string; code?: number; errors?: string[] };

export function isBadRequest(obj: any): obj is BadRequestResponse {
  return (
    (obj && 'message' in obj) ||
    ('message' in obj && 'code' in obj) ||
    ('message' in obj && 'errors' in obj) ||
    ('message' in obj && 'code' in obj && 'errors' in obj)
  );
}

export function isBadRequestErrorResponse(obj: any): obj is AxiosResponse<BadRequestResponse> {
  return obj && 'data' in obj && isBadRequest(obj.data);
}

export type PostBulkInviteResponse = BulkInviteResults;

const BulkInvite = {
  key: 'invitations/bulk-invite',
  getKey: () => [BulkInvite.key],
  queryFn: (params: PostBulkInviteParams) => {
    const formData = new FormData();
    formData.append('file', params.file);

    return axiosService
      .getAxios()
      .post('/1/invitations/bulk-invite', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => res.data);
  },
};

export const usePostBulkInviteMutation = (
  options: UseMutationOptions<
    PostBulkInviteResponse,
    AxiosError<BulkInviteResults | BadRequestResponse>,
    PostBulkInviteParams
  > = {},
) => {
  return useMutation(BulkInvite.queryFn, { ...options });
};
