import * as React from 'react';
import { Button, ButtonProps } from 'components/design/next';

// eslint-disable-next-line react/display-name
export const MobileActionsItem = React.forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  return (
    <Button
      ref={ref}
      minH="12"
      iconSpacing="2"
      fontSize="sm"
      color="gray.600"
      leftIcon={
        props.leftIcon
          ? React.cloneElement(props.leftIcon, {
              size: '4',
              color: 'gray.500',
            })
          : undefined
      }
      variant="unstyled"
      textAlign="left"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      fontWeight="500"
      {...props}
    />
  );
});
