import { axiosService } from 'services/axios-service';
import { Action, Routes } from 'app/resources/factory/resource-factory';

const PARAM_PATTERN = /:(\w+)/g;
const ROUTE_PATTERN = /(\w+)(?:\[])?\s+([\w:/\\.,-]+)/;

export type Resource = (params: Record<string, unknown>) => Promise<unknown>;

export type Resources<T> = Record<keyof T, Resource>;

const extractParams = (url: string): string[] => {
  const params: string[] = [];
  let match = PARAM_PATTERN.exec(url);
  while (match) {
    params.push(match[1]);
    match = PARAM_PATTERN.exec(url);
  }
  return params;
};

const extractAction = (route: string): Action => {
  const matches = ROUTE_PATTERN.exec(route);
  if (matches) {
    const [, method, path] = matches;
    const url = path;
    const params = extractParams(path);

    return {
      method: method as Action['method'],
      url,
      params,
    };
  } else {
    throw new Error(`Invalid route: ${route}`);
  }
};

const isAction = (route: string | Action): route is Action => typeof route !== 'string';

const create = <T extends Routes>(routes: T): Resources<T> => {
  return Object.keys(routes).reduce((resources: Resources<T>, name: keyof T) => {
    const route = routes[name];
    const action = isAction(route) ? route : extractAction(route);
    resources[name] = (params: Record<string, unknown>) => {
      const replacedUrl = (action.params || []).reduce((url, key) => {
        return url.replace(`:${key}`, String(params[key]));
      }, action.url);
      const instance = axiosService.getAxios();
      if (action.method === 'GET') {
        return instance
          .get(replacedUrl, {
            params,
            withCredentials: action.withCredentials,
          })
          .then(response => response.data);
      } else {
        return instance
          .request({
            url: replacedUrl,
            method: action.method,
            data: params,
            withCredentials: action.withCredentials,
          })
          .then(response => response.data);
      }
    };
    return resources;
  }, {} as Resources<T>);
};

export const AxiosResourceFactory = {
  create,
};
