import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { PLANS_GET_ALL, PLANS_GET_BY_ID } from 'reducers/plan/plan.actions';
import { USER_COMPLETE_LOGIN } from 'reducers/user/user.actions';
import { handleActionsOnSuccess } from 'reducers/util';

const normalizePlan = plan => plan;

export const planEntitiesReducer = handleActionsOnSuccess(
  {
    [PLANS_GET_BY_ID]: (state, { payload: plan }) => EntitiesReducerUtils.upsert(state, plan, normalizePlan),
    [PLANS_GET_ALL]: (state, { payload: plans }) => EntitiesReducerUtils.upsertAll(state, plans, normalizePlan),
    [USER_COMPLETE_LOGIN]: (state, { payload: { selectedPlan } }) =>
      EntitiesReducerUtils.upsert(state, selectedPlan, normalizePlan),
  },
  {},
);
