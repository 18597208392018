import { connectService } from 'reducers/util';
import { DueDateRuleSourceType, TaskStatus, FieldType } from '@process-street/subgrade/process';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { DueDateHelper } from 'features/dynamic-due-dates/services/due-date-helper';

export class TaskDueDateListenerService {
  constructor($ngRedux, $rootScope, TaskActions, TaskDueDateService, TaskStatsService) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.logger = trace({ name: 'TaskDueDateListenerService' });
    this.TaskDueDateService = TaskDueDateService;
    this.TaskStatsService = TaskStatsService;

    connectService('TaskDueDateListenerService', $ngRedux, null, TaskActions)(this);
  }

  /**
   * Updates all tasks' due dates as a result of form field updated event
   * Only form field of type date will change anything
   * Only affected tasks will be updated
   *
   * This function DOES NOT modify passed in tasks, instead updates redux state
   *
   * @param rules
   * @param tasks
   * @param checklist
   * @param formFieldValues
   * @param updatedFormFieldValue
   * @param originalFormFieldValue
   *
   * @returns All the tasks which may or may not be updated
   */
  updateTaskDueDatesByFormFieldValueSource(
    rules,
    tasks,
    checklist,
    formFieldValues,
    updatedFormFieldValue,
    originalFormFieldValue,
  ) {
    this.logger.info('attempting to updated affected due dates after form field value update');

    if (originalFormFieldValue && updatedFormFieldValue.fieldValue === originalFormFieldValue.fieldValue) {
      this.logger.info('nothing changed -> nothing to update');
      return tasks;
    }

    if (updatedFormFieldValue.formFieldWidget.fieldType !== FieldType.Date) {
      this.logger.info('updated field is not type of date -> nothing to update');
      return tasks;
    }

    const relatedRules = DueDateHelper.findAllRulesByFormFieldWidgetGroupId(
      rules,
      updatedFormFieldValue.formFieldWidget.header.group.id,
    );

    if (relatedRules.length === 0) {
      this.logger.info('no related rules found -> nothing to update');
      return tasks;
    }

    this.logger.info('executing %d due date rule(s)', relatedRules.length);

    const tasksMap = this.buildTasksMap(tasks);

    relatedRules.forEach(rule => {
      DueDateHelper.withChecklistRule(checklist, rule, () => {
        const newDueDate = this.TaskDueDateService.calculateDueDateFromFormFieldValue(rule, formFieldValues);
        this._updateChecklistDueDateAndBroadcastChange(checklist, newDueDate);
      });

      DueDateHelper.withTaskRule(tasksMap, rule, ({ targetTask }) => {
        const newDueDate = this.TaskDueDateService.calculateDueDateFromFormFieldValue(rule, formFieldValues);
        this._logTaskInfo(targetTask, newDueDate);
        this._updateTaskDueDateAndBroadcastChange(targetTask, newDueDate);
      });
    });

    return tasks;
  }

  _toDate(newDueDate) {
    if (newDueDate && newDueDate.toDate instanceof Function) {
      return newDueDate.toDate().getTime();
    } else {
      return newDueDate;
    }
  }

  _logTaskInfo(task, newDueDate) {
    const date = newDueDate && newDueDate.format instanceof Function && newDueDate.format('YYYY-MM-DD [at] h:mmA');
    const taskName = task && task.taskTemplate && task.taskTemplate.name;

    this.logger.info(`updating due date of task '${taskName}' to ${date}'`);
  }

  /**
   * Updates due dates of all related tasks which are affected as a result of task completion
   *
   * This function DOES NOT modify passed in tasks, instead updates redux state
   *
   * @param rules
   * @param tasks
   * @param checklist
   * @param updatedTask
   * @param originalTask
   *
   * @returns All the tasks which may or may not be updated
   */
  updateTaskDueDatesByTaskCompletedDateSource(rules, tasks, checklist, updatedTask, originalTask) {
    this.logger.info('attempting to updated affected due dates after task completion');

    if (updatedTask.status === originalTask.status) {
      this.logger.info('nothing changed -> nothing to update');
      return tasks;
    }

    const relatedRules = this.findAllRulesByTaskTemplateGroupId(rules, updatedTask.taskTemplate.group.id);

    if (relatedRules.length === 0) {
      this.logger.info('no related rules found -> nothing to update');
      return tasks;
    }

    this.logger.info('executing %d due date rule(s)', relatedRules.length);

    const tasksMap = this.buildTasksMap(tasks);

    relatedRules.forEach(rule => {
      DueDateHelper.withChecklistRule(checklist, rule, () => {
        const newDueDate =
          updatedTask.status === TaskStatus.Completed
            ? this.TaskDueDateService.calculateDueDateFromTaskCompletedDate(rule, tasks)
            : null;
        this._updateChecklistDueDateAndBroadcastChange(checklist, newDueDate);
      });

      DueDateHelper.withTaskRule(tasksMap, rule, ({ targetTask }) => {
        const newDueDate =
          updatedTask.status === TaskStatus.Completed
            ? this.TaskDueDateService.calculateDueDateFromTaskCompletedDate(rule, tasks)
            : null;
        this._logTaskInfo(targetTask, newDueDate);
        this._updateTaskDueDateAndBroadcastChange(targetTask, newDueDate);
      });
    });

    return tasks;
  }

  findAllRulesByTaskTemplateGroupId(rules, taskTemplateGroupId) {
    return rules.filter(
      rule =>
        rule.sourceType === DueDateRuleSourceType.TaskCompletedDate &&
        rule.taskTemplateGroup &&
        rule.taskTemplateGroup.id === taskTemplateGroupId,
    );
  }

  findAllRulesByTargetTaskTemplateGroupId(rules, taskTemplateGroupId) {
    return rules.filter(
      rule => rule.targetTaskTemplateGroup && rule.targetTaskTemplateGroup.id === taskTemplateGroupId,
    );
  }

  /**
   * Updates all due dates of affected next tasks as a result of task completion
   *
   * This function DOES NOT modify passed in tasks, instead updates redux state
   *
   * @param rules
   * @param tasks
   * @param updatedTask
   * @param originalTask
   *
   * @returns All the tasks which may or may not be updated
   */
  updateTaskDueDatesByPreviousTaskCompletedDateSource(rules, tasks, updatedTask, originalTask, taskStatsMap) {
    this.logger.info('attempting to updated affected due date after previous task completion');

    if (updatedTask.status === originalTask.status) {
      this.logger.info('nothing changed -> nothing to update');
      return tasks;
    }

    const nextTask = this.TaskDueDateService.findNextTask(tasks, updatedTask.taskTemplate.group.id);
    if (!nextTask) {
      this.logger.info('next task not found -> nothing to update');
      return tasks;
    }

    const nextTaskStats = taskStatsMap?.[nextTask.id];

    if (nextTaskStats && nextTaskStats.taskId !== nextTask.id) {
      this.logger.info('task invisible -> nothing to update');
      return tasks;
    }

    const relatedRules = DueDateHelper.findAllRulesByPreviousTaskCompleteSource(rules).filter(
      rule => rule.targetTaskTemplateGroup?.id === nextTask.taskTemplate.group.id,
    );

    if (relatedRules.length === 0) {
      this.logger.info('no related rules found -> nothing to update');
      return tasks;
    }

    const tasksMap = this.buildTasksMap(tasks);

    relatedRules.forEach(rule => {
      DueDateHelper.withTaskRule(tasksMap, rule, ({ targetTask }) => {
        const newDueDate =
          updatedTask.status === TaskStatus.Completed
            ? this.TaskDueDateService.calculateDueDateFromPreviousTaskCompletedDate(rule, tasks)
            : null;
        this._logTaskInfo(targetTask, newDueDate);
        this._updateTaskDueDateAndBroadcastChange(targetTask, newDueDate);
      });
    });

    return tasks;
  }

  /**
   * Updates due dates of all affected tasks as a result of checklist due date update
   *
   * This function DOES NOT modify passed in tasks, instead updates redux state
   *
   * @param rules
   * @param tasks
   * @param checklist
   *
   * @returns All the tasks which may or may not be updated
   */
  updateTaskDueDatesByChecklistDueDateSource(rules, tasks, checklist) {
    this.logger.info('attempting to updated affected due dates after checklist due date updated event');

    const relatedRules = this.findAllRulesByChecklistDueDateSource(rules);

    if (relatedRules.length === 0) {
      this.logger.info('no related rules found -> nothing to update');
      return tasks;
    }

    const tasksMap = this.buildTasksMap(tasks);

    relatedRules.forEach(rule => {
      DueDateHelper.withTaskRule(tasksMap, rule, ({ targetTask }) => {
        const newDueDate = this.TaskDueDateService.calculateDueDateFromChecklistDueDate(rule, checklist);
        this._logTaskInfo(targetTask, newDueDate);
        this._updateTaskDueDateAndBroadcastChange(targetTask, newDueDate);
      });
    });

    return tasks;
  }

  _updateTaskDueDateAndBroadcastChange(targetTask, newDueDate) {
    const dueDate = this._toDate(newDueDate);

    if (targetTask.dueDate === dueDate) {
      return null; // no change
    }

    this.actions.updateInternal({ ...targetTask, dueDate });

    const data = {
      originalTask: targetTask,
      updatedTask: {
        id: targetTask.id,
        dueDate,
        dueDateOverridden: targetTask.dueDateOverridden,
      },
      updatedDueDate: dueDate,
      originalDueDate: targetTask.dueDate,
    };

    this.$rootScope.$broadcast(EventName.TASK_DYNAMIC_DUE_DATE_UPDATED, data);
    return data;
  }

  _updateChecklistDueDateAndBroadcastChange(checklist, newDueDate) {
    const dueDate = this._toDate(newDueDate);

    if (checklist.dueDate === dueDate) {
      return; // no change
    }

    this.logger.info('Updating checklist due date', dueDate);

    const data = {
      dueDate,
    };
    this.$rootScope.$broadcast(EventName.CHECKLIST_DYNAMIC_DUE_DATE_UPDATED, data);
  }

  findAllRulesByChecklistDueDateSource(rules) {
    return rules.filter(rule => rule.sourceType === DueDateRuleSourceType.ChecklistDueDate);
  }

  buildTasksMap(tasks) {
    const tasksMap = {};

    tasks.forEach(t => {
      tasksMap[t.taskTemplate.group.id] = t;
    });

    return tasksMap;
  }
}
