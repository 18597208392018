import { axiosService } from 'services/axios-service';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export namespace ImportWorkflowMutation {
  export type Params = {
    s3FileId: string;
    templateRevisionId: string;
  };

  export const key = ['post', 'ai-generation', 'import-template'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<void>(`/1/ai-generation/import-template`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<void, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
