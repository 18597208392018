import { Margin } from '@visx/xychart';

/** Bars end up being less than 40px tall but the value is used to calculate total chart height */
export const MAX_BAR_HEIGHT = 45;

export const X_AXIS_HEIGHT = 21.5;

export function useBarStackHorizontalHeights({
  rowCount,
  legendHeight,
  parentHeight,
  xAxisHeight = X_AXIS_HEIGHT,
  margin,
}: {
  rowCount: number;
  legendHeight: number;
  parentHeight: number;
  xAxisHeight?: number;
  margin: Margin;
}) {
  const maxBarsHeight = (rowCount - 1) * MAX_BAR_HEIGHT;
  const heightOffset = legendHeight + xAxisHeight + margin.top + margin.bottom;
  // If there are only three rows, for example, the height should default to the parentHeight prop
  const chartHeight = Math.max(parentHeight, maxBarsHeight) - heightOffset;
  return {
    chartHeight,
    heightOffset,
    chartOverflowHeight: parentHeight - heightOffset,
    xAxisHeight,
  };
}
