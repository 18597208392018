import { Muid } from '@process-street/subgrade/core';
import { TemplateRevision } from '@process-street/subgrade/process';
import { API } from 'pages/pages/_id/edit/page/api';
import { Mutation } from 'react-query/types/core/mutation';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';

type DiscardDraftMutationParams = { tmplRevId: Muid };
type DiscardDraftMutationResult = TemplateRevision;
export const DiscardDraftMutation = {
  key: 'discard-draft',
  mutationFn: API.discardDraft,
};

export function isDiscardDraftMutation(
  mutation: unknown,
): mutation is Mutation<DiscardDraftMutationResult, AxiosError, DiscardDraftMutationParams> {
  return typeof mutation === 'object' && (mutation as Mutation)?.options?.mutationKey === DiscardDraftMutation.key;
}

export const useDiscardDraftMutation = (
  options: UseMutationOptions<DiscardDraftMutationResult, AxiosError, DiscardDraftMutationParams> = {},
): UseMutationResult<DiscardDraftMutationResult, AxiosError, DiscardDraftMutationParams> => {
  return useMutation(({ tmplRevId }) => DiscardDraftMutation.mutationFn(tmplRevId), {
    mutationKey: DiscardDraftMutation.key,
    ...options,
  });
};
