import { createAction } from 'redux-actions';

export const DATA_SERVICE_CLEAR = 'dataService/CLEAR';

const clearDataStore = () => ({ type: DATA_SERVICE_CLEAR });

const setInitialized = createAction('dataService/SET_INITIALIZED', flag => flag);

const setChecklistDataInitialized = createAction('dataService/SET_CHECKLIST_DATA_INITIALIZED', flag => flag);

export const DataServiceActions = {
  clearDataStore,
  setInitialized,
  setChecklistDataInitialized,
};
