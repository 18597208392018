import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { SignUpLoadingState } from './loading-state';
import { ShellLeftPane } from './components/shell-left-pane';
import { ConsultationAppointment } from './consultation-appointment';
import { ConsultationPrompt } from './consultation-prompt';
import { SignUpCreateAccount } from './create-account';
import { OrganizationDetails } from './organization-details';
import { Referral } from './referral';

export const SignUpModule = angular
  .module('signUp', [])
  .component('psSignUpConsultationAppointment', reactToAngular(ConsultationAppointment))
  .component('psSignUpConsultationPrompt', reactToAngular(ConsultationPrompt))
  .component('psSignUpOrganizationDetails', reactToAngular(OrganizationDetails))
  .component('psSignUpReferral', reactToAngular(Referral))
  .component('psSignUpShellLeftPane', reactToAngular(ShellLeftPane, ['templateName', 'showBenefits']))
  .component('psSignUpCreateAccount', reactToAngular(SignUpCreateAccount))
  .component('psSignUpLoadingState', reactToAngular(SignUpLoadingState)).name;
