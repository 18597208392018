export enum AvatarSize {
  ExtraSmall = 'ExtraSmall',
  Small = 'Small',
  Normal = 'Normal',
  Medium = 'Medium',
  Large = 'Large',
}

export const avatarSizeMap: { [key in AvatarSize]: number } = {
  [AvatarSize.ExtraSmall]: 16,
  [AvatarSize.Small]: 24,
  [AvatarSize.Normal]: 32,
  [AvatarSize.Medium]: 40,
  [AvatarSize.Large]: 48,
};
