import { ICellRendererParams } from '@ag-grid-community/core';
import { Muid } from '@process-street/subgrade/core';
import { AssigneesRenderer } from '../../AssigneesRenderer';
import { GridSelectors } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/user-value.selectors';
import { UserValue } from 'components/dashboard/models/grid';
import React from 'react';
import { useSelector } from 'react-redux';

export interface MembershipFieldRendererProps extends Partial<ICellRendererParams> {
  organizationMembershipIds: Muid[];
}

export const MembershipFieldRenderer: React.FunctionComponent<
  React.PropsWithChildren<MembershipFieldRendererProps>
> = ({ organizationMembershipIds }) => {
  const userValuesMap = useSelector(GridSelectors.getUserValueMapSelector);

  if (!organizationMembershipIds || !organizationMembershipIds.length || !userValuesMap) {
    return <div />;
  }

  const users: UserValue[] = organizationMembershipIds.map(id => userValuesMap[id]).filter(uv => !!uv);

  return (
    <div>
      <AssigneesRenderer value={users} />
    </div>
  );
};
