import { HttpStatus } from '@process-street/subgrade/util';

export const ResponseCodes = {
  OrganizationUserLimitReached: 'OrganizationUserLimitReached',
  PlanUserLimitReached: 'PlanUserLimitReached',
};

export class ResponseUtilsService {
  isUsersLimitReached(response) {
    const statusIsPaymentRequired = response.status === HttpStatus.PAYMENT_REQUIRED;
    const codeIsUsersLimitReached =
      response.data &&
      (response.data.code === ResponseCodes.OrganizationUserLimitReached ||
        response.data.code === ResponseCodes.PlanUserLimitReached);

    return statusIsPaymentRequired && codeIsUsersLimitReached;
  }

  getUsersLimitReachedMessage(response) {
    const { code } = response.data;

    if (code === ResponseCodes.OrganizationUserLimitReached) {
      return `You've reached your limit of ${response.data.maxQuantity} user(s).<br>
                        Contact support@process.st to add more users.`;
    } else {
      return `Your plan has a maximum of ${response.data.maxQuantity} user(s). <br>
                        Please upgrade your plan to add more users.`;
    }
  }
}

export const responseUtilsService = new ResponseUtilsService();
