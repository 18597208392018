import * as React from 'react';

import { Box, Flex, Divider, FlexProps } from 'components/design/next';

import { ActiveOutgoingList } from '../common/active-outgoing-list';

export type NoActiveProps = {
  showOnlyIcon?: boolean;
} & FlexProps;

export const WithActiveAutomationsButton: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Flex w="full" alignItems="center" flexDirection="column" mt="-5" mb="7">
      <Box height="6">
        <Divider orientation="vertical" borderStyle="solid" borderColor="gray.300" />
      </Box>
      <ActiveOutgoingList />
    </Flex>
  );
};
