import { orderTreeService } from '@process-street/subgrade/core';
import { OrderTree } from '@process-street/subgrade/process';
import { IWindowService } from 'angular';

export function isBrowserSafari(w: Window | IWindowService = window): boolean {
  return /^((?!chrome|android).)*safari/i.test(w.navigator.userAgent);
}

const identity = <T>(x: T): T => x;

export function toOrderTrees<T>(as: T[], transform: (arg: T) => { orderTree?: OrderTree }): OrderTree[];
export function toOrderTrees<T extends { orderTree?: OrderTree }>(as: T[]): OrderTree[];
export function toOrderTrees<T extends { orderTree?: OrderTree }>(
  as: T[],
  transform: (arg: T) => { orderTree?: OrderTree } = identity,
) {
  return as.map(a => transform(a).orderTree);
}

export function getOrderableComparer<T>(transform?: (arg: T) => { orderTree?: OrderTree }): (a: T, b: T) => number;
export function getOrderableComparer<T extends { orderTree?: OrderTree }>(
  transform?: (arg: T) => { orderTree?: OrderTree },
): (a: T, b: T) => number {
  return function (a, b) {
    const f = transform ?? identity<T>;
    const orderTreeA = f(a).orderTree;
    const orderTreeB = f(b).orderTree;
    if (!orderTreeA || !orderTreeB) {
      throw new Error(`orderTree missing: ${f(a)}, ${f(b)}`);
    }
    return orderTreeService.compare(orderTreeA, orderTreeB);
  };
}
