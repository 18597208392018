import {
  DateFormFieldWidget,
  EmailFormFieldWidget,
  FieldType,
  FileFormFieldWidget,
  MultiChoiceFormFieldWidget,
  NumberFormFieldWidget,
  SelectFormFieldWidget,
  TableFormFieldWidget,
  TextareaFormFieldWidget,
  TextFormFieldWidget,
  UrlFormFieldWidget,
} from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import { makeTextFormFieldMachine, TextFormFieldMachine } from './text-form-field';
import {
  FormFieldResponseWidget as FormFieldWidget,
  FormFieldMachineBuilderProps,
  FormFieldResponseWidget,
} from '../../types';
import { makeTextareaFormFieldMachine, TextareaFormFieldMachine } from './textarea-form-field';
import { makeEmailFormFieldMachine, EmailFormFieldMachine } from './email-form-field';
import { makeUrlFormFieldMachine, UrlFormFieldMachine } from './url-form-field';
import { makeSelectFormFieldMachine, SelectFormFieldMachine } from './select-form-field';
import { ActorRefFrom } from 'xstate';
import { makeFileFormFieldMachine, FileFormFieldMachine } from './file-form-field';
import { DateFormFieldMachine, makeDateFormFieldMachine } from './date-form-field';
import { makeNumberFormFieldMachine, NumberFormFieldMachine } from './number-form-field';
import { makeTableFormFieldMachine, TableFormFieldMachine } from './table-form-field';

export const makeFormFieldMachine = <W extends FormFieldResponseWidget>(
  props: FormFieldMachineBuilderProps<W>,
): FormFieldWidgetMachineLookup<W> => {
  return match<FormFieldMachineBuilderProps>(props)
    .with({ formFieldWidget: { fieldType: FieldType.Text } }, props =>
      makeTextFormFieldMachine(props as FormFieldMachineBuilderProps<TextFormFieldWidget>),
    )
    .with({ formFieldWidget: { fieldType: FieldType.Textarea } }, props =>
      makeTextareaFormFieldMachine(props as FormFieldMachineBuilderProps<TextareaFormFieldWidget>),
    )
    .with({ formFieldWidget: { fieldType: FieldType.Email } }, props =>
      makeEmailFormFieldMachine(props as FormFieldMachineBuilderProps<EmailFormFieldWidget>),
    )
    .with({ formFieldWidget: { fieldType: FieldType.Url } }, props =>
      makeUrlFormFieldMachine(props as FormFieldMachineBuilderProps<UrlFormFieldWidget>),
    )
    .with({ formFieldWidget: { fieldType: FieldType.Select } }, props =>
      makeSelectFormFieldMachine(props as FormFieldMachineBuilderProps<SelectFormFieldWidget>),
    )
    .with({ formFieldWidget: { fieldType: FieldType.MultiChoice } }, props =>
      makeSelectFormFieldMachine(props as FormFieldMachineBuilderProps<MultiChoiceFormFieldWidget>),
    )
    .with({ formFieldWidget: { fieldType: FieldType.File } }, props =>
      makeFileFormFieldMachine(props as FormFieldMachineBuilderProps<FileFormFieldWidget>),
    )
    .with({ formFieldWidget: { fieldType: FieldType.Date } }, props =>
      makeDateFormFieldMachine(props as FormFieldMachineBuilderProps<DateFormFieldWidget>),
    )
    .with({ formFieldWidget: { fieldType: FieldType.Number } }, props =>
      makeNumberFormFieldMachine(props as FormFieldMachineBuilderProps<NumberFormFieldWidget>),
    )
    .with({ formFieldWidget: { fieldType: FieldType.Table } }, props =>
      makeTableFormFieldMachine(props as FormFieldMachineBuilderProps<TableFormFieldWidget>),
    )
    .run() as FormFieldWidgetMachineLookup<W>;
};

export type FormFieldWidgetMachine = ReturnType<typeof makeFormFieldMachine>;

export type FormFieldWidgetMachineLookup<Widget extends FormFieldWidget> = Widget extends { fieldType: FieldType.Text }
  ? TextFormFieldMachine
  : Widget extends { fieldType: FieldType.Textarea }
  ? TextareaFormFieldMachine
  : Widget extends { fieldType: FieldType.Email }
  ? EmailFormFieldMachine
  : Widget extends { fieldType: FieldType.Url }
  ? UrlFormFieldMachine
  : Widget extends { fieldType: FieldType.Select }
  ? SelectFormFieldMachine
  : Widget extends { fieldType: FieldType.MultiChoice }
  ? SelectFormFieldMachine
  : Widget extends { fieldType: FieldType.File }
  ? FileFormFieldMachine
  : Widget extends { fieldType: FieldType.Date }
  ? DateFormFieldMachine
  : Widget extends { fieldType: FieldType.Number }
  ? NumberFormFieldMachine
  : Widget extends { fieldType: FieldType.Table }
  ? TableFormFieldMachine
  : never;

export type FormFieldWidgetActorLookup<Widget extends FormFieldWidget> = ActorRefFrom<
  FormFieldWidgetMachineLookup<Widget>
>;

export type { FormFieldMachineBuilderProps };
