import { Option } from '../core';
import { ChecklistStatus } from '../process';
import { ProgressStatus } from './conditional-filters';
import { ChecklistGridData, InboxGridData } from './grid';

const DueSoonTimeLimit = 24 * 60 * 60 * 1000;

const getByDueDate = (dueDate: Option<number>): ProgressStatus => {
  if (dueDate) {
    const dueFromNow = dueDate - Date.now();

    if (dueFromNow <= 0) {
      return ProgressStatus.Overdue;
    }

    if (dueFromNow <= DueSoonTimeLimit) {
      return ProgressStatus.DueSoon;
    }
  }

  return ProgressStatus.OnTrack;
};

export const getByInboxGridData = (inboxItem: InboxGridData): ProgressStatus => {
  if (inboxItem.checklistStatus === ChecklistStatus.Archived) {
    return ProgressStatus.Archived;
  }

  return getByChecklistGridData(inboxItem);
};

export const getByChecklistGridData = (row: ChecklistGridData): ProgressStatus => {
  if (row.checklistCompletedDate) {
    return ProgressStatus.Completed;
  }

  return getByDueDate(row.earliestDueDate);
};

export const ProgressStatusUtils = {
  getByDueDate,
  getByInboxGridData,
};
