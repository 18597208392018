import * as React from 'react';
import { BarStackHorizontal } from '@visx/shape';
import { Box, Stat, StatLabel, StatNumber, StatHelpText } from 'components/design/next';
import { usePopper } from 'react-popper';
import { ParentSize } from '@visx/responsive';
import {
  ChecklistAnalyticsQuery,
  isSingleWorkflowResponse,
  SingleWorkflowResponse,
  useChecklistAnalyticsQuery,
} from 'features/checklists/query-builder';
import { match, P } from 'ts-pattern';
import { DerivedTaskStatus as Status } from '@process-street/subgrade/dashboard';
import { TaskStat, TooltipEffectContext } from './tooltip-state';
import { LegendItem } from './legend-item';
import { Rect } from './rect';
import { Tick } from './tick';
import { TASK_COMPLETION_AND_STATUS } from '../common/visualization-names';
import { ParentSizeRenderProps } from '../common/types';
import { useChecklistSearchCriteria } from 'pages/reports/hooks/use-checklist-search-criteria';
import { EmptyBarStackHorizontal } from '../empty-bar-stack-horizontal';
import {
  useBarStackHorizontal,
  BarStackHorizontalAxisLeft,
  makeBarStackHorizontalLegend,
  BarStackHorizontalAxisBottom,
  BarStackHorizontalScrollable,
} from '../bar-stack-horizontal';
import { tooltipReducer, makeInitialTooltipState } from './tooltip-state';
import { TASK_STATUS_COLORS as COLORS, TASK_STATUS_KEYS as KEYS, TASK_STATUS_LABELS } from '../common/constants';
import { getDerivedTaskStatusPercentage } from '../common/utils';
import { ChartTooltip } from '../chart-tooltip';
import { ChartSpinner } from '../common/chart-spinner';
import { ChartAlert } from '../chart-alert';
import { Muid } from '@process-street/subgrade/core';

export type ChecklistTaskStatusByTaskProps = Pick<SingleWorkflowResponse, 'taskStatusStatsByTaskGroupId'> & {
  parentSize: ParentSizeRenderProps;
};

const BarStackHorizontalLegend = makeBarStackHorizontalLegend<Status>();

export const ChecklistTaskStatusByTaskChart: React.VFC<ChecklistTaskStatusByTaskProps> = ({
  parentSize,
  taskStatusStatsByTaskGroupId,
}) => {
  const { width: parentWidth } = parentSize;

  const data = React.useMemo(() => {
    return Object.entries(taskStatusStatsByTaskGroupId)
      .filter(([_, { name }]) => !name?.endsWith(':'))
      .map(([id, rest]) => ({ id, ...rest }))
      .sort(ChecklistAnalyticsQuery.sortByOrderTree);
  }, [taskStatusStatsByTaskGroupId]);

  const UNNAMED = '(unnamed)';
  const getName = (id: Muid) => taskStatusStatsByTaskGroupId[id].name ?? UNNAMED;

  const [tooltipState, dispatch] = React.useReducer(tooltipReducer, makeInitialTooltipState());
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const popper = usePopper(tooltipState.referenceElement, popperElement);

  const {
    legendRef,
    colorScale,
    xScale,
    yScale,
    yMax,
    chartHeight,
    chartOverflowHeight,
    xAxisHeight,
    margin,
    invertYCoord,
  } = useBarStackHorizontal({
    data,
    keys: KEYS,
    yDomain: datum => datum.id,
    yTickLabelDomain: d => d.name ?? UNNAMED,
    parentSize,
    colors: COLORS,
  });

  return parentWidth < 10 ? null : (
    <TooltipEffectContext.Provider value={dispatch}>
      <Box {...{ 'position': 'relative', 'aria-label': TASK_COMPLETION_AND_STATUS }}>
        <BarStackHorizontalScrollable
          {...{
            chartHeight,
            chartOverflowHeight,
            margin,
            parentWidth,
            xAxisHeight,
            barStackHorizontal: (
              <BarStackHorizontal<TaskStat, Status>
                data={data}
                keys={KEYS}
                height={yMax}
                y={x => x.id}
                xScale={xScale}
                yScale={yScale}
                color={colorScale}
              >
                {barStacks =>
                  barStacks.map(barStack =>
                    barStack.bars.map(bar => (
                      <Rect key={`barstack-horizontal-${barStack.index}-${bar.index}`} bar={bar} />
                    )),
                  )
                }
              </BarStackHorizontal>
            ),
            axisLeft: (
              <BarStackHorizontalAxisLeft
                numTicks={data.length}
                scale={yScale}
                tickComponent={Tick}
                tickFormat={getName}
              />
            ),
            axisBottom: <BarStackHorizontalAxisBottom scale={xScale} />,
          }}
        />

        <BarStackHorizontalLegend ref={legendRef} aria-label="task completion legend" scale={colorScale}>
          {label => <LegendItem key={label.datum} {...label} />}
        </BarStackHorizontalLegend>

        {tooltipState.data && (
          <ChartTooltip ref={setPopperElement} style={popper.styles.popper} {...popper.attributes.popper}>
            {match(tooltipState.data)
              .with({ bar: P.not(P.nullish) }, ({ key: status, bar: { data: taskStat } }) => {
                const percent = getDerivedTaskStatusPercentage(taskStat)(status);
                return (
                  <Stat aria-label="task status percentage and total by task">
                    <StatLabel>{TASK_STATUS_LABELS[status]}</StatLabel>
                    <StatNumber color={colorScale(status)}>{percent}%</StatNumber>
                    <StatHelpText>Total: {taskStat[status]}</StatHelpText>
                  </Stat>
                );
              })
              .with({ status: P.not(P.nullish) }, ({ total, status }) => (
                <Stat aria-label="task status total">
                  <StatLabel>Total</StatLabel>
                  <StatNumber color={colorScale(status)}>{total}</StatNumber>
                </Stat>
              ))
              .with({ y: P.number }, ({ y }) => {
                const id = invertYCoord(y);
                const taskName = getName(id);
                return (
                  <Stat aria-label="full task name">
                    <StatLabel>{taskName}</StatLabel>
                  </Stat>
                );
              })
              .exhaustive()}
          </ChartTooltip>
        )}
      </Box>
    </TooltipEffectContext.Provider>
  );
};

export const ChecklistTaskStatusByTask = () => {
  const searchCriteria = useChecklistSearchCriteria();
  const { templateIds } = searchCriteria;
  const checklistAnalyticsQuery = useChecklistAnalyticsQuery(searchCriteria, {
    select: analytics => (isSingleWorkflowResponse(analytics) ? analytics.taskStatusStatsByTaskGroupId ?? {} : {}),
  });
  return match(checklistAnalyticsQuery)
    .with({ status: 'loading' }, () => <ChartSpinner />)
    .with({ status: 'error' }, ({ error }) => <ChartAlert statusCode={error.response?.status} />)
    .with({ status: 'success', data: P.when(d => d && Object.keys(d).length > 0) }, ({ data }) => (
      <ParentSize>
        {parentSize => (
          <ChecklistTaskStatusByTaskChart
            key={templateIds?.join(':')}
            taskStatusStatsByTaskGroupId={data}
            parentSize={parentSize}
          />
        )}
      </ParentSize>
    ))
    .with({ status: 'success' }, () => <EmptyBarStackHorizontal />)
    .otherwise(() => null);
};
