import { queryClient } from 'components/react-root';
import { Muid } from '@process-street/subgrade/core';
import { GetTemplatesPermissionStatsByOrganization } from './query';

const invalidateCache = (organizationId: Muid, parentFolderId: Muid) => {
  queryClient.invalidateQueries(GetTemplatesPermissionStatsByOrganization.getKey({ organizationId, parentFolderId }));
};

export const TemplatePermissionsStatsQueryService = {
  queryClient,
  invalidateCache,
};
