import angular from 'angular';

angular.module('frontStreetApp.services').service('TextInsertService', function ($window) {
  this.insert = function (element, text) {
    if (element === undefined) {
      return;
    }

    const scrollPos = element.scrollTop;
    let pos = 0;

    const ie =
      typeof $window.document.selection !== 'undefined' && $window.document.selection.type !== 'Control' && true;
    const w3 = typeof $window.getSelection !== 'undefined' && true;

    let range = null;
    if (ie) {
      element.focus();
      range = $window.document.selection.createRange();
      range.moveStart('character', -element.value.length);
      pos = range.text.length;
    } else if (w3) {
      pos = element.selectionStart;
    }

    const front = element.value.substring(0, pos);
    const back = element.value.substring(pos, element.value.length);
    element.value = front + text + back;
    pos += text.length;

    if (ie) {
      element.focus();
      range = $window.document.selection.createRange();
      range.moveStart('character', -element.value.length);
      range.moveStart('character', pos);
      range.moveEnd('character', 0);
      range.select();
    } else if (w3) {
      element.selectionStart = pos;
      element.selectionEnd = pos;
      element.focus();
    }

    element.scrollTop = scrollPos;

    // It works like this for IE. Explanations are here:
    // http://stackoverflow.com/questions/17109850/update-angular-model-after-setting-input-value-with-jquery
    angular.element(element).trigger('input'); // Use for Chrome/Firefox/Edge
    angular.element(element).trigger('change'); // Use for Chrome/Firefox/Edge + IE11
  };
});
