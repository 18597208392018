import { ChecklistTaskListComponents as Components } from '..';
import { Checklist } from '@process-street/subgrade/process';
import * as React from 'react';
import { HStack, useToken } from 'components/design/next';
import { GetCoverIconByTemplateId } from 'app/features/cover-icon/query-builder';
import { ChecklistCoverIcon } from 'app/features/cover-icon/components/checklist';
import { Breadcrumbs } from 'app/components/breadcrumbs';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { AutomationsIndicator } from './automations-indicator';
import { HeaderActionsMenu } from './header-actions-menu';

type TaskListTopControlsProps = {
  checklist: Checklist;
};

export function TaskListTopControls({ checklist }: TaskListTopControlsProps) {
  const templateId = checklist.template.id;
  const { data: coverIcon } = GetCoverIconByTemplateId.useQuery({ templateId });

  const user = useCurrentUser();
  const isAnonymous = user ? isAnonymousUser(user) : true;

  const headerRef = React.useRef<HTMLDivElement>(null);

  const [isPinned, setIsPinned] = React.useState(false);

  React.useEffect(() => {
    const element = headerRef.current;
    if (!element) return;
    const observer = new IntersectionObserver(([e]) => setIsPinned(e.intersectionRatio < 1), {
      threshold: [1],
      // make sure we don't check for horizontal intersections on drawer open-close
      rootMargin: '0px 5000px',
    });

    observer.observe(element);
    return () => observer.unobserve(element);
  }, []);

  const [size12] = useToken('sizes', ['12']);

  return (
    <>
      <HStack justifyContent="space-between" w="full">
        {!isAnonymous && <Breadcrumbs templateId={templateId} shouldShowTemplateBreadcrumb />}
        <AutomationsIndicator checklist={checklist} />
      </HStack>
      <HStack
        justifyContent="flex-start"
        // spread to container width when pinned
        w={isPinned ? `calc(100% + ${size12})` : 'full'}
        mx={isPinned ? -6 : 0}
        px={isPinned ? 6 : 0}
        position="sticky"
        zIndex="sticky"
        ref={headerRef}
        top="-1px"
        bgColor={isPinned ? 'brand.50' : undefined}
        transition="background-color 0.2s ease-in-out, padding-top 0.2s ease-in-out, padding-bottom 0.2s ease-in-out"
        py={isPinned ? 2 : 0}
      >
        {coverIcon && <ChecklistCoverIcon flexShrink="0" w={10} h={10} mr={3} icon={coverIcon} />}
        <Components.ChecklistName checklist={checklist} />
        <HeaderActionsMenu />
      </HStack>
    </>
  );
}
