import { Template, TemplateRevisionStatus } from '@process-street/subgrade/process';
import React from 'react';
import { useInjector } from 'components/injection-provider';
import {
  GetNewestTemplateRevisionsByTemplateIdQuery,
  useGetNewestTemplateRevisionsByTemplateIdQuery,
} from 'features/template/query-builder';
import { useCurrentUser } from 'hooks/use-current-user';
import messageTemplate from 'directives/template-share/modal/template-share-modal.component.html';

export function useShareFormButton(template: Template): { onShowShareTemplate: () => void } {
  const { MessageBox } = useInjector('MessageBox');
  const currentUser = useCurrentUser();

  const templateRevisionsQuery = useGetNewestTemplateRevisionsByTemplateIdQuery({ templateId: template.id });

  const onShowShareTemplate = React.useCallback(() => {
    templateRevisionsQuery.refetch().then(response => {
      const { data: templateRevisions } = response;

      if (template && templateRevisions) {
        const activeTemplateRevision = GetNewestTemplateRevisionsByTemplateIdQuery.select.where({
          status: TemplateRevisionStatus.Finished,
        })(templateRevisions);

        MessageBox.custom({
          animation: false,
          templateUrl: messageTemplate,
          controller: 'TemplateShareModal',
          size: 'lg',
          options: { template, templateRevision: activeTemplateRevision, user: currentUser },
        });
      }
    });
  }, [MessageBox, currentUser, template, templateRevisionsQuery]);

  return {
    onShowShareTemplate,
  };
}
