import {
  Attachment,
  FinishUploadForAttachmentRequest,
  FinishUploadForFormFieldWidgetRequest,
  FinishUploadForTemplateWidgetRequest,
  FormFieldValueUpdateResult,
  GetUploadUrlForAttachmentRequest,
  GetUploadUrlForAttachmentResponse,
  GetUploadUrlForFormFieldWidgetRequest,
  GetUploadUrlForFormFieldWidgetResponse,
  GetUploadUrlForTemplateWidgetRequest,
  GetUploadUrlForTemplateWidgetResponse,
  Widget,
} from '@process-street/subgrade/process';
import { AngularResource } from 'reducers/angular-types';
import { ResourceFactory } from 'app/resources/factory/resource-factory';

export const FileUploadResourceFactory = (resourceFactory: ResourceFactory) => {
  'ngInject';

  return resourceFactory.create({
    finishUploadForAttachment: 'POST /1/attachments/finish-upload',
    finishUploadForFormFieldWidget: 'POST /1/checklist-revisions/:checklistRevisionId/widgets/:widgetId/finish-upload',
    finishUploadForTemplateWidget: 'POST /1/widgets/:headerId/finish-upload',

    getUploadUrlForAttachment: 'POST /1/attachments/upload-url',
    getUploadUrlForFormFieldWidget: 'POST /1/checklist-revisions/:checklistRevisionId/widgets/:widgetId/upload-url',
    getUploadUrlForTemplateWidget: 'POST /1/widgets/:headerId/upload-url',
  });
};

export interface FileUploadResource {
  getUploadUrlForAttachment(
    request: GetUploadUrlForAttachmentRequest,
  ): AngularResource<GetUploadUrlForAttachmentResponse>;

  finishUploadForAttachment(request: FinishUploadForAttachmentRequest): AngularResource<Attachment>;

  getUploadUrlForFormFieldWidget(
    request: GetUploadUrlForFormFieldWidgetRequest,
  ): AngularResource<GetUploadUrlForFormFieldWidgetResponse>;

  finishUploadForFormFieldWidget(
    request: FinishUploadForFormFieldWidgetRequest,
  ): AngularResource<FormFieldValueUpdateResult>;

  getUploadUrlForTemplateWidget(
    request: GetUploadUrlForTemplateWidgetRequest,
  ): AngularResource<GetUploadUrlForTemplateWidgetResponse>;

  finishUploadForTemplateWidget(request: FinishUploadForTemplateWidgetRequest): AngularResource<Widget>;
}
