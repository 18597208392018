import * as React from 'react';
import { Text, VStack } from 'components/design/next';

export type MainStatProps = { label: React.ReactNode };

export const MainStat: React.FC<React.PropsWithChildren<MainStatProps>> = ({ children, label }) => {
  return (
    <VStack w="full" spacing="1" py="3" px={{ base: '2' }} bgColor="gray.50" borderRadius="md">
      <Text fontSize="xl" color="gray.700">
        {children}
      </Text>
      <Text color="gray.500" lineHeight="tall">
        {label}
      </Text>
    </VStack>
  );
};
