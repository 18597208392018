import * as React from 'react';
import { DropdownIndicatorProps, GroupBase } from 'react-select';

import { Flex, HStack, Icon, Text } from 'components/design/next';
import { PreviewAvatarComponent } from './preview-avatar-component';
import { getPreviewerType, PreviewerOption, SandboxUserType, UserTypeOption, UserTypeOptionCategory } from './utils';
import { match } from 'ts-pattern';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { ChakraAvatar } from 'app/components/design/next/chakra-avatar';
import { User } from '@process-street/subgrade/core';
import { UserOption } from 'app/utils/user-option-transformer';
import { AssignmentType, RoleOption } from './role-option-transformer';

export const CustomDropdownIndicator = (
  props: DropdownIndicatorProps<PreviewerOption, false, GroupBase<PreviewerOption>>,
) => {
  const value = props.getValue();
  const currentUser = useCurrentUser();
  const [selected] = value;
  const previewerTypeOption = getPreviewerType(selected);
  return match(previewerTypeOption)
    .with(SandboxUserType.User, () => {
      return (
        <HStack color="gray.600" px="1" gap={2} {...props.innerProps}>
          <PreviewAvatarComponent user={(selected as UserOption).user} isEmailVisible={false} />
          <Icon icon="chevron-down" size="4" variant="far" color="gray.600" />
        </HStack>
      );
    })
    .with(SandboxUserType.UserType, () => {
      const optionContent = getUserTypeContent({ userType: selected as UserTypeOption, currentUser });
      return (
        <HStack gap={2} {...props.innerProps}>
          {optionContent}
          <Icon icon="chevron-down" size="4" variant="far" color="gray.600" />
        </HStack>
      );
    })
    .with(SandboxUserType.Role, () => {
      const { assignmentType, name } = selected as RoleOption;
      return (
        <HStack gap={2} {...props.innerProps}>
          <Flex bgColor="green.500" borderRadius="full" w={6} h={6} alignItems="center" justifyContent="center">
            <Icon
              size="4"
              icon={assignmentType === AssignmentType.Members ? 'users' : 'envelope'}
              variant="fas"
              color="white"
            />
          </Flex>
          <Text>{name}</Text>
          <Icon icon="chevron-down" size="4" variant="far" color="gray.600" />
        </HStack>
      );
    })
    .otherwise(() => null);
};

export const getUserTypeContent = ({
  userType,
  currentUser,
}: {
  userType: UserTypeOption;
  currentUser: User | undefined;
}) => {
  return match(userType.category)
    .with(UserTypeOptionCategory.WorkflowRunner, () => (
      <>
        <ChakraAvatar user={currentUser} size="xs" />
        <Text color="gray.600" noOfLines={1}>
          {userType.label}
        </Text>
      </>
    ))
    .with(UserTypeOptionCategory.Guest, () => (
      <>
        <Flex bgColor="gray.300" borderRadius="full" w={6} h={6} alignItems="center" justifyContent="center">
          <Icon size="4" icon="user" variant="far" color="white" />
        </Flex>
        <Text color="gray.600" noOfLines={1}>
          {userType.label}
        </Text>
      </>
    ))
    .with(UserTypeOptionCategory.ShareLink, () => (
      <>
        <Flex bgColor="gray.300" borderRadius="full" w={6} h={6} alignItems="center" justifyContent="center">
          <Icon size="4" icon="link" variant="far" color="white" />
        </Flex>
        <Text color="gray.600" noOfLines={1}>
          {userType.label}
        </Text>
      </>
    ))
    .otherwise(() => null);
};
