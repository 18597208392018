import * as React from 'react';
import { Plan, PlanLevel, SubscriptionState } from '@process-street/subgrade/billing';
import { PlanSubscriptionDetails } from 'pages/organizations/manage/billing/models';
import {
  PlanAlertActiveFree,
  PlanAlertActivePro,
  PlanAlertCancelAtPeriodEnd,
  PlanAlertExceeded,
  PlanAlertExpired,
  PlanAlertLegacy,
  PlanAlertPastDue,
  PlanAlertTrial,
  PlanAlertTrialExpired,
} from 'pages/organizations/manage/billing/components/plan-alert/ui';
import { useInjector } from 'components/injection-provider';
import { Organization, OrganizationDiscount, Subscription } from '@process-street/subgrade/core';

export type PlanAlertWrapperProps = {
  discount: OrganizationDiscount;
  onContactClick: () => void;
  onUpgradeClick: () => void;
  plan: Plan;
  planSubscriptionDetails: PlanSubscriptionDetails;
  quantity: number;
  subscription: Subscription;
  organization: Organization;
  onStripeError?: () => Promise<void> | void;
};

export const PlanAlertWrapper: React.FC<React.PropsWithChildren<PlanAlertWrapperProps>> = ({
  discount,
  plan,
  quantity,
  planSubscriptionDetails,
  subscription,
  onContactClick,
  onUpgradeClick,
  organization,
  onStripeError,
}) => {
  const { BillingService, PlanService } = useInjector('BillingService', 'PlanService');
  const legacy = PlanService.isPlanIdLegacy(plan.id);

  switch (planSubscriptionDetails.subscriptionState) {
    case SubscriptionState.TRIALING:
      const daysLeft = BillingService.getTrialDaysRemaining(subscription);
      if (daysLeft > 0) {
        return <PlanAlertTrial daysLeft={daysLeft} />;
      } else {
        return <PlanAlertTrialExpired />;
      }

    case SubscriptionState.ACTIVE:
      if (legacy) {
        const planCost = PlanService.calculatePlanCost(plan, quantity, discount);

        return <PlanAlertLegacy pricePerMember={planCost.cost} onUpgradeClick={onUpgradeClick} />;
      } else if (plan.level === PlanLevel.Free) {
        return <PlanAlertActiveFree />;
      } else if ([PlanLevel.Professional, PlanLevel.Enterprise].includes(plan.level)) {
        return <PlanAlertActivePro onContactClick={onContactClick} />;
      } else {
        return null;
      }

    case SubscriptionState.PAST_DUE:
      return <PlanAlertPastDue organization={organization} errorCallback={onStripeError} />;

    case SubscriptionState.EXCEEDED:
      return <PlanAlertExceeded onUpgradeClick={onUpgradeClick} />;

    case SubscriptionState.EXPIRED:
      return <PlanAlertExpired />;

    case SubscriptionState.CANCEL_AT_PERIOD_END:
      const { currentPeriodEndDate } = subscription;
      if (currentPeriodEndDate) {
        return <PlanAlertCancelAtPeriodEnd currentPeriodEndDate={currentPeriodEndDate} />;
      } else {
        return null;
      }

    default:
      return null;
  }
};
