import angular from 'angular';
import templateUrl from './template-date-field.component.html';
import './template-date-field.scss';
import { FormFieldEvent } from 'services/form-field-event';

angular.module('frontStreetApp.directives').component('psTemplateDateFormField', {
  bindings: {
    widget: '<',
    editable: '<',
    onUpdateWidget: '&',
  },
  templateUrl,
  controller($rootScope) {
    const ctrl = this;

    // Viewable

    ctrl.clickDisabledButton = function () {
      $rootScope.$broadcast(FormFieldEvent.DISABLED_FORM_FIELD_CLICKED);
    };

    // Actionable

    ctrl.updateFormFieldWidget = function () {
      ctrl.onUpdateWidget({ widget: ctrl.widget });
    };
  },
});
