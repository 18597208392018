import { Organization } from '@process-street/subgrade/core';
import { SavedViewFolder } from 'components/dashboard/models/saved-views';

export enum SettingsKey {
  CHECKLIST_EDITOR = 'checklistEditor',
  CHECKLIST_STATS = 'checklistStats',
  DASHBOARD = 'dashboard',
  DASHBOARD_OPEN_FOLDERS = 'dashboardOpenFolders',
  INBOX = 'inbox',
  DEFAULT_HOMEPAGE = 'defaultHomepage',
  ORGANIZATION_USE_FREQUENCY_MAP = 'organizationUseFrequencyMap',
  SELECTED_ORGANIZATION_ID = 'selectedOrganizationId',
  TEMPLATE_EDITOR = 'templateEditor',
  ONBOARDING = 'onboarding',
  SANDBOX_NOTIFICATIONS_REDIRECT = 'sandboxNotificationsRedirect',
}

export type UserSettings = Partial<
  {
    [SettingsKey.ONBOARDING]: OnboardingSettings;
    [SettingsKey.SANDBOX_NOTIFICATIONS_REDIRECT]: boolean;
    [SettingsKey.DASHBOARD_OPEN_FOLDERS]: Record<Organization['id'], Array<SavedViewFolder['id']>>;
  } & Record<SettingsKey, unknown>
>;
export type V2OnboardingStatus = 'started' | 'completed';

export enum OnboardingSettingsKey {
  TEMPLATE_GALLERY_SEEN = 'templateGallerySeen',
  V2_ONBOARDING_STATUS = 'v2OnboardingStatus',
  V2_ONBOARDING_ATTRIBUTION = 'attribution',
  /** User has dismissed 'create first workflow' notification. */
  FIRST_WORKFLOW_CREATED = 'firstWorkflowCreated',
  /** User has dismissed 'customize your first workflow' alert. */
  CUSTOMIZE_WORKFLOW_ALERT_DISMISSED = 'customizeWorkflowAlertDismissed',
  /** User has dismissed 'create your first tasks' alert. */
  CREATE_TASKS_ALERT_DISMISSED = 'createTasksAlertDismissed',
  /** Don't show workflow setup wizard when creating a blank workflow. */
  DISABLE_WORKFLOW_SETUP_WIZARD = 'disableWorkflowSetupWizard',
  /** User has dismissed schedule notification in Run Workflow modal */
  SCHEDULE_ALERT_DISMISSED = 'scheduleAlertDismissed',
}

export type V2OnboardingAttribution =
  | {
      permissions?: 'admin' | 'guest' | 'free_member' | 'full_member';
      referrer: 'sso' | 'invitation' | 'assignment' | 'template_gallery' | 'marketing';
    }
  | undefined;

export type OnboardingSettings = {
  [OnboardingSettingsKey.TEMPLATE_GALLERY_SEEN]?: boolean;
  [OnboardingSettingsKey.V2_ONBOARDING_STATUS]?: V2OnboardingStatus;
  [OnboardingSettingsKey.V2_ONBOARDING_ATTRIBUTION]?: V2OnboardingAttribution;
  [OnboardingSettingsKey.FIRST_WORKFLOW_CREATED]?: boolean;
  [OnboardingSettingsKey.CUSTOMIZE_WORKFLOW_ALERT_DISMISSED]?: boolean;
  [OnboardingSettingsKey.CREATE_TASKS_ALERT_DISMISSED]?: boolean;
  [OnboardingSettingsKey.DISABLE_WORKFLOW_SETUP_WIZARD]?: boolean;
  [OnboardingSettingsKey.SCHEDULE_ALERT_DISMISSED]?: boolean;
};
