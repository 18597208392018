import { UrlRuleHandlerFn } from '@uirouter/core';
import { match, P } from 'ts-pattern';
import { AppModalName, AppModalQueryParam } from './app.constants';

const runWorkflow: UrlRuleHandlerFn = ($match, $stateParams) => {
  'ngInject';
  return (
    match({ $match, $stateParams })
      .with({ $match: { templateId: P.string } }, ({ $match: { templateId } }) => {
        const queryParams = [
          [AppModalQueryParam.Modal, AppModalName.RunChecklist],
          [AppModalQueryParam.ModalTemplateId, templateId],
        ]
          .map(keyVal => keyVal.join('='))
          .join('&');
        return `/workflows/${templateId}/view?${queryParams}`;
      })
      // void to continue handling
      .otherwise(() => void 0)
  );
};

export const AppRedirectHandlers = {
  runWorkflow,
};
