import { Box, Center, Text, VStack } from '@chakra-ui/react';
import { Icon } from 'app/components/design/next';
import { FormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-types';
import pLimit from 'p-limit';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';

export type WidgetListDropzoneProps = React.PropsWithChildren<{ actor: FormEditorPageActorRef; isReadonly: boolean }>;

export const WidgetListDropzone = ({ children, actor, isReadonly }: WidgetListDropzoneProps) => {
  const handleDrop = React.useCallback(
    (files: File[]) => {
      const limit = pLimit(1);

      Array.from(files).forEach(file => {
        void limit(
          () =>
            new Promise(resolve => {
              actor.send({ type: 'PASTE_FILE', file });

              setTimeout(() => {
                resolve({});
              }, 500);
            }),
        );
      });
    },
    [actor],
  );

  const dropzoneState = useDropzone({
    onDrop: handleDrop,
  });

  if (isReadonly) {
    return <>{children}</>;
  }

  return (
    <Box {...dropzoneState.getRootProps()} onClick={() => {}} w="full">
      <input {...dropzoneState.getInputProps()} />

      {children}

      {dropzoneState.isDragActive && (
        <Center
          position="absolute"
          inset="0"
          bgColor="rgba(0, 0, 0, 0.3)"
          borderRadius={{ base: '0', lg: 'lg' }}
          zIndex="tooltip"
        >
          <VStack>
            <Icon icon="cloud-arrow-up" size="20" color="white" />
            <Text fontSize="xl" color="white">
              Drop here to upload
            </Text>
          </VStack>
        </Center>
      )}
    </Box>
  );
};
