import { ImageWidget } from '@process-street/subgrade/process';
import { ActorRefFrom, createMachine, spawn, StateFrom } from 'xstate';
import { makeRulesEngineTargetMachine } from '../../form-response-body/rules-engine-machine';

type Context = {
  widget: ImageWidget;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
};

type MakeImageContentWidgetMachineArgs = {
  widget: ImageWidget;
};

export const makeImageContentWidgetMachine = ({ widget }: MakeImageContentWidgetMachineArgs) => {
  return createMachine({
    id: `image-widget-machine:${widget.id}`,
    predictableActionArguments: true,
    context: () => ({
      widget,
      rulesEngineTargetActor: spawn(
        makeRulesEngineTargetMachine({ type: 'widget', widgetHeaderGroupId: widget.header.group.id }),
        { name: 'hidden-by-rule-actor', sync: true },
      ),
    }),
    tsTypes: {} as import('./image-content-widget-machine.typegen').Typegen0,
    schema: {
      context: {} as Context,
    },
  });
};

export type ImageContentWidgetMachine = ReturnType<typeof makeImageContentWidgetMachine>;
export type ImageContentWidgetMachineActorRef = ActorRefFrom<ImageContentWidgetMachine>;

type State = StateFrom<ImageContentWidgetMachine>;

const Selectors = {
  getWidget: (state: State) => state.context.widget,
};
export { Selectors as ImageContentWidgetMachineSelectors };
