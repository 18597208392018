import * as React from 'react';

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from 'components/design/next';

import { env } from 'components/common/env';
import { useFeatureFlag } from 'features/feature-flags';
import { match } from 'ts-pattern';
import { useMount } from 'react-use';

export const SHOULD_SHOW_ONBOARDING_SLIDESHOW = 'shouldShowOnboardingSlideshow';

export interface OnboardingSlideshowProps {}

export const OnboardingSlideshow: React.FC<React.PropsWithChildren<OnboardingSlideshowProps>> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onboardingSlideshowEnabled = useFeatureFlag('onboardingSlideshow');

  useMount(() => {
    // message is sent from the app when the slideshow button is clicked in the iframe
    window.addEventListener('message', (ev: MessageEvent<{ type: string; message: string }>) => {
      match(ev)
        .with(
          {
            data: {
              type: 'close-button',
              message: 'web_close_modal',
            },
          },
          () => {
            onClose();
          },
        )
        .otherwise(() => null);
    });
  });

  useMount(() => {
    if (onboardingSlideshowEnabled && localStorage.getItem(SHOULD_SHOW_ONBOARDING_SLIDESHOW) === 'true') {
      onOpen();
      localStorage.removeItem(SHOULD_SHOW_ONBOARDING_SLIDESHOW);
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size="full"
      isCentered
    >
      <ModalOverlay />
      <ModalContent m={['20px', '50px']} backgroundColor={'#0279CA'}>
        <ModalBody position="relative">
          <Box
            display={'block'}
            as="iframe"
            position="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            height="full"
            width="full"
            margin={0}
            borderRadius={10}
            border="none"
            src={env.APP_WEB_URL + '/in-app-slideshow'}
          />
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
