import angular from 'angular';
import { dayjs as moment } from '@process-street/subgrade/util';

angular.module('frontStreetApp.services').service('AuditFormatService', function ($q, UserService) {
  const self = this;

  /**
   * Formats the date to "updated by dude 5 min ago" style.
   * It will make sure it is always in the past.
   *
   * @param date timestamp
   * @returns {{updatedBy: *, updatedDate: *}}
   */
  this.formatAuditDateFromNow = function (date) {
    const now = Date.now();
    const checkedDate = date > now ? now : date;

    return moment(checkedDate).fromNow();
  };

  /**
   * Formats the date taking timezone into account.
   *
   * @param date
   * @param timeZone
   * @returns {*}
   */
  this.formatAuditDate = function (date, timeZone) {
    if (angular.isDefined(date)) {
      return moment(date).tz(timeZone).format('MM/DD/YYYY h:mmA');
    } else {
      return undefined;
    }
  };

  this.formatAudit = function (audit) {
    let userRequest;

    if (audit.by && audit.by.username) {
      userRequest = $q.resolve(audit.by);
    } else if (audit.by && audit.by.id) {
      userRequest = UserService.getById(audit.by.id);
    } else {
      userRequest = $q.reject();
    }

    return userRequest.then(
      user => ({
        by: user.username,
        date: self.formatAuditDateFromNow(audit.date),
      }),
      () => ({
        by: 'Someone',
        date: 'at some point',
      }),
    );
  };
});
