import * as React from 'react';
import { useToken, Box, Stat, StatLabel, StatNumber, StatHelpText } from 'components/design/next';
import { Axis, LineSeries, Margin, XYChart, Tooltip } from '@visx/xychart';
import { ParentSizeRenderProps } from '../common/types';
import { ParentSize } from '@visx/responsive';
import { match } from 'ts-pattern';
import { useChecklistSearchCriteria } from 'pages/reports/hooks/use-checklist-search-criteria';
import { isSingleWorkflowResponse, useChecklistAnalyticsQuery } from 'features/checklists/query-builder';
import { ChecklistTimeSeries } from '@process-street/subgrade/dashboard';
import { ChartSpinner } from '../common/chart-spinner';
import { ChartAlert } from '../chart-alert';
import { dayjs } from '@process-street/subgrade/util';
import { EmptyLineGraph } from '../empty-line-graph';
import { CHECKLIST_COUNT_OVER_TIME } from '../common/visualization-names';
import { useResponsiveDateTicks } from '../common/use-responsive-date-ticks';
import { getScaledXAxisMeasurements } from '../common/get-scaled-x-axis-measurements';
import { MarkerCircle } from '@visx/marker';

export interface ChecklistCountOverTimeChartProps {
  parentSize: ParentSizeRenderProps;
  data: ChecklistTimeSeries[];
}

const X_AXIS_LABEL_CLASS = 'checklist-count-over-time__x-axis-label';

const MARKER_ID = 'marker-circle-count';

export const ChecklistCountOverTimeChart: React.FC<React.PropsWithChildren<ChecklistCountOverTimeChartProps>> = ({
  parentSize: { height: parentHeight, width: parentWidth },
  data: dataWithDateString,
}) => {
  const [stroke, axisStroke] = useToken('colors', ['brand.400', 'gray.300']);
  const data = dataWithDateString.map(d => ({ ...d, date: new Date(d.date) }));
  const { format: dateFormat, tickCount: dateTickCount } = useResponsiveDateTicks({
    width: parentWidth,
    total: data.length,
  });

  const maxCount = Math.max(...data.map(d => d.runCount ?? 0));

  const { axisLabelOffset, marginLeft } = getScaledXAxisMeasurements({ max: maxCount });
  const margin: Margin = {
    left: marginLeft,
    right: 50,
    top: 50,
    bottom: 50,
  };

  return (
    <Box
      aria-label={CHECKLIST_COUNT_OVER_TIME}
      sx={{
        [`& .${X_AXIS_LABEL_CLASS}`]: {
          fontWeight: 'normal',
          fill: 'gray.500',
        },
      }}
    >
      <XYChart
        xScale={{ type: 'time' }}
        yScale={{ type: 'linear', round: true, nice: true }}
        height={parentHeight}
        width={parentWidth}
        margin={margin}
      >
        <LineSeries
          aria-label="workflow run count over time line"
          stroke={stroke}
          dataKey="Workflow Run Count"
          data={data}
          xAccessor={d => d.date}
          yAccessor={y => y.runCount}
          max={maxCount}
          markerStart={`url(#${MARKER_ID})`}
          markerMid={`url(#${MARKER_ID})`}
          markerEnd={`url(#${MARKER_ID})`}
        />

        <MarkerCircle id={MARKER_ID} fill={stroke} size={2} refX={2} />

        <Axis
          key="time-axis"
          orientation="bottom"
          numTicks={dateTickCount}
          tickFormat={d => dayjs(d).format(dateFormat)}
          strokeWidth="1"
          stroke={axisStroke}
        />

        <Axis
          key="count-axis"
          orientation="left"
          numTicks={Math.min(maxCount, 10)}
          tickFormat={d => parseInt(d).toString()}
          strokeWidth="1"
          stroke={axisStroke}
          label="Workflow Run Count"
          labelClassName={X_AXIS_LABEL_CLASS}
          labelOffset={axisLabelOffset}
        />

        <Tooltip<typeof data[number]>
          snapTooltipToDatumX
          showVerticalCrosshair
          showDatumGlyph
          glyphStyle={{ stroke: 'white', fill: stroke }}
          verticalCrosshairStyle={{ strokeDasharray: '3 3' }}
          renderTooltip={({ tooltipData }) => {
            const count = tooltipData?.nearestDatum?.datum?.runCount ?? 0;
            return (
              <Stat p="2" aria-label="workflow run count over time tooltip">
                <StatLabel>{dayjs(tooltipData?.nearestDatum?.datum.date).format(dateFormat)}</StatLabel>
                <StatNumber>{count}</StatNumber>
                <StatHelpText>{count === 1 ? 'Workflow run' : 'Workflow runs'}</StatHelpText>
              </Stat>
            );
          }}
        />
      </XYChart>
    </Box>
  );
};

export interface ChecklistCountOverTimeProps {}

export const ChecklistCountOverTime: React.VFC<ChecklistCountOverTimeProps> = () => {
  // Fetch the stats
  const searchCriteria = useChecklistSearchCriteria();
  const { templateIds } = searchCriteria;
  const analyticsQuery = useChecklistAnalyticsQuery(searchCriteria, {
    select: analytics => (isSingleWorkflowResponse(analytics) ? analytics.checklistStatsTimeSeries : []),
  });

  return match(analyticsQuery)
    .with({ status: 'loading' }, () => <ChartSpinner />)
    .with({ status: 'error' }, ({ error }) => <ChartAlert statusCode={error.response?.status} />)
    .with({ status: 'success', data: [] }, () => <EmptyLineGraph />)
    .with({ status: 'success' }, ({ data }) => (
      <ParentSize>
        {parentSize => (
          <ChecklistCountOverTimeChart
            {...{
              // Using a key here to force a re-render if the id selection changes. Prevents a hooks issue from @visx/axis
              key: templateIds?.join(':'),
              data,
              parentSize,
            }}
          />
        )}
      </ParentSize>
    ))
    .otherwise(() => null);
};
