import angular from 'angular';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { getEnv } from 'components/common/env';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.services')
  .service(
    'SatisMeterService',
    function (
      $q,
      $state,
      $timeout,
      $window,
      OrganizationMembershipService,
      OrganizationService,
      PlanService,
      SecurityService,
    ) {
      const logger = trace({ name: 'SatisMeterService' });

      const LOADER_WAIT_DELAY = 5000;
      const LOADER_WAIT_RETRIES = 10;

      const self = this;

      self.boot = (user, forceSurvey, event) => {
        logger.info('attempting to boot SatisMeter');

        doBootAfterLoader(user, forceSurvey, 0 /* delay */, LOADER_WAIT_RETRIES, event);
      };

      function isInLoaderState() {
        return $state.current.name.startsWith('loader');
      }

      function doBootAfterLoader(user, forceSurvey, delay, retries, event) {
        $timeout(() => {
          if (!isInLoaderState()) {
            doBoot(user, forceSurvey, event);
          } else {
            doBootAfterLoader(user, forceSurvey, LOADER_WAIT_DELAY, retries - 1, event);
          }
        }, delay);
      }

      function doBoot(user, forceSurvey, event) {
        logger.info('booting SatisMeter');
        self.initialize(user).then(userData => {
          if (self.shouldShowSatisMeterSurvey(user, userData)) {
            const createdAt = new Date(user.audit.createdDate).toISOString();

            const track = event
              ? {
                  type: 'track',
                  event,
                }
              : {};

            $window.satismeter({
              writeKey: getEnv().SATIS_WRITE_KEY,
              userId: user.id,
              traits: {
                name: user.username,
                email: user.email,
                createdAt,
                organizationId: userData.organizationId,
                organizationName: userData.organization.name,
                userLevel: getUserLevel(userData),
                planId: userData.plan.id,
                planLevel: userData.plan.level,
                planName: userData.plan.name,
              },
              forceSurvey,
              ...track,
            });
          }
        });
      }

      function getUserLevel(userData) {
        return userData.userIsAdmin ? 'Admin' : 'Member';
      }

      self.initialize = function (user) {
        if (user) {
          const data = {
            organizationId: SecurityService.getSelectedOrganizationIdByUser(user),
          };

          const userIsAdminRequest = OrganizationMembershipService.isAdminByUserIdAndOrganizationId(
            user.id,
            data.organizationId,
          );
          const userIsGuestRequest = OrganizationMembershipService.isGuestByUserIdAndOrganizationId(
            user.id,
            data.organizationId,
          );
          const organizationRequest = OrganizationService.getById(data.organizationId);

          return $q
            .all({
              userIsAdmin: userIsAdminRequest,
              userIsGuest: userIsGuestRequest,
              organization: organizationRequest,
            })
            .then(response => {
              data.userIsAdmin = response.userIsAdmin;
              data.userIsGuest = response.userIsGuest;
              data.organization = response.organization;

              return PlanService.getById(data.organization.subscription.plan.id).then(plan => {
                data.plan = plan;

                return data;
              });
            });
        } else {
          return $q.resolve();
        }
      };

      self.shouldShowSatisMeterSurvey = function (user, userData) {
        return user && userData.organizationId && !isAnonymousUser(user) && !userData.userIsGuest;
      };
    },
  );
