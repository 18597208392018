import angular from 'angular';
import { htmlEscaped } from '@process-street/subgrade/util';
import { SessionSelector } from 'reducers/session/session.selectors';
import {
  organizationMembershipsByGroupIdSelector,
  selectedOrganizationMembershipWithUsersNoSystemGroupsSelector,
  systemGroupsSelector,
} from 'reducers/group/group.selectors';
import { sortOrganizationMembershipsByUsername } from './checklist-members-field-helper';
import { connectController } from 'reducers/util';
import templateUrl from './checklist-members-field.component.html';
import '../members-field.scss';
import { GroupActions } from 'reducers/group/group.actions';
import { GroupType, isOrganizationMembershipActive } from '@process-street/subgrade/core';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { isT5KPlan } from 'services/plans';
import { GetDeletedByIdQuery } from 'features/organization-memberships/query-builder';
import { queryClient } from 'components/react-root';

const MAX_SUGGESTIONS = 100;

export const ChecklistMembersFieldComponent = {
  bindings: {
    user: '<',
    widget: '<',
    editable: '<',
    formFieldValue: '<',
    onUpdateValue: '&',
    readOnly: '<',
  },
  templateUrl,
  controller: class ChecklistMembersFieldComponentController {
    constructor($ngRedux, $timeout, GroupMembershipActions, OrganizationMembershipActions) {
      'ngInject';
      this.$ngRedux = $ngRedux;
      this.$timeout = $timeout;
      this.GroupMembershipActions = GroupMembershipActions;
      this.OrganizationMembershipActions = OrganizationMembershipActions;
    }

    $onInit() {
      const mapStateToThis = () => state => {
        const isUserDeactivationEnabled = FeatureFlagSelector.getFeatureFlags(state).deactivatingUsers;
        const selectedOrganization = SessionSelector.getSelectedOrganization(state);

        const organizationMemberships = selectedOrganizationMembershipWithUsersNoSystemGroupsSelector(state);
        const systemGroups = systemGroupsSelector(state);

        var selectableOrganizationMemberships = organizationMembershipsByGroupIdSelector(this.widget.config.groupId)(
          state,
        );

        const isAllMembersGroup = systemGroups.some(
          group => group.id === this.widget.config.groupId && group.groupType === GroupType.AllMembers,
        );

        if (isAllMembersGroup && isT5KPlan(selectedOrganization.subscription.plan.id)) {
          // On the T5K plans internal guests (aka free members) can exist in groups and be assigned to tasks.
          // That being the case, members field is for the "All Members" group we should include internal guests.
          const allFreeMembersGroup = systemGroups.find(group => group.groupType === GroupType.AllFreeMembers);

          const allFreeMembersMembers = organizationMembershipsByGroupIdSelector(allFreeMembersGroup.id)(state);

          selectableOrganizationMemberships = selectableOrganizationMemberships.concat(allFreeMembersMembers);
        }

        this.resolveSelectedOrganizationMembership(organizationMemberships);

        return {
          selectedOrganizationId: selectedOrganization.id,
          organizationMemberships,
          selectableOrganizationMemberships,
          isUserDeactivationEnabled,
        };
      };

      const mapDispatchToThis = () => ({
        queryGroups: GroupActions.queryGroups,
        queryGroupMembership: this.GroupMembershipActions.queryGroupMembership,
        getAllOrgMembershipByOrgId: this.OrganizationMembershipActions.getAllOrgMembershipByOrgId,
      });

      connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, this.shouldChange)(this);

      this.ready = false;
      this.actions
        .getAllOrgMembershipByOrgId(this.state.selectedOrganizationId)
        .then(() => this.actions.queryGroups())
        .then(() => this.actions.queryGroupMembership())
        .then(() => {
          this.ready = true;
        });
    }

    shouldChange = changes =>
      (changes.widget && changes.widget.currentValue) ||
      (changes.formFieldValue && changes.formFieldValue.currentValue);

    getMatchingMemberships(search) {
      let matchingMemberships = this.state.selectableOrganizationMemberships.filter(membership => {
        return this.isOrganizationMembershipActive(membership);
      });

      if (matchingMemberships && search) {
        matchingMemberships = this.state.selectableOrganizationMemberships.filter(({ user }) =>
          this.isUserMatching(user, search),
        );
      }

      const sorted = sortOrganizationMembershipsByUsername(matchingMemberships);
      if (sorted.length > MAX_SUGGESTIONS) {
        sorted.length = MAX_SUGGESTIONS;
      }

      return sorted;
    }

    isUserMatching = (user, term) =>
      user.username.toLowerCase().includes(term.toLowerCase()) || user.email.toLowerCase().includes(term.toLowerCase());

    isOrganizationMembershipActive = membership => {
      if (!this.state.isUserDeactivationEnabled) return true;

      return isOrganizationMembershipActive(membership);
    };

    resolveSelectedOrganizationMembershipId = () => {
      return (
        this.formFieldValue &&
        this.formFieldValue.fieldValue &&
        angular.isArray(this.formFieldValue.fieldValue.organizationMembershipIds) &&
        this.formFieldValue.fieldValue.organizationMembershipIds[0]
      );
    };

    resolveSelectedOrganizationMembership = organizationMemberships => {
      const organizationMembershipId = this.resolveSelectedOrganizationMembershipId();

      let selectedMembership;

      if (organizationMembershipId) {
        selectedMembership = organizationMemberships.find(om => om.id === organizationMembershipId);

        // We check for > 1 because there will always be at least 1, the current user, and they won't be deleted
        if (!selectedMembership && organizationMemberships.length > 1) {
          queryClient
            .fetchQuery({
              queryFn: () => GetDeletedByIdQuery.queryFn({ organizationMembershipId }),
              queryKey: GetDeletedByIdQuery.getKey({ organizationMembershipId }),
            })
            .then(om => {
              this.$timeout(() => {
                this.selectedMembership = om;
              });
            });
        }
      }

      this.selectedMembership = selectedMembership;
    };

    clearSelectedMembership() {
      this.selectedMembership = undefined;
      this.updateMembersField();
    }

    isClearButtonDisabled = () => !this.selectedMembership;

    resolveUsername = user => {
      if (user) {
        return htmlEscaped`${user.username}`;
      } else if (this.resolveSelectedOrganizationMembershipId()) {
        return this.ready ? '(User Removed)' : '(Loading...)';
      }

      return '';
    };

    updateMembersField = () => {
      const fieldValue = {
        organizationMembershipIds: [],
      };

      if (this.selectedMembership) {
        fieldValue.organizationMembershipIds = [this.selectedMembership.id];
      }

      return this.onUpdateValue({
        widget: this.widget,
        fieldValue,
      });
    };

    isReadOnly = () => !this.formFieldValue || !this.formFieldValue.fieldValue || !this.editable;
  },
};
