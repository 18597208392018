import * as React from 'react';
import { useMediaQuery } from '@chakra-ui/media-query';
import { HStack, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Button, GridItem, Box } from 'components/design/next';
import { useEffectOnce, useToggle, useUpdateEffect } from 'react-use';
import { Icon } from 'components/design/next';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { NodeType, SelectorHelper } from 'directives/rules/template/task-templates-selector/selector-helper';
import { RuleFilterSearch } from 'features/conditional-logic/components/rule-filter-search';
import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { VirtualizedAccordion } from './virtualized-accordion';
import { useConditionalLogicAccordion } from './use-accordion-handlers';
import { useTemplateTypeContext } from 'utils/template/template-type-context';
import { useConditionalLogicModalStore } from '../modal/store';

export type SideMenuProps = {
  widgets: Widget[];
  taskTemplates: TaskTemplate[];
  rules: ChecklistRuleDefinition[];
};

export const SideMenu: React.FC<React.PropsWithChildren<SideMenuProps>> = ({ widgets, taskTemplates, rules }) => {
  const { selectedWidget, selectedTask, dispatch: dispatchModalState } = useConditionalLogicModalStore();
  const [isMobile] = useMediaQuery('(max-width: 992px)');
  const [showSidebar, toggleSidebar] = useToggle(false);

  const widgetsMap = React.useMemo(() => Object.fromEntries(widgets.map(w => [w.id, w]) ?? []), [widgets]);
  const nodesList = React.useMemo(() => SelectorHelper.createNodes(taskTemplates, widgets), [taskTemplates, widgets]);
  const taskNodes = React.useMemo(() => nodesList.filter(node => node.type !== NodeType.Widget), [nodesList]);

  const [searchTerm, setSearchTerm] = React.useState('');
  const accordion = useConditionalLogicAccordion({ taskNodes });

  const filteredTaskNodes = React.useMemo(() => {
    if (!searchTerm) return taskNodes;

    const re = new RegExp(searchTerm, 'i');
    const test = (text?: string) => re.test(text ?? '');

    const filteredTaskNodes = taskNodes
      .filter(node => test(node.name) || node.widgets?.some(w => test(w.name)))
      .map(taskNode => ({
        ...taskNode,
        widgets: taskNode.widgets?.filter(w => test(w.name)),
      }));

    return filteredTaskNodes;
  }, [searchTerm, taskNodes]);

  const doSearch = (term: string) => {
    setSearchTerm(term);

    if (term) {
      accordion.expand();
    } else {
      accordion.collapse();
    }
  };

  useUpdateEffect(() => {
    if (selectedWidget) accordion.expand();
    else accordion.collapse();
  }, [selectedWidget]);

  useEffectOnce(() => {
    if (selectedTask) {
      accordion.expand();
    }
  });

  const { isForm } = useTemplateTypeContext();
  const taskName = isForm ? 'steps' : 'tasks';

  return (
    <>
      {isMobile && showSidebar && (
        <Box
          onClick={() => toggleSidebar()}
          position="absolute"
          top="0"
          left="0"
          w="full"
          h="full"
          bgColor="black"
          opacity="0.2"
          zIndex="98"
        />
      )}

      {isMobile && (
        <Button
          position="absolute"
          left={showSidebar ? '341px' : 0}
          top="3"
          w="10"
          h="10"
          zIndex="999"
          onClick={() => toggleSidebar()}
          borderTopLeftRadius="0"
          borderBottomLeftRadius="0"
          bgColor="white"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.300"
          borderLeft="none"
          color="gray.500"
          _hover={{ bgColor: 'gray.50' }}
          _active={{ bgColor: 'gray.100' }}
        >
          <Icon
            transform={showSidebar ? 'rotate(180deg)' : 'rotate(0deg)'}
            transition="200ms ease transform 300ms"
            icon="chevron-right"
            size="4"
          />
        </Button>
      )}

      <GridItem
        as="aside"
        bgColor="brand.50"
        position={isMobile ? 'absolute' : undefined}
        left="0"
        transform={!isMobile ? 'undefined' : showSidebar ? 'translateX(0)' : 'translateX(-100%)'}
        willChange="transform"
        transition="200ms ease transform"
        width={{ sm: 'calc(100% - 110px)', xs: '100%' }}
        zIndex={99}
        height="100%"
        overflow="hidden"
      >
        <HStack spacing="4" py="3" px="8" w="full" bgColor="brand.100" position="sticky" top="0" zIndex="2">
          {selectedWidget || selectedTask ? (
            <Button
              h="10"
              variant="outline"
              colorScheme="gray"
              borderColor="gray.300"
              borderWidth="1px"
              bgColor="white"
              px="2.5"
              py="3"
              leftIcon={<Icon icon="arrow-left" size="4" />}
              fontWeight="normal"
              fontSize="sm"
              onClick={() => {
                dispatchModalState({ type: 'RESET_SELECTED_VALUES' });
                accordion.collapse();
              }}
            >
              {selectedWidget ? 'Back' : 'View all'}
            </Button>
          ) : (
            <>
              <RuleFilterSearch doSearch={doSearch} />

              <Menu>
                <MenuButton
                  as={Button}
                  aria-label="Side menu options"
                  h="10"
                  w="10"
                  variant="outline"
                  colorScheme="gray"
                  borderColor="gray.300"
                  borderWidth="1px"
                  bgColor="white"
                  color="gray.500"
                  p="0"
                >
                  <Icon icon="ellipsis-h" size="4" />
                </MenuButton>
                <MenuList>
                  <MenuGroup title="view">
                    <MenuItem
                      color="gray.600"
                      iconSpacing="3"
                      icon={<Icon color="gray.500" icon="expand" size="4" py="2" />}
                      onClick={() => accordion.expand()}
                    >
                      Expand {taskName}
                    </MenuItem>

                    <MenuItem
                      color="gray.600"
                      iconSpacing="3"
                      icon={<Icon color="gray.500" icon="compress" size="4" py="2" />}
                      onClick={() => accordion.collapse()}
                    >
                      Collapse {taskName}
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>
            </>
          )}
        </HStack>

        <Box pb="6" pt="6">
          <VirtualizedAccordion
            filteredTaskNodes={filteredTaskNodes}
            widgetsMap={widgetsMap}
            rules={rules}
            taskNodes={taskNodes}
            accordion={accordion}
          />
        </Box>
      </GridItem>
    </>
  );
};
