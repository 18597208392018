import escape from 'lodash.escape';

export const escapeHtml = escape;

export function safeEscapeHtml(text: string): string {
  return escapeHtml(text)
    .replace(/&#39;/g, "'")
    .replace(/&quot;/g, '"');
}

export function preserveEmptyParagraphsWithNbsp(text: string): string {
  return text.replace(/<p><\/p>/g, '<p>&nbsp;</p>');
}

interface Stringable {
  toString: () => string;
}
export const htmlEscaped = (
  literalSections: TemplateStringsArray,
  ...placeholders: (Stringable | undefined | null)[]
): string => {
  // Use raw literal sections: we don’t want
  // backslashes (\n etc.) to be interpreted
  const { raw } = literalSections;

  let result = '';

  placeholders.forEach((placeholder, i) => {
    // In the example, map() returns an array:
    // If substitution is an array (and not a string),
    // we turn it into a string
    const normSubst =
      typeof placeholder === 'undefined' || placeholder === null
        ? ''
        : Array.isArray(placeholder)
        ? placeholder.join('')
        : placeholder.toString();

    result += raw[i];
    result += escapeHtml(normSubst);
  });

  // Take care of last literal section
  // (Never fails, because an empty template string
  // produces one literal section, an empty string)
  result += raw[raw.length - 1]; // (A)

  return result;
};
