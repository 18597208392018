import angular from 'angular';

import { createSelector } from 'reselect';
import { EntitiesSelectors } from 'reducers/entities/entities.selectors';
import { connectService } from 'reducers/util';
import { SessionSelector } from 'reducers/session/session.selectors';
import { DataServiceConstants } from 'reducers/data-service/data-service-constants';
import { GroupActions } from 'reducers/group/group.actions';

class GroupService {
  constructor($ngRedux) {
    'ngInject';

    const mapStateToThis = createSelector(SessionSelector.getSelectedOrganizationId, selectedOrganizationId => ({
      selectedOrganizationId,
    }));

    this.selectors = new EntitiesSelectors(DataServiceConstants.GROUPS, $ngRedux);

    connectService('GroupService', $ngRedux, mapStateToThis, GroupActions)(this);
  }

  filter = (predicate, includes) => this.selectors.calculateFilter(predicate, includes);

  get = (id, includes) => this.selectors.calculateGet(id, includes);

  queryGroups = () => this.actions.queryGroups();

  getGroupStatsByOrganizationId = organizationId => this.actions.getGroupStatsByOrganizationId(organizationId);

  getGroupByUserId = userId => this.actions.getGroupByUserId(userId);

  // TODO Delete this, not used anywhere
  getGroupById = groupId => this.actions.getGroupById(groupId);

  createGroup = name => this.actions.createGroup(name);

  updateGroup = (group, info) => this.actions.updateGroup(group, info);

  updateGroupAvatar = (group, data) => this.actions.updateGroupAvatar(group, data);

  deleteGroup = group => this.actions.deleteGroup(group);

  addUserToGroup = (group, email) => this.actions.addUserToGroup(group, email);

  removeUserFromGroup = (group, membership) => this.actions.removeUserFromGroup(group, membership);
}

angular.module('frontStreetApp.services').service('GroupService', GroupService);
