import * as React from 'react';
import { Menu, MenuButton, MenuList, IconButton, MenuGroup, Box, StyleProps, Tooltip } from 'components/design/next';
import { FormFieldWidget } from '@process-street/subgrade/process';
import { ProcessPeteCircle, ProcessPeteIcon } from 'pages/runs/_id/components/ai-task-audit/common';
import { NgLink } from 'features/app/components/ng-link';
import { match, P } from 'ts-pattern';
import { useStateParam } from 'hooks/use-state-param';
import { MenuItemNgLink } from 'directives/ui/topbar/components/common';
import { AppState } from 'app/app.states.typegen';
import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';
import { useAssociatedAiTaskTemplates } from './use-associated-ai-task-templates';

export interface AiTaskTemplateWidgetIndicatorProps {
  widget: FormFieldWidget;
}

export const AiTaskTemplateWidgetIndicator: React.FC<React.PropsWithChildren<AiTaskTemplateWidgetIndicatorProps>> = ({
  widget,
}) => {
  const associatedTaskTemplates = useAssociatedAiTaskTemplates({ widget });

  const templateId = useStateParam({ key: 'id' });
  const workflowState = useWorkflowState();
  const stateName: AppState = workflowState === 'view' ? 'templateView.task' : 'template.task';
  const isView = workflowState === 'view';

  // Angular adds margin to the footer only in edit mode
  const viewModeMargin: StyleProps = isView ? { mt: 2 } : { mt: 0 };

  return match(associatedTaskTemplates)
    .with({ inputsTo: [], outputsFrom: [] }, () => null)
    .with({ outputsFrom: [{ group: { id: P.string } }], inputsTo: [] }, ({ outputsFrom: [{ group }] }) => (
      <Tooltip label="This form field is the output of an AI task" hasArrow>
        <Box {...viewModeMargin}>
          <NgLink to={stateName} params={{ groupId: group.id, id: templateId }}>
            {PROCESS_PETE_ICON}
          </NgLink>
        </Box>
      </Tooltip>
    ))
    .with({ inputsTo: [{ group: { id: P.string } }], outputsFrom: [] }, ({ inputsTo: [{ group }] }) => (
      <Tooltip label="This form field is an input of an AI task" hasArrow>
        <Box {...viewModeMargin}>
          <NgLink to={stateName} params={{ groupId: group.id, id: templateId }}>
            {PROCESS_PETE_ICON}
          </NgLink>
        </Box>
      </Tooltip>
    ))
    .otherwise(({ inputsTo, outputsFrom }) => (
      <Menu>
        <MenuButton {...viewModeMargin} as={IconButton} variant="unstyled" icon={PROCESS_PETE_ICON} />

        <MenuList>
          {inputsTo.length > 0 && (
            <MenuGroup title="Input to">
              {inputsTo.map(taskTemplate => (
                <MenuItemNgLink
                  key={taskTemplate.id}
                  to={stateName}
                  params={{ groupId: taskTemplate.group.id, id: templateId }}
                >
                  {taskTemplate.name}
                </MenuItemNgLink>
              ))}
            </MenuGroup>
          )}
          {outputsFrom.length > 0 && (
            <MenuGroup title="Output from">
              {outputsFrom.map(taskTemplate => (
                <MenuItemNgLink
                  key={taskTemplate.id}
                  to={stateName}
                  params={{ groupId: taskTemplate.group.id, id: templateId }}
                >
                  {taskTemplate.name}
                </MenuItemNgLink>
              ))}
            </MenuGroup>
          )}
        </MenuList>
      </Menu>
    ));
};

const PROCESS_PETE_ICON = (
  <ProcessPeteCircle bg="brand.100">
    <ProcessPeteIcon color="brand.500" />
  </ProcessPeteCircle>
);
