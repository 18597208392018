import { Muid } from '../core';

export enum AnonymousAuthenticationResourceType {
  Checklist = 'Checklist',
  Template = 'Template',
}

export interface AnonymousAutenticationRequest {
  id: Muid;
  type: AnonymousAuthenticationResourceType;
}
