import { generateUUID, Muid, MuidConverter, MuidUtils, Option, updateAuditMetadata } from '../core';
import { Task, TaskStatus, TaskTemplate, TaskWithTaskTemplate } from '../process';
import { ObjectMap } from '../redux/types';
import { ApprovalRuleSubject } from './approval-rule-subject.model';
import { Approval, ApprovalStatus, ApprovalWithChecklistRevisionId } from './approval.model';
import { ApprovalTaskGroupsMap } from './approval.types';

const groupTaskByApprovalStatus = (
  subjectTasks: TaskWithTaskTemplate[],
  subjectTaskIdToApprovalMap: ObjectMap<Approval>,
): ApprovalTaskGroupsMap => {
  return subjectTasks.reduce(
    (agg: ApprovalTaskGroupsMap, subjectTask: TaskWithTaskTemplate) => {
      const approval = subjectTaskIdToApprovalMap[subjectTask.id];
      if (approval === undefined) {
        if (subjectTask.status === TaskStatus.Completed) {
          agg.awaitingTasks.push(subjectTask);
        } else {
          agg.notSubmittedTasks.push(subjectTask);
        }
      } else {
        switch (approval.status) {
          case ApprovalStatus.Approved:
            agg.approvedTasks.push(subjectTask);
            break;
          case ApprovalStatus.Rejected:
            if (subjectTask.status === TaskStatus.Completed) {
              agg.awaitingTasks.push(subjectTask);
            } else {
              agg.rejectedTasks.push(subjectTask);
            }
        }
      }
      return agg;
    },
    {
      approvedTasks: [],
      awaitingTasks: [],
      rejectedTasks: [],
      notSubmittedTasks: [],
    },
  );
};

const isTaskLocked = (subjectTask: Option<Task>, approvals: Approval[]) => {
  if (!subjectTask || subjectTask.status !== TaskStatus.Completed) {
    return false;
  }
  return approvals.some(
    (approval: Approval) => approval.status === ApprovalStatus.Approved && approval.subjectTaskId === subjectTask.id,
  );
};

const createRejection = (
  rejectedBy: Muid,
  comment: Option<string>,
  approvalTask: Task,
  subjectTaskId: Muid,
  approval: Option<Approval>,
): ApprovalWithChecklistRevisionId => ({
  id: approval ? approval.id : MuidUtils.randomMuid(),
  audit: updateAuditMetadata(rejectedBy, approval),
  organizationId: approvalTask.organization.id,
  subjectTaskId,
  approvalTaskId: approvalTask.id,
  reviewedById: rejectedBy,
  status: ApprovalStatus.Rejected,
  comment: comment ?? '',
  checklistRevisionId: approvalTask.checklistRevision.id,
});

const createApproval = (
  approvedBy: Muid,
  approvalTask: Task,
  subjectTaskId: Muid,
  approval: Option<Approval>,
): ApprovalWithChecklistRevisionId => ({
  id: approval ? approval.id : MuidUtils.randomMuid(),
  audit: updateAuditMetadata(approvedBy, approval),
  organizationId: approvalTask.organization.id,
  subjectTaskId,
  approvalTaskId: approvalTask.id,
  reviewedById: approvedBy,
  status: ApprovalStatus.Approved,
  comment: '',
  checklistRevisionId: approvalTask.checklistRevision.id,
});

const createApprovalFromTaskTemplate = ({
  id,
  taskTemplate,
  organizationId,
  templateRevisionId,
  approvalTaskTemplateGroupId,
}: {
  id?: Muid;
  taskTemplate: TaskTemplate;
  organizationId: Muid;
  templateRevisionId: Muid;
  approvalTaskTemplateGroupId: Muid;
}): ApprovalRuleSubject => ({
  id: id ?? MuidConverter.fromUuid(generateUUID()),
  approvalTaskTemplateGroupId,
  subjectTaskTemplateGroupId: taskTemplate.group.id,
  organizationId,
  templateRevisionId,
  audit: {
    createdBy: { id: generateUUID() },
    createdDate: Date.now(),
    updatedBy: { id: generateUUID() },
    updatedDate: Date.now(),
  },
});

export const ApprovalUtils = {
  createApproval,
  createApprovalFromTaskTemplate,
  createRejection,
  isTaskLocked,
  groupTaskByApprovalStatus,
};
