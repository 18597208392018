import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, HStack, VStack } from 'components/design/next';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import * as React from 'react';
import { ChecklistRuleDefinition, ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { EmptyRuleDefinition } from '../empty-rule-definition/empty-rule-definition';
import { ConditionalLogicEmptyState } from './empty-state';
import { generateEmptyRule } from './rule-operations';
import { TabContainer } from '../tab-container';
import { VirtualizedRuleList } from './virtualized-rule-list';
import { RuleList } from './rule-list';
import { AddRuleAtTheBottomBtn } from './add-rule-at-the-bottom';
import { useFeatureFlags } from 'features/feature-flags';
import { useShowErrors } from 'features/conditional-logic/components/modal/use-show-errors';
import { useScrollToFirstInvalidRule } from './use-scroll-to-invalid-rule';
import { useConditionalLogicModalStore } from '../modal/store';
import { useAutoCreateRule } from './use-auto-create-rule';
import { ConditionalLogicUtils } from 'features/conditional-logic/utils/conditional-logic-utils';
import { FormFieldOrTaskOption } from '../form-fields-select';
import { useTemplateTypeContext } from 'utils/template/template-type-context';

export type RulesManagerProps = {
  widgets: Widget[];
  taskTemplates: TaskTemplate[];
  selectedWidget: Widget | null;
  initialRules: ChecklistRuleDefinition[];
};

export const RulesManager: React.FC<React.PropsWithChildren<RulesManagerProps>> = ({
  widgets,
  taskTemplates,
  initialRules,
}) => {
  const {
    selectedWidget,
    selectedRule,
    normalizedData,
    selectedTask,
    dispatch: dispatchModalState,
  } = useConditionalLogicModalStore();
  const selectedWidgetOrTask = selectedWidget || selectedTask;

  const [ruleIdsCreatedWhenTaskSelected, setRuleIdsCreatedWhileFilteringByTask] = React.useState<
    Set<ChecklistRuleDefinition['id']>
  >(new Set([]));

  const { values, errors } = useFormikContext<{
    rules: ChecklistRuleDefinition[];
  }>();
  const shouldShowError = useShowErrors();
  const featureFlags = useFeatureFlags();

  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const selectedTaskNode = React.useMemo(
    () => (selectedTask ? normalizedData.nodes.byId[selectedTask.id] : null),
    [selectedTask, normalizedData.nodes],
  );
  const selectedTaskWidgetGroupIds: Set<string> = React.useMemo(
    () =>
      selectedTaskNode ? new Set(selectedTaskNode.widgets?.map(w => (w.ref as Widget).header.group.id)) : new Set(),
    [selectedTaskNode],
  );

  const initialRuleIds = React.useMemo(() => new Set(initialRules.map(rule => rule.id)), [initialRules]);

  React.useEffect(() => {
    if (!selectedTask) {
      setRuleIdsCreatedWhileFilteringByTask(new Set());
    } else {
      setRuleIdsCreatedWhileFilteringByTask(value => {
        const newValue = new Set(value);

        values.rules.forEach(rule => {
          if (
            ConditionalLogicCommonUtils.getConditionFormFieldWidgetGroupIdsFromRule(rule).size === 0 &&
            ConditionalLogicCommonUtils.getConditionTaskTemplateGroupIdsFromRule(rule).size === 0
          ) {
            newValue.add(rule.id);
          }
        });

        return newValue;
      });
    }
  }, [selectedTask, values.rules]);

  const rulesToShow = React.useMemo(() => {
    if (selectedWidget) return normalizedData.rules.byFormFieldWidgetGroupId[selectedWidget.header.group.id] ?? [];
    if (selectedTask) {
      return values.rules.filter(rule => {
        const isNewRule = ConditionalLogicCommonUtils.getConditionFormFieldWidgetGroupIdsFromRule(rule).size === 0;
        const isRuleCreatedWhileFilteringByTask = ruleIdsCreatedWhenTaskSelected.has(rule.id);
        const ruleWidgetGroupIds = ConditionalLogicCommonUtils.getFormFieldWidgetGroupIdsFromRule(rule);
        const isTaskParticipatingInRule =
          rule.taskTemplateGroupIds.includes(selectedTask.group.id) ||
          Array.from(ruleWidgetGroupIds.values()).some(id => selectedTaskWidgetGroupIds.has(id));

        return isNewRule || isTaskParticipatingInRule || isRuleCreatedWhileFilteringByTask;
      });
    }

    return values.rules;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- recalculate when normalized data or selection changes
  }, [selectedWidget, selectedTask, normalizedData, ruleIdsCreatedWhenTaskSelected]);

  const { templateType } = useTemplateTypeContext();
  const formFieldSelectOptions: FormFieldOrTaskOption[] = React.useMemo(
    () =>
      ConditionalLogicUtils.getFormFieldSelectOptions({
        widgetsEligibleForSelection: widgets,
        taskTemplates,
        featureFlags,
        templateType,
      }),
    [widgets, taskTemplates, featureFlags, templateType],
  );

  const { scrollToFirstInvalidRule } = useScrollToFirstInvalidRule({ errors, rules: values.rules });

  const handleJumpToInvalidRule = () => {
    if (selectedRule || selectedTask) {
      dispatchModalState({ type: 'SET_SCROLL_TO_FIRST_RULE_WITH_ERROR', payload: true });
    } else {
      scrollToFirstInvalidRule();
    }
  };

  React.useEffect(
    function selectInitialRule() {
      if (!selectedRule) {
        dispatchModalState({ type: 'SET_SELECTED_RULE', payload: rulesToShow[0] ?? null });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- omitting selectedRule to only select rule on modal open
    [selectedWidget, rulesToShow, dispatchModalState],
  );

  const errorsCount = (errors.rules as unknown[])?.filter(Boolean).length ?? 0;

  const arrayHelpersRef = React.useRef<FieldArrayRenderProps | null>(null);

  const addEmptyRule = () => {
    const rule = generateEmptyRule({
      atIndex: values.rules.length - 1,
      rules: values.rules,
      organizationId: selectedOrganization?.id,
      selectedWidget,
    });

    arrayHelpersRef.current?.push(rule);
  };

  useAutoCreateRule({ addRule: addEmptyRule, isEmpty: Boolean(selectedWidget) && rulesToShow.length === 0 });

  return (
    <Box w="full">
      <VStack w="full" spacing="5" px="4">
        {shouldShowError && (
          <Alert as={VStack} justifyContent="flex-start" alignItems="flex-start" spacing="1" status="error">
            <HStack w="full">
              <AlertIcon />
              <AlertTitle>Conditional logic error ({errorsCount})</AlertTitle>
            </HStack>

            <AlertDescription w="full" pl="38px">
              There are rules that need your attention.
              <Button ml="1" size="sm" onClick={handleJumpToInvalidRule} variant="link">
                Jump to invalid rule.
              </Button>
            </AlertDescription>
          </Alert>
        )}
        <Box w="full">
          {!!taskTemplates.length && !!widgets.length && (
            <FieldArray
              name="rules"
              validateOnChange={false}
              render={arrayHelpers => {
                arrayHelpersRef.current = arrayHelpers;
                return (
                  <TabContainer>
                    {rulesToShow.length > 0 && !selectedWidgetOrTask && (
                      <VirtualizedRuleList
                        showErrors={shouldShowError}
                        key="virtualized-0"
                        arrayHelpers={arrayHelpers}
                        formFieldSelectOptions={formFieldSelectOptions}
                        widgets={widgets}
                        taskTemplates={taskTemplates}
                        selectedTaskWidgetGroupIds={selectedTaskWidgetGroupIds}
                        ruleIdsCreatedWhenTaskSelected={ruleIdsCreatedWhenTaskSelected}
                        initialRuleIds={initialRuleIds}
                        rules={rulesToShow}
                      />
                    )}

                    {rulesToShow.length > 0 && selectedWidgetOrTask && (
                      <>
                        <RuleList
                          showErrors={shouldShowError}
                          arrayHelpers={arrayHelpers}
                          formFieldSelectOptions={formFieldSelectOptions}
                          widgets={widgets}
                          taskTemplates={taskTemplates}
                          selectedTaskWidgetGroupIds={selectedTaskWidgetGroupIds}
                          ruleIdsCreatedWhenTaskSelected={ruleIdsCreatedWhenTaskSelected}
                          initialRuleIds={initialRuleIds}
                        />
                        <AddRuleAtTheBottomBtn arrayHelpers={arrayHelpers} />
                      </>
                    )}

                    {rulesToShow.length === 0 && selectedWidgetOrTask && (
                      <EmptyRuleDefinition onAddRule={() => addEmptyRule()} />
                    )}

                    {rulesToShow.length === 0 && !selectedWidgetOrTask && (
                      <ConditionalLogicEmptyState onAddRule={() => addEmptyRule()} />
                    )}

                    {rulesToShow.length > 0 && !selectedWidget && <Box height="100px">&nbsp;</Box>}
                  </TabContainer>
                );
              }}
            />
          )}
        </Box>
      </VStack>
    </Box>
  );
};
