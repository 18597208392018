import { TaskTemplate } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { AssignAllTaskTemplatesMutationResponse } from './assign-all-task-templates-mutation';

export namespace UnassignAllTaskTemplatesMutation {
  export type Params = {
    taskTemplatesIds: Array<TaskTemplate['id']>;
    email: string;
  };

  export type Response = AssignAllTaskTemplatesMutationResponse;

  export const key = ['unassign', 'all', 'task-templates'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/task-templates/unassign`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
