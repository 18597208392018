import * as React from 'react';
import { useStepHeader } from './use-step-header';
import { StepHeaderCell } from './step-header-cell';

export interface StepHeaderProps {}

export const StepHeader: React.FC<React.PropsWithChildren<StepHeaderProps>> = () => {
  const { isEnabled, ...cellProps } = useStepHeader();
  return isEnabled ? <StepHeaderCell {...cellProps} /> : null;
};
