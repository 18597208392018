import { MuidUtils, orderTreeService } from '@process-street/subgrade/core';
import { TaskTemplate } from '@process-street/subgrade/process';
import { toOrderTrees } from 'services/util-pure';

export const createDuplicateTaskTemplatePlaceholder = ({
  sourceTaskTemplate,
  taskTemplates,
  index,
}: {
  sourceTaskTemplate: TaskTemplate;
  taskTemplates: TaskTemplate[];
  index: number;
}) => {
  const referenceTree = taskTemplates[index]?.orderTree;
  const [orderTree] = orderTreeService.after(toOrderTrees(taskTemplates), referenceTree);

  const groupId = MuidUtils.randomMuid();

  return {
    id: MuidUtils.randomMuid(),
    group: { id: groupId },
    templateRevision: sourceTaskTemplate.templateRevision,
    orderTree,
    name: `${sourceTaskTemplate.name} (Copy)`,
    stop: sourceTaskTemplate.stop,
  };
};
