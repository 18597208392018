import React from 'react';
import { Muid, Option } from '@process-street/subgrade/core';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { OnChangeValue } from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { Template } from 'webpack';
import { useGetAllTemplatesQuery } from 'features/template/query-builder';
import { TemplateStatus, TemplateType } from '@process-street/subgrade/process';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

export type FormSelectorProps = {
  selectedTemplateId?: Muid;
  onChange: (templateId: Option<Muid>) => void;
};

export type FormSelectorOptionType = { formTemplate?: Template; label: string; value?: string };

export const FormSelector: React.FC<React.PropsWithChildren<FormSelectorProps>> = ({
  selectedTemplateId,
  onChange,
}) => {
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const formTemplatesQuery = useGetAllTemplatesQuery(
    {
      organizationId: organizationId!,
      templateStatus: TemplateStatus.Active,
      templateType: TemplateType.Form,
    },
    {
      enabled: Boolean(organizationId),
    },
  );

  const options: FormSelectorOptionType[] = (formTemplatesQuery.data ?? []).map(formTemplate => ({
    label: formTemplate.name,
    value: formTemplate.id,
    formTemplate,
  }));

  const selectedOption = options.find(o => o.value === selectedTemplateId);

  const handleChange = (value: OnChangeValue<FormSelectorOptionType, false>) => {
    if (BlvdSelectHelpers.isOptionType<FormSelectorOptionType>(value)) {
      onChange(value.value);
    }
  };

  return <BlvdSelect options={options} value={selectedOption} onChange={handleChange} isSearchable />;
};
