import angular from 'angular';
import { connectController } from 'reducers/util';
import './loader.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { trace } from 'components/trace';

class LoaderCtrl {
  constructor(
    $location,
    $ngRedux,
    $q,
    $scope,
    $state,
    $timeout,
    auth,
    ChecklistAssignmentService,
    ChecklistPermitActions,
    ChecklistService,
    DataService,
    FolderPermitActions,
    FolderService,
    GroupMembershipService,
    GroupService,
    OrganizationMembershipService,
    PermitService,
    PlanService,
    SecurityService,
    SessionService,
    TemplateActions,
    TemplatePermitActions,
    ToastService,
    UserSettingsService,
    UserService,
  ) {
    'ngInject';

    const mapDispatchToThis = () => ({
      getAllChecklistPermitsByOrganizationId: ChecklistPermitActions.getAllByOrganizationId,
      getAllFolderPermitsByOrganizationId: FolderPermitActions.getAllByOrganizationId,
      getAllTemplatePermitsByOrganizationId: TemplatePermitActions.getAllByOrganizationId,
      getAllTemplatesByOrganizationIdAndStatus: TemplateActions.getAllByOrganizationIdAndStatus,
    });
    connectController($ngRedux, null, mapDispatchToThis)(this);

    this.$location = $location;
    this.$q = $q;
    this.$scope = $scope;
    this.$state = $state;
    this.$timeout = $timeout;
    this.auth = auth;
    this.ChecklistAssignmentService = ChecklistAssignmentService;
    this.ChecklistService = ChecklistService;
    this.DataService = DataService;
    this.FolderService = FolderService;
    this.GroupMembershipService = GroupMembershipService;
    this.GroupService = GroupService;
    this.OrganizationMembershipService = OrganizationMembershipService;
    this.PermitService = PermitService;
    this.PlanService = PlanService;
    this.SecurityService = SecurityService;
    this.SessionService = SessionService;
    this.UserSettingsService = UserSettingsService;
    this.UserService = UserService;
    this.ToastService = ToastService;

    const logger = trace({ name: 'LoaderCtrl' });
    this.logger = logger;

    $scope.progress = {
      done: 0,
      value: 0,
      type: 'success',
    };
    $scope.steps = 7;

    if ($scope.test) {
      return; // skip initialization for tests
    }

    DataService.clear();

    const sessionUser = this.getAuthenticatedUserOrRedirectToLogin();
    if (!sessionUser) {
      return;
    }

    const organizationsRequest = this.retrieveUserOrganizations(sessionUser.id).then(this.updateProgress);
    const userSettingsRequest = this.UserService.getSettingsById(sessionUser.id).then(settings => {
      this.SessionService.importSettings(settings);
      return settings;
    });

    const organizationsAndSettingsRequest = $q.all({
      userSettings: userSettingsRequest,
      organizations: organizationsRequest,
    });

    // first retrieve user organizations & settings, then load user
    organizationsAndSettingsRequest
      .then(({ userSettings, organizations }) => {
        this.updateProgress();

        const selectedOrganizationId =
          SessionService.getSelectedOrganizationId() ?? userSettings.selectedOrganizationId;
        const selectedOrganization = organizations.find(o => o.id === selectedOrganizationId) ?? organizations[0];

        return selectedOrganization;
      })
      .then(o =>
        $q.all([
          this.retrieveUser(sessionUser.id).then(this.updateProgress),
          this.loadOrganizationDependentRequests(o, sessionUser),
        ]),
      )
      .then(() => {
        logger.info('succeeded to load all objects');

        DataService.setInitialized(true);

        $timeout(() => {
          const url = this.$state.params.url || '/dashboard';

          this.logger.info('redirecting to "%s"', url);
          this.logger.info(`progress ${this.$scope.progress.done} of ${this.$scope.steps}`);

          this.$location.url(url);
          this.$location.replace();
        });
      })
      .catch(error => {
        logger.error('failed to load an object. Reason: %s', JSON.stringify(error));

        // If the session is initialized but there's an error,
        // it means it's trying to redirect to a page to fix it, so don't redirect
        if (error && error.status !== 401 && !this.DataService.isInitialized()) {
          $state.go('unavailable', { url: $state.params.url });
          $location.replace();
        }
      });
  }

  /**
   * Initializes user and redirects to login if user is not fully authenticated
   */
  getAuthenticatedUserOrRedirectToLogin = () => {
    if (this.SecurityService.isAuthenticatedFully()) {
      return this.SessionService.getUser();
    } else {
      this.$state.go('login', { url: this.$state.params.url });
      return null;
    }
  };

  // Progress

  updateProgress = response => {
    this.$scope.progress.done += 1;

    const numerator = this.$scope.progress.done;
    const denominator = this.$scope.steps;

    if (denominator) {
      this.$scope.progress.value = parseInt((numerator * 100) / denominator, 10);
    } else {
      this.$scope.progress.value = 0;
    }

    return response;
  };

  loadOrganizationDependentRequests = organization => {
    if (organization === undefined) {
      return this.$q.resolve([]);
    }

    this.SessionService.setSelectedOrganizationId(organization.id);

    this.UserSettingsService.updateSelectedOrganizationId(organization.id);

    const plansRequest = this.retrievePlans().then(this.updateProgress);

    const organizationUsersRequest = this.retrieveOrganizationUsers(organization.id).then(this.updateProgress);

    const groupsRequest = this.retrieveGroups().then(this.updateProgress);

    const foldersRequest = this.retrieveFolders(organization.id).then(this.updateProgress);

    return this.$q.all([plansRequest, organizationUsersRequest, groupsRequest, foldersRequest]).then(() => {
      this.initializeChecklistSearchData(organization.id);
    });
  };

  // Search

  initializeChecklistSearchData = organizationId => {
    if (this.DataService.isChecklistDataInitialized()) {
      return this.$q.resolve({});
    }

    const templatesRequest = this.actions.getAllTemplatesByOrganizationIdAndStatus(organizationId, 'Active');

    const templatePermitsRequest = this.actions.getAllTemplatePermitsByOrganizationId(organizationId);

    const checklistsRequest = this.ChecklistService.getAllByOrganizationId({
      organizationId,
      templateStatus: 'Active',
      checklistStatus: 'Active',
    });

    const checklistPermitsRequest = this.actions.getAllChecklistPermitsByOrganizationId(organizationId);

    const requests = {
      templates: templatesRequest,
      templatePermits: templatePermitsRequest,
      checklists: checklistsRequest,
      checklistPermits: checklistPermitsRequest,
    };

    return this.$q
      .all(requests)
      .then(responses => {
        this.DataService.setChecklistDataInitialized(true);

        this.logger.info('succeeded to retrieve %d templates(s)', responses.templates.payload.length);
        this.logger.info('succeeded to retrieve %d template permit(s)', responses.templatePermits.payload.length);
        this.logger.info('succeeded to retrieve %d checklist(s)', responses.checklists.length);
        this.logger.info('succeeded to retrieve %d checklist permit(s)', responses.checklistPermits.payload.length);

        this.UserSettingsService.cleanupEditorSettings(responses.checklists);
        return responses;
      })
      .catch(() => {
        this.ToastService.openToast({
          status: 'error',
          title: `We're having problems loading your data`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
        this.logger.error('checklist data failed to initialized or was canceled');
      });
  };

  // Plans

  retrievePlans = () =>
    this.PlanService.getAll()
      .then(plans => {
        this.logger.info('succeeded to retrieve %d plan(s)', plans.length);

        return plans;
      })
      .catch(error => {
        this.logger.error('failed to retrieve plans. Reason: %s', JSON.stringify(error));
        return this.$q.reject(error);
      });

  // User

  retrieveUser = userId => {
    this.logger.info('retrieving user data');

    return this.UserService.getById(userId).then(user => {
      this.SessionService.setUser(user);
      return user;
    });
  };

  retrieveUserOrganizations = userId => {
    this.logger.info('retrieving user organizations');
    return this.OrganizationMembershipService.getAllByUserId(userId)
      .then(memberships => {
        this.logger.info('succeeded to retrieve %d user organization(s)', memberships.length);
        return memberships.map(membership => membership.organization);
      })
      .catch(response => {
        this.logger.error('failed to retrieve user organizations. Reason: %s', JSON.stringify(response));
        return this.$q.reject(response);
      });
  };

  // Organization

  retrieveOrganizationUsers = organizationId => {
    this.logger.info('retrieving organization users');

    return this.OrganizationMembershipService.getAllByOrganizationId(organizationId, true /* flushCache */)
      .then(memberships => {
        this.logger.info('succeeded to retrieve %d organization user(s)', memberships.length);

        return memberships.map(membership => membership.user);
      })
      .catch(response => {
        this.logger.error('failed to retrieve organization users. Reason: %s', JSON.stringify(response));

        return this.$q.reject(response);
      });
  };

  // Groups

  retrieveGroups = () => {
    const groups = this.GroupService.queryGroups().then(({ payload }) => payload);
    const groupMemberships = this.GroupMembershipService.queryGroupMembership().then(({ payload }) => payload);

    const requests = {
      groups,
      groupMemberships,
    };

    return this.$q
      .all(requests)
      .then(responses => {
        this.logger.info('succeeded to retrieve %d group(s)', responses.groups.length);
        this.logger.info('succeeded to retrieve %d group membership(s)', responses.groupMemberships.length);
        return responses;
      })
      .catch(response => {
        this.logger.error('failed to retrieve groups and group memberships. Reason: %s', JSON.stringify(response));

        return this.$q.reject(response);
      });
  };

  // Folders

  retrieveFolders = organizationId => {
    const foldersRequest = this.FolderService.getAllByOrganizationId(this.SessionService.getSelectedOrganizationId());
    const folderPermitsRequest = this.actions.getAllFolderPermitsByOrganizationId(organizationId);

    const requests = {
      folders: foldersRequest,
      folderPermits: folderPermitsRequest,
    };

    return this.$q
      .all(requests)
      .then(responses => {
        this.logger.info('succeeded to retrieve %d folder(s)', responses.folders.length);
        this.logger.info('succeeded to retrieve %d folder permit(s)', responses.folderPermits.payload.length);
        return responses;
      })
      .catch(response => {
        this.logger.error('failed to retrieve folders and folder permits. Reason: %s', JSON.stringify(response));

        return this.$q.reject(response);
      });
  };
}

angular.module('frontStreetApp.controllers').controller('LoaderCtrl', LoaderCtrl);
