import { AuditMetadata, Muid, Organization, Ref, User } from '../core';
import { Checklist, Task, Template } from '../process';

export enum InboxItemType {
  Checklist = 'Checklist',
  StandardTask = 'StandardTask',
  ApprovalTask = 'ApprovalTask',
  OneOffTask = 'OneOffTask',
}

/**
 * Used for state path
 */
export enum InboxPathStateItemType {
  Checklist = 'checklist',
  Task = 'task',
  Approval = 'approval',
}

export interface ChecklistInboxItem {
  assignmentId: Muid;
  itemType: InboxItemType.Checklist;
  template: Template;
  organization: Ref<Organization>;
  assignees: User[];
  checklist: Ref<Checklist>;
  totalDoodads?: number;
  masked?: boolean;
  snoozedUntilDate?: number;
}

export interface StandardTaskInboxItem {
  assignmentId: Muid;
  itemType: InboxItemType.StandardTask;
  template: Template;
  organization: Ref<Organization>;
  assignees: User[];
  checklist: Ref<Checklist>;
  totalDoodads?: number;
  task: Task;
  masked?: boolean;
  snoozedUntilDate?: number;
}

export interface ApprovalTaskInboxItem {
  assignmentId: Muid;
  itemType: InboxItemType.ApprovalTask;
  template: Template;
  organization: Ref<Organization>;
  assignees: User[];
  checklist: Ref<Checklist>;
  totalDoodads?: number;
  task: Task;
  masked?: boolean;
  snoozedUntilDate?: number;
}

export interface OneOffTaskInboxItem {
  assignmentId: Muid;
  itemType: InboxItemType.OneOffTask;
  template: Template;
  organization: Ref<Organization>;
  assignees: User[];
  checklist: Ref<Checklist>;
  totalDoodads?: number;
  task: Task;
  masked?: boolean;
  snoozedUntilDate?: number;
}

export type TaskInboxItem = StandardTaskInboxItem | ApprovalTaskInboxItem | OneOffTaskInboxItem;

export type InboxItem = ChecklistInboxItem | TaskInboxItem;

export interface InboxAssignmentStats {
  hasWorkflowTasks: boolean;
  hasWorkflowRunTasks: boolean;
}

export interface InboxStats {
  inbox: number;
  upcoming: number;
  overdue?: number;
  assignmentStats?: InboxAssignmentStats;
}

export type SnoozedAssignment = {
  id: Muid;
  audit: AuditMetadata;
  organizationId: Muid;
  assignmentId: Muid;
  organizationMembershipId: Muid;
  untilDate: number;
};
