import angular from 'angular';
import { reactToAngular } from 'angulareact';
import { withErrorBoundary } from 'app/components/error-boundary';
import { WorkflowEditorWrapper } from './editor-wrapper';
import { BackgroundImage } from 'pages/workflows/_id/edit-v2/components/background-image/background-image';

export const WorkflowsEditorV2PageModule = angular
  .module('workflowsV2EditPage', [])
  .component('psBackgroundImage', reactToAngular(BackgroundImage, ['templateId', 'topOffset']))
  .component('psWorkflowEditorV2Page', reactToAngular(withErrorBoundary(WorkflowEditorWrapper), [])).name;
