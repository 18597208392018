import { FormControl, FormErrorMessage, FormLabel } from 'components/design/next';
import * as React from 'react';
import { DataSetNameSelectorField } from './dataset-name-select-field';
import { DataSetOption } from './predefined-datasets-options';

type DataSetNameFieldProps = {
  hasModerationError: boolean;
  maxLength: number;
  value: string;
  description: string;
  onSelect: (option: DataSetOption) => void;
  isLoading: boolean;
};

export const DataSetNameField = ({
  hasModerationError,
  maxLength,
  value,
  description,
  onSelect,
  isLoading,
}: DataSetNameFieldProps) => {
  return (
    <FormControl isRequired isInvalid={hasModerationError || value.length >= maxLength}>
      <FormLabel>Data Set Name</FormLabel>
      <DataSetNameSelectorField {...{ value, description, onSelect, isDisabled: isLoading }} />
      {hasModerationError && (
        <FormErrorMessage>ChatGPT moderators rejected that workflow name, try another one</FormErrorMessage>
      )}

      {value.length > maxLength && (
        <FormErrorMessage>Data Set name can&apos;t be longer than {maxLength} characters.</FormErrorMessage>
      )}
    </FormControl>
  );
};
