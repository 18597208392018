import { Camelize } from '@process-street/subgrade/core';
import { DataSet, Widget } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import { DisclosureActor, DisclosureEvent, makeDisclosureMachine } from 'utils/machines';
import { ActorRefFrom, assign, createMachine, interpret, sendTo, spawn, StateFrom } from 'xstate';

const DISCLOSURE_NAMES = [
  'SETTINGS',
  'MOVE',
  'CONNECT_DATA_SET',
  'LINK_DATA_SET',
  'UNLINK_DATA_SET',
  'IMPORT_DATA_SET',
  'DELETE',
] as const;
type DisclosureName = typeof DISCLOSURE_NAMES[number];

type Context = Record<`${Camelize<DisclosureName>}Disclosure`, DisclosureActor> & {
  widget: Widget | null;
  dataSet: DataSet | null;
};

type Event = {
  type: `${DisclosureEvent['type']}_${DisclosureName}`;
  widget?: Context['widget'];
  dataSet?: Context['dataSet'];
};

const open = { actions: ['forwardToActor' as const, 'assignWidget' as const, 'assignDataSet' as const] };
const close = { actions: ['forwardToActor' as const] };

export const widgetModalsMachine = createMachine(
  {
    id: 'widget-modals-machine',
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as Event,
    },
    tsTypes: {} as import('./widget-modals-machine.typegen').Typegen0,
    context: () => ({
      settingsDisclosure: spawn(makeDisclosureMachine(), { name: 'settings-disclosure' }),
      moveDisclosure: spawn(makeDisclosureMachine(), { name: 'move-disclosure' }),
      linkDataSetDisclosure: spawn(makeDisclosureMachine(), { name: 'link-data-set-disclosure' }),
      connectDataSetDisclosure: spawn(makeDisclosureMachine(), { name: 'connect-data-set-disclosure' }),
      unlinkDataSetDisclosure: spawn(makeDisclosureMachine(), { name: 'unlink-data-set-disclosure' }),
      importDataSetDisclosure: spawn(makeDisclosureMachine(), { name: 'import-data-set-disclosure' }),
      deleteDisclosure: spawn(makeDisclosureMachine(), { name: 'delete-disclosure' }),
      widget: null,
      dataSet: null,
    }),
    on: {
      OPEN_SETTINGS: open,
      CLOSE_SETTINGS: close,
      OPEN_MOVE: open,
      CLOSE_MOVE: close,
      OPEN_LINK_DATA_SET: open,
      CLOSE_LINK_DATA_SET: close,
      OPEN_CONNECT_DATA_SET: open,
      CLOSE_CONNECT_DATA_SET: close,
      OPEN_UNLINK_DATA_SET: open,
      CLOSE_UNLINK_DATA_SET: close,
      OPEN_IMPORT_DATA_SET: open,
      CLOSE_IMPORT_DATA_SET: close,
      OPEN_DELETE: open,
      CLOSE_DELETE: close,
    },
  },
  {
    actions: {
      assignWidget: assign({
        widget: (_, event: Event) => event.widget ?? null,
      }),
      assignDataSet: assign({
        dataSet: (_, event: Event) => event.dataSet ?? null,
      }),
      forwardToActor: sendTo(
        (ctx, event) => {
          return match(event.type)
            .when(endsWith('SETTINGS'), () => ctx.settingsDisclosure)
            .when(endsWith('MOVE'), () => ctx.moveDisclosure)
            .when(endsWith('UNLINK_DATA_SET'), () => ctx.unlinkDataSetDisclosure)
            .when(endsWith('LINK_DATA_SET'), () => ctx.linkDataSetDisclosure)
            .when(endsWith('CONNECT_DATA_SET'), () => ctx.connectDataSetDisclosure)
            .when(endsWith('IMPORT_DATA_SET'), () => ctx.importDataSetDisclosure)
            .when(endsWith('DELETE'), () => ctx.deleteDisclosure)
            .run();
        },
        (_ctx, event) => ({ type: startsWith('OPEN')(event.type) ? 'OPEN' : 'CLOSE' }),
      ),
    },
  },
);

function endsWith(str: DisclosureName) {
  return <S extends string>(t: S) => t.endsWith(str);
}

function startsWith(str: DisclosureEvent['type']) {
  return <S extends string>(t: S) => t.startsWith(str);
}

export type WidgetModalsMachine = typeof widgetModalsMachine;
export type WidgetModalsActorRef = ActorRefFrom<WidgetModalsMachine>;

const _service = interpret(widgetModalsMachine).start();
export const getWidgetModalsMachineService = () => _service;

type State = StateFrom<WidgetModalsMachine>;
export const Selectors = {
  getIsOpen:
    (name: Camelize<DisclosureName>) =>
    (state: State): boolean =>
      Boolean(state.context[`${name}Disclosure`].getSnapshot()?.matches('open')),
  getWidget: <W extends Widget>(state: State): W | null => state.context.widget as W | null,
  getDataSet: (state: State): DataSet | null => state.context.dataSet,
  noneAreOpen: (state: State): boolean =>
    Object.values(state.context)
      .filter((value): value is DisclosureActor => Boolean(value && 'getSnapshot' in value))
      .every(actor => actor.getSnapshot?.()?.matches('closed')),
};
