import { createUsableContext } from '@process-street/subgrade/util';

export type AiGeneratedDataSetSettingsModalContextValue = {
  name: string;
  description: string;
  onInputChange: (name: string) => void;
};

export const [useAiGeneratedDataSetSettingsModalContext, AiGeneratedDataSetSettingsModalContext] =
  createUsableContext<AiGeneratedDataSetSettingsModalContextValue>({
    hookName: 'useAiGeneratedDataSetSettingsModalContext',
    providerName: 'AiGeneratedDataSetSettingsModalContext',
  });
