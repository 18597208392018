import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type GetOrganizationLinkableDomainsParams = {
  organizationId?: Muid;
};

export const OrganizationLinkableDomains = {
  key: ['organization', 'linkable-domains'],
  getKey: (params: GetOrganizationLinkableDomainsParams): QueryKey => [...OrganizationLinkableDomains.key, params],
  queryFn: (params: GetOrganizationLinkableDomainsParams) =>
    axiosService
      .getAxios()
      .get<string[]>(`/1/users/me/organizations/${params.organizationId}/linkable-domains`, {
        params: { v: 4 },
      })
      .then(r => r.data),
};

export const useOrganizationLinkableDomains = (
  params: GetOrganizationLinkableDomainsParams,
  options?: UseQueryOptions<string[], AxiosError>,
) => {
  return useQuery(OrganizationLinkableDomains.getKey(params), () => OrganizationLinkableDomains.queryFn(params), {
    ...options,
    enabled: Boolean(params.organizationId) && options?.enabled !== false,
  });
};
