import * as React from 'react';

export function assertContext<T>(
  context: T | undefined,
  config: {
    hookName: string;
    providerName: string;
  },
): asserts context is T {
  if (typeof context === 'undefined') {
    throw new Error(`\`${config.hookName}\` must be used within \`<${config.providerName} />\``);
  }
}

export function createUsableContext<T>(config: {
  hookName: string;
  providerName: string;
}): [() => T, React.Context<T | undefined>] {
  const Context = React.createContext<T | undefined>(undefined);

  const useContext = () => {
    const context = React.useContext(Context);
    assertContext(context, config);
    return context;
  };

  return [useContext, Context];
}
