import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { ChakraProvider } from '@chakra-ui/provider';
import { theme2024 } from './theme';
import { useBrandTheme } from './use-brand-theme';
import { MuidUtils } from '@process-street/subgrade/core';
import { ThemeName, ThemeNameContext } from './theme-name-provider';
import { colorModeManager } from './color-mode-manager';

export const ThemeProvider2024 = ({ children }: React.PropsWithChildren<{}>) => {
  const theme = useBrandTheme(theme2024);
  const varsRootId = React.useRef(MuidUtils.randomMuid());

  return (
    <Box id={varsRootId.current}>
      <ThemeNameContext.Provider value={ThemeName.TwentyFour}>
        <ChakraProvider
          colorModeManager={colorModeManager}
          resetCSS={false}
          theme={theme}
          cssVarsRoot={`#${varsRootId.current}`}
        >
          {children}
        </ChakraProvider>
      </ThemeNameContext.Provider>
    </Box>
  );
};
