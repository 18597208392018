import angular from 'angular';
import { TaskAssignmentRuleSourceType } from '@process-street/subgrade/role-assignment';
import { FieldType } from '@process-street/subgrade/process';
import templateUrl from './manage-assignments.component.html';
import './manage-assignments.scss';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';
import { TaskListEvent } from 'directives/task-list/task-list-event';

angular
  .module('frontStreetApp.directives')
  .controller('ManageAssignmentsBoxCtrl', function ($scope) {
    const ctrl = this;
    ctrl.$onInit = () => {
      $scope.$on('init', (__event, values) => {
        $scope.users = values.users;
        $scope.unassignable = values.unassignable;
        $scope.assignmentRules = values.assignmentRules;
        $scope.emailAndMembersFieldWidgets = values.emailAndMembersFieldWidgets;
        $scope.checklistTaskAssignments = values.checklistTaskAssignments;
      });

      $scope.$on('changes', (__event, changes) => {
        if (changes.users) {
          $scope.users = changes.users.currentValue;
        }

        if (changes.unassignable) {
          $scope.unassignable = changes.unassignable.currentValue;
        }

        if (changes.assignmentRules) {
          $scope.assignmentRules = changes.assignmentRules.currentValue;
        }

        if (changes.emailAndMembersFieldWidgets) {
          $scope.emailAndMembersFieldWidgets = changes.emailAndMembersFieldWidgets.currentValue;
        }

        if (changes.checklistTaskAssignments) {
          $scope.checklistTaskAssignments = changes.checklistTaskAssignments.currentValue;
        }
      });
    };

    $scope.getUsername = function (user) {
      return user.username || '???';
    };

    $scope.getDescription = function (user) {
      return isGroupUser(user) ? 'Group' : user.email;
    };

    $scope.getChecklistTaskAssignments = () => {
      const assignments = $scope.checklistTaskAssignments || [];
      return assignments.sort((a, b) => {
        const aUsername = a.organizationMembership.user.username;
        const bUsername = b.organizationMembership.user.username;

        return aUsername < bUsername ? -1 : 1;
      });
    };

    $scope.isAssignmentCreatedByRule = function (assigment) {
      return !!(assigment.sourceTaskAssignmentRule && assigment.sourceTaskAssignmentRule.id);
    };

    $scope.getRuleDescription = function (rule) {
      if (rule.sourceType === TaskAssignmentRuleSourceType.ChecklistInitiator) {
        return 'Workflow Run';
      } else if (
        rule.sourceType === TaskAssignmentRuleSourceType.FormField &&
        rule.sourceFormFieldWidgetGroup &&
        rule.sourceFormFieldWidgetGroup.id
      ) {
        const widget = resolveWidgetByGroupId(rule.sourceFormFieldWidgetGroup.id);

        if (!widget) {
          return '';
        }

        if (widget.fieldType === FieldType.Email) {
          return 'Email Field';
        } else if (widget.fieldType === FieldType.Members) {
          return 'Members Field';
        } else {
          throw new Error('unsupported form field widget type');
        }
      } else {
        throw new Error('could not resolve rule description');
      }
    };

    $scope.getRuleTitle = function (rule) {
      if (rule.sourceType === TaskAssignmentRuleSourceType.ChecklistInitiator) {
        return 'Workflow Runner';
      } else if (
        rule.sourceType === TaskAssignmentRuleSourceType.FormField &&
        rule.sourceFormFieldWidgetGroup &&
        rule.sourceFormFieldWidgetGroup.id
      ) {
        const widget = resolveWidgetByGroupId(rule.sourceFormFieldWidgetGroup.id);

        return widget && (widget.label || widget.key);
      } else {
        throw new Error('could not resolve rule title');
      }
    };

    $scope.unassignUser = function ($event, user) {
      // If this is not here, then the box will dismiss after being clicked
      $event.stopPropagation();

      $scope.$emit('unassign', user);
    };

    function resolveWidgetByGroupId(groupId) {
      return $scope.emailAndMembersFieldWidgets.find(w => w.header.group.id === groupId);
    }

    $scope.removeRule = function ($event, rule) {
      // If this is not here, then the box will dismiss after being clicked
      $event.stopPropagation();

      $scope.$emit('removeRule', rule);
    };
  })
  .component('psManageAssignments', {
    transclude: true,
    // FIXME: This entire component needs to be split up into the ones that serve specific purposes
    bindings: {
      // These are used for all cases
      title: '<',
      users: '<',
      unassignable: '<',
      onUnassign: '&',

      // Rules and fields users for template task assignments only
      assignmentRules: '<',
      emailAndMembersFieldWidgets: '<',
      onRemoveRule: '&',

      // This one is used only for checklist task assignments
      checklistTaskAssignments: '<',
    },
    templateUrl,
    controller($scope) {
      const ctrl = this;

      ctrl.box = { visible: false };

      // This component communicates with the controller in the pop box via events in the scope

      ctrl.init = function () {
        $scope.$broadcast('init', {
          users: ctrl.users,
          assignmentRules: ctrl.assignmentRules,
          emailAndMembersFieldWidgets: ctrl.emailAndMembersFieldWidgets,
          checklistTaskAssignments: ctrl.checklistTaskAssignments,
          unassignable: ctrl.unassignable,
        });
      };

      ctrl.$onChanges = function (changes) {
        if (changes.title) {
          ctrl.title = changes.title.currentValue || 'Assignees';
        }

        $scope.$broadcast('changes', changes);
      };

      $scope.$on('unassign', (event, user) => {
        // Don't let the event escape the component
        event.stopPropagation();

        ctrl.onUnassign({ user });
      });

      $scope.$on('removeRule', (event, rule) => {
        // Don't let the event escape the component
        event.stopPropagation();

        ctrl.onRemoveRule({ rule });
      });

      ctrl.show = function () {
        ctrl.box.visible = true;
      };

      ctrl.hide = function () {
        ctrl.box.visible = false;
      };

      $scope.$on('$stateChangeStart', () => {
        ctrl.box.visible = false;
      });

      $scope.$on(TaskListEvent.SHOW_MULTI_SELECT_MENU, () => {
        ctrl.box.visible = false;
      });

      $scope.$on(TaskListEvent.HIDE_MULTI_SELECT_MENU, () => {
        ctrl.box.visible = false;
      });
    },
  });
