import * as React from 'react';
import {
  Center,
  HStack,
  Icon,
  IconButton,
  VStack,
  Link,
  StackProps,
  Text,
  TextProps,
  CenterProps,
} from 'components/design/next';
import prettyBytes from 'pretty-bytes';

export const FileUpload: React.FC<React.PropsWithChildren<StackProps>> = props => {
  return <HStack spacing="0" w="88" h="13" borderRadius="base" overflow="hidden" {...props} />;
};

export const FileUploadLeftElement: React.FC<React.PropsWithChildren<CenterProps>> = props => {
  return (
    <Center bgColor="brand.500" w="13" h="13" flexShrink={0} {...props}>
      <Icon icon="file" color="white" size="4" />
    </Center>
  );
};

export const FileUploadRightElement: React.FC<React.PropsWithChildren<StackProps>> = props => {
  return (
    <HStack
      flex="1"
      overflow="hidden"
      px="4"
      py="3"
      justifyContent="space-between"
      borderWidth="px"
      borderStyle="solid"
      borderColor="gray.200"
      borderLeft="none"
      h="full"
      {...props}
    />
  );
};

export const FileUploadRightElementInfo: React.FC<React.PropsWithChildren<StackProps>> = props => {
  return <VStack spacing="px" alignItems="flex-start" w="full" overflow="hidden" {...props} />;
};

export const DownloadFileIconButton = ({ href }: { href: string }) => {
  const handleDownload: React.MouseEventHandler = e => {
    e.stopPropagation();
  };
  return (
    <IconButton
      variant="ghost"
      colorScheme="gray"
      size="sm"
      as={Link}
      aria-label="download file"
      download
      icon={<Icon icon="arrow-down-to-line" size="4" />}
      onClick={handleDownload}
      isExternal
      href={href}
      borderRadius="full"
    />
  );
};

export const FileUploadSizeLabel: React.FC<React.PropsWithChildren<TextProps>> = props => {
  const size = typeof Number(props.children) === 'number' ? Number(props.children) : 0;
  return (
    <Text variant="-2" color="gray.500" {...props}>
      {prettyBytes(size)}
    </Text>
  );
};
