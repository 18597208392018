import React, { useState } from 'react';
import {
  IconButton,
  Icon,
  Button,
  Text,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ButtonGroup,
  useDisclosure,
} from 'components/design/next';
import { connect, useSelector } from 'react-redux';

import { Muid, Option, OrganizationMembership } from '@process-street/subgrade/core';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { ReduxAppState } from 'reducers/types';
import { UserSelector } from 'reducers/user/user.selectors';
import { HttpStatus } from '@process-street/subgrade/util';
import { OrganizationMembershipDeleteErrorCode } from 'components/organization-manage/users/RemoveUserButton/organization-membership-delete-error-code';
import { trace } from 'components/trace';
import { useInjector } from 'components/injection-provider';
import { useToast } from 'components/design/next';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { AnalyticsService } from 'components/analytics/analytics.service';

interface ConfirmBox {
  title: string;
  body: React.ReactNode;
}

interface StateToProps {
  isAdmin: boolean;
  isCurrentUser: boolean;
  membership: Option<OrganizationMembership>;
  organizationMembershipsSize: number;
  organizationName: Option<string>;
  userEmail: Option<string>;
  username: Option<string>;
}

export interface PureRemoveUserButtonProps {
  userId: Muid;
}

export type RemoveUserButtonProps = StateToProps & PureRemoveUserButtonProps;

const initialValues = {
  body: null,
  title: '',
};

const getRemoveSelfMessage = (organizationName: string): ConfirmBox => ({
  body: (
    <Text>
      If you remove yourself, you won't be able to access any of the workflows or workflow runs in{' '}
      <b>{organizationName}</b>. If you want to regain access, you'll need to ask a coworker to invite you to the
      organization again.
    </Text>
  ),
  title: 'Remove yourself from the organization?',
});

const getRemoveOtherUserMessage = (username: string): ConfirmBox => ({
  body: (
    <Text>
      If you remove <Text as="b">{username}</Text> from this organization, they will be unassigned from all workflow
      runs and tasks.
    </Text>
  ),
  title: 'Are you sure you want to remove this user?',
});

export const RemoveUserButtonPure: React.FunctionComponent<React.PropsWithChildren<RemoveUserButtonProps>> = ({
  userId,
  userEmail,
  membership,
  username,
  organizationName,
  isCurrentUser,
}) => {
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  if (!organizationId) {
    throw new Error('No organization to remove from');
  }

  const modalDisclosure = useDisclosure();

  const { OrganizationMembershipService } = useInjector('OrganizationMembershipService');
  const logger = trace({ name: 'OrganizationManageUserRemoveButtonComponent' });
  const [confirmBox, setConfirmBox] = useState<ConfirmBox>(initialValues);
  const [removeInProgress, setRemoveInProgress] = useState<boolean>(false);
  const toast = useToast();

  const handleRemove = () => {
    modalDisclosure.onOpen();
    const confirmBoxState = isCurrentUser
      ? getRemoveSelfMessage(organizationName as string)
      : getRemoveOtherUserMessage(username as string);
    setConfirmBox(confirmBoxState);
  };

  const handleConfirmOk = () => {
    setRemoveInProgress(true);
    modalDisclosure.onClose();

    if (!membership) {
      return;
    }

    OrganizationMembershipService.deleteByOrganizationIdAndUserId(organizationId, userId)
      .then(() => {
        logger.info('succeeded to remove user');

        AnalyticsService.trackEvent('user removed someone from organization', {
          'someone email': userEmail,
        });

        toast({
          status: 'success',
          title: `Member ${username as string} removed`,
        });
      })
      .catch(errorResponse => {
        switch (errorResponse.status) {
          case HttpStatus.FORBIDDEN:
            const { code } = errorResponse.data;
            logger.error(`failed to remove user with code ${code}`);
            let message = '';
            switch (code) {
              case OrganizationMembershipDeleteErrorCode.NotAllowed:
                message = `You cannot remove ${username}.`;
                break;
              case OrganizationMembershipDeleteErrorCode.LastAdminCannotRemoveSelfWhenPaying:
                message = `You cannot remove the last Admin from this organization until you cancel your subscription.`;
                break;
              case OrganizationMembershipDeleteErrorCode.LastAdminCannotRemoveSelfWhenOtherUsersExist:
                message = `You cannot remove the last Admin until you remove all other users from this organization.`;
                break;
              default:
                logger.error(`Did not recognize organization membership delete error code: ${code}`);
            }
            toast({
              status: 'warning',
              title: `We couldn't remove the member`,
              description: message,
            });
            break;
          default:
            toast({
              status: 'error',
              title: `We're having problems removing the member`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
            break;
        }
      })
      .finally(() => {
        setRemoveInProgress(false);
      });
  };

  return (
    <>
      <IconButton
        aria-label="Remove User"
        icon={<Icon icon="user-minus" variant="far" size="4" />}
        isLoading={removeInProgress}
        variant="ghost"
        colorScheme="gray"
        onClick={handleRemove}
      />
      <Modal {...modalDisclosure}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text as="h3" variant="1" fontWeight="bold">
              {confirmBox.title}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{confirmBox.body}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button variant="secondary" onClick={modalDisclosure.onClose}>
                Close
              </Button>
              <Button variant="danger" onClick={handleConfirmOk}>
                Remove User
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: ReduxAppState, { userId }: PureRemoveUserButtonProps): StateToProps => {
  const organizationId = SessionSelector.getSelectedOrganizationId(state);
  if (!organizationId) {
    throw new Error('No organization to remove from');
  }
  const organizationMembership = OrganizationMembershipSelector.getByOrganizationIdAndUserId(
    organizationId,
    userId,
  )(state);

  const organizationMembershipsSize: number =
    OrganizationMembershipSelector.getAllStandardWithUserByOrganizationId(organizationId)(state).length;
  const currentUserId = SessionSelector.getCurrentUserId(state);
  const user = UserSelector.getById(userId)(state);
  const selectedOrganization = SessionSelector.getSelectedOrganization(state);

  return {
    isAdmin: !!organizationMembership && organizationMembership.admin,
    isCurrentUser: currentUserId === userId,
    membership: organizationMembership,
    organizationMembershipsSize,
    organizationName: selectedOrganization ? selectedOrganization.name : undefined,
    userEmail: user && user.email ? user.email : undefined,
    username: user && user.username ? user.username : undefined,
  };
};

const mapDispatchToProps = (): {} => ({});

export const RemoveUserButton = connect<StateToProps, {}, PureRemoveUserButtonProps, ReduxAppState>(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveUserButtonPure);
