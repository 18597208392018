import { Muid } from '@process-street/subgrade/core';
import { FileWidget, ImageWidget, VideoWidget } from '@process-street/subgrade/process';
import { axiosService } from 'services/axios-service';

export type FinishWidgetUploadMutationParams = {
  headerId: Muid;
  contentType: string;
  key: string;
  originalFilename: string;
};

type UploadableWidget = FileWidget | ImageWidget | VideoWidget;
export type FinishWidgetUploadMutationResponse<W extends UploadableWidget> = W;

export const FinishWidgetUploadMutation = {
  key: ['widget', 'attachment'],
  mutationFn: <W extends UploadableWidget>(params: FinishWidgetUploadMutationParams) =>
    axiosService
      .getAxios()
      .post<FinishWidgetUploadMutationResponse<W>>(`/1/widgets/${params.headerId}/finish-upload`, params)
      .then(r => r.data),
};
