import angular from 'angular';
import { TaskStatus } from '@process-street/subgrade/process';
import { StopTaskEvent } from 'services/stop-task-event';

angular
  .module('frontStreetApp.services')
  .service('StopTaskService', function ($rootScope, RequiredFieldService, TaskTemplateService) {
    const self = this;

    /**
     * @returns {{disabledTaskTemplateGroupIds, firstStopGroupId}}
     */
    self.getDisabledTaskTemplateGroupIdsByFormFieldValue = function (
      taskTemplates,
      widgetsMap,
      formFieldValueMap,
      taskMap,
    ) {
      const invalidFormFieldsCountMap = RequiredFieldService.getInvalidCountMapUsingFormFieldValue(
        taskTemplates,
        widgetsMap,
        formFieldValueMap,
      );

      return self._findDisabledTaskTemplateGroupIds(taskTemplates, taskMap, invalidFormFieldsCountMap);
    };

    self._findDisabledTaskTemplateGroupIds = (taskTemplates, taskMap, invalidFormFieldsCountMap) => {
      const disabledTaskTemplateGroupIds = [];
      // Search for uncompleted stop task
      const firstStopIndex = taskTemplates.findIndex(
        taskTemplate =>
          taskTemplate.stop &&
          // PS-4595 - ignore stop task for heading
          !TaskTemplateService.isHeading(taskTemplate) &&
          taskMap[taskTemplate.group.id] &&
          !taskMap[taskTemplate.group.id].hidden &&
          taskMap[taskTemplate.group.id].status === TaskStatus.NotCompleted,
      );

      // Add all task templates after the uncompleted first stop task
      taskTemplates.forEach((taskTemplate, index) => {
        if (
          firstStopIndex > -1 &&
          index > firstStopIndex &&
          taskMap[taskTemplate.group.id] &&
          !taskMap[taskTemplate.group.id].hidden
        ) {
          disabledTaskTemplateGroupIds.push(taskTemplate.group.id);
        }
      });

      // Then add a stop task if there is any invalid required field
      for (let i = 0; i < firstStopIndex; i += 1) {
        const groupId = taskTemplates[i].group.id;
        if (
          invalidFormFieldsCountMap[groupId] > 0 &&
          taskMap[groupId] &&
          !taskMap[groupId].hidden &&
          taskMap[groupId].status === TaskStatus.NotCompleted
        ) {
          disabledTaskTemplateGroupIds.push(taskTemplates[firstStopIndex].group.id);
          break;
        }
      }

      const firstStopGroupId = firstStopIndex > -1 ? taskTemplates[firstStopIndex].group.id : null;
      return { disabledTaskTemplateGroupIds, firstStopGroupId };
    };

    self.getNotCompletedVisibleStopTasks = function (taskTemplates, taskMap) {
      return taskTemplates.reduce((notCompletedTasks, taskTemplate) => {
        const task = taskMap[taskTemplate.group.id];
        if (
          taskTemplate.stop &&
          !task.hidden &&
          !TaskTemplateService.isHeading(taskTemplate) &&
          task.status === TaskStatus.NotCompleted
        ) {
          notCompletedTasks.push(task);
        }
        return notCompletedTasks;
      }, []);
    };

    self.broadcastChecklistHasNotCompletedStopTasks = function (notCompletedStopTasks) {
      const data = {
        notCompletedStopTasks: notCompletedStopTasks || [],
      };

      $rootScope.$broadcast(StopTaskEvent.CHECKLIST_HAS_NOT_COMPLETED_STOP_TASKS, data);
    };
  });
