import { Button, Icon, Text, VStack } from 'components/design/next';
import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';
import { usePaywall } from 'components/paywalls';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useTemplateId } from 'pages/templates/_id/automation/utils/use-template-id';
import React from 'react';
import { FreeMember } from 'components/paywalls/edit-workflow/ui';
import { RunWorkflowButton } from 'components/focus-bar/workflow/run-workflow-button';

export const NoRowsRenderer: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const isDashboard = useWorkflowState() === 'dashboard';
  const templateId = useTemplateId();
  const templateQuery = useGetTemplateQuery({
    templateId,
  });
  const template = templateQuery.data;
  const { setPaywall } = usePaywall();

  const templatePermissionsQuery = useGetConsolidatedTemplatePermissionsQuery(template?.id, {
    enabled: Boolean(template),
  });
  const canRun = templatePermissionsQuery.data?.permissionMap.checklistCreate ?? false;

  const runAriaLabel = `run workflow ${template ? template.name : ''}`;

  return (
    <VStack spacing="4">
      {!isDashboard && <Icon aria-label="search" color="gray.200" size="10" icon="search" variant="far" />}

      <Text color="gray.500" fontSize={isDashboard ? 'lg' : 'xl'}>
        No workflow runs found
      </Text>

      {!isDashboard && (
        <Text fontSize="sm" color="gray.400">
          Try re-adjusting your search terms, filters,
          <br /> and/or workflows to display better results.
        </Text>
      )}

      {isDashboard && canRun && (
        <RunWorkflowButton>
          <Button
            aria-label={runAriaLabel}
            leftIcon={<Icon size="4" icon="play" variant="far" />}
            variant="primary"
            colorScheme="brand"
            fontSize="sm"
            onClick={canRun ? undefined : () => setPaywall(<FreeMember />)}
          >
            Run Workflow
          </Button>
        </RunWorkflowButton>
      )}
    </VStack>
  );
};
