import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import _random from 'lodash/random';
import { match, P } from 'ts-pattern';

export const MIN_CHAR_PER_KEYFRAME = 1;
export const MAX_CHAR_PER_KEYFRAME = 5;
export const MIN_DELAY_IN_MS = 50;
export const MAX_DELAY_IN_MS = 100;

/* Represents a function that can execute the animation of the keyframes */
type AnimatorFn<TKeyframe> = () => Promise<TKeyframe>;
type AnimatorCreatorOptions<TKeyframe> = {
  timeout: typeof setTimeout;
  update: (taskTemplateKeyframe: TKeyframe, index: number) => void;
  addMissingItems?: boolean;
};

// Creates an array with timings that each frame should be executed
const getTimings = (framesLength: number): number[] => {
  return Array.from({ length: framesLength }).reduce<number[]>(timings => {
    const lastKeyframeTiming = timings[timings.length - 1] ?? 0;
    const delay = _random(MIN_DELAY_IN_MS, MAX_DELAY_IN_MS);

    // Sum with the last keyframe timing so the frames can run in sequence
    timings.push(delay + lastKeyframeTiming);

    return timings;
  }, []);
};

/*
 * Gets a string and returns a list containing its keyframes.
 *
 * A keyframe is a "picture" of the text at some point in time.
 * This is an example of return value when passing the "text" string:
 * ['t', 'tex', 'text']
 * The size of each chunk is random, allowing us to have an animation effect
 * similar to Chat GPT.
 */
const createTextKeyFrames = (text: string): string[] => {
  if (!text) return [text];

  const keyframes = [''];
  let textCharIndex = 0;

  while (textCharIndex < text.length) {
    const substringLength = _random(MIN_CHAR_PER_KEYFRAME, MAX_CHAR_PER_KEYFRAME);
    const nameChunk = text.substring(textCharIndex, textCharIndex + substringLength);
    const lastKeyframe = keyframes[keyframes.length - 1];

    keyframes.push(lastKeyframe + nameChunk);

    textCharIndex += substringLength;
  }

  return keyframes;
};

/*
 * Creates the keyframes for the widget label.
 */
const createLabelKeyframes = (widget: Widget): Widget[] => {
  const emptyWidget: Widget = match(widget)
    .with(
      { config: { items: P.array({ name: P.string, id: P.string }) } },
      w =>
        ({
          ...w,
          label: '',
          config: {
            ...w.config,
            items: [],
          },
        } as Widget),
    )
    .otherwise(w => ({
      ...w,
      label: '',
    }));

  const labelKeyframes = match(widget)
    .with({ label: P.not(P.nullish) }, ({ label }) => createTextKeyFrames(label))
    .otherwise(() => [''])
    .map(keyframe => ({
      ...emptyWidget,
      label: keyframe,
    }));

  return labelKeyframes;
};

/*
 * Creates the keyframes for widgets that has `widget.config.items` available.
 */
const createConfigItemsKeyframes = (widget: Widget, addMissingItems = false): Widget[] => {
  return match(widget)
    .with({ config: { items: P.array({ name: P.string, id: P.string }) } }, widget => {
      const widgetWithoutItems = {
        ...widget,
        config: {
          ...widget.config,
          items: addMissingItems ? widget.config.items.map(i => ({ id: i.id, name: '' })) : [],
        },
      };

      const keyframes: typeof widget[] = [widgetWithoutItems];

      widget.config.items.forEach((item, itemIndex) => {
        const itemKeyframes = createTextKeyFrames(item.name).map(name => ({
          ...item,
          name,
        }));

        itemKeyframes.forEach(keyframe => {
          const lastWidgetKf = keyframes[keyframes.length - 1];
          const newWidget = {
            ...lastWidgetKf,
            config: {
              ...lastWidgetKf.config,
              items: [...lastWidgetKf.config.items],
            },
          };

          newWidget.config.items[itemIndex] = keyframe;

          keyframes.push(newWidget);
        });
      });
      return keyframes as Widget[];
    })
    .otherwise(() => []);
};

const createTaskTemplateKeyframes = (taskTemplate: TaskTemplate): TaskTemplate[] => {
  if (!taskTemplate.name) return [taskTemplate];

  const keyframes = createTextKeyFrames(taskTemplate.name).map(name => ({
    ...taskTemplate,
    name,
  }));

  return keyframes;
};

const createWidgetKeyframes = (widget: Widget, addMissingItems: boolean = false) => {
  const labelKeyframes = createLabelKeyframes(widget);
  const configItemsKeyframes: Widget[] = createConfigItemsKeyframes(widget, addMissingItems);

  return [...labelKeyframes, ...configItemsKeyframes];
};

const createTaskTemplatesAnimator = (
  taskTemplates: TaskTemplate[],
  options: AnimatorCreatorOptions<TaskTemplate>,
): AnimatorFn<TaskTemplate> => {
  const taskTemplatesIndexMap = Object.fromEntries(taskTemplates.map((tt, index) => [tt.id, index]));
  const keyframes = taskTemplates.flatMap(tt => createTaskTemplateKeyframes(tt));
  const keyframeTimings = getTimings(keyframes.length);

  const animate = async () => {
    return new Promise<TaskTemplate>(resolve => {
      keyframes.forEach((keyframe, keyframeIndex) => {
        const timing = keyframeTimings[keyframeIndex];
        const index = taskTemplatesIndexMap[keyframe.id];
        const isLast = keyframes.length - 1 === keyframeIndex;

        options.timeout(() => {
          options.update(keyframe, index);

          if (isLast) resolve(keyframe);
        }, timing);
      });
    });
  };

  return animate;
};

type TaskTemplateGroupId = TaskTemplate['group']['id'];

const createWidgetsAnimator = (
  widgetsGroupedByTaskTemplateGroupId: Record<TaskTemplateGroupId, Widget[]>,
  options: AnimatorCreatorOptions<Widget>,
): AnimatorFn<Widget> => {
  const widgetIndexById: Record<Widget['id'], number> = Object.fromEntries(
    Object.values(widgetsGroupedByTaskTemplateGroupId).flatMap(widgetsForStep =>
      widgetsForStep.map((w, i) => [w.id, i]),
    ),
  );
  const widgets = Object.values(widgetsGroupedByTaskTemplateGroupId).flat();

  const keyframes = widgets.flatMap(widget => createWidgetKeyframes(widget, options.addMissingItems));
  const keyframeTimings = getTimings(keyframes.length);

  const animate = async (): Promise<Widget> => {
    return new Promise(resolve => {
      keyframes.forEach((keyframe, keyframeIndex) => {
        const timing = keyframeTimings[keyframeIndex];
        const widgetIndex = widgetIndexById[keyframe.id];
        const isLast = keyframes.length - 1 === keyframeIndex;

        options.timeout(() => {
          options.update(keyframe, widgetIndex);

          if (isLast) resolve(keyframe);
        }, timing);
      });
    });
  };

  return animate;
};

export const AiGeneratorAnimationService = {
  createTaskTemplateKeyframes,
  createWidgetKeyframes,
  createTaskTemplatesAnimator,
  createWidgetsAnimator,
  getTimings,
};
