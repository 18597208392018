import { PromiseQueueConstants } from './promise-queue-constants';

export namespace PromiseQueueKeyGenerator {
  export function generateByChecklistId(id: string): string {
    return generate(PromiseQueueConstants.Namespace.CHECKLIST, id);
  }

  export function generateForMergeTagsParsingByWidgetId(id: string): string {
    return generate(PromiseQueueConstants.Namespace.TAGS_PARSING, id);
  }

  export function generate(prefix: string, id: string): string {
    return `${prefix}:${id}`;
  }
}
