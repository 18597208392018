const CAPTURE_ERROR = 'error/CAPTURE_ERROR';

export interface ErrorCaptureExceptionAction {
  type: 'error/CAPTURE_ERROR';
  payload: Error;
}

const captureException = (error: Error): ErrorCaptureExceptionAction => ({
  type: CAPTURE_ERROR,
  payload: error,
});
captureException.type = CAPTURE_ERROR;

const CAPTURE_MESSAGE = 'error/CAPTURE_MESSAGE';

export interface ErrorCaptureMessageAction {
  type: 'error/CAPTURE_MESSAGE';
  payload: string;
}

const captureMessage = (message: string): ErrorCaptureMessageAction => ({
  type: CAPTURE_MESSAGE,
  payload: message,
});
captureMessage.type = CAPTURE_MESSAGE;

export const ErrorActions = {
  captureException,
  captureMessage,
};
