import * as React from 'react';
import { Flex, HStack, MenuItem, Switch, Text, Tooltip } from 'components/design/next';
import { useGetSettingsQuery, useUpdateSettingsMutation } from 'features/user/query-builder';
import { useCurrentUser } from 'hooks/use-current-user';
import { SettingsKey } from 'components/user/user-constants';

export interface RedirectNotificationsToggleProps {
  isMobile?: boolean;
}

export const RedirectNotificationsToggle = ({ isMobile }: RedirectNotificationsToggleProps) => {
  const [isRedirecting, setIsRedirecting] = React.useState(false);

  const currentUser = useCurrentUser();
  const userSettingQuery = useGetSettingsQuery({ id: currentUser?.id });

  const redirectSetting = userSettingQuery.data?.sandboxNotificationsRedirect ?? false;

  React.useEffect(() => {
    if (redirectSetting !== isRedirecting) {
      setIsRedirecting(redirectSetting);
    }
  }, [isRedirecting, redirectSetting]);

  const updateSettingsMutation = useUpdateSettingsMutation();

  const onToggle = async () => {
    const value = !isRedirecting;
    setIsRedirecting(value);
    await updateSettingsMutation.mutateAsync({
      id: currentUser?.id!,
      key: SettingsKey.SANDBOX_NOTIFICATIONS_REDIRECT,
      value,
    });
    await userSettingQuery.refetch();
  };

  const isSwitchEnabled = !userSettingQuery.isLoading && Boolean(currentUser) && !updateSettingsMutation.isLoading;

  const contents = (
    <HStack spacing={1} alignItems="center">
      <Tooltip label="All email notifications will be redirected to your email address." placement="bottom" hasArrow>
        <Flex gap={2}>
          <Switch
            size="lg"
            isReadOnly={!isSwitchEnabled} // prevents mouse cursor flash during loading state
            onChange={onToggle}
            isChecked={isRedirecting}
            mr={1}
            aria-label="Toggle email notifications"
          />
          <Text fontSize={isMobile ? 'lg' : 'md'}>Receive email notifications</Text>
        </Flex>
      </Tooltip>
    </HStack>
  );
  return isMobile ? (
    <MenuItem closeOnSelect={false} onClick={onToggle}>
      {contents}
    </MenuItem>
  ) : (
    contents
  );
};
