import angular from 'angular';
import Muid from 'node-muid';
import { UrlUtils } from 'components/utils/url-utils';
import { uuid } from 'services/uuid';
import templateUrl from './template-multi-select-field.component.html';
import './template-multi-select-field.scss';
import { trace } from 'components/trace';
import { MergeTagMode, MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { MergeTagTarget } from '@process-street/subgrade/form';
import { createMergeTag } from 'hooks/use-insert-merge-tag-callback';
import { FormFieldEvent } from 'services/form-field-event';

angular.module('frontStreetApp.directives').component('psTemplateMultiSelectFormField', {
  bindings: {
    widget: '<',
    editable: '<',
    onUpdateWidget: '&',
  },
  templateUrl,
  controller(
    $filter,
    $ngRedux,
    $rootScope,
    $timeout,
    $scope,
    FeatureFlagService,
    focusById,
    TextInsertService,
    MergeTagsService,
    util,
  ) {
    const ctrl = this;
    const logger = trace({ name: 'MultiSelectFieldCtrl' });

    // Editable

    const KEY_UP = 38;
    const KEY_DOWN = 40;
    const KEY_ENTER = 13;
    const KEY_BACKSPACE = 8;

    $scope.$on(FormFieldEvent.SUB_CHECKLIST_ADD_ITEM_BUTTON_CLICKED, () => {
      createItemAt(ctrl.widget, ctrl.widget.config.items.length);
    });

    $scope.isVariablesInSubtasksEnabled = FeatureFlagService.getFeatureFlags().variablesInSubtasks;

    ctrl.$onInit = function () {
      ctrl.initializeSortable();
      ctrl.initializeMergeTags();
    };

    ctrl.initializeSortable = () => {
      ctrl.taskTemplateSortableOptions = {
        start(event) {
          if (event.ctrlKey || event.metaKey || event.shiftKey) {
            ctrl.taskTemplateSortableOptions.disabled = true;
          } else {
            ctrl.taskTemplateDragging = true;
          }
        },
        stop() {
          ctrl.taskTemplateDragging = false;
        },
        update() {
          ctrl.updateWidget(ctrl.widget);
        },
        disabled: !ctrl.editable,
        revert: true,
        scrollSensitivity: 70,
        tolerance: 'pointer',
        axis: 'y',
        opacity: 0.8,
      };
    };

    ctrl.initializeMergeTags = () => {
      if (!ctrl.widget.templateRevision) return;

      MergeTagsService.getAllByTemplateRevision(ctrl.widget.templateRevision, MergeTagTarget.GENERAL).then(
        mergeTags => {
          ctrl.tags = Object.entries(mergeTags).map(([key, value]) => ({ key, default: value }));
        },
      );
    };

    ctrl.handleItemNameKeydown = function (event, widget, index) {
      // This doesn't work well on mobile, so disable it
      if (util.isMobile()) {
        return;
      }

      const { items } = widget.config;
      const item = items[index];

      switch (event.keyCode) {
        case KEY_UP: {
          const aboveIndex = Math.max(0, index - 1);
          const aboveItem = items[aboveIndex];
          focusById(`widget-${widget.header.id}-item-${aboveItem.id}`);
          event.preventDefault();
          break;
        }
        case KEY_DOWN: {
          const belowIndex = Math.min(index + 1, items.length - 1);
          const belowItem = items[belowIndex];
          focusById(`widget-${widget.header.id}-item-${belowItem.id}`);
          event.preventDefault();
          break;
        }
        case KEY_ENTER:
          createItemAt(widget, index);
          event.preventDefault();
          break;
        case KEY_BACKSPACE:
          if (items.length > 1 && item && !item.name) {
            deleteItemAt(widget, index);
            event.preventDefault();
          }
          event.stopPropagation();
          break;
        default: // We don't care about these keys
      }
    };

    ctrl.inputItem = function (__event, widget, item) {
      let lines;
      if (item.name) {
        lines = item.name.split('\n');
      } else {
        lines = [];
      }

      // This is for the situation where it's just one line
      [item.name] = lines;

      const index = widget.config.items.indexOf(item);
      if (index >= 0) {
        // Update the current item we're on
        ctrl.updateWidget(widget);

        // If we had more than 1 line, then create the rest as well
        lines.slice(1).forEach((line, i) => {
          createItemAt(widget, index + i, line);
        });
      } else {
        logger.error('could not find item: %s', JSON.stringify(item));
      }
    };

    function createItemAt(widget, index, name) {
      const newItem = { id: Muid.fromUuid(uuid()), name: name || '' };

      widget.config.items.splice(index + 1, 0, newItem);
      ctrl.updateWidget(widget);

      focusById(`widget-${widget.header.id}-item-${newItem.id}`);
    }

    function deleteItemAt(widget, index) {
      const aboveItem = widget.config.items[index - 1];

      widget.config.items.splice(index, 1);
      ctrl.updateWidget(widget);

      if (aboveItem) {
        focusById(`widget-${widget.header.id}-item-${aboveItem.id}`);
      }
    }

    /**
     * Used to keep track of when to update
     */
    let updateWidgetTimeout;

    /**
     * Update in 500 ms, unless another change is made
     *
     * @param widget
     */
    ctrl.updateWidget = widget => {
      $timeout.cancel(updateWidgetTimeout);
      updateWidgetTimeout = $timeout(() => {
        ctrl.onUpdateWidget({ widget });
      }, 500);
    };

    // Viewable

    ctrl.clickDisabledInput = function () {
      $rootScope.$broadcast(FormFieldEvent.DISABLED_FORM_FIELD_CLICKED);
    };

    ctrl.setTargetAttribute = function (url) {
      return {
        target: UrlUtils.isAppUrl(url) ? '_self' : '_blank',
      };
    };

    ctrl.deleteItem = deleteItemAt;

    ctrl.insertVariable = function (key, fieldId, fallback) {
      const [field] = angular.element(`[id="${fieldId}"]`);

      TextInsertService.insert(field, createMergeTag(key, fallback));
    };

    ctrl.getSubtaskItemFieldId = item => `widget-${ctrl.widget.header.id}-item-${item.id}`;

    ctrl.parseMergeTag = content => {
      const value =
        content === undefined
          ? ''
          : MergeTagStringReplacementUtils.replaceResolvedTagsValues(ctrl.tags ?? [], content, MergeTagMode.HTML);

      if (value.includes('Variable value is missing')) return value;

      return $filter('psLinky')(value, null, ctrl.setTargetAttribute);
    };
  },
});
