import angular from 'angular';
import templateUrl from './attachment.component.html';

angular.module('frontStreetApp.directives').component('psAttachment', {
  bindings: {
    attachment: '<',
    deletable: '<',
    onDelete: '&',
    onCancel: '&',
  },
  templateUrl,
  controller(UserService, $state) {
    const ctrl = this;

    ctrl.$onInit = function () {
      const { createdBy } = ctrl.attachment.audit;
      ctrl.item = { attachment: ctrl.attachment };
      if (createdBy.username) {
        ctrl.createdByUser = createdBy;
        ctrl.userLink = $state.href('userManage', { id: createdBy.id, tab: 'activity' });
      } else if (createdBy.id) {
        UserService.getById(createdBy.id).then(user => {
          ctrl.createdByUser = user;
          ctrl.userLink = $state.href('userManage', { id: ctrl.createdByUser.id, tab: 'activity' });
        });
      } else {
        throw new Error('createdBy has no id but should have');
      }
    };
    ctrl.deleteAttachment = function () {
      ctrl.onDelete({ doodad: ctrl.attachment });
    };

    ctrl.cancelUpload = function () {
      ctrl.onCancel({ doodad: ctrl.attachment });
    };
  },
});
