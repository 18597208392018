import { AuditMetadata, Identifiable, Muid } from '../core';

export enum ApprovalStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export interface Approval extends Identifiable {
  readonly id: Muid;
  audit: AuditMetadata;
  organizationId: Muid;
  approvalTaskId: Muid;
  subjectTaskId: Muid;
  status: ApprovalStatus;
  reviewedById: Muid;
  comment: string;
  checklistRevisionId?: Muid;
}

export interface ApprovalWithChecklistRevisionId extends Approval {
  checklistRevisionId: Muid;
}
