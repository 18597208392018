import * as React from 'react';
import { useNgStateModalControls } from 'components/ng-state-modal-controls';
import { ModalKey } from 'components/template-gallery/components/TemplateGalleryModal/TemplateGalleryModal';
import { useShouldBlockCreateTemplate } from './use-should-block-create-template';

export const useTemplateGalleryPaywallCheck = (): void => {
  const { onClose, isOpen } = useNgStateModalControls(ModalKey);
  const { shouldBlock } = useShouldBlockCreateTemplate();

  // this is a little glitchy at the moment, but not sure how to intercept before the modal get's set first
  React.useEffect(() => {
    if (isOpen && shouldBlock) {
      onClose();
    }
  }, [onClose, isOpen, shouldBlock]);
};
