import * as React from 'react';
import { EmailFormFieldWidget, FieldType, MembersFormFieldWidget } from '@process-street/subgrade/process';
import {
  TaskAssignmentRule as TTaskAssignmentRule,
  TaskAssignmentRuleSourceType,
  TaskAssignmentRuleUtils,
} from '@process-street/subgrade/role-assignment';
import { VStack, HStack, ListItem, Avatar, Text, IconButton, Spacer } from '@chakra-ui/react';
import { Muid } from '@process-street/subgrade/core';
import { Icon } from 'components/design/next';
import {
  DeleteTaskAssignmentRuleMutation,
  useDeleteTaskAssignmentRuleMutation,
  GetTaskAssignmentRulesByTemplateRevisionIdQuery,
  DeleteAllTaskAssignmentRulesMutation,
} from 'features/task-assignment-rules/query-builder';
import { match } from 'ts-pattern';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import { useQueryClient } from 'react-query';

interface TaskAssignmentRuleProps {
  rule: TTaskAssignmentRule & { widget?: EmailFormFieldWidget | MembersFormFieldWidget };
  templateRevisionId: Muid;
  isReadOnly?: boolean;
  isEditorV2?: boolean;
  assignmentRules?: TTaskAssignmentRule[]; // passing all rules for bulk mode to remove all similar rules
}

export const TaskAssignmentRule: React.FC<React.PropsWithChildren<TaskAssignmentRuleProps>> = ({
  rule,
  templateRevisionId,
  isReadOnly = false,
  isEditorV2 = false,
  assignmentRules = [],
}) => {
  const queryClient = useQueryClient();
  const deleteRuleMutation = useDeleteTaskAssignmentRuleMutation({
    mutationKey: DeleteTaskAssignmentRuleMutation.key,
    onSuccess: () => {
      queryClient.setQueryData<TTaskAssignmentRule[]>(
        GetTaskAssignmentRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
        current => current?.filter(r => r.id !== rule.id) ?? [],
      );
    },
  });

  const bulkDeleteRulesMutation = DeleteAllTaskAssignmentRulesMutation.useMutation({
    onSuccess: (_data, variables) => {
      const ruleIdsSet = new Set(variables.ruleIds);
      queryClient.setQueryData<TTaskAssignmentRule[]>(
        GetTaskAssignmentRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
        current => current?.filter(r => !ruleIdsSet.has(r.id)) ?? [],
      );
    },
  });

  const deleteRule = () => deleteRuleMutation.mutate({ templateRevisionId, ruleId: rule.id });
  const deleteAllRules = () => {
    const ruleIds = assignmentRules.filter(r => TaskAssignmentRuleUtils.areRulesSameType(r, rule)).map(r => r.id);
    if (ruleIds.length > 0) {
      bulkDeleteRulesMutation.mutate({ templateRevisionId, ruleIds });
    }
  };

  const handleRuleDeletion = () => {
    if (assignmentRules.length > 0) {
      deleteAllRules();
    } else {
      deleteRule();
    }
  };

  const icon = match<typeof rule, CustomIconName>(rule)
    .with({ sourceType: TaskAssignmentRuleSourceType.ChecklistInitiator }, () => 'user')
    .with({ widget: { fieldType: FieldType.Email } }, () => 'envelope')
    .with({ widget: { fieldType: FieldType.Members } }, () => 'users')
    .otherwise(() => 'user');

  const description = match(rule)
    .with(
      { sourceType: TaskAssignmentRuleSourceType.FormField, widget: { fieldType: FieldType.Email } },
      () => 'Email Field',
    )
    .with(
      { sourceType: TaskAssignmentRuleSourceType.FormField, widget: { fieldType: FieldType.Members } },
      () => 'Members Field',
    )
    .otherwise(() => undefined);

  const title = match(rule)
    .with({ sourceType: TaskAssignmentRuleSourceType.ChecklistInitiator }, () => 'Workflow Runner')
    .with({ sourceType: TaskAssignmentRuleSourceType.FormField }, () => rule.widget?.label || rule.widget?.key)
    .run();

  return (
    <HStack as={ListItem} w="full">
      <Avatar icon={<Icon icon={icon} size={isEditorV2 ? '3' : '4'} />} size={isEditorV2 ? 'xs' : 'inherit'} />
      <VStack alignItems="flex-start" w="full" spacing="px">
        <Text variant="-1" fontWeight="medium" fontSize={isEditorV2 ? 'xs' : 'inherit'}>
          {title}
        </Text>
        {description && (
          <Text variant="-2" color="gray.400" fontSize={isEditorV2 ? 'xs' : 'inherit'}>
            {description}
          </Text>
        )}
      </VStack>
      <Spacer />
      {!isReadOnly && (
        <IconButton
          aria-label="Remove assignment"
          icon={<Icon icon="user-xmark" size="4" />}
          onClick={handleRuleDeletion}
          variant="ghost"
          color="gray.500"
          colorScheme="gray"
          size="sm"
        />
      )}
    </HStack>
  );
};
