/**
 * Error types
 * @readonly
 * @memberof StripeConstants
 * @enum {string}
 */
enum ErrorType {
  CARD_ERROR = 'card_error',
}

/**
 * Error codes
 * @readonly
 * @memberof StripeConstants
 * @enum {string}
 */
enum ErrorCode {
  INVALID_NUMBER = 'invalid_number',
  INVALID_EXPIRY_MONTH = 'invalid_expiry_month',
  INVALID_EXPIRY_YEAR = 'invalid_expiry_year',
  INVALID_CVC = 'invalid_cvc',
  INVALID_SWIPE_DATA = 'invalid_swipe_data',
  INCORRECT_NUMBER = 'incorrect_number',
  EXPIRED_CARD = 'expired_card',
  INCORRECT_CVC = 'incorrect_cvc',
  INCORRECT_ZIP = 'incorrect_zip',
  CARD_DECLINED = 'card_declined',
  MISSING = 'missing',
  PROCESSING_ERROR = 'processing_error',
  CARD_ERROR = 'card_error',
}

export const StripeConstants = {
  ErrorType,
  ErrorCode,
};
