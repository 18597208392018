import * as React from 'react';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Center } from 'components/design/next';
import { match } from 'ts-pattern';
import { HttpStatus } from '@process-street/subgrade/util';

export interface ChartAlertProps {
  statusCode?: number;
}

export const ChartAlert: React.VFC<ChartAlertProps> = ({ statusCode }) => {
  return (
    <Center h="full">
      <Alert status="error" display="inline-flex" w="auto">
        <AlertIcon />
        <AlertTitle>Uh oh!</AlertTitle>
        <AlertDescription>
          {match(statusCode)
            .with(HttpStatus.GATEWAY_TIMEOUT, () => 'That query was too complex. Please try again…')
            .otherwise(() => 'Unable to load this chart. Please contact support.')}
        </AlertDescription>
      </Alert>
    </Center>
  );
};
