import { RuleConstants } from '@process-street/subgrade/conditional-logic/rule-constants';
import { trace } from 'components/trace';
import { FormFieldValueExtractor } from '../services/form-field-value-extractor';
import { OperandTypeResolver } from './operand-type-resolver';
import { MultiChoiceFormFieldValue, MultiChoiceFormFieldWidget } from '@process-street/subgrade/process';
import { ConditionEvaluator } from '@process-street/subgrade/conditional-logic';
import { FormFieldValueConverter } from 'directives/rules/services/form-field-value-converter';

const logger = trace({ name: 'HasNoValueCondition' });

export const HasNoValueCondition: ConditionEvaluator = {
  evaluate: (formFieldValue, formFieldWidget, _operand) => {
    if (!formFieldWidget) return false;
    if (!formFieldValue) return true;

    const operandType = OperandTypeResolver.resolve(formFieldWidget);
    let evaluatedAsTrue = false;

    switch (operandType) {
      case RuleConstants.OperandType.STRING:
        const value = FormFieldValueConverter.convertToStringOperand(formFieldWidget, formFieldValue);
        evaluatedAsTrue = !value;
        break;
      case RuleConstants.OperandType.STRING_LIST:
        const selectedItemNames = FormFieldValueExtractor.extractSelectedItemNamesFromMultiChoice(
          formFieldValue as MultiChoiceFormFieldValue,
          formFieldWidget as MultiChoiceFormFieldWidget,
        );
        evaluatedAsTrue = selectedItemNames.length === 0;
        break;
      case RuleConstants.OperandType.DATE_TIME:
        const valueMoment = FormFieldValueExtractor.extract(formFieldValue, formFieldWidget);
        if (!valueMoment || ('isValid' in valueMoment && !valueMoment.isValid())) {
          evaluatedAsTrue = true;
        }
        break;
      default:
        logger.error('unsupported operand type: %s', operandType);
    }

    return evaluatedAsTrue;
  },
};
