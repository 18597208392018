import { Group } from '@process-street/subgrade/core';
import { useQuery, QueryKey, UseQueryOptions, QueryClient, QueryObserver, QueryObserverOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { AxiosError } from 'axios';

type GetAllGroupsParams = { include: 'user' };
export type GetAllGroupsResponse = Group[];

export const GetAllGroupsQuery = {
  getKey: (params: GetAllGroupsParams): QueryKey => ['groups', params],
  queryFn: (params: GetAllGroupsParams) =>
    axiosService
      .getAxios()
      .get<GetAllGroupsResponse>('/1/groups', { params })
      .then(r => r.data),
};

export const useGetAllGroupsQuery = <Select = GetAllGroupsResponse>(
  params: GetAllGroupsParams,
  options: UseQueryOptions<GetAllGroupsResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetAllGroupsQuery.getKey(params), () => GetAllGroupsQuery.queryFn(params), options);
};

export const makeGetAllGroupsQueryObserver = ({
  queryClient,
  options,
}: {
  queryClient: QueryClient;
  options?: QueryObserverOptions<GetAllGroupsResponse, AxiosError>;
}) => {
  return new QueryObserver<GetAllGroupsResponse, AxiosError>(queryClient, {
    queryKey: GetAllGroupsQuery.getKey({ include: 'user' }),
    queryFn: () => GetAllGroupsQuery.queryFn({ include: 'user' }),
    enabled: options?.enabled !== false,
    ...options,
  });
};
