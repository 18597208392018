import { dayjs as moment } from '@process-street/subgrade/util';
import { queryClient } from 'components/react-root';
import { GetInboxStatsByOrganization } from 'features/inbox/query-builder';

export const InboxApi = function (InboxItems, $q) {
  'ngInject';

  /**
   * Gets a single user by id
   *
   * @param organizationId
   * @param filters
   * @param offset
   * @return {Promise}
   */
  const getAllByOrganizationId = (organizationId, filters = {}, offset = 0) => {
    const params = { ...filters, organizationId, offset };
    return InboxItems.getAllByOrganizationId(params).$promise;
  };

  const getInboxStatsByOrganization = (organizationId, userId, itemsType) => {
    const params = {
      dateTo: moment().endOf('day').format('x'),
      dateFrom: moment().endOf('day').format('x'),
      userId,
      organizationId,
      itemsType,
    };

    return $q((resolve, reject) =>
      queryClient
        .fetchQuery({
          queryKey: GetInboxStatsByOrganization.getKey({ userId, organizationId, itemsType }),
          queryFn: () => GetInboxStatsByOrganization.queryFn(params),
          staleTime: 1000 * 60 * 10, // 10 Minutes
        })
        .then(resolve)
        .catch(reject),
    );
  };
  return {
    getAllByOrganizationId,
    getInboxStatsByOrganization,
  };
};
