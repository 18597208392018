import { createAction } from 'redux-actions';
import { checklistUserAssignmentsSelector } from 'reducers/checklist-assignment/checklist-assignment.selectors';
import { TaskSelector } from 'reducers/task/task.selectors';
import { ChecklistSelector } from 'reducers/checklist/checklist.selectors';

export const CHECKLIST_UPDATE_STARTED_EVENT = 'scopeEvent/checklist/UPDATE_STARTED';
export const CHECKLIST_DELETE_OK_EVENT = 'scopeEvent/checklist/DELETE_OK';
export const CHECKLIST_ASSIGNMENT_CREATE_OK_EVENT = 'scopeEvent/checklistAssignment/CREATE_OK';
export const CHECKLIST_ASSIGNMENT_DELETE_OK_EVENT = 'scopeEvent/checklistAssignment/DELETE_OK';
export const TASK_ASSIGNED_UNASSIGNED_EVENT = 'scopeEvent/task/ASSIGNED_UNASSIGNED';
export const TASK_STATUS_UPDATE_OK_EVENT = 'scopeEvent/taskStatus/UPDATE_OK';
export const INBOX_UPDATED_EVENT = 'scopeEvent/inbox/UPDATED';
export const TASK_DUE_DATE_UPDATE_OK_EVENT = 'scopeEvent/taskDueDate/UPDATE_OK';

/*eslint no-unused-vars:0*/
const eventPayload = (_event, data) => {
  if (data && data.$promise) {
    if (data.$resolved) {
      return data;
    } else {
      return data.$promise;
    }
  } else {
    return data;
  }
};

const checklistUpdateStartedEvent = createAction(CHECKLIST_UPDATE_STARTED_EVENT, eventPayload, (_event, data) => ({
  organizationId: data.updatedChecklist.organization.id,
}));

const checklistDeleteOkEvent = createAction(CHECKLIST_DELETE_OK_EVENT, eventPayload, (_event, checklist) => ({
  organizationId: checklist.organization.id,
}));

const taskStatusUpdateOkEvent = createAction(
  TASK_STATUS_UPDATE_OK_EVENT,
  (updatedTask, dueDateTaskStates, originalTask, assignees) => ({
    updatedTask,
    originalTask,
    dueDateTaskStates,
    assignees,
  }),
  updatedTask => ({ organizationId: updatedTask.organization.id }),
);

const taskDueDateUpdateOkEvent = createAction(TASK_DUE_DATE_UPDATE_OK_EVENT, eventPayload, (_event, data) => ({
  organizationId: data.originalTask.organization.id,
}));

const getChecklistTasks = (checklistId, state) => {
  const tasks = TaskSelector.getAllWithTaskTemplateByChecklistId(checklistId)(state);
  const taskToAssignees = tasks.map(task => {
    const assignees = TaskSelector.getAllUserAssignmentsByTaskId(task.id)(state);
    return { assignees, task };
  });
  return taskToAssignees;
};

export const ScopeEventActions = {
  checklistUpdateStarted: (_event, data) => (dispatch, getState) => {
    const checklistId = data.updatedChecklist.id;
    const state = getState();

    let { originalChecklist } = data;
    // Sometimes originalChecklist is the same as updated and all information about update is lost
    // This is a workaround to prevent this
    if (
      data.updatedChecklist.dueDate === data.originalChecklist.dueDate &&
      data.updatedChecklist.status === data.originalChecklist.status
    ) {
      originalChecklist = ChecklistSelector.getById(checklistId)(state) || originalChecklist;
    }
    const assignees = checklistUserAssignmentsSelector(checklistId)(state);
    const tasks = getChecklistTasks(checklistId, state);
    const dataWithAssignments = {
      ...data,
      originalChecklist,
      assignees,
      tasks,
    };
    dispatch(checklistUpdateStartedEvent(_event, dataWithAssignments));
  },
  checklistDeleteOk: (_event, data) => (dispatch, getState) => {
    const checklistId = data.id;
    const state = getState();
    const assignees = checklistUserAssignmentsSelector(checklistId)(state);
    const tasks = getChecklistTasks(checklistId, state);
    const dataWithAssignments = { ...data, assignees, tasks };
    dispatch(checklistDeleteOkEvent(_event, dataWithAssignments));
  },
  taskStatusUpdateOk: (_event, updatedTask, dueDateTaskStates, originalTask) => (dispatch, getState) => {
    const taskId = updatedTask.id;
    const assignees = TaskSelector.getAllUserAssignmentsByTaskId(taskId)(getState());
    dispatch(taskStatusUpdateOkEvent(updatedTask, dueDateTaskStates, originalTask, assignees));
  },
  taskDueDateUpdateOk: (_event, data) => (dispatch, getState) => {
    const taskId = data.originalTask.id;
    const assignees = TaskSelector.getAllUserAssignmentsByTaskId(taskId)(getState());
    const newData = { ...data, assignees };
    dispatch(taskDueDateUpdateOkEvent(_event, newData));
  },
  checklistAssignmentCreateOk: createAction(CHECKLIST_ASSIGNMENT_CREATE_OK_EVENT, eventPayload, (_event, data) => ({
    organizationId: data.checklist.organization.id,
  })),
  checklistAssignmentDeleteOk: createAction(CHECKLIST_ASSIGNMENT_DELETE_OK_EVENT, eventPayload, (_event, data) => ({
    organizationId: data.checklist.organization.id,
  })),
  taskAssignedUnassigned: createAction(TASK_ASSIGNED_UNASSIGNED_EVENT, eventPayload, (_event, data) => ({
    organizationId: data.task.organization.id,
  })),
  inboxUpdated: createAction(
    INBOX_UPDATED_EVENT,
    (_event, organizationId, selectedItems, action, dueDate) => ({
      organizationId,
      selectedItems,
      action,
      dueDate,
    }),
    (_event, organizationId) => ({ organizationId }),
  ),
};
