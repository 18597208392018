import React from 'react';
import { ConditionalFilterChecklistLabelListOperand } from '@process-street/subgrade/dashboard';
import { GetLabelsByOrganizationQuery } from 'features/checklist-grid/query-builder';
import { ChecklistLabelMultiselect } from 'components/dashboard/components/checklist/ChecklistDashboardFilters/filters/ChecklistDashboardConditionalFilter/ChecklistLabelListOperandEditor/ChecklistLabelMultiselect';

export type ChecklistLabelListOperandEditorProps = {
  operand: ConditionalFilterChecklistLabelListOperand;
  onChange: (operand: ConditionalFilterChecklistLabelListOperand) => void;
};

export const ChecklistLabelListOperandEditor: React.FC<ChecklistLabelListOperandEditorProps> = ({
  operand,
  onChange,
}) => {
  const handleSelect = (labels: string[]) => {
    onChange({ ...operand, value: labels });
  };

  const labelsQuery = GetLabelsByOrganizationQuery.useQuery();
  if (!labelsQuery.data) {
    return null;
  }

  return (
    <ChecklistLabelMultiselect
      id="ChecklistLabelListOperandEditor"
      labels={labelsQuery.data}
      selectedLabels={operand.value}
      onDone={handleSelect}
    />
  );
};
