import React from 'react';
import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  VStack,
} from 'components/design/next';
import { AccessLevel, PermitConstants } from '@process-street/subgrade/permission';

const { accessLevelCopyMap } = PermitConstants;

export type PermissionsSelectorProps = {
  isDisabled: boolean;
  accessLevels: AccessLevel[];
  selectedAccessLevel?: AccessLevel;
  onChange?: (level: AccessLevel) => void;
  isLoading?: boolean;
};

export const PermissionsSelector: React.FC<React.PropsWithChildren<PermissionsSelectorProps>> = ({
  isDisabled,
  accessLevels,
  selectedAccessLevel,
  onChange,
  isLoading = false,
}) => {
  const handleChange = (level: AccessLevel) => () => {
    if (level === selectedAccessLevel) {
      return;
    }

    if (onChange) {
      onChange(level);
    }
  };

  if (!selectedAccessLevel) {
    return null;
  }

  return (
    <Menu autoSelect={false} isLazy={true}>
      <MenuButton
        as={Button}
        isLoading={isLoading}
        variant="ghost"
        rightIcon={<Icon icon="chevron-down" size="4" variant="far" />}
        isDisabled={isDisabled}
        title={accessLevelCopyMap[selectedAccessLevel].title}
        size="sm"
        color="gray.600"
        _focus={{ boxShadow: 'none' }}
        textAlign="right"
      >
        <Text as="span" variant="inherit" mr="auto">
          {accessLevelCopyMap[selectedAccessLevel].title}
        </Text>
      </MenuButton>

      <Portal>
        <MenuList color="gray.600" maxW="324px" zIndex="modal">
          {accessLevels.map(level => (
            <MenuItem onClick={handleChange(level)} key={level} data-item-selected={level === selectedAccessLevel}>
              <HStack spacing="2">
                <Icon size="4" variant="far" icon={accessLevelCopyMap[level].icon} />
                <VStack w="full" align="left" spacing="0">
                  <Text fontWeight="medium">{accessLevelCopyMap[level].title}</Text>
                  <Text fontSize="xs" color="gray.500" data-item-description={true} sx={{ whiteSpace: 'pre-line' }}>
                    {accessLevelCopyMap[level].description}
                  </Text>
                </VStack>
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};
