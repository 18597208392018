import { AuthConnection, Muid } from '@process-street/subgrade/core';
import { AxiosError, AxiosResponse } from 'axios';
import { FlashActions } from 'reducers/flash/flash.actions';
import { ReduxAppState } from 'reducers/types';
import { toFailure, toSuccess } from 'reducers/util/handlers';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { axiosService } from 'services/axios-service';

// Rules
export const AUTH_CONNECTION_GET_ALL_BY_ORGANIZATION_ID = 'frontStreet/authConnections/GET_ALL_BY_ORGANIZATION_ID';

export const AuthConnectionActions = {
  getAllByOrganizationId:
    (organizationId: Muid): ThunkAction<void, ReduxAppState, Record<string, unknown>, AnyAction> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>, _getState: () => ReduxAppState) => {
      axiosService
        .getAxios()
        .get(`/1/organizations/${organizationId}/auth-connections`)
        .then(
          (response: AxiosResponse) => {
            const authConnections = response.data as AuthConnection[];
            dispatch({
              meta: { organizationId },
              payload: authConnections,
              type: toSuccess(AUTH_CONNECTION_GET_ALL_BY_ORGANIZATION_ID),
            });
          },
          (_: AxiosError) => {
            dispatch({
              meta: { organizationId },
              type: toFailure(AUTH_CONNECTION_GET_ALL_BY_ORGANIZATION_ID),
            });

            const message = 'Oops, there was a problem retrieving auth connections. Please contact support.';
            dispatch(FlashActions.showDangerNotice(message));
          },
        );
    },
};
