import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from 'components/design/next';
import { AlertDialogProps } from '@chakra-ui/modal';

export interface ChangePromptTypeAlertDialogProps extends Omit<AlertDialogProps, 'leastDestructiveRef' | 'children'> {
  onContinue: () => void;
}

export const ChangePromptTypeAlertDialog: React.FC<React.PropsWithChildren<ChangePromptTypeAlertDialogProps>> = ({
  onContinue,
  ...props
}) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog {...props} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Change prompt type?
        </AlertDialogHeader>
        <AlertDialogBody>Your existing configuration will be lost and irrecoverable.</AlertDialogBody>
        <AlertDialogFooter>
          <Button onClick={() => props.onClose()} ref={cancelRef} variant="secondary">
            Cancel
          </Button>
          <Button variant="danger" ml={3} onClick={onContinue}>
            Continue
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
