import { Muid } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import {
  GetNewestTemplateRevisionsByTemplateIdQueryResponse,
  GetTemplateQuery,
  GetTemplateQueryResponse,
  makeGetNewestTemplateRevisionsByTemplateIdQueryObserver,
  makeGetTemplateQueryObserver,
  UpdateTemplateMutation,
  UpdateTemplateMutationResponse,
} from 'features/template/query-builder';
import { ToastServiceImpl } from 'services/toast-service.impl';
import {
  makeQueryMachine,
  QueryActor,
  QueryActorSelectors,
  RefetchOnMountOnly,
  SystemUpdateEvent,
} from 'utils/query-builder';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { ActorRefFrom, assign, createMachine, spawn, StateFrom } from 'xstate';
import { makeUIMachine, UIActorRef, SharedContext } from '../shared';
import { makeTopBarMachine, TopBarActorRef, TopBarParentEvent } from '../shared/form-editor-top-bar/top-bar-machine';
import { makeErrorLoggerAction } from 'app/utils/machines';

type Context = {
  templateQuery?: QueryActor<GetTemplateQueryResponse>;
  templateRevisionsQuery?: QueryActor<GetNewestTemplateRevisionsByTemplateIdQueryResponse>;
  uiActorRef: UIActorRef;
  topBarActorRef: TopBarActorRef;
};

type Event =
  | { type: 'SUBMIT'; form: Pick<Template, 'name'> }
  | TopBarParentEvent
  | SystemUpdateEvent<
      | { data: GetTemplateQueryResponse; id: 'template-query' }
      | { data: GetNewestTemplateRevisionsByTemplateIdQueryResponse; id: 'template-revisions-query' }
    >
  // mutations
  | { type: 'done.invoke.updateTemplate'; data: UpdateTemplateMutationResponse };

export const makeFormSettingsPageMachine = ({
  templateId,
  sharedContext,
}: {
  templateId: Muid;
  sharedContext: SharedContext;
}) => {
  const { queryClient } = sharedContext;
  const id = `form-settings-page-machine:${templateId}`;
  return createMachine(
    {
      id,
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      context: () =>
        ({
          uiActorRef: spawn(makeUIMachine({ sharedContext }), { name: 'ui-actor' }),
          topBarActorRef: spawn(makeTopBarMachine({ sharedContext }), { name: 'top-bar-actor' }),
        } as Context),
      tsTypes: {} as import('./settings-page-machine.typegen').Typegen0,
      initial: 'loading',
      states: {
        loading: {
          entry: ['assignTemplateQuery', 'assignTemplateRevisionsQuery'],
          on: { 'xstate.update': [{ cond: 'allDataReady', target: 'ready' }] },
        },
        ready: {
          on: {
            SUBMIT: 'submitting',
          },
        },
        submitting: {
          invoke: {
            id: 'updateTemplate',
            src: 'updateTemplateMutation',
            onDone: {
              target: 'ready',
              actions: ['setTemplateCache', 'openUpdatingSuccessToast'],
            },
            onError: {
              target: 'ready',
              actions: ['logError', 'openUpdatingErrorToast'],
            },
          },
        },
      },
    },
    {
      services: {
        updateTemplateMutation: (_ctx, evt) => {
          return makeMutation(queryClient, {
            mutationKey: UpdateTemplateMutation.key,
            mutationFn: () => UpdateTemplateMutation.mutationFn({ templateId, name: evt.form.name }),
          }).execute();
        },
      },
      actions: {
        assignTemplateQuery: assign({
          templateQuery: (_ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetTemplateQueryObserver({ templateId, queryClient, options: RefetchOnMountOnly }),
              }),
              { sync: true, name: 'template-query' },
            );
          },
        }),
        assignTemplateRevisionsQuery: assign({
          templateRevisionsQuery: (_ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: makeGetNewestTemplateRevisionsByTemplateIdQueryObserver({
                  templateId,
                  queryClient,
                  options: RefetchOnMountOnly,
                }),
              }),
              { sync: true, name: 'template-revisions-query' },
            );
          },
        }),
        openUpdatingSuccessToast: () => {
          ToastServiceImpl.openToast({
            title: `Updated form settings`,
            status: 'success',
          });
        },
        openUpdatingErrorToast: () => {
          ToastServiceImpl.openToast({
            title: `We're having problems saving the form`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
            status: 'error',
          });
        },
        setTemplateCache: (_ctx, _evt) => {
          queryClient.setQueryData<GetTemplateQueryResponse>(GetTemplateQuery.getKey({ templateId }), oldTemplate => ({
            ...oldTemplate!,
            name: _evt.data.name,
          }));
        },
        logError: makeErrorLoggerAction(id),
      },
      guards: {
        allDataReady: (ctx, _evt) => {
          return [ctx.templateQuery, ctx.templateRevisionsQuery].every(
            query => QueryActorSelectors.getQueryData(query) !== undefined,
          );
        },
      },
    },
  );
};

export type FormSettingsPageMachine = ReturnType<typeof makeFormSettingsPageMachine>;
export type FormSettingsPageActor = ActorRefFrom<FormSettingsPageMachine>;

function getTemplateRevisions(context: Context) {
  return QueryActorSelectors.getQueryData(context.templateRevisionsQuery);
}

function getDraftTemplateRevision(context: Context) {
  return getTemplateRevisions(context)?.[1];
}

function getPublishedTemplateRevision(context: Context) {
  return getTemplateRevisions(context)?.[0];
}

export const FormSettingsPageActorSelectors = {
  getTemplate(state: StateFrom<FormSettingsPageMachine>) {
    return QueryActorSelectors.getQueryData(state.context.templateQuery);
  },
  getTemplateRevision(state: StateFrom<FormSettingsPageMachine>) {
    return getDraftTemplateRevision(state.context) ?? getPublishedTemplateRevision(state.context);
  },
};
