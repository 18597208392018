import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { setSelection, insertNodes, getPointAfter } from '@udecode/slate';
import { ELEMENT_PS_UNORDERED_LIST_ITEM } from '../components/unordered-list';
import { createPagesPluginFactory } from '../pages-plate-types';
import { PSEditor } from '../utils/ps-editor';

export const ELEMENT_PS_SOFT_BREAK = 'break';

const ALLOWED_ELEMENTS = [ELEMENT_PARAGRAPH, ELEMENT_PS_UNORDERED_LIST_ITEM];
/**
 * Adds support for soft breaks inside paragraphs.
 */
export const createPSSoftBreak = createPagesPluginFactory({
  key: ELEMENT_PS_SOFT_BREAK,
  isElement: true,
  handlers: {
    onKeyDown: editor => e => {
      if (e.shiftKey) {
        switch (e.key) {
          case 'Enter': {
            e.preventDefault();
            const widgetElement = PSEditor.getWidgetElement(editor);

            if (widgetElement && ALLOWED_ELEMENTS.includes(widgetElement[0].type)) {
              insertNodes(editor, [
                { type: 'break', children: [{ text: '' }] },
                { type: 'p', children: [{ text: '' }] },
              ]);

              // the selection is now on the void BR text child
              // find this path to get the range _after_ that
              const location = editor.selection && getPointAfter(editor, editor.selection);
              if (location) {
                setSelection(editor, { anchor: location, focus: location });
              }
            }
          }
        }
      }
    },
  },
});
