import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { useQueryClient } from 'react-query';
import {
  GetDataSetPermitsQuery,
  GetDataSetPermitsResponse,
  useGetDataSetPermitsQuery,
  useUpsertDataSetPermitMutation,
} from 'pages/reports/data-sets/query-builder';
import { useDeleteDataSetPermitMutation } from 'pages/reports/data-sets/query-builder/delete-data-set-permit-mutation';
import { OrganizationMembershipRole, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { DataSetPermitWithOm } from '@process-street/subgrade/process';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { isStandardUserOrStandardGroupOm } from '@process-street/subgrade/util/membership-utils';
import { useGetCurrentUserInfoQuery } from 'app/features/user/query-builder';

interface UseDataSetPermitsParams {
  dataSetId: string;
  isEnabled?: boolean;
}

export function useDataSetPermits({ dataSetId, isEnabled = true }: UseDataSetPermitsParams) {
  const organizationId = useSelectedOrganization()?.id;

  const dataSetPermitsQuery = useGetDataSetPermitsQuery(
    { dataSetId },
    {
      enabled: isEnabled,
      select: data => (isEnabled ? data : []),
    },
  );

  const organizationMembershipsByIdQuery = useGetAllOrganizationMembershipsQuery(
    { organizationId },
    {
      enabled: Boolean(organizationId),
      select: oms => {
        return Object.fromEntries(oms.filter(isStandardUserOrStandardGroupOm).map(om => [om.id, om]));
      },
    },
  );

  const dataSetPermitsWithOms: DataSetPermitWithOm[] =
    dataSetPermitsQuery.data
      ?.flatMap(dataSetPermit => {
        const om = organizationMembershipsByIdQuery.data?.[dataSetPermit.organizationMembershipId];

        if (om) {
          return [
            {
              ...dataSetPermit,
              organizationMembership: om,
            },
          ];
        } else {
          return [];
        }
      })
      .filter(permitWithOm => isStandardUserOrStandardGroupOm(permitWithOm.organizationMembership)) ?? [];

  const queryClient = useQueryClient();
  const upsertDataSetPermitMutation = useUpsertDataSetPermitMutation({});
  const deleteDataSetPermitMutation = useDeleteDataSetPermitMutation({});

  const handleRemoveDataSetPermit = (permit: DataSetPermitWithOm) =>
    deleteDataSetPermitMutation
      .mutateAsync({
        dataSetId: permit.dataSetId,
        permitId: permit.id,
      })
      .then(_ => {
        queryClient.setQueryData<GetDataSetPermitsResponse | undefined>(
          GetDataSetPermitsQuery.getKey({ dataSetId }),
          current => {
            if (!current) return current;
            return current.filter(p => p.id !== permit.id);
          },
        );
      });

  const handleAddDataSetPermit = (user: OrganizationMembershipWithUser) =>
    upsertDataSetPermitMutation
      .mutateAsync({
        organizationMembershipId: user.id,
        dataSetId,
        dataSetRead: true,
        dataSetUpdate: true,
      })
      .then(response => {
        queryClient.setQueryData<GetDataSetPermitsResponse | undefined>(
          GetDataSetPermitsQuery.getKey({ dataSetId }),
          current => {
            if (!current) return current;
            const index = current.findIndex(permit => permit.id === response.id);
            if (index !== -1) {
              const newItems = current.slice();
              newItems.splice(index, 1, response);
              return newItems;
            }
            return [...current, response];
          },
        );
      });

  const currentUserInfo = useGetCurrentUserInfoQuery();
  const isAdmin = currentUserInfo.data?.organizationMembership.role === OrganizationMembershipRole.Admin;

  const isCurrentUserEditPermitted =
    (currentUserInfo &&
      dataSetPermitsWithOms.some(
        permit => permit.organizationMembership.user.id === currentUserInfo.data?.user.id && permit.dataSetUpdate,
      )) ||
    isAdmin;

  return {
    dataSetPermitsWithOms,
    handleAddDataSetPermit,
    handleRemoveDataSetPermit,
    isCurrentUserEditPermitted,
  };
}
