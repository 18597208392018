import { Selector } from 'reselect';
import { Plan } from '../../billing';
import { Muid, Option } from '../../core';
import { EntityMap, LightReduxState } from '../types';

const getById =
  (id: Muid): Selector<LightReduxState, Option<Plan>> =>
  (state: LightReduxState) =>
    state.entities.plans[id];

const getEntityMap: Selector<LightReduxState, EntityMap<Plan>> = (state: LightReduxState) => state.entities.plans;

export const BasePlanSelector = {
  getById,
  getEntityMap,
};
