import { AuditMetadata, Muid, Ref, User } from '../core';

export const IncomingWebhookChecklistConfigKeys = ['Name', 'DueDate', 'Shared', 'Assignees'] as const;
export type IncomingWebhookChecklistConfigKey = typeof IncomingWebhookChecklistConfigKeys[number];

export interface TemplateIncomingWebhookConfig {
  checklistProperties: Partial<Record<IncomingWebhookChecklistConfigKey, string>>;
  fields: Record<Muid, string>;
}

export interface DataSetIncomingWebhookConfig {
  dataSetColumns: Record<Muid, string>;
  dataSetKeyColumn?: Muid;
}

export type IncomingWebhookType = 'DataSet' | 'Workflow';

type IncomingWebhookBase = {
  id: Muid;
  name: string;
  audit: AuditMetadata;
  deletedDate?: number;
  deletedById?: Ref<User>;
  organizationId: Muid;
  status: 'Active' | 'Disabled' | 'Deleted';
  automationApp: UnsafeIncomingWebhookAppName;
};

export type TemplateIncomingWebhook = IncomingWebhookBase & {
  templateId: Muid;
  action: 'CreateChecklist';
  config: TemplateIncomingWebhookConfig;
  webhookType: 'Workflow';
};

export type DataSetIncomingWebhook = IncomingWebhookBase & {
  dataSetId: Muid;
  config: DataSetIncomingWebhookConfig;
  action: 'UpdateDataSet';
  webhookType: 'DataSet';
};

export type IncomingWebhook = TemplateIncomingWebhook | DataSetIncomingWebhook;

export type IncomingWebhookCreateForm = Pick<IncomingWebhook, 'id' | 'name' | 'config' | 'automationApp'>;

export type IncomingWebhookOptimistic = IncomingWebhookCreateForm & {
  status: 'Disabled';
  templateId?: Muid;
  dataSetId?: Muid;
  webhookType: IncomingWebhookType;
};

export type IncomingWebhookUpdateForm = Pick<IncomingWebhook, 'id' | 'webhookType'> &
  Partial<Pick<IncomingWebhook, 'name' | 'config' | 'status'>>;

type JSONValue = string | number | boolean | null | { [x: string]: JSONValue } | JSONValue[];

export interface IncomingWebhookSamplePayload {
  payload: JSONValue;
  paths: string[];
}

export interface FeaturedAppCsvRow {
  'App name': UnsafeIncomingWebhookAppName;
  'Event': string;
  'Docs url': string;
  'Icon url': string;
  /** JSON parsable */
  'Sample payload': string;
  'Enabled': string;

  /** Sheets only */
  'Icon Preview': unknown;
  'Notes': unknown;
}

export interface FeaturedIncomingWebhookEvent {
  appName: UnsafeIncomingWebhookAppName;
  event: string;
  docsUrl: string;
  iconUrl: string;
  samplePayload: JSONValue;
  enabled: boolean;
}

export type IncomingWebhookLog = {
  id: Muid;
  organizationId: Muid;
  webhookId: Muid;
  createdDate: number;
  error: string;
  payload: Record<string, unknown>;
  status: string;
};

// https://docs.google.com/spreadsheets/d/1MJTVIbTL_bVgm-8Ojnfce0uPwJvHT6D53kygNimPMW0/edit?usp=sharing
// App names sheet
export const UNSAFE_INCOMING_WEBHOOK_APP_NAMES = [
  'GitHub',
  'Hireflix',
  'Greenhouse',
  'Monday',
  'GoogleSheets',
  'Slack',
  'Salesforce',
  'Airtable',
  'Asana',
  'Typeform',
  'Trello',
  'Pipedrive',
  'Keap',
  'Streak',
  'Dubsado',
  'Close',
  'Insightly',
  'Capsule CRM',
  'Clio',
  'Circle',
  'Zendesk Sell',
  'Zendesk',
  'Google Forms',
  'Zoho Forms',
  'FIN compliance',
  'Okta',
  'UpGuard',
  'LastPass',
  'Detectify',
  'BambooHR',
  'Breezy HR',
  'Convenia',
  'Crelate',
  'Hibob',
  'Recruitee',
  'WizeHire',
  'Workable',
  'Zoho Recruit',
  'Quickbooks',
  'Paypal',
  'Wave',
  'Xero',
  'FreshBooks',
  'Zoho Invoice',
  'Stripe',
  'Guesty',
  'TIDY',
  'Moxo',
  'Investment Dominator',
  'Jira',
  'IPQualityScore',
  'Hubspot',
  'Ronin',
  'People HR',
  'Jotform',
  'Wufoo',
  'Mailchimp',
  'Twitter',
  'Process Street',

  // For our system
  'Webhook',
  'Default',
] as const;
export type UnsafeIncomingWebhookAppName = typeof UNSAFE_INCOMING_WEBHOOK_APP_NAMES[number];

export type IncomingWebhookPrefix = 'webhook';
export type WithIncomingWebhookPrefix<AppName extends UnsafeIncomingWebhookAppName> =
  `${IncomingWebhookPrefix}:${AppName}`;

export function withIncomingWebhookPrefix<AppName extends UnsafeIncomingWebhookAppName>(
  appName: AppName,
): WithIncomingWebhookPrefix<AppName> {
  return `webhook:${appName}`;
}

export const INCOMING_WEBHOOK_APP_NAMES = UNSAFE_INCOMING_WEBHOOK_APP_NAMES.map(withIncomingWebhookPrefix);
export type IncomingWebhookAppName = typeof INCOMING_WEBHOOK_APP_NAMES[number];

export const isTemplateIncomingWebhook = (
  incomingWebhook: IncomingWebhook,
): incomingWebhook is TemplateIncomingWebhook => incomingWebhook.webhookType === 'Workflow';

export const isDataSetIncomingWebhook = (incomingWebhook: IncomingWebhook): incomingWebhook is DataSetIncomingWebhook =>
  incomingWebhook.webhookType === 'DataSet';
