import { Muid } from '../core-model';
import { AuditMetadata } from '../user-model';
import { KeysEndingWith } from '../util';

export interface OrganizationNotificationConfig {
  readonly id: Muid;
  audit: AuditMetadata;
  organizationId: Muid;
  // Daily inbox email
  dailyInboxEmailAllowed: boolean;
  // Onboarding
  newProcessStreetAccountEmailAllowed: boolean;
  // Tasks
  taskAssignedEmailAllowed: boolean;
  taskDueEmailAllowed: boolean;
  stopTaskHandOffEmailAllowed: boolean;
  mentionEmailAllowed: boolean;
  // Workflow runs
  checklistAssignedEmailAllowed: boolean;
  checklistDueEmailAllowed: boolean;
  // Approvals
  approvalsReviewResultEmailAllowed: boolean;
  approvalsSubmissionEmailAllowed: boolean;
  // Emails allowed
  emailsAllowed: boolean;
}

export type OrganizationNotificationConfigKeys = KeysEndingWith<OrganizationNotificationConfig, 'Allowed'>;

export const GroupedOrganizationNotificationConfig: Array<
  [string, Array<[OrganizationNotificationConfigKeys, string]>]
> = [
  ['daily inbox email', [['dailyInboxEmailAllowed', 'A once-a-day-reminder of overdue and upcoming tasks.']]],
  ['onboarding', [['newProcessStreetAccountEmailAllowed', 'Invitation to create an account witin your organization.']]],
  [
    'tasks',
    [
      ['taskAssignedEmailAllowed', 'When a task is assigned.'],
      ['taskDueEmailAllowed', 'When a task is due.'],
      ['stopTaskHandOffEmailAllowed', 'When a hand-off occurs from a Stop Task.'],
      ['mentionEmailAllowed', 'When a team member is @mentioned'],
    ],
  ],
  [
    'workflow runs',
    [
      ['checklistAssignedEmailAllowed', 'When a workflow run is assigned.'],
      ['checklistDueEmailAllowed', 'When a workflow run is due.'],
    ],
  ],
  [
    'approvals',
    [
      ['approvalsSubmissionEmailAllowed', 'When an approval has been submitted.'],
      ['approvalsReviewResultEmailAllowed', 'When an approval is either approved or rejected.'],
    ],
  ],
  [
    'unsubscribe from all emails',
    [['emailsAllowed', "If checked, you won't receive any emails except billing and security-related ones."]],
  ],
];
