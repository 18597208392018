import angular from 'angular';
import { HttpStatus } from '@process-street/subgrade/util';
import { AuthConstants } from '@process-street/subgrade/auth/auth-constants';
import { UserStatus, UserType } from '@process-street/subgrade/core';
import { connectService } from 'reducers/util';
import { createSelector } from 'reselect';
import { SessionSelector } from 'reducers/session/session.selectors';
import { CookieService } from 'features/cookies/cookie-service';
import { deletePersistedTheme } from 'components/design/next/use-brand-theme';
import { EventName } from 'services/event-name';
import { AuthEvent } from 'services/auth-event/AuthEvent';
import { trace } from 'components/trace';
import { SignUpStateService } from 'services/sign-up-state-service';
import jwtDecode from 'jwt-decode';

angular
  .module('frontStreetApp.services')
  .service(
    'auth',
    function (
      $ngRedux,
      $rootScope,
      $q,
      Authentication,
      AuthenticationActions,
      DaoCacheService,
      DataService,
      OrganizationService,
      ReduxPersistorService,
      SessionService,
      ToastService,
      TempDataService,
      UserService,
    ) {
      const self = this;

      const mapStateToThis = createSelector([SessionSelector.getSession], session => ({
        session,
      }));

      connectService('auth', $ngRedux, mapStateToThis, {
        setTimeOffset: AuthenticationActions.setTimeOffset,
      })(self);

      const logger = trace({ name: 'auth' });

      self.setTimeOffset = () => self.actions.setTimeOffset();

      self.getTimeOffset = () => this.state.session.timeOffset;

      self.getOffsetDateNow = () => Date.now() - self.getTimeOffset();

      // This is the method that gets called when the user signs up without user/pass, e.g. Google, Microsoft, SSO, etc.
      self.signUp = function () {
        const coupon = SignUpStateService.getCoupon();
        const planId = SignUpStateService.getPlanId();
        const source = SignUpStateService.getSource();

        return Authentication.signUpByToken({
          coupon,
          planId,
          source,
        }).$promise.then(response => {
          SignUpStateService.removeCoupon();
          SignUpStateService.removePlanId();
          SignUpStateService.removeSource();
          return response;
        });
      };

      self.broadcastCreatedEventsBySignUpResult = function (result) {
        const { user, organizationCreated, organization } = result;

        $rootScope.$broadcast(AuthEvent.USER_CREATED_ON_AUTH, user);

        if (organizationCreated) {
          $rootScope.$broadcast(
            EventName.ORGANIZATION_CREATED,
            {
              organization,
              plan: {
                name: 'Free',
                cost: 0,
              },
            },
            true /*authFlow*/,
          );
        }
      };

      self.logout = function () {
        self.clearSession();
        $rootScope.$broadcast(AuthEvent.USER_LOGGED_OUT);
      };

      self.storeToken = function (accessToken) {
        // Saving token in the session
        SessionService.setToken(accessToken);
      };

      self.clearSession = function () {
        logger.info('clearing session');

        CookieService.removeTemporaryTokenCookie();
        CookieService.removeFilesAuthTokenCookie();

        DataService.clear();

        DaoCacheService.clearAll();
        TempDataService.clear();

        // This is needed to trigger certain cleanup
        SessionService.setSelectedOrganizationId(null);
        SessionService.clear();

        ReduxPersistorService.purge();

        deletePersistedTheme();
        OrganizationService.removeLastSelectedOrganizationId();
      };

      self.isLoggedIn = function () {
        const token = SessionService.getToken();
        const profile = SessionService.getProfile();
        const user = SessionService.getUser();
        const isStandardUser = user?.userType === UserType.Standard;
        const isLoggedIn = Boolean(token && profile && isStandardUser);

        return isLoggedIn;
      };

      self.needsToCompleteSignUp = function () {
        return SessionService.getUser().status === UserStatus.Created;
      };

      self.getIssuedAt = function (accessToken) {
        const deserializedToken = jwtDecode(accessToken);
        return deserializedToken.iat * 1000;
      };

      self.getExpirationTime = function (accessToken) {
        const deserializedToken = jwtDecode(accessToken);
        return deserializedToken.exp * 1000;
      };

      self.isExpired = function () {
        // Check whether the current time is past the access token's expiry time
        const accessToken = SessionService.getToken();
        if (accessToken) {
          const expirationTime = self.getExpirationTime(accessToken);
          return self.getOffsetDateNow() >= expirationTime;
        } else {
          return true;
        }
      };

      self.resetPassword = function (resetId, newPassword, confirmNewPassword) {
        return UserService.resetPassword(resetId, newPassword, confirmNewPassword).then(
          () => {
            ToastService.openToast({
              status: 'success',
              title: `Your password has been changed`,
            });
          },
          response => {
            let message;
            switch (response.status) {
              case HttpStatus.FORBIDDEN:
                message =
                  'Sorry, the password reset link has expired, ' +
                  '<a href data-ui-sref="requestPasswordReset">please re-request a reset</a>.';
                break;
              case HttpStatus.CONFLICT:
                message = self.convertPasswordValidationErrorCodesToMessage(response.data.errorCodes);
                break;
              default:
                message = 'An internal error occurred. Please try again later.';
                break;
            }

            return $q.reject(message);
          },
        );
      };

      self.convertPasswordValidationErrorCodesToMessage = function (errorCodes) {
        const notEmpty = m => !!m;
        const errorMessages = errorCodes
          .map(errorCode => AuthConstants.PasswordValidationErrorCodeToMessage[errorCode])
          .filter(notEmpty);

        return `Password must:<br>${errorMessages.join(',<br>')}.`;
      };
    },
  );
