import { FolderSelector } from 'reducers/folder/folder.selectors';
import { createCachedAction } from 'reducers/util';

export const FOLDER_GET_BY_ID = 'folder/GET_BY_ID';
export const FOLDER_GET_ALL_BY_ORGANIZATION_ID = 'folder/GET_ALL_BY_ORGANIZATION_ID';
export const FOLDER_UPDATE = 'folder/UPDATE';
export const FOLDER_DELETE_BY_ID = 'folder/DELETE_BY_ID';
export const FOLDER_SET = 'folder/SET';

export const FolderActions = FolderApi => {
  'ngInject';

  const getById = createCachedAction(
    FOLDER_GET_BY_ID,
    FolderApi.getById,
    (folderId, flushCache) => ({ folderId, flushCache }),
    (state, id) => FolderSelector.getById(id)(state) !== undefined,
    FolderSelector.getById,
  );

  const getAllByOrganizationId = createCachedAction(
    FOLDER_GET_ALL_BY_ORGANIZATION_ID,
    FolderApi.getAllByOrganizationId,
  );

  const update = createCachedAction(FOLDER_UPDATE, FolderApi.update);

  const deleteById = createCachedAction(FOLDER_DELETE_BY_ID, FolderApi.deleteById, folderId => ({ folderId }));

  return {
    deleteById,
    getById,
    getAllByOrganizationId,
    update,
  };
};
