import * as React from 'react';
import {
  ModalContent,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  Button,
  ModalCloseButton,
  Icon,
  IconButton,
  useDisclosure,
  Box,
  Text,
} from 'components/design/next';
import { RichTextEditor, TinyMCEEditor } from 'features/rich-text';
import { useCustomNotificationConfigContext } from '../../custom-notification-config-provider';
import { useCustomNotificationsStore } from '../../custom-notifications-store';
import { MergeTagsMenu, MergeTagsMenuButton } from 'features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { useTemplateRevision } from '../../template-revision-context';
import { createMergeTag } from 'hooks/use-insert-merge-tag-callback';

export interface CustomMessageProps {}

export const CustomMessage: React.FC<React.PropsWithChildren<CustomMessageProps>> = () => {
  const templateRevision = useTemplateRevision();
  const { config } = useCustomNotificationConfigContext();
  const [content, setContent] = React.useState(config.customMessage ?? '');
  const update = useCustomNotificationsStore(state => state.updateCustomMessage);

  const valueOnOpen = React.useRef(content);
  const disclosure = useDisclosure({
    onOpen: () => {
      valueOnOpen.current = content;
    },
  });

  const handleCancel = () => {
    setContent(valueOnOpen.current);
    disclosure.onClose();
  };

  const handleSave = () => {
    update(config, content ?? '');
    disclosure.onClose();
  };

  const editorRef = React.useRef<TinyMCEEditor | null>(null);

  const insertText = (key: string, fallback?: string) => {
    editorRef.current?.insertContent(createMergeTag(key, fallback));
  };

  return (
    <>
      <IconButton
        variant="ghost"
        colorScheme="gray"
        aria-label="edit custom message"
        icon={<Icon icon="edit" size="4" color={content === '' ? 'gray.500' : 'purple.500'} />}
        onClick={disclosure.onOpen}
      />
      <Modal {...disclosure}>
        <ModalContent>
          <ModalHeader>
            <Text variant="2" fontWeight="bold" color="gray.700">
              Add custom message
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box borderWidth="1px" borderStyle="solid" borderRadius="base" px="2" py="1" position="relative" pr="8">
              <RichTextEditor
                editorProps={{
                  value: content,
                  onEditorChange: newContent => setContent(newContent),
                  onInit: (_evt, editor) => {
                    editorRef.current = editor;
                  },
                }}
                init={{ placeholder: 'Type something...' }}
                editorWrapperProps={{
                  'aria-label': 'custom message editor',
                  'flex': '1',
                  'sx': {
                    '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before': {
                      fontStyle: 'normal',
                      pl: '1',
                    },
                  },
                }}
              />
              <MergeTagsMenu
                {...{
                  templateRevisionId: templateRevision.id,
                  onSelect: (key, _fieldId, fallback) => insertText(key, fallback),
                  mergeTagTarget: MergeTagsConstants.Target.GENERAL,
                  menuButton: <MergeTagsMenuButton position="absolute" top="px" right="px" size="sm" />,
                }}
              />
            </Box>
          </ModalBody>
          <ModalFooter display="flex">
            <ButtonGroup>
              <Button variant="ghost" colorScheme="gray" fontWeight="normal" onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={handleSave}>Save</Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
