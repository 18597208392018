import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { Muid } from '@process-street/subgrade/core';
import {
  ChecklistColumn,
  ChecklistGridUserDto,
  ColumnType,
  FormFieldDto,
  ProgressStatus,
} from '@process-street/subgrade/dashboard';
import { Checklist, Label, TaskStatus } from '@process-street/subgrade/process';
import { Filtering, Sorting } from 'components/dashboard/models/filters';

export interface ProgressValue {
  completedTasks: number;
  status: ProgressStatus;
  totalTasks: number;
}

export interface LabelValue {
  labelId?: Label['id'];
  checklistId: Checklist['id'];
  rowValues?: RowValues;
}

export interface NameValue {
  checklistId: Muid;
  checklistName?: string;
}

export interface TemplateValue {
  templateId: Muid;
  templateName: string;
}

export interface UserValue {
  id?: Muid;
  organizationMembershipId?: Muid;
  avatarUrl?: string;
  initials?: string;
}

export interface MembershipAvatarDto {
  id: Muid;
  organizationMembershipId: Muid;
  avatarUrl?: string;
  initials?: string;
}

export interface CommentsValue {
  checklistId: Muid;
  count: number;
}

export interface ChecklistColDef extends ColDef {
  type: ColumnType.Checklist;
  field: ChecklistColumn;
  headerName: string;
}

export interface FormFieldColDef extends ColDef {
  field: string; // "formField.<widgetGroupId>"
  type: ColumnType.FormField;
}

export interface OverdueTasksValue {
  checklistId: Muid;
  count: number;
}

export interface PermissionMapValue {
  checklistArchive: boolean;
  checklistDelete: boolean;
}

export type CellValue =
  | number
  | string
  | Muid
  | Date
  | NameValue
  | TemplateValue
  | ProgressValue
  | UserValue[]
  | FormFieldDto
  | ChecklistGridUserDto
  | OverdueTasksValue
  | CommentsValue
  | PermissionMapValue
  | LabelValue
  | undefined;

export interface RowValues {
  id: Muid;

  [index: string]: CellValue;
}

export interface GridContext {
  filters: Filtering;
  sorting?: Sorting;
}

export type UserValueMap = Record<string, UserValue>;

// server model
export interface TaskDataRow {
  checklistRevisionId: Muid;
  tasks: TaskDto[];
}

export interface TaskDto {
  id: Muid;
  hidden: boolean;
  status: TaskStatus;
  groupId: Muid;
}

export interface GetAvatarsRequest {
  organizationId: Muid;
  organizationMembershipIds: Muid[];
}

export interface GetAvatarsResponse {
  avatars: MembershipAvatarDto[];
}

export interface ColumnOrderMap {
  [index: string]: number;
}

export interface ColumnSizeMap {
  [index: string]: number;
}

export interface OverdueTaskDto {
  taskId: Muid;
  taskTemplateGroupId: Muid;
  taskName: string;
  dueDate: number;
  assignees?: UserValue[];
  masked: boolean;
}

export const isColDef = (col: ColDef | ColGroupDef): col is ColDef => {
  return (col as ColDef).colId !== undefined;
};

export const isChecklistColDef = (col: ColDef | ChecklistColDef): col is ChecklistColDef => {
  return (col as ChecklistColDef).type === ColumnType.Checklist;
};

export const isFormFieldColDef = (col: ColDef): col is FormFieldColDef => {
  return (col as FormFieldColDef).type === ColumnType.FormField;
};
