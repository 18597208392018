import { ChecklistRuleDefinitionOperator, RuleConstants } from '@process-street/subgrade/conditional-logic';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { trace } from 'components/trace';
import { ConditionalLogicUtils } from 'features/conditional-logic/utils/conditional-logic-utils';
import * as React from 'react';
import { match, P } from 'ts-pattern';
import { OptionDropdown } from '../option-dropdown';
import { TimeSource } from 'directives/rules/template/task-templates-selector/selector-helper';

export type ConditionDropdownProps = {
  widget: Widget | null;
  task: TaskTemplate | null;
  timeSource: TimeSource | null;
  initialValue?: ChecklistRuleDefinitionOperator | null;
  value?: ChecklistRuleDefinitionOperator | null;
  onChange: (value: ChecklistRuleDefinitionOperator | null) => void;
  disabled?: boolean;
};

export const ConditionDropdown: React.VFC<ConditionDropdownProps> = ({
  widget,
  task,
  timeSource,
  value: outerValue,
  initialValue,
  onChange,
  disabled,
}) => {
  const [value, setValue] = React.useState<ChecklistRuleDefinitionOperator | null>(initialValue ?? outerValue ?? null);
  const logger = trace({ name: 'ConditionDropdown' });

  const handleChange = ({ value }: { value: string }) => {
    onChange(value as ChecklistRuleDefinitionOperator);
    setValue(value as ChecklistRuleDefinitionOperator);
  };

  const operators = match({ widget, task, timeSource })
    .with({ widget: P.not(P.nullish) }, ({ widget }) =>
      ConditionalLogicUtils.getAvailableOperatorListByWidget(widget, logger),
    )
    .with({ task: P.not(P.nullish) }, () => RuleConstants.FieldTypeToOperatorListMap.task)
    .with({ timeSource: P.not(P.nullish) }, () => RuleConstants.FieldTypeToOperatorListMap.timeSource)
    .otherwise(() => []);

  React.useEffect(() => {
    if (outerValue !== undefined && outerValue !== value) {
      setValue(outerValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- acting on outerValue change
  }, [outerValue]);

  const getOperatorName = (operator: ChecklistRuleDefinitionOperator) => {
    if (widget) {
      return ConditionalLogicUtils.resolveOperatorName(operator, widget, logger);
    }

    return (
      RuleConstants.OperatorNameMap.default[operator as keyof typeof RuleConstants.OperatorNameMap.default] ?? operator
    );
  };

  return (
    <OptionDropdown
      displayValue={value ? getOperatorName(value) : undefined}
      value={value ?? undefined}
      disabled={disabled}
      onChange={handleChange}
      label="Condition"
      options={operators.map(operator => ({
        value: operator,
        label: getOperatorName(operator),
      }))}
    />
  );
};
