import * as React from 'react';
import { AvatarGroup, Avatar, AvatarGroupProps } from 'components/design/next';
import { InboxItem } from '@process-street/subgrade/inbox';

type AssigneesProps = { assignees: InboxItem['assignees'] } & Omit<AvatarGroupProps, 'children'>;
export const Assignees: React.FC<React.PropsWithChildren<AssigneesProps>> = ({ assignees, ...props }) => {
  return (
    <AvatarGroup aria-label="item assignees" size="md" max={2} spacing="-4" {...props}>
      {assignees.map(({ id, username, avatarFile }) => {
        if (avatarFile) {
          return <Avatar key={id} name={username} src={`https://aang.s3.amazonaws.com/${avatarFile?.id}-32.jpg`} />;
        }
        return <Avatar key={id} name={username} />;
      })}
    </AvatarGroup>
  );
};
