import * as React from 'react';
import { Button, Menu, MenuItem, MenuButton, MenuGroup, MenuList, Box, Text } from 'components/design/next';
import { Options } from 'react-select';
import { Icon } from 'components/design/next';

export type OptionDropdownProps = {
  options: Options<{ value: string; label: string }>;
  value?: string;
  displayValue?: string;
  disabled?: boolean;
  onChange: (value: { value: string; label: string }) => void;
  label?: string;
};

export const OptionDropdown: React.VFC<OptionDropdownProps> = ({
  onChange,
  options,
  displayValue,
  value,
  disabled,
  label,
}) => {
  return (
    <Menu autoSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            aria-label={label}
            bgColor="white"
            borderColor="gray.300"
            borderRadius="4px"
            borderStyle="solid"
            borderWidth="1px"
            h="10"
            px="3"
            py="2.5"
            w="full"
            isDisabled={disabled}
            _hover={{
              bgColor: 'white',
            }}
            _active={{
              bgColor: 'white',
              borderColor: 'brand.500',
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Text fontSize="sm" color="gray.600" fontWeight="normal">
                {displayValue ?? value}
              </Text>
              <Box transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'} transition="200ms ease transform">
                <Icon icon="chevron-down" size="4" color="gray.500" />
              </Box>
            </Box>
          </MenuButton>

          <MenuList fontSize="sm">
            <MenuGroup title={label}>
              {options.map(option => (
                <MenuItem
                  key={option.value}
                  onClick={() => onChange(option)}
                  color={value === option.value ? 'white' : 'inherit'}
                  backgroundColor={value === option.value ? 'brand.500' : 'white'}
                >
                  {option.label}
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default OptionDropdown;
