import { checklistByTemplateReducer } from 'reducers/checklist/checklist.by-template.reducer';
import { CHECKLIST_UPDATE_STARTED_EVENT } from 'reducers/scope-event/scope-event.actions';
import { TEMPLATE_UPDATE_STATUS } from 'reducers/template/template.actions';
import { combineReducers } from 'redux';
import { composeReducerObjects } from 'reducers/util';
import { INBOX_GET_ALL_BY_ORGANIZATION_ID } from 'reducers/inbox/inbox.actions';
import { CHECKLIST_PERMIT_PUT } from 'reducers/checklist-permit/checklist-permit.actions';
import { appendOnPutReducerObject } from 'reducers/entities/entities.utils';
import {
  CHECKLIST_ARRAY_ACTIONS,
  CHECKLIST_DELETE_BY_ID,
  CHECKLIST_GET_STATS,
  CHECKLIST_SEARCH,
  CHECKLIST_SINGLE_ACTION,
  CHECKLIST_UPDATE_DUE_DATE,
  CHECKLIST_UPDATE_SHAREABLE,
  normalizeChecklist,
} from 'reducers/checklist/checklist.actions';
import {
  CHECKLIST_ASSIGNMENT_ARRAY_ACTIONS,
  CHECKLIST_ASSIGNMENT_ASSIGN_USER,
} from 'reducers/checklist-assignment/checklist-assignment.actions';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { ChecklistStatus } from '@process-street/subgrade/process';
import { TemplateConstants } from 'services/template-constants';
import { CHECKLIST_REVISION_GET_ACTIVE_BY_CHECKLIST } from 'reducers/checklist-revision/checklist-revision.actions';
import { InboxItemUtils } from '@process-street/subgrade/inbox';

const checklistInboxEntitiesReducerObject = {
  [INBOX_GET_ALL_BY_ORGANIZATION_ID]: (state, { payload: items }) => {
    const inboxChecklists = InboxItemUtils.getChecklists(items).map(item => normalizeChecklist(item.checklist));
    if (inboxChecklists.length > 0) {
      return EntitiesReducerUtils.upsertAll(state, inboxChecklists);
    } else {
      return state;
    }
  },
};

const checklistApiReducerObject = {
  [CHECKLIST_ARRAY_ACTIONS]: (state, { payload: checklists }) =>
    EntitiesReducerUtils.upsertAll(state, checklists, normalizeChecklist),
  [CHECKLIST_SINGLE_ACTION]: (state, { payload: checklist }) =>
    EntitiesReducerUtils.upsert(state, checklist, normalizeChecklist),
  [CHECKLIST_DELETE_BY_ID]: (state, { meta: { checklistId } }) => EntitiesReducerUtils.deleteById(state, checklistId),
  [CHECKLIST_SEARCH]: (state, { payload: searchResults }) =>
    EntitiesReducerUtils.upsertAll(
      state,
      searchResults.map(({ checklist }) => checklist),
      normalizeChecklist,
    ),
  [CHECKLIST_UPDATE_SHAREABLE]: (state, { meta: { checklistId, shared } }) => {
    const checklist = state[checklistId];
    const updatedChecklist = { ...checklist, shared };
    return EntitiesReducerUtils.upsert(state, updatedChecklist);
  },
  [CHECKLIST_UPDATE_DUE_DATE]: (state, { payload: { updatedChecklist } }) =>
    EntitiesReducerUtils.upsert(state, updatedChecklist, normalizeChecklist),
};

const checklistTemplateChangesReducerObject = {
  [TEMPLATE_UPDATE_STATUS]: (state, { payload: { id: templateId, status } }) => {
    if (status === TemplateConstants.STATUS.ARCHIVED) {
      const updatedChecklists = Object.values(state).map(checklist => {
        if (checklist.template.id === templateId) {
          return { ...checklist, status: ChecklistStatus.Archived };
        } else {
          return checklist;
        }
      });
      return EntitiesReducerUtils.upsertAll({}, updatedChecklists);
    } else if (status === TemplateConstants.STATUS.DELETED) {
      const updatedChecklists = Object.values(state).map(checklist => {
        if (checklist.template.id === templateId) {
          return { ...checklist, status: ChecklistStatus.Deleted };
        } else {
          return checklist;
        }
      });
      return EntitiesReducerUtils.upsertAll({}, updatedChecklists);
    } else {
      return state;
    }
  },
};

const checklistEventEntitiesReducerObject = {
  [CHECKLIST_UPDATE_STARTED_EVENT]: (state, { payload: { updatedChecklist, originalChecklist } }) => {
    if (updatedChecklist.status === originalChecklist.status) {
      return state;
    } else {
      return EntitiesReducerUtils.upsert(state, updatedChecklist, normalizeChecklist);
    }
  },
};

const checklistRevisionEventEntitiesReducerObject = {
  [CHECKLIST_REVISION_GET_ACTIVE_BY_CHECKLIST]: (state, { payload: { checklist } }) =>
    EntitiesReducerUtils.upsert(state, checklist, normalizeChecklist),
};

export const checklistEntitiesReducer = composeReducerObjects(
  checklistApiReducerObject,
  checklistTemplateChangesReducerObject,
  checklistRevisionEventEntitiesReducerObject,

  checklistInboxEntitiesReducerObject,
  checklistEventEntitiesReducerObject,

  appendOnPutReducerObject(CHECKLIST_PERMIT_PUT, 'checklist', normalizeChecklist),
  appendOnPutReducerObject(CHECKLIST_ASSIGNMENT_ASSIGN_USER, 'checklist', normalizeChecklist),
  appendOnPutReducerObject(CHECKLIST_ASSIGNMENT_ARRAY_ACTIONS, 'checklist', normalizeChecklist),
);

export const checklistStatsReducer = composeReducerObjects({
  [CHECKLIST_GET_STATS]: (state, { payload: checklistStats }) =>
    EntitiesReducerUtils.upsertAllUsingIdSelector(state, checklistStats, s => s.checklistId),
});

export const checklistReducer = combineReducers({
  stats: checklistStatsReducer,
  byTemplate: checklistByTemplateReducer,
});
