import { TaskTemplateUpdateResponseStatus } from '@process-street/subgrade/process';
import angular from 'angular';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ArrayService } from 'services/array-service';
import { trace } from 'components/trace';
import { makeOptimisticTaskTemplate } from 'features/task-templates/query-builder';

const createPromiseQueueExecutor = () => {
  let promiseQueue = Promise.resolve();

  return async function executePromiseSequentially(promiseFunction) {
    // Wrap the incoming promise function in a new promise
    const wrappedPromise = () =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {
        try {
          // Wait for the previous promise in the queue to finish
          await promiseQueue;
          // Execute the provided promise function
          const result = await promiseFunction();
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });

    // Update the promise queue to execute the new promise
    promiseQueue = wrappedPromise();

    // Return the result of the wrapped promise
    return promiseQueue;
  };
};

const createTaskTemplateSequentially = createPromiseQueueExecutor();

angular
  .module('frontStreetApp.services')
  .service(
    'TaskTemplateListService',
    function (
      $q,
      focusById,
      OrderTreeService,
      SessionService,
      TaskTemplateService,
      TemplateTaskAssignmentService,
      util,
      FeatureFlagService,
      ToastService,
    ) {
      const logger = trace({ name: 'TaskTemplateListService' });

      const self = this;

      self.getTaskTemplateMap = function (taskTemplates) {
        return taskTemplates.reduce((taskTemplateMap, taskTemplate) => {
          taskTemplateMap[taskTemplate.group.id] = taskTemplate;
          return taskTemplateMap;
        }, {});
      };

      self.initializeAssigneesMap = function (taskTemplates, assigneesMap) {
        taskTemplates.forEach(taskTemplate => {
          assigneesMap[taskTemplate.id] = assigneesMap[taskTemplate.id] || [];
        });
      };

      self.initializeTemplateAssignments = function (templateRevisionId, assigneesMap) {
        return TemplateTaskAssignmentService.getAllByTemplateRevisionId(templateRevisionId).then(assignments => {
          angular.extend(assigneesMap, TemplateTaskAssignmentService.toAssigneesMap(assignments));
          return assignments;
        });
      };

      self.getInitialSelected = function (initialTaskTemplateGroupId, templateId, taskTemplates) {
        const taskTemplateMap = self.getTaskTemplateMap(taskTemplates);

        let initialTaskTemplate;
        if (initialTaskTemplateGroupId) {
          initialTaskTemplate = taskTemplateMap[initialTaskTemplateGroupId];
        }
        if (!initialTaskTemplate) {
          // Check if there's a saved initial task
          const key = `template:${templateId}:activeStep`;
          const taskTemplateGroupId = SessionService.getTemplateEditorProperty(key);
          initialTaskTemplate = taskTemplateMap[taskTemplateGroupId];

          if (!initialTaskTemplate) {
            SessionService.deleteTemplateEditorProperty(key);
            [initialTaskTemplate] = taskTemplates;
          }
        }

        return initialTaskTemplate;
      };

      self.getTaskTemplateClasses = function (taskTemplate, selected, approvalSubject, aiGeneratingWidgets) {
        const classes = taskTemplate && {
          selected,
          'heading': TaskTemplateService.isHeading(taskTemplate),
          'approval': TaskTemplateService.isApproval(taskTemplate),
          'has-error': taskTemplate._updateFailed || taskTemplate._deleteFailed,
          'approval-subject': approvalSubject,
          'ai-generating-widgets': aiGeneratingWidgets,
        };

        return classes;
      };

      self.setTaskTemplateInputFocus = function (singleTaskTemplate) {
        const options = {};
        if (singleTaskTemplate.name === 'Heading:') {
          options.selectionStart = function () {
            return 0;
          };
          options.selectionEnd = function (length) {
            return length - 1;
          };
        }

        focusById(`step-${singleTaskTemplate.group.id}`, options);
      };

      self.rememberSingleSelectedTaskTemplate = function (templateRevision, activeTaskTemplate) {
        SessionService.setTemplateEditorProperty(
          `template:${templateRevision.template.id}:activeStep`,
          activeTaskTemplate.group.id,
        );
      };

      self.getTaskTemplateMap = function (taskTemplates) {
        return taskTemplates.reduce((map, taskTemplate) => {
          map[taskTemplate.group.id] = taskTemplate;
          return map;
        }, {});
      };

      // Creating

      self.generateTaskTemplate = function (name, templateRevisionId, taskType, atIndex, taskTemplates) {
        return makeOptimisticTaskTemplate({ name, templateRevisionId, taskType, atIndex, taskTemplates });
      };

      self.createTaskTemplate = function (newTaskTemplate, taskTemplates, taskTemplateMap, assigneesMap) {
        taskTemplates.push(newTaskTemplate);
        TaskTemplateService.sortTaskTemplates(taskTemplates);

        taskTemplateMap[newTaskTemplate.group.id] = newTaskTemplate;
        assigneesMap[newTaskTemplate.id] = [];

        newTaskTemplate._creating = true;

        return createTaskTemplateSequentially(() => {
          return TaskTemplateService.create(newTaskTemplate).then(
            createdTaskTemplate => {
              // Do not put removing the flag in finally, otherwise delete method in onCreated won't work
              delete newTaskTemplate._creating;

              // We must extend, otherwise we'll lost the _onCreated function
              angular.extend(newTaskTemplate, createdTaskTemplate);

              if (newTaskTemplate._onCreated) {
                newTaskTemplate._onCreated();
              }

              return newTaskTemplate;
            },
            response => {
              // Do not put removing the flag in finally, otherwise delete method in onCreated won't work
              delete newTaskTemplate._creating;

              delete taskTemplateMap[newTaskTemplate.group.id];
              ArrayService.desplice(taskTemplates, newTaskTemplate);
              throw new Error(response);
            },
          );
        });
      };

      // Updating

      self.updateTaskTemplate = function (taskTemplate) {
        if (taskTemplate._creating) {
          const deferred = $q.defer();
          taskTemplate._onCreated = function () {
            util.pipe(deferred, TaskTemplateService.update(taskTemplate));
          };
          return deferred.promise;
        } else {
          return TaskTemplateService.update(taskTemplate);
        }
      };

      // Moving

      self.canMoveTaskTemplateUp = function (taskTemplate, taskTemplates) {
        return (
          taskTemplate &&
          taskTemplates.some(t => !t._deleting && OrderTreeService.compare(t.orderTree, taskTemplate.orderTree) < 0)
        );
      };

      self.canMoveTaskTemplateDown = function (taskTemplate, taskTemplates) {
        return (
          taskTemplate &&
          taskTemplates.some(t => !t._deleting && OrderTreeService.compare(t.orderTree, taskTemplate.orderTree) > 0)
        );
      };

      self.checkIfAllTaskTemplatesCreated = function (taskTemplates) {
        return !taskTemplates.some(taskTemplate => taskTemplate._creating);
      };

      self.onOrderTreesBulkUpdateSuccess = function (bulkUpdateResponses, taskTemplatesMoved) {
        taskTemplatesMoved.forEach(taskTemplate => {
          taskTemplate._updateFailed = false;
        });

        const taskTemplateMap = self.getTaskTemplateMap(taskTemplatesMoved);

        let atLeastOneFailed = false;
        bulkUpdateResponses.forEach(res => {
          if (res.response !== TaskTemplateUpdateResponseStatus.Ok) {
            atLeastOneFailed = true;
            const taskTemplate = taskTemplateMap[res.taskTemplate.group.id];
            taskTemplate._updateFailed = true;
          }
        });

        if (atLeastOneFailed) {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems updating the tasks`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        }
      };

      self.onOrderTreesBulkUpdateFailure = function (__response, movedTaskTemplates, __orderTreeMap, taskTemplates) {
        TaskTemplateService.sortTaskTemplates(taskTemplates);

        movedTaskTemplates.forEach(taskTemplate => {
          taskTemplate._updateFailed = true;
        });
      };

      self.moveAllTaskTemplatesUp = function (taskTemplatesToMove, taskTemplates, orderTreesBulkUpdater) {
        const indexes = taskTemplatesToMove
          .map(taskTemplate => taskTemplates.indexOf(taskTemplate))
          .sort(ArrayService.numberComparer);

        const topIndex = indexes.reduce((a, b) => Math.min(a, b));

        if (topIndex !== 0) {
          const newIndexes = indexes.map(index => index - 1);
          const request = orderTreesBulkUpdater.moveAt(indexes, newIndexes, taskTemplates);
          TaskTemplateService.sortTaskTemplates(taskTemplates);
          return request;
        } else {
          logger.info('task already at top');
          return $q.defer().promise;
        }
      };

      self.moveAllTaskTemplatesDown = function (taskTemplatesToMove, taskTemplates, orderTreesBulkUpdater) {
        const indexes = taskTemplatesToMove
          .map(taskTemplate => taskTemplates.indexOf(taskTemplate))
          .sort(ArrayService.numberComparer);

        const bottomIndex = indexes.reduce((a, b) => Math.max(a, b));

        if (bottomIndex !== taskTemplates.length - 1) {
          const newIndexes = indexes.map(index => index + 1);
          const request = orderTreesBulkUpdater.moveAt(indexes.reverse(), newIndexes.reverse(), taskTemplates);

          TaskTemplateService.sortTaskTemplates(taskTemplates);
          return request;
        } else {
          logger.info('task already at bottom');
          return $q.defer().promise;
        }
      };

      // Order Trees

      /**
       * Gets moved TaskTemplate with updated orderTree
       *
       * @note Used to generate new orderTree, but was decided just to re-order task templates, to avoid
       *       having new orderTrees
       *
       * @param taskTemplates
       * @param fromIndex
       * @param toIndex
       * @returns {*}
       */
      self.getMovedTaskWithNewOrderTree = function (taskTemplates, fromIndex, toIndex) {
        // This sorting is required to support bulk moving
        const sortedOrderTrees = util.toOrderTrees(taskTemplates).sort(OrderTreeService.compare);

        const boundedIndex = Math.max(0, Math.min(fromIndex, taskTemplates.length));
        const boundedNewIndex = Math.max(0, Math.min(toIndex, taskTemplates.length));
        const referenceTree = sortedOrderTrees[boundedNewIndex] ? sortedOrderTrees[boundedNewIndex] : null;

        const taskTemplate = taskTemplates[boundedIndex];

        const orderTrees = util.toOrderTrees(taskTemplates);
        if (boundedNewIndex > boundedIndex) {
          // Moving down
          [taskTemplate.orderTree] = OrderTreeService.after(orderTrees, referenceTree);
        } else {
          // Moving up
          [taskTemplate.orderTree] = OrderTreeService.before(orderTrees, referenceTree);
        }

        return taskTemplate;
      };

      self.duplicateTaskTemplate = function (
        sourceTaskTemplate,
        placeholderTaskTemplate,
        taskTemplates,
        taskTemplateMap,
        assigneesMap,
      ) {
        taskTemplates.push(placeholderTaskTemplate);
        TaskTemplateService.sortTaskTemplates(taskTemplates);

        taskTemplateMap[placeholderTaskTemplate.group.id] = placeholderTaskTemplate;

        assigneesMap[placeholderTaskTemplate.id] = [];

        placeholderTaskTemplate._duplicating = true;

        return TaskTemplateService.duplicate(sourceTaskTemplate, placeholderTaskTemplate).then(
          duplicatedTask => duplicatedTask,
          response => {
            delete taskTemplateMap[placeholderTaskTemplate.groupId];
            ArrayService.desplice(taskTemplates, placeholderTaskTemplate);

            return $q.reject(response);
          },
        );
      };

      self.updateAllDueOffset = function (dueOffset, taskTemplatesToUpdate, taskTemplateMap) {
        const taskTemplateGroupIdOriginalDueOffsetMap = taskTemplatesToUpdate.reduce((map, taskTemplate) => {
          map[taskTemplate.group.id] = taskTemplate.dueOffset;
          return map;
        }, {});

        // Filtering out task templates that are headings
        const taskTemplates = taskTemplatesToUpdate.filter(tt => !TaskTemplateService.isHeading(tt));

        taskTemplates.forEach(taskTemplate => {
          taskTemplate.dueOffset = dueOffset;
          delete taskTemplate._updateFailed;
        });

        TaskTemplateService.updateAllDueOffset(taskTemplates, dueOffset).then(
          bulkUpdateResponses => {
            let atLeastOneFailed = false;
            bulkUpdateResponses.forEach(res => {
              if (res.response !== TaskTemplateUpdateResponseStatus.Ok) {
                atLeastOneFailed = true;
                const taskTemplate = taskTemplateMap[res.taskTemplate.group.id];
                taskTemplate._updateFailed = true;
                // roll back
                taskTemplate.dueOffset = taskTemplateGroupIdOriginalDueOffsetMap[taskTemplate.group.id];
              }
            });

            if (atLeastOneFailed) {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems updating the tasks`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            } else {
              const message = dueOffset ? 'Due after updated' : 'Due after removed';

              ToastService.openToast({
                status: 'success',
                title: message,
              });
            }
          },
          () => {
            taskTemplatesToUpdate.forEach(taskTemplate => {
              taskTemplate._updateFailed = true;
              taskTemplate.dueOffset = taskTemplateGroupIdOriginalDueOffsetMap[taskTemplate.group.id];
            });

            ToastService.openToast({
              status: 'error',
              title: `We're having problems updating the due dates`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          },
        );
      };

      self.updateAllStop = function (stop, taskTemplatesToUpdate, taskTemplateMap) {
        const taskTemplateGroupIdOriginalStopMap = taskTemplatesToUpdate.reduce((map, taskTemplate) => {
          map[taskTemplate.group.id] = taskTemplate.stop;
          return map;
        }, {});

        // Filtering out task templates that are headings
        const taskTemplates = taskTemplatesToUpdate.filter(tt => !TaskTemplateService.isHeading(tt));

        taskTemplates.forEach(taskTemplate => {
          taskTemplate.stop = stop;
          delete taskTemplate._updateFailed;
        });

        return TaskTemplateService.updateAllStop(taskTemplates, stop).then(
          bulkUpdateResponses => {
            let atLeastOneFailed = false;
            bulkUpdateResponses.forEach(res => {
              if (res.response !== TaskTemplateUpdateResponseStatus.Ok) {
                atLeastOneFailed = true;
                const taskTemplate = taskTemplateMap[res.taskTemplate.group.id];
                taskTemplate._updateFailed = true;
                // roll back
                taskTemplate.stop = taskTemplateGroupIdOriginalStopMap[taskTemplate.group.id];
              }
            });

            if (atLeastOneFailed) {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems updating the tasks`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            } else if (bulkUpdateResponses.length !== 0) {
              const message = stop ? 'Stop added to the task(s)' : 'Stop removed from task(s)';
              ToastService.openToast({
                status: 'success',
                title: message,
              });
            }
          },
          () => {
            taskTemplatesToUpdate.forEach(taskTemplate => {
              taskTemplate._updateFailed = true;
              taskTemplate.stop = taskTemplateGroupIdOriginalStopMap[taskTemplate.group.id];
            });

            ToastService.openToast({
              status: 'error',
              title: `We're having problems updating the tasks`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          },
        );
      };

      // Multi-select

      self.isTaskTemplateSelected = function (taskTemplate, selectedTaskTemplateMap) {
        return !angular.isUndefined(selectedTaskTemplateMap[taskTemplate.group.id]);
      };

      self.addTaskTemplateToSelected = function (taskTemplate, selectedTaskTemplates, selectedTaskTemplateMap) {
        if (self.isTaskTemplateSelected(taskTemplate, selectedTaskTemplateMap)) {
          return; // Ignore, if task template already selected
        }
        selectedTaskTemplates.push(taskTemplate);
        selectedTaskTemplateMap[taskTemplate.group.id] = taskTemplate;
      };

      self.removeTaskTemplateFromSelected = function (taskTemplate, selectedTaskTemplates, selectedTaskTemplateMap) {
        delete selectedTaskTemplateMap[taskTemplate.group.id];
        ArrayService.desplice(selectedTaskTemplates, taskTemplate);
      };
    },
  );
