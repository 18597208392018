import { EmailFormFieldWidget, TEXT_MAX_LENGTH } from '@process-street/subgrade/process';
import * as yup from 'yup';
import { SettingsSchemaValidators } from 'components/widgets/form-field/settings/common/settings-schema-validators';
import { match } from 'ts-pattern';
import { Option } from 'space-monad';

export type EmailSettingsSchema = EmailFormFieldWidget['constraints'] &
  EmailFormFieldWidget['config'] & {
    hasVariables: boolean;
  };

type Restriction = EmailFormFieldWidget['constraints']['restriction'];

const DOMAIN_PATTERN = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
export const emailSettingsSchema = yup.object<EmailSettingsSchema>({
  restriction: yup.string().oneOf(['Allow', 'Block'] as Restriction[]),
  domains: yup.array().of(
    yup
      .string()
      .matches(DOMAIN_PATTERN, domain => `Invalid domain: ${domain.value}`)
      .required(),
  ),
  placeholder: SettingsSchemaValidators.placeholder(60),
  // this is a transient/hidden value used to drive a dynamic validation
  // inspiration: https://stackoverflow.com/questions/65613573/yup-how-to-validate-field-only-when-it-exists
  hasVariables: yup.boolean(),
  defaultValue: yup
    .string()
    .min(0)
    .max(TEXT_MAX_LENGTH)
    .when('hasVariables', {
      is: true,
      then: (schema: yup.StringSchema) => schema,
      otherwise: (schema: yup.StringSchema) => {
        return schema
          .email()
          .when(
            ['restriction', 'domains'],
            (restriction: Restriction, domains: string[] | undefined, schema: yup.StringSchema) => {
              return match(restriction)
                .with('Allow', () =>
                  schema.test(
                    'Allowed domain',
                    context => `${(context.value as string)?.split('@')?.[1] ?? 'This'} is not a valid domain`,
                    email =>
                      Option(email)
                        .map(email => email === '' || domains?.includes(email.split('@')[1]))
                        .getOrElse(true),
                  ),
                )
                .with('Block', () =>
                  schema.test(
                    'Blocked domain',
                    context => `${(context.value as string)?.split('@')?.[1] ?? 'This'} is not a valid domain`,
                    email =>
                      Option(email)
                        .map(email => email === '' || !domains?.includes(email.split('@')[1]))
                        .getOrElse(true),
                  ),
                )
                .otherwise(() => schema);
            },
          );
      },
    }),
});
