import { GroupMembership, Muid } from '@process-street/subgrade/core';
import { QueryKey, useQuery as useRQQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type Params = { userId?: Muid; groupId?: Muid };
type Result = GroupMembership[];

export namespace GetGroupMembershipsQuery {
  export const key = ['group-memberships'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params): Promise<Result> => {
    return axiosService
      .getAxios()
      .get<Result>(`/1/group-memberships`, { params })
      .then(res => res.data);
  };

  export const useQuery = <Select = Result>(
    params: Params = {},
    options: UseQueryOptions<Result, Error, Select> = {},
  ) => {
    return useRQQuery<Result, Error, Select>(getKey(params), () => queryFn(params), options);
  };
}

// exposed to not modify existing imports
export const useGroupMemberships = GetGroupMembershipsQuery.useQuery;
