import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { WidgetMenu } from './widget-menu';
import { WidgetMenuModals } from './widget-menu-modals';

export const WidgetMenuModule = angular
  .module('widgetMenu', [])
  .component(
    'psWidgetMenu',
    reactToAngular(WidgetMenu, [
      'canMoveDown',
      'canMoveUp',
      'onCopy',
      'onDelete',
      'onMoveDown',
      'onMoveUp',
      'widget',
      'onOpen',
      'onClose',
    ]),
  )
  .component(
    'psWidgetMenuModals',
    reactToAngular(WidgetMenuModals, ['onDelete', 'onMove', 'onUpdate', 'taskTemplateId', 'templateRevisionId']),
  ).name;
