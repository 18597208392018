import angular from 'angular';
import { BlvdSearch } from 'components/design/BlvdSearch';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { reactToAngular } from 'components/react-root';
import { Icon, Switch } from './next';

export const DesignModule = angular
  .module('designModule', [])
  .component(
    'psBlvdSearch',
    reactToAngular(BlvdSearch, [
      'value',
      'placeholder',
      'inputColour',
      'onChange',
      'autoFocus',
      'debounceDelay',
      'enabled',
    ]),
  )
  .component('psSwitch', reactToAngular(Switch, ['isChecked', 'isDisabled', 'id', 'onChange', 'size']))
  .component(
    'psBlvdSelect',
    reactToAngular(BlvdSelect, [
      'options',
      'onChange',
      'isClearable',
      'isSearchable',
      'placeholder',
      'searchPlaceholder',
      'maxOptionsBeforeVirtualization',
      'isMulti',
      'menuControls',
      'fixedSize',
      'defaultValue',
      'components',
    ]),
  )
  /** This is just to allow Angular templates to use custom FA icons */
  .component('psIcon', reactToAngular(Icon, ['variant', 'size', 'color', 'icon', 'bgColor', 'p', 'borderRadius'])).name;
