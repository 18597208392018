import * as React from 'react';
import { useChecklistScreenState } from '../../../ChecklistDashboardScreen/checklist-screen-state';
import { ColumnsFilterState, FilteredItem } from './use-columns-filter-state';
import { Box } from 'components/design/next';
import { match } from 'ts-pattern';
import { ChecklistColumn } from '@process-street/subgrade/dashboard';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { components, GroupBase, SelectComponentsConfig } from 'react-select';
import { CustomDropdownIndicator } from './custom-dropdown-indicator';
import {
  CustomGroup,
  WORKFLOW_LABEL,
  TASK_LABEL,
  FORM_FIELD_LABEL,
  EMPTY_FORM_FIELD,
  EMPTY_TASK,
} from './custom-group';
import { useSortedItems } from './use-sorted-items';
import { ChecklistDashboardColumnsFilterProvider } from './context';

export interface ChecklistDashboardColumnsFilterProps extends ColumnsFilterState {
  multiple: boolean;
  onClose?: () => void;
}

export const ChecklistDashboardColumnsFilter: React.FunctionComponent<
  React.PropsWithChildren<ChecklistDashboardColumnsFilterProps>
> = ({
  onSelectionChange: handleSelectionChange,
  formFieldItems,
  predefinedItems,
  taskItems,
  selectedFields,
  multiple,
  columns,
  savedSelectedColumns: selectedColumns,
  onClose,
}) => {
  const { viewModifiers } = useChecklistScreenState();

  const columnsChanged = viewModifiers?.includes('columns') ?? false;

  const selectedCount = selectedColumns.filter(column => column !== ChecklistColumn.Selection).length;
  const label = match(selectedCount)
    .with(0, columns.length, () => 'All Columns')
    .with(1, () => '1 Column')
    .when(
      c => c > 99,
      () => '99+ Columns',
    )
    .otherwise(() => `${selectedCount} Columns`);

  const selectedSet = React.useMemo(() => new Set(selectedFields), [selectedFields]);

  const getSortedItems = React.useCallback(
    <T extends { value: string }>(items: T[]) => {
      return [...items].sort((a, b) => {
        const aSelected = selectedSet.has(a.value);
        const bSelected = selectedSet.has(b.value);
        return match({ aSelected, bSelected })
          .with({ aSelected: true, bSelected: false }, () => -1)
          .with({ aSelected: false, bSelected: true }, () => 1)
          .otherwise(() => 0);
      });
    },
    [selectedSet],
  );

  const [sortedPredefinedItems, setSortedPredefinedItems] = useSortedItems({ getSortedItems, items: predefinedItems });
  const [sortedTaskItems, setSortedTaskItems] = useSortedItems({ getSortedItems, items: taskItems });
  const [sortedFormFieldItems, setSortedFormFieldItems] = useSortedItems({ getSortedItems, items: formFieldItems });

  const handleMenuClose = React.useCallback(() => {
    setSortedFormFieldItems(getSortedItems(formFieldItems));
    setSortedPredefinedItems(getSortedItems(predefinedItems));
    setSortedTaskItems(getSortedItems(taskItems));
    onClose?.();
  }, [
    formFieldItems,
    getSortedItems,
    predefinedItems,
    setSortedFormFieldItems,
    setSortedPredefinedItems,
    setSortedTaskItems,
    taskItems,
    onClose,
  ]);

  const options = React.useMemo(() => {
    return [
      { label: WORKFLOW_LABEL, options: sortedPredefinedItems },
      { label: TASK_LABEL, options: sortedTaskItems.length === 0 ? [EMPTY_TASK] : sortedTaskItems },
      {
        label: FORM_FIELD_LABEL,
        options: sortedFormFieldItems.length === 0 ? [EMPTY_FORM_FIELD] : sortedFormFieldItems,
      },
    ];
  }, [sortedFormFieldItems, sortedPredefinedItems, sortedTaskItems]);

  const value = React.useMemo(() => {
    const columnsMap = new Map(
      columns.map(column => [column.field, { label: column.name, value: column.field, meta: column.columnType }]),
    );
    return selectedFields.map(id => columnsMap.get(id)!);
  }, [columns, selectedFields]);

  return (
    <ChecklistDashboardColumnsFilterProvider label={label} multiple={multiple} columnsChanged={columnsChanged}>
      <Box
        display="inline-block"
        sx={{
          '.blvd-select .blvd-select__control': {
            'border': '0',
            '&--is-focused': {
              boxShadow: 'unset',
            },
          },
          '.blvd-select .blvd-select__menu': {
            width: { md: '80' },
            zIndex: 'dropdown',
          },
        }}
      >
        <BlvdSelect
          isSearchable
          placeholder="All columns"
          options={options}
          isMulti
          menuControls
          onMenuClose={handleMenuClose}
          components={COMPONENTS}
          onChange={value => {
            if (value === null) {
              handleSelectionChange([]);
            }
            if (BlvdSelectHelpers.isOptionsType<FilteredItem>(value)) {
              handleSelectionChange(value.map(option => option.value));
            }
          }}
          value={value}
        />
      </Box>
    </ChecklistDashboardColumnsFilterProvider>
  );
};

const COMPONENTS: Partial<SelectComponentsConfig<FilteredItem, true, GroupBase<FilteredItem>>> = {
  ValueContainer: props => (
    // hide it visually, but the internal behavior via props is still needed
    <Box w="0" overflow="hidden" h="0">
      <components.ValueContainer {...props} />
    </Box>
  ),
  DropdownIndicator: CustomDropdownIndicator,
  Group: CustomGroup,
};
