import * as React from 'react';
import { useNavigate } from '@process-street/adapters/navigation';
import { DataSet } from '@process-street/subgrade/process';
import { useSelectedSavedViewIdStore } from 'app/pages/reports/data-sets/components/data-set-page/selected-saved-view-id-store';
import qs from 'qs';

export function useNavigateToDataSet() {
  const navigate = useNavigate();
  const dataSetPageStore = useSelectedSavedViewIdStore();

  return React.useCallback(
    (dataSet: DataSet, onNavigate?: () => void) => {
      dataSetPageStore.setDataSetId(dataSet.id);
      dataSetPageStore.setSavedViewId(undefined);
      navigate({
        pathname: 'dataSets.dataSet',
        search: qs.stringify({
          dataSetId: dataSet.id,
        }),
      });

      onNavigate?.();
    },
    [navigate, dataSetPageStore],
  );
}
