import * as React from 'react';
import { match } from 'ts-pattern';
import { FolderView, ScheduleView, TagView } from './views';
import { useLibraryContext } from 'pages/library/context';
import { useGetAllCoverIconsQuery } from 'features/cover-icon/query-builder/all-cover-icons';
import {
  useGetAllConsolidatedFolderPermissionsQuery,
  useGetAllConsolidatedTemplatePermissionsQuery,
} from 'features/permissions/query-builder';
import { useGetAllTemplateRevisions } from 'features/template-revisions/query-builder';

export const TemplateLibrary: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { view } = useLibraryContext();

  //Prefetch all the data for the library view
  useGetAllTemplateRevisions();
  useGetAllCoverIconsQuery();
  useGetAllConsolidatedTemplatePermissionsQuery();
  useGetAllConsolidatedFolderPermissionsQuery();

  return match(view)
    .with('tag', () => <TagView />)
    .with('folder', () => <FolderView />)
    .with('scheduled', () => <ScheduleView />)
    .otherwise(() => null);
};
