import * as React from 'react';
import {
  Button,
  ButtonGroup,
  ButtonProps,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Tooltip,
} from 'components/design/next';
import { ColumnOptionType } from '../columns-visibility-filter';
import { DataSetColumnDef, DataSetColumnState, DataSetColumnStateModel } from '@process-street/subgrade/process';
import { useDataSetFilters } from '../../store';
import deepEqual from 'deep-equal';
import { useSelectedDataSetContext, useSelectedSavedViewContext } from '../../context';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { CreateSavedViewButton } from '../create-saved-view-button';
import { DataSetSortButton } from '../sort-button/sort-button';
import { Icon } from 'components/design/next';
import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import { Spacer } from '@chakra-ui/react';
import { DataSetColumnsFilterV1 } from 'pages/reports/data-sets/components/columns-filter/columns-filter-v1';
import { DataSetColumnsVisibilityFilterV1 } from 'pages/reports/data-sets/components/columns-visibility-filter/columns-visibility-filter-v1';

export type DataSetFiltersBarV1Props = {
  allColumns: DataSetColumnDef[];
  onUpdateSavedView: () => void;
  isDisabled: boolean;
};

export const DataSetFiltersBarV1: React.FC<React.PropsWithChildren<DataSetFiltersBarV1Props>> = ({
  allColumns,
  onUpdateSavedView,
  isDisabled,
}) => {
  const dataSetFilters = useDataSetFilters();
  const [selectedSavedView] = useSelectedSavedViewContext();
  const [selectedDataSet] = useSelectedDataSetContext();

  const selectedColumns = dataSetFilters.columns.filter(column => !column.hide);
  const hasUnsavedChanges = selectedSavedView
    ? !deepEqual(
        selectedSavedView.columns,
        // We must remove the keys with undefined values to correctly compare the two values
        dataSetFilters.columns.map(column => _omitBy(column, _isUndefined)),
      ) || !deepEqual(DataSetColumnStateModel.getFilters(selectedSavedView.columns), dataSetFilters.filters)
    : false;

  const selectedOptions: ColumnOptionType[] = selectedColumns.map(column => ({
    value: column.id,
    label: allColumns.find(c => c.id === column.id)?.name ?? '',
    column: allColumns.find(c => c.id === column.id)!,
  }));

  const columnsWithFilters: DataSetColumnState[] = DataSetColumnStateModel.combineColumnsWithFilters(
    dataSetFilters.columns,
    dataSetFilters.filters,
  );

  const updateColumnsState = (selectedColumnsDef: ColumnOptionType[]) => {
    const newColumnsStates: DataSetColumnState[] = allColumns.map(columnOption => {
      const isSelected = selectedColumnsDef.some(c => c.column?.id === columnOption.id);
      const columnState = dataSetFilters.columns.find(c => c.id === columnOption.id);

      if (!columnState) {
        return {
          id: columnOption.id,
          hide: !isSelected,
          filters: [],
        };
      }

      return {
        ...columnState,
        hide: !isSelected,
      };
    });

    dataSetFilters.setColumnsState({ columns: newColumnsStates });
  };

  const sortColumnState = dataSetFilters.columns.find(column => column.sort);
  const sortColumnDef = selectedDataSet?.columnDefs.find(c => c.id === sortColumnState?.id);
  const isUpdateDisabled = !hasUnsavedChanges || !selectedSavedView || isDisabled;

  const updateCreateButtonProps: Partial<ButtonProps> = {
    h: '10',
    variant: 'solid',
    colorScheme: 'gray',
    fontSize: 'sm',
    px: '4',
    bgColor: 'gray.200',
    color: 'gray.700',
    _hover: { bgColor: 'gray.300' },
    leftIcon: undefined,
  };

  const canCreateSavedView = Boolean(selectedDataSet?.canAccessDataSet);

  return (
    <HStack spacing="4">
      <ButtonGroup spacing="2">
        {sortColumnState && sortColumnDef && (
          <DataSetSortButton columnState={sortColumnState} columnDef={sortColumnDef} />
        )}

        <DataSetColumnsFilterV1 isDisabled={isDisabled} />

        <DataSetColumnsVisibilityFilterV1
          onChange={columns => {
            if (BlvdSelectHelpers.isOptionsType(columns)) {
              updateColumnsState(columns.map(c => c));
            }
          }}
          columns={allColumns}
          selected={selectedOptions}
          isDisabled={isDisabled}
        />
      </ButtonGroup>
      <Spacer />

      {selectedDataSet && selectedSavedView && (
        <Tooltip
          label="You don't have access to edit this saved view. Please reach out to the owner or an admin."
          hasArrow={true}
          placement="bottom"
          fontSize="xs"
          isDisabled={!isDisabled}
          shouldWrapChildren
        >
          <ButtonGroup size="sm" isAttached variant="outline" isDisabled={isDisabled}>
            <Button onClick={onUpdateSavedView} isDisabled={isUpdateDisabled} {...updateCreateButtonProps} pr={2}>
              Update
            </Button>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    as={IconButton}
                    isActive={isOpen}
                    variant="solid"
                    colorScheme="gray"
                    bgColor="gray.200"
                    color="gray.700"
                    opacity={isUpdateDisabled ? 0.4 : 1}
                    _hover={{ bgColor: 'gray.300' }}
                    h="10"
                    aria-label="Additional Options"
                    icon={<Icon variant="fas" icon={isOpen ? 'caret-up' : 'caret-down'} size="3" />}
                  />
                  <MenuList mt="-1" border="none" bgColor="transparent" minW="none" minH="none" py="0">
                    <CreateSavedViewButton
                      dataSet={selectedDataSet}
                      columns={columnsWithFilters}
                      isDisabled={!canCreateSavedView}
                      buttonProps={{
                        variant: 'outlined',
                        colorScheme: 'brand',
                        fontSize: 'sm',
                        px: '4',
                        size: 'md',
                        bgColor: 'white',
                        color: 'gray.600',
                        _hover: { bgColor: 'gray.100' },
                        borderWidth: '1px',
                        borderColor: 'gray.300',
                        borderStyle: 'solid',
                        h: '12',
                      }}
                    />
                  </MenuList>
                </>
              )}
            </Menu>
          </ButtonGroup>
        </Tooltip>
      )}

      {selectedDataSet && !selectedSavedView && (
        <CreateSavedViewButton
          dataSet={selectedDataSet}
          columns={columnsWithFilters}
          isDisabled={!canCreateSavedView}
          buttonProps={updateCreateButtonProps}
        />
      )}
    </HStack>
  );
};
