import { Muid } from '@process-street/subgrade/core';
import {} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateChecklistAssignmentMutationParams = {
  assignmentId: Muid;
  checklistId: Muid;
  email: string;
};

export type CreateChecklistAssignmentMutationResponse = {};

export const CreateChecklistAssignmentMutation = {
  key: ['put', 'checklist-assignments'],
  mutationFn: ({ assignmentId, checklistId, email }: CreateChecklistAssignmentMutationParams) =>
    axiosService
      .getAxios()
      .put<CreateChecklistAssignmentMutationResponse>(`/1/checklist-assignments/${assignmentId}`, {
        checklistId,
        email,
        autoAssign: false,
      })
      .then(res => res.data),
};

export const useCreateChecklistAssignmentMutation = (
  options: UseMutationOptions<
    CreateChecklistAssignmentMutationResponse,
    AxiosError,
    CreateChecklistAssignmentMutationParams
  > = {},
) => {
  return useMutation(CreateChecklistAssignmentMutation.mutationFn, options);
};
