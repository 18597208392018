import * as React from 'react';
import { Button, HStack, Icon, IconButton, Text, VStack } from 'components/design/next';
import { match, P } from 'ts-pattern';

type DesignItemButtonProps = {
  title: string;
  onClick: () => void;
  handleRemove: () => void;
  isSmallImage?: boolean;
  children?: React.ReactNode;
  isLoading: boolean;
};

const RemoveButton: React.FC<{ onClick: () => void; title: string; isLoading?: boolean }> = ({
  onClick,
  title,
  isLoading = false,
}) => (
  <IconButton
    aria-label={`Remove ${title}`}
    icon={<Icon icon="trash" size="4" />}
    variant="secondary"
    onClick={onClick}
    isLoading={isLoading}
  />
);

const AddButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button aria-label="Add icon" color="gray.600" variant="secondary" onClick={onClick}>
    Add
  </Button>
);

export const DesignItemButton: React.FC<DesignItemButtonProps> = ({
  title,
  onClick,
  handleRemove,
  isSmallImage = false,
  isLoading = false,
  children,
}) => {
  const actionButtons = match({ children, isSmallImage, isLoading })
    .with({ children: P.nullish }, () => <AddButton onClick={onClick} />)
    .with({ children: P.not(P.nullish), isSmallImage: true }, () => (
      <HStack gap={4}>
        {children}
        <RemoveButton onClick={handleRemove} title={title} isLoading={isLoading} />
      </HStack>
    ))
    .otherwise(() => <RemoveButton onClick={handleRemove} title={title} isLoading={isLoading} />);

  return (
    <VStack w="full">
      <HStack justifyContent="space-between" w="full">
        <Text>{title}</Text>
        {actionButtons}
      </HStack>
      {match({ children, isSmallImage })
        .with({ children: P.not(P.nullish), isSmallImage: false }, () => children)
        .otherwise(() => null)}
    </VStack>
  );
};
