import { Muid, Option } from '@process-street/subgrade/core';
import { ChecklistSelector } from 'reducers/checklist/checklist.selectors';
import { ReduxAppState } from 'reducers/types';

export interface SentryStateContext {
  templateId?: Muid;
  checklistId?: Muid;
  taskTemplateGroupId?: Muid;
}

interface TransitionStateParams {
  groupId?: string;
  id?: string;
  templateId?: string;
}

function contextFromChecklistId(
  checklistId: Option<Muid>,
  taskTemplateGroupId: Option<Muid>,
  state: ReduxAppState,
): SentryStateContext {
  const checklist = checklistId ? ChecklistSelector.getById(checklistId)(state) : undefined;
  const templateId = checklist ? checklist.template.id : undefined;
  return {
    checklistId,
    taskTemplateGroupId,
    templateId,
  };
}

function fromTransition(name: string, $stateParams: TransitionStateParams, state: ReduxAppState): SentryStateContext {
  switch (name) {
    case 'templateView':
    case 'templatePrint':
    case 'template':
    case 'workflowManage':
    case 'pageManage':
    case 'templateSubscriptionUnsubscribe':
      return { templateId: $stateParams.id };
    case 'templateView.task':
    case 'template.task':
      return { templateId: $stateParams.id, taskTemplateGroupId: $stateParams.groupId };
    case 'checklistAdd':
    case 'checklistAddMultiple':
    case 'checklistRun':
    case 'futureChecklistAdd':
    case 'checklistRunRedirect1':
    case 'checklistRunRedirect2':
    case 'checklistRunRedirect3':
      return { templateId: $stateParams.templateId };
    case 'checklist':
    case 'futureChecklist':
    case 'checklistPrint':
    case 'checklistManage':
    case 'checklistManage.tab':
    case 'checklistShared':
    case 'checklistSubscriptionUnsubscribe':
      return contextFromChecklistId($stateParams.id, undefined, state);
    case 'checklist.task':
    case 'checklistShared.task':
      return contextFromChecklistId($stateParams.id, $stateParams.groupId, state);
    default:
      return {};
  }
}

export const SentryStateContextUtils = {
  fromTransition,
};
