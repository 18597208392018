import debounce from 'lodash/debounce';
import React, { FormEvent, useEffect, useState } from 'react';
import { Input } from 'components/design/next';
import styles from './BlvdNumberInput.module.scss';

export interface BlvdNumberInputProps {
  value: number;
  placeholder?: string;
  onChange: (value: number) => void;
  debounceDelay?: number;
}

const DefaultDebounceDelay = 500;

export const BlvdNumberInput: React.FunctionComponent<React.PropsWithChildren<BlvdNumberInputProps>> = ({
  value,
  placeholder,
  onChange,
  debounceDelay,
}) => {
  const [pattern, setPattern] = useState(value);
  const delay = debounceDelay || DefaultDebounceDelay;

  const onChangeCallback = React.useMemo(
    () => debounce((filter: number) => onChange(filter), delay),
    [delay, onChange],
  );

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setPattern(+event.currentTarget.value);
    onChangeCallback(+event.currentTarget.value);
  };

  useEffect(() => {
    setPattern(value);
  }, [value]);

  return (
    <div className={styles.numberInput}>
      <Input type="number" placeholder={placeholder} value={pattern} onChange={handleChange} />
    </div>
  );
};
