import { isAdmin, Muid, MuidUtils, Option, OrganizationMembership, User } from '@process-street/subgrade/core';
import { DateUtils } from '@process-street/subgrade/util';
import { MemberItem } from 'components/common/MemberOption';
import {
  accessLevelToDescriptionMap,
  accessLevelToLabelMap,
  SavedView,
  SavedViewAccessLevel,
} from 'components/dashboard/models/saved-views';
import { SavedViewsPermissionService } from 'components/dashboard/services/saved-views-permission.service';
import React, { FormEvent, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
  VStack,
} from 'components/design/next';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { SavedViewPermissionSelector } from '../SavedViewPermissionSelector';
import { match } from 'ts-pattern';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import { copyView } from './copy-view';
import { useFeatureFlag } from 'features/feature-flags';
import { SavedViewFolderDropdown } from 'components/dashboard/components/checklist/ChecklistDashboardSavedViews/SavedViewEditor/SavedViewFolderDropdown';

export enum SavedViewEditorMode {
  NEW = 'New',
  EDIT = 'Edit',
}

export interface SavedViewEditorProps extends Omit<ModalProps, 'children'> {
  allMembers: MemberItem[];
  createdBy: Option<MemberItem>;
  canSavePredefined: boolean;
  mode: SavedViewEditorMode;
  onSave: (view: SavedView) => void;
  organizationMembership: OrganizationMembership;
  view: SavedView;
}

const ACCESS_LEVELS = [
  SavedViewAccessLevel.Private,
  SavedViewAccessLevel.IndividualsAndGroups,
  SavedViewAccessLevel.Organization,
];

export enum EditAction {
  CreateNew = 'CreateNew',
  Save = 'Save',
}

export const SavedViewEditor: React.FunctionComponent<React.PropsWithChildren<SavedViewEditorProps>> = ({
  allMembers,
  canSavePredefined,
  createdBy,
  mode,
  onClose,
  onSave,
  organizationMembership,
  view,
  isOpen,
}) => {
  const [editedView, setEditedView] = useState(copyView(view, canSavePredefined, mode, organizationMembership));
  useEffect(() => {
    setEditedView(copyView(view, canSavePredefined, mode, organizationMembership));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- view & mode change
  }, [view, mode]);

  const canEditView = SavedViewsPermissionService.canUserEditSavedView(view, organizationMembership);
  const shouldShowCreateAndUpdate = mode === SavedViewEditorMode.EDIT && canEditView;

  const currentUser = useSelector(SessionSelector.getCurrentUser);

  const handleNameChange = (name: string) => {
    setEditedView({ ...editedView, name });
  };

  const handleFolderChange = (folderId?: Muid) => {
    setEditedView({ ...editedView, folderId });
  };

  const saveById = (id: Muid) => {
    const organizationId = organizationMembership.organization.id;
    const predefined = canSavePredefined ? editedView.predefined : false;
    onSave({ ...editedView, id, predefined, organizationId });
  };

  const handleCreateNew = () => {
    saveById(MuidUtils.randomMuid());
  };

  const handleUpdate = () => {
    saveById(editedView.id);
  };

  const handleAssigneeAdd = (item: MemberItem) => {
    const organizationMembershipIds = editedView.organizationMembershipIds.concat(item.organizationMembershipId);
    setEditedView({ ...editedView, organizationMembershipIds });
  };

  const handleAssigneeRemove = (id: Muid) => {
    const organizationMembershipIds = editedView.organizationMembershipIds.filter(assignedId => assignedId !== id);
    setEditedView({ ...editedView, organizationMembershipIds });
  };

  const userIsAdmin = isAdmin(organizationMembership);

  const handlePublishedChange = (event: FormEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;
    setEditedView({ ...editedView, predefined: checked });
  };

  const saveDisabled = editedView.name.trim() === '';

  const accessVisible = editedView.accessLevel === SavedViewAccessLevel.IndividualsAndGroups;
  const header = shouldShowCreateAndUpdate ? 'Edit saved view' : 'New saved view';

  const publishVisible = SavedViewsPermissionService.canUserPublishView(editedView, organizationMembership);

  const isSavedViewFoldersEnabled = useFeatureFlag('reportsSavedViewFolders');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <Text variant="1">{header}</Text>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody as={VStack} alignItems="flex-start" spacing="4">
          <FormControl>
            <FormLabel>View name</FormLabel>
            <Input
              autoFocus
              placeholder="e.g. My Overdue Tasks"
              value={editedView.name}
              onChange={e => handleNameChange(e.target.value)}
            />
          </FormControl>

          {isSavedViewFoldersEnabled && (
            <SavedViewFolderDropdown
              editedView={editedView}
              handleFolderChange={handleFolderChange}
              organizationMembership={organizationMembership}
            />
          )}

          <FormControl>
            <FormLabel>Who can see this?</FormLabel>

            <RadioGroup
              value={editedView.accessLevel}
              onChange={accessLevel =>
                setEditedView({ ...editedView, accessLevel: accessLevel as SavedViewAccessLevel })
              }
            >
              <VStack alignItems="flex-start">
                {ACCESS_LEVELS.map(accessLevel => {
                  const disabled = accessLevel === SavedViewAccessLevel.Organization && !userIsAdmin;
                  const label = accessLevelToLabelMap[accessLevel];
                  const description = accessLevelToDescriptionMap[accessLevel];

                  return (
                    <Tooltip
                      isDisabled={!disabled}
                      key={accessLevel}
                      label="You do not have permission to select this option"
                      shouldWrapChildren
                      hasArrow
                    >
                      <Radio isDisabled={disabled} value={accessLevel} spacing="2">
                        <HStack spacing="2">
                          <Icon
                            size="5"
                            variant="far"
                            icon={match<SavedViewAccessLevel, CustomIconName>(accessLevel)
                              .with(SavedViewAccessLevel.IndividualsAndGroups, () => 'users')
                              .with(SavedViewAccessLevel.Organization, () => 'globe')
                              .with(SavedViewAccessLevel.Private, () => 'lock')
                              .exhaustive()}
                          />
                          <VStack alignItems="flex-start" spacing="0">
                            <Text as="span" color="gray.600">
                              {label}
                            </Text>
                            <Text variant="-2" color="gray.400">
                              {description}
                            </Text>
                          </VStack>
                        </HStack>
                      </Radio>
                    </Tooltip>
                  );
                })}
              </VStack>
            </RadioGroup>
          </FormControl>

          {accessVisible && (
            <SavedViewPermissionSelector
              allMembers={allMembers}
              organizationMembershipIds={editedView.organizationMembershipIds}
              onAdd={handleAssigneeAdd}
              onRemove={handleAssigneeRemove}
            />
          )}

          {publishVisible && (
            <FormControl>
              <FormLabel>Publish as predefined</FormLabel>
              <Checkbox isChecked={editedView.predefined} onChange={handlePublishedChange} spacing="2">
                <HStack>
                  <Icon icon="book-open" variant="far" size="4" />
                  <Text as="span">Published</Text>
                </HStack>
              </Checkbox>
            </FormControl>
          )}

          {mode === SavedViewEditorMode.EDIT && currentUser && (
            <CreatedLabel {...{ savedView: editedView, createdBy, currentUser }} />
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup w="full">
            {match({ shouldShowCreateAndUpdate })
              .with({ shouldShowCreateAndUpdate: true }, () => (
                <>
                  <Button variant="tertiary" onClick={onClose} mr="auto">
                    Cancel
                  </Button>

                  <Button variant="secondary" onClick={handleCreateNew}>
                    Create New
                  </Button>
                  <Button onClick={handleUpdate} isDisabled={saveDisabled}>
                    Update
                  </Button>
                </>
              ))
              .with({ shouldShowCreateAndUpdate: false }, () => (
                <>
                  <Button variant="tertiary" onClick={onClose} ml="auto">
                    Cancel
                  </Button>

                  <Button onClick={handleCreateNew}>Save</Button>
                </>
              ))
              .exhaustive()}
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const CreatedLabel: React.FC<
  React.PropsWithChildren<{ savedView: SavedView; createdBy: Option<MemberItem>; currentUser: User }>
> = ({ savedView, createdBy, currentUser }) => {
  const createdDate = DateUtils.formatDateToShortMonth(savedView.audit.createdDate, currentUser.timeZone);
  const updatedDate = DateUtils.formatDateFromNow(savedView.audit.updatedDate);
  const name = (createdBy && createdBy.name) || 'Someone';

  return (
    <Text color="gray.500" variant="-2">
      This Saved View was created by <b>{name}</b> on <b>{createdDate}</b> (Updated {updatedDate})
    </Text>
  );
};
