import * as React from 'react';
import { Box, Center, Grid, GridItem, HStack, Skeleton, SkeletonProps, VStack } from '@chakra-ui/react';
import { TOP_BAR_HEIGHT_CSS_VAR, TOP_BAR_HEIGHT_TOKEN } from 'app/pages/forms/_id/shared';
import { Global } from '@emotion/react';
import { WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT_V2 } from 'app/components/focus-bar/workflow/focus-bar-hstack';

const predefinedSidebarSkeletonSizes = ['300px', '150px', '200px'];

const defaultSkeletonProps: SkeletonProps = {
  startColor: 'gray.200',
  endColor: 'gray.100',
};

const EditorSkeletonSidebarItem = ({ width }: { width: string }) => {
  return (
    <HStack w="full" justifyContent="space-between">
      <Skeleton {...defaultSkeletonProps} borderRadius="4px" w={width} h="16px" />
      <Skeleton {...defaultSkeletonProps} w="8" h="8" borderRadius="100%" />
    </HStack>
  );
};

const EditorSkeletonWidgetItem = ({ width }: { width: string }) => {
  return (
    <VStack alignItems="flex-start" w="full" spacing={4}>
      <Skeleton {...defaultSkeletonProps} borderRadius="4px" w={width} h="4" />
      <Skeleton {...defaultSkeletonProps} w="full" h="4" />
    </VStack>
  );
};

const FocusBarSkeleton = ({ variant = 'edit' }: { variant?: 'view' | 'edit' }) => {
  const skeletonProps = {
    startColor: 'gray.300',
    endColor: 'gray.200',
  };
  return (
    <HStack
      w="full"
      h={WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT_V2}
      px={{ base: 4, md: 4 }}
      bgColor={variant === 'edit' ? 'gray.700' : 'white'}
      justifyContent="space-between"
      alignItems="center"
    >
      <VStack w="full" alignItems="flex-start" justifyContent="center">
        <Skeleton {...skeletonProps} w={{ base: '120px', md: '217px' }} h={3} />
        <Skeleton {...skeletonProps} w={{ base: '80px', md: '120px' }} h={3} />
      </VStack>
      <HStack w="full" justifyContent="flex-end" spacing={4}>
        <Skeleton {...skeletonProps} w="83px" h={3} />
        <HStack spacing={2}>
          <Skeleton {...skeletonProps} width={8} h={8} />
          <Skeleton {...skeletonProps} width={{ base: 8, md: '107px' }} h={8} />
        </HStack>
      </HStack>
    </HStack>
  );
};

export const EditorSkeleton = ({ variant = 'edit' }: { variant?: 'view' | 'edit' }) => {
  return (
    <Box
      zIndex="tooltip"
      bgColor="white"
      w="100vw"
      h={`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR})`}
      mt={TOP_BAR_HEIGHT_TOKEN}
      position="fixed"
      top="0"
      left="0"
    >
      <Global
        styles={`
            .chakra-modal__content-container { opacity: 0 !important }
          `}
      />

      <VStack w="full" alignItems="stretch" justifyContent="stretch" spacing={0} bgColor="brand.50" zIndex="2">
        <FocusBarSkeleton variant={variant} />
        <Grid
          gridTemplateColumns={{
            base: variant === 'edit' ? '0 1fr 0' : '0 1fr',
            xl: variant === 'edit' ? `480px 1fr 156px` : '480px 1fr',
          }}
          h={`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR})`}
          position="relative"
          background="transparent"
          gap={{ base: 2, sm: 15 }}
        >
          <GridItem
            h="full"
            maxW="480px"
            bgColor="white"
            borderWidth="thin"
            borderColor="gray.100"
            borderRightStyle="solid"
            boxShadow="0px 1px 2px 0px #0000000D"
          >
            <Skeleton {...defaultSkeletonProps} mb={6} h="152px" w="full" />

            <VStack spacing={4} px={6}>
              {Array.from({ length: 8 }).map((_, key) => (
                <EditorSkeletonSidebarItem
                  key={key}
                  width={predefinedSidebarSkeletonSizes[key % predefinedSidebarSkeletonSizes.length]}
                />
              ))}
            </VStack>
          </GridItem>

          <GridItem
            py={{ base: 8, md: 10 }}
            px={{ base: 2, md: 0 }}
            overflowY="auto"
            h="full"
            position="relative"
            display="flex"
            flexDirection="column"
            w="full"
          >
            <Center w="full">
              <VStack
                w="full"
                alignItems="flex-start"
                maxW={{ base: 'full', lg: '788px' }}
                borderColor="gray.200"
                borderWidth="px"
                borderRadius={{ base: 0, lg: 'lg' }}
                py={9}
                spacing={6}
                px={{ base: '8', lg: '9' }}
                bgColor="white"
              >
                <HStack spacing={3}>
                  <Skeleton {...defaultSkeletonProps} h={6} w={12} />
                  <Skeleton {...defaultSkeletonProps} h={6} w="107px" />
                  <Skeleton {...defaultSkeletonProps} h={6} w="6" />
                  <Skeleton {...defaultSkeletonProps} h={6} w="6" />
                  <Skeleton {...defaultSkeletonProps} h={6} w="6" />
                </HStack>

                <Skeleton {...defaultSkeletonProps} h={5} w="307px" />

                <VStack w="full" spacing={8}>
                  <EditorSkeletonWidgetItem width="300px" />
                  <EditorSkeletonWidgetItem width="120px" />
                  <EditorSkeletonWidgetItem width="200px" />
                </VStack>
              </VStack>
            </Center>
          </GridItem>
        </Grid>
      </VStack>
    </Box>
  );
};
