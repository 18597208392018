import { FieldType, TextareaFormFieldWidget, TEXTAREA_MAX_LENGTH } from '@process-street/subgrade/process';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';
import { FormFieldValueSchemaTests } from '../common/validation/form-field-value-schema-tests';
import { FormFieldWidgetSettingsSchemas } from '../common/validation/form-field-widget-settings-schema';

export const textareaSettingsSchema: ObjectSchema<WidgetSettings<FieldType.Textarea>> = yup
  .object({
    config: yup
      .object<TextareaFormFieldWidget['config']>({
        ...FormFieldWidgetSettingsSchemas.placeholder(60),
        ...FormFieldWidgetSettingsSchemas.defaultValue(TEXTAREA_MAX_LENGTH),
      })
      .required(),
    constraints: yup
      .object<TextareaFormFieldWidget['constraints']>({
        ...FormFieldWidgetSettingsSchemas.characterLimits(),
      })
      .required(),
  })
  .required();

type ValidationSchemaProps = {
  required: boolean;
  constraints: TextareaFormFieldWidget['constraints'];
};

export const makeTextareaValidationSchema = ({ required, constraints }: ValidationSchemaProps) => {
  const { min, max } = constraints;

  return yup
    .string()
    .test(FormFieldValueSchemaTests.required(required))
    .test(FormFieldValueSchemaTests.characterRange(min, max))
    .test(FormFieldValueSchemaTests.lengthMin(min))
    .test(FormFieldValueSchemaTests.lengthMax(max));
};
