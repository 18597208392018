import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type DeleteTaskAssignmentRuleMutationParams = {
  templateRevisionId: Muid;
  ruleId: Muid;
};

export type DeleteTaskAssignmentRuleMutationResponse = void;

export const DeleteTaskAssignmentRuleMutation = {
  key: ['delete', 'template-revision-task-assignment-rule'],
  mutationFn: ({ ruleId, templateRevisionId }: DeleteTaskAssignmentRuleMutationParams) =>
    axiosService
      .getAxios()
      .delete<DeleteTaskAssignmentRuleMutationResponse>(
        `/1/template-revisions/${templateRevisionId}/task-assignment-rules/${ruleId}`,
      )
      .then(res => res.data),
};

export const useDeleteTaskAssignmentRuleMutation = (
  options: UseMutationOptions<
    DeleteTaskAssignmentRuleMutationResponse,
    AxiosError,
    DeleteTaskAssignmentRuleMutationParams
  > = {},
) => {
  return useMutation(DeleteTaskAssignmentRuleMutation.mutationFn, options);
};
