import * as React from 'react';

import { WorkflowAutomationsProvider, ProviderProps } from '../common/context';
import { TemplateWorkflowAutomations } from './';

export const WorkflowAutomationsTemplateWrapper: React.FC<React.PropsWithChildren<ProviderProps>> = ({
  ...context
}) => {
  return (
    <WorkflowAutomationsProvider {...context}>
      <TemplateWorkflowAutomations />
    </WorkflowAutomationsProvider>
  );
};
