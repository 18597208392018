import React from 'react';

import type { Template } from '@process-street/subgrade/process';

import { Icon, IconButton, Tooltip } from 'components/design/next';
import type { IconButtonProps } from 'components/design/next';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import { useCopyTemplateLink } from 'hooks/use-copy-template-link';

export type Props = { templateId: Template['id'] } & IconButtonProps;
export const TemplateCopyLinkButton: React.FC<React.PropsWithChildren<Props>> = props => {
  const { templateId, ...rest } = props;
  const [url, handleCopyLink] = useCopyTemplateLink(templateId);
  const templateIsPrivate = useIsPrivateTemplate(templateId);

  return !templateIsPrivate ? (
    <Tooltip
      closeOnClick={false}
      closeDelay={url === '' ? 0 : 1000}
      label={url ? 'Copied!' : 'Copy Link'}
      hasArrow
      placement="top"
      variant="-2"
    >
      <IconButton
        onClick={handleCopyLink}
        variant="ghost"
        color="gray.400"
        _hover={{ color: 'gray.600' }}
        {...rest}
        icon={<Icon size="4" icon="link" variant="far" />}
      />
    </Tooltip>
  ) : null;
};
