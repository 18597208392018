import { VStack, Text } from 'components/design/next';
import { Icon } from 'components/design/next';
import * as React from 'react';

export const EmptyState = () => (
  <VStack w="full" alignItems="center" spacing="0" p="4">
    <Icon icon="magnifying-glass" size="10" pb="4" color="gray.800" />
    <Text fontSize="lg" color="gray.800">
      No activities found
    </Text>
    <Text fontSize="sm" color="gray.500">
      There is no activity matching your filter.
    </Text>
  </VStack>
);
