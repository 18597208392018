import { Muid } from '@process-street/subgrade/core';
import { ConsolidatedTaskPermit } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetConsolidatedTaskPermitsQuery {
  export type Params = { checklistRevisionId: Muid };

  export type Response = ConsolidatedTaskPermit[];

  export const key = ['checklist-revision', 'task', 'permissions', 'consolidated'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/checklist-revisions/${params.checklistRevisionId}/tasks/permissions/consolidated`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.checklistRevisionId) && options.enabled !== false,
    });
  };
}
