import { createSelector } from 'reselect';
import { canAccess, Feature } from 'services/features/features';
import { SessionSelector } from 'reducers/session/session.selectors';
import { groupMembershipSelector } from 'reducers/group-membership/group-membership.selectors';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { UserSelector } from 'reducers/user/user.selectors';
import { entitiesSelector } from 'reducers/entities/entities.selectors';
import { GroupType } from '@process-street/subgrade/core';

export const groupsSelector = state => entitiesSelector(state).groups;
const groupByIdSelector = groupId => state => groupsSelector(state)[groupId];

export const groupWithUserSelector = (state, groupId) => {
  const group = groupByIdSelector(groupId)(state);
  if (!group) {
    return undefined;
  }
  const user = UserSelector.getById(group.user.id)(state);
  return Object.assign({}, group, { user });
};

export const allGroupsSelector = createSelector([groupsSelector, UserSelector.getEntityMap], (groups, users) =>
  Object.values(groups).map(group => Object.assign({}, group, { user: users[group.user.id] })),
);

export const groupsByGroupTypesSelector = types =>
  createSelector([groupsSelector, UserSelector.getEntityMap], (groups, users) =>
    Object.values(groups)
      .filter(({ groupType }) => types.includes(groupType))
      .map(group => Object.assign({}, group, { user: users[group.user.id] })),
  );

export const standardGroupsSelector = state => groupsByGroupTypesSelector([GroupType.Standard])(state);

export const systemGroupsSelector = createSelector([groupsSelector, UserSelector.getEntityMap], (groups, users) =>
  Object.values(groups)
    .filter(({ groupType }) => groupType !== GroupType.Standard)
    .map(group => Object.assign({}, group, { user: users[group.user.id] })),
);

export const groupMembershipLookupSelector = createSelector(
  [groupMembershipSelector, OrganizationMembershipSelector.getEntityMap, UserSelector.getEntityMap],
  (memberships = [], orgMemberships, users) =>
    Object.values(memberships).reduce((agg, membership) => {
      const groupId = membership.group.id;
      const orgMem = orgMemberships[membership.organizationMembership.id];
      const user = users[orgMem.user.id];
      agg[groupId] = (agg[groupId] || []).concat(user);
      return agg;
    }, {}),
);

export const groupOrganizationMembershipLookupSelector = createSelector(
  [groupMembershipSelector, OrganizationMembershipSelector.getEntityMap, UserSelector.getEntityMap],
  (groupMemberships = [], organizationMemberships, users) =>
    Object.values(groupMemberships).reduce((agg, groupMembership) => {
      const groupId = groupMembership.group.id;
      const organizationMembership = organizationMemberships[groupMembership.organizationMembership.id];
      const user = users[organizationMembership.user.id];
      if (user) {
        const organizationMembershipWithUser = { ...organizationMembership, user };
        agg[groupId] = (agg[groupId] || []).concat(organizationMembershipWithUser);
      }
      return agg;
    }, {}),
);

export const organizationMembershipsByGroupIdSelector = groupId =>
  createSelector(
    groupOrganizationMembershipLookupSelector,
    groupOrganizationMembershipsMap => groupOrganizationMembershipsMap[groupId] || [],
  );

export const restrictedSelector = createSelector(
  [SessionSelector.getCurrentPlan, SessionSelector.isCanceled],
  (currentPlan, isCanceled) => !canAccess(Feature.GROUPS, currentPlan.id) || isCanceled,
);

export const groupNamesSelector = state =>
  Object.keys(groupsSelector(state))
    .map(groupId => {
      const group = groupByIdSelector(groupId)(state);
      const user = UserSelector.getById(group.user.id)(state);
      return user ? user.username : undefined;
    })
    .filter(groupName => groupName !== undefined);

export const selectedOrganizationMembershipWithUsersNoSystemGroupsSelector = createSelector(
  [OrganizationMembershipSelector.getAllWithUserBySelectedOrganizationId, systemGroupsSelector],
  (allMemberships, groups) => {
    const systemGroupUserIds = groups.map(group => group.user.id);

    return allMemberships.filter(m => m.user && !systemGroupUserIds.includes(m.user.id)) || [];
  },
);

export const selectedOrganizationMembershipWithUsersOnlySystemGroupsSelector = createSelector(
  [OrganizationMembershipSelector.getAllWithUserBySelectedOrganizationId, systemGroupsSelector],
  (allMemberships, groups) => {
    const systemGroupUserIds = groups.map(group => group.user.id);

    return allMemberships.filter(m => m.user && systemGroupUserIds.includes(m.user.id)) || [];
  },
);

const findGroupMembership = (allMemberships, groups, requiredGroupType) => {
  const group = groups.find(({ groupType }) => groupType === requiredGroupType);
  const groupUserId = group && group.user && group.user.id;
  return allMemberships.find(m => m.user && m.user.id === groupUserId);
};

export const selectedOrganizationAllMembersMembershipSelector = createSelector(
  [OrganizationMembershipSelector.getAllWithUserBySelectedOrganizationId, systemGroupsSelector],
  (allMemberships, groups) => findGroupMembership(allMemberships, groups, GroupType.AllMembers),
);

export const selectedOrganizationAllGuestsMembershipSelector = createSelector(
  [OrganizationMembershipSelector.getAllWithUserBySelectedOrganizationId, systemGroupsSelector],
  (allMemberships, groups) => findGroupMembership(allMemberships, groups, GroupType.AllGuests),
);

export const selectedOrganizationAllAnonymousMembershipSelector = createSelector(
  [OrganizationMembershipSelector.getAllWithUserBySelectedOrganizationId, systemGroupsSelector],
  (allMemberships, groups) => findGroupMembership(allMemberships, groups, GroupType.AllAnonymous),
);

export const selectedOrganizationPredefinedTaskGroupMembershipsSelector = createSelector(
  [OrganizationMembershipSelector.getAllWithUserBySelectedOrganizationId, systemGroupsSelector],
  (allMemberships, groups) =>
    [GroupType.AllMembers, GroupType.AllGuests, GroupType.AllAnonymous].reduce((acc, groupType) => {
      acc[groupType] = findGroupMembership(allMemberships, groups, groupType);
      return acc;
    }, {}),
);

const getGroupByGroupUser = user =>
  createSelector(groupsSelector, groups => Object.values(groups).find(g => user && g.user.id === user.id));

const getAllMembersGroup = createSelector(groupsByGroupTypesSelector([GroupType.AllMembers]), groups => groups[0]);

const getAllFreeMembersGroup = createSelector(
  groupsByGroupTypesSelector([GroupType.AllFreeMembers]),
  groups => groups[0],
);

const getHiddenSystemGroups = groupsByGroupTypesSelector([GroupType.AllFreeMembers, GroupType.AllAdmins]);

const getHiddenSystemGroupsUserIds = createSelector(getHiddenSystemGroups, groups =>
  groups.map(g => g?.user.id).filter(u => u !== undefined),
);

export const GroupSelector = {
  getGroupByGroupUser,
  getAllMembersGroup,
  getAllFreeMembersGroup,
  getHiddenSystemGroups,
  getHiddenSystemGroupsUserIds,
};
