import { MotionWrapper, Tooltip, TooltipProps } from 'components/design/next';
import { FormFieldEvent } from 'services/form-field-event';
import { useInjector } from 'components/injection-provider';
import * as React from 'react';
import { useDebounce } from 'react-use';
import { useFunctionRef } from 'hooks/use-function-ref';

export const FORM_FIELD_DISABLED_TIMEOUT = 3000;

export type Props = TooltipProps & { onOpen?: () => void };

export const DisabledFormFieldClickTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  onOpen,
  children,
  ...props
}) => {
  const { $rootScope } = useInjector('$rootScope');

  const onOpenRef = useFunctionRef(onOpen);

  const [runWorkflowTooltipMessage, setRunWorkflowTooltipMessage] = React.useState<string | null>(null);

  useDebounce(
    () => {
      if (runWorkflowTooltipMessage) {
        setRunWorkflowTooltipMessage(null);
      }
    },
    FORM_FIELD_DISABLED_TIMEOUT,
    [runWorkflowTooltipMessage],
  );

  React.useEffect(() => {
    const unsubscribe = $rootScope.$on(FormFieldEvent.DISABLED_FORM_FIELD_CLICKED, () => {
      onOpenRef.current?.();
      setRunWorkflowTooltipMessage('Form fields are only editable on workflow runs.');
    });
    return () => {
      setRunWorkflowTooltipMessage(null);
      unsubscribe();
    };
  }, [$rootScope, onOpenRef]);

  const safeChildren = React.isValidElement(children) ? children : <>{children}</>;

  return runWorkflowTooltipMessage ? (
    <Tooltip hasArrow={true} label={runWorkflowTooltipMessage} isOpen placement="bottom" shouldWrapChildren {...props}>
      <MotionWrapper layout={true} initial={{ scale: 1 }} animate={{ scale: [1, 1.1, 1.1, 1] }} exit={{ scale: 1 }}>
        {safeChildren}
      </MotionWrapper>
    </Tooltip>
  ) : (
    safeChildren
  );
};
