import * as React from 'react';
import { Text, Box, useToken } from 'components/design/next';
import { RenderElementProps } from 'slate-react';
import { EditorListItem } from '../elements/list-item';
import { PagesListItemElement } from '../../pages-plate-types';

export type ListItemElementProps = {
  element: PagesListItemElement;
  attributes: RenderElementProps['attributes'];
  children: RenderElementProps['children'];
};

export const ELEMENT_PS_UNORDERED_LIST_ITEM = 'li';

const getBullet = (depth: PagesListItemElement['depth'] = 1) =>
  ((
    {
      1: '●',
      2: '○',
      3: '■',
      4: '□',
      5: '●',
      6: '○',
      7: '■',
      8: '□',
    } as Record<string, string>
  )[depth] || '●');

export const UnorderedListItemElement: React.FC<React.PropsWithChildren<ListItemElementProps>> = React.memo(
  ({ attributes, children, element }): JSX.Element => {
    const [ml] = useToken('sizes', [6]);
    return (
      <EditorListItem {...attributes}>
        <Box position="relative" paddingLeft={(element.depth - 1) * 4} marginLeft={ml}>
          <Text
            _before={{
              content: `"${getBullet(element.depth)}"`,
              display: 'block',
              position: 'absolute',
              transform: `translateX(calc(-1 * ${ml})) scale(0.5)`,
            }}
            fontSize="inherit"
            lineHeight="inherit"
          >
            {children}
          </Text>
        </Box>
      </EditorListItem>
    );
  },
);
