import angular from 'angular';
import { htmlEscaped } from '@process-street/subgrade/util';
import templateUrl from './template-header.component.html';
import './template-header.scss';
import { TemplateConstants } from 'services/template-constants';

angular.module('frontStreetApp.directives').component('psTemplateHeader', {
  bindings: {
    updateable: '<',
    template: '<',
    onUpdateName: '&',
    onBlurEmptyName: '&',
    descriptionShown: '<',
    onUpdateDescription: '&',
    publicShown: '<',
    onClickPublic: '&',
  },
  templateUrl,
  controller($scope, focusById) {
    const ctrl = this;

    // Name

    $scope.$watch(
      () => ctrl.template && ctrl.template.name,
      name => {
        ctrl.name = name;
        if (name === TemplateConstants.BLANK_WORKFLOW_NAME) {
          ctrl.focusOnName(name);
        }
      },
    );

    ctrl.updateName = function () {
      if (ctrl.name) {
        ctrl.onUpdateName({ name: ctrl.name });
        // This is done because we let the callback decide if they want to update
        // This is the recommended way by Angular (so far as I can tell)
        ctrl.name = ctrl.template.name;
      }
    };

    ctrl.blurName = function () {
      if (!ctrl.name) {
        ctrl.onBlurEmptyName();
        ctrl.name = ctrl.template.name;
      }
    };

    ctrl.focusOnName = () => {
      focusById('template-name-input', {
        selectionStart: () => 0,
        selectionEnd: length => length,
      });
    };

    // Description

    $scope.$watch(
      () => ctrl.template && ctrl.template.description,
      desc => {
        ctrl.description = desc;
      },
    );

    ctrl.updateDescription = () => {
      ctrl.onUpdateDescription({ description: ctrl.description });
      ctrl.description = ctrl.template.description;
    };

    // Public

    ctrl.shouldShowPublicMarker = () => ctrl.publicShown && ctrl.template.public;

    ctrl.clickPublic = () => {
      ctrl.onClickPublic({ template: ctrl.template });
    };

    ctrl.getTemplateDescription = () => htmlEscaped`${ctrl.template.description}`;
  },
});
