import { Plan } from '@process-street/subgrade/billing';
import { Organization, User } from '@process-street/subgrade/core';
import { dayjs } from '@process-street/subgrade/util';
import { PSLaunchDarklyUser } from 'services/features/feature-flags/feature-flag-service';
import { Option } from 'space-monad';
import random from 'lodash/random';

const LD_ANONYMOUS_USER_PREFIX = 'ld_anon_user_';
const LD_ANONYMOUS_USER_COUNT = 20;
const ANONYMOUS_USER_LOCALSTORAGE_KEY = 'anonymousUserId';

const generateLaunchDarklyUser = (
  user: User,
  selectedOrganization?: Organization,
  selectedPlan?: Plan,
): PSLaunchDarklyUser => {
  return {
    kind: 'user',
    key: user.id,
    name: user.username,
    email: user.email,
    beta: user.beta,
    developer: user.developer,
    selectedOrganizationId: selectedOrganization?.id,
    selectedOrganizationName: selectedOrganization?.name,
    selectedOrganizationCreatedDate: selectedOrganization && dayjs.utc(selectedOrganization.audit.createdDate).format(),
    selectedPlanId: selectedPlan?.id,
  };
};

const generateLaunchDarklyAnonymousUser = (): PSLaunchDarklyUser => {
  const persistedAnonymousId = Option(localStorage.getItem(ANONYMOUS_USER_LOCALSTORAGE_KEY));
  const anonymousId = persistedAnonymousId.getOrElse(getRandomAnonymousUser());
  const anonymousName = getAnonymousUserName(anonymousId);
  const persistedData = Option(localStorage.getItem('persist:root'));

  const user = persistedData.fold<Partial<User>, Partial<User>>(
    () => ({}),
    data => JSON.parse(data)?.session?.user,
  );

  localStorage.setItem(ANONYMOUS_USER_LOCALSTORAGE_KEY, anonymousId);

  const preLoginUser: PSLaunchDarklyUser = user?.id
    ? {
        kind: 'user',
        key: user.id,
        name: user.username ?? getAnonymousUserName(user.id),
      }
    : {
        kind: 'user',
        key: anonymousId,
        name: anonymousName,
      };

  return preLoginUser;
};

const getRandomAnonymousUser = () => {
  return LD_ANONYMOUS_USER_PREFIX + random(1, LD_ANONYMOUS_USER_COUNT);
};

const getAnonymousUserName = (key: string) => {
  const userNo = key.split('_').pop() ?? '';
  return `Anonymous User ${+userNo || 'Default'}`;
};

export const FeatureFlagsUtils = {
  getAnonymousUserName,
  getRandomAnonymousUser,
  generateLaunchDarklyUser,
  generateLaunchDarklyAnonymousUser,
  localStorageKey: ANONYMOUS_USER_LOCALSTORAGE_KEY,
};
