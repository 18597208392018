import * as React from 'react';
import { User } from '@process-street/subgrade/core';
import { CreateWorkflowToast } from 'features/create-organization/components/create-workflow-cta/toast';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useInjector } from 'components/injection-provider';

export type CreateWorkflowWrapperProps = {
  isOpen: boolean;
  onToastClose?: () => void;
  user?: User;
};

interface ChecksWrapperProps {
  user?: User;
}

export const ChecksWrapper: React.FC<React.PropsWithChildren<ChecksWrapperProps>> = ({ children, user }) => {
  const { SecurityService: securityService } = useInjector('SecurityService');
  const isGuest = useSelector(SessionSelector.isUserGuestOfSelectedOrganization);
  const userCanCreateOrganization = user ? securityService.canCreateOrganization(user) : false;
  const userCanSeeCta = isGuest && userCanCreateOrganization;

  return <>{userCanSeeCta && children}</>;
};

export const CreateWorkflowWrapper: React.FC<React.PropsWithChildren<CreateWorkflowWrapperProps>> = ({
  isOpen,
  onToastClose,
  user,
}) => {
  return (
    <ChecksWrapper user={user}>
      <CreateWorkflowToast onToastClose={onToastClose} isOpen={isOpen} />
    </ChecksWrapper>
  );
};
