import { Organization, OrganizationStats } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

const getOrganizationStats = async (organizationId: Organization['id']): Promise<OrganizationStats> => {
  return axiosService
    .getAxios()
    .get(`/1/organizations/${organizationId}/stats`)
    .then(res => res.data);
};

export const GetOrganizationStats = {
  key: ['organization', 'stats', 'get'],
  getKey: (organizationId: Organization['id']): QueryKey => [...GetOrganizationStats.key, organizationId],
  queryFn: (organizationId: Organization['id']) => getOrganizationStats(organizationId),
};

type GetOrganizationStatsResult = OrganizationStats;

export const useGetOrganizationStatsQuery = <Select = GetOrganizationStatsResult>(
  organizationId: Organization['id'],
  options: UseQueryOptions<OrganizationStats, AxiosError, Select> = {},
) => {
  return useQuery(GetOrganizationStats.getKey(organizationId), () => GetOrganizationStats.queryFn(organizationId), {
    staleTime: 1000 * 60 * 10, // 10 Minutes
    ...options,
  });
};
