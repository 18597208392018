import * as React from 'react';
import { CellRenderer } from './common';
import { Avatar as PSAvatar, AvatarSize } from 'components/common/Avatar';
import { AvatarGroup as PSAvatarGroup } from 'components/common/avatar-group';

const AVATAR_COUNT_LIMIT = 5;
const AVATAR_MARGIN = -1.5;

const RestCountAvatar = ({ restCount }: { restCount: number }) => {
  const initials = restCount < 100 ? `+${restCount}` : '99+';
  return (
    <PSAvatar
      size={AvatarSize.Normal}
      initials={initials}
      ml={AVATAR_MARGIN}
      initialsProps={{ backgroundColor: 'gray.400' }}
    />
  );
};

export const AssignmentsCell: CellRenderer<'assignments'> = ({ cell: { value: assignments } }) => {
  return (
    <PSAvatarGroup max={AVATAR_COUNT_LIMIT} restCountAvatar={RestCountAvatar}>
      {assignments
        .filter(a => Boolean(a.organizationMembership))
        .map(({ organizationMembership }, index) => (
          <PSAvatar
            ml={index === 0 ? 0 : AVATAR_MARGIN}
            key={organizationMembership.id}
            user={organizationMembership.user}
            size={AvatarSize.Normal}
          />
        ))}
    </PSAvatarGroup>
  );
};
