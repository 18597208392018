import * as React from 'react';
import { NativeAutomation } from '@process-street/subgrade/process';
import { Flex, Icon, Tooltip } from 'components/design/next';
import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';
import { Muid } from '@process-street/subgrade/core';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';

export type AiTaskErrorIndicatorProps = {
  templateRevisionId: Muid;
  taskTemplateId: Muid;
  isTaskSelected?: boolean;
};

export const AiTaskErrorIndicator: React.FC<React.PropsWithChildren<AiTaskErrorIndicatorProps>> = ({
  templateRevisionId,
  taskTemplateId,
  isTaskSelected,
}) => {
  const isEditMode = useWorkflowState() === 'edit';

  const taskAutomationQuery = GetAllNativeAutomationsQuery.useGetTaskTemplateAutomationQuery({
    templateRevisionId,
    taskTemplateId,
  });

  const label = React.useMemo(() => {
    if (!taskAutomationQuery.data) return;

    const isConfigured = NativeAutomation.isAutomationConfigured(taskAutomationQuery.data);
    if (isConfigured) return;

    const shouldShowAlert = isEditMode ? !isTaskSelected : true;
    if (!shouldShowAlert) return;

    return 'Incomplete configuration';
  }, [isEditMode, isTaskSelected, taskAutomationQuery.data]);

  return (
    <>
      {Boolean(label) && (
        <Tooltip label={label}>
          <Flex w="8" h="8" alignItems="center" justifyContent="center">
            <Icon aria-label={label} icon="warning" color="red" size="4" />
          </Flex>
        </Tooltip>
      )}
    </>
  );
};
