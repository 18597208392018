import { isEditor } from '@udecode/slate';
import { PSEditor } from 'pages/pages/_id/edit/page/utils/ps-editor';
import { createPagesPluginFactory, PagesNodeEntry } from '../pages-plate-types';

export const createPSNormalizer = createPagesPluginFactory({
  key: 'PSNormalizer',
  withOverrides: editor => {
    const { normalizeNode } = editor;

    return Object.assign(editor, {
      normalizeNode: (entry: PagesNodeEntry) => {
        const [node] = entry;
        if (isEditor(node)) {
          const lastNode = node.children[node.children.length - 1];

          // Ensure we always have at least one trailing text node
          if (!PSEditor.isTextWidgetElement(lastNode)) {
            PSEditor.insertTextNode(editor, { at: [node.children.length] });
            return;
          }
        }
        normalizeNode(entry);
      },
    });
  },
});
