import { TaskListContainer } from './task-list-container';
import { TaskListTasksWrapper } from './task-list-tasks-wrapper';
import { TaskListItem } from './task-list-item';
import { TaskListChecklistName } from './task-list-checklist-name';
import { TaskListTopControls } from './top-controls';
import { TaskListToggleStoppedTasks } from './tasks-list-toggle-stopped-tasks';

export namespace ChecklistTaskListComponents {
  export const Container = TaskListContainer;
  export const TasksWrapper = TaskListTasksWrapper;
  export const ChecklistName = TaskListChecklistName;
  export const Item = TaskListItem;
  export const TopControls = TaskListTopControls;
  export const ToggleStoppedTasks = TaskListToggleStoppedTasks;
}
