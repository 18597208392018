import { undeleteWidgetByHeaderId } from 'pages/pages/_id/edit/page/utils/widget.api';
import { useMutation } from 'react-query';

export const UndeleteWidgetByHeaderIdMutation = {
  key: ['widget', 'undelete'],
  getKey() {
    return [...UndeleteWidgetByHeaderIdMutation.key];
  },
  mutationFn: undeleteWidgetByHeaderId,
};

export const useUndeleteWidgetMutation = () => {
  return useMutation(UndeleteWidgetByHeaderIdMutation.mutationFn, {
    mutationKey: UndeleteWidgetByHeaderIdMutation.getKey(),
  });
};
