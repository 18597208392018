import * as React from 'react';
import { Button, Checkbox, HStack, Icon, ListItem, Text, UnorderedList } from 'components/design/next';
import { useField } from 'formik';
import {
  Node,
  NodeInfoMap,
  NodeType,
  SelectorHelper,
} from 'directives/rules/template/task-templates-selector/selector-helper';
import { ConditionalLogicUtils, NormalizedData } from 'features/conditional-logic/utils/conditional-logic-utils';

import { produce } from 'immer';
import { TaskVisibility } from '@process-street/subgrade/conditional-logic';
import { NodeName } from '../node-name';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useTemplateTypeContext } from 'utils/template/template-type-context';
import { useConditionalLogicModalStore } from '../modal/store';

export type HiddenTabChecklistProps = {
  nodes: Node[];
  isSearching: boolean;
};

export const HiddenTabChecklist: React.FC<React.PropsWithChildren<HiddenTabChecklistProps>> = ({
  nodes,
  isSearching,
}) => {
  const [{ value }, , helpers] = useField<NodeInfoMap>('nodeInfoMap');
  const { normalizedData } = useConditionalLogicModalStore();

  const visibleNodes = React.useMemo(() => {
    // When searching, the nodes already contains all the nodes that should be rendered.
    if (isSearching) return nodes;

    return nodes.filter(node => {
      if (node.type === NodeType.Task) {
        const parentHeading = value[node.parentId ?? ''];

        // Show a task only when the parent heading is not folded
        // If the task doesn't have any parent heading, it will also be displayed.
        return !parentHeading?.folded;
      }

      if (node.type === NodeType.Widget) {
        const parentNode = normalizedData.nodes.byId[node.parentId ?? ''];
        const taskNodeInfo = value[parentNode?.id ?? ''];
        const headingNodeInfo = value[parentNode?.parentId ?? ''];

        // Only show widgets when the parent task node is unfolded and the
        // tasks' heading node is also unfolded.
        return !headingNodeInfo?.folded && !taskNodeInfo?.folded;
      }

      return true;
    });
  }, [nodes, isSearching, normalizedData.nodes.byId, value]);

  const handleCheck = (selectedNode: Node, e: React.ChangeEvent<HTMLInputElement>) => {
    const newNodeInfoMap = produce(value, draft => {
      SelectorHelper.changeSelection(draft, nodes, selectedNode, e.target.checked, TaskVisibility.HIDE);
    });

    helpers.setValue(newNodeInfoMap);
  };

  const handleFold = (node: Node) => {
    const nodeInfo = value[node.id];

    helpers.setValue({
      ...value,
      [node.id]: {
        ...nodeInfo,
        folded: !nodeInfo.folded,
      },
    });
  };

  return (
    <UnorderedList
      listStyleType="none"
      h={`calc(100vh - ${EMPTY_VERTICAL_SPACE}px)`}
      w={`calc(100% - ${LIST_LEFT_MARGIN}px)`}
    >
      <ReactVirtualizedAutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <FixedSizeList
            itemData={{
              nodes: visibleNodes,
              nodeInfoMap: value,
              normalizedData,
              onCheck: handleCheck,
              onFold: handleFold,
              isSearching,
            }}
            itemCount={visibleNodes.length}
            height={height}
            width={width}
            itemSize={25}
          >
            {Row}
          </FixedSizeList>
        )}
      </ReactVirtualizedAutoSizer>
    </UnorderedList>
  );
};

type RowProps = {
  index: number;
  style: React.CSSProperties;
  data: {
    nodes: Node[];
    nodeInfoMap: NodeInfoMap;
    normalizedData: NormalizedData;
    onCheck: (selectedNode: Node, e: React.ChangeEvent<HTMLInputElement>) => void;
    onFold: (node: Node) => void;
    isSearching: boolean;
  };
};

const Row: React.FC<React.PropsWithChildren<RowProps>> = ({ data, index, style }) => {
  const { nodeInfoMap, isSearching, nodes, normalizedData, onCheck, onFold } = data;
  const node = nodes[index];
  const { isForm } = useTemplateTypeContext();

  if (!node) return null;

  const nodeInfo = nodeInfoMap[node.id];

  const isSelected = nodeInfo?.selected ?? false;
  const isFolded = (isSearching ? false : nodeInfo?.folded) ?? true;
  const isWidget = node.type === NodeType.Widget;
  const parentNode = normalizedData.nodes.byId[node.parentId ?? ''];
  const isFoldable = (node.widgets && node.widgets.length > 0) || node.type === NodeType.Heading;
  const templateTypePaddingOffset = isForm ? 4 : 0;

  return (
    <HStack
      style={style}
      as={ListItem}
      spacing="2"
      key={node.id}
      pl={ConditionalLogicUtils.getLevel(node, parentNode) * 4 - templateTypePaddingOffset}
      mb="2"
    >
      {!isForm && (
        <Text
          fontWeight={isSelected ? 'semibold' : 'normal'}
          fontSize="xs"
          color="gray.400"
          textAlign="right"
          minW="8"
          visibility={isWidget ? 'hidden' : 'visible'}
        >
          {node.position ?? 0}
        </Text>
      )}

      <Checkbox
        size="lg"
        sx={{
          '.chakra-checkbox__control': {
            borderRadius: 'base',
            borderWidth: '1px',
          },
        }}
        borderColor="gray.400"
        onChange={e => onCheck(node, e)}
        isChecked={isSelected}
      >
        <HStack spacing="1">
          <Text ml="1" fontWeight={!isWidget || isSelected ? 'semibold' : 'normal'} fontSize="sm" noOfLines={1}>
            <NodeName node={node} />
          </Text>

          {isFoldable && (
            <Button
              aria-label={nodeInfo?.folded ? `Expand ${node.label}` : `Collapse ${node.label}`}
              p="1"
              onClick={() => onFold(node)}
              variant="ghost"
              w="6"
              h="5"
              minW="0"
            >
              <Icon icon={isFolded ? 'chevron-down' : 'chevron-up'} />
            </Button>
          )}
        </HStack>
      </Checkbox>
    </HStack>
  );
};

const LIST_LEFT_MARGIN = 32;
const EMPTY_VERTICAL_SPACE = 428;
