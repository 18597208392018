import * as React from 'react';
import { DropzoneInputProps } from 'react-dropzone';
import { Box, Icon, Button, ButtonProps, Text } from 'components/design/next';

export const UploadButton: React.FC<
  React.PropsWithChildren<{ dropzoneInputProps: DropzoneInputProps } & ButtonProps>
> = ({ dropzoneInputProps, ...props }) => {
  return (
    <Box>
      <input aria-label="file-upload" {...dropzoneInputProps} />
      <Button
        color="gray.600"
        variant="ghost"
        size="sm"
        leftIcon={<Icon size="4" variant="far" icon="paperclip" color="brand.500" />}
        {...props}
      >
        <Text color="brand.500" fontWeight="normal">
          Add attachment
        </Text>
      </Button>
    </Box>
  );
};
