import angular from 'angular';

angular.module('frontStreetApp.directives').directive('typeaheadOnFocus', $timeout => ({
  require: ['ngModel'],
  link(__scope, element, __attributes, ctrls) {
    element.on('focus', () => {
      $timeout(() => {
        // Set it to a blank string because the typeahead plugin has a hardcoded minimum of 1
        ctrls[0].$setViewValue('');
        ctrls[0].$setViewValue(element.val() || ' ');
      });
    });
    element.on('keyup', () => {
      if (element.val() !== '') {
        return;
      }
      $timeout(() => {
        // Set it to a blank string because the typeahead plugin has a hardcoded minimum of 1
        ctrls[0].$setViewValue(element.val() || ' ');
      });
    });
  },
}));
