import { Organization } from '@process-street/subgrade/core';
import { Folder } from '@process-street/subgrade/process';
import { FolderPickerApi } from 'components/folder-picker/folder-picker-api';
import { useQuery, UseQueryOptions } from 'react-query';
import { findOrganizationRootFolder } from '../lib';

export type GetAllFoldersByOrganizationIdResult = Folder[];
export type GetAllFoldersByOrganizationIdParams = Organization['id'];

export const GetAllFoldersByOrganizationId = {
  key: 'organizations',
  getKey(selectedOrganizationId: GetAllFoldersByOrganizationIdParams) {
    return [GetAllFoldersByOrganizationId.key, selectedOrganizationId, 'folders'];
  },
  queryFn: FolderPickerApi.getAllFoldersByOrganizationId,
  select: {
    organizationRootFolder: findOrganizationRootFolder,
    currentFolder:
      ({ path, pathType }: { path: string; pathType: 'folder' | 'tag' }) =>
      (folders: GetAllFoldersByOrganizationIdResult): Folder => {
        const segments = path.split('/');
        const head = folders.find(f => f.slug === segments[segments.length - 1]);
        // home should always be there
        const organizationRootFolder = folders.find(f => !f.parentFolder)!;
        return pathType === 'folder' ? head ?? organizationRootFolder : organizationRootFolder;
      },
  },
};

export const useAllFoldersByOrganizationQuery = <Select = GetAllFoldersByOrganizationIdResult>(
  orgId: GetAllFoldersByOrganizationIdParams,
  options: UseQueryOptions<GetAllFoldersByOrganizationIdResult, Error, Select> = {},
) => {
  return useQuery<GetAllFoldersByOrganizationIdResult, Error, Select>(
    GetAllFoldersByOrganizationId.getKey(orgId!),
    () => GetAllFoldersByOrganizationId.queryFn(orgId!),
    {
      ...options,
      enabled: Boolean(orgId) && (options.enabled ?? true),
    },
  );
};
