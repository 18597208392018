import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';
import { FormResponseActor } from 'app/pages/responses/_id/components/form-response-body/form-response-machine';

export const [useFormResponseActorRef, FormResponseActorRefContext] = createUsableContext<FormResponseActor>({
  hookName: 'useFormResponseActorRef',
  providerName: 'FormResponseActorRefProvider',
});

export const FormResponseActorRefProvider: React.FC<React.PropsWithChildren<{ actor?: FormResponseActor }>> = ({
  children,
  actor,
}) => {
  return (
    <FormResponseActorRefContext.Provider value={actor}>{actor ? children : null}</FormResponseActorRefContext.Provider>
  );
};
