import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { StartHere } from '.';

export const StartHereModule = angular
  .module(
    'startHere',
    [], // dependencies
  )
  .component(
    'psStartHere',
    reactToAngular(
      StartHere,
      [], // props
    ),
  ).name;
