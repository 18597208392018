import * as React from 'react';
import { Button, Skeleton } from 'components/design/next';
import { ConditionalLogicButtonWrapper } from 'pages/templates/_id/components/conditional-logic-button-wrapper';
import { useTemplateRevisionQuery } from 'features/template-revisions/query-builder';
import { match } from 'ts-pattern';

type ModalTriggerProps = {
  templateRevisionId: string;
};

export const ModalTrigger: React.FC<React.PropsWithChildren<ModalTriggerProps>> = ({ templateRevisionId }) => {
  const templateRevisionQuery = useTemplateRevisionQuery({ templateRevisionId });
  const btn = (
    <Button
      variant="ghost"
      leftIcon={<i className="far fa-random fa-fw" />}
      bgColor="white"
      fontWeight="normal"
      borderColor="gray.300"
      borderWidth="1px"
      borderStyle="solid"
      borderRadius="4px"
    >
      Conditional Logic
    </Button>
  );
  return match(templateRevisionQuery)
    .with({ status: 'loading' }, () => <Skeleton>{btn}</Skeleton>)
    .with({ status: 'success' }, ({ data }) => (
      <ConditionalLogicButtonWrapper templateRevisionId={data.id}>{btn}</ConditionalLogicButtonWrapper>
    ))
    .otherwise(() => null);
};
