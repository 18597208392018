import { IncomingWebhookSamplePayload } from '@process-street/subgrade/automation';
import { Muid } from '@process-street/subgrade/core';
import { HttpStatus } from '@process-street/subgrade/util';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetIncomingWebhookJsonPathsQueryParams = {
  webhookId: Muid;
};

export type GetIncomingWebhookJsonPathsQueryResponse = IncomingWebhookSamplePayload | undefined;

export const GetIncomingWebhookJsonPathsQuery = {
  key: ['incoming-webhooks', 'json-paths'],
  getKey: (params: GetIncomingWebhookJsonPathsQueryParams): QueryKey => [
    ...GetIncomingWebhookJsonPathsQuery.key,
    params,
  ],
  queryFn: ({ webhookId }: GetIncomingWebhookJsonPathsQueryParams) =>
    axiosService
      .getAxios()
      .get<GetIncomingWebhookJsonPathsQueryResponse>(`/1/incoming-webhooks/${webhookId}/paths`, {
        validateStatus: status => status === HttpStatus.OK || status === HttpStatus.NOT_FOUND,
      })
      .then(res => {
        if (res.status === HttpStatus.NOT_FOUND) {
          return undefined;
        }
        return res.data;
      }),
};

export const useGetIncomingWebhookJsonPathsQuery = <Select = GetIncomingWebhookJsonPathsQueryResponse>(
  params: GetIncomingWebhookJsonPathsQueryParams,
  options: UseQueryOptions<GetIncomingWebhookJsonPathsQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetIncomingWebhookJsonPathsQuery.getKey(params),
    () => GetIncomingWebhookJsonPathsQuery.queryFn(params),
    options,
  );
};
