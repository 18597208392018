import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Text,
  UnorderedList,
  ListItem,
} from 'components/design/next';
import * as React from 'react';
import { useQuery } from 'react-query';
import { Muid } from '@process-street/subgrade/core';
import { OrganizationMembershipUsageStats } from 'pages/organizations/manage/users/models/organization-membership-usage-stats';
import { UsageStatsApi } from 'pages/organizations/manage/users/services/usage-stats.api';
import { useRoleNames } from 'hooks/use-role-names';
import { isT5KPlan } from 'services/plans';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

export type DowngradeDialogProps = {
  organizationMembershipId: Muid;
  username: string;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const DowngradeDialog: React.FC<React.PropsWithChildren<DowngradeDialogProps>> = ({
  isOpen,
  username,
  onConfirm,
  onClose,
  organizationMembershipId,
}) => {
  const cancelRef = React.useRef(null);
  const roleNames = useRoleNames();

  const statsQuery = useQuery<OrganizationMembershipUsageStats, Error>(
    ['organization-usage-stats', organizationMembershipId],
    () => UsageStatsApi.getOrganizationMembershipUsageStats(organizationMembershipId),
    {
      enabled: isOpen,
    },
  );

  const plan = useSelector(SessionSelector.getCurrentPlan);
  const planIsT5K = plan && isT5KPlan(plan.id);
  const runningWorkflowsAllowed = planIsT5K;
  const groupAssignmentsAllowed = planIsT5K;
  const workflowPreassignmentsAllowed = planIsT5K;

  const stats = statsQuery.data;
  const groups = stats ? stats.groupsCount : 'all';
  const assignments = stats ? stats.templateAssignmentsCount + stats.futureChecklistsAssignmentsCount : 'all';

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} size={'lg'}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" color="gray.700">
            Demote to {roleNames.FreeMember.single}?
          </AlertDialogHeader>
          <AlertDialogCloseButton mt={2} />
          <AlertDialogBody>
            <Text color="gray.600">
              If you demote {username} to a {roleNames.FreeMember.single}
            </Text>
            <UnorderedList color="gray.600" spacing={1} paddingLeft={2} paddingTop={2}>
              {!groupAssignmentsAllowed && (
                <ListItem>
                  They will be removed from{' '}
                  <Text as="span" fontWeight={'semibold'} lineHeight="inherit">
                    {groups}
                  </Text>{' '}
                  groups
                </ListItem>
              )}

              {!workflowPreassignmentsAllowed && (
                <ListItem>
                  They will be removed from{' '}
                  <Text as="span" fontWeight={'semibold'} lineHeight="inherit">
                    {assignments}
                  </Text>{' '}
                  workflow assignments
                </ListItem>
              )}

              {!runningWorkflowsAllowed && <ListItem>They won't be able to run workflows</ListItem>}

              <ListItem>They won't be able to create or edit workflows</ListItem>
              <ListItem>They won't be able to schedule workflows</ListItem>
            </UnorderedList>

            {(!groupAssignmentsAllowed || !workflowPreassignmentsAllowed) && (
              <Text color="gray.600" mt={4} fontWeight="medium">
                These changes cannot be recovered.
              </Text>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <ButtonGroup spacing={3}>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={onConfirm}>
                Demote {username}
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
