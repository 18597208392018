import * as React from 'react';
import { EmbedWidget } from '@process-street/subgrade/process/widget-model';
import { PagesRenderElementProps, PagesWidgetElement, usePagesEditorRef } from '../../pages-plate-types';
import { Box, Icon, Text, VStack, Input, InputLeftElement, InputGroup } from 'components/design/next';
import { EditorVoid } from '../elements/void';
import { useIsEditable } from 'features/rich-text';
import { useDebouncedCallback } from 'use-debounce';
import { useMutation } from 'react-query';
import * as WidgetApi from 'pages/pages/_id/edit/page/utils/widget.api';
import { EmbedUI } from './components/embed-ui';
import { EmbedUtils } from '@process-street/subgrade/util';
import { PSEditor } from '../../utils/ps-editor';
import { DomainLink } from './components/domain-link';

export const EmbedElement: React.FC<
  React.PropsWithChildren<PagesRenderElementProps<PagesWidgetElement<EmbedWidget>>>
> = ({ attributes, element, children }) => {
  const { widget } = element;
  const editor = usePagesEditorRef();
  const editable = useIsEditable();

  const [value, setValue] = React.useState(widget.url || '');

  const updateWidgetMutation = useMutation((updatedWidget: EmbedWidget) => WidgetApi.updateWidget(updatedWidget));

  const updatedWidgetDebounced = useDebouncedCallback(async (url: string) => {
    const updatedWidget = await updateWidgetMutation.mutateAsync({
      ...widget,
      url,
    });

    PSEditor.setWidgetElement(editor, updatedWidget);
  }, 500);

  const [errorMessage, setErrorMessage] = React.useState<React.ReactElement | null>(null);
  const handleChangeText = React.useCallback(
    async (event: { target: { value: string } }) => {
      const url = EmbedUtils.extractUrl(event.target.value);
      setValue(url);

      if (url !== '' && !EmbedUtils.isUrlHttps(url)) {
        setErrorMessage(
          <>
            Oops! The website URL must start with <b>https://</b>
          </>,
        );
      } else if (url !== '' && !EmbedUtils.isUrlValid(url)) {
        setErrorMessage(<>Oops! That's not a valid website URL.</>);
      } else {
        setErrorMessage(null);

        await updatedWidgetDebounced(url);
      }
    },
    [updatedWidgetDebounced],
  );

  return (
    <EditorVoid attributes={attributes} contentEditable={false}>
      {editable && (
        <VStack bg={'gray.100'} p={4} borderRadius="md" spacing={3}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon size="4" icon="code" variant="far" color="gray.600" />}
            />
            <Input
              value={value}
              variant="outline"
              placeholder="Paste website embed URL here"
              px={3}
              py={4}
              bg="white"
              onChange={handleChangeText}
            />
          </InputGroup>
          {widget.url && (
            <>
              <EmbedUI url={widget.url} editable={editable} />
              <Box width="100%" alignContent="left">
                <DomainLink href={widget.url} />
              </Box>
            </>
          )}
        </VStack>
      )}
      {!editable && widget.url && (
        <VStack borderRadius="md" bg={'gray.100'} spacing={0}>
          <EmbedUI url={widget.url} editable={editable} />
          <Box width="100%" alignContent="left" p={4}>
            <DomainLink href={widget.url} />
          </Box>
        </VStack>
      )}
      {errorMessage ? <Text color="red.500">{errorMessage}</Text> : undefined}
      {children}
    </EditorVoid>
  );
};
