import { OneOffTask } from '@process-street/subgrade/one-off-task';
import * as React from 'react';
import { useOneOffTaskDrawerStore } from '../one-off-task-drawer-store';
import isEqual from 'lodash/isEqual';

export const useRefreshDrawerTask = (task: OneOffTask) => {
  const { viewTask, isOpen, task: viewedTask } = useOneOffTaskDrawerStore();

  // The viewedTask (task in the store) is set, but when the original task is updated (task via props)
  // we need to update the task viewd on the store.
  React.useEffect(
    function updateViewedTaskOnChange() {
      if (isOpen && !isEqual(task, viewedTask) && task.id === viewedTask?.id) {
        viewTask({ task });
      }
    },
    [isOpen, task, viewTask, viewedTask],
  );
};
