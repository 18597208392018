import { Muid } from '@process-street/subgrade/core';
import { ChecklistGridData, ChecklistGridSearchRequest } from '@process-street/subgrade/dashboard';
import {} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetFormResponsesSearchQueryParams = {
  organizationId?: Muid;
  searchCriteria: Omit<ChecklistGridSearchRequest, 'search'>;
};

export type GetFormResponsesSearchQueryResponse = ChecklistGridData[];

const key = ['organizations', 'checklist-grid', 'search'];
export const GetFormResponsesSearchQuery = {
  key,
  getKey: (params: GetFormResponsesSearchQueryParams) => [...key, params],
  queryFn: ({ organizationId, searchCriteria }: GetFormResponsesSearchQueryParams) =>
    axiosService
      .getAxios()
      .post<GetFormResponsesSearchQueryResponse>(
        `/1/organizations/${organizationId}/checklist-grid/search`,
        searchCriteria,
      )
      .then(res => res.data),
};

export const useGetFormResponsesSearchQuery = <Select = GetFormResponsesSearchQueryResponse>(
  params: GetFormResponsesSearchQueryParams,
  options: UseQueryOptions<GetFormResponsesSearchQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetFormResponsesSearchQuery.getKey(params), () => GetFormResponsesSearchQuery.queryFn(params), {
    enabled: Boolean(params.organizationId) && options.enabled !== false,
  });
};
