import { DataSetPermit } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpsertDataSetPermissionMutationParams = {
  dataSetId: string;
  organizationMembershipId: string;
  dataSetUpdate: boolean;
  dataSetRead: boolean;
};

export type UpsertDataSetPermissionMutationResponse = DataSetPermit;

export const UpsertDataSetPermitMutation = {
  key: ['upsert', 'data-set-permits'],
  getKey() {
    return [...UpsertDataSetPermitMutation.key];
  },
  mutationFn: (params: UpsertDataSetPermissionMutationParams) =>
    axiosService
      .getAxios()
      .post<UpsertDataSetPermissionMutationResponse>(`/1/data-sets/${params.dataSetId}/permits`, params)
      .then(res => res.data),
};

export const useUpsertDataSetPermitMutation = (
  options: UseMutationOptions<
    UpsertDataSetPermissionMutationResponse,
    AxiosError,
    UpsertDataSetPermissionMutationParams
  >,
) => {
  return useMutation(UpsertDataSetPermitMutation.getKey(), UpsertDataSetPermitMutation.mutationFn, options);
};
