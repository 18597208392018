import { Muid } from '@process-street/subgrade/core';
import { ValueType } from 'app/pages/responses/_id/components/form-fields/select-form-field';
import * as yup from 'yup';

type ValidationSchemaProps = {
  required: boolean;
};

export const makeSelectValidationSchema = ({ required }: ValidationSchemaProps) => {
  return yup
    .array(
      yup.object().shape({
        value: yup.string().optional(),
        dataSetRowId: yup.string().optional(),
      }) as yup.Schema<{ value: string; dataSetRowId?: Muid }>,
    )
    .optional()
    .test('required select', 'Required', values => {
      if (required) {
        if (!values || values.length === 0) {
          return false;
        }
        // Ensure that at least one object in the array has a non-empty `value`
        const hasValidValue = values.some(item => item && item.value && item.value.trim() !== '');
        return hasValidValue;
      }
      return true;
    }) as yup.Schema<ValueType | undefined>;
};
