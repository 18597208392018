import angular from 'angular';
import { ChecklistMigrationModalComponent } from 'components/migration/checklist/checklist-migration-modal.component';
import { ChecklistMigrationServiceImpl } from 'components/migration/services/checklist-migration-service.impl';
import { TemplateMigrationServiceImpl } from 'components/migration/services/template-migration-service.impl';
import { ChecklistMigrationActions } from 'components/migration/store/checklist-migration.actions';
import { TemplateMigrationActionsImpl } from 'components/migration/store/template-migration.actions';
import { TemplateMigrationApiImpl } from 'components/migration/store/template-migration.api';
import { TemplateMigrationModalComponent } from 'components/migration/template/template-migration-modal.component';
import { TemplateMigrationProgressComponent } from 'components/migration/template/template-migration-progress.component';

export const MigrationModule = angular
  .module('migration', [])
  .component('psTemplateMigrationModal', TemplateMigrationModalComponent)
  .component('psChecklistMigrationModal', ChecklistMigrationModalComponent)
  .component('psTemplateMigrationProgress', TemplateMigrationProgressComponent)
  .service('ChecklistMigrationActions', ChecklistMigrationActions)
  .service('TemplateMigrationService', TemplateMigrationServiceImpl)
  .service('ChecklistMigrationService', ChecklistMigrationServiceImpl)
  .service('TemplateMigrationApi', TemplateMigrationApiImpl)
  .service('TemplateMigrationActions', TemplateMigrationActionsImpl).name;
