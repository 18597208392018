import { GROUP_DELETE, GROUP_USER_ADD, GROUP_USER_REMOVE } from 'reducers/group/group.actions';
import {
  GROUP_MEMBERSHIP_GET_ALL_BY_ORGANIZATION_ID,
  GROUP_MEMBERSHIP_QUERY,
  GROUP_MEMBERSHIP_QUERY_BY_GROUP,
  GROUP_MEMBERSHIP_QUERY_BY_USER,
  GROUP_MEMBERSHIP_REMOVE_FROM_ALL_GROUPS,
} from 'reducers/group-membership/group-membership.actions';
import { composeReducerObjects } from 'reducers/util';
import { cascadeCleanOnDeleteReducerObject, referencesNormalizer } from 'reducers/entities/entities.utils';
import { toEntityMap } from '@process-street/subgrade/redux/reducer-utils';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { ORGANIZATION_MEMBERSHIP_DELETE_BY_ORGANIZATION_ID_AND_USER_ID } from 'reducers/organization-membership/organization-membership.actions';

const normalizeGroupMembership = referencesNormalizer(['organization', 'organizationMembership', 'group']);

/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "state" }]*/
const groupMembershipReducerObject = {
  [GROUP_MEMBERSHIP_QUERY]: (state, { payload }) => toEntityMap(payload, normalizeGroupMembership),
  [GROUP_MEMBERSHIP_QUERY_BY_GROUP]: (state, { payload }) =>
    EntitiesReducerUtils.upsertAll(state, payload, normalizeGroupMembership),
  [GROUP_MEMBERSHIP_GET_ALL_BY_ORGANIZATION_ID]: (state, { payload }) =>
    EntitiesReducerUtils.upsertAll(state, payload, normalizeGroupMembership),
  [GROUP_MEMBERSHIP_QUERY_BY_USER]: (state, { payload }) =>
    EntitiesReducerUtils.upsertAll(state, payload, normalizeGroupMembership),
  [GROUP_MEMBERSHIP_REMOVE_FROM_ALL_GROUPS]: (state, { payload: { membership: orgMem } }) => {
    const filtered = Object.values(state).filter(groupMem => groupMem.organizationMembership.id !== orgMem.id);
    return EntitiesReducerUtils.upsertAll({}, filtered, normalizeGroupMembership);
  },
  [GROUP_USER_ADD]: (state, { payload: membership }) =>
    EntitiesReducerUtils.upsert(state, membership, normalizeGroupMembership),
  [GROUP_USER_REMOVE]: (state, { meta: { group, membership } }) => {
    const filtered = Object.values(state).filter(
      mem => !(mem.group.id === group.id && mem.organizationMembership.id === membership.id),
    );
    return EntitiesReducerUtils.upsertAll({}, filtered, normalizeGroupMembership);
  },
  [ORGANIZATION_MEMBERSHIP_DELETE_BY_ORGANIZATION_ID_AND_USER_ID]: (state, { meta: { membership } }) => {
    if (!membership) {
      return state;
    }
    const relatedGroupMemberships = Object.values(state).filter(
      groupMembership => groupMembership.organizationMembership.id === membership.id,
    );
    return EntitiesReducerUtils.deleteAll(state, relatedGroupMemberships);
  },
};

export const groupMembershipEntitiesReducer = composeReducerObjects(
  groupMembershipReducerObject,

  cascadeCleanOnDeleteReducerObject(GROUP_DELETE, 'group.id', 'id'),
);
