import * as React from 'react';
import { Folder, isFolder, isTemplate, Template, TemplateStatus } from '@process-street/subgrade/process';
import {
  GetAllTemplatesQuery,
  useDeleteTemplatesMutation,
  useMoveTemplatesMutation,
  useUpdateTemplatesStatusMutation,
} from 'features/template/query-builder';
import { useLibraryContext } from 'pages/library/context';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { match } from 'ts-pattern';
import { useTemplateLibrarySelectionSlice } from '../selection-store';
import { useUnmount } from 'react-use';
import { SelectionBar } from 'components/selection-bar-actions';
import { useCurrentOrPrivateRootFolderQuery } from 'features/new-menu/query';
import { Box, Button, Icon, useToast } from 'components/design/next';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import {
  GetAllFoldersByOrganizationId,
  useDeleteFoldersMutation,
  useMoveFoldersMutation,
} from 'features/folder/query-builder';
import { FolderPickerModal } from 'components/folder-picker/modal';
import { FolderErrorCodes } from '@process-street/subgrade/core';
import { isAxiosError } from '@process-street/subgrade/api';

export const TemplateLibrarySelectionBarActionsWrapper = () => {
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const { selectedIds, selectedItemsMap, deselectAll } = useTemplateLibrarySelectionSlice();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { templateStatusView } = useLibraryContext();

  const deleteTemplatesMutation = useDeleteTemplatesMutation({
    onSuccess: (_, variables) => {
      const deletedTemplateIds = new Set(variables.templateIds);

      // Remove deleted templates from cache
      queryClient.setQueryData<Template[]>(
        GetAllTemplatesQuery.getKey({
          organizationId,
          templateStatus: templateStatusView === 'active' ? TemplateStatus.Active : TemplateStatus.Archived,
        }),
        templates => templates?.filter(template => !deletedTemplateIds.has(template.id)) ?? [],
      );
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems deleting 1 or more templates",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const deleteFoldersMutation = useDeleteFoldersMutation({
    onSuccess: (_, variables) => {
      if (!organizationId) return;

      const deletedFolderIds = new Set(variables.folderIds);

      // Remove deleted folders from cache
      queryClient.setQueryData<Folder[]>(
        GetAllFoldersByOrganizationId.getKey(organizationId),
        folders => folders?.filter(folder => !deletedFolderIds.has(folder.id)) ?? [],
      );
    },
  });

  const archiveTemplatesMutation = useUpdateTemplatesStatusMutation({
    onSuccess: () => {
      toast({
        status: 'success',
        title: `${selectedIds.size} workflow${selectedIds.size > 1 ? 's' : ''} have been archived.`,
      });

      deselectAll();
      queryClient.invalidateQueries(
        GetAllTemplatesQuery.getKey({
          organizationId,
          templateStatus: templateStatusView === 'active' ? TemplateStatus.Active : TemplateStatus.Archived,
        }),
      );
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems archiving 1 or more templates",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const moveTemplatesMutation = useMoveTemplatesMutation({
    onSuccess: (_, variables) => {
      const movedTemplateIds = new Set(variables.candidateIds);

      // Update moved templates' folder
      queryClient.setQueryData<Template[]>(
        GetAllTemplatesQuery.getKey({
          organizationId,
          templateStatus: templateStatusView === 'active' ? TemplateStatus.Active : TemplateStatus.Archived,
        }),
        templates =>
          templates?.map(template => {
            if (!movedTemplateIds.has(template.id)) return template;

            return {
              ...template,
              folder: {
                id: variables.toFolderId,
              },
            };
          }) ?? [],
      );
    },
  });

  const moveFoldersMutation = useMoveFoldersMutation({
    onSuccess: (_, variables) => {
      if (!organizationId) return;

      const movedFolderIds = new Set(variables.candidateIds);

      // Update moved folders' parentFolder
      queryClient.setQueryData<Folder[]>(
        GetAllFoldersByOrganizationId.getKey(organizationId),
        folders =>
          folders?.map(folder => {
            if (!movedFolderIds.has(folder.id)) return folder;

            return {
              ...folder,
              parentFolder: {
                id: variables.toFolderId,
              },
            };
          }) ?? [],
      );
    },
  });

  const { data: currentFolder } = useCurrentOrPrivateRootFolderQuery();

  const handleDelete = async (items: (Template | Folder)[]) => {
    const templateIds = items.filter(isTemplate).map(template => template.id);
    const folderIds = items.filter(isFolder).map(folder => folder.id);

    try {
      await Promise.all([
        await deleteTemplatesMutation.mutateAsync({ templateIds }),
        await deleteFoldersMutation.mutateAsync({ folderIds }),
      ]);

      toast({
        status: 'success',
        title: `${selectedIds.size} item${selectedIds.size > 1 ? 's' : ''} have been deleted.`,
      });

      deselectAll();
    } catch (e: unknown) {
      if (!isAxiosError(e)) throw e;
      const isFolderDeleteWithChildrenError = e?.response?.data?.code === FolderErrorCodes.FolderContainsChildrenError;
      const title = isFolderDeleteWithChildrenError
        ? 'Error deleting folder'
        : "We're having problems deleting 1 or more items";
      const description = isFolderDeleteWithChildrenError
        ? 'You cannot delete this folder until all folders and all workflows in it ' +
          '(including archived workflows) have been moved or deleted.'
        : DefaultErrorMessages.unexpectedErrorDescription;
      toast({
        status: 'error',
        title,
        description,
      });
    }
  };
  const handleArchive = async (items: (Template | Folder)[]) => {
    const templateIds = items.filter(isTemplate).map(template => template.id);

    return archiveTemplatesMutation.mutateAsync({ templateIds, status: TemplateStatus.Archived });
  };

  const handleMove = async (items: (Template | Folder)[], folder: Folder) => {
    const templateIds = items.filter(isTemplate).map(template => template.id);
    const folderIds = items
      .filter(isFolder)
      .filter(f => f.id !== folder.id)
      .map(folder => folder.id);

    try {
      await Promise.all([
        moveTemplatesMutation.mutateAsync({
          candidateIds: templateIds,
          toFolderId: folder.id,
        }),
        moveFoldersMutation.mutateAsync({
          candidateIds: folderIds,
          toFolderId: folder.id,
        }),
      ]);

      toast({
        status: 'success',
        title: `${selectedIds.size} item${selectedIds.size > 1 ? 's' : ''} have been moved.`,
      });

      deselectAll();
    } catch (e) {
      toast({
        status: 'error',
        title: `We're having problems moving 1 or more items`,
      });
    }
  };

  const stats = React.useMemo(() => {
    const data = { activeCount: 0, archivedCount: 0 };

    selectedItemsMap.forEach(item => {
      match(item as Folder | Template)
        .with({ status: TemplateStatus.Active }, () => {
          data.activeCount++;
        })
        .with({ status: TemplateStatus.Archived }, () => {
          data.archivedCount++;
        })
        .otherwise(() => {});
    });

    return data;
  }, [selectedItemsMap]);

  useUnmount(() => {
    deselectAll();
  });

  const hasSomeFolderSelected = React.useMemo(
    () => Array.from(selectedItemsMap.values()).some(item => 'folderType' in item),
    [selectedItemsMap],
  );
  const selectedItems = Array.from(selectedItemsMap.values()) as (Template | Folder)[];

  React.useEffect(() => {
    if (currentFolder) {
      deselectAll();
    }
  }, [currentFolder, deselectAll]);

  return (
    <SelectionBar.Actions<Template | Folder> selectedItems={selectedItems}>
      {stats.activeCount > 0 && !hasSomeFolderSelected && (
        <SelectionBar.Button
          confirmationBody={`Your workflow${selectedIds.size > 1 ? 's' : ''} and all of their runs will be archived.`}
          confirmationTitle={`Archive ${selectedIds.size} item${selectedIds.size > 1 ? 's' : ''}`}
          confirmationButtonText="Archive"
          isLoading={archiveTemplatesMutation.isLoading}
          onConfirm={handleArchive}
          leftIcon={<Icon icon="archive" size="4" />}
          minW={30}
        >
          Archive{' '}
          <Box as="span" display={{ base: 'none', md: 'block' }} ml="1">
            {stats.activeCount > 0 ? `(${stats.activeCount})` : null}
          </Box>
        </SelectionBar.Button>
      )}

      <SelectionBar.Button
        isLoading={moveTemplatesMutation.isLoading}
        onConfirm={() => Promise.resolve({})}
        leftIcon={<Icon icon="arrow-right" size="4" />}
        minW={30}
        modal={props =>
          currentFolder?.id ? (
            <FolderPickerModal
              candidateId={selectedItems[0]?.id}
              candidateType={'template'}
              initialFolderId={currentFolder?.id}
              title="Move to..."
              verb="move"
              isOpen={props.isOpen}
              onPick={async folder => {
                await handleMove(selectedItems, folder);
                props.onClose();
              }}
              onClose={props.onClose}
              pickButton={({ pickType: _, ...props }) => (
                <Button {...props} isLoading={moveTemplatesMutation.isLoading}>
                  Move
                </Button>
              )}
            />
          ) : null
        }
      >
        Move
      </SelectionBar.Button>

      <SelectionBar.Button
        confirmationBody={`Your workflow${
          selectedIds.size > 1 ? 's' : ''
        } and all of their runs will be deleted and can’t be recovered.`}
        confirmationTitle={`Delete ${selectedIds.size} item${selectedIds.size > 1 ? 's' : ''}`}
        confirmationButtonText="Delete"
        isLoading={deleteTemplatesMutation.isLoading}
        onConfirm={handleDelete}
        leftIcon={<Icon icon="trash" size="4" />}
        minW={30}
        color="red.400"
      >
        Delete
      </SelectionBar.Button>
    </SelectionBar.Actions>
  );
};
