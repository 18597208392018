import angular from 'angular';

import { AddFormResponsePageModule } from 'pages/forms/_id/respond/page.module';
import { FormResponsePageModule } from 'pages/responses/_id/form-response-page.module';
import { FormResponseFinishPageModule } from 'pages/responses/_id/finish/page.module';
import { FormsEditPageModule } from 'pages/forms/_id/edit/editor.module';
import { FormSettingsPageModule } from 'pages/forms/_id/settings/settings.module';
import { FormResponsesPageModule } from './responses/form-responses-page.module';
import { FormLoginPageModule } from 'pages/forms/_id/login/page.module';

export const FormsPageModule = angular.module('formsPage', [
  AddFormResponsePageModule,
  FormsEditPageModule,
  FormLoginPageModule,
  FormResponsePageModule,
  FormResponseFinishPageModule,
  FormSettingsPageModule,
  FormResponsesPageModule,
]).name;
