import * as React from 'react';
import { Box, Text, VStack } from 'components/design/next';
import {
  AutomationAppType,
  DataSetSolutionTypeTagDescription,
  IncomingWebhook,
  IncomingWebhookOptimistic,
  SolutionTypeTag,
} from '@process-street/subgrade/automation';
import { AutomationType } from './components/type';
import { useAutomationSelector } from './components/selector/context';
import { useDataSetId } from './utils/use-data-set-id';
import { AutomationListItem } from './components/list-item';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import { match } from 'ts-pattern';

type DataSetAutomationsSelectorProps = {
  webhook: IncomingWebhook | IncomingWebhookOptimistic;
};

export const DataSetAutomationsSelector: React.FC<React.PropsWithChildren<DataSetAutomationsSelectorProps>> = ({
  webhook,
}) => {
  const [state, send] = useAutomationSelector();
  const dataSetId = useDataSetId();

  const nativeAutomations = GetAllNativeAutomationsQuery.useQuery(
    {
      dataSetId,
    },
    {
      select: automations => automations.filter(({ automation }) => automation.status !== 'Deleted'),
    },
  );

  return (
    <VStack as="nav" spacing="6" px="6" w="full" bgColor="blue.50" py="8">
      {visibleSolutionTypeTags.map(solutionTypeTag => {
        const selected = match(solutionTypeTag)
          .with(SolutionTypeTag.WhenChecklistCompletedThen, () => state.context.solutionTypeTag === solutionTypeTag)
          .otherwise(() => state.matches('creating') && state.context.solutionTypeTag === solutionTypeTag);

        return (
          <VStack key={solutionTypeTag} w="full">
            <Box w="full">
              <AutomationType
                title={
                  DataSetSolutionTypeTagDescription[solutionTypeTag as keyof typeof DataSetSolutionTypeTagDescription]
                }
                type={solutionTypeTag}
                selected={selected}
                rightIcon={
                  solutionTypeTag === SolutionTypeTag.WhenChecklistCompletedThen ? (
                    <Text color={selected ? 'white' : 'gray.800'}>{webhook.status === 'Active' ? 'On' : 'Off'}</Text>
                  ) : undefined
                }
              />

              {solutionTypeTag === SolutionTypeTag.CreateChecklistWhen &&
                nativeAutomations.data?.map(({ automation }) => {
                  const handleAutomationSelected: React.MouseEventHandler = () =>
                    send({
                      type: 'AUTOMATION_SELECTED',
                      payload: {
                        id: automation.id,
                        automationType: 'native' as AutomationAppType,
                        solutionTypeTag: SolutionTypeTag.CreateChecklistWhen,
                      },
                    });

                  return (
                    <AutomationListItem
                      key={automation.id}
                      templateSolutionInstanceTypeTag={SolutionTypeTag.CreateChecklistWhen}
                      onClick={handleAutomationSelected}
                      status={automation.status === 'Active' ? 'on' : 'off'}
                      description={TriggerLabelMap[automation.trigger.triggerType as keyof typeof TriggerLabelMap]}
                      selected={state.matches('editing') && state.context.automationInstanceId === automation.id}
                    />
                  );
                })}
            </Box>
          </VStack>
        );
      })}
    </VStack>
  );
};

const visibleSolutionTypeTags = [SolutionTypeTag.WhenChecklistCompletedThen, SolutionTypeTag.CreateChecklistWhen];

const TriggerLabelMap = {
  DataSetRowCreated: 'Data Set record is created',
  DataSetRowUpdated: 'Data Set record is updated',
  DataSetRowChanged: 'Data Set record matches a condition',
};
