import { Group, Muid, Organization, OrganizationMembershipRole, Ref, User } from '../core';
import { TaskTemplatePermissionLevel, TemplatePermit } from '../permission';
import {
  Attachment,
  AttachmentStatus,
  Checklist,
  ChecklistRevision,
  ChecklistRevisionStatus,
  ChecklistStatus,
  Comment,
  CommentStatus,
  Folder,
  FormFieldValue,
  MultiSelectItemValueStatus,
  Task,
  TaskTemplate,
  Template,
  TemplateRevision,
  TemplateRevisionStatus,
  TemplateStatus,
  TemplateType,
} from '../process';
import { Tag, TagMembership } from '../process/tag-model';
import { ChecklistAssignment, TaskAssignment } from '../role-assignment';

export enum ActivityVerb {
  Created = 'Created',
  Copied = 'Copied',
  Updated = 'Updated',
  Deleted = 'Deleted',
  Attached = 'Attached',
  Detached = 'Detached',
  Restored = 'Restored',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum ActivityObjectType {
  Attachment = 'Attachment',
  Checklist = 'Checklist',
  ChecklistAssignment = 'ChecklistAssignment',
  ChecklistRevision = 'ChecklistRevision',
  ChecklistTaskAssignment = 'ChecklistTaskAssignment',
  Comment = 'Comment',
  Folder = 'Folder',
  FormFieldValue = 'FormFieldValue',
  Group = 'Group',
  GroupMembership = 'GroupMembership',
  ManyChecklistTaskAssignments = 'ManyChecklistTaskAssignments',
  Masked = 'Masked',
  OrganizationMembership = 'OrganizationMembership',
  Tag = 'Tag',
  TagMembership = 'TagMembership',
  Task = 'Task',
  Tasks = 'Tasks',
  Team = 'Team',
  Template = 'Template',
  TemplatePermit = 'TemplatePermit',
  TemplateRevision = 'TemplateRevision',
  User = 'User',
}

interface BaseActivity<T, D> {
  id: Muid;
  date: number;
  organization: Ref<Organization>;
  subject: Ref<User>;
  verb: ActivityVerb;
  objectType: ActivityObjectType;
  object: Ref<T>;
  data: D;
}

export interface CommentActivityData {
  commentId?: Muid;
  content?: string;
  checklistId: Muid;
  checklistName?: string;
  status: CommentStatus;
  subjectId: Muid;
  subjectName: string;
  taskId: Muid;
  taskTemplateGroupId: Muid;
  taskTemplateName?: string;
  templateId: Muid;
  templateName: string;
  templateType: TemplateType;
  checklistRevisionId: Muid;
}

export interface AttachmentActivityData {
  attachmentId?: Muid;
  checklistId: Muid;
  checklistName?: string;
  status: CommentStatus;
  subjectId: Muid;
  subjectName: string;
  taskId: Muid;
  taskTemplateGroupId: Muid;
  taskTemplateName?: string;
  templateId: Muid;
  templateName: string;
  templateType: TemplateType;
  checklistRevisionId: Muid;
  fileUrl?: string;
  fileOriginalName?: string;
  fileSize?: number;
  fileExtension?: string;
  fileMimeType?: string;
  fileName?: string;
}

export interface CommentCreatedActivity extends BaseActivity<Comment, CommentActivityData> {
  verb: ActivityVerb.Created;
  objectType: ActivityObjectType.Comment;
}

export interface AttachmentCreatedActivity extends BaseActivity<Attachment, AttachmentActivityData> {
  verb: ActivityVerb.Created;
  objectType: ActivityObjectType.Attachment;
}

export interface CommentCreatedActivityResponse extends CommentCreatedActivity {
  object: Ref<
    Comment & {
      task: Task & { taskTemplate: TaskTemplate; checklistRevision: ChecklistRevision & { checklist: Checklist } };
    }
  >;
}

export interface CommentCreatedAcitvityGetResponse {
  data: CommentCreatedActivityResponse[];
  total: number;
}

interface CommentDeletedActivityData extends CommentActivityData {
  status: CommentStatus.Deleted;
}

interface AttachmentDeletedActivityData {
  status: AttachmentStatus.Deleted;
  attachmentId?: Muid;
}

export interface CommentDeletedActivity extends BaseActivity<Comment, CommentDeletedActivityData> {
  verb: ActivityVerb.Updated;
  objectType: ActivityObjectType.Comment;
}

export interface AttachmentDeletedActivity extends BaseActivity<Attachment, AttachmentDeletedActivityData> {
  verb: ActivityVerb.Updated;
  objectType: ActivityObjectType.Attachment;
}

export type CommentActivity = CommentCreatedActivity | CommentDeletedActivity;

export type AttachmentActivity = AttachmentCreatedActivity | AttachmentDeletedActivity;

export interface TagCreatedActivityData {
  name: string;
  oldName?: string;
  newName?: string;
}

export type TagActivityData = TagCreatedActivityData;

export interface TagCreatedActivity extends BaseActivity<Tag, TagCreatedActivityData> {
  verb: ActivityVerb.Created | ActivityVerb.Deleted;
  objectType: ActivityObjectType.Tag;
}

export interface TagCreatedActivityResponse extends TagCreatedActivity {
  object: Tag & {
    template: Template;
  };
}

export interface TagCreatedActivityGetResponse {
  data: TagCreatedActivityResponse[];
  total: number;
}

export type TagActivity = TagCreatedActivity;

export type SubtaskUpdatedActivityData = {
  itemsUpdated: Array<{
    id: Muid;
    name: string;
    newData: { status?: MultiSelectItemValueStatus };
    oldData: { status?: MultiSelectItemValueStatus };
  }>;
};

export interface SubtaskUpdatedActivity extends BaseActivity<FormFieldValue, SubtaskUpdatedActivityData> {
  verb: ActivityVerb.Updated;
  objectType: ActivityObjectType.FormFieldValue;
}

export type Activity =
  | AttachmentActivity
  | CommentActivity
  | TagActivity
  | GroupMembershipActivity
  | GroupActivity
  | UserActivity
  | ChecklistActivity
  | TemplateActivity
  | TemplateRevisionActivity
  | FolderActivity
  | TagMembershipActivity
  | ChecklistAssignmentActivity
  | ChecklistRevisionActivity
  | TemplatePermitActivity
  | ChecklistTaskAssignmentActivity
  | ManyChecklistTaskAssignmentsActivity
  | MaskedActivity
  | SubtaskUpdatedActivity;

export type ActivityOrigin = 'ProcessStreet' | 'Scim';

export interface TemplatePermitActivity extends BaseActivity<TemplatePermit, TemplatePermitActivityData> {
  objectType: ActivityObjectType.TemplatePermit;
}

export interface ChecklistRevisionActivity extends BaseActivity<ChecklistRevision, ChecklistRevisionActivityData> {
  objectType: ActivityObjectType.ChecklistRevision;
}

export interface ChecklistAssignmentActivity
  extends BaseActivity<ChecklistAssignment, ChecklistAssignmentActivityData> {
  objectType: ActivityObjectType.ChecklistAssignment;
}

export interface ChecklistTaskAssignmentActivity
  extends BaseActivity<TaskAssignment, ChecklistTaskAssignmentActivityData> {
  objectType: ActivityObjectType.ChecklistTaskAssignment;
}

export interface TagMembershipActivity extends BaseActivity<TagMembership, TagMembershipActivityData> {
  objectType: ActivityObjectType.TagMembership;
}

export interface FolderActivity extends BaseActivity<Folder, FolderActivityData> {
  objectType: ActivityObjectType.Folder;
}

export interface GroupMembershipActivity extends BaseActivity<Group, GroupMembershipActivityData> {
  objectType: ActivityObjectType.GroupMembership;
}

export interface GroupActivity extends BaseActivity<Group, GroupActivityData> {
  objectType: ActivityObjectType.Group;
}

export interface UserActivity extends BaseActivity<User, UserActivityData> {
  objectType: ActivityObjectType.User;
}

export interface ChecklistActivity extends BaseActivity<Checklist, ChecklistActivityData> {
  objectType: ActivityObjectType.Checklist;
}

export interface MaskedActivity extends BaseActivity<{}, MaskedActivityData> {
  objectType: ActivityObjectType.Masked;
}

export interface TemplateActivity extends BaseActivity<Template, TemplateActivityData> {
  objectType: ActivityObjectType.Template;
}

export interface TemplateRevisionActivity extends BaseActivity<TemplateRevision, TemplateRevisionActivityData> {
  objectType: ActivityObjectType.TemplateRevision;
}

export interface ManyChecklistTaskAssignmentsActivity
  extends BaseActivity<unknown, ManyChecklistTaskAssignmentsActivityData> {
  objectType: ActivityObjectType.ManyChecklistTaskAssignments;
  objects: TaskAssignment[];
}

export interface MaskedActivityData {}

export interface ChecklistActivityData {
  status: ChecklistStatus;
}

export interface TemplateActivityData {
  status: TemplateStatus;
  template: Template;
  public?: boolean;
  newFolder?: Folder;
  oldFolder?: Folder;
}

export interface TemplateRevisionActivityData {
  status: TemplateRevisionStatus;
}

export interface ManyChecklistTaskAssignmentsActivityData {
  checklist: Checklist;
  assignments: ChecklistTaskAssignmentActivityData[];
}

export interface FolderActivityData {
  name: string;
}

export interface TagMembershipActivityData {
  tag: Tag;
}

export interface ChecklistAssignmentActivityData {
  checklist: Checklist;
  user: User;
}

export interface ChecklistTaskAssignmentActivityData {
  taskTemplate: TaskTemplate;
  assignee: User;
  checklist: Checklist;
  task: Task;
}

export interface ChecklistRevisionActivityData {
  status: ChecklistRevisionStatus;
}

export interface TemplatePermitActivityData {
  user: User;
  level: TaskTemplatePermissionLevel;
}

export interface GroupMembershipActivityData {
  groupId: Muid;
  userId: Muid;
  groupName: string;
  userName: string;
  origin: ActivityOrigin;
}

export interface GroupActivityData {
  id: Muid;
  name: string;
  origin: ActivityOrigin;
}

export interface UserActivityData {
  origin: ActivityOrigin;
  role: OrganizationMembershipRole;
  updatedRole?: OrganizationMembershipRole;
  email: string;
  updatedEmail?: string;
  username: string;
  updatedUsername?: string;
}
