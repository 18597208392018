import * as React from 'react';
import { match } from 'ts-pattern';
import {
  List,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from 'components/design/next';
import { Checklist } from '@process-street/subgrade/process';

import { useGetAllActivitiesByTypeQuery } from 'features/activities/query-builder';
import { Activity, ActivityObjectType, AttachmentActivity, CommentActivity } from '@process-street/subgrade/activity';
import { getActiveAttachmentsFromActivityLog, getActiveCommentsFromActivityLog } from 'features/comments/utils';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { OverviewAttachmentItem, OverviewCommentItem } from '../../common';

export type TemplateCommentsOverviewPopoverProps = {
  templateId: Checklist['id'];
  children: React.ReactElement;
};

export const TemplateCommentsOverviewPopover: React.FC<
  React.PropsWithChildren<TemplateCommentsOverviewPopoverProps>
> = ({ templateId, children }) => {
  const { data: currentUser } = useGetCurrentUserInfoQuery({
    select: data => data.user,
  });

  const commentsQuery = useGetAllActivitiesByTypeQuery<Activity[]>(
    {
      type: 'template',
      templateId,
      limit: 100,
      objectType: ActivityObjectType.Comment,
    },
    {
      enabled: Boolean(templateId),
      select: ({ data }) => getActiveCommentsFromActivityLog(data as CommentActivity[]),
    },
  );

  const attachmentsQuery = useGetAllActivitiesByTypeQuery<Activity[]>(
    {
      type: 'template',
      templateId,
      limit: 100,
      objectType: ActivityObjectType.Attachment,
    },
    {
      enabled: Boolean(templateId),
      select: ({ data }) => getActiveAttachmentsFromActivityLog(data as AttachmentActivity[]),
    },
  );

  const allItems = match([commentsQuery, attachmentsQuery])
    .with(
      [{ status: 'success' }, { status: 'success' }],
      ([{ data: comments }, { data: attachments }]) =>
        comments?.concat(attachments ?? []).sort((a, b) => b.date - a.date) ?? [],
    )
    .otherwise(() => []);

  const count = allItems?.length;

  const trigger = React.cloneElement(children, {
    isDisabled: !count,
  });
  return (
    <Popover isLazy placement="bottom-end">
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <Portal>
        <PopoverContent w="md" maxH="xl" overflowY="auto">
          <PopoverHeader px="6" pt="6" color="gray.700" fontWeight="bold">
            Run Comments <Text as="span" color="gray.500" fontWeight="normal">{`(${count})`}</Text>
          </PopoverHeader>
          <PopoverBody px="6" pb="2">
            <List spacing="4" w="full">
              {currentUser &&
                allItems.map(activity => {
                  if ('attachmentId' in activity.data) {
                    return (
                      <OverviewAttachmentItem
                        {...{ key: activity.id, activity, currentUserId: currentUser.id, type: 'template' }}
                      />
                    );
                  }
                  if ('commentId' in activity.data) {
                    return (
                      <OverviewCommentItem
                        {...{
                          key: activity.id,
                          activity,
                          currentUserId: currentUser.id,
                          type: 'template',
                        }}
                      />
                    );
                  }
                  return null;
                })}
            </List>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
