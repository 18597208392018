import angular from 'angular';
import { dayjs as moment, htmlEscaped, StringUtils } from '@process-street/subgrade/util';
import templateUrl from './select-time-zone.component.html';
import { timeZones } from 'utils/timezones';

angular.module('frontStreetApp.directives').directive('psSelectTimeZone', $timeout => {
  const ZONE_PAGE_SIZE = 50;

  return {
    scope: {
      maxSuggestions: '=selectTimeZoneMaxSuggestions',
    },
    restrict: 'A',
    templateUrl,
    link(scope) {
      let zoneLimit = ZONE_PAGE_SIZE;
      scope.zoneNames = timeZones.slice(0, zoneLimit);

      scope.requestMoreTimeZones = function () {
        zoneLimit += ZONE_PAGE_SIZE;
        scope.zoneNames = timeZones.slice(0, zoneLimit);
      };

      scope.getTimeZonesNames = function (pattern) {
        if (pattern) {
          const lowerPattern = pattern.toLowerCase();

          // We're using a for loop here because zoneNames is huge and we can break early if we find
          // enough matches

          const matchedZoneNames = [];

          for (let i = 0; i < timeZones.length; i += 1) {
            if (StringUtils.containsIgnoreCase(timeZones[i], lowerPattern)) {
              matchedZoneNames.push(timeZones[i]);
              if (matchedZoneNames.length === +scope.maxSuggestions) {
                break;
              }
            }
          }

          return matchedZoneNames;
        } else {
          return scope.zoneNames;
        }
      };

      scope.getTimeZoneLabel = function (timezoneName) {
        const timezone = timeZones.find(tz => tz === timezoneName);
        let label;
        if (timezone) {
          // get offset of the timezone at the current moment
          const currentTimeInTimezone = moment().tz(timezoneName);
          const offset = currentTimeInTimezone.format('Z');
          const name = timezoneName.replace(/_/g, ' ');
          const abbr = currentTimeInTimezone.offsetName();
          label = htmlEscaped`(UTC${offset}) ${name} [${abbr}]`;
        } else {
          label = '';
        }

        return label;
      };

      $timeout(() => {
        const select = angular.element('.ui-select-container input');
        select.blur(() => {
          scope.zoneNames = timeZones.slice(0, ZONE_PAGE_SIZE);
        });
      });
    },
  };
});
