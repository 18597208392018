import * as React from 'react';
import { Button, Icon, Text, useToast, VStack } from 'components/design/next';
import { isHeading, TaskTemplate } from '@process-street/subgrade/process';
import { useAiGenerationWidgetsMutation } from 'features/ai-generation/query-builder';
import { useInjector } from 'components/injection-provider';
import { TaskListEvent } from 'directives/task-list/task-list-event';
import { HttpStatus } from '@process-street/subgrade/util';
import { useAiGenerationSlice } from '../ai-generated-workflow-settings-modal/store';
import { AiGenerationTooltip } from 'features/widgets/components/ai/ai-generation-tooltip';

export type EmptyTaskTemplateProps = {
  taskTemplate: TaskTemplate;
};

export const EmptyTaskTemplate: React.FC<React.PropsWithChildren<EmptyTaskTemplateProps>> = ({ taskTemplate }) => {
  const { $rootScope } = useInjector('$rootScope');
  const toast = useToast();
  const aiGenerationSlice = useAiGenerationSlice();

  const generateWidgetsMutation = useAiGenerationWidgetsMutation({
    onSuccess: widgets => {
      $rootScope.$broadcast(TaskListEvent.AI_WIDGET_GENERATION_FOR_TASK_DONE, widgets);
    },
    onError: e => {
      aiGenerationSlice.stopGeneration();

      if (e.response?.status === HttpStatus.BAD_REQUEST) {
        toast({
          title: 'ChatGPT moderators rejected that Task name, try another one',
          status: 'error',
        });

        return;
      }

      toast({
        title: 'ChatGPT is experiencing an issue, please try again',
        status: 'error',
      });
    },
  });

  const isHeadingTaskTemplate = isHeading(taskTemplate);

  const handleGenerate = () => {
    // prevent quick double clicks
    if (generateWidgetsMutation.isLoading) return;

    aiGenerationSlice.startTaskGeneration(taskTemplate.id);

    generateWidgetsMutation.mutate({ taskTemplateId: taskTemplate.id });
  };

  const [hasTaskError, setHasTaskError] = React.useState(false);
  const headingError = (
    <Text key="heading-task-error" fontSize="xs">
      Cannot generate content for heading tasks.
    </Text>
  );

  const generateButton = (
    <Button
      onClick={handleGenerate}
      leftIcon={<Icon icon="process-pete" variant="far" size="5" />}
      colorScheme="brand"
      fontSize="sm"
      iconSpacing="2"
      isDisabled={hasTaskError || isHeadingTaskTemplate || generateWidgetsMutation.isLoading}
    >
      Generate your task with AI
    </Button>
  );

  return (
    <VStack py="8" spacing="6">
      <Text variant="1" color="gray.400">
        Drop content & forms here
      </Text>

      <Text variant="1" color="gray.400">
        or
      </Text>

      <AiGenerationTooltip
        taskTemplate={taskTemplate}
        errorHeader={
          <Text fontSize="xs" fontWeight="semibold" color="white">
            Can&apos;t generate your task with AI.
          </Text>
        }
        extraErrorItems={isHeadingTaskTemplate ? [headingError] : undefined}
        setHasError={setHasTaskError}
      >
        {generateButton}
      </AiGenerationTooltip>
    </VStack>
  );
};
