/* tslint:disable */
import { ObjectMap } from '@process-street/subgrade/redux/types';
import {
  createAction,
  createFailureAction,
  createRequestAction,
  createSuccessAction,
} from '@process-street/subgrade/redux/action-creator';
import hash from 'object-hash';
import { ReduxAppState } from 'reducers/types';
import { Dispatch } from 'redux';
import { Selector } from 'reselect';

// added unknown properties for compatibility
// https://mariusschulz.com/blog/weak-type-detection-in-typescript#workarounds-for-weak-types
interface FlushableMeta {
  [prop: string]: unknown;

  flushCache?: boolean;
}

// ts-lint:disable-next-line
type SimpleMetaCreator<M> = (...args: any[]) => M;
// ts-lint:disable-next-line
type SelectorMetaCreator<S, M> = (...args: any[]) => Selector<S, M>;

type MetaCreator<S, M> = SimpleMetaCreator<M> | SelectorMetaCreator<S, M>;

// ts-lint:disable-next-line
type SimplePayloadCreator<T> = (...args: any[]) => T;
// ts-lint:disable-next-line
type PromisePayloadCreator<T> = (...args: any[]) => Promise<T>;

type PayloadCreator<T> = SimplePayloadCreator<T> | PromisePayloadCreator<T>;

// ts-lint:disable-next-line
function isFunction(value: any): boolean {
  return value && typeof value === 'function';
}

function isSelectorMetaCreator<S, M>(meta: M | Selector<S, M>): meta is Selector<S, M> {
  return isFunction(meta);
}

function createMeta<S, M>(metaCreator: MetaCreator<S, M>, args: any[], state: S): M {
  const meta = metaCreator(...args);
  return isSelectorMetaCreator<S, M>(meta) ? meta(state) : meta;
}

const CACHE: ObjectMap<Promise<any>> = {};

function isPromise<T>(payload?: T | Promise<T>): payload is Promise<T> {
  if (!payload) {
    return false;
  }
  return typeof (payload as Promise<T>).then === 'function';
}

/**
 *
 * @param type - string type of the action
 * @param payloadCreator - payload creator, that should return either Object or Promise
 * @param metaCreator - meta creator, if meta contains flushCache cache will be ignored and request will be made
 * @param cachedBefore - function, that can prevent unnecessary calls to the server, if it returns true action
 *  won't be triggered
 * @param cachedValueSelector - function, that returns currently cached value for the object
 * @return {function(...[*]): Function}
 */
export function createCachedAction<S extends ReduxAppState, T, M extends FlushableMeta>(
  type: string,
  payloadCreator: PayloadCreator<T>,
  metaCreator?: MetaCreator<S, M>,
  cachedBefore?: (state: S, ...args: any[]) => boolean,
  cachedValueSelector?: (...args: any[]) => Selector<S, T>,
) {
  const actionCreator =
    (...args: any[]) =>
    (dispatch: Dispatch, getState: () => S) => {
      const state = getState();
      const meta = metaCreator ? createMeta(metaCreator, args, state) : undefined;
      if (cachedBefore && cachedBefore(state, ...args) && (!meta || !meta.flushCache)) {
        const cachedValue = cachedValueSelector ? cachedValueSelector(...args)(state) : true;
        const cachedAction = createAction(type, cachedValue, meta);

        return Promise.resolve(cachedAction);
      }

      const cacheKey = `${type}-${hash.MD5({ type, args })}`;
      if (CACHE[cacheKey] as Promise<any> | undefined) {
        return CACHE[cacheKey].then(payload => ({ payload }));
      }

      const payload = payloadCreator(...args);
      if (isPromise(payload)) {
        CACHE[cacheKey] = payload;
        payload.then(
          (response: T) => {
            delete CACHE[cacheKey];
            dispatch(createSuccessAction(type, response, meta));
          },
          error => {
            delete CACHE[cacheKey];
            dispatch(createFailureAction(type, error, meta));
          },
        );
      }

      dispatch(createRequestAction(type, undefined, meta));

      return dispatch(createAction(type, payload, meta));
    };

  actionCreator.toString = () => type;

  return actionCreator;
}
