import { dayjs as moment } from '@process-street/subgrade/util';
import {
  TEMPLATE_REVISION_DELETE_BY_ID,
  TEMPLATE_REVISION_FINISH_BY_ID,
} from 'reducers/template-revision/template-revision.actions';
import { combineActions } from 'redux-actions';
import { TEMPLATE_DELETE_BY_ID } from 'reducers/template/template.actions';
import { composeReducerObjects } from 'reducers/util';
import {
  CHECKLIST_ASSIGNMENT_CREATE_OK_EVENT,
  CHECKLIST_ASSIGNMENT_DELETE_OK_EVENT,
  CHECKLIST_DELETE_OK_EVENT,
  CHECKLIST_UPDATE_STARTED_EVENT,
  INBOX_UPDATED_EVENT,
  TASK_ASSIGNED_UNASSIGNED_EVENT,
  TASK_DUE_DATE_UPDATE_OK_EVENT,
  TASK_STATUS_UPDATE_OK_EVENT,
} from 'reducers/scope-event/scope-event.actions';
import { InboxConstants } from '@process-street/subgrade/inbox/inbox-constants';
import { ChecklistStatus, TaskStatus } from '@process-street/subgrade/process';
import { INBOX_GET_STATS_BY_ORGANIZATION } from 'reducers/inbox/inbox.actions';
import { GetInboxStatsByOrganization } from 'features/inbox/query-builder';
import { queryClient } from 'components/react-root';
import { InboxItemUtils } from '@process-street/subgrade/inbox';

const isUpcoming = dueDate =>
  dueDate !== undefined && dueDate != null && moment().startOf('day').diff(dueDate, 'days') < 0;

const toStatChange = (change, entityDueDate) => {
  if (isUpcoming(entityDueDate)) {
    return { upcoming: change };
  }
  return { inbox: change };
};

const calculateDueDateChange = (originalDueDate, newDueDate) => {
  if (isUpcoming(originalDueDate) && !isUpcoming(newDueDate)) {
    return {
      inbox: +1,
      upcoming: -1,
    };
  } else if (!isUpcoming(originalDueDate) && isUpcoming(newDueDate)) {
    return {
      inbox: -1,
      upcoming: +1,
    };
  }
  return {
    inbox: 0,
    upcoming: 0,
  };
};

const updateUserState = (state = {}, organizationId, userId, change) => {
  if (organizationId) {
    const oldUserOrgState = state[organizationId] || { loaded: false };
    const newUserOrgState = { ...oldUserOrgState };
    if (change.inbox) {
      newUserOrgState.inbox += change.inbox;
    }
    if (change.upcoming) {
      newUserOrgState.upcoming += change.upcoming;
    }
    newUserOrgState.assignmentStats = {
      ...state.assignmentStats,
      hasWorkflowRunTasks:
        !!state.assignmentStats?.hasWorkflowRunTasks || !!newUserOrgState.inbox || !!newUserOrgState.upcoming,
    };
    const newUserState = { ...state, [organizationId]: newUserOrgState };

    queryClient.setQueryData(GetInboxStatsByOrganization.getKey({ organizationId, userId }), () => newUserOrgState);
    return newUserState;
  } else {
    // `Should never be here, unexpected action without organization`;
    // TODO add appropriate notification
    return state;
  }
};

const inboxStatsByTaskReducerObject = {
  [TASK_ASSIGNED_UNASSIGNED_EVENT]: (
    state,
    {
      payload: {
        user: { id: userId },
        task,
        assigned,
      },
      meta: { organizationId },
    },
  ) => {
    // Ignore change if task was already Completed
    if (task.status === TaskStatus.Completed) {
      return state;
    }

    const statChange = toStatChange(assigned ? 1 : -1, task.dueDate);
    const newUserStats = updateUserState(state[userId], organizationId, userId, statChange);
    return { ...state, [userId]: newUserStats };
  },
  [TASK_STATUS_UPDATE_OK_EVENT]: (state, { payload: { assignees, updatedTask }, meta: { organizationId } }) => {
    const change = updatedTask.status === TaskStatus.NotCompleted ? 1 : -1;
    const statChange = toStatChange(change, updatedTask.dueDate);
    const newState = assignees.reduce(
      (agg, assigneeId) => {
        agg[assigneeId] = updateUserState(agg[assigneeId], organizationId, assigneeId, statChange);
        return agg;
      },
      { ...state },
    );
    return newState;
  },
  [TASK_DUE_DATE_UPDATE_OK_EVENT]: (state, { payload, meta: { organizationId } }) => {
    const { assignees, originalTask, originalDueDate, updatedDueDate } = payload;
    // Ignore change if task was already Completed
    if (originalTask.status === TaskStatus.Completed) {
      return state;
    }

    const statChange = calculateDueDateChange(originalDueDate, updatedDueDate);
    const newState = assignees.reduce(
      (agg, assigneeId) => {
        agg[assigneeId] = updateUserState(agg[assigneeId], organizationId, assigneeId, statChange);
        return agg;
      },
      { ...state },
    );
    return newState;
  },
};

const inboxStatsByInboxReducerObject = {
  [INBOX_GET_STATS_BY_ORGANIZATION]: (
    state,
    { meta: { userId, organizationId }, payload: { inbox, upcoming, assignmentStats } },
  ) => {
    const oldUserState = state[userId] || {};
    const newUserState = { ...oldUserState, [organizationId]: { inbox, upcoming, assignmentStats, loaded: true } };

    return { ...state, [userId]: newUserState };
  },
  [INBOX_UPDATED_EVENT]: (state, { payload: { selectedItems, action, dueDate }, meta: { organizationId } }) => {
    const assigneeToItems = selectedItems.reduce((agg, item) => {
      item.assignees.forEach(user => {
        agg[user.id] = (agg[user.id] || []).concat(item);
      });
      return agg;
    }, {});

    const newState = Object.keys(assigneeToItems).reduce(
      (agg, assigneeId) => {
        const userRelatedItems = assigneeToItems[assigneeId];
        if (action === InboxConstants.Actions.COMPLETE_ALL) {
          const itemDueDate = InboxItemUtils.getDueDate(userRelatedItems[0]);
          const statChange = toStatChange(-userRelatedItems.length, itemDueDate);
          agg[assigneeId] = updateUserState(agg[assigneeId], organizationId, assigneeId, statChange);
        } else if (action === InboxConstants.Actions.UPDATE_ALL_DUE_DATES) {
          userRelatedItems.forEach(item => {
            const entityDueDate = InboxItemUtils.getDueDate(item);
            const statChange = calculateDueDateChange(entityDueDate, dueDate);
            agg[assigneeId] = updateUserState(agg[assigneeId], organizationId, assigneeId, statChange);
          });
        }
        return agg;
      },
      { ...state },
    );
    return newState;
  },
};

const invalidateActions = combineActions(
  TEMPLATE_DELETE_BY_ID,
  TEMPLATE_REVISION_FINISH_BY_ID,
  TEMPLATE_REVISION_DELETE_BY_ID,
);

const invalidateUserStats = userStat => {
  const invalidatedStats = Object.keys(userStat).reduce((agg, organizationId) => {
    agg[organizationId] = { ...userStat[organizationId], loaded: false };
    return agg;
  }, {});
  return invalidatedStats;
};

const inboxStatsInvalidateReducerObject = {
  [invalidateActions]: state =>
    Object.keys(state).reduce((agg, userId) => {
      agg[userId] = invalidateUserStats(state[userId]);
      return agg;
    }, {}),
};

const inboxStatsByChecklistReducerObject = {
  [CHECKLIST_ASSIGNMENT_CREATE_OK_EVENT]: (
    state,
    {
      payload: {
        user: { id: userId },
        checklist: { dueDate },
      },
      meta: { organizationId },
    },
  ) => {
    const statChange = toStatChange(1, dueDate);
    const newUserStats = updateUserState(state[userId], organizationId, userId, statChange);
    return { ...state, [userId]: newUserStats };
  },
  [CHECKLIST_ASSIGNMENT_DELETE_OK_EVENT]: (
    state,
    {
      payload: {
        user: { id: userId },
        checklist: { dueDate },
      },
      meta: { organizationId },
    },
  ) => {
    const statChange = toStatChange(-1, dueDate);
    const newUserStats = updateUserState(state[userId], organizationId, userId, statChange);
    return { ...state, [userId]: newUserStats };
  },
  [CHECKLIST_DELETE_OK_EVENT]: (
    state,
    { payload: { assignees: checklistAssignees, tasks, dueDate }, meta: { organizationId } },
  ) => {
    const statChange = toStatChange(-1, dueDate);
    const newState = { ...state };

    checklistAssignees.forEach(assigneeId => {
      newState[assigneeId] = updateUserState(newState[assigneeId], organizationId, assigneeId, statChange);
    });

    tasks.forEach(({ task, assignees: taskAssignees }) => {
      if (task.status !== TaskStatus.Completed) {
        taskAssignees.forEach(assigneeId => {
          const relatedChange = toStatChange(-1, task.dueDate);
          newState[assigneeId] = updateUserState(newState[assigneeId], organizationId, assigneeId, relatedChange);
        });
      }
    });

    return newState;
  },
  [CHECKLIST_UPDATE_STARTED_EVENT]: (
    state,
    {
      payload: { assignees: checklistAssignees, updatedChecklist, originalChecklist, tasks },
      meta: { organizationId },
    },
  ) => {
    const statusChanged = updatedChecklist.status !== originalChecklist.status;
    const dueDateChanged = updatedChecklist.dueDate !== originalChecklist.dueDate;
    if (statusChanged) {
      const calculateChange = () => {
        if (originalChecklist.status === ChecklistStatus.Active && updatedChecklist.status !== ChecklistStatus.Active) {
          return -1;
        }
        if (originalChecklist.status !== ChecklistStatus.Active && updatedChecklist.status === ChecklistStatus.Active) {
          return 1;
        }
        return 0;
      };
      const statChange = toStatChange(calculateChange(), updatedChecklist.dueDate);

      const newState = { ...state };

      checklistAssignees.forEach(assigneeId => {
        newState[assigneeId] = updateUserState(newState[assigneeId], organizationId, assigneeId, statChange);
      });

      tasks.forEach(({ task, assignees: taskAssignees }) => {
        if (task.status !== TaskStatus.Completed) {
          taskAssignees.forEach(assigneeId => {
            const relatedChange = toStatChange(calculateChange(), task.dueDate);
            newState[assigneeId] = updateUserState(newState[assigneeId], organizationId, assigneeId, relatedChange);
          });
        }
      });

      return newState;
    } else if (dueDateChanged) {
      const statChange = calculateDueDateChange(originalChecklist.dueDate, updatedChecklist.dueDate);
      const nextState = checklistAssignees.reduce(
        (agg, assigneeId) => {
          agg[assigneeId] = updateUserState(agg[assigneeId], organizationId, assigneeId, statChange);
          return agg;
        },
        { ...state },
      );
      return nextState;
    } else {
      return state;
    }
  },
};

export const inboxStatsReducer = composeReducerObjects(
  inboxStatsByInboxReducerObject,
  inboxStatsByTaskReducerObject,
  inboxStatsByChecklistReducerObject,
  inboxStatsInvalidateReducerObject,
);
