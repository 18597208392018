import { RefObject, useCallback } from 'react';
import { GroupBase, SelectInstance } from 'react-select';

const MENU_HEADER_SELECTOR = '.blvd-select__menu__header';
const MENU_SELECTOR = '.blvd-select__menu';
const DEFAULT_MENU_HEADER_HEIGHT_PX = 56;
const MENU_LIST_MARGIN_PX = 30;

const getSpaceBelowElement = (element: Element): number => {
  const rect = element.getBoundingClientRect();

  return Math.floor(window.innerHeight - rect.bottom);
};

export const useBlvdSelectMaxHeight = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>() => {
  const onMenuOpen = useCallback((ref: RefObject<SelectInstance<Option, IsMulti, Group>>) => {
    window.requestAnimationFrame(() => {
      const control = ref.current?.controlRef;
      const menuList = ref.current?.menuListRef;
      const menuHeader = document.querySelector(MENU_HEADER_SELECTOR);
      const menu = document.querySelector(MENU_SELECTOR);
      const menuHeaderHeight = menuHeader?.clientHeight ?? DEFAULT_MENU_HEADER_HEIGHT_PX;

      if (!control || !menuList || !menu) {
        console.warn('`control` or `menuList` not found.');
        return;
      }

      const spaceBelowSelectControl = getSpaceBelowElement(control);
      const menuListMaxHeight = spaceBelowSelectControl - menuHeaderHeight - MENU_LIST_MARGIN_PX;

      if (menu.clientHeight > spaceBelowSelectControl) {
        menuList.style.height = `${menuListMaxHeight}px`;
      }
    });
  }, []);

  return {
    onMenuOpen,
  };
};
