import { Muid } from '@process-street/subgrade/core';
import { OrderTree, TaskTemplate } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace DuplicateTaskTemplateMutation {
  export type Params = {
    duplicateId: Muid;
    groupId: Muid;
    taskTemplateId: Muid;
    orderTree: OrderTree;
  };

  export type Response = TaskTemplate;

  export const key = ['put', 'duplicate-task-template'];

  export const mutationFn = ({ taskTemplateId, duplicateId, ...params }: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/task-templates/${taskTemplateId}/duplicate/${duplicateId}`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
