import * as React from 'react';
import { Button, ButtonProps } from 'components/design/next';

export type ToggleButtonProps = ButtonProps & {
  isSelected?: boolean;
};

export const ToggleButton: React.FC<React.PropsWithChildren<ToggleButtonProps>> = ({
  children,
  isSelected = false,
  ...props
}) => {
  return (
    <Button
      aria-pressed={isSelected}
      backgroundColor="white"
      borderColor={isSelected ? 'brand.500' : 'gray.300'}
      borderRadius="base"
      borderWidth="thin"
      color={isSelected ? 'brand.500' : 'gray.600'}
      fontSize="md"
      fontWeight="normal"
      height="unset"
      lineHeight="normal"
      p="2.5"
      variant="outline"
      _hover={{
        borderColor: !isSelected && 'gray.500',
        backgroundColor: isSelected ? 'brand.50' : 'gray.50',
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
