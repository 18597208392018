import angular from 'angular';
import { ApprovalRulesContainerComponent } from 'components/approval-rules/container/approval-rules-container.component';
import { ApprovalRulesEditContainerComponent } from 'components/approval-rules/container/edit/edit-container.component';
import { ApprovalRuleSubjectTasksManagerComponent } from 'components/approval-rules/container/edit/manager/manager.component';
import { TaskTemplatesSelectComponent } from 'components/approval-rules/container/edit/manager/select/select.component';
import { ApprovalRuleSubjectTasksListItemComponent } from 'components/approval-rules/container/view/list/item/item.component';
import { ApprovalRuleSubjectTasksListComponent } from 'components/approval-rules/container/view/list/list.component';
import { ApprovalRulesViewContainerComponent } from 'components/approval-rules/container/view/view-container.component';
import { ApprovalRulesApiImpl } from './api/approval-rules.api';
import { ApprovalRules } from './api/approval-rules.resource';
import { ApprovalRulesActionsImpl } from './store/approval-rules.actions';

export const ApprovalRulesModule = angular
  .module('approval-rules', [])
  .factory('ApprovalRules', ApprovalRules)
  .service('ApprovalRulesApi', ApprovalRulesApiImpl)
  .factory('ApprovalRulesActions', ApprovalRulesActionsImpl)
  .component('psApprovalRulesContainer', ApprovalRulesContainerComponent)
  .component('psApprovalRulesViewContainer', ApprovalRulesViewContainerComponent)
  .component('psApprovalRulesEditContainer', ApprovalRulesEditContainerComponent)
  .component('psApprovalRuleSubjectTasksManager', ApprovalRuleSubjectTasksManagerComponent)
  .component('psApprovalRuleSubjectTaskTemplatesSelect', TaskTemplatesSelectComponent)
  .component('psApprovalRuleSubjectTasksList', ApprovalRuleSubjectTasksListComponent)
  .component('psApprovalRuleSubjectTasksListItem', ApprovalRuleSubjectTasksListItemComponent).name;
