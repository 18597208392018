import { Muid } from '@process-street/subgrade/core';
import * as React from 'react';
import { RetryNativeAutomationMutation } from 'features/native-automations/query-builder';

export const useHandleRetryFailedNativeAutomation = ({
  nativeAutomationExecutionId,
}: {
  nativeAutomationExecutionId?: Muid;
}) => {
  const retryAutomationMutation = RetryNativeAutomationMutation.useMutation();

  const handleRetry = React.useCallback(() => {
    if (!nativeAutomationExecutionId) return;

    retryAutomationMutation.mutateAsync({
      nativeAutomationExecutionId,
    });
  }, [nativeAutomationExecutionId, retryAutomationMutation]);

  return handleRetry;
};
