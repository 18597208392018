import angular from 'angular';
import { htmlEscaped, HttpStatus } from '@process-street/subgrade/util';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ArrayService } from 'services/array-service';
import { EventName } from 'services/event-name';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'UserManageOrganizationsCtrl',
    function (
      $scope,
      $rootScope,
      FeatureFlagService,
      MessageBox,
      OrganizationMembershipService,
      OrganizationService,
      SecurityService,
      SessionService,
      UserService,
      ToastService,
    ) {
      const ctrl = this;
      ctrl.$onInit = () => {
        $scope.sessionUser = UserService.getCurrentUser();
        $scope.selectedOrganization = SessionService.getSelectedOrganization();

        $scope.userIsDeveloper = $scope.sessionUser.developer;

        const organizationUseFrequencyMap = SessionService.getOrganizationUseFrequencyMap();
        OrganizationService.getAllByUser($scope.sessionUser).then(organizations => {
          $scope.organizations = organizations;

          $scope.organizations.forEach(organization => {
            organization._useFrequency = organizationUseFrequencyMap[organization.id] || 0;
          });
        });

        registerListeners();
      };

      $scope.canCreateOrganization = function () {
        return SecurityService.canCreateOrganization($scope.sessionUser);
      };

      $scope.addOrganization = function () {
        if (!$scope.canCreateOrganization()) {
          return;
        }

        MessageBox.prompt({
          title: 'What should the new organization be called?',
          okButton: {
            action: createOrganization,
          },
        });
      };

      function registerListeners() {
        $scope.$on(EventName.ORGANIZATION_CREATED, (__event, data) => {
          $scope.onNewOrganizationCreated(data.organization);
        });
      }

      $scope.onNewOrganizationCreated = organization => {
        $scope.organizations.push(organization);

        // If the user has no organizations, automatically switch to the new one
        if (!SessionService.getSelectedOrganization()) {
          $scope.switchOrganization(organization.id);
        }
      };

      function createOrganization(name) {
        if ($scope.creating) {
          return;
        }

        $scope.creating = true;

        OrganizationService.create(name)
          .then(
            response => {
              ToastService.openToast({
                status: 'success',
                title: `Organization ${name} created`,
              });

              return response;
            },
            () => {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems creating the organization`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          )
          .finally(() => {
            $scope.creating = false;
          });
      }

      $scope.switchOrganization = function (organizationId) {
        $scope.switching = true;

        OrganizationService.switchById(organizationId, true /* redirectToHome */);
      };

      $scope.confirmLeaveOrganization = function (organization) {
        const message = htmlEscaped`If you remove yourself, you won't be able to access any of the workflows or
                workflow runs in <b>${organization.name}</b>. If you want to regain access, you'll need
                to ask a coworker to invite you to the organization again.`;
        MessageBox.confirm({
          title: 'Remove yourself from this organization?',
          message,
          okButton: {
            type: 'danger',
            text: 'Remove me',
            action: leaveOrganization.bind(null, organization),
          },
        });
      };

      function leaveOrganization(organization) {
        ArrayService.desplice($scope.organizations, organization);

        const sessionUser = UserService.getCurrentUser();
        OrganizationMembershipService.deleteByOrganizationIdAndUserId(organization.id, sessionUser.id).then(
          () => {
            ToastService.openToast({
              status: 'success',
              title: `You have left ${organization.name}`,
            });

            $rootScope.$broadcast(EventName.ORGANIZATION_REMOVED, organization);
          },
          response => {
            switch (response.status) {
              case HttpStatus.FORBIDDEN:
                ToastService.openToast({
                  status: 'warning',
                  title: `We couldn't remove you from the organization the workflow run`,
                  description: `You cannot leave <b>${organization.name}</b>
                            because you are the only admin there and it has other users.
                            Either give someone else admin access, or remove all other users.`,
                });
                break;
              default:
                ToastService.openToast({
                  status: 'error',
                  title: `We're having problems removing you from the oranization`,
                  description: DefaultErrorMessages.unexpectedErrorDescription,
                });
            }

            $scope.organizations.push(organization);
          },
        );
      }
    },
  );
