import { StateService } from '@uirouter/core';
import { ReactContextType } from 'components/common/context/angular-dependencies-context';
import { testGridDataService } from 'components/dashboard/models/test-grid-data.service.impl';
import { ChecklistDashboardActions } from 'components/dashboard/store/checklist-dashboard.actions';
import { OrganizationMembershipActionsMock } from 'reducers/organization-membership/organization-membership.actions.mock';
import { SessionService } from 'services/session-service.interface';
import { Mock } from 'ts-mockery';
import { getEnv } from 'components/common/env';

export const testContext: ReactContextType = {
  actions: {
    checklistDashboardActions: new ChecklistDashboardActions(testGridDataService),
    organizationMembershipActions: OrganizationMembershipActionsMock,
  },
  env: {
    mrProcesspantsOrganizationId: getEnv().APP_PROCESSPANTS_ORG_ID,
  },
  services: {
    $state: Mock.of<StateService>(),
    gridDataService: testGridDataService,
    sessionService: Mock.of<SessionService>(),
  },
};
