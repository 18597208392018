import angular from 'angular';
import templateUrl from './assignment.component.html';
import './assignment-box.component.html';

angular
  .module('frontStreetApp.directives')
  .controller('AssignmentBoxCtrl', function ($scope) {
    const ctrl = this;
    ctrl.$onInit = () => {
      $scope.$on('init', (__event, values) => {
        $scope.user = values.user;
        $scope.unassignable = values.unassignable;
      });

      $scope.$on('changes', (__event, changes) => {
        if (changes.user) {
          $scope.user = changes.user.currentValue;
        }

        if (changes.unassignable) {
          $scope.unassignable = changes.unassignable.currentValue;
        }
      });
    };

    $scope.unassignUser = function () {
      $scope.$emit('unassign', $scope.user);
    };
  })
  .component('psAssignment', {
    bindings: {
      user: '<',
      unassignable: '<',
      onUnassign: '&',
    },
    templateUrl,
    controller($scope) {
      const ctrl = this;

      // This component communicates with the controller in the pop box via events in the scope

      ctrl.init = function () {
        $scope.$broadcast('init', {
          user: ctrl.user,
          unassignable: ctrl.unassignable,
        });
      };

      ctrl.$onChanges = function (changes) {
        $scope.$broadcast('changes', changes);
      };

      $scope.$on('unassign', (event, user) => {
        // Don't let the event escape the component
        event.stopPropagation();

        ctrl.onUnassign({ user });
      });
    },
  });
