import { useStateParams } from 'app/adapters/navigation';

export enum ViewMode {
  DataSet = 'DataSet',
  SavedView = 'SavedView',
}

export const useDataSetViewMode = () => {
  const $stateParams = useStateParams();

  if ($stateParams.savedViewId) {
    return ViewMode.SavedView;
  } else {
    return ViewMode.DataSet;
  }
};
