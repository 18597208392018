import { Muid } from '../core';
import { Method } from '../process';

export interface ChecklistCreatedAnalyticsEvent {
  'method': Method;
  'template id': Muid;
  'template name': string;
  'checklist id': string;
  'checklist name': string;
  'checklist due date'?: string;
  'number of rules': number;
  'number of dynamic due dates': number;
  'number of stop tasks': number;
  'number of role-based assignments': number;
  'number of checklist initiator role-based assignments': number;
  'number of members field role-based assignments': number;
  'number of email field role-based assignments': number;
  'number of task template permits': number;
  'number of task permission rules': number;
  'number of approval tasks': number;
}

export interface ChecklistAnalyticsEvent {
  'method': Method;
  'checklist id': string;
  'checklist name': string;
  'template name': string;
  'template id': Muid;
}

export enum ChecklistViewedSource {
  Direct = 'direct',
  Inbox = 'inbox',
  Dashboard = 'dashboard',
  ChecklistDashboard = 'checklist dashboard',
  Template = 'template',
}

export interface ChecklistViewedAnalyticsEvent extends ChecklistAnalyticsEvent {
  source: ChecklistViewedSource;
}
