import { Template } from '@process-street/subgrade/process';
import { NgLinkRouteProps } from 'features/app/components/ng-link';
import React from 'react';
import { TempDataServiceImpl } from 'services/temp-data-service';
import { Option } from 'space-monad';
import { getAncestorFolders, toSlugPath } from 'features/folder/lib';
import { useFoldersQuery } from 'pages/library/components/template-library/query';
import { AppState } from 'app/app.states.typegen';

type UseBackPropsArgs = {
  template?: Template;
};
const STATES_TO_IGNORE: AppState[] = [
  '',
  'loadAndGo',
  'objectRedirect',
  'form',
  'form.step',
  'formSettings',
  'formResponses',
];

export function useBackProps({ template }: UseBackPropsArgs): NgLinkRouteProps {
  const [defaultBackProps, setDefaultBackProps] = React.useState<NgLinkRouteProps>({
    to: 'dashboard',
  });

  const previousState = React.useMemo(
    () => TempDataServiceImpl.getStates().find(state => !STATES_TO_IGNORE.includes(state.state.name)),
    [],
  );

  useFoldersQuery({
    enabled: Boolean(template) && (!previousState || previousState.state.name === ''),
    onSuccess: folders => {
      const folder = folders.find(folder => folder.id === template?.folder.id);
      if (!folder) return;
      setDefaultBackProps({
        to: 'dashboard.type',
        params: {
          type: 'folder',
          path: toSlugPath(folder, getAncestorFolders(folder, folders)),
        },
      });
    },
  });

  const backProps = React.useMemo<NgLinkRouteProps>(() => {
    return Option(previousState)
      .map<NgLinkRouteProps>(state => ({ to: state.state.name as AppState, params: state.params }))
      .getOrElse(defaultBackProps);
  }, [defaultBackProps, previousState]);

  return backProps;
}
