import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { connectController } from 'reducers/util';
import { createSelector } from 'reselect';
import { UserType, OrganizationMembershipRole } from '@process-street/subgrade/core';
import templateUrl from './total-users-heading.component.html';

export const TotalUsersHeadingComponent = {
  templateUrl,
  controller: class {
    constructor($ngRedux) {
      'ngInject';

      const mapStateToCtrl = createSelector(
        [OrganizationMembershipSelector.getAllWithUserBySelectedOrganizationId],
        organizationMemberships => {
          const totalMemberships = organizationMemberships.filter(
            ({ user: { userType }, role }) =>
              userType === UserType.Standard &&
              role !== OrganizationMembershipRole.Guest &&
              role !== OrganizationMembershipRole.FreeMember,
          ).length;

          const totalGuestMemberships = organizationMemberships.filter(
            membership =>
              membership.role === OrganizationMembershipRole.Guest ||
              membership.role === OrganizationMembershipRole.FreeMember,
          ).length;

          return {
            totalMemberships,
            totalGuestMemberships,
          };
        },
      );

      connectController($ngRedux, () => mapStateToCtrl)(this);
    }
  },
};
