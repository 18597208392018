import { axiosService } from 'services/axios-service';
import { OrganizationMembership, User } from '@process-street/subgrade/core';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';

export type GetUserOrganizationMembershipsResult = OrganizationMembership[];

const getUserOrganizationMemberships = (userId: User['id']): Promise<GetUserOrganizationMembershipsResult> =>
  axiosService
    .getAxios()
    // don't be fooled by this endpoint, you get OMs back, not organizations
    .get(`/1/users/${userId}/organizations`)
    .then(res => res.data);

export const GetUserOrganizationMemberships = {
  key: ['user', 'organization-memberships'],
  getKey(userId: User['id']): QueryKey {
    return [...GetUserOrganizationMemberships.key, userId];
  },
  queryFn: getUserOrganizationMemberships,
};

export const useGetUserOrganizationsMembershipsQuery = <Select = GetUserOrganizationMembershipsResult>(
  userId: User['id'],
  options: UseQueryOptions<GetUserOrganizationMembershipsResult, AxiosError, Select> = {},
) => {
  return useQuery(
    GetUserOrganizationMemberships.getKey(userId),
    () => GetUserOrganizationMemberships.queryFn(userId),
    options,
  );
};
