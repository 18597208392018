import { Category } from '@process-street/subgrade/process/premade-template-model';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import {
  CategoryOption,
  UserOptionTransformer,
} from 'components/template-gallery/components/TemplateGalleryCategories/option-transform';
import { TemplateGalleryCategoriesItem } from 'components/template-gallery/components/TemplateGalleryCategories/TemplateGalleryCategoriesItem';
import React from 'react';
import { OnChangeValue, ActionMeta } from 'react-select';
import styles from './TemplateGalleryCategories.module.scss';

export interface TemplateGalleryCategoriesProps {
  selectedCategory?: Category;
  categories: Category[];
  onSelectCategory: (tag: Category) => void;
}

export const TemplateGalleryCategories: React.FunctionComponent<
  React.PropsWithChildren<TemplateGalleryCategoriesProps>
> = ({ selectedCategory, categories, onSelectCategory }) => {
  const categoriesAsOptions = UserOptionTransformer.transformCategoriesToCategoryOptions(categories);

  const selectedCategoryAsOption = selectedCategory
    ? UserOptionTransformer.transformCategoryToCategoryOption(selectedCategory)
    : undefined;

  const handleCategorySelect = (category: Category) => {
    if (category !== selectedCategory) {
      onSelectCategory(category);
    }
  };

  const handleDropdownCategorySelect = (
    value: OnChangeValue<CategoryOption, false>,
    actionMeta: ActionMeta<CategoryOption>,
  ) => {
    if (actionMeta.action === 'select-option') {
      if (BlvdSelectHelpers.isOptionType<CategoryOption>(value)) {
        onSelectCategory(value.category);
      }
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <span className={styles.title}>Categories</span>
      </div>

      <BlvdSelect
        className={styles.categoryDropdwonSelect}
        value={selectedCategoryAsOption}
        onChange={handleDropdownCategorySelect}
        options={categoriesAsOptions}
      />

      <div className={styles.categoryListSelect} role={'menu'}>
        {categories.map(category => (
          <TemplateGalleryCategoriesItem
            key={category.id}
            category={category}
            selected={category.id === selectedCategory?.id}
            onClick={handleCategorySelect}
          />
        ))}
      </div>
    </div>
  );
};
