import React from 'react';
import { OrganizationMembershipRole, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { HStack, Input, InputGroup, List, ListItem, Skeleton, SkeletonCircle, Text } from 'components/design/next';
import { useCombobox } from 'downshift';
import { UserUtils } from '@process-street/subgrade/util';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { isStandardUserOrStandardGroupOm } from '@process-street/subgrade/util/membership-utils';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { ChakraAvatar } from 'components/design/next/chakra-avatar';

export type UserTypeAheadSelectProps = {
  onChange: (user: OrganizationMembershipWithUser) => void;
  organizationMembershipIdsToExclude: string[];
};

export const MaxSuggestions = 5;
const FieldHeight = 12;
const suggestionsAreLoading = false;

const ALLOWED_PERMIT_ROLES = [OrganizationMembershipRole.Admin, OrganizationMembershipRole.FullMember];

export const UserTypeAheadSelect: React.FC<React.PropsWithChildren<UserTypeAheadSelectProps>> = ({
  onChange,
  organizationMembershipIdsToExclude,
}) => {
  const [suggestedUsers, setSuggestedUsers] = React.useState<OrganizationMembershipWithUser[]>([]);
  const inputRef = React.useRef<HTMLInputElement>();
  const selectedOrganization = useSelectedOrganization();
  const organizationMembershipsQuery = useGetAllOrganizationMembershipsQuery(
    { organizationId: selectedOrganization?.id! },
    {
      enabled: Boolean(selectedOrganization),
      select: data => {
        return data.filter((omWithUser: OrganizationMembershipWithUser) => {
          return (
            isStandardUserOrStandardGroupOm(omWithUser) &&
            !organizationMembershipIdsToExclude.includes(omWithUser.id) &&
            ALLOWED_PERMIT_ROLES.includes(omWithUser.role)
          );
        });
      },
    },
  );
  const users = organizationMembershipsQuery.data ?? [];

  const { isOpen, getMenuProps, getItemProps, getInputProps, getComboboxProps, openMenu, reset } =
    useCombobox<OrganizationMembershipWithUser>({
      items: suggestedUsers,
      onInputValueChange: ({ inputValue }) => {
        setSuggestedUsers(UserUtils.filterOmUsersByEmailOrUsername(users, inputValue ?? '').slice(0, MaxSuggestions));
      },
      onSelectedItemChange: ({ selectedItem }) => {
        if (selectedItem) {
          onChange(selectedItem);
        }
        reset();
      },
    });

  React.useEffect(() => {
    if (organizationMembershipsQuery.data) {
      setSuggestedUsers(organizationMembershipsQuery.data.slice(0, MaxSuggestions));
    }
  }, [organizationMembershipsQuery.data]);

  return (
    <>
      <InputGroup h={FieldHeight} {...getComboboxProps()}>
        <Input
          placeholder="Enter name, group, or email address"
          aria-label="Enter name, group, or email address"
          h={FieldHeight}
          isDisabled={false}
          ref={inputRef}
          value=""
          {...getInputProps({
            onFocus: () => {
              if (!isOpen) {
                openMenu();
              }
            },
          })}
        />
      </InputGroup>
      <List
        display={isOpen && (suggestedUsers.length > 0 || suggestionsAreLoading) ? 'block' : 'none'}
        borderColor="gray.300"
        backgroundColor="white"
        zIndex="dropdown"
        borderWidth="px"
        borderStyle="solid"
        boxShadow="base"
        py={1}
        px={2}
        my={1}
        rounded="md"
        pos="absolute"
        {...getMenuProps()}
      >
        {suggestionsAreLoading
          ? [...Array(4)].map((_, i) => (
              <HStack key={i} pt={2} pb={1}>
                <SkeletonCircle size="8" />
                <Skeleton h={6} w={60} />
              </HStack>
            ))
          : suggestedUsers.map((omUser, index) => (
              <ListItem
                _selected={{
                  backgroundColor: 'brand.500',
                  color: 'white',
                }}
                key={omUser.id}
                h={12}
                rounded="md"
                px={2}
                py={1}
                cursor="pointer"
                {...getItemProps({ item: omUser, index })}
              >
                <HStack h="100%">
                  <ChakraAvatar user={omUser.user} />
                  <Text as="span">{UserUtils.getLabel(omUser.user)}</Text>
                </HStack>
              </ListItem>
            ))}
      </List>
    </>
  );
};
