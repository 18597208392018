import * as React from 'react';
import { FilteredItem } from './use-columns-filter-state';
import { Text } from 'components/design/next';
import { match } from 'ts-pattern';
import { ColumnType } from '@process-street/subgrade/dashboard';
import { components, GroupProps } from 'react-select';
import { useChecklistDashboardFiltersContext } from './context';

export const WORKFLOW_LABEL = 'Workflow';
export const TASK_LABEL = 'Tasks';
export const FORM_FIELD_LABEL = 'Form Fields';

// Use these empty options as identifiers for custom help text in the select
export const EMPTY_TASK = { value: '', label: '', meta: ColumnType.Task };
export const EMPTY_FORM_FIELD = { value: '', label: '', meta: ColumnType.FormField };

export const CustomGroup = (props: GroupProps<FilteredItem, true>) => {
  const { multiple } = useChecklistDashboardFiltersContext();
  return match({ ...props, multiple })
    .with({ data: { label: TASK_LABEL, options: [EMPTY_TASK] }, multiple: true }, () => (
      <components.Group {...props}>
        <Text variant="-2" color="gray.500" px="2">
          You have multiple workflows selected. To see task and form field columns, please select only 1 workflow.
        </Text>
      </components.Group>
    ))
    .with({ data: { label: TASK_LABEL, options: [EMPTY_TASK] }, multiple: false }, () => (
      <components.Group {...props}>
        <Text variant="-2" color="gray.500" px="2">
          No Tasks
        </Text>
      </components.Group>
    ))
    .with({ data: { label: FORM_FIELD_LABEL, options: [EMPTY_FORM_FIELD] }, multiple: true }, () => null)
    .with({ data: { label: FORM_FIELD_LABEL, options: [EMPTY_FORM_FIELD] }, multiple: false }, () => (
      <components.Group {...props}>
        <Text variant="-2" color="gray.500" px="2">
          No Form Fields
        </Text>
      </components.Group>
    ))
    .otherwise(() => <components.Group {...props}>{props.children}</components.Group>);
};
