import angular from 'angular';
import { dayjs as moment } from '@process-street/subgrade/util';
import { HttpStatus } from '@process-street/subgrade/util';
import { PlanInterval } from '@process-street/subgrade/billing';
import { BillingConstants } from '@process-street/subgrade/billing/billing-constants';
import { StripeConstants } from '@process-street/subgrade/billing/stripe-constants';
import { SubscriptionStatus } from '@process-street/subgrade/core';
import { getReachedPlanLevelLimitsAsMessages, getTrialDaysRemaining } from 'utils/billing';
import { IntercomService } from 'services/interop/intercom-service';
import { EventName } from 'services/event-name';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.services')
  .service(
    'BillingService',
    function ($state, $rootScope, $q, FacebookService, OrganizationService, PlanService, ToastService) {
      const self = this;

      /**
       * Returns state of the subscription
       *
       * @param {Plan} plan
       * @param {PlanFeatureSet} planFeatureSet
       * @param {Subscription} subscription
       * @param {OrganizationStats} organizationStats
       * @returns
       */
      self.getSubscriptionState = function (plan, planFeatureSet, subscription, organizationStats) {
        if (subscription.status === SubscriptionStatus.PastDue) {
          return BillingConstants.SubscriptionState.PAST_DUE;
        } else if (self.willSubscriptionCancelAtPeriodEnd(subscription, plan)) {
          return BillingConstants.SubscriptionState.CANCEL_AT_PERIOD_END;
        } else if (self.isSubscriptionExpired(subscription, plan)) {
          return BillingConstants.SubscriptionState.EXPIRED;
        } else if (
          self.getActiveTemplatesLimitDetails(planFeatureSet, organizationStats).limitIsReached ||
          // If subscription is not canceled or expired, checking if any limit exceeded
          self.getChecklistRunsLimitDetails(planFeatureSet, organizationStats).limitIsReached
        ) {
          return BillingConstants.SubscriptionState.EXCEEDED;
        } else if (OrganizationService.isTrialing(subscription)) {
          // It should be placed after limits checking as limits have higher priority
          return BillingConstants.SubscriptionState.TRIALING;
        }

        // Otherwise the subscription is active
        return BillingConstants.SubscriptionState.ACTIVE;
      };

      /**
       * Checks whether subscription is cancelling at period end.
       *
       * @param {Subscription} subscription
       * @param {Plan} plan
       * @returns {boolean}
       */
      self.willSubscriptionCancelAtPeriodEnd = function (subscription, plan) {
        return (
          OrganizationService.isSubscribed(subscription) &&
          subscription.cancelAtPeriodEnd &&
          !PlanService.isPlanFree(plan)
        );
      };

      /**
       * Checks whether subscription is expired
       *
       *
       * @param {Subscription} subscription
       * @param {Plan} plan
       * @returns {boolean}
       */
      self.isSubscriptionExpired = function (subscription, plan) {
        return !OrganizationService.isSubscribed(subscription) && !PlanService.isPlanFree(plan);
      };

      /**
       * Returns number of days left on trial
       *
       * @param {Subscription} subscription
       * @returns {number}
       */
      self.getTrialDaysRemaining = getTrialDaysRemaining;

      /**
       * Returns end of subscription period. If yearly, then period is calculated for current month
       *
       * @param plan
       * @param subscription
       * @returns {*}
       */
      self.getEndDateOfCurrentSubscriptionPeriod = function (plan, subscription) {
        let endDate = moment(subscription.currentPeriodEndDate);

        if (plan.interval === PlanInterval.Yearly) {
          const startDate = moment(subscription.currentPeriodStartDate);
          const now = moment();
          const calculatedEndDate = startDate.add(now.diff(startDate, 'months') + 1, 'months');
          if (endDate > calculatedEndDate) {
            endDate = calculatedEndDate;
          }
        }

        return endDate;
      };

      /**
       * @typedef {Object} LimitDetails
       * @property {number} usage Total usage of the limited feature
       * @property {number} limit Maximum allowed usage by plan level
       * @property {boolean} limitIsReached True if the limit is reached
       */

      /**
       * Returns checklists runs limit details from organization stats and level limit
       *
       * @param {PlanFeatureSet} planFeatureSet
       * @param {OrganizationStats} organizationStats
       * @returns LimitDetails
       */
      self.getChecklistRunsLimitDetails = function (planFeatureSet, organizationStats) {
        const usage = organizationStats.checklistsRunsCountCurrentPeriod;
        const limit = planFeatureSet.checklistRunsLimit;

        return {
          usage,
          limit,
          limitIsReached: limit ? usage > limit : false,
        };
      };

      /**
       * Returns active checklists limit details from organization stats and level limit
       *
       * @param {PlanFeatureSet} planFeatureSet
       * @param {OrganizationStats} organizationStats
       * @returns LimitDetails
       */
      self.getActiveChecklistsLimitDetails = function (planFeatureSet, organizationStats) {
        const usage = organizationStats.checklistsActiveCount;
        const limit = planFeatureSet.activeChecklistsLimit;

        return {
          usage,
          limit,
          limitIsReached: limit ? usage > limit : false,
        };
      };

      /**
       * Returns active template limit details from organization stats and level limit
       *
       * @param {PlanFeatureSet} planFeatureSet
       * @param {OrganizationStats} organizationStats
       * @returns LimitDetails
       */
      self.getActiveTemplatesLimitDetails = function (planFeatureSet, organizationStats) {
        const usage = organizationStats.templatesActiveCount;
        const limit = planFeatureSet.activeTemplatesLimit;

        return {
          usage,
          limit,
          limitIsReached: limit ? usage > limit : false,
        };
      };

      /**
       * Updates subscription plan
       *
       * @param cardInfo
       * @param organization
       * @returns {Promise}
       */
      self.updateSubscriptionPlan = function (cardInfo, organization) {
        return OrganizationService.updateSubscriptionPlanByOrganization(organization, cardInfo.planId).then(
          updatedOrganization => {
            $state.go($state.current, {}, { reload: true });

            return updatedOrganization;
          },
          response => {
            if (response.status === HttpStatus.PAYMENT_REQUIRED) {
              ToastService.openToast(response.data.message);
            }

            return $q.reject(response);
          },
        );
      };

      /**
       * Renews a canceled subscription.
       *
       * @param {Organization} organization
       * @param {Plan} currentPlan
       * @returns {Promise}
       */
      self.renewSubscription = function (organization, currentPlan) {
        const cardInfo = { planId: currentPlan.id };
        return self.updateSubscriptionPlan(cardInfo, organization).then(
          () => {
            AnalyticsService.trackEvent('subscription renewed', {
              'plan id': currentPlan.id,
              'plan name': currentPlan.name,
              'plan interval': currentPlan.interval,
              'plan level': currentPlan.level,
            });
          },
          response => {
            if (response.status !== HttpStatus.PAYMENT_REQUIRED) {
              AnalyticsService.trackEvent('failed to renew subscription', {
                'plan id': currentPlan.id,
                'plan name': currentPlan.name,
                'plan interval': currentPlan.interval,
                'plan level': currentPlan.level,
              });

              const message =
                'Failed to renew subscription. Please contact support. <i class="far fa-arrow-right"></i>';
              ToastService.openToast(message);

              IntercomService.show();
            }
          },
        );
      };

      /**
       * Upgrades subscription
       *
       * @param cardInfo
       * @param organization
       * @param {Plan} currentPlan
       * @param {Plan} upgradePlan
       * @param {User} user
       * @returns {Promise}
       */
      self.upgradeSubscription = function (cardInfo, organization, currentPlan, upgradePlan, user) {
        const oldSubscriptionStatus = organization.subscription.status;

        cardInfo.planId = upgradePlan.id || currentPlan.id;

        return self.updateSubscriptionPlan(cardInfo, organization).then(
          updatedOrganization => {
            $rootScope.$broadcast(EventName.SELECTED_ORGANIZATION_UPDATED, updatedOrganization.id);

            // Flushing cache
            OrganizationService.getAllByUser(user, undefined /* fullMembershipOnly */, true /* flushCache*/);

            return PlanService.getById(cardInfo.planId).then(plan => {
              AnalyticsService.trackEvent('subscription upgraded', {
                'plan id': plan.id,
                'plan name': plan.name,
                'plan interval': plan.interval,
                'plan level': plan.level,
                'old plan id': currentPlan.id,
                'old plan name': currentPlan.name,
                'old plan interval': currentPlan.interval,
                'old plan level': currentPlan.level,
                'old subscription status': oldSubscriptionStatus,
              });

              const planLevelHasChanged = plan.level !== currentPlan.level;
              if (planLevelHasChanged && currentPlan.level === 'Free') {
                OrganizationService.getDiscountByOrganizationId(updatedOrganization.id)
                  .catch(() => ({}))
                  .then(discount => {
                    const discountedPlanCost = PlanService.calculatePlanCost(
                      plan,
                      updatedOrganization.subscription.quantity,
                      discount,
                    );

                    FacebookService.trackMakePurchase(discountedPlanCost.totalCost);
                  });
              }

              return updatedOrganization;
            });
          },
          response => {
            if (
              response.type !== StripeConstants.ErrorType.CARD_ERROR &&
              response.status !== HttpStatus.PAYMENT_REQUIRED
            ) {
              AnalyticsService.trackEvent('failed to upgrade subscription', {
                'plan id': cardInfo.planId,
                'old plan id': currentPlan.id,
                'old plan name': currentPlan.name,
                'old plan interval': currentPlan.interval,
                'old plan level': currentPlan.level,
                'old subscription status': oldSubscriptionStatus,
              });

              const message =
                'Failed to upgrade subscription. Please contact support. <i class="far fa-arrow-right"></i>';
              ToastService.openToast(message);

              IntercomService.show();
            }

            return $q.reject(response);
          },
        );
      };

      /**
       * Returns a reached plan level limits as messages
       *
       * @param {PlanFeatureSet} planFeatureSet
       * @param {OrganizationStats} organizationStats
       * @returns {Array}
       */
      self.getReachedPlanLevelLimitsAsMessages = getReachedPlanLevelLimitsAsMessages;
      /**
       * Returns true when subscription is premium and will be renewed
       * @param organization
       * @param plan
       * @returns {boolean|*}
       */
      self.subscriptionIsPremiumAndWillBeRenewed = function (organization, plan) {
        return (
          !PlanService.isPlanFree(plan) &&
          !organization.subscription.cancelAtPeriodEnd &&
          (OrganizationService.isActive(organization.subscription) ||
            (OrganizationService.isTrialing(organization.subscription) && organization.cardDefined))
        );
      };

      self.subscriptionIsCancelable = (organization, plan) => {
        return (
          !PlanService.isPlanFree(plan) &&
          !organization.subscription.cancelAtPeriodEnd &&
          !(OrganizationService.isTrialing(organization.subscription) && !organization.cardDefined) &&
          !OrganizationService.isCanceled(organization.subscription)
        );
      };

      self.getDiscountValue = function (discount) {
        let discountValue;
        if (discount && discount.percentOff) {
          discountValue = `${discount.percentOff}%`;
        } else if (discount && discount.amountOff) {
          discountValue = `$${discount.amountOff / 100}`;
        }
        return discountValue;
      };
    },
  );
