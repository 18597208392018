import { Template } from '@process-street/subgrade/process';
import { useSolutionTypeTagInstances } from 'pages/templates/_id/automation/components/selector/use-solution-type-tag-instances';

import { useGetScheduledWorfklowInfo } from './use-get-scheduled-workflow-info';
import { useGetAllIncomingWebhooksByTemplateIdQuery } from 'features/automations/query-builder';

export const useGetActiveAutomationsCount = ({
  templateId,
}: {
  templateId: Template['id'];
}): {
  incomingCount: number;
  outgoingCount: number;
} => {
  const scheduled = useGetScheduledWorfklowInfo({ templateId });
  const {
    instances: { ps_create_checklist_when, ps_when_checklist_completed_then },
  } = useSolutionTypeTagInstances({ templateId });

  const incomingWebhooksCount =
    useGetAllIncomingWebhooksByTemplateIdQuery(
      { templateId },
      { select: webhooks => webhooks.filter(w => w.status !== 'Deleted') },
    ).data?.length ?? 0;

  const incomingCount = incomingWebhooksCount + ps_create_checklist_when.length + scheduled?.length;
  const outgoingCount = ps_when_checklist_completed_then.filter(i => i.configured).length;

  return {
    incomingCount,
    outgoingCount,
  };
};
