import * as React from 'react';
import { InputGroup, Input, InputRightElement, InputProps } from 'components/design/next';
import { EMAIL_PLACEHOLDER } from '../../../common/common';
import { MergeTagsMenu, MergeTagsMenuButton } from 'features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants, MergeTagTarget } from '@process-street/subgrade/form';
import { useMergeTaggableInput } from 'hooks/use-merge-taggable-input';
import { toGetSetForSeparatableString } from '../to-get-set-for-separatable-string';
import { Muid } from '@process-street/subgrade/core';
import { MaskedInput, MaskedInputParsers } from 'features/widgets/components/masked-input';
import { useFeatureFlag } from 'features/feature-flags';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';

export interface ToInputProps extends Omit<InputProps, 'onChange' | 'value'> {
  templateRevisionId: Muid;
  value: string;
  onChange: (s: string) => void;
  mergeTagTarget?: MergeTagTarget;
  inputRightElement?: (props: { mergeTagsMenu: React.ReactNode }) => React.ReactNode;
}

const maskedInputParser = MaskedInputParsers.makeParser('merge-tags');

export const ToInput: React.FC<React.PropsWithChildren<ToInputProps>> = ({
  templateRevisionId,
  value,
  onChange,
  inputRightElement,
  mergeTagTarget,
  ...inputProps
}) => {
  const isMergeTagImprovementsEnabled = useFeatureFlag('mergeTagImprovements');
  const rightElementRef = React.useRef<HTMLDivElement>(null);
  const [maxWidth, setMaxWidth] = React.useState('100%');

  const { ref: toRef, insertMergeTag: insertTo } = useMergeTaggableInput(
    toGetSetForSeparatableString({ get: () => value, set: onChange }),
  );
  const mergeTagsMenu = (
    <MergeTagsMenu
      {...{
        templateRevisionId,
        onSelect: (key, _fieldId, fallback) => insertTo(key, fallback),
        mergeTagTarget: mergeTagTarget ?? MergeTagsConstants.Target.EMAIL,
        menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
      }}
    />
  );

  const html = React.useMemo(
    () =>
      isMergeTagImprovementsEnabled
        ? maskedInputParser(MergeTagStringReplacementUtils.replaceUnknownTagsValues(value))
        : value,
    [value, isMergeTagImprovementsEnabled],
  );

  React.useEffect(() => {
    if (inputRightElement) {
      const updateMaxWidth = () => {
        const inputWidth = toRef.current?.offsetWidth;
        const rightElementWidth = rightElementRef.current?.offsetWidth;
        if (inputWidth && rightElementWidth) {
          setMaxWidth(`calc(100% - ${rightElementWidth}px)`);
        }
      };

      updateMaxWidth();
      window.addEventListener('resize', updateMaxWidth);
      return () => window.removeEventListener('resize', updateMaxWidth);
    }
  }, [inputRightElement, toRef]);

  return (
    <InputGroup>
      <MaskedInput w="full" html={html}>
        <Input
          cursor="auto"
          bg="white"
          variant="outline"
          placeholder={inputProps.placeholder ?? EMAIL_PLACEHOLDER}
          value={value}
          onChange={e => onChange(e.target.value)}
          ref={toRef}
          zIndex={2}
          sx={{ maxWidth, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          {...inputProps}
        />
      </MaskedInput>

      {inputRightElement ? (
        inputRightElement({ mergeTagsMenu })
      ) : (
        <InputRightElement _focusWithin={{ zIndex: 3 }}>{mergeTagsMenu}</InputRightElement>
      )}
    </InputGroup>
  );
};
