import * as React from 'react';
import { Admin } from 'components/paywalls/preassigning-free-members/admin';
import { NonAdmin } from 'components/paywalls/preassigning-free-members/non-admin';
import { useAdminEmails } from 'components/paywalls/use-admin-emails';
import { mailto as buildMailTo } from '@process-street/subgrade/process';
import { usePaywall } from 'components/paywalls';
import { useInjector } from 'components/injection-provider';
import { createSelector } from 'reselect';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { useSelector } from 'react-redux';
import { useRoleNames } from 'hooks/use-role-names';

export type Props = {
  isOpen: boolean;
  onGoBack: () => void;
  userName: string;
  userRole?: OrganizationMembershipRole;
};

enum Mode {
  Hidden,
  Admin,
  NonAdmin,
}

const selector = createSelector(
  [OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId, SessionSelector.getCurrentUser],
  (currentOrganizationMembership, user) => {
    return {
      admin: currentOrganizationMembership?.role === OrganizationMembershipRole.Admin,
      currentUserName: user?.username,
    };
  },
);

const getMode = (isOpen: boolean, admin: boolean) => {
  if (!isOpen) {
    return Mode.Hidden;
  } else {
    return admin ? Mode.Admin : Mode.NonAdmin;
  }
};

export const PreassigningFreeMembersWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onGoBack,
  userName,
  userRole = OrganizationMembershipRole.FreeMember,
}) => {
  const { setPaywall } = usePaywall();
  const { $state } = useInjector('$state');
  const { admin, currentUserName } = useSelector(selector);
  const [adminEmail, ...bcc] = useAdminEmails();
  const roleNames = useRoleNames();
  const mode = getMode(isOpen, admin);

  React.useEffect(() => {
    const upgradeRoute = $state.href('organizationManage.tab', { tab: 'billing' });
    const mailto = buildMailTo({
      to: adminEmail,
      subject: `Someone on your team is requesting a role upgrade on your Process Street plan`,
      body: `An user on your Process Street account, ${currentUserName}, would like you to assign ${userName} to a workflow.
     Only ${roleNames.FullMember.plural} can be assigned to workflows.\n\nUpgrade here: ${upgradeRoute}`,
      bcc,
    });

    const paywall = {
      [Mode.Hidden]: null,
      [Mode.Admin]: <Admin onGoBack={onGoBack} userName={userName} isOpen={isOpen} userRole={userRole} />,
      [Mode.NonAdmin]: (
        <NonAdmin onGoBack={onGoBack} contactHref={mailto} userName={userName} isOpen={isOpen} userRole={userRole} />
      ),
    }[mode];

    setPaywall(paywall);

    return () => {
      setPaywall(null);
    };
  }, [
    setPaywall,
    isOpen,
    mode,
    userName,
    currentUserName,
    adminEmail,
    bcc,
    onGoBack,
    $state,
    userRole,
    roleNames.FullMember.plural,
  ]);

  return null;
};
