import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { RulesManagerHelper } from 'directives/rules/template/rules-manager/rules-manager-helper';
import { NodeInfoMap, SelectorHelper } from 'directives/rules/template/task-templates-selector/selector-helper';
import { NormalizedData } from 'features/conditional-logic/utils/conditional-logic-utils';
import keyBy from 'lodash/keyBy';

export type ConditionalLogicModalFormValues = {
  rules: ChecklistRuleDefinition[];
  nodeInfoMap: NodeInfoMap;
};

type GetConditionalLogicDeltaParams = {
  values: ConditionalLogicModalFormValues;
  widgets: Widget[];
  taskTemplates: TaskTemplate[];
  initialRules: ChecklistRuleDefinition[];
  normalizedData: NormalizedData;
};

export const getNewWidgetGroupIdsFromRule = (
  initialRule: ChecklistRuleDefinition,
  currentRule: ChecklistRuleDefinition,
) => {
  const initialWidgetGroupIds = new Set(initialRule.widgetGroupIds);

  return currentRule.widgetGroupIds.filter(widgetGroupId => !initialWidgetGroupIds.has(widgetGroupId));
};

export const getNewTaskTemplateGroupIdsFromRule = (
  initialRule: ChecklistRuleDefinition,
  currentRule: ChecklistRuleDefinition,
) => {
  const initialTaskTemplateGroupIds = new Set(initialRule.taskTemplateGroupIds);

  return currentRule.taskTemplateGroupIds.filter(ttGroupId => !initialTaskTemplateGroupIds.has(ttGroupId));
};

/*
 * Creates a map keyed by rule's id where the value is a set containing all the widgets and task templates that are
 * affected by the previous rules.
 */
export const groupTasksAndWidgetsAffectedByRuleAtIndex = (rules: ChecklistRuleDefinition[]) => {
  const { affectedWidgetsBeforeRuleMap, affectedTaskTemplatesBeforeRuleMap } = rules.reduce<{
    affectedWidgetsBeforeRuleMap: Record<string, Set<string>>;
    affectedTaskTemplatesBeforeRuleMap: Record<string, Set<string>>;
    affectedWidgetGroupIdsByIndex: Set<string>[];
    affectedTaskTemplateGroupIdsByIndex: Set<string>[];
  }>(
    (acc, rule, index) => {
      let widgetsAffectedByPreviousRules = new Set<string>();
      let taskTemplatesAffectedByPreviousRules = new Set<string>();

      if (index > 0) {
        widgetsAffectedByPreviousRules = acc.affectedWidgetGroupIdsByIndex[index - 1] ?? new Set<string>();
        taskTemplatesAffectedByPreviousRules = acc.affectedTaskTemplateGroupIdsByIndex[index - 1] ?? new Set<string>();
      }

      acc.affectedWidgetGroupIdsByIndex.push(new Set([...widgetsAffectedByPreviousRules, ...rule.widgetGroupIds]));
      acc.affectedTaskTemplateGroupIdsByIndex.push(
        new Set([...taskTemplatesAffectedByPreviousRules, ...rule.taskTemplateGroupIds]),
      );

      acc.affectedWidgetsBeforeRuleMap[rule.id] = widgetsAffectedByPreviousRules;
      acc.affectedTaskTemplatesBeforeRuleMap[rule.id] = taskTemplatesAffectedByPreviousRules;

      return acc;
    },
    {
      affectedWidgetGroupIdsByIndex: [],
      affectedTaskTemplateGroupIdsByIndex: [],
      affectedWidgetsBeforeRuleMap: {},
      affectedTaskTemplatesBeforeRuleMap: {},
    },
  );

  return { affectedWidgetsBeforeRuleMap, affectedTaskTemplatesBeforeRuleMap };
};

const getWidgetsAndTaskTemplatesToAutoHideForNewRules = (
  newRulesShowing: ChecklistRuleDefinition[],
  affectedTaskTemplatesBeforeRuleMap: Record<string, Set<string>>,
  affectedWidgetsBeforeRuleMap: Record<string, Set<string>>,
  normalizedData: NormalizedData,
) => {
  const widgets = new Set<Widget>();
  const taskTemplates = new Set<TaskTemplate>();

  newRulesShowing.forEach(newRule => {
    const widgetsAffectedByPreviousRules = affectedWidgetsBeforeRuleMap[newRule.id];
    const taskTemplatesAffectedByPreviousRules = affectedTaskTemplatesBeforeRuleMap[newRule.id];

    /* By using a `for` loop with `Math.max` we can loop only once instead of having a loop for widgetGroupIds
     * and other for taskTemplateGroupIds */
    for (let index = 0; index < Math.max(newRule.widgetGroupIds.length, newRule.taskTemplateGroupIds.length); index++) {
      const widgetGroupId = newRule.widgetGroupIds[index];
      const widget = normalizedData.widgets.byGroupId[widgetGroupId];

      if (widget && !widgetsAffectedByPreviousRules.has(widget.header.group.id)) {
        widgets.add(widget);
      }

      const taskTemplateGroupId = newRule.taskTemplateGroupIds[index];
      const taskTemplate = normalizedData.tasks.byGroupId[taskTemplateGroupId];

      if (taskTemplate && !taskTemplatesAffectedByPreviousRules.has(taskTemplate.group.id)) {
        taskTemplates.add(taskTemplate);
      }
    }
  });

  return { widgets, taskTemplates };
};

const getWidgetsAndTaskTemplatesToAutoHideForUpdatedRules = (
  updatedRulesShowing: ChecklistRuleDefinition[],
  affectedTaskTemplatesBeforeRuleMap: Record<string, Set<string>>,
  affectedWidgetsBeforeRuleMap: Record<string, Set<string>>,
  normalizedData: NormalizedData,
  initialRules: ChecklistRuleDefinition[],
) => {
  const initialRulesMap = keyBy(initialRules, rule => rule.id);
  const widgets = new Set<Widget>();
  const taskTemplates = new Set<TaskTemplate>();

  updatedRulesShowing.forEach(updatedRule => {
    const initialRule = initialRulesMap[updatedRule.id];

    const widgetsAffectedByPreviousRules = affectedWidgetsBeforeRuleMap[updatedRule.id];
    const taskTemplatesAffectedByPreviousRules = affectedTaskTemplatesBeforeRuleMap[updatedRule.id];
    const newWidgetGroupIds = new Set(getNewWidgetGroupIdsFromRule(initialRule, updatedRule));
    const newTaskTemplateGroupIds = new Set(getNewTaskTemplateGroupIdsFromRule(initialRule, updatedRule));

    const maxLength = Math.max(updatedRule.widgetGroupIds.length, updatedRule.taskTemplateGroupIds.length);
    let index = 0;

    while (index <= maxLength) {
      const widgetGroupId = updatedRule.widgetGroupIds[index];
      if (newWidgetGroupIds.has(widgetGroupId)) {
        const widget = normalizedData.widgets.byGroupId[widgetGroupId];

        if (widget && !widgetsAffectedByPreviousRules.has(widget.header.group.id)) {
          widgets.add(widget);
        }
      }

      const taskTemplateGroupId = updatedRule.taskTemplateGroupIds[index];
      if (newTaskTemplateGroupIds.has(taskTemplateGroupId)) {
        const taskTemplate = normalizedData.tasks.byGroupId[taskTemplateGroupId];

        if (taskTemplate && !taskTemplatesAffectedByPreviousRules.has(taskTemplate.group.id)) {
          taskTemplates.add(taskTemplate);
        }
      }

      index++;
    }
  });

  return { widgets, taskTemplates };
};

type GetWidgetsAndTaskTemplatesToAutoHideParams = {
  initialRules: ChecklistRuleDefinition[];
  finalRules: ChecklistRuleDefinition[];
  updatedRules: ChecklistRuleDefinition[];
  newRules: ChecklistRuleDefinition[];
  normalizedData: NormalizedData;
};

const getWidgetsAndTaskTemplatesToAutoHide = ({
  initialRules,
  finalRules,
  updatedRules,
  newRules,
  normalizedData,
}: GetWidgetsAndTaskTemplatesToAutoHideParams) => {
  const newRulesShowing = newRules.filter(r => !r.hidden);
  const updatedRulesShowing = updatedRules.filter(r => !r.hidden);

  const { affectedTaskTemplatesBeforeRuleMap, affectedWidgetsBeforeRuleMap } =
    groupTasksAndWidgetsAffectedByRuleAtIndex(finalRules);

  const autoHideForNewRules = getWidgetsAndTaskTemplatesToAutoHideForNewRules(
    newRulesShowing,
    affectedTaskTemplatesBeforeRuleMap,
    affectedWidgetsBeforeRuleMap,
    normalizedData,
  );

  const autoHideForUpdatedRules = getWidgetsAndTaskTemplatesToAutoHideForUpdatedRules(
    updatedRulesShowing,
    affectedTaskTemplatesBeforeRuleMap,
    affectedWidgetsBeforeRuleMap,
    normalizedData,
    initialRules,
  );

  return {
    widgets: [...autoHideForNewRules.widgets, ...autoHideForUpdatedRules.widgets],
    taskTemplates: [...autoHideForNewRules.taskTemplates, ...autoHideForUpdatedRules.taskTemplates],
  };
};

export const getConditionalLogicDelta = ({
  values,
  widgets,
  taskTemplates,
  initialRules,
  normalizedData,
}: GetConditionalLogicDeltaParams) => {
  const nodes = SelectorHelper.createNodes(taskTemplates, widgets);

  const initialHiddenByDefaultTasks = taskTemplates.filter(tt => Boolean(tt.hiddenByDefault));
  const initialHiddenByDefaultWidgets = widgets.filter(w => Boolean(w.header.hiddenByDefault));

  const { selectedWidgets: hiddenWidgets, selectedTaskTemplates: hiddenTaskTemplates } = SelectorHelper.getSelection(
    values.nodeInfoMap,
    nodes,
  );

  const { deletedRuleIds, updatedRules, newRules } = RulesManagerHelper.getDeltaUpdateForRules(
    initialRules,
    values.rules,
  );

  const autoHide = getWidgetsAndTaskTemplatesToAutoHide({
    initialRules,
    finalRules: values.rules,
    updatedRules,
    newRules,
    normalizedData,
  });

  const taskTemplatesHiddenByDefaultDelta = RulesManagerHelper.getDeltaUpdateForHiddenTasks(
    initialHiddenByDefaultTasks,
    [...hiddenTaskTemplates, ...autoHide.taskTemplates],
  );

  const widgetsHiddenByDefaultDelta = RulesManagerHelper.getDeltaUpdateForHiddenWidgets(initialHiddenByDefaultWidgets, [
    ...hiddenWidgets,
    ...autoHide.widgets,
  ]);

  const delta = {
    taskTemplatesHiddenByDefault: taskTemplatesHiddenByDefaultDelta,
    widgetsHiddenByDefault: widgetsHiddenByDefaultDelta,
    rules: {
      deletedRuleIds,
      newRules,
      updatedRules,
    },
  };

  return delta;
};
