import angular from 'angular';
import './editor.scss';

angular
  .module('frontStreetApp.controllers')
  .controller('EditorCtrl', function ($rootScope, $scope, $timeout, Subject, SecurityService) {
    // Hack until a better solution is found
    $timeout(() => {
      $rootScope.$broadcast('elastic:adjust');
    }, 500);

    $timeout(() => {
      $rootScope.$broadcast('elastic:adjust');
    }, 1000);

    $timeout(() => {
      $rootScope.$broadcast('elastic:adjust');
    }, 2000);

    $timeout(() => {
      $rootScope.$broadcast('elastic:adjust');
    }, 5000);

    $timeout(() => {
      $rootScope.$broadcast('elastic:adjust');
    }, 10000);

    const subject = new Subject($scope.user.id, SecurityService.getSelectedOrganizationIdByUser($scope.user));
    $scope.userIsDeveloper = subject.isDeveloper();
  });
