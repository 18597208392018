import { ActorRefFrom } from 'xstate';
import { TaskMachine } from './task-machine';
import { useSelector } from '@xstate/react';
import { TaskMachineSelectors } from './task-machine-selectors';
import { useSafeActor } from 'app/hooks/use-safe-actor';

export namespace TaskMachineHooks {
  export const useIsTaskComplete = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getIsCompleted);
  };

  export const useTask = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getTask);
  };
}
