import * as React from 'react';
import { Avatar, Box, Divider, HStack, Icon, IconButton, Link } from 'components/design/next';
import { Group, OrganizationMembershipWithUser, User } from '@process-street/subgrade/core';
import { Avatar as PSAvatar, AvatarSize, getAvatar } from 'components/common/Avatar';
import { AvatarGroup as PSAvatarGroup } from 'components/common/avatar-group';
import { useInjector } from 'components/injection-provider';

export interface GroupListItemProps {
  group: Group;
  groupMembers: OrganizationMembershipWithUser[];
}

const AVATAR_COUNT_LIMIT = 5;
const AVATAR_MARGIN = -1.5;

const RestCountAvatar = ({ restCount }: { restCount: number }) => {
  const initials = restCount < 100 ? `+${restCount}` : '99+';
  return <PSAvatar initials={initials} ml={AVATAR_MARGIN} initialsProps={{ backgroundColor: 'gray.400' }} />;
};

export const GroupListItem: React.FC<React.PropsWithChildren<GroupListItemProps>> = ({ group, groupMembers }) => {
  const user = group.user as User;
  const avatar = getAvatar(user);
  const { $state } = useInjector('$state');

  const groupHref = $state.href('groupManage.tab', {
    id: group.id,
    tab: 'members',
  });

  const editGroup = () => {
    $state.go('groupManage.tab', {
      id: group.id,
      tab: 'members',
    });
  };

  return (
    <>
      <HStack>
        <Avatar
          key={user.id}
          showBorder={true}
          name={avatar.title}
          title={avatar.title}
          src={avatar.url}
          getInitials={() => avatar.initials}
        />
        <Box w="340px">
          <Link href={groupHref} variant="noline" color="brand.500" fontWeight="regular" noOfLines={1}>
            {user.username}
          </Link>
        </Box>
        <PSAvatarGroup w="280px" max={AVATAR_COUNT_LIMIT} restCountAvatar={RestCountAvatar}>
          {groupMembers.map((organizationMembership, index) => (
            <PSAvatar
              user={organizationMembership.user}
              size={AvatarSize.Normal}
              key={index}
              ml={index === 0 ? 0 : AVATAR_MARGIN}
            />
          ))}
        </PSAvatarGroup>
        <IconButton
          color="gray.500"
          aria-label="Edit group"
          variant="ghost"
          icon={<Icon variant="far" icon="pencil" size="4" />}
          onClick={editGroup}
        />
      </HStack>
      <Divider marginY={2} />
    </>
  );
};
