import * as React from 'react';
import { FilteredItem } from './use-columns-filter-state';
import { Button, Icon, Text } from 'components/design/next';
import { DropdownIndicatorProps } from 'react-select';
import { useChecklistDashboardFiltersContext } from './context';

export const CustomDropdownIndicator = (_props: DropdownIndicatorProps<FilteredItem, true>) => {
  const { columnsChanged, label } = useChecklistDashboardFiltersContext();
  return (
    <Button
      size="sm"
      leftIcon={<Icon icon="columns" size="4" variant="far" />}
      rightIcon={<Icon icon="chevron-down" size="4" variant="far" />}
      variant="ghost"
      width={{ base: 'full', md: 'auto' }}
      {...{
        ...(columnsChanged
          ? {
              fontWeight: 'medium',
              colorScheme: 'brand',
            }
          : {
              fontWeight: 'normal',
              colorScheme: 'gray',
            }),
      }}
    >
      <Text mr="auto" as="span" variant="inherit">
        {label}
      </Text>
    </Button>
  );
};
