import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { match, P } from 'ts-pattern';

export interface AvatarGroupProps extends BoxProps {
  max: number;
  restCountAvatar: React.FC<React.PropsWithChildren<{ restCount: number }>>;
  /** Items that render after the children */
  rightItem?: React.ReactNode;
  children?: React.ReactNode[];
}

/**
 * @deprecated use ChakraAvatarGroup instead
 * Similar to Chakra's AvatarGroup, but the "remaining count" avatar is customizable.
 * Children should be the avatars. */
export const AvatarGroup = ({
  max,
  restCountAvatar: RestCountAvatar,
  children = [],
  rightItem,
  ...rest
}: AvatarGroupProps) => {
  const limited = children.slice(0, max - 1);
  const restCount = children.length - limited.length;
  const lastItem = match(restCount)
    .with(1, () => children[max - 1])
    .with(
      P.when(c => (c as number) > 1),
      () => <RestCountAvatar restCount={restCount} key={'restCount'} />,
    )
    .otherwise(() => null);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" {...rest}>
      {limited}
      {lastItem}
      {rightItem}
    </Box>
  );
};
