import { Muid, Organization } from '@process-street/subgrade/core';
import { Filter } from '@process-street/subgrade/dashboard';
import { AppConfig } from 'components/common/app-config';
import { ExportCsvOption, Sorting } from 'components/dashboard/models/filters';
import { ColumnOrderMap } from 'components/dashboard/models/grid';
import React, { useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { TemplateType } from '@process-street/subgrade/process';

export interface ChecklistGridExportFormBaseFields {
  conditionalFilter?: Filter[];
  orderMap?: ColumnOrderMap;
  searchPattern?: string;
  selectedTemplates?: Muid[];
  sorting?: Sorting;
  templateType?: TemplateType;
  visibleColumns?: string[];
  sortByWidgetGroupId?: Muid;
}

export interface ChecklistGridExportFormFields extends ChecklistGridExportFormBaseFields {
  accessToken: string;
  organizationId: Organization['id'];
}

export interface ChecklistGridExportFormResult {
  onFormSubmit: (option: ExportCsvOption) => void;
  ChecklistGridExportForm: React.FC<React.PropsWithChildren<unknown>>;
}

export const useChecklistGridExportForm: (fields: ChecklistGridExportFormFields) => ChecklistGridExportFormResult = ({
  accessToken,
  conditionalFilter,
  orderMap,
  organizationId,
  searchPattern,
  selectedTemplates,
  sorting,
  templateType,
  visibleColumns,
  sortByWidgetGroupId,
}) => {
  const TOKEN_COOKIE_KEY = 'st.process.app.authToken';
  const COOKIE_DOMAIN = new URL(AppConfig.url!).hostname.split('.').slice(-2).join('.');
  const [, setCookie] = useCookies([TOKEN_COOKIE_KEY]);

  const exportFormRef = useRef<HTMLFormElement>(null);
  const [exportOption, setExportOption] = useState<ExportCsvOption>(ExportCsvOption.ExportVisible);
  const onFormSubmit = (option: ExportCsvOption) => {
    setCookie(TOKEN_COOKIE_KEY, accessToken, {
      domain: `.${COOKIE_DOMAIN}`,
      maxAge: 5 * 60,
      path: '/',
      secure: true,
    });
    setExportOption(option);

    setTimeout(() => {
      // The timeout is needed because the form is not getting updated before submission otherwise
      if (exportFormRef.current) {
        exportFormRef.current.submit();
      }
    });
  };

  const visibleColumnsValue =
    exportOption === ExportCsvOption.ExportVisible ? JSON.stringify(visibleColumns ?? []) : JSON.stringify([]);

  const ChecklistGridExportForm = React.useCallback(
    () => (
      <form
        ref={exportFormRef}
        target="_blank"
        method="post"
        action={`${AppConfig.apiUrl}/1/organizations/${organizationId}/checklist-grid/export`}
      >
        {selectedTemplates && selectedTemplates.length > 0 && (
          <input type="hidden" name="templateIds" value={JSON.stringify(selectedTemplates)} />
        )}
        {searchPattern && <input type="hidden" name="searchPattern" value={searchPattern} />}
        {orderMap && <input type="hidden" name="columnOrder" value={JSON.stringify(orderMap)} />}
        <input type="hidden" name="visibleColumns" value={visibleColumnsValue} />
        {conditionalFilter && <input type="hidden" name="filter" value={JSON.stringify(conditionalFilter)} />}
        {templateType && <input type="hidden" name="templateType" value={templateType} />}
        {sortByWidgetGroupId && <input type="hidden" name="sortByWidgetGroupId" value={sortByWidgetGroupId} />}
        {sorting && (
          <>
            <input type="hidden" name="sortBy" value={sorting.sortColumn} />
            <input type="hidden" name="sortAsc" value={sorting.sortAsc ? 'true' : 'false'} />
          </>
        )}
      </form>
    ),
    [
      conditionalFilter,
      orderMap,
      organizationId,
      searchPattern,
      selectedTemplates,
      sorting,
      templateType,
      visibleColumnsValue,
      sortByWidgetGroupId,
    ],
  );

  return {
    ChecklistGridExportForm,
    onFormSubmit,
  };
};
