import { IncomingWebhook, IncomingWebhookOptimistic } from '@process-street/subgrade/automation';
import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetAllIncomingWebhooksByTemplateIdQueryParams = {
  templateId?: Muid;
};

export type GetAllIncomingWebhooksByTemplateIdQueryResponse = (IncomingWebhook | IncomingWebhookOptimistic)[];

export const GetAllIncomingWebhooksByTemplateIdQuery = {
  key: ['templates', 'incoming-webhooks'],
  getKey: (params: GetAllIncomingWebhooksByTemplateIdQueryParams): QueryKey => [
    ...GetAllIncomingWebhooksByTemplateIdQuery.key,
    params,
  ],
  queryFn: (params: GetAllIncomingWebhooksByTemplateIdQueryParams) =>
    axiosService
      .getAxios()
      .get<GetAllIncomingWebhooksByTemplateIdQueryResponse>(`/1/templates/${params.templateId}/incoming-webhooks`)
      .then(res => res.data),
};

export const useGetAllIncomingWebhooksByTemplateIdQuery = <Select = GetAllIncomingWebhooksByTemplateIdQueryResponse>(
  params: GetAllIncomingWebhooksByTemplateIdQueryParams,
  options: UseQueryOptions<GetAllIncomingWebhooksByTemplateIdQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetAllIncomingWebhooksByTemplateIdQuery.getKey(params),
    () => GetAllIncomingWebhooksByTemplateIdQuery.queryFn(params),
    {
      ...options,
      enabled: Boolean(params.templateId) && options.enabled !== false,
    },
  );
};
