import { Icon, IconButton, IconButtonProps, Tooltip } from 'components/design/next';
import * as React from 'react';

export const RemoveAssigneeButton = (props: Partial<IconButtonProps>) => (
  <Tooltip label="Remove assignee">
    <IconButton
      size="md"
      color="gray.500"
      _hover={{ color: 'brand.500' }}
      variant="unstyled"
      aria-label="Remove"
      icon={<Icon variant="far" size="5" icon="user-times" />}
      {...props}
    />
  </Tooltip>
);
