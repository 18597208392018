import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { FormEditorPageMachine, useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { StateFrom } from 'xstate';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';
import { useSelector } from '@xstate/react';
import _isEqual from 'lodash/isEqual';

type Context = {
  hasConditionalLogic: boolean;
};

export const [useTaskConditionalLogicContext, TaskConditionalLogicContext] = createUsableContext<Context>({
  hookName: 'usePrintRulesContext',
  providerName: 'PrintRulesContext',
});

type Props = {
  isTaskTemplateAssociatedWithRules: (args: { widgets: Array<Widget>; taskTemplate: TaskTemplate }) => boolean;
  taskTemplate: TaskTemplate;
};

export const TaskConditionalLogicContextProvider: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  isTaskTemplateAssociatedWithRules,
  taskTemplate,
}) => {
  const actor = useFormEditorPageActorRef();
  const getChildWidgetsSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return FormEditorPageActorSelectors.getWidgetsForTaskTemplateId(taskTemplate.id)(state);
    },
    [taskTemplate.id],
  );

  const childWidgets = useSelector(actor, getChildWidgetsSelector, _isEqual);
  const hasConditionalLogic = React.useMemo(
    () =>
      isTaskTemplateAssociatedWithRules({
        widgets: childWidgets,
        taskTemplate,
      }),
    [childWidgets, isTaskTemplateAssociatedWithRules, taskTemplate],
  );

  const value = React.useMemo(() => ({ hasConditionalLogic }), [hasConditionalLogic]);

  return <TaskConditionalLogicContext.Provider value={value}>{children}</TaskConditionalLogicContext.Provider>;
};
