import { AxiosError } from 'axios';
import { useToast } from 'components/design/next';
import { GetIncomingWebhookJsonPathsQueryResponse } from 'features/automations/query-builder';
import { useFunctionRef } from 'hooks/use-function-ref';
import * as React from 'react';
import { QueryObserverResult } from 'react-query';

export function useDelayedTriggerTest(
  pathsQuery: QueryObserverResult<GetIncomingWebhookJsonPathsQueryResponse, AxiosError>,
) {
  const toast = useToast();
  const refetchRef = useFunctionRef(pathsQuery.refetch);

  const [delayedTestIsOn, setDelayedTestIsOn] = React.useState(false);
  return React.useMemo(() => {
    const handleRefetch = () => {
      setDelayedTestIsOn(true);
      setTimeout(() => {
        refetchRef
          .current()
          .then(result => {
            if (result.isSuccess && (result.data?.paths.length ?? 0) > 0) {
              toast({
                title: 'Payload refreshed',
                status: 'success',
              });
            }
          })
          .finally(() => {
            setDelayedTestIsOn(false);
          });
      }, 2000);
    };

    return {
      isLoading: delayedTestIsOn,
      handleTest: handleRefetch,
    };
  }, [delayedTestIsOn, refetchRef, toast]);
}
