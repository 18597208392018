import { Muid } from '@process-street/subgrade/core';
import { FileFormFieldValue } from '@process-street/subgrade/process';
import { axiosService } from 'services/axios-service';

export type FinishWidgetUploadByChecklistRevisionIdMutationParams = {
  checklistRevisionId: Muid;
  widgetId: Muid;
  contentType: string;
  key: string;
  originalFilename: string;
};
export type FinishWidgetUploadByChecklistRevisionIdMutationResponse = { formFieldValue: FileFormFieldValue };

export const FinishWidgetUploadByChecklistRevisionIdMutation = {
  key: ['widget', 'attachment'],
  mutationFn: (params: FinishWidgetUploadByChecklistRevisionIdMutationParams) =>
    axiosService
      .getAxios()
      .post<FinishWidgetUploadByChecklistRevisionIdMutationResponse>(
        `/1/checklist-revisions/${params.checklistRevisionId}/widgets/${params.widgetId}/finish-upload`,
        params,
      )
      .then(r => r.data),
};
