import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { CHECKLIST_PUT } from 'reducers/checklist/checklist.actions';
import {
  appendOnPutReducerObject,
  appendOnSingleElementPutReducerObject,
  referencesNormalizer,
} from 'reducers/entities/entities.utils';

import { INBOX_GET_ALL_BY_ORGANIZATION_ID } from 'reducers/inbox/inbox.actions';
import { TEMPLATE_PERMIT_PUT } from 'reducers/template-permit/template-permit.actions';
import {
  TEMPLATE_REVISION_MULTIPLE_RESPONSE_ACTIONS,
  TEMPLATE_REVISION_SINGLE_RESPONSE_ACTIONS,
} from 'reducers/template-revision/template-revision.actions';
import {
  TEMPLATE_COPY,
  TEMPLATE_CREATE,
  TEMPLATE_DELETE_BY_ID,
  TEMPLATE_GET_ALL_BY_ORGANIZATION_ID_AND_STATUS,
  TEMPLATE_GET_ALL_BY_ORGANIZATION_ID_AND_STATUS_FOR_SCHEDULE,
  TEMPLATE_GET_BY_ID,
  TEMPLATE_GET_PREMADE_BY_ID,
  TEMPLATE_SET,
  TEMPLATE_UNDELETE,
  TEMPLATE_UPDATE,
  TEMPLATE_UPDATE_PUBLIC,
  TEMPLATE_UPDATE_SHARE_LEVEL,
  TEMPLATE_UPDATE_SHAREABLE_RUN_LINK,
  TEMPLATE_UPDATE_STATUS,
} from 'reducers/template/template.actions';
import { composeReducerObjects, toSuccess } from 'reducers/util';
import { combineReducers } from 'redux';
import { getStatusReducer } from '../statuses/statuses-utils';

const normalizeTemplate = referencesNormalizer(['organization', 'folder']);

export const templateAdditionalEntitiesReducer = {
  [INBOX_GET_ALL_BY_ORGANIZATION_ID]: (state, { payload: items }) => {
    const inboxTemplates = items.map(item => item.template).filter(template => template.audit !== undefined);
    if (inboxTemplates.length > 0) {
      return EntitiesReducerUtils.upsertAll(state, inboxTemplates, normalizeTemplate);
    } else {
      return state;
    }
  },
  [TEMPLATE_DELETE_BY_ID]: (state, { meta: { templateId } }) => {
    const newState = { ...state };
    delete newState[templateId];
    return newState;
  },
  [TEMPLATE_CREATE]: (state, { payload: template }) => EntitiesReducerUtils.upsert(state, template, normalizeTemplate),
  [TEMPLATE_SET]: (state, { payload: { template } }) => EntitiesReducerUtils.upsert(state, template, normalizeTemplate),
  [TEMPLATE_UPDATE]: (state, { payload: template }) => EntitiesReducerUtils.upsert(state, template, normalizeTemplate),
  [TEMPLATE_UNDELETE]: (state, { payload: template }) =>
    EntitiesReducerUtils.upsert(state, template, normalizeTemplate),
  [TEMPLATE_COPY]: (state, { payload: template }) => EntitiesReducerUtils.upsert(state, template, normalizeTemplate),
  [TEMPLATE_UPDATE_STATUS]: (state, { payload: template }) =>
    EntitiesReducerUtils.upsert(state, template, normalizeTemplate),
  [TEMPLATE_UPDATE_PUBLIC]: (state, { payload: template }) =>
    EntitiesReducerUtils.upsert(state, template, normalizeTemplate),
  [TEMPLATE_UPDATE_SHARE_LEVEL]: (state, { payload: template }) =>
    EntitiesReducerUtils.upsert(state, template, normalizeTemplate),
  [TEMPLATE_UPDATE_SHAREABLE_RUN_LINK]: (state, { meta: { templateId, sharedRunLink } }) => {
    const template = state[templateId];
    if (template) {
      const updatedTemplate = { ...template, sharedRunLink };
      return {
        ...state,
        [templateId]: normalizeTemplate(updatedTemplate),
      };
    } else {
      return state;
    }
  },
  [TEMPLATE_GET_BY_ID]: (state, { payload: template }) =>
    EntitiesReducerUtils.upsert(state, template, normalizeTemplate),
  [toSuccess(TEMPLATE_GET_BY_ID)]: (state, { payload: template }) =>
    EntitiesReducerUtils.upsert(state, template, normalizeTemplate),
  [TEMPLATE_GET_PREMADE_BY_ID]: (state, { payload: template }) =>
    EntitiesReducerUtils.upsert(state, template, normalizeTemplate),
  [TEMPLATE_GET_ALL_BY_ORGANIZATION_ID_AND_STATUS]: (state, { payload: templates }) =>
    EntitiesReducerUtils.upsertAll(state, templates, normalizeTemplate),
  [TEMPLATE_GET_ALL_BY_ORGANIZATION_ID_AND_STATUS_FOR_SCHEDULE]: (state, { payload: templates }) =>
    EntitiesReducerUtils.upsertAll(state, templates, normalizeTemplate),
};

export const templateEntitiesReducer = composeReducerObjects(
  templateAdditionalEntitiesReducer,

  appendOnSingleElementPutReducerObject(TEMPLATE_REVISION_SINGLE_RESPONSE_ACTIONS, 'template', normalizeTemplate),
  appendOnPutReducerObject(TEMPLATE_REVISION_MULTIPLE_RESPONSE_ACTIONS, 'template', normalizeTemplate),

  appendOnPutReducerObject(TEMPLATE_PERMIT_PUT, 'template', normalizeTemplate),
  appendOnPutReducerObject(CHECKLIST_PUT, 'template', normalizeTemplate),
);

export const templateStatusesReducer = combineReducers({
  byStatus: getStatusReducer(TEMPLATE_GET_ALL_BY_ORGANIZATION_ID_AND_STATUS, 'status'),
});
