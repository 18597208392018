import { ICellRendererParams } from '@ag-grid-community/core';
import classNames from 'classnames';
import { NameValue } from 'components/dashboard/models/grid';
import { GridHelper } from 'components/dashboard/services/grid-helper';
import { Box } from 'components/design/next';
import { MergeTagHighlighter } from 'features/merge-tags/components/merge-tag-highlighter';
import * as React from 'react';
import { RunWorkflowRenderer } from '../RunWorkflowRenderer';
import styles from './NameRenderer.module.scss';

export interface NameRendererProps extends Partial<ICellRendererParams> {
  value?: NameValue;
}

const NameSkeleton = ({ rowIndex }: { rowIndex: number }) => {
  const sizes = ['50', '60', '54', '42', '46'];

  return <Box w={sizes[rowIndex % sizes.length]} h="4" bgColor="gray.100" borderRadius="xs" />;
};

export const NameRenderer: React.FC<React.PropsWithChildren<NameRendererProps>> = ({ value, rowIndex, data }) => {
  if (!value && GridHelper.isEmptyStateRow(data)) return <NameSkeleton rowIndex={rowIndex ?? 0} />;

  if (!value) {
    return (
      <div className={styles.skeletonWrapperBody} role="alert" aria-busy="true">
        <div className={styles.skeletonChecklistName} />
      </div>
    );
  }

  if (value.checklistId === GridHelper.getRunWorkflowRowId()) {
    return <RunWorkflowRenderer />;
  }

  const checklistLink = `/checklists/${value.checklistId}`;

  const name = value.checklistName ?? 'untitled';

  return (
    <div className={styles.nameRenderer}>
      <a className={classNames('truncate-text', styles.checklistName)} href={checklistLink}>
        <MergeTagHighlighter lineHeight="1.3" content={name} />
      </a>
    </div>
  );
};
