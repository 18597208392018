import { StateParams, StateService } from '@uirouter/core';
import { auto, ILocationService, IRootScopeService, ITimeoutService } from 'angular';
import { PlanLevelSelectorService } from 'app/directives/billing/plan-level-selector/plan-level-selector-service.interface';
import { ResponseUtilsService } from 'components/utils/response-utils.service';
import { BillingService } from 'directives/billing/billing-service.interface';
import { BillingTabService } from 'pages/organizations/manage/billing/services/billing-tab-service';
import { OrganizationMembershipRoleService } from 'pages/organizations/manage/users/services/organization-membership-role.service';
import * as React from 'react';
import { Store } from 'redux';
import { AuthService } from 'services/auth.interface';
import { Auth0Service } from 'services/auth0-service.interface';
import { InvitationService } from 'services/invitation-service.interface';
import { OrganizationMembershipService } from 'services/organization-membership-service.interface';
import { OrganizationService } from 'services/organization-service.interface';
import { PlanService } from 'services/plan-service.interface';
import { SecurityService } from 'services/security/security-service.interface';
import { SessionService } from 'services/session-service.interface';
import { TempDataService } from 'services/temp-data-service.interface';
import { TemplateService } from 'services/template-service.interface';
import { UserService } from 'services/user-service.interface';
import { IOrganizationMembershipActions } from 'reducers/organization-membership/organization-membership.actions';
import { IRoleAssignmentRulesActions } from 'components/role-assignments/store/role-assignment-rules.actions.interface';
import { TaskTemplateActions } from 'reducers/task-template/task-template.actions';
import { ITemplateTaskAssignmentActions } from 'components/template-task-assignment/store/template-task-assignment.actions.interface';
import { WidgetActions } from 'components/widgets/store/widget.actions';
import { PremadeTemplateService } from 'services/premade-template-service';
import { useDeepCompareEffect } from 'react-use';
import { CsvExportModalService } from 'components/csv-export-modal/csv-export-modal.service';
import { MessageBox } from 'services/message-box.interface';
import { SignUpService } from 'services/sign-up-service.interface';
import { TemplateActions } from 'reducers/template/template.actions.interface';
import { ChecklistService } from 'services/checklist-service.interface';
import { ConditionalsButtonService } from 'directives/rules/template/conditionals-button/conditionals-button.service';
import { TaskTemplateService } from 'services/task-template-service.interface';
import { TaskTemplateListService } from 'directives/task-template-list/task-template-list-service.interface';
import { WidgetService } from 'services/widget-service.interface';
import { AngularInjectorContext } from 'angulareact';
import { MergeTagsService } from 'services/merge-tags';
import { ElevioService } from 'services/interop/elevio-service.interface';
import { ToastService } from 'services/toast-service.interface';
import { AnonymousAuthService } from 'services/AnonymousAuthService';
import { DynamicDueDateService } from 'components/dynamic-due-dates/services/dynamic-due-date.service.inteface';
import { TaskPermissionRuleService } from 'components/task-permission/service/task-permission-rule.service';
import { TemplateActivityService } from 'services/activities/template-activity-service.interface';
import { UibModalService } from 'services/uib-modal-service.interface';
import { PromiseQueueService } from 'app/services/promise-queue/promise-queue-service.interface';
import { UserSettingsService } from '../user/UserSettingsService';

// If you're here because TS said your dependency didn't exist on useInjector(...),
// you just need to add the type to InjectableServices.
export type InjectableServices = {
  $location: ILocationService;
  $ngRedux: Store;
  $rootScope: IRootScopeService;
  $state: StateService;
  $stateParams: StateParams;
  $timeout: ITimeoutService;
  $uibModal: UibModalService;
  AnonymousAuthService: AnonymousAuthService;
  auth: AuthService;
  Auth0Service: Auth0Service;
  BillingService: BillingService;
  BillingTabService: BillingTabService;
  ChecklistService: ChecklistService;
  ConditionalsButtonService: ConditionalsButtonService;
  CsvExportModalService: CsvExportModalService;
  DynamicDueDateService: DynamicDueDateService;
  ElevioService: ElevioService;
  InvitationService: InvitationService;
  MergeTagsService: MergeTagsService;
  MessageBox: MessageBox;
  OrganizationMembershipActions: IOrganizationMembershipActions;
  OrganizationMembershipRoleService: OrganizationMembershipRoleService;
  OrganizationMembershipService: OrganizationMembershipService;
  OrganizationService: OrganizationService;
  PlanLevelSelectorService: PlanLevelSelectorService;
  PlanService: PlanService;
  PremadeTemplateService: PremadeTemplateService;
  PromiseQueueService: PromiseQueueService;
  ResponseUtilsService: ResponseUtilsService;
  RoleAssignmentRulesActions: IRoleAssignmentRulesActions;
  SecurityService: SecurityService;
  SessionService: SessionService;
  SignUpService: SignUpService;
  TaskPermissionRuleService: TaskPermissionRuleService;
  TaskTemplateActions: TaskTemplateActions;
  TaskTemplateListService: TaskTemplateListService;
  TaskTemplateService: TaskTemplateService;
  TempDataService: TempDataService;
  TemplateActions: TemplateActions;
  TemplateActivityService: TemplateActivityService;
  TemplateService: TemplateService;
  TemplateTaskAssignmentActions: ITemplateTaskAssignmentActions;
  ToastService: ToastService;
  UserService: UserService;
  UserSettingsService: UserSettingsService;
  WidgetActions: WidgetActions;
  WidgetService: WidgetService;
};

export type InjectorProps = { $injector: auto.IInjectorService };

/**
 * @deprecated use React Query, navigation adapters, EventEmitterService etc.
 * Start a discussion with the team about how to convert Angular services to React.
 */
export function useInjector<Key extends keyof InjectableServices>(...serviceKeys: Key[]) {
  type Result = Pick<InjectableServices, Key>;
  const $injector = React.useContext(AngularInjectorContext);

  const reduceServices = React.useCallback(
    (acc: Result, el: Key) => {
      acc[el] = $injector.get<InjectableServices[Key]>(el);
      return acc;
    },
    [$injector],
  );

  const [services, setServices] = React.useState(() => serviceKeys.reduce(reduceServices, {} as Result));

  useDeepCompareEffect(() => {
    setServices(serviceKeys.reduce(reduceServices, {} as Pick<InjectableServices, Key>));
  }, [serviceKeys]);

  return services;
}

export const INJECTABLE_SERVICES: (keyof InjectableServices)[] = [
  '$location',
  '$ngRedux',
  '$rootScope',
  '$state',
  '$stateParams',
  '$timeout',
  '$uibModal',
  'AnonymousAuthService',
  'auth',
  'Auth0Service',
  'BillingService',
  'BillingTabService',
  'ChecklistService',
  'ConditionalsButtonService',
  'CsvExportModalService',
  'DynamicDueDateService',
  'ElevioService',
  'InvitationService',
  'MergeTagsService',
  'MessageBox',
  'OrganizationMembershipActions',
  'OrganizationMembershipRoleService',
  'OrganizationMembershipService',
  'OrganizationService',
  'PlanLevelSelectorService',
  'PlanService',
  'PremadeTemplateService',
  'PromiseQueueService',
  'ResponseUtilsService',
  'RoleAssignmentRulesActions',
  'SecurityService',
  'SessionService',
  'SignUpService',
  'TaskPermissionRuleService',
  'TaskTemplateActions',
  'TaskTemplateListService',
  'TaskTemplateService',
  'TempDataService',
  'TemplateActions',
  'TemplateService',
  'TemplateTaskAssignmentActions',
  'ToastService',
  'UserService',
  'UserSettingsService',
  'WidgetActions',
  'WidgetService',
];
