import { GetIncomingWebhookJsonPathsQueryResponse } from 'features/automations/query-builder';
import { match } from 'ts-pattern';
import { createMachine, MachineConfig } from 'xstate';

// The contextual data for this all lives in RQ cache, so keeping that separate and firing events on user actions and fetch results
type Context = {};

export type Event =
  | { type: 'COPIED'; hasFeaturedEvents: boolean }
  | { type: 'TEST_RECEIVED'; result: GetIncomingWebhookJsonPathsQueryResponse }
  | { type: 'TEST_SKIPPED' }
  | { type: 'FEATURED_EVENT_SELECTED' };

export type State = {
  value:
    | 'initial'
    | 'uncopied'
    | 'listeningForTest'
    | 'testError'
    | 'selectingFeaturedEvent'
    | 'editing'
    | 'editing.initial'
    | 'editing.testError';
  context: {};
};

type Guard = {
  type: 'noValidPaths';
};

/** Utility for typing strings correctly for guards */
function guardCIF<G extends Guard['type']>(guard: G) {
  return guard;
}

export const makeIncomingWebhookEditorMachine = (overrides: Partial<MachineConfig<Context, any, Event>> = {}) =>
  createMachine<Context, Event, State>(
    {
      // schema is for type inference
      schema: {
        guards: {} as Guard,
      },
      predictableActionArguments: true,
      initial: 'initial',
      states: {
        initial: {
          on: {
            TEST_RECEIVED: [
              {
                target: 'uncopied',
                cond: guardCIF('noValidPaths'),
              },
              {
                target: 'editing',
              },
            ],
          },
        },
        uncopied: {
          on: {
            COPIED: [
              { target: 'selectingFeaturedEvent', cond: (_ctx, evt) => evt.hasFeaturedEvents },
              { target: 'listeningForTest' },
            ],
          },
        },
        listeningForTest: {
          on: {
            TEST_RECEIVED: [
              {
                target: 'testError',
                cond: guardCIF('noValidPaths'),
              },
              {
                target: 'editing',
              },
            ],
            TEST_SKIPPED: 'editing',
          },
        },
        testError: {
          on: {
            TEST_RECEIVED: [
              {
                target: 'testError',
                cond: (_: unknown, evt: Extract<Event, { type: 'TEST_RECEIVED' }>) =>
                  evt.result === undefined || evt.result.paths.length === 0,
              },
              {
                target: 'editing',
              },
            ],
            TEST_SKIPPED: 'editing',
          },
        },
        selectingFeaturedEvent: {
          on: {
            FEATURED_EVENT_SELECTED: 'editing',
          },
        },
        editing: {
          initial: 'initial',
          states: {
            initial: {
              on: {
                TEST_RECEIVED: [
                  {
                    target: 'testError',
                    cond: guardCIF('noValidPaths'),
                  },
                ],
              },
            },
            testError: {
              on: {
                TEST_RECEIVED: [
                  {
                    target: 'testError',
                    cond: guardCIF('noValidPaths'),
                  },
                  { target: 'initial' },
                ],
                TEST_SKIPPED: 'initial',
              },
            },
          },
        },
      },
      ...overrides,
    },
    {
      guards: {
        noValidPaths: (_ctx, evt) =>
          match(evt)
            .with({ type: 'TEST_RECEIVED' }, ({ result }) => result === undefined || result.paths.length === 0)
            .otherwise(() => true),
      },
    },
  );
