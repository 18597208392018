import * as React from 'react';
import { useLibraryContext } from '../context';
import {
  Badge,
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  IconProps,
  Link,
  LinkProps,
  MotionWrapper,
  Skeleton,
  Spacer,
  Stack,
  Text,
  VStack,
} from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { useFoldersQuery } from 'pages/library/components/template-library/query';
import { findOrganizationRootFolder, findPrivateRootFolder } from 'features/folder/lib';
import { useGetOrganizationTagsQuery, useGetTagMembershipsCountsQuery } from 'features/tags/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { match } from 'ts-pattern';
import { TemplateStatus } from '@process-street/subgrade/process';
import { AnimatePresence } from 'framer-motion';
import { useRoleNames } from 'hooks/use-role-names';
import { CreateTemplateSource } from 'services/template-service.interface';
import { CreateActions } from './create-actions';
import { TOP_BAR_HEIGHT_CSS_VAR } from 'pages/forms/_id/shared';

export const LeftNav: React.FC<React.PropsWithChildren<unknown>> = () => {
  const userIsGuest = useSelector(SessionSelector.isUserGuestOfSelectedOrganization);
  const org = useSelector(SessionSelector.getSelectedOrganization);
  const roleNames = useRoleNames();

  const { view, path, leftNavState, setLeftNavState } = useLibraryContext();
  const { $state } = useInjector('$state');
  const foldersSlugQuery = useFoldersQuery({
    select: folders => {
      return {
        librarySlug: findOrganizationRootFolder(folders)?.slug,
        privateLibrarySlug: findPrivateRootFolder(folders)?.slug,
      };
    },
  });

  const isOpen = leftNavState === 'open';

  const btnRef = React.useRef<HTMLButtonElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  return (
    <Flex
      aria-label="Library"
      role="navigation"
      position={['fixed', 'relative']}
      bg="gray.50"
      boxShadow="base"
      transition="all 0.2s"
      height={[`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR})`, 'full']}
      zIndex="3"
      {...(isOpen
        ? {
            minW: 'xs',
            maxW: { base: 'full', sm: 'xs' },
            px: { sm: '0' },
            py: { sm: '0' },
          }
        : {
            minW: '0',
            w: '0',
            px: '0',
          })}
    >
      <Flex
        alignItems="center"
        h="8"
        position="absolute"
        top="4"
        right={isOpen ? `-${(btnRef.current?.offsetWidth ?? 0) / 2}px` : `-${btnRef.current?.offsetWidth ?? 0}px`}
      >
        <IconButton
          ref={btnRef}
          aria-label={`${isOpen ? 'Close' : 'Open'} left navigation`}
          icon={<Icon icon={isOpen ? 'chevron-left' : 'chevron-right'} size="3" variant="far" />}
          bg="white"
          variant="outline"
          borderRadius="full"
          size="xs"
          onClick={() => setLeftNavState(isOpen ? 'closed' : 'open')}
          colorScheme="gray"
        />
      </Flex>
      <AnimatePresence>
        {isOpen ? (
          <MotionWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: isOpen ? 0 : 0.5 }}
          >
            <Flex overflowY="auto" w="full" direction="column">
              <Stack justify="space-between" spacing="1" width="full" overflowY="auto" flex="1" ref={containerRef}>
                {userIsGuest ? (
                  <VStack alignItems="flex-start">
                    <Text>
                      You are a {roleNames.Guest.single} of <Text as="b">{org?.name ?? 'this organization'}</Text>.
                    </Text>
                    <Text>
                      Visit the{' '}
                      <Link color="brand.500" href={$state.href('myWork')}>
                        inbox
                      </Link>{' '}
                      to complete workflow runs you have been invited to.
                    </Text>
                  </VStack>
                ) : (
                  <Stack
                    justify="space-between"
                    h="full"
                    {...(isOpen
                      ? {
                          py: { base: '4', sm: '6' },
                          px: { base: '4', sm: '6' },
                        }
                      : { px: 0 })}
                  >
                    <Stack spacing="4" shouldWrapChildren>
                      <Stack spacing="2">
                        <NavLink
                          label={org?.name ?? 'Library'}
                          icon="building"
                          href={$state.href('dashboard.type', {
                            type: 'folder',
                            path: foldersSlugQuery.data?.librarySlug,
                          })}
                          isSelected={view === 'folder' && !path.startsWith('private')}
                        />

                        <NavLink
                          label="Private Library"
                          icon="user-lock"
                          href={$state.href('dashboard.type', {
                            type: 'folder',
                            path: foldersSlugQuery.data?.privateLibrarySlug,
                          })}
                          isSelected={view === 'folder' && path.startsWith('private')}
                        />

                        <NavLink
                          label="Scheduled"
                          icon="clock"
                          href={$state.href('dashboard.type', { type: 'scheduled' }, { inherit: false })}
                          isSelected={view === 'scheduled'}
                        />
                      </Stack>

                      <Divider />

                      <Tags />
                    </Stack>
                  </Stack>
                )}
              </Stack>
              <>
                <Divider w="full" />
                <Box pt="3">
                  <CreateActions source={CreateTemplateSource.LIBRARY_SIDEBAR} />
                </Box>
              </>
            </Flex>
          </MotionWrapper>
        ) : null}
      </AnimatePresence>
    </Flex>
  );
};

interface NavLinkProps extends LinkProps {
  icon: IconProps['icon'];
  label: string;
  isSelected?: boolean;
}

export const NavLink = (props: NavLinkProps) => {
  const { icon, label, children, isSelected, ...linkProps } = props;
  return (
    <Link justifyContent="start" {...linkProps} _hover={{}} aria-current={isSelected ? 'page' : undefined} w="full">
      <HStack
        boxSizing="border-box"
        spacing="3"
        role="group"
        _hover={{ color: 'brand.500' }}
        borderRadius="3xl"
        py="2"
        px="4"
        {...(isSelected ? { bgColor: 'brand.100', color: 'brand.500' } : { color: 'gray.600' })}
      >
        <Icon icon={icon} variant="far" size="4" />
        <Text fontWeight={isSelected ? 'medium' : 'normal'}>{label}</Text>
        {children}
      </HStack>
    </Link>
  );
};

const Tags: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { view, path } = useLibraryContext();
  const { $state } = useInjector('$state');

  const orgId = useSelector(SessionSelector.getSelectedOrganizationId);

  const tagsQuery = useGetOrganizationTagsQuery(orgId ?? '', {
    select: tags => {
      return [...tags].sort(({ name: a }, { name: b }) => a.localeCompare(b));
    },
  });
  const tagMembershipsCountsQuery = useGetTagMembershipsCountsQuery({
    organizationId: orgId ?? '',
    templateStatus: TemplateStatus.Active,
  });
  return (
    <Stack>
      <Stack aria-label="Tags" spacing="1">
        {match(tagsQuery)
          .with({ status: 'loading' }, () => Array.from({ length: 4 }, (_, i) => <SkeletonTag key={i} />))
          .with({ status: 'success' }, ({ data: tags }) =>
            tags.map(tag => (
              <NavLink
                key={tag.id}
                label={tag.name}
                icon="tag"
                href={$state.href('dashboard.type', { type: 'tag', path: tag.name })}
                isSelected={view === 'tag' && path === tag.name}
              >
                <Flex flex="1" justifyContent="flex-end">
                  <Skeleton isLoaded={!tagMembershipsCountsQuery.isLoading}>
                    <Badge variant="subtle" ml="auto" _groupHover={{ bgColor: 'gray.200' }}>
                      {tagMembershipsCountsQuery.data?.[tag.id]}
                    </Badge>
                  </Skeleton>
                </Flex>
              </NavLink>
            )),
          )
          .otherwise(() => null)}
      </Stack>
    </Stack>
  );
};

const SkeletonTag: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <HStack py="2" px="4" spacing="3">
      <Skeleton>
        <Icon icon="tag" variant="far" size="4" />
      </Skeleton>

      <Skeleton>Tag name</Skeleton>
      <Spacer />
      <Skeleton>
        <Badge variant="subtle" ml="auto" _groupHover={{ bgColor: 'gray.200' }}>
          2
        </Badge>
      </Skeleton>
    </HStack>
  );
};
