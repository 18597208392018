import {
  DataSet,
  DataSetColumnDef,
  DataSetColumnStateModel,
  getAllDataSavedView,
} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { QueryClient, QueryKey, useQuery as useRQQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { Muid } from '@process-street/subgrade/core';
import produce from 'immer';

export namespace GetAllDataSetsQuery {
  export type Response = DataSet[];

  export const key = ['data-sets'];

  export const queryFn = () =>
    axiosService
      .getAxios()
      .get<Response>(`/1/data-sets`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(options: UseQueryOptions<Response, AxiosError, Select> = {}) => {
    return useRQQuery(key as QueryKey, () => GetAllDataSetsQuery.queryFn(), options);
  };

  export function removeDataSetColumnFromCache({
    dataSetId,
    columnId,
    queryClient,
  }: {
    dataSetId: Muid;
    columnId: Muid;
    queryClient: QueryClient;
  }) {
    queryClient.setQueryData<GetAllDataSetsQuery.Response | undefined>(GetAllDataSetsQuery.key, current => {
      if (!current) return current;

      return produce(current, draft => {
        const dataSet = draft.find(d => d.id === dataSetId);
        if (!dataSet?.columnDefs) return;
        dataSet.columnDefs = dataSet.columnDefs.filter(c => c.id !== columnId);
        const allDataView = getAllDataSavedView(dataSet);
        if (!allDataView?.columns) return;
        allDataView.columns = allDataView.columns.filter(column => column.id !== columnId);
      });
    });
  }

  export function addDataSetColumnToCache({
    dataSetId,
    columnDef,
    index,
    queryClient,
  }: {
    dataSetId: Muid;
    columnDef: DataSetColumnDef;
    index: number;
    queryClient: QueryClient;
  }) {
    queryClient.setQueryData<GetAllDataSetsQuery.Response | undefined>(GetAllDataSetsQuery.key, current => {
      if (!current) return current;
      return produce(current, draft => {
        const dataSet = draft.find(d => d.id === dataSetId);
        if (!dataSet) return;
        dataSet.columnDefs.splice(index, 0, columnDef);
        const allDataView = getAllDataSavedView(dataSet);
        if (!allDataView?.columns) return;
        allDataView.columns.splice(index, 0, DataSetColumnStateModel.fromColumnDef(columnDef));
      });
    });
  }

  export function modifyDataSetColumnInCache({
    dataSetId,
    columnId,
    updates,
    queryClient,
  }: {
    dataSetId: Muid;
    columnId: Muid;
    updates: Partial<DataSetColumnDef>;
    queryClient: QueryClient;
  }) {
    queryClient.setQueryData<GetAllDataSetsQuery.Response | undefined>(GetAllDataSetsQuery.key, current => {
      if (!current) return current;
      return produce(current, draft => {
        const dataSet = draft.find(ds => ds.id === dataSetId);
        if (!dataSet) return;
        const column = dataSet.columnDefs.find(c => c.id === columnId);
        if (column) Object.assign(column, updates);
      });
    });
  }
}
