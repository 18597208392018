import angular from 'angular';
import { canAccess, Feature } from 'services/features/features';
import { OrganizationMembershipRole, UserType } from '@process-street/subgrade/core';
import { ArrayService } from 'services/array-service';
import { EmailService } from 'services/email-service';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'InviteMembersCtrl',
    function (
      $q,
      $rootScope,
      $scope,
      $state,
      $timeout,
      focusById,
      InvitationService,
      OrganizationMembershipService,
      OrganizationService,
      SecurityService,
      SessionService,
      UserService,
      UserSuggester,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'InviteMembersCtrl' });
      logger.info('loading ctrl');

      $scope.initialized = false;

      const userSuggester = new UserSuggester([], membership => membership.user.userType === UserType.Standard);

      ctrl.$onInit = () => {
        $scope.user = UserService.getCurrentUser();
        $scope.selectedOrganizationId = SecurityService.getSelectedOrganizationIdByUser($scope.user);

        $scope.exampleEmails = [
          'tony@stark.com',
          'don@sterling-cooper.com',
          'korra@avatar.com',
          'farnsworth@p-express.com',
          'gob@bluth.com',
        ];

        $scope.info = {
          emails: [],
        };

        const permissionsRequest = initializePermissions();
        const featuresRequest = initializeFeatures();
        return $q
          .all({
            permissionMap: permissionsRequest,
            features: featuresRequest,
          })
          .then(() => {
            initializeSuggestions();
            $scope.initialized = true;
          });
      };

      $scope.permissionMap = {};

      function initializePermissions() {
        return $q
          .all({
            membersInvite: SecurityService.canInviteMembersByOrganizationId($scope.selectedOrganizationId),
          })
          .then(permissionMap => {
            $scope.permissionMap = permissionMap;
          });
      }

      function initializeFeatures() {
        return OrganizationService.getById($scope.selectedOrganizationId).then(organization => {
          const currentPlanId = organization && organization.subscription.plan.id;

          $scope.planCanAccessFullPermissions = canAccess(Feature.FULL_PERMISSIONS, currentPlanId);
        });
      }

      function initializeSuggestions() {
        if ($scope.permissionMap.membersInvite) {
          focusById('email0');

          userSuggester.suggest('').then(users => {
            // Put contacts with non-Gravatar photos at the front
            const partitions = ArrayService.partition(
              users,
              user => !user.avatarUrl.startsWith('https://www.gravatar.com/avatar'),
            );

            const reorderedUsers = partitions[0].concat(partitions[1]);

            $scope.suggestedUserGroups = [reorderedUsers.slice(0, 3), reorderedUsers.slice(3, 6)];
          });
        } else {
          $timeout(() => {
            logger.info('no permission to invite members, redirecting');
            $scope._done();
          });
        }
      }

      const MAX_SUGGESTIONS = 5;

      $scope.getSuggestions = function (partial) {
        return OrganizationMembershipService.getAllByOrganizationId($scope.selectedOrganizationId).then(memberships => {
          const excludeMembers = memberships.map(membership => membership.user);

          return userSuggester
            .suggest(partial.toLowerCase(), excludeMembers)
            .then(users => users.slice(0, MAX_SUGGESTIONS));
        });
      };

      $scope.formatSuggestion = function (suggestion) {
        return suggestion && (suggestion.email || suggestion);
      };

      $scope.selectSuggestion = function (suggestion) {
        $timeout(() => {
          focusNext($scope.info.emails);
        });
        return suggestion.email;
      };

      $scope.getSuggestionLabel = UserSuggester.getLabel;

      $scope.pushUser = function (emails, user) {
        for (let i = 0; i < 5; i += 1) {
          if (!emails[i] || emails[i] === '') {
            emails[i] = user.email;
            break;
          }
        }

        focusNext(emails);
      };

      function focusNext(emails) {
        for (let i = 0; i < 5; i += 1) {
          if (!emails[i]) {
            focusById(`email${i}`);
            break;
          }
        }
      }

      $scope.invite = function (info) {
        $scope.submitting = true;

        AnalyticsService.trackEvent('user invited sign-up members', {
          'number of invitees': info.emails.length,
        });

        const resource = {
          type: 'Organization',
          id: $scope.selectedOrganizationId,
        };

        const requests = info.emails
          .filter(email => email.trim().length >= 3) // 3 is the minimum length of an email address
          .map(email => {
            const user = userSuggester.cacheGet(email) || {};
            const options = {
              username: user.username,
              avatarUrl: user.avatarUrl,
              provider: user.provider,
              role: OrganizationMembershipRole.FullMember,
            };

            return InvitationService.invite(email, resource, options);
          });

        const restricted = !$scope.planCanAccessFullPermissions || SessionService.isCanceled();

        $q.all(requests)
          .then(responses => {
            const allMemberships = responses.map(response => response.organizationMembership);

            if (restricted) {
              return $q.all(
                allMemberships.map(membership => OrganizationMembershipService.setLevel(membership.id, 'Admin')),
              );
            } else {
              return allMemberships;
            }
          })
          .then(memberships =>
            memberships.map(updatedMembership => {
              $rootScope.$broadcast(EventName.ORGANIZATION_MEMBERSHIP_UPDATED, updatedMembership);
              return updatedMembership;
            }),
          )
          .finally(() => {
            $scope._done();
          });
      };

      $scope.skip = function () {
        AnalyticsService.trackEvent('user skipped inviting sign-up members');
        $scope._done();
      };

      $scope._done = () => {
        $state.go('dashboard');
      };

      $scope.expandEmailAddress = EmailService.expandEmailAddress;
    },
  );
