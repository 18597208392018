import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';

import { TemplateRevision } from '@process-street/subgrade/process';
import { SolutionTypeTag, AutomationAppName, AutomationAppType } from '@process-street/subgrade/automation';
import { useInjector } from 'components/injection-provider';
import { AutomationsModalEvent } from 'pages/templates/_id/automation/automations-modal-event';
import { Muid } from '@process-street/subgrade/core';

export type ProviderProps = {
  editable: boolean;
  templateRevision: TemplateRevision;
  solutionTypeTag: SolutionTypeTag;
};

type WorkflowAutomationsEffects = {
  openAutomationInstance: (opts: {
    id: Muid;
    automationType: AutomationAppType;
    modalView?: 'task' | 'all';
    solutionTypeTag?: SolutionTypeTag;
  }) => void;
  openSolutionTypeTag: (solutionTypeTag: SolutionTypeTag, automationAppName?: AutomationAppName) => void;
  openTaskAutomations: () => void;
};

export type ContextProps = ProviderProps & WorkflowAutomationsEffects;

export const [useWorkflowAutomationsContext, WorkflowAutomationsContext] = createUsableContext<ContextProps>({
  hookName: 'useWorkflowAutomationsContext',
  providerName: 'WorkflowAutomationsProvider',
});

export const useAutomationsModalEvents = (): WorkflowAutomationsEffects => {
  const { $rootScope } = useInjector('$rootScope');

  return React.useMemo(
    () => ({
      openSolutionTypeTag: (solutionTypeTag, automationAppName) => {
        $rootScope.$broadcast(AutomationsModalEvent.OPEN_SOLUTION_TYPE_TAG, solutionTypeTag, automationAppName);
      },
      openAutomationInstance: ({ id, automationType, modalView, solutionTypeTag }) => {
        $rootScope.$broadcast(AutomationsModalEvent.OPEN_AUTOMATION_INSTANCE, {
          id,
          automationType,
          modalView,
          solutionTypeTag,
        });
      },
      openTaskAutomations: () => {
        $rootScope.$broadcast(AutomationsModalEvent.OPEN_TASK_AUTOMATIONS);
      },
    }),
    [$rootScope],
  );
};

export const WorkflowAutomationsProvider: React.FC<React.PropsWithChildren<ProviderProps>> = ({
  children,
  ...rest
}) => {
  const events = useAutomationsModalEvents();
  const value: ContextProps = React.useMemo<ContextProps>(() => ({ ...events, ...rest }), [events, rest]);
  return <WorkflowAutomationsContext.Provider value={value}>{children}</WorkflowAutomationsContext.Provider>;
};
