import Papa from 'papaparse';
import aiGeneratorSelectorOptionsCsv from './ai-generator-selector-options.csv';

export type WorkflowOption = {
  name: string;
  description: string;
};

export const workflowOptions: WorkflowOption[] = Papa.parse<{ 'workflow name': string; 'description': string }>(
  aiGeneratorSelectorOptionsCsv.trim(),
  {
    header: true,
  },
).data.map(row => ({
  name: row['workflow name'],
  description: row['description'],
}));
