import angular from 'angular';
import { HttpStatus } from '@process-street/subgrade/util';
import { trace } from 'components/trace';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { DefaultErrorMessages } from 'components/utils/error-messages';

angular
  .module('frontStreetApp.controllers')
  .controller('RequestPasswordResetCtrl', function ($scope, $state, auth, focusById, SessionService, UserService) {
    const ctrl = this;
    const logger = trace({ name: 'RequestPasswordResetCtrl' });
    logger.info('loading ctrl');

    ctrl.$onInit = () => {
      $scope.info = {
        email: $state.params.email,
      };

      focusById('email');
    };

    $scope.requestPasswordReset = function (info) {
      if (!$scope.form.$invalid) {
        requestPasswordReset(info);
      } else {
        const messages = [];

        if ($scope.form.email.$error.required) {
          messages.push('You must enter an email address.');
        }
        if ($scope.form.email.$error.email) {
          messages.push('You must enter a valid email address.');
        }

        const message = messages.join('<br>');
        ToastServiceImpl.openToast({
          status: 'error',
          title: message,
        });
      }
    };

    function requestPasswordReset(info) {
      $scope.submitting = true;

      UserService.requestPasswordReset(info.email).then(
        () => {
          logger.info('succeeded to request password reset');

          ToastServiceImpl.openToast({
            status: 'success',
            title: 'If there is an account associated with that email address you will receive a password reset email.',
          });

          if (auth.isLoggedIn()) {
            $state.go('reports');
          } else {
            $state.go('login', { email: info.email });
          }
        },
        response => {
          logger.error('failed to request password reset. Reason: %s', JSON.stringify(response));

          $scope.submitting = false;

          switch (response.status) {
            case HttpStatus.CONFLICT:
              ToastServiceImpl.openToast({
                status: 'warning',
                title: 'Please finish creating your account to continue.',
              });
              $state.go('completeSignUp.user', {
                email: info.email,
              });
              break;
            case HttpStatus.FORBIDDEN:
              ToastServiceImpl.openToast({
                status: 'error',
                title: 'Please contact your administrator to reset your password.',
              });
              break;
            case HttpStatus.TOO_MANY_REQUESTS:
              ToastServiceImpl.openToast({
                status: 'error',
                title: 'Too many password reset attempts',
                description:
                  'You have attempted to reset your password too many times recently. Please wait 5 minutes and try again.',
              });
              break;
            default:
              ToastServiceImpl.openToast({
                status: 'error',
                title: "We couldn't reset your password",
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
              break;
          }
        },
      );
    }
  });
