import { Period } from '@process-street/subgrade/core';
import { dayjs as moment } from '@process-street/subgrade/util';

function calculateDueDate({ startDate, duePeriod }: { startDate?: number; duePeriod?: Period }): number | undefined {
  if (!startDate || !duePeriod) return undefined;

  return moment(startDate)
    .add(duePeriod.years, 'years')
    .add(duePeriod.months, 'months')
    .add(duePeriod.days, 'days')
    .add(duePeriod.hours, 'hours')
    .add(duePeriod.minutes, 'minutes')
    .add(duePeriod.seconds, 'seconds')
    .valueOf();
}

export const FutureChecklistUtils = {
  calculateDueDate,
};
