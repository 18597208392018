import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { useSelectedOrganization } from 'hooks/use-selected-organization';

const transformOmsToUserNames = (oms: OrganizationMembershipWithUser[]) => oms.map(om => om.user.username);

export const useGetAllUsernames = (): { loading: boolean; usernames: string[] } => {
  const selectedOrganizationId = useSelectedOrganization()?.id;

  const { data: oms, isLoading } = useGetAllOrganizationMembershipsQuery(
    {
      organizationId: selectedOrganizationId!,
    },
    {
      enabled: Boolean(selectedOrganizationId),
      select: transformOmsToUserNames,
    },
  );

  const usernames = oms ? oms : [];

  return { loading: isLoading, usernames };
};
