import {} from '@process-street/subgrade/core';
import { TaskPermissionRule } from '@process-street/subgrade/permission';
import { TemplateRevision } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateAllTaskTemplatesRulesMutation {
  export type Params = {
    templateRevisionId: TemplateRevision['id'];
    rules: Array<TaskPermissionRule>;
  };

  export type Response = Array<TaskPermissionRule>;

  export const key = ['task-template', 'rules', 'all'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/template-revisions/${params.templateRevisionId}/task-permission-rules/all`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
