import { FormFieldWidget } from '@process-street/subgrade/process';
import {
  StdMergeTagKey,
  StdMergeTagKeyLegacy,
  StdMergeTagLabel,
  StdMergeTagLabelLegacy,
  User,
} from '@process-street/subgrade/core';

/**
 * There are 2 modes in which merge tags returns result
 * - Plaintext - used for email mailto link generation
 * - Html - everywhere else
 */
export { MergeTagMode } from '../form';

export enum ResolvedTagFormat {
  /** This will go into the form field unescaped - make sure to escape user contents. */
  Html = 'Html',
  Markdown = 'Markdown',
}

export interface ResolvedTagMeta {
  format?: ResolvedTagFormat;
}

/**
 * When value is present we generate ResolvedTag, which had
 * - key - which is formFieldWidget key
 * - replacement - optional string value for the kye
 */
export interface ResolvedTag {
  key: string;
  replacement?: string;
  default: string;
  meta?: ResolvedTagMeta;
}

const asResolvedTag = ({
  key,
  replacement,
  label,
  meta,
}: {
  key: string;
  replacement?: string;
  label?: string;
  meta?: ResolvedTagMeta;
}): ResolvedTag => ({
  key,
  replacement,
  default: label || key,
  meta,
});

const resolvedTag = (formFieldWidget: FormFieldWidget, replacement?: string): ResolvedTag =>
  asResolvedTag({ key: `form.${formFieldWidget.key}`, replacement, label: formFieldWidget.label });

const resolveUserEmails = (formFieldWidget: FormFieldWidget, users: User[]): ResolvedTag => {
  const key = `form.${formFieldWidget.key}.email`;
  return {
    key,
    replacement: users.length > 0 ? users.map(user => user.email).join(', ') : undefined,
    default: formFieldWidget.label ? `${formFieldWidget.label} email` : key,
  };
};

const resolveUserNames = (formFieldWidget: FormFieldWidget, users: User[]): ResolvedTag => {
  const key = `form.${formFieldWidget.key}.name`;
  return {
    key,
    replacement: users.length > 0 ? users.map(user => user.username).join(', ') : undefined,
    default: formFieldWidget.label ? `${formFieldWidget.label} name` : key,
  };
};

const generalTag = (key: StdMergeTagKey | StdMergeTagKeyLegacy, replacement?: string): ResolvedTag => ({
  key,
  replacement,
  default: StdMergeTagLabel[key as StdMergeTagKey] ?? StdMergeTagLabelLegacy[key as StdMergeTagKeyLegacy],
});

export const ResolveTagFactory = {
  asResolvedTag,
  generalTag,
  resolvedTag,
  resolveUserEmails,
  resolveUserNames,
};

/**
 * Subset of filters supported by text editor.
 */
export enum MergeTagFilter {
  FallbackLegacy = 'default',
  Fallback = 'fallback',
  Downcase = 'downcase',
  Upcase = 'upcase',
  UrlEncode = 'url_encode',
}
