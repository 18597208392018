import { Template } from '@process-street/subgrade/process';
import { UseMutationResult } from 'react-query';
import { UpdateTemplateForm } from '../api';

export type TemplateState = { template?: Template; status: 'stale' | 'fresh' };
export type TemplateAction =
  | { type: 'UPDATE'; form: UpdateTemplateForm }
  | { type: 'REFRESH'; template: Template; mutationStatus: UseMutationResult['status'] }
  | { type: 'SET_STATUS'; status: TemplateState['status'] };

export const templateReducer = (state: TemplateState, action: TemplateAction): TemplateState => {
  switch (action.type) {
    case 'REFRESH': {
      const { template, status } = state;
      const { template: freshTemplate, mutationStatus } = action;
      if (!template) return { template: freshTemplate, status: 'fresh' };

      // don't replace the state with stale server state while the user is editing
      const keepClientState = status === 'stale' || mutationStatus === 'loading';
      return {
        ...state,
        template: {
          ...freshTemplate,
          name: keepClientState ? template.name : freshTemplate.name,
        },
      };
    }

    case 'UPDATE': {
      const { template } = state;
      if (!template) return state;
      return {
        template: { ...template, ...action.form },
        status: 'stale',
      };
    }

    case 'SET_STATUS': {
      return { ...state, status: action.status };
    }
  }
};
