import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { Template } from '@process-street/subgrade/process';

export namespace GetTemplateOverviewQuery {
  export type Params = { templateId?: Template['id'] };

  export type Response = {
    id: Template['id'];
    name: string;
    description?: string;
  };

  export const key = ['template', 'overview'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/templates/${params.templateId}/overview`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.templateId) && options.enabled !== false,
    });
  };
}
