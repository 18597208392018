import { Organization } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UpdateOrganizationParams = {
  id: Organization['id'];
  name: string;
  growSumoPartnerKey?: string;
  industry?: string;
  timeZone?: string;
  dateFormat?: string;
};

export type UpdateOrganizationResult = Organization;

export const UpdateOrganizationMutation = {
  key: ['organization', 'update'],
  mutationFn: (params: UpdateOrganizationParams) =>
    axiosService
      .getAxios()
      .patch<UpdateOrganizationResult>(`/1/organizations/${params.id}`, params)
      .then(r => r.data),
};

export const useUpdateOrganizationMutation = (
  options: UseMutationOptions<UpdateOrganizationResult, AxiosError, UpdateOrganizationParams> = {},
) => {
  return useMutation<UpdateOrganizationResult, AxiosError, UpdateOrganizationParams>(
    UpdateOrganizationMutation.key,
    UpdateOrganizationMutation.mutationFn,
    options,
  );
};
