import axios, { AxiosResponse } from 'axios';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

export type UploadToS3MutationParams = {
  file: File;
  url: string;
  data: string | ArrayBuffer;
  onProgress?: (value: number) => void;
};

export type UploadToS3MutationResponse = { url: string; key: string };

export type ProgressEvent = {
  lengthComputable: boolean;
  loaded: number;
  total: number;
};
export const UploadToS3Mutation = {
  key: ['upload-to-s3'],
  mutationFn: ({ onProgress = () => {}, ...params }: UploadToS3MutationParams) =>
    axios.put(params.url, params.data, {
      headers: { 'Content-Type': params.file.type || 'application/octet-stream' },
      onUploadProgress: (event: ProgressEvent) => {
        onProgress((100 * event.loaded) / event.total);
      },
    }),
};

export const useUploadToS3Mutation = (
  options: UseMutationOptions<AxiosResponse<UploadToS3MutationResponse>, AxiosError, UploadToS3MutationParams> = {},
) => {
  return useMutation(UploadToS3Mutation.mutationFn, options);
};
