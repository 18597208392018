import { axiosService } from 'services/axios-service';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export type DeleteTemplateEmailAttachmentMutationParams = {
  attachmentId: string;
};

export type DeleteTemplateEmailAttachmentMutationResponse = {};

export const DeleteTemplateEmailAttachmentMutation = {
  key: ['email-attachment'],
  mutationFn: (params: DeleteTemplateEmailAttachmentMutationParams) =>
    axiosService
      .getAxios()
      .delete<DeleteTemplateEmailAttachmentMutationResponse>(`/1/email-attachments/${params.attachmentId}`)
      .then(res => res.data),
};

export const useDeleteTemplateEmailAttachmentMutation = (
  options: UseMutationOptions<
    DeleteTemplateEmailAttachmentMutationResponse,
    AxiosError,
    DeleteTemplateEmailAttachmentMutationParams
  > = {},
) => {
  return useMutation(DeleteTemplateEmailAttachmentMutation.mutationFn, options);
};
