import angular from 'angular';
import { htmlEscaped } from '@process-street/subgrade/util';
import { IdentityProvider } from '@process-street/subgrade/core';
import { ArrayService } from 'services/array-service';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';
import { isStandardUserOrStandardGroupOm } from '@process-street/subgrade/util/membership-utils';
import { EquatableSet } from 'services/equatable-set.pure';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.services')
  .factory('UserSuggester', ($q, OrganizationMembershipService, OrganizationService, SecurityService, UserService) => {
    const logger = trace({ name: 'UserSuggester' });

    function UserSuggester(providers, filter) {
      this.providers = providers || [];
      this.filter =
        filter ||
        function () {
          return true;
        };
      this.cache = {};
    }

    UserSuggester.prototype.cacheSet = function (email, user) {
      this.cache[email] = user;
    };

    UserSuggester.prototype.cacheGet = function (email) {
      return this.cache[email];
    };

    function membershipIncludes(membership, partial) {
      return (
        membership.user.email.toLowerCase().includes(partial) ||
        membership.user.username.toLowerCase().includes(partial)
      );
    }

    UserSuggester.prototype.getProcessStreetSuggestions = function (useProcessStreet, trimmedPartial, organizationId) {
      if (useProcessStreet) {
        const self = this;
        return OrganizationMembershipService.getAllByOrganizationId(organizationId)
          .then(memberships =>
            memberships
              .filter(
                membership =>
                  self.filter(membership) &&
                  isStandardUserOrStandardGroupOm(membership) &&
                  membershipIncludes(membership, trimmedPartial),
              )
              .map(membership => membership.user),
          )
          .catch(reason => {
            logger.error('getProcessStreetSuggestions. Reason: %s', JSON.stringify(reason));
            return [];
          });
      } else {
        return $q.resolve([]);
      }
    };

    UserSuggester.prototype.suggest = function (partial, excludeUsers) {
      const self = this;

      const trimmedPartial = (partial || '').trim();

      const sessionUser = UserService.getCurrentUser();

      const promise = OrganizationService.getById(SecurityService.getSelectedOrganizationIdByUser(sessionUser)).then(
        selectedOrganization => {
          const shouldUseProcessStreet = ArrayService.includes(self.providers, IdentityProvider.ProcessStreet);

          const suggestionPromises = {
            processStreet: self.getProcessStreetSuggestions(
              shouldUseProcessStreet,
              trimmedPartial,
              selectedOrganization.id,
            ),
          };

          return $q
            .all(suggestionPromises)
            .then(suggestions => suggestions.processStreet)
            .then(users => {
              users.forEach(user => {
                self.cacheSet(user.email, user);
              });

              return new EquatableSet('email')
                .addAll(users)
                .removeAll(excludeUsers || [])
                .toArray()
                .filter(user => user.email);
            });
        },
      );

      return $q((resolve, reject) => promise.then(resolve).catch(reject));
    };

    UserSuggester.getLabel = function (value) {
      let label = '???';
      if (angular.isString(value)) {
        // It's an email address
        label = value;
      } else if (angular.isObject(value)) {
        const user = value;

        if (user.username) {
          label = isGroupUser(user) ? user.username : `${user.username} (${user.email})`;
        } else if (user.email) {
          label = user.email;
        } else {
          label = '???';
        }
      }

      return htmlEscaped`${label}`;
    };

    return UserSuggester;
  });
