import React from 'react';
import { components, GroupBase, MultiValueGenericProps } from 'react-select';
import { HStack } from '@chakra-ui/react';

export const MultiValueLabel = <
  Option,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MultiValueGenericProps<Option, IsMulti, Group>,
) => {
  return (
    <components.MultiValueLabel {...props}>
      <HStack className={'blvd-select__multi-value__label'}>
        <span className={'blvd-select__ellipsis'}>{props.children}</span>
      </HStack>
    </components.MultiValueLabel>
  );
};
