import { IconName } from '@fortawesome/fontawesome-common-types';
import { Muid, Option, Organization } from '@process-street/subgrade/core';
import { Folder } from '@process-street/subgrade/process';
import { createUsableContext } from '@process-street/subgrade/util';
import { AxiosError } from 'axios';
import { Box, Button, Center, Spinner, Text } from 'components/design/next';
import { isPrivateFolder } from 'features/folder/lib';
import { useReadableFoldersQuery, useWriteableFoldersQuery } from 'features/folder/query-builder';
import { useGetOrganizationQuery } from 'features/organization/query-builder';
import React, { useEffect, useMemo } from 'react';
import { QueryKey, UseQueryOptions } from 'react-query';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { FolderPickerItem } from './item';
import { useFolderPickerModalContext } from './modal';
import { FolderPickerTree } from './tree';

export type Context = {
  folders: Folder[];
  writeableFolderIdSet: Set<Muid>;
  selectedId?: Muid;
  onSelect?: (folder: Folder, writeable: boolean) => void;
};
export const [useFolderPickerRootContext, FolderPickerRootContext] = createUsableContext<Context>({
  hookName: 'useFolderPickerRootContext',
  providerName: 'FolderPickerRootContext',
});

export interface FolderPickerRootProps {
  organizationId: Option<Muid>;
  libraryIsPrivate: boolean;
  onSelect?: (folder: Folder, writeable: boolean) => void;
}
export const FolderPickerRoot: React.FC<React.PropsWithChildren<FolderPickerRootProps>> = ({
  libraryIsPrivate,
  onSelect,
  organizationId: organizationIdOverride,
  children,
}) => {
  const { initialFolderId, selectedFolder } = useFolderPickerModalContext();
  const selectedId = selectedFolder?.id;
  const sessionOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const organizationId = (organizationIdOverride ?? sessionOrganizationId) as Organization['id'];

  const privateSelector = (folders: Folder[]) =>
    folders.filter(folder => isPrivateFolder(folder, folders) === libraryIsPrivate);
  const queryOptions: UseQueryOptions<Folder[], AxiosError, Folder[], QueryKey> = {
    enabled: !!organizationId,
    staleTime: Infinity,
    refetchOnMount: 'always',
    select: privateSelector,
  };
  const readableQuery = useReadableFoldersQuery(organizationId!, queryOptions);
  const writeableQuery = useWriteableFoldersQuery(organizationId!, queryOptions);
  const writeableFolderIdSet = useMemo(() => new Set(writeableQuery.data?.map(f => f.id)), [writeableQuery.data]);

  const { data: organization } = useGetOrganizationQuery({ organizationId });

  const libraryName = libraryIsPrivate ? 'Private Library' : organization?.name ?? 'Library';
  const icon: IconName = libraryIsPrivate ? 'user-lock' : 'building';
  const description = libraryIsPrivate ? 'Only you will be able to view this.' : undefined;

  const readableFolders = readableQuery.data;
  useEffect(() => {
    if (initialFolderId) {
      const f = readableFolders?.find(f => f.id === initialFolderId);
      if (f) {
        const w = writeableFolderIdSet.has(initialFolderId);
        onSelect?.(f, w);
      }
    }
  }, [initialFolderId, readableFolders, writeableFolderIdSet, onSelect]);

  const folderIdSet = new Set(readableFolders?.map(f => f.id));
  const orphanFolders = readableFolders?.filter(f => f.parentFolder && !folderIdSet.has(f.parentFolder.id));
  const rootFolder = readableFolders?.find(
    f => f.parentFolder === undefined && isPrivateFolder(f, readableFolders) === libraryIsPrivate,
  );
  const context = useMemo(() => {
    const handleSelect = (folder: Folder, writeable: boolean) => {
      onSelect?.(folder, writeable);
    };
    return {
      folders: readableFolders ?? [],
      writeableFolderIdSet,
      selectedId,
      onSelect: handleSelect,
    };
  }, [readableFolders, writeableFolderIdSet, selectedId, onSelect]);

  if (readableQuery.isLoading || writeableQuery.isLoading) {
    return (
      <Center p="4">
        <Spinner size="xl" />
      </Center>
    );
  }
  if (rootFolder) {
    return (
      <FolderPickerRootContext.Provider value={context}>
        {children}
        <Box role="tree">
          <Box borderColor="gray.200">
            <Button
              {...(rootFolder.id === selectedId
                ? { bgColor: 'brand.100', textColor: 'brand.500', fontWeight: 'bold' }
                : null)}
              variant="unstyled"
              flex="1"
              borderRadius="0"
              width="full"
              onClick={() => context.onSelect(rootFolder, writeableFolderIdSet.has(rootFolder.id))}
              role="treeitem"
            >
              <FolderPickerItem
                name={libraryName}
                writeable={writeableFolderIdSet.has(rootFolder.id)}
                icon={icon}
                description={description}
                px="6"
              />
            </Button>
          </Box>
          <Box pl={4}>
            <FolderPickerTree rootId={rootFolder.id} extraFolders={orphanFolders} />
          </Box>
        </Box>
      </FolderPickerRootContext.Provider>
    );
  }
  return (
    <Center p="4">
      <Text>There was a problem loading the folders.</Text>
    </Center>
  );
};
