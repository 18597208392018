import { IconDefinition } from '@fortawesome/fontawesome-common-types';

enum Type {
  FOLDER = 'folder',
  TEMPLATE = 'template',
  CHECKLIST = 'checklist',
}

export { Type as PermitType };

export const PermitTypeDescription = {
  [Type.FOLDER]: 'folder',
  [Type.TEMPLATE]: 'workflow',
  [Type.CHECKLIST]: 'workflow run',
};

export enum AccessLevel {
  FOLDER_EDIT = 'folder can edit and run',
  FOLDER_EDIT_VIEW_OWN = 'FolderEditViewOwn',
  FOLDER_VIEW_ALL = 'folder can view and run',
  FOLDER_RUN = 'folder can view own and run',
  FOLDER_VIEW = 'folder can view own',
  FOLDER_EDIT_FREE_MEMBER = 'folder edit free member',
  FOLDER_VIEW_FREE_MEMBER = 'folder view free member',
  FOLDER_EDIT_WITH_RUN_FREE_MEMBER = 'folder edit with run free member',
  FOLDER_VIEW_WITH_RUN_FREE_MEMBER = 'folder view with run free member',
  WORKFLOW_EDIT = 'template can edit and run',
  WORKFLOW_EDIT_VIEW_OWN = 'WorkflowEditViewOwn',
  WORKFLOW_VIEW_ALL = 'template can view and run',
  WORKFLOW_RUN = 'template can view own and run',
  WORKFLOW_VIEW = 'template can view own',
  WORKFLOW_RUN_RUN = 'checklist can edit',
  PAGE_EDIT = 'page edit',
  PAGE_VIEW = 'page view',
  FORM_EDIT = 'form edit',
  FORM_EDIT_VIEW_OWN = 'form edit view own',
  FORM_VIEW_ALL = 'form view all',
  FORM_VIEW = 'form view',
  FORM_RESPOND = 'form respond',
}

// See
// https://miro.com/app/board/o9J_lKWwK0U=/?moveToWidget=3074457357652371478&cot=14

// A1
const FolderFullMemberAccessLevels = [
  AccessLevel.FOLDER_EDIT,
  AccessLevel.FOLDER_VIEW_ALL,
  AccessLevel.FOLDER_RUN,
  AccessLevel.FOLDER_VIEW,
];

// with Edit View Own
const FolderFullMemberAccessLevelsV2 = [
  AccessLevel.FOLDER_EDIT,
  AccessLevel.FOLDER_EDIT_VIEW_OWN,
  AccessLevel.FOLDER_VIEW_ALL,
  AccessLevel.FOLDER_RUN,
  AccessLevel.FOLDER_VIEW,
];

// B1
const FolderFreeMemberAccessLevels = [AccessLevel.FOLDER_EDIT_FREE_MEMBER, AccessLevel.FOLDER_VIEW_FREE_MEMBER];
const FolderFreeMemberWithRunAccessLevels = [
  AccessLevel.FOLDER_EDIT_WITH_RUN_FREE_MEMBER,
  AccessLevel.FOLDER_VIEW_WITH_RUN_FREE_MEMBER,
  AccessLevel.FOLDER_VIEW_FREE_MEMBER,
];

// C1
const FolderGuestAccessLevels = [AccessLevel.FOLDER_VIEW];
const FolderLegacyGuestAccessLevels = [AccessLevel.FOLDER_RUN, AccessLevel.FOLDER_VIEW];

// A2
const WorkflowFullMemberAccessLevels = [
  AccessLevel.WORKFLOW_EDIT,
  AccessLevel.WORKFLOW_VIEW_ALL,
  AccessLevel.WORKFLOW_RUN,
  AccessLevel.WORKFLOW_VIEW,
];

// with Edit View Own
const WorkflowFullMemberAccessLevelsV2 = [
  AccessLevel.WORKFLOW_EDIT,
  AccessLevel.WORKFLOW_EDIT_VIEW_OWN,
  AccessLevel.WORKFLOW_VIEW_ALL,
  AccessLevel.WORKFLOW_RUN,
  AccessLevel.WORKFLOW_VIEW,
];
// C2
const WorkflowFreeMemberAccessLevels = [AccessLevel.WORKFLOW_VIEW];
const WorkflowFreeMemberWithRunAccessLevels = [AccessLevel.WORKFLOW_VIEW, AccessLevel.WORKFLOW_RUN];
const WorkflowGuestAccessLevels = [AccessLevel.WORKFLOW_VIEW];
const WorkflowLegacyGuestAccessLevels = [AccessLevel.WORKFLOW_VIEW, AccessLevel.WORKFLOW_RUN];

// B2
const PageFullMemberAccessLevels = [AccessLevel.PAGE_EDIT, AccessLevel.PAGE_VIEW];
const PageFreeMemberAccessLevels = [AccessLevel.PAGE_EDIT, AccessLevel.PAGE_VIEW];
const PageGuestAccessLevels: AccessLevel[] = [];

// Forms
const FormFullMemberAccessLevels = [
  AccessLevel.FORM_EDIT,
  AccessLevel.FORM_EDIT_VIEW_OWN,
  AccessLevel.FORM_VIEW_ALL,
  AccessLevel.FORM_RESPOND,
  AccessLevel.FORM_VIEW,
];
const FormFreeMemberAccessLevels = [AccessLevel.FORM_RESPOND, AccessLevel.FORM_VIEW];
const FormGuestAccessLevels = [AccessLevel.FORM_VIEW];

interface AccessLevelData {
  title: string;
  description: string;
  icon: IconDefinition['iconName'];
}

const accessLevelCopyMap: Record<AccessLevel, AccessLevelData> = {
  [AccessLevel.WORKFLOW_EDIT]: {
    title: 'Edit & View All',
    description: 'Edit, run workflows, and view all workflow runs.',
    icon: 'edit',
  },
  [AccessLevel.WORKFLOW_EDIT_VIEW_OWN]: {
    title: 'Edit & View Own',
    description: 'Edit, run workflows, and view assigned workflow runs.',
    icon: 'user-pen',
  },
  [AccessLevel.WORKFLOW_VIEW_ALL]: {
    title: 'View All',
    description: 'Run workflows and view all workflow runs.',
    icon: 'th-list',
  },
  [AccessLevel.WORKFLOW_RUN]: {
    title: 'Run',
    description: 'Run workflows and view assigned workflow runs.',
    icon: 'play',
  },
  [AccessLevel.WORKFLOW_VIEW]: {
    title: 'View',
    description: 'View assigned workflows and workflow runs.',
    icon: 'eye',
  },
  [AccessLevel.FOLDER_EDIT]: {
    title: 'Edit & View All',
    description:
      'Create, edit and run workflows, and view all runs.\nCreate, edit and use forms, view all responses.\nCreate, edit and share pages.',
    icon: 'edit',
  },
  [AccessLevel.FOLDER_EDIT_VIEW_OWN]: {
    title: 'Edit & View Own',
    description:
      'Create, edit and run workflows, view assigned runs.\nCreate, edit and use forms, view own responses.\nCreate, edit and share pages.',
    icon: 'user-pen',
  },
  [AccessLevel.FOLDER_VIEW_ALL]: {
    title: 'View All',
    description: 'Run workflows and view all runs.\nUse forms and view all responses.\nView pages.',
    icon: 'th-list',
  },
  [AccessLevel.FOLDER_RUN]: {
    title: 'Run',
    description: 'Run workflows and view assigned runs.\nUse forms and view own responses.\nView pages.',
    icon: 'play',
  },
  [AccessLevel.FOLDER_VIEW]: {
    title: 'View',
    description: 'View assigned runs.\nView own responses.\nView pages.',
    icon: 'eye',
  },
  [AccessLevel.WORKFLOW_RUN_RUN]: {
    title: 'Run',
    description: 'Can only see workflow runs assigned to them.',
    icon: 'eye',
  },
  [AccessLevel.FOLDER_EDIT_FREE_MEMBER]: {
    title: 'Edit',
    description: 'View workflows.\nEdit & Share pages.',
    icon: 'edit',
  },
  [AccessLevel.FOLDER_VIEW_FREE_MEMBER]: {
    title: 'View',
    description: 'View workflows and pages only.',
    icon: 'eye',
  },
  [AccessLevel.FOLDER_EDIT_WITH_RUN_FREE_MEMBER]: {
    title: 'Edit',
    description: 'Run workflows.\nEdit & Share pages.',
    icon: 'edit',
  },
  [AccessLevel.FOLDER_VIEW_WITH_RUN_FREE_MEMBER]: {
    title: 'Run',
    description: 'View and run workflows.\nView and edit pages.',
    icon: 'play',
  },
  [AccessLevel.PAGE_EDIT]: {
    title: 'Edit',
    description: 'Edit & Share pages',
    icon: 'edit',
  },
  [AccessLevel.PAGE_VIEW]: {
    title: 'View',
    description: 'View pages.',
    icon: 'eye',
  },
  [AccessLevel.FORM_EDIT]: {
    title: 'Edit & View All',
    description: 'Edit and use the form.\nView all responses.',
    icon: 'edit',
  },
  [AccessLevel.FORM_EDIT_VIEW_OWN]: {
    title: 'Edit & View Own',
    description: 'Edit and use the form.\nView own responses.',
    icon: 'user-pen',
  },
  [AccessLevel.FORM_VIEW_ALL]: {
    title: 'View All',
    description: 'Use the form and view all responses.',
    icon: 'th-list',
  },
  [AccessLevel.FORM_VIEW]: {
    title: 'View',
    description: 'View own responses.',
    icon: 'eye',
  },
  [AccessLevel.FORM_RESPOND]: {
    title: 'Respond',
    description: 'Use the form and view own responses.',
    icon: 'play',
  },
};

export const PermitConstants = {
  AccessLevel,
  accessLevelCopyMap,
  FolderFreeMemberAccessLevels,
  FolderFreeMemberWithRunAccessLevels,
  FolderFullMemberAccessLevels,
  FolderFullMemberAccessLevelsV2,
  FolderGuestAccessLevels,
  FolderLegacyGuestAccessLevels,
  FormFullMemberAccessLevels,
  FormFreeMemberAccessLevels,
  FormGuestAccessLevels,
  PageFullMemberAccessLevels,
  PageFreeMemberAccessLevels,
  PageGuestAccessLevels,
  Type,
  WorkflowFullMemberAccessLevels,
  WorkflowFullMemberAccessLevelsV2,
  WorkflowFreeMemberAccessLevels,
  WorkflowFreeMemberWithRunAccessLevels,
  WorkflowGuestAccessLevels,
  WorkflowLegacyGuestAccessLevels,
};
