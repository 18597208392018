import { Organization } from '@process-street/subgrade/core';
import { TaskPermissionRule, TaskPermissionRuleSourceType } from '@process-street/subgrade/permission';
import {
  FieldType,
  FormFieldWidget,
  FormFieldWidgetLike,
  TaskTemplate,
  TemplateRevision,
  Widget,
} from '@process-street/subgrade/process';
import { Trace, trace } from 'components/trace';
import uniqWith from 'lodash/uniqWith';
import Muid from 'node-muid';
import { uuid } from 'services/uuid';
import { match, P } from 'ts-pattern';

export class TaskPermissionRuleService {
  private logger: Trace;

  constructor() {
    this.logger = trace({ name: 'TaskPermissionRuleService' });
  }

  resolveWidgetByGroupId = (groupId: string, widgets: FormFieldWidget[]) =>
    widgets.find(w => w.header.group.id === groupId);

  resolveWidgetLikeByGroupId = (groupId: string, widgets: FormFieldWidgetLike[]) =>
    widgets.find(w => w.header.group.id === groupId);

  getRuleDescription(rule: TaskPermissionRule, widgets: FormFieldWidget[], widgetsLoaded: FormFieldWidget[]) {
    if (rule.sourceType === TaskPermissionRuleSourceType.ChecklistInitiator) {
      return 'Workflow Run';
    } else if (
      rule.sourceType === TaskPermissionRuleSourceType.FormField &&
      rule.sourceFormFieldWidgetGroup &&
      rule.sourceFormFieldWidgetGroup.id
    ) {
      const widget = this.resolveWidgetByGroupId(rule.sourceFormFieldWidgetGroup.id, widgets);

      if (!widget) {
        if (widgetsLoaded) {
          this.logger.error('could not find widget by group id');
        }
        return null;
      }

      if (widget.fieldType === FieldType.Email) {
        return 'Email Field';
      } else if (widget.fieldType === FieldType.Members) {
        return 'Members Field';
      } else {
        this.logger.error('unsupported form field widget type');
      }
    } else {
      this.logger.error('could not resolve rule description');
    }
  }

  getRuleTitle(rule: TaskPermissionRule, widgets: FormFieldWidget[], widgetsLoaded: FormFieldWidget[]) {
    if (rule.sourceType === TaskPermissionRuleSourceType.ChecklistInitiator) {
      return 'Workflow Runner';
    } else if (
      rule.sourceType === TaskPermissionRuleSourceType.FormField &&
      rule.sourceFormFieldWidgetGroup &&
      rule.sourceFormFieldWidgetGroup.id
    ) {
      const widget = this.resolveWidgetByGroupId(rule.sourceFormFieldWidgetGroup.id, widgets);

      if (!widget) {
        if (widgetsLoaded) {
          this.logger.error('could not find widget by group id');
        }
        return null;
      }

      return widget.label || widget.key;
    } else {
      this.logger.error('could not resolve rule title');
    }
  }

  getRuleTitleLike(rule: TaskPermissionRule, widgets: FormFieldWidgetLike[]) {
    return match({ type: rule.sourceType, groupId: rule.sourceFormFieldWidgetGroup?.id })
      .with({ type: TaskPermissionRuleSourceType.ChecklistInitiator }, () => 'Workflow Runner')
      .with({ type: TaskPermissionRuleSourceType.FormField, groupId: P.not(P.nullish) }, data => {
        const widget = this.resolveWidgetLikeByGroupId(data.groupId, widgets);
        if (widget) {
          return widget.label || widget.key;
        }

        return '';
      })
      .otherwise(() => '');
  }

  areRulesSameType = (rule1: TaskPermissionRule, rule2: TaskPermissionRule) =>
    rule1.sourceType === rule2.sourceType &&
    (rule1.sourceType === TaskPermissionRuleSourceType.ChecklistInitiator ||
      (rule1.sourceType === TaskPermissionRuleSourceType.FormField &&
        !!rule1.sourceFormFieldWidgetGroup &&
        !!rule2.sourceFormFieldWidgetGroup &&
        rule1.sourceFormFieldWidgetGroup.id === rule2.sourceFormFieldWidgetGroup.id));

  extractUniqueTypedRules = (rules: TaskPermissionRule[]) => uniqWith(rules, this.areRulesSameType);

  areRulesEqual = (rule1: TaskPermissionRule, rule2: TaskPermissionRule) =>
    rule1.targetTaskTemplateGroup.id === rule2.targetTaskTemplateGroup.id && this.areRulesSameType(rule1, rule2);

  isRuleAvailable = (existingRules: TaskPermissionRule[], possibleRule: TaskPermissionRule) =>
    existingRules.findIndex(r => this.areRulesEqual(r, possibleRule)) === -1;

  generatePossibleRulesForTaskTemplate(
    emailAndMembersFieldWidgets: Widget[],
    selectedOrganizationId: Organization['id'],
    templateRevisionId: TemplateRevision['id'],
    taskTemplate: TaskTemplate,
  ) {
    const rules = [];

    const checklistInitiatorRule = {
      id: Muid.fromUuid(uuid()),
      organization: {
        id: selectedOrganizationId,
      },
      templateRevision: {
        id: templateRevisionId,
      },
      targetTaskTemplateGroup: {
        id: taskTemplate.group.id,
      },
      sourceType: TaskPermissionRuleSourceType.ChecklistInitiator,
      taskRead: true,
      taskUpdate: true,
    };
    rules.push(checklistInitiatorRule);

    emailAndMembersFieldWidgets.forEach(widget => {
      const rule = {
        id: Muid.fromUuid(uuid()),
        organization: {
          id: selectedOrganizationId,
        },
        templateRevision: {
          id: templateRevisionId,
        },
        targetTaskTemplateGroup: {
          id: taskTemplate.group.id,
        },
        sourceType: TaskPermissionRuleSourceType.FormField,
        sourceFormFieldWidgetGroup: {
          id: widget.header.group.id,
        },
        taskRead: true,
        taskUpdate: true,
      };

      rules.push(rule);
    });

    return rules;
  }
}
