import {
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
  useToast,
} from 'components/design/next';
import * as React from 'react';
import { ImportDataSet } from './import-data-set';
import { useCreateDataSetFromCsvMutation } from '../../query-builder/create-data-set-from-csv-mutation';
import { useQueryClient } from 'react-query';
import { GetAllDataSetsQuery } from '../../query-builder/get-all-data-sets';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { DataSet } from '@process-street/subgrade/process';
import { getWidgetModalsMachineService, Selectors } from 'features/widgets/widget-modals-machine';
import { useSelector } from '@xstate/react';
import { isAxiosError } from '@process-street/subgrade/api';
import { HttpStatus } from '@process-street/subgrade/util';

export type DatasetCreationModalProps = Partial<Pick<UseDisclosureReturn, 'isOpen' | 'onClose'>> & {
  onCreateSuccess?: (dataSet: DataSet) => void;
};

const actor = getWidgetModalsMachineService();

export const DatasetCreateFromCsvModal: React.FC<React.PropsWithChildren<DatasetCreationModalProps>> = ({
  isOpen: isOpenProp,
  onClose: onCloseProp,
  onCreateSuccess,
}) => {
  const toast = useToast();
  const fileInputRef = React.useRef<{ clear: () => void }>(null);
  const queryClient = useQueryClient();
  const isActorOpen = useSelector(actor, Selectors.getIsOpen('importDataSet'));
  const widget = useSelector(actor, Selectors.getWidget);

  const isOpen = isOpenProp ?? isActorOpen;
  const onClose = () => {
    if (onCloseProp) {
      onCloseProp();
    } else {
      actor.send({ type: 'CLOSE_IMPORT_DATA_SET' });
    }
  };

  const createDataSetFromCsvMutation = useCreateDataSetFromCsvMutation({
    onSuccess: async (dataSet: DataSet) => {
      await queryClient.invalidateQueries(GetAllDataSetsQuery.key);

      toast({
        status: 'success',
        description: 'Data set created',
      });

      onClose();
      if (onCreateSuccess) {
        onCreateSuccess(dataSet);
      } else {
        if (widget) {
          actor.send({ type: 'OPEN_LINK_DATA_SET', widget, dataSet });
        }
      }
    },
    onError: e => {
      if (isAxiosError(e) && e?.response?.status === HttpStatus.BAD_REQUEST) {
        toast({
          status: 'error',
          title: "We're having problems creating the data set",
          description: e?.response?.data?.message ?? DefaultErrorMessages.unexpectedErrorDescription,
        });
      } else {
        toast({
          status: 'error',
          title: "We're having problems creating the data set",
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      }
      // Clear the file input so when the user selects the file to try again we will be able to catch the onChange event,
      // otherwise, the onChange event would not be triggered because the file hasn't changed.
      fileInputRef.current?.clear();
    },
  });

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ?? [];
    const file = files[0];

    if (!file) {
      return toast({
        status: 'warning',
        title: 'Please select a CSV file to upload',
      });
    }

    createDataSetFromCsvMutation.mutate({ file });
  };

  const handleClose = () => {
    fileInputRef.current?.clear();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent mt="20">
        <HStack
          spacing="2"
          as={ModalHeader}
          border="0"
          borderBottomWidth="1px"
          borderStyle="solid"
          borderColor="gray.200"
        >
          <Icon color="gray.400" icon="cog" size="4" />
          <Text as="h4" size="xl" fontWeight="700" color="gray.700">
            Create Data Set
          </Text>
        </HStack>
        <ModalCloseButton />

        <ModalBody py="10">
          <ImportDataSet
            ref={fileInputRef}
            isLoading={createDataSetFromCsvMutation.isLoading}
            onChange={handleFileSelection}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
