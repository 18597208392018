import { NativeAutomation } from '@process-street/subgrade/process';
import { CustomNotificationUtils } from './custom-notification-utils';
import { match, P } from 'ts-pattern';

export namespace ScheduledTriggerUtils {
  type Config = CustomNotificationUtils.Config;
  type Trigger = NativeAutomation.GetTriggerByType<'Scheduled'>;

  export const configToScheduledTrigger = (config: Config): Trigger => {
    const checklistIdPath = NativeAutomation.getJsonPath<
      NativeAutomation.AutomationContext.WorkflowRunCreated | NativeAutomation.AutomationContext.WorkflowRunCompleted
    >('trigger', 'event', 'checklist', 'id');

    const checklistCreatedDatePath =
      NativeAutomation.getJsonPath<NativeAutomation.AutomationContext.WorkflowRunCreated>(
        'trigger',
        'event',
        'checklist',
        'audit',
        'createdDate',
      );

    const checklistCompletedDatePath =
      NativeAutomation.getJsonPath<NativeAutomation.AutomationContext.WorkflowRunCompleted>(
        'trigger',
        'event',
        'checklist',
        'completedDate',
      );

    const taskChecklistIdPath = NativeAutomation.getJsonPath<
      | NativeAutomation.AutomationContext.TaskCompleted
      | NativeAutomation.AutomationContext.TaskUncompleted
      | NativeAutomation.AutomationContext.TaskDueDateCreated
      | NativeAutomation.AutomationContext.TaskDueDateUpdated
      | NativeAutomation.AutomationContext.TaskDueDateDeleted
    >('trigger', 'event', 'checklist', 'id');

    const taskCompletedDatePath = NativeAutomation.getJsonPath<NativeAutomation.AutomationContext.TaskCompleted>(
      'trigger',
      'event',
      'task',
      'completedDate',
    );

    const taskDueDateDatePath = NativeAutomation.getJsonPath<
      | NativeAutomation.AutomationContext.TaskDueDateCreated
      | NativeAutomation.AutomationContext.TaskDueDateUpdated
      | NativeAutomation.AutomationContext.TaskDueDateDeleted
    >('trigger', 'event', 'task', 'dueDate');

    return {
      triggerType: 'Scheduled',
      config: {
        offset: config.timing?.offset!,
        offsetDirection: config.timing?.offsetDirection!,
        ...match<Config, Pick<Trigger['config'], 'createTrigger' | 'updateTriggers' | 'deleteTriggers'>>(config)
          .with(
            { timing: { entity: { type: 'WorkflowRun' }, property: 'startDate' } },
            ({
              timing: {
                entity: { templateRevisionId },
              },
            }) => ({
              createTrigger: {
                triggerType: 'WorkflowRunCreated',
                config: { templateRevisionId },
                scheduledJobKeyPath: checklistIdPath,
                scheduledJobDatePath: checklistCreatedDatePath,
              },
              updateTriggers: [],
              deleteTriggers: [],
            }),
          )
          .with(
            { timing: { entity: { type: 'WorkflowRun' }, property: 'completedDate' } },
            ({
              timing: {
                entity: { templateRevisionId },
              },
            }) => ({
              createTrigger: {
                triggerType: 'WorkflowRunCompleted',
                config: { templateRevisionId },
                scheduledJobKeyPath: checklistIdPath,
                scheduledJobDatePath: checklistCompletedDatePath,
              },
              updateTriggers: [],
              deleteTriggers: [
                {
                  triggerType: 'WorkflowRunUncompleted',
                  config: { templateRevisionId },
                  scheduledJobKeyPath: checklistIdPath,
                },
              ],
            }),
          )
          .with(
            { timing: { entity: { type: 'Task' }, property: 'dueDate', offsetDirection: P.union('Before', 'After') } },
            ({
              timing: {
                entity: { taskTemplateId },
              },
            }) => ({
              createTrigger: {
                triggerType: 'TaskDueDateCreated',
                scheduledJobKeyPath: taskChecklistIdPath,
                scheduledJobDatePath: taskDueDateDatePath,
                config: { taskTemplateId },
              },
              updateTriggers: [
                {
                  triggerType: 'TaskDueDateUpdated',
                  scheduledJobKeyPath: taskChecklistIdPath,
                  scheduledJobDatePath: taskDueDateDatePath,
                  config: { taskTemplateId },
                },
              ],
              deleteTriggers: [
                {
                  triggerType: 'TaskDueDateDeleted',
                  config: { taskTemplateId },
                  scheduledJobKeyPath: taskChecklistIdPath,
                },
              ],
            }),
          )
          .with(
            { timing: { entity: { type: 'Task' }, property: 'completedDate' } },
            ({
              timing: {
                entity: { taskTemplateId },
              },
            }) => ({
              createTrigger: {
                triggerType: 'TaskCompleted',
                scheduledJobKeyPath: taskChecklistIdPath,
                scheduledJobDatePath: taskCompletedDatePath,
                config: { taskTemplateId },
              },
              updateTriggers: [],
              deleteTriggers: [
                {
                  triggerType: 'TaskUncompleted',
                  config: { taskTemplateId },
                  scheduledJobKeyPath: checklistIdPath,
                },
              ],
            }),
          )
          .run(),
      },
    };
  };
}
