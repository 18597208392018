import { MultiSelectFieldValue, MultiSelectItemValueStatus } from '@process-street/subgrade/process';
import { ActorRefFrom, assertEvent, assign, setup, StateFrom, raise } from 'xstate5';
import { SubtaskEditor } from './subtasks-form-field';
import { Muid, MuidUtils } from '@process-street/subgrade/core';
import { useCallback, useMemo } from 'react';
import { useSelector } from '@xstate5/react';
import { waitForElement } from 'app/utils/wait-for-element';

export namespace SubtasksFormFieldMachine {
  type Context = {
    items: Array<MultiSelectFieldValue>;
    touched: Record<number, boolean>;
    errors: Record<number, boolean>;
  };

  type Event =
    | { type: 'UPDATE_ITEM'; index: number; updatedItem: MultiSelectFieldValue }
    | { type: 'DELETE_ITEM'; index: number }
    | { type: 'INSERT_ITEM'; afterIndex: number; editorType: SubtaskEditor; newItem?: MultiSelectFieldValue }
    | { type: 'DROP_ITEM'; before?: MultiSelectFieldValue; after?: MultiSelectFieldValue; item: MultiSelectFieldValue }
    | { type: 'BLUR'; index: number }
    | { type: 'FOCUS'; index: number }
    | { type: 'SET_FOCUS'; index: number }
    | { type: 'APPEND_ITEM'; editorType: SubtaskEditor }
    | { type: 'MOVE_ITEM' }
    | { type: 'SET_ITEMS'; items: MultiSelectFieldValue[] }
    | { type: 'PERSIST' };

  export const create = ({
    items,
    headerId,
  }: Pick<Context, 'items'> & {
    headerId?: Muid;
  }) => {
    return setup({
      types: {
        context: {} as Context,
        events: {} as Event,
      },
      actions: {
        sendPersist: raise({ type: 'PERSIST' }),
        persist: () => {
          throw new Error('Implementation missing. `persist` should be provided when creating the machine.');
        },
        setItems: assign({
          items: ({ event }) => {
            assertEvent(event, 'SET_ITEMS');

            return event.items;
          },
        }),
        setFocus: async ({ event, context }) => {
          assertEvent(event, 'SET_FOCUS');
          const subtaskId = context.items[event.index]?.id;

          if (!subtaskId) return;

          const selector = `[name="subtasks.${subtaskId}${headerId ? '-' + headerId : ''}"`;
          try {
            const input = await waitForElement<HTMLInputElement>(selector, 1000);
            const size = input.value.length;

            input.focus();
            // Set the cursor position to the end of the input's value
            input.setSelectionRange(size, size);
          } catch (e) {
            console.warn((e as Error).message);
          }
        },
        validate: assign({
          errors: ({ context }) => {
            return context.items.map((item, index) => {
              const isTouched = context.touched[index];

              if (!isTouched) return false;

              return !item.name;
            });
          },
        }),
        deleteItem: assign({
          items: ({ context, event }) => {
            assertEvent(event, 'DELETE_ITEM');

            return context.items.filter((_, index) => index !== event.index);
          },
        }),
        updateItem: assign({
          items: ({ context, event }) => {
            assertEvent(event, 'UPDATE_ITEM');

            return context.items.map((item, index) => {
              if (index !== event.index) return item;

              return {
                ...item,
                ...event.updatedItem,
              };
            });
          },
        }),
        insertItem: assign({
          items: ({ context, event }) => {
            assertEvent(event, 'INSERT_ITEM');

            const itemType = event.editorType === SubtaskEditor.OneOffTask ? 'Dynamic' : 'Static';

            const newItem =
              event.newItem ??
              ({
                status: MultiSelectItemValueStatus.NotCompleted,
                itemType,
                id: MuidUtils.randomMuid(),
                name: '',
              } as MultiSelectFieldValue);

            return [
              ...context.items.slice(0, event.afterIndex + 1),
              newItem,
              ...context.items.slice(event.afterIndex + 1),
            ];
          },
        }),
        appendItem: assign({
          items: ({ context, event }) => {
            assertEvent(event, 'APPEND_ITEM');

            const itemType = event.editorType === SubtaskEditor.OneOffTask ? 'Dynamic' : 'Static';

            const newItem = {
              status: MultiSelectItemValueStatus.NotCompleted,
              itemType,
              id: MuidUtils.randomMuid(),
              name: '',
            } as MultiSelectFieldValue;

            return [...context.items, newItem];
          },
        }),
        assignTouched: assign({
          touched: ({ context, event }) => {
            assertEvent(event, 'BLUR');

            return {
              ...context.touched,
              [event.index]: true,
            };
          },
        }),
        moveItem: assign({}),
        dropItem: assign({
          items: ({ context, event }) => {
            assertEvent(event, 'DROP_ITEM');
            const { before, after, item } = event;

            if (!before && !after) return context.items;
            if (item.id === before?.id) return context.items;
            if (item.id === after?.id) return context.items;

            const newValue = context.items.reduce((acc, current) => {
              if (current.id === before?.id) {
                acc.push(item, current);
              } else if (current.id === after?.id) {
                acc.push(current, item);
              } else if (current.id !== item.id) {
                acc.push(current);
              }

              return acc;
            }, [] as MultiSelectFieldValue[]);

            return newValue.flat();
          },
        }),
      },
    }).createMachine({
      context: {
        items,
        errors: {},
        touched: {},
      },
      initial: 'ready',
      states: {
        ready: {
          on: {
            UPDATE_ITEM: { actions: ['updateItem', 'validate', 'sendPersist'] },
            DELETE_ITEM: { actions: ['deleteItem', 'validate', 'sendPersist'] },
            INSERT_ITEM: { actions: ['insertItem', 'validate', 'sendPersist'] },
            MOVE_ITEM: { actions: ['moveItem', 'validate', 'sendPersist'] },
            DROP_ITEM: { actions: ['dropItem', 'validate', 'sendPersist'] },
            APPEND_ITEM: { actions: ['appendItem', 'validate', 'sendPersist'] },
            BLUR: { actions: ['assignTouched', 'validate'] },
            SET_FOCUS: { actions: ['setFocus'] },
            PERSIST: { actions: ['persist'] },
            SET_ITEMS: { actions: ['setItems'] },
          },
        },
      },
    });
  };

  export type Machine = ReturnType<typeof create>;
  export type State = StateFrom<Machine>;
  export type ActorRef = ActorRefFrom<Machine>;

  export namespace Selectors {
    export const getErrors = (state: State) => state.context.errors;
    export const getTouched = (state: State) => state.context.touched;
    export const getHasError = (index: number) => (state: State) => state.context.errors[index] ?? false;
    export const getIsTouched = (index: number) => (state: State) => state.context.touched[index] ?? false;
    export const getItems = (state: State) => state.context.items;
  }

  export namespace Hooks {
    export const useErrors = (actorRef: ActorRef) => {
      return useSelector(actorRef, Selectors.getErrors);
    };

    export const useHasError = (actorRef: ActorRef, index: number) => {
      const selector = useMemo(() => Selectors.getHasError(index), [index]);
      return useSelector(actorRef, selector);
    };

    export const useItems = (actorRef: ActorRef) => {
      return useSelector(actorRef, Selectors.getItems);
    };

    export const useIsTouched = (actorRef: ActorRef, index: number) => {
      const selector = useMemo(() => Selectors.getIsTouched(index), [index]);
      return useSelector(actorRef, selector);
    };

    export const useTouched = (actorRef: ActorRef) => {
      return useSelector(actorRef, Selectors.getTouched);
    };

    export const useApi = (actorRef: ActorRef) => {
      const getItems = useCallback(() => Selectors.getItems(actorRef.getSnapshot()), [actorRef]);

      const onDelete = useCallback((index: number) => actorRef.send({ type: 'DELETE_ITEM', index }), [actorRef]);

      const onUpdate = useCallback(
        (index: number, updatedItem: MultiSelectFieldValue) => {
          actorRef.send({ type: 'UPDATE_ITEM', index, updatedItem });
        },
        [actorRef],
      );

      const onInsert = useCallback(
        (afterIndex: number, editorType: SubtaskEditor, newItem?: MultiSelectFieldValue) => {
          actorRef.send({ type: 'INSERT_ITEM', afterIndex, editorType, newItem });
        },
        [actorRef],
      );

      const onBlur = useCallback(
        (index: number) => {
          actorRef.send({ type: 'BLUR', index });
        },
        [actorRef],
      );

      const onFocus = useCallback(
        (index: number) => {
          actorRef.send({ type: 'FOCUS', index });
        },
        [actorRef],
      );

      const setFocus = useCallback(
        (index: number) => {
          actorRef.send({ type: 'SET_FOCUS', index });
        },
        [actorRef],
      );

      const dropItem = useCallback(
        (data: Omit<Extract<Event, { type: 'DROP_ITEM' }>, 'type'>) => {
          actorRef.send({ type: 'DROP_ITEM', ...data });
        },
        [actorRef],
      );

      const appendItem = useCallback(
        (data: Omit<Extract<Event, { type: 'APPEND_ITEM' }>, 'type'>) => {
          actorRef.send({ type: 'APPEND_ITEM', ...data });
        },
        [actorRef],
      );

      const setItems = useCallback(
        (data: Omit<Extract<Event, { type: 'SET_ITEMS' }>, 'type'>) => {
          actorRef.send({ type: 'SET_ITEMS', ...data });
        },
        [actorRef],
      );

      return useMemo(
        () => ({
          onDelete,
          onUpdate,
          onInsert,
          onBlur,
          onFocus,
          setFocus,
          dropItem,
          appendItem,
          getItems,
          setItems,
        }),
        [onDelete, onUpdate, onInsert, onBlur, onFocus, setFocus, dropItem, appendItem, getItems, setItems],
      );
    };
  }
}
