import { GridItem, HStack, FormControl, Grid, FormLabel, Skeleton } from 'components/design/next';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import {
  useCreateOrganizationDomainMutation,
  useDeleteOrganizationDomainMutation,
  useGetOrganizationDomainsQuery,
  useOrganizationLinkableDomains,
} from 'features/organization/query-builder';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { match, P } from 'ts-pattern';
import { isUndefined } from '@process-street/subgrade/core';
import { SimpleOption } from 'components/design/BlvdSelect/types';

export const DomainsField = () => {
  const currentOrganization = useSelector(SessionSelector.getSelectedOrganization);

  const [domains, setDomains] = React.useState<string[]>();

  const organizationDomainsQuery = useGetOrganizationDomainsQuery({ organizationId: currentOrganization?.id });

  React.useEffect(() => {
    if (isUndefined(domains) && organizationDomainsQuery.data) {
      setDomains(organizationDomainsQuery?.data.map(domain => domain.name) ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
  }, [organizationDomainsQuery.data]);

  const linkableDomainsQuery = useOrganizationLinkableDomains({ organizationId: currentOrganization?.id });
  const createOrganizationDomainMutation = useCreateOrganizationDomainMutation();
  const deleteOrganizationDomainMutation = useDeleteOrganizationDomainMutation();

  if (!linkableDomainsQuery.data?.length || !organizationDomainsQuery.data?.length) return null;

  return (
    <FormControl as={Grid} templateColumns={{ base: '1fr', md: '220px 1fr' }}>
      <GridItem display="flex" alignItems="center">
        <FormLabel htmlFor="domains" color="gray.600" as="label">
          Domains
        </FormLabel>
      </GridItem>
      <GridItem>
        <HStack
          w="full"
          sx={{ '.blvd-select': { 'width': '100%', '.blvd-select__control .blvd-select__value-container': { pl: 3 } } }}
        >
          <Skeleton w="full" isLoaded={!organizationDomainsQuery.isLoading && !linkableDomainsQuery.isLoading}>
            <BlvdSelect
              id="domains"
              name="domains"
              value={domains?.map(domain => ({ label: domain, value: domain })) ?? []}
              options={
                linkableDomainsQuery?.data?.map(domain => ({
                  label: domain,
                  value: domain,
                })) ?? []
              }
              onChange={(value, action) => {
                if (BlvdSelectHelpers.isOptionsType<SimpleOption>(value)) {
                  setDomains(value.map(v => v.value));
                } else {
                  setDomains([]);
                }

                match(action)
                  .with({ action: 'select-option', option: P.not(P.nullish) }, action => {
                    createOrganizationDomainMutation.mutate({
                      organizationId: currentOrganization?.id,
                      domain: action.option.value,
                    });
                  })
                  .with({ action: 'deselect-option', option: P.not(P.nullish) }, action => {
                    deleteOrganizationDomainMutation.mutate({
                      organizationId: currentOrganization?.id,
                      domain: action.option.value,
                    });
                  })
                  .with({ action: 'remove-value', removedValue: P.not(P.nullish) }, action => {
                    deleteOrganizationDomainMutation.mutate({
                      organizationId: currentOrganization?.id,
                      domain: action.removedValue.value,
                    });
                  })
                  .otherwise(() => {});
              }}
              isMulti
            />
          </Skeleton>
        </HStack>
      </GridItem>
    </FormControl>
  );
};
