import { ICellRendererParams } from '@ag-grid-community/core';
import { SendRichEmailFieldValue } from '@process-street/subgrade/process';
import { SendRichEmailTimestamp } from 'features/widgets/components/send-rich-email-timestamp';
import React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

export interface SendRichEmailFieldRendererProps extends Partial<ICellRendererParams> {
  value?: SendRichEmailFieldValue;
}

export const SendRichEmailFieldRenderer: React.FC<React.PropsWithChildren<SendRichEmailFieldRendererProps>> = ({
  value,
  rowIndex,
  colDef,
}) => {
  const currentUser = useSelector(SessionSelector.getCurrentUser);
  if (!value || !currentUser) {
    return <div />;
  }
  return (
    <SendRichEmailTimestamp
      id={`checklist-${rowIndex}-${colDef?.colId ?? 'unknown-send-rich-email-column'}`}
      {...value}
    />
  );
};
