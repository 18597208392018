import * as React from 'react';
import { Organization } from '@process-street/subgrade/core';
import { SignUpSelector } from 'pages/guest-signup/components/signup-selector';
import { SignUpForm } from 'pages/guest-signup/components/signup-form';
import { SignUpInfo } from 'pages/guest-signup/model/model';
import { useInjector } from 'components/injection-provider';
import { LocalStorageService } from 'features/storage/local-storage-service';

export type SignupFlowProps = {
  organization: Organization;
  email: string;
  onSubmit: (info: SignUpInfo) => Promise<unknown>;
};

enum Mode {
  Selector,
  Form,
}

export const SignupFlow: React.FC<React.PropsWithChildren<SignupFlowProps>> = ({ email, organization, onSubmit }) => {
  const [mode, setMode] = React.useState(Mode.Selector);
  const { $state, Auth0Service: auth0Service } = useInjector('$state', 'Auth0Service');

  const getUrl = () => {
    const { url } = $state.params;
    if (url === '/dashboard') {
      return '/inbox';
    } else {
      return url;
    }
  };

  const signUpUsingGoogle = () => {
    const url = getUrl();
    if (url) {
      LocalStorageService.setItem('url', url);
    }

    auth0Service.loginUsingGoogle({
      login_hint: email,
    });
  };

  const signIn = () => {
    const url = getUrl();
    $state.go('login', { url });
  };

  const onEmailSignup = () => {
    setMode(Mode.Form);
  };

  return (
    <>
      {
        {
          [Mode.Selector]: (
            <SignUpSelector
              onGoogleSignUp={signUpUsingGoogle}
              onEmailSignUp={onEmailSignup}
              onSignIn={signIn}
              organization={organization}
            />
          ),
          [Mode.Form]: <SignUpForm email={email} organization={organization} onSubmit={onSubmit} />,
        }[mode]
      }
    </>
  );
};
