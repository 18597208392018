import { ICellRendererParams } from '@ag-grid-community/core';
import { ChecklistGridUserDto } from '@process-street/subgrade/dashboard';
import { Avatar } from 'components/common/Avatar';
import * as React from 'react';

export interface CompletedByRendererProps extends Partial<ICellRendererParams> {
  value?: ChecklistGridUserDto;
}

export const CompletedByRenderer: React.FC<React.PropsWithChildren<CompletedByRendererProps>> = ({ value }) => {
  if (!value) {
    return <div />;
  }

  return (
    <div>
      <Avatar url={value.avatarUrl} initials={value.initials} />
    </div>
  );
};
