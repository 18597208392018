import { canAccess, Feature } from 'services/features/features';
import { SessionSelector } from 'reducers/session/session.selectors';
import { connectController } from 'reducers/util';
import { TaskTemplatePermitsSelector } from 'components/task-permission/store/task-template-permit.selector';
import templateUrl from './task-permission-template-button.component.html';
import './task-permission-template-button.scss';
import { match } from 'ts-pattern';
import { TaskPermissionRuleSelector } from '../store/task-permission-rule.selector';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import { selectedOrganizationPredefinedTaskGroupMembershipsSelector } from 'reducers/group/group.selectors';
import { StringUtils } from '@process-street/subgrade/util';
import { UserUtils } from '@process-street/subgrade/util';

export const TaskPermissionTemplateButtonComponent = {
  bindings: {
    user: '<',
    taskTemplate: '<',
    taskTemplates: '<',
    bulk: '<',
    disabled: '<',
    setHasCustomPermissions: '&',
  },
  templateUrl,
  controller: class {
    constructor($ngRedux, $uibModal, TaskPermissionRuleService, TaskTemplatePermitService) {
      'ngInject';

      this.$uibModal = $uibModal;
      this.title = 'Task permissions';

      const mapStateToThis = () => state => {
        const selectedOrganization = SessionSelector.getSelectedOrganization(state);
        if (!selectedOrganization) {
          return {};
        }

        const planId = selectedOrganization.subscription.plan.id;
        const taskPermissionFeatureIsAvailable = canAccess(Feature.TASK_PERMISSIONS, planId);

        const taskTemplateId = this.taskTemplate?.id;
        const taskTemplateIds = taskTemplateId ? [taskTemplateId] : [];
        const taskTemplateGroupId = this.taskTemplate?.group?.id;
        const taskTemplateGroupIds = taskTemplateGroupId ? [taskTemplateGroupId] : [];
        const templateRevisionId = this.taskTemplate?.templateRevision.id;

        const { systemPermits: systemPermitsCount, nonSystemPermits: nonSystemPermitsCount } =
          TaskTemplatePermitsSelector.getCountsByTaskTemplateIds(taskTemplateIds)(state);

        const totalSystemPermitsCount =
          TaskTemplatePermitsSelector.getAllByTaskTemplateIdsOnlySystemGroups(taskTemplateIds)(state).length;

        const hasDirectPermits = nonSystemPermitsCount !== 0;
        const hasDefaultSystemPermits =
          (systemPermitsCount === 4 && totalSystemPermitsCount === 0) || systemPermitsCount === totalSystemPermitsCount;

        const allTaskPermissionRules = taskTemplateGroupIds
          ? TaskPermissionRuleSelector.getAllByTaskTemplateGroupIds(taskTemplateGroupIds)(state)
          : [];
        const taskPermissionRules = TaskPermissionRuleService.extractUniqueTypedRules(allTaskPermissionRules);
        const hasTaskPermissionRules = taskPermissionRules.length > 0;
        const emailAndMembersFieldWidgets = templateRevisionId
          ? WidgetSelector.getAllEmailAndMembersWidgetsByTemplateRevisionId(templateRevisionId)(state)
          : [];

        const widgetsLoaded = templateRevisionId
          ? WidgetSelector.getLoadedStatusByTemplateRevisionId(templateRevisionId)(state)
          : false;
        const ruleUsernames = taskPermissionRules.map(rule =>
          TaskPermissionRuleService.getRuleTitle(rule, emailAndMembersFieldWidgets, widgetsLoaded),
        );

        const taskTemplatePermitsSystemGroups =
          TaskTemplatePermitsSelector.getAllByTaskTemplateIdsOnlySystemGroups(taskTemplateIds)(state);
        const predefinedGroups = selectedOrganizationPredefinedTaskGroupMembershipsSelector(state);
        const activePredefinedGroups = Object.values(predefinedGroups)
          .filter(organizationMembership => {
            if (!organizationMembership) return false;

            const filteredPermits = taskTemplatePermitsSystemGroups.filter(
              permit => permit.organizationMembership.id === organizationMembership.id,
            );
            return filteredPermits.every(permit => permit.taskRead);
          })
          .map(om => UserUtils.getLabel(om.user, false));

        const taskTemplatePermitsNoSystemGroups = TaskTemplatePermitsSelector.getAllByTaskTemplateIdsNoSystemGroups(
          taskTemplateIds,
        )(state).filter(permit => permit.taskRead);
        const permissionMemberships = TaskTemplatePermitService.getMembershipsConcatenation(
          taskTemplatePermitsNoSystemGroups,
        ).map(om => om.user.username);

        const usernames = [...permissionMemberships, ...ruleUsernames, ...activePredefinedGroups];

        const permitsAreDefault = hasDefaultSystemPermits && !hasDirectPermits && !hasTaskPermissionRules;

        this.setHasCustomPermissions({ hasCustomPermissions: !permitsAreDefault });

        const label = match({ bulk: this.bulk, permitsAreDefault })
          .with({ bulk: true }, () => '')
          .with({ permitsAreDefault: true }, () =>
            [
              StringUtils.getSentenceList({ items: activePredefinedGroups, prefix: 'Visible to ', max: 3 }),
              '(default)',
            ].join(' '),
          )
          .otherwise(() =>
            StringUtils.getSentenceList({
              items: usernames,
              prefix: 'Visible to ',
              max: 3,
            }),
          );

        return {
          permitsAreDefault,
          taskPermissionFeatureIsAvailable,
          label,
        };
      };

      const shouldChange = change => change.taskTemplate;

      connectController($ngRedux, mapStateToThis, null, shouldChange)(this);
    }

    isReady = () => this.taskTemplate;

    buttonClass = () =>
      match({ bulk: this.bulk, ...this.state })
        .with({ bulk: true }, () => 'btn-default task-permission-bulk-assigner')
        .otherwise(() => 'btn-md btn-outline-info task-bar-template-cell__button');

    checkFeature(event) {
      const { taskPermissionFeatureIsAvailable } = this.state;

      if (!taskPermissionFeatureIsAvailable) {
        event.stopPropagation();
        this.openUpgradeModal();
      }
    }

    openUpgradeModal() {
      this.$uibModal.open({
        component: 'ps-task-permission-upgrade-modal',
        animation: true,
        backdrop: 'static',
        windowClass: 'task-permission-upgrade-modal',
        resolve: {},
      });
    }
  },
};
