import * as React from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle } from 'components/design/next';
import { FieldType, Widget, WidgetType } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { GetActor } from './make-get-actor';
import { FormFields } from '../form-fields';
import { Content } from '../content';
import { ErrorBoundary } from 'react-error-boundary';

const TextContentWidget = React.lazy(() =>
  import('../content/text-content').then(({ TextContent: TextContentWidget }) => ({
    default: TextContentWidget,
  })),
);

type WidgetListItemProps = {
  widget: Widget;
  getActor: GetActor;
  isFirst: boolean;
  isLast: boolean;
};

export const WidgetListItem = React.memo(({ widget, getActor, isFirst, isLast }: WidgetListItemProps) => (
  <ErrorBoundary fallbackRender={ErrorBoundaryFallback}>
    {match(widget)
      .with({ fieldType: FieldType.Text }, widget => {
        return <FormFields.Text actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ fieldType: FieldType.Email }, widget => {
        return <FormFields.Email actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ fieldType: FieldType.Textarea }, widget => {
        return <FormFields.Textarea actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ fieldType: FieldType.Url }, widget => {
        return <FormFields.Url actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ fieldType: P.union(FieldType.Select, FieldType.MultiChoice) }, widget => {
        return <FormFields.Select actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ fieldType: FieldType.File }, widget => {
        return <FormFields.File actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ fieldType: FieldType.Date }, widget => {
        return <FormFields.Date actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ fieldType: FieldType.Number }, widget => {
        return <FormFields.Number actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ fieldType: FieldType.Table }, widget => {
        return <FormFields.Table actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ fieldType: FieldType.Hidden }, widget => {
        return <FormFields.Hidden actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ fieldType: FieldType.Snippet }, widget => {
        return <FormFields.Snippet actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ fieldType: FieldType.Members }, widget => {
        return <FormFields.Members actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ header: { type: WidgetType.Text } }, widget => {
        return (
          <React.Suspense fallback="">
            <TextContentWidget actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />
          </React.Suspense>
        );
      })
      .with({ header: { type: WidgetType.Image } }, widget => {
        return <Content.Image actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ header: { type: WidgetType.File } }, widget => {
        return <Content.File actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ header: { type: WidgetType.Video } }, widget => {
        return <Content.Video actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ header: { type: WidgetType.CrossLink } }, widget => {
        return <Content.Page actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ fieldType: FieldType.SendRichEmail }, widget => {
        return <Content.Email actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ fieldType: FieldType.MultiSelect }, widget => {
        return <Content.Subtasks actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ header: { type: WidgetType.Embed } }, widget => {
        return <Content.Embed actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .with({ header: { type: WidgetType.Table } }, widget => {
        return <Content.Table actor={getActor(widget)} isFirst={isFirst} isLast={isLast} />;
      })
      .otherwise(() => null)}
  </ErrorBoundary>
));

WidgetListItem.displayName = 'WidgetListItem';

const ErrorBoundaryFallback = () => {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>Oops!</AlertTitle>
      <AlertDescription>Something went wrong with this widget.</AlertDescription>
    </Alert>
  );
};
