import * as React from 'react';
import { useNgStateModalControls } from 'components/ng-state-modal-controls';
import { AppModalName } from 'app/app.constants';
import { ImportWorkflowModal } from 'pages/templates/_id/components/import-workflow-modal/import-workflow-modal';
import { useNewBlankWorkflowButton } from 'features/new-buttons/use-new-blank-workflow-button';
import { CreateTemplateSource } from 'services/template-service.interface';
import { FolderPickerModal } from 'components/folder-picker/modal';
import { PickButton } from 'features/new-menu/new-workflow-modal';
import { useSelectedOrganization } from 'hooks/use-selected-organization';

export const ImportWorkflowModalWrapper = () => {
  const { isOpen, onClose } = useNgStateModalControls(AppModalName.ImportWorkflow);
  const organizationId = useSelectedOrganization()?.id;

  const {
    buttonAction: newAiGeneratedWorkflowButtonAction,
    folderPickerProps: newAiGeneratedWorkflowFolderPickerProps,
  } = useNewBlankWorkflowButton({
    source: CreateTemplateSource.MAIN_NAV_BAR,
    isAiGenerated: true,
    allowSelectFolder: true,
  });

  const folderPickerProps = [newAiGeneratedWorkflowFolderPickerProps].find(props => props.isOpen);

  return (
    <>
      {isOpen && (
        <ImportWorkflowModal isOpen={isOpen} onClose={onClose} onFinish={newAiGeneratedWorkflowButtonAction} />
      )}
      {folderPickerProps?.isOpen && (
        <FolderPickerModal {...folderPickerProps} verb="add" organizationId={organizationId} pickButton={PickButton} />
      )}
    </>
  );
};
