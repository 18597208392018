import { ConditionalRules } from '@process-street/subgrade/conditional-logic';
import { Attachment, Comment } from '@process-street/subgrade/process';
import { EntityMap, RequestStage } from '@process-street/subgrade/redux/types';
import { approvalRuleSubjectReducer } from 'components/approval-rules/store/approval-rules.reducers';
import { approvalReducer } from 'components/approvals/store/approval.reducers';
import { authConnectionReducers } from 'components/auth-connection/store/auth-connection.reducers';
import { checklistTaskAssignmentEntitiesReducer } from 'components/checklist-task-assignment/store/checklist-task-assignment.reducer';
import { dynamicDueDatesReducer } from 'components/dynamic-due-dates/store/dynamic-due-dates.reducers';
import { formFieldValueEntitiesReducer } from 'components/form-field-value/store/form-field-value.reducer';
import { webhooksEntitiesReducer } from 'components/organization-manage/webhooks/store/webhooks.reducer';
import { roleAssignmentRuleEntitiesReducer } from 'components/role-assignments/store/role-assignment-rules.reducer';
import { taskPermissionRuleReducer } from 'components/task-permission/store/task-permission-rule.reducer';
import { taskTemplatePermitEntitiesReducer } from 'components/task-permission/store/task-template-permit.reducer';
import { templateTaskAssignmentEntitiesReducer } from 'components/template-task-assignment/store/template-task-assignment.reducer';
import { widgetEntitiesReducer } from 'components/widgets/store/widget.reducer';
import { checklistAssignmentEntitiesReducer } from 'reducers/checklist-assignment/checklist-assignment.reducer';
import { checklistPermitEntitiesReducer } from 'reducers/checklist-permit/checklist-permit.reducer';
import { checklistRevisionEntitiesReducer } from 'reducers/checklist-revision/checklist-revision.reducer';
import { checklistEntitiesReducer } from 'reducers/checklist/checklist.reducer';
import { folderPermitEntitiesReducer } from 'reducers/folder-permit/folder-permit.reducer';
import { folderEntitiesReducer } from 'reducers/folder/folder.reducer';
import { groupMembershipEntitiesReducer } from 'reducers/group-membership/group-membership.reducer';
import { groupEntitiesReducer } from 'reducers/group/group.reducer';
import { organizationMembershipEntitiesReducer } from 'reducers/organization-membership/organization-membership.reducer';
import { organizationStatsEntitiesReducer } from 'reducers/organization-stats/organization-stats.reducers';
import { organizationEntitiesReducer } from 'reducers/organization/organization.reducer';
import { taskStatsReducer } from 'reducers/task-stats/task-stats.reducers';
import { taskTemplateEntitiesReducer } from 'reducers/task-template/task-template.reducers';
import { taskEntitiesReducer } from 'reducers/task/task.reducer';
import { templatePermitEntitiesReducer } from 'reducers/template-permit/template-permit.reducer';
import { templateRevisionEntitiesReducer } from 'reducers/template-revision/template-revision.reducer';
import { templateEntitiesReducer } from 'reducers/template/template.reducer';
import { userEntitiesReducer } from 'reducers/user/user.reducer';
import { AnyAction, combineReducers } from 'redux';
import { applyOptimisticUpdate, isOptimisticAction } from '../optimistic';
import { organizationDomainEntitiesReducer } from '../organization-domain/organization-domain.reducer';
import { planFeatureSetEntitiesReducer } from '../plan-feature-set/plan-feature-set.reducers';
import { planEntitiesReducer } from '../plan/plan.reducers';
import { EntitiesState } from '../types';
import { checklistWidgetEntitiesReducer } from 'components/widgets/store/checklist-widget.reducer';

const defaultEntitiesReducer = combineReducers<EntitiesState>({
  approval: approvalReducer,
  approvalRuleSubject: approvalRuleSubjectReducer,
  attachment: (state: EntityMap<Attachment> = {}) => state,
  authConnection: authConnectionReducers,
  checklistAssignments: checklistAssignmentEntitiesReducer,
  checklistPermits: checklistPermitEntitiesReducer,
  checklistRevision: checklistRevisionEntitiesReducer,
  checklistTaskAssignments: checklistTaskAssignmentEntitiesReducer,
  checklistWidget: checklistWidgetEntitiesReducer,
  checklists: checklistEntitiesReducer,
  comment: (state: EntityMap<Comment> = {}) => state,
  conditionalRules: (state: EntityMap<ConditionalRules> = {}, _action: AnyAction) => state,
  dynamicDueDate: dynamicDueDatesReducer,
  folderPermits: folderPermitEntitiesReducer,
  folders: folderEntitiesReducer,
  formFieldValue: formFieldValueEntitiesReducer,
  groupMemberships: groupMembershipEntitiesReducer,
  groups: groupEntitiesReducer,
  organizationDomain: organizationDomainEntitiesReducer,
  organizationMemberships: organizationMembershipEntitiesReducer,
  organizationStats: organizationStatsEntitiesReducer,
  organizations: organizationEntitiesReducer,
  planFeatureSet: planFeatureSetEntitiesReducer,
  plans: planEntitiesReducer,
  roleAssignmentRules: roleAssignmentRuleEntitiesReducer,
  task: taskEntitiesReducer,
  taskPermissionRules: taskPermissionRuleReducer,
  taskStats: taskStatsReducer,
  taskTemplatePermits: taskTemplatePermitEntitiesReducer,
  taskTemplates: taskTemplateEntitiesReducer,
  templatePermits: templatePermitEntitiesReducer,
  templateRevision: templateRevisionEntitiesReducer,
  templateTaskAssignments: templateTaskAssignmentEntitiesReducer,
  templates: templateEntitiesReducer,
  users: userEntitiesReducer,
  webhooks: webhooksEntitiesReducer,
  widgets: widgetEntitiesReducer,
});

export const entitiesReducer = (state: EntitiesState, action: AnyAction) => {
  if (isOptimisticAction(action)) {
    switch (action.stage) {
      case RequestStage.Request:
        return defaultEntitiesReducer(applyOptimisticUpdate(state, action.meta.optimistic.commit), action);
      case RequestStage.Failure:
        return defaultEntitiesReducer(applyOptimisticUpdate(state, action.meta.optimistic.rollback), action);
      case RequestStage.Success:
        if (action.meta.optimistic.rollbackOnSuccess) {
          return defaultEntitiesReducer(applyOptimisticUpdate(state, action.meta.optimistic.rollback), action);
        }
        return defaultEntitiesReducer(state, action);
    }
  }
  return defaultEntitiesReducer(state, action);
};
