import * as React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { TemplateMenuContext } from '../template-menu';
import { ExportConditionalLogicButton } from '.';
import { Button, Icon } from 'components/design/next';

interface ExportConditionalLogicWrapperProps {
  templateId: Muid;
}
export const ExportConditionalLogicWrapper: React.FC<React.PropsWithChildren<ExportConditionalLogicWrapperProps>> = ({
  templateId,
}) => {
  return (
    <TemplateMenuContext.Provider
      value={{ templateId, setCloseOnBlur: (close: boolean) => close, closeOnBlur: true, view: 'show' }}
    >
      <ExportConditionalLogicButton>
        <Button
          type="button"
          variant="ghost"
          background="none"
          w="full"
          fontWeight="normal"
          justifyContent="flex-start"
          color="#555" // Color nonexistent in our current theme. Added to fit the menu styles
          paddingLeft="9px" // One-off rule to fit the current Angular menu
          _hover={{ background: '#f5f5f5', textDecoration: 'none' }} // Color nonexistent in our current theme. Added to fit the menu styles
          _focus={{ background: '#f5f5f5', textDecoration: 'none', border: 'none' }} // Color nonexistent in our current theme. Added to fit the menu styles
        >
          <Icon icon="print" size="4" variant="far" color="gray.500" />
          &nbsp;Print Conditional Logic
        </Button>
      </ExportConditionalLogicButton>
    </TemplateMenuContext.Provider>
  );
};
