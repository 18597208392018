import * as React from 'react';
import { FormControl, FormErrorMessage, FormLabel, Link, Text } from '@chakra-ui/react';
import { WorkflowNameSelectorField } from './workflow-name-select-field';
import { WorkflowOption } from './predefined-workflows-options';

type WorkflowNameFieldProps = {
  hasModerationError: boolean;
  maxLength: number;
  value: string;
  description: string;
  onSelect: (option: WorkflowOption) => void;
  isLoading: boolean;
};

export const WorkflowNameField: React.FC<React.PropsWithChildren<WorkflowNameFieldProps>> = ({
  hasModerationError,
  maxLength,
  value,
  description,
  onSelect,
  isLoading,
}) => {
  return (
    <FormControl isRequired isInvalid={hasModerationError || value.length >= maxLength}>
      <FormLabel>Workflow Name</FormLabel>

      <Text mb="2" variant="-1" color="gray.500">
        Specific workflow names work best.{' '}
        <Link href={AI_GENERATED_WORKFLOW_LINK} target="blank">
          Learn more
        </Link>
        .
      </Text>

      <WorkflowNameSelectorField {...{ value, description, onSelect, isDisabled: isLoading }} />
      {hasModerationError && (
        <FormErrorMessage>ChatGPT moderators rejected that workflow name, try another one</FormErrorMessage>
      )}

      {value.length > maxLength && (
        <FormErrorMessage>Workflow name can&apos;t be longer than {maxLength} characters.</FormErrorMessage>
      )}
    </FormControl>
  );
};

const AI_GENERATED_WORKFLOW_LINK = 'https://www.process.st/help/docs/ai-workflow-generator/';
