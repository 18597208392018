import { Template, TemplateStatus, TemplateType } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetAllTemplatesQueryParams = {
  organizationId: Template['organization']['id'];
  templateStatus: TemplateStatus;
  schedule?: boolean;
  templateType?: TemplateType;
};

export type GetAllTemplatesQueryResponse = Template[];

export const GetAllTemplatesQuery = {
  key: ['all', 'templates'],
  getKey: (params: Partial<GetAllTemplatesQueryParams>): QueryKey => [...GetAllTemplatesQuery.key, params],
  queryFn: ({ organizationId, ...params }: GetAllTemplatesQueryParams) =>
    axiosService
      .getAxios()
      .get<GetAllTemplatesQueryResponse>(`/1/organizations/${organizationId}/templates`, {
        params,
      })
      .then(res => res.data),
};

export const useGetAllTemplatesQuery = <Select = GetAllTemplatesQueryResponse>(
  params: GetAllTemplatesQueryParams,
  options: UseQueryOptions<GetAllTemplatesQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetAllTemplatesQuery.getKey(params), () => GetAllTemplatesQuery.queryFn(params), options);
};
