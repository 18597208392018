import angular from 'angular';
import { dayjs as moment } from '@process-street/subgrade/util';
import { PopBoxConstants } from '../pop-box/pop-box-constants';
import templateUrl from './datepicker.component.html';
import './datepicker.scss';
import { DateContextUtils } from '@process-street/subgrade/core/date-context';

angular
  .module('frontStreetApp.directives')
  .controller('DatepickerBoxCtrl', function ($scope, $timeout, $window) {
    let box;

    /**
     * Date form fields can be used to calculate dynamic due dates
     * so when a user hasn't set a time we need a default time
     *
     * TODO - Decouple dynamic due date logic from datepicker
     *  https://processstreet.atlassian.net/browse/PS-8542
     */
    const DefaultTime = { hour: 8, minute: 0, second: 0, millisecond: 0 };

    /**
     * The actual timezone we render depends on whether the date picker has a
     * time or is just a date
     */
    function configureActualTimeZones() {
      $scope.actualTimeZone = $scope.timeHidden
        ? DateContextUtils.getOrganizationTimeZone($scope.dateContext)
        : $scope.userTimeZone;
    }

    function configureTimeHidden(timeHidden, timeRequired) {
      $scope.timeHidden = timeHidden === undefined ? timeRequired === false : timeHidden;
    }

    /**
     * Converts the date to a moment with the correct timezone.
     *
     * If the date is undefined it will default to tomorrow 8am in the organization TZ.
     */
    function optionalDateToMoment(date) {
      if (date) {
        return moment(date).tz($scope.actualTimeZone);
      } else {
        return moment().tz($scope.actualTimeZone).set(DefaultTime).add(1, 'days');
      }
    }

    /**
     * Initialize a date for the datepicker and also two date/time strings for the date and time text fields.
     */
    function configureDate(date) {
      const m = optionalDateToMoment(date);

      // Not sure what this is for, but I'm afraid to remove it.
      $scope.date = date ? new Date(date) : undefined;

      // This is the date actually fed into the datepicker, the time is irrelevant.
      // This JS Date is always in the UTC timezone, so we don't have to worry about shifting it between the users
      // timezone and the organization timezone when the user adds/removes the time component.
      $scope.datepickerDate = new Date(Date.UTC(m.year(), m.month(), m.date(), 12, 0, 0));
      // This is date and time displayed in the text boxes.
      $scope.dateTime = {
        datePart: m.format('MM/DD/YYYY'),
        timePart: m.format('h:mmA'),
      };
    }

    $scope.$on('init', (__event, values) => {
      ({ box } = values);
      $scope.dateNotSet = !values.date;
      $scope.ignoreOutsideClick = values.ignoreOutsideClick;
      $scope.overridingDueDate = false;
      $scope.dateContext = values.dateContext;
      $scope.timeRequired = values.timeRequired === undefined ? true : values.timeRequired;
      $scope.userTimeZone = values.timeZone;
      $scope.overrideVisible = values.overrideVisible;
      $scope.overrideDescription = values.overrideDescription;
      $scope.subject = values.subject;

      configureTimeHidden(values.timeHidden, values.timeRequired);
      configureActualTimeZones();
      configureDate(values.date);
    });

    $scope.$on('changes', (__event, changes) => {
      if (changes.dateContext) {
        $scope.dateContext = changes.dateContext.currentValue;
        configureActualTimeZones();
        configureDate($scope.date);
      }

      if (changes.timeZone) {
        $scope.userTimeZone = changes.timeZone.currentValue;
        configureActualTimeZones();
        configureDate($scope.date);
      }

      if (changes.date) {
        configureDate(changes.date.currentValue);
        $scope.dateNotSet = !changes.date.currentValue;
      }

      if (changes.timeHidden) {
        configureTimeHidden(changes.timeHidden.currentValue, $scope.timeRequired);
        configureActualTimeZones();
        configureDate($scope.date);
      }

      if (changes.overrideVisible) {
        $scope.overrideVisible = changes.overrideVisible.currentValue;
        $scope.overridingDueDate = false;
      }

      if (changes.overrideDescription) {
        $scope.overrideDescription = changes.overrideDescription.currentValue;
      }

      if (changes.hideMethod && changes.hideMethod === PopBoxConstants.HideMethod.OUTSIDE_CLICK) {
        if (!$scope.ignoreOutsideClick) {
          $scope.saveDate($scope.dateTimeSelected);
        }
      }
    });

    function createDraftState() {
      // clone controller dates to UI "draft" values
      $scope.datepickerDateSelected = $scope.datepickerDate;
      $scope.dateTimeSelected = { ...$scope.dateTime };
    }

    $scope.$on('open', () => {
      createDraftState();
      box.visible = true;
    });

    $scope.datepickerOptions = {
      showWeeks: false,
    };

    this.override = function () {
      $scope.overrideVisible = false;
      $scope.overridingDueDate = true;

      $timeout(() => {
        // this emits the event, on which pop box is registered, so it can re-position itself
        angular.element($window).resize();
      });
    };

    // when user selects a date in date picker, keep date display in sync
    $scope.$watch('datepickerDateSelected', date => {
      if (date) {
        $scope.dateTimeSelected.datePart = moment.tz(date, 'UTC').format('MM/DD/YYYY');
      }
    });

    $scope.setTodayDate = () => {
      configureDate(moment().tz($scope.actualTimeZone).set(DefaultTime));
      createDraftState();
    };

    $scope.saveDate = dateTime => {
      // write UI draft values back to controller
      $scope.dateTime = { ...dateTime };
      $scope.datepickerDate = $scope.datepickerDateSelected;

      const { datePart, timePart } = dateTime;
      $scope.invalidDate = false;
      $scope.invalidTime = false;

      if (!moment(datePart, 'MM/DD/YYYY').isValid()) {
        $scope.invalidDate = true;
        return;
      }

      const loweredTimePart = timePart.toLowerCase();
      let timeFormat;
      if (loweredTimePart.includes('am') || loweredTimePart.includes('pm')) {
        timeFormat = 'h:mma';
      } else {
        timeFormat = 'HH:mm';
      }

      if (!moment(loweredTimePart, timeFormat).isValid()) {
        $scope.invalidTime = true;
        return;
      }

      const dateStr = `${datePart} ${loweredTimePart}`;
      const format = `MM/DD/YYYY ${timeFormat}`;
      const dateTimeMoment = moment.tz(dateStr, format, $scope.actualTimeZone);

      $scope.$emit('save', dateTimeMoment.valueOf(), $scope.timeHidden);
      box.visible = false;
    };

    $scope.removeDate = function () {
      $scope.$emit('remove');
      box.visible = false;
    };

    $scope.addTime = () => {
      $scope.timeHidden = false;
      configureActualTimeZones();
    };

    $scope.removeTime = () => {
      $scope.timeHidden = true;
      $scope.dateTime.timePart = moment().set(DefaultTime).format('h:mmA');
      configureActualTimeZones();
    };

    $scope.stopClick = function (event) {
      event.stopPropagation();
    };

    $scope.stopEventPropagation = function (event) {
      event.stopPropagation();
    };
  })
  .component('psDatepicker', {
    transclude: true,
    bindings: {
      title: '<',
      subject: '<',
      date: '<',
      timeRequired: '<',
      timeHidden: '<',
      timeZone: '<', // to be removed, use dateContext instead
      dateContext: '<',
      disabled: '<',
      ignoreOutsideClick: '<',
      overrideVisible: '<',
      overrideDescription: '<',
      onSave: '&',
      onRemove: '&',
    },
    templateUrl,
    controller($scope) {
      const ctrl = this;

      // This is used to control the box visibility
      ctrl.box = { visible: false };

      // This component communicates with the controller in the pop box via events in the scope

      ctrl.init = function () {
        $scope.$broadcast('init', {
          box: ctrl.box,
          date: ctrl.date,
          dateContext: ctrl.dateContext,
          timeZone: ctrl.timeZone,
          timeRequired: ctrl.timeRequired,
          timeHidden: ctrl.timeHidden,
          ignoreOutsideClick: ctrl.ignoreOutsideClick,
          overrideVisible: ctrl.overrideVisible,
          overrideDescription: ctrl.overrideDescription,
          subject: ctrl.subject ?? 'task',
        });
      };

      ctrl.$onChanges = function (changes) {
        if (changes.title) {
          ctrl.title = changes.title.currentValue || 'Set date';
        }

        $scope.$broadcast('changes', changes);
      };

      ctrl.show = function () {
        $scope.$broadcast('open');
      };

      ctrl.hide = function (hideMethod) {
        ctrl.box.visible = false;

        $scope.$broadcast('changes', {
          hideMethod,
        });
      };

      $scope.$on('save', (event, date, timeHidden) => {
        // Don't let the event escape the component
        event.stopPropagation();

        ctrl.onSave({ date, timeHidden });
      });

      $scope.$on('remove', event => {
        // Don't let the event escape the component
        event.stopPropagation();

        ctrl.onRemove({});
      });
    },
  });
