import angular from 'angular';
import { IntercomService } from 'services/interop/intercom-service';
import './add-multiple.scss';
import { trace } from 'components/trace';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular.module('frontStreetApp.controllers').controller('ChecklistAddMultipleCtrl', function ($q, $scope, $state) {
  const ctrl = this;
  const logger = trace({ name: 'ChecklistAddMultipleCtrl' });
  logger.info('loading ctrl');

  ctrl.$onInit = () => {
    const { templateId } = $state.params;
    $scope.templateId = templateId;
  };

  $scope.showSupport = function () {
    AnalyticsService.trackEvent('support link clicked', { location: 'checklist add multiple' });
    logger.info('support link clicked');
    IntercomService.show();
  };
});
