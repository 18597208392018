import angular from 'angular';
import { generateLocation, generateTo, getTabByName, getTabIndexByName } from 'services/tab-service';
import templateUrl from './activity.component.html';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { tmplTypeName } from '@process-street/subgrade/process';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'TemplateManageCtrl',
    function ($scope, $state, SessionService, Subject, UserService, TemplateService, ToastService) {
      const ctrl = this;
      const logger = trace({ name: 'TemplateManageCtrl' });
      logger.info('loading ctrl');

      const activityTab = {
        name: 'activity',
        heading: 'Activity',
        templateUrl,
      };

      ctrl.$onInit = () => {
        initializePermissions();

        $scope.tabs = [activityTab];
        $scope.activeIndex = getTabIndexByName($scope.tabs, $state.params.tab) || 0;

        initializeTemplate().then(
          () => {
            initializeTabs($scope.tabs);
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading the workflow`,
              description: DefaultErrorMessages.notFoundErrorDescription,
            });

            $state.go('dashboard');
          },
        );
      };

      $scope.$on('$stateChangeSuccess', (__event, __toState, toParams) => {
        $scope.activeIndex = getTabIndexByName($scope.tabs, toParams.tab) || 0;
      });

      $scope.getTypeNameTemplateType = template => {
        return template ? tmplTypeName(template) : '';
      };

      $scope.navigateToTemplate = template => {
        if (TemplateService.isPageByTemplateType(template.templateType)) {
          $state.go('pageView', { id: template.id });
        } else if (TemplateService.isFormByTemplateType(template.templateType)) {
          $state.go('form', { id: template.id });
        } else {
          $state.go('templateDashboard', { id: template.id });
        }
      };

      // Permissions

      function initializePermissions() {
        const sessionUser = UserService.getCurrentUser();
        const selectedOrganizationId = SessionService.getSelectedOrganizationId();
        $scope.subject = new Subject(sessionUser.id, selectedOrganizationId);
      }

      // Initialization

      function initializeTabs(tabs) {
        tabs.forEach(tab => {
          tab.select = function () {
            this.selectedOnce = true;

            const to = generateTo($state.current.name);
            const location = generateLocation($state.current.name);
            $state.go(to, { tab: tab.name, index: null }, { location });
          };
        });

        const selectedTab = getTabByName($scope.tabs, $state.params.tab) || tabs[0];
        selectedTab.select();
      }

      function initializeTemplate() {
        return TemplateService.get($state.params.id).then(template => {
          $scope.template = template;
        });
      }
    },
  );
