import { HStack, Skeleton } from 'components/design/next';
import * as React from 'react';

export type MenuItemSkeletonProps = {
  isLoaded?: boolean;
};

export const MenuItemSkeleton: React.FC<React.PropsWithChildren<MenuItemSkeletonProps>> = ({ isLoaded = true }) => {
  return (
    <HStack w="full" px="4" py="2" spacing="2">
      <Skeleton
        minW="5"
        h="5"
        isLoaded={isLoaded}
        flexBasis="20px"
        startColor="brand.100"
        endColor="brand.300"
        bgColor="brand.100"
        borderWidth="1px"
        borderColor="brand.200"
        borderStyle="solid"
        borderRadius="4px"
        aria-label={!isLoaded ? 'Loading' : undefined}
      />

      <Skeleton
        startColor="brand.100"
        endColor="brand.300"
        w="full"
        h="2"
        bgColor="brand.100"
        borderRadius="4px"
        isLoaded={isLoaded}
        aria-label={!isLoaded ? 'Loading' : undefined}
      />
    </HStack>
  );
};
