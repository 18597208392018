const desplice = <T>(items: T[], item: T, key?: keyof T): void => {
  let index;
  if (key) {
    index = items.findIndex(i => i && item && i[key] === item[key]);
  } else {
    index = items.indexOf(item);
  }
  if (index >= 0) {
    items.splice(index, 1);
  }
};

const pushIfMissing = <T>(items: T[], item: T, key?: keyof T): void => {
  let index;
  if (key) {
    index = items.findIndex(i => i && item && i[key] === item[key]);
  } else {
    index = items.indexOf(item);
  }
  if (index === -1) {
    items.push(item);
  }
};

/**
 * Returns true if the array includes the item.
 *
 * @param {Array} array
 * @param {*} item
 * @param {string} [key]
 */
const includes = <T>(array: T[], item: T, key?: keyof T): boolean => {
  return array.some(i => (key ? i && item && i[key] === item[key] : i === item));
};

/**
 * Partition an array into two arrays, the first having the values where `p` is true.
 *
 * @param {Array} array
 * @param p
 * @returns {Array} An array with two arrays, the first having the values where `p` is true.
 */
const partition = <T>(array: T[], p: (t: T) => boolean): [T[], T[]] => {
  return array.reduce(
    (partitions, x) => {
      partitions[+!p(x)].push(x);
      return partitions;
    },
    [[], []] as [T[], T[]],
  );
};

/**
 * Partitions elements into fixed size columns.
 *
 * @param {Array} array the array to group
 * @param {Number} size the number of elements per group
 * @returns {Array} An array of size `size`, except the last will be less than `size` if the elements don't
 *                  divide evenly.
 */
const group = <T>(array: T[], size: number): T[][] | undefined => {
  if (size) {
    const groups: T[][] = [];
    let currentGroup: T[] | undefined;
    for (let i = 0; i < array.length; i += 1) {
      if (i % size === 0) {
        currentGroup = [];
        groups.push(currentGroup);
      }
      currentGroup?.push(array[i]);
    }
    return groups;
  } else {
    // Technically should be an infinite set of zero-length groups but who has time for that?
    return undefined;
  }
};

const numberComparer = (a: number, b: number): number => {
  return a - b;
};

/**
 * Moves an element from `oldIndex` to `newIndex`, pushing all the items over.
 * If the index is larger than the array, it'll extend the array, filling the gaps with `undefined`.
 *
 * Adapted from:
 * https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
 *
 * @param array
 * @param oldIndex
 * @param newIndex
 */
const move = <T>(array: (T | undefined)[], oldIndex: number, newIndex: number): void => {
  if (newIndex >= array.length) {
    let k = newIndex - array.length;
    while (k-- + 1) {
      array.push(undefined);
    }
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
};

const movePure = <T>(array: T[], oldIndex: number, newIndex: number): T[] => {
  const adjustedNewIndex = newIndex >= array.length ? array.length - 1 : newIndex < 0 ? 0 : newIndex;
  if (adjustedNewIndex === oldIndex) {
    return array;
  }
  const copy = [...array];
  copy.splice(adjustedNewIndex, 0, copy.splice(oldIndex, 1)[0]);
  return copy;
};

export const ArrayService = {
  desplice,
  pushIfMissing,
  includes,
  partition,
  group,
  numberComparer,
  move,
  movePure,
};
