import angular from 'angular';

import { FileUploadApiImpl } from 'components/file-upload/services/file-upload.api';
import { FileUploadResourceFactory } from 'components/file-upload/services/file-upload.resource';
import { FileUploadServiceImpl } from 'components/file-upload/services/file-upload.service.impl';
import { FileUploadActions } from 'components/file-upload/store/file-upload.actions';

export const FileUploadModule = angular
  .module('fileUpload', [])
  .service('FileUploadService', FileUploadServiceImpl)
  .service('FileUploadActions', FileUploadActions)
  .service('FileUploadApi', ['FileUploadResource', FileUploadApiImpl])
  .factory('FileUploadResource', ['ResourceFactory', FileUploadResourceFactory]).name;
