import React from 'react';
import { PermissionsStats } from 'components/permissions/models/permissions-stats';
import { ListIcon, ListItem, Text } from 'components/design/next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faGlobe, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { ICON_LABELS } from 'components/permissions/services/permissions-stats.utils';

interface PermissionsDescriptionListProps {
  permissionsStats: PermissionsStats;
  level: 'direct' | 'inherited';
}

export const PermissionsDescriptionList: React.FC<React.PropsWithChildren<PermissionsDescriptionListProps>> = ({
  permissionsStats,
  level,
}) => {
  const allMembersGroupsCount =
    level === 'direct' ? permissionsStats.allMembersGroupsCount : permissionsStats.inheritedAllMembersGroupsCount;
  const membersCount = level === 'direct' ? permissionsStats.membersCount : permissionsStats.inheritedMembersCount;
  const groupsCount = level === 'direct' ? permissionsStats.groupsCount : permissionsStats.inheritedGroupsCount;
  const guestsCount = level === 'direct' ? permissionsStats.guestsCount : permissionsStats.inheritedGuestsCount;

  const items = [];

  if (allMembersGroupsCount > 0) {
    items.push(
      <Text as={'span'} fontSize={'xs'}>
        <Text as={'span'} display={'inline-block'} w={6} textAlign={'center'}>
          <ListIcon as={FontAwesomeIcon} icon={faBuilding} aria-label={ICON_LABELS['building']} />
        </Text>
        All Organization
      </Text>,
    );
  } else {
    const descriptionParts = [];
    if (membersCount > 0 || guestsCount > 0 || groupsCount > 0) {
      if (groupsCount > 0) {
        descriptionParts.push(`${groupsCount} group${groupsCount > 1 ? 's' : ''}`);
      }
      const membersAndGuestsCount = membersCount + guestsCount;
      if (membersAndGuestsCount > 0) {
        descriptionParts.push(
          `${membersAndGuestsCount} member${membersAndGuestsCount > 1 ? 's' : ''} & guest${
            membersAndGuestsCount > 1 ? 's' : ''
          }`,
        );
      }
    }

    items.push(
      <Text as={'span'} fontSize={'xs'}>
        <Text as={'span'} display={'inline-block'} w={6} textAlign={'center'}>
          <ListIcon as={FontAwesomeIcon} icon={faUsers} aria-label={ICON_LABELS['users']} />
        </Text>
        {`${descriptionParts.join(', ')}`}
      </Text>,
    );
  }

  if (level === 'direct' && permissionsStats.templateShared) {
    items.push(
      <Text as={'span'} fontSize={'xs'}>
        <Text as={'span'} display={'inline-block'} w={6} textAlign={'center'}>
          <ListIcon as={FontAwesomeIcon} icon={faGlobe} aria-label={ICON_LABELS['globe']} />
        </Text>
        Anyone with the link
      </Text>,
    );
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      {items.map((item, index) => (
        <ListItem key={index} fontSize="sm" fontWeight="normal" color="currentColor" lineHeight="base">
          {item}
        </ListItem>
      ))}
    </>
  );
};
