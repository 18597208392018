import * as React from 'react';
import { useQueryClient } from 'react-query';
import { keysAreEqual, makeMutationListener } from 'services/react-query';
import { UpdateFormFieldValueMutation } from 'features/widgets/query-builder';

export function useAreFormFieldsUpdating() {
  const [areFormFieldValuesUpdating, setAreFormFieldValuesUpdating] = React.useState(false);
  const queryClient = useQueryClient();
  const mutationCache = queryClient.getMutationCache();
  React.useEffect(() => {
    return mutationCache.subscribe(
      makeMutationListener({
        keys: [UpdateFormFieldValueMutation.key],
        onLoading: mutation => {
          if (keysAreEqual(mutation.options.mutationKey!, UpdateFormFieldValueMutation.key)) {
            setAreFormFieldValuesUpdating(true);
          }
        },
        onSuccess: mutation => {
          if (keysAreEqual(mutation.options.mutationKey!, UpdateFormFieldValueMutation.key)) {
            setAreFormFieldValuesUpdating(false);
          }
        },
        onError: () => {
          setAreFormFieldValuesUpdating(false);
        },
      }),
    );
  }, [mutationCache]);

  return areFormFieldValuesUpdating;
}
