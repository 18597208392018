import { axiosService } from 'services/axios-service';
import { Folder, Template } from '@process-street/subgrade/process';
import { FolderPermissionMap, Muid, Organization, TemplatePermissionMap } from '@process-street/subgrade/core';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { QueryKey } from 'react-query';
import { AxiosError } from 'axios';

export type GetConsolidatedTemplatePermissionsResult = {
  templateId: Muid;
  userId: Muid;
  permissionMap: TemplatePermissionMap;
};

export type GetConsolidatedTemplatePermissionsParams = Template['id'] | undefined;

const getConsolidatedTemplatePermissions = (
  templateId: GetConsolidatedTemplatePermissionsParams,
): Promise<GetConsolidatedTemplatePermissionsResult> =>
  axiosService
    .getAxios()
    .get(`/1/templates/${templateId}/permissions/consolidated`)
    .then(res => res.data);

export const GetConsolidatedTemplatePermissions = {
  key: ['template-permissions', 'consolidated'],
  getKey(templateId: GetConsolidatedTemplatePermissionsParams): QueryKey {
    return [...GetConsolidatedTemplatePermissions.key, templateId];
  },
  queryFn: getConsolidatedTemplatePermissions,
};

export const useGetConsolidatedTemplatePermissionsQuery = <Select = GetConsolidatedTemplatePermissionsResult>(
  templateId: GetConsolidatedTemplatePermissionsParams,
  options: UseQueryOptions<GetConsolidatedTemplatePermissionsResult, AxiosError, Select> = {},
) => {
  const queryClient = useQueryClient();

  return useQuery<GetConsolidatedTemplatePermissionsResult, AxiosError, Select>(
    GetConsolidatedTemplatePermissions.getKey(templateId),
    () => GetConsolidatedTemplatePermissions.queryFn(templateId),
    {
      initialData: () => {
        return queryClient
          .getQueryData<GetAllConsolidatedTemplatePermissionsResponse>(GetAllConsolidatedTemplatePermissions.getKey())
          ?.find(fp => fp.templateId === templateId);
      },
      initialDataUpdatedAt: () => {
        return queryClient.getQueryState(GetAllConsolidatedTemplatePermissions.getKey())?.dataUpdatedAt;
      },
      enabled: Boolean(templateId) && options.enabled !== false,
      ...options,
    },
  );
};

export type GetAllConsolidatedTemplatePermissionsResponse = GetConsolidatedTemplatePermissionsResult[];

const getAllConsolidatedTemplatePermissions = (): Promise<GetAllConsolidatedTemplatePermissionsResponse> =>
  axiosService
    .getAxios()
    .get(`/1/templates/permissions/consolidated`)
    .then(res => res.data);

export const GetAllConsolidatedTemplatePermissions = {
  key: ['template-permissions', 'consolidated'],
  getKey(): QueryKey {
    return [...GetConsolidatedTemplatePermissions.key];
  },
  queryFn: getAllConsolidatedTemplatePermissions,
};

export const useGetAllConsolidatedTemplatePermissionsQuery = <Select = GetAllConsolidatedTemplatePermissionsResponse>(
  options: UseQueryOptions<GetAllConsolidatedTemplatePermissionsResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetAllConsolidatedTemplatePermissions.getKey(),
    () => GetAllConsolidatedTemplatePermissions.queryFn(),
    {
      ...options,
    },
  );
};

export type GetConsolidatedFolderPermissionsResult = {
  folderId: Muid;
  userId: Muid;
  permissionMap: FolderPermissionMap;
};

const getConsolidatedFolderPermissions = (folderId: Folder['id']): Promise<GetConsolidatedFolderPermissionsResult> =>
  axiosService
    .getAxios()
    .get(`/1/folders/${folderId}/permissions/consolidated`)
    .then(res => res.data);

type GetConsolidatedFolderPermissionsParams = Folder['id'];

export const GetConsolidatedFolderPermissions = {
  key: ['folder-permissions', 'consolidated'],
  getKey(folderId: GetConsolidatedFolderPermissionsParams): QueryKey {
    return [...GetConsolidatedFolderPermissions.key, folderId];
  },
  queryFn: getConsolidatedFolderPermissions,
};

export const useGetConsolidatedFolderPermissionsQuery = (
  folderId: GetConsolidatedFolderPermissionsParams,
  options: UseQueryOptions<GetConsolidatedFolderPermissionsResult, Error> = {},
) => {
  const queryClient = useQueryClient();

  return useQuery<GetConsolidatedFolderPermissionsResult, Error>(
    GetConsolidatedFolderPermissions.getKey(folderId),
    () => GetConsolidatedFolderPermissions.queryFn(folderId),
    {
      initialData: () => {
        return queryClient
          .getQueryData<GetAllConsolidatedFolderPermissionsResponse>(GetAllConsolidatedFolderPermissions.getKey())
          ?.find(fp => fp.folderId === folderId);
      },
      initialDataUpdatedAt: () => {
        return queryClient.getQueryState(GetAllConsolidatedFolderPermissions.getKey())?.dataUpdatedAt;
      },
      ...options,
    },
  );
};

export type GetAllConsolidatedFolderPermissionsResponse = GetConsolidatedFolderPermissionsResult[];

const getAllConsolidatedFolderPermissions = (): Promise<GetAllConsolidatedFolderPermissionsResponse> =>
  axiosService
    .getAxios()
    .get(`/1/folders/permissions/consolidated`)
    .then(res => res.data);

export const GetAllConsolidatedFolderPermissions = {
  key: ['folder-permissions', 'consolidated'],
  getKey(): QueryKey {
    return [...GetConsolidatedFolderPermissions.key];
  },
  queryFn: getAllConsolidatedFolderPermissions,
};

export const useGetAllConsolidatedFolderPermissionsQuery = <Select = GetAllConsolidatedFolderPermissionsResponse>(
  options: UseQueryOptions<GetAllConsolidatedFolderPermissionsResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetAllConsolidatedFolderPermissions.getKey(), () => GetAllConsolidatedFolderPermissions.queryFn(), {
    ...options,
  });
};

export type GlobalPermissionMap = Record<keyof typeof DefaultGlobalPermissionMap, boolean>;
export const DefaultGlobalPermissionMap = {
  tagCreate: false,
  organizationMembershipLevelUpdate: false,
  membersInvite: true,
};

export type GetConsolidatedGlobalPermissionsResult = {
  organizationId: Muid;
  userId: Muid;
  permissionMap: GlobalPermissionMap;
};

const getConsolidatedGlobalPermissions = (
  organizationId: Organization['id'],
): Promise<GetConsolidatedGlobalPermissionsResult> =>
  axiosService
    .getAxios()
    .get(`/1/organization/${organizationId}/permissions/consolidated`)
    .then(res => res.data);

type GetConsolidatedGlobalPermissionsParams = Organization['id'];

export const GetConsolidatedGlobalPermissions = {
  key: ['organization', 'permissions', 'consolidated'],
  getKey(organizationId: GetConsolidatedGlobalPermissionsParams): QueryKey {
    return [...GetConsolidatedGlobalPermissions.key, organizationId];
  },
  queryFn: getConsolidatedGlobalPermissions,
};

export const useGetConsolidatedGlobalPermissionsQuery = (
  organizationId: GetConsolidatedGlobalPermissionsParams,
  options: UseQueryOptions<GetConsolidatedGlobalPermissionsResult, Error> = {},
) => {
  return useQuery<GetConsolidatedGlobalPermissionsResult, Error>(
    GetConsolidatedGlobalPermissions.getKey(organizationId),
    () => GetConsolidatedGlobalPermissions.queryFn(organizationId),
    options,
  );
};

type GetAllConsolidatedChecklistPermissionsResponse = {
  checklistId: Muid;
  userId: Muid;
  permissionMap: {
    checklistArchive: boolean;
    checklistDelete: boolean;
    checklistRead: boolean;
    checklistExport: boolean;
  };
}[];

type GetAllConsolidatedChecklistPermissionsParams = {
  checklistIds: Muid[];
};

export const GetAllConsolidatedChecklistPermissions = {
  key: ['checklists', 'permissions'],
  getKey(params: GetAllConsolidatedChecklistPermissionsParams): QueryKey {
    return [...GetAllConsolidatedFolderPermissions.key, params];
  },
  queryFn: (
    params: GetAllConsolidatedChecklistPermissionsParams,
  ): Promise<GetAllConsolidatedChecklistPermissionsResponse> =>
    axiosService
      .getAxios()
      .get<GetAllConsolidatedChecklistPermissionsResponse>(`/1/checklists/permissions/consolidated`, {
        params: { checklistIds: params?.checklistIds?.join(',') },
      })
      .then(res => res.data),
};

export const useGetAllConsolidatedChecklistPermissionsQuery = (
  params: GetAllConsolidatedChecklistPermissionsParams,
  options?: UseQueryOptions<GetAllConsolidatedChecklistPermissionsResponse, AxiosError>,
) => {
  return useQuery(
    GetAllConsolidatedChecklistPermissions.getKey(params),
    () => GetAllConsolidatedChecklistPermissions.queryFn(params),
    { ...options },
  );
};
