import * as React from 'react';
import { Checklist, TaskStatus } from '@process-street/subgrade/process';
import { GetOneOffTasksByChecklistQuery } from 'features/one-off-tasks/query-builder';
import { Checkbox, Divider, HStack, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { SessionSelector } from 'reducers/session/session.selectors';
import { PrintOneOffTaskAudit } from './print-one-off-task-audit';
import { useSelector } from 'react-redux';

export type PrintOneOffTasksSummaryProps = {
  checklist: Checklist;
};
export const PrintOneOffTasksSummary = ({ checklist }: PrintOneOffTasksSummaryProps) => {
  const currentOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const oneOffTasksQuery = GetOneOffTasksByChecklistQuery.useQuery(
    {
      checklistId: checklist?.id,
    },
    { enabled: !!checklist?.id },
  );

  const usersMapQuery = useGetAllOrganizationMembershipsQuery(
    {
      organizationId: currentOrganizationId,
    },
    {
      enabled: Boolean(currentOrganizationId),
      select: data => {
        return Object.fromEntries(data.map(item => [item.user.id, item.user]));
      },
    },
  );

  if (!oneOffTasksQuery.data?.length) {
    return null;
  }

  const totalTasksCount = oneOffTasksQuery.data.length;
  const completedTasksCount = oneOffTasksQuery.data.filter(task => task.status === TaskStatus.Completed).length;

  return (
    <VStack w="full" alignItems="stretch" spacing={4}>
      <VStack w="full" alignItems="stretch">
        <HStack justifyContent="space-between">
          <Text fontSize="sm" m={0}>
            Tasks Attached
          </Text>

          <Text fontSize="sm">
            {completedTasksCount}/{totalTasksCount}
          </Text>
        </HStack>
        <Divider m={0} />
      </VStack>

      <UnorderedList m={0}>
        {oneOffTasksQuery.data?.map(task => {
          return (
            <ListItem
              listStyleType="none"
              key={task.id}
              bgColor="gray.50"
              borderColor="gray.200"
              borderStyle="solid"
              borderWidth="1px 1px 0px 1px"
              _first={{
                borderTopRightRadius: '4px',
                borderTopLeftRadius: '4px',
              }}
              _last={{
                borderBottomRightRadius: '4px',
                borderBottomLeftRadius: '4px',
                borderBottomWidth: 'thin',
              }}
            >
              <HStack h={10} pl={10} pr="4" alignItems="center" justifyContent="space-between" w="full" spacing={4}>
                <HStack spacing={4} w="full">
                  <Checkbox
                    w={5}
                    h={5}
                    size="lg"
                    isChecked={task.status === TaskStatus.Completed}
                    sx={{
                      '.chakra-checkbox__control': { bgColor: 'white', borderRadius: '4px' },
                    }}
                  />
                  <Text fontSize="xs" color="gray.700">
                    {task.name}
                  </Text>
                </HStack>

                <PrintOneOffTaskAudit task={task} usersMap={usersMapQuery.data ?? {}} />
              </HStack>
            </ListItem>
          );
        })}
      </UnorderedList>
    </VStack>
  );
};
