import { Muid } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace CreateFormSharesMutation {
  export type Params = {
    templateId: Template['id'];
    organizationMembershipIds: Muid[];
    emails: string[];
  };

  export type Response = {};

  export const key = ['post', 'templates', 'form-shares'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/templates/${params.templateId}/form-shares`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
