import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { featureFlagsStatusSelector } from 'reducers/statuses/statuses.selectors';
import { useCanViewDataSets } from './use-can-view-data-sets';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { useNavigate } from 'app/adapters/navigation';

export const useDataSetsGuard = () => {
  const navigate = useNavigate();

  const isUserInfoSuccess = useGetCurrentUserInfoQuery().isSuccess;
  const isFeatureFlagsLoading = useSelector(featureFlagsStatusSelector).loading;

  const canViewDataSets = useCanViewDataSets();

  // Prevent the users from accessing the data sets page directly by changing the URL
  // in case they shouldn't have access to it.
  useEffect(() => {
    if (isUserInfoSuccess && !canViewDataSets && !isFeatureFlagsLoading) {
      navigate('reports');
    }
  }, [canViewDataSets, isFeatureFlagsLoading, isUserInfoSuccess, navigate]);
};
