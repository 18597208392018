import { FutureChecklist, Template } from '@process-street/subgrade/process';
import { useSearchFutureChecklistsByOrganizationIdQuery } from 'features/future-checklist/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { generateSummary } from 'utils/scheduled-workflows';

export type ScheduleWorkflowInfoType = FutureChecklist & {
  description: string;
};
export type ScheduledWorkflowsInfoReturnType = Array<ScheduleWorkflowInfoType>;

const DEFAULT_SEARCH_LIMIT = 100;
export const useGetScheduledWorfklowInfo = ({
  templateId,
}: {
  templateId: Template['id'];
}): ScheduledWorkflowsInfoReturnType => {
  const orgId = useSelector(SessionSelector.getSelectedOrganizationId);

  const query = useSearchFutureChecklistsByOrganizationIdQuery(
    {
      organizationId: orgId ?? '',
      query: '',
      limit: DEFAULT_SEARCH_LIMIT,
    },
    {
      keepPreviousData: true,
      enabled: Boolean(orgId),
      select: futureChecklists => {
        return futureChecklists.reduce((acc, { futureChecklist, jobs }) => {
          if (futureChecklist.template.id === templateId) {
            acc.push({
              ...futureChecklist,
              description: generateSummary({ startDate: futureChecklist.startDate, jobs }),
            });
          }
          return acc;
        }, [] as ScheduledWorkflowsInfoReturnType);
      },
    },
  );

  return query?.data?.sort((a, b) => a.nextRunDate - b.nextRunDate) ?? [];
};
