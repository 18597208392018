import { Muid } from '@process-street/subgrade/core';
import {
  FormResponseMachineReceptionist,
  makeRulesEngineTargetTaskKey,
  makeRulesEngineTargetWidgetKey,
} from 'pages/responses/_id/form-response-machine-receptionist';
import { match } from 'ts-pattern';
import { ActorRefFrom, createMachine, sendParent } from 'xstate';
import { ResponseWidgetActorRef } from '../../form-fields';
import { TaskActor } from '../../task/task-machine';
import { RulesEngineMachineActorRef, RulesEngineMachineExternalEvent } from './rules-engine-machine';

type ParentEvent = { type: 'HIDE' } | { type: 'REVEAL' };
export { ParentEvent as RulesEngineTargetParentEvent };

type Event = RulesEngineMachineExternalEvent | ParentEvent;

export const makeRulesEngineTargetMachine = (
  options: { type: 'widget'; widgetHeaderGroupId: Muid } | { type: 'task'; taskTemplateGroupId: Muid },
) => {
  return createMachine(
    {
      id: match(options)
        .with({ type: 'widget' }, ({ widgetHeaderGroupId }) => makeRulesEngineTargetWidgetKey(widgetHeaderGroupId))
        .with({ type: 'task' }, ({ taskTemplateGroupId }) => makeRulesEngineTargetTaskKey(taskTemplateGroupId))
        .exhaustive(),
      predictableActionArguments: true,
      initial: 'visible',
      tsTypes: {} as import('./rules-engine-target-machine.typegen').Typegen0,
      schema: {
        events: {} as Event,
      },
      entry: 'requestStateFromRulesActor',
      states: {
        hidden: {
          entry: 'sendParentHide',
          on: {
            RULES_UPDATE: [{ target: 'hidden', cond: 'shouldHide' }, { target: 'visible' }],
          },
        },
        visible: {
          entry: 'sendParentReveal',
          on: {
            RULES_UPDATE: [{ target: 'hidden', cond: 'shouldHide' }],
          },
        },
      },
    },
    {
      actions: {
        requestStateFromRulesActor: () => {
          FormResponseMachineReceptionist.get<RulesEngineMachineActorRef>('rules-engine-actor')?.send({
            type: 'REQUEST_VISIBILITY_STATE',
          });
        },
        sendParentHide: sendParent('HIDE'),
        sendParentReveal: sendParent('REVEAL'),
      },
      guards: {
        shouldHide: (_ctx, event) => {
          return match(options)
            .with({ type: 'widget' }, ({ widgetHeaderGroupId }) => {
              return event.widgetVisibilityMap[widgetHeaderGroupId];
            })
            .with({ type: 'task' }, ({ taskTemplateGroupId }) => {
              return event.taskVisibilityMap[taskTemplateGroupId];
            })
            .otherwise(() => false);
        },
      },
    },
  );
};

export type RulesEngineTargetMachine = ReturnType<typeof makeRulesEngineTargetMachine>;
export type RulesEngineTargetActorRef = ActorRefFrom<RulesEngineTargetMachine>;

type State = ReturnType<(ResponseWidgetActorRef | TaskActor)['getSnapshot']>;
export const RulesEngineTargetSelectors = {
  getIsHiddenByRule: (state: State) => {
    return state?.context.rulesEngineTargetActor.getSnapshot()?.matches('hidden');
  },
};
