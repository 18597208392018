import React from 'react';
import { shouldShowUser, User } from '@process-street/subgrade/core';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { UserOption, UserOptionTransformer } from 'utils/user-option-transformer';
import { ActionMeta, createFilter, InputActionMeta, OnChangeValue } from 'react-select';
import { Box } from 'components/design/next';
import { generateUser } from '@process-street/subgrade/test';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { UserOptionItem } from './UserOptionItem';
import { InviteUsers } from './InviteUsers';
import { UserSelectedItem } from './UserSelectedItem';
import { ExtendedComponentsConfig } from 'components/design/BlvdSelect/types';
import { OverflowingUsersIndicator } from 'components/run-checklist/components/RunChecklist/components/OverflowingUsersIndicator';
import { UserSelectedItemRemove } from 'components/run-checklist/components/RunChecklist/components/UserSelectedItemRemove';
import { UserSelectedContainer } from 'components/run-checklist/components/RunChecklist/components/UserSelectedContainer';
import { useRoleNames } from 'hooks/use-role-names';
import { useSelector } from 'react-redux';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useFeatureFlag } from 'features/feature-flags';

export type AssigneesSelectorProps = {
  users: User[];
  onChange: (emails: string[]) => void;
  currentUser: User;
};

export const AssigneesSelector: React.FC<React.PropsWithChildren<AssigneesSelectorProps>> = ({
  users,
  onChange,
  currentUser,
}) => {
  const [invitedOptions, setInvitedOptions] = React.useState<UserOption[]>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<UserOption[]>(
    UserOptionTransformer.transformUsersToOptions([currentUser]),
  );
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const [inputValue, setInputValue] = React.useState<string>('');
  const roleNames = useRoleNames();
  const isUserDeactivationEnabled = useFeatureFlag('deactivatingUsers');

  const organizationMembershipsSelector = React.useMemo(
    () => OrganizationMembershipSelector.getAllByOrganizationIdMapByUserIdMemoized(organizationId ?? ''),
    [organizationId],
  );
  const organizationMembershipsByUserIdMap = useSelector(organizationMembershipsSelector);

  const userOptions = React.useMemo(
    () =>
      UserOptionTransformer.transformUsersToOptions(
        users.filter(user => shouldShowUser(user, organizationMembershipsByUserIdMap, isUserDeactivationEnabled)),
      ),
    [organizationMembershipsByUserIdMap, users, isUserDeactivationEnabled],
  );

  const allOptions = invitedOptions.concat(userOptions);

  React.useEffect(() => {
    onChange(selectedOptions.map(v => v.user.email));
  }, [onChange, selectedOptions]);

  const handleInputChange = (newValue: string, _actionMeta: InputActionMeta) => {
    setInputValue(newValue);
  };

  const inviteUser = (email: string) => {
    const user = generateUser({ email, username: email });
    const option = {
      user,
      label: email,
      value: user.id,
    } as UserOption;

    setInvitedOptions(invitedOptions.concat(option));
    setSelectedOptions(selectedOptions.concat(option));
    setInputValue('');
  };

  const selectComponents: ExtendedComponentsConfig<UserOption, true> = {
    Option: UserOptionItem,
    MultiValueLabel: UserSelectedItem,
    MultiValueContainer: UserSelectedContainer,
    MultiValueRemove: UserSelectedItemRemove,
    NoOptionsMessage: InviteUsers(inviteUser, roleNames.Guest.single),
    OverflowingValuesIndicator: OverflowingUsersIndicator,
  };

  const handleChange = (value: OnChangeValue<UserOption, true>, actionMeta: ActionMeta<UserOption>) => {
    if (actionMeta.action === 'remove-value') {
      setSelectedOptions(selectedOptions.filter(o => o.value !== actionMeta.removedValue?.value));
    } else if (BlvdSelectHelpers.isOptionsType<UserOption>(value)) {
      setSelectedOptions(value as UserOption[]);
    }
  };

  const optionsFilter = createFilter({
    ignoreCase: true,
    matchFrom: 'any',
    stringify: ({ data }: { data: UserOption }) =>
      data.emailVisible ? `${data.label} ${data.user.email}` : data.label,
  });

  return (
    <Box
      maxWidth="600px"
      width="full"
      sx={{
        '.blvd-select__menu': {
          zIndex: 99,
        },
      }}
    >
      <BlvdSelect
        options={allOptions}
        value={selectedOptions}
        components={selectComponents}
        className={'blvd-user-select'}
        fixedSize={true}
        isClearable={true}
        isSearchable={true}
        isMulti={true}
        menuControls={false}
        placeholder={'Unassigned'}
        searchPlaceholder={`Search or enter email address to invite a ${roleNames.Guest.single}.`}
        onChange={handleChange}
        closeMenuOnSelect={true}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        filterOption={optionsFilter}
      />
    </Box>
  );
};
