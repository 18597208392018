import React from 'react';
import { match, P } from 'ts-pattern';

import { Template } from '@process-street/subgrade/process';
import { Flex, FlexProps, Text } from 'components/design/next';
import { CDNImage } from 'components/cdn-image';
import { GetCoverIconByTemplateId } from 'features/cover-icon/query-builder';

export type InboxCoverIconProps = {
  templateId: Template['id'];
} & FlexProps;

const IconContainer: React.FC<React.PropsWithChildren<FlexProps>> = ({ children, ...props }) => {
  return (
    <Flex
      data-testid="cover-icon-container"
      alignItems="center"
      justifyContent="center"
      height={{ base: '7', md: '10', lg: '12' }}
      width={{ base: '7', md: '10', lg: '12' }}
      position="relative"
      bg="white"
      borderRadius="50%"
      {...props}
    >
      {children}
    </Flex>
  );
};
export const InboxCoverIcon: React.FC<React.PropsWithChildren<InboxCoverIconProps>> = ({ templateId, ...props }) => {
  const coverIconQuery = GetCoverIconByTemplateId.useQuery({ templateId });
  return match(coverIconQuery)
    .with({ status: 'success', data: { emoji: P.not(undefined) } }, ({ data }) => (
      <IconContainer {...props}>
        <Text fontSize="2xl" align="center">
          {data.emoji}
        </Text>
      </IconContainer>
    ))
    .with({ status: 'success', data: { s3File: P.not(undefined) } }, ({ data }) => (
      <IconContainer {...props}>
        <CDNImage
          alt={data.s3File.originalName}
          s3File={data.s3File}
          transformation={{
            height: 48,
            width: 48,
          }}
          borderRadius="50%"
        />
      </IconContainer>
    ))
    .otherwise(() => null);
};
