import { Folder } from '@process-street/subgrade/process';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { AxiosError } from 'axios';

export type DeleteFolderParams = { folderId: Folder['id'] };
export type DeleteFolderResult = void;

export const DeleteFolder = {
  mutationFn: async ({ folderId }: DeleteFolderParams): Promise<DeleteFolderResult> => {
    return axiosService
      .getAxios()
      .delete<DeleteFolderResult>(`/1/folders/${folderId}`)
      .then(res => res.data);
  },
};
export const useDeleteFolderMutation = (
  options: UseMutationOptions<DeleteFolderResult, AxiosError, DeleteFolderParams> = {},
) => {
  return useMutation<DeleteFolderResult, AxiosError, DeleteFolderParams>(DeleteFolder.mutationFn, options);
};

export type DeleteFoldersParams = { folderIds: Folder['id'][] };

export const useDeleteFoldersMutation = (
  options: UseMutationOptions<DeleteFolderResult[], AxiosError, DeleteFoldersParams> = {},
) => {
  return useMutation<DeleteFolderResult[], AxiosError, DeleteFoldersParams>(
    ({ folderIds }) => Promise.all(folderIds.map(folderId => DeleteFolder.mutationFn({ folderId }))),
    options,
  );
};
