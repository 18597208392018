import { Template } from '@process-street/subgrade/process';
import { Icon, IconButton, MotionWrapper, Stack, useBreakpointValue } from 'components/design/next';
import { DashboardAutomations } from 'pages/templates/_id/dashboard/components/sidebar/dashboard-automations';
import { MoreRunningOptions } from 'pages/templates/_id/dashboard/components/sidebar/more-running-options';
import * as React from 'react';
import { BasicWorkflowActions } from 'pages/templates/_id/dashboard/components/sidebar/basic-workflow-actions';
import { Assignments } from 'pages/templates/_id/dashboard/components/sidebar/assignments';
import { Box, useDisclosure } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { TOP_BAR_HEIGHT_TOKEN } from 'pages/forms/_id/shared';

export type SidebarProps = {
  template?: Template;
};

const TOP_OFFSET = TOP_BAR_HEIGHT_TOKEN + 8;
const ICON_TOP_OFFSET = TOP_BAR_HEIGHT_TOKEN + 16;

export const Sidebar = ({ template }: SidebarProps) => {
  const templateId = template?.id;

  const { isOpen, onToggle, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const isDesktop = useBreakpointValue({ base: false, xl: true });

  React.useEffect(() => {
    if (isDesktop) {
      onOpen();
    } else {
      // Sidebar starts collapsed on mobile screen sizes
      onClose();
    }
  }, [isDesktop, onOpen, onClose]);

  return (
    <Box
      h="100vh"
      top={0}
      position={{ base: 'fixed', xl: 'sticky' }}
      right={{ base: 0, xl: undefined }}
      maxW={{ base: '320px', sm: '360px' }}
      borderLeftWidth="thin"
      borderLeftColor="gray.100"
      borderLeftStyle="solid"
      backgroundColor="blue.50"
      pt={{ base: TOP_OFFSET + 16, xl: TOP_OFFSET }}
      width={isOpen ? '100%' : 0}
      transition="width 0.3s linear"
      aria-hidden={!isOpen}
      zIndex="2"
      boxShadow={{ base: 'xl', xl: 'none' }}
    >
      <IconButton
        aria-label="Toggle sidebar visibility"
        icon={<Icon icon={isOpen ? 'chevron-right' : 'chevron-left'} size="4" />}
        h={9}
        w={9}
        variant="ghost"
        onClick={onToggle}
        backgroundColor="blue.50"
        color="gray.500"
        position="absolute"
        top={ICON_TOP_OFFSET}
        left={-10}
        _hover={{ bgColor: 'gray.200' }}
        borderRightRadius="0"
        boxShadow={{ base: 'xl', xl: 'base' }}
        display={{ base: 'block', xl: 'none' }}
      />
      <AnimatePresence initial={false}>
        {isOpen && (
          <MotionWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Stack w="full" alignItems="stretch" justifyContent="flex-start" spacing={8} px={{ base: 4, sm: 6 }}>
              <BasicWorkflowActions template={template} />
              <MoreRunningOptions templateId={templateId} />
              <DashboardAutomations templateId={templateId} />
              <Assignments template={template} />
            </Stack>
          </MotionWrapper>
        )}
      </AnimatePresence>
    </Box>
  );
};
