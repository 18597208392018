import { Option } from '@process-street/subgrade/core';
import type { State, TempDataService as ITempDataService } from 'services/temp-data-service.interface';

const MAX_STATES = 10;

interface DataMap {
  satisMeterSurveyForced?: boolean;
  states: State[];
}

class TempDataService implements ITempDataService {
  private dataMap: DataMap = {
    states: [],
  };

  constructor() {
    this.clear();
  }

  public clear() {
    this.dataMap = {
      satisMeterSurveyForced: false, // This is a temporary flag used to test out SatisMeter
      states: [],
    };
  }

  public isSatisMeterSurveyForced(): boolean {
    return !!this.dataMap.satisMeterSurveyForced;
  }

  public setSatisMeterSurveyForced(surveyForced: boolean): void {
    this.dataMap.satisMeterSurveyForced = surveyForced;
  }

  public getStates(): State[] {
    return this.dataMap.states.slice().reverse();
  }

  public getPreviousState(): Option<State> {
    const states = this.getStates();
    return states.length > 0 ? states[0] : undefined;
  }

  public pushState(state: State): void {
    const { states } = this.dataMap;
    states.push(state);

    if (states.length > MAX_STATES) {
      states.shift();
    }
  }
}

// Singleton used by angular injection _and_ React via direct import
export const TempDataServiceImpl = new TempDataService();
