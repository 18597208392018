import * as React from 'react';
import {
  MultiChoiceFormFieldWidget,
  SelectFormFieldWidget,
  TaskTemplate,
  TemplateRevision,
  Widget,
} from '@process-street/subgrade/process';
import { useSelector } from '@xstate/react';
import { DatasetCreateFromCsvModal } from 'app/pages/reports/data-sets/components/data-set-creation-modal';
import { DisconnectDataSetConfirmationModal } from 'app/pages/reports/data-sets/components/disconnect-data-set-confirmation-modal';
import { ConnectDataSetModal } from 'app/pages/templates/_id/components/connect-data-set-modal';
import { LinkDataSetModal } from 'app/pages/templates/_id/components/link-data-set-modal';
import { getWidgetModalsMachineService, Selectors } from 'features/widgets/widget-modals-machine';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';

export type DataSetWidgetModalsProps = {
  taskTemplateId: TaskTemplate['id'];
  templateRevisionId: TemplateRevision['id'];
};

const actor = getWidgetModalsMachineService();

export type LinkableWidget = SelectFormFieldWidget | MultiChoiceFormFieldWidget;

export const DataSetWidgetModals = ({ taskTemplateId, templateRevisionId }: DataSetWidgetModalsProps) => {
  const widget = useSelector(actor, Selectors.getWidget<SelectFormFieldWidget>);
  const editorActor = useFormEditorPageActorRef();
  const widgetActorMap = useSelector(editorActor, FormEditorPageActorSelectors.getWidgetActorMap);
  const widgetActor = React.useMemo(() => widgetActorMap[widget?.header.id ?? ''], [widget, widgetActorMap]);
  const handleOnDisconnect = React.useCallback(
    (widget: Widget) => {
      widgetActor.send({ type: 'LINK_UNLINK_DATA_SET', widget });
    },
    [widgetActor],
  );
  return (
    <>
      <ConnectDataSetModal />
      {widgetActor && (
        <>
          <LinkDataSetModal
            templateRevisionId={templateRevisionId}
            taskTemplateId={taskTemplateId}
            onUpdate={async (widget: Widget) => {
              await widgetActor.send({
                type: 'LINK_UNLINK_DATA_SET',
                widget: widget as LinkableWidget,
              });
              return widget;
            }}
          />
          <DisconnectDataSetConfirmationModal onUpdate={handleOnDisconnect} />
        </>
      )}
      <DatasetCreateFromCsvModal />
    </>
  );
};
