import angular from 'angular';
import { groupWithUserSelector } from 'reducers/group/group.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { getTabIndexByName, initializeTabs } from 'services/tab-service';
import membersTemplate from './members.component.html';
import propertiesTemplate from './properties.component.html';
import { GroupActions } from 'reducers/group/group.actions';

const POSSIBLE_TABS = [
  {
    name: 'properties',
    heading: 'Properties',
    templateUrl: propertiesTemplate,
  },
  {
    name: 'members',
    heading: 'Members',
    templateUrl: membersTemplate,
  },
];

class GroupManageCtrl {
  constructor($scope, $state, $ngRedux, OrganizationActions) {
    'ngInject';

    const mapStateToScope = state => {
      const organization = SessionSelector.getSelectedOrganization(state);
      const group = groupWithUserSelector(state, $state.params.id);
      return { organization, group };
    };

    const mapDispatchToScope = {
      getGroupById: GroupActions.getGroupById,
      loadSelectedOrganization: OrganizationActions.loadSelectedOrganization,
    };

    this.unsubscribe = $ngRedux.connect(mapStateToScope, mapDispatchToScope)($scope);

    Promise.all([$scope.getGroupById($state.params.id), $scope.loadSelectedOrganization()]).then(() => {
      if (!$scope.group) {
        $state.go('dashboard');
      } else {
        $scope.tabs = POSSIBLE_TABS;
        $scope.activeIndex = getTabIndexByName($scope.tabs, $state.params.tab) || 0;

        initializeTabs($scope.tabs, $state, $scope, state => state.includes('groupManage'));
      }
    });

    this.unsubscribeStateChangeListeners = $scope.$on('$stateChangeSuccess', (__event, __toState, toParams) => {
      $scope.activeIndex = getTabIndexByName($scope.tabs, toParams.tab) || 0;
    });
  }

  $onDestroy = () => {
    this.unsubscribeStateChangeListeners();
    this.unsubscribe();
  };
}

angular.module('frontStreetApp.controllers').controller('GroupManageCtrl', GroupManageCtrl);
