import { Muid } from '@process-street/subgrade/core';
import { ChecklistRevision } from '@process-street/subgrade/process';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { EntityMap } from '@process-street/subgrade/redux/types';
import { CHECKLIST_DELETE_BY_ID } from 'reducers/checklist/checklist.actions';
import { referencesNormalizer } from 'reducers/entities/entities.utils';
import { handleActionsOnSuccess } from 'reducers/util/index';
import { Action, ActionMeta, combineActions } from 'redux-actions';
import {
  CHECKLIST_REVISION_GET,
  CHECKLIST_REVISION_GET_ACTIVE_BY_CHECKLIST,
  CHECKLIST_REVISION_QUERY,
} from './checklist-revision.actions';

const normalizeChecklistRevision = referencesNormalizer<ChecklistRevision>([
  'organization',
  'templateRevision',
  'checklist',
]);

export const checklistRevisionEntitiesReducer = handleActionsOnSuccess<EntityMap<ChecklistRevision>>(
  {
    // @ts-expect-error -- TODO
    [combineActions(CHECKLIST_REVISION_QUERY)]: (
      state: EntityMap<ChecklistRevision>,
      action: Action<ChecklistRevision[]>,
    ) => EntitiesReducerUtils.upsertAll<ChecklistRevision>(state, action.payload, normalizeChecklistRevision),
    // @ts-expect-error -- TODO
    [combineActions(CHECKLIST_REVISION_GET, CHECKLIST_REVISION_GET_ACTIVE_BY_CHECKLIST)]: (
      state: EntityMap<ChecklistRevision>,
      action: Action<ChecklistRevision>,
    ) => EntitiesReducerUtils.upsert(state, action.payload, normalizeChecklistRevision),
    [CHECKLIST_DELETE_BY_ID]: (
      state: EntityMap<ChecklistRevision>,
      action: ActionMeta<void, { checklistId: Muid }>,
    ) => {
      const {
        meta: { checklistId },
      } = action;
      const relevantChecklistRevisions = Object.values(state).filter(revision => revision.checklist.id === checklistId);
      return EntitiesReducerUtils.deleteAll(state, relevantChecklistRevisions);
    },
  },
  {},
);
