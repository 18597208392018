import { ELEMENT_PS_SOFT_BREAK } from 'pages/pages/_id/edit/page/plugins/ps-soft-break';
import { createPagesPluginFactory, PagesElement } from '../pages-plate-types';
import { ELEMENT_LINK } from '@udecode/plate-link';

const InlineElements: PagesElement['type'][] = [ELEMENT_PS_SOFT_BREAK, ELEMENT_LINK];
export const createPSInline = createPagesPluginFactory({
  key: 'PSInline',
  withOverrides: editor => {
    const { isInline } = editor;

    return Object.assign(editor, {
      isInline: (element: PagesElement) => {
        return InlineElements.includes(element.type) ? true : isInline(element);
      },
    });
  },
});
