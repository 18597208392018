import angular from 'angular';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller('ChecklistManageActivityCtrl', function ($scope, $state, $timeout, ChecklistActivityService) {
    const ctrl = this;
    const logger = trace({ name: 'ChecklistManageActivityCtrl' });
    logger.info('loading ctrl');

    ctrl.$onInit = () => {
      $timeout($scope.loadOlderActivities);
    };

    const PAGE_SIZE = 20;

    $scope.loadOlderActivities = function () {
      $scope.loadingSentences = true;

      const oldSentences = $scope.sentences || [];
      const offsetId = oldSentences[oldSentences.length - 1]?.activity?.id;

      ChecklistActivityService.getActivitiesByIdLimitAndOffsetId($state.params.id, PAGE_SIZE, offsetId)
        .then(result => {
          $scope.hiddenTotal = result.hiddenTotal;

          $scope.sentences = $scope.sentences || [];

          if (result.data.length) {
            const newSentences = result.data.map(ChecklistActivityService.interpretSimple).filter(a => a);
            $scope.sentences = $scope.sentences.concat(newSentences);
            $scope.moreSentences = result.data.length === PAGE_SIZE;
          } else {
            $scope.moreSentences = false;
          }
        })
        .finally(() => {
          $scope.loadingSentences = false;
        });
    };
  });
