import { Folder } from '@process-street/subgrade/process/folder-model';
import {
  Box,
  Icon,
  IconButton,
  MenuButton,
  Skeleton,
  useBoolean,
  useBreakpointValue,
  VStack,
} from 'components/design/next';
import { FolderPermissionsIndicator } from 'components/permissions/folder-permissions-indicator/component';
import { useFeatureFlag } from 'features/feature-flags';
import { toSlugPath, isPrivateRootFolder, getAncestorFolders } from 'features/folder/lib';
import {
  GetConsolidatedTemplatePermissionsResult,
  useGetAllConsolidatedFolderPermissionsQuery,
} from 'features/permissions/query-builder';
import * as React from 'react';
import { match, P } from 'ts-pattern';
import {
  RightButton,
  Row,
  RowLink,
  IconLinkContainer,
  ReportsLink,
  WorkflowRunsLinkText,
  RowPermissionsIndicator,
  RowProps,
} from '../common-ui';
import { useFoldersQuery } from '../query';
import { useChecklistCountByFolder } from './use-checklist-count-by-folder';
import { FolderRowMenu } from './menu';
import { FolderRowDragZoneWrapper } from './dropzone-wrapper';
import { FolderRowDragWrapper } from './drag-wrapper';
import { FolderPermissionMap, Muid } from '@process-street/subgrade/core';
import { SelectionCheckbox } from '../selection-checkbox/selection-checkbox';
import { MobileActions } from '../mobile-actions';
import { Link } from '@process-street/adapters/navigation';
import { queryString } from '@process-street/subgrade/util';

export type Props = {
  folder: Folder;
  folderPermissions?: FolderPermissionMap;
  templatePermissionsById?: Record<string, GetConsolidatedTemplatePermissionsResult>;
  writeableFoldersSet: Set<Muid>;
  toggleItemDisclosure: (id: Muid) => void;
  itemDisclosureById: Record<Muid, boolean>;
} & RowProps;

export const FolderRow: React.FC<React.PropsWithChildren<Props>> = ({
  folder,
  folderPermissions,
  templatePermissionsById,
  writeableFoldersSet,
  toggleItemDisclosure,
  itemDisclosureById,
  ...props
}) => {
  const [isHover, setIsHover] = useBoolean(false);
  const [isMenuOpen, setIsMenuOpen] = useBoolean(false);
  const folderId = folder.id;
  const isSelectionEnabled = useFeatureFlag('deleteArchiveMultipleTemplatesFromLibrary');
  const isDragAndDropEnabled = useFeatureFlag('dragAndDropLibrary');
  const isExpanded = itemDisclosureById[folder.id] ?? false;
  const isMobile = useBreakpointValue({ base: true, md: false });

  // CHECKLIST DATA
  const { isLoading: checklistCountIsLoading, count: checklistCount } = useChecklistCountByFolder(folder);

  // FOLDER DATA
  const { data: folders = [] } = useFoldersQuery();
  const ancestors = getAncestorFolders(folder, folders);
  const root = ancestors[ancestors.length - 1];
  const folderIsPrivate = isPrivateRootFolder(root);

  // PERMISSIONS
  const { data: { permissionMap } = {} } = useGetAllConsolidatedFolderPermissionsQuery({
    select: allFolderPermits => allFolderPermits.find(fp => fp.folderId === folderId),
  });

  const canManage = permissionMap?.folderUpdate;

  const handleOnCloseMenu = () => {
    setIsMenuOpen.off();
  };

  return (
    <FolderRowDragZoneWrapper
      key={folder.id}
      folder={folder}
      folderPermissions={folderPermissions}
      templatePermissionsById={templatePermissionsById}
      writeableFoldersSet={writeableFoldersSet}
    >
      {({ hasPermissions, isOver }) => (
        <FolderRowDragWrapper key={folder.id} folder={folder}>
          <VStack spacing="0" w="full" overflow="hidden">
            <Row
              onMouseEnter={() => setIsHover.on()}
              onMouseLeave={() => setIsHover.off()}
              {...(isDragAndDropEnabled ? { px: undefined, pr: [2, 2, 4], pl: 1 } : {})}
              {...props}
            >
              {isSelectionEnabled && (
                <Box mr="4">
                  <SelectionCheckbox item={folder} />
                </Box>
              )}

              <RowLink
                to="dashboard.type"
                params={{ type: 'folder', path: toSlugPath(folder, ancestors) }}
                options={{ inherit: false }}
                icon={
                  <Icon
                    icon={!hasPermissions && isOver ? 'lock' : 'folder'}
                    variant="fas"
                    size="4"
                    color="gray.500"
                    aria-label={`folder ${folder.name}`}
                  />
                }
                _hover={{
                  color: 'brand.500',
                  svg: {
                    color: 'gray.700',
                  },
                }}
                color={!hasPermissions && isOver ? 'gray.400' : undefined}
                onClick={isMobile ? () => toggleItemDisclosure(folder.id) : undefined}
              >
                {folder.name}
              </RowLink>

              {isHover || isMenuOpen ? (
                <RightButton
                  onClick={() => {
                    if (!isMenuOpen) setIsMenuOpen.on();
                  }}
                  w={{ base: '8', md: 'auto' }}
                  pr={{ base: undefined, md: '8' }}
                  pl={{ base: undefined, md: '4' }}
                >
                  {canManage ? <FolderRowMenu folder={folder} onClose={handleOnCloseMenu} /> : null}
                </RightButton>
              ) : null}

              <IconButton
                aria-label={`${isExpanded ? 'Collapse' : 'Expand'} ${folder.name} folder`}
                display={{ base: 'block', md: 'none' }}
                icon={<Icon icon={isExpanded ? 'caret-up' : 'caret-down'} color="gray.500" variant="fas" size="4" />}
                variant="unstyled"
                maxW="8"
                onClick={() => toggleItemDisclosure(folder.id)}
              />

              <RowPermissionsIndicator>
                {!folderIsPrivate ? (
                  // TODO The permission indicator doesn't work for private items.
                  <FolderPermissionsIndicator folderId={folder.id} organizationId={folder.organization.id} />
                ) : null}
              </RowPermissionsIndicator>

              {match({ checklistCountIsLoading, checklistCount })
                .with({ checklistCountIsLoading: true }, () => (
                  <>
                    <IconLinkContainer>
                      <Skeleton h="6" width="8" />
                    </IconLinkContainer>
                    <IconLinkContainer>
                      <Skeleton h="6" width="8" />
                    </IconLinkContainer>
                  </>
                ))
                .with({ checklistCount: P.when(n => n > 0) }, () => (
                  // only display analytics & reports shortcuts if folder contains workflow runs
                  <>
                    <IconLinkContainer w={10}>
                      <ReportsLink
                        to="reports"
                        params={{ folderId, tab: 'analytics' }}
                        options={{ inherit: false }}
                        title="Click to view the analytics for this folder."
                        icon={<Icon icon="chart-bar" variant="far" size="4" />}
                      />
                    </IconLinkContainer>
                    <IconLinkContainer aria-label="workflow run count">
                      <ReportsLink
                        to="reports"
                        params={{ folderId, tab: 'table' }}
                        options={{ inherit: false }}
                        title="Click to view the workflow runs for this folder."
                        icon={<Icon icon="th-list" variant="far" size="4" />}
                      >
                        <WorkflowRunsLinkText>{checklistCount}</WorkflowRunsLinkText>
                      </ReportsLink>
                    </IconLinkContainer>
                  </>
                ))
                .otherwise(() => (
                  <>
                    <IconLinkContainer w={10} />
                    <IconLinkContainer />
                  </>
                ))}
            </Row>

            {isExpanded && (
              <MobileActions.Wrapper>
                <MobileActions.Item
                  as={Link}
                  // @ts-expect-error the `as` is not changing the props to accept `to`
                  to={{
                    pathname: 'dashboard.type',
                    search: queryString.stringify({ type: 'folder', path: toSlugPath(folder, ancestors) }),
                  }}
                  leftIcon={<Icon icon="folder-open" variant="fas" size="4" />}
                >
                  Open folder
                </MobileActions.Item>

                {canManage && (
                  <FolderRowMenu
                    menuButton={
                      <MenuButton as={MobileActions.Item} leftIcon={<Icon icon="ellipsis-h" size="4" />}>
                        View options
                      </MenuButton>
                    }
                    folder={folder}
                    onClose={handleOnCloseMenu}
                  />
                )}
              </MobileActions.Wrapper>
            )}
          </VStack>
        </FolderRowDragWrapper>
      )}
    </FolderRowDragZoneWrapper>
  );
};
