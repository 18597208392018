import { Box, Button, HStack, Icon, Image, Input, Skeleton, useToast } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import {
  GetOrganizationQuery,
  useGetOrganizationQuery,
  useUploadOrganizationLogoFileMutation,
} from 'features/organization/query-builder';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { EventName } from 'services/event-name';
import { features } from 'services/features/features';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export const LogoUploader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { $rootScope } = useInjector('$rootScope');
  const queryClient = useQueryClient();
  const currentOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const toast = useToast();
  const isBrandingFeatureAvailable = currentOrganization
    ? features.canAccess(features.Feature.BRANDING, currentOrganization.subscription.plan.id)
    : false;

  const [file, setFile] = React.useState<File | null>(null);

  const organizationQuery = useGetOrganizationQuery({ organizationId: currentOrganization?.id });

  const uploadOrganizationLogoFileMutation = useUploadOrganizationLogoFileMutation({
    onSuccess: (logoFile, variables) => {
      queryClient.invalidateQueries(GetOrganizationQuery.getKey({ organizationId: variables.organizationId }));
      $rootScope.$broadcast(EventName.ORGANIZATION_UPDATED, {
        ...currentOrganization,
        logoFile,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems updating the organization logo",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const previewUrl = React.useMemo(() => {
    if (!file) return currentOrganization?.logoFile?.url;

    return URL.createObjectURL(file);
  }, [file, currentOrganization?.logoFile?.url]);

  return (
    <HStack spacing="4">
      <Skeleton borderRadius="100%" isLoaded={!organizationQuery.isLoading}>
        {previewUrl ? (
          <Image
            src={previewUrl}
            w="14"
            h="14"
            borderRadius="full"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.200"
            minWidth="14"
            flexBasis="14"
          />
        ) : (
          <Box
            w="14"
            h="14"
            borderRadius="full"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.200"
            minWidth="14"
            flexBasis="14"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon icon="image" size="5" color="gray.500" />
          </Box>
        )}
      </Skeleton>

      <HStack spacing="2">
        <Button
          variant="outline"
          colorScheme="gray"
          borderWidth="1px"
          color="gray.600"
          fontWeight="normal"
          px="4"
          leftIcon={<Icon icon="camera" size="4" />}
          iconSpacing="2"
          onClick={() => {
            inputRef.current?.click();
          }}
          isDisabled={!isBrandingFeatureAvailable || organizationQuery.isLoading}
          loadingText="Uploading..."
          isLoading={uploadOrganizationLogoFileMutation.isLoading}
        >
          Upload
        </Button>
      </HStack>

      <Input
        ref={inputRef}
        id="file"
        data-testid="logo-file-input"
        name="file"
        type="file"
        visibility="hidden"
        position="absolute"
        w="0"
        h="0"
        accept=".gif, .jpg, .jpeg, .png"
        htmlSize={MAX_FILE_SIZE}
        isDisabled={!isBrandingFeatureAvailable}
        onChange={e => {
          if (e.target.files?.[0]) {
            setFile(e.target.files[0]);

            if (!currentOrganization?.id) throw new Error('Organization ID is missing');

            uploadOrganizationLogoFileMutation.mutate({
              file: e.target.files[0],
              organizationId: currentOrganization?.id,
            });
          } else {
            setFile(null);
          }
        }}
      />
    </HStack>
  );
};

const MAX_FILE_SIZE = 2 * 1024 * 1024;
