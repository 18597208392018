import angular from 'angular';
import { HttpStatus } from '@process-street/subgrade/util';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'FolderManagePropertiesCtrl',
    function ($scope, $state, DataService, FolderService, SessionService, ToastService) {
      const ctrl = this;
      const logger = trace({ name: 'FolderManagePropertiesCtrl' });
      logger.info('loading ctrl');

      ctrl.$onInit = () => {
        initializeFolder();
        initializeForm($scope.folder);
      };

      $scope.update = function (folder, info) {
        $scope.updating = true;

        FolderService.update(folder, info)
          .then(
            updatedFolder => {
              $scope.folder = updatedFolder;
            },
            () => {
              // Do nothing
            },
          )
          .finally(() => {
            $scope.updating = false;
          });
      };

      // This event is fired by the FolderService
      $scope.$on(EventName.FOLDER_UPDATED, () => {
        $scope.form.$setPristine();
      });

      // This event is fired by the FolderService
      $scope.$on(EventName.FOLDER_UPDATE_FAILED, () => {
        ToastService.openToast({
          status: 'error',
          title: "We're having problems updating the folder.",
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });

      $scope.canDelete = function (folder) {
        return $scope.subject.canDeleteFolder(folder.id);
      };

      /**
       * This ensures that the confirm delete modal will only be shown once.
       * @type {boolean}
       */
      let confirmDeleteShown = false;

      $scope.delete = function (folder) {
        if (confirmDeleteShown) {
          return;
        }
        confirmDeleteShown = true;

        function callback(fl) {
          confirmDeleteShown = false;

          $scope.deleting = true;

          FolderService.deleteFolder(fl).finally(() => {
            $scope.deleting = false;
          });
        }

        FolderService.confirmDelete(folder, callback);
      };

      $scope.$on(EventName.FOLDER_DELETED, () => {
        ToastService.openToast({
          status: 'success',
          title: `Folder deleted`,
        });

        $state.go('dashboard');
      });

      $scope.$on(EventName.FOLDER_DELETE_FAILED, (__event, __folder, response) => {
        switch (response.status) {
          case HttpStatus.BAD_REQUEST:
            ToastService.openToast({
              status: 'warning',
              title: `We couldn't delete the folder`,
              description:
                'You must delete all the active and archived templates ' + 'in the folder before deleting it.',
            });
            break;
          default:
            ToastService.openToast({
              status: 'error',
              title: `We're having problems deleting the workflow`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
        }
      });

      // Initialization

      function initializeFolder() {
        $scope.folder = DataService.getCollection('folders').get($state.params.id);
      }

      function initializeForm(folder) {
        $scope.info = {
          name: folder.name,
        };
      }
    },
  );
