import {
  DateFormFieldConstraints,
  DateFormFieldConstraintsSourceType,
  FormFieldWidget,
} from '@process-street/subgrade/process';
import * as React from 'react';
import { match, P } from 'ts-pattern';
import { Period } from '@process-street/subgrade/core';
import { DateUtils } from '@process-street/subgrade/util';
import { useCurrentUser } from 'hooks/use-current-user';

// Auxiliar type to easily amnage constraints and to build rules like: "Date must be 2 days after Workflow run start date"
export type DateFieldConstraint = {
  date?: number;
  offset?: Period;
  targetDateWidgetGroupId?: string;
  direction: ConstraintDirection;
  sourceType: DateFormFieldConstraintsSourceType;
  isPersisted: boolean;
};

export type ConstraintDirection = 'before' | 'after';

export const useDateSettingsConstraints = (
  existingConstraints: DateFormFieldConstraints,
  dateWidgets: FormFieldWidget[],
) => {
  const currentUser = useCurrentUser();

  const isWorkflowOrTaskSourceType = (sourceType: DateFormFieldConstraintsSourceType): boolean =>
    [
      DateFormFieldConstraintsSourceType.TaskDueDate,
      DateFormFieldConstraintsSourceType.ChecklistStartDate,
      DateFormFieldConstraintsSourceType.ChecklistDueDate,
    ].includes(sourceType);

  const getRulesFromConstraints = (): DateFieldConstraint[] => {
    const rulesCopy: DateFieldConstraint[] = [];
    // Handle the after date rules
    if (existingConstraints.afterDate) {
      rulesCopy.push({
        date: existingConstraints.afterDate,
        offset: existingConstraints.afterDateOffset,
        direction: 'after',
        sourceType: DateFormFieldConstraintsSourceType.SpecificDate,
        isPersisted: true,
      });
    }

    if (
      existingConstraints.afterDateFormFieldWidgetGroupId &&
      existingConstraints.afterDateSourceType === DateFormFieldConstraintsSourceType.FormFieldValue
    ) {
      const targetWidget = dateWidgets.find(
        widget => widget.header.group.id === existingConstraints.afterDateFormFieldWidgetGroupId,
      );
      if (targetWidget) {
        rulesCopy.push({
          offset: existingConstraints.afterDateOffset,
          direction: 'after',
          targetDateWidgetGroupId: targetWidget.header.group.id,
          sourceType: DateFormFieldConstraintsSourceType.FormFieldValue,
          isPersisted: true,
        });
      }
    }

    if (
      existingConstraints.afterDateSourceType &&
      isWorkflowOrTaskSourceType(existingConstraints.afterDateSourceType)
    ) {
      rulesCopy.push({
        offset: existingConstraints.afterDateOffset,
        direction: 'after',
        sourceType: existingConstraints.afterDateSourceType,
        isPersisted: true,
      });
    }

    // Handle the before date rules
    if (
      existingConstraints.beforeDate &&
      existingConstraints.beforeDateSourceType === DateFormFieldConstraintsSourceType.SpecificDate
    ) {
      rulesCopy.push({
        date: existingConstraints.beforeDate,
        offset: existingConstraints.beforeDateOffset,
        direction: 'before',
        sourceType: DateFormFieldConstraintsSourceType.SpecificDate,
        isPersisted: true,
      });
    }

    if (
      existingConstraints.beforeDateFormFieldWidgetGroupId &&
      existingConstraints.beforeDateSourceType === DateFormFieldConstraintsSourceType.FormFieldValue
    ) {
      const targetWidget = dateWidgets.find(
        widget => widget.header.group.id === existingConstraints.beforeDateFormFieldWidgetGroupId,
      );
      if (targetWidget) {
        rulesCopy.push({
          offset: existingConstraints.beforeDateOffset,
          direction: 'before',
          targetDateWidgetGroupId: targetWidget.header.group.id,
          sourceType: DateFormFieldConstraintsSourceType.FormFieldValue,
          isPersisted: true,
        });
      }
    }

    if (
      existingConstraints.beforeDateSourceType &&
      isWorkflowOrTaskSourceType(existingConstraints.beforeDateSourceType)
    ) {
      rulesCopy.push({
        offset: existingConstraints.beforeDateOffset,
        direction: 'before',
        sourceType: existingConstraints.beforeDateSourceType,
        isPersisted: true,
      });
    }

    if (rulesCopy.length > 2) {
      throw new Error('Unexpected number of rules for date form field widget');
    }

    return rulesCopy;
  };

  const getConstraintDefinitionString = (rule: DateFieldConstraint): string => {
    const offsetStr = match(rule)
      .with({ offset: P.not(P.nullish) }, ({ offset }) => {
        return match(offset)
          .with({ months: P.when(value => value > 0), days: P.when(value => value > 0) }, ({ months, days }) => {
            return `${months} ${months > 1 ? 'months' : 'month'} and ${days} ${days > 1 ? 'days ' : 'day '}`;
          })
          .with({ months: P.when(value => value > 0) }, ({ months }) => {
            return `${months} ${months > 1 ? 'months ' : 'month '}`;
          })
          .with({ days: P.when(value => value > 0) }, ({ days }) => {
            return `${days} ${days > 1 ? 'days ' : 'day '}`;
          })
          .otherwise(() => '');
      })
      .otherwise(() => '');

    const targetStr = match(rule)
      .with({ date: P.not(P.nullish) }, ({ date }) =>
        DateUtils.formatDateToMonthDay(date, { showYearIfPast: true, timeZone: currentUser?.timeZone }),
      )
      .with(
        { sourceType: DateFormFieldConstraintsSourceType.FormFieldValue, targetDateWidgetGroupId: P.not(P.nullish) },
        ({ targetDateWidgetGroupId }) =>
          dateWidgets.find(widget => widget.header.group.id === targetDateWidgetGroupId)?.label ?? '',
      )
      .with({ sourceType: DateFormFieldConstraintsSourceType.ChecklistStartDate }, () => 'Workflow run start date')
      .with({ sourceType: DateFormFieldConstraintsSourceType.ChecklistDueDate }, () => 'Workflow run due date')
      .with({ sourceType: DateFormFieldConstraintsSourceType.TaskDueDate }, () => 'Task due date')
      .otherwise(() => '');
    return `Date must be ${offsetStr}${rule.direction} ${targetStr}`;
  };

  const [constraints, setConstraints] = React.useState<DateFieldConstraint[]>(() => getRulesFromConstraints());

  return {
    constraints,
    setConstraints,
    getConstraintDefinitionString,
  };
};
