import { OnboardingSettingsKey } from 'components/user/user-constants';
import * as React from 'react';
import { SHOW_WORKFLOW_SETUP_MODAL_FOR } from 'components/focus-bar/workflow/right-button-group';
import { useOnboardingSettings } from 'pages/templates/_id/components/template-settings-modal/use-onboarding-settings';
import { useTemplateSettingsModalContext } from 'pages/templates/_id/components/template-settings-modal/template-settings-modal-context';

/** Display workflow setup modal when creating a new workflow. */
export function useDisplayWorkflowSetupModal() {
  const { onboardingSettingsQuery } = useOnboardingSettings();
  const shouldDisableWizard = onboardingSettingsQuery.data?.[OnboardingSettingsKey.DISABLE_WORKFLOW_SETUP_WIZARD];
  const { openOnboarding, templateId } = useTemplateSettingsModalContext();

  React.useEffect(() => {
    if (
      !onboardingSettingsQuery.isLoading &&
      !shouldDisableWizard &&
      localStorage.getItem(SHOW_WORKFLOW_SETUP_MODAL_FOR) === templateId
    ) {
      openOnboarding();
      localStorage.removeItem(SHOW_WORKFLOW_SETUP_MODAL_FOR);
    }
  }, [templateId, openOnboarding, shouldDisableWizard, onboardingSettingsQuery.isLoading]);
}
