import { Runnable } from '@process-street/subgrade/conditional-logic';
import { trace } from 'components/trace';
import {
  ContainsCondition,
  DoesNotContainCondition,
  EndsWithCondition,
  HasAnyValueCondition,
  HasNoValueCondition,
  IsCondition,
  IsGreaterThanCondition,
  IsLessThanCondition,
  IsNotCondition,
  StartsWithCondition,
} from '../condition';
import { TaskVisibilityRule } from './task-visibility-rule';
import {
  Checklist,
  ChecklistState,
  ChecklistWidget,
  FormFieldValue,
  FormFieldValueWithWidget,
  FormFieldWidget,
  Task,
  TaskTemplate,
  TaskWithTaskTemplate,
  Widget,
} from '@process-street/subgrade/process';
import { Muid } from '@process-street/subgrade/core';
import { match, P } from 'ts-pattern';
import cloneDeep from 'lodash/cloneDeep';
import { TaskStatusIsCondition } from '../condition/task-status-is-condition';
import { TimeBasedConditionEvaluator } from 'directives/rules/condition/time-based-condition-evaluator';

const logger = trace({ name: 'ChecklistRulesEngineService' });

/**
 * Just a small convenience method to convert list of variables into an object that will be used in a rule
 * This method runs some minimal validations
 */
function buildTaskVisibilityRule<Operator extends Runnable.ChecklistRuleDefinitionOperator>({
  operator: _, // used for type inference on optional widget
  ...rest
}: Runnable.TaskVisibilityRule<Operator> & {
  operator: Operator;
}): Runnable.TaskVisibilityRule<Operator> {
  return rest as unknown as Runnable.TaskVisibilityRule<Operator>;
}

/**
 * Convenience method to build checklist state object for given checklist and list of tasks
 *
 * @param checklist
 * @param tasks
 * @param checklistWidgets
 * @return object
 */
const buildChecklistStateObject = (
  checklist: Checklist,
  tasks: TaskWithTaskTemplate[],
  checklistWidgets: ChecklistWidget[],
): ChecklistState => {
  const taskStates = tasks.map(t => ({
    task: t, // FIXME: This should be a copy but I'm afraid to change it and break something else
    taskTemplateGroupId: t.taskTemplate.group.id,
    updated: false,
  }));

  const checklistWidgetStates = checklistWidgets.map(cw => ({
    checklistWidget: cw,
    widgetGroupId: cw.groupId,
    updated: false,
  }));

  return {
    checklist,
    taskStates,
    checklistWidgetStates,

    formFieldValueStates: [],
  };
};

const resetChecklistState = (checklistState: ChecklistState, taskTemplates: TaskTemplate[], widgets: Widget[]) => {
  const taskTemplateHiddenByDefaultMap = taskTemplates.reduce((map, taskTemplate) => {
    map[taskTemplate.group.id] = taskTemplate.hiddenByDefault;
    return map;
  }, {} as Record<Muid, boolean>);

  const widgetHiddenByDefaultMap = widgets.reduce((map, widget) => {
    map[widget.header.group.id] = widget.header.hiddenByDefault;
    return map;
  }, {} as Record<Muid, boolean>);

  // we need to reset the visibility status of the tasks
  checklistState.taskStates.forEach(ts => {
    const hidden = !!taskTemplateHiddenByDefaultMap[ts.taskTemplateGroupId];
    ts.task = { ...ts.task, hidden };
  });

  checklistState.checklistWidgetStates.forEach(cws => {
    const hidden = !!widgetHiddenByDefaultMap[cws.widgetGroupId];
    cws.checklistWidget = { ...cws.checklistWidget, hidden };
  });

  return checklistState;
};

/**
 * Executes all the rules defined by the array of taskVisibilityRules
 *
 * @param taskVisibilityRules List of task visibility rule data objects
 * @param checklistState Starting point of checklist state from which the engine should be executed
 * @return Modified checklist state as a result of applying all the given rules
 */
const execute = (taskVisibilityRules: Runnable.TaskVisibilityRule[], checklistState: ChecklistState) => {
  // Let's make a copy to make sure that we are not relying on the user knowledge
  // and not change any of their data
  let stateCopy = cloneDeep(checklistState);

  taskVisibilityRules.forEach(rule => {
    stateCopy = TaskVisibilityRule.execute(rule, stateCopy);
  });

  return stateCopy;
};

const buildTaskVisibilityRules = ({
  ruleDefinitions,
  formFieldWidgets,
  formFieldValues,
  tasks,
  checklist,
}: {
  ruleDefinitions: Runnable.ChecklistRuleDefinition[];
  formFieldWidgets: FormFieldWidget[];
  formFieldValues: FormFieldValueWithWidget[];
  tasks: Task[];
  checklist?: Checklist;
}) => {
  const taskVisibilityRules: Runnable.TaskVisibilityRule[] = [];

  const widgetMap: Record<Muid, FormFieldWidget> = {};
  formFieldWidgets.forEach(w => {
    widgetMap[w.header.group.id] = w;
  });

  const formFieldValueMap: Record<Muid, FormFieldValue> = {};
  formFieldValues.forEach(ffv => {
    formFieldValueMap[ffv.formFieldWidget.header.group.id] = ffv;
  });

  const taskMap: Record<Muid, Task> = tasks.reduce((map, task) => {
    match(task.taskTemplate as TaskTemplate)
      .with({ group: { id: P.string } }, taskTemplate => {
        map[taskTemplate.group.id] = task;
      })
      .otherwise(() => {
        logger.error('`task.taskTemplate.group.id` is missing');
      });

    return map;
  }, {} as Record<Muid, Task>);

  ruleDefinitions.forEach(rule => {
    match(rule)
      .when(Runnable.isRuleLogical, rule => {
        // logical rule
        const evaluationResult = evaluateLogicalRule(rule, widgetMap, formFieldValueMap, taskMap, checklist);
        // treat logical rule as "precalculated"
        // we always "buildTaskVisibilityRules" before evaluating rules
        // so these values are never stale
        taskVisibilityRules.push(
          buildTaskVisibilityRule({
            operand: evaluationResult, // will evaluate to true or false based on the result
            targetTaskTemplateGroupIds: rule.taskTemplateGroupIds,
            targetWidgetGroupIds: rule.widgetGroupIds,
            hidden: rule.hidden,
            operator: Runnable.ChecklistRuleDefinitionLogicalOperator.Logical,
          }),
        );
      })
      .when(Runnable.isFormFieldOrPrecalculatedRule, rule => {
        const formFieldValue = formFieldValueMap[rule.formFieldWidgetGroupId];
        const formFieldWidget = widgetMap[rule.formFieldWidgetGroupId];
        const baseRule = {
          formFieldValue,
          formFieldWidget,
          targetTaskTemplateGroupIds: rule.taskTemplateGroupIds,
          targetWidgetGroupIds: rule.widgetGroupIds,
          hidden: rule.hidden,
        };
        match(rule)
          .when(Runnable.isPrecalculatedRule, rule => {
            taskVisibilityRules.push(
              buildTaskVisibilityRule({
                ...baseRule,
                operator: rule.operator,
                operand: rule.operand?.value,
              }),
            );
          })
          .when(
            rule => {
              return formFieldWidget && Runnable.isFormFieldRule(rule);
            },
            rule => {
              taskVisibilityRules.push(
                buildTaskVisibilityRule({
                  ...baseRule,
                  operator: rule.operator,
                  condition: resolveConditionObject(rule.operator as NotPrecalculated),
                  operand: rule.operand?.value,
                }),
              );
            },
          )
          .otherwise(() => {
            logger.error('missing form field widget for the rule:', rule);
          });
      })
      .when(Runnable.isTaskOrPrecalculatedRule, rule => {
        const task = taskMap[rule.taskTemplateGroupId];

        const baseRule = {
          task,
          targetTaskTemplateGroupIds: rule.taskTemplateGroupIds,
          targetWidgetGroupIds: rule.widgetGroupIds,
          hidden: rule.hidden,
        };

        match(rule)
          .when(Runnable.isTaskPrecalculatedRule, rule => {
            taskVisibilityRules.push(
              buildTaskVisibilityRule({
                ...baseRule,
                operator: rule.operator,
                operand: rule.operand?.value,
              }),
            );
          })
          .when(Runnable.isTaskNotPrecalculatedRule, rule => {
            if (!task) return;

            taskVisibilityRules.push(
              buildTaskVisibilityRule({
                ...baseRule,
                operator: rule.operator,
                condition: resolveTaskConditionObject(rule.operator),
                operand: rule.operand?.value,
              }),
            );
          })
          .otherwise(() => {});
      })
      .run();
  });

  return taskVisibilityRules;
};

type NotPrecalculated = Exclude<
  Runnable.ChecklistRuleDefinitionFormFieldOperator,
  Runnable.ChecklistRuleDefinitionFormFieldOperator.Precalculated
>;

type TaskNotPrecalculated = Exclude<
  Runnable.ChecklistRuleDefinitionTaskOperator,
  Runnable.ChecklistRuleDefinitionTaskOperator.TaskPrecalculated
>;

/**
 * This is public for testing only. Technically should not be exposed,
 * but really no harm done by making it public.
 *
 * @param operator
 * @return {*}
 */
const resolveConditionObject = (operator: NotPrecalculated) => {
  return match(operator)
    .with(Runnable.ChecklistRuleDefinitionFormFieldOperator.Is, () => IsCondition)
    .with(Runnable.ChecklistRuleDefinitionFormFieldOperator.IsNot, () => IsNotCondition)
    .with(Runnable.ChecklistRuleDefinitionFormFieldOperator.IsGreaterThan, () => IsGreaterThanCondition)
    .with(Runnable.ChecklistRuleDefinitionFormFieldOperator.IsLessThan, () => IsLessThanCondition)
    .with(Runnable.ChecklistRuleDefinitionFormFieldOperator.StartsWith, () => StartsWithCondition)
    .with(Runnable.ChecklistRuleDefinitionFormFieldOperator.EndsWith, () => EndsWithCondition)
    .with(Runnable.ChecklistRuleDefinitionFormFieldOperator.Contains, () => ContainsCondition)
    .with(Runnable.ChecklistRuleDefinitionFormFieldOperator.DoesNotContain, () => DoesNotContainCondition)
    .with(Runnable.ChecklistRuleDefinitionFormFieldOperator.HasNoValue, () => HasNoValueCondition)
    .with(Runnable.ChecklistRuleDefinitionFormFieldOperator.HasAnyValue, () => HasAnyValueCondition)
    .exhaustive();
};

const resolveTaskConditionObject = (operator: TaskNotPrecalculated) => {
  return match(operator)
    .with(Runnable.ChecklistRuleDefinitionTaskOperator.TaskStatusIs, () => TaskStatusIsCondition)
    .exhaustive();
};

/**
 * Evaluates a logical rule.
 * @param rule {LogicalChecklistRuleDefinition}
 * @param formFieldWidgetMap {Record<string, FormFieldWidget>}
 * @param formFieldValueMap {Record<string, FormFieldValue>}
 * @returns {boolean}
 */
const evaluateLogicalRule = (
  rule: Runnable.LogicalChecklistRuleDefinition,
  formFieldWidgetMap: Record<Muid, FormFieldWidget>,
  formFieldValueMap: Record<Muid, FormFieldValue>,
  taskMap: Record<Muid, Task>,
  checklist?: Checklist,
) => {
  return evaluateCondition(rule.operand.data);

  /**
   * Evaluates a single condition.
   * @param {Condition} condition
   * @returns {boolean}
   */
  function evaluateCondition<Condition extends Runnable.Condition>(condition: Condition): boolean {
    return match<Runnable.Condition>(condition)
      .when(Runnable.isConditionLogicalOr, ({ conditions }) => conditions.some(c => evaluateCondition(c)))
      .when(Runnable.isConditionLogicalAnd, ({ conditions }) => conditions.every(c => evaluateCondition(c)))
      .when(Runnable.isConditionPrecalculated, ({ operandValue }) => operandValue.value)
      .when(Runnable.isNotPrecalculated, condition => {
        // form field condition
        const widget = formFieldWidgetMap[condition.formFieldWidgetGroupId];
        const formFieldValue = formFieldValueMap[condition.formFieldWidgetGroupId];
        const conditionObject = resolveConditionObject(condition.operator);
        return conditionObject.evaluate(formFieldValue, widget, condition.operandValue.value ?? undefined);
      })
      .when(Runnable.isTaskConditionPrecalculated, ({ operand }) => operand.value)
      .when(Runnable.isTaskNotPrecalculated, condition => {
        const task = taskMap[condition.taskTemplateGroupId];
        const conditionObject = resolveTaskConditionObject(condition.operator);

        return conditionObject.evaluate(task, condition.operand.value);
      })
      .when(Runnable.isTimeBasedCondition, condition =>
        checklist ? TimeBasedConditionEvaluator.evaluate(checklist, condition) : false,
      )
      .otherwise(() => {
        throw new Error(`unsupported condition: ${JSON.stringify(condition)}`);
      });
  }
};

export const ChecklistRulesEngineService = {
  buildTaskVisibilityRule,
  buildTaskVisibilityRules,
  buildChecklistStateObject,
  resetChecklistState,
  execute,
  resolveConditionObject,
  evaluateLogicalRule,
};
