export class PlanApi {
  constructor(Plans) {
    'ngInject';

    this.Plans = Plans;
  }

  /**
   * Gets a single plan object by id
   */
  getById = planId => this.Plans.getById({ id: planId }).$promise;

  /**
   * Gets all plans
   */
  getAll = () => this.Plans.getAll().$promise;
}
