import * as React from 'react';
import {
  Box,
  Icon,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from 'components/design/next';

export const DigitsInfoPopover = () => (
  <Popover trigger="hover" placement="top" variant="tooltip-dark">
    <PopoverTrigger>
      {/* Box for forwardRef behavior */}
      <Box>
        <Icon size="4" icon="info-circle" variant="far" color="gray.500" />
      </Box>
    </PopoverTrigger>
    <PopoverContent w="auto">
      <PopoverArrow />
      <PopoverBody w="96">
        <Text variant="-2">
          Number of physical digits present in the form field{' '}
          <Text variant="-2" as="em">
            ie. 10 digits for US phone numbers
          </Text>
          . More information{' '}
          <Link href="https://www.process.st/help/docs/form-fields/" isExternal>
            here
          </Link>
          .
        </Text>
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

export const ValueInfoPopover = () => (
  <Popover trigger="hover" placement="top" variant="tooltip-dark">
    <PopoverTrigger>
      {/* Box for forwardRef behavior */}
      <Box>
        <Icon size="4" icon="info-circle" variant="far" color="gray.500" />
      </Box>
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
      <PopoverBody maxW="74">
        <Text variant="-2">
          Total amount within the form field{' '}
          <Text variant="-2" as="em">
            ie. Rate your experience from 1 to 10
          </Text>
          . More information{' '}
          <Link href="https://www.process.st/help/docs/form-fields/" isExternal>
            here
          </Link>
          .
        </Text>
      </PopoverBody>
    </PopoverContent>
  </Popover>
);
