import React from 'react';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import styles from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers/FormFieldValueRenderer/FormFieldValueRenderer.module.scss';
import { TableFormFieldConfig } from '@process-street/subgrade/process/configs/table-form-field-config';
import { FormFieldDto } from '@process-street/subgrade/dashboard';

interface TableFieldRendererProps {
  value?: FormFieldDto;
}

export const TableFieldRenderer: React.FC<TableFieldRendererProps> = ({ value }) => {
  if (!value) {
    return null;
  }

  const config = value.config as TableFormFieldConfig.Config;
  const fieldValue = value.fieldValue as TableFieldValue.FieldValue;
  const colIds = config.columnDefs.map(columnDef => columnDef.id);
  const csv = fieldValue.rows.map(row => colIds.map(colId => row.cells[colId]?.value ?? '').join(',')).join(' | ');

  return <div className={styles.trimmedLabel}>{csv}</div>;
};
