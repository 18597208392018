import { FinishUploadForCreateOneOffTaskRequest } from '@process-street/subgrade/process';
import {
  MAX_FILE_SIZE,
  readFile,
  SIZE_TOO_LARGE,
} from 'features/comments/components/common/attachment/use-upload-attachment-comment';
import { useUploadToS3Mutation } from 'features/cover-icon/query-builder';
import { FinishUploadAttachmentMutation, UploadUrlAttachmentMutation } from 'features/one-off-tasks/query-builder';
import React from 'react';
import sum from 'lodash/sum';
import { DropzoneState, useDropzone } from 'react-dropzone';
import { AxiosError } from 'axios';

export type AttachmentToFinishUpload = Omit<FinishUploadForCreateOneOffTaskRequest, 'taskId'> & {
  url: string;
  fileExtension: string;
  size: number | undefined;
};
type CreateOneOffTaskUploadAttachmentsProps = {
  setProgress: (progress: number | undefined) => void;
};
export const useCreateOneOffTaskUploadAttachments = ({
  setProgress,
}: CreateOneOffTaskUploadAttachmentsProps): {
  attachments: AttachmentToFinishUpload[];
  createAttachments: (taskId: string) => Promise<void>;
  removeAttachment: (key: string) => void;
  dropzoneState: DropzoneState;
  uploadError: AxiosError | null;
} => {
  const [attachmentsToFinishUpload, setAttachmentsToFinishUpload] = React.useState<AttachmentToFinishUpload[]>([]);
  const uploadUrlMutation = UploadUrlAttachmentMutation.useMutation();
  const uploadToS3Mutation = useUploadToS3Mutation();
  const finishUploadMutation = FinishUploadAttachmentMutation.useMutation();

  const uploadError = uploadUrlMutation.error ?? uploadToS3Mutation.error ?? finishUploadMutation.error;

  React.useEffect(() => {
    if (uploadError) {
      setProgress(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query error result
  }, [uploadError]);

  const fileProgressesRef = React.useRef(new Map<File, number>());
  const resetFileProgresses = () => (fileProgressesRef.current = new Map());

  const setProgressForFile = (file: File, progress: number) => {
    fileProgressesRef.current.set(file, progress);
    const files = Array.from(fileProgressesRef.current.keys());
    const totalFileSize = sum(files.map(file => file.size));
    const relativeProgresses = Array.from(fileProgressesRef.current.entries()).map(
      ([file, progress]) => (file.size / totalFileSize) * progress,
    );
    const totalProgress = sum(relativeProgresses);
    setProgress(totalProgress);
  };

  const uploadSingleFileToS3 = async (file: File) => {
    setProgressForFile(file, 0);
    const mimeType = file.type || 'application/octet-stream';

    const { url, key } = await uploadUrlMutation.mutateAsync({
      fileName: file.name,
      mimeType,
    });

    const fileBuffer = await readFile(file);

    await uploadToS3Mutation.mutateAsync({
      file,
      url,
      data: fileBuffer,
      onProgress: (progress: number) => setProgressForFile(file, progress),
    });

    setProgressForFile(file, 100);
    setAttachmentsToFinishUpload(prev => [
      ...prev,
      {
        url,
        key,
        originalFilename: file.name,
        contentType: mimeType,
        fileExtension: file.name.split('.').pop() || '',
        size: file.size,
      },
    ]);
  };

  const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
    resetFileProgresses();
    await Promise.all(acceptedFiles.map(uploadSingleFileToS3));
    resetFileProgresses();
    setProgress(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
  }, []);

  const dropzoneState = useDropzone({
    onDrop,
    noDrag: true,
    validator: file => {
      if (file.size > MAX_FILE_SIZE) {
        return {
          code: SIZE_TOO_LARGE,
          message: `Uploaded files must be under ${MAX_FILE_SIZE} MB.`,
        };
      }
      return null;
    },
  });

  const createAttachments = async (taskId: string): Promise<void> => {
    await Promise.all(
      attachmentsToFinishUpload.map(async item => {
        await finishUploadMutation.mutateAsync({
          taskId,
          key: item.key,
          originalFilename: item.originalFilename,
          contentType: item.contentType,
        });
      }),
    );
  };

  const removeAttachment = (key: string): void => {
    setAttachmentsToFinishUpload(prev => [...prev.filter(attachment => attachment.key !== key)]);
  };

  return { attachments: attachmentsToFinishUpload, createAttachments, removeAttachment, dropzoneState, uploadError };
};
