import { AutoformatPlugin, AutoformatRule } from '@udecode/plate-autoformat';
import {
  DOMHandlers,
  createPlateEditor,
  CreatePlateEditorOptions,
  createPluginFactory,
  createPlugins,
  createTEditor,
  Decorate,
  DecorateEntry,
  DOMHandler,
  EDescendant,
  EElement,
  EElementEntry,
  EElementOrText,
  EMarks,
  ENode,
  ENodeEntry,
  EText,
  ETextEntry,
  getTEditor,
  InjectComponent,
  InjectProps,
  KeyboardHandler,
  NoInfer,
  OnChange,
  OverrideByKey,
  PlateEditor,
  PlateId,
  PlatePlugin,
  PlatePluginComponent,
  PlatePluginInsertData,
  PlatePluginProps,
  PlateProps,
  PluginOptions,
  SerializeHtml,
  TElement,
  TNodeEntry,
  TReactEditor,
  useEditorRef,
  useEditorState,
  usePlateActions,
  usePlateEditorRef,
  usePlateEditorState,
  usePlateSelectors,
  usePlateStates,
  WithOverride,
  TInsertNodeOperation,
  TInsertTextOperation,
  TMergeNodeOperation,
  TMoveNodeOperation,
  TRemoveNodeOperation,
  TRemoveTextOperation,
  TSetNodeOperation,
  TSplitNodeOperation,
  TOperation,
  PlateRenderLeafProps,
} from '@udecode/plate-common';
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading';
import { ELEMENT_LINK } from '@udecode/plate-link';
import { ELEMENT_LI, ELEMENT_UL } from '@udecode/plate-list';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { ELEMENT_TABLE, ELEMENT_TD, ELEMENT_TR, ELEMENT_TH, TTableElement } from '@udecode/plate-table';
import { TText } from '@udecode/slate';
import { Muid } from '@process-street/subgrade/core';
import { Widget, WidgetType } from '@process-street/subgrade/process';
import { TRenderElementProps } from '@udecode/plate-core';

/**
 * Block props
 */

export interface PagesBlockElement extends TElement {
  id?: PlateId;
}

export interface PagesSoftBreakElement extends TElement {
  type: 'break';
}

export interface PagesFragmentElement extends TElement {
  type: 'fragment';
}

export interface PagesUnorderedListElement extends PagesBlockElement {
  type: typeof ELEMENT_UL;
  children: PagesListItemElement[];
}

export interface PagesListItemElement extends PagesBlockElement {
  type: typeof ELEMENT_LI;
  depth: number;
  children: PagesInlineChildren;
}

export interface PagesFileElement extends PagesBlockElement {
  type: 'ps-file-widget';
}

export interface PagesImageElement extends PagesBlockElement {
  type: 'ps-image-widget';
}

export interface PagesVideoElement extends PagesBlockElement {
  type: 'ps-video-widget';
}

export interface PagesEmbedElement extends PagesBlockElement {
  type: 'ps-embed-widget';
}

export interface PagesCrossLinkElement extends PagesBlockElement {
  type: 'ps-cross-link-widget';
}

export interface PagesTableWidgetElement extends PagesBlockElement {
  type: 'ps-table-widget';
}

export interface PagesTableBodyElement extends PagesBlockElement {
  type: 'tbody';
  children: PagesTableRowElement[];
}

export interface PagesTableHeadElement extends PagesBlockElement {
  type: 'thead';
  children: PagesTableRowElement[];
}

export type PagesTableSectionElement = PagesTableBodyElement | PagesTableHeadElement;

export interface PagesLinkElement extends PagesBlockElement {
  type: typeof ELEMENT_LINK;
  href: string;
  target: string;
  rel: 'nofollow';
  children: PagesRichText[];
}

export type PagesCustomColor = string;

/**
 * Text
 */

export type EmptyText = {
  text: '';
};

export type PlainText = {
  text: string;
};

export interface PagesRichText extends TText {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
  placeholder?: string;
  color?: PagesCustomColor;
  background?: PagesCustomColor;
  selected?: boolean;
}
export type PagesRichTextProperty = keyof { [k in keyof PagesRichText as string extends k ? never : k]: k };

/**
 * Inline Elements
 */

export type PagesInlineElement = PagesLinkElement | PagesSoftBreakElement;
export type PagesInlineDescendant = PagesInlineElement | PagesRichText;
export type PagesInlineChildren = PagesInlineDescendant[];

/**
 * Blocks
 */

export interface PagesParagraphElement extends PagesBlockElement {
  type: typeof ELEMENT_PARAGRAPH;
  children: PagesInlineChildren;
}

export interface PagesH1Element extends PagesBlockElement {
  type: typeof ELEMENT_H1;
  children: PagesInlineChildren;
}

export interface PagesH2Element extends PagesBlockElement {
  type: typeof ELEMENT_H2;
  children: PagesInlineChildren;
}

export interface PagesH3Element extends PagesBlockElement {
  type: typeof ELEMENT_H3;
  children: PagesInlineChildren;
}

export interface PagesTableElement extends TTableElement, PagesBlockElement {
  type: typeof ELEMENT_TABLE;
  children: PagesTableSectionElement[];
}

export interface PagesTableRowElement extends PagesBlockElement {
  type: typeof ELEMENT_TR;
  children: PagesTableCellElement[] | PagesTableHeaderElement[];
}

export interface PagesTableHeaderElement extends PagesBlockElement {
  type: typeof ELEMENT_TH;
  children: PagesNestableBlock[];
}
export interface PagesTableDataElement extends PagesBlockElement {
  type: typeof ELEMENT_TD;
  children: PagesNestableBlock[];
}

export type PagesTableCellElement = PagesTableDataElement | PagesTableHeaderElement;

export type PagesNestableBlock = PagesParagraphElement;

export type PagesBlock = Exclude<PagesElement, PagesInlineElement>;
export type PagesBlockEntry = TNodeEntry<PagesBlock>;

export type PagesRootBlock =
  | PagesParagraphElement
  | PagesH1Element
  | PagesH2Element
  | PagesH3Element
  | PagesTableElement
  | PagesFileElement
  | PagesImageElement
  | PagesVideoElement
  | PagesEmbedElement
  | PagesCrossLinkElement
  | PagesTableWidgetElement
  | PagesTableHeadElement
  | PagesTableBodyElement
  | PagesTableHeaderElement
  | PagesFragmentElement
  | PagesLinkElement;

export type OptimisticWidget = {
  header: {
    id: Muid;
    orderTree: string;
    taskTemplate: {
      id: Muid;
    };
    type: WidgetType;
  };
};

export type PagesWidgetElement<T extends Widget | OptimisticWidget = Widget> = PagesRootBlock & {
  widget: T;
};

export type PagesValue = PagesWidgetElement[];

/**
 * Editor types
 */

export type PagesEditor = PlateEditor<PagesValue>;
export type PagesReactEditor = TReactEditor<PagesValue>;
export type PagesNode = ENode<PagesValue>;
export type PagesNodeEntry = ENodeEntry<PagesValue>;
export type PagesElement = EElement<PagesValue>;
export type PagesElementEntry = EElementEntry<PagesValue>;
export type PagesText = EText<PagesValue>;
export type PagesTextEntry = ETextEntry<PagesValue>;
export type PagesElementOrText = EElementOrText<PagesValue>;
export type PagesDescendant = EDescendant<PagesValue>;
export type PagesMarks = EMarks<PagesValue>;
export type PagesMark = keyof PagesMarks;
export type PagesElementType = PagesRootBlock['type'] | PagesWidgetElement['type'];

/**
 * Plate types
 */

export type PagesDecorate<P = PluginOptions> = Decorate<P, PagesValue, PagesEditor>;
export type PagesDecorateEntry = DecorateEntry<PagesValue>;
export type PagesDOMHandler<P = PluginOptions> = DOMHandler<P, PagesValue, PagesEditor>;
export type PagesInjectComponent = InjectComponent<PagesValue>;
export type PagesInjectProps = InjectProps<PagesValue>;
export type PagesKeyboardHandler<P = PluginOptions> = KeyboardHandler<P, PagesValue, PagesEditor>;
export type PagesOnChange<P = PluginOptions> = OnChange<P, PagesValue, PagesEditor>;
export type PagesOverrideByKey = OverrideByKey<PagesValue, PagesEditor>;
export type PagesPlatePlugin<P = PluginOptions> = PlatePlugin<P, PagesValue, PagesEditor>;
export type PagesPlatePluginInsertData = PlatePluginInsertData<PagesValue>;
export type PagesPlatePluginProps = PlatePluginProps<PagesValue>;
export type PagesPlateProps = PlateProps<PagesValue, PagesEditor>;
export type PagesSerializeHtml = SerializeHtml<PagesValue>;
export type PagesWithOverride<P = PluginOptions> = WithOverride<P, PagesValue, PagesEditor>;
export type PagesInsertNodeOperation = TInsertNodeOperation<PagesDescendant>;
export type PagesInsertTextOperation = TInsertTextOperation;
export type PagesMergeNodeOperation = TMergeNodeOperation;
export type PagesRemoveNodeOperation = TRemoveNodeOperation<PagesDescendant>;
export type PagesRemoveTextOperation = TRemoveTextOperation;
export type PagesSetNodeOperation = TSetNodeOperation;
export type PagesSplitNodeOperation = TSplitNodeOperation;
export type PagesMoveNodeOperation = TMoveNodeOperation;
export type PagesOperation = TOperation;
export type PagesDOMHandlers<P = PluginOptions> = DOMHandlers<P, PagesValue, PagesEditor>;

/**
 * Plate store, Slate context
 */

export const getPagesEditor = (editor: PagesEditor) => getTEditor<PagesValue, PagesEditor>(editor);
export const usePagesEditorRef = () => useEditorRef<PagesValue, PagesEditor>();
export const usePagesEditorState = () => useEditorState<PagesValue, PagesEditor>();
export const usePagesPlateEditorRef = (id?: PlateId) => usePlateEditorRef<PagesValue, PagesEditor>(id);
export const usePagesPlateEditorState = (id?: PlateId) => usePlateEditorState<PagesValue, PagesEditor>(id);
export const usePagesPlateSelectors = (id?: PlateId) => usePlateSelectors<PagesValue, PagesEditor>(id);
export const usePagesPlateActions = (id?: PlateId) => usePlateActions<PagesValue, PagesEditor>(id);
export const usePagesPlateStates = (id?: PlateId) => usePlateStates<PagesValue, PagesEditor>(id);

/**
 * Utils
 */
export const createPagesEditor = () => createTEditor() as PagesEditor;
export const createPagesPlateEditor = (options: CreatePlateEditorOptions<PagesValue, PagesEditor> = {}) =>
  createPlateEditor<PagesValue, PagesEditor>(options);
export const createPagesPluginFactory = <P = PluginOptions>(
  defaultPlugin: PlatePlugin<NoInfer<P>, PagesValue, PagesEditor>,
) => createPluginFactory(defaultPlugin);
export const createPagesPlugins = (
  plugins: PagesPlatePlugin[],
  options?: {
    components?: Record<string, PlatePluginComponent>;
    overrideByKey?: PagesOverrideByKey;
  },
) => createPlugins<PagesValue, PagesEditor>(plugins, options);

export type PagesAutoformatRule = AutoformatRule<PagesValue, PagesEditor>;
export type PagesAutoformatPlugin = AutoformatPlugin<PagesValue, PagesEditor>;

export type PagesRenderElementProps<Element extends PagesElement = PagesElement> = TRenderElementProps<
  PagesValue,
  Element
>;
export type PagesRenderLeafProps = PlateRenderLeafProps<PagesValue, PagesRichText>;
