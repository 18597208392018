import { Textarea } from '@chakra-ui/react';
import { useInjector } from 'components/injection-provider';
import { useGetChecklistQuery } from 'features/checklists/query-builder';
import { MaskedInput, MaskedInputParsers } from 'features/widgets/components/masked-input';
import * as React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { escapeHtml } from '@process-street/subgrade/util';

export type ChecklistNameInputProps = {
  onChange: (value: string) => void;
  value: string;
  onBlur: (value: string) => void;
  isDisabled: boolean;
};

const maskedInputParser = MaskedInputParsers.makeParser('merge-tags');

export const ChecklistNameInput: React.FC<React.PropsWithChildren<ChecklistNameInputProps>> = ({
  onChange,
  value: externalValue,
  onBlur,
  isDisabled,
}) => {
  const { $stateParams } = useInjector('$stateParams');
  const checklistId = $stateParams.id;
  const [value, setValue] = React.useState(externalValue);
  const isFocusedRef = React.useRef<boolean>(false);

  const checklistQuery = useGetChecklistQuery({
    checklistId,
  });

  const html = React.useMemo(
    () => (checklistQuery.data?.hasDefaultName ? maskedInputParser(value) : escapeHtml(value)),
    [value, checklistQuery.data],
  );

  const onChangeDebounced = useDebouncedCallback(onChange, 1000);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setValue(value);
    onChangeDebounced(value);
  };

  React.useEffect(() => {
    if (externalValue !== value && !isFocusedRef.current) {
      setValue(externalValue);
    }
    // We don't want this effect to be triggered by `value` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalValue]);

  return (
    <MaskedInput minH="unset" fontSize="lg" fontWeight="500" html={html}>
      <Textarea
        placeholder="Type workflow name here"
        minH="unset"
        rows={1}
        value={value}
        onChange={handleChange}
        onFocus={() => {
          isFocusedRef.current = true;
        }}
        onBlur={() => {
          isFocusedRef.current = false;
          onBlur(value);
        }}
        isDisabled={isDisabled}
        _hover={{
          borderColor: 'transparent',
        }}
      ></Textarea>
    </MaskedInput>
  );
};
