import { Folder } from '@process-street/subgrade/process/folder-model';
import {
  Icon,
  IconButton,
  Link,
  LinkProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemProps,
  MenuList,
  ToastId,
  useDisclosure,
  useToast,
} from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { MoveToFolderModal } from 'app/components/move-to-folder/move-to-folder-modal';
import { getAncestorFolders, isPrivateRootFolder, toSlugPath } from 'features/folder/lib';
import { GetAllFoldersByOrganizationId, useDeleteFolderMutation } from 'features/folder/query-builder';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { FOLDER_DELETE_BY_ID, FOLDER_SET } from 'reducers/folder/folder.actions';
import { SessionSelector } from 'reducers/session/session.selectors';
import { canAccess, Feature } from 'services/features/features';
import { useFoldersQuery } from '../query';
import { DeleteAlertDialog } from './delete-alert-dialog';
import { PERMISSION_STATS_KEY } from 'components/permissions/services/query';
import { NewFolderModal } from 'features/new-menu/new-folder-modal';
import { useGetAllConsolidatedFolderPermissionsQuery } from 'features/permissions/query-builder';
import { PureNgLink } from 'features/app/components/ng-link';
import { FolderErrorCodes } from '@process-street/subgrade/core';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export type Props = { folder: Folder; onClose?: () => void; menuButton?: React.ReactNode };

export const FolderRowMenu: React.FC<React.PropsWithChildren<Props>> = ({
  folder,
  onClose,
  children: otherMenuItems,
  menuButton,
}) => {
  React.Children.forEach(otherMenuItems, item => {
    if (React.isValidElement(item) && item.type === MenuItem) {
      return;
    }
    throw new Error('FolderRowMenu only accepts MenuItem children');
  });

  const { $state } = useInjector('$state');
  const toast = useToast();
  const folderId = folder.id;
  const isNotRootFolder = Boolean(folder.parentFolder);

  // FOLDER DATA
  const { data: folders = [] } = useFoldersQuery();
  const foldersMap = React.useMemo(() => new Map(folders.map(f => [f.id, f])), [folders]);
  const ancestors = getAncestorFolders(folder, folders);
  const root = ancestors[ancestors.length - 1];
  const folderIsPrivate = isPrivateRootFolder(root);

  // PERMISSIONS
  const { data: { permissionMap } = {} } = useGetAllConsolidatedFolderPermissionsQuery({
    select: allFolderPermits => allFolderPermits.find(fp => fp.folderId === folder.id),
  });

  const selectedPlan = useSelector(SessionSelector.getCurrentPlan);
  const planId = selectedPlan?.id;
  const moveEnabled = canAccess(Feature.NESTED_FOLDERS, planId);
  const canMove = permissionMap?.folderUpdate && moveEnabled && isNotRootFolder;
  const canDelete = permissionMap?.folderDelete && isNotRootFolder;
  const canCreate = permissionMap?.folderCreate;
  const canRename = isNotRootFolder && permissionMap?.folderUpdate;
  const canShare = !folderIsPrivate && isNotRootFolder;

  // MOVE FOLDER
  const moveDisclosure = useDisclosure();
  const showMoveFolder = () => {
    moveDisclosure.onOpen();
  };
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const toastIdRef = React.useRef<ToastId>();
  const handleMove = React.useCallback(
    async (movedFolder: Folder) => {
      if (canMove) {
        const parentFolder = movedFolder.parentFolder?.id ? foldersMap.get(movedFolder.parentFolder.id) : undefined;
        toastIdRef.current = toast({
          status: 'success',
          title: `Folder moved `,
          description: parentFolder ? (
            <PureNgLink
              to="dashboard.type"
              params={{ type: 'folder', path: toSlugPath(parentFolder, ancestors) }}
              options={{ inherit: false }}
              $state={$state}
              textDecor="underline"
              onClick={() => toast.close(toastIdRef.current!)}
            >
              Go to {parentFolder.name}
            </PureNgLink>
          ) : null,
        });
        dispatch({ type: FOLDER_SET, payload: movedFolder });
        await queryClient.invalidateQueries(PERMISSION_STATS_KEY);
        await queryClient.invalidateQueries(GetAllFoldersByOrganizationId.getKey(movedFolder.organization.id));
      }
    },
    [canMove, foldersMap, toast, ancestors, $state, dispatch, queryClient],
  );

  // DELETE FOLDER
  const cancelDeleteRef = React.useRef<HTMLButtonElement>(null);
  const deleteDisclosure = useDisclosure();
  const { mutate, isLoading: deleting } = useDeleteFolderMutation({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Folder deleted',
      });
      queryClient.invalidateQueries(GetAllFoldersByOrganizationId.getKey(folder.organization.id));
      deleteDisclosure.onClose();
      dispatch({ type: FOLDER_DELETE_BY_ID, meta: { folderId } });
    },
    onError: e => {
      const isFolderDeleteWithChildrenError = e?.response?.data?.code === FolderErrorCodes.FolderContainsChildrenError;
      const description = isFolderDeleteWithChildrenError
        ? 'You cannot delete this folder until all folders and all workflows in it ' +
          '(including archived workflows) have been moved or deleted.'
        : DefaultErrorMessages.unexpectedErrorDescription;
      toast({
        status: 'error',
        title: 'Error deleting folder',
        description,
      });
    },
  });
  const deleteFolder = () => {
    deleteDisclosure.onOpen();
  };

  // CREATE NEW FOLDER
  const createDisclosure = useDisclosure();

  // HREFS
  const folderSettingsHref = $state.href('folderManage.tab', { id: folder.id, tab: 'properties' });
  const folderPermissionsHref = $state.href('folderManage.tab', { id: folder.id, tab: 'permissions' });

  return (
    <>
      <Menu isLazy={true} autoSelect={false} onClose={() => (onClose ? onClose() : null)}>
        {menuButton ?? (
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="sm"
            icon={<Icon icon="ellipsis-h" variant="far" size="3" color="gray.600" />}
            aria-label="manage folder"
            colorScheme="gray"
            color="gray.600"
            backgroundColor="white"
            borderWidth="thin"
            borderColor="gray.300"
            borderStyle="solid"
            fontWeight="normal"
            fontSize="xs"
          />
        )}

        <MenuList>
          {canShare ? (
            <MenuItemLink href={folderPermissionsHref} icon={<Icon icon="share-alt" size="4" variant="far" />}>
              Share
            </MenuItemLink>
          ) : null}

          {canRename ? (
            <MenuItemLink href={folderSettingsHref} icon={<Icon size="4" variant="far" icon="edit" />}>
              Rename
            </MenuItemLink>
          ) : null}

          {canMove ? (
            <MenuItem icon={<Icon icon="arrow-right" size="4" variant="far" />} onClick={showMoveFolder}>
              Move
            </MenuItem>
          ) : null}

          {canCreate ? (
            <MenuItem
              onClick={() => createDisclosure.onOpen()}
              icon={<Icon icon="folder-plus" size="4" variant="far" />}
            >
              New folder
            </MenuItem>
          ) : null}

          {otherMenuItems}

          {canDelete ? (
            <>
              <MenuDivider />
              <MenuItem
                isDisabled={deleting}
                onClick={deleteFolder}
                color="red.500"
                icon={<Icon size="4" variant="far" icon="trash-alt" color="red.500" />}
              >
                Delete
              </MenuItem>
            </>
          ) : null}
        </MenuList>
      </Menu>

      {folder.parentFolder ? (
        <MoveToFolderModal
          candidateType="folder"
          candidateId={folder.id}
          folderId={folder.parentFolder.id}
          isOpen={moveDisclosure.isOpen}
          onFolderMove={handleMove}
          onClose={moveDisclosure.onClose}
        />
      ) : null}

      <DeleteAlertDialog
        {...deleteDisclosure}
        leastDestructiveRef={cancelDeleteRef}
        folder={folder}
        onDelete={() => mutate({ folderId })}
      />

      <NewFolderModal
        selectedOrganizationId={folder.organization.id}
        currentFolderId={folder.id}
        {...createDisclosure}
      />
    </>
  );
};

export const MenuItemLink: React.FC<React.PropsWithChildren<MenuItemProps & LinkProps>> = props => {
  return <MenuItem as={Link} {...props} variant="noline" _focus={{ color: 'inherit', bgColor: 'gray.100' }} />;
};
