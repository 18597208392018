import * as React from 'react';
import {
  Box,
  Button,
  Grid,
  GridItem,
  GridProps,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Skeleton,
  Text,
  VStack,
} from 'components/design/next';
import { ColorInput } from 'features/brand-customization/components/color-input';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import {
  GetOrganizationTheme,
  useGetOrganizationTheme,
} from 'features/brand-customization/query-builder/get-organization-theme';
import { useCreateOrganizationTheme } from 'features/brand-customization/query-builder/create-organization-theme';
import { isUndefined, OrganizationTheme } from '@process-street/subgrade/core';
import { features } from 'services/features/features';
import { match, P } from 'ts-pattern';
import * as OrganizationUtils from 'utils/organization';
import { NgLink } from 'features/app/components/ng-link';
import { useQueryClient } from 'react-query';

export const BrandColorField = () => {
  const currentOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const selectedOrganizationPlan = useSelector(SessionSelector.getCurrentPlan);
  const queryClient = useQueryClient();

  const isTrialing = currentOrganization ? OrganizationUtils.isTrialing(currentOrganization.subscription) : true;
  const isFeatureEnabled = selectedOrganizationPlan
    ? features.canAccess(features.Feature.BRAND_COLOR, selectedOrganizationPlan.id)
    : false;

  const [color, setColor] = React.useState<string>();
  const [colorError, setColorError] = React.useState<string | null>(null);

  const organizationThemeQuery = useGetOrganizationTheme({ organizationId: currentOrganization?.id });

  React.useEffect(() => {
    if (isUndefined(color) && organizationThemeQuery.data) {
      setColor(organizationThemeQuery.data.brandColor ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
  }, [organizationThemeQuery.data]);

  const updateOrganizationThemeMutation = useCreateOrganizationTheme({
    onSuccess: async () => {
      await queryClient.invalidateQueries(GetOrganizationTheme.getKey({ organizationId: currentOrganization?.id }));

      organizationThemeQuery.refetch();
    },
  });

  const handleSaveTheme = (overrides?: Partial<OrganizationTheme>) => {
    if (!currentOrganization?.id) throw new Error('The organization is not available');

    updateOrganizationThemeMutation.mutate({
      organizationId: currentOrganization.id,
      brandColor: overrides?.brandColor ?? '',
      emailCustomizationEnabled: organizationThemeQuery.data?.emailCustomizationEnabled ?? false,
      emailPrimaryColor: organizationThemeQuery.data?.emailPrimaryColor ?? '',
      emailFooter: organizationThemeQuery.data?.emailFooter ?? '',
      emailBackgroundColor: organizationThemeQuery.data?.emailBackgroundColor ?? '',
      emailHeaderLogoFileId: organizationThemeQuery.data?.emailHeaderLogoFileId ?? null,
    });
  };

  const contentStyles: Partial<GridProps> = match({
    isTrialing,
    isFeatureEnabled,
  })
    .with(P.union({ isTrialing: true }, { isFeatureEnabled: false }), () => ({
      opacity: 0.4,
      pointerEvents: 'none',
    }))
    .otherwise(() => ({}));

  const content = (
    <Grid w="full" mt="8" rowGap="6" templateColumns={{ base: '1fr', md: '220px 1fr' }}>
      <GridItem display="flex" alignItems="center">
        <Text color="gray.600" as="label">
          Brand color
        </Text>
      </GridItem>
      <GridItem mt={{ base: '-4', md: '0' }}>
        <HStack w="full">
          <Skeleton w="full" isLoaded={!organizationThemeQuery.isLoading}>
            <ColorInput
              value={color ?? null}
              onChange={setColor}
              onError={setColorError}
              onValid={() => setColorError(null)}
              inputTestId="brand-color-input"
            />
          </Skeleton>
          <Button
            alignSelf="flex-start"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.300"
            px="4"
            h="10"
            size="sm"
            variant="solid"
            colorScheme="gray"
            aria-label="Save brand color"
            isDisabled={Boolean(colorError) || color === organizationThemeQuery.data?.brandColor}
            onClick={() => handleSaveTheme({ brandColor: color })}
          >
            Save
          </Button>
        </HStack>
      </GridItem>
    </Grid>
  );

  return match({ isTrialing, isFeatureEnabled })
    .with(P.union({ isTrialing: true }, { isFeatureEnabled: false }), () => (
      <Popover placement="top" trigger="hover">
        <PopoverTrigger>
          <Box w="full">
            <Box w="full" {...contentStyles}>
              <Skeleton w="full" isLoaded={!organizationThemeQuery.isLoading}>
                {content}
              </Skeleton>
            </Box>
          </Box>
        </PopoverTrigger>

        <PopoverContent>
          <PopoverArrow />

          <PopoverHeader py="0" bgColor="gray.50">
            <HStack h="11" alignItems="center" justifyContent="center">
              <Icon icon="lock" size="4" color="gray.600" />
              <Text variant="-2u" color="gray.600">
                Pro Plan Feature
              </Text>
            </HStack>
          </PopoverHeader>

          <PopoverBody p="3">
            <VStack spacing="4">
              <Text textAlign="center" color="gray.700" variant="-1">
                Add your brand color to your account, workflows and email notifications.
              </Text>

              <Button
                as={NgLink}
                data-testid="billing-link"
                to="organizationManage.tab"
                params={{ tab: 'billing', modal: true }}
                options={{ inherit: false, reload: true }}
                variant="solid"
                colorScheme="brand"
                _hover={{ color: 'white', textDecoration: 'none' }}
              >
                Upgrade
              </Button>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    ))
    .otherwise(() => content);
};
