const DefaultChecklistName = '<unnamed run>';

export enum Method {
  FRONT_END = 'FrontEnd',
  FRONT_END_MULTIPLE = 'FrontEndMultiple',
  SCHEDULED = 'Scheduled',
  API = 'Api',
  RUN_LINK = 'RunLink',
  Mobile = 'Mobile',
}

export const ChecklistConstants = {
  Method,
  DefaultChecklistName,
};
