import * as React from 'react';
import {
  Modal,
  ModalProps,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
  HStack,
  Icon,
  Text,
  ButtonGroup,
  Button,
  VStack,
} from 'components/design/next';
import { TemplateRevisionWithTemplate } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { Selectors, useCustomNotificationsStore } from './custom-notifications-store';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import { CustomNotificationUtils } from './custom-notification-utils';
import { useSaveCustomNotifications } from './use-save-custom-notifications';
import { CustomNotificationIcon } from 'features/custom-notifications/components/custom-notification-icon';
import { TemplateRevisionProvider } from './template-revision-context';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { AddCustomNotificationConfigWizard } from './components/add-custom-notification-config-wizard';
import { CustomNotificationConfigList } from './custom-notification-config-list';

export interface CustomNotificationsModalProps extends Partial<Omit<ModalProps, 'children'>> {
  templateRevision: TemplateRevisionWithTemplate;
  isReadOnly?: boolean;
}

export const CustomNotificationsModal: React.FC<React.PropsWithChildren<CustomNotificationsModalProps>> = ({
  templateRevision,
  isReadOnly,
  ...props
}) => {
  const disclosure = useDisclosure({
    isOpen: props.isOpen,
    onClose: () => {
      // TODO check if dirty
      props.onClose?.();
    },
  });

  return (
    <TemplateRevisionProvider templateRevision={templateRevision}>
      <Modal size="2xl" {...props} {...disclosure}>
        <ModalOverlay />
        {disclosure.isOpen && (
          <CustomNotificationsModalContent
            templateRevision={templateRevision}
            disclosure={disclosure}
            isReadOnly={isReadOnly}
          />
        )}
      </Modal>
    </TemplateRevisionProvider>
  );
};

type CustomNotificationsModalContentProps = {
  disclosure: ReturnType<typeof useDisclosure>;
} & CustomNotificationsModalProps;

const CustomNotificationsModalContent: React.FC<CustomNotificationsModalContentProps> = ({
  templateRevision,
  disclosure,
  isReadOnly,
}) => {
  const automationsQuery = GetAllNativeAutomationsQuery.useQuery(
    { templateRevisionId: templateRevision.id },
    {
      select: data =>
        data.filter(
          ({ automation }) =>
            automation.automationType === 'CustomTemplateNotification' && automation.status !== 'Deleted',
        ),
    },
  );

  const taskTemplatesQuery = useTaskTemplatesByTemplateRevisionIdQuery({ templateRevisionId: templateRevision.id });
  const taskTemplatesMap = React.useMemo(
    () =>
      new Map(
        taskTemplatesQuery.data?.map((taskTemplate, i) => [
          taskTemplate.id,
          { name: taskTemplate.name, index: i + 1 },
        ]) ?? [],
      ),
    [taskTemplatesQuery.data],
  );

  const store = useCustomNotificationsStore();
  const { setConfigs } = store;
  const newConfig = Selectors.getNewConfig(store);
  React.useEffect(() => {
    if (automationsQuery.data) {
      setConfigs(automationsQuery.data.map(CustomNotificationUtils.Transformer.automationToConfig));
    }
  }, [automationsQuery.data, setConfigs]);

  const automationsMap = React.useMemo(() => {
    return new Map(
      automationsQuery.data?.map(automationWithLink => [automationWithLink.automation.id, automationWithLink]) ?? [],
    );
  }, [automationsQuery.data]);

  const { isDisabled, handleSave, isLoading } = useSaveCustomNotifications({
    automationsMap,
    templateRevisionId: templateRevision.id,
  });

  const isSaveDisabled = match(store.view)
    .with('list', () => isDisabled)
    .with('create', () => !CustomNotificationUtils.isConfigValid(newConfig))
    .exhaustive();

  const isEmpty = store.configs.length === 0 && automationsQuery.data?.length === 0;
  const serverDataLength = automationsQuery.data?.length ?? 0;

  const handleCancel = React.useMemo(
    () =>
      match(store.view)
        .with('list', () => disclosure.onClose)
        .with('create', () => store.discardNewConfig)
        .exhaustive(),
    [disclosure.onClose, store.discardNewConfig, store.view],
  );

  const handlePrimaryAction = React.useMemo(
    () =>
      match(store.view)
        .with('list', () => () => handleSave().then(() => disclosure.onClose()))
        .with('create', () => store.promoteNewConfig)
        .exhaustive(),
    [disclosure, handleSave, store],
  );
  return (
    <ModalContent>
      <ModalHeader>
        <HStack spacing="2">
          <CustomNotificationIcon color="gray.500" bg="white" />
          {match(store.view)
            .with('list', () => (
              <>
                <Text variant="2" fontWeight="bold" color="gray.700">
                  Custom notifications
                </Text>
                <Text variant="2" color="gray.500" fontWeight="normal">
                  {templateRevision.template.name}
                </Text>
              </>
            ))
            .with('create', () => (
              <Text variant="2" fontWeight="bold" color="gray.700">
                New custom notification
              </Text>
            ))
            .exhaustive()}
        </HStack>
      </ModalHeader>
      <ModalCloseButton />

      <ModalBody as={VStack} alignItems="stretch" spacing="4">
        {match({ view: store.view, newConfig })
          .with({ view: 'list' }, () => (
            <CustomNotificationConfigList
              taskTemplatesMap={taskTemplatesMap}
              serverDataLength={serverDataLength}
              isReadOnly={isReadOnly}
            />
          ))
          .with({ view: 'create', newConfig: P.not(P.nullish) }, ({ newConfig }) => (
            <AddCustomNotificationConfigWizard newConfig={newConfig} />
          ))
          .otherwise(() => null)}
      </ModalBody>

      {isReadOnly ? (
        <ModalFooter justifyContent="flex-end" />
      ) : (
        <ModalFooter justifyContent="space-between">
          {store.view === 'list' && (
            <Button
              leftIcon={<Icon icon="plus" size="4" color="gray.700" />}
              variant="tertiary"
              onClick={() => store.createNewConfig()}
              {...(isEmpty ? { margin: '0 auto' } : {})}
            >
              Add
            </Button>
          )}

          {isEmpty ? null : (
            <ButtonGroup ml="auto">
              <Button onClick={handleCancel} variant="ghost" colorScheme="gray">
                Cancel
              </Button>

              <Button isDisabled={isSaveDisabled} onClick={handlePrimaryAction} isLoading={isLoading}>
                {match(store.view)
                  .with('list', () => 'Save')
                  .with('create', () => 'Add')
                  .exhaustive()}
              </Button>
            </ButtonGroup>
          )}
        </ModalFooter>
      )}
    </ModalContent>
  );
};
