import { Folder, Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

// We are keeping candidateId instead of templateId because we need to have the same params as MoveFolderMutation
export type MoveTemplateMutationParams = { candidateId: Template['id']; toFolderId: Folder['id'] };

export type MoveTemplateMutationResponse = Template;

export const MoveTemplateMutation = {
  key: ['put', 'template', 'move'],
  mutationFn: (params: MoveTemplateMutationParams) =>
    axiosService
      .getAxios()
      .put<MoveTemplateMutationResponse>(`/1/templates/${params.candidateId}/move/${params.toFolderId}`, params)
      .then(res => res.data),
};

export const useMoveTemplateMutation = (
  options: UseMutationOptions<MoveTemplateMutationResponse, AxiosError, MoveTemplateMutationParams> = {},
) => {
  return useMutation(MoveTemplateMutation.mutationFn, options);
};

export type MoveTemplatesMutationParams = { candidateIds: Template['id'][]; toFolderId: Folder['id'] };

export const useMoveTemplatesMutation = (
  options: UseMutationOptions<MoveTemplateMutationResponse[], AxiosError, MoveTemplatesMutationParams> = {},
) => {
  return useMutation(
    ({ candidateIds, toFolderId }) =>
      Promise.all(candidateIds.map(candidateId => MoveTemplateMutation.mutationFn({ candidateId, toFolderId }))),
    options,
  );
};
