import { entitiesSelector } from 'reducers/entities/entities.selectors';
import { TemplateSelector } from 'reducers/template/template.selectors';

const oneTemplateRevisionWithTemplateByIdSelector = id => state => {
  const templateRevision = entitiesSelector(state).templateRevision[id];
  if (templateRevision) {
    const template = TemplateSelector.getById(templateRevision.template.id)(state);
    return { ...templateRevision, template };
  } else {
    return undefined;
  }
};

export const getNewestByTemplateId = templateId => state => {
  const templateRevisionIds = state.templateRevision.newestByTemplateId[templateId] || [];
  return templateRevisionIds.map(revisionId => oneTemplateRevisionWithTemplateByIdSelector(revisionId)(state));
};

export const TemplateRevisionSelectors = {
  getNewestByTemplateId,
};
