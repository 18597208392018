import { Muid } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { LookupMap } from '@process-street/subgrade/redux/types';
import { TEMPLATE_GET_ALL_BY_ORGANIZATION_ID_AND_STATUS_FOR_SCHEDULE } from 'reducers/template/template.actions';
import { TemplateLookups } from 'reducers/types';
import { handleActionsOnSuccess } from 'reducers/util';
import { combineReducers } from 'redux';

const templateLookupForScheduleReducer = handleActionsOnSuccess<Muid[]>(
  {
    [TEMPLATE_GET_ALL_BY_ORGANIZATION_ID_AND_STATUS_FOR_SCHEDULE]: (
      _state: LookupMap,
      { payload: templates }: { payload: Template[] },
    ) => LookupsReducerUtils.replaceRootUsingSelectorFunctions(templates),
  },
  [],
);

export const templateLookupsReducer = combineReducers<TemplateLookups>({
  forSchedule: templateLookupForScheduleReducer,
});
