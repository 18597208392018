import { Muid, OrganizationMembershipRole } from '@process-street/subgrade/core';
import { LocalStorageService } from 'features/storage/local-storage-service';
import { dayjs as moment } from '@process-street/subgrade/util';

declare const window: { dataLayer?: DataLayerEvent[] };

interface IdentifyEvent {
  event: 'identify';
  fields: {
    userId: Muid;
    userCreatedDate: string;
    userUsername: string;
    userEmail: string;
    organizationId: Muid;
    organizationName: string;
    organizationMembershipRole: OrganizationMembershipRole;
    planId: Muid;
    planLevel: string;
    planName: string;
  };
}

interface PageviewEvent {
  event: 'pageview';
  fields: {
    page: string;
    userId: Muid;
    userUsername: string;
    userEmail: string;
  };
}

interface UserCompleteEvent {
  event: 'user.completed';
  fields: {
    userId: Muid;
    userUsername: string;
    userEmail: string;
    organizationId?: Muid;
  };
}

interface UserLoggedInSecondTimeEvent {
  event: 'user.loggedInSecondTime';
  fields: {
    userId: Muid;
    userCreatedDate: string;
    userUsername: string;
    userEmail: string;
    organizationId: Muid;
    organizationName: string;
    organizationMembershipRole: OrganizationMembershipRole;
    planId: Muid;
    planLevel: string;
    planName: string;
  };
}

export type DataLayerEvent = IdentifyEvent | PageviewEvent | UserCompleteEvent | UserLoggedInSecondTimeEvent;

const pushEvent = (event: DataLayerEvent) => {
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push(event);
};

const SIGN_UP_DATE_ITEM_KEY = 'signUpDate';

const saveSignUpDateInStorage = ({
  userId,
  userCreatedDate,
  organizationCreatedById,
}: {
  userId: Muid;
  userCreatedDate: number;
  organizationCreatedById: Muid;
}) => {
  const isOrganizationCreator = userId === organizationCreatedById;
  const signUpMoment = moment(userCreatedDate);
  if (isOrganizationCreator && signUpMoment.isAfter('2022-03-30')) {
    const key = [userId, SIGN_UP_DATE_ITEM_KEY].join(':');
    const signUpDateIso8601 = signUpMoment.toISOString();
    LocalStorageService.setItem(key, signUpDateIso8601);
  }
};

const pushUserLoggedInSecondTimeEventIfNeeded = async (event: UserLoggedInSecondTimeEvent) => {
  const key = [event.fields.userId, SIGN_UP_DATE_ITEM_KEY].join(':');
  const signUpDateIso8601 = LocalStorageService.getItem(key);
  const signUpDate = signUpDateIso8601 && moment(signUpDateIso8601);
  const now = moment();
  if (signUpDate && now.diff(signUpDate, 'hours') >= 12) {
    pushEvent(event);
    LocalStorageService.removeItem(key);
  }
};

// GTM stands for Google Tag Manager
export const GtmService = {
  pushEvent,
  saveSignUpDateInStorage,
  pushUserLoggedInSecondTimeEventIfNeeded,
};
