import * as React from 'react';
import { Box, Flex, Image, Text } from 'components/design/next';
import { Organization } from '@process-street/subgrade/core';
import { useRoleNames } from 'hooks/use-role-names';

export type SignUpHeaderProps = {
  organization: Organization;
};

export const SignUpHeader: React.FC<React.PropsWithChildren<SignUpHeaderProps>> = ({ organization }) => {
  const roleNames = useRoleNames();
  const logo = organization?.logoFile?.url;

  return (
    <Flex alignItems="center" direction="column">
      {logo && (
        <Box marginBottom="4">
          <Image src={logo} alt={`${organization.name} logo`} borderRadius="base" />
        </Box>
      )}

      <Text color="gray.600" textAlign="center" fontSize="lg">
        You have been invited to join <b>{organization.name}</b> as a {roleNames.Guest.single}.
        <br />
        Create your account to get started.
      </Text>
    </Flex>
  );
};
