import * as React from 'react';

import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  HStack,
  Icon,
  MenuButton,
  Show,
  Text,
  useDisclosure,
} from 'components/design/next';

import { TemplateMenu, TemplateMenuItem } from 'app/features/template/components/template-menu/template-menu';

import { useGetTemplateQuery } from 'features/template/query-builder';
import { useTemplateSettingsModalContext } from 'pages/templates/_id/components/template-settings-modal/template-settings-modal-context';
import { FocusBarIconButton } from 'app/components/focus-bar/workflow/right-button-group/edit/focus-bar-icon-button';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';
import { TemplateShareButton } from 'app/directives/template-share/template-share-button';
import { TemplateMemberList } from 'app/components/template/membership/components/TemplateMemberList/TemplateMemberList';
import { TemplateShareLevel, TemplateStatus, TemplateType } from '@process-street/subgrade/process';
import { useIsPrivateTemplate } from 'app/hooks/use-is-private-template';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'app/reducers/session/session.selectors';
import { useGetCurrentUserInfoQuery } from 'app/features/user/query-builder';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { useInjector } from 'app/components/injection-provider';
import { EditButton } from '../../../edit/page/components/edit-button';

const focusButtonViewStyles = {
  borderColor: 'gray.300',
  _hover: { bgColor: 'gray.100' },
  _active: { bgColor: 'gray.100' },
  _focus: { bgColor: 'gray.100' },
};

export const ViewButtonGroup: React.FC<React.PropsWithChildren<ButtonGroupProps>> = props => {
  const templateMenuDisclosure = useDisclosure();
  const { templateId, templateRevisionId } = useTemplateSettingsModalContext();
  if (!templateRevisionId) {
    throw new Error('Expecting template revision ID for this component.');
  }
  const { $state } = useInjector('$state');
  const { AnonymousAuthService } = useInjector('AnonymousAuthService');

  const handleImportClick = () => {
    AnonymousAuthService.logoutIfAuthedAnonymously();
    $state.go('signUp', { pageId: templateId }, { reload: true });
  };

  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;
  const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId);
  const sharingIsEnabled =
    !!permissionMap &&
    (permissionMap.templatePermitsManage ||
      permissionMap.templateShareLevelUpdate ||
      permissionMap.templateShareLinkUpdate);
  const currentUserInfoQuery = useGetCurrentUserInfoQuery();
  const isAnonymousUsr = currentUserInfoQuery.data && isAnonymousUser(currentUserInfoQuery.data.user);

  const isPagePubliclyShared =
    isAnonymousUsr &&
    template &&
    template.templateType === TemplateType.Page &&
    template.shareLevel === TemplateShareLevel.View;

  const isPrivate = useIsPrivateTemplate(templateId);
  const isGuest = useSelector(SessionSelector.isUserGuestOfSelectedOrganization);

  const isActiveTemplate = template?.status == TemplateStatus.Active;
  const isTemplateMembershipViewable = !(isPrivate || isGuest) && isActiveTemplate;

  return (
    <ButtonGroup spacing="2" alignItems="center" {...props}>
      {isPagePubliclyShared ? (
        <Button leftIcon={<Icon icon="edit" variant="far" size="4" />} onClick={handleImportClick}>
          <Text fontWeight="normal">
            Edit in&nbsp;
            <Text as="span" fontWeight="bold">
              Process Street
            </Text>
          </Text>
        </Button>
      ) : (
        <>
          <HStack color="gray.500" sx={{ svg: { color: 'gray.500' } }}>
            <Show breakpoint="(min-width: 950px)">
              {isTemplateMembershipViewable && <TemplateMemberList templateId={templateId} />}
            </Show>
          </HStack>

          <TemplateShareButton
            templateId={templateId}
            isDisabled={!sharingIsEnabled}
            modalOptions={{ hideTabs: true, tab: 'sharelink' }}
          >
            <FocusBarIconButton
              aria-label="Share link"
              icon={<Icon color="gray.500" icon="share-alt" variant="far" size="4" />}
              tooltipText="Share link"
              sx={focusButtonViewStyles}
            />
          </TemplateShareButton>

          <TemplateMenu
            autoSelect={false}
            mode="view"
            {...templateMenuDisclosure}
            placement="bottom-start"
            menuButton={
              <MenuButton
                as={FocusBarIconButton}
                aria-label="actions"
                sx={focusButtonViewStyles}
                icon={<Icon size="4" variant="far" icon="ellipsis-h" color="gray.500" />}
              />
            }
          >
            <EditButton templateId={templateId}>
              <TemplateMenuItem aria-label="edit workflow" w="full">
                Edit
              </TemplateMenuItem>
            </EditButton>
          </TemplateMenu>
        </>
      )}
    </ButtonGroup>
  );
};
