import {} from '@process-street/subgrade/core';
import { NativeAutomation } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateNativeAutomationActionsMutation {
  export type Params = {
    nativeAutomationId: string;
    actions: NativeAutomation.Action[];
  };

  export type Request = Params['actions'];
  export type Response = NativeAutomation;

  export const key = ['native-automations', 'actions'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/native-automations/${params.nativeAutomationId}/actions`, params.actions)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
