import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { EntityMap } from '@process-street/subgrade/redux/types';
import { FILE_UPLOAD_SET_STATS } from 'components/file-upload/store/file-upload.actions';
import { FileUploadStats } from 'components/file-upload/store/file-upload.types';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { combineReducers } from 'redux';
import { Action } from 'redux-actions';
import { FileUploadModuleState } from './file-upload.types';

export const fileUploadStatsReducer = handleActionsOnSuccess(
  {
    [FILE_UPLOAD_SET_STATS]: (state: EntityMap<FileUploadStats>, action: Action<FileUploadStats>) =>
      EntitiesReducerUtils.upsert(state, action.payload),
  },
  {},
);

export const fileUploadModuleReducer = combineReducers<FileUploadModuleState>({
  uploadsMap: fileUploadStatsReducer,
});
