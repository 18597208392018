import { useQuery } from 'react-query';
import { Muid } from '@process-street/subgrade/core';
import { PermissionsStats } from 'components/permissions/models/permissions-stats';
import { PermissionsStatsApi } from 'components/permissions/services/permissions-stats.api';
import { EntityMap, toEntityMapUsingIdSelector } from '@process-street/subgrade/redux';

export const PERMISSION_STATS_KEY = 'permission-stats';

export const GetFoldersPermissionStatsByOrganization = {
  key: [PERMISSION_STATS_KEY, 'folders'],
  getKey: (organizationId: Muid) => [...GetFoldersPermissionStatsByOrganization.key, organizationId],
  queryFn: (organizationId: Muid) => PermissionsStatsApi.getAllForFoldersByOrganizationId(organizationId),
};
export function useFoldersPermissionsStatsQuery(organizationId: Muid) {
  return useQuery<PermissionsStats[], Error, EntityMap<PermissionsStats>>(
    GetFoldersPermissionStatsByOrganization.getKey(organizationId),
    () => GetFoldersPermissionStatsByOrganization.queryFn(organizationId),
    {
      enabled: Boolean(organizationId),
      staleTime: 1000 * 60 * 5, // 5 Minutes
      select: stats => toEntityMapUsingIdSelector(stats, stat => stat.folderId ?? ''),
    },
  );
}

type GetTemplatesPermissionStatsProps = { organizationId: Muid; parentFolderId: Muid };

export const GetTemplatesPermissionStatsByOrganization = {
  key: [PERMISSION_STATS_KEY, 'templates'],
  getKey: ({ organizationId, parentFolderId }: GetTemplatesPermissionStatsProps) => [
    ...GetTemplatesPermissionStatsByOrganization.key,
    organizationId,
    parentFolderId,
  ],
  queryFn: ({ organizationId, parentFolderId }: GetTemplatesPermissionStatsProps) =>
    PermissionsStatsApi.getAllTemplatePermissionsStatsByOrganizationIdAndParentId(organizationId, parentFolderId),
};
export function useTemplatesPermissionsStatsQuery(organizationId: Muid, parentFolderId: Muid) {
  return useQuery<PermissionsStats[], Error, EntityMap<PermissionsStats>>(
    GetTemplatesPermissionStatsByOrganization.getKey({ organizationId, parentFolderId }),
    () => GetTemplatesPermissionStatsByOrganization.queryFn({ organizationId, parentFolderId }),
    {
      staleTime: 1000 * 60 * 5, // 5 Minutes
      select: stats => toEntityMapUsingIdSelector(stats, stat => stat.templateId ?? ''),
    },
  );
}
