import * as React from 'react';
import { Icon } from 'app/components/design/next';
import { Alert, Box, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip } from '@chakra-ui/react';
import { match } from 'ts-pattern';
import { TinyMCEEditor } from 'app/features/rich-text';
import { getFormatButtonStyles } from './format-button';

export type TextFormatsProps = {
  editor?: TinyMCEEditor;
  activeCommands: Record<string, boolean>;
};

const formats = [
  { label: 'Normal', name: 'p' },
  { label: 'Heading 1', name: 'h1' },
  { label: 'Heading 2', name: 'h2' },
  { label: 'Heading 3', name: 'h3' },
  { label: 'Formatted', name: 'pre' },
  { label: 'Success', name: 'success' },
  { label: 'Info', name: 'info' },
  { label: 'Reminder', name: 'reminder' },
  { label: 'Critical', name: 'critical' },
  { label: 'Code', name: 'code' },
  { label: 'Quote', name: 'bq' },
];

export const TextFormats = ({ editor, activeCommands }: TextFormatsProps) => {
  const isSomeFormatActive = formats.some(format => activeCommands[format.name]);

  return (
    <Menu isLazy placement="auto">
      {({ isOpen }) => (
        <>
          <Tooltip label="Formats" shouldWrapChildren>
            <MenuButton
              {...getFormatButtonStyles({ isActive: isOpen || isSomeFormatActive })}
              as={IconButton}
              aria-label="Formats"
              icon={<Icon icon="heading" size="3.5" />}
            />
          </Tooltip>

          <MenuList minW="max-content">
            {formats.map(format => {
              return (
                <MenuItem key={format.name} onClick={() => editor?.execCommand('FormatBlock', false, format.name)}>
                  {match(format.name)
                    .with('p', () => <Text m={0}>{format.label}</Text>)
                    .with('h1', () => (
                      <Text color="" m={0} fontSize="2xl" fontWeight="semibold">
                        {format.label}
                      </Text>
                    ))
                    .with('h2', () => (
                      <Text m={0} fontSize="xl" fontWeight="semibold">
                        {format.label}
                      </Text>
                    ))
                    .with('h3', () => (
                      <Text m={0} fontSize="lg" fontWeight="semibold">
                        {format.label}
                      </Text>
                    ))
                    .with('pre', () => (
                      <Box
                        as="pre"
                        m={0}
                        p={2}
                        bgColor="gray.100"
                        w="full"
                        borderRadius="2px"
                        borderColor="gray.200"
                        borderStyle="solid"
                        borderWidth="thin"
                      >
                        {format.label}
                      </Box>
                    ))
                    .with('success', () => <Alert status="success">{format.label}</Alert>)
                    .with('info', () => <Alert status="info">{format.label}</Alert>)
                    .with('reminder', () => <Alert status="warning">{format.label}</Alert>)
                    .with('critical', () => <Alert status="error">{format.label}</Alert>)
                    .with('code', () => (
                      <Box color="red.500" as="code" fontFamily="monospace">
                        {format.label}
                      </Box>
                    ))
                    .with('bq', () => <Box as="blockquote">{format.label}</Box>)
                    .otherwise(() => (
                      <Text>{format.label}</Text>
                    ))}
                </MenuItem>
              );
            })}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
