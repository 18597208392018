import angular from 'angular';
import { RouteServiceImpl } from 'components/routing/route-service.impl';
import { Transition, TransitionService } from '@uirouter/angularjs';
import { GuestRedirectService, GuestRedirectServiceImpl } from 'components/routing/guest-redirect-service';

export const RoutingModule = angular
  .module('routing', [])
  .service('RouteService', RouteServiceImpl)
  .service('guestRedirectService', GuestRedirectServiceImpl)
  .run((guestRedirectService: GuestRedirectService, $transitions: TransitionService) => {
    $transitions.onBefore({}, (transition: Transition) => {
      const targetStateName = transition.to().name;
      if (targetStateName) {
        const newStateName = guestRedirectService.getGuestRedirectStateName(targetStateName);
        if (newStateName) {
          return transition.router.stateService.target(newStateName);
        }
      }
    });
  }).name;
