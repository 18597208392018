import { useOrganizationMembershipRole } from 'components/paywalls/use-organization-membership-role';
import { useOrganizationUsageStats } from 'components/paywalls/trial-expired/query';
import { usePlan } from 'services/use-plan-track';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { PlanLevel } from '@process-street/subgrade/billing';

export const useInviteQuery = () => {
  const role = useOrganizationMembershipRole();
  const { data: organizationUsageStats } = useOrganizationUsageStats();

  const plan = usePlan();
  const free = plan?.level === PlanLevel.Free;
  const admin = role === OrganizationMembershipRole.Admin;
  const overLimit = !!organizationUsageStats && organizationUsageStats?.fullMembersCount >= 5;
  const showInviteNote = !free && admin && overLimit;

  return {
    admin,
    free,
    overLimit,
    showInviteNote,
  };
};
