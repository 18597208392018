import angular from 'angular';
import templateUrl from './multi-select-menu.component.html';
import './multi-select-menu.scss';
import { TaskListEvent } from 'directives/task-list/task-list-event';

angular.module('frontStreetApp.directives').component('psTaskTemplateMultiSelectMenu', {
  bindings: {
    taskTemplates: '<',
  },
  templateUrl,
  controller($rootScope, $scope, FeatureGateService, OrganizationService, SessionService) {
    const ctrl = this;

    ctrl.selectedTaskTemplates = [];
    ctrl.actionMap = {
      MOVE_UP: 'Move up',
      MOVE_DOWN: 'Move down',
      DELETE: 'Delete',
    };

    ctrl.labelMap = {};
    ctrl.labelMap[ctrl.actionMap.MOVE_UP] = 'fa-arrow-up';
    ctrl.labelMap[ctrl.actionMap.MOVE_DOWN] = 'fa-arrow-down';
    ctrl.labelMap[ctrl.actionMap.DELETE] = 'fa-trash-alt';

    ctrl.isTaskPermissionsFeatureEnabled = () => FeatureGateService.isTaskPermissionsFeatureEnabled(ctrl.user);

    ctrl.$onInit = function () {
      ctrl.user = SessionService.getUser();

      OrganizationService.getSelectedOrganization().then(organization => {
        ctrl.organization = organization;
      });
    };

    ctrl.doAction = function (action) {
      switch (action) {
        case ctrl.actionMap.MOVE_UP:
          $rootScope.$broadcast(TaskListEvent.BULK_MOVE_UP_REQUEST_FROM_MENU);
          break;
        case ctrl.actionMap.MOVE_DOWN:
          $rootScope.$broadcast(TaskListEvent.BULK_MOVE_DOWN_REQUEST_FROM_MENU);
          break;
        case ctrl.actionMap.DELETE:
          $rootScope.$broadcast(TaskListEvent.BULK_DELETE_REQUEST_FROM_MENU);
          break;
        default:
      }
    };

    ctrl.clearSelection = function () {
      $rootScope.$broadcast(TaskListEvent.BULK_CLEAR_SELECTION);
    };

    ctrl.setDueOffset = function (dueOffset) {
      $rootScope.$broadcast(TaskListEvent.BULK_SET_DUE_OFFSET, dueOffset);
      ctrl.dueOffset = dueOffset;
    };

    ctrl.removeDueOffset = function () {
      $rootScope.$broadcast(TaskListEvent.BULK_REMOVE_DUE_OFFSET);
      ctrl.dueOffset = undefined;
    };

    ctrl.assignUser = function (user) {
      $rootScope.$broadcast(TaskListEvent.BULK_ASSIGN_USER, user);
    };

    ctrl.unassignUser = function (user) {
      $rootScope.$broadcast(TaskListEvent.BULK_UNASSIGN_USER, user);
    };

    ctrl.addStop = function () {
      $rootScope.$broadcast(TaskListEvent.BULK_ADD_STOP);
    };

    ctrl.removeStop = function () {
      $rootScope.$broadcast(TaskListEvent.BULK_REMOVE_STOP);
    };

    // Events Listeners

    $scope.$on(
      TaskListEvent.SHOW_MULTI_SELECT_MENU,
      (__event, itemsCount, dueOffset, onlyAdd, selectedTaskTemplates, templateRevision) => {
        ctrl.visible = true;
        ctrl.selectedItemsCount = itemsCount;
        ctrl.dueOffset = dueOffset;
        ctrl.onlyAdd = onlyAdd;
        ctrl.selectedTaskTemplates = angular.copy(selectedTaskTemplates);
        ctrl.templateRevision = templateRevision;
      },
    );

    $scope.$on(TaskListEvent.HIDE_MULTI_SELECT_MENU, () => {
      ctrl.visible = false;
    });
  },
});
