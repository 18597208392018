import * as React from 'react';
import { Box, Button, Icon, Portal, useBreakpointValue } from 'components/design/next';
import { DatePicker } from 'features/conditional-logic/components/date-picker';
import { ThemeProvider2024 } from 'components/design/next/theme-provider-2024';
import { dayjs } from '@process-street/subgrade/util';

export type SnoozeButtonDatePickerProps = {
  onChange: (value: number) => void;
};

export const SnoozeButtonDatePicker = ({ onChange }: SnoozeButtonDatePickerProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box>
      <DatePicker
        pickerProps={{
          disabled: date => dayjs(date).isBefore(dayjs().subtract(1, 'day')),
        }}
        popoverProps={{
          placement: 'bottom-start',
        }}
        onChange={date => date && onChange(date)}
        renderTrigger={() => (
          <Button
            w="full"
            colorScheme="gray"
            size="sm"
            variant="ghost"
            color="gray.600"
            justifyContent="flex-start"
            _hover={{ bgColor: 'gray.200' }}
            fontWeight="400"
            borderRadius="4px"
            leftIcon={<Icon icon="clock" size="4" color="gray.600" />}
            iconSpacing={2}
          >
            Pick date and time
          </Button>
        )}
        PopoverContentWrapper={
          isMobile
            ? ({ children }) => {
                return (
                  <Portal>
                    <Box
                      sx={{
                        '.chakra-popover__popper': {
                          transform: { base: 'unset !important' },
                          position: { base: 'fixed !important' },
                          top: { base: '0 !important' },
                          left: { base: '0 !important' },
                          display: { base: 'flex' },
                          alignItems: { base: 'center' },
                          justifyContent: { base: 'center' },
                          width: { base: '100vw' },
                          height: { base: '100vh' },
                          backgroundColor: { base: 'blackAlpha.200' },
                        },
                      }}
                    >
                      {children}
                    </Box>
                  </Portal>
                );
              }
            : undefined
        }
        renderContent={children => (
          <ThemeProvider2024>
            <Box
              sx={{
                '.rdp-day': {
                  fontSize: 'sm',
                },
              }}
            >
              {children}
            </Box>
          </ThemeProvider2024>
        )}
        size="md"
      />
    </Box>
  );
};
