import {
  calculatePercentDiscountedCost,
  Plan,
  PlanInterval,
  PlanLevel,
  PlanLevelUtil,
  PlanTrack,
  PlanTrackPaidPlans,
} from '@process-street/subgrade/billing';
import { Organization, OrganizationDiscount } from '@process-street/subgrade/core';
import { FreePlanItem } from 'components/billing/FreePlanItem';
import { PlanLevelPricingItem } from 'components/billing/PlanLevelPricingItem';
import { PricingFeatureList } from 'components/billing/PricingFeatureList';
import { useInjector } from 'components/injection-provider';
import React from 'react';
import { useOnPlanTrack } from 'services/use-plan-track';
import { PlanLevelSelectorState } from '../../../directives/billing/plan-level-selector/plan-level-selector-service.interface';
import './PlanLevelPricingPanel.scss';

type PlanMap = {
  [level in PlanLevel]?: {
    [interval in PlanInterval]?: Plan;
  };
};

export interface PlanLevelPricingPanelProps {
  currentPlan: Plan;
  planInterval: PlanInterval;
  onSelect: (plan: PlanLevel, selectorState: PlanLevelSelectorState) => boolean;
  discount: OrganizationDiscount;
  planMap: PlanMap;
  organization: Organization;
}

type PlanStates = Record<string, PlanLevelSelectorState>;

export const PlanLevelPricingPanel: React.FC<React.PropsWithChildren<PlanLevelPricingPanelProps>> = props => {
  const onFreemium = useOnPlanTrack(PlanTrack.Freemium);
  const paidPlanLevels = PlanTrackPaidPlans[onFreemium ? PlanTrack.Freemium : PlanTrack.FreeTrial];
  const [selectedPlanLevel, setSelectedPlanLevel] = React.useState<PlanLevel>(props.currentPlan.level);
  const { PlanLevelSelectorService } = useInjector('PlanLevelSelectorService');

  const planStates = React.useMemo(() => {
    const ps: PlanStates = {};

    PlanLevelUtil.all().forEach(planLevel => {
      const selected = selectedPlanLevel === planLevel;

      ps[planLevel] = PlanLevelSelectorService.getPlanLevelState(
        props.organization,
        props.currentPlan,
        planLevel,
        selected,
      );
    });
    return ps;
  }, [selectedPlanLevel, PlanLevelSelectorService, props.organization, props.currentPlan]);

  const handleSelect = (level: PlanLevel) => {
    const selectorState = planStates[level];

    const changed = props.onSelect(level, selectorState);
    if (changed) {
      setSelectedPlanLevel(level);
    }
  };

  function getFormattedCostPerMonth(level: PlanLevel) {
    const baseCost = props.planMap[level]?.[props.planInterval]?.cost;

    if (!baseCost) {
      return '0.00';
    }

    let cost = calculatePercentDiscountedCost(baseCost, props.discount);

    if (props.planInterval === PlanInterval.Yearly) {
      cost /= 12;
    }
    return cost ? PlanLevelSelectorService.formatCostAsString(cost) : '0.00';
  }

  const freeSelectorState = planStates[PlanLevel.Free];

  return (
    <div className={`plan-level-pricing-panel plan-level-pricing-panel--${onFreemium ? 'freemium' : 'free-trial'}`}>
      <div className="row">
        <div className="col-sm-3 hidden-xs pricing-feature-list">
          <div className="label-features" aria-label="Features" role="heading" aria-level={3}>
            Features
          </div>
          <PricingFeatureList />
        </div>
        <div className="col-sm-9 pricing-items">
          {paidPlanLevels.map(level => (
            <PlanLevelPricingItem
              key={level}
              level={level}
              selectorState={planStates[level]}
              cost={getFormattedCostPerMonth(level)}
              onSelect={handleSelect}
            />
          ))}
        </div>
      </div>

      <div className="row">
        <div className="col-sm-3 hidden-xs" />

        <div className="col-sm-9 plan-level-pricing-panel__free-plan-item">
          <FreePlanItem selectorState={freeSelectorState} onSelect={handleSelect} />
        </div>
      </div>
    </div>
  );
};
