import angular from 'angular';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller('RedirectCtrl', function ($state, $timeout, DataService, SessionService) {
    const ctrl = this;
    const logger = trace({ name: 'RedirectCtrl' });
    logger.info('loading ctrl');

    ctrl.$onInit = () => {
      $timeout(() => {
        redirect($state.params.to, $state.params.id);
      });
    };

    function redirect(toState, id) {
      switch (toState) {
        case 'organization':
          (function () {
            SessionService.setSelectedOrganizationId(id);
            DataService.setInitialized(false);
            $state.go('reports');
          })();
          break;
        default:
          logger.error('unrecognized "to" parameter: %s', toState);
          $state.go('reports');
      }
    }
  });
