import { DropzoneState } from 'react-dropzone';
import { useEffect } from 'react';
import last from 'lodash/last';
import { SIZE_TOO_LARGE } from './use-upload-checklist-email-attachment';
import { useToast } from 'components/design/next';

export const useShowFileUploadErrorToast = (dropzoneState: DropzoneState) => {
  const toast = useToast();

  useEffect(() => {
    const fileTooLarge =
      dropzoneState.fileRejections.length > 0 && last(dropzoneState.fileRejections[0].errors)?.code === SIZE_TOO_LARGE;
    if (!fileTooLarge) {
      return;
    }

    toast({
      title: "We're having problems attaching the file",
      description: last(dropzoneState.fileRejections[0].errors)?.message,
      status: 'error',
    });
  }, [dropzoneState.fileRejections, dropzoneState.fileRejections.length, toast]);
};
