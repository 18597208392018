import { ImageWidget } from '@process-street/subgrade/process';
import { ImagePreview } from 'components/common/preview/ImagePreview';
import React from 'react';
import 'react-image-lightbox/style.css';
import styles from './ChecklistImageWidget.module.scss';

interface ChecklistImageWidgetProps {
  widget: ImageWidget;
}

export const ChecklistImageWidget: React.FunctionComponent<React.PropsWithChildren<ChecklistImageWidgetProps>> = ({
  widget,
}) => {
  const { file } = widget;

  let checklistImageWidget;

  if (file) {
    checklistImageWidget = (
      <div className={styles.imageWidgetContainer}>
        <ImagePreview src={file.url} caption={widget.caption} />
      </div>
    );
  } else {
    checklistImageWidget = <div />;
  }

  return checklistImageWidget;
};
