import { StringUtils } from '@process-street/subgrade/util';
import { trace } from 'components/trace';

export type TabItem = {
  icon?: string;
  label: string;
  url: string;
  regex?: string;
};

export interface GoNative {
  gonative_library_ready?: () => void;
  gonative_onesignal_info?: () => void;
  gonative?: {
    // See https://gonative.io/docs/personalized-push
    onesignal?: {
      externalUserId: {
        set: ({ externalId }: { externalId: string }) => void;
        remove: () => void;
      };
    };
    // See https://gonative.io/docs/dynamic-tab-menu
    tabNavigation: {
      setTabs: ({ enabled, items }: { enabled: boolean; items?: TabItem[] }) => void;
      selectTab: (index: number) => void;
      deselect: () => void;
    };
  };
}

declare const window: Window & GoNative;

const logger = trace({ name: 'GoNativeService' });

// This command queue is needed because the `gonative` object loads async, so we need to save the commands and run them
const commands: (() => void)[] = [];

const runCommand = (command: () => void) => {
  if (window.gonative) {
    command();
  } else {
    commands.push(command);
  }
};

// See https://gonative.io/docs/gonative-javascript-bridge
window.gonative_library_ready = () => {
  commands.forEach(command => command());
};

const isGoNativeApp = () => window.navigator.userAgent.includes('gonative');

const setup = (userId: string) => {
  setOneSignalExternalUserId(userId);
  setDefaultTabs({ enabled: true });
};

const shutdown = () => {
  removeOneSignalExternalUserId();
  setDefaultTabs({ enabled: false });
};

const setTabs = (params: { enabled: boolean; items?: TabItem[] }): void => {
  runCommand(() => window.gonative?.tabNavigation.setTabs(params));
};

const setDefaultTabs = ({ enabled }: { enabled: boolean }) => {
  const origin = `https://${window.location.hostname}`;
  const inboxRegex = StringUtils.escapeRegExp(`${origin}/work`) + '.*';
  const libraryRegex = StringUtils.escapeRegExp(`${origin}/library`) + '.*';
  const reportsRegex = StringUtils.escapeRegExp(`${origin}/reports`) + '.*';
  const commentsRegex = StringUtils.escapeRegExp(`${origin}/comments`) + '.*';
  const items: TabItem[] = [
    {
      icon: 'fas fa-square-check',
      label: 'My Work',
      // eslint-disable-next-line no-script-url
      url: 'javascript:gonativeTabs && gonativeTabs.selectMyWork()',
      regex: inboxRegex,
    },
    {
      icon: 'fas fa-book-open',
      label: 'Library',
      // eslint-disable-next-line no-script-url
      url: 'javascript:gonativeTabs && gonativeTabs.selectLibrary()',
      regex: libraryRegex,
    },
    {
      icon: 'fas fa-chart-line',
      label: 'Reports',
      // eslint-disable-next-line no-script-url
      url: 'javascript:gonativeTabs && gonativeTabs.selectReports()',
      regex: reportsRegex,
    },
    {
      icon: 'fas fa-comment',
      label: 'Comments',
      // eslint-disable-next-line no-script-url
      url: 'javascript:gonativeTabs && gonativeTabs.selectComments()',
      regex: commentsRegex,
    },
  ];
  setTabs({ enabled, items });
};

const deselectTab = (): void => {
  runCommand(() => window.gonative?.tabNavigation.deselect());
};

// This command queue is needed because the `onesignal` object loads async, so we need to save the commands and run them
const oneSignalCommands: (() => void)[] = [];

const runOneSignalCommand = (command: () => void) => {
  if (window.gonative?.onesignal) {
    command();
  } else {
    oneSignalCommands.push(command);
  }
};

window.gonative_onesignal_info = () => {
  oneSignalCommands.forEach(command => command());
};

const setOneSignalExternalUserId = (externalId: string) => {
  runOneSignalCommand(() => {
    window.gonative?.onesignal?.externalUserId.set({ externalId });
    logger.info(`Set OneSignal ID to [${externalId}].`);
  });
};

const removeOneSignalExternalUserId = () => {
  runOneSignalCommand(() => {
    window.gonative?.onesignal?.externalUserId.remove();
    logger.info(`Removed OneSignal ID.`);
  });
};

export const GoNativeService = {
  isGoNativeApp,
  setup,
  shutdown,
  setDefaultTabs,
  deselectTab,
  setOneSignalExternalUserId,
  removeOneSignalExternalUserId,
};
