import {
  Button,
  Center,
  Flex,
  MotionWrapper,
  Spinner,
  Text,
  useBreakpointValue,
  useToast,
} from 'components/design/next';
import React from 'react';
import { useCreateChecklistMutation } from 'features/checklists/query-builder/create-checklist-mutation';
import { useInjector } from 'components/injection-provider';
import { Muid } from '@process-street/subgrade/core';
import usePromise from 'react-use/lib/usePromise';
import { AuthInternalErrors, DefaultErrorMessages } from 'components/utils/error-messages';
import { inIframe } from 'pages/forms/_id/login/utils';
import { GetTemplateOverviewQuery } from 'features/template/query-builder';
import { useBackgroundCdnImage } from 'features/forms/hooks/use-background-cdn-image';
import { match } from 'ts-pattern';
import { OrganizationLogo } from 'pages/responses/_id/components/organization-logo/organization-logo';
import { useMount } from 'react-use';
import { useLocation } from 'app/adapters/navigation';
import { RunLinkService } from 'features/checklists/services/run-link-service';

export const AddFormResponsePage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { $state, AnonymousAuthService } = useInjector('$state', 'AnonymousAuthService');

  const templateId = $state.params?.id as Muid;

  const mounted = usePromise();
  const toast = useToast();

  const isEmbedded = inIframe();
  const [isStartButtonVisible, setIsStartButtonVisible] = React.useState(false);

  const templateOverviewQuery = GetTemplateOverviewQuery.useQuery({ templateId });
  const cdnImage = useBackgroundCdnImage({ templateId });
  const backgroundSize = useBreakpointValue({ base: 'contain', lg: 'cover' });
  const backgroundPosition = useBreakpointValue({ base: 'center top', lg: 'center center' });

  const createFormResponseMutation = useCreateChecklistMutation({
    onSuccess: formResponse => {
      void $state.go(
        'formResponse',
        {
          id: formResponse.id,
          name: formResponse.name,
        },
        { location: 'replace' },
      );
    },
  });

  const location = useLocation();
  // This is used instead of `queryString.parse` because the values need to stay as strings
  const params = Object.fromEntries(new URLSearchParams(location.search).entries());
  const formFields = RunLinkService.extractFieldValueMapFromQueryParams(params);

  const createFormResponse = () => createFormResponseMutation.mutate({ templateId, formFields });

  useMount(() => {
    const loginParams = { page: 'formLogin', params: { id: templateId } };
    mounted(AnonymousAuthService.authenticateForFormResponse(templateId, loginParams))
      .then(() => {
        if (isEmbedded) {
          setIsStartButtonVisible(true);
        } else {
          createFormResponse();
        }
      })
      .catch(error => {
        if (error.message === AuthInternalErrors.formResponseLoginRequired) {
          // just wait for the redirect to login page
        } else {
          toast({
            status: 'error',
            title: "We're having problems loading the form",
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        }
      });
  });

  return isStartButtonVisible ? (
    <Flex
      h="100vh"
      justifyContent="center"
      pt={{ base: '32', lg: '0' }}
      pb={{ base: '0', lg: '24' }}
      {...{
        ...match(cdnImage)
          .with({ status: 'loaded' }, ({ src }) => ({
            backgroundImage: `url(${src})`,
          }))
          .with({ lqipStatus: 'loaded' }, ({ lqipSrc }) => ({
            backgroundImage: `url(${lqipSrc}) `,
          }))
          .otherwise(() => {}),
        backgroundRepeat: 'no-repeat',
        backgroundPosition,
        backgroundAttachment: 'fixed',
        backgroundSize,
      }}
      alignItems="center"
      bgColor="brand.100"
    >
      <MotionWrapper layout>
        <Flex
          w={{ base: 'full', lg: '3xl' }}
          minH={{ base: 'calc(100vh - var(--ps-sizes-32))', lg: '77' }}
          h={{ base: 'calc(100vh - var(--ps-sizes-32))', lg: 'fit-content' }}
          bgColor="white"
          borderRadius={{ base: '0', lg: 'lg' }}
          p={{ base: '4', lg: '8' }}
          alignItems="center"
          justifyContent="center"
          direction="column"
          gap="4"
        >
          {templateOverviewQuery.data?.description ? (
            <Text color="gray.600" fontWeight="bolder">
              {templateOverviewQuery.data?.description}
            </Text>
          ) : (
            <Text>&nbsp;</Text>
          )}

          <Button variant="primary" aria-label="start" mt={4} onClick={createFormResponse}>
            Start
          </Button>

          <Flex direction="column" w="full" alignItems="center" mt="4">
            <OrganizationLogo />
          </Flex>
        </Flex>
      </MotionWrapper>
    </Flex>
  ) : (
    <Center h="100vh" bgColor="brand.100">
      <Spinner h="30" w="30"></Spinner>
    </Center>
  );
};
