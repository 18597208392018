import * as React from 'react';
import Pie from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { Box, useToken, useBreakpointValue } from 'components/design/next';
import { ParentSize } from '@visx/responsive';
import { ParentSizeRenderProps } from '../common/types';
import { getRandomInt } from '@process-street/subgrade/core';
import { NotEnoughData } from '../common/not-enough-data';
import { useChartBoxContext } from '../chart-box/context';
import { useEffectOnce } from 'react-use';

const KEYS = ['a', 'b', 'c', 'd'] as const;
const COLORS = ['gray.100', 'gray.200', 'gray.300', 'gray.50'];
type Status = typeof KEYS[number];
type Stat = { status: Status; total: number };

export interface EmptyDonutChartProps {
  data: Stat[];
  parentSize: ParentSizeRenderProps;
  config?: Partial<{
    thickness: number;
  }>;
}

export const EmptyDonutChart: React.VFC<EmptyDonutChartProps> = ({
  parentSize: { width: parentWidth, height: parentHeight },
  data,
  config = {},
}) => {
  const margin = { top: 20, right: 20, bottom: 0, left: 20 };

  const colorScale = scaleOrdinal<Status, string>({
    domain: [...KEYS],
    range: useToken('colors', COLORS),
  });

  const [labelHeight, setLabelHeight] = React.useState(0);
  const innerWidth = parentWidth - margin.left - margin.right;
  const innerHeight = parentHeight - margin.top - margin.bottom - labelHeight;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = useBreakpointValue({ md: 100, base: 50 }) ?? 50;

  if (parentWidth < 10) return null;

  return (
    <Box aria-label="empty donut chart">
      <svg width={parentWidth} height={parentHeight - labelHeight}>
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          <Pie<Stat>
            data={data}
            pieValue={stat => stat.total}
            outerRadius={radius}
            innerRadius={radius - (config.thickness ?? donutThickness)}
            cornerRadius={3}
            padAngle={0.005}
          >
            {({ arcs, path }) =>
              arcs.map(arc => {
                return (
                  <g key={arc.data.status} aria-label="empty donut chart section">
                    <path
                      // compute interpolated path d attribute from intermediate angle values
                      d={path({ ...arc, startAngle: arc.startAngle, endAngle: arc.endAngle }) ?? undefined}
                      fill={colorScale(arc.data.status)}
                    />
                  </g>
                );
              })
            }
          </Pie>
        </Group>
      </svg>

      <NotEnoughData
        ref={node => {
          setLabelHeight(node?.offsetHeight ?? 0);
        }}
      />
    </Box>
  );
};

export type EmptyDonutProps = Omit<EmptyDonutChartProps, 'data' | 'parentSize'>;

export const EmptyDonut: React.VFC<EmptyDonutProps> = props => {
  const { setIsEmpty } = useChartBoxContext();
  useEffectOnce(() => {
    setIsEmpty.on();
    return () => {
      setIsEmpty.off();
    };
  });

  const data: Stat[] = KEYS.map(key => ({ status: key, total: getRandomInt({ max: 100, min: 0 }) }));
  return <ParentSize>{parentSize => <EmptyDonutChart {...{ parentSize, data, ...props }} />}</ParentSize>;
};
