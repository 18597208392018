import { createSelector, Selector } from 'reselect';
import { Muid, Option } from '../../core';
import { Task, TaskTemplateTaskType, TaskWithTaskTemplate } from '../../process';
import { safeEntityMapToArrayByIdsWith } from '../safe-entity-map-to-array-by-ids';
import { BaseReduxState, EntityMap } from '../types';
import { BaseTaskTemplateSelector } from './task-template.selectors';

const getEntityMap: Selector<BaseReduxState, EntityMap<Task>> = (state: BaseReduxState) => state.entities.task;

const getById =
  (taskId: Muid): Selector<BaseReduxState, Option<Task>> =>
  (state: BaseReduxState) =>
    state.entities.task[taskId];

const isApprovalTask =
  (taskId: Muid): Selector<BaseReduxState, boolean> =>
  (state: BaseReduxState) => {
    const task = getById(taskId)(state);
    if (!task) {
      return false;
    }
    const taskTemplate = BaseTaskTemplateSelector.getById(task.taskTemplate.id)(state);
    if (!taskTemplate) {
      return false;
    }
    return taskTemplate.taskType === TaskTemplateTaskType.Approval;
  };

const getAllByIds =
  (taskIds: Muid[]): Selector<BaseReduxState, Task[]> =>
  (state: BaseReduxState) =>
    taskIds.map(taskId => state.entities.task[taskId]);

const byChecklistRevisionLookups =
  (checklistRevisionId: Muid): Selector<BaseReduxState, Muid[]> =>
  (state: BaseReduxState) =>
    state.lookups.task.byChecklistRevisionId[checklistRevisionId] ?? [];

const getAllByChecklistRevisionId = (checklistRevisionId: Muid): Selector<BaseReduxState, Task[]> =>
  createSelector(
    [byChecklistRevisionLookups(checklistRevisionId), getEntityMap],
    (taskIds: Muid[], taskMap: EntityMap<Task>) => {
      return safeEntityMapToArrayByIdsWith(taskMap, taskIds);
    },
  );

const getAllWithTaskTemplateByChecklistRevisionId = (
  checklistRevisionId: Muid,
): Selector<BaseReduxState, TaskWithTaskTemplate[]> =>
  createSelector(
    [
      BaseTaskSelector.getEntityMap,
      byChecklistRevisionLookups(checklistRevisionId),
      BaseTaskTemplateSelector.getEntityMap,
    ],
    (entityMap, taskIds, taskTemplateMap) => {
      const tasks = safeEntityMapToArrayByIdsWith<Task>(
        entityMap,
        taskIds,
        (task: Task): Option<TaskWithTaskTemplate> => {
          const taskTemplate = taskTemplateMap[task.taskTemplate.id];
          if (taskTemplate !== undefined) {
            return { ...task, taskTemplate };
          }
        },
      );

      return tasks as TaskWithTaskTemplate[];
    },
  );

export const BaseTaskSelector = {
  getAllByIds,
  getAllByChecklistRevisionId,
  getById,
  getEntityMap,
  getAllWithTaskTemplateByChecklistRevisionId,
  isApprovalTask,
};
