/**
 * DEPRECATED
 *
 * This component has been deprecated in favor of `FontAwesomeIcon`.
 *
 * ref:https://processstreet.slack.com/archives/C01AQAUEQ87/p1603893861413700?thread_ts=1603893035.410400&cid=C01AQAUEQ87
 */
import classNames from 'classnames';
import React from 'react';
import styles from './Icon.module.scss';

export enum IconTypeFilled {}

export enum IconTypeRegular {}

export enum IconType {
  Comment = 'fa-comment',
  Folder = 'fa-folder-open',
  ChevronDown = 'fa-chevron-down',
  ChevronUp = 'fa-chevron-up',
  ChevronLeft = 'fa-chevron-left',
  ChevronRigth = 'fa-chevron-right',
  ArrowLeft = 'fa-arrow-left',

  Lock = 'fa-lock',
  Globe = 'fa-globe',
  Ellipsis = 'fa-ellipsis-h',
  Close = 'fa-times',
  Filter = 'fa-filter',

  ThumbsDown = 'fa-thumbs-down',
  ThumbsUp = 'fa-thumbs-up',
  Trash = 'fa-trash-alt',
  Send = 'fa-paper-plane',
  Print = 'fa-print',

  CloseCircle = 'fa-times-circle',
  Asterisk = 'fa-asterisk',
  RightArrow = 'fa-arrow-right',
  SortFilter = 'fa-sliders-v',
  CalendarNew = 'fa-calendar-plus',
  CalendarAlt = 'fa-calendar-alt',

  Error = 'fa-ban',
  Smile = 'fa-smile',
  New = 'fa-plus-circle',
  Boxes = 'fa-th-large',
  Email = 'fa-envelope',

  Mention = 'fa-at',
  Inbox = 'fa-inbox',
  FileAttach = 'fa-paperclip',
  Group = 'fa-users',
  Stop = 'fa-hand-paper',

  Camera = 'fa-camera',
  Danger = 'fa-exclamation-triangle',
  Pencil = 'fa-pen',
  Checklist = 'fa-th-list',
  RemoveUser = 'fa-user-minus',
  AddUser = 'fa-user-plus',

  Image = 'fa-image',
  Info = 'fa-info-circle',
  Archive = 'fa-archive',
  Calendar = 'fa-calendar',
  Book = 'fa-book-open',

  CheckmarkCircle = 'fa-check-circle',
  CheckmarkSquare = 'fa-check-square',
  User = 'fa-user',
  FileUpload = 'fa-file-upload',
  Download = 'fa-arrow-to-bottom',
  Eye = 'fa-eye',

  Search = 'fa-search',
  Gear = 'fa-cog',
  Check = 'fa-check',
  Repeat = 'fa-sync-alt',
  Bell = 'fa-bell',

  Columns = 'fa-columns',
  Copy = 'fa-clone',
  Megaphone = 'fa-megaphone',
  EyeSlash = 'fa-eye-slash',
  Plus = 'fa-plus',

  Spinner = 'fa-spinner-third',
  Edit = 'fa-edit',
}

export enum IconSize {
  Small = 'Small',
  Normal = 'Normal',
  Large = 'Large',
  ExtraLarge = 'ExtraLarge',
}

interface IconProps {
  className?: string;
  iconType: IconType;
  size?: IconSize;
  spin?: boolean;
}

export const Icon: React.FunctionComponent<React.PropsWithChildren<IconProps>> = ({
  iconType,
  className = '',
  size,
  spin,
}) => {
  const sizeClass = classNames({
    [styles.iconSmall]: size === IconSize.Small,
    [styles.iconNormal]: !size || size === IconSize.Normal,
    [styles.iconLarge]: size === IconSize.Large,
    [styles.iconExtraLarge]: size === IconSize.ExtraLarge,
    'fa-spin': spin,
  });

  return <i className={`far ${iconType} ${sizeClass} ${className}`} />;
};
