import { TEXTAREA_MAX_LENGTH, TextareaFormFieldWidget } from '@process-street/subgrade/process';
import * as yup from 'yup';
import { SettingsSchemaValidators } from 'components/widgets/form-field/settings/common/settings-schema-validators';

export type TextareaSettingsSchema = TextareaFormFieldWidget['constraints'] &
  TextareaFormFieldWidget['config'] & {
    hasVariables: boolean;
  };

const { min, max } = SettingsSchemaValidators.minMax({ minConst: 0, maxConst: TEXTAREA_MAX_LENGTH });

export const textareaSettingsSchema = yup.object<TextareaSettingsSchema>({
  min,
  max,
  placeholder: SettingsSchemaValidators.placeholder(60),
  // this is a transient/hidden value used to drive a dynamic validation
  // inspiration: https://stackoverflow.com/questions/65613573/yup-how-to-validate-field-only-when-it-exists
  hasVariables: yup.boolean(),
  defaultValue: SettingsSchemaValidators.defaultStringValue(TEXTAREA_MAX_LENGTH),
});
