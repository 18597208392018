import * as React from 'react';
import { Template, TemplateRevision } from '@process-street/subgrade/process';
import { ButtonGroup, ButtonGroupProps, Icon } from 'components/design/next';
import { MiddleGroupButton } from 'app/components/focus-bar/workflow/middle-button-group/edit/middle-group-button';
import { queryString } from '@process-street/subgrade/util';
import { useNavigate } from '@process-street/adapters/navigation';

export type EditMiddleButtonGroupProps = ButtonGroupProps & {
  templateRevision: TemplateRevision;
  templateId: Template['id'];
};

export enum MiddleGroupItems {
  Build = 'Edit',
  View = 'View',
}

export const EditMiddleButtonGroup: React.FC<EditMiddleButtonGroupProps> = ({
  templateId,
  templateRevision,
  ...props
}) => {
  const navigate = useNavigate();
  const handleClick = React.useCallback(() => {
    navigate({ pathname: 'pageViewV2', search: queryString.stringify({ id: templateId }) });
  }, [navigate, templateId]);
  return (
    <ButtonGroup spacing="2" {...props} alignItems="center">
      <MiddleGroupButton isActive>{MiddleGroupItems.Build}</MiddleGroupButton>
      <Icon icon="chevron-right" size="4" color="gray.500" />
      <MiddleGroupButton onClick={handleClick}>{MiddleGroupItems.View}</MiddleGroupButton>
    </ButtonGroup>
  );
};
