import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type DeleteDataSetLinkParams = {
  dataSetId: Muid;
  savedViewId: Muid;
  linkId: Muid;
};

export type DeleteDataSetLinkResponse = void;

export const DeleteDataSetLinkMutation = {
  mutationFn: async (params: DeleteDataSetLinkParams) => {
    return axiosService
      .getAxios()
      .delete<DeleteDataSetLinkResponse>(
        `/1/data-sets/${params.dataSetId}/saved-views/${params.savedViewId}/links/${params.linkId}`,
      )
      .then(res => res.data);
  },
};

export const useDeleteDataSetLinkMutation = (
  options?: UseMutationOptions<DeleteDataSetLinkResponse, AxiosError, DeleteDataSetLinkParams>,
) => {
  return useMutation(DeleteDataSetLinkMutation.mutationFn, options);
};
