import * as React from 'react';
import {
  isFileFormFieldWidget,
  isFormFieldWidget,
  isMultiSelectFormFieldWidget,
  Widget,
} from '@process-street/subgrade/process';
import { Node, SelectorHelper } from 'directives/rules/template/task-templates-selector/selector-helper';
import { ConditionalLogicUtils, NodeStatus } from '../../utils/conditional-logic-utils';
import { match, P } from 'ts-pattern';
import { Box, Button, Divider, HStack, Icon, StackProps, Text, Tooltip, VStack } from 'components/design/next';
import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { ConditionalLogicSideMenuHelpers } from '../sidemenu/helpers';
import { NodeName } from '../node-name';
import { useConditionalLogicModalStore } from '../modal/store';

export type SideMenuSubItemProps = {
  node: Node;
  widgetsMap: Record<string, Widget>;
  nodeStatus?: NodeStatus;
  parentNodeStatus?: NodeStatus;
  ruleAffectingWidget?: ChecklistRuleDefinition;
  color?: 'brand' | 'gray';
};

export const SideMenuSubItem: React.FC<React.PropsWithChildren<SideMenuSubItemProps>> = ({
  node,
  widgetsMap,
  nodeStatus,
  parentNodeStatus,
  ruleAffectingWidget,
  color = 'brand',
}) => {
  const { selectedWidget, selectedRule, dispatch: dispatchModalState } = useConditionalLogicModalStore();

  const widget = widgetsMap[node.ref.id];
  const isParentHiddenByRule = parentNodeStatus?.isHiddenBy.has(selectedRule?.id ?? '');
  const isParentHiddenByDefault =
    selectedRule && parentNodeStatus
      ? !parentNodeStatus.isShownBy.has(selectedRule.id) && parentNodeStatus.isHiddenByDefault
      : false;

  const [_prefix, ...iconParts] = widget ? SelectorHelper.getIconForWidget(widget).split('-') : [];
  const iconName = iconParts?.join('-');
  const isRuleTrigger = !!nodeStatus?.isTriggerAt.size;
  const isSelected = selectedWidget?.header.group.id === ConditionalLogicUtils.getNodeGroupId(node);

  const iconsState = ConditionalLogicSideMenuHelpers.getVisibilityIconsState({
    selectedRule,
    ruleAffectingWidget: ruleAffectingWidget ?? null,
    nodeStatus: nodeStatus ?? null,
  });

  const iconColor = match({ isSelected, selectedWidget, isRuleTrigger })
    .with({ isSelected: true }, () => 'white')
    .with({ selectedWidget: P.not(P.nullish) }, () => `${color}.500`)
    .with({ isRuleTrigger: true }, () => 'purple.500')
    .otherwise(() => `${color}.500`);

  const handleSelectWidget = () => {
    dispatchModalState({ type: 'SET_SELECTED_WIDGET', payload: node.ref as Widget });
  };

  const containerProps: Partial<StackProps> = match({ isSelected, selectedWidget, isRuleTrigger })
    .with({ isSelected: true }, () => ({
      bgColor: `${color}.500`,
      borderColor: `${color}.500`,
      color: 'white',
      fontWeight: 'semibold',
      _hover: {
        borderColor: `${color}.600`,
        bgColor: `${color}.600`,
      },
    }))
    .with({ selectedWidget: P.not(P.nullish), isRuleTrigger: P.boolean }, () => ({
      borderColor: `${color}.500`,
      fontWeight: 'semibold',
      color: `${color}.500`,
      _hover: {
        borderColor: `${color}.600`,
        bgColor: `${color}.100`,
      },
    }))
    .otherwise(() => ({}));

  const canReceiveRule =
    isFormFieldWidget(widget) && !isMultiSelectFormFieldWidget(widget) && !isFileFormFieldWidget(widget);

  return (
    <VStack w="full" spacing="0">
      <Divider
        borderColor={isParentHiddenByRule || isParentHiddenByDefault ? 'brand.500' : 'transparent'}
        h="3"
        orientation="vertical"
      />

      <HStack
        aria-label={node.name}
        aria-current={isSelected}
        as={Button}
        cursor={canReceiveRule ? 'pointer' : 'default'}
        onClick={canReceiveRule ? handleSelectWidget : undefined}
        key={node.id}
        bgColor="white"
        borderColor="gray.200"
        borderRadius="16px"
        borderStyle="solid"
        borderWidth="1px"
        color="gray.500"
        h="9"
        justifyContent="space-between"
        position="relative"
        px="4"
        py="2"
        fontWeight="normal"
        spacing="0"
        variant="ghost"
        w="full"
        _hover={{
          borderColor: 'gray.300',
          bgColor: 'gray.100',
        }}
        opacity={isParentHiddenByRule || isParentHiddenByDefault ? '0.5' : undefined}
        _before={{}}
        {...containerProps}
      >
        <HStack w="full" justifyContent="space-between" spacing="2">
          {iconName && <Box as="i" className={`fa-${iconName} far fw`} color={iconColor} />}

          <Text flex="1" textAlign="left" fontSize="sm" fontWeight="inherit" noOfLines={1}>
            <NodeName node={node} />
          </Text>

          <HStack spacing="2" color={isSelected ? 'white' : `${color}.500`}>
            {iconsState.showTriggerIcon && (
              <Tooltip label="Form field affected by a rule (IF)">
                <Box fontSize="xs" as="i" color="inherit" className="fas fa-angle-double-right" />
              </Tooltip>
            )}

            {match(iconsState)
              .with({ showShownIcon: true }, () => (
                <Tooltip label="Form field shown by rule">
                  <Box>
                    <Icon icon="eye" size="3.5" color="inherit" />
                  </Box>
                </Tooltip>
              ))
              .with({ showHiddenIcon: true }, () => (
                <Tooltip label="Form field hidden by rule">
                  <Box>
                    <Icon icon="eye-slash" size="3.5" color="inherit" />
                  </Box>
                </Tooltip>
              ))
              .with({ showHiddenByDefaultIcon: true }, () => (
                <Tooltip label="Form field hidden by default">
                  <Box>
                    <Icon icon="eye-slash" size="3.5" color="inherit" />
                  </Box>
                </Tooltip>
              ))
              .otherwise(() => null)}
          </HStack>
        </HStack>
      </HStack>
    </VStack>
  );
};
