import { axiosService } from 'services/axios-service';
import { User } from '@process-street/subgrade/core';
import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { OnboardingSettings, SettingsKey, UserSettings } from 'components/user/user-constants';

type GetSettingsParams = { id?: User['id'] };
type GetSettingsResult = UserSettings;
export const GetSettings = {
  key: ['user', 'settings'],
  getKey: (params: GetSettingsParams) => [...GetSettings.key, params],
  queryFn: (params: GetSettingsParams) =>
    axiosService
      .getAxios()
      .get<GetSettingsResult>(`/1/users/${params.id}/settings`)
      .then(r => r.data),
};

export const useGetSettingsQuery = (
  params: GetSettingsParams,
  options: UseQueryOptions<GetSettingsResult, AxiosError> = {},
) => {
  return useQuery<GetSettingsResult, AxiosError>(GetSettings.getKey(params), () => GetSettings.queryFn(params), {
    ...options,
    enabled: Boolean(params.id) && options.enabled !== false,
  });
};

export const useGetOnboardingSettingsQuery = (
  params: GetSettingsParams,
  options: UseQueryOptions<UserSettings, AxiosError, OnboardingSettings> = {},
) => {
  return useQuery<GetSettingsResult, AxiosError, OnboardingSettings>(
    GetSettings.getKey(params),
    () => GetSettings.queryFn(params),
    {
      ...options,
      select: data => data[SettingsKey.ONBOARDING] ?? {},
    },
  );
};
