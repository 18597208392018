import angular from 'angular';
import { connectService } from '../reducers/util';

angular
  .module('frontStreetApp.services')
  .service('OrganizationDomainService', function ($ngRedux, OrganizationDomainActions) {
    connectService('OrganizationDomainService', $ngRedux, null, OrganizationDomainActions)(this);

    /**
     * @typedef {Object} OrganizationDomain
     * @property {number} id
     */

    const self = this;

    // DAO ACCESS

    /**
     * Gets all organization domains by organization id.
     *
     * @param organizationId
     * @param flushCache
     *
     * @return {Promise}
     */
    self.getAllByOrganizationId = function (organizationId, flushCache) {
      return this.actions.getAllByOrganizationId(organizationId, flushCache).then(({ payload }) => payload);
    };

    /**
     * Creates organization domain by name and organization id.
     *
     * @param organizationId
     * @param name
     *
     * @return {Promise} with created organization domain
     */
    self.createByOrganizationIdAndName = function (organizationId, name) {
      return this.actions.createByOrganizationIdAndName(organizationId, name).then(({ payload }) => payload);
    };

    /**
     * Deletes organization domain by id.
     *
     * @param organizationId
     * @param name
     *
     * @return {Promise} with delete organization domain
     */
    self.deleteByOrganizationIdAndName = function (organizationId, name) {
      return this.actions.deleteByOrganizationIdAndName(organizationId, name).then(({ payload }) => payload);
    };
  });
