import { CancellationAlternatives, CancellationReasons, Plan } from '../billing';
import { Identifiable, Muid, Ref, S3File } from './core-model';
import { Option } from './option';
import { AuditMetadata, User } from './user-model';
import { isModelRef } from './util';

export enum SubscriptionStatus {
  Unpaid = 'Unpaid',
  Trialing = 'Trialing',
  Active = 'Active',
  Canceled = 'Canceled',
  PastDue = 'PastDue',
}

export enum OrganizationMembershipRole {
  Admin = 'Admin',
  FreeMember = 'FreeMember',
  FullMember = 'FullMember',
  Guest = 'Guest',
}

export enum OrganizationMembershipStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum OrganizationMembershipErrorCodes {
  UserInactive = 'UserInactive',
}

export function isFreeMember(role: OrganizationMembershipRole): boolean;
export function isFreeMember(om: OrganizationMembership): boolean;
export function isFreeMember(roleOrOM: OrganizationMembership | OrganizationMembershipRole): boolean {
  if (typeof roleOrOM === 'string') {
    return roleOrOM === OrganizationMembershipRole.FreeMember;
  }
  return isFreeMember(roleOrOM.role);
}

export function isFullMember(role: OrganizationMembershipRole): boolean;
export function isFullMember(om: OrganizationMembership): boolean;
export function isFullMember(roleOrOM: OrganizationMembership | OrganizationMembershipRole): boolean {
  if (typeof roleOrOM === 'string') {
    return roleOrOM === OrganizationMembershipRole.FullMember;
  }
  return isFullMember(roleOrOM.role);
}

export function isGuest(role: OrganizationMembershipRole): boolean;
export function isGuest(om: OrganizationMembership): boolean;
export function isGuest(roleOrOM: OrganizationMembership | OrganizationMembershipRole): boolean {
  if (typeof roleOrOM === 'string') {
    return roleOrOM === OrganizationMembershipRole.Guest;
  }
  return isGuest(roleOrOM.role);
}

export function isAdmin(role: OrganizationMembershipRole): boolean;
export function isAdmin(om: OrganizationMembership): boolean;
export function isAdmin(roleOrOM: OrganizationMembership | OrganizationMembershipRole): boolean {
  if (typeof roleOrOM === 'string') {
    return roleOrOM === OrganizationMembershipRole.Admin;
  }
  return isAdmin(roleOrOM.role);
}

export const isOrganizationMembershipActive = (om: OrganizationMembership) =>
  om.status === OrganizationMembershipStatus.Active;

export enum SsoLoginType {
  Optional = 'Optional',
  RequiredForMembers = 'RequiredForMembers',
  RequiredForAllUsers = 'RequiredForAllUsers',
}

export interface Subscription {
  plan: Ref<Plan>;
  trialStartDate?: number;
  trialEndDate?: number;
  currentPeriodStartDate?: number;
  currentPeriodEndDate?: number;
  cancelAtPeriodEnd: boolean;
  quantity: number;
  status: SubscriptionStatus;
}

export interface SubscriptionCancellationForm {
  reason: CancellationReasons;
  alternativeSolution: CancellationAlternatives;
  additionalDetails: Option<string>;
}

export interface Organization {
  readonly id: Muid;
  audit: AuditMetadata;
  stripeId: string;
  subscription: Subscription;
  minQuantity?: number;
  maxQuantity?: number;
  cardDefined: boolean;
  name: string;
  email: string;
  industry?: string;
  logoFile?: S3File;
  settings: { [key: string]: string };
  growSumoPartnerKey?: string;
  automationUserId?: string;
  defaultSsoOrganizationMembershipLevel: OrganizationMembershipRole;
  enhancedFileSecurity: boolean;
  timeZone?: string;
  dateFormat?: string;
}

export interface OrganizationMembership {
  readonly id: Muid;
  organization: Ref<Organization>;
  user: Ref<User>;
  role: OrganizationMembershipRole;
  status: OrganizationMembershipStatus;

  /** @deprecated use role instead */
  admin: boolean;
  /** @deprecated use role instead */
  guest: boolean;
}

export interface OrganizationMembershipWithUser extends OrganizationMembership {
  user: User;
}

export function isOrganizationMembershipWithUser(
  orgMembership: Ref<OrganizationMembership>,
): orgMembership is OrganizationMembershipWithUser {
  return isModelRef(orgMembership) && isModelRef(orgMembership.user);
}

export type OrganizationMembershipWithOrganization = OrganizationMembership & { organization: Organization };

export interface OrganizationDiscount {
  amountOff: number | null;
  percentOff: number | null;
  duration: string;
  durationInMonths: number | null;
  endDate: number | null;
  startDate: number | null;
}

export interface AuthConnection {
  id: Muid;
  organization: Ref<Organization>;
  name: string;
  ssoLogin: SsoLoginType;
}

export interface OrganizationDomain {
  id: Muid;
  organization: Ref<Organization>;
  name: string;
  authConnection?: Ref<AuthConnection>;
}

export interface OrganizationStats extends Identifiable {
  id: Muid;
  templatesCreatedCount: number;
  templatesActiveCount: number;
  templatesArchivedCount: number;
  templatesDeletedCount: number;
  checklistsCreatedCount: number;
  checklistsActiveCount: number;
  checklistsArchivedCount: number;
  checklistsCompletedCount: number;
  checklistsDeletedCount: number;
  checklistsRunsCountCurrentPeriod: number;
  commentsCreatedCount: number;
  attachmentsCreatedCount: number;
  usersTotalCount: number;
  usersAdminCount: number;
  usersGuestCount: number;
  usersMemberCount: number;
  apiKeysCount: number;
  scheduledChecklistsCount: number;
}

export type OrganizationUsageStats = {
  fullMembersCount: number;
  activeTemplatesCount: number;
  activeChecklistsCount: number;
};

export type OrganizationDetails = {
  id: Muid;
  audit: AuditMetadata;
  organizationId: Muid;
  employeeCount: EmployeeCount;
};

export type OrganizationTheme = {
  id: Muid;
  audit: AuditMetadata;
  organizationId: Muid;
  brandColor: string | null;
  // Email branding
  emailCustomizationEnabled: boolean;
  emailPrimaryColor: string | null;
  emailBackgroundColor: string | null;
  emailFooter: string | null;
  emailHeaderLogoFileUrl: string | null;
  emailHeaderLogoFileId: string;
};

export type EmployeeCount = '1-29' | '30-99' | '100-249' | '250-999' | '1000+';

export const EMAIL_BRAND_FOOTER_MAX_LENGTH = 65536;
export const EMAIL_BRAND_FOOTER_DEFAULT =
  '<p>If you’re having any issues, you can contact the person who invited you, email us at <a href="mailto:support@process.st" style="color:#0079ca;text-decoration:none" target="_blank">support@process.st</a> or reply to this email.</p>';
