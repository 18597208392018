import { FormControl, FormLabel, Grid, GridItem, Switch, Tooltip } from 'components/design/next';
import { useCreateOrganizationTheme } from 'features/brand-customization/query-builder/create-organization-theme';
import {
  GetOrganizationTheme,
  useGetOrganizationTheme,
} from 'features/brand-customization/query-builder/get-organization-theme';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

export const EmailCustomizationEnabledSwitchField: React.FC<React.PropsWithChildren<unknown>> = () => {
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const queryClient = useQueryClient();

  const organizationThemeQuery = useGetOrganizationTheme({ organizationId });

  const updateOrganizationThemeMutation = useCreateOrganizationTheme({
    onSuccess: async () => {
      await queryClient.invalidateQueries(GetOrganizationTheme.getKey({ organizationId }));

      organizationThemeQuery.refetch();
    },
  });

  const isDisabled =
    !organizationThemeQuery?.data ||
    (organizationThemeQuery.data &&
      !organizationThemeQuery.data.emailCustomizationEnabled &&
      !organizationThemeQuery.data.emailPrimaryColor &&
      !organizationThemeQuery.data.emailBackgroundColor &&
      !organizationThemeQuery.data.emailHeaderLogoFileUrl);

  const isChecked = Boolean(organizationThemeQuery?.data && organizationThemeQuery.data.emailCustomizationEnabled);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (organizationThemeQuery.data) {
      updateOrganizationThemeMutation.mutate({
        organizationId,
        brandColor: organizationThemeQuery.data.brandColor,
        emailCustomizationEnabled: e.target.checked,
        emailPrimaryColor: organizationThemeQuery.data.emailPrimaryColor,
        emailBackgroundColor: organizationThemeQuery.data.emailBackgroundColor,
        emailFooter: organizationThemeQuery.data?.emailFooter ?? '',
        emailHeaderLogoFileId: organizationThemeQuery.data.emailHeaderLogoFileId,
      });
    }
  };

  return (
    <FormControl>
      <Grid w="full" mt="8" rowGap="6" templateColumns={{ base: '1fr', md: '220px 1fr' }}>
        <GridItem display="flex" alignItems="center">
          <FormLabel color="gray.600" as="label">
            Activate
          </FormLabel>
        </GridItem>
        <GridItem mt={{ base: '-4', md: '0' }}>
          <Tooltip
            isDisabled={!isDisabled}
            w="100%"
            label={`You need to set up the email branding.`}
            hasArrow
            shouldWrapChildren
          >
            <Switch size="lg" isDisabled={isDisabled} isChecked={isChecked} onChange={e => handleChange(e)} />
          </Tooltip>
        </GridItem>
      </Grid>
    </FormControl>
  );
};
