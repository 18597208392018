import { createSelector, Selector } from 'reselect';
import {
  Approval,
  ApprovalRuleSubject,
  ApprovalStatus,
  ApprovalTaskGroupsMap,
  ApprovalUtils,
} from '../../approval-rule';
import { Muid, Option } from '../../core';
import { Task, TaskStatus, TaskTemplate, TaskWithTaskTemplate } from '../../process';
import { toEntityMap } from '../reducer-utils';
import { safeEntityMapToArrayByIdsWith } from '../safe-entity-map-to-array-by-ids';
import { BaseApprovalLookupState, BaseReduxState, EntityMap, LookupMap, ObjectMap } from '../types';
import { BaseApprovalRuleSelector } from './approval-rule.selectors';
import { OrderTreeSelectors } from './order-tree.selectors';
import { BaseTaskTemplateSelector } from './task-template.selectors';
import { BaseTaskSelector } from './task.selectors';

const getEntityMap: Selector<BaseReduxState, EntityMap<Approval>> = (state: BaseReduxState) => state.entities.approval;
const getLookups: Selector<BaseReduxState, BaseApprovalLookupState> = (state: BaseReduxState) => state.lookups.approval;

const getById =
  (id: Muid): Selector<BaseReduxState, Option<Approval>> =>
  (state: BaseReduxState) =>
    state.entities.approval[id];

const getLookupMapByChecklistRevisionId: Selector<BaseReduxState, LookupMap> = (state: BaseReduxState) =>
  getLookups(state).byChecklistRevisionId;

const getIdsByChecklistRevisionId = (checklistRevisionId: Muid): Selector<BaseReduxState, Muid[]> =>
  createSelector(getLookupMapByChecklistRevisionId, (lookupMap: LookupMap) => lookupMap[checklistRevisionId] ?? []);

const getAllByChecklistRevisionId = (checklistRevisionId: Muid): Selector<BaseReduxState, Approval[]> =>
  createSelector([getEntityMap, getIdsByChecklistRevisionId(checklistRevisionId)], (entitiesMap, ruleIds): Approval[] =>
    safeEntityMapToArrayByIdsWith(entitiesMap, ruleIds),
  );

const getAllBySubjectTaskId =
  (subjectTaskId: Muid): Selector<BaseReduxState, Approval[]> =>
  (state: BaseReduxState) => {
    const subjectTask = BaseTaskSelector.getById(subjectTaskId)(state);
    if (!subjectTask) {
      return [];
    }
    const allApprovals = getAllByChecklistRevisionId(subjectTask.checklistRevision.id)(state);
    return allApprovals.filter(approval => approval.subjectTaskId === subjectTaskId);
  };

const getAllRelevantBySubjectTaskId =
  (subjectTaskId: Muid): Selector<BaseReduxState, Approval[]> =>
  (state: BaseReduxState) => {
    const allApprovals: Approval[] = getAllBySubjectTaskId(subjectTaskId)(state);
    const sortedApprovalTasks = OrderTreeSelectors.getTaskIdsSortedByOrderTree(
      allApprovals.map(approval => approval.approvalTaskId),
    )(state);
    const sortedApprovals = sortedApprovalTasks.map(
      // since sortedApprovalTasks is a derived from a map of allApprovals' ids, we can be confident in the non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      approvalTaskId => allApprovals.find(approval => approval.approvalTaskId === approvalTaskId)!,
    );
    if (sortedApprovals.length > 1 && sortedApprovals.some(approval => approval.status === ApprovalStatus.Rejected)) {
      const mostRelevantRejection = sortedApprovals.reduce((latestApproval, approval) => {
        if (approval.audit.updatedDate === latestApproval.audit.updatedDate) {
          return approval;
        }
        return latestApproval;
      }, sortedApprovals[0]);

      return [mostRelevantRejection];
    }
    return sortedApprovals;
  };

const getBySubjectTaskIdAndApprovalTaskId =
  (subjectTaskId: Muid, approvalTaskId: Muid): Selector<BaseReduxState, Option<Approval>> =>
  (state: BaseReduxState) =>
    getAllBySubjectTaskId(subjectTaskId)(state).find(approval => approval.approvalTaskId === approvalTaskId);

const getSubjectTaskIdToApprovalMapByApprovalTaskId = (
  checklistRevisionId: Muid,
  approvalTaskId: Muid,
): Selector<BaseReduxState, ObjectMap<Approval>> =>
  createSelector([getAllByChecklistRevisionId(checklistRevisionId)], approvals =>
    approvals.reduce((map: ObjectMap<Approval>, approval: Approval) => {
      if (approval.approvalTaskId === approvalTaskId) {
        map[approval.subjectTaskId] = approval;
      }
      return map;
    }, {}),
  );

const getSubjectTaskIdToApprovalsMapByChecklistRevisionId = (
  checklistRevisionId: Muid,
): Selector<BaseReduxState, ObjectMap<Approval[]>> =>
  createSelector([getAllByChecklistRevisionId(checklistRevisionId)], approvals =>
    approvals.reduce((map: ObjectMap<Approval[]>, approval: Approval) => {
      map[approval.subjectTaskId] = map[approval.subjectTaskId] ?? [];
      map[approval.subjectTaskId].push(approval);
      return map;
    }, {}),
  );

const getAllByTaskIds = (checklistRevisionId: Muid, taskIds: Muid[]): Selector<BaseReduxState, Approval[]> =>
  createSelector(getAllByChecklistRevisionId(checklistRevisionId), allChecklistRevisionApprovals =>
    allChecklistRevisionApprovals.filter(r => taskIds.includes(r.approvalTaskId)),
  );

const getApprovalSubjectTasks = (
  checklistRevisionId: Muid,
  templateRevisionId: Muid,
  approvalTaskTemplateGroupId: Muid,
  includeHidden = false,
): Selector<BaseReduxState, TaskWithTaskTemplate[]> =>
  createSelector(
    [
      BaseApprovalRuleSelector.getAllByTaskTemplateGroupId(templateRevisionId, approvalTaskTemplateGroupId),
      BaseTaskSelector.getAllWithTaskTemplateByChecklistRevisionId(checklistRevisionId),
    ],
    (approvalRules: ApprovalRuleSubject[], tasks: TaskWithTaskTemplate[]): TaskWithTaskTemplate[] => {
      const subjectTaskTemplateGroupIds = approvalRules.map(ars => ars.subjectTaskTemplateGroupId);
      return tasks.filter(
        t =>
          (includeHidden || !t.hidden) &&
          subjectTaskTemplateGroupIds.includes((t.taskTemplate as TaskTemplate).group.id),
      );
    },
  );

const areNotPermittedTasksExist = (
  checklistRevisionId: Muid,
  templateRevisionId: Muid,
  approvalTaskTemplateGroupId: Muid,
): Selector<BaseReduxState, boolean> =>
  createSelector(
    [
      BaseApprovalRuleSelector.getAllByTaskTemplateGroupId(templateRevisionId, approvalTaskTemplateGroupId),
      getApprovalSubjectTasks(checklistRevisionId, templateRevisionId, approvalTaskTemplateGroupId, true),
    ],
    (approvalRules: ApprovalRuleSubject[], tasks: Task[]): boolean => approvalRules.length > tasks.length,
  );

const getApprovalTaskGroupsMap = (
  checklistRevisionId: Muid,
  approvalTaskId: Muid,
  templateRevisionId: Muid,
  approvalTaskTemplateGroupId: Muid,
): Selector<BaseReduxState, ApprovalTaskGroupsMap> =>
  createSelector(
    [
      getApprovalSubjectTasks(checklistRevisionId, templateRevisionId, approvalTaskTemplateGroupId),
      getSubjectTaskIdToApprovalMapByApprovalTaskId(checklistRevisionId, approvalTaskId),
    ],
    (subjectTasks: TaskWithTaskTemplate[], subjectTaskIdToApprovalMap: ObjectMap<Approval>): ApprovalTaskGroupsMap =>
      ApprovalUtils.groupTaskByApprovalStatus(subjectTasks, subjectTaskIdToApprovalMap),
  );

const getApprovalTaskGroupsMapByApprovalTaskId =
  (approvalTaskId: Muid): Selector<BaseReduxState, Option<ApprovalTaskGroupsMap>> =>
  (state: BaseReduxState) => {
    const approvalTask = BaseTaskSelector.getById(approvalTaskId)(state);
    if (!approvalTask) {
      return undefined;
    }
    const approvalTaskTemplate = BaseTaskTemplateSelector.getById(approvalTask.taskTemplate.id)(state);
    if (!approvalTaskTemplate) {
      return undefined;
    }
    const subjectTasks = getApprovalSubjectTasks(
      approvalTask.checklistRevision.id,
      approvalTaskTemplate.templateRevision.id,
      approvalTaskTemplate.group.id,
    )(state);
    const subjectTaskIdToApprovalMap = getSubjectTaskIdToApprovalMapByApprovalTaskId(
      approvalTask.checklistRevision.id,
      approvalTaskId,
    )(state);
    return ApprovalUtils.groupTaskByApprovalStatus(subjectTasks, subjectTaskIdToApprovalMap);
  };

const areNotPermittedTasksExistByApprovalTaskId =
  (approvalTaskId: Muid): Selector<BaseReduxState, boolean> =>
  (state: BaseReduxState) => {
    const approvalTask = BaseTaskSelector.getById(approvalTaskId)(state);
    if (!approvalTask) {
      return false;
    }
    const approvalTaskTemplate = BaseTaskTemplateSelector.getById(approvalTask.taskTemplate.id)(state);
    if (!approvalTaskTemplate) {
      return false;
    }

    return areNotPermittedTasksExist(
      approvalTask.checklistRevision.id,
      approvalTaskTemplate.templateRevision.id,
      approvalTaskTemplate.group.id,
    )(state);
  };

const getAllApprovedTaskIds = (checklistRevisionId: Muid): Selector<BaseReduxState, Muid[]> =>
  createSelector(
    [
      getAllByChecklistRevisionId(checklistRevisionId),
      BaseTaskSelector.getAllByChecklistRevisionId(checklistRevisionId),
    ],
    (allApprovals: Approval[], tasks: Task[]) => {
      const taskMap: EntityMap<Task> = toEntityMap(tasks);
      return allApprovals.reduce((ids: Muid[], approval: Approval) => {
        const subjectTask = taskMap[approval.subjectTaskId];
        if (approval.status === ApprovalStatus.Approved && subjectTask?.status === TaskStatus.Completed) {
          ids.push(subjectTask.id);
        }
        return ids;
      }, []);
    },
  );

const isTaskLocked = (checklistRevisionId: Muid, subjectTaskId: Muid): Selector<BaseReduxState, boolean> =>
  createSelector(
    BaseTaskSelector.getById(subjectTaskId),
    getAllByChecklistRevisionId(checklistRevisionId),
    ApprovalUtils.isTaskLocked,
  );

export const BaseApprovalSelector = {
  getAllApprovedTaskIds,
  getAllByChecklistRevisionId,
  getAllByTaskIds,
  getAllBySubjectTaskId,
  getAllRelevantBySubjectTaskId,
  getApprovalSubjectTasks,
  getApprovalTaskGroupsMap,
  getApprovalTaskGroupsMapByApprovalTaskId,
  getById,
  getSubjectTaskIdToApprovalMapByApprovalTaskId,
  getSubjectTaskIdToApprovalsMapByChecklistRevisionId,
  getBySubjectTaskIdAndApprovalTaskId,
  isTaskLocked,
  areNotPermittedTasksExist,
  areNotPermittedTasksExistByApprovalTaskId,
};
