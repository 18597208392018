import { Folder, Template, TemplateStatus } from '@process-street/subgrade/process';
import { useToast } from 'components/design/next';
import { GetAllTemplatesQuery, useMoveTemplateMutation } from 'features/template/query-builder';
import { useLibraryContext } from 'pages/library/context';
import { useQueryClient } from 'react-query';

type UseMoveTemplateParams = {
  folder: Folder;
};

export const useMoveTemplate = ({ folder }: UseMoveTemplateParams) => {
  const organizationId = folder.organization.id;
  const { templateStatusView } = useLibraryContext();
  const queryClient = useQueryClient();
  const toast = useToast();

  const moveTemplateMutation = useMoveTemplateMutation({
    onMutate: ({ candidateId, toFolderId }) => {
      const queryKey = GetAllTemplatesQuery.getKey({
        organizationId,
        templateStatus: templateStatusView === 'active' ? TemplateStatus.Active : TemplateStatus.Archived,
      });
      const currentTemplates = queryClient.getQueryData(queryKey);

      // Perform optimistic update
      queryClient.setQueryData(
        queryKey,
        (data: Template[] | undefined) =>
          data?.map(template => {
            if (template.id === candidateId) {
              return {
                ...template,
                folder: {
                  id: toFolderId,
                },
              };
            }

            return template;
          }) ?? [],
      );

      // Return the currentTemplates so we can use in `onError` to revert the data in case the request fails
      return currentTemplates;
    },
    onError: (_, __, currentTemplates) => {
      const queryKey = GetAllTemplatesQuery.getKey({
        organizationId,
        templateStatus: templateStatusView === 'active' ? TemplateStatus.Active : TemplateStatus.Archived,
      });

      // Revert optimistic update
      queryClient.setQueryData(queryKey, () => currentTemplates);
      toast({
        status: 'warning',
        title: 'You don’t have permission to move to this folder',
      });
    },
  });

  return moveTemplateMutation;
};
