import { AvatarSize } from 'components/common/Avatar/AvatarSize';
import classNames from 'classnames';
import styles from 'components/common/Avatar/Avatar.module.scss';

export function useAvatarClass(size: AvatarSize) {
  const isExtraSmall = size === AvatarSize.ExtraSmall;
  const isSmall = size === AvatarSize.Small;
  const isMedium = size === AvatarSize.Medium;
  const isLarge = size === AvatarSize.Large;
  const isNormal = !isExtraSmall && !isSmall && !isMedium && !isLarge;

  const avatarClass = classNames({
    [styles.avatar]: true,
    [styles.avatarExtraSmall]: isExtraSmall,
    [styles.avatarSmall]: isSmall,
    [styles.avatarNormal]: isNormal,
    [styles.avatarMedium]: isMedium,
    [styles.avatarLarge]: isLarge,
  });

  const imgClass = classNames({
    [styles.img]: true,
    [styles.imgExtraSmall]: isExtraSmall,
    [styles.imgSmall]: isSmall,
    [styles.imgNormal]: isNormal,
    [styles.imgLarge]: isLarge,
    [styles.imgMedium]: isMedium,
  });
  const initialsClass = classNames({
    [styles.initials]: true,
    [styles.initialsExtraSmall]: isExtraSmall,
    [styles.initialsSmall]: isSmall,
    [styles.initialsNormal]: isNormal,
    [styles.initialsMedium]: isMedium,
    [styles.initialsLarge]: isLarge,
  });

  const unconfirmedClass = classNames({
    [styles.unconfirmed]: true,
    [styles.unconfirmedSmall]: isExtraSmall || isSmall,
    [styles.unconfirmedNormal]: isNormal,
    [styles.unconfirmedMedium]: isMedium,
    [styles.unconfirmedLarge]: isLarge,
  });

  const badgeClass = classNames({
    [styles.avatarBadge]: true,
    [styles.avatarBadgeSmall]: isSmall || isExtraSmall,
    [styles.avatarBadgeNormal]: isNormal,
    [styles.avatarBadgeMedium]: isMedium,
    [styles.avatarBadgeLarge]: isLarge,
  });

  return {
    imgClass,
    initialsClass,
    badgeClass,
    avatarClass,
    unconfirmedClass,
  };
}
