import { DataSet } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateDataSetMutationParams = {};

export type CreateDataSetMutationResponse = DataSet;

export const CreateDataSetMutation = {
  mutationFn: async (_: CreateDataSetMutationParams) => {
    return axiosService
      .getAxios()
      .post<CreateDataSetMutationResponse>(`/1/data-sets`)
      .then(res => res.data);
  },
};

export const useCreateDataSetMutation = (
  options: UseMutationOptions<CreateDataSetMutationResponse, AxiosError, CreateDataSetMutationParams>,
) => {
  return useMutation(CreateDataSetMutation.mutationFn, options);
};
