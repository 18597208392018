import { MuidUtils } from '@process-street/subgrade/core';
import create from 'zustand';
import produce from 'immer';
import { CustomNotificationUtils } from './custom-notification-utils';
import { NativeAutomation } from '@process-street/subgrade/process';

type Timing = CustomNotificationUtils.Timing;
type Config = CustomNotificationUtils.Config;

type CustomNotificationsState = {
  configs: Partial<Config & { isNew?: true }>[];
  view: 'list' | 'create';
};

type CustomNotificationsActions = {
  setConfigs: (configs: Partial<Config>[]) => void;
  createNewConfig: () => void;
  promoteNewConfig: () => void;
  discardNewConfig: () => void;
  removeConfig: (config: Partial<Config>) => void;
  updateRecipients: (config: Partial<Config>, recipients: NativeAutomation.SendEmailActionRecipient[]) => void;
  updateTiming: (config: Partial<Config>, timing: Timing | undefined) => void;
  updateCustomMessage: (config: Partial<Config>, message: string) => void;
};

type CustomNotificationsStore = CustomNotificationsState & CustomNotificationsActions;

const updateConfig = (store: CustomNotificationsStore, config: Partial<Config>, update: Partial<Config>) => {
  return produce(store, draft => {
    draft.configs = store.configs.map(c =>
      c.nativeAutomationId === config.nativeAutomationId ? { ...c, ...update } : c,
    );
  });
};

const getNewConfig = (): CustomNotificationsStore['configs'][0] => ({
  nativeAutomationId: MuidUtils.randomMuid(),
  isNew: true,
});

export const useCustomNotificationsStore = create<CustomNotificationsStore>((set, get) => ({
  view: 'list',
  configs: [],
  setConfigs: configs => set({ configs }),
  createNewConfig: () => set({ view: 'create', configs: [...get().configs, getNewConfig()] }),
  discardNewConfig: () => set({ view: 'list', configs: get().configs.filter(c => !c.isNew) }),
  promoteNewConfig: () => {
    const { configs } = get();
    const newConfig = configs.find(c => c.isNew);
    if (CustomNotificationUtils.isConfigValid(newConfig)) {
      set({
        view: 'list',
        configs: configs.map(({ isNew: _, ...config }) => config),
      });
    }
  },

  removeConfig: config =>
    set({ configs: get().configs.filter(c => c.nativeAutomationId !== config.nativeAutomationId) }),

  updateRecipients: (config, recipients) => {
    set(current => updateConfig(current, config, { recipients }));
  },

  updateTiming: (config, timing) => {
    set(current => updateConfig(current, config, { timing }));
  },

  updateCustomMessage: (config, customMessage) => {
    set(current => updateConfig(current, config, { customMessage }));
  },
}));

export const Selectors = {
  getNewConfig: (state: CustomNotificationsState) => state.configs.find(c => c.isNew),
};
