import angular from 'angular';

angular.module('frontStreetApp.dao').service('TodoDao', function (Todos) {
  this.updateAllStatuses = function (todos, status) {
    return Todos.updateStatuses({ todos, newStatus: status }).$promise;
  };

  this.updateAllDueDates = function (todos, dueDate) {
    return Todos.updateDueDates({ todos, dueDate }).$promise;
  };
});
