import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { match } from 'ts-pattern';

export const useRoleButtonWidth = (role: OrganizationMembershipRole): number => {
  return match(role)
    .with(OrganizationMembershipRole.Admin, () => 16)
    .with(OrganizationMembershipRole.FullMember, () => 20)
    .with(OrganizationMembershipRole.FreeMember, () => 34)
    .with(OrganizationMembershipRole.Guest, () => 36)
    .otherwise(() => 34);
};
