import * as React from 'react';
import { TemplateSettingsModalProvider } from 'pages/templates/_id/components/template-settings-modal/template-settings-modal-context';
import { useWorkflowState } from './use-workflow-state';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { match, P } from 'ts-pattern';
import { FocusBarSkeleton } from './focus-bar-skeleton';
import { AiGeneratedWorkflowSettingsModal } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal';
import { useMatch, useStateParams } from 'app/adapters/navigation';
import { Muid } from '@process-street/subgrade/core';

export const REFETCH_TIMEOUT = 500;

export const WorkflowFocusBarWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const workflowState = useWorkflowState();
  const editable = workflowState === 'edit';
  const stateParams = useStateParams() ?? {};
  const templateId = stateParams.id as Muid;
  const draftTmplRevIdRef = React.useRef<null | string>(null);
  const newestTemplateRevisionQuery = useNewestTemplateRevisionQuery(
    { templateId, editable },
    {
      // Angular is in charge of creating the template revision Draft, so we refetch until it's defined
      refetchInterval: draftTmplRevIdRef.current ? false : REFETCH_TIMEOUT,
      onSuccess: data => {
        if (data) {
          draftTmplRevIdRef.current = data.id;
        }
      },
    },
  );

  const isTemplatePage = useMatch('template');
  return match(newestTemplateRevisionQuery)
    .with({ status: 'loading' }, { status: 'success', data: P.nullish }, () => <FocusBarSkeleton />)
    .with({ status: 'success', data: P.not(P.nullish) }, ({ data: { id: templateRevisionId } }) => (
      <TemplateSettingsModalProvider templateId={templateId} templateRevisionId={templateRevisionId}>
        {children}

        {isTemplatePage && (
          <AiGeneratedWorkflowSettingsModal templateId={templateId} templateRevisionId={templateRevisionId} />
        )}
      </TemplateSettingsModalProvider>
    ))
    .otherwise(() => null);
};
