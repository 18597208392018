import { TypeOfEnumValue } from '@process-street/subgrade/core';
import { DateFormFieldWidget } from '../widget-model';

function dateFormFieldCIF<
  Code extends string,
  Config extends Record<
    string,
    { code: Code; isInvalid: (constraints: NonNullable<DateFormFieldWidget['constraints']>, value: number) => boolean }
  >,
>(config: Config): Config {
  return config;
}

export const DateFormFieldValidation = dateFormFieldCIF({
  // TODO: when removing CustomDateValidationWithDynamicParameters use only calculated dates (and test it!)
  notInBetween: {
    code: 'not-in-between',
    isInvalid: (constraints, value) => {
      const afterDate = constraints.calculatedAfterDate || constraints.afterDate;
      const beforeDate = constraints.calculatedBeforeDate || constraints.beforeDate;
      return !!afterDate && !!beforeDate && !(value > afterDate && value < beforeDate);
    },
  },
  afterDate: {
    code: 'after-date',
    isInvalid: (constraints, value) => {
      const afterDate = constraints.calculatedAfterDate || constraints.afterDate;
      return !!afterDate && !(value > afterDate);
    },
  },
  beforeDate: {
    code: 'before-date',
    isInvalid: (constraints, value) => {
      const beforeDate = constraints.calculatedBeforeDate || constraints.beforeDate;
      return !!beforeDate && !(value < beforeDate);
    },
  },
});

export const getDateFormFieldWidgetErrorType = (constraints: DateFormFieldWidget['constraints'], value: number) => {
  if (!value || !constraints) return;
  return Object.values(DateFormFieldValidation).find(config => config.isInvalid(constraints, value))?.code ?? undefined;
};
export type DateFormFieldValidationCode = TypeOfEnumValue<typeof DateFormFieldValidation>['code'] | undefined;
