import { combineReducers } from 'redux';
import { handleActionsOnSuccess } from 'reducers/util';
import { EntitiesReducerUtils } from '@process-street/subgrade/redux/entities-reducer-utils';
import { LookupsReducerUtils } from '@process-street/subgrade/redux/lookups-reducer-utils';
import { TASK_TEMPLATE_DELETE_ALL } from 'reducers/task-template/task-template.actions';
import {
  TASK_TEMPLATE_PERMIT_UPSERT,
  TASK_TEMPLATE_PERMIT_UPSERT_ALL,
  TASK_TEMPLATE_PERMIT_GET_ALL_BY_TEMPLATE_REVISION,
} from './task-template-permit.actions';

export const taskTemplatePermitEntitiesReducer = handleActionsOnSuccess(
  {
    [TASK_TEMPLATE_PERMIT_GET_ALL_BY_TEMPLATE_REVISION]: (state, { payload: { permits } }) =>
      EntitiesReducerUtils.upsertAll(state, permits),
    [TASK_TEMPLATE_PERMIT_UPSERT]: (state, { payload }) => EntitiesReducerUtils.upsertAll(state, payload),
    [TASK_TEMPLATE_PERMIT_UPSERT_ALL]: (state, { payload }) => EntitiesReducerUtils.upsertAll(state, payload),
    [TASK_TEMPLATE_DELETE_ALL]: (state, { meta: { relatedPermissions } }) =>
      EntitiesReducerUtils.deleteAll(state, relatedPermissions),
  },
  {},
);

const taskTemplatePermitLookupByTaskTemplateIdReducer = handleActionsOnSuccess(
  {
    [TASK_TEMPLATE_PERMIT_GET_ALL_BY_TEMPLATE_REVISION]: (state, { payload: { permits } }) =>
      LookupsReducerUtils.replaceAll(state, permits, 'taskTemplate.id', 'id'),
    [TASK_TEMPLATE_PERMIT_UPSERT]: (state, { payload }) =>
      LookupsReducerUtils.upsertAll(state, payload, 'taskTemplate.id', 'id'),
    [TASK_TEMPLATE_PERMIT_UPSERT_ALL]: (state, { payload }) =>
      LookupsReducerUtils.upsertAll(state, payload, 'taskTemplate.id', 'id'),
    [TASK_TEMPLATE_DELETE_ALL]: (state, { meta: { taskTemplates } }) =>
      LookupsReducerUtils.deleteAllKeysByIds(
        state,
        taskTemplates.map(tt => tt.id),
      ),
  },
  {},
);

export const taskTemplatePermitLookupsReducer = combineReducers({
  byTaskTemplateId: taskTemplatePermitLookupByTaskTemplateIdReducer,
});
