import angular from 'angular';
import { HttpStatus } from '@process-street/subgrade/util';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.controllers')
  .controller('ChecklistUnsubscribeCtrl', function ($scope, $state, ChecklistSubscriptions) {
    if ($state.params.id) {
      ChecklistSubscriptions.delete({
        id: $state.params.id,
      }).$promise.then(
        () => {
          AnalyticsService.trackEvent('checklist subscription deleted');
          $scope.outcome = 'success';
        },
        response => {
          switch (response.status) {
            case HttpStatus.NOT_FOUND:
              $scope.outcome = 'success';
              break;
            default:
              $scope.outcome = 'failure';
          }
        },
      );
    } else {
      $scope.outcome = 'failure';
    }

    $scope.redirect = function () {
      $state.go('dashboard');
    };
  });
