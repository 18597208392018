import { isEditorReadOnly, toDOMRange } from '@udecode/slate-react';
import { getSelectionText, isSelectionExpanded } from '@udecode/slate-utils';
import { Box } from 'components/design/next';
import * as React from 'react';
import { usePopper } from 'react-popper';
import { Range as SlateRange } from 'slate';
import { usePagesEditorState } from '../../pages-plate-types';
import { useSelectionContext } from '../../selection-context';
import { useBalloonToolbarControls } from './context';

export const BalloonToolbar: React.FC<React.PropsWithChildren<unknown>> = React.memo(({ children }) => {
  const editor = usePagesEditorState();
  const { forcedState } = useBalloonToolbarControls();
  const [show, setShow] = React.useState(false);
  const editable = !isEditorReadOnly(editor);

  const selectionExpanded = isSelectionExpanded(editor);
  const selectionText = getSelectionText(editor);
  const { lastSelection } = useSelectionContext();
  const selectionRef = React.useRef(lastSelection);
  const hasSelectedText = selectionExpanded && selectionText?.length > 0;

  const rangeRef = React.useRef<Range>();
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const { styles, attributes, update } = usePopper(rangeRef.current, popperElement, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  React.useEffect(() => {
    if (!lastSelection) return;
    if (!selectionRef.current) {
      selectionRef.current = lastSelection;
    }
    if (SlateRange.equals(lastSelection, selectionRef.current)) return;

    selectionRef.current = lastSelection;
    rangeRef.current = toDOMRange(editor, lastSelection);
    update?.();
  }, [lastSelection, editor, update]);

  React.useEffect(() => {
    switch (forcedState) {
      case 'closed': {
        setShow(false);
        break;
      }
      case 'open': {
        setShow(true);
        break;
      }
      default: {
        setShow(hasSelectedText);
      }
    }
  }, [hasSelectedText, forcedState]);

  return show && editable ? (
    <Box
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      zIndex="popover"
      bgColor="gray.700"
      borderRadius="lg"
      w="auto"
    >
      <Box ml="0" display="flex" py="0" px="2">
        {children}
      </Box>
    </Box>
  ) : null;
});
