import * as React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { TopbarContext } from './context';
import { Topbar } from './topbar';

export interface TopbarWrapperProps {
  switchOrganization: (orgId: Muid) => void;
}

export const TopbarWrapper = (context: TopbarWrapperProps) => {
  return (
    <TopbarContext.Provider value={{ ...context }}>
      <Topbar />
    </TopbarContext.Provider>
  );
};
