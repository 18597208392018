import * as React from 'react';
import { useFeatureFlag } from 'features/feature-flags';
import { DataSetPage } from './data-set-page';
import { DataSetPageV1 } from 'pages/reports/data-sets/components/data-set-page/data-set-page-v1';
import { ThemeProvider2024 } from 'components/design/next/theme-provider-2024';

export const DataSetPageWrapper: React.FC = () => {
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');
  return isDataSetsV2Enabled ? (
    <ThemeProvider2024>
      <DataSetPage />
    </ThemeProvider2024>
  ) : (
    <DataSetPageV1 />
  );
};
