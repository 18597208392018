import React, { useState } from 'react';

import { UpgradeModal } from 'components/approvals/components/UpgradeModal';
import styles from './TaskApprovalButton.module.scss';

export interface TaskApprovalButtonProps {
  admin: boolean;
  featureAvailable: boolean;
  onAddClick: () => void;
  onUpgradeClick: () => void;
}

export const TaskApprovalButton: React.FunctionComponent<React.PropsWithChildren<TaskApprovalButtonProps>> = ({
  admin,
  featureAvailable,
  onAddClick,
  onUpgradeClick,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const hideModal = () => setModalVisible(false);
  const showModal = () => setModalVisible(true);

  const handleClick = () => {
    if (featureAvailable) {
      onAddClick();
    } else {
      showModal();
    }
  };

  return (
    <span className={styles.container}>
      <a title="Add Approval" onClick={handleClick} className={`btn btn-default ${styles.button}`}>
        <i className="far fa-thumbs-up" />
        <span className={`visible-xl-inline ${styles.label}`}>Approval</span>
      </a>

      <UpgradeModal admin={admin} visible={modalVisible} onHide={hideModal} onUpgradeClick={onUpgradeClick} />
    </span>
  );
};
