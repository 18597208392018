import angular from 'angular';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'UserActivityCtrl',
    function (
      $scope,
      $state,
      ActivityService,
      SecurityService,
      SessionService,
      OrganizationMembershipService,
      UserActivityService,
      UserService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'UserActivityCtrl' });
      logger.info('loading ctrl');

      let userIsAdmin;
      ctrl.$onInit = () => {
        const user = SessionService.getUser();
        const selectedOrganizationId = SecurityService.getSelectedOrganizationIdByUser(user);
        OrganizationMembershipService.isAdminByUserIdAndOrganizationId(user.id, selectedOrganizationId).then(
          currentUserIsAdmin => {
            userIsAdmin = currentUserIsAdmin;
            $scope.loadOlderActivities();
          },
        );
      };

      const PAGE_SIZE = 20;

      $scope.loadOlderActivities = function () {
        $scope.loadingSentences = true;

        const userId = $state.params.id === 'me' ? UserService.getCurrentUser().id : $state.params.id;

        const oldSentences = $scope.sentences || [];
        const offsetId = oldSentences[oldSentences.length - 1]?.activity?.id;
        const selectedOrganizationId = SessionService.getSelectedOrganizationId();

        return UserActivityService.getActivitiesByIdOrganizationIdLimitAndOffsetId(
          userId,
          selectedOrganizationId,
          PAGE_SIZE,
          offsetId,
        )
          .then(result => {
            $scope.hiddenTotal = result.hiddenTotal;

            $scope.sentences = $scope.sentences || [];

            if (result.data.length) {
              const newSentences = result.data.map(UserActivityService.interpretSimple).filter(a => a);
              $scope.sentences = $scope.sentences || [];
              $scope.sentences = $scope.sentences.concat(newSentences);
              $scope.moreSentences = result.data.length === PAGE_SIZE;
            } else {
              $scope.moreSentences = false;
            }
          })
          .finally(() => {
            $scope.loadingSentences = false;
          });
      };

      $scope.isUndeletable = function (activity) {
        return userIsAdmin && ActivityService.isUndeletable(activity);
      };

      $scope.undelete = function (activity) {
        ActivityService.undelete(activity);
      };
    },
  );
