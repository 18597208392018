import { Muid } from '@process-street/subgrade/core';
import { TaskMachine } from 'pages/responses/_id/components/task/task-machine';
import { ActorRefFrom } from 'xstate';

const getPreviousTaskIndex = (
  taskActorsMap: Record<Muid, ActorRefFrom<TaskMachine>>,
  currentTaskActor?: ActorRefFrom<TaskMachine>,
) => {
  const currentTaskIndex = getCurrentTaskIndex(taskActorsMap, currentTaskActor);

  return currentTaskIndex - 1;
};

const getVisibleTasks = (taskActorsMap: Record<Muid, ActorRefFrom<TaskMachine>>) => {
  // implicitly relies on chronological order of tasks actors that have been added to the map in order
  return Object.values(taskActorsMap).filter(actor => !taskActorIsHiddenByRule(actor));
};

const getPreviousTaskActor = (
  taskActorsMap: Record<Muid, ActorRefFrom<TaskMachine>>,
  currentTaskActor?: ActorRefFrom<TaskMachine>,
) => {
  const previousTaskIndex = getPreviousTaskIndex(taskActorsMap, currentTaskActor);
  const taskActors = getVisibleTasks(taskActorsMap);

  return taskActors[previousTaskIndex];
};

const taskActorIsHiddenByRule = (taskActor: ActorRefFrom<TaskMachine>) => {
  return Boolean(taskActor.getSnapshot()?.matches('visibility.hidden'));
};

const getCurrentTaskIndex = (
  taskActorsMap: Record<Muid, ActorRefFrom<TaskMachine>>,
  currentTaskActor?: ActorRefFrom<TaskMachine>,
) => {
  const taskActors = getVisibleTasks(taskActorsMap);
  return taskActors.findIndex(taskActor => taskActor === currentTaskActor);
};

const getNextTaskIndex = (
  taskActorsMap: Record<Muid, ActorRefFrom<TaskMachine>>,
  currentTaskActor?: ActorRefFrom<TaskMachine>,
) => {
  const currentTaskIndex = getCurrentTaskIndex(taskActorsMap, currentTaskActor);

  return currentTaskIndex + 1;
};

const getNextTaskActor = (
  taskActorsMap: Record<Muid, ActorRefFrom<TaskMachine>>,
  currentTaskActor?: ActorRefFrom<TaskMachine>,
) => {
  const nextTaskIndex = getNextTaskIndex(taskActorsMap, currentTaskActor);
  const taskActors = getVisibleTasks(taskActorsMap);

  return taskActors[nextTaskIndex];
};

const hasMoreTasks = (
  taskActorsMap: Record<Muid, ActorRefFrom<TaskMachine>>,
  currentTaskActor?: ActorRefFrom<TaskMachine>,
) => {
  const taskActors = getVisibleTasks(taskActorsMap);
  const nextTaskIndex = getNextTaskIndex(taskActorsMap, currentTaskActor);

  return nextTaskIndex < taskActors.length;
};

export const FormMachineUtils = {
  getVisibleTasks,
  getPreviousTaskIndex,
  getPreviousTaskActor,
  getCurrentTaskIndex,
  getNextTaskIndex,
  getNextTaskActor,
  hasMoreTasks,
  taskActorIsHiddenByRule,
};
