import { createUsableContext } from '@process-street/subgrade/util';

export type AiGeneratedModalSettingsContextValue = {
  name: string;
  description: string;
  onInputChange: (name: string) => void;
};

export const [useAiGeneratedModalSettingsContext, AiGeneratedModalSettingsContext] =
  createUsableContext<AiGeneratedModalSettingsContextValue>({
    hookName: 'useAiGeneratedModalSettingsContext',
    providerName: 'AiGeneratedModalSettingsContext',
  });
