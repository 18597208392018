import * as React from 'react';
import { Box, HStack, Spinner, Text, VStack } from 'components/design/next';
import { useFormResponseCountQuery } from 'pages/forms/_id/shared/use-form-responses-count-query';
import { FormResponsesTable } from '../form-responses-table';
import { FormResponsesFilters } from '../form-responses-filters';
import { TOP_BAR_HEIGHT_CSS_VAR } from 'pages/forms/_id/shared';
import { Spacer } from '@chakra-ui/react';
import { FormResponsesExportButton } from 'pages/forms/_id/responses/components/form-responses-page-body/export-button';
import { match, P } from 'ts-pattern';

export interface FormResponsesPageBodyProps {}

export const TABLE_HEADER_HEIGHT = 26;
export const PAGE_BODY_SPACING = 6;

const toPsVar = (size: number) => `var(--ps-sizes-${size})`;

const TABLE_HEIGHT = `calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR} - ${toPsVar(TABLE_HEADER_HEIGHT)} - ${toPsVar(
  PAGE_BODY_SPACING,
)})`;

export const FormResponsesPageBody: React.FC<React.PropsWithChildren<FormResponsesPageBodyProps>> = () => {
  const countQuery = useFormResponseCountQuery();

  return (
    <VStack role="main" w="full" alignItems="flex-start" spacing={PAGE_BODY_SPACING}>
      <HStack
        bg="white"
        w="full"
        h={TABLE_HEADER_HEIGHT}
        borderBottom="1px solid"
        borderBottomColor="gray.200"
        px={{ base: 4, md: 8 }}
        py="5"
      >
        <VStack alignItems="flex-start">
          <HStack alignItems="baseline">
            <Text variant="2" color="gray.600" fontWeight="bold">
              Responses
            </Text>

            <Text variant="-1" color="gray.500">
              {match(countQuery)
                .with({ status: 'loading' }, () => <Spinner as="span" size="xs" />)
                .with({ status: 'success', data: { total: P.select('total') } }, ({ total }) => `(${total})`)
                .otherwise(() => null)}
            </Text>
          </HStack>

          <Box>
            {/* offset ghost button padding so left icon lines up with title */}
            <Box ml="-2">
              <FormResponsesFilters />
            </Box>
          </Box>
        </VStack>

        <Spacer />

        <FormResponsesExportButton />
      </HStack>
      <FormResponsesTable h={TABLE_HEIGHT} px={{ base: 4, md: 8 }} />
    </VStack>
  );
};
