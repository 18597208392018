import * as yup from 'yup';
import {
  EmailFormFieldWidget,
  isValidEmailDomain,
  TEXT_MAX_LENGTH,
  URL_REGEXP,
} from '@process-street/subgrade/process';
import { SettingsSchemaValidators } from 'components/widgets/form-field/settings/common/settings-schema-validators';

const characterLimits = () => ({
  min: yup
    .number()
    .min(0)
    .when('max', {
      is: max => max === undefined || max === '',
      then: (schema: yup.NumberSchema) => schema.max(Number.MAX_VALUE),
      otherwise: (schema: yup.NumberSchema) => schema.max(yup.ref('max'), 'Min must be less than max.'),
    }),
  max: yup.number().min(0).max(Number.MAX_VALUE),
});

const emailDomains = () => ({
  restriction: yup.string().oneOf(['Allow', 'Block'] as EmailFormFieldWidget['constraints']['restriction'][]),
  domains: yup.array().of(
    yup
      .string()
      .test({
        name: 'email-domain',
        message: ({ value }) => `Domain '${value}' is invalid`,
        test: value => isValidEmailDomain(value),
      })
      .required(),
  ),
});

const placeholder = (maxLength: number) => ({
  placeholder: yup.string().max(maxLength, `Placeholder must be less than ${maxLength} characters`),
});

const defaultValue = (maxLength: number, minKey = '$constraints.min', maxKey = '$constraints.max') => ({
  hasVariables: yup.boolean(),
  defaultValue: yup.string().when('$hasVariables', {
    is: true,
    then: (schema: yup.StringSchema) => schema.max(maxLength).min(0),
    otherwise: (schema: yup.StringSchema) =>
      schema
        .when(minKey, (min: number | undefined, schema: yup.StringSchema) =>
          typeof min === 'number' ? schema.min(min, `Default value must be at least ${min} characters`) : schema.min(0),
        )
        .when(maxKey, (max: number | undefined, schema: yup.StringSchema) =>
          typeof max === 'number'
            ? schema.max(max, `Default value must be less than ${max} characters`)
            : schema.max(maxLength),
        ),
  }),
});

const defaultValueUrl = () => ({
  hasVariables: yup.boolean(),
  defaultValue: yup.string().when('$hasVariables', {
    is: true,
    then: (schema: yup.StringSchema) => schema.max(TEXT_MAX_LENGTH).min(0),
    otherwise: (schema: yup.StringSchema) =>
      schema
        .max(TEXT_MAX_LENGTH, `Default value must be less than ${TEXT_MAX_LENGTH} characters`)
        .test('is-valid-url', 'Invalid URL', value => !value || URL_REGEXP.test(value)),
  }),
});

const { min: minDigits, max: maxDigits } = SettingsSchemaValidators.minMax({
  minConst: 0,
  maxConst: Number.MAX_VALUE,
  maxKey: 'maxDigits',
});

const { min: minValue, max: maxValue } = SettingsSchemaValidators.minMax({
  minConst: 0,
  maxConst: Number.MAX_VALUE,
  maxKey: 'maxValue',
});

const snippetValue = (maxLength: number) => ({
  value: yup.string().max(maxLength, `The value must be less than ${maxLength} characters`),
});

export const FormFieldWidgetSettingsSchemas = {
  characterLimits,
  defaultValue,
  defaultValueUrl,
  emailDomains,
  placeholder,
  maxDigits,
  minDigits,
  maxValue,
  minValue,
  snippetValue,
};
