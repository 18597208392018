import { BackgroundImage } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { HttpStatus } from '@process-street/subgrade/util';
import { AxiosError } from 'axios';
import { useQuery as useRQ, UseQueryOptions, QueryKey } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetBackgroundImageQuery {
  export type Params = { templateId: Template['id'] };

  export type Response = BackgroundImage | undefined;

  export const key = ['template', 'background-image'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/templates/${params.templateId}/background-image`, {
        validateStatus: status => {
          return status === HttpStatus.OK || status === HttpStatus.NOT_FOUND;
        },
      })
      .then(res => {
        if (res.status === HttpStatus.NOT_FOUND) {
          return undefined;
        } else {
          return res.data;
        }
      });

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.templateId) && options.enabled !== false,
    });
  };
}
