import { HStack, Icon, Image, MotionWrapper, Text, VStack } from 'components/design/next';
import { Auth0Constants } from '@process-street/subgrade/auth/auth0-constants';
import { LogoSpinner } from 'components/common/LogoSpinner';
import { MotionProps } from 'framer-motion';
import * as React from 'react';
import { match } from 'ts-pattern';

export const ssoProviders: Record<string, string> = {
  ...Auth0Constants.ConnectionLabels,
  sso: 'SSO',
};

export type MicrosoftTeamsIdentityProviderLoadingAnimationProps = {
  provider?: string;
  leftImageMotionProps?: MotionProps;
  rightImageMotionProps?: MotionProps;
  iconContainerMotionProps?: MotionProps;
  textMotionProps?: MotionProps;
  text?: React.ReactNode;
  icon?: React.ReactNode;
};

export const MicrosoftTeamsIdentityProviderLoadingAnimation: React.VFC<
  MicrosoftTeamsIdentityProviderLoadingAnimationProps
> = ({
  provider,
  leftImageMotionProps,
  rightImageMotionProps,
  iconContainerMotionProps,
  textMotionProps,
  text = 'Loading',
  icon,
}) => {
  const providerName = ssoProviders[provider ?? ''];

  return (
    <VStack w="full" justifyContent="center" alignItems="center" minHeight="100vh" spacing="6">
      {!providerName && <LogoSpinner />}

      {providerName && (
        <>
          <HStack w="full" justifyContent="center" alignItems="center">
            <MotionWrapper
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                ease: 'easeInOut',
                delay: 0.2,
                duration: 0.5,
              }}
              {...leftImageMotionProps}
            >
              <Image src={require('app/images/logo-square.png')} w="80px" />
            </MotionWrapper>

            {icon ? (
              icon
            ) : (
              <MotionWrapper
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  ease: 'easeInOut',
                  delay: 1,
                  duration: 0.4,
                }}
                {...iconContainerMotionProps}
              >
                <Icon icon="check" color="green.500" size="8" mb="-8" mr="0.5" />
              </MotionWrapper>
            )}

            <MotionWrapper
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                ease: 'easeInOut',
                delay: 0.3,
                duration: 0.5,
              }}
              {...rightImageMotionProps}
            >
              {match(provider)
                .with(Auth0Constants.Connection.Google, () => (
                  <Image alt="Google" src={require('app/images/providers/google-logo.svg')} w="80px" />
                ))
                .with(Auth0Constants.Connection.Microsoft, () => (
                  <Image alt="Microsoft" src={require('app/images/providers/microsoft-logo.svg')} w="80px" />
                ))
                .otherwise(() => (
                  <Icon icon="lock" color="gray.600" size="20" />
                ))}
            </MotionWrapper>
          </HStack>

          {text && (
            <MotionWrapper
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                ease: 'easeInOut',
                delay: 0.6,
                duration: 0.5,
              }}
              {...textMotionProps}
            >
              <Text>{text}</Text>
            </MotionWrapper>
          )}
        </>
      )}
    </VStack>
  );
};
