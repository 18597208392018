import { useSelector } from '@xstate/react';
import { useFunctionRef } from 'hooks/use-function-ref';
import React from 'react';
import { match } from 'ts-pattern';
import { useUIActorRef } from './ui-context';
import { ContextDisclosureName } from './ui-machine';

export const useUiDisclosure = (
  disclosureName: ContextDisclosureName,
  options: Partial<{ onOpen: () => void; onClose: () => void }> = {},
) => {
  const { uiActorRef } = useUIActorRef();
  const disclosureActor = useSelector(uiActorRef, s => s.context[`${disclosureName}Disclosure`]);
  const isOpen = useSelector(disclosureActor, s => s.matches('open'));
  const onCloseProp = useFunctionRef(options.onClose);
  const onOpenProp = useFunctionRef(options.onOpen);

  // Call the onOpen/onClose props if the open/close events are initiated by other actors programmatically
  React.useEffect(() => {
    return disclosureActor.subscribe(state => {
      match(state.event)
        .with({ type: 'OPEN' }, () => onOpenProp.current?.())
        .with({ type: 'CLOSE' }, () => onCloseProp.current?.())
        .otherwise(() => {});
    }).unsubscribe;
  }, [disclosureActor, onCloseProp, onOpenProp]);

  return React.useMemo(() => {
    return {
      isOpen,
      onOpen: () => {
        disclosureActor.send('OPEN');
      },
      onClose: () => {
        disclosureActor.send('CLOSE');
      },
    };
  }, [disclosureActor, isOpen]);
};
