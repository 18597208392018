import { FieldType, Widget, WidgetType, Template } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { SharedContext } from '../../../shared';
import { FormFieldMachineFactories } from '../../components/form-fields';
import { spawn } from 'xstate';
import { ContentMachineFactories } from '../../components';

export function spawnWidgetMachine(
  widget: Widget,
  template: Template,
  sharedContext: SharedContext,
  isReadOnly: boolean = false,
) {
  return match(widget)
    .with({ fieldType: FieldType.Text }, widget => {
      const machine = FormFieldMachineFactories.Text({ widget, template, sharedContext, isReadOnly });
      return spawn(machine, {
        name: machine.id,
      });
    })
    .with({ fieldType: FieldType.Email }, widget =>
      spawn(FormFieldMachineFactories.Email({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Textarea }, widget =>
      spawn(FormFieldMachineFactories.Textarea({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Url }, widget =>
      spawn(FormFieldMachineFactories.Url({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Select }, widget =>
      spawn(FormFieldMachineFactories.Select({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.MultiChoice }, widget =>
      spawn(FormFieldMachineFactories.Select({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.File }, widget =>
      spawn(FormFieldMachineFactories.File({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Date }, widget =>
      spawn(FormFieldMachineFactories.Date({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Number }, widget =>
      spawn(FormFieldMachineFactories.Number({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Table }, widget =>
      spawn(FormFieldMachineFactories.Table({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Hidden }, widget =>
      spawn(FormFieldMachineFactories.Hidden({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Snippet }, widget =>
      spawn(FormFieldMachineFactories.Snippet({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.Members }, widget =>
      spawn(FormFieldMachineFactories.Members({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.SendRichEmail }, widget =>
      spawn(ContentMachineFactories.Email({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ fieldType: FieldType.MultiSelect }, widget =>
      spawn(ContentMachineFactories.Subtasks({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ header: { type: WidgetType.Text, taskTemplate: { templateRevision: { id: P.string } } } }, widget =>
      spawn(ContentMachineFactories.Text({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ header: { type: WidgetType.Image, taskTemplate: { templateRevision: { id: P.string } } } }, widget =>
      spawn(ContentMachineFactories.Image({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ header: { type: WidgetType.File, taskTemplate: { templateRevision: { id: P.string } } } }, widget =>
      spawn(ContentMachineFactories.File({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ header: { type: WidgetType.Video, taskTemplate: { templateRevision: { id: P.string } } } }, widget =>
      spawn(ContentMachineFactories.Video({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ header: { type: WidgetType.CrossLink, taskTemplate: { templateRevision: { id: P.string } } } }, widget =>
      spawn(ContentMachineFactories.Page({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ header: { type: WidgetType.Embed, taskTemplate: { templateRevision: { id: P.string } } } }, widget =>
      spawn(ContentMachineFactories.Embed({ widget, template, sharedContext, isReadOnly })),
    )
    .with({ header: { type: WidgetType.Table, taskTemplate: { templateRevision: { id: P.string } } } }, widget =>
      spawn(ContentMachineFactories.Table({ widget, template, sharedContext, isReadOnly })),
    )
    .otherwise(() => undefined);
}
