import * as React from 'react';
import { BlvdSelect, BlvdSelectHelpers, BlvdSelectProps } from 'components/design/BlvdSelect';
import { Timing, Property, getOffsetDirectionEntityProperties } from './rule-trees';
import { FieldTypePreservingOmit } from '@process-street/subgrade/core';

export type PropertySelectProps = FieldTypePreservingOmit<BlvdSelectProps<PropertyOption>, 'onChange'> & {
  timing: Timing;
  onChange: React.Dispatch<React.SetStateAction<Timing>>;
};

export type PropertyOption = { value: Property; label: string };

export const PropertySelect: React.FC<React.PropsWithChildren<PropertySelectProps>> = ({
  timing,
  onChange,
  ...props
}) => {
  return (
    <BlvdSelect
      value={propertyToValue(timing.property)}
      options={getOffsetDirectionEntityProperties(timing).map(propertyToOption)}
      placeholder="Select"
      onChange={option => {
        if (BlvdSelectHelpers.isOptionType<PropertyOption>(option)) {
          onChange(t => ({ ...t, property: option.value }));
        } else {
          onChange(t => ({ ...t, property: undefined }));
        }
      }}
      {...props}
    />
  );
};

const PROPERTY_LABELS: { [k in Property]?: string } = {
  startDate: 'start date',
  completedDate: 'complete',
  dueDate: 'is due',
};

const propertyToOption = (property: Property): PropertyOption => ({
  value: property,
  label: PROPERTY_LABELS[property] ?? property,
});

const propertyToValue = (property?: Property): PropertyOption | null => (property ? propertyToOption(property) : null);
