import * as React from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from 'components/design/next';
import { Icon } from 'components/design/next';

export type MeatballMenuProps = {
  onDuplicate: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onDelete: () => void;
};

export const MeatballMenu = ({ onDuplicate, onMoveUp, onMoveDown, onDelete }: MeatballMenuProps) => {
  return (
    <Menu>
      <MenuButton as={Button} variant="ghost" p="0">
        <Icon icon="ellipsis-h" size="4" color="gray.500" />
      </MenuButton>

      <MenuList>
        <MenuItem
          color="gray.500"
          iconSpacing="3"
          icon={<Icon color="gray.500" icon="clone" size="4" py="2" />}
          onClick={onDuplicate}
        >
          Duplicate
        </MenuItem>

        {onMoveUp && (
          <MenuItem
            color="gray.600"
            iconSpacing="3"
            icon={<Icon color="gray.500" icon="arrow-up" size="4" py="2" />}
            onClick={onMoveUp}
          >
            Move up
          </MenuItem>
        )}

        {onMoveDown && (
          <MenuItem
            color="gray.600"
            iconSpacing="3"
            icon={<Icon color="gray.500" icon="arrow-down" size="4" py="2" />}
            onClick={onMoveDown}
          >
            Move down
          </MenuItem>
        )}

        <MenuItem
          color="red.500"
          iconSpacing="3"
          icon={<Icon color="red.500" icon="trash-can" size="4" py="2" />}
          onClick={onDelete}
        >
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
