import { Muid } from '@process-street/subgrade/core';
import {
  MigrateAllByTemplateIdResponse,
  TemplateMigrationResource,
  TemplateMigrationStats,
} from '../../../resources/template-migration-resource.interface';

export interface TemplateMigrationApi {
  getMigrationStatsByTemplateRevisionId(templateRevisionId: Muid): angular.IPromise<TemplateMigrationStats>;

  scheduleAllMigrationsByTemplateRevisionId(
    finishedTemplateRevisionId: Muid,
  ): angular.IPromise<MigrateAllByTemplateIdResponse>;
}

export class TemplateMigrationApiImpl implements TemplateMigrationApi {
  public static $inject = ['TemplateMigration'];
  constructor(private templateMigrationResource: TemplateMigrationResource) {}

  public getMigrationStatsByTemplateRevisionId(templateRevisionId: Muid): angular.IPromise<TemplateMigrationStats> {
    return this.templateMigrationResource.getMigrationStatsByTemplateRevisionId({ templateRevisionId }).$promise;
  }

  public scheduleAllMigrationsByTemplateRevisionId(
    finishedTemplateRevisionId: Muid,
  ): angular.IPromise<MigrateAllByTemplateIdResponse> {
    return this.templateMigrationResource.scheduleAllMigrationsByTemplateRevisionId({ finishedTemplateRevisionId })
      .$promise;
  }
}
