import { Option, OrganizationDiscount, Subscription } from '../core';
import { Plan, PlanLevel } from './plan-model';
import Encase from 'encase';

export const calculatePercentageDiscount = (cost: number, discount: OrganizationDiscount): number =>
  discount.percentOff ? (cost * discount.percentOff) / 100 : 0;

export const calculatePercentDiscountedCost = (cost: number, discount?: OrganizationDiscount): number => {
  let discountedCost = cost;
  if (discount && discount.percentOff) {
    discountedCost = cost - calculatePercentageDiscount(cost, discount);
  }
  return discountedCost;
};

export const calculateRawPlanCost = (
  plan: Plan,
  quantity: number,
  discount?: OrganizationDiscount,
): { planCost: number; totalPlanCost: number } => {
  let planCost;
  let totalPlanCost;

  if (discount && discount.percentOff) {
    planCost = calculatePercentDiscountedCost(plan.cost, discount);
    totalPlanCost = planCost * quantity;
  } else if (discount && discount.amountOff) {
    planCost = plan.cost;
    totalPlanCost = Math.max(0, planCost * quantity - discount.amountOff / 100);
  } else {
    planCost = plan.cost;
    totalPlanCost = planCost * quantity;
  }

  return {
    planCost,
    totalPlanCost,
  };
};

export const calculateNextPaymentTotalCost = (
  plan: Plan,
  quantity: number,
  discount: Option<OrganizationDiscount>,
  currentSubscription: Subscription,
): number => {
  let totalPlanCost = plan.cost * quantity;
  const isDiscountAvailable =
    discount &&
    currentSubscription.currentPeriodEndDate &&
    (discount.endDate === undefined ||
      discount.endDate === null ||
      currentSubscription.currentPeriodEndDate < discount.endDate);
  if (isDiscountAvailable) {
    ({ totalPlanCost } = calculateRawPlanCost(plan, quantity, discount));
  }
  return totalPlanCost;
};

export const calculateNextPaymentDiscount = (
  discount: Option<OrganizationDiscount>,
  currentSubscription: Subscription,
): Option<string> => {
  if (!discount) {
    return undefined;
  }
  let discountValue;
  const isDiscountAvailable =
    currentSubscription.currentPeriodEndDate &&
    (discount.endDate === undefined ||
      discount.endDate === null ||
      currentSubscription.currentPeriodEndDate < discount.endDate);
  if (isDiscountAvailable) {
    discountValue = getDiscountValue(discount);
  }
  return discountValue;
};

export const getDiscountValue = (discount?: OrganizationDiscount): Option<string> => {
  if (!discount) {
    return undefined;
  }
  if (discount.percentOff) {
    return `${discount.percentOff}%`;
  }
  if (discount.amountOff) {
    return `$${discount.amountOff / 100}`;
  }
  return undefined;
};

export const calculateRawAdditionalMemberCost = (
  plan: Plan,
  discount: Option<OrganizationDiscount>,
  quantity: number,
) => {
  const oldCost = calculateRawPlanCost(plan, quantity, discount).totalPlanCost;
  const newCost = calculateRawPlanCost(plan, quantity + 1, discount).totalPlanCost;
  return newCost - oldCost;
};

/**
 * Returns true is plan level is free
 *
 * @param {PlanConstants.PlanLevel} planLevel
 * @returns {boolean}
 */
export const isPlanLevelFree = (planLevel: PlanLevel): boolean => planLevel === PlanLevel.Free;

/**
 * Returns true if plan is free
 *
 * @param {Plan} plan
 * @returns {boolean}
 */
export const isPlanFree = (plan: Plan): boolean => isPlanLevelFree(plan.level);

export const getTitleForPlanLevel = (level: PlanLevel) => Encase.separate(level).join(' ');
