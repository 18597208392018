import {
  GetOrganizationTheme,
  useGetOrganizationTheme,
} from 'features/brand-customization/query-builder/get-organization-theme';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { isUndefined, OrganizationTheme } from '@process-street/subgrade/core';
import { useCreateOrganizationTheme } from 'features/brand-customization/query-builder/create-organization-theme';
import { useQueryClient } from 'react-query';
import { Button, Grid, GridItem, HStack, Skeleton, Text } from 'components/design/next';
import { ColorInput } from 'features/brand-customization/components/color-input';

export const EmailPrimaryBrandColorField: React.FC<React.PropsWithChildren<unknown>> = () => {
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const queryClient = useQueryClient();

  const [color, setColor] = React.useState<string>();
  const [colorError, setColorError] = React.useState<string | null>(null);

  const organizationThemeQuery = useGetOrganizationTheme({ organizationId });

  React.useEffect(() => {
    if (isUndefined(color) && organizationThemeQuery.data) {
      setColor(organizationThemeQuery.data.emailPrimaryColor ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
  }, [organizationThemeQuery.data]);

  const updateOrganizationThemeMutation = useCreateOrganizationTheme({
    onSuccess: async () => {
      await queryClient.invalidateQueries(GetOrganizationTheme.getKey({ organizationId }));

      organizationThemeQuery.refetch();
    },
  });

  const handleSave = (overrides?: Partial<OrganizationTheme>) => {
    updateOrganizationThemeMutation.mutate({
      organizationId,
      brandColor: organizationThemeQuery.data?.brandColor ?? '',
      emailCustomizationEnabled: organizationThemeQuery.data?.emailCustomizationEnabled ?? false,
      emailPrimaryColor: overrides?.emailPrimaryColor ?? '',
      emailFooter: organizationThemeQuery.data?.emailFooter ?? '',
      emailBackgroundColor: organizationThemeQuery.data?.emailBackgroundColor ?? '',
      emailHeaderLogoFileId: organizationThemeQuery.data?.emailHeaderLogoFileId ?? null,
    });
  };

  return (
    <Grid w="full" mt="8" rowGap="6" templateColumns={{ base: '1fr', md: '220px 1fr' }}>
      <GridItem display="flex" alignItems="center">
        <Text color="gray.600" as="label">
          Primary color
        </Text>
      </GridItem>
      <GridItem mt={{ base: '-4', md: '0' }}>
        <HStack w="full">
          <Skeleton w="full" isLoaded={!organizationThemeQuery.isLoading}>
            <ColorInput
              value={color ?? null}
              onChange={setColor}
              onError={setColorError}
              onValid={() => setColorError(null)}
              inputTestId="email-primary-color-input"
            />
          </Skeleton>
          <Button
            alignSelf="flex-start"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.300"
            px="4"
            h="10"
            size="sm"
            variant="solid"
            colorScheme="gray"
            aria-label="Save email primary brand color"
            isDisabled={Boolean(colorError) || color === organizationThemeQuery.data?.emailPrimaryColor}
            onClick={() => handleSave({ emailPrimaryColor: color })}
          >
            Save
          </Button>
        </HStack>
      </GridItem>
    </Grid>
  );
};
