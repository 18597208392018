import { MuidConverter } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { ButtonWithUpgradeTooltip } from 'components/button-with-upgrade-tooltip';
import { env } from 'components/common/env';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  Spacer,
  Switch,
  Text,
  Tooltip,
  useToast,
  VStack,
} from 'components/design/next';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import {
  GetRunViaEmail,
  GetRunViaEmailResult,
  useGetRunViaEmailQuery,
  useUpdateRunViaEmailMutation,
} from 'features/run-via-email/query-builder';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useCopyToClipboard } from 'react-use';
import { match } from 'ts-pattern';

export type RunViaEmailTabPanelProps = { templateId: Template['id']; onClose?: () => void };

export const RunViaEmailTabPanel: React.VFC<RunViaEmailTabPanelProps> = ({ templateId, onClose }) => {
  const [_, copyToClipboard] = useCopyToClipboard();
  const toast = useToast();
  const isPrivateTemplate = useIsPrivateTemplate(templateId);
  const templatePermissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId);
  const queryClient = useQueryClient();

  const hasPermissions = templatePermissionsQuery.data?.permissionMap.templateUpdate;

  const runViaEmailQuery = useGetRunViaEmailQuery({ templateId }, { retry: false });

  const updateRunViaEmailMutation = useUpdateRunViaEmailMutation({
    onMutate: variables => {
      GetRunViaEmail.updateCache(variables, { queryClient });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(GetRunViaEmail.getKey({ templateId }));
    },
    onError: (error, variables) => {
      GetRunViaEmail.updateCache({ templateId, enabled: !variables.enabled });

      const action = variables.enabled ? 'enabling' : 'disabling';

      const description = match(error.response?.data?.message)
        .with('user needs to verify their email', () => 'Please verify your email address.')
        .otherwise(() => DefaultErrorMessages.unexpectedErrorDescription);

      toast({
        status: 'error',
        title: `We're having problems ${action} the run via email trigger`,
        description,
      });
    },
  });

  const isRunViaEmailEnabled = runViaEmailQuery.data?.enabled ?? false;

  const triggerEmailAddress = getInboundEmail(isRunViaEmailEnabled ? runViaEmailQuery.data : undefined);

  const handleRunViaEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = e.target.checked;

    updateRunViaEmailMutation.mutate({ enabled, templateId });
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(triggerEmailAddress);
    toast({
      status: 'success',
      description: 'Workflow email address copied to clipboard',
    });
  };

  const switchElement = (
    <Switch
      aria-labelledby="run-via-email-toggle-label"
      size="lg"
      isChecked={isRunViaEmailEnabled ?? false}
      onChange={handleRunViaEmailChange}
      isDisabled={!hasPermissions}
    />
  );

  return (
    <VStack spacing="6" alignItems="flex-start">
      <FormControl>
        <FormLabel>
          <HStack spacing="2">
            <Icon variant="far" icon="run-email" size="5" color="gray.600" />
            <Text>Workflow Email Address</Text>
          </HStack>
        </FormLabel>

        <InputGroup>
          <Input data-testid="input" h="12" isReadOnly value={triggerEmailAddress} />
          <InputRightAddon h="12">
            <Button
              display="flex"
              variant="unstyled"
              color="gray.700"
              fontWeight="bold"
              leftIcon={<Icon icon="copy" variant="far" size="4" />}
              isDisabled={!isRunViaEmailEnabled}
              onClick={handleCopyToClipboard}
            >
              Copy
            </Button>
          </InputRightAddon>
        </InputGroup>

        <FormHelperText>
          Sending an email to this address will start a workflow run with the subject and body as variables.
          <br />
          Try using it with emails sent by other apps.{' '}
          <Link color="brand.500" target="_blank" href="https://www.process.st/help/docs/run-workflows-email/">
            Learn more »
          </Link>
        </FormHelperText>
      </FormControl>

      <HStack w="full" alignItems="stretch">
        <HStack spacing="2" alignItems="center">
          {isPrivateTemplate ? (
            <Tooltip label="Private workflows can't be triggered via email. To enable this feature, please move this workflow to your organization's library.">
              <Box>{React.cloneElement(switchElement, { isDisabled: true })}</Box>
            </Tooltip>
          ) : hasPermissions ? (
            switchElement
          ) : (
            <ButtonWithUpgradeTooltip>{switchElement}</ButtonWithUpgradeTooltip>
          )}

          <Box>
            <Text id="run-via-email-toggle-label" fontWeight="400" color="gray.600" mt="-1">
              Enable run via email for this workflow
              <Tooltip label="Disable to prevent emails from triggering workflow runs." maxW="xl">
                <Box as="span" ml="1" display="inline">
                  <Icon icon="info-circle" color="gray.500" variant="far" size="4" />
                </Box>
              </Tooltip>
            </Text>
          </Box>
        </HStack>
        <Spacer />
        {onClose && (
          <Button variant="tertiary" onClick={onClose}>
            Done
          </Button>
        )}
      </HStack>
    </VStack>
  );
};

const getInboundEmail = (data?: GetRunViaEmailResult) => {
  if (!data) return 'Enable run via email to generate the email address.';

  const emailDomain = 'inbound.process.st';
  const envSuffix = env.APP_STAGE === 'prod' ? '' : `-${env.APP_STAGE}`;

  const uuid = MuidConverter.toUuid(data.id);

  return `run-${uuid}${envSuffix}@${emailDomain}`;
};
