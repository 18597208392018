import { ChakraProvider, ChakraProviderProps, ChakraTheme } from '@chakra-ui/react';
import * as React from 'react';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { useBrandTheme } from './use-brand-theme';
import { ThemeName, ThemeNameContext } from 'components/design/next/theme-name-provider';
import { colorModeManager } from './color-mode-manager';

const ATTRIBUTE_TO_REMOVE = 'data-theme';

export const DesignProvider: React.FC<React.PropsWithChildren<ChakraProviderProps>> = props => {
  const theme = useBrandTheme();
  React.useEffect(() => {
    // Recreate standalone toast service to keep styles
    ToastServiceImpl.setTheme(theme as ChakraTheme);
  }, [theme]);

  // Prevent theme leaking
  // Reference: https://github.com/chakra-ui/chakra-ui/discussions/5180#discussioncomment-5080398
  React.useEffect(function watchDataThemeHtmlAttribute() {
    const htmlElement = document.querySelector('html');

    if (!htmlElement) return;

    const mutationCallback: MutationCallback = mutationsList => {
      for (const mutation of mutationsList) {
        if (mutation.type !== 'attributes' && mutation.attributeName !== ATTRIBUTE_TO_REMOVE) return;

        // Remove 'data-theme' attribute so we can nest chakra themes
        htmlElement?.removeAttribute(ATTRIBUTE_TO_REMOVE);
      }
    };

    const observer = new MutationObserver(mutationCallback);

    observer.observe(htmlElement, { attributes: true });

    return () => observer.disconnect();
  }, []);

  return (
    <div data-ds-next={true}>
      <ThemeNameContext.Provider value={ThemeName.Next}>
        <ChakraProvider colorModeManager={colorModeManager} resetCSS={false} {...props} theme={theme} />
      </ThemeNameContext.Provider>
    </div>
  );
};
