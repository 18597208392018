import * as React from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Icon,
  InputRightElement,
  IconButton,
} from 'components/design/next';
import { EquatableSet } from 'services/equatable-set.pure';
import { toDayGroupId, toDayName } from './utils';
import { useInjector } from 'components/injection-provider';
import { useScheduleStore } from './query';

export const TableActions = () => {
  const { searchQuery, setSearchQuery, rows, day, setDay } = useScheduleStore();
  const { $state } = useInjector('$state');
  const days = React.useMemo(() => {
    const daysSet = new EquatableSet<{ id: string; name: string }>('id');
    daysSet.addAll(
      rows
        .toArray()
        .sort()
        .map(row => ({
          id: toDayGroupId(row.nextRunDate),
          name: toDayName(row.nextRunDate),
        })),
    );
    return daysSet.toArray();
  }, [rows]);

  return (
    <Stack spacing="4" direction={{ base: 'column', lg: 'row' }} justify="space-between">
      <HStack>
        <FormControl minW={{ md: '320px' }} id="search">
          <InputGroup>
            <FormLabel srOnly>Search</FormLabel>
            <InputLeftElement pointerEvents="none" color="gray.400">
              <Icon icon="search" size="4" variant="far" />
            </InputLeftElement>
            <Input
              variant="outline"
              colorScheme="gray"
              rounded="base"
              type="search"
              placeholder="Search"
              onChange={e => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
            {searchQuery !== '' ? (
              <InputRightElement>
                <IconButton
                  variant="ghost"
                  size="xs"
                  aria-label="clear search"
                  icon={<Icon icon="times" variant="far" size="4" />}
                  onClick={() => setSearchQuery('')}
                />
              </InputRightElement>
            ) : null}
          </InputGroup>
        </FormControl>

        <Select
          placeholder="Start date"
          value={day}
          onChange={e => setDay(e.target.value)}
          aria-label="Filter by start date"
        >
          {days.map(dayOption => (
            <option key={dayOption.id} value={dayOption.id}>
              {dayOption.name}
            </option>
          ))}
        </Select>
      </HStack>

      <Button
        as="a"
        variant="secondary"
        iconSpacing="1"
        leftIcon={<Icon icon="plus" size="4" variant="far" />}
        href={$state.href('futureChecklistAdd')}
        _hover={{ bgColor: 'gray.200', color: 'gray.700' }}
      >
        Schedule workflow
      </Button>
    </Stack>
  );
};
