import { NativeAutomation, TaskTemplate } from '@process-street/subgrade/process';
import {
  Box,
  HStack,
  Icon,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  StyleProps,
  Text,
  VStack,
} from 'components/design/next';
import * as React from 'react';
import { match } from 'ts-pattern';
import { useChecklistRevisionNativeAutomationStatus } from '../../hooks/use-checklist-revision-native-automation-status';
import { useUpdateTaskStatus } from './use-update-task-status';
import { useHandleRetryFailedNativeAutomation } from '../../hooks/use-handle-retry-failed-native-automation';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';

export type AiTaskListItemWrapperProps = {
  taskTemplate: TaskTemplate;
  isFirst: boolean;
  isLast: boolean;
};

const getColorForAutomationStatus = (status: NativeAutomation.EventStatus | undefined): StyleProps['color'] =>
  match(status)
    .with('AutomationRunning', () => 'blue.500') // Intentionally blue, not branded
    .with('AutomationComplete', () => 'blue.500') // Intentionally blue, not branded
    .with('AutomationFailed', () => 'red.500')
    .otherwise(() => 'gray.400');

// Simple wrapper to make storybooks easier to model without websockets
export const AiTaskListItemWrapper: React.FC<React.PropsWithChildren<AiTaskListItemWrapperProps>> = ({
  taskTemplate,
  isFirst,
  isLast,
}) => {
  const nativeAutomationQuery = GetAllNativeAutomationsQuery.useGetTaskTemplateAutomationQuery({
    templateRevisionId: taskTemplate.templateRevision.id,
    taskTemplateId: taskTemplate.id,
  });

  const { latestExecutionId, status } = useChecklistRevisionNativeAutomationStatus({
    nativeAutomation: nativeAutomationQuery.data,
  });
  useUpdateTaskStatus({ taskTemplate, status });

  const handleRetry = useHandleRetryFailedNativeAutomation({ nativeAutomationExecutionId: latestExecutionId });

  return <AiTaskListItem status={status} isFirst={isFirst} isLast={isLast} handleRetry={handleRetry} />;
};

export type AiTaskListItemProps = {
  isFirst: boolean;
  isLast: boolean;
  status: NativeAutomation.EventStatus | undefined;
  handleRetry: () => void;
};

export const AiTaskListItem: React.FC<React.PropsWithChildren<AiTaskListItemProps>> = ({
  status,
  isFirst,
  isLast,
  handleRetry,
}) => {
  const statusColor = getColorForAutomationStatus(status);

  return (
    <HStack spacing="0" w="full">
      <Box flex="1" />
      <VStack alignItems="stretch" alignContent="stretch" spacing="0">
        <Box
          background={
            isFirst
              ? 'none'
              : 'linear-gradient(var(--ps-colors-gray-200), var(--ps-colors-gray-200)) no-repeat center/1px 100%;'
          }
          h="9px"
        />
        <Popover trigger="hover" size="auto" variant="tooltip-dark">
          <PopoverTrigger>
            <HStack
              borderRadius="full"
              border="1px solid"
              borderColor={statusColor}
              px="2"
              py="2px"
              position="relative"
              cursor="default"
              aria-label={match(status)
                .with('AutomationRunning', () => 'AI task in progress.')
                .with('AutomationComplete', () => 'AI task completed.')
                .with('AutomationFailed', () => 'AI task failed.')
                .otherwise(() => 'At this point, the AI task will be executed.')}
            >
              <Icon icon="process-pete" variant="far" size="4" color={statusColor} />
              <Text variant="-2u" color={statusColor} fontWeight="bold">
                AI Task
              </Text>
              {status === 'AutomationRunning' && <Spinner color="blue.500" />}
            </HStack>
          </PopoverTrigger>
          <PopoverContent w="auto">
            <PopoverArrow />
            <PopoverBody>
              <Text variant="-1">
                {match(status)
                  .with('AutomationRunning', () => 'AI task in progress.')
                  .with('AutomationComplete', () => 'AI task completed.')
                  .with('AutomationFailed', () => (
                    <>
                      An error occurred executing the AI task. Please{' '}
                      <Link onClick={handleRetry} textDecor="underline">
                        retry
                      </Link>
                      .
                    </>
                  ))
                  .otherwise(() => 'At this point, the AI task will be executed.')}
              </Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Box
          background={
            isLast
              ? 'none'
              : 'linear-gradient(var(--ps-colors-gray-200), var(--ps-colors-gray-200)) no-repeat center/1px 100%;'
          }
          h="9px"
        />
      </VStack>
      <Box flex="1" />
    </HStack>
  );
};
