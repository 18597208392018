import { Muid } from '@process-street/subgrade/core';
import * as React from 'react';
import { TaskTemplateActions } from 'reducers/task-template/task-template.actions';
import { useDispatch } from 'react-redux';

interface LoadTaskTemplatesProps {
  templateRevisionId: Muid;
  taskTemplateActions: TaskTemplateActions;
}

export function useLoadTaskTemplatesQuery({ templateRevisionId, taskTemplateActions }: LoadTaskTemplatesProps) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (templateRevisionId) {
      dispatch(taskTemplateActions.getAllByTemplateRevisionId(templateRevisionId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- template revision ID change
  }, [dispatch, templateRevisionId]);
}
