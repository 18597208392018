import * as React from 'react';
import { VStack, List } from '@chakra-ui/react';

export interface TaskAssignmentsProps {}

export const TaskAssignments: React.FC<React.PropsWithChildren<TaskAssignmentsProps>> = ({ children }) => {
  return (
    <VStack as={List} alignItems="flex-start" w="full" mb="0" spacing="4">
      {children}
    </VStack>
  );
};
