import * as React from 'react';
import { useInsertMergeTagCallback } from './use-insert-merge-tag-callback';

/** Sets up a ref and callback for handling merge tag insertion */
export function useMergeTaggableInput<T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement>(opts: {
  get: () => string;
  set: (value: string) => void;
}) {
  const ref = React.useRef<T | null>(null);
  const insertMergeTag = useInsertMergeTagCallback({
    getStartIndex: () => ref.current?.selectionStart ?? undefined,
    getEndIndex: () => ref.current?.selectionEnd ?? undefined,
    onChange: ({ injection, result }) => {
      opts.set(result);

      if (ref.current) {
        const { current: input } = ref;
        input.focus();
        // need to make the setSelectionRange async for some reason?
        // https://github.com/facebook/react/issues/6483
        const newSelection = input.selectionStart !== null ? input.selectionStart + injection.length : result.length;
        setTimeout(() => {
          input.setSelectionRange(newSelection, newSelection);
        });
      }
    },
    value: opts.get(),
  });
  return { insertMergeTag, ref };
}
