import { Entities } from 'reducers/entities/entities';
import { entitiesSelector } from 'reducers/entities/entities.selectors';
import { safeEntityMapToArrayByIds } from 'reducers/entities/safe-entity-map-to-array-by-ids';
import { lookupsSelector } from 'reducers/lookups/lookups.selectors';
import { createSelector } from 'reselect';

const entityMap = state => entitiesSelector(state)[Entities.TASK_PERMISSION_RULE];
const lookups = state => lookupsSelector(state)[Entities.TASK_PERMISSION_RULE];

const getAllIdsByTaskTemplateGroupIds = taskTemplateGroupIds => state => {
  let res = [];
  taskTemplateGroupIds.forEach(taskTemplateGroupId => {
    const lookup = lookups(state).byTaskTemplateGroupId[taskTemplateGroupId];
    if (lookup) {
      res = res.concat(lookup);
    }
  });

  return res;
};

const getAllByTaskTemplateGroupIds = taskTemplateGroupIds =>
  createSelector([getAllIdsByTaskTemplateGroupIds(taskTemplateGroupIds), entityMap], (ids, rules) =>
    safeEntityMapToArrayByIds(rules, ids),
  );

const getAllIdsByTemplateRevisionId = templateRevisionId => state =>
  lookups(state).byTemplateRevisionId[templateRevisionId] || [];

const getAllByTemplateRevisionId = templateRevisionId =>
  createSelector([getAllIdsByTemplateRevisionId(templateRevisionId), entityMap], (ids, rules) =>
    safeEntityMapToArrayByIds(rules, ids),
  );

const getAllBySourceFormFieldWidgetGroupId = (templateRevisionId, sourceFormFieldWidgetGroupId) =>
  createSelector(getAllByTemplateRevisionId(templateRevisionId), rules =>
    rules.filter(r => r.sourceFormFieldWidgetGroup && r.sourceFormFieldWidgetGroup.id === sourceFormFieldWidgetGroupId),
  );

export const TaskPermissionRuleSelector = {
  entityMap,
  getAllByTaskTemplateGroupIds,
  getAllByTemplateRevisionId,
  getAllBySourceFormFieldWidgetGroupId,
};
