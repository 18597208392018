import { Muid } from '@process-street/subgrade/core';
import { DataSetPermit } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetDataSetPermitsResponse = DataSetPermit[];

export type GetDataSetPermitsParams = {
  dataSetId: Muid;
};

const key = ['data-sets', 'permits'];

export const GetDataSetPermitsQuery = {
  getKey: (params: GetDataSetPermitsParams): QueryKey => [...key, params],
  queryFn: (params: GetDataSetPermitsParams) =>
    axiosService
      .getAxios()
      .get<GetDataSetPermitsResponse>(`/1/data-sets/${params.dataSetId}/permits`)
      .then(res => res.data),
};

export const useGetDataSetPermitsQuery = <Select = GetDataSetPermitsResponse>(
  params: GetDataSetPermitsParams,
  options: UseQueryOptions<GetDataSetPermitsResponse, AxiosError, Select> = {},
) => {
  return useQuery(GetDataSetPermitsQuery.getKey(params), () => GetDataSetPermitsQuery.queryFn(params), options);
};
