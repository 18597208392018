import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { Label } from '@process-street/subgrade/process';

export namespace GetLabelsByOrganizationQuery {
  export type Params = {};

  export type Response = Label[];

  export const key = ['labels'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (_params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/labels`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params = {},
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: options.enabled !== false,
    });
  };
}
