import angular from 'angular';
import { Muid, Option } from '@process-street/subgrade/core';
import { TemplateRevision } from '@process-street/subgrade/process';
import { StateService } from '@uirouter/angularjs';
import { trace, Trace } from 'components/trace';
import { PollingStatus, ReduxAppState } from 'reducers/types';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State, TempDataService } from 'services/temp-data-service.interface';
import { TemplateMigrationStats } from 'app/resources/template-migration-resource.interface';
import { TemplateMigrationApi } from './template-migration.api';
import { getFirstStateMatching, redirectToRelevantState, RELEVANT_MIGRATION_STATES } from './util';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ToastService } from 'services/toast-service.interface';

// Polling delays
const Delay = {
  GET_STATS_RETRY_DELAY: 3000,
  MAX_SUCCESS_DELAY: 5000,
  REDIRECT_DELAY: 750, // to make sure that we see 100% progress on popup before redirect
  SUCCESS_FACTOR: 500,
  SUCCESS_INITIAL: 500,
};

export const TEMPLATE_MIGRATION_GET_MIGRATION_STATS_SUCCESS = 'template/migration/GET_MIGRATION_STATS/SUCCESS';

export const TEMPLATE_MIGRATION_SUCCESS = 'template/migration/TEMPLATE_MIGRATION/SUCCESS';

export const TEMPLATE_MIGRATION_SET_TOTAL = 'template/migration/TEMPLATE_MIGRATION_SET_TOTAL';

export const TEMPLATE_MIGRATION_UPDATE_POLLER_STATUS = 'template/migration/TEMPLATE_MIGRATION_UPDATE_POLLER_STATUS';

export const TEMPLATE_MIGRATION_REDIRECT_SUCCESS = 'template/migration/TEMPLATE_MIGRATION_REDIRECT_SUCCESS';

interface TemplateMigrationPayload {
  templateId: Muid;
  templateRevisionId: Muid;
}

export interface MigrationSetTotalActionPayload extends TemplateMigrationPayload {
  countTotal: number;
}

export interface GetMigrationStatsSuccessActionPayload extends TemplateMigrationPayload {
  stats: TemplateMigrationStats;
}

export interface MigrationSuccessActionPayload extends TemplateMigrationPayload {
  countTotal: number;
}

export interface UpdatePollingStatusPayload extends TemplateMigrationPayload {
  pollingStatus: PollingStatus;
}

export interface TemplateMigrationActions {
  pollMigrationStatsIfChecklistsAreBeingMigrated(
    templateId: Muid,
    templateRevisionId: Muid,
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  pollMigrationStats(
    templateId: Muid,
    templateRevisionId: Muid,
    executionCounter: number,
    doneCallback: () => void,
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  redirectToViewPage(): void;

  scheduleAllMigrationsByTemplateRevisionId(
    templateId: Muid,
    templateRevisionId: Muid,
    retryAttempt?: number,
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;

  stopPollerForTemplateRevisionId(
    templateId: Muid,
    templateRevisionId: Muid,
  ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action>;
}

export class TemplateMigrationActionsImpl implements TemplateMigrationActions {
  public static $inject = ['$state', '$timeout', 'ToastService', 'TempDataService', 'TemplateMigrationApi'];
  private logger: Trace;

  constructor(
    private $state: StateService,
    private $timeout: angular.ITimeoutService,
    private toastService: ToastService,
    private tempDataService: TempDataService,
    private templateMigrationApi: TemplateMigrationApi,
  ) {
    this.logger = trace({ name: 'TemplateMigrationActions' });
  }

  public scheduleAllMigrationsByTemplateRevisionId =
    (
      templateId: Muid,
      templateRevisionId: Muid,
      retryAttempt = 0,
    ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, Action>) => {
      this.templateMigrationApi.scheduleAllMigrationsByTemplateRevisionId(templateRevisionId).then(
        response => {
          dispatch(this.updatePollerStatusAction(templateId, templateRevisionId, PollingStatus.ON));
          dispatch(this.migrationSuccessfulAction(templateId, templateRevisionId, response.count));
          dispatch(this.pollMigrationStats(templateId, templateRevisionId, 0, () => this.redirectToViewPage()));
        },
        (error: Error) => {
          this.logger.error('scheduling of migration failed', error);
          this.handleScheduleMigrationFailure(templateId, templateRevisionId, retryAttempt, dispatch);
        },
      );
    };

  public pollMigrationStatsIfChecklistsAreBeingMigrated =
    (templateId: Muid, templateRevisionId: Muid): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, Action>) => {
      dispatch(this.updatePollerStatusAction(templateId, templateRevisionId, PollingStatus.ON));
      dispatch(
        this.pollMigrationStats(templateId, templateRevisionId, 0, () => {
          /* */
        }),
      );
    };

  public pollMigrationStats =
    (
      templateId: Muid,
      templateRevisionId: Muid,
      executionCounter: number,
      doneCallback: () => void,
    ): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, Action>, getState: () => ReduxAppState) => {
      this.templateMigrationApi.getMigrationStatsByTemplateRevisionId(templateRevisionId).then(
        stats => {
          const state = getState();
          if (isNewestMigratedTemplateRevision(state, templateId, templateRevisionId)) {
            const total = stats.countMigrating + stats.countMigrated + stats.countScheduled;
            dispatch(this.migrationSetTotalAction(templateId, templateRevisionId, total));
            dispatch(this.getMigrationStatsSuccessAction(templateId, templateRevisionId, stats));
            const isPollerOn =
              state.modules.templateMigration[templateId] &&
              state.modules.templateMigration[templateId].pollingStatus === PollingStatus.ON;

            if (stats.countMigrated < total && isPollerOn) {
              const delay = Delay.SUCCESS_INITIAL + executionCounter * Delay.SUCCESS_FACTOR;
              const normalizedDelay = delay < Delay.MAX_SUCCESS_DELAY ? delay : Delay.MAX_SUCCESS_DELAY;
              this.$timeout(() => {
                dispatch(this.pollMigrationStats(templateId, templateRevisionId, executionCounter + 1, doneCallback));
              }, normalizedDelay);
            } else {
              this.$timeout(() => doneCallback(), Delay.REDIRECT_DELAY);
            }
          } else {
            this.logger.info('Skip pollMigrationStats actions as the template revision is not newest');
          }
        },
        (error: Error) => {
          this.pollingFailureHandler(templateId, templateRevisionId, executionCounter, doneCallback, error, dispatch);
        },
      );
    };

  public stopPollerForTemplateRevisionId =
    (templateId: Muid, templateRevisionId: string): ThunkAction<void, ReduxAppState, Record<string, unknown>, Action> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, Action>) => {
      this.logger.info(`stop poller for template ${templateId}, templateRevision ${templateRevisionId}`);
      dispatch(this.updatePollerStatusAction(templateId, templateRevisionId, PollingStatus.OFF));
    };

  public redirectToViewPage = (): Action => {
    const returnState = this.determineReturnState();

    this.$timeout(() => {
      redirectToRelevantState({ $state: this.$state, returnState });
    }, 200);
    return {
      type: TEMPLATE_MIGRATION_REDIRECT_SUCCESS,
    };
  };

  // This is made public just to be able to mock
  public determineReturnState = (): Option<State> => {
    return getFirstStateMatching(this.tempDataService.getStates(), ...RELEVANT_MIGRATION_STATES);
  };

  private handleScheduleMigrationFailure(
    templateId: Muid,
    templateRevisionId: Muid,
    retryAttempt: number,
    dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, Action>,
  ) {
    if (retryAttempt === 0) {
      this.redirectToViewPage();
    }
    if (retryAttempt < 5) {
      this.toastService.openToast({
        status: 'warning',
        title: `We couldn't update the workflow run(s)`,
        description: 'Retrying...',
      });
      this.$timeout(
        () =>
          dispatch(this.scheduleAllMigrationsByTemplateRevisionId(templateId, templateRevisionId, retryAttempt + 1)),
        1000,
      );
    } else {
      this.toastService.openToast({
        status: 'error',
        title: `We're having problems updating the workflow run(s)`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    }
  }

  private pollingFailureHandler(
    templateId: Muid,
    templateRevisionId: Muid,
    executionCounter: number,
    doneCallback: () => void,
    error: Error,
    dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, Action>,
  ) {
    this.logger.error('Error while fetching migration stats', error);

    this.$timeout(() => {
      this.logger.info('Restarting polling after error', error);
      dispatch(this.pollMigrationStats(templateId, templateRevisionId, executionCounter, doneCallback));
    }, Delay.GET_STATS_RETRY_DELAY);
  }

  private migrationSetTotalAction = (templateId: Muid, templateRevisionId: Muid, countTotal: number) => ({
    payload: { templateId, templateRevisionId, countTotal },
    type: TEMPLATE_MIGRATION_SET_TOTAL,
  });

  private getMigrationStatsSuccessAction = (
    templateId: Muid,
    templateRevisionId: Muid,
    stats: TemplateMigrationStats,
  ) => ({
    payload: { templateId, templateRevisionId, stats },
    type: TEMPLATE_MIGRATION_GET_MIGRATION_STATS_SUCCESS,
  });

  private migrationSuccessfulAction = (templateId: Muid, templateRevisionId: Muid, countTotal: number) => ({
    payload: { templateId, templateRevisionId, countTotal },
    type: TEMPLATE_MIGRATION_SUCCESS,
  });

  private updatePollerStatusAction = (templateId: Muid, templateRevisionId: Muid, pollingStatus: PollingStatus) => ({
    payload: { templateId, templateRevisionId, pollingStatus },
    type: TEMPLATE_MIGRATION_UPDATE_POLLER_STATUS,
  });
}

function isNewestMigratedTemplateRevision(state: ReduxAppState, templateId: Muid, templateRevisionId: Muid) {
  if (state.modules.templateMigration[templateId] === undefined) {
    return true;
  }
  const newTemplateRevision: TemplateRevision = state.entities.templateRevision[templateRevisionId];

  const storedTemplateRevisionId = state.modules.templateMigration[templateId].templateRevisionId;
  const storedTemplateRevision: TemplateRevision = state.entities.templateRevision[storedTemplateRevisionId];
  if (storedTemplateRevision && newTemplateRevision) {
    return newTemplateRevision.revision >= storedTemplateRevision.revision;
  }
  return true;
}
