import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import {
  PaywallBodyDescription,
  PaywallBodyHeading,
  PaywallBodyWithIcon,
  PaywallCloseButton,
  PaywallDivider,
  PaywallFooter,
  PaywallHeader,
  PaywallIconUserLock,
  PaywallModal,
  PaywallPrimaryActionLinkButton,
} from 'components/paywalls/common';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { usePaywall } from '../paywall-provider';
import { useAdminEmails } from '../use-admin-emails';
import { OrganizationSelector } from 'reducers/organization/organization.selectors';
import { mailto as buildMailTo } from '@process-street/subgrade/process';
import { useRoleNames } from 'hooks/use-role-names';

export const FreeMember: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setPaywall } = usePaywall();
  const orgId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const { name: orgName } = useSelector(OrganizationSelector.getById(orgId)) ?? {};
  const currentUser = useSelector(SessionSelector.getCurrentUser);
  const roleNames = useRoleNames();

  const [adminEmail, ...bcc] = useAdminEmails();

  const closePaywall = React.useCallback(() => {
    setPaywall(null);
  }, [setPaywall]);

  const mailto = buildMailTo({
    to: adminEmail,
    subject: `Someone on your team is requesting an upgrade on your Process Street plan`,
    body: `A ${roleNames.FreeMember.single} on your Process Street account${
      currentUser?.username ? `, ${currentUser.username}, ` : ''
    }would like you to upgrade them to a ${roleNames.FullMember.single}. ${
      roleNames.FullMember.plural
    } can create and run workflows.\n\nUpgrade here: https://app.process.st/organizations/manage/billing`,
    bcc,
  });

  return (
    <PaywallModal>
      <PaywallHeader>
        <PaywallCloseButton onClick={closePaywall} />
      </PaywallHeader>

      <PaywallBodyWithIcon>
        <PaywallIconUserLock />

        <PaywallBodyHeading>Ask your {orgName ? `${orgName} ` : ''}admin for access</PaywallBodyHeading>

        <PaywallBodyDescription>
          Want to run workflows? Ask your {orgName ? `${orgName} ` : ''}admin to upgrade your account.
        </PaywallBodyDescription>
      </PaywallBodyWithIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallPrimaryActionLinkButton href={mailto} target="_blank">
          Send Request
        </PaywallPrimaryActionLinkButton>
      </PaywallFooter>
    </PaywallModal>
  );
};

export const UIs = {
  [OrganizationMembershipRole.FreeMember]: FreeMember,
  [OrganizationMembershipRole.FullMember]: () => null,
  [OrganizationMembershipRole.Admin]: () => null,
  [OrganizationMembershipRole.Guest]: () => null,
};
