import { ExportCsvOption } from 'components/dashboard/models/filters';
import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { ExportModal } from 'components/dashboard/components/checklist/ChecklistDashboardFilters/filters/ChecklistExportButton/ExportModal';
import { Button, Icon, IconButton, Tooltip } from 'components/design/next';
import {
  ChecklistGridExportFormBaseFields,
  ChecklistGridExportFormFields,
  useChecklistGridExportForm,
} from 'components/dashboard/components/checklist/ChecklistGridExportForm';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

export type ChecklistExportButtonProps = {
  iconOnly?: boolean;
  exportForm?: ChecklistGridExportFormBaseFields;
};

export const ChecklistExportButton = (props: ChecklistExportButtonProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId)!;
  const accessToken = useSelector(SessionSelector.getToken);

  const formProps: ChecklistGridExportFormFields = {
    ...props.exportForm,
    organizationId,
    accessToken,
  };

  const { ChecklistGridExportForm, onFormSubmit } = useChecklistGridExportForm(formProps);

  const onExport = (option: ExportCsvOption) => {
    onFormSubmit(option);
  };

  return (
    <>
      {props.iconOnly ? (
        <Tooltip label="Export form responses">
          <IconButton
            aria-label="Export form responses"
            icon={<Icon icon="arrow-to-bottom" size="4" variant="far" />}
            variant="solid"
            colorScheme="gray"
            onClick={onOpen}
          />
        </Tooltip>
      ) : (
        <Button
          ml="auto"
          size="sm"
          leftIcon={<Icon icon="arrow-to-bottom" size="4" variant="far" />}
          variant="secondary"
          onClick={onOpen}
        >
          Export
        </Button>
      )}
      <ExportModal isOpen={isOpen} onExport={onExport} onClose={onClose} />
      <ChecklistGridExportForm />
    </>
  );
};
