import * as React from 'react';

export const useIsTextTruncated = ({
  textRef,
}: {
  textRef: React.RefObject<HTMLParagraphElement>;
}): { isTextTruncated: boolean } => {
  const [isTextTruncated, setIsTextTruncated] = React.useState(false);

  React.useEffect(function checkIfTruncated() {
    const textElement = textRef.current;
    setIsTextTruncated(
      textElement
        ? textElement.offsetWidth < textElement.scrollWidth || textElement.offsetHeight < textElement.scrollHeight
        : false,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only execute once
  }, []);

  return { isTextTruncated };
};
