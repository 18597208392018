import { Muid } from '@process-street/subgrade/core';
import { MultiOptionFieldValue } from '@process-street/subgrade/process';
import { Icon, IconType } from 'components/design/Icon';
import React from 'react';

interface MultiSelectFieldRendererProps {
  value?: MultiOptionFieldValue;
  itemId?: Muid;
}

export const MultiSelectFieldRenderer: React.FunctionComponent<
  React.PropsWithChildren<MultiSelectFieldRendererProps>
> = ({ value, itemId }) => {
  if (!value) {
    return <div />;
  }

  const itemValue = value.itemValues.find(item => item.id === itemId);

  const completed = itemValue && itemValue.status === 'Completed';

  return <div>{completed && <Icon iconType={IconType.Check} />}</div>;
};
