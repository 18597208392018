import { Icon, IconButton, IconButtonProps, IconProps, Tooltip } from 'components/design/next';
import React from 'react';
import { useTableStore } from 'pages/reports/data-sets/components/data-set-page/data-table-store';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import { DataSet, SavedView } from '@process-street/subgrade/process';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useSelector } from 'react-redux';
import { DataSetUtils } from 'pages/reports/data-sets/components/data-set-page/data-set-utils';
import { useIsFetching } from 'react-query';
import { GetDataSetRowsQuery } from 'pages/reports/data-sets/query-builder';

export type ExportToCsvButtonProps = Partial<IconButtonProps> & {
  dataSet: DataSet;
  savedView: SavedView | null;
  iconProps?: Partial<IconProps>;
};

export const ExportToCsvButton: React.FC<React.PropsWithChildren<ExportToCsvButtonProps>> = ({
  dataSet,
  savedView,
  iconProps,
  ...buttonProps
}) => {
  const isLoading = useIsFetching(GetDataSetRowsQuery.key) > 0;
  const { gridApi } = useTableStore();

  const currentUser = useSelector(SessionSelector.getCurrentUser);

  const label = 'Export visible columns';

  const datePart = DateUtils.formatDateTime(new Date(), DateFormat.FileNameDate, currentUser?.timeZone);

  const resourceName = savedView ? savedView?.name.replace(/ +/g, '-') : dataSet.name.replace(/ +/g, '-');

  const fullFileName = `${resourceName}-${datePart}.csv`;
  const handleExport = () => {
    const columnKeys = (gridApi?.getColumnDefs()?.filter(DataSetUtils.isColDef).filter(DataSetUtils.isDataColDef) ?? [])
      .filter(c => !c.hide)
      .map(c => c.field ?? '');

    if (gridApi) {
      gridApi.exportDataAsCsv({ fileName: fullFileName, columnKeys });
    }
  };

  return (
    <>
      <Tooltip hasArrow label={label}>
        <IconButton
          variant="ghost"
          colorScheme="gray"
          aria-label={label}
          borderRadius="4px"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.300"
          icon={<Icon color="gray.500" icon="arrow-down-to-line" size="4" {...iconProps} />}
          onClick={handleExport}
          isDisabled={!gridApi || isLoading}
          {...buttonProps}
        />
      </Tooltip>
    </>
  );
};
