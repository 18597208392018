import { Box, BoxProps, Flex, Spinner, useBoolean } from 'components/design/next';
import * as React from 'react';
import { useMount } from 'react-use';

type CalendlyBookPayload = { event: { uri: string }; invitee: { uri: string } };

type CalendlyEvent =
  | { event: 'calendly.event_type_viewed'; payload: {} }
  | { event: 'calendly.date_and_time_selected'; payload: {} }
  | { event: 'calendly.event_scheduled'; payload: CalendlyBookPayload };

type BookingWidgetProps = BoxProps & {
  eventLink: string;
  prefill?: Record<string, unknown>;
  utm?: Record<string, unknown>;
  onEvent?: (event: CalendlyEvent) => void;
  onBook?: (data: CalendlyBookPayload) => void;
};

export const BookingWidget: React.VFC<BookingWidgetProps> = ({
  eventLink,
  prefill,
  utm,
  onEvent,
  onBook,
  ...props
}) => {
  const [isLoading, setIsLoading] = useBoolean(true);
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const initWidget = () => {
    // @ts-expect-error -- TODO
    window.Calendly.initInlineWidget({
      url: `${eventLink}?hide_event_type_details=1`,
      parentElement: containerRef.current,
      prefill,
      utm,
    });
  };

  useMount(() => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.onload = initWidget;

    document.head.appendChild(script);
  });

  React.useEffect(() => {
    const handleMessage = (e: MessageEvent<CalendlyEvent>) => {
      onEvent?.(e.data);

      if (e.data.event === 'calendly.event_type_viewed') setIsLoading.off();

      if (e.data.event === 'calendly.event_scheduled') {
        onBook?.(e.data.payload);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on booking event
  }, [onEvent, onBook]);

  return (
    <Flex w="full" h="750px" maxWidth="640px" alignItems="center" justifyContent="center" position="relative">
      {isLoading && <Spinner position="absolute" top="50%" left="50%" zIndex="docked" size="xl" />}
      <Box
        ref={containerRef}
        w="full"
        h="750px"
        maxWidth="640px"
        marginTop="-6"
        {...props}
        opacity={isLoading ? 0.2 : 1}
      />
    </Flex>
  );
};
