import angular from 'angular';
import templateUrl from './checklist-widget.component.html';

angular.module('frontStreetApp.directives').component('psChecklistWidget', {
  bindings: {
    user: '<',
    checklistRevision: '<',
    editable: '<',
    formFieldValueMap: '<',
    onUpdateTaskProgressMap: '&',
    organization: '<',
    task: '<',
    widget: '<',
    invalidFormFieldMap: '<',
    readOnly: '<',
  },
  templateUrl,
  controller() {
    const ctrl = this;

    ctrl.updateTaskProgressMap = function (itemValue, groupId) {
      ctrl.onUpdateTaskProgressMap({ itemValue, groupId });
    };
  },
});
