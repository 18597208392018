import { Muid } from '@process-street/subgrade/core';
import { axiosService } from 'services/axios-service';
import { AxiosResponse } from 'axios';
import { PermissionsStats } from 'components/permissions/models/permissions-stats';

export const TEST_PERMISSIONS_COUNTS: Pick<
  PermissionsStats,
  | 'allMembersGroupsCount'
  | 'membersCount'
  | 'groupsCount'
  | 'guestsCount'
  | 'inheritedAllMembersGroupsCount'
  | 'inheritedGroupsCount'
  | 'inheritedGuestsCount'
  | 'inheritedMembersCount'
  | 'templateShared'
> = {
  allMembersGroupsCount: 1,
  membersCount: 2,
  groupsCount: 3,
  guestsCount: 4,
  inheritedAllMembersGroupsCount: 5,
  inheritedMembersCount: 6,
  inheritedGroupsCount: 7,
  inheritedGuestsCount: 8,
  templateShared: true,
};

export const TEST_PERMISSIONS_MEMBERS_AND_GUESTS_COUNTS: Pick<
  PermissionsStats,
  | 'allMembersGroupsCount'
  | 'membersCount'
  | 'groupsCount'
  | 'guestsCount'
  | 'inheritedAllMembersGroupsCount'
  | 'inheritedGroupsCount'
  | 'inheritedGuestsCount'
  | 'inheritedMembersCount'
  | 'templateShared'
> = {
  allMembersGroupsCount: 0,
  membersCount: 2,
  groupsCount: 0,
  guestsCount: 2,
  inheritedAllMembersGroupsCount: 0,
  inheritedMembersCount: 4,
  inheritedGroupsCount: 0,
  inheritedGuestsCount: 5,
  templateShared: true,
};

export const TEST_PERMISSIONS_MEMBERS_GROUPS_COUNTS: Pick<
  PermissionsStats,
  | 'allMembersGroupsCount'
  | 'membersCount'
  | 'groupsCount'
  | 'guestsCount'
  | 'inheritedAllMembersGroupsCount'
  | 'inheritedGroupsCount'
  | 'inheritedGuestsCount'
  | 'inheritedMembersCount'
  | 'templateShared'
> = {
  allMembersGroupsCount: 0,
  membersCount: 1,
  groupsCount: 1,
  guestsCount: 0,
  inheritedAllMembersGroupsCount: 0,
  inheritedMembersCount: 1,
  inheritedGroupsCount: 1,
  inheritedGuestsCount: 5,
  templateShared: true,
};

const getAllForFoldersByOrganizationId = (organizationId: Muid): Promise<PermissionsStats[]> =>
  axiosService
    .getAxios()
    .get(`/1/organizations/${organizationId}/folder/permissions-stats`)
    .then((response: AxiosResponse<PermissionsStats[]>) => response.data);

const getAllTemplatePermissionsStatsByOrganizationIdAndParentId = (
  organizationId: Muid,
  parentId: Muid,
): Promise<PermissionsStats[]> =>
  axiosService
    .getAxios()
    .get(`/1/organizations/${organizationId}/folder/${parentId}/template/permissions-stats`)
    .then((response: AxiosResponse<PermissionsStats[]>) => response.data);

export const PermissionsStatsApi = {
  getAllForFoldersByOrganizationId,
  getAllTemplatePermissionsStatsByOrganizationIdAndParentId,
};
