import { queryClient } from 'components/react-root';
import { OrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';

export class OrganizationMembershipApi {
  constructor($q, OrganizationMemberships, Users) {
    'ngInject';

    this.$q = $q;
    this.OrganizationMemberships = OrganizationMemberships;
    this.Users = Users;
  }

  /**
   * Gets all organization memberships by `organizationId`.
   */
  getAllOrgMembershipByOrgId = organizationId =>
    this.$q((resolve, reject) =>
      queryClient
        .fetchQuery({
          queryKey: OrganizationMembershipsQuery.getKey({ organizationId }),
          queryFn: () => OrganizationMembershipsQuery.queryFn({ organizationId }),
          staleTime: 1000 * 60 * 5, // 5 Minutes
        })
        .then(resolve)
        .catch(reject),
    );

  /**
   * Gets all organization memberships that available for a given user id for all organization
   */
  getAllByUserId = userId => this.Users.getOrganizations({ id: userId }).$promise;

  setLevel = (membershipId, newLevel) =>
    this.OrganizationMemberships.setLevel({
      id: membershipId,
      level: newLevel,
    }).$promise;

  deleteByOrganizationIdAndUserId = (organizationId, userId) =>
    this.OrganizationMemberships.deleteByOrganizationIdAndUserId({ organizationId, userId }).$promise;
}
