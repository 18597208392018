import { MutationKey } from 'react-query';
import { Mutation } from 'react-query/types/core/mutation';

export const keysAreEqual = (a: MutationKey, b: MutationKey): boolean =>
  Array.isArray(a) && Array.isArray(b) ? a.every((part, i) => part === b[i]) : a === b;

export const makeShouldListenToMutation = (keys: MutationKey[]) => (mutationKey: MutationKey) =>
  (Array.isArray(mutationKey)
    ? keys.filter(Array.isArray).findIndex(key => keysAreEqual(key, mutationKey))
    : keys.indexOf(mutationKey)) !== -1;

export const makeMutationListener = <M extends Mutation<any, any, any, any>>({
  onSuccess,
  onLoading,
  onError,
  keys,
}: {
  onSuccess?: (mutation: M) => void;
  onLoading?: (mutation: M) => void;
  onError?: <E extends Error>(e?: E) => void;
  keys: MutationKey[];
}) => {
  const shouldListen = makeShouldListenToMutation(keys);

  return (mutation?: Mutation): void => {
    if (!mutation) return;
    const {
      state: { status },
      options: { mutationKey = '' },
    } = mutation;

    if (shouldListen(mutationKey)) {
      switch (status) {
        case 'loading': {
          onLoading?.(mutation as M);
          break;
        }
        case 'success': {
          onSuccess?.(mutation as M);
          break;
        }
        case 'error': {
          onError?.(mutation.state?.error as Error | undefined);
          break;
        }
      }
    }
  };
};
