import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { CommentsPage } from '.';

export const CommentsPageModule = angular
  .module(
    'commentsPage',
    [], // dependencies
  )
  .component(
    'psCommentsPage',
    reactToAngular(
      CommentsPage,
      [], // props
    ),
  ).name;
