import Ably, { Types } from 'ably';
import { env } from 'components/common/env';
import { Muid } from '@process-street/subgrade/core';

const ablyClientOptions: Types.ClientOptions = {
  key: env.ABLY_API_KEY,
  autoConnect: false,
};

const ably = new Ably.Realtime(ablyClientOptions);

const getChannel = (channelName: string): Types.RealtimeChannelCallbacks => {
  if (ably.connection.state !== 'connected') {
    ably.connect();
  }

  return ably.channels.get(channelName);
};

const connect = (): void => ably.connect();
const close = (): void => ably.close();

const getChannelNameForChecklist = (checklistId: Muid): string => `checklist-${checklistId}`;
const getChannelNameForNativeAutomation = (nativeAutomationId: Muid, checklistRevisionId: Muid): string =>
  `checklist-revision-${checklistRevisionId}/automation-${nativeAutomationId}`;
const getChannelNameForInbox = (organizationId: Muid, userId: Muid): string => `inbox-${organizationId}-${userId}`;
const getChannelNameForTemplateRevision = (templateRevisionId: Muid): string =>
  `templateRevision-${templateRevisionId}`;

export const ablyService = {
  close,
  connect,
  getChannel,
  getChannelNameForChecklist,
  getChannelNameForInbox,
  getChannelNameForTemplateRevision,
  getChannelNameForNativeAutomation,
};
