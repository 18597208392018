import * as React from 'react';
import { OneOffTask, OneOffTaskAssignmentDto } from '@process-street/subgrade/one-off-task';
import {
  AvatarBadge,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  ListItem,
  Spacer,
  Text,
} from 'components/design/next';
import { Checklist, TaskStatus } from '@process-street/subgrade/process';
import { useOneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { ChakraAvatar } from 'components/design/next/chakra-avatar';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import { useRefreshDrawerTask } from 'features/one-off-tasks/components/shared/hooks/use-refresh-drawer-task';
import { OneOffTaskCheckbox } from './one-off-task-checkbox';

export type OneOffTaskListItemProps = {
  checklist: Checklist;
  task: OneOffTask;
  requiredTaskIds: OneOffTask['id'][];
};

export const OneOffTaskListItem: React.FC<React.PropsWithChildren<OneOffTaskListItemProps>> = ({
  task,
  requiredTaskIds,
  checklist,
  ...props
}) => {
  const isBlocking = task.status === TaskStatus.NotCompleted && requiredTaskIds.includes(task.id);
  const isCompleted = task.status === TaskStatus.Completed;
  const { viewTask, isOpen, task: viewedTask } = useOneOffTaskDrawerStore();
  useRefreshDrawerTask(task);

  const handleItemClick = () => viewTask({ task });

  const taskIsOpen = isOpen && viewedTask === task;

  const lastAssignee: OneOffTaskAssignmentDto | undefined = task.assignments[task.assignments.length - 1];

  return (
    <ListItem {...props}>
      <HStack alignItems="center" width="full" spacing={2}>
        <OneOffTaskCheckbox task={task} checklist={checklist} />
        <HStack alignItems="center" width="full" as={Button} variant="unstyled" onClick={handleItemClick}>
          <FormControl isRequired={task.required}>
            <FormLabel display="flex" _hover={{ color: 'brand.500' }} cursor="pointer" mb={0} mr={0}>
              <Text
                as="span"
                maxWidth={{ base: task.dueDate ? '199px' : '243px', xl: task.dueDate ? '349px' : '393px' }}
                fontStyle={isCompleted ? 'italic' : 'unset'}
                color={isCompleted ? 'gray.400' : taskIsOpen ? 'brand.500' : 'inherit'}
                fontWeight={taskIsOpen ? 'semibold' : 'inherit'}
                textDecoration={isCompleted ? 'line-through' : 'none'}
                fontSize="md"
                noOfLines={1}
              >
                {task.name}
              </Text>
            </FormLabel>
          </FormControl>
          {task.dueDate && (
            <Box bgColor="blue.100" borderRadius="md" color="blue.500" fontSize="xs" fontWeight="medium" py={2}>
              {DateUtils.formatDateTime(task.dueDate, DateFormat.DateMonthDay)}
            </Box>
          )}
          <ChakraAvatar user={lastAssignee?.user} size="sm" bgColor="gray.300">
            {task.assignments.length > 1 && (
              <AvatarBadge
                fontSize="8px"
                fontWeight="600"
                px="5px"
                py="3px"
                bg="gray.400"
                border="none"
                borderRadius="4px"
              >
                {task.assignments.length}
              </AvatarBadge>
            )}
          </ChakraAvatar>
          <Spacer />
          {isBlocking && <Icon icon="exclamation-circle" variant="far" color="red.500" size="3" mr="1" />}
        </HStack>
      </HStack>
    </ListItem>
  );
};
