import { OrderTreeUtils } from '@process-street/subgrade/process';
import { OrderTreeService } from './order-tree.service';

export type ReorderedArraySummary<T> = {
  originalIndex: number;
  newIndex: number;
  displacedItem: T;
  movedItem: T;
};

export class OrderTreeServiceImpl implements OrderTreeService {
  public static getInstance() {
    return OrderTreeServiceImpl.instance;
  }
  private static instance = new OrderTreeServiceImpl();

  /* eslint @typescript-eslint/no-empty-function: ["error", {"allow": ["private-constructors"]}] */
  private constructor() {}

  public between(orderTree1: string | null, orderTree2: string | null, count?: number): string[] {
    const tree1 = orderTree1 === null ? '0' : orderTree1;
    const tree2 = orderTree2;

    let newOrderTree;

    if (tree2 === null) {
      newOrderTree = OrderTreeUtils.toString(OrderTreeUtils.toNumber(tree1.split('.')[0]) + 1);
    } else {
      const parts1 = tree1.split('.');
      const parts2 = tree2.split('.');

      const max = Math.max(parts1.length, parts2.length);

      newOrderTree = '';

      for (let i = 0; i < max; i += 1) {
        const p1 = OrderTreeUtils.toNumber(parts1[i]);
        const p2 = OrderTreeUtils.toNumber(parts2[i]);
        if (p1 === p2) {
          newOrderTree += `.${OrderTreeUtils.toString(p1)}`;
        } else if (p1 + 1 === p2) {
          newOrderTree += `.${OrderTreeUtils.toString(p1)}.${OrderTreeUtils.toString(
            OrderTreeUtils.toNumber(parts1[i + 1]) + 1,
          )}`;
          break;
        } else {
          newOrderTree += `.${OrderTreeUtils.toString(p1 + 1)}`;
          break;
        }
      }
    }

    const parts = newOrderTree.split('.');
    const baseOrderTree = parts.slice(0, parts.length - 1).join('.');
    const last = OrderTreeUtils.toNumber(parts[parts.length - 1]);

    const orderTrees: string[] = [];
    for (let j = 0; j < (count || 1); j += 1) {
      orderTrees.push(`${baseOrderTree}.${OrderTreeUtils.toString(last + j)}`.substr(1));
    }
    return orderTrees;
  }

  public compare(a: string, b: string): number {
    return OrderTreeUtils.compare(a, b);
  }

  /**
   * Find the orderTree before the object at index.
   *
   * @param orderTrees A sorted list of order trees.
   * @param orderTree The reference order tree, or `null` for after all order trees.
   * @param count The number of order trees to generate.
   */
  public before(orderTrees: string[], orderTree: string | null, count?: number): string[] {
    let index;
    if (orderTree === null) {
      index = orderTrees.length;
    } else {
      index = orderTrees.indexOf(orderTree);

      if (index < 0) {
        const message = `orderTree "${orderTree}" was not found in orderTrees`;
        throw new Error(message);
      }
    }

    let newOrderTrees;

    if (orderTrees.length) {
      if (orderTrees[index]) {
        newOrderTrees = this.between(orderTrees[index - 1] || null, orderTrees[index], count);
      } else if (index === orderTrees.length) {
        newOrderTrees = this.between(orderTrees[orderTrees.length - 1], null, count);
      } else {
        throw new Error('this should not happen');
      }
    } else {
      newOrderTrees = [];

      for (let i = 0; i < (count || 1); i += 1) {
        newOrderTrees.push(OrderTreeUtils.toString(i + 1));
      }
    }

    return newOrderTrees;
  }

  /**
   * Find the orderTree after the object at index.
   *
   * @param orderTrees A sorted list of order trees.
   * @param orderTree The reference order tree, or `null` for after all order trees.
   * @param count The number of order trees to generate.
   */
  public after(orderTrees: string[], orderTree: string | null, count?: number): string[] {
    let index;
    if (orderTree === null) {
      index = -1;
    } else {
      index = orderTrees.indexOf(orderTree);

      if (index < 0) {
        const message = `orderTree "${orderTree}" was not found in orderTrees`;
        throw new Error(message);
      }
    }

    let newOrderTrees;

    if (orderTrees.length) {
      if (orderTrees[index]) {
        newOrderTrees = this.between(orderTrees[index], orderTrees[index + 1] || null, count);
      } else if (index === -1) {
        newOrderTrees = this.between(null, orderTrees[0], count);
      } else {
        throw new Error('this should not happen');
      }
    } else {
      newOrderTrees = [];

      for (let i = 0; i < (count || 1); i += 1) {
        newOrderTrees.push(OrderTreeUtils.toString(i + 1));
      }
    }

    return newOrderTrees;
  }

  public summarizeReorderedArray<T>({
    old: a,
    new: b,
    getId = x => x,
  }: {
    old: T[];
    new: T[];
    getId?: (el: T | undefined) => any;
  }): ReorderedArraySummary<T> | undefined {
    // https://stackoverflow.com/a/22740997
    let originalIndex = 0;
    const arrayLength = a.length;
    while (
      (getId(a[originalIndex]) === getId(b[originalIndex]) ||
        getId(a[originalIndex]) === getId(b[originalIndex + 1])) &&
      originalIndex < arrayLength
    ) {
      originalIndex++;
    }
    const newIndex = b.findIndex(x => getId(x) === getId(a[originalIndex]))!;
    if (newIndex === -1) {
      return undefined;
    }

    const displacedItem = a[newIndex];
    const movedItem = a[originalIndex];
    return { originalIndex, newIndex, displacedItem, movedItem };
  }
}

export const orderTreeService = OrderTreeServiceImpl.getInstance();
