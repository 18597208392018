import * as React from 'react';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { TaskComments } from 'features/comments/components/task';
export interface PrintCommentsProps {
  task: OneOffTask;
}
export const PrintComments: React.FC<React.PropsWithChildren<PrintCommentsProps>> = ({ task }) => {
  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({
    checklistId: task.internalChecklistId,
  });
  return (
    <>
      {checklistRevisionQuery.data && (
        <TaskComments
          checklistRevision={checklistRevisionQuery.data}
          taskId={task.id}
          activeStepId={task.taskTemplateGroupId}
          fontSize="md"
          onlyComments
        />
      )}
    </>
  );
};
