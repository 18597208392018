import { Muid } from '@process-street/subgrade/core';
import { FutureChecklist, FutureChecklistAssignment, FutureChecklistJob } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type SearchFutureChecklistsByOrganizationIdParams = {
  organizationId: Muid;
  query: string;
  offsetId?: string;
  limit: number;
};

export interface SearchFutureChecklistsByOrganizationIdResultItem {
  futureChecklist: FutureChecklist;
  jobs: FutureChecklistJob[];
  assignments: FutureChecklistAssignment[];
}

export type SearchFutureChecklistsByOrganizationIdResult = Array<SearchFutureChecklistsByOrganizationIdResultItem>;

export const SearchFutureChecklistsByOrganizationId = {
  key: ['organizations', 'future-checklists'],
  getKey: (params: SearchFutureChecklistsByOrganizationIdParams): QueryKey => [
    ...SearchFutureChecklistsByOrganizationId.key,
    params,
  ],
  queryFn: ({ organizationId, ...params }: SearchFutureChecklistsByOrganizationIdParams) =>
    axiosService
      .getAxios()
      .get<SearchFutureChecklistsByOrganizationIdResult>(`/1/organizations/${organizationId}/future-checklists`, {
        params,
      })
      .then(r => r.data),
};

export const useSearchFutureChecklistsByOrganizationIdQuery = <Select = SearchFutureChecklistsByOrganizationIdResult>(
  params: SearchFutureChecklistsByOrganizationIdParams,
  options: UseQueryOptions<SearchFutureChecklistsByOrganizationIdResult, AxiosError, Select> = {},
) => {
  return useQuery(
    SearchFutureChecklistsByOrganizationId.getKey(params),
    () => SearchFutureChecklistsByOrganizationId.queryFn(params),
    options,
  );
};
