import * as React from 'react';
import { Flex, FlexProps, Image, StackProps, Text, VStack } from 'components/design/next';

type DatasetModalOptionProps = {
  title: React.ReactNode;
  description: string;
  imageSrc: string;
  imageContainerProps?: FlexProps;
  isDisabled?: boolean;
  onClick?: React.MouseEventHandler;
  onClose?: () => void;
  stackProps?: StackProps;
};

export const DatasetModalOption: React.FC<DatasetModalOptionProps> = ({
  title,
  description,
  imageSrc,
  imageContainerProps,
  onClick,
  onClose,
  isDisabled,
  stackProps,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    onClick?.(e);
    onClose?.();
  };

  return (
    <VStack
      borderWidth="thin"
      borderColor="gray.200"
      borderStyle="solid"
      boxShadow="1px 2px 4px 1px rgba(0, 0, 0, 0.06)"
      borderRadius="base"
      overflow="hidden"
      maxW="56"
      w="full"
      onClick={isDisabled ? undefined : handleClick}
      opacity={isDisabled ? 0.5 : 1}
      cursor={isDisabled ? 'default' : 'pointer'}
      pt="3"
      {...stackProps}
    >
      <Flex h="22" p="2.5" alignItems="flex-end" justifyContent="center" {...imageContainerProps} w="full">
        <Image src={imageSrc} />
      </Flex>

      <VStack position="relative" pt="4" px="4" pb="6" justifyContent="flex-start" alignItems="center">
        <Text color="black" fontWeight="500">
          {title}
        </Text>

        <Text fontSize="sm" color="gray.500">
          {description}
        </Text>
      </VStack>
    </VStack>
  );
};
