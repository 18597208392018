import * as React from 'react';
import { FocusBarHStack } from './focus-bar-hstack';
import { SkeletonText, Button, Skeleton, HStack } from 'components/design/next';

export const FocusBarSkeleton = () => {
  return (
    <FocusBarHStack alignItems="space-between">
      <SkeletonText noOfLines={2} w="sm" skeletonHeight="4" display="flex" flexWrap="wrap" alignItems="center" />
      <HStack>
        <SkeletonText noOfLines={1} w="48" mr="8" />
        <Skeleton>
          <Button></Button>
        </Skeleton>
        <Skeleton>
          <Button>Publish</Button>
        </Skeleton>
      </HStack>
    </FocusBarHStack>
  );
};
