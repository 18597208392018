import { MergeTagsConstants } from '@process-street/subgrade/form';
import { TypeOfEnumValue } from '@process-street/subgrade/core';
import { escapeHtml, StringUtils } from '@process-street/subgrade/util';

/**
 * Generate a pattern that'll match a merge tag and an optional filter.
 */
export function generatePatternByKey(mode: TypeOfEnumValue<typeof MergeTagsConstants.Mode>, key: string): RegExp {
  const regexps = [StringUtils.escapeRegExp(key)];
  if (mode === MergeTagsConstants.Mode.HTML) {
    const sanitizedKey = escapeHtml(key);
    regexps.push(StringUtils.escapeRegExp(sanitizedKey));
  }
  const regexp = `{{ *(${regexps.join('|')}) *(?:\\| *(\\w+)(?:: *'([^']*)')?)? *}}`;
  return new RegExp(regexp, 'g');
}
