import angular from 'angular';
import { MSTeamsUtils } from 'features/microsoft-teams/utils';

angular.module('frontStreetApp.controllers').controller('OtherwiseCtrl', function ($state, $timeout, auth) {
  if (auth.isLoggedIn()) {
    const isEmbeddedInMsTeams = MSTeamsUtils.isEmbeddedInMsTeams();
    $timeout(() => {
      isEmbeddedInMsTeams ? $state.go('microsoftTeamsInbox') : $state.go('reports');
    });
  } else {
    $timeout(() => {
      $state.go('login');
    });
  }
});
