import { useInjector } from 'components/injection-provider';
import * as React from 'react';
import { usePaywall } from '../paywall-provider';
import { useShouldBlockUseChecklist } from './use-should-block-use-checklist';
import { UIs } from './ui';

export const ChecklistPaywall = () => {
  const { setPaywall } = usePaywall();
  const { shouldBlock, role } = useShouldBlockUseChecklist();
  const { $state } = useInjector('$state');

  React.useEffect(() => {
    if (!role) return;
    const paywall = shouldBlock ? React.createElement(UIs[role]) : null;
    setPaywall(paywall);

    return () => {
      setPaywall(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- act on $state change
  }, [setPaywall, role, shouldBlock, $state.current]);

  return null;
};
