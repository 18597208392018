import { ApiKey } from '@process-street/subgrade/api-keys';
import { Button, useToast } from 'components/design/next';
import { queryClient } from 'components/react-root';
import { CreateApiKeyMutation, GetApiKeysQuery } from 'features/api-keys/query-builder';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import * as React from 'react';

interface CreateApiButtonProps {
  currentApiKeyCount: number | undefined;
}

export const CreateApiButton: React.FC<React.PropsWithChildren<CreateApiButtonProps>> = ({
  currentApiKeyCount = 0,
}) => {
  const toast = useToast();
  const organization = useSelectedOrganization();
  const label = React.useMemo(() => `API Key #${currentApiKeyCount + 1}`, [currentApiKeyCount]);

  const createNewApiMutation = CreateApiKeyMutation.useMutation({
    onSuccess: data => {
      const previousList = queryClient.getQueryData<ApiKey[]>(
        GetApiKeysQuery.getKey({ organizationId: organization?.id }),
      );
      // Set query data so that the new API Key appears at the end of the list
      queryClient.setQueryData(
        GetApiKeysQuery.getKey({ organizationId: organization?.id }),
        previousList ? [...previousList, data] : [data],
      );
    },
    onError: () => {
      toast({
        title: "We're having problems creating the API Key",
        status: 'error',
      });
    },
  });

  const handleNewApiKeyClick = () => createNewApiMutation.mutate({ id: organization?.id, label });
  return (
    <Button w={{ base: 'full', md: 'auto' }} size="lg" onClick={handleNewApiKeyClick}>
      + New API Key
    </Button>
  );
};
