import { User } from '@process-street/subgrade/core';
import { Task } from '@process-street/subgrade/process';
import { TaskAssignment } from '@process-street/subgrade/role-assignment';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace UpdateTaskAssignmentMutation {
  export type Params = {
    taskId: Task['id'];
    email: User['email'];
  };

  export type Response = TaskAssignment;

  export const key = ['put', 'task-assignments'];

  export const mutationFn = ({ taskId, email }: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/tasks/${taskId}/assignments/${email}`, {
        email,
        taskId,
      })
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
