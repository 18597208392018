import { Muid } from '@process-street/subgrade/core';
import { DataSetLinkWithNames } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

type GetSavedViewLinksParams = {
  dataSetId?: Muid;
  savedViewId?: Muid;
};

type GetSavedViewLinksResponse = DataSetLinkWithNames[];

export const GetSavedViewLinksQuery = {
  key: ['saved-views', 'links'],
  getKey: (params: GetSavedViewLinksParams): QueryKey => [...GetSavedViewLinksQuery.key, params],
  queryFn: (params: GetSavedViewLinksParams) =>
    axiosService
      .getAxios()
      .get(`/1/data-sets/${params.dataSetId}/saved-views/${params.savedViewId}/links`)
      .then(r => r.data),
};

export const useGetSavedViewLinksQuery = (
  params: GetSavedViewLinksParams,
  options: UseQueryOptions<GetSavedViewLinksResponse, AxiosError> = {},
) => {
  return useQuery(GetSavedViewLinksQuery.getKey(params), () => GetSavedViewLinksQuery.queryFn(params), {
    ...options,
    enabled: Boolean(params.dataSetId) && Boolean(params.savedViewId) && options.enabled !== false,
  });
};
