import * as React from 'react';
import {
  InboxPaywallBodyWithIcon,
  InboxPaywallFooter,
  PaywallBodyDescription,
  PaywallBodyDescriptionEmphasis,
  PaywallBodyHeading,
  PaywallIconChecklist,
  PaywallPrimaryActionLinkButton,
} from 'components/paywalls/common';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { OrganizationSelector } from 'reducers/organization/organization.selectors';
import { useAdminEmails } from 'components/paywalls/use-admin-emails';
import { mailto as buildMailToLink } from '@process-street/subgrade/process';
import { Spacer, Text, VStack } from 'components/design/next';
import { useFeatureSet } from 'components/paywalls/use-feature-set';
import { useOrganizationMembershipRole } from 'components/paywalls/use-organization-membership-role';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { useRoleNames } from 'hooks/use-role-names';

export const InboxPaywall: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { activeChecklistsLimit } = useFeatureSet();
  const orgId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const { name: orgName } = useSelector(OrganizationSelector.getById(orgId)) ?? {};
  const currentUser = useSelector(SessionSelector.getCurrentUser);
  const [adminEmail, ...bcc] = useAdminEmails();
  const roleNames = useRoleNames();

  const mailto = buildMailToLink({
    to: adminEmail,
    subject: `Someone on your team is requesting an upgrade on your Process Street plan`,
    body: `A ${roleNames.FreeMember.single} on your Process Street account${
      currentUser?.username ? `, ${currentUser.username}, ` : ''
    } would like you to upgrade them to a ${roleNames.FullMember.single}. ${
      roleNames.FullMember.plural
    } can create and run workflows.\n\nUpgrade here: https://app.process.st/organizations/manage/billing`,
    bcc,
  });

  const role = useOrganizationMembershipRole();
  const admin = role === OrganizationMembershipRole.Admin;

  return (
    <VStack pt="1" px="16">
      <InboxPaywallBodyWithIcon>
        <PaywallIconChecklist />

        <PaywallBodyHeading>{orgName} has reached its workflow run limit</PaywallBodyHeading>

        <VStack>
          {activeChecklistsLimit === 1 ? (
            <PaywallBodyDescription>
              Your organization is running the <PaywallBodyDescriptionEmphasis>1</PaywallBodyDescriptionEmphasis>{' '}
              workflow that comes with your Free plan. This workflow run exceeds your plan limits and cannot be
              accessed.
            </PaywallBodyDescription>
          ) : (
            <PaywallBodyDescription>
              Your organization is running the the{' '}
              <PaywallBodyDescriptionEmphasis>{activeChecklistsLimit}</PaywallBodyDescriptionEmphasis> workflows that
              come with your Free plan. This workflow run exceeds your plan limits and cannot be accessed.
            </PaywallBodyDescription>
          )}

          <Spacer />
          <PaywallBodyDescription>
            Run{' '}
            <Text fontWeight="bold" as="strong">
              unlimited
            </Text>{' '}
            workflows by upgrading {orgName} to Pro.
          </PaywallBodyDescription>
        </VStack>
      </InboxPaywallBodyWithIcon>
      <InboxPaywallFooter>
        {admin ? (
          <PaywallPrimaryActionLinkButton href="/organizations/manage/billing">
            Upgrade Now
          </PaywallPrimaryActionLinkButton>
        ) : (
          <PaywallPrimaryActionLinkButton href={mailto}>Send Request</PaywallPrimaryActionLinkButton>
        )}
      </InboxPaywallFooter>
    </VStack>
  );
};
