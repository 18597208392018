import angular from 'angular';

angular
  .module('frontStreetApp.controllers')
  .controller('OrganizationManageBillingCtrl', function ($scope, SessionService) {
    'ngInject';

    $scope.organization = SessionService.getSelectedOrganization();
    $scope.currentPlan = SessionService.getCurrentPlan();
  });
