import { User } from '../core/index';
import { htmlEscaped } from './html-escaped';

const extractUsernames = (users: User[]) => users.map(user => user.username).sort((a, b) => b.length - a.length);

const Punctuations = '[!"#$%&\'()*+,-. /:;<=>?[\\]^_`{|}~\\s]';

const generateUsernameRegexStr = (username: string) =>
  `((^${Punctuations}*)+|(\\b${Punctuations}+))(${username})(${Punctuations}*\\b)`;

const tagMentionedUser = (prefix: string, postfix: string) => (text: string, users: User[]) => {
  // Sort usernames to ensure that the longest one is matched first
  const usernames = extractUsernames(users);

  let replacedText = text;
  usernames.forEach(username => {
    const atUsername = `@${username}`;
    const safeUserName = htmlEscaped`${atUsername}`;
    const atUsernameHtml = `$1${prefix}${safeUserName}${postfix}$5`;
    const atUsernameRegex = generateUsernameRegexStr(atUsername);
    const match = new RegExp(atUsernameRegex, 'gi');

    replacedText = replacedText.replace(match, atUsernameHtml);
  });

  return replacedText;
};

const tagMentionedUsersWithB = tagMentionedUser('<b>', '</b>');

const tagMentionedUsersWithSpan = tagMentionedUser('<span class="at-mention">', '</span>');

const countMatches = (text: string, username: string) => {
  const atUsername = `@${username}`;
  const atUsernameRegex = generateUsernameRegexStr(atUsername);
  const usernameMatch = new RegExp(atUsernameRegex, 'gi');

  const textMatchToUsername = text.match(usernameMatch);

  return textMatchToUsername ? textMatchToUsername.length : 0;
};

const hasMentionedUsers = (text: string, users: User[]) =>
  extractUsernames(users).some((username: string) => countMatches(text, username) > 0);

const countMentionedUsers = (text: string, users: User[]) =>
  extractUsernames(users).reduce((agg: number, username: string) => agg + countMatches(text, username), 0);

export const CommentUtils = {
  hasMentionedUsers,
  countMentionedUsers,
  tagMentionedUser,
  tagMentionedUsersWithB,
  tagMentionedUsersWithSpan,
};
