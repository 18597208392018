import { Muid } from '@process-street/subgrade/core';
import { ChecklistGridData, ChecklistGridSearchRequest } from '@process-street/subgrade/dashboard';
import { AxiosError } from 'axios';
import {
  useQuery as useRQQuery,
  UseQueryOptions,
  QueryClient,
  InvalidateQueryFilters,
  InvalidateOptions,
} from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace PostReportsSearchQuery {
  export type Params = {
    organizationId?: Muid;
    searchCriteria: Omit<ChecklistGridSearchRequest, 'search'>;
  };

  export type Response = ChecklistGridData[];

  export const key = ['organizations', 'checklist-grid', 'search'];
  export const getKey = (params: Params) => [...key, params];
  export const queryFn = ({ organizationId, searchCriteria }: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/organizations/${organizationId}/checklist-grid/search`, searchCriteria)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQQuery(PostReportsSearchQuery.getKey(params), () => PostReportsSearchQuery.queryFn(params), {
      enabled: Boolean(params.organizationId) && options.enabled !== false,
    });
  };

  export const invalidate = (
    queryClient: QueryClient,
    params?: Params,
    filters?: InvalidateQueryFilters,
    options?: InvalidateOptions,
  ) => {
    return queryClient.invalidateQueries(params ? getKey(params) : key, filters, options);
  };
}
