import { MergeTagFilter } from '@process-street/subgrade/merge-tags';

/**
 * Applies a filter to the value.
 * All filter are from Shopify's Liquid template language.
 *
 * @param filter
 * @param param
 * @param key
 * @param value
 * @returns {*}
 */
export function applyFilter({
  filter,
  param,
  key,
  value,
}: {
  filter?: MergeTagFilter;
  param?: string;
  key: string;
  value: string;
}): string {
  switch (filter) {
    case MergeTagFilter.FallbackLegacy:
    case MergeTagFilter.Fallback:
      if (param === undefined || param === null) {
        return applyFilter({ filter: undefined, param: undefined, key, value });
      } else {
        return isAbsentOrEmpty(value) ? param : value;
      }
    case MergeTagFilter.Downcase:
      return isAbsentOrEmpty(value) ? `{{${key}}}` : value.toLowerCase();
    case MergeTagFilter.Upcase:
      return isAbsentOrEmpty(value) ? `{{${key}}}` : value.toUpperCase();
    case MergeTagFilter.UrlEncode:
      return isAbsentOrEmpty(value) ? `{{${key}}}` : encodeURIComponent(value);
    default:
      return isAbsentOrEmpty(value) ? `{{${key}}}` : value;
  }
}

/**
 * Checks if a string is absent (i.e. undefined or null) or is the empty string ('').
 */
export function isAbsentOrEmpty(str?: string | null | undefined): boolean {
  return str === undefined || str === null || str === '';
}
