import * as React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  Radio,
  RadioGroup,
  RadioProps,
  StackProps,
  Text,
  Tooltip,
  TooltipProps,
  useToast,
  VStack,
} from 'components/design/next';

import { Template, TemplateShareLevel, TemplateType } from '@process-street/subgrade/process';
import { TemplatePermissionsStatsQueryService } from 'components/permissions/services/template-permissions-stats-query-service';
import { useCopyShareLink } from './hooks/use-copy-share-link';
import { useUpdateShareLevelMutation } from './query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { match } from 'ts-pattern';
import { useGetCanShareLinks } from 'utils/plans/get-can-share-links';

export type TemplateShareLinkTabProps = StackProps & {
  template: Template;
  isPrivate: boolean;
  hideLabel?: boolean;
};

export const TemplateShareLinkTab: React.VFC<TemplateShareLinkTabProps> = ({
  template,
  isPrivate,
  hideLabel,
  ...props
}) => {
  const toast = useToast();

  const [shareLevel, setShareLevel] = React.useState<TemplateShareLevel>(template.shareLevel);
  const shareLink = useCopyShareLink({ template });

  const canShareLinks = useGetCanShareLinks();

  const isPage = template.templateType === TemplateType.Page;
  const templateTypeLabel = isPage ? 'page' : 'workflow';

  const updateShareLevelMutation = useUpdateShareLevelMutation({
    onMutate: variables => {
      setShareLevel(variables.level);

      return shareLevel;
    },
    onError: (_error, _variables, context) => {
      toast({
        title: "We're having problems updating the share level",
        description: DefaultErrorMessages.unexpectedErrorDescription,
        status: 'error',
      });

      setShareLevel(context as TemplateShareLevel);
    },
    onSuccess: () => {
      TemplatePermissionsStatsQueryService.invalidateCache(template.organization.id, template.folder.id);
    },
  });

  const handleShareLevelChange = (level: TemplateShareLevel) => {
    updateShareLevelMutation.mutate({
      templateId: template.id,
      level,
    });

    setShareLevel(level);
  };

  const handleCopyToClipboard = () => {
    toast({
      description: 'Share link copied to clipboard',
      status: 'success',
    });

    shareLink.copyToClipboard();
  };

  return (
    <VStack alignItems="start" spacing="2" {...props}>
      <FormControl>
        {!shareLink && (
          <FormLabel>
            <HStack spacing="2">
              <Icon variant="far" icon="link" size="4" color="gray.600" />
              <Text variant="1" color="gray.600" fontWeight="medium">
                Share link
              </Text>
            </HStack>
          </FormLabel>
        )}

        <InputGroup>
          <Input data-testid="input" h="12" isReadOnly value={shareLink.url} />
          <InputRightAddon h="12">
            <Button
              display="flex"
              variant="unstyled"
              color="gray.700"
              fontWeight="bold"
              leftIcon={<Icon icon="copy" variant="far" size="4" />}
              onClick={handleCopyToClipboard}
            >
              Copy
            </Button>
          </InputRightAddon>
        </InputGroup>

        <FormHelperText>
          {isPage
            ? 'Allows people with the link to view the page. '
            : 'Allows people with the link to view the workflow and run it. '}
          <Link color="brand.500" target="_blank" href="https://www.process.st/help/docs/workflow-share-link/">
            Learn more »
          </Link>
        </FormHelperText>
      </FormControl>

      <VStack w="full" spacing="2" alignItems="start" py="6">
        <Text variant="1" color="gray.600" fontWeight="medium">
          Permissions
        </Text>

        {isPrivate && (
          <HStack spacing="2">
            <Icon icon="lock" variant="far" size="4" />
            <Text>
              Only organization members can {isPage ? 'view this private page.' : 'view and run this private workflow.'}
            </Text>
          </HStack>
        )}

        {!isPrivate && (
          <RadioGroup
            value={shareLevel}
            onChange={handleShareLevelChange}
            as={VStack}
            spacing="2"
            alignItems="start"
            name="shareLevel"
          >
            <Radio value={TemplateShareLevel.None}>
              <HStack ml="1">
                <Icon icon="lock" variant="far" size="4" />

                <Text color="gray.600" fontWeight="normal">
                  Only organization members can view {isPage ? 'this page' : 'and run this workflow'}.
                </Text>
              </HStack>
            </Radio>

            {match({ isPage, canShareLinks })
              .with({ isPage: false, canShareLinks: true }, () => (
                <>
                  <AnyoneCanSeeRadio templateTypeLabel={templateTypeLabel} />
                  <AnyoneCanRunRadio />
                </>
              ))
              .with({ isPage: true, canShareLinks: true }, () => (
                <AnyoneCanSeeRadio templateTypeLabel={templateTypeLabel} />
              ))
              .with({ isPage: true, canShareLinks: false }, () => (
                <Box position="relative">
                  <ShareLinksDisabledTooltip>
                    <AnyoneCanSeeRadio templateTypeLabel={templateTypeLabel} isDisabled={true} />
                  </ShareLinksDisabledTooltip>
                </Box>
              ))
              .with({ isPage: false, canShareLinks: false }, () => (
                <>
                  <ShareLinksDisabledTooltip>
                    <AnyoneCanSeeRadio templateTypeLabel={templateTypeLabel} isDisabled={true} m="0" />
                  </ShareLinksDisabledTooltip>
                  <ShareLinksDisabledTooltip>
                    <AnyoneCanRunRadio isDisabled={true} m="0" />
                  </ShareLinksDisabledTooltip>
                </>
              ))
              .otherwise(() => null)}
          </RadioGroup>
        )}
      </VStack>
    </VStack>
  );
};

const AnyoneCanSeeRadio: React.FC<React.PropsWithChildren<RadioProps & { templateTypeLabel: string }>> = ({
  templateTypeLabel,
  ...props
}) => {
  return (
    <Radio value={TemplateShareLevel.View} {...props}>
      <HStack ml="1">
        <Icon icon="unlock-alt" variant="far" size="4" />

        <Text color="gray.600" fontWeight="normal">
          Anyone with the link can view this {templateTypeLabel}.
        </Text>
      </HStack>
    </Radio>
  );
};

const AnyoneCanRunRadio: React.FC<React.PropsWithChildren<RadioProps>> = props => {
  return (
    <Radio value={TemplateShareLevel.Run} {...props}>
      <HStack ml="1">
        <Icon icon="unlock-alt" variant="far" size="4" />

        <Text color="gray.600" fontWeight="normal">
          Anyone with the link can view and run this workflow.
        </Text>
      </HStack>
    </Radio>
  );
};

const ShareLinksDisabledTooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({ children, ...props }) => {
  return (
    <Tooltip
      w="100%"
      label={
        <Text>
          Share links are enabled after a 7-day period on any paid plan. For expedited access, please contact our
          support&nbsp;team.
        </Text>
      }
      hasArrow
      shouldWrapChildren
      {...props}
    >
      {children}
    </Tooltip>
  );
};
