import * as React from 'react';
import { getPluginType } from '@udecode/plate-core';
import { Icon } from 'components/design/next';
import { ToolbarButton, ToolbarTooltip } from '../common';
import { useOS } from 'services/os-util';
import { MARK_BOLD, MARK_ITALIC, MARK_STRIKETHROUGH, MARK_UNDERLINE } from '@udecode/plate-basic-marks';
import { usePagesEditorRef, usePagesEditorState } from '../../pages-plate-types';
import { isMarkActive, toggleMark } from '@udecode/slate-utils';

export const MarkButton: React.FC<React.PropsWithChildren<{ type: string; pluginKey: string; label: string }>> = ({
  type,
  pluginKey,
  children,
  label,
}) => {
  const editor = usePagesEditorState();
  const active = isMarkActive(editor, type);

  return (
    <ToolbarTooltip label={label.trim()}>
      <ToolbarButton
        aria-label={type}
        onMouseDown={e => {
          e.preventDefault();
          toggleMark(editor, { key: pluginKey });
        }}
        color={active ? 'brand.300' : 'white'}
      >
        {children}
      </ToolbarButton>
    </ToolbarTooltip>
  );
};

export const MarksButtons: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { metaSymbol } = useOS();
  const withMetaHint = (key: string) => (metaSymbol ? `(${metaSymbol}+${key})` : '');
  const withMetaShiftHint = (key: string) => (metaSymbol ? `(${metaSymbol}+Shift+${key})` : '');
  const editor = usePagesEditorRef();

  return (
    <>
      <MarkButton pluginKey={MARK_BOLD} type={getPluginType(editor, MARK_BOLD)} label={`Bold ${withMetaHint('B')}`}>
        <Icon size="4" icon="bold" />
      </MarkButton>

      <MarkButton
        pluginKey={MARK_ITALIC}
        type={getPluginType(editor, MARK_ITALIC)}
        label={`Italic ${withMetaHint('I')}`}
      >
        <Icon size="4" icon="italic" />
      </MarkButton>

      <MarkButton
        pluginKey={MARK_UNDERLINE}
        type={getPluginType(editor, MARK_UNDERLINE)}
        label={`Underline ${withMetaHint('U')}`}
      >
        <Icon size="4" icon="underline" />
      </MarkButton>

      <MarkButton
        pluginKey={MARK_STRIKETHROUGH}
        type={getPluginType(editor, MARK_STRIKETHROUGH)}
        label={`Strike-through ${withMetaShiftHint('S')}`}
      >
        <Icon size="4" icon="strikethrough" />
      </MarkButton>
    </>
  );
};
