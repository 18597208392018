import { RuleConstants } from '@process-street/subgrade/conditional-logic/rule-constants';
import { dayjs as moment } from '@process-street/subgrade/util';
import { trace } from 'components/trace';
import { FormFieldValueExtractor } from '../services/form-field-value-extractor';
import { OperandTypeResolver } from './operand-type-resolver';
import {
  MultiChoiceFormFieldValue,
  MultiChoiceFormFieldWidget,
  SimpleFieldValue,
} from '@process-street/subgrade/process';
import { ConditionEvaluator } from '@process-street/subgrade/conditional-logic';
import { FormFieldValueConverter } from 'directives/rules/services/form-field-value-converter';

const logger = trace({ name: 'IsNotCondition' });

export const IsNotCondition: ConditionEvaluator = {
  evaluate: (formFieldValue, formFieldWidget, operand) => {
    if (!formFieldValue && formFieldWidget) return true;
    if (!formFieldValue || !formFieldWidget || !operand) return false;

    const operandType = OperandTypeResolver.resolve(formFieldWidget);

    let evaluatedAsTrue = false;

    switch (operandType) {
      case RuleConstants.OperandType.STRING: {
        const value = FormFieldValueConverter.convertToStringOperand(formFieldWidget, formFieldValue);
        evaluatedAsTrue = value !== operand;
        break;
      }
      case RuleConstants.OperandType.STRING_LIST: {
        const selectedItemNames = FormFieldValueExtractor.extractSelectedItemNamesFromMultiChoice(
          formFieldValue as MultiChoiceFormFieldValue,
          formFieldWidget as MultiChoiceFormFieldWidget,
        );
        evaluatedAsTrue = selectedItemNames.length !== 1 || selectedItemNames[0] !== operand;
        break;
      }
      case RuleConstants.OperandType.DATE_TIME: {
        const fieldValue = (formFieldValue?.fieldValue as SimpleFieldValue)?.value;
        const operandMoment = moment(operand);
        const valueMoment = moment(fieldValue);
        if (operandMoment.isValid() && valueMoment.isValid()) {
          evaluatedAsTrue = !valueMoment.isSame(operandMoment);
        }
        break;
      }
      default:
        logger.error('unsupported operand type: %s', operandType);
    }

    return evaluatedAsTrue;
  },
};
