import { createAction } from 'redux-actions';
import { EventName } from 'services/event-name';
import { EventEmitterService } from '@process-street/subgrade/util/event-emitter-service';

export const SESSION_ORGANIZATION_USE_FREQUENCY_INCREMENT = 'session/organizationUseFrequency/INCREMENT';

export const SESSION_CLEAR = 'session/CLEAR';
export const SESSION_IMPORT = 'session/IMPORT';

export const SESSION_SET_USER = 'session/SET_USER';
export const SESSION_SET_PROFILE = 'session/SET_PROFILE';

export const SESSION_SET_DEFAULT_HOME_PAGE = 'session/SET_DEFAULT_HOMEPAGE';
export const SESSION_SET_SELECTED_ORGANIZATION_ID = 'session/SET_SELECTED_ORGANIZATION_ID';

export const SESSION_SET_INBOX_PROPERTY = 'session/SET_INBOX_PROPERTY';
export const SESSION_SET_CHECKLIST_STATS_PROPERTY = 'session/SET_CHECKLIST_STATS_PROPERTY';
export const SESSION_SET_ONBOARDING_PROPERTY = 'session/SET_ONBOARDING_PROPERTY';

export const SESSION_SET_TOKEN = 'session/SET_TOKEN';
export const SESSION_CLEAR_TOKEN = 'session/CLEAR_TOKEN';

export const SESSION_TEMPLATE_EDITOR_SET_PROPERTY = 'session/templateEditor/SET_PROPERTY';
export const SESSION_TEMPLATE_EDITOR_CLEAR_PROPERTY = 'session/templateEditor/CLEAR_PROPERTY';

export const SESSION_CHECKLIST_EDITOR_SET_PROPERTY = 'session/checklistEditor/SET_PROPERTY';
export const SESSION_CHECKLIST_EDITOR_CLEAR_PROPERTY = 'session/checklistEditor/CLEAR_PROPERTY';

export const SESSION_SET_ORGANIZATION_MEMBERSHIPS = 'session/organizationMemberships/SET_PROPERTY';
export const SESSION_SET_ORGANIZATION_SUBDOMAINS_MAP = 'session/organizationSubdomainsMap/SET_PROPERTY';

export const SESSION_SET_ELEVIO_HASH = 'session/SET_ELEVIO_HASH';
export const SESSION_SET_INTERCOM_HASH = 'session/SET_INTERCOM_HASH';

const setSelectedOrganizationIdAction = createAction(
  SESSION_SET_SELECTED_ORGANIZATION_ID,
  organizationId => organizationId,
);

const setSelectedOrganizationId = id => dispatch => {
  dispatch(setSelectedOrganizationIdAction(id));
  EventEmitterService.broadcast(EventName.SELECTED_ORGANIZATION_UPDATED, id);
};

export const SessionActions = {
  clear: createAction(SESSION_CLEAR),

  // Default homepage

  setDefaultHomepage: createAction(SESSION_SET_DEFAULT_HOME_PAGE, page => page),

  // Token

  setToken: createAction(SESSION_SET_TOKEN, token => token),
  deleteToken: createAction(SESSION_CLEAR_TOKEN),

  // Profile

  setProfile: createAction(SESSION_SET_PROFILE, profile => profile),

  // Inbox

  setInboxProperty: createAction(SESSION_SET_INBOX_PROPERTY, (key, value) => ({ key, value })),

  // Template Editor

  setTemplateEditorProperty: createAction(SESSION_TEMPLATE_EDITOR_SET_PROPERTY, (key, value) => ({ key, value })),
  deleteTemplateEditorProperty: createAction(SESSION_TEMPLATE_EDITOR_CLEAR_PROPERTY, key => key),

  // Checklist Editor

  setChecklistEditorProperty: createAction(SESSION_CHECKLIST_EDITOR_SET_PROPERTY, (key, value) => ({ key, value })),
  deleteChecklistEditorProperty: createAction(SESSION_CHECKLIST_EDITOR_CLEAR_PROPERTY, key => key),

  // Checklist Stats

  setChecklistStatsProperty: createAction(SESSION_SET_CHECKLIST_STATS_PROPERTY, (key, value) => ({ key, value })),

  // Organizations

  setSelectedOrganizationId,
  incrementOrganizationUseFrequency: createAction(
    SESSION_ORGANIZATION_USE_FREQUENCY_INCREMENT,
    organizationId => organizationId,
  ),

  // Organization Subdomains

  setOrganizationSubdomainsMap: createAction(SESSION_SET_ORGANIZATION_SUBDOMAINS_MAP, s => s),

  // Miscellaneous
  importSettings: createAction(SESSION_IMPORT, session => session),

  // User
  setUser: createAction(SESSION_SET_USER, user => user || null),
  setOnboardingProperty: createAction(SESSION_SET_ONBOARDING_PROPERTY, (key, value) => ({ key, value })),

  // Hash
  setElevioHash: createAction(SESSION_SET_ELEVIO_HASH, hash => hash),
  setIntercomHash: createAction(SESSION_SET_INTERCOM_HASH, hash => hash),
};
