import { Group, GroupType, Muid, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { isSystemGroupUserOm } from '@process-street/subgrade/util/membership-utils';
import { useGetAllGroupsQuery } from 'features/group/query-builder/get-all-groups';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import React from 'react';

const findGroupMembership = (groupType: GroupType, groups: Group[], memberships: OrganizationMembershipWithUser[]) => {
  const group = groups.find(x => x.groupType === groupType);
  const groupUserId = group && group.user && group.user.id;
  return memberships.find(x => x.user && x.user.id === groupUserId);
};

export function usePredefinedGroupMemberships({ organizationId }: { organizationId: Muid }) {
  const { data: groups } = useGetAllGroupsQuery({ include: 'user' });

  const { data: users } = useGetAllOrganizationMembershipsQuery(
    {
      organizationId,
    },
    {
      enabled: Boolean(groups),
      select: oms => oms.filter(isSystemGroupUserOm),
    },
  );

  return React.useMemo(() => {
    if (users && groups) {
      const allMembersMembership = findGroupMembership(GroupType.AllMembers, groups, users);
      const allGuestsMembership = findGroupMembership(GroupType.AllGuests, groups, users);
      const allAnonymousMembership = findGroupMembership(GroupType.AllAnonymous, groups, users);
      return { allMembersMembership, allGuestsMembership, allAnonymousMembership };
    }
    return {};
  }, [users, groups]);
}
