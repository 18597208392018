import * as React from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Link,
  Spinner,
  Switch,
  Tooltip,
  useToast,
} from 'components/design/next';
import { GetOrganizationQuery, useGetOrganizationQuery } from 'features/organization/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { isUndefined } from '@process-street/subgrade/core';
import { GetOrganizationTheme } from 'features/brand-customization/query-builder/get-organization-theme';
import { useQueryClient } from 'react-query';
import { useUpdateOrganizationEnhancedFileSecurityMutation } from 'features/organization/query-builder/update-organization-enhanced-file-security-mutation';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export type EnhancedFileSecurityFieldProps = {};

export const EnhancedFileSecurityField: React.FC<React.PropsWithChildren<EnhancedFileSecurityFieldProps>> = () => {
  const currentOrganization = useSelector(SessionSelector.getSelectedOrganization);

  const queryClient = useQueryClient();

  const [enhancedFileSecurity, setEnhancedFileSecurity] = React.useState<boolean>();

  const organizationQuery = useGetOrganizationQuery(
    { organizationId: currentOrganization?.id },
    // Set state when organization is manually refetched (e.g. mutation error)
    {
      onSuccess: result => setEnhancedFileSecurity(result.enhancedFileSecurity),
    },
  );
  const toast = useToast();

  const updateEnhancedFileSecurityMutation = useUpdateOrganizationEnhancedFileSecurityMutation({
    onSettled: async () => {
      await queryClient.invalidateQueries(GetOrganizationTheme.getKey({ organizationId: currentOrganization?.id }));
      await queryClient.invalidateQueries(GetOrganizationQuery.getKey({ organizationId: currentOrganization?.id }));
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems toggling the enhanced file security feature.",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  React.useEffect(() => {
    // Set initial state
    if (isUndefined(enhancedFileSecurity) && organizationQuery.data) {
      setEnhancedFileSecurity(organizationQuery.data.enhancedFileSecurity ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
  }, [organizationQuery.data]);

  return (
    <FormControl as={Grid} templateColumns={{ base: '1fr', md: '220px 1fr' }}>
      <GridItem display="flex" alignItems="center">
        <FormLabel color="gray.600" as="label">
          Enhanced File Security
        </FormLabel>
      </GridItem>
      <GridItem display="flex" flexDirection="row" alignItems="start">
        <Flex alignItems="start" minW="18">
          <Tooltip hasArrow label="Changes might take a few minutes to take effect.">
            <Box>
              <Switch
                mt="2px"
                size="lg"
                isDisabled={organizationQuery.isLoading || updateEnhancedFileSecurityMutation.isLoading}
                isChecked={enhancedFileSecurity}
                onChange={e => {
                  setEnhancedFileSecurity(e.target.checked);
                  if (currentOrganization) {
                    updateEnhancedFileSecurityMutation.mutate({
                      organizationId: currentOrganization?.id,
                      enhancedFileSecurity: e.target.checked,
                    });
                  }
                }}
              />
            </Box>
          </Tooltip>
          {updateEnhancedFileSecurityMutation.isLoading && <Spinner size="lg" ml="2" mt="2px" />}
        </Flex>
        <Link ml="2" href={ENHANCED_FILE_SECURITY_DOCS} color="brand.500" isExternal>
          Learn more »
        </Link>
      </GridItem>
    </FormControl>
  );
};

const ENHANCED_FILE_SECURITY_DOCS = 'https://www.process.st/help/docs/enhanced-file-security/';
