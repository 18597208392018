import { VideoWidgetService } from '@process-street/subgrade/process';
import React from 'react';

export interface EmbeddedVideoProps {
  code: string;
  service: string;
}

export class EmbeddedVideo extends React.PureComponent<EmbeddedVideoProps> {
  constructor(props: EmbeddedVideoProps) {
    super(props);
  }

  public render() {
    const { service } = this.props;

    switch (service) {
      case VideoWidgetService.YouTube:
        return this.renderYouTubeVideo();
      case VideoWidgetService.Vimeo:
        return this.renderVimeoVideo();
      case VideoWidgetService.Wistia:
        return this.renderWistiaVideo();
      case VideoWidgetService.Loom:
        return this.renderLoomVideo();
      default:
        return <span>Unknown service: {service}</span>;
    }
  }

  private renderYouTubeVideo() {
    const src = `https://www.youtube.com/embed/${this.props.code}?modestbranding=1&showinfo=0`;

    return (
      <div className="embed-responsive embed-responsive-16by9 widget-video-container">{this.renderIFrame(src)}</div>
    );
  }

  private renderVimeoVideo() {
    const src = `https://player.vimeo.com/video/${this.props.code}`;

    return (
      <div className="embed-responsive embed-responsive-16by9 widget-video-container">{this.renderIFrame(src)}</div>
    );
  }

  private renderLoomVideo() {
    const src = `https://www.loom.com/embed/${this.props.code}`;

    return (
      <div className="embed-responsive embed-responsive-16by9 widget-video-container">{this.renderIFrame(src)}</div>
    );
  }

  private renderIFrame(src: string) {
    return <iframe src={src} frameBorder="0" allowFullScreen={true} className="embed-responsive-item" />;
  }

  private renderWistiaVideo() {
    const className = `embed-responsive-item wistia_embed wistia_async_${this.props.code}`;

    return (
      <div className="embed-responsive embed-responsive-16by9 widget-video-container">
        <div className={className} />
      </div>
    );
  }
}
