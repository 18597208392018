import * as React from 'react';
import { useNgStateModalControls } from 'components/ng-state-modal-controls';
import { TemplateGallery } from '../TemplateGallery/TemplateGallery';
import styles from './TemplateGalleryModal.module.scss';
import { AppModalName } from 'app/app.constants';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from 'components/design/next';

export const ModalKey = AppModalName.TemplateGallery;

export const TemplateGalleryModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { isOpen, onClose, source, selectedFolderId } = useNgStateModalControls(ModalKey);

  return (
    <Modal aria-label={ModalKey} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className={styles.templateGalleryModal} overflow="hidden" maxW="none" mb="0">
        <ModalCloseButton color="gray.400" />
        <ModalBody h="full">
          <TemplateGallery source={source} selectedFolderId={selectedFolderId} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
