import { Grid, StackProps } from 'components/design/next';
import * as React from 'react';

type ShellProps = StackProps;

export const Shell: React.FC<React.PropsWithChildren<ShellProps>> = ({ children, ...props }) => {
  return (
    <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} alignItems="center" {...props}>
      {children}
    </Grid>
  );
};
