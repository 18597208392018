import { dayjs as moment } from '@process-street/subgrade/util';
import { DueDateRuleSourceType, FieldType } from '@process-street/subgrade/process';
import { DynamicDueDateEngine } from 'reducers/optimistic/rules/dynamic-due-date';
import { trace } from 'components/trace';
import { DateContextUtils } from '@process-street/subgrade/core/date-context';

export class TaskDueDateService {
  constructor(
    DueDateRuleDefinitionDao,
    DueDateRuleDefinitionValidationService,
    SessionService,
    TaskTemplateService,
    util,
  ) {
    'ngInject';

    this.logger = trace({ name: 'TaskDueDateService' });

    this.DueDateRuleDefinitionDao = DueDateRuleDefinitionDao;
    this.DueDateRuleDefinitionValidationService = DueDateRuleDefinitionValidationService;
    this.SessionService = SessionService;
    this.TaskTemplateService = TaskTemplateService;
    this.util = util;

    this.taskTemplateComparer = util.getOrderableComparer(this.toTaskTemplate);
  }

  /**
   * Calculates due date based on rule
   *
   * @return {object}
   */
  calculateDueDate(dueDateRule, checklist, tasks, formFieldValues) {
    this.DueDateRuleDefinitionValidationService.validateRuleObjectStructure(dueDateRule);

    let dueDate;
    switch (dueDateRule.sourceType) {
      case DueDateRuleSourceType.FormFieldValue:
        dueDate = this.calculateDueDateFromFormFieldValue(dueDateRule, formFieldValues);
        break;
      case DueDateRuleSourceType.TaskCompletedDate:
        dueDate = this.calculateDueDateFromTaskCompletedDate(dueDateRule, tasks);
        break;
      case DueDateRuleSourceType.PreviousTaskCompletedDate:
        dueDate = this.calculateDueDateFromPreviousTaskCompletedDate(dueDateRule, tasks);
        break;
      case DueDateRuleSourceType.TaskDueDate:
        dueDate = this.calculateDueDateFromTaskDueDate(dueDateRule, tasks);
        break;
      case DueDateRuleSourceType.ChecklistStartDate:
        dueDate = this.calculateDueDateFromChecklistStartDate(dueDateRule, checklist);
        break;
      case DueDateRuleSourceType.ChecklistDueDate:
        dueDate = this.calculateDueDateFromChecklistDueDate(dueDateRule, checklist);
        break;
      default:
        dueDate = undefined;
    }

    return dueDate;
  }

  calculateDueDateFromFormFieldValue(dueDateRule, formFieldValues) {
    const formFieldWidgetGroupId = dueDateRule.formFieldWidgetGroup && dueDateRule.formFieldWidgetGroup.id;

    if (!formFieldWidgetGroupId) {
      throw new ReferenceError(`formFieldWidgetGroup must be set (ruleId=${dueDateRule.id})`);
    }

    const sourceFormFieldValue = formFieldValues.find(
      ffv => ffv.formFieldWidget.header.group.id === formFieldWidgetGroupId,
    );

    if (!sourceFormFieldValue) {
      throw new ReferenceError(`could not find form field value (ruleId=${dueDateRule.id},
            formFieldWidgetGroupId=${formFieldWidgetGroupId})`);
    }
    if (sourceFormFieldValue.formFieldWidget.fieldType !== FieldType.Date) {
      throw new ReferenceError(`field is not of date type (ruleId=${dueDateRule.id},
            fieldType=${sourceFormFieldValue.formFieldWidget.fieldType})`);
    }

    const dateValue = sourceFormFieldValue.fieldValue && sourceFormFieldValue.fieldValue.value;

    const momentValue = dateValue && moment(dateValue, 'x');

    let dueDate;
    if (momentValue && momentValue.isValid()) {
      const organization = this.SessionService.getSelectedOrganization();
      const dateContext = organization ? DateContextUtils.getDateContext(organization) : undefined;
      dueDate = this.calculateRelativeDate(dueDateRule, momentValue, dateContext);
    }

    return dueDate;
  }

  calculateRelativeDate(dueDateRule, relativeDateMoment, dateContext) {
    return DynamicDueDateEngine.calculateRelativeDate(dueDateRule, relativeDateMoment, dateContext);
  }

  calculateDueDateFromTaskCompletedDate(dueDateRule, tasks) {
    const taskTemplateGroupId = dueDateRule.taskTemplateGroup && dueDateRule.taskTemplateGroup.id;
    if (!taskTemplateGroupId) {
      throw new ReferenceError(`taskTemplateGroup must be set (ruleId=${dueDateRule.id})`);
    }

    const sourceTask = tasks.find(t => t.taskTemplate.group.id === taskTemplateGroupId);
    if (!sourceTask) {
      throw new ReferenceError(`could not find task (ruleId=${dueDateRule.id},
            taskTemplateGroupId=${taskTemplateGroupId})`);
    }

    const dateValue = sourceTask.completedDate;
    const momentValue = dateValue && moment(dateValue, 'x');

    let dueDate;
    if (momentValue && momentValue.isValid()) {
      dueDate = this.calculateRelativeDate(dueDateRule, momentValue);
    }

    return dueDate;
  }

  toTaskTemplate(task) {
    return task.taskTemplate;
  }

  findPreviousTask(tasks, targetTaskTemplateGroupId) {
    const sortedTasksExcludingHeading = tasks
      .filter(t => !this.TaskTemplateService.isHeading(t.taskTemplate) && !t.hidden)
      .sort(this.taskTemplateComparer);

    const allTaskTemplateGroupIds = sortedTasksExcludingHeading.map(t => t.taskTemplate.group.id);

    const targetTaskTemplateIndex = allTaskTemplateGroupIds.indexOf(targetTaskTemplateGroupId);

    if (targetTaskTemplateIndex > 0) {
      return sortedTasksExcludingHeading[targetTaskTemplateIndex - 1];
    } else {
      return undefined;
    }
  }

  findNextTask(tasks, targetTaskTemplateGroupId) {
    const sortedTasksExcludingHeading = tasks
      .filter(t => !this.TaskTemplateService.isHeading(t.taskTemplate) && !t.hidden)
      .sort(this.taskTemplateComparer);

    const allTaskTemplateGroupIds = sortedTasksExcludingHeading.map(t => t.taskTemplate.group.id);

    const targetTaskTemplateIndex = allTaskTemplateGroupIds.indexOf(targetTaskTemplateGroupId);

    if (targetTaskTemplateIndex >= 0 && targetTaskTemplateIndex + 1 < sortedTasksExcludingHeading.length) {
      return sortedTasksExcludingHeading[targetTaskTemplateIndex + 1];
    } else {
      return undefined;
    }
  }

  /**
   * This function calculates the due date based on the previous task
   * It assumes that the previous task actually exists
   *
   * @param dueDateRule
   * @param tasks
   * @return {undefined}
   */
  calculateDueDateFromPreviousTaskCompletedDate(dueDateRule, tasks) {
    const targetTaskTemplateGroupId = dueDateRule.targetTaskTemplateGroup && dueDateRule.targetTaskTemplateGroup.id;
    if (!targetTaskTemplateGroupId) {
      throw new ReferenceError(`targetTaskTemplateGroup must be set (ruleId=${dueDateRule.id})`);
    }

    const previousTask = this.findPreviousTask(tasks, targetTaskTemplateGroupId);
    if (!previousTask) {
      throw new ReferenceError(`could not find previous task (ruleId=${dueDateRule.id},
            targetTaskTemplateGroupId=${targetTaskTemplateGroupId})`);
    }

    const dateValue = previousTask.completedDate;
    const momentValue = dateValue && moment(dateValue, 'x');

    let dueDate;
    if (momentValue && momentValue.isValid()) {
      dueDate = this.calculateRelativeDate(dueDateRule, momentValue);
    }

    return dueDate;
  }

  calculateDueDateFromTaskDueDate(dueDateRule, tasks) {
    const taskTemplateGroupId = dueDateRule.taskTemplateGroup && dueDateRule.taskTemplateGroup.id;
    if (!taskTemplateGroupId) {
      throw new ReferenceError(`taskTemplateGroup must be set (ruleId=${dueDateRule.id})`);
    }

    const sourceTask = tasks.find(t => t.taskTemplate.group.id === taskTemplateGroupId);
    if (!sourceTask) {
      throw new ReferenceError(`could not find task (ruleId=${dueDateRule.id},
            taskTemplateGroupId=${taskTemplateGroupId})`);
    }

    const dateValue = sourceTask.dueDate;
    const momentValue = dateValue && moment(dateValue, 'x');

    let dueDate;
    if (momentValue && momentValue.isValid()) {
      dueDate = this.calculateRelativeDate(dueDateRule, momentValue);
    }

    return dueDate;
  }

  calculateDueDateFromChecklistStartDate(dueDateRule, checklist) {
    const momentValue = moment(checklist.audit.createdDate, 'x');

    let dueDate;
    if (momentValue && momentValue.isValid()) {
      dueDate = this.calculateRelativeDate(dueDateRule, momentValue);
    }

    return dueDate;
  }

  calculateDueDateFromChecklistDueDate(dueDateRule, checklist) {
    const dateValue = checklist.dueDate;
    const momentValue = dateValue && moment(dateValue, 'x');

    let dueDate;
    if (momentValue && momentValue.isValid()) {
      dueDate = this.calculateRelativeDate(dueDateRule, momentValue);
    }

    return dueDate;
  }
}
