import {
  GroupType,
  isOrganizationMembershipWithUser,
  Muid,
  OrganizationMembershipWithUser,
  User,
} from '@process-street/subgrade/core';
import { FolderPermit, TemplatePermit } from '@process-street/subgrade/permission';
import { createUsableContext } from '@process-street/subgrade/util';
import { Box, Divider, HStack, Input, Spinner, VStack } from 'components/design/next';
import { PermissionsDescription } from 'components/permissions/permissions-description/component';
import { StringUtils } from '@process-street/subgrade/util';
import { useReadableFoldersQuery } from 'features/folder/query-builder';
import { useGetAllGroupsQuery } from 'features/group/query-builder/get-all-groups';
import { useFolderPermitsQuery, useTemplatePermitsQuery } from 'features/permits/query-builder';
import uniqBy from 'lodash/fp/uniqBy';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { canAccess, Feature } from 'services/features/features';
import { ParticipantRow } from '../participant-row/component';
import type { ParticipantRowProps } from '../participant-row/component';
import { isAnonymousUserOm } from '@process-street/subgrade/util/membership-utils';
import { TemplateType } from '@process-street/subgrade/process';

export type Props = { id: Muid; subject: 'folder' | 'template'; templateType?: TemplateType } & Pick<
  ParticipantRowProps,
  'onRemove'
>;

type Context = Pick<Props, 'subject' | 'id'> & { restricted: boolean };
export const [useParticipantsContext, ParticipantsContext] = createUsableContext<Context>({
  hookName: 'useParticipantsContext',
  providerName: 'ParticipantsContext.Provider',
});

export const Participants: React.FC<React.PropsWithChildren<Props>> = ({ id, subject, templateType, onRemove }) => {
  const [searchQuery, setSearch] = React.useState('');

  const currentPlan = useSelector(SessionSelector.getCurrentPlan);
  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const restricted = !canAccess(Feature.FULL_PERMISSIONS, currentPlan?.id);
  const groupsQuery = useGetAllGroupsQuery({ include: 'user' });
  const readableFoldersQuery = useReadableFoldersQuery(selectedOrganizationId!, {
    enabled: !!selectedOrganizationId,
  });
  const isHiddenSystemGroup = React.useCallback(
    (user: User) => {
      if (!groupsQuery.data) return false;

      const group = groupsQuery.data!.find(g => g.user.id === user.id);
      return group?.groupType && [GroupType.AllFreeMembers, GroupType.AllAdmins].includes(group?.groupType);
    },
    [groupsQuery.data],
  );

  /**
   * Here and in {@link ParticipantRow}, we have to disambiguate between folders and templates because the API endpoint itself is dynamic.
   * You'll see a few places where we set up two queries (one for template, one for folder) but only one of them will run based on `subject`.
   */
  const templatePermitsQuery = useTemplatePermitsQuery(
    { id },
    {
      enabled: subject === 'template',
      select: permits => {
        return {
          permits: permits.permits.filter(p => {
            if (isOrganizationMembershipWithUser(p.organizationMembership)) {
              const { user } = p.organizationMembership;
              return !isHiddenSystemGroup(user);
            }
            return false;
          }),
          inheritedPermits: permits.inheritedPermits.filter(p => {
            if (isOrganizationMembershipWithUser(p.organizationMembership)) {
              const { user } = p.organizationMembership;
              const folder = readableFoldersQuery.data?.find(f => f.id === p.folder.id);
              return !isHiddenSystemGroup(user) && !!folder?.parentFolder;
            }
            return false;
          }),
        };
      },
    },
  );
  const folderPermitsQuery = useFolderPermitsQuery(
    { id },
    {
      enabled: subject === 'folder',
      select: permits => {
        return {
          permits: permits.permits.filter(p => {
            if (isOrganizationMembershipWithUser(p.organizationMembership)) {
              const { user } = p.organizationMembership;
              return !isHiddenSystemGroup(user);
            }
            return false;
          }),
          inheritedPermits: permits.inheritedPermits.filter(p => {
            if (isOrganizationMembershipWithUser(p.organizationMembership)) {
              const { user } = p.organizationMembership;
              const folder = readableFoldersQuery.data?.find(f => f.id === p.folder.id);
              return !isHiddenSystemGroup(user) && !!folder?.parentFolder;
            }
            return false;
          }),
        };
      },
    },
  );

  const permitsQuery = subject === 'template' ? templatePermitsQuery : folderPermitsQuery;

  const combinedPermits = [
    ...(permitsQuery.data?.permits
      .filter(
        p => isOrganizationMembershipWithUser(p.organizationMembership) && !isAnonymousUserOm(p.organizationMembership),
      )
      .sort(({ organizationMembership: a }, { organizationMembership: b }) =>
        isOrganizationMembershipWithUser(a) && isOrganizationMembershipWithUser(b)
          ? a.user.username.toLowerCase().localeCompare(b.user.username.toLowerCase())
          : 0,
      ) ?? []),
    ...(permitsQuery.data?.inheritedPermits ?? []),
  ] as Array<(TemplatePermit | FolderPermit) & { organizationMembership: OrganizationMembershipWithUser }>;

  const combinedMemberships = uniqBy(
    om => om.id,
    combinedPermits.map(p => p.organizationMembership),
  );

  const visiblePermits = combinedPermits
    .filter(m => {
      if (searchQuery === '') return true;
      const inUsername = StringUtils.containsIgnoreCase(m.organizationMembership.user.username, searchQuery);
      const inEmail = StringUtils.containsIgnoreCase(m.organizationMembership.user.email, searchQuery);
      return inUsername || inEmail;
    })
    .slice(0, 50);

  return permitsQuery.isLoading ? (
    <Spinner />
  ) : (
    <ParticipantsContext.Provider value={{ subject, id, restricted }}>
      <Box px="3">
        <HStack justifyContent="space-between" my="4">
          {combinedMemberships.length > 0 ? (
            <PermissionsDescription flex="1" memberships={combinedMemberships} />
          ) : null}

          {combinedPermits.length > 0 ? (
            <Input
              variant="outline"
              w="50%"
              value={searchQuery}
              onChange={e => setSearch(e.target.value)}
              placeholder="Filter current users"
            />
          ) : null}
        </HStack>

        <VStack divider={<Divider my="0!important" />} alignItems="stretch">
          {visiblePermits.map(p => (
            <ParticipantRow key={p.id} permit={p} templateType={templateType} onRemove={onRemove} />
          ))}
        </VStack>
      </Box>
    </ParticipantsContext.Provider>
  );
};
