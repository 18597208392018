import { useDisclosure } from '@chakra-ui/react';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  isNativeAutomationWithLink,
  isTemplateSolutionInstance,
  SolutionTypeTag,
  TemplateSolutionInstance,
  TraySolution,
} from '@process-street/subgrade/automation';
import { Muid, MuidUtils } from '@process-street/subgrade/core';
import { NativeAutomation, NativeAutomationWithLink } from '@process-street/subgrade/process';
import { Box, Button, Text, VStack } from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import {
  SolutionInstancesByTemplateIdQuery,
  SolutionInstancesByTemplateIdQueryResponse,
} from 'features/automations/query-builder';
import { useCreateTemplateSolutionInstanceMutation } from 'features/automations/query-builder/create-template-solution-instance-mutation';
import { useFeatureFlag } from 'features/feature-flags';
import * as AutomationSelectorMachine from 'pages/templates/_id/automation/components/selector/context';
import { PublishTaskToPageConfigModal } from 'pages/templates/_id/automation/_id/publish-task-to-page-config-modal';
import React from 'react';
import { useQueryClient } from 'react-query';
import { usePromise } from 'react-use';
import { useTaskTemplate } from '../../use-task-template';
import { useTaskTemplateGroupId } from '../../utils/use-task-template-group-id';
import { useConfigWizardDisclosure } from '../../_id/config-wizard/context';

export interface SolutionsSelectorProps {
  /** When listing PS2PS automations, also lists relevant selectable native automations. */
  solutions: TraySolution[];
  /** Disables editing Tray instances if it's a native automation. */
  configuredAutomation?: TemplateSolutionInstance | NativeAutomationWithLink;
}

export const SolutionsSelector: React.FC<React.PropsWithChildren<SolutionsSelectorProps>> = ({
  solutions,
  configuredAutomation,
}) => {
  const configuredTrayAutomation = isTemplateSolutionInstance(configuredAutomation) ? configuredAutomation : undefined;
  const configuredNativeAutomation = isNativeAutomationWithLink(configuredAutomation)
    ? configuredAutomation
    : undefined;
  const [loadingSolutionId, setLoadingSolutionId] = React.useState<TraySolution['id']>();
  const isLoadingSolution = Boolean(loadingSolutionId);

  const taskTemplateGroupId = useTaskTemplateGroupId();
  const taskTemplateId = useTaskTemplate()?.id;
  const configWizardModalDisclosure = useConfigWizardDisclosure();
  const mounted = usePromise();
  const [, send] = AutomationSelectorMachine.useAutomationSelector();

  const queryClient = useQueryClient();
  const createTemplateSolutionInstanceMutation = useCreateTemplateSolutionInstanceMutation({
    onSuccess: templateSolutionInstance => {
      if (!templateSolutionInstance) return;

      queryClient.setQueryData<SolutionInstancesByTemplateIdQueryResponse>(
        SolutionInstancesByTemplateIdQuery.getKey({ templateId: templateSolutionInstance.templateId }),
        current => [...(current ?? []), templateSolutionInstance],
      );
    },
  });

  const templateId = useInjector('$stateParams').$stateParams.id;

  const createAutomation = async (solutionId: Muid) => {
    if (!templateId) return;

    const id = MuidUtils.randomMuid();

    await createTemplateSolutionInstanceMutation.mutateAsync({
      id,
      templateId,
      solutionId,
      taskTemplateGroupId,
    });

    setLoadingSolutionId(undefined);

    send({
      type: 'AUTOMATION_CONFIGURED',
      payload: { id, automationType: 'tray' },
    });
    configWizardModalDisclosure.onOpen();
  };

  const handleSelectSolution = (solution: TraySolution) => () => {
    setLoadingSolutionId(solution.id);
    if (configuredTrayAutomation) {
      configWizardModalDisclosure.onOpen();
      setLoadingSolutionId(undefined);
    } else {
      void mounted(createAutomation(solution.id));
    }
  };

  const configModalDisclosure = useDisclosure();

  const handleSelectNativeAutomation = () => {
    configModalDisclosure.onOpen();
  };

  const hasActiveConfiguredAutomation =
    (configuredTrayAutomation?.configured && configuredTrayAutomation?.enabled) ||
    isNativeAutomationWithLink(configuredAutomation);
  const hasNoConfiguredAutomation = !configuredAutomation;

  const isPublishTaskToPageEnabled = useFeatureFlag('publishTaskToPage');
  const isShowingPsTrayAutomations =
    solutions[0]?.automationApp === 'ProcessStreet' &&
    solutions[0]?.solutionTypeTag === SolutionTypeTag.WhenTaskCheckedThen;
  const shouldShowNativeAutomations =
    isPublishTaskToPageEnabled && (isShowingPsTrayAutomations || Boolean(configuredNativeAutomation));

  return (
    <>
      {isPublishTaskToPageEnabled && templateId && taskTemplateId && (
        <PublishTaskToPageConfigModal
          {...configModalDisclosure}
          configuredAutomation={configuredNativeAutomation}
          templateId={templateId}
          taskTemplateId={taskTemplateId}
        />
      )}
      <VStack align="stretch" spacing="4">
        {solutions.map(s => {
          const isSolutionSelected = configuredTrayAutomation?.solutionId === s.id;
          // if automation is configured, only enable editing current solution, and only when it's turned off
          const isSelectedInactiveSolution = !hasActiveConfiguredAutomation && isSolutionSelected;
          const isEnabled = !isLoadingSolution && (hasNoConfiguredAutomation || isSelectedInactiveSolution);
          return (
            <Box key={s.id}>
              <Button
                w="full"
                variant={isSolutionSelected ? 'secondary' : 'tertiary'}
                onClick={handleSelectSolution(s)}
                isLoading={loadingSolutionId === s.id}
                isDisabled={!isEnabled}
                isActive={isSolutionSelected}
                loadingText="Creating Automation"
                rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                justifyContent="space-between"
              >
                <Text fontWeight="normal" as="span" noOfLines={1}>
                  …{s.description}
                </Text>
              </Button>
            </Box>
          );
        })}
        {shouldShowNativeAutomations && (
          <Box>
            <Button
              w="full"
              variant={configuredNativeAutomation ? 'secondary' : 'tertiary'}
              onClick={handleSelectNativeAutomation}
              isDisabled={Boolean(configuredTrayAutomation)}
              isActive={Boolean(configuredNativeAutomation)}
              loadingText="Creating Automation"
              rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
              justifyContent="space-between"
            >
              <Text fontWeight="normal" as="span" noOfLines={1}>
                …{NativeAutomation.workflowPageAutomationDescription}
              </Text>
            </Button>
          </Box>
        )}
      </VStack>
    </>
  );
};
