import { Organization } from '@process-street/subgrade/core';
import { Folder } from '@process-street/subgrade/process';
import { FolderPickerApi } from 'components/folder-picker/folder-picker-api';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';

export type GetAllReadableFoldersByOrganizationIdResult = Folder[];

export type GetAllReadableFoldersByOrganizationIdParams = Organization['id'];

export const GetAllReadableFoldersByOrganizationId = {
  key: 'organizations',
  getKey(selectedOrganizationId: GetAllReadableFoldersByOrganizationIdParams): QueryKey {
    return [GetAllReadableFoldersByOrganizationId.key, selectedOrganizationId, 'folders', 'readable'];
  },
  queryFn: FolderPickerApi.getAllReadableFoldersByOrganizationId,
};

export const useReadableFoldersQuery = <Select = GetAllReadableFoldersByOrganizationIdResult>(
  params: GetAllReadableFoldersByOrganizationIdParams,
  options: UseQueryOptions<GetAllReadableFoldersByOrganizationIdResult, AxiosError, Select> = {},
) =>
  useQuery(
    GetAllReadableFoldersByOrganizationId.getKey(params),
    () => GetAllReadableFoldersByOrganizationId.queryFn(params),
    options,
  );
