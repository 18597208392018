import { Verb } from 'services/activities/verb';
import { escapeHtml as esc } from '@process-street/subgrade/util';
import { sprintf as spf } from 'sprintf-js';
import { InterpretedActivity, InterpreterContext } from 'services/activities/interpreters/InterpreterContext';
import { ActivityObjectType, GroupMembershipActivity } from '@process-street/subgrade/activity';
import { Option } from '@process-street/subgrade/core';

function interpret(activity: GroupMembershipActivity, ctx: InterpreterContext) {
  let sentence: Option<InterpretedActivity> = ctx.interpret(activity);
  const { groupId, groupName, userId, userName } = activity.data;

  switch (activity.verb) {
    case Verb.CREATED:
      sentence.verb.value = 'added';
      sentence.objects.push({
        value: spf('<a>%s</a>', esc(ctx.abbr(userName))),
        tooltip: userName,
        type: ActivityObjectType.User,
        id: userId,
      });

      sentence.objects.push({
        value: spf(' to <a>%s</a> group', esc(ctx.abbr(groupName))),
        type: ActivityObjectType.Group,
        id: groupId,
      });
      break;

    case Verb.DELETED:
      sentence.verb.value = 'removed';
      sentence.objects.push({
        value: spf('<a>%s</a>', esc(ctx.abbr(userName))),
        tooltip: userName,
        type: ActivityObjectType.User,
        id: userId,
      });

      sentence.objects.push({
        value: spf(' from <a>%s</a> group', esc(ctx.abbr(groupName))),
        type: ActivityObjectType.Group,
        id: groupId,
      });
      break;

    default:
      ctx.logger.error('unrecognized verb: %s', activity.verb);
      sentence = undefined;
  }
  return sentence;
}

export const GroupMembershipActivityInterpreter = {
  interpret,
};
