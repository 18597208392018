import { useBreakpointValue } from '@chakra-ui/media-query';
import { useInjector } from 'app/components/injection-provider';
import {
  useCloseOneOffTaskDrawer,
  useViewTaskById,
  useViewTaskByItem,
} from 'app/features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { useTimezone } from 'app/hooks/use-timezone';
import { useCallback, useMemo } from 'react';
import { useFocusedItemStore } from '../../hooks/use-focused-item-store';
import { useInboxItemDetailsModal } from '../../hooks/use-inbox-item-details-modal';
import { useTasksFilters } from '../../tasks-filters-provider';
import { useInboxItemsGridContextStore } from '../../use-inbox-items-grid-context-store';

export const useGridContext = () => {
  const gridApi = useInboxItemsGridContextStore(useCallback(state => state.gridApi, []));
  const { groupBy, ...inboxQueryParams } = useTasksFilters();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const {
    onOpen: openDrawer,
    openNextItemIfAvailable,
    getNextNode,
  } = useInboxItemDetailsModal(gridApi, inboxQueryParams.includeCompleted, groupBy);
  const { $rootScope } = useInjector('$rootScope');
  const focusManagerActions = useFocusedItemStore(useCallback(state => state.actions, []));
  const viewTaskById = useViewTaskById();
  const viewTaskByItem = useViewTaskByItem();
  const closeTaskDrawer = useCloseOneOffTaskDrawer();
  const timeZone = useTimezone() ?? 'UTC';

  return useMemo(
    () => ({
      filters: inboxQueryParams,
      isMobile,
      openDrawer,
      openNextItemIfAvailable,
      getNextNode,
      $rootScope,
      focusManagerActions,
      viewTaskById,
      viewTaskByItem,
      closeTaskDrawer,
      timeZone,
    }),
    // This rule is disabled because the `openDrawer`,`openNextItemIfAvailable`,`getNextNode` references are unstable.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isMobile,
      $rootScope,
      focusManagerActions,
      closeTaskDrawer,
      timeZone,
      viewTaskById,
      viewTaskByItem,
      inboxQueryParams,
    ],
  );
};

export type MyWorkGridContext = ReturnType<typeof useGridContext>;
