import { Muid, Option } from '@process-street/subgrade/core';
import { axiosService } from 'services/axios-service';

const authorize = (organizationId: Muid, code: string): Promise<void> =>
  axiosService.getAxios().post('/1/slack/authorize', {
    organizationId,
    code,
  });
const connect = (
  organizationId: Muid,
  slackTeamId: string,
  slackUserId: string,
  enterpriseId: Option<string>,
  hmac: string,
): Promise<void> =>
  axiosService.getAxios().post('/1/slack/connect', {
    organizationId,
    slackTeamId,
    slackUserId,
    enterpriseId,
    hmac,
  });

export const SlackApi = {
  authorize,
  connect,
};
