import React from 'react';
import { Button, Menu, MenuButton, MenuList, MenuItem, Icon, Tooltip } from 'components/design/next';
import { WebhookType } from '@process-street/subgrade/process';

export type AddWebhookButtonProps = {
  tooltip: string;
  disabled: boolean;
  onClick: (webhookType: WebhookType) => void;
};

export const AddWebhookButton: React.FC<React.PropsWithChildren<AddWebhookButtonProps>> = ({
  tooltip,
  disabled,
  onClick,
}) => {
  const handleWorkflowItemClick = () => onClick('Workflow');
  const handleFormItemClick = () => onClick('Form');
  const handleDataSetItemClick = () => onClick('DataSet');

  return (
    <Menu>
      <Tooltip label={tooltip} hasArrow placement="top" shouldWrapChildren>
        <MenuButton as={Button} isDisabled={disabled} leftIcon={<Icon variant="far" icon="plus" size="4" />}>
          New Webhook
        </MenuButton>
      </Tooltip>
      <MenuList>
        <MenuItem
          aria-label="create workflow webhook"
          w="full"
          icon={<Icon icon="workflow" variant="far" size="4" />}
          onClick={handleWorkflowItemClick}
        >
          Workflow
        </MenuItem>
        <MenuItem
          aria-label="create form webhook"
          w="full"
          icon={<Icon icon="ballot" variant="far" size="4" />}
          onClick={handleFormItemClick}
        >
          Form
        </MenuItem>
        <MenuItem
          aria-label="create data set webhook"
          w="full"
          icon={<Icon icon="database" variant="far" size="4" />}
          onClick={handleDataSetItemClick}
        >
          Data Set
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
