import { Template } from '@process-street/subgrade/process';
import { PremadeTemplateOverview, Category } from '@process-street/subgrade/process/premade-template-model';
import { getEnv } from 'components/common/env';

const starterTemplateTag = 'Starter Template';

const sortCategories = (categories: Category[], defaultCategoryName?: Category): Category[] => {
  return categories.slice().sort((a: Category, b: Category) => {
    if (a.name === defaultCategoryName?.name) {
      return -1;
    } else if (b.name === defaultCategoryName?.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  });
};

const sortTemplates = (templates: PremadeTemplateOverview[]): PremadeTemplateOverview[] => {
  return templates.slice().sort((a: PremadeTemplateOverview, b: PremadeTemplateOverview) => {
    if (isStarterTemplate(a) && !isStarterTemplate(b)) {
      return -1;
    } else if (!isStarterTemplate(a) && isStarterTemplate(b)) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  });
};

const isStarterTemplate = (template: PremadeTemplateOverview) =>
  template.tags.find(tag => tag.name === starterTemplateTag);

const isCopiedFromBlank = (template: Template) => template.copiedFromTemplateId === getEnv().APP_BLANK_WORKFLOW_ID;

const isCopiedFromTemplateGallery = (template: Template) => !isCopiedFromBlank(template);

export const TemplateGalleryHelper = {
  sortCategories,
  sortTemplates,
  isCopiedFromBlank,
  isCopiedFromTemplateGallery,
};
