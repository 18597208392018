import { Muid } from '@process-street/subgrade/core';
import { FolderPermit } from '@process-street/subgrade/permission';
import { createSuccessAction } from '@process-street/subgrade/redux/action-creator';
import { SuccessAction } from '@process-street/subgrade/redux/types';
import { AxiosResponse } from 'axios';
import { FlashActions } from 'reducers/flash/flash.actions';
import { ReduxAppState } from 'reducers/types';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { axiosService } from 'services/axios-service';

export const FOLDER_PERMITS_GET_ALL_BY_FOLDER_AND_ORGANIZATION_ID =
  'permits/folder/FOLDER_PERMITS_GET_ALL_BY_FOLDER_AND_ORGANIZATION_ID';
export const DELETE_FOLDER_PERMIT = 'permits/folder/DELETE_FOLDER_PERMITS';
export const CREATE_FOLDER_PERMIT = 'permits/folder/CREATE_FOLDER_PERMITS';
export const UPDATE_FOLDER_PERMIT = 'permits/folder/UPDATE_FOLDER_PERMITS';

export interface GetFolderPermitsActionPayload {
  folderId: Muid;
  response: FolderPermit[];
}

export interface CreateOrUpdateFolderPermitActionPayload {
  permit: FolderPermit;
  folderId: Muid;
}

export interface DeleteFolderPermitActionPayload {
  permitId: Muid;
  folderId: Muid;
}

export type GetFolderPermitsActionType = SuccessAction<GetFolderPermitsActionPayload, null>;

export type CreateOrUpdateFolderPermitActionType = SuccessAction<CreateOrUpdateFolderPermitActionPayload, null>;

export type DeleteFolderPermitActionType = SuccessAction<DeleteFolderPermitActionPayload, null>;

const createFolderPermitAction = (folderId: Muid, permit: FolderPermit) =>
  createSuccessAction(CREATE_FOLDER_PERMIT, { folderId, permit });

const deleteFolderPermitAction = (folderId: Muid, permitId: Muid) =>
  createSuccessAction(DELETE_FOLDER_PERMIT, {
    folderId,
    permitId,
  });

const getAllFolderPermitsAction = (folderId: Muid, folderPermits: FolderPermit[]) =>
  createSuccessAction(FOLDER_PERMITS_GET_ALL_BY_FOLDER_AND_ORGANIZATION_ID, {
    folderId,
    response: folderPermits,
  });

const updateFolderPermitAction = (folderId: Muid, permit: FolderPermit) =>
  createSuccessAction(UPDATE_FOLDER_PERMIT, { folderId, permit });

export const FolderMembershipActions = {
  createFolderPermit:
    (folderId: Muid, permit: FolderPermit): ThunkAction<void, ReduxAppState, Record<string, unknown>, AnyAction> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>) =>
      dispatch(createFolderPermitAction(folderId, permit)),

  deleteFolderPermit:
    (folderId: Muid, permitId: Muid): ThunkAction<void, ReduxAppState, Record<string, unknown>, AnyAction> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>) =>
      dispatch(deleteFolderPermitAction(folderId, permitId)),

  getFolderPermits:
    (folderId: Muid, organizationId: Muid): ThunkAction<void, ReduxAppState, Record<string, unknown>, AnyAction> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>) => {
      axiosService
        .getAxios()
        .get(
          `/1/permits/folder?include=organizationMembership.user&where=${encodeURIComponent(
            `{"folderId":{"_eq":"${folderId}"}}`,
          )}`,
          {
            headers: { 'X-Process-Street-Organization-Id': organizationId },
          },
        )
        .then(
          (response: AxiosResponse) => {
            const folderPermits = response.data;
            dispatch(getAllFolderPermitsAction(folderId, folderPermits));
          },
          () => {
            dispatch(
              FlashActions.showCriticalDangerNotice(
                'Oops, cannot fetch memberships for a folder. Please contact support.',
              ),
            );
          },
        );
    },

  updateFolderPermit:
    (folderId: Muid, permit: FolderPermit): ThunkAction<void, ReduxAppState, Record<string, unknown>, AnyAction> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>) =>
      dispatch(updateFolderPermitAction(folderId, permit)),
};
