import { Muid } from '@process-street/subgrade/core';
import { Image, HStack, Icon, Box } from 'components/design/next';
import { GetOrganizationTheme } from 'features/brand-customization/query-builder/get-organization-theme';
import { DeleteEmailHeaderLogoMutation } from 'features/organization/query-builder';
import * as React from 'react';
import { useQueryClient } from 'react-query';

export interface EmailBrandLogoProps {
  organizationId: Muid;
  logoUrl: string;
}

export const EmailBrandLogo: React.FC<React.PropsWithChildren<EmailBrandLogoProps>> = ({ organizationId, logoUrl }) => {
  const queryClient = useQueryClient();

  const deleateLogoMutation = DeleteEmailHeaderLogoMutation.useMutation({
    onSuccess: _ => queryClient.invalidateQueries(GetOrganizationTheme.getKey({ organizationId })),
  });

  const handleRemoveLogo = () => {
    deleateLogoMutation.mutate({ organizationId });
  };

  return (
    <HStack spacing="2">
      <Image
        fit="scale-down"
        w="27"
        h="10"
        borderRadius="2px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="gray.300"
        src={logoUrl}
      />
      <Box
        h="10"
        w="10"
        borderRadius="2px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="gray.300"
        display="flex"
        alignItems="center"
        justifyContent="center"
        _hover={{ cursor: 'pointer' }}
        onClick={handleRemoveLogo}
      >
        <Icon icon="trash-alt" size="4" variant="far" color="gray.600" />
      </Box>
    </HStack>
  );
};
