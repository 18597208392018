import { useToken } from '@chakra-ui/react';

export const BREAKPOINT_SIZES = ['base', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'] as const;
export type BreakpointSize = typeof BREAKPOINT_SIZES[number];

export function useBreakpointPxMap(): Record<BreakpointSize, number> {
  const breakpointsInEm = useToken(
    'breakpoints',
    [...BREAKPOINT_SIZES],
    ['0em', '30em', '48em', '62em', '80em', '96em', '120em'],
  ) as string[];
  const breakpointsInPx = Object.fromEntries(
    breakpointsInEm.map((em, i) => [BREAKPOINT_SIZES[i], parseInt(em) * 16]),
  ) as Record<BreakpointSize, number>;
  return breakpointsInPx;
}
