import * as React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { CreateOneOffTaskSettingsSchema } from '../components/create/schema';
import { FormikTouched } from 'formik';

export const useTaskEditorFields = (
  setTouched: (touched: FormikTouched<CreateOneOffTaskSettingsSchema>) => void,
  setFieldValue: (field: string, value: string) => void,
  touched: FormikTouched<CreateOneOffTaskSettingsSchema>,
  values: CreateOneOffTaskSettingsSchema,
) => {
  const debouncedSetFieldValue = useDebouncedCallback((field: string, value: string) => {
    setFieldValue(field, value);
    if (!touched[field as keyof CreateOneOffTaskSettingsSchema]) {
      setFieldTouched(field);
    }
  }, 500);

  const [editorContent, setEditorContent] = React.useState(values.description);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetFieldValue('name', event.target.value);
    setFieldTouched('name');
  };

  const setFieldTouched = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  const handleDescriptionChange = React.useCallback(
    (content: string) => {
      setEditorContent(content);
      debouncedSetFieldValue('description', content);
    },
    [debouncedSetFieldValue],
  );
  return { handleDescriptionChange, handleNameChange, editorContent, setFieldTouched };
};
