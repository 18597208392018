import angular from 'angular';

import { AdditionalCostComponent } from './additional-cost-message.component';
import { UserInviteComponent } from './user-invite.component';
import { TotalUsersHeadingComponent } from './total-users-heading.component';
import { reactToAngular } from 'components/react-root';
import { UserList } from './user-list';

export const OrganizationManageUsersModule = angular
  .module('organization-manage-user', ['ngRedux', 'ui.bootstrap'])
  .component('psUserInvite', UserInviteComponent)
  .component('psUserList', reactToAngular(UserList, ['organizationId']))
  .component('psTotalUsersHeading', TotalUsersHeadingComponent)
  .component('psAdditionalCostMessage', AdditionalCostComponent).name;
