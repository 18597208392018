import { isGuest, Option, Organization, UserInfo } from '@process-street/subgrade/core';
import { getTrialDaysRemaining, isSubscriptionExpired } from 'utils/billing';

import { getTitleForPlanLevel, isPlanFree, Plan } from '@process-street/subgrade/billing';
import { isTrialing } from 'utils/organization';
import { isPlanIdLegacy } from 'utils/plans';
import { htmlEscaped } from '@process-street/subgrade/util';
import { match } from 'ts-pattern';

type GetWarningMessageProps = Partial<{
  organization: Organization;
  plan: Plan;
  limits: string[];
  userInfo: Option<UserInfo>;
}>;

export const getWarningMessage = ({ organization, plan, limits, userInfo }: GetWarningMessageProps): string => {
  if (!userInfo) return '';

  const userIsGuest = isGuest(userInfo.organizationMembership.role);

  if (!organization || !plan || !limits || userIsGuest) return '';

  if (isSubscriptionExpired({ subscription: organization?.subscription, plan })) {
    const planTitle = getTitleForPlanLevel(plan.level);
    return htmlEscaped`Your ${planTitle} plan has expired`;
  }
  if (!isPlanIdLegacy(plan.id) && limits.length > 0) {
    return `You’re over your plan limit for monthly ${limits[0]}`;
  }

  if (isTrialing(organization.subscription)) {
    const days = getTrialDaysRemaining(organization.subscription);
    return match(days)
      .when(
        d => d <= 0,
        () => 'Your trial has expired',
      )
      .with(1, () => '<strong>1 day left</strong> in your free trial.')
      .otherwise(count => `<strong>${count} days left</strong> in your free trial.`);
  }

  return '';
};

type UpgradeButtonVisibleProps = Partial<{
  selectedOrganization: Organization;
  selectedOrganizationPlan: Plan;
  limits: string[];
}>;

export const upgradeButtonVisible = ({
  selectedOrganizationPlan,
  selectedOrganization,
  limits = [],
}: UpgradeButtonVisibleProps): boolean => {
  if (!selectedOrganizationPlan || !selectedOrganization) return true;

  const limitIsReached = !isPlanIdLegacy(selectedOrganizationPlan.id) && limits.length > 0;

  return (
    isSubscriptionExpired({ subscription: selectedOrganization.subscription, plan: selectedOrganizationPlan }) ||
    isPlanFree(selectedOrganizationPlan) ||
    limitIsReached ||
    (!selectedOrganization.cardDefined && isTrialing(selectedOrganization.subscription))
  );
};
