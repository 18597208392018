import {
  DateFieldValue,
  FileFieldValue,
  MultiChoiceItemValueStatus,
  MultiOptionFieldValue,
  SelectFormFieldConfig,
} from '@process-street/subgrade/process';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import { TableFormFieldConfig } from '@process-street/subgrade/process/configs/table-form-field-config';
import { TableFormFieldUtils } from '@process-street/subgrade/process/widget-utils/table-form-field-utils';
import { DateFieldValueContext, MembersFieldValueContext } from 'features/activities/models/field-value-context';
import { DateFieldUtils } from '@process-street/subgrade/util';

export namespace FormFieldStringService {
  export function convertDateToString(fieldValue: DateFieldValue, context: DateFieldValueContext) {
    if (fieldValue.value) {
      // Use the same function as the form field so the value looks the same
      return DateFieldUtils.formatDateFieldValueToLongWeekdayShortMonth(fieldValue, {
        organizationTimeZone: context.organizationTimeZone,
      });
    } else {
      return '';
    }
  }
  export function convertFileToString(fieldValue: FileFieldValue): string {
    return fieldValue.name;
  }
  export function convertMembersToString(context: MembersFieldValueContext): string {
    return context.username ?? '';
  }
  export function convertMultiChoiceToString(fieldValue: MultiOptionFieldValue, config: SelectFormFieldConfig): string {
    const lookup = new Map(config.items.map(item => [item.id, item.name]));
    return fieldValue.itemValues
      .filter(v => v.status == MultiChoiceItemValueStatus.Selected)
      .flatMap(v => lookup.get(v.id) ?? [] /* so flatMap removes it */)
      .join(', ');
  }
  export function convertTableToString(
    fieldValue: TableFieldValue.FieldValue,
    config: TableFormFieldConfig.Config,
  ): string {
    return TableFormFieldUtils.toCsv(config, fieldValue, true /* includeHeader */);
  }
}
