import * as React from 'react';

import { FormControl, FormLabel, Icon, Tooltip, HStack, Textarea, useToast, Spinner } from 'components/design/next';
import { Template } from '@process-street/subgrade/process';
import { GetTemplateQuery, useUpdateTemplateMutation } from 'features/template/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useQueryClient } from 'react-query';

export type FormDescriptionProps = {
  template: Template;
};

export const FormDescription: React.FC<React.PropsWithChildren<FormDescriptionProps>> = ({ template }) => {
  const [description, setDescription] = React.useState(template.description);
  const toast = useToast();
  const queryClient = useQueryClient();

  const updateTemplateMutation = useUpdateTemplateMutation({
    onSuccess: async template => {
      await queryClient.invalidateQueries(GetTemplateQuery.getKey({ templateId: template.id }));
    },
    onError: () => {
      setDescription(template.description);

      toast({
        status: 'error',
        title: `We're having problems updating the form description`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const handleDescriptionChange: React.ChangeEventHandler<HTMLTextAreaElement> = event => {
    const updatedDescription = event.target.value;
    setDescription(updatedDescription);
  };

  const saveDescription = () => {
    if (description !== template.description) {
      updateTemplateMutation.mutateAsync({
        templateId: template.id,
        description,
      });
    }
  };

  return (
    <FormControl>
      <HStack spacing="2" alignItems="center" mb="1">
        <FormLabel m="0">Description</FormLabel>
        <Tooltip label="Description will only be visible on embedded forms" shouldWrapChildren hasArrow>
          <Icon icon="info-circle" size="4" variant="far" color="gray.400" />
        </Tooltip>
        {updateTemplateMutation.isLoading && <Spinner size="sm" />}
      </HStack>

      <Textarea
        rows={3}
        bgColor="white"
        placeholder="Describe what your form is about (optional)"
        value={description}
        onChange={handleDescriptionChange}
        onBlur={saveDescription}
      />
    </FormControl>
  );
};
