import React from 'react';
import { FilteredItem } from './use-columns-filter-state';

type Args<I extends FilteredItem> = {
  items: I[];
  getSortedItems: (items: I[]) => I[];
};

// We use sorted items to keep the selected items at the top of the list.
// The previousFoo pattern is to support search result changes
export const useSortedItems = <I extends FilteredItem>({ items, getSortedItems }: Args<I>) => {
  const sortedItemsState = React.useState(getSortedItems(items));
  const [_sortedItems, setSortedItems] = sortedItemsState;
  const [previousItems, setPreviousItems] = React.useState(items);
  if (previousItems !== items) {
    setPreviousItems(items);
    setSortedItems(getSortedItems(items));
  }
  return sortedItemsState;
};
