import angular from 'angular';
import templateUrl from './template-form-field-widget.component.html';
import './template-form-field-widget.scss';
import { WidgetValidation } from '@process-street/subgrade/process/widget-validation';
import { EventName } from 'services/event-name';
import { FormFieldEvent } from 'services/form-field-event';

angular.module('frontStreetApp.directives').component('psTemplateFormFieldWidget', {
  bindings: {
    widget: '<',
    templateRevision: '<',
    editable: '<',
    onUpdateWidget: '&',
  },
  templateUrl,
  controller($scope, $timeout, SessionService) {
    const ctrl = this;

    ctrl.$onInit = function () {
      ctrl.showAddItemText = ctrl.widget.fieldType === 'MultiSelect' ? 'Add subtask' : 'Add option';
      const user = SessionService.getUser();
      ctrl.userIsDeveloper = user && user.developer;
      $scope.isAutoSendEmailEnabled = false;
    };

    ctrl.showAddItemButtonFieldTypes = () => {
      const hasLinkedDataset = ctrl.widget.fieldType === 'Select' && !!ctrl.widget.config?.linkedDataSetId;
      const isValidFieldType =
        !hasLinkedDataset && ['MultiSelect', 'MultiChoice', 'Select'].includes(ctrl.widget.fieldType);
      return ctrl.editable && isValidFieldType;
    };

    ctrl.widgetHasConstraints = widget => WidgetValidation.hasConstraints(widget);

    ctrl.addSubtask = () => {
      $scope.$broadcast(FormFieldEvent.SUB_CHECKLIST_ADD_ITEM_BUTTON_CLICKED);
    };

    $scope.$on(EventName.AUTO_SEND_RICH_EMAIL_TOGGLE, (_, event) => {
      if (event.widgetId === ctrl.widget?.id) {
        $timeout(() => ($scope.isAutoSendEmailEnabled = event.value));
      }
    });
  },
});
