import { Muid } from '@process-street/subgrade/core';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { useStateParam } from 'hooks/use-state-param';
import { useOutputWidgetAiTasksMap } from 'features/native-automations/hooks/use-output-widget-ai-tasks-map';
import { useChecklistRevisionNativeAutomationStatus } from './use-checklist-revision-native-automation-status';

export const useAiTaskOutputWidgetStatus = (widgetId: Muid) => {
  const checklistId = useStateParam({ key: 'id' });
  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({ checklistId });
  const templateRevisionId = checklistRevisionQuery.data?.templateRevision.id;
  const widgetAiTaskMap = useOutputWidgetAiTasksMap({ templateRevisionId });
  // TODO figure out how to find the right native automation if multiple exist
  const [{ nativeAutomation }] = widgetAiTaskMap[widgetId] ?? [{}];
  const { status } = useChecklistRevisionNativeAutomationStatus({ nativeAutomation });
  return { status };
};
