import * as React from 'react';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { Box, MenuItem, MenuItemProps, VStack } from '@chakra-ui/react';
import { Icon } from 'app/components/design/next';
import { TaskTemplatesByTemplateRevisionIdQuery } from 'app/features/task-templates/query-builder';
import { useQueryClient } from 'react-query';

export type StopTaskMenuItemProps = Omit<MenuItemProps, 'onClick'> & {
  taskTemplate: TaskTemplate;
  templateRevision: TemplateRevision;
  onClick: (taskTemplateId: TaskTemplate['id'], taskTemplate: Partial<TaskTemplate>) => void;
};

export const StopTaskMenuItem = React.forwardRef(
  (
    { taskTemplate, templateRevision, onClick, ...menuItemProps }: StopTaskMenuItemProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const queryClient = useQueryClient();

    const handleAddStopClick = () => {
      const overrides: Partial<TaskTemplate> = {
        stop: !taskTemplate.stop,
        name: taskTemplate.name,
      };

      onClick(taskTemplate.id, overrides);

      queryClient.setQueryData<Array<TaskTemplate> | undefined>(
        TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId: templateRevision.id }),
        current => {
          return (current ?? []).map(tt => {
            if (tt.id === taskTemplate.id) {
              return {
                ...tt,
                ...overrides,
              };
            } else {
              return tt;
            }
          });
        },
      );
    };

    return (
      <MenuItem
        ref={ref}
        fontSize="md"
        icon={
          taskTemplate.stop ? (
            <VStack position="relative" w="3.5" h="3.5">
              <Icon icon="hand" size="3.5" />
              <Box position="absolute" top="0" left="0">
                <Icon icon="slash" size="3.5" />
              </Box>
            </VStack>
          ) : (
            <Icon icon={'hand-paper'} size="3.5" />
          )
        }
        onClick={handleAddStopClick}
        color="gray.600"
        {...menuItemProps}
      >
        {taskTemplate.stop ? 'Remove Stop' : 'Add Stop'}
      </MenuItem>
    );
  },
);
