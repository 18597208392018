import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UploadUrlTemplateEmailAttachmentMutationParams = {
  templateId: Template['id'];
  fileName: string;
  mimeType: string;
};

export type UploadUrlTemplateEmailAttachmentMutationResponse = { url: string; key: string };

export const UploadUrlTemplateEmailAttachmentMutation = {
  key: ['email-attachment', 'template-upload-url'],
  mutationFn: (params: UploadUrlTemplateEmailAttachmentMutationParams) =>
    axiosService
      .getAxios()
      .post<UploadUrlTemplateEmailAttachmentMutationResponse>(`/1/email-attachments/template-upload-url`, params)
      .then(res => res.data),
};

export const useUploadUrlTemplateEmailAttachmentMutation = (
  options: UseMutationOptions<
    UploadUrlTemplateEmailAttachmentMutationResponse,
    AxiosError,
    UploadUrlTemplateEmailAttachmentMutationParams
  > = {},
) => {
  return useMutation(UploadUrlTemplateEmailAttachmentMutation.mutationFn, options);
};
