import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { RunWorkflowButton } from '.';

export const RunWorkflowButtonModule = angular
  .module('runWorkflowButton', [])
  .component(
    'psRunWorkflowButton',
    reactToAngular(RunWorkflowButton, ['label', 'isVisible', 'onClick', 'isLoading', 'inline']),
  ).name;
