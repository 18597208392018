import * as React from 'react';
import { Divider, Grid, Text, VStack, HStack, Button, GridItem, useToast } from 'components/design/next';
import { OrganizationMembershipRole, SsoLoginType } from '@process-street/subgrade/core';
import { match } from 'ts-pattern';
import {
  useGetOrganizationAuthConnectionsQuery,
  useGetOrganizationQuery,
  useUpdateOrganizationDefaultSsoMembershipLevelMutation,
} from 'features/organization/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { Icon } from 'components/design/next';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { components } from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { IntercomService } from 'services/interop/intercom-service';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { AnalyticsService } from 'components/analytics/analytics.service';

export const SsoSettings = () => {
  const toast = useToast();

  const [defaultMembershipLevel, setDefaultMembershipLevel] = React.useState<OrganizationMembershipRole | null>(null);
  const currentOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const authConnectionsQuery = useGetOrganizationAuthConnectionsQuery({
    organizationId: currentOrganization?.id,
  });

  useGetOrganizationQuery(
    { organizationId: currentOrganization?.id },
    {
      onSuccess: organization => {
        if (!defaultMembershipLevel) setDefaultMembershipLevel(organization.defaultSsoOrganizationMembershipLevel);
      },
    },
  );

  const updateOrganizationDefaultSsoMembershipLevel = useUpdateOrganizationDefaultSsoMembershipLevelMutation({
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems updating the default user type",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const ssoEnabled = authConnectionsQuery.data && authConnectionsQuery.data.length > 0;

  const showSupport = () => {
    AnalyticsService.trackEvent('support link clicked', { location: 'organization settings tab' });
    // eslint-disable-next-line no-console
    console.info('support link clicked');
    IntercomService.show();
  };

  if (!ssoEnabled) return null;

  const requiredSsoLoginType = authConnectionsQuery.data[0].ssoLogin;

  return (
    <VStack spacing="6" alignItems="flex-start" w="full">
      <Text color="gray.500" variant="-2u">
        SSO
      </Text>
      <Divider />

      {match(requiredSsoLoginType)
        .with(SsoLoginType.Optional, () => (
          <VStack spacing="4" alignItems="stretch">
            <HStack spacing="2">
              <Icon icon="check-circle" variant="fas" color="green.500" size="4" />
              <Text>Optional SSO is enabled</Text>
            </HStack>

            <Text>
              Single sign-on (SSO) is optional for users to sign-in with.{' '}
              <Button variant="link" colorScheme="brand" onClick={showSupport}>
                Contact support
              </Button>{' '}
              to require users to sign-in with SSO.
            </Text>

            <Text>
              Choose a <strong>User Type</strong> below to be assigned to all new users created through Just-In-Time
              provisioning.
            </Text>
          </VStack>
        ))
        .otherwise(() => null)}

      <Grid w="full" mt="8" rowGap="6" templateColumns={{ base: '1fr', md: '220px 1fr' }}>
        <GridItem alignSelf="center">
          <Text htmlFor="organizationMembershipLevel" color="gray.600" as="label">
            Default User Type
          </Text>
        </GridItem>

        <GridItem mt={{ base: '-4', md: '0' }}>
          <BlvdSelect
            id="organizationMembershipLevel"
            name="organizationMembershipLevel"
            value={
              defaultMembershipLevel ? USER_TYPE_OPTIONS.find(ut => ut.value === defaultMembershipLevel) : undefined
            }
            onChange={value => {
              if (BlvdSelectHelpers.isOptionType<{ value: OrganizationMembershipRole }>(value)) {
                setDefaultMembershipLevel(value.value);

                if (!currentOrganization?.id) throw new Error('Organization id is missing.');
                updateOrganizationDefaultSsoMembershipLevel.mutate({
                  organizationId: currentOrganization?.id,
                  level: value.value,
                });
              }
            }}
            options={USER_TYPE_OPTIONS}
            components={{
              Option: props => {
                return (
                  <components.Option {...props}>
                    <VStack spacing="0.5" alignItems="stretch">
                      <Text fontWeight="semibold">{props.label}</Text>
                      <Text fontSize="sm">{props.data.description}</Text>
                    </VStack>
                  </components.Option>
                );
              },
            }}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
};

const USER_TYPE_OPTIONS = [
  {
    label: 'Member',
    value: OrganizationMembershipRole.FullMember,
    description: 'Regular user - can be given access to folders, workflows and workflow runs',
  },
  {
    label: 'Guest (External)',
    value: OrganizationMembershipRole.Guest,
    description: "Limited access - can only see what's directly assigned to them",
  },
];
