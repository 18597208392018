import { checklistDashboardModuleReducer } from 'components/dashboard/store/checklist-dashboard.reducers';
import { fileUploadModuleReducer } from 'components/file-upload/store/file-upload.reducers';
import { checklistMigrationModuleReducer } from 'components/migration/store/checklist-migration.reducers';
import { templateMigrationReducers } from 'components/migration/store/template-migration.reducer';
import { locationReducer } from 'reducers/location/location.reducer';
import { navigationModuleReducer } from 'reducers/navigation/navigation.reducer';
import { combineReducers, Reducer } from 'redux';
import { ModulesState } from '../types';
import { featureFlagReducer } from 'services/features/feature-flags/store/feature-flags.reducer';
import { formFieldValueModuleReducers } from 'components/form-field-value/store/form-field-value.reducer';

export const modulesReducer: Reducer<ModulesState> = combineReducers({
  checklistDashboard: checklistDashboardModuleReducer,
  checklistMigration: checklistMigrationModuleReducer,
  featureFlag: featureFlagReducer,
  fileUpload: fileUploadModuleReducer,
  location: locationReducer,
  navigation: navigationModuleReducer,
  templateMigration: templateMigrationReducers,
  formFieldValue: formFieldValueModuleReducers,
});
