import { ColDef, ColGroupDef, Column, IsFullWidthRowParams } from '@ag-grid-community/core';
import {
  DataSetCellValue,
  DataSetColumnState,
  DataSetColumnStateModel,
  DataSetRow,
  SavedView,
  SavedViewFilterOperator,
} from '@process-street/subgrade/process';
import deepEqual from 'deep-equal';
import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import _isNull from 'lodash/isNull';
import _isEmpty from 'lodash/isEmpty';
import { DataSetFiltersSlice } from 'pages/reports/data-sets/store';
import omit from 'lodash/omit';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';

export namespace DataSetUtils {
  export const hasUnsavedChanges = (savedView: SavedView, dataSetFilters: DataSetFiltersSlice) => {
    // Remove sorting related keys for comparison
    const omitSortingAndWidthKeys = (column: Partial<DataSetColumnState>) =>
      _omitBy(column, (_, k) => k === 'sort' || k === 'sortIndex' || k === 'width');

    // Compare columns without sorting keys
    const columnsEqualWithoutSorting = deepEqual(
      savedView.columns.map(omitSortingAndWidthKeys),
      dataSetFilters.columns.map(column =>
        omitSortingAndWidthKeys(_omitBy(column, v => _isUndefined(v) || _isNull(v))),
      ),
    );

    // Compare original columns including sorting keys
    const columnsEqual = deepEqual(
      savedView.columns,
      // We must remove the keys with undefined values to correctly compare the two values
      dataSetFilters.columns.map(column => _omitBy(column, v => _isUndefined(v) || _isNull(v))),
    );

    const isNoOperatorSet =
      dataSetFilters.operator === SavedViewFilterOperator.And && !savedView.config?.filterOperator;
    const isOperatorEqual = dataSetFilters.operator === savedView.config?.filterOperator || isNoOperatorSet;

    const sanitizedDataSetFilters = dataSetFilters.filters.map(filter => ({
      ...omit(filter, 'id'),
      // Since value is optional, we need to filter out undefined values in order for deepEqual to work correctly
      filterParams: _omitBy(filter.filterParams, v => _isUndefined(v) || _isNull(v)),
    }));

    const savedViewFiltersWithoutId = DataSetColumnStateModel.getFilters(savedView.columns).map(filter =>
      omit(filter, 'id'),
    );

    const isEqual = columnsEqual && deepEqual(savedViewFiltersWithoutId, sanitizedDataSetFilters) && isOperatorEqual;

    const shouldShowWarning =
      !columnsEqualWithoutSorting || !deepEqual(savedViewFiltersWithoutId, sanitizedDataSetFilters) || !isOperatorEqual;

    const hasUnsavedChanges = !isEqual;

    return {
      hasUnsavedChanges,
      shouldShowWarning,
    };
  };

  export type FullWidthDataSetRow = {
    isFullWidth: boolean;
  };

  export const isFullWidthRow = (params: Pick<IsFullWidthRowParams, 'rowNode'>): boolean => {
    return params.rowNode.data.isFullWidth;
  };

  export const appendFullWidthRow = (rows: (DataSetRow | DataSetUtils.FullWidthDataSetRow)[]) => {
    const fullWidthRow = {
      isFullWidth: true,
    };
    return rows.concat(fullWidthRow);
  };

  export const ROW_CONTEXT_MENU_ID = 'row-context-menu';
  export const LAST_COLUMN_ID = 'last-column-menu';
  export const NON_DATA_COLUMN_IDS = [ROW_CONTEXT_MENU_ID, LAST_COLUMN_ID];

  export const isColDef = (column: ColGroupDef | ColDef): column is ColDef => {
    return (column as ColDef).colId !== undefined;
  };

  export const isDataColDef = (column: ColDef) => {
    return !NON_DATA_COLUMN_IDS.includes(column?.field ?? '');
  };

  export const isDataColumn = (column: Column) => {
    const id = column.getId();
    return !NON_DATA_COLUMN_IDS.includes(id);
  };
  export const isRowEmpty = (row: DataSetRow) => _isEmpty(row.cells);

  export const parseDataSetValue = (value: DataSetCellValue['value']): string => {
    if (value === null) return '';
    if (typeof value === 'number' && DateUtils.isUnixTimestamp(value)) {
      const date = new Date(value);
      return date.getTime() > 0 ? DateUtils.formatDateTime(date, DateFormat.DefaultDateOnly) : value.toString();
    }
    return value.toString();
  };
}
