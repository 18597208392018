import { Muid } from '@process-street/subgrade/core';
import { ChecklistRevision } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type ChecklistRevisionQueryParams = { checklistRevisionId: Muid };

export type ChecklistRevisionQueryResponse = ChecklistRevision;

export const ChecklistRevisionQuery = {
  key: ['checklist-revisions'],
  getKey: (params: ChecklistRevisionQueryParams): QueryKey => [...ChecklistRevisionQuery.key, params],
  queryFn: ({ checklistRevisionId }: ChecklistRevisionQueryParams) =>
    axiosService
      .getAxios()
      .get<ChecklistRevisionQueryResponse>(`/1/checklist-revisions/${checklistRevisionId}`)
      .then(res => res.data),
};

export const useChecklistRevisionQuery = <Select = ChecklistRevisionQueryResponse>(
  params: ChecklistRevisionQueryParams,
  options: UseQueryOptions<ChecklistRevisionQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(ChecklistRevisionQuery.getKey(params), () => ChecklistRevisionQuery.queryFn(params), options);
};
