import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import {
  PaywallAdminBodyDescription,
  PaywallAdminHeaderIcon,
  PaywallAdminIconChecklist,
  PaywallBodyAdminHeading,
  PaywallBodyDescription,
  PaywallBodyDescriptionEmphasis,
  PaywallBodyHeading,
  PaywallBodyNoIcon,
  PaywallBodyWithIcon,
  PaywallButtonGroup,
  PaywallDivider,
  PaywallFeatures,
  PaywallFooter,
  PaywallGoBack,
  PaywallHeader,
  PaywallHeaderWithIcon,
  PaywallIconChecklist,
  PaywallModal,
  PaywallPrimaryActionLinkButton,
  PaywallSecondaryActionButton,
} from 'components/paywalls/common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { OrganizationSelector } from 'reducers/organization/organization.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useAdminEmails } from '../use-admin-emails';
import { mailto as buildMailToLink } from '@process-street/subgrade/process';
import { useGoBack } from './use-go-back';
import { useFeatureSet } from 'components/paywalls/use-feature-set';
import { useRoleNames } from 'hooks/use-role-names';
import { IntercomService } from 'services/interop/intercom-service';
import { AnalyticsService } from 'components/analytics/analytics.service';

export const FreeMember: React.FC<React.PropsWithChildren<unknown>> = () => {
  const orgId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const { name: orgName } = useSelector(OrganizationSelector.getById(orgId)) ?? {};
  const currentUser = useSelector(SessionSelector.getCurrentUser);
  const roleNames = useRoleNames();
  const [adminEmail, ...bcc] = useAdminEmails();

  const mailto = buildMailToLink({
    to: adminEmail,
    subject: `Someone on your team is requesting an upgrade on your Process Street plan`,
    body: `A ${roleNames.FreeMember.single} on your Process Street account${
      currentUser?.username ? `, ${currentUser.username}, ` : ''
    } would like you to upgrade them to a ${roleNames.FullMember.single}. ${
      roleNames.FullMember.plural
    } can create and run workflows.\n\nUpgrade here: https://app.process.st/organizations/manage/billing`,
    bcc,
  });
  const goBack = useGoBack();

  return (
    <PaywallModal>
      <PaywallHeader>
        <PaywallGoBack onClick={goBack} />
      </PaywallHeader>

      <PaywallBodyWithIcon>
        <PaywallIconChecklist />

        <PaywallBodyHeading>Ask your {orgName} admin for access</PaywallBodyHeading>

        <PaywallBodyDescription>Want to run workflows?</PaywallBodyDescription>
        <PaywallBodyDescription>Ask your Process Street admin to upgrade your account.</PaywallBodyDescription>
      </PaywallBodyWithIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallPrimaryActionLinkButton href={mailto}>Send Request</PaywallPrimaryActionLinkButton>
      </PaywallFooter>
    </PaywallModal>
  );
};

export const FullMember: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { activeChecklistsLimit } = useFeatureSet();

  const orgId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const { name: orgName } = useSelector(OrganizationSelector.getById(orgId)) ?? {};
  const [adminEmail, ...bcc] = useAdminEmails();
  const mailto = buildMailToLink({
    to: adminEmail,
    subject: `Someone on your team is requesting an upgrade on your Process Street plan`,
    body: `Help your team create and run more workflows in Process Street.\n\nUpgrade here: https://app.process.st/organizations/manage/billing`,
    bcc,
  });
  const goBack = useGoBack();

  return (
    <PaywallModal>
      <PaywallHeader>
        <PaywallGoBack onClick={goBack} />
      </PaywallHeader>

      <PaywallBodyWithIcon>
        <PaywallIconChecklist />

        <PaywallBodyHeading>{orgName} has reached workflow run limit</PaywallBodyHeading>

        <PaywallBodyDescription>
          Your Free plan allows a maximum of{' '}
          {activeChecklistsLimit === 1 ? (
            <PaywallBodyDescriptionEmphasis>1 workflow run.</PaywallBodyDescriptionEmphasis>
          ) : (
            <PaywallBodyDescriptionEmphasis>{activeChecklistsLimit} workflow runs.</PaywallBodyDescriptionEmphasis>
          )}
        </PaywallBodyDescription>
        <PaywallBodyDescription>Contact your administrator to have your plan upgraded.</PaywallBodyDescription>
      </PaywallBodyWithIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallPrimaryActionLinkButton href={mailto}>Send Request</PaywallPrimaryActionLinkButton>
      </PaywallFooter>
    </PaywallModal>
  );
};

export const Admin: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { activeChecklistsLimit } = useFeatureSet();
  const orgId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';
  const { name: orgName } = useSelector(OrganizationSelector.getById(orgId)) ?? {};

  const contactSales = React.useCallback(() => {
    AnalyticsService.trackEvent('contact us link clicked', { location: 'use checklist' });
    IntercomService.show();
  }, []);

  const goBack = useGoBack();

  return (
    <PaywallModal>
      <PaywallHeaderWithIcon bgColor="brand.100">
        <PaywallGoBack onClick={goBack} />

        <PaywallAdminHeaderIcon>
          <PaywallAdminIconChecklist />
        </PaywallAdminHeaderIcon>
      </PaywallHeaderWithIcon>

      <PaywallBodyNoIcon>
        <PaywallBodyAdminHeading>{orgName} has reached workflow run limit</PaywallBodyAdminHeading>
        {activeChecklistsLimit === 1 ? (
          <PaywallAdminBodyDescription>
            Your organization is running the <PaywallBodyDescriptionEmphasis>1</PaywallBodyDescriptionEmphasis> workflow
            that comes with your free plan.
          </PaywallAdminBodyDescription>
        ) : (
          <PaywallAdminBodyDescription>
            Your organization is running the{' '}
            <PaywallBodyDescriptionEmphasis>{activeChecklistsLimit}</PaywallBodyDescriptionEmphasis> workflows that come
            with your free plan.
          </PaywallAdminBodyDescription>
        )}
        <PaywallAdminBodyDescription>
          Run unlimited <PaywallBodyDescriptionEmphasis>workflows</PaywallBodyDescriptionEmphasis> by upgrading{' '}
          {orgName} to Pro.
        </PaywallAdminBodyDescription>
        <PaywallFeatures />
      </PaywallBodyNoIcon>

      <PaywallDivider />

      <PaywallFooter>
        <PaywallButtonGroup>
          <PaywallSecondaryActionButton onClick={contactSales} className="intercom-bot-contact-sales">
            Contact Sales
          </PaywallSecondaryActionButton>

          <PaywallPrimaryActionLinkButton href="/organizations/manage/billing">
            Upgrade Now
          </PaywallPrimaryActionLinkButton>
        </PaywallButtonGroup>
      </PaywallFooter>
    </PaywallModal>
  );
};

export const UIs = {
  [OrganizationMembershipRole.FreeMember]: FreeMember,
  [OrganizationMembershipRole.FullMember]: FullMember,
  [OrganizationMembershipRole.Admin]: Admin,
  [OrganizationMembershipRole.Guest]: () => null,
};
