import angular from 'angular';
import { ChecklistStatus } from '@process-street/subgrade/process';
import templateUrl from './confetti.component.html';
import './confetti.scss';
import { ConfettiThemeColorsMap, determineThemeByDate } from './confetti-theme';
import { ChecklistEvent } from 'services/checklists/checklist-event';

angular.module('frontStreetApp.directives').component('psConfetti', {
  bindings: {
    theme: '<',
  },
  templateUrl,
  controller($scope, $state, $timeout, $window) {
    const ctrl = this;

    ctrl.canvas = undefined;
    ctrl.ctx = undefined;
    ctrl.W = 0;
    ctrl.H = 0;
    ctrl.mp = 300; // max particles
    ctrl.particles = [];
    ctrl.angle = 0;
    ctrl.tiltAngle = 0;
    ctrl.confettiActive = true;
    ctrl.animationComplete = true;
    ctrl.reactivationTimerHandler = undefined;
    ctrl.animationHandler = undefined;

    ctrl.$onInit = function () {
      setGlobals();
      initializeConfetti();

      $scope.$on(ChecklistEvent.UPDATE_STARTED, (__event, data) => {
        data.isOptimistic && ctrl.dropConfettiIfStatusCompleted(data.updatedChecklist);
      });

      $scope.$on(ChecklistEvent.UPDATE_OK, (__event, data) => {
        !data.isOptimistic && ctrl.dropConfettiIfStatusCompleted(data.updatedChecklist);
      });
    };

    ctrl.$onChanges = () => {
      if ($state.includes('developer') && ctrl.theme) {
        ctrl.particleColors.colorOptions = ConfettiThemeColorsMap[ctrl.theme];
        ctrl.dropConfetti();
      }
    };

    ctrl.dropConfettiIfStatusCompleted = checklist => {
      const checklistIsCompleted = checklist.status === ChecklistStatus.Completed;
      const isChecklistOrSandbox = $state.includes('checklist') || $state.includes('sandboxChecklist');
      if (isChecklistOrSandbox && checklistIsCompleted) {
        ctrl.dropConfetti();
      }
    };

    ctrl.dropConfetti = function () {
      restartConfetti();
      $timeout(() => {
        deactivateConfetti();
      }, 2000);
    };

    ctrl.particleColors = {
      colorOptions: ConfettiThemeColorsMap[determineThemeByDate(new Date())],
      colorIndex: 0,
      colorIncrementer: 0,
      colorThreshold: 10,
      getColor() {
        if (this.colorIncrementer >= 10) {
          this.colorIncrementer = 0;
          this.colorIndex += 1;
          if (this.colorIndex >= this.colorOptions.length) {
            this.colorIndex = 0;
          }
        }
        this.colorIncrementer += 1;
        return this.colorOptions[this.colorIndex];
      },
    };

    function ConfettiParticle(color) {
      this.x = Math.random() * ctrl.W; // x-coordinate
      this.y = Math.random() * ctrl.H - ctrl.H; //y-coordinate
      this.r = randomFromTo(10, 30); //radius;
      this.d = Math.random() * ctrl.mp + 10; //density;
      this.color = color;
      this.tilt = Math.floor(Math.random() * 10) - 10;
      this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
      this.tiltAngle = 0;

      this.draw = function () {
        ctrl.ctx.beginPath();
        ctrl.ctx.lineWidth = this.r / 2;
        ctrl.ctx.strokeStyle = this.color;
        ctrl.ctx.moveTo(this.x + this.tilt + this.r / 4, this.y);
        ctrl.ctx.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 4);
        return ctrl.ctx.stroke();
      };
    }

    function setGlobals() {
      [ctrl.canvas] = angular.element($window.document.querySelector('.confetti'));
      ctrl.ctx = ctrl.canvas.getContext('2d');
      ctrl.W = $window.innerWidth;
      ctrl.H = $window.innerHeight;
      ctrl.canvas.width = ctrl.W;
      ctrl.canvas.height = ctrl.H;
    }

    function initializeConfetti() {
      ctrl.particles = [];
      ctrl.animationComplete = false;
      for (let i = 0; i < ctrl.mp; i++) {
        const particleColor = ctrl.particleColors.getColor();
        ctrl.particles.push(new ConfettiParticle(particleColor));
      }
    }

    function draw() {
      ctrl.ctx.clearRect(0, 0, ctrl.W, ctrl.H);
      const results = [];
      for (let i = 0; i < ctrl.mp; i++) {
        (function (j) {
          results.push(ctrl.particles[j].draw());
        })(i);
      }
      update();

      return results;
    }

    function randomFromTo(from, to) {
      return Math.floor(Math.random() * (to - from + 1) + from);
    }

    function update() {
      let remainingFlakes = 0;
      let particle;
      ctrl.angle += 0.01;
      ctrl.tiltAngle += 0.1;

      for (let i = 0; i < ctrl.mp; i++) {
        particle = ctrl.particles[i];
        if (ctrl.animationComplete) {
          return;
        }

        if (!ctrl.confettiActive && particle.y < -15) {
          particle.y = ctrl.H + 100;
        } else {
          stepParticle(particle, i);
          if (particle.y <= ctrl.H) {
            remainingFlakes += 1;
          }
          checkForReposition(particle, i);
        }
      }

      if (remainingFlakes === 0) {
        stopConfetti();
      }
    }

    function checkForReposition(particle, index) {
      if ((particle.x > ctrl.W + 20 || particle.x < -20 || particle.y > ctrl.H) && ctrl.confettiActive) {
        if (index % 5 > 0 || index % 2 === 0) {
          //66.67% of the flakes
          repositionParticle(particle, Math.random() * ctrl.W, -10, Math.floor(Math.random() * 10) - 10);
        } else if (Math.sin(ctrl.angle) > 0) {
          //Enter from the left
          repositionParticle(particle, -5, Math.random() * ctrl.H, Math.floor(Math.random() * 10) - 10);
        } else {
          //Enter from the right
          repositionParticle(particle, ctrl.W + 5, Math.random() * ctrl.H, Math.floor(Math.random() * 10) - 10);
        }
      }
    }

    function stepParticle(particle, particleIndex) {
      particle.tiltAngle += particle.tiltAngleIncremental;
      particle.y += (Math.cos(ctrl.angle + particle.d) + 3 + particle.r / 2) / 2;
      particle.x += Math.sin(ctrl.angle);
      particle.tilt = Math.sin(particle.tiltAngle - particleIndex / 3) * 15;
    }

    function repositionParticle(particle, xCoordinate, yCoordinate, tilt) {
      particle.x = xCoordinate;
      particle.y = yCoordinate;
      particle.tilt = tilt;
    }

    function startConfetti() {
      ctrl.W = $window.innerWidth;
      ctrl.H = $window.innerHeight;
      ctrl.canvas.width = ctrl.W;
      ctrl.canvas.height = ctrl.H;
      (function animloop() {
        if (ctrl.animationComplete) {
          return null;
        }
        ctrl.animationHandler = $window.requestAnimFrame(animloop);
        return draw();
      })();
    }

    function clearTimers() {
      clearTimeout(ctrl.reactivationTimerHandler);
      clearTimeout(ctrl.animationHandler);
    }

    function deactivateConfetti() {
      ctrl.confettiActive = false;
      clearTimers();
    }

    function stopConfetti() {
      ctrl.animationComplete = true;
      if (ctrl.ctx === undefined) {
        return;
      }
      ctrl.ctx.clearRect(0, 0, ctrl.W, ctrl.H);
    }

    function restartConfetti() {
      clearTimers();
      stopConfetti();
      ctrl.reactivationTimerHandler = $timeout(() => {
        ctrl.confettiActive = true;
        ctrl.animationComplete = false;
        initializeConfetti();
        startConfetti();
      }, 100);
    }

    $window.requestAnimFrame = (function () {
      return (
        $window.requestAnimationFrame ||
        $window.webkitRequestAnimationFrame ||
        $window.mozRequestAnimationFrame ||
        $window.oRequestAnimationFrame ||
        $window.msRequestAnimationFrame ||
        function (callback) {
          return $timeout(callback, 1000 / 60);
        }
      );
    })();
  },
});
