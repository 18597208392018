import { connectController } from 'reducers/util';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { DynamicDueDatesSelector } from '../store/dynamic-due-dates.selectors';
import templateUrl from './template-button.component.html';
import './template-button.scss';
import { DynamicDueDateLabelService } from 'components/dynamic-due-dates/services/dynamic-due-date-label.service';
import { TaskListEvent } from 'directives/task-list/task-list-event';

export const TemplateButtonComponent = {
  bindings: {
    selectedTaskTemplate: '<',
    templateRevision: '<',
    user: '<',
    disabled: '<',
    setHasDynamicDueDate: '&',
  },
  templateUrl,
  controller: class {
    constructor($ngRedux, $rootScope, $q, $scope, DynamicDueDateService) {
      'ngInject';

      this.$scope = $scope;
      this.$q = $q;
      this.$rootScope = $rootScope;

      this.DynamicDueDateLabelService = DynamicDueDateLabelService;
      this.DynamicDueDateService = DynamicDueDateService;

      this.dateWidgets = [];

      const mapStateToThis = () => state => {
        const templateRevisionId = this.templateRevision && this.templateRevision.id;

        const taskRules = templateRevisionId
          ? DynamicDueDatesSelector.getAllByTemplateRevisionId(templateRevisionId)(state)
          : [];

        const dateWidgets = templateRevisionId
          ? DynamicDueDatesSelector.getDateWidgetsByTemplateRevisionId(templateRevisionId)(state)
          : [];

        const taskTemplates = templateRevisionId
          ? TaskTemplateSelector.getAllByTemplateRevisionId(templateRevisionId)(state)
          : [];

        const taskRule =
          this.selectedTaskTemplate && DynamicDueDateService.findRuleForTask(taskRules, this.selectedTaskTemplate);

        this.setHasDynamicDueDate({ hasDynamicDueDate: Boolean(taskRule) });

        const label = this.DynamicDueDateLabelService.getFullLabelByRule(taskRule, taskTemplates, dateWidgets);

        const btnClass = this.disabled ? 'btn-transparent-info' : 'btn-outline-info';

        return {
          btnClass,
          dateWidgets,
          label,
          taskRule,
          taskTemplates,
        };
      };

      const shouldChange = change =>
        (change.selectedTaskTemplate && change.selectedTaskTemplate.currentValue) ||
        (change.templateRevision && change.templateRevision.currentValue);

      connectController($ngRedux, mapStateToThis, null, shouldChange)(this);
    }

    saveDueRule(data) {
      const { taskRule, taskTemplates, dateWidgets } = this.state;

      this.DynamicDueDateService.saveDueRule(
        data,
        this.templateRevision,
        taskRule,
        this.selectedTaskTemplate,
        taskTemplates,
        dateWidgets,
      );

      this.$rootScope.$broadcast(TaskListEvent.HIDE_POP_BOX);
    }

    removeDueRule() {
      const templateRevisionId = this.templateRevision.id;
      const ruleId = this.state.taskRule.id;

      this.DynamicDueDateService.deleteRules(templateRevisionId, [ruleId]);
      this.$rootScope.$broadcast(TaskListEvent.HIDE_POP_BOX);
    }
  },
};
