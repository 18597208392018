import angular from 'angular';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import templateUrl from './template-view-bar.component.html';
import './template-view-bar.scss';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular.module('frontStreetApp.directives').component('psTemplateViewBar', {
  bindings: {
    selectedTab: '<',
    template: '<',
    userAnonymous: '<',
    onSwitchTab: '&',
    canUpdateTemplate: '<',
    switchTab: '&',
  },
  templateUrl,
  controller($q, $state, SessionService, OrganizationService, SecurityService, TemplateService) {
    const ctrl = this;

    ctrl.shouldShowAutomationTab = () => {
      return ctrl.canUpdateTemplate;
    };

    ctrl.shouldShowBar = () => {
      return ctrl.shouldShowControls();
    };
    ctrl.switchTab = function (tab) {
      ctrl.selectedTab = tab;
      ctrl.onSwitchTab({ tab });
    };

    ctrl.$onChanges = function (changes) {
      if (changes.template && changes.template.currentValue) {
        ctrl.template = changes.template.currentValue;
      }

      initializePermissions(ctrl.template);
    };

    ctrl.permissionMap = {};

    function initializePermissions(template) {
      if (!template) {
        return;
      }

      $q.all({
        checklistCreate: SecurityService.canCreateChecklistByTemplate(template),
      }).then(permissionMap => {
        ctrl.permissionMap = permissionMap;
      });
    }

    // Controls

    ctrl.shouldShowControls = function () {
      return ctrl.userAnonymous === true;
    };

    ctrl.shouldAllowCreateChecklist = function () {
      return ctrl.permissionMap.checklistCreate && ctrl.template.shareLevel === TemplateService.ShareLevel.RUN;
    };

    ctrl.addChecklist = function () {
      const { id: templateId } = ctrl.template;
      AnalyticsService.trackEvent('run checklist clicked', { location: 'template view bar' });

      const runUrlOptions = {
        to: $state.current,
        params: {
          ...$state.params,
          [AppModalQueryParam.Modal]: AppModalName.RunChecklist,
          [AppModalQueryParam.ModalTemplateId]: templateId,
        },
        options: { inherit: false },
      };

      $state.go(runUrlOptions.to, runUrlOptions.params, runUrlOptions.options);
    };
  },
});
