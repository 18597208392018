import angular from 'angular';
import { htmlEscaped } from '@process-street/subgrade/util';
import { HttpStatus } from '@process-street/subgrade/util';
import { UserType } from '@process-street/subgrade/core';
import { connectService } from 'reducers/util';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.services')
  .service(
    'UserService',
    function ($ngRedux, $q, DataService, GroupMembershipService, SessionService, UserActions, ToastService) {
      'ngInject';

      connectService('UserService', $ngRedux, null, UserActions)(this);

      const self = this;
      const logger = trace({ name: 'UserService' });

      /**
       * @deprecated The user should be resolved specifically in the controller and passed through. See inbox-ctrl.js
       * @return {*}
       */
      self.getCurrentUser = () => SessionService.getUser();

      self.updateCurrentUserSettings = (key, value) => {
        const user = SessionService.getUser();
        if (user && user.userType === UserType.Standard) {
          logger.info(`updating user's (${user.id}) setting ${key} to`, value);

          return this.actions.updateSettings(user.id, key, value).then(({ payload }) => payload);
        } else {
          return $q.resolve({});
        }
      };

      self.getCoverUser = users => {
        const lower = str => (str || '').toLowerCase();

        const sortedUsers = users.slice().sort((a, b) => lower(a.username).localeCompare(lower(b.username)));

        return sortedUsers.length > 0 ? sortedUsers[0] : null;
      };

      /**
       * Gets a single user object
       *
       * @param id
       *
       * @returns {Promise}
       */
      self.getById = function (id) {
        return this.actions.getById(id).then(({ payload }) => payload);
      };

      /**
       * Gets the user object identified in the token.
       *
       * @returns {Promise}
       */
      self.getByToken = function () {
        return this.actions.getByToken().then(({ payload }) => payload);
      };

      self.completeLogin = () => this.actions.completeLogin().then(({ payload }) => payload);

      self.update = (userId, info) => this.actions.update(userId, info).then(({ payload }) => payload);

      self.updateUsingToken = token => this.actions.updateUsingToken(token).then(({ payload }) => payload);

      self.sendEmailVerification = (userId, email) =>
        this.actions.sendEmailVerification(userId, email).then(({ payload }) => payload);

      self.requestPasswordReset = email => this.actions.requestPasswordReset(email).then(({ payload }) => payload);

      self.resetPassword = (resetId, newPassword, confirmNewPassword) =>
        this.actions.resetPassword(resetId, newPassword, confirmNewPassword).then(({ payload }) => payload);

      /**
       * Fetches user settings for given user id
       *
       * @param userId
       * @return {Promise}
       */
      self.getSettingsById = function (userId) {
        return this.actions.getSettingsById(userId).then(({ payload }) => payload);
      };

      /**
       * Fetches user emails for given user id
       *
       * @param userId
       * @return {Promise}
       */
      self.getAllEmailsById = function (userId) {
        return this.actions.getAllEmailsById(userId).then(({ payload }) => payload);
      };

      self.validateEmailVerification = codeId =>
        this.actions.validateEmailVerification(codeId).then(({ payload }) => payload);

      /**
       * Adds a new email address to the user
       *
       * @param userId
       * @param email
       * @param password
       */
      self.createUserEmail = function (userId, email, password) {
        return this.actions.createUserEmail(userId, email, password).then(
          ({ payload: userEmail }) => {
            ToastService.openToast({
              status: 'success',
              title: `Email address added`,
            });

            return userEmail;
          },
          response => {
            switch (response.status) {
              case HttpStatus.CONFLICT:
                ToastService.openToast({
                  status: 'warning',
                  title: `We couldn't add the email address`,
                  description: 'The email address already exists.',
                });
                break;
              case HttpStatus.FORBIDDEN:
                ToastService.openToast({
                  status: 'warning',
                  title: `We couldn't add the email address`,
                  description: 'The provided password is incorrect.',
                });
                break;
              default:
                ToastService.openToast({
                  status: 'error',
                  title: `We're having problems adding the email address`,
                  description: DefaultErrorMessages.unexpectedErrorDescription,
                });
            }

            return $q.reject(response);
          },
        );
      };

      /**
       * Deletes an additional email address from the user
       *
       * @param userId
       * @param email
       */
      self.deleteUserEmail = function (userId, email) {
        return this.actions.deleteUserEmail(userId, email).then(
          ({ payload: deletedUserEmail }) => {
            ToastService.openToast({
              status: 'success',
              title: `Email address removed`,
            });

            return deletedUserEmail;
          },
          response => {
            switch (response.status) {
              case HttpStatus.CONFLICT:
                ToastService.openToast({
                  status: 'warning',
                  title: `We couldn't delete the email address`,
                  description: `You can't delete primary email.`,
                });
                break;
              default:
                ToastService.openToast({
                  status: 'error',
                  title: `We're having problems deleting the email address`,
                  description: DefaultErrorMessages.unexpectedErrorDescription,
                });
            }

            return $q.reject(response);
          },
        );
      };

      self.setPrimaryEmail = function (userId, email) {
        return this.actions.setPrimaryEmail(userId, email).then(
          ({ payload: primaryUserEmail }) => {
            ToastService.openToast(htmlEscaped`Primary email changed to: <b>${primaryUserEmail.email}</b>.`);

            return primaryUserEmail;
          },
          response => {
            switch (response.status) {
              case HttpStatus.CONFLICT:
                ToastService.openToast({
                  status: 'error',
                  title: `We couldn't update your primary email address`,
                  description: 'The email address does not exist.',
                });
                break;
              default:
                ToastService.openToast({
                  status: 'error',
                  title: `We're having problems updating your primary email address`,
                  description: DefaultErrorMessages.unexpectedErrorDescription,
                });
            }

            return $q.reject(response);
          },
        );
      };

      /**
       * Gets the label for a user.
       *
       * @param user
       * @returns {*}
       */
      self.getLabel = function (user) {
        let name;
        if (isGroupUser(user)) {
          name = user.username;
        } else if (user.username && user.email) {
          name = `${user.username} (${user.email})`;
        } else {
          name = user.email;
        }
        return htmlEscaped`${name}`;
      };

      self.getUserLabelByMembership = membership => {
        if (membership && isGroupUser(membership.user)) {
          return membership.user.username;
        } else {
          return self.getLabel(membership.user);
        }
      };

      self.getOrganizationMemberships = function (userId, organizationId, excludeGroupMemberships) {
        const organizationMembershipCollection = DataService.getCollection('organizationMemberships');

        const userMembership = organizationMembershipCollection.find({
          'organization.id': organizationId,
          'user.id': userId,
        });

        if (!userMembership) {
          return [];
        }

        if (excludeGroupMemberships) {
          return [userMembership];
        } else {
          const groupMemberships = GroupMembershipService.filter({ 'organizationMembership.id': userMembership.id }, [
            'group.user',
          ])
            .filter(groupMembership => !!groupMembership.group.user)
            .map(groupMembership =>
              organizationMembershipCollection.find({
                'organization.id': organizationId,
                'user.id': groupMembership.group.user.id,
              }),
            );

          return [userMembership].concat(groupMemberships);
        }
      };

      self.validateSessionUser = function () {
        let promise;

        const sessionUser = SessionService.getUser();
        if (sessionUser) {
          promise = self
            .getByToken()
            .then(tokenUser => sessionUser.id === tokenUser.id)
            .catch(response => {
              if (response.status === HttpStatus.NOT_FOUND) {
                return $q.resolve(false);
              } else {
                return $q.reject(response);
              }
            });
        } else {
          // We consider the session user to be valid if it's not set
          promise = $q.resolve(true);
        }

        return promise;
      };

      self.getUserLinkableDomains = organizationId =>
        this.actions.getUserLinkableDomains(organizationId).then(({ payload }) => payload);
    },
  );
