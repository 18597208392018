import { TaskPermissionRule, TaskPermissionRuleSourceType } from '@process-street/subgrade/permission';
import { FormFieldWidget, TaskTemplate, TemplateRevision, WidgetGroup } from '@process-street/subgrade/process';
import { getRuleLabel, resolveRuleIcon } from '@process-street/subgrade/util/task-permission-rule-utils';
import { Box, HStack, Icon, Text } from 'components/design/next';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import * as React from 'react';
import { RulePermissionSelector } from './rule-permission-selector';

export interface RulePermissionItemProps {
  rule: TaskPermissionRule;
  taskTemplates: TaskTemplate[];
  widgets: FormFieldWidget[];
  templateRevisionId: TemplateRevision['id'];
  onRemoveRule: (sourceType: TaskPermissionRuleSourceType, sourceFormFieldWidgetGroup?: WidgetGroup) => void;
}

export const RulePermissionItem: React.FC<React.PropsWithChildren<RulePermissionItemProps>> = ({
  rule,
  taskTemplates,
  widgets,
  templateRevisionId,
  onRemoveRule,
}) => {
  const avatarIcon = resolveRuleIcon(rule, widgets) as CustomIconName;
  const label = getRuleLabel(rule, widgets);

  const handleRemove = () => {
    onRemoveRule(rule.sourceType, rule.sourceFormFieldWidgetGroup);
  };
  return (
    <HStack justifyContent="space-between" w="100%">
      <HStack>
        <Box
          bg="gray.300"
          border="1px solid"
          borderColor="white"
          borderRadius="50%"
          color="white"
          display="inline-block"
          h="8"
          textAlign="center"
          w="8"
        >
          <Icon variant="far" size="4" icon={avatarIcon} pt="1.5" />
        </Box>
        <Box flex={1} role="cell">
          <Text variant="-1" fontWeight="medium" color="gray.600" noOfLines={1}>
            {label}
          </Text>

          <Text variant="-2" color="gray.500" noOfLines={1}>
            {label}
          </Text>
        </Box>
      </HStack>
      <Box pb="5">
        <HStack>
          <RulePermissionSelector rule={rule} taskTemplates={taskTemplates} templateRevisionId={templateRevisionId} />
          <Icon
            icon="user-times"
            variant="far"
            size="4"
            color="gray.400"
            _hover={{ cursor: 'pointer' }}
            onClick={handleRemove}
          />
        </HStack>
      </Box>
    </HStack>
  );
};
