import { AnalyticsConstants } from '@process-street/subgrade/analytics/analytics-constants';
import { AnalyticsService } from 'components/analytics/analytics.service';

export class CsvExportModalService {
  constructor($uibModal) {
    'ngInject';

    this.$uibModal = $uibModal;
  }

  exportToCsvModal(template) {
    this.$uibModal.open({
      component: 'ps-csv-export-modal',
      animation: true,
      backdrop: true,
      size: 'sm',
      windowClass: 'csv-export-modal',
      resolve: {
        template: () => template,
      },
    });
  }

  trackExportEvent(template) {
    AnalyticsService.trackEvent(AnalyticsConstants.Event.CHECKLISTS_EXPORTED_AS_CSV, {
      'template id': template.id,
      'template name': template.name,
    });
  }
}
