import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { isStandardUserOm } from '@process-street/subgrade/util/membership-utils';
import { useSelector } from 'react-redux';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { createSelector } from 'reselect';
import { features } from 'services/features/features';
import { membersByUsernameComparator } from 'services/user-utils';

export const useUserListQuery = () => {
  return useSelector(
    createSelector(
      [
        OrganizationMembershipSelector.getAllWithUserBySelectedOrganizationId,
        OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId,
        SessionSelector.getCurrentPlan,
      ],
      (allMemberships, currentOrganizationMembership, plan) => {
        const sortedOrganizationMemberships = allMemberships.filter(isStandardUserOm).sort(membersByUsernameComparator);

        // We might not have loaded organization memberships, so we can't rely on that
        const userIsAdmin = currentOrganizationMembership?.role === OrganizationMembershipRole.Admin;
        const freemium = !!plan && features.isFreemiumTrack(plan.id);

        return {
          data: {
            memberships: sortedOrganizationMemberships,
            userIsAdmin,
            freemium,
          },
        };
      },
    ),
  );
};
