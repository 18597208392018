import { UserType } from '@process-street/subgrade/core';
import { abbreviateForTitle } from '@process-street/subgrade/util';
import angular from 'angular';
import { SessionSelector } from 'reducers/session/session.selectors';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import templateUrl from './share.component.html';
import './template.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { TemplateViewUtils } from 'features/template/view-utils';

const lazyLoadTinymce = () => import('directives/template-widgets/tinymce-lazy-load');

angular
  .module('frontStreetApp.controllers')
  .controller(
    'TemplateViewCtrl',
    function (
      $anchorScroll,
      $ngRedux,
      $q,
      $rootScope,
      $scope,
      $state,
      $timeout,
      $window,
      AnonymousAuthService,
      FeatureFlagService,
      FeatureGateService,
      GrowSumoService,
      MessageBox,
      OrganizationService,
      SecurityService,
      SessionService,
      TaskTemplateListMenuEvent,
      TemplateRevisionService,
      TemplateService,
      ToastService,
      UserSettingsService,
    ) {
      const ctrl = this;

      const mapStateToScope = state => {
        const featureFlags = FeatureFlagSelector.getFeatureFlags(state);
        const isUserGuestOfSelectedOrganization = SessionSelector.isUserGuestOfSelectedOrganization(state);
        return {
          featureFlags,
          isUserGuestOfSelectedOrganization,
        };
      };

      const unsubscribe = $ngRedux.connect(mapStateToScope)($scope);

      const logger = trace({ name: 'TemplateCtrl' });
      logger.info('loading ctrl');

      // Keep this an undefined and not false, or else the one-time binding doesn't work correctly
      $scope.initialized = undefined;

      $scope.$watch('featureFlags', (newFeatureFlags, oldFeatureFlags) => {
        if (oldFeatureFlags?.isInitialized === false && newFeatureFlags?.isInitialized === true) {
          initialize();
        }
      });

      ctrl.$onInit = async () => {
        try {
          $scope.user = await AnonymousAuthService.templateViewAnonymousAuth($state.params.id);
        } catch (error) {
          $scope.message = error.message;
        }

        if ($scope.featureFlags?.isInitialized) {
          initialize();
        }
      };

      async function initialize() {
        if ($scope.featureFlags.workflowViewModeV2) {
          $state.go('templateViewV2', $state.params);

          return;
        }

        // We only load tinyMCE v4 in case the new version of the viewer is not enabled.
        // The new viewer uses TinyMCE V6, so if we load v4 before, it will brake the
        // text content component.
        await lazyLoadTinymce();

        $scope.taskTemplateGroupId = $state.params.groupId;

        if (
          $scope.taskTemplateGroupId ||
          SessionService.getTemplateEditorProperty(`template:${$state.params.id}:activeStep`)
        ) {
          showWidgets(true);
        }

        initTemplateData().then(() => {
          initOrganization();

          $scope.initialized = true;

          $scope._trackTemplateView($scope.templateRevision);
        });

        showViewForState($state.current, $state.params);
      }

      $scope.$on(EventName.TEMPLATE_SIDEBAR_TOGGLED, (__event, sidebarHidden) => {
        SessionService.setTemplateEditorProperty('sidebarHidden', sidebarHidden);
      });

      $scope.$on(EventName.TEMPLATE_MOVED, (__event, __movedTemplate, updatedTemplate) => {
        $scope.templateRevision.template = updatedTemplate;
      });

      function initTemplateData() {
        logger.info('initializing template data');

        $scope.templateId = $state.params.id;

        return TemplateRevisionService.getAllNewestByTemplateId($scope.templateId).then(
          revisions => {
            const [finishedRevision] = revisions;

            if (finishedRevision) {
              $scope.templateRevision = finishedRevision;

              const { template } = finishedRevision;
              if (template.organization.id !== SecurityService.getSelectedOrganizationIdByUser($scope.user)) {
                OrganizationService.switchById(template.organization.id);
                return;
              }
              if (TemplateService.isPageByTemplateType(template.templateType)) {
                const title = abbreviateForTitle(template.name);
                if (title && title.length) {
                  $state.go($state.current, { id: template.id, title: `${title}-` });
                }

                return;
              }
              if (TemplateService.isTaskByTemplateType(template.templateType)) {
                $state.go('dashboard');

                return;
              }

              // Set page title on initialization
              updatePageTitle(finishedRevision);

              initializePermissions(template);

              initializeViewer($scope.templateRevision);
            } else {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems loading the workflow`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
              $state.go('dashboard');
            }
          },
          response => {
            TemplateViewUtils.showWorkflowViewErrorToast(response.status, ToastService.openToast.bind(ToastService));
          },
        );
      }

      function initOrganization() {
        if ($scope.user.userType === UserType.Anonymous) {
          OrganizationService.getById($scope.templateRevision.template.organization.id).then(organization => {
            GrowSumoService.setPartnerKeyCookie(organization.growSumoPartnerKey);
          });
        }
      }

      $scope.$on('$stateChangeSuccess', (event, toState, toParams) => {
        showViewForState(toState, toParams);
        event.preventDefault();
      });

      function showViewForState(state, stateParams) {
        if (state.name.split('.')[0] === 'templateView') {
          switch (state.name) {
            case 'templateView':
              // So, if we get sent to the template viewer without a specific task,
              // then we should redirect to the last tab we were on
              break;
            case 'templateView.task':
              if (stateParams.groupId) {
                $scope.taskTemplateGroupId = stateParams.groupId;
              }
              break;
            default:
              logger.error('unexpected state: %s', state.name);
          }
        }
      }

      function updatePageTitle(templateRevision) {
        const { template } = templateRevision;

        const title = template && abbreviateForTitle(template.name);
        if (title && title.length) {
          $state.go($state.current, { title: `${title}-` }, { location: 'replace' });
        }

        $window.document.title = `${templateRevision.template.name} | Process Street`;
      }

      function initializeViewer(templateRevision) {
        $scope.info = {
          title: templateRevision.template.name,
          titleEditable: false,
          description: templateRevision.template.description,
        };

        //set page title on initialization
        updatePageTitle(templateRevision);

        $scope.$watch('templateRevision.template.status', status => {
          $scope.info.message = status === 'Archived' && 'This workflow is archived.';
        });
      }

      $scope.selectTaskTemplate = function (taskTemplateGroupId, replace) {
        // Don't select template task if we're on overview
        if ($state.includes('templateView') && !$state.is('templateView.overview')) {
          if (taskTemplateGroupId) {
            $state.go('templateView.task', { groupId: taskTemplateGroupId }, { location: replace ? 'replace' : true });
          } else {
            $state.go('templateView');
          }
        }
      };

      $scope.setActiveTaskTemplate = function (taskTemplate, assignees, __canMoveUp, canMoveDown) {
        $scope.activeStep = { id: taskTemplate.group.id, name: taskTemplate.name, taskType: taskTemplate.taskType };
        $scope.activeStepTaskTemplate = taskTemplate;
        $scope.activeStepAssignees = assignees;
        $scope.activeStepHasAssignees = assignees && assignees.length > 0;
        $scope.activeStepHasDueDateOffset = taskTemplate.dueOffset;
        $scope.activeStepIsLastTask = !canMoveDown;
      };

      $scope.selectStepBelow = function (task) {
        $rootScope.$broadcast(TaskTemplateListMenuEvent.MOVE_DOWN_REQUEST_FROM_MENU, task);
      };

      $scope._trackTemplateView = templateRevision => {
        if (!templateRevision || !$scope.initialized) {
          return;
        }

        const { template } = templateRevision;

        $rootScope.$broadcast(EventName.TEMPLATE_VIEWED, { template });
      };

      // Widgets

      $scope.widgetsMap = {};

      // This method is fired by task list
      $scope.setWidgetsVisibility = function (visible, instantly) {
        if (visible) {
          showWidgets(instantly);
        } else {
          hideWidgets();
        }
      };

      $scope.toggleWidgets = function () {
        if ($scope.widgetsShown) {
          hideWidgets();
        } else {
          showWidgets();
        }
      };

      function showWidgets(instantly) {
        $scope.instantly = instantly;
        $scope.widgetsShown = true;

        $timeout(() => {
          $anchorScroll('widgets-top');
        });
      }

      function hideWidgets() {
        $scope.widgetsShown = false;
      }

      $scope.hideWidgetsAsync = () => {
        $timeout(() => {
          hideWidgets();
        });
      };

      $scope.$on('$destroy', () => {
        // Save the editor properties
        UserSettingsService.updateTemplateEditor(SessionService.getTemplateEditorProperties());

        if (!$scope.featureFlags.workflowViewModeV2) {
          AnonymousAuthService.logoutIfAuthedAnonymously();
        }

        unsubscribe();
      });

      // Permissions

      $scope.permissionMap = {};

      function initializePermissions(template) {
        return $q
          .all({
            templateShare: SecurityService.canShareTemplateByTemplate(template),
            templateUpdate: SecurityService.canUpdateTemplateByTemplate(template),
          })
          .then(permissionMap => {
            $scope.permissionMap = permissionMap;
          });
      }

      let shareTemplateShown = false;
      $scope.showShareTemplate = function (templateRevision) {
        if (shareTemplateShown) {
          return;
        }

        shareTemplateShown = true;
        MessageBox.custom({
          templateUrl,
          controller: 'TemplateShareCtrl',
          options: {
            template: templateRevision.template,
            steps: $scope.steps,
          },
        }).result.finally(() => {
          // Get the template, as it may have been updated
          TemplateService.get(templateRevision.template.id).then(template => {
            templateRevision.template = template;
          });

          shareTemplateShown = false;
        });
      };

      $scope.shouldShowFocusBar = function () {
        return $scope.user && !isAnonymousUser($scope.user);
      };

      $scope.shouldShowDueOffset = function () {
        return $scope.activeStep;
      };

      $scope.shouldShowStopTask = function () {
        return $scope.activeStepTaskTemplate.stop;
      };

      $scope.shouldShowTaskPermissions = () =>
        $scope.activeStep && FeatureGateService.isTaskPermissionsFeatureEnabled($scope.user);

      $scope.isAnonymousUser = user => isAnonymousUser(user);
    },
  );
