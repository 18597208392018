import { components, DropdownIndicatorProps } from 'react-select';
import * as React from 'react';
import { Icon } from 'components/design/next';

export function DropdownIndicator<Option, IsMulti extends boolean>(props: DropdownIndicatorProps<Option, IsMulti>) {
  const isOpen = props.selectProps.menuIsOpen;
  return (
    <components.DropdownIndicator {...props}>
      {props.children || <ChevronIcon isOpen={isOpen} />}
    </components.DropdownIndicator>
  );
}

const ChevronIcon = ({ isOpen }: { isOpen: boolean }) => {
  return <Icon icon={isOpen ? 'chevron-up' : 'chevron-down'} size="3" variant="far" />;
};
