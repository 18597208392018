import * as React from 'react';
import { Button, Icon, MenuItem } from 'components/design/next';
import { RefreshSandboxMutation } from 'features/template/query-builder';
import { ChecklistRevision } from '@process-street/subgrade/process';

export interface SandboxRefreshButtonProps {
  checklistRevisionId: ChecklistRevision['id'];
  isMobile?: boolean;
}

export const SandboxRefreshButton: React.FC<React.PropsWithChildren<SandboxRefreshButtonProps>> = ({
  checklistRevisionId,
  isMobile = false,
}) => {
  const refreshSandboxMutation = RefreshSandboxMutation.useMutation();

  const [isDisabled, setIsDisabled] = React.useState(false);

  const refreshSandbox = () => {
    refreshSandboxMutation.mutateAsync({ checklistRevisionId }).then(() => {
      setIsDisabled(true);
      window.location.reload();
    });
  };

  return isMobile ? (
    <MenuItem
      as={Button}
      fontSize="lg"
      justifyContent="flex-start"
      isDisabled={refreshSandboxMutation.isLoading || isDisabled}
      onClick={refreshSandbox}
      variant="ghost"
    >
      Refresh
    </MenuItem>
  ) : (
    <Button
      variant="outline"
      colorScheme="gray"
      leftIcon={<Icon icon="refresh" size="4" />}
      isLoading={refreshSandboxMutation.isLoading}
      isDisabled={isDisabled}
      onClick={refreshSandbox}
    >
      Refresh
    </Button>
  );
};
