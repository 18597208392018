import { TextFormFieldWidget, TEXT_MAX_LENGTH } from '@process-street/subgrade/process';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { FormFieldValueSchemaTests } from '../common/validation/form-field-value-schema-tests';
import { FormFieldWidgetSettingsSchemas } from '../common/validation/form-field-widget-settings-schema';

export type TextSettingsSchema = {
  config: TextFormFieldWidget['config'];
  constraints: TextFormFieldWidget['constraints'];
  hasVariables?: boolean;
};

export const textSettingsSchema: ObjectSchema<TextSettingsSchema> = yup
  .object({
    config: yup
      .object({
        ...FormFieldWidgetSettingsSchemas.placeholder(60),
        ...FormFieldWidgetSettingsSchemas.defaultValue(TEXT_MAX_LENGTH),
      })
      .required(),
    constraints: yup
      .object({
        ...FormFieldWidgetSettingsSchemas.characterLimits(),
      })
      .required(),
  })
  .required();

type ValidationSchemaProps = {
  required: boolean;
  constraints: TextFormFieldWidget['constraints'];
};

export const makeTextValidationSchema = ({ required, constraints }: ValidationSchemaProps) => {
  const { min, max } = constraints;

  return yup
    .string()
    .test(FormFieldValueSchemaTests.required(required))
    .test(FormFieldValueSchemaTests.characterRange(min, max))
    .test(FormFieldValueSchemaTests.lengthMin(min))
    .test(FormFieldValueSchemaTests.lengthMax(max));
};
