import { Muid } from '@process-street/subgrade/core';
import { ChecklistColumn, Filter } from '@process-street/subgrade/dashboard';

export interface TemplateDto {
  id: Muid;
  name: string;
  folderId: Muid;
}

export interface Sorting {
  sortColumn: ChecklistColumn | 'FormFieldValue';
  sortAsc: boolean;
  sortByWidgetGroupId?: Muid;
}

export interface Filtering {
  searchPattern: string;
  selectedTemplates: Muid[];
  conditionalFilter?: Filter; // tree of filters
}

export interface GetDataRequest {
  organizationId: Muid;
  offsetId?: Muid;
  pageSize: number;
  sorting?: Sorting;
  filtering?: Filtering;
}

export enum ExportCsvOption {
  ExportAll = 'ExportAll',
  ExportVisible = 'ExportVisible',
}
