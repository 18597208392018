import * as React from 'react';
import { HStack, Icon, Link, TabPanel, TabPanelProps, Text, VStack } from 'components/design/next';

import { TemplateRevision } from '@process-street/subgrade/process';

import { OnChangeValue } from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';

import { RunLink } from './run-link';
import { PermissionsSwitch } from './permissions-switch';
import { LinkVariablesSelect } from './link-variables-select';

export type RunViaLinkTabContentProps = { templateRevision: TemplateRevision; hideLabel?: boolean };
export type RunViaLinkTabPanelProps = RunViaLinkTabContentProps & TabPanelProps;

export type SelectedVariables = string[];
export type SelectedVariableOption = { value: string; label: string };

export const RunViaLinkTabContent: React.FC<React.PropsWithChildren<RunViaLinkTabContentProps>> = ({
  templateRevision,
  hideLabel,
}) => {
  const [selectedVariables, setSelectedVariables] = React.useState<SelectedVariables | null>(null);
  const templateId = templateRevision?.template?.id;

  const handleChange = (value: OnChangeValue<SelectedVariableOption, true>) => {
    if (BlvdSelectHelpers.isOptionsType<SelectedVariableOption>(value)) {
      setSelectedVariables(value.map(v => v.value));
    }
  };

  return (
    <VStack alignItems="start" spacing="2">
      {!hideLabel && (
        <HStack spacing="2">
          <Icon variant="far" icon="run-link" size="4" color="gray.600" />
          <Text variant="1" color="gray.600" fontWeight="medium">
            Run Link
          </Text>
        </HStack>
      )}

      <RunLink {...{ templateId, selectedVariables }} />

      <Text variant="-1" color="gray.600">
        Anyone clicking this link will run a workflow. Try embedding it in other apps.{' '}
        <Link target="_blank" href="https://www.process.st/help/docs/workflow-run-link/" color="brand.500">
          Learn more »
        </Link>
      </Text>
      <PermissionsSwitch {...{ templateRevision }} />
      <LinkVariablesSelect {...{ templateRevision, handleChange }} />
    </VStack>
  );
};

export const RunViaLinkTabPanel: React.FC<React.PropsWithChildren<RunViaLinkTabPanelProps>> = ({
  templateRevision,
  ...props
}) => {
  return (
    <TabPanel p="6" {...props}>
      <RunViaLinkTabContent templateRevision={templateRevision} />
    </TabPanel>
  );
};
