import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';
import { TaskTemplate } from '@process-street/subgrade/process';
import { Dictionary } from 'lodash';

type Context = {
  highLightedTasksMap: Dictionary<TaskTemplate> | undefined;
  setHighlightedTasksMap: React.Dispatch<React.SetStateAction<Dictionary<TaskTemplate> | undefined>>;
};

export const [useHighlightedTasksContext, HighlightedTasksContext] = createUsableContext<Context>({
  hookName: 'useHighlightedTasksContext',
  providerName: 'HighlightedTasksContext',
});

export const HighlightedTasksContextProvider: React.FunctionComponent<React.PropsWithChildren<Context>> = ({
  children,
  highLightedTasksMap,
  setHighlightedTasksMap,
}) => {
  const value = React.useMemo(
    () => ({ highLightedTasksMap, setHighlightedTasksMap }),
    [highLightedTasksMap, setHighlightedTasksMap],
  );
  return <HighlightedTasksContext.Provider value={value}>{children}</HighlightedTasksContext.Provider>;
};
