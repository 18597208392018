import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Divider,
  Heading,
  List,
  Text,
  useColorModeValue as mode,
} from 'components/design/next';
import * as React from 'react';
import { LevelToPlanInfoMap, PlanCostQuantity, PlanStates } from 'pages/organizations/manage/billing/models/models';
import { PricingDetail } from './PricingDetail';
import { PlanDetailsSection } from './PlanDetailsSection';
import { DetailsButton } from 'pages/organizations/manage/billing/components/pricing-card/DetailsButton';
import { PlanLevel } from '@process-street/subgrade/billing';
import { PlanLevelSelectorState } from 'directives/billing/plan-level-selector/plan-level-selector-service.interface';

interface PricingCardProps extends BoxProps {
  colorScheme: string;
  detailsExpanded: boolean;
  level: PlanLevel;
  onPlanSelect: (level: PlanLevel) => void;
  onToggleDetails: () => void;
  planCostQuantity: PlanCostQuantity;
  planStates: PlanStates;
  price: string;
  selected: boolean;
}

export const PricingCard = (props: PricingCardProps) => {
  const {
    colorScheme: c,
    detailsExpanded,
    level,
    onPlanSelect,
    onToggleDetails,
    planCostQuantity,
    planStates,
    price,
    selected,
    ...rest
  } = props;

  const {
    features,
    name,
    description: Description,
    duration: Duration,
    featuresHeader,
    planDetails,
  } = LevelToPlanInfoMap[level]!; // safe as we have all freemium levels in this map (but not other levels)

  const handleClick = () => onPlanSelect(level);

  const actionText = planStates[level]!;
  const disabled = selected || actionText === PlanLevelSelectorState.CURRENT_PLAN;
  const intercomClass = actionText === PlanLevelSelectorState.CONTACT_US ? 'intercom-bot-enterprise' : undefined;

  return (
    <Box p={{ base: '10', md: '12' }} bgColor="gray.50" {...rest}>
      <Heading textTransform="none">{name}</Heading>
      <Divider opacity={1} borderWidth="2px" borderColor={mode(`${c}.500`, `${c}.200`)} my="6" w="56px" />

      <Text maxW="280px" fontSize="md" color="gray.600">
        <Description />
      </Text>

      <Box mt="2">
        <Text fontSize="5xl" color="gray.700" mb={1} mt={8}>
          {price}
        </Text>
        <Text casing="uppercase" fontSize="xs" color="gray.600" fontWeight="bold">
          <Duration planCostQuantity={planCostQuantity} />
        </Text>
      </Box>

      <Button
        my="8"
        size="lg"
        fontSize="md"
        variant="solid"
        colorScheme={c}
        onClick={handleClick}
        isDisabled={disabled}
        className={intercomClass}
      >
        {actionText}
      </Button>

      <Box>
        <Text fontWeight="bold" mb="4">
          {featuresHeader}
        </Text>
        <List spacing="4">
          {features.map((feature, index) => (
            <PricingDetail key={index} iconColor={`${c}.500`}>
              {feature}
            </PricingDetail>
          ))}
        </List>
      </Box>

      {!!planDetails && (
        <Box>
          <Collapse in={detailsExpanded} animateOpacity>
            {planDetails.map((section, index) => (
              <PlanDetailsSection key={index} section={section} colorSchema={c} />
            ))}

            <Button
              mt="8"
              size="lg"
              fontSize="md"
              variant="solid"
              colorScheme={c}
              onClick={handleClick}
              isDisabled={disabled}
              className={intercomClass}
            >
              {actionText}
            </Button>
          </Collapse>

          <DetailsButton expanded={detailsExpanded} onToggleDetails={onToggleDetails} />
        </Box>
      )}
    </Box>
  );
};
