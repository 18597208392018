import angular from 'angular';
import templateUrl from './item.component.html';
import './item.scss';

angular.module('frontStreetApp.directives').component('psTaskTemplateMultiSelectMenuItem', {
  bindings: {
    action: '<',
    icon: '<',
    onClick: '&',
  },
  templateUrl,
  controller() {},
});
