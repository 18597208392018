import { useEffect, useState } from 'react';
import { QueryObserverResult } from 'react-query';

type UseRulesLoadingParams = {
  query: QueryObserverResult;
  isModalOpen: boolean;
};

// This hook checks if the rules query was loading before the modal was opened,
// this will be helpful to show the loading state only when the modal opens but not
// when it's saving the conditional logic changes.
export const useIsLoadingRules = ({ query, isModalOpen }: UseRulesLoadingParams) => {
  const [modalLastOpenedAt, setModalLastOpenedAt] = useState(Infinity);
  const [startedLoadingRulesAt, setStartedLoadingRulesAt] = useState(Infinity);

  const rulesStartedLoadingBeforeModalWasOpen = startedLoadingRulesAt <= modalLastOpenedAt;
  const rulesWereLoadingWhenModalOpened = rulesStartedLoadingBeforeModalWasOpen && query.isFetching;

  useEffect(() => {
    if (isModalOpen) {
      setModalLastOpenedAt(Date.now());
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (query.isFetching) setStartedLoadingRulesAt(Date.now());
  }, [query.isFetching]);

  return query.isLoading || rulesWereLoadingWhenModalOpened;
};
