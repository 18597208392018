import { PlanLevel, PlanLevelUtil, PlanTrack } from '../billing';

export type TrayAutomationAppConfig<Name extends string = string> = {
  name: Name;
  brandColor: string;
  label: string;
  minimumPlan: PlanLevel;
  tag: string;
};

function trayAutomationAppConfigsCIF<Name extends string, Configs extends TrayAutomationAppConfig<Name>[]>(
  configs: Configs,
): Configs {
  return configs;
}

// ALL AUTOMATION APP INFORMATION GOES HERE
/*
https://docs.google.com/spreadsheets/d/1fUbZoKxZ0PTyKHrrrzBmbgDKwEtG9PSv_RMVuFqbHlY/edit#gid=1802617012
Plan info

              F B S E
Process St    ✔ ✔ ✔ ✔
Slack         ✔ ✔ ✔ ✔
Google Sheets ✔ ✔ ✔ ✔
Salesforce    𐄂 𐄂 ✔ ✔
DocuSign      𐄂 𐄂 ✔ ✔
Jira          𐄂 𐄂 ✔ ✔
TypeForm      𐄂 𐄂 ✔ ✔
*/
export const TrayAutomationApps = trayAutomationAppConfigsCIF([
  {
    name: 'Salesforce',
    tag: 'ps_provider_salesforce',
    brandColor: '#00A1E1',
    label: 'Salesforce',
    minimumPlan: PlanLevel.Standard,
  },
  {
    name: 'ProcessStreet',
    tag: 'ps_provider_process_street',
    brandColor: 'var(--ps-colors-blue-400)',
    label: 'Process Street',
    minimumPlan: PlanLevel.Free,
  },
  {
    name: 'Jira',
    tag: 'ps_provider_jira',
    brandColor: '#2584FF',
    label: 'Jira',
    minimumPlan: PlanLevel.Standard,
  },
  {
    name: 'Slack',
    tag: 'ps_provider_slack',
    brandColor: '#611f69',
    label: 'Slack',
    minimumPlan: PlanLevel.Free,
  },
  {
    name: 'DocuSign',
    tag: 'ps_provider_docusign',
    brandColor: '#000000',
    label: 'DocuSign',
    minimumPlan: PlanLevel.Standard,
  },
  {
    name: 'TypeForm',
    tag: 'ps_provider_typeform',
    brandColor: '',
    label: 'TypeForm',
    minimumPlan: PlanLevel.Standard,
  },
  {
    name: 'GoogleSheets',
    tag: 'ps_provider_google_sheets',
    brandColor: '#34a853',
    label: 'Google Sheets',
    minimumPlan: PlanLevel.Free,
  },
  {
    name: 'Greenhouse',
    tag: 'ps_provider_greenhouse',
    brandColor: '#22822e',
    label: 'Greenhouse',
    minimumPlan: PlanLevel.Free,
  },
  {
    name: 'Zapier',
    tag: 'ps_zapier',
    brandColor: '#FF4A00',
    label: 'Zapier',
    minimumPlan: PlanLevel.Free,
  },
  {
    name: 'PowerAutomate',
    tag: 'ps_power_automate',
    brandColor: '#FF4A00',
    label: 'Power Automate',
    minimumPlan: PlanLevel.Free,
  },
  {
    name: 'Make',
    tag: 'ps_make',
    brandColor: '#FF4A00',
    label: 'Make',
    minimumPlan: PlanLevel.Free,
  },
]);

export const UnsafeTrayAutomationAppNames = TrayAutomationApps.map(a => a.name);
export type UnsafeTrayAutomationAppName = typeof UnsafeTrayAutomationAppNames[number];

export type TrayPrefix = 'tray';
export type WithTrayPrefix<AppName extends UnsafeTrayAutomationAppName> = `${TrayPrefix}:${AppName}`;
export function withTrayPrefix<AppName extends UnsafeTrayAutomationAppName>(appName: AppName): WithTrayPrefix<AppName> {
  return `tray:${appName}`;
}

export const TrayAutomationAppNames = UnsafeTrayAutomationAppNames.map(withTrayPrefix);
export type TrayAutomationAppName = typeof TrayAutomationAppNames[number];

export type TrayAutomationAppDetail = typeof TrayAutomationApps[number];

const TrayAutomationAppLookup = TrayAutomationApps.reduce((acc, app) => {
  const safeName = withTrayPrefix(app.name);
  acc[safeName] = app;
  return acc;
}, {} as Record<TrayAutomationAppName, TrayAutomationAppDetail>);

export const getTrayAutomationAppDetail = (automationApp: TrayAutomationAppName): TrayAutomationAppDetail => {
  return TrayAutomationAppLookup[automationApp];
};

export const trayAutomationAppIsPremium = (automationApp: TrayAutomationAppName, planTrack: PlanTrack): boolean => {
  return (
    planTrack !== PlanTrack.Freemium &&
    PlanLevelUtil.rank(getTrayAutomationAppDetail(automationApp).minimumPlan) >= PlanLevelUtil.rank(PlanLevel.Standard)
  );
};

export const canUseTrayAutomationApp = ({
  automationApp,
  planLevel,
  planTrack,
}: {
  automationApp: TrayAutomationAppName;
  planLevel: PlanLevel;
  planTrack: PlanTrack;
}): boolean => {
  return (
    planTrack === PlanTrack.Freemium ||
    PlanLevelUtil.rank(planLevel) >= PlanLevelUtil.rank(getTrayAutomationAppDetail(automationApp).minimumPlan)
  );
};
