import { DataSetRow } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type DeleteDataSetColumnMutationParams = {
  dataSetId: string;
  columnId: string;
};

type DeleteDataSetMutationResponse = DataSetRow;

export const DeleteDataSetColumnMutation = {
  mutationFn: (params: DeleteDataSetColumnMutationParams) =>
    axiosService
      .getAxios()
      .delete<DeleteDataSetMutationResponse>(`/1/data-sets/${params.dataSetId}/columns/${params.columnId}`)
      .then(res => res.data),
};

export const useDeleteDataSetColumnMutation = (
  options: UseMutationOptions<DeleteDataSetMutationResponse, AxiosError, DeleteDataSetColumnMutationParams> = {},
) => {
  return useMutation(DeleteDataSetColumnMutation.mutationFn, options);
};
