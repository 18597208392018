import { Box, HStack, Text, useDisclosure, useOutsideClick } from '@chakra-ui/react';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { BlvdSelect, BlvdSelectProps } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { trace } from 'components/trace';
import { NodeType, TimeSource } from 'directives/rules/template/task-templates-selector/selector-helper';
import { useLazyMenuOptions } from 'features/conditional-logic/hooks/useLazyMenuOptions';
import { useFeatureFlags } from 'features/feature-flags';
import keyBy from 'lodash/keyBy';
import * as React from 'react';
import { components, GroupBase, OnChangeValue, SelectComponentsConfig } from 'react-select';
import { useTemplateTypeContext } from 'utils/template/template-type-context';
import { match } from 'ts-pattern';

export type FormFieldOrTaskOption = {
  label: string;
  value: string;
  icon: string;
  type: NodeType;
};

export type FormFieldSelectProps = Omit<BlvdSelectProps<FormFieldOrTaskOption>, 'onChange'> & {
  onChange: (widget: TaskTemplate | Widget | TimeSource | null) => void;
  widgets: Widget[];
  taskTemplates: TaskTemplate[];
};

export const FormFieldSelect: React.VFC<FormFieldSelectProps> = ({
  widgets,
  taskTemplates,
  onChange,
  options,
  ...props
}) => {
  const featureFlags = useFeatureFlags();
  const [widgetsMap, setWidgetsMap] = React.useState<Record<string, Widget> | null>(null);
  const [taskTemplatesMap, setTaskTemplatesMap] = React.useState<Record<string, TaskTemplate> | null>(null);
  const { handleMenuOpen, value: lazyOptions } = useLazyMenuOptions({
    getValue: () => options,
    defaultValue: [],
  });

  const templateType = useTemplateTypeContext();

  const logger = trace({ name: 'FormFieldSelect' });

  const handleChange = (value: OnChangeValue<FormFieldOrTaskOption, false>) => {
    // Defer the calculation of the widgetsMap to reduce the initial render time.
    const currentWidgetsMap = widgetsMap ? widgetsMap : keyBy(widgets, w => w.id);
    const currentTaskTemplatesMap = taskTemplatesMap ? taskTemplatesMap : keyBy(taskTemplates, tt => tt.id);

    if (!widgetsMap) setWidgetsMap(currentWidgetsMap);
    if (!taskTemplatesMap) setTaskTemplatesMap(currentTaskTemplatesMap);

    if (BlvdSelectHelpers.isOptionType<{ value: string; label: string }>(value)) {
      const selectedValue = match(value.type)
        .with(NodeType.Widget, () => currentWidgetsMap[value.value])
        .with(NodeType.Task, () => currentTaskTemplatesMap[value.value])
        .with(NodeType.TimeSource, () => value.value as TimeSource)
        .otherwise(() => null);

      onChange(selectedValue);
      disclosure.onClose();
    } else {
      logger.info('option type not supported.');
    }
  };

  const placeholder = featureFlags.taskCondition
    ? templateType.isForm
      ? 'Select step or form field…'
      : 'Select a task, form field or date…'
    : 'Select a form field…';
  const containerRef = React.useRef<HTMLDivElement>(null);
  const disclosure = useDisclosure();
  useOutsideClick({
    ref: containerRef,
    handler: () => disclosure.onClose(),
  });

  return (
    <Box ref={containerRef}>
      <BlvdSelect
        options={lazyOptions}
        onChange={handleChange}
        isMulti={false}
        isSearchable
        placeholder={placeholder}
        enableSearchAutoFocus
        isOptionDisabled={option => option.type === NodeType.Heading}
        onMenuOpen={() => {
          disclosure.onToggle();
          handleMenuOpen();
        }}
        onMenuClose={() => disclosure.onClose()}
        components={featureFlags.taskCondition ? COMPONENTS : undefined}
        menuIsOpen={disclosure.isOpen}
        {...props}
      />
    </Box>
  );
};

const COMPONENTS: SelectComponentsConfig<FormFieldOrTaskOption, false, GroupBase<FormFieldOrTaskOption>> = {
  Option: props => {
    const option = props.data as FormFieldOrTaskOption;

    return (
      <components.Option {...props}>
        <HStack pl={option.type === NodeType.Widget ? 6 : 2} opacity={props.isDisabled ? 0.6 : 1}>
          {option.icon && <Box as="span" className={`fa ${option.icon}`} />}

          <Text noOfLines={1} fontSize="sm">
            {option.label}
          </Text>
        </HStack>
      </components.Option>
    );
  },
};
