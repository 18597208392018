import * as React from 'react';
import { ButtonProps } from 'components/design/next';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { TemplateType } from '@process-street/subgrade/process';
import { useIsMutating } from 'react-query';
import { match } from 'ts-pattern';
import { DeleteTemplateMutation } from 'features/template/mutation-builder';
import { useTemplateMenuContext, useTemplateMenuDisclosureContext } from '../template-menu';

export interface DeleteTemplateButtonProps {
  children: React.ReactElement | ((props: { isMutating: boolean }) => React.ReactElement<ButtonProps>);
}

export const DeleteTemplateButton: React.FC<DeleteTemplateButtonProps> = React.memo(({ children }) => {
  const { templateId } = useTemplateMenuContext();
  const { deleteTemplateAlertDisclosure: disclosure } = useTemplateMenuDisclosureContext();

  const deleteMutations = useIsMutating({
    mutationKey: DeleteTemplateMutation.getKey(templateId),
  });

  const isMutating = deleteMutations > 0;

  const child =
    typeof children === 'function' ? children({ isMutating }) : (children as React.ReactElement<ButtonProps>);
  const btn = React.cloneElement(child, {
    onClick: () => {
      disclosure.onToggle();
    },
  });

  const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId, {
    enabled: !!templateId,
  });
  const { data: template } = useGetTemplateQuery({ templateId });

  const canDeleteTemplate = match({ template, permissionMap })
    .with({ template: { templateType: TemplateType.Playbook }, permissionMap: { templateDelete: true } }, () => true)
    .with({ template: { templateType: TemplateType.Form }, permissionMap: { templateDelete: true } }, () => true)
    .with({ template: { templateType: TemplateType.Page }, permissionMap: { pageDelete: true } }, () => true)
    .otherwise(() => false);

  if (!canDeleteTemplate) return null;

  return <>{btn}</>;
});
