import { Approval } from '@process-street/subgrade/approval-rule/approval.model';
import { Identifiable, Option, OrganizationMembership, ProcessingError, User } from '@process-street/subgrade/core';
import { Attachment, Checklist, Comment, FormFieldValue, Task, TaskStats } from '@process-street/subgrade/process';
import { BaseReduxState, ReduxAction } from '@process-street/subgrade/redux/types';
import { ChecklistAssignment, TaskAssignment } from '@process-street/subgrade/role-assignment';
import { Selector } from 'reselect';

export enum OptimisticEventType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export type OptimisticDiff<T> = Partial<T> & Identifiable;

export interface OptimisticCreateEvent<T extends Identifiable> {
  entity: T;
  eventType: OptimisticEventType.Create;
}

export interface OptimisticUpdateEvent<T extends Identifiable> {
  eventType: OptimisticEventType.Update;
  entity: OptimisticDiff<T>;
}

export interface OptimisticDeleteEvent<T extends Identifiable> {
  entity: T;
  eventType: OptimisticEventType.Delete;
}

export function asCreateEvent<T extends Identifiable>(entity: T): OptimisticCreateEvent<T> {
  return {
    entity,
    eventType: OptimisticEventType.Create,
  };
}

export function asUpdateEvent<T extends Identifiable>(entity: OptimisticDiff<T>): OptimisticUpdateEvent<T> {
  return {
    entity,
    eventType: OptimisticEventType.Update,
  };
}

export function asDeleteEvent<T extends Identifiable>(entity: T): OptimisticDeleteEvent<T> {
  return {
    entity,
    eventType: OptimisticEventType.Delete,
  };
}

export type OptimisticEvent<T extends Identifiable> =
  | OptimisticCreateEvent<T>
  | OptimisticUpdateEvent<T>
  | OptimisticDeleteEvent<T>;

export interface OptimisticUpdate {
  approvalEvents: Array<OptimisticEvent<Approval>>;
  attachmentEvents: Array<OptimisticEvent<Attachment>>;
  checklistAssignmentEvents: Array<OptimisticEvent<ChecklistAssignment>>;
  checklistEvents: Array<OptimisticEvent<Checklist>>;
  commentEvents: Array<OptimisticEvent<Comment>>;
  formFieldValueEvents: Array<OptimisticEvent<FormFieldValue>>;
  organizationMembershipEvents: Array<OptimisticEvent<OrganizationMembership>>;
  taskAssignmentEvents: Array<OptimisticEvent<TaskAssignment>>;
  taskEvents: Array<OptimisticEvent<Task>>;
  taskStatEvents: Array<OptimisticEvent<TaskStats>>;
  userEvents: Array<OptimisticEvent<User>>;
}

export interface OptimisticResult {
  commit: Partial<OptimisticUpdate>;
  rollback: Partial<OptimisticUpdate>;
  rollbackOnSuccess: boolean;
}

export type OptimisticResultCreator<T extends ProcessingError> =
  // TODO
  // @ts-expect-error -- TODO
  (...args) => Selector<BaseReduxState, Option<OptimisticResult | T>>;

export interface OptimisticService<T extends ProcessingError> {
  [key: string]: OptimisticResultCreator<T>;
}

export interface OptimisticMeta {
  optimistic: OptimisticResult;
}

export interface OptimisticAction<P, M extends OptimisticMeta> extends ReduxAction<P, M> {
  meta: M;
}
