import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { useAutomationSelector } from './context';

const CONFIRM_LEAVE_MESSAGE = 'You have unsaved changes. Are you sure you want to leave this page?';

export function useUnsavedChangesBrowserAlert() {
  const { $rootScope } = useInjector('$rootScope');
  const [state] = useAutomationSelector();
  const { hasUnsavedChanges } = state.context;

  React.useEffect(() => {
    const unsub = $rootScope.$on('$locationChangeStart', e => {
      if (hasUnsavedChanges) {
        const answer = window.confirm(CONFIRM_LEAVE_MESSAGE);
        if (!answer) {
          e.preventDefault();
        }
      }
    });
    const alertUser = (e: WindowEventMap['beforeunload']) => {
      if (hasUnsavedChanges) {
        e.returnValue = CONFIRM_LEAVE_MESSAGE;
        return CONFIRM_LEAVE_MESSAGE;
      }
    };

    window.addEventListener('beforeunload', alertUser);
    return () => {
      unsub();
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [$rootScope, hasUnsavedChanges]);
}
