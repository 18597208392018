import * as React from 'react';
import { Alert, AlertIcon, Box, FormControl, FormLabel, useDisclosure, VStack, Link } from 'components/design/next';
import { CustomNotificationConfigProvider } from '../../custom-notification-config-provider';
import { RecipientSelect } from '../recipient-select';
import { CustomNotificationTimingConfig } from '../custom-notification-timing-config';
import { CustomNotificationUtils } from '../../custom-notification-utils';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import dayjs from 'dayjs';

export interface AddCustomNotificationConfigWizardProps {
  newConfig: Partial<CustomNotificationUtils.Config>;
}

export const AddCustomNotificationConfigWizard: React.FC<
  React.PropsWithChildren<AddCustomNotificationConfigWizardProps>
> = ({ newConfig }) => {
  const [state, setState] = React.useState<'init' | 'recipientsSet' | 'timingOpened'>('init');

  const organization = useSelector(SessionSelector.getSelectedOrganization);

  const handleRecipientsClose = () => {
    if ((newConfig.recipients?.length ?? 0) > 0 && state === 'init') {
      setState('recipientsSet');
    }
  };

  const timingDisclosure = useDisclosure();
  if (state === 'recipientsSet' && !CustomNotificationUtils.isTimingValid(newConfig?.timing)) {
    setState('timingOpened');
    timingDisclosure.onOpen();
  }

  const orgCreatedDate = dayjs(organization?.audit.createdDate);
  const cantSendToUnverifiedEmailAddresses = orgCreatedDate.isAfter(dayjs().subtract(7, 'day'));

  return (
    <CustomNotificationConfigProvider config={newConfig}>
      <VStack>
        {cantSendToUnverifiedEmailAddresses && (
          <Alert status="warning" mb="2">
            <AlertIcon />
            Notifications won't be sent to unverified email addresses.&nbsp;
            <Link
              href="https://www.process.st/help/docs/custom-notifications/#creating-custom-notifications"
              isExternal={true}
            >
              Learn more »
            </Link>
          </Alert>
        )}
        <FormControl>
          <FormLabel>Send to</FormLabel>
          <Box borderColor="gray.200" borderWidth="px" borderStyle="solid" borderRadius="base">
            <RecipientSelect
              defaultMenuIsOpen={(newConfig?.recipients?.length ?? 0) === 0}
              menuControls
              onMenuClose={handleRecipientsClose}
            />
          </Box>
        </FormControl>

        <FormControl pt="4">
          <FormLabel>Timing for notification</FormLabel>
          <CustomNotificationTimingConfig
            {...timingDisclosure}
            buttonProps={{
              borderColor: 'gray.200',
              borderWidth: 'px',
              borderStyle: 'solid',
              borderRadius: 'base',
            }}
          />
        </FormControl>
      </VStack>
    </CustomNotificationConfigProvider>
  );
};
