import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule/approval-rule-subject.model';
import { Muid } from '@process-street/subgrade/core';
import { AngularResource } from 'reducers/angular-types';
import angular from 'angular';

interface ApprovalRuleResource {
  getAllByTemplateRevisionId(request: { templateRevisionId: Muid }): AngularResource<ApprovalRuleSubject[]>;
  createAll(request: {
    approvalRuleSubjects: ApprovalRuleSubject[];
    templateRevisionId: Muid;
    taskTemplateGroupId: Muid;
  }): AngularResource<ApprovalRuleSubject[]>;
  deleteAll(request: { ids: Muid[]; templateRevisionId: Muid }): AngularResource<ApprovalRuleSubject[]>;
}

export interface ApprovalRulesApi {
  getAllByTemplateRevisionId(templateRevisionId: Muid): angular.IPromise<ApprovalRuleSubject[]>;
  createAll(
    templateRevisionId: Muid,
    taskTemplateGroupId: Muid,
    rules: ApprovalRuleSubject[],
  ): angular.IPromise<ApprovalRuleSubject[]>;
  deleteAll(templateRevisionId: Muid, ids: Muid[]): angular.IPromise<ApprovalRuleSubject[]>;
}

export const ApprovalRulesApiImpl = function (ApprovalRules: ApprovalRuleResource): ApprovalRulesApi {
  'ngInject';

  const getAllByTemplateRevisionId = (templateRevisionId: Muid) =>
    ApprovalRules.getAllByTemplateRevisionId({ templateRevisionId }).$promise;

  const createAll = (
    templateRevisionId: Muid,
    taskTemplateGroupId: Muid,
    approvalRuleSubjects: ApprovalRuleSubject[],
  ) => ApprovalRules.createAll({ approvalRuleSubjects, templateRevisionId, taskTemplateGroupId }).$promise;

  const deleteAll = (templateRevisionId: Muid, ids: Muid[]) =>
    ApprovalRules.deleteAll({ ids, templateRevisionId }).$promise;

  return {
    createAll,
    deleteAll,
    getAllByTemplateRevisionId,
  };
};
