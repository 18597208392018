import * as React from 'react';
import { Box, BoxProps, Tooltip, TooltipProps, useDisclosure } from 'components/design/next';

export interface TooltipWithTouchProps extends Omit<TooltipProps, 'shouldWrapChildren'> {
  boxProps?: BoxProps;
}

export const TooltipWithTouch: React.FC<React.PropsWithChildren<TooltipWithTouchProps>> = ({
  children,
  boxProps,
  ...props
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <Tooltip isOpen={isOpen} {...props}>
      <Box
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        onBlur={onClose}
        onFocus={onOpen}
        onClick={onOpen}
        tabIndex={0}
        {...boxProps}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
