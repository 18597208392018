import * as React from 'react';
import { connectHighlight } from 'react-instantsearch-dom';
import { HighlightProps } from 'react-instantsearch-core';
import { Text } from 'components/design/next';

export const HighlightUI: React.FC<React.PropsWithChildren<HighlightProps>> = ({
  highlight,
  hit,
  attribute,
  highlightProperty,
}) => {
  const parsedHit = highlight({
    highlightProperty: highlightProperty ?? '_highlightResult',
    attribute,
    hit,
  });

  return (
    <>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <Text as="strong" key={index} color="gray.700" fontWeight="bold" variant="inherit">
            {part.value}
          </Text>
        ) : (
          <Text as="span" key={index} variant="inherit">
            {part.value}
          </Text>
        ),
      )}
    </>
  );
};

export const Highlight = connectHighlight(HighlightUI);
