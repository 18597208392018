import React, { ChangeEvent, useRef } from 'react';

import {
  Button,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from 'components/design/next';
import {
  isBadRequestErrorResponse,
  isBulkInviteResultsErrorResponse,
  usePostBulkInviteMutation,
} from 'features/invitations/query-builder';
import { match, P } from 'ts-pattern';
import { useInjector } from 'components/injection-provider';
import { useDispatch, useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import pluralize from 'pluralize';

import { getEnv } from 'components/common/env';
import { HttpStatus } from '@process-street/subgrade/util';
import { BulkInvitePaywall } from 'components/paywalls/bulk-invite/bulk-invite-paywall';
import { usePaywall } from 'components/paywalls';
import { useTracking } from 'react-tracking';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { queryClient } from 'components/react-root';
import { OrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';

export interface Props {
  isOpen: boolean;

  onClose(): void;
}

export const BulkInviteModal: React.FC<React.PropsWithChildren<Props>> = ({ isOpen, onClose }) => {
  const { trackEvent } = useTracking();

  const { OrganizationMembershipActions } = useInjector('OrganizationMembershipActions');

  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const dispatch = useDispatch();

  const toast = useToast();

  const bulkInviteMutation = usePostBulkInviteMutation();

  const formRef = useRef<HTMLFormElement>();
  const formRefHandler = (instance: HTMLFormElement) => (formRef.current = instance);

  const fileInputRef = useRef<HTMLInputElement>();
  const fileInputRefHandler = (instance: HTMLInputElement) => (fileInputRef.current = instance);

  const { setPaywall } = usePaywall();

  const uploadClick = () => {
    fileInputRef?.current?.click();
  };

  const reset = () => {
    formRef?.current?.reset(); // Clears the selected file
    bulkInviteMutation.reset();
  };

  const handleClose = async () => {
    if (selectedOrganizationId) {
      await queryClient.invalidateQueries(
        OrganizationMembershipsQuery.getKey({ organizationId: selectedOrganizationId }),
      );
      dispatch(OrganizationMembershipActions.getAllOrgMembershipByOrgId(selectedOrganizationId));
      reset();
      onClose();
    } else {
      throw new Error('Missing selected organization ID.');
    }
  };

  const submitForm = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    bulkInviteMutation.mutate(
      { file },
      {
        onError: ({ response }) => {
          if (response && response.status == HttpStatus.BAD_REQUEST && isBadRequestErrorResponse(response)) {
            const errors = response.data.errors || [];
            toast({
              status: 'warning',
              title: 'The uploaded file failed validation',
              description: (
                <p>
                  Please try again or contact support. <br />
                  {errors.map(e => (
                    <>
                      <br />
                      {e}
                    </>
                  ))}
                </p>
              ),
            });
            reset();
          } else if (response && response.status == HttpStatus.PAYMENT_REQUIRED) {
            setPaywall(<BulkInvitePaywall />);
            reset();
            handleClose();
          } else if (response && response.status == HttpStatus.TOO_MANY_REQUESTS) {
            toast({
              status: 'error',
              title: 'You have invited too many members too quickly',
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
            reset();
            handleClose();
          } else if (
            response &&
            response.status != HttpStatus.BAD_REQUEST &&
            !isBulkInviteResultsErrorResponse(response)
          ) {
            toast({
              status: 'error',
              title: "We're having problems uploading the file",
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
            reset();
          }
        },
        onSuccess: response => {
          trackEvent({
            eventCategory: 'invitations',
            eventAction: 'bulk-invite',
            eventLabel: 'success',
            eventValue: response.processedCount,
          });
          handleClose();
        },
      },
    );
  };

  const bulkInviteSampleUrl = `${getEnv().APP_API_URL}/1/invitations/bulk-invite/sample`;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text variant="2">Bulk invite from CSV</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <form ref={formRefHandler}>
            <Input
              aria-label="file upload"
              ref={fileInputRefHandler}
              type="file"
              accept="text/csv"
              className="hidden"
              onChange={submitForm}
            />
          </form>
          <VStack spacing="4">
            <Text>
              Upload a CSV file with the emails and role of your team members to invite them to your Process Street
              organization.
            </Text>

            {match(bulkInviteMutation)
              .with({ status: 'idle' }, () => (
                <>
                  <Button colorScheme="brand" onClick={uploadClick}>
                    <Icon icon="upload" size="4" mr={1} />
                    Upload file
                  </Button>
                </>
              ))
              .with({ status: 'loading' }, () => (
                <>
                  <Spinner mb={16} />
                </>
              ))
              .with(
                {
                  status: 'error',
                  error: { response: P.when(isBulkInviteResultsErrorResponse) },
                },
                match => {
                  const { processedCount, errorCount, errors } = match.error.response.data;

                  const errorRows = errors.map(({ email, error }) => (
                    <Tr key={email}>
                      <Td color="red.500" fontWeight="bold">
                        {email}
                      </Td>
                      <Td>{error}</Td>
                    </Tr>
                  ));

                  return (
                    <>
                      <Text align="left" width="full">
                        {pluralize('user', processedCount, true)} were invited but {errorCount} failed.
                      </Text>
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th>EMAIL</Th>
                            <Th>ERROR</Th>
                          </Tr>
                        </Thead>
                        <Tbody bg="gray.100">{errorRows}</Tbody>
                      </Table>
                      <Button colorScheme="brand" onClick={handleClose}>
                        Continue
                      </Button>
                    </>
                  );
                },
              )
              .otherwise(() => (
                <></>
              ))}

            <Text variant="-1">
              The CSV file should be properly formatted.{' '}
              <Link href={bulkInviteSampleUrl} color="brand.500">
                Download example CSV file
              </Link>
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
