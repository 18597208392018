import { Option, StdMergeTagLabel, StdMergeTagLabelLegacy } from '@process-street/subgrade/core';
import { escapeHtml, htmlEscaped, nl2br, StringUtils, unescapeHtml } from '@process-street/subgrade/util';
import { upperFirst } from 'lodash';
import { MergeTagMode } from '../form';
import { MergeTagFilter, ResolvedTag } from './merge-tag-model';

/**
 * Checks if a string is absent (i.e. undefined or null) or is the empty string ('').
 *
 * @param str
 * @returns {boolean}
 */
function isAbsentOrEmpty(str: string | undefined | null): str is undefined {
  return str === undefined || str === null || str === '';
}

/**
 * Applies a filter to the value.
 * All filter are from Shopify's Liquid template language.
 *
 * @param filter
 * @param param
 * @param key
 * @param value
 * @returns {*}
 */
function applyFilter(filter: Option<MergeTagFilter>, param: string, key: string, value: Option<string>): string {
  if (!filter) {
    return isAbsentOrEmpty(value) ? `{{${key}}}` : value;
  }
  switch (filter) {
    case MergeTagFilter.Fallback:
    case MergeTagFilter.FallbackLegacy:
      return isAbsentOrEmpty(value) ? param : value;
    case MergeTagFilter.Downcase:
      return isAbsentOrEmpty(value) ? `{{${key}}}` : value.toLowerCase();
    case MergeTagFilter.Upcase:
      return isAbsentOrEmpty(value) ? `{{${key}}}` : value.toUpperCase();
    case MergeTagFilter.UrlEncode:
      return isAbsentOrEmpty(value) ? `{{${key}}}` : encodeURIComponent(value);
    default:
      return isAbsentOrEmpty(value) ? `{{${key}}}` : value;
  }
}

/**
 * Generate a pattern that'll match a merge tag and an optional filter.
 *
 * @param mode
 * @param key
 *
 * @returns {RegExp}
 */
export function generatePatternByKey(mode: MergeTagMode, key: string): RegExp {
  const regexps = [StringUtils.escapeRegExp(key)];
  if (mode === MergeTagMode.HTML) {
    // eslint-disable-next-line no-undef
    const sanitizedKey = escapeHtml(key);
    regexps.push(StringUtils.escapeRegExp(sanitizedKey));
  }
  // This regex matches {{ key }} or {{ key | filter }} or {{ key | filter:'param' }}
  // All spaces are optional, so it also matches {{key}}, {{key|filter}}, etc.
  const regexp = `{{ *(${regexps.join('|')}) *(?:\\| *(\\w+)(?:: *'([^']*)')?)? *}}`;
  return new RegExp(regexp, 'g');
}

const resolveTag = (tag: ResolvedTag, filter: Option<MergeTagFilter>, param: string, mode: MergeTagMode) => {
  if (tag.replacement) {
    // When we're in HTML mode, we need to unescape the param because it will have been escaped
    // They will be re-escaped after the filter is applied (if they were used in the replacement)
    if (mode === MergeTagMode.HTML) {
      const unescapedParam = unescapeHtml(param);
      const filteredReplacement = applyFilter(filter, unescapedParam, tag.key, tag.replacement);
      return nl2br(htmlEscaped`${filteredReplacement}`);
    }
    return applyFilter(filter, param, tag.key, tag.replacement);
  }

  if (filter === MergeTagFilter.Fallback || filter === MergeTagFilter.FallbackLegacy) {
    return param;
  }

  if (mode === MergeTagMode.HTML) {
    return `<span class="empty-merge-tag" title="Variable value is missing."> ${escapeHtml(tag.default)} </span>`;
  }

  return tag.default;
};

function isInsideHref(agg: string, offset: number) {
  const linkOpen = agg.lastIndexOf('href="', offset);
  if (linkOpen === -1) {
    return false;
  }
  const linkEnd = agg.indexOf('"', linkOpen + 6);
  if (linkEnd === -1) {
    return false;
  }
  return linkEnd > offset;
}

/**
 * Replaces merge tags with resolved values.
 *
 * @param resolvedTags [{ key: 'tag key', replacement: 'value to replace' }]
 * @param content
 * @param mode - mode of replacement
 *
 * @returns {String}
 */
function replaceResolvedTagsValues(resolvedTags: ResolvedTag[], content: string, mode: MergeTagMode): string {
  const value = resolvedTags.reduce((agg, tag) => {
    const pattern = generatePatternByKey(mode, tag.key);
    return agg.replace(pattern, (match, _key, filter, param: string, offset: number) => {
      if (!tag.replacement && isInsideHref(agg, offset)) {
        return match;
      }
      return resolveTag(tag, filter, param, mode);
    });
  }, content);

  return value;
}

const mergeTagFilterParam = ": *'[^']*'";
const filters = [
  MergeTagFilter.Upcase,
  MergeTagFilter.Downcase,
  MergeTagFilter.UrlEncode,
  MergeTagFilter.Fallback + mergeTagFilterParam,
  MergeTagFilter.FallbackLegacy + mergeTagFilterParam,
];
const filtersWithPipe = Object.values(MergeTagFilter).map(f => '\\| *' + f);

const TAGS_REGEXP_STRING =
  `{{\\s*((?:(?!{{)(?!${filtersWithPipe.join('|')})\\S)+)\\s*` + `(?:\\|\\s*(?:${filters.join('|')}))?\\s*}}`;
const TAGS_REGEXP = new RegExp(TAGS_REGEXP_STRING, 'g');

/**
 * Find tags in specified content
 * @param {string} content
 * @param {} mode
 * @return {string[]}
 */
function findTags(content: string, mode: MergeTagMode): string[] {
  const foundTags: string[] = [];

  let array: RegExpExecArray | null;

  while ((array = TAGS_REGEXP.exec(content)) !== null) {
    foundTags.push(array[1]);
  }

  // unescape tags for html mode, trim to get rid of possible &nbsp;
  if (mode === MergeTagMode.HTML) {
    return foundTags.map(tag => unescapeHtml(tag).trim());
  }

  return foundTags;
}

const LEFT_CHEVRON = '\u2039'; // ‹
const RIGHT_CHEVRON = '\u203A'; // ›
const generateSurroundRegexp = (open: string, close: string) =>
  new RegExp(`(${open} *)([^${close}]*)( *${close})`, 'g');
const withChevrons = (content: string) => LEFT_CHEVRON + content + RIGHT_CHEVRON;
const CHEVRONS_REGEXP = generateSurroundRegexp(LEFT_CHEVRON, RIGHT_CHEVRON);
const withCurlyBraces = (content: string) => `{{${content}}}`;
const CURLY_BRACES_REGEXP = generateSurroundRegexp('{{', '}}');
const CURLY_BRACES_FILTERS_REGEXP = /\|(.*?)\s*'[^']*'/g;

const convertTagToLabel = (tag: string): string =>
  StdMergeTagLabel[tag as keyof typeof StdMergeTagLabel] ??
  StdMergeTagLabelLegacy[tag as keyof typeof StdMergeTagLabelLegacy] ??
  upperFirst(tag.replace(/(form.)|[_]/g, ' ')).trim() ??
  '';

function replaceUnknownTagsValues(content: string): string {
  const tags = findTags(content, MergeTagMode.PLAINTEXT);
  const contentWithoutMergeTagFilters = content.replace(CURLY_BRACES_FILTERS_REGEXP, '');

  return tags.reduce((acc, tag) => {
    const replacement = convertTagToLabel(tag);
    return acc.replace(withCurlyBraces(tag), withChevrons(replacement));
  }, contentWithoutMergeTagFilters);
}

export const MergeTagStringReplacementUtils = {
  findTags,
  replaceResolvedTagsValues,
  replaceUnknownTagsValues,
  TAGS_REGEXP,
  LEFT_CHEVRON,
  RIGHT_CHEVRON,
  CHEVRONS_REGEXP,
  CURLY_BRACES_REGEXP,
};
