import { Attachment, AttachmentType, Task } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type FinishUploadAttachmentMutationParams = {
  taskId: Task['id'];
  key: string;
  originalFilename: string;
  contentType: string;
  attachmentType?: AttachmentType;
};

export type FinishUploadAttachmentMutationResponse = Attachment;

export const FinishUploadAttachmentMutation = {
  key: ['post', 'comments', 'attachment'],
  mutationFn: (params: FinishUploadAttachmentMutationParams) =>
    axiosService
      .getAxios()
      .post<FinishUploadAttachmentMutationResponse>(`/1/attachments/finish-upload`, params)
      .then(res => res.data),
};

export const useFinishUploadAttachmentMutation = (
  options: UseMutationOptions<
    FinishUploadAttachmentMutationResponse,
    AxiosError,
    FinishUploadAttachmentMutationParams
  > = {},
) => {
  return useMutation(FinishUploadAttachmentMutation.mutationFn, options);
};
