// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../images/task-template/approval-rules-empty-img.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"approval-rules-view-container\" ng-if=\"$ctrl.state.approvalRuleSubjectsLoaded\"> <div data-ng-if=\"$ctrl.nonEmpty()\"> <div class=\"row header\"> <div class=\"col-sm-6 list-title\">Will be submitted for approval:</div> <div class=\"col-sm-6\"> <button type=\"button\" data-ng-hide=\"$ctrl.disabled || !$ctrl.state.approvalsFeatureIsAvailable\" data-ng-click=\"$ctrl.onToEditMode()\" class=\"btn btn-primary navbar-btn add pull-right\"> <i class=\"far fa-plus\"></i> Add Additional Tasks </button> </div> </div> <ps-approval-rule-subject-tasks-list data-disabled=\"$ctrl.disabled || !$ctrl.state.approvalsFeatureIsAvailable\" data-subject-task-templates=\"$ctrl.state.subjectTaskTemplates\" data-template-revision=\"$ctrl.templateRevision\" data-on-remove=\"$ctrl.removeSubjectTaskTemplates(taskTemplate)\" data-on-select=\"$ctrl.onSubjectTaskSelect({ taskTemplate: taskTemplate })\"> </ps-approval-rule-subject-tasks-list> </div> <div class=\"empty-list\" data-ng-if=\"!$ctrl.nonEmpty()\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> <br/> <h5>Approvals</h5> <br/> <p data-ng-if=\"$ctrl.disabled\" class=\"lead text-muted widgets-message\">Edit this workflow to add an approval.</p> <p data-ng-if=\"!$ctrl.disabled\">Add an Approval step to your tasks.</p> <button type=\"button\" data-ng-hide=\"$ctrl.disabled || !$ctrl.state.approvalsFeatureIsAvailable\" data-ng-click=\"$ctrl.onToEditMode()\" class=\"btn btn-primary navbar-btn\"> Select Task(s) to Approve </button> </div> </div> ";
// Exports
var _module_exports = code;;
var path = '/components/approval-rules/container/view/view-container.component.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;