import { PlanLevel, PlanTrack } from '@process-street/subgrade/billing';
import { FeatureItem } from 'components/billing/FeatureItem';
import { PlanSelectButton } from 'components/billing/PlanSelectButton';
import { PlanTopPanel } from 'components/billing/PlanTopPanel';
import React from 'react';
import { useOnPlanTrack } from 'services/use-plan-track';
import { PlanLevelSelectorState } from '../../../directives/billing/plan-level-selector/plan-level-selector-service.interface';
import './FreePlanItem.scss';

interface FreePlanItemProps {
  selectorState: PlanLevelSelectorState;
  onSelect: (level: PlanLevel) => void;
}

const handleSelect = (onSelect: (level: PlanLevel) => void) => () => onSelect(PlanLevel.Free);

const FreePlanFeatures = ['5 Active Workflow Runs', '5 Active Workflows', 'Limited Support'];
const FreemiumFreePlanFeatures = [
  'Up to 5 workflows and workflow runs',
  'Up to 5 full team members',
  'Unlimited free team members and pages',
];

const renderFeatureForMobile = (feature: string) => (
  <div key={feature} className="col-xs-6 free-plan-item__label">
    <FeatureItem checked={true} name={feature} />
  </div>
);

const renderFeatureForDesktop = (feature: string) => (
  <div key={feature} className="free-plan-item__label">
    {feature}
  </div>
);

export const FreePlanItem: React.FunctionComponent<React.PropsWithChildren<FreePlanItemProps>> = ({
  selectorState,
  onSelect,
}) => {
  const onFreemium = useOnPlanTrack(PlanTrack.Freemium);
  const freePlanFeatures = onFreemium ? FreemiumFreePlanFeatures : FreePlanFeatures;
  const selected = selectorState === PlanLevelSelectorState.SELECTED_PLAN;
  const freeSelectedClass = selected ? `selected-level-free` : '';

  return (
    <div className={`row free-plan-item ${freeSelectedClass}`}>
      <div className="col-sm-3 free-plan-item__top-panel">
        <PlanTopPanel level={PlanLevel.Free} cost={'0'} selected={selected} />
      </div>
      <div className="col-sm-3 col-sm-push-6 free-plan-item__button">
        <PlanSelectButton level={PlanLevel.Free} selectorState={selectorState} onSelect={handleSelect(onSelect)} />
      </div>
      <div className="col-sm-6 col-sm-pull-3 free-plan-item__labels">
        <div className="hidden-xs">{freePlanFeatures.map(renderFeatureForDesktop)}</div>
        <div className="visible-xs row">{freePlanFeatures.map(renderFeatureForMobile)}</div>
      </div>
    </div>
  );
};
