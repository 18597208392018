import { MergeTagsConstants } from '@process-street/subgrade/form/merge-tags-constants';
import { escapeHtml } from '@process-street/subgrade/util';
import angular from 'angular';
import { createMergeTag } from 'hooks/use-insert-merge-tag-callback';
import { FormatBlockType } from './toolbar-blocks';
import templateUrl from './toolbar.component.html';
import './toolbar.scss';

angular.module('frontStreetApp.directives').component('psTextWidgetToolbar', {
  bindings: {
    editor: '<',
    templateRevision: '<',
    mergeTagsEnabled: '<',
    headerId: '<',
    widget: '<',
  },
  templateUrl,
  controller($timeout) {
    const ctrl = this;

    ctrl.Target = MergeTagsConstants.Target;

    ctrl._hasBoldParents = parents => parents.some(el => ['B', 'STRONG'].includes(el.nodeName));

    ctrl._hasItalicParents = parents => parents.some(el => ['I', 'EM'].includes(el.nodeName));

    ctrl._hasUnderlineParents = parents =>
      parents.some(el => el.nodeName === 'U' || el.style.cssText.includes('underline'));

    ctrl.$onChanges = function (changes) {
      if (changes.editor && changes.editor.currentValue) {
        ctrl.editor.on('NodeChange', e => {
          ctrl.boldActive = ctrl._hasBoldParents(e.parents);
          ctrl.italicActive = ctrl._hasItalicParents(e.parents);
          ctrl.underlineActive = ctrl._hasUnderlineParents(e.parents);
        });
        ctrl._registerFormatters();
        ctrl._addShortcuts();
      }
    };

    ctrl._registerFormatters = () => {
      // A custom blockquote that doesn't let it contain other blocks
      ctrl.editor.formatter.register('bq', {
        block: 'p',
        // Use attributes instead so that it replaces the old classes instead of merging them
        attributes: {
          class: FormatBlockType.Blockquote,
        },
      });

      ctrl.editor.formatter.register('success', {
        block: 'p',
        attributes: {
          class: FormatBlockType.Success,
        },
      });
      ctrl.editor.formatter.register('info', {
        block: 'p',
        attributes: {
          class: FormatBlockType.Info,
        },
      });
      ctrl.editor.formatter.register('reminder', {
        block: 'p',
        attributes: {
          class: FormatBlockType.Warning,
        },
      });
      ctrl.editor.formatter.register('critical', {
        block: 'p',
        attributes: {
          class: FormatBlockType.Danger,
        },
      });
    };

    ctrl._addShortcuts = () => {
      ctrl.editor.shortcuts.add('meta+l', 'Link', () => {
        ctrl.link();
      });

      ctrl.editor.shortcuts.add('meta+k', 'Code', () => {
        ctrl.code();
      });

      ctrl.editor.shortcuts.add('meta+shift+u', 'Insert Unordered List', () => {
        ctrl.insertUnorderedList();
      });

      ctrl.editor.shortcuts.add('meta+shift+o', 'Insert Ordered List', () => {
        ctrl.insertOrderedList();
      });

      ctrl.editor.shortcuts.add('access+f', 'Formatted', () => {
        ctrl.formatBlock('pre');
      });
    };

    ctrl.hasUndo = function () {
      return ctrl.editor.undoManager.hasUndo();
    };

    ctrl.undo = function () {
      ctrl.editor.execCommand('Undo');
    };

    ctrl.hasRedo = function () {
      return ctrl.editor.undoManager.hasRedo();
    };

    ctrl.redo = function () {
      ctrl.editor.execCommand('Redo');
    };

    ctrl.bold = function () {
      ctrl.editor.execCommand('Bold');
    };

    ctrl.italicize = function () {
      ctrl.editor.execCommand('Italic');
    };

    ctrl.underline = function () {
      ctrl.editor.execCommand('Underline');
    };

    ctrl.code = function () {
      ctrl.editor.execCommand('FormatBlock', false, 'code');
    };

    ctrl.link = function () {
      // TinyMCE doesn't like it if you try to link without editor focus
      ctrl.editor.focus();
      $timeout(() => {
        // This needs to be in a timeout otherwise the editor won't have a chance to focus
        ctrl.editor.execCommand('mceLink');
      });
    };

    ctrl.insertUnorderedList = function () {
      ctrl.editor.execCommand('insertUnorderedList');
    };

    ctrl.insertOrderedList = function () {
      ctrl.editor.execCommand('insertOrderedList');
    };

    ctrl.indent = function () {
      ctrl.editor.execCommand('Indent');
    };

    ctrl.outdent = function () {
      ctrl.editor.execCommand('Outdent');
    };

    ctrl.justifyCenter = function () {
      ctrl.editor.execCommand('JustifyCenter');
    };

    ctrl.justifyLeft = function () {
      ctrl.editor.execCommand('JustifyLeft');
    };

    ctrl.justifyRight = function () {
      ctrl.editor.execCommand('JustifyRight');
    };

    ctrl.justifyFull = function () {
      ctrl.editor.execCommand('JustifyFull');
    };

    ctrl.justifyNone = function () {
      ctrl.editor.execCommand('JustifyNone');
    };

    ctrl.insertHorizontalRule = function () {
      ctrl.editor.execCommand('insertHorizontalRule');
    };

    ctrl.colors = [
      { label: 'Default', color: '#333333', menuClass: 'c text-color-1' },
      { label: 'Gray', color: '#b3b3b3', menuClass: 'c text-color-2' },
      { label: 'Red', color: '#E15951', menuClass: 'c text-color-3' },
      { label: 'Blue', color: '#007DB6', menuClass: 'c text-color-4' },
      { label: 'Green', color: '#00B4A6', menuClass: 'c text-color-5' },
    ];

    ctrl.highlights = [
      { label: 'Yellow', color: '#FCE8A7', menuClass: 'c highlight-1' },
      { label: 'Orange', color: '#FFD0B9', menuClass: 'c highlight-2' },
      { label: 'Green', color: '#B3F0C1', menuClass: 'c highlight-3' },
      { label: 'Blue', color: '#B4E5FA', menuClass: 'c highlight-4' },
      { label: 'Clear', color: 'rgba(0,0,0,0)', menuClass: 'c highlight-5' },
    ];

    ctrl.formats = [
      { label: 'Normal', name: 'p', menuClass: 'style-normal' },
      { label: 'Heading 1', name: 'h1', menuClass: 'style-heading1' },
      { label: 'Heading 2', name: 'h2', menuClass: 'style-heading2' },
      { label: 'Heading 3', name: 'h3', menuClass: 'style-heading3' },
      { label: 'Formatted', name: 'pre', menuClass: 'style-formatted' },
      { label: 'Success', name: 'success', menuClass: FormatBlockType.Success },
      { label: 'Info', name: 'info', menuClass: FormatBlockType.Info },
      { label: 'Reminder', name: 'reminder', menuClass: FormatBlockType.Warning },
      { label: 'Critical', name: 'critical', menuClass: FormatBlockType.Danger },
      { label: 'Code', name: 'code', menuClass: 'style-code' },
      { label: 'Quote', name: 'bq', menuClass: FormatBlockType.Blockquote },
    ];

    ctrl.formatBlock = function (format) {
      ctrl.editor.execCommand('FormatBlock', false, format.name);
    };

    ctrl.textColor = function (color) {
      ctrl.editor.execCommand('Forecolor', false, color.color);
    };

    ctrl.highLight = function (color) {
      ctrl.editor.execCommand('Hilitecolor', false, color.color);
    };

    // Merge Tags

    ctrl.insertMergeTag = function (key, _fieldId, fallback) {
      ctrl.editor.insertContent(createMergeTag(escapeHtml(key), fallback));
    };

    ctrl.onAiContentGenerated = widget => {
      ctrl.editor.setContent(widget.content, { format: 'html' });
    };

    /**
     * Enable/disables the editor based on the ai generation status
     *
     * @param {string} status - The status of the AI generation process.
     *                          Possible values are: 'idle', 'error', 'success', 'loading'.
     */
    ctrl.onAiGenerationStatusChange = status => {
      const editorBody = ctrl.editor.getBody();
      const editorContent = ctrl.editor.getContent();
      const widgetEditorDisabledClass = 'widget-editor--disabled';
      const placeholder = 'Draft being generated by AI';

      if (status === 'loading') {
        editorBody.setAttribute('contenteditable', false);
        editorBody.classList.add(widgetEditorDisabledClass);

        if (!editorContent) {
          ctrl.editor.setContent(placeholder);
        }
      } else {
        editorBody.setAttribute('contenteditable', true);
        editorBody.classList.remove(widgetEditorDisabledClass);

        if (editorContent === `<p>${placeholder}</p>`) {
          ctrl.editor.setContent('');
        }
      }
    };
  },
});
