export const TaskTemplatePermitApi = function (TaskTemplatePermits) {
  'ngInject';

  const getAllByTemplateRevisionId = templateRevisionId =>
    TaskTemplatePermits.getAllByTemplateRevisionId({ templateRevisionId }).$promise;

  const upsert = (templateRevisionId, createRequest) =>
    TaskTemplatePermits.upsert(templateRevisionId, { templateRevisionId, ...createRequest }).$promise;

  const upsertAll = (templateRevisionId, createRequest) =>
    TaskTemplatePermits.upsertAll({ templateRevisionId, permits: createRequest }).$promise;

  return {
    getAllByTemplateRevisionId,
    upsert,
    upsertAll,
  };
};
