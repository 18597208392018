import * as React from 'react';
import { ThemeTypings } from 'components/design/next';
import { useWorkflowState } from './use-workflow-state';

export const useGetFocusBarColor = () => {
  const state = useWorkflowState();
  const isEditable = state === 'edit';
  const isDashboard = state === 'dashboard';

  return React.useCallback(
    ({
      light,
      dark,
      dashboard,
    }: {
      light: ThemeTypings['colors'];
      dark: ThemeTypings['colors'];
      dashboard?: ThemeTypings['colors'];
    }) => {
      if (isDashboard && dashboard) return dashboard;
      return isEditable ? dark : light;
    },
    [isDashboard, isEditable],
  );
};
