import { NotificationConfigPropertyKey } from '@process-street/subgrade/core';
import { Divider, Text, useToast, VStack } from 'components/design/next';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { GetOrganizationNotificationConfigQuery } from 'features/organization/query-builder';
import { GetNotificationConfigQuery, NotificationConfigMutation } from 'features/user/query-builder';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { ConfigItem } from './config-item';

export interface UserNotificationConfigProps {}

export const UserNotificationConfig: React.FC<React.PropsWithChildren<UserNotificationConfigProps>> = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const configQuery = GetNotificationConfigQuery.useQuery({});

  const configMutation = NotificationConfigMutation.useMutation({
    onSuccess: data => {
      queryClient.setQueryData(GetNotificationConfigQuery.getKey({}), () => data);
      toast({
        status: 'success',
        title: 'Notification settings updated',
      });
    },
    onError: _ => {
      toast({
        status: 'error',
        title: `We're having problems updating the notifications settings`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  // const [notificationConfig, setNotificationConfig] = useState(props.notificationConfig);
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId) ?? '';

  const { data: orgConfig } = GetOrganizationNotificationConfigQuery.useQuery({ organizationId });

  const handleChange = (id: NotificationConfigPropertyKey, e: React.ChangeEvent<HTMLInputElement>) => {
    if (configQuery.data) {
      const { checked } = e.target;
      const isChecked = id === 'emailsAllowed' ? !checked : checked;

      // If emails are off, but some property is turned on, then emailsAllowed should be turned on
      const isEmailsAllowed = id === 'emailsAllowed' ? isChecked : configQuery.data.emailsAllowed;
      const emailsAllowed = isChecked && id !== 'emailsAllowed' && !isEmailsAllowed ? true : isEmailsAllowed;

      const updatedConfig = { ...configQuery.data, [id]: isChecked, emailsAllowed };

      configMutation.mutate(updatedConfig);
    }
  };

  return (
    <>
      {configQuery.data && (
        <VStack ml="2" alignItems="start" justifyContent="space-between" w="full">
          <Text variant="-2u" color="gray.400">
            Daily Inbox Email
          </Text>
          <ConfigItem
            id="dailyInboxEmailsAllowed"
            label="Get a once-a-day reminder of overdue and upcoming tasks."
            notificationConfig={configQuery.data}
            onChange={handleChange}
            organizationNotificationConfig={orgConfig}
            organizationConfigKey="dailyInboxEmailAllowed"
          />

          <Divider pt="3" />

          <Text pt="5" variant="-2u" color="gray.400">
            Tasks
          </Text>

          <ConfigItem
            id="taskAssignedEmailAllowed"
            label="When you are assigned to a task."
            notificationConfig={configQuery.data}
            onChange={handleChange}
            organizationNotificationConfig={orgConfig}
            organizationConfigKey="taskAssignedEmailAllowed"
          />

          <ConfigItem
            id="taskDueEmailAllowed"
            label="When your task is due."
            notificationConfig={configQuery.data}
            onChange={handleChange}
            organizationNotificationConfig={orgConfig}
            organizationConfigKey="taskDueEmailAllowed"
          />

          <ConfigItem
            id="stopTaskHandOffEmailAllowed"
            label="When a hand-off occurs from a Stop Task."
            notificationConfig={configQuery.data}
            onChange={handleChange}
            organizationNotificationConfig={orgConfig}
            organizationConfigKey="stopTaskHandOffEmailAllowed"
          />

          <ConfigItem
            id="mentionEmailAllowed"
            label="When someone @mentions you."
            notificationConfig={configQuery.data}
            onChange={handleChange}
            organizationNotificationConfig={orgConfig}
            organizationConfigKey="mentionEmailAllowed"
          />

          <Divider pt="3" />

          <Text pt="5" variant="-2u" color="gray.400">
            Workflow Runs
          </Text>

          <ConfigItem
            id="checklistAssignedEmailAllowed"
            label="When you are assigned to a workflow run."
            notificationConfig={configQuery.data}
            onChange={handleChange}
            organizationNotificationConfig={orgConfig}
            organizationConfigKey="checklistAssignedEmailAllowed"
          />

          <ConfigItem
            id="checklistDueEmailAllowed"
            label="When your workflow run is due."
            notificationConfig={configQuery.data}
            onChange={handleChange}
            organizationNotificationConfig={orgConfig}
            organizationConfigKey="checklistDueEmailAllowed"
          />

          <Divider pt="3" />

          <Text pt="5" variant="-2u" color="gray.400">
            Approvals
          </Text>

          <ConfigItem
            id="approvalsSubmissionEmailsAllowed"
            notificationConfig={configQuery.data}
            label="When you receive an approval."
            onChange={handleChange}
            organizationNotificationConfig={orgConfig}
            organizationConfigKey="approvalsSubmissionEmailAllowed"
          />

          <ConfigItem
            id="approvalsReviewResultEmailsAllowed"
            notificationConfig={configQuery.data}
            label="When your work is approved/rejected."
            onChange={handleChange}
            organizationNotificationConfig={orgConfig}
            organizationConfigKey="approvalsReviewResultEmailAllowed"
          />

          <Divider pt="3" />

          <Text pt="5" variant="-2u" color="gray.400">
            Unsubscribe from all emails
          </Text>

          <ConfigItem
            id="emailsAllowed"
            notificationConfig={configQuery.data}
            label="If checked, you won't receive any emails except billing and security-related ones."
            onChange={handleChange}
            organizationNotificationConfig={orgConfig}
            organizationConfigKey="emailsAllowed"
          />
        </VStack>
      )}
    </>
  );
};
