import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { useTaskTemplateGroupId } from './utils/use-task-template-group-id';
import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';
import { useTemplateId } from './utils/use-template-id';

export const useTaskTemplate = () => {
  const templateId = useTemplateId();
  const isEditable = useWorkflowState() === 'edit';
  const taskTemplateGroupId = useTaskTemplateGroupId();
  const newestTemplateRevisionQuery = useNewestTemplateRevisionQuery({ templateId, editable: isEditable });

  const templateRevisionId = newestTemplateRevisionQuery.data?.id;
  const query = useTaskTemplatesByTemplateRevisionIdQuery(
    { templateRevisionId: templateRevisionId! },
    {
      enabled: Boolean(templateRevisionId),
      select: data => data.find(task => task.group.id === taskTemplateGroupId),
    },
  );
  return query.data;
};
