import { BlvdDatePicker, BlvdDatePickerProps } from 'components/design/BlvdDatePicker';
import { useDisclosure, FormControl, Checkbox } from 'components/design/next';
import { useField } from 'formik';
import * as React from 'react';

export type DateSettingsDatePickerProps = {
  name: string;
  label: React.ReactNode;
};

export const DateSettingsDatePicker: React.FC<React.PropsWithChildren<DateSettingsDatePickerProps>> = ({
  name,
  label,
}) => {
  const [field, _meta, helpers] = useField(name);
  const disclosure = useDisclosure();
  const [isExpanded, setIsExpanded] = React.useState<boolean>(Boolean(field.value));

  const { setValue } = helpers;

  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;
    setIsExpanded(checked);
    if (!checked) {
      setValue(undefined); // clearing value on uncheck
    }
  };

  const handleOnSave: BlvdDatePickerProps['onSave'] = (date, _timeStatus) => {
    if (date && date.getTime()) {
      setValue(date.getTime());
    } else {
      setValue(undefined);
      setIsExpanded(false);
    }
  };

  return (
    <FormControl>
      <Checkbox onChange={handleCheckboxClick} isChecked={isExpanded} data-testid={`${field.name}-checkbox`}>
        {label}
      </Checkbox>
      {isExpanded && (
        <BlvdDatePicker
          {...field}
          id={field.name}
          data-testid={`${field.name}-date-picker`}
          value={field.value ? new Date(field.value) : undefined}
          disclosure={disclosure}
          onSave={handleOnSave}
          timeStatus="disabled"
        />
      )}
    </FormControl>
  );
};
